import React, { useState, useEffect, useRef } from "react";

import "../../../../assets/css/ProductService.css";

import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

import { LeftButton, RightButton } from "../Incentive_User_icon_img/All_Icon";

import ProductServiceCard from "./Product_Service_Card";

function Product_Service_Card_Slider({ id, data }) {
  // -------------------- Image Slide 1 --------------------
  // const [current, setCurrent] = useState(0);
  // const length = data.length;

  // function nextSlide(params) {
  //   setCurrent(current === length - 1 ? 0 : current + 1)
  // }

  // function prevSlide(params) {
  //   setCurrent(current === 0 ? length - 1 : current - 1)
  // }

  // console.log(current);

  // if (!Array.isArray(data) || data.length <= 0) {
  //   return null;
  // }

  // return (
  //   <>
  //     <button onClick={prevSlide}>{"<<"}</button>
  //     <div style={{
  //       display: "flex"
  //     }}>
  //       {data.map((item, index) => {
  //         return item.item
  //       })}
  //     </div>
  //     <button onClick={nextSlide}>{">>"}</button>
  //   </>
  // );
  // -------------------- Image Slide 1 --------------------

  // -------------------- Image Slide 2 --------------------
  // const [slideIndex, setSlideIndex] = useState(1);

  // const nextSlide = () => {
  //   if (slideIndex !== data.length) {
  //     setSlideIndex(slideIndex + 1);
  //   } else if (slideIndex === data.length) {
  //     setSlideIndex(1);
  //   }
  // };

  // const prevSlide = () => {
  //   if (slideIndex !== 1) {
  //     setSlideIndex(slideIndex - 1);
  //   } else if (slideIndex === 1) {
  //     setSlideIndex(data.length);
  //   }
  // };

  // const moveDot = (index) => {
  //   setSlideIndex(index);
  // };

  // return (
  //   <>
  //     <div className="container-slider">
  //       <button onClick={prevSlide}>{"<<"}</button>
  //       <button onClick={nextSlide}>{">>"}</button>
  //       <div
  //         style={{
  //           display: "flex",
  //         }}
  //       >
  //         {data.map((item, index) => {
  //           return (
  //             <div
  //               key={index}
  //               className={
  //                 slideIndex === index + 1 ? "slide active-anim" : "slide"
  //               }
  //             >
  //               {item.item}
  //             </div>
  //           );
  //         })}
  //       </div>

  //       <div className="container-dots">
  //         {Array.from({ length: 5 }).map((item, index) => (
  //           <div
  //             onClick={() => moveDot(index + 1)}
  //             className={slideIndex === index + 1 ? "dot active" : "dot"}
  //           ></div>
  //         ))}
  //       </div>
  //     </div>
  //   </>
  // );
  // -------------------- Image Slide 2 --------------------

  // -------------------- Multi Slide --------------------
  const [elementSize, setElementSize] = useState({ width: 0 });

  const [showButtonLeft, setShowButtonLeft] = useState(false);
  const [showButtonRight, setShowButtonRight] = useState(true);

  const [loadComplete, setLoadComplete] = useState(false);

  function slideLeft() {
    let slider = document.getElementById(`slider-${id}`);
    slider.scrollLeft = slider.scrollLeft - 500;
  }

  function slideRight() {
    let slider = document.getElementById(`slider-${id}`);
    slider.scrollLeft = slider.scrollLeft + 500;
  }

  // useEffect(() => {
  //   // Handler to call on window resize
  //   function handleResize() {
  //     // Set window width/height to state
  //     // setWindowSize({
  //     //   width: window.innerWidth,
  //     //   height: window.innerHeight,
  //     // });

  //     if (elementSize.width === 0) {
  //       setElementSize({
  //         width: width,
  //       });
  //       elementSize.width = width;
  //     } else {
  //       setElementSize({
  //         width: document
  //           .getElementById(`containerImageSlide2-${id}`)
  //           .getBoundingClientRect().width,
  //       });
  //       elementSize.width = document
  //         .getElementById(`containerImageSlide2-${id}`)
  //         .getBoundingClientRect().width;
  //     }
  //   }
  //   // Add event listener
  //   window.addEventListener("resize", handleResize);
  //   // Call handler right away so state gets updated with initial window size
  //   handleResize();
  //   // Remove event listener on cleanup
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    setLoadComplete(true);
  }, []);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      // setWindowSize({
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      // });

      setElementSize({
        width: document.getElementById(`slider-${id}`).offsetWidth,
      });
      elementSize.width = document.getElementById(`slider-${id}`).offsetWidth;
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <>
      <div className="containerMultiSlide">
        {showButtonLeft &&
        loadComplete &&
        (elementSize.width === 0
          ? document.getElementById(`slider-${id}`).offsetWidth
          : elementSize.width) <
          document.getElementById(`slider-${id}`).scrollWidth ? (
          <a
            className="slider-multi-icon left"
            style={{ cursor: "pointer" }}
            onClick={slideLeft}
          >
            <LeftButton whidth="10" height="20" fill="#0697FF" />
          </a>
        ) : (
          <div className="slider-multi-icon left" />
        )}

        <div
          id={`slider-${id}`}
          className="slider-multi"
          onScroll={(e) => {
            if (loadComplete) {
              // console.log(
              //   document.getElementById(`slider-${id}`).getBoundingClientRect()
              //     .width,
              //   Math.ceil(
              //     document
              //       .getElementById(`slider-${id}`)
              //       .getBoundingClientRect().width
              //   ) + e.target.scrollLeft,
              //   document.getElementById(`slider-${id}`).scrollWidth
              // );

              if (
                Math.ceil(
                  document
                    .getElementById(`slider-${id}`)
                    .getBoundingClientRect().width
                ) +
                  e.target.scrollLeft >=
                document.getElementById(`slider-${id}`).scrollWidth - 10
              ) {
                setShowButtonRight(false);
              } else {
                setShowButtonRight(true);
              }

              if (e.target.scrollLeft <= 10) {
                setShowButtonLeft(false);
              } else {
                setShowButtonLeft(true);
              }
            }
          }}
        >
          {data.map((item, _) => {
            // return <div className="slider-card">{item}</div>;
            return item;
          })}
        </div>

        {showButtonRight &&
        loadComplete &&
        (elementSize.width === 0
          ? document.getElementById(`slider-${id}`).offsetWidth
          : elementSize.width) <
          document.getElementById(`slider-${id}`).scrollWidth ? (
          <a
            className="slider-multi-icon right"
            style={{ cursor: "pointer" }}
            onClick={slideRight}
          >
            <RightButton whidth="10" height="20" fill="#0697FF" />
          </a>
        ) : (
          <div className="slider-multi-icon right" />
        )}
      </div>
    </>
  );
  // -------------------- Multi Slide --------------------
}

export default Product_Service_Card_Slider;
