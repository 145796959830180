import React, { useState, useEffect } from "react";

import "../../../assets/css/Dashboard.css";
import "../../../assets/css/CustomScroll.css";

function Card_Table(params) {
  return (
    <>
      <div
        className={`${params.ClassName} cardTableComponentNormal`}
        style={{
          ...params.Style,
        }}
      >
        <label
          style={{
            fontSize: "24px",
            lineHeight: "normal",
            margin: "0px 0px 15px 0px",
            display: "block",
            textAlign: "center",
          }}
        >
          {params.Text}
        </label>
        <div
          className="customScroll-1"
          style={{ maxHeight: params?.maxHeight || "500px" }}
        >
          <table
            className="topHeadTable-Table"
            style={{ borderLeft: "solid 2px black" }}
          >
            <thead className="topHeadTable-Thead">
              <tr>
                {params.DataTable.columns.map((column) => {
                  return (
                    <th
                      style={{
                        ...column.style,
                        textAlign: "center",
                        borderRight: "solid 2px black",
                        borderBottom: "solid 2px black",
                        borderTop: "solid 2px black",
                      }}
                    >
                      <label
                        style={{
                          color: params.Color,
                          fontSize: "20px",
                          lineHeight: "normal",
                          margin: "0px",
                          verticalAlign: "middle",
                          whiteSpace: "nowrap",
                          padding: "0px 10px 0px 10px",
                        }}
                      >
                        {column.label}
                      </label>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {params.DataTable.rows.map((row) => {
                return (
                  <tr>
                    {params.DataTable.columns.map((column) => {
                      return (
                        <td
                          style={{
                            ...row[column.field].style,
                            borderRight: "solid 2px black",
                            borderBottom: "solid 2px black",
                          }}
                        >
                          <label
                            style={{
                              color: params.Color,
                              fontSize: "20px",
                              lineHeight: "normal",
                              margin: "0px",
                              verticalAlign: "middle",
                              padding: "0px 10px 0px 10px",
                            }}
                          >
                            {row[column.field].label}
                          </label>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
export default Card_Table;
