import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import Configs from "../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { useParams } from "react-router";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Config_AIOI_AEV({ mode }) {
  const { id } = useParams();

  const [settingPage, setSettingPage] = useState({
    disableInputMain: false,
    disableInputModal: false,
  });

  const [configMainData, setConfigMainData] = useState({
    warehouse_id: "",
    connect_address: "",
    remark: "",
    controller_name: "",
    controller_address: "",
    controller_port: "",
  });

  const [configSubData, setConfigSubData] = useState({
    sub_id: "",
    module_id: "",
    module_name: "",
    module_address: "",
    rack_id: "",
    rack_name: "",
    position_id: "",
    position_name: "",
    confirm_use: null,
    cancel_use: null,
    is_new: true,
    is_status: "add",
    is_batch: false,
  });

  const [configListData, setConfigListData] = useState({
    data: [],
    remove: [],
  });

  const [modalStatus, setModalStatus] = useState({
    status_1: false,
    mode_1: "add",
    status_2: false,
  });

  const [moduleData, setModuleData] = useState({
    dropdown: [],
  });

  const [warehouseData, setWarehouseData] = useState({
    dropdown: [],
  });

  const [rackData, setRackData] = useState({
    dropdown: [],
  });

  const [positionData, setPositionData] = useState({
    dropdown: [],
  });

  const [syncData, setSyncData] = useState({
    index_data: 0,
    module_name: "",
    module_address: "",
    rack_name: "",
    position_name: "",
  });

  const [countTime, setCountTime] = useState(10);

  const [address, setAddress] = useState({
    number: 0,
    data: [],
  });

  function paddingNumber(lastNumber) {
    const currentNumber = Number(lastNumber) + 1;
    const resultNumber = String(currentNumber).padStart(4, "0");
    return resultNumber;
  }

  async function setUpDataModal(index) {
    const temp_data = JSON.parse(JSON.stringify(configListData.data[index]));

    setConfigSubData({
      sub_id: temp_data.sub_id,
      module_id: temp_data.module_id,
      module_name: temp_data.module_name,
      module_address: temp_data.module_address,
      rack_id: temp_data.rack_id || "",
      rack_name: temp_data.rack_name,
      position_id: temp_data.position_id,
      position_name: temp_data.position_name,
      confirm_use: temp_data.confirm_use,
      cancel_use: temp_data.cancel_use,
      is_new: temp_data.is_new,
      is_status: "update",
      is_batch: temp_data.is_batch,
    });
    configSubData.sub_id = temp_data.sub_id;
    configSubData.module_id = temp_data.module_id;
    configSubData.module_name = temp_data.module_name;
    configSubData.module_address = temp_data.module_address;
    configSubData.rack_id = temp_data.rack_id || "";
    configSubData.rack_name = temp_data.rack_name;
    configSubData.position_id = temp_data.position_id;
    configSubData.position_name = temp_data.position_name;
    configSubData.confirm_use = temp_data.confirm_use;
    configSubData.cancel_use = temp_data.cancel_use;
    configSubData.is_new = temp_data.is_new;
    configSubData.is_status = "update";
    configSubData.is_batch = temp_data.is_batch;

    await GetPositionForDropDown();
  }

  async function deleteDataList(index) {
    const temp_list = JSON.parse(JSON.stringify(configListData.data));
    const remove_list = JSON.parse(JSON.stringify(configListData.remove));

    const data_list = temp_list.filter((item) => {
      return item.sub_id !== temp_list[index].sub_id;
    });
    const pop_data = temp_list.filter((item) => {
      return item.sub_id === temp_list[index].sub_id;
    });

    data_list.map((item, index) => {
      return [
        // (item.module_address = paddingNumber(index)),
        (item.is_status = "update"),
      ];
    });

    if (!pop_data[0].is_new) {
      remove_list.push(...pop_data);
    }

    setConfigListData({
      ...configListData,
      data: data_list,
      remove: remove_list,
    });
    configListData.data = data_list;
    configListData.remove = remove_list;

    await generateModuleAddress();
  }

  async function GoToPage() {
    if (mode == "Add" || mode == "Edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/IoT_Master_Data/Config_AIOI_Table";
        }
      });
    } else {
      window.location.href = "/IoT_Master_Data/Config_AIOI_Table";
    }
  }

  async function GetModuleForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/module_aioi/getModule`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setModuleData({
          dropdown: response_data.data.data,
        });
        moduleData.dropdown = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetWarehouseForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getWarehouseForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setWarehouseData({
          dropdown: response_data.data.data,
        });
        warehouseData.dropdown = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetRackForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      allData: false,
      warehouse_id: configMainData.warehouse_id || null,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getRackForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setRackData({
          dropdown: response_data.data.data,
        });
        rackData.dropdown = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetPositionForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      allData: false,
      rack_id: configSubData.rack_id || null,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getPositionForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setPositionData({
          dropdown: response_data.data.data,
        });
        positionData.dropdown = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetConfigAIOI() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      config_id: id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/config_aioi/getConfigAIOIData`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        const temp_main = response_data.data.data.main_data[0];
        const temp_sub = response_data.data.data.sub_list;

        setConfigMainData({
          ...configMainData,
          warehouse_id: temp_main.ca_wh_id,
          connect_address: temp_main.ca_connect_address,
          remark: temp_main.ca_remark,
          controller_name: temp_main.ca_con_name,
          controller_address: temp_main.ca_con_address,
          controller_port: temp_main.ca_con_port,
        });
        configMainData.warehouse_id = temp_main.ca_wh_id;
        configMainData.connect_address = temp_main.ca_connect_address;
        configMainData.remark = temp_main.ca_remark;
        configMainData.controller_name = temp_main.ca_con_name;
        configMainData.controller_address = temp_main.ca_con_address;
        configMainData.controller_port = temp_main.ca_con_port;

        const sub_list = [];
        for (let item of temp_sub) {
          sub_list.push({
            sub_id: item.cm_id,
            module_id: item.cm_module_id,
            module_name: item.module_name,
            module_address: item.cm_module_address,
            rack_id: item.cm_rack_id,
            rack_name: item.rack_name,
            position_id: item.cm_rd_id,
            position_name: item.rp_position,
            confirm_use: item.cm_is_confirm,
            cancel_use: item.cm_is_cancel,
            is_new: false,
            is_status: "add",
            is_batch: item.cm_is_batch,
          });
        }

        setConfigListData({
          ...configListData,
          data: sub_list,
        });
        configListData.data = sub_list;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function SaveConfigAIOI(is_sync) {
    const error_list = [];

    if (
      configMainData.warehouse_id === null ||
      configMainData.warehouse_id.trim() === ""
    ) {
      let temp_err = {
        message: "กรุณาเลือก Warehouse",
      };
      error_list.push(temp_err);
    }

    // if (
    //   configMainData.connect_address === null ||
    //   configMainData.connect_address.trim() === ""
    // ) {
    //   let temp_err = {
    //     message: "กรุณากรอก Connect Address",
    //   };
    //   error_list.push(temp_err);
    // }

    if (
      configMainData.controller_name === null ||
      configMainData.controller_name.trim() === ""
    ) {
      let temp_err = {
        message: "กรุณากรอก Controller Name",
      };
      error_list.push(temp_err);
    }

    if (
      configMainData.controller_address === null ||
      configMainData.controller_address.trim() === ""
    ) {
      let temp_err = {
        message: "กรุณากรอก Controller Address",
      };
      error_list.push(temp_err);
    }

    if (
      configMainData.controller_port === null ||
      configMainData.controller_port.trim() === ""
    ) {
      let temp_err = {
        message: "กรุณากรอก Connect Port",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";

      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }

      Swal.fire("กรอกข้อมูลไม่ถูกต้อง", err_message, "error");
    } else {
      Swal.fire({
        title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "บันทึก",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const sub_data_add = configListData.data.filter((item) => {
              return item.is_new == true;
            });

            const sub_data_edit = configListData.data.filter((item) => {
              return item.is_new == false && item.is_status == "update";
            });

            const send_data = {
              user_id: getUser().fup,
              company_id: getUser().com,
              oem_id: getOem(),
              config_id: id,
              main_data: configMainData,
              sub_data_add: sub_data_add,
              sub_data_edit: sub_data_edit,
              sub_data_del: configListData.remove,
            };

            let axios_data = {};

            if (mode == "Add") {
              axios_data = {
                method: "POST",
                url: `${Configs.API_URL_AIOI_Back}/api/config_aioi/addConfigAIOIData`,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: send_data,
              };
            } else if (mode == "Edit") {
              axios_data = {
                method: "POST",
                url: `${Configs.API_URL_AIOI_Back}/api/config_aioi/updateConfigAIOIData`,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: send_data,
              };
            }

            await axios(axios_data)
              .then(async (response_data) => {
                Swal.fire({
                  icon: "success",
                  text: "เสร็จสิ้น",
                  showConfirmButton: false,
                  timer: 1000,
                }).then(async () => {
                  if (!is_sync) {
                    window.location.href = "/IoT_Master_Data/Config_AIOI_Table";
                  } else {
                    await axios({
                      method: "POST",
                      url: `${Configs.API_URL_AIOI_Back}/api/config_aioi/getConfigAIOIData`,
                      headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: send_data,
                    }).then(async (response_data) => {
                      const temp_main = response_data.data.data.main_data[0];
                      setConfigMainData({
                        ...configMainData,
                        connect_address: temp_main.ca_connect_address,
                      });
                      configMainData.connect_address =
                        temp_main.ca_connect_address;
                    });
                    setSyncData({
                      ...syncData,
                      index_data: 0,
                      module_name: configListData.data[0].module_name,
                      module_address: configListData.data[0].module_address,
                      rack_name: configListData.data[0].rack_name,
                      position_name: configListData.data[0].position_name,
                    });
                    syncData.index_data = 0;
                    syncData.module_name = configListData.data[0].module_name;
                    syncData.module_address =
                      configListData.data[0].module_address;
                    syncData.rack_name = configListData.data[0].rack_name;
                    syncData.position_name =
                      configListData.data[0].position_name;

                    setCountTime(10);

                    setModalStatus({
                      ...modalStatus,
                      status_2: true,
                    });
                    modalStatus.status_2 = true;
                  }
                });
              })
              .catch(async (error) => {
                Swal.fire({
                  icon: "warning",
                  // title: "กรุณากรอกข้อมูลใหม่",
                  text: error.response.data.error.message,
                });
              });
          });
        }
      });
    }
  }

  function SetModuleTableData(index) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["module_name"] = configListData.data[index].module_name;

    rowItem["module_address"] = configListData.data[index].module_address;

    rowItem["rack"] = configListData.data[index].rack_name || "-";

    rowItem["position"] = configListData.data[index].position_name || "-";

    // rowItem["status"] =
    //   configListData.data[index].module_id ==
    //     "d5a3656b-e476-4c71-b673-80defe71efc0" ||
    //   configListData.data[index].module_id ==
    //     "75fab0c7-ac9e-4c67-aae3-afce4979c7dc"
    //     ? configListData.data[index].confirm_use !== null
    //       ? configListData.data[index].confirm_use
    //         ? "Confirm"
    //         : "Cancel"
    //       : "-"
    //     : configListData.data[index].module_id ==
    //       "efe74074-e64e-4ca7-a425-f0c33bf7ec1a"
    //     ? configListData.data[index].is_batch
    //       ? "Not Use Confirm"
    //       : "-"
    //     : "-";

    rowItem["management"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap", margin: "0 auto" }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={`view_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              await setUpDataModal(index);

              setSettingPage({
                ...settingPage,
                disableInputModal: true,
              });
              settingPage.disableInputModal = true;

              setModalStatus({
                ...modalStatus,
                status_1: true,
                mode_1: "View",
              });
              modalStatus.status_1 = true;
              modalStatus.mode_1 = "View";
            }}
          >
            <i class="fas fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={`edit_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              await setUpDataModal(index);

              setSettingPage({
                ...settingPage,
                disableInputModal: false,
              });
              settingPage.disableInputModal = false;

              setModalStatus({
                ...modalStatus,
                status_1: true,
                mode_1: "Edit",
              });
              modalStatus.status_1 = true;
              modalStatus.mode_1 = "Edit";
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={`delete_${index}`}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={async () => {
              await deleteDataList(index);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    return rowItem;
  }

  const ModuleTableData = [];
  for (let index = 0; index < configListData.data.length; index += 1) {
    ModuleTableData.push(SetModuleTableData(index));
  }

  const ModuleTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Module Name",
        field: "module_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Module Address",
        field: "module_address",
        sort: "asc",
        width: 50,
      },
      {
        label: "Rack",
        field: "rack",
        sort: "asc",
        width: 50,
      },
      {
        label: "Position",
        field: "position",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 50,
      // },
      {
        label: "Management",
        field: "management",
        sort: "asc",
        width: 50,
      },
    ],

    rows: ModuleTableData,
  };

  if (mode == "View") {
    const index = ModuleTable.columns.findIndex((e) => {
      return e.field === "management";
    });
    ModuleTable.columns.splice(index, 1);
  }
  async function sendCommandSetAddress() {
    const send_data = {
      ip: configMainData.controller_address,
      port: configMainData.controller_port,
      module_address: address.data[syncData.index_data], //syncData.module_address
    };
    await axios.request({
      method: "POST",
      url: `${configMainData.connect_address}/api/aioi_connect/set_address`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    });
  }

  async function generateModuleAddress() {
    const temp_list = JSON.parse(JSON.stringify(configListData.data));
    let count = 0;
    let address_array = [];
    for (let item of temp_list) {
      let temp_address = "";
      if (item.module_id == "655ffa0c-de7c-4ca9-8f7e-bf50419ce531") {
        let num_loop = 3;
        for (let i = 0; i < num_loop; i++) {
          if (i == num_loop - 1) {
            temp_address += paddingNumber(count);
          } else {
            temp_address += paddingNumber(count) + ",";
          }
          address_array.push(paddingNumber(count));
          count++;
        }
      } else if (item.module_id == "d0edfbbd-62ab-453f-a243-2ceec390ebb1") {
        let num_loop = 2;
        for (let i = 0; i < num_loop; i++) {
          if (i == num_loop - 1) {
            temp_address += paddingNumber(count);
          } else {
            temp_address += paddingNumber(count) + ",";
          }
          count++;
        }
        address_array.push(paddingNumber(count - 2));
      } else {
        temp_address += paddingNumber(count);
        address_array.push(paddingNumber(count));
        count++;
      }
      item.module_address = temp_address;
      item.is_status = "update";
    }

    setConfigListData({
      ...configListData,
      data: temp_list,
    });
    configListData.data = temp_list;

    setAddress({
      ...address,
      number: address_array.length,
      data: address_array,
    });
  }

  useEffect(async () => {
    if (mode == "Edit" || mode == "View") {
      await GetConfigAIOI();
    }

    if (mode == "View") {
      setSettingPage({
        ...settingPage,
        disableInputMain: true,
        disableInputModal: true,
      });
      settingPage.disableInputMain = true;
      settingPage.disableInputModal = true;
    }

    await GetModuleForDropDown();
    await GetWarehouseForDropDown();
    await GetRackForDropDown();
    await GetPositionForDropDown();
    await generateModuleAddress();
  }, []);

  useEffect(() => {
    if (modalStatus.status_2) {
      let timer = null;

      timer = setInterval(async () => {
        if (countTime > 0) {
          setCountTime(countTime - 1);
        } else {
          if (address.number == syncData.index_data + 1) {
            //configListData.data.length
            setModalStatus({
              ...modalStatus,
              status_2: false,
            });

            clearInterval(timer);

            window.location.href = "/IoT_Master_Data/Config_AIOI_Table";
          }

          let Index = configListData.data.findIndex((e) => {
            return e.module_address.includes(
              address.data[syncData.index_data + 1]
            );
          });

          let temp = JSON.parse(JSON.stringify(configListData.data[Index]));
          let address_temp = "";
          if (temp?.module_id === "d0edfbbd-62ab-453f-a243-2ceec390ebb1") {
            address_temp = temp.module_address;
          } else {
            address_temp = address.data[syncData.index_data + 1];
          }

          setSyncData({
            ...syncData,
            index_data: syncData.index_data + 1,
            module_name: configListData.data[Index].module_name,
            module_address: address_temp,
            rack_name: configListData.data[Index].rack_name,
            position_name: configListData.data[Index].position_name,
          });

          setCountTime(10);
        }

        if (countTime == 1) {
          await sendCommandSetAddress();
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [modalStatus.status_2, countTime]);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-sm-4 col-xl-6">
                <h1>Config AIOI {mode}</h1>
              </div>
              <div className="col-12 col-sm-8 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">IoT Master Data</li>
                  <li className="breadcrumb-item active">Config AIOI</li>
                  <li className="breadcrumb-item active">{mode}</li>
                </ol>
              </div>
            </div>

            <div className="row">
              {mode !== "View" ? (
                <>
                  <div className="col-6 col-md-2 col-xl-1">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-success"
                        onClick={async () => {
                          await SaveConfigAIOI(false);
                        }}
                      >
                        บันทึก
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="col-6 col-md-2 col-xl-1">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-danger"
                    onClick={async () => {
                      await GoToPage();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>

              {mode !== "View" ? (
                <>
                  <div className="col-12 col-md-3 col-xl-2">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        disabled={configListData.data.length == 0}
                        onClick={async () => {
                          await generateModuleAddress();
                          await SaveConfigAIOI(true);
                        }}
                      >
                        Sync Module
                      </button>
                    </div>
                  </div>

                  <div className="col-12 col-md">
                    <div className="form-group">
                      <label style={{ fontSize: "20px", color: "red" }}>
                        * ทุกครั้งที่มีการ เพิ่ม/ลบ/แก้ไข Controller หรือ Module
                        กรุณากด Sync Module
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    {/* <h4>Config AIOI Add</h4> */}
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <h2
                          style={{
                            fontWeight: "bolder",
                            textDecoration: "underline",
                          }}
                        >
                          Information
                        </h2>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-4">
                        <div class="form-group">
                          <Select
                            isDisabled={settingPage.disableInputMain}
                            options={warehouseData.dropdown}
                            value={warehouseData.dropdown.filter((e) => {
                              return e.value == configMainData.warehouse_id;
                            })}
                            onChange={async (e) => {
                              if (e !== null) {
                                setConfigMainData({
                                  ...configMainData,
                                  warehouse_id: e.value,
                                });
                                configMainData.warehouse_id = e.value;

                                const temp_list = JSON.parse(
                                  JSON.stringify(configListData.data)
                                );

                                temp_list.forEach((item) => {
                                  item.rack_id = "";
                                  item.rack_name = "";
                                  item.position_id = "";
                                  item.position_name = "";
                                });

                                setConfigListData({
                                  ...configListData,
                                  data: temp_list,
                                });
                                configListData.data = temp_list;

                                await GetRackForDropDown();
                              }
                            }}
                          />
                          <label>
                            Warehouse <span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true} // settingPage.disableInputMain
                            value={configMainData.connect_address}
                            onChange={async (e) => {
                              setConfigMainData({
                                ...configMainData,
                                connect_address: e.target.value,
                              });
                              configMainData.connect_address = e.target.value;
                            }}
                          />
                          <label>
                            Connect Address{" "}
                            {/* <span style={{ color: "red" }}> *</span> */}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            className="form-control"
                            style={{ fontSize: "25px" }}
                            required
                            disabled={settingPage.disableInputMain}
                            value={configMainData.remark}
                            onChange={async (e) => {
                              setConfigMainData({
                                ...configMainData,
                                remark: e.target.value,
                              });
                              configMainData.remark = e.target.value;
                            }}
                          />
                          <label>Remark</label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <h2
                          style={{
                            fontWeight: "bolder",
                            textDecoration: "underline",
                          }}
                        >
                          Controller
                        </h2>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={settingPage.disableInputMain}
                            value={configMainData.controller_name}
                            onChange={async (e) => {
                              setConfigMainData({
                                ...configMainData,
                                controller_name: e.target.value,
                              });
                              configMainData.controller_name = e.target.value;
                            }}
                          />
                          <label>
                            Controller Name{" "}
                            <span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={settingPage.disableInputMain}
                            value={configMainData.controller_address}
                            onChange={async (e) => {
                              setConfigMainData({
                                ...configMainData,
                                controller_address: e.target.value,
                              });
                              configMainData.controller_address =
                                e.target.value;
                            }}
                          />
                          <label>
                            Controller Address{" "}
                            <span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={settingPage.disableInputMain}
                            value={configMainData.controller_port}
                            onChange={(e) => {
                              setConfigMainData({
                                ...configMainData,
                                controller_port: e.target.value,
                              });
                              configMainData.controller_port = e.target.value;
                            }}
                          />
                          <label>
                            Controller Port{" "}
                            <span style={{ color: "red" }}> *</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <h2
                          style={{
                            fontWeight: "bolder",
                            textDecoration: "underline",
                          }}
                        >
                          Module
                        </h2>
                      </div>
                    </div>

                    {mode == "Add" || mode == "Edit" ? (
                      <>
                        <div className="row">
                          <div className="col-6 col-md-4 col-xl-2">
                            <div className="form-group">
                              <button
                                id="btn_add"
                                className="btn btn-block btn-primary"
                                type="button"
                                onClick={() => {
                                  setPositionData({
                                    dropdown: [],
                                  });
                                  positionData.dropdown = [];

                                  setConfigSubData({
                                    sub_id: "",
                                    module_id: "",
                                    module_name: "",
                                    module_address: "",
                                    rack_id: "",
                                    rack_name: "",
                                    position_id: "",
                                    position_name: "",
                                    confirm_use: null,
                                    is_new: true,
                                    is_status: "add",
                                    is_batch: false,
                                  });

                                  setSettingPage({
                                    ...settingPage,
                                    disableInputModal: false,
                                  });
                                  settingPage.disableInputModal = false;

                                  setModalStatus({
                                    ...modalStatus,
                                    status_1: true,
                                    mode_1: "Add",
                                  });
                                  modalStatus.status_1 = true;
                                  modalStatus.mode_1 = "Add";
                                }}
                              >
                                Add Config Module
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div className="table-responsive">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={true}
                            data={ModuleTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          size="sm"
          className="Modal_Center_LeftRight"
          isOpen={modalStatus.status_1}
          toggle={async () => {
            setModalStatus({
              ...modalStatus,
              status_1: false,
            });
            modalStatus.status_1 = false;
          }}
        >
          <ModalHeader
            toggle={async () => {
              setModalStatus({
                ...modalStatus,
                status_1: false,
              });
              modalStatus.status_1 = false;
            }}
          >
            <h4 className="modal-title" id="modalDelivery">
              <b>{modalStatus.mode_1} Config Module</b>
            </h4>
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    isDisabled={settingPage.disableInputModal}
                    options={moduleData.dropdown}
                    value={moduleData.dropdown.filter((e) => {
                      return e.value == configSubData.module_id;
                    })}
                    onChange={(e) => {
                      if (e !== null) {
                        setConfigSubData({
                          ...configSubData,
                          module_id: e.value,
                          module_name: e.label,
                          rack_id: "",
                          rack_name: "",
                          position_id: "",
                          position_name: "",
                          confirm_use: null,
                          is_batch: false,
                        });
                        configSubData.module_id = e.value;
                        configSubData.module_name = e.label;
                        configSubData.rack_id = "";
                        configSubData.rack_name = "";
                        configSubData.position_id = "";
                        configSubData.position_name = "";
                        configSubData.confirm_use = null;
                        configSubData.is_batch = false;
                      }
                    }}
                  />
                  <label>
                    Module Name <span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    isClearable
                    isDisabled={
                      settingPage.disableInputModal ||
                      configSubData.confirm_use ||
                      configSubData.cancel_use
                    }
                    options={rackData.dropdown}
                    value={rackData.dropdown.filter((e) => {
                      return e.value == configSubData.rack_id;
                    })}
                    onChange={async (e) => {
                      if (e !== null) {
                        setConfigSubData({
                          ...configSubData,
                          rack_id: e.value,
                          rack_name: e.label,
                          position_id: "",
                          position_name: "",
                        });
                        configSubData.rack_id = e.value;
                        configSubData.rack_name = e.label;
                        configSubData.position_id = "";
                        configSubData.position_name = "";
                      } else {
                        setConfigSubData({
                          ...configSubData,
                          rack_id: "",
                          rack_name: "",
                          position_id: "",
                          position_name: "",
                        });
                        configSubData.rack_id = "";
                        configSubData.rack_name = "";
                        configSubData.position_id = "";
                        configSubData.position_name = "";
                      }

                      await GetPositionForDropDown();
                    }}
                  />
                  <label>
                    Rack
                    {(configSubData.module_id ==
                      "d5a3656b-e476-4c71-b673-80defe71efc0" ||
                      configSubData.module_id ==
                        "75fab0c7-ac9e-4c67-aae3-afce4979c7dc") &&
                    configSubData.rack_id === "" &&
                    configSubData.confirm_use === null ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : (
                      <></>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    isClearable
                    isDisabled={
                      settingPage.disableInputModal ||
                      configSubData.confirm_use ||
                      configSubData.cancel_use
                    }
                    options={positionData.dropdown}
                    value={positionData.dropdown.filter((e) => {
                      return e.value == configSubData.position_id;
                    })}
                    onChange={(e) => {
                      if (e !== null) {
                        setConfigSubData({
                          ...configSubData,
                          position_id: e.value,
                          position_name: e.label,
                        });
                        configSubData.position_id = e.value;
                        configSubData.position_name = e.label;
                      } else {
                        setConfigSubData({
                          ...configSubData,
                          position_id: "",
                          position_name: "",
                        });
                        configSubData.position_id = "";
                        configSubData.position_name = "";
                      }
                    }}
                  />
                  <label>Position</label>
                </div>
              </div>
            </div>

            {/* {configSubData.module_id ==
              "d5a3656b-e476-4c71-b673-80defe71efc0" ||
            configSubData.module_id ==
              "75fab0c7-ac9e-4c67-aae3-afce4979c7dc" ? (
              <>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ marginLeft: "5px", alignSelf: "center" }}
                  >
                    <div className="form-check" style={{ height: "38px" }}>
                      <input
                        id="radio_confirm"
                        disabled={
                          settingPage.disableInputModal ||
                          configSubData.rack_id !== ""
                        }
                        type="radio"
                        required
                        value={true}
                        checked={configSubData.confirm_use}
                        onChange={(e) => {
                          setConfigSubData({
                            ...configSubData,
                            confirm_use: true,
                            cancel_use: false,
                            rack_id: "",
                            rack_name: "",
                            position_id: "",
                            position_name: "",
                          });
                          configSubData.confirm_use = true;
                          configSubData.cancel_use = false;
                          configSubData.rack_id = "";
                          configSubData.rack_name = "";
                          configSubData.position_id = "";
                          configSubData.position_name = "";
                        }}
                      />
                      <label htmlFor="radio_confirm"> Confirm</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ marginLeft: "5px", alignSelf: "center" }}
                  >
                    <div className="form-check" style={{ height: "38px" }}>
                      <input
                        id="radio_cancel"
                        disabled={
                          settingPage.disableInputModal ||
                          configSubData.rack_id !== ""
                        }
                        type="radio"
                        required
                        value={false}
                        checked={configSubData.cancel_use}
                        onChange={(e) => {
                          setConfigSubData({
                            ...configSubData,
                            confirm_use: false,
                            cancel_use: true,
                            rack_id: "",
                            rack_name: "",
                            position_id: "",
                            position_name: "",
                          });
                          configSubData.confirm_use = false;
                          configSubData.cancel_use = true;
                          configSubData.rack_id = "";
                          configSubData.rack_name = "";
                          configSubData.position_id = "";
                          configSubData.position_name = "";
                        }}
                      />
                      <label htmlFor="radio_cancel"> Cancel</label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {configSubData.module_id ==
            "efe74074-e64e-4ca7-a425-f0c33bf7ec1a" ? (
              <>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ marginLeft: "5px", alignSelf: "center" }}
                  >
                    <div className="form-check" style={{ height: "38px" }}>
                      <input
                        id="checkbox_no_confirm"
                        disabled={settingPage.disableInputModal}
                        className="form-check-input"
                        type="checkbox"
                        required
                        checked={configSubData.is_batch}
                        onChange={(e) => {
                          setConfigSubData({
                            ...configSubData,
                            is_batch: e.target.checked,
                          });
                          configSubData.is_batch = e.target.checked;
                        }}
                      />
                      <label htmlFor="checkbox_no_confirm">
                        Not Use Confirm
                      </label>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )} */}
          </ModalBody>

          <ModalFooter>
            {modalStatus.mode_1 == "Add" || modalStatus.mode_1 == "Edit" ? (
              <>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={async () => {
                    const error_list = [];

                    if (
                      configSubData.module_id === null ||
                      configSubData.module_id.trim() === ""
                    ) {
                      let temp_err = {
                        message: "กรุณาเลือก Module",
                      };
                      error_list.push(temp_err);
                    } else {
                      if (
                        configSubData.module_id ==
                          "d5a3656b-e476-4c71-b673-80defe71efc0" ||
                        configSubData.module_id ==
                          "75fab0c7-ac9e-4c67-aae3-afce4979c7dc"
                      ) {
                        if (
                          configSubData.rack_id === null &&
                          configSubData.confirm_use === null
                        ) {
                          let temp_err = {
                            message:
                              "กรุณาเลือก Rack หรือ Confirm หรือ Cancel อย่างใดอย่างหนึ่ง",
                          };
                          error_list.push(temp_err);
                        }
                      }
                    }

                    if (error_list.length > 0) {
                      var err_message = "";

                      for (var e = 0; e < error_list.length; e++) {
                        err_message += "<br/>" + error_list[e].message;
                      }

                      Swal.fire("Error", err_message, "error");
                    } else {
                      const temp_list = JSON.parse(
                        JSON.stringify(configListData)
                      );

                      if (configSubData.is_status == "add") {
                        let sub_id_data = "";

                        const sub_id_List = configListData.data.filter(
                          (item) => {
                            return item.sub_id.includes("sub_");
                          }
                        );

                        if (sub_id_List.length == 0) {
                          sub_id_data = "sub_0";
                        } else {
                          sub_id_data = `sub_${
                            Number(sub_id_List.at(-1).sub_id.substring(4)) + 1
                          }`;
                        }
                        setConfigSubData({
                          ...configSubData,
                          sub_id: sub_id_data,
                          // module_address: paddingNumber(
                          //   temp_list.data.length
                          // ),
                        });
                        configSubData.sub_id = sub_id_data;
                        // configSubData.module_address = paddingNumber(
                        //   temp_list.data.length
                        // );

                        const temp_sub_data = JSON.parse(
                          JSON.stringify(configSubData)
                        );

                        temp_list.data.push(temp_sub_data);
                      } else {
                        const temp_sub_data = JSON.parse(
                          JSON.stringify(configSubData)
                        );
                        const index_data = temp_list.data.findIndex((item) => {
                          return item.sub_id === temp_sub_data.sub_id;
                        });

                        temp_list.data[index_data] = temp_sub_data;
                      }

                      setConfigListData({
                        ...configListData,
                        data: temp_list.data,
                      });
                      configListData.data = temp_list.data;

                      await generateModuleAddress();

                      setModalStatus({
                        ...modalStatus,
                        status_1: false,
                      });
                      modalStatus.status_1 = false;
                    }
                  }}
                >
                  บันทึก
                </button>
              </>
            ) : (
              <></>
            )}

            <button
              type="button"
              className="btn btn-danger"
              onClick={async () => {
                if (
                  modalStatus.mode_1 == "Add" ||
                  modalStatus.mode_1 == "Edit"
                ) {
                  Swal.fire({
                    title: "คุณมั่นใจว่าจะออกจากหน้านี้ใช่หรือไม่",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: `ใช่`,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: `ไม่`,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setModalStatus({
                        ...modalStatus,
                        status_1: false,
                      });
                      modalStatus.status_1 = false;
                    }
                  });
                } else {
                  setModalStatus({
                    ...modalStatus,
                    status_1: false,
                  });
                  modalStatus.status_1 = false;
                }
              }}
            >
              ยกเลิก
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          size="sm"
          className="Modal_Center_LeftRight"
          isOpen={modalStatus.status_2}
          // toggle={async () => {
          //   setModalStatus({
          //     ...modalStatus,
          //     status_2: false
          //   });
          //   modalStatus.status_2 = false;
          // }}
        >
          <ModalHeader
          // toggle={async () => {
          //   setModalStatus({
          //     ...modalStatus,
          //     status_2: false,
          //   });
          //   modalStatus.status_2 = false;
          // }}
          >
            <h4 className="modal-title" id="modalDelivery">
              <b>Sync Module</b>
            </h4>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <label>Module : {syncData.module_name}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label>Address : {syncData.module_address}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label>Rack : {syncData.rack_name || "-"}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <label>Position : {syncData.position_name || "-"}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <labelc style={{ fontSize: "30px" }}>
                  CountDown : {countTime}
                </labelc>
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Config_AIOI_AEV;
