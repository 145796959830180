import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { dashboard } from "..";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import moment from "moment";
import TableLEK from "../../component/tableLEKTTT/table";


import { useParams } from "react-router";
import { Progress, Tooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";




function External_Data_CTS(params) {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const toggle_modal2 = () => setModal2(!modal2);
  const toggle_modal3 = () => setModal3(!modal3);
  const [get_list_external_data, set_list_external_data] = useState([]);

  const [search, setSearch] = useState(0);
  const [countData, setCountData] = useState(0);
  const [countPage, setCountPage] = useState(1);
  const [entries, setEntries] = useState(10);

  const [template_stats_text, set_template_stats_text] = useState("");
  const [cltext, setcltext] = useState("");
  const [externaldata, setexternaldata] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    data_list: "",
  });
  const [test, settest] = useState({
    test: '',
  });

  const rowsData = [];

  const [type_select, set_type_select] = useState({
    type: '',
  })
  const [filterData,setFilterData] = useState({
    all_search:''
  })

  const [template_name, set_template_name] = useState("");
  const [exlx_detail_view, set_exlx_detail_view] = useState([]);
  const [exlx_detail_status, set_exlx__detail_status] = useState({
    success: "",
    not_success: "",
  });



  const clearFilter = async () => {
    await setFilterData({
      ...filterData,
      all_search: ""
    });
    filterData.all_search = "";
    getexternaldate(1,10,'search');
  };



  const upload_file_exlx = async (e) => {
    if (type_select.type !== '') {
      if (e.target.files[0]) {

        var data = new FormData();
        data.append("file", e.target.files[0]);
        data.append("file_name", e.target.files[0].name);

        data.append("oem_id", getOem());
        data.append("company_id", getUser().com);
        data.append("type_select", type_select.type);
        console.log("list", data);
        /*   var list_data_file = {
            oem_id: getOem(),
            company_id: getUser().com,
            file_target: data ,
           
          }
          console.log("list2",list_data_file); */
        Swal.fire({
          title: "กำลังดำเนินการ",
          text: "ระบบอยู่ในระหว่างการดำเนินการ",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });



        axios({
          method: "post",
          url: Configs.API_URL + "/api/externalData/importForecastFiles",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(function (response) {
            //  Swal.fire("Success", response.data, "info");
            //  document.getElementById("import_external_file_input").value = ""; 
            //  getexternaldate(1,10,'search');
            console.log("ดาต้า", response.data);

            if (response.data) {
              var check = response.data.data.filter((e) => { return e.status === false });
              var check2 = response.data.data.filter((e) => { return e.status === true });

              set_exlx__detail_status({ ...exlx_detail_status, success: check2.length, not_success: check.length });
              // set_template_name("ทดสอบโมเดว รอดาต้าจริง");
              setcltext("green");
              set_template_stats_text("ตรง Template");
              set_exlx_detail_view(response.data.data); //เปิดเมื่อดาต้าพร้อม
              set_template_name(response.data.template_result)
              Swal.close();
              set_type_select({ ...type_select, type: '' });
              type_select.type = '';

              Swal.fire(
                "อัพโหลด ไฟล์ สำเร็จ",
                "ไฟล์ ได้ถูกอัพโหลดเข้าฐานข้อมูลเรียบร้อย",
                "susccess"
              ).then((result) => {

                if (response.data.template_result === "purchase_order_excel_skt" || response.data.template_result === "PO-SKT template") {
                  toggle_modal2();
                } else {
                  toggle_modal3();
                }

                // toggle_modal3();


              });
            }
            document.getElementById("import_external_file_input").value = "";

            getexternaldate(1, 10, 'search');
          })
          .catch(function (error) {
            Swal.close();
            set_template_stats_text("ไม่ตรง Template");
            setcltext("red");
            console.log(error);
            Swal.fire("Error", "ไฟล์ที่อัปโหลดไม่ตรง Template กรุณาลองใหม่"/* error.response.data.message */, "error");
            document.getElementById("import_external_file_input").value = "";
            /* getexternaldate(1,10,'search'); */
          });


      }

    }
    else {
      Swal.close();

      Swal.fire("Error", "โปรดเลือกประเภทการอัพโหลดของ Forecast", "error");
      document.getElementById("import_external_file_input").value = "";
    }
  }

  // console.log("list",list_data_file);

  useEffect(() => {
    getexternaldate(1, 10, 'search');

  }, []);

  //console.log("statd",set_exlx_detail_view);

  const getexternaldate = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }

    var get_log_external_data_list = {
      oem_id: getOem(),
      company_id: getUser().com,
      page: page || 1,
      size: size || 10,
      all_search: (filterData.all_search || "").trim()

    }

    console.log(get_log_external_data_list);

    await axios({
      method: "post",
      url: Configs.API_URL + "/api/externalData/getExternalDataLog_",
      /*  url: Configs.API_URL + "/api/externalData/getExternalDataLog", */
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_log_external_data_list,
    })
      .then(function (response) {
        set_list_external_data(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);

        console.log("ไฟล์", response.data);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("ErrorStatus", error, "errorstatus");
      });
    Swal.close();

  };



  for (let index = 0; index < get_list_external_data.length; index++) {
    const rowItem = {}
    /*  rowItem["no"] = index + 1; */
    rowItem["template"] = get_list_external_data[index].external_name;
    rowItem["upload Date"] = moment(get_list_external_data[index].create_date).format('DD/MM/yyyy , HH:mm');
    rowItem["Status"] = get_list_external_data[index].status_upload;
    rowItem["type"] = get_list_external_data[index].external_type || '';
    rowItem["user"] = get_list_external_data[index].create_by;
    rowsData.push(rowItem);
  }


  const [headTable, setheadTable] = useState(
    [

      {
        label: "Template",
        field: "template",
        sort: "asc",
        width: 50,
      },
      {
        label: "Upload Date",
        field: "upload Date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 50,
      },
      {
        label: "User",
        field: "user",
        sort: "asc",
        width: 50,
      },
    ]
  );


  const data = {
    columns: [
      {
        label: "NO.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Template",
        field: "template",
        sort: "asc",
        width: 50,
      },
      {
        label: "Upload Date",
        field: "upload Date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 50,
      },
      {
        label: "User",
        field: "user",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };


  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>External Data{"  "}<a target="_blank" href={getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">External Data</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">External Data</h3>
            </div>

            <div className="card-body">
            <div className="row mb-2">
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterData.all_search}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        all_search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      getexternaldate(1,10,'search')
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
            
              
              
    



            </div>
              <div className="row">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-5 col-md-5">
                  <div className="none-nolmalinput">
                    <div className="row">
                      <div className="row">

                      </div>
                      <div className="col-12">
                        <label htmlFor="">External file:</label>
                        <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small>
                        <div className="input-group">
                          <div className="custom-file">

                            <input
                              // onClick={clickclear}
                              type="file"
                              required
                              //disabled={externaldata.template === "" || externaldata.template === "ค่าซ่อม.xlsx"  ? true : false}
                              className="custom-file-input"
                              id="import_external_file_input"
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={upload_file_exlx}

                            />
                            <label
                              className="custom-file-label"
                              htmlFor="import_external_file_input"
                            >
                              {externaldata.file_name !== null &&
                                externaldata.file_name !== "" &&
                                externaldata.file_name !== undefined
                                ? externaldata.file_name
                                : "Select File"}
                            </label>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  <br />

                </div>
                <div className="col-5 ">
                  <br />

                  <div className="row">
                    <div>
                      {/* <a onClick={setstatus_template}>       */}
                      <a >
                        <h4>
                          Template Check : <span style={{ color: cltext }} >{template_stats_text}</span>
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>

              </div>

              <div className="col-12">
                <div className="select-check">
                  <div className="row">
                    <div className="col-6 col-md-3 col-xl-2">
                      <input type="radio" className="mt-1" id="new_forecast" name="type_select" checked={type_select.type == 'New Forecast' ? true : false} onClick={() => { set_type_select({ ...type_select, type: 'New Forecast' }) }} />
                      <label className="ml-2" for="new_forecast">New Forecast</label>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2">
                      <input type="radio" className="mt-1" id="update_forecast" name="type_select" checked={type_select.type == 'Update Forecast' ? true : false} onClick={() => { set_type_select({ ...type_select, type: 'Update Forecast' }) }} />
                      <label className="ml-2" for="update_forecast">Update Forecast</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">

                <div
                  className="table-responsive"
                >

                  <TableLEK


                    headTable={headTable}
                    dataTable={rowsData}
                    countPage={countPage}
                    countData={countData}
                    entries={entries}
                    isSearch={search}
                    callFnc={getexternaldate}


                  />


                  {/*   <MDBDataTable
                    className="table table-head-fixed"
                    table
                    sortable = {false}
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={data}
                  /> */}
                </div>

                <div
                  className="table-responsive" style={{ display: "none" }}
                >
                  <MDBDataTable
                    className="table table-head-fixed"
                    table
                    sortable={false}
                    id="table-to-xls"
                    entries={4096}
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={data}
                  />
                </div>

              </div>

            </div>
          </div>
        </section>



        <Modal isOpen={modal2} toggle={toggle_modal2} className="modal-dialog modal-xl">

          <ModalHeader toggle={toggle_modal2}>

            <h1>รายการ {template_name}  ::: [ <font color="green">สำเร็จ {exlx_detail_status.success}</font> / <font color="red"> ไม่สำเร็จ {exlx_detail_status.not_success}</font>]</h1>
          </ModalHeader>
          <ModalBody>
            <div className="row">

              <div className="col-4">
                <label>ตารางแสดงสถานะการอัพเดต {template_name}</label>
              </div>
            </div>
            <div className="table-responsive" style={{ height: "500px" }}>
              <MDBTable striped hover bordered small searching={true}>
                <MDBTableHead>
                  <tr>
                    <th>No.</th>
                    <th>PO number</th>
                    <th>Item number</th>
                    <th>Qty ordered</th>
                    <th>Item description</th>
                    <th>Unit price</th>
                    <th>Delivery date</th>
                    <th>status</th>
                    <th>หมายเหตุ</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {exlx_detail_view.map((el, index) => {
                    var color;
                    var text_status;
                    if (el.status === false) {
                      color = "red";
                      text_status = "ไม่สำเร็จ";
                    } else {
                      color = "green";
                      text_status = "สำเร็จ";
                    }
                    return (
                      <tr id={index}>
                        <td><font color={color}>{index + 1}</font></td>
                        <td><font color={color}>{el.po_number}</font></td>
                        <td><font color={color}>{el.item_number}</font></td>
                        <td><font color={color}>{el.qty_order}</font></td>
                        <td><font color={color}>{el.item_description}</font></td>
                        <td><font color={color}>{el.unit_price}</font></td>
                        <td><font color={color}>{el.delivery_date}</font></td>
                        <td><font color={color}>{text_status}</font></td>
                        <td><font color={color}>{el.remark}</font></td>
                      </tr>
                    );
                  })
                  }

                </MDBTableBody>
              </MDBTable>
            </div>
          </ModalBody>
          <ModalFooter>
            {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle_modal2}>
              Close
            </Button>
          </ModalFooter>
        </Modal>





        <Modal isOpen={modal3} toggle={toggle_modal3} className="modal-dialog modal-xl">

          <ModalHeader toggle={toggle_modal3}>


            <h1>รายการ {template_name}  ::: [ <font color="green">สำเร็จ {exlx_detail_status.success}</font> / <font color="red"> ไม่สำเร็จ {exlx_detail_status.not_success}</font>]</h1> </ModalHeader>
          <ModalBody>
            <div className="row">

              <div className="col-4">
                <label>ตารางแสดงสถานะการอัพเดต {template_name} </label>
              </div>
            </div>
            <div className="table-responsive" style={{ height: "500px" }}>
              <MDBTable striped hover bordered small searching={true}>
                <MDBTableHead>
                  <tr>
                    <th>No.</th>
                    <th>Product No.</th>
                    <th>Product Name</th>
                    <th>n1</th>
                    <th>n2</th>
                    <th>n3</th>
                    <th>n4</th>
                    <th>status</th>
                    <th>หมายเหตุ</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {exlx_detail_view.map((el, index) => {

                    var color;
                    var text_status;
                    if (el.status === false) {
                      color = "red";
                      text_status = "ไม่สำเร็จ";
                    } else {
                      color = "green";
                      text_status = "สำเร็จ";
                    }
                    return (
                      <tr id={index}>
                        <td><font color={color}>{index + 1}</font></td>
                        <td><font color={color}>{el.product_no}</font></td>
                        <td><font color={color}>{el.product_name}</font></td>
                        <td><font color={color}>{el.n1}</font></td>
                        <td><font color={color}>{el.n2}</font></td>
                        <td><font color={color}>{el.n3}</font></td>
                        <td><font color={color}>{el.n4}</font></td>
                        <td><font color={color}>{text_status}</font></td>
                        <td><font color={color}>{el.remark}</font></td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>
          </ModalBody>
          <ModalFooter>
            {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle_modal3}>
              Close
            </Button>
          </ModalFooter>
        </Modal>




      </div>
    </div>
  );
};
export default External_Data_CTS;
