import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import { MultiSelect } from "react-multi-select-component";
import {
  getComConfig,
  getOem,
  getToken,
  getUser,
} from "../../../../Utils/Common";
import "../../../../../src/assets/css/Quotation_CutePlus.css";
import Quotation_report from "../Quotation_Admin/Component/Quotation_report";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Select from "react-select";

function Quotation_Table() {
  const [Quotation_Table, setQuotation_Table] = useState({
    data: [],
  });

  const [quotation_history, setQuotation_history] = useState({
    data: [],
    product: [],
    detail: [],
    pic: [],
  });

  const [historyQuotation_Table, setHistoryQuotation_Table] = useState({
    data: [],
  });

  const [Product, setProduct] = useState({
    data: [],
  });
  /* const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
  ]; */
  const [optionsStatus, setOptionsStatus] = useState({
    data: [],
  });
  let [status_list_filter, setStatus_list_filter] = useState([]);

  const [company, setcompany] = useState();
  /* const [status_list_filter, setStatus_list_filter] = useState({
    data: []
  }); */

  const [customerDD, setCustomerDD] = useState([]);
  const [Filter, setFilter] = useState({
    quot_No: "",
    customer_name: "",
    status: [],
  });
  const [ModalNotApprove, setModalNotApprove] = useState(false)
  const [notApproveRemark, setNotApproveRemark] = useState({
    index: null,
    remark: null,
  })
  const [isReadNotApproveRemark, setisReadNotApproveRemark] = useState(false)

  useEffect(async () => {
    await getDropdownStatus();
    await FilterQuotSales();
    await getCustomerDD();
    await getReport();
    await GetProduct();
  }, []);
  const [test, setTest] = useState({
    test: "",
  });

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  const clearSearch = async () => {
    setStatus_list_filter(() => []);
    status_list_filter = [];

    setFilter({
      quot_No: "",
      customer_name: "",
      status: [],
    });
    Filter.quot_No = "";
    Filter.customer_name = "";
    Filter.status = [];
    await FilterQuotSales();
  };

  const GetProduct = async () => {
    let temp = {
      code: null,
      name: null,
      category: null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWherecat2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log('response', response.data)
        if (response) {
          setProduct({ data: response.data });
          Product.data = response.data;
          /////////////////////////////////////
          let data = new FormData();
          data.append("company_id", getUser().com);
          data.append("oem_id", getOem());
          data.append("user_id", getUser().fup);
          data.append("products", JSON.stringify(response.data));

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "multipart/form-data",
            },
            data: data,
          }).then(async (response) => {
            // console.log('first', response.data)
            setProduct({ data: response.data });
            Product.data = response.data;
            return response.data;
          });
          // .catch(async (error) => {
          //   Swal.fire({
          //     icon: "error",
          //     title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
          //   });
          // });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getCustomerDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        const cus_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        cus_dd.unshift({
          label: "--Select--",
          value: ""
        })
        setCustomerDD(cus_dd);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function FilterQuotSales() {
    // console.log('status_list_filter.length', status_list_filter.length);
    let data = {
      user_id: getUser().fup,
      quot_No: Filter.quot_No == "" ? null : Filter.quot_No.trim(),
      customer_name: Filter.customer_name == "" ? null : Filter.customer_name,
      status: status_list_filter.length === 0 ? null : status_list_filter,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/FilterQuotataionSales",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        // setQuotationAdmin(response.data);
        setQuotation_Table({
          ...Quotation_Table,
          data: response.data,
        });
        Quotation_Table.data = response.data;
        // console.log('data :>> ', response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getDropdownStatus = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotationStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: { data: "" },
    })
      .then(async function (response) {
        const options = [];
        for (let item of response.data) {
          // console.log('item', item);
          let temp = {
            label: item.qs_name,
            value: item.qs_id,
          };
          options.push(temp);
        }
        // console.log('options', options);
        setOptionsStatus({ ...optionsStatus, data: options });
        optionsStatus.data = options;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getReport() {
    axios(config_com)
      .then(function (response) {
        setcompany(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  async function getQuotHistoryData(arr) {
    const hisList = [...arr];
    const maxIndex = arr.reduce(
      (acc, cur, index) => (cur.hq_version > arr[acc].hq_version ? index : acc),
      0
    );
    let temp = {
      hq_id: hisList[maxIndex].hq_id,
      company_id: getUser().com,
      oem_id: getOem(),
    }
    // console.log('hq_id :>> ', temp.hq_id);
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotHistoryReport",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        // console.log('response.data707 :>> ', response.data);
        setQuotation_history({
          ...quotation_history,
          data: response.data.result1,
          product: response.data.result2,
        });
        quotation_history.data = response.data.result1;
        quotation_history.product = response.data.result2;

        const getValidDate = new Date(quotation_history.data[0].hq_valid_unit);
        getValidDate.toISOString().slice(0, 10);
        let day = getValidDate.getDate();
        let month = getValidDate.getMonth() + 1;
        let year = getValidDate.getFullYear();
        let endDate = `${day.toString().padStart(2, "0")}/${month
          .toString()
          .padStart(2, "0")}/${year}`;
        quotation_history.data[0].hq_valid_unit = endDate;

        let newDetails = [];
        for (let i = 0; i < quotation_history.product.length; i++) {
          let temp = {
            product_id: quotation_history.product[i].hqp_product_id,
            company_id: getUser().com,
            oem_id: getOem(),
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus +
              "/api/product/GetProductDetailsForQuotAdmin",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: temp,
          })
            .then((response) => {
              newDetails.push(response.data[0]);
              // console.log('.quot', quotation_history)
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
        // console.log('product', newDetails);
        setQuotation_history({ ...quotation_history, detail: newDetails });
        quotation_history.detail = newDetails;
        // console.log('quot', Product.data)
        quotation_history.product?.map((el) => {
          const check = Product.data.find(
            (tl) => tl.product_id === el.hqp_product_id
          );
          // console.log('check', check)
          if (check) {
            el.path_image = check.pci_img_path;
            el.name_image = check.pci_img_name;
            // console.log('path', el)
            quotation_history.pic.push(el);

            return el;
          }
          // quotation_history.pic.push(el)
          // console.log('Pic', quotation_history.pic)
        });
      })
      .then(async (response) => {
        let tempData = {
          company_id: getUser().com,
          oem_id: getOem(),
          // user_id: getUser().fup,
        };
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/customer/getPaymentTerm",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(async (response) => {
            setQuotation_history({
              ...quotation_history,
              term: response.data
                ? response.data.filter(
                    (el) => el.pt_id === quotation_history.data[0].hq_pt_id
                  )
                : [],
            });
            quotation_history.term = response.data
              ? response.data.filter(
                  (el) => el.pt_id === quotation_history.data[0].hq_pt_id
                )
              : [];
            if (quotation_history.product.length > 0) {
              Quotation_report(company, quotation_history, getComConfig());
            } else {
              Swal.fire({
                title: "ไม่สามารถออก Report ที่ไม่มี Product ได้",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch(function (error) {
            console.log("error", error);
          });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const rowsData = [];
  for (let index = 0; index < Quotation_Table.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    // console.log('Quotation_Table.data[index].history_versions', Quotation_Table.data[index].history_versions);
    rowItem["quo_no"] = (
      <>
        {Quotation_Table.data[index].history_versions.length > 0 ? (
          <a
            className=""
            style={{ fontSize: "1.2rem", color: "blue", cursor: "pointer" }}
            data-toggle="modal"
            data-target="#modalQuotationCustomer"
            onClick={() => {
              setHistoryQuotation_Table({
                ...historyQuotation_Table,
                data: Quotation_Table.data[index].history_versions,
              });
              historyQuotation_Table.data =
                Quotation_Table.data[index].history_versions;
            }}
          >
            <u>{Quotation_Table.data[index].quo_no}</u>
          </a>
        ) : (
          Quotation_Table.data[index].quo_no
        )}
      </>
    );
    //  Quotation_Table.data[index].quo_no

    rowItem["quo_cus_name"] = Quotation_Table.data[index].quo_cus_name;

    rowItem["quo_total_price"] = (
      <div style={{ textAlign: "end" }}>
        {parseFloat(Quotation_Table.data[index].quo_total_price)
          ?.toFixed(2)
          ?.toString()
          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </div>
    );

    rowItem["sales_name"] = Quotation_Table.data[index].quo_created_name || "-";

    rowItem["approved_name"] =
      Quotation_Table.data[index].quo_approve_user_name || "-";

    rowItem["status"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          type="button"
          disabled={Quotation_Table.data[index].qs_name === "Not Approve" ? false : true}
          style={{
            opacity: "1",
            width: "100%",
            border: "4px solid",
            borderRadius: "10px",
            paddingTop: ".2rem",
            paddingBottom: ".2rem",
          }}
          className={`btn btn-outline-${
            Quotation_Table.data[index].qs_name === "Cancel"
              ? "dark"
              : Quotation_Table.data[index].qs_name === "Success"
              ? "success"
              : Quotation_Table.data[index].qs_name === "Approved"
              ? "primary"
              : Quotation_Table.data[index].qs_name === "Not Approve"
              ? "danger"
              : "warning"
          }`}
          onClick={() => {
            setisReadNotApproveRemark(true)
            setNotApproveRemark({
              ...notApproveRemark,
              index: index,
              remark: Quotation_Table.data[index].quo_not_approve,
            })
            notApproveRemark.index = index;
            notApproveRemark.remark = Quotation_Table.data[index].quo_not_approve;
            setModalNotApprove(true)
          }}
        >
          {Quotation_Table.data[index].qs_name}
        </button>
      </div>
    );

    rowItem["report"] = (
      <>
        <div>
          <button
            disabled={
              Quotation_Table.data[index].qs_name === "Pending" ||
              Quotation_Table.data[index].qs_name === "Not Approve" ||
              Quotation_Table.data[index].qs_name === "Cancel"
                ? true
                : false
            }
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              Swal.fire({
                title: "คุณต้องการจะออก Report นี้ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  getQuotHistoryData(
                    Quotation_Table.data[index].history_versions
                  );
                  setQuotation_history({ ...quotation_history, pic: [] });
                  quotation_history.pic = [];
                }
              });
            }}
          >
            Report
          </button>
        </div>
      </>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Quotation_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/View/" +
                Quotation_Table.data[index].quo_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 "
          hidden={
            Quotation_Table.data[index].qs_name === "Success" ||
            Quotation_Table.data[index].qs_name === "Cancel" ||
            Quotation_Table.data[index].quo_sent_approve === true
              ? true
              : false
          } /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <a
            key={Quotation_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/Edit/" +
                Quotation_Table.data[index].quo_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const Quotation_data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Quotation No.",
        field: "quo_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "quo_cus_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Price",
        field: "quo_total_price",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Sales Name",
        field: "sales_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Approved Name",
        field: "approved_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const rowsHis = [];
  for (let index = 0; index < historyQuotation_Table.data.length; index += 1) {
    const rowItem = {};

    rowItem["his_no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["his_quo_no"] = historyQuotation_Table.data[index].hq_no;

    rowItem["his_version"] = (
      <div style={{ textAlign: "end" }}>
        {historyQuotation_Table.data[index].hq_version}
      </div>
    );

    rowItem["his_management"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={historyQuotation_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/ViewHistory/" +
                historyQuotation_Table.data[index].hq_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
      </div>
    );

    rowsHis.push(rowItem);
  }

  const history_data = {
    columns: [
      {
        label: "No.",
        field: "his_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Quotations No.",
        field: "his_quo_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Quotations Version",
        field: "his_version",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "his_management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsHis,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Quotation (Sales)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sale</li>
                  <li className="breadcrumb-item active">Quotation (Sale)</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.quot_No}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        quot_No: e.target.value,
                      });
                      Filter.quot_No = e.target.value;
                    }}
                  />
                  <label>Quotation No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <Select
                    type="text"
                    required
                    styles={{
                      control: base => ({
                        ...base,
                        height: 35,
                        minHeight: 39,
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 999,
                      }),
                    }}
                    options={customerDD}
                    filterOption={customFilter}
                    value={
                      customerDD?.find(el => el.value === Filter.customer_name) || { label: "--Select--", value: "" }
                    }
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        customer_name: e.value,
                      });
                      Filter.customer_name = e.value;
                    }}
                  >
                    {/* <option key="" value="">
                      Select a customer
                    </option>
                    {customerDD.map((option, index) => (
                      <option key={option.cus_name} value={option.cus_id}>
                        {option.cus_name}
                      </option>
                    ))} */}
                  </Select>
                  <label>Customer</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                {/* <label></label> */}
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    options={optionsStatus.data}
                    value={status_list_filter}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Status
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      //   GetProduct();
                      FilterQuotSales();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Sale_cuteplut/Quotation_Sales_Cuteplut/Quotation/Add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Quotation</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={Quotation_data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="modal fade"
        id="modalQuotationCustomer"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalfoodRedisterLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title" id="modalfoodRedisterLabel">
                <b>View Quotation (Sales)</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              {/* history_data */}
              <div className="row">
                <div className="col-12">
                  <h4>View Version Quotation</h4>
                </div>
              </div>
              <div className="table-responsive">
                <div className="row">
                  <div className="col-12 col-md-12 col-xl-12">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={history_data}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div
              className="modal-footer"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // ref={closeModalFoodRegister}
              >
                Close
              </button>
              <></>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        className="Modal_Center_LeftRight"
        isOpen={ModalNotApprove}
      >
        <ModalHeader
          toggle={() => {
            setNotApproveRemark({
              ...notApproveRemark,
              index: null,
              remark: null,
            })
            notApproveRemark.index = null;
            notApproveRemark.remark = null;
            setModalNotApprove(false);
            setisReadNotApproveRemark(false);
          }}
        >
          <h4 className="modal-title" id="modalContrat">
            <b>Reason for Not Approve</b>
            {notApproveRemark.remark === null || notApproveRemark.remark?.trim() === "" ? (
              <span style={{ color: "red" }}>*</span>
            ) : ("")}
          </h4>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <input
                className="form-control"
                value={notApproveRemark.remark}
                disabled={isReadNotApproveRemark}
                onChange={(e) => {
                  setNotApproveRemark({
                    ...notApproveRemark,
                    remark: e.target.value
                  });
                  notApproveRemark.remark = e.target.value;
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <>
            <button
              type="button"
              style={{ width: "100px" }}
              className="btn btn-danger"
              onClick={() => {
                setModalNotApprove(false);
                setNotApproveRemark({
                  ...notApproveRemark,
                  index: null,
                  remark: null,
                })
                notApproveRemark.index = null;
                notApproveRemark.remark = null;
              }}
            >
              ยกเลิก
            </button>
          </>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Quotation_Table;
