import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import DatePicker from "react-datepicker";
import { useParams } from "react-router";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Select from "react-select";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import TableLEK from "../../component/tableLEKTTT/table";


function MaterailCon(params) {


  const [test,settest] = useState({
    test:'',
  });
  const [headTable, setheadTable] = useState(
    [
     
      {
        label: "Material No",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
  
      {
        label: "Amount (kg.)",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      
   
      {
        label: "Mat Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },

      {
        label: "Mat Grade",
        field: "mat_grade",
        sort: "asc",
        width: 50,
      },
   
      {
        label: "Coil Weight",
        field: "coil_weight",
        sort: "asc",
        width: 50,
      },
 
      
      
      {
        label: "Mat Unit",
        field: "mat_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "mat_pupp",
        sort: "asc",
        width: 50,
      },

      {
        label: "Weight Piece",
        field: "weight_piece",
        sort: "asc",
        width: 50,
      },

    
]
);
const [valueData, setvalueData] = useState([]);
const [countPage, setCountPage] = useState(1);
const [currentPage,setCurrentPage] = useState(1);
const [countData, setCountData] = useState(0);
const [entries, setEntries] = useState(10);
const [search, setSearch] = useState(0);



  const [matype_option, setmatype_option] = useState([]);
  const select_matype = useRef();
  const [matsize_option, setmatsize_option] = useState([]);
  const select_matsize = useRef();
  const [matspac_option, setmatspac_option] = useState([]);
  const select_matspac = useRef();
  const [matsup_option, setmatsup_option] = useState([]);
  const select_matsup= useRef();

  const [supplier, setSupplier] = useState([]);
  const [type, setType] = useState([]);
  const [size, setSize] = useState([]);
  const [spect, setSpect] = useState([]);
  const [oem_data, setOem_data] = useState();
  const [company, setcompany] = useState();
  const [feature, setfeature] = useState([]);


  const [materail, setmaterial] = useState([]);
  const [materialExcel, setmaterialExcel] = useState([]);
  const [header_list_mat, setheader_list_mat] = useState([]);
  var startdate = moment(new Date()).startOf('month')
  var enddate = moment(new Date()).endOf('month')
  const [filler_material, setFiller_material] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
    date_start: new Date(startdate),
    date_end: new Date(enddate),
    all_search: ""
  });

  async function SetDate_start(date) {
    /* setFiller_record_oil({ ...filler_record_oil, date_start: date }); */
  }
  async function SetDate_end(date) {
    /* setFiller_record_oil({ ...filler_record_oil, date_end: date }); */
  }

  const { id } = useParams();


  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_type = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialType?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_spec = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSpec?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_size = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSize?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };


  const GetMaterial = async (page,size,search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if(search){
      setSearch(1);
  }else{
      setSearch(0);
  }
    var temp = {
      company_id: filler_material.company_id,
      oem_id: filler_material.oem_id,
      no: filler_material.no.trim(),
      name: filler_material.name.trim(),
      edit_type: filler_material.edit_type,
      amount: filler_material.amount,
      create_date: filler_material.create_date,
      date_start: filler_material.date_start,
      date_end: filler_material.date_end,
      page:page || 1,
      size:size || 10,
      all_search: (filler_material.all_search || "").trim()
    }
    //console.log("temp",temp);
    axios({
      method: "post",
      url: Configs.API_URL + "/api/materailConCTSRouter/filterMaterial_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {

        /* response.data.heder.splice(0, 0, ({ label: 'ลำดับ', field: 'number', sort: 'asc', width: 50 }))
        let mat_out_only = response.data.bodyValue.filter((e) => { return e['activity type'] === 'out' })
        console.log("เช็คout", mat_out_only);
        setheader_list_mat(response.data.heder); */
        
        setmaterial(response.data.data);
       setCountPage(response.data.countPage);
       setCountData(response.data.count);
       setEntries(size);
       settest({...test,test:''})
       test.test = '';
       Swal.close();
        /* var sort = response.data.sort((a,b) => a.no.localeCompare(b.no));
        setmaterial(sort); */
      
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        console.log(error);
      });
  };

  const GetMaterial_export = async (page,size,search) => {
  
    var temp = {
      company_id: filler_material.company_id,
      oem_id: filler_material.oem_id,
      no: filler_material.no.trim(),
      name: filler_material.name.trim(),
      edit_type: filler_material.edit_type,
      amount: filler_material.amount,
      create_date: filler_material.create_date,
      date_start: filler_material.date_start,
      date_end: filler_material.date_end,
      page:page || 1,
      size:size || 10,
      all_search: (filler_material.all_search || "").trim()
    }
    //console.log("temp",temp);
    axios({
      method: "post",
      url: Configs.API_URL + "/api/materailConCTSRouter/filterMaterialexport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {

        /* response.data.heder.splice(0, 0, ({ label: 'ลำดับ', field: 'number', sort: 'asc', width: 50 }))
        let mat_out_only = response.data.bodyValue.filter((e) => { return e['activity type'] === 'out' })
        console.log("เช็คout", mat_out_only);
        setheader_list_mat(response.data.heder); */
        console.log('Ezport',response.data);
        setmaterialExcel(response.data);
       
        /* var sort = response.data.sort((a,b) => a.no.localeCompare(b.no));
        setmaterial(sort); */
      
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        console.log(error);
      });
  };



  useEffect(() => {
    GetMaterial_export();
    axios(config_sub)
    .then(function (response) {
      setSupplier(response.data);
      /* console.log(response.data); */
      const options = [];
      response.data.forEach((el) => {
      var element = {};
      element["value"] = el.id;
      element["label"] = el.name;
      options.push(element);
       });
      setmatsup_option(options);
    })
    .catch(function (error) {
      console.log(error);
    });

  axios(config_type)
    .then(function (response) {
      setType(response.data);
      /* console.log(response.data); */

      const options = [];
      response.data.forEach((el) => {
      var element = {};
      element["value"] = el.id;
      element["label"] = el.name;
      options.push(element);
       });
      setmatype_option(options);
    })
    .catch(function (error) {
      console.log(error);
    });

  axios(config_spec)
    .then(function (response) {
      setSpect(response.data);
      /* console.log(response.data); */
      const options = [];
      response.data.forEach((el) => {
      var element = {};
      element["value"] = el.id;
      element["label"] = el.name;
      options.push(element);
       });
      setmatspac_option(options);
    })
    .catch(function (error) {
      console.log(error);
    });

  axios(config_size)
    .then(function (response) {
      setSize(response.data);
      /* console.log(response.data); */
      const options = [];
      response.data.forEach((el) => {
      var element = {};
      element["value"] = el.id;
      element["label"] = el.name;
      options.push(element);
       });
      setmatsize_option(options);
    })
    .catch(function (error) {
      console.log(error);
    });

  axios(config_getOem)
    .then(function (response) {
      response.data.map((el) => {
        if (el.id === getOem()) {
          /* console.log(el); */
          return setOem_data(el);
        }
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  axios(config_com)
    .then(function (response) {
      /*  console.log(response.data); */
      setcompany(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
    GetMaterial(1,10,'search');
  }, []);


  const clearFilter = async () => {
    await setFiller_material({
      ...filler_material,
      no: "",
      name: "",
      create_date: "",
      all_search: "",
      date_start: new Date(startdate),
      date_end: new Date(enddate),
    });
    filler_material.no = "";
    filler_material.name = "";
    filler_material.create_date = "";
    filler_material.all_search = "";
    filler_material.date_start = new Date(startdate);
    filler_material.date_end = new Date(enddate);
    GetMaterial(1,10,'search');
    GetMaterial_export(1,10,'search');
  };






  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }



  const rowsData = [];

  // for (var index = 0; index < material.length; index++) {
  //   const rowItem = {};
  //   // console.log("sadasd",ro[index]," : ",material[index]);

  //   rowItem["number"] = index + 1;
  //   rowItem["material no "] = material[index]['material no '];
  //   rowItem["material name"] = material[index]['material name'];
  //   rowItem["activity type"] = material[index]['activity type'];
  //   rowItem["coil no"] = material[index]['coil no'];
  //   rowItem["invoice no"] = material[index]['invoice no'];
  //   rowItem["amount"] = material[index].amount;
  //   rowItem["check_date"] = moment(material[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
  //   rowItem["tag weight"] = material[index]['tag weight'];
  //   rowItem["actual_weight"] = material[index].actual_weight;
  //   rowItem["grade"] = material[index].grade;
  //   rowItem["supplier name"] = material[index]['supplier name'];
  //   rowItem["location name"] = material[index]['location name'];
  //   rowItem["oem"] = material[index].oem;
  //   rowItem["company"] = material[index].company;
  //   rowItem["date"] = moment(material[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
  //   rowItem["username"] = material[index].username || "";
  //   rowItem["qc date"] = moment(material[index]['qc date']).format("DD/MM/yyyy ::: HH:mm") || "";
  //   rowItem["qc by"] = material[index]['qc by'] || "";
  //   rowItem["username"] = material[index].username || "";


  //   materialrowsData.push(rowItem);
  // }
  for (var index = 0; index < materail.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    /* rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            materail[index].imaget_path !== null
              ? Configs.API_URL_IMG + materail[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    ); */
 
    rowItem["mat_code"] = materail[index].no;

     rowItem["unit_by"] = materail[index].material_unit_id;


    
    for (let indexin = 0; indexin < spect.length; indexin++) {
      if (materail[index].material_spec_id === spect[indexin].id) {
        rowItem["mat_grade"] = spect[indexin].name;
      } 
    }
    for (let indexin = 0; indexin < size.length; indexin++) {
      if (materail[index].material_size_id === size[indexin].id) {
        rowItem["mat_size"] = size[indexin].name;
      }
    }
    for (let indexin = 0; indexin < type.length; indexin++) {
      if (materail[index].material_type_id === type[indexin].id) {
        rowItem["mat_type"] = type[indexin].name;
      } 
    }
    for (let indexin = 0; indexin < supplier.length; indexin++) {
      if (materail[index].material_supplier_id === supplier[indexin].id) {
        rowItem["mat_pupp"] = supplier[indexin].name;
      }
    }
    
    rowItem["name"] = materail[index].name;
    rowItem["Min_lot"] =
        materail[index].minimum_lot === null ? 0 : materail[index].minimum_lot;
    rowItem["Min_lot_sup"] =
      materail[index].minimum_order === null
        ? "0"
        : materail[index].minimum_order;
 
    rowItem["mat_unit"] = materail[index].material_unit;
    rowItem["coil_weight"] = materail[index].coil_weight;
    rowItem["description"] = materail[index].description;
    rowItem["remark"] = materail[index].remark;
    rowItem["amount"] = numberWithCommas(materail[index].log_sum_amount || 0) ;

    rowItem["grade"] = materail[index].grade; 
    rowItem["weight_piece"] = materail[index].weight_piece; 
    rowItem["invoice_no"] = materail[index].invoice_no; 
    rowItem["qc_approve_person"] = materail[index].qc_approve_person;
    rowItem["log_create_date"] = materail[index].log_create_date ? moment(materail[index].log_create_date).format('DD/MM/yyyy :: HH:mm:ss') : "";
    rowItem["check_date"] = materail[index].check_date ? moment(materail[index].check_date).format('DD/MM/yyyy :: HH:mm:ss') : "";

    rowsData.push(rowItem);
  }


  const rowsDataExcel = [];


  for (var index = 0; index < materialExcel.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    /* rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            materailExcel[index].imaget_path !== null
              ? Configs.API_URL_IMG + materailExcel[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    ); */
 
    rowItem["mat_code"] = materialExcel[index].no;

     rowItem["unit_by"] = materialExcel[index].material_unit_id;

/*     for (let indexin = 0; indexin < spect.length; indexin++) {
      if (materialExcel[index].material_spec_id === spect[indexin].id) {
        rowItem["mat_spec"] = spect[indexin].name;
      } 
    } */
    
    for (let indexin = 0; indexin < spect.length; indexin++) {
      if (materialExcel[index].material_spec_id === spect[indexin].id) {
        rowItem["mat_grade"] = spect[indexin].name;
      } /* else {
        rowItem["spec"] = "none"
      } */
    }
    for (let indexin = 0; indexin < size.length; indexin++) {
      if (materialExcel[index].material_size_id === size[indexin].id) {
        rowItem["mat_size"] = size[indexin].name;
      } /* else{
      rowItem["mat_size"] = "none"
    } */
    }
    for (let indexin = 0; indexin < type.length; indexin++) {
      if (materialExcel[index].material_type_id === type[indexin].id) {
        rowItem["mat_type"] = type[indexin].name;
      } /* else{
    rowItem["mat_type"] = "none"
  } */
    }
    for (let indexin = 0; indexin < supplier.length; indexin++) {
      if (materialExcel[index].material_supplier_id === supplier[indexin].id) {
        rowItem["mat_pupp"] = supplier[indexin].name;
      } /* else {
        rowItem["mat_pupp"] = "none";
      } */
    }
    
    rowItem["name"] = materialExcel[index].name;
    rowItem["Min_lot"] =
        materialExcel[index].minimum_lot === null ? 0 : materialExcel[index].minimum_lot;
    rowItem["Min_lot_sup"] =
      materialExcel[index].minimum_order === null
        ? "0"
        : materialExcel[index].minimum_order;
 
    rowItem["mat_unit"] = materialExcel[index].material_unit;
    rowItem["coil_weight"] = materialExcel[index].coil_weight;
    rowItem["description"] = materialExcel[index].description;
    rowItem["remark"] = materialExcel[index].remark;
    rowItem["amount"] = numberWithCommas(materialExcel[index].log_sum_amount || 0) ;
  /*   rowItem["material_tag_weight"] = materialExcel[index].tag_weight; */
/*     rowItem["coil_no"] = materialExcel[index].coil_no;  */
    rowItem["grade"] = materialExcel[index].grade; 
    rowItem["weight_piece"] = materialExcel[index].weight_piece; 
    rowItem["invoice_no"] = materialExcel[index].invoice_no; 
    rowItem["qc_approve_person"] = materialExcel[index].qc_approve_person;
    rowItem["log_create_date"] = materialExcel[index].log_create_date ? moment(materialExcel[index].log_create_date).format('DD/MM/yyyy :: HH:mm:ss') : "";
    rowItem["check_date"] = materialExcel[index].check_date ? moment(materialExcel[index].check_date).format('DD/MM/yyyy :: HH:mm:ss') : "";
   /*  rowItem["activity_type"] = materail[index].edit_type === true ? 'IN' : 'OUT'; */
   rowsDataExcel.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material No",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "Date OUT",
        field: "log_create_date",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "Status",
        field: "activity_type",
        sort: "asc",
        width: 50,s
      }, */
      {
        label: "Amount (kg.)",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      
      /* {
        label: "QR",
        field: "qr",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Mat Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },
  /*     {
        label: "Spec",
        field: "mat_spec",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Mat Grade",
        field: "mat_grade",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "Coil No",
        field: "coil_no",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "invoice_no",
        field: "invoice_no",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Coil Weight",
        field: "coil_weight",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "Mat Size",
        field: "mat_size",
        sort: "asc",
        width: 50,
      }, */
     /*  {
        label: "Buy Price",
        field: "buy_price",
        sort: "asc",
        width: 50,
      }, */
      
      
      {
        label: "Mat Unit",
        field: "mat_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "mat_pupp",
        sort: "asc",
        width: 50,
      },

      {
        label: "Weight Piece",
        field: "weight_piece",
        sort: "asc",
        width: 50,
      },

     /*  {
        label: "Sup lead time",
        field: "Sup_lead_time",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "Min stock",
        field: "Min_lot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min order",
        field: "Min_lot_sup",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "QC Date",
        field: "check_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "QC by",
        field: "qc_approve_person",
        sort: "asc",
        width: 50,
      }, */
     
  /*     {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }, */
    ],

    rows: rowsDataExcel,


  };
  // var check1 = data.columns.filter((e1) => { return e1.field === "oem_id" });
  // if (check1.length > 0) {
  //   var index1 = data.columns.findIndex((e1) => e1.field === "oem_id");
  //   data.columns.splice(index1, 1);
  // }
  // var check2 = data.columns.filter((e1) => { return e1.field === "company_id" });
  // if (check2.length > 0) {
  //   var index2 = data.columns.findIndex((e1) => e1.field === "company_id");
  //   data.columns.splice(index2, 1);
  // }
  // var check3 = data.columns.filter((e1) => { return e1.field === "check_date" });
  // if (check3.length > 0) {
  //   var index3 = data.columns.findIndex((e1) => e1.field === "check_date");
  //   data.columns.splice(index3, 1);
  // }
  // var check4 = data.columns.filter((e1) => { return e1.field === "company" });
  // if (check4.length > 0) {
  //   var index4 = data.columns.findIndex((e1) => e1.field === "company");
  //   data.columns.splice(index4, 1);
  // }
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Material Consumtion{"  "}<a target="_blank" href={getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Statistic & History
                  </li>
                  <li className="breadcrumb-item active">Materail Consumtion</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_material.all_search}
                    onChange={(e) => {
                      setFiller_material({
                        ...filler_material,
                        all_search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_material.no}
                    onChange={(e) => {
                      setFiller_material({
                        ...filler_material,
                        no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Material No.</label>{" "}
                </div>
              </div>
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_material.name}
                    onChange={(e) => {
                      setFiller_material({
                        ...filler_material,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Material Name.</label>{" "}
                </div>
              </div>
              
                <label>&nbsp;&nbsp; วันที่</label>
              
              <div className="col-2 col-md-2  col-xl-2" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <DatePicker
                    selected={filler_material.date_start}
                    className="form-control"
                    // //required="false"
                    dateFormat={"dd-MM-yyyy"}
                    // onChange={async (date) => SetDate_start_fg(date)}
                    onChange={
                      async (date) => {
                        setFiller_material({
                          ...filler_material,
                          date_start: date,
                        });
                      }
                    }
                    selectsStart startDate={filler_material.date_start}
                    endDate={filler_material.date_end}
                  />
                </div>
              </div>
            
              <label>ถึง</label>
          
              <div className="col-2 col-md-2  col-xl-2" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <DatePicker
                    selected={filler_material.date_end}
                    className="form-control"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={
                      async (date) => {
                        setFiller_material({
                          ...filler_material,
                          date_end: date,
                        });
                      }

                    }
                    selectsEnd startDate={filler_material.date_start}
                    endDate={filler_material.date_end}
                    minDate={filler_material.date_start}
                  />
                </div>
              </div>
             



            </div>
            <div className="row">
            <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      GetMaterial(1,10,'search');
                      GetMaterial_export(1,10,'search')
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-2 col-md-2  col-xl-2">
                <div className="form-group ">
                   <ReactHTMLTableToExcel
                     id="test-table-xls-button"
                     className="btn btn-block btn-primary"
                     table="table-to-xls_material"
                     filename={"HistoryMatOut" + "_" + moment(new Date()).format('DDMMyyyy')}
                     sheet="tablexls"
                     buttonText="Export To Xlsx"
                 />
                
                </div>
              </div>
            </div>
          </div>
          {/*  <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={getallRecord}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>

             
            </div>
          </div> */}
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
            <div className="container-fluid">
             {/*  <h3 className="card-title">บันทึกการใช้น้ำมัน</h3> */}
              <div className="card-tools"></div>
            </div>

            <div
              className="table-responsive " 
              style={{ whiteSpace: 'nowrap',padding:'2%' }}
              /*  style={{ height: "100%" }} */
            >
              <TableLEK                 
            headTable = {headTable}
            dataTable = {rowsData}
            countPage = {countPage}
            countData = {countData}
            entries = {entries}
            isSearch = {search}
            callFnc ={GetMaterial}
            />
           {/*    <MDBDataTable
                sortable={false}
                
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            */}
            </div>
            <div style={{ display: "none" }} >   
                            <MDBDataTable
                                sortable={false}
                                id="table-to-xls_material"
                                entries ={4096}
                                className="table table-head-fixed"
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={data}
                            />
                        </div>
                            
            {/* /.card-body */}
            <div className="card-footer"></div>
            </div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*  <Footter/> */}
    </div>
  );
}

export default MaterailCon;
