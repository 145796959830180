import Configs from "../../../../config";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../../../assets/font/pdf_font";
import jsPDF from "jspdf";
// import QRCode from "qrcode";
import moment from "moment";
import LogoTTT from "../../../../assets/img/button_menu/logoTTT.png";

async function Report_PP(data, companyData) {
  // console.log("data", data);
  let dl = data.delivery;
  let dp = data.delivery_product;
  const doc = new jsPDF("l", "mm", "a4");
  doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
  doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
  doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
  doc.setFont("THSarabunNew");
  let urlImg = Configs.API_URL_IMG + companyData.logo;
  let TTTlogo = await getImageSizeFromURL(urlImg);
  ///////////กำหนดตั้งค่าหน้าแนวตั้ง
  let padding = 8,
    wpage = 296,
    hpage = 210,
    page = 1;
  //////////////////////////////////
  let inpage = 0;
  let sizes = 9;
  let sizem = 12;
  let sizel = 13;
  let numecut = 10;

  let table = {
    col1: CutPadding("w", 13, -padding),
    col2: CutPadding("w", 13, -padding),
    col3: CutPadding("w", 13, -padding),
    col4: CutPadding("w", 13, -padding),
    col5: CutPadding("w", 12, -padding),
    col6: CutPadding("w", 12, -padding),
    col7: CutPadding("w", 12, -padding),
    col8: CutPadding("w", 12, -padding),
    name1: "PO Name",
    // namesub1: "PO Name",
    name2: "Part No",
    // namesub2: "Part No",
    name3: "Part Name",
    // namesub3: "Part Name",
    name4: "Out Quantity",
    // namesub4: "Out Quantity",
    name5: "Warehouse",
    // namesub5: "Warehouse",
    name6: "Rack",
    // namesub6: "Rack",
    name7: "Position",
    // namesub7: "Position",
    name8: "Pick Up Quantity",
    // namesub8: "Quantity",
    loop: 8,
  };

  let delivery_date =
    dl.dl_delivery_date !== null && dl.dl_delivery_date !== undefined
      ? dl.dl_delivery_date
      : null;
  let jsonhead = {
    text1: "วันที่งาน",
    amount1: `${
      delivery_date ? moment(delivery_date).format("DD/MM/YY") : "-"
    }`,
    text2: "เลขที่งาน",
    amount2: `${dl.dl_no}`,
    loop: 2,
  };

  let headerHeight = await setheader();

  inpage += headerHeight + 10;
  Shot("lw", 0.5);
  Shot("ld", 0, 0, 0);
  ShotTable("ewt", table, inpage, table.loop, 10, "", "fd");
  // ShotTable("ht", table, inpage, table.loop, 10, "", "fd");

  let textPad = 6;
  for (let index = 0; index < dp.length; index++) {
    // for (let j = 0; j < dp[index].log_product.length; j++) {
    if (inpage + numecut > CutPadding("h", 200)) {
      Shot("add");
      inpage = 0;
      page += 1;
      let headerHeight = await setheader();
      inpage += headerHeight + 10;
      Shot("lw", 0.5);
      Shot("ld", 0, 0, 0);
      ShotTable("lt", table, inpage, table.loop, 10, "", "fd");
      ShotTable("ht", table, inpage, table.loop, 10, "", "fd");
      numecut = 10;
    }
    let po_no =
      dp[index].dp_po_no !== null && dp[index].dp_po_no !== undefined
        ? doc.splitTextToSize(dp[index].dp_po_no, CutPadding("w", 15 - padding)).length > 1
          ? doc.splitTextToSize(dp[index].dp_po_no, CutPadding("w", 15 - padding))[0] + "..."
          : doc.splitTextToSize(dp[index].dp_po_no, CutPadding("w", 15 - padding))[0]
        : "-";
    Shot(
      "t",
      Colposition("t", table, 1, "l", 5),
      inpage + numecut + textPad,
      TexttoString(`${po_no}`),
      "l"
    );
    let part_no =
      dp[index].dp_part_no !== null && dp[index].dp_part_no !== undefined
        ? doc.splitTextToSize(dp[index].dp_part_no, CutPadding("w", 15 - padding)).length > 1
          ? doc.splitTextToSize(dp[index].dp_part_no, CutPadding("w", 15 - padding))[0] + "..."
          : doc.splitTextToSize(dp[index].dp_part_no, CutPadding("w", 15 - padding))[0]
        : "-";
    Shot(
      "t",
      Colposition("t", table, 2, "l", 5),
      inpage + numecut + textPad,
      TexttoString(`${part_no}`),
      "l"
    );
    let part_name =
      dp[index].dp_part_name !== null && dp[index].dp_part_name !== undefined
        ? doc.splitTextToSize(dp[index].dp_part_name, CutPadding("w", 15 - padding)).length > 1
          ? doc.splitTextToSize(dp[index].dp_part_name, CutPadding("w", 15 - padding))[0] + "..."
          : doc.splitTextToSize(dp[index].dp_part_name, CutPadding("w", 15 - padding))[0]
        : "-";
    Shot(
      "t",
      Colposition("t", table, 3, "l", 5),
      inpage + numecut + textPad,
      TexttoString(`${part_name}`),
      "l"
    );
    let out_quantity = Number(dp[index].dp_quantity || 0);
    Shot(
      "t",
      Colposition("t", table, 4, "r", 5),
      inpage + numecut + textPad,
      TexttoString(`${out_quantity}`),
      "r"
    );

    let warehouse =
      dp[index].log_product.length > 0
        ? dp[index].log_product
            ?.map((item) =>
              doc.splitTextToSize(item.wh_name, CutPadding("w", 15 - padding))
                .length > 1
                ? doc.splitTextToSize(
                    item.wh_name,
                    CutPadding("w", 15 - padding)
                  )[0] + "..."
                : doc.splitTextToSize(
                    item.wh_name,
                    CutPadding("w", 15 - padding)
                  )[0]
            )
            .join("\n")
        : "-";
    Shot(
      "t",
      Colposition("t", table, 5, "l", 5),
      inpage + numecut + textPad,
      TexttoString(`${warehouse}`),
      "l"
    );
    let rack =
      dp[index].log_product.length > 0
        ? dp[index].log_product
            ?.map((item) =>
              doc.splitTextToSize(item.rack_name, CutPadding("w", 15 - padding))
                .length > 1
                ? doc.splitTextToSize(
                    item.rack_name,
                    CutPadding("w", 15 - padding)
                  )[0] + "..."
                : doc.splitTextToSize(
                    item.rack_name,
                    CutPadding("w", 15 - padding)
                  )[0]
            )
            .join("\n")
        : "-";
    Shot(
      "t",
      Colposition("t", table, 6, "l", 5),
      inpage + numecut + textPad,
      TexttoString(`${rack}`),
      "l"
    );
    let position =
      dp[index].log_product.length > 0
        ? dp[index].log_product
            ?.map((item) =>
              doc.splitTextToSize(
                item.rp_position,
                CutPadding("w", 15 - padding)
              ).length > 1
                ? doc.splitTextToSize(
                    item.rp_position,
                    CutPadding("w", 15 - padding)
                  )[0] + "..."
                : doc.splitTextToSize(
                    item.rp_position,
                    CutPadding("w", 15 - padding)
                  )[0]
            )
            .join("\n")
        : "-";
    Shot(
      "t",
      Colposition("t", table, 7, "l", 5),
      inpage + numecut + textPad,
      TexttoString(`${position}`),
      "l"
    );
    let out_quantity_temp = Number(dp[index].dp_quantity || 0);
    let quantity =
      dp[index].log_product.length > 0
        ? dp[index].log_product
            ?.map((item) => {
              if (
                out_quantity_temp > 0 &&
                out_quantity_temp >= Number(item.lp_amount || 0)
              ) {
                out_quantity_temp -= Number(item.lp_amount || 0);
                return NumtoString(Number(item.lp_amount || 0));
              } else if (
                out_quantity_temp > 0 &&
                out_quantity_temp < Number(item.lp_amount || 0)
              ) {
                let temp = out_quantity_temp;
                out_quantity_temp = 0;
                return NumtoString(temp);
              }
            })
            .join("\n")
        : "-";

    Shot(
      "t",
      Colposition("t", table, 8, "r", 5),
      inpage + numecut + textPad,
      TexttoString(`${quantity}`),
      "r"
    );
    // find if has \n in text and count
    let countBuntud = 0; // warehouse
    let countBuntud2 = 0; // rack
    let countBuntud3 = 0; // position
    let countBuntud4 = 0; // quantity

    if (warehouse.includes("\n")) {
      countBuntud = warehouse.split("\n").length;
    }
    if (rack.includes("\n")) {
      countBuntud2 = rack.split("\n").length;
    }
    if (position.includes("\n")) {
      countBuntud3 = position.split("\n").length;
    }
    if (quantity.includes("\n")) {
      countBuntud4 = quantity.split("\n").length;
    }
    let extra = 0;
    let maxBuntud = Math.max(
      countBuntud,
      countBuntud2,
      countBuntud3,
      countBuntud4
    );
    if (maxBuntud > 1) {
      extra += (maxBuntud - 1) * 6;
    }

    ShotTable("lt", table, inpage + numecut, table.loop, 10, "", "fd", extra);
    numecut += 10; // ระยะห่างระหว่างแถว เพื่อกำหนด จำนวนแถวด้วย
    if (extra > 0) {
      numecut += extra;
    }
    // }
  }

  // if (isPreview) {
  // Preview
  Shot("new");
  // } else {
  //   // Download
  //   Shot("save", `${main.pr_no}.pdf`);
  // }

  async function jsonboxlist(settable, pos) {
    let setpos = pos;

    for (let i = 1; i <= settable.loop; i++) {
      Shot("fs", sizel);
      Shot(
        "t",
        CutPadding("w", 85),
        setpos,
        TexttoString(`${settable[`text${i}`]}`),
        "l"
      );
      Shot("fs", sizem);
      Shot(
        "t",
        CutPadding("w", 100),
        setpos,
        TexttoString(`${settable[`amount${i}`]}`),
        "r"
      );
      setpos += 6;
    }

    return setpos;
  }

  async function setheader() {
    Shot(
      "i",
      urlImg,
      CutPadding("w", -7),
      CutPadding("h", 0),
      CutPadding("w", 20),
      CutPadding("h", 20),
      "fit",
      TTTlogo
    );
    Shot("lw", 1);
    Shot("fs", 30);
    Shot(
      "t",
      CutPadding("w", 13),
      CutPadding("h", 10),
      TexttoString(`${companyData.name}`),
      "l"
    );
    Shot(
      "t",
      CutPadding("w", 13),
      CutPadding("h", 10),
      TexttoString(`${companyData.name}`),
      "l"
    );
    Shot("tc", 0, 0, 102);
    doc.setFontSize(30);
    doc.setFont("THSarabunNew", "bold");
    doc.text(
      'Warehouse "OUT" Note',
      CutPadding("w", 100),
      CutPadding("h", 10),
      "right"
    );
    doc.setFontSize(sizel);
    doc.setFont("THSarabunNew", "normal");
    Shot("tc", 0, 0, 0);
    let yAxis = jsonboxlist(jsonhead, CutPadding("h", 23));

    return yAxis;
  }

  async function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
    if (mode === "add") {
      if (c1 !== undefined) {
        return doc.addPage(c1);
      }
      return doc.addPage();
    }
    if (mode === "new") {
      window.open(doc.output("bloburl"));
    }
    if (mode === "save") {
      if (c1 !== undefined) {
        return doc.output("save", c1);
      } else {
        return doc.output(
          "save",
          moment(new Date()).add("year", 543).format("DD/MM/YY")
        );
      }
    }
    if (mode === "newsave") {
      if (c1 !== undefined) {
        return window.open(doc.output("bloburl"), doc.output("save", c1));
      }
      return window.open(
        doc.output("bloburl"),
        doc.output(
          "save",
          moment(new Date()).add("year", 543).format("DD/MM/YY")
        )
      );
    }

    // if (mode === "qr") {
    //   let imgqr = "";
    //   QRCode.toDataURL(c1, function (err, url) {
    //     imgqr = url;
    //   });

    //   return doc.addImage(imgqr, "JPEG", c2, c3, c4, c4);
    // }

    if (mode === "fs") {
      doc.setFontSize(c1);
    }
    if (mode === "dc") {
      doc.setDrawColor(c1, c2, c3);
    }
    if (mode === "tc") {
      doc.setTextColor(c1, c2, c3);
    }
    if (mode === "fc") {
      doc.setFillColor(c1, c2, c3);
    }
    if (mode === "lw") {
      doc.setLineWidth(c1);
    }
    if (mode === "ld") {
      doc.setLineDash([c1, c2], c3);
    }
    if (mode === "l") {
      return doc.line(c1, c2, c3, c4);
    }

    if (mode === "i") {
      if (c5 !== undefined) {
        if (c6 !== undefined) {
          if (c6 === "fit") {
            const imgar = c7.width / c7.height;
            const reactar = c4 / c5;

            let scale = 1;
            if (imgar > reactar) {
              scale = c4 / c7.width;
            } else {
              scale = c5 / c7.height;
            }
            const nw = c7.width * scale;
            const nh = c7.height * scale;

            const x = (c4 - nw) / 2;
            const y = (c5 - nh) / 2;

            return doc.addImage(c1, "JPEG", c2 + x, c3 + y, nw, nh);
          }
        }
        return doc.addImage(c1, "JPEG", c2, c3, c4, c5);
      }
      return doc.addImage(c1, "JPEG", c2, c3, c4, c4);
    }
    if (mode === "r") {
      if (c5 !== undefined) {
        if (c5 === "d") {
          return doc.rect(c1, c2, c3, c4, "D");
        }
        if (c5 === "fd") {
          return doc.rect(c1, c2, c3, c4, "FD");
        }
        if (c5 === "f") {
          return doc.rect(c1, c2, c3, c4, "F");
        }
      } else return doc.rect(c1, c2, c3, c4);
    }
    if (mode === "rr") {
      if (c7 !== undefined) {
        if (c7 === "d") {
          return doc.roundedRect(c1, c2, c3, c4, c5, c6, "D");
        }
        if (c7 === "fd") {
          return doc.roundedRect(c1, c2, c3, c4, c5, c6, "FD");
        }
        if (c7 === "f") {
          return doc.roundedRect(c1, c2, c3, c4, c5, c6, "F");
        }
      } else return doc.roundedRect(c1, c2, c3, c4, c5, c6);
    }
    if (mode === "t") {
      if (c4 !== undefined) {
        if (c4 === "c") {
          return doc.text(c1, c2, c3, "center");
        }
        if (c4 === "r") {
          return doc.text(c1, c2, c3, "right");
        }
        if (c4 === "l") {
          return doc.text(c1, c2, c3, "left");
        }
      } else return doc.text(c1, c2, c3);
    }
  }

  function CutPadding(mode, percent, num) {
    if (mode === "h") {
      if (num !== undefined) {
        return ((hpage / 2 - padding * 2) * percent) / 100 + padding + num;
      }
      return ((hpage / 2 - padding * 2) * percent) / 100 + padding;
    }
    if (mode === "w") {
      if (num !== undefined) {
        return ((wpage - padding * 2) * percent) / 100 + padding + num;
      }
      return ((wpage - padding * 2) * percent) / 100 + padding;
    }
  }

  function TexttoString(text) {
    if (text === null) {
      return "".toString();
    }
    return text.toString();
  }
  function NumtoString(num, fix) {
    if (num !== null) {
      if (fix !== undefined) {
        return parseFloat(num)
          .toFixed(fix)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return parseFloat(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseFloat(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function ShotTable(mode, head, pos, col, coll, loop, color, extra) {
    let collengthsum = coll === undefined ? 5 : coll;
    let loopsum = loop === undefined ? 10 : loop;
    if (mode === "ht") {
      Shot("fc", 0, 0, 0);
      Shot("dc", 0, 0, 0);
      Shot("ld", 0, 0, 0);
      Shot("lw", 0.1);
      for (let t = 1; t <= col; t += 1) {
        if (head[`name${t}`] !== undefined) {
          if (t === col) {
            Shot("fs", sizel);
            Shot(
              "t",
              Colposition("t", head, t, "c"),
              pos + collengthsum / 2,
              TexttoString(head[`name${t}`]),
              "c"
            );
          } else {
            Shot("fs", sizel);
            Shot(
              "t",
              Colposition("t", head, t, "c"),
              pos + collengthsum / 2,
              TexttoString(head[`name${t}`]),
              "c"
            );
          }
        }
      }
    }

    if (mode === "lt") {
      Shot("fc", 0, 0, 102);
      Shot("dc", 0, 0, 0);
      Shot("ld", 0, 0, 0);
      Shot("lw", 0.1);
      let lengthloop = loopsum * collengthsum + collengthsum;
      for (let t = 1; t <= col; t += 1) {
        Shot(
          "r",
          Colposition("r", head, t),
          pos,
          CutPadding("w", 0, head[`col${t}`] - padding),
          lengthloop + extra
        );
      }
    }

    if (mode === "st") {
      let lengthloop = collengthsum;
      for (let l = 0; l < loopsum; l += 1) {
        for (let t = 1; t <= col; t += 1) {
          Shot(
            "r",
            Colposition("r", head, t),
            pos + lengthloop,
            CutPadding("w", 0, head[`col${t}`] - padding),
            collengthsum
          );
        }
        lengthloop += collengthsum;
      }
    }

    if (mode === "ewt") {
      Shot("dc", 255, 255, 255);
      Shot("fc", 0, 0, 102);
      Shot("lw", 0.2);
      Shot("ld", 0, 0, 0);
      let lengthloop = loopsum * collengthsum + collengthsum;
      for (let t = 1; t <= col; t += 1) {
        Shot(
          "r",
          Colposition("r", head, t),
          pos,
          CutPadding("w", 0, head[`col${t}`] - padding),
          lengthloop,
          "fd"
        );
      }
      Shot("tc", 255, 255, 255);
      for (let t = 1; t <= col; t += 1) {
        if (head[`name${t}`] !== undefined) {
          if (t === col) {
            Shot("fs", sizel);
            Shot(
              "t",
              Colposition("t", head, t, "c"),
              pos + collengthsum / 1.5,
              TexttoString(head[`name${t}`]),
              "c"
            );
          } else {
            Shot("fs", sizel);
            Shot(
              "t",
              Colposition("t", head, t, "c"),
              pos + collengthsum / 1.5,
              TexttoString(head[`name${t}`]),
              "c"
            );
          }
        }
      }
      Shot("tc", 0, 0, 0);
    }
  }
  function Colposition(mode, name, col, pos, pad) {
    if (mode === "r") {
      const poscol = pos !== undefined ? pos : 0;
      const colcount = col - 1;
      let colsum = 0;

      for (let i = colcount - 1; i > 0; i -= 1) {
        colsum += name[`col${i}`];
      }

      if (colcount === 0) {
        return CutPadding("w", 0);
      }
      return CutPadding("w", 0, name[`col${colcount}`] + poscol) + colsum;
    }
    if (mode === "t") {
      let colsum = 0;
      const paddingcol = pad !== undefined ? pad : 0;
      for (let i = col - 1; i > 0; i -= 1) {
        colsum += name[`col${i}`];
      }

      if (col === 0) {
        return CutPadding("w", 0);
      }

      if (pos !== undefined) {
        if (pos === "c") {
          return (
            CutPadding("w", 0, (name[`col${col}`] * (50 + paddingcol)) / 100) +
            colsum
          );
        }
        if (pos === "l") {
          return (
            CutPadding("w", 0, (name[`col${col}`] * (1 + paddingcol)) / 100) +
            colsum
          );
        }
        if (pos === "r") {
          return (
            CutPadding("w", 0, (name[`col${col}`] * (99 - paddingcol)) / 100) +
            colsum
          );
        }
      }
      return CutPadding("w", 0, name[`col${col}`]) + colsum;
    }
  }

  async function getImageSizeFromURL(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Image not found or request failed.");
      }
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.onerror = (error) => {
          reject(error);
        };
        img.src = URL.createObjectURL(blob);
      });
    } catch (error) {
      // console.error(error);
      return null;
    }
  }
}

export default Report_PP;
