// BarChart.js
import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  registerables,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";

const BarChart = (props) => {
  const CustomCrosshairPlugin = function (plugin) {
    const originalAfterDraw = plugin.afterDraw;
    plugin.afterDraw = function (chart, easing) {
      if (chart && chart.crosshair) {
        originalAfterDraw.call(this, chart, easing);
      }
    };
    return plugin;
  };

  ChartJS.register(
    ...registerables,
    ChartDataLabels,
    CustomCrosshairPlugin(CrosshairPlugin),
  );

  const options = {
    events: ["mousemove"],
    interaction: {
      mode: "index",
      axis: "y",
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: "#007BFF",
        anchor: "end",
        align: "top",
        offset: 5,
        formatter: (value) => value,
        font: {
          weight: "bold",
          size: 10,
        },
        backgroundColor: function (context) {
          return "#E0F7FF";
        },
        borderRadius: 20,
        padding: {
          top: 5,
          right: 15,
          bottom: 5,
          left: 15,
        },
      },
      crosshair: {
        line: {
          color: "#D1D4DC", // crosshair line color
          width: 0.00000001, // crosshair line width
          display: false,
        },
        sync: {
          enabled: false, // enable trace line syncing with other charts
          group: 1, // chart group
          suppressTooltips: false, // suppress tooltips when showing a synced tracer
        },
        zoom: {
          enabled: false,
        },
        snap: {
          enabled: false,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          color: "#DAEBF2",
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#DAEBF2",
          drawBorder: false,
        },
        border: {
          display: false, // Ensure no border is drawn
        },
        min: 0,
        max: props.data.max,
        ticks: {
          stepSize: props.data.stepSize,
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    // props.barRef.current.update();
    // const chart = props.barRef.current;
    // console.log('update', props.data.count);
    // console.log('props.data.count :>> ', props.data.count);
    // chart.update();
    // if (chart && props.data.isStyleLabel) {
    //   const ctx = chart.ctx;
    //   const originalDraw = chart.draw;
    //   chart.draw = function () {
    //     originalDraw.apply(this, arguments);

    //     const xAxis = chart.scales["x"];
    //     const yAxis = chart.scales["y"];

    //     xAxis.ticks.forEach((value, index) => {
    //       const x = xAxis.getPixelForTick(index);
    //       const y = yAxis.bottom + 5; // Adjust this value for spacing

    //       console.log("value :>> ", value);
    //       ctx.save();
    //       ctx.fillStyle = backgroundColors[value.label] || "#FFFFFF";
    //       const text = value.label;
    //       const textWidth = ctx.measureText(text).width;
    //       const textHeight = 16; // Approximate height of the text
    //       const padding = 6;
    //       const radius = 10;

    //       // Draw rounded rectangle
    //       ctx.beginPath();
    //       ctx.moveTo(x - textWidth / 2 - padding + radius, y);
    //       ctx.lineTo(x + textWidth / 2 + padding - radius, y);
    //       ctx.quadraticCurveTo(
    //         x + textWidth / 2 + padding,
    //         y,
    //         x + textWidth / 2 + padding,
    //         y + radius
    //       );
    //       ctx.lineTo(
    //         x + textWidth / 2 + padding,
    //         y + textHeight + padding - radius
    //       );
    //       ctx.quadraticCurveTo(
    //         x + textWidth / 2 + padding,
    //         y + textHeight + padding,
    //         x + textWidth / 2 + padding - radius,
    //         y + textHeight + padding
    //       );
    //       ctx.lineTo(
    //         x - textWidth / 2 - padding + radius,
    //         y + textHeight + padding
    //       );
    //       ctx.quadraticCurveTo(
    //         x - textWidth / 2 - padding,
    //         y + textHeight + padding,
    //         x - textWidth / 2 - padding,
    //         y + textHeight + padding - radius
    //       );
    //       ctx.lineTo(x - textWidth / 2 - padding, y + radius);
    //       ctx.quadraticCurveTo(
    //         x - textWidth / 2 - padding,
    //         y,
    //         x - textWidth / 2 - padding + radius,
    //         y
    //       );
    //       ctx.closePath();
    //       ctx.fill();

    //       // Draw text
    //       ctx.fillStyle = backgroundColors[`${value.label}_Text`] || "#007BFF";
    //       ctx.fillText(
    //         text,
    //         x - textWidth / 2,
    //         y + textHeight /*  / 2 + padding / 2 */
    //       );
    //       ctx.restore();
    //     });
    //   };
    //   chart.update();
    // }
    console.log('bar1.max :>> ', props.data.max);
  }, [JSON.stringify(props.data.max)]);

  const backgroundColors = {
    Minor: "#E0E0E0",
    Minor_Text: "#808080",
    Average: "#FFE3E3",
    Average_Text: "#FF7B7F",
    Major: "#FFF9E6",
    Major_Text: "#FEC400",
    Critical: "#FFD4D4",
    Critical_Text: "#DD2025",
    "No status": "#E0F7FF",
    "No status_Text": "#007BFF",
  };

  return (
    <div className="chart-container" style={{ maxHeight: '240px', minWidth: props.minWidth }}>
      <Bar ref={props.barRef} data={props.chartData} options={options} style={{ maxHeight: '240px', minWidth: props.minWidth }} />
    </div>
  );
};

export default BarChart;
