import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import {
    getOem,
    getToken,
    getUser,
    getAcademy,
} from "../../../../Utils/Common";
import NumberFormat from "react-number-format";

function IncentiveUserAwardpoint() {
    const [test, settest] = useState({
        test:""
    })
    const [awardData, setawardData] = useState([])
    const [filterAward, setfilterAward] = useState({
        ap_point_id: "",
        ap_point_name: "",
    })
    const row_data = [];
    for (let index = 0; index < awardData.length; index++) {
        const element = {};
        element["no"] = index + 1;
        element['award_img'] = (
            <img
                id="img"
                alt="..."
                className="img-fluid rounded shadow border-0"
                src={
                    awardData[index].ap_image_path !== "" &&
                        awardData[index].ap_image_path !== null &&
                        awardData[index].ap_image_path !== undefined
                        ? Configs.API_URL_IMG_incentive + awardData[index].ap_image_path
                        : userdefault_img.imgs
                }
                style={{
                    width: "120px",
                    height: "100px",
                    position: "relative",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                }}
            />
        );
        element["award_no"] = awardData[index].ap_point_id;
        element["award_name"] = awardData[index].ap_point_name;
        element["award_point"] = (
            <NumberFormat
                value={awardData[index].ap_point}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
                />
        );
        element["mgt"] = (
            <>
                <a
                    href={"/Human_Resource/incentive_user/award_point/view/" + awardData[index].ap_id}
                    id={"btn_edit" + (index + 1)}
                    key={awardData[index].ap_id}
                    className=" btn btn-xs "
                >
                    <i className="fas fa-eye"></i>
                </a>
            </>
        );
        row_data.push(element);
    }

    const data = {
        columns: [
            {
                label: "ลำดับ",
                field: "no",
                sort: "asc",
                width: 50,
            },
            {
                label: "รูปภาพ",
                field: "award_img",
                sort: "asc",
                width: 50,
            },
            {
                label: "รหัสคะแนน",
                field: "award_no",
                sort: "asc",
                width: 50,
            },
            {
                label: "ชื่อคะแนนความสามารถ",
                field: "award_name",
                sort: "asc",
                width: 50,
            },
            {
                label: "จำนวนคะแนน",
                field: "award_point",
                sort: "asc",
                width: 50,
            },
            {
                label: "จัดการ",
                field: "mgt",
                sort: "asc",
                width: 50,
            },
        ],
        rows: row_data,
    };

    const getUserAwardData = async () => {
        const get_filter_award = {
            ap_point_id: filterAward.ap_point_id !== "" ? filterAward.ap_point_id.trim() : null,
            ap_point_name: filterAward.ap_point_name !== "" ? filterAward.ap_point_name.trim() : null,
            fup: getUser().fup,
            company_id: getUser().com,
            oem_id: getOem(),
        };
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/awardPoint/filterUserAward",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: get_filter_award,

        }).then((res) => {
            console.log(res.data);
            setawardData(res.data);
        });
    }
    useEffect(() => {
        getUserAwardData();
    }, []);
    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>เกณฑ์คะแนนและความสามารถ</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>

                                    <li className="breadcrumb-item active">Human Resource</li>
                                    <li className="breadcrumb-item active">News</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        id='award_no'
                                        className="form-control"
                                        value={filterAward.ap_point_id}
                                        onChange={(e) => {
                                            setfilterAward({
                                                ...filterAward,
                                                ap_point_id: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    <label htmlFor='award_no'>รหัสคะแนน</label>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        id='award_name'
                                        className="form-control"
                                        value={filterAward.ap_point_name}
                                        onChange={(e) => {
                                            setfilterAward({
                                                ...filterAward,
                                                ap_point_name: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    <label htmlFor='award_name'>ชื่อคะแนนความสามารถ</label>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info"
                                        onClick={() => {
                                            getUserAwardData();
                                        }}
                                    >
                                        ค้นหา
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info"
                                        onClick={() => {
                                            setfilterAward({
                                                ap_point_id: "",
                                                ap_point_name: "",
                                            });
                                            filterAward.ap_point_id = "";
                                            filterAward.ap_point_name = "";
                                            settest({...test, test:""})
                                            test.test=""
                                            getUserAwardData();
                                        }}
                                    >
                                        ล้างข้อมูล
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">คะแนนความสามารถ</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div
                                        className="table-responsive "
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <MDBDataTable
                                            sortable={false}
                                            className="table table-head-fixed"
                                            striped
                                            bordered
                                            hover
                                            fixedHeader
                                            data={data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default IncentiveUserAwardpoint