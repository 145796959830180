import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartPluginStacked100 from "chartjs-plugin-stacked100";

import "../../../assets/css/Dashboard.css";

function Horizontal_Bar_Chart(params) {
  ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartPluginStacked100
  );

  let newHeight = 300;
  if (params.DataChart.labels.length > 0) {
    newHeight = 300 + params.DataChart.labels.length * 20;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          flexDirection: "column",
          width: "100%",
          // height: "80px",
          backgroundColor: params.BackgroundColor,
        }}
      >
        <label
          style={{
            color: params.Color,
            fontSize: "23px",
            lineHeight: "normal",
            margin: "0px",
            display: "block",
            textAlign: "center",
            margin: "0px 0px 15px 0px",
          }}
        >
          {params.Text}
        </label>
        <div className="customScroll-1" style={{ maxHeight: "1000px" }}>
          <div style={{ height: `${newHeight}px` }}>
            <Bar
              data={params.DataChart}
              options={{
                maintainAspectRatio: false,
                indexAxis: "y",
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    beginAtZero: true,
                    stacked: true,
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        return `${context.dataset.label}: ${context.raw}`;
                      },
                    },
                  },
                  stacked100: {
                    enable: true,
                  },
                  legend: {
                    display: false,
                    // onClick: (evt, legendItem, legend) => {
                    //   // console.log(evt);
                    //   // console.log(legendItem.text);
                    //   // console.log(legend.chart.data.labels);
                    //   const index = legend.chart.data.labels.indexOf(
                    //     legendItem.text
                    //   );
                    //   legend.chart.toggleDataVisibility(index);
                    //   legend.chart.update();
                    // },

                    // labels: {
                    //   generateLabels: (chart) => {
                    //     // console.log(chart);
                    //     let visibility = [];
                    //     for (let i = 0; i < chart.data.labels.length; i++) {
                    //       if (chart.getDataVisibility(i) === true) {
                    //         visibility.push(false);
                    //       } else {
                    //         visibility.push(true);
                    //       }
                    //     }
                    //     return chart.data.labels.map((label, index) => ({
                    //       text: label,
                    //       strokeStyle: chart.data.datasets[0].borderColor[index],
                    //       fillStyle:
                    //         chart.data.datasets[0].backgroundColor[index],
                    //       hidden: visibility[index],
                    //     }));
                    //   },
                    // },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Horizontal_Bar_Chart;
