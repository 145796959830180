import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

function MasterUnit() {
  const [filter, setfilter] = useState({
    data: null,
  });

  const [unitAddData, setUnitAddData] = useState({
    unit_id: "",
    unit_name: "",
    unit_remark: "",
    mode: "add",
  });

  const[ filterTable,setFilterTable] = useState({
    is_check : true,
  });

  const [unitData, setUnitData] = useState({
    data: [
      // { unit_id: "1", unit_name: "t1", unit_remarks: "tt1" },
      // { unit_id: "1", unit_name: "t1", unit_remarks: "tt1" },
      // { unit_id: "1", unit_name: "t1", unit_remarks: "tt1" },
    ],
  });

  const removeUnit = (unit_id) => {
    Swal.fire({
      title: "คุณมั่นใจที่จะลบใช่หรือไม่",
      text: "คุณต้องการลบรายการ Unit ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url: Configs.API_URL_AIOI_Back + "/api/unit/removeUnit",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: {
            unit_id: unit_id,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire({
                icon: "success",
                title: "ลบข้อมูลสำเร็จ",
                allowEnterKey: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timer: 1500,
                showConfirmButton: false,
              }).then(() => {
                getTableUnit();
              });
            }
          })
          .catch(function (error) {
            console.log(error.response.data.error.message);
            if(error.response.data.error.message && error.response.data.error.message){
              if(error.response.data.error.message.includes("ข้อมูล Unit มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่")) {
                Swal.fire("Error", "ข้อมูล Unit มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่", "error")
                .then(() => {
                  window.location.reload()
                }) 
              } else {
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error.response.data, "error");
              }
            } else{
              Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            }
          });
      }
    });
  };

  const rowsData = [];
  for (let index = 0; index < unitData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["no2"] = index + 1;
    rowItem["unit_name"] = (
      <div style={{ textAlign: "left" }}>
        {unitData.data[index].unit_name || "-"}
      </div>
    );
    rowItem["unit_name"] = unitData.data[index].unit_name;
    rowItem["unit_remark"] = (
      <div style={{ textAlign: "left" }}>
        {unitData.data[index].unit_remark || "-"}
      </div>
    );
    rowItem["unit_remark2"] = unitData.data[index].unit_remark;

    rowItem["management"] = (
      <div
        className="row "
        style={{ flexWrap: "nowrap", margin: "0 auto",textAlign: "left"}}
      >
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={unitData.data[index].unit_id}
            id="btn_edit"
            className=" btn btn-xs "
            type="button"
            data-toggle="modal"
            data-target="#modal-add_supplier_rerationship"
            value="edit"
            // hidden={disable}
            onClick={() => {
              setUnitAddData({
                ...unitAddData,
                mode: "edit",
                unit_id : unitData.data[index].unit_id,
                unit_name : "",
                unit_remark : "",
              });
              getEditSelect(unitData.data[index].unit_id);

            }}
          >
            <i
              class="fas fa-pencil-alt"
              style={{
                fontSize: "14px",
              }}
            ></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={unitData.data[index].unit_id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              removeUnit(unitData.data[index].unit_id);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Unit Name"}</div>,
        field: "unit_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Unit Remark"}</div>,
        field: "unit_remark",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Unit Management"}</div>,
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  async function getEditSelect(unit_id) {
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/unit/getEditSelect",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        unit_id: unit_id,
      },
    })
      .then(async function (response) {
        // console.log("response", response);

        console.log(response.data);
        setUnitAddData({
          ...unitAddData,
          unit_id : response.data.unit_id,
          unit_name: response.data.unit_name,
          unit_remark: response.data.unit_remark,
          mode: "edit",
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getTableUnit() {
    let temp = {
      unit_company_id: getUser().com,
      unit_oem_id: getOem(),
      filter: filter.data?.trim() || null,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/unit/getTableUnit",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log("response", response);
        if (response.data) {
          console.log(response.data);
          setUnitData(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function checkSave() {
    let error = [];
    if (
      unitAddData.unit_name?.trim() === "" ||
      unitAddData.unit_name === null
    ) {
      error.push("กรุณากรอก Unit Name");
    }
    return error;
  }

  async function Add_Unit() {
    let error = await checkSave();
    if (error.length > 0) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: error.join("\n"),
        showConfirmButton: true,
      });
      return;
    }
    let temp = {
      unit_name: unitAddData.unit_name?.trim() || null,
      unit_remark: unitAddData.unit_remark?.trim() || null,
      unit_created_by: getUser().fup,
      unit_updated_by: getUser().fup,
      unit_created_name: getUser().name + " " + getUser().surname,
      unit_updated_name: getUser().name + " " + getUser().surname,
      unit_company_id: getUser().com,
      unit_oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/unit/addUnit",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log("response", response);
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "บันทึกสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(async (result) => {
            // window.location.reload();
            await getTableUnit();
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data.error.message);
        if(error.response.data.error.message && error.response.data.error.message){
          if(error.response.data.error.message.includes("ชื่อ Unit นี้มีอยู่ในระบบอยู่แล้ว")) {
            Swal.fire("Error", "ชื่อ Unit นี้มีอยู่ในระบบอยู่แล้ว", "error");
          } else {
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error.response.data, "error");
          }
        } else{
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        }
      });
  }

  async function saveEditUnit(unit_id) {
    let error = await checkSave();
    if (error.length > 0) {
      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: error.join("\n"),
        showConfirmButton: true,
      });
      return;
    }
    let temp = {
      unit_id : unit_id,
      unit_name: unitAddData.unit_name?.trim() || null,
      unit_remark: unitAddData.unit_remark?.trim() || null,
      unit_created_by: getUser().fup,
      unit_updated_by: getUser().fup,
      unit_created_name: getUser().name + " " + getUser().surname,
      unit_updated_name: getUser().name + " " + getUser().surname,
      unit_company_id: getUser().com,
      unit_oem_id: getOem(),
    };
    
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/unit/saveEditUnit",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        console.log("response", response);
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "บันทึกสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(async (result) => {
            // window.location.reload();
            await getTableUnit();
          });
        }
      })
      .catch(function (error) {
        console.log(error.response.data.error.message);
        if(error.response.data.error.message && error.response.data.error.message){
          if(error.response.data.error.message.includes("ชื่อ Unit นี้มีอยู่ในระบบอยู่แล้ว")) {
            Swal.fire("Error", "ชื่อ Unit นี้มีอยู่ในระบบอยู่แล้ว", "error");
          } else if (error.response.data.error.message.includes("ข้อมูล Unit มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่")){
            Swal.fire("Error", "ข้อมูล Unit มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่", "error");
          }else{
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error.response.data, "error");
          }
        } else{
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        }
      });
  }

  const clearFilter = async () => {
    setfilter({
      data: "",
    });
    filter.data = null;
    await getTableUnit();
    setFilterTable({
      is_check : true})
      filterTable.is_check = true;
  };

  function clearSave() {
    setUnitAddData({
      unit_name: "",
      unit_remark: "",
    });
    unitAddData.unit_name = "";
    unitAddData.unit_remark = "";
  }

  useEffect(async () => {
    await getTableUnit();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
          <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Unit
                  {"  "}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  {/* <li className="breadcrumb-item active">Human Resource</li> */}
                  <li className="breadcrumb-item active">Master Unit</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    id="text_empid"
                    required="false"
                    value={filter.data}
                    onChange={(e) =>
                      setfilter({
                        ...filter,
                        data: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="">Unit Search</label>{" "}
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info"
                    onClick={()=>{
                      setFilterTable({
                        is_checked: false})
                        filterTable.is_check = false
                      getTableUnit()
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      setFilterTable({
                        is_check : false})
                        filterTable.is_check = false
                      clearFilter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    id="btn_add"
                    value="add"
                    className="btn btn-block btn-primary"
                    type="button"
                    data-toggle="modal"
                    data-target="#modal-add_supplier_rerationship"
                    class="btn btn-block btn-primary "
                    // hidden={disable}
                    onClick={() => {
                      setUnitAddData({
                        ...unitAddData,
                        unit_name: "",
                        unit_remark: "",
                        mode: "add",
                      });
                      clearFilter();
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="card">
            <div className="card-header">
              <h4>Unit</h4>
            </div>
            <div className="card-body">
              <div
                className="table-responsive "
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  disableRetreatAfterSorting={filterTable.is_check}
                  fixedHeader
                  data={data}
                  //disableRetreatAfterSorting={MDBT}
                />
              </div>
            </div>
            <div className="card-footer" />
          </div>
        </section>

        <div className="modal fade" id="modal-add_supplier_rerationship">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-label">
                  {" "}
                  {unitAddData.mode === "add" ? "Add Unit" : "Edit Unit"}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <input
                        allowNegative={false}
                        type="text"
                        // disabled={disable}
                        className="form-control"
                        thousandSeparator={false}
                        value={unitAddData.unit_name}
                        onChange={(e) =>
                          setUnitAddData({
                            ...unitAddData,
                            unit_name: e.target.value,
                          })
                        }
                        required
                      />

                      <label htmlFor="">Unit Name :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <input
                        allowNegative={false}
                        type="text"
                        className="form-control"
                        // disabled={disable}
                        value={unitAddData.unit_remark}
                        onChange={(e) =>
                          setUnitAddData({
                            ...unitAddData,
                            unit_remark: e.target.value,
                          })
                        }
                        required
                      />

                      <label htmlFor="">Remark :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>

                  <div className="col-1"></div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  onClick={() =>{
                    if(unitAddData.mode === "add"){
                      console.log("Add")
                      Add_Unit(unitAddData.unit_id);
                    }  else if(unitAddData.mode === "edit"){
                      console.log("Edit")
                      saveEditUnit(unitAddData.unit_id);
                      
                    }
                  }}
                  data-dismiss="modal"
                  className="btn btn-primary"
                >
                  Save
                </button>

                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterUnit;
