import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import sparePart_excel from "../../assets/file/upload_SparePart.xlsx";
import Select from "react-select";



function Spare_part(mode) {
  const [SparePart, setSparePart] = useState([]);
  const [FillerSparePart, setFillerSparePart] = useState({
    spare_part_no: "",
    spare_part_name: "",
    product_no: "",
    company_id: getUser().com,
  });
  const [product_all, setproduct_all] = useState([]);
  const [product_selected, setproduct_selected] = useState(null);
  const [product_option, setproduct_option] = useState([]);
  const [SparePart_excel, setSparePart_excel] = useState([]);
  const [excel, setexcel] = useState({
    name: "",
    status: null,
  });
  const Gettable_all = async () => {
    var filter_product = {
      no: "",
      name: "",
      product_model_id: "",
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product,
    })
      .then(async (response) => {
        /*     console.log(response.data); */

        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;
          options.push(element);
        });

        setproduct_option(options);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetSparePart();
    Gettable_all();
  }, []);

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Spare_Part/add";
  }

  function GetSparePart(params) {
    axios({
      method: "post",
      url: Configs.API_URL + "/sparePart/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: FillerSparePart,
    })
      .then(function (response) {
        setSparePart(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function set_excel() {
    setexcel({ ...excel, status: null, name: "" });
    excel.status = null;
    excel.name = "";
    setSparePart_excel([]);
   window.location.href ="/Warehouse_Management/Factory_Master_Data/Spare_Part";
    /* window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product"; */
  }

  const upload_prod_file = async (e) => {
    if (e.target.files[0] && product_selected !== null) {

      var data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("company_id", getUser().com);
      data.append("product_id", product_selected.value);
      console.log(data);
      
      var config_uploadExecl = {
        method: "post",
        url: Configs.API_URL + "/sparePart/importExcel",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };
      
      axios(config_uploadExecl)
        .then(function (response)  {

          console.log(response.data);
          
          let num =0;
          for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].status===false) {
              num++;
            }
          }
          
          if (num!==0) {
            setSparePart_excel(response.data)
          }else{
            Swal.fire(
              "Upload Success",
              "Your file has been Upload.",
              "success"
            ).then(() => {
            window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Spare_part";
              setSparePart_excel(response.data)

            });

            setexcel({...excel,status:null,name:""});
          } 
        
        })
        .catch(function (error) {
          console.log(error);
        });
    } 

  };
  const clearFilter = async () => {
    await setFillerSparePart({
      ...FillerSparePart,
      spare_part_no: "",
      spare_part_name: "",
      product_no: "",
    });

    FillerSparePart.spare_part_no = "";
    FillerSparePart.spare_part_name = "";
    FillerSparePart.product_no = "";
    GetSparePart();
  };

  function Import_Excel(params) {}

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/sparePart/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Spare_part";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  const rowsData = [];

  for (var index = 0; index < SparePart.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["spare_part_no"] = SparePart[index].spare_part_no;
    rowItem["spare_part_name"] = SparePart[index].spare_part_name;
    rowItem["amount"] = SparePart[index].amount || 0;
    rowItem["product_ref"] = SparePart[index].product_ref.map((el, i) => {
      return (
        <div key={el.product_id}>
          {el.product_no}{" "}
          {i < SparePart[index].product_ref.length - 1 ? "," : ""}
        </div>
      );
    });
    rowItem["description"] = SparePart[index].description;

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Spare_part/read/" +
              SparePart[index].id
            }
            key={SparePart[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Spare_part/edit/" +
              SparePart[index].id
            }
            key={SparePart[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={SparePart[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, SparePart[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spare_Part_No",
        field: "spare_part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spare_Part_Name",
        field: "spare_part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product_ref",
        field: "product_ref",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Spare Part</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Spare_Part</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={FillerSparePart.spare_part_no.trim()}
                    onChange={(e) => {
                      setFillerSparePart({
                        ...FillerSparePart,
                        spare_part_no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Spare Part No</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={FillerSparePart.spare_part_name.trim()}
                    onChange={(e) => {
                      setFillerSparePart({
                        ...FillerSparePart,
                        spare_part_name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Spare Part Name</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={FillerSparePart.product_no.trim()}
                    onChange={(e) => {
                      setFillerSparePart({
                        ...FillerSparePart,
                        product_no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product ref</label>{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetSparePart();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    class="btn btn-block btn-info "
                    data-toggle="modal"
                    data-target="#modal-product"
                  >
                    Import Excel
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <a
                    href={sparePart_excel}
                    download="sparePart_excel.xlsx"
                    style={{ fontSize: "" }}
                  >
                    {" "}
                    <button className="btn btn-block btn-info">
                      Download Template.xls
                    </button>
                  </a>

                  {/*      <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={Download_Template}
                  >
                    Download Template sparePart_excel
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Spare Part</h3>
            </div>
            <div className="card-body">
              <div
                className="card-body table-responsive " /*  style={{ height: "100%" }} */
              >
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="modal fade" id="modal-product">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Import Spare Part</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                 // onClick={ window.location.href ="/Warehouse_Management/Factory_Master_Data/Spare_Part"}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                  <div className="row">
                    <div className="col-4">
                
                        <h3>
                          Part No. Selected :{" "}
                          {product_selected === null
                            ? "None"
                            : product_selected.label}
                        </h3>
                    </div>
                    <div className="col-4">
                    <label htmlFor="">Spare part file:</label>
                      <small style={{ color: "red" }}>
                        ** Excel from template **
                      </small>
                      </div>
                            </div>
                    <div className="row">
                      <div className="col-4">
                        <Select
                          options={product_option}
                          defaultValue={product_selected}
                          onChange={setproduct_selected}
                        />
                      </div>
                      <div className="col-6">
                    <div className="none-nolmalinput">
                     
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            /* disabled={} */
                            className="custom-file-input"
                            id="exampleInputFile"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={upload_prod_file}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            {excel.name !== null &&
                            excel.name !== "" &&
                            excel.name !== undefined
                              ? excel.name
                              : "Select File"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                      
                    </div>
                  </div>
                </div>
                <div className="row">
                  
                </div>
                              <br/>
                {/* <div className="row">
                  <div className="col-12">
                    <div
                      className="table-responsive" 
                    >
                      <MDBTable striped hover bordered small searching={true}>
                        <MDBTableHead>
                          <tr>
                            <th>No.</th>
                            <th>Spare Part No</th>
                            <th>Spare Part Name</th>
                            <th>Amount</th>
                            <th>Error</th>
                            <th>Update</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {sparePart_excel.map((el, index) => {
                            if (
                              sparePart_excel.filter((x) => !x.status_update)
                                .length > 0
                            ) {
                              var status_up = false;
                            } else {
                              var status_up = true;
                            }
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.model}</td>

                                <td>
                                  {el.status ? (
                                    <span style={{ color: "green" }}>True</span>
                                  ) : (
                                    <span style={{ color: "red" }}>False</span>
                                  )}
                                </td>
                                <td>{el.error}</td>
                                <td>
                                  {status_up ? (
                                    <span style={{ color: "green" }}>
                                      Success
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>False</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-4">
                  <h3>
                      {excel.status !== null ? (
                        <span>
                          {excel.status === true
                            ? "Upload Success"
                            : "Upload Fail"}
                        </span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={set_excel}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Spare_part;
