import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "../Expenses_Approve/Expenses_Approve_Table.css";
import img_pay_slips from "../../../assets/img/accounting/Capa_1.png";
import img_report_icon from "../../../assets/img/accounting/Frame.png";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button as ButtonAnt, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import ComponentDashboard2 from "./ComponentDashboard2";
import ComponentDashboard1 from "./ComponentDashboard1";
import ComponentDashboard3 from "./ComponentDashboard3";
const CustomInput = ({ value, onClick }) => (
  <div className="input-group">
    <input
      type="text"
      className="form-control float-left"
      onClick={onClick}
      value={value}
    />{" "}
    <div className="input-group-prepend">
      <span className="input-group-text">
        <i className="far fa-calendar-alt" />
      </span>
    </div>
  </div>
);

function Accounting_Dashboard() {

  const [functionComponenet, setFunctionComponenet] = useState({
    getDataChart2: null,
    getDataChart3: null,
    getDataChart1: null,
  });
  const [costSumTotal, setCostSumTotal] = useState({ total: 0 });
  const [dateRange, setDateRange] = useState([null, null]);
  let [startDate, endDate] = dateRange;

  async function searchData() {
    if (functionComponenet.getDataChart2) {
      functionComponenet.getDataChart2(dateRange[0] || dateRange[1], dateRange[1] || dateRange[0]);
    }
    if (functionComponenet.getDataChart3) {
      functionComponenet.getDataChart3(dateRange[0] || dateRange[1], dateRange[1] || dateRange[0]);
    }
    if (functionComponenet.getDataChart1) {
      functionComponenet.getDataChart1(dateRange[0] || dateRange[1], dateRange[1] || dateRange[0]);
    }
  }

  async function clearSearchData() {
    const start_month = new Date(moment().startOf("month"));
    const end_month = new Date(moment().endOf("month"));

    setDateRange([start_month, end_month]);
    [startDate, endDate] = [start_month, end_month];
    searchData();
  }

  useEffect(async () => {
    const start_month = new Date(moment().startOf("month"));
    const end_month = new Date(moment().endOf("month"));

    setDateRange([start_month, end_month]);
    [startDate, endDate] = [start_month, end_month];

    if (functionComponenet.getDataChart2) {
      functionComponenet.getDataChart2(start_month, end_month);
    }
    if (functionComponenet.getDataChart3) {
      functionComponenet.getDataChart3(start_month, end_month);
    }
    if (functionComponenet.getDataChart1) {
      functionComponenet.getDataChart1(start_month, end_month);
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="wrapper">
        {/* <Header />
  <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Dashboard</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>

                    <li className="breadcrumb-item active">Human Resource</li>
                    <li className="breadcrumb-item active">Accounting</li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-12 col-xl-6 mb-3">
                <div className="card" style={{ height: "100%" }}>
                  <div className="card-body">
                    <div
                      className="row"
                      style={{
                        alignItems: "flex-end",
                      }}
                    >
                      <div className="col-12 col-md-6">
                        <div style={{ padding: "10px 0px" }}>
                          <label
                            style={{
                              margin: "0px 0px 0px 2px",
                            }}
                          >
                            วันที่
                          </label>
                          <DatePicker
                            wrapperClassName="w-100"
                            locale="th"
                            id="text_start_work"
                            dateFormat={"dd-MM-yyyy"}
                            value={`${moment(startDate).format(
                              "DD/MM/YYYY"
                            )} - ${moment(endDate).format("DD/MM/YYYY")}`}
                            selected={startDate}
                            onChange={async (update) => {
                              setDateRange(update);
                            }}
                            shouldCloseOnSelect={
                              !(!startDate || Boolean(startDate && endDate))
                            }
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={true}
                            // isClearable={true}
                            showMonthDropdown
                            showYearDropdown
                            disabledKeyboardNavigation
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            // todayButton="Today"
                            customInput={<CustomInput />}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-md-3">
                        <div style={{ padding: "10px 0px" }}>
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              searchData();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-6 col-md-3">
                        <div style={{ padding: "10px 0px" }}>
                          <button
                            type="button"
                            id="btn_clear"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              clearSearchData();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-xl-6 mb-3">
                <div className="card" style={{ height: "100%" }}>
                  <div className="card-body" style={{ alignContent: "center" }}>
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        fontSize: "36px",
                      }}
                    >
                      ยอดรวม <span style={{ color: "#3579F6" }}>
                        {
                          !costSumTotal.total 
                          ? 0 
                          : costSumTotal.total.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        }
                      </span> บาท
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="content">
            <div className="row">
              <div className="col-md-12 col-xl-6 mb-3">
                <div className="card" style={{ height: "100%" }}>
                  <div className="card-body"></div>
                </div>
              </div>

              <div className="col-md-12 col-xl-6 mb-3">
                <div className="card" style={{ height: "100%" }}>
                  <div className="card-body"></div>
                </div>
              </div>
            </div>
          </section> */}

          <div className="p-2">
            <ComponentDashboard1
              costSumTotal={costSumTotal}
              setCostSumTotal={setCostSumTotal}
              functionComponenet={functionComponenet} 
              setFunctionComponenet={setFunctionComponenet} 
            />
          </div>

          <div className="p-2">
            <ComponentDashboard2
              functionComponenet={functionComponenet} 
              setFunctionComponenet={setFunctionComponenet} 
            />
          </div>

          <div className="p-2">
            <ComponentDashboard3
              functionComponenet={functionComponenet} 
              setFunctionComponenet={setFunctionComponenet} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accounting_Dashboard;
