import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";
import axios from "axios";
import { getOem, getToken, getUser, getFeature, getComConfig, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import jsPDF from "jspdf";
import numeral from "numeral";
import moment from "moment";

function Perchase_po_report(company, toolings, po_sign, statusCheck) {
    console.log("tetstststtsetessetsetes", toolings);
    // const [po_sign, setpo_sign] = useState(getComConfig());
    /* var testjson = JSON.stringify(rowsDataForPO)
      console.log( JSON.parse(testjson));
      
    */

    var poConfig = {
        no: "",
        our_ref: "",
        your_ref: "",
    }
    /* const [poConfig, setPoConfig] = useState({
        no: "",
        our_ref: "",
        your_ref: "",
    }); */

    var Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
    //console.log(supplier);
    //console.log(rowsDataForPO);
    const doc = new jsPDF("p", "mm", "a4");
    var counts = 0;
    for (let index = 0; index < toolings.perchaseReport.length; index+= 15) {
        doc.addImage(Com_logo, 'JPEG', 20, 10, 25, 25)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        doc.addFileToVFS('THSarabunNew.ttf', Get_font_pdf_th2())
        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew');
        //doc.addFileToVFS('ANGSA.ttf',Get_font_pdf_th())
        //doc.addFont('ANGSA.ttf', 'custom', 'normal');
        //doc.setFont('custom');
        doc.text(52, 18, 'TTT Brother Co., Ltd.')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(52, 24, '852/8 ถนนหลวงแพ่ง แขวงทับยาว เขตลาดกระบัง กรุงเทพฯ 10520 (สำนักงานใหญ่)')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(52, 30, 'Bangkok 10520. Tel.02-7380623 Fax.02-1729639 เลขประจำตัวผู้เสียภาษี : 0105539003283')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /*  doc.addFont('ANGSA.ttf', 'custom', 'normal');
         doc.setFont('custom'); */
        doc.text(105, 40, 'PURCHASE ORDER', 'center')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
        doc.setFont('custom'); */
        doc.text(105, 47, 'ใบสั่งซื้อ', 'center')


        /*---------------------------------------------------------------------------*/

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(23, 55, 'Supplier')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(55, 55, ':')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(40, 55, 'บจก.')
        //data
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
        doc.setFont('custom'); */
        doc.text(60, 55, `${toolings.sup_name ? toolings.sup_name : "-"}`)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(23, 60, 'ผู้ขาย')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(40, 60, 'คุณ')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(55, 60, ':')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
        doc.setFont('custom'); */
        doc.text(60, 60, `${toolings.sup_contact_name === null || toolings.sup_contact_name === '' ? "-" : toolings.sup_contact_name}`)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(40, 65, 'Tel')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(55, 65, ':')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
          doc.setFont('custom'); */
        doc.text(60, 65, `${toolings.sup_contact_tel === null || toolings.sup_contact_tel === '' ? "-" : toolings.sup_contact_tel}`)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(40, 70, 'Fax')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(55, 70, ':')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
        doc.setFont('custom'); */
        doc.text(60, 70, `${toolings.sup_contact_fax === null || toolings.sup_contact_fax === '' ? "-" : toolings.sup_contact_fax}`)

        /* doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(23, 75, 'Your Ref.   No.')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(55, 75, ':') */

        /* doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(60, 75, poConfig.your_ref) */

        //right
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(130, 55, 'PO No.')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(150, 55, ':')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(155, 55, toolings.po_no)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(130, 60, 'Date')
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(150, 60, ':')



        //var today = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
        Date.prototype.addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }
        var date = new Date();
        var today = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(155, 60, today)

        /* doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(130, 75, 'Our Ref.')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(155, 75, poConfig.our_ref)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(150, 75, ':')*/
        doc.rect(20, 50, 170, 27) 

        /*-------------------------------------------------------------------------------*/
        //header
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(24, 82, 'Item')
        doc.setFontSize(12)
        doc.text(24, 88, 'ลำดับ')
        doc.rect(20, 77, 15, 13)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(80, 82, 'Description', 'center')
        doc.setFontSize(12)
        doc.text(80, 88, 'รายการ', 'center')
        doc.rect(35, 77, 95, 13)

        /* doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(118, 82, 'Delivery dd.', 'center')
        doc.setFontSize(12)
        doc.text(118, 88, 'กำหนดการส่งสินค้า', 'center')
        doc.rect(105, 77, 25, 13) */

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(138, 82, 'Qunitity', 'center')
        doc.setFontSize(12)
        doc.text(139, 88, 'จำนวนหน่วย', 'center')
        doc.rect(130, 77, 18, 13)


        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(158, 82, 'Unit Price', 'center')
        doc.setFontSize(12)
        doc.text(158, 88, 'ราคาต่อหน่วย', 'center')
        doc.rect(148, 77, 20, 13)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)
        doc.text(179, 82, 'Amount', 'center')
        doc.setFontSize(12)
        doc.text(179, 88, 'จำนวนเงิน', 'center')
        doc.rect(168, 77, 22, 13)
        /* console.log(rowsDataForPO.materail[0].no) */
        var row = 0
        var totalByPO = 0;
        // var material_dataReport = material.filter((e) => { return e.supplier_id === supplier[index].supplier_id });
        for (let index2 = 0; index2 < 15; index2++) {
            for (let i = 0; row < 15; i = i + 8) {
                if (index + row < toolings.perchaseReport.length) {

                    doc.setTextColor(0, 0, 0)
                    doc.setFontSize(12)
                    doc.text(26, 95 + i, (row + 1).toString(), 'center') //item mat

                    doc.setTextColor(0, 0, 0)
                    doc.setFontSize(12)
                    doc.text(38, 95 + i, `${toolings.perchaseReport[index + row].ol_no} ,${toolings.perchaseReport[index + row].product_name} ,${toolings.perchaseReport[index + row].ol_model_name}`) //description mat

                    doc.setTextColor(0, 0, 0)
                    doc.setFontSize(12)

                    Date.prototype.addDays = function (days) {
                        var date = new Date(this.valueOf());
                        date.setDate(date.getDate() + days);
                        return date;
                    }

                    /* var date = new Date();
                    var format_date = `${toolings.perchaseReport[row].lead_time === null ? "-" :
                        moment(new Date()).add(parseInt(toolings.perchaseReport[row].lead_time), 'days').format('DD/MM/YYYY')}` */

                    // doc.text(118, 95 + i, format_date, 'center') //delivery dd

                    doc.setTextColor(0, 0, 0)
                    doc.setFontSize(12)
                    doc.text(147, 95 + i, numeral(toolings.perchaseReport[index + row].ol_amount).format('0,0'), 'right') //qunitity mat

                    let unit = 0;
                    let sumAmount = 0
                    const amount = (toolings.perchaseReport[index + row].ol_amount !== null && toolings.perchaseReport[index + row].ol_amount !== '') ? parseFloat(toolings.perchaseReport[index + row].ol_amount) : 0;
                    const price = (toolings.perchaseReport[index + row].price !== null && toolings.perchaseReport[index + row].price !== '') ? parseFloat(toolings.perchaseReport[index + row].price) : 0;
                    const buy_price = (toolings.perchaseReport[index + row].ol_point !== null && toolings.perchaseReport[index + row].ol_point !== '') ? parseFloat(toolings.perchaseReport[index + row].ol_point) : 0;
                    unit = price / amount;
                    sumAmount = amount * buy_price;
                    // console.log('unit', unit);
                    doc.setTextColor(0, 0, 0)
                    doc.setFontSize(12)
                    doc.text(167, 95 + i, `${buy_price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`, 'right') //unit price

                    doc.setTextColor(0, 0, 0)
                    doc.setFontSize(12)
                    let amount_ = 0
                    // amount_ = material_dataReport[row].buy_price * material_dataReport[row].nms
                    totalByPO += sumAmount;
                    doc.text(189, 95 + i, sumAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 'right')
                }
                doc.rect(20, 90 + i, 15, 8) //item
                doc.rect(35, 90 + i, 95, 8) // description
                // doc.rect(105, 90 + i, 25, 8) // delivery dd.
                doc.rect(130, 90 + i, 18, 8) //qunitity
                doc.rect(148, 90 + i, 20, 8) //unit price
                doc.rect(168, 90 + i, 22, 8) //amount
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(15)
                //doc.text(23, 92+i, materail[i].no,'center')
                row = row + 1
                counts = toolings.perchaseReport.length - 15;
            }
        }
        /*------------------------------------------------------------------------*/
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(20, 216, 'PLEASE DELIVER THE GOODS ON/BEFORE')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(149, 216, 'Total')
        doc.rect(148, 210, 20, 8)
        //value total

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(189, 215, totalByPO.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 'right')
        doc.rect(168, 210, 22, 8)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(20, 224, 'ให้ส่งสินค้าภายในวันที่  :')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        let vat = 0
        vat = totalByPO * 0.07
        doc.text(149, 224, 'Vat 7 %')
        doc.text(189, 223, vat.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 'right')
        doc.rect(148, 218, 20, 8)

        //value
        doc.rect(168, 218, 22, 8)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')

        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(20, 232, 'PLEASE DELIVER THE GOODS TO: ')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(149, 232, 'Grand Total')
        let grand_total = totalByPO + vat
        doc.text(189, 231, grand_total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), 'right')
        doc.rect(148, 226, 20, 8)

        //value grand
        doc.rect(168, 226, 22, 8)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(20, 240, 'ให้ส่งของที่  :   ')

        doc.setTextColor(255, 0, 0)
        doc.setFontSize(16)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(38, 240, po_sign.com_name)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(22, 248, 'กรุณาเซ็นต์ ยืนยันตรวจรับใบสั่งซื้อ')
        doc.rect(20, 244, 65, 25)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(128, 248, 'APPROVED BY')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(15)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(128, 260, 'อนุมัติโดย…………………………………..')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(22, 260, 'ผู้รับใบสั่งซื้อ .................................................................')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        doc.text(22, 264, 'และแฟ็กซ์กลับ 02-172-9639')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(20, 272, 'Remark the PO. NO. Must be show on the invoice and delivery note.')


        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(145, 265, '( ' + po_sign.po_sign + ' )')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(20, 276, 'หมายเหตุ : จะต้องระบุเลขที่ใบสั่งซื้อในใบส่งสินค้าทุกครั้ง')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(20, 284, 'FORM 47-08')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(128, 272, '***กรุณาแนบใบ Cer ทุกครั้งที่ส่งวัตถุดิบ')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(128, 276, 'หรือส่งทาง อีเมล์ : shimiz_thai@hotmail.com')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(128, 280, '**กรุณาส่งของตาม PO.ระบุ วันที่/ส่งของ')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12)

        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        /* doc.addFont('ANGSA.ttf', 'th', 'normal');
        doc.setFont('th'); */
        // doc.text(128, 284, 'ถ้าไม่มี PO.สั่งซื้องดรับของ ขอบคุณค่ะ')


        /* doc.text(38, 240, po_sign.com_name)
 
        doc.text(145, 265, po_sign.po_sign)  */


        if (index < counts) {
            doc.addPage("a4", "h");
        }
    }

    if (statusCheck === true) {
        return doc.output('datauristring');
    } else {
        window.open(doc.output("bloburl"));
    }


}

export default Perchase_po_report