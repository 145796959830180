import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import axios from "axios";
import Configs from "../../../../config";
import Swal from "sweetalert2";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import PieChart from "./pie_chart";
import DoughnutChart from "./doughnut_chart";
import HorizontalBarChart from "./horizontal_bar_chart";
import VerticalBarChart from "./vertical_bar_chart";

function Dashboard_Booking() {
  const [checkDRAS, setCheckDRAS] = useState({
    bool: false,
  });

  const [searchData, setSearchData] = useState({
    user_id: getUser().fup,
    oem_id: getOem(),
    company_id: getUser().com,
    startDate: "",
    endDate: "",
    userBooking: [],
    roomBooking: [],
    rangeTimeBooking: [],
  });

  const [filterDate, setFilterDate] = useState({
    start_date: "",
    end_date: "",
  });

  const [employeeWorkTypeData, setEmployeeWorkTypeData] = useState({
    ori: [],
  });

  const [employeeData, setEmployeeData] = useState({
    ori: [],
    dropdown: [],
  });

  const [roomData, setRoomData] = useState({
    ori: [],
    dropdown: [],
  });

  const [roomAccessoriesData, setRoomAccessoriesData] = useState({
    ori: [],
  });

  const [rangeTimeData, setRangeTimeData] = useState({
    dropdown: [
      {
        value: "1",
        label: "ช่วงเช้า (08.30-12.00)",
      },
      {
        value: "2",
        label: "ช่วงบ่าย (12.00-17.30)",
      },
      {
        value: "3",
        label: "ช่วงค่ำ (17.30-08.30)",
      },
    ],
  });

  const [firstPartData, setFirstPartData] = useState({
    sumRoom: 0,
    hall: 0,
    hotel_meet: 0,
    hotel: 0,
  });

  const [secondPartData, setSecondPartData] = useState({
    range_1: 0,
    range_2: 0,
    range_3: 0,
  });

  const [thirdPartData, setThirdPartData] = useState({
    hall: [],
    hotel_meet: [],
  });

  const [fourthPartData, setFourthPartData] = useState({
    room_label: [],
    room_all: [],
    room_sick: [],
    room_sum: [],
  });

  const [fifthPartData, setFifthPartData] = useState({ data: [] });

  async function GetEmployeeWorkType() {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [];

        for (let item of response.data.data) {
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
        }

        setEmployeeWorkTypeData({
          ori: arrList,
        });
        employeeWorkTypeData.ori = arrList;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function FilterEmployee() {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      resign_status: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        let empAll = [];

        for (let item of employeeWorkTypeData.ori) {
          let empEqualType = response.data.data.filter((empItem) => {
            return empItem.emp_contract_type_id === item.id;
          });

          empEqualType.sort((a, b) => {
            if (a.emp_no === null) {
              return 1;
            }

            if (b.emp_no === null) {
              return -1;
            }

            if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
              return 0;
            }

            return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
          });

          empAll.push(...empEqualType);
        }

        const options = [];

        empAll.forEach((el) => {
          var element = {};

          element["value"] = el.emp_id;
          element["label"] = `${el.emp_no || ""} ${
            el.emp_nickname ? "( " + el.emp_nickname + " )" : ""
          } `;

          options.push(element);
        });

        setEmployeeData({
          ori: empAll,
          dropdown: options,
        });
        employeeData.ori = empAll;
        employeeData.dropdown = options;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetHotelRoom() {
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/booking/getHotelRoom",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const options = [
          {
            value: "00000000-0000-0000-0000-000000000000",
            label: "TTT Hall",
          },
          {
            value: "00000000-0000-0000-0000-000000000001",
            label: "TTT Hotel Meeting",
          },
        ];

        for (const item of response.data.data) {
          let element = {};

          element["value"] = item.bhr_id;
          element["label"] = item.bhr_name;

          options.push(element);
        }

        setRoomData({
          ori: response.data.data,
          dropdown: options,
        });
        roomData.ori = response.data.data;
        roomData.dropdown = options;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetRoomAccessories() {
    await axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/booking/getRoomAccessories",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setRoomAccessoriesData({
          ori: response.data.data,
        });
        roomAccessoriesData.ori = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function RoomBookingResult() {
    const tempData = JSON.parse(JSON.stringify(searchData));

    tempData.userBooking =
      tempData.userBooking?.map((item) => {
        return item.value;
      }) || [];

    tempData.roomBooking =
      tempData.roomBooking?.map((item) => {
        return item.value;
      }) || [];

    tempData.rangeTimeBooking =
      tempData.rangeTimeBooking?.map((item) => {
        return item.value;
      }) || [];

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/booking/roomBookingResult",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        fetchStateSearchReport();
        const allRoomData = [];

        for (const itemOut of response.data.data?.range_1 || []) {
          if (
            allRoomData.filter((itemIn) => {
              return itemIn.booking_id == itemOut.booking_id;
            }).length == 0
          ) {
            allRoomData.push(itemOut);
          }
        }

        for (const itemOut of response.data.data?.range_2 || []) {
          if (
            allRoomData.filter((itemIn) => {
              return itemIn.booking_id == itemOut.booking_id;
            }).length == 0
          ) {
            allRoomData.push(itemOut);
          }
        }

        for (const itemOut of response.data.data?.range_3 || []) {
          if (
            allRoomData.filter((itemIn) => {
              return itemIn.booking_id == itemOut.booking_id;
            }).length == 0
          ) {
            allRoomData.push(itemOut);
          }
        }

        // ----- First Part ----- //
        const roomHallData = allRoomData.filter((item) => {
          return item.room_id === "00000000-0000-0000-0000-000000000000";
        });

        const roomHotelMeetData = allRoomData.filter((item) => {
          return item.room_id === "00000000-0000-0000-0000-000000000001";
        });

        const roomHotelData = allRoomData.filter((item) => {
          return (
            item.room_id !== "00000000-0000-0000-0000-000000000000" &&
            item.room_id !== "00000000-0000-0000-0000-000000000001"
          );
        });

        setFirstPartData({
          sumRoom: allRoomData.length,
          hall: roomHallData.length,
          hotel_meet: roomHotelMeetData.length,
          hotel: roomHotelData.length,
        });
        firstPartData.sumRoom = allRoomData.length;
        firstPartData.hall = roomHallData.length;
        firstPartData.hotel_meet = roomHotelMeetData.length;
        firstPartData.hotel = roomHotelData.length;
        // ----- First Part ----- //

        // ----- Second Part ----- //
        setSecondPartData({
          range_1: response.data.data?.range_1?.length || 0,
          range_2: response.data.data?.range_2?.length || 0,
          range_3: response.data.data?.range_3?.length || 0,
        });
        secondPartData.range_1 = response.data.data?.range_1?.length || 0;
        secondPartData.range_2 = response.data.data?.range_2?.length || 0;
        secondPartData.range_3 = response.data.data?.range_3?.length || 0;
        // ----- Second Part ----- //

        // ----- Third Part ----- //
        const accessoriesHallData = [];
        const accessoriesHotelMeetData = [];

        for (const itemOut of roomAccessoriesData.ori) {
          const tempAccessoriesHall = roomHallData.filter((itemIn) => {
            return itemIn.accessories.includes(itemOut.bacc_id);
          });

          accessoriesHallData.push(tempAccessoriesHall.length);

          const tempAccessoriesHotelMeet = roomHotelMeetData.filter(
            (itemIn) => {
              return itemIn.accessories.includes(itemOut.bacc_id);
            }
          );

          accessoriesHotelMeetData.push(tempAccessoriesHotelMeet.length);
        }

        setThirdPartData({
          hall: accessoriesHallData,
          hotel_meet: accessoriesHotelMeetData,
        });
        thirdPartData.hall = accessoriesHallData;
        thirdPartData.hotel_meet = accessoriesHotelMeetData;
        // ----- Third Part ----- //

        // ----- Third Part ----- //
        const roomCountType = [];

        for (const itemOut of roomData.dropdown) {
          const roomCount = allRoomData.filter((itemIn) => {
            return itemIn.room_id == itemOut.value;
          });

          roomCountType.push({
            label: itemOut.label,
            all: roomCount.filter((itemIn) => {
              return itemIn.objective1 != true;
            }).length,
            sick: roomCount.filter((itemIn) => {
              return itemIn.objective1 == true;
            }).length,
            sum: roomCount.length,
          });
        }

        roomCountType.sort((a, b) => {
          if (a.sum < b.sum) {
            return 1;
          }

          if (a.sum > b.sum) {
            return -1;
          }
        });

        const room_label = [];
        const room_all = [];
        const room_sick = [];
        const room_sum = [];

        for (const itemOut of roomCountType) {
          room_label.push(itemOut.label);
          room_all.push(itemOut.all);
          room_sick.push(itemOut.sick);
          room_sum.push(itemOut.sum);
        }

        setFourthPartData({
          room_label: room_label,
          room_all: room_all,
          room_sick: room_sick,
          room_sum: room_sum,
        });
        fourthPartData.room_label = room_label;
        fourthPartData.room_all = room_all;
        fourthPartData.room_sick = room_sick;
        fourthPartData.room_sum = room_sum;
        // ----- Fourth Part ----- //

        // ---- Fifth Part ------ //
        const tableData = [], dup_emp_id = {}, dropdown_emp = [];

        for (let item of response.data.data?.range_1) {
          const _findIdx = tableData.findIndex(
            (el) =>
              el.emp_no == `${item.booker_no} (${item.booker_nickname})` &&
              el.room == item.room_name &&
              el.type == 1
          );
          if (_findIdx != -1) {
            tableData[_findIdx].count_reserve += 1;
          } else {
            tableData.push({
              emp_no: `${item.booker_no} (${item.booker_nickname})`,
              room: item.room_name,
              range_date: "ช่วงเช้า (8.30 - 12.00)",
              type: 1,
              count_reserve: 1,
            });
          }

          if (!dup_emp_id[item.booker_id]) {
            dropdown_emp.push({ value: item.booker_id, label: `${item.booker_no} (${item.booker_nickname})` });
            dup_emp_id[item.booker_id] = item.booker_id;
          }
        }

        for (let item of response.data.data?.range_2) {
          const _findIdx = tableData.findIndex(
            (el) =>
              el.emp_no == `${item.booker_no} (${item.booker_nickname})` &&
              el.room == item.room_name &&
              el.type == 2
          );
          if (_findIdx != -1) {
            tableData[_findIdx].count_reserve += 1;
          } else {
            tableData.push({
              emp_no: `${item.booker_no} (${item.booker_nickname})`,
              room: item.room_name,
              range_date: "ช่วงบ่าย (12.00 - 17.30)",
              type: 2,
              count_reserve: 1,
            });
          }

          if (!dup_emp_id[item.booker_id]) {
            dropdown_emp.push({ value: item.booker_id, label: `${item.booker_no} (${item.booker_nickname})` });
            dup_emp_id[item.booker_id] = item.booker_id;
          }
        }

        for (let item of response.data.data?.range_3) {
          const _findIdx = tableData.findIndex(
            (el) =>
              el.emp_no == `${item.booker_no} (${item.booker_nickname})` &&
              el.room == item.room_name &&
              el.type == 3
          );
          if (_findIdx != -1) {
            tableData[_findIdx].count_reserve += 1;
          } else {
            tableData.push({
              emp_no: `${item.booker_no} (${item.booker_nickname})`,
              room: item.room_name,
              range_date: "ช่วงค่ำ (17.30 - 8.30)",
              type: 3,
              count_reserve: 1,
            });
          }

          if (!dup_emp_id[item.booker_id]) {
            dropdown_emp.push({ value: item.booker_id, label: `${item.booker_no} (${item.booker_nickname})` });
            dup_emp_id[item.booker_id] = item.booker_id;
          }
        }

        const finalTableData = tableData.sort(
          (a, b) =>
            b.count_reserve - a.count_reserve ||
            a.emp_no.localeCompare(b.emp_no) ||
            a.room.localeCompare(b.room) ||
            a.type - b.type
        );

        setFifthPartData({
          data: finalTableData,
        });
        fifthPartData.data = finalTableData;

        setEmployeeData({
          ...employeeData,
          dropdown: dropdown_emp,
        });
        employeeData.dropdown = dropdown_emp;
        // ---- Fifth Part ------ //
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const rowsEmployeeReserve = [];

  function formatEmployeeReserve(index, data) {
    let rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["emp_no"] = <div style={{ textAlign: "left" }}>{data.emp_no}</div>;
    rowItem["emp_no_search"] = data.emp_no;

    rowItem["room"] = <div style={{ textAlign: "left" }}>{data.room}</div>;
    rowItem["room_search"] = data.room;

    rowItem["range_date"] = (
      <div style={{ textAlign: "left" }}>{data.range_date}</div>
    );
    rowItem["range_date_search"] = data.range_date;

    rowItem["count_reserve"] = data.count_reserve;

    return rowItem;
  }

  for (let index = 0; index < fifthPartData.data.length; index += 1) {
    rowsEmployeeReserve.push(
      formatEmployeeReserve(index, fifthPartData.data[index])
    );
  }

  const tableEmployeeReserve = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อผู้จอง",
        field: "emp_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ห้องที่จอง",
        field: "room",
        // sort: "asc",
        width: 10,
      },
      {
        label: "ช่วงเวลาที่จอง",
        field: "range_date",
        // sort: "asc",
        width: 10,
      },
      {
        label: "จำนวนครั้งที่จอง",
        field: "count_reserve",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsEmployeeReserve,
  };

  function fetchStateSearchReport() {
    setFilterDate({
      ...filterDate,
      start_date: searchData.startDate,
      end_date: searchData.endDate,
    });
    filterDate.start_date = searchData.startDate;
    filterDate.end_date = searchData.endDate;
  }

  async function dataSearch() {
    if (searchData.startDate != filterDate.start_date || searchData.endDate != filterDate.end_date) {
      setSearchData({
        ...searchData,
        userBooking: [],
      });
      searchData.userBooking = [];
    }
    await RoomBookingResult();
  }

  async function clearDataSearch() {
    const startDate = new Date(moment().weekday(1));
    const endDate = new Date(moment().weekday(5));

    setSearchData({
      ...searchData,
      startDate: startDate,
      endDate: endDate,
      userBooking: [],
      roomBooking: [],
      rangeTimeBooking: [],
    });
    searchData.startDate = startDate;
    searchData.endDate = endDate;
    searchData.userBooking = [];
    searchData.roomBooking = [];
    searchData.rangeTimeBooking = [];

    await RoomBookingResult();
  }

  const pieChartData = {
    labels: ["TTT Hall", "TTT Hotel Meeting", "TTT Hotel"],
    datasets: [
      {
        data: [
          firstPartData.hall || 0,
          firstPartData.hotel_meet || 0,
          firstPartData.hotel || 0,
        ],
        backgroundColor: ["#55B6FB", "#AEFD54", "#50FFC4"],
        borderColor: ["#55B6FB", "#AEFD54", "#50FFC4"],
        borderWidth: 1,
        hoverBackgroundColor: ["#38a9fa", "#a0fd35", "#33ffbb"],
        hoverBorderColor: ["#38a9fa", "#a0fd35", "#33ffbb"],
        hoverOffset: 10,
      },
    ],
  };

  const doughnutChartData = {
    labels: ["ช่วงเช้า", "ช่วงบ่าย", "ช่วงค่ำ"],
    datasets: [
      {
        data: [
          secondPartData.range_1 || 0,
          secondPartData.range_2 || 0,
          secondPartData.range_3 || 0,
        ],
        backgroundColor: ["#55B6FB", "#AEFD54", "#50FFC4"],
        borderColor: ["#55B6FB", "#AEFD54", "#50FFC4"],
        borderWidth: 1,
        hoverBackgroundColor: ["#38a9fa", "#a0fd35", "#33ffbb"],
        hoverBorderColor: ["#38a9fa", "#a0fd35", "#33ffbb"],
        hoverOffset: 10,
      },
    ],
  };

  const horizontalBarChartData = {
    labels: roomAccessoriesData.ori.map((item) => {
      return item.bacc_name;
    }),
    datasets: [
      {
        label: "TTT Hall",
        data: thirdPartData.hall,
        backgroundColor: ["#1AC9E6"],
        // borderColor: ["#1AC9E6"],
        // borderWidth: 1,
        hoverBackgroundColor: ["#5ed8ed"],
        // hoverBorderColor: ["#5ed8ed"],
      },
      {
        label: "TTT Hotel Meeting",
        data: thirdPartData.hotel_meet,
        backgroundColor: ["#176BA0"],
        // borderColor: ["#176BA0"],
        // borderWidth: 1,
        hoverBackgroundColor: ["#2096df"],
        // hoverBorderColor: ["#2096df"],
      },
    ],
  };

  const verticalBarChartData = {
    labels: fourthPartData.room_label,
    datasets: [
      {
        label: "จองห้อง",
        data: fourthPartData.room_all,
        backgroundColor: ["#176BA0"],
        // borderColor: ["#176BA0"],
        // borderWidth: 1,
        hoverBackgroundColor: ["#2096df"],
        // hoverBorderColor: ["#2096df"],
      },
      {
        label: "ป่วย",
        data: fourthPartData.room_sick,
        backgroundColor: ["#1AC9E6"],
        // borderColor: ["#1AC9E6"],
        // borderWidth: 1,
        hoverBackgroundColor: ["#5ed8ed"],
        // hoverBorderColor: ["#5ed8ed"],
      },
    ],
  };

  async function downloadExcelDashboard() {
    Swal.fire({
      title: "กำลังออกรายงาน",
      text: "Loading...",
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const tempData = {
      start_date: new Date(moment(filterDate.start_date).startOf("D")),
      end_date: new Date(moment(filterDate.end_date).endOf("D")),
    };
    tempData.userBooking =
      searchData.userBooking?.map((item) => {
        return item.value;
      }) || [];

    tempData.roomBooking =
      searchData.roomBooking?.map((item) => {
        return item.value;
      }) || [];

    tempData.rangeTimeBooking =
      searchData.rangeTimeBooking?.map((item) => {
        return item.value;
      }) || [];

    const response = await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/booking/getReportToBase64",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    });
    const data = await response.data.data;
    // Create a data URL for the PDF
    Swal.close();
    const pdfDataUrl = `data:application/pdf;base64,${data}`;
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = `ข้อมูลการจองห้อง-dashboard${moment().format(
      "DD-MM-YYYY"
    )}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after click
  }

  useEffect(async () => {
    const startDate = new Date(moment().weekday(1));
    const endDate = new Date(moment().weekday(5));

    setSearchData({
      ...searchData,
      startDate: startDate,
      endDate: endDate,
    });
    searchData.startDate = startDate;
    searchData.endDate = endDate;

    await GetEmployeeWorkType();
    // await FilterEmployee();
    await GetHotelRoom();
    await GetRoomAccessories();
    await RoomBookingResult();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Dashboard Booking</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Booking</li>
                  <li className="breadcrumb-item active">Dashboard Booking</li>
                </ol>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div
                      className="row"
                      style={{
                        alignItems: "flex-end",
                      }}
                    >
                      <div className="col-12 col-md-6 col-xl-3">
                        <label style={{ margin: "0px" }}>ช่วงวันที่</label>

                        <div
                          style={{ margin: "0px", paddingTop: "0px" }}
                          className="form-group input-group"
                        >
                          <DatePicker
                            locale="th"
                            id="inputDate"
                            className="form-control"
                            wrapperClassName="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            value={`${moment(searchData.startDate).format(
                              "DD/MM/YYYY"
                            )} - ${moment(
                              searchData.endDate || searchData.startDate
                            ).format("DD/MM/YYYY")}`}
                            selected={searchData.startDate}
                            onChange={async (rangeDate) => {
                              setSearchData({
                                ...searchData,
                                startDate: rangeDate[0],
                                endDate: rangeDate[1],
                              });
                              searchData.startDate = rangeDate[0];
                              searchData.startDate = rangeDate[1];
                            }}
                            shouldCloseOnSelect={
                              !(
                                !searchData.startDate ||
                                Boolean(
                                  searchData.startDate && searchData.endDate
                                )
                              )
                            }
                            startDate={searchData.startDate}
                            endDate={searchData.endDate}
                            selectsRange={true}
                            // isClearable={true}
                            showMonthDropdown
                            showYearDropdown
                            disabledKeyboardNavigation
                            yearDropdownItemNumber={15}
                            scrollableYearDropdown
                            // todayButton="Today"
                          />
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="far fa-calendar-alt" />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-3">
                        <label style={{ margin: "0px" }}>ชื่อผู้จอง</label>

                        <div
                          style={{ margin: "0px", paddingTop: "0px" }}
                          className="form-group"
                        >
                          <Select
                            isMulti
                            // isDisabled={viewMode}
                            options={employeeData.dropdown}
                            // isOptionDisabled={(option) => option.disabled}
                            // formatOptionLabel={(label) => label.html}
                            filterOption={(option, searchText) => {
                              if (
                                option.data.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                                // || option.data.value
                                //   .toLowerCase()
                                //   .includes(searchText.toLowerCase())
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            value={searchData.userBooking}
                            onChange={(e) => {
                              setSearchData({
                                ...searchData,
                                userBooking: e,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-3">
                        <label style={{ margin: "0px" }}>ชื่อห้องที่จอง</label>

                        <div
                          style={{ margin: "0px", paddingTop: "0px" }}
                          className="form-group"
                        >
                          <Select
                            isMulti
                            // isDisabled={viewMode}
                            options={roomData.dropdown}
                            // isOptionDisabled={(option) => option.disabled}
                            // formatOptionLabel={(label) => label.html}
                            filterOption={(option, searchText) => {
                              if (
                                option.data.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                                // || option.data.value
                                //   .toLowerCase()
                                //   .includes(searchText.toLowerCase())
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            value={searchData.roomBooking}
                            onChange={(e) => {
                              setSearchData({
                                ...searchData,
                                roomBooking: e,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-3">
                        <label style={{ margin: "0px" }}>ช่วงเวลาที่จอง</label>

                        <div
                          style={{ margin: "0px", paddingTop: "0px" }}
                          className="form-group"
                        >
                          <Select
                            isMulti
                            // isDisabled={viewMode}
                            options={rangeTimeData.dropdown}
                            // isOptionDisabled={(option) => option.disabled}
                            // formatOptionLabel={(label) => label.html}
                            filterOption={(option, searchText) => {
                              if (
                                option.data.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase())
                                // || option.data.value
                                //   .toLowerCase()
                                //   .includes(searchText.toLowerCase())
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            }}
                            value={searchData.rangeTimeBooking}
                            onChange={(e) => {
                              setSearchData({
                                ...searchData,
                                rangeTimeBooking: e,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-6 col-md-4 col-xl-2">
                        <div style={{ margin: "0px" }} className="form-group ">
                          <button
                            type="button"
                            id="btn_search"
                            className="btn btn-block btn-info"
                            onClick={dataSearch}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-6 col-md-4 col-xl-2">
                        <div style={{ margin: "0px" }} className="form-group ">
                          <button
                            type="button"
                            id="btn_clear"
                            className="btn btn-block btn-info"
                            onClick={clearDataSearch}
                          >
                            Clear
                          </button>
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-xl-2">
                        <div style={{ margin: "0px" }} className="form-group ">
                          <button
                            type="button"
                            id="btn_clear"
                            className="btn btn-block btn-primary"
                            onClick={downloadExcelDashboard}
                          >
                            Export Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-xl-3">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "15px 10px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "30px" }}>
                          การจองห้องทั้งหมด
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "46px", color: "#3579F6" }}>
                          {firstPartData.sumRoom || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "15px 10px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "30px" }}>TTT Hall</span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "46px", color: "#3579F6" }}>
                          {firstPartData.hall || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "15px 10px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "30px" }}>
                          TTT Hotel Meeting
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "46px", color: "#3579F6" }}>
                          {firstPartData.hotel_meet || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      fontWeight: "700",
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "15px 10px 10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "30px" }}>TTT Hotel</span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "46px", color: "#3579F6" }}>
                          {firstPartData.hotel || 0}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-12 col-md-6 col-xl-3"
                style={{ paddingBottom: "10px" }}
              >
                <div className="card" style={{ height: "100%", margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "26px" }}>
                          ประเภทการจองห้อง
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div style={{ height: "364px" }}>
                          <PieChart DataChart={pieChartData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-md-6 col-xl-3"
                style={{ paddingBottom: "10px" }}
              >
                <div className="card" style={{ height: "100%", margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "26px" }}>ช่วงเวลาที่จอง</span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div style={{ height: "364px" }}>
                          <DoughnutChart DataChart={doughnutChartData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-md-12 col-xl-6"
                style={{ paddingBottom: "10px" }}
              >
                <div className="card" style={{ height: "100%", margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "26px" }}>
                          อุปกรณ์ที่ใช้บ่อยสุดในการจองห้อง
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div
                          className="auto_scroll_y"
                          style={{ height: "364px" }}
                        >
                          <HorizontalBarChart
                            DataChart={horizontalBarChartData}
                            // HeightChart={`${
                            //   roomAccessoriesData.ori.length * 91 < 364
                            //     ? 364
                            //     : roomAccessoriesData.ori.length * 91
                            // }px`}
                            HeightChart={`${
                              roomAccessoriesData.ori.length <= 4
                                ? 0
                                : (roomAccessoriesData.ori.length - 4) * 91
                            }px`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 col-xl-7">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "26px" }}>
                          อันดับข้อมูลการจอง แยกตามห้อง
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div
                          className="auto_scroll_x"
                          style={{ height: "364px" }}
                        >
                          <VerticalBarChart
                            DataChart={verticalBarChartData}
                            WidthChart={`${
                              roomData.dropdown.length <= 10
                                ? 0
                                : (roomData.dropdown.length - 10) * 89
                            }px`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-xl-5">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      textAlign: "center",
                      lineHeight: "normal",
                      padding: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <span style={{ fontSize: "26px" }}>
                          อันดับข้อมูลการจอง แยกตามห้อง
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div style={{ whiteSpace: "nowrap" }}>
                          <MDBDataTable
                            className="MDBDataTable-responsive MDBDataTable-center MDBDataTable-margin-zero"
                            sortable={false}
                            // striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={checkDRAS.bool}
                            data={tableEmployeeReserve}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}

export default Dashboard_Booking;
