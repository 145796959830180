import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser, getFeature } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import INDF from "../../component/input_date_feature";
import QR_stage from "../../assets/file/QR_CODE_PROCESS_TRACKING_CTS.pdf";
import Select from "react-select";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

function HandheldCTS() {
  const [STEP1, setSTEP1] = useState({
    process_Select: "",
    style: "",
  });
  const [modal, setModal] = useState(false);
  const toggle_modal = () => setModal(!modal);

    const [modal0, setModal0] = useState(false);
  const toggle_modal0 = () => setModal0(!modal0);
  const [STEP1_scan, setSTEP1_scan] = useState({
    stage: "",

  });
  const [test,settest] = useState({
    test:""
  })

  const [lot_noDisplay,setlot_noDisplay] = useState(true);

  const [tracking, settracking] = useState({
    tag_no: "",
    lot_no: "",
    exp: new Date(),
    oem_id: getOem(),

  });
  const [fg_exp,setfg_exp] = useState({
    exp: new Date(),
    amount:"",
    tag_no:"",
    production_order_no:"",
    production_order_id:""

  })

  const [mat_out,setmat_out] = useState({
    tag_no: '',
    amount: '',
    material_id: '',
    invoice_no: '',
    coil_no: '',
    production_order_no:'',
    production_order_id:''
  });
  const [dataSaveDeatail, setdataSaveDetail] = useState({
    pack_name:"",
    production_order_no: "",
    production_order_id: "",
    loss_amount:"",
    project_name:"",
    project_id:"",
  });


  const [status, setstatus] = useState(false);
  const [STEP2, setSTEP2] = useState([]);
  const [disPreStep3, setdisPreStep3] = useState(true);
  const [reCheck, setreCheck] = useState(false);
  const [disEditLot, setdisEditLot] = useState(true);
  const [disSTEP1, setdisSTEP1] = useState(false);
  const [disSTEP2, setdisSTEP2] = useState(false);
  const [feature, setfeature] = useState([]);

  const [productionList, setProductionList] = useState([]);
  const [projectList, setProjectList] = useState([]);

  const selectProductionOrder = useRef();
  const selectProject = useRef();





  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return (element.menu === "Handheld");

    })

    setfeature(feature_data);
    /* getProductionOrderCTS(); */ /// lot no ใบ สั่งผลิต
    getProjectCTS(); // project fg out
    document.getElementById("STEP1").focus();
  }, []);

  //console.log(feature)
  function set_step1(process, style) {
    setSTEP1({ ...STEP1, process_Select: process, style: style });
  }



  if (STEP1_scan.stage.length === 17) {
    console.log(STEP1_scan.stage);
    if (STEP1_scan.stage === "TTT&CTS_PROCESS01") {
      console.log("Raw Mat Out");
      /* Swal.fire(
        'Message',
        'Your scan has been set stage.',
        'success'
      ) */
      set_step1("Raw mat Out", "btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if (STEP1_scan.stage === "TTT&CTS_PROCESS02") {
      console.log("Wip Prod Out");
      /*   Swal.fire(
          'Message',
          'Your scan has been set stage.',
          'success'
        ) */
      set_step1("Wip Prod Out", "btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    /* else if (STEP1_scan.stage === "TTT&CTS_PROCESS03") {
      console.log("รับชุบ");
    
      set_step1("Wip รับชุบ", "btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    } */
    else if (STEP1_scan.stage === "TTT&CTS_PROCESS03") {
      console.log("PACK Out");
   
      set_step1("Pack Out", "btn btn-block btn-outline-success");
      setdisSTEP1(true);
      // setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if (STEP1_scan.stage === "TTT&CTS_PROCESS04") {
      console.log("F/G In");
      /*  Swal.fire(
         'Message',
         'Your scan has been set stage.',
         'success'
       ) */
      set_step1("F/G In", "btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if (STEP1_scan.stage === "TTT&CTS_PROCESS05") {
      console.log("F/G Out");
      /*  Swal.fire(
         'Message',
         'Your scan has been set stage.',
         'success'
       ) */
      set_step1("F/G Out", "btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else {
      Swal.fire(
        'Error',
        'Not found.',
        'error'
      )
    }
    setSTEP1_scan({ ...setSTEP1_scan, stage: "" });
    setSTEP1_scan.stage = "";
  }

  if (STEP2.length > 0 && STEP1.process_Select !== "" && status === false) {
    setdisPreStep3(false);
    setstatus(true);

  }
  if(tracking.tag_no.length === 12 && (STEP1.process_Select === 'F/G In' || STEP1.process_Select === 'F/G Out')){
    console.log("FG only")
    axios({
      method: "get",
      url: Configs.API_URL + "/api/handheldCTS/checkAndGetDetailByProductNo/" + tracking.tag_no+"/"+getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },

    })
      .then(function (response) {
        settracking({ ...tracking, tag_no: "" });
        tracking.tag_no = "";
        console.log(response.data)
        var tag_no = response.data.tag_no;
        var check = STEP2.filter((e) => {
          return e.tag_no === tag_no;
        });
       
        if (check.length > 0) {
          //ยิงซ้ำแล้ว ไม่มีอะไร
         

        } else {
          setfg_exp({...fg_exp,tag_no:tag_no,product_id:response.data.product_id});
          getProductionOrderCTS(response.data.product_id);
          toggle_modal();
       
            }
       
   /*        Swal.fire({
            title: 'Input Amount',
            
            input: 'number',
      inputAttributes: {
        autocapitalize: 'off'
      },
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, input it!'
          }).then((result) => {
            
              
            if (result.isConfirmed) {
              var tempData = STEP2;
              setSTEP2([]);
              var temp = {
                tag_no: response.data.tag_no,
                amount: result.value,
    
              }
              tempData.push(temp);
              setSTEP2(tempData);
            }
          }) */
         

        



        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
        settracking({ ...tracking, tag_no: "" });
        tracking.tag_no = "";
      });
  }

  if (tracking.tag_no.length === 10 && STEP1.process_Select !== 'F/G In' && STEP1.process_Select !== 'F/G Out') {
    console.log("tracking", tracking.tag_no);
    if (STEP1.process_Select === 'Raw mat Out') {
      axios({
        method: "get",
        url: Configs.API_URL + "/api/handheldCTS/checkAndGetDetailByTag?tag_id=" + tracking.tag_no,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },

      })
        .then(function (response) {
          settracking({ ...tracking, tag_no: "" });
          tracking.tag_no = "";
          /* console.log(response.data) */
          var tag_no = response.data.tag_no;
          var check = STEP2.filter((e) => {
            return e.tag_no === tag_no;
          });
          /*   console.log("chekc",check) */
          if (check.length > 0) {
            //ยิงซ้ำแล้ว ไม่มีอะไร
            /*   Swal.fire(
                  'Message',
                  'Already have this information.',
                  'info'
                ) */

          } else {
            /*     console.log("here") */
            setmat_out({...mat_out,
              tag_no: response.data.tag_no,
              amount: response.data.amount,
              material_id: response.data.material_id,
              invoice_no:response.data.invoice_no,
              coil_no:response.data.coil_no,
            });
            getProductionOrderCTS(response.data.material_id);
            toggle_modal0();

            /* var tempData = STEP2;
            let check = tempData.filter((e)=>{return e.material_id === response.data.material_id});

            if(check.length != 0 || tempData.length == 0){
              getProductionOrderCTS(response.data.material_id);
     
              setSTEP2([]);
              var temp = {
                tag_no: response.data.tag_no,
                amount: response.data.amount,
                material_id: response.data.material_id,
                invoice_no:response.data.invoice_no,
                coil_no:response.data.coil_no,
              }
              
              tempData.push(temp);
  
              setSTEP2(tempData);
            }else{
              Swal.fire("Error", 'ดำเนินการได้ต่อเมื่อเป็น material เดียวกันกับตัวก่อนหน้า', "error");
            } */


        

          }



          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
          settracking({ ...tracking, tag_no: "" });
          tracking.tag_no = "";
        });
    } else if (STEP1.process_Select === 'Wip Prod Out') {

      axios({
        method: "post",
        url: Configs.API_URL + "/api/handheldCTS/checkAndGetDetailByTagWipProd?tag_id=" + tracking.tag_no,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data:STEP2,
      })
        .then(function (response) {
          settracking({ ...tracking, tag_no: "" });
          tracking.tag_no = "";
          var tag_no = response.data.tag_no;
          var check = STEP2.filter((e) => {
            return e.tag_no === tag_no;
          });
          if (check.length > 0) {


          } else {

            var tempData = STEP2;
            setSTEP2([]);
            var temp = {
              tag_no: response.data.tag_no,
              amount: response.data.amount,
              box_no: response.data.box_no,

            }
            tempData.push(temp);
            setSTEP2(tempData);

          }



          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
          settracking({ ...tracking, tag_no: "" });
          tracking.tag_no = "";
        });


    } else if (STEP1.process_Select === 'Pack Out') {
      axios({
        method: "post",
        url: Configs.API_URL + "/api/handheldCTS/checkAndGetDetailByTagPack?tag_id=" + tracking.tag_no,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data:STEP2,
      })
        .then(function (response) {
          console.log(response.data)
         
        
            settracking({ ...tracking, tag_no: "" });
          tracking.tag_no = "";
          var tag_no = response.data.tag_no;
          var check = STEP2.filter((e) => {
            return e.tag_no === tag_no;
          });
          if (check.length > 0) {
            

          } else {
            
            var tempData = STEP2;
            setSTEP2([]);
       
            var temp = {
              tag_no: response.data.tag_no,
              amount: response.data.amount,
              pack_no: response.data.pack_no,

            }
            tempData.push(temp);
            setSTEP2(tempData);
            
          }
       
 
      
           

        



          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
          settracking({ ...tracking, tag_no: "" });
          tracking.tag_no = "";
        });








    }












  }
console.log("STEP2",STEP2)


  function amount_update(e) {
    var tempData = STEP2;

    tempData[e.target.id].amount = e.target.value;

    setSTEP2(tempData);

  }

  function remove_tracking(index,e) {
    console.log(index)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var tempData = STEP2;
        setSTEP2([]);
        tempData.splice(index, 1);
        if (tempData.length < 1) {
          setstatus(false);
          setdisPreStep3(true);
        }
        setSTEP2(tempData);
        
        if(STEP1.process_Select === "Raw mat Out" || STEP1.process_Select === "F/G In"){
          getProductionOrderCTS_AfterDel(tempData);
        }
     
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }


  async function getProductionOrderCTS(id){
    let data = "";
    if(STEP1.process_Select === "Raw mat Out"){
       data = "mat";
    }else if(STEP1.process_Select === "F/G In"){
       data = "product";
    }   

    axios({
      method: "get",
      url: Configs.API_URL + "/api/handheldCTS/getProductionOrderCTS?id="+id+"&key="+data,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    
    })
      .then(function (response) {
/*         var options = productionList; */
        var options = [];
        setlot_noDisplay(false);
        setProductionList([]);
       
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;
          
          var check = options.filter((e)=> {return e.label === el.no});

          if(check.length === 0){
            options.push(element);
          }
     
        });
        setProductionList(options);
        setlot_noDisplay(true);
        settest({...test,test:""});
        test.test = "";


      }).catch(function (error) {
        Swal.fire(
          'Error',
          'has error some one..',
          'error'
        )
        console.log(error);
      });
  }


  async function getProductionOrderCTS_AfterDel(stock){
    let temp = {}
    if(STEP1.process_Select === "Raw mat Out"){
       temp = {
        inScan:stock,
        key:"mat"
      }
    }else if (STEP1.process_Select === "F/G In"){
       temp = {
        inScan:stock,
        key:"product"
      }
    }
  
    axios({
      method: "post",
      url: Configs.API_URL + "/api/handheldCTS/getProductionOrderCTS_AfterDel",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
data:temp
    })
      .then(function (response) {
        var options = [];
        setlot_noDisplay(false);
        setProductionList([]);
       
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;
          
          var check = options.filter((e)=> {return e.label === el.no});

          if(check.length === 0){
            options.push(element);
          }
     
        });
        setProductionList(options);
        setlot_noDisplay(true);
        settest({...test,test:""});
        test.test = "";


      }).catch(function (error) {
        Swal.fire(
          'Error',
          'has error some one..',
          'error'
        )
        console.log(error);
      });
  }


  const getProjectCTS = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/handheldCTS/getProjectList?oem_id="+getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },

    })
      .then(function (response) {
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.project_name;

          options.push(element);
        });
        setProjectList(options);



      }).catch(function (error) {
        Swal.fire(
          'Error',
          'has error some one..',
          'error'
        )
        console.log(error);
      });
  }

  function saveOption() {
    var temp;
    if (STEP1.process_Select === "Raw mat Out") {
      temp = {
        type: STEP1.process_Select,
        list_track: STEP2,
    /*     product_order_no: dataSaveDeatail.production_order_no,
        product_order_id: dataSaveDeatail.production_order_id, */
        oem_id: getOem(),
        company_id: getUser().com,
      }
    } else if (STEP1.process_Select === "Wip Prod Out") {
      temp = {
        type: STEP1.process_Select,
        list_track: STEP2,
        loss_amount: dataSaveDeatail.loss_amount,
        oem_id: getOem(),
        company_id: getUser().com,
      }
    } else if (STEP1.process_Select === "Wip รับชุบ") {
      temp = {
        type: STEP1.process_Select,
        list_track: STEP2,
        pack_name: dataSaveDeatail.pack_name,
        oem_id: getOem(),
        company_id: getUser().com,
      }
    } else if (STEP1.process_Select === "Pack Out") {
      temp = {
        type: STEP1.process_Select,
        list_track: STEP2,
        loss_amount: dataSaveDeatail.loss_amount,
        oem_id: getOem(),
        company_id: getUser().com,
      }
    } else if (STEP1.process_Select === "F/G In") {
   /*    var tempData = STEP2;
      for(let i of tempData){
        i.amount = Number(i.amount.replace(/,/g, ""));
      } */
      temp = {
        type: STEP1.process_Select,
        list_track: STEP2,
     /*    product_order_no: dataSaveDeatail.production_order_no,
        product_order_id: dataSaveDeatail.production_order_id, */
        oem_id: getOem(),
        company_id: getUser().com,
      }
    } else if (STEP1.process_Select === "F/G Out") {
     /*  var tempData = STEP2;
      for(let i of tempData){
        i.amount = Number(i.amount.replace(/,/g, ""));
      } */
      temp = {
        type: STEP1.process_Select,
        list_track: STEP2,
        project_id:dataSaveDeatail.project_id,
        oem_id: getOem(),
        company_id: getUser().com,
      }
    }




    axios({
      method: "post",
      url: Configs.API_URL + "/api/handheldCTS/SaveProcessHandheld",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        if (response.data === true) {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            setdisSTEP1(false);
            setSTEP1({ ...STEP1, process_Select: "" });
            STEP1.process_Select = "";
            setSTEP2([]);
            setdataSaveDetail({ ...dataSaveDeatail, production_order_no: "", production_order_id: "",pack_name:"",loss_amount:"",project_name:"",project_id:"" });
            dataSaveDeatail.production_order_no = "";
            dataSaveDeatail.production_order_id = "";
            dataSaveDeatail.loss_amount = "";
            dataSaveDeatail.pack_name = "";
            dataSaveDeatail.project_name = "";
            dataSaveDeatail.project_id = "";
            
            if (STEP1.process_Select === "Raw mat Out") {
            selectProductionOrder.current.select.clearValue();
            }
            if (STEP1.process_Select === "F/G Out") {
              selectProject.current.select.clearValue();
              }
      
            document.getElementById("STEP1").focus();
            setdisSTEP2(false);

            //toggle_modal();
            /* setreturn_detail(response.data);
            toggle_modal();
            console.log(response.data) */
            /*   window.location.href =
              "/Warehouse_Management/Handheld"; */
          });
        }


      }).catch(function (error) {
        Swal.fire(
          'Error',
          error.response.data.message,
          'error'
        )
        console.log(error);
      });





  }

  function save_Option() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!'
    }).then((result) => {
      if (result.isConfirmed) {
        saveOption();





      }
    })
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  async function Setexp(date) {
    settracking({ ...tracking, exp: date });
  }

  
  function setValueMatOut(){
    
           /*  getProductionOrderCTS(fg_exp.product_id); */
          var tempData = STEP2;
              setSTEP2([]);
              let temp = {
                tag_no: mat_out.tag_no,
                amount: mat_out.amount,
                material_id: mat_out.material_id,
                invoice_no: mat_out.invoice_no,
                coil_no:mat_out.coil_no,
                production_order_no: mat_out.production_order_no,
                production_order_id: mat_out.production_order_id,
              }
                console.log("temp",temp)
              tempData.push(temp);
              setSTEP2(tempData);
              setmat_out({...mat_out,
                tag_no: '',
                amount: '',
                material_id: '',
                invoice_no: '',
                coil_no:'',
                production_order_no: '',
                production_order_id: '',
              });
              mat_out.tag_no= '';
              mat_out.amount= '';
              mat_out.material_id= '';
              mat_out.invoice_no= '';
              mat_out.coil_no='';
              mat_out.production_order_no= '';
              mat_out.production_order_id= '';
  }

  function setValueFGIn(){
    
           /*  getProductionOrderCTS(fg_exp.product_id); */
          var tempData = STEP2;
              setSTEP2([]);
              var temp = {
                tag_no: fg_exp.tag_no,
                amount: fg_exp.amount,
                exp: fg_exp.exp,
                product_id:fg_exp.product_id,
                production_order_id:fg_exp.production_order_id,
                production_order_no:fg_exp.production_order_no
    
              }
              tempData.push(temp);
              setSTEP2(tempData);
              setfg_exp({...fg_exp,exp:new Date(),amount:"",tag_no:"",product_id:"",production_order_id:"",production_order_no:""});
              fg_exp.amount = "";
              fg_exp.exp = new Date();
              fg_exp.tag_no = "";
              fg_exp.product_id = "";
              fg_exp.production_order_id = "";
              fg_exp.production_order_no = "";
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );


  return (
    <div className="wrapper">

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Handheld</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Handheld</li>

                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">

          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-6">
                  <h3>Handheld</h3>
                </div>
                <div className="col-6">
                  <div class="form-group">
                    <a href={QR_stage} download="TTT_TRACKING_STAGE.pdf" style={{ fontSize: "" }}>
                      <button
                        type="button"
                        //onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                        value="Cutting OUT"
                        class="btn btn-block btn-primary "
                      >
                        Dowload QR Stage
                      </button>
                    </a>
                  </div>
                </div>

              </div>

              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <h3>
                STEP 1
              </h3>
              <div className="row">
                <div className="col-10">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="STEP1"
                      required
                      value={STEP1_scan.stage.trim()}
                      disabled={disSTEP1}
                      onChange={(e) => {
                        setSTEP1_scan({
                          ...setSTEP1_scan,
                          stage: e.target.value.trim(),
                        });
                      }}
                    />
                    <label>
                      SCAN STAGE
                    </label>
                  </div>
                </div>
                <div className="col-2">
                  <div class="form-group">
                    <button class="btn btn-block btn-primary "
                      onClick={() => {
                        if (disSTEP1 === true) {
                          setdisSTEP1(false);
                          setdisPreStep3(true);
                          setstatus(false);
                          setdisSTEP2(false);
                
                          setSTEP1({ ...STEP1, process_Select: "" });
                          STEP1.process_Select = "";
                          setSTEP2([]);
                          setProductionList([]);

                        }


                      }}
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </button>

                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-5">
                  <h3>
                    Stage Select :

                  </h3>
                </div>
                <div className="col-6">
                  {STEP1.process_Select === "" ? (
                    <>

                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        //disabled
                        class={STEP1.style}
                      >
                        {STEP1.process_Select}
                      </button>
                    </>
                  )}


                </div>
              </div>

              <div className="tab-custom-content"></div>
              <h3>STEP 2</h3>
              <div className="row">
                <div className="col-12" /* style = {{visibility: STEP1.process_Select === "" || disSTEP2 === true ? 'hidden' : ''}} */>
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="STEP2"
                      value={tracking.tag_no.trim()}

                      disabled={disSTEP2}
                      onChange={(e) => {
                        settracking({
                          ...tracking,
                          tag_no: e.target.value.trim(),
                        });
                      }}
                      autofocus
                    />
                    <label>
                      SCAN TRACKING
                    </label>
                  </div>
                </div>


                <div className="table-responsive" style={{ whiteSpace: "nowrap" }} >

                  <table style={{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%" }}>
                    {STEP2.map((e, index) => {
                      return (<>
                        <tr style={{ border: "1px solid #ddd", backgroundColor: "#F0FFFF", bordercollapse: "collapse" }}>
                        {STEP1.process_Select === "Raw mat Out" ? (<>
                            <td style={{ textAlign: "center" }}>{e.invoice_no}</td>
                            <td style={{ textAlign: "center" }}>{e.coil_no}</td>
                            <td style={{ textAlign: "center" }}>{e.production_order_no}</td>
                          </>):(<></>)}
                          {STEP1.process_Select === "Wip Prod Out" ? (<>
                            <td style={{ textAlign: "center" }}>{e.box_no}</td>
                          </>) : (<></>)}

                          {STEP1.process_Select === "Pack Out" ? (<>
                            <td style={{ textAlign: "center" }}>{e.pack_no}</td>
                          </>) : (<></>)}

                          <td style={{ textAlign: "center" }}>{e.tag_no}</td>
                       
                          <td>{numberWithCommas(e.amount)} {STEP1.process_Select === 'Wip รับชุบ' ? (<>{e.type === "kg" ? "กก." : "ชิ้น"}&nbsp;&nbsp;&nbsp;loss&nbsp;{e.loss}</>) :""} </td>
                          
                          
                          {e.exp && STEP1.process_Select === "F/G In" ? (<>
                            <td>exp : {moment(e.exp).format('DD/MM/YYYY')}</td>
                            <td>LOT : {e.production_order_no}</td>
                            </>
                          ):(<></>)}
                          <td> <button
                            type="button"
                            onClick={remove_tracking.bind(this,index)}
                            disabled={disSTEP2}
                            class="btn btn-block btn-danger "
                            style={{ verticalAlign: "center" }}
                          >
                            <i class="fas fa-times"></i>
                          </button></td>
                        </tr>


                      </>
                      );
                    })}
                  </table>

                </div>



              </div>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  <div class="form-group">
                    <button
                      type="button"
                      onClick={() => {
                        setdisSTEP2(!disSTEP2);
                        /*    document.getElementById("STEP2").blur();
                           if(disSTEP2 === false){
                             
                           } */




                      }}



                      class="btn btn-block btn-primary "
                      disabled={STEP2.length > 0 ? false : true}
                    >
                      {disSTEP2 === false ? "Scan Finish" : "Rety Scan"}
                    </button>
                  </div>
                </div>
                <div className="col-4"></div>
              </div>


              <div className="tab-custom-content" />
      {/*         {STEP1.process_Select === "Raw mat Out" && lot_noDisplay === true ? (
                <>
                  <h3>STEP 3 (ระบุ Lot No.)</h3>
                  <div className="form-group ">
                    <Select
                      options={productionList}
               
                      ref={selectProductionOrder}
                      onChange={(e) => {

                        if (e !== null) {
                          setdataSaveDetail({ ...dataSaveDeatail, production_order_no: e.label, production_order_id: e.value });

                        }

                      }}
                    />
                    <label htmlFor="">Select Lot No</label>
                  </div>



                  <div className="tab-custom-content" />
                </>
              ) : (<></>)} */}

{STEP1.process_Select === "Wip Prod Out" || STEP1.process_Select === "Pack Out" ? (
                <>
                  <h3>STEP 3 (ระบุจำนวนสูญหาย หากมี)</h3>
                   <div className="form-group">
                  <NumberFormat
                /*         thousandSeparator={true} */
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        value={dataSaveDeatail.loss_amount}
                        onChange={(e)=>{setdataSaveDetail({...dataSaveDeatail,loss_amount:e.target.value})}}
                       
                      />
                       <label htmlFor="">จำนวนที่สูญหาย (Loss)</label>{" "}
                      </div>
                {/*   <div className="form-group ">
                      <input type = 'text' className="form-control"
                      value={dataSaveDeatail.loss_amount}
                      onChange={(e) => {setdataSaveDetail({...dataSaveDeatail,loss_amount:e.target.value})}}
                      />
                    <label htmlFor="">จำนวนที่สูญหาย (Loss)</label>
                  </div> */}



                  <div className="tab-custom-content" />
                </>
              ) : (<></>)}


{/* {STEP1.process_Select === "F/G In" && lot_noDisplay === true ? (
                <>
                  <h3>STEP 3 (ระบุ Lot No.)</h3>
                  <div className="form-group ">
                    <Select
                      options={productionList}
                      
                      ref={selectProductionOrder}
                      onChange={(e) => {

                        if (e !== null) {
                          setdataSaveDetail({ ...dataSaveDeatail, production_order_no: e.label, production_order_id: e.value });

                        }

                      }}
                    />
                    <label htmlFor="">Select Lot No</label>
                  </div>



                  <div className="tab-custom-content" />
                </>
              ) : (<></>)} */}

{STEP1.process_Select === "F/G Out" ? (
                <>
                  <h3>STEP 3 (ระบุ Project.)</h3>
                  <div className="form-group ">
                    <Select
                      options={projectList}
                      /*  value={productionList.filter((f) => { return f.value === material_process.coil_no })} */
                      ref={selectProject}
                      onChange={(e) => {

                        if (e !== null) {
                          setdataSaveDetail({ ...dataSaveDeatail, project_name: e.label, project_id: e.value });

                        }

                      }}
                    />
                    <label htmlFor="">Select Project</label>
                  </div>



                  <div className="tab-custom-content" />
                </>
              ) : (<></>)}




              <br />


              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  <div class="form-group">
                    {STEP1.process_Select === 'Raw mat Out' || STEP1.process_Select === 'F/G In' ? (
                      <button
                        type="button"
                        onClick={save_Option}
                        class="btn btn-block btn-success "
                        disabled={STEP1.process_Select !== "" && STEP2.length > 0 && disSTEP2 === true && dataSaveDeatail.production_order_id !== "" ? false : true}
                      >
                        SAVE
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={save_Option}
                        class="btn btn-block btn-success "
                        disabled={STEP1.process_Select !== "" && STEP2.length > 0 && disSTEP2 === true ? false : true}
                      >
                        SAVE
                      </button>
                    )}

                  </div>
                </div>
                <div className="col-4"></div>
              </div>






              <Modal isOpen={modal} toggle={toggle_modal} className="modal-dialog modal-md">

                <ModalHeader toggle={toggle_modal}>

                  <h1>Input Value</h1>
                </ModalHeader>
                <ModalBody>
                 

                  <div className="row">

<div className="col-12">
  <div class="form-group">
  <NumberFormat
                /*         thousandSeparator={true} */
                        decimalScale={0}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                       /*  value={e.weight_by_tag} */
                        onChange={(e)=>{setfg_exp({...fg_exp,amount:e.target.value})}}
                       
                      />
 {/*    <input type = "text"
     className = "form-control"
     onChange={(e)=>{setfg_exp({...fg_exp,amount:e.target.value})}}
     /> */}
    <label htmlFor="">Amount</label>
  </div>
</div>
{STEP1.process_Select === 'F/G In' ? (
  <>
  <div className="col-12">
  <div class="form-group">
  <div className="form-group " style = {{paddingTop:"20px"}}>
                        
                        <DatePicker
                          selected={fg_exp.exp}
                          dateFormat={"dd-MM-yyyy"}
                          //onClick={params.onClick}
                          onChange={(date)=> setfg_exp({...fg_exp,exp:date})}
                          selectsStart 
                          //startDate={params.selected}
                          minDate={new Date()}
                          customInput={<CustomInput />}
                          //disabled={params.disabled}
                        />
               
               </div>
    <label htmlFor="" >EXP Date</label>
  </div>
</div>

<div className="col-12">
<div className="form-group ">
                    <Select
                      options={productionList}
               
                      ref={selectProductionOrder}
                      onChange={(e) => {

                        if (e !== null) {
                          setdataSaveDetail({ ...dataSaveDeatail, production_order_no: e.label, production_order_id: e.value });
                          setfg_exp({...fg_exp, production_order_no: e.label, production_order_id: e.value });
                        }

                      }}
                    />
                    <label htmlFor="">Select Lot No</label>
                  </div>
                  </div>
  </>
):(<></>)}

</div>
           
                </ModalBody>
                <ModalFooter>
                   <Button color="primary" disabled = {(fg_exp.production_order_id == "" && STEP1.process_Select === 'F/G In') || fg_exp.amount < 1  ? true : false} onClick={()=>{toggle_modal();
                    setValueFGIn();
                  
                  }}>Confirm</Button>{' '}
                  <Button color="secondary" onClick={toggle_modal}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>




              <Modal isOpen={modal0} toggle={toggle_modal0} className="modal-dialog modal-md">

<ModalHeader toggle={toggle_modal0}>

  <h1>Input Value</h1>
</ModalHeader>
<ModalBody>
 

  <div className="row">

{STEP1.process_Select === 'Raw mat Out' ? (
<>


<div className="col-12">
<div className="form-group ">
    <Select
      options={productionList}

      ref={selectProductionOrder}
      onChange={(e) => {

        if (e !== null) {
          setdataSaveDetail({ ...dataSaveDeatail, production_order_no: e.label, production_order_id: e.value });
          setmat_out({...mat_out,production_order_no: e.label, production_order_id: e.value});
        }

      }}
    />
    <label htmlFor="">Select Lot No</label>
  </div>
  </div>
</>
):(<></>)}

</div>

</ModalBody>
<ModalFooter>
   <Button color="primary" disabled = {mat_out.production_order_id == "" ? true : false} onClick={()=>{toggle_modal0();
    setValueMatOut();
  
  }}>Confirm</Button>{' '}
  <Button color="secondary" onClick={toggle_modal0}>
    Close
  </Button>
</ModalFooter>
</Modal>



            </div>

          </div>
        </section>
      </div>
      {/*  <Footter/> */}
    </div>





  );
}
export default HandheldCTS;
