import React, { useState, useEffect } from "react";
import { getDashboardMenu } from "../../Utils/Common";
import "../../assets/css/Content.css";
import "../../assets/css/LandingPage.css";
function Dashboard_hr(params) {
  const [fullscreen, setFullscreen] = useState({
    checked: false,
  });
  const [menuDashboard, setmenuDashboard] = useState([]);
  // const [iframeStyle, setiframeStyle] = useState({
  //   border: "none",
  //   width: "100%",
  //   height: "100vh",
  //   /*  position: "fixed",
  //   top: "0px",
  //   left: "0",
  //   bottom: "0",
  //   right: "0",
  //   margin: "0",
  //   padding: "0",
  //   overflow: "hidden",
  //   zIndex: "9999", */
  // });

  useEffect(() => {
    const menuDashboard_list = getDashboardMenu();
    if (menuDashboard_list.length > 0) {
      setmenuDashboard(
        menuDashboard_list.filter((ele) => {
          return ele.path === "Dashboard_HR";
        })
      );
    }

    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setFullscreen({ checked: false });
        fullscreen.checked = false;
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1
                onClick={() => {
                  console.log(menuDashboard);
                }}
              >
                HR Dashboard
                {/*          Welcome {user.sub} */}
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/Welcome">Home</a>
                </li>
                <li className="breadcrumb-item active">HR Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {/*    <label>Mode</label> */}
          <div
            class="custom-control custom-switch"
            style={{
              position: "fixed",
              right: "25px",
              bottom: "25px",
              /*   top: "0",
                    left: "0",
                    margin: "0",
                    padding: "0", */
              overflow: "hidden",
              zIndex: "999999",
            }}
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitches"
              checked={fullscreen.checked}
              onClick={(e) => {
                // console.log(e);
                setFullscreen({ checked: e.target.checked });
                fullscreen.checked = e.target.checked;
                // if (e.target.checked === true) {
                //   setiframeStyle({
                //     border: "none",
                //     width: "100%",
                //     height: "100vh",
                //     position: "fixed",
                //     top: "0px",
                //     left: "0",
                //     bottom: "0",
                //     right: "0",
                //     margin: "0",
                //     padding: "0",
                //     overflow: "hidden",
                //     zIndex: "9999",
                //   });
                // } else {
                //   setiframeStyle({
                //     border: "none",
                //     width: "100%",
                //     height: "100vh",
                //     // position: "fixed",
                //     // top: "0px",
                //     // left: "0",
                //     // bottom: "0",
                //     // right: "0",
                //     // margin: "0",
                //     // padding: "0",
                //     // overflow: "hidden",
                //     // zIndex: "9999",
                //   });
                // }
              }}
            />{" "}
            <label class="custom-control-label" for="customSwitches">
              Full
            </label>
            {/* 
          <p>Full Screen</p>
       */}
          </div>
          {/*  <iframe style ={{width: "100%", height:"90vh"}}  src={"https://lookerstudio.google.com/u/0/reporting/1a485d51-e3a6-43d1-92d5-e8d0cc3a708e/page/qjQ2C"} frameborder="0" allowfullscreen ></iframe>    */}
          {menuDashboard.map((el) => {
            return (
              <iframe
                id="hr_dashboard"
                // style={iframeStyle}
                style={
                  fullscreen.checked
                    ? {
                        border: "none",
                        width: "100%",
                        height: "100vh",
                        position: "fixed",
                        top: "0px",
                        left: "0",
                        bottom: "0",
                        right: "0",
                        margin: "0",
                        padding: "0",
                        overflow: "hidden",
                        zIndex: "9999",
                      }
                    : {
                        border: "none",
                        width: "100%",
                        height: "100vh",
                      }
                }
                src={el.link_dashboard}
                frameborder="0"
                allowfullscreen
              ></iframe>
            );
          })}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
export default Dashboard_hr;
