import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

function Vertical_Bar_Chart({ /* Text, */ DataChart, WidthChart }) {
  ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
        },
        grace: "50%",
      },
    },
    layout: {
      padding: {
        bottom: 10,
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 15,
          padding: 15,
        },
        align: "start",
      },
      datalabels: {
        display: "auto",
        formatter: function (value, context) {
          const stackedValues = context.chart.data.datasets.map(
            (ds) => ds.data[context.dataIndex]
          );

          const dsIdxLastVisibleNonZeroValue = stackedValues.reduce(
            (prev, curr, i) =>
              !!curr && !context.chart.getDatasetMeta(i).hidden
                ? Math.max(prev, i)
                : prev,
            0
          );

          if (
            !!value &&
            context.datasetIndex === dsIdxLastVisibleNonZeroValue
          ) {
            return stackedValues
              .filter((ds, i) => !context.chart.getDatasetMeta(i).hidden)
              .reduce((sum, v) => sum + v, 0);
          } else if (context.datasetIndex === dsIdxLastVisibleNonZeroValue) {
            return 0;
          } else {
            return null;
          }
        },
        align: "end",
        anchor: "end",
        color: "#000000",
        font: {
          size: 10,
        },
        // listeners: {
        //   enter: function (context) {
        //     const chart = context?.chart;
        //     if (chart) {
        //       const tooltip = chart.tooltip;
        //       tooltip.setActiveElements([
        //         {
        //           datasetIndex: context.datasetIndex,
        //           index: context.dataIndex,
        //         },
        //       ]);
        //       chart.update();
        //     }
        //   },
        //   leave: function (context) {
        //     const chart = context?.chart;
        //     if (chart) {
        //       const tooltip = chart.tooltip;
        //       tooltip.setActiveElements([]);
        //       chart.update();
        //     }
        //   },
        // },
      },
    },
  };

  const plugin = {
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 10;
      };
    },
  };

  return (
    <>
      <div style={{ width: `calc(100% + ${WidthChart})`, height: "100%" }}>
        <Bar data={DataChart} plugins={[plugin]} options={options} />
      </div>
    </>
  );
}
export default Vertical_Bar_Chart;
