import React, { Children, Component, useEffect, useState } from "react";
import Configs from "../../../../config";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

function ManageMessageTypeAdd(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const { id } = useParams();
  const [nameNotNull, setNameNotNull] = useState(false);
  const [dataFileDoc, setDataFileDoc] = useState({
    rf_name: "",
    rc_id: "",
    file_name: "",
    file_path: "",
    file: "",
    rp_rf_id: "",
  });
  const [product_category_image_path_null, setproduct_category_image_path_null] = useState(false);
  const [product_category, setproduct_category] = useState({
    product_category_name: "",
    product_category_image_name: "",
    product_category_image_path: "",
    product_category_file: "",
    product_category_type: "",
  });
  console.log('product_category: ', product_category);
  const [filCategory, setFilterCategory] = useState([]);
  const [filterteam, setFilterTeam] = useState({
    file_name: "",
    rc_id: "",
  });
  useEffect(() => {
    if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else if (mode.mode === "view") {
      setdisable(true);
      setPageMode("View");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
    getDataByID(id);
    // let id = "ed61b4af-a1b2-4bae-90d2-94f7befaa430"
    // GetSubDepartmerntList(id);
    // GetDepartmentData();
  }, []);

  function cancle_add_award_point(params) {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Human_Resource/company_regulations/Manage_message_types/";
      }
    });
  }

  function getDataByID(id) {
    if (mode.mode == "view" || mode.mode == "edit") {
      var temp = {
        rc_id: id,
      };
      axios({
        method: "post",
        url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterManageMessageTypeByID",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      })
        .then(function (response) {
          console.log("response: ", response.data.data);
          setproduct_category({
            product_category_name: response.data.data[0].rc_name,
            product_category_image_name: response.data.data[0].rc_path_name,
            product_category_image_path: response.data.data[0].rc_path_image,
          })
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  function isFileImage(file) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
    return file && acceptedImageTypes.includes(file["type"]);
  }

  function SaveDataFile(filePath) {
    if (mode.mode === "add") {
      const temp = {
        rc_name: product_category.product_category_name.trim(),
        rc_name_file: product_category.product_category_image_name,
        rc_path_image: filePath,
        rc_file_type: product_category.product_category_type,
      };
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/addProductCategory",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data.data !== false) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href ="/Human_Resource/company_regulations/Manage_message_types";
              });
            } else {
              Swal.fire(
                "Error",
                "หมวดหมู่นี้มีอยู่ในระบบแล้ว",
                "error"
              );
            }
            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }
    if (mode.mode === "edit") {
      let temp;
      if (filePath) {
        temp = {
          rc_id: id,
          rc_name: product_category.product_category_name.trim(),
          rc_name_file: product_category.product_category_image_name,
          rc_path_image: filePath,
          rc_file_type: product_category.product_category_type,
        };
      } else {
        temp = {
          rc_id: id,
          rc_name: product_category.product_category_name.trim(),
          rc_name_file: product_category.product_category_image_name,
          rc_path_image: product_category.product_category_image_path,
          rc_file_type: product_category.product_category_type,
        };
      }
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/companyRegulations/editProductCategory",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data.data !== false) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href ="/Human_Resource/company_regulations/Manage_message_types";
              });
            } else {
              Swal.fire(
                "Error",
                "หมวดหมู่นี้มีอยู่ในระบบแล้ว",
                "error"
              );
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }
  }

  async function uploadFile() {
    const data = new FormData();
    data.append("Profile", product_category.product_category_file);
    Swal.fire({
      title: "คุณต้องการที่จะบันทึกหรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const error_list = [];
        if (product_category.product_category_name.trim() == "") {
          let temp_err = {
            message: "กรุณากรอกชื่อหมวดหมู่",
          };
          error_list.push(temp_err);
        }
        if (product_category.product_category_image_name == "") {
          let temp_err = {
            message: "กรุณาอัปโหลดรูปภาพ",
          };
          error_list.push(temp_err);
        }
        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          var config_uploadProfile = {
            method: "post",
            url: Configs.API_URL + "/upload/profile",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config_uploadProfile)
            .then(function (response) {
              if (response.data.status) {
                setDataFileDoc({
                  ...dataFileDoc,
                  file_path: response.data.data.path,
                });
                SaveDataFile(response.data.data.path);
              } else if (!response.data.status && mode.mode === "edit") {
                SaveDataFile();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    })
  }
  
  async function check_rf_name(e) {
    if (e.target.value.trim() === "") {
        setproduct_category({
            ...product_category,
            product_category_name: e.target.value
        })
      setDataFileDoc({
        ...dataFileDoc,
        rf_name: e.target.value,
      });
      setNameNotNull(true);
    } else if (e.target.value.trim().length >= 200) {
      setNameNotNull(false);
    } else {
        setproduct_category({
            ...product_category,
            product_category_name: e.target.value
        })
      setNameNotNull(false);
    }
  }

  const GetAllCatgory = async () => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterCategoryAll",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam, // Sending filterteam as the request body
    })
      .then(async function (response) {
        setFilterCategory(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  useEffect(() => {
    GetAllCatgory();
  }, []);

  const upload_file = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (isFileImage(file) === true) {
        setproduct_category({
            ...product_category,
            product_category_image_name: file.name,
            product_category_file: file,
            product_category_type: file.type,
            product_category_image_path: URL.createObjectURL(file),
        });
        setproduct_category_image_path_null(false)
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Please upload a file of type: JPG, PNG, or PDF only.",
        });
      }
    }
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>จัดการประเภทบันทึกข้อความ</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                  <li className="breadcrumb-item active">จัดการประเภทบันทึกข้อความ</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={uploadFile}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">จัดการประเภทบันทึกข้อความ {pageMode}</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="award_point_name"
                      value={product_category.product_category_name}
                      // placeholder="ตัวอย่าง สมศรี เรืองศักดา,Somsri Ruangsakda"
                      disabled={disable}
                      onChange={(e) => {
                        check_rf_name(e);
                      }}
                    />
                    <label>
                      ชื่อหมวดหมู่	
                      <span style={{ color: "red" }}>{ !disable ? " *" : ""}</span>
                    </label>{" "}
                    {nameNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกหมวดหมู่
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row ">
                    <div className="col-12 col-md-6 col-xl-6">
                      <label htmlFor="exampleInputFile">
                        Part Image{" "}
                        <span style={{ color: "red" }}>size(400 x 300) px</span>
                      </label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="exampleInputFile"
                            disabled={disable}
                            accept="image/*"
                            onChange={upload_file}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            {product_category.product_category_image_name !== "" && 
                            product_category.product_category_image_name !== null && 
                            product_category.product_category_image_name !== undefined

                              ? product_category.product_category_image_name.length > 25
                                ? product_category.product_category_image_name.substring(0, 25) +
                                  "..."
                                : product_category.product_category_image_name
                              : "Select Image"}
                          </label>
                        </div>
                      </div>
                      {product_category_image_path_null ? (
                        <span style={{ color: "red" }}>
                          *กรุณาอัปโหลดรูปภาพ
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 col-xl-3">
                        <label htmlFor="exampleInputFile"> </label>
                        <img
                            id="img"
                            alt="..."
                            className="img-fluid rounded shadow border-0"
                            src={
                                product_category.product_category_image_path !== "" &&
                                product_category.product_category_image_path !== null &&
                                product_category.product_category_image_path !== undefined
                                ? product_category.product_category_image_path?.indexOf("blob") >= 0
                                    ? product_category.product_category_image_path
                                    : Configs.API_URL_IMG +
                                    product_category.product_category_image_path
                                    : userdefault_img.imgs
                            }
                            style={{
                            width: "400px",
                            height: "300px",
                            objectFit: "cover",
                            position: "relative",
                            marginTop: "10px",
                            }}
                        />
                        <br />{" "}
                        <span style={{ color: "red", fontSize: "15px" }}>
                            Recommend Size:400x300px{" "}
                        </span>
                        <span style={{ color: "red", fontSize: "12px" }}></span>
                    </div>
                  </div>
              {/* card-body */}
            </div>
            {/* card */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default ManageMessageTypeAdd;
