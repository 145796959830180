import React, { useRef } from "react";
import { useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import Quotation_Cute_Product from "./Component/Quotation_Cute_Product";
import Select from "react-select";

function Quotation_AEV({ mode, isHistory }) {
  const { id } = useParams();
  const closeModalCustomer = useRef();
  const [disable, setdisable] = useState(null);
  const [pageMode, setPageMode] = useState("");
  const [quotation_data, setQuotation_data] = useState({
    quo_id: "",
    quo_no: "",
    quo_ref_id: "",
    quo_date: "",
    quo_valid_unit: "",
    quo_cus_id: "",
    quo_cus_id_supply: "",
    quo_cus_name: "",
    quo_cus_house_number: "",
    quo_cus_building: "",
    quo_swine: "",
    quo_soi: "",
    quo_road: "",
    quo_sub_district: "",
    quo_district: "",
    quo_province: "",
    quo_postcode: "",
    quo_ccp_id: "",
    quo_contract_name: "",
    quo_contract_email: "",
    quo_contract_tel: "",
    quo_pt_id: "",
    quo_term: "",
    quo_cus_remark: "",
    quo_remark: "",
    quo_lang: false, /// true is TH and flase is ENG
    quo_incl_vat: false,
    quo_final_discount: "",
    quo_shipping_cost: "",
    quo_total_befor_vat: "",
    quo_vat: "",
    quo_total_price: "",
    quo_is_use: "",
    quo_created_date: "",
    quo_updated_date: "",
    quo_created_by: "",
    quo_updated_by: "",
    quo_qs_id: "",
    quo_approve_date: "",
    quo_approve_user_id: "",
    quo_approve_user_name: "",
    quo_version: "",
    quo_created_name: "",
    quo_updated_name: "",
    quo_ct_id: "",
    quo_ct_name: "",
    quo_pl_id: "",
    /// gp_standard
    quo_gp_standard: "",
    quo_final_discount_bath: "",
    qoutation_product: [],
    quo_reset: false,
    quo_confirm_customer: false,
  });

  const [paymentTermData, setPaymentTermData] = useState({
    data: [],
  });

  const [on_change_cusName, setOn_change_cusName] = useState({
    data: "",
  });

  const [quotation_upload_files, setQuotation_upload_files] = useState({
    data: [],
    new: [],
    old: [],
    del: [],
  });

  const [inputDataTab2, setInputDataTab2] = useState({
    data: [],
  });

  const [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });

  const [functionSaveTab2, setFunctionSaveTab2] = useState({
    data: null,
  });

  const [refQuotationDD, setRefQuotationDD] = useState({
    data: [],
  });

  const [refPriceListDD, setRefPriceListDD] = useState({
    data: []
  });

  const [customerTypeData, setCustomerTypeData] = useState({
    data: [],
  });

  const [is_cus_ref, setIs_cus_ref] = useState({
    data: "",
  });

  const allowedTypes = ["image/png", "image/jpeg"];
  const allowedTypes2 = [
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/png",
    "image/jpeg",
  ];
  const uploadRef = useRef(null);
  const [customerDD, setCustomerDD] = useState({
    dataNomap: [],
    data: [],
  });
  const [customerNameDD, setCustomerNameDD] = useState({
    data: [],
  });
  useEffect(async () => {
    await GetCustomerType();
    if (!id) {
      setQuotation_data({
        ...quotation_data,
        quo_no: "Auto Generate",
        quo_date: new Date(),
        quo_valid_unit: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        quo_ct_id: "fd5acfc9-6d49-45fc-b7bf-aba6e2536b93",
        quo_ct_name: "นิติบุคคล"
      });
      // await getCurrentQuotationNO();
    }
    if (id) {
      await getQuotationById();
      await getFileQuotation();
    }
    await getCustomerDropDown();
    await getQuotationRef();
    await getPriceListRef();
    await GetPaymentTerm();
    await getProductLogAndDetails();
  }, []);

  useEffect(async () => {
    if (mode === "view") {
      setPageMode("View");
      setdisable(true);
    } else {
      setdisable(false);
      setPageMode(mode === "add" ? "Add" : "Edit");
    }
  }, []);

  const clickCloseModalCustomer = () => {
    closeModalCustomer.current.click();
  };

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  async function GetPaymentTerm() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getPaymentTerm",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setPaymentTermData({
          ...paymentTermData,
          data: response.data,
        });

        paymentTermData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCustomerType() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com
    }
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(async (response) => {
        setCustomerTypeData({
          ...customerTypeData,
          data: response.data,
        });
        customerTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const addCustomerDetails = async () => {
    const cus_id = quotation_data.quo_cus_id;
    const cus_name_id = quotation_data.quo_ccp_id || null;
    const tempAddress = customerDD.dataNomap?.find((el) => el.cus_id === cus_id);
    const tempCustomer =
      tempAddress.customerList.length > 0
        ? tempAddress.customerList.find((el) => el.ccp_id === cus_name_id)
        : null;
    // console.log('tempAddress, tempCustomer', tempAddress);
    let checkCusCommon = false;
    if (quotation_data.quo_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
      checkCusCommon = true;
    }
    setQuotation_data({
      ...quotation_data,
      quo_cus_name: tempAddress.cus_name || "",
      quo_cus_house_number: tempAddress[checkCusCommon ? "cus_house_number" : "cus_branch_house_number"] || "",
      quo_cus_building: tempAddress[checkCusCommon ? "cus_building" : "cus_branch_building"] || "",
      quo_swine: tempAddress[checkCusCommon ? "cus_swine" : "cus_branch_swine"] || "",
      quo_soi: tempAddress[checkCusCommon ? "cus_soi" : "cus_branch_soi"] || "",
      quo_road: tempAddress[checkCusCommon ? "cus_road" : "cus_branch_road"] || "",
      quo_sub_district: tempAddress[checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"] || "",
      quo_district: tempAddress[checkCusCommon ? "cus_district" : "cus_branch_district"] || "",
      quo_province: tempAddress[checkCusCommon ? "cus_province" : "cus_branch_province"] || "",
      quo_postcode: tempAddress[checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] || "",
      quo_pt_id: tempAddress.cus_pt_id || null,
      quo_term: tempAddress.cus_term || "",
      quo_cus_remark: tempAddress.cus_remark || "",
      quo_contract_name: tempCustomer?.ccp_name || "",
      quo_contract_email: tempCustomer?.ccp_email || "",
      quo_contract_tel: tempCustomer?.ccp_tel || "",
      quo_gp_standard: tempCustomer?.ccp_gp_standard || "",
    });
    quotation_data.quo_cus_name = tempAddress.cus_name || "";
    quotation_data.quo_cus_house_number = tempAddress[checkCusCommon ? "cus_house_number" : "cus_branch_house_number"] || "";
    quotation_data.quo_cus_building = tempAddress[checkCusCommon ? "cus_building" : "cus_branch_building"] || "";
    quotation_data.quo_swine = tempAddress[checkCusCommon ? "cus_swine" : "cus_branch_swine"] || "";
    quotation_data.quo_soi = tempAddress[checkCusCommon ? "cus_soi" : "cus_branch_soi"] || "";
    quotation_data.quo_road = tempAddress[checkCusCommon ? "cus_road" : "cus_branch_road"] || "";
    quotation_data.quo_sub_district = tempAddress[checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"] || "";
    quotation_data.quo_district = tempAddress[checkCusCommon ? "cus_district" : "cus_branch_district"] || "";
    quotation_data.quo_province = tempAddress[checkCusCommon ? "cus_province" : "cus_branch_province"] || "";
    quotation_data.quo_postcode = tempAddress[checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] || "";
    quotation_data.quo_pt_id = tempAddress.cus_pt_id || null;
    quotation_data.quo_term = tempAddress.cus_term || "";
    quotation_data.quo_cus_remark = tempAddress.cus_remark || "";
    quotation_data.quo_contract_name = tempCustomer?.ccp_name || "";
    quotation_data.quo_contract_email = tempCustomer?.ccp_email || "";
    quotation_data.quo_contract_tel = tempCustomer?.ccp_tel || "";
    quotation_data.quo_gp_standard = tempCustomer?.ccp_gp_standard || "";

    if (tempAddress.cus_name) {
      setOn_change_cusName({ data: tempAddress.cus_name });
      on_change_cusName.data = tempAddress.cus_name;
    }
  };

  const getCustomerDropDown = async () => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerAndNameDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        const customer_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id, cus_ct_id: el.cus_ct_id }));
        customer_dd.unshift({
          label: "--Select Customer--",
          value: "",
          cus_ct_id: null
        })
        setCustomerDD({ ...customerDD, data: customer_dd, dataNomap: response.data });
        customerDD.data = customer_dd;
        customerDD.dataNomap = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getQuotationRef = async () => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotationRef",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setRefQuotationDD({ ...refQuotationDD, data: response.data });
        refQuotationDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getPriceListRef = async () => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/price_list/getPriceListRef",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setRefPriceListDD({ ...refPriceListDD, data: response.data });
        refPriceListDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getCurrentQuotationNO = async () => {
    let temp = {
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/getCurrentQuotationNO",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log('response', response.data);
        setQuotation_data({
          ...quotation_data,
          quo_no: response.data,
          quo_date: new Date(),
        });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const sendApproveQuotation = () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะ Send Approve Quotation นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave();

        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
          return;
        } else {
          const temp = {
            company_id: getUser().com,
            oem_id: getOem(),
            user_id: getUser().fup,
            user_name: getUser().name + " " + getUser().surname,
            data: quotation_data,
            products: inputDataTab2,
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus_sales +
              `/api/quotation/sendApproveQuotation`,
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(async function (response) {
              // console.log('response saveDrafQuotation -', response.data);
              await deleteImagesAndFilesOfProduct();

              Swal.fire({
                icon: "success",
                title: "Send Approve สำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.href =
                  "/Sale_cuteplut/Quotation_Sales_Cuteplut";
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
      }
    });
  };

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/Sale_cuteplut/Quotation_Sales_Cuteplut";
        }
      });
    } else {
      window.location.href = "/Sale_cuteplut/Quotation_Sales_Cuteplut";
    }
  }

  function isFileImage(file) {
    return file && allowedTypes2.includes(file["type"]);
  }

  async function encodeImage(file) {
    // console.log("file", file);
    if (allowedTypes2.includes(file[0]?.type) === false) {
      Swal.fire(
        "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
        "กรุณาอัปโหลดประเภทไฟล์ใหม่",
        "error"
      );
      return;
    }
    if (file[0].name) {
      const regex = /([\u0E00-\u0E7Fก-๙]+)/gmu;
      const str = file[0].name;
      const m = regex.exec(str);

      if (isFileImage(file[0]) === false) {
        Swal.fire({
          title: "ประเภทไฟล์ไม่ถูกต้อง",
          text: "",
          icon: "warning",
          confirmButtonColor: "#032973",
          confirmButtonText: "ตกลง",
        });
      } else {
        const oleTempFileUpload = quotation_upload_files.data;
        setQuotation_upload_files({ ...quotation_upload_files, data: [] });

        // const newFileUpload = quotaion_save_files.new;
        if (file.length > 0) {
          for (let index = 0; index < file.length; index += 1) {
            const tempDataImg = {
              qou_document_name: file[index].name,
              qou_path_document: URL.createObjectURL(file[index]),
              qou_image: file[index],
              oldNew: "new",
              status: "add",
            };
            // console.log('index', index);
            oleTempFileUpload.push(tempDataImg);
          }

          setQuotation_upload_files({
            ...quotation_upload_files,
            data: oleTempFileUpload,
          });
          quotation_upload_files.data = oleTempFileUpload;
          await saveQuotationFiles();
        }
      }
    }
  }

  async function saveQuotationFiles() {
    const findCheckTrue = quotation_upload_files.data.filter((item) => {
      return item.status !== "del";
    });

    const addNew = quotation_upload_files.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const delOld = quotation_upload_files.del.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setQuotation_upload_files({
      ...quotation_upload_files,
      data: findCheckTrue,
      new: addNew,
      del: delOld,
    });

    quotation_upload_files.data = findCheckTrue;
    quotation_upload_files.new = addNew;
    quotation_upload_files.del = delOld;

    console.log(
      quotation_upload_files.data,
      quotation_upload_files.new,
      quotation_upload_files.del
    );
  }

  const checkErrorForSave = async () => {
    let error_list = [];
    // console.log('quotation_data', quotation_data);
    if (on_change_cusName.data) {
      if (on_change_cusName.data?.trim() !== quotation_data.quo_cus_name) {
        quotation_data.quo_cus_id = "";
      } else {
        const check = customerDD?.data?.find(
          (el) => el.cus_name === quotation_data.quo_cus_name
        );

        if (check) {
          quotation_data.quo_cus_id = check.cus_id;
        }
      }
    } else {
      quotation_data.quo_cus_id = "";
    }

    if (quotation_data.quo_term) {
      quotation_data.quo_term =
        typeof quotation_data.quo_term === "string"
          ? parseFloat(quotation_data.quo_term?.replace(/,/g, ""))
          : quotation_data.quo_term;
    }

    if (quotation_data.quo_cus_name) {
      quotation_data.quo_cus_name = quotation_data.quo_cus_name?.trim();
    }
    if (quotation_data.quo_cus_house_number) {
      quotation_data.quo_cus_house_number =
        quotation_data.quo_cus_house_number?.trim();
    }
    if (quotation_data.quo_cus_building) {
      quotation_data.quo_cus_building = quotation_data.quo_cus_building?.trim();
    }
    if (quotation_data.quo_swine) {
      quotation_data.quo_swine = quotation_data.quo_swine?.trim();
    }
    if (quotation_data.quo_soi) {
      quotation_data.quo_soi = quotation_data.quo_soi?.trim();
    }
    if (quotation_data.quo_road) {
      quotation_data.quo_road = quotation_data.quo_road?.trim();
    }
    if (quotation_data.quo_sub_district) {
      quotation_data.quo_sub_district = quotation_data.quo_sub_district?.trim();
    }
    if (quotation_data.quo_district) {
      quotation_data.quo_district = quotation_data.quo_district?.trim();
    }
    if (quotation_data.quo_postcode) {
      quotation_data.quo_postcode = quotation_data.quo_postcode?.trim();
    }
    if (quotation_data.quo_contract_email) {
      quotation_data.quo_contract_email =
        quotation_data.quo_contract_email?.trim();
    }
    if (quotation_data.quo_contract_name) {
      quotation_data.quo_contract_name =
        quotation_data.quo_contract_name?.trim();
    }
    if (quotation_data.quo_cus_remark) {
      quotation_data.quo_cus_remark = quotation_data.quo_cus_remark?.trim();
    }
    if (quotation_data.quo_remark) {
      quotation_data.quo_remark = quotation_data.quo_remark?.trim();
    }
    if (quotation_data.quo_final_discount) {
      quotation_data.quo_final_discount =
        typeof quotation_data.quo_final_discount === "string"
          ? parseFloat(quotation_data.quo_final_discount?.replace(/,/g, ""))
          : quotation_data.quo_final_discount;
    }
    if (quotation_data.quo_shipping_cost) {
      quotation_data.quo_shipping_cost =
        typeof quotation_data.quo_shipping_cost === "string"
          ? parseFloat(quotation_data.quo_shipping_cost?.replace(/,/g, ""))
          : quotation_data.quo_shipping_cost;
    }
    if (quotation_data.quo_total_befor_vat) {
      quotation_data.quo_total_befor_vat =
        typeof quotation_data.quo_total_befor_vat === "string"
          ? parseFloat(quotation_data.quo_total_befor_vat?.replace(/,/g, ""))
          : quotation_data.quo_total_befor_vat;
    }
    /* if (quotation_data.quo_contract_tel) {
      quotation_data.quo_contract_tel =
        quotation_data.quo_contract_tel?.replace(/-/g, "");
    } */
    if (quotation_data.quo_vat) {
      quotation_data.quo_vat =
        typeof quotation_data.quo_vat === "string"
          ? parseFloat(quotation_data.quo_vat?.replace(/,/g, ""))
          : quotation_data.quo_vat;
    }
    if (quotation_data.quo_total_price) {
      quotation_data.quo_total_price =
        typeof quotation_data.quo_total_price === "string"
          ? parseFloat(quotation_data.quo_total_price?.replace(/,/g, ""))
          : quotation_data.quo_total_price;
    }
    if (quotation_data.quo_version) {
      quotation_data.quo_version =
        typeof quotation_data.quo_version === "string"
          ? parseFloat(quotation_data.quo_version?.replace(/,/g, ""))
          : quotation_data.quo_version;
    }

    if (
      quotation_data.quo_valid_unit === null ||
      quotation_data.quo_valid_unit === ""
    ) {
      let temp_err = {
        message: `กรุณาเลือก Valid Unit`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_cus_name === null ||
      quotation_data.quo_cus_name.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอก Customer Name`,
      };
      error_list.push(temp_err);
    }

    /* if (
      quotation_data.quo_cus_house_number === null ||
      quotation_data.quo_cus_house_number.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกบ้านเลขที่`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_cus_building === null ||
      quotation_data.quo_cus_building.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกอาคาร`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_swine === null ||
      quotation_data.quo_swine.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกหมู่`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_soi === null ||
      quotation_data.quo_soi.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกซอย`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_road === null ||
      quotation_data.quo_road.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกถนน`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_sub_district === null ||
      quotation_data.quo_sub_district.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกแขวง/ตำบล`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_district === null ||
      quotation_data.quo_district.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกเขต/อำเภอ`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_province === null ||
      quotation_data.quo_province.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกจังหวัด`,
      };
      error_list.push(temp_err);
    } */

    if (
      quotation_data.quo_postcode === null ||
      quotation_data.quo_postcode.trim() === ""
    ) {
      /* let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์`,
      };
      error_list.push(temp_err); */
    } else if (quotation_data.quo_postcode?.length !== 5) {
      let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์ให้ครบ 5 หลัก`,
      };
      error_list.push(temp_err);
    }

    if (
      quotation_data.quo_contract_name === null ||
      quotation_data.quo_contract_name.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอก Customer Contact Name`,
      };
      error_list.push(temp_err);
    }

    /* if (quotation_data.quo_contract_tel.trim() !== "") {
      if ((quotation_data.quo_contract_tel?.replace(/-/g, "").trim()).length !== 10) {
        let temp_err = {
          message: `Contact Tel ต้องมี 10 หลัก`,
        };
        error_list.push(temp_err);
      }
    } */

    if (quotation_data.quo_pt_id === null || quotation_data.quo_pt_id === "") {
      let temp_err = {
        message: `กรุณาเลือก Payment term`,
      };
      error_list.push(temp_err);
    }

    if (quotation_data.quo_term === null || quotation_data.quo_term === "") {
      let temp_err = {
        message: `กรุณากรอก term`,
      };
      error_list.push(temp_err);
    }

    return error_list;
  };

  const deleteImagesAndFilesOfProduct = async () => {
    /* await axios({
            method: "get",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/delImagesAndFilesOfProduct/" + id,
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            // data: tempData1,
        }) */
    let tempData1 = {
      del_list: quotation_upload_files.del,
      user_id: getUser().fup,
      user_name: `${getUser().name} ${getUser().surname}`,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/delImagesAndFiles",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData1,
    })
      .then(async (response) => {
        if (response.data) {
          await uploadAndSaveImageAdvance(
            mode === "edit" ? id : response,
            "file_quotation_sales"
          );
        }
      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถลบเอกสารได้",
        });
      });
  };

  const uploadAndSaveImageAdvance = async (quo_id, fileGroup) => {
    // console.log('inputDataTab2.data?.documentFile', inputDataTab2.data?.documentFile);
    // console.log('inputDataTab3.data?.dataFoodRegister', inputDataTab3.data?.dataFoodRegister);
    let arrFiles = [];
    /* 
        let temp = {}
                temp.name = el.qou_document_name
                temp.typeFile = "เอกสาร"
                temp.path = el.qou_image
                temp.remark = el.remark || ""
                return temp
        */
    if (fileGroup === "file_quotation_sales") {
      let data = new FormData();
      if (quotation_upload_files.new.length === 0) {
        return;
      }
      await quotation_upload_files.new?.forEach(async (el) => {
        data.append("Profile", el.qou_image);
        data.append("ProfileName", el.qou_document_name);
      });
      data.append("company_id", getUser().com);
      data.append("oem_id", getOem());
      data.append("user_id", getUser().fup);
      data.append("user_name", `${getUser().name} ${getUser().surname}`);
      data.append("Project", "Cute_Products_Plus");
      data.append("Menu", "Quotation (Sales)");
      data.append("Ref", quo_id);

      data.append("group", "file_quotation_sales");

      await axios({
        method: "post",
        url: Configs.API_URL_IMG_cuteplus + "/api/upload/saveDataUploadFile",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      })
        .then(async (response) => { })
        .catch(async (error) => {
          Swal.fire({
            icon: "error",
            title: "ไม่สามารถอัปโหลดเอกสารได้",
          });
        });
    }
  };

  const getFileQuotation = async () => {
    await axios({
      method: "get",
      url:
        Configs.API_URL_IMG_cuteplus +
        "/api/upload/getDataFileGroupAll/" +
        id +
        "/file_quotation_sales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData1,
    })
      .then(async (response) => {
        // console.log(response.data);
        if (response.data.length > 0) {
          const file_list = response.data?.map((el, idx) => {
            return {
              ul_id: el.ul_id,
              qou_document_name: el.ul_file_name,
              qou_path_document: el.ul_path_file,
              oldNew: "old",
            };
          });
          setQuotation_upload_files({
            ...quotation_upload_files,
            data: file_list,
          });
          quotation_upload_files.data = file_list;
          // console.log('file_list', file_list);
        }
      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถอัปโหลดเอกสารได้",
        });
      });
  };

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave();

        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              data: quotation_data,
              products: inputDataTab2,
            };
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus_sales +
                `/api/quotation/${mode === "add" ? "saveDrafQuotation" : "updateDrafQuotation"
                }`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                // console.log('response saveDrafQuotation -', response.data);
                if (response.data) {
                  if (
                    mode === "edit" &&
                    quotation_upload_files.data.length > 0
                  ) {
                    await deleteImagesAndFilesOfProduct();
                  }
                }
              })
              .then(async function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href =
                    "/Sale_cuteplut/Quotation_Sales_Cuteplut";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  const [product_LogExp, setProduct_LogExp] = useState({
    data: []
  });

  const getProductLogAndDetails = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      code: null,
      name: null,
      category: null,
      customer_barcode: null,
      customer_product_name: null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductLogAndDetails",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {

        if (response.data.length > 0) {

          setProduct_LogExp({ ...product_LogExp, data: response.data });
          product_LogExp.data = response.data;

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const generateSO_Quotation = async () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะ Generate SO Quotation นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave();

        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
          return;
        } else {

          for (let item of inputDataTab2.data) {

            const check_has = product_LogExp.data?.find(el => el.product_id === item.product_id)
            if (check_has && check_has.exp_date_list.length > 0) {
              item.exp = check_has.exp_date_list[0].exp
            }

          }
          const temp = {
            company_id: getUser().com,
            oem_id: getOem(),
            user_id: getUser().fup,
            user_name: getUser().name + " " + getUser().surname,
            data: quotation_data,
            products: inputDataTab2,
          };
          // return console.log('temp', temp);
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus_sales +
              `/api/quotation/generateSO_Quotation`,
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(async function (response) {
              // console.log('response saveDrafQuotation -', response.data);
              await deleteImagesAndFilesOfProduct();
              if (response.data) {
                await addBillfromSO(response.data);
                // await addLogInfromSO(response.data);
              }

            })
            .then(async function (response) {
              Swal.fire({
                icon: "success",
                title: "Generate SO สำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.href =
                  "/Sale_cuteplut/Quotation_Sales_Cuteplut";
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
      }
    });
  };

  const addLogInfromSO = async (response) => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus +
        `/api/log_product/addLogProduct`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: { temp2: response.product_list },
    })
      .then(async function (response) {
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const addBillfromSO = async (temp) => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus +
        `/api/bill/addBillfromSO`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const cancelQuotation = async () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ Quotation นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const temp = {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          user_name: getUser().name + " " + getUser().surname,
          quo_id: id,
          quo_no: quotation_data.quo_no
        };
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus_sales + `/api/quotation/cancelQuotation`,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            // console.log('response saveDrafQuotation -', response.data);

            Swal.fire({
              icon: "success",
              title: "ยกเลิกรายการ Quotation สำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href = "/Sale_cuteplut/Quotation_Sales_Cuteplut";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  };
  const [ref_id_qout, setRef_id_qout] = useState({
    data: "",
    from: "",
  });

  const resetQuotationRef = async () => {
    setQuotation_data({
      ...quotation_data,
      quo_ref_id: "",
      quo_cus_id: "",
      quo_cus_name: "",
      quo_cus_house_number: "",
      quo_cus_building: "",
      quo_swine: "",
      quo_soi: "",
      quo_road: "",
      quo_sub_district: "",
      quo_district: "",
      quo_province: "",
      quo_postcode: "",
      quo_ccp_id: "",
      quo_contract_name: "",
      quo_contract_email: "",
      quo_contract_tel: "",
      quo_pt_id: "",
      quo_term: "",
      quo_cus_remark: "",
      quo_remark: "",
      quo_lang: true, /// true is TH and flase is ENG
      quo_incl_vat: true,
      quo_final_discount: "",
      quo_shipping_cost: "",
      quo_total_befor_vat: "",
      quo_vat: "",
      quo_total_price: "",
      quo_is_use: "",
      quo_created_date: "",
      quo_updated_date: "",
      quo_created_by: "",
      quo_updated_by: "",
      quo_qs_id: "",
      quo_approve_date: "",
      quo_approve_user_id: "",
      quo_approve_user_name: "",
      quo_created_name: "",
      quo_updated_name: "",

      /// gp_standard
      quo_gp_standard: "",
      qoutation_product: [],
      quo_reset: true,
      quo_pl_id: ""
    });
    quotation_data.quo_pl_id = "";
    quotation_data.quo_ref_id = "";
    quotation_data.quo_cus_id = "";
    quotation_data.quo_cus_name = "";
    quotation_data.quo_cus_house_number = "";
    quotation_data.quo_cus_building = "";
    quotation_data.quo_swine = "";
    quotation_data.quo_soi = "";
    quotation_data.quo_road = "";
    quotation_data.quo_sub_district = "";
    quotation_data.quo_district = "";
    quotation_data.quo_province = "";
    quotation_data.quo_postcode = "";
    quotation_data.quo_ccp_id = "";
    quotation_data.quo_contract_name = "";
    quotation_data.quo_contract_email = "";
    quotation_data.quo_contract_tel = "";
    quotation_data.quo_pt_id = "";
    quotation_data.quo_term = "";
    quotation_data.quo_cus_remark = "";
    quotation_data.quo_remark = "";
    quotation_data.quo_lang = true; /// true is TH and flase is EN;
    quotation_data.quo_incl_vat = true;
    quotation_data.quo_final_discount = "";
    quotation_data.quo_shipping_cost = "";
    quotation_data.quo_total_befor_vat = "";
    quotation_data.quo_vat = "";
    quotation_data.quo_total_price = "";
    quotation_data.quo_is_use = "";
    quotation_data.quo_created_date = "";
    quotation_data.quo_updated_date = "";
    quotation_data.quo_created_by = "";
    quotation_data.quo_updated_by = "";
    quotation_data.quo_qs_id = "";
    quotation_data.quo_approve_date = "";
    quotation_data.quo_approve_user_id = "";
    quotation_data.quo_approve_user_name = "";
    quotation_data.quo_version = "";
    quotation_data.quo_created_name = "";
    quotation_data.quo_updated_name = "";

    /// gp_standard
    quotation_data.quo_gp_standard = "";
    quotation_data.qoutation_product = [];
    quotation_data.quo_reset = true;
  };

  const clearCustomer = async () => {
    setQuotation_data({
      ...quotation_data,
      quo_cus_name: "",
      quo_cus_id: "",
      quo_cus_house_number: "",
      quo_cus_building: "",
      quo_swine: "",
      quo_soi: "",
      quo_road: "",
      quo_sub_district: "",
      quo_district: "",
      quo_province: "",
      quo_postcode: "",
      quo_pt_id: "",
      quo_term: "",
      quo_cus_remark: "",
      quo_contract_name: "",
      quo_contract_email: "",
      quo_contract_tel: "",
      quo_gp_standard: "",
    });
    quotation_data.quo_cus_name = "";
    quotation_data.quo_cus_id = "";
    quotation_data.quo_cus_house_number = "";
    quotation_data.quo_cus_building = "";
    quotation_data.quo_swine = "";
    quotation_data.quo_soi = "";
    quotation_data.quo_road = "";
    quotation_data.quo_sub_district = "";
    quotation_data.quo_district = "";
    quotation_data.quo_province = "";
    quotation_data.quo_postcode = "";
    quotation_data.quo_pt_id = "";
    quotation_data.quo_term = "";
    quotation_data.quo_cus_remark = "";
    quotation_data.quo_contract_name = "";
    quotation_data.quo_contract_email = "";
    quotation_data.quo_contract_tel = "";
    quotation_data.quo_gp_standard = "";
  };

  const getPriceListById = async (id) => {
    let temp = {
      pl_id: id,
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + `/api/price_list/${"getPriceListById"}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        const data = response.data[0];
        if (data) {
          /* console.log('data.product_list', data.product_list);
          setInputDataTab2({
            ...inputDataTab2,
            data: data.product_list
          });
          inputDataTab2.data = data.product_list; */

          setQuotation_data({
            ...quotation_data,
            quo_ref_id: "",
            quo_cus_id: data.pl_cus_id || "",
            quo_cus_name: data.pl_cus_name || "",
            quo_cus_house_number: data.pl_cus_house_number || "",
            quo_cus_building: data.pl_cus_building || "",
            quo_swine: data.pl_swine || "",
            quo_soi: data.pl_soi || "",
            quo_road: data.pl_road || "",
            quo_sub_district: data.pl_sub_district || "",
            quo_district: data.pl_district || "",
            quo_province: data.pl_province || "",
            quo_postcode: data.pl_postcode || "",
            quo_ccp_id: "",
            quo_contract_name: "",
            quo_contract_email: "",
            quo_contract_tel: "",
            quo_pt_id: data.pl_pt_id || "",
            quo_term: data.pl_term || "",
            quo_cus_remark: data.pl_cus_remark || "",
            quo_remark: data.pl_remark || "",
            quo_lang: data.pl_lang === "TH" ? true : false, /// true is TH and flase is ENG
            quo_incl_vat: data.pl_incl_vat,
            quo_final_discount_bath: "",
            quo_shipping_cost: "",
            quo_total_befor_vat: data.pl_total_befor_vat || "",
            quo_vat: data.pl_vat || "",
            quo_total_price: data.pl_total_price || "",
            quo_ct_id: data.pl_ct_id || null,
            quo_ct_name: data.pl_ct_id ? customerTypeData.data?.find(el => el.ct_id === data.pl_ct_id).ct_name || "" : null,
            quo_final_discount: "",
          });
          quotation_data.quo_ref_id = "";
          quotation_data.quo_cus_id = data.pl_cus_id || "";
          quotation_data.quo_cus_name = data.pl_cus_name || "";
          quotation_data.quo_cus_house_number = data.pl_cus_house_number || "";
          quotation_data.quo_cus_building = data.pl_cus_building || "";
          quotation_data.quo_swine = data.pl_swine || "";
          quotation_data.quo_soi = data.pl_soi || "";
          quotation_data.quo_road = data.pl_road || "";
          quotation_data.quo_sub_district = data.pl_sub_district || "";
          quotation_data.quo_district = data.pl_district || "";
          quotation_data.quo_province = data.pl_province || "";
          quotation_data.quo_postcode = data.pl_postcode || "";
          quotation_data.quo_ccp_id = "";
          quotation_data.quo_contract_name = "";
          quotation_data.quo_contract_email = "";
          quotation_data.quo_contract_tel = "";
          quotation_data.quo_pt_id = data.pl_pt_id || "";
          quotation_data.quo_term = data.pl_term || "";
          quotation_data.quo_cus_remark = data.pl_cus_remark || "";
          quotation_data.quo_remark = data.pl_remark || "";
          quotation_data.quo_lang = data.pl_lang === "TH" ? true : false; /// true is TH and flase is ENG
          quotation_data.quo_incl_vat = data.pl_incl_vat;
          quotation_data.quo_final_discount_bath = "";
          quotation_data.quo_shipping_cost = "";
          quotation_data.quo_total_befor_vat = data.pl_total_befor_vat || "";
          quotation_data.quo_vat = data.pl_vat || "";
          quotation_data.quo_total_price = data.pl_total_price || "";
          quotation_data.quo_ct_id = data.pl_ct_id || null;
          quotation_data.quo_ct_name = data.pl_ct_id ? customerTypeData.data?.find(el => el.ct_id === data.pl_ct_id).ct_name || "" : null;
          quotation_data.quo_final_discount = ""

          if (data.quo_cus_name) {
            setOn_change_cusName({ data: data.pl_cus_name });
            on_change_cusName.data = data.pl_cus_name;
          }
        }
      })
      .catch(function (error) {
        console.log('error', error);
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getQuotationById = async (ref_id) => {
    let temp = {
      quo_id: ref_id ? ref_id : id,
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      isHistory: isHistory,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + `/api/quotation/${"getQuotationById"}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        const data = response.data.data[0];
        /* 
          มีเข้ามาจากการเลือก ref กับ history
          ดึง ref เลือกเอาข้อมูลที่จำเป็น
          ดึง history แสดงทั้งหมด
        */
        if ((mode === "edit" || mode === "view") && !ref_id) {
          setQuotation_data({
            ...quotation_data,
            quo_no: data.quo_no || "",
            quo_date: new Date(data.quo_date) || "",
            quo_valid_unit: new Date(data.quo_valid_unit) || "",
            quo_approve_date: data.quo_approve_date || "",
            quo_approve_user_id: data.quo_approve_user_id || "",
            quo_approve_user_name: data.quo_approve_user_name || "",
            quo_version: data.quo_version || "",
            quo_created_name: data.quo_created_name || "",
            quo_updated_name: data.quo_updated_name || "",
            quo_created_date: data.quo_created_date || "",
            quo_updated_date: data.quo_updated_date || "",
            quo_created_by: data.quo_created_by || "",
            quo_updated_by: data.quo_updated_by || "",
          });
          quotation_data.quo_no = data.quo_no || "";
          quotation_data.quo_date = new Date(data.quo_date) || "";
          quotation_data.quo_valid_unit = new Date(data.quo_valid_unit) || "";
          quotation_data.quo_approve_date = data.quo_approve_date || "";
          quotation_data.quo_approve_user_id = data.quo_approve_user_id || "";
          quotation_data.quo_approve_user_name =
            data.quo_approve_user_name || "";
          quotation_data.quo_version = data.quo_version || "";
          quotation_data.quo_created_name = data.quo_created_name || "";
          quotation_data.quo_updated_name = data.quo_updated_name || "";
          quotation_data.quo_created_date = data.quo_created_date || "";
          quotation_data.quo_updated_date = data.quo_updated_date || "";
          quotation_data.quo_created_by = data.quo_created_by || "";
          quotation_data.quo_updated_by = data.quo_updated_by || "";
        }
        setQuotation_data({
          ...quotation_data,
          quo_id: mode === "edit" || mode === "view" ? id : "",
          quo_ref_id: ref_id ? ref_id : data.quo_ref_id || "",
          quo_cus_id: data.quo_cus_id || "",
          quo_cus_name: data.quo_cus_name || "",
          quo_cus_house_number: data.quo_cus_house_number || "",
          quo_cus_building: data.quo_cus_building || "",
          quo_swine: data.quo_swine || "",
          quo_soi: data.quo_soi || "",
          quo_road: data.quo_road || "",
          quo_sub_district: data.quo_sub_district || "",
          quo_district: data.quo_district || "",
          quo_province: data.quo_province || "",
          quo_postcode: data.quo_postcode || "",
          quo_ccp_id: data.quo_ccp_id || "",
          quo_contract_name: data.quo_contract_name || "",
          quo_contract_email: data.quo_contract_email || "",
          quo_contract_tel: data.quo_contract_tel || "",
          quo_pt_id: data.quo_pt_id || "",
          quo_term: data.quo_term || "",
          quo_cus_remark: data.quo_cus_remark || "",
          quo_remark: data.quo_remark || "",
          quo_lang: data.quo_lang === "TH" ? true : false, /// true is TH and flase is ENG
          quo_incl_vat: data.quo_incl_vat,
          quo_final_discount_bath: data.quo_final_discount || "",
          quo_shipping_cost: data.quo_shipping_cost || "",
          quo_total_befor_vat: data.quo_total_befor_vat || "",
          quo_vat: data.quo_vat || "",
          quo_total_price: data.quo_total_price || "",
          quo_is_use: data.quo_is_use || "",
          quo_qs_id: data.quo_qs_id || "",
          quo_ct_id: data.quo_ct_id || null,
          quo_ct_name: data.quo_ct_id ? customerTypeData.data?.find(el => el.ct_id === data.quo_ct_id).ct_name || "" : null,
          quo_final_discount: data.quo_final_discount_percent || "",
        });
        quotation_data.quo_id = mode === "edit" || mode === "view" ? id : "";
        quotation_data.quo_ref_id = ref_id ? ref_id : data.quo_ref_id || "";
        quotation_data.quo_cus_id = data.quo_cus_id || "";
        quotation_data.quo_cus_name = data.quo_cus_name || "";
        quotation_data.quo_cus_house_number = data.quo_cus_house_number || "";
        quotation_data.quo_cus_building = data.quo_cus_building || "";
        quotation_data.quo_swine = data.quo_swine || "";
        quotation_data.quo_soi = data.quo_soi || "";
        quotation_data.quo_road = data.quo_road || "";
        quotation_data.quo_sub_district = data.quo_sub_district || "";
        quotation_data.quo_district = data.quo_district || "";
        quotation_data.quo_province = data.quo_province || "";
        quotation_data.quo_postcode = data.quo_postcode || "";
        quotation_data.quo_ccp_id = data.quo_ccp_id || "";
        quotation_data.quo_contract_name = data.quo_contract_name || "";
        quotation_data.quo_contract_email = data.quo_contract_email || "";
        quotation_data.quo_contract_tel = data.quo_contract_tel || "";
        quotation_data.quo_pt_id = data.quo_pt_id || "";
        quotation_data.quo_term = data.quo_term || "";
        quotation_data.quo_cus_remark = data.quo_cus_remark || "";
        quotation_data.quo_remark = data.quo_remark || "";
        quotation_data.quo_lang = data.quo_lang === "TH" ? true : false; /// true is TH and flase is ENG
        quotation_data.quo_incl_vat = data.quo_incl_vat;
        quotation_data.quo_final_discount_bath = data.quo_final_discount || "";
        quotation_data.quo_shipping_cost = data.quo_shipping_cost || "";
        quotation_data.quo_total_befor_vat = data.quo_total_befor_vat || "";
        quotation_data.quo_vat = data.quo_vat || "";
        quotation_data.quo_total_price = data.quo_total_price || "";
        quotation_data.quo_is_use = data.quo_is_use || "";
        quotation_data.quo_qs_id = data.quo_qs_id || "";
        quotation_data.quo_ct_id = data.quo_ct_id || null;
        quotation_data.quo_ct_name = data.quo_ct_id ? customerTypeData.data?.find(el => el.ct_id === data.quo_ct_id).ct_name || "" : null;
        quotation_data.quo_final_discount = data.quo_final_discount_percent || ""

        if (data.quo_cus_name) {
          setOn_change_cusName({ data: data.quo_cus_name });
          on_change_cusName.data = data.quo_cus_name;
        }

        if (data.quo_ccp_id) {
          await getGP_CustomerContractPoint(data.quo_ccp_id);
        }
        /* quotation_data.quo_id = data.quo_id;
                quotation_data.quo_no = data.quo_no;
                quotation_data.quo_ref_id = data.quo_ref_id || ""; */

        // console.log('quotation_data.data', quotation_data);
      })
      .catch(function (error) {
        console.log('error', error);
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getGP_CustomerContractPoint = async (ccp_id) => {
    let temp = {
      ccp_id: ccp_id,
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus +
        `/api/customer/${"getGP_CustomerContractPoint"}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data) {
          const temp = { ...quotation_data };
          setQuotation_data({
            ...quotation_data,
            quo_gp_standard: response.data.ccp_gp_standard,
          });
          quotation_data.quo_gp_standard = response.data.ccp_gp_standard;
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const Custom_Input_date = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Quotation (Sales) {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sales</li>
                  <li className="breadcrumb-item active">Quotation</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-2">
                <div className="col-12 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={() => {
                      cancelOption();
                    }}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-12 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    Save
                  </button>
                </div>
                <div className="col-12 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={() => {
                      cancelOption();
                    }}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
                <div
                  className="col-12 col-md-3 col-xl-2"
                  hidden={mode !== "edit" ? true : false}
                >
                  <button
                    type="button"
                    onClick={sendApproveQuotation}
                    className="btn btn-block btn-info"
                    id="btn-save"
                  >
                    Send Approve
                  </button>
                </div>
              </div>
            )}

            <div className="row mb-2 mt-2">
              <div className="col-12 col-md-6 col-xl-6">
                <h4>
                  Quotation Current Version : {quotation_data.quo_version || ""}
                </h4>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div
                className="row"
                style={{ justifyContent: "start", alignItems: "start" }}
              >
                <div
                  className="col-3"
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <h3 className="card-title" style={{ whiteSpace: "nowrap" }}>
                    Quotation (Sales)
                  </h3>
                  <button
                    hidden={mode !== "edit" ? true : false}
                    onClick={async () => {
                      await cancelQuotation();
                    }}
                    style={{ whiteSpace: "nowrap", minWidth: "100px" }}
                    type="button"
                    className="btn btn-block btn-danger"
                  >
                    Cancel Quotation
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body">
              {/* <div className="row mb-2">
                                <div className="col-6">
                                    <h2>
                                        <b>Quotation Detail</b>
                                    </h2>
                                </div>
                            </div> */}

              <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={true}
                      value={quotation_data.quo_no}
                    />
                    <label htmlFor="">Quotation No.</label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_ref_id}
                      onChange={async (e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_ref_id: e.target.value,
                          quo_pl_id: ""
                        });
                        quotation_data.quo_ref_id = e.target.value;
                        quotation_data.quo_pl_id = "";
                        if (e.target.value) {
                          await getQuotationById(e.target.value);
                        } else {
                          await resetQuotationRef();
                        }
                        setRef_id_qout({
                          ...ref_id_qout,
                          data: e.target.value,
                          from: "quot"
                        });
                        ref_id_qout.data = e.target.value;
                        ref_id_qout.from = "quot";
                      }}
                    >
                      <option key="" value="">
                        Select a quotation
                      </option>
                      {refQuotationDD.data?.map((option, index) => (
                        <option key={option.quo_id} value={option.quo_id}>
                          {option.quo_no}
                        </option>
                      ))}
                    </select>
                    <label>Ref. Quotation No. </label>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_pl_id}
                      onChange={async (e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_pl_id: e.target.value,
                        });
                        quotation_data.quo_pl_id = e.target.value;
                        if (e.target.value) {
                          await getPriceListById(e.target.value);
                          // await functionSaveTab2.getProduct();
                        } else {
                          await resetQuotationRef();
                        }
                        setRef_id_qout({
                          ...ref_id_qout,
                          data: e.target.value,
                          from: "price_list"
                        });
                        ref_id_qout.data = e.target.value;
                        ref_id_qout.from = "price_list";
                      }}
                    >
                      <option key="" value="">
                        Select a price list
                      </option>
                      {refPriceListDD.data?.map((option, index) => (
                        <option key={option.pl_id} value={option.pl_id}>
                          {option.pl_no}
                        </option>
                      ))}
                    </select>
                    <label>Ref. Price List</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12 col-md-4 col-xl-3"
                  style={{ "z-index": "987" }}
                >
                  <div className="form-group ">
                    <DatePicker
                      selected={quotation_data.quo_date}
                      disabled={true}
                      dateFormat={"dd-MM-yyyy"}
                      popperPlacement="bottom"
                      wrapperClassName="w-100"
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"], // don't allow it to flip to be above
                        },
                        preventOverflow: {
                          enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                          enabled: false, // turn off since needs preventOverflow to be enabled
                        },
                      }}
                      onChange={async (date) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_date: date,
                        });
                      }}
                      // selectsStart
                      // startDate={quotation_data.quo_date}
                      minDate={quotation_data.quo_date}
                      customInput={<Custom_Input_date isDis={true} />}
                    />
                    <label htmlFor="">
                      Quotation Date
                      {quotation_data.quo_date === "" ||
                        quotation_data.quo_date === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div
                  className="col-12 col-md-4 col-xl-3"
                  style={{ "z-index": "99" }}
                >
                  <div className="form-group ">
                    <DatePicker
                      disabled={disable}
                      dateFormat={"dd-MM-yyyy"}
                      popperPlacement="bottom"
                      wrapperClassName="w-100"
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"], // don't allow it to flip to be above
                        },
                        preventOverflow: {
                          enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                          enabled: false, // turn off since needs preventOverflow to be enabled
                        },
                      }}
                      selected={quotation_data.quo_valid_unit}
                      onChange={async (date) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_valid_unit: date,
                        });
                      }}
                      // selectsEnd
                      startDate={quotation_data.quo_date}
                      minDate={quotation_data.quo_date}
                      customInput={<Custom_Input_date isDis={disable} />}
                    ></DatePicker>
                    <label htmlFor="">
                      Valid Unit
                      {quotation_data.quo_valid_unit === "" ||
                        quotation_data.quo_valid_unit === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-12">
                  <h2>
                    <b>Customer Detail</b>
                  </h2>
                </div>
              </div>
              <hr style={{ marginTop: "0" }}></hr>
              <div className="row">
                <div className="col-12 col-md-4 col-xl-4">
                  <div class="form-group">
                    <select
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_ct_id}
                      onChange={(e) => {
                        const value = e.target.value;
                        const ct_name = customerTypeData.data?.find(el => el.ct_id === value).ct_name
                        setQuotation_data({
                          ...quotation_data,
                          quo_ct_id: value,
                          quo_ct_name: ct_name,
                          quo_confirm_customer: true,
                          quo_cus_id: null,
                          quo_final_discount: 0,
                          quo_final_discount_bath: 0
                        });
                        quotation_data.quo_ct_id = value;
                        quotation_data.quo_ct_name = ct_name;
                        quotation_data.quo_confirm_customer = true;
                        quotation_data.quo_cus_id = null;
                        quotation_data.quo_final_discount = 0;
                        quotation_data.quo_final_discount_bath = 0;
                        clearCustomer();
                      }}
                    >
                      <option value="" disabled>
                        -- กรุณาเลือก --
                      </option>

                      {customerTypeData.data.map((data, index) => {
                        return (
                          <option key={data.ct_id} value={data.ct_id}>
                            {data.ct_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ประเภทลูกค้า
                      {quotation_data.quo_ct_id === "" ||
                        quotation_data.quo_ct_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-5 col-xl-4">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={disable}
                      value={quotation_data.quo_cus_name}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_cus_name: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="">
                      Customer Name
                      {quotation_data.quo_cus_name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <a
                      data-toggle="modal"
                      data-target="#modalQuotationCustomer"
                      style={{ cursor: "pointer" }}
                      hidden={disable}
                      onClick={() => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_cus_id_supply: "",
                          quo_ccp_id: "",
                        });
                        quotation_data.quo_cus_id_supply = "";
                        quotation_data.quo_ccp_id = "";
                      }}
                    >
                      <i class="fas fa-search"></i>
                    </a>
                    {/* <button
                                            hidden={quotation_data.quo_cus_id && quotation_data.quo_ccp_id
                                                ? false : true
                                            }
                                            type="button"
                                            style={{ minWidth: "70px", maxWidth: "70px" }}
                                            className="btn btn-block btn-info"
                                            disabled={disable}
                                            onClick={async () => {
                                                await clearCustomer();
                                            }}
                                        >
                                            Clear
                                        </button> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4>
                    <b> ที่อยู่</b>
                    {/* {true ? <span style={{ color: "red" }}> *</span> : ""} */}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-2 col-xl-1">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_cus_house_number}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_cus_house_number: e.target.value,
                        });
                        quotation_data.quo_cus_house_number = e.target.value;
                      }}
                    />
                    <label>บ้านเลขที่</label>
                  </div>
                </div>

                <div className="col-12 col-md-3 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_cus_building}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_cus_building: e.target.value,
                        });
                        quotation_data.quo_cus_building = e.target.value;
                      }}
                    />
                    <label>อาคาร</label>
                  </div>
                </div>

                <div className="col-12 col-md-2 col-xl-1">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_swine}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_swine: e.target.value,
                        });
                        quotation_data.quo_swine = e.target.value;
                      }}
                    />
                    <label>หมู่</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_soi}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_soi: e.target.value,
                        });
                        quotation_data.quo_soi = e.target.value;
                      }}
                    />
                    <label>ซอย</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_road}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_road: e.target.value,
                        });
                        quotation_data.quo_road = e.target.value;
                      }}
                    />
                    <label>ถนน</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_sub_district}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_sub_district: e.target.value,
                        });
                        quotation_data.quo_sub_district = e.target.value;
                      }}
                    />
                    <label>แขวง / ตำบล</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_district}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_district: e.target.value,
                        });
                        quotation_data.quo_district = e.target.value;
                      }}
                    />
                    <label>เขต / อำเภอ</label>
                  </div>
                </div>

                <div className="col-12 col-md-5 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_province}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_province: e.target.value,
                        });
                        quotation_data.quo_province = e.target.value;
                      }}
                    />
                    <label>จังหวัด</label>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-2">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      decimalScale={0}
                      maxLength={5}
                      allowNegative={false}
                      disabled={disable}
                      className="form-control"
                      required
                      value={quotation_data.quo_postcode}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_postcode: e.target.value,
                        });
                        quotation_data.quo_postcode = e.target.value;
                      }}
                    />
                    <label>รหัสไปรษณีย์</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_contract_name}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_contract_name: e.target.value,
                        });
                        quotation_data.quo_contract_name = e.target.value;
                      }}
                    />
                    <label>
                      Customer Contact Name
                      {quotation_data.quo_contract_name === "" ||
                        quotation_data.quo_contract_name === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_contract_email}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_contract_email: e.target.value,
                        });
                        quotation_data.quo_contract_email = e.target.value;
                      }}
                    />
                    <label>Contact Email</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-5">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      // format={"###-###-####"}
                      required
                      disabled={disable}
                      value={quotation_data.quo_contract_tel}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_contract_tel: e.target.value,
                        });
                        quotation_data.quo_contract_tel = e.target.value;
                      }}
                    />
                    <label>Contact Tel.</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={quotation_data.quo_pt_id}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_pt_id: e.target.value,
                        });
                        quotation_data.quo_pt_id = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        -- กรุณาเลือก --
                      </option>
                      {paymentTermData.data.map((data) => {
                        return (
                          <option value={data.pt_id}>{data.pt_name}</option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Payment Term
                      {quotation_data.quo_pt_id === "" ||
                        quotation_data.quo_pt_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-2">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      allowNegative={false}
                      decimalScale={0}
                      disabled={disable}
                      value={quotation_data.quo_term}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_term: e.target.value,
                        });
                        quotation_data.quo_term = e.target.value;
                      }}
                    />
                    <label>
                      Term
                      {quotation_data.quo_term === "" ||
                        quotation_data.quo_term === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-xl-10">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={2}
                      disabled={disable}
                      style={{
                        height: "100px",
                        fontSize: "25px",
                      }}
                      value={quotation_data.quo_cus_remark}
                      onChange={(e) => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_cus_remark: e.target.value,
                        });
                        quotation_data.quo_cus_remark = e.target.value;
                      }}
                      defaultValue=""
                      required
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>

              <hr className="mt-0"></hr>
              <div className="row">
                <div className="col-12">
                  <h2>
                    <b>Product</b>
                  </h2>
                </div>
              </div>
              <div>
                <Quotation_Cute_Product
                  id={id}
                  mode={mode}
                  setRef_id_qout={setRef_id_qout}
                  ref_id_qout={ref_id_qout}
                  isHistory={isHistory}
                  isDisabled={disable}
                  quotation_data={quotation_data}
                  setQuotation_data={setQuotation_data}
                  inputDataTab2={inputDataTab2}
                  setInputDataTab2={setInputDataTab2}
                  errorListTab2={errorListTab2}
                  setErrorListTab2={setErrorListTab2}
                  functionSaveTab2={functionSaveTab2}
                  setFunctionSaveTab2={setFunctionSaveTab2}
                />
              </div>
              {(mode === "edit" || mode === "view") &&
                (quotation_data.quo_qs_id ===
                  "f4d7da7a-86b6-4757-a96a-61ae63e642de" ||
                  quotation_data.quo_qs_id ===
                  "24af7b0f-4c1f-45c7-9bc6-b6a3c2bcbadb") ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        <u>
                          <b>
                            วันที่อนุมัติ :{" "}
                            {moment(
                              new Date(quotation_data.quo_approve_date)
                            ).format("DD/MM/YYYY")}
                          </b>
                        </u>
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-6 col-sm-6">
                      <label htmlFor="exampleInputFile">
                        Upload Document{" "}
                        {/* <span style={{ color: "red" }}>size(400 x 300) px</span> */}
                        {/* {!Is_Product_Kitting && (product_data.product_image_path === "") ? (
                                        <span style={{ color: "red" }}> *</span>
                                    ) : (
                                        ""
                                    )} */}
                      </label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            style={{ display: "none" }}
                            type="file"
                            className="custom-file-input"
                            id="exampleInputInvoid"
                            accept={allowedTypes}
                            multiple
                            disabled={disable}
                            ref={uploadRef}
                            name="customFile"
                            onChange={(e) => encodeImage(e.target.files)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                            onClick={() => uploadRef.current.click()}
                          >
                            {"Select File"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6 col-sm-6 d-flex align-items-end">
                      <div className="form-group mb-0 pb-0">
                        <button
                          type="button"
                          hidden={mode === "view" ? true : false}
                          className="btn btn-block btn-primary"
                          onClick={async () => {
                            await generateSO_Quotation();
                          }}
                        >
                          Generate SO
                        </button>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <table class="table">
                      <caption>List of users</caption>
                      <thead>
                        <tr>
                          <th scope="col">ลำดับ</th>
                          <th scope="col">เอกสาร</th>
                          <th scope="col">จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotation_upload_files.data?.map((el, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td style={{ textAlign: "center" }}>
                                  <a
                                    className=" btn btn-xs "
                                    style={{
                                      fontSize: "1.2rem",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    href={
                                      el.qou_path_document &&
                                        el.qou_document_name &&
                                        !el.status
                                        ? Configs.API_URL_IMG_cuteplus +
                                        `/static/${el.qou_document_name}/${el.qou_path_document}`
                                        : el.qou_path_document &&
                                          el.qou_document_name &&
                                          el.status
                                          ? el.qou_path_document
                                          : ""
                                    }
                                    download={
                                      el.qou_document_name
                                        ? el.qou_document_name
                                        : ""
                                    }
                                  >
                                    <u>{el.qou_document_name}</u>
                                  </a>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <a
                                    hidden={mode === "view" ? true : false}
                                    className=" btn btn-xs "
                                    onClick={async () => {
                                      el.status = "del";
                                      let temp = quotation_upload_files.data;
                                      temp[index].status = "del";
                                      setQuotation_upload_files({
                                        ...quotation_upload_files,
                                        del: temp,
                                      });
                                      quotation_upload_files.del = temp;
                                      await saveQuotationFiles();
                                    }}
                                  >
                                    <i
                                      class="fas fa-trash-alt"
                                      style={{ color: "red" }}
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
        <div
          className="modal fade"
          id="modalQuotationCustomer"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalfoodRedisterLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              {/* Header */}
              <div className="modal-header">
                <h4 className="modal-title" id="modalfoodRedisterLabel">
                  <b>Customer</b>
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* Body */}
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <h5>
                      <b>Customer</b>
                      <span style={{ color: "red" }}> *</span>
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div class="form-group">
                      <Select
                        type="text"
                        required
                        options={customerDD.data.filter((el, index_el) => el.cus_ct_id === quotation_data.quo_ct_id || !el.cus_ct_id)}
                        filterOption={customFilter}
                        value={
                          customerDD.data?.find(el => el.value === quotation_data.quo_cus_id_supply) || { label: "--Select Customer--", value: "" }
                        }
                        onChange={(e) => {
                          const customerName = e.value
                            ? customerDD?.dataNomap.find(
                              (el) => el.cus_id === e.value
                            ).customerList
                            : [];
                          setQuotation_data({
                            ...quotation_data,
                            quo_cus_id_supply: e.value,
                          });

                          const tempCusName = customerName.map(el => {
                            return {
                              label: el.ccp_name,
                              value: el.ccp_id
                            }
                          })
                          tempCusName.unshift({
                            label: "--Select Customer Name--",
                            value: ""
                          })
                          setCustomerNameDD({
                            ...customerNameDD,
                            data: tempCusName,
                          });
                          customerNameDD.data = tempCusName;
                          quotation_data.quo_cus_id_supply = e.value;
                        }} // customerDD.data
                      >
                        {/* <option key="" value="">
                          Select a Customer
                        </option>
                        {customerDD.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.cus_id}>
                              {item.cus_name}
                            </option>
                          );
                        })} */}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <h5>
                      <b>Customer Name</b>
                      {/* <span style={{ color: "red" }}> *</span> */}
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div class="form-group">
                      <Select
                        type="text"
                        required
                        options={customerNameDD?.data}
                        filterOption={customFilter}
                        isDisabled={
                          (mode !== "view"
                            ? !quotation_data.quo_cus_id_supply
                              ? true
                              : false
                            : true)
                          || customerNameDD.data.length <= 1
                        }
                        value={
                          customerNameDD.data?.find(el => el.value === quotation_data.quo_ccp_id)
                          || { label: "--Select Customer Name--", value: "" }
                        }
                        onChange={(e) => {
                          setQuotation_data({
                            ...quotation_data,
                            quo_ccp_id: e.value,
                          });
                          quotation_data.quo_ccp_id = e.value;
                        }}
                      >
                        {/* <option key="" value="">
                          Select a Customer Name
                        </option>
                        {customerNameDD?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.ccp_id}>
                              {item.ccp_name}
                            </option>
                          );
                        })} */}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              {/* Footer */}
              <div
                className="modal-footer"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  ref={closeModalCustomer}
                >
                  ยกเลิก
                </button>
                {true ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        setQuotation_data({
                          ...quotation_data,
                          quo_confirm_customer: true,
                          quo_cus_id: quotation_data.quo_cus_id_supply
                        });
                        quotation_data.quo_confirm_customer = true;
                        quotation_data.quo_cus_id = quotation_data.quo_cus_id_supply;

                        if (!quotation_data.quo_cus_id) {
                          clearCustomer();
                        } else {
                          /* if (!quotation_data.quo_ccp_id) {
                            Swal.fire(
                              "Error",
                              "กรุณาเลือก Customer name ก่อนทำรายการถัดไป",
                              "error"
                            );
                            return;
                          } */
                          addCustomerDetails();
                        }
                        clickCloseModalCustomer();
                      }}
                    >
                      บันทึก
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quotation_AEV;
