import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

function ManageMessageType(params) {
  const [filterteam, setFilterTeam] = useState({
    file_name: "",
    cat_name: "",
    rc_id: "",
  });
  const [filCategory, setFilterCategory] = useState([]);

  const GetAllCatgory = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterManageMessageType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam, // Sending filterteam as the request body
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          setFilterCategory(response.data.data);
        } else {
          setFilterCategory([]);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  async function ChangeStatus(id, status) {
    let data = {
      ap_id: id,
      ap_status: status,
      company_id: getUser().com,
      oem_id: getOem(),
    }
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/companyRegulations/ChangeStatusManageMessageTypeById",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data:data,
    })
      .then(function (response) {
        // console.log(response);
        GetAllCatgory();
        // Swal.fire({
        //   icon: "success",
        //   text: "เสร็จสิ้น",
        // }).then(() => {
        //   window.location.href =
        //     "/Human_Resource/incentive_hr_admin/team";
        // });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        console.log(status ? false : true);
        document.getElementById(id).checked = status ? false : true;
      });
    // }
    // });
  }

  function deldata(id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "get",
          url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/delManageMessageTypeById?id="+id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data.status === "success") {
              Swal.fire({
                icon: "success",
                text: "เสร็จสิ้น",
                showConfirmButton: false,
                timer: 1500,
            }).then(() => {
                  GetAllCatgory();
                }
              );
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "บางอย่างผิดพลาด: " + error, "error");
          });
      }
    });
  }
  
  function clearFilter() {
    setFilterTeam({
      file_name: "",
      cat_name: "",
      rc_id: "",
    })
    filterteam.file_name = "";
    filterteam.cat_name = "";
    filterteam.rc_id = "";
    // Swal.fire({
    //   title: "กำลังดึงข้อมูล",
    //   text: "Loading",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    GetAllCatgory();

  }

  function addpage(params) {
    window.location.href = "/Human_Resource/company_regulations/Manage_message_types/add";
  }

  function generateDataTable(cat, index) {
    const element = {};
    element["no"] = index + 1;
    element["image"] = (
      <img
        id="img"
        alt="..."
        className="img-fluid rounded shadow border-0"
        src={
          cat.rc_path_image !== "" &&
          cat.rc_path_image !== null &&
          cat.rc_path_image !== undefined
            ? Configs.API_URL_IMG + cat.rc_path_image
            : userdefault_img.imgs
        }
        style={{
          width: "60px",
          height: "60px",
          position: "relative",
          objectFit: "cover",
          display: "block",
          "margin-left": "auto",
          "margin-right": "auto",
        }}
      />
    );
    element["category"] = <div style={{ minWidth: '120px', display: 'flex', justifyContent: 'start' }}>{cat.rc_name || "ไม่พบ"}</div>;
    element["status"] = (
      <div style={{ }}>
        <div class="custom-control custom-switch custom-switch-on-success" style={{textAlign:"center"}}>
        <input
          type="checkbox"
          class="custom-control-input"
          id={"customSwitch" + (index + 1)}
          key={cat.rc_id}
          value={cat.rc_is_active}
          onChange={(e) => {
            ChangeStatus(cat.rc_id, e.target.checked)
            // getdataToggle(this)
          }}
          defaultChecked={cat.rc_is_active}
        ></input>
        <label
          class="custom-control-label"
          for={"customSwitch" + (index + 1)}
        ></label>
      </div>
      </div>
    );
    element["mgt"] = (
      <div className="row align-items-center" style={{ flexWrap: "nowrap", display: 'flex', justifyContent: 'center' }}>
        <div className="col-xl-3 col-md-3 col-xs-3" style={{ textAlign: "center" }}>
          <a
            href={"/Human_Resource/company_regulations/Manage_message_types/view/" + cat.rc_id}
            id="btn_read"
            key={cat.rc_id + "view"}
            className="btn btn-xs "
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3" style={{ textAlign: "center" }}>
          <a
            href={"/Human_Resource/company_regulations/Manage_message_types/edit/" + cat.rc_id}
            id="btn_edit"
            key={cat.rc_id + "edit"}
            className=" btn btn-xs "
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3" style={{ textAlign: "center" }}>
          <a
            key={cat.rc_id + "del"}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={deldata.bind(this, cat.rc_id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    return element;
  }

  const dataCat = [];
  for (let index = 0; index < filCategory.length; index++) {
    dataCat.push(generateDataTable(filCategory[index], index));
  }

  const data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อหมวดหมู่",
        field: "category",
        // sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 5,
      },
      {
        label: <div style={{ textAlign: 'center' }}>Management</div>,
        field: "mgt",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: dataCat,
  };

  useEffect(() => {
    GetAllCatgory();
  }, []);

  return (
    <div className="wrapper" >
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>จัดการประเภทบันทึกข้อความ{"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                  <li className="breadcrumb-item active">จัดการประเภทบันทึกข้อความ</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterteam.cat_name}
                    onChange={(e) => {
                      setFilterTeam({
                        ...filterteam,
                        cat_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อหมวดหมู่</label>{" "}
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetAllCatgory();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                       onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">จัดการประเภทบันทึกข้อความ</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default ManageMessageType;
