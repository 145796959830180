import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import NumberFormat from "react-number-format";

function SN_management(params) {
  const [serial , setserial] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  const [filler_serial, setfiller_serial] = useState({
    sn_number: "",
    oem_id: getOem(),
   company_id: getUser().com,
  });
  const [loop_refresh, setloop_refresh] = useState({
    refresh_time: 30,
  });
  const [loop_time , setloop_time] = useState([]);
  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /* console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 
  const GetSerial = async () => {
    
   var get_filler_serial = {
    sn_number: filler_serial.sn_number.trim(),
    oem_id: getOem(),
    company_id: getUser().com,
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/api/SNManagement/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_serial
    })
      .then(function (response) {
       /*  console.log(response.data); */
        setserial(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 
  const GetLooptime = async () => {
    var loop_refresh_list = []
    var time = loop_refresh.refresh_time
    if ( time !== 1) {
      time = time - 1
    /*   console.log(time,loop_time,loop_refresh_list); */
     loop_refresh.refresh_time = time
      setloop_time(loop_refresh_list)  
    } else {
      GetSerial()
      loop_refresh.refresh_time = 30
     /*  console.log("time"); */
    }

  };


  useEffect(() => {
    GetSerial()
    GetCompany()
   
    setInterval(function(){ 
      GetLooptime()
  }, 1000);

   
  }, []);


  const clearFilter = async () => {
    await setfiller_serial({
      ...filler_serial,
      sn_number:""
     
    });
   
    filler_serial.sn_number = "";
    GetSerial();
   
  };

  const RefreshFilter = async () => {
    await setfiller_serial({
      ...filler_serial,
      sn_number:""
     
    });

    filler_serial.sn_number = "";
    loop_refresh.refresh_time = 30;
    GetSerial();
   
  };

  

  const rowsData = [];
 
  for (var index = 0; index < serial.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["sn_number"] = serial[index].sn_number;
    if ( serial[index].hydro_status === true &&  serial[index].hydro_status != null) {
        rowItem["hydro_status"] = "Pass";
    } else {
        rowItem["hydro_status"] = "Fail";
    };
    rowItem["generate_qr"] = serial[index].generate_qr;
    rowItem["wieght"] = serial[index].weigth;
    if (serial[index].airleak_status === true &&  serial[index].airleak_status != null) {
        rowItem["airleak_status"] = "Pass";
    } else {
        rowItem["airleak_status"] = "Fail";
    };
    rowItem["packing"] = serial[index].packing;
 

    rowItem["mgt"] = (
      <div className="row">
        
        <div className="col-4">
          <a
              /* href={"/Warehouse_Management/Factory_Master_Data/Project/edit/"+ serial[index].id}
             key={serial[index].id} */
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
       
      </div>
    ); 
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Serial No.",
        field: "sn_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "Hydrostatic Test",
        field: "hydro_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Generate QR",
        field: "generate_qr",
        sort: "asc",
        width: 50,
      },
      {
        label: "Weight",
        field: "wieght",
        sort: "asc",
        width: 50,
      },
      {
        label: "Air leak Test",
        field: "airleak_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Packing",
        field: "packing",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Serial Number Management{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">S/N Management</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_serial.sn_number}
                    onChange={(e) => {
                      setfiller_serial({
                        ...filler_serial,
                        sn_number: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Serial No.</label>{" "}
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetSerial();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() =>  RefreshFilter()}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>
     
          <div className="container-fluid">
            <div className="row">
            &nbsp;&nbsp;&nbsp;<h1 className="modal-title"> Refresh Auto in &nbsp;&nbsp;
            {
              <NumberFormat
                style={
                  { backgroundColor: "#F0FFFF", border: "1px solid #ddd", padding: "5px", width: "100" }}
                  
                value={loop_refresh.refresh_time}
                displayType={"text"}
              /*   thousandSeparator={true} */
              />}

            &nbsp;&nbsp; seconds
            </h1>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Serial Number Management(Auto receiver from IOT and manual input)</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " style={{ whiteSpace: 'nowrap' }}
            >
              <MDBDataTable
                sortable = {false}
                disableRetreatAfterSorting = {true}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
   {/*   <Footter/> */}
     </div>
     
  );
}

export default SN_management;
