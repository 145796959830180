import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../../../../../config";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../../Utils/Common";
import Swal from "sweetalert2";
import moment from "moment";
function FDA({
  id,
  mode,
  isDisabled,
  Is_Product_Kitting,
  inputData,
  setInputData,
  errorList,
  setErrorList,
  functionSave,
  setFunctionSave,
}) {
  const closeModalFoodRegister = useRef();
  function closeModalFoodRegisterfunction() {
    closeModalFoodRegister.current.click();
  }
  const closeModalCertificate = useRef();
  function closeModalCertificatefunction() {
    closeModalCertificate.current.click();
  }
  const [test, settest] = useState({
    test: "",
  });
  const [fromSave, setfromSave] = useState({
    fromSave: true,
  });
  const [FDAtype, setFDAtype] = useState({
    data: []
  });
  const [FoodType, setFoodType] = useState([]);
  const [dataFDAforSave, setdataFDAforSave] = useState({
    FDA_id: "",
    FDA_type_list: [],
    FDA_type_id: "",
    FDA_number: "",
    FDA_name_th: "",
    FDA_name_eng: "",
    Foodtype_id: "",
  });
  let [FDA_data_table, setFDA_data_table] = useState([]);
  const [modal_FDA, setModal_FDA] = useState({
    no_food: "",
    name_th: "",
    name_eng: "",
    type_food: null,
    mode_modal: "add"
  });
  let [certificate_data_table, setcertificate_data_table] = useState([]);
  let [certificate_data_table_temp, setcertificate_data_table_temp] = useState(
    []
  );
  let [certificate_data_table_modal, setcertificate_data_table_modal] =
    useState([]);
  const [foodRegisterUpload, setfoodRegisterUpload] = useState({
    real_file: null,
    file: null,
    file_name: "",
    remark: "",
  });
  const [p_fda_upload_files, setP_fda_upload_files] = useState({
    data: [],
    new: [],
    old: [],
    del: [],
  });
  const [dataFoodRegister, setdataFoodRegister] = useState([]);
  const allowedTypes = [
    "image/png",
    "image/jpeg",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  async function saveProductAdvanceFiles() {
    const findCheckTrue = p_fda_upload_files.data.filter((item) => {
      return item.status !== "del"
    });

    const addNew = p_fda_upload_files.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const delOld = p_fda_upload_files.del.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setP_fda_upload_files({
      ...p_fda_upload_files,
      data: findCheckTrue,
      new: addNew,
      del: delOld,
    });

    p_fda_upload_files.data = findCheckTrue;
    p_fda_upload_files.new = addNew;
    p_fda_upload_files.del = delOld;

    // console.log(
    //   p_fda_upload_files.data,
    //   p_fda_upload_files.new,
    //   p_fda_upload_files.del
    // );
  }

  async function getFDAType() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getFDAType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then((res) => {
        // console.log('fda type----', res.data);
        settest({ ...test, test: '' });
        test.test = '';
        const arrList = [];
        for (let item of res.data) {
          arrList.push(item);
        }
        setFDAtype({
          ...FDAtype,
          data: arrList
        });
        FDAtype.data = arrList

        settest({ ...test, test: '' });
        test.test = '';
        // console.log('setFDAtype(res.data)', FDAtype);

      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function getFoodType() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getFoodType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then((res) => {
        // console.log(res.data);
        setFoodType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function getCertificate() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getCertificate",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then((res) => {
        // console.log(res.data);
        let temp = res.data;
        for (let item of temp) {
          item.check = false;
        }
        setcertificate_data_table_modal(temp);
        certificate_data_table_modal = temp;
        setcertificate_data_table_temp(temp);
        certificate_data_table_temp = temp;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function saveFoodRegister() {
    if (foodRegisterUpload.file === null) {
      Swal.fire({
        icon: "error",
        title: "กรุณาเลือกไฟล์",
        text: "กรุณาเลือกไฟล์",
      });
      return;
    }
    let data = {
      real_file: foodRegisterUpload.real_file,
      file: foodRegisterUpload.file,
      file_name: foodRegisterUpload.file_name,
      remark: foodRegisterUpload.remark,
      oldNew: "new",
      status: "add"
    };
    // console.log(data);
    let temp = structuredClone(dataFoodRegister);
    let check = dataFoodRegister.filter(
      (item) => item.file_name === data.file_name
    );
    if (check.length > 0) {
      Swal.fire({
        icon: "error",
        title: "ชื่อไฟล์ซ้ำ",
        text: "ชื่อไฟล์ซ้ำ",
      });
      return;
    } else {
      temp.push(data);
      setdataFoodRegister(temp);
      setP_fda_upload_files({
        ...p_fda_upload_files,
        data: temp,
        new: temp
      });
      p_fda_upload_files.data = temp;
      p_fda_upload_files.new = temp;
      await saveProductAdvanceFiles();

      settest({ ...test, test: "" });
      test.test = "";
      closeModalFoodRegisterfunction();
    }
  }

  function deleteFoodRegister(index) {
    Swal.fire({
      title: "คุณต้องการลบเอกสารนี้ใช่หรือไม่?",
      text: "คุณต้องการลบเอกสารนี้ใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่ใช่",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let temp = dataFoodRegister;
        temp[index].status = "del";
        setP_fda_upload_files({
          ...p_fda_upload_files,
          data: temp,
          del: temp
        })
        p_fda_upload_files.data = temp;
        p_fda_upload_files.del = temp;
        await saveProductAdvanceFiles();

        temp.splice(index, 1);
        setdataFoodRegister(temp);
        settest({ ...test, test: "" });
        test.test = "";
      }
    });
  }

  function saveCertificate() {
    setfromSave({
      ...fromSave,
      fromSave: false,
    });
    fromSave.fromSave = false;
    settest({ ...test, test: "" });
    test.test = "";
    let temp = structuredClone(certificate_data_table_temp);
    let checkTrue = temp.filter((item) => item.check === true);
    // console.log(checkTrue);
    let table = [];
    if (checkTrue.length > 0) {
      for (let item of checkTrue) {
        // console.log(item);
        let table2 = structuredClone(certificate_data_table);
        if (table2.length > 0) {
          let check = table2.find((item2) => item2.cer_id === item.cer_id);
          if (!check) {
            table.push(item);
          } else {
            table.push(check);
          }
        } else {
          table.push(item);
        }
      }
      setcertificate_data_table([]);
      setcertificate_data_table(table);
      settest({ ...test, test: "" });
      test.test = "";
      closeModalCertificatefunction();
    } else {
      setcertificate_data_table([]);
      closeModalCertificatefunction();
    }
  }

  function cancelSaveToTable() {
    setfromSave({
      ...fromSave,
      fromSave: true,
    });
    fromSave.fromSave = true;
    settest({ ...test, test: "" });
    test.test = "";
    let table = structuredClone(certificate_data_table);
    let temp = structuredClone(certificate_data_table_temp);
    temp = temp.map((el) => {
      el.check = false;
      return el;
    });
    for (let item of table) {
      for (let item2 of temp) {
        if (item.cer_id === item2.cer_id) {
          item.check = true;
          item2.check = true;
        }
      }
    }

    setcertificate_data_table_modal([]);
    setcertificate_data_table_modal(temp);
    setcertificate_data_table_temp([]);
    setcertificate_data_table_temp(temp);
    setcertificate_data_table([]);
    setcertificate_data_table(table);
    settest({ ...test, test: "" });
    test.test = "";
  }
  async function genFilePath(FoodRegister) {
    const tempFileUpload = [];
    // console.log('documentFile', documentFile);
    const temp = new Promise(async (resolve, reject) => {
      try {
        const oleTempFileUpload = FoodRegister;
        if (FoodRegister.length > 0) {
          for (let index = 0; index < FoodRegister.length; index += 1) {
            if (FoodRegister[index].ul_id) {
              tempFileUpload.push(FoodRegister[index]);
              continue;
            }
            const data = new FormData();
            data.append("company_id", getUser().com);
            data.append("oem_id", getOem());
            data.append("user_id", getUser().fup);
            data.append(
              "user_name",
              `${getUser().name} ${getUser().surname}`
            );
            data.append("Project", "Cute_Products_Plus");
            data.append("Menu", "Product");
            data.append("Ref", null);
            data.append("Profile", FoodRegister[index].real_file);
            data.append("ProfileName", FoodRegister[index].file_name);

            await axios({
              method: "post",
              url: Configs.API_URL_IMG_cuteplus + "/api/upload/uploadFile",
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
              },
              data: data,
            })
              .then(function (response) {
                if (response.data) {
                  // console.log('response.data.data', response.data.data);
                  FoodRegister[index].file =
                    response.data[0].path;
                  tempFileUpload.push(FoodRegister[index]);
                } else {
                  Swal.fire("Error", "File name !!!", "error");
                  reject(null);
                }
              })
              .catch(function (error) {
                console.log(error);
                reject(null);
              });
          }
        }
        setdataFoodRegister([]);
        setdataFoodRegister(tempFileUpload);
        setP_fda_upload_files({
          ...p_fda_upload_files,
          data: tempFileUpload
        });
        p_fda_upload_files.data = tempFileUpload;
        await saveProductAdvanceFiles();
        settest({ ...test, test: "" });
        test.test = "";
        resolve(FoodRegister);
      } catch (error) {
        console.log("error", error);
        reject(error);
      }
    });

    // console.log('FoodRegister------', temp)
    return temp;
  }
  async function checkErrorFDA(params) {
    const error_list = [];
    if (dataFDAforSave.FDA_type_list.length === 0) {
      let temp_err = {
        message: "กรุณาเลือก ประเภท FDA",
      };
      error_list.push(temp_err);
    }
    /* if (dataFDAforSave.FDA_number.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก เลขสารบบอาหาร",
      };
      error_list.push(temp_err);
    }
    if (dataFDAforSave.FDA_name_th?.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก ชื่อภาษา ไทย",
      };
      error_list.push(temp_err);
    } else {
      if (/^[ก-๙\s]+$/gi.test(dataFDAforSave.FDA_name_th?.trim()) === false) {
        let temp_err = {
          message: "กรุณากรอก ชื่อภาษา ไทยเป็นภาษา ไทยเท่านั้น",
        };
        error_list.push(temp_err);
      }
    }
    if (dataFDAforSave.FDA_name_eng?.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก ชื่อภาษา อังกฤษ",
      };
      error_list.push(temp_err);
    } else {
      if (
        /^[a-zA-Z\s]+$/gi.test(dataFDAforSave.FDA_name_eng?.trim()) === false
      ) {
        let temp_err = {
          message: "กรุณากรอก ชื่อภาษา อังกฤษเป็นภาษา อังกฤษเท่านั้น",
        };
        error_list.push(temp_err);
      }
    }
    if (dataFDAforSave.Foodtype_id === "") {
      let temp_err = {
        message: "กรุณาเลือก ประเภทอาหาร/ฉบับที่",
      };
      error_list.push(temp_err);
    } */
    setErrorList({
      ...errorList,
      data: error_list,
    });
    errorList.data = error_list;
  }

  async function checkErrorModalFDA() {
    const error_list = [];
    let errorStatus = false;
    /* if (/^[ก-๙0-9.\s]+$/gi.test(modal_FDA.name_th?.trim()) === false) {
      let temp_err = {
        message: "กรุณากรอก FDA ชื่อภาษา ไทย เป็นภาษา ไทยเท่านั้น",
      };
      error_list.push(temp_err);
    } */
    /* if(/^[a-zA-Z0-9.\s]+$/gi.test(modal_FDA.name_eng?.trim()) === false) {
      let temp_err = {
        message: "กรุณากรอก FDA ชื่อภาษา อังกฤษ เป็นภาษา อังกฤษเท่านั้น",
      };
      error_list.push(temp_err);
    } */
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
      errorStatus = true;
    }
    return errorStatus
  }

  async function saveOption(params) {
    let error_list = [];
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (dataFoodRegister.length > 0) {
        let temp = await genFilePath(dataFoodRegister);
        // console.log(temp);
        setdataFoodRegister(temp);
        settest({ ...test, test: "" });
        test.test = "";
      }
    }
  }

  async function getFDAforEdit() {
    let data = {
      pc_id: id,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "POST",
      url: Configs.API_URL_cuteplus + "/api/product/getFDAforEdit",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data !== null) {
          if (res.data.FDA_Type) {
            setdataFDAforSave({
              ...dataFDAforSave,
              FDA_type_list: res.data.FDA_Type
            });
            dataFDAforSave.FDA_type_list = res.data.FDA_Type;
            let FDA_Type_temp = [...FDAtype.data]
            // console.log('FDA_Type_temp', FDA_Type_temp);
            FDA_Type_temp = FDA_Type_temp?.filter(el => {
              const check = res.data.FDA_Type?.find(tl => tl.ft_id === el.ft_id);
              // console.log('check', check);
              if (!check) {
                return el;
              }
            })
            // console.log('FDA_Type_temp', FDA_Type_temp);
            setFDAtype({ ...FDAtype, data: FDA_Type_temp });
            FDAtype.data = FDA_Type_temp
          }
          if (res.data.FDA_Details) {
            setFDA_data_table(res.data.FDA_Details);
            // console.log('FDA_data_table', FDA_data_table);
          }
          if (res.data.FDA_Certificate.length > 0) {
            // console.log(res.data.FDA_Certificate.length);
            for (let item of res.data.FDA_Certificate) {
              let temp = certificate_data_table;
              item.check = true;
              temp.push(item);
              setcertificate_data_table(temp);
              for (let item2 of certificate_data_table_modal) {
                if (item2.cer_id === item.cer_id) {
                  item2.check = true;
                }
              }
              settest({ ...test, test: "" });
              test.test = "";
            }
          }
          if (res.data.FDA_FoodRegister.length > 0) {
            for (let item of res.data.FDA_FoodRegister) {
              let temp = dataFoodRegister;
              let data = {
                file_name: item.pd_file_name,
                file: item.pd_file_path,
                date: item.pd_created_date,
                remark: item.pd_remark,
              };
              temp.push(data);
              setdataFoodRegister(temp);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // async function DownloadFile(index, file_name, file_path) {
  //     await axios({
  //         method: "get",
  //         url: Configs.API_URL_IMG_cuteplus + file_path,
  //         headers: {
  //             Authorization: "Bearer " + getToken(),
  //             "X-TTT": Configs.API_TTT,
  //             "Content-Type": "application/json",
  //         },
  //         responseType: "blob",
  //     }).then(async (response) => {
  //         const type = response.headers["content-type"];
  //         const blob = new Blob([response.data]);
  //         const href = URL.createObjectURL(blob);
  //         const link = document.createElement("a");
  //         link.href = href;
  //         link.setAttribute("download", file_name); //or any other extension
  //         document.body.appendChild(link);
  //         link.click();

  //         // clean up "a" element & remove ObjectURL
  //         document.body.removeChild(link);
  //         URL.revokeObjectURL(href);
  //     });
  // }

  const getFilesFDA = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileGroupAll/" + id + "/files_product_fda",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData1,
    })
      .then(async (response) => {
        // console.log(response.data);
        if (response.data.length > 0) {
          let temp = dataFoodRegister;
          for (let item of response.data) {
            let data = {
              ul_id: item.ul_id,
              file_name: item.ul_file_name,
              file: item.ul_path_file,
              date: item.ul_created_date,
              remark: item.ul_remark,
              oldNew: "old",
            };
            temp.push(data);
          }
          setdataFoodRegister(temp);
          setP_fda_upload_files({
            ...p_fda_upload_files,
            data: temp
          });
          p_fda_upload_files.data = temp;
          await saveProductAdvanceFiles();
          settest({ ...test, test: "" });
          test.test = "";
        }
      })
      .catch(async (error) => {
        console.log('error', error);
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถอัปโหลดเอกสารได้",
        });
      });
  }

  useEffect(async () => {
    await getFDAType();
    await getFoodType();
    await getCertificate();
    if (mode === "edit" || mode === "view") {
      await getFDAforEdit();
      await getFilesFDA();
    }
  }, []);
  useEffect(async () => {
    setInputData({
      ...inputData,
      data: {
        dataFDAforSave: dataFDAforSave,
        certificate_data_table: certificate_data_table,
        dataFoodRegister: dataFoodRegister,
        FDA_data_muti: FDA_data_table,
        p_fda_upload_files: p_fda_upload_files
      },
    });
    setFunctionSave({
      ...functionSave,
      data: checkErrorFDA,
      data2: saveOption,
    });
  }, [dataFDAforSave, certificate_data_table, dataFoodRegister, FDA_data_table, p_fda_upload_files]);

  const CertificateRow = [];
  for (let index = 0; index < certificate_data_table.length; index++) {
    const rowItem = [];
    rowItem["certificate_name"] = certificate_data_table[index].cer_name;
    rowItem["certificate_expire"] =
      certificate_data_table[index].cer_exp_date === null
        ? "-"
        : moment(certificate_data_table[index].cer_exp_date).format(
          "DD/MM/YYYY"
        );
    rowItem["certificate_download"] = (
      <a
        href={
          certificate_data_table[index].cer_file_name && certificate_data_table[index].cer_file_path ? Configs.API_URL_IMG_cuteplus +
            `/static/${certificate_data_table[index].cer_file_name}/${certificate_data_table[index].cer_file_path}` : ""
        }
        download={
          certificate_data_table[index].cer_file_name === null
            ? ""
            : certificate_data_table[index].file_name
        }
      >
        <button
          className="btn btn-block btn-primary"
        // onClick={async() => {
        //     await DownloadFile(index, certificate_data_table[index].file_name, certificate_data_table[index].file_path)
        // }}
        >
          ดาวน์โหลด
        </button>
      </a>
    );

    rowItem["certificate_manage"] =
      isDisabled === false ? (
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            <a
              onClick={() => {
                let temp = certificate_data_table;
                let check = certificate_data_table_modal.findIndex(
                  (item) => item.cer_id === temp[index].cer_id
                );
                let arrCer_modal = certificate_data_table_modal;
                if (check >= 0) {
                  arrCer_modal[check].check = false;
                  setcertificate_data_table_modal(arrCer_modal);
                  setcertificate_data_table_temp(arrCer_modal);
                }
                temp.splice(index, 1);
                settest({ ...test, test: "" });
                test.test = "";
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ) : (
        ""
      );
    if (certificate_data_table[index].check === true) {
      CertificateRow.push(rowItem);
    }
  }
  const CertificateColumn = {
    columns: [
      {
        label: "ชื่อ Certificate",
        field: "certificate_name",
        // sort: "asc",
        width: 150,
      },
      {
        label: "วันหมดอายุ",
        field: "certificate_expire",
        // sort: "asc",
        width: 150,
      },
      {
        label: "ดาวน์โหลด",
        field: "certificate_download",
        // sort: "asc",
        width: 150,
      },
      {
        label: "การจัดการ",
        field: "certificate_manage",
        // sort: "asc",
        width: 150,
      },
    ],
    rows: CertificateRow,
  };
  let colIndex = CertificateColumn.columns.findIndex(
    (item) => item.field === "certificate_download"
  );
  if (mode !== "view") {
    CertificateColumn.columns.splice(colIndex, 1);
  }
  const FoodRegisterRow = [];
  for (let index = 0; index < dataFoodRegister.length; index++) {
    const rowItem = {};
    rowItem["FoodRegister_name"] = dataFoodRegister[index].file_name;
    rowItem["FoodRegister_update"] = dataFoodRegister[index].date
      ? moment(dataFoodRegister[index].date).format("DD/MM/YYYY")
      : moment(new Date()).format("DD/MM/YYYY");
    rowItem["FoodRegister_remark"] = dataFoodRegister[index].remark;
    rowItem["FoodRegister_download"] = (
      <a
        href={
          Configs.API_URL_IMG_cuteplus +
          `/static/${dataFoodRegister[index].file_name}/${dataFoodRegister[index].file}`
        }
        download={
          dataFoodRegister[index].file_name === null
            ? ""
            : dataFoodRegister[index].file_name
        }
      >
        <button className="btn btn-block btn-primary">ดาวน์โหลด</button>
      </a>
    );
    rowItem["FoodRegister_manage"] =
      isDisabled === false ? (
        <div className="row">
          <div className="col-12" style={{ textAlign: "center" }}>
            <a value={index} onClick={deleteFoodRegister.bind(this, index)}>
              <i className="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ) : (
        ""
      );
    FoodRegisterRow.push(rowItem);
  }
  const FoodRegisterColumn = {
    columns: [
      {
        label: "เอกสาร",
        field: "FoodRegister_name",
        // sort: "asc",
        width: 150,
      },
      {
        label: "วันที่อัพเดต",
        field: "FoodRegister_update",
        // sort: "asc",
        width: 150,
      },
      {
        label: "หมายเหตุ",
        field: "FoodRegister_remark",
        // sort: "asc",
        width: 150,
      },
      {
        label: "ดาวน์โหลด",
        field: "FoodRegister_download",
        // sort: "asc",
        width: 150,
      },
      {
        label: "การจัดการ",
        field: "FoodRegister_manage",
        // sort: "asc",
        width: 150,
      },
    ],
    rows: FoodRegisterRow,
  };
  let colIndex2 = FoodRegisterColumn.columns.findIndex(
    (item) => item.field === "FoodRegister_download"
  );
  if (mode !== "view") {
    FoodRegisterColumn.columns.splice(colIndex2, 1);
  }
  const CertificateModalRow = [];
  for (let index = 0; index < certificate_data_table_modal.length; index++) {
    const rowItem = {};
    rowItem["certificateModal_name"] =
      certificate_data_table_modal[index].cer_name;
    rowItem["certificateModal_expire"] =
      certificate_data_table_modal[index].cer_exp_date === null
        ? ""
        : moment(certificate_data_table_modal[index].cer_exp_date).format(
          "DD/MM/YYYY"
        );
    rowItem["certificateModal_select"] =
      certificate_data_table_modal[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            // onClick={selectCertificate.bind(this, certificate_data_table_modal[index])}
            onClick={() => {
              let temp = structuredClone(certificate_data_table_modal);
              let temp2 = structuredClone(certificate_data_table_temp);
              temp[index].check = true;
              temp2[index].check = true;
              setcertificate_data_table_modal(temp);
              setcertificate_data_table_temp(temp2);
              settest({ ...test, test: "" });
              test.test = "";
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            // onClick={deleteCertificate.bind(this, certificate_data_table_modal[index], "modal")}
            onClick={() => {
              let temp = structuredClone(certificate_data_table_modal);
              let temp2 = structuredClone(certificate_data_table_temp);
              temp[index].check = false;
              temp2[index].check = false;
              setcertificate_data_table_modal(temp);
              setcertificate_data_table_temp(temp2);
              settest({ ...test, test: "" });
              test.test = "";
            }}
          >
            ยกเลิก
          </button>
        </div>
      );

    CertificateModalRow.push(rowItem);
  }
  const CertificateModalColumn = {
    columns: [
      {
        label: "ชื่อ Certificate",
        field: "certificateModal_name",
        // sort: "asc",
        width: 150,
      },
      {
        label: "วันหมดอายุ",
        field: "certificateModal_expire",
        // sort: "asc",
        width: 150,
      },
      {
        label: "การดำเนินการ",
        field: "certificateModal_select",
        // sort: "asc",
        width: 150,
      },
    ],
    rows: CertificateModalRow,
  };
  const [modal, setModal] = useState(false);
  const [indexModal, setIndexModal] = useState({
    index: null
  });
  const toggle = () => setModal(!modal);

  const rowsData = [];
  for (let index = 0; index < FDA_data_table.length; index += 1) {
    const rowItem = {};

    rowItem["no_food"] = FDA_data_table[index].no_food || "-";

    rowItem["name_th"] = FDA_data_table[index].name_th || "-";

    rowItem["name_eng"] = FDA_data_table[index].name_eng || "-";

    rowItem["type_food"] = FoodType?.find(el => el.food_type_id === FDA_data_table[index].type_food).food_type_name || "-";

    rowItem["management"] =
      mode !== "view" ? (
        <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              key={FDA_data_table[index].id}
              id={FDA_data_table[index].id}
              className="btn btn-sm"
              onClick={() => {
                setModal_FDA({
                  no_food: FDA_data_table[index].no_food,
                  name_th: FDA_data_table[index].name_th,
                  name_eng: FDA_data_table[index].name_eng,
                  type_food: FDA_data_table[index].type_food,
                  mode_modal: "view"
                });
                toggle();
              }}
              style={{ cursor: "pointer" }}
            >
              <i class="fas fa-eye"></i>
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              key={FDA_data_table[index].id}
              id={FDA_data_table[index].id}
              className="btn btn-sm"
              onClick={() => {
                setModal_FDA({
                  no_food: FDA_data_table[index].no_food,
                  name_th: FDA_data_table[index].name_th,
                  name_eng: FDA_data_table[index].name_eng,
                  type_food: FDA_data_table[index].type_food,
                  mode_modal: "edit"
                });
                setIndexModal({ index: index });
                indexModal.index = index;
                toggle();
              }}
              style={{ cursor: "pointer" }}
            >
              <i class="fas fa-pencil-alt"></i>
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              key={FDA_data_table[index].id}
              id={FDA_data_table[index].id}
              className="btn btn-sm"
              onClick={() => {
                Swal.fire({
                  title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
                  text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "ตกลง",
                  cancelButtonText: `ไม่`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    const temp_FDA_data_table = FDA_data_table;
                    temp_FDA_data_table.splice(index, 1);
                    setFDA_data_table([...temp_FDA_data_table]);
                  }
                })
              }}
              style={{ cursor: "pointer" }}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ) : (
        <></>
      );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "เลขสารบบอาหาร",
        field: "no_food",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อไทย",
        field: "name_th",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่ออังกฤษ",
        field: "name_eng",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทอาหาร",
        field: "type_food",
        // sort: "asc",
        width: 50,
      },
      {
        label: "การจัดการ",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* <h4>
                        <b>ประเภท FDA</b><span style={{ color: "red" }}> *</span>
                    </h4> */}
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <select
              className="form-control"
              required
              disabled={isDisabled}
              value={""}
              onChange={(e) => {
                const selectFDA = FDAtype.data.find(el => el.ft_id === e.target.value);
                setdataFDAforSave({
                  ...dataFDAforSave,
                  FDA_type_id: e.target.value,
                  FDA_type_list: selectFDA ? [...dataFDAforSave.FDA_type_list, selectFDA] : [...dataFDAforSave.FDA_type_list]
                });
                dataFDAforSave.FDA_type_id = e.target.value;
                dataFDAforSave.FDA_type_list = selectFDA ? [...dataFDAforSave.FDA_type_list, selectFDA] : [...dataFDAforSave.FDA_type_list];
                const FDA_list = FDAtype.data.filter(el => el.ft_id !== e.target.value);
                setFDAtype({ ...FDAtype, data: FDA_list });
                FDAtype.data = FDA_list;
              }}
            >
              <option value="">
                เลือกประเภท FDA
              </option>
              {FDAtype.data.map((item, index) => {
                return (
                  <option key={index} value={item.ft_id}>
                    {item.ft_name}
                  </option>
                );
              })}
            </select>
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              ประเภท FDA
              {!Is_Product_Kitting && (dataFDAforSave.FDA_type_list.length === 0 ||
                dataFDAforSave.FDA_type_id === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
        {
          dataFDAforSave.FDA_type_list?.map((el, index) => {
            return (
              <div className="col-md-2" key={el.ft_id}>
                <div className="form-group">
                  <input
                    style={{ position: 'relative' }}
                    type="text"
                    className="form-control"
                    required
                    disabled={true}
                    value={el.ft_name}
                  />
                  <a style={{
                    position: "absolute",
                    top: "11px",
                    paddingBottom: "0px",
                    backgroundColor: "#e9ecef",
                    right: "1px",
                    cursor: "pointer",
                    borderRadius: "3px",
                    padding: ".4rem .3rem 0px .3rem"
                  }}
                    hidden={isDisabled}
                    onClick={() => {
                      const FDA_type_listClone = dataFDAforSave.FDA_type_list;
                      const FDA_find = FDA_type_listClone.find(fl => fl.ft_id === el.ft_id);
                      FDA_type_listClone.splice(index, 1);
                      setdataFDAforSave({
                        ...dataFDAforSave,
                        FDA_type_list: FDA_type_listClone
                      });

                      const FDAtypeClone = FDAtype.data;
                      FDAtypeClone.push(FDA_find);
                      setFDAtype({ ...FDAtype, data: FDAtypeClone });
                      FDAtype.data = FDAtypeClone;
                    }}
                  >
                    <i class="fa fa-times-circle" style={{ color: '#ff6161' }} aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            );
          })
        }
      </div >
      <div className="row">
        <div className="col-12">
          <label
            style={{
              fontSize: "24px",
              lineHeight: "normal",
              wordBreak: "break-all",
            }}
          >
            FDA
            {!Is_Product_Kitting && FDA_data_table.length === 0 ? (
              <span style={{ color: "red" }}> *</span>
            ) : (
              ""
            )}
            {/* {tabData.Barcode === "" ||
              tabData.Barcode === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
          </label>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6 col-md-3 col-xl-2">
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDisabled}
            onClick={() => {
              toggle();
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className="table-responsive " style={{ whiteSpace: "nowrap" }}>
        <MDBDataTable
          sortable={false}
          className="table table-head-fixed"
          striped
          bordered
          hover
          fixedHeader
          data={data}
          disableRetreatAfterSorting={true}
        />
      </div>

      <Modal
        isOpen={modal}
        toggle={() => {
          setModal_FDA({
            no_food: "",
            name_th: "",
            name_eng: "",
            type_food: null,
            mode_modal: "add"
          });

          toggle();
        }}
        size="md"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModal_FDA({
              no_food: "",
              name_th: "",
              name_eng: "",
              type_food: null,
              mode_modal: "add"
            });

            toggle();
          }}
        >
          <label
            style={{
              fontSize: "40px",
              fontWeight: "700",
              lineHeight: "normal",
              color: "black",
              margin: "0px",
            }}
          >
            {modal_FDA.mode_modal === "add" ? "Add FDA" : modal_FDA.mode_modal === "edit" ? "Edit FDA" : "View FDA"}
          </label>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={0}
                  allowNegative={false}
                  disabled={modal_FDA.mode_modal === "view" ? true : false}
                  value={modal_FDA.no_food}
                  onChange={(e) => {
                    setModal_FDA({
                      ...modal_FDA,
                      no_food: e.target.value,
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  เลขสารบบอาหาร
                  {modal_FDA.no_food?.trim() === "" && modal_FDA.no_food !== null ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : ('')
                  }
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={modal_FDA.mode_modal === "view" ? true : false}
                  value={modal_FDA.name_th}
                  onChange={(e) => {
                    setModal_FDA({
                      ...modal_FDA,
                      name_th: e.target.value,
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ชื่อภาษาไทย
                  {modal_FDA.name_th?.trim() === "" && modal_FDA.name_th !== null ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : ('')
                  }
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={modal_FDA.mode_modal === "view" ? true : false}
                  value={modal_FDA.name_eng}
                  onChange={(e) => {
                    setModal_FDA({
                      ...modal_FDA,
                      name_eng: e.target.value,
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ชื่อภาษาอังกฤษ
                  {modal_FDA.name_eng?.trim() === "" && modal_FDA.name_eng !== null ? (
                    <span style={{ color: "red" }}> *</span>
                    ) : ('')
                  }
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <select
                  type="text"
                  className="form-control"
                  disabled={modal_FDA.mode_modal === "view" ? true : false}
                  defaultValue={""}
                  value={modal_FDA.type_food}
                  onChange={(e) => {
                    setModal_FDA({
                      ...modal_FDA,
                      type_food: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled>
                    Select -----------
                  </option>
                  {FoodType.map((item, index) => {
                    return (
                      <option key={index} value={item.food_type_id}>
                        {item.food_type_name}
                      </option>
                    );
                  })}
                </select>
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ประเภทอาหาร/ฉบับที่
                  {modal_FDA.type_food === null && modal_FDA.type_food !== '' ? (
                    <span style={{ color: "red" }}> *</span>
                    ) : ('')
                  }
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <div className="col-auto">
            <button
              type="button"
              class="btn btn-block btn-danger"
              onClick={() => {
                setModal_FDA({
                  no_food: "",
                  name_th: "",
                  name_eng: "",
                  type_food: null,
                  mode_modal: "add"
                });

                toggle();
              }}
            >
              ยกเลิก
            </button>
          </div>

          {(modal_FDA.no_food?.trim() !== "" && modal_FDA.no_food !== null) &&
            (modal_FDA.name_th?.trim() !== "" && modal_FDA.name_th !== null) &&
            (modal_FDA.name_eng?.trim() !== "" && modal_FDA.name_eng !== null) &&
            (modal_FDA.type_food !== null && modal_FDA.type_food !== '') && (modal_FDA.mode_modal !== "view") ? (
            <div className="col-auto">
              <button
                type="button"
                class="btn btn-block btn-success"
                onClick={async () => {
                  let FDA_Modal_List = FDA_data_table;
                  if (await checkErrorModalFDA() === true) {
                    return;
                  }
                  if (modal_FDA.mode_modal === "add") {
                    FDA_Modal_List.push({
                      no_food: modal_FDA.no_food?.trim(),
                      name_th: modal_FDA.name_th?.trim(),
                      name_eng: modal_FDA.name_eng?.trim(),
                      type_food: modal_FDA.type_food,
                    });
                  } else {
                    FDA_Modal_List[indexModal.index] = modal_FDA
                    setIndexModal({ index: null });
                    indexModal.index = null;
                  }
                  // barcodeNewList.push(...tabData.Barcode.New);
                  setFDA_data_table(FDA_Modal_List);
                  setModal_FDA({
                    no_food: "",
                    name_th: "",
                    name_eng: "",
                    type_food: null,
                    mode_modal: "add"
                  });

                  toggle();
                }}
              >
                บันทึก
              </button>
            </div>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Modal>

      {/* <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={dataFDAforSave.FDA_number}
              onChange={(e) => {
                setdataFDAforSave({
                  ...dataFDAforSave,
                  FDA_number: e.target.value,
                });
                dataFDAforSave.FDA_number = e.target.value;
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              เลขสารบบอาหาร
              {!Is_Product_Kitting && (dataFDAforSave.FDA_number === "" ||
              dataFDAforSave.FDA_number === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={dataFDAforSave.FDA_name_th}
              onChange={(e) => {
                setdataFDAforSave({
                  ...dataFDAforSave,
                  FDA_name_th: e.target.value,
                });
                dataFDAforSave.FDA_name_th = e.target.value;
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              ชื่อภาษาไทย
              {!Is_Product_Kitting && (dataFDAforSave.FDA_name_th === "" ||
              dataFDAforSave.FDA_name_th === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={dataFDAforSave.FDA_name_eng}
              onChange={(e) => {
                setdataFDAforSave({
                  ...dataFDAforSave,
                  FDA_name_eng: e.target.value,
                });
                dataFDAforSave.FDA_name_eng = e.target.value;
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              ชื่อภาษาอังกฤษ
              {!Is_Product_Kitting && (dataFDAforSave.FDA_name_eng === "" ||
              dataFDAforSave.FDA_name_eng === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <select
              className="form-control"
              required
              disabled={isDisabled}
              value={dataFDAforSave.Foodtype_id}
              onChange={(e) => {
                setdataFDAforSave({
                  ...dataFDAforSave,
                  Foodtype_id: e.target.value,
                });
                dataFDAforSave.Foodtype_id = e.target.value;
              }}
            >
              <option value="" disabled>
                เลือกประเภทอาหาร
              </option>
              {FoodType.map((item, index) => {
                return (
                  <option key={index} value={item.ft_id}>
                    {item.ft_name}
                  </option>
                );
              })}
            </select>
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              ประเภทอาหาร
              {!Is_Product_Kitting && (dataFDAforSave.Foodtype_id === "" ||
              dataFDAforSave.Foodtype_id === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div> */}

      <div className="row mb-3">
        <div className="col-md-12">
          <h4>
            <b>Certificate</b>
          </h4>
        </div>
        <div className="col-6 col-md-4 col-xl-2">
          <button
            type="button"
            disabled={isDisabled}
            className="btn btn-block btn-primary"
            data-toggle="modal"
            data-target="#modalCertificate"
            onClick={() => {
              setfromSave({
                ...fromSave,
                fromSave: true,
              });
              fromSave.fromSave = true;
            }}
          >
            Add
          </button>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="table-responsive"
            style={{ whiteSpace: "nowrap" }} //, whiteSpace: 'nowrap'
          >
            <MDBDataTable
              sortable={false}
              striped
              bordered
              hover
              data={CertificateColumn}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <h4>
            <b>ใบจดทะเบียนอาหาร/แจ้งรายละเอียดอาหาร</b>
          </h4>
        </div>
        <div className="col-6 col-md-4 col-xl-2">
          <button
            type="button"
            disabled={isDisabled}
            className="btn btn-block btn-primary"
            data-toggle="modal"
            data-target="#modalfoodRedister"
            onClick={() => {
              setfoodRegisterUpload({
                ...foodRegisterUpload,
                file: null,
                file_name: "",
                remark: "",
              });
              foodRegisterUpload.file = null;
              foodRegisterUpload.file_name = "";
              foodRegisterUpload.remark = "";
            }}
          >
            Add
          </button>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <div
            className="table-responsive"
            style={{ whiteSpace: "nowrap" }} //, whiteSpace: 'nowrap'
          >
            <MDBDataTable
              sortable={false}
              striped
              bordered
              hover
              data={FoodRegisterColumn}
            />
          </div>
        </div>
      </div>
      {/* <div className="row mb-3">
                <div className="col-md-12">
                    <button
                        type="button"
                        className="btn btn-block btn-success"
                        onClick={() => {
                            saveOption()
                        }}
                    >
                        บันทึก
                    </button>
                </div>
            </div> */}

      {/* Modal Certificate */}
      <div
        className="modal fade"
        id="modalCertificate"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalCertificateLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{ width: "70%" }}
          role="document"
        >
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title" id="modalCertificateLabel">
                <b>เพิ่มใบ Certificate</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span
                  aria-hidden="true"
                  onClick={() => {
                    if (fromSave.fromSave) {
                      cancelSaveToTable();
                    }
                  }}
                >
                  &times;
                </span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="table-responsive"
                    style={{ height: "450px", whiteSpace: "nowrap" }} //, whiteSpace: 'nowrap'
                  >
                    <MDBDataTable
                      sortable={false}
                      striped
                      bordered
                      hover
                      data={CertificateModalColumn}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div
              className="modal-footer"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                ref={closeModalCertificate}
                onClick={() => {
                  if (fromSave.fromSave) {
                    cancelSaveToTable();
                  }
                }}
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  saveCertificate();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Food Redister */}
      <div
        className="modal fade"
        id="modalfoodRedister"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalfoodRedisterLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title" id="modalfoodRedisterLabel">
                <b>เพิ่มเอกสาร</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-md-12">
                  <h4>
                    <b>File Upload</b>
                    <span style={{ color: "red" }}> *</span>
                  </h4>
                </div>
                <div className="col-md-12">
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        accept={allowedTypes}
                        onClick={(e) => {
                          e.target.value = null;
                          e.target.files = null;
                        }}
                        onChange={(e) => {
                          if (e.target.files[0]) {
                            if (
                              allowedTypes.includes(e.target.files[0]?.type)
                            ) {
                              setfoodRegisterUpload({
                                ...foodRegisterUpload,
                                real_file: e.target.files[0],
                                file: URL.createObjectURL(e.target.files[0]),
                                file_name: e.target.files[0].name,
                              });
                              foodRegisterUpload.real_file = e.target.files[0];
                              foodRegisterUpload.file = e.target.files[0];
                              foodRegisterUpload.file_name =
                                e.target.files[0].name;
                              e.target.files = null;
                              settest({ ...test, test: "" });
                              test.test = "";
                            } else {
                              Swal.fire(
                                "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
                                "กรุณาอัปโหลดประเภทไฟล์ PNG, JPEG, CSV, WORD, EXCEL, PDF",
                                "error"
                              );
                            }
                          }
                        }}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {foodRegisterUpload.file_name !== null &&
                          foodRegisterUpload.file_name !== "" &&
                          foodRegisterUpload.file_name !== undefined
                          ? foodRegisterUpload.file_name
                          : "Select File"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <h4>
                    <b>หมายเหตุ</b>
                  </h4>
                </div>
                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    rows="3"
                    value={foodRegisterUpload.remark}
                    onChange={(e) => {
                      setfoodRegisterUpload({
                        ...foodRegisterUpload,
                        remark: e.target.value,
                      });
                      foodRegisterUpload.remark = e.target.value;
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Footer */}
            <div
              className="modal-footer"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                ref={closeModalFoodRegister}
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => saveFoodRegister()}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FDA;
