import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";

function Get_Global_Config({ mode,tabOn }) {
  const { globalConfigId } = useParams();
  const [initialData, setInitialData] = useState({});
  const [initidenData,setinitidenData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true); 
  const [isFirst, setIsFirst] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true); 
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false); 
  const [editButtonText, setEditButtonText] = useState("แก้ไข"); 
  const [modeconfig, setmodeconfig] = useState("");
  const [gf_id, setGf_id] = useState("");
  const [iden_id,setiden_id] = useState("");
  const [modeindentityconfig, setmodeidentityconfig] = useState("");
  const [global_config_data, setglobal_config_data] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    get_company_name2: "",
    get_address2: "",
    get_tax: "",
  });

  const [identity_data, setidentity_data] = useState({
  company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    get_company_name: "",
    get_address: "",
    get_telephone: "",
    get_name: "",
  });
  

  const [hasUserEditedCompanyName2, setHasUserEditedCompanyName2] =
    useState(false);
  const [hasUserEditedAddress2, setHasUserEditedAddress2] = useState(false);
  const [tabActive, setTabActive] = useState(tabOn || "1");


  async function loadIdentityConfig() {
    try {
      const response = await axios({
        method: "post",
        url: `${Configs.API_URL_hrMagenatement}/api/globalConfig/viewIdentityConfig`,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
        },
      });
  
      if (response.data.data.length > 0) {
        setiden_id(response.data.data[0].ic_id);
        setmodeidentityconfig("Edit");
        const data = JSON.parse(response.data.data[0].ic_value);
        setidentity_data({
          get_name: data.get_name,
          get_company_name: data.get_company_name,
          get_telephone: data.get_telephone,
          get_address: data.get_address,
        });
        setinitidenData(data); 
      } else {
        setmodeidentityconfig("Add");
      }
      setIsFirst(true);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "warning",
        html: error.response?.data?.error?.message || "Error occurred",
      });
    }
  }
  
  

  async function loadGlobalConfig() {
    try {
      const response = await axios({
        method: "post",
        url: `${Configs.API_URL_hrMagenatement}/api/globalConfig/viewGlobalConfig`,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: {
          company_id: getUser().com,
          oem_id: getOem(),
        },
      });
  
      if (response.data.data.length > 0) {
        setGf_id(response.data.data[0].emp_gf_id);
        setmodeconfig("Edit");
        const data = JSON.parse(response.data.data[0].emp_gf_value);
        setglobal_config_data({
          get_name: data.get_name,
          get_company_name: data.get_company_name,
          get_telephone: data.get_telephone,
          get_address: data.get_address,
          get_company_name2: data.get_company_name2,
          get_address2: data.get_address2,
          get_tax: data.get_tax,
        });
        setInitialData(data); 
      } else {
        setmodeconfig("Add");
      }
      setIsFirst(true);
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "warning",
        html: error.response?.data?.error?.message || "Error occurred",
      });
    }
  }
  
  async function saveUpdateGlobalConfig() {
    Swal.fire({
      title: "คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (tabActive == 2 ) {
          const error_list = [];
        const trimmedData = {
          get_company_name2: global_config_data.get_company_name2?.trim(),
          get_address2: global_config_data.get_address2?.trim(),
          get_tax: global_config_data.get_tax?.trim(),
        };
        if (!trimmedData.get_company_name2) {
          error_list.push({ message: "กรุณากรอกชื่อบริษัทผู้ออกเอกสารหักภาษี" });
        }
  
        if (!trimmedData.get_address2) {
          error_list.push({ message: "กรุณากรอกที่อยู่ผู้ออกเอกสารหักภาษี" });
        }
  
        if (!trimmedData.get_tax || trimmedData.get_tax.length !== 13) {
          error_list.push({
            message: "กรุณากรอกเลขประจำตัวผู้เสียภาษีอากรให้ครบ 13 หลัก",
          });
        }
  
        if (error_list.length > 0) {
          let err_message = error_list.map((err) => `<br/>${err.message}`).join("");
          Swal.fire("Error", err_message, "error");
        } else {
          let axiosData = {};
          const configValue = JSON.stringify(trimmedData);
          const tempData = {
            emp_gf_value: configValue,
            emp_gf_company: getUser().com,
            emp_gf_oem: getOem(),
            emp_gf_created_by: getUser().fup,
            emp_gf_updated_by: getUser().fup,
            emp_gf_id: gf_id,
          };
  
          if (modeconfig === "Add") {
            axiosData = {
              method: "post",
              url: `${Configs.API_URL_hrMagenatement}/api/globalConfig/saveGlobalConfig`,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: { ...tempData },
            };
          } else if (modeconfig === "Edit") {
            tempData["globalConfigId"] = globalConfigId;
            axiosData = {
              method: "post",
              url: `${Configs.API_URL_hrMagenatement}/api/globalConfig/updateGlobalConfig`,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: { ...tempData },
            };
          }
  
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            try {
              await axios(axiosData);
              Swal.fire({
                icon: "success",
                title: "บันทึกสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location.href = "/accounting/global_config/2";
                setIsDisabled(true);
                setIsSaveDisabled(true); 
                setEditButtonText("แก้ไข"); 
                setIsEditButtonDisabled(false);
              });
            } catch (error) {
              console.log(error);
              Swal.fire({
                icon: "warning",
                html: error.response?.data?.error?.message || "Error occurred",
              });
            }
          });
        }
        }
        else {
          const error_list = [];
          const trimmedData = {
            get_name: identity_data.get_name?.trim(),
            get_company_name: identity_data.get_company_name?.trim(),
            get_telephone: identity_data.get_telephone?.trim(),
            get_address: identity_data.get_address?.trim(),
          };
        if (!trimmedData.get_name) {
          error_list.push({ message: "กรุณากรอกชื่อผู้จัดส่ง" });
        }
  
        if (!trimmedData.get_company_name) {
          error_list.push({ message: "กรุณากรอกชื่อบริษัทผู้จัดส่ง" });
        }
  
        if (!trimmedData.get_telephone) {
          error_list.push({ message: "กรุณากรอกเบอร์โทร" });
        }
  
        if (!trimmedData.get_address) {
          error_list.push({ message: "กรุณากรอกที่อยู่ผู้จัดส่ง" });
        }
        if (error_list.length > 0) {
          let err_message = error_list.map((err) => `<br/>${err.message}`).join("");
          Swal.fire("Error", err_message, "error");
        } else {
          let axiosData = {};
          const configValue = JSON.stringify(trimmedData);
          const tempData = {
            ic_value: configValue,
            ic_company: getUser().com,
            ic_oem: getOem(),
            ic_created_by: getUser().fup,
            ic_updated_by: getUser().fup,
            ic_id: iden_id,
          };
        
          if (modeindentityconfig === "Add") {
            axiosData = {
              method: "post",
              url: `${Configs.API_URL_hrMagenatement}/api/globalConfig/saveIdentityConfig`,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: { ...tempData },
            };
          } else if (modeindentityconfig === "Edit") {
            tempData["ic_id"] = iden_id; 
            axiosData = {
              method: "post",
              url: `${Configs.API_URL_hrMagenatement}/api/globalConfig/updateIdentityConfig`,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: { ...tempData },
            };
          }
        
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            try {
              await axios(axiosData);
              Swal.fire({
                icon: "success",
                title: "บันทึกสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                window.location.href = "/accounting/global_config";
                setIsDisabled(true);
                setIsSaveDisabled(true); 
                setEditButtonText("แก้ไข"); 
                setIsEditButtonDisabled(false);
              });
            } catch (error) {
              console.log(error);
              Swal.fire({
                icon: "warning",
                html: error.response?.data?.error?.message || "Error occurred",
              });
            }
          });
        }
        
        }
      } else if (result.isDismissed) {
        setglobal_config_data(initialData);
        setidentity_data(initidenData);
      }
    });
  }
  
  const handleEditOrCancel = () => {
    if (editButtonText === "แก้ไข") {
      setIsDisabled(false); 
      setIsSaveDisabled(false); 
      setEditButtonText("ยกเลิกการแก้ไข"); 
    } else {
      setglobal_config_data(initialData); 
      setidentity_data(initidenData);
      setIsDisabled(true);
      setIsSaveDisabled(true); 
      setEditButtonText("แก้ไข"); 
    }
  };

  useEffect(() => {
    setTabActive(tabOn || "1")
    loadGlobalConfig();
    loadIdentityConfig();
  }, [mode, globalConfigId]);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{mode} Global Config</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">
                    {mode} Global Config
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-6 col-md-3 col-xl-2">
                <button
                  type="button"
                  onClick={handleEditOrCancel} 
                  className={`btn btn-block btn-${editButtonText === "แก้ไข" ? "primary" : "danger"}`}
                >
                  {editButtonText}
                </button>
              </div>
              <div className="col-6 col-md-3 col-xl-2">
                <button
                  type="button"
                  onClick={saveUpdateGlobalConfig}
                  className="btn btn-block btn-success"
                  disabled={isSaveDisabled} 
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${tabActive === "1" ? "active" : ""}`}
                    onClick={() => setTabActive("1")}
                    role="tab"
                    aria-selected={tabActive === "1"}
                    style={{ cursor: "pointer" }} 
                  >
                    รายละเอียดผู้ส่งเอกสาร
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${tabActive === "2" ? "active" : ""}`}
                    onClick={() => setTabActive("2")}
                    role="tab"
                    aria-selected={tabActive === "2"}
                    style={{ cursor: "pointer" }} 
                  >
                    รายละเอียดผู้ออกเอกสารหักภาษี ณ ที่จ่าย
                  </a>
                </li>
              </ul>
            </div>

            <div className="card-body">
              <div className="tab-content">
                {tabActive === "1" && (
                  <div className="tab-pane fade show active">
                    <h3>รายละเอียดผู้ส่งเอกสาร</h3>
                    <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                    <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="get_name"
                      value={identity_data.get_name}
                      disabled={isDisabled}
                      onChange={(e) =>
                        setidentity_data({
                          ...identity_data,
                          get_name: e.target.value,
                        })
                      }
                      style={{ padding: "0px 10px" }}
                    />
                    <label>
                      ชื่อผู้จัดส่ง<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                    <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="get_company_name"
                      value={identity_data.get_company_name}
                      disabled={isDisabled}
                      onChange={(e) =>
                        setidentity_data({
                          ...identity_data,
                          get_company_name: e.target.value,
                        })
                      }
                      style={{ padding: "0px 10px" }}
                    />
                    <label>
                      ชื่อบริษัท<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="get_telephone"
                      value={identity_data.get_telephone}
                      disabled={isDisabled}
                      onChange={(e) => {
                        let input = e.target.value.replace(/\D/g, "");
                        if (input.length <= 10) {
                          if (input.length > 6) {
                            input = `${input.slice(0, 3)}-${input.slice(
                              3,
                              6
                            )}-${input.slice(6)}`;
                          } else if (input.length > 3) {
                            input = `${input.slice(0, 3)}-${input.slice(3)}`;
                          }
                          setidentity_data({
                            ...identity_data,
                            get_telephone: input,
                          });
                        }
                      }}
                      style={{ padding: "0px 10px" }}
                    />
                    <label>
                      เบอร์โทร<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      required
                      id="get_address"
                      value={identity_data.get_address}
                      disabled={isDisabled}
                      onChange={(e) =>
                        setidentity_data({
                          ...identity_data,
                          get_address: e.target.value,
                        })
                      }
                      style={{ padding: "0px 10px", height: "200px", fontSize: "25px" }}
                    />
                    <label>
                      ที่อยู่<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  </div>
                  </div>
                  </div>
                )}
                {tabActive === "2" && (
                  <div className="tab-pane fade show active">
                    <h3>รายละเอียดผู้ออกเอกสารหักภาษี ณ ที่จ่าย</h3>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-4">
                      <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="get_company_name2"
                      value={global_config_data.get_company_name2}
                      disabled={isDisabled}
                      onChange={(e) =>
                        setglobal_config_data({
                          ...global_config_data,
                          get_company_name2: e.target.value,
                        })
                      }
                      style={{ padding: "0px 10px" }}
                    />
                    <label>
                      ชื่อบริษัท<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="get_tax"
                      value={global_config_data.get_tax}
                      disabled={isDisabled}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (/^\d{0,13}$/.test(input)) {
                          setglobal_config_data({
                            ...global_config_data,
                            get_tax: input,
                          });
                        }
                      }}
                      style={{ padding: "0px 10px" }}
                    />
                    <label>
                      เลขประจำตัวผู้เสียภาษีอากร(13หลัก)
                      <span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      required
                      id="get_address2"
                      value={global_config_data.get_address2}
                      disabled={isDisabled}
                      onChange={(e) =>
                        setglobal_config_data({
                          ...global_config_data,
                          get_address2: e.target.value,
                        })
                      }
                      style={{ padding: "0px 10px", height: "200px", fontSize: "25px" }}
                    />
                    <label>
                      ที่อยู่<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Get_Global_Config;
