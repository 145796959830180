import React, { useEffect, useRef, useState } from "react";
import Bar_Chart from "../Accounting_Dashboard/Chart/Bar_Chart";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";

const MONTH_DATA = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
function ComponentDashboard1(props) {

  const [costTypeAllInfo, setCostTypeAllInfo] = useState({ fc: [], cp: [], cos: [] });
  const [costAllInfo, setCostAllInfo] = useState({ data: [] });
  // const [costSumTotal, setCostSumTotal] = useState({ total: 0 });

  const data_chart_cost_all_info = {
    labels: MONTH_DATA, // The two months being compared
    datasets: /* costMonthVsMonthAgo.data.map(el => el)  */
    [
      {
        label: '',
        backgroundColor: '#007BFF',
        data: costAllInfo.data.map(el => el) || [20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000], // Data for each month
        borderRadius: 10,
        barThickness: 35
      }
    ],
  };

  const data_chart_cost_type_all_info = {
    labels: MONTH_DATA, // The two months being compared
    datasets: /* costMonthVsMonthAgo.data.map(el => el)  */
    [
      {
        label: 'Fix Cost',
        backgroundColor: ['red'],
        data: costTypeAllInfo.fc.map(el => el) || [20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000], // Data for each month
        borderRadius: 10,
        barThickness: 10
      },
      {
        label: 'Cost Project',
        backgroundColor: ['#FFA800'],
        data: costTypeAllInfo.cp.map(el => el) || [20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000], // Data for each month
        borderRadius: 10,
        barThickness: 10
      },
      {
        label: 'Cost out sourcing',
        backgroundColor: ['#007BFF'],
        data: costTypeAllInfo.cos.map(el => el) || [20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000, 20000, 25000], // Data for each month
        borderRadius: 10,
        barThickness: 10
      }
    ],
  };

  const useContainerDimensions = (myRef, key) => {
    const [dimensions, setDimensions] = useState({ ['cardWidth' + (key || '')]: 0, ['cardHeight' + (key || '')]: 0 })
  
    useEffect(() => {
      const getDimensions = () => ({
        ['cardWidth' + (key || '')]: myRef.current.offsetWidth,
        ['cardHeight' + (key || '')]: myRef.current.offsetHeight
      })
  
      const handleResize = () => {
        setDimensions(getDimensions())
      }
  
      if (myRef.current) {
        setDimensions(getDimensions())
      }
  
      window.addEventListener("resize", handleResize)
  
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [myRef])
  
    return dimensions;
  };

  const declarationRef = useRef();
  const { cardWidth, cardHeight } = useContainerDimensions(declarationRef);

  const declaration2Ref = useRef();
  const { cardWidth2, cardHeight2 } = useContainerDimensions(declarationRef, '2');

  async function getCostAllInfo(start_date, end_date) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/getCostAllInfo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date, 
        end_date
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data; // || response.data.data.sort((a, b) => b.cost_sum - a.cost_sum)
          const data_result = [];
          for (let item of MONTH_DATA) {
            const _find = data.find(el => el.month == item);
            if (_find) data_result.push(Number(_find.cost_sum || 0));
            else data_result.push(0);
          }
          setCostAllInfo({
            data: data_result
          });
          costAllInfo.data = data_result;
        } else {
          setCostAllInfo({
            data: []
          });
          costAllInfo.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getCostTypeAllInfo(start_date, end_date) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/getCostTypeAllInfo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date, 
        end_date
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          // const data = response.data.data.map(el => {
          //   el.log_list.sort((a, b) => new Date(b.rl_date_filed) - new Date(a.rl_date_filed))
          //   return el;
          // })
          const data = response.data.data; // || response.data.data.sort((a, b) => b.cost_sum - a.cost_sum)
          const data_result_fc = [], data_result_cp = [], data_result_cos = [];
          for (let item of MONTH_DATA) {
            let result = {
              fc: 0,
              cp: 0,
              cos: 0
            }
            const _filter = data.filter(el => el.month == item);

            if (_filter.length > 0) {
              for (let item2 of _filter) {
                result[item2.type_data] = Number(item2.cost_sum || 0);
              }
            }

            data_result_fc.push(result.fc);
            data_result_cp.push(result.cp);
            data_result_cos.push(result.cos);
          }
          setCostTypeAllInfo({
            fc: data_result_fc,
            cp: data_result_cp,
            cos: data_result_cos
          });
          costTypeAllInfo.fc = data_result_fc;
          costTypeAllInfo.cp = data_result_cp;
          costTypeAllInfo.cos = data_result_cos;
        } else {
          setCostTypeAllInfo({
            fc: [],
            cp: [],
            cos: []
          });
          costTypeAllInfo.fc = [];
          costTypeAllInfo.cp = [];
          costTypeAllInfo.cos = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getSumCostTotal(start_date, end_date) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/getSumCostTotal",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date, 
        end_date
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data) && response.data.data.length > 0) {
          // const data = response.data.data.map(el => {
          //   el.log_list.sort((a, b) => new Date(b.rl_date_filed) - new Date(a.rl_date_filed))
          //   return el;
          // })
          props.setCostSumTotal({
            total: Number(response.data.data[0].cost_sum || 0)
          });
          props.costSumTotal.total = Number(response.data.data[0].cost_sum || 0);
        } else {
          props.setCostSumTotal({
            total: 0
          });
          props.costSumTotal.total = 0;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  function GetAllDashboard(start_date, end_date) {
    setCostTypeAllInfo({ fc: [], cp: [], cos: [] });
    costTypeAllInfo.fc = [];
    costTypeAllInfo.cp = [];
    costTypeAllInfo.cos = [];
    setCostAllInfo({ data: [] })
    costAllInfo.data = [];
    props.setCostSumTotal({ total: 0 })
    props.costSumTotal.total = 0;
    // getMostCostTypeAcc(start_date, end_date);
    // getCostMonthVsMonthAgo();
    
    getCostTypeAllInfo(start_date, end_date);
    getCostAllInfo(start_date, end_date);
    getSumCostTotal(start_date, end_date);
  }

  useEffect(() => {
    if (props.functionComponenet && props.setFunctionComponenet) {
      props.setFunctionComponenet({
        ...props.functionComponenet,
        getDataChart1: GetAllDashboard
      });
      props.functionComponenet.getDataChart1 = GetAllDashboard;
    }
  }, [])

  return (
    <section className="content">
      <div className="row">
        <div className="col-12 col-xl-6 col-md-12">

        </div>
        <div className="col-12 col-xl-6 col-md-12"></div>
      </div>
      <div className="row">
      <div className="col-12 col-xl-6 col-md-12">
          <div ref={declarationRef} className="card" style={{ height: "100%" }}>
            <div className="card-body p-2">
              <div>
                <span style={{ fontWeight: "600", fontSize: "22px" }}>
                แสดงข้อมูลค่าใช้จ่ายรวม{" "}
                </span>
              </div>
              {/* <div style={{ height: '320px'}}>
                <BarChart key="bar1" data={bar1} barRef={bar1Ref} chartData={data_chart_status} />
              </div> */}
              <div
                style={{
                  overflow: "auto",
                  height: "90%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Bar_Chart
                  Text={""}
                  isHideLegend={true}
                  DataChart={data_chart_cost_all_info}
                  func={() => {}}
                  pos={1}
                  // minWidth={"800px"}
                  // width={`${
                  //   data_chart_declaration_performance_cb.labels.length * 100 <
                  //   cardWidth
                  //     ? "100%"
                  //     : `calc(${
                  //         data_chart_declaration_performance_cb.labels.length *
                  //         100
                  //       }px + 100%)`
                  // }`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 col-md-12">
          <div ref={declaration2Ref} className="card" style={{ height: "100%" }}>
            <div className="card-body p-2">
              <div>
                <span style={{ fontWeight: "600", fontSize: "22px" }}>
                แสดงข้อมูลค่าใช้จ่ายตามประเภท {" "}
                </span>
              </div>
              {/* <div style={{ height: '320px'}}>
                <BarChart key="bar1" data={bar1} barRef={bar1Ref} chartData={data_chart_status} />
              </div> */}
              <div
                style={{
                  overflow: "auto",
                  height: "90%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Bar_Chart
                  Text={""}
                  legendPosition={'start'}
                  isNotStacks={'false'}
                  DataChart={data_chart_cost_type_all_info}
                  func={() => {}}
                  pos={1}
                  // minWidth={"800px"}
                  // width={`${
                  //   data_chart_declaration_performance_cb.labels.length * 100 <
                  //   cardWidth2
                  //     ? "100%"
                  //     : `calc(${
                  //         data_chart_declaration_performance_cb.labels.length *
                  //         100
                  //       }px + 100%)`
                  // }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ComponentDashboard1;
