import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import { nullFormat } from "numeral";

function Warehouse_add_rack_floor(mode) {
 

  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(false);
  const  {id} = useParams();
  const  {parent_id} = useParams();
  const  {old_parent_id} = useParams();
  const [filter_warehouse_type, setFilter_warehouse_type] = useState({
    type: "",
    company_id: getUser().com,
  });
  const [warehouse_type, setwarehouse_type] = useState([]);
  const [location_temp, setLocation_temp] = useState({
    warehouse:"",
    rack:"",
  });

  const [warehouse, setWarehouse] = useState([]);
  const [filter_warehouse, setFilter_warehouse] = useState({
    id: "",
    code_no: "",
    company_id:getUser().com,
    parent_id:"",
    type:"Warehouse",

 });
 const [rack_t, setRack_t] = useState({
    id: "",
    code_no: "",
    company_id:getUser().com,
    parent_id:"",
 });

 const [rack, setRack] = useState([]);
 const [filter_rack, setFilter_rack] = useState({
  id: "",
  code_no: "",
  company_id:getUser().com,
  parent_id: old_parent_id,
  type:"Rack",

 });

 const [rack_floor, setRack_floor] = useState({
  id: "",
  code_no: "",
  company_id:getUser().com,
  description:"",
  parent_id:"",
  location: "",
  capacity: "",
  warehouse_type_id:"7b18f9a8-cd76-4d48-b43a-59de75e43d13",

 });

 const [filter_product, setfilter_product] = useState({
  no: "",
  name: "",
  product_model_id: "",
  oem_id: getOem(),
});
const [MDBT,setMDBT] =useState(true);
 const [product,setProduct] = useState([]);
const [product_select,setProduct_select] = useState([]);
const [test, settest] = useState({
  test: "",
});

  

  useEffect(() => {
    if (mode.mode === "view") {
      setdisable(true);
      setPageMode("view");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetProduct = async () => {
   
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product,
    })
      .then(async (response) => {
        console.log(response.data)
        //setProduct([]);
        for(let item of response.data){
          var check = product_select.filter((e)=>{
            return e.id === item.id
          });
          if(check.length > 0){
            item.isPush = true;
          }else{
            item.isPush = false;
          }
      
        }
        console.log(response.data)

         setProduct(response.data);
         setMDBT(true);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const GetWarehouse_type = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse_type/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data:filter_warehouse_type,
      
    
    })
      .then(function (response) {
        //console.log(response.data);
        setwarehouse_type(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }; 

  const GetWarehouse_view = async () => {
 
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/warehouse",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_warehouse
    
    })
      .then(function (response) {
        console.log(response.data);
        setWarehouse(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetRack_view = async () => {
 
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/rack",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_rack
    
    })
      .then(function (response) {
        console.log(response.data);
        setRack(response.data);
      
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetRack_tems_view = async () => {
     
    if ( mode.mode =="view" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/warehouse/findItemWarehouseById?id="+ parent_id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log(response.data);
          setRack_t({
            id: response.data.id,
            code_no: response.data.code_no,
            company_id: getUser().com,
            parent_id: response.data.parent_id,     
          });
        })
        
        .catch(function (error) {
          console.log(error);
        });
    }
    
  } 
  

  
    const GetRack_floor_view = async () => {
     
        if ( mode.mode =="view" || mode.mode =="edit"  ) {
          axios({
            method: "get",
            url: Configs.API_URL + "/warehouse/findItemWarehouseById?id="+ id,
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
          })
            .then(function (response) {
              
              
            
              console.log(response.data);
                setRack_floor({
                  id: response.data.id,
                  code_no: response.data.code_no,
                  company_id: getUser().com,
                  description:response.data.description,
                  parent_id: response.data.parent_id,
                  location: response.data.location,
                  capacity: response.data.capacity,
                  warehouse_type_id: response.data.warehouse_type_id
                      
              });
                 setProduct_select(response.data.product_on_shelf === null ? [] : response.data.product_on_shelf);
                 axios({
                  method: "post",
                  url: Configs.API_URL + "/product/filter",
                  headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                  },
                  data: filter_product,
                })
                  .then(async (response2) => {
                    //console.log(response.data)
                    setProduct([]);
                    for(let item of response2.data){
                      var check = response.data.product_on_shelf.filter((e)=>{
                        return e.product_id === item.id
                      });
                      if(check.length > 0){
                        item.isPush = true;
                      }else{
                        item.isPush = false;
                      }
                  
                    }
                    //console.log(response.data)
            
                     setProduct(response2.data);
                   
                  })
                  .catch(async (error) => {
                    console.log(error);
                  });
           
              
            })
            
            .catch(function (error) {
              console.log(error);
            });
        }
        
      }


useEffect(() => {
  console.log("id: "+id+ " parent_id: "+parent_id);
  GetWarehouse_view();
  GetRack_view();
  GetRack_tems_view();
  GetRack_floor_view();
  GetWarehouse_type();
  GetProduct();

}, []);

function saveOption(params) {
  const error_list = [];
  if(rack_t.parent_id.trim() == ""){
    let temp_err ={
      message:"Please enter information in the fields >>> [Warehouse]."
    }
    error_list.push(temp_err);
  }
  if(rack_floor.parent_id.trim() == ""){
    let temp_err ={
      message:"Please enter information in the fields >>> [Rack]."
    }
    error_list.push(temp_err);
  }
  if(rack_floor.code_no.trim() == ""){
    let temp_err ={
      message:"Please enter information in the fields >>> [Code No]."
    }
    error_list.push(temp_err);
  }
  if(rack_floor.description == ""){
    let temp_err ={
      message:"Please enter information in the fields >>> [Description]."
    }
    error_list.push(temp_err);
  }
  if(rack_floor.location == ""){
    let temp_err ={
      message:"Please enter information in the fields >>> [Location]."
    }
    error_list.push(temp_err);
  }
  if(rack_floor.capacity == ""){
    let temp_err ={
      message:"Please enter information in the fields >>> [Capacity]."
    }
    error_list.push(temp_err);
  }

  if(error_list.length > 0){
    var err_message = "";
    for(var e = 0; e < error_list.length;e++){
      err_message += "<br/>"+error_list[e].message;
    }
    Swal.fire("Error", err_message, "error");
  }else{
    if (mode.mode === "add") {
      const temp ={
      
        company_id: rack_floor.company_id,
        code_no: rack_floor.code_no.trim(),
        description: rack_floor.description,
        location: rack_floor.location,
        capacity: rack_floor.capacity,
        parent_id: rack_floor.parent_id,
        warehouse_type_id: rack_floor.warehouse_type_id,
        product_on_shelf:product_select,
      }
      console.log(temp);
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/warehouse/add_rack_floor",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Warehouse/status/rack_floor";
              });
            }

            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    }
    if (mode.mode === "edit") {

      const temp ={
        warehouse_id: id,
        company_id: rack_floor.company_id,
        code_no: rack_floor.code_no.trim(),
        description: rack_floor.description,
        location: rack_floor.location,
        capacity: rack_floor.capacity,
        parent_id: rack_floor.parent_id,
        product_on_shelf:product_select,
      }
      //console.log(temp);
     
      Swal.fire( {
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/warehouse/update_rack_floor",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Warehouse/status/rack_floor";
              });
            }
  
            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
     }
  }

}

function cancelOption(params) {
  Swal.fire({
    title: "Do you want to cancel and leave the changes?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: `Yes`,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText:`No`,
  }).then((result) => {
    if(result.isConfirmed){
      window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Warehouse/status/rack_floor";
    }
    
  });
}


function pushProduct(e){
  const product_select_temp = product_select;
    var data = product.filter((element)=>{ 
      return (element.id === e.target.id);
    })
    const product_p = product;
    setProduct([]);
    for(let i of product_p){
      if(i.id === e.target.id){
        i.isPush = true;
      }
    }
    setProduct(product_p);
    console.log(product_select_temp);
    var checked = product_select_temp.filter((e2)=>{
      return e2.product_id === data[0].id;
    })
    //console.log(checked)
    if(checked.length > 0){
      console.log("ซ้ำ")
    }else{
      let temp = {
        id:"",
        product_id:data[0].id,
        warehouse_id:"",
        name:data[0].name,
        no:data[0].no,
      };
      product_select_temp.push(temp);
    }
    console.log(product_select_temp);
     setProduct_select([]);
     setProduct_select(product_select_temp);
     settest({...test,test:""});
     test.test = "";
  }

  function removeProduct(e){
    //console.log(e.target.id);
    const product_p = product;
    setProduct([]);
    for(let i of product_p){
      if(i.id === e.target.id){
        i.isPush = false;
      }
    }
    setProduct(product_p);
    const product_select_temp = product_select;
    setProduct_select([]);
    let indexOf = product_select_temp.findIndex((element)=> element.product_id ===  e.target.id) 
    console.log(indexOf);
    product_select_temp.splice(indexOf, 1);
    setProduct_select(product_select_temp);
    settest({...test,test:""});
     test.test = "";
    //console.log(data[0]);    
  }

const Product_data = [];
  for(var index = 0; index < product.length;index++){
    const rowItem = {};

    rowItem["no"] = index +1 ;
    rowItem["id"] = product[index].id;
    rowItem["product_no"] = product[index].no;
    rowItem["product_name"] = product[index].name;
    rowItem["mgt"] = (
      <>
      {product[index].isPush === true ? (
          <>
          <div className="col-12">
                  <button
                    type="button"
                    id = {product[index].id}
                    onClick={removeProduct.bind(this)}
                    class="btn btn-block btn-danger "
                  >
                    Remove
                  </button>
                </div>
        </>
      ):(
        <>
        <div className="col-12">
        <button
          type="button"
          id = {product[index].id}
          onClick={pushProduct.bind(this)}
          class="btn btn-block btn-primary "
        >
          Push
        </button>
      </div>
      </>
      )}
     
      </>
    );
    Product_data.push(rowItem);
  }

  const TableProduct = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
       label: "Product Name",
       field: "product_name",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Product_data,
  };


  const Product_data_select = [];
  if(product_select !== null && product_select.length > 0){
    for(var index = 0; index < product_select.length;index++){
      const rowItem = {};
  
      rowItem["no"] = index +1 ;
      rowItem["id"] = product_select[index].id;
      rowItem["product_no"] = product_select[index].no;
      rowItem["product_name"] = product_select[index].name;
      rowItem["mgt"] = (
        <>
        <div className="col-6">
                    <button
                      type="button"
                      id = {product_select[index].product_id}
                      onClick={removeProduct.bind(this)}
                      class="btn btn-block btn-danger "
                    >
                      Remove
                    </button>
                  </div>
        </>
      );
      Product_data_select.push(rowItem);
    }
  }

 
 
  

  const TableProduct_select = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
       label: "Product Name",
       field: "product_name",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Product_data_select,
  };


  const clearFilter = async () => {
     setfilter_product({
      ...filter_product,
      no: "",
      name: "",
      product_model_id: "",
      oem_id: getOem()
    }); 
    filter_product.no = "";
    filter_product.name = "";
    filter_product.product_model_id = "";
   
    GetProduct();
   
  }; 

function gen_location(warehouse,rack,shelf){
  var location = warehouse+"-"+rack+"-"+shelf;
  console.log(location)
  setRack_floor({...rack_floor,location: location});
}
function gen_location2(){
  console.log("here");
  var warehouse1 = warehouse.filter((e)=>{return e.id === filter_rack.parent_id});
  var rack1 = rack.filter((e)=>{return e.id === rack_floor.parent_id});
  var shelf = rack_floor.code_no;
  /* console.log("warehouse",warehouse1);
  console.log("rack",rack1);
  console.log("shelf",shelf); */
  var location = warehouse1[0].code_no+"-"+rack1[0].code_no+"-"+shelf;
  //console.log(location)
  setRack_floor({...rack_floor,location: location});
}


  return (
    <div className="wrapper">
     
    
      <div className="content-wrapper">
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
              
                
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-process-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-process"
                    role="tab"
                    aria-controls="custom-tabs-four-process"
                    aria-selected="false"
                  >
                 Product on Shelf
                  </a>
                </li>
             
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
               
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-process"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-process-tab"
                >
        <h3 class="title ng-binding">Product on Shelf {pageMode} </h3>
        <div className="tab-custom-content"></div><br></br>
        
                  <div className="container-fluid">

                  <div className="row mb-2">
            <div className="col-md-4">
                <div className="form-group ">
               

                              <select
                    className="custom-select select2 form-control"
                    value={rack_t.parent_id}
                    disabled={mode.mode === "view" ? true : false}
                    onClick={GetRack_view}
                    onChange={(e) => {
                        setFilter_rack({
                            ...filter_rack,
                            parent_id: e.target.value,     
                          });
                          warehouse.map((w) => {
                            if(w.id === e.target.value){
                              setLocation_temp({
                                ...location_temp,
                                warehouse:w.code_no,
                            
                              });
                         
                            }
                            
                          })
                       
                      setRack_t({
                        ...rack_t,
                        parent_id: e.target.value,
                    
                      });
                    
                    }} 
              
                  >
                   <option value="" selected>
                        Select Warehouse
                      </option>
                    {warehouse.map((el) => {
                 
                      return  <option value={el.id} name ={el.code_no} >{el.code_no}</option>;
                    })} 
                  </select>
                  {/* <label htmlFor="">Warehouse</label> */}
                  <label>
                      Warehouse
                      {rack_t.parent_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                </div>
             
              </div>
             </div>

             <div className="row mb-2">
            <div className="col-md-4">
                <div className="form-group ">
               

                              <select
                    className="custom-select select2 form-control"
                    value={rack_floor.parent_id}
                    disabled={mode.mode === "view" ? true : false}
                
                    onChange={(e) => {
                      console.log(e.target.value)
                      setRack_floor({
                        ...rack_floor,
                        parent_id: e.target.value,
                   
                      });
                  
                      rack.map((r) => {
                        if(r.id === e.target.value){
                          setLocation_temp({
                            ...location_temp,
                            rack:r.code_no,
                        
                          });
                         
                        }
                      })
                      
                    }}
                  >
                   

                   <option value=""  selected>
                        Select Rack
                      </option>
                      
                    {
                    rack.map((el) => {
                  
                      return <option value={el.id} >{el.code_no}</option>;
                    })} 
                  </select>
                
                  <label>
                      Rack
                      {rack_floor.parent_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                </div>
             
              </div>
              <div style={{display:"none"}} >
              <div className="col-md-4">
                <div className="form-group ">
             
                  <input
                    type="text"
                    className="form-control"
                    disabled={mode.mode === "view" ? true : false}
                     value = {rack_t.code_no}
                    //disabled={disable}
                      onChange={(e) => {
                        setRack_t({
                          ...rack_t,
                          code_no: e.target.value,
                        });
                   
                      }}     
                  />
                  <label htmlFor="">Rack_temp</label>{" "}
                  
                </div>
              </div>
              </div>

             </div>

           
             <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    required
                      thousandSeparator={true}
                      allowNegative ={false}
                    disabled={mode.mode === "view" ? true : false}
                     value = {rack_floor.code_no}
                    //disabled={disable}
                      onChange={(e) => {
                        setRack_floor({
                          ...rack_floor,
                          code_no: e.target.value,
                        });
                      
                      }}     
                  />
                  <label>
                      Shelf No
                      {rack_floor.code_no === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                </div>
              </div>
              </div>

              <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    required
                      thousandSeparator={true}
                      allowNegative ={false}
                    disabled={mode.mode === "view" ? true : false}
                     value = {rack_floor.description}
                    //disabled={disable}
                      onChange={(e) => {
                        setRack_floor({
                          ...rack_floor,
                          description: e.target.value,
                        });
                   
                      }}     
                  />              
                  <label>
                      Description
                      {rack_floor.description.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                </div>
              </div>
              </div>

              <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    required
                      thousandSeparator={true}
                      allowNegative ={false}
                     disabled={true}
                     value = {rack_floor.location}
                     
                      onChange={(e) => {
                        setRack_floor({
                          ...rack_floor,
                          location: e.target.value,
                        });
                   
                      }}     
                  />
                  <label>
                      Location
                      {rack_floor.location === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                     
                  
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
              <button
                          type="button"
                          class="btn btn-block btn-info "
                          //consoledisabled={mode.mode === "read" ? true : false}
                         
                         onClick={gen_location2}
                        >
                          Genarate Location
                        </button>
                        </div>
              </div>
              </div>

              <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
              
                  <input
                      /* type="number" */
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    className="form-control"
                    required
                      thousandSeparator={true}
                      allowNegative ={false}
                    //disabled={true}
                    value = {rack_floor.capacity}
                    disabled={mode.mode === "view" ? true : false}
                      onChange={(e) => {
                        setRack_floor({
                          ...rack_floor,
                          capacity: e.target.value,
                        });
                   
                      }}     
                  />
                  <label>
                      Capacity
                      {rack_floor.capacity === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                </div>
              </div>
              </div>

              <div className="row mb-2"style = {{display:"none"}}>
              <div className="col-md-4">
                <div className="form-group ">
              
                <select className="form-control "
                      type="text"
                      //className="form-control"
                      required
                      value={rack_floor.warehouse_type_id}
                      disabled={true}
                      onChange={(e) => {
                        setRack_floor({
                          ...rack_floor,
                          warehouse_type_id: e.target.value,
                        });
                      }}
                
                    >
                    <option value="" disabled selected>
                      Select Warehouse Type
                    </option>
                     {warehouse_type.map((el) => {
                      return <option value={el.id}>{el.type}</option>;
                    })}
                    </select>
                  <label htmlFor="">Warehouse Type</label>{" "}
                </div>
              </div>
              </div>


              <div className="col-12">
                  <h3 className="mt-5 mt-5-head">
                    Product on Shelf
                  </h3>
                  <div className="tab-custom-content">
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="">Product Select</label>
                      <div className = "row">
                      <div className="col-2">
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={mode.mode === "read" ? true : false}
                          data-toggle="modal"
                          data-target="#modal-product"
                        // onClick={testassy}
                        >
                          Select Product
                        </button>
                      </div>
                   
                     
                    
                      </div>


                    </div>

                  </div>
                  <br />

                







                  <div className="row">

                    <div className="col-12">


                      <div className="table-responsive" style={{ height: "600px" }} >
                        <MDBDataTable
                        sortable = {false}
                          className="table table-head-fixed"
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={TableProduct_select}
                        />
                      </div>
                    </div>

                    </div>
                  </div>
                  <br />
              
            
              {mode.mode === "view" ? (
              <div className="row mb-2">
              <div className="col-sm-1">
                <button
                  type="button"
                  onClick={cancelOption}
                  class="btn btn-block btn-danger "
                >
                  Cancel
                </button>
              </div>
            </div>
            
              ) : (     
                <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button" 
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    
                      onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
              )}
              </div>
              

                 
              
              <div className="modal fade" id="modal-product">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Product</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput">


                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">

                            <div className="col-12">
                              <div className="row">
                              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.no}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        no: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Product No</label>{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.name}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        name: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                                    disabled={mode.mode === "read" ? true : false}
                                    onClick={() =>{
                                      setMDBT(false);
                                      GetProduct();
                                      
                                      }}
                                  >
                                    Search
                                  </button>
                                </div>
                                </div>

                                <div className="col-md-3">
                            <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                                    disabled={mode.mode === "read" ? true : false}
                                    onClick={()=>{
                                      setMDBT(false);
                                      clearFilter();
                                      
                                      
                                    }
                                     }
                                  >
                                    Clear
                                  </button>
                                </div>
                                </div>
                               




                              </div>
                              <br />

                    

                              <div
                                className="table-responsive"
                                style={{ height: "500px" }}
                              >
                                <MDBDataTable
                                sortable = {false}
                                  className="table table-head-fixed"
                                  //paging={false}
                                  disableRetreatAfterSorting={MDBT}
                                  
                                  onPageChange={(page)=>{
                                    console.log(page)
                                  }}
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={TableProduct}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h3>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
               

                
              
               

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  );
}
export default Warehouse_add_rack_floor;
