import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,  getFeature} from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import INDF from "../../component/input_date_feature";
import QR_stage from "../../assets/file/TTT_track_inout.pdf";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

function Handheld() {
    const [STEP1,setSTEP1] = useState({
        process_Select:"",
        style:"",
    });
    const [modal, setModal] = useState(false);
    const toggle_modal = () => setModal(!modal);
    const [STEP1_scan,setSTEP1_scan] = useState({
      stage:"",
   
  });

  const [return_detail,setreturn_detail] = useState([]);
    const [tracking,settracking] = useState({
        tag_no:"",
        lot_no:"",
        exp:new Date(),
        oem_id: getOem(),
        
    });
    const [status, setstatus] = useState(false);
    const [STEP2,setSTEP2] = useState([]);
    const [disPreStep3, setdisPreStep3] = useState(true);
    const [reCheck, setreCheck] = useState(false);
    const [disEditLot, setdisEditLot] = useState(true);
    const [disSTEP1, setdisSTEP1] = useState(false);
    const [feature, setfeature] = useState([]);
    const [remark, setremark] = useState({
      remark:"",
    });



  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element)=>{ 
      return (element.menu === "Handheld");
      
    })
    setfeature(feature_data);
    document.getElementById("STEP1").focus();
  }, []);

  //console.log(feature)
  function set_step1(process,style){
    setSTEP1({...STEP1,process_Select:process,style:style});
  }


 function genLotNo(){
  axios({
    method: "get",
    url: Configs.API_URL + "/handheld/genLotNo?oem_id=" + getOem(),
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    /* data: tracking, */
  })
    .then(function (response) {
      settracking({...tracking,lot_no:response.data});
      tracking.lot_no = response.data;
      //console.log(response.data)
    }).catch(function(e){ 
      console.log(e);
    })
 }
 if(STEP1_scan.stage.length === 12){
      console.log(STEP1_scan.stage);
    if(STEP1_scan.stage === "TTTProcess01"){
      console.log("Cutting In");
      /* Swal.fire(
        'Message',
        'Your scan has been set stage.',
        'success'
      ) */
     set_step1("Cutting IN","btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if(STEP1_scan.stage === "TTTProcess02"){
      console.log("Cutting Out");
    /*   Swal.fire(
        'Message',
        'Your scan has been set stage.',
        'success'
      ) */
      set_step1("Cutting OUT","btn btn-block btn-outline-danger");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if(STEP1_scan.stage === "TTTProcess03"){
      console.log("WIP In");
      /* Swal.fire(
        'Message',
        'Your scan has been set stage.',
        'success'
      ) */
      set_step1("WIP IN","btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if(STEP1_scan.stage === "TTTProcess04"){
      console.log("WIP Out");
      /* Swal.fire(
        'Message',
        'Your scan has been set stage.',
        'success'
      ) */
      set_step1("WIP OUT","btn btn-block btn-outline-danger");
      setdisSTEP1(true);
     // setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if(STEP1_scan.stage === "TTTProcess05"){
      console.log("F/G In");
     /*  Swal.fire(
        'Message',
        'Your scan has been set stage.',
        'success'
      ) */
      set_step1("F/G IN","btn btn-block btn-outline-success");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }
    else if(STEP1_scan.stage === "TTTProcess06"){
      console.log("F/G Out");
      /* Swal.fire(
        'Message',
        'Your scan has been set stage.',
        'success'
      ) */
      set_step1("F/G OUT","btn btn-block btn-outline-danger");
      setdisSTEP1(true);
      //setdisPreStep3(false);
      document.getElementById("STEP1").blur();
      document.getElementById("STEP2").focus();
    }else{
      Swal.fire(
        'Error',
        'Not found.',
        'error'
      )
    }
    setSTEP1_scan({...setSTEP1_scan,stage:""});
    setSTEP1_scan.stage = "";
 }
 
 if(STEP2.length > 0 && STEP1.process_Select !== "" && status === false){
  setdisPreStep3(false);
  setstatus(true);

 }

  if(tracking.tag_no.length === 12){
    //console.log(tracking);
    axios({
        method: "post",
        url: Configs.API_URL + "/handheld/findTrackNo",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tracking,
      })
        .then(function (response) {
            settracking({...tracking,tag_no:""});
            tracking.tag_no ="";
            console.log(response.data)
            var tag_no = response.data.tag_no;
            var check = STEP2.filter((e)=>{
                return e.tag_no === tag_no;
            });
            
            if(check.length > 0){
                Swal.fire(
                    'Message',
                    'Already have this information.',
                    'info'
                  )

            }else{
              if(STEP1.process_Select === "Cutting IN"){
                var tempData = STEP2;
                setSTEP2([]);
              
                var amount = response.data.amount;
              if(amount< 0){
               /*  console.log("here") */
                amount =parseInt(amount) * -1;
              }
            var temp = {
                tag_no:tag_no,
                amount:amount,
         
            }
            tempData.push(temp);
          
       
        
        console.log(tempData);
    
        setSTEP2(tempData);
      
              Swal.fire(
                'Message',
                'Your tag_no has been set amount.',
                'success'
              )
              }else{
                Swal.fire({
                  title: 'Input Amount',
                  
                  input: 'number',
            inputAttributes: {
              autocapitalize: 'off'
            },
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, input it!'
                }).then((result) => {
                  
                    
                  if (result.isConfirmed) {
              
                      var tempData = STEP2;
                      setSTEP2([]);
                    
                      var amount = result.value;
                    if(result.value < 0){
                     /*  console.log("here") */
                      amount =parseInt(result.value) * -1;
                    }
                  var temp = {
                      tag_no:tag_no,
                      amount:amount,
               
                  }
                  tempData.push(temp);
                
             
              
              console.log(tempData);
          
              setSTEP2(tempData);
            
                    Swal.fire(
                      'Message',
                      'Your tag_no has been set amount.',
                      'success'
                    )
                  }
              })
              }
             
            }
            
          

          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
          settracking({...tracking,tag_no:""});
          tracking.tag_no ="";
        });








    
    
  }
 


  function amount_update(e){
    var tempData = STEP2;

    tempData[e.target.id].amount = e.target.value;

    setSTEP2(tempData);
   
  }

  function remove_tracking(e){
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
            var tempData = STEP2;
            setSTEP2([]);
            tempData.splice(e.target.id, 1);
              if(tempData.length < 1){
                setstatus(false);
                setdisPreStep3(true);
              }
            setSTEP2(tempData);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }
      })
  }

  function saveOption() {
    var temp;
    if(STEP1.process_Select === "F/G IN"/*  || STEP1.process_Select === "F/G OUT"  */){
       temp ={
          type:STEP1.process_Select,
          list_track: STEP2,
          lot_no: tracking.lot_no,
          exp: moment(tracking.exp).add(1,'years'),
          oem_id: getOem(),
          company_id: getUser().com,
          remark:remark.remark
        } 
    }else{
       temp ={
          type:STEP1.process_Select,
          list_track: STEP2,
          oem_id: getOem(),
          company_id: getUser().com,
          remark:remark.remark
        }
    }
      
      
        axios({
          method: "post",
          url: Configs.API_URL + "/handheld/save_form_handheld",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
  /*           if(response.data === true){ */
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                setreturn_detail(response.data);
                toggle_modal();
                console.log(response.data)
              /*   window.location.href =
                "/Warehouse_Management/Handheld"; */
              });
              setSTEP2([]);
              setreCheck(false);
              setdisSTEP1(false); 
              setSTEP1({...STEP1,process_Select:"",style:""});
              STEP1.process_Select = "";
              STEP1.style = "";
              setremark({...remark,remark:""});
              remark.remark = "";
           /*  }else{

              var str ="";
              for(let e of response.data){
                str += e.tag_no +" amount "+e.new_amount+"<br/>";
              }
              Swal.fire(
                'Message',
                'check your amount<br>'+str,
                'error'
              )
            } */
             
          }).catch(function (error) {
            Swal.fire(
              'Error',
              'has error some one..',
              'error'
            )
            console.log(error);
          });
        

    
 

}

  function save_Option(){
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, save it!'
      }).then((result) => {
        if (result.isConfirmed) {
            saveOption();
          
           
        
           /*  Swal.fire(
                'Save!',
                'Your item has been save.',
                'success'
              ) */
        
         
        }
      })
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  async function Setexp(date) {
    settracking({ ...tracking, exp: date });
  }




  //console.log("STEP3",reCheck);
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Handheld</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Handheld</li>
    
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="row">
              <div className="col-6">
              <h3>Handheld</h3>
              </div> 
              <div className="col-6">
                  <div class="form-group">
                  <a href={QR_stage} download="TTT_track_inout.pdf" style={{ fontSize: "" }}>
                  <button
                    type="button"
                    //onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                    value = "Cutting OUT"
                    class="btn btn-block btn-primary "
                  >
                    Dowload QR Stage
                  </button>
                  </a>
                  </div>
                </div> 

              </div>
              
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3>
                  STEP 1
                  </h3>
                  <div className="row">
            <div className="col-10">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      id="STEP1"
                      required
                      value={STEP1_scan.stage.trim()}
                      disabled = {disSTEP1}
                      onChange={(e) => {
                        setSTEP1_scan({
                          ...setSTEP1_scan,
                          stage: e.target.value.trim(),
                        }); 
                      }}
                    />
                    <label>
                    SCAN STAGE
                    </label>
                  </div>
                </div>
                <div className="col-2">
                  <div class="form-group">
                <button class ="btn btn-block btn-primary "
                    onClick={()=>{
                        if(disSTEP1 === true){
                            setdisSTEP1(false);
                            setdisPreStep3(true);
                            setstatus(false);
                            setSTEP1({...STEP1,process_Select:""});
                        STEP1.process_Select = "";
                
                            
                       
                        }
                        
                        
                    }}
                    >
                        <i class="fas fa-pencil-alt"></i>
                        </button>

                </div>
                </div>
                </div>
    
             {/*  <div className="row">
              <div className="col-6">
                  <div class="form-group">
                  <button
                    type="button"
                    onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                    value = "Cutting IN"
                    class="btn btn-block btn-outline-success "
                    
                  >
                    Cutting IN
                  </button>
                  </div>
                </div>

                <div className="col-6">
                  <div class="form-group">
                  <button
                    type="button"
                    onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                    value = "Cutting OUT"
                    class="btn btn-block btn-outline-danger "
                  >
                    Cutting OUT
                  </button>
                  </div>
                </div>
                     
              </div>

              <div className="row">
              <div className="col-6">
                  <div class="form-group">
                  <button
                    type="button"
                    onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                    value = "WIP IN"
                    class="btn btn-block btn-outline-success "
                  >
                    WIP IN
                  </button>
                  </div>
                </div>

                <div className="col-6">
                  <div class="form-group">
                  <button
                    type="button"
                    onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                    value = "WIP OUT"
                    class="btn btn-block btn-outline-danger "
                  >
                    WIP OUT
                  </button>
                  </div>
                </div>
                     
              </div>

              <div className="row">
              <div className="col-6">
                  <div class="form-group">
                  <button
                    type="button"
                    onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                    value = "F/G IN"
                    class="btn btn-block btn-outline-success "
                  >
                    F/G IN
                  </button>
                  </div>
                </div>

                <div className="col-6">
                  <div class="form-group">
                  <button
                    type="button"
                    onClick={(e)=>{set_step1(e.target.value,e.target.className)}}
                    value = "F/G OUT"
                    class="btn btn-block btn-outline-danger "
                  >
                    F/G OUT
                  </button>
                  </div>
                </div>
                     
              </div> */}
              <div className="row">
              <div className="col-5">
              <h3>
                Stage Select :
                  
                  </h3>
                  </div>
                  <div className="col-6">
                    {STEP1.process_Select === "" ? (
                    <>
                 
                    </>
                    ):(
                        <>
                    <button
                    type="button"
                    //disabled
                    class= {STEP1.style}
                  >
                    {STEP1.process_Select}
                  </button>
                  </>
                    )}
                  
             
                  </div>
            </div>

            <div className="tab-custom-content"></div>
            <h3>STEP 2</h3>
            <div className="row">
            <div className="col-12">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      id = "STEP2"
                  value={tracking.tag_no.trim()}
               
                      onChange={(e) => {
                        settracking({
                          ...tracking,
                          tag_no: e.target.value.trim(),
                        });
                      }}
                      autofocus
                    />
                    <label>
                    SCAN TRACKING
                    </label>
                  </div>
                </div>

               {/*  <div className="col-10">
        
        <INDF selected = {tracking.exp}
        txt = "EXP Date"
        onChange ={(date) =>
        Setexp(date)}
        customInput={<CustomInput />}
      
      />
   
      </div> */}
                <div className="table-responsive" style={{ whiteSpace: "nowrap" }} >
              
                      <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                {STEP2.map((e,index) =>{
                    return (<>
                    <tr style = {{ border: "1px solid #ddd",backgroundColor:"#F0FFFF", bordercollapse: "collapse"}}>
                      <td style = {{textAlign: "center" }}>{e.tag_no}</td>
                      <td> <NumberFormat
                          allowNegative={false}
                      type="text"
                      style = {{textAlign: "right"}}
                      className="form-control"
                      id = {index}
                      value={e.amount}
                      //disabled={disable}
                      onChange={amount_update.bind(this)}
          
                    /></td>
                      <td> <button
                    type="button"
                    onClick={remove_tracking.bind(this)}
                    value = "F/G OUT"
                    class="btn btn-block btn-danger "
                    style = {{verticalAlign:"center"}}
                  >
                        <i class="fas fa-times"></i>
                            </button></td>
                    </tr>
                   
               {/*     <div className="row">
                      <div className="col-12" style = {{'background-color':"#0069d9",whiteSpace: "nowrap"}}>
                      <div className="row">
                          <div className="col-6">
                          <div className="form-group">
                          <h3>{e.tag_no}</h3>
                          </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group">
                            <NumberFormat
                          allowNegative={false}
                      type="text"
                      style = {{textAlign: "right"}}
                      className="form-control"
                      id = {index}
                      value={e.amount}
                      //disabled={disable}
                      onChange={amount_update.bind(this)}
          
                    />
                            </div>
                         
                     
                          </div>
                          <div className="col-2">
                          <div className="form-group">
                          <button
                    type="button"
                    onClick={remove_tracking.bind(this)}
                    value = "F/G OUT"
                    class="btn btn-block btn-danger "
                    style = {{verticalAlign:"center"}}
                  >
                        <i class="fas fa-times"></i>
                            </button>
                          </div>
                          </div>
                          </div>
                        </div>
                        </div> */}
             


                {/*     <div className="col-12" style = {{Padding:"0px 0px" , Margin:"0px 0px 0px",whiteSpace: "nowrap"}}>
                
                  <button
                    type="button"
           
                    value = "F/G OUT"
                    class="btn btn-block btn-primary "
                  >
                      <div className="row">
                          <div className="col-6">
                          <h3>{e.tag_no}</h3>
                          </div>
                          <div className="col-4">
                          <NumberFormat
                          allowNegative={false}
                      type="text"
                      style = {{textAlign: "right"}}
                      className="form-control"
                      id = {index}
                      value={e.amount}
                      //disabled={disable}
                      onChange={amount_update.bind(this)}
          
                    />
                     
                          </div>
                          <div className="col-2">
                          <button
                    type="button"
                    onClick={remove_tracking.bind(this)}
                    value = "F/G OUT"
                    class="btn btn-block btn-danger "
                    style = {{verticalAlign:"center"}}
                  >
                        <i class="fas fa-times"></i>
                            </button>
                          </div>
                          
                      </div>
                 
                  </button>
          
                </div> */}
                </>
                    );
                })}
                </table>
                </div>

                </div>
             
              
                <div className="tab-custom-content">
                 </div>
                 <br/>
                 <div className ="form-group">
                 <textarea type="text" value = {remark.remark} onChange={(e)=>{setremark({...remark,remark:e.target.value})}} className="form-control" />
                 <label>Remark</label>
                 </div>
                
                 <div className="row">
                 <div className="col-4"></div>
              <div className="col-4">
                  <div class="form-group">
                  <button
                    type="button"
                    //</div>onClick={()=>{setreCheck(false);setdisEditLot(true);genLotNo();}
                    onClick = { save_Option }
             
                  /*   data-toggle = "modal"
                    data-target = "#modal-recheck" */
                    class="btn btn-block btn-success "
                    disabled={disPreStep3}
                  >
                    SAVE
                  </button>
                  </div>
                </div>
                <div className="col-4"></div>
                </div>


                <div className = "modal fade"id = "modal-recheck" >
        <div className = "modal-dialog modal-xl" >
        <div className = "modal-content" >

        <div className = "modal-body" >

        <div className = "row" >

        <div className="col-12"><h3> STEP3 Detail [{STEP1.process_Select}]</h3></div>
            <br/>
      
            <div className="table-responsive" style={{ whiteSpace: "nowrap" }} >
              
              <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                <tr>
          
                  <th>tag no</th>
                  <th>lot_no</th>
                  <th>amount</th>
                  <th>status</th>
                  <th>remark</th>
                </tr>
                {STEP2.map((e,index)=>{
                return(
                    <>
                    <tr>
          
                    <td>{e.tag_no}</td>
                    <td>{e.amount}</td>
                    <td>{e.amount}</td>
                    <td>{e.amount}</td>
                    <td>{e.amount}</td>
                    </tr>
                    </>
                   
                );
            })}
                </table>
                </div>
         

        </div> 
        
        
        </div> 
        
       
        <div className = "modal-footer justify-content-center" >


        <a data-toggle = "modal"
        data-target = "#modal-recheck" >
               
        <button type = "button"
        className = "btn btn-primary"
        //onClick = { set_order }
        data-dismiss = "modal"
        aria-label = "Close" >
         OK
        </button> 
              
        </a> 
        </div> 
        </div> 
        </div> 
        </div>



        <Modal isOpen={modal} toggle={toggle_modal}  className="modal-dialog modal-md">
          
          <ModalHeader toggle={toggle_modal}>

            <h1>รายการ scan tag{/*  [ {STEP1.process_Select} ] */}{/*  ::: [ <font color = "green">สำเร็จ {repairStatus.success}</font> / <font color = "red"> ไม่สำเร็จ {repairStatus.not_success}</font> ]*/}</h1>
          </ModalHeader>
          <ModalBody>
            <div className="row">
           
              <div className="col-12">
                <label>ตารางแสดงสถานะการ scan tag</label>
              </div>
            </div>
  
            <div className="table-responsive" style={{ whiteSpace: "nowrap" }} >
              
              <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                <tr>
          
                  <th style = {{ border: "1px solid #ddd",textAlign: "center" }}>tag no</th>
                  <th style = {{ border: "1px solid #ddd",textAlign: "center"}}>lot_no</th>
                  <th style = {{ border: "1px solid #ddd",textAlign: "center"}}>amount</th>
                  <th style = {{ border: "1px solid #ddd",textAlign: "center"}}>status</th>
                  <th style = {{ border: "1px solid #ddd",textAlign: "center"}}>remark</th>
                </tr>
                {return_detail.map((e,index)=>{
                return(
                    <>
                    <tr>
          
                    <td style = {{ border: "1px solid #ddd",textAlign: "center" }}>{e.tag_no}</td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center" }}>{e.lot_no}</td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "right" }}>{e.amount}</td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center" }}>{e.status === true ? "success" : "fail"}</td>
                    <td style = {{ border: "1px solid #ddd"}}>{e.remark}</td>
                    </tr>
                    </>
                   
                );
            })}
                </table>
                </div>
          </ModalBody>
          <ModalFooter>
            {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle_modal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>







        
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>





  );
}
export default Handheld;
