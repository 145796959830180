import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import sortJsonArray from "sort-json-array";
import Swal from "sweetalert2";
import Footter from "../../../component/Footter";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import { getOem, getToken, getUser, getFeature, getAcademy } from "../../../Utils/Common";
import Configs from "../../../config";
import NumberFormat from "react-number-format";
import QRCode from "qrcode.react";
import moment from "moment";
import { userdefault_img, logoTTT } from "../../../routes/imgRoute/imgUrl";
import INF from "../../../component/input_feature";
import LOGH from "../../../component/log_detailHistory_feature";
import { NUMBER_BINARY_OPERATORS } from "@babel/types";

import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../../assets/font/pdf_font";
import jsPDF from "jspdf";
import QRCode2 from "qrcode";
function DefluxWipInOut(params) {
  const [defluxData, setdefluxData] = useState([]);
  const [Lot_rawmat,setLot_rawmat] = useState([]);
  
  const [Lot_rawmat_full,setLot_rawmat_full] = useState([]);
  const [Lot_rawmat_list,setLot_rawmat_list] = useState([]);
  const [oem_select, setoem_select] = useState({
    oem_id:getOem(),
  });
  const [history, sethistory] = useState({
    id: "",
    material_id: "",
    edit_type: "",
    amount: "",
    create_date: "",
    mat_lot_no: "",
  });
  const [feature, setfeature] = useState([]);
  const [history_Select, sethistory_Select] = useState({
    isSelect_status: false,
  });
  const [historyWIP, setHistoryWIP] = useState([]);
  const [search_cus_end,setsearch_cus_end] = useState({
    result_cus:"",
    result_end:""
  });
  const [customer, setCustomer] = useState([]);
  const [end_user, setEnd_user] = useState([]);
  const [customer_option, setcustomer_option] = useState([]);
  const [End_user_option, setEnd_user_option] = useState([]);
  const [amount, setamount] = useState({
    id: "",
    edit_type: true,
    amount: 1,
    status_sst: "",
    total: "",
    lot_no: "",
    lot_target:[],
    ref_lot_no:"",

 
    customer_id:"",
    end_user_id:"",

    lot_rawMat:[],
    weight_ready_toUse:"",
  });

  const [fillter, setfillter] = useState({
    diameter_no: "",
    diameter: "",
    oem_id: getOem(), 
    company_id: getUser().com,
  
  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });


  const [Tracking_lot, setTracking_lot] = useState([]);

  const GetCustomer = async () => {
    var filter_customer = {
      name: "",
      relate_product: "",
      company_id: getUser().com,
      companyGroup_id: ""
    }
    //console.log(filler_customer);
    axios({
      method: "post",
      url: Configs.API_URL + "/customer/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_customer
    })
      .then(function (response) {
        //console.log(response.data);
        setCustomer(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value1"] = el.id;
          element["value2"] = el.full_name;
          element["label"] = el.name;
          options.push(element);
        });
        setcustomer_option(options);

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetEnd_user = async () => {
    
    let temp = {
      oem_id: getOem(),
    } 
    axios({
      method: "post",
      url: Configs.API_URL + "/end_user/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        //console.log(response.data);
        setEnd_user(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value1"] = el.id;
          element["value2"] = el.fullname;
          element["label"] = el.name;
          options.push(element);
        });
        setEnd_user_option(options);

     
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    
    ////////permission feature//////////
  
    ////////permission feature//////////


    GetAllLotMatSTP();
    
    Getdata();
    GetCustomer();
    GetEnd_user();

  }, []);
  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? '#87CEFA' : '#FFF',
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',

      };
    },

  };
  const GetAllLotMatSTP = async () => {

    let temp = {
      oem_id: getOem(),
    }
    await axios({
      method: "post",
      url: Configs.API_URL + "/DefluxWipInOut/getAllLotMat",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        /*   await setmaterail(response.data); */
        setLot_rawmat_full(response.data);
        const options = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.mat_lot_no;
          options.push(element);
        });
        setLot_rawmat(options);
       // setLot_rawmat(response.data);
      /*   let sorted = response.data.sort((a, b) => b.amount - a.amount);
        await setdefluxData(sorted); */

        console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  

  const Getdata = async () => {
let temp = {
  diameter_no: fillter.diameter_no.trim(),
  diameter: fillter.diameter.trim(),
  oem_id: getOem(), 
  company_id: getUser().com,
}
 
    await axios({
      method: "post",
      url: Configs.API_URL + "/DefluxWipInOut/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        /*   await setmaterail(response.data); */

        let sorted = response.data.sort((a, b) => b.amount - a.amount);
        await setdefluxData(sorted);

        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  const selectMat_type = useRef();
  const selectMat_spec = useRef();
  const selectMat_size = useRef();
  const selectMat_supp = useRef();
  const selectCompanyGroup = useRef(); 

  const clearfillter = async () => {
     setfillter({
      ...fillter,
      diameter_no:"",
      diameter: "",

      oem_id: getOem(),
  
    });
    fillter.diameter_no = "";
    fillter.diameter = "";

    fillter.oem_id = getOem();

    
     
   
   
    
   
    Getdata();
  };

  // console.log(amount);
  const Getamount = async () => {
    //console.log(Lot_rawmat_list)
    
    
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if(result.isConfirmed){
        const error_list = [];
       

        if(  amount.edit_type === false &&
          MultiLot_outBound.length === 0){
          let temp_err ={
            message:"กรุณาเลือก Lot ที่จะทำการ Out bound."
          }
          error_list.push(temp_err);
        }

        if(  amount.edit_type === true &&
          Lot_rawmat_list.length === 0){
          let temp_err ={
            message:"กรุณาเลือก Lot Raw mat."
          }
          error_list.push(temp_err);
        }
        
        if(error_list.length > 0){
          var err_message = "";
          for(var e = 0; e < error_list.length;e++){
            err_message += "<br/>"+error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        }else{
        
       
            //console.log(amount.lot_no);
            axios({
              method: "get",
              url: Configs.API_URL + "/DefluxWipInOut/genLotNo_WIP?oem_id="+oem_select.oem_id,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
       
            })
              .then(function (response) {
                setamount({ ...amount, lot_no: response.data });
                amount.lot_no = response.data;
                //console.log(response.data)
            
                var temp_amount ={
                  id:amount.id,
                  lot_no:amount.lot_no,
                  lot_no_target:MultiLot_outBound || [],
                  edit_type: amount.edit_type,
                  amount:amount.amount,
                  lot_rawMat:Lot_rawmat_list,
                  weight_ready_toUse:amount.weight_ready_toUse,
                  outLot_rawMat:lot_out_list,
                  status_sst: amount.status_sst,
                  customer: amount.customer || null,
                  end_user: amount.end_user || null,
                  company_id: getUser().com,
                  oem_id: getOem(),
                  
                }
                console.log(temp_amount);
        
           // console.log(temp_amount);
    
            axios({
              method: "post",
              url: Configs.API_URL + "/DefluxWipInOut/amountInOut",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp_amount,
            })
              .then(async (response) => {
                if (response.data) {
                  Swal.fire({
                    title: "Saving",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    timer: 300,
                    onOpen: () => {
                      Swal.showLoading();
                    },
                  }).then((result) => {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      
                      genLotNo();
                      getHistoryWIP(amount.id);
                      GetAllLotMatSTP();
                      setMultiLot_outBound([]);
                          //clear radio----------------------------------------
                          var radioButtonArray = document.getElementsByName('check_lot_matSTP');
                          //console.log(radioButtonArray)
                          for (var i=0; i<radioButtonArray.length; i++)
                          {
                           var radioButton = radioButtonArray[i];
                           radioButton.checked = false;
                           /* radioButton.clear(); */
                          }
                          //clear radio------------------------------------------
                      setamount({...amount,
                        amount: 1,
                   
                      
                        status_sst: "",
                  
                    
                        lot_target: "",
                    
                        customer_id: "",
                        end_user_id: "",
                        lot_rawMat: [],
                        weight_ready_toUse: "",
                 
                        
                        lot_target:"",
                      });
                      amount.amount = 1;
                      amount.status_sst = "";
                      amount.lot_target = [];
                      amount.customer_id = "";
                      amount.end_user_id = "";
                      amount.lot_rawMat = [];
                      amount.weight_ready_toUse = "";
                      setLot_rawmat_list([]);
                  
                      axios({
                        method: "get",
                        url:
                          Configs.API_URL + "/DefluxWipInOut/gethistory?id=" + amount.id,
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                      })
                        .then(async (response) => {
                          sethistory(response.data);
                          Getdata();
                          //console.log(response.data);
                        })
                        .catch(async (error) => {
                          console.log(error);
                        });
                 
          
                  
                      axios({
                        method: "post",
                        url: Configs.API_URL + "/DefluxWipInOut/filter",
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                        data: fillter,
                      })
                        .then(async (response) => {
               
    
                          //let sorted = response.data.sort((a, b) => b.amount - a.amount);
                          //await setmaterail(sorted);
                          let set_total_view = response.data.filter((element) => {
                            return element.id === amount.id;
                          });
                          
                          setamount({
                            ...amount,
                            total: set_total_view[set_total_view.length - 1].amount,
                            amount:1,
                          });
                          amount.total = set_total_view[set_total_view.length - 1].amount;
                          console.log("total",amount);
                        })
                        .catch(async (error) => {
                          console.log(error);
                        });
    
                      //window.location.href = "/Warehouse_Management/Raw_Mat_In_Out";
                    });
                  });
                }
    
                //console.log(response.data);
              })
              .catch(async (error) => {
                Swal.fire("Over Stock!", "Check your amount", "warning");
                // .then(
                //   (resault) => {
                //     window.location.href = "/Warehouse_Management/Raw_Mat_In_Out";
                //   }
                // );
                console.log(error);
              });
            })
            .catch(function (e) {
              console.log(e);
            });
          }
      
          }
    });
    
  };

  function fillterdata() {
    ///* console.log(type); */ console.log(rowsData);
    Getdata();
  }

  async function setoutbound(e) {
  

    ////////permission feature//////////  {feature.map((e) =>{
    
    setamount({
      ...amount,
      edit_type: e.target.value === "false" ? false : true,
      lot_no: "",
    });
    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/DefluxWipInOut/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    sethistory_Select({ ...history_Select, isSelect_status: true });
  }

  async function setinbound(e) {
    //console.log("in");
    setamount({
      ...amount,
      edit_type: e.target.value === "true" ? true : false,
      lot_no: "",
    });
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Raw Mat IN/OUT";
    });

    ////////permission feature//////////  {feature.map((e) =>{
   

    sethistory({ ...history });

    await axios({
      method: "get",
      url: Configs.API_URL + "/DefluxWipInOut/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    sethistory_Select({ ...history_Select, isSelect_status: false });
  }

  async function form(no, name, id, amount_total, edit_type,oem) {
    setMultiLot_outBound([]);
    setLot_rawmat_list([]);

  
    amount.amount = "";
    amount.status_sst = "";
    amount.customer = "";
    amount.end_user = "";
    oem_select.oem_id = oem;
    //console.log(oem)
    //console.log(oem_select.oem_id)
    getHistoryWIP(id);
    genLotNo();
    //console.log(edit_type);
    setmessage({ ...message, name: name, no: no });
    setamount({
      ...amount,
      amount: "",
      total: amount_total,
      id: id,
      lot_no: "",
      edit_type: true,
      weight_ready_toUse:"",
    });
    amount.id = id;
    amount.amount = 1;
    amount.total = amount_total;
    amount.edit_type = true;
    amount.weight_ready_toUse = "";
   

    ////////permission feature//////////  {feature.map((e) =>{
  
        setamount({
          ...amount,
          amount: "",
          total: amount_total,
          id: id,
          lot_no: moment(new Date()).format("YYYYMMDD"),
        });
   

    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/DefluxWipInOut/gethistory?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    // Get_history({id});
  }

 

  async function GetTrack_Lot(wire_id,diameter) {
    setmessage({ ...message, name: diameter});
 
    await axios({
      method: "get",
      url: Configs.API_URL + "/DefluxWipInOut/getTrackLot?id=" + wire_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setTracking_lot(response.data)
       //sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });

  }
  
  async function reset_select() {
    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/DefluxWipInOut/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });

    if (amount.edit_type == true) {
      setamount({
        ...amount,
        lot_no: "MAT" + moment(new Date()).format("YYYYMMDD"),
      });
      sethistory_Select({ ...history_Select, isSelect_status: false });
    } else {
      setamount({ ...amount, lot_no: "" });
      sethistory_Select({ ...history_Select, isSelect_status: false });
    }
    // e.target.checked = false;
    // Get_history({id});
  }

  const rowsData = [];
  for (var index = 0; index < defluxData.length; index++) {
    const rowItem = {};
 
    rowItem["num"] = index + 1;
    rowItem["diameter_no"] = defluxData[index].diameter_no;
    rowItem["diameter"] = defluxData[index].diameter;

    /*  rowItem["unit_by"] = materail[index].material_unit_id; */

    rowItem["amount"] = (

        <NumberFormat
          value={parseInt(defluxData[index].amount || 0)}
          displayType={"text"}
          thousandSeparator={true}
        />

    );

    rowItem["weight"] = parseFloat(defluxData[index].weight).toFixed(2) + " kg";
    rowItem["track_detail"] = (
      <button
      type="button"
      className="btn btn-block btn-info btn-sm "
      data-toggle="modal"
      data-target="#modal-Track_detail"
      onClick={GetTrack_Lot.bind(this,defluxData[index].id,defluxData[index].diameter)

      }
    >
      View track
    </button>
    );
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className="btn btn-xs "
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={form.bind(
              this,
              defluxData[index].diameter_no,
              defluxData[index].diameter,
              defluxData[index].id,
              defluxData[index].amount || 0,
              amount.edit_type,
              defluxData[index].oem_id,
            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "num",
        sort: "asc",
        width: 50,
      },
      {
        label: "Diameter No",
        field: "diameter_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "เหล็กพร้อมใช้(Diameter)",
        field: "diameter",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนัก",
        field: "weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "Track Detail",
        field: "track_detail",
        sort: "asc",
        width: 50,
      },
  
  
  
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
 
  const [MultiLot_outBound ,setMultiLot_outBound] = useState([]);
  function changes_lot_no_Target(e){
    let check = MultiLot_outBound.filter((e2)=>{return e2.deflux_lot_no === e.target.value});
    if(check.length > 0){
      let index = MultiLot_outBound.findIndex((e3)=> e3.deflux_lot_no === e.target.value);
      MultiLot_outBound.splice(index,1);
    }else{

      let temp ={
        deflux_lot_no:e.target.value
      }
      MultiLot_outBound.push(temp);
    }
   

    console.log(MultiLot_outBound);
  }
  const rowsData_history = [];

  for (var index = 0; index < history.length; index++) {
    const rowItem = {};
    rowItem["lot_no"] = history[index].deflux_lot_no;





    rowItem["items"] = parseInt(history[index].amount) ;
    
    rowItem["weight"] = parseFloat(history[index].weight) + " kg";
    rowItem["date"] = moment(history[index].create_date).format('DD/MM/yyyy  HH:mm น.');
    rowItem["is_reserved"] = history[index].is_reserved === true ? "ติดจอง" : "คลัง";
    rowItem["cus_and_end"] = history[index].cus_and_end || "";
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6"></div>
        <div className="col-4">
          <input
            className="form-check-input"
            type="checkbox"
            id={index}
            name="check_lot_matSTP"
            //defaultChecked = {MultiLot_outBound.filter((e)=>{return history[index].deflux_lot_no === e.deflux_lot_no}).length > 0 ? true : false}
            value={history[index].deflux_lot_no}
            onClick={changes_lot_no_Target.bind(this)
          
              /* changes_log_no.bind(
              this
            ) */}
          />
        </div>
      </div>
    );
    rowsData_history.push(rowItem);
  }

  const data_history = {
    columns: [
       {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
     /*  {
        label: "Ref Lot No.",
        field: "ref_lot_no",
        sort: "asc",
        width: 50,
      }, */
     
      {
        label: "จำนวน",
        field: "items",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนักพร้อมใช้งาน",
        field: "weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "In Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "is_reserved",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลูกค้า/ตัวแทนจำหน่าย",
        field: "cus_and_end",
        sort: "asc",
        width: 50,
      },
 
    ],
    rows: rowsData_history,
  };


  if (amount.edit_type === true) {
    var index = data_history.columns.findIndex((e) => {
      return e.field === "mgt";
    })
    data_history.columns.splice(index, 1);
  }
 

  function getHistoryWIP(wire_id) {
    axios({
      method: "get",
      url: Configs.API_URL + "/DefluxWipInOut/getHistoryWIP?id=" + wire_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
        setHistoryWIP(response.data);
        console.log(response.data);
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const rowsData_historyWIP = [];

  for (var index = 0; index < historyWIP.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["deflux_lot_no"] = historyWIP[index].deflux_lot_no;

    var status;
    if (historyWIP[index].edit_type === true) {
      status = "IN";
    } else {
      status = "OUT";
    }
    rowItem["status"] = status;
    rowItem["amount"] = parseInt(historyWIP[index].amount);
    rowItem["weight"] = parseFloat(historyWIP[index].weight).toFixed(2) + " kg";

    rowItem["date"] = moment(historyWIP[index].create_date).format(
      "DD/MM/yyyy :: HH:mm น."
    );
    rowItem["is_reserved"] = historyWIP[index].is_reserve === true ? "ติดจอง" : "คลัง";
    rowItem["cus_and_end"] = historyWIP[index].cus_and_end;

    rowsData_historyWIP.push(rowItem);
  }

  const Data_HistoryWIP = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "deflux_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "น้ำหนักพร้อมใช้งาน",
        field: "weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "In Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "is_reserved",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลูกค้า/ตัวแทนจำหน่าย",
        field: "cus_and_end",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_historyWIP,
  };
  async function genLotNo() {
    axios({
      method: "get",
      url: Configs.API_URL + "/DefluxWipInOut/genLotNo_WIP?oem_id="+oem_select.oem_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
      setamount({ ...amount, lot_no: response.data });
        amount.lot_no = response.data;
        console.log(response.data)
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );


  const [lot_out_list,setlot_out_list] = useState([]);
  const [test,settest] = useState({
    test:"",
  });

  function setDefaultLotOut(){

    //console.log("e",e);
    //console.log("index",index);
    setlot_out_list([]);
    let tempData = Lot_rawmat_full;
    for(let i of tempData){
      i.isOut = false;
    }
    //tempData[index].isOut = true;
    setLot_rawmat_full(tempData);
    settest({...test,test:""});
    test.test = "";
  }
  function LotOut(e,index){

    //console.log("e",e);
    //console.log("index",index);
    lot_out_list.push(e);
    let tempData = Lot_rawmat_full;

    tempData[index].isOut = true;
    setLot_rawmat_full(tempData);
    settest({...test,test:""});
    test.test = "";
  }





  function Deflux_tracking(data) {
    
      console.log(data)
      var img_qr = "";
      QRCode2.toDataURL(data.deflux_lot_no, function (err, url) {
        img_qr = url
      })
    const doc = new jsPDF("l", "mm", "a5");
 


    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    /* doc.addFont('Rocki', 'bold'); */
   //box 1
   doc.setFontSize(24);
   doc.rect(5,5,100,30);
   doc.text(55,22.5,message.name,"center");

   doc.rect(105,5,100,30);
   doc.text(155,22.5,"จำนวน : 1 ม้วน","center");

   doc.rect(5,35,100,30);
   doc.text(55,52.5,"Deflux & WIP Lot : "+data.deflux_lot_no,"center");

   doc.rect(105,35,100,30);
   doc.text(155,52.5,"น้ำหนักพร้อมใช้ : "+data.weight+" kg","center");

   doc.rect(5,65,100,78);
   doc.addImage(img_qr, 'JPEG', 20,68, 70,70);

   doc.rect(105,65,100,78);
   doc.text(132.5,75,"Material Lot : ","center");
   let y_txt = 0;
   for(let mat_lot of data.mat_lot_list){
    doc.text(150,75+y_txt,mat_lot.label,"left");
    y_txt = y_txt + 8;
   }
    
    
     
    /*     doc.addPage("a5", "l") */
    
     

     
  /*   } */
   
/* } */

   window.open(doc.output('bloburl'));
   }



  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="rawmatinout" submenu="none" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Deflux & WIP IN/OUT{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                  Deflux & WIP IN/OUT
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-md-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={fillter.diameter_no}
                    onChange={(e) => {
                      setfillter({ ...fillter, diameter_no: e.target.value});
                    }}
              
                  />
                  <label htmlFor="">Diameter No</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={fillter.diameter}
                    onChange={(e) => {
                      setfillter({ ...fillter, diameter: e.target.value});
                    }}
              
                  />
                  <label htmlFor="">ขนาดเหล็กพร้อมใช้</label>
                </div>
              </div>
           
           

            
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2 ">
            <div className="col-4 col-md-3 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm "
                  onClick={fillterdata}
                >
                  Search
                </button>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm"
                  onClick={() => {
                    clearfillter();
                  }}
                >
                  Clear
                </button>
              </div>
              {/*
              <div className=" col-sm-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-sm "
                  onClick={showmessage}
                >
                  QR Code
                </button>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Deflux & WIP IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div
                className="table-responsive" style={{ whiteSpace: 'nowrap'}} 
              >
                <MDBDataTable
                  className="table table-head-fixed"
                  sortable = {false}
                  striped
                  bordered
                  hover
                  order={["amount", "asc"]}
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">
                  Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={{
                        backgroundColor: "#F0FFFF",
                        border: "1px solid #ddd",
                        padding: "10px",
                        width: "100",
                      }}
                      decimalScale={2}
  /*                     value={amount.total|| 0 } */
                      value={parseInt(amount.total|| 0 )}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                  &nbsp;&nbsp;&nbsp; items
                </h1>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  //onClick={close}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                <div className="col-0 col-md-1 col-xl-2"></div>
                  <div className="col-12 col-md-5 col-xl-4" >
                    <h3>WIP NO : {message.no}</h3>
                  
                  </div>
                  <div className="col-12 col-md-5 col-xl-4" >
                    <h3>WIP Name : {message.name}</h3>

                  </div>
                </div>
                    
                  <div className="row">
                  <div className="col-0 col-md-1 col-xl-2"></div>
                  <div className="col-6 col-md-5 col-xl-4" >
               
                    <input
                      type="radio"
                      id="inbound"
                      name="edit_type"
                      checked={amount.edit_type != true ? false : true}
                      value={true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "true" ? true : false,
                        });
                      }}
                   
                    />

                    <label htmlFor="inbound">
                      <h4> In bound</h4>
                    </label>
                  </div>
                <div className="col-6 col-md-5 col-xl-4" >
                    

                    <input
                      type="radio"
                      id="outbound"
                      name="edit_type"
                      value={false}
                      checked={amount.edit_type != false ? false : true}
                      onChange={(e) => {
                        
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "false" ? false : true,lot_target:""
                        });
                        setMultiLot_outBound([]);
                      }}
                    />
                    <label htmlFor="outbound">
                      <h4> Out bound</h4>
                    </label>
                  </div>
                </div>
              

          {/*       {feature.map((e) => {
                  if (e.feature === "Mat IN/OUT History") {
                    return ( */}
                      <LOGH
                        onClick={reset_select}
                        history={Data_HistoryWIP}
                        data={data_history}
                      />
               {/*      );
                  }
                })} */}
             
                <div className="row"></div>
                <div className="row">
            

                  {amount.edit_type === true ? (
                    <>
                         <div className="col-0 col-md-0 col-xl-4" >
                         
                          </div>
                          <div className="col-12 col-md-6 col-xl-4" >
                       
                              <INF
                                value={amount.lot_no}
                                disabled={true}
                                label={"WIP Lot No"}
                                onChange={(e) => {
                                  setamount({
                                    ...amount,
                                    lot_no: e.target.value,
                                  });
                                }}
                              />
                     
                      </div>

                      <div className="col-12 col-md-6 col-xl-4" >
                      <div class="form-group">
                                  <select
                                    className="custom-select select2 form-control"
                                    value={amount.status_sst}
                                    onChange={(e) =>{
                                      setamount({ ...amount, status_sst: e.target.value })
                                      search_cus_end.result_end = "";
                                      search_cus_end.result_cus = "";
                                    }
                                    
                                    }
                                  >

                                    <option value="Warehouse_STP">Warehouse STP</option>
                                    <option value="customer">Customer</option>
                                    <option value="end_user">End User</option>

                                  </select>

                                  <label htmlFor="">สถานะ :</label>

                                </div>
                      </div>
                      {amount.status_sst === "customer" ? (
                        <>          
                           <div className="col-0 col-md-0 col-xl-4" ></div>
                        <div className="col-12 col-md-6 col-xl-4" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <Select
                    placeholder=""
                    styles={colourStyles}
                    options={customer_option}
                    defaultValue={amount.customer }
                   
                    onChange={(e) => {
                      // console.log(e);
                     
                      setamount({
                          ...amount,
                          customer : e.value1,
                        });
                        amount.customer = e.value1;
                  
                        setsearch_cus_end({
                          ...search_cus_end,
                          result_cus : e.value2,
                        });
                        search_cus_end.result_cus = e.value2;
                  
                   
                    }}
                  />
                  <label htmlFor="">No. Customer </label>
                </div>
              </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div class="form-group">
                          <INF value={search_cus_end.result_cus} disabled={true}  label={"Result Customer"} /* onChange={(e) => {
                                 setamount({ ...amount, customer: e.target.value })
                              }} */ />
                          </div>
                          </div>
                        </>
                      ) : (
                        <>
                      {amount.status_sst === "end_user" ? (
                        <>      <div className="col-0 col-md-0 col-xl-4" ></div>
                    <div className="col-12 col-md-6 col-xl-4" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <Select
                    placeholder=""
                    styles={colourStyles}
                    options={End_user_option}
                    defaultValue={amount.end_user}
                    onChange={(e) => {
                      // console.log(e);
                     
                      setamount({
                          ...amount,
                          end_user : e.value1,
                        });
                        amount.end_user = e.value1;
                  
                        setsearch_cus_end({
                          ...search_cus_end,
                          result_end : e.value2,
                        });
                        search_cus_end.result_end = e.value2;
                  
                   
                    }}
                  />
                  <label htmlFor="">No. ENd User</label>
                </div>
              </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div class="form-group">
                          <INF value={search_cus_end.result_end} disabled={true}  label={"Result End user"} /* onChange={(e) => {
                                 setamount({ ...amount, customer: e.target.value })
                              }} */ />
                          </div>
                          </div>
                   
                        </>
                      ) : (
                        <>
                     
                        </>
                      )}
                        </>
                      )}
                      <div className="col-12 col-md-6 col-xl-4" style={{textAlign:"right"}}>
                      <label htmlFor="">กรุณาเลือก Lot Raw mat :</label>
                         </div>
                         <div className="col-12 col-md-6 col-xl-4">
                     

                         <MultiSelect
 

      hasSelectAll = {false}
        options={Lot_rawmat}
        value={Lot_rawmat_list}
        onChange={setLot_rawmat_list}
        labelledBy="Select"
      />



                          
                         </div>

                     <div className="col-12 col-md-6 col-xl-4">
                         <div class="form-group">
                                
                         <button
      type="button"
      className="btn btn-block btn-info "
      data-toggle="modal"
      data-target="#modal-lotmat_mgt"
      onClick={setDefaultLotOut}
    >
     Lot Raw Mat MTG
    </button>
                         

                                </div>
                         </div>
                    </>
                  ) : (
                    <>
                       {/*  <div className="col-12 col-md-6 col-xl-4"></div>
                        <div className="col-12 col-md-6 col-xl-4"></div>
                        <div className="col-12 col-md-6 col-xl-4"></div> */}
                    </>
                  )}
          
          <div className="col-0 col-md-3 col-xl-4" ></div>
                  
          <div className="col-12 col-md-6 col-xl-4">
                    <div className="form-group ">
                      <NumberFormat
                        disabled = {true}
                        thousandSeparator={true}
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        value={amount.amount}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            amount: e.target.value,
                          });
                        }}
                        required
                      />
                      <label htmlFor="">Amount</label>{" "}
                    </div>
                  </div>
                  {amount.edit_type === true ? (
                    <>
              
              <div className="col-12 col-md-6 col-xl-4">
                  <div className="form-group ">
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        value={amount.weight_ready_toUse}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            weight_ready_toUse: e.target.value,
                          });
                        }}
                        required
                      />
                      <label htmlFor="">น้ำหนักเหล็กพร้อมใช้งาน</label>{" "}
                    </div>
                  </div>
                      </>
                      ) : (
                        <>  
                           <div className="col-12 col-md-6 col-xl-4"></div>
                        </>
                      )}
                </div>
            

              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={Getamount}
                  // data-dismiss="modal"
                  // aria-label="Close"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>




      <div className="modal fade" id="modal-lotmat_mgt">
      <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">รายการ Lot Material </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">
                <div className="table-responsive">
                <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                          <tr>
                            <th style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap' ,backgroundColor:"#B0C4DE" }}>Lot Material No</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap' ,backgroundColor:"#B0C4DE" }}>จำนวน</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap' ,backgroundColor:"#B0C4DE" }}>น้ำหนักตามป้าย(tag)</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap' ,backgroundColor:"#B0C4DE" }}>น้ำหนักชั่งจริง</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap' ,backgroundColor:"#B0C4DE" }}>Date</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap' ,backgroundColor:"#B0C4DE" }}>Management</th>
                          </tr>
                       
                              {Lot_rawmat_full.map((e,index)=>{
                                if(Lot_rawmat_list.filter((lot)=> {return lot.label == e.mat_lot_no}).length > 0){
                                  
                                  return(<>
                                    <tr>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap'  }}>{e.mat_lot_no}</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap'  }}>{e.amount} ม้วน</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap'  }}>{e.weight_by_tag !== null ? e.weight_by_tag +" Kg." : "ไม่ระบุ"}</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd" ,whiteSpace: 'nowrap' }}>{e.actual_weight !== null ? e.actual_weight +" Kg." : "ไม่ระบุ"}</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd" ,whiteSpace: 'nowrap' }}>{moment(e.create_date).format("DD/MM/yyyy  HH:mm น.")}</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd",whiteSpace: 'nowrap'  }}>
                                 <button
                   type="button"
                   className={e.isOut === true ? "btn btn-block btn-secondary" : "btn btn-block btn-danger"  }
                  
                   key={index}
                   disabled={e.isOut === true ? true : false}
                   onClick={LotOut.bind(this,e,index)}
                   // data-dismiss="modal"
                   // aria-label="Close"
                 >
                   {e.isOut === true ? "Lot OUT." : "Select Lot Out."}
                 </button>
                                 </td>
                                 </tr>
                                 </>);
                                }
                               
                              })}
                          
                        </table>
       
                </div>
              </div>
              </div>
            
              
           
            </div>
            <div className="modal-footer "align="right">
            
                 
               <div className="row">
              {/*      <div className="col-12" align="right"> */}
                   <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
                {/*    </div> */}
               </div>
             
              
          
             
            </div>
          </div>
         
        </div>
       
      </div>





      <div className="modal fade" id="modal-Track_detail"> 
      <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Tracking Detail {message.name}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">
                <div className="table-responsive" style ={{whiteSpace: 'nowrap'}}>
                <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                          <tr>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>WIP LOT NO</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>Material LOT NO</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>Management</th>
                          </tr>
                       
                              {Tracking_lot.map((e,index)=>{
                                return(<>
                                   <tr>
                                <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>{e.deflux_lot_no}</td>
                                <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>
                                  {e.mat_lot_list.map((e2)=>{
                                    return (<>
                                    <div>
                                      {e2.label}
                                    </div>
                                    </>)
                                  })
                                }</td>
                               <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>
                                <button
                  type="button"
                  className="btn btn-primary"
                 
                  key={index}
                  onClick={Deflux_tracking.bind(this,e)}
                  /* disabled={e.isOut === true ? true : false}
                  onClick={LotOut.bind(this,e,index)} */
                  // data-dismiss="modal"
                  // aria-label="Close"
                >
                 Tracking lot PDF 
                </button>
                                </td>
                                </tr>
                                </>);
                              })}
                          
                        </table>
       
                </div>
              </div>
              </div>
            
              
           
            </div>
            <div className="modal-footer "align="right">
            
                 
               <div className="row">
              {/*      <div className="col-12" align="right"> */}
                   <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
                {/*    </div> */}
               </div>
             
              
          
             
            </div>
          </div>
         
        </div>
       
      </div>







      {/*    <Footter /> */}
    </div>
  );
}
export default DefluxWipInOut;
