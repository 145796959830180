import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";

function Customer_Cute_Product({
  id,
  mode,
  isHistory,
  isDisabled,
  inputDataTab2,
  cus_id_filter,
  setInputDataTab2,
  errorListTab2,
  setErrorListTab2,
  functionTab2,
  setFunctionTab2,
  inputLang,
  setInputLang,
  inputData,
  setInputData
}) {
  const modalProductRef = useRef();

  const [categoryData, setCategoryData] = useState({
    data: [],
  });
  const [MDBT, setMDBT] = useState(true);

  const [productData, setProductData] = useState({
    data: [],
    filter: []
  });

  const [userData, setUserData] = useState({
    data: [],
  });

  const [selectProductData, setSelectProductData] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [searchData, setSearchData] = useState({
    code: "",
    name: "",
    barcode: "",
    category: "",
    customer_barcode: "",
    customer_product_name: ""
  });

  const [cus_id_temp, setCus_id_temp] = useState({
    data: ""
  });


  useEffect(async () => {
    if (mode === "edit" || mode === "view") {
      // await GetQuotationSelectProduct(id, false);
      await GetRequisitiontProduct();
    }
    await GetCategoryLv2();
    await GetUserSales();
    await GetProduct();
  }, []);

  useEffect(async () => {

    setCus_id_temp({ data: cus_id_filter });
    cus_id_temp.data = cus_id_filter;
  }, [cus_id_filter])

  useEffect(async () => {
    setInputDataTab2({
      ...inputDataTab2,
      data: selectProductData.data,
      newData: selectProductData.newData,
      updateData: selectProductData.updateData,
      delData: selectProductData.delData,
    });

    inputDataTab2.data = selectProductData.data;
    inputDataTab2.newData = selectProductData.newData;
    inputDataTab2.updateData = selectProductData.updateData;
    inputDataTab2.delData = selectProductData.delData;

    setFunctionTab2({
      ...functionTab2,
      save: checkErrorSelectProductData,
      quotationSelectProduct: GetQuotationSelectProduct,
      GetProduct: GetProduct,
    });

    functionTab2.save = checkErrorSelectProductData;
    functionTab2.quotationSelectProduct = GetQuotationSelectProduct;
    functionTab2.GetProduct = GetProduct;
  }, [selectProductData.data, inputData.count]);

  async function GetUserSales() {
    let tempData = {
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/users/getUserSales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {

        setUserData({
          ...userData,
          data: response.data,
        });
        userData.data = response.data;

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function openModalProduct(is_click) {
    const tempSelectProduct = selectProductData.data;
    const tempProductTable = productData.data;
    const tempList1 = tempProductTable.map((item1) => {

      for (let item of item1.exp_date_list) {

        const check = tempSelectProduct.find(el => el.product.product_id === item.product.product_id && el.exp === item.exp)

        if (check) {
          item.check = true;
        } else {
          item.check = false;
        }
      }
      return item1;
    })

    // tempList[0].unit = "0";
    setProductData({ ...productData, data: tempList1 });
    productData.data = tempList1;

    if (!is_click) {
      modalProductRef.current.click();

    }
  }

  async function GetQuotationSelectProduct(data, trigger) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      quotation: data,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/quotation/getQuotationSelectProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data,
        });

        inputDataTab2.data = response.data;
        // console.log('res --- ', response.data)
        if (trigger) {
          const tempProduct = productData.data;
          const selectProductDataList = [];
          ///////////
          for (let item of tempProduct) {

            if (inputDataTab2.data?.length > 0) {

              const idx = inputDataTab2.data?.findIndex(el => el.qp_product_id === item.product_id)

              if (cus_id_temp.data && item.detailsCustomer) {

                const check_has = item.detailsCustomer?.find(el => el.crp_cus_id === cus_id_temp.data)
                if (check_has) {
                  item.cus_id_check = cus_id_temp.data;
                  item.bar_code = check_has.crp_pb_id || item.barcode_id[0]; //item1.barcode_id?.find(el => el === item1.detailsCustomer.crp_pb_id) || item1.barcode_id[0];
                  item.customer_code = check_has.crp_cus_barcode || "";
                }
              }

              if (idx >= 0) {
                if (item.exp_date_list.length > 0) {

                  item.exp_date_list[0].check = true;
                  const temp = item.exp_date_list[0];

                  const { exp_date_list, ...other } = item;
                  temp.check = true;
                  temp.product = other;
                  temp.product.oldNew = "new";
                  temp.product.status = "add";
                  temp.product.customer_code = inputDataTab2.data[idx].qp_cus_barcode;
                  temp.product.bar_code = inputDataTab2.data[idx].qp_pb_id;
                  temp.product.quantity = inputDataTab2.data[idx].qp_quantity;
                  temp.product.qp_id = inputDataTab2.data[idx].qp_id;
                  temp.product.unit = inputDataTab2.data[idx].qp_quantity_unit?.toLowerCase();

                  selectProductDataList.push({ ...temp });
                  inputDataTab2.data.splice(idx, 1);
                }
              }
            }
          }

          setSelectProductData({
            ...selectProductData,
            data: selectProductDataList,
            newData: selectProductDataList,
            updateData: [],
            delData: [],
          });
          selectProductData.data = selectProductDataList;
          selectProductData.newData = selectProductDataList;
          selectProductData.updateData = [];
          selectProductData.delData = [];

          let dataList = cus_id_temp.data ?
            tempProduct?.filter(el => el.cus_id_check === cus_id_temp.data) : tempProduct;

          setProductData({ data: dataList });
          productData.data = dataList;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetRequisitiontProduct(ref_id) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      req_id: id,
      isHistory: isHistory
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/requisition/GetRequisitionProductById",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        console.log('response.data', response.data);
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data.data,
        });

        inputDataTab2.data = response.data.data;
        setMDBT(true);

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetCategoryLv2() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategoryLv2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategoryData({ data: response.data });
        categoryData.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProduct(cus_id) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      code: searchData.code.trim() || null,
      name: searchData.name.trim() || null,
      barcode: searchData.barcode.trim() || null,
      category: searchData.category.trim() || null,
      customer_barcode: searchData.customer_barcode.trim() || null,
      customer_product_name: searchData.customer_product_name.trim() || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductLogAndDetails",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {

        let data = new FormData();
        data.append("company_id", getUser().com);
        data.append("oem_id", getOem());
        data.append("user_id", getUser().fup);
        data.append("products", JSON.stringify(response.data));

        await axios({
          method: "post",
          url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        })
          .then(async (response) => {
            if (response.data) {
              if (response.data.length == 0) {
                await resetProduct(false);
                return;
              } else if (productData.data?.length > 0) {
                setProductData({
                  ...productData,
                  filter: response.data
                })
                productData.filter = response.data
              }
              await SetProduct_Details(productData.data?.length > 0 ? productData.data : response.data, cus_id)
            }
          })
          .catch(async (error) => {
            console.log('error', error);
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
            });
          });
        // const tempRes = response.data;
        setMDBT(true)

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const SetProduct_Details = async (tempRes, cus_id) => {

    const selectProductDataList = [];
    const arrProductTable = [];
    for (let item1 of tempRes) {

      if (productData.filter.length > 0) {

        const check = productData.filter.find(el => el.product_id === item1.product_id)
        if (check) {
          item1.is_use = true
        } else {
          item1.is_use = false
        }
      } else {
        item1.is_use = true
      }

      if (cus_id) {
        const check = selectProductData.data.find(el => el.product.product_id === item1.product_id);
        if (check) {
          item1.check = true;
          arrProductTable.push(item1);
          continue;
        }
      }

      item1.check = false;
      item1.oldNew = "new";
      item1.quantity = "";
      item1.sale_id = "";
      item1.sale_name = "";
      item1.unit = "piece";
      item1.fg_amount_cal = [];
      item1.customer_code = "";
      if (item1.barcode_id.length === 1) {
        item1.isBarSelect = false;
        item1.bar_code = item1.barcode_id[0];
      } else {
        item1.isBarSelect = true;
        item1.bar_code = item1.barcode_id[0];
      }

      if (item1.exp_date_list && item1.exp_date_list.length > 0) {

        item1.fg_amount_cal = item1.exp_date_list.map(el => {
          return (el.amount_in - el.amount_out - el.amount_reserve)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) || 0
        })

        /* for (let item of item1.exp_date_list) {
          const { exp_date_list, ...other } = item1;
          item.product = { ...other };
        } */

      }

      if (mode === "edit") {
        if (inputDataTab2.data.length > 0) {

          for (let item of item1.exp_date_list) {
            const check = inputDataTab2.data.findIndex(
              (item2) => item2.rp_exp_date === item.exp && item2.rp_product_id === item1.product_id
            );

            if (check >= 0) {

              item1.oldNew = "old";
              item1.bar_code = inputDataTab2.data[check].rp_pb_id;
              item1.quantity = parseInt(inputDataTab2.data[check].rp_quantity);
              item1.customer_code = inputDataTab2.data[check].rp_cus_barcode
              item1.rp_id = inputDataTab2.data[check].rp_id;
              item1.unit = inputDataTab2.data[check].rp_quantity_unit ? inputDataTab2.data[check].rp_quantity_unit?.toLowerCase() : "piece";
              item1.exp_date = inputDataTab2.data[check].rp_exp_date;
              item1.sale_id = inputDataTab2.data[check].rp_sales_id || "";
              item1.sale_name = inputDataTab2.data[check].rp_sales_name || "";

              const { exp_date_list, ...other } = item1;
              item.product = { ...other };

              let check2 = false;
              if (inputDataTab2.data[check].rp_exp_date === item.exp) check2 = true;

              if (check2) {

                item.check = true
                selectProductDataList.push({ ...item });
              }

              inputDataTab2.data.splice(check, 1);
            }
          }
        }

        /* for (let item2 of inputDataTab2.data) {
          if (item1.product_id === item2.qp_product_id) {
            item1.check = true;
            item1.oldNew = "old";
            item1.bar_code = item2.qp_pb_id;
            item1.quantity = item2.qp_quantity;
            item1.qp_id = item2.qp_id;

            selectProductDataList.push(item1);
          }
        } */
      } else if (mode === "view") {


        for (let check = 0; check < inputDataTab2.data.length; check++) {

          if (inputDataTab2.data[check].rp_product_id === item1.product_id) {

            let temp = {
              exp: inputDataTab2.data[check].rp_exp_date,
              sale_dropdown: userData.data?.map(el => {
                return {
                  sale_id: el.id,
                  sale_name: el.fullname
                }
              })
            };

            item1.oldNew = "old";
            item1.bar_code = inputDataTab2.data[check].rp_pb_id;
            item1.quantity = parseInt(inputDataTab2.data[check].rp_quantity);
            item1.customer_code = inputDataTab2.data[check].rp_cus_barcode
            item1.rp_id = inputDataTab2.data[check].rp_id;
            item1.unit = inputDataTab2.data[check].rp_quantity_unit ? inputDataTab2.data[check].rp_quantity_unit?.toLowerCase() : "piece";
            item1.exp_date = inputDataTab2.data[check].rp_exp_date;
            item1.sale_id = inputDataTab2.data[check].rp_sales_id || "";
            item1.sale_name = inputDataTab2.data[check].rp_sales_name || "";

            const { exp_date_list, ...other } = item1;
            temp.product = { ...other };

            selectProductDataList.push({ ...temp });

            inputDataTab2.data.splice(check, 1);
          }

        }

      }

      if (item1.detailsCustomer && cus_id_temp.data) {
        const check_has = item1.detailsCustomer?.find(el => el.crp_cus_id === cus_id_temp.data)

        if (check_has) {

          item1.bar_code = check_has.crp_pb_id || item1.barcode_id[0]; //item1.barcode_id?.find(el => el === item1.detailsCustomer.crp_pb_id) || item1.barcode_id[0];
          item1.customer_code = check_has.crp_cus_barcode || ""
          item1.gp = check_has.crp_gp1 || "";
          item1.retail_price = check_has.crp_sale_price || ""

          if (check_has.crp_product_name_th) item1.product_name_select = check_has.crp_product_name_th;

          if (check_has.crp_product_name_eng) item1.product_name_eng_select = check_has.crp_product_name_eng;

          for (let item of item1.exp_date_list) {
            const { exp_date_list, ...other } = item1;
            item.product = { ...other };
          }

          arrProductTable.push(item1)
        }

      } else if (!cus_id_temp.data) {

        item1.bar_code = item1.barcode_id[0] || "";
        for (let item of item1.exp_date_list) {
          const { exp_date_list, ...other } = item1;
          item.product = { ...other };
        }

        arrProductTable.push(item1)
      }

      
    }

    if (!cus_id) {
      setSelectProductData({
        ...selectProductData,
        data: selectProductDataList,
      });
      selectProductData.data = selectProductDataList;
    }
    setProductData({
      ...productData,
      data: arrProductTable
    });
    productData.data = arrProductTable;
    // await openModalProduct(true);
  }

  const OnTableSelectProductChange = async () => {
    const num = inputData.count;
    setInputData({
      ...inputData,
      count: (num + 1)
    });
    inputData.count = (num + 1);
  }

  const rowsData1 = [];
  let run_number = 0;
  for (let index = 0; index < productData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{run_number + 1}</div>;

    rowItem["code"] = productData.data[index].product_code
      ? productData.data[index].product_code
      : "-";

    rowItem["barcode"] = productData.data[index].barcode[0] ? productData.data[index].barcode[0] : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            productData.data[index].pci_img_path !== "" &&
              productData.data[index].pci_img_path !== null &&
              productData.data[index].pci_img_path !== undefined
              ? productData.data[index].pci_img_path?.indexOf("blob") >= 0
                ? productData.data[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                `/static/${productData.data[index].pci_img_name}/${productData.data[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = productData.data[index].product_name_eng
      ? productData.data[index].product_name_eng
      : "-";

    rowItem["category"] = productData.data[index].cat_name
      ? productData.data[index].cat_name
      : "-";
    if (productData.data[index].exp_date_list && productData.data[index].exp_date_list.length > 0) {

      /* for (let index2 = 0; index2 < productData.data[index].exp_date_list.length; index2++) {
      } */

      /* rowItem["exp_date"] = (
        productData.data[index].exp_date_list?.map((el, index2) => {
          return (
            <></>
          )
        })
      ) */


    }
    rowItem["exp_date"] = (
      <div style={{ whiteSpace: "pre-line", lineHeight: "2.5rem" }}>
        {
          productData.data[index].exp_date_list
            && productData.data[index].exp_date_list.length > 0
            ? productData.data[index].exp_date_display?.join(',\n')
            : <div style={{ lineHeight: "2.5rem", textAlign: 'center' }}>- </div>
        }
      </div>
    );

    rowItem["fg_amount"] = (
      <div style={{ whiteSpace: "pre-line", lineHeight: "2.5rem", textAlign: 'end' }}>
        {
          productData.data[index].exp_date_list
            && productData.data[index].exp_date_list.length > 0
            ? productData.data[index].fg_amount_cal?.join(',\n')
            : <div style={{ lineHeight: "2.5rem", textAlign: 'center' }}>- </div>
        }
      </div>
    );

    rowItem["management"] = productData.data[index].exp_date_list
      && productData.data[index].exp_date_list.length === 0 ? <div style={{ lineHeight: "2.5rem", textAlign: 'center' }}>- </div>
      :
      productData.data[index].exp_date_list?.map((el, index2) => {
        return (
          productData.data[index].exp_date_list[index2].check === false ? (
            <div>
              <button
                className="btn btn-block btn-primary py-1 mb-2"
                onClick={() => {
                  let tempData = productData.data;

                  tempData[index].exp_date_list[index2].check = true;
                  if (tempData[index].statusUpdate) {
                    // tempData[index].status = "update";
                    tempData[index].exp_date_list[index2].product.status = "update";

                  } else {
                    // tempData[index].status = "add";
                    tempData[index].exp_date_list[index2].product.status = "add";

                  }

                  setProductData({
                    ...productData,
                    data: tempData,
                  });
                }}
              >
                เลือก
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn btn-block btn-danger py-1 mb-2"
                onClick={() => {
                  let tempData = productData.data;

                  tempData[index].exp_date_list[index2].check = false;
                  tempData[index].exp_date_list[index2].product.status = "del";
                  // tempData[index].status = "del";

                  setProductData({
                    ...productData,
                    data: tempData,
                  });
                }}
              >
                ยกเลิก
              </button>
            </div>
          )
        )
      })

    if (productData.data[index].is_use) {
      run_number += 1;
      rowsData1.push(rowItem);
    }
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Category",
        field: "category",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "EXP. Date",
        field: "exp_date",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "FG Amount",
        field: "fg_amount",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };

  const rowsData2 = [];
  for (let index = 0; index < selectProductData.data.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = selectProductData.data[index].product.product_code
      ? selectProductData.data[index].product.product_code
      : "-";

    rowItem["bar_code"] =
      selectProductData.data[index].product.isBarSelect === false ? (
        <select
          type="text"
          className="form-control"
          style={{ minWidth: "150px" }}
          disabled={true}
          value={selectProductData.data[index].product.bar_code}
        >
          <option value="-" disabled selected>
            -- เลือก --
          </option>

          <option value={selectProductData.data[index].product.barcode_id[0]}>
            {selectProductData.data[index].product.barcode[0]}
          </option>
        </select>
      ) : (
        <select
          type="text"
          className="form-control"
          style={{ minWidth: "150px" }}
          disabled={isDisabled}
          value={selectProductData.data[index].product.bar_code}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].product.bar_code = e.target.value;

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].product.oldNew === "old") {
              temp[index].product.status = "update";
              temp[index].product.statusUpdate = true;
              await saveProductData();
            }
          }}
        >
          <option value="" disabled>
            -- เลือก --
          </option>

          {selectProductData.data[index].product.barcode_id?.map((item, indexIn) => {
            return (
              <option value={item}>
                {selectProductData.data[index].product.barcode[indexIn]}
              </option>
            );
          })}
        </select>
      );

    rowItem["customer_code"] = (
      <NumberFormat
        type="text"
        className="form-control"
        // required
        decimalScale={0}
        allowNegative={false}
        allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].product.customer_code}
        onChange={async (item) => {
          const temp = selectProductData.data;

          temp[index].product.customer_code = item.target.value;
          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].product.oldNew === "old") {
            temp[index].product.status = "update";
            temp[index].product.statusUpdate = true;
            await saveProductData();
          }
          await OnTableSelectProductChange();

        }}
      />
    )

    inputLang.check
      ? (rowItem["product_name"] = selectProductData.data[index].product.product_name
        ? selectProductData.data[index].product.product_name
        : "-")
      : (rowItem["product_name"] = selectProductData.data[index].product
        .product_name_eng
        ? selectProductData.data[index].product.product_name_eng
        : "-");

    rowItem["sale"] = (
      <select
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        disabled={isDisabled}
        value={selectProductData.data[index].product.sale_id}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].product.sale_id = e.target.value;

          if (userData.data.length > 0) {
            temp[index].product.sale_name = userData.data?.find(el => el.id === e.target.value)?.fullname
          }
          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].product.oldNew === "old") {
            temp[index].product.status = "update";
            temp[index].product.statusUpdate = true;
            await saveProductData();
          }
          await OnTableSelectProductChange();

        }}
      >
        <option value="" disabled>
          -- เลือก Sale --
        </option>

        {selectProductData.data[index].sale_dropdown?.map((item, indexIn) => {
          return (
            <option value={item.sale_id}>
              {item.sale_name}
            </option>
          );
        })}
      </select>
    );

    rowItem["exp_date"] = selectProductData.data[index].exp
      ? selectProductData.data[index].exp
      : "-";

    rowItem["quantity"] = (
      <div style={{ display: "flex", gap: ".5rem" }}>
        <NumberFormat
          type="text"
          className="form-control"
          /*  style={{ minWidth: "150px" }} */
          required
          decimalScale={0}
          allowNegative={false}
          // allowLeadingZeros={true}
          // isAllowed={(values) => {
          //   const { formattedValue, floatValue } = values;
          //   return formattedValue === "" || floatValue <= 100;
          // }}
          disabled={isDisabled}
          thousandSeparator={true}
          value={selectProductData.data[index].product.quantity}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].product.quantity = Number(e.target.value.replace(/,/g, ""));

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].product.oldNew === "old") {
              temp[index].product.status = "update";
              temp[index].product.statusUpdate = true;
              await saveProductData();
            }
            await OnTableSelectProductChange();
          }}
        />
        <select
          type="text"
          className="form-control"
          required
          disabled={isDisabled}
          value={selectProductData.data[index].product.unit}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].product.unit = e.target.value;

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });
            selectProductData.data = temp;

            if (temp[index].product.oldNew === "old") {
              temp[index].product.status = "update";
              temp[index].product.statusUpdate = true;
              await saveProductData();
            }
            await OnTableSelectProductChange();
          }}
        >
          <option key={"Piece"} value={"piece"}>
            {"Piece"}
          </option>
          <option key={"Pack"} value={"pack"}>
            {"Pack"}
          </option>
          <option key={"Ctn"} value={"ctn"}>
            {"Ctn"}
          </option>
          {/* {categoryData.data.map((option, index) => (
                <option
                  key={categoryData.data[index].cat_name}
                  value={categoryData.data[index].cat_id}
                >
                  {categoryData.data[index].cat_name}
                </option>
              ))} */}
        </select>
      </div>
    );

    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          key={selectProductData.data[index].product.product_id}
          id={selectProductData.data[index].product.product_id}
          className=" btn btn-xs "
          onClick={async () => {
            const tempProductData = productData.data;
            const tempSelectProductData = selectProductData.data;

            const findIndex = tempProductData.findIndex(
              (item) =>
                item.product_id === tempSelectProductData[index].product.product_id
            );

            if (findIndex >= 0) {

              const findIndex2 = tempProductData[findIndex].exp_date_list.findIndex(
                (item) =>
                  item.exp === selectProductData.data[index].exp
              );

              if (findIndex2 >= 0) {

                tempProductData[findIndex].exp_date_list[findIndex2].check = false;
                tempProductData[findIndex].exp_date_list[findIndex2].product.status = "del";
              }

            }

            await saveProductData();
          }}
        >
          <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
        </a>
      </div>
    );

    rowsData2.push(rowItem);
  }

  const selectProducTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Customer Code",
        field: "customer_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Sale",
        field: "sale",
        // sort: "asc",
        width: 50,
      },
      {
        label: "EXP. Date",
        field: "exp_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Quantity",
        field: "quantity",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (isDisabled) {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "management";
    });

    selectProducTable.columns.splice(index1, 1);
  }

  async function saveProductData() {
    let findCheckTrue = [];
    let addNew = [];
    let updateOld = [];
    let delOld = [];

    for (let item of productData.data) {

      const inFind = item.exp_date_list.filter(el => el.check === true)
      if (inFind.length > 0) {
        findCheckTrue.push(...inFind);
      }

      const inFindNew = item.exp_date_list.filter(el => el.product.status === "add" && el.product.oldNew === "new")
      if (inFindNew.length > 0) {
        addNew.push(...inFindNew);
      }

      const inFindUpdate = item.exp_date_list.filter(el => el.product.status === "update" && el.product.oldNew === "old")
      if (inFindUpdate.length > 0) {
        updateOld.push(...inFindUpdate);
      }

      const inFindDel = item.exp_date_list.filter(el => el.product.status === "del" && el.product.oldNew === "old");
      if (inFindDel.length > 0) {
        delOld.push(...inFindDel);
      }

    }

    setSelectProductData({
      ...selectProductData,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    selectProductData.data = findCheckTrue;
    selectProductData.newData = addNew;
    selectProductData.updateData = updateOld;
    selectProductData.delData = delOld;

    console.log('selectProductData',
      selectProductData.data,
      selectProductData.newData,
      selectProductData.updateData,
      selectProductData.delData
    );
  }

  async function clearSearch(is_clear) {
    setSearchData({
      ...searchData,
      code: "",
      name: "",
      barcode: "",
      category: "",
      customer_barcode: "",
      customer_product_name: "",
    });

    searchData.code = "";
    searchData.name = "";
    searchData.barcode = "";
    searchData.category = "";
    searchData.customer_barcode = "";
    searchData.customer_product_name = "";
    
    if (is_clear) {
      await resetProduct(true)
    } else {
      await GetProduct("clear");
    }
  }

  const resetProduct = async (is_use) => {
    const arrTemp = productData.data
    arrTemp.forEach(el => {
      el.is_use = is_use
    });
    setProductData({
      ...productData,
      data: arrTemp
    })
    productData.data = arrTemp;
  }

  async function checkErrorSelectProductData() {

    if (selectProductData.data.length > 0) {
      const error_list = [];
      let number = 1;
      let m3Total = 0;
      let weightTotal = 0;

      for (let item of selectProductData.data) {

        if (item.product.quantity) {
          item.product.quantity = typeof item.product.quantity === "string" ? parseFloat(item.product.quantity?.replace(/,/g, "") || 0) : item.product.quantity || 0;

        }

        if (item.product.bar_code === null || item.product.bar_code.trim() === "") {
          let temp_err = {
            message: `Product แถวที่ ${number} กรุณาเลือก Barcode`,
          };

          error_list.push(temp_err);
        }

        if (item.product.customer_code === null || item.product.customer_code.trim() === "") {
          let temp_err = {
            message: `Product แถวที่ ${number} กรุณากรอก Customer Code`,
          };

          error_list.push(temp_err);
        }

        if (item.product.sale_id === null || item.product.sale_id.trim() === "") {
          let temp_err = {
            message: `Product แถวที่ ${number} กรุณาเลือก Sale`,
          };

          error_list.push(temp_err);
        }

        if (!item.product.quantity || item.product.quantity === 0) {
          let temp_err = {
            message: `Product แถวที่ ${number} กรุณากรอก Product Quantity`,
          };

          error_list.push(temp_err);
        }
        if (!item.product.unit) {
          let temp_err = {
            message: `Product แถวที่ ${number} กรุณาเลือก Unit`,
          };

          error_list.push(temp_err);
        }

        if (item.product.quantity) {

          let m3 = ((parseFloat(item.product.detailsAdvance.pc_w) || 1) *
            (parseFloat(item.product.detailsAdvance.pc_h) || 1) *
            (parseFloat(item.product.detailsAdvance.pc_l) || 1)) /
            1000000;

          let sum_unit = 1;
          if (item.product.unit === "ctn" || item.product.unit === "carton") {

            m3 = ((parseFloat(item.product.detailsAdvance.ctn_h) || 1) *
              (parseFloat(item.product.detailsAdvance.ctn_l) || 1) *
              (parseFloat(item.product.detailsAdvance.ctn_w) || 1)) /
              1000000;

            sum_unit = parseFloat(item.product.detailsAdvance.pc_ctn);
          } else if (item.product.unit === "pack") {

            m3 = ((parseFloat(item.product.detailsAdvance.pack_h) || 1) *
              (parseFloat(item.product.detailsAdvance.pack_l) || 1) *
              (parseFloat(item.product.detailsAdvance.pack_w) || 1)) /
              1000000;

            sum_unit = parseFloat(item.product.detailsAdvance.pc_pack);
          }

          let fg_amount = ((item.amount_in || 0) - (item.amount_out || 0) - (item.amount_reserve || 0));
          if ((item.product.quantity * sum_unit) > fg_amount) {
            let temp_err = {
              message: `Product แถวที่ ${number} จำนวน Product Quantity ต้องไม่เกิน FG amount ${fg_amount || 0}`,
            };

            error_list.push(temp_err);
          }

          m3Total += m3 * (parseFloat(item.product.quantity) || 0);
        }

        if (item.product.detailsAdvance.weight && item.product.detailsAdvance.unit_weight) {
          const unit_w = item.product.detailsAdvance.unit_weight
          const _w = parseFloat(item.product.detailsAdvance.weight || 0)
          let unit_sum = 0;
          //2e2e1d28-40c0-41d7-9ec3-220377f75156 lid
          //2e559852-632e-4a6c-bfab-f06536ac6d38 kg
          //d0ee9202-5ce5-48a8-89d8-3b5f76c450eb g
          //d2c26320-f01d-4f2c-a5ec-8019794f9f43 ml
          if (unit_w === "2e2e1d28-40c0-41d7-9ec3-220377f75156") {
            unit_sum = 1
          } else if (unit_w === "2e559852-632e-4a6c-bfab-f06536ac6d38") {
            unit_sum = 1
          } else if (unit_w === "d0ee9202-5ce5-48a8-89d8-3b5f76c450eb") {
            unit_sum = 0.1
          } else if (unit_w === "d2c26320-f01d-4f2c-a5ec-8019794f9f43") {
            unit_sum = 0.1
          }

          weightTotal += (unit_sum * _w);
        }

        number += 1;
      }
      setInputData({
        ...inputData,
        m3: m3Total.toFixed(2),
        weight: weightTotal.toFixed(2)
      })
      inputData.m3 = m3Total.toFixed(2)
      inputData.weight = weightTotal.toFixed(2)

      // if (error_list.length > 0) {
      //   let temp_err = {
      //     message: `- Tab Customer Product -`,
      //   };
      //   error_list.unshift(temp_err);
      // }

      setErrorListTab2({
        ...errorListTab2,
        data: error_list,
      });
      errorListTab2.data = error_list;
    }
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 col-md-3 col-xl-2">
          <button
            hidden={true}
            data-toggle="modal"
            data-target="#modalProduct"
            ref={modalProductRef}
          />
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDisabled}
            onClick={async () => {
              await openModalProduct();
              await clearSearch(true);
            }}
          >
            Select Product
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div class="custom-control custom-switch custom-switch-md">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch8"
              disabled={isDisabled}
              value={inputLang.data}
              checked={inputLang.check}
              onChange={(event) => {
                setInputLang({
                  ...inputLang,
                  data: event.target.checked ? "TH" : "ENG",
                  check: event.target.checked,
                });

                inputLang.data = event.target.checked ? "TH" : "ENG";
                inputLang.check = event.target.checked;
              }}
            />
            <label class="custom-control-label" for="customSwitch8">
              TH
            </label>
          </div>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-12" style={{ overflow: "overlay" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <MDBDataTable
              sortable={false}
              striped
              bordered
              hover
              data={selectProducTable}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProduct"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProduct"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{ width: "70%" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="modalProduct">
                <b>Add Product</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.code}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          code: e.target.value,
                        });
                        searchData.code = e.target.value;
                      }}
                    />
                    <label>Product Code</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          barcode: e.target.value,
                        });
                        searchData.barcode = e.target.value;
                      }}
                    />
                    <label>Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          name: e.target.value,
                        });
                        searchData.name = e.target.value;
                      }}
                    />
                    <label>Product Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      value={searchData.category}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          category: e.target.value,
                        });
                        searchData.category = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select a category
                      </option>
                      {categoryData.data.map((option, index) => (
                        <option
                          key={categoryData.data[index].cat_name}
                          value={categoryData.data[index].cat_id}
                        >
                          {categoryData.data[index].cat_name}
                        </option>
                      ))}
                    </select>
                    <label>Category</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_barcode: e.target.value,
                        });
                        searchData.customer_barcode = e.target.value;
                      }}
                    />
                    <label>Customer Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_product_name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_product_name: e.target.value,
                        });
                        searchData.customer_product_name = e.target.value;
                      }}
                    />
                    <label>Customer Product Name</label>
                  </div>
                </div>

                <div className="col-12"></div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await GetProduct("search");
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await clearSearch();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <div className="col-md-12" style={{ overflow: "overlay" }}>
                  <div
                    style={{
                      height: "450px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MDBDataTable
                      sortable={false}
                      striped
                      bordered
                      hover
                      data={ProductTable}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={async () => {
                  await saveProductData();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customer_Cute_Product;
