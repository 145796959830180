import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

function Pie_Chart({ /* Text, */ DataChart }) {
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: 10,
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 15,
          padding: 15,
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  const plugin = {
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 10;
      };
    },
  };

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <Pie data={DataChart} plugins={[plugin]} options={options} />
      </div>
    </>
  );
}
export default Pie_Chart;
