import React, {useEffect, useState } from "react";
/* import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2"; */


function Test_Entry(params) {

return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <h1>Test_Entry</h1>
                </div>
            </section>
        </div>
      );
}
export default Test_Entry;