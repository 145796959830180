import React, { useState, useEffect } from "react";
import "../assets/css/Header.css";
import Swal from "sweetalert2";
import { Icon_settings, Icon_logout } from "../routes/imgRoute/imgUrl";
import { removeUserSession, getUser, removeOem, setOemlist_, setOemlist, getOem, getToken } from "../Utils/Common";
import PMRP_NoImage from "../assets/img/Landing_Page/PMRP-Noimage.png";
import Configs from "../config";
import axios from "axios";
import { Tooltip } from "antd";
function Header(params) {
  const [user, setUsere] = useState({});
  const [favorite, setFavorite] = useState([]);
  const [stateCheckMenu, setStateCheckMenu] = useState({ menu_fav: false });
  useEffect(() => {
    setUsere(getUser);

    setTimeout(() => {
      const user = sessionStorage.getItem('user');
      if (user) {
        const obj_user = JSON.parse(user);
        if (obj_user.have_menu_favorite) {
          setStateCheckMenu({
            ...stateCheckMenu,
            menu_fav: true
          })
          stateCheckMenu.menu_fav = true;
          getMenuFavorite();
        }
      }
    }, 500)
  }, []);

  // const favorite_mock = [
  //   {
  //     favorite_user_id: 1,
  //     path: "/user/favorite/item1",
  //     display_name: "Favorite Item 1",
  //     image_path: ""
  //   },
  //   {
  //     favorite_user_id: 1,
  //     path: "/user/favorite/item2",
  //     display_name: "Favorite Item 2",
  //     image_path: ""
  //   },
  //   {
  //     favorite_user_id: 1,
  //     path: "/user/favorite/item3",
  //     display_name: "Favorite Item 3",
  //     image_path: null 
  //   },
  //   {
  //     favorite_user_id: 1,
  //     path: "/user/favorite/item4",
  //     display_name: "Favorite Item 4",
  //     image_path: ""
  //   }
  // ];

  // const user_mock = {
  //   fup: 1 
  // };

  const getMenuFavorite = async (user_id) => {
    const oem_id = getOem();
    if (!oem_id) return;
    axios({
      method: "post",
      url: Configs.API_URL + "/api/favorite/menuFavorite",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(async function (response) {
        const sorted = response.data.sort((a, b) => a.favorite_sort - b.favorite_sort);
        setFavorite(sorted);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const handleLogout = () => {
    /*    console.log(user.sub);*/
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to log out?",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log Out!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeUserSession();
        removeOem();
        window.sessionStorage.removeItem("Noti")
        window.sessionStorage.removeItem("Noti_ticket")
        window.sessionStorage.removeItem("Noti_Favorites");
        window.sessionStorage.removeItem("Noti_RulesTTT");

        Swal.fire({
          icon: "success",
          title: "Sigh out success",
          showConfirmButton: false,
          timer: 1500,
        }).then((results) => {
          window.location.href = "/login";
        });
      }
    });
  };
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <a
              href="/Welcome"
              className="nav-link"
              style={{ fontWeight: "bold" }}
            >
              Switch OEM
            </a>
          </li>
        </ul>
        <div className="row"
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'scroll',
            position: 'relative',
            marginLeft: 'auto',
            marginRight: '20px',
            minWidth: '50px',
            // justifyContent: 'flex-end',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            maxWidth: '240px',
          }}
        >
          {stateCheckMenu.menu_fav && favorite.map((b) => {
            return (
              user.fup === b.favorite_user_id && (
                <div
                  className="col-12 col-md-4 col-xl-3"
                  style={{
                    flex: '0 0 auto',
                    padding: '0rem',
                    minWidth: '60px',
                    maxWidth: '60px',
                    flexShrink: 0,
                  }}
                  key={b.favorite_user_id}
                >
                  <div key={b.favorite_user_id}>
                    <Tooltip
                      title={`${b.display_name}`}
                      placement="bottom"
                    >
                      <a
                        href={"/" + b.path.split("/").reverse().join("/")}
                      >
                        <div style={{ textAlign: "center" }}>
                          <img
                            alt="..."
                            className="img-fluid oem-1"
                            style={{
                              width: '40px',
                              height: '33px',
                              padding: '4px',
                              margin: '0',
                              borderRadius: '0',
                            }}
                            src={
                              b.image_path !== null &&
                                b.image_path !== "" &&
                                b.image_path !== undefined
                                ? (b.image_path.startsWith('data:image')
                                  ? b.image_path
                                  : Configs.API_URL_IMG + b.image_path)
                                : PMRP_NoImage
                            }
                          />
                        </div>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              )
            );
          })}
        </div>

        <ul
          className="navbar-nav logout-size"
          style={{ textAlign: "center", marginLeft: 0 }}
        >
          <img
            alt="User Avatar"
            className="img-fluid rounded img-size-50 "
            src={Icon_settings.imgs}
            style={{ width: "25px", height: "25px", paddingRight: "2px" }}
          />{" "}
          {/* Khun Teewin*/} {user.sub}
          <div className="log-out-line"></div>
          {/*         <a className="btn logout-btn"  value="Logout"> */}
          <li class="nav-item dropdown show">
            <a
              class="nav-link"
              data-toggle="dropdown"
              href="#"
              aria-expanded="true"
            >
              <img
                alt="User Avatar"
                className="img-fluid rounded img-size-50 "
                src={Icon_logout.imgs}
                style={{ width: "20px", height: "20px" }}
                //onClick={handleLogout}
                value="Logout"
              />
            </a>
            <div
              class="dropdown-menu dropdown-menu-md dropdown-menu-center"
              style={{ left: "-120px" }}
            >
              <a href="/Profile" class="dropdown-item">
                <i class="fa fa-user mr-2"></i>
                Profile
              </a>
              <div class="dropdown-divider"></div>
              <a style={{ cursor: 'pointer' }} class="dropdown-item" onClick={handleLogout}>
                <i class="fas fa-sign-out-alt mr-2"></i>
                {/* <i class="fas fa-users"></i> */}Log out
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default Header;
