/**
* ชื่อไฟล์: connectTable.js
* คําอธิบาย: ตารางของ Power Monitor Connection
* Input: -
* Output: ข้อมูลของมอนิเตอร์
* ชื่อผู้เขียน/แก้ไข: ณัฐวุฒิ สมดุลยกนก
* วันที่จัดทํา/แก้ไข: 12 กันยายน 2566
*/

import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "./table.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the default styles
import { CSVLink } from 'react-csv';
import eye from '../../assets/img/Dashboard/eye.svg';
import edit from '../../assets/img/Dashboard/edit.svg';
import deletes from '../../assets/img/Dashboard/delete.svg';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
  getUser,
  getToken,
  setOemlist,
  getOem,
  getComConfig,
} from "../../Utils/Common";
import Configs from "../../config";
import axios from "axios";
import { Card } from "antd";

const styleShowEntires = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  marginBottom: "1rem"
}
function ConnectTable(params) {
    const rowsData = params.data.rows;
    const history = useHistory();
    const arr = rowsData.map(row => row.ims_name);
    // console.log("🚀 ~ file: connectTable.js:27 ~ ConnectTable ~ arr:", arr)

    const [searchID, setSearchID] = useState("");
    const [searchName, setSearchName] = useState("");
    const [searchDate, setSearchDate] = useState("");
    const [globalSearch, setGlobalSearch] = useState("");

    const [this_page, setthis_page] = useState({ page: 1 });
    const [show_entries, setshow_entries] = useState({ entries: 10 });
    const [all_page, setall_page] = useState([]);
    const [formGroupZIndex, setFormGroupZIndex] = useState(10000);
  
    // Add these states to your component
    const [filteredData, setFilteredData] = useState(rowsData);

    const navigateToViewPage = (rowData) => {
      history.push('/IoT_Master_Data/PowerMonitorConnection/viewMonitor', {
        rowData,
      })
    }

    const navigateToEditPage = (rowData) => {
      history.push('/IoT_Master_Data/PowerMonitorConnection/editMonitor', {
        rowData,
        imsMonitorIds: rowsData.map(row => row.ims_monitor_id),
        imsMonitorName: rowsData.map(row => row.ims_name),
      });
    }

    const navigateToAddPage = () => {
      history.push('/IoT_Master_Data/PowerMonitorConnection/addMonitor', {
        imsMonitorIds: rowsData.map(row => row.ims_monitor_id),
        imsMonitorName: rowsData.map(row => row.ims_name),
      });
    };
  
    const prepareDataForCSV = () => {
      const csvData = filteredData.map((el) => ({
        'Monitor Name': el.monitor_name,
        'Monitor ID': el.monitor_id,
        'Frequency API (minute)': el.frequency_api,
        'Power (W)': el.power,
        'Energy (kWh)': el.energy,
        'Voltage (V)': el.voltage,
        'Current (A)': el.current,
        'Frequency (Hz)': el.frequency,
        'Add Date': el.date,
      }));
    
      return csvData;
    };
  
    // Modify the search criteria handling
    // useEffect(() => {
    //   const filteredRows = rowsData.filter(
    //     (el) =>
    //       el.Part_Name.toLowerCase().includes(searchID.toLowerCase()) &&
    //       el.Part_No.toLowerCase().includes(searchName.toLowerCase())
    //   );
    //   setFilteredData(filteredRows);
    // }, [searchID, searchName, rowsData]);

    const handleSearchClick = () => {
      // Create a copy of the filtered data to apply new filters
      let filteredRows = [...rowsData];
    
      // Apply the search criteria on the copy of filtered data
      if (searchID?.trim()) {
        filteredRows = filteredRows.filter((el) =>
          el.ims_monitor_id.toString().toLowerCase().includes(searchID?.trim().toLowerCase())
        );
      }
    
      if (searchName?.trim()) {
        filteredRows = filteredRows.filter((el) =>
          el.ims_name.toLowerCase().includes(searchName?.trim().toLowerCase())
        );
      }
    
      if (searchDate) {
        const formattedDate = new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).format(searchDate);
        filteredRows = filteredRows.filter((el) =>
          el.to_char.toLowerCase().includes(formattedDate.toLowerCase())
        );
      }
    
      // Set the filtered data based on the applied criteria
      setFilteredData(filteredRows);
    };

    useEffect(() => {
      // Filter the data based on the global search
      const globalFilteredRows = rowsData.filter((el) => {

        let matchGlobalName = true;
        let matchGlobalID = true;
        let matchGlobalDate = true;
        if (globalSearch) {
          matchGlobalName = el.ims_name.toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          matchGlobalID = el.ims_monitor_id.toString().toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          matchGlobalDate = el.to_char.toLowerCase().includes(globalSearch.toLowerCase());
        }

        return matchGlobalName || matchGlobalID || matchGlobalDate;
      });
    
      // Filter the data based on other filters

        setFilteredData(globalFilteredRows);
    }, [globalSearch, rowsData]);
  
    const clearSearchData = () => {
      setSearchID("");
      setSearchName("");
      setSearchDate("");
      setPage(1); // Reset the page to 1
      setshow_entries({ entries: 10 }); // Reset the entries to default
      setFilteredData(rowsData); // Reset the filtered data to the original data
    }
  
    var BN = 0;
    var FN = 0;
  
    function setPage(page) {
      /*  console.log("this page : ", page); */
      setthis_page({ ...this_page, page: page });
    }
  
    function previous_page() {
      let page_ = this_page.page - 1;
      if (page_ >= 1) {
        setthis_page({ ...this_page, page: page_ });
      }
    }
  
    function next_page() {
      let page_ = this_page.page + 1;
      if (page_ <= totalPageCount) {
        setthis_page({ ...this_page, page: page_ });
      }
    }
  
    function performSearch(event) {
      event.preventDefault(); // Prevent default form submission behavior
      setPage(1); // Reset to the first page
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(searchDate);
      setFilteredData(
        rowsData.filter(
          (el) =>
          el.monitor_id.toLowerCase().includes(searchID.toLowerCase()) &&
          el.monitor_name.toLowerCase().includes(searchName.toLowerCase()) &&
          el.date.includes(formattedDate)
        )
      );
    }  

    const handleDelete = (rootId) => {
      var config_delete = {
        method: "put",
        url: `${Configs.API_URL_IoT_Connect}/powerMonitorConnection/delete`, // Make sure this URL matches your server endpoint
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: {
          monitorRoot : rootId
        }, // Include the monitorData in the request body
      };

      // Show a confirmation dialog using SweetAlert
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        // console.log(config_delete);
        if (result.isConfirmed) {
          // Delete the row from filteredData
          const updatedData = filteredData.filter((el) => el.ims_id !== rootId);
        
          axios(config_delete);

          // console.log(el.ims_id);
          setFilteredData(updatedData);

          setFormGroupZIndex(-1);
  
          Swal.fire(
            'Deleted!',
            'The record has been deleted.',
            'success'
          );
        }
      });
    };
    
  
    async function setEntries(entries) {
      await setshow_entries({ ...show_entries, entries: entries });
      var page_arr = [];
      let page = 1;
  
      console.log("showAllPage", rowsData.length);
      for (let i = 0; i < rowsData.length; i++) {
        if (Math.ceil(i / entries) >= page) {
          console.log(Math.ceil(i / entries), page);
          let temp = {
            page: page,
          };
          page_arr.push(temp);
          page++;
        }
      }
      await setall_page(page_arr);
      await setPage(1);
    }

    const Custom_Input_date = ({ value, onClick, isDis }) => (
      <div className="input-group">
        <input
          disabled={isDis}
          type="text"
          className="form-control float-left"
          onClick={onClick}
          value={value}
        />{" "}
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="far fa-calendar-alt" />
          </span>
        </div>
      </div>
    );
  
  const totalPageCount = Math.ceil(filteredData.length / show_entries.entries);
  
  return (
    <div>
      <div className="row" style={{ alignItems: 'center' }}>
        <div className="col-12 col-md-3 col-xl-2">
            <div className="form-group ">
                <input
                    type="text"
                    className="form-control"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                />
                <label htmlFor="">Monitor Name</label>{" "}
            </div>
        </div>
        <div className="col-12 col-md-3 col-xl-2">
            <div className="form-group ">
                <input
                    type="text"
                    className="form-control"
                    value={searchID}
                    onChange={(e) => setSearchID(e.target.value)}
                />
                <label htmlFor="">Monitor ID</label>{" "}
            </div>
        </div>
        <div className="col-12 col-md-3 col-xl-2">
          <div className="form-group ">
            <DatePicker
              dateFormat="dd/MM/yyyy" // Set the desired date format
              popperPlacement="bottom"
              wrapperClassName="w-100"
              popperModifiers={{
                flip: {
                  behavior: ["bottom"], // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false, // turn off since needs preventOverflow to be enabled
                },
              }}
              selected={searchDate}
              onChange={(date) => setSearchDate(date)}
              // selectsStart
              // startDate={quotation_data.quo_date}
              customInput={<Custom_Input_date isDis={false} />}
            />
            <label htmlFor="">
              Date
            </label>
          </div>
        </div>
        <div className="col-6 col-md-3 col-xl-2">
          <div className="form-group ">
              <button
                  type="button"
                  className="btn btn-block btn-info  "
                  onClick={handleSearchClick}
              >
                  Search
              </button>
          </div>
      </div>

      <div className="col-6 col-md-3 col-xl-2">
          <div className="form-group ">
              <button
                  type="button"
                  className="btn btn-block btn-info"
                  onClick={clearSearchData}
              >
                  Clear
              </button>
          </div>
      </div>
    </div>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-3 col-xl-2">
          <div className="form-group ">
              <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={() => navigateToAddPage()}
              >
                  Add
              </button>
          </div>
      </div>
    </div>
      {/* <div class="col-10">
        <div className="label-filter">
          <label>Monitor Name</label>
          <label>Monitor ID</label>
          <label className="label-date">Date</label>
        </div>
        <div class="form-group">
            <form onSubmit={performSearch}>
              <div className="filter">
              <input
              type="text"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              />
            <input
              type="text"
              value={searchID}
              onChange={(e) => setSearchID(e.target.value)}
            />  
            <DatePicker   
              className="date-picker"
              dateFormat="dd/MM/yyyy" // Set the desired date format
              selected={searchDate}
              onChange={(date) => setSearchDate(date)}
            />  
            <button
              type="button" // This will trigger the form submission
              className="btn btn-primary"
              onClick={handleSearchClick}
            >
              Search
            </button>

            <button
              type="button" // This will trigger the form submission
              className="btn btn-primary"
              onClick={clearSearchData}
            >
              Clear
            </button>
              </div>
                
            </form>

            <button className="btn add" onClick={() => navigateToAddPage()}>
              Add
            </button>
      
        </div>
      </div> */}
      <Card>
      <div className="table-frame">
      <div className="selected-entries mt-[10px]" style={styleShowEntires}>
          <div className="entries">
            <label htmlFor="">Show entries</label><br/>
            <select
                className="form-control custom-select select2"
                type="text"
                required
                onChange={(e) => setEntries(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={rowsData.length}>{rowsData.length} (ALL)</option>
            </select>
          </div>
          
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <form>
            <input
              style={{ marginRight: '1.5rem'}}
              type="text"
              className="form-control"
              placeholder="Search..."
              value={globalSearch}
              onChange={(e) => setGlobalSearch(e.target.value)}
            />
            </form>
            </div>
        </div>
        

      <div className="table-responsive">
        <MDBTable
          className="table"
          striped
          bordered
          hover
          // fixedHeader
        >
          <MDBTableHead>
            <tr>
              <th style={{ textAlign: "center"}}>No</th>
              <th style={{ textAlign: "center"}}>Monitor Name</th>
              <th style={{ textAlign: "center"}}>Monitor ID</th>
              <th style={{ textAlign: "center"}}>Frequency API</th>
              <th style={{ textAlign: "center"}}>Status</th>
              <th style={{ textAlign: "center"}}>Added Date</th>
              <th style={{ textAlign: "center"}}>Actions</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {filteredData.slice((this_page.page - 1) * show_entries.entries, this_page.page * show_entries.entries)
              .map((el, index) => {
                const startIndex = (this_page.page - 1) * show_entries.entries;
                const endIndex = Math.min(startIndex + show_entries.entries, filteredData.length);
                BN = startIndex + 1;
                FN = endIndex;
                const dataIndex = (this_page.page - 1) * show_entries.entries + index;
                return (
                  <tr key={dataIndex}>
                    <td style={{ textAlign: "center" }}>{dataIndex + 1}</td>
                    <td style={{ textAlign: "center" }}>{el.ims_name}</td>
                    <td style={{ textAlign: "center" }}>{el.ims_monitor_id}</td>
                    <td style={{ textAlign: "center" }}>{el.ims_frequency_api}</td>
                    <td className="status-cell" style={{ textAlign: "center" }}>
                      {el.ims_status === true ? <span style={{ padding: "0px 10px", borderRadius: "100%", backgroundColor: "green"}} className="green-circle" /> : <span className="red-circle" style={{padding: "0px 10px", borderRadius: "100%", backgroundColor: "red"}} />}
                      {el.ims_status === true ? "online" : "offline"}
                    </td>
                    <td style={{ textAlign: "center" }}>{el.to_char}</td>
                    <td className="action" style={{ textAlign: "center", gap: "10px", display: "flex", justifyContent: "center" }}>
                      <a href="/IoT_Master_Data/PowerMonitorConnection/viewMonitor" value="">
                        <img src={eye} onClick={() => navigateToViewPage(el)}/>
                      </a>
                      <a href="/IoT_Master_Data/PowerMonitorConnection/editMonitor">
                        <img src={edit} onClick={() => navigateToEditPage(el)}/>
                      </a>
                       <img
                          src={deletes}
                          alt="Delete"
                          className="delete-icon"
                          onClick={() => handleDelete(el.ims_id)}
                        />
                    </td>
  
                  </tr>
                );
              })}
          </MDBTableBody>
          <MDBTableHead>
            <tr>
              <th style={{ textAlign: "center"}}>No</th>
              <th style={{ textAlign: "center"}}>Monitor Name</th>
              <th style={{ textAlign: "center"}}>Monitor ID</th>
              <th style={{ textAlign: "center"}}>Frequency API</th>
              <th style={{ textAlign: "center"}}>Status</th>
              <th style={{ textAlign: "center"}}>Added Date</th>
              <th style={{ textAlign: "center"}}>Actions</th>
            </tr>
          </MDBTableHead>
        </MDBTable>
      </div>

      <div className="row">
        <div className="col-5">
          <p>Showing {BN} to {FN} of {filteredData.length} entries</p>
        </div>
        <div className="col-0.5">
          <button
            type="button"
            className="btn btn-block btn-outline-primary border"
            onClick={previous_page}
            disabled={this_page.page === 1}
          >
            Previous
          </button>
        </div>
  
        {Array.from({ length: totalPageCount }, (_, index) => {
          const pageNumber = index + 1;
          const isActive = pageNumber === this_page.page;

          return (
            <div className="col-0.5" key={index}>
              <button
                type="button"
                className={`btn btn-block ${
                  isActive ? "btn-primary" : "btn-outline-primary"
                } border`}
                onClick={() => setthis_page({ ...this_page, page: pageNumber })}
              >
                {pageNumber}
              </button>
            </div>
          );
        })}
        <div className="col-0.5">
          <button
            type="button"
            className="btn btn-block btn-outline-primary border"
            onClick={next_page}
            disabled={this_page.page === totalPageCount}
          >
            Next
          </button>
        </div>
      </div>
      </div>
      </Card>

      
    </div>
  );
}

export default ConnectTable;