import React, { Component, useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../../../config";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../../../../Utils/Common";
import { useParams } from "react-router";
import { userdefault_img } from "../../../../../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import {
  getDateCompo,
  upload_fileToDBBase,
  getDropdown,
  isFileImage,
  product_variable,
  DownloadFile,
} from "../../../../../../Utils/Employee_history";

function Advance_Data({
  id,
  mode,
  isDisabled,
  Is_Product_Kitting,
  inputData,
  setInputData,
  errorList,
  setErrorList,
  functionSave,
  setFunctionSave,
}) {
  // const { id } = useParams();
  const [disable, setdisable] = useState(null);
  const [documentFile, setdocumentFile] = useState([]);
  const [imageUseEffect, setImageUseEffect] = useState([]);
  const [product_data, setproduct_data] = useState(product_variable);
  const [productFile, setProductFile] = useState({
    file_name_flow: "",
    file_path_flow: "",
    file_name_ingre: "",
    file_path_ingre: "",
  });
  
  const [p_advance_upload_files, setP_advance_upload_files] = useState({
    data: [],
    new: [],
    old: [],
    del: [],
  });
  const tabData = {
    product_data: product_data,
    documentFile: documentFile,
    productFile: productFile,
    p_advance_upload_files: p_advance_upload_files
  };

  const [dropDownData, setDropDownData] = useState({
    unit: [],
    license: [],
    product_unit: [],
    currency: [],
    form_type: [],
    container: [],
  });

  const [test, setTest] = useState({
    test: "",
  });

  const [seeFileImage, setSeeFileImage] = useState({
    product_image_name: "",
    product_image_path: "",
    product_image: "",
  });

  const allowedTypes = ["image/png", "image/jpeg"];
  const allowedTypes2 = [
    "application/pdf",
  ];
  /* 
      "text/csv",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  */
  useEffect(async () => {
    setInputData({
      ...inputData,
      data: tabData,
    });

    setFunctionSave({
      ...functionSave,
      data: checkError,
      data2: saveOption,
    });
  }, [product_data, productFile, documentFile, p_advance_upload_files]);

  useEffect(async () => {
    // console.log("useEffect mode");
    if (mode !== "add") {
      setdisable(mode === "edit" ? false : true);
      await getDataAdvance();
      await getImageAdvance();
    } else {
      setdisable(false);
    }
    const unitDD = []; // await getDropdown("unit");
    const licenseDD = await getDropdown("license");
    const product_unitDD = await getDropdown("product_unit");
    const currencyDD = await getDropdown("currency");
    const formTypeDD = await getDropdown("formType");
    const containerDD = await getDropdown("container");
    // console.log('licenseDD', licenseDD);
    setDropDownData({
      ...dropDownData,
      unit: unitDD,
      license: licenseDD,
      product_unit: product_unitDD,
      currency: currencyDD,
      form_type: formTypeDD,
      container: containerDD,
    });
    dropDownData.unit = unitDD;
    dropDownData.license = licenseDD;
    dropDownData.product_unit = product_unitDD;
    dropDownData.currency = currencyDD;
    dropDownData.form_type = formTypeDD;
    dropDownData.container = containerDD;
  }, []);


  useEffect(async () => {
    if (documentFile.length > 0) {
      const doc = documentFile?.find((el) => el.is_checked === true);
      const docIdx = documentFile?.findIndex((el) => el.is_checked === true);
      if (docIdx >= 0) {
        // console.log('docIdx', docIdx);
        await checkedSelectBox(docIdx);
      }
      if (doc) {
        setSeeFileImage({
          product_image_name: doc.sbd_document_name,
          product_image_path: doc.sbd_path_document,
          product_image: doc.sbd_path_document,
        });
        seeFileImage.product_image_name = doc.sbd_document_name;
        seeFileImage.product_image_path = doc.sbd_path_document;
        seeFileImage.product_image = doc.sbd_path_document;
      }
    }
    // console.log("seeFileImage", seeFileImage);
  }, [documentFile]);

  async function saveProductAdvanceFiles() {
    const findCheckTrue = p_advance_upload_files.data.filter((item) => {
      return item.status !== "del"
    });

    const addNew = p_advance_upload_files.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const delOld = p_advance_upload_files.del.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setP_advance_upload_files({
      ...p_advance_upload_files,
      data: findCheckTrue,
      new: addNew,
      del: delOld,
    });

    p_advance_upload_files.data = findCheckTrue;
    p_advance_upload_files.new = addNew;
    p_advance_upload_files.del = delOld;

    // console.log(
    //   p_advance_upload_files.data,
    //   p_advance_upload_files.new,
    //   p_advance_upload_files.del
    // );
  }

  const upload_file = async (e) => {
    // console.log("filname", e.target.files);
    if (allowedTypes2.includes(e.target.files[0]?.type) === false) {
      Swal.fire(
        "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
        "กรุณาอัปโหลดประเภทไฟล์ PDF",
        "error"
      );
      return;
    }
    if (e.target.files[0]) {
      const file = e.target.files[0];
      // console.log("ss", file);
      var data = new FormData();
      data.append("Profile", file);
      data.append("ProfileName", file.name);
      data.append("typeFile", `Advance_Document`);
      //data.append("Profile", file);
      //data.append("ProfileName", inputData.File_Name);
      //data.append("typeFile", `Certificate_Document`);

      if (true) {
        /*  console.log("filre",response.data); */
        if (e.target.id == "file_flow") {
          // console.log("e.target.id", e.target.id);
          setProductFile({
            ...productFile,
            file_name_flow: e.target.files[0].name,
            file_path_flow: e.target.files[0],
            /* image: response.data.data, */
          });
        } else {
          setProductFile({
            ...productFile,
            file_name_ingre: e.target.files[0].name,
            file_path_ingre: e.target.files[0],
            /* image: response.data.data, */
          });
        }
      }
      Swal.fire({
        icon: "success",
        title: "อัปโหลดไฟล์สำเร็จ",
        showConfirmButton: false,
        timer: 1500,
      });

      //  }
    }
  };

  async function encodeImage(file) {
    // console.log("file", file);
    if (allowedTypes.includes(file[0]?.type) === false) {
      Swal.fire(
        "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
        "กรุณาอัปโหลดประเภทไฟล์ PNG, JPEG",
        "error"
      );
      return;
    }
    if (file[0].name) {
      const regex = /([\u0E00-\u0E7Fก-๙]+)/gmu;
      const str = file[0].name;
      const m = regex.exec(str);

      if (isFileImage(file[0]) === false) {
        Swal.fire({
          title: "ประเภทไฟล์ไม่ถูกต้อง",
          text: "",
          icon: "warning",
          confirmButtonColor: "#032973",
          confirmButtonText: "ตกลง",
        });
      } else {
        const oleTempFileUpload = documentFile;
        setdocumentFile([]);

        const tempFileUpload = [];
        if (file.length > 0) {
          for (let index = 0; index < file.length; index += 1) {
            const tempDataImg = {
              sbd_document_name: file[index].name,
              sbd_path_document: "",
              sbd_image: file[index],
              file_isOld: false,
              oldNew: "new",
              status: "add"
            };
            /* console.log(
              "URL.createObjectURL(file[index]",
              URL.createObjectURL(file[index])
            ); */
            if (true) {
              // console.log('response.data.data', response.data.data);
              tempDataImg.sbd_path_document = URL.createObjectURL(file[index]);
              tempDataImg.is_checked = false;
              // tempDataImg.sbd_name_document = response.data.data.orgin_name
              if (
                product_data.product_image_path === "" ||
                product_data.product_image_path === null ||
                product_data.product_image_path === undefined
              ) {
                tempDataImg.is_checked = true;
                setproduct_data({
                  ...product_data,
                  product_image_name: tempDataImg.sbd_document_name,
                  product_image_path: URL.createObjectURL(file[index]),
                  product_image: file[index],
                });
                product_data.product_image_name = tempDataImg.sbd_document_name;
                product_data.product_image_path = URL.createObjectURL(
                  file[index]
                );
                setSeeFileImage({
                  ...seeFileImage,
                  product_image_name: tempDataImg.sbd_document_name,
                  product_image_path: URL.createObjectURL(file[index]),
                  product_image: file[index],
                });
                seeFileImage.product_image_name = tempDataImg.sbd_document_name;
                seeFileImage.product_image_path = URL.createObjectURL(
                  file[index]
                );
                seeFileImage.product_image = file[index];
              }
            }

            tempFileUpload.push(tempDataImg);
          }
        }

        oleTempFileUpload.forEach((el) => {
          tempFileUpload.push(el);
        });
        // console.log('before--');
        setP_advance_upload_files({
          ...p_advance_upload_files,
          data: tempFileUpload,
          new: tempFileUpload
        });
        p_advance_upload_files.data = tempFileUpload;
        p_advance_upload_files.new = tempFileUpload;
        await saveProductAdvanceFiles();

        // console.log('tempFileUpload', tempFileUpload[0],tempFileUpload[0].sbd_document_name, tempFileUpload[0].sbd_path_document);
        setdocumentFile(tempFileUpload);
        setTest({ ...test, test: "" });
      }
    }
  }

  const clickToSeeImage = async (index) => {
    const oleTempFileUpload = documentFile;
    setSeeFileImage({
      ...seeFileImage,
      product_image_name: oleTempFileUpload[index].sbd_document_name,
      product_image_path: oleTempFileUpload[index].sbd_path_document,
      product_image: oleTempFileUpload[index],
    });
    seeFileImage.product_image_name =
      oleTempFileUpload[index].sbd_document_name;
    seeFileImage.product_image_path =
      oleTempFileUpload[index].sbd_path_document;
    seeFileImage.product_image = oleTempFileUpload[index];
    setdocumentFile([]);
    setdocumentFile(oleTempFileUpload);
    setTest({ ...test, test: "" });
  };

  async function checkedSelectBox(index) {
    // console.log("index", documentFile);
    const oleTempFileUpload = documentFile;
    setdocumentFile([]);
    oleTempFileUpload.map((el) => (el.is_checked = false));
    oleTempFileUpload[index].is_checked = true;
    setproduct_data({
      ...product_data,
      product_image_name: oleTempFileUpload[index].sbd_document_name,
      product_image_path: oleTempFileUpload[index].sbd_path_document,
      product_image: oleTempFileUpload[index],
    });
    setSeeFileImage({
      ...seeFileImage,
      product_image_name: oleTempFileUpload[index].sbd_document_name,
      product_image_path: oleTempFileUpload[index].sbd_path_document,
      product_image: oleTempFileUpload[index],
    });
    seeFileImage.product_image_name =
      oleTempFileUpload[index].sbd_document_name;
    seeFileImage.product_image_path =
      oleTempFileUpload[index].sbd_path_document;
    seeFileImage.product_image = oleTempFileUpload[index];

    product_data.product_image_name =
      oleTempFileUpload[index].sbd_document_name;
    product_data.product_image_path =
      oleTempFileUpload[index].sbd_path_document;
    product_data.product_image = oleTempFileUpload[index];
    setdocumentFile(oleTempFileUpload);
    setTest({ ...test, test: "" });
  }

  async function delDocList(index) {
    // console.log("index", index);
    const oleTempFileUpload = documentFile;
    setdocumentFile([]);
    const check = oleTempFileUpload.splice(index, 1);
    check.forEach((el) => {
      if (el.sbd_path_document === product_data.product_image_path) {
        setproduct_data({
          ...product_data,
          product_image_name: "",
          product_image_path: "",
          product_image: "",
        });
        product_data.product_image_name = "";
        product_data.product_image_path = "";
        product_data.product_image = "";
        setSeeFileImage({
          ...seeFileImage,
          product_image_name: "",
          product_image_path: "",
          product_image: "",
        });
        seeFileImage.product_image_name = "";
        seeFileImage.product_image_path = "";
        seeFileImage.product_image = "";
      }
    });
    // console.log("check", check);

    if (documentFile.length === 0) {
      setSeeFileImage({
        ...seeFileImage,
        product_image_name: "",
        product_image_path: "",
        product_image: "",
      });
      seeFileImage.product_image_name = "";
      seeFileImage.product_image_path = "";
      seeFileImage.product_image = "";
    }
    if (oleTempFileUpload.length !== 0) {
      setdocumentFile(oleTempFileUpload);
      setTest({ ...test, test: "" });
    }
  }

  async function encodeImageToDBBase(documentFile) {
    const tempFileUpload = [];
    // console.log('documentFile', documentFile);
    const temp = new Promise(async (resolve, reject) => {
      try {
        if (documentFile.length > 0) {
          for (let index = 0; index < documentFile.length; index += 1) {
            if (documentFile[index].ul_id) {
              tempFileUpload.push(documentFile[index]);
              continue;
            }
            const data = new FormData();
            data.append("company_id", getUser().com);
            data.append("oem_id", getOem());
            data.append("user_id", getUser().fup);
            data.append(
              "user_name",
              `${getUser().name} ${getUser().surname}`
            );
            data.append("Project", "Cute_Products_Plus");
            data.append("Menu", "Product");
            data.append("Ref", null);
            data.append("Profile", documentFile[index].sbd_image);
            data.append("ProfileName", documentFile[index].sbd_document_name);

            await axios({
              method: "post",
              url: Configs.API_URL_IMG_cuteplus + "/api/upload/uploadFile",
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
              },
              data: data,
            })
              .then(function (response) {
                if (response.data) {
                  // console.log('response.data.data', response.data.data);
                  documentFile[index].sbd_path_document =
                    response.data[0].path;
                  tempFileUpload.push(documentFile[index]);
                } else {
                  Swal.fire("Error", "File name !!!", "error");
                  reject(null);
                }
              })
              .catch(function (error) {
                console.log(error);
                reject(null);
              });
          }
        }
        setdocumentFile([]);
        setdocumentFile(tempFileUpload);
        setP_advance_upload_files({
          ...p_advance_upload_files,
          data: tempFileUpload
        });
        p_advance_upload_files.data = tempFileUpload;
        await saveProductAdvanceFiles();
        setTest({ ...test, test: "" });
        test.test = "";
        resolve(documentFile);
      } catch (error) {
        console.log("error", error);
        reject(error);
      }
    });

    // console.log('documentFile------', temp)
  }

  const saveOption = async () => {
    const err = []; //checkError(product_data);
    if (err.length > 0) {
      var err_message = "";
      for (var e = 0; e < err.length; e++) {
        err_message += "<br/>" + err[e].message;
      }
      Swal.fire("บันทึกข้อมูลไม่สำเร็จ", err_message, "error");
      return;
    } else {
      await encodeImageToDBBase(documentFile);
      const temp1 = productFile;
      // console.log("temp1", typeof temp1.file_path_flow === "object");

      // let flow = temp1.file_path_flow?.indexOf('blob');
      if (typeof temp1.file_path_flow === "object") {
        await upload_fileToDBBase(temp1.file_path_flow).then((res) => {
          // console.log("pdfPathFlow", res);
          setProductFile({
            ...productFile,
            file_path_flow: res,
          });
          productFile.file_path_flow = res || "";
        });
      }
      // let inq = temp1.file_path_ingre?.indexOf('blob');
      if (typeof temp1.file_path_ingre === "object") {
        await upload_fileToDBBase(temp1.file_path_ingre).then((res) => {
          // console.log("pdfPathFlow", res);
          setProductFile({
            ...productFile,
            file_path_ingre: res || "",
          });
          productFile.file_path_ingre = res || "";
        });
      }
      if (mode === "add") {
        /* const temp1 = productFile;
                await upload_fileToDBBase(temp1.file_path_flow).then((res) => {
                    console.log('pdfPathFlow', res);
                    setProductFile({
                        ...productFile,
                        file_path_flow: res,
                    });
                    productFile.file_path_flow = res || '';
                })
                await upload_fileToDBBase(temp1.file_path_ingre).then((res) => {
                    console.log('pdfPathFlow', res);
                    setProductFile({
                        ...productFile,
                        file_path_ingre: res || '',
                    });
                    productFile.file_path_ingre = res || '';
                }) */

        setTest({ ...test, test: "" });
        test.test = "";
        // await getDateCompo(product_data, productFile, documentFile, '/saveTestAdvance');
      } else if (mode === "edit") {
        //pci_id

        setTest({ ...test, test: "" });
        test.test = "";
        // await getDateCompo(product_data, productFile, documentFile, '/updateTestAdvance');
      }
    }
  };

  const getImageAdvance = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileGroupAll/" + id + "/imgs_product_advance",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData1,
    })
      .then(async (response) => {
        // console.log(response.data);
        if (response.data.length > 0) {
          const file_list = response.data?.map((el, idx) => {
            if (el.ul_is_profile === true) {
              /* setproduct_data({
                ...product_data,
                product_image_name: el.ul_file_name,
                product_image_path: el.ul_path_file,
                product_image: el,
              });
              product_data.product_image_name = el.ul_file_name;
              product_data.product_image_path = el.ul_path_file;
              product_data.product_image = el;
              setTest({ ...test, test: ""});
              test.test = ""; */
            }
            return {
              ul_id: el.ul_id,
              is_checked: el.ul_is_profile,
              sbd_document_name: el.ul_file_name,
              sbd_path_document: el.ul_path_file,
              oldNew: "old",
            }
          })
          setdocumentFile(file_list);
          setP_advance_upload_files({
            ...p_advance_upload_files,
            data: file_list
          });
          p_advance_upload_files.data = file_list;
          await saveProductAdvanceFiles();
          setTest({ ...test, test: "" });
          test.test = "";
          // console.log('file_list', file_list);
        }
      })
      .catch(async (error) => {
        console.log('error', error);
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถอัปโหลดเอกสารได้",
        });
      });
  }

  const getDataAdvance = async () => {
    /* Swal.fire({
            title: "loading...",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            didOpen: () => {
                Swal.showLoading();
            }
        }).then(async () => { */
    let pushData = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      pc_id_main: id,
    };
    // console.log("pushData", pushData);
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getTestAdvance",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        // console.log("response", response);
        if (!response.data.product_av.id) {
          setproduct_data(product_variable);
        } else {
          // console.log('check', response.data.product_av);
          let temp = { ...response.data.product_av }
          setproduct_data(temp);
          setTest({ ...test, test: "" });
          test.test = "";
          // console.log('product_data', product_data);
        }
        if (response.data) {
          setdocumentFile(response.data.product_img);
          setProductFile(response.data.product_pdf);
          setTest({ ...test, test: "" });
          test.test = "";
        }
        // Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        // Swal.close();
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
    /* }); */
  };

  const checkError = async () => {
    const error_list = [];

    if (
      product_data.product_image_path == "" ||
      product_data.product_image_path == null ||
      product_data.product_image_path == undefined
    ) {
      let temp_err = {
        message: "กรุณาเลือก Image",
      };
      error_list.push(temp_err);
    }

    /* if (!product_data.license) {
      let temp_err = {
        message: "กรุณาเลือก License",
      };
      error_list.push(temp_err);
    } */

    if (!product_data.shelf_life_full) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Shelf life full",
      };
      error_list.push(temp_err);
    }

    if (!product_data.garantee) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Garantee",
      };
      error_list.push(temp_err);
    }

    if (!product_data.weight) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Weight",
      };
      error_list.push(temp_err);
    }

    if (!product_data.unit_weight) {
      let temp_err = {
        message: "กรุณาเลือก unit Weight",
      };
      error_list.push(temp_err);
    }

    if (!product_data.product_time) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Product time",
      };
      error_list.push(temp_err);
    }

    if (!product_data.delivery_time) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Delivery time",
      };
      error_list.push(temp_err);
    }

    if (!product_data.su_pc_pack) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Sale Unit Pc/pack",
      };
      error_list.push(temp_err);
    }

    if (!product_data.su_pc_ctn) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Sale Unit Pc/ctn",
      };
      error_list.push(temp_err);
    }

    if (!product_data.su_pack_ctn) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Sale Unit Pack/ctn",
      };
      error_list.push(temp_err);
    }

    if (!product_data.net_weight) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Net weight",
      };
      error_list.push(temp_err);
    }

    if (!product_data.unit_net_weight) {
      let temp_err = {
        message: "กรุณาเลือก Unit net weight",
      };
      error_list.push(temp_err);
    }

    if (!product_data.gross_weight) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Gross weight",
      };
      error_list.push(temp_err);
    }

    if (!product_data.unit_gross_weigth) {
      let temp_err = {
        message: "กรุณาเลือก Unit gross weight",
      };
      error_list.push(temp_err);
    }

    if (!product_data.moq) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล MOQ",
      };
      error_list.push(temp_err);
    }

    if (!product_data.carton_from_supplier) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล Carton from supplier",
      };
      error_list.push(temp_err);
    }

    if (!product_data.container_20) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล container 20'",
      };
      error_list.push(temp_err);
    }

    if (!product_data.container_20_type) {
      let temp_err = {
        message: "กรุณาเลือก Container 20' type",
      };
      error_list.push(temp_err);
    }

    if (!product_data.container_40) {
      let temp_err = {
        message: "กรุณากรอกข้อมูล container 40'",
      };
      error_list.push(temp_err);
    }

    if (!product_data.container_40_type) {
      let temp_err = {
        message: "กรุณาเลือก Container 40' type",
      };
      error_list.push(temp_err);
    }
    /* if ()
        await saveOption(); */
    setErrorList({
      ...errorList,
      data: error_list,
    });
    errorList.data = error_list;
    // return error_list;
  };

  const uploadRef = useRef(null);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-xl-4 col-md-5 col-sm-10 col-12 mr-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label htmlFor="exampleInputFile"> </label>
            <img
              id="img"
              alt="..."
              className="img-fluid rounded shadow border-0"
              src={
                seeFileImage?.product_image_path !== "" &&
                  seeFileImage?.product_image_path !== null &&
                  seeFileImage?.product_image_path !== undefined
                  ? seeFileImage?.product_image_path?.indexOf("blob") >= 0
                    ? seeFileImage?.product_image_path
                    : Configs.API_URL_IMG_cuteplus +
                    `/static/${seeFileImage?.product_image_name}/${seeFileImage?.product_image_path}`
                  : userdefault_img.imgs
              }
              onError={(e) => {
                e.target.src = userdefault_img.imgs;
              }}
              style={{
                width: "400px",
                height: "300px",
                position: "relative",
              }}
            />
            <br />
            {/* <span style={{ color: "red", fontSize: "15px" }}>
                            Recommend Size:400x300px{" "}
                        </span> */}
          </div>
          <div className="col-xl-4 col-md-5 col-sm-10 col-12">
            <div className="row">
              <label htmlFor="exampleInputFile">
                Part Image{" "}
                <span style={{ color: "red" }}>size(400 x 300) px</span>
                {!Is_Product_Kitting && (!product_data.product_image_path) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    style={{ display: "none" }}
                    type="file"
                    className="custom-file-input"
                    id="exampleInputInvoid"
                    accept={allowedTypes}
                    multiple
                    disabled={disable}
                    ref={uploadRef}
                    name="customFile"
                    onChange={(e) => encodeImage(e.target.files)}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="exampleInputFile"
                    onClick={() => uploadRef.current.click()}
                  >
                    {"Select Image"}
                  </label>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    position: "relative",
                    whiteSpace: "nowrap",
                  }}
                >
                  {" "}
                </div>
                <div className="col-12">
                  <table className="table-responsive" style={{ width: "100%" }}>
                    <thead>
                      <th
                        style={{
                          border: "2px solid #032973",
                          textAlign: "center",
                          padding: "5px",
                          color: "#2670B9",
                        }}
                      >
                        เลือก
                      </th>
                      <th
                        style={{
                          border: "2px solid #032973",
                          textAlign: "center",
                          padding: "5px",
                          color: "#2670B9",
                        }}
                      >
                        ลำดับ
                      </th>
                      <th
                        style={{
                          border: "2px solid #032973",
                          textAlign: "center",
                          padding: "5px",
                          color: "#2670B9",
                        }}
                      >
                        เอกสาร
                      </th>
                      <th
                        style={{
                          border: "2px solid #032973",
                          textAlign: "center",
                          padding: "5px",
                          color: "#2670B9",
                        }}
                      >
                        จัดการ
                      </th>
                    </thead>
                    <tbody>
                      {documentFile?.map((el, i) => {
                        // console.log('el', el);
                        return (
                          <tr key={el.sbd_document_name}>
                            <td
                              style={{
                                border: "2px solid #032973",
                                textAlign: "center",
                                padding: "5px",
                              }}
                            >
                              <input
                                // className="form-check-input"
                                type="checkbox"
                                id={el.sbd_path_document}
                                name={el.sbd_path_document}
                                value={el.sbd_path_document}
                                disabled={disable}
                                // hidden={disable}
                                checked={el.is_checked}
                                onClick={(e) => {
                                  checkedSelectBox(i);
                                }}
                              />
                            </td>
                            <td
                              style={{
                                border: "2px solid #032973",
                                textAlign: "center",
                                padding: "5px",
                              }}
                            >
                              {i + 1}
                            </td>
                            <td
                              style={{
                                border: "2px solid #032973",
                                padding: "5px",
                              }}
                            >
                              <a /* href={`${Configs.API_URL_IMG_incentive}${el.sbd_path_document}`} */
                                style={{ color: "#2670B9", cursor: "pointer" }}
                                onClick={() => {
                                  clickToSeeImage(i);
                                }}
                              >
                                {el.sbd_document_name}
                              </a>
                            </td>
                            <td
                              style={{
                                border: "2px solid #032973",
                                textAlign: "center",
                                padding: "5px",
                              }}
                            >
                              <a
                                key={`${el.sbd_document_name}del`}
                                className="simple-icon-trash button-color black-color"
                                style={{ borderRadius: "10px", color: "red" }}
                                disabled={disable}
                                hidden={disable}
                                onClick={() => {
                                  Swal.fire({
                                    title: "คุณต้องการลบรูปภาพนี้ใช่หรือไม่?",
                                    text: "รูปภาพที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบรูปภาพนี้",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "ตกลง",
                                    cancelButtonText: `ไม่`,
                                  }).then(async (result) => {
                                    if (result.isConfirmed) {
                                      el.status = "del";
                                      let temp = p_advance_upload_files.data
                                      temp[i].status = "del";
                                      setP_advance_upload_files({
                                        ...p_advance_upload_files,
                                        data: temp,
                                        del: temp
                                      })
                                      p_advance_upload_files.data = temp;
                                      p_advance_upload_files.del = temp;
                                      await delDocList(i);
                                      await saveProductAdvanceFiles();
                                    }
                                  });
                                }}
                              >
                                <i className="fas fa-trash-alt" />
                                {/* {' '} */}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-12 col-md-12 col-sm-12 col-12">
            <div className="form-group ">
              <textarea
                className="form-control"
                rows={2}
                disabled={disable}
                style={{
                  height: "100px",
                  fontSize: "25px",
                }}
                value={product_data.product_detail}
                onChange={(e) =>
                  setproduct_data({
                    ...product_data,
                    product_detail: e.target.value,
                  })
                }
                defaultValue={""}
                required
              />
              <label>Product Description</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.license}
                onChange={(e) => {
                  setproduct_data({ ...product_data, license: e.target.value });
                  product_data.license = e.target.value;
                }}
              >
                <option value="">---เลือก License---</option>
                {dropDownData?.license?.map((item, index) => {
                  return (
                    <option key={index} value={item.license_id}>
                      {item.license_name}
                    </option>
                  );
                })}
              </select>
              <label>
                License
                {/* {product_data.license === "" ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                maxLength={5}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.shelf_life_full}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    shelf_life_full: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Shelf life full (month)
                {!Is_Product_Kitting && (!product_data.shelf_life_full) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                maxLength={5}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.garantee}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    garantee: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Garantee (month)
                {!Is_Product_Kitting && (!product_data.garantee) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.weight}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    weight: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Weight
                {!Is_Product_Kitting && (!product_data.weight) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.unit_weight}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    unit_weight: e.target.value,
                  });
                  product_data.unit_weight = e.target.value;
                }}
              >
                <option value="">---เลือก Unit---</option>
                {dropDownData?.product_unit?.map((item, index) => {
                  return (
                    <option key={index} value={item.pu_id}>
                      {item.pu_name}
                    </option>
                  );
                })}
              </select>
              <label>
                Unit{" "}
                {!Is_Product_Kitting && (!product_data.unit_weight) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                maxLength={5}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.product_time}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    product_time: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Product lead time (day)
                {!Is_Product_Kitting && (!product_data.product_time) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                maxLength={5}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.delivery_time}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    delivery_time: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Delivery time (day)
                {!Is_Product_Kitting && (!product_data.delivery_time) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>Selling Price</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.sp_pc}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    sp_pc: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                PC
                {/* {!Is_Product_Kitting && (!product_data.sp_pc) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.sp_pack}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    sp_pack: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Pack
                {/* {!Is_Product_Kitting && (!product_data.sp_pack) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.sp_ctn}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    sp_ctn: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Ctn
                {/* {!Is_Product_Kitting && (!product_data.sp_ctn) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>Selling Cost</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.sc_pc}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    sc_pc: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                PC
                {/* {!Is_Product_Kitting && (!product_data.sc_pc) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.sc_pack}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    sc_pack: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Pack
                {/* {!Is_Product_Kitting && (!product_data.sc_pack) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.sc_ctn}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    sc_ctn: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Ctn
                {/* {!Is_Product_Kitting && (!product_data.sc_ctn) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>Sale Unit</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.su_pc_pack}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    su_pc_pack: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                PC/Pack
                {!Is_Product_Kitting && (!product_data.su_pc_pack) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.su_pc_ctn}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    su_pc_ctn: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                PC/Ctn
                {!Is_Product_Kitting && (!product_data.su_pc_ctn) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.su_pack_ctn}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    su_pack_ctn: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Pack/Ctn
                {!Is_Product_Kitting && (!product_data.su_pack_ctn) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>Import Price</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.pc}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    pc: e.target.value,
                  });
                }}
              />
              <label htmlFor="">PC.</label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.ctn}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    ctn: e.target.value,
                  });
                }}
              />
              <label htmlFor="">Ctn.</label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.currency}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    currency: e.target.value,
                  });
                  product_data.currency = e.target.value;
                }}
              >
                <option value="">---เลือก Currency---</option>
                {dropDownData?.currency?.map((item, index) => {
                  return (
                    <option key={index} value={item.currency_id}>
                      {item.currency_name}
                    </option>
                  );
                })}
              </select>
              <label>Currency</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>Pallet</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                id="text_product_name"
                required="false"
                decimalScale={0}
                disabled={disable}
                value={product_data.ctn_layer}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    ctn_layer: e.target.value,
                  });
                }}
              />
              <label htmlFor="">Ctn/Layer</label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.layer}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    layer: e.target.value,
                  });
                }}
              />
              <label htmlFor="">Layers</label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.ctn_x_plt}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    ctn_x_plt: e.target.value,
                  });
                }}
              />
              <label htmlFor="">Ctn x plt</label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.height}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    height: e.target.value,
                  });
                }}
              />
              <label htmlFor="">height (cm)</label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>TARIF CODE : HS CODE</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              {/* <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.original_side}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    original_side: e.target.value,
                  });
                }}
              /> */}
              <input
                type="text"
                className="form-control"
                required
                disabled={isDisabled}
                value={product_data.original_side}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    original_side: e.target.value,
                  });
                }}
              />
              <label htmlFor="">Original Side</label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                // maxLength={3}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.rate_os}
                onChange={(e) => {
                  if (parseFloat(e.target.value?.replace(/,/g, "")) > 100) {
                    setproduct_data({
                      ...product_data,
                      rate_os: 100,
                    });
                  } else {
                    setproduct_data({
                      ...product_data,
                      rate_os: e.target.value,
                    });
                  }
                }}
              />
              <label htmlFor="">Rate (%)</label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                // thousandsGroupStyle="thousand"
                // thousandSeparator=","
                allowNegative={false}
                // maxLength={3}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.thailand}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    thailand: e.target.value,
                  });
                }}
              />
              <label htmlFor="">Thailand</label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                // maxLength={3}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.rate_th}
                onChange={(e) => {
                  if (parseFloat(e.target.value?.replace(/,/g, "")) > 100) {
                    setproduct_data({
                      ...product_data,
                      rate_th: 100,
                    });
                  } else {
                    setproduct_data({
                      ...product_data,
                      rate_th: e.target.value,
                    });
                  }
                }}
              />
              <label htmlFor="">Rate (%)</label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>Agreement Discount Form</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.form_type}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    form_type: e.target.value,
                  });
                  product_data.form_type = e.target.value;
                }}
              >
                <option value="">---เลือก Form Type---</option>
                {dropDownData?.form_type?.map((item, index) => {
                  return (
                    <option key={index} value={item.form_type_id}>
                      {item.form_type_name}
                    </option>
                  );
                })}
              </select>
              <label>
                Form Type{" "}
                {/* {infoForSave.cat3 ===
                                    "" ? (
                                    <span style={{ color: "red" }}>
                                        {" "}
                                        *
                                    </span>
                                ) : (
                                    ""
                                )} */}
              </label>
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                // maxLength={5}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.rate_ft}
                onChange={(e) => {
                  if (parseFloat(e.target.value?.replace(/,/g, "")) > 100) {
                    setproduct_data({
                      ...product_data,
                      rate_ft: 100,
                    });
                  } else {
                    setproduct_data({
                      ...product_data,
                      rate_ft: e.target.value,
                    });
                  }
                }}
              />
              <label htmlFor="">
                Rate (%)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h4>
              <b>Dismension H x L x H - (CM)</b>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.pc_w}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    pc_w: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                PC (W)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.pc_l}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    pc_l: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                PC (L)
                {/*  {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.pc_h}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    pc_h: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                PC (H)
                {/*  {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.pack_w}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    pack_w: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Pack (W)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.pack_l}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    pack_l: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Pack (L)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.pack_h}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    pack_h: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Pack (H)
                {/* {product_data.pack_h === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.ctn_w}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    ctn_w: e.target.value,
                    m3_auto_cal:
                      (parseFloat(e.target.value?.replace(/,/g, "") || 0) *
                        parseFloat(product_data.ctn_l?.replace(/,/g, "") || 0) *
                        parseFloat(
                          product_data.ctn_h?.replace(/,/g, "") || 0
                        )) /
                      1000000.0,
                  });
                  /*                                     setproduct_data({
                                                                            ...product_data,
                                                                            
                                                                        }); */
                }}
              />
              <label htmlFor="">
                Ctn (W)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.ctn_l}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    ctn_l: e.target.value,
                    m3_auto_cal:
                      (parseFloat(e.target.value?.replace(/,/g, "") || 0) *
                        parseFloat(product_data.ctn_w?.replace(/,/g, "") || 0) *
                        parseFloat(
                          product_data.ctn_h?.replace(/,/g, "") || 0
                        )) /
                      1000000.0,
                  });
                  /* setproduct_data({
                                        ...product_data,
                                        
                                    }); */
                }}
              />
              <label htmlFor="">
                Ctn (L)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-4 col-md-4 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.ctn_h}
                onChange={(e) => {
                  /* console.log('e.target.value', e.target.value.replace(/,/g, ""));
                                    console.log('parseFloat(product_data.ctn_w || 0)', parseFloat(product_data.ctn_w || 0))
                                    console.log('parseFloat(product_data.ctn_l || 0)', parseFloat(product_data.ctn_l || 0)); */
                  setproduct_data({
                    ...product_data,
                    ctn_h: e.target.value,
                    m3_auto_cal:
                      (parseFloat(e.target.value?.replace(/,/g, "") || 0) *
                        parseFloat(product_data.ctn_w?.replace(/,/g, "") || 0) *
                        parseFloat(
                          product_data.ctn_l?.replace(/,/g, "") || 0
                        )) /
                      1000000.0,
                  });
                  /* setproduct_data({
                                        ...product_data,
                                        
                                    }); */
                }}
              />
              <label htmlFor="">
                Ctn (H)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-7 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={true}
                value={product_data.m3_auto_cal}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    m3_auto_cal: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                M3 (Auto Calculate)
                {/* {product_data.product_point === "" ?
                                    <span style={{ color: "red" }}> *</span>
                                    : ""
                                } */}
              </label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                // thousandsGroupStyle="thousand"
                // thousandSeparator=","
                allowNegative={false}
                // decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.net_weight}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    net_weight: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Net Weight
                {!Is_Product_Kitting && (!product_data.net_weight) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.unit_net_weight}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    unit_net_weight: e.target.value,
                  });
                  product_data.unit_net_weight = e.target.value;
                }}
              >
                <option value="">---เลือก Unit---</option>
                {dropDownData?.product_unit?.map((item, index) => {
                  return (
                    <option key={index} value={item.pu_id}>
                      {item.pu_name}
                    </option>
                  );
                })}
              </select>
              <label>
                Unit{" "}
                {!Is_Product_Kitting && (!product_data.unit_net_weight) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                // thousandsGroupStyle="thousand"
                // thousandSeparator=","
                allowNegative={false}
                // decimalScale={2}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.gross_weight}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    gross_weight: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Gross Weight
                {!Is_Product_Kitting && (!product_data.gross_weight) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.unit_gross_weigth}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    unit_gross_weigth: e.target.value,
                  });
                  product_data.unit_gross_weigth = e.target.value;
                }}
              >
                <option value="">---เลือก Unit---</option>
                {dropDownData?.product_unit?.map((item, index) => {
                  return (
                    <option key={index} value={item.pu_id}>
                      {item.pu_name}
                    </option>
                  );
                })}
              </select>
              <label>
                Unit{" "}
                {!Is_Product_Kitting && (!product_data.unit_gross_weigth) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.moq}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    moq: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                MOQ (Ctn)
                {!Is_Product_Kitting && (!product_data.moq) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            {/*  */}
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={3}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.carton_from_supplier}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    carton_from_supplier: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                Carton from Supplier
                {!Is_Product_Kitting && (!product_data.carton_from_supplier) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.container_20}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    container_20: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                20'
                {!Is_Product_Kitting && (!product_data.container_20) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.container_20_type}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    container_20_type: e.target.value,
                  });
                  product_data.container_20_type = e.target.value;
                }}
              >
                <option value="">---เลือก Container---</option>
                {dropDownData?.container?.map((item, index) => {
                  return (
                    <option key={index} value={item.ct_id}>
                      {item.ct_name}
                    </option>
                  );
                })}
              </select>
              <label>
                Container Type{" "}
                {!Is_Product_Kitting && (!product_data.container_20_type) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-md-6 col-sm-6 col-12">
            <div className="form-group ">
              <NumberFormat
                type="text"
                className="form-control"
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                allowNegative={false}
                decimalScale={0}
                id="text_product_name"
                required="false"
                disabled={disable}
                value={product_data.container_40}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    container_40: e.target.value,
                  });
                }}
              />
              <label htmlFor="">
                40'
                {!Is_Product_Kitting && (!product_data.container_40) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>{" "}
            </div>
          </div>
          <div className="col-xl-3 col-md-3 col-sm-6 col-12">
            <div className="form-group" style={{ position: "relative" }}>
              <select
                required
                className="form-control"
                id="thank_point"
                name="thank_point"
                disabled={disable}
                value={product_data.container_40_type}
                onChange={(e) => {
                  setproduct_data({
                    ...product_data,
                    container_40_type: e.target.value,
                  });
                  product_data.container_40_type = e.target.value;
                }}
              >
                <option value="">---เลือก Container---</option>
                {dropDownData?.container?.map((item, index) => {
                  return (
                    <option key={index} value={item.ct_id}>
                      {item.ct_name}
                    </option>
                  );
                })}
              </select>
              <label>
                Container Type{" "}
                {!Is_Product_Kitting && (!product_data.container_40_type) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h4>
              <b>Document</b>
            </h4>
          </div>
        </div>
        <div className="row" style={{ gap: "10px" }}>
          <div className="col-12 col-md-9 col-xl-9">
            <label htmlFor="">Flow Chart (.PDF)</label>
            {/* <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small> */}
            <div className="input-group">
              <div className="custom-file">
                <input
                  // disabled={mode.mode == "read" ? true : false}
                  // onClick={clickclear}
                  type="file"
                  disabled={disable}
                  required
                  className="custom-file-input"
                  id="file_flow"
                  accept={allowedTypes2}
                  onChange={upload_file}
                />
                <label className="custom-file-label" htmlFor="exampleInputFile">
                  {productFile?.file_name_flow !== null &&
                    productFile?.file_name_flow !== "" &&
                    productFile?.file_name_flow !== undefined
                    ? productFile?.file_name_flow
                    : "Select File"}
                </label>
              </div>
            </div>
          </div>
          {mode === "view" ? (
            <>
              {
                productFile.file_name_flow === "" &&
                  productFile.file_path_flow === "" ?
                  <>
                  </>
                  :
                  <>
                    <div
                      className="col-6 col-md-3 col-xl-3"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <a
                        href={
                          productFile.file_name_flow && productFile.file_path_flow ? Configs.API_URL_IMG_cuteplus + `/static/${productFile.file_name_flow}/${productFile.file_path_flow}` : ""
                        }
                        download={productFile.file_name_flow ? productFile.file_name_flow : ""}
                      >
                        <button
                          type="button"
                          id="btn_search"
                          disabled={productFile?.file_path_flow == "" ? true : false}
                          className="btn btn-block btn-info"
                          style={{ minWidth: "60px" }}
                        >
                          Download
                        </button>
                      </a>
                    </div>
                  </>
              }
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="row" style={{ gap: "10px" }}>
          <div className="col-12 col-md-9 col-xl-9">
            <label htmlFor="">Ingredient (.PDF)</label>
            {/* <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small> */}
            <div className="input-group">
              <div className="custom-file">
                <input
                  // disabled={mode.mode == "read" ? true : false}
                  // onClick={clickclear}
                  type="file"
                  disabled={disable}
                  required
                  className="custom-file-input"
                  accept={allowedTypes2}
                  id="file_flow22"
                  onChange={upload_file}
                />
                <label className="custom-file-label" htmlFor="exampleInputFile">
                  {productFile?.file_name_ingre !== null &&
                    productFile?.file_name_ingre !== "" &&
                    productFile?.file_name_ingre !== undefined
                    ? productFile?.file_name_ingre
                    : "Select File"}
                </label>
              </div>
            </div>
          </div>
          {mode === "view" ? (
            <>
              {
                productFile.file_path_ingre === "" &&
                  productFile.file_path_ingre === null ?
                  <>
                  </>
                  :
                  <>
                    <div
                      className="col-6 col-md-3 col-xl-3"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <a
                        href={productFile.file_name_ingre && productFile.file_path_ingre ? Configs.API_URL_IMG_cuteplus + `/static/${productFile.file_name_ingre}/${productFile.file_path_ingre}` : ""}
                        download={productFile.file_name_ingre ? productFile.file_name_ingre : ""}
                      >
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-info"
                          style={{ minWidth: "60px" }}
                          disabled={productFile?.file_path_ingre == "" ? true : false}
                        >
                          Download
                        </button>
                      </a>
                    </div>
                  </>
              }

            </>
          ) : (
            <></>
          )}
        </div>

        <div className="row">
          <div
            className="col-12 col-md-6 col-xl-6"
            style={{
              display: "flex",
              height: "auto",
              columnGap: "1rem",
              alignItems: "center",
              fontSize: "1.5rem",
            }}
          >
            <input
              // className="form-check-input"
              type="checkbox"
              style={{ width: 15, height: 15 }}
              id={"sbd_path_document"}
              name={"el.sbd_path_document"}
              value={product_data.art_work}
              disabled={disable}
              // hidden={disable}
              checked={product_data.art_work}
              onClick={(e) => {
                // checkedSelectBox(1);
                setproduct_data({
                  ...product_data,
                  art_work: e.target.checked,
                });
              }}
            />
            <p style={{ margin: 0, padding: 0 }}>Art work</p>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12 col-12">
            <div className="form-group ">
              <textarea
                className="form-control"
                rows={2}
                disabled={disable}
                style={{
                  height: "100px",
                  fontSize: "25px",
                }}
                value={product_data.remark}
                onChange={(e) =>
                  setproduct_data({
                    ...product_data,
                    remark: e.target.value,
                  })
                }
                defaultValue={""}
                required
              />
              <label>Remark</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Advance_Data;
