import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";

import "../../../../../../src/assets/css/Quotation_CutePlus.css";

function Quotation_Cute_Product({
  id,
  mode,
  isHistory,
  ref_id_qout,
  setRef_id_qout,
  isDisabled,
  inputDataTab2,
  setInputDataTab2,
  quotation_data,
  setQuotation_data,
  errorListTab2,
  setErrorListTab2,
  functionSaveTab2,
  setFunctionSaveTab2,
}) {
  const modalProductRef = useRef();

  const [categoryData, setCategoryData] = useState({
    data: [],
  });
  const [test, setTest] = useState({
    test: ''
  });
  const [MDBT, setMDBT] = useState(true);

  const [productData, setProductData] = useState({
    data: [],
    filter: []
  });

  const [selectProductData, setSelectProductData] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [searchData, setSearchData] = useState({
    code: "",
    name: "",
    barcode: "",
    category: "",
    customer_barcode: "",
    customer_product_name: ""
  });
  const [ref_id_qout_change, setRef_id_qout_change] = useState({
    data: false,
    is_resetSelectProduct: false
  });

  const [countChangeInfo, setCountChangeInfo] = useState({
    data: 0
  });

  useEffect(() => {
    calSummaryProductTable();
  }, [selectProductData.data])

  useEffect(async () => {
    if (ref_id_qout.data) {
      await GetQuotationRelationProduct(ref_id_qout.data, ref_id_qout.from);
      setRef_id_qout_change({
        ...ref_id_qout_change,
        data: true,
      });
      ref_id_qout_change.data = true;
      await GetProduct();

    }
  }, [ref_id_qout.data])

  useEffect(async () => {
    if (quotation_data.quo_confirm_customer === true) {
      setRef_id_qout_change({
        ...ref_id_qout_change,
        is_resetSelectProduct: true
      })
      ref_id_qout_change.is_resetSelectProduct = true;
      await GetProduct();
      setQuotation_data({ ...quotation_data, quo_confirm_customer: false });
      quotation_data.quo_confirm_customer = false;
    }
  }, [quotation_data.quo_cus_id, quotation_data.quo_confirm_customer]);

  useEffect(async () => {
    if (quotation_data.quo_reset === true) {
      setSelectProductData({
        data: [],
        newData: [],
        updateData: [],
        delData: [],
      });
      selectProductData.data = [];
      selectProductData.newData = [];
      selectProductData.updateData = [];
      selectProductData.delData = [];
      await GetProduct();
      setQuotation_data({
        ...quotation_data,
        quo_reset: false
      });
      quotation_data.quo_reset = false;
    }
  }, [quotation_data.quo_reset]);

  useEffect(() => {
    if (quotation_data.quo_gp_standard) {
      productData.data?.forEach(el => {
        if (!el.gp) el.gp = parseFloat(quotation_data.quo_gp_standard?.toString());
      });
    }
  }, [quotation_data.quo_gp_standard])

  async function openModalProduct() {
    const tempSelectProduct = selectProductData.data;
    const tempProductTable = productData.data;

    const tempList = tempProductTable.map((item1) => {
      const check = tempSelectProduct.find(
        (item2) => item2.product_id === item1.product_id
      );

      if (check) {
        item1.check = true;
        return item1;
      } else {
        item1.check = false;
        return item1;
      }
    });

    setProductData({ ...productData, data: tempList });
    productData.data = tempList;
    setMDBT(true)
    modalProductRef.current.click();
  }

  const SetProduct_Details = async (tempRes, cus_id) => {
    const tempResList = [];
    const selectProductDataList = [];

    let selectProductOldList = [];
    const checkreset = ref_id_qout_change.is_resetSelectProduct;
    if (checkreset && mode === "edit" || mode === "view") {
      selectProductOldList = structuredClone(selectProductData.data)
    }
    /* console.log('cus_id', cus_id);
    if (cus_id === "search") {
      await resetProduct(false);
    } */

    for (let item1 of tempRes) {
      item1.is_use = false

      if (productData.filter.length > 0) {
        const check = productData.filter.find(el => el.product_id === item1.product_id)
        if (check) {
          item1.is_use = true
        }
      } else {
        item1.is_use = true
      }

      if (cus_id) {
        const check = selectProductData.data.find(el => el.product_id === item1.product_id);
        if (check) {
          item1.check = true;
          tempResList.push(item1);
          continue;
        }
      }
      // let has_detailsCustomer = false;
      item1.check = false;
      item1.retail_price = "";
      item1.pq_unit = "Piece"
      item1.gp = "";
      item1.product_quantity = "";
      item1.customer_code = "";
      item1.quo_full_shelf_life = "";
      item1.quo_garantee = "";
      if (!item1.oldNew) {
        item1.oldNew = "new";
      }
      item1.product_name_select = item1.product_name || "";
      item1.product_name_eng_select = item1.product_name_eng || "";

      if (item1.barcode_id.length === 1) {
        item1.isBarSelect = false;
        item1.bar_code = item1.barcode_id[0] || "";
      } else if (item1.barcode_id.length === 0) {
        item1.isBarSelect = false;
        item1.bar_code = "";
      } else {
        item1.isBarSelect = true;
        const index_barcode = item1.barcode_active?.indexOf(true)
        item1.bar_code = index_barcode >= 0 ? item1.barcode_id[index_barcode] : "";
      }

      if (item1.retail_price === "") {
        item1.retail_price = item1.product_resale 
          ? (parseFloat(item1.product_resale || 0) * (100 / 107))
          : "";
      }
      if (item1.detailsCustomer && quotation_data.quo_cus_id) {
        const check_has = item1.detailsCustomer?.find(el => el.crp_cus_id === quotation_data.quo_cus_id)
        // console.log('check_has', check_has);
        if (check_has) {
          item1.bar_code = check_has.crp_pb_id || item1.barcode_id[0]; //item1.barcode_id?.find(el => el === item1.detailsCustomer.crp_pb_id) || item1.barcode_id[0];
          item1.customer_code = check_has.crp_cus_barcode || ""
          item1.gp = check_has.crp_gp1 || "";
          item1.retail_price = check_has.crp_sale_price || ""
          if (check_has.crp_product_name_th) item1.product_name_select = check_has.crp_product_name_th;
          if (check_has.crp_product_name_eng) item1.product_name_eng_select = check_has.crp_product_name_eng;

          if (check_has.crp_slt_id) {
            let is_garantee = ("3541b465-fe34-45cf-ac0b-469c81770218" === check_has.crp_slt_id);
            item1.quo_garantee = is_garantee ? check_has.crp_amount || 0 : "";
            item1.quo_full_shelf_life = !is_garantee ? check_has.crp_amount || 0 : "";
          }
          // has_detailsCustomer = true;
          /*           if (cus_id !== "search")
                      item1.is_use = true; */
        } else {
          item1.is_use = false;
        }
      } else if (!item1.detailsCustomer && quotation_data.quo_cus_id) {
        item1.is_use = false;
      }/*  else {
        item1.is_use = true;
      } */

      if (mode === "edit" || mode === "view" || ref_id_qout_change.data === true) {
        for (let item2 of inputDataTab2.data) {
          if (item1.product_id === item2.qp_product_id) {
            item1.check = true;
            if (!item2.pp_id) {
              item1.customer_code = item2.qp_cus_barcode || "";
              item1.oldNew = "old";
              item1.qp_id = item2.qp_id;
              item1.gp = item2.qp_gp_percent || "";
              if (item2.qp_product_name_th)
                item1.product_name_select = item2.qp_product_name_th || "";
              if (item2.qp_product_name_eng)
                item1.product_name_eng_select = item2.qp_product_name_eng || "";
            } else {
              item1.oldNew = "new";
              item1.status = "add";
            }
            
            if (item2.qp_full_shelf_life) {
              item1.quo_full_shelf_life = item2.qp_full_shelf_life;
            }
            if (item2.qp_garantee) {
              item1.quo_garantee = item2.qp_garantee;
            }
            item1.pq_unit = item2.qp_quantity_unit || "";
            item1.bar_code = item2.qp_pb_id;
            item1.retail_price = item2.qp_retail_price || "";
            item1.product_quantity = item2.qp_quantity || "";

            selectProductDataList.push(item1);
          }
        }
      }

      // check delete product
      if (checkreset && mode === "edit" || mode === "view") {
        for (let item2 of selectProductOldList) {
          if (item1.product_id === item2.product_id && item2.qp_id) {
            item1.status = "del";
            item1.oldNew = "old";
          }
        }
      }

      if (selectProductData.delData.length > 0) {
        for (let item2 of selectProductData.delData) {
          if (item1.product_id === item2.product_id && item2.qp_id) {
            // console.log('item2', item2)
            item1.status = "del";
            item1.oldNew = "old";
          }
        }
      }
      // console.log('item1', item1)

      // if (!quotation_data.quo_cus_id || has_detailsCustomer) {
      tempResList.push(item1);
      // }

    }

    if (!cus_id) {
      if (ref_id_qout_change.data === false) {
        setSelectProductData({
          ...selectProductData,
          data: selectProductDataList,
        });
        selectProductData.data = selectProductDataList;
      } else {
        setSelectProductData({
          ...selectProductData,
          data: selectProductDataList,
          newData: selectProductDataList
        });
        selectProductData.data = selectProductDataList;
        selectProductData.newData = selectProductDataList;
      }
    }

    setRef_id_qout_change({
      ...ref_id_qout_change,
      data: false,
    })
    ref_id_qout_change.data = false;
    /* setQuotation_data({ ...quotation_data, quo_confirm_customer: false });
    quotation_data.quo_confirm_customer = false; */
    // if (productData.data.length === 0) {
    // console.log('tempResList', tempResList)
    setProductData({
      ...productData,
      data: tempResList
    });
    productData.data = tempResList;
    setTest({ ...test, test: '' });
    test.test = '';
    // }
    await saveProductData();
    // await calSummaryProductTable();
  }

  async function GetQuotationRelationProduct(ref_id, from) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      quo_id: ref_id ? ref_id : id,
      pl_id: ref_id,
      isHistory: isHistory
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + (from === "price_list"
          ? "/api/price_list/getPricelistProduct"
          : "/api/quotation/getQuotationProduct"),
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        // console.log('response', response.data.data);
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data.data,
        });

        inputDataTab2.data = response.data.data;
        setMDBT(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const validThai = new RegExp(`[\u0E00-\u0E7F]`);
  async function GetCategoryLv2() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategoryLv2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategoryData({ data: response.data });
        categoryData.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProduct(cus_id) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      code: searchData.code.trim() || null,
      name: searchData.name.trim() || null,
      barcode: searchData.barcode.trim() || null,
      category: searchData.category.trim() || null,
      customer_barcode: searchData.customer_barcode.trim() || null,
      customer_product_name: searchData.customer_product_name.trim() || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductAndDetails",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {

        let data = new FormData();
        data.append("company_id", getUser().com);
        data.append("oem_id", getOem());
        data.append("user_id", getUser().fup);
        data.append("products", JSON.stringify(response.data));

        await axios({
          method: "post",
          url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        })
          .then(async (response) => {
            if (response.data) {
              if (response.data.length == 0) {
                await resetProduct(false);
                return;
              } else if (productData.data?.length > 0) {
                setProductData({
                  ...productData,
                  filter: response.data
                })
                productData.filter = response.data
              }
              await SetProduct_Details(productData.data?.length > 0 ? productData.data : response.data, cus_id)
            }
          })
          .catch(async (error) => {
            console.log('error', error);
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
            });
          });
        // const tempRes = response.data;
        setMDBT(true)
        // calSummaryProductTable();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const rowsData1 = [];
  let run_number = 0;
  for (let index = 0; index < productData.data.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{run_number + 1}</div>;

    rowItem["code"] = productData.data[index].product_code
      ? productData.data[index].product_code
      : "-";

    rowItem["barcode"] = productData.data[index].barcode[0] ? productData.data[index].barcode[0] : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            productData.data[index].pci_img_path !== "" &&
              productData.data[index].pci_img_path !== null &&
              productData.data[index].pci_img_path !== undefined
              ? productData.data[index].pci_img_path?.indexOf("blob") >= 0
                ? productData.data[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                `/static/${productData.data[index].pci_img_name}/${productData.data[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = productData.data[index].product_name_eng
      ? productData.data[index].product_name_eng
      : "-";

    rowItem["category"] = productData.data[index].cat_name
      ? productData.data[index].cat_name
      : "-";

    rowItem["management"] =
      productData.data[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            onClick={() => {
              let tempData = productData.data;

              tempData[index].check = true;
              if (tempData[index].statusUpdate) {
                tempData[index].status = "update";
              } else {
                tempData[index].status = "add";
              }

              setProductData({
                ...productData,
                data: tempData,
              });
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            onClick={() => {
              let tempData = productData.data;

              tempData[index].check = false;
              tempData[index].status = "del";

              setProductData({
                ...productData,
                data: tempData,
              });
            }}
          >
            ยกเลิก
          </button>
        </div>
      );
    // console.log('in loop', productData.data);
    if (productData.data[index].is_use) {
      run_number += 1;
      rowsData1.push(rowItem);
    }
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Category",
        field: "category",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };

  const calSummaryProductTable = async () => {
    setCountChangeInfo({
      ...countChangeInfo,
      data: (countChangeInfo.data + 1)
    });
    let sumTotal = 0;
    let beforeVat = 0;
    let vat = 0;
    let valueDelivery = parseFloat(quotation_data.quo_shipping_cost) || 0;
    let discountfinal = parseFloat(quotation_data.quo_final_discount) || 0;
    for (let item of selectProductData.data) {
      let retail_price = parseFloat(item.retail_price?.toString().replace(/,/g, "") || 0) || 0;
      let p_quantity = parseInt(item.product_quantity?.toString().replace(/,/g, "") || 0) || 0;
      let gp = parseFloat(item.gp);
      const x_unit = item.pq_unit === "Piece" ? 1 : item.pq_unit === "pack" ? parseInt(item.detailsAdvance.pc_pack || 1) : item.pq_unit === "ctn" ? parseInt(item.detailsAdvance.pc_ctn || 1) : 1;
      if (gp) {
        let tempcount = (retail_price * (p_quantity * x_unit))
        sumTotal += (tempcount - ((gp / 100) * tempcount));
        item.quo_sop_total = (tempcount - ((gp / 100) * tempcount)); // for save sale order 

      } else {
        sumTotal += (retail_price * (p_quantity * x_unit));
        item.quo_sop_total = (retail_price * (p_quantity * x_unit)); // for save sale order 
      }
    }
    let product_total = quotation_data.quo_incl_vat === false ? sumTotal : /* ((7 / 100) * sumTotal) +  */sumTotal;
    if (discountfinal) {
      sumTotal -= ((discountfinal / 100) * sumTotal)
    }
    if (quotation_data.quo_incl_vat === true) {
      vat = (7 / 100) * sumTotal;
      beforeVat = sumTotal;
      sumTotal = beforeVat + vat + valueDelivery;
      // console.log('sumTotal', sumTotal);
    } else {
      beforeVat = sumTotal;
      sumTotal += valueDelivery;
      vat = 0;
    }
    setQuotation_data({
      ...quotation_data,
      quo_total_price: sumTotal,
      quo_total_befor_vat: beforeVat,
      quo_vat: vat?.toFixed(2),
      quo_product_total: product_total,
      quo_final_discount_bath: ((discountfinal / 100) * product_total)?.toFixed(2)
    });
    quotation_data.quo_total_price = sumTotal;
    quotation_data.quo_total_befor_vat = beforeVat;
    quotation_data.quo_vat = vat?.toFixed(2);
    quotation_data.quo_product_total = product_total;
    quotation_data.quo_final_discount_bath = ((discountfinal / 100) * product_total)?.toFixed(2);

  }


  const rowsData2 = [];
  for (let index = 0; index < selectProductData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = selectProductData.data[index].product_code
      ? selectProductData.data[index].product_code
      : "-";
    rowItem["bar_code"] = (
      <div style={{ display: "flex", justifyContent: "center", minWidth: "max-content" }}>
        {selectProductData.data[index].isBarSelect === false ? (
          <>
            <select
              type="text"
              className="form-control"
              disabled={true}
              value={selectProductData.data[index].bar_code || ""}
            >
              <option value="" disabled selected>
                -- เลือก --
              </option>

              <option value={selectProductData.data[index].barcode_id[0]}>
                {selectProductData.data[index].barcode[0]}
              </option>
            </select>
          </>
        ) : (
          <>
            <select
              type="text"
              className="form-control"
              disabled={isDisabled}
              value={selectProductData.data[index].bar_code || ""}
              onChange={async (e) => {
                const temp = selectProductData.data;
                temp[index].bar_code = e.target.value;
                setSelectProductData({
                  ...selectProductData,
                  data: temp,
                });

                selectProductData.data = temp;

                if (temp[index].oldNew === "old") {
                  temp[index].status = "update";
                  temp[index].statusUpdate = true;

                  await saveProductData();
                }

              }}
            >
              <option value="" disabled>
                -- เลือก --
              </option>

              {selectProductData.data[index].barcode_id.map((item, indexIn) => {
                return (
                  <option value={item}>
                    {selectProductData.data[index].barcode[indexIn]}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </div>
    );

    rowItem["customer_code"] = (
      <div style={{ minWidth: "max-content" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          decimalScale={0}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].customer_code}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].customer_code = item.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }

          }}
        />
      </div>
    );

    rowItem["product_name"] = (
      <>
        {
          quotation_data.quo_lang === false ?
            <input
              type="text"
              className="form-control"
              style={{ minWidth: "150px" }}
              // required
              // decimalScale={0}
              // allowNegative={false}
              // allowLeadingZeros={true}
              disabled={isDisabled}
              value={selectProductData.data[index].product_name_eng_select}
              onChange={async (e) => {
                if (/* !validThai.test(e.target.value.toString().trim()) */true) {
                  const temp = selectProductData.data;

                  temp[index].product_name_eng_select = e.target.value;

                  setSelectProductData({
                    ...selectProductData,
                    data: temp,
                  });

                  selectProductData.data = temp;

                  if (temp[index].oldNew === "old") {
                    temp[index].status = "update";
                    temp[index].statusUpdate = true;
                    await saveProductData();
                  }
                }
              }}
            />
            :
            <input
              type="text"
              className="form-control"
              style={{ minWidth: "150px" }}
              // required
              // decimalScale={0}
              // allowNegative={false}
              // allowLeadingZeros={true}
              disabled={isDisabled}
              value={selectProductData.data[index].product_name_select}
              onChange={async (e) => {
                const temp = selectProductData.data;

                temp[index].product_name_select = e.target.value;

                setSelectProductData({
                  ...selectProductData,
                  data: temp,
                });

                selectProductData.data = temp;

                if (temp[index].oldNew === "old") {
                  temp[index].status = "update";
                  temp[index].statusUpdate = true;
                  await saveProductData();
                }
              }}
            />
        }
      </>
    );/* quotation_data.quo_lang === false ? (selectProductData.data[index].product_name_eng
      ? selectProductData.data[index].product_name_eng
      : "-") : (selectProductData.data[index].product_name
        ? selectProductData.data[index].product_name : "-"); */
    rowItem["quo_full_shelf_life"] = (
      <div style={{ minWidth: "max-content" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          thousandSeparator={true}
          decimalScale={0}
          format={"###"}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].quo_full_shelf_life}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].quo_full_shelf_life = item.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        />
      </div>
    );

    rowItem["quo_garantee"] = (
      <div style={{ minWidth: "max-content" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          thousandSeparator={true}
          decimalScale={0}
          format={"###"}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].quo_garantee}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].quo_garantee = item.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        />
      </div>
    );

    rowItem["retail_price"] = (
      <NumberFormat
        type="text"
        className="form-control"
        // required
        thousandSeparator={true}
        decimalScale={2}
        allowNegative={false}
        allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].retail_price}
        onChange={async (item) => {
          const temp = selectProductData.data;

          temp[index].retail_price = item.target.value;
          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
          await calSummaryProductTable();
        }}
      />
    );

    rowItem["product_quantity"] = (
      <div style={{ display: "flex", gap: ".5rem", minWidth: "150px" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          decimalScale={0}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].product_quantity}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].product_quantity = item.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        />
        <select
          type="text"
          className="form-control"
          disabled={isDisabled}
          value={selectProductData.data[index].pq_unit}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].pq_unit = e.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        >
          <option value="Piece">
            {quotation_data.quo_lang === false ? "Piece" : "ชิ้น"}
          </option>
          <option value="pack" hidden={selectProductData.data[index].detailsAdvance?.pc_pack ? false : true} >
            {quotation_data.quo_lang === false ? "Pack" : "แพ็ค"}
          </option>
          <option value="ctn" hidden={selectProductData.data[index].detailsAdvance?.pc_ctn ? false : true}>
            {quotation_data.quo_lang === false ? "Ctn" : "ลัง"}
          </option>
        </select>
      </div>

    );

    rowItem["gp"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "80px" }}
        // required
        decimalScale={2}
        allowNegative={false}
        // allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].gp}
        onChange={async (item) => {
          const temp = selectProductData.data;
          let value = parseFloat(item.target.value);
          temp[index].gp = value;
          if (value > 100) temp[index].gp = 100;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
          await calSummaryProductTable();
        }}
      />
    );

    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          key={selectProductData.data[index].product_id}
          id={selectProductData.data[index].product_id}
          className=" btn btn-xs "
          onClick={async () => {
            const tempProductData = productData.data;
            const tempSelectProductData = selectProductData.data;

            const findIndex = tempProductData.findIndex(
              (item) =>
                item.product_id === tempSelectProductData[index].product_id
            );

            if (findIndex >= 0) {
              tempProductData[findIndex].check = false;
              tempProductData[findIndex].status = "del";
            }

            await saveProductData();
          }}
        >
          <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
        </a>
      </div>
    );

    rowsData2.push(rowItem);
  }

  const selectProducTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Customer Item Code",
        field: "customer_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Full shelf life",
        field: "quo_full_shelf_life",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Garantee",
        field: "quo_garantee",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Retail Price",
        field: "retail_price",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Quantity",
        field: "product_quantity",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "GP (%)",
        field: "gp",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (quotation_data.quo_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "customer_code";
    });

    selectProducTable.columns.splice(index1, 1);

    const index2 = selectProducTable.columns.findIndex((e) => {
      return e.field === "gp";
    });

    selectProducTable.columns.splice(index2, 1);
  }

  if (isDisabled) {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "management";
    });

    selectProducTable.columns.splice(index1, 1);
  }

  async function saveProductData() {
    const findCheckTrue = productData.data.filter((item) => {
      // quo_gp_standard
      if (item.check === true) {
        /* if (!item.gp && quotation_data.quo_gp_standard) {
          item.gp = parseFloat(quotation_data.quo_gp_standard);
        } */
        return item;
      }
    });

    const addNew = productData.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = productData.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = productData.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setSelectProductData({
      ...selectProductData,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    selectProductData.data = findCheckTrue;
    selectProductData.newData = addNew;
    selectProductData.updateData = updateOld;
    selectProductData.delData = delOld;

    console.log(
      selectProductData.data,
      selectProductData.newData,
      selectProductData.updateData,
      selectProductData.delData
    );
  }

  async function clearSearch(is_clear, only_clear_input) {
    setSearchData({
      ...searchData,
      code: "",
      name: "",
      barcode: "",
      category: "",
      customer_barcode: "",
      customer_product_name: "",
    });

    searchData.code = "";
    searchData.name = "";
    searchData.barcode = "";
    searchData.category = "";
    searchData.customer_barcode = "";
    searchData.customer_product_name = "";
    if (!only_clear_input) {
      if (is_clear) {
        await resetProduct(true)
      } else {
        await GetProduct("clear");
      }
    }
  }

  const resetProduct = async (is_use) => {
    const arrTemp = productData.data
    arrTemp.forEach(el => {
      el.is_use = is_use
    });
    setProductData({
      ...productData,
      data: arrTemp
    })
    productData.data = arrTemp;
  }

  async function checkErrorSelectProductData() {
    const error_list = [];
    if (selectProductData.data.length > 0) {
      let number = 1;

      for (let item of selectProductData.data) {
        if (!item.bar_code || item.bar_code?.trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณาเลือก Barcode`,
          };

          error_list.push(temp_err);
        }

        /* if (
          !item.customer_code ||
          item.customer_code?.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Customer Code`,
          };

          error_list.push(temp_err);
        } */

        if (
          !item.product_name_select ||
          item.product_name_select?.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Product Name ภาษาไทย`,
          };

          error_list.push(temp_err);
        }

        if (
          !item.product_name_eng_select ||
          item.product_name_eng_select?.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Product Name ภาษาอังกฤษ`,
          };

          error_list.push(temp_err);
        }

        if (
          !item.retail_price
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Retail Price`,
          };

          error_list.push(temp_err);
        } else if (item.retail_price == 0) {
          let temp_err = {
            message: `แถวที่ ${number} Retail Price ต้องไม่เท่ากับ 0`,
          };

          error_list.push(temp_err);
        } else {
          item.retail_price = typeof item.retail_price === "string" ? parseFloat(item.retail_price?.replace(/,/g, "") || 0) : item.retail_price || 0;
        }

        if (
          !item.product_quantity
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Product Quantity`,
          };

          error_list.push(temp_err);
        } else if (item.product_quantity == 0) {
          let temp_err = {
            message: `แถวที่ ${number} Product Quantity ต้องไม่เท่ากับ 0`,
          };

          error_list.push(temp_err);
        } else {
          item.product_quantity = typeof item.product_quantity === "string" ? parseFloat(item.product_quantity?.replace(/,/g, "") || 0) : item.product_quantity || 0;

        }

        if (item.quo_garantee) {
          item.quo_garantee = typeof item.quo_garantee === "string" ? parseFloat(item.quo_garantee?.replace(/,/g, "") || 0) : item.quo_garantee || 0;
        }

        if (item.quo_full_shelf_life) {
          item.quo_full_shelf_life = typeof item.quo_full_shelf_life === "string" ? parseFloat(item.quo_full_shelf_life?.replace(/,/g, "") || 0) : item.quo_full_shelf_life || 0;
        }
        number += 1;
      }

    } else {
      let temp_err = {
        message: `กรุณาเลือก Product อย่างน้อย 1 รายการก่อนทำรายการถัดไป`,
      };

      error_list.push(temp_err);
    }

    if (quotation_data.quo_final_discount) {
      quotation_data.quo_final_discount = typeof quotation_data.quo_final_discount === "string" ? parseFloat(quotation_data.quo_final_discount?.replace(/,/g, "") || 0) : quotation_data.quo_final_discount || 0;
    }

    if (quotation_data.quo_shipping_cost) {
      quotation_data.quo_shipping_cost = typeof quotation_data.quo_shipping_cost === "string" ? parseFloat(quotation_data.quo_shipping_cost?.replace(/,/g, "") || 0) : quotation_data.quo_shipping_cost || 0;
    }

    if (quotation_data.quo_total_befor_vat) {
      quotation_data.quo_total_befor_vat = typeof quotation_data.quo_total_befor_vat === "string" ? parseFloat(quotation_data.quo_total_befor_vat?.replace(/,/g, "") || 0) : quotation_data.quo_total_befor_vat || 0;
    }

    if (quotation_data.quo_vat) {
      quotation_data.quo_vat = typeof quotation_data.quo_vat === "string" ? parseFloat(quotation_data.quo_vat?.replace(/,/g, "") || 0) : quotation_data.quo_vat || 0;
    }

    if (quotation_data.quo_total_price) {
      quotation_data.quo_total_price = typeof quotation_data.quo_total_price === "string" ? parseFloat(quotation_data.quo_total_price?.replace(/,/g, "") || 0) : quotation_data.quo_total_price || 0;
    }

    if (error_list.length > 0) {
      /* let temp_err = {
        message: `- Tab Supplier Product -`,
      };
      error_list.unshift(temp_err); */
    }

    setErrorListTab2({
      ...errorListTab2,
      data: error_list,
    });
    errorListTab2.data = error_list;
  }

  useEffect(async () => {
    /* setQuotation_data({
      ...quotation_data,
      quo_shipping_cost
    }) */
    if (mode === "edit" || mode === "view") {
      await GetQuotationRelationProduct();
    }
    await GetCategoryLv2();
    await GetProduct();
  }, []);

  useEffect(async () => {
    setInputDataTab2({
      ...inputDataTab2,
      data: selectProductData.data,
      newData: selectProductData.newData,
      updateData: selectProductData.updateData,
      delData: selectProductData.delData,
    });

    inputDataTab2.data = selectProductData.data;
    inputDataTab2.newData = selectProductData.newData;
    inputDataTab2.updateData = selectProductData.updateData;
    inputDataTab2.delData = selectProductData.delData;

    setFunctionSaveTab2({
      ...functionSaveTab2,
      data: checkErrorSelectProductData,
      getProduct: GetProduct
    });

    functionSaveTab2.data = checkErrorSelectProductData;
    functionSaveTab2.getProduct = GetProduct;
  }, [selectProductData.data, quotation_data.quo_total_price, countChangeInfo.data]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-md-3 col-xl-2 pb-2">
          <button
            hidden={true}
            data-toggle="modal"
            data-target="#modalProduct"
            ref={modalProductRef}
          />
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDisabled}
            onClick={async () => {
              await openModalProduct();
              await clearSearch(false);
            }}
          >
            Select Product
          </button>
        </div>
        <div className="col-12 col-md-3 col-xl-2">
          <h4 style={{ color: "#007bff", whiteSpace: "nowrap" }}><b>Total : {" "}
            {quotation_data.quo_total_price?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) || 0} Baht</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-md-4 col-xl-3">
          <div class="custom-control custom-switch custom-switch-md">
            <input type="checkbox" class="custom-control-input" id="customSwitch8"
              disabled={false}
              // value={quotation_data.quo_lang}
              checked={quotation_data.quo_lang}
              onChange={(event) => {
                setQuotation_data({
                  ...quotation_data,
                  quo_lang: event.target.checked,
                });
                quotation_data.quo_lang = event.target.checked;
                // console.log('event.target', event.target.checked);
              }}
            />
            <label class="custom-control-label" for="customSwitch8">TH</label>
          </div>
        </div>

        <div className="col-12 col-md-4 col-xl-2"
          style={{
            display: "flex",
            height: "auto",
            columnGap: "1rem",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
        >
          <label class="container-checkbox-product" style={{ whiteSpace: "nowrap" }}>Include VAT (7%)
            <input
              disabled={isDisabled}
              className={``}
              type="checkbox"
              id={"sbd_path_document"}
              name={"el.sbd_path_document"}
              /* value={product_data.art_work}
              */
              checked={quotation_data.quo_incl_vat}
              onClick={(e) => {
                setQuotation_data({
                  ...quotation_data,
                  quo_incl_vat: e.target.checked,
                });
                quotation_data.quo_incl_vat = e.target.checked;
                calSummaryProductTable();
              }}
            />
            <span className="checkmark-checkbox-product"></span>
          </label>
        </div>

      </div>
      <div className="row mb-3">
        <div className="col-md-12" style={{ overflow: "overlay" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <MDBDataTable
              sortable={false}
              disableRetreatAfterSorting={MDBT}
              striped
              bordered
              hover
              data={selectProducTable}
            />
          </div>
        </div>
      </div>

      <div className="row res-quotation-flex">
        <div className="col-6 col-md-6 col-xl-6 res-quotation-flex-box1">
          <div className="form-group">
            <textarea
              className="form-control"
              rows={2}
              disabled={isDisabled}
              style={{
                height: "150px",
                fontSize: "25px",
              }}
              value={quotation_data.quo_remark}
              onChange={(e) =>
                setQuotation_data({
                  ...quotation_data,
                  quo_remark: e.target.value,
                })
              }
              defaultValue=""
              required
            />
            <label>Remark</label>
          </div>
        </div>
        <div className="col-6 col-md-6 col-xl-6 pl-6 d-flex align-items-end flex-column res-quotation-flex-box1">
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ส่วนลดท้ายบิล</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  decimalScale={2}
                  // maxLength={5}
                  allowLeadingZeros={true}
                  allowNegative={false}
                  disabled={isDisabled}
                  className="form-control"
                  required
                  value={quotation_data.quo_final_discount}
                  onChange={async (e) => {
                    let value = parseFloat(e.target.value);
                    const totalBeforeVat = parseFloat(quotation_data.quo_product_total?.toString().replace(/,/g, "") || 0);
                    if (value > 100) value = 100;
                    setQuotation_data({
                      ...quotation_data,
                      quo_final_discount: value,
                      quo_final_discount_bath: ((value / 100) * totalBeforeVat)
                    });
                    quotation_data.quo_final_discount = value;
                    quotation_data.quo_final_discount_bath = ((value / 100) * totalBeforeVat);
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div className="ml-3">
              <label>% {"   "}</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              {/* <label>ส่วนลดท้ายบิล *</label> */}
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  decimalScale={2}
                  // maxLength={5}
                  allowLeadingZeros={true}
                  thousandSeparator
                  allowNegative={false}
                  disabled={isDisabled}
                  className="form-control"
                  required
                  value={quotation_data.quo_final_discount_bath}
                  onChange={async (e) => {
                    let value = parseFloat(e.target.value?.toString().replace(/,/g, "") || 0);
                    const totalBeforeVat = parseFloat(quotation_data.quo_product_total?.toString().replace(/,/g, "") || 0);
                    if (value > totalBeforeVat) value = totalBeforeVat;
                    setQuotation_data({
                      ...quotation_data,
                      quo_final_discount_bath: value,
                      quo_final_discount: ((value / totalBeforeVat) * 100)
                    });
                    quotation_data.quo_final_discount_bath = value;
                    quotation_data.quo_final_discount = ((value / totalBeforeVat) * 100);
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div className="">
              <label>บาท {"   "}</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ค่าจัดส่ง</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  // required
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={isDisabled}
                  value={quotation_data.quo_shipping_cost}
                  onChange={async (e) => {
                    let value = parseFloat(e.target.value?.replace(/,/g, ""));
                    setQuotation_data({
                      ...quotation_data,
                      quo_shipping_cost: value,
                    });
                    quotation_data.quo_shipping_cost = value;
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ราคาก่อน VAT</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  // required
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={true}
                  value={quotation_data.quo_total_befor_vat}
                  onChange={(e) => {
                    setQuotation_data({
                      ...quotation_data,
                      quo_total_befor_vat: e.target.value,
                    });
                    quotation_data.quo_total_befor_vat = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>VAT</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  // required
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={true}
                  value={quotation_data.quo_vat}
                  onChange={(e) => {
                    setQuotation_data({
                      ...quotation_data,
                      quo_vat: e.target.value,
                    });
                    quotation_data.quo_vat = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ราคารวม</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  // required
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={true}
                  value={quotation_data.quo_total_price}
                  onChange={(e) => {
                    setQuotation_data({
                      ...quotation_data,
                      quo_total_price: e.target.value,
                    });
                    quotation_data.quo_total_price = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProduct"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProduct"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{ width: "70%" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="modalProduct">
                <b>Add Product</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.code}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          code: e.target.value,
                        });
                        searchData.code = e.target.value;
                      }}
                    />
                    <label>Product Code</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          barcode: e.target.value,
                        });
                        searchData.barcode = e.target.value;
                      }}
                    />
                    <label>Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          name: e.target.value,
                        });
                        searchData.name = e.target.value;
                      }}
                    />
                    <label>Product Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      value={searchData.category}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          category: e.target.value,
                        });
                        searchData.category = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select a category
                      </option>
                      {categoryData.data.map((option, index) => (
                        <option
                          key={categoryData.data[index].cat_name}
                          value={categoryData.data[index].cat_id}
                        >
                          {categoryData.data[index].cat_name}
                        </option>
                      ))}
                    </select>
                    <label>Category</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_barcode: e.target.value,
                        });
                        searchData.customer_barcode = e.target.value;
                      }}
                    />
                    <label>Customer Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_product_name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_product_name: e.target.value,
                        });
                        searchData.customer_product_name = e.target.value;
                      }}
                    />
                    <label>Customer Product Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await GetProduct("search");
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await clearSearch();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <div className="col-md-12" style={{ overflow: "overlay" }}>
                  <div
                    style={{
                      height: "450px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MDBDataTable
                      sortable={false}
                      striped
                      bordered
                      hover
                      disableRetreatAfterSorting={MDBT}
                      data={ProductTable}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={async () => {
                  await saveProductData();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Quotation_Cute_Product;
