import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";

import moment from "moment";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";

import { tag_point_cts } from "../../component/Report";
import NumberFormat from "react-number-format";
import TableLEK from "../../component/tableLEKTTT/table";



function WipPackInOUtCTS(params) {
  const [packInOut, setPackInOut] = useState([]);
  const [test,settest] = useState({
    test:'',
  });
  const [headTable, setheadTable] = useState(
    [
      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "product_image",
        sort: "asc",
        width: 50,
      },
      /*     {
          label: "QR",
          field: "material_qr",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      /* {
          label: "Min Stock",
          field: "min_product",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "โรงชุบ",
        field: "choup_fac",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "choup_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(กก.)",
        field: "amount_kg",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "amount_piece",
        sort: "asc",
        width: 50,
      },

      {
        label: "วันที่เข้ารอ pack",
        field: "date_pack",
        sort: "asc",
        width: 50,
      },

      {
        label: "Lot deail",
        field: "lot_deail",
        sort: "asc",
        width: 50,
      },
      /* {
              label: "Lot Detail",
              field: "lot_deail",
              sort: "asc",
              width: 50,
            }, */

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
]
);
const [valueData, setvalueData] = useState([]);
const [countPage, setCountPage] = useState(1);
const [currentPage,setCurrentPage] = useState(1);
const [countData, setCountData] = useState(0);
const [entries, setEntries] = useState(10);
const [search, setSearch] = useState(0);

  const [filter_prdoct_wip_pack, setfilter_prdoct_wip_pack] = useState({
    product_no: "",
    product_name: "",
    work_choup: true,
    work_sanim: true,
    work_late: false,
    company_key: "CTS",
    oem_id: getOem(),
    company_id: getUser().com,
    all_search:''
  });

  const filterPackInOut = async (page,size,search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if(search){
      setSearch(1);
  }else{
      setSearch(0);
  }
    var get_filler_product = {
      product_no: filter_prdoct_wip_pack.product_no.trim(),
      product_name: filter_prdoct_wip_pack.product_name.trim(),
      is_work_choup: filter_prdoct_wip_pack.work_choup,
      is_work_sanim: filter_prdoct_wip_pack.work_sanim,
      is_work_late: filter_prdoct_wip_pack.work_late,
      company_key: "CTS",
      oem_id: getOem(),
      company_id: getUser().com,
      page:page || 1,
      size:size || 10,
      all_search: (filter_prdoct_wip_pack.all_search || "").trim()
    };

    /*  console.log(filter_prdoct_wip_pack); */
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/packInOutCTS/filterPackInOut_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_product,
    })
      .then(function (response) {
     

        setPackInOut(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({...test,test:''})
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
      });
    Swal.close();
  };

  useEffect(() => {
    filterPackInOut(1,10,'search');
 
  }, []);

  const Clearfilter = async () => {
    await setfilter_prdoct_wip_pack({
      ...filter_prdoct_wip_pack,
      product_no: "",
      product_name: "",
      materail_lot_start_date: "",
      materail_lot_end_date: "",
      is_waitQc: false,
      is_minStock: false,
      all_search:''
    });

    filter_prdoct_wip_pack.product_no = "";
    filter_prdoct_wip_pack.product_name = "";
    filter_prdoct_wip_pack.materail_lot_start_date = "";
    filter_prdoct_wip_pack.materail_lot_end_date = "";
    filter_prdoct_wip_pack.all_search = "";
    filter_prdoct_wip_pack.is_waitQc = false;
    filter_prdoct_wip_pack.is_minStock = false;
    filterPackInOut(1,10,'search');
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const rowsData = [];

  for (var index = 0; index < packInOut.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["product_no"] = packInOut[index].product_no;
    rowItem["product_image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            packInOut[index].imaget_path !== null
              ? Configs.API_URL_IMG + packInOut[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );

    var color_alert = "#000";

    rowItem["product_name"] = packInOut[index].product_name;
    /*  <>
        <a style={{ color: color_alert }}>{packInOut[index].product_name}</a>
      </> */

    /*  rowItem["min_product"] =(
          <>
          <a style={{ color: color_alert }}>
          { packInOut[index].min_product }
        </a>
        </>
        ) */
    var amount_piece = 0;
    var amount_weight = 0;

    for (let i of packInOut[index].lot_detail) {
      amount_weight += parseFloat(i.amount || 0);
    }

    rowItem["amount_kg"] = (
      <div style={{ textAlign: 'right' }}>
        {
          <NumberFormat
            thousandSeparator={true}
            value={amount_weight}
            displayType="text"
          />
        }
      </div>

    );
    rowItem["amount_piece"] = (
      <div style={{ textAlign: 'right' }}>
        {
          amount_weight !== null ? (
            <NumberFormat
              thousandSeparator={true}
              value={Math.floor(
                (parseFloat(amount_weight) / packInOut[index].nw) * 1000
              )}
              displayType="text"
            />
          ) : packInOut[index].nw === null ? (
            "ไม่พบ n/w"
          ) : (
            "0"
          )
        }
      </div>
    );
    /*      rowItem["date_pack"] =  */
    rowItem["choup_fac"] = packInOut[index].lot_detail.map((e) => {
      var color = "#000";
      if (packInOut[index].surface === "น้ำมันกันสนิม") {
        if (moment(new Date()).diff(moment(e.date), "days") > 90) {
          color = "red";
        }
      } else {
        if (moment(new Date()).diff(moment(e.date), "days") > 180) {
          color = "red";
        }
      }
      return (
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              marginTop: "-9px",
              marginBottom: "-9px",
            }}
          >
            <font color={color}>
              <b>{e.choup_fac || "ไม่มี"}</b>
            </font>
          </div>

          <hr />
        </>
      );
    });
    rowItem["choup_tel"] = packInOut[index].lot_detail.map((e) => {
      var color = "#000";
      if (packInOut[index].surface === "น้ำมันกันสนิม") {
        if (moment(new Date()).diff(moment(e.date), "days") > 90) {
          color = "red";
        }
      } else {
        if (moment(new Date()).diff(moment(e.date), "days") > 180) {
          color = "red";
        }
      }
      return (
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              textAlign: "center",
              marginTop: "-9px",
              marginBottom: "-9px",
            }}
          >
            <font color={color}>
              <b>
                {e.choup_tel !== null ? (
                  <a href={"tel:" + e.choup_tel}>{e.choup_tel}</a>
                ) : (
                  "ไม่ระบุ"
                )}
              </b>
            </font>
          </div>

          <hr />
        </>
      );
    });
    rowItem["lot_deail"] = packInOut[index].lot_detail.map((e) => {
      var color = "#000";
      if (packInOut[index].surface === "น้ำมันกันสนิม") {
        if (moment(new Date()).diff(moment(e.date), "days") > 90) {
          color = "red";
        }
      } else {
        if (moment(new Date()).diff(moment(e.date), "days") > 180) {
          color = "red";
        }
      }
      return (
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              marginTop: "-9px",
              marginBottom: "-9px",
            }}
          >
            <font color={color}>
              <b>
                {e.lot_no} {e.pack_no}
              </b>
            </font>
          </div>

          <hr />
        </>
      );
    });

    rowItem["date_pack"] = packInOut[index].lot_detail.map((e) => {
      var color = "#000";
      if (packInOut[index].surface === "น้ำมันกันสนิม") {
        if (moment(new Date()).diff(moment(e.date), "days") > 90) {
          color = "red";
        }
      } else {
        if (moment(new Date()).diff(moment(e.date), "days") > 180) {
          color = "red";
        }
      }
      return (
        <>
          <div
            style={{
              whiteSpace: "nowrap",
              marginTop: "-9px",
              marginBottom: "-9px",
            }}
          >
            <font color={color}>
              <b>{moment(e.date).format("DD/MM/YYYY  HH:mm น.")}</b>
            </font>
          </div>

          <hr />
        </>
      );
    });

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Pack_In_OUt/mgt/" +
              packInOut[index].product_id
            }
            key={packInOut[index].product_id}
            className=" btn btn-xs " /* onClick={editpage.bind(this,materail[index].id)} */
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  const conditionalRowStyles = [
    {
      when: (row) => row.amount_kg > -1,
      style: {
        backgroundColor: "yellow",
      },
    },
  ];

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "product_image",
        sort: "asc",
        width: 50,
      },
      /*     {
          label: "QR",
          field: "material_qr",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      /* {
          label: "Min Stock",
          field: "min_product",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "โรงชุบ",
        field: "choup_fac",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "choup_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(กก.)",
        field: "amount_kg",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "amount_piece",
        sort: "asc",
        width: 50,
      },

      {
        label: "วันที่เข้ารอ pack",
        field: "date_pack",
        sort: "asc",
        width: 50,
      },

      {
        label: "Lot deail",
        field: "lot_deail",
        sort: "asc",
        width: 50,
      },
      /* {
              label: "Lot Detail",
              field: "lot_deail",
              sort: "asc",
              width: 50,
            }, */

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  const linkreport = async () => {
    var loopreport = [];
    for (let index = 0; index < packInOut.length; index++) {
      var loghard = packInOut[index].lot_detail;
      if (loghard.length > 0) {
        for (let i = 0; i < loghard.length; i++) {
          if (loghard[i].track_no !== null) {
            var as = {
              tracking_no: loghard[i].track_no,
              box_no: loghard[i].pack_no,
              customer_name: loghard[i].customer,
              plated: loghard[i].surface || "",
              type_amount: loghard[i].type_amount === "weight" ? "kg." : "pieces",
              amount: loghard[i].amount,
              product_name: packInOut[index].product_name,
              product_no: packInOut[index].product_no,
              order_no: loghard[i].lot_no || "ไม่ระบุ",
              po_no: loghard[i].po_no || "-",
            };

            loopreport.push(as);
          }
        }
      }
    }
    loopreport.sort(function (a, b) {
      return ("" + a.box_no).localeCompare(b.box_no);
    });
    tag_point_cts(loopreport, getUser().sub, "pack");
  };

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  งานรอ Pack IN/OUT{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">งานรอ Pack IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_prdoct_wip_pack.all_search}
                    onChange={(e) => {
                      setfilter_prdoct_wip_pack({
                        ...filter_prdoct_wip_pack,
                        all_search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_prdoct_wip_pack.product_no}
                    onChange={(e) => {
                      setfilter_prdoct_wip_pack({
                        ...filter_prdoct_wip_pack,
                        product_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product No.</label>{" "}
                </div>
              </div>
              <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_prdoct_wip_pack.product_name}
                    onChange={(e) => {
                      setfilter_prdoct_wip_pack({
                        ...filter_prdoct_wip_pack,
                        product_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                  
                      filterPackInOut(1,10,'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => Clearfilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => linkreport()}
                  >
                    Print ป้ายชี้บ่ง
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                className="col-12 col-md-4 col-xl-2"
                style={{ textAlign: "left" }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="work_choup"
                  name="work_choup"
                  value="work_choup"
                  checked={filter_prdoct_wip_pack.work_choup}
                  onChange={(event) => {
                    setfilter_prdoct_wip_pack({
                      ...filter_prdoct_wip_pack,
                      work_choup: event.currentTarget.checked,
                    });
                    filter_prdoct_wip_pack.work_choup =
                      event.currentTarget.checked;
                      filterPackInOut(1,10,'search');
                  }}
                />
                {/*  {console.log(  product.is_virtual)} */}
                <label htmlFor="work_choup">งานชุบ</label>
                {/*            </div> */}
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                style={{ textAlign: "left" }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="work_sanim"
                  name="work_sanim"
                  value="work_sanim"
                  checked={filter_prdoct_wip_pack.work_sanim}
                  onChange={(event) => {
                    setfilter_prdoct_wip_pack({
                      ...filter_prdoct_wip_pack,
                      work_sanim: event.currentTarget.checked,
                    });
                    filter_prdoct_wip_pack.work_sanim =
                      event.currentTarget.checked;
                      filterPackInOut(1,10,'search');
                  }}
                />
                {/*  {console.log(  product.is_virtual)} */}
                <label htmlFor="work_sanim">งานกันสนิม</label>
                {/*            </div> */}
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                style={{ textAlign: "left" }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="work_late"
                  name="work_late"
                  value="work_late"
                  checked={filter_prdoct_wip_pack.work_late}
                  onChange={(event) => {
                    setfilter_prdoct_wip_pack({
                      ...filter_prdoct_wip_pack,
                      work_late: event.currentTarget.checked,
                    });
                    filter_prdoct_wip_pack.work_late =
                      event.currentTarget.checked;
                      filterPackInOut(1,10,'search');
                  }}
                />
                {/*  {console.log(  product.is_virtual)} */}
                <label htmlFor="work_late">งานค้างนาน</label>
                {/*            </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WIP Pack IN/OUT</h3>
              <div className="card-tools"></div>
            </div>


            <div style= {{padding:'2%'}}>
            <TableLEK       

                               
                                headTable = {headTable}
                                dataTable = {rowsData}
                                countPage = {countPage}
                                countData = {countData}
                                entries = {entries}
                                isSearch = {search}
                                callFnc ={filterPackInOut}
                                
                                
                                />
                                </div>

         {/*    <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div> */}
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}
export default WipPackInOUtCTS;
