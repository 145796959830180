import React, { useEffect, useState, useRef } from "react";
import { Card, Pagination } from "antd";
import { CardBody } from "reactstrap";
import './table_all_project.css';

function Table_All_Project({ cardData, onPageChange, currentPage, pageSize, onChange, onShowSizeChange, total, minWidth }) {
  
  // คำนวณจำนวนข้อมูลทั้งหมด
  const totalItems = Array.isArray(total)? total.length : total || 0;
// console.log('currentPage', currentPage)
  // คำนวณหน้าทั้งหมดโดยใช้ pageSize และจำนวนข้อมูลทั้งหมด
  const totalPages = Math.ceil(totalItems / pageSize);

  return (
    <>
        <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
      <div className="row mt-3" style={minWidth ? { minWidth: minWidth, marginLeft: '0.00001rem', marginRight: '0.00001rem' } : { }}>
          {cardData.map((card) => (
            <Card bodyStyle={{ padding: '0 30px', height: '100%' }} key={card.id} style={{ boxShadow: '0px 4px 30px 0px #00000015', padding: '0px', width: "100%", height:"100px" ,marginBottom: "15px" }}>
              <div style={{ height: '100%' }}>{card.content}</div>
            </Card>
          ))}
        </div>
      </div>
      <div className="row align-items-center justify-content-end ml-1 mr-1" style={{ marginTop:"50px" }}>
        <div><span>{`Total ${Number(totalItems).toLocaleString()} items`}</span></div>
        <Pagination
          showQuickJumper
          showSizeChanger
          pageSize={pageSize}
          current={currentPage}
          defaultCurrent={currentPage}
          pageSizeOptions={[10,20,50,100,250]}
          total={totalItems}
          onChange={onChange}
          onShowSizeChange={onShowSizeChange}
        />
    
      </div>
    </>
  );
}

export default Table_All_Project;