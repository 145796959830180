import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../../config";
import React, { useState } from "react";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import { useEffect } from "react";
import "../../../../../assets/css/product_incentive.css";
import product_excel from "../../../../../assets/file/CutePlus/Import_Excel_Template.xlsx";

import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

function Product_Table_plus() {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modal_1, setModal_1] = useState(false);
  const toggle_1 = () => setModal_1(!modal_1);

  const [Filter, setFilter] = useState({
    code: "",
    name: "",
    category: "",
  });

  const [Product, setProduct] = useState({
    data: [],
  });

  const [ImportProduct, setImportProduct] = useState({
    checkError: null,
    data: [],
  });

  const [modalData, setModalData] = useState({
    File_name: "",
    File_path: "",
  });

  const [File, setFile] = useState(null);

  const [Category, setCategory] = useState({
    data: [],
  });

  const allowedTypes = [
    // "image/png",
    // "image/jpeg",
    // "text/csv",
    // "application/msword",
    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const filterCheckboxProductKitting = async (is_Check) => {
    // console.log('is_Check', is_Check);
    if (is_Check.target.checked === true) {
      const tempProduct = Product.data.filter(
        (el) => true === el.product_is_kitting
      );
      setProduct({
        ...Product,
        data: tempProduct,
      });
      Product.data = tempProduct;
    } else {
      GetProduct();
    }
  };

  const GetProduct = async () => {
    let temp = {
      code: Filter.code.trim() || "",
      name: Filter.name.trim() || "",
      category: Filter.category.trim() || "",
      oem_id: getOem(),
      company_id: getUser().com,
      main: true,
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWherecat2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        if (response) {
          for (let item of response.data) {
            const product = item;
            if (Array.isArray(product.barcode) && product.barcode.length > 0) {
              const arrlist = product.barcode;
              let str = "";
              str = arrlist.join(",\n");
              /* .forEach((element, index) => {
                str += element;

                if (arrlist.length - 1 !== index) {
                  str += ",\n";
                }
              }); */
              product.barcode = str;
            }
          }
          setProduct({ data: response.data });
          Product.data = response.data;
          /////////////////////////////////////
          let data = new FormData();
          data.append("company_id", getUser().com);
          data.append("oem_id", getOem());
          data.append("user_id", getUser().fup);
          data.append("user_name", `${getUser().name} ${getUser().surname}`);
          data.append("products", JSON.stringify(response.data));

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "multipart/form-data",
            },
            data: data,
          })
            .then(async (response) => {
              setProduct({ data: response.data });
              Product.data = response.data;
              return response.data;
            })
            .catch(async (error) => {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
              });
            });

          /////////////////////////////////////
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const GetCategory = async () => {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategoryLv2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategory({ data: response.data });
        Category.data = response.data;
        // console.log(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function ImportExcel() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setModalData({
          File_name: "",
          File_path: "",
        });

        setFile(null);

        toggle();

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        })
          .then(async () => {
            let tempData = new FormData();
            tempData.append("file", File);
            tempData.append("company_id", getUser().com);
            tempData.append("oem_id", getOem());
            tempData.append("user_id", getUser().fup);
            tempData.append("user_name", `${getUser().name} ${getUser().surname}`);

            await axios({
              method: "post",
              url: Configs.API_URL_cuteplus + "/api/product/ImportExcel",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            }).then(function (response) {
              console.log(response.data);
              setImportProduct({
                ...ImportProduct,
                checkError: response.data.checkError,
                data: response.data.data,
              });
              ImportProduct.data = response.data;

              Swal.close();
              toggle_1();
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  function clear() {
    setFilter({
      ...Filter,
      code: "",
      name: "",
      category: "",
    });
    Filter.code = "";
    Filter.name = "";
    Filter.category = "";
    GetProduct();
  }

  function delProduct(e) {
    // console.log("id", e.product_id);
    const dataSend = {
      company_id: getUser().com,
      oem_id: getOem(),
      p_id: e.product_id,
    };
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/product/delProduct",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: dataSend,
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              let arrList = [];
              window.location.reload();
              /* arrList = perchase_po.filter((po) => po.perchaseTable.po_id !== e.perchaseTable.po_id);
                        setPerchase_po(arrList);
                        setTest({ ...test, test: '' }); */
              // window.location.href = "/Human_Resource/HRD/External_dev_train";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  const rowsData = [];
  for (let index = 0; index < Product.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["barcode"] = Product.data[index].barcode ? (
      <span style={{ whiteSpace: "pre-line" }}>
        {typeof Product.data[index].barcode === "string"
          ? Product.data[index].barcode
          : "-"}
      </span>
    ) : (
      "-"
    );

    rowItem["product_code"] = Product.data[index].product_code
      ? Product.data[index].product_code
      : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="..."
          className="img-fluid rounded shadow border-0"
          src={
            Product.data[index].pci_img_path !== "" &&
            Product.data[index].pci_img_path !== null &&
            Product.data[index].pci_img_path !== undefined
              ? Product.data[index].pci_img_path?.indexOf("blob") >= 0
                ? Product.data[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${Product.data[index].pci_img_name}/${Product.data[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = Product.data[index].product_name_eng
      ? Product.data[index].product_name_eng
      : "-";

    rowItem["category"] = Product.data[index].cat_name
      ? Product.data[index].cat_name
      : "-";

      rowItem["status"] = Product.data[index].pst_name
      ? Product.data[index].pst_name
      : "-";

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Product.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus/view/" +
                Product.data[index].product_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <a
            key={Product.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus/edit/" +
                Product.data[index].product_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.deleteBtn ? "hidden" : "visible" }} */
        >
          <a
            key={Product.data[index]}
            id={Product.data[index]}
            className=" btn btn-xs "
            onClick={delProduct.bind(this, Product.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Category Lv2",
        field: "category",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const rowsDataImportTable = [];
  for (let index = 0; index < ImportProduct.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["Product_Code"] = ImportProduct.data[index].Product_Code;

    rowItem["Product_Name"] = ImportProduct.data[index].Product_Name;

    rowItem["Status"] = ImportProduct.checkError ? (
      <Button disabled color="btn btn-danger w-100" style={{ opacity: 1 }}>
        Error
      </Button>
    ) : ImportProduct.data[index].status ? (
      <Button disabled color="btn btn-warning w-100" style={{ opacity: 1 }}>
        Update
      </Button>
    ) : (
      <Button disabled color="btn btn-success w-100" style={{ opacity: 1 }}>
        Success
      </Button>
    );

    var err_message = "";
    for (var e = 0; e < ImportProduct.data[index].remark.length; e++) {
      if (err_message === "") {
        err_message += ImportProduct.data[index].remark[e].message;
      } else {
        err_message += "<br/>" + ImportProduct.data[index].remark[e].message;
      }
    }

    rowItem["Remark"] = (
      <div dangerouslySetInnerHTML={{ __html: err_message }} />
    );

    rowsDataImportTable.push(rowItem);
  }

  const ImportTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Code",
        field: "Product_Code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "Product_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "Remark",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsDataImportTable,
  };

  useEffect(async () => {
    GetProduct();
    GetCategory();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Product</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.code}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        code: e.target.value,
                      });
                      Filter.code = e.target.value;
                    }}
                  />
                  <label>Product Code</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        name: e.target.value,
                      });
                      Filter.name = e.target.value;
                    }}
                  />
                  <label>Product Name</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    type="text"
                    className="form-control"
                    required
                    value={Filter.category}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        category: e.target.value,
                      });
                      Filter.category = e.target.value;
                    }}
                  >
                    <option key="" value="">
                      Select a category
                    </option>
                    {Category.data.map((option, index) => (
                      <option
                        key={Category.data[index].cat_name}
                        value={Category.data[index].cat_id}
                      >
                        {Category.data[index].cat_name}
                      </option>
                    ))}
                  </select>
                  <label>Category Lv.2</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      GetProduct();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <a
                    href={product_excel}
                    download="Import_Excel_Template.xlsx"
                    style={{ fontSize: "" }}
                  >
                    <button className="btn btn-block btn-primary">
                      Download Template
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    onClick={() => {
                      toggle();
                    }}
                    className="btn btn-block btn-primary"
                  >
                    Import Excel
                  </button>
                </div>
              </div>
              <div
                className="col-6 col-md-4 col-xl-2 pl-3"
                style={{
                  display: "flex",
                  height: "auto",
                  columnGap: "1rem",
                  alignItems: "center",
                  fontSize: "1.5rem",
                }}
              >
                <label class="container-checkbox-product">
                  Product Kitting
                  <input
                    className={``}
                    type="checkbox"
                    id={"sbd_path_document"}
                    name={"el.sbd_path_document"}
                    /* value={product_data.art_work}
                    checked={product_data.art_work} */
                    onClick={(e) => {
                      filterCheckboxProductKitting(e);
                    }}
                  />
                  <span className="checkmark-checkbox-product"></span>
                </label>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={ProductTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={modal}
        toggle={() => {
          setModalData({
            File_name: "",
            File_path: "",
          });

          setFile(null);

          toggle();
        }}
        size="md"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModalData({
              File_name: "",
              File_path: "",
            });

            setFile(null);

            toggle();
          }}
        >
          <h3>Import Excel</h3>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-4">
              <label>File Upload<span style={{ color: 'red' }}> *</span></label>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    // disabled={isDisabled}
                    id="exampleInputFile"
                    accept={allowedTypes}
                    onChange={(e) => {
                      if (e.target.files[0] !== undefined) {
                        if (allowedTypes.includes(e.target.files[0]?.type)) {
                          const file = e.target.files[0];

                          if (file.name) {
                            setFile(e.target.files[0]);

                            setModalData({
                              ...modalData,
                              File_name: e.target.files[0].name,
                              File_path: URL.createObjectURL(e.target.files[0]),
                            });
                          }
                        } else {
                          Swal.fire(
                            "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
                            "กรุณาอัปโหลดประเภทไฟล์ EXCEL",
                            "error"
                          );

                          setFile(null);

                          setModalData({
                            ...modalData,
                            File_name: "",
                            File_path: "",
                          });

                          document.getElementById("exampleInputFile").value =
                            null;
                        }
                      } else {
                        setFile(null);

                        setModalData({
                          ...modalData,
                          File_name: "",
                          File_path: "",
                        });

                        document.getElementById("exampleInputFile").value =
                          null;
                      }
                    }}
                  />
                  <label
                    className="custom-file-label textVerticalOverflow-1"
                    htmlFor="exampleInputFile"
                  >
                    {modalData.File_name}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setModalData({
                File_name: "",
                File_path: "",
              });

              setFile(null);

              toggle();
            }}
          >
            ยกเลิก
          </Button>
          {File !== null ? (
            <Button
              color="btn btn-info"
              onClick={async () => {
                await ImportExcel();

                // setModalData({
                //   File_name: "",
                //   File_path: "",
                // });

                // setFile(null);

                // toggle();
              }}
            >
              บันทึก
            </Button>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modal_1}
        toggle={() => {
          setImportProduct({
            data: [],
          });

          toggle_1();

          window.location.reload();
        }}
        size="xl"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setImportProduct({
              data: [],
            });

            toggle_1();

            window.location.reload();
          }}
        >
          <h3>Import Excel</h3>
        </ModalHeader>

        <ModalBody>
          <div className="table-responsive " style={{ whiteSpace: "nowrap" }}>
            <MDBDataTable
              sortable={false}
              className="table table-head-fixed"
              striped
              bordered
              hover
              fixedHeader
              data={ImportTable}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="btn btn-info"
            onClick={async () => {
              setImportProduct({
                data: [],
              });

              toggle_1();

              window.location.reload();
            }}
          >
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Product_Table_plus;
