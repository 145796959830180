import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

function Horizontal_Bar_Chart(params) {
  ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels
  );
  // unregister

  function numberWithCommas(x) {
    return x
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // console.log(params.DataChart);
  let newHeight = 300;
  if (params.setHeight) {
    newHeight = params.setHeight;
  } else {
    if (params.DataChart.labels.length > 0) {
      newHeight = 300 + params.DataChart.labels.length * 20;
    }
  }

  const options = {
    plugins: {
      datalabels: {
        formatter: function (value) {
          /*        console.log(value)
          let val = Math.round(value);
          console.log(val) */
          let str_show = value + "%";
          return str_show; //for number format
        },
        color: "white",
        font: {
          weight: "bold",
          size: 14,
          family: "poppins",
        },
      },
      responsive: true,
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "0px",
        }}
      >
        {params.Text != "" && (
          <label
            style={{
              fontSize: "23px",
              lineHeight: "normal",
              margin: "0px",
              display: "block",
              textAlign: "center",
              margin: "0px 0px 15px 0px",
            }}
          >
            {params.Text}
          </label>
        )}
        <div
          className="customScroll-1"
          style={{
            // height: '100%'
            height: params.isFullView
              ? "750px"
              : params.height
              ? params.height
              : "320px",
          }}
        >
          <Bar
            data={params.DataChart}
            height={params.height || "320px"}
            options={{
              onHover: (event, chartElement) => {
                if (params.func) {
                  if (chartElement.length == 1) {
                    event.native.target.style.cursor = "pointer";
                  }
                }
              },
              maintainAspectRatio: false,
              indexAxis: "y",
              scales: {
                x: {
                  display: false, // ซ่อนแกน X
                  grid: {
                    display: false,
                    color: "#DAEBF2",
                  },
                  ticks: {
                    display: false, // Keep y-axis labels visible
                    color: "#000", // Customize the label color if needed
                  },
                  border: {
                    display: false, // Hide y-axis line
                  },
                  stacked: params.xStacked,
                  grace: "50%",
                },
                y: {
                  min: 0, // Set the minimum value
                  // max: 6,
                  grid: {
                    display: false,
                    color: "#DAEBF2",
                  },
                  border: {
                    display: false, // Hide y-axis line
                  },
                  ticks: {
                    display: true,
                  },
                  stacked: params.yStacked,
                  grace: "50%",
                },
              },
              plugins: {
                legend: {
                  display: false,
                  labels: {
                    /*  useBorderRadius:true,
                       borderRadius:5, */
                    boxWidth: 12,
                    boxHeight: 12,
                    color: "#96A5D6",
                  },
                },
                datalabels: {
                  formatter: function (value) {
                    let str_show = Number(value || 0)
                      ? Number(value || 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0;
                    return str_show; //for number format
                  },
                  align: "end",
                  anchor: "end",
                  color: "#000",
                  borderRadius: 20,
                  font: {
                    // weight: 'bold',
                    size: 10,
                    // family: "poppins",
                  },
                  listeners: {
                    enter: function (context) {
                      const chart = context?.chart;
                      if (chart) {
                        const tooltip = chart.tooltip;
                        tooltip.setActiveElements([
                          {
                            datasetIndex: context.datasetIndex,
                            index: context.dataIndex,
                          },
                        ]);
                        chart.update();
                      }
                    },
                    leave: function (context) {
                      const chart = context?.chart;
                      if (chart) {
                        const tooltip = chart.tooltip;
                        tooltip.setActiveElements([]);
                        chart.update();
                      }
                    },
                  },
                },
                responsive: true,
              },
              onClick: function (event, elements) {
                if (params.func && elements.length > 0) {
                  let clickedIndex = elements[0].index,
                    index = elements[0].datasetIndex;
                  let label = params.DataChart.labels[clickedIndex];
                  let value = params.DataChart.datasets[index].label;
                  /*                console.log(label,params.DataChart.datasets,elements); */
                  params.func({ label: label, value: value }, params.pos);
                }
              },
            }}
          />
        </div>
      </div>
    </>
  );
}
export default Horizontal_Bar_Chart;
