import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";
import jsPDF from "jspdf";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import { userdefault_img, logo_smz } from "../../routes/imgRoute/imgUrl";
import { useParams } from "react-router";

function Travel_expenses(params) {
  const { start_date, end_date } = useParams();
  const [empTravelExpen, setEmpTravelExpen] = useState([]);
  const [emptype, setEmptype] = useState([]);
  const [filterOption, setFilterOption] = useState({
    emp_no: "",
    emp_name_th: "",
    emp_type_id: "",
    start_day: "",
    start_month: "",
    start_year: "",
    end_day: "",
    end_month: "",
    end_year: "",
    start_date: "",
    end_date: "",
  });
  const [stateDate, setStateDate] = useState({
    start_date: "",
    end_date: "",
  });

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithOutCommas = (x) => {
    return x.toString().replace(/,/g, "");
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const GetEmpTravelExpen = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: filterOption.emp_no.trim(),
      emp_name_th: filterOption.emp_name_th.trim(),
      emp_type_id: filterOption.emp_type_id,
      start_day: filterOption.start_day,
      start_month: filterOption.start_month,
      start_year: filterOption.start_year,
      end_day: filterOption.end_day,
      end_month: filterOption.end_month,
      end_year: filterOption.end_year,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getTravelExpenses",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        setEmpTravelExpen(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetEmployeeType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        setEmptype(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const rowsData = [];
  for (let index = 0; index < empTravelExpen.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["name_surname"] = empTravelExpen[index].emp_name_th;

    rowItem["employee_code"] = empTravelExpen[index].emp_no;

    rowItem["type"] = empTravelExpen[index].period_time_name;

    rowItem["time_travel_expenses"] = numberWithCommas(
      empTravelExpen[index].sum_extra_cost
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-4 col-md-4 col-xs-4">
          <a
            href={`/Human_Resource/ค่าเที่ยว/view/${
              empTravelExpen[index].emp_id
            }/${moment(stateDate.start_date).format("YYYY-MM-DD")}/${moment(
              stateDate.end_date
            ).format("YYYY-MM-DD")}`}
            id="btn_read"
            key={empTravelExpen[index].emp_id}
            className="btn btn-xs "
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-4 col-md-4 col-xs-4">
          <a
            href={`/Human_Resource/ค่าเที่ยว/edit/${
              empTravelExpen[index].emp_id
            }/${moment(stateDate.start_date).format("YYYY-MM-DD")}/${moment(
              stateDate.end_date
            ).format("YYYY-MM-DD")}`}
            id="btn_edit"
            key={empTravelExpen[index].emp_id}
            className=" btn btn-xs "
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ-นามสกุล",
        field: "name_surname",
        // sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "employee_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ประเภท",
        field: "type",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ค่าเที่ยวตามช่วงเวลา",
        field: "time_travel_expenses",
        // sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const clearFilter = async () => {
    const currentDate = new Date();

    if (currentDate.getDate() <= 15) {
      setFilterOption({
        ...filterOption,
        emp_no: "",
        emp_name_th: "",
        emp_type_id: "30648047-0836-4cb3-afe9-a03faf5b8468",
        start_day: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).getDate(),
        start_month: currentDate.getMonth() + 1,
        start_year: currentDate.getFullYear(),
        end_day: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          15
        ).getDate(),
        end_month: currentDate.getMonth() + 1,
        end_year: currentDate.getFullYear(),
        start_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ),
        end_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          15
        ),
      });

      filterOption.emp_no = "";
      filterOption.emp_name_th = "";
      filterOption.emp_type_id = "30648047-0836-4cb3-afe9-a03faf5b8468";
      filterOption.start_day = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      ).getDate();
      filterOption.start_month = currentDate.getMonth() + 1;
      filterOption.start_year = currentDate.getFullYear();
      filterOption.end_day = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        15
      ).getDate();
      filterOption.end_month = currentDate.getMonth() + 1;
      filterOption.end_year = currentDate.getFullYear();
      filterOption.start_date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      filterOption.end_date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        15
      );

      setStateDate({
        ...stateDate,
        start_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ),
        end_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          15
        ),
      });
    } else {
      setFilterOption({
        ...filterOption,
        emp_no: "",
        emp_name_th: "",
        emp_type_id: "30648047-0836-4cb3-afe9-a03faf5b8468",
        start_day: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          16
        ).getDate(),
        start_month: currentDate.getMonth() + 1,
        start_year: currentDate.getFullYear(),
        end_day: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate(),
        end_month: currentDate.getMonth() + 1,
        end_year: currentDate.getFullYear(),
        start_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          16
        ),
        end_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ),
      });

      filterOption.emp_no = "";
      filterOption.emp_name_th = "";
      filterOption.emp_type_id = "30648047-0836-4cb3-afe9-a03faf5b8468";
      filterOption.start_day = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        16
      ).getDate();
      filterOption.start_month = currentDate.getMonth() + 1;
      filterOption.start_year = currentDate.getFullYear();
      filterOption.end_day = filterOption.end_day = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
      filterOption.end_month = currentDate.getMonth() + 1;
      filterOption.end_year = currentDate.getFullYear();
      filterOption.start_date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        16
      );
      filterOption.end_date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      setStateDate({
        ...stateDate,
        start_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          16
        ),
        end_date: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ),
      });
    }

    await GetEmpTravelExpen();
  };

  useEffect(async () => {
    const currentDate = new Date();

    if (currentDate.getDate() <= 15) {
      setFilterOption({
        ...filterOption,
        emp_type_id: "30648047-0836-4cb3-afe9-a03faf5b8468",
        start_day:
          start_date === undefined
            ? new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
              ).getDate()
            : new Date(start_date).getDate(),
        start_month:
          start_date === undefined
            ? currentDate.getMonth() + 1
            : new Date(start_date).getMonth() + 1,
        start_year:
          start_date === undefined
            ? currentDate.getFullYear()
            : new Date(start_date).getFullYear(),
        end_day:
          end_date === undefined
            ? new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                15
              ).getDate()
            : new Date(end_date).getDate(),
        end_month:
          end_date === undefined
            ? currentDate.getMonth() + 1
            : new Date(end_date).getMonth() + 1,
        end_year:
          end_date === undefined
            ? currentDate.getFullYear()
            : new Date(end_date).getFullYear(),
        start_date:
          start_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
            : new Date(start_date),
        end_date:
          end_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 15)
            : new Date(end_date),
      });

      filterOption.emp_type_id = "30648047-0836-4cb3-afe9-a03faf5b8468";
      filterOption.start_day =
        start_date === undefined
          ? new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1
            ).getDate()
          : new Date(start_date).getDate();
      filterOption.start_month =
        start_date === undefined
          ? currentDate.getMonth() + 1
          : new Date(start_date).getMonth() + 1;
      filterOption.start_year =
        start_date === undefined
          ? currentDate.getFullYear()
          : new Date(start_date).getFullYear();
      filterOption.end_day =
        end_date === undefined
          ? (filterOption.end_day = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              15
            ).getDate())
          : new Date(end_date).getDate();
      filterOption.end_month =
        end_date === undefined
          ? currentDate.getMonth() + 1
          : new Date(end_date).getMonth() + 1;
      filterOption.end_year =
        end_date === undefined
          ? currentDate.getFullYear()
          : new Date(end_date).getFullYear();
      filterOption.start_date =
        start_date === undefined
          ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
          : new Date(start_date);
      filterOption.end_date =
        end_date === undefined
          ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 15)
          : new Date(end_date);

      setStateDate({
        ...stateDate,
        start_date:
          start_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
            : new Date(start_date),
        end_date:
          end_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 15)
            : new Date(end_date),
      });
    } else {
      setFilterOption({
        ...filterOption,
        emp_type_id: "30648047-0836-4cb3-afe9-a03faf5b8468",
        start_day:
          start_date === undefined
            ? new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                16
              ).getDate()
            : new Date(start_date).getDate(),
        start_month:
          start_date === undefined
            ? currentDate.getMonth() + 1
            : new Date(start_date).getMonth() + 1,
        start_year:
          start_date === undefined
            ? currentDate.getFullYear()
            : new Date(start_date).getFullYear(),
        end_day:
          end_date === undefined
            ? new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
              ).getDate()
            : new Date(end_date).getDate(),
        end_month:
          end_date === undefined
            ? currentDate.getMonth() + 1
            : new Date(end_date).getMonth() + 1,
        end_year:
          end_date === undefined
            ? currentDate.getFullYear()
            : new Date(end_date).getFullYear(),
        start_date:
          start_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 16)
            : new Date(start_date),
        end_date:
          end_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
            : new Date(end_date),
      });

      filterOption.emp_type_id = "30648047-0836-4cb3-afe9-a03faf5b8468";
      filterOption.start_day =
        start_date === undefined
          ? new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              16
            ).getDate()
          : new Date(start_date).getDate();
      filterOption.start_month =
        start_date === undefined
          ? currentDate.getMonth() + 1
          : new Date(start_date).getMonth() + 1;
      filterOption.start_year =
        start_date === undefined
          ? currentDate.getFullYear()
          : new Date(start_date).getFullYear();
      filterOption.end_day =
        end_date === undefined
          ? (filterOption.end_day = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() + 1,
              0
            ).getDate())
          : new Date(end_date).getDate();
      filterOption.end_month =
        end_date === undefined
          ? currentDate.getMonth() + 1
          : new Date(end_date).getMonth() + 1;
      filterOption.end_year =
        end_date === undefined
          ? currentDate.getFullYear()
          : new Date(end_date).getFullYear();
      filterOption.start_date =
        start_date === undefined
          ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 16)
          : new Date(start_date);
      filterOption.end_date =
        end_date === undefined
          ? new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
          : new Date(end_date);

      setStateDate({
        ...stateDate,
        start_date:
          start_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth(), 16)
            : new Date(start_date),
        end_date:
          end_date === undefined
            ? new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
            : new Date(end_date),
      });
    }

    await GetEmpTravelExpen();
    await GetEmployeeType();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  ค่าเที่ยว{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">ค่าเที่ยว</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    id="text_empid"
                    required="false"
                    value={filterOption.emp_no}
                    onChange={(e) => {
                      setFilterOption({
                        ...filterOption,
                        emp_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">รหัส</label>{" "}
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    id="text_empid"
                    required="false"
                    value={filterOption.emp_name_th}
                    onChange={(e) => {
                      setFilterOption({
                        ...filterOption,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <select
                    className="form-control custom-select select2"
                    style={{ fontSize: "22px", lineHeight: "normal" }}
                    type="text"
                    required
                    id="drop_employee_type"
                    value={filterOption.emp_type_id}
                    onChange={(e) => {
                      setFilterOption({
                        ...filterOption,
                        emp_type_id: e.target.value,
                      });
                    }}
                  >
                    {/* <option value="" disabled selected> */}
                    <option style={{ fontSize: "18px" }} value="" selected>
                      เลือกทุกประเภท
                    </option>

                    {emptype.map((el) => {
                      return (
                        <option
                          style={{ fontSize: "18px" }}
                          value={el.period_time_id}
                        >
                          {el.period_time}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">ประเภทพนักงาน</label>
                </div>
              </div>

              <div
                className="col-6 col-md-4 col-xl-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filterOption.start_date}
                    className="form-control"
                    wrapperClassName="w-100"
                    id="btn_end_date"
                    //required="false"
                    dateFormat={"dd-MM-yyyy"}
                    customInput={<CustomInput />}
                    onChange={async (date) => {
                      setFilterOption({
                        ...filterOption,
                        start_date: date,
                        start_day: new Date(date).getDate(),
                        start_month: new Date(date).getMonth() + 1,
                        start_year: new Date(date).getFullYear(),
                      });
                    }}
                    selectsStart
                    startDate={filterOption.start_date}
                    maxDate={filterOption.end_date}
                  />
                  <label htmlFor="">วันที่เริ่มต้น</label>
                </div>
              </div>

              <div
                className="col-6 col-md-4 col-xl-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filterOption.end_date}
                    className="form-control"
                    wrapperClassName="w-100"
                    id="btn_end_date"
                    //required="false"
                    dateFormat={"dd-MM-yyyy"}
                    customInput={<CustomInput />}
                    onChange={async (date) => {
                      setFilterOption({
                        ...filterOption,
                        end_date: date,
                        end_day: new Date(date).getDate(),
                        end_month: new Date(date).getMonth() + 1,
                        end_year: new Date(date).getFullYear(),
                      });
                    }}
                    selectsEnd
                    startDate={filterOption.start_date}
                    endDate={filterOption.end_date}
                    minDate={filterOption.start_date}
                  />
                  <label htmlFor="">วันที่สิ้นสุด</label>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await GetEmpTravelExpen();
                      setStateDate({
                        ...stateDate,
                        start_date: filterOption.start_date,
                        end_date: filterOption.end_date,
                      });
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              {/* <div className="card-tools"></div> */}
            </div>

            <div
              className="card-body table-responsive"
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
                // disableRetreatAfterSorting={MDBT}
              />
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>

        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Travel_expenses;
