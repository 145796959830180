const { default: axios } = require("axios");
const { getToken } = require("../../../Utils/Common");
const { default: Configs } = require("../../../config");

export const GetStatusFilterAccounting = async (model) => {
    try {
        return await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/orgzExpenses/GetAllStatusExpenses",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: model,
        }).then((response) => {
            if (response.data && Array.isArray(response.data.data)) {
                const data = response.data.data.map(el => (
                    { value: el.emp_status_id, label: el.emp_status_name }
                ));
                data.unshift({ value: null, label: 'ทั้งหมด' });
                return data;
            } else {
                return [];
            }
        }).catch((error) => {
            console.log('axios GetStatusFilterAccounting :>> ', error);
            return [];
        })
    } catch (error) {
        console.log('GetStatusFilterAccounting error :>> ', error);
        return [];
    }
}