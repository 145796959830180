import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import DatePicker from "react-datepicker";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import moment from "moment";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import QRCode2 from "qrcode";
import QRCode from "qrcode.react";
import { plating_billing_report, tag_point_cts } from "../../component/Report";
import NumberFormat from "react-number-format";
import Select from "react-select";
import TableLEK from "../../component/tableLEKTTT/table";


function WipShupOutInCTS(params) {
  const [WipproductShup, setWipproductShup] = useState([]);
  const [test,settest] = useState({
    test:'',
  });
  const [headTable, setheadTable] = useState(
    [
   
      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "product_image",
        sort: "asc",
        width: 50,
      },
      /*  {
          label: "QR",
          field: "material_qr",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อย่อโรงชุบ",
        field: "sub_name_com",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "telnumber",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "wip_amount_1",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(กก.)",
        field: "wip_amount_2",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่คาดรับงาน",
        field: "estimate_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot Detail",
        field: "lot_deail",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ]
  );
  const [valueData, setvalueData] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [currentPage,setCurrentPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);

  const [filler_product_wipshup, setfiller_product_wipshup] = useState({
    product_no: "",
    product_name: "",
    materail_lot_start_date: "",
    materail_lot_end_date: "",
    is_waitQc: false,
    is_minStock: false,
    company_key: "CTS",
    oem_id: getOem(),
    company_id: getUser().com,
    search:''
  });

  const [filter_report, setfilter_report] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    date: new Date(),
  });

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );

  const GetfilterWipCTS = async (page,size,search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if(search){
      setSearch(1);
  }else{
      setSearch(0);
  }
    var get_filler_product = {
      product_no: filler_product_wipshup.product_no.trim(),
      product_name: filler_product_wipshup.product_name.trim(),

      company_key: "CTS",
      oem_id: getOem(),
      company_id: getUser().com,
      page:page || 1,
      size:size || 10,
      search:filler_product_wipshup.search
    };

    /*  console.log(filler_product_wipshup); */
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/wipshupOutInCTS/filterWipCTS_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_product,
    })
      .then(function (response) {
        /*           response.data.sort((a,b)=> a.plate_number.localeCompare(b.plate_number)); */
    /*     response.data.sort((a, b) => a.product_no.localeCompare(b.product_no)); */

     /*    setWipproductShup(response.data); */
     
     setWipproductShup(response.data.data);
     setCountPage(response.data.countPage);
     setCountData(response.data.count)
     setEntries(size);
     setCurrentPage(page || 1);
     
     settest({...test,test:''})
     test.test = '';
     Swal.close();
      })
      .catch(function (error) {
        console.log(error);
      });

    Swal.close();
  };

  useEffect(() => {
    GetfilterWipCTS(1,10,'search');
   
  }, []);

  const Clearfilter = async () => {
    await setfiller_product_wipshup({
      ...filler_product_wipshup,
      product_no: "",
      product_name: "",
      materail_lot_start_date: "",
      materail_lot_end_date: "",
      is_waitQc: false,
      is_minStock: false,
      search:''
    });

    filler_product_wipshup.product_no = "";
    filler_product_wipshup.product_name = "";
    filler_product_wipshup.materail_lot_start_date = "";
    filler_product_wipshup.materail_lot_end_date = "";
    filler_product_wipshup.is_waitQc = false;
    filler_product_wipshup.is_minStock = false;
    filler_product_wipshup.search = '';
    GetfilterWipCTS(1,10,'search');
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const rowsData = [];

  for (var index = 0; index < WipproductShup.length; index++) {
    const rowItem = {};

    /*       var color = ''; */
    console.log(WipproductShup[index].log_detail);
    if (WipproductShup[index].log_detail !== 0) {
      var name_com = WipproductShup[index].log_detail.sort(function (a, b) {
        return ("" + a.create_date).localeCompare(b.create_date);
      });
      rowItem["sub_name_com"] = name_com.map((e) => {
        var color = "#000";

        if (
          moment(e.estimate_date)
            .add(7, "hours")
            .diff(moment(new Date()), "days") < 1
        ) {
          color = "red";
        }
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <>
              <font color={color}>
                <b> {e.sub_name_com}</b>
              </font>
              {/*   {console.log('list',el," : ",color)} */}
            </>
          </div>
        );
      });
      rowItem["telnumber"] = name_com.map((e) => {
        /*    var color = "#000";
                 
          if(moment(e.estimate_date).diff(moment(new Date()),'days') < 2){
            color = "red";
          } */
        return (
          <div /* style={{whiteSpace: 'nowrap',color:color}} */>
            <>
              <a href={"tel:" + e.telnumber}>{e.telnumber}</a>
            </>
          </div>
        );
      });

      var amount_piece = 0;
      var amount_weight = 0;

      for (let i of WipproductShup[index].log_detail) {
        if (i.weight_amount !== null && i.picecs_amount === null) {
          amount_piece += (Math.floor(
            (parseFloat(i.weight_amount) / WipproductShup[index].nw) * 1000)
          );
          amount_weight += parseFloat(i.weight_amount || 0);
        } else {
          amount_piece += Math.floor(i.picecs_amount || 0);
          amount_weight +=
            ((parseFloat(i.picecs_amount || 0) * WipproductShup[index].nw) / 1000);
        }
      }

      rowItem["wip_amount_1"] = (
        <div style={{ textAlign: 'right' }}>
          {
            <NumberFormat
              thousandSeparator={true}
              value={amount_piece}
              displayType="text"
            />
          }
        </div>

      );
      /*    .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
      rowItem["wip_amount_2"] = (
        <div style={{ textAlign: 'right' }}>
          {
            <NumberFormat
              thousandSeparator={true}
              value={amount_weight.toFixed(4)}
              displayType="text"
            />
          }
        </div>

      );
      /*   .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
      rowItem["estimate_date"] = name_com.map((e) => {
        var color = "#000";

        if (
          moment(e.estimate_date)
            .add(7, "hours")
            .diff(moment(new Date()), "days") < 1
        ) {
          color = "red";
        }
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <>
              <font color={color}>
                <b>
                  {e.estimate_date
                    ? moment(e.estimate_date).format("DD/MM/YYYY")
                    : ""}
                </b>
              </font>
              {/*   {console.log('list',el," : ",color)} */}
            </>
          </div>
        );
      });

      rowItem["lot_deail"] = name_com.map((e) => {
        var color = "#000";

        if (
          moment(e.estimate_date)
            .add(7, "hours")
            .diff(moment(new Date()), "days") < 1
        ) {
          color = "red";
        }
        return (
          <div style={{ whiteSpace: "nowrap", color: color }}>
            <>
              <font color={color}>
                <b>
                  {/*  {" "}
                  {e.po_no}{" "}
                  {e.create_date
                    ? moment(e.create_date).format("DD/MM/YYYY")
                    : ""} */}
                  {e.lot_no}
                </b>
              </font>

            </>
          </div>
        );
      });
    }

    rowItem["no"] = index + 1;
    rowItem["product_no"] = WipproductShup[index].product_no;
    rowItem["product_image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            WipproductShup[index].imaget_path !== null
              ? Configs.API_URL_IMG + WipproductShup[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );

    var color_alert = "#000";

    rowItem["product_name"] = (
      <>
        <a style={{ color: color_alert }}>
          {WipproductShup[index].product_name}
        </a>
      </>
    );

    /*  rowItem["sub_name_com"] =(
          <>
          <a style={{ color: color_alert }}>
          { WipproductShup[index].sub_name_com }
        </a>
        </>
        )

        rowItem["telnumber"] =(
          <>
          <a style={{ color: color_alert }}>
          { WipproductShup[index].telnumber }
        </a>
        </>
        ) */

    /*  rowItem["min_product"] =(
          <>
          <a style={{ color: color_alert }}>
          { WipproductShup[index].min_product }
        </a>
        </>
        ) */

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/WipShupOutInCTS/mgt/" +
              WipproductShup[index].product_id
            }
            key={WipproductShup[index].product_id}
            className=" btn btn-xs " /* onClick={editpage.bind(this,materail[index].id)} */
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "product_image",
        sort: "asc",
        width: 50,
      },
      /*  {
          label: "QR",
          field: "material_qr",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อย่อโรงชุบ",
        field: "sub_name_com",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "telnumber",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "wip_amount_1",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(กก.)",
        field: "wip_amount_2",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่คาดรับงาน",
        field: "estimate_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot Detail",
        field: "lot_deail",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  const linkreport = async () => {
    var get_report = {
      date: filter_report.date,
      product_no: filler_product_wipshup.product_no.trim(),
      product_name: filler_product_wipshup.product_name.trim(),
      oem_id: getOem(),
    };

    axios({
      method: "post",
      url:
        Configs.API_URL + "/api/WipShupOutInCTS/getWipShupplatingbillingreport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_report,
    })
      .then(function (response) {
        if (response.data < 1) {
          Swal.fire({
            icon: "error",
            title: "ไม่มีข้อมูล",
          });
        } else {
          plating_billing_report(response.data, getUser().sub);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  WIP ชุบ OUT/IN{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">WIP ชุบ OUT/IN</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-3">
            <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product_wipshup.search}
                    onChange={(e) => {
                      setfiller_product_wipshup({
                        ...filler_product_wipshup,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product_wipshup.product_no}
                    onChange={(e) => {
                      setfiller_product_wipshup({
                        ...filler_product_wipshup,
                        product_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product No.</label>{" "}
                </div>
              </div>
              <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product_wipshup.product_name}
                    onChange={(e) => {
                      setfiller_product_wipshup({
                        ...filler_product_wipshup,
                        product_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetfilterWipCTS(1,10,'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => Clearfilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#modal-platingbillingreport"
                    className="btn btn-block btn-info"
                  >
                    ใบจ่ายงานชุบ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WIP ชุบ OUT/IN</h3>
              <div className="card-tools"></div>
            </div>


            <div style= {{padding:'2%'}}>
            <TableLEK       

                               
                                headTable = {headTable}
                                dataTable = {rowsData}
                                countPage = {countPage}
                                countData = {countData}
                                entries = {entries}
                                isSearch = {search}
                                callFnc ={GetfilterWipCTS}
                                
                                
                                />
                                </div>
            {/* <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div> */}
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
        <div className="modal fade" id="modal-platingbillingreport">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">รายงานใบจ่ายงานชุบ</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group ">
                          <DatePicker
                            selected={filter_report.date}
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setfilter_report({
                                ...filter_report,
                                date: date,
                              });
                            }}
                            selectsEnd
                            startDate={filter_report.date}
                            /*        endDate = { filter_bill.date_end }
                            minDate = { filter_bill.date_start } */
                            customInput={<CustomInput />}
                          ></DatePicker>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={() => {
                    linkreport();
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*   <Footter/> */}
    </div>
  );
}
export default WipShupOutInCTS;
