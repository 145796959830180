import React from "react";

import "../../../../assets/css/CusService.css";

// import Icon_support from "../../../../assets/img/Landing_Page/Icon_support.png";
import customerService from "../Incentive_User_icon_img/customer-service2.png";

function CustomerService() {
  return (
    <div className="customre-service">
      <a
        data-toggle="collapse"
        href="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <img
          alt="..."
          className="img-fluid"
          style={{ width: "80px", height: "80px", aspectRatio: "108/108" }}
          src={customerService}
        />
      </a>
      <div class="collapse" id="collapseExample" style={{ position: "absolute", bottom: "0px", right: "85px" }}>
        <div className="card card-outline" style={{ width: "100%" }}>
          {/* <div className="card-header cus-card-header">
            <div className="row">
              <div className="ttt">Customer Service </div>

              <img
                alt="..."
                className="img-fluid img-sm img-icon"
                src={Icon_support}
              />
            </div>
          </div> */}

          <div
            className="card-body cus-card-body"
            style={{ whiteSpace: "nowrap" }}
          >
            {/* ระบบใช้งานมีปัญหา ติดต่อได้ที่ */}
            ติดต่อเจ้าหน้าที่
            <br />
            {/* <div className="ttt"> TTT Brother Co.,Ltd</div> */}
            Tel : 084-677-7505
            <br />
            Line : @tttbrother
            <br />
            {/* Email: w.pubadee@tttbrother.com
            <br /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CustomerService;
