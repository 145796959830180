import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import NumberFormat from "react-number-format";
import Select from "react-select";
import Swal from "sweetalert2";

function Logistic_Car_Table() {
  const [InvoiceData, setInvoiceData] = useState({
    data: [],
  });
  const [InvoiceOption, setInvoiceOption] = useState({
    data: [],
  });
  const [Route, setRoute] = useState({
    data: [],
  });
  const [CarDetail, setCarDetail] = useState({
    data: [],
  });
  const [SearchInput, setSearchInput] = useState({
    LogisticName: "",
    InvoiceNo: "",
  });
  const [CarHaveProduct, setCarHaveProduct] = useState({
    check: false,
  });

  async function getInvoiceNoData() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/getInvoiceNo",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    }).then((response) => {
      setInvoiceData({
        ...InvoiceData,
        data: response.data,
      });
      InvoiceData.data = response.data;
      setInvoiceOption({
        ...InvoiceOption,
        data: response.data,
      });
      InvoiceOption.data = response.data;
    });
  }

  async function getRouteData() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getRouteFromBill",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    }).then((response) => {
      setRoute({
        ...Route,
        data: response.data,
      });
      Route.data = response.data;
    });
  }

  async function getCarDetail() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      LogisticName: SearchInput.LogisticName.trim(),
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getCarDetail",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus_sales +
            "/api/invoice/GetInvoiceFromLogisticInvoice",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: response.data,
        })
          .then(async (response2) => {
            await axios({
              method: "post",
              url: Configs.API_URL_cuteplus + "/api/bill/getRouteInvAll",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: response2.data,
            })
              .then((response3) => {
                setCarDetail({
                  ...CarDetail,
                  data: response3.data,
                });
                CarDetail.data = response3.data;
              })
              .catch((error) => {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const rowsData = [];
  for (let index = 0; index < CarDetail.data.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["logistic_name"] = (
      <div style={{ textAlign: "center" }}>
        {CarDetail.data[index].lgt_name}
      </div>
    );
    rowItem["logistic_type"] = (
      <div style={{ textAlign: "center" }}>{CarDetail.data[index].ds_name}</div>
    );
    rowItem["invoice_no"] = (
      <div style={{ textAlign: "center" }}>
        {CarDetail.data[index].inv_data !== undefined &&
        CarDetail.data[index].inv_data.length > 0
          ? CarDetail.data[index].inv_data.map((el) => {
              return <div>{el.inv_no}</div>;
            })
          : ""}
      </div>
    );
    rowItem["customer"] = (
      <div style={{ textAlign: "center" }}>
        {CarDetail.data[index].inv_data !== undefined &&
        CarDetail.data[index].inv_data.length > 0
          ? CarDetail.data[index].inv_data.map((el) => {
              return <div>{el.inv_cus_name}</div>;
            })
          : ""}
      </div>
    );
    rowItem["ship_to"] = (
      <div style={{ textAlign: "center" }}>
        {CarDetail.data[index].inv_data !== undefined &&
        CarDetail.data[index].inv_data.length > 0
          ? CarDetail.data[index].inv_data.map((el) => {
              return <div>{el.inv_ship_name}</div>;
            })
          : ""}
      </div>
    );
    rowItem["route"] = (
      <div style={{ textAlign: "center" }}>
        {CarDetail.data[index].route_data !== undefined &&
        CarDetail.data[index].route_data.length > 0
          ? CarDetail.data[index].route_data.map((el) => {
              return <div>{el.route_name}</div>;
            })
          : ""}
      </div>
    );
    rowItem["m3"] = (
      <div style={{ textAlign: "center" }}>
        {CarDetail.data[index].inv_data !== undefined &&
        CarDetail.data[index].inv_data.length > 0
          ? CarDetail.data[index].inv_data
              .reduce((acc, cur) => {
                return parseFloat(acc || 0) + parseFloat(cur.inv_total_m3 || 0);
              }, 0)
              .toFixed(2)
              .toString()
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')
              .concat(" CBM")
          : ""}
      </div>
    );
    rowItem["management"] = (
      <div style={{ textAlign: "center" }}>
        <a
          href={`/Warehouse_Management/Logistic_Car_IN_OUT_Cuteplus/Edit/${CarDetail.data[index].lgt_id}`}
          id="btn_edit"
          // key={assignData.data[index].task_id}
          className=" btn btn-xs "
        >
          <i class="fas fa-pencil-alt"></i>
        </a>
      </div>
    );

    rowsData.push(rowItem);
  }
  const CarInOutTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        width: 50,
      },
      {
        label: "Logistic Name",
        field: "logistic_name",
        width: 50,
      },
      {
        label: "Logistic Type",
        field: "logistic_type",
        width: 50,
      },
      {
        label: "Invoice No.",
        field: "invoice_no",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        width: 50,
      },
      {
        label: "Ship to",
        field: "ship_to",
        width: 50,
      },
      {
        label: "Route",
        field: "route",
        width: 50,
      },
      {
        label: "M3",
        field: "m3",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  useEffect(async () => {
    await getInvoiceNoData();
    await getRouteData();
    await getCarDetail();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Logistic Car In/Out</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Logistic Car In/Out
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={SearchInput.LogisticName}
                    onChange={(e) => {
                      setSearchInput({
                        ...SearchInput,
                        LogisticName: e.target.value,
                      });
                      SearchInput.LogisticName = e.target.value;
                    }}
                  ></input>
                  <label>Logistic Name</label>
                </div>
              </div>
              <div className="col-12 col-md-3 col-xl-2">
                <div class="form-group" style={{ zIndex: 99 }}>
                  <select
                    required
                    className="form-control"
                    value={SearchInput.InvoiceNo}
                    onChange={(e) => {
                      setSearchInput({
                        ...SearchInput,
                        InvoiceNo: e.target.value,
                      });
                      SearchInput.InvoiceNo = e.target.value;
                    }}
                  >
                    <option value="" selected>
                      -- Select --
                    </option>
                    {InvoiceOption.data.map((el) => {
                      return <option value={el.inv_id}>{el.inv_no}</option>;
                    })}
                  </select>
                  <label>Invoice No.</label>
                </div>
              </div>
              <div className="col-12 col-md-2 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async (e) => {
                      await getCarDetail();
                      if (
                        SearchInput.InvoiceNo !== "" &&
                        SearchInput.InvoiceNo !== null
                      ) {
                        let temp = [...CarDetail.data];
                        CarDetail.data = [];
                        let filterInvoice = temp.filter((e) => {
                          if (
                            e.inv_data !== undefined &&
                            e.inv_data.length > 0
                          ) {
                            return (
                              e.inv_data.filter((el) => {
                                return el.inv_id === SearchInput.InvoiceNo;
                              }).length > 0
                            );
                          }
                        });
                        setCarDetail({
                          ...CarDetail,
                          data: filterInvoice,
                        });
                        CarDetail.data = filterInvoice;
                      }
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-2 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async (e) => {
                      setSearchInput({
                        ...SearchInput,
                        LogisticName: "",
                        InvoiceNo: "",
                      });
                      SearchInput.LogisticName = "";
                      SearchInput.InvoiceNo = "";
                      setCarHaveProduct({
                        ...CarHaveProduct,
                        check: false,
                      });
                      CarHaveProduct.check = false;
                      await getCarDetail();
                      await getInvoiceNoData();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-2 col-xl-2">
                <div className="custom-control custom-checkbox mt-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheckbox2"
                    checked={CarHaveProduct.check}
                    onChange={async (e) => {
                      setCarHaveProduct({
                        ...CarHaveProduct,
                        check: e.target.checked,
                      });
                      CarHaveProduct.check = e.target.checked;
                      if (e.target.checked) {
                        let temp = [...CarDetail.data];
                        CarDetail.data = [];
                        let filterInvoice = temp.filter((e) => {
                          if (
                            e.inv_data !== undefined &&
                            e.inv_data.length > 0
                          ) {
                            return e.inv_data.length > 0;
                          }
                        });
                        setCarDetail({
                          ...CarDetail,
                          data: filterInvoice,
                        });
                        CarDetail.data = filterInvoice;
                      } else {
                        await getCarDetail();
                        await getInvoiceNoData();
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckbox2"
                  >
                    {" "}
                    รถที่มีสินค้า{" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Logistic Car In/Out</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12" style={{ overflow: "overlay" }}>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed"
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={CarInOutTable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Logistic_Car_Table;
