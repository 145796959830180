import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import React, { useState, useEffect } from "react";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FG_In_Out_Log from "./Component/FG_In_Out_Log";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

function FG_In_Out_Table() {
  const [inputSearch, setInputSearch] = useState({
    pro_code: "",
    pro_name: "",
    cat: "",
    sale: "",
    reserve: true,
  });

  const [categoryData, setCategoryData] = useState({
    data: [],
  });

  const [userData, setUserData] = useState({
    data: [],
  });

  const [fgInOutData, setfgInOutData] = useState({
    id: "",
    amount: "",
  });

  const [productWithLogData, setProductWithLogData] = useState({
    data: [],
  });

  const [productWithLogEditData, setProductWithLogEditData] = useState({
    data: [],
  });

  const [productWithLogModalData, setProductWithLogModalData] = useState({
    data: [],
  });

  const [modalData, setModalData] = useState({
    userLogin: `${getUser().name} ${getUser().surname}`,
    quantity: 0,
    userGive: [],
    expDate: [],
  });

  const [openModal, setOpenModal] = useState(false);

  // const [openModal2, setOpenModal2] = useState(false);

  const [LotSelect, setLotSelect] = useState({
    data: null,
    quantity: 0,
  });

  async function GetProductWithLog() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
      inputSearch: {
        pro_code: inputSearch.pro_code?.trim(),
        pro_name: inputSearch.pro_name?.trim(),
        cat: inputSearch.cat,
        sale: inputSearch.sale,
        reserve: inputSearch.reserve,
      },
    };

    if (inputSearch.sale === "") {
      tempData["userData"] = userData.data;
    } else {
      const index = userData.data.findIndex((item) => {
        return item.id === inputSearch.sale;
      });
      
      tempData["userData"] = [userData.data[index]];
    }

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWithLog",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const tempRes = response.data;

        for (let item1 of tempRes) {
          // let breackLoop = false;

          // if (breackLoop) {
          //   break;
          // }

          let tempImage = {
            company_id: getUser().com,
            oem_id: getOem(),
            // user_id: getUser().fup,
            ul_project: "Cute_Products_Plus",
            ul_type: "ภาพ",
            ul_ref_id: item1.product_id,
            // ul_menu_id: ,
            ul_is_profile: true,
            // ul_file_group: ,
          };

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileOne",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: tempImage,
          }).then(async function (response) {
            item1.image = response.data;
          });
          // .catch(() => {
          //   breackLoop = true;
          // });
        }

        setProductWithLogData({
          ...productWithLogData,
          data: tempRes,
        });

        productWithLogData.data = tempRes;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetProductWithLogEdit() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
      inputSearch: {
        pro_code: "",
        pro_name: "",
        cat: "",
        sale: "",
        reserve: false,
      },
    };

    if (inputSearch.sale === "") {
      tempData["userData"] = userData.data;
    }

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWithLog",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const tempRes = response.data;

        setProductWithLogEditData({
          ...productWithLogEditData,
          data: tempRes,
        });

        productWithLogEditData.data = tempRes;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetProductWithLogModal(product_id) {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
      reserve: inputSearch.reserve,
      userData: userData.data,
      product_id: product_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWithLogModal",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setProductWithLogModalData({
          ...productWithLogModalData,
          data: response.data.product,
        });

        productWithLogModalData.data = response.data.product;

        let quantity = 0;
        const userGive = [];

        response.data.sale.forEach((item) => {
          if (item.id === getUser().fup) {
            quantity += item.in;
          } else {
            item.expDate = "";
            item.giveStock = "";
            item.unit = "piece";

            userGive.push(item);
          }
        });

        setModalData({
          ...modalData,
          quantity: quantity,
          userGive: userGive,
          expDate: response.data.exp_date,
        });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCategoryLv2() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCategoryData({
          ...categoryData,
          data: response.data,
        });

        categoryData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetUserSales() {
    let tempData = {
      company_id: getUser().com,
      // oem_id: getOem(),
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/users/getUserSales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setUserData({
          ...userData,
          data: response.data,
        });
        userData.data = response.data;

        if (getUser().detail.toLowerCase() === "sales") {
          setInputSearch({
            ...inputSearch,
            sale: getUser().fup,
          });

          inputSearch.sale = getUser().fup;
        }

        // if (
        //   response.data.find((item) => {
        //     return item.fullname === `${getUser().name} ${getUser().surname}`;
        //   })
        // ) {
        //   setInputSearch({
        //     ...inputSearch,
        //     sale: `${getUser().name} ${getUser().surname}`,
        //   });

        //   inputSearch.sale = `${getUser().name} ${getUser().surname}`;
        // }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function SaveGiveStock(totalGive) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      user_name: `${getUser().name} ${getUser().surname}`,
      product_id: productWithLogModalData.data[0].pd_pc_id,
      totalGive: totalGive,
      // giveStockData: modalData.userGive,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/saveGiveStock",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        await GetProductWithLog();
        await GetProductWithLogEdit();
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const rowsData1 = [];
  for (let index = 0; index < productWithLogData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = productWithLogData.data[index].product_code
      ? productWithLogData.data[index].product_code
      : "-";

    let barcodeText = [];
    productWithLogData.data[index].barcode.forEach((item, indexIn) => {
      if (productWithLogData.data[index].barcode.length - 1 !== indexIn) {
        barcodeText.push(`${item},\n`);
      } else {
        barcodeText.push(`${item}`);
      }
    });

    rowItem["bar_code"] =
      barcodeText.length !== 0
        ? barcodeText.map((item) => {
            return <p style={{ margin: "0px" }}>{item}</p>;
          })
        : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            productWithLogData.data[index].image
              ? productWithLogData.data[index].image.ul_path_file !==
                  undefined &&
                productWithLogData.data[index].image.ul_path_file !== null &&
                productWithLogData.data[index].image.ul_path_file !== ""
                ? productWithLogData.data[index].image.ul_path_file?.indexOf(
                    "blob"
                  ) >= 0
                  ? productWithLogData.data[index].image.ul_path_file
                  : Configs.API_URL_IMG_cuteplus +
                    `/static/${productWithLogData.data[index].image.ul_file_name}/${productWithLogData.data[index].image.ul_path_file}`
                : userdefault_img.imgs
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            minWidth: "70px",
            minHeight: "70px",
            maxWidth: "70px",
            maxHeight: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["product_name"] = productWithLogData.data[index].product_name_eng
      ? productWithLogData.data[index].product_name_eng
      : "-";

    rowItem["category"] = productWithLogData.data[index].cat_name
      ? productWithLogData.data[index].cat_name
      : "-";

    rowItem["fg_amount_piece"] = productWithLogData.data[index].FG_amount_piece
      ? productWithLogData.data[index].FG_amount_piece
      : "-";

    rowItem["fg_amount_ctn"] =
      productWithLogData.data[index].FG_amount_piece &&
      Number(productWithLogData.data[index].pd_sale_pc_ctn) !== 0
        ? Math.floor(
            productWithLogData.data[index].FG_amount_piece /
              Number(productWithLogData.data[index].pd_sale_pc_ctn)
          )
        : "-";

    // -------------------------------------------- First Code --------------------------------------------
    // let expDateText = [];
    // productWithLogData.data[index].exp_date_list.forEach((item) => {
    //   expDateText.push(`${item}\n`);
    // });

    // rowItem["exp_date"] =
    //   expDateText.length !== 0
    //     ? expDateText.map((item) => {
    //         return <p style={{ margin: "0px" }}>{item}</p>;
    //       })
    //     : "-";

    // let saleText = [];
    // productWithLogData.data[index].sale_list.forEach((item) => {
    //   if (item.in > 0) {
    //     saleText.push(`${item.fullname} (${item.reserve}/${item.in})\n`);
    //   }
    // });

    // rowItem["sale"] =
    //   saleText.length !== 0
    //     ? saleText.map((item) => {
    //         return <p style={{ margin: "0px" }}>{item}</p>;
    //       })
    //     : "-";
    // -------------------------------------------- First Code --------------------------------------------

    // -------------------------------------------- Second Code --------------------------------------------
    let expDateText = [];
    let saleText = [];

    for (let item of productWithLogData.data[index].sale_list) {
      item.listData.forEach((itemIn) => {
        if (itemIn.in > 0) {
          saleText.push(`${item.fullname} (${itemIn.reserve}/${itemIn.in})\n`);
          expDateText.push(`${itemIn.exp_date}\n`);
        }
      });
    }

    rowItem["exp_date"] =
      expDateText.length !== 0
        ? expDateText.map((item) => {
            return <p style={{ margin: "0px" }}>{item}</p>;
          })
        : "-";

    rowItem["sale"] =
      saleText.length !== 0
        ? saleText.map((item) => {
            return <p style={{ margin: "0px" }}>{item}</p>;
          })
        : "-";
    // -------------------------------------------- Second Code --------------------------------------------

    rowItem["give_stock"] =
      getUser().detail.toLowerCase() === "sales" ? (
        <button
          type="button"
          className="btn btn-block btn-success"
          style={{ fontSize: "18px" }}
          onClick={async () => {
            await GetProductWithLogModal(
              productWithLogData.data[index].product_id
            );

            setOpenModal(true);
          }}
        >
          Select
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-block btn-success"
          disabled
          style={{ fontSize: "18px" }}
          // onClick={async () => {
          //   setOpenModal(true);
          // }}
        >
          Select
        </button>
      );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-12">
          <a
            className="btn btn-xs"
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={() => {
              setfgInOutData({
                ...fgInOutData,
                id: productWithLogData.data[index].product_id,
                amount: productWithLogEditData.data[index].FG_amount_piece,
              });

              fgInOutData.id = productWithLogData.data[index].product_id;
              fgInOutData.amount =
                productWithLogEditData.data[index].FG_amount_piece;

              // setOpenModal2(true);

              // console.log(fgInOutData);
              LotSelect.data(
                productWithLogData.data[index].product_id,
                productWithLogData.data[index].FG_amount_piece
                  ? productWithLogData.data[index].FG_amount_piece
                  : "-",
                productWithLogData.data[index].product_name,
                productWithLogData.data[index].product_code
                  ? productWithLogData.data[index].product_code
                  : "-"
              );
              // console.log("LotSelect", LotSelect);
            }}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );

    rowsData1.push(rowItem);
  }

  const productFgTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Barcode",
        field: "bar_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Category Lv2",
        field: "category",
        // sort: "asc",
        width: 50,
      },
      {
        label: "FG Amount (Piece)",
        field: "fg_amount_piece",
        // sort: "asc",
        width: 50,
      },
      {
        label: "FG Amount (Ctn.)",
        field: "fg_amount_ctn",
        // sort: "asc",
        width: 50,
      },
      {
        label: "EXP. Date",
        field: "exp_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Sale",
        field: "sale",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Give Stock",
        field: "give_stock",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData1,
  };

  const rowsData2 = [];
  for (let index = 0; index < modalData.userGive.length; index += 1) {
    const rowItem = {};

    rowItem["sale_name"] = modalData.userGive[index].fullname;

    rowItem["quantity"] = modalData.userGive[index].in;

    rowItem["exp_date"] = (
      <select
        className="form-control"
        required
        // disabled={isDisabled}
        value={modalData.userGive[index].expDate}
        onChange={(e) => {
          const temp = modalData.userGive;

          temp[index].expDate = e.target.value;

          setModalData({
            ...modalData,
            userGive: temp,
          });

          modalData.userGive = temp;
        }}
      >
        <option key="" value="" disabled>--- กรุณาเลือก ---</option>

        {modalData.expDate.map((data, index) => {
          return (
            <option key={index} value={data.exp}>
              {data.exp}
            </option>
          );
        })}
      </select>
    );

    rowItem["give_stock"] = (
      <div className="row">
        <div className="col">
          <NumberFormat
            type="text"
            className="form-control"
            required
            decimalScale={0}
            allowNegative={false}
            // disabled={isDisabled}
            value={modalData.userGive[index].giveStock}
            onChange={(e) => {
              const temp = modalData.userGive;

              temp[index].giveStock = e.target.value;

              setModalData({
                ...modalData,
                userGive: temp,
              });

              modalData.userGive = temp;
            }}
          />
        </div>

        <div className="col-auto">
          <select
            className="form-control"
            required
            // disabled={isDisabled}
            value={modalData.userGive[index].unit}
            onChange={(e) => {
              const temp = modalData.userGive;

              temp[index].unit = e.target.value;

              setModalData({
                ...modalData,
                userGive: temp,
              });

              modalData.userGive = temp;
            }}
          >
            <option key="piece" value="piece">
              Piece
            </option>

            <option key="pack" value="pack">
              Pack
            </option>

            <option key="ctn" value="ctn">
              Ctn
            </option>
          </select>
        </div>
      </div>
    );

    rowsData2.push(rowItem);
  }

  const giveStockTable = {
    columns: [
      {
        label: "Sale name",
        field: "sale_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Quantity",
        field: "quantity",
        // sort: "asc",
        width: 50,
      },
      {
        label: "EXP. Date",
        field: "exp_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Give Stock",
        field: "give_stock",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData2,
  };

  async function clearSearch() {
    setInputSearch({
      ...inputSearch,
      pro_code: "",
      pro_name: "",
      cat: "",
      sale: "",
    });

    inputSearch.pro_code = "";
    inputSearch.pro_name = "";
    inputSearch.cat = "";
    inputSearch.sale = "";

    await GetProductWithLog();
    await GetProductWithLogEdit();
  }

  useEffect(async () => {
    await GetCategoryLv2();
    await GetUserSales();
    await GetProductWithLog();
    await GetProductWithLogEdit();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>F/G IN/OUT</h1>
              </div>
              <div className="col">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">F/G IN/OUT</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    // disabled={isDisabled}
                    value={inputSearch.pro_code}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        pro_code: e.target.value,
                      });
                    }}
                  />
                  <label>Product Code</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    // disabled={isDisabled}
                    value={inputSearch.pro_name}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        pro_name: e.target.value,
                      });
                    }}
                  />
                  <label>Product Name</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    type="text"
                    className="form-control"
                    required
                    // disabled={isDisabled}
                    value={inputSearch.cat}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        cat: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select category
                    </option>

                    {categoryData.data.map((data, index) => {
                      return (
                        <option key={index} value={data.cat_id}>
                          {data.cat_name}
                        </option>
                      );
                    })}
                  </select>
                  <label>Category Lv2</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    className="form-control"
                    required
                    // disabled={isDisabled}
                    value={inputSearch.sale}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        sale: e.target.value,
                      });
                    }}
                  >
                    <option key="" value="">
                      Select Sale
                    </option>

                    {userData.data.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>
                          {data.fullname}
                        </option>
                      );
                    })}
                  </select>
                  <label>Sale</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await GetProductWithLog();
                      await GetProductWithLogEdit();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-12"
                style={{
                  display: "flex",
                  height: "auto",
                  columnGap: "1rem",
                  alignItems: "center",
                  fontSize: "1.5rem",
                }}
              >
                <label class="container-checkbox-product">
                  แสดงสต็อกที่ถูกจอง
                  <input
                    // className={``}
                    type="checkbox"
                    id={"sbd_path_document"}
                    name={"el.sbd_path_document"}
                    value={inputSearch.reserve}
                    checked={inputSearch.reserve}
                    onChange={async (e) => {
                      setInputSearch({
                        ...inputSearch,
                        reserve: e.target.checked,
                      });
                      inputSearch.reserve = e.target.checked;

                      await GetProductWithLog();
                      await GetProductWithLogEdit();
                    }}
                  />
                  <span className="checkmark-checkbox-product"></span>
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>F/G IN/OUT</h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ overflow: "overlay" }}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={productFgTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="modal fade" id="modal-xl">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title">
                      {" "}
                      Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                      {
                        <NumberFormat
                          style={{
                            backgroundColor: "#F0FFFF",
                            border: "1px solid #ddd",
                            padding: "10px",
                            width: "100",
                          }}
                          value={fgInOutData.amount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      }
                      &nbsp;&nbsp;&nbsp; items
                    </h1>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={async () => {
                        await GetProductWithLog();
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <FG_In_Out_Log data={LotSelect} setdata={setLotSelect} fgInOutData={fgInOutData} setfgInOutData={setfgInOutData} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </div>

      <div
        className="modal fade"
        id="modal-xl"
        onClick={async (e) => {
          if (e.currentTarget === e.target) {
            await GetProductWithLog();
            await GetProductWithLogEdit();
          }
        }}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">
                {" "}
                Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                {
                  <NumberFormat
                    // style={{
                    //   backgroundColor: "#F0FFFF",
                    //   border: "1px solid #ddd",
                    //   padding: "10px",
                    //   width: "100",
                    // }}
                    value={fgInOutData.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
                &nbsp;&nbsp;&nbsp; items
              </h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={async () => {
                  await GetProductWithLog();
                  await GetProductWithLogEdit();
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <FG_In_Out_Log
                data={LotSelect}
                setdata={setLotSelect}
                fgInOutData={fgInOutData}
                setfgInOutData={setfgInOutData}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        className="Modal_Center_LeftRight"
        isOpen={openModal}
        toggle={async () => {
          setOpenModal(false);
        }}
      >
        <ModalHeader
          toggle={async () => {
            setOpenModal(false);
          }}
        >
          <h4 className="modal-title" id="modalDelivery">
            <b>Sale give Stock</b>
          </h4>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <label
                style={{
                  fontSize: "24px",
                  lineHeight: "normal",
                  wordBreak: "break-all",
                }}
              >
                {modalData.userLogin} [Quantity {modalData.quantity} Piece]
              </label>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12" style={{ overflow: "overlay" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={giveStockTable}
                />
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-between" }}>
          <button
            type="button"
            className="btn btn-danger"
            onClick={async () => {
              setOpenModal(false);
            }}
          >
            ยกเลิก
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={async () => {
              const expGive = [];
              const expGiveCheck = [];
              const totalGive = [];
              const error_list = [];

              const checkFreeSpace = modalData.userGive.filter((item) => {
                return item.giveStock === "" && item.expDate === "";
              });

              if (checkFreeSpace.length === modalData.userGive.length) {
                let temp_err = {
                  message: `กรุณาใส่ข้อมูล EXP. Date และ Give Stock ให้ Sale คนใดคนหนึ่งให้ครบ`,
                };
                error_list.push(temp_err);
              } else {
                for (let item of modalData.userGive) {
                  if (item.giveStock !== "" && item.expDate === "") {
                    let temp_err = {
                      message: `กรุณาเลือก EXP. Date ของ ${item.fullname}`,
                    };
                    error_list.push(temp_err);
                  }

                  if (item.giveStock === "" && item.expDate !== "") {
                    let temp_err = {
                      message: `กรุณากรอก Give Stock ของ ${item.fullname}`,
                    };
                    error_list.push(temp_err);
                  } else if (
                    Number(item.giveStock) === 0 &&
                    item.expDate !== ""
                  ) {
                    let temp_err = {
                      message: `Give Stock ของ ${item.fullname} ต้องมากกว่า 0`,
                    };
                    error_list.push(temp_err);
                  }

                  let totalQuantity = 0;
                  if (item.unit === "piece") {
                    totalQuantity += Number(item.giveStock);
                  } else if (item.unit === "pack") {
                    totalQuantity +=
                      Number(item.giveStock) *
                      Number(productWithLogModalData.data[0].pd_sale_pc_pack || 1);
                  } else if (item.unit === "ctn") {
                    totalQuantity +=
                      Number(item.giveStock) *
                      Number(productWithLogModalData.data[0].pd_sale_pc_ctn || 1);
                  }

                  if (expGive.includes(item.expDate)) {
                    const index = expGive.findIndex((item1) => {
                      return item1 === item.expDate;
                    });

                    expGiveCheck[index].totalQuantity += totalQuantity;
                  } else {
                    expGive.push(item.expDate);

                    expGiveCheck.push({
                      exp: item.expDate,
                      totalQuantity: totalQuantity,
                    });
                  }

                  totalGive.push({
                    id: item.id,
                    fullname: item.fullname,
                    exp: item.expDate,
                    totalQuantity: totalQuantity,
                  });
                }

                for (let index = 0; index < expGive.length; index += 1) {
                  const index1 = modalData.expDate.findIndex((item1) => {
                    return item1.exp === expGive[index];
                  });

                  if (index1 >= 0) {
                    if (
                      modalData.expDate[index1].totalAmountExp <
                      expGiveCheck[index].totalQuantity
                    ) {
                      let temp_err = {
                        message: `จำนวน Give ที่ให้ Sale คนอื่นจำนวนมากกว่าที่มีใน EXP. Date : ${modalData.expDate[index].exp}`,
                      };
                      error_list.push(temp_err);
                    }
                  }
                }
              }

              if (error_list.length > 0) {
                var err_message = "";

                for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
                }

                Swal.fire("Error", err_message, "error");
              } else {
                setOpenModal(false);

                await SaveGiveStock(totalGive);
              }
            }}
          >
            บันทึก
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FG_In_Out_Table;
