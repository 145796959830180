import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "../Expenses_Approve/Expenses_Approve_Table.css";
import img_pay_slips from "../../../assets/img/accounting/Capa_1.png";
import img_report_icon from "../../../assets/img/accounting/Frame.png";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button as ButtonAnt, Checkbox, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import { getAllByAltText } from "@testing-library/react";

const DATATEST = [
  /* {
    expenses_id: "1",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 69,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_1", // id master
  },
  {
    expenses_id: "2",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "Smart Clinic",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 500,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_2", // id master
  },
  {
    expenses_id: "3",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "Komatsu",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 25000,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_3", // id master
  },
  {
    expenses_id: "4",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "Cute Products Plus",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 1450,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_4", // id master
  }, */
];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ACCOUNTING_STATUS = {
  waiting: "a86edd8a-5b08-445c-8377-9def93ceb8c0", // รอดำเนินการ
  approve_not_upload: "99c1692b-8baa-46b3-983a-c5839a48cf84", // อนุมัติ	// อนุมัติแบบไม่ upload
  approve_uploaded: "d4329102-39f6-420a-820f-7c00f98cbcb3", // อนุมัตื	// อนุมัติแบบ upload
  not_approve: "8ab5629d-754c-4eda-8b8e-bd2920fa6167", // ไม่อนุมัติ
};

const W_ACCOUNTING_STATUS = {
  waiting: "b1c8f95a-fcaf-409f-b108-405c650a52b6", // รอจัดส่ง
  delivered: "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5", // จัดส่งแล้ว
};

function Withholding_Tax_Account_Table() {
  const [disableRetreatAfterSorting, setDisableRetreatAfterSorting] =
    useState(true);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [previewImage, setPreviewImage] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [modalState, setModalState] = useState("");

  const [dataForSave, setDataForSave] = useState({
    id: "",
    remark_not_approve: "",
  });

  const [fileList, setFileList] = useState([]);

  const [fileListApprove, setFileListApprove] = useState({
    data: [],
    onIndex: 0,
  });

  const [filterExpenses, setFilterExpenses] = useState({
    search: "",
    start_date: new Date(moment().startOf("month")),
    end_date: new Date(moment().endOf("month")),
    is_select_all: false,
  });

  const [expensesData, setExpensesData] = useState({
    data: DATATEST,
    sum_amount: 0,
    start_d: new Date(moment().startOf("month")),
    end_d: new Date(moment().endOf("month")),
  });

  const [withholderData, setWithholderData] = useState({
    data: DATATEST,
    start_d: new Date(moment().startOf("month")),
    end_d: new Date(moment().endOf("month")),
    waiting_status: "",
  });

  const [filterWithholder, setFilterWithholder] = useState({
    search: "",
    start_date: new Date(moment().startOf("month")),
    end_date: new Date(moment().endOf("month")),
    waiting_status: W_ACCOUNTING_STATUS.waiting,
    is_select_all: false,
    checkbox: false,
  });

  const handleCloseModal = () => {
    setDataForSave({
      ...dataForSave,
      id: "",
      remark_not_approve: "",
    });
    dataForSave.id = "";
    dataForSave.remark_not_approve = "";
    setFileList([]);
    setShowModal(false);
  };

  const handleOpenModal = (state) => {
    setModalState(state);
    setShowModal(true);
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    // console.log("handleRemove",file.img_id);
    // setDelIMG([...delIMG, file.img_id]);
    setFileList(newFileList);
  };

  const handleUploadSuccess = (file) => {
    // console.log("file.fileList :>> ", file.fileList);
    setFileList(file.fileList);
  };

  async function GetAllWithholding() {
    setDisableRetreatAfterSorting(false);
    let data = {
      company_id: getUser().com,
      oem_id: getOem(),
      search: filterWithholder.search,
      start_date: filterWithholder.start_date,
      end_date: filterWithholder.end_date,
      waiting_status: filterWithholder.waiting_status,
      user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/Withholding/filterWithholding",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          // const _sum_amount = response.data.data.reduce((accumulator, currentValue) => accumulator + Number(currentValue.emp_exp_amount || 0), 0)
          setWithholderData({
            ...withholderData,
            data: response.data.data,
            start_d: data.start_date,
            end_d: data.end_date,
            waiting_status: data.waiting_status,
          });
          withholderData.start_d = data.start_date;
          withholderData.data = response.data.data;
          withholderData.end_d = data.end_date;
          withholderData.waiting_status = data.waiting_status;
        }
        setDisableRetreatAfterSorting(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  function clearFilter() {
    setFilterWithholder({
      search: "",
      start_date: new Date(moment().startOf("month")),
      end_date: new Date(moment().endOf("month")),
      waiting_status: W_ACCOUNTING_STATUS.waiting,
      is_select_all: false,
    });
    filterWithholder.start_date = new Date(moment().startOf("month"));
    filterWithholder.end_date = new Date(moment().endOf("month"));
    filterWithholder.search = "";
    filterWithholder.waiting_status = W_ACCOUNTING_STATUS.waiting;
    filterWithholder.is_select_all = false;
    GetAllWithholding();
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group" style={{ width: "100%" }}>
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  function GetDivAlign(value, align) {
    return <div style={{ textAlign: align || "left" }}>{value}</div>;
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  // async function ApproveHaveFiles() {
  //   const error_list = [];
  //   if (fileList.length == 0) {
  //     let temp_err = {
  //       message: "กรุณาอัปโหลดรูปภาพ",
  //     };
  //     error_list.push(temp_err);
  //   }
  //   if (dataForSave.remark_not_approve.trim() == "") {
  //     let temp_err = {
  //       message: "กรุณากรอก Remark",
  //     };
  //     error_list.push(temp_err);
  //   }
  //   if (error_list.length > 0) {
  //     var err_message = "";
  //     for (var e = 0; e < error_list.length; e++) {
  //       err_message += "<br/>" + error_list[e].message;
  //     }
  //     Swal.fire("Error", err_message, "error");
  //     return;
  //   }

  //   Swal.fire({
  //     title: "คุณมั่นใจที่จะแนบหลักฐานการชำระเงินหรือไม่",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: `บันทึก`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ยกเลิก`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const data = new FormData();
  //       for (let item of fileList) {
  //         data.append("Profile", item.originFileObj);
  //       }

  //       await axios({
  //         method: "post",
  //         url: Configs.API_URL + "/upload/document",
  //         headers: {
  //           Authorization: getToken(),
  //           "X-TTT": Configs.API_TTT,
  //           "Content-Type": "application/json",
  //         },
  //         data: data,
  //       })
  //         .then(async function (response) {
  //           if (response.data.status) {
  //             const file_list = response.data.data?.map((el) => {
  //               return { name: el.orgin_name, path: el.path };
  //             });
  //             await axios({
  //               method: "post",
  //               url:
  //                 Configs.API_URL_hrMagenatement +
  //                 "/api/accountingTTT/ApproveHaveFiles",
  //               headers: {
  //                 Authorization: getToken(),
  //                 "Content-Type": "application/json",
  //                 "X-TTT": Configs.API_TTT,
  //               },
  //               data: {
  //                 emp_exp_id: Array.isArray(dataForSave.id)
  //                   ? dataForSave.id
  //                   : [dataForSave.id],
  //                 file_list: file_list,
  //                 remark: dataForSave.remark_not_approve,
  //                 user_id: getUser().fup,
  //                 status_id: ACCOUNTING_STATUS.approve_not_upload,
  //               },
  //             })
  //               .then(function (response) {})
  //               .catch(function (error) {
  //                 console.log(error);
  //               });

  //             Swal.fire({
  //               icon: "success",
  //               title: "ทำรายการสำเร็จ",
  //               showConfirmButton: false,
  //               timer: 1500,
  //             }).then((result) => {
  //               handleCloseModal();
  //               setFileList([]);
  //               setFilterExpenses({
  //                 ...filterExpenses,
  //                 is_select_all: false,
  //               });
  //               GetAllWithholding();
  //             });
  //           }
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //         });
  //     }
  //   });
  // }

  // async function getFilesApproveById(emp_exp_id) {
  //   await axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_hrMagenatement +
  //       "/api/accountingTTT/getFilesApproveById",
  //     headers: {
  //       Authorization: getToken(),
  //       "Content-Type": "application/json",
  //       "X-TTT": Configs.API_TTT,
  //     },
  //     data: {
  //       company_id: getUser().com,
  //       oem_id: getOem(),
  //       user_id: getUser().fup,
  //       emp_exp_id: emp_exp_id,
  //     },
  //   })
  //     .then(function (response) {
  //       // console.log("response :>> ", response.data.data);
  //       if (response.data.data && Array.isArray(response.data.data)) {
  //         setFileListApprove({ ...fileListApprove, data: response.data.data });
  //         fileListApprove.data = response.data.data;
  //       } else {
  //         setFileListApprove({ ...fileListApprove, data: [] });
  //         fileListApprove.data = [];
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  async function delData(acc_wta_id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "บันทึก",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/Withholding/delWithholding",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: {
            acc_wta_id: acc_wta_id,
            user_id: getUser().fup,
          },
        })
          .then(function (response) {
            // console.log("response :>> ", response.data.data);
            if (response.data.data) {
              Swal.fire({
                icon: "success",
                text: "เสร็จสิ้น",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                setFilterWithholder({
                  ...filterWithholder,
                  is_select_all: false,
                });
                GetAllWithholding();
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  // function GetBodyModal(modalState) {
  //   if (modalState === "not_approve") {
  //     return (
  //       <div style={{ padding: "15px" }}>
  //         <textarea
  //           type="text"
  //           className="form-control"
  //           required
  //           rows={4}
  //           id="award_point_name"
  //           value={dataForSave.remark_not_approve}
  //           onChange={(e) => {
  //             // check_rf_name(e);
  //             setDataForSave({
  //               ...dataForSave,
  //               remark_not_approve: e.target.value,
  //             });
  //             dataForSave.remark_not_approve = e.target.value;
  //           }}
  //         />
  //         <div
  //           style={{
  //             textAlign: "center",
  //             marginTop: "10px",
  //             display: "flex",
  //             gap: "10px",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <button
  //             type="button"
  //             style={{ minWidth: "130px" }}
  //             // onClick={uploadFile}
  //             className="btn btn-success"
  //             id="btn-save"
  //           >
  //             Save
  //           </button>
  //           <button
  //             type="button"
  //             style={{ minWidth: "130px" }}
  //             onClick={handleCloseModal}
  //             className="btn btn-danger"
  //             id="btn-cancle"
  //           >
  //             Cancel
  //           </button>
  //         </div>
  //       </div>
  //     );
  //   } else if (modalState === "upload_slips") {
  //     return (
  //       <div /* style={{ padding: '15px'}} */>
  //         <div
  //           className="area-upload-files-expenses-user"
  //           style={{
  //             /* display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center", */
  //             padding: "15px",
  //           }}
  //         >
  //           <Upload
  //             action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
  //             listType="picture"
  //             fileList={fileList}
  //             onRemove={handleRemove}
  //             onPreview={handlePreview}
  //             onChange={handleUploadSuccess}
  //             accept=".jpg,.png"
  //             multiple
  //           >
  //             <ButtonAnt
  //               type="primary"
  //               style={{ backgroundColor: "#3579F626", color: "#3579F6" }}
  //             >
  //               Choose files
  //             </ButtonAnt>
  //           </Upload>
  //         </div>
  //         <div>
  //           <span
  //             style={{
  //               fontSize: "24px",
  //               fontWeight: "800",
  //               padding: "0px 15px",
  //             }}
  //           >
  //             Remark
  //           </span>
  //           <hr style={{ margin: "0px" }} />
  //           <div style={{ padding: "15px" }}>
  //             <textarea
  //               type="text"
  //               className="form-control"
  //               required
  //               rows={4}
  //               id="award_point_name"
  //               value={dataForSave.remark_not_approve}
  //               onChange={(e) => {
  //                 // check_rf_name(e);
  //                 setDataForSave({
  //                   ...dataForSave,
  //                   remark_not_approve: e.target.value,
  //                 });
  //                 dataForSave.remark_not_approve = e.target.value;
  //               }}
  //             />
  //           </div>
  //         </div>
  //         <div
  //           style={{
  //             textAlign: "center",
  //             marginTop: "10px",
  //             display: "flex",
  //             gap: "10px",
  //             justifyContent: "center",
  //             paddingBottom: "15px",
  //           }}
  //         >
  //           <button
  //             type="button"
  //             style={{ minWidth: "130px" }}
  //             onClick={handleCloseModal}
  //             className="btn btn-success"
  //             id="btn-save"
  //           >
  //             Save
  //           </button>
  //           <button
  //             type="button"
  //             style={{ minWidth: "130px" }}
  //             onClick={handleCloseModal}
  //             className="btn btn-danger"
  //             id="btn-cancle"
  //           >
  //             Cancel
  //           </button>
  //         </div>
  //       </div>
  //     );
  //   } else if (modalState === "view_remark_not_approve") {
  //     return (
  //       <div /* style={{ padding: "15px" }} */>
  //         <div>
  //           <span
  //             style={{
  //               fontSize: "20px",
  //               fontWeight: "600",
  //               padding: "0px 15px",
  //             }}
  //           >
  //             Remark
  //           </span>
  //           <hr style={{ margin: "0px" }} />
  //           <div style={{ padding: "15px" }}>
  //             <p>{dataForSave.remark_not_approve || "-"}</p>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (modalState === "view_slips") {
  //     return (
  //       <div /* style={{ padding: "15px" }} */>
  //         <div
  //           style={{
  //             padding: "15px",
  //             display: "flex",
  //             alignItems: "center",
  //             gap: "10px",
  //           }}
  //         >
  //           <div
  //             hidden={fileListApprove.data.length == 0}
  //             style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
  //             onClick={() => {
  //               let index = fileListApprove.onIndex;
  //               if (index - 1 == -1) {
  //                 index = fileListApprove.data.length - 1;
  //               } else {
  //                 index -= 1;
  //               }
  //               setFileListApprove({
  //                 ...fileListApprove,
  //                 onIndex: index,
  //               });
  //               fileListApprove.onIndex = index;
  //             }}
  //           >
  //             {"<"}
  //           </div>
  //           <div
  //             style={{
  //               width: "100%",
  //               height: "400px",
  //               display: "flex",
  //               alignItems: "center",
  //             }}
  //           >
  //             {fileListApprove.data?.[fileListApprove.onIndex]
  //               ?.emp_file_path ? (
  //               <img
  //                 id="img"
  //                 alt="..."
  //                 className="img-fluid "
  //                 src={
  //                   Configs.API_URL_IMG +
  //                   fileListApprove.data?.[fileListApprove.onIndex]
  //                     .emp_file_path
  //                 }
  //                 style={{
  //                   width: "100%",
  //                   height: "100%",
  //                   position: "relative",
  //                   objectFit: "contain",
  //                   display: "block",
  //                   "margin-left": "auto",
  //                   "margin-right": "auto",
  //                 }}
  //               />
  //             ) : (
  //               <img
  //                 id="img"
  //                 alt="..."
  //                 className="img-fluid rounded shadow border-0"
  //                 src={userdefault_img.imgs}
  //                 style={{
  //                   // width: "60px",
  //                   // height: "60px",
  //                   position: "relative",
  //                   objectFit: "cover",
  //                   display: "block",
  //                   "margin-left": "auto",
  //                   "margin-right": "auto",
  //                 }}
  //               />
  //             )}
  //             {/* <img
  //               id="img"
  //               alt="..."
  //               className="img-fluid rounded shadow border-0"
  //               src={
  //                 fileListApprove.data?.[fileListApprove.onIndex].emp_file_path !== "" &&
  //                 fileListApprove.data?.[fileListApprove.onIndex].emp_file_path !== null &&
  //                 fileListApprove.data?.[fileListApprove.onIndex].emp_file_path !== undefined
  //                   ? Configs.API_URL_IMG + fileListApprove.data?.[fileListApprove.onIndex].emp_file_path
  //                   : userdefault_img.imgs
  //               }
  //               style={{
  //                 // width: "60px",
  //                 // height: "60px",
  //                 position: "relative",
  //                 objectFit: "cover",
  //                 display: "block",
  //                 "margin-left": "auto",
  //                 "margin-right": "auto",
  //               }}
  //             /> */}
  //             {/* <img src={fileListApprove.data[fileListApprove.onIndex].emp_file_path} /> */}
  //           </div>
  //           <div
  //             hidden={fileListApprove.data.length == 0}
  //             style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
  //             onClick={() => {
  //               let index = fileListApprove.onIndex;
  //               if (index + 1 == fileListApprove.data.length) {
  //                 index = 0;
  //               } else {
  //                 index += 1;
  //               }
  //               setFileListApprove({
  //                 ...fileListApprove,
  //                 onIndex: index,
  //               });
  //               fileListApprove.onIndex = index;
  //             }}
  //           >
  //             {">"}
  //           </div>
  //         </div>
  //         <div>
  //           <span
  //             style={{
  //               fontSize: "20px",
  //               fontWeight: "600",
  //               padding: "0px 15px",
  //             }}
  //           >
  //             Remark
  //           </span>
  //           <hr style={{ margin: "0px" }} />
  //           <div style={{ padding: "15px" }}>
  //             <p>{dataForSave.remark_not_approve || "-"}</p>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }
  // }

  // async function fetchAndPreviewPDF(emp_exp_id) {
  //   Swal.fire({
  //     title: "กำลังออกรายงาน",
  //     text: "Loading",
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timer: 2000,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });
  //   const response = await axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_hrMagenatement + "/api/accountingTTT/viewReportBillPay",
  //     headers: {
  //       Authorization: getToken(),
  //       "Content-Type": "application/json",
  //       "X-TTT": Configs.API_TTT,
  //     },
  //     data: {
  //       emp_exp_id: Array.isArray(emp_exp_id) ? emp_exp_id : [emp_exp_id],
  //       user_id: getUser().fup,
  //       url: Configs.API_URL_IMG,
  //     },
  //   });
  //   // console.log("response.data.data :>> ", response.data.data);
  //   const data = await response.data.data;

  //   // Create a data URL for the PDF
  //   const pdfDataUrl = `data:application/pdf;base64,${data.base64}`;
  //   // console.log('pdfDataUrl :>> ', pdfDataUrl);
  //   const link = document.createElement("a");
  //   link.href = pdfDataUrl;
  //   link.download = data.file_name || "downloaded_file.pdf";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link); // Clean up after click

  //   if (data.file_name) {
  //     await delFileReport(data.file_name);
  //   }
  //   Swal.close();
  // }

  async function ReportWithholding(acc_wta_id) {
    Swal.fire({
      title: "กำลังออกรายงาน",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const response = await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/Withholding/reportWithholding",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        company_id: getUser().com,
        oem_id: getOem(),
        user_id: getUser().fup,
        acc_wta_id: Array.isArray(acc_wta_id) ? acc_wta_id : [acc_wta_id],
        // url: Configs.API_URL_IMG,
      },
    });
    // console.log("response.data.data :>> ", response.data.data);
    const data = await response.data.data;

    // Create a data URL for the PDF
    const pdfDataUrl = `data:application/pdf;base64,${data.base64}`;
    // console.log('pdfDataUrl :>> ', pdfDataUrl);
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = data.file_name || "downloaded_file.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after click

    if (data.file_name) {
      await delFileReport(data.file_name, "_withholding");
    }
    Swal.close();
  }

  async function ReportExportingDoc(acc_wta_id) {
    Swal.fire({
      title: "กำลังออกรายงาน",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const response = await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/Withholding/reportExportingDoc",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        company_id: getUser().com,
        oem_id: getOem(),
        user_id: getUser().fup,
        acc_wta_id: Array.isArray(acc_wta_id) ? acc_wta_id : [acc_wta_id],
        // url: Configs.API_URL_IMG,
      },
    });
    // console.log("response.data.data :>> ", response.data.data);
    const data = await response.data.data;

    // Create a data URL for the PDF
    const pdfDataUrl = `data:application/pdf;base64,${data.base64}`;
    // console.log('pdfDataUrl :>> ', pdfDataUrl);
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = data.file_name || "downloaded_file.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after click

    if (data.file_name) {
      await delFileReport(data.file_name, "_exporting");
    }
    Swal.close();
  }

  async function delFileReport(file_name, folder_name) {
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/Withholding/delReport",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        file_name: file_name,
        folder: `${getUser().fup.replace(/-/g, "_")}${folder_name}`,
      },
    }).catch((error) => {
      console.log("error :>> ", error);
    });
  }

  function disabledBtnFile() {
    const _data = [...expensesData.data].filter(
      (el) =>
        el.is_select && el.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
    );
    if (_data.length === 0) return true;
    return false;
    // return !_data.every(el => el.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded);
  }

  function generateDataTable(withholding, index) {
    const element = {};

    element["select"] = (
      <div style={{ textAlign: "center", paddingTop: "0px" }}>
        <input
          style={{ width: "20px", height: "20px" }}
          type="checkbox"
          id="select_all"
          checked={withholding.is_select || false}
          onChange={(e) => {
            const dataTemp = [...withholderData.data];
            dataTemp[index].is_select = e.target.checked;
            setWithholderData({
              ...withholderData,
              data: dataTemp,
            });
            withholderData.data = dataTemp;

            const temp_is_select_all = dataTemp.every((el) => el.is_select);
            setFilterWithholder({
              ...filterWithholder,
              is_select_all: temp_is_select_all,
            });
            filterWithholder.is_select_all = temp_is_select_all;
          }}
        ></input>
      </div>
    );

    element["no"] = GetDivAlign(index + 1, "center");
    element["no_search"] = index + 1;

    element["withholding_date"] = GetDivAlign(
      moment(withholding.acc_wta_paid_date).format("DD-MM-YYYY"),
      "center"
    );
    element["withholding_date_search"] = moment(
      withholding.acc_wta_paid_date
    ).format("DD-MM-YYYY");

    element["withholding_no_vol"] = GetDivAlign(
      <div style={{ minWidth: "120px", textAlign: "center" }}>
        {withholding.acc_wta_doc_no && withholding.acc_wta_doc_vol
          ? `${withholding.acc_wta_doc_no}-${withholding.acc_wta_doc_vol}`
          : "-"}
      </div>
    );
    element["withholding_no_vol_search"] =
      withholding.acc_wta_doc_no + "-" +  withholding.acc_wta_doc_vol;

    element["withholding_name"] = GetDivAlign(
      withholding.acc_wta_name || "-",
      "center"
    );

    element["withholding_name_search"] = withholding.acc_wta_name;

    element["withholding_amount"] = GetDivAlign(
      Number(withholding.acc_wta_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-",
      "right"
    );
    element["withholding_amount_search"] =
      Number(withholding.acc_wta_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-";

    element["withholding_tax_amount"] = GetDivAlign(
      Number(withholding.acc_wta_tax_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-",
      "right"
    );
    element["withholding_tax_amount_search"] =
      Number(withholding.acc_wta_tax_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-";

    element["withholding_status"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
          className={
            withholding.acc_wta_status_id === W_ACCOUNTING_STATUS.waiting
              ? "withholding-status_waiting"
              : "withholding-status_delivered"
          }
        >
          {withholding.emp_status_name}
        </div>
      </div>
    );

    element["mgt"] = (
      <div
        className=""
        style={{
          // flexWrap: "nowrap",
          display: "flex",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{ textAlign: "center", padding: 0 }}
        >
          <a
            key={withholding.acc_wta_id + "del"}
            id="btn_delete"
            className=" btn btn-xs"
            onClick={() => {
              ReportWithholding(withholding.acc_wta_id);
            }}
          >
            <img width={18} src={img_report_icon} />
          </a>
        </div>
        <div style={{ textAlign: "center", padding: 0 }}>
          <a
            id="btn_read"
            key={withholding.rp_id + "view"}
            className="btn btn-xs"
            onClick={() => {
              ReportExportingDoc(withholding.acc_wta_id);
            }}
          >
            <i class="ri-road-map-fill"></i>
            {"   "}
          </a>
        </div>
        <div style={{ textAlign: "center", padding: 0 }}>
          <a
            href={
              "/accounting/withholding_tax_account/View/" +
              withholding.acc_wta_id
            }
            id="btn_read"
            key={withholding.rp_id + "view"}
            className="btn btn-xs"
            onClick={() => {
              // withholderData
              sessionStorage.setItem('state_filter_withoding_tax', JSON.stringify({
                search: filterWithholder.search,
                start_date: filterWithholder.start_date,
                end_date: filterWithholder.end_date,
                waiting_status: filterWithholder.waiting_status,
              }));
            }}
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div
          style={{ textAlign: "center" }}
          hidden={
            withholding.acc_wta_status_id ==
            "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
          }
        >
          <a
            href={
              "/accounting/withholding_tax_account/Edit/" +
              withholding.acc_wta_id
            }
            id="btn_edit"
            key={withholding.acc_wta_id + "edit"}
            className=" btn btn-xs"
            onClick={() => {
              // withholderData
              sessionStorage.setItem('state_filter_withoding_tax', JSON.stringify({
                search: filterWithholder.search,
                start_date: filterWithholder.start_date,
                end_date: filterWithholder.end_date,
                waiting_status: filterWithholder.waiting_status,
              }));
            }}
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
        <div
          style={{ textAlign: "center" }}
          hidden={
            withholding.acc_wta_status_id ==
            "8f8c4cbf-05c3-4fc2-b43d-03bb7b9993b5"
          }
        >
          <a
            key={withholding.acc_wta_id + "del"}
            id="btn_delete"
            className=" btn btn-xs"
            onClick={() => {
              delData(withholding.acc_wta_id);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    return element;
  }

  const dataWithholding = [];
  for (let index = 0; index < withholderData.data.length; index++) {
    const withholding = withholderData.data[index];
    dataWithholding.push(generateDataTable(withholding, index));
  }

  const data = {
    columns: [
      {
        label: (
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <input
              style={{ width: "20px", height: "20px" }}
              type="checkbox"
              id="select_all"
              checked={filterWithholder.is_select_all}
              onChange={(e) => {
                const dataTemp = [...withholderData.data];
                dataTemp.forEach((el) => {
                  el.is_select = e.target.checked;
                });
                setWithholderData({
                  ...withholderData,
                  data: dataTemp,
                });
                withholderData.data = dataTemp;
                setFilterWithholder({
                  ...filterWithholder,
                  is_select_all: e.target.checked,
                });
                filterWithholder.is_select_all = e.target.checked;
              }}
            ></input>
          </div>
        ),
        field: "select",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("No.", "center"),
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("วันที่", "center"),
        field: "withholding_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("เล่มที่-เลขที่", "center"),
        field: "withholding_no_vol",
        // sort: "asc",
        width: 100,
      },
      {
        label: GetDivAlign("ชื่อบริษัท", "center"),
        field: "withholding_name",
        // sort: "asc",
        width: 50,
      },
      /*       {
              label: GetDivAlign("หัวข้อค่าใช้จ่าย", "center"),
              field: "expenses_header_expenses",
              // sort: "asc",
              width: 50,
            }, */
      {
        label: GetDivAlign("จำนวนเงิน", "center"),
        field: "withholding_amount",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("ยอดหัก", "center"),
        field: "withholding_tax_amount",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("สถานะจัดส่งเอกสาร", "center"),
        field: "withholding_status",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("MGT", "center"),
        field: "mgt",
        // sort: "asc",
        width: 100,
      },
    ],
    rows: dataWithholding,
  };

  useEffect(() => {
    const data2 = sessionStorage.getItem('state_filter_withoding_tax');
    if (data2) {
      const data_filter = JSON.parse(data2);
      setFilterWithholder({
        ...filterWithholder,
        search: data_filter.search,
        start_date: new Date(data_filter.start_date),
        end_date: new Date(data_filter.end_date),
        waiting_status: data_filter.waiting_status,
        checkbox: data_filter.waiting_status == "" ? true : false
      });
      filterWithholder.search = data_filter.search;
      filterWithholder.start_date = new Date(data_filter.start_date);
      filterWithholder.end_date = new Date(data_filter.end_date);
      filterWithholder.waiting_status = data_filter.waiting_status;
      filterWithholder.checkbox = data_filter.waiting_status == "" ? true : false;
      sessionStorage.removeItem('state_filter_withoding_tax');
    }
    GetAllWithholding();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Withholding tax account {"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">
                    Withholding tax account
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterWithholder.search}
                    onChange={(e) => {
                      setFilterWithholder({
                        ...filterWithholder,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterWithholder.start_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterWithholder({
                        ...filterWithholder,
                        start_date: date,
                      });
                    }}
                    maxDate={new Date(filterWithholder.end_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>Start Date</label>
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterWithholder.end_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterWithholder({
                        ...filterWithholder,
                        end_date: date,
                      });
                    }}
                    minDate={new Date(filterWithholder.start_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>End Date</label>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary  "
                    onClick={() => {
                      GetAllWithholding();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    disabled={!withholderData.data.some((el) => el.is_select)}
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      const _data = [...withholderData.data]
                        .filter((el) => el.is_select)
                        .map((el) => el.acc_wta_id);

                      ReportWithholding(_data);
                    }}
                  >
                    ออกใบหักภาษีทั้งหมด
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    disabled={!withholderData.data.some((el) => el.is_select)}
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      const _data = [...withholderData.data]
                        .filter((el) => el.is_select)
                        .map((el) => el.acc_wta_id);

                      ReportExportingDoc(_data);
                    }}
                  >
                    ออกที่อยู่สำหรับส่งออกเอกสาร
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/accounting/withholding_tax_account/Add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  id="select_all"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginBottom: "13px",
                  }}
                  checked={filterWithholder.checkbox}
                  onClick={(e) => {
                    setFilterWithholder({
                      ...filterWithholder,
                      checkbox: e.target.checked,
                      waiting_status: e.target.checked
                        ? ""
                        : W_ACCOUNTING_STATUS.waiting,
                    });
                    filterWithholder.waiting_status = e.target.checked
                      ? ""
                      : W_ACCOUNTING_STATUS.waiting;
                    GetAllWithholding();
                  }}
                />
                <label htmlFor="select_all" style={{ marginLeft: "10px" }}>
                  จัดส่งแล้ว
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            {/* <div className="card-header">
              <h3 className="card-title">จัดการเอกสาร</h3>
              <div className="card-tools"></div>
            </div> */}

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                disableRetreatAfterSorting={disableRetreatAfterSorting}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
          {/* Modal */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Withholding_Tax_Account_Table;
