import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const Loading = () => {
  const [timerInterval, setTimerInterval] = useState(null);

  useEffect(() => {
    const fireAlert = async () => {
      await Swal.fire({
        title: 'Loading....',
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector('b');
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
    };

    fireAlert();
  }, []);

  return <div></div>; // You can render some content here if needed
};

export default Loading;