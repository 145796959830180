import React, { Component, useEffect, useState } from "react";
import Footter from "../../../component/Footter";
import Header from "../../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import TableLEK from "../../../component/tableLEKTTT/table";

function Factory_Choup(params) {
  const [project, setProject] = useState([]);
  const [company, setCompany] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);
  
  const [FacChoup, setFacChoup] = useState([]);
  const [filterFacChoup, setfilterFacChoup] = useState({
    fac_choup_no: "",
    fac_choup_name: "",
    oem_id: getOem(),
    company_id: getUser().com,
    all_search:''
  });

  const [test, settest] = useState({
    test: '',
  });

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetFactoryChoup = async (page, size, search) => {

    //console.log(filler_project);
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }

    let temp = {
      fac_choup_no: filterFacChoup.fac_choup_no.trim(),
      fac_choup_name: filterFacChoup.fac_choup_name.trim(),
      oem_id: getOem(),
      company_id: getUser().com,
      page: page || 1,
      size: size || 10,
      all_search: (filterFacChoup.all_search || "").trim()
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/api/factory_choup/filterFactoryChoup_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        //console.log(response.data);
        /*         var sort = response.data.sort((a,b)=> a.code.localeCompare(b.code)); */
        setFacChoup(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();


      })
      .catch(function (error) {
        Swal.close();
        console.log(error);
      });
  };

  useEffect(() => {
    GetFactoryChoup(1, 10, 'search');
    GetCompany();
  }, []);

  const clearFilter = async () => {
    await setfilterFacChoup({
      ...filterFacChoup,
      fac_choup_no: "",
      fac_choup_name: "",
      all_search:''
    });

    filterFacChoup.fac_choup_no = "";
    filterFacChoup.fac_choup_name = "";
    filterFacChoup.all_search = "";
    GetFactoryChoup(1, 10, 'search');

  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Factory_Choup/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/api/factory_choup/delFactoryChoup?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                GetFactoryChoup(1, 10, 'search');
                /*  window.location.href =
                   "/Warehouse_Management/Factory_Master_Data/Factory_Choup"; */
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];
  for (var index = 0; index < FacChoup.length; index++) {
    const rowItem = {};
/*     rowItem["no"] = index + 1; */
    rowItem["fac_choup_no"] = FacChoup[index].code;
    rowItem["fac_choup_name"] = FacChoup[index].name;
    rowItem["fac_choup_tel"] = (<><a href={"tel:" + FacChoup[index].telnumber}>{FacChoup[index].telnumber}</a></>);;

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Factory_Choup/read/" + FacChoup[index].id}
            key={FacChoup[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Factory_Choup/edit/" + FacChoup[index].id}
            key={FacChoup[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={FacChoup[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, FacChoup[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const [headTable, setheadTable] = useState(
    [
   /*    {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "ชื่อย่อโรงงานชุบ",
        field: "fac_choup_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "โรงงานชุบ",
        field: "fac_choup_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ติดต่อ",
        field: "fac_choup_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ]
  );
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อย่อโรงงานชุบ",
        field: "fac_choup_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "โรงงานชุบ",
        field: "fac_choup_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ติดต่อ",
        field: "fac_choup_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>โรงงานชุบ{"  "}<a target="_blank" href={getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">โรงงานชุบ</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">

                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterFacChoup.all_search}
                    onChange={(e) => {
                      setfilterFacChoup({
                        ...filterFacChoup,
                        all_search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">

                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterFacChoup.fac_choup_no}
                    onChange={(e) => {
                      setfilterFacChoup({
                        ...filterFacChoup,
                        fac_choup_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อย่อโรงงานชุบ</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">

                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterFacChoup.fac_choup_name}
                    onChange={(e) => {
                      setfilterFacChoup({
                        ...filterFacChoup,
                        fac_choup_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">โรงงานชุบ</label>{" "}
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetFactoryChoup(1, 10, 'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">โรงงานชุบ</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " style={{ whiteSpace: 'nowrap' }}
            >
              <TableLEK


                headTable={headTable}
                dataTable={rowsData}
                countPage={countPage}
                countData={countData}
                entries={entries}
                isSearch={search}
                callFnc={GetFactoryChoup}


              />

              {/*   <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              /> */}
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>

  );
}

export default Factory_Choup;
