import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
  getComConfig,
} from "../../Utils/Common";

function Tooling_Brand(params) {
  const [brand, setBrand] = useState([]);
  const [company, setCompany] = useState([]);
  const [oemfix, setoemfix] = useState();
  const [feature, setfeature] = useState([]);
  // const [disable, setdisable] = useState(null);
  const [color_option, setcolor_option] = useState([]);
  const [filler_brand, setfiller_brand] = useState({
    name: "",
    full_name: "",
    company_id: getUser().com,
  });
  const options = [];
  const selectColor_model = useRef();
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetBrand_tooling = async () => {
    //console.log(filler_brand);
    axios({
      method: "get",
      url:
        Configs.API_URL +
        `/tooling/getToolingBrand?id=${getUser().com}&id2=${getOem()}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log("brand", response.data);
        setBrand(response.data);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.brand_name;
          element["label"] = el.brand_name;
          options.push(element);
        });
        setcolor_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetBrand_tooling();
    GetCompany();
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
    }
  }, []);

  const clearFilter = async () => {
    await setfiller_brand({
      ...filler_brand,
      name: "",
      full_name: "",
    });

    filler_brand.name = "";
    filler_brand.full_name = "";
    selectColor_model.current.select.clearValue();
    GetBrand_tooling();
  };
  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? "#87CEFA" : "#FFF",
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/tooling_brand/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/tooling/toolingBranddelById/" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/tooling_brand/";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];

  for (var index = 0; index < brand.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["Brand_name"] = (
      <div className="row">
        <div className="col-8">{brand[index].brand_name}</div><div className="col-4"></div>
      </div>
    );
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/tooling_brand/view/" +
              brand[index].brand_id
            }
            key={brand[index].brand_id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/tooling_brand/edit/" +
              brand[index].brand_id
            }
            key={brand[index].brand_id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={brand[index].brand_id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, brand[index].brand_id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  /* console.log(feature); */
  var hardtabel = [];

  hardtabel.push(
    {
      label: "No",
      field: "no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Brand",
      field: "Brand_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "Management",
      field: "mgt",
      sort: "asc",
      width: 50,
    }
  );

  const data = {
    columns: hardtabel,
    rows: rowsData,
  };

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Tooling Brand{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Tooling Brand</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-3">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_brand.name.trim()}
                    onChange={(e) => {
                      setfiller_brand({
                        ...filler_brand,
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Name</label>{" "}
                </div>
              </div>

              {/* <div className="col-md-3">
                <div class="form-group" style={{ "z-index": "97" }}>
                  <Select
                    options={color_option}
                    defaultValue={filler_brand.full_name}
                    ref={selectColor_model}
                    onChange={(e) => {
                      if (e !== null) {
                        setfiller_brand({
                          ...filler_brand,
                          full_name: e.value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="">Brand Full Name</label>
                </div>
              </div> */}

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetBrand_tooling();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_brand.company_id}
                    onChange={(e) => {
                      setfiller_brand({
                        ...filler_brand,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Tooling Brand</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }} /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*  <Footter/> */}
    </div>
  );
}

export default Tooling_Brand;
