import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../Utils/Common";
import axios from "axios";
import Configs from "../../../config";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

function Project_Table() {
  const [searchData, setSearchData] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    global: "",
  });

  const [projectData, setProjectData] = useState([]);

  const rowsProjectTable = [];

  function formatProjectData(index, data) {
    let rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["project_name"] = (
      <div style={{ textAlign: "left" }}>{data.acc_project_name}</div>
    );

    rowItem["project_name_hidden"] = data.acc_project_name;

    rowItem["project_remark"] = data.acc_project_remark ? (
      <div
        style={{
          textAlign: "left",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: "1",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
      >
        {data.acc_project_remark}
      </div>
    ) : (
      <div style={{ textAlign: "center" }}> - </div>
    );

    rowItem["project_remark_hidden"] = data.acc_project_remark || "-";

    rowItem["project_active"] = (
      <div
        class="custom-control custom-switch custom-switch-on-success"
        style={{ textAlign: "center" }}
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id={"customSwitch" + (index + 1)}
          key={data.acc_project_id}
          // value={data.acc_project_id}
          onChange={() =>
            ActiveProjectData(data.acc_project_id, !data.acc_project_is_active)
          }
          defaultChecked={data.acc_project_is_active}
        ></input>
        <label
          class="custom-control-label"
          for={"customSwitch" + (index + 1)}
        ></label>
      </div>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3">
          <a
            href={
              "/accounting/project_acc/view/" +
              data.acc_project_id
            }
            id={"btn_view" + (index + 1)}
            key={data.acc_project_id}
            className="btn btn-xs"
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>

        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            href={
              "/accounting/project_acc/edit/" +
              data.acc_project_id
            }
            id="btn_edit"
            key={data.acc_project_id}
            className="btn btn-xs"
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            onClick={() => DeleteProjectData(data.acc_project_id)}
            key={data.acc_project_id}
            id="btn_delete"
            className="btn btn-xs"
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    return rowItem;
  }

  for (let index = 0; index < projectData.length; index += 1) {
    rowsProjectTable.push(formatProjectData(index, projectData[index]));
  }

  const tableProjectTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ Project",
        field: "project_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "project_remark",
        // sort: "asc",
        width: 10,
      },
      {
        label: "Active",
        field: "project_active",
        // sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsProjectTable,
  };

  async function FilterProjectData() {
    const tempData = JSON.parse(JSON.stringify(searchData));

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/project/filterProjectData",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setProjectData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function ActiveProjectData(projectId, active) {
    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      projectId: projectId,
      active: active,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/project/activeProjectData",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        FilterProjectData();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function DeleteProjectData(projectId) {
    Swal.fire({
      title: "คุณมั่นใจว่าจะลบข้อมูลนี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const tempData = {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          projectId: projectId,
        };

        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/project/deleteProjectData",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              title: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.href =
                "/accounting/project_acc";
            });
            FilterProjectData();
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              // title: "กรุณากรอกข้อมูลใหม่",
              html: error.response.data.error.message,
            });
          });
      }
    });
  }

  async function clearFilter() {
    setSearchData({
      ...searchData,
      global: "",
    });
    searchData.global = "";

    await FilterProjectData();
  }

  useEffect(() => {
    FilterProjectData();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Project
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">Project</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={searchData.global}
                    onChange={(e) => {
                      setSearchData({
                        ...searchData,
                        global: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={FilterProjectData}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/accounting/project_acc/add";
                    }}
                  >
                    Add Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title"></h3> */}
            </div>

            <div className="card-body">
              <div
                className="table-responsive "
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed text-center"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={tableProjectTable}
                  disableRetreatAfterSorting={true}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Project_Table;
