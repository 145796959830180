import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../Utils/Common";
import Select from "react-select";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import moment from "moment";
import DatePicker from "react-datepicker";

import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

const CustomInput = ({ value, onClick }) => (
  <div className="input-group">
    <input
      type="text"
      className="form-control float-left"
      onClick={onClick}
      value={value}
    />
    <div className="input-group-prepend">
      <span className="input-group-text">
        <i className="far fa-calendar-alt" />
      </span>
    </div>
  </div>
);

function Employee_Info(params) {
  const [Employee, setEmployee] = useState([]);
  const [company, setCompany] = useState([]);
  const [filler_employee, setfiller_employee] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    emp_no: "",
    emp_name_th: "",
    emp_type_id: "",
  });

  const [checkGen, setCheckGen] = useState([]);

  const [emptype, setemptype] = useState([]);
  const modalRef = useRef(null);

  const [modal, setModal] = useState(false);

  const toggle_modal = () => setModal(!modal);

  const [template_stats_text, set_template_stats_text] = useState("");
  const [cltext, setcltext] = useState("");
  const [template_name, set_template_name] = useState("");
  const [exlx_detail_view, set_exlx_detail_view] = useState([]);
  const [exlx_detail_status, set_exlx__detail_status] = useState({
    success: "",
    not_success: "",
  });
  const [externaldata, setexternaldata] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    data_list: "",
  });

  const [birthdayState, setBirthdayState] = useState({
    selectMonth: null,
    originData: [],
  });

  const [feature, setfeature] = useState([]);
  const upload_file_exlx = async (e) => {
    if (e.target.files[0]) {
      var data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("file_name", e.target.files[0].name);

      data.append("oem_id", getOem());
      data.append("company_id", getUser().com);
      console.log("list", data);

      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/importEmployeeFile",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(function (response) {
            console.log("ดาต้า", response.data);

            if (response.data) {
              var check = response.data.data.filter((e) => {
                return e.is_success === false;
              });
              var check2 = response.data.data.filter((e) => {
                return e.is_success === true;
              });

              set_exlx__detail_status({
                ...exlx_detail_status,
                success: check2.length,
                not_success: check.length,
              });
              // set_template_name("ทดสอบโมเดว รอดาต้าจริง");
              setcltext("green");
              set_template_stats_text("ตรง Template");
              set_exlx_detail_view(response.data.data); //เปิดเมื่อดาต้าพร้อม
              set_template_name(response.data.template_result);

              Swal.fire(
                "อัพโหลด ไฟล์ สำเร็จ",
                "ไฟล์ ได้ถูกอัพโหลดเข้าฐานข้อมูลเรียบร้อย",
                "susccess"
              ).then((result) => {
                toggle_modal();
              });
            }
            document.getElementById("import_external_file_input").value = "";
            GetEmployee();
            /*  getexternaldate(); */
          })
          .catch(function (error) {
            set_template_stats_text("ไม่ตรง Template");
            setcltext("red");
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
            document.getElementById("import_external_file_input").value = "";
            /* getexternaldate(); */
          });
      });
    }
  };

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CheckGenAccount = async (emp_list) => {
    var temp = {
      emp_list: emp_list,
    };
    await axios({
      method: "post",
      url: Configs.API_URL + "/users/checkGenerateAccount",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setCheckGen(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Generate_Account = async (emp_list) => {
    Swal.fire({
      title: "ต้องการที่จะ Generate Account ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        let list_item = [];
        for (let item of emp_list) {
          let item_list = Employee.find((e) => {
            return e.emp_id === item.id;
          });

          if (item_list) {
            list_item.push({
              emp_id: item_list.emp_id,
              emp_name_eng: item_list.emp_name_eng,
              emp_email_company: item_list.emp_email_company,
              emp_mobile: item_list.emp_mobile,
              emp_nickname: item_list.emp_nickname || null,
              emp_email: item_list.emp_email_company || null,
              emp_name_th: item_list.emp_name_th || null,
            });
          }
        }

        var temp = {
          emp_list: list_item,
          company_id: getUser().com,
          oem_id: getOem(),
          fup: getUser().fup
        };

        axios({
          method: "post",
          url: Configs.API_URL + "/users/Generate_Account",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            /*  setEmployee(response.data); */
            Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              GetEmployee();
            });
          })
          .catch(function (error) {
            Swal.fire("Message", error.response.data.message, "warning");
            console.log(error);
          });
      }
    });
  };
  const [getEmployeeWorkType, setgetEmployeeWorkType] = useState([]);
  const [test, setTest] = useState({
    test: "",
  });
  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        // console.log(response.data.data,"ddd",);
        const arrList = [];
        // setgetEmployeeWorkType(response.data.data);
        setgetEmployeeWorkType([]);
        for (let item of response.data.data) {
          // console.log('item', item);
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
          };
          arrList.push(temp);
          getEmployeeWorkType.push(temp);
        }
        setgetEmployeeWorkType(arrList);
        setTest({ ...test, test: "" });
        /* 
        id
        contract_type_name
        */

        // console.log('getEmployeeWorkType', getEmployeeWorkType);
      })
      .then(() => GetEmployee())
      .catch(function (error) {
        console.log(error);
      });
  };

  const [allEmployee, setAllEmployee] = useState({
    data: [],
  });
  const [checkFeature, setCheckFeature] = useState({
    data: null,
  });

  const GetEmployee = async () => {
    const check = feature.findIndex((e) => e.feature === "col_emp_type");
    // console.log('check', check);
    checkFeature.data = check;
    setCheckFeature({ ...checkFeature, data: check });
    setTest({ ...test, test: "" });
    // console.log('checkFeature.check', checkFeature.data);
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: filler_employee.emp_no.trim(),
      emp_name_th: filler_employee.emp_name_th.trim(),
      emp_type_id: filler_employee.emp_type_id.trim(),
      status_User: check === -1 ? false : true,
    };
    //console.log(filler_employee);
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        /*   console.log(response.data, "ds"); */
        // console.log('first', first)
        feature.map((e) => {
          if (e.feature === "GenAccountIncentive") {
            let emp_list = response.data.data.map((it) => {
              return it.emp_id;
            });
            CheckGenAccount(emp_list);
          }
        });
        if (check >= 0) {
          const allEmp = response.data.data;
          // console.log('allEmp', allEmp);
          // console.log('getEmployeeWorkType', getEmployeeWorkType);
          // setAllEmployee([]);
          const arrList = getEmployeeWorkType.map((item) => {
            const temp = {
              rows: null,
              columns: null,
            };
            temp.columns = item.contract_type_name;
            temp.id = item.id;
            if (item.id === "9a729476-553f-48c4-9ffe-090c02ae66ee") {
              temp.rows = allEmp.filter(
                (el) =>
                  el.emp_status_type_id ===
                    "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" ||
                  el.is_active === false
              );
            } else {
              temp.rows = allEmp.filter(
                (el) =>
                  el.emp_contract_type_id === item.id && el.is_active === true
              );
            }

            return temp;
          });

          arrList.push({
            columns: "วันเกิด",
            id: "birthday",
            rows: allEmp
              ?.filter(
                (el) =>
                  el.emp_status_type_id !==
                    "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" &&
                  el.is_active === true &&
                  moment(el.emp_birthday).format("MM") ==
                    moment().add(1, "month").format("MM")
              )
              ?.sort(
                (a, b) =>
                  moment(a.emp_birthday).format("MMDD") -
                  moment(b.emp_birthday).format("MMDD")
              ),
          });

          setBirthdayState({
            selectMonth: new Date(moment().add(1, "month")),
            originData: allEmp
              ?.filter(
                (el) =>
                  el.emp_status_type_id !==
                    "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" &&
                  el.is_active === true
              )
              ?.sort(
                (a, b) =>
                  moment(a.emp_birthday).format("MMDD") -
                  moment(b.emp_birthday).format("MMDD")
              ),
          });

          setAllEmployee({ ...allEmployee, data: arrList });
          allEmployee.data = arrList;
          setTest({ ...test, test: "" });
          await spliceEmpToTypeWork();
          setEmployee(response.data.data);
        } else {
          setEmployee(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetemployeeTyer = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    /*  console.log("get_com_oem",get_com_oem); */
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        /*  console.log(response.data.data,"dddd"); */
        setemptype(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const syncHistoryFromIn = async (empList) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      empList: empList,
    };
    /*  console.log("get_com_oem",get_com_oem); */
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/syncHistoryFromIn",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        GetEmployee();
        Swal.fire({
          icon: "success",
          text: "เชื่อมข้อมูลประวัติเสร็จสิ้น",
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.error.message, "error");
      });
  };

  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "ข้อมูลพนักงาน";
    });
    feature_data.map((el) => feature.push(el));
    setfeature(feature_data);
    // console.log('feature', feature);
    GetEmployeeWorkType();

    GetCompany();
    GetemployeeTyer();
  }, []);

  const clearFilter = async () => {
    await setfiller_employee({
      ...filler_employee,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
    });

    filler_employee.emp_no = "";
    filler_employee.emp_name_th = "";
    filler_employee.emp_type_id = "";
    GetEmployee();
  };

  function addpage(params) {
    window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee/add";
  }

  function deldata(id) {
    console.log("id", id);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/delEmployeeById?id=" +
            id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
            }).then(() => {
              window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  }

  const rowsData = [];
  /*   console.log(Employee,"ds"); */
  for (var index = 0; index < Employee.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["emp_name_th"] = Employee[index].emp_name_th || null;
    rowItem["emp_no"] = Employee[index].emp_no || null;
    rowItem["emp_period_time"] = Employee[index].emp_period_time || null;
    rowItem["emp_salary"] =
      parseFloat(Employee[index].emp_salary || 0)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท";
    /* if (getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
      rowItem["emp_email"] = Employee[index].emp_email || null;
    } */
    rowItem["emp_email_company"] = Employee[index].emp_email_company || null;
    rowItem["emp_line"] = Employee[index].emp_line || null;
    rowItem["emp_mobile"] = (
      <>
        <a href={"tel:" + Employee[index].emp_mobile}>
          {Employee[index].emp_mobile}
        </a>
      </>
    );
    if (Employee[index].emp_is_connect == true) {
      rowItem["emp_is_connect"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-success"
            /*  disabled = {true} */
          >
            เชื่อมแล้ว
          </button>
        </div>
      );
    } else {
      rowItem["emp_is_connect"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-danger"
            /* disabled = {true} */
          >
            ไม่เชื่อม
          </button>
        </div>
      );
    }

    if (Employee[index].isGenAccount === true) {
      rowItem["gen_user"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-success"
            disabled={true}
          >
            Gen Account แล้ว
          </button>
        </div>
      );
    } else {
      rowItem["gen_user"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-success"
            id={index}
            onClick={(e) => {
              console.log(e.target.id);
              Generate_Account([Employee[e.target.id]]);
            }}
            /*       disabled = {true} */
          >
            Gen Account
          </button>
        </div>
      );
    }

    rowItem["username"] = Employee[index].username;

    if (Employee[index].emp_is_history === true) {
      rowItem["emp_is_history"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-success"
            style={{ opacity: 1 }}
            disabled={true}
          >
            เชื่อมแล้ว
          </button>
        </div>
      );
    } else {
      rowItem["emp_is_history"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-danger"
            id={index}
            onClick={(e) => {
              // console.log(e.target.id);
              syncHistoryFromIn([Employee[e.target.id]]);
            }}
          >
            ไม่เชื่อม
          </button>
        </div>
      );
    }

    if (Employee[index].emp_is_history === true) {
      rowItem["mgt_history"] = (
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              href={
                "/Human_Resource/ข้อมูลพนักงาน/employee_history/reade/" +
                Employee[index].emp_id
              }
              id="btn_read"
              key={Employee[index].emp_id}
              className="btn btn-xs "
            >
              <i class="fas fa-eye"></i>
              {"   "}
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              href={
                "/Human_Resource/ข้อมูลพนักงาน/employee_history/edite/" +
                Employee[index].emp_id
              }
              id="btn_edit"
              key={Employee[index].emp_id}
              className=" btn btn-xs "
            >
              {"   "}
              <i class="fas fa-pencil-alt"></i>
              {"   "}
            </a>
          </div>
        </div>
      );
    } else {
      rowItem["mgt_history"] = <></>;
    }
    const checkMGT = feature.findIndex((e) => e.feature === "col_emp_type");
    rowItem["mgt"] =
      checkMGT >= 0 ? (
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              id="btn_read"
              key={Employee[index].emp_id}
              className="btn btn-xs "
              onClick={getUserFup.bind(this, Employee[index].emp_id, "read")}
            >
              <i class="fas fa-eye"></i>
              {"   "}
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              id="btn_edit"
              key={Employee[index].emp_id}
              className=" btn btn-xs "
              onClick={getUserFup.bind(this, Employee[index].emp_id, "edit")}
            >
              {"   "}
              <i class="fas fa-pencil-alt"></i>
              {"   "}
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              key={Employee[index].emp_id}
              id="btn_delete"
              className=" btn btn-xs "
              onClick={deldata.bind(this, Employee[index].emp_id)}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ) : (
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              // href={"/Human_Resource/ข้อมูลพนักงาน/Employee/read/" + Employee[index].emp_id}
              href={
                "/Human_Resource/ข้อมูลพนักงาน/Employee/read/" +
                Employee[index].emp_id
              }
              id="btn_read"
              key={Employee[index].emp_id}
              className="btn btn-xs "
            >
              <i class="fas fa-eye"></i>
              {"   "}
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              href={
                "/Human_Resource/ข้อมูลพนักงาน/Employee/edit/" +
                Employee[index].emp_id
              }
              id="btn_edit"
              key={Employee[index].emp_id}
              className=" btn btn-xs "
            >
              {"   "}
              <i class="fas fa-pencil-alt"></i>
              {"   "}
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              key={Employee[index].emp_id}
              id="btn_delete"
              className=" btn btn-xs "
              onClick={deldata.bind(this, Employee[index].emp_id)}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ",
        field: "emp_name_th",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภท",
        field: "emp_period_time",
        sort: "asc",
        width: 50,
      },
      /* {
          label: "อัตราเงินเดือน",
          field: "emp_salary",
          sort: "asc",
          width: 50,
        }, */
      /* {
          label: "Email ส่วนตัว",
          field: "emp_email",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Email บริษัท",
        field: "emp_email_company",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line ID",
        field: "emp_line",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ติดต่อ",
        field: "emp_mobile",
        sort: "asc",
        width: 50,
      },
      {
        label: "เชื่อม Face",
        field: "emp_is_connect",
        sort: "asc",
        width: 50,
      },
      {
        label: "Gen Account",
        field: "gen_user",
        sort: "asc",
        width: 50,
      },
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 50,
      },
      {
        label: "เชื่อม ประวัติ",
        field: "emp_is_history",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "จัดการ ประวัติ",`
        field: "mgt_history",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 80,
      },
    ],
    rows: rowsData,
  };
  const [tableEmpTypeWork, setTableEmpTypeWork] = useState({
    data: [],
  });

  async function spliceEmpToTypeWork() {
    const arrLis = [];
    let data = {};

    for (let index = 0; index < allEmployee.data.length; index++) {
      const rowsData = [];
      // console.log('allEmployee.data[index]', allEmployee.data[index].id);
      if (allEmployee.data[index].id === "birthday") {
        for (
          let index2 = 0;
          index2 < allEmployee.data[index].rows.length;
          index2++
        ) {
          const Employee = allEmployee.data[index].rows;

          const rowItem = {};
          rowItem["no"] = index2 + 1;

          rowItem["emp_name_th"] = (
            <div style={{ textAlign: "left" }}>
              {getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb"
                ? `${Employee[index2].emp_name_th} (${Employee[index2].emp_nickname})` ||
                  null
                : Employee[index2].emp_name_th || null}
            </div>
          );

          rowItem["emp_name_th_search"] =
            getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb"
              ? `${Employee[index2].emp_name_th} (${Employee[index2].emp_nickname})` ||
                null
              : Employee[index2].emp_name_th || null;

          rowItem["emp_name_age"] = Employee[index2].emp_birthday
            ? moment().format("YYYY") -
              moment(Employee[index2].emp_birthday).format("YYYY") -
              (moment().format("MMDD") >=
              moment(Employee[index2].emp_birthday).format("MMDD")
                ? 0
                : 1)
            : null;

          rowItem["emp_position_name"] = (
            <div style={{ textAlign: "left" }}>
              {Employee[index2].emp_position_name || null}
            </div>
          );

          rowItem["emp_position_name_search"] =
            Employee[index2].emp_position_name || null;

          rowItem["employee_type"] = Employee[index2].emp_contract_type || null;

          rowItem["emp_birthday"] = Employee[index2].emp_birthday
            ? moment(Employee[index2].emp_birthday).format("DD-MM-YYYY")
            : null;

          rowsData.push(rowItem);
        }

        data = {
          columns: [
            {
              label: "ลำดับ",
              field: "no",
              sort: "asc",
              width: 50,
            },
            {
              label: "ชื่อ - นามสกุล (ชื่อเล่น)",
              field: "emp_name_th",
              sort: "asc",
              width: 50,
            },
            {
              label: "อายุ",
              field: "emp_name_age",
              sort: "asc",
              width: 50,
            },
            {
              label: "ตำแหน่ง",
              field: "emp_position_name",
              sort: "asc",
              width: 50,
            },
            {
              label: "ประเภทพนักงาน",
              field: "employee_type",
              sort: "asc",
              width: 50,
            },
            {
              label: "วันเกิด",
              field: "emp_birthday",
              sort: "asc",
              width: 50,
            },
          ],
          rows: rowsData,
        };
      } else {
        for (
          let index2 = 0;
          index2 < allEmployee.data[index].rows.length;
          index2++
        ) {
          const Employee = allEmployee.data[index].rows;
          // console.log('Employee[index2]', Employee[index2]);
          const rowItem = {};
          rowItem["no"] = index2 + 1;

          rowItem["emp_name_th"] =
            getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb"
              ? `${Employee[index2].emp_name_th} (${Employee[index2].emp_nickname})` ||
                null
              : Employee[index2].emp_name_th || null;
          rowItem["emp_no"] = Employee[index2].emp_no || null;
          rowItem["employee_type"] = Employee[index2].emp_contract_type || null;
          rowItem["emp_status_type"] = Employee[index2].emp_status_type || null;
          rowItem["emp_period_time"] = Employee[index2].emp_period_time || null;
          rowItem["emp_salary"] =
            parseFloat(Employee[index2].emp_salary || 0)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " บาท";
          if (getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
            rowItem["emp_email"] = Employee[index2].emp_email || null;
          }
          rowItem["emp_email_company"] =
            Employee[index2].emp_email_company || null;
          rowItem["emp_line"] = Employee[index2].emp_line || null;
          rowItem["emp_mobile"] = (
            <>
              <a href={"tel:" + Employee[index2].emp_mobile}>
                {Employee[index2].emp_mobile}
              </a>
            </>
          );
          if (Employee[index2].emp_is_connect == true) {
            rowItem["emp_is_connect"] = (
              <div style={{ textAlign: "center" }}>
                <button
                  type="button"
                  className="btn btn-block btn-success"
                  /*  disabled = {true} */
                >
                  เชื่อมแล้ว
                </button>
              </div>
            );
          } else {
            rowItem["emp_is_connect"] = (
              <div style={{ textAlign: "center" }}>
                <button
                  type="button"
                  className="btn btn-block btn-danger"
                  /* disabled = {true} */
                >
                  ไม่เชื่อม
                </button>
              </div>
            );
          }
          if (getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
            if (Employee[index2].isGenAccount === true) {
              rowItem["gen_user"] = (
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-block btn-success"
                    disabled={true}
                  >
                    Gen Account แล้ว
                  </button>
                </div>
              );
            } else {
              rowItem["gen_user"] = (
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-block btn-success"
                    id={index2}
                    onClick={(e) => {
                      console.log(e.target.id);
                      Generate_Account([Employee[e.target.id]]);
                    }}
                    /*       disabled = {true} */
                  >
                    Gen Account
                  </button>
                </div>
              );
            }
          }

          rowItem["username"] = Employee[index2].username;

          if (Employee[index2].emp_is_history === true) {
            rowItem["emp_is_history"] = (
              <div style={{ textAlign: "center" }}>
                <button
                  type="button"
                  className="btn btn-block btn-success"
                  style={{ opacity: 1 }}
                  disabled={true}
                >
                  เชื่อมแล้ว
                </button>
              </div>
            );
          } else {
            rowItem["emp_is_history"] = (
              <div style={{ textAlign: "center" }}>
                <button
                  type="button"
                  className="btn btn-block btn-danger"
                  id={index2}
                  onClick={(e) => {
                    // console.log(e.target.id);
                    syncHistoryFromIn([Employee[e.target.id]]);
                  }}
                >
                  ไม่เชื่อม
                </button>
              </div>
            );
          }

          if (Employee[index2].emp_is_history === true) {
            rowItem["mgt_history"] = (
              <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    href={
                      "/Human_Resource/ข้อมูลพนักงาน/employee_history/reade/" +
                      Employee[index2].emp_id
                    }
                    id="btn_read"
                    key={Employee[index2].emp_id}
                    className="btn btn-xs "
                  >
                    <i class="fas fa-eye"></i>
                    {"   "}
                  </a>
                </div>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    href={
                      "/Human_Resource/ข้อมูลพนักงาน/employee_history/edite/" +
                      Employee[index2].emp_id
                    }
                    id="btn_edit"
                    key={Employee[index2].emp_id}
                    className=" btn btn-xs "
                  >
                    {"   "}
                    <i class="fas fa-pencil-alt"></i>
                    {"   "}
                  </a>
                </div>
              </div>
            );
          } else {
            rowItem["mgt_history"] = <></>;
          }
          const checkMGT = feature.findIndex(
            (e) => e.feature === "col_emp_type"
          );
          rowItem["mgt"] =
            checkMGT >= 0 ? (
              <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    id="btn_read"
                    key={Employee[index2].emp_id}
                    className="btn btn-xs "
                    onClick={getUserFup.bind(
                      this,
                      Employee[index2].emp_id,
                      "read"
                    )}
                  >
                    <i class="fas fa-eye"></i>
                    {"   "}
                  </a>
                </div>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    id="btn_edit"
                    key={Employee[index2].emp_id}
                    className=" btn btn-xs "
                    onClick={getUserFup.bind(
                      this,
                      Employee[index2].emp_id,
                      "edit"
                    )}
                  >
                    {"   "}
                    <i class="fas fa-pencil-alt"></i>
                    {"   "}
                  </a>
                </div>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    key={Employee[index2].emp_id}
                    id="btn_delete"
                    className=" btn btn-xs "
                    onClick={deldata.bind(this, Employee[index2].emp_id)}
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            ) : (
              <div className="row" style={{ flexWrap: "nowrap" }}>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    // href={"/Human_Resource/ข้อมูลพนักงาน/Employee/read/" + Employee[index2].emp_id}
                    href={
                      "/Human_Resource/ข้อมูลพนักงาน/Employee/read/" +
                      Employee[index2].emp_id
                    }
                    id="btn_read"
                    key={Employee[index2].emp_id}
                    className="btn btn-xs "
                  >
                    <i class="fas fa-eye"></i>
                    {"   "}
                  </a>
                </div>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    href={
                      "/Human_Resource/ข้อมูลพนักงาน/Employee/edit/" +
                      Employee[index2].emp_id
                    }
                    id="btn_edit"
                    key={Employee[index2].emp_id}
                    className=" btn btn-xs "
                  >
                    {"   "}
                    <i class="fas fa-pencil-alt"></i>
                    {"   "}
                  </a>
                </div>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                  <a
                    key={Employee[index2].emp_id}
                    id="btn_delete"
                    className=" btn btn-xs "
                    onClick={deldata.bind(this, Employee[index2].emp_id)}
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            );
          rowsData.push(rowItem);
        }
        data = {
          columns: [
            {
              label: "ลำดับ",
              field: "no",
              sort: "asc",
              width: 50,
            },
            {
              label: "ชื่อ - นามสกุล ",
              field: "emp_name_th",
              sort: "asc",
              width: 50,
            },
            {
              label: "รหัสพนักงาน",
              field: "emp_no",
              sort: "asc",
              width: 50,
            },
            {
              label: "ประเภท",
              field: "emp_period_time",
              sort: "asc",
              width: 50,
            },
            // {
            //   label: "สถานะพนักงาน",
            //   field: "emp_status_type",
            //   sort: "asc",
            //   width: 50,
            // },
            // {
            //   label: "อัตราเงินเดือน",
            //   field: "emp_salary",
            //   sort: "asc",
            //   width: 50,
            // },
            {
              label: "Email บริษัท",
              field: "emp_email_company",
              sort: "asc",
              width: 50,
            },
            {
              label: "Line ID",
              field: "emp_line",
              sort: "asc",
              width: 50,
            },
            {
              label: "เบอร์ติดต่อ",
              field: "emp_mobile",
              sort: "asc",
              width: 50,
            },
            {
              label: "เชื่อม Face",
              field: "emp_is_connect",
              sort: "asc",
              width: 50,
            },
            {
              label: "Username",
              field: "username",
              sort: "asc",
              width: 50,
            },
            /* {
            label: "เชื่อม ประวัติ",
            field: "emp_is_history",
            sort: "asc",
            width: 50,
          }, */
            /* {
            label: "จัดการ ประวัติ",
            field: "mgt_history",
            sort: "asc",
            width: 50,
          }, */
            {
              label: "จัดการ",
              field: "mgt",
              sort: "asc",
              width: 80,
            },
          ],
          rows: rowsData,
        };
        let checkFeatureT = feature.findIndex(
          (e) => e.feature === "col_emp_type"
        );
        if (checkFeatureT >= 0) {
          data.columns.splice(3, 1);
        }
        if (
          allEmployee.data[index].id === "9a729476-553f-48c4-9ffe-090c02ae66ee"
        ) {
          data.columns.splice(4, 0, {
            label: "ประเภทพนักงาน",
            field: "employee_type",
            sort: "asc",
            width: 80,
          });
        }
        // tableEmpTypeWork.push(data);
      }

      arrLis.push(data);
    }
    // console.log('arrLis', arrLis);
    setTableEmpTypeWork({ ...tableEmpTypeWork, data: arrLis });
    tableEmpTypeWork.data = arrLis;
    setTest({ ...test, test: "" });
  }

  function getUserFup(emp_id, mode, e) {
    // console.log("emp_id", emp_id);
    // const fup = { fup: (id) ? id : getUser().fup }
    axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getUserFapEmployee?id=" +
        emp_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        /* if (mode.mode !== "read" && mode.mode !== "edit" && res.data.data) {
        window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee/read/" + res.data.data;
      } */
        sessionStorage.setItem("emTTT", emp_id);
        if (mode == "read" && res.data.data) {
          window.location.href =
            "/Human_Resource/ข้อมูลพนักงาน/Employee/reade/" + res.data.data;
        }
        if (mode == "edit" && res.data.data) {
          window.location.href =
            "/Human_Resource/ข้อมูลพนักงาน/Employee/edite/" + res.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  var genAcFea = feature.filter((e) => {
    return e.feature === "GenAccountIncentive";
  });
  if (genAcFea.length == 0) {
    var index = data.columns.findIndex((e) => e.field === "gen_user");
    data.columns.splice(index, 2);
  }

  var hisFea = feature.filter((e) => {
    return e.feature === "employee_history";
  });
  if (hisFea.length == 0) {
    var index = data.columns.findIndex((e) => e.field === "emp_is_history");
    data.columns.splice(index, 1);
  }
  /* if (filler_employee.company_id !== "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
    var index = data.columns.findIndex((e) => e.field === "emp_email");
    data.columns.splice(index, 1);
  } */
  const columnTableFea = feature.filter((e) => {
    return e.feature === "col_emp_type";
  });
  if (columnTableFea.length > 0) {
    // console.log('first');
    const index = data.columns.findIndex((e) => e.field === "emp_email");
    data.columns.splice(index, 1);
    const index2 = data.columns.findIndex((e) => e.field === "gen_user");
    data.columns.splice(index2, 1);
  }

  const pad_cync_connect = async () => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let employee_id = { employee_id: [] };
    Employee.forEach((element) => {
      employee_id.employee_id.push(element.emp_id);
    });
    let allPad = [];
    let status_api = "";
    await axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement +
        "/config?company_id=" +
        getUser().com +
        "&&oem_id=" +
        getOem(),
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        response.data.data.sort((a, b) => a.pad_name.localeCompare(b.pad_name));
        /* console.log("ฟีลเตอร์", response.data.data); */
        /*  set_Log_pad_connection(response.data.data); */
        allPad = response.data.data.filter((e) => {
          return e.is_active === true && e.is_use === true;
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    /* console.log("allPad",allPad); */
    if (allPad.length > 0 && employee_id.employee_id.length > 0) {
      /*  console.log("allPad", allPad);
      console.log("employee_id", employee_id); */
      for (const elementData of allPad) {
        /* console.log("employee_id2",elementData.id); */
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/pad/personnel/findPersonnelName?pad_id=" +
            elementData.id,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: employee_id,
        })
          .then(function (response) {
            if (response.data.status === "success") {
              status_api = "success";
            } else {
              status_api = "fail";
            }
            /* console.log(response.data); */
          })
          .catch(function (error) {
            console.log(error);
          });

        if (status_api === "success") {
          let timerInterval;
          /*  Swal.fire({
            title: "Updating !!!",
            html: "Data is updating progress.",
            timer: 20000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              timerInterval = setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => { */
          /* Read more about handling dismissals below */
          GetEmployee();
          /*   if (result.dismiss === Swal.DismissReason.timer) {
              window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
            } */
          Swal.close();
          /*    }); */
        } else {
          Swal.fire({
            icon: "warning",
            text: "ไม่มีพนักงาน หรือ ไม่พบข้องมูล Pad2",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        text: "ไม่มีพนักงาน หรือ ไม่พบข้องมูล Pad1",
      });
    }
  };

  function newEmployee() {
    window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee/NewEmployee";
  }
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ข้อมูลพนักงาน</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Employee</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    id="text_empid"
                    required="false"
                    value={filler_employee.emp_no}
                    onChange={(e) => {
                      setfiller_employee({
                        ...filler_employee,
                        emp_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">รหัสพนักงาน</label>{" "}
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={filler_employee.emp_name_th}
                    onChange={(e) => {
                      setfiller_employee({
                        ...filler_employee,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                </div>
              </div>
              {getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb" ? (
                <div
                  className="col-md-2 col-sm-12 col-lg-2"
                  style={{ "z-index": "99" }}
                >
                  <div className="form-group ">
                    <select
                      className="form-control custom-select select2"
                      type="text"
                      required
                      id="drop_emptype"
                      value={filler_employee.emp_type_id}
                      onChange={(e) => {
                        setfiller_employee({
                          ...filler_employee,
                          emp_type_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected></option>
                      {emptype.map((el) => {
                        return (
                          <option value={el.period_time_id}>
                            {el.period_time}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor=""> ประเภทพนักงาน</label>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      // setAllEmployee([]);
                      // setTableEmpTypeWork([]);
                      GetEmployee();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      // setTableEmpTypeWork([]);
                      clearFilter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              {getUser().com !== "5146dd92-19f6-4dab-ad49-735a114fcfdb" ? (
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_add"
                      className="btn btn-block btn-primary"
                      onClick={addpage}
                    >
                      Add
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}

              {feature.find((e) => e.feature === "col_emp_type") ===
              undefined ? (
                <>
                  <div className="col-6 col-md-4 col-xl-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        id="btn_import_excel"
                        className="btn btn-block btn-primary"
                        data-toggle="modal"
                        data-target="#modal-importexcel"
                        /*   onClick={ImportExcel} */
                      >
                        Import Excel
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="modal fade" id="modal-importexcel">
                <div className="modal-dialog modal-md">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title">Import Excel</h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={modalRef}
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-12 ">
                          <div className="none-nolmalinput"></div>

                          <div className="col-12">
                            <label htmlFor="">ไฟล์ภายนอก:</label>
                            <small style={{ color: "red" }}>
                              ** เทมเพลตรูปแบบ Excel **
                            </small>
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  // onClick={clickclear}
                                  type="file"
                                  required
                                  //disabled={externaldata.template === "" || externaldata.template === "ค่าซ่อม.xlsx"  ? true : false}
                                  className="custom-file-input"
                                  id="import_external_file_input"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  onChange={upload_file_exlx}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="import_external_file_input"
                                >
                                  {externaldata.file_name !== null &&
                                  externaldata.file_name !== "" &&
                                  externaldata.file_name !== undefined
                                    ? externaldata.file_name
                                    : "Select File"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        /*  onClick={ImportExcel} */
                      >
                        ยืนยัน
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={pad_cync_connect}
                  >
                    Sync Face Recognition
                  </button>
                </div>
              </div>
              {hisFea.length !== 0 ? (
                <>
                  {/* <div className="col-12 col-md-3 col-xl-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-info"
                        onClick={() => {
                          syncHistoryFromIn(Employee);
                        }}
                      >
                        Sync Employee History
                      </button>
                    </div>
                  </div> */}
                </>
              ) : (
                <></>
              )}
              {feature.map((e) => {
                if (e.feature === "GenAccountIncentive") {
                  return (
                    <div className="col-12 col-md-3 col-xl-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          className="btn btn-block btn-success"
                          onClick={() => {
                            let temp = checkGen.filter((e) => {
                              return e.isGenAccount === false;
                            });
                            Generate_Account(temp);
                          }}
                          disabled={
                            checkGen.filter((e) => {
                              return e.isGenAccount === false;
                            }).length > 0
                              ? false
                              : true
                          }
                        >
                          Gen Account All
                        </button>
                      </div>
                    </div>
                  );
                }
              })}
              {feature.map((e) => {
                if (e.feature === "newemployee") {
                  return (
                    <div className="col-12 col-md-3 col-xl-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          className="btn btn-block btn-success"
                          onClick={() => {
                            newEmployee();
                          }}
                        >
                          {e.feature_name}
                        </button>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </section>

        <section className="content">
          {feature.map((e) => {
            if (e.feature === "col_emp_type") {
              return (
                <>
                  <div className="card card-primary card-outline card-outline-tabs">
                    <div className="card-header p-0 border-bottom-0">
                      <ul
                        className="nav nav-tabs"
                        id="custom-tabs-four-tab"
                        role="tablist"
                      >
                        {allEmployee.data.map((el, index) => {
                          if (index === 0) {
                            return (
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  id={el.id}
                                  data-toggle="pill"
                                  href={"#" + el.columns + "-teb"}
                                  role="tab"
                                  aria-controls={el.columns + "-teb"}
                                  aria-selected="true"
                                >
                                  {el.columns + ` (${el.rows.length})`}
                                </a>
                              </li>
                            );
                          } else if (el.id === "birthday") {
                            return (
                              <li className="nav-item">
                                {/* {mode.mode === "read" || mode.mode === "edit" ? */}
                                <a
                                  className="nav-link"
                                  id={el.id}
                                  data-toggle="pill"
                                  href={"#" + el.columns + "-teb"}
                                  role="tab"
                                  aria-controls={el.columns + "-teb"}
                                  aria-selected="false"
                                >
                                  {el.columns}
                                </a>
                                {/* } */}
                              </li>
                            );
                          } else {
                            return (
                              <li className="nav-item">
                                {/* {mode.mode === "read" || mode.mode === "edit" ? */}
                                <a
                                  className="nav-link"
                                  id={el.id}
                                  data-toggle="pill"
                                  href={"#" + el.columns + "-teb"}
                                  role="tab"
                                  aria-controls={el.columns + "-teb"}
                                  aria-selected="false"
                                >
                                  {el.columns + ` (${el.rows.length})`}
                                </a>
                                {/* } */}
                              </li>
                            );
                          }
                        })}

                        {/* <li className="nav-item">
                          <a
                            className="nav-link"
                            id="custom-tabs-four-page-3-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-page-3"
                            role="tab"
                            aria-controls="custom-tabs-four-page-3"
                            aria-selected="false"
                          >
                            Supplier
                          </a>
                        </li> */}
                      </ul>
                    </div>
                    <div className="card-body">
                      <div
                        className="tab-content"
                        id="custom-tabs-four-tabContent"
                      >
                        {allEmployee.data.map((el, index) => {
                          if (index === 0) {
                            return (
                              <div
                                className="tab-pane fade show active"
                                id={el.columns + "-teb"}
                                role="tabpanel"
                                aria-labelledby={el.id}
                              >
                                {/* ส่วนแรก */}
                                <div
                                  className="table-responsive "
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <MDBDataTable
                                    sortable={false}
                                    className="table table-head-fixed text-center"
                                    striped
                                    bordered
                                    hover
                                    fixedHeader
                                    data={tableEmpTypeWork.data[index]}
                                  />
                                </div>
                              </div>
                            );
                          } else if (el.id === "birthday") {
                            return (
                              <div
                                className="tab-pane fade"
                                id={el.columns + "-teb"}
                                role="tabpanel"
                                aria-labelledby={el.id}
                              >
                                <div className="row mb-2">
                                  <div
                                    className="col-12 col-md-4 col-xl-3"
                                    style={{ "z-index": "98" }}
                                  >
                                    <div className="form-group input-group">
                                      <DatePicker
                                        id="input-emp-time_start"
                                        // customInput={<CustomInput />}
                                        className="form-control"
                                        wrapperClassName="form-control"
                                        dateFormat="MM"
                                        placeholderText="00"
                                        showMonthYearPicker
                                        isClearable
                                        selected={birthdayState.selectMonth}
                                        onChange={async (date) => {
                                          setBirthdayState({
                                            ...birthdayState,
                                            selectMonth: date,
                                          });

                                          // console.log(
                                          //   moment(date).startOf("month")
                                          // );

                                          // console.log(
                                          //   moment(date).endOf("month")
                                          // );

                                          const newData =
                                            birthdayState.originData.filter(
                                              (item) => {
                                                if (
                                                  item.emp_birthday &&
                                                  moment(
                                                    moment(
                                                      item.emp_birthday
                                                    ).format("MM-DD"),
                                                    "MM-DD"
                                                  ).isBetween(
                                                    moment(date).startOf(
                                                      "month"
                                                    ),
                                                    moment(date).endOf("month"),
                                                    null,
                                                    "[]"
                                                  )
                                                ) {
                                                  return item;
                                                }
                                              }
                                            );

                                          const indexBirthday =
                                            allEmployee.data.findIndex(
                                              (item) => item.id === "birthday"
                                            );

                                          const tempData = JSON.parse(
                                            JSON.stringify(allEmployee.data)
                                          );

                                          if (date) {
                                            tempData[indexBirthday].rows =
                                              newData;
                                          } else {
                                            tempData[indexBirthday].rows =
                                              birthdayState.originData;
                                          }

                                          setAllEmployee({
                                            data: tempData,
                                          });
                                          allEmployee.data = tempData;

                                          await spliceEmpToTypeWork();
                                        }}
                                      />
                                      <label>Select Month</label>{" "}
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          <i className="far fa-calendar-alt" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="table-responsive"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <MDBDataTable
                                    sortable={false}
                                    className="table table-head-fixed text-center"
                                    striped
                                    bordered
                                    hover
                                    fixedHeader
                                    data={tableEmpTypeWork.data[index]}
                                  />
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="tab-pane fade"
                                id={el.columns + "-teb"}
                                role="tabpanel"
                                aria-labelledby={el.id}
                              >
                                <div
                                  className="table-responsive "
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <MDBDataTable
                                    sortable={false}
                                    className="table table-head-fixed text-center"
                                    striped
                                    bordered
                                    hover
                                    fixedHeader
                                    data={tableEmpTypeWork.data[index]}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}

                        {/* Tap 2 */}

                        {/* ************************************************** Tap 3 *************************************************** */}
                        {/* <div
                          className="tab-pane fade"
                          id="custom-tabs-four-page-3"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-four-page-3-tab"
                        >
                          Test 3
                        </div> */}

                        {/* ************************************************** End ******************************************************* */}
                      </div>
                    </div>

                    <Modal
                      isOpen={modal}
                      toggle={toggle_modal}
                      className="modal-dialog modal-xl"
                    >
                      <ModalHeader toggle={toggle_modal}>
                        <h1>
                          รายการ {template_name} ::: [{" "}
                          <font color="green">
                            สำเร็จ {exlx_detail_status.success}
                          </font>{" "}
                          /{" "}
                          <font color="red">
                            {" "}
                            ไม่สำเร็จ {exlx_detail_status.not_success}
                          </font>
                          ]
                        </h1>
                      </ModalHeader>
                      <ModalBody>
                        <div className="row">
                          <div className="col-4">
                            <label>
                              ตารางแสดงสถานะการอัพเดต {template_name}
                            </label>
                          </div>
                        </div>
                        <div
                          className="table-responsive"
                          style={{ height: "500px" }}
                        >
                          <MDBTable
                            striped
                            hover
                            bordered
                            small
                            searching={true}
                            responsive
                          >
                            <MDBTableHead>
                              <tr>
                                <th>No.</th>
                                <th>ชื่อ-นามสกุล</th>
                                <th>รหัสพนักงาน</th>
                                <th>หมายเลขบัตรประชาชน</th>
                                <th>ชื่อเล่น</th>
                                <th>วันเกิด</th>
                                <th>status</th>
                                <th>สัญชาติ</th>
                                <th>Line</th>
                                <th>เบอร์โทรศัพท์</th>
                                <th>หมายเหตุ</th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {exlx_detail_view.map((el, index) => {
                                var color;
                                var text_status;
                                if (el.is_success === false) {
                                  color = "red";
                                  text_status = "ไม่สำเร็จ";
                                } else {
                                  color = "green";
                                  text_status = "สำเร็จ";
                                }
                                return (
                                  <tr id={index}>
                                    <td>
                                      <font color={color}>{index + 1}</font>
                                    </td>
                                    <td>
                                      <font color={color}>
                                        {el.emp_name_th}
                                      </font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.emp_no}</font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.citizen_id}</font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.nickname}</font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.birthday}</font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.status}</font>
                                    </td>
                                    <td>
                                      <font color={color}>
                                        {el.employee_nation}
                                      </font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.line}</font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.mobile}</font>
                                    </td>
                                    <td>
                                      <font color={color}>{el.remark}</font>
                                    </td>
                                  </tr>
                                );
                              })}
                            </MDBTableBody>
                          </MDBTable>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
                        <Button
                          color="secondary"
                          onClick={() => {
                            toggle_modal();
                            modalRef.current.click();
                          }}
                        >
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>

                    {/* /.card-body */}
                    <div className="card-footer"></div>
                    {/* /.card-footer*/}
                  </div>
                </>
              );
            }
          })}
          {checkFeature.data === -1 ? (
            <>
              <div className="card card-primary card-outline card-outline-tabs">
                <div className="card-header p-0 border-bottom-0"> </div>
                <div className="card-body">
                  <div
                    className="table-responsive "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed text-center"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={data}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* {checkFeature.data === -1 ?
            <>
              <div
                className="table-responsive "
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </>
            


 
          }*/}

          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Employee_Info;
