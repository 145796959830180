import React, { useRef } from "react";
import { useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import PO_Products_Component from "./Component/PO_Products_Component";
import { getDropdown } from "../../../../Utils/Employee_history";

function Purchase_order_AEV({ mode, isHistory }) {
  const [quotation_data, setQuotation_data] = useState({});

  const [supplierDD, setSupplierDD] = useState({
    data: [],
  });

  const [supplierNameDD, setSupplierNameDD] = useState({
    data: [],
  });

  const [on_change_supName, setOn_change_supName] = useState({
    data: "",
  });
  ///////////////////////////

  const { id } = useParams();

  const [disable, setdisable] = useState(null);

  const [pageMode, setPageMode] = useState("");

  const [purchaseOrder_data, setPurchaseOrder_data] = useState({
    po_id: "",
    po_no: "",
    po_ref_po: "",
    po_date: "",
    po_ref: "",
    po_sup_id: "",
    po_sup_name: "",
    po_sup_contact_name: "",
    po_sup_pt_id: "",
    po_sup_ct_id: "",
    po_sup_contact_email: "",
    po_sup_tel: "",
    po_address: "",
    po_sup_remark: "",
    po_lang: false,
    po_currency_id: "",
    po_incl_vat: false,
    po_final_discount: "",
    po_total_befor_vat: "",
    po_vat: "",
    po_total_price: "",
    po_remark: "",
    po_is_use: "",
    po_ps_id: "",
    po_prs_id: "",
    po_approve_date: "",
    po_approve_user_id: "",
    po_approve_user_name: "",
    po_created_date: "",
    po_updated_date: "",
    po_created_by: "",
    po_updated_by: "",
    po_created_name: "",
    po_updated_name: "",
    po_oem_id: "",
    po_company_id: "",
    po_version: "",

    po_sup_contact_name_index: "",
    po_confirm_sup: false,
    po_sup_container_type_id: "",
  });

  const [inputDataTab2, setInputDataTab2] = useState({
    data: [],
  });

  const [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });

  const [functionSaveTab2, setFunctionSaveTab2] = useState({
    data: null,
  });

  const [refPurchaseOrderDD, setRefPurchaseOrderDD] = useState({
    data: [],
  });

  const [TermDataDD, setTermDataDD] = useState({
    data: [],
  });

  const [ContainerTypeDD, setContainerTypeDD] = useState({
    data: [],
  });

  const [sup_CTI_DD, setSup_CTI_DD] = useState({
    data: [],
  });

  const [ref_id_po, setRef_id_po] = useState({
    data: "",
  });

  const closeModalCustomer = useRef();

  const clickCloseModalCustomer = () => {
    closeModalCustomer.current.click();
  };

  useEffect(async () => {
    setPageMode(mode === "view" ? "View" : mode === "edit" ? "Edit" : "Add");
    setdisable(mode === "view" ? true : false);
    if (mode === "add") {
      setPurchaseOrder_data({
        ...purchaseOrder_data,
        po_no: "Auto Generate",
        po_date: new Date(),
      });
      purchaseOrder_data.po_date = new Date();
      purchaseOrder_data.po_no = "Auto Generate";
    }
    await getPurchaseOrderRef();
    await getSupplierDD();
    // await getTermDataDD();
    const containerDD = await getDropdown("container");
    setSup_CTI_DD({ data: containerDD });
    sup_CTI_DD.data = containerDD;

    await getContainerTypeDD();

    if (id) {
      await getQuotationById();
    }

    /* await getRouteDropDown();
        await GetProduct();
        await GetShippingByDropDown();

        if (id) {
            await getBillById();
        } */
  }, []);

  const resetPO_Ref = async () => {
    setPurchaseOrder_data({
      ...purchaseOrder_data,
      po_ref: "",
      po_sup_id: "",
      po_sup_name: "",
      po_sup_contact_name: "",
      po_sup_pt_id: "",
      po_sup_ct_id: "",
      po_sup_contact_email: "",
      po_sup_tel: "",
      po_address: "",
      po_sup_remark: "",
      po_lang: false,
      po_currency_id: "",
      po_incl_vat: true,
      po_final_discount: "",
      po_total_befor_vat: "",
      po_vat: "",
      po_total_price: "",
      po_remark: "",
      po_is_use: "",
      po_ps_id: "",
      po_prs_id: "",
      po_approve_date: "",
      po_approve_user_id: "",
      po_approve_user_name: "",
      po_created_date: "",
      po_updated_date: "",
      po_created_by: "",
      po_updated_by: "",
      po_created_name: "",
      po_updated_name: "",
      po_oem_id: "",
      po_company_id: "",
      po_version: "",

      po_sup_contact_name_index: "",
      po_confirm_sup: false,
      po_sup_container_type_id: "",
    });

    purchaseOrder_data.po_ref = "";
    purchaseOrder_data.po_sup_id = "";
    purchaseOrder_data.po_sup_name = "";
    purchaseOrder_data.po_sup_contact_name = "";
    purchaseOrder_data.po_sup_pt_id = "";
    purchaseOrder_data.po_sup_ct_id = "";
    purchaseOrder_data.po_sup_contact_email = "";
    purchaseOrder_data.po_sup_tel = "";
    purchaseOrder_data.po_address = "";
    purchaseOrder_data.po_sup_remark = "";
    purchaseOrder_data.po_lang = false;
    purchaseOrder_data.po_currency_id = "";
    purchaseOrder_data.po_incl_vat = true;
    purchaseOrder_data.po_final_discount = "";
    purchaseOrder_data.po_total_befor_vat = "";
    purchaseOrder_data.po_vat = "";
    purchaseOrder_data.po_total_price = "";
    purchaseOrder_data.po_remark = "";
    purchaseOrder_data.po_is_use = "";
    purchaseOrder_data.po_ps_id = "";
    purchaseOrder_data.po_prs_id = "";
    purchaseOrder_data.po_approve_date = "";
    purchaseOrder_data.po_approve_user_id = "";
    purchaseOrder_data.po_approve_user_name = "";
    purchaseOrder_data.po_created_date = "";
    purchaseOrder_data.po_updated_date = "";
    purchaseOrder_data.po_created_by = "";
    purchaseOrder_data.po_updated_by = "";
    purchaseOrder_data.po_created_name = "";
    purchaseOrder_data.po_updated_name = "";
    purchaseOrder_data.po_oem_id = "";
    purchaseOrder_data.po_company_id = "";
    purchaseOrder_data.po_version = "";

    purchaseOrder_data.po_sup_contact_name_index = "";
    purchaseOrder_data.po_confirm_sup = false;
    purchaseOrder_data.po_sup_container_type_id = "";

    setOn_change_supName({ data: "" });
    on_change_supName.data = "";
  };

  const clearSupplier = () => {
    setPurchaseOrder_data({
      ...purchaseOrder_data,
      po_sup_id: "",
      po_sup_name: "",
      po_sup_contact_name: "",
      po_sup_pt_id: "",
      po_sup_ct_id: "",
      po_sup_contact_email: "",
      po_sup_tel: "",
      po_address: "",
      po_sup_remark: "",
      po_sup_container_type_id: "",
      po_sup_contact_name_index: "",
    });
    purchaseOrder_data.po_sup_id = "";
    purchaseOrder_data.po_sup_name = "";
    purchaseOrder_data.po_sup_contact_name = "";
    purchaseOrder_data.po_sup_pt_id = "";
    purchaseOrder_data.po_sup_ct_id = "";
    purchaseOrder_data.po_sup_contact_email = "";
    purchaseOrder_data.po_sup_tel = "";
    purchaseOrder_data.po_address = "";
    purchaseOrder_data.po_sup_remark = "";
    purchaseOrder_data.po_sup_contact_name_index = "";
    purchaseOrder_data.po_sup_container_type_id = "";

    setOn_change_supName({ data: "" });
    on_change_supName.data = "";
  };

  const addCustomerDetails = async () => {
    const sup_id = purchaseOrder_data.po_sup_id;
    const sup_name_contact_index = purchaseOrder_data.po_sup_contact_name_index;
    const tempAddress = supplierDD.data?.find((el) => el.sup_id === sup_id);
    // console.log('tempAddress, tempCustomer', tempAddress, tempCustomer);
    setPurchaseOrder_data({
      ...purchaseOrder_data,
      po_sup_name: tempAddress.sup_name,
      po_sup_contact_name:
        sup_name_contact_index == "1"
          ? tempAddress.sup_contract_name
          : tempAddress.sup_contract_name2,
      po_sup_contact_email:
        sup_name_contact_index == "1"
          ? tempAddress.sup_contract_email
          : tempAddress.sup_contract_email2,
      po_sup_tel:
        sup_name_contact_index == "1"
          ? tempAddress.sup_contract_tel
          : tempAddress.sup_contract_tel2,
      po_sup_pt_id: tempAddress.sup_pt_id,
      po_sup_ct_id: tempAddress.sup_ct_id,
      po_address: tempAddress.sup_address || "",
    });
    purchaseOrder_data.po_sup_name = tempAddress.sup_name;
    purchaseOrder_data.po_sup_contact_name =
      sup_name_contact_index == "1"
        ? tempAddress.sup_contract_name
        : tempAddress.sup_contract_name2;
    purchaseOrder_data.po_sup_contact_email =
      sup_name_contact_index == "1"
        ? tempAddress.sup_contract_email
        : tempAddress.sup_contract_email2;
    purchaseOrder_data.po_sup_tel =
      sup_name_contact_index == "1"
        ? tempAddress.sup_contract_tel
        : tempAddress.sup_contract_tel2;
    purchaseOrder_data.po_sup_pt_id = tempAddress.sup_pt_id;
    purchaseOrder_data.po_sup_ct_id = tempAddress.sup_ct_id;
    purchaseOrder_data.po_address = tempAddress.sup_address || "";

    if (tempAddress.sup_name) {
      setOn_change_supName({ data: tempAddress.sup_name });
      on_change_supName.data = tempAddress.sup_name;
    }
  };

  async function getSupplierDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getSupplierDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setSupplierDD({ data: response.data });
        supplierDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getTermDataDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getPaymentTerm",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setTermDataDD({ data: response.data });
        TermDataDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getContainerTypeDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getConditionTerm",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setContainerTypeDD({ data: response.data });
        ContainerTypeDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getPurchaseOrderRef = async () => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/getPurchaseOrderRef",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setRefPurchaseOrderDD({ ...refPurchaseOrderDD, data: response.data });
        refPurchaseOrderDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getQuotationById = async (ref_id) => {
    let temp = {
      po_id: ref_id ? ref_id : id,
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      isHistory: isHistory || false,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        `/api/purchase_order/${"getPurchaseOrderById"}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        const data = response.data.data[0];
        if ((mode === "edit" || mode === "view") && !ref_id) {
          setPurchaseOrder_data({
            ...purchaseOrder_data,
            po_no: data.po_no || "",
            po_date: new Date(data.po_date) || "",
            po_ref: data.po_ref || "",
            po_approve_date: data.po_approve_date || "",
            po_approve_user_id: data.po_approve_user_id || "",
            po_approve_user_name: data.po_approve_user_name || "",
            po_version: data.po_version || "",
            po_created_name: data.po_created_name || "",
            po_updated_name: data.po_updated_name || "",
            po_created_date: data.po_created_date || "",
            po_updated_date: data.po_updated_date || "",
            po_created_by: data.po_created_by || "",
            po_updated_by: data.po_updated_by || "",
          });
          purchaseOrder_data.po_no = data.po_no || "";
          purchaseOrder_data.po_date = new Date(data.po_date) || "";
          purchaseOrder_data.po_ref = data.po_ref || "";
          purchaseOrder_data.po_approve_date = data.po_approve_date || "";
          purchaseOrder_data.po_approve_user_id = data.po_approve_user_id || "";
          purchaseOrder_data.po_approve_user_name =
            data.po_approve_user_name || "";
          purchaseOrder_data.po_version = data.po_version || "";
          purchaseOrder_data.po_created_name = data.po_created_name || "";
          purchaseOrder_data.po_updated_name = data.po_updated_name || "";
          purchaseOrder_data.po_created_date = data.po_created_date || "";
          purchaseOrder_data.po_updated_date = data.po_updated_date || "";
          purchaseOrder_data.po_created_by = data.po_created_by || "";
          purchaseOrder_data.po_updated_by = data.quo_updated_by || "";
        }
        setPurchaseOrder_data({
          ...purchaseOrder_data,
          po_id: mode === "edit" || mode === "view" ? id : "",
          po_ref_po: ref_id ? ref_id : data.po_ref_po || "",
          po_sup_id: data.po_sup_id || "",
          po_sup_name: data.po_sup_name || "",
          po_sup_contact_name: data.po_sup_contact_name || "",
          po_sup_pt_id: data.po_sup_pt_id || "",
          po_sup_ct_id: data.po_sup_ct_id || "",
          po_sup_contact_email: data.po_sup_contact_email || "",
          po_sup_tel: data.po_sup_tel || "",
          po_address: data.po_address || "",

          po_sup_remark: data.po_sup_remark || "",
          po_remark: data.po_remark || "",
          po_lang: data.po_lang === "TH" ? true : false, /// true is TH and flase is EN,
          po_currency_id: data.po_currency_id,
          po_incl_vat: data.po_incl_vat,
          po_final_discount: data.po_final_discount || "",
          po_total_befor_vat: data.po_total_befor_vat || "",
          po_vat: data.po_vat || "",
          po_total_price: data.po_total_price || "",
          po_is_use: data.po_is_use || "",
          po_ps_id: data.po_ps_id || "",
          po_sup_container_type_id: data.po_sup_container_type_id || "",
        });
        purchaseOrder_data.po_id = mode === "edit" || mode === "view" ? id : "";
        purchaseOrder_data.po_ref_po = ref_id ? ref_id : data.po_ref_po || "";
        purchaseOrder_data.po_sup_id = data.po_sup_id || "";
        purchaseOrder_data.po_sup_name = data.po_sup_name || "";
        purchaseOrder_data.po_sup_contact_name = data.po_sup_contact_name || "";
        purchaseOrder_data.po_sup_pt_id = data.po_sup_pt_id || "";
        purchaseOrder_data.po_sup_ct_id = data.po_sup_ct_id || "";
        purchaseOrder_data.po_sup_contact_email =
          data.po_sup_contact_email || "";
        purchaseOrder_data.po_sup_tel = data.po_sup_tel || "";
        purchaseOrder_data.po_address = data.po_address || "";

        purchaseOrder_data.po_sup_remark = data.po_sup_remark || "";
        purchaseOrder_data.po_remark = data.po_remark || "";
        purchaseOrder_data.po_lang = data.po_lang === "TH" ? true : false; /// true is TH and flase is ENG
        purchaseOrder_data.po_currency_id = data.po_currency_id;
        purchaseOrder_data.po_incl_vat = data.po_incl_vat;
        purchaseOrder_data.po_final_discount = data.po_final_discount || "";
        purchaseOrder_data.po_total_befor_vat = data.po_total_befor_vat || "";
        purchaseOrder_data.po_vat = data.po_vat || "";
        purchaseOrder_data.po_total_price = data.po_total_price || "";
        purchaseOrder_data.po_is_use = data.po_is_use || "";
        purchaseOrder_data.po_ps_id = data.po_ps_id || "";
        purchaseOrder_data.po_sup_container_type_id =
          data.po_sup_container_type_id || "";

        if (data.po_sup_name) {
          setOn_change_supName({ data: data.po_sup_name });
          on_change_supName.data = data.po_sup_name;
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const sendApprovePO = () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะ Send Approve Quotation นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave();

        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
          return;
        } else {
          const temp = {
            company_id: getUser().com,
            oem_id: getOem(),
            user_id: getUser().fup,
            user_name: getUser().name + " " + getUser().surname,
            data: purchaseOrder_data,
            products: inputDataTab2,
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus_sales +
              `/api/purchase_order/sendApprovePO`,
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(async function (response) {
              // console.log('response saveDrafQuotation -', response.data);

              Swal.fire({
                icon: "success",
                title: "Send Approve สำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.href =
                  "/Warehouse_Management/purchase_order_cuteplus";
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
      }
    });
  };

  const cancelPurchaseOrder = async () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ PO นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const temp = {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          user_name: getUser().name + " " + getUser().surname,
          po_id: id,
        };
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus_sales +
            `/api/purchase_order/cancelPurchaseOrder`,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            // console.log('response saveDrafQuotation -', response.data);

            Swal.fire({
              icon: "success",
              title: "ยกเลิกรายการ PO สำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href =
                "/Warehouse_Management/purchase_order_cuteplus";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  };

  const checkErrorForSave = async () => {
    let error_list = [];
    // console.log('quotation_data', quotation_data);

    if (purchaseOrder_data.po_sup_name) {
      purchaseOrder_data.po_sup_name = purchaseOrder_data.po_sup_name?.trim();
    }
    if (purchaseOrder_data.po_sup_contact_name) {
      purchaseOrder_data.po_sup_contact_name =
        purchaseOrder_data.po_sup_contact_name?.trim();
    }
    if (purchaseOrder_data.po_sup_contact_email) {
      purchaseOrder_data.po_sup_contact_email =
        purchaseOrder_data.po_sup_contact_email?.trim();
    }

    if (on_change_supName.data) {
      if (on_change_supName.data !== purchaseOrder_data.po_sup_name) {
        setPurchaseOrder_data({
          ...purchaseOrder_data,
          po_sup_id: null,
        });
        purchaseOrder_data.po_sup_id = null;
      }
    }

    if (purchaseOrder_data.po_total_price) {
      purchaseOrder_data.po_total_price =
        typeof purchaseOrder_data.po_total_price === "string"
          ? parseFloat(purchaseOrder_data.po_total_price?.replace(/,/g, ""))
          : purchaseOrder_data.po_total_price;
    }
    
    /* if (purchaseOrder_data.po_sup_tel) {
      if (purchaseOrder_data.po_sup_tel?.replace(/-/g, "")?.trim().length !== 10) {
        let temp_err = {
          message: `Contact Tel ต้องมี 10 หลัก`,
        };
        error_list.push(temp_err);
      }
    } */
    if (purchaseOrder_data.po_address) {
      purchaseOrder_data.po_address = purchaseOrder_data.po_address?.trim();
    }
    if (purchaseOrder_data.po_remark) {
      purchaseOrder_data.po_remark = purchaseOrder_data.po_remark?.trim();
    }
    if (purchaseOrder_data.po_sup_remark) {
      purchaseOrder_data.po_sup_remark =
        purchaseOrder_data.po_sup_remark?.trim();
    }

    if (
      purchaseOrder_data.po_sup_name === null ||
      purchaseOrder_data.po_sup_name === ""
    ) {
      let temp_err = {
        message: `กรุณากรอก Supplier Name`,
      };
      error_list.push(temp_err);
    }

    if (
      purchaseOrder_data.po_sup_ct_id === null ||
      purchaseOrder_data.po_sup_ct_id.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณาเลือก Term`,
      };
      error_list.push(temp_err);
    }

    if (
      purchaseOrder_data.po_sup_container_type_id === null ||
      purchaseOrder_data.po_sup_container_type_id.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณาเลือก Container Type`,
      };
      error_list.push(temp_err);
    }

    return error_list;
  };

  async function saveOption() {
    /* await functionSaveTab2.data();
    
        console.log(...errorListTab2.data); */
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave();

        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              data: purchaseOrder_data,
              products: inputDataTab2,
            };
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus_sales +
                `/api/purchase_order/${
                  mode === "add"
                    ? "saveDrafPurchaseOrder"
                    : "updateDrafPurchaseOrder"
                }`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                // console.log('response saveDrafQuotation -', response.data);
              })
              .then(async function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href =
                    "/Warehouse_Management/purchase_order_cuteplus";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
            "/Warehouse_Management/purchase_order_cuteplus";
        }
      });
    } else {
      window.location.href = "/Warehouse_Management/purchase_order_cuteplus";
    }
  }

  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Purchase Order</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Purchase Order</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={() => {
                      cancelOption();
                    }}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={() => {
                      cancelOption();
                    }}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
                <div
                  className="col-6 col-md-3 col-xl-2"
                  hidden={mode !== "edit" ? true : false}
                >
                  <button
                    type="button"
                    onClick={sendApprovePO}
                    className="btn btn-block btn-info"
                    id="btn-save"
                  >
                    Send Approve
                  </button>
                </div>
              </div>
            )}

            <div className="row mb-2 mt-2">
              <div className="col-6 col-md-6 col-xl-6">
                <h4 style={{ whiteSpace: "nowrap" }}>
                  PO Current Version : {purchaseOrder_data.po_version || ""}
                </h4>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div
                className="row"
                style={{ justifyContent: "start", alignItems: "start" }}
              >
                <div
                  className="col-3"
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <h3 className="card-title" style={{ whiteSpace: "nowrap" }}>
                    {pageMode} Purchase Order (PO){" "}
                  </h3>
                  <button
                    hidden={mode !== "edit" ? true : false}
                    onClick={async () => {
                      await cancelPurchaseOrder();
                    }}
                    style={{ whiteSpace: "nowrap" }}
                    type="button"
                    className="btn btn-block btn-danger"
                  >
                    Cancel Purchase Order
                  </button>
                </div>
              </div>
            </div>

            <div className="card-body">
              {/* <div className="row mb-2">
                                <div className="col-6">
                                    <h2>
                                        <b>Quotation Detail</b>
                                    </h2>
                                </div>
                            </div> */}

              <div className="row">
                <div className="col-12 col-md-5 col-xl-3">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={true}
                      value={purchaseOrder_data.po_no}
                    />
                    <label htmlFor="">Purchase Order (PO) No.</label>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-xl-3">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={purchaseOrder_data.po_ref_po}
                      onChange={async (e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_ref_po: e.target.value,
                        });
                        purchaseOrder_data.po_ref_po = e.target.value;
                        if (e.target.value) {
                          await getQuotationById(e.target.value);
                        } else {
                          await resetPO_Ref();
                        }
                        setRef_id_po({
                          ...ref_id_po,
                          data: e.target.value,
                        });
                        ref_id_po.data = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select a status
                      </option>
                      {refPurchaseOrderDD.data?.map((option, index) => (
                        <option key={option.po_id} value={option.po_id}>
                          {option.po_no}
                        </option>
                      ))}
                    </select>
                    <label>Ref. Purchase Order No. </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div className="form-group">
                    <DatePicker
                      required
                      disabled={true}
                      selected={purchaseOrder_data.po_date}
                      locale="th"
                      wrapperClassName="w-100"
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_date: date,
                        });
                      }}
                      selectsStart
                      showYearDropdown
                      showMonthDropdown
                      startDate={new Date()}
                      minDate={new Date()}
                      customInput={<CustomInput isDis={true} />}
                    />
                    <label>
                      Purchase Order Date
                      {/* {reportDate.startDate === "" ||
                      reportDate.startDate === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                  {/* <label style={{ whiteSpace: 'nowrap', fontSize: 'x-large' }} htmlFor="">
                                        Purchase Order Date
                                        {(purchaseOrder_data.po_date === "" ||
                                            purchaseOrder_data.po_date === null) ? (
                                            <span style={{ color: "red" }}> *</span>
                                        ) : (
                                            ""
                                        )}
                                    </label>

                                    <div className="form-group ">
                                        <DatePicker
                                            selected={purchaseOrder_data.po_date}
                                            disabled={true}
                                            dateFormat={"dd-MM-yyyy"}
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                                flip: {
                                                    behavior: ["bottom"] // don't allow it to flip to be above
                                                },
                                                preventOverflow: {
                                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                },
                                                hide: {
                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                }
                                            }}
                                            onChange={async (date) => {
                                                setPurchaseOrder_data({
                                                    ...purchaseOrder_data,
                                                    po_date: date,
                                                })
                                            }}
                                            // selectsStart
                                            // startDate={purchaseOrder_data.po_date}
                                            minDate={purchaseOrder_data.po_date}
                                            customInput={<Custom_Input_date isDis={disable} />}
                                        />
                                    </div> */}
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={disable}
                      value={purchaseOrder_data.po_ref}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_ref: e.target.value,
                        });
                        purchaseOrder_data.po_ref = e.target.value;
                      }}
                    />
                    <label htmlFor="">
                      Ref.
                      {/* {purchaseOrder_data.po_ref === "" ? (
                                                <span style={{ color: "red" }}> *</span>
                                            ) : (
                                                ""
                                            )} */}
                    </label>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-12">
                  <h2>
                    <b>Supplier Detail</b>
                  </h2>
                </div>
              </div>
              <hr style={{ marginTop: "0" }}></hr>
              <div className="row">
                <div className="col-12 col-md-5 col-xl-4">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={disable}
                      value={purchaseOrder_data.po_sup_name}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_name: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="">
                      Supplier Name
                      {purchaseOrder_data.po_sup_name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <a
                      data-toggle="modal"
                      data-target="#modalQuotationCustomer"
                      style={{ cursor: "pointer" }}
                      hidden={disable}
                      onClick={() => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_contact_name: "",
                          po_sup_contact_name_index: "",
                        });
                        purchaseOrder_data.po_sup_contact_name = "";
                        purchaseOrder_data.po_sup_contact_name_index = "";
                      }}
                    >
                      <i class="fas fa-search"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={purchaseOrder_data.po_sup_contact_name}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_contact_name: e.target.value,
                        });
                        purchaseOrder_data.po_sup_contact_name = e.target.value;
                      }}
                    />
                    <label>Supplier Contact Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={purchaseOrder_data.po_sup_ct_id}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_ct_id: e.target.value,
                        });
                        purchaseOrder_data.po_sup_ct_id = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        -- กรุณาเลือก --
                      </option>
                      {ContainerTypeDD.data.map((data) => {
                        return (
                          <option value={data.ct_id}>{data.ct_name}</option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Term
                      {purchaseOrder_data.po_sup_ct_id === "" ||
                      purchaseOrder_data.po_sup_ct_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    {/* <select
                                            type="text"
                                            className="form-control"
                                            required
                                            disabled={disable}
                                            value={purchaseOrder_data.po_sup_pt_id}
                                            onChange={(e) => {
                                                setPurchaseOrder_data({
                                                    ...purchaseOrder_data,
                                                    po_sup_pt_id: e.target.value,
                                                });
                                                purchaseOrder_data.po_sup_pt_id = e.target.value;
                                            }}
                                        >
                                            <option key="" value="">
                                                -- กรุณาเลือก --
                                            </option>
                                            {TermDataDD.data.map((data) => {
                                                return (
                                                    <option value={data.pt_id}>
                                                        {data.pt_name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <label
                                            style={{
                                                lineHeight: "normal",
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            Term
                                            {purchaseOrder_data.po_sup_pt_id === "" ||
                                                purchaseOrder_data.po_sup_pt_id === null ? (
                                                <span style={{ color: "red" }}> *</span>
                                            ) : (
                                                ""
                                            )}
                                        </label> */}
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={purchaseOrder_data.po_sup_container_type_id}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_container_type_id: e.target.value,
                        });
                        purchaseOrder_data.po_sup_container_type_id =
                          e.target.value;
                      }}
                    >
                      <option key="" value="">
                        -- กรุณาเลือก --
                      </option>
                      {sup_CTI_DD.data.map((data) => {
                        return (
                          <option value={data.ct_id}>{data.ct_name}</option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Container Type
                      {purchaseOrder_data.po_sup_container_type_id === "" ||
                      purchaseOrder_data.po_sup_container_type_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={purchaseOrder_data.po_sup_contact_email}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_contact_email: e.target.value,
                        });
                        purchaseOrder_data.po_sup_contact_email =
                          e.target.value;
                      }}
                    />
                    <label>Contact Email</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      // format={"###-###-####"}
                      required
                      disabled={disable}
                      value={purchaseOrder_data.po_sup_tel}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_tel: e.target.value,
                        });
                        purchaseOrder_data.po_sup_tel = e.target.value;
                      }}
                    />
                    <label>Contact Tel.</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-xl-10">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={2}
                      disabled={disable}
                      style={{
                        height: "100px",
                        fontSize: "25px",
                      }}
                      value={purchaseOrder_data.po_address}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_address: e.target.value,
                        });
                        purchaseOrder_data.po_address = e.target.value;
                      }}
                      defaultValue=""
                      required
                    />
                    <label>ที่อยู่</label>
                  </div>
                </div>

                <div className="col-12 col-md-12 col-xl-10">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={2}
                      disabled={disable}
                      style={{
                        height: "100px",
                        fontSize: "25px",
                      }}
                      value={purchaseOrder_data.po_sup_remark}
                      onChange={(e) => {
                        setPurchaseOrder_data({
                          ...purchaseOrder_data,
                          po_sup_remark: e.target.value,
                        });
                        purchaseOrder_data.po_sup_remark = e.target.value;
                      }}
                      defaultValue=""
                      required
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>

              <hr className="mt-0"></hr>
              <div className="row">
                <div className="col-6">
                  <h2>
                    <b>Product</b>
                  </h2>
                </div>
              </div>
              <div>
                <PO_Products_Component
                  id={id}
                  mode={mode}
                  isHistory={isHistory}
                  isDisabled={disable}
                  ref_id_po={ref_id_po}
                  purchaseOrder_data={purchaseOrder_data}
                  setPurchaseOrder_data={setPurchaseOrder_data}
                  inputDataTab2={inputDataTab2}
                  setInputDataTab2={setInputDataTab2}
                  errorListTab2={errorListTab2}
                  setErrorListTab2={setErrorListTab2}
                  functionSaveTab2={functionSaveTab2}
                  setFunctionSaveTab2={setFunctionSaveTab2}
                />
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
        <div
          className="modal fade"
          id="modalQuotationCustomer"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalfoodRedisterLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              {/* Header */}
              <div className="modal-header">
                <h4 className="modal-title" id="modalfoodRedisterLabel">
                  <b>Supplier</b>
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* Body */}
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <h5>
                      <b>Supplier</b>
                      <span style={{ color: "red" }}> *</span>
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div class="form-group">
                      <select
                        type="text"
                        className="form-control"
                        required
                        value={purchaseOrder_data.po_sup_id}
                        onChange={(e) => {
                          const customerName = supplierDD.data?.find(
                            (el) => el.sup_id === e.target.value
                          );

                          setPurchaseOrder_data({
                            ...purchaseOrder_data,
                            po_sup_id: e.target.value,
                          });
                          purchaseOrder_data.po_sup_id = e.target.value;

                          let tempList = [];
                          if (customerName) {
                            tempList.push(customerName.sup_contract_name);
                            if (customerName.sup_contract_name2) {
                              tempList.push(customerName.sup_contract_name2);
                            }
                          }
                          setSupplierNameDD({
                            ...supplierNameDD,
                            data: tempList,
                          });
                          supplierNameDD.data = tempList;
                        }} // customerDD.data
                      >
                        <option key="" value="">
                          Select a Supplier
                        </option>
                        {supplierDD.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.sup_id}>
                              {item.sup_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <h5>
                      <b>Supplier Name</b>
                      <span style={{ color: "red" }}> *</span>
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div class="form-group">
                      <select
                        type="text"
                        className="form-control"
                        required
                        disabled={!purchaseOrder_data.po_sup_id ? true : false}
                        value={
                          purchaseOrder_data.po_sup_contact_name_index || ""
                        }
                        onChange={(e) => {
                          setPurchaseOrder_data({
                            ...purchaseOrder_data,
                            po_sup_contact_name_index: e.target.value,
                          });
                          purchaseOrder_data.po_sup_contact_name_index =
                            e.target.value;
                        }}
                      >
                        <option key="" value="">
                          Select a Supplier Name
                        </option>
                        {supplierNameDD?.data?.map((item, index) => {
                          return (
                            <option key={index} value={index + 1}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* Footer */}
              <div
                className="modal-footer"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  ref={closeModalCustomer}
                >
                  ยกเลิก
                </button>
                {true ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        if (!purchaseOrder_data.po_sup_id) {
                          clearSupplier();
                        } else {
                          if (!purchaseOrder_data.po_sup_contact_name_index) {
                            Swal.fire(
                              "Error",
                              "กรุณาเลือก Supplier name ก่อนทำรายการถัดไป",
                              "error"
                            );
                            return;
                          }
                          setPurchaseOrder_data({
                            ...purchaseOrder_data,
                            po_confirm_sup: true,
                          });
                          purchaseOrder_data.po_confirm_sup = true;

                          addCustomerDetails();
                        }
                        clickCloseModalCustomer();
                      }}
                    >
                      บันทึก
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchase_order_AEV;
