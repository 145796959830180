import React, { useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser, getAcademy, setOemlist } from "../../../../Utils/Common";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import {
    Progress,
    Tooltip,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from "reactstrap";
import moment from "moment";
function SaveMessage(params) {
    const [filterteam, setFilterTeam] = useState({
        file_name: "",
        cat_name: "",
        rc_id: "",
    });

    const [filCategory, setFilterCategory] = useState([]);
    const [checkNews, setCheckNews] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [dataFileByCate, setDataFileByCate] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(dataFileByCate);
    const [allEmployee, setAllEmployee] = useState({ data: [] });
    useEffect(() => {
        const results = dataFileByCate.filter(item =>
            item.file_name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
        );
        setFilteredItems(results);
    }, [searchTerm, dataFileByCate]);


    const GetAllCatgory = async () => {
        await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterManageMessageType",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: filterteam,
        })
            .then(async function (response) {
                const activeItems = response.data.data.filter(item => {
                    return item.rc_is_active === true;
                });

                if (Array.isArray(response.data.data) && activeItems.length > 0) {
                    setFilterCategory(activeItems);
                } else {
                    setFilterCategory([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    };

    const CheckNewsData = async () => {
        await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/checkNewsData",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: {
                user_id: getUser().fup,
            },
        })
            .then(async function (response) {
                if (response.data.data && Array.isArray(response.data.data)) {
                    const currentDate = new Date();
                    const groupedData = response.data.data
                        .filter(row => {
                            const startDate = new Date(row.rf_start_date);
                            const endDate = new Date(row.rf_end_date);
                            if (row.user_checked === 0) {
                                return true;
                            }
                            return (startDate <= currentDate);
                        })
                        .map(row => ({
                            rp_rc_id: row.rp_rc_id || null,
                            rc_name: row.rc_name || null,
                            rf_name: row.rf_name || null,
                            startDate: row.rf_start_date,
                            endData: row.rf_end_date,
                            user_checked: row.user_checked || null,
                        }))
                    setCheckNews(groupedData);
                } else {
                    setCheckNews([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    };

    async function filterFileByCategory(id) {
        let data = {
            rc_id: id,
            user_id: getUser().fup,
        }
        await axios({
            method: "post",
            url:
                Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterFileByCategory",
            headers: {
                Authorization: getToken(),
                "Content-Type": "application/json",
                "X-TTT": Configs.API_TTT,
            },
            data: data,
        })
            .then(function (response) {
                if (response.data.data && Array.isArray(response.data.data)) {
                    const currentDate = new Date();
                    const groupedData = response.data.data
                        .filter(row => {
                            const startDate = new Date(row.rf_start_date);
                            const endDate = new Date(row.rf_end_date);
                            if (row.user_checked === 0) {
                                return true;
                            }
                            return (startDate <= currentDate);
                        })
                        .map(row => ({
                            rf_id: row.rf_id || null,
                            file_name: row.rf_name || null,
                            file_path: row.rf_path_file || null,
                            file_path_name: row.rf_name_file || null,
                            number_readed: row.number_readed || 0,
                            rf_start_date: row.rf_start_date || null,
                            rf_end_date: row.rf_end_date || null,
                            user_checked: row.user_checked || null,
                            check_rf_id: id || null,
                        }))
                        .reduce((acc, row) => {
                            const existing = acc.find(item => item.rf_id === row.rf_id);
                            if (existing) {
                                existing.number_readed = Math.max(existing.number_readed, row.number_readed);
                            } else {
                                acc.push(row);
                            }
                            return acc;
                        }, []);
                    groupedData.sort((a, b) => {
                        if (a.user_checked === b.user_checked) return 0;
                        return a.user_checked === "0" ? -1 : 1;
                    });

                    setDataFileByCate(groupedData);
                } else {
                    setDataFileByCate([]);
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    }

    useEffect(async () => {
        await GetAllCatgory();
        GetEmployee();
        CheckNewsData();
    }, []);

    const handleCardClick = async (item) => {
        await filterFileByCategory(item.rc_id);
        setSelectedItem(item);
        setShowModal(true);
    };

    const handleCloseModal = async () => {
        GetAllCatgory();
        CheckNewsData();
        setShowModal(false);
        setSelectedItem(null);
        setSearchTerm('');
    };

    function generateDataTable(Message, index) {
        // console.log('Message: ', Message.check_rf_id);

        const element = {};
        element["no"] = index + 1;
        element["file_name"] = (
            <div style={{ minWidth: '250px', display: 'flex', justifyContent: 'start' }}>
                {Message?.file_name}
                {Message?.user_checked === "0" && (
                    <span style={{ color: 'white', backgroundColor: 'red', marginLeft: '10px', padding: "0 10px", borderRadius: "7px" }}>New</span>
                )}
            </div>
        );
        element["file_name2"] = Message?.file_name;
        element["start_date"] = Message?.rf_start_date
            ? moment(Message?.rf_start_date).format('DD/MM/YYYY')
            : "ไม่พบ";

        element["end_date"] = Message?.rf_end_date
            ? moment(Message?.rf_end_date).format('DD/MM/YYYY')
            : "ไม่พบ";
        element["view"] = `${(Message?.number_readed || 0)} / ${allEmployee?.data.length || 0}`;
        element["mgt"] = (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%" }}>
                    <a
                        // href={
                        //     Message.file_path && Message.file_path_name
                        //         ? encodeURI(Configs.API_URL_IMG + `${Message.file_path}`)
                        //         : ""
                        // }
                        onClick={() => {
                            DownloadFile(encodeURI(Configs.API_URL_IMG + `${Message?.file_path}`), Message?.file_path_name, Message?.user_checked, Message?.rf_id, Message?.check_rf_id);
                        }}
                        download={Message.file_path_name ? encodeURIComponent(Message?.file_path_name) : "default-filename"}
                        id="btn_read"
                        className="btn btn-block btn-primary"
                        style={{ minWidth: "130px", display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}
                    >
                        Download
                    </a>
                </div>
            </div>
        );
        return element;
    }

    const dataMess = [];
    for (let index = 0; index < filteredItems.length; index++) {
        const Message = filteredItems[index];
        dataMess.push(generateDataTable(Message, index));
    }

    const data = {
        columns: [
            {
                label: "No",
                field: "no",
                // sort: "asc",
                width: 50,
            },
            {
                label: "File name",
                field: "file_name",
                // sort: "asc",
                width: 100,
            },
            {
                label: "Start Date",
                field: "start_date",
                // sort: "asc",
                width: 100,
            },
            {
                label: "End Date",
                field: "end_date",
                // sort: "asc",
                width: 100,
            },
            {
                label: "รับทราบ",
                field: "view",
                // sort: "asc",
                width: 100,
            },
            {
                label: "Management",
                field: "mgt",
                // sort: "asc",
                // width: 50,
            },
        ],
        rows: dataMess,
    };

    const GetEmployee = async () => {
        var get_filler_employee = {
            oem_id: getOem(),
            company_id: getUser().com,
            emp_no: "",
            emp_name_th: "",
            emp_type_id: "",
            status_User: true,
        };
        await axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: get_filler_employee,
        })
            .then(async function (response) {
                if (response.data.data && Array.isArray(response.data.data)) {
                    const data = response.data.data.filter(el => {
                        return el.emp_status_type_id !== "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" && el.is_active !== false;
                    });
                    setAllEmployee({ ...allEmployee, data: data });
                    allEmployee.data = data;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const [heightOfContent, setHeightOfContent] = useState({
        data: 0
    });
    useEffect(() => {
        setTimeout(() => {
            const sidebar = document.querySelector('.main-sidebar');
            if (sidebar) {
                const height = sidebar.offsetHeight - 50;
                setHeightOfContent({ data: height });
                heightOfContent.data = height;
            }
        }, 1000)
    }, []);

    async function DownloadFile(url, fileName, check, id, check_id) {
        try {
            Swal.fire({
                title: 'Loading...',
                allowOutsideClick: false,
                showConfirmButton: false,  // Hide confirm button
                onBeforeOpen: () => {
                    Swal.showLoading();
                },
            });
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('File could not be downloaded');
            }
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName || 'downloaded_file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);

            Swal.close();

            if (check === "0") {
                var downloadFile = {
                    file_id: id,
                    user_id: getUser().fup,
                };
                await axios({
                    method: "post",
                    url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/saveDownLoadFile",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: downloadFile,
                })
                    .then(async function (response) {
                        Swal.fire({
                            icon: "success",
                            title: "Save",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then((result) => {
                            filterFileByCategory(check_id);
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        } catch (error) {
            console.error('Error downloading the file:', error.message);
            Swal.close();   
        }
    }

    return (
        <div className="wrapper">
            <div className="content-wrapper" style={heightOfContent.data ? { minHeight: `${heightOfContent.data}px` } : {}}>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>บันทึกข้อความ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Human Resource</li>
                                    <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                                    <li className="breadcrumb-item active">บันทึกข้อความ</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            {filCategory.map((el, index) => (
                                <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 d-flex align-items-stretch">
                                    <div
                                        className="card"
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => handleCardClick(el)}
                                    >
                                        {checkNews.map((b) => {
                                            if (b.rp_rc_id === el.rc_id && b.user_checked === "0") {
                                                return (
                                                    <div style={{ backgroundColor: 'red', borderRadius: "8px", position: 'absolute', right: 0, padding: "0 15px" }}>
                                                        <span style={{ color: 'white', display: 'flex', justifyContent: 'center' }}>New</span>
                                                    </div>
                                                );
                                            }
                                        })}
                                        <div className="card-body" style={{ display: 'flex', backgroundColor: 'white', borderRadius: '10px', flexDirection: 'column', alignItems: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                            {/* Customize the card content as needed */}
                                            <img alt="..." className="img-fluid" style={{ width: "auto", height: "110px", objectFit: "cover", marginBottom: '10px' }} src={Configs.API_URL_IMG + el.rc_path_image} />
                                            <div style={{ height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                                                <h3 style={{ fontSize: "24px", margin: 0 }}>{el.rc_name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Modal */}
                    <Modal isOpen={showModal} toggle={handleCloseModal} style={{ maxWidth: '90%', minWidth: '70%', width: '70%' }}>
                        <ModalHeader toggle={handleCloseModal} >
                        </ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                    <img
                                        alt="..."
                                        className="img-fluid"
                                        style={{ width: "auto", height: "130px", marginRight: '10px', objectFit: "cover" }}
                                        src={selectedItem?.rc_path_image ? Configs.API_URL_IMG + selectedItem?.rc_path_image : userdefault_img}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <h1 style={{ margin: 0, fontWeight: 'bold' }}>{selectedItem?.rc_name}</h1>
                                    <div className="form-group mt-2 mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ width: '240px' }}
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <label htmlFor="">File Name</label>
                                    </div>
                                </div>
                            </div>

                            <section className="content">
                                <div className="card">
                                    <div
                                        className="card-body table-responsive "
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <MDBDataTable
                                            sortable={false}
                                            className="table table-head-fixed"
                                            striped
                                            bordered
                                            hover
                                            fixedHeader
                                            data={data}
                                            search={false}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer"></div>
                                    {/* /.card-footer*/}
                                </div>
                                {/* /.card */}
                            </section>
                        </ModalBody>
                        {/* <ModalFooter>
                            <Button color="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </ModalFooter> */}
                    </Modal>

                </section>
            </div>
        </div>
    );
}

export default SaveMessage;
