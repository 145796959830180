import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import Configs from "../../../config";

function Company_Card(param) {
  const text_color =
    parseInt(param.model.bg_color.replace("#", ""), 16) > 0xffffff / 2
      ? "#3C3C3C"
      : "#ffffff";

  return (
    <div style={{ display: "inline-flex" }}>
      <div
        style={{
          display: "block",
          width: "347px",
          height: "100%",
          borderRadius: "10px",
          background: `${param.model.bg_color}`,
          boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.15)",
          marginRight: "4px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "347px",
            height: "14px",
            borderRadius: "10px 10px 0px 0px",
            background: `${param.model.top_color}`,
          }}
        />
        <div
          style={{
            width: "70px",
            height: "70px",
            borderRadius: "100%",
            background: `${
              param.model.bg_color === "#E9E9E9" ? "#ffffff" : "#E9E9E9"
            }`,
            position: "absolute",
            left: "20px",
            top: "50%",
            transform: "translate(0%, -38%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "100%",
              border: `4px solid ${param.model.bg_color}`,
              background: `${
                param.model.bg_color === "#E9E9E9" ? "#ffffff" : "#E9E9E9"
              }`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {param.model.abbreviation !== null &&
            param.model.abbreviation !== "" ? (
              <>
                <label
                  style={{
                    margin: "0px",
                    fontWeight: "700",
                    fontSize: "25px",
                    textTransform: "uppercase",
                    color: `${
                      param.model.bg_color === "#ffffff"
                        ? "#3C3C3C"
                        : parseInt(param.model.bg_color.replace("#", ""), 16) >
                          0xffffff / 2
                        ? "#3C3C3C"
                        : param.model.bg_color
                    }`,
                  }}
                >
                  {param.model.abbreviation}
                </label>
              </>
            ) : (
              <>
                {param.model.icon === true ? (
                  <i
                    style={{
                      margin: "0px",
                      fontWeight: "700",
                      fontSize: "25px",
                      textTransform: "uppercase",
                      color: `${param.model.top_color}`,
                    }}
                    class="fas fa-building"
                  ></i>
                ) : (
                  <img
                    alt="..."
                    // className="img-fluid rounded img-size-50 mr-3"
                    src={
                      param.model.company !== undefined &&
                      param.model.company !== null &&
                      param.model.company !== ""
                        ? Configs.API_URL_IMG + param.model.company.logo_path
                        : userdefault_img.imgs
                    }
                    style={{ width: "40px", height: "37.5px" }}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {param.model.word_2 !== null && param.model.word_2 !== "" ? (
          <>
            <div
              style={{
                width: "200px",
                wordBreak: "break-word",
                textAlign: "center",
                position: "relative",
                left: "117px",
                marginTop: "14.85px",
              }}
            >
              <label
                style={{
                  margin: "0px",
                  fontWeight: "900",
                  fontSize: "18px",
                  textTransform: "uppercase",
                  color: `${text_color}`,
                }}
              >
                {param.model.word_2}
              </label>
            </div>
            <div
              style={{
                width: "200px",
                wordBreak: "break-word",
                textAlign: "center",
                position: "relative",
                left: "117px",
                marginBottom: "14.85px",
              }}
            >
              <label
                style={{
                  margin: "0px",
                  fontWeight: "700",
                  fontSize: "23px",
                  textTransform: "uppercase",
                  color: `${text_color}`,
                }}
              >
                {param.model.word_1}
              </label>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                width: "200px",
                wordBreak: "break-word",
                textAlign: "center",
                position: "relative",
                left: "117px",
                margin: "29.25px 0px",
              }}
            >
              <label
                style={{
                  margin: "0px",
                  fontWeight: "700",
                  fontSize: "23px",
                  textTransform: "uppercase",
                  color: `${text_color}`,
                }}
              >
                {param.model.word_1}
              </label>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          width: "49px",
          height: "107px",
          borderRadius: "10px",
          background: "#ffffff",
          boxShadow: "0px 4px 30px rgba(0, 0, 0, .15)",
          marginLeft: "4px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {param.model.hid_add !== true ? (
          <>
            {param.model.dis_add !== true ? (
              <>
                <div
                  style={{
                    height: "33.3%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <a
                    // href={"#"}
                    id="btn_add"
                    // key={}
                    style={{ color: "#02AD88", cursor: "pointer" }}
                    onClick={() => param.toggleAdd()}
                  >
                    <i class="fas fa-plus"></i>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    height: "33.3%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <a
                    // href={"#"}
                    id="btn_add"
                    // key={}
                    style={{ color: "#343a40", opacity: "0.5" }}
                  >
                    <i class="fas fa-plus"></i>
                  </a>
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {param.model.hid_edit !== true ? (
          <>
            {param.model.dis_edit !== true ? (
              <>
                <div
                  style={{
                    height: "33.3%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <a
                    // href={}
                    id="btn_edit"
                    // key={}
                    style={{ color: "#0FA9FF", cursor: "pointer" }}
                    onClick={() => param.toggleEdit()}
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    height: "33.3%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <a
                    // href={}
                    id="btn_edit"
                    // key={}
                    style={{ color: "#343a40", opacity: "0.5" }}
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
        {param.model.hid_del !== true ? (
          <>
            {param.model.dis_del !== true ? (
              <>
                <div
                  style={{
                    height: "33.3%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <a
                    // href={}
                    id="btn_delete"
                    // key={}
                    style={{ color: "#FF415C", cursor: "pointer" }}
                    onClick={() => param.toggleDelete()}
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    height: "33.3%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <a
                    // href={}
                    id="btn_delete"
                    // key={}
                    style={{ color: "#343a40", opacity: "0.5" }}
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Company_Card;
