import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import LogoTTT from "../../../assets/img/IMG_Ticket_Management/logoTTT03.png";
import Select from "react-select";
import moment from "moment";
import Table_All_Project from "../../../component/component_ticket/table_all_project_of_project";
import img_Three_dot from "../../../assets/img/IMG_Ticket_Management/three_dot.png";
import {
  Avatar,
  Divider,
  Tooltip,
  Dropdown,
  Space,
  Checkbox,
  Drawer,
  ConfigProvider,
  DatePicker,
  Modal,
  Card,
  Row,
  Col,
} from "antd";
import {
  AntDesignOutlined,
  UserOutlined,
  DownOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { Button, Input } from "reactstrap";
import dayjs from "dayjs";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";

function Report_Ticket() {
  const history = useHistory();
  const { RangePicker } = DatePicker;

  const [modeReport, setModeReport] = useState({
    name_report: "",
    is_open: false,
    start_date: null,
    end_date: null,
    project: "all",
    role_id: null
  });

  const [projectList, setProjectList] = useState([
    {
      value: "all",
      label: "All",
    },
  ]);

  function CheckRolePage() {
    const id = JSON.parse(sessionStorage.getItem("user"));
    return axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/getByidChckeRole/${id.fup}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        oem_id: getOem(),
        com_id: getUser().com
      }
    })
      .then(function (response) {
        // console.log("response: ", response.data[0].iu_ps_id);
        if (response.data.length > 0) {
          setModeReport({
            ...modeReport,
            role_id: response.data[0].iu_ps_id
          });
          return true;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
        return false;
      });
  }

  function selectProjectCode() {
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/selectProjectCode`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: { 
        user_id: getUser().fup,
        oem_id: getOem(),
        com_id: getUser().com
      },
    })
      .then(function (response) {
        const dataW = [];

        dataW.push(JSON.parse(JSON.stringify(...projectList)));

        response.data.forEach((item, index) => {
          const formatData = {
            value: item.pr_id,
            label: `${item.pr_project_code} - ${item.pr_name}`,
          };

          dataW.push(formatData);
        });

        setProjectList(dataW);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  async function All_Report() {
    Swal.fire({
      title: "กำลังสร้างรายงาน",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const tempData = JSON.parse(JSON.stringify(modeReport));

    tempData.user_id = getUser().fup;
    tempData.company_id = getUser().com;
    tempData.oem_id = getOem();

    if (tempData.project == "all") {
      const list = [];
      projectList.forEach((item) => {
        if (item.value != "all") {
          list.push(item.value);
        }
      });
      tempData.project = list;
    } else {
      tempData.project = [tempData.project];
    }

    const axios_report = {
      method: "POST",
      url: "",
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: tempData,
      responseType: "blob",
    };

    if (modeReport.name_report == "Weekly Report") {
      axios_report.url = `${Configs.API_Ticket}/api/report/weekly_report`;
    }

    if (modeReport.name_report == "Weekly Dashboard") {
      axios_report.url = `${Configs.API_Ticket}/api/report/weekly_dashboard`;
    }

    if (modeReport.name_report == "Daily Report") {
      axios_report.url = `${Configs.API_Ticket}/api/report/daily_report`;
    }

    if (modeReport.name_report == "History Month Ticket") {
      axios_report.url = `${Configs.API_Ticket}/api/report/history_month_ticket`;
    }

    if (modeReport.name_report == "Monthly Dashboard") {
      axios_report.url = `${Configs.API_Ticket}/api/report/monthly_dashboard`;
    }

    if (modeReport.name_report == "Critical Report") {
      axios_report.url = `${Configs.API_Ticket}/api/report/critical_report`;
    }

    await axios(axios_report)
      .then(async (response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim()
          .slice(1, -1);

        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        Swal.close();
      })
      .catch(async (error) => {
        console.log(error);
        Swal.fire("ดาวน์โหลดไฟล์ไม่สำเร็จ");
      });
  }

  useEffect(() => {
    CheckRolePage().then(function (responseData) {
      if (responseData) {
        selectProjectCode();
      } else {
        // window.location = `/NonPermission`;
        history.push("/NonPermission", {
          module: "Project Management",
          menu: "Report",
        });
      }
    });
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-12 col-sm-6" style={{ display: "flex" }}>
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={28}
              />

              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginLeft: "10px",
                  lineHeight: "30px",
                }}
              >
                Report
                {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
              </h1>
            </div>

            <div className="col-12 col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">
                  {/* <li className="breadcrumb-item">
                  <a href="#">Home</a> */}
                  Home
                </li>
                <li className="breadcrumb-item active">Project Management</li>
                <li className="breadcrumb-item">Report</li>
              </ol>
            </div>
          </div>

          <div className="row" style={{ margin: "10px -7.5px" }}>
            <div
              className="col-12 col-md-6 col-xl-4"
              style={{ padding: "10px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#ffffff",
                  filter: "drop-shadow(0px 4px 30px #00000014)",
                  borderRadius: "10px",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={() => {
                  setModeReport({
                    ...modeReport,
                    name_report: "Weekly Report",
                    is_open: true,
                  });
                }}
              >
                <i
                  class="ri-file-chart-fill"
                  style={{ fontSize: "76px", color: "#282E51" }}
                />
                <div style={{ fontSize: "30px", fontWeight: "700" }}>
                  Weekly Report
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-4"
              style={{ padding: "10px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#ffffff",
                  filter: "drop-shadow(0px 4px 30px #00000014)",
                  borderRadius: "10px",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={() => {
                  setModeReport({
                    ...modeReport,
                    name_report: "Weekly Dashboard",
                    is_open: true,
                  });
                }}
              >
                <i
                  class="ri-file-chart-fill"
                  style={{ fontSize: "76px", color: "#282E51" }}
                />
                <div style={{ fontSize: "30px", fontWeight: "700" }}>
                  Weekly Dashboard
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-4"
              style={{ padding: "10px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#ffffff",
                  filter: "drop-shadow(0px 4px 30px #00000014)",
                  borderRadius: "10px",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={() => {
                  setModeReport({
                    ...modeReport,
                    name_report: "Daily Report",
                    is_open: true,
                  });
                }}
              >
                <i
                  class="ri-file-chart-fill"
                  style={{ fontSize: "76px", color: "#282E51" }}
                />
                <div style={{ fontSize: "30px", fontWeight: "700" }}>
                  Daily Report
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-4"
              style={{ padding: "10px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#ffffff",
                  filter: "drop-shadow(0px 4px 30px #00000014)",
                  borderRadius: "10px",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={() => {
                  setModeReport({
                    ...modeReport,
                    name_report: "History Month Ticket",
                    is_open: true,
                  });
                }}
              >
                <i
                  class="ri-file-chart-fill"
                  style={{ fontSize: "76px", color: "#282E51" }}
                />
                <div style={{ fontSize: "30px", fontWeight: "700" }}>
                  History Month Ticket
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-4"
              style={{ padding: "10px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#ffffff",
                  filter: "drop-shadow(0px 4px 30px #00000014)",
                  borderRadius: "10px",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={() => {
                  setModeReport({
                    ...modeReport,
                    name_report: "Monthly Dashboard",
                    is_open: true,
                  });
                }}
              >
                <i
                  class="ri-file-chart-fill"
                  style={{ fontSize: "76px", color: "#282E51" }}
                />
                <div style={{ fontSize: "30px", fontWeight: "700" }}>
                  Monthly Dashboard
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-4"
              style={{ padding: "10px" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundColor: "#ffffff",
                  filter: "drop-shadow(0px 4px 30px #00000014)",
                  borderRadius: "10px",
                  textAlign: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onClick={() => {
                  setModeReport({
                    ...modeReport,
                    name_report: "Critical Report",
                    is_open: true,
                  });
                }}
              >
                <i
                  class="ri-file-chart-fill"
                  style={{ fontSize: "76px", color: "#282E51" }}
                />
                <div style={{ fontSize: "30px", fontWeight: "700" }}>
                  Critical Report
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        centered
        closable={false}
        maskClosable={false}
        open={modeReport.is_open}
        width={345}
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ fontWeight: "500", fontSize: "30px" }}>
              {modeReport.name_report}
            </span>
          </div>
        }
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "5px" }}
            >
              <Button
                style={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  border: "solid 1px #D9D9D9",
                  borderRadius: "2px",
                }}
                onClick={() => {
                  setModeReport({
                    ...modeReport,
                    name_report: "",
                    is_open: false,
                    start_date: null,
                    end_date: null,
                    project: "all",
                  });
                }}
              >
                Cancel
              </Button>

              <Button
                style={{
                  backgroundColor: "#007BFF",
                  color: "#ffffff",
                  border: "solid 1px #007BFF",
                  borderRadius: "2px",
                }}
                onClick={async () => {
                  let warning_list = "";

                  if (modeReport.name_report.includes("Month")) {
                    if (!modeReport.start_date || !modeReport.end_date) {
                      warning_list +=
                        "กรุณาเลือกช่วงเดือนที่ต้องการออก Report<br/>";
                    }
                  } else {
                    if (!modeReport.start_date) {
                      if (modeReport.name_report.includes("Daily")) {
                        warning_list +=
                          "กรุณาเลือกวันที่ต้องการออก Report<br/>";
                      } else {
                        warning_list +=
                          "กรุณาเลือกวันที่ต้องการเริ่มต้นออก Report<br/>";
                      }
                    }

                    if (
                      !modeReport.end_date &&
                      !modeReport.name_report.includes("Daily")
                    ) {
                      warning_list +=
                        "กรุณาเลือกวันที่ต้องการสิ้นสุดออก Report<br/>";
                    }
                  }

                  if (warning_list != "") {
                    Swal.fire("Error", warning_list, "error");
                  } else {
                    setModeReport({
                      ...modeReport,
                      name_report: "",
                      is_open: false,
                      start_date: null,
                      end_date: null,
                      project: "all",
                    });
                    modeReport.is_open = false;

                    await All_Report();
                  }
                }}
              >
                Export Report
              </Button>
            </div>
          </>
        )}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {modeReport.name_report.includes("Weekly") ||
          modeReport.name_report.includes("Critical") ||
          modeReport.name_report.includes("Daily") ? (
            <div style={{ width: "100%", margin: "5px 0px" }}>
              <p
                style={{
                  margin: "0px 0px 5px 0px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {modeReport.name_report.includes("Weekly")
                  ? "Start Date"
                  : "Date"}
              </p>

              <DatePicker
                style={{ width: "100%", height: "36px" }}
                onChange={(date) => {
                  setModeReport({
                    ...modeReport,
                    start_date: date,
                  });
                }}
                value={
                  modeReport.start_date ? dayjs(modeReport.start_date) : null
                }
                disabledDate={(current) =>
                  modeReport.end_date ? modeReport.end_date < current : false
                }
                format={"DD/MM/YYYY"}
              />
            </div>
          ) : (
            <></>
          )}

          {modeReport.name_report.includes("Weekly") ||
          modeReport.name_report.includes("Critical") ? (
            <div style={{ width: "100%", margin: "5px 0px" }}>
              <p
                style={{
                  margin: "0px 0px 5px 0px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Finish Date
              </p>

              <DatePicker
                style={{ width: "100%", height: "36px" }}
                onChange={(date) => {
                  setModeReport({
                    ...modeReport,
                    end_date: date,
                  });
                }}
                value={modeReport.end_date ? dayjs(modeReport.end_date) : null}
                disabledDate={(current) =>
                  modeReport.start_date
                    ? modeReport.start_date > current
                    : false
                }
                format={"DD/MM/YYYY"}
              />
            </div>
          ) : (
            <></>
          )}

          {modeReport.name_report.includes("Month") ? (
            <div style={{ width: "100%", margin: "5px 0px" }}>
              <p
                style={{
                  margin: "0px 0px 5px 0px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Months Range
              </p>

              <RangePicker
                style={{ width: "100%", height: "36px" }}
                picker="month"
                onChange={(raw_date, tran_date) => {
                  setModeReport({
                    ...modeReport,
                    start_date: raw_date?.[0] || null,
                    end_date: raw_date?.[1] || null,
                  });
                }}
                value={
                  modeReport.start_date && modeReport.end_date
                    ? [dayjs(modeReport.start_date), dayjs(modeReport.end_date)]
                    : null
                }
                format={"MM/YYYY"}
              />
            </div>
          ) : (
            <></>
          )}

          <div style={{ width: "100%", margin: "5px 0px" }}>
            <p
              style={{
                margin: "0px 0px 5px 0px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Project
            </p>

            <Select
              options={projectList}
              value={projectList.find(
                (option) => option.value === modeReport.project
              )}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setModeReport({
                    ...modeReport,
                    project: selectedOption.value,
                  });
                } else {
                  setModeReport({
                    ...modeReport,
                    project: "",
                  });
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Report_Ticket;
