import React, { useEffect, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";

import Configs from "../../../../config";
import { getOem, getToken, getUser } from "../../../../Utils/Common";

import Wave_Type_1 from "./Wave_Type_1";

import Ocean_Background from "../../../../assets/img/Task_Work_Animate/Ocean_Background.png";
import Logo_PMRP from "../../../../assets/img/Task_Work_Animate/Logo_PMRP.png";

import Boat_1_1 from "../../../../assets/img/Task_Work_Animate/Boat_1_1.png";

// import Boat_2_1 from "../../../../assets/img/Task_Work_Animate/Boat_2_1.png";

// import Boat_3_1 from "../../../../assets/img/Task_Work_Animate/Boat_3_1.png";

// import Boat_4_1 from "../../../../assets/img/Task_Work_Animate/Boat_4_1.png";

import Shark_1 from "../../../../assets/img/Task_Work_Animate/Shark_1.png";
// import Shark_1_1 from "../../../../assets/img/Task_Work_Animate/Shark_1_1.png";
// import Shark_1_2 from "../../../../assets/img/Task_Work_Animate/Shark_1_2.png";
// import Shark_1_3 from "../../../../assets/img/Task_Work_Animate/Shark_1_3.png";

import Shark_2 from "../../../../assets/img/Task_Work_Animate/Shark_2.png";
// import Shark_2_1 from "../../../../assets/img/Task_Work_Animate/Shark_2_1.png";
// import Shark_2_2 from "../../../../assets/img/Task_Work_Animate/Shark_2_2.png";
// import Shark_2_3 from "../../../../assets/img/Task_Work_Animate/Shark_2_3.png";

import Shark_3 from "../../../../assets/img/Task_Work_Animate/Shark_3.png";
// import Shark_3_1 from "../../../../assets/img/Task_Work_Animate/Shark_3_1.png";
// import Shark_3_2 from "../../../../assets/img/Task_Work_Animate/Shark_3_2.png";
// import Shark_3_3 from "../../../../assets/img/Task_Work_Animate/Shark_3_3.png";

import { ReactComponent as Icon_Task } from "../../../../assets/img/Task_Work_Animate/Icon_Task.svg";
import { ReactComponent as Icon_Share } from "../../../../assets/img/Task_Work_Animate/Icon_Share.svg";
import { ReactComponent as Icon_Close } from "../../../../assets/img/Task_Work_Animate/Icon_Close.svg";

import { ReactComponent as Icon_Pass_1 } from "../../../../assets/img/Task_Work_Animate/Icon_Pass_1.svg";
import { ReactComponent as Icon_Pass_2 } from "../../../../assets/img/Task_Work_Animate/Icon_Pass_2.svg";
import { ReactComponent as Icon_Fail } from "../../../../assets/img/Task_Work_Animate/Icon_Fail.svg";
import { ReactComponent as Icon_Shark } from "../../../../assets/img/Task_Work_Animate/Icon_Shark.svg";

function Task_Work_Animate() {
  const [dateTimeData, setDateTimeData] = useState({
    startDate: 0,
    startMonth: 0,
    startYear: 0,
    endDate: 0,
    endMonth: 0,
    endYear: 0,
    dayOfWeek: 0,
  });

  const [headerNumberData, setHeaderNumberData] = useState({
    green_count: 0,
    yellow_count: 0,
    red_count: 0,
    shark_count: 0,
    score_count: 0,
  });

  const [taskData, setTaskData] = useState([]);

  const [boatData, setBoatData] = useState({
    boat_stage: 1, // 1, 2, 3, 4
    boat_status: "normal", // normal, attacked_1, attacked_2, attacked_3
  });

  const [sharkDataGroup, setSharkDataGroup] = useState({
    group_1: [],
    group_2: [],
  });

  // ----- Prevent Zoom ----- //
  function usePreventZoom(scrollCheck = true, keyboardCheck = true) {
    useEffect(() => {
      const handleKeydown = (e) => {
        if (
          keyboardCheck &&
          e.ctrlKey &&
          (e.keyCode == "61" ||
            e.keyCode == "107" ||
            e.keyCode == "173" ||
            e.keyCode == "109" ||
            e.keyCode == "187" ||
            e.keyCode == "189")
        ) {
          e.preventDefault();
        }
      };

      const handleWheel = (e) => {
        if (scrollCheck && e.ctrlKey) {
          e.preventDefault();
        }
      };

      document.addEventListener("keydown", handleKeydown);
      document.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        document.removeEventListener("keydown", handleKeydown);
        document.removeEventListener("wheel", handleWheel);
      };
    }, [scrollCheck, keyboardCheck]);
  }

  usePreventZoom();
  // ----- Prevent Zoom ----- //

  async function GetSharkData() {
    const tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      user: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/todolist/filterTaskShark",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        const temp_group_1 = [];
        const temp_group_2 = [];

        // for (let index = 0; index < response.data.data.length; index++) {
        for (const item of response.data.data) {
          const temp_shark_status =
            item.assign_to_status_id != "22bb6045-d8b6-44dd-8f2f-be7330c17aa9"
              ? "alive"
              : "dead"; // alive, dead

          const temp_shark_stage = item.finish_week
            ? moment(`${item.finish_year}-${item.finish_week}`, "YYYY-WW").diff(
                moment(`${item.year}-${item.week}`, "YYYY-WW"),
                "week"
              )
            : moment(
                `${moment(new Date()).isoWeekYear()}-${moment(new Date())
                  .isoWeekday(1)
                  .week()}`,
                "YYYY-WW"
              ).diff(moment(`${item.year}-${item.week}`, "YYYY-WW"), "week");

          const sharkData = {
            shark_id: item.id,
            shark_title: item.task,
            shark_status: temp_shark_status, // alive, dead
            shark_stage: temp_shark_stage + 1 > 3 ? 3 : temp_shark_stage + 1, // 1, 2, 3
            shark_injured: item.finish_type, // 1 = gun, 2 = knife,3 = harpoon
            shark_open_title: false,
          };

          if (temp_group_1.length == temp_group_2.length) {
            temp_group_1.push(sharkData);
          } else {
            temp_group_2.push(sharkData);
          }
        }

        setSharkDataGroup({
          group_1: temp_group_1,
          group_2: temp_group_2,
        });
      })
      .catch(function (error) {
        // console.log(error);
        // Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  useEffect(async () => {
    setDateTimeData({
      startDate: moment().startOf("week").add(1, "days").format("DD"),
      startMonth: moment().startOf("week").add(1, "days").format("MM"),
      startYear: moment().startOf("week").add(1, "days").format("YY"),
      endDate: moment().startOf("week").subtract(1, "days").format("DD"),
      endMonth: moment().startOf("week").subtract(1, "days").format("MM"),
      endYear: moment().startOf("week").subtract(1, "days").format("YY"),
      dayOfWeek: moment().day(),
    });

    await GetSharkData();
  }, []);

  return (
    <div
      className="main-background"
      style={{
        "--mainBackground": `url(${Ocean_Background})`,
      }}
    >
      {/* ----- Part Header ----- */}
      <div
        className="row"
        style={{
          margin: "0px",
          justifyContent: "space-between",
        }}
      >
        <div className="col-auto" style={{ padding: 0 }}>
          <div className="row" style={{ margin: "5px 10px 0px 10px" }}>
            <img
              src={Logo_PMRP}
              alt="Logo_PMRP"
              draggable={false}
              style={{ height: "65px", margin: "5px", userSelect: "none" }}
            />

            <div className="base-div-1" style={{ flex: "1", fontSize: "30px" }}>
              <div className="vertical-line-div" />
              PMRP TO DO LIST
            </div>
          </div>

          <div className="row" style={{ margin: "0px 10px" }}>
            <div
              className="base-div-1"
              style={{
                width: "100%",
                minHeight: "86px",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div style={{ fontSize: "22px" }}>
                {dateTimeData.startDate}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.startMonth}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.startYear} - {dateTimeData.endDate}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.endMonth}
                <span style={{ color: "#FFD027" }}>/</span>
                {dateTimeData.endYear}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 1
                      ? { backgroundColor: "#ECBF00" }
                      : {}
                  }
                >
                  จ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 2
                      ? { backgroundColor: "#FF00B7" }
                      : {}
                  }
                >
                  อ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 3
                      ? { backgroundColor: "#00BF63" }
                      : {}
                  }
                >
                  พ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 4
                      ? { backgroundColor: "#FF914D" }
                      : {}
                  }
                >
                  พฤ.
                </div>

                <div
                  className="day-text-div"
                  style={
                    dateTimeData.dayOfWeek == 5
                      ? { backgroundColor: "#38B6FF" }
                      : {}
                  }
                >
                  ศ.
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{ margin: "0px 10px" }}>
            <div className="base-div-2">
              <Icon_Pass_1 style={{ marginRight: "10px" }} /> X{" "}
              {new Intl.NumberFormat().format(headerNumberData.green_count)}
            </div>

            <div className="base-div-2">
              <Icon_Pass_2 style={{ marginRight: "10px" }} /> X{" "}
              {new Intl.NumberFormat().format(headerNumberData.yellow_count)}
            </div>

            <div className="base-div-2">
              <Icon_Fail style={{ marginRight: "10px" }} /> X{" "}
              {new Intl.NumberFormat().format(headerNumberData.red_count)}
            </div>
          </div>

          <div className="row" style={{ margin: "0px 10px" }}>
            <div className="base-div-2" style={{ fontSize: "32px" }}>
              <Icon_Shark style={{ marginRight: "10px" }} /> X{" "}
              {/* {new Intl.NumberFormat().format(headerNumberData.shark_count)} */}
              {new Intl.NumberFormat().format(
                sharkDataGroup.group_1.length + sharkDataGroup.group_2.length
              )}
            </div>

            <div className="base-div-2" style={{ flex: "1", fontSize: "32px" }}>
              Score :
              <span style={{ marginLeft: "5px", color: "#00FF58" }}>
                {new Intl.NumberFormat().format(headerNumberData.score_count)}
              </span>
            </div>
          </div>
        </div>

        <div className="col-auto" style={{ padding: 0 }}>
          <div className="row" style={{ margin: "5px 10px" }}>
            <button type="button" className="base-button">
              <Icon_Task style={{ marginRight: "10px" }} /> Task Detail (
              <span style={{ color: "#3579F6" }}>
                {/* {new Intl.NumberFormat().format(taskData.length)} */}
                {new Intl.NumberFormat().format(
                  sharkDataGroup.group_1.length + sharkDataGroup.group_2.length
                )}
              </span>
              )
            </button>

            <button type="button" className="base-button">
              <Icon_Share />
            </button>

            <button
              type="button"
              className="base-button"
              onClick={() => {
                window.location.href = "/Human_Resource/to_do_list/task_work";
              }}
            >
              <Icon_Close />
            </button>
          </div>
        </div>
      </div>
      {/* ----- Part Header ----- */}

      {/* ----- Part Ocean ----- */}
      <Wave_Type_1
        gradient_id="wave-gradient-1"
        gradient_color_1="#05697A"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.8"
        use_y="2"
        use_class="waves-parallax-style-1"
      />

      <Wave_Type_1
        gradient_id="wave-gradient-2"
        gradient_color_1="#0793AB"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.7"
        use_y="4"
        use_class="waves-parallax-style-2"
      />

      {/* <div className="setting-shark">
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div
        className="setting-shark-1-type-1"
        style={{
          "--layer-width": "min(6vw, 6vh)",
          "--layer-height": "min(4vw, 4vh)",
        }}
      >
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div
        className="setting-shark-1-type-2"
        style={{
          "--layer-width": "min(6vw, 6vh)",
          "--layer-height": "min(4vw, 4vh)",
          "--delay-shark": "2s",
        }}
      >
        <img src={Shark_1} alt="" />
      </div> */}

      {sharkDataGroup.group_2.map((item, index) => {
        if (index % 2 == 0) {
          return (
            <div
              id={`shark_group_2_${index}`}
              className={
                item.shark_stage == 1
                  ? "setting-shark-1-type-1"
                  : item.shark_stage == 2
                  ? "setting-shark-2-type-1"
                  : "setting-shark-3-type-1"
              }
              style={{
                "--layer-width": "min(6vw, 6vh)",
                "--layer-height": "min(4vw, 4vh)",
                "--delay-shark": `${(index + 1) * 3}s`,
              }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_2)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_2: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_2_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_2_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_2_${index}`}
                className="text-tooltip-1"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#FF415C" }}
                />
                {item.shark_title}
              </div>

              <img
                src={
                  item.shark_stage == 1
                    ? Shark_1
                    : item.shark_stage == 2
                    ? Shark_2
                    : Shark_3
                }
                alt=""
                draggable={false}
              />
            </div>
          );
        } else {
          return (
            <div
              id={`shark_group_2_${index}`}
              className={
                item.shark_stage == 1
                  ? "setting-shark-1-type-2"
                  : item.shark_stage == 2
                  ? "setting-shark-2-type-2"
                  : "setting-shark-3-type-2"
              }
              style={{
                "--layer-width": "min(6vw, 6vh)",
                "--layer-height": "min(4vw, 4vh)",
                "--delay-shark": `${(index + 1) * 3}s`,
              }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_2)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_2: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_2_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_2_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_2_${index}`}
                className="text-tooltip-2"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#E9B500" }}
                />
                {item.shark_title}
              </div>

              <img
                src={
                  item.shark_stage == 1
                    ? Shark_1
                    : item.shark_stage == 2
                    ? Shark_2
                    : Shark_3
                }
                alt=""
                draggable={false}
              />
            </div>
          );
        }
      })}

      <Wave_Type_1
        gradient_id="wave-gradient-3"
        gradient_color_1="#09BDDC"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.6"
        use_y="6"
        use_class="waves-parallax-style-3"
      />

      <div className="setting-boat-1">
        <img src={Boat_1_1} alt="" draggable={false} />
      </div>

      <Wave_Type_1
        gradient_id="wave-gradient-4"
        gradient_color_1="#23D6F6"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.5"
        use_y="8"
        use_class="waves-parallax-style-4"
      />

      {/* <div className="setting-shark">
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div className="setting-shark-1-type-1" style={{ "--delay-shark": "4s" }}>
        <div className="text-tooltip-1">
          <div
            className="status-tooltip"
            style={{ backgroundColor: "#FF415C" }}
          />
          TEST
        </div>
        <img src={Shark_1} alt="" />
      </div> */}

      {/* <div className="setting-shark-1-type-2" style={{ "--delay-shark": "6s" }}>
        <div className="text-tooltip-2">
          <div
            className="status-tooltip"
            style={{ backgroundColor: "#E9B500" }}
          />
          TEST
        </div>
        <img src={Shark_1} alt="" />
      </div> */}

      {sharkDataGroup.group_1.map((item, index) => {
        if (index % 2 == 0) {
          return (
            <div
              id={`shark_group_1_${index}`}
              className={
                item.shark_stage == 1
                  ? "setting-shark-1-type-1"
                  : item.shark_stage == 2
                  ? "setting-shark-2-type-1"
                  : "setting-shark-3-type-1"
              }
              style={{ "--delay-shark": `${(index + 1) * 2}s` }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_1)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_1: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_1_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_1_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_1_${index}`}
                className="text-tooltip-1"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#FF415C" }}
                />
                {item.shark_title}
              </div>

              <img
                src={
                  item.shark_stage == 1
                    ? Shark_1
                    : item.shark_stage == 2
                    ? Shark_2
                    : Shark_3
                }
                alt=""
                draggable={false}
              />
            </div>
          );
        } else {
          return (
            <div
              id={`shark_group_1_${index}`}
              className={
                item.shark_stage == 1
                  ? "setting-shark-1-type-2"
                  : item.shark_stage == 2
                  ? "setting-shark-2-type-2"
                  : "setting-shark-3-type-2"
              }
              style={{ "--delay-shark": `${(index + 1) * 2}s` }}
              onClick={() => {
                const temp_data = JSON.parse(
                  JSON.stringify(sharkDataGroup.group_1)
                );

                temp_data[index].shark_open_title =
                  !temp_data[index].shark_open_title;

                setSharkDataGroup({
                  ...sharkDataGroup,
                  group_1: temp_data,
                });

                const shark_element = document.getElementById(
                  `shark_group_1_${index}`
                );

                const tooltip_shark_element = document.getElementById(
                  `tooltip_shark_group_1_${index}`
                );

                if (temp_data[index].shark_open_title) {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 0.3;
                    item.play();
                  });
                } else {
                  shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });

                  tooltip_shark_element.getAnimations().forEach((item) => {
                    item.playbackRate = 1;
                    item.play();
                  });
                }
              }}
            >
              <div
                id={`tooltip_shark_group_1_${index}`}
                className="text-tooltip-2"
                style={{
                  visibility: item.shark_open_title ? "visible" : "hidden",
                }}
              >
                <div
                  className="status-tooltip"
                  style={{ backgroundColor: "#E9B500" }}
                />
                {item.shark_title}
              </div>

              <img
                src={
                  item.shark_stage == 1
                    ? Shark_1
                    : item.shark_stage == 2
                    ? Shark_2
                    : Shark_3
                }
                alt=""
                draggable={false}
              />
            </div>
          );
        }
      })}

      <Wave_Type_1
        gradient_id="wave-gradient-5"
        gradient_color_1="#54DFF8"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.4"
        use_y="10"
        use_class="waves-parallax-style-5"
      />

      <Wave_Type_1
        gradient_id="wave-gradient-6"
        gradient_color_1="#85E8FA"
        gradient_color_2="#05697A"
        gradient_opacity_1="0.3"
        use_y="12"
        use_class="waves-parallax-style-6"
      />
      {/* ----- Part Ocean ----- */}
    </div>
  );
}

export default Task_Work_Animate;
