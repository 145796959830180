import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature,getAcademy } from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import moment, { min } from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import NumberFormat from "react-number-format";
import Sidebar from "../../component/Sidebar";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import sortJsonArray from "sort-json-array";
import FactoryWorkHour from "../WarehousMGT/Factory_work_hours";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import jsPDF from "jspdf";
import {
    Progress,
    Tooltip,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from "reactstrap";
import QRCode2 from "qrcode";
import { useParams } from "react-router";
var oslnos;
var oem_names;
var truetag;
function OrderSummaryReport_add(mode) {
    const { id } = useParams();
    const [page_mode, setPage_mode] = useState([]);
    const [feature, setfeature] = useState([]);
    const [openform, setopenform] = useState({
        id:"",
        no:"",
        actual:"",
    });
    const [OSL_NO ,setOSL_NO] = useState(null);
    const [work_hours, setWork_hours] = useState({
        company_id: getUser().com,
        day_normal: "",
        day_ot: "",
        night_normal: "",
        night_ot: "",
    });
    const [filler_work_hours, setFiller_work_hours] = useState({
        company_id: getUser().com,

    });
    
    const [calender_data, setcalender_data] = useState({
        start_date: new Date(),
        end_date: new Date(),
    });
    const [day_off, setDay_off] = useState({
       // sum_day_off: "",
    });

    const [date_calendar, setdate_calendar] = useState([]);

    const [day_off_detail, setDay_off_detail] = useState([]);


    const [osl_data, setosl_data] = useState({
        loading_date: new Date(),
        confirm_date: moment(new Date()).format("DD/MM/YYYY"),
    });
    const [color, setcolor] = useState({
        color: "red",
    })
    const [mode_icon, setmode_icon] = useState("fab fa-readme");

    const [order, setOrder] = useState([]);
    /*  const [product_data, setProduct_data] = useState([]); */
    const [lines, setLines] = useState([]);
    const [company, setcompany] = useState([]);

    const [section, setSection] = useState([]);
    const [filter_section, setFilter_section] = useState({
        oem_id: getOem(),
    });
    /*   const [process, setProcess] = useState([]);
      const [filter_process, setFilter_process] = useState({
        section_id: "",
      }); */
    const [process, setprocess] = useState([]);
    const [process_select, setprocess_select] = useState([]);
    const [order_product_select, setorder_product_select] = useState([]);
    const [filler_process, setFiller_process] = useState({
        oem_id: getOem(),
        section_id: "",
        section_name: "",
        process_name: "",
    });
    const [machine, setmachine] = useState([]);
    const [machine_select, setmachine_select] = useState([]);
    const [filter_machine, setFilter_machine] = useState({
        process_id: "",
    });
    const [order_product, setOrder_product] = useState([]);
    const [filler_order_product, setFiller_order_product] = useState({
        order_no: "",
        oem_id:getOem(),
    });
    
    const [OSL_table, setOSL_table] = useState([]);

    const [machine_process, setmacine_process] = useState([]);
    const [numberline, setnumberline] = useState({
        number_line: "1",
    });

    const [all_td_process_actual,setall_td_process_actual] = useState([]); 
    const GetWork_hours = async() => {

        axios({
                method: "post",
                url: Configs.API_URL + "/factoryWorkHours/filter",
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
                data: filler_work_hours
            })
            .then(function(response) {
                //console.log(response.data);
                setWork_hours(response.data);

            })
            .catch(function(error) {
                console.log(error);
            });
    };

    function GetDay_off(){
        const temp_date ={
          start_date:formatDate(calender_data.start_date),
          end_date: formatDate(calender_data.end_date),
          company_id:getUser().com
        }
        //console.log("temp_date",temp_date);
        axios({
            method: "post",
            url: Configs.API_URL + "/factoryWorkHours/GetWorkHour",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_date
          })
          .then(function (response) {
           // console.log(response.data);
           setDay_off(response.data);
           const calendarSort = response.data.date_work.sort((a,b)=> a.id - b.id);
           setdate_calendar(calendarSort);
          
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      const GetDay_off_detail = async () => {
        const temp_date ={
          start_date:formatDate(calender_data.start_date),
          end_date: formatDate(calender_data.end_date),
        }
        //console.log(temp_date);
        axios({
          method: "post",
          url: Configs.API_URL + "/orderSummary/getDay_off_detail",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_date,
        })
          .then(function (response) {
           //\\ console.log(response.data);
           setDay_off_detail(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      };
const[have_process,setHave_process] = useState([]);

   //console.log(have_process)
    const GetOSL_table = async() => {
        var tempIn_process = [];
        let process_select_temp1 = process_select.filter((element) => {
            return (element.isSelect === true);
        })
        for (var index = 0; index < process_select_temp1.length; index++) {
            const temp_process = {

                process_id: process_select_temp1[index].id,
            }

            tempIn_process.push(temp_process)
        }
        var tempIn_order = [];
        let order_product_select_temp1 = order_product_select.filter((element) => {
            return (element.isSelect === true);
        })
       // console.log(order_product_select_temp1);
        //  setorder_product_select_temp(order_product_select_temp1);

        for (var index = 0; index < order_product_select_temp1.length; index++) {
            const temp_order = {

                order_id: order_product_select_temp1[index].id,
            }

            tempIn_order.push(temp_order)
        }
        var tempReq = [];
        tempReq.push(tempIn_order)
        tempReq.push(tempIn_process)

       // console.log(tempReq);
        axios({
                method: "post",
                url: Configs.API_URL + "/orderSummary/GetOSL_table",
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
                data: tempReq,
            })
            .then(function(response) {
               //console.log(response.data);
               var have_process = [];
               for(let t of response.data) {
               for(let e of t.process) {
                   let check = have_process.filter((e2)=>{return e2.process_id === e.process_id})
                let temp = {
                    process_id:e.process_id,
                };
                if(check.length === 0) {
                   
                    have_process.push(temp);
                   // console.log("have_process",have_process)
                }
                
               }
            }

            setHave_process(have_process);
                
                
                const OSL_table2 = response.data;
                /* for(let osl_Data of OSL_table2){
                    let actual_amount;
                    let sum_n;
                    if(parseInt(osl_Data.suggestion) === 1){
                        sum_n = osl_Data.n1;
                        if((sum_n - osl_Data.in_hand)>0){
                            actual_amount = sum_n - osl_Data.in_hand;
                        }else{
                            actual_amount = 0;
                        }
                    }else if(parseInt(osl_Data.suggestion) === 2){
                        sum_n = osl_Data.n1 + osl_Data.n2;
                            if((sum_n - osl_Data.in_hand)>0){
                                actual_amount = sum_n - osl_Data.in_hand;
                            }else{
                                actual_amount = 0;
                            }
                            
                      

                    }else if(parseInt(osl_Data.suggestion) === 3){
                        sum_n = osl_Data.n1 + osl_Data.n2 + osl_Data.n3;
                        if((sum_n - osl_Data.in_hand)>0){
                            actual_amount = sum_n - osl_Data.in_hand;
                        }else{
                            actual_amount = 0;
                        }
                    }else{
                        sum_n = osl_Data.n1 + osl_Data.n2 + osl_Data.n3 + osl_Data.n4;
                        if((sum_n - osl_Data.in_hand)>0){
                            actual_amount = sum_n - osl_Data.in_hand;
                        }else{
                            actual_amount = 0;
                        }
                    }

                    osl_Data.actual_amount = actual_amount;
                    
                } */
              //  console.log("adsasdsadasdsa",OSL_table2);
                const OSL_tableSort = OSL_table2.sort((a, b) => b.actual_amount - a.actual_amount); 
                setOSL_table(OSL_table2);


            })
            .catch(function(error) {
                console.log(error);
            });
    };


    const GetMachine_process = async() => {
        let temp = [];
        let process_select_temp1 = process_select.filter((element) => {
            return (element.isSelect === true);
        })
        for (var index = 0; index < process_select_temp1.length; index++) {
            const temp_process = {

                process_id: process_select_temp1[index].id,
                oem_id: getOem(),
            }

            temp.push(temp_process);
        }
        //console.log("temp", temp)
            //console.log("test")
        axios({
                method: "post",
                url: Configs.API_URL + "/orderSummary/GetMachineProcess",
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
                data: temp
            })
            .then(function(response) {
               // console.log(response.data);
                setmacine_process(response.data);

            })
            .catch(function(error) {
                console.log(error);
            });
    };

    const GetMachine = async () => {
        let temp = {
            oem_id: getOem(),
            id: "",
            machine_name: ""
}
        if(mode.mode === "add"){
            axios({
                method: "post",
                url: Configs.API_URL + "/factoryParameter/machine/filter",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(function (response) {
                    const temp_machine = [];
                    for (let index = 0; index < response.data.length; index++) {
                        const element = {};
                        element["id"] = response.data[index].id;
                        element["machine_name"] = response.data[index].machine_name;
                        element["isSelect"] = false;
                        temp_machine.push(element);
                    }
                    setmachine(temp_machine);
                 // setmachine(response.data);
        
            
                })
                .catch(function (error) {
                  console.log(error);
                });
           
        } else {
            axios({
                method: "post",
                url: Configs.API_URL + "/factoryParameter/machine/filter",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(function(response) {
                    axios({
                            method: "get",
                            url: Configs.API_URL + "/orderSummary/findById?id=" + id,
                            headers: {
                                Authorization: getToken(),
                                "X-TTT": Configs.API_TTT,
                                "Content-Type": "application/json",
                            },
                        })
                        .then(function(response2) {
                  
                            const temp_machine = [];
                            for (let index = 0; index < response.data.length; index++) {
                                const element = {};
                                element["id"] = response.data[index].id;
                                element["machine_name"] = response.data[index].machine_name;
                                element["isSelect"] = false;
                                for (let k = 0; k < response2.data.partno_list.machine.length; k++) {

                                    if (response.data[index].id === response2.data.partno_list.machine[k].id) {
                                        //console.log(response2.data.process_id[k].process_id);
                                        element["isSelect"] = true;
                                    }

                                }
                                temp_machine.push(element)
                            }
                            var mac_use = temp_machine.filter((e)=>{return e.isSelect === true});
                            setmachine_select(mac_use);
                            setmachine(temp_machine);

                        })
                        .catch(function(error) {
                            console.log(error);
                        });

                })
                .catch(function(error) {
                    console.log(error);
                });
        }
        
      };


    async function SetDate_and_GetDayoff_start(date){
       setcalender_data({ ...calender_data, start_date: date})
       calender_data.start_date= date;
       GetDay_off();
      }
       async function SetDate_and_GetDayoff_end(date){
       setcalender_data({ ...calender_data, end_date: date})
       calender_data.end_date= date;
       GetDay_off();
      }
    // GetDay_off();
 

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }


    const GetProduct_order = async() => {

        // console.log(filler_order_product);
        if (mode.mode == "add") {
            axios({
                    method: "post",
                    url: Configs.API_URL + "/productPlaning/orderProduction/filter",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: filler_order_product,
                })
                .then(function(response) {
                    //console.log(response.data);
                    setOrder_product(response.data);



                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {

            axios({
                    method: "post",
                    url: Configs.API_URL + "/productPlaning/orderProduction/filter",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: filler_order_product,
                })
                .then(function(response) {
                    setOrder_product(response.data);
                    axios({
                            method: "get",
                            url: Configs.API_URL + "/orderSummary/findById?id=" + id,
                            headers: {
                                Authorization: getToken(),
                                "X-TTT": Configs.API_TTT,
                                "Content-Type": "application/json",
                            },
                        })
                        .then(function(response2) {
                            oslnos =response2.data.oslno;
                            const temp_order = [];
                            for (let index = 0; index < response.data.length; index++) {
                                const element = {};
                                element["id"] = response.data[index].id;

                                for (let k = 0; k < response2.data.partno_list.order.length; k++) {
                                    // console.log(response2.data.partno_list.order.id);
                                    if (response.data[index].id === response2.data.partno_list.order[k].id) {
                                        //console.log(response2.data.partno_list.order[k].id); 
                                        element["isSelect"] = true;
                                    }

                                }
                                temp_order.push(element)
                            }
                            //console.log(temp_order);
                            setorder_product_select(temp_order);

                        })
                        .catch(function(error) {
                            console.log(error);
                        });

                })
                .catch(function(error) {
                    console.log(error);
                });



        }
    };
     const GetSection = async () => {
       axios({
         method: "post",
         url: Configs.API_URL + "/orderSummary/GetSection",
         headers: {
           Authorization: getToken(),
           "X-TTT": Configs.API_TTT,
           "Content-Type": "application/json",
         },
         data: filter_section,
       })
         .then(function (response) {
          // console.log(response.data);
           setSection(response.data);
         })
         .catch(function (error) {
           console.log(error);
         });
     };
   
    const GetProcess = async() => {
        if (mode.mode == "add") {
            axios({
                    method: "post",
                    url: Configs.API_URL + "/factoryParameter/process/filter",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: filler_process,
                })
                .then(function(response) {
                    /*   console.log(response.data); */
                    setprocess(response.data);

                    const temp_process = [];
                    for (let index = 0; index < response.data.length; index++) {
                        const element = {};
                        element["id"] = response.data[index].id;
                        element["process_name"] = response.data[index].process_name;
                        element["section_id"] = response.data[index].section_id;
                        element["isSelect"] = false;
                        temp_process.push(element);
                    }
                    setprocess_select(temp_process);
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else {
            axios({
                    method: "post",
                    url: Configs.API_URL + "/factoryParameter/process/filter",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: filler_process,
                })
                .then(function(response) {
                    axios({
                            method: "get",
                            url: Configs.API_URL + "/orderSummary/findById?id=" + id,
                            headers: {
                                Authorization: getToken(),
                                "X-TTT": Configs.API_TTT,
                                "Content-Type": "application/json",
                            },
                        })
                        .then(function(response2) {
                            oslnos =response2.data.oslno;
                            const temp_process = [];
                            for (let index = 0; index < response.data.length; index++) {
                                const element = {};
                                element["id"] = response.data[index].id;
                                element["process_name"] = response.data[index].process_name;
                                element["section_id"] = response.data[index].section_id;
                                for (let k = 0; k < response2.data.process_id.length; k++) {

                                    if (response.data[index].id === response2.data.process_id[k].process_id) {
                                        //console.log(response2.data.process_id[k].process_id);
                                        element["isSelect"] = true;
                                    }

                                }
                                temp_process.push(element)
                            }
                            setprocess_select(temp_process);

                        })
                        .catch(function(error) {
                            console.log(error);
                        });

                })
                .catch(function(error) {
                    console.log(error);
                });
        }
    };



    function GetCom(params) {
        axios({
                method: "get",
                url: Configs.API_URL + "/company/findById?id=" + getUser().com,
                headers: {
                    Authorization: getToken(),
                    "Content-Type": "application/json",
                    "X-TTT": Configs.API_TTT,
                },
            })
            .then(function(response) {
                /*  console.log(response.data); */
                setcompany(response.data);
            })
            .catch(function(error) {
                console.log(error);
            });
    }



    function GetOrder(params) {
        axios({
                method: "get",
                url: Configs.API_URL + "/orderSummary/GetLastOrder",
                headers: {
                    Authorization: getToken(),
                    "Content-Type": "application/json",
                    "X-TTT": Configs.API_TTT,
                },
            })
            .then(function(response) {
                //console.log(response.data);
                setOrder(response.data);
            })
            .catch(function(error) {
                console.log(error);
            });
    }


    const [test , settest] = useState([]);
    const GetOSL_view = async() => {

        if (mode.mode == "read" || mode.mode == "edit") {
            axios({
                    method: "get",
                    url: Configs.API_URL + "/orderSummary/findById?id=" + id,
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                })
                .then(function(response) {
                    let tempData = [];
                    let v = 0;
                    response.data.partno_list.product_plan.sort((a,b) => (moment(a.time_in) - moment(b.time_in)));
                    console.log(response.data.partno_list.product_plan)
                    setall_td_process_actual(response.data.partno_list.product_plan);
                    for(let i of response.data.partno_list.part_no){
                       // console.log(response.data.partno_list.part_no[v].start_date);
                      //  console.log(response.data.partno_list.part_no[v].end_date);
                        tempData.push(i);
                        v++;
                    }
                    //console.log("test view data ", response.data.partno_list.part_no);
                    oslnos =response.data.oslno;
                    oem_names=response.data.oem_name;
                    //console.log(response.data);
                    setOSL_NO(response.data.oslno);
                    //console.log(new Date("2021-09-01T02:14:49.000Z"));
                    //set table
                    //setOSL_temp(response.data.partno_list);
                    //console.log("test view data ", response.data.partno_list.part_no);
                   // console.log("test view data ", response.data.partno_list.part_no[0].start_date,response.data.partno_list.part_no[0].end_date);
                    //console.log("test view data2 ", tempData);
                    //setOSL_table(tempData);
                    settest(tempData);
                    setOSL_table([]);
                    setOSL_table(tempData);
                    //set เวลาเครื่องจักร
                    //    if(mode.mode == "edit"){

                    //  }else{
                        //setmachine_select(response.data.partno_list.machine);
                        settest(response.data.partno_list.part_no);
                    setDay_off({
                        total_day:response.data.manpower.total_day,
                        total_hour:response.data.manpower.total_working_hours_available,
                    })
                    setnumberline({
                        number_line: response.data.manpower.number_of_line,
                    });
                    setWork_hours({
                        day_normal: response.data.manpower.normal_day,
                        day_ot: response.data.manpower.ot_day,
                        night_normal: response.data.manpower.normal_night,
                        night_ot: response.data.manpower.ot_night,
                    });
                    // }
                    //set วันที่
                    setcalender_data({
                        start_date: new Date(response.data.start_plan),
                        end_date: new Date(response.data.end_plan),
                    });
//console.log(response.data);
                    //set process
                    /*  const temp_process = [];
          console.log(process_select);
          for (let index = 0; index < process_select.length; index++) {
            const element = {};
            element["id"] = process_select[index].id;
            element["process_name"] = process_select[index].process_name;
      
            for (let k = 0; k < response.data.process_id.length; k++) {
              
              if (process_select[index].id === response.data.process_id[k].process_id) {
        
                element["isSelect"] = true;
              }
            
            }
            temp_process.push(element)
          }
          setprocess_select(temp_process); */


GetDay_off();
const temp_date ={
    start_date:formatDate(new Date(response.data.start_plan)),
    end_date: formatDate(new Date(response.data.end_plan)),
    company_id:getUser().com
  }
  //console.log("temp_date",temp_date);
  axios({
      method: "post",
      url: Configs.API_URL + "/factoryWorkHours/GetWorkHour",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp_date
    })
    .then(function (response) {
      //console.log(response.data);
     setDay_off(response.data);
     const calendarSort = response.data.date_work.sort((a,b)=> a.id - b.id);
     setdate_calendar(calendarSort);
    
    })
    .catch(function (error) {
      console.log(error);
    });

                })
                .catch(function(error) {
                    console.log(error);
                });
        }

    }

//console.log(test)

    useEffect(() => {
        var feature_data = JSON.parse(getFeature()).filter((element)=>{ 
            return (element.menu === "Order Summary Report");
          })
          setfeature(feature_data);
        if (mode.mode === "read") {
            setPage_mode("View");
        } else if (mode.mode === "edit") {
            setPage_mode("Edit");
        } else {
            setPage_mode("Add");
        }
    }, []);

    useEffect(() => {
        GetCom();
        GetWork_hours();
        //GetProductionLine();
        //GetSection();
        //GetLines();
        if(mode.mode === "add"){
            GetDay_off();
        }
        GetSection();
        GetMachine();
        GetProcess();
        GetOrder();
        GetProduct_order();
        /*   setcalender_data({
            start_date: new Date(),
            end_date: new Date(), 
          }); */
          //GetDay_off();
        GetOSL_view();
    }, []);

    const CustomInput = ({ value, onClick }) => ( 
      
        <
        div className = "input-group" >
        < input type = "text"
        className = "form-control float-left"
        onClick = { onClick }
        value = { value }
        /> <div className = "input-group-prepend" >
        <span className = "input-group-text" >
        <i className = "far fa-calendar-alt" />
        </span>

        { /*  <label>test</label> */ } 
        </div> 
        </div>
     

    )

    const [confirmBut, setconfirmBut] = useState("Confirm");

    function confirmButton() {
        if (confirmBut === "Confirm") {
            setconfirmBut("Print");
        } else if (confirmBut === "Print") {
            OSL_Report();
        }
    }

    function grantButton(params) {
        var err_message = "";
        console.log(err_max);
        for(var e =0; e < err_max.length;e++){
            if(err_max[e].machine === "-"){
              err_message += "<br/>"+err_max[e].product_no+" ไม่มีเครื่องที่จะลง";
            }else{
              err_message += "<br/>"+err_max[e].product_no+" เกินที่ "+err_max[e].machine;
            }
          
        }
       // Swal.fire("Error", err_message, "error");
          Swal.fire("Warning", err_message, "warning");
       /*  Swal.fire("Warning", "% Remain is zero or nagative!!!", "warning"); */
    }
    function setform(no){
        setopenform({...openform,no:no});
        //console.log(no)
    }
    function set_actual_time(){
        //console.log(openform.actual); 
        const tempData = OSL_table;
        var index = tempData.findIndex((element)=>element.product_no === openform.no)
        tempData[index].actual_time = openform.actual;
        openform.actual = "";
      //  console.log(tempData[index]);
       setOSL_table([]);
        //setOSL_table(tempData);
        //setOSL_table(tempData);
        Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 300,
            onOpen: () => {
              Swal.showLoading();
            },
        }).then((result) => {
             setOSL_table(tempData);
            /* Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
               // setOSL_table(tempData);
            }) */
        })
    }

    function actual_update (e) {
         // console.log(e.target.value); 
  
       // approve_list[e.target.name].nmo = e.target.value;
        var temp_osl = OSL_table;
      
         temp_osl[e.target.name].actual_time =  Number(e.target.value.replace(/,/g, "")); 
         rowsData[e.target.name].actual_time =  (
            <NumberFormat
            className="form-control"
            value={e.target.value || ""}
            required
            name={e.target.name}
            //thousandSeparator={true}
            onChange={actual_update.bind(this)}
          />
        );
   
         // console.log
         setOSL_table(temp_osl);
        
       }
      
    const rowsData = [];
    var sum_hours_cycle =0;
    for (var index = 0; index < OSL_table.length; index++) {
        const rowItem = {};
        rowItem["no"] = index + 1;
        rowItem["product_no"] = OSL_table[index].product_no;
        rowItem["product_name"] = OSL_table[index].product_name;
        rowItem["amount"] = OSL_table[index].amount;
        rowItem["cycle_time"] = OSL_table[index].cycle_time;
        rowItem["tt_work_hours"] = ((OSL_table[index].amount * OSL_table[index].cycle_time) * 0.000277).toFixed(2) + " ≈ " + Math.ceil((OSL_table[index].amount * OSL_table[index].cycle_time) * 0.000277) + " hr";
        sum_hours_cycle += Math.ceil((OSL_table[index].amount * OSL_table[index].cycle_time) * 0.000277);
        rowItem["start_date"] = OSL_table[index].start_date || "-";
        rowItem["end_date"] = OSL_table[index].end_date || "-";
        rowItem["amount"] = OSL_table[index].amount;
        rowItem["in_hand"] = OSL_table[index].in_hand;
        //rowItem["actual_amount"] = OSL_table[index].actual_amount;
        var actual_time = "";
        var actual_start = "";
        var actual_end = "";
        var actual_time_Check = all_td_process_actual.filter((e)=>{return e.product_id === OSL_table[index].product_id && e.time_out});
       // console.log(actual_time_Check)
        if(actual_time_Check.length > 0){
   
                
               
                let start_date_ac = actual_time_Check.sort((a,b)=> moment(a.time_in) - moment(b.time_in));
                //console.log(start_date_ac)
                let end_date_ac = actual_time_Check.sort((a,b)=> moment(b.time_out) - moment(a.time_out));
               // console.log(end_date_ac)
               actual_start = start_date_ac[0].time_in || "";
                actual_end = end_date_ac[0].time_out || "";
               
     
           

          
        }
        if(actual_time === ""){
            rowItem["actual_time"] = "";
        }else{
            rowItem["actual_time"] = actual_time + " hr";
        }
            if(actual_start !== ""){
                rowItem["actual_start"] = moment(actual_start).format('DD/MM/YYYY HH:mm:ss');
            }else{
                rowItem["actual_start"] = "";
            }
            if(actual_end !== ""){
                rowItem["actual_end"] = moment(actual_end).format('DD/MM/YYYY HH:mm:ss');
            }else{
                rowItem["actual_end"] = "";
            }
        /* rowItem["actual_time"] = (
            <NumberFormat
            className="form-control"
            value={OSL_table[index].actual_time || ""}
            //required
            allowNegative={false}
            decimalScale={0}
            name={index}
            disabled = {mode.mode === "read" ? true : false }
            //thousandSeparator={true}
            onChange={actual_update.bind(this)}
          />
        ); */
     


        rowsData.push(rowItem);
    }
  
    var sum_day_off = parseInt(day_off);


    var total_day = Math.round((calender_data.end_date - calender_data.start_date) / (1000 * 60 * 60 * 24) + 1);
   // console.log("total_day",total_day);
    var working_day = total_day - sum_day_off;
    var working_hours = (parseInt(work_hours.day_normal) + parseInt(work_hours.day_ot) + parseInt(work_hours.night_normal) + parseInt(work_hours.night_ot));
    if(working_hours > 24){
        setWork_hours({
            ...work_hours,
            day_normal: 8,
            day_ot:4,
            night_normal:8,
            night_ot:4,
        });   
    }
    var total_working_hours_available = day_off.total_hour;





    function getRandomColor() {
        var letters = 'BCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }
        return color;
    }
    var process_hr;
    var lenght;
    const td1hr_forcyle = [];
    var all_td_process = [];
    //var all_td_process_actual = [];
    var line_no;
    var stack = 0;
    const line = [];
    for (let y = 0; y < machine_select.length; y++) {
        let temp = {
            machine_id: machine_select[y],
            line: y + 1,
            lenght: 0,
        }
        line.push(temp);
    }
  

    const err_max = [];
    
    var of_line = 1;
    var stack_of_line = 1;
    var OSL_table_temp = [];
    var OSL_table_temp2 = [];
    var have_machine = [];
    var count_ = 0;
    for (var j = 0; j < OSL_table.length; j++) {
        for(let e of OSL_table[j].machine) {
            //console.log(e)
            let check = have_machine.filter((e2)=>{
                return e2.id === e.machine_id;
            })
            if(check.length === 0){
                let temp ={
                    id:e.machine_id,
                    machine_name:e.machine_name,
                }
                have_machine.push(temp)
            }
        }
        if (OSL_table[j].cycle_time > 0) {
            let f = j - 1;
            let color = "#F0FFF0";
            if (f > -1 && OSL_table[j].product_id != OSL_table[f].product_id ) {
                color = getRandomColor();
                let use_color = OSL_table_temp.filter((e)=> {
                    return e.color === color;
                })
                if(use_color.length > 0){
                    //console.log("ซ้ำ");
                    color = getRandomColor();
                }                
            }
            let count_hours = Math.ceil((OSL_table[j].amount * OSL_table[j].cycle_time) * 0.000277);
            let count_hours_actual = Math.ceil(OSL_table[j].actual_time);
               if(count_hours > total_working_hours_available){
                   let actual_time = 0;
                if(OSL_table[j].actual_time){
                    if(count_hours_actual < total_working_hours_available){
                        actual_time = count_hours_actual;
                    }else{
                        actual_time = total_working_hours_available;
                    }
                    
                }
                   
                   let temp_ble  = {    
                            amount: OSL_table[j].amount,
                            cycle_time: OSL_table[j].cycle_time,
                            process_id: OSL_table[j].process_id,
                            product_id: OSL_table[j].product_id,
                            product_name: OSL_table[j].product_name,
                            product_no: OSL_table[j].product_no,
                            machine: OSL_table[j].machine,
                            c_balacne: total_working_hours_available,
                            actual_time: actual_time,
                            color: color,
                            count: count_++,
                        }
                        OSL_table_temp.push(temp_ble);
                        count_hours =  count_hours - total_working_hours_available;
                        count_hours_actual = count_hours_actual - total_working_hours_available;
               }
               if(count_hours > total_working_hours_available){
             
                let actual_time = 0;
                if(OSL_table[j].actual_time){
                    if(count_hours_actual < total_working_hours_available){
                        actual_time = count_hours_actual;
                    }else{
                        actual_time = total_working_hours_available;
                    }
                }
                   
                
                let temp_ble  = {
                    amount: OSL_table[j].amount,
                         cycle_time: OSL_table[j].cycle_time,
                         process_id: OSL_table[j].process_id,
                         product_id: OSL_table[j].product_id,
                         product_name: OSL_table[j].product_name,
                         product_no: OSL_table[j].product_no,
                         machine: OSL_table[j].machine,
                         c_balacne: total_working_hours_available,
                         actual_time: actual_time,
                         color: color,
                         count: count_++,
                     }
                     OSL_table_temp.push(temp_ble);
                     count_hours =  count_hours - total_working_hours_available;
                     count_hours_actual = count_hours_actual - total_working_hours_available;
            }
            if(count_hours > total_working_hours_available){
                let actual_time = 0;
                if(OSL_table[j].actual_time){
                    if(count_hours_actual < total_working_hours_available){
                        actual_time = count_hours_actual;
                    }else{
                        actual_time = total_working_hours_available;
                    }
                }
                   
             
                
                let temp_ble  = {
                    amount: OSL_table[j].amount,
                         cycle_time: OSL_table[j].cycle_time,
                         process_id: OSL_table[j].process_id,
                         product_id: OSL_table[j].product_id,
                         product_name: OSL_table[j].product_name,
                         product_no: OSL_table[j].product_no,
                         machine: OSL_table[j].machine,
                         c_balacne: total_working_hours_available,
                         actual_time: actual_time,
                         color: color,
                         count: count_++,
                     }
                     OSL_table_temp.push(temp_ble);
                     count_hours =  count_hours - total_working_hours_available;
                     count_hours_actual = count_hours_actual - total_working_hours_available;
            }
            if(count_hours > total_working_hours_available){
                let actual_time = 0;
                if(OSL_table[j].actual_time){
                    if(count_hours_actual < total_working_hours_available){
                        actual_time = count_hours_actual;
                    }else{
                        actual_time = total_working_hours_available;
                    }
                }
                   
             
                
                let temp_ble  = {
                         amount: OSL_table[j].amount,
                         cycle_time: OSL_table[j].cycle_time,
                         process_id: OSL_table[j].process_id,
                         product_id: OSL_table[j].product_id,
                         product_name: OSL_table[j].product_name,
                         product_no: OSL_table[j].product_no,
                         machine: OSL_table[j].machine,
                         c_balacne: total_working_hours_available,
                         actual_time: actual_time,
                         color: color,
                         count: count_++,
                     }
                     OSL_table_temp.push(temp_ble);
                     count_hours =  count_hours - total_working_hours_available;
                     count_hours_actual = count_hours_actual - total_working_hours_available;
            }
            if(count_hours > total_working_hours_available){
              
             
             
                let actual_time = 0;
                if(OSL_table[j].actual_time){
                    if(count_hours_actual < total_working_hours_available){
                        actual_time = count_hours_actual;
                    }else{
                        actual_time = total_working_hours_available;
                    }
                }
                   
                let temp_ble  = {
                    amount: OSL_table[j].amount,
                         cycle_time: OSL_table[j].cycle_time,
                         process_id: OSL_table[j].process_id,
                         product_id: OSL_table[j].product_id,
                         product_name: OSL_table[j].product_name,
                         product_no: OSL_table[j].product_no,
                         machine: OSL_table[j].machine,
                         c_balacne: total_working_hours_available,
                         actual_time: actual_time,
                         color: color,
                         count: count_++,
                     }
                     OSL_table_temp.push(temp_ble);
                     count_hours =  count_hours - total_working_hours_available;
                     count_hours_actual = count_hours_actual - total_working_hours_available;
            }
            if(count_hours > total_working_hours_available){
                
                let actual_time = 0;
                if(OSL_table[j].actual_time){
                    if(count_hours_actual < total_working_hours_available){
                        actual_time = count_hours_actual;
                    }else{
                        actual_time = total_working_hours_available;
                    }
                }
                   
                
                let temp_ble  = {
                    amount: OSL_table[j].amount,
                         cycle_time: OSL_table[j].cycle_time,
                         process_id: OSL_table[j].process_id,
                         product_id: OSL_table[j].product_id,
                         product_name: OSL_table[j].product_name,
                         product_no: OSL_table[j].product_no,
                         machine: OSL_table[j].machine,
                         c_balacne: total_working_hours_available,
                         actual_time: actual_time,
                         color: color,
                         count: count_++,
                     }
                     OSL_table_temp.push(temp_ble);
                     count_hours =  count_hours - total_working_hours_available;
                     count_hours_actual = count_hours_actual - total_working_hours_available;
            }
              
                
               let temp_ble2  = {
                amount: OSL_table[j].amount,
                cycle_time: OSL_table[j].cycle_time,
                process_id: OSL_table[j].process_id,
                product_id: OSL_table[j].product_id,
                product_name: OSL_table[j].product_name,
                product_no: OSL_table[j].product_no,
                machine: OSL_table[j].machine,
                c_balacne: count_hours,
                actual_time: count_hours_actual,
                color: color,
                count: count_++,
            }
            OSL_table_temp.push(temp_ble2);

        }}
        //console.log(OSL_table_temp)
    //------------------------------------------------------------------------------------------------
 let max_other = 0;
    //------------------------------------------------------------------------------------------------

    for (var j = 0; j < OSL_table_temp.length; j++) {
        if (OSL_table_temp[j].cycle_time > 0) {
            let c = OSL_table_temp[j].c_balacne;
            let c_actual = OSL_table_temp[j].actual_time || 0;
           
          
            
                let scope_line = [];
                for(let item of OSL_table_temp[j].machine){
                    for(let item2 of line){
                        if(item.machine_id === item2.machine_id.id){
                            scope_line.push(item2);
                        }
                    }
                    
                }
                //console.log("scope_line",scope_line);
                let result = scope_line.sort((a,b)=> a.lenght - b.lenght);
               
                //let result = scope_line.filter(e => e.lenght === Math.min(...line.map(f => f.lenght)));
            
    
               // console.log("after",result);
                if(result.length > 0){
                    for (let index3 = 0; index3 < line.length; index3++) {
                
                        if (line[index3].line === result[0].line) {
                   
                            line_no = result[0].line;
                            lenght = result[0].lenght;
                            let old_lenght1 = line[index3].lenght;
                            
                            let new_lenght1 = old_lenght1 + c;
                           // console.log("old_lenght1",old_lenght1)
                            //console.log("new_lenght1",new_lenght1)
                            let machine = result[0].machine_id;
                            if(new_lenght1 > total_working_hours_available){
                         
                                let balance = new_lenght1 - total_working_hours_available;
                                let balance_inline = c - balance;
                             
                                //console.log(OSL_table_temp[j].product_no,"new_lenght1", new_lenght1, "c", c, "balance ",balance, "balance_inline",balance_inline)
                                line[index3].lenght = total_working_hours_available;
                               let temp_ = {
                                product_id: OSL_table_temp[j].product_id,
                                product_no: OSL_table_temp[j].product_no,
                                product_name: OSL_table_temp[j].product_name,
                                machine: machine,
                                line: line_no,
                                length: balance_inline,
                                //ac_length: balance,
                                color: OSL_table_temp[j].color,
                            }
                            //console.log(balance_inline)
                                if(balance_inline >= 0){
                                    max_other += c;
                                    console.log(temp_.product_no," เกินที่ ",temp_.machine.machine_name)
                                    temp_.length = c;
                                }
                            //console.log("no1", OSL_table_temp[j].product_no,temp_.machine.machine_name,temp_.length)
                                
                                all_td_process.push(temp_)
                      
                             
                      
                              //console.log("ถึงนี่แล้ว")
                              let temp_ble2  = {
                                amount: OSL_table_temp[j].amount,
                                cycle_time: OSL_table_temp[j].cycle_time,
                                process_id: OSL_table_temp[j].process_id,
                                product_id: OSL_table_temp[j].product_id,
                                product_name: OSL_table_temp[j].product_name,
                                product_no: OSL_table_temp[j].product_no,
                                machine: machine,
                                c_balacne: balance,
                                //ac_length: balance,
                                color: OSL_table_temp[j].color,
                            }
                            OSL_table_temp2.push(temp_ble2);
                              
                            //console.log("เกินแล้ว line ที่ " , line[index3].line,"\r\nถ้าใส่ตัวนี้", OSL_table_temp[j].product_name ,"\r\nจะเกินไปจำนวน ", balance )     
                           }else{
                              
                            line[index3].lenght = new_lenght1;
                     
                            let temp_ = {
                                product_id: OSL_table_temp[j].product_id,
                                product_name: OSL_table_temp[j].product_name,
                                product_no: OSL_table_temp[j].product_no,
                                machine:machine,
                                line: line_no,
                                length: c,
                                //ac_length: c,
                                color: OSL_table_temp[j].color,
                            }
                           // console.log("no2", OSL_table_temp[j].product_no,temp_.machine.machine_name,temp_.length)
                         
                       
                                all_td_process.push(temp_)
                         
                            //all_td_process.push(temp_);
                            //console.log("มีเครื่อง",temp_.product_no)
                            if(c_actual > 0){
                              //  all_td_process_actual.push(temp_actual);
                            }
                           }
                          
                        }
                    }
                }else{
                    let temp = {
                        product_no:OSL_table_temp[j].product_no,
                        machine:"-"
                    }
                    err_max.push(temp);
                     console.log("ไมมีเครื่อง :",OSL_table_temp[j].product_no)
                }
            
               

           // } */

            td1hr_forcyle.push(j);

            process_hr = c;
        }
        if(OSL_table_temp.length === j+1){
           // console.log("final")

        }
    }
    //console.log(OSL_table_temp2);
    //------------------------------------------
    for (var j = 0; j < OSL_table_temp2.length; j++) {
        if (OSL_table_temp2[j].cycle_time > 0) {
            let c = OSL_table_temp2[j].c_balacne;
            let c_actual = OSL_table_temp[j].actual_time || 0;
     

                let scope_line = [];
                for(let item of OSL_table_temp[j].machine){
                    for(let item2 of line){
                        if(item.machine_id === item2.machine_id.id){
                            scope_line.push(item2);
                        }
                    }
                    
                }
                //console.log("scope_line",scope_line);
                let result = scope_line.sort((a,b)=> a.lenght - b.lenght);
               if(result.length > 0){
                for (let index3 = 0; index3 < line.length; index3++) {
                
                    if (line[index3].line === result[0].line) {
                      

                        line_no = result[0].line;
                        lenght = result[0].lenght;
                        //console.log(line_no," ",lenght);
                        let old_lenght1 = line[index3].lenght;
                 
                        let new_lenght1 = old_lenght1 + c;
                     
                        if(new_lenght1 > total_working_hours_available){
                            let balance = new_lenght1 - total_working_hours_available;
                            let balance_inline = c - balance;
                          //  console.log(balance_inline)
                            line[index3].lenght = total_working_hours_available;
                     
                           let temp_ = {
                            product_id: OSL_table_temp2[j].product_id,
                            product_no: OSL_table_temp2[j].product_no,
                            product_name: OSL_table_temp2[j].product_name,
                            machine: OSL_table_temp2[j].machine,
                            line: line_no,
                            length: balance,
                            ac_length: balance,
                            color: OSL_table_temp2[j].color,
                        }
                        //console.log("no3", OSL_table_temp[j].product_no,temp_.machine.machine_name,temp_.length)
                  
                            all_td_process.push(temp_)
                            console.log(balance_inline)
                            if(balance_inline >= 0){
                                max_other += c;
                                console.log(temp_.product_no," เกินที่ ",temp_.machine)
                            }
                          //all_td_process.push(temp_)
                          //console.log("ถึงนี่แล้ว")
                        
                        //OSL_table_temp2.push(temp_ble2);
                          
                        //console.log("เกินแล้ว line ที่ " , line[index3].line,"\r\nถ้าใส่ตัวนี้", OSL_table_temp2[j].product_name ,"\r\nจะเกินไปจำนวน ", balance )     
                       }else{
                        line[index3].lenght = new_lenght1;
                     
                        let temp_ = {
                            product_id: OSL_table_temp2[j].product_id,
                            product_no: OSL_table_temp2[j].product_no,
                            product_name: OSL_table_temp2[j].product_name,
                            machine: OSL_table_temp2[j].machine,
                            line: line_no,
                            length: c,
                            ac_length: c,
                            color: OSL_table_temp2[j].color,
                        }
                       // console.log("no4", OSL_table_temp[j].product_no,temp_.machine.machine_name,temp_.length)
                      
                       // console.log("no: ",temp_.product_no," machine: ",line_no," lenght: ",c)
                 
                        all_td_process.push(temp_)
                 
                        //all_td_process.push(temp_)
                        if(c_actual >0){
                          //  all_td_process_actual.push(temp_actual);
                        }
                       
                       }
                      
                    }
                }
               }else{
                let temp = {
                    product_no:OSL_table_temp2[j].product_no,
                    machine:"-"
                }
                err_max.push(temp);
                console.log("ไมมีเครื่อง :",OSL_table_temp2[j].product_no)
               }
        
                

            //}

            td1hr_forcyle.push(j);

            process_hr = c;
        }
        if(OSL_table_temp.length === j+1){
          //  console.log("final")

        }
    }


    for(let i of all_td_process){
        for(let p of all_td_process_actual){
            if(i.product_id === p.product_id){
                p.color = i.color;
            }
        } 
    }


//console.log(line)
//console.log(all_td_process)
var check_line = all_td_process.filter((e)=>{return e.ac_length >= 0});
 
 console.log(check_line)
 
if(check_line.length > 0){
    for(let i of check_line){
        var check = err_max.filter((e)=>{return e.product_no === i.product_no });
        let temp = {
            product_no:i.product_no,
            machine:i.machine.machine_name
        }
        if(check.length === 0){
            err_max.push(temp);
        }
       
       // console.log("เกินแล้วที่เครื่อง :", i.machine.machine_name);
    }
  //  console.log("ไม่มีเครื่องลง ",)
}

//console.log("err",err_max);

    
 
    
    const count = [];
    const td24hr = [];
    for (var p = 0; p < machine_process.length; p++) {
        /*   let temp ={
            count:168
          } */
        count.push(168);
        td24hr.push(0);
    }
    //console.log(count)
   




    var total_work_hours = parseFloat(0);
    var total_work_pieces = parseInt(0);
    var total_work_hours2 = parseFloat(0);
    for (var i = 0; i < rowsData.length; i++) {
        total_work_hours += Math.ceil((rowsData[i].amount * rowsData[i].cycle_time) * 0.000277);
        total_work_pieces += parseInt(rowsData[i].amount);
    }

    //console.log("all_td_process",all_td_process,day_off.total_hour,numberline.number_line)
    for (var i = 0; i < all_td_process.length; i++) {
        total_work_hours2 += parseFloat(all_td_process[i].length);
        //console.log("ตัวที่ ",i, " ",all_td_process[i].length);
    }
    //console.log(sum_hours_cycle)
    //console.log("total_work_hours",total_work_hours)
    //console.log("total_work_hours2",total_work_hours2)
    //console.log("total_working_hours_available",total_working_hours_available)
    //console.log("numberline.number_line",numberline.number_line)
    //console.log("total_work_hours",total_work_hours,max_other,day_off.total_hour,numberline.number_line)
    var target_use_hrs = ( (total_work_hours) / (day_off.total_hour * numberline.number_line)) * 100;
    //var fore_remain = 100 - target_use_hrs;
    //console.log("target_use_hrs",target_use_hrs)
    var remain_hrs = 100 - target_use_hrs;
    //console.log("remain_hrs",remain_hrs);
    


   
    //console.log("sum",moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24)).format('DD-MM-yyyy'))
    //console.log("sum",moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24)).format('ddd'))

    //const date_stock = [];
    //const month_stock = [];
    //var date_start_txt = moment(Math.round(calender_data.start_date)).format('ddd');
    //var month_check1 = moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24)).format('MMMM');
    //var month_check = moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24)).format('MMMM');
    /* let temp_month = {
        month: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24)).format('MM'),
        month_name: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24)).format('MMMM'),
        year: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24)).format('yyyy'),
    } */
    //month_stock.push(temp_month)
    var week_check;

    //const week_stock = [];
    const week_stock2 = [];
    //console.log(date_calendar)
    //console.log(date_calendar.length)
    if(date_calendar.length > 0){
        for(let date of date_calendar){
            //console.log(date)
            var temp_date = {
                day_start: moment(date.start).format(),
                day_normal:date.day_normal,
                day_ot:date.day_ot,
                night_normal:date.night_normal,
                night_ot:date.night_ot,
               // day_name_start: temp_date.day_name,
                //month: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MMMM'),
            }
            week_stock2.push(temp_date);
        }
    }
   
    /* for (let i = 0; i < total_day; i++) {
        
        let temp_date = {
            day_name: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('ddd'),
            day: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('DD'),
            month: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MM'),
            month_name: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MMMM'),
            year: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('yyyy'),
        }
        date_stock.push(temp_date);
      
       if(temp_date.day_name !== "Sun"){
        week_check = week_check + 1;
        let temp_week = {
            week: week_check,
            day_start: (Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)),
            day_name_start: temp_date.day_name,
            month: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MMMM'),
        }
        week_stock.push(temp_week);



        var month_check = moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MMMM');
        if (month_check1 != month_check) {
            month_check1 = moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MMMM');
            //console.log(month_check1);
            let temp_month = {
                month: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MM'),
                month_name: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('MMMM'),
                year: moment(Math.round(calender_data.start_date) + (1000 * 60 * 60 * 24 * i)).format('yyyy'),
            }
            month_stock.push(temp_month);
        }

       }
       

    

    } */

    const td24hr_2 = [];

    for (var j = 1; j <= day_off.total_hour; j++) {
        td24hr_2.push(j);
    }

//var tagc_use_data = all_td_process.reduce((sum,current) => {return sum + current.age});
var tagc_use_data = [];
all_td_process.reduce(function(res, value) {
  if (!res[value.line]) {
    res[value.line] = { line: value.line, length: 0 };
    tagc_use_data.push(res[value.line])
  }
  res[value.line].length += value.length;
  return res;
}, {});

tagc_use_data.sort((a,b)=>a.line - b.line);
console.log(tagc_use_data)
function cal_plan(){
    
    if(remain_hrs < 0 || err_max.length > 0){
        var err_message = "";
      for(var e =0; e < err_max.length;e++){
          if(err_max[e].machine === "-"){
            err_message += "<br/>"+err_max[e].product_no+" ไม่มีเครื่องที่จะลง";
          }else{
            err_message += "<br/>"+err_max[e].product_no+" เกินที่ "+err_max[e].machine;
          }
        
      }
     // Swal.fire("Error", err_message, "error");
        Swal.fire("Warning", err_message, "warning");
    }else{
        const day_length = [];
        var start_length =0;
        for(let dl of week_stock2){
            start_length =parseInt(start_length) + parseInt(dl.day_normal)+parseInt(dl.day_ot)+parseInt(dl.night_normal)+parseInt(dl.night_ot);
            let temp = {
                day:dl.day_start,
                _length:parseInt(dl.day_normal)+parseInt(dl.day_ot)+parseInt(dl.night_normal)+parseInt(dl.night_ot),
                start:start_length - (parseInt(dl.day_normal)+parseInt(dl.day_ot)+parseInt(dl.night_normal)+parseInt(dl.night_ot))+1,
                end:start_length
             
            }
            day_length.push(temp);
        }
        for(let l of line){
            let position_length = 0;
            for(let sl of all_td_process){
                if(l.line === sl.line){
                    position_length = position_length+sl.length;
                    sl.position_length = position_length;
                }
            }
        }
        for(let dl of day_length){
            for(let sl of all_td_process){
                
                if(dl.start <= sl.position_length && sl.position_length <= dl.end){
                   // console.log(dl.start ,"<=", sl.position_length ,"<=", dl.end)
                    //console.log(sl.line, sl.product_name, sl.position_length,"--->",dl.day)
                    sl.day_end = dl.day;
                 
                }
                if(dl.start <= sl.position_length-sl.length+1 && sl.position_length-sl.length+1  <= dl.end){
                    sl.day_start = dl.day;
                }
            }
        }
        
     for(let osl of OSL_table){
        let osl_filter = all_td_process.filter((e)=>{
            return e.product_id === osl.product_id;
        })
      
        
        if(osl_filter.length > 0){
            let start_date = osl_filter.sort((a,b)=> moment(a.day_start) - moment(b.day_start));
            let end_date = osl_filter.sort((a,b)=> moment(b.day_end) - moment(a.day_end));
            //console.log("day_start ",start_date[0].day_start,"   day_end ",end_date[0].day_end)
            osl.start_date = moment(start_date[0].day_start).format('DD/MM/yyyy');
            osl.end_date = moment(end_date[0].day_end).format('DD/MM/yyyy');
        }
    } 
    
    let s = calender_data.start_date;
    let e = calender_data.end_date;
    setcalender_data({...calender_data,start_date:s,end_date:e});
    }
    

}
//console.log(all_td_process)


    const data = {
        columns: [{
                label: "No",
                field: "no",
                sort: "asc",
                width: 50,
            },
            {
                label: "Product No",
                field: "product_no",
                sort: "asc",
                width: 50,
            },
            {
                label: "Product Name",
                field: "product_name",
                sort: "asc",
                width: 50,
            },
            {
                label: "Qty",
                field: "amount",
                sort: "asc",
                width: 50,
            },
           /*  {
                label: "In hand",
                field: "in_hand",
                sort: "asc",
                width: 50,
            }, */
            /* {
                label: "Actual Qty",
                field: "actual_amount",
                sort: "asc",
                width: 50,
            }, */
            {
                label: "Cycle time(in second)",
                field: "cycle_time",
                sort: "asc",
                width: 50,
            },
            {
                label: "tt work hours (in hours)",
                field: "tt_work_hours",
                sort: "asc",
                width: 50,
            },
            {
                label: "Start Date",
                field: "start_date",
                sort: "asc",
                width: 50,
            },
            {
                label: "End date",
                field: "end_date",
                sort: "asc",
                width: 50,
            },
            {
                label: "Start date (Actual)",
                field: "actual_start",
                sort: "asc",
                width: 50,
            },
            {
                label: "End date (Actual)",
                field: "actual_end",
                sort: "asc",
                width: 50,
            },
           



        ],
        rows: rowsData,
    };


    function controlClick(){
        console.log("HERE")
        document.getElementById('test-table-xls-button').click();
    }

    function saveOption() {
        console.log(OSL_table)
        const err_list = [];
        for(let osl of OSL_table){
            console.log(osl.material_lot);
            let check_mat_lot = osl.material_lot.filter((e)=>{
                return e.mat_lot === false;
            })
            console.log(check_mat_lot);
            if(check_mat_lot.length > 0){
                let temp = {
                    product_no:osl.product_no,
                    material_no:osl.mat_no,
                    err_amount:check_mat_lot[0].mat_amount,
                }
                err_list.push(temp);
            }
        }
        console.log(err_list)
        if(err_list.length > 0){
            var err_message = "<h2>ไม่สามารถบันทึกได้</h2>";
            err_message += "เนื่องจากมีจำนวน material ไม่เพียงพอ ดังนี้"
            err_message += "<table id = 'table_err-to-xls'  border = '1' width = '100%'>";
            err_message += "<tr><td>Material No</td><td>ขาดเป็นจำนวน</td></tr>";
            let tempErrorSumMat =[];
            console.log(err_list);
        
            for(let itemerr of err_list){
                var check = tempErrorSumMat.filter((ee)=>{return ee.material_no === itemerr.material_no});
                if(check.length > 0){
                    var index = tempErrorSumMat.findIndex((ee)=> ee.material_no === itemerr.material_no);
                    tempErrorSumMat[index].err_amount = parseInt(tempErrorSumMat[index].err_amount)+parseInt(itemerr.err_amount);
                }else{
                    tempErrorSumMat.push(itemerr);
                }
            }
            console.log(tempErrorSumMat);
            for(var e =0;e < tempErrorSumMat.length;e++){
              err_message += "<tr><td>"+tempErrorSumMat[e].material_no+/* " "+err_list[e].product_no+ */"</td><td>"+tempErrorSumMat[e].err_amount +"</td></tr>";
            }
            err_message += "</table>";
          
            //err_message +="<button class = 'btn btn-block btn-success'>export excel</button>"
            Swal.fire("Error", err_message, "error");
            controlClick();
        }else{

    
        var tempIn_process = [];
        let process_select_temp1 = process_select.filter((element) => {
            return (element.isSelect === true);
        })
        for (var index = 0; index < process_select_temp1.length; index++) {
            const temp_process = {

                process_id: process_select_temp1[index].id,
            }

            tempIn_process.push(temp_process)
        }

        const temp_manpower = {
         
                total_day: day_off.total_day,
                working_hours: day_off.total_hour / day_off.total_day,
                total_working_hours_available: day_off.total_hour,
                number_of_line:numberline.number_line,
            }
         
        const temp_calculate = {
                total_work_pieces: total_work_pieces,
                total_work_hours: total_work_hours.toFixed(2),
                target_use_hrs: target_use_hrs.toFixed(2),
                remain_hrs: remain_hrs.toFixed(2),
            }
         
        var tempIn_order = [];
        let order_product_select_temp1 = order_product_select.filter((element) => {
            return (element.isSelect === true);
        })
        for (var index = 0; index < order_product_select_temp1.length; index++) {
            const temp_order = {

                id: order_product_select_temp1[index].id,
            }
            tempIn_order.push(temp_order);
        }
        const temp_part_no = {
            order: tempIn_order,
            part_no: OSL_table,
            product_plan: all_td_process,
            machine: machine_select,
        }
        //console.log(OSL_table);

        const temp_add = {
            part_no: temp_part_no,
            process_id: tempIn_process,
            manpower: temp_manpower,
            calculate: temp_calculate,
            start_date: calender_data.start_date,
            end_date: calender_data.end_date,
            create_by: getUser().fup,
            oem_id: getOem(),
            is_draft:false,
        }

        const temp_update = {
            id: id,
            part_no: temp_part_no,
            process_id: tempIn_process,
            manpower: temp_manpower,
            calculate: temp_calculate,
            start_date: calender_data.start_date,
            end_date: calender_data.end_date,
            create_by: getUser().fup,
            oem_id: getOem(),
            is_draft:false,
        }


       // console.log(temp_add);
        
if(tempIn_order.length > 0 && tempIn_process.length > 0){
    if (mode.mode == "add") {

        Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            axios({
                    method: "post",
                    url: Configs.API_URL + "/orderSummary/Add_Log_OSL",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: temp_add,
                })
                .then(function(response) {
                    if (response.data) {
                        Swal.fire({
                            icon: "success",
                            title: "Save",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then((result) => {
                            window.location.href =
                                "/Production_Planing/Order_Summary_Report";
                        });
                    }

                
                })
                .catch(function(error) {
                    console.log(error);
                    Swal.fire("Error", "There is an error", "error");
                });
        });

    } else if (mode.mode == "edit") {
       // console.log(temp_update);
        Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            axios({
                    method: "post",
                    url: Configs.API_URL + "/orderSummary/Edit_Log_OSL",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: temp_update,
                })
                .then(function(response) {
                    if (response.data) {
                        Swal.fire({
                            icon: "success",
                            title: "Save",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then((result) => {
                            window.location.href =
                                "/Production_Planing/Order_Summary_Report";
                        });
                    }

                   // console.log(response.data);
                })
                .catch(function(error) {
                    console.log(error);
                    Swal.fire("Error", "There is an error", "error");
                });
        });
    }
}else{
    Swal.fire("Error", "Please Select Order and Process", "error");
}
      

}

    } 
    function saveDraftOption() {
        console.log(OSL_table)
        const err_list = [];
        for(let osl of OSL_table){
            console.log(osl.material_lot);
            let check_mat_lot = osl.material_lot.filter((e)=>{
                return e.mat_lot === false;
            })
            console.log(check_mat_lot);
            if(check_mat_lot.length > 0){
                let temp = {
                    product_no:osl.product_no,
                    material_no:osl.mat_no,
                    err_amount:check_mat_lot[0].mat_amount,
                }
                err_list.push(temp);
            }
        }
        console.log(err_list)
        if(err_list.length > 0){
            var err_message = "<h2>ทำการบันทึก Draft ได้อย่างเดียว</h2>";
            err_message += "เนื่องจากมีจำนวน material ไม่เพียงพอ ดังนี้"
            err_message += "<table id = 'table_err-to-xls'  border = '1' width = '100%'>";
            err_message += "<tr><td>Material No</td><td>ขาดเป็นจำนวน</td></tr>";
            let tempErrorSumMat =[];
            console.log(err_list);
        
            for(let itemerr of err_list){
                var check = tempErrorSumMat.filter((ee)=>{return ee.material_no === itemerr.material_no});
                if(check.length > 0){
                    var index = tempErrorSumMat.findIndex((ee)=> ee.material_no === itemerr.material_no);
                    tempErrorSumMat[index].err_amount = parseInt(tempErrorSumMat[index].err_amount)+parseInt(itemerr.err_amount);
                }else{
                    tempErrorSumMat.push(itemerr);
                }
            }
            console.log(tempErrorSumMat);
            for(var e =0;e < tempErrorSumMat.length;e++){
              err_message += "<tr><td>"+tempErrorSumMat[e].material_no+/* " "+err_list[e].product_no+ */"</td><td>"+tempErrorSumMat[e].err_amount +"</td></tr>";
            }
            err_message += "</table>";
          
            //err_message +="<button class = 'btn btn-block btn-success'>export excel</button>"
            Swal.fire("Error", err_message, "error")
            controlClick(); 
        }/* else{ */

    
        var tempIn_process = [];
        let process_select_temp1 = process_select.filter((element) => {
            return (element.isSelect === true);
        })
        for (var index = 0; index < process_select_temp1.length; index++) {
            const temp_process = {

                process_id: process_select_temp1[index].id,
            }

            tempIn_process.push(temp_process)
        }

        const temp_manpower = {
         
                total_day: day_off.total_day,
                working_hours: day_off.total_hour / day_off.total_day,
                total_working_hours_available: day_off.total_hour,
                number_of_line:numberline.number_line,
            }
         
        const temp_calculate = {
                total_work_pieces: total_work_pieces,
                total_work_hours: total_work_hours.toFixed(2),
                target_use_hrs: target_use_hrs.toFixed(2),
                remain_hrs: remain_hrs.toFixed(2),
            }
         
        var tempIn_order = [];
        let order_product_select_temp1 = order_product_select.filter((element) => {
            return (element.isSelect === true);
        })
        for (var index = 0; index < order_product_select_temp1.length; index++) {
            const temp_order = {

                id: order_product_select_temp1[index].id,
            }
            tempIn_order.push(temp_order);
        }
        const temp_part_no = {
            order: tempIn_order,
            part_no: OSL_table,
            product_plan: all_td_process,
            machine: machine_select,
        }
        //console.log(OSL_table);

        const temp_add = {
            part_no: temp_part_no,
            process_id: tempIn_process,
            manpower: temp_manpower,
            calculate: temp_calculate,
            start_date: calender_data.start_date,
            end_date: calender_data.end_date,
            create_by: getUser().fup,
            oem_id: getOem(),
            is_draft:true,
        }

        const temp_update = {
            id: id,
            part_no: temp_part_no,
            process_id: tempIn_process,
            manpower: temp_manpower,
            calculate: temp_calculate,
            start_date: calender_data.start_date,
            end_date: calender_data.end_date,
            create_by: getUser().fup,
            oem_id: getOem(),
            is_draft:true,
        }


       // console.log(temp_add);
        
if(tempIn_order.length > 0 && tempIn_process.length > 0){
    if (mode.mode == "add") {

        Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            axios({
                    method: "post",
                    url: Configs.API_URL + "/orderSummary/Add_Log_OSL",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: temp_add,
                })
                .then(function(response) {
                    if (response.data) {
                        Swal.fire({
                            icon: "success",
                            title: "Save",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then((result) => {
                            window.location.href =
                                "/Production_Planing/Order_Summary_Report";
                        });
                    }

                
                })
                .catch(function(error) {
                    console.log(error);
                    Swal.fire("Error", "There is an error", "error");
                });
        });

    } else if (mode.mode == "edit") {
       // console.log(temp_update);
        Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
                Swal.showLoading();
            },
        }).then((result) => {
            axios({
                    method: "post",
                    url: Configs.API_URL + "/orderSummary/Edit_Log_OSL",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: temp_update,
                })
                .then(function(response) {
                    if (response.data) {
                        Swal.fire({
                            icon: "success",
                            title: "Save",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then((result) => {
                            window.location.href =
                                "/Production_Planing/Order_Summary_Report";
                        });
                    }

                   // console.log(response.data);
                })
                .catch(function(error) {
                    console.log(error);
                    Swal.fire("Error", "There is an error", "error");
                });
        });
    }
}else{
    Swal.fire("Error", "Please Select Order and Process", "error");
}
      

/* } */

    } 

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [OSL, setOSL] = useState([]);
    const [filler_OSL, setFiller_OSL] = useState({
        osl_no: "",
        oem_id: getOem(),
    });
    const GetOSL = async() => {


        axios({
                method: "post",
                url: Configs.API_URL + "/orderSummary/filter",
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
                data: filler_OSL
            })
            .then(function(response) {
                // console.log(response.data);
                setOSL(response.data);

            })
            .catch(function(error) {
                console.log(error);
            });
    };

    useEffect(() => {
        GetOSL();

    }, []);

    function truereport(){
        if(remain_hrs < 0  || err_max.length > 0){
            Swal.fire("Warning", "% Remain is zero or nagative!!!", "warning");
        }else{
        for (let checks = 0; checks < OSL_table.length; checks++)
        {
           OSL_table[checks].dd_off_line =  OSL_table[0].dd_off_line;
           OSL_table[checks].osl_no = OSL[0].oslno;
           OSL_table[checks].pbn_no = order_product[0].order_no;
           OSL_table[checks].oem_id = getOem();

        }
     //  console.log(OSL_table);
  
       axios({
           method: "post",
           url: Configs.API_URL + "/orderSummary/Addtagno",
           headers: {
               Authorization: getToken(),
               "X-TTT": Configs.API_TTT,
               "Content-Type": "application/json",
           },
           data: OSL_table
       })
       .then(function(response) {
           //console.log(response.data);
            truetag = response.data;
           downloadReport(response.data);
       })
       .catch(function(error) {
           console.log(error);
       });
    }}
    function downloadReport() {
    
     
        const doc = new jsPDF("l", "mm", "a5");
       let img_qr = "";
       var counts = 0;
       var dateendcount = 0;
       var countsdate=0;
       var sum = OSL_table.filter((e)=>{
           return !e.end_date
       })
       //console.log(OSL_table);
       
       for (let index = 0; index < OSL_table.length; index = index + 1) {
           if(OSL_table[index].end_date)
           {
               
        // console.log(OSL);
        //console.log(OSL_table);
        // console.log(order_product);
  
        // console.log(OSL_table[index].end_date);
    
   
        var textRmatno = "";

  
    //    console.log(textRmatno);
     

        doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.addFont('Rocki', 'bold');
       //box 1
        if (index < OSL_table.length) {
        

          
            var text1;
            var text2;
            var lengtext;
          
  
      

   
          
          
            var Rmuse = 0;
              var osl_amount;
              var actuse=0;
              var product_no;
              var amount;
              var product_name;
              var product_route;
              var mat_type;
              var dts;
              var mat_spec;
              var netdate;
              var min ;
              var thisday;
              var dd_off_line;
              var c_leng;
              var end_dates;
              var start_dates;
              var mat_lot_no;
          var row = 0;
          var sum_amount = 0;
             var product_id = "";
             var productid="";
             var steel_bar;
             var mat_size;
             //Part No.
             
            
              //part no
              if (!OSL_table[index]) {
                product_name="";
                steel_bar="";
                product_id ="";
                productid="";
                mat_lot_no = "";
                mat_spec= "";
                mat_size="";
                  osl_amount = "none";
                  product_no = "";
                  oslno = "";
                  amount = 0;
                  end_dates= "";
                  start_dates= "";
                  dd_off_line = "";
                  product_route ="";
                  //console.log("ไม่มี :",osl_amount)
                  mat_type = "";
                  c_leng = 0;
                  dts ="";
              } else {
              
                  osl_amount = OSL_table[index].amount;
                  product_no = OSL_table[index].product_no;
             
          
                  
                  if(!OSL_table[index].product_name){
                    product_name ="";
                  }else{
                    product_name = OSL_table[index].product_name;}
                  
                  if(!OSL_table[index].steel_bar){
                    steel_bar ="";
                  }else{
                    steel_bar = OSL_table[index].steel_bar;}

                    if(!OSL_table[index].  product_id){
                        product_id ="";
                      }else{
                        product_id = OSL_table[index].product_id;}


                  if(!OSL_table[index].start_date){
                    start_dates ="";
                  }else{
                    start_dates = OSL_table[index].start_date;}

                    if(!OSL_table[index].end_date){
                        end_dates ="";
                      }else{
                        end_dates =OSL_table[index].end_date;}

                  if(!OSL_table[index].amount){
                    amount =0;
                  }else{
                    amount = OSL_table[index].amount;}

                  if(!OSL_table[index].c_leng){
                    c_leng =0;
                  }else{
                    c_leng = OSL_table[index].c_leng;}
                  
                  if(!OSL_table[index].product_route){
                    product_route ="";
                  }else{
                  product_route = OSL_table[index].product_route;}

                  if(!OSL_table[index].mat_type){
                    mat_type ="";
                  }else{
                    mat_type = OSL_table[index].mat_type;}

           
                  
                    if(!OSL_table[index].  mat_spec){
                        mat_spec ="";
                      }else{
                        mat_spec = OSL_table[index].mat_spec;}


                        if(!OSL_table[index].mat_size){
                            mat_size ="";
                          }else{
                            mat_size = OSL_table[index].mat_size;}
                      

                        if(!OSL_table[index].material_lot){
                            mat_lot_no ="";
                            textRmatno="";
                          }else{
                            mat_lot_no = OSL_table[index].material_lot;}
                var tagnorr = "";

                            if(product_id!=OSL_table[index].product_id)
                            {
                               tagnorr = "";
                            }
                            else
                            {
                                tagnorr =truetag[index].tagno;
                            }
              
                    // min = OSL_table.sort(function(a, b){return new Date(a.dd_off_line)-new Date(b.dd_off_line)});
                    // var mindate = moment(min[0].dd_off_line).format('DD-MM-yyyy');
              
                    // var  lastdate = moment(min[0].dd_off_line).format('yyyyMMDD');
                    // console.log(mindate);

                    for (let mln of mat_lot_no)
                    {    
                         textRmatno += mln.mat_lot+",";
             
             
               
                     
                    }
                    steel_bar = Math.ceil(steel_bar);

                     thisday = moment(new Date()).format('DD/MM/yyyy');


                    //  Rmuse = ((amount*c_leng)/6000).toFixed(2);
                    //  actuse = ((Rmuse*6000)/c_leng).toFixed(2);
                    //  console.log(amount);
                    //  console.log(c_leng);
                    //  actuse = Math.ceil(actuse);

                     if (isNaN(actuse))
                     { actuse = ""}

                //   console.log(mat_size,product_route)
              }
     
            
              var oslno ="";

             
             
              if (!OSL[index]) {
          
                  oslno = "";
                  order_no= "";
             
                //   mat_lot="";
  
            
              } else {
                // if(!OSL[index].mat_lot_no){
                //     mat_lot ="";
                //   }else{
                //     mat_lot = OSL[index].mat_lot_no;}
     
                  if(!OSL[index].oslno){
                    oslno ="";
                  }else{
                    oslno = OSL[index].oslno;}
    
  
   



             
                    
              }

              var order_no ="";
              if (!order_product[index]) {
            
               
                order_no = "";
            
              } else { 
                    if(!order_product.order_no){
                    order_no ="";
                       }
                       else{
                    order_no = order_product.order_no;}
        
            }


      
        // console.log(Rmuse);

        var Qrdetail =tagnorr;
          // qr 
        QRCode2.toDataURL(Qrdetail, function (err, url) {
        img_qr = url
      })
              
// qr 
      doc.addImage(img_qr, 'JPEG', 9,46, 48,48)
     


          
    //   กรอบ

          doc.rect(1,12,208,135)
          doc.rect(70,12,0,79)
          doc.rect(140,12,0,24)
          doc.rect(1,24,208,0)
          doc.rect( 1,36,208,0)
          doc.rect(1,48,208,0)
   
          doc.rect(150,36,0,24)
          doc.rect(70,60,139,0)
     
          doc.rect(70,75,40,0)
          doc.rect(1,91,208,0)
          doc.rect(110,48,0,43)


        //   หัว
          doc.setTextColor(0, 0, 0)
 
          doc.setFont('Rocki');
          doc.setFontSize(20)
          doc.text(5,8,"Tracking Tag")

          doc.setTextColor(0, 0, 0)
     
        
          doc.setFontSize(12)
          doc.text(5,97,"Tracking Status")





          
          doc.setTextColor(0, 0, 0)
 
       
          doc.setFontSize(12)
          doc.text(3,32,"Customer Route:")

          doc.text(33,32,oem_names)



                    
          doc.setTextColor(0, 0, 0)
     
       
          doc.setFontSize(10)
          doc.text(3,40,"Product No.")
          doc.setFontSize(12)
          doc.text(33,45,product_no,"center")






          doc.setTextColor(0, 0, 0)
    
       
          doc.setFontSize(12)
          doc.text(71,20,"Date Plan:")
          doc.text(90,20, start_dates)

         





          doc.setTextColor(0, 0, 0)
        
     
          doc.setFontSize(12)
          doc.text(71,32,"Date Off Line :")
          doc.text(98,32,end_dates)

       
                          
          doc.setTextColor(0, 0, 0)
     
    
          doc.setFontSize(10)
          doc.text(71,40,"Product name:")
          doc.setFontSize(12)
          doc.text(111,45,product_name,"center")

          doc.setTextColor(0, 0, 0)
     

          doc.setFontSize(10)
          doc.text(71,52,"RM Spec.")
          //doc.text(90,56,mat_spec)
          doc.text(90,56,mat_spec,"center")


          doc.setTextColor(0, 0, 0)
  
    
          doc.setFontSize(10)
          doc.text(111,52,"RM Size.")
          doc.setFontSize(10)
          doc.text(130,56,mat_size,"center")


          
          doc.setTextColor(0, 0, 0)

      
          doc.setFontSize(12)
          doc.text(116,64,"Qty cut(pcs)")


          doc.setTextColor(0, 0, 0)
        
    
          doc.setFontSize(70)
          doc.text(127,86,numberWithCommas(osl_amount).toString())



          doc.setTextColor(0, 0, 0)
      
       
          doc.setFontSize(10)
          doc.text(71,64,"RM use(pcs)")
          doc.text(90,71,steel_bar.toString(),"center")

          doc.setTextColor(0, 0, 0)
 
       
          doc.setFontSize(10)
          doc.text(71,79,"Cut Length")
          doc.text(90,86,c_leng.toString(),"center")




          
          doc.setTextColor(0, 0, 0)
       
   
          doc.setFontSize(12)
          doc.text(141,20,"Date Input:")
          doc.text(160,20, thisday)


          
          
          doc.setTextColor(0, 0, 0)
     
 
          doc.setFontSize(12)
          doc.text(141,32,"Tracking Tag No.")
          doc.text(173,32,tagnorr)

          /* if(textRmatno.length>24){
      
            doc.setFontSize(9)
            lengtext = textRmatno.length-1;
            text1 = textRmatno.slice(0,24);
            text2 = textRmatno.slice(24,lengtext);
            doc.text(165,54,text1)
            doc.text(165,58,text2)
            textRmatno = "";}
            else if (textRmatno.length==12)
            {
            doc.setFontSize(10)
            lengtext = textRmatno.length-1;
            text2 = textRmatno.slice(0,lengtext);
            doc.text(173,56,text2)
            textRmatno = "";
            }
            else if (textRmatno.length==24)
            {
                doc.setFontSize(9)
                lengtext = textRmatno.length-1;
                text2 = textRmatno.slice((0),lengtext);
                doc.text(167,56,text2)
                textRmatno = "";
            } */



          doc.setTextColor(0, 0, 0)
          doc.setFontSize(10)
          doc.text(151,52,"Lot No.")
          if(mat_lot_no.length > 0){
            let k = 0;
        
       
            for(let mn of mat_lot_no){
                doc.setFontSize(8)
        

                /* lengtext = textRmatno.length-1;
                 text1 = textRmatno.slice(0,24);
                text2 = textRmatno.slice(24,lengtext); */
                doc.text(165, 52+k,mn.mat_lot+"    - "+mn.mat_amount);
        
                
                    k = k +3;
                
         
            }
         
        }
    

          doc.setTextColor(0, 0, 0)
    
       
          doc.setFontSize(12)
          doc.text(151,44,"OSL/PBN")
          doc.text(170,44,oslnos)





          
//  Cutting 
          doc.rect(15,109,27,9)

          doc.setTextColor(0, 0, 0)

   
          doc.setFontSize(12)
          doc.text(19,115,"CUTTING")


          doc.rect(15,118,27,9)
          doc.text(24,124,"WIP")

          doc.rect(15,127,27,9)
          doc.text(24,133,"F/G")


        //   in 
        doc.rect(60,100,40,9)

        doc.setTextColor(0, 0, 0)

    
        doc.setFontSize(12)
        doc.text(78,106,"IN")

        doc.rect(60,109,40,9)
        doc.rect(60,118,40,9)
        doc.rect(60,127,40,9)

        // out 
        doc.rect(110,100,40,9)

        doc.setTextColor(0, 0, 0)
      

        doc.setFontSize(12)
        doc.text(127,106,"OUT")

        doc.rect(110,109,40,9)
        doc.rect(110,118,40,9)
        doc.rect(110,127,40,9)

        //lost
        doc.rect(160,100,40,9)

        doc.setTextColor(0, 0, 0)


        doc.setFontSize(12)
        doc.text(177,106,"LOSS")

        doc.rect(160,109,40,9)
        doc.rect(160,118,40,9)
        doc.rect(160,127,40,9)
     
          
        //   doc.setDrawColor(192,192,192)

        }
        
        //box 2
        countsdate =countsdate+1;
       counts =index;
       dateendcount = OSL_table.length-sum.length; 
    //    console.log(OSL_table.length);
    //    console.log(sum.length);
    //    console.log(dateendcount);
    //    console.log(counts);
    //    console.log(index);
    //    console.log(countsdate);
       if ( countsdate <dateendcount )
       {
         
            doc.addPage("a5", "l")
        
         }
    
         
        }
       
    }

       window.open(doc.output('bloburl'));
       }


  
       function OSL_Report() {
        let OSL_table2 = OSL_table.filter((e)=>{
            return e.end_date;
        })

        console.log("OSL_table",OSL_table);
        console.log("OSL_table",OSL_table2);
        var counts = 0;
        const doc = new jsPDF("l", "mm", "a4");
        for (let index = 0; index < OSL_table2.length; index = index + 12) {
        doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.addFont('Rocki', 'bold');
        
        var textRmatno = "";
        var mat_lot ="";
   
       
     
        doc.setTextColor(0, 0, 0)
      
        doc.setFont('Rocki');
        doc.setFontSize(12)
        doc.text(30,9,"Current")
        doc.text(130,9,"PMRP")
        doc.text(126,19,"OSL/PBN")
        doc.text(146,19,oslnos)
      
      
        doc.text(12,17,"OSL")
        doc.text(30,17,"Oder Sumary List")
        doc.text(12,25,"PBN")
        doc.text(30,25,"Production Batch no.")
      
      
        // doc.text(68,44,"Forecast Dated")
        doc.text(68,47,"List Production for Month")
        // doc.rect(120,40, 11, 6);
        doc.rect(118, 42, 25, 7);
      
        
        
        doc.text(158,37,"Customer Route")
        doc.rect(188, 32, 25, 7);
        doc.text(167,47,"PBN")
        doc.rect(188, 42, 25, 7);
      
          
        doc.text(238,37,"Date Plan")
        doc.text(235,47,"Date Approve")
        doc.rect(263, 32, 25, 7);
      
        doc.rect(263, 42, 25, 7);
      
      
        doc.setFontSize(14)
        doc.text(15,42,"OSL/PBN Report")
      
     
      
      
        //item+20
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
          
              doc.rect(7, 52, 11, 6);
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(9, 56, "Item");
        
              doc.rect(18, 52, 22, 6); //Part No.
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(19, 56, "Product No.");
        
              doc.rect(40, 52, 35, 6); //Part NAME.
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(44, 56, "Product Name");
        
              doc.rect(75, 52, 23, 6); //QTY Target.
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(77, 56, "Qty Target");
        
              doc.rect(98, 52, 35, 6); //RRR NO
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(108,56, "RRR No.");
        
        
              doc.rect(133, 52, 23, 6); //Rm.spec
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(137, 56, "Rm Spec.");
        
        
              doc.rect(156,  52, 36, 6); //RMsize
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(168,56, "Rm Size.");
              
              doc.rect(192, 52, 23, 6); //Cut Length
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(194,56, "Cut Length");
              
              doc.rect(215, 52, 23, 6); //RMspc
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(219,56, "RM(pcs.)");
              
              doc.rect(238, 52, 26, 6); //Act Cut(pcs.)
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(240,56, "Act Cut(pcs.)");
              
              doc.rect(264, 52, 28, 6); //dd Off-Line
              doc.setTextColor(0, 0, 0);
              doc.setFontSize(12);
              doc.text(267, 56, "dd. Off-Line");
      
            var row = 0;
            var sum_amount = 0;
            var text1;
            var text2;
            var lengtext;
            
            for (let i = 0; row < 12 ; i = i + 12) {
                var steel_bar="";
                var osl_amount ;
             
            
                var oslno ="";
             
     
                var actuse="";
                var product_no;
                var amount;
        
                var osl_no;
                var product_route;
                var mat_type;
                var dts;
                var mat_spec;
                var mat_size;
                var  end_date;
              
                var thisday;
                var dd_off_line;
                var c_leng;
                var end_dates;
                var start_dates;
                var product_name;
                var mat_lot_no;
                if (!OSL_table2[index+row]) {
                    steel_bar="";
                    osl_amount  = "";
                    osl_no="";
                    mat_spec= "";
                    mat_size= "";
                      product_no = "";
                      oslno = "";
                      amount = "";
                      product_name = "";
                      end_dates= "";
                      start_dates= "";
                      dd_off_line = "";
                      product_route ="";
                      //console.log("ไม่มี :",osl_amount)
                      mat_type = "";
                      c_leng = "";
                      dts ="";
                      mat_lot_no="";
                  } else {
    
                  

                    if(!OSL_table2[index+row].product_name){
                        product_name ="";
                      }else{
                        product_name = OSL_table2[index+row].product_name;}
                  if(!OSL_table2[index+row].steel_bar){
                    steel_bar ="";
                  }else{
                    steel_bar = OSL_table2[index+row].steel_bar;
                    steel_bar = Math.ceil(steel_bar);}

                  
                    if(!OSL_table2[index+row].osl_no){
                        osl_no ="";
                      }else{
                        osl_no =OSL_table2[index+row].osl_no;}
                        // console.log(osl_no);
                    
                    if(!OSL_table2[index+row].end_date){
                        end_dates ="";
                      }else{
                        end_dates =OSL_table2[index+row].end_date;}
                      osl_amount = OSL_table2[index+row].amount;
                      product_no = OSL_table2[index+row].product_no;
                      if(!OSL_table2[index+row].amount){
                        amount =0;
                      }else{
                        amount = OSL_table2[index+row].amount;}

                        if(!OSL_table2[index+row].material_lot){
                            mat_lot_no ="";
                            textRmatno = "";
                            mat_lot = "";
                          }else{
                            mat_lot_no =OSL_table2[index+row].material_lot;
                            textRmatno = "";

                   }
                     console.log(product_no,"  ",mat_lot_no)
                if(!OSL_table2[index+row].c_leng){
                 c_leng =0;
                    }else{
                 c_leng = OSL_table2[index+row].c_leng;}
                            
                  if(!OSL_table2[index+row].mat_type){
                    mat_type ="";
                  }else{
                    mat_type = OSL_table2[index+row].mat_type;}

                    if(!OSL_table2[index+row].mat_size){
                        mat_size ="";
                      }else{
                        mat_size = OSL_table2[index+row].mat_size;}

                           
                    if(!OSL_table2[index+row].mat_spec){
                        mat_spec ="";
                      }else{
                        mat_spec = OSL_table2[index+row].mat_spec;}


                           
                    if (isNaN(actuse))
                     { actuse = "0"}
                     textRmatno ="";
                        if(mat_lot_no.length > 0){ 
                         
                            for (let mln of mat_lot_no){
                                mat_lot += mln.mat_lot+",";
                                textRmatno += mln.mat_lot+",";
                            }
                           
                        }
                 
    /*      for (let mln of mat_lot_no)
                    {    
                         textRmatno += mln.mat_lot+",";
                         mat_lot += mln.mat_lot+",";
             
               
                     
                    } */
               
                    console.log("test ",product_no, " ",mat_lot)








          
        
        
                     
                            
                      }

            
                var itemnum = row;
      
                doc.rect(7, 50 + i + 8, 11, 12); //item
               if((itemnum+1 + index)<=OSL_table2.length){
                   
                doc.text(11, 58+i+8, (itemnum+1 + index).toString());}

      
                // doc.rect(7, 57+5+ 8, 11, 15)


                doc.setFontSize(10)
                doc.rect(18, 50 + i + 8, 22, 12); //Part No.
                doc.text(29, 58+i+8,product_no,"center");
               

                if(product_name.length<17){
                doc.setFontSize(10)
                doc.rect(40, 50 + i + 8, 35, 12); //Part NAME.
                doc.text(58, 58+i+8, product_name,"center");}
                else if(product_name.length==20)
                {
                    doc.setFontSize(9)
                    doc.rect(40, 50 + i + 8, 35, 12); //Part NAME.
                    doc.text(58, 58+i+8, product_name,"center");
                }
                else{
                    doc.setFontSize(7)
                    doc.rect(40, 50 + i + 8, 35, 12); //Part NAME.
                    doc.text(58, 58+i+8, product_name,"center");
                }
                doc.setFontSize(12)
                doc.rect(75, 50 + i + 8, 23, 12); //QTY.
                doc.text(95,58+i+8, numberWithCommas(amount).toString(),"right");
       

                doc.rect(98, 50 + i + 8, 35, 12); //rrrno.
             /*    doc.setFontSize(12)
                doc.text(103, 54+i+8,textRmatno); */
                if(mat_lot_no.length > 0){
                    let k = 0;
                    let n = 1;
               
                    for(let mn of mat_lot_no){
                        doc.setFontSize(8)
                

                        /* lengtext = textRmatno.length-1;
                         text1 = textRmatno.slice(0,24);
                        text2 = textRmatno.slice(24,lengtext); */
                        doc.text(102, 54+k+i+8,mn.mat_lot+"    - "+mn.mat_amount);
                
                        
                            k = k +3;
                        
                 
                    }
                 
                }
              /*   if(textRmatno.length>24){
                doc.setFontSize(7)
                lengtext = textRmatno.length-1;
                text1 = textRmatno.slice(0,24);
                text2 = textRmatno.slice(24,lengtext);
                doc.text(103, 54+i+8,text1);
                doc.text(103, 59+i+8,text2);
               // textRmatno = "";
            }
                else if (textRmatno.length==12)
                {
                    doc.setFontSize(10)
                    lengtext = textRmatno.length-1;
                    text2 = textRmatno.slice(0,lengtext);
                    doc.text(115, 57+i+8,text2,"center");
                  
                   // textRmatno = "";
                }
                else if (textRmatno.length==24)
                {
                    doc.setFontSize(7)
                    lengtext = textRmatno.length-1;
                    text2 = textRmatno.slice((0),lengtext);
                    doc.text(103, 57+i+8,text2);
                  //  textRmatno = "";
                } */
            

                doc.rect(133, 50 + i + 8, 23, 12); //mat_spec
                doc.setFontSize(12)
                doc.text(145, 58+i+8,mat_spec,"center");

              
                doc.setFontSize(10)
                
                doc.rect(156,  50+ i +8, 36, 12); //msize
                doc.text(175, 58+i+8,mat_size,"center");
                doc.setFontSize(12)
                doc.rect(192,  50+ i + 8, 23, 12);//Clength
                doc.text(214, 58+i+8,c_leng.toString(),"right");

            
                doc.rect(215, 50 + i + 8, 23, 12); //Rm
                doc.text(237, 58+i+8,steel_bar.toString(),"right");

                // osl_amount.toString()
                doc.rect(238, 50 + i + 8, 26, 12); //Act Cut
                doc.text(263, 58+i+8,numberWithCommas(amount).toString(),"right");

                doc.rect(264, 50 + i + 8, 28, 12); //dd.ofline
                // doc.text(268, 61+i+8, end_dates);
                doc.text(278, 58+i+8, end_dates,"center");
         
         

      
              row = row+1;
              counts = OSL_table2.length-12;
             console.log(row,index, counts)
            }
            if (index <= counts)
            {  doc.addPage("l");}
       
         
          
          }
        
        window.open(doc.output("bloburl"));
      
      }
    function cancelOption(params) {
        window.location.href = "/Production_Planing/Order_Summary_Report";
    }




    const order_product_select_item = async(el) => {
        if (mode.mode == "edit") {
            const tempData = order_product_select;

            if (tempData[el.target.id].isSelect) {
                tempData[el.target.id].isSelect = false;
                tempData[el.target.id].order_no = tempData[el.target.id].order_no;
            } else {
                tempData[el.target.id].isSelect = true;
                tempData[el.target.id].order_no = tempData[el.target.id].order_no;
            }
           // console.log(tempData[el.target.id].isSelect);

            setorder_product_select(tempData)
                // console.log("edit",tempData);
        } else {
            const tempData = order_product;

            if (tempData[el.target.id].isSelect) {
                tempData[el.target.id].isSelect = false;
                tempData[el.target.id].order_no = tempData[el.target.id].order_no;
            } else {
                tempData[el.target.id].isSelect = true;
                tempData[el.target.id].order_no = tempData[el.target.id].order_no;
            }
           // console.log(tempData[el.target.id].isSelect);

            setorder_product_select(tempData)
                //  console.log("add/read",tempData);
        }

        // console.log(tempData);

        /*   let order_product_select_temp1 = order_product_select.filter((element)=>{ 
    return (element.isSelect === true);
})
setorder_product_select_temp(order_product_select_temp1); */


    }

    //state ตัว order ที่เลือกเพื่อนำไปใช้ทำ dynamic table
    const [order_product_select_temp, setorder_product_select_temp] = useState([]);

    function set_order() {
         /* let order_product_select_temp1 = order_product_select.filter((element)=>{ 
            return (element.isSelect === true);
        })
        setorder_product_select_temp(order_product_select_temp1);
        console.log(order_product_select_temp); */
        total_work_hours = parseFloat(0);
        total_work_pieces = parseInt(0);
        if (mode.mode === "add" || mode.mode === "edit") {
            GetOSL_table();
        } else {

        }
   

    }




    const rowsData_order = [];


    if (mode.mode == "add") {
        for (var index = 0; index < order_product.length; index++) {
            const rowItem = {};
            //console.log(order_product_select[1].isSelect);
            if (order_product[index].is_active == true) {

                rowItem["select"] = ( 
                    <div className = "row" >
                    <div className = "col-4" >
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                    <input className = "form-check-input"
                    type = "checkbox"
                    id = { index }
                    value = { order_product.id }
                    defaultChecked = { order_product_select.isSelect }
                    onChange = { order_product_select_item.bind(this) }
                    disabled = { mode.mode === "read" ? true : false }
                    //onClick={matchMat_prod.bind(this, materail[index])}
                    /> 
                    </div> 
                    </div>
                );

                rowItem["no"] = index + 1;
                rowItem["order_no"] = order_product[index].order_no;
                if (order_product[index].is_active == true) {
                    rowItem["status"] = "OK";
                } else {
                    rowItem["status"] = "CANCLE";
                }

                rowsData_order.push(rowItem);
            }
        }

    } else {
        for (var index = 0; index < order_product_select.length; index++) {
            const rowItem = {};
            if (order_product[index].is_active == true) {
                //console.log(order_product_select[1].isSelect);
                let checked = order_product_select[index].isSelect || false;

                rowItem["select"] = ( 
                    <div className = "row" >
                    <div className = "col-4" >
                    <span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                    <input className = "form-check-input"
                    type = "checkbox"
                    id = { index }
                    disabled = { mode.mode === "read" ? true : false }
                    value = { order_product_select.id }
                    defaultChecked = { checked }
                    onChange = { order_product_select_item.bind(this) }


                    /> 
                    </div> 
                    </div>
                );

               /*  rowItem["no"] = index + 1; */
                rowItem["order_no"] = order_product[index].order_no;
                /* if (order_product[index].is_active == true) {
                    rowItem["status"] = "OK";
                } else {
                    rowItem["status"] = "CANCLE";
                } */

                rowsData_order.push(rowItem);
            }
        }
    }

    const data_order = {
        columns: [{
                label: "Select",
                field: "select",
                sort: "asc",
                width: 50,
            },
           /*  {
                label: "No",
                field: "no",
                sort: "asc",
                width: 50,
            }, */
            {
                label: "Order No",
                field: "order_no",
                sort: "asc",
                width: 50,
            },
            /* {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 50,
            }, */

        ],
        rows: rowsData_order,
    };



    const process_select_item = async(el) => {

        const tempData = process_select;
        setprocess_select([]);
        if (tempData[el.target.id].isSelect) {
            tempData[el.target.id].isSelect = false;
            //tempData[el.target.id].process_name = el.process_name;
        } else {
            tempData[el.target.id].isSelect = true;
            // tempData[el.target.id].process_name = el.process_name;
        }
       console.log(tempData[el.target.id].isSelect);


        setprocess_select(tempData);
        settest({...test,test:""});
        test.test = "";

    }

    const machine_select_item = async(el) => {

        const tempData = machine;
        let index = tempData.findIndex((e)=>{return e.id === el.target.id})
        if (tempData[index].isSelect) {
            tempData[index].isSelect = false;
            //tempData[el.target.id].process_name = el.process_name;
        } else {
            tempData[index].isSelect = true;
            // tempData[el.target.id].process_name = el.process_name;
        }
       // console.log(tempData[el.target.id].isSelect);
        let test = tempData.filter((e)=>{return e.isSelect === true})
        //console.log(test)
        setmachine_select(test);
        setnumberline({
            ...numberline,
            number_line: test.length,
        });
        numberline.number_line = test.length;
        setmachine(tempData);

    }

    //state ตัว process ที่เลือกเพื่อนำไปใช้ทำ dynamic table
    const [process_select_temp, setprocess_select_temp] = useState([]);

    function set_process() {
        /*    let process_select_temp1 = process_select.filter((element)=>{ 
              return (element.isSelect === true);
          }) */
        /*   setprocess_select_temp(process_select_temp1);
          console.log(process_select_temp); */

        total_work_hours = parseFloat(0);
        total_work_pieces = parseInt(0);
        if (mode.mode === "add" || mode.mode === "edit") {
            GetOSL_table();
        } else {

        }


    }

    function set_machine() {
        /*    let process_select_temp1 = process_select.filter((element)=>{ 
              return (element.isSelect === true);
          }) */
        /*   setprocess_select_temp(process_select_temp1);
          console.log(process_select_temp); */

        total_work_hours = parseFloat(0);
        total_work_pieces = parseInt(0);
        if (mode.mode === "add" || mode.mode === "edit") {
            GetOSL_table();
        } else {

        }


    }

 
    const process_radio = [];
    for (var index = 0; index < process.length; index++) {
        var process_r = ( 
            <div className = "col-3" >
            <div className = "form-check" >
            <input className = "form-check-input"
            type = "checkbox"
            name = { process[index].process_name }
            id = { process[index].process_name }
            value = { process[index].id }
            /> 
            <label className = "form-check-label"
            for = { process[index].process_name } > { process[index].process_name } 
            </label> 
            </div> 
            </div>
        );
        process_radio.push(process_r);
    }

    function reset_value() {
        document.location.reload(true);
    }

    function message_order() {
        /* if(){

        } */
        /*  Swal.fire({
           title: "Are you sure?",
           text: "not found old order no.",
           icon: "warning",
           showCancelButton: true,
           confirmButtonColor: "#3085d6",
           cancelButtonColor: "#d33",
           confirmButtonText: "Yes, cancle order it!",
         }) */
    }

    function autoSelect_machine(){
        var tempdata = machine;
        setmachine([]);
       
        for(let i of tempdata){
            let check = have_machine.filter((e2)=>{
                return e2.id === i.id;
            })
           i.isSelect = false;
            if(check.length > 0){
                i.isSelect = true;  
         
            }
    
         }  

  
        let test = tempdata.filter((e)=>{return e.isSelect === true})
     
        setmachine_select(test);
        setnumberline({
            ...numberline,
            number_line: test.length,
        });
        numberline.number_line = test.length;
    // setmachine(...machine);
     setmachine(tempdata);
    }

    function ClearautoSelect_machine(){
        var tempdata = machine;
        setmachine([]);
       
        for(let i of tempdata){

           i.isSelect = false;
          
    
         }  

  
        let test = tempdata.filter((e)=>{return e.isSelect === true})
     
        setmachine_select(test);
        setnumberline({
            ...numberline,
            number_line: test.length,
        });
        numberline.number_line = test.length;
    // setmachine(...machine);
     setmachine(tempdata);
    }

  
    function autoSelect_process(){
        var tempdata = process_select;
        setprocess_select([]);
       
        for(let i of tempdata){
            let check = have_process.filter((e2)=>{
                return e2.process_id === i.id;
            })
           i.isSelect = false;
            if(check.length > 0){
                i.isSelect = true;  
         
            }
    
         }  
  
        //let test = tempdata.filter((e)=>{return e.isSelect === true})
    
        setprocess_select(tempdata);
        settest({...test,test:""});
        test.test = "";
    }

    function ClearautoSelect_process(){
        var tempdata = process_select;
        setprocess_select([]);
       
        for(let i of tempdata){

           i.isSelect = false;
          
    
         }  
        
   
    setprocess_select(tempdata);
    settest({...test,test:""});
    test.test = "";
    }

    return ( 
        <div className = "wrapper" >
        {/* <Header />
        <Sidebar menu = "pp"
        activemenu = "osr"
        submenu = "none" /> */}
        <div className = "content-wrapper" >
        <section className = "content-header" >
        <div className = "container-fluid" >
        <div className = "row mb-2" >
        <div className = "col-sm-6" >
        <h1>
        Order Summary Report(OSL) { mode.mode } / OSL : {OSL_NO}
        {"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
        {/* <h1>OSL : {OSL_NO}</h1> */}
        </div> 
        <div className = "col-sm-6" >
        <ol className = "breadcrumb float-sm-right" >
        <li className = "breadcrumb-item" >
        <a href = "#" >Home</a> 
        </li> 
        <li className = "breadcrumb-item active" >
        Order Summary Report(OSL) 
        </li>
        </ol> 
        </div> 
        </div>

        <div className = "container-fluid" > {
            mode.mode === "read" ? ( 
                <div className = "row mb-2" >
                <div className = "col-sm-1" >
                <button type = "button"
                onClick = { cancelOption }
                class = "btn btn-block btn-danger " >
                Cancel 
                </button> 
                </div> 
                </div>
            ) : ( 
                <div className = "row mb-2" >
                <div className = "col-sm-1" >
                <button type = "button"
                onClick = { saveOption }
                class = "btn btn-block btn-success" >
                Save 
                </button> 
                </div> 
                <div className = "col-sm-2" >
                <button type = "button"
                onClick = { saveDraftOption }
                class = "btn btn-block btn-info" >
                Save Draft
                </button> 
                </div> 
                <div className = "col-sm-1" >
                <button type = "button"
                onClick = { cancelOption }
                class = "btn btn-block btn-danger " >
                Cancel 
                </button> 
                </div> 
                </div>
            )
        } 
        </div> 
        </div> 
        </section> 
        <section class = "content" >
        <div class = "container-fluid" >
        <div className = "row" >
        <div className = "col-md-12" >
        <div className = "card" >
        <div className = "card-header" >
        <div className = "row">
        <div className = "col-md-8" >
        <div className = "row" >  {
            mode.mode === "edit" ? ( 
                <div className = "col-sm-3" >
                <div className = "form-group" >
                <button type = "button"
                class = "btn btn-block btn-info "

                onClick = { reset_value } >
                Reset Value 
                </button> 
                </div> 
                </div>
            ) : ( 
                <div className = "col-sm-3" style = {{ display: "none" }}>
                <div className = "form-group">
                <button type = "button"
                class = "btn btn-block btn-info "

                //onClick={form.bind(this, order_product[index].id,order_product[index].no)}
                >
                Reset Value </button> </div> </div>
            )
        } {/* {
            mode.mode === "read" ? ( 
                <div className = "col-sm-3"style = {{ display: "none" }} >
                <div className = "form-group" >
                <button type = "button"
                class = "btn btn-block btn-info "
                data-toggle = "modal"
                data-target = "#modal-load"
                // onClick={form.bind(this, order_product[index].id,order_product[index].no)}
                >
                Manual Osl 
                </button> 
                </div> 
                </div>
            ) : (  */}
                <div className = "col-sm-3" >
                <div className = "form-group" >
                <button type = "button"
                class = "btn btn-block btn-info "
                data-toggle = "modal"
                data-target = "#modal-load"
                onClick = {message_order} >
                Manual Osl 
                </button> </div> </div>
    {/*         )
        }  */}
        <div className = "col-sm-3" >
        <div className = "form-group" >

        <a data-toggle = "modal"
        data-target = "#modal-process-line" >
        <button type = "button"
        class = "btn btn-block btn-info" >
        Select Process 
        </button> 
        </a> 
        </div> 
        </div> 
       
        {feature.map((e)=>{
            if(e.feature === "Grant View"){
                return (
<div className = "col-sm-3" >
        <div className = "form-group" >
        {remain_hrs <= 0 || err_max.length > 0? (
        <button type = "button"
        class = "btn btn-block btn-info"
 
        onClick = {grantButton}
        >
        Grant View 
        </button> 
        ) : (
            <button type = "button"
            class = "btn btn-block btn-info"
            data-toggle = "modal"
            data-target = "#modal-grand_view"
            onClick = {GetDay_off_detail}
            >
            Grant View 
            </button> 
          )} 
        </div> 
        </div> 
                );
            }

            if(e.feature === "Report OSL" && (mode.mode === "read" || mode.mode === "edit")){
                return (
                    <>
                    <div className = "col-sm-3" >
        <div className = "form-group" >
        <button type = "button"
        class = "btn btn-block btn-info "
        onClick = {OSL_Report} >
        Report OSL 
        </button> 
        </div> 
        </div>
        <div className = "col-sm-3" >
        <div className = "form-group" >
        <button type = "button"
        class = "btn btn-block btn-info "
        onClick = {truereport} >
        Report Tracking
        </button> 
        </div> 
        </div>
        </>
                );
            }
        })}
        
        {mode.mode === "read" ? (
           <></>
        ):(
            <div className = "col-sm-3" >
        <div className = "form-group" >
        <button type = "button"
        class = "btn btn-block btn-info "
        data-toggle = "modal"
        data-target = "#modal-FactoryWorkHour"
        
        >
        Factory Work Hour
        </button> 
        </div> 
        </div>
        )}
        



        </div>


        <div className = "row" >
        <div className = "col-0.5" >
        <label > Start Date: </label> 
        </div>
        <div className = "col-3" >
        <DatePicker selected = {calender_data.start_date}
        dateFormat = {"dd-MM-yyyy"}

        onChange = {
            (date) =>
            SetDate_and_GetDayoff_start(date)
            
        }

        selectsStart startDate = {calender_data.start_date}
        endDate = {calender_data.end_date}
        customInput = {<CustomInput/>}
        disabled = {mode.mode === "read" ? true : false }
        /> 
        </div>

        <div className = "col-0.5" >
        <label> End Date: </label> 
        </div> 
        <div className = "col-3" >
        <DatePicker selected = {calender_data.end_date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
            (date) =>
            SetDate_and_GetDayoff_end(date)
            
        }
 


        selectsEnd startDate = { calender_data.start_date }
        endDate = { calender_data.end_date }
        minDate = { calender_data.start_date }
        customInput = { <CustomInput/> }
        disabled = { mode.mode === "read" ? true : false } >
        </DatePicker> 
        </div>

        </div>




        <div className = "modal fade" id = "modal-process-line" >
        <div className = "modal-dialog modal-xl" >
        <div className = "modal-content" >

        <div className = "modal-body" >

        <div className = "row" >

        <h3> Select Process </h3> 
        <div className = "col-4"></div> 
        </div> 
        <div className = "row">
        <div className = "col-10" >
        <label> Process</label> 
        </div> 
        <div className = "col-2" >
        <button type = "button"
 className = "btn btn-primary"
 onClick = {autoSelect_process}
 disabled = { mode.mode === "read" ? true : false } 
 >
 Auto Select 
 </button> 
 {" "}
 <button type = "button"
 className = "btn btn-primary"
 onClick = {ClearautoSelect_process}
 disabled = { mode.mode === "read" ? true : false }
 >
 clear
 </button> 
 </div>

 
   
        {section.map((e)=>{
            // console.log(process_select)
         
            return (<>
            
          
        <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
            <tr>
                <td style = {{padding: "20px"}}>
                <div className="row">
                <div className = "col-12" >
        <label>{e.section_name}</label> 
        </div> 
                {
            process_select.map((el, index) => {
              
                let color_p = "#DC143C";
               // console.log(have_process);
               if(mode.mode == "read"){
                color_p = "#000";
            }
                var check = have_process.filter((e2)=>{return e2.process_id === el.id});
                if(check.length > 0){
                    color_p = "#006600";
                }
                    if(el.section_id === e.id){
                        return ( 
                           
                            <div className = "col-2" >
                            <div className = "form-check" >
                            <input className = "form-check-input"
                            type = "checkbox"
                            id = { index }
                            name = { el.process_name }
                            value = { el.id }
                            checked = { el.isSelect }
                            onChange = { process_select_item.bind(this) }
                            disabled = { mode.mode === "read" ? true : false }
                            /> 
                            <label className = "form-check-label"
                            for = { index } >
                          <font color ={color_p}>{el.process_name} </font>
                            </label> 
                            </div> 
                            </div>
                      
                        );
                    }
                


            })
        }
              </div>
                </td>
            </tr>
        </table>
            
            </>);
        })}
       


        </div> 
        </div> 
        <div className = "modal-footer justify-content-center" >

        <a data-toggle = "modal"
        data-target = "#modal-xl" >
 
 <button type = "button"
 className = "btn btn-primary"
 
 onClick = {set_process}
 data-dismiss = "modal"
 aria-label = "Close" >
 OK 
 </button> 
           
       
        </a> 
        </div> 
        </div> 
        </div> 
        </div>



        <div className = "modal fade" id = "modal-machine" >
        <div className = "modal-dialog modal-xl" >
        <div className = "modal-content" >

        <div className = "modal-body" >

        <div className = "row" >

        <h3> Select Machine </h3> 
        <div className = "col-4"></div> 
        </div> 
        <div className = "row">
        <div className = "col-10" >
        <label> Select Machine</label> 
        </div> 
        <div className = "col-2" >
        <button type = "button"
 className = "btn btn-primary"
 onClick = {autoSelect_machine}
 disabled = { mode.mode === "read" ? true : false } 
 >
 Auto Select 
 </button> 
 {" "}
 <button type = "button"
 className = "btn btn-primary"
 onClick = {ClearautoSelect_machine}
 disabled = { mode.mode === "read" ? true : false } 
 >
 clear
 </button> 
 </div>


        {machine.map((e,index)=>{
           // console.log(e.isSelect);
            let check = have_machine.filter((e2)=>{
                return e2.id === e.id;
            })
            let color_m = "#DC143C";
            if(check.length > 0){
                color_m = "#006600";
            }
               
                return (<>
                    <div className = "col-2" >
                                   <div className = "form-check" >
                                   <input className = "form-check-input"
                                   type = "checkbox"
                                   id = { e.id }
                                   name = { index }
                                   value = { e.id }
                                   checked = { e.isSelect }
                                   onClick = { machine_select_item.bind(this) }
                                   disabled = { mode.mode === "read" ? true : false }
                                   /> 
                                   <label className = "form-check-label"
                                   for = { e.id } >
                                   <font color ={color_m}>{e.machine_name}</font>
                                   </label> 
                                   </div> 
                                   </div>
                 
                   
                   </>);
         
            
        })}
       


        </div> 
        </div> 
        <div className = "modal-footer justify-content-center" >

        <a data-toggle = "modal"
        data-target = "#modal-xl" >
 
 <button type = "button"
 className = "btn btn-primary"
 
 onClick = {set_machine}
 data-dismiss = "modal"
 aria-label = "Close" >
 OK 
 </button> 
           
       
        </a> 
        </div> 
        </div> 
        </div> 
        </div>





        <br/>
        <div className = "row" >
        <div className = "col-3" >
        <div className = "form-group" >
        <NumberFormat format = "###"
        type = "text"
        className = "form-control"
        required = "false"
       
        value = { machine_select.length}
        onChange = {
            (e) => {
                setnumberline({
                    ...numberline,
                    number_line: e.target.value,
                });
            }
        }
        disabled = {true}
        /> 
        <label htmlFor = "" >Number of Line</label> 
        </div>
        </div>

        <div className = "col-sm-3" >
        <div className = "form-group" >
        <button type = "button"
            class = "btn btn-block btn-info"
            data-toggle = "modal"
            data-target = "#modal-machine"
            onClick = {GetDay_off_detail}
            >
            Select Machine 
            </button> 
            </div> 
            </div>

        </div>

        <div className = "row" >

        <div className = "col-sm-2" >
        <div className = "form-group" >
        <label htmlFor = "" >Total Day</label> 
        </div> 
        </div> 
        <div className = "col-sm-2" >
        <div className = "form-group" >
        <label htmlFor = "" >Working hours</label> 
        </div> 
        </div> 
        
        </div> 
        <div className = "row" >
        <div className = "col-sm-2" >
        <div className = "form-group" >
        <input type = "text"
        disabled value = { day_off.total_day }
        className = "form-control"
        required = "false" 
        />
        </div> 
        </div> 
        <div className = "col-sm-2" >
        <div className = "form-group" >
        <input type = "text"
        disabled value = { (day_off.total_hour/day_off.total_day).toFixed(2) || "" }
        className = "form-control"
        required = "false" 
        />
        </div> 
        </div> 
       

      
        <div className = "col-sm-3" >
        <div className = "form-group" >
        <input type = "text"
        disabled className = "form-control"
        required = "false"
        value = { total_work_pieces.toLocaleString("en-US") }
        /> 
        <label htmlFor = "" >Total Work Pieces</label> 
        </div> 
        </div> 
        <div className = "col-sm-3" >
       {/*  <div className = "form-group" >
        <input type = "text"
        disabled className = "form-control"
        required = "false"
        value = { target_use_hrs.toFixed(2) + " %" }
        /> 
        <label htmlFor = "" >% tagc use hrs</label> 
        </div>  */}
        </div> 
        </div> 
        <div className = "row" >

        <div className = "col-sm-3" >
        <div className = "form-group" >
        <input type = "text"
        disabled className = "form-control"
        required = "false"
        value = { day_off.total_hour }
        /> 
        <label htmlFor = "" >Total Working Hours Available</label> 
        </div> 
        </div> 
        

        
        <div className = "col-sm-3" >
        <div className = "form-group" >
        <input type = "text"
        disabled className = "form-control"
        required = "false"
        value = { total_work_hours2 }
        /> 
        <label htmlFor = "" > Total Work Hours </label> 
        </div> 
        </div> 
        <div className = "col-sm-3" >
       {/*  <div className = "form-group" >
        <input type = "text"
        disabled className = "form-control"
        required = "false"
        value = { remain_hrs.toFixed(2) + " %" }
        />
        <label htmlFor = "" >% Remain</label> 
        </div>  */}
        </div> 

        <div className = "col-sm-3" >
        <div className = "form-group" >
        <button type = "button"
                onClick = { cal_plan }
                disabled = {mode.mode === "read" ? true:false}
                class = "btn btn-block btn-primary" >
                    Calculate Plan
      </button>
        </div> 
        </div> 
        </div>

        </div>
        <div className = "col-md-4">

            <div className="table-responsive" style={{ height: "300px"}}>

 
        <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
            <thead style = {{position: "sticky", top: "0" ,"z-index": "1"}}>
          
            <tr>
                <td colSpan ={2 + td24hr_2.length}
                style = {
                    {  "padding-left":"25px","padding-right":"25px", border: "1px solid #ddd",backgroundColor:"#B0E0E6", textAlign: "center" } }
                >Cap tagc use view</td>
            </tr>
        <tr>
        <th style = {
            {  "padding-left":"25px","padding-right":"25px", border: "1px solid #ddd",backgroundColor:"#B0C4DE", textAlign: "center" } }
        rowSpan = "1"
        colSpan = "2" >

        { /*  Week {e.week} */ }
        Machine


        </th>

        <th colSpan = {td24hr_2.length} style = {
            { "padding-left":"25px","padding-right":"25px",backgroundColor: "#B0C4DE", textAlign: "center", border: "1px solid #ddd" } } >%tagc use</th >

      


        </tr>


        

       
       

        </thead>
        {
            machine_select.map((e2, index) => {
 
                return ( 
                    <>

                    <tr>
                    <td style = {{ border: "1px solid #ddd" }} colSpan = "2" rowSpan = "1" >{ e2.machine_name } </td>

                   
        

                    {tagc_use_data.map((element4, index2) => {

                
                           
                            if (tagc_use_data[index2].line === line[index].line) {
                                var tagc_use_per = ((element4.length / td24hr_2.length) *100).toFixed(2);
                                var color = "#ADFF2F";
                                if (30 < tagc_use_per && tagc_use_per < 76){
                                    color = "#FFFF00"
                                }else if (tagc_use_per > 75 && tagc_use_per < 101){
                                    color = "#FF0000"
                                }else if(tagc_use_per > 100){
                                    color = "#7B68EE"
                                }
                                return ( 
                                    <>
                                    <td colSpan = {element4.length} style = {{ border: "1px solid #000",backgroundColor: color }}>
                                      
                                            {tagc_use_per} %
                                    </td> 
                                    </>
                                );
                            }


                        })
                    }




                    </tr>

                   

                   
                    <tr>


<td style = {{backgroundColor:"#ddd"}} ></td> 
{
      td24hr_2.map((e3,i) => {
        return ( 
            <>
            <td style = {{ backgroundColor:"#ddd"}}></td>

            </>
        );
    })
}

</tr>
                    </>
                );
            })
        } 
        </table>
  

           
          
            </div>
        </div>
        </div>
   
        </div>


       
        <div className = "card-body table-responsive " /*  style={{ height: "100%" }} */ >
        <MDBDataTable className = "table table-head-fixed"
        striped id = "table-to-xls"
        sortable = {false}
        bordered hover fixedHeader data = { data }
        />

        

        </div> 
        </div> 
        </div>

        <div className = "modal fade"id = "modal-load" >
        <div className = "modal-dialog modal-xl" >
        <div className = "modal-content" >

        <div className = "modal-body" >

        <div className = "row" >

        <h3> SELECT ORDER </h3>
        <div className = "col-4" > </div> 
        </div> 
        <div className = "row table-responsive"   style={{ height: "500px" }}>
        <MDBDataTable className = "table table-head-fixed"
        entries={4098}
        sortable = {false}
        striped id = "table-to-xls"
        bordered hover fixedHeader data = { data_order }
        /> 
        </div> 
        </div> 
        <div className = "modal-footer justify-content-center" >

        <a data-toggle = "modal"
        data-target = "#modal-xl" >
               
        <button type = "button"
        className = "btn btn-primary"
        onClick = { set_order }
        data-dismiss = "modal"
        aria-label = "Close" >
        OK 
        </button> 
              
        </a> 
        </div> 
        </div> 
        </div> 
        </div>

        </div> 
        </div> 
        </section> 
        </div>




        <div className = "modal fade" id = "modal-grand_view" >
        <div className = "modal-dialog modal-xl" >
        <div className = "modal-content" >
        <div className = "modal-header" >
        <h4 className = "modal-title" >Grand Chart OSL</h4> 
        <button type = "button"
        className = "close"
        data-dismiss = "modal"
        //onClick={set_bomlist_excel}
        aria-label = "Close" >
        <span aria-hidden = "true" >×</span> 
        </button> 
        </div> 
        <div className = "modal-body" >
        <div className = "row" >
        <div className = "col-12 " >
        <div className = "none-nolmalinput" >
        <label htmlFor = "" > Product Planning </label>


        </div> 
        </div> 
        </div> 
        <br/>

        <div className = "row" >

        <div className = "col-12" >
        
 
        <div className="table-responsive" style={{ height: "500px"}}>

 
        <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
            <thead style = {{position: "sticky", top: "0" ,"z-index": "1"}}>
        <tr>
        <th style = {
            {  "padding-left":"25px","padding-right":"25px", border: "1px solid #ddd",backgroundColor:"#B0C4DE", textAlign: "center" } }
        rowSpan = "3"
        colSpan = "2" >

        { /*  Week {e.week} */ }
        OSL Planning


        </th>

        <th style = {
            { "padding-left":"25px","padding-right":"25px",backgroundColor: "black", textAlign: "center", border: "1px solid #ddd" } } > <font color = "#ffffff" >Month:</font></th >

        {
            week_stock2.map((e) => {
                return ( 
                    <>
                    <th style = {
                        {textAlign: "center", backgroundColor: "#FFE4E1", border: "1px solid #ddd" } }
                    colSpan = {parseInt(e.day_normal) + parseInt(e.day_ot) + parseInt(e.night_normal) + parseInt(e.night_ot) } > { moment((e.day_start)).format('MMMM') } 
                    </th>

                    </>

                );
            })
        }



        </tr>


        <tr>


        <th style = {{ backgroundColor: "black", textAlign: "center", border: "1px solid #ddd" }} > <font color = "#ffffff" > Date: </font></th>

        {week_stock2.map((e) => {
                    var col_d = parseInt(e.day_normal) + parseInt(e.day_ot);
                    var col_D = parseInt(e.night_normal) + parseInt(e.night_ot);
                    var col1 = col_d;
                    var col2 = col_D;
                    if(col_D == 0){
                        col1 = Math.ceil(col_d/2);
                        col2 =  Math.floor(col_d/2);



                        
                    }
                return ( 
                    <>
                    <th style = {{ "padding-left":"180px","padding-right":"180px",textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }} colSpan = {col1} >{moment((e.day_start)).format('ddd')}</th>
                    <th style = {{ "padding-left":"180px","padding-right":"180px",textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }} colSpan = {col2} >{moment((e.day_start)).format('DD')}</th> 
                    </>

                );
            })
        }


        </tr> 
        <tr>

        <th style = {{ backgroundColor: "black", textAlign: "center", border: "1px solid #ddd" }}><font color = "#ffffff" >day / night:</font></th>

        {
            week_stock2.map((e) => {
                return ( 
                    <>
                    <th style = {{ textAlign: "center", backgroundColor: "#FFFFE0", border: "1px solid #ddd" }} colSpan = {parseInt(e.day_normal) + parseInt(e.day_ot)} >Day ({parseInt(e.day_normal) + parseInt(e.day_ot)} hr)</th> 
                    
                    {parseInt(e.night_normal) + parseInt(e.night_ot) == 0 ? (<></>):(<><th style = {{ textAlign: "center", backgroundColor: "#E6E6FA", border: "1px solid #ddd" }} colSpan = {parseInt(e.night_normal) + parseInt(e.night_ot)}>Night ({parseInt(e.night_normal) + parseInt(e.night_ot)} hr)</th></>)}
         

                </>

                );
            })
        }


        </tr>

        </thead>
        {
            machine_select.map((e2, index) => {

      
                return ( 
                    <>

                    <tr>
                    <td style = {{ border: "1px solid #ddd" }} colSpan = "2" rowSpan = "4" >{ e2.machine_name } </td>

                    <td style = {{ border: "1px solid #ddd" }} >Plan</td>
        

                    {all_td_process.map((element4, index2) => {

                      

                            if (all_td_process[index2].line === line[index].line) {


                                return ( 
                                    <>
                                    <td colSpan = {element4.length} style = {{ border: "1px solid #000",backgroundColor: element4.color }}>
                                        {element4.product_no}&nbsp;({element4.length} hr)

                                    </td> 
                                    </>
                                );
                            }


                        })
                    }




                    </tr>

                    <tr>


                    <td style = {{backgroundColor:"#ddd"}} ></td> 
                    {
                          td24hr_2.map((e3,i) => {
                            return ( 
                                <>
                                <td style = {{ backgroundColor:"#ddd",border: "1px solid #fff"}}></td>

                                </>
                            );
                        })
                    }

                    </tr>

                    <tr>
                    <td style = {{ border: "1px solid #ddd"}} >Actual</td> 
                    {
                         all_td_process_actual.map((element4, index2) => {

                      

                            if (element4.line === line[index].line) {

                                if(element4.actual_time > 0){
                                    return ( 
                                        <>
                                        <td colSpan = {element4.actual_time} style = {{border: "1px solid red", backgroundColor: element4.color }}>
                                            <font color = "red">{element4.product_no}{" "}({element4.actual_time_show})</font>
    
                                        </td> 
                                        </>
                                    );
                                }
                                
                            }


                        })
                 
                      
                    }


                    </tr> 
                    <tr>


<td style = {{backgroundColor:"#ddd"}} ></td> 
{
      td24hr_2.map((e3,i) => {
        return ( 
            <>
            <td style = {{ backgroundColor:"#ddd"}}></td>

            </>
        );
    })
}

</tr>
                    </>
                );
            })
        } 
        </table>
  

           
          
            </div>


        </div> 
        </div>

        </div> 
        <div className = "modal-footer justify-content-between" >
        <button type = "button"
        className = "btn btn-default"
        data-dismiss = "modal"
        >
        Close 
        </button> 
        </div> 
        </div>
         </div> 
        </div>




        <div className="modal fade" id="modal-editActual">
        <div className="modal-dialog modal-sm">

          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Actual time :  {openform.no} </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
           
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 ">
                  <div className="none-nolmalinput">

                  <div className = "form-group" >
        <NumberFormat 
        allowNegative = {false}
        //format = "################"
        type = "text"
        className = "form-control"
        required = "false"
        value = { openform.actual }
        onChange = {
            (e) => {
                setopenform({
                    ...openform,
                    actual: e.target.value,
                });
            }
        }
        //disabled = { mode.mode === "read" ? true : false }
        /> 
        <label htmlFor = "" >Actual times</label> 
        </div> 
                   
                  </div>
                </div>
              </div>
       
       
          
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={set_actual_time}
              >
                Save
              </button>
            
            </div>
          </div>
          </div>
      </div>




      <div className="modal fade" id="modal-FactoryWorkHour">
        <div className="modal-dialog modal-xl">

          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Factory Work Hour</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
           
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              


                <FactoryWorkHour mode = "popup" fnc = {GetDay_off}></FactoryWorkHour>




       
       
          
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                //onClick={set_actual_time}
              >
                Close
              </button>
            
            </div>
          </div>
          </div>
      </div>
<div style = {{display:"none"}}>
<ReactHTMLTableToExcel id='test-table-xls-button' className='btn btn-block btn-primary' table='table_err-to-xls' filename='Err_OSL_report' sheet='tablexls' buttonText='Download as XLS' />
    </div>
     



        </div>
    );
}
export default OrderSummaryReport_add;