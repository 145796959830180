import React, { Component, useEffect, useState ,useRef} from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from  'sort-json-array';
import Sidebar from "../../component/Sidebar";
import DatePicker from "react-datepicker";
import axios from "axios";
import QRCode from 'qrcode.react';
import Configs from "../../config";
import { getOem, getToken,getUser,getAcademy } from "../../Utils/Common";
import { userdefault_img,logoTTT } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import Select from "react-select";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Delivery_Time_Record(params) {

  const selectCompanyGroup = useRef(); 
  const CustomInput = ({ value, onClick }) => ( 
      
    <
    div className = "input-group" >
    < input type = "text"
    className = "form-control float-left"
    onClick = { onClick }
    value = { value }
    /> <div className = "input-group-prepend" >
    <span className = "input-group-text" >
    <i className = "far fa-calendar-alt" />
    </span>
  
    { /*  <label>test</label> */ } 
    </div> 
    </div>
  
  
  )

  var startdate = moment(new Date()).startOf('m');
  var enddate = moment(new Date()).endOf('m');
/*   console.log(new Date(startdate),new Date(enddate)) */
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [filterDelivery,setfilterDelivery] = useState({
    oem_id: getOem(),
    company_id:getUser().com,
    start_date: new Date(startdate),
    end_date: new Date(enddate),
    plate_number: "",
    bill_no:"",
  })

  const [delivery_record,setdelivery_record] = useState([]);

  const [companyGroup, setCompanyGroup] = useState([]);
  const [bill_detail,setbill_detail] = useState({
    bill_no:"",
    bill_oem_id:"",
    bill_values:"",
    bill_destination:"",
    create_bill:"",
    create_time:"",
    delivery_bill:"",
    delivery_time:"",
    customer_name:"",
    weight:"",
    remark:"",
    status_bill:"",

  });
 

/*   useEffect(() => {
    const script = document.createElement("script");

    script.src = `js/Product`;
    document.body.appendChild(script);
  }, []);
 */


  const GetDeliveryTimeRecord = async () => {
    var temp = {
      oem_id: filterDelivery.oem_id,
      company_id:filterDelivery.company_id,
      start_date:filterDelivery.start_date,
      end_date:filterDelivery.end_date,
      plate_number: filterDelivery.plate_number.trim(),
      bill_no:filterDelivery.bill_no.trim(),
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/delivery_time_record/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        console.log("GetDatess",response.data);
        //console.log("ChecgkGetDatess",response.data[0].bi_list);
        setdelivery_record(response.data)
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  

  useEffect(() => {
    GetDeliveryTimeRecord();
    axios(config_getOem)
    .then(function (response) {
     
      const options = [];
      var element2 = {};
      element2["value"] = "";
      element2["label"] = "---ทั้งหมด---";
      options.push(element2);
      response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.name;
        options.push(element);
        if (el.id === getOem()) {
          setCurrentOEM({ label: el.name, value: el.id });
        }
      });
     
      console.log(options);
      setCompanyGroup(options);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, []);

  function formBillDetail(data_detail,e){
    console.log("data_detail",data_detail)
    setbill_detail({...bill_detail,
      bill_no:data_detail.bill_no,
      bill_oem_id:data_detail.bill_oem_id,
      bill_values:data_detail.bill_values,
      bill_destination: data_detail.bill_destination,
      create_bill:data_detail.create_bill,
      create_time:data_detail.create_time,
      delivery_bill:data_detail.delivery_bill,
      delivery_time:data_detail.delivery_time,
      customer_name:data_detail.customer_name,
      weight:data_detail.weight,
      remark:data_detail.remark,
      status_bill:data_detail.is_pack === true ? "จัดแล้ว":"ยังไม่ได้จัด",
      latitude:data_detail.latitude,
      longitude:data_detail.longitude,
      remark:data_detail.remark,
      weight_meter:data_detail.weight_meter,
      compressive_strength:data_detail.compressive_strength,


  
    
    })
  }

  const rowsData = [];


  for (let index = 0; index < delivery_record.length; index++) {
    const rowItem = {}
    rowItem["no"] = index+1;
    rowItem["bill_no"] = 
    
   (<a  data-toggle="modal"
   data-target="#modal-bill_detail"
   href = ""
   onClick={formBillDetail.bind(this,delivery_record[index].bill_detail)}
   >
         {delivery_record[index].bill_no}
  
   </a>) 
    
    ;
    // rowItem["bill_no"] = delivery_record[index].bi_list.map((el, i) => {
    //   //  var name_driver_pe = "...";
    //   //  if(el.nickname_driver !== null ){
    //   //   name_driver_pe = el.nickname_driver;
    //   //  }
    //   return (
    //     <div style={{whiteSpace: 'nowrap'}}>   
    //         <>
    //       {el.bill_no}{" "}
    //    </>
    //     </div> 
    //   );
    // });
  
    rowItem["plate_number"] = delivery_record[index].car_name;
    rowItem["oem"] = delivery_record[index].oem_bill_name;
    rowItem["car_type"] = delivery_record[index].car_type_name;
    rowItem["time_length"] = "20 ชั่วโมง 35 นาที";
    rowItem["create_bill"] = moment(delivery_record[index].create_bill).format('DD/MM/yyyy') + " :: "+delivery_record[index].create_bill_time + " น.";
    rowItem["delivery_bill"] =  moment(delivery_record[index].delicery_bill_plan).format('DD/MM/yyyy') + " :: "+delivery_record[index].delivery_bill_time + " น.";
    rowItem["delivery_bill_actual"] =  moment(delivery_record[index].delicery_bill_actual).format('DD/MM/yyyy :: HH:mm') + " น."; 

    rowItem["user_create_bill"] = delivery_record[index].user_create_bill;
    rowItem["user_driver_bill"] = delivery_record[index].user_driver_bill;
    rowItem["approve_person"] = delivery_record[index].approve_person;
    //rowItem["nickname_driver"] = delivery_record[index].nickname_driver;

    rowItem["nickname_driver"] = delivery_record[index].bi_list.map((el, i) => {
      //  var name_driver_pe = "...";
      //  if(el.nickname_driver !== null ){
      //    name_driver_pe = el.nickname_driver;
      //   }
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.all_a}{" "}
       </>
        </div> 
      );
    });
  

    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },{
        label: "เลขที่ใบส่งสินค้า",
        field: "bill_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทะเบียน",
        field: "plate_number",
        sort: "asc",
        width: 50,
      },
      {
       label: "กลุ่มบริษัท",
       field: "oem",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "ประเภทรถ",
        field: "car_type",
        sort: "asc",
        width: 50,
      },
    /*   {
        label: "เวลา(ชั่วโมง-นาที)",
        field: "time_length",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "วัน-เวลาเปิดบิล",
        field: "create_bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "วัน-เวลานัดส่งมอบ(Plan)",
        field: "delivery_bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "วัน-เวลานัดส่งมอบ(Actual)",
        field: "delivery_bill_actual",
        sort: "asc",
        width: 50,
      },
      {
        label: "ผู้สร้างบิล",
        field: "user_create_bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "ผู้กำหนดบิลขึ้นรถ",
        field: "user_driver_bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "ผู้ตรวจ",
        field: "approve_person",
        sort: "asc",
        width: 50,
      },
      {
        label: "คนขับรถ",
        field: "nickname_driver",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

 
  function clearFilter(){
    setfilterDelivery({...filterDelivery, 
      oem_id: getOem(),
      company_id:getUser().com,
      start_date:"",
      end_date:"",
      plate_number: "",
      bill_no:"",
});
filterDelivery.oem_id =getOem();
filterDelivery.company_id =getUser().com;
filterDelivery.start_date = "";
filterDelivery.end_date = "";
filterDelivery.plate_number = "";
filterDelivery.bill_no = "";
GetDeliveryTimeRecord();
  }

    async function SetDate_start(date){
       setfilterDelivery({ ...filterDelivery, start_date: date});
  
     }
      async function SetDate_end(date){
       setfilterDelivery({ ...filterDelivery, end_date: date});
  
     }
    
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="end_userinout" submenu="none" /> */}
      <div className="content-wrapper">
       
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Delivery Time Record{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

             
                  <li className="breadcrumb-item active">Delivery Time Record</li>
                </ol>
              </div>
            </div>
          </div>
        
      
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
     
          <div className="card">
        
            <div className="container-fluid">
            <div className="col-md-2">
                
                <div className="form-group ">
                  
                <h3 className="card-title">Delivery Record</h3>
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
            <div className="row mb-2">
              
              <div className="col-md-2">
                
                <div className="form-group ">
                  
    
                                     <input
                      type="text"
                      className="form-control"
                      required
                      value={filterDelivery.bill_no}
                
                      onChange={(e) => {
                        setfilterDelivery({
                          ...filterDelivery,
                          bill_no: e.target.value,
                        });
                      }}
                    />
                        
                         <label htmlFor="">เลขที่ใบส่งสินค้า</label>

                </div>
              </div>

              <div className="col-md-2">
                
                <div className="form-group ">
                  
    
                                     <input
                      type="text"
                      className="form-control"
                      required
                      value={filterDelivery.plate_number}
                
                      onChange={(e) => {
                        setfilterDelivery({
                          ...filterDelivery,
                          plate_number: e.target.value,
                        });
                      }}
                    />
                        
                         <label htmlFor="">ทะเบียน</label>

                </div>
              </div>
                   
            <div className="col-md-0.5">
        <label > วัน-เวลานัดส่งมอบ(Actual) &nbsp;</label>
        </div>
              <div className="col-md-2" style = {{"z-index": "100"}}>
                <div className="form-group ">
                <DatePicker 
                selected = {filterDelivery.start_date}
        dateFormat = {"dd-MM-yyyy"}
        onChange = {
          async(date) =>
          SetDate_start(date)   
          }
        selectsStart startDate = {filterDelivery.start_date}
        endDate = {filterDelivery.end_date}
        customInput = {<CustomInput/>}
        
        /> 
                </div>
              </div>
              <div className="col-md-0.5">
        <label>&nbsp;&nbsp;  ถึง  &nbsp;&nbsp;</label> 
        </div> 
              <div className="col-md-2" style = {{"z-index": "99"}}>
                <div className="form-group ">
                <DatePicker 
        selected = {filterDelivery.end_date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>
          SetDate_end(date)   
          }
        selectsEnd startDate = { filterDelivery.start_date }
        endDate = { filterDelivery.end_date }
        minDate = { filterDelivery.start_date }
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
                </div>
              </div>
              <div className="col-md-2">
           
              </div>
            </div>
          </div>
          <div className="container-fluid">
          <div className="row">       
             {/*  <div className="col-md-2" style={{ "z-index": "99" }}>
                        <div className="form-group ">
                          <Select
                            options={companyGroup}
                            defaultValue={filterDelivery.oem_id}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setfilterDelivery({
                                  ...filterDelivery,
                                  oem_id: e.value,
                                });
                      
                              }
                            }}
                          />
         
                          <label> กลุ่มบริษัท </label>
                        </div>
                      </div> */}
     
     <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  
                <button
                    type="button"
                    className="btn btn-block btn-primary"
                     onClick={GetDeliveryTimeRecord}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  
                <button
                    type="button"
                    className="btn btn-block btn-primary"
                     onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  
                <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-block btn-primary"
                          table="table-to-xls_deliveryRecord"
                          filename={"Delivery_record" + "_" + moment(new Date()).format('DDMMyyyy')}
                          sheet="tablexls"
                          buttonText="Export To CSV"
                        />
                </div>
              </div>
            
            

              {/*
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary" onClick={showmessage}>
                    QR Code
                  </button>
                </div>
              </div>
              */}
            </div>
          </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                 
                  sortable = {false}
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
              <div className="table-responsive"  style={{ display: "none" }} >
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  id ="table-to-xls_deliveryRecord"
                  paging ={false}
                  sortable = {false}
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>




            <div className="modal fade" id="modal-bill_detail">
      <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">รายละเอียดใบส่งของ</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">
             
                  <h4>เลขที่ใบส่งของ{" : "}{bill_detail.bill_no || ""}</h4>
                  <h4>วันที่ออกบิล{" : "}{bill_detail.create_bill === null || "" ?(<>
                    {bill_detail.create_bill || "ไม่ระบุ"} เวลา {bill_detail.create_time || "ไม่ระบุ"}{" "}น.
                  </>):(<>
                    {moment(bill_detail.create_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.create_time || "ไม่ระบุ"}{" "}น.
                  </>
                  )}
                  </h4>
                  {/* <h4>วันที่ออกบิล{" : "}{moment(bill_detail.create_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.create_time || "ไม่ระบุ"}{" "}น.</h4> */}
                  <h4>วันที่นัดส่งสินค้า{" : "}{bill_detail.delivery_bill === null || "" ?(<>
                    {bill_detail.delivery_bill || "ไม่ระบุ"} เวลา {bill_detail.delivery_time || "ไม่ระบุ"}{" "}น.
                  </>):(<>
                    {moment(bill_detail.delivery_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.delivery_time || "ไม่ระบุ"}{" "}น.
                  </>
                  )}
                  </h4>
                  <h4>ชื่อลูกค้า{" : "}{bill_detail.customer_name || "ไม่ระบุ"}</h4>
                  <h4>หน้างาน{" : "}{bill_detail.bill_destination || "ไม่ระบุ"}</h4>
                  <h4>มูลค่า{" : "}{bill_detail.bill_values || "ไม่ระบุ"}</h4>
                  <h4>{getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์"? "จำนวนคิว":"น้ำหนัก(ตัน)"}{" : "}{bill_detail.weight || "ไม่ระบุ"}</h4>
                  <h4>สถานะ{" : "}{bill_detail.status_bill || "ไม่ระบุ"}</h4>
                  <h4>latitude{" : "}{bill_detail.latitude || "ไม่ระบุ"}</h4>
                  <h4>longitude{" : "}{bill_detail.longitude || "ไม่ระบุ"}</h4>
                  <h4>หมายเหตุ{" : "}{bill_detail.remark || "ไม่ระบุ"}</h4>
                  {getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์"?(<>
                    <h4>น้ำหนักจากเครื่องชั่ง{" : "} {bill_detail.weight_meter || "0"}  </h4>
                    <h4>แรงอัด{" : "} {bill_detail.compressive_strength || "0"}</h4>
                    <h4>ข้อมูลน้ำหนักและเเรงอัดล่าสุด{" : "}{bill_detail.delivery_bill === null || "" ?(<>
                    {bill_detail.weight_meter_updated || "ไม่ระบุ"}{" "}น.
                  </>):(<>
                    {moment(bill_detail.weight_meter_updated).format('DD/MM/yyyy เวลา HH:mm') || ""}{" "}น.
                  </>
                  )}
                  </h4>
                  </>
                  ):(
                    <>  
                    
                    </>
                  )}
                 
                </div>
              </div>

            
              
           
            </div>
            <div className="modal-footer "align="right">
            
                 
               <div className="row">
              {/*      <div className="col-12" align="right"> */}
                   <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
                {/*    </div> */}
               </div>
             
              
          
             
            </div>
          </div>
         
        </div>
       
      </div>





            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
   
      </div>
     
                    {/*   <Footter/> */}
                      </div>
  );
}
export default Delivery_Time_Record;
