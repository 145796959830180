import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import axios from "axios";
import Configs from "../../../../config";
import Swal from "sweetalert2";

function ModalDisplayProduct({ modalDisplayProduct, setModalDisplayProduct, modelType }) {
  // console.log('modalDisplayProduct', modalDisplayProduct);
  function SetTableStockData(index) {
    const rowItem = {};
    const _data = modalDisplayProduct.capacity_of_position?.find(el =>
      (el.id == modalDisplayProduct.data[index].product_id)
    ) || {};

    rowItem["warehosue"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {modalDisplayProduct?.wh_name || '-'}
      </span>
    );
    rowItem["warehosue_hide"] = modalDisplayProduct?.wh_name || '-';

    rowItem["model"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {modelType == "aioi" ? 'AIOI' : 'UNAI'}
      </span>
    );
    rowItem["model_hide"] = modelType == "aioi" ? 'AIOI' : 'UNAI';

    rowItem["prod_no"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {modalDisplayProduct.data[index]?.prod_no || '-'}
      </span>
    );
    rowItem["prod_no_hide"] = modalDisplayProduct.data[index]?.prod_no || '-';

    rowItem["prod_name"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {modalDisplayProduct.data[index]?.prod_name || '-'}
      </span>
    );
    rowItem["prod_name_hide"] = modalDisplayProduct.data[index]?.prod_name || '-';

    rowItem["amount"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {modalDisplayProduct.data[index]?.sum_amount || '0'}
      </span>
    );
    rowItem["amount_hide"] = modalDisplayProduct.data[index]?.sum_amount || '0';

    
    rowItem["max_capacities"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {modelType == "aioi" ? _data?.quantity || '-' : '-'}
      </span>
    );
    rowItem["max_capacities_hide"] = modelType == "aioi" ? _data?.quantity || '-' : '-';

    return rowItem;
  }
  const rowsStock = [];
  for (let index = 0; index < modalDisplayProduct.data.length; index += 1) {
    rowsStock.push(SetTableStockData(index));
  }

  const tableStock = {
    columns: [
      {
        label: "Warehosue",
        field: "warehosue",
        sort: "asc",
        width: 50,
      },
      {
        label: "Model",
        field: "model",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "prod_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "prod_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Max Capacities",
        field: "max_capacities",
        sort: "asc",
        width: 50,
      },
    ],

    rows: rowsStock,
  };

  async function GetDataProduct() {
    const data = {
      data_list: modalDisplayProduct.data,
      oem_id: getOem(),
      user: getUser().name + " " + getUser().surname,
      fup: getUser().fup,
      company_id: getUser().com,
      key_id_prod: "product_id"
    }
    Swal.fire({
      title: "กำลังดึงข้อมูลสินค้า",
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/GetProductDataFor3D_WH",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async (response) => {
        Swal.close();
        // console.log('response.data', response.data);
        setModalDisplayProduct({
          ...modalDisplayProduct,
          data: response.data
        });
        modalDisplayProduct.data = response.data;
      })
      .catch(async (error) => {
        Swal.close();
        Swal.fire("เกิดข้อผิดพลาด", "ดึงข้อมูลไม่สำเร็จ โปรดติดต่อผู้ดูแลระบบ", "error");
        console.log('error GetDataProduct ->', error);
      })
  }

  useEffect(async () => {
    if (modalDisplayProduct.is_open) {
      await GetDataProduct();
    }
  }, [modalDisplayProduct.is_open]);
  // console.log("modalDisplayProduct", modalDisplayProduct);
  return (
    <Modal
      size="xl"
      className="Modal_Center_LeftRight"
      isOpen={modalDisplayProduct.is_open}
      toggle={async () => {
        setModalDisplayProduct({
          ...modalDisplayProduct,
          is_open: false,
        });
        modalDisplayProduct.is_open = false;
      }}
    >
      <ModalHeader
        toggle={async () => {
          setModalDisplayProduct({
            ...modalDisplayProduct,
            is_open: false,
          });
          modalDisplayProduct.is_open = false;
        }}
      >
        <h4 className="modal-title" id="modalDelivery">
          <b>
            Rack : {modalDisplayProduct.rackData?.rack_name || ""} (
            {modalDisplayProduct.rackData.pos_name || ""})
          </b>
        </h4>
      </ModalHeader>

      <ModalBody>
        <div className="row">
          <div className="col-12">
            <div className="card card-primary card-outline card-outline-tabs">
              <div className="card-header p-0 border-bottom-0"></div>

              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <MDBDataTable
                        sortable={false}
                        className="table table-head-fixed"
                        striped
                        bordered
                        hover
                        fixedHeader
                        disableRetreatAfterSorting={true}
                        data={tableStock}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          className="btn btn-danger"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setModalDisplayProduct({
              ...modalDisplayProduct,
              is_open: false,
            });
            modalDisplayProduct.is_open = false;
          }}
        >
          ยกเลิก
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalDisplayProduct;
