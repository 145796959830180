import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../../Utils/Common";

import { userdefault_img } from "../../../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";

function Customer_Cute_Product({
  id,
  mode,
  isDisabled,
  inputDataTab2,
  setInputDataTab2,
  errorListTab2,
  setErrorListTab2,
  functionSaveTab2,
  setFunctionSaveTab2,
}) {
  const validThai = new RegExp(`[\u0E00-\u0E7F]`);
  const validEng = new RegExp(`[a-zA-Z]`);

  const modalProductRef = useRef();

  const [categoryData, setCategoryData] = useState({
    data: [],
  });

  const [conditionReceiveData, setConditionReceiveData] = useState({
    data: [],
  });
  
  const [shelfLifeTypeData, setShelfLifeTypeData] = useState({
    data: [],
  });

  const [productData, setProductData] = useState({
    data: [],
    filter: []
  });

  const [selectProductData, setSelectProductData] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [searchData, setSearchData] = useState({
    code: "",
    name: "",
    category: "",
    barcode: "",
  });

  async function openModalProduct() {
    const tempSelectProduct = selectProductData.data;
    const tempProductTable = productData.data;

    const tempList = tempProductTable.map((item1) => {
      const check = tempSelectProduct.find(
        (item2) => item2.product_id === item1.product_id
      );

      if (check) {
        item1.check = true;
        return item1;
      } else {
        item1.check = false;
        return item1;
      }
    });

    setProductData({ ...productData, data: tempList });
    productData.data = tempList;

    modalProductRef.current.click();
  }

  async function GetShelfLifeType() {
    await axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/customer/getShelfLifeType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setShelfLifeTypeData({
          ...shelfLifeTypeData,
          data: response.data,
        });
        shelfLifeTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  
  async function GetConditionReceive() {
    await axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/customer/getConditionReceive",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setConditionReceiveData({
          ...conditionReceiveData,
          data: response.data,
        });
        conditionReceiveData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCustomerRelationProduct() {
    // let tempData = {
    //   company_id: getUser().com,
    //   oem_id: getOem(),
    //   user_id: getUser().fup,
    // };

    await axios({
      method: "get",
      url:
        Configs.API_URL_cuteplus +
        "/api/customer/getCustomerRelationProductByCustomerID/" +
        id,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData,
    })
      .then(function (response) {
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data,
        });

        inputDataTab2.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetCategoryLv2() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategoryLv2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategoryData({ data: response.data });
        categoryData.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProduct(hava_searchOrClear) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      code: searchData.code.trim() || null,
      name: searchData.name.trim() || null,
      barcode: searchData.barcode.trim() || null,
      category: searchData.category.trim() || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {

        if (response.data.length == 0) {
          await resetProduct(false);
          return;
        } else if (productData.data?.length > 0) {
          setProductData({
            ...productData,
            filter: response.data
          })
          productData.filter = response.data
        }

        const tempRes = productData.data?.length > 0 ? productData.data : response.data;
        const selectProductDataList = [];
        const tempResList = [];

        for (let item1 of tempRes) {
          let tempImage = {
            company_id: getUser().com,
            oem_id: getOem(),
            // user_id: getUser().fup,
            ul_project: "Cute_Products_Plus",
            ul_type: "ภาพ",
            ul_ref_id: item1.product_id,
            // ul_menu_id: ,
            ul_is_profile: true,
            // ul_file_group: ,
          };

          axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileOne",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: tempImage,
          }).then(function (response) {
            item1.image = response.data;
          });

          if (productData.filter.length > 0) {

            const check = productData.filter.find(el => el.product_id === item1.product_id)
            if (check) {
              item1.is_use = true
            } else {
              item1.is_use = false
            }
          } else {
            item1.is_use = true
          }

          if (hava_searchOrClear) {
            const check = selectProductData.data.find(el => el.product_id === item1.product_id);
            if (check) {
              item1.check = true;
              tempResList.push(item1)
              /* tempResListAll.push(item1); */
              continue;
            }
          }

          item1.check = false;
          item1.oldNew = "new";
          item1.customer_bar_code = "";
          item1.customer_code = "";
          item1.customer_product_name_eng = item1.product_name_eng || "";
          item1.customer_product_name_th = item1.product_name || "";
          item1.full_shelf_life = "";
          item1.unit = "";
          item1.sale_price = "";
          item1.GP_1 = "";
          item1.resale = item1.product_resale || "";
          item1.ltp = item1.product_ltp || "";
          item1.spp = item1.product_spp || "";
          item1.condition_rc = "";
          if (item1.barcode_id.length === 1) {
            item1.isBarSelect = false;
            item1.bar_code = item1.barcode_id[0];
          } else {
            item1.isBarSelect = true;
            const index_barcode = item1.barcode_active?.indexOf(true)
            item1.bar_code = index_barcode >= 0 ? item1.barcode_id[index_barcode] : "";
          }

          if (mode === "edit" || mode === "view") {
            for (let item2 of inputDataTab2.data) {
              if (item1.product_id === item2.crp_product_id) {
                item1.check = true;
                item1.oldNew = "old";
                item1.customer_bar_code = item2.crp_cus_barcode;
                item1.customer_code = item2.crp_cus_code;
                item1.customer_product_name_eng = item2.crp_product_name_eng;
                item1.customer_product_name_th = item2.crp_product_name_th;
                item1.full_shelf_life = item2.crp_amount;
                item1.unit = item2.crp_slt_id || "";
                item1.sale_price = item2.crp_sale_price;
                item1.GP_1 = item2.crp_gp1;
                item1.resale = item2.crp_resale;
                item1.ltp = item2.crp_ltp;
                item1.spp = item2.crp_spp;
                item1.bar_code = item2.crp_pb_id;
                item1.cusRePro = item2.crp_id;
                item1.condition_rc = item2.crp_cr_id || "";
                selectProductDataList.push(item1);
              }
            }
          }
          tempResList.push(item1)
        }

        if (!hava_searchOrClear) {
          setSelectProductData({
            ...selectProductData,
            data: selectProductDataList,
          });
          selectProductData.data = selectProductDataList;
        }
        setProductData({ ...productData, data: tempResList });
        productData.data = tempResList;

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const rowsData1 = [];
  let run_number = 0;
  for (let index = 0; index < productData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{run_number + 1}</div>;

    rowItem["code"] = productData.data[index].product_code
      ? productData.data[index].product_code
      : "-";

    rowItem["barcode"] = productData.data[index].barcode[0] ? productData.data[index].barcode[0] : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            productData.data[index].image
              ? productData.data[index].image.ul_path_file !== undefined &&
                productData.data[index].image.ul_path_file !== null &&
                productData.data[index].image.ul_path_file !== ""
                ? productData.data[index].image.ul_path_file?.indexOf("blob") >=
                  0
                  ? productData.data[index].image.ul_path_file
                  : Configs.API_URL_IMG_cuteplus +
                  `/static/${productData.data[index].image.ul_file_name}/${productData.data[index].image.ul_path_file}`
                : userdefault_img.imgs
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = productData.data[index].product_name_eng
      ? productData.data[index].product_name_eng
      : "-";

    rowItem["category"] = productData.data[index].cat_name
      ? productData.data[index].cat_name
      : "-";

    rowItem["management"] =
      productData.data[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            onClick={() => {
              let tempData = productData.data;

              tempData[index].check = true;
              if (tempData[index].statusUpdate) {
                tempData[index].status = "update";
              } else {
                tempData[index].status = "add";
              }

              setProductData({
                ...productData,
                data: tempData,
              });
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            onClick={() => {
              let tempData = productData.data;

              tempData[index].check = false;
              tempData[index].status = "del";

              setProductData({
                ...productData,
                data: tempData,
              });
            }}
          >
            ยกเลิก
          </button>
        </div>
      );
    if (productData.data[index].is_use) {
      run_number += 1;
      rowsData1.push(rowItem);

    }
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Category Lv2",
        field: "category",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };

  const rowsData2 = [];
  for (let index = 0; index < selectProductData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = selectProductData.data[index].product_code
      ? selectProductData.data[index].product_code
      : "-";

    rowItem["bar_code"] =
      selectProductData.data[index].isBarSelect === false ? (
        <select
          type="text"
          className="form-control"
          style={{ minWidth: "150px" }}
          disabled={true}
          value={selectProductData.data[index].bar_code}
        >
          <option value="-" disabled selected>
            -- เลือก --
          </option>

          <option value={selectProductData.data[index].barcode_id[0]}>
            {selectProductData.data[index].barcode[0]}
          </option>
        </select>
      ) : (
        <select
          type="text"
          className="form-control"
          style={{ minWidth: "150px" }}
          disabled={isDisabled}
          value={selectProductData.data[index].bar_code}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].bar_code = e.target.value;

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
          }}
        >
          <option value="" disabled>
            -- เลือก --
          </option>

          {selectProductData.data[index].barcode_id.map((item, indexIn) => {
            return (
              <option value={item}>
                {selectProductData.data[index].barcode[indexIn]}
              </option>
            );
          })}
        </select>
      );

      rowItem["customer_code"] = (
        <NumberFormat
          type="text"
          className="form-control"
          style={{ minWidth: "150px" }}
          required
          decimalScale={0}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].customer_code}
          onChange={async (e) => {
            const temp = selectProductData.data;
  
            temp[index].customer_code = e.target.value;
  
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });
  
            selectProductData.data = temp;
  
            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
          }}
        />
      );

    rowItem["customer_bar_code"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        required
        decimalScale={0}
        allowNegative={false}
        allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].customer_bar_code}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].customer_bar_code = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["product_name"] = selectProductData.data[index].product_name_eng
      ? selectProductData.data[index].product_name_eng
      : "-";

    rowItem["customer_product_name_eng"] = (
      <input
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        // required
        // decimalScale={0}
        // allowNegative={false}
        // allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].customer_product_name_eng}
        onChange={async (e) => {
          if (/* !validThai.test(e.target.value.toString().trim()) */true) {
            const temp = selectProductData.data;

            temp[index].customer_product_name_eng = e.target.value;

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
          }
        }}
      />
    );

    rowItem["customer_product_name_th"] = (
      <input
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        // required
        // decimalScale={0}
        // allowNegative={false}
        // allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].customer_product_name_th}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].customer_product_name_th = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["unit"] = (
      <select
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        disabled={isDisabled}
        value={selectProductData.data[index].unit}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].unit = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      >
        <option value="" disabled>
          -- เลือก --
        </option>
        {shelfLifeTypeData.data.map((data, index) => {
          return (
            <option key={index} value={data.slt_id}>
              {data.slt_name}
            </option>
          );
        })}
        {/* <option value="เดือน">เดือน</option>
        <option value="เปอร์เซ็นต์">เปอร์เซ็นต์</option> */}
      </select>
    );

    rowItem["full_shelf_life"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        // required
        decimalScale={0}
        allowNegative={false}
        // allowLeadingZeros={true}
        /* isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return selectProductData.data[index].unit === "เดือน"
            ? values
            : formattedValue === "" || floatValue <= 100;
        }} */
        disabled={isDisabled}
        value={selectProductData.data[index].full_shelf_life}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].full_shelf_life = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["price_ex_vat"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        required
        decimalScale={2}
        allowNegative={false}
        thousandSeparator
        // allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].sale_price}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].sale_price = e.target.value;

          if (e.target.value && e.target.value !== 0) {
            const retail_price = parseFloat(temp[index].resale/* product_resale */?.toString().replace(/,/g, "") || 0)
            const sale_price = parseFloat(e.target.value?.toString().replace(/,/g, "") || 0)
            temp[index].GP_1 = ((retail_price * 100 / 107) - sale_price) / (retail_price * 100 / 107) * 100; // (sale_price * 107 / retail_price)
            
            // console.log('temp[index].GP_1', temp[index].GP_1);
            if (temp[index].GP_1 < 0) {
              temp[index].GP_1 = 0;
              temp[index].sale_price = (retail_price * 100 / 107);// ((retail_price * (100 / 107)) * (100 / 100))
            }
            // (((retail_price * (100 / 107)) * (gp / 100))
          } else {
            temp[index].GP_1 = e.target.value === 0 ? 100 : '';
          }

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["GP_1"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        required
        decimalScale={2}
        allowNegative={false}
        // allowLeadingZeros={true}
        isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue <= 100;
        }}
        disabled={isDisabled}
        value={selectProductData.data[index].GP_1}
        onChange={async (e) => {
          const temp = selectProductData.data;
          temp[index].GP_1 = e.target.value;
          
          const gp = parseFloat(e.target.value?.toString().replace(/,/g, "") || 0);
          const retail_price = parseFloat(temp[index].resale/* product_resale */?.toString().replace(/,/g, "") || 0)
          if (e.target.value && e.target.value != 0) {
            temp[index].sale_price = ((retail_price * 100 / 107) - ((retail_price * 100 / 107) / 100 * gp)/*  + (retail_price * (100 / 107)) */);
          } else {
            temp[index].sale_price = (e.target.value == '' ? '' : (retail_price * 100 / 107));
            /* if (e.target.value === '') {
            }
            console.log('in',(e.target.value == 0 ? (retail_price * 100 / 107) : 700));
            console.log('(retail_price * 100 / 107)', (retail_price * 100 / 107));
            console.log('temp[index].sale_price', temp[index].sale_price); */
          }
          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["retail_price"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        required
        decimalScale={2}
        allowNegative={false}
        thousandSeparator
        // allowLeadingZeros={true}
        /* isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue <= 100;
        }} */
        disabled={isDisabled}
        value={selectProductData.data[index].resale}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].resale = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["ltp"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        required
        decimalScale={2}
        allowNegative={false}
        thousandSeparator
        // allowLeadingZeros={true}
        /* isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue <= 100;
        }} */
        disabled={isDisabled}
        value={selectProductData.data[index].ltp}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].ltp = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["spp"] = (
      <NumberFormat
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        required
        decimalScale={2}
        allowNegative={false}
        thousandSeparator
        // allowLeadingZeros={true}
        /* isAllowed={(values) => {
          const { formattedValue, floatValue } = values;
          return formattedValue === "" || floatValue <= 100;
        }} */
        disabled={isDisabled}
        value={selectProductData.data[index].spp}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].spp = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["condition_recieve_product"] = (
      <select
        type="text"
        className="form-control"
        style={{ minWidth: "150px" }}
        disabled={isDisabled}
        value={selectProductData.data[index].condition_rc}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].condition_rc = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      >
        <option value="" disabled>
          -- เลือก --
        </option>
        {conditionReceiveData.data.map((data, index) => {
          return (
            <option key={index} value={data.cr_id}>
              {data.cr_name}
            </option>
          );
        })}
        {/* <option value="เดือน">เดือน</option>
        <option value="เปอร์เซ็นต์">เปอร์เซ็นต์</option> */}
      </select>
    )

    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          key={selectProductData.data[index].product_id}
          id={selectProductData.data[index].product_id}
          className=" btn btn-xs "
          onClick={async () => {
            const tempProductData = productData.data;
            const tempSelectProductData = selectProductData.data;

            const findIndex = tempProductData.findIndex(
              (item) =>
                item.product_id === tempSelectProductData[index].product_id
            );

            if (findIndex >= 0) {
              tempProductData[findIndex].check = false;
              tempProductData[findIndex].status = "del";
            }

            await saveProductData();
          }}
        >
          <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
        </a>
      </div>
    );

    rowsData2.push(rowItem);
  }

  const selectProducTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Customer Code",
        field: "customer_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer Barcode",
        field: "customer_bar_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer Product Name (Eng)",
        field: "customer_product_name_eng",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer Product Name (ไทย)",
        field: "customer_product_name_th",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Unit",
        field: "unit",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Full Shelf life",
        field: "full_shelf_life",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Price EX VAT",
        field: "price_ex_vat",
        // sort: "asc",
        width: 50,
      },
      {
        label: "GP(%)",
        field: "GP_1",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Retail Price",
        field: "retail_price",
        // sort: "asc",
        width: 50,
      },
      {
        label: "LTP",
        field: "ltp",
        // sort: "asc",
        width: 50,
      },
      {
        label: "SPP",
        field: "spp",
        // sort: "asc",
        width: 50,
      },
      {
        label: "เงื่อนไขการรับสินค้า",
        field: "condition_recieve_product",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (isDisabled) {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "management";
    });

    selectProducTable.columns.splice(index1, 1);
  }

  async function saveProductData() {
    const findCheckTrue = productData.data.filter((item) => {
      return item.check === true;
    });

    const addNew = productData.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = productData.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = productData.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setSelectProductData({
      ...selectProductData,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    selectProductData.data = findCheckTrue;
    selectProductData.newData = addNew;
    selectProductData.updateData = updateOld;
    selectProductData.delData = delOld;
  }

  async function clearSearch(is_clear) {
    setSearchData({
      ...searchData,
      code: "",
      name: "",
      category: "",
      barcode: "",
    });

    searchData.code = "";
    searchData.name = "";
    searchData.category = "";
    searchData.barcode = "";

    if (is_clear) {
      await resetProduct(true)
    } else {
      await GetProduct("clear");
    }
  }

  const resetProduct = async (is_use) => {
    const arrTemp = productData.data
    arrTemp.forEach(el => {
      el.is_use = is_use
    });
    setProductData({
      ...productData,
      data: arrTemp
    })
    productData.data = arrTemp;
  }

  async function checkErrorSelectProductData() {
    if (selectProductData.data.length > 0) {
      const error_list = [];
      let number = 1;
      // const regexThai = /^[ก-๙0-9]+$/
      const regexEng = /^[A-Za-z ]+$/
      const regexThai = /[A-Za-z]/
      for (let item of selectProductData.data) {
        if (item.bar_code === null || item.bar_code.trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณาเลือก Barcode`,
          };

          error_list.push(temp_err);
        }

        /* if (
          item.customer_bar_code === null ||
          item.customer_bar_code.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอก Customer Barcode`,
          };

          error_list.push(temp_err);
        } */

        if (
          item.customer_product_name_eng === null ||
          item.customer_product_name_eng.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอก Customer Product Name (Eng)`,
          };

          error_list.push(temp_err);
        } /* else if (!regexEng.test(item.customer_product_name_eng)) {
          let temp_err = {
            message: `แถวที่ ${number} Customer Product Name (Eng) ต้องเป็นภาษา อังกฤษ`,
          };

          error_list.push(temp_err);
        } */

        if (
          item.customer_product_name_th === null ||
          item.customer_product_name_th.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอก Customer Product Name (ไทย)`,
          };

          error_list.push(temp_err);
        } /* else if (regexThai.test(item.customer_product_name_th)) {
          let temp_err = {
            message: `แถวที่ ${number} Customer Product Name (ไทย) ต้องเป็นภาษา ไทย`,
          };

          error_list.push(temp_err);
        } */

        /* if (
          item.full_shelf_life === null ||
          item.full_shelf_life.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอก Full Shelf Life`,
          };

          error_list.push(temp_err);
        }

        if (item.unit === null || item.unit.trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณาเลือก Unit`,
          };

          error_list.push(temp_err);
        } */

        if ((item.full_shelf_life && !item.unit)) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณาเลือก Unit`,
          };

          error_list.push(temp_err);
        }

        if (item.sale_price === null || item.sale_price?.toString().trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอก Sale Price`,
          };

          error_list.push(temp_err);
        }

        /* if (item.GP_1 === null || item.GP_1.trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอก GP1`,
          };

          error_list.push(temp_err);
        } */

        // if (
        //   item.resale === null ||
        //   item.resale.trim() === ""
        // ) {
        //   let temp_err = {
        //     message: `แถวที่ ${number} กรุณากรอก GP2`,
        //   };

        //   error_list.push(temp_err);
        // }

        // if (
        //   item.ltp === null ||
        //   item.ltp.trim() === ""
        // ) {
        //   let temp_err = {
        //     message: `แถวที่ ${number} กรุณากรอก GP3`,
        //   };

        //   error_list.push(temp_err);
        // }

        // if (
        //   item.spp === null ||
        //   item.spp.trim() === ""
        // ) {
        //   let temp_err = {
        //     message: `แถวที่ ${number} กรุณากรอก spp`,
        //   };

        //   error_list.push(temp_err);
        // }

        number += 1;
      }

      if (error_list.length > 0) {
        let temp_err = {
          message: `- Tab Customer Product -`,
        };
        error_list.unshift(temp_err);
      }

      setErrorListTab2({
        ...errorListTab2,
        data: error_list,
      });
      errorListTab2.data = error_list;
    }
  }

  useEffect(async () => {
    await GetConditionReceive();
    await GetShelfLifeType();
    if (mode === "edit" || mode === "view") {
      await GetCustomerRelationProduct();
    }
    await GetCategoryLv2();
    await GetProduct();
  }, []);

  useEffect(async () => {
    setInputDataTab2({
      ...inputDataTab2,
      data: selectProductData.data,
      newData: selectProductData.newData,
      updateData: selectProductData.updateData,
      delData: selectProductData.delData,
    });

    inputDataTab2.data = selectProductData.data;
    inputDataTab2.newData = selectProductData.newData;
    inputDataTab2.updateData = selectProductData.updateData;
    inputDataTab2.delData = selectProductData.delData;

    setFunctionSaveTab2({
      ...functionSaveTab2,
      data: checkErrorSelectProductData,
    });

    functionSaveTab2.data = checkErrorSelectProductData;
  }, [
    selectProductData.data,
    selectProductData.newData,
    selectProductData.updateData,
    selectProductData.delData,
    // functionSaveTab2.data,
    errorListTab2.data
  ]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-md-3 col-xl-2">
          <button
            hidden={true}
            data-toggle="modal"
            data-target="#modalProduct"
            ref={modalProductRef}
          />
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDisabled}
            onClick={async () => {
              await openModalProduct();
              await clearSearch(true);
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-12" style={{ overflow: "overlay" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <MDBDataTable
              sortable={false}
              striped
              bordered
              hover
              data={selectProducTable}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProduct"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProduct"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{ width: "70%" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="modalProduct">
                <b>Add Product</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.code}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          code: e.target.value,
                        });
                        searchData.code = e.target.value;
                      }}
                    />
                    <label>Product Code</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          barcode: e.target.value,
                        });
                        searchData.barcode = e.target.value;
                      }}
                    />
                    <label>Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          name: e.target.value,
                        });
                        searchData.name = e.target.value;
                      }}
                    />
                    <label>Product Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      value={searchData.category}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          category: e.target.value,
                        });
                        searchData.category = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select a category
                      </option>
                      {categoryData.data.map((option, index) => (
                        <option
                          key={categoryData.data[index].cat_name}
                          value={categoryData.data[index].cat_id}
                        >
                          {categoryData.data[index].cat_name}
                        </option>
                      ))}
                    </select>
                    <label>Category Lv2</label>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await GetProduct("search");
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await clearSearch();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <div className="col-md-12" style={{ overflow: "overlay" }}>
                  <div
                    style={{
                      height: "450px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MDBDataTable
                      sortable={false}
                      striped
                      bordered
                      hover
                      data={ProductTable}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={async () => {
                  await saveProductData();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customer_Cute_Product;
