/* import Construccomponent from "../../component/Construction"; */
import React, { Component, useEffect, useState, useRef } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { MDBDataTable } from "mdbreact";
import Footter from "../../component/Footter";
import sortJsonArray from "sort-json-array";
import Header from "../../component/Header";
import { logo_kubota } from "../../routes/imgRoute/imgUrl";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  setMenu,
  getUser,
  getAcademy
} from "../../Utils/Common";
import FormData from "form-data";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Product_forcast_excel from "../../assets/file/upload_OrderForcast.xlsx";
import BTN from "../../component/btn_feature";
import OEM from "../../component/OEM";
import moment from "moment";
let date = new Date();
let dateformatYear = date.getFullYear();
let dateformatMonth = date.getMonth() + 1;
function Uploadorderforecast(params) {
  const [user, setUser] = useState(getUser());
  const [oem, setOem] = useState([]);
  const [orderForcast, setOrderForcast] = useState([]);
  const [filler_Order, setfiller_Order] = useState({
    oem_id: getOem(),
    year: dateformatYear,
    month: dateformatMonth,
    save: false,
  });
 
  const [mod_date_formService, setmod_date_formService] = useState();

  const inputFile = useRef(null);
  const inputFile2 = useRef(null);
  const inputFile3 = useRef(null);
  const inputFile4 = useRef(null);

  const [message, setmessage] = useState({
    message: "",
  });
  const [upload, setupload] = useState([]);
  const [upload_temp, setupload_temp] = useState();
  const [namefile, setNamefile] = useState({
    upload_stat: true,
  });
  const [feature, setfeature] = useState([]);

  const [n1Change, setn1Change] = useState('');
  const [n2Change, setn2Change] = useState('');
  const [n3Change, setn3Change] = useState('');
  const [n4Change, setn4Change] = useState('');
  /* const Gettable = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/productPlaning/FilterOrderForcast",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_Order,
    })
      .then(async (response) => {
        await setfiller_Order(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }; */

  const rowsData = [];
  for (var index = 0; index < orderForcast.length; index++) {
    const rowItem = {};

    if (orderForcast[index].n1 == null) {
      rowItem["n1"] = "null";
    } else {
      rowItem["n1"] = orderForcast[index].n1;
    }
    if (orderForcast[index].n2 == null) {
      rowItem["n2"] = "null";
    } else {
      rowItem["n2"] = orderForcast[index].n2;
    }
    if (orderForcast[index].n3 == null) {
      rowItem["n3"] = "null";
    } else {
      rowItem["n3"] = orderForcast[index].n3;
    }
    if (orderForcast[index].n4 == null) {
      rowItem["n4"] = "null";
    } else {
      rowItem["n4"] = orderForcast[index].n4;
    }

    rowItem["Item"] = index + 1;
    rowItem["Part_No"] = orderForcast[index].no;
    rowItem["Part_Name"] = orderForcast[index].name;
    rowItem["WIP"] = orderForcast[index].wip;
    rowItem["FG"] = orderForcast[index].fg;
    rowItem["In_Hand"] = orderForcast[index].in_hand;
    rowItem["N"] = "N" + index;
    rowItem["Delivery"] = orderForcast[index].delivery;
    rowItem["Pending"] = orderForcast[index].pending;
    rowItem["N1"] = <div style={{ color: "tomato" }}>{rowItem["n1"]}</div>;
    rowItem["N2"] = <div style={{ color: "tomato" }}>{rowItem["n2"]}</div>;
    rowItem["N3"] = <div style={{ color: "tomato" }}>{rowItem["n3"]}</div>;
    rowItem["N4"] = <div style={{ color: "tomato" }}>{rowItem["n4"]}</div>;
    /*  rowItem["wt_pc"] = index;
    rowItem["mat_kg"] = index;
    rowItem["cos_pc"] = index;
    rowItem["mat_pupp"] = index; 
    rowItem["remark"] = index;
    */

    rowsData.push(rowItem);
  }
  /*   console.log(namefile.file);
  console.log(filler_Order);
 */

  function Uploadfile_komatsu(e) {
    if (filler_Order.year !== "" && filler_Order.month !== "") {
      if (
        e.target.files[0] !== "" &&
        e.target.files[0] !== null &&
        e.target.files[0] !== undefined
      ) {
        setupload_temp(e.target.files[0]);

        const data = new FormData();

        data.append("file", e.target.files[0]);
        data.append("oem_id", filler_Order.oem_id);
        data.append("year", filler_Order.year);
        data.append("month", filler_Order.month);

        const config_upload = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importReadOrderForcastByKMS",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };


       /*  const config_upload_add = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importAddOrderForcast",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        }; */

        /*           console.log("yes2_kms"); */

        axios(config_upload)
          .then(function (response) {
        

            let test = sortJsonArray(response.data, "no", "asc");

          setupload(test);
           console.log("resData",test);
            let num = 0;
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].status === false) {
                num++;
              }
            }

            if (num !== 0) {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                Swal.fire("Error", "There is still an error", "error");
              });
              setNamefile({ ...namefile, upload_stat: true });
            } else {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                data.append("save", true);
                axios(config_upload)
                  .then(function (response2) {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch(function (error2) {
                    Swal.fire("Error", error2, "error");
                  });
              });
              setfiller_Order({ ...filler_Order, save: true });
              setNamefile({ ...namefile, upload_stat: false });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error File Xlsx",
              text: "File Xlsx can't null " + error.message,
              icon: "warning",

              confirmButtonColor: "#3085d6",

              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href =
                  "/Production_Planing/Upload_Order_Forecast";
              }
            });
          });
      }
    } else {
      Swal.fire(
        "Please select year and month.",
        "Year and cannot blank",
        "warning"
      );
    }
    inputFile.current.value = "";
  }

  function Uploadfile_kubota(e) {
    if (filler_Order.year !== "" && filler_Order.month !== "") {
      console.log("yes1");
      if (
        e.target.files[0] !== "" &&
        e.target.files[0] !== null &&
        e.target.files[0] !== undefined
      ) {
        setupload_temp(e.target.files[0]);

        const data = new FormData();

        data.append("file", e.target.files[0]);
        data.append("oem_id", filler_Order.oem_id);
        data.append("year", filler_Order.year);
        data.append("month", filler_Order.month);

        const config_upload = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importReadOrderForcastByKTC",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };
        /* console.log(config_upload); */
        console.log("yes2_ktc");

        ///data.append("save", true);
        axios(config_upload)
          .then(function (response) {
            console.log(response.data);
            console.log("yes3_ktc");
            let test = sortJsonArray(response.data, "no", "asc");

            setupload(test);

            let num = 0;
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].status === false) {
                num++;
              }
            }

            if (num !== 0) {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                Swal.fire("Error", "There is still an error", "error");
              });

              setNamefile({ ...namefile, upload_stat: true });
            } else {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: response.data.length+100,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                data.append("save", true);
                axios(config_upload)
                  .then(function (response2) {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch(function (error2) {
                    Swal.fire("Error", error2, "error");
                  });
              });

              setfiller_Order({ ...filler_Order, save: true });
              setNamefile({ ...namefile, upload_stat: false });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error File Xlsx",
              text: "File Xlsx can't null " + error.message,
              icon: "warning",

              confirmButtonColor: "#3085d6",

              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href =
                  "/Production_Planing/Upload_Order_Forecast";
              }
            });
          });
      }
    } else {
      Swal.fire(
        "Please select year and month.",
        "Year and cannot blank",
        "warning"
      );
    }
    inputFile.current.value = "";
  }
  function Uploadfile(e) {
    if (filler_Order.year !== "" && filler_Order.month !== "") {
      console.log("yes1");
      if (
        e.target.files[0] !== "" &&
        e.target.files[0] !== null &&
        e.target.files[0] !== undefined
      ) {
        setupload_temp(e.target.files[0]);

        const data = new FormData();

        data.append("file", e.target.files[0]);
        data.append("oem_id", filler_Order.oem_id);
        data.append("year", filler_Order.year);
        data.append("month", filler_Order.month);

        const config_upload = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importReadOrderForcast",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config_upload)
          .then(function (response) {
            console.log(response.data);

            let test = sortJsonArray(response.data, "no", "asc");

            setupload(test);

            let num = 0;
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].status === false) {
                num++;
              }
            }

            if (num !== 0) {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                Swal.fire("Error", "There is still an error", "error");
              });
              setNamefile({ ...namefile, upload_stat: true });
            } else {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                data.append("save", true);
                axios(config_upload)
                  .then(function (response2) {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch(function (error2) {
                    Swal.fire("Error", error2, "error");
                  });
              });
              setfiller_Order({ ...filler_Order, save: true });
              setNamefile({ ...namefile, upload_stat: false });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error File Xlsx",
              text: "File Xlsx can't null " + error.message,
              icon: "warning",

              confirmButtonColor: "#3085d6",

              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href =
                  "/Production_Planing/Upload_Order_Forecast";
              }
            });
          });
      }
    } else {
      Swal.fire(
        "Please select year and month.",
        "Year and cannot blank",
        "warning"
      );
    }
    inputFile.current.value = "";
  }

  function Uploadfile_add(e) {
    if (filler_Order.year !== "" && filler_Order.month !== "") {
      console.log("yes1");
      if (
        e.target.files[0] !== "" &&
        e.target.files[0] !== null &&
        e.target.files[0] !== undefined
      ) {
        setupload_temp(e.target.files[0]);

        const data = new FormData();

        data.append("file", e.target.files[0]);
        data.append("oem_id", filler_Order.oem_id);
        data.append("year", filler_Order.year);
        data.append("month", filler_Order.month);

        const config_upload = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importReadOrderForcast",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };

        const config_upload_add = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importAddOrderForcast",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config_upload)
          .then(function (response) {
            console.log(response.data);

            let test = sortJsonArray(response.data, "no", "asc");

            setupload(test);

            let num = 0;
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].status === false) {
                num++;
              }
            }

            if (num !== 0) {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                Swal.fire("Error", "There is still an error", "error");
              });
              setNamefile({ ...namefile, upload_stat: true });
            } else {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                data.append("save", true);
                axios(config_upload_add)
                  .then(function (response2) {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  })
                  .catch(function (error2) {
                    Swal.fire("Error", error2, "error");
                  });
              });
              setfiller_Order({ ...filler_Order, save: true });
              setNamefile({ ...namefile, upload_stat: false });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error File Xlsx",
              text: "File Xlsx can't null " + error.message,
              icon: "warning",

              confirmButtonColor: "#3085d6",

              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href =
                  "/Production_Planing/Upload_Order_Forecast";
              }
            });
          });
      }
    } else {
      Swal.fire(
        "Please select year and month.",
        "Year and cannot blank",
        "warning"
      );
    }
    inputFile.current.value = "";
  }

  function Updatefile(e) {
    if (filler_Order.year !== "" && filler_Order.month !== "") {
      if (
        e.target.files[0] !== "" &&
        e.target.files[0] !== null &&
        e.target.files[0] !== undefined
      ) {
        //  setupload_temp(e.target.files[0]);

        const data = new FormData();

        data.append("file", e.target.files[0]);
        data.append("oem_id", filler_Order.oem_id);
        data.append("year", filler_Order.year);
        data.append("month", filler_Order.month);

        const config_upload = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importUpdateOrderForcast",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config_upload)
          .then(function (response) {
            //console.log(response.data);
            // console.log("yes3");
            let test = sortJsonArray(response.data, "no", "asc");

            setupload(test);

            let num = 0;
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].status === false) {
                num++;
              }
            }

            if (num !== 0) {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                Swal.fire("Error", "There is still an error", "error");
              });
              setNamefile({ ...namefile, upload_stat: true });
            } else {
              Swal.fire({
                title: "Saving",
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1000,
                onOpen: () => {
                  Swal.showLoading();
                },
              }).then((result) => {
                /* axios(config_upload)
                .then(function (response2) {
                
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  })
                }).catch(function (error2) {
                  Swal.fire("Error", error2, "error");
                }) */
              });
              setfiller_Order({ ...filler_Order, save: true });
              setNamefile({ ...namefile, upload_stat: false });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error File Xlsx",
              text: "File Xlsx can't null " + error.message,
              icon: "warning",

              confirmButtonColor: "#3085d6",

              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href =
                  "/Production_Planing/Upload_Order_Forecast";
              }
            });
          });
      }
    } else {
      Swal.fire(
        "Please select year and month.",
        "Year and cannot blank",
        "warning"
      );
    }
    inputFile.current.value = "";
  }

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + user.com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {


    montchange();


    axios(config_com)
      .then(function (response) {
        const data = response.data;

        setOem(data.oem);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Upload Order Forecast";
    });
    ////////////////console.log(feature_data)
    setfeature(feature_data);
    ////////permission feature//////////
  }, []);

  /* async function selectmonth(e) {
    await setfiller_Order({ ...filler_Order, month: e.target.value });
    filler_Order.month = e.target.value;
   
  }
  async function selectyear(e) {
    await setfiller_Order({ ...filler_Order, year: e.target.value });
    filler_Order.year = e.target.value;
   
  } */

  function loadFromService() {
    if (filler_Order.year !== "" && filler_Order.month !== "") {
      const data = new FormData();
      data.append("oem_id", filler_Order.oem_id);
      data.append("year", filler_Order.year);
      data.append("month", filler_Order.month);

      const config_upload = {
        method: "post",
        url:
          Configs.API_URL + "/productPlaning/importReadOrderForcastByBKC_FTP",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config_upload)
        .then(function (response) {
          console.log(response.data);

          let test = sortJsonArray(response.data, "no", "asc");
          setmod_date_formService(
            "Date : " + moment(response.data[0].mod_date).format("DD/MM/YYYY")
          );

          setupload(test);

          let num = 0;
          for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].status === false) {
              num++;
            }
          }

          if (num !== 0) {
            console.log("not save!!!");
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire("Error", "There is still an error", "error");
            });

            setNamefile({ ...namefile, upload_stat: true });
          } else {
            console.log("save!!!");

            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              data.append("save", true);
              axios(config_upload)
                .then(function (response2) {
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch(function (error2) {
                  Swal.fire("Error", error2, "error");
                });
            });

            setfiller_Order({ ...filler_Order, save: true });
            setNamefile({ ...namefile, upload_stat: false });
          }
        })
        .catch(function (error) {
          Swal.fire({
            title: "Error File Xlsx",
            text: "File Xlsx can't null " + error.message,
            icon: "warning",

            confirmButtonColor: "#3085d6",

            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href =
                "/Production_Planing/Upload_Order_Forecast";
            }
          });
        });
    } else {
      Swal.fire(
        "Please select year and month.",
        "Year and cannot blank",
        "warning"
      );
    }

    inputFile.current.value = "";
  }

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onButtonClick2 = () => {
    // console.log("oem_name",oem_name);
    if (oem_name === "Komatsu") {
      inputFile4.current.click();
    }

    if (oem_name === "Kubota") {
      inputFile2.current.click();
    }

    /*  Swal.fire("Waiting for KUBOTA", "waiting", "Info"); */
  };

  const onButtonClick3 = () => {
    inputFile3.current.click();
  };

  function montchange(monthnumber2) {
    var monthList = [{i:1,v:"JAN"},{i:2,v:"FEB"},{i:3,v:"MAR"},{i:4,v:"APR"},{i:5,v:"MAY"},{i:6,v:"JUN"},{i:7,v:"JUL"},{i:8,v:"AUG"},{i:9,v:"SEP"},{i:10,v:"OCT"},{i:11,v:"NOV"},{i:12,v:"DEC"}]
    let finai=[];
   /*  load_suggrest(); */
    let setss = date.getMonth() + 1;
    let monthnumber = '';
   if(monthnumber2 === "" || monthnumber2 === undefined){
      monthnumber = setss;
   }else{
    monthnumber = monthnumber2
   }
    if(parseInt(monthnumber) < 8){
      for(let item = parseInt(monthnumber); item <= monthList.length; item++){
        var list = monthList.filter((e)=> {return e.i === item})
        finai.push(list[0])
      }
    }else{
      if (parseInt(monthnumber) === 9) {
        finai.push({i:9,v:"SEP"},{i:'10',v:"OCT"},{i:"11",v:"NOV"},{i:"12",v:"DEC"},{i:"1",v:"JAN"})
      }else if(parseInt(monthnumber) === 10){
        finai.push({i:'10',v:"OCT"},{i:"11",v:"NOV"},{i:"12",v:"DEC"},{i:"1",v:"JAN"},{i:"2",v:"FEB"})
        /* finai.push(list[0].v) */
      }else if(parseInt(monthnumber) === 11){
        finai.push({i:"11",v:"NOV"},{i:"12",v:"DEC"},{i:"1",v:"JAN"},{i:"2",v:"FEB"},{i:"3",v:"MAR"})
        /* finai.push(list[0].v) */
      }else if(parseInt(monthnumber) === 12){
        finai.push({i:"12",v:"DEC"},{i:"1",v:"JAN"},{i:"2",v:"FEB"},{i:"3",v:"MAR"},{i:"4",v:"APR"})
        /* finai.push(list[0].v) */
      }


    }
   
    let temp = []; 
    if (finai.length > 0) {
      for(let i1 = 1; i1 < 5; i1++){
        /* console.log([i1],"เช็ค i1",finai[i1]); */
        temp.push(finai[i1].v);
      }
    }
 

    /* mountChean.N1 */
    if(temp.length > 0){
      setn1Change(temp[0]);
      setn2Change(temp[1]);
      setn3Change(temp[2]);
      setn4Change(temp[3]);
      
    }
  

    
  }

  let open = "xxxx";
  const rowsUpload = [];

  for (var indexin = 0; indexin < upload.length; indexin++) {
    const rowUp = {};

    rowUp["num"] = indexin + 1;
    if (upload[indexin].error == "not found") {
      rowUp["no"] = <span style={{ color: "red" }}>{upload[indexin].no}</span>;
    } else {
      rowUp["no"] = upload[indexin].no;
    }
    rowUp["name"] = upload[indexin].name;
    rowUp["wip"] = upload[indexin].wip;
    rowUp["fg"] = upload[indexin].fg;
    rowUp["in_hand"] = upload[indexin].in_hand;
    rowUp["delivery"] = upload[indexin].delivery;
    rowUp["pending"] = upload[indexin].pending;
    rowUp["amount"] = upload[indexin].amount_forcast;
    if (upload[indexin].n1 == "fill in here too!!") {
      rowUp["N1"] = <span style={{ color: "red" }}>{upload[indexin].n1}</span>;
    } else {
      rowUp["N1"] = upload[indexin].n1;
    }
    if (upload[indexin].n2 == "fill in here too!!") {
      rowUp["N2"] = <span style={{ color: "red" }}>{upload[indexin].n2}</span>;
    } else {
      rowUp["N2"] = upload[indexin].n2;
    }
    if (upload[indexin].n3 == "fill in here too!!") {
      rowUp["N3"] = <span style={{ color: "red" }}>{upload[indexin].n3}</span>;
    } else {
      rowUp["N3"] = upload[indexin].n3;
    }
    if (upload[indexin].n4 == "fill in here too!!") {
      rowUp["N4"] = <span style={{ color: "red" }}>{upload[indexin].n4}</span>;
    } else {
      rowUp["N4"] = upload[indexin].n4;
    }
    rowUp["status"] = upload[indexin].status ? (
      <span style={{ color: "green" }}> true</span>
    ) : (
      <span style={{ color: "red" }}> false</span>
    );
    rowUp["error"] = (
      <span style={{ color: "red" }}>{upload[indexin].error}</span>
    );
    rowsUpload.push(rowUp);
  }

  const data = {
    columns: [
      {
        label: "Item",
        field: "num",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "WIP",
        field: "wip",
        sort: "asc",
        width: 50,
      },
      {
        label: "FG",
        field: "fg",
        sort: "asc",
        width: 50,
      },
      {
        label: "In_Hand",
        field: "in_hand",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Delivery",
        field: "delivery",
        sort: "asc",
        width: 50,
      },
      {
        label: "Pending",
        field: "pending",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N1" */n1Change,
        field: "N1",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N2" */n2Change,
        field: "N2",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N3" */n3Change,
        field: "N3",
        sort: "asc",
        width: 50,
      },
      {
        label: /* "N4" */n4Change,
        field: "N4",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Error Message",
        field: "error",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsUpload,
  };

  var oem_name;
  oem.map((x) => {
    if (x.id === getOem()) {
      oem_name = x.name;
    }
  });

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="pp" activemenu="uof" submenu="none" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Upload Order Forecast{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Upload Order Forecast
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_Order.year}
                    onChange={(e) =>
                      setfiller_Order({ ...filler_Order, year: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                  <label htmlFor="">Year</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_Order.month}
                    onChange={(e) => {
                      setfiller_Order({
                        ...filler_Order,
                        month: e.target.value,
                      })
                      montchange(e.target.value);
                    }
                  }
                  >
                    <option value="Sletect">Sletect</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label htmlFor="">Month</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {/*   <div className="col-2">
                <div className="form-group ">
                  <button
                    className="btn btn-block btn-primary"
                    onClick={onButtonClick}
                  >
                    Browse Forecast file
                  </button>
                  <input
                    type="file"
                    onChange={Uploadfile}
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                  />
                </div>
              </div> */}
              {feature.map((e) => {
                //console.log(e.feature)
                if (e.feature === "Browse Forecast file") {
                  return <BTN onClick={onButtonClick} name={e.feature_name} />;
                }
                if (e.feature === "Browse OEM Forecast file") {
                  return (
                    <BTN
                      onClick={onButtonClick2}
                      name={"Browse " + oem_name + " Forecast file"}
                    />
                  );
                }
                if (
                  e.feature === "Load Form Service" &&
                  getOem() === "ad5431b1-4136-4695-a1c2-7fe02b137d68"
                ) {
                  return (
                    <BTN onClick={loadFromService} name={e.feature_name} />
                  );
                }
                if (e.feature === "download template.xls") {
                  return (
                    <BTN
                      href={Product_forcast_excel}
                      download={"upload_Product_forcast.xlsx"}
                      name={e.feature_name}
                    />
                  );
                }

                if (e.feature === "Update Forecast File") {
                  return <BTN onClick={onButtonClick3} name={e.feature_name} />;
                }
              })}

              {/*   <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick = {onButtonClick3}
                  >
                    Add Forecast File
                  </button>
                </div>
              </div> */}
              {/*   <BTN onClick = {onButtonClick} name = {"Browse Forecast file"} /> */}
              <input
                type="file"
                onChange={Uploadfile}
                id="file"
                ref={inputFile}
                style={{ display: "none" }}
              />

              {/*      <BTN onClick = {onButtonClick2} name = {"Browse KUBOTA Forecast file"} /> */}
              <input
                type="file"
                onChange={Uploadfile_kubota}
                id="file"
                ref={inputFile2}
                style={{ display: "none" }}
              />

              <input
                type="file"
                onChange={Uploadfile_komatsu}
                id="file"
                ref={inputFile4}
                style={{ display: "none" }}
              />

              <input
                type="file"
                onChange={Uploadfile_add}
                id="file"
                ref={inputFile3}
                style={{ display: "none" }}
              />

              {/*    <BTN onClick = {showmessage} name = {"Load Form Service"} /> */}
              {/* <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={showmessage}
                  >
                    Load Form Service
                  </button>
                </div>
              </div> */}

              {/* <div className="col-2">
                <div className="form-group ">
                  {namefile.upload_stat  ? (
 <button
 type="button"
 className={"btn btn-block btn-danger"}
 disabled={namefile.upload_stat}
 onClick={Upload_file_toBase}
>
                  Save To Database
                  </button>
                  ):(
                    <button
                    type="button"
                    className={"btn btn-block btn-success"}
                    disabled={namefile.upload_stat}
                    onClick={Upload_file_toBase}
                  >
                    Save To Database
                  </button>
                  )}
                 
                </div>
              </div> */}

              {/*  <a 
                    href={Product_forcast_excel}
                    download="upload_Product_forcast.xlsx"
                    //style={{ fontSize: "13px" }} 
                  > */}
              {/*   <BTN href = {Product_forcast_excel} download ={"upload_Product_forcast.xlsx"} name = {"download template.xls"} /> */}
              {/*  <button
                    className="btn btn-block btn-primary"
                     >
                       download template.xls
                     </button> */}
              {/*  </a>    */}
              {/*  <input
                    type="file"
                    onChange={Uploadfile_kubota}
                    id="file"
                    ref={inputFile2}
                    style={{ display: "none" }}
                  /> */}

              {/* <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className={"btn btn-block btn-primary "}
                    
                    onClick={clearData}
                  >
                    Clear for new upload
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-8">
                  <h3 className="card-title">Upload Order Forecast</h3>
                </div>
                <div className="col-4">
                  <h3 className="card-title">{mod_date_formService}</h3>
                </div>
              </div>

              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div
                className="table-responsive" /* style={{ height: "500px" }} */
              >
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  sortable={false}
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
              <div className="table-responsive" style={{ display: "none" }}>
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  sortable={false}
                  bordered
                  id="table-to-xls"
                  hover
                  paging={false}
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              <div className="card-footer">
                <div
                  className="form-group "
                  style={{
                    display:
                      upload.filter((el) => el.status === false).length > 0
                        ? "block"
                        : "none",
                  }}
                >
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="table-to-xls"
                    filename={
                      "Error product" +
                      " " +
                      moment(new Date()).format("DD-MM-yyyy")
                    }
                    sheet="tablexls"
                    buttonText="Error Product Report"
                  />
                </div>
              </div>
            </div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>

      {/* <Footter /> */}
    </div>
  );
}
export default Uploadorderforecast;
/* <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Upload Order Forecast</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                  Upload Order Forecast
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                <select className="custom-select select2 form-control">
                    <option value="">Seclect Year</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <label htmlFor="">Year</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">

                  <select className="custom-select select2 form-control">
                    <option value="">Seclect Month</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label htmlFor="">Month</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary ">
                    Browse Forecast file
                  </button>
                  <a href="#" style={{ fontSize: "13px" }}>
                    {" "}
                    download template.xls
                  </a>
                </div>
                </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"

                  >
                    Load Form Service
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary ">
                    Save To Database
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Upload Order Forecast</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" style={{ height: "500px" }}>

              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>

      </div> */
