import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import moment from "moment";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";

function Promotion(params) {
  const [promotion, setpromotion] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  const [filter_promotion, setFilter_promotion] = useState({
    topic_promotion: "",
   company_id: getUser().com,
   oem_id: getOem(),
  });
  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */
  const GetPromotion = async () => {
    //console.log(filler_supplier);
    axios({
      method: "post",
      url: Configs.API_URL + "/promotion/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_promotion,
    })
      .then(function (response) {
        //console.log(response.data);
        setpromotion(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

 

  useEffect(() => {
    GetPromotion();
    GetCompany();
  }, []);

  const clearFilter = async () => {
   setFilter_promotion({
      ...filter_promotion,
      topic_promotion:"",
    });
   
    filter_promotion.topic_promotion = "";
    GetPromotion();
   
  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Promotion/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/promotion/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Promotion";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];
 
  for (var index = 0; index < promotion.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["topic_promotion"] = promotion[index].topic_promotion;
    rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            promotion[index].image_path !== null
              ? Configs.API_URL_IMG + promotion[index].image_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );

    rowItem["detail"] = promotion[index].detail_promotion;
    rowItem["date"] = moment(promotion[index].start_promotion).format('DD/MM/yyyy') + " - " + moment(promotion[index].end_promotion).format('DD/MM/yyyy');
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
             href={"/Warehouse_Management/Promotion/read/"+ promotion[index].id}
            key={promotion[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
              href={"/Warehouse_Management/Promotion/edit/"+ promotion[index].id}
             key={promotion[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
             key={promotion[index].id}
            className=" btn btn-xs "
             onClick={deldata.bind(this,promotion[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Topic Promotion",
        field: "topic_promotion",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image", 
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
        label: "Detail Promotion", 
        field: "detail",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date", 
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Promotion{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Promotion</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filter_promotion.topic_promotion}
                    onChange={(e) => {
                      setFilter_promotion({
                        ...filter_promotion,
                        topic_promotion: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Topic Promotion</label>{" "}
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetPromotion();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Promotion List</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "   style={{ whiteSpace: 'nowrap' }} 
            >
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    {/*  <Footter/> */}
     </div>
     
  );
}

export default Promotion;
