import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import QRCode from "qrcode.react";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import "../../assets/css/table.css";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken } from "../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

function Factory_parameter_add(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [line, setline] = useState({
    company_id: getOem(),
    id: "",
    line_name: "",
    is_use: "",
    sort: "",
  });
  const [line_machine, setline_machine] = useState([]);
/*   console.log(line_machine.length); */
  const [machine, setmachine] = useState([]);

  const [machine_select, setmachine_select] = useState([]);
  const [filler_machine, setFiller_machine] = useState({
    oem_id: getOem(),
    id: "",
    machine_name: "",
  });

  const { id } = useParams();

  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  useEffect(() => {
  /*   console.log("id:" + id); */
    GetLine_view();
   /*  GetLineMachine_view(); */
    GetMachine();
  }, []);

  const GetLine_view = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url: Configs.API_URL + "/factoryParameter/line/findLineById?id=" + id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
        /*   console.log(response.data); */
          setline({
            company_id: response.data.company_id,
            line_name: response.data.line_name,
            is_use: response.data.is_use,
            sort: response.data.sort,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

 

  const GetMachine = async () => {
    if (mode.mode === "add") {
      axios({
        method: "post",
        url: Configs.API_URL + "/factoryParameter/machine/filter",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: filler_machine,
      })
        .then(function (response) {
        /*   console.log(response.data); */
          setmachine(response.data);

          const temp_machine = [];
          for (let index = 0; index < response.data.length; index++) {
            const element = {};
            element["id"] = response.data[index].id;
            element["machine_name"] = response.data[index].machine_name;
            element["isSelect"] = false;
            temp_machine.push(element);
          }
          setmachine_select(temp_machine);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
     
   
        axios({
          method: "post",
          url: Configs.API_URL + "/factoryParameter/machine/filter",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: filler_machine,
        })
          .then(function (response) {
         
             //console.log(response.data)
              axios({
                method: "get",
                url: Configs.API_URL + "/factoryParameter/line/findLineMachineById?id=" + id,
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
              })
                .then(function (response2) {
                
                  
                  const temp_machine = [];
                  for (let index = 0; index < response.data.length; index++) {
                    const element = {};
                    element["id"] = response.data[index].id;
                    element["machine_name"] = response.data[index].machine_name;
                    element["isSelect"] = false;
                    for (let k = 0; k < response2.data.length; k++) {
                      
                      if (response.data[index].id === response2.data[k].machine_id) {
                        /* console.log(k); */
                        element["isSelect"] = true;
                      }
                    
                    }
                    temp_machine.push(element)
                  }
                  //console.log(temp_machine)
                  setmachine_select(temp_machine);
                })
                .catch(function (error) {
                  console.log(error);
                });
            
          
          })
          .catch(function (error) {
            console.log(error);
          });

    }
  };

  const machine_select_item = async (el) => {

    const tempData = machine_select;
 
      if (tempData[el.target.id].isSelect) {
        tempData[el.target.id].isSelect = false;
        //tempData[el.target.id].machine_name = "test"
      }else{
        tempData[el.target.id].isSelect =true;
        //tempData[el.target.id].machine_name = "test"
       
      }
      //console.log(tempData[el.target.id].isSelect);
      let check = tempData.filter((element) => {
        return element.isSelect === true;
      });
     
      setmachine_select(tempData)
    
  }

  /*     for(var i = 0; i < machine_select.length; i++){
    console.log("ลูปแรกมาแล้ว"+i);
     for(var k = 0; k < line_machine.length; k++){
      console.log("ลูปสองมาแล้ว");
      console.log(machine_select[i].id);
      console.log(line_machine.machine_id);
      if(machine_select[i].id === line_machine.machine_id){
      var  check = true;
      console.log("true");
      }else{
       var check = false;
       console.log("false");
     } 
   /   }else{
        var check = "";
        console.log("ไม่มี")
      } 
    
     } 
  }    */

  
  /*  console.log(machine_select.length);
  console.log(temp_save_add); */
  function saveOption(params) {
    let machine_select_temp = machine_select.filter((element) => {
      return element.isSelect === true;
    });
    const error_list = [];
    if(line.line_name.trim() == ""){
      let temp_err = {
        message:"Please enter information in the fields >>> [Production Line]."
      }
      error_list.push(temp_err);
    }
    if(machine_select_temp.length < 1){
      let temp_err = {
        message:"Please enter information in the fields >>> [Select Machine]."
      }
      error_list.push(temp_err);
    }
    if(error_list.length > 0){
      var err_message = "";
      for(var e =0; e < error_list.length; e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
        const temp_save_add = {
          oem_id: getOem(),
          line_name: line.line_name.trim(),
          machine_id: machine_select_temp,
        };
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/factoryParameter/line/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save_add,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/line";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }

      if (mode.mode === "edit") {

        const temp_save_edit = {
          oem_id: getOem(),
          line_id: id,
          line_name: line.line_name.trim(),
          machine_id: machine_select_temp,
        };
        //console.log(temp_save_edit)
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/factoryParameter/line/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save_edit,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/line";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       } 
    }
  
 
  
  }

  function cancelOption(params) {

      Swal.fire({
        title: 'Do you want to cancel and leave the changes?',
        icon:'warning',
        showCancelButton: true,
        confirmButtonText: `Yes`,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText:`No`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
          "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/line";
        }
      }
      );
      
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="facpara" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-line-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-line"
                    role="tab"
                    aria-controls="custom-tabs-four-line"
                    aria-selected="false"
                  >
                    Line
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-line"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-line-tab"
                >
                  <h3 class="title ng-binding">{pageMode} Line</h3>
                  <div className="tab-custom-content"></div>
                  <br></br>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            value={line.line_name.trim()}
                            disabled={disable}
                            onChange={(e) => {
                              setline({
                                ...line,
                                line_name: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Production Line
                          {line.line_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                          </label>{" "}
                        </div>
                      </div>

                      <div className="col-md-10"></div>

                      <div className="col-12">
                        <label>Select Machine
                        
                        <span style={{ color: "red" }}> *</span>
                   
                              </label>
                      </div>

                      {machine_select.map((el,index) => {
                    
                          return (
                            <div className="col-3">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={index}
                                  name={el.machine_name}
                                  value={el.id}
                                  defaultChecked={el.isSelect}
                                  onChange={machine_select_item.bind(this)}
                                  disabled={mode.mode === "read" ? true : false}
                                />
                                <label
                                  className="form-check-label"
                                  for={index}
                                >
                                  {el.machine_name /* ? "true":"false" */}
                                </label>
                              </div>
                            </div>
                          );
                       
                        
                      })}
                    </div>

                    {mode.mode === "read" ? (
                      <div className="row mb-2">
                        <div className="col-sm-1">
                          <button
                            type="button"
                            onClick={cancelOption}
                            class="btn btn-block btn-danger "
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="row mb-2">
                        <div className="col-sm-1">
                          <button
                            type="button"
                            onClick={saveOption}
                            class="btn btn-block btn-success "
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-sm-1">
                          <button
                            type="button"
                            onClick={cancelOption}
                            class="btn btn-block btn-danger "
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-4"></div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-four-machine"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-machine-tab"
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
  {/*     <Footter /> */}
    </div>
  );
}
export default Factory_parameter_add;
