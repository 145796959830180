import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Configs from "../../../../config";
import { useParams } from "react-router";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import NumberFormat from "react-number-format";

import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";

import "../../../../assets/css/ProductService.css";

import { LightningBolt, Arrow } from "../Incentive_User_icon_img/All_Icon";

import PointCartLine from "../Incentive_User_component/Point_Cart_Line";
import CategoryCard from "../Incentive_User_component/Category_Card";
import ProductServiceCard from "../Incentive_User_component/Product_Service_Card";
import ProductServiceCardMultiSlider from "../Incentive_User_component/Product_Service_Card_Multi_Slider";

import Pagination2 from "../Incentive_User_component/Pagination2";
import Pagination3 from "../Incentive_User_component/Pagination3";

function Product_Service_Category({ mode }) {
  const { id } = useParams();

  const [currentCetagory, setCurrentCetagory] = useState(id);

  const [currentPage, setCurrentPage] = useState(1);

  const [categoryData, setCategoryData] = useState([]);

  const [productData, setProductData] = useState([]);

  const [totalProductData, setTotalProductData] = useState(0);

  const [searchOption, setSearchOption] = useState({
    productName: "",
    minPoint: "",
    maxPoint: "",
  });

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithOutCommas = (x) => {
    return x.toString().replace(/,/g, "");
  };

  async function GetProductGroup() {
    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
    };

    let axiosData = {};

    if (mode === "category" || mode === "new" || mode === "hit") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive + "/api/productService/GetProductCategory",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    if (mode === "flashsale") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/productService/GetProductCategoryFlashSale",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    await axios(axiosData)
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            categoryId: item.category_id,
            categoryName: item.category_name,
            categoryImageName: item.category_image,
            categoryImagePath:
              item.category_image_path !== "" &&
              item.category_image_path !== null &&
              item.category_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.category_image_path
                : userdefault_img.imgs,
          };

          allData.push(data);
        }

        setCategoryData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetQuantityProduct() {
    let startDataIndex = currentPage * 20 - 20 + 1;
    let endDataIndex = 20;

    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
      categoryId: currentCetagory,
      productName: searchOption.productName.trim(),
      minPoint: numberWithOutCommas(searchOption.minPoint),
      maxPoint: numberWithOutCommas(searchOption.maxPoint),
      startDataIndex: startDataIndex,
      endDataIndex: endDataIndex,
    };

    let axiosData = {};

    if (mode === "category") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/productService/GetQuantityProductModeCetagory",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    if (mode === "flashsale") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/productService/GetQuantityProductModeFlashSale",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    if (mode === "new" || mode === "hit") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/productService/GetQuantityProductModeNewHit",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    await axios(axiosData)
      .then(async (response) => {
        setTotalProductData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProduct() {
    let startDataIndex = currentPage * 20 - 20;
    let endDataIndex = 20;

    let tempInput = {
      oem_id: getOem(),
      company_id: getUser().com,
      categoryId: currentCetagory,
      productName: searchOption.productName.trim(),
      minPoint: numberWithOutCommas(searchOption.minPoint),
      maxPoint: numberWithOutCommas(searchOption.maxPoint),
      startDataIndex: startDataIndex,
      endDataIndex: endDataIndex,
    };

    let axiosData = {};

    if (mode === "category") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/productService/GetProductModeCetagory",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    if (mode === "flashsale") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/productService/GetProductModeFlashSale",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    if (mode === "new") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive + "/api/productService/GetProductModeNew",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    if (mode === "hit") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_incentive + "/api/productService/GetProductModeHit",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempInput,
      };
    }

    await axios(axiosData)
      .then(async (response) => {
        let allData = [];

        for (let item of response.data) {
          let data = {
            productId: item.product_id,
            productName: item.product_name,
            productDetail: item.product_detail,
            productValue: item.product_point,
            productSold: item.product_count_sales,
            productImageName: item.product_image,
            productImagePath:
              item.product_image_path !== "" &&
              item.product_image_path !== null &&
              item.product_image_path !== undefined
                ? Configs.API_URL_IMG_incentive + item.product_image_path
                : userdefault_img.imgs,
          };

          if (item.fs_id) {
            data["productValue"] = item.fs_product_point;
            data["discount"] = item.fs_discount;
            data["discountValue"] = item.fs_point;
          }

          allData.push(data);
        }
        allData = allData.sort((a, b) => a.productValue - b.productValue);
        setProductData(allData);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function clearSearchData() {
    setSearchOption({
      productName: "",
      minPoint: "",
      maxPoint: "",
    });

    searchOption.productName = "";
    searchOption.minPoint = "";
    searchOption.maxPoint = "";

    setCurrentPage(1);

    if (mode === "flashsale" || mode === "new" || mode === "hit") {
      setCurrentCetagory(id);
    }

    await GetQuantityProduct();
    await GetProduct();
  }

  useEffect(async () => {
    await GetProductGroup();
    await GetQuantityProduct();
    await GetProduct();
  }, []);

  useEffect(async () => {
    await GetQuantityProduct();
    await GetProduct();
  }, [currentPage, currentCetagory]);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>สินค้าและบริการ</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Incentive Point</li>
                  <li className="breadcrumb-item active">สินค้าและบริการ</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <PointCartLine />
          </div>

          <div className="row" style={{ alignItems: "flex-end" }}>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  id="text_empname"
                  value={searchOption.productName}
                  onChange={(e) => {
                    setSearchOption({
                      ...searchOption,
                      productName: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">ชื่อสินค้า</label>{" "}
              </div>
            </div>

            <div className="col-6 col-md-2 col-lg-2 col-xl-2">
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "black",
                  lineHeight: "normal",
                  margin: "0px",
                }}
              >
                ช่วงคะแนน
              </label>

              <div className="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  required="false"
                  id="text_empname"
                  thousandSeparator
                  value={searchOption.minPoint}
                  onChange={(e) => {
                    setSearchOption({
                      ...searchOption,
                      minPoint: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">คะแนนน้อย</label>{" "}
              </div>
            </div>

            <div className="col-6 col-md-2 col-lg-2 col-xl-2">
              <div className="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  required="false"
                  id="text_empname"
                  thousandSeparator
                  value={searchOption.maxPoint}
                  onChange={(e) => {
                    setSearchOption({
                      ...searchOption,
                      maxPoint: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">คะแนนมาก</label>{" "}
              </div>
            </div>

            <div className="col-6 col-md-3 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  id="btn_search"
                  className="btn btn-block btn-primary"
                  onClick={async () => {
                    setCurrentPage(1);
                    await GetQuantityProduct();
                    await GetProduct();
                  }}
                >
                  ค้นหา
                </button>
              </div>
            </div>

            <div className="col-6 col-md-3 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  id="btn_clear"
                  className="btn btn-block btn-primary"
                  onClick={async () => {
                    await clearSearchData();
                  }}
                >
                  ล้างการค้นหา
                </button>
              </div>
            </div>
          </div>

          <div className="row" style={{ padding: "10px" }}>
            <ProductServiceCardMultiSlider
              id="CategoryCard"
              data={categoryData.map((item) => {
                return (
                  <>
                    <div style={{ margin: "0px 6px" }}>
                      <a
                        // href={`/Human_Resource/incentive_user/product_service/category/${item.categoryId}`}
                        onClick={() => {
                          setCurrentPage(1);
                          setCurrentCetagory(item.categoryId);
                        }}
                      >
                        <CategoryCard data={item} />
                      </a>
                    </div>
                  </>
                );
              })}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "30px 0px 20px 0px",
            }}
          >
            <div style={{ width: "98%", borderBottom: "solid 1px #E0E0E0" }} />
          </div>

          <label
            style={{
              fontSize: "32px",
              fontWeight: "700",
              lineHeight: "normal",
              color: "black",
              margin: "0px 20px",
            }}
          >
            {categoryData.find((item) => {
              return item.categoryId === currentCetagory;
            })
              ? categoryData.find((item) => {
                  return item.categoryId === currentCetagory;
                }).categoryName
              : ""}
          </label>

          <div
            style={{
              margin: "10px",
              padding: "10px 0px",
              backgroundColor: "#E4F4FF",
              borderRadius: "10px",
            }}
          >
            <div
              className="psecat_grid"
              style={{
                margin: "10px",
                display: "grid",
                // gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
              }}
            >
              {/* <div
                // className="col-auto"
                style={{ display: "flex", flexWrap: "wrap" }}
              > */}
              {productData.map((item) => {
                return (
                  <>
                    {/* <div
                      className="col-auto"
                      // style={{ display: "flex", margin: "auto", flexWrap: "wrap" }}
                    > */}
                    <div
                      className="psecat_card"
                      style={{
                        // margin: "20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={`/Human_Resource/incentive_user/product_service/product_view_details/${item.productId}`}
                      >
                        <ProductServiceCard data={item} />
                      </a>
                    </div>
                    {/* </div> */}
                  </>
                );
              })}
            </div>
            {/* <div className="col"></div> */}
          </div>
          {/* </div> */}

          <div className="pagination_show_1">
            <Pagination2
              currentPage={currentPage}
              total={totalProductData}
              limit={20}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>

          <div className="pagination_show_2">
            <Pagination3
              currentPage={currentPage}
              total={totalProductData}
              limit={20}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Product_Service_Category;
