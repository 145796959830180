import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
// import './all_project.css';
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import {
  Input,
  Modal,
  Card,
  Avatar,
  Divider,
  Tooltip,
  Dropdown,
  Space,
  Checkbox,
  Switch,
  Upload,
  ConfigProvider,
  Drawer,
  DatePicker,
} from "antd";
import { ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";
import "./ticket_style.css";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function Ticket_Detail({ params, id }) {
  // console.log('id', id)
  const idRef = useRef(id);
  const [fileList, setFileList] = useState([]);
  const [fileRemark, setFileRemark] = useState([]);
  let [remarkList, setRemarkList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewOpenRemark, setPreviewOpenRemark] = useState(false);
  const [previewImageRemark, setPreviewImageRemark] = useState("");
  const [previewTitleRemark, setPreviewTitleRemark] = useState("");

  const { TextArea } = Input;
  let [delIMG, setDelIMG] = useState([]);
  const [isResponse, setIsResponse] = useState({
    lg: false,
    md: false,
    sm: false,
    xs: false,
  });

  const [FullProject, setFullProject] = useState({
    StrProjectNameShow: "",
    StrProjectCode: "",
    GenTicket_No: "",
    memberAll: [],
  });

  const [Tickect_List, setTickect_List] = useState({
    ticket_code: "",
    ticket_no: "",
    ticket_tkt_id: "",
    ticket_severity_id: "",
    ticket_start_date: "",
    ticket_notified_time: "",
    ticket_administrator: "",
    ticket_create_date: "",
    ticket_create_by: "",
    ticket_description: "",
    ticket_name: "",
    ticket_customer_name: "",
    ticket_update_date: "",
    ticket_edit_by: "",
    ticket_pr_id: "",
    ticket_tsb_id: "",
    ticket_id: "",
    ticket_stk_id: "",
    img_arry: [],
    profile_path_edit_by: "",
    profile_path_create_by: "",
    profile_path_admin: "",
  });
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: 150,
      fontSize: 16,
      marginTop: "-18px",
      marginLeft: "-9px",
    }),
  };
  const [LogList, setLogList] = useState([]);
  // console.log('LogList', LogList)
  const [UserPermi, setUserPermi] = useState({
    iu_ps_id: "",
    iu_id: "",
    iu_profile_path: "",
  });
  const [checkMode, setCheckMode] = useState({
    remark_id: "",
    mode: "Add",
  });

  //   console.log(id);
  useEffect(() => {
    setIsResponse({
      ...isResponse,
      lg: window.innerWidth < 1240,
    });
    checkRoleUserTicket();
    // filterAllTicket();
    getByIdTicket(id);
    getLogAll(id);
    getByIdRemark();
  }, []);

  const handleCancel = () => setPreviewOpen(false);

  const handleCancelRemark = () => setPreviewOpenRemark(false);

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleChangeRemark = async (
    { fileList: newFileList },
    remarkNo,
    remark_prop
  ) => {
    const formData = new FormData();
    // console.log('newFileList :>> ', newFileList);
    newFileList.forEach((file) => {
      // console.log(file.originFileObj);
      if (file.originFileObj) {
        formData.append(`files`, file.originFileObj);
      }
    });
    formData.append(`remark_id`, remarkNo);
    if (
      remark_prop &&
      remark_prop.remarkIMGDel &&
      remark_prop.remarkIMGDel.length > 0
    )
      formData.append(`files_del`, remark_prop.remarkIMGDel);
    // console.log("newFileList.originFileObj: ",newFileList);
    // const updatedRemarkList = remarkList.map(remark => {
    //   if (remark.remark_no === remarkNo) {
    //     return { ...remark, remarkIMG: newFileList };
    //   }
    //   return remark;
    // });
    formData.append(`img_ticket_id`, idRef.current);
    formData.append(`img_type`, "img_remark");
    try {
      const response = await axios.post(
        `${Configs.API_Ticket}/api/image/upload/${Tickect_List.ticket_no}`,
        // formData,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
          },
        }
      );
      // setFileList([]);
      getByIdRemark();

      // console.log(response.data);
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการอัปโหลดไฟล์:", error);
    }
    getByIdRemark();
    //  console.log("updatedRemarkList",updatedRemarkList);
    //  setRemarkList(updatedRemarkList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handlePreviewRemark = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImageRemark(file.url || file.preview);
    setPreviewOpenRemark(true);
    setPreviewTitleRemark(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleRemove = (file) => {
    if (fileList) {
      const newFileList = [...fileList.filter((item) => item.uid !== file.uid)];
      setDelIMG([...delIMG, file.img_id]);
      delIMG = [...delIMG, file.img_id];
      setFileList(newFileList.length > 0 ? newFileList : undefined);
    }
    Swal.fire({
      title: "คุณต้องการลบรูปภาพนี้นี้ใช่หรือไม่",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "ใช่",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ไม่",
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: "swal2-confirm-button",
        cancelButton: "swal2-cancel-button",
      },
      didOpen: () => {
        const confirmButton = document.querySelector(".swal2-confirm-button");
        const cancelButton = document.querySelector(".swal2-cancel-button");
        confirmButton.style.width = "150px";
        cancelButton.style.width = "150px";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        savePicture();
        getByIdTicket(id);
      } else if (result.isDismissed) {
        console.log("Cancelled");
      }
    });
  };

  // const handleRemoveRemarkFile = (file) => {
  const handleRemoveRemarkFile = async (
    { fileList: newFileList },
    remarkNo
  ) => {
    if (fileRemark) {
      const newFileList = fileRemark.filter(
        (item) => item.uid !== newFileList.file.uid
      );
      setFileRemark(newFileList.length > 0 ? newFileList : undefined);
    }

    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/image/delImage/${remarkNo}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        getByIdRemark();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
    getByIdRemark();
  };
  // console.log("LogList: ", LogList);
  // console.log("fileRemark: ", fileRemark);

  function ClickApprove(id_ticket) {
    // console.log("C",id_ticket);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = {
      user_id: fupValue,
      ticket_oem_id: getOem(),
      ticket_company_id: getUser().com
    };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickApprove/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function ClickPadding(id_ticket) {
    // console.log("C",id_ticket);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = { 
      user_id: fupValue,
      ticket_oem_id: getOem(),
      ticket_company_id: getUser().com
    };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickPadding/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // if((response = true))
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function ClickClose(id_ticket) {
    // console.log("C",id_ticket);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = { 
      user_id: fupValue,
      ticket_oem_id: getOem(),
      ticket_company_id: getUser().com
    };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickClose/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }
  function ClickReopen(id_ticket, is_dev) {
    console.log("C", is_dev);
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    let temp = {
      user_id: fupValue,
      is_dev,
      ticket_oem_id: getOem(),
      ticket_company_id: getUser().com
    };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/ticket/ClickReopen/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  const handleResize = () => {
    setIsResponse({
      ...isResponse,
      lg: window.innerWidth < 1240,
    });
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const uploadButtonRemark = (isDisabled) => (
    <button
      disabled={isDisabled}
      style={{
        border: 0,
        background: "none",
        cursor: isDisabled ? "not-allowed" : "pointer",
      }}
      type="button"
    >
      <PlusOutlined disabled={isDisabled} />
      <div
        style={{
          marginTop: 8,
        }}
        disabled={isDisabled}
      >
        Upload
      </div>
    </button>
  );

  // console.log("fileList: ", fileList);

  function checkRoleUserTicket() {
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const fupValue = sessionData.fup;
    // console.log("fupValue: ", fupValue);
    let temp = {
      id: fupValue,
      oem_id: getOem(),
      com_id: getUser().com
    };
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/ticket/checkRoleUserTicket`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        console.log("dataRole: ", response.data[0].iu_ps_id);

        setUserPermi({
          ...UserPermi,
          iu_ps_id: response.data[0].iu_ps_id,
          iu_id: response.data[0].iu_id,
          iu_profile_path: response.data[0].iu_profile_path,
          ps_name: response.data[0].ps_name,
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function getLogAll(id_ticket) {
    setLogList([]);
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/ticket/getAllLog/${id_ticket}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        // Sort the logs by log_create_date in descending order (most recent first)
        const sortedData = response.data.sort(
          (a, b) => new Date(b.log_create_date) - new Date(a.log_create_date)
        );

        // Update the state with the sorted data
        sortedData.forEach((item) => {
          setLogList((prevList) => [
            ...prevList,
            {
              log_id: item.log_id,
              log_action: item.log_action,
              log_action_by: item.iu_firstname + " " + item.iu_lastname,
              log_create_date: item.log_create_date,
              iu_id: item.iu_id,
            },
          ]);
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  //  console.log("Tickect_List",Tickect_List);

  async function getByIdTicket(id) {
    // console.log(id);
    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    setFileList([]);
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/ticket/getByIdTicketDetail/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        oem_id: getOem(),
        com_id: getUser().com
      }
    })
      .then(async function (response) {
        //  console.log("getById: ", response.data);

        const tempFile = response.data.data_img.map((image) => {
          const arrayBufferView = new Uint8Array(image.img_data.data);
          const blob = new Blob([arrayBufferView], { type: "image/jpeg" });

          const url = URL.createObjectURL(blob);

          return {
            uid: image.img_id,
            name: image.img_name,
            img_id: image.img_id,
            status: "done",
            url: url,
            thumbUrl: url,
          };
        });

        setFileList(tempFile);

        // แปลงค่า severity_sla_time จาก นาที เป็นมิลลิวินาที
        // console.log(response.data.data_ticket[0].severity_sla_time);
        const severitySlaTimeInMilliseconds =
          Number(response.data.data_ticket[0].severity_sla_time) * 60 * 1000;
        const ticketStartDate = new Date(
          response.data.data_ticket[0].ticket_start_date
        );
        ticketStartDate.setTime(
          ticketStartDate.getTime() + severitySlaTimeInMilliseconds
        );
        const updatedTicketStartDate = ticketStartDate.toISOString();

        //  const prUserIdArray = JSON.parse(response.data.pr_user_id);
        setTickect_List({
          ...Tickect_List,
          ticket_id: response.data.data_ticket[0].ticket_id,
          ticket_code: response.data.data_ticket[0].ticket_code,
          ticket_no: response.data.data_ticket[0].ticket_no,
          ticket_name: response.data.data_ticket[0].ticket_name,
          ticket_tkt_id: response.data.data_ticket[0].ticket_tkt_id,
          ticket_severity_id: response.data.data_ticket[0].ticket_severity_id,
          ticket_start_date: response.data.data_ticket[0].ticket_start_date,
          ticket_notified_time:
            response.data.data_ticket[0].ticket_notified_time,
          ticket_administrator:
            response.data.data_ticket[0].ticket_administrator,
          ticket_create_date: response.data.data_ticket[0].ticket_create_date,
          ticket_create_by: response.data.data_ticket[0].ticket_create_by,
          ticket_description: response.data.data_ticket[0].ticket_description,
          ticket_customer_name:
            response.data.data_ticket[0].ticket_customer_name,
          ticket_update_date: response.data.data_ticket[0].ticket_update_date,
          ticket_edit_by: response.data.data_ticket[0].ticket_edit_by,
          ticket_pr_id: response.data.data_ticket[0].ticket_pr_id,
          ticket_tsb_id: response.data.data_ticket[0].ticket_tsb_id || "",
          create_by:
            response.data.data_ticket[0].create_by_f +
              " " +
              response.data.data_ticket[0].create_by_l || "",
          manage_by:
            response.data.data_ticket[0].edit_by_f +
              " " +
              response.data.data_ticket[0].edit_by_l || "",
          severity_sla_time:
            response.data.data_ticket[0].severity_sla_time || "",
          st_name: response.data.data_ticket[0].st_name || "",
          sla_status_name: response.data.data_ticket[0].sla_status_name || "",
          phase_name: response.data.data_ticket[0].phase_name || "",
          severity_name: response.data.data_ticket[0].severity_name || "",
          end_date: updatedTicketStartDate,
          img_arry: [],
          profile_path_admin:
            response.data.data_ticket[0].profile_path_admin || "",
          profile_path_create_by:
            response.data.data_ticket[0].profile_path_create_by || "",
          profile_path_edit_by:
            response.data.data_ticket[0].profile_path_edit_by || "",
        });

        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function addRemark() {
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);

    const _remarkList = [...remarkList];
    // ตรวจสอบจำนวน remark_id ที่เป็น null ใน remarkList
    const nullRemarkIds = _remarkList.filter(
      (remark) => remark.remark_id == null
    ).length;
    // console.log('nullRemarkIds', nullRemarkIds)

    if (nullRemarkIds > 0) {
      Swal.fire({
        title: "เพิ่มข้อมูลไม่ได้",
        text: "กรุณาบันทึกข้อมูล Remark ก่อนทำรายการถัดไป",
        icon: "error",
        confirmButtonText: "ตกลง",
      });
      return;
    }
    console.log("remarkList", remarkList);
    const checkRemarkNo = () => {
      let newRemarkNo = String(_remarkList.length + 1).padStart(2, "0");
      while (_remarkList.some((remark) => remark.remark_no === newRemarkNo)) {
        newRemarkNo = String(parseInt(newRemarkNo) + 1).padStart(2, "0");
      }
      return newRemarkNo;
    };
    _remarkList.unshift({
      remark_id: null,
      remark_no: checkRemarkNo(),
      remark_detail: "",
      remark_by: sessionData.fup,
      remark_create_by: sessionData.name,
      remark_ticket_id: idRef.current,
      remark_mode: "Add",
      remark_is_use: true,
      remarkIMG: [],
      ps_name: UserPermi.ps_name,
    });
    setRemarkList(_remarkList);
  }

  function getByIdRemark() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/ticket/getRemarkTicket/${idRef.current}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //  console.log("getByIdRemark: ", response.data);
        let tempImgRemark = [];

        const tempFile = response.data.data_img.map((image) => {
          const arrayBufferView = new Uint8Array(image.img_data.data);
          const blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          const url = URL.createObjectURL(blob);
          const thumbUrl = URL.createObjectURL(blob);
          return {
            uid: image.img_id,
            name: image.img_name,
            img_remark_id: image.img_remark_id,
            status: "done",
            url: url,
            thumbUrl: thumbUrl,
            is_old: true,
            is_del: false,
          };
        });

        // console.log("tempImgRemark",tempImgRemark);
        const sortedRemarks = response.data.data_remark.sort((a, b) => {
          return parseInt(b.remark_no) - parseInt(a.remark_no);
        });

        const formattedRemarks = sortedRemarks.map((remark) => ({
          remark_id: remark.remark_id,
          remark_no: remark.remark_no,
          remark_detail: remark.remark_detail,
          remark_by: remark.remark_by,
          remark_create_by:
            remark.iu_firstname + " " + remark.iu_lastname || "",
          remark_ticket_id: remark.remark_ticket_id,
          remarkIMG: [],
          remark_mode: "",
          remark_is_use: true,
          iu_profile_path: remark.iu_profile_path,
          remark_create_date: remark.remark_create_date,
          ps_name: remark.ps_name,
        }));
        // console.log("formattedRemarks", formattedRemarks[0].remarkIMG);
        formattedRemarks.forEach((remark) => {
          // กรอง tempFile เพื่อให้ได้เฉพาะไฟล์ที่มี img_remark_id ตรงกับ remark_id
          const relevantFiles = tempFile.filter(
            (file) => file.img_remark_id === remark.remark_id
          );
          // เพิ่มไฟล์ที่เป็นไปได้ลงใน remarkIMG
          remark.remarkIMG.push(...relevantFiles);
        });
        // กำหนดค่า remarkList ให้เป็นข้อมูล remark ที่เรียงลำดับแล้วและถูกจัดรูปแบบ
        setRemarkList(formattedRemarks);
        console.log("remarkList", remarkList);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  const handleSaveClick = (remarkId) => {
    const updatedRemarkList = remarkList.map((item) => {
      if (item.remark_id !== remarkId) {
        return {
          ...item,
          remark_mode: "",
        };
      }
      item.remark_mode = "Edit";
      return item;
    });

    setRemarkList(updatedRemarkList);
    // console.log("updatedRemarkList", updatedRemarkList);
  };
  const addRemarkClick = (remarkId) => {
    const updatedRemarkList = remarkList.map((item) => {
      if (item.remark_id !== remarkId) {
        return {
          ...item,
          remark_mode: "",
        };
      }
      item.remark_mode = "Add";
      return item;
    });

    setRemarkList(updatedRemarkList);
    // console.log("updatedRemarkList", updatedRemarkList);
  };

  const handleRemoveClick = (remarkId) => {
    // console.log('remarkId', remarkId)
    const updatedRemarkList = remarkList.map((item) => {
      // console.log('item.remark_id---', item.remark_id)
      if (item.remark_id !== remarkId) {
        // console.log('in')
        return {
          ...item,
          remark_is_use: true,
        };
        // item.remark_is_use = true
      }
      item.remark_is_use = false;
      return item;
    });

    setRemarkList(updatedRemarkList);
    remarkList = updatedRemarkList;
    saveRemark("delelte");
    // console.log("updatedRemarkList", editedRemarks);
  };

  async function saveRemark(mode, remark_prop, remark_index_prop) {
    const editedRemarks = remarkList.filter(
      (item) => item.remark_mode === "Edit"
    );
    const oem_id = getOem(), com_id = getUser().com;
    const removeRemarks = remarkList.filter(
      (item) => {
        if (item.remark_is_use === false) {
          item.remark_oem_id = oem_id;
          item.remark_company_id = com_id;
          return item;
        }
      }
    );
    // console.log("remarkListขขข", remarkList.remark_detail);

    const error_list = [];
    /* if (remarkList.remark_detail === undefined || remarkList.remark_detail === "") {
      let temp_err = {
        message: "ไม่มีข้อมูล Remark",
      };
      error_list.push(temp_err);
    } */
    if (mode === "Add" || mode === "Edit") {
      for (let item of remarkList) {
        if (!item.remark_detail?.trim()) {
          let temp_err = {
            message: "ไม่มีข้อมูล Remark",
          };
          error_list.push(temp_err);
        }
      }
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode === "Edit") {
        Swal.fire({
          title: "ระบบ",
          text: `ระบบกำลังอัพเดตข้อมูล`,
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        const oem_id = getOem(), com_id = getUser().com;
        axios({
          method: "post",
          url: `${Configs.API_Ticket}/api/ticket/editRemarkTicket`,
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
          data: [remark_prop].map(el => {
            el.remark_oem_id = oem_id;
            el.remark_company_id = com_id;
            return el;
          }),
        })
          .then(async function (response) {
            if (response.data && remark_prop.remarkIMG) {
              if (remark_prop.remarkIMG.length > 0) {
                await handleChangeRemark(
                  { fileList: remark_prop.remarkIMG },
                  response.data,
                  remark_prop
                );
              }
              const _remarkList = [...remarkList];
              _remarkList[remark_index_prop].remarkIMG = [];
              setRemarkList(_remarkList);
            }
            Swal.close();
            Swal.fire({
              title: "บันทึก Remark สำเร็จ",
              showConfirmButton: false,
              timer: 1500,
              icon: "success",
            }).then(async function (response) {
              getByIdRemark();
              getByIdTicket(idRef.current);
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
          });
      } else if (removeRemarks[0]?.remark_is_use === false) {
        Swal.fire({
          title: "คุณต้องการลบ Remark นี้ใช่หรือไม่",
          text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "ใช่",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "ไม่",
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "swal2-confirm-button",
            cancelButton: "swal2-cancel-button",
          },
          didOpen: () => {
            const confirmButton = document.querySelector(
              ".swal2-confirm-button"
            );
            const cancelButton = document.querySelector(".swal2-cancel-button");
            confirmButton.style.width = "150px";
            cancelButton.style.width = "150px";
          },
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: "post",
              url: `${Configs.API_Ticket}/api/ticket/addRemarkTicket`,
              headers: {
                Authorization: getToken(),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
              },
              data: removeRemarks,
            });
            Swal.fire({
              title: "บันทึก Remark สำเร็จ",
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false,
              timer: 1500,
              icon: "success",
            })
              .then(async function (response) {
                getByIdRemark();
                getByIdTicket(idRef.current);
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire(
                  "แจ้งเตือน",
                  error.response.data.errorMessage,
                  "error"
                );
              });
          } else if (result.isDismissed) {
            console.log("Cancelled");
          }
        });
      } else {
        const oem_id = getOem(), com_id = getUser().com;
        await axios({
          method: "post",
          url: `${Configs.API_Ticket}/api/ticket/addRemarkTicket`,
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
          data: [remark_prop].map(el => {
            el.remark_oem_id = oem_id;
            el.remark_company_id = com_id;
            return el;
          }),
        })
          .then(async function (response) {
            console.log("response", response);
            if (response?.data && remark_prop.remarkIMG) {
              console.log("first");
              if (remark_prop.remarkIMG.length > 0) {
                await handleChangeRemark(
                  { fileList: remark_prop.remarkIMG },
                  response.data,
                  remark_prop
                );
              }
              const _remarkList = [...remarkList];
              _remarkList[remark_index_prop].remarkIMG = [];
              setRemarkList(_remarkList);
              Swal.fire({
                title: "บันทึก Remark สำเร็จ",
                showConfirmButton: false,
                timer: 1500,
                icon: "success",
              }).then(async function () {
                getByIdRemark();
                getByIdTicket(idRef.current);
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
          });
      }
    }
  }

  // savePicture(id);
  function savePicture() {
    console.log("idRef", idRef.current);
    const error_list = [];
    if (fileList.length === 0) {
      let temp_err = {
        message: "ไม่มีข้อมูล Picture",
      };
      error_list.push(temp_err);
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      // console.log("savePicture");

      const formData = new FormData();
      fileList.forEach((file) => {
        // console.log("file",file);
        formData.append(`files`, file.originFileObj);
      });
      formData.append(`delImg`, delIMG);
      formData.append(`img_ticket_id`, idRef.current);
      formData.append(`img_type`, "img_ticket");
      try {
        const response = axios.post(
          `${Configs.API_Ticket}/api/image/upload/${Tickect_List.ticket_no}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: getToken(),
              "X-TTT": `${Configs.API_TTT}`,
            },
          }
        );

        // getByIdTicket(id);

        // console.log(response.data);
      } catch (error) {
        console.error("เกิดข้อผิดพลาดในการอัปโหลดไฟล์:", error);
      }
      // setFileList([]);
      getByIdTicket(id);
    }
  }
  // console.log("remarkList: ",remarkList);
  // console.log('RemarkList',remarkList )
  const cardRemark = remarkList?.map((item, index) => (
    <div
      key={item.id + index}
      style={{
        width: "96%",
        background: "none",
        border: "none",
        marginLeft: "10px",
      }}
    >
      <div className="row mt-1">
        <div className="col-12 col-sm-9 col-md-9 col-lg-9 col-xl-9">
          <div className="row" style={{}}>
            <Card style={{ width: "100%", height: "50px" }}>
              <div className="row flex justify-between ">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-14px",
                    // paddingTop:"20px",
                    // marginTop: "-9px",
                  }}
                >
                  <span
                    style={{
                      color: "#007BFF",
                      fontWeight: 500,
                      alignItems: "center",
                      fontSize: "16px",
                      // padding:"0px"
                    }}
                  >
                    REMARK {item?.remark_no}
                  </span>
                </div>
                {item.remark_by === UserPermi.iu_id &&
                Tickect_List.ticket_tsb_id !==
                  "2027332c-b338-470b-9ab1-4c7b99775288" ? (
                  <div style={{ marginLeft: "auto", marginTop: "-12px" }}>
                    {
                      /* item.remark_id &&  */ item.remark_mode === "Edit" ||
                      item.remark_mode === "Add" ? (
                        <i
                          className="far fa-save"
                          style={{
                            color: "#007BFF",
                            fontSize: "15px",
                            marginLeft: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            Swal.fire({
                              title: "คุณต้องการบันทึก Remark นี้ใช่หรือไม่",
                              text: "",
                              icon: "warning",
                              showConfirmButton: true,
                              showCancelButton: true,
                              confirmButtonText: "ใช่",
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              cancelButtonText: "ไม่",
                              allowEscapeKey: false,
                              allowOutsideClick: false,
                              customClass: {
                                confirmButton: "swal2-confirm-button",
                                cancelButton: "swal2-cancel-button",
                              },
                              didOpen: () => {
                                const confirmButton = document.querySelector(
                                  ".swal2-confirm-button"
                                );
                                const cancelButton = document.querySelector(
                                  ".swal2-cancel-button"
                                );
                                confirmButton.style.width = "150px";
                                cancelButton.style.width = "150px";
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                saveRemark(
                                  item.remark_mode,
                                  item,
                                  index
                                ); /* save btn */
                              }
                            });
                          }}
                        ></i>
                      ) : (
                        <></>
                      )
                    }

                    {item.remark_id ? (
                      <i
                        className="far fa-edit"
                        style={{
                          color: "#007BFF",
                          fontSize: "14px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const _remarkList = [...remarkList];
                          const nullRemarkIds = _remarkList.filter(
                            (remark) => remark.remark_id == null
                          ).length;
                          const nullRemarkDetail = _remarkList.filter(
                            (remark) => remark.remark_detail == null
                          ).length;
                          if (nullRemarkIds > 0 || nullRemarkDetail > 0) {
                            Swal.fire({
                              title: "เพิ่มข้อมูลไม่ได้",
                              text: "กรุณาบันทึกข้อมูล Remark ก่อนทำรายการถัดไป",
                              icon: "error",
                              confirmButtonText: "ตกลง",
                            });
                            return;
                          } else {
                            handleSaveClick(item.remark_id);
                          }
                        }}
                      ></i>
                    ) : (
                      <></>
                    )}
                    <i
                      className="fas fa-trash"
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (item.remark_id) {
                          handleRemoveClick(item.remark_id);
                        } else {
                          const checkRemarkId = remarkList.filter(
                            (remark, indexRemark) => indexRemark !== index
                          );
                          setRemarkList(checkRemarkId);
                          remarkList = checkRemarkId;
                        }
                      }}
                    ></i>
                  </div>
                ) : null}
              </div>
            </Card>
          </div>
          <div className="row mt-2">
            <Card style={{ width: "100%" }}>
              <TextArea
                style={{ border: "none" }}
                value={item.remark_detail}
                disabled={
                  //Tester
                  (UserPermi.iu_ps_id ===
                    "d6655dd6-0302-4ebe-bc8c-de61a0339777" &&
                    item.remark_by !== UserPermi.iu_id) ||
                  (item.remark_mode !== "Edit" && item.remark_mode !== "Add") ||
                  //Developer
                  (UserPermi.iu_ps_id ===
                    "d9300f9b-e533-47a2-b40c-4039e2e50ea3" &&
                    item.remark_by !== UserPermi.iu_id) ||
                  (item.remark_mode !== "Edit" && item.remark_mode !== "Add")
                    ? true
                    : false
                }
                onChange={(e) => {
                  setRemarkList((prevList) => {
                    return prevList.map((remark) => {
                      if (remark.remark_no === item.remark_no) {
                        return { ...remark, remark_detail: e.target.value };
                      }
                      return remark;
                    });
                  });
                }}
                placeholder="Remark Detail"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span
                  style={{ fontWeight: 500, fontSize: "11px", color: "gray" }}
                >{`Create Date : ${moment(item.remark_create_date).format(
                  "YYYY-MM-DD HH.mm"
                )}`}</span>
              </div>
            </Card>
          </div>
        </div>
        <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <Card style={{ height: "100%", width: "125%" }}>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    whiteSpace: "nowrap",
                  }}
                >
                  Remark by
                </span>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                {/* <Button 
                  style={{ marginTop:"-10px", color: "#007BFF", background:"none" , borderColor:"#007BFF", height:"25px", fontSize:"9px" }}
                  onClick={()=>{
                    saveRemark(item.remark_id);

                  }}
                  >
                      <div style={{}}>Save Remark</div>
                  </Button> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-3">
                {/* <Tooltip title={`${item.remark_create_by}`} placement="top">  */}
                <Avatar
                  src={
                    item.remark_mode === "Add"
                      ? Configs.API_URL_IMG + UserPermi?.iu_profile_path
                      : Configs.API_URL_IMG + item?.iu_profile_path
                  }
                  // src={`${Configs.API_URL}/static/`+ `${item.iu_profile_path}`}

                  onError={(e) => {
                    e.target.src = "";
                  }}
                  key={item.iu_profile_path}
                  style={{
                    backgroundColor: "#f56a00",
                    fontSize: "30px",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  {String(item.remark_create_by).substring(0, 1).toUpperCase()}
                </Avatar>
                {/* </Tooltip>  */}
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-3">
                <span style={{ fontSize: "16px" }}>
                  {item.remark_create_by}
                </span>
                <p style={{ fontSize: "14px", color: "#A1A1A1" }}>
                  {item.ps_name}
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="row mt-2" style={{ marginLeft: "10px" }}>
        {(item.remark_id === undefined || item.remarkIMG.length === 0) &&
        item.remark_mode !== "Edit" &&
        item.remark_mode !== "Add" ? (
          <></>
        ) : (
          <>
            {" "}
            {/* upload file */}
            <Upload
              beforeUpload={() => false}
              key={item.remark_id + index + "upload"}
              listType="picture-card"
              fileList={item.remarkIMG}
              onPreview={handlePreviewRemark}
              onChange={(fileList) => {
                if (item.remark_mode !== "Add" && item.remark_id && false) {
                  // handleChangeRemark(fileList, item.remark_id)
                } else if (
                  item.remark_mode == "Add" ||
                  item.remark_mode == "Edit"
                ) {
                  const _remarkList = [...remarkList];
                  // console.log('fileList.fileList onChange :>> ', fileList.fileList);
                  _remarkList[index].remarkIMG = fileList.fileList;
                  setRemarkList(_remarkList);
                }
              }}
              onRemove={(fileList) => {
                // if (item.remark_mode !== "Add" && item.remark_mode !== "Edit" && item.remark_id) {
                // handleRemoveRemarkFile(fileList, item.remark_id)
                // }
                /*  else if (item.remark_mode == "Add") {
                  } */
                // console.log('fileList :>> ', fileList);
                // console.log('_remarkList[index].remarkIMG :>> ', remarkList[index].remarkIMG);
                // console.log('fileList.fileList onChange :>> ', fileList.fileList);
                // console.log('_remarkList[index] :>> ', _remarkList[index]);
                const _remarkList = [...remarkList];
                if (!_remarkList[index].remarkIMGDel)
                  _remarkList[index].remarkIMGDel = [];
                if (fileList.is_old && item.remark_mode === "Edit")
                  _remarkList[index].remarkIMGDel.push(fileList.uid);

                setRemarkList(_remarkList);
              }}
              accept=".jpg,.png"
              // multiple
              disabled={
                //Tester
                (UserPermi.iu_ps_id ===
                  "d6655dd6-0302-4ebe-bc8c-de61a0339777" &&
                  item.remark_by !== UserPermi.iu_id) ||
                (item.remark_mode !== "Edit" && item.remark_mode !== "Add") ||
                //Developer
                (UserPermi.iu_ps_id ===
                  "d9300f9b-e533-47a2-b40c-4039e2e50ea3" &&
                  item.remark_by !== UserPermi.iu_id) ||
                (item.remark_mode !== "Edit" && item.remark_mode !== "Add")
                  ? true
                  : false
              }
            >
              {item.remarkIMG /* && item.remarkIMG.length >= 8 */ &&
              item.remark_by !== UserPermi.iu_id
                ? null
                : uploadButtonRemark(
                    //Tester
                    (UserPermi.iu_ps_id ===
                      "d6655dd6-0302-4ebe-bc8c-de61a0339777" &&
                      item.remark_by !== UserPermi.iu_id) ||
                      (item.remark_mode !== "Edit" &&
                        item.remark_mode !== "Add") ||
                      //Developer
                      (UserPermi.iu_ps_id ===
                        "d9300f9b-e533-47a2-b40c-4039e2e50ea3" &&
                        item.remark_by !== UserPermi.iu_id) ||
                      (item.remark_mode !== "Edit" &&
                        item.remark_mode !== "Add")
                      ? true
                      : false
                  )}
            </Upload>
            <Modal
              open={previewOpenRemark}
              title={previewTitleRemark}
              footer={null}
              onCancel={handleCancelRemark}
              disabled={
                //Tester
                (UserPermi.iu_ps_id ===
                  "d6655dd6-0302-4ebe-bc8c-de61a0339777" &&
                  item.remark_by !== UserPermi.iu_id) ||
                (item.remark_mode !== "Edit" && item.remark_mode !== "Add") ||
                //Developer
                (UserPermi.iu_ps_id ===
                  "d9300f9b-e533-47a2-b40c-4039e2e50ea3" &&
                  item.remark_by !== UserPermi.iu_id) ||
                (item.remark_mode !== "Edit" && item.remark_mode !== "Add")
                  ? true
                  : false
              }
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={previewImageRemark}
                disabled={
                  //Tester
                  (UserPermi.iu_ps_id ===
                    "d6655dd6-0302-4ebe-bc8c-de61a0339777" &&
                    item.remark_by !== UserPermi.iu_id) ||
                  (item.remark_mode !== "Edit" && item.remark_mode !== "Add") ||
                  //Developer
                  (UserPermi.iu_ps_id ===
                    "d9300f9b-e533-47a2-b40c-4039e2e50ea3" &&
                    item.remark_by !== UserPermi.iu_id) ||
                  (item.remark_mode !== "Edit" && item.remark_mode !== "Add")
                    ? true
                    : false
                }
              />
            </Modal>
          </>
        )}
      </div>
    </div>
  ));

  const cardLog = [];
  LogList.forEach((item) => {
    // console.log('item.log_action', item.log_action)
    cardLog.push({
      id: item.id,
      content: (
        <>
          {/* <div className="row mt-2"> */}
          {/* <Card key={20} style={{ width: "99%", height:"120px", marginLeft:"5px" }}> */}
          <div className="row" style={{ marginTop: "-20px" }}>
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <div style={{ marginTop: "18px" }}>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    fontSize: "25px",
                  }}
                >
                  {/* {item.log_action === "Fixed" ? "Close" : item.log_action} */}
                  {item.log_action}
                </span>
              </div>
            </div>
            <div className="col-12 col-sm- col-md-6 col-lg-6 col-xl-6 mt-3">
              <div className="row">
                <div style={{ marginLeft: "130px" }}>
                  <span style={{ fontSize: "10px", color: "#A1A1A1" }}>
                    Action Date
                  </span>
                </div>
              </div>
              <div className="row mt-1">
                <div style={{ marginLeft: "65px" }}>
                  <i
                    class="far fa-calendar"
                    style={{
                      marginTop: "10px",
                      color: "#007BFF",
                      fontSize: "11px",
                    }}
                  ></i>{" "}
                  &nbsp;
                  <span
                    style={{ fontWeight: 500, fontSize: "13px" }}
                  >{`${moment(item.log_create_date).format(
                    "YYYY-MM-DD HH.mm"
                  )}`}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-3">
              <div className="row">
                <div style={{ marginLeft: "40px" }}>
                  <span style={{ fontSize: "10px", color: "#A1A1A1" }}>
                    Action by
                  </span>
                </div>
              </div>
              <div className="row">
                <div style={{ marginLeft: "50px" }}>
                  <Tooltip title={`${item.log_action_by}`} placement="top">
                    <Avatar
                      src={
                        item.iu_id === Tickect_List.ticket_edit_by
                          ? Configs.API_URL_IMG +
                            Tickect_List?.profile_path_edit_by
                          : item.iu_id === Tickect_List.ticket_create_by
                          ? Configs.API_URL_IMG +
                            Tickect_List?.profile_path_create_by
                          : item.iu_id === Tickect_List.ticket_administrator
                          ? Configs.API_URL_IMG +
                            Tickect_List?.profile_path_admin
                          : undefined
                      }
                      onError={(e) => {
                        e.target.onerror = null; // Prevents an infinite loop if the fallback image also fails
                        e.target.src = ""; // Forces fallback to show the initial letter
                      }}
                      key={item.iu_id}
                      style={{
                        backgroundColor: "#f56a00",
                      }}
                    >
                      {String(Tickect_List.create_by)
                        .substring(0, 1)
                        .toUpperCase()}
                    </Avatar>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          {/* </Card> */}
          {/* </div> */}
        </>
      ),
    });
  });

  const formatDuration = (minutes, showAll) => {
    // console.log("minutes", minutes);
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor((minutes % (60 * 24)) / 60);
    const mins = minutes % 60;
    return !showAll
      ? `${hours} hr ${mins} min`
      : `${days} day ${hours} hr ${mins} min`;
  };

  const formatDurationHrMin = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    return `${hours} : ${mins}`;
  };

  function SwitchButton(ticket_tsb_id, id_ticket, ticket) {
    // console.log("tsb_id", ticket_tsb_id);
    // console.log("id_ticket: ",id_ticket);
    let button;
    if (UserPermi.iu_ps_id === "d9300f9b-e533-47a2-b40c-4039e2e50ea3") {
      //Dev

      if (ticket_tsb_id === "a4b304ec-0af4-49ef-91e6-f9d04a1effc6") {
        //Approve
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#007BFF",
              border: "none",
              width: "100px",
              minWidth: "100px",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
            }}
            onClick={() => {
              ClickApprove(id_ticket);
            }}
          >
            Approve
          </Button>
        );
      }

      if (ticket_tsb_id === "41950bd6-ca94-4cdd-991e-44effbbe4870") {
        //Reopen
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#007BFF",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
              width: "100px",
              minWidth: "100px",
            }}
            onClick={() => {
              // ClickApprove(id_ticket);
              ClickReopen(id_ticket, true);
            }}
          >
            Reopen
          </Button>
        );
      }

      if (ticket_tsb_id === "5fc5716d-91a5-4a76-b3fc-1e7b073cf9d7") {
        //Padding
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#007BFF",
              border: "none",
              fontSize: "13px",
              padding: "5px 10px",
              width: "100px",
              minWidth: "100px",
            }}
            onClick={() => {
              ClickPadding(id_ticket);
            }}
          >
            Pending
          </Button>
        );
      }

      if (
        ticket_tsb_id === "4d2ad7d2-316a-4431-a185-38ecdac80069" ||
        ticket_tsb_id === "2027332c-b338-470b-9ab1-4c7b99775288"
      ) {
        //Fixed
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#47BA6F",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
              width: "100px",
              minWidth: "100px",
            }}
          >
            Fixed
          </Button>
        );
      }
    }

    if (
      UserPermi.iu_ps_id === "d6655dd6-0302-4ebe-bc8c-de61a0339777" ||
      UserPermi.iu_ps_id !== "d9300f9b-e533-47a2-b40c-4039e2e50ea3"
    ) {
      //Software Tester

      if (ticket_tsb_id === "4d2ad7d2-316a-4431-a185-38ecdac80069") {
        //Close & Reopen
        button = (
          <>
            <Button
              as="input"
              type="primary"
              value="Input"
              danger
              style={{
                backgroundColor: "#007BFF",
                border: "none",
                fontSize: "13px",
                // marginTop: "21px",
                padding: "5px 10px",
                width: "100px",
                minWidth: "100px",
              }}
              onClick={() => {
                ClickClose(id_ticket);
              }}
            >
              Close
            </Button>
            &nbsp;
            <Button
              as="input"
              type="primary"
              value="Input"
              style={{
                backgroundColor: "#007BFF",
                border: "none",
                fontSize: "13px",
                // marginTop: "21px",
                // marginLeft: "25px",
                padding: "5px 10px",
                width: "100px",
                minWidth: "100px",
                marginLeft: "12px",
              }}
              onClick={() => {
                ClickReopen(id_ticket);
              }}
            >
              Reopen
            </Button>
          </>
        );
      }

      if (
        ticket_tsb_id === "5fc5716d-91a5-4a76-b3fc-1e7b073cf9d7" ||
        ticket_tsb_id === "a4b304ec-0af4-49ef-91e6-f9d04a1effc6"
      ) {
        //Padding
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#A1A1A1",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              // marginRight: "-20px",
              padding: "5px 10px",
              width: "100px",
              minWidth: "100px",
            }}
          >
            Pending
          </Button>
        );
      }
      if (ticket_tsb_id === "41950bd6-ca94-4cdd-991e-44effbbe4870") {
        //Reopen

        button = (
          <>
            <Button
              as="input"
              type="primary"
              value="Input"
              danger
              style={{
                backgroundColor: "#A1A1A1",
                border: "none",
                fontSize: "13px",
                padding: "5px 10px",
                width: "100px",
                minWidth: "100px",
                // marginTop: "21px",
              }}
            >
              Close
            </Button>
            &nbsp;
            <Button
              as="input"
              type="primary"
              value="Input"
              style={{
                backgroundColor: "#A1A1A1",
                border: "none",
                padding: "5px 10px",
                fontSize: "13px",
                width: "100px",
                minWidth: "100px",
                marginLeft: "12px",
                // marginTop: "21px",
                // marginLeft: "25px",
              }}
            >
              Reopen
            </Button>
          </>
        );
      }

      if (ticket_tsb_id === "2027332c-b338-470b-9ab1-4c7b99775288") {
        //Fixed
        button = (
          <Button
            as="input"
            type="primary"
            value="Input"
            style={{
              backgroundColor: "#47BA6F",
              border: "none",
              fontSize: "13px",
              // marginTop: "21px",
              padding: "5px 10px",
              // marginLeft: "12px",
              width: "100px",
              minWidth: "100px",
            }}
          >
            Close
          </Button>
        );
      }
    }

    return button;
  }

  return (
    <div className="content-wrapper" style={{ overflowY: "auto" }}>
      <section className="content-header" style={{ marginRight: "12px" }}>
        <div
          className="container-fluid"
          style={{ marginTop: "10px", marginLeft: "12px" }}
        >
          <div className="row">
            <div
              className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
              style={{ display: "flex" }}
            >
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={30}
              />
              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginTop: "-16px",
                  marginLeft: "18px",
                }}
              >
                {Tickect_List.ticket_no}
              </h1>
            </div>

            <div className="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-7 col-xl-6 ">
              <ol className="breadcrumb float-sm-right ">
                <li className="breadcrumb-item active">
                  Home
                  {/* <a href="/Welcome">Home</a> */}
                </li>
                <li className="breadcrumb-item active">TTT Project</li>
                <li className="breadcrumb-item">All Project</li>
                <li className="breadcrumb-item">{Tickect_List.ticket_code}</li>
                {/* <li className="breadcrumb-item active">{mode}</li> */}
              </ol>
            </div>
          </div>

          <div
            className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {UserPermi.iu_id !== Tickect_List.ticket_create_by &&
            UserPermi.iu_id !== Tickect_List.ticket_edit_by ? (
              <></>
            ) : (
              <div
                className=""
                style={{ marginBottom: "15px", marginTop: "15px" }}
              >
                {SwitchButton(
                  Tickect_List.ticket_tsb_id,
                  Tickect_List.ticket_id
                )}
              </div>
            )}
          </div>
          <div className="row" style={{ marginLeft: "-18px" }}>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
              <Card
                key={1}
                style={{
                  width: "100%",
                  height: "50px",
                  boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                }}
              >
                <div
                  style={{
                    marginTop: "-13px",
                    color: "#007BFF",
                    fontWeight: 700,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "-5px" }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Ticket Detail
                  </span>
                </div>
              </Card>
              <div className="row ">
                <div
                  className="col-12 col-xs-12 col-sm-12  col-md-6 col-lg-4 col-xl-4"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                >
                  <Card key={3} style={{ width: "100%", height: "124px" }}>
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "11px", fontWeight: 700 }}>
                        {" "}
                        Create by
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">
                        <Avatar
                          size={30}
                          src={
                            Configs.API_URL_IMG +
                            Tickect_List.profile_path_create_by
                          }
                          onError={(e) => {
                            e = Tickect_List.ticket_create_by
                              .substring(0, 1)
                              .toUpperCase();
                          }}
                          style={{
                            backgroundColor: "#f56a00",
                          }}
                        >
                          {String(Tickect_List.create_by)
                            .substring(0, 1)
                            .toUpperCase()}
                        </Avatar>
                      </div>

                      <div className="col-6 col-sm-6 col-md-6 ">
                        <div className="row" style={{}}>
                          <span style={{ fontSize: "10px", fontWeight: 700 }}>
                            {Tickect_List.create_by}
                          </span>
                        </div>
                        <div className="row">
                          <span style={{ fontSize: "10px", color: "#A1A1A1" }}>
                            Software Tester
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div
                  className={`col-12 col-xs-6 col-sm-6 col-md-3 col-lg-${
                    isResponse.lg ? "auto" : 3
                  }`}
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                  // style={{ marginLeft: "56px" }}
                >
                  <Card
                    key={4}
                    style={{
                      width: "100%",
                      height: "124px",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "14px", fontWeight: 700 }}>
                        Start Date
                      </span>
                    </div>
                    <div className="row">
                      <i
                        class="far fa-calendar"
                        style={{
                          marginTop: "10px",
                          color: "#007BFF",
                          fontSize: "11px",
                        }}
                      ></i>{" "}
                      &nbsp;
                      <span
                        style={{
                          fontSize: "14px",
                          marginTop: "4px",
                          fontWeight: 500,
                        }}
                      >
                        {`${moment(Tickect_List.ticket_start_date).format(
                          "YYYY-MM-DD : HH.mm"
                        )}`}
                      </span>
                    </div>
                  </Card>
                </div>
                <div
                  className="col-12 col-xs-6 col-sm-6 col-md-3 col-lg-auto col-xl-custom-3-5"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                  // style={{ marginLeft: "50px" }}
                >
                  <Card
                    key={5}
                    style={{
                      width: "100%",
                      height: "124px",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                      alignItems: "center",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "14px", fontWeight: 700 }}>
                        End Date
                      </span>
                    </div>
                    <div className="row">
                      <i
                        class="far fa-calendar"
                        style={{
                          marginTop: "10px",
                          color: "#007BFF",
                          fontSize: "11px",
                        }}
                      ></i>{" "}
                      &nbsp;
                      <span
                        style={{
                          fontSize: "14px",
                          marginTop: "4px",
                          fontWeight: 500,
                        }}
                      >
                        {`${moment(Tickect_List.end_date).format(
                          "YYYY-MM-DD : HH.mm"
                        )}`}
                      </span>
                    </div>
                  </Card>
                </div>
                <div
                  className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg col-xl-custom-3-5"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                >
                  <Card
                    key={6}
                    style={{
                      width: "100%",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "14px", fontWeight: 700 }}>
                        Complete in
                      </span>
                    </div>
                    <div className="row">
                      <i
                        class="far fa-clock"
                        style={{
                          marginTop: "8px",
                          color: "#007BFF",
                          fontSize: "14px",
                        }}
                      ></i>{" "}
                      &nbsp;
                      <span
                        style={{
                          fontSize: "14px",
                          marginTop: "4px",
                          fontWeight: 500,
                        }}
                      >
                        {`${formatDuration(
                          Math.max(
                            moment(Tickect_List.ticket_start_date)
                              .add(
                                parseInt(Tickect_List.severity_sla_time),
                                "minutes"
                              )
                              .diff(moment(), "minutes"),
                            0
                          ),
                          true
                        )}`}
                      </span>
                    </div>
                    <div className="row">
                      <i
                        class="fas fa-hourglass-half"
                        style={{
                          marginTop: "8px",
                          color: "#007BFF",
                          fontSize: "14px",
                        }}
                      ></i>{" "}
                      &nbsp;
                      <span
                        style={{
                          fontSize: "14px",
                          marginTop: "4px",
                          fontWeight: 500,
                        }}
                      >
                        {`${Math.floor(
                          Tickect_List.severity_sla_time / 60
                        )} hr ${Math.floor(
                          Tickect_List.severity_sla_time % 60
                        )} min`}
                      </span>
                    </div>
                  </Card>
                </div>
              </div>

              <div className="row ">
                <div
                  className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                >
                  <Card
                    key={7}
                    style={{
                      width: "100%",
                      height: "100%",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "11px", fontWeight: 700 }}>
                        {" "}
                        Manage by
                      </span>
                    </div>
                    <div className="row">
                      {Tickect_List.ticket_edit_by ? (
                        <>
                          <div className="col-6 col-sm-3 col-md-3 col-lg-3 col-xl-3 mt-1">
                            <Avatar
                              src={
                                Configs.API_URL_IMG +
                                Tickect_List.profile_path_edit_by
                              }
                              onError={(e) => {
                                e = Tickect_List.ticket_create_by
                                  .substring(0, 1)
                                  .toUpperCase();
                              }}
                              style={{
                                backgroundColor: "#f56a00",
                              }}
                            >
                              {String(Tickect_List.manage_by)
                                .substring(0, 1)
                                .toUpperCase()}
                            </Avatar>
                          </div>

                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <span
                                style={{ fontSize: "10px", fontWeight: 700 }}
                              >
                                {Tickect_List.manage_by}
                              </span>
                            </div>
                            <div className="row">
                              <span
                                style={{ fontSize: "10px", color: "#A1A1A1" }}
                              >
                                Developer
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>-</div>
                      )}
                    </div>
                  </Card>
                </div>
                <div
                  className="col-12 col-xs-6 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                  // style={{ marginLeft: "125px" }}
                >
                  <Card
                    key={8}
                    style={{
                      width: "100%",
                      height: "100%",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "14px", fontWeight: 700 }}>
                        Status Tickect
                      </span>
                    </div>
                    <div className="row">
                      &nbsp;
                      {Tickect_List.st_name === "Success" ? (
                        <>
                          <span
                            style={{
                              color: "#47BA6F",
                              fontSize: "14px",
                              marginTop: "4px",
                              fontWeight: 500,
                            }}
                          >
                            {`${Tickect_List.st_name || ""}`}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            style={{
                              color: "#007BFF",
                              fontSize: "14px",
                              marginTop: "4px",
                              fontWeight: 500,
                            }}
                          >
                            {`${Tickect_List.st_name || ""}`}
                          </span>
                        </>
                      )}
                    </div>
                  </Card>
                </div>
                <div
                  className="col-12 col-xs-6 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                  // style={{ marginLeft: "3px" }}
                >
                  <Card
                    key={9}
                    style={{
                      width: "100%",
                      height: "100%",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "14px", fontWeight: 700 }}>
                        Phase
                      </span>
                    </div>
                    <div className="row">
                      &nbsp;
                      {Tickect_List.phase_name === "DONE" ? (
                        <>
                          <span
                            style={{
                              color: "#47BA6F",
                              fontSize: "14px",
                              marginTop: "4px",
                              fontWeight: 500,
                              textTransform: "capitalize",
                            }}
                          >
                            {`${Tickect_List.phase_name || ""}`}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            style={{
                              color: "#007BFF",
                              fontSize: "14px",
                              marginTop: "4px",
                              fontWeight: 500,
                              textTransform: "capitalize",
                            }}
                          >
                            {`${
                              Tickect_List.phase_name
                                ? Tickect_List.phase_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  Tickect_List.phase_name.slice(1).toLowerCase()
                                : "-"
                            }`}
                          </span>
                        </>
                      )}
                    </div>
                  </Card>
                </div>
                <div
                  className="col-12 col-xs-6 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                  // style={{ marginLeft: "3px" }}
                >
                  <Card
                    key={10}
                    style={{
                      width: "100%",
                      height: "100%",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "14px", fontWeight: 700 }}>
                        Severity
                      </span>
                    </div>
                    <div className="row">
                      &nbsp;
                      <span
                        style={{
                          color: "#DD2025",
                          fontSize: "15px",
                          marginTop: "4px",
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                      >
                        {`${Tickect_List.severity_name}`}
                      </span>
                    </div>
                  </Card>
                </div>
                <div
                  className="col-12 col-xs-6 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                  style={{ marginTop: "7px", paddingRight: "2px" }}
                  // style={{ marginLeft: "3px" }}
                >
                  <Card
                    key={10}
                    style={{
                      width: "100%",
                      height: "100%",
                      boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <div className="row" style={{ alignItems: "center" }}>
                      <span style={{ fontSize: "14px", fontWeight: 700 }}>
                        SLA Status
                      </span>
                    </div>
                    <div className="row">
                      &nbsp;
                      {Tickect_List.sla_status_name === "In Process" ? (
                        <>
                          <span
                            style={{
                              color: "#007BFF",
                              fontSize: "15px",
                              marginTop: "4px",
                              fontWeight: 500,
                            }}
                          >
                            {`${Tickect_List.sla_status_name || ""}`}
                          </span>
                        </>
                      ) : (
                        <>
                          {Tickect_List.sla_status_name === "Fail" ? (
                            <>
                              <span
                                style={{
                                  color: "#DD2025",
                                  fontSize: "15px",
                                  marginTop: "4px",
                                  fontWeight: 500,
                                }}
                              >
                                {`${Tickect_List.sla_status_name || ""}`}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                style={{
                                  color: "#47BA6F",
                                  fontSize: "15px",
                                  marginTop: "4px",
                                  fontWeight: 500,
                                }}
                              >
                                {`${Tickect_List.sla_status_name || ""}`}
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Card>
                </div>
              </div>

              <div className="row mt-3">
                <Card
                  key={11}
                  style={{
                    width: "99%",
                    height: "100%",
                    marginLeft: "5px",
                    boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <div className="row" style={{ marginTop: "-16px" }}>
                    <span style={{ fontWeight: 700, fontSize: "30px" }}>
                      Description
                    </span>
                  </div>
                  <div
                    className="row"
                    // style={{ marginTop: "-16px" }}
                  >
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: "20px",
                        color: "#007BFF",
                      }}
                    >
                      {`${Tickect_List.ticket_name || ""}`}
                    </span>
                  </div>
                  <div className="row mt-1">
                    <span style={{ fontSize: "14px", fontWeight: 500 }}>
                      {`${Tickect_List.ticket_description || ""}`}
                    </span>
                  </div>
                </Card>
              </div>

              <div className="row mt-3">
                <Card
                  key={12}
                  style={{
                    width: "100%",
                    marginLeft: "5px",
                    boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                    padding: "0px",
                    height: "50px",
                  }}
                >
                  <div
                    className="row"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0px",
                    }}
                  >
                    <div className="col-10">
                      <div
                        style={{
                          color: "#007BFF",
                          // fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                          marginTop: "-14px",
                        }}
                      >
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                          // style={{ marginTop: "-5px" }}
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                        </svg> */}
                        <span
                          style={{
                            marginLeft: "10px",
                            fontSize: "16px",
                            alignItems: "center",
                            fontSize: "16px",
                            fontWeight: 500,
                            fontWeight: 500,
                          }}
                        >
                          Picture Problem
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-2">
                      <Button
                        style={{
                          color: "#007BFF",
                          background: "none",
                          borderColor: "#007BFF",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          savePicture();
                          getByIdTicket(id);
                        }}
                        // disabled={
                        //   UserPermi.iu_ps_id ===
                        //   "d9300f9b-e533-47a2-b40c-4039e2e50ea3"
                        //     ? true
                        //     : false
                        // }
                      >
                        <div style={{ marginTop: "-3px" }}>Save Picture</div>
                      </Button>
                    </div> */}
                  </div>
                </Card>

                <div className="row mt-2" style={{ marginLeft: "10px" }}>
                  <Upload
                    beforeUpload={() => false}
                    listType="picture-card"
                    showUploadList={
                      {showRemoveIcon: false}
                    }
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    accept=".jpg,.png"
                    multiple
                    // disabled={
                    //   UserPermi.iu_ps_id ===
                    //   "d9300f9b-e533-47a2-b40c-4039e2e50ea3"
                    //     ? true
                    //     : false
                    // }
                  >
                    {/* {fileList && fileList.length >= 8 ? null : uploadButton} */}
                  </Upload>
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      style={{
                        width: "100%",
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              </div>

              <div className="row mt-3">
                <Card
                  key={12}
                  style={{
                    width: "100%",
                    height: "50px",
                    marginLeft: "5px",
                    // marginTop: "-10px",
                    boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <div
                    className="row"
                    style={{
                      justifyContent: "space-between",
                      // display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <div className="col-10">
                      <div
                        style={{
                          marginTop: "-10px",
                          color: "#007BFF",
                          // fontWeight: 700,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                          style={{ marginTop: "-6px" }}
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                        </svg>
                        <Button
                          style={{
                            marginTop: "-8px",
                            border: "none",
                            background: "none",
                          }}
                          onClick={() => {
                            // setRemarkList({
                            //   ...remarkList,
                            //   remark_mode : "Edit"
                            // })
                            // console.log("setremarkAdd",remarkList)
                            addRemark();
                          }}
                          disabled={
                            Tickect_List.ticket_tsb_id ===
                            "2027332c-b338-470b-9ab1-4c7b99775288"
                          }
                        >
                          <span
                            style={{
                              color: "#007BFF",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            REMARK
                          </span>
                        </Button>
                      </div>
                    </div>
                    {/* <div className="col-2" style={{ display: "flex" }}>
                      <Button
                        style={{
                          marginTop: "-25px",
                          color: "#007BFF",
                          background: "none",
                          borderColor: "#007BFF",
                          // height: "25px",
                          fontSize: "12px",
                        }}
                        onClick={() => {
                          saveRemark();
                        }}
                        // disabled={
                        //   UserPermi.iu_ps_id ===
                        //   "d9300f9b-e533-47a2-b40c-4039e2e50ea3"
                        //     ? true
                        //     : false
                        // }
                      >
                        <div style={{ marginTop: "-3px" }}>Save Remark</div>
                      </Button>
                    </div> */}
                  </div>
                </Card>

                <div
                  style={{ width: "96%", marginLeft: "5px", marginTop: "8px" }}
                >
                  {cardRemark}
                </div>
              </div>
            </div>

            {/* <div className="col-12 col-sm-4 col-md-12 col-lg-12 col-xl-0"></div> */}

            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
              <Card
                key={2}
                style={{
                  // width: "99%",
                  height: "50px" /* marginBottom: "15px" */,
                  boxShadow: "1px 4.76px 35.67px 0 rgba(0, 0, 0, 0.15)",
                }}
              >
                <div
                  style={{
                    marginTop: "-13px",
                    color: "#007BFF",
                    fontWeight: 700,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                    style={{ marginTop: "-5px" }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Log Ticket Phase
                  </span>
                </div>
              </Card>
              <div className="row mt-2">
                <Card
                  style={{
                    width: "98%",
                    marginLeft: "5px",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {cardLog.map((card) => (
                    <div
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                        paddingBottom: "20px",
                      }}
                      key={card.id}
                    >
                      {card.content}
                    </div>
                  ))}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Ticket_Detail;
