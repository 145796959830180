import React, { Component, useEffect, useState,useRef } from "react";
import { MDBDataTable } from "mdbreact";
import sortJsonArray from "sort-json-array";
import Swal from "sweetalert2";
import Select from "react-select";
import axios from "axios";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import INDF from "../../component/input_date_feature";
import INF from "../../component/input_feature";
function Spare_partInOut(props) {
  const [SparePart, setSparePart] = useState([]);
  const [loop_option, setloop_option] = useState([]);
  const select_loop = useRef();
  const [w_option, setw_option] = useState([]);
  const select_w = useRef();
  const [l_option, setl_option] = useState([]);
  const select_l = useRef();
  const [r_option, setr_option] = useState([]);
  const select_r = useRef();
  const [p_option, setp_option] = useState([]);
  const select_p = useRef();

  const [FillerSparePart, setFillerSparePart] = useState({
    spare_part_no: "",
    spare_part_name: "",
    product_no: "",
    company_id: getUser().com,
  });
  const [filter_loop, setFilter_loop] = useState({
    loop_no: "",
    company_id: getUser().com,
  });
  const [loop, setLoop] = useState([]);
  const [filter_warehouse, setFilter_warehouse] = useState({
    id: "",
    code_no: "",
    company_id:getUser().com,
    parent_id:"",
    type:"Warehouse",

 });
  const [warehouse, setWarehouse] = useState([]);
  const [rack, setRack] = useState([]);
  const [product_on_rack, setProduct_on_rack] = useState([]);
  const [load_status, setLoad_status] = useState([]);
  const [warehouse_data, setwarehouse_data] = useState({
    loop_id:"",
    warehouse_id:"",
    rack_id:"",
    product_on_rack_id:"",
    load_status_id:"",
    remark:"",
    description:"",

  });
  useEffect(() => {
    GetSparePart();
    GetLoop();
    GetWarehouse();
    GetLoad_status();
  }, []);

  function GetSparePart(params) {
    axios({
      method: "post",
      url: Configs.API_URL + "/sparePart/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: FillerSparePart,
    })
      .then(function (response) {
        setSparePart(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const clearFilter = async () => {
   setFillerSparePart({
      ...FillerSparePart,
      spare_part_no: "",
      spare_part_name: "",
      product_no: "",
    });

    FillerSparePart.spare_part_no = "";
    FillerSparePart.spare_part_name = "";
    FillerSparePart.product_no = "";
    GetSparePart();
  };


  const GetLoop = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/loop/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_loop
    })
      .then(function (response) {
        console.log(response.data);
        setLoop(response.data);
        const options = [];
        response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.loop_no;
        options.push(element);
         });
        setloop_option(options);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetWarehouse = async () => {
 
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/warehouse",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_warehouse
    
    })
      .then(function (response) {
        console.log(response.data);
        setWarehouse(response.data);
        const options = [];
        response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.code_no;
        options.push(element);
         });
        setw_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function GetRack(id) {
    var temp ={
      id: "",
      code_no: "",
      company_id:getUser().com,
      parent_id: id,
      type:"Rack",
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/rack",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    
    })
      .then(function (response) {
        console.log(response.data);
        setRack(response.data);
     
        const options = [];
        response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.code_no;
        options.push(element);
         });
        setr_option(options);
      
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function GetProduct_on_rack(id) {
    var temp ={
      id: "",
      code_no: "",
      company_id:getUser().com,
      parent_id: id,
      type:"Product_on_rack",
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter/product_on_rack",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    
    })
      .then(function (response) {
        console.log(response.data);
        setProduct_on_rack(response.data);
      
        const options = [];
        response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.code_no;
        options.push(element);
         });
        setp_option(options);
      
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const GetLoad_status = async () => {
    var temp = {
      status: "",
      company_id: getUser().com,
    }
    //console.log(filler_project);
    axios({
      method: "post",
      url: Configs.API_URL + "/load_status/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        console.log(response.data);
        setLoad_status(response.data);
        const options = [];
        response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.status;
        options.push(element);
         });
        setl_option(options);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value.trim()}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  async function SetDate_and_GetDayoff_start(date) {
    setcalender_data({ ...calender_data, exp: date });
  }

  const [calender_data, setcalender_data] = useState({
    exp: "",
    now_date: new Date(),
  });
  function form(no, name, id, amount, edit_type) {
    setmessage({ ...message, name: name, no: no });
    setamount({
      ...amount,
      amount: "",
      total: amount,
      id: id,
      lot_no: "",
      edit_type: edit_type,
    });
  }
  const [amount, setamount] = useState({
    id: "",
    edit_type: null,
    amount: "",
    total: "",
    loop_id: "",
    expire_date: "",
    warehouse_id: "",
    rack_id:"",
    product_on_rack_id:"",
    location: "",
    load_status_id: "",
  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });

  function Getamount(params) {
    if (
      amount.amount !== null &&
      amount.amount !== undefined &&
      amount.amount !== ""
    ) {
      if (amount.edit_type !== null) {
        const temp_amount = {
          id: amount.id,
          edit_type: amount.edit_type,
          amount: Number(amount.amount.replace(/,/g, "")),
          loop_id: amount.loop_id,
          expire_date: amount.expire_date,
          warehouse_id: amount.warehouse_id,
          rack_id:amount.rack_id,
          location: amount.location,
          load_status_id: amount.load_status_id,
          remark:"",
          description: "",
        };

        axios({
          method: "post",
          url: Configs.API_URL + "/sparePart/amountSparePartInOut",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_amount,
        })
          .then(function (response) {
            console.log(response.data);
            Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
            GetSparePart();
            axios({
              method: "post",
              url: Configs.API_URL + "/sparePart/filter",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: FillerSparePart,
            })
              .then(function (response) {
                console.log(response.data);
                let set_total_view = response.data.filter((element)=>{
                  return element.id === amount.id;
                })              
                setamount({
                  ...amount,
                  total: set_total_view[set_total_view.length - 1].amount || 0,
                  amount:"",
                });
                
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(function (error) {
            console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
           
          });
        })
        .catch(function (error) {
          console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
         
        });
         /* console.log(temp_amount);
    
        setamount({
          id: amount.id,
          edit_type: null,
          amount: "",
        });
        amount.id = amount.id;
        amount.edit_type = null;
        amount.amount = "";
 
        let real_id =SparePart.filter((x)=>{
          return (x.id === amount.id);
        })
        console.log(real_id);       
        setamount({
          ...amount
        });
         setamount({
          ...amount,
          total: real_id[0].cut_amount
        });
        GetSparePart();

        console.log(temp_amount); */
      }
    }
  }
  const rowsData = [];

  for (var index = 0; index < SparePart.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["spare_part_no"] = SparePart[index].spare_part_no;
    rowItem["spare_part_name"] = SparePart[index].spare_part_name;
    rowItem["amount"] = SparePart[index].amount || 0;
    rowItem["product_ref"] = SparePart[index].product_ref.map((el, i) => {
      return (
        <div key={el.product_id}>
          {el.product_no}{" "}
          {i < SparePart[index].product_ref.length - 1 ? "," : ""}
        </div>
      );
    });
    rowItem["description"] = SparePart[index].description;

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className=" btn btn-xs "
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={form.bind(
              this,
              SparePart[index].spare_part_no,
              SparePart[index].spare_part_name,
              SparePart[index].id,
              SparePart[index].amount,
              amount.edit_type
            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spare_Part_No",
        field: "spare_part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spare_Part_Name",
        field: "spare_part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product_ref",
        field: "product_ref",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      {/*  <Header />
        <Sidebar menu="warehouse" activemenu="cuttinginout" submenu="none" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Spare Part IN/OUT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Spare Part IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={FillerSparePart.spare_part_no.trim()}
                    onChange={(e) => {
                      setFillerSparePart({
                        ...FillerSparePart,
                        spare_part_no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Spare Part No</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={FillerSparePart.spare_part_name.trim()}
                    onChange={(e) => {
                      setFillerSparePart({
                        ...FillerSparePart,
                        spare_part_name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Spare Part Name</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={FillerSparePart.product_no.trim()}
                    onChange={(e) => {
                      setFillerSparePart({
                        ...FillerSparePart,
                        product_no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product No</label>{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetSparePart();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Cutting IN/OUT </h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div
                className="table-responsive" /*s tyle={{ height: "400px" }} */
              >
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}

        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">
                  Edit Amount : Items in Spare part warehouse &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={{
                        backgroundColor: "#F0FFFF",
                        border: "1px solid #ddd",
                        padding: "10px",
                        width: "100",
                      }}
                      value={amount.total}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                  &nbsp;&nbsp;&nbsp; items{" "}
                </h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-4">
                    <h3>Spare part No : {message.no}</h3>
                    <input
                      type="radio"
                      id="inbound"
                      name="edit_type"
                      checked={amount.edit_type != true ? false : true}
                      value={true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "true" ? true : false,
                        });
                      }}
                    />

                    <label htmlFor="inbound">
                      <h4>  In bound</h4>
                    </label>
                  </div>

                  <div className="col-4">
                    <h3>Spare part Name : {message.name}</h3>

                    <div className="row"></div>

                    <input
                      type="radio"
                      id="outbound"
                      name="edit_type"
                      value={false}
                      checked={amount.edit_type != false ? false : true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "false" ? false : true,
                        });
                      }}
                    />
                    <label htmlFor="outbound">
                      <h4>  Out bound</h4>
                    </label>
                  </div>
                </div>
                {amount.edit_type === true ? (
                    <>
                <div className="row">
                   
                   <div className="col-3"style = {{"z-index":"98"}}>
                   <div className="form-group">


                   <Select 
                          options={loop_option}
                          ref = {select_loop}
                          defaultValue={amount.loop_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setamount({
                              ...amount,
                              loop_id: e.value || "",
                            });
                            }
                           
                          }}
                        />  
                        {/* <select
                          className="custom-select"
                          value={amount.loop_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setamount({
                              ...amount,
                              loop_id: e.target.value,
                            })
                          }
                        >
                          <option value="" selected disabled>
                            select loop
                          </option>
                          {loop.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.loop_no}
                              </option>
                            );
                          })}
                        </select> */}
                        <label htmlFor="">Loop</label>
                      </div>
                   </div>
                   <div className="col-3">
                   <div className="form-group">


                   <Select
                          options={w_option}
                          ref = {select_w}
                          defaultValue={amount.warehouse_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setamount({
                              ...amount,
                              warehouse_id: e.value || "",
                            });
                              GetRack(e.value || "");
                            }
                           
                          }}
                        />  
                        {/* <select
                          className="custom-select"
                          value={amount.warehouse_id}
                          //disabled={mode.mode === "read" ? true : false}
                         
                          onChange={(e) =>{
                            setamount({
                              ...amount,
                              warehouse_id: e.target.value,
                            });

                            GetRack(e.target.value);
                          }}
                        >
                          <option value="" selected disabled>
                            select warehouse
                          </option>
                          {warehouse.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.code_no}
                              </option>
                            );
                          })}
                        </select> */}
                        <label htmlFor="">Warehouse</label>
                      </div>
                   </div>
                   <div className="col-3">
                   <div className="form-group">


                   <Select
                          options={r_option}
                          ref = {select_r}
                          defaultValue={amount.rack_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setamount({
                              ...amount,
                              rack_id: e.value || "",
                            });
                            GetProduct_on_rack(e.value || "");
                            }
                           
                          }}
                        />  
                        {/* <select
                          className="custom-select"
                          value={amount.rack_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>{
                            setamount({
                              ...amount,
                              rack_id: e.target.value,
                            })
                            GetProduct_on_rack(e.target.value);
                          }}
                        >
                          <option value="" selected disabled>
                            select rack
                          </option>
                          {rack.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.code_no}
                              </option>
                            );
                          })}
                        </select> */}
                        <label htmlFor="">Rack</label>
                      </div>
                   </div>
                   <div className="col-3">
                   <div className="form-group">



                   <Select
                          options={p_option}
                          ref = {select_p}
                          defaultValue={amount.product_on_rack_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setamount({
                              ...amount,
                              product_on_rack_id: e.value || "",
                            });
                          
                            }
                           
                          }}
                        />  
                        {/* <select
                          className="custom-select"
                          value={amount.product_on_rack_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>{
                            setamount({
                              ...amount,
                              product_on_rack_id: e.target.value,
                            })
                 
                          }}
                        >
                          <option value="" selected disabled>
                            select product on rack
                          </option>
                          {product_on_rack.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.code_no}
                              </option>
                            );
                          })}
                        </select> */}
                        <label htmlFor="">Product on rack</label>
                      </div>
                   </div>
                
                   <div className="col-3">
                   <div className="form-group">



                     
                   <Select
                          options={l_option}
                          ref = {select_l}
                          defaultValue={amount.load_status_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setamount({
                              ...amount,
                              load_status_id: e.value || "",
                            });
                          
                            }
                           
                          }}
                        />  
                        {/* <select
                          className="custom-select"
                          value={amount.load_status_id}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setamount({
                              ...amount,
                              load_status_id: e.target.value,
                            })
                          }
                        >
                          <option value="" selected disabled>
                            select load status
                          </option>
                          {load_status.map((el) => {
                            return (
                              <option key={el.id} value={el.id}>
                                {el.status}
                              </option>
                            );
                          })}
                        </select> */}
                        <label htmlFor="">Load Status</label>
                      </div>
                   </div>
                   {/* {
                    load_status.map((e)=>{
                      if(e.status == "Fail" && e.id === warehouse_data.load_status_id){
                        return (<>
              <div className="col-3">
                   <INF value = {warehouse_data.remark}  label = {"Remark"} onChange ={(e) => {
                          setwarehouse_data({
                            ...warehouse_data,
                            remark: e.target.value,
                          });
                        }}/>
                   </div>
                        </>);
                      }
                    })
                   } */}
                  
                   <div className="col-3">
                     <INF value = {amount.location}  label = {"Location"} onChange ={(e) => {
                          setamount({
                            ...amount,
                            location: e.target.value,
                          });
                        }}/></div>
                   </div>
                   </>):(<></>)}
              
                <div className="row">
                  {" "}
                  <div className="col-5"></div>
                  <div className="col-4">
                    <div className="form-group ">
                      <INDF
                        txt = "EXP Date"
                        selected={calender_data.exp}
                        onChange={(date) => SetDate_and_GetDayoff_start(date)}
                        customInput={<CustomInput />}
                      />

                      <label>&nbsp;&nbsp; Expire Date &nbsp;&nbsp;</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4">
                    <div className="form-group ">
                      <NumberFormat
                        format="################"
                        thousandSeparator={true}
                        type="text"
                        className="form-control"
                        value={amount.amount}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            amount: e.target.value,
                          });
                        }}
                        required
                      />
                      <label htmlFor="">Amount In</label>{" "}
                    </div>
                  </div>

                  <div className="col-4"></div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={Getamount}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Spare_partInOut;
