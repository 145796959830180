import React, { useEffect, useState, useRef } from "react";
import { renderToString } from "react-dom/server";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import sortJsonArray from "sort-json-array";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import { useParams, useHistory } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Footter from "../../component/Footter";
import material_excel from "../../assets/file/upload_Material.xlsx";
import Header from "../../component/Header";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
    getOem,
    getToken,
    getUser,
    getFeature,
    getAcademy,
} from "../../Utils/Common";
import { Get_font_pdf_mat } from "../../assets/font/pdf_font";
import QRCode2 from "qrcode";
import BTN from "../../component/btn_feature";
import Select from "react-select";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import moment from "moment";

function ToolingInOut() {

    useEffect(() => {
        getfilterTooling();
        getToolingBrand();
        getToolingSupplier();
    }, []);
    const [test, settest] = useState({
        test: ""
    })
    const [checked, setchecked] = useState({
        checked: false
    })
    const [MDBT, setMDBT] = useState(true);
    const [filterTooling, setfilterTooling] = useState({
        tooling_code: "",
        tooling_name: "",
        brand_id: "",
        brand_name: "",
        supplier_id: "",
        supplier_name: "",
        under_min_stock: false,
    });
    const [tooling, settooling] = useState([]);
    const [brand, setbrand] = useState([]);
    const select_brand = useRef();
    const [brand_option, setbrand_option] = useState([]);
    const [supplier, setsupplier] = useState([]);
    const [supplier_option, setsupplier_option] = useState([]);
    const select_supplier = useRef();
    const [Inbound, setInbound] = useState(true)
    const [is_fifo, setis_fifo] = useState(true);
    const [LotInStock, setLotInStock] = useState([])
    const [LotHistory, setLotHistory] = useState([])
    const [LotNoGen, setLotNoGen] = useState({
        select: "",
        lot_no: "",
        edit_type: true,
        amount: "",
        remark: "",
        current_amount: "",
        check: false,
    })
    const [Alldata, setAlldata] = useState([])
    const [detailTooling, setdetailTooling] = useState({
        id: "",
        tooling_id: "",
        tooling_name: "",
        lot_no: "",
        amount: "",
        remark: "",
        edit_type: true,

    });
    const [zIndexSupAndBrand, setZIndexSupAndBrand] = useState({
        brand: "",
        supply: ""
    })

    const CustomInput = ({ value, onClick }) => (
        <div className="input-group">
            <input
                type="text"
                className="form-control float-left"
                onClick={onClick}
                value={value}
            />{" "}
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="far fa-calendar-alt" />
                </span>
            </div>
        </div>
    );

    function getfilterTooling() {
        let filter = {
            company_id: getUser().com,
            oem_id: getOem(),
            tooling_code: filterTooling.tooling_code.trim(),
            tooling_name: filterTooling.tooling_name.trim(),
            brand_id: filterTooling.brand_id.trim(),
            supplier_id: filterTooling.supplier_id.trim(),
            under_min_stock: filterTooling.under_min_stock,
        }
        axios({
            method: "post",
            url: Configs.API_URL + "/tooling/getfilterTooling",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: filter
        })
            .then(function (response) {
                console.log("Tooling", response.data);
                settooling(response.data);
                settest({ test: "" })
                test.test = ""
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", error.response.data.message, "error");
            });
    }

    function clearfilter() {
        setfilterTooling({
            ...filterTooling,
            tooling_code: "",
            tooling_name: "",
            brand_id: "",
            brand_name: "",
            supplier_id: "",
            supplier_name: "",
            under_min_stock: false,
        });
        filterTooling.tooling_code = "";
        filterTooling.tooling_name = "";
        filterTooling.brand_id = "";
        filterTooling.brand_name = "";
        filterTooling.supplier_id = "";
        filterTooling.supplier_name = "";
        filterTooling.under_min_stock = false;
        settest({ test: "" })
        test.test = ""
        getfilterTooling();
    }

    function getToolingSupplier() {
        axios({
            method: "get",
            url: Configs.API_URL + "/tooling/getToolingSupplier?id=" + getUser().com,
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                setsupplier(response.data);
                const options = [{ value: "", label: "Select All" }];
                response.data.forEach((el) => {
                    var element = {};
                    element["value"] = el.name;
                    element["label"] = el.name;
                    element["supplier_id"] = el.id;
                    options.push(element);
                });
                setsupplier_option(options);
                settest({ test: "" })
                test.test = ""
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", error.response.data.error.message, "error");
            });
    }

    function getToolingBrand() {
        axios({
            method: "get",
            url:
                Configs.API_URL +
                `/tooling/getToolingBrand?id=${getUser().com}&id2=${getOem()}`,
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                setbrand(response.data);
                const options = [{ value: "", label: "Select All" }];
                response.data.forEach((el) => {
                    let element = {};
                    element["value"] = el.brand_name;
                    element["label"] = el.brand_name;
                    element["brand_id"] = el.brand_id;
                    options.push(element);
                });
                setbrand_option(options);
                settest({ test: "" })
                test.test = ""
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", error.response.data.error.message, "error");
            });
    }

    function getLotInStock() {
        let data = {
            tooling_id: Alldata.id,
            company_id: getUser().com,
            oem_id: getOem(),
        }
        axios({
            method: "post",
            url:
                Configs.API_URL +
                `/tooling/getLotInStock`,
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data
        })
            .then(function (response) {
                // console.log("LotInStock", response.data);
                setLotInStock(response.data);
            }
            )
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", error.response.data.error.message, "error");
            }
            );
    }

    function getLotHistory() {
        let data = {
            tooling_id: Alldata.id,
            company_id: getUser().com,
            oem_id: getOem(),
        }
        axios({
            method: "post",
            url:
                Configs.API_URL +
                `/tooling/getLotHistory`,
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data
        })
            .then(function (response) {
                // console.log("LotHistory", response.data);
                setLotHistory(response.data);
            }
            )
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", error.response.data.error.message, "error");
            }
            );
    }

    function genLotNo() {
        axios({
            method: "get",
            url: Configs.API_URL + "/tooling/genLotNo?oem_id=" + getOem(),
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                setLotNoGen({
                    ...LotNoGen,
                    lot_no: response.data,
                });
                LotNoGen.lot_no = response.data;
                settest({ test: "" })
                test.test = ""
            }).catch(function (error) {
                console.log(error);
                Swal.fire("Error", error.response.data.error.message, "error");
            })
    }

    function saveToolingInBound() {
        if (LotNoGen.amount === "" || LotNoGen.amount == 0) {
            Swal.fire({
                title: "กรุณากรอก Amount",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ตกลง",
            });
            return;
        }
        Swal.fire({
            title: "คุณต้องการที่จะบันทึกใช่หรือไม่?",
            text: "คุณต้องการที่จะบันทึกใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: "ไม่ใช่",
        }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    lot_no: LotNoGen.lot_no,
                    tooling_id: Alldata.id || null,
                    edit_type: LotNoGen.edit_type || null,
                    amount: LotNoGen.amount.trim() || null,
                    remark: LotNoGen.remark.trim() || null,
                    fup: getUser().fup,
                    oem_id: getOem(),
                    company_id: getUser().com,
                    current_amount: LotNoGen.current_amount || null,
                }
                axios({
                    method: "post",
                    url: Configs.API_URL + "/tooling/SaveLotInBound",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: data,
                })
                    .then(function (response) {
                        // console.log("SaveLotInBound", response.data);
                        if (response.data === true) {
                            Swal.fire({
                                title: "บันทึกสำเร็จ",
                                text: "บันทึกสำเร็จ",
                                icon: "success",
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1000,
                            }).then(() => {
                                Swal.close();
                                genLotNo();
                                setLotNoGen({
                                    ...LotNoGen,
                                    amount: "",
                                    remark: "",
                                    edit_type: true,
                                });
                                LotNoGen.amount = "";
                                LotNoGen.remark = "";
                                LotNoGen.edit_type = true;
                                getLotInStock();
                                getLotHistory();
                                getAmountTooling();
                                getfilterTooling();
                            });
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: "บันทึกไม่สำเร็จ",
                            text: error.response.data.message,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "ตกลง",
                            confirmButtonColor: "#3085d6",
                        }).then(() => {
                            Swal.close();
                        }
                        );
                    });
            }
        })
    }

    async function saveToolingOutBound() {
        if (LotNoGen.amount === "" || LotNoGen.amount == 0) {
            Swal.fire({
                title: "กรุณากรอก Amount",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ตกลง",
            });
            return;
        }
        if (is_fifo !== true) {
            if (LotNoGen.check === false) {
                Swal.fire({
                    title: "กรุณาเลือก Lot No",
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "ตกลง",
                });
                return;
            }
        }
        Swal.fire({
            title: "คุณต้องการที่จะบันทึกใช่หรือไม่?",
            text: "คุณต้องการที่จะบันทึกใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: "ไม่ใช่",
        }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    lot_no: LotNoGen.lot_no,
                    tooling_id: Alldata.id || null,
                    edit_type: LotNoGen.edit_type || null,
                    amount: LotNoGen.amount.trim() || null,
                    remark: LotNoGen.remark.trim() || null,
                    fup: getUser().fup,
                    oem_id: getOem(),
                    company_id: getUser().com,
                    current_amount: LotNoGen.current_amount || null,
                    is_fifo: is_fifo,
                    select: LotNoGen.select || null,
                }
                let url_api = Configs.API_URL + "/tooling/SaveLotOutBound";
                if (Number(data.amount) > 0) {
                    if (is_fifo === true) {
                        //FIFO ปกติ
                        url_api = Configs.API_URL + "/tooling/SaveLotOutBound";
                    } else if (is_fifo === false) {
                        //NON-FIFO แบบเลือกตัด
                        url_api = Configs.API_URL + "/tooling/SaveLotOutBound_FIFO";
                    }
                }
                axios({
                    method: "post",
                    url: url_api,
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: data,
                })
                    .then(async function (response) {
                        // console.log("SaveLotInBound", response.data);
                        if (response.data === true) {
                            Swal.fire({
                                title: "บันทึกสำเร็จ",
                                text: "บันทึกสำเร็จ",
                                icon: "success",
                                showCancelButton: false,
                                showConfirmButton: false,
                                timer: 1000,
                            }).then(() => {
                                Swal.close();
                                genLotNo();
                                setLotNoGen({
                                    ...LotNoGen,
                                    select: "",
                                    amount: "",
                                    remark: "",
                                    edit_type: false,
                                    check: false,
                                });
                                LotNoGen.select = "";
                                LotNoGen.amount = "";
                                LotNoGen.remark = "";
                                LotNoGen.edit_type = false;
                                LotNoGen.check = false;
                                getLotInStock();
                                getLotHistory();
                                getAmountTooling();
                                getfilterTooling();
                            });
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: "บันทึกไม่สำเร็จ",
                            text: error.response.data.message,
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "ตกลง",
                            confirmButtonColor: "#3085d6",
                        }).then(() => {
                            Swal.close();
                        }
                        );
                    });
            }
        })
    }

    async function form(id, tooling_code, tooling_name, brand_name, min_stock, consumption, amount, edit_type) {
        // console.log("form", id, tooling_code, tooling_name, brand_name, min_stock, consumption, amount, edit_type);
        setMDBT(true);
        setis_fifo(true);
        setLotNoGen({
            ...LotNoGen,
            amount: "",
            remark: "",
            edit_type: true,
            check: false,
        });
        LotNoGen.amount = "";
        LotNoGen.remark = "";
        LotNoGen.edit_type = true;
        LotNoGen.check = false;
        setAlldata({
            ...Alldata,
            id: id,
            tooling_code: tooling_code,
            tooling_name: tooling_name,
            brand_name: brand_name,
            min_stock: min_stock,
            consumption: consumption,
            amount: amount,
            edit_type: true,
        });
        Alldata.id = id;
        Alldata.tooling_code = tooling_code;
        Alldata.tooling_name = tooling_name;
        Alldata.brand_name = brand_name;
        Alldata.min_stock = min_stock;
        Alldata.consumption = consumption;
        Alldata.amount = amount;
        Alldata.edit_type = edit_type;
        setdetailTooling({
            ...detailTooling,
            id: id,
            tooling_code: tooling_code,
            tooling_name: tooling_name,
            amount: amount,
        })
        await getfilterTooling();
        await genLotNo();
        await getLotInStock();
        await getLotHistory();
    }

    function getAmountTooling() {
        axios({
            method: "get",
            url: Configs.API_URL + "/tooling/GetAmountTooling?id=" + Alldata.id,
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
            }
        })
            .then(async function (response) {
                console.log("GetAmountTooling", response.data);
                let amountFinal = response.data.amount;
                if (amountFinal === null) {
                    amountFinal = 0;
                }
                setdetailTooling({
                    ...detailTooling,
                    amount: amountFinal,
                });
            })
            .catch((error) => {
                console.log(error.response);
                Swal.fire({ title: "เกิดข้อผิดพลาด", text: error.response.data.message, icon: "error", showCancelButton: false, confirmButtonText: "ตกลง", confirmButtonColor: "#3085d6", }).then(() => { Swal.close(); });
            }
            );
    }
    const rowsData2 = [];
    for (let index = 0; index < LotInStock.length; index++) {
        const rowItem = {};
        rowItem["LotNo"] = LotInStock[index].lot_no;
        rowItem["Amount"] = (
            <NumberFormat
                value={LotInStock[index].amount}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
            />
        );
        rowItem["PONo"] = LotInStock[index].purchase_order_id;
        rowItem["CreateDate"] = moment(LotInStock[index].create_date).format("DD/MM/YYYY");
        rowItem["Remark"] = LotInStock[index].remark;
        rowItem["select"] = (
            <div className="row">
                <div className="col-6"></div>
                <div className="col-4">
                    <input
                        className="form-check-input"
                        type="radio"
                        id={index}
                        name="select"
                        checked={LotInStock[index].lot_no === LotNoGen.select ? true : false}
                        value={LotInStock[index].lot_no}
                        onClick={changes_log_no.bind(
                            this
                        )}
                    />
                </div>
            </div>
        )

        rowsData2.push(rowItem);
    }
    const data2 = {
        columns: [
            {
                label: "++++",
                field: "select",
                sort: "asc",
                width: 50,
            },
            {
                label: "Lot No.",
                field: "LotNo",
                sort: "asc",
                width: 50,
            },
            {
                label: "Amount",
                field: "Amount",
                sort: "asc",
                width: 50,
            },
            {
                label: "PO No.",
                field: "PONo",
                sort: "asc",
                width: 50,
            },
            {
                label: "Create Date",
                field: "CreateDate",
                sort: "asc",
                width: 50,
            },
            {
                label: "Remark",
                field: "Remark",
                sort: "asc",
                width: 50,
            },
        ],
        rows: rowsData2,
    };
    if (is_fifo === true) {
        let index = data2.columns.findIndex((e) => {
            return e.field === "select";
        })
        data2.columns.splice(index, 1);
    }
    function changes_log_no(e) {
        //console.log(e.target.value);
        let lot_no = e.target.value;
        setLotNoGen({
            ...LotNoGen,
            select: lot_no,
            check: e.target.checked,
        })
        LotNoGen.select = lot_no;
        LotNoGen.check = e.target.checked;
        //console.log(amount);
    }
    const rowsData4 = [];
    for (let index = 0; index < LotHistory.length; index++) {
        const rowItem = {};
        rowItem["No"] = index + 1;
        rowItem["LotNo"] = LotHistory[index].lot_no;
        rowItem["Status"] = LotHistory[index].edit_type === true ? "In" : "Out";
        rowItem["Amount"] = (
            <NumberFormat
                value={LotHistory[index].amount}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
            />
        );
        rowItem["Date"] = moment(LotHistory[index].created_date).format("DD/MM/YYYY::HH:mm น.");
        rowItem["Remark"] = LotHistory[index].remark;

        rowsData4.push(rowItem);
    }
    const data4 = {
        columns: [
            {
                label: "No",
                field: "No",
                sort: "asc",
                width: 50,
            },
            {
                label: "Lot No.",
                field: "LotNo",
                sort: "asc",
                width: 50,
            },
            {
                label: "Status",
                field: "Status",
                sort: "asc",
                width: 50,
            },
            {
                label: "Amount",
                field: "Amount",
                sort: "asc",
                width: 50,
            },
            {
                label: "Date",
                field: "Date",
                sort: "asc",
                width: 50,
            },
            {
                label: "Remark",
                field: "Remark",
                sort: "asc",
                width: 50,
            },
        ],
        rows: rowsData4,
    };

    const rowsData = [];
    for (let index = 0; index < tooling.length; index++) {
        const rowItem = {};
        let c = "";
        if (Number(tooling[index].amount) < Number(tooling[index].min_stock)) {
            c = "red"
        } else if (Number(tooling[index].amount) >= Number(tooling[index].min_stock)) {
            c = "black"
        } else {
            c = "black"
        }
        rowItem["No"] = index + 1;
        rowItem["ToolingCode"] = tooling[index].tooling_code;
        rowItem["Image"] = (
            <div className="row" style={{ textAlign: "center" }}>
                <div className="col-12">
                    <img
                        src={tooling[index].tooling_path_name !== null && tooling[index].tooling_path_name !== ""  ? Configs.API_URL_IMG + tooling[index].tooling_path_name : userdefault_img.imgs}
                        alt="img"
                        className="img-fluid"
                        style={{ width: "50px", height: "50px" }}
                    />
                </div>
            </div>
        );
        rowItem["ToolingName"] = tooling[index].tooling_name;
        rowItem["Brand"] = tooling[index].brand_name;
        rowItem["MinStock"] = (
            <div style={{ color: c }}>
                <NumberFormat
                    value={tooling[index].min_stock === null ? 0 : tooling[index].min_stock}
                    displayType={"text"}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                />
            </div>
        );
        rowItem["Consumption"] = (
            <NumberFormat
                value={tooling[index].consumption === null ? 0 : tooling[index].consumption}
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
            />
        );
        rowItem["Amount"] = (
            <div style={{ color: c }}>
                <NumberFormat
                    value={tooling[index].amount === null ? 0 : tooling[index].amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                />
            </div>
        );
        rowItem["MGT"] = (
            <>
                <div className="row">
                    <div className="col-4">
                        <a
                            href={"/Warehouse_Management/Factory_Master_Data/Tooling/read/" + tooling[index].tooling_id}
                            key={tooling[index].tooling_id}
                            className=" btn btn-xs "
                        >
                            <i class="fas fa-eye"></i>
                        </a>
                    </div>
                    <div className="col-4">
                        <a
                            href={"#"}
                            key={""/* materail[index].id */}
                            className=" btn btn-xs "
                            data-toggle="modal"
                            data-target="#modal-xl"
                            onClick={form.bind(
                                this,
                                tooling[index].tooling_id,
                                tooling[index].tooling_code,
                                tooling[index].tooling_name,
                                tooling[index].brand_name,
                                tooling[index].min_stock,
                                tooling[index].consumption,
                                tooling[index].amount,
                                LotNoGen.edit_type,
                            )
                            }
                        >
                            {" "}
                            <i class="fas fa-pencil-alt"></i>{" "}
                        </a>
                    </div>
                </div>
            </>
        );
        rowsData.push(rowItem);
    }

    const data = {
        columns: [
            {
                label: "No",
                field: "No",
                sort: "asc",
                width: 50,
            },
            {
                label: "Tooling Code",
                field: "ToolingCode",
                sort: "asc",
                width: 50,
            },
            {
                label: "Image",
                field: "Image",
                sort: "asc",
                width: 50,
            },
            {
                label: "Tooling Name",
                field: "ToolingName",
                sort: "asc",
                width: 50,
            },
            {
                label: "Brand",
                field: "Brand",
                sort: "asc",
                width: 50,
            },
            {
                label: "Min Stock",
                field: "MinStock",
                sort: "asc",
                width: 50,
            },

            {
                label: "Consumption",
                field: "Consumption",
                sort: "asc",
                width: 50,
            },
            {
                label: "Amount",
                field: "Amount",
                sort: "asc",
                width: 50,
            },
            {
                label: "MGT",
                field: "MGT",
                sort: "asc",
                width: 50,
            },
        ],
        rows: rowsData,
    };

    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Tooling IN/OUT{"  "}<a target="_blank" href={""}><i class='fa fa-info-circle' ></i></a></h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Warehouse Management
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Tooling IN/OUT
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-2 col-xl-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        required
                                        value={filterTooling.tooling_code}
                                        onChange={(e) => {
                                            setfilterTooling({
                                                ...filterTooling,
                                                tooling_code: e.target.value,
                                            });
                                        }}
                                    />
                                    <label>Tooling Code</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-xl-2">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        required
                                        value={filterTooling.tooling_name}
                                        onChange={(e) => {
                                            setfilterTooling({
                                                ...filterTooling,
                                                tooling_name: e.target.value,
                                            });
                                        }}
                                    />
                                    <label>Tooling Name</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-xl-3" style={{ zIndex: zIndexSupAndBrand.brand}}
                                onClick={() => {
                                    setZIndexSupAndBrand({
                                        brand: "999",
                                        supply: "0"
                                    })
                                    zIndexSupAndBrand.brand = "999";
                                    zIndexSupAndBrand.supply = "0";
                                }}
                            >
                                <div className="form-group">
                                    <Select
                                        ref={select_brand}
                                        value={brand_option.find(
                                            (item) => item.value === filterTooling.brand_name
                                        )}
                                        options={brand_option}
                                        onChange={(e) => {
                                            if (e !== null) {
                                                setfilterTooling({
                                                    ...filterTooling,
                                                    brand_id: e.brand_id || "",
                                                    brand_name: e.value || "",
                                                });
                                            }
                                        }}

                                    />
                                    <label htmlFor="">Brand</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-3 col-xl-3" style={{ zIndex: zIndexSupAndBrand.supply }}
                                onClick={() => {
                                    setZIndexSupAndBrand({
                                        brand: "0",
                                        supply: "999"
                                    })
                                    zIndexSupAndBrand.brand = "0";
                                    zIndexSupAndBrand.supply = "999";
                                }}
                            >
                                <div className="form-group">
                                    <Select
                                        options={supplier_option}
                                        ref={select_supplier}
                                        value={supplier_option.find(
                                            (item) => item.value === filterTooling.supplier_name
                                        )}
                                        onChange={(e) => {
                                            if (e !== null) {
                                                setfilterTooling({
                                                    ...filterTooling,
                                                    supplier_id: e.supplier_id || "",
                                                    supplier_name: e.value || "",
                                                });
                                            }
                                        }}
                                    />
                                    <label>Supplier</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1 col-md-1 col-xl-1"></div>
                            <div className="col-2 col-md-2 col-xl-2">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        type="checkbox"
                                        id="customCheckbox1"
                                        checked={filterTooling.under_min_stock}
                                        onChange={(e) => {
                                            setfilterTooling({
                                                ...filterTooling,
                                                under_min_stock: e.target.checked,
                                            });
                                            filterTooling.under_min_stock = e.target.checked;
                                            getfilterTooling();
                                        }}
                                    />
                                    <label
                                        htmlFor="customCheckbox1"
                                        className="custom-control-label"
                                        style={{ whiteSpace: 'nowrap'}}
                                    >
                                        Under Min Stock
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-2 col-xl-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={() => {
                                            getfilterTooling();
                                            setMDBT(false);
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-xl-2">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-primary"
                                        onClick={() => {
                                            clearfilter();
                                            setMDBT(false);
                                        }}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Tooling</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <MDBDataTable
                                                sortable={false}
                                                className="table table-head-fixed"
                                                striped
                                                bordered
                                                hover
                                                fixedHeader
                                                small
                                                disableRetreatAfterSorting={MDBT}
                                                data={data}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id={"modal-xl"}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Amount : items in warehouse&nbsp;&nbsp;<div style={{ border: '1px solid black', borderRadius: "5px", padding: '5px', display: 'inline-block', backgroundColor: "#D6EAF8" }}>{
                                <NumberFormat
                                    value={detailTooling.amount}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    thousandsGroupStyle="thousand"
                                />
                            }</div>&nbsp;&nbsp;{" "}items</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-md-5 col-xl-5">
                                    <div className="form-group">
                                        <h4 className="text-center">Tooling Code : {Alldata.tooling_code}</h4>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 col-xl-5">
                                    <div className="form-group">
                                        <h4 className="text-center">Tooling Name : {Alldata.tooling_name}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-0 col-md-2 col-xl-2"></div>
                                <div className="col-0">
                                    <input
                                        type="radio"
                                        id="inbound"
                                        name="customRadio"
                                        value={true}
                                        checked={LotNoGen.edit_type !== true ? false : true}
                                        onChange={(e) => {
                                            setLotNoGen({ ...LotNoGen, edit_type: e.target.value === "true" ? true : false })
                                            setis_fifo(true)
                                            settest({ ...test, test: "" })
                                            test.test = ""
                                        }}
                                    />
                                </div>
                                <div className="col-5 col-md-5 col-xl-5">
                                    <label htmlFor="inbound" ><h4>In Bound</h4></label>
                                </div>
                                <div className="col-0">
                                    <input
                                        type="radio"
                                        id="outbound"
                                        name="customRadio"
                                        value={false}
                                        checked={LotNoGen.edit_type !== false ? false : true}
                                        onChange={(e) => {
                                            setLotNoGen({ ...LotNoGen, edit_type: e.target.value === "false" ? false : true })
                                            settest({ ...test, test: "" })
                                            test.test = ""
                                        }}
                                    />
                                </div>
                                <div className="col-5 col-md-4 col-xl-4">
                                    <label htmlFor="outbound" ><h4>Out bound</h4></label>
                                </div>
                            </div>
                            {LotNoGen.edit_type === false
                                ? (
                                    <div className="col-10" style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center', margin: '0px'  }}>
                                        {/* <div className="col-0 col-md-0 col-xl-3"></div> */}
                                        <div className="col-5 col-md-2 col-xl-2" style={{ textAlign: "right", padding: '0' }}>
                                            <label htmlFor="manual_out_fg">Manual Out FG</label>
                                        </div>
                                        <div className="col-6 col-md-2 col-xl-2" style={{ marginLeft: '.5rem'}}>
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="manual_out_fg"
                                                    defaultChecked={is_fifo}
                                                    onChange={(e) => {
                                                        // console.log(e.target.checked)
                                                        setLotNoGen({
                                                            ...LotNoGen,
                                                            select: "",
                                                        })
                                                        setis_fifo(e.target.checked)
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="manual_out_fg">First In First Out</label>
                                            </div>
                                        </div>
                                    </div>
                                ) : ("")}
                            <div className="row">
                                <div className="col-12 col-md-12 col-xl-12">
                                    <div class="card card-primary card-outline card-outline-tabs">
                                        <div class="card-header p-0 border-bottom-0">
                                            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true">Lot In Stock</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false">Lot History</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="tab-content" id="custom-tabs-four-tabContent">
                                                <div className="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                                                    <h4>Lot In Stock</h4>
                                                    <div className="table-responsive">
                                                        <MDBDataTable
                                                            striped
                                                            bordered
                                                            small
                                                            data={data2}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel" aria-labelledby="custom-tabs-four-profile-tab">
                                                    <h4>Lot History</h4>
                                                    <div className="table-responsive">
                                                        <MDBDataTable
                                                            striped
                                                            bordered
                                                            small
                                                            data={data4}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {LotNoGen.edit_type === true
                                ? (
                                    <>
                                        <div className="row">
                                            <div className="col-3 col-md-4 col-xl-4"></div>
                                            <div className="col-6 col-md-4 col-xl-4">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        disabled="true"
                                                        value={LotNoGen.lot_no}
                                                    >
                                                    </input>
                                                    <label htmlFor="">Lot No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3 col-md-4 col-xl-4"></div>
                                            <div className="col-6 col-md-4 col-xl-4">
                                                <div className="form-group">
                                                    <NumberFormat
                                                        type="text"
                                                        value={LotNoGen.amount}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle="thousand"
                                                        className="form-control"
                                                        allowNegative={false}
                                                        decimalScale={0}
                                                        onChange={(e) => {
                                                            setLotNoGen({
                                                                ...LotNoGen,
                                                                amount: (e.target.value).replace(/,/g, "")
                                                            })
                                                            LotNoGen.amount = (e.target.value).replace(/,/g, "")
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    >
                                                    </NumberFormat>
                                                    <label htmlFor="">Amount</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3 col-md-4 col-xl-4"></div>
                                            <div className="col-6 col-md-4 col-xl-4">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={LotNoGen.remark}
                                                        onChange={(e) => {
                                                            setLotNoGen({
                                                                ...LotNoGen,
                                                                remark: e.target.value
                                                            })
                                                            LotNoGen.remark = e.target.value
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    >
                                                    </input>
                                                    <label htmlFor="">Remark</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 col-md-5 col-xl-5"></div>
                                            <div className="col-4 col-md-2 col-xl-2">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="form-control btn btn-primary"
                                                        onClick={() => {
                                                            saveToolingInBound()
                                                        }}
                                                    >
                                                        Save Change
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            <div className="col-3 col-md-4 col-xl-4"></div>
                                            <div className="col-6 col-md-4 col-xl-4">
                                                <div className="form-group">
                                                    <NumberFormat
                                                        type="text"
                                                        value={LotNoGen.amount}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle="thousand"
                                                        className="form-control"
                                                        allowNegative={false}
                                                        decimalScale={0}
                                                        onChange={(e) => {
                                                            setLotNoGen({
                                                                ...LotNoGen,
                                                                amount: (e.target.value).replace(/,/g, "")
                                                            })
                                                            LotNoGen.amount = (e.target.value).replace(/,/g, "")
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    >
                                                    </NumberFormat>
                                                    <label htmlFor="">Amount</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3 col-md-4 col-xl-4"></div>
                                            <div className="col-6 col-md-4 col-xl-4">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={LotNoGen.remark}
                                                        onChange={(e) => {
                                                            setLotNoGen({
                                                                ...LotNoGen,
                                                                remark: e.target.value
                                                            })
                                                            LotNoGen.remark = e.target.value
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    >
                                                    </input>
                                                    <label htmlFor="">Remark</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 col-md-5 col-xl-5"></div>
                                            <div className="col-4 col-md-2 col-xl-2">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="form-control btn btn-primary"
                                                        onClick={() => {
                                                            saveToolingOutBound()
                                                        }}
                                                    >
                                                        Save Change
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ToolingInOut