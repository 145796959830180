import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Select from "react-select";
function Tow_truck(params) {

  const [company, setCompany] = useState([]);
  const [filter_towTruck , setfilter_towTruck] = useState({
    plate_number:"",
    chassis_number:"",
    model_car:"",
    car_type_id:"",
    oem_id: getOem(),
    company_id: getUser().com,
  })
  const [car_type,setcar_type] = useState([]);
  const [tow_truck,settow_truck] = useState([]);



  const GetCarType = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL + "/logistic/GetCarType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    
    })
      .then(async (response) => {
       
        setcar_type(response.data);

     
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const GetTowTruck = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/logistic/filter_towTruck",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    data:filter_towTruck,
    })
      .then(async (response) => {
       console.log(response.data);
        settow_truck(response.data);

     
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    GetCarType();
    GetTowTruck();
  }, []);
  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Tow_truck/add";}
        const clearFilter = async () => {
    setfilter_towTruck({
      ...filter_towTruck,
      plate_number:"",
      chassis_number:"",
      model_car:"",
      car_type_id:"",

    }); 
   
    filter_towTruck.plate_number = "";
    filter_towTruck.chassis_number = "";
    filter_towTruck.model_car = "";
    filter_towTruck.car_type_id = "";
    GetTowTruck();
   
  }; 
 
  

  function deldata(id_tow) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/logistic/delTowTruck?id=" + id_tow,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Tow_truck";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "มีบางอย่างผิดพลาด ไม่สามารถลบได้" + error, "error");
          });
      }
    });
  } 

 

  const rowsData = [];
  for(var index = 0; index < tow_truck.length; index++){
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["plate_number"] = tow_truck[index].plate_number;
    rowItem["chassis_number"] = tow_truck[index].chassis_number;
    rowItem["model_car"] = tow_truck[index].model_car;
    rowItem["car_type"] = "";
    var car_type_name = car_type.filter((e)=>{
      return e.id === tow_truck[index].car_type_id;
    });
    if(car_type_name.length > 0){
      rowItem["car_type"] = car_type_name[0].name;
    }

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
             href={"/Warehouse_Management/Factory_Master_Data/Tow_truck/read/"+ tow_truck[index].id}
            key={tow_truck[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
              href={"/Warehouse_Management/Factory_Master_Data/Tow_truck/edit/"+ tow_truck[index].id}
             key={tow_truck[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
             key={tow_truck[index].id}
            className=" btn btn-xs "
             onClick={deldata.bind(this,tow_truck[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทะเบียนรถ",
        field: "plate_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขตัวถัง",
        field: "chassis_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "รุ่นเเบบ",
        field: "model_car",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภท",
        field: "car_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }

    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="customer" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Tow Truck{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Tow Truck</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_towTruck.plate_number}
                    onChange={(e) => {
                      setfilter_towTruck({
                        ...filter_towTruck,
                        plate_number: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">ทะเบียนรถ</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_towTruck.chassis_number}
                    onChange={(e) => {
                      setfilter_towTruck({
                        ...filter_towTruck,
                        chassis_number: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">เลขตัวถัง</label>{" "}
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_towTruck.model_car}
                    onChange={(e) => {
                      setfilter_towTruck({
                        ...filter_towTruck,
                        model_car: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">รุ่นเเบบ</label>{" "}
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2" style = {{"z-index":"99"}}>
              <div className="form-group ">
              <select
                    className="custom-select select2 form-control"
                    value={filter_towTruck.car_type_id}
                    onChange={(e)=>{setfilter_towTruck({...filter_towTruck,car_type_id:e.target.value});
                  
                  }}
                  >
                    <option value="">--เลือกประเภท--</option>
                    {car_type.map((e)=>{
                      return (<>
                      <option value={e.id}>{e.name}</option> 
                      </>)
                    })}
                  
            
                  </select>
                         <label htmlFor="">ประเภท</label>
                      </div>
                      </div>


                      <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetTowTruck();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
             
            </div>
          </div>
         
          <div className="container-fluid">
            <div className="row">
              
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Tow Truck</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " 
            >
                   <div className="table-responsive" style={{ whiteSpace: 'nowrap' }}>
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
   {/*   <Footter/> */}
     </div>
     
  );
}

export default Tow_truck;
