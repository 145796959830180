import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";

function Wire_Ready_toUse(params) {
  const [project, setProject] = useState([]);
  const [company, setCompany] = useState([]);
  const [all_steel_wire, setall_steel_wire] = useState([]);
  /* console.log(getUser().com); */
  const [filler_steel_wire, setFiller_steel_wire] = useState({
    diameter_no: "",
    diameter_name: "",
    oem_id: getOem(),
    company_id: getUser().com,
  });
  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */
  useEffect(() => {
    GetDiameter();
    GetCompany();
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function GetDiameter(){
    
    //console.log(filler_project);
    let trimm = {
      diameter_no :filler_steel_wire.diameter_no.trim(),
      diameter_name:filler_steel_wire.diameter_name.trim(),
      oem_id:filler_steel_wire.oem_id,
      company_id:filler_steel_wire.company_id,
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/Wire_Ready_toUse/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: trimm,
    })
      .then(function (response) {
        console.log("get",response.data);
        setall_steel_wire(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const clearFilter = async () => {
    await setFiller_steel_wire({
      ...filler_steel_wire,
      diameter_no: "",
      diameter_name: "",
      company_id: getUser().com,
      oem_id: getOem(),
    });
    filler_steel_wire.diameter_no = "";
    filler_steel_wire.diameter_name = "";
    filler_steel_wire.company_id = getUser().com;
    filler_steel_wire.oem_id = getOem();
    // filler_steel_wire.oem_id = "";
    // filler_steel_wire.company_id = "";
    GetDiameter();
   
  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/Wire_Ready_toUse/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                // window.location.href =
                //   "/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse";
                GetDiameter();
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];
 
  for (var index = 0; index < all_steel_wire.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["Diameter_No"] = all_steel_wire[index].diameter_no;
    rowItem["Diameter_Name"] = all_steel_wire[index].diameter;
    rowItem["Amount"] = all_steel_wire[index].amount;;
    rowItem["weight"] = all_steel_wire[index].weight;
 
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
             href={"/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse/read/"+ all_steel_wire[index].id}
            // key={all_steel_wire[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
         <div className="col-4">
           <a
              href={"/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse/edit/"+ all_steel_wire[index].id}
            //  key={all_steel_wire[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            //  key={all_steel_wire[index].id}
            className=" btn btn-xs "
             onClick={deldata.bind(this,all_steel_wire[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Diameter No.",
        field: "Diameter_No",
        sort: "asc",
        width: 50,
      },
      {
        label: "Diameter_Name",
        field: "Diameter_Name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "Amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "weight (kg)",
        field: "weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ลวดพร้อมใช้{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">ลวดพร้อมใช้</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_steel_wire.diameter_no}
                    onChange={(e) => {
                      setFiller_steel_wire({
                        ...filler_steel_wire,
                        diameter_no: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">DiameterNo.</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_steel_wire.diameter_name}
                    onChange={(e) => {
                      setFiller_steel_wire({
                        ...filler_steel_wire,
                        diameter_name: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Diameter Name</label>{" "}
                </div>
              </div>
              <div className="col-3 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={GetDiameter}
                    // onClick={() => {
                    //     GetDiameter();
                    // }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-3 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
              <div className="col-3 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">ลวดพร้อมใช้</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
   {/*   <Footter/> */}
     </div>
     
  );
}

export default Wire_Ready_toUse;
