import React, { useState, useEffect, useRef } from "react";
import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import Footter from "../component/Footter";
import "../assets/css/Content.css";
import "../assets/css/LandingPage.css";
import Oem from "../component/OEM";
import CustomerService from "../component/CustomerService";
import {
  getUser,
  getToken,
  setOemlist,
  setOemlist_,
  getOem,
  removeUserSession,
  removeOem,
} from "../Utils/Common";
import Configs from "../config";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import { logoTTT, IconFileNoti } from "../routes/imgRoute/imgUrl";
import PMRP_NoImage from "../assets/img/Landing_Page/PMRP-Noimage.png";
import {
  Icon_factory,
  factory,
  userdefault_img,
  Icon_contact,
  Icon_Choose,
} from "../routes/imgRoute/imgUrl";
function Langingpage(params) {
  const [dataRuleOfCompany, setDataRuleOfCompany] = useState({
    is_checked: false,
    have_mune_fav: false,
  });
  const [user, setUser] = useState(getUser());
  const [user_detail, setUser_detail] = useState({});
  const [company, setCompany] = useState({});
  const [oem, setOem] = useState([]);
  const [favorite, setFavorite] = useState([]);
  const [user_oem, setUser_oem] = useState([]);
  var config_user = {
    method: "get",
    url: Configs.API_URL + "/company/findUserById?id=" + user.fup,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + user.com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var permission_oem = {
    method: "get",
    url: Configs.API_URL + "/company/viewOemByUserId",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_oem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + user.com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
  };
  const oemp = getOem();
  const comp = getUser().com;
  useEffect(async () => {
    if (comp === "363e0c07-2c35-4cc2-8fa2-d411914a592c") {
      let checkNoti = window.sessionStorage.getItem("Noti");
      if (oemp && (checkNoti == null || checkNoti == undefined)) {
        window.sessionStorage.setItem("Noti", true);
        let check_notification = [];
        let temp = {
          oem_id: getOem(),
          company_id: await getUser().com,
        };
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/certificate/checkNotification",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async (response) => {
            check_notification = response.data;
          })
          .catch(async (error) => {
            console.log(error);
          });

        let swal = "";

        check_notification.cer.forEach((e) => {
          swal += `Certificate : ${e.cer_name} จะหมดอายุในวันที่ ${moment(
            e.cer_exp_date
          ).format("DD/MM/yyyy")}</br>`;
        });

        check_notification.lic.forEach((e) => {
          swal += `License : ${e.license_name} จะหมดอายุในวันที่ ${moment(
            e.license_exp_date
          ).format("DD/MM/yyyy")}</br>`;
        });

        if (swal !== "") {
          Swal.fire({
            title: "แจ้งเตือนวันหมดอายุ Certificate/License",
            html: swal,
            icon: "warning",
            // imageUrl: logoTTT.imgs,
            // imageWidth: 50,
            // imageHeight: 50,
            // imageAlt: "Custom image",
          }).then((result) => {
            // removeUserSession();
            // removeOem();
            // window.location.href = "/login";
          });
        }
      }
    }
  }, [oemp]);
  useEffect(async () => {
    var balance_day = getUser().balance_time;
    if (balance_day < 0) {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "หมดอายุการใช้งาน",
        imageUrl: logoTTT.imgs,
        imageWidth: 50,
        imageHeight: 50,
        imageAlt: "Custom image",
      }).then((result) => {
        removeUserSession();
        removeOem();
        window.location.href = "/login";
      });
    }
    axios(config_user)
      .then(function (response) {
        setUser_detail(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_com)
      .then(function (response) {
        const data = response.data;
        setCompany(data);
        data.oem.sort((a, b) => a.sort - b.sort);
        setOem(data.oem);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(permission_oem)
      .then(function (response) {
        //console.log(response.data);
        setUser_oem(response.data);
        if (response.data.length === 1) {
          setOemlist(response.data[0].oem_id);

          //  href="/";
          //button.form.submit();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    /*  axios(config_oem)
      .then(function (response) {
        console.log(oem)
        setOem(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
 */
    /* console.log(getOem()); */
    let checkNotiNews = window.sessionStorage.getItem("Noti_RulesTTT");
    if (!checkNotiNews && comp === "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
      await checkHaveNewsTTTNotReadByUserId();
    } else {
      setDataRuleOfCompany({
        ...dataRuleOfCompany,
        is_checked: true
      });
      dataRuleOfCompany.is_checked = true;
    }

    setTimeout(() => {
      const user = sessionStorage.getItem('user');
      if (user) {
        const obj_user = JSON.parse(user);
        if (obj_user.have_menu_favorite) {
          setDataRuleOfCompany({
            ...dataRuleOfCompany,
            have_mune_fav: true
          })
          dataRuleOfCompany.have_mune_fav = true;
          getMenuFavorite();
        }
      }
    }, 300)
  }, []);
  //console.log(company.company_name)
  if (
    user_oem.length === 1 &&
    company.company_name !== undefined &&
    company.company_name === "ธนาทรัพย์ ค้าไม้"
  ) {
    window.location.href = "/Warehouse_Management/F_G_In_Out";
  }
  /* if(user_oem.length ===  1 && getOem() === null && getOem() ===  "480479b6-dcd3-4a4f-89a7-95c5e8f275bc"){
  window.location.href="/Warehouse_Management/F_G_In_Out";
} */
  // const favoritePage = favorite.filter((row) => row.favorite_fav)[0];
  // if (favoritePage) {
  //   window.location.href = favoritePage.path;
  // }

  async function checkHaveNewsTTTNotReadByUserId() {
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/checkHaveNewsTTTNotReadByUserId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(async function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          window.sessionStorage.setItem("Noti_RulesTTT", true);
          if (response.data.data.length > 0) {
            Swal.fire({
              title: `ประกาศแจ้งเตือนเอกสารสำคัญ <span style="color: #E42726;">${response.data.data.length}</span> ฉบับ`,
              // text: "หมดอายุการใช้งาน",
              html: `<div>
                  <p>กรุณาดำเนินการอ่านและรับทราบเอกสารฉบับใหม่ภายในระยะเวลา 15 วัน</p>
                  <span style="color: #E42726;">** หากท่านไม่ได้ดำเนินการภายในระยะเวลาที่กำหนด ท่านอาจได้รับการพิจารณาตักเตือนตามระเบียบของบริษัท **</span>
                </div>`
              ,
              imageUrl: IconFileNoti.imgs,
              imageWidth: 150,
              imageHeight: 150,
              imageAlt: "Custom image",
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'ดูเอกสาร',
              confirmButtonColor: '#3579F6',
              cancelButtonText: 'ปิด',
              cancelButtonColor: '#E42726',
              width: 700
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/Human_Resource/company_regulations/Save_message';
              } else {
                setDataRuleOfCompany({
                  ...dataRuleOfCompany,
                  is_checked: true
                });
                dataRuleOfCompany.is_checked = true;
              }
            });
          } else {
            setDataRuleOfCompany({
              ...dataRuleOfCompany,
              is_checked: true
            });
            dataRuleOfCompany.is_checked = true;
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  const getMenuFavorite = async (user_id) => {
    const oem_id = getOem();
    if (!oem_id) return;
    axios({
      method: "post",
      url: Configs.API_URL + "/api/favorite/menuFavorite",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(async function (response) {
        const sorted = response.data.sort((a, b) => a.favorite_sort - b.favorite_sort);
        setFavorite(sorted);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  useEffect(() => {
    if (user_oem.length > 0 && favorite.length > 0 && dataRuleOfCompany.is_checked) {
      let checkNotiFav = window.sessionStorage.getItem("Noti_Favorites");
      favorite
        .filter((row) => row.favorite_is_fav)
        .forEach((row) => {
          user_oem.forEach((a) => {
            if (a.user_id === row.favorite_user_id && !checkNotiFav) {
              window.location.href = row.path.split("/").reverse().join("/");
              window.sessionStorage.setItem("Noti_Favorites", true);
            }
          });
        });
    }
  }, [user_oem, favorite, dataRuleOfCompany.is_checked]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>
                Welcome {user.sub}
                {/* {user_detail.name}  Khun Teewin*/}
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/Welcome">Home</a>
                </li>
                <li className="breadcrumb-item active">OEM</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              {/* Profile Image */}
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title blank-space">About us</h3>{" "}
                  <img
                    alt="..."
                    className="img-fluid img-icon "
                    src={Icon_factory.imgs}
                  />
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <img
                    alt="..."
                    className="img-fluid about-img"
                    src={
                      company.logo_path !== null &&
                        company.logo_path !== "" &&
                        company.logo_path !== undefined
                        ? Configs.API_URL_IMG + company.logo_path
                        : userdefault_img.imgs
                    }
                  />

                  <p>
                    {/*  บริษัทที่ให้บริการด้านไอที โซลูชั่น
                      แนะนำแนวทางการประยุกต์ใช้ระบบสารสนเทศเพื่อการบริหารจัดการ
                      และการฝึกอบรม เน้นการพัฒนากระบวนการแบบก้าวกระโดด
                      เพิ่มประสิทธิภาพการทำงาน
                      ลดระยะเวลาการดำเนินการอย่างมีนัยสำคัญ รวมทั้งแก้ปัญหา
                      Human error
                      เพื่อให้ธุรกิจของลูกค้าทุกท่านบรรลุเป้าหมายอย่างรวดเร็ว
                      และมั่นคงแข็งแรงยั่งยืน */}
                    {company.company_detail}
                  </p>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
              {/* About Me Box */}
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title blank-space">
                    Contact us {"    "}
                  </h3>{" "}
                  <img
                    alt="..."
                    className="img-fluid img-icon"
                    src={Icon_contact.imgs}
                  />
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <p>
                    บริษัท ทีทีที บราเธอร์ส จำกัด 852/8 ถนนหลวงแพ่ง แขวงทับยาว
                    เขตลาดกระบัง กรุงเทพฯ 10520 (สำนักงานใหญ่)
                  </p>

                  <CustomerService />
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
            <div className="col-md-8">
              <div className="card">
                <div className="card-header ">
                  <h3 className="card-title blank-space">
                    Please Select OEM {"    "}
                  </h3>{" "}
                  <img
                    alt="..."
                    className="img-fluid img-icon"
                    src={Icon_Choose.imgs}
                  />
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="tab-content">
                    <div className="row">
                      {oem.map((b) => {
                        return (
                          <>
                            {user_oem.map((a) => {
                              if (a.oem_id === b.id) {
                                //console.log(b)
                                return (
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div key={b.id}>
                                      <a
                                        href="/"
                                        onClick={() => {
                                          setOemlist(b.id);
                                          setOemlist_(b.name);
                                        }}
                                      >
                                        <Oem oem={b} />
                                      </a>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </>
                        );
                      })}

                      {/* { oem.map((el) => { 
                      //console.log(oem);
                      return (
                    <div key={el.id}>
                       <a href="/" onClick={()=>{setOemlist(el.id)}}>
                         
                      <Oem  oem={el} />
                     </a>
                    </div> 
                    ); }) } */}
                    </div>
                  </div>
                  {/* /.tab-content */}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.nav-tabs-custom */}
              <div className="card" hidden={!dataRuleOfCompany.have_mune_fav}>
                <div className="card-header ">
                  <h3 className="card-title blank-space">
                    Menu Favorite {"    "}
                    <i
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#F97762",
                        borderRadius: "100%",
                        width: "20px",
                        height: "20px",
                        color: "white",
                        fontSize: "13px",
                        display: "inline-block",
                        textAlign: "center",
                        lineHeight: "20px",
                        verticalAlign: "middle",
                        fontWeight: "100",
                      }}
                      className="ri-star-line"
                    ></i>
                  </h3>{" "}
                  {/* <img
                    alt="..."
                    className="img-fluid img-icon"
                    src={Icon_Choose.imgs}
                  /> */}

                </div>
                {/* /.card-header */}
                <div
                  className="card-body"
                  style={favorite.length > 6 ? {
                    maxHeight: "440px",
                    overflowY: "scroll",
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none'
                  } : {}}
                >
                  <div className="tab-content">
                    <div className="row">
                      {favorite.map((b) => {
                        return (
                          <>
                            <div className="col-12 col-md-6 col-xl-4">
                              <div key={b.favorite_user_id}>
                                <a
                                  href={b.path.split("/").reverse().join("/")}
                                  // onClick={() => {
                                  //   setOemlist(b.favorite_user_id);
                                  //   setOemlist_(b.display_name);
                                  // }}
                                >
                                  <div className="col-12" style={{ textAlign: "center" }}>

                                    <img
                                      alt="..."
                                      className="img-fluid oem-1"
                                      src={
                                        b.image_path !== null &&
                                          b.image_path !== "" &&
                                          b.image_path !== undefined
                                          ? (b.image_path.startsWith('data:image')
                                            ? b.image_path
                                            : Configs.API_URL_IMG + b.image_path)
                                          : PMRP_NoImage
                                      }
                                    />
                                    <h2></h2>

                                    <h3>{b.display_name}</h3>

                                  </div>
                                </a>
                              </div>
                            </div>
                            {/* {user_oem.map((a) => {
                              if (a.user_id === b.favorite_user_id) {
                                // console.log(b)
                                return (
                                  
                                );
                              }
                            })} */}
                          </>
                        );
                      })}
                      {/* { oem.map((el) => { 
                      //console.log(oem);
                      return (
                    <div key={el.id}>
                       <a href="/" onClick={()=>{setOemlist(el.id)}}>
                         
                      <Oem  oem={el} />
                     </a>
                    </div> 
                    ); }) } */}
                    </div>
                  </div>
                  {/* /.tab-content */}
                </div>
                {/* /.card-body */}
              </div>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    </div>
  );
}
export default Langingpage;
