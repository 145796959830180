import React, { Children, Component, useEffect, useState } from "react";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import Resizer from "react-image-file-resizer";
import CheckboxTree from "react-checkbox-tree";
import NumberFormat from "react-number-format";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";

function ManageSupplierAdd(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [supplier, setsupplier] = useState({
    company_id: getUser().com,
    name: "",
    is_use: "",
    sort: "",
    detail: "",
    contact_tel: "",
    contact_fax: "",
    contact_name: "",
    supplier_email: "",
    line_id: "",
    supplier_email_person: "",
    remark: "",
    tel: "",
    full_name: "",
    address: "",
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  useEffect(() => {
    GetSupp_view();
  }, []);

  const GetSupp_view = async () => {
    if (mode.mode === "read" || mode.mode === "edit") {
      console.log("id",id);
      axios({
        method: "get",
        url: Configs.API_URL_incentive + "/api/manageSupplier/GetSupplierByID/" + id,
        headers: {
          Authorization:  "Barer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log("supplier",response.data.supplier);
          setsupplier({
            company_id: response.data.supplier.sup_company_id,
            name: response.data.supplier.sup_abbr_name || "",
            is_use: response.data.supplier.sup_is_use,

            detail: response.data.supplier.sup_detail || "",

            full_name: response.data.supplier.sup_name || "",
          /*   address: response.data.supplier.sup_address || "",

            tel: response.data.supplier.sup_tel || "", */

          contact_tel: response.data.supplier.sup_contact_tel,
          contact_fax: response.data.supplier.sup_contact_fax,
          contact_name: response.data.supplier.sup_contact_name,
          supplier_email: response.data.supplier.sup_email,
          line_id: response.data.supplier.sup_line_id,
          supplier_email_person: response.data.supplier.sup_email_person,
          remark: response.data.supplier.sup_remark,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function saveOption(params) {
    console.log(mode.mode , id);
    const error_list = [];
    if (supplier.name.trim() == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Supplier Name].",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        const temp = {
          sup_company_id: supplier.company_id,
          sup_abbr_name: supplier.name === null? supplier.name: supplier.name.trim(),
          sup_detail:supplier.detail === null? supplier.detail: supplier.detail.trim(),
          sup_contact_tel: supplier.contact_tel,
           sup_contact_fax:supplier.contact_fax === null? supplier.contact_fax:supplier.contact_fax.trim(),
           sup_contact_name: supplier.contact_name === null? supplier.contact_name:supplier.contact_name.trim(),
           sup_email: supplier.supplier_email=== null?supplier.supplier_email: supplier.supplier_email.trim(),
           sup_email_person: supplier.supplier_email_person === null?supplier.supplier_email_person: supplier.supplier_email_person.trim(),
           sup_remark: supplier.remark,
            sup_name: supplier.full_name === null? supplier.full_name:supplier.full_name.trim(),
            sup_line_id: supplier.line_id === null?supplier.line_id: supplier.line_id.trim(),
        /*   sup_address: supplier.address.trim(),
          sup_tel: supplier.tel, */

        };

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/manageSupplier/AddSupplier",
            headers: {
              Authorization: "Barer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Human_Resource/intensive_TTT/Manage_Supplier";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error.response.data.error[0].errorDis);
              Swal.fire({
                icon: "warning",
                title: error.response.data.error[0].errorDis,
                text: "",
              });
            });
        });
      } else if (mode.mode === "edit") {
        const temp = {
          supplier_id: id,
          sup_company_id: supplier.company_id,
          sup_abbr_name: supplier.name === null? supplier.name: supplier.name.trim(),
          sup_detail:supplier.detail === null? supplier.detail: supplier.detail.trim(),
          sup_contact_tel: supplier.contact_tel,
           sup_contact_fax:supplier.contact_fax === null? supplier.contact_fax:supplier.contact_fax.trim(),
           sup_contact_name: supplier.contact_name === null? supplier.contact_name:supplier.contact_name.trim(),
           sup_email: supplier.supplier_email=== null?supplier.supplier_email: supplier.supplier_email.trim(),
           sup_email_person: supplier.supplier_email_person === null?supplier.supplier_email_person: supplier.supplier_email_person.trim(),
           sup_remark: supplier.remark,
            sup_name: supplier.full_name === null? supplier.full_name:supplier.full_name.trim(),
            sup_line_id: supplier.line_id === null?supplier.line_id: supplier.line_id.trim(),
        };
        console.log("supplier",supplier);
        console.log(temp);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/manageSupplier/EditSupplier",
            headers: {
              Authorization: "Barer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Human_Resource/intensive_TTT/Manage_Supplier";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error.response.data.error[0].errorDis);
              Swal.fire({
                icon: "warning",
                title: error.response.data.error[0].errorDis,
                text: "",
              });
            });
        });
      }
    }
  }

  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Human_Resource/intensive_TTT/Manage_Supplier";
      }
    });
  }
  return (
    <div className="wrapper">
      {/*  <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Supplier {pageMode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Supplier</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    if
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Supplier {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <h3 className="mt-5-head">Supplier Detail</h3>
              <div className="tab-custom-content"></div>
              <br />
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.name}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Name
                      {supplier.name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.full_name}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          full_name: e.target.value,
                        });
                      }}
                    />
                    <label>Supplier Full Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={supplier.detail}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          detail: e.target.value,
                        });
                      }}
                    />
                    <label>Supplier Detail</label>
                  </div>
                </div>
              </div>
            {/*   <div className="row">
                <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={supplier.address}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          address: e.target.value,
                        });
                      }}
                    />
                    <label>Supplier Address</label>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.supplier_email}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          supplier_email: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Email
                    
                    </label>
                  </div>
                </div>
                  <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.line_id}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          line_id: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Line ID
                     
                    </label>
                  </div>
                </div>
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-####"}
                      value={supplier.tel}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          tel: e.target.value,
                        });
                      }}
                    />
                     <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,.
                          contact_fax: e.target.value,
                        });
                      }}
                    />
                    <label>Tel</label>
                  </div>
                </div> */}
                {/* <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.line}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          line: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_fax: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Line Id
               
                    </label>
                  </div>
                </div> */}
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-#######"}
                      value={supplier.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_fax: e.target.value,
                        });
                      }}
                    />
                   {/*  <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_fax: e.target.value,
                        });
                      }}
                    /> */}
                    <label>
                      Contact Fax
               
                    </label>
                  </div>
                </div>
              </div>

              <h3 className="mt-5 mt-5-head">Contact Point</h3>
              <div className="tab-custom-content"></div>
              <br />
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.contact_name}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Contact Name
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.supplier_email_person}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          supplier_email_person: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Supplier Person Email
                      {/* {supplier.supplier_email_person.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                
              </div>
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={supplier.contact_tel}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          contact_tel: e.target.value,
                        });
                      }}
                    />

                    <label>
                      Contact Tel
                      {/* {supplier.contact_tel === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={supplier.remark}
                      disabled={disable}
                      onChange={(e) => {
                        setsupplier({
                          ...supplier,
                          remark: e.target.value,
                        });
                      }}
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*      <Footter/> */}
    </div>
  );
}

export default ManageSupplierAdd;
