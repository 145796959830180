import React, { Component, useEffect, useState,useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from  'sort-json-array';
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import QRCode from 'qrcode.react';
import Configs from "../../config";
import { getOem, getToken } from "../../Utils/Common";
import { userdefault_img,logoTTT } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import Select from "react-select";
function CHOUPInOut(params) {

  const [cate_option, setcate_option] = useState([]);
  const [osl_option, setosl_option] = useState([]);
  const select_cate = useRef();
  const select_osl = useRef();


  const [product_data, setProduct_data] = useState([]);
  const [model, setModel] = useState([]);
  const [modelosl, setModelosl] = useState([]);
  const [oem_id, setOem_id] = useState(getOem());
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    osl_id: "",
    osl_name: "",
    oem_id:getOem()
  });
  //console.log(filler_product);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = `js/Product`;
    document.body.appendChild(script);
  }, []);


  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_modelosl = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel_Osl?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const Getamount = async () => {
    if (
      amount.amount !== null &&
      amount.amount !== undefined &&
      amount.amount !== ""
    ) {
      if (amount.edit_type !== null) {
        const temp_amount = {
          id: amount.id,
          edit_type: amount.edit_type,
          amount: Number(amount.amount.replace(/,/g, "")),
          osl_id: filler_product.osl_id,
          
        };
        //console.log(temp_amount);
        axios({
          method: "post",
          url: Configs.API_URL + "/product/amountDIPInOutOsl",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_amount,
        })
          .then(async (response) => {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 300,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                
              setamount({
                id:  amount.id,
                edit_type: null,
                amount: "",
              });
              amount.id =  amount.id;
              amount.edit_type = null;
              amount.amount = "";
            }).then(()=>{
              Gettable();
              let real_id =product_data.filter((x)=>{
                return (x.id === amount.id);
              })
              console.log(real_id);       
              setamount({
                ...amount

              });
               setamount({
                ...amount,
                total: real_id[0].dip_amount
              });
              Gettable();
              //console.log(amount.id)
              axios({
                method: "post",
                url: Configs.API_URL + "/product/choup/filternormel",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: filler_product,
              })
                .then(function (response) {
                  console.log(response.data);
                  let set_total_view = response.data.filter((element)=>{
                    return element.id === amount.id;
                  })
                 // console.log(set_total_view)
                 // console.log(set_total_view[set_total_view.length - 1].logistic_amount)
                  setamount({
                    ...amount,
                    total: set_total_view[set_total_view.length - 1].dip_amount || 0,
                    amount:"",
                  });
                  
                })
                .catch(function (error) {
                  console.log(error);
                });
              // window.location.href =
              // "/Warehouse_Management/WIP_IN_OUT";


              })

              
            });
            /* console.log(response.data); */
          })
          .catch(async (error) => {

            Swal.fire("Over Stock!", "Check your amount", "warning")
        .then((resault)=> {
          // window.location.href =
          //       "/Warehouse_Management/CHOUP_IN_OUT";
        });
            console.log(error);
          });
      } else {
        Swal.fire("check in bound , out bound", "bound can't null", "warning");
      }
    } else {
      Swal.fire("check amount", "amount can't null", "warning");
    }
  };

  useEffect(() => {
    Gettable();
    Getmodel();
    Getmodelosl();
    Gettablenormel();
  }, []);

  //console.log(filler_product.osl_id)

  const Getmodel = async () => {
    axios(config_model)
      .then(async (response) => {
        // console.log(response.data);
        setModel(response.data);

        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setcate_option(options);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };


  const Getmodelosl = async () => {
    axios(config_modelosl)
      .then(async (response) => {
        // console.log(response.data);
        setModelosl(response.data);

        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.oslno;
          options.push(element);
        });
        setosl_option(options);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

   const Gettablenormel = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/choup/filternormel",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product,
      
    })
    
      .then(async (response) => {
       let sorted = response.data.sort((a, b) => b.dip_amount-  a.dip_amount);
        await setProduct_data(sorted);
        
          
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/choup/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product,
      
    })
      .then(async (response) => {
        //console.log("xdsfdsgfd",response.data);
       let sorted = response.data.sort((a, b) => b.dip_amount-  a.dip_amount);
         setProduct_data(sorted);
        
          /* console.log(test); */
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  //console.log(modelosl);

  function form(no, name, id, amount,edit_type,osl_id) {

    const result = modelosl.filter((member) => {
      return member.id === osl_id
    })



    setmessage({ ...message, name: name, no: no, oslno: result[0].oslno});
    setamount({
      ...amount,
      amount: "",
      total: amount,
      id: id,
      lot_no: "",
      edit_type:edit_type,
    });

  }

  const [amount, setamount] = useState({
    id: "",
    edit_type: null,
    amount: "",
    osl_id:filler_product.osl_id,
  });


  const [message, setmessage] = useState({
    num: "",
    name: "",
    oslno:"",
  });
  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product/add";
  }
  function handleChange(value) {
    Gettable();
    /* console.log(filler_product); */
  }

  const clearfillter = async () => {
    await setFiller_product({...filler_product,product_model_id: "", name: "", no: "" ,osl_id:"" });
    filler_product.product_model_id = "";
    filler_product.name = "";
    filler_product.no = "";
    filler_product.osl_id = "";
    Gettablenormel();
    window.location.href =
      "/Warehouse_Management/CHOUP_INT_OUT";
  };
 /*  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail/add";
  } */

  /* const yai = modelosl.filter((member) => {
    return member.id.length > 0 ;
  });
  console.log(yai)
 */

  

  // /////////////////////////////////////////
  const rowsData = [];
  for (var index = 0; index < product_data.length; index++) {
    const rowItem = {};

    for (let indexin = 0; indexin < model.length; indexin++) {
      if (
        model[indexin].id == product_data[index].product_model_id &&
        product_data[index].product_model_id !== undefined
      ) {
        rowItem["product_model_id"] = "" + model[indexin].name;
      }
    }

    if (product_data[index].dip_amount !== null) {
      rowItem["amount"] = (
        <NumberFormat
          value={product_data[index].dip_amount}
          displayType={"text"}
          thousandSeparator={true}
        />
      );
    } else {
      rowItem["amount"] = 0;
    }

    {
      /* <a className=" btn btn-xs " onClick={form.bind(this, product_data[index].no, product_data[index].name,product_data[index].id)}> */
    }
    rowItem["num"] = index + 1;
    rowItem["no"] = product_data[index].no;
    rowItem["name"] = product_data[index].name;
    rowItem["qty_unit"] = product_data[index].qty_unit;
    // rowItem["amount"] = product_data[index].wip_amount;
    // rowItem["weight"] = product_data[index].weight;
    rowItem["min_prod"] = <NumberFormat value={product_data[index].min_product ===null?0:product_data[index].min_product } displayType={'text'} thousandSeparator={true} />
    
    rowItem["image"] = /* product_data[index].image_path */(
      <img
        alt="..."
        className="img-fluid rounded "
        src={product_data[index].imaget_path !== null ? Configs.API_URL_IMG + product_data[index].imaget_path :userdefault_img.imgs}
        style={{ width: "50px", height: "50px" }}
      />
    );
    rowItem["qr"] = (
      <div style={{textAlign:"center"}}>
      <QRCode
      value={product_data[index].no}
      size={50}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"svg"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 15,
              width: 15,
              x: null,
              y: null,
              excavate: true,
            }
      }
    /></div>
    );


    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          {filler_product.osl_id != "" ? (
          <a
            className=" btn btn-xs "
            style={{width: "100px"}}
            data-toggle="modal"
            data-target="#modal-xl"
            
            onClick={form.bind(
              this,
              product_data[index].no,
              product_data[index].name,
              product_data[index].id,
              product_data[index].dip_amount,
              amount.edit_type,
              filler_product.osl_id,


            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
          ) : (
            <a
            className=" btn btn-xs "
            style={{width: "110px"}}
            //data-toggle="modal"
            //data-target="#modal-xl"
            
          >
            {" "}
            {/* <i class="fas fa-pencil-alt"></i> */}{""}
            <h6>    Please Select OLS !</h6>
            
          </a>
          )}
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  // console.log(rowsData);
  //////////////////////////

  


  const data = {
    columns: [
      {
        label: "No_",
        field: "num",
        sort: "asc",
        width: 50,
      },{
        label: "Part_No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
       label: "QR",
       field: "qr",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "Part_Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_Model",
        field: "product_model_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qty_Unit",
        field: "qty_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min_Stock",
        field: "min_prod",
        sort: "asc",
        width: 50,
      },
      {
        label: "DIP_Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      ,
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }
  function showmessage() {
      Swal.fire({
        icon: "info",
        title: "Waiting for connect service API QR",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
  
    }
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="wipinout" submenu="none" /> */}
      <div className="content-wrapper">
       
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ชุบ IN/OUT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">ชุบ IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.no.trim()}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Part No.</label>{" "}
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.name.trim()}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Part Name</label>{" "}
                </div>
              </div>
              <div className="col-md-2"style = {{"z-index":"98"}}>
                <div className="form-group ">


                <Select
                          options={cate_option}
                          ref = {select_cate}
                          defaultValue={filler_product.product_model_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setFiller_product({
                              ...filler_product,
                              product_model_id: e.value || "",
                            });
                            }
                           
                          }}
                        />  




                  {/* <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select> */}
                  <label htmlFor="">Category/Model</label>
                </div>
                {/*   <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
              </div>
              <div className="col-md-2"style = {{"z-index":"98"}}>
                <div className="form-group ">

                <Select
                          options={osl_option}
                          ref = {select_osl}
                          defaultValue={filler_product.osl_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setFiller_product({
                              ...filler_product,
                              osl_id: e.value || "",
                            });
                            }
                           
                          }}
                        />  


                  {/* <select
                    className="custom-select select2 form-control"
                    value={filler_product.osl_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        osl_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {modelosl.map((el) => {
                      return <option value={el.id}>{el.oslno}</option>;
                    })}
                  </select> */}
                  <label htmlFor="">OSL</label>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">

             {filler_product.osl_id == "" ? ( 

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      Gettablenormel();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
               ) : ( 
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      Gettable();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
               )} 





              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearfillter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

              {/*
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary" onClick={showmessage}>
                    QR Code
                  </button>
                </div>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">ชุบ IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-head-fixed"
                  sortable = {false}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
       <div className="modal fade" id="modal-xl">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
            <h1 className="modal-title">Edit DIP Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={
                        { backgroundColor: "#F0FFFF", border: "1px solid #ddd", padding: "10px", width: "100" }}
                      value={amount.total}
                      displayType={"text"}
                      thousandSeparator={true}
                    />}

                  &nbsp;&nbsp;&nbsp; items </h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-4">
                  <h3>Product No : {message.no}</h3>
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={amount.edit_type !=true ? false:true}
                    value={true}
                    onChange={(e) => {
                      setamount({
                        ...amount,
                        edit_type: e.target.value === "false" ? false : true,
                      });
                    }}
                  />

                  <label htmlFor="inbound">
                    <h4>  In bound</h4>
                  </label>
                </div>

                <div className="col-4">
                  <h3>Product Name : {message.name}</h3>

                  <div className="row"></div>

                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    checked={amount.edit_type !=false ? false:true}
                    value={false}
                    onChange={(e) => {
                      setamount({
                        ...amount,
                        edit_type: e.target.value === "false" ? false : true,
                      });
                    }}
                  />
                  <label htmlFor="outbound">
                    <h4>  Out bound</h4>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  <div className="form-group ">
                    <NumberFormat
                    format="################"
                      thousandSeparator={true}
                      type="text"
                      className="form-control"
                      value={amount.amount}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          amount: e.target.value,
                        });
                      }}
                      required
                    />
                    <label htmlFor="">Amount In</label>{" "}
                    <h3>OSL : {message.oslno}</h3>
                  </div>
                </div>

                <div className="col-4"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
            
              <button
                type="button"
                className="btn btn-primary"
                onClick={Getamount}
                //data-dismiss="modal"
                //aria-label="Close"
              >
                Save Changes
              </button>
            
            </div>
          </div>
        </div>
      </div>
      </div>
     
                     {/*  <Footter/> */}
                      </div>
  );
}
export default CHOUPInOut;
