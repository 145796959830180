import React, { Component, useEffect, useState,useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Select from "react-select";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature, getAcademy, getComConfig} from "../../Utils/Common";
import TableLEK from "../../component/tableLEKTTT/table";

function Supplier(params) {
  const [test,settest] = useState({
    test:'',
  });
  const [headTable, setheadTable] = useState(
    [
      
]
);
const [valueData, setvalueData] = useState([]);
const [countPage, setCountPage] = useState(1);
const [currentPage,setCurrentPage] = useState(1);
const [countData, setCountData] = useState(0);
const [entries, setEntries] = useState(10);
const [search, setSearch] = useState(0);
  const [supplier, setSupplier] = useState([]);
  const [company, setCompany] = useState([]);
  const [oemfix, setoemfix] = useState();
  const [feature, setfeature] = useState([]);
  // const [disable, setdisable] = useState(null);
  const [color_option, setcolor_option] = useState([]);
  const [filler_supplier, setFiller_supplier] = useState({
    name: "",
    full_name: "",
    company_id: getUser().com,
    all_search:''
  });
  const options = [];
  const selectColor_model = useRef();
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetSupplier = async (page,size,search) => {
    //console.log(filler_supplier);
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if(search){
      setSearch(1);
  }else{
      setSearch(0);
  }
  let temp = {
    name: (filler_supplier.name || '').trim(),
    full_name: filler_supplier.full_name,
    company_id: getUser().com,
    page:page || 1,
    size:size || 10,
    all_search: (filler_supplier.all_search || "").trim()
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/supplier/filter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log(response.data);
        setSupplier(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({...test,test:''})
        test.test = '';
        Swal.close();
    /*     response.data.forEach(el => {
          var element = {};
          element["value"] = el.full_name;
          element["label"] = el.full_name;
          options.push(element);
        });
        setcolor_option(options); */
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetSupplierOption = async () => {
    //console.log(filler_supplier);
  
  let temp = {
    name: /* filler_supplier.name */ '',
    full_name: /* filler_supplier.full_name */ '',
    company_id: getUser().com,
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/supplier/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log(response.data);

        response.data.forEach(el => {
          var element = {};
          element["value"] = el.full_name;
          element["label"] = el.full_name;
          options.push(element);
        });
        setcolor_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    let hardtabel = [];
    hardtabel.push(
    
      {
        label: "Supplier",
        field: "Sup_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Full Name",
        field: "full_name",
        sort: "asc",
        width: 50,
      },
     )
    
      /* console.log(ComConfig.com_name) */
      if (getOem() != "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
        hardtabel.push(
          {
            label: "Supplier Detail",
            field: "Sup_detail",
            sort: "asc",
            width: 50,
          },
          {
            label: "Line ID",
            field: "line_id",
            sort: "asc",
            width: 50,
          },
         )
      }
      hardtabel.push(
       
      
       
        {
          label: "Contact Name",
          field: "Contact_name",
          sort: "asc",
          width: 50,
        },
        {
          label: "Tel",
          field: "Sup_contact_tel",
          sort: "asc",
          width: 50,
        },
        
        {
          label: "FAX",
          field: "Sup_contact_fax",
          sort: "asc",
          width: 50,
        },
        {
          label: "Email",
          field: "Sup_email",
          sort: "asc",
          width: 50,
        },
        /* {
          label: "Sup_sup_mail",
          field: "Sup_sup_mail",
          sort: "asc",
          width: 50,
        }, */
       
        {
          label: "Management",
          field: "mgt",
          sort: "asc",
          width: 50,
        },
       )
       setheadTable(hardtabel);
    GetSupplier(1,10,'search');
    GetSupplierOption();
    GetCompany();
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
   
    }
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Supplier";
    });
    setfeature(feature_data);
  }, []);

  const clearFilter = async () => {
    await setFiller_supplier({
      ...filler_supplier,
      name: "",
      full_name:"",
      all_search:''
    });

    filler_supplier.name = "";
    filler_supplier.full_name = "";
    filler_supplier.all_search = "";
    selectColor_model.current.select.clearValue();
    GetSupplier(1,10,'search');
  };
  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? '#87CEFA' : '#FFF',
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',

      };
    },

  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Supplier/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/supplier/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Supplier";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];

  for (var index = 0; index < supplier.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["Sup_name"] = supplier[index].name;
    rowItem["full_name"] = supplier[index].full_name;
    rowItem["Sup_detail"] = supplier[index].detail;
    rowItem["Contact_name"] = supplier[index].contact_name;
    rowItem["Sup_contact_tel"] = (<><a href={"tel:"+supplier[index].contact_tel}>{supplier[index].contact_tel}</a></>);
    rowItem["Sup_contact_fax"] = supplier[index].contact_fax;
    rowItem["Sup_email"] = supplier[index].supplier_email;
    rowItem["line_id"] = supplier[index].line_id;
    rowItem["Sup_sup_mail"] = supplier[index].supplier_email_person;

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Supplier/read/" +
              supplier[index].id
            }
            key={supplier[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Supplier/edit/" +
              supplier[index].id
            }
            key={supplier[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={supplier[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, supplier[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  /* console.log(feature); */
 var hardtabel = [];

 hardtabel.push(
  {
    label: "No",
    field: "no",
    sort: "asc",
    width: 50,
  },
  {
    label: "Supplier",
    field: "Sup_name",
    sort: "asc",
    width: 50,
  },
  {
    label: "Full Name",
    field: "full_name",
    sort: "asc",
    width: 50,
  },
 )

  /* console.log(ComConfig.com_name) */
  if (oemfix !=="thanasub") {
    hardtabel.push(
      {
        label: "Supplier Detail",
        field: "Sup_detail",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line ID",
        field: "line_id",
        sort: "asc",
        width: 50,
      },
     )
  }
  hardtabel.push(
   
  
   
    {
      label: "Contact Name",
      field: "Contact_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "Tel",
      field: "Sup_contact_tel",
      sort: "asc",
      width: 50,
    },
    
    {
      label: "FAX",
      field: "Sup_contact_fax",
      sort: "asc",
      width: 50,
    },
    {
      label: "Email",
      field: "Sup_email",
      sort: "asc",
      width: 50,
    },
    /* {
      label: "Sup_sup_mail",
      field: "Sup_sup_mail",
      sort: "asc",
      width: 50,
    }, */
   
    {
      label: "Management",
      field: "mgt",
      sort: "asc",
      width: 50,
    },
   )

  const data = {
    columns: hardtabel,
    rows: rowsData,
  };

  var fullName_feature = feature.filter((e) => {
    return e.feature === "Supplier Table Full Name";
  });

  var fax_feature = feature.filter((e) => {
    return e.feature === "Supplier Table Fax";
  });

  var email_feature = feature.filter((e) => {
    return e.feature === "Supplier Table Email";
  });

  if (fullName_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "full_name";
    });

    data.columns.splice(index, 1);
  }

  if (fax_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "Sup_contact_fax";
    });

    data.columns.splice(index, 1);
  }

  if (email_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "Sup_email";
    });

    data.columns.splice(index, 1);
  }

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Supplier{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Supplier</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    style={{height:'43px !important'}}
                    required="false"
                    value={filler_supplier.all_search}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        all_search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    style={{height:'43px !important'}}
                    className="form-control"
                    required="false"
                    value={filler_supplier.name}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Name</label>{" "}
                </div>
              </div>

              <div className="col-md-3">
                  <div class="form-group"style={{ "z-index": "97" }}>
                    <Select
                      options={color_option}
                      defaultValue={filler_supplier.full_name}
                      ref={selectColor_model}
                      onChange={(e) => {
                        if (e !== null) {
                        setFiller_supplier({
                          ...filler_supplier,
                          full_name: e.value,
                        });
                     
                      }

                      }}
                    />
                    <label htmlFor="">
                      Supplier Full Name
                      </label>
                  </div>
                
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetSupplier(1,10,'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Supplier</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " style = {{whiteSpace: 'nowrap',padding:'2%'}} /*  style={{ height: "100%" }} */
            >
                 <TableLEK       

                               
headTable = {headTable}
dataTable = {rowsData}
countPage = {countPage}
countData = {countData}
entries = {entries}
isSearch = {search}
callFnc ={GetSupplier}


/>
          {/*     <MDBDataTable
   
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              /> */}
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*  <Footter/> */}
    </div>
  );
}

export default Supplier;
