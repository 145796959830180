import React, { Component, useEffect, useState, useRef } from "react";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getComConfig,
} from "../../Utils/Common";
import DatePicker from "react-datepicker";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import Tooling_report from "../../../src/component/Report/Tooling_po_report";
import { useParams } from "react-router";
import { report_variable } from "../../Utils/Employee_history";
function Generate_po(props) {
  const { id } = useParams();

  const [page_mode, setPage_mode] = useState([]);
  const [inquiry, setinquiry] = useState([]);
  const [disable, setdisable] = useState(null);

  const [inquiryList, setinquiryList] = useState([]);
  const [inquiry_id, setinquiry_id] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    inquiry_id: "",
  });
  const [currentPrice, setcurrentPrice] = useState({
    id: "",
    inquiry_id: "",
    tooling_id: "",
    amount: "",
    price: "",
    tooling_supplier_id: "",
    is_select: "",
    po_id: "",
  });
  const [EditPo, setEditPo] = useState({
    po_no: "",
    issue_date: "",
    po_id: "",
  });
  const [test, settest] = useState({
    test: "",
  });

  const [company, setcompany] = useState();
  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  useEffect(() => {
    axios(config_com)
      .then(function (response) {
        /*  console.log(response.data); */
        setcompany(response.data);
      }).catch(function (error) {
        console.log(error);
      });
    // Getmaterial_order();
  }, []);

  const [dataGenarateReport, setDataGenarateReport] = useState({
    data: []
  });

  // async function generateReport(e) {
  //   const data = {
  //     toolingSupplier_id: e.supplier_id,
  //     tooling_poId: id,
  //   }
  //   axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL +
  //       "/tooling/getToolingBySupplierId",
  //     headers: {
  //       Authorization: getToken(),
  //       "Content-Type": "application/json",
  //       "X-TTT": Configs.API_TTT,
  //     },
  //     data: data,
  //   })
  //     .then(async function (response) {
  //       console.log('response.data', response.data);
  //       const temp = dataGenarateReport.data;
  //       if (temp) {
  //         setDataGenarateReport({ data: [] });
  //         temp.push(response.data);
  //         setDataGenarateReport({ data: temp });
  //         dataGenarateReport.data = temp;
  //         // console.log('dataGenarateReport.data', dataGenarateReport.data);
  //         // SendEmailAndFilePDF();
  //       } else {
  //         Swal.fire("Error", "เกิดข้อผิดพลาด: ไม่พบข้อมูลสำหรับ Report", "error");
  //       }

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
  //     });
  //   // 
  // }

  // const SendEmailAndFilePDF = async () => {
  //   Swal.fire({
  //     title: "คุณต้องการส่งเมลใช่หรือไม่",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ไม่`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let pdfContent = null;
  //       const promiseA = new Promise(async (resolve, reject) => {
  //         try {
  //           // console.log('dataGenarateReport.data', dataGenarateReport.data);
  //           for (let item of dataGenarateReport.data) {
  //             const checkInquiry = inquiryList.data_forsave.findIndex(el => item.tooling_receive[0].tooling_supplier_id === el.supplier_id);
  //             // console.log('checkInquiry', checkInquiry);
  //             if (checkInquiry >= 0) {
  //               pdfContent = await Tooling_report(company, item, getComConfig(), true);
  //               // console.log('pdfContent', pdfContent);
  //               inquiryList.data_forsave[checkInquiry].pdfContent = pdfContent;
  //             }
  //           }
  //           resolve();
  //         } catch (error) {
  //           console.log('error', error);
  //           reject(error);
  //         }
  //       });

  //       promiseA.then(() => {
  //         // console.log('pdfContent', JSON.stringify(pdfContent));
  //         let total = JSON.stringify(pdfContent);
  //         const arrList = [];

  //         while (total.length > 0) {
  //           if (total.length >= 800000) {
  //             const cutStr = total.substring(0, 800000);
  //             arrList.push(cutStr);
  //             total = total.substring(800000); // Update the remaining portion of the string

  //           } else {
  //             arrList.push(total);
  //             total = ""; // The entire string has been added to arrList, so clear it

  //           }
  //         }
  //         let mailOptions = {
  //           from: 'dechbodin34@gmail.com',
  //           to: "dechbodin.lump@northbkk.ac.th",
  //           subject: "Attachment experiment",
  //           text: "My <3",
  //           company_id: getUser().com,
  //           oem_id: getOem(),
  //           allDataInquiry: inquiryList.data_forsave[0].supplier_id,
  //           dataPDFList: arrList,
  //           /* data1haf1: data1haf1,
  //           data1haf2: data1haf2,
  //           data2haf1: data2haf1,
  //           data2haf2: data2haf2, */
  //           attachments: [
  //             {
  //               // filename: 'attachment.pdf',
  //               content: "pdfContent",
  //               // path: pdfContent,
  //               // contentType: 'application/pdf',
  //               // encoding: 'base64'    //this line!!!!
  //             }
  //           ]
  //         };
  //         // console.log('mailOptions', mailOptions);

  //         const formData = new FormData();
  //         // formData.append('dataPDFList', mailOptions.dataPDFList);
  //         formData.append('allDataInquiry', mailOptions.allDataInquiry);
  //         formData.append('oem_id', mailOptions.oem_id);
  //         formData.append('company_id', mailOptions.company_id);
  //         formData.append('company_name', getComConfig().com_name);
  //         for (let item = 0; item < arrList.length; item++) {
  //           formData.append('pdfContent' + item, JSON.stringify(arrList[item]));
  //         }
  //         formData.append('dataPDFLength', arrList.length);

  //         // Send the request with FormData
  //         axios({
  //           method: 'post',
  //           url: Configs.API_URL + '/tooling/sendEmailAndFilePDF',
  //           headers: {
  //             'Authorization': getToken(),
  //             'X-TTT': Configs.API_TTT,
  //             'Content-Type': 'multipart/form-data',
  //           },
  //           data: formData,
  //         })
  //           .then(function (response) {
  //             if (response) {
  //               Swal.fire({
  //                 icon: "success",
  //                 text: "ส่งอีเมลสำเร็จ",
  //                 showConfirmButton: false,
  //                 timer: 1000
  //               }).then(() => {
  //                 //window.location.reload(); //.href = "/Human_Resource/intensive_TTT/manage_product_system";
  //               });
  //             }
  //           })
  //           .catch(function (error) {
  //             console.log(error);
  //             Swal.fire('Error', 'เกิดข้อผิดพลาด: ' + error, 'error');
  //           });
  //       })
  //         .catch(function (error) {
  //           console.log(error);
  //           Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
  //         });

  //     }
  //   })

  // }

  async function GetInquiryList(params) {
    let pushData = {
      company_id: getUser().com,
      oem_id: getOem(),
      mode: props.mode,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/tooling/filterInquiry",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        const options = [];
        /*         console.log("setHeadTeam", response.data.data); */
        await response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.inquiry_no;
          options.push(element);
        });

        setinquiry(options);

        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  async function GetPOByInquiry(params) {


    let filter_temp = {
      company_id: inquiry_id.company_id,
      oem_id: inquiry_id.oem_id,
      inquiry_id: inquiry_id.inquiry_id,
      po_id: id
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/tooling/GetPOByInquiry",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_temp,
    })
      .then(async function (response) {
        console.log(response.data);
        setinquiryList([]);
        setinquiryList(response.data);

        /* if (props.mode === "read") {
          const temp = [];
          for (let item of response.data.data_forsave) {
            const data = await generateReport(item);
            temp.push(data);
          }
          console.log('temp', temp);
        } */
        /*  const options = [];
  
        await response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.inquiry_no;
          options.push(element);
        });
  
        setinquiry(options);
  
        settest({ ...test, test: "" });
        test.test = ""; */
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }


  async function finePOToolingInquiry(params) {
    console.log(id);
    axios({
      method: "get",
      url: Configs.API_URL + "/tooling/finePOToolingInquiry?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        console.log("finePOToolingInquiry", response.data);

        setinquiry_id({ ...inquiry_id, inquiry_id: response.data.inquiry_id });
        inquiry_id.inquiry_id = response.data.inquiry_id;
        setEditPo({ ...EditPo, po_no: response.data.po_no, issue_date: new Date(moment(response.data.issue_date).format('YYYY-MM-DD')), po_id: response.data.id })
        EditPo.po_no = response.data.po_no;
        EditPo.issue_date = new Date(moment(response.data.issue_date).format('YYYY-MM-DD'));
        EditPo.id = response.data.id;
        GetPOByInquiry();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }


  function numberWithCommas(x) {
    return (
      <NumberFormat
        thousandSeparator={true}
        value={x || ''}
        displayType="text"
      />
    );
    /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
  }
  useEffect(() => {

    if (props.mode === "read") {
      setdisable(true);
      setPage_mode("View");
      // generateReport(inquiryList.data_forsave);
    } else if (props.mode === "edit") {
      setdisable(false);
      setPage_mode("Edit");
    } else {
      setPage_mode("Add");
      setdisable(false);
    }

  }, []);


  useEffect(() => {
    if (props.mode === "add") {

    } else {
      /*   console.log("id",id); */
      finePOToolingInquiry();

    }
    GetInquiryList();
  }, []);

  async function saveOption() {
    console.log("teeeeessss", inquiryList.data_forsave);
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องบันทึกแบบร่างใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {

        axios({
          method: "post",
          url: Configs.API_URL + "/tooling/saveDrafPOByInquiry",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: { list: inquiryList.data_forsave, po_id: id || null },
        })
          .then(async function (response) {
            if (response.data === true) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => { });
              // window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
            }


          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              title: "เกิดข้อผิดพลาด",
              text: '',
              html: error.response.data.message
            }).then(() => {
              if (error.response.data.message == 'ไม่สามารถทำรายการได้ เนื่องจากรายการใน PO นี้ได้รับสินค้าแล้ว')  {
                window.location.href = '/Warehouse_Management/Tooling_PO';
              }
            });
          });
      }
    });

  }
  async function gen_po(params) {
    console.log("Poooooooooo", inquiryList.data_forsave);
    if (inquiryList.data_forsave === undefined) {
      Swal.fire("Gennerate PO ไม่สำเร็จ", "กรุณาเลือก Inquiry No. ก่อนทำรายการถัดไป", "error");
      return;
    }
    if (inquiryList.data_forsave.filter(el => el.is_select === false).length === inquiryList.data_forsave.length) {
      Swal.fire("Gennerate PO ไม่สำเร็จ", "ต้องเลือก Supplier อย่างน้อย 1 Supplier", "error");
      return;
    }
    for (let el of inquiryList.data_forsave) {
      if (el.amount == 0 || el.amount == "") {
        Swal.fire("Gennerate PO ไม่สำเร็จ", "ช่องจำนวนที่ต้องการ ต้องมีค่ามากกว่า 0", "error");
        return;
      }
      
      if (el.is_select === true && (el.price == 0 || el.price == "")) {
        Swal.fire("Gennerate PO ไม่สำเร็จ", "ราคาของ Supplier ที่เลือกต้องมีค่ามากกว่า 0", "error");
        return;
      }
    }
    const tempGenPo = {
      company_id: getUser().com,
      oem_id: getOem(),
      data_forsave: inquiryList.data_forsave,
      user_id: getUser().fup
    }
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องบันทึกข้อมูลใบ PO ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {

        axios({
          method: "post",
          url: Configs.API_URL + "/tooling/saveDrafPOByInquiry",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: { list: inquiryList.data_forsave, po_id: null },
        })
          .then(async function (response) {

            if (response.data === true) {

              axios({
                method: "post",
                url: Configs.API_URL + "/tooling/genPOToolingInquiry",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: tempGenPo,
              })
                .then(async function (response) {
                  if (response.data) {
                    setPoDataResponse({ data: response.data });
                    poDataResponse.data = response.data;

                    ClickModalRef();
                    /* Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => { });
                    window.location.href = "/Warehouse_Management/Tooling_PO"; */
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              title: "เกิดข้อผิดพลาด",
              text: '',
              html: error.response.data.message
            }).then(() => {
              if (error.response.data.message == 'ไม่สามารถทำรายการได้ เนื่องจากรายการใน PO นี้ได้รับสินค้าแล้ว')  {
                window.location.href = '/Warehouse_Management/Tooling_PO';
              }
            });
          });
      }
    });
  }
  const saveOptionPO = async () => {

    const fineBlank = poDataResponse.data.filter((e)=>{
      return e.po_no.trim() === ''
    }) 
    console.log(fineBlank);
    if (fineBlank.length > 0) {
      Swal.fire("Error", "เกิดข้อผิดพลาด: กรุณาระบุ Po No. ให้ครบ", "error");
    } else {
    
    const dataSave = {
      arrList: poDataResponse.data,
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup
    }
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องบันทึกข้อมูลใบ PO ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
      axios({
        method: "post",
        url: Configs.API_URL + "/tooling/savePOToolingInquiry",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: dataSave
      })
        .then(async function (response) {
          if (response.data === true) {
            Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              window.location.href = "/Warehouse_Management/Tooling_PO";
            });
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error.response.data.message, "error");
        });
      }
    })
  }
  }
  const modalRef = useRef(null);
  const ClickModalRef = () => {
    modalRef.current.click();
  }
  const [poDataResponse, setPoDataResponse] = useState({
    data: []
  });
  async function save_change_po(e) {
    console.log("Change Poooooooooo", inquiryList.data_forsave);
    
    if (inquiryList.data_forsave === undefined) {
      Swal.fire("Gennerate PO ไม่สำเร็จ", "ต้องเลือก Supplier อย่างน้อย 1 Supplier", "error");
      return;
    }
    if (inquiryList.data_forsave.filter(el => el.is_select === false).length === inquiryList.data_forsave.length) {
      Swal.fire("Gennerate PO ไม่สำเร็จ", "ต้องเลือก Supplier อย่างน้อย 1 Supplier", "error");
      return;
    }
    for (let el of inquiryList.data_forsave) {
      if (el.amount == 0 || el.amount == "") {
        Swal.fire("Gennerate PO ไม่สำเร็จ", "ช่องจำนวนที่ต้องการ ต้องมีค่ามากกว่า 0", "error");
        return;
      }
      if (el.is_select === true && (el.price == 0 || el.price == "")) {
        Swal.fire("Gennerate PO ไม่สำเร็จ", "ราคาของ Supplier ที่เลือกต้องมีค่ามากกว่า 0", "error");
        return;
      }
    }
    const tempGenPo = {
      company_id: getUser().com,
      oem_id: getOem(),
      data_forsave: inquiryList.data_forsave,
      user_id: getUser().fup,
      issue_date: EditPo.issue_date,
      po_id: EditPo.po_id,
    }
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องบันทึกข้อมูลใบ PO ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {

        axios({
          method: "post",
          url: Configs.API_URL + "/tooling/saveDrafPOByInquiry",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: { list: inquiryList.data_forsave, po_id: tempGenPo.po_id },
        })
          .then(async function (response) {

            if (response.data === true) {

              axios({
                method: "post",
                url: Configs.API_URL + "/tooling/updatePOToolingInquiry",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: tempGenPo,
              })
                .then(async function (response) {
                  if (response.data === true) {

                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => { });
                    window.location.href = "/Warehouse_Management/Tooling_PO";
                  }


                })
                .catch(function (error) {
                  console.log(error);
                  Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                });

            }


          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              title: "เกิดข้อผิดพลาด",
              text: '',
              html: error.response.data.message
            }).then(() => {
              if (error.response.data.message == 'ไม่สามารถทำรายการได้ เนื่องจากรายการใน PO นี้ได้รับสินค้าแล้ว')  {
                window.location.href = '/Warehouse_Management/Tooling_PO';
              }
            });
          });
      }
    });
  }

  const rowsData = [];
  for (let index = 0; index < poDataResponse.data.length; index++) {
    const rowItem = {};
    // console.log('object :>> ', poDataResponse.data);
    rowItem["no"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <input
          type="text"
          value={poDataResponse.data[index].po_no}
          className="form-control"
          // disabled={disable}
          onChange={(e) => {
            let temp = poDataResponse.data
            temp[index].po_no = e.target.value
            setPoDataResponse({
              ...poDataResponse,
              data: temp
            });
            poDataResponse.data = temp;
          }}
      />
      </div>
    );
    rowItem["name"] = poDataResponse.data[index].supplier_name;

    rowsData.push(rowItem);
  }

  const dataGenPO = {
    columns: [
      {
        label: "PO No.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier Name",
        field: "name",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  }; /* ประจำเเค่ละปี */

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Tooling PO {props.mode === "edit" ? `แก้ไข PO No --> ${EditPo.po_no}` : ""}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Tooling PO</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              {props.mode === "add" || props.mode === "edit" ? (
                <div className="col-3 col-md-2 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
              ) : (
                ""
              )}

              <div className="col-3 col-md-2 col-xl-1">
                <button
                  type="button"
                  onClick={() => { window.location.href = "/Warehouse_Management/Tooling_PO"; }}
                  class="btn btn-block btn-danger "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row"></div>
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2" style={{ zIndex: "98" }}>
                <div className="form-group ">
                  <Select
                    isDisabled={props.mode === "edit" ? true : disable}
                    options={inquiry}
                    value={inquiry.filter((e) => {
                      return e.value === inquiry_id.inquiry_id;
                    })}
                    /*   ref={select_headTeam} */
                    defaultValue={page_mode === "Add" ? null : inquiry_id.inquiry_id}
                    onChange={(e) => {
                      if (e !== null) {
                        setinquiry_id({
                          ...inquiry_id,
                          inquiry_id: e.value || "",
                        });
                      }
                      inquiry_id.inquiry_id = e.value || "";
                      GetPOByInquiry();
                    }}
                  />
                  <label>Inquiry No.</label>
                </div>
              </div>
              {props.mode === "view" || props.mode === "edit" ? (<div
                className="col-6 col-md-3 col-xl-2"
                style={{ "z-index": "98" }}
              >
                <div className="form-group ">
                  <DatePicker
                    id="input-emp-time_start"
                    customInput={<CustomInput />}
                    selected={EditPo.issue_date}
                    className="form-control"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {

                      setEditPo({ ...EditPo, issue_date: date });
                      EditPo.issue_date = date;
                      //  console.log(date);

                    }}
                  // onChange={async (date) => {
                  //   set_filler_operational_data({
                  //     ...filler_operational_data,
                  //     time_start: date,
                  //   });
                  // }}
                  // selectsStart
                  // startDate={filler_operational_data.time_start}
                  // maxDate={filler_operational_data.time_end}
                  //  endDate={filler_operational_data.time_end}
                  />
                  <label> Issue Date </label>
                </div></div>) : ("")}
              {/* {props.mode === "add" || props.mode === "edit" ?  (<div
                className="col-6 col-md-3 col-xl-2"
                style={{ "z-index": "98" }}
              >
                <div className="form-group ">
                  <DatePicker
                    id="input-emp-time_start"
                    customInput={<CustomInput />}
                    selected={filler_operational_data.time_start}
                    className="form-control"
                    dateFormat={"dd-MM-yyyy"}
                    // onChange={async (date) => SetDate_start_fg(date)}
                    onChange={async (date) => {
                      set_filler_operational_data({
                        ...filler_operational_data,
                        time_start: date,
                      });
                    }}
                    selectsStart
                    startDate={filler_operational_data.time_start}
                    maxDate={filler_operational_data.time_end}
                    //  endDate={filler_operational_data.time_end}
                  />
                  <label> วันที่เริ่มต้น </label>
                </div>
              </div>)
              : (
               ""
              )} */}
              {props.mode === "add" || props.mode === "edit" ? (

                props.mode === "edit" ? <div className="col-4 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                      onClick={() => save_change_po()}
                    >
                      Save Change PO
                    </button>
                  </div>
                </div> :
                  <>
                    <div className="col-4 col-md-3 col-xl-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          className="btn btn-block btn-primary"
                          onClick={() => gen_po()}
                        >
                          Generate PO
                        </button>
                      </div>
                    </div>
                  </>
                /*      
                    
                    */


              ) : (
                <div className="col-4 col-md-3 col-xl-2">
                  {/* <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        console.log('inq', inquiryList.data_forsave);
                        // generateReport(inquiryList.data_forsave[0]);
                        // SendEmailAndFilePDF();
                      }}
                    >
                      Send Email
                    </button>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Tooling PO</h3>
              <div className="card-tools"></div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-ls-12">
                <div className="card-body  table-responsive table-head-fixed table-bordered table-hover">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <table
                      style={{
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            rowSpan={2}
                            style={{
                              textAlign: "center",
                              border: "1px solid #000",
                            }}
                          >
                            รายการ
                          </th>
                          <th
                            rowSpan={2}
                            style={{
                              textAlign: "center",
                              border: "1px solid #000",
                            }}
                          >
                            จำนวนที่ต้องการ
                          </th>
                          <th
                            style={{
                              textAlign: "center",
                              border: "1px solid #000",
                            }}
                            colSpan={
                              inquiryList.supplierList === undefined
                                ? 0
                                : inquiryList.supplierList.length
                            }
                          >
                            ราคา
                          </th>
                        </tr>
                        <tr>
                          {inquiryList.supplierList === undefined
                            ? ""
                            : inquiryList.supplierList.map((el) => {
                              return (
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid #000",
                                  }}
                                >
                                  {el.supplier_name}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {inquiryList.toolingList === undefined
                          ? ""
                          : inquiryList.toolingList.map((el) => {
                            let find_index_el = inquiryList.data_forsave.findIndex((element) => {

                              return element.tooling_id === el.tooling_id;
                            });

                            return (
                              <tr>
                                {" "}
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid #000",
                                  }}
                                >
                                  {el.tooling_code} - {el.tooling_name}
                                </th>
                                <th
                                  style={{
                                    textAlign: "center",
                                    border: "1px solid #000",
                                  }}
                                >
                                  <NumberFormat
                                    key={el.inquiry_id + el.tooling_supplier_id + "amount"}
                                    type="text"
                                    className="form-control"
                                    value={find_index_el > -1 ? inquiryList.data_forsave[find_index_el].amount : ''}
                                    id={el.inquiry_id + el.tooling_supplier_id + "amount"}
                                    name={el.inquiry_id + el.tooling_supplier_id + "amount"}
                                    disabled={disable}
                                    decimalScale={0}
                                    allowNegative={false}
                                    required
                                    //disabled={el2.tooling_supplier_id === el.tooling_supplier_id && el2.tooling_id === el.tooling_id ? false:true}
                                    thousandSeparator={true}
                                    style={{
                                      width: "100%",
                                    }}
                                    onChange={(e) => {
                                      inquiryList.data_forsave[find_index_el].amount = Number(e.target.value.toString().replace(/,/g, ""));
                                      console.log(inquiryList.data_forsave);
                                    }}
                                  />{/*  {numberWithCommas(el.amount)} */}
                                </th>
                                {console.log('test start')}
                                {inquiryList.supplierList === undefined
                                  ? ""
                                  : inquiryList.supplierList.map((el2, id) => {
                                    let find_index = null;
                                    // console.log('in-------', el2.tooling_supplier_id === el.tooling_supplier_id && el.supplier_id === el2.supplier_id, el2, el);
                                    // if (el2.tooling_supplier_id === el.tooling_supplier_id && el2.tooling_id === el.tooling_id) {
                                    find_index = inquiryList.data_forsave.findIndex((element) =>
                                      // console.log("",element.duo_id === el2.tooling_relation_supplier);
                                      element.supplier_id === el2.supplier_id && el.tooling_id === element.tooling_id /*  && element.tooling_id === el2.tooling_id */
                                    );
                                    // }
                                    /*   console.log("find_index",find_index); */
                                    return (
                                      <td
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid #000",
                                        }}
                                      >
                                        {console.log(el2.supplier_id === el.supplier_id/*  && el2.tooling_id === el.tooling_id */, inquiryList.data_forsave, inquiryList.supplierList, "find_index", find_index)}

                                        <NumberFormat
                                          key={el2.inquiry_id + el2.tooling_supplier_id + "input"}
                                          type="text"
                                          className="form-control"
                                          value={find_index !== null && find_index !== -1 ? (Number(inquiryList.data_forsave[find_index].price) !== 0 ? Number(inquiryList.data_forsave[find_index].price) : Number(inquiryList.data_forsave[find_index].buy_price)) || '' : ''}
                                          id={el2.inquiry_id + el2.tooling_supplier_id}
                                          name={el2.inquiry_id + el2.tooling_supplier_id}
                                          required
                                          allowNegative={false}
                                          disabled={/* (el2.tooling_supplier_id === el.tooling_supplier_id && el2.tooling_id === el.tooling_id) */find_index !== null && find_index !== -1 ? disable : true}
                                          thousandSeparator={true}
                                          style={{
                                            width: "100%",
                                          }}
                                          onChange={(e) => {
                                            inquiryList.data_forsave[find_index].price = Number(e.target.value.toString().replace(/,/g, "")) || '';
                                            settest({ ...test, test: "" });
                                            test.test = "";
                                            console.log(inquiryList.data_forsave);
                                          }}
                                        />
                                        {find_index !== null && find_index > -1 ? <>
                                          {inquiryList.data_forsave[find_index].is_select !== true ?
                                            <button
                                              disabled={/* el2.tooling_supplier_id === el.tooling_supplier_id && el2.tooling_id === el.tooling_id */find_index !== null && find_index !== -1 ? disable : true}
                                              type="button"
                                              key={el2.id + "select"}
                                              className="btn btn-sm btn-block btn-info"
                                              onClick={(e) => {
                                                const temp = inquiryList.data_forsave.filter(el => el.tooling_id === inquiryList.data_forsave[find_index].tooling_id);
                                                if (temp) {
                                                  temp.forEach(tl => {
                                                    tl.is_select = false;
                                                  });
                                                }
                                                inquiryList.data_forsave[find_index].is_select = true;
                                                settest({ ...test, test: "" });
                                                test.test = "";
                                                console.log(inquiryList.data_forsave);

                                              }}
                                            >
                                              Select
                                            </button>
                                            :
                                            <button
                                              disabled={/* el2.tooling_supplier_id === el.tooling_supplier_id && el2.tooling_id === el.tooling_id */find_index !== null && find_index !== -1 ? disable : true}
                                              type="button"
                                              key={el2.id + "select"}
                                              className="btn btn-sm btn-block btn-success "
                                              onClick={(e) => {
                                                inquiryList.data_forsave[find_index].is_select = false;
                                                console.log(inquiryList.data_forsave);

                                                settest({ ...test, test: "" });
                                                test.test = "";
                                              }}
                                            >
                                              Selected
                                            </button>
                                          }
                                        </> : <>
                                          <button
                                            disabled={/* el2.tooling_supplier_id === el.tooling_supplier_id && el2.tooling_id === el.tooling_id */find_index !== null && find_index !== -1 ? disable : true}
                                            type="button"
                                            key={el2.id + "select"}
                                            className="btn btn-sm btn-block btn-info"
                                            onClick={(e) => {
                                              inquiryList.data_forsave[find_index].is_select = true;

                                              settest({ ...test, test: "" });
                                              test.test = "";
                                              console.log(inquiryList.data_forsave);

                                            }}
                                          >
                                            Select
                                          </button>
                                        </>}



                                      </td>
                                    );
                                    /*  if (
                                      el2.supplier_id === el.supplier_id
                                    ) {
                                      return (
                                        <>
                                          <NumberFormat
                                            key={el2.id}
                                            className="form-control"
                                            // value={materail[index].nmo}
  
                                            name={el2.id}
                                            thousandSeparator={true}
                                            style={{
                                              width: "100%",
                                            }}
                                            // onChange={approve_update.bind(this)}
                                          />
  
                                          <button
                                            type="button"
                                            key={el2.id}
                                            className="btn btn-sm btn-block btn-info  "
                                            // onClick={() => {
                                            //    filterPlanning();
                                            //  }}
                                          >
                                            Select
                                          </button>
                                        </>
                                      );
                                    } */
                                  })}
                              </tr>
                            );
                          })}
                        {/*  <tr >
                            <td>
                              ststs
                            </td>
                            <td>
                              ststs
                            </td>
                            <td>
                              ststs
                            </td>
                          </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            ></div>
            {/* /.card-body */}
            <div className="card-footer">
            <div className="" hidden>
              <a
                className=" btn btn-xs "
                data-toggle="modal"
                ref={modalRef}
                data-target="#modal-viewLeaveHistory"
                data-backdrop="static"
              >
              </a>
          </div>
            </div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        <section>
        <div className="modal fade" id="modal-viewLeaveHistory">
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">
                      Input PO No.
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setPoDataResponse({ data: [] });
                        poDataResponse.data = [];
                      }}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row mb-4">
                    <div className="col-6 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={saveOptionPO}
                        class="btn btn-block btn-success "
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-6 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={() => { window.location.href = "/Warehouse_Management/Tooling_PO"; }}
                        class="btn btn-block btn-danger "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div
                    className=" table-responsive "
                    style={{ whiteSpace: "nowrap", padding: ".5rem" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      id="table-to-xls"
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={dataGenPO}
                    />
                  </div>
                  </div>
                  <div className="modal-footer justify-content-between">
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-1"> </div>
                      <div className="col-4"></div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={() => {
                        setPoDataResponse({ data: [] });
                        poDataResponse.data = [];
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Generate_po;
