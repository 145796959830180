import React, { useState, useEffect } from "react";

import { getOem, getUser } from "../../../Utils/Common";
import Select from "react-select";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import SVGLineChart from "./SVGLineChart.svg";

import { Line } from "react-chartjs-2";

function Cumulative_Graph({
  // resultSuccessData,
  setResultSuccessDataAll,
  resultSuccessDataAll,
  allWeek,
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  // const labels = [
  //   "JAN",
  //   "FEB",
  //   "MAR",
  //   "APR",
  //   "MAY",
  //   "JUN",
  //   "JUL",
  //   "AUG",
  //   "SEP",
  //   "OCT",
  //   "NOV",
  //   "DEC",
  // ];

  const [label, setLabel] = useState({
    data: [],
  });

  const [colorList, setColorList] = useState(["#012361"]);

  // let newHeight = 300;
  // if (params.DataChart.labels.length > 0) {
  //   newHeight = 300 + params.DataChart.labels.length * 20;
  // }

  let width, height, gradient;
  function getGradient(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      gradient.addColorStop(0, "#012361");
      gradient.addColorStop(0.5, "#1B59F8");
    }

    return gradient;
  }

  function getRandomColor() {
    let colorFound = true;
    let letters = "0123456789ABCDEF";

    do {
      colorFound = true;
      var randomColor = "#";
      for (var i = 0; i < 6; i++) {
        randomColor += letters[Math.floor(Math.random() * 16)];
      }

      colorList.some((color) => {
        if (deltaE(color, randomColor) < 50) {
          /* Branch taken when randomColor is too similar
           *  to an already existing color. */
          colorFound = false;
          return true;
        }

        return false;
      });

      return randomColor;
    } while (!colorFound);
  }

  function deltaE(rgbA, rgbB) {
    let labA = rgb2lab(rgbA);
    let labB = rgb2lab(rgbB);
    let deltaL = labA[0] - labB[0];
    let deltaA = labA[1] - labB[1];
    let deltaB = labA[2] - labB[2];
    let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    let deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    let sc = 1.0 + 0.045 * c1;
    let sh = 1.0 + 0.015 * c1;
    let deltaLKlsl = deltaL / 1.0;
    let deltaCkcsc = deltaC / sc;
    let deltaHkhsh = deltaH / sh;
    let i =
      deltaLKlsl * deltaLKlsl +
      deltaCkcsc * deltaCkcsc +
      deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
  }

  function rgb2lab(rgb) {
    let r = rgb[0] / 255,
      g = rgb[1] / 255,
      b = rgb[2] / 255,
      x,
      y,
      z;
    r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116;
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116;
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116;
    return [116 * y - 16, 500 * (x - y), 200 * (y - z)];
  }

  // const data = {
  //   labels: label.data,
  //   datasets: [
  //     {
  //       data: resultSuccessDataAll.showData.data,
  //       borderColor: function (context) {
  //         const chart = context.chart;
  //         const { ctx, chartArea } = chart;

  //         if (!chartArea) {
  //           return;
  //         }
  //         return getGradient(ctx, chartArea);
  //       },
  //       borderWidth: 5,
  //       label: "คะแนนความสำเร็จ",
  //     },
  //   ],
  // };

  const data = {
    labels: label.data,
    datasets: resultSuccessDataAll.showData.map((item, index) => {
      return {
        data: item.data,
        borderColor:
          resultSuccessDataAll.showData.length > 1
            ? colorList[index]
            : function (context) {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  return;
                }
                return getGradient(ctx, chartArea);
              },
        backgroundColor:
          resultSuccessDataAll.showData.length > 1
            ? `${colorList[index]}7F`
            : "#1B59F87F",
        borderWidth: 5,
        label:
          resultSuccessDataAll.showData.length > 1
            ? ` ${item.name} `
            : " คะแนนความสำเร็จ ",
      };
    }),
  };

  function pad_2num(num) {
    if (num < 10) {
      return "0" + num.toString();
    } else {
      return num.toString();
    }
  }

  useEffect(async () => {
    const weekOfMonth = [];

    for (let index = 0; index < allWeek; index++) {
      weekOfMonth.push(`Week${pad_2num(index + 1)}`);
    }

    setLabel({
      data: weekOfMonth,
    });
    label.data = weekOfMonth;
  }, [allWeek]);

  return (
    <>
      <div className="flexResultOfYourSuccess_direction">
        <div style={{ display: "flex", marginRight: "20px" }}>
          <img
            src={SVGLineChart}
            className="fontResultOfYourSuccess_title_image"
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              whiteSpace: "nowrap",
            }}
          >
            <label
              className="fontResultOfYourSuccess_title_1"
              style={{
                // fontSize: "40px",
                fontWeight: "bold",
                lineHeight: "normal",
                display: "block",
                margin: "0px",
                color: "#000000",
              }}
            >
              Result of your Success
            </label>

            <label
              className="fontResultOfYourSuccess_title_2"
              style={{
                // fontSize: "35px",
                fontWeight: "normal",
                lineHeight: "normal",
                display: "block",
                margin: "0px",
                color: "#A3A3A3",
              }}
            >
              สรุปผลความสำเร็จของคุณ
            </label>
          </div>
        </div>

        <div className="cardWeekResultOfYourSuccess_direction">
          <div className="cardWeekResultOfYourSuccess_part_1">
            <div className="cardWeekResultOfYourSuccess_size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    background: "#04B520",
                    borderRadius: "30px",
                    width: "78px",
                    height: "30px",
                    flexShrink: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#E0F0E4" }}>+3</span>
                </div>

                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: "normal",
                    display: "block",
                    margin: "0px",
                    color: "#02951A",
                    textAlign: "center",
                    background: "#E0F0E4",
                    width: "67px",
                    height: "24px",
                    borderRadius: "5px",
                  }}
                >
                  + Success
                </label>
              </div>

              <label
                style={{
                  fontSize: "40px",
                  fontWeight: "normal",
                  lineHeight: "normal",
                  display: "block",
                  margin: "0px",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                {resultSuccessDataAll.showData.length === 1
                  ? `${resultSuccessDataAll.showData[0].greenSuccess} Weeks`
                  : "-"}
              </label>
            </div>

            <div className="cardWeekResultOfYourSuccess_size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    background: "#E9B500",
                    borderRadius: "30px",
                    width: "78px",
                    height: "30px",
                    flexShrink: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#FFF5D1" }}>+1</span>
                </div>

                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: "normal",
                    display: "block",
                    margin: "0px",
                    color: "#D3A504",
                    textAlign: "center",
                    background: "#FFF5D1",
                    width: "67px",
                    height: "24px",
                    borderRadius: "5px",
                  }}
                >
                  + Success
                </label>
              </div>

              <label
                style={{
                  fontSize: "40px",
                  fontWeight: "normal",
                  lineHeight: "normal",
                  display: "block",
                  margin: "0px",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                {resultSuccessDataAll.showData.length === 1
                  ? `${resultSuccessDataAll.showData[0].yellowSeccess} Weeks`
                  : "-"}
              </label>
            </div>

            <div className="cardWeekResultOfYourSuccess_size">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    background: "#FF0024",
                    borderRadius: "30px",
                    width: "78px",
                    height: "30px",
                    flexShrink: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ color: "#FFB9C3" }}>-1</span>
                </div>

                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: "normal",
                    display: "block",
                    margin: "0px",
                    color: "#FF0024",
                    textAlign: "center",
                    background: "#FFB9C3",
                    width: "67px",
                    height: "24px",
                    borderRadius: "5px",
                  }}
                >
                  - Fail
                </label>
              </div>

              <label
                style={{
                  fontSize: "40px",
                  fontWeight: "normal",
                  lineHeight: "normal",
                  display: "block",
                  margin: "0px",
                  color: "#000000",
                  textAlign: "center",
                }}
              >
                {resultSuccessDataAll.showData.length === 1
                  ? `${resultSuccessDataAll.showData[0].redFail} Weeks`
                  : "-"}
              </label>
            </div>
          </div>

          <div className="cardWeekResultOfYourSuccess_size">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  background: "#FF0024",
                  borderRadius: "30px",
                  width: "78px",
                  height: "30px",
                  flexShrink: "0",
                }}
              />

              <div
                style={{
                  background: "#E9B500",
                  borderRadius: "30px",
                  width: "68px",
                  height: "30px",
                  flexShrink: "0",
                  marginLeft: "-53%",
                }}
              />

              <div
                style={{
                  background: "#04B520",
                  borderRadius: "30px",
                  width: "58px",
                  height: "30px",
                  flexShrink: "0",
                  marginLeft: "-48%",
                }}
              />

              <label
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  lineHeight: "normal",
                  display: "block",
                  margin: "0px",
                  color: "#8D8D8D",
                  textAlign: "center",
                  // background: "#FFB9C3",
                  // width: "67px",
                  // height: "24px",
                  // borderRadius: "5px",
                }}
              >
                Total
              </label>
            </div>

            <label
              style={{
                fontSize: "40px",
                fontWeight: "normal",
                lineHeight: "normal",
                display: "block",
                margin: "0px",
                color: "#000000",
                textAlign: "center",
              }}
            >
              {resultSuccessDataAll.showData.length === 1
                ? `${
                    resultSuccessDataAll.showData[0].greenSuccess * 3 +
                    resultSuccessDataAll.showData[0].yellowSeccess * 1 -
                    resultSuccessDataAll.showData[0].redFail * 1
                  } Point`
                : "-"}
            </label>
          </div>
        </div>
      </div>

      <div
        style={{
          margin: "20px",
        }}
      >
        <div className="form-group">
          {/* <Select
            options={resultSuccessDataAll.dropdown}
            value={resultSuccessDataAll.dropdown.filter((e) => {
              return e.value === resultSuccessDataAll.selectDropDown;
            })}
            onChange={(e) => {
              if (e !== null) {
                const showData = resultSuccessDataAll.dataList.filter((item) => {
                  return item.id === e.value;
                });

                setResultSuccessDataAll({
                  ...resultSuccessDataAll,
                  selectDropDown: e.value,
                  showData: showData[0],
                });
                resultSuccessDataAll.selectDropDown = e.value;
                resultSuccessDataAll.showData = showData[0];
              }
            }}
          /> */}
          <Select
            isMulti
            options={resultSuccessDataAll.dropdown}
            value={resultSuccessDataAll.selectDropDown}
            onChange={(e) => {
              if (e !== null) {
                const dataId = e.map((item) => item.value);

                const showData = resultSuccessDataAll.dataList.filter(
                  (item) => {
                    return dataId.includes(item.id);
                  }
                );

                const oldColorList = JSON.parse(JSON.stringify(colorList));
                if (
                  showData.length > 1 &&
                  showData.length > resultSuccessDataAll.showData.length
                ) {
                  oldColorList.push(getRandomColor());
                  setColorList(oldColorList);
                } else if (resultSuccessDataAll.showData.length > 1) {
                  // const indexColor = resultSuccessDataAll.showData.findIndex(
                  //   (item) =>
                  //     item.id ===
                  //     resultSuccessDataAll.showData.filter(
                  //       (x) => !showData.includes(x)
                  //     )[0].id
                  // );
                  oldColorList.splice(oldColorList.length - 1, 1);
                  setColorList(oldColorList);
                }

                setResultSuccessDataAll({
                  ...resultSuccessDataAll,
                  selectDropDown: e,
                  showData: showData,
                });
                resultSuccessDataAll.selectDropDown = e;
                resultSuccessDataAll.showData = showData;
              } else {
                setResultSuccessDataAll({
                  ...resultSuccessDataAll,
                  selectDropDown: e,
                  showData: [],
                });
                resultSuccessDataAll.selectDropDown = e;
                resultSuccessDataAll.showData = [];
              }
            }}
          />
          <label htmlFor="">Employee</label>
        </div>
      </div>

      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <div className="chartWrapper_other">
          <div className="chartAreaWrapper_other customScroll-2">
            <div className="chartAreaWrapper2_other">
              <Line
                id="chart-result-of-your-success"
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display:
                        resultSuccessDataAll.showData.length > 1 ? true : false,
                      onClick: false,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      min: -20,
                      max: 100,
                      ticks: {
                        stepSize: 10,
                      },
                      grid: {
                        borderDash: function (value) {
                          return value.tick.value == 30 ||
                            value.tick.value == 60
                            ? []
                            : [8, 4];
                        },
                        color: function (value) {
                          return value.tick.value == 30 ||
                            value.tick.value == 60
                            ? "#5FD404"
                            : "rgba(0, 0, 0, 0.2)";
                        },
                      },
                    },
                  },
                }}
                data={data}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Cumulative_Graph;
