import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from 'sort-json-array';
import Sidebar from "../../component/Sidebar";
import DatePicker from "react-datepicker";
import axios from "axios";
import QRCode from 'qrcode.react';
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import Select from "react-select";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";


function Fuel_Consumtion(params) {
  const [getfuel_consumtion, setfuel_consumtion] = useState([]);
  const [company, setCompany] = useState([]);
  const [feature, setfeature] = useState([]);
  const [filler_order_product, setFiller_order_product] = useState({
    plate_number: "",
    car_type: "",
    groubcompany: "",
    kpi_name: "",
    milmg: "",
    lilt: "",
    location_oil: "",
    record_data: "",
    pesonal_name: "",
    start_date: "",
    end_date: "",
    oem_id: getOem(),
    company_id: getUser().com,
  });
  const rowsData = [];
  console.log("ชื่อ", getfuel_consumtion.plate_number);
  console.log("ชื่อsss", setFiller_order_product.rows);
  const CustomInput = ({ value, onClick }) => (

    <
      div className="input-group" >
      < input type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      /> <div className="input-group-prepend" >
        <span className="input-group-text" >
          <i className="far fa-calendar-alt" />
        </span>
        { /*  <label>test</label> */}
      </div>
    </div>
  )

  const [product_data, setProduct_data] = useState([]);

  const [end_option, setend_option] = useState([]);
  const select_end = useRef();
  const [model, setModel] = useState([]);
  const [oem_id, setOem_id] = useState(getOem());
  const [end_user, setEnd_user] = useState([]);
  const [filler_product, setFiller_product] = useState({
    oem_id: getOem(),

  });
  const [filler_end_user, setFiller_end_user] = useState({
    name: "",
    full_name: "",
  });

  useEffect(() => {
    const script = document.createElement("script");

    script.src = `js/Product`;
    document.body.appendChild(script);
  }, []);

  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  // const Get_End_user = async () => {
  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL + "/end_user/filter",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: filler_product,
  //   })
  //     .then(async (response) => {

  //       await setEnd_user(response.data);

  //       console.log(response.data);
  //       const options = [];

  //       response.data.forEach((el) => {
  //         var element = {};
  //         element["value"] = el.id;
  //         element["label"] = el.fullname;
  //         options.push(element);
  //       });
  //       setend_option(options);

  //     })
  //     .catch(async (error) => {
  //       console.log(error);
  //     });
  // };

  // const Getamount = async () => {
  //   if (
  //     amount.amount !== null &&
  //     amount.amount !== undefined &&
  //     amount.amount !== ""
  //   ) {
  //     if (amount.edit_type !== null) {
  //       const temp_amount = {
  //         id: amount.id,
  //         edit_type: amount.edit_type,
  //         amount: Number(amount.amount.replace(/,/g, "")),
  //         end_user_id: amount.end_user_id,
  //       };
  //       //console.log(temp_amount);
  //       axios({
  //         method: "post",
  //         url: Configs.API_URL + "/product/amountEnd_userInOut",
  //         headers: {
  //           Authorization: getToken(),
  //           "X-TTT": Configs.API_TTT,
  //           "Content-Type": "application/json",
  //         },
  //         data: temp_amount,
  //       })
  //         .then(async (response) => {
  //           Swal.fire({
  //             title: "Saving",
  //             allowEscapeKey: false,
  //             allowOutsideClick: false,
  //             timer: 300,
  //             onOpen: () => {
  //               Swal.showLoading();
  //             },
  //           }).then((result) => {
  //             Swal.fire({
  //               icon: "success",
  //               title: "Save",
  //               showConfirmButton: false,
  //               timer: 1500,
  //             }).then((result) => {
  //               /*  window.location.href = "/Warehouse_Management/WIP_IN_OUT"; */
  //               //  setmessage({
  //               //   num: "",
  //               //   name: "",
  //               // });
  //               // message.num="";
  //               // message.name="";
  //               setamount({
  //                 id: "",
  //                 edit_type: null,
  //                 amount: "",
  //                 end_user_id: "",
  //               });
  //               amount.id = "";
  //               amount.edit_type = null;
  //               amount.amount = "";
  //               amount.end_user_id = "";
  //               window.location.href =
  //                 "/Warehouse_Management/End_user_IN_OUT";

  //             }).then(() => {
  //               // Gettable();
  //               axios({
  //                 method: "post",
  //                 url: Configs.API_URL + "/product/filter",
  //                 headers: {
  //                   Authorization: getToken(),
  //                   "X-TTT": Configs.API_TTT,
  //                   "Content-Type": "application/json",
  //                 },
  //                 data: filler_product,
  //               })
  //                 .then(function (response) {
  //                   console.log(response.data);
  //                   let set_total_view = response.data.filter((element) => {
  //                     return element.id === amount.id;
  //                   })
  //                   // console.log(set_total_view)
  //                   // console.log(set_total_view[set_total_view.length - 1].logistic_amount)
  //                   setamount({
  //                     ...amount,
  //                     total: set_total_view[set_total_view.length - 1].end_user_amount || 0,
  //                     amount: "",
  //                   });

  //                 })
  //                 .catch(function (error) {
  //                   console.log(error);
  //                 });
  //             })
  //           });
  //           /* console.log(response.data); */
  //         })
  //         .catch(async (error) => {

  //           Swal.fire("Over Stock!", "Check your amount", "warning")
  //             .then((resault) => {
  //               window.location.href =
  //                 "/Warehouse_Management/End_user_IN_OUT";
  //             });
  //           console.log(error);
  //         });
  //     } else {
  //       Swal.fire("check in bound , out bound", "bound can't null", "warning");
  //     }
  //   } else {
  //     Swal.fire("check amount", "amount can't null", "warning");
  //   }
  // };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const selectCompanyGroup = useRef();
  useEffect(() => {
    GetCompany();
    // Gettable();
    // Getmodel();
    // Get_End_user();
    getFuelconsumtion();

  }, []);

  function getFuelconsumtion() {
    var temp = {
      plate_number: filler_order_product.plate_number.trim(),
      car_type: filler_order_product.car_type,
      groubcompany: filler_order_product.groubcompany,
      kpi_name: filler_order_product.kpi_name,
      milmg: filler_order_product.milmg,
      lilt: filler_order_product.lilt,
      location_oil: filler_order_product.location_oil,
      record_data: filler_order_product.record_data,
      pesonal_name: filler_order_product.pesonal_name,
      start_date: filler_order_product.start_date,
      end_date: filler_order_product.end_date,
      oem_id: filler_order_product.oem_id,
      company_id: filler_order_product.company_id,
    }

    axios({
      method: "post",
      url: Configs.API_URL + "/fuelConsumtion/get_fuel_consumtion",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setfuel_consumtion(response.data);
        console.log("ข้อมูลทั้งหมด", response.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("ErrorGetOil", error, "errorgetplate");
      });
  }

  // const Getmodel = async () => {
  //   axios(config_model)
  //     .then(async (response) => {
  //       // console.log(response.data);
  //       setModel(response.data);
  //     })
  //     .catch(async (error) => {
  //       console.log(error);
  //     });
  // };

  // const Gettable = async () => {
  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL + "/product/filter",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: filler_product,
  //   })
  //     .then(async (response) => {
  //       let sorted = response.data.sort((a, b) => b.end_user_amount - a.end_user_amount);
  //       await setProduct_data(sorted);
  //       //console.log(response.data);
  //     })
  //     .catch(async (error) => {
  //       console.log(error);
  //     });
  // };

  // function form(no, name, id, amount, edit_type) {
  //   setmessage({ ...message, name: name, no: no });
  //   setamount({
  //     ...amount,
  //     amount: "",
  //     total: amount,
  //     id: id,
  //     lot_no: "",
  //     edit_type: edit_type,
  //   });
  // }

  // const [amount, setamount] = useState({
  //   id: "",
  //   edit_type: null,
  //   end_user_id: "",
  //   amount: "",
  // });

  // const [message, setmessage] = useState({
  //   num: "",
  //   name: "",
  // });

  // function handleChange(value) {
  //   // Gettable();
  //   /* console.log(filler_product); */
  // }
  const clearFilter = async () => {
    await setFiller_order_product({
      ...filler_order_product,
      plate_number: "",
      car_type: "",
      groubcompany: "",
      kpi_name: "",
      milmg: "",
      lilt: "",
      location_oil: "",
      record_data: "",
      pesonal_name: "",
      start_date: "",
      end_date: "",
      oem_id: getOem(),
      company_id: getUser().com,
    });
    filler_order_product.plate_number = "";
    filler_order_product.car_type = "";
    filler_order_product.groubcompany = "";
    filler_order_product.kpi_name = "";
    filler_order_product.milmg = "";
    filler_order_product.lilt = "";
    filler_order_product.location_oil = "";
    filler_order_product.record_data = "";
    filler_order_product.pesonal_name = "";
    filler_order_product.start_date = "";
    filler_order_product.end_date = "";
    filler_order_product.oem_id = getOem();
    filler_order_product.company_id = getUser().com;
    // selectCompanyGroup.current.select.clearValue();
    getFuelconsumtion();
  };

// let name = {};
// let lname = {};



  for (let i = 0; i < getfuel_consumtion.length; i++) {
    const rowItem = {}
    
let name_oem_ = "";
if (getfuel_consumtion[0].oem === "บจก.สตูลค้าเหล็ก" || getfuel_consumtion[0].oem === "บริษัท สตูลค้าเหล็ก จำกัด") {
  name_oem_ = "SST";
}else if (getfuel_consumtion[0].oem  === "บจก.เอสทีสตีล(2001)" || getfuel_consumtion[0].oem === "บริษัท เอสทีสตีล(2001) จำกัด") {
 name_oem_ = "STS";
}else if (getfuel_consumtion[0].oem  === "บจก.เอสทีสตีล(2001) ปัตตานี" || getfuel_consumtion[0].oem === "บริษัท เอสทีสตีล(2001) ปัตตานี จำกัด") {
 name_oem_ = "STP";
}else if (getfuel_consumtion[0].oem  === "บจก.เอสที คอนกรีตโปรดักส์" || getfuel_consumtion[0].oem === "บริษัท เอสที คอนกรีตโปรดักส์ จำกัด") {
 name_oem_ = "STC";
}else if (getfuel_consumtion[0].oem  === "บจก.เอสทีซีวิล(2001)" || getfuel_consumtion[0].oem === "บริษัท เอสทีซีวิล(2001) จำกัด") {
 name_oem_ = "ST CIVIL";
}
    // let name = {};
    // let lname = {};
    // if (getfuel_consumtion[i].person_sirname_car === null || getfuel_consumtion[i].person_sirname_car === "ว่าง" && getfuel_consumtion[i].person_lastname_car === null || getfuel_consumtion[i].person_lastname_car === "ว่าง"){
    //   name = "ไม่ระบุ";
    //   lname = "";
    // }else{
    //   name = getfuel_consumtion[i].person_sirname_car;
    //   lname = getfuel_consumtion[i].person_lastname_car;
    // }
    
    rowItem["NO"] = i + 1;
    rowItem["plate_number"] = getfuel_consumtion[i].plate_number;
    rowItem["groupcompany"] = name_oem_;
    rowItem["typec"] = getfuel_consumtion[i].name;
    // rowItem["kpi"] = getfuel_consumtion[i].kpi;
    rowItem["milk"] = getfuel_consumtion[i].mileage;
    rowItem["lit"] = getfuel_consumtion[i].liter;
    rowItem["location"] = getfuel_consumtion[i].oil_location_name; ;
    rowItem["record_date"] = moment(getfuel_consumtion[i].record_date).format("DD/MM/yyyy , HH:mm") + "น.";
    rowItem["personal"] = getfuel_consumtion[i].person_car;
    // rowItem["personal"] = getfuel_consumtion[i].person_sirname_car + " " + getfuel_consumtion[i].person_lastname_car;
    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "NO",
        sort: "asc",
        width: 50,
      }, {
        label: "ทะเบียนรถ",
        field: "plate_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทรถ",
        field: "typec",
        sort: "asc",
        width: 50,
      },
      {
        label: "กลุ่มบริษัท",
        field: "groupcompany",
        sort: "asc",
        width: 50,
      },

      // {
      //   label: "KPI",
      //   field: "kpi",
      //   sort: "asc",
      //   width: 50,
      // },
      {
        label: "เลขไมล์ (กม.)",
        field: "milk",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวนลิตร (ลิตร)",
        field: "lit",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานที่เติม",
        field: "location",
        sort: "asc",
        width: 50,
      },
      {
        label: "เวลาเติมน้ำมัน",
        field: "record_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "ผู้รับผิดชอบ",
        field: "personal",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }
  function showmessage() {
    Swal.fire({
      icon: "info",
      title: "Waiting for connect service API QR",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });

  }

  async function SetDate_start(date) {
    setFiller_order_product({ ...filler_order_product, start_date: date })

  }
  async function SetDate_end(date) {
    setFiller_order_product({ ...filler_order_product, end_date: date })

  }

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="end_userinout" submenu="none" /> */}
      <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Fuel Consumtion{"  "}<a  target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>


                  <li className="breadcrumb-item active">Fuel Consumtion</li>
                </ol>
              </div>
            </div>
          </div>


          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}

          <div className="card">

            <div className="container-fluid">
              <div className="col-md-2">

                <div className="form-group ">

                  <h3 className="card-title">Fuel Consumtion</h3>
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
              <div className="row mb-2">

                <div className="col-md-2">

                  <div className="form-group ">


                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      value={filler_order_product.plate_number}
                      // disabled={disable}
                      onChange={(e) => {
                        setFiller_order_product({
                          ...filler_order_product,
                          plate_number: e.target.value,
                        });
                      }}
                    />

                    <label htmlFor="">ทะเบียน</label>{" "}
                    {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                  </div>
                </div>

                <div className="col-md-0.5">
                &nbsp;  <label > วันที่เริ่ม &nbsp;</label>
                </div>
                <div className="col-md-2">
                  <div className="form-group ">
                    <DatePicker selected={filler_order_product.start_date}
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => SetDate_start(date)}
                      selectsStart
                      startDate={filler_order_product.start_date}
                      endDate={filler_order_product.end_date}
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
                <div className="col-md-0.5">
                  <label>&nbsp;&nbsp;  วันที่สิ้นสุด  &nbsp;&nbsp;</label>
                </div>
                <div className="col-md-2">
                  <div className="form-group ">
                    <DatePicker
                      selected={filler_order_product.end_date}
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => SetDate_end(date)}
                      selectsEnd
                      startDate={filler_order_product.start_date}
                      endDate={filler_order_product.end_date}
                      minDate={filler_order_product.start_date}
                      customInput={<CustomInput />}

                    >
                    </DatePicker>
                  </div>
                </div>
                <div className="col-md-2">
                  {/*     <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                  <label htmlFor="">Category/Model</label>
                </div> */}
                  {/*   <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">       <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={getFuelconsumtion}
                  >
                    Search
                  </button>
                </div>
              </div>
                <div className="col-md-2">
                  <div className="form-group ">

                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                      onClick={() => clearFilter()}
                    >
                      Clear
                    </button>

                    {/* <Select
                          options={product_model_option}
                          defaultValue={filler_product.product_model_id}
                          ref = {selectProduct_model}
                          onChange={(e) => {
                           // console.log(e);
                           if(e !== null){
                            setFiller_product({
                              ...filler_product,
                              product_model_id: e.value,
                            });
                           }
                          
                          }}
                        />
                             <label htmlFor="">กลุ่มบริษัท</label> */}
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-group ">
                    {/* <button
                      type="button"
                      className="btn btn-block btn-primary"
                    >
                      Export.CSV
                    </button> */}       
                              <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-block btn-primary"
                                table="table-to-xls"
                                filename={"Fuel Consumtion" + " " + moment(new Date()).format('DD-MM-yyyy')}
                                sheet="tablexls"
                                buttonText=" Export.CSV"
                              />
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group ">

                    {/* <button
                      type="button"
                      className="btn btn-block btn-primary"
                    // onClick={addpage}
                    >
                      Clear
                    </button> */}
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group ">

                    {/* <button
                      type="button"
                      className="btn btn-block btn-primary"
                    // onClick={addpage}
                    >
                      Export .CSV
                    </button> */}
                  </div>
                </div>



                {/*
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary" onClick={showmessage}>
                    QR Code
                  </button>
                </div>
              </div>
              */}
              </div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-fixed"
                  striped
                  sortable={false}
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>

            <div className="card-body " hidden>
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-fixed"
                  striped
                  
                  paging ={false}
                  id = "table-to-xls"
                  sortable={false}
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Edit WIP_SST Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={
                        { backgroundColor: "#F0FFFF", border: "1px solid #ddd", padding: "10px", width: "100" }}
                      // value={amount.total}
                      displayType={"text"}
                      thousandSeparator={true}
                    />}

                  &nbsp;&nbsp;&nbsp; items </h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-4">
                    {/* <h3>Product No : {message.no}</h3> */}
                    <input
                      type="radio"
                      id="inbound"
                      name="edit_type"
                    // checked={amount.edit_type != true ? false : true}
                    // value={true}
                    // onChange={(e) => {
                    //   setamount({
                    //     ...amount,
                    //     edit_type: e.target.value === "false" ? false : true,
                    //   });
                    // }}
                    />

                    <label htmlFor="inbound">
                      <h4>  In bound</h4>
                    </label>
                  </div>

                  <div className="col-4">
                    {/* <h3>Product Name : 
                      {message.name}
                      </h3> */}

                    <div className="row"></div>

                    <input
                      type="radio"
                      id="outbound"
                      name="edit_type"
                    // checked={amount.edit_type != false ? false : true}
                    // value={false}
                    // onChange={(e) => {
                    //   setamount({
                    //     ...amount,
                    //     edit_type: e.target.value === "false" ? false : true,
                    //   });
                    // }}
                    />
                    <label htmlFor="outbound">
                      <h4>  Out bound</h4>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4">
                    <div className="form-group ">

                      <Select
                        options={end_option}
                        // ref={select_end}
                        // defaultValue={amount.end_user_id}
                        onChange={(e) => {
                          //console.log(e);
                          // if (e !== null) {
                          //   setamount({
                          //     ...amount,
                          //     end_user_id: e.value || "",
                          //   });
                          // }

                        }}
                      />



                      {/* <select
                     className="custom-select select2 form-control"
                     value={amount.end_user_id}
                     
                     onChange={(e) => {
                       setamount({
                         ...amount,
                         end_user_id: e.target.value,
                       });
                     }}
                   >
                     <option value="" disabled selected>
                       Select End_user
                     </option>
                     {end_user.map((el) => {
                       return <option value={el.id}>{el.name}</option>;
                     })}
                   </select> */}
                      <label htmlFor=""> Fuel Consumtion</label>
                    </div>
                  </div>
                  <div className="col-4"></div>
                  <div className="col-4"></div>
                  <div className="col-4">
                    <div className="form-group ">
                      <NumberFormat
                        format="################"
                        thousandSeparator={true}
                        type="text"
                        className="form-control"
                        // value={amount.amount}
                        // onChange={(e) => {
                        //   setamount({
                        //     ...amount,
                        //     amount: e.target.value,
                        //   });
                        // }}
                        required
                      />
                      <label htmlFor="">Amount In</label>{" "}
                    </div>
                  </div>

                  <div className="col-4"></div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                {/* <button
                  type="button"
                  className="btn btn-primary"
                  // onClick={Getamount}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Save Changes
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*   <Footter/> */}
    </div>
  );
}
export default Fuel_Consumtion;
