/******
โค้ดนี้เป็นส่วนหนึ่งของโปรเจ็กต์ React ที่ใช้เพื่อสร้างฉากการแสดง Warehouse 3D 
โดยใช้ไลบรารี Three.js สำหรับการแสดงภาพ 3 มิติและการจัดการกับองค์ประกอบต่าง ๆ ของฉาก
มีการ  
      Import model 3D อย่าง forklift, map เข้ามาเพื่อแสดงผล
      Import Components อย่าง socketClientModule เพื่อไว้ใช้เชื่อมต่อ socket io

ไลบรารี `three` เป็นไลบรารีที่ใช้สร้างและจัดการกับกราฟิกสามมิติ (3D) 
ใน JavaScript โดยใช้ WebGL พวกโมดูลต่าง ๆ ที่นำเข้ามานี้ เป็นอุปกรณ์เสริมที่ช่วยในการสร้างและควบคุมการทำงานขององค์ประกอบต่าง ๆ ของ three.js:

1. **GLTFLoader:** เป็นโมดูลที่ใช้โหลดและดึงข้อมูลจากไฟล์ GLTF (GL Transmission Format) ซึ่งเป็นรูปแบบไฟล์ที่ใช้เก็บข้อมูลแบบ 3D 
โมเดลและซีนสำหรับการแสดงผล มีความสามารถในการโหลดโมเดล 3D และวัตถุภายนอกมาใช้ในโปรแกรม three.js

2. **OrbitControls:** เป็นโมดูลที่ช่วยในการสร้างตัวควบคุมที่ใช้ในการจัดการกับกล้องในฉาก 3D 
เช่นการเลื่อนเลนส์ การซูม การหมุน หรือการควบคุมการมองเห็นของฉาก

3. **TrackballControls:** เป็นโมดูลที่ให้การควบคุมเพิ่มเติมสำหรับกล้อง 3D 
โดยให้ผู้ใช้สามารถปรับเปลี่ยนมุมมองและตำแหน่งของกล้องได้อย่างอิสระโดยไม่จำเป็นต้องใช้การควบคุมแบบธรรมดา

4. **CSS2DRenderer:** เป็น Renderer ที่ใช้ในการแสดง HTML elements ในฉาก three.js 
โดยจะทำการสร้าง layer ให้กับ HTML elements ภายในฉาก ทำให้สามารถแสดงเหตุการณ์การควบคุมต่าง ๆ ของ HTML ได้ในสภาพแวดล้อมสามมิติ
******/
import React, 
{ 
  useState, 
  useEffect, 
  useRef, 
  useLayoutEffect 
} from "react";
import axios from "axios";
import * as THREE from "three";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";
import Stats from "three/examples/jsm/libs/stats.module";
import forkliftModelPath from "../../assets/model/Forklift.glb";
import mapModelPath from "../../assets/model/merged-map.glb";
import rackModelPath from "../../assets/model/Rack.glb";
import { GUI } from 'dat.gui';
import "../../assets/css/Warehouse3D.css";
import socketClientModule from "../../component/socket/_client"
import { render } from "@testing-library/react";
import { reset } from "numeral";
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
import Configs from "../../config";
import { getToken } from "../../Utils/Common";
// import { Modal } from "antd";
// import ThreeScene from "./ThreeScene";

function Warehouse3D() {

  const [socketConnected, setSocketConnected] = useState(false);
  const [modalTest, setModalTest] = useState(false);
  const toggleModal = () => setModalTest(!modalTest);

  // Variables to store forklift position
  let model;
  let modelRef = React.useRef();
  let x = 0;
  let y = 0;
  let z = 8;

  // Variables to store performance measurement value
  const [socketLatency, setSocketLatency] = useState(0);
  const [fps, setFPS] = useState(0);
  let latency = 0;
  let frames = 0, prevTime = performance.now();

  // Variables to store Azimuthal Angle
  let angle = 0;
  let dir = new THREE.Vector3();

  // Variables to handle zoom method
  let zoomingIn = false;
  let zoomingOut = false;
  let animationFrameId;

  // Variables to store origin;
  let originX;
  let originZ;

  let boxHelper;

  // Reference instances
  const guiRef = useRef();
  const cubeRef = useRef();
  const canvasRef = useRef();
  const cameraRef = useRef();
  const rendererRef = useRef();
  const controlsRef = useRef();
  const trackballsRef = useRef();
  const compassRef = useRef();

  const labelRef = useRef();

  /*****************
  *****************/
  // Variables to store connection
  let socketx, socket_on_connected;
  const encryptTopicRef = useRef("16faafd0421b54a8f2f09cdfdd9c4d901a049a7b");
  const socketTokenRef = useRef("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsaWNlbnNlS2V5IjoibGljZW5zZUtleSIsImlhdCI6MTcwNDU4ODk3OCwiZXhwIjoxNzA3MTgwOTc4fQ.vcPBm7BYTFtcdPLPgHTXp42PVph39TchkaitITKGzjI");

  /*****************
  function socketConnect เป็นการเชื่อมต่อกับ Socket.io server โดยใช้ socketClientModule.setup เพื่อกำหนดการเชื่อมต่อ และตั้งค่าต่าง ๆ สำหรับการเชื่อมต่อ Socket.io
    การทำงานของโค้ดนี้ได้แก่:
    1.หนดการเชื่อมต่อ Socket.io: โดยใช้ socketClientModule.setup เพื่อเชื่อมต่อไปยังเซิร์ฟเวอร์ https://socketx.lailab.online 
    และช่องการเชื่อมต่อ /ble/location และตัว namespace /

    2.ตั้งค่าการเชื่อมต่อ: กำหนด query parameter เพื่อส่ง token และเตรียมค่าสำหรับการเชื่อมต่อ Socket.io

    3.การรับข้อมูลจาก Socket.io: ผ่านการใช้ socket.on('clientBox', function (data) { ... }); 
    สำหรับการรับข้อมูลที่ถูกส่งมาจากเซิร์ฟเวอร์ด้วย topic clientBox โดยจะมีการตรวจสอบและประมวลผลข้อมูลที่ได้รับมา โดยแบ่งประเภทข้อมูลต่าง ๆ ด้วย data.topic

  *****************/
  // Functions to Connect with Socket.
  function socketConnect() {
    let idx;
    var options = {};
    options.query = {
      token: socketTokenRef.current,
    };
    // console.log('socket conecting...');
    socketx = socketClientModule.setup(
      'https://socketx.lailab.online',
      '/ble/location',
      '/',
      (socket) => {
        setSocketConnected(socket.connected);
        socket_on_connected = socket;
        idx = 'user' + '_' + Math.floor(1000 + Math.random() * 9000) + '_' + Math.floor(1000 + Math.random() * 9000);
        socketClientModule.register(idx, socket);
        console.log('socket_on_connected', socket_on_connected);
        socketClientModule.join(`unai/${encryptTopicRef.current}/tag`, socket_on_connected);
        // console.log('socket.id : ', socket.id);
      },
      options
    );

    socketx.on('clientBox', function (data) {
      console.log('[data] :', data);

      if (typeof data === 'object' && data !== null) {
        if (data.topic != undefined || data.topic != 'NULL') {
          if (data?.topic?.includes('/tag')) {
            if(data){
              x = data.x * 2;
              z = Math.abs(data.y) * 2;
            }
            // calculateCumulatedTag(data);
          } else if (data?.topic?.includes('/anchor')) {
            // calculateCumulatedAnchor(data);
          }
        }
      } else {
        console.log('[clientBox] :', data);
      }
    });
  };
  
  async function requestGenToken() {
    let config = {
      method: "get",
      url:
        `${Configs.API_URL_IoT_Connect}` +
        "/IOT_Connect/genTokenIoTConnect",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      }
    };
    await axios.request(config)
    .then((response) => {
      encryptTopicRef.current = response.data.encrypt_topic;
      socketTokenRef.current = response.data.socket_token;
      // return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
  }

  // Functions to Request Encrypt Topic and Socket Token
  /* async function requestSocketToken() {
    const tokenResponse = await requestGenToken(); // Await the result here
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://rtls.lailab.online/gen_encrypt_topic',
      headers: {
          Authorization: tokenResponse.access_token, // Access the property after awaiting
          'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
          'floorID': '108'
      }
    };

    await axios.request(config)
    .then((response) => {
      console.log('response', response);
      // encryptTopicRef.current = response.data.encrypt_topic;
      // socketTokenRef.current = response.data.socket_token;
      // socketConnect();
    })
    .catch((error) => {
      console.log(error);
    });

  }; */

  // Functions to set zoom in state to true and request animation frame
  function startZoomIn() {

    zoomingIn = true;

    animationFrameId = requestAnimationFrame( onZoomIn );

  };

  // Functions to set zoom in state to false and cancel request animation frame
  function stopZoomIn() {
    zoomingIn = false;
  }

  // Functions to set zoom out state to true and request animation frame
  function startZoomOut() {

    zoomingOut = true;

    animationFrameId = requestAnimationFrame( onZoomOut );

  };

  // Functions to set zoom out state to false and cancel request animation frame
  function stopZoomOut() {
    zoomingOut = false;
  }

  // Functions to zoom in
  function onZoomIn() {

    if( zoomingIn ) {

      cameraRef.current.getWorldDirection(dir);
      cameraRef.current.position.addScaledVector(dir, 1.0);
      animationFrameId = requestAnimationFrame(onZoomIn);

    }

  };

  // Functions to zoom out
  function onZoomIn() {
    if (zoomingIn) {
      cameraRef.current.getWorldDirection(dir);
      cameraRef.current.position.addScaledVector(dir, 10.0);
    }
  }
  
  // Functions to zoom out
  function onZoomOut() {
    if (zoomingOut) {
      cameraRef.current.getWorldDirection(dir);
      cameraRef.current.position.addScaledVector(dir, -10.0);
    }
  }

  // Function reset camera to traditional position
  function resetControls() {

    const camera = cameraRef.current.position;
    const targetPosition = new THREE.Vector3(40, 40, 40);
    camera.set(40, 40, 40);

  }

  // Functions to return CSS Matrix
  function getCameraCSSMatrix(matrix) {
    var elements = matrix.elements;
    return 'matrix3d(' +
      epsilon(elements[0]) + ',' +
      epsilon(-elements[1]) + ',' +
      epsilon(elements[2]) + ',' +
      epsilon(elements[3]) + ',' +
      epsilon(elements[4]) + ',' +
      epsilon(-elements[5]) + ',' +
      epsilon(elements[6]) + ',' +
      epsilon(elements[7]) + ',' +
      epsilon(elements[8]) + ',' +
      epsilon(-elements[9]) + ',' +
      epsilon(elements[10]) + ',' +
      epsilon(elements[11]) + ',' +
      epsilon(elements[12]) + ',' +
      epsilon(-elements[13]) + ',' +
      epsilon(elements[14]) + ',' +
      epsilon(elements[15]) +
      ')';
  };

  // Functions to return epsilon
  function epsilon(value) {
    return Math.abs(value) < 1e-10 ? 0 : value;
  };

  // Functions to convert radians to degrees
  function radiansToDegrees(radians) {
    return radians * (180 / Math.PI);
  };

  function getRandomSeconds(min, max) {
    // Ensure that the input values are non-negative
    min = Math.max(0, min);
    max = Math.max(0, max);
  
    // Generate a random number within the specified range
    const randomSeconds = Math.random() * (max - min) + min;
  
    // Return the random number rounded to 2 decimal places
    return parseFloat(randomSeconds.toFixed(2));
  };

  function functest() {
    setInterval(() => {
      z += Math.random() * 2 - 1;
      x += 0.1;
      console.log('x :>> ', x);
      console.log('y :>> ', z);
    }, 200)
  }

  useEffect(async () => {
    await requestGenToken();
    socketConnect();
    // functest();
  

    // Initialize instances
    const renderer = new THREE.WebGLRenderer( { antialias: true } );
    const labelRenderer = new CSS2DRenderer();
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    const controls = new OrbitControls(camera, renderer.domElement);
    const trackballs = new TrackballControls(camera, renderer.domElement);
    const loader = new GLTFLoader();
    const destination = new THREE.Vector3(0, 0, 0);

    // Assign useRef
    controlsRef.current = controls;
    trackballsRef.current = trackballs;
    cameraRef.current = camera;

    // Set up Renderer
    const container = document.getElementById('content-wrapper');
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.VSMShadowMap;
    canvasRef.current.appendChild(renderer.domElement);


    // Set up Background
    scene.background = new THREE.Color( 0xDED5FF );
    // scene.fog = new THREE.Fog( 0xDED5FF, 60 , 200 );

    // Set up Lights
		const ambient = new THREE.HemisphereLight( 0xffffff, 0xffffff, 1 );
    ambient.position.set( 0, 20, 0 );
		scene.add( ambient );
    // ambient.castShadow = true;

    /*************
    โค้ดนี้ใช้สร้างแสงแบบ Directional Light ใน Three.js โดยกำหนดค่าต่าง ๆ เพื่อให้แสงที่สร้างขึ้นมีลักษณะเฉพาะที่เหมาะสมกับฉากสามมิติที่กำลังถูกสร้างขึ้น:

      1. `const dirLight = new THREE.DirectionalLight( 0xffffff, 3 );` สร้างแสงแบบ Directional Light ด้วยสีขาว `0xffffff` และ intensity เท่ากับ 3.

      2. `dirLight.color.setHSL( 0.1, 1, 0.95 );` กำหนดค่าสีของแสงโดยใช้ HSL (Hue, Saturation, Lightness) โดยกำหนดค่า Hue เป็น 0.1, Saturation เป็น 1, และ Lightness เป็น 0.95 ซึ่งจะกำหนดความสว่างและแสงสีของแสงได้ตามต้องการ

      3. `dirLight.position.set( -1, 1.75, 1 );` กำหนดตำแหน่งของแสงที่ถูกสร้างขึ้น

      4. `dirLight.position.multiplyScalar( 30 );` เพิ่มขนาดของแสงโดยการคูณตำแหน่งด้วยค่า scale factor เพื่อเพิ่มขนาดของแสง

      5. `scene.add( dirLight );` เพิ่มแสงที่สร้างขึ้นไปยังฉาก scene ของ Three.js

      6. ค่าต่าง ๆ ที่ถูกตั้งค่าเกี่ยวกับการสร้างเงาของแสง (shadow):
        - `dirLight.castShadow = true;` เปิดใช้งานการสร้างเงาจากแสง
        - `dirLight.shadow.camera` กำหนดค่าต่าง ๆ ของกล้องที่ใช้สำหรับการสร้างเงา เช่น ระยะทางที่เปิดให้เริ่มต้น (`near`), ระยะทางสูงสุด (`far`), และพื้นที่ที่กล้องมองเห็น (`right`, `left`, `top`, `bottom`)
        - `dirLight.shadow.mapSize.width` และ `dirLight.shadow.mapSize.height` กำหนดขนาดของแผนที่ที่ใช้สำหรับสร้างเงา
        - `dirLight.shadow.radius` กำหนดรัศมีของเงา
        - `dirLight.shadow.blurSamples` กำหนดจำนวนตัวอย่างที่ใช้สำหรับการบลัร์เงา
        - `dirLight.shadow.bias` ช่วยปรับค่าเบียส์ในกรณีที่เงาอาจจะมีปัญหาการซ้อนทับหรือเกิดเรียบตัวได้ โดยการปรับค่าตัวเลขนี้
    **************/
    const dirLight = new THREE.DirectionalLight( 0xffffff, 3 );
		dirLight.color.setHSL( 0.1, 1, 0.95 );
		dirLight.position.set( -1, 1.75, 1 );
		dirLight.position.multiplyScalar( 30 );
		scene.add( dirLight );

    dirLight.castShadow = true;
    dirLight.shadow.camera.near = 0.1;
    dirLight.shadow.camera.far = 500;
    dirLight.shadow.camera.right = 70;
    dirLight.shadow.camera.left = - 50;
    dirLight.shadow.camera.top	= 50;
    dirLight.shadow.camera.bottom = - 70;
    dirLight.shadow.mapSize.width = 512;
    dirLight.shadow.mapSize.height = 512;
    dirLight.shadow.radius = 8;
    dirLight.shadow.blurSamples = 25;
    dirLight.shadow.bias = - 0.0005;

    // scene.add( new THREE.CameraHelper( dirLight.shadow.camera ))

    /*******
    โค้ดนี้เกี่ยวกับการตั้งค่ากล้องและการควบคุม (controls) ใน Three.js:

      1. `camera.position.set( 50, 50, 50 );` กำหนดตำแหน่งของกล้องในฉาก 3D ที่ถูกสร้างขึ้น ที่ตำแหน่ง x = 50, y = 50, z = 50

      2. `camera.layers.enableAll();` เปิดใช้งานเลเยอร์ทั้งหมดสำหรับกล้อง

      3. การกำหนดค่า controls เพื่อควบคุมการแสดงผลของฉาก:
        - `controls.enableDamping = true;` เปิดใช้งานการทำการหยุดทันทีของการเคลื่อนไหวเมื่อหยุดให้มีความสมจริง
        - `controls.dampingFactor = 0.12;` กำหนดค่า factor ของการหยุดทันทีเมื่อหยุดให้มีความสมจริง
        - `controls.enableZoom = false;` ปิดการใช้งานฟังก์ชันซูม
        - `controls.enablePan = false;` ปิดการใช้งานฟังก์ชันพาน
        - `controls.minDistance`, `controls.maxDistance` กำหนดระยะทางที่กล้องสามารถเคลื่อนที่ได้
        - `controls.minPolarAngle`, `controls.maxPolarAngle` กำหนดการหมุนแนวตั้งของกล้อง
        - `controls.minAzimuthAngle`, `controls.maxAzimuthAngle` กำหนดการหมุนแนวนอนของกล้อง

      4. การกำหนดค่า trackballs:
        - `trackballs.noRotate = true;` ปิดการหมุนของ trackballs
        - `trackballs.noPan = true;` ปิดฟังก์ชันการพานของ trackballs
        - `trackballs.noZoom = false;` เปิดใช้งานการซูมของ trackballs
        - `trackballs.minDistance`, `trackballs.maxDistance` กำหนดระยะทางที่ trackballs สามารถซูมได้
        - `trackballs.zoomSpeed = 1.5;` กำหนดความเร็วในการซูมของ trackballs ที่ระบุไว้ในที่นี้คือ 1.5

      โดยการตั้งค่ากล้องและการควบคุมนี้ช่วยในการเปลี่ยนแปลงมุมมองของฉาก 3D 
      และการทำงานกับฉากให้ผู้ใช้สามารถจัดการกับมุมมองและการแสดงผลได้อย่างหลากหลายและตอบสนองตามความต้องการของแต่ละแอพพลิเคชั่น.
    *******/
    // Set up Camera
    camera.position.set( 50, 50, 50 );
    camera.layers.enableAll();

    // Configuration Controls
    controls.enableDamping = true;
    controls.dampingFactor = 0.12;
    controls.enableZoom = false;
    controlsRef.current = controls;
    controls.enablePan = false;
    controls.minDistance = 20;
    controls.maxDistance = 70;
    controls.minPolarAngle = 0;
	  controls.maxPolarAngle = 1.5; 
    controls.minAzimuthAngle = - Infinity;
	  controls.maxAzimuthAngle = Infinity;

    trackballs.noRotate = true;
    trackballs.noPan = true;
    trackballs.noZoom = false;
    trackballs.minDistance = 20;
    trackballs.maxDistance = 70;
    trackballs.zoomSpeed = 1.5;

    
    // const rackPositions = [
    //   { position: new THREE.Vector3(4, 0, 0), tag: 2024 },
    //   { position: new THREE.Vector3(30, 0, 0), tag: 2025 },
    //   { position: new THREE.Vector3(30.72, 0, 0), tag: 2026 },
    //   { position: new THREE.Vector3(30.48, 0, 0), tag: 2027 },
    // ];
  //   rackPositions.forEach(pos => {

  //   loader.load(rackModelPath, function(gltf) {

  //     let rack = gltf.scene
  //     rack.traverse(function (child) {
  //       if (child.isMesh) {
  //         child.castShadow = true;
  //       }
  //     });
      
  //     rack.position.copy(pos.position);
  //     rack.scale.set(2, 1, 2);
  //     scene.add( rack );
  //   });
      
  // });

  // const multiply = 2;

  //   const anchorPositions = [
  //     { position: new THREE.Vector3(0 * multiply, 5.95 * multiply, 11.9 * multiply), tag: 2024 },
  //     { position: new THREE.Vector3(0 * multiply, 6.14 * multiply, 0.15 * multiply), tag: 2025 },
  //     { position: new THREE.Vector3(12.04 * multiply, 5.9 * multiply, 0.15 * multiply), tag: 2026 },
  //     { position: new THREE.Vector3(12.04 * multiply, 5.79 * multiply, 11.9 * multiply), tag: 2027 },
  //     { position: new THREE.Vector3(23.09 * multiply, 5.9 * multiply, 0.15 * multiply), tag: 2028 },
  //     { position: new THREE.Vector3(23.09 * multiply, 4.5 * multiply, 11.9 * multiply), tag: 2029 },
  //   ];

  //   anchorPositions.forEach(pos => {
  //     // Create a pillar

    
  //     // Create a box (you can keep this if needed)
  //     const box = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 0.5), new THREE.MeshPhongMaterial({ color: 0x439CEF }));
  //     box.position.copy(pos.position);
  //     scene.add(box);
  //   });


    /***********
    โค้ดนี้สร้างพื้นที่ดินหรือพื้นผิวราบในฉาก 3D ด้วย Three.js:

      1. `const ground = new THREE.Mesh(...);` สร้าง `Mesh` ซึ่งเป็นออบเจ็กต์ที่ใช้ในการแสดงวัตถุ โดยใช้ `PlaneGeometry` ในขนาด 500x500 พร้อมกับ `MeshStandardMaterial` ที่กำหนดสีเป็น 0xFBECFF (สีม่วงอ่อน).

      2. `ground.rotation.x = -Math.PI / 2;` หมุนวัตถุเป็นแนวแกน x แบบแทบ 90 องศา (ปรับมุมของพื้นผิวให้อยู่แนวนอน).

      3. `ground.position.y = -0.98;` กำหนดตำแหน่งในแกน y เพื่อวางพื้นผิวลงในแนวแกน y ที่ความสูง -0.98 (ในกรณีนี้อาจจะมีการแทรกลงด้านล่างของฉาก).

      4. `ground.receiveShadow = true;` ทำให้พื้นผิวสามารถรับแสงและแสดงเงาได้ (ใช้สำหรับฉากที่มีการใช้แสงและเงา).

      5. `scene.add(ground);` เพิ่มพื้นผิวดินลงในฉาก 3D.

      โค้ดนี้สร้างพื้นที่ดินหรือพื้นผิวราบซึ่งสามารถใช้เป็นพื้นหรือพื้นที่วางวัตถุต่างๆ ในฉาก 3D และใช้เงื่อนไขการรับแสงและแสดงเงาเพื่อเข้าใจการประยุกต์ใช้ในฉากที่มีการจัดการกับแสงและเงาได้ด้วย Three.js.
    ************/
    // Create Ground Geometry
    const ground = new THREE.Mesh(new THREE.PlaneGeometry(500, 500, 10, 10), new THREE.MeshStandardMaterial(
      { 
        color: 0xFBECFF
      })
      );
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -0.98;
    ground.receiveShadow = true;
    scene.add(ground);

    /*****
    โค้ดนี้ใช้ `GLTFLoader` ใน Three.js เพื่อโหลดโมเดลของ forklift หรือรถยกเข้าสู่ฉาก 3D:

      1. `loader.load(forkliftModelPath, function(gltf) { ... });` โหลดโมเดล forklift จากที่อยู่ที่ระบุใน `forkliftModelPath` โดยใช้ `GLTFLoader`. เมื่อโมเดลโหลดเสร็จสิ้น จะเรียกใช้ฟังก์ชันที่ระบุ (callback function) และส่งข้อมูล glTF มาให้.

      2. `model = gltf.scene;` เก็บออบเจกต์ของโมเดล (ในรูปแบบของ `Object3D`) ไว้ในตัวแปร `model`.

      3. `model.traverse(function (child) { ... });` วนลูปผ่านทุกๆ child (ลูกโมเดล) ของโมเดลเพื่อตั้งค่าคุณสมบัติที่เกี่ยวข้องกับเงา (`castShadow`) ให้กับทุกๆ mesh ภายในโมเดล (child.isMesh).

      4. `model.scale.set(1, 1, 1);` ตั้งขนาดของโมเดลให้มีขนาด 1x1x1 เป็นค่าเริ่มต้น.

      5. `scene.add( model );` เพิ่มโมเดล forklift เข้าไปในฉาก 3D.

      โค้ดนี้โหลดโมเดล forklift และเพิ่มลงในฉาก 3D และยังตั้งค่าให้โมเดลนี้สามารถสร้างเงาได้ด้วยการตั้งค่า `castShadow` ในทุกๆ mesh ภายในโมเดลไว้ในส่วนของ `model.traverse`.
    *****/
    // Load Forklift Model
    loader.load(forkliftModelPath, function(gltf) {
      // console.log('gltf', gltf);
      modelRef.current = gltf.scene
      model = gltf.scene
      // console.log('model', model);
      model.traverse(function (child) {
        // console.log('child', child);
        // if (child.isMesh) {
        //   child.castShadow = true;
        // }
      });

      // model.scale.set(1, 1, 1);
      scene.add( model );
      /* boxHelper = new THREE.BoxHelper(model, 0xffff00);
      boxHelper.position.y = 30
      scene.add(boxHelper); */
      
    });
    // console.log('model', model);
    /*****
    เป็นการโหลดโมเดล map และเพิ่มลงในฉาก 3D:

      loader.load(mapModelPath, function(gltf) { ... }); โหลดโมเดล map จากที่อยู่ที่ระบุใน mapModelPath โดยใช้ GLTFLoader. เมื่อโมเดลโหลดเสร็จสิ้น จะเรียกใช้ฟังก์ชันที่ระบุ (callback function) และส่งข้อมูล glTF มาให้.

      map = gltf.scene; เก็บออบเจกต์ของโมเดล (ในรูปแบบของ Object3D) ไว้ในตัวแปร map.

      map.traverse(function (child) { ... }); วนลูปผ่านทุกๆ child (ลูกโมเดล) ของโมเดลเพื่อตั้งค่าคุณสมบัติที่เกี่ยวข้องกับเงา (castShadow) ให้กับทุกๆ mesh ภายในโมเดล (child.isMesh).

      map.scale.set(0.462, 0.5, 0.5); ตั้งขนาดของโมเดลให้มีขนาดตามที่กำหนด (0.462, 0.5, 0.5).

      map.position.set(24, 0, 12); กำหนดตำแหน่งของโมเดลในฉาก 3D ที่ตำแหน่ง x = 24, y = 0, z = 12.

      scene.add( map ); เพิ่มโมเดล map เข้าไปในฉาก 3D.

      โค้ดนี้โหลดโมเดล map และเพิ่มลงในฉาก 3D และยังตั้งค่าให้โมเดลนี้สามารถสร้างเงาได้ด้วยการตั้งค่า castShadow ในทุกๆ mesh ภายในโมเดลไว้ในส่วนของ map.traverse.
    *****/
    let map;

    loader.load(mapModelPath, function(gltf) {

      map = gltf.scene
      map.traverse(function (child) {
        if (child.isMesh) {
          child.castShadow = true;
        }
      });

      map.scale.set(0.462, 0.5, 0.5);
      map.position.set(24, 0, 12);
      scene.add( map );
      // boxHelper = new THREE.BoxHelper(model, 0xffff00);
      // scene.add(boxHelper);


    });


    // Add Axes Helper
    const axesHelper = new THREE.AxesHelper( 15 );
    scene.add( axesHelper );

    /* *****
    สร้างพื้นหรือพื้นผิว (floor) ในฉาก 3D โดยใช้ BoxGeometry 
    ซึ่งเป็นเรซเทียนกล่องสี่เหลี่ยมที่มีขนาดความยาว 48 หน่วย (24 * 2), ความสูง 1 หน่วย, และความกว้าง 24 หน่วย (12 * 2) 
    เพื่อแสดงเป็นพื้นของฉาก 3D โดยใช้วัสดุ MeshStandardMaterial ที่กำหนดสีเป็นสีชมพูอ่อน โดยการตั้งค่า opacity เป็น 0.1 
    เพื่อให้สามารถมองเห็นผ่านได้บางส่วน และกำหนดให้มีการรับแสงและส่งแสง (receiveShadow และ castShadow) ตามลำดับเพื่อเข้าใจว่าพื้นที่นี้เป็นส่วนที่เป็นพื้นหรือพื้นผิวจริง

    นอกจากนี้ยังมีการสร้าง extendFloor ที่เป็นส่วนขยายของพื้นที่ โดยใช้เช่นกัน BoxGeometry 
    และ MeshStandardMaterial ซึ่งได้กำหนดให้มีขนาดความยาว 36 หน่วย (18 * 2), ความสูง 0.5 หน่วย, และความกว้าง 24 หน่วย (12 * 2) 
    โดยให้มีสีและความโปร่งใสที่แตกต่างกันจากพื้นหลักเพื่อแสดงเป็นส่วนขยายของพื้นที่นั้นๆในฉาก 3D
    ***** */
    // Create Floor
    // ** Temporary **
    const mapWidth = 1632.11;
    const mapHeight = 773.78;
    originX = 672.9718236488109;
    originZ = 179.46242873150362;
    const pixelMeter = 35.49974930239615;
    originX = 672.9718236488109 / pixelMeter;
    originZ = 179.46242873150362 / pixelMeter;

    // Create a BoxGeometry for the floor with depth
    const floorGeometry = new THREE.BoxGeometry(24 * 2, 1, 12 * 2);
    const floorMaterial = new THREE.MeshStandardMaterial({
      color: 0xFBF1FF,
      side: THREE.DoubleSide,
      transparent: false,
      opacity: 0.1,
    });
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);

    // Floor Configuration
    floor.position.set(24, 0, 12);
    controls.target.set(24, 0, 12);
    floor.receiveShadow = true;
    floor.castShadow = true;
    floor.position.y = -0.5;
    floor.rotation.x = 0;

    const extendFloor = new THREE.Mesh( new THREE.BoxGeometry(18 * 2, 0.5, 12 * 2), new THREE.MeshStandardMaterial( { color: 0xFBECFF ,transparent:true, opacity: 0.8 } ) );
    extendFloor.position.set(-18, -0.3, 12);

    scene.add(extendFloor);

    // Create Wall Geometry
    // ** Temporary **
    // const wallHeight = 10;
    // const wallDepth = 1;

    // const wallX1 = createPercentageTank(floorWidthSegments + wallDepth, wallHeight, wallDepth, 0xffffff, 0x2F7AE5, 0xffffff, 40);
    // wallX1.position.set(-wallDepth / 2, -1, -floorHeightSegments / 2 - wallDepth / 2);
    // wallX1.castShadow = true;
    // wallX1.receiveShadow = true;

    // const wallY1 = createPercentageTank(floorHeightSegments, wallHeight, wallDepth, 0xffffff, 0x2F7AE5, 0xffffff, 40);
    // wallY1.position.set(-floorWidthSegments / 2 - wallDepth / 2, -1, 0);
    // wallY1.rotation.y = Math.PI / 2;
    // wallY1.castShadow = true;
    // wallY1.receiveShadow = true;

    // scene.add(wallX1);

    
    // const geometry = new THREE.BoxGeometry( 1, 1, 1 ); 
    // const material = new THREE.MeshBasicMaterial( {color: 0x439CEF} ); 
    // const cube = new THREE.Mesh( geometry, material ); 
    // cube.scale.set(9, 2, 2);
    // cube.position.set(6, 0.5, 9);
    // cube.castShadow = true;
    // scene.add( cube );

    // const cube2 = new THREE.Mesh( geometry, material ); 
    // cube2.scale.set(6, 2, 2);
    // cube2.position.set(15.5, 0.5, 9);
    // cube2.castShadow = true;
    // scene.add( cube2 );

    // const cube3 = new THREE.Mesh( geometry, material ); 
    // cube3.scale.set(15, 2, 6);
    // cube3.position.set(29, 0.5, 9);
    // cube3.castShadow = true;
    // scene.add( cube3 );


   

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    /* *****
    นี่คือสิ่งที่ฟังก์ชัน animate() ทำ:

      requestAnimationFrame(animate): คำขอให้บราวเซอร์เรียก animate() อีกครั้งในเฟรมถัดไป เพื่อทำการทำซ้ำและสร้างอนิเมชันของฉาก 3D

      การคำนวณค่า angle จากการแปลงมุมระหว่างกล้องและตัวควบคุม controls.getAzimuthalAngle() และทำการแสดงค่ามุมนั้นในแผนที่ผ่าน compassRef.current.style.transform

      การอัพเดตตำแหน่งของ model ที่มีการกำหนดไว้เพื่อเคลื่อนที่ที่ตัวแปร destination โดยใช้ lerp() เพื่อทำให้เกิดการเคลื่อนที่เรียบขึ้น

      การตรวจสอบและปรับเป้าหมายที่กำหนดให้กับ model เพื่อให้หันไปที่จุดหมายที่กำหนดไว้

      การนับเฟรมและคำนวณ FPS (เฟรมต่อวินาที) และปรับปรุงค่านี้ในการแสดงผล

      การอัพเดตตำแหน่งและการทำงานของ controls และ trackballs ซึ่งเป็นเครื่องมือสำหรับควบคุมการเคลื่อนที่และมุมมองของกล้องในฉาก 3D

      การทำการเรนเดอร์ฉากใหม่โดยใช้ renderer.render(scene, camera) เพื่อให้สามารถแสดงผลสิ่งที่อัพเดตแล้วในฉาก 3D และกล้อง

      การทำงานของฟังก์ชัน animate() นี้เป็นส่วนสำคัญที่ทำให้ฉาก 3D มีการเคลื่อนไหวและการอัพเดตโดยต่อเนื่องในแอปพลิเคชัน
    ***** */
    function animate() {

      requestAnimationFrame(animate);

      angle = radiansToDegrees(controls.getAzimuthalAngle());

      destination.set(Number(x), 0, Number(z));

      cameraRef.current.updateProjectionMatrix();

      compassRef.current.style.transform = `rotate(${angle}deg)`;

      // cubeRef.current.style.transform = `translateZ(-300px) ${getCameraCSSMatrix(camera.matrixWorldInverse)}`;

      const time = performance.now();

      SetConfigForkliftModel();

      if (model) {
        // console.log('destination', destination);
        model.position.lerp(destination, 0.1);

        if( Math.abs(model.position.x - destination.x) > 0.1 ) {
          model.lookAt(destination);
        }

      }



      frames ++;
      
      if ( time >= prevTime + 1000 ) {
      
        setFPS( Math.round( ( frames * 1000 ) / ( time - prevTime ) ) );
        
        frames = 0;
        prevTime = time;
        
      }
      
      const target = controls.target;
      controls.update();
      trackballs.target.set(target.x, target.y, target.z);
      trackballs.update();
      renderer.render(scene, camera);

    };
    

    window.addEventListener('resize', onWindowResize);

    animate();

    // Dispose when components unmounts
    return () => {
      window.removeEventListener('resize', onWindowResize);
      renderer.clear();
      renderer.dispose();

      if (trackballsRef.current) {
        trackballsRef.current.dispose();
      }

    };
  }, []);

  let arr_child_name = [
    "Circle004_32",
    /* "Circle004_24",
    "Circle004_25",
    "Circle004_26", */
    // "Circle004_27",
    // "Circle004_28",
    "Circle004_29",
]
  let x_ = 10
  function SetConfigForkliftModel() {
    if (modelRef.current) {
      
      // modelRef.current.geometry.center()

      modelRef.current.traverse(function (child) {
        // console.log('in', child.name == "Circle004_15");
        if (child.isMesh && arr_child_name.includes(child.name)) {
          /* child.position.y = x_;
          child.position.z = -x_+ 1;
          child.position.x = 0.8; */
        }
        if (child.isMesh) {
          // child.position.x = -40;
        }
      });
      /* modelRef.current.rotation.x = Math.PI * 2
      modelRef.current.position.x = 40 */
      // modelRef.current.position.y = 10
      // modelRef.current.rotation.y = Math.PI

    }
  }
  // console.log('modelRef.current', modelRef.current); 



  // Return Content
  return (
    <div id="content-wrapper" className="content-wrapper">

      {/* <section className="content-header">
        <div id="title-panel" className="title-panel">
          <div className="row mb-2">
            <div className="col-sm-6">
              <div className="monitor_title">
                <div className="title"><h1>Warehouse <span>3D</span></h1></div>
              </div>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/Welcome">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/IOT_Connect">IOT Connect</a>
                </li>
                <li className="breadcrumb-item active">Warehouse3D</li>
              </ol>
            </div>
          </div>
        </div>
      </section> */}


      <div className="container-fluid">
        
        <div className="scene-container">

          <div id="title" className="col-sm-6">
            <div className="monitor_title">
              <div className="title"><h1>Warehouse <span>3D</span></h1></div>
            </div>
          </div>

          <div id="performance-panel" class="performance-panel">
            <p id="fps">fps: {fps}</p>
            <p id="latency">latency: {socketLatency > 0 ? `${socketLatency}ms` : '???'}</p>
            <p id="status">Status: {socketConnected ? 'Connected' : 'Not Connected'}</p>
          </div>

          <div id="compass" class="compass" ref={compassRef}>
            <div id="north" class="north"></div>
            <div id="south" class="south"></div>
          </div>

          {/* <div id="breadcrumb" className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/IOT_Connect">IOT Connect</a>
                  </li>
                  <li className="breadcrumb-item active">Warehouse3D</li>
                </ol>
            </div> */}

          {/* <div id="cube-icon" className="col-span-8">
            <div className="cube " ref={cubeRef}>
              <div class="cube__face cube__face--front">front</div>
              <div class="cube__face cube__face--back">back</div>
              <div class="cube__face cube__face--right">right</div>
              <div class="cube__face cube__face--left">left</div>
              <div class="cube__face cube__face--top">top</div>
              <div class="cube__face cube__face--bottom">bottom</div>
            </div>
          </div> */}

          <div className="card" id="yourCanvasContainerID" ref={canvasRef}></div>

          <div className="label" id="label" ref={labelRef}></div>

          <div className="extend-panel">
          <button 
              id="zoom-out-button"
              className="zoom-out-button" 
              onMouseDown={startZoomOut}
              onMouseUp={stopZoomOut}
              onMouseLeave={stopZoomOut}
              >
              <b>－</b>
            </button>
            <button
              id="zoom-in-button"
              className="zoom-in-button"
              onMouseDown={startZoomIn}
              onMouseUp={stopZoomIn}
              onMouseLeave={stopZoomIn}
            >
              <b>＋</b>
            </button>
            <button
              id="reset-zoom-button"
              className="reset-zoom-button"
              onClick={resetControls}
            >
              <b>⟳</b>
            </button>
            {/* <button
              id="reset-zoom-button"
              className="reset-zoom-button"
              onClick={toggleModal}
            >
              <b>M</b>
            </button> */}
          </div>

          <div ref={guiRef} style={{ position: 'absolute', bottom: 20, left: 0, zIndex: 1000}} />
        </div>
        
        {/* <Modal
            title={
              <div
                style={{
                  color: "#00AF43",
                  fontWeight: 600,
                  fontFamily: "Kanit",
                  width: "400px",
                  height: "44pxs",
                  lineHeight: "43.85px",
                  fontSize: "25.33px",
                  margin: "-15px 0 0 0",
                }}
              >
                Test THREE
              </div>
            }
            open={modalTest}
            onCancel={toggleModal}
            width={1100}
            bodyStyle={{ height: '450px',overflow: 'auto' }}
            footer={[
              <>

                <button
                  key="back"
                  id="btns-cancle"
                  className="bnt btn-danger"
                  style={{
                    color: "#fff",
                    border: "none",
                    fontWeight: "19px",
                    fontFamily: "Kanit",
                    width: "80px",
                    height: "35px",
                    border: "none",
                    outline: "none",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  ยกเลิก
                </button>
              </>
            ]}
          >
          <div>
            <ThreeScene />
          </div>
          </Modal> */}
      </div>
    </div>
  );
}

export default Warehouse3D;
