import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import TableLEK from "../../component/tableLEKTTT/table";
import Select from "react-select";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  fixOEMForSST,
  getAcademy,
} from "../../Utils/Common";

function Customer(params) {
  const [customer, setCustomer] = useState([]);
  const [company, setCompany] = useState([]);
  const [companyGroup, setCompanyGroup] = useState([]);
  const [defaultSelect, setDefaultSelect] = useState({ label: "", value: "" });
  const [feature, setfeature] = useState([]);
  const [relate_product, setRelate_product] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);

  const [test, settest] = useState({
    test: '',
  });

  const [filler_customer, setFiller_customer] = useState({
    name: "",
    relate_product: "",
    company_id: getUser().com,
    companyGroup_id: getOem(),
    all_search:''
  });
  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetCustomer = async (page, size, search) => {
    /*  console.log(filler_customer); */
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }
    let dataseting = {
      name: (filler_customer.name || '').trim(),
      relate_product: filler_customer.relate_product,
      company_id:  filler_customer.company_id,
      companyGroup_id:  filler_customer.companyGroup_id,
      all_search: (filler_customer.all_search || '').trim()
    } 
    
    dataseting.page = page
    dataseting.size = size
    axios({
      method: "post",
      url: Configs.API_URL + "/customer/filter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: dataseting,
    })
      .then(function (response) {
        //console.log(response.data);
    console.log();
        setCustomer(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        Swal.close();
        console.log(error);
      });
  };

  const GetRelate_product = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL + "/customer/get_relate_product",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        //console.log(response.data)
        await setRelate_product(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function GetOem_data(params) {
    var config_getOem = {
      method: "get",
      url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    };
    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
          if (el.id === getOem()) {
            /*    console.log("oem",el.name); */
            setDefaultSelect({ label: el.name, value: el.id });
            defaultSelect.label = el.name;
            defaultSelect.value = el.id;
            setFiller_customer({ ...filler_customer, companyGroup_id: el.id });
          }
        });

        /*     console.log(options); */
        setCompanyGroup(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Customer";
    });
    setfeature(feature_data);
    ////////permission feature//////////
    GetCustomer(1, 10, 'search');
    GetOem_data();
    GetCompany();
    GetRelate_product();

    /*   setFiller_customer(); */
  }, []);

  const clearFilter = async () => {
    await setFiller_customer({
      ...filler_customer,
      name: "",
      relate_product: "",
      all_search: "",
      companyGroup_id: getOem(),
    });

    /*     setDefaultSelect({label: el.name,value:el.id}); */

    filler_customer.name = "";
    filler_customer.all_search = "";
    filler_customer.relate_product = "";
    filler_customer.companyGroup_id = getOem();
    /*     selectCompanyGroup.current.select.clearValue(); */

    GetCustomer(1, 10, 'search');
    GetOem_data();
    GetCompany();
    GetRelate_product();
  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Customer/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/customer/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Customer";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];

  for (var index = 0; index < customer.length; index++) {
    //console.log(rowsData);
    const rowItem = {};
  /*   rowItem["no"] = index + 1; */
    rowItem["Cus_name"] = customer[index].name;
    rowItem["full_name"] = customer[index].full_name;
    rowItem["Cus_detail"] = customer[index].detail;
    rowItem["Contact_name"] = customer[index].contact_name;
    rowItem["Cus_contact_tel"] = (
      <>
        <a href={"tel:" + customer[index].contact_tel}>
          {customer[index].contact_tel}
        </a>
      </>
    );
    rowItem["Cus_contact_fax"] = customer[index].contact_fax;
    rowItem["line"] = (
      <a
        target="_blank"
        href={" https://line.me/ti/p/~" + customer[index].line_id}
      >
        {customer[index].line_id}
      </a>
    );
    rowItem["Cus_email"] = customer[index].customer_email;
    //console.log(relate_product);
    let relate_product_temp = relate_product.filter((e) => {
      if (e.customer_id === customer[index].id) {
        return e.no;
      }
    });
    if (relate_product_temp.length > 0) {
      var relate_product_n = "";

      /* console.log("here relate"); */

      for (let r = 0; r < relate_product_temp.length; r++) {
        relate_product_n += relate_product_temp[r].no;
        if (r < relate_product_temp.length - 1) {
          relate_product_n += ", ";
        }
        if (r === 2) {
          relate_product_n += "... ";
          break;
        }
      }
      rowItem["Relate_Product"] = relate_product_n;
    } else {
      rowItem["Relate_Product"] = "";
    }

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Customer/read/" +
              customer[index].id
            }
            key={customer[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>

        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Customer/edit/" +
              customer[index].id
            }
            key={customer[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={customer[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, customer[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  const [headTable, setheadTable] = useState(
    [
      {
        label: "Customer No",
        field: "Cus_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Full Name",
        field: "full_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Detail",
        field: "Cus_detail",
        sort: "asc",
        width: 50,
      },
      /*  {
        label: "Contact_name",
        field: "Contact_name",
        sort: "asc",
        width: 50,
      }, */

      {
        label: "Tel",
        field: "Cus_contact_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Fax",
        field: "Cus_contact_fax",
        sort: "asc",
        width: 50,
      },
      {
        label: "Email",
        field: "Cus_email",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line",
        field: "line",
        sort: "asc",
        width: 50,
      },
      {
        label: "Relate Product",
        field: "Relate_Product",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ]
  );

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },

      {
        label: "Customer No",
        field: "Cus_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Full Name",
        field: "full_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Detail",
        field: "Cus_detail",
        sort: "asc",
        width: 50,
      },
      /*  {
        label: "Contact_name",
        field: "Contact_name",
        sort: "asc",
        width: 50,
      }, */

      {
        label: "Tel",
        field: "Cus_contact_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Fax",
        field: "Cus_contact_fax",
        sort: "asc",
        width: 50,
      },
      {
        label: "Email",
        field: "Cus_email",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line",
        field: "line",
        sort: "asc",
        width: 50,
      },
      {
        label: "Relate Product",
        field: "Relate_Product",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  const selectCompanyGroup = useRef();
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="customer" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Customer{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Customer</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_customer.all_search}
                    onChange={(e) => {
                      setFiller_customer({
                        ...filler_customer,
                        all_search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_customer.name}
                    onChange={(e) => {
                      setFiller_customer({
                        ...filler_customer,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Customer No</label>{" "}
                </div>
              </div>

              {/* {feature.map((e) => {
                if (e.feature === "Group_Company") {
                  return (
                    <> */}

              {/* <div className="col-md-2" style={{ "z-index": "99" }}>
                        <div className="form-group ">
                          <Select
                          defaultValue={defaultSelect}
                            options={companyGroup}
                            isDisabled={true}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setFiller_customer({
                                  ...filler_customer,
                                  companyGroup_id: e.value,
                                });
                              }
                            }}
                          />
                          
                          <label> กลุ่มบริษัท </label>
                        </div>
                      </div> */}
              {/*  </>
                  );
                }
              })} */}

              {/* <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                     value={filler_customer.relate_product_id}
                    onChange={(e) => {
                      setFiller_customer({
                        ...filler_customer,
                        relate_product_id: e.target.value,
                      });
                    }}   
                  />
                  <label htmlFor="">Relate Product</label>{" "}
                </div>
              </div> */}

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetCustomer(1, 10, 'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Customer</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
             <TableLEK
headTable={headTable}
dataTable={rowsData}
countPage={countPage}
countData={countData}
entries={entries}
isSearch={search}
callFnc={GetCustomer}
/>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Customer;
