import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import moment from "moment";

function WinSpeedHistory(params) {
  const [po, setPo] = useState([]);

  /* console.log(getUser().com); */
  const [filter_po, setFilter_po] = useState({
    mat_name: "",
    company_id: getUser().com,
  });

  const GetPo = async () => {
    //console.log(filler_project);
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    axios({
      method: "post",
      url: Configs.API_URL + "/api/winSpeed/getHistoryStock",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_po,
    })
      .then(function (response) {
        //console.log(response.data);
        setPo(response.data);
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetPo();
  }, []);

  const clearFilter = async () => {
    setFilter_po({
      ...filter_po,
      mat_name: "",
    });

    filter_po.mat_name = "";
    GetPo();
  };
  const rowsData = [];

  for (var index = 0; index < po.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["DATE_STOCK"] = moment(po[index].DATE_STOCK).add(-7,'h').format('DD/MM/YYYY เวลา HH:mm น.');
    rowItem["WH_ID"] = po[index].WH_ID;
    rowItem["DOC_ID"] = po[index].DOC_ID;
    rowItem["RAW_ID"] = po[index].RAW_ID;
    rowItem["ITM_ID"] = po[index].ITM_ID;
    rowItem["MAT_NAME"] = po[index].MAT_NAME;
    rowItem["QTY"] = po[index].QTY;
    rowItem["TYPE"] = po[index].TYPE;
    rowItem["TOTAL_QTY"] = po[index].TOTAL_QTY;
    rowItem["STATUS"] = po[index].STATUS;
    rowItem["CREATE_USER"] = po[index].CREATE_USER;
   

    /* rowItem["mgt"] = (
        <div className="row">
          <div className="col-4">
            <a
               href={"/Warehouse_Management/Factory_Master_Data/Loop/read/"+ loop[index].id}
              key={loop[index].id}
              className=" btn btn-xs "
            >
              <i class="fas fa-eye"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
                href={"/Warehouse_Management/Factory_Master_Data/Loop/edit/"+ loop[index].id}
               key={loop[index].id}
              className=" btn btn-xs "
            >
              {" "}
              <i class="fas fa-pencil-alt"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
               key={loop[index].id}
              className=" btn btn-xs "
               onClick={deldata.bind(this,loop[index].id)}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ); */
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "DATE_STOCK",
        field: "DATE_STOCK",
        sort: "asc",
        width: 50,
      },
      {
        label: "WH_ID",
        field: "WH_ID",
        sort: "asc",
        width: 50,
      },
      /*  {
        label: "PO_CODE",
        field: "PO_CODE",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "DOC_ID",
        field: "DOC_ID",
        sort: "asc",
        width: 50,
      },
      {
        label: "RAW_ID",
        field: "RAW_ID",
        sort: "asc",
        width: 50,
      },
      {
        label: "ITM_ID",
        field: "ITM_ID",
        sort: "asc",
        width: 50,
      },
      {
        label: "MAT_NAME",
        field: "MAT_NAME",
        sort: "asc",
        width: 50,
      },
      {
        label: "QTY",
        field: "QTY",
        sort: "asc",
        width: 50,
      },
      {
        label: "TYPE",
        field: "TYPE",
        sort: "asc",
        width: 50,
      },
      {
        label: "TOTAL_QTY",
        field: "TOTAL_QTY",
        sort: "asc",
        width: 50,
      },
      {
        label: "STATUS",
        field: "STATUS",
        sort: "asc",
        width: 50,
      },
         {
        label: "CREATE_USER",
        field: "CREATE_USER",
        sort: "asc",
        width: 50,
      },
      
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>WareHouse History</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">WareHouse History</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_po.mat_name}
                    onChange={(e) => {
                      setFilter_po({
                        ...filter_po,
                        mat_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">MAT Name.</label>{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetPo();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*  <div className="container-fluid">
            <div className="row">
              
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div> */}
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WareHouse History</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
      </div>
    </div>
  );
}
export default WinSpeedHistory;
