import React, { useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";

function DoughnutChart(props) {
  ChartJS.register(...registerables, ChartDataLabels);
  // ChartJS.unregister(CrosshairPlugin);
  // ChartJS.unregister(ChartDataLabels);

  const data = [];

  const config = {
    // events: ["click"],
    type: "doughnut",
    data: data,
    interaction: {
      mode: "index",
      axis: "y",
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        position: "bottom",
      },
      datalabels: {
        color: "#FFFFFF",
        offset: 5,
        formatter: (value) => "",
        font: {
          weight: "bold",
          size: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}`;
          },
        },
      },
    },
  };

  return (
    <div className="chart-container" style={{ maxHeight: "240px" }}>
      <Doughnut
        ref={props.donutRef}
        data={props.chartData}
        options={config}
        style={{ maxHeight: "240px" }}
      />
    </div>
  );
}

export default DoughnutChart;
