import React, { useEffect, useState } from "react";
import axios from "axios";
import Configs from "../../../../../config";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { CompactPicker } from "react-color";

function AEV_Route_Cuteplus({ mode }) {
  const { id } = useParams();

  const [pageMode, setPageMode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [RouteDelDate, setRouteDelDate] = useState([]);

  const [inputData, setInputData] = useState({
    route_name: "",
    route_detail: "",
    route_rdd_id: "",
    route_remark: "",
    route_created_by: getUser().fup,
    route_updated_by: getUser().fup,
    route_created_name: getUser().name + " " + getUser().surname,
    route_updated_name: getUser().name + " " + getUser().surname,
    route_color_code: "",
  });
  const [LogisticCar, setLogisticCar] = useState({
    data: [],
  });
  const [Filter, setFilter] = useState({
    name: "",
    contact: "",
  });
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  let [displayColorPicker, setdisplayColorPicker] = useState(false);

  function handleClick() {
    setdisplayColorPicker(!displayColorPicker);
    displayColorPicker = !displayColorPicker;
  }

  function handleClose() {
    setdisplayColorPicker(false);
    displayColorPicker = false;
  }
  const RoutData = async () => {
    axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/Route/getByid/" + id,
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        // console.log(response.data);
        setInputData({
          ...inputData,
          route_name: response.data[0].route_name,
          route_detail: response.data[0].route_detail,
          route_rdd_id: response.data[0].route_rdd_id,
          route_remark: response.data[0].route_remark,
          route_color_code:
            response.data[0].route_color_code === null
              ? ""
              : response.data[0].route_color_code,
        });
        inputData.route_name = response.data[0].route_name;
        inputData.route_detail = response.data[0].route_detail;
        inputData.route_rdd_id = response.data[0].route_rdd_id;
        inputData.route_remark = response.data[0].route_remark;
        inputData.route_color_code =
          response.data[0].route_color_code === null
            ? ""
            : response.data[0].route_color_code;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  function saveOption() {
    const errorList = [];
    // for (let i = 0; i < LogisticCar.data.length; i++) {
    //   if (mode === "add") {
    //     if (inputData.route_name === LogisticCar.data[i].lgt_name) {
    //       errorList.push("Route Name ซํ้า กรุณากรอกใหม่อีกครั้ง");
    //     }
    //   } else {
    //     if (
    //       inputData.Route_Name === LogisticCar.data[i].lgt_name &&
    //       id !== LogisticCar.data[i].lgt_id
    //     ) {
    //       errorList.push("Route Name ซํ้า กรุณากรอกใหม่อีกครั้ง");
    //     }
    //   }
    // }
    if (inputData.route_name?.trim() === "") {
      errorList.push("กรุณากรอก Route Name");
    }
    if (inputData.route_rdd_id === "") {
      errorList.push("กรุณาเลือก Delivery Date");
    }
    if (inputData.route_detail?.trim() === "") {
      errorList.push("กรุณาระบุ Route Detail");
    }

    if (inputData.route_color_code === "") {
      errorList.push("กรุณาระบุ Color");
    }

    // console.log('errorList :>> ', errorList);
    if (errorList.length > 0) {
      let err_message = "";
      for (let index = 0; index < errorList.length; index++) {
        err_message += errorList[index] + "<br>";
      }
      Swal.fire({
        icon: "error",
        title: "Error",
        html: err_message,
      });
      return;
    }
    if (mode === "add") {
      // console.log("add");
      addRoute();
    } else if (mode === "edit") {
      // console.log("edit");
      editRoute();
    }
  }

  function addRoute() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        let savedData = {
          route_name:
            inputData.route_name !== "" && inputData.route_name !== null
              ? inputData.route_name.trim()
              : null,
          route_detail:
            inputData.route_detail !== "" && inputData.route_detail !== null
              ? inputData.route_detail.trim()
              : null,
          route_remark:
            inputData.route_remark !== "" && inputData.route_remark !== null
              ? inputData.route_remark.trim()
              : null,
          route_rdd_id: inputData.route_rdd_id,
          route_created_by: inputData.route_created_by,
          route_updated_by: inputData.route_updated_by,
          route_created_name: inputData.route_created_name,
          route_updated_name: inputData.route_updated_name,
          route_color_code: inputData.route_color_code,
          route_oem_id: getOem(),
          route_company_id: getUser().com,
        };
        // console.log(savedData);
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/route/add",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: savedData,
        })
          .then((response) => {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "บันทึกข้อมูลสำเร็จ",
                timer: 1500,
              }).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data_Cuteplus/Route";
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  function editRoute() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("confirm Edit");   console.log("edit", inputData);
        let data = {
          route_name:
            inputData.route_name !== "" && inputData.route_name !== null
              ? inputData.route_name.trim()
              : null,
          route_detail:
            inputData.route_detail !== "" && inputData.route_detail !== null
              ? inputData.route_detail.trim()
              : null,
          route_remark:
            inputData.route_remark !== "" && inputData.route_remark !== null
              ? inputData.route_remark.trim()
              : null,
          route_rdd_id: inputData.route_rdd_id,
          route_updated_by: inputData.route_updated_by,
          route_updated_name: inputData.route_updated_name,
          route_color_code: inputData.route_color_code,
        };

        axios({
          method: "put",
          url: Configs.API_URL_cuteplus + "/api/route/edit/" + id,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            console.log(response.data);
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "บันทึกข้อมูลสำเร็จ",
                timer: 1500,
              }).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data_Cuteplus/Route";
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
        /* .then(() => {
            window.location.href =
              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Route";
          }); */
      }
    });
  }

  //   function GetfindByPkController() {
  //     let data = {
  //       user_id: id,
  //       oem_id: getOem(),
  //       company_id: getUser().com,
  //     };
  //     axios({
  //       method: "post",
  //       url: Configs.API_URL_cuteplus + "/api/route/getByid/"+id,
  //       headers: {
  //         Authorization: "Bearer " + getToken(),
  //         "X-TTT": Configs.API_TTT,
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     })
  //       .then((response) => {
  //         console.log(response.data);
  //         if (response.data) {
  //           response.data = response.data[0];
  //           setInputData({
  //             ...inputData,
  //             Route_Name:
  //               response.data.lgt_name !== null ? response.data.lgt_name : "",
  //             OutSource_Company_Name:
  //               response.data.lgt_lcom_id !== null
  //                 ? response.data.lgt_lcom_id
  //                 : "",
  //             Contact_Name:
  //               response.data.lgt_contract_name !== null
  //                 ? response.data.lgt_contract_name
  //                 : "",
  //             Tel: response.data.lgt_tel !== null ? response.data.lgt_tel : "",
  //             Gross_weight:
  //               response.data.lgt_gross_weight !== null
  //                 ? response.data.lgt_gross_weight
  //                 : "",
  //             Delivery_Source:
  //               response.data.lgt_ds_id !== "" ? response.data.lgt_ds_id : null,
  //             Transportation_Type:
  //               response.data.lgt_tt_id !== "" ? response.data.lgt_tt_id : null,
  //             Vehicle_Type:
  //               response.data.lgt_vt_id !== "" ? response.data.lgt_vt_id : null,
  //             Width:
  //               response.data.lgt_size_width !== null
  //                 ? response.data.lgt_size_width
  //                 : "",
  //             Length:
  //               response.data.lgt_size_length !== null
  //                 ? response.data.lgt_size_length
  //                 : "",
  //             Height:
  //               response.data.lgt_size_height !== null
  //                 ? response.data.lgt_size_height
  //                 : "",
  //             m3_auto_cal:
  //               response.data.lgt_m3 !== null
  //                 ? parseFloat(response.data.lgt_m3).toFixed(2)
  //                 : "",
  //             Remark:
  //               response.data.lgt_remark !== null ? response.data.lgt_remark : "",
  //           });
  //           console.log("M3 :>> ", parseFloat(response.data.lgt_m3).toFixed(2));
  //         }
  //       })
  //     .catch((error) => {
  //         Swal.fire({
  //           title: "Error",
  //           text: error.response.data.error.message,
  //           icon: "error",
  //         });
  //     });
  // }

  function cancelOption() {
    Swal.fire({
      title: "คุณต้องการยกเลิกหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Warehouse_Management/Factory_Master_Data_Cuteplus/Route";
      }
    });
  }

  async function getRouteDelDate() {
    axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/route_delivery_date/getAll",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let tempArr = [];
        response.data.forEach((element) => {
          const el = {};
          el["label"] = element.rdd_name;
          el["value"] = element.rdd_id;
          tempArr.push(el);
        });
        setRouteDelDate(tempArr);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  useEffect(async () => {
    if (mode === "add") {
      setPageMode("Add");
      setIsDisabled(false);
      await getRouteDelDate();
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
      await RoutData();
      await getRouteDelDate();
    } else if (mode === "view") {
      setPageMode("Read");
      setIsDisabled(true);
      await RoutData();
      await getRouteDelDate();
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-sm-4">
                <h1>Route</h1>
              </div>
              <div className="col-sm-8">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Route</li>
                  <li className="breadcrumb-item active">Route {pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={() => {
                      saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Route Name : {inputData.route_name}
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 style={{ margin: "0px" }}>Route Detail</h4>
                  </div>
                  <div className="card-body">
                    {/* <div className="row">
                      <div className="col-12">
                        <label
                          style={{
                            fontSize: "24px",
                            lineHeight: "normal",
                            wordBreak: "break-all",
                          }}
                        >
                          Route Detail  Route Detail
                        </label>
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.route_name}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                route_name: e.target.value,
                              });
                              inputData.route_name = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Route Name
                            {inputData.route_name === "" ||
                            inputData.route_name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.route_rdd_id}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                route_rdd_id: e.target.value,
                              });
                              inputData.route_rdd_id = e.target.value;
                            }}
                          >
                            <option value="">-- Please Select --</option>
                            {RouteDelDate?.map((data, index) => {
                              return (
                                <option key={index} value={data.value}>
                                  {data.label}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Delivery Date
                            {inputData.route_rdd_id === "" ||
                            inputData.route_rdd_id === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-md-3 col-xl-3">
                        <div class="form-group">
                          {mode === "view" ? (
                            <div className="color-palette-set">
                              <div
                                className="color-palette"
                                style={{
                                  backgroundColor: `${inputData.route_color_code}`,
                                  height: "36px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{ color: inputData.route_color_code }}
                                >
                                  {inputData.route_color_code}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <button
                                type="button"
                                class="btn btn-block"
                                onClick={handleClick}
                                style={{
                                  backgroundColor:
                                    inputData.route_color_code === ""
                                      ? "#666666"
                                      : inputData.route_color_code,
                                  color: "#ffffff",
                                }}
                              >
                                Pick Color
                                {inputData.route_color_code === "" ||
                                inputData.route_color_code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </button>

                              {displayColorPicker ? (
                                <div style={popover}>
                                  <div style={cover} onClick={handleClose} />
                                  <CompactPicker
                                    color={
                                      inputData.route_color_code === ""
                                        ? "#666666"
                                        : inputData.route_color_code
                                    }
                                    onChangeComplete={(color) => {
                                      setInputData({
                                        ...inputData,
                                        route_color_code: color.hex,
                                      });
                                      inputData.route_color_code = color.hex;
                                    }}
                                  />
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>

                        {/* <CompactPicker
                          color={inputData.route_color_code}
                          onChangeComplete={(color) => {
                            setInputData({
                              ...inputData,
                              route_color_code: color.hex,
                            });
                            inputData.route_color_code = color.hex;
                          }}
                        /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.route_detail}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                route_detail: e.target.value,
                              });
                              inputData.route_detail = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Route Detail
                            {inputData.route_detail.trim() === "" ||
                            inputData.route_detail === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.route_remark}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                route_remark: e.target.value,
                              });
                              inputData.route_remark = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Remark
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AEV_Route_Cuteplus;
