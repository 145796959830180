import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";
import moment from "moment";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import INDF from "../../../../../component/input_date_feature";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";
function FG_In_Out_Log({ data, setdata, fgInOutData, setfgInOutData }) {
  const [dataDisplay, setdataDisplay] = useState({
    lp_product_id: "",
    lp_product_name: "",
    lp_product_no: "",
    lp_edit_amount: true,
    lp_amount: "",
  });
  const [dataForSave, setdataForSave] = useState({
    lp_lot_no: "",
    lp_id: "",
    lp_product_id: "",
    lp_edit_amount: true,
    lp_amount: "",
    lp_ref_document: "",
    lp_exp_date: new Date(),
    lp_remark: "",
    lp_sup_id: "",
    lp_created_date: new Date(),
    lp_created_by: getUser().fup,
    lp_created_name: getUser().name + " " + getUser().surname,
    lp_owner_lot_id: "",
    lp_owner_lot_name: "",
    lp_company_id: getUser().com,
    lp_oem_id: getOem(),
  });
  const [is_fifo, setis_fifo] = useState(true);
  const [saleDD, setSaleDD] = useState([]);
  const [productStockLot, setproductStockLot] = useState([]);
  const [productHisStockLot, setproductHisStockLot] = useState([]);
  const [SupplierData, setSupplierData] = useState([]);
  useEffect(async () => {
    await Getsale();
    await GetSupplier();

    setdata({ ...data, data: GetLotSTock });
    data.data = GetLotSTock;
  }, []);
  async function GetLotSTock(prod_id, amount, name, no) {
    setdataDisplay({
      ...dataDisplay,
      lp_product_id: prod_id,
      lp_product_name: name,
      lp_amount: amount,
      lp_product_no: no,
    });
    dataDisplay.lp_product_id = prod_id;
    dataDisplay.lp_product_name = name;
    dataDisplay.lp_amount = amount;
    dataDisplay.lp_product_no = no;
    setdataForSave({
      ...dataForSave,
      lp_product_id: prod_id,
    });
    dataForSave.lp_product_id = prod_id;
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/log_product/getLotByid",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: dataDisplay,
    })
      .then(async (response) => {
        setproductStockLot(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/log_product/getLotHistryByid",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: dataDisplay,
    })
      .then(async (response) => {
        setproductHisStockLot(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async function GetSupplier() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetSupplier",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setSupplierData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const Getsale = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/cutePlus/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "";
        element2["label"] = "---ไม่ระบุ---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name + " " + el.sirname;
          options.push(element);
        });

        setSaleDD(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  function changes_log_no(id, no) {
    setdataForSave({
      ...dataForSave,
      lp_lot_no: no,
      lp_id: id,
    });
    dataForSave.lp_lot_no = no;
    dataForSave.lp_id = id;
  }
  const lot_FG_Stock_Table = [];
  for (let index = 0; index < productStockLot.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["amount"] = (
      <NumberFormat
        value={productStockLot[index].lp_amount}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
    rowItem["ref_document"] = productStockLot[index].lp_ref_document;
    rowItem["lot_no"] = productStockLot[index].lp_lot_no;
    rowItem["supplier"] = productStockLot[index].lp_sup_name;
    rowItem["sale"] = productStockLot[index].lp_owner_lot_name;
    rowItem["expire_date"] = moment(productStockLot[index].lp_exp_date).format(
      "DD/MM/yyyy"
    );
    rowItem["remark"] = productStockLot[index].lp_remark;
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6"></div>
        <div className="col-4">
          <input
            className="form-check-input"
            type="radio"
            id={index}
            name="check_lot_fg"
            value={productStockLot[index].lp_lot_no}
            onClick={changes_log_no.bind(
              this,
              productStockLot[index].lp_id,
              productStockLot[index].lp_lot_no
            )}
          />
        </div>
      </div>
    );
    lot_FG_Stock_Table.push(rowItem);
  }

  const Stock_Table = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Quantity",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Ref. Document",
        field: "ref_document",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "supplier",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sale",
        field: "sale",
        sort: "asc",
        width: 50,
      },
      {
        label: "Expire Date",
        field: "expire_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
    ],
    rows: lot_FG_Stock_Table,
  };
  if (is_fifo === true) {
    var index = Stock_Table.columns.findIndex((e) => {
      return e.field === "mgt";
    });
    Stock_Table.columns.splice(index, 1);
  }
  const lot_histry_FG_Stock_Table = [];
  for (let index = 0; index < productHisStockLot.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["status"] = productHisStockLot[index].lp_edit_amount ? "In" : "Out";
    rowItem["amount"] = (
      <NumberFormat
        value={productHisStockLot[index].lp_amount}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
    rowItem["ref_document"] = productHisStockLot[index].lp_ref_document; //productStockLot[index].lp_ref_document;
    rowItem["lot_no"] = productHisStockLot[index].lp_lot_no;
    rowItem["sale"] = productHisStockLot[index].lp_owner_lot_name;
    rowItem["date"] = moment(productHisStockLot[index].lp_created_date).format(
      "DD/MM/yyyy :: HH:mm"
    );
    rowItem["expire_date"] = moment(
      productHisStockLot[index].lp_exp_date
    ).format("DD/MM/yyyy");
    rowItem["remark"] = productHisStockLot[index].lp_remark;
    rowItem["reserve"] = productHisStockLot[index].reserve_name;
    lot_histry_FG_Stock_Table.push(rowItem);
  }

  const Stock_histry_Table = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 50,
      },

      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Quantity",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      ,
      {
        label: "Ref. Document",
        field: "ref_document",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sale",
        field: "sale",
        sort: "asc",
        width: 50,
      },
      {
        label: "Expire Date",
        field: "expire_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Reserve",
        field: "reserve",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
    ],
    rows: lot_histry_FG_Stock_Table,
  };

  async function savechang() {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const error_list = [];
        if (dataForSave.lp_edit_amount === true) {
          if (dataForSave.lp_exp_date === "") {
            let temp_err = {
              message: "กรุณากรอก EXP DATE",
            };
            error_list.push(temp_err);
          }

          if (dataForSave.lp_owner_lot_id === "") {
            let temp_err = {
              message: "กรุณาระบุ SALE NAME",
            };
            error_list.push(temp_err);
          }
        }

        if (dataForSave.lp_amount === "" || dataForSave.lp_amount <= 0) {
          let temp_err = {
            message: "กรุณาระบุ Quantity",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          let temp_err = {
            message: "",
          };
          error_list.unshift(temp_err);

          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          let temp_data_sand_save;
          let config_data;
          if (dataForSave.lp_edit_amount) {
            temp_data_sand_save = {
              lp_product_id: dataForSave.lp_product_id || null,
              lp_edit_amount: dataForSave.lp_edit_amount || null,
              lp_amount: dataForSave.lp_amount || null,
              lp_ref_document: dataForSave.lp_ref_document || null,
              lp_exp_date: dataForSave.lp_exp_date || null,
              lp_remark: dataForSave.lp_remark || null,
              lp_sup_id: dataForSave.lp_sup_id || null,
              lp_created_date: dataForSave.lp_created_date || null,
              lp_updated_date: dataForSave.lp_created_date || null,
              lp_created_by: dataForSave.lp_created_by || null,
              lp_updated_by: dataForSave.lp_created_by || null,
              lp_created_name: dataForSave.lp_created_name || null,
              lp_updated_name: dataForSave.lp_created_name || null,
              lp_owner_lot_id: dataForSave.lp_owner_lot_id || null,
              lp_owner_lot_name: dataForSave.lp_owner_lot_name || null,
              lp_company_id: dataForSave.lp_company_id || null,
              lp_oem_id: dataForSave.lp_oem_id || null,
              lp_is_waste: false,
            };
            config_data = {
              method: "post",
              url: Configs.API_URL_cuteplus + "/api/log_product/saveLogFgIn",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp_data_sand_save,
            };
          } else {
            temp_data_sand_save = {
              lp_lot_no: dataForSave.lp_lot_no || null,
              lp_id: dataForSave.lp_id || null,
              lp_product_id: dataForSave.lp_product_id || null,
              lp_edit_amount: dataForSave.lp_edit_amount || null,
              lp_amount: dataForSave.lp_amount || null,
              lp_ref_document: dataForSave.lp_ref_document || null,
              lp_remark: dataForSave.lp_remark || null,
              lp_created_date: dataForSave.lp_created_date || null,
              lp_updated_date: dataForSave.lp_created_date || null,
              lp_created_by: dataForSave.lp_created_by || null,
              lp_updated_by: dataForSave.lp_created_by || null,
              lp_created_name: dataForSave.lp_created_name || null,
              lp_updated_name: dataForSave.lp_created_name || null,
              lp_company_id: dataForSave.lp_company_id || null,
              lp_oem_id: dataForSave.lp_oem_id || null,
            };

            if (
              is_fifo &&
              temp_data_sand_save.lp_lot_no === null &&
              temp_data_sand_save.lp_id === null
            ) {
              config_data = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus +
                  "/api/log_product/saveLogFgOutAutoLot",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp_data_sand_save,
              };
            } else {
              config_data = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus +
                  "/api/log_product/saveLogFgOutManualLot",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp_data_sand_save,
              };
            }
          }

          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(() => {
            axios(config_data)
              .then(function (response) {
                if (response.data) {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    setfgInOutData({
                      ...fgInOutData,
                      amount: dataForSave.lp_edit_amount
                        ? Number(fgInOutData.amount) +
                          Number(dataForSave.lp_amount)
                        : Number(fgInOutData.amount) -
                          Number(dataForSave.lp_amount),
                    });

                    fgInOutData.amount = dataForSave.lp_edit_amount
                      ? Number(fgInOutData.amount) +
                        Number(dataForSave.lp_amount)
                      : Number(fgInOutData.amount) -
                        Number(dataForSave.lp_amount);

                    GetLotSTock(
                      dataDisplay.lp_product_id,
                      dataDisplay.lp_amount,
                      dataDisplay.lp_product_name,
                      dataDisplay.lp_product_no
                    );
                    clearDataDefault();
                    // window.location.reload();
                    // window.location.href =
                    //   "/Warehouse_Management/FG_IN_OUT_Cuteplus";
                  });
                }
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error!",
                  text: error.response.data.Error,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              });
          });
        }
      }
    });
  }

  async function clearDataDefault() {
    setdataForSave({
      ...dataForSave,
      lp_edit_amount: true,
      lp_amount: "",
      lp_ref_document: "",
      lp_exp_date: new Date(),
      lp_remark: "",
      lp_sup_id: "",
      lp_owner_lot_id: "",
      lp_owner_lot_name: "",
    });

    dataForSave.lp_edit_amount = true;
    dataForSave.lp_amount = "";
    dataForSave.lp_ref_document = "";
    dataForSave.lp_exp_date = new Date();
    dataForSave.lp_remark = "";
    dataForSave.lp_sup_id = "";
    dataForSave.lp_owner_lot_id = "";
    dataForSave.lp_owner_lot_name = "";

    setis_fifo(true);
    is_fifo = true;
  }
  return (
    <>
      <div className="row">
        <div className="col-0 col-md-2 col-xl-2"></div>
        <div className="col-12 col-md-5 col-xl-5">
          <h3>Product No : {dataDisplay.lp_product_no}</h3>
        </div>

        <div className="col-12 col-md-5 col-xl-5">
          <h3>Product Name :{dataDisplay.lp_product_name}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-0 col-md-2 col-xl-2"></div>
        <div className="col-0">
          <input
            type="radio"
            id="inbound"
            name="edit_type"
            checked={dataForSave.lp_edit_amount != true ? false : true}
            value={true}
            onChange={(e) => {
              setdataForSave({
                ...dataForSave,
                lp_edit_amount: e.target.value === "true" ? true : false,
              });
              dataForSave.lp_edit_amount = e.target.value;
              setis_fifo(true);
            }}
          />
        </div>
        <div className="col-5 col-md-5 col-xl-5">
          <label htmlFor="inbound">
            <h4>{"In bound"}</h4>
          </label>
        </div>

        <div className="col-0">
          <input
            type="radio"
            id="outbound"
            name="edit_type"
            value={false}
            checked={dataForSave.lp_edit_amount != false ? false : true}
            onChange={(e) => {
              setdataForSave({
                ...dataForSave,
                lp_edit_amount: e.target.value === "false" ? false : true,
              });
              dataForSave.lp_edit_amount = e.target.value;
            }}
          />
        </div>
        <div className="col-5 col-md-4 col-xl-4">
          <label htmlFor="outbound">
            <h4>Out bound</h4>
          </label>
        </div>
      </div>

      {dataForSave.lp_edit_amount === false ? (
        <>
          <div className="row" style={{ whiteSpace: "nowrap" }}>
            <div className="col-0 col-md-3 col-xl-3"></div>
            <div
              className="col-5 col-md-2 col-xl-2"
              style={{ textAlign: "right" }}
            >
              <label for="is_fifo">Manual out FG</label>
            </div>
            <div className="col-3">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is_fifo"
                  defaultChecked={is_fifo}
                  onClick={(e) => {
                    setis_fifo(e.target.checked);

                    setis_fifo(e.target.checked);
                  }}
                />
                <label class="custom-control-label" for="is_fifo">
                  First In First Out
                </label>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="card card-primary card-outline card-outline-tabs">
        <div className="card-header p-0 border-bottom-0">
          <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="custom-tabs-four-warehouse-tab"
                data-toggle="pill"
                href="#custom-tabs-four-warehouse"
                role="tab"
                aria-controls="custom-tabs-four-warehouse"
                aria-selected="true"
              >
                Lot in Stock
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                id="custom-tabs-four-rack-tab"
                data-toggle="pill"
                href="#custom-tabs-four-rack"
                role="tab"
                aria-controls="custom-tabs-four-rack"
                aria-selected="false"
              >
                Lot History
              </a>
            </li>
          </ul>
        </div>

        <div className="card-body">
          <div className="tab-content" id="custom-tabs-four-tabContent">
            <div
              className="tab-pane fade show active"
              id="custom-tabs-four-warehouse"
              role="tabpanel"
              aria-labelledby="custom-tabs-four-warehouse-tab"
            >
              <div className="row">
                <div className="col-4"></div>
              </div>

              <h3 class="title ng-binding">Lot In Stock</h3>
              <div
                classNmae="row"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <div className="col-12 col-md-4 col-xl-2">
                  {/* {params.approve_cts} */}
                </div>
                <div className="col-12 col-md-4 col-xl-2">
                  {/* {params.report_tag} */}
                </div>
              </div>

              <div className="row">
                <div
                  className="table-responsive"
                  style={{ height: "350px", whiteSpace: "nowrap" }}
                >
                  <MDBDataTable
                    className="table"
                    searching={true}
                    sortable={false}
                    disableRetreatAfterSorting={true}
                    is
                    striped
                    bordered
                    hover
                    data={Stock_Table}
                  />
                </div>
              </div>
              {/*   <div className="tab-custom-content"></div>
                  <div className="row">

                  </div> */}
            </div>

            <div
              className="tab-pane fade"
              id="custom-tabs-four-rack"
              role="tabpanel"
              aria-labelledby="custom-tabs-four-rack-tab"
            >
              <div className="row">
                <div className="col-4"></div>
              </div>

              <h3 class="title ng-binding">Lot History</h3>

              <div className="row">
                <div
                  className="table-responsive"
                  style={{ height: "350px", whiteSpace: "nowrap" }}
                >
                  <MDBDataTable
                    className="table"
                    disableRetreatAfterSorting={true}
                    sortable={false}
                    searching={true}
                    striped
                    bordered
                    hover
                    data={Stock_histry_Table}
                  />
                </div>
              </div>
              {/*   <div className="tab-custom-content"></div>
                  <div className="row">

                  </div> */}
            </div>
          </div>
          <br />
          <div className="row">
            {dataForSave.lp_edit_amount === true ? (
              <>
                <div
                  className="col-12 col-md-4 col-xl-4"
                  style={{ "z-index": "101", alignItems: "right" }}
                >
                  <INDF
                    selected={dataForSave.lp_exp_date}
                    txt={"EXP Date"}
                    isRequire={true}
                    onChange={(date) =>
                      setdataForSave({ ...dataForSave, lp_exp_date: date })
                    }
                    customInput={<CustomInput />}
                  />
                </div>
                <div className="col-12 col-md-4 col-xl-4">
                  <div class="form-group">
                    <select
                      className="form-control"
                      required
                      value={dataForSave.lp_owner_lot_id}
                      onChange={(e) => {
                        const filter = saleDD.filter((el) => {
                          return el.value === e.target.value;
                        });
                        setdataForSave({
                          ...dataForSave,
                          lp_owner_lot_id: e.target.value,
                          lp_owner_lot_name: filter[0].label,
                        });
                        dataForSave.lp_owner_lot_id = e.target.value;
                        dataForSave.lp_owner_lot_name = filter[0].label;
                      }}
                    >
                      {saleDD.map((data) => {
                        return <option value={data.value}>{data.label}</option>;
                      })}
                    </select>
                    <label>
                      Sale{" "}
                      {dataForSave.lp_owner_lot_id === "" ||
                      dataForSave.lp_owner_lot_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-4">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      //   disabled={isDisabled}
                      value={dataForSave.lp_sup_id}
                      onChange={(e) => {
                        setdataForSave({
                          ...dataForSave,
                          lp_sup_id: e.target.value,
                        });
                        dataForSave.lp_sup_id = e.target.value;
                      }}
                    >
                      <option value="" disabled>
                        --- กรุณาเลือก ---
                      </option>
                      {SupplierData.map((data, index) => {
                        return (
                          <option key={index} value={data.sup_id}>
                            {data.sup_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Supplier
                      {/* {!Is_Product_Kitting && (dataForSave.supplier_id === "" || dataForSave.supplier_id === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
                    </label>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-xl-4">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={dataForSave.lp_ref_document}
                  onChange={(e) => {
                    setdataForSave({
                      ...dataForSave,
                      lp_ref_document: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">Ref.Document</label>{" "}
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-4">
              <div className="form-group ">
                <NumberFormat
                  value={dataForSave.lp_amount}
                  className="form-control"
                  onChange={(e) => {
                    setdataForSave({
                      ...dataForSave,
                      // lp_amount: Number(e.target.value.replace(/,/g, "")),
                      lp_amount: e.target.value.replace(/,/g, ""),
                    });
                  }}
                  type="text"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={0}
                  autofocus
                  required
                />
                {/* <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={dataForSave.lp_amount}
                  onChange={(e) => {
                    setdataForSave({
                      ...dataForSave,
                      lp_amount: e.target.value,
                    });
                  }}
                /> */}
                <label htmlFor="">
                  Quantity(Piece)
                  {dataForSave.lp_amount === "" ||
                  dataForSave.lp_amount === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>{" "}
              </div>
            </div>

            <div className="col-12 col-md-4 col-xl-4">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={dataForSave.lp_remark}
                  onChange={(e) => {
                    setdataForSave({
                      ...dataForSave,
                      lp_remark: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">Remark</label>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button
            type="button"
            className="btn btn-primary"
            id="btn_save"
            onClick={savechang}
            // disabled={block_btn}
            // data-dismiss="modal"
            // aria-label="Close"
          >
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
}

export default FG_In_Out_Log;
