import React, { useEffect, useState } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import Swal from "sweetalert2";

import NumberFormat from "react-number-format";

function Input_Certificate_license({
  fromPage,
  isDisabled,
  inputData,
  setInputData,
  setFile,
  oldFile,
  setOldFile,
  cerType,
}) {
  const allowedTypes = [
    "image/png",
    "image/jpeg",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={inputData.Certificate_License}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  Certificate_License: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              {fromPage === "Certificate" ? "Factory" : "License"}
              {inputData.Certificate_License === "" ||
              inputData.Certificate_License === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div class="form-group">
            <DatePicker
              customInput={<CustomInput />}
              wrapperClassName="w-100"
              popperClassName="zI-2"
              locale="th"
              dateFormat={"dd-MM-yyyy"}
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              disabledKeyboardNavigation
              yearDropdownItemNumber={15}
              disabled={isDisabled}
              selected={inputData.Expire_Date}
              value={inputData.Expire_Date}
              minDate={new Date()}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  Expire_Date: e,
                  Extend_Date:
                    inputData.Extend_Date < e ? inputData.Extend_Date : "",
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Expire Date
              {inputData.Expire_Date === "" ||
              inputData.Expire_Date === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div class="form-group">
            <DatePicker
              customInput={<CustomInput />}
              wrapperClassName="w-100"
              popperClassName="zI-2"
              locale="th"
              dateFormat={"dd-MM-yyyy"}
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              disabledKeyboardNavigation
              yearDropdownItemNumber={15}
              disabled={isDisabled}
              selected={inputData.Extend_Date}
              value={inputData.Extend_Date}
              maxDate={inputData.Expire_Date}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  Extend_Date: e,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Extend Date
              {inputData.Extend_Date === "" ||
              inputData.Extend_Date === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        {fromPage === "Certificate" ? (
          <>
            <div className="col-12 col-md-3">
              <div class="form-group">
                <select
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputData.Certificate_Type}
                  onChange={(e) => {
                    setInputData({
                      ...inputData,
                      Certificate_Type: e.target.value,
                    });
                    inputData.Certificate_Type = e.target.value;
                  }}
                >
                  <option key="" value="" disabled>
                    Select Type
                  </option>
                  {cerType.data.map((option, index) => (
                    <option key={index} value={option.ctt_id}>
                      {option.ctt_name}
                    </option>
                  ))}
                </select>
                <label>
                  Certificate Type
                  {inputData.Certificate_Type === "" ||
                  inputData.Certificate_Type === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="col-12 col-md-6">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={inputData.Con_Name}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  Con_Name: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Contact Name
              {/* {inputData.Con_Name === "" ||
              inputData.Con_Name === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
            </label>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              // format={"###-###-####"}
              disabled={isDisabled}
              value={inputData.Con_Tel}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  Con_Tel: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Contact Tel
              {/* {inputData.Con_Tel === "" ||
              inputData.Con_Tel === null ||
              inputData.Con_Tel.replace(/[^\d.]/g, "").length < 9 ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={inputData.Address}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  Address: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Contact Location
              {fromPage === "Certificate" &&
              (inputData.Address === "" || inputData.Address === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div class="form-group">
            <textarea
              type="text"
              style={{ fontSize: "25px" }}
              className="form-control"
              required
              disabled={isDisabled}
              value={inputData.Remark}
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  Remark: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Remark
              {/* {inputData.Remark === "" ||
              inputData.Remark === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
            </label>
          </div>
        </div>
      </div>

      {/* {mode === "add" ? ( */}
      <div className="row">
        <div className="col-12">
          <div className="none-nolmalinput">
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              {fromPage === "Certificate"
                ? "Certificate Document"
                : "License Document"}
              {inputData.File_Name === "" || inputData.File_Name === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>

            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  disabled={isDisabled}
                  id="exampleInputFile"
                  accept={allowedTypes}
                  onChange={(e) => {
                    if (e.target.files[0] !== undefined) {
                      if (allowedTypes.includes(e.target.files[0]?.type)) {
                        const file = e.target.files[0];

                        if (file.name) {
                          setFile(e.target.files[0]);

                          setInputData({
                            ...inputData,
                            File_Name: e.target.files[0].name,
                            File_Path: URL.createObjectURL(e.target.files[0]),
                          });
                        }

                        setOldFile({
                          ...oldFile,
                          statusFile: true,
                        });
                      } else {
                        Swal.fire(
                          "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
                          "กรุณาอัปโหลดประเภทไฟล์ PNG, JPEG, CSV, WORD, EXCEL, PDF",
                          "error"
                        );

                        setFile(null);

                        setInputData({
                          ...inputData,
                          File_Name: oldFile.File_Name,
                          File_Path: oldFile.File_Path,
                        });

                        setOldFile({
                          ...oldFile,
                          statusFile: false,
                        });

                        document.getElementById("exampleInputFile").value =
                          null;
                      }
                    } else {
                      setFile(null);

                      setInputData({
                        ...inputData,
                        File_Name: oldFile.File_Name,
                        File_Path: oldFile.File_Path,
                      });

                      setOldFile({
                        ...oldFile,
                        statusFile: false,
                      });

                      document.getElementById("exampleInputFile").value = null;
                    }
                  }}
                  style={{
                    zIndex: "1",
                  }}
                />
                <label
                  className="custom-file-label textVerticalOverflow-1"
                  htmlFor="exampleInputFile"
                >
                  {inputData.File_Name}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Input_Certificate_license;
