import React, { Component, useEffect, useState,useRef } from "react";
import Footter from "../../../component/Footter";
import Header from "../../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import moment from "moment";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Planning_CTS(params) {
  const [feature, setfeature] = useState([]);

  useEffect(() => {
    const element_modal_daily_plan =
      document.getElementById("modal-daily_plan");

    if (element_modal_daily_plan) {
      const observer = new MutationObserver(() => {
        if (element_modal_daily_plan.classList.contains("show")) {
          setIsOpen1(true);
        } else {
          setIsOpen1(false);
        }
      });

      observer.observe(element_modal_daily_plan, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const [filterProduct, setfilterProduct] = useState({
    no: "",
  });

  const PlanningRef = useRef(null);
  const [month_th, setmonth_th] = useState([
    { label: "01", no: "1", month: "มกราคม" },
    { label: "02", no: "2", month: "กุมภาพันธ์" },
    { label: "03", no: "3", month: "มีนาคม" },
    { label: "04", no: "4", month: "เมษายน" },
    { label: "05", no: "5", month: "พฤษภาคม" },
    { label: "06", no: "6", month: "มิถุนายน" },
    { label: "07", no: "7", month: "กรกฎาคม" },
    { label: "08", no: "8", month: "สิงหาคม" },
    { label: "09", no: "9", month: "กันยายน" },
    { label: "10", no: "10", month: "ตุลาคม" },
    { label: "11", no: "11", month: "พฤศจิกายน" },
    { label: "12", no: "12", month: "ธันวาคม" },
  ]);
  const [planning_month, setplanning_month] = useState({
    no: moment(new Date()).format("MM"),
    month: "",
    year: moment(new Date()).format("YYYY"),
    modelCate: "",
  });
  const [dayInMonth, setdayInMonth] = useState([]);
  const [product, setproduct] = useState([]);
  const [test, settest] = useState({
    test: "",
  });
  const [productModel, setproductModel] = useState([]);
  const [model_category, setmodel_category] = useState([]);
  const [model_category_list, setmodel_category_list] = useState([]);
  const [dataDailyPlan, setdataDailyPlan] = useState([]);

  const [startDateDaily, setStartDateDaily] = useState(new Date());
  const [endDateDaily, setEndDateDaily] = useState(null);
  const [isOpen1, setIsOpen1] = useState(false);
  const onChangeDateDaily = (dates) => {
    const [start, end] = dates;
    setStartDateDaily(start);
    setEndDateDaily(end);
  };

  const [openModal, setOpenModal] = useState(false);

  const [balanceStockData, setBalanceStockData] = useState({
    data: [],
  });
  const [productpage, setproductpage] = useState({
    page: 1,
    size: 10,
});
 const [isLoadingproduct, setIsLoadingproduct] = useState(false);
const [dataproductCount, setDataproductCount] = useState(0);
  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + getOem(),
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  const Getmodel = async () => {
    await axios(config_model)
      .then(function (response) {
        var options = [];
        /*       var element1 = {};
          element1["value"] = '';
          element1["label"] = 'All';
          options.push(element1); */
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setmodel_category(options);

        setproductModel(response.data);
        setplanning_month({
          ...planning_month,
          modelCate: response.data[0].id,
        });
        planning_month.modelCate = response.data[0].id;
        filterPlanning(1,10,'start');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterPlanning = async (page, size, mode) => {
    setIsLoadingproduct(true)
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let temp = {
      no: filterProduct.no.trim(),
      oem_id: getOem(),
      month: planning_month.no,
      year: planning_month.year,
      modelCate: planning_month.modelCate,
      currentDay: moment(new Date()).format("DD"),
      currentMonth: moment(new Date()).format("MM"),
      currentYear: moment(new Date()).format("YYYY"),
      page: page || 1,
      size: size || 10,
    };
    // console.log(temp);
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/planning/filterPlanning_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        setproductpage({ ...productpage, page: page || 1, size: size || 10 });
        productpage.page = page || 1;
        productpage.size = page || 10;

        var items = response.data.data;
        // console.log("items", items);
        var date = moment(new Date(planning_month.year))
          .startOf("years")
          .add(planning_month.no - 1, "month");
        var day_in_month = moment(date).daysInMonth();
        let day = [];
        for (let i = 0; i < day_in_month; i++) {
          day.push(i + 1);
        }
        setdayInMonth(day);

        /*      let due = []; */
        /*   let act = []; */
        /*         let fg_in = []; */
        let send_choup = [];
        /*   for (let i = 0; i < day_in_month; i++) {
          due.push(i + 1000);
        } */

        /*   for (let i = 0; i < day_in_month; i++) {
          act.push(i + 1000);
        } */
        for (let i = 0; i < day_in_month; i++) {
          /*      if (i % 5 === 0) {
            fg_in.push(2000);
          } else {
            fg_in.push(0);
          } */
          /*    if (i % 10 === 0) {
            send_choup.push(500);
          } else {
            send_choup.push(0);
          } */
          send_choup.push(0);
        }

        /*        var balance = act.map((a, i) => a - due[i]); */

        for (let i = 0; i < items.length; i++) {
          /*  items[i].due = due; */
          /*      items[i].actual = act; */
          /*        items[i].balance = balance; */
          /*     items[i].fg_in = fg_in; */
          items[i].send_choup = send_choup;
          /*    let act_stock = [];
          for (let i = 0; i < day_in_month; i++) {
            let old_act_stock = act_stock[act_stock.length - 1];
            if (i === 0) {

              let act_stock_value = items[i].fg_amount - act[i] + fg_in[i];
              act_stock.push(act_stock_value);
            } else {
              let act_stock_value = old_act_stock - act[i] + fg_in[i];
              act_stock.push(act_stock_value);
            }

          }
          items[i].act_stock = act_stock; */
        }

        // let send_pack_in = [];

        // for (let i = 0; i < day_in_month; i++) {

        //   send_pack_in.push(0);

        // }

        // for (let i = 0; i < items.length; i++) {

        //   items[i].send_pack_in = send_pack_in;

        // }

        // let send_pack_out = [];

        // for (let i = 0; i < day_in_month; i++) {

        //   send_pack_out.push(0);
        // }

        // for (let i = 0; i < items.length; i++) {

        //   items[i].send_pack_out = send_pack_out;

        // }
        if (mode === 'start') {
        setproduct(items);
        setDataproductCount(response.data.count);
      } else {
        setproduct(prevState => [...prevState, ...items]);
 
    }
    setIsLoadingproduct(false)
      })
      .catch(async (error) => {
        console.log(error);
      });

    Swal.close();
  };

  async function getBalanceAllDataPlanning() {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const temp = {
      oem_id: getOem(),
      month: planning_month.no,
      year: planning_month.year,
      currentDay: moment(new Date()).format("DD"),
      currentMonth: moment(new Date()).format("MM"),
      currentYear: moment(new Date()).format("YYYY"),
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/api/planning/getBalanceAllDataPlanning",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        setBalanceStockData({
          ...balanceStockData,
          data: response.data,
        });
        balanceStockData.data = response.data;
      })
      .catch(async (error) => {
        console.log(error);
      });

    Swal.close();
    setOpenModal(true);
  }

  async function saveBalanceStock() {
    Swal.fire({
      title: "กำลังบันทึกข้อมูล",
      text: "Saveing",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const temp = {
      oem_id: getOem(),
      month: planning_month.no,
      year: planning_month.year,
      currentDay: moment(new Date()).format("DD"),
      currentMonth: moment(new Date()).format("MM"),
      currentYear: moment(new Date()).format("YYYY"),
      data: balanceStockData.data,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/api/planning/saveBalanceStock",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        Swal.close();
        window.location.reload();
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Warehouse";
    });
    setfeature(feature_data);
    ////////permission feature//////////
    var date = moment(new Date());
    var month = moment(new Date()).format("MM");
    var default_month = month_th.filter((e) => {
      return e.label === month;
    });
    // console.log("default_month", default_month);
    if (default_month.length > 0) {
      setplanning_month({
        ...planning_month,
        no: default_month[0].no,
        month: default_month[0].month,
      });
      planning_month.no = default_month[0].no;
      planning_month.month = default_month[0].month;
    }

    /* var day_in_month =  moment(date).daysInMonth();
    let day = [];
    for (let i = 0; i < day_in_month; i++){
        day.push(i+1);
    }
    setdayInMonth(day); */
    Getmodel();

    /*    filterPlanning(); */
    /*  let product = [];
    for (let i = 0; i < 6; i++){
        product.push(i+1);
    }
    setproduct(product); */
  }, []);

  function calDate() {
    // console.log(planning_month)

    setdayInMonth([]);
    var date = moment(new Date(planning_month.year))
      .startOf("years")
      .add(planning_month.no - 1, "month");
    var day_in_month = moment(date).daysInMonth();
    /*     console.log(day_in_month); */
    let day = [];
    for (let i = 0; i < day_in_month; i++) {
      day.push(i + 1);
    }
    setdayInMonth(day);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function clearFilter() {
    setfilterProduct({ ...filterProduct, no: "" });
    setplanning_month({ ...planning_month, modelCate: productModel[0].id });
    filterProduct.no = "";
    planning_month.modelCate = productModel[0].id;
    PlanningRef.current.scrollTop = 0;
    filterPlanning(1,10,'start');
  }

  const GetDailyPlanData = async () => {
    let temp = {
      oem_id: getOem(),
      startDate: startDateDaily,
      endDate: endDateDaily,
      modelCate: model_category_list,
    };
    // console.log(temp);
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/planning/dailyPlan",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        setdataDailyPlan(response.data);
        settest({ ...test, test: "" });
        test.test = "";
        document.getElementById("excel-daily_plan").click();
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function numberWithCommas(x) {
    return (
      <NumberFormat
        thousandSeparator={true}
        value={x || 0}
        displayType="text"
      />
    );
    /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
  }


  const handleScroll = event => {

    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    /* console.log((scrollHeight * 0.95) - scrollTop, clientHeight, event.currentTarget.id); */
    if ((scrollHeight * 0.95) - scrollTop <= clientHeight) {
        if (event.currentTarget.id === 'idPlanning' && isLoadingproduct === false && dataproductCount >= (productpage.page * productpage.size)) {
           /*  console.log('add'); */
            filterPlanning(productpage.page+1,productpage.size,'add');
          /*   let getDetail = dashboardDetails[dashboardDetail.no];
            if (getDetail) {
                getDetail(dashboardDetail.value, configTableDetail.page + 1, configTableDetail.size, 'add');
            } */

        }
       


    }
};




  return (
    <div className="wrapper">
      {/*  <Header />
      <Sidebar menu="warehouse" activemenu="facpara" submenu="factmaster" /> */}

      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-12 col-md-12 col-xl-12">
              <div className="col-12 col-md-12 col-xl-12"> </div>
              <h1>
                Monitor Planning{"  "}
                <a target="_blank" href={getAcademy()}>
                  <i class="fa fa-info-circle"></i>
                </a>
              </h1>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
              <div className="form-group ">
                <select
                  className="custom-select select2 form-control"
                  value={planning_month.year}
                  onChange={(e) => {
                    setplanning_month({
                      ...planning_month,
                      year: e.target.value,
                    });
                    planning_month.year = e.target.value;
                    calDate();
                    PlanningRef.current.scrollTop = 0;
                    filterPlanning(1,10,'start');
                  }}
                >
                  <option value="2020">{2020 + 543}</option>
                  <option value="2021">{2021 + 543}</option>
                  <option value="2022">{2022 + 543}</option>
                  <option value="2023">{2023 + 543}</option>
                  <option value="2024">{2024 + 543}</option>
                  <option value="2025">{2025 + 543}</option>
                  <option value="2026">{2026 + 543}</option>
                  <option value="2027">{2027 + 543}</option>
                  <option value="2028">{2028 + 543}</option>
                  <option value="2029">{2029 + 543}</option>
                  <option value="2030">{2030 + 543}</option>
                </select>
                <label htmlFor="">Year</label>
              </div>
            </div>
          </div>

          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                {month_th.map((month) => {
                  return (
                    <>
                      <li className="nav-item">
                        <a
                          className={
                            month.no === planning_month.no
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id={"custom-tabs-four-" + month.no + "-tab"}
                          data-toggle="pill"
                          href={"#custom-tabs-four-" + month.no}
                          role="tab"
                          onClick={() => {
                            setplanning_month({
                              ...planning_month,
                              no: month.no,
                              month: month.month,
                            });
                            planning_month.no = month.no;
                            planning_month.month = month.month;

                            calDate();
                            PlanningRef.current.scrollTop = 0;
                            filterPlanning(1,10,'start');
                          }}
                          aria-controls="custom-tabs-four-warehouse"
                          aria-selected="false"
                        >
                          {month.month}
                        </a>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className={"tab-pane fade show active"}
                  id="custom-tabs-four-warehouse"
                  role="tabpanel"
                  aria-labelledby={
                    "custom-tabs-four-" + planning_month.no + "-tab"
                  }
                >
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-md-8 col-xl-10">
                        <h3 class="title ng-binding">
                          Planning {">>"} {planning_month.month} ปี{" "}
                          {parseInt(planning_month.year) + 543}
                        </h3>
                      </div>

                      <div className="col-12 col-md-4 col-xl-2">
                        {planning_month.no === "4" ||
                        planning_month.no === "8" ||
                        planning_month.no === "12" ? (
                          <>
                            <div className="form-group">
                              <button
                                type="button"
                                className="btn btn-block btn-info  "
                                onClick={async () => {
                                  await getBalanceAllDataPlanning();
                                }}
                              >
                                Save Balance
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filterProduct.no}
                            onChange={(e) => {
                              setfilterProduct({
                                ...filterProduct,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Part No</label>{" "}
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <select
                            className="custom-select select2 form-control"
                            value={planning_month.modelCate}
                            onChange={(e) => {
                              setplanning_month({
                                ...planning_month,
                                modelCate: e.target.value,
                              });
                              planning_month.modelCate = e.target.value;
                            }}
                          >
                            <option value="">All</option>
                            {productModel.map((e) => {
                              return <option value={e.id}>{e.name}</option>;
                            })}
                          </select>
                          <label htmlFor="">Model&Category</label>
                        </div>
                      </div>

                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              PlanningRef.current.scrollTop = 0;
                              filterPlanning(1,10,'start');
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => clearFilter()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>

                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-info"
                            data-dismiss="modal"
                            table="table-to-xls"
                            filename={
                              "Planning Excel " +
                              planning_month.no +
                              "-" +
                              (parseInt(planning_month.year) + 543)
                            }
                            sheet="tablexls"
                            buttonText="Export Excel"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-4 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            data-toggle="modal"
                            data-target="#modal-daily_plan"
                            onClick={() => {
                              onChangeDateDaily([
                                new Date(),
                                new Date(moment().endOf("month")),
                              ]);
                              // setIsOpen1(true);
                            }}
                          >
                            Daily Plan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-custom-content"></div>
                  <div className="row">
                    <div
                      className="table-responsive"
                      style={{ height: "90vh" }}
                      id={'idPlanning'}
                      onScroll={handleScroll}
                      ref={PlanningRef}
                    >
                      <table
                        id="table-to-xls"
                        /* id="table-daily_plan" */
                        style={{
                          whiteSpace: "nowrap",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          <th
                            colSpan={2}
                            rowSpan={2}
                            style={{ border: "1px solid #ddd" }}
                          ></th>
                          <th style={{ border: "1px solid #ddd" }}></th>
                          <th
                            colSpan={dayInMonth.length}
                            style={{ border: "1px solid #ddd" }}
                          >
                            Production {"&"} Delivery Schedule Control (For
                            Extension & Torsion spring)
                          </th>
                          <th style={{ border: "1px solid #ddd" }}>
                            <font color="red">
                              <i>I</i>
                            </font>
                          </th>
                          <th
                            colSpan={4}
                            rowSpan={2}
                            style={{ border: "1px solid #ddd" }}
                          >
                            Forecast
                          </th>
                        </tr>
                        <tr>
                          <th style={{ border: "1px solid #ddd" }}></th>
                          <th
                            colSpan={Math.ceil(dayInMonth.length / 2)}
                            style={{ border: "1px solid #ddd" }}
                          >
                            {/* For Customer : */}
                          </th>
                          <th
                            colSpan={dayInMonth.length / 2}
                            style={{ border: "1px solid #ddd" }}
                          >
                            {/* Q2:AH30 */}
                          </th>
                          <th style={{ border: "1px solid #ddd" }}>
                            <font color="red">
                              <i>Month sum</i>
                            </font>
                          </th>
                        </tr>
                        <tr>
                          <th
                            colSpan={2}
                            style={{
                              backgroundColor: "#90EE90",
                              border: "1px solid #ddd",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            Part Description
                          </th>
                          <th style={{ border: "1px solid #ddd" }}>Date</th>
                          {dayInMonth.map((date, index) => {
                            /* console.log(planning_month) */
                            let color = "#";
                            if (
                              date ===
                                parseInt(moment(new Date()).format("DD")) &&
                              parseInt(moment(new Date()).format("MM")) ==
                                parseInt(planning_month.no) &&
                              parseInt(moment(new Date()).format("YYYY")) ==
                                parseInt(planning_month.year)
                            ) {
                              color = "#FFFF00";
                            } else {
                              color = "white";
                            }

                            return (
                              <>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: color,
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {date}
                                </th>
                              </>
                            );
                          })}
                          <th
                            style={{
                              border: "1px solid #ddd",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            <font color="red">
                              <i>I</i>
                            </font>
                          </th>

                          <th
                            style={{
                              border: "1px solid #ddd",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            <font color="#C71585">
                              {parseInt(planning_month.no) + 1 > 12
                                ? parseInt(planning_month.no) + 1 - 12
                                : parseInt(planning_month.no) + 1}
                            </font>
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            <font color="#C71585">
                              {parseInt(planning_month.no) + 2 > 12
                                ? parseInt(planning_month.no) + 2 - 12
                                : parseInt(planning_month.no) + 2}
                            </font>
                          </th>
                          <th
                            style={{
                              border: "1px solid #ddd",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                            }}
                          >
                            <font color="#C71585">
                              {parseInt(planning_month.no) + 3 > 12
                                ? parseInt(planning_month.no) + 3 - 12
                                : parseInt(planning_month.no) + 3}
                            </font>
                          </th>
                        </tr>
                        {product.map((product) => {
                          var sum_due = 0;
                          return (
                            <>
                              <tr>
                                <th
                                  rowSpan={3}
                                  colSpan={2}
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">{product.product_no}</font>
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">Due</font>
                                </th>
                                {product.due.map((due, index) => {
                                  let color = "#";

                                  if (
                                    index + 1 ===
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    parseInt(moment(new Date()).format("MM")) ==
                                      parseInt(planning_month.no) &&
                                    parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) == parseInt(planning_month.year)
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }
                                  var due_value = due;
                                  var color_txt = "#C71585";
                                  var po_value = product.po_order.filter(
                                    (e, indexPO) => {
                                      return indexPO === index;
                                    }
                                  );

                                  if (po_value.length > 0 && po_value[0] > 0) {
                                    due_value = po_value[0];
                                    color_txt = "blue";
                                  }

                                  if (due_value) {
                                    due_value += index === 0 ? product.next_due : 0;
                                  } else {
                                    due_value = index === 0 ? product.next_due : 0;
                                    color_txt = "black";
                                  }

                                  sum_due += due_value;
                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font color={color_txt}>
                                          {due_value !== 0
                                            ? numberWithCommas(due_value)
                                            : ""}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {
                                      numberWithCommas(
                                        sum_due
                                      ) /* product.due.reduce((a, b) => a + b)) */
                                    }
                                  </font>
                                </th>

                                <th
                                  rowSpan={8}
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.product_forecast_n2_sum
                                    )}
                                    <br />
                                    {product.product_forecast_n2.map((n2) => {
                                      return (
                                        <>
                                          {n2.source_data}
                                          <NumberFormat
                                            thousandSeparator={true}
                                            value={n2.amount_forcast}
                                            displayType="text"
                                            prefix="("
                                            suffix=")"
                                          />
                                          <br />
                                        </>
                                      );
                                    })}
                                  </font>
                                </th>
                                <th
                                  rowSpan={8}
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.product_forecast_n3_sum
                                    )}
                                    <br />
                                    {product.product_forecast_n3.map((n3) => {
                                      return (
                                        <>
                                          {n3.source_data}(
                                          {numberWithCommas(n3.amount_forcast)})
                                          {/* <NumberFormat
                                        thousandSeparator={true}
                                        value={n3.amount_forcast}
                                        displayType="text"
                                        prefix="("
                                        suffix=")"
                                      />  */}
                                          <br />
                                        </>
                                      );
                                    })}
                                  </font>
                                </th>
                                <th
                                  rowSpan={8}
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.product_forecast_n4_sum
                                    )}
                                    <br />
                                    {product.product_forecast_n4.map((n4) => {
                                      return (
                                        <>
                                          {n4.source_data}
                                          <NumberFormat
                                            thousandSeparator={true}
                                            value={n4.amount_forcast}
                                            displayType="text"
                                            prefix="("
                                            suffix=")"
                                          />
                                          <br />
                                        </>
                                      );
                                    })}
                                  </font>
                                </th>
                              </tr>

                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">Actual</font>
                                </th>
                                {product.actual.map((actual, index) => {
                                  let color = "#";

                                  if (
                                    index + 1 ==
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    parseInt(moment(new Date()).format("MM")) ==
                                      parseInt(planning_month.no) &&
                                    parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) == parseInt(planning_month.year)
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }
                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font color="blue">
                                          {actual !== 0
                                            ? numberWithCommas(actual)
                                            : ""}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.actual.reduce((a, b) => a + b)
                                    )}
                                  </font>
                                </th>
                              </tr>

                              {/*       //----------------------------------------------------- */}

                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">Balance</font>
                                </th>
                                {product.balance.map((balance, index) => {
                                  let color = "#";
                                  let color_text = "blue";
                                  if (
                                    index + 1 ===
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    parseInt(moment(new Date()).format("MM")) ==
                                      parseInt(planning_month.no) &&
                                    parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) == parseInt(planning_month.year)
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }

                                  if (balance < 0) {
                                    color_text = "red";
                                  } else if (balance > 0) {
                                    color_text = "#000";
                                  }

                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font color={color_text}>
                                          {numberWithCommas(balance)}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {/*   {numberWithCommas(
                                      product.balance.reduce((a, b) => a + b)
                                    )} */}
                                  </font>
                                </th>
                              </tr>
                              <tr>
                                <th
                                  rowSpan={2}
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">ผิวชุบ</font>
                                </th>

                                <th
                                  rowSpan={2}
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#FFFF00",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">
                                    {product.product_surface}
                                  </font>
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#008000">ส่งชุบ</font>
                                </th>
                                {product.choup_in.map((send_choup, index) => {
                                  let color = "#";
                                  if (
                                    send_choup !== 0 ||
                                    product.send_choup[index - 6] !== 0 ||
                                    product.send_choup[index - 5] !== 0 ||
                                    product.send_choup[index - 4] !== 0 ||
                                    product.send_choup[index - 3] !== 0 ||
                                    product.send_choup[index - 2] !== 0 ||
                                    product.send_choup[index - 1] !== 0
                                  ) {
                                    color = "#87CEEB";
                                  }
                                  /*    if(index +1 === parseInt(moment(new Date()).format('DD')) && moment(new Date()).format('MM') ===  planning_month.no && moment(new Date()).format('YYYY') === planning_month.year){
                   
                                     color = "#FFFF00";
                                 }else{
                                     color = "white";
                                 } */
                                  /* if (index + 1 === parseInt(moment(new Date()).format('DD')) && moment(new Date()).format('MM') === planning_month.no && moment(new Date()).format('YYYY') === planning_month.year) {

                                  if (color === "#87CEEB") {
                                    color = "red";
                                  } else {
                                    color = "#FFFF00";
                                  }

                                } */
                                  if (
                                    index + 1 ===
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    parseInt(moment(new Date()).format("MM")) ==
                                      parseInt(planning_month.no) &&
                                    parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) == parseInt(planning_month.year)
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }

                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font>
                                          {send_choup === 0
                                            ? ""
                                            : numberWithCommas(send_choup)}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.choup_in.reduce((a, b) => a + b)
                                    )}
                                  </font>
                                </th>
                              </tr>

                              {/******** Choup Out *********/}

                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#008000">รับชุบ</font>
                                </th>

                                {product.choup_out.map((send_choup, index) => {
                                  let color = "#";

                                  if (
                                    index + 1 ===
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    parseInt(moment(new Date()).format("MM")) ==
                                      parseInt(planning_month.no) &&
                                    parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) == parseInt(planning_month.year)
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }

                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font>
                                          {send_choup === 0
                                            ? ""
                                            : numberWithCommas(send_choup)}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}

                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.choup_out.reduce((a, b) => a + b)
                                    )}
                                  </font>
                                </th>
                              </tr>

                              {/********** Pack In **********/}
                              {/* <tr> */}
                              {/*   <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#000000">Pack In</font>
                                </th>

                                {product.pack_in.map((send_pack, index) => {
                                  let color = "#";

                                  if (
                                    index + 1 ===
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    moment(new Date()).format("MM") ===
                                      planning_month.no &&
                                    moment(new Date()).format("YYYY") ===
                                      planning_month.year
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }

                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font>
                                          {send_pack === 0
                                            ? ""
                                            : numberWithCommas(send_pack)}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}

                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.pack_in.reduce((a, b) => a + b)
                                    )}
                                  </font>
                                </th> */}
                              {/*        </tr> */}

                              {/******* Pack Out ********/}
                              {/*             <tr> */}
                              {/* <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#000000">Pack Out</font>
                                </th>

                                {product.pack_out.map((send_pack, index) => {
                                  let color = "#";

                                  if (
                                    index + 1 ===
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    moment(new Date()).format("MM") ===
                                      planning_month.no &&
                                    moment(new Date()).format("YYYY") ===
                                      planning_month.year
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }

                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font>
                                          {send_pack === 0
                                            ? ""
                                            : numberWithCommas(send_pack)}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}

                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.pack_out.reduce((a, b) => a + b)
                                    )}
                                  </font>
                                </th> */}
                              {/*           </tr> */}

                              <tr>
                                <th
                                  rowSpan={3}
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#9ACD32",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">Stock</font>
                                </th>
                                <th
                                  rowSpan={3}
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#9ACD32",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="blue">
                                    {numberWithCommas(
                                      product.product_stock || 0
                                    )}
                                  </font>
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  รับเข้า
                                </th>
                                {product.fg_in.map((fg_in, index) => {
                                  let color = "#";
                                  if (
                                    index + 1 ===
                                      parseInt(
                                        moment(new Date()).format("DD")
                                      ) &&
                                    parseInt(moment(new Date()).format("MM")) ==
                                      parseInt(planning_month.no) &&
                                    parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) == parseInt(planning_month.year)
                                  ) {
                                    color = "#FFFF00";
                                  } else {
                                    color = "white";
                                  }
                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: color,
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        {fg_in === 0
                                          ? ""
                                          : numberWithCommas(fg_in)}
                                      </th>
                                    </>
                                  );
                                })}
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  <font color="#C71585">
                                    {numberWithCommas(
                                      product.fg_in.reduce((a, b) => a + b)
                                    )}
                                  </font>
                                </th>
                              </tr>

                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#9ACD32",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  Balance
                                </th>
                                {product.bal_stock.map((bal_stock) => {
                                  let color_text = "black";
                                  if (bal_stock < 0) {
                                    color_text = "red";
                                  }

                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: "#9ACD32",
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font color={color_text}>
                                          {numberWithCommas(bal_stock || 0)}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#9ACD32",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                ></th>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#9ACD32",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  Actual
                                </th>
                                {/* {product.act_stock.map((act_stock) => {
                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: "#9ACD32",
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        {numberWithCommas(act_stock || 0)}
                                      </th>
                                    </>
                                  );
                                })} */}
                                {product.act_stock.map((act_stock) => {
                                  let color_text = "black";
                                  if (act_stock < 0) {
                                    color_text = "red";
                                  }

                                  return (
                                    <>
                                      <th
                                        style={{
                                          border: "1px solid #ddd",
                                          backgroundColor: "#9ACD32",
                                          paddingLeft: "20px",
                                          paddingRight: "20px",
                                        }}
                                      >
                                        <font color={color_text}>
                                          {numberWithCommas(act_stock || 0)}
                                        </font>
                                      </th>
                                    </>
                                  );
                                })}
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    backgroundColor: "#9ACD32",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                ></th>
                              </tr>
                              {/*    ------------------------------------------------------------- */}
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>

                  <div className="table-responsive" style={{ display: "none" }}>
                    <table
                      id="table-daily_plan"
                      style={{ whiteSpace: "nowrap", width: "100%" }}
                    >
                      <tr>
                        <td
                          rowSpan={2}
                          colSpan={13}
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          <h1>
                            แผนงานประจำวันที่{" "}
                            {moment(startDateDaily).format("DD/MM/")}
                            {parseInt(moment(startDateDaily).format("YYYY")) +
                              543}{" "}
                            ถึง {moment(endDateDaily).format("DD/MM/")}
                            {parseInt(moment(endDateDaily).format("YYYY")) +
                              543}{" "}
                            ประเภท{" "}
                            {model_category_list.map((e, index) => {
                              return (
                                e.label +
                                (index + 1 === model_category_list.length
                                  ? ""
                                  : " & ")
                              );
                            })}
                          </h1>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          ผู้ออกแบบ :
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          จิรบวร
                        </td>
                        <td
                          rowSpan={3}
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          หมายเหตุ
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          ผู้รับแผน :
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          ศุภกิจ - เหมือน
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          DUE/ลูกค้า
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          กำหนดงานออก
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          PART NO
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          PART NAME
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          CUS
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          LOT
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          ยอดดิล
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          WIP ส่งชุบ
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          WIP รับชุบ
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          จำนวนงานที่ต้องการ
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          ยอดที่ต้องผลิต
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          จำนวนที่ไม่พอส่งทั้งเดือน
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          N+1
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          จำนวนคนที่ใช้
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid #000",
                          }}
                        >
                          Machine Plan
                        </td>
                      </tr>
                      {dataDailyPlan.map((ddp) => {
                        var under_amount = ddp.balance;
                        return (
                          <>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {/*                                 {moment(ddp.product_due,'YYYYMMDD').format('DD/MM/YYYY')} */}
                                {moment(ddp.product_due, "YYYYMMDD").format(
                                  "DD/MM/"
                                )}
                                {parseInt(
                                  moment(ddp.product_due, "YYYYMMDD").format(
                                    "YYYY"
                                  )
                                ) + 543}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              ></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.product_no}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.product_name}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.product_customer}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              ></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.po_order}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.product_wip_in}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.product_wip_out}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.po_order -
                                  ddp.product_wip_out -
                                  ddp.product_wip_in}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.po_order -
                                  ddp.product_wip_out -
                                  ddp.product_wip_in <
                                1
                                  ? 0
                                  : ddp.product_min}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {under_amount < 0 ? 0 : under_amount}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              >
                                {ddp.product_n2}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              ></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              ></td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #000",
                                }}
                              ></td>
                            </tr>
                          </>
                        );
                      })}
                      <tr></tr>
                    </table>
                  </div>
                  {/*   <div className="tab-custom-content"></div>
                  <div className="row">

                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="modal fade" id="modal-daily_plan">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-label"> Daily Plan</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  /*  onClick={() => {
                    setMatSTC_select({
                      ...MatStc_select,
                      year: "",
                      month: "",
                    });
                    MatStc_select.year = "";
                    MatStc_select.month = "";
                  }} */
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="">Model&Category</label>
                    <MultiSelect
                      /*  disabled={disable} */
                      hasSelectAll={false}
                      options={model_category}
                      value={model_category_list}
                      onChange={setmodel_category_list}
                      labelledBy="Select Model&Category"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="">
                      Select Date Range:{" "}
                      {`${moment(startDateDaily || new Date()).format(
                        "DD/MM/YYYY"
                      )} - ${moment(
                        endDateDaily || startDateDaily || new Date()
                      ).format("DD/MM/YYYY")}`}
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        minHeight: "250px",
                      }}
                    >
                      {isOpen1 && (
                        <DatePicker
                          selected={startDateDaily}
                          onChange={onChangeDateDaily}
                          minDate={new Date()}
                          startDate={startDateDaily}
                          endDate={endDateDaily}
                          selectsRange
                          inline
                          showDisabledMonthNavigation
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                Date
                {/*  {MatStc_select.year != "" && MatStc_select.month != "" ? ( */}
                <div style={{ display: "none" }}>
                  <ReactHTMLTableToExcel
                    id="excel-daily_plan"
                    className="btn button btn-primary"
                    data-dismiss="modal"
                    table="table-daily_plan"
                    filename={
                      "Daily Plan" +
                      " " +
                      moment(new Date()).format("DD-MM-") +
                      (parseInt(moment(new Date()).format("YYYY")) + 543)
                    }
                    sheet="tablexls"
                    buttonText="Export to Daily Plan"
                  />
                </div>
                <button
                  type="button"
                  className="btn button btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    GetDailyPlanData();
                  }}
                >
                  Export to Daily Plan
                </button>
                {/*  ) : (
                  ""
                )} */}
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  // onClick={() => setIsOpen1(false)}
                  /*  onClick={() => {
                    setMatSTC_select({
                      ...MatStc_select,
                      year: "",
                      month: "",
                    });
                    MatStc_select.year = "";
                    MatStc_select.month = "";
                  }} */
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modal
          size="md"
          className="Modal_Center_LeftRight"
          isOpen={openModal}
          toggle={() => {
            setOpenModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setOpenModal(false);
            }}
          >
            <h4 className="modal-title" id="modalStockBalance">
              <b>Save Balance</b>
            </h4>
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="table-responsive">
                <table
                  style={{
                    whiteSpace: "nowrap",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th style={{ border: "1px solid #ddd" }}>Part No.</th>
                    <th style={{ border: "1px solid #ddd" }}>Balance</th>
                  </tr>

                  {balanceStockData.data.map((item, index) => {
                    return (
                      <tr>
                        <td style={{ border: "1px solid #ddd" }}>
                          {item.product_no}
                        </td>
                        <td style={{ border: "1px solid #ddd" }}>
                          <div
                            className="form-group"
                            style={{ margin: "5px 15px" }}
                          >
                            <input
                              type="number"
                              className="form-control"
                              style={{ textAlign: "center" }}
                              required="false"
                              value={item.saveBalance}
                              onChange={(e) => {
                                const temp = balanceStockData.data;

                                temp[index].saveBalance = e.target.value;

                                setBalanceStockData({
                                  ...balanceStockData,
                                  data: temp,
                                });
                                balanceStockData.data = temp;
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              ยกเลิก
            </button>

            <button
              type="button"
              className="btn btn-success"
              onClick={async () => {
                setOpenModal(false);
                await saveBalanceStock();
              }}
            >
              บันทึก
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Planning_CTS;
