import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import axios from "axios";
import Configs from "../../config";
import {
    getOem,
    getToken,
    getUser,
    getAcademy,
    getFeature,
} from "../../Utils/Common";
import NumberFormat from "react-number-format";
import moment from "moment";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import External_dev_train_report from "./External_dev_train_report";

function External_dev_train(params) {

    useEffect(() => {
        getCourseType();
        // getAllCourse();
        filterCourse();
        // Course();
    }, []);

    const id = useParams();

    const [allCourse, setallCourse] = useState([])
    const [Course, setCourse] = useState([]);

    const [filter_course, setfilter_course] = useState({
        course_name: "",
        course_price: "",
        course_type: "",
    });
    // console.log(filter_course);
    const [CourseType, setCourseType] = useState([])
    const getCourseType = async () => {
        axios({
            method: "get",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getCourseType",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                setCourseType(response.data.data);
                // console.log(response.data.data);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    };

    const filterCourse = async () => {
        let get_filter_course = {
            course_name: filter_course.course_name.trim() || null,
            course_price: filter_course.course_price > 0 ? filter_course.course_price : null,
            course_type: filter_course.course_type || null,
        };
        // console.log(get_filter_course);
        axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterCourse",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: get_filter_course,
        })
            .then(async function (response) {
                const courses = response.data.data;
                setallCourse(courses);
                // console.log('fillterCourse--', response.data.data);
                getHasEmpInCourse();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const clearFilter = async () => {
        setfilter_course({
            ...filter_course,
            course_name: "",
            course_price: "",
            course_type: "",
        });
        filter_course.course_name = "";
        filter_course.course_price = "";
        filter_course.course_type = "";
        // getAllCourse();
        filterCourse();
    };

    function addpage(params) {
        window.location.href = "/Human_Resource/employee_train/add";
    }

    function deldata(id) {
        console.log("id", id);
        Swal.fire({
            title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
            text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            cancelButtonText: `ไม่`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    method: "get",
                    url:
                        Configs.API_URL_hrMagenatement +
                        "/api/hrManagement/delCourseById?id=" +
                        id,
                    headers: {
                        Authorization: getToken(),
                        "Content-Type": "application/json",
                        "X-TTT": Configs.API_TTT,
                    },
                })
                    .then(function (response) {
                        Swal.fire({
                            icon: "success",
                            text: "เสร็จสิ้น",
                        }).then(() => {
                            window.location.href = "/Human_Resource/HRD/External_dev_train";
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
                    });
            }
        });
    }

    const getHasEmpInCourse = async () => {
        const data = allCourse;
        axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getHasEmpInCourse",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then(function (response) {
                // setReportCheck(response.data.data);
                // console.log(response.data.data);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    };

    const rowsData = [];

    for (let index = 0; index < allCourse.length; index++) {
        const rowItem = {};
        rowItem["no"] = index + 1;
        rowItem["course_image"] = allCourse[index].course_image || null;
        rowItem["course_name"] = allCourse[index].course_name || null;
        rowItem["course_type"] = allCourse[index].course_type || null;
        rowItem["course_price"] =
            (<div>
                <NumberFormat
                    displayType={"text"}
                    thousandsGroupStyle="thousand"
                    thousandSeparator={true}
                    value={allCourse[index].course_price || 0}
                />
            </div>)
        rowItem["course_start"] = moment(allCourse[index].course_start).format('DD-MM-YYYY') || null;
        rowItem["course_end"] = moment(allCourse[index].course_end).format('DD-MM-YYYY') || null;
        rowItem["course_type_train"] = allCourse[index].course_type_train || null;
        rowItem["export_pdf"] = (
            <div className="row" style={{ textAlign: "center" }}>
                <div className="col-xl-12 col-md-12 col-xs-12 ">
                    <button className="btn btn-block btn-info" disabled={allCourse[index].disableReport} onClick={() => { External_dev_train_report(allCourse[index].course_id) }}>
                        <i class="fas fa-file-pdf"></i>
                    </button>
                </div>
            </div>
        );
        rowItem["mgt"] = (
            <div className="row" style={{ textAlign: "center" }}>
                <div className="col-xl-4 col-md-4 col-xs-4 ">
                    <a
                        href={"/Human_Resource/employee_train/read/" + allCourse[index].course_id}
                        id="btn_read"
                        // key={Employee[index].emp_id}
                        className="btn btn-xs "
                    >
                        <i class="fas fa-eye"></i>
                        {"   "}
                    </a>
                </div>
                <div className="col-xl-4 col-md-4 col-xs-4 ">
                    <a
                        href={"/Human_Resource/employee_train/edit/" + allCourse[index].course_id}
                        id="btn_edit"
                        // key={Employee[index].emp_id}
                        className=" btn btn-xs "

                    >
                        <i class="fas fa-pencil-alt"></i>
                    </a>


                </div>
                <div className="col-xl-4 col-md-4 col-xs-4 ">
                    <a
                        // key={Course[index].emp_id}
                        id="btn_delete"
                        className=" btn btn-xs "
                        onClick={deldata.bind(this, allCourse[index].course_id)}
                    >
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </div>
            </div>
        );
        rowsData.push(rowItem);
    }

    const data = {
        columns: [
            {
                label: "ลำดับ",
                field: "no",
                sort: "desc",
                width: 50,
            },
            {
                label: "รูปภาพ",
                field: "course_image",
                sort: "desc",
                width: 50,
            },
            {
                label: "ชื่อคอร์ส",
                field: "course_name",
                sort: "desc",
                width: 50,
            },
            {
                label: "ประเภทคอร์ส",
                field: "course_type",
                sort: "desc",
                width: 50,
            },
            {
                label: "ราคาคอร์ส",
                field: "course_price",
                sort: "desc",
                width: 50,
            },
            {
                label: "วันที่เริ่ม",
                field: "course_start",
                sort: "desc",
                width: 50,
            },
            {
                label: "วันที่สิ้นสุด",
                field: "course_end",
                sort: "desc",
                width: 50,
            },
            {
                label: "รูปแบบการอบรม",
                field: "course_type_train",
                sort: "desc",
                width: 50,
            },
            {
                label: "Export to PDF",
                field: "export_pdf",
                sort: "desc",
                width: 50,
            },
            {
                label: "จัดการ",
                field: "mgt",
                sort: "desc",
                width: 50,
            },
        ],
        rows: rowsData,
    };

    return (
        <div className="wrapper">
            <div className="content-wrapper">

                <section className="content-header">

                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>การพัฒนาและการฝึกอบรมภายนอก</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">Human Resource</li>
                                    <li className="breadcrumb-item active">External_dev_train</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row mb-2">

                            <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group ">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="course_name"
                                        required="false"
                                        value={filter_course.course_name}
                                        onChange={(e) => {
                                            setfilter_course({
                                                ...filter_course,
                                                course_name: e.target.value,
                                            })
                                            /* setfiller_employee({
                                                ...filler_employee,
                                                emp_no: e.target.value,
                                            }); */
                                        }}
                                    />
                                    <label htmlFor="">ชื่อคอร์ส</label>{" "}
                                </div>
                            </div>

                            <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group ">
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        required="false"
                                        id="course_price"
                                        value={filter_course.course_price}
                                        onChange={(e) => {
                                            setfilter_course({
                                                ...filter_course,
                                                course_price: e.target.value,
                                            })
                                        }}
                                    />
                                    <label htmlFor="">ราคาคอร์สตั้งแต่ 0 ถึง</label>{" "}
                                </div>
                            </div>

                            <div className="col-12 col-md-4 col-xl-2">
                                <div className="form-group ">
                                    <select
                                        className="form-control"
                                        type="text"
                                        required="false"
                                        id="course_type"
                                        value={filter_course.course_type}
                                        onChange={(e) => {
                                            setfilter_course({
                                                ...filter_course,
                                                course_type: e.target.value,
                                            })
                                        }}
                                    >
                                        <option value=""></option>
                                        {CourseType.map((el) => {
                                            return (
                                                <option value={el.id}>
                                                    {el.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <label htmlFor=""> ประเภทคอร์ส</label>
                                </div>
                            </div>

                            <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        id="btn_search"
                                        className="btn btn-block btn-info  "
                                        onClick={() => {
                                            filterCourse();
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>

                            <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        id="btn_clear"
                                        className="btn btn-block btn-info"
                                        onClick={() => clearFilter()}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6 col-md-4 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        id="btn_add"
                                        className="btn btn-block btn-primary"
                                        onClick={addpage}
                                    >
                                        Add Course
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="content">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-tools"></div>
                        </div>

                        <div className="card-body">
                            <div
                                className="table-responsive "
                            >
                                <MDBDataTable
                                    sortable={false}
                                    className="table table-head-fixed"
                                    striped
                                    bordered
                                    hover
                                    fixedHeader
                                    data={data}
                                />
                            </div>
                        </div>

                        <div className="card-footer"></div>
                    </div>
                </section>

            </div> {/* content-wrapper */}
        </div> /* wrapper */
    );
}

export default External_dev_train;
