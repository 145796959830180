import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import moment from 'moment';
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import NumberFormat from "react-number-format";
import Sidebar from "../../component/Sidebar";
import { useParams } from "react-router";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import QRCode2 from "qrcode";
import { Get_font_pdf } from "../../assets/font/pdf_font";
import jsPDF from "jspdf";
function Order_Raw_Material(params) {
  const [order_material, setOrder_material] = useState([]);
  const [filler_order_material, setFiller_order_material] = useState({
    order_no: "",
    start_date: "",
    end_date: "",
    oem_id: getOem(),
  });

  const [disable, setdisable] = useState(null);


  const [view, setView] = useState({
    read: "read",
    edit: "edit",
  });


  const { id } = useParams();
  /* useEffect(() => {
    if (params.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (params.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []); */
  
  const [view_order, setView_order] = useState([]);

  const [view_order2, setView_order2] = useState([]);


  const [filler_view_order, setFiller_view_order] = useState({
    material_order_id: "",
  });
  const [view_order_no, setView_order_no] = useState({
    order_no: "",
    order_id: "",
  });

const[order_index,setorder_index] =  useState("");


const CustomInput = ({ value, onClick }) => ( 
      
  <
  div className = "input-group" >
  < input type = "text"
  className = "form-control float-left"
  onClick = { onClick }
  value = { value }
  /> <div className = "input-group-prepend" >
  <span className = "input-group-text" >
  <i className = "far fa-calendar-alt" />
  </span>

  { /*  <label>test</label> */ } 
  </div> 
  </div>


)


/*   var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */

  const [modal, setModal] = useState(false);
  const toggle_modal = () => setModal(!params);





  async function update_order_delivery_date(e, data) {
    /* console.log(e.target.name) */

    view_order[e.target.name].delivery_date = e.target.value;
    //Summary();
   // Summary();
  
  }

   async function update_order_rrr(e) {

    view_order[e.target.name].rrr = e.target.value;
    //Summary();
   // Summary();
  
  }

  async function update_order_length(e, data) {
  /*   console.log(e.target.name) */

    view_order[e.target.name].length = e.target.value;
    //Summary();
   // Summary();
  
  }

  async function update_order_weitght(e, data) {
    /* console.log(e.target.name) */
    
    view_order[e.target.name].weitght = e.target.value;
    //Summary();
   // Summary();
  
  }

  async function update_order_dd_start(e,date) {
    /* console.log(e.target.name);
    console.log(date) ;*/
    view_order[e.target.name].dd_start = e.target.value;
    //view_order[e.target.name].dd_start = startDatedd_off_line; เอาเวลาด้วย
    //Summary();
   // Summary();
  
  }

  async function update_order_dd_off_line(e, data) {
    /* console.log(e.target.value)
    console.log(startDatedd_off_line) */

    view_order[e.target.name].dd_off_line = e.target.value;
    //view_order[e.target.name].dd_off_line = startDatedd_off_line;
    //view_order[e.target.name].dd_off_line = startDatedd_off_line; เอาเวลาด้วย
    //Summary();
   // Summary();
  
  }

  const [getmaterialheader, setGetmaterialheader] = useState({
    pbn_no: "",
    cus_route: "",
    date_off_line: "",
    date_loading: "",
    po_no: "",
    osl: "",
  });


  function save_material_detail() {
    
       const data_header = {
         pbn_no: getmaterialheader.pbn_no,
         cus_route:getmaterialheader.cus_route,
         po_no:getmaterialheader.po_no,
         date_off_line:getmaterialheader.date_off_line,
         date_loading:getmaterialheader.date_loading,
         osl:getmaterialheader.osl,
      };
       const data_body = [];
       for (let index = 0; index < view_order.length; index++) {
         const element = {};
    
         element["id"] = view_order[index].id;
         element["no"] = view_order[index].no;
         element["name"] = view_order[index].name;
         element["year"] = view_order[index].year;
         element["month"] = view_order[index].month;

         element["material_spec"] = view_order[index].material_spec;
         element["material_size"] = view_order[index].material_size;

         element["material_type"] = view_order[index].material_type;
         element["buy_price"] = view_order[index].buy_price;

         element["material_supplier"] = view_order[index].material_supplier;
         element["material_supplier_id"] = view_order[index].material_supplier_id;
         element["material_unit"] = view_order[index].material_unit;
         element["oem_id"] = view_order[index].oem_id;
         element["material_amount"] = view_order[index].material_amount;
         element["material_forcast_id"] = view_order[index].material_forcast_id;
         element["material_material_id"] = view_order[index].material_material_id;
         element["year_month"] = view_order[index].year_month;
         element["in_hand_amount"] = view_order[index].in_hand_amount;
         element["nmo"] = view_order[index].material_amount;
         element["total"] = view_order[index].buy_price * view_order[index].product_amount;

        
                  
                    

         

 
         data_body.push(element);
       }
       var temp ={
         order_id:view_order_no.order_id,
         header:data_header,
         /* pbn_no:getProductheader.pbn_no,
         cus_route:getProductheader.cus_route,
         po_no:getProductheader.po_no,
         date_off_line:getProductheader.date_off_line,
         date_loading:getProductheader.pbn_no,
         header:getProductheader.date_loading,
         osl:getProductheader.osl, */

         body:data_body
       }
       console.log(view_order);
       console.log(data_header);
       console.log(temp);
        axios({
         method: "post",
         url: Configs.API_URL + "/materialPlaning/orderMaterial/updateListMaterial",
         headers: {
           Authorization: getToken(),
           "Content-Type": "application/json",
           "X-TTT": Configs.API_TTT,
         },
         data: temp,
       })
       .then(function (response) {
        console.log(response.data);
        
        Swal.fire({
         title: "Saving",
         allowEscapeKey: false,
         allowOutsideClick: false,
         timer: 300,
         onOpen: () => {
           Swal.showLoading();
         },
       }).then((result) => {
         Swal.fire({
           icon: "success",
           title: "Save",
           showConfirmButton: false,
           timer: 1500,})

           .then((result) => {
             window.location.href =
               "/Material_Requirement_Planning/Order_Raw_Material";
           });
         })
    
         })
         .catch(function (error) {
 
           console.log(error);
           Swal.fire(
             "Warning",
             "Year and Month not found!!!",
             "warning"
           ); 
        
         });
       
     
   }

  function cancle_order(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancle order it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/materialPlaning/orderMaterial/cancle?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Cancle!",
                "Your order has been cancle.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Material_Requirement_Planning/Order_Raw_Material";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }



  const Getmaterial_order = async () => {
    
    console.log(filler_order_material);
    axios({
      method: "post",
      url: Configs.API_URL + "/materialPlaning/orderMaterial/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_order_material
    })
      .then(function (response) {
        /* const order_list_product = [];
        for (let i = 0; i < response.data.list_product.length; i++) {
          const element = {};
          element["rrr"] = response.data.list_product[index].rrr;

          order_list_product.push(element);

        }


        console.log(order_list_product); */
        setOrder_material(response.data);
        //setOrder_product(order_list_product);
        console.log(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
    
  };

  


  useEffect(() => {
    Getmaterial_order();
   // GetView_order();
   
  }, []);


  





  const clearFilter = async () => {
    await setFiller_order_material({
      ...filler_order_material,
      order_no:"",
      start_date: "",
      end_date: "",

    });
    filler_order_material.order_no = "";
    filler_order_material.start_date =  "";
    filler_order_material.end_date =  "";
    Getmaterial_order();
  };


 
  function form(id,no,list_material,list_header,mode) {
    console.log(mode)
    if(mode == "read"){
      setdisable(true);
      
    }
    else{
      setdisable(false);
      
    }

    if(getmaterialheader.pbn_no!=""){
      getmaterialheader.pbn_no = "";
      getmaterialheader. cus_route = "";
      getmaterialheader.date_off_line = "";
      getmaterialheader.date_loading = "";
      getmaterialheader.po_no = "";
      getmaterialheader.osl = "";
    }

    if(list_header!=null){
    
    setGetmaterialheader({
      ...getmaterialheader,
      pbn_no:list_header.pbn_no,
      cus_route:list_header.cus_route,
      date_off_line:list_header.date_off_line,
      date_loading:list_header.date_loading,
      po_no:list_header.po_no,
      osl:list_header.osl,
      

    });
  }
  
  if(list_material.length > 0 ){
    console.log(list_material);
    setView_order();
  }





    setView_order_no({...view_order_no,order_no:no,order_id:id});
    setorder_index(order_index);
    setView_order(list_material);
    /* if(list_header!=null){
      setGetProductheader(list_header);
    } */
    setFiller_view_order({ ...filler_view_order, material_order_id:id});  
  }


  const [startDatedelivery_date, setStartDatedelivery_date] = useState(new Date());
  const [startDatedd_start, setStartDatedd_start] = useState(new Date());
  const [startDatedd_off_line, setStartDatedd_off_line] = useState(new Date());

  const rowsData = [];
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  for (var index = 0; index < view_order.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["material_no"] = view_order[index].material_no;
    rowItem["material_name"] = view_order[index].material_name;


    rowItem["qty"] = numberWithCommas(view_order[index].next_order);
    rowItem["total"] = numberWithCommas(view_order[index].total);
    /* rowItem["rm"] = view_order[index].mat_size;
    rowItem["cut"] = view_order[index].c_leng;
    rowItem["spec"] = view_order[index].mat_spec;
    rowItem["use_pcs"] = view_order[index].use_pcs = (view_order[index].c_leng*view_order[index].order)/6000;
    rowItem["qty_cut"] = view_order[index].qty_cut = ((view_order[index].use_pcs*6000)/view_order[index].c_leng); */



    /* rowItem["rrr"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat
          format="####-##/#" 
          //placeholder="DD/MM/YY" 
          //mask={['D','D', 'M', 'M', 'Y','Y','Y','Y']} 
          type = "text"
          className="form-control"
          required
          disabled={disable}
          allowNegative ={false}
          id={view_order[index]}
          name={index}
          disabled={disable}
          thousandSeparator={true}
          value={view_order[index].rrr || ""}
          onChange={update_order_rrr.bind(this)}       
        />
        
        </div>
        </div>
    );
    rowItem["length"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat 
          type = "text"
          className="form-control"
          required
          disabled={disable}
          allowNegative ={false}
          name={index}
          thousandSeparator={true}
          value={view_order[index].length || "" }
          onChange={update_order_length.bind(this)}
        />
        </div>
        </div>
    );


    rowItem["weitght"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat
          
          type = "text"
          className="form-control"
          required
          disabled={disable}
          allowNegative ={false}
          name={index}
          thousandSeparator={true}
          value={view_order[index].weitght || ""}
          onChange={update_order_weitght.bind(this)}
        />
        </div>
        </div>
    );





    rowItem["delivery_date"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat 
        format="##/##/####" 
        placeholder="DD/MM/YY" 
        mask={['D','D', 'M', 'M', 'Y','Y','Y','Y']} 
        type = "text"
        className="form-control"
        required
        disabled={disable}
        allowNegative ={false}
        name={index}
        thousandSeparator={true}
        value={view_order[index].delivery_date || ""}
        onChange={update_order_delivery_date.bind(this)}
        />
        
        </div>
        </div>
    );
    rowItem["dd_start"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat 
        format="##/##/####" 
        placeholder="DD/MM/YY" 
        mask={['D','D', 'M', 'M', 'Y','Y','Y','Y']} 
        type = "text"
        className="form-control"
        required
        disabled={disable}
        allowNegative ={false}
        name={index}
        thousandSeparator={true}
        value={view_order[index].dd_start || ""}
        onChange={update_order_dd_start.bind(this)}
        />

        </div>
        </div>

    );
    rowItem["dd_off_line"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat 
        format="##/##/####" 
        placeholder="DD/MM/YY" 
        mask={['D','D', 'M', 'M', 'Y','Y','Y','Y']} 
        type = "text"
        className="form-control"
        required
        disabled={disable}
        allowNegative ={false}
        name={index}
        thousandSeparator={true}
        value={view_order[index].dd_off_line}
        onChange={update_order_dd_off_line.bind(this) || ""}
        />
        </div>
        </div>
    ); */
    
    


    rowsData.push(rowItem);
  }


  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material No",
        field: "material_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "material_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qty",
        field: "qty",
        sort: "asc",
        width: 50,
      },
      {
        label: "Total Price",
        field: "total",
        sort: "asc",
        width: 50,
      },
     /*  {
        label: "Material Size",
        field: "material_size",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "Qty",
        field: "order",
        sort: "asc",
        width: 50,
      }, */
      /* {
        label: "Delivery_Date",
        field: "delivery_date",
        sort: "asc",
        width: 20,
      }, */
      /* {
        label: "RM",
        field: "rm",
        sort: "asc",
        width: 50,
      },
      {
        label: "CUT",
        field: "cut",
        sort: "asc",
        width: 50,
      },
      {
        label: "RRR",
        field: "rrr",
        sort: "asc",
        width: 50,
      },
      {
        label: "Length",
        field: "length",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "USE/PCS",
        field: "use_pcs",
        sort: "asc",
        width: 50,
      },
      {
        label: "Weitght",
        field: "weitght",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qty Cut",
        field: "qty_cut",
        sort: "asc",
        width: 50,
      },
      {
        label: "DD.Start",
        field: "dd_start",
        sort: "asc",
        width: 50,
      },
      {
        label: "DD Off-Line",
        field: "dd_off_line",
        sort: "asc",
        width: 50,
      }, */
   
   
     
      
    ],
    rows: rowsData,
  };
  
  const rowsData_order = [];
 
  
 
  
  for (var index = 0; index < order_material.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["order_no"] = order_material[index].order_no;
  /*   if(order_product[index].is_active == true){
    rowItem["status"] = "OK";
    }else{
    rowItem["status"] = "CANCLE";
    } */
    rowItem["date"] = moment(order_material[index].create_date).format('DD/MM/yyyy ::: HH:mm')+" น.";
   
    
    rowItem["mgt"] = (
      <div className="row">


     {/*    <div className="col-4">
          <a
          data-toggle="modal"
          data-target="#modal-xl"
            onClick={form.bind(this, 
              order_product[index].id,
              order_product[index].order_no, 
              order_product[index].list_product,
              order_product[index].list_header,index,
              "edit",
              
              
              )}
            className=" btn btn-xs "
          >

            <i class="far fa-calendar-plus"></i>{" "}
          </a>
        </div> */}


        <div className="col-4">
          <a
          data-toggle="modal"
          data-target="#modal-xl"
            onClick={form.bind(this, 
              order_material[index].id,
              order_material[index].order_no, 
              order_material[index].list_material,
              order_material[index].list_header,
              "read",
              

              )}
            className=" btn btn-xs "
          >

            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        
        <div className="col-4">
        <a
            key={order_material[index].id}
            className=" btn btn-xs "
            onClick={cancle_order.bind(this, order_material[index].id)}
          >
             <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData_order.push(rowItem);
  }
  const data_order = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Order No",
        field: "order_no",
        sort: "asc",
        width: 50,
      },
     /*  {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mgt",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      
   
  
    ],
    rows: rowsData_order,
  };


  async function SetDate_start(date){
    setFiller_order_material({ ...filler_order_material, start_date: date})

   }
    async function SetDate_end(date){
      setFiller_order_material({ ...filler_order_material, end_date: date})

   }
  
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="pp" activemenu="order" submenu="none" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Order Material{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Order Material 
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                <input
                    type="text"
                    className="form-control"
                    value={filler_order_material.order_no.trim()}
                    onChange={(e) => {
                      setFiller_order_material({ ...filler_order_material, order_no: e.target.value.trim() });
                    }} 
                    required
                  />
                  <label htmlFor="">Order No.</label>
                  </div>
                  </div>
                 
             
           
            <div className="col-md-0.5">
        <label > Date &nbsp;</label>
        </div>
        
        <div className="col-md-1.5">
        <div className="form-group ">
 
        <DatePicker selected = {filler_order_material.start_date}
        dateFormat = {"dd-MM-yyyy"}
        onChange = {
          async(date) =>
          SetDate_start(date)   
          }
        selectsStart startDate = {filler_order_material.start_date}
        endDate = {filler_order_material.end_date}
        customInput = {<CustomInput/>}
        
        /> 
        </div>
        </div>

        <div className="col-md-0.5">
        <label>&nbsp;&nbsp;  Between  &nbsp;&nbsp;</label> 
        </div> 
        <div className="col-md-1.5">
        <div className="form-group ">
        <DatePicker 
        selected = {filler_order_material.end_date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>
          SetDate_end(date)   
          }
        selectsEnd startDate = { filler_order_material.start_date }
        endDate = { filler_order_material.end_date }
        minDate = { filler_order_material.start_date }
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
        </div>
        </div>
              
      
                  <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      Getmaterial_order();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>

            </div>
            {" "}
            
          </div>
          
         
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Order Material</h3>
             
             
             
            
            </div>

            <div className="card-body ">
              <div
                className="table-responsive" /* style={{ height: "500px" }} */
              >
              
                <MDBDataTable
                sortable = {false}
                  //className="table table-head-fixed"
                  striped
                  id="table-to-xls"
                  bordered
                  hover
                  //fixedHeader
                  data={data_order}
                />

    {/*       <div style={{display:"none"}} >

              <MDBDataTable    
                  className="table table-head-fixed" 
                  striped
                  entries={4096}
                  id="table-to-xls_all"
                  bordered
                  hover
                  fixedHeader
                  data={data}
                
                />
                </div> */}
        

              </div>
            </div>
            <div className="card-footer">
              {/* <Button ref={modal_click} color="info" onClick={toggle_modal}>111111111</Button> */}
            </div>
          </div>
        </section>
      
      
      </div>
   



      <div className="modal fade" id="modal-xl">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Order No : {view_order_no.order_no}</h1>

              
              
                  
              
        
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>







            {/* <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.pbn_no || ""}
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          pbn_no: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">PBN_NO</label>{" "}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.cus_route || ""}
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          cus_route: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">Cus_Route</label>{" "}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.po_no || ""}
                      
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          po_no: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">PO.NO</label>{" "}
                </div>
              </div>
            </div>
          </div>




          <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.date_off_line || ""}
                  onChange={(e) => {
                    setGetProductheader({
                      ...getProductheader,
                      date_off_line: e.target.value,
                    });
                  }}
                />
                  <label htmlFor="">Date_Off_Line</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.date_loading || ""}
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          date_loading: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">Date_Loading</label>{" "}
                </div>
              </div>
              
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.osl || "" }
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          osl: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">OSL</label>
                </div>
              </div>
            </div>
          </div> */}







        
        


            <div className="modal-body">
              <div className="row">
                <div className="col-2"></div>
                
               
              </div>
              <div className="row">
              <div
                className="table-responsive" style={{ height: "500px"}} 
              >
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  id="table-to-xls"
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
                </div>

                <div className="col-4"></div>
              </div>
            </div>


            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-danger"
              /*   onClick={Getamount} */
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={save_product_detail}
                data-dismiss="modal"
                aria-label="Close"
              >
                Save Changes
              </button> */}
            </div>

          </div>
        </div>
      </div>


      

     {/*  <Footter /> */}
    </div>
  );
}
export default Order_Raw_Material;
/* Approve Production Item */
