import React, { useEffect, useState, useRef } from "react";

import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../../config";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Table_Supplier_Cuteplus() {
  const [SupplierType, setSupplierType] = useState([]);
  const [Filter, setFilter] = useState({
    supplier_code: "",
    supplier_name: "",
    supplier_type: "",
  });
  const [Supplier, setSupplier] = useState({
    data: [],
  });

  useEffect(async () => {
    await getSupplierType();
    await FilterSupplier();
  }, []);

  async function getSupplierType() {
    let data = {
      st_company_id: getUser().com,
      st_oem_id: getOem(),
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/GetSupplierTypeFill",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        setSupplierType(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function FilterSupplier() {
    let data = {
      company_id: getUser().com,
      oem_id: getOem(),
      supplier_code:
        Filter.supplier_code == "" ? null : Filter.supplier_code.trim(),
      supplier_name:
        Filter.supplier_name == "" ? null : Filter.supplier_name.trim(),
      supplier_type: Filter.supplier_type == "" ? null : Filter.supplier_type,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/FilterSupplier",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        setSupplier(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function clear() {
    setFilter({
      ...Filter,
      supplier_code: "",
      supplier_name: "",
      supplier_type: "",
    });
    Filter.supplier_code = "";
    Filter.supplier_name = "";
    Filter.supplier_type = "";
    FilterSupplier();
  }

  function delSupplier(e) {
    console.log(e);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          user_id: e.sup_id,
          company_id: getUser().com,
          oem_id: getOem(),
        };
        // console.log(data);
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/supplier/DeleteSupplier",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            if (response.data) {
              Swal.fire({
                text: "เสร็จสิ้น",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
              }).then((result) => {
                window.location.reload();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  const SupplierData = [];
  for (let index = 0; index < Supplier.length; index++) {
    const rowItem = {};
    rowItem["No"] = index + 1;
    rowItem["Supplier_Code"] = (
      <div style={{ whiteSpace: "pre" }}>{Supplier[index].sup_code}</div>
    );
    rowItem["Supplier_Name"] = (
      <div style={{ whiteSpace: "pre" }}>{Supplier[index].sup_name}</div>
    );
    rowItem["Supplier_Code_Search"] = Supplier[index].sup_code;
    rowItem["Supplier_Name_Search"] = Supplier[index].sup_name;
    rowItem["Supplier_Type"] = Supplier[index].st_name;
    rowItem["Contact_Name"] = Supplier[index].sup_contract_name;
    rowItem["Management"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-4">
            <a
              href={
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus/view/" +
                Supplier[index].sup_id
              }
              key={Supplier[index].id}
              className=" btn btn-xs "
            >
              <i class="fas fa-eye"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
              href={
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus/edit/" +
                Supplier[index].sup_id
              }
              key={Supplier[index].id}
              className=" btn btn-xs "
            >
              {" "}
              <i class="fas fa-pencil-alt"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
              key={Supplier[index].id}
              className=" btn btn-xs "
              onClick={delSupplier.bind(this, Supplier[index])}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      </>
    );

    SupplierData.push(rowItem);
  }
  const SupplierTable = {
    columns: [
      {
        label: "No",
        field: "No",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Supplier Code",
        field: "Supplier_Code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Supplier Name",
        field: "Supplier_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Supplier Type",
        field: "Supplier_Type",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Contact Name",
        field: "Contact_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "Management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: SupplierData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Supplier</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Supplier</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.supplier_code}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        supplier_code: e.target.value,
                      });
                      Filter.supplier_code = e.target.value;
                    }}
                  />
                  <label>Supplier Code</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.supplier_name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        supplier_name: e.target.value,
                      });
                      Filter.supplier_name = e.target.value;
                    }}
                  />
                  <label>Supplier Name</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    className="form-control"
                    required
                    value={Filter.supplier_type}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        supplier_type: e.target.value,
                      });
                      Filter.supplier_type = e.target.value;
                    }}
                  >
                    <option value="">--Select--</option>
                    {SupplierType.map((data) => {
                      return <option value={data.st_id}>{data.st_name}</option>;
                    })}
                  </select>
                  <label>Supplier Type</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      FilterSupplier();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    window.location.href =
                      "/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus/add";
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Supplier</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div
                          className="table-responsive"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <MDBDataTable
                            sortable={false}
                            striped
                            className="table table-head-fixed"
                            bordered
                            hover
                            data={SupplierTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Table_Supplier_Cuteplus;
