import React from "react";
import Construccomponent from "../../component/Construction";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
function ModeTranning(params) {
  return (
    <div className="wrapper">
     {/*  <Header />
      <Sidebar menu="qc" activemenu="mt" submenu="none" /> */}
      <Construccomponent name="Model Traning" />
   {/*    <Footter /> */}
    </div>
  );
}
export default ModeTranning;
