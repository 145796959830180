import React, { useEffect, useRef, useState } from "react";
import Horizontal_Bar_Chart from "../Accounting_Dashboard/Chart/Horizontal_Bar_Chart";
import Bar_Chart from "../Accounting_Dashboard/Chart/Bar_Chart";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
function ComponentDashboard3(props) {
  const [costProject, setCostProject] = useState({ data: [] });
  const [dataRes, setDataRes] = useState({ data: [] });
  const [dataListRes, setDataListRes] = useState({ data: [] });
  const [dataTotalRes, setDataTotalRes] = useState({ data: [] });
  const [dataListResByName, setDataListResByName] = useState({ data: [] });
  const [checkStatusData, setCheckStatusData] = useState({ data: [] });
  // console.log('checkStatusData', checkStatusData)
  const [modalOpen, setModalOpen] = useState(false);

  const rowsPerPage = 5;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [currentReserveChange, setCurrentReserveChange] = useState(1);
  const dataReservePages = Math.ceil(dataRes.data.length / rowsPerPage);
  const indexOfLastRow = currentReserveChange * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const dataReserveRows = dataRes.data.slice(indexOfFirstRow, indexOfLastRow);

  const [costProjectChange, setCostProjectChange] = useState(1);
  const costProjectPages = Math.ceil(costProject.data.length / rowsPerPage);
  const indexCostProjectRow = costProjectChange * rowsPerPage;
  const indexOfCostProjectRow = indexCostProjectRow - rowsPerPage;
  const constProjectRows = costProject.data.slice(
    indexOfCostProjectRow,
    indexCostProjectRow
  );

  const [currentListReserveChange, setCurrentListReserve] = useState(1);
  const currentListReservePages = Math.ceil(
    dataListRes.data.length / rowsPerPage
  );
  const indexCurrentListReserveRow = currentListReserveChange * rowsPerPage;
  const indexOfCurrentListReserveRow = indexCurrentListReserveRow - rowsPerPage;
  const currentListReserveRows = dataListRes.data.slice(
    indexOfCurrentListReserveRow,
    indexCurrentListReserveRow
  );

  const [currentTotalReserveChange, setCurrentTotalReserve] = useState(1);
  const currentTotalReservePages = Math.ceil(
    dataTotalRes.data.length / rowsPerPage
  );
  const indexCurrentTotalReserveRow = currentTotalReserveChange * rowsPerPage;
  const indexOfCurrentTotalReserveRow =
    indexCurrentTotalReserveRow - rowsPerPage;
  const currentTotalReserveRows = dataTotalRes.data.slice(
    indexOfCurrentTotalReserveRow,
    indexCurrentTotalReserveRow
  );

  const [listReserveByNameChange, setListReserveByNameChange] = useState(1);
  const currentListReserveByNamePages = Math.ceil(
    dataListResByName.data.length / rowsPerPage
  );
  const indexCurrentListReserveByNameRow =
    listReserveByNameChange * rowsPerPage;
  const indexOfCurrentListReserveByNameRow =
    indexCurrentListReserveByNameRow - rowsPerPage;
  const currentListReserveByNameRows = dataListResByName.data.slice(
    indexOfCurrentListReserveByNameRow,
    indexCurrentListReserveByNameRow
  );

  const handlePageListReserve = (page) => {
    setCurrentListReserve(page);
  };

  const handlePageReserveChange = (page) => {
    setCurrentReserveChange(page);
  };

  const handlePageCostProjectChange = (page) => {
    setCostProjectChange(page);
  };

  const handlePageReserveByNameChange = (page) => {
    setListReserveByNameChange(page);
  };

  const handlePageTotalReserve = (page) => {
    setCurrentTotalReserve(page);
  };

//   const sortedRows = [...currentTotalReserveRows].sort((a, b) => {
//     const aStatus = checkStatusData.data[a.org_exp_paid_by]?.length > 0 ? 1 : 0;
//     const bStatus = checkStatusData.data[b.org_exp_paid_by]?.length > 0 ? 1 : 0;
  
//     if (aStatus !== bStatus) {
//       return bStatus - aStatus;
//     }

//     return b.total_amount - a.total_amount;
//   });

  const checkStatusStyles = (status) => {
    let color;
    let backgroundColor;

    if (status === "อนุมัติรอจ่าย") {
      color = "rgba(29, 194, 86, 1)";
      backgroundColor = "rgba(29, 194, 86, 0.11)";
    } else if (status === "รอดำเนินการ") {
      backgroundColor = "rgba(249, 168, 37, 0.2)";
      color = "rgba(249, 168, 37, 1)";
    } else if (status === "ไม่อนุมัติ") {
      color = "rgba(255, 0, 36, 1)";
      backgroundColor = "rgba(255, 0, 36, 0.1)";
    } else if (status === "แบบร่าง") {
      color = "white";
      backgroundColor = "#7aadff";
    } else if (status === "เบิกจ่ายเสร็จสิ้น") {
      color = "white";
      backgroundColor = "rgba(29, 194, 86, 1)";
    }

    return { color, backgroundColor };
  };

  const handleRowClick = (empId, startDate, endDate) => {
    setListReserveByNameChange(1);
    DataReserveByName(empId, startDate, endDate);
    setModalOpen(true);
  };

  const handleRowClick2 = (empId, startDate, endDate) => {
    setListReserveByNameChange(1);
    DataReserveByStatus(
      empId,
      startDate,
      endDate,
      "d4329102-39f6-420a-820f-7c00f98cbcb3",
      "a86edd8a-5b08-445c-8377-9def93ceb8c0"
    );
    setModalOpen(true);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const CostProjectTotalAmount = async (start_date, end_date) => {
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/costProjectTotalAmount",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data.sort(
            (a, b) => b.total_amount - a.total_amount
          );
          setCostProject({
            data: data,
          });
          costProject.data = data;
        } else {
          setCostProject({
            data: [],
          });
          costProject.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const DataReserve = async (start_date, end_date) => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/orgzExpenses/dataReserve",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          // const data = response.data.data.sort((a, b) => b.total_amount - a.total_amount)
          setDataRes({
            data: response.data.data,
          });
          dataRes.data = response.data.data;
        } else {
          setDataRes({
            data: [],
          });
          dataRes.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const DataListReserve = async (start_date, end_date) => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/orgzExpenses/listReserve",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const data = response.data.data.sort(
            (a, b) => b.total_amount - a.total_amount
          );
          setDataListRes({
            data: data,
          });
          dataListRes.data = data;
        } else {
          setDataListRes({
            data: [],
          });
          dataListRes.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const DataTotalReserve = async (start_date, end_date) => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/orgzExpenses/totalReserve",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        if (Array.isArray(response.data.data)) {
          const sortedData = response.data.data.sort((a, b) => {
            const aStatusLength =
              checkStatusData.data[a.org_exp_paid_by]?.length || 0;
            const bStatusLength =
              checkStatusData.data[b.org_exp_paid_by]?.length || 0;
              
            if (aStatusLength > bStatusLength) return -1;
            if (aStatusLength < bStatusLength) return 1;
            return b.total_amount - a.total_amount;
          });
          setDataTotalRes({
            data: sortedData,
          });
          dataTotalRes.data = sortedData;
        } else {
          setDataTotalRes({
            data: [],
          });
          dataTotalRes.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const DataReserveByName = async (empID, start_date, end_date) => {
    const data = { id: empID };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/orgzExpenses/dataReserveByName",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        data,
        start_date,
        end_date,
      },
    })
      .then(async function (response) {
        setDataListResByName({ data: response.data.data });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const DataReserveByStatus = async (
    empID,
    start_date,
    end_date,
    id_1,
    id_2
  ) => {
    const data = { id: empID };
    const status_id = [id_1, id_2].filter(Boolean);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/dataReserveByStatus",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        data,
        start_date,
        end_date,
        status_id,
      },
    })
      .then(async function (response) {
        setDataListResByName({ data: response.data.data });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const CheckStatus = async (start_date, end_date, status1, status2) => {
    const status_id = [status1, status2].filter(Boolean);
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/orgzExpenses/CheckStatusID",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
        start_date,
        end_date,
        status_id,
      },
    })
      .then(async function (response) {
        const statusResult = {};

        response.data.data.forEach((item) => {
          const empId = item.org_exp_paid_by;
          const status = item.org_exp_status;

          if (status === "a86edd8a-5b08-445c-8377-9def93ceb8c0") {
            if (!statusResult[empId]) {
              statusResult[empId] = [];
            }
            statusResult[empId].push(status);
          }
        });


          setCheckStatusData({ data: statusResult });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  function GetAllDashboard(start_date, end_date, emp_id) {
    DataListReserve(start_date, end_date);
    CostProjectTotalAmount(start_date, end_date);
    DataReserve(start_date, end_date);
    setStartDate(start_date);
    setEndDate(end_date);
    CheckStatus(
      start_date,
      end_date,
      "d4329102-39f6-420a-820f-7c00f98cbcb3",
      "a86edd8a-5b08-445c-8377-9def93ceb8c0"
    );
    DataTotalReserve(start_date, end_date);
  }

  useEffect(() => {
    if (props.functionComponenet && props.setFunctionComponenet) {
      props.setFunctionComponenet({
        ...props.functionComponenet,
        getDataChart3: GetAllDashboard,
      });
      props.functionComponenet.getDataChart3 = GetAllDashboard;
    }
  }, []);

  return (
    <section className="content">
      <div className="row">
        <div className="col-md-12 col-xl-4 mb-3">
          <div className="card" style={{ height: "100%" }}>
            <div className="card-body">
              <label>Project ที่มีค่าใช้จ่ายสูงสุด</label>
              <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <table
                  className="table-style-dashboard-log"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}>
                    <tr>
                      <th style={{ width: "80px" }}>No.</th>
                      <th>Project</th>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>ค่าใช้จ่าย</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {constProjectRows.length > 0 ? (
                      constProjectRows.map((row, row_index) => (
                        <React.Fragment key={row.emp_id}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {indexOfCostProjectRow + row_index + 1}
                            </td>
                            <td>{row.acc_project_name}</td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              {Number(row.total_amount || 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            height: "100px",
                            width: "100%",
                          }}
                        >
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() =>
                    handlePageCostProjectChange(costProjectChange - 1)
                  }
                  disabled={costProjectChange === 1}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor: costProjectChange === 1 ? "not-allowed" : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      costProjectChange === 1
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &lt;
                </button>
                {costProjectPages <= 4 ? (
                  Array.from({ length: costProjectPages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageCostProjectChange(index + 1)}
                      style={{
                        margin: "0 5px",
                        padding: "5px",
                        color:
                          costProjectChange === index + 1
                            ? "rgba(53, 121, 246, 1)"
                            : "rgba(102, 102, 102, 1)",
                        border: "none",
                        background: "none",
                        backgroundColor: "none",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {costProjectChange > 3 && (
                      <>
                        <button
                          onClick={() => handlePageCostProjectChange(1)}
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              costProjectChange === 1
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          1
                        </button>
                        {costProjectChange > 4 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                      </>
                    )}

                    {/* Dynamic pages */}
                    {Array.from({ length: 3 }, (_, index) => {
                      const pageIndex =
                        Math.max(1, costProjectChange - 1) + index;
                      if (pageIndex <= costProjectPages) {
                        return (
                          <button
                            key={pageIndex}
                            onClick={() =>
                              handlePageCostProjectChange(pageIndex)
                            }
                            style={{
                              margin: "0 5px",
                              padding: "5px",
                              color:
                                costProjectChange === pageIndex
                                  ? "rgba(53, 121, 246, 1)"
                                  : "rgba(102, 102, 102, 1)",
                              border: "none",
                              background: "none",
                              backgroundColor: "none",
                              cursor: "pointer",
                            }}
                          >
                            {pageIndex}
                          </button>
                        );
                      }
                      return null;
                    })}

                    {/* Show last page and ellipsis if needed */}
                    {costProjectChange < costProjectPages - 2 && (
                      <>
                        {costProjectChange < costProjectPages - 3 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                        <button
                          onClick={() =>
                            handlePageCostProjectChange(costProjectPages)
                          }
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              costProjectChange === costProjectPages
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          {costProjectPages}
                        </button>
                      </>
                    )}
                  </>
                )}

                {/* Right Arrow */}
                <button
                  onClick={() =>
                    handlePageCostProjectChange(costProjectChange + 1)
                  }
                  disabled={costProjectChange === costProjectPages}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      costProjectChange === costProjectPages
                        ? "not-allowed"
                        : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      costProjectChange === costProjectPages
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &gt; {/* Right arrow symbol */}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-xl-4 mb-3">
          <div className="card" style={{ height: "100%" }}>
            <div className="card-body">
              <label>รายชื่อเบิกจ่ายทั้งหมดประจำเดือน</label>
              <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <table
                  className="table-style-dashboard-log"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}>
                    <tr>
                      <th style={{ width: "80px" }}>No.</th>
                      <th
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "start",
                        }}
                      >
                        ผู้สำรองจ่าย
                      </th>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>ยอดสำรองจ่าย</div>
                        </div>
                      </th>
                      <th>MGT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentListReserveRows.length > 0 ? (
                      currentListReserveRows.map((row, row_index) => (
                        <React.Fragment key={row.emp_id}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {indexOfCurrentListReserveRow + row_index + 1}
                            </td>
                            <td>{row.emp_name_th}</td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {Number(row.total_amount || 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td
                              onClick={() =>
                                handleRowClick(
                                  row.org_exp_paid_by_2,
                                  startDate,
                                  endDate
                                )
                              }
                              style={{
                                cursor: "pointer",
                                // color: "#3579F6",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              <i class="fas fa-eye"></i>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="4"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            height: "100px",
                            width: "100%",
                          }}
                        >
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Left Arrow */}
                <button
                  onClick={() =>
                    handlePageListReserve(currentListReserveChange - 1)
                  }
                  disabled={currentListReserveChange === 1}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      currentListReserveChange === 1
                        ? "not-allowed"
                        : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentListReserveChange === 1
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &lt; {/* Left arrow symbol */}
                </button>

                {/* Page Numbers */}
                {currentListReservePages <= 4 ? (
                  // Show all pages when total pages are 4 or less
                  Array.from(
                    { length: currentListReservePages },
                    (_, index) => (
                      <button
                        key={index + 1}
                        onClick={() => handlePageListReserve(index + 1)}
                        style={{
                          margin: "0 5px",
                          padding: "5px",
                          color:
                            currentListReserveChange === index + 1
                              ? "rgba(53, 121, 246, 1)"
                              : "rgba(102, 102, 102, 1)",
                          border: "none",
                          background: "none",
                          backgroundColor: "none",
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </button>
                    )
                  )
                ) : (
                  <>
                    {/* Show first page and ellipsis if needed */}
                    {currentListReserveChange > 3 && (
                      <>
                        <button
                          onClick={() => handlePageListReserve(1)}
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentListReserveChange === 1
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          1
                        </button>
                        {currentListReserveChange > 4 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                      </>
                    )}
                    {Array.from({ length: 3 }, (_, index) => {
                      const pageIndex =
                        Math.max(1, currentListReserveChange - 1) + index;
                      if (pageIndex <= currentListReservePages) {
                        return (
                          <button
                            key={pageIndex}
                            onClick={() => handlePageListReserve(pageIndex)}
                            style={{
                              margin: "0 5px",
                              padding: "5px",
                              color:
                                currentListReserveChange === pageIndex
                                  ? "rgba(53, 121, 246, 1)"
                                  : "rgba(102, 102, 102, 1)",
                              border: "none",
                              background: "none",
                              backgroundColor: "none",
                              cursor: "pointer",
                            }}
                          >
                            {pageIndex}
                          </button>
                        );
                      }
                      return null;
                    })}
                    {currentListReserveChange < currentListReservePages - 2 && (
                      <>
                        {currentListReserveChange <
                          currentListReservePages - 3 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                        <button
                          onClick={() =>
                            handlePageListReserve(currentListReservePages)
                          }
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentListReserveChange ===
                              currentListReservePages
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          {currentListReservePages}
                        </button>
                      </>
                    )}
                  </>
                )}

                {/* Right Arrow */}
                <button
                  onClick={() =>
                    handlePageListReserve(currentListReserveChange + 1)
                  }
                  disabled={
                    currentListReserveChange === currentListReservePages
                  }
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      currentListReserveChange === currentListReservePages
                        ? "not-allowed"
                        : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentListReserveChange === currentListReservePages
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &gt; {/* Right arrow symbol */}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-xl-4 mb-3">
          <div className="card" style={{ height: "100%" }}>
            <div className="card-body">
              <label>ยอดสํารองจ่ายทั้งหมดประจําเดือน</label>
              <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <table
                  className="table-style-dashboard-log"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}>
                    <tr>
                      <th style={{ width: "80px" }}>No.</th>
                      <th
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "start",
                        }}
                      >
                        ผู้สำรองจ่าย
                      </th>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>ยอดสำรองจ่าย</div>
                        </div>
                      </th>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>Status</div>
                        </div>
                      </th>
                      <th>MGT</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTotalReserveRows.length > 0 ? (
                      currentTotalReserveRows.map((row, row_index) => (
                        <React.Fragment key={row.emp_id}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {indexOfCurrentTotalReserveRow + row_index + 1}
                            </td>
                            <td>{row.emp_name_th}</td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {Number(row.total_amount || 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td>
                              {checkStatusData.data[row.org_exp_paid_by]?.length > 0 ? (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      backgroundColor:
                                        checkStatusStyles("รอดำเนินการ")
                                          .backgroundColor,
                                      color:
                                        checkStatusStyles("รอดำเนินการ").color,
                                      borderRadius: "25px",
                                      padding: "0px 6px 0 6px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    รอดำเนินการ
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      width: "100%",
                                      backgroundColor:
                                        checkStatusStyles("เบิกจ่ายเสร็จสิ้น")
                                          .backgroundColor,
                                      color:
                                        checkStatusStyles("เบิกจ่ายเสร็จสิ้น")
                                          .color,
                                      borderRadius: "25px",
                                      padding: "0px 6px 0 6px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    เบิกจ่ายเสร็จสิ้น
                                  </div>
                                </>
                              )}
                            </td>
                            <td
                              onClick={() =>
                                handleRowClick2(
                                  row.org_exp_paid_by,
                                  startDate,
                                  endDate
                                )
                              }
                              style={{
                                cursor: "pointer",
                                // color: "#3579F6",
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              <i class="fas fa-eye"></i>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="4"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            height: "100px",
                            width: "100%",
                          }}
                        >
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Left Arrow */}
                <button
                  onClick={() =>
                    handlePageTotalReserve(currentTotalReserveChange - 1)
                  }
                  disabled={currentTotalReserveChange === 1}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      currentTotalReserveChange === 1
                        ? "not-allowed"
                        : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentTotalReserveChange === 1
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &lt; {/* Left arrow symbol */}
                </button>

                {/* Page Numbers */}
                {currentTotalReservePages <= 4 ? (
                  // Show all pages when total pages are 4 or less
                  Array.from(
                    { length: currentTotalReservePages },
                    (_, index) => (
                      <button
                        key={index + 1}
                        onClick={() => handlePageTotalReserve(index + 1)}
                        style={{
                          margin: "0 5px",
                          padding: "5px",
                          color:
                            currentTotalReserveChange === index + 1
                              ? "rgba(53, 121, 246, 1)"
                              : "rgba(102, 102, 102, 1)",
                          border: "none",
                          background: "none",
                          backgroundColor: "none",
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </button>
                    )
                  )
                ) : (
                  <>
                    {/* Show first page and ellipsis if needed */}
                    {currentTotalReserveChange > 3 && (
                      <>
                        <button
                          onClick={() => handlePageTotalReserve(1)}
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentTotalReserveChange === 1
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          1
                        </button>
                        {currentTotalReserveChange > 4 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                      </>
                    )}
                    {Array.from({ length: 3 }, (_, index) => {
                      const pageIndex =
                        Math.max(1, currentTotalReserveChange - 1) + index;
                      if (pageIndex <= currentTotalReservePages) {
                        return (
                          <button
                            key={pageIndex}
                            onClick={() => handlePageTotalReserve(pageIndex)}
                            style={{
                              margin: "0 5px",
                              padding: "5px",
                              color:
                                currentTotalReserveChange === pageIndex
                                  ? "rgba(53, 121, 246, 1)"
                                  : "rgba(102, 102, 102, 1)",
                              border: "none",
                              background: "none",
                              backgroundColor: "none",
                              cursor: "pointer",
                            }}
                          >
                            {pageIndex}
                          </button>
                        );
                      }
                      return null;
                    })}
                    {currentTotalReserveChange <
                      currentTotalReservePages - 2 && (
                      <>
                        {currentTotalReserveChange <
                          currentTotalReservePages - 3 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                        <button
                          onClick={() =>
                            handlePageTotalReserve(currentTotalReservePages)
                          }
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentTotalReserveChange ===
                              currentTotalReservePages
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          {currentTotalReservePages}
                        </button>
                      </>
                    )}
                  </>
                )}

                {/* Right Arrow */}
                <button
                  onClick={() =>
                    handlePageTotalReserve(currentTotalReserveChange + 1)
                  }
                  disabled={
                    currentTotalReserveChange === currentTotalReservePages
                  }
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      currentTotalReserveChange === currentTotalReservePages
                        ? "not-allowed"
                        : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentTotalReserveChange === currentTotalReservePages
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &gt; {/* Right arrow symbol */}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          style={{ maxWidth: "80%", width: "1000px", marginTop: "9%" }}
        >
          <ModalHeader toggle={toggleModal}>
            <label>ข้อมูลผู้สำรองจ่าย</label>
          </ModalHeader>
          <ModalBody>
            <div className="table-responsive" style={{ whiteSpace: "nowrap" }}>
              <table
                className="table-style-dashboard-log"
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}>
                  <tr>
                    <th style={{ width: "60px" }}>No.</th>
                    <th style={{ width: "80px" }}>
                      <div
                        style={{
                          display: "flex",
                          textAlign: "start",
                          justifyContent: "start",
                          width: "150px",
                        }}
                      >
                        <div>ผู้สำรองจ่าย</div>
                      </div>
                    </th>
                    <th style={{ width: "120px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ width: "100%" }}>หัวข้อค่าใช้จ่าย</div>
                      </div>
                    </th>
                    <th style={{ width: "250px" }}>รายละเอียด</th>
                    <th style={{ width: "150px" }}>ยอดสำรองจ่าย</th>
                    <th style={{ width: "120px" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentListReserveByNameRows.length > 0 ? (
                    currentListReserveByNameRows.map((row, row_index) => (
                      <React.Fragment key={row.emp_id}>
                        <tr>
                          <td style={{ textAlign: "center" }}>
                            {indexOfCurrentListReserveByNameRow + row_index + 1}
                          </td>
                          <td>{row.emp_name_th}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {row.acc_et_name}
                            </div>
                          </td>
                          <td>
                            {row.org_exp_detail.length > 45
                              ? `${row.org_exp_detail.substring(0, 45)}...`
                              : row.org_exp_detail}
                          </td>
                          <td
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {Number(row.org_exp_amount || 0).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            <div
                              style={{ display: "flex", textAlign: "center" }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  backgroundColor: checkStatusStyles(
                                    row.emp_status_name
                                  ).backgroundColor,
                                  // color: "rgba(249, 168, 37, 1)",
                                  color: checkStatusStyles(row.emp_status_name)
                                    .color,
                                  borderRadius: "25px",
                                  padding: "0px 6px 0 6px",
                                }}
                              >
                                {row.emp_status_name}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          height: "100px",
                          width: "100%",
                        }}
                      >
                        No data
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              style={{
                marginTop: "10px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() =>
                  handlePageReserveByNameChange(listReserveByNameChange - 1)
                }
                disabled={listReserveByNameChange === 1}
                style={{
                  margin: "0 5px",
                  padding: "5px",
                  cursor:
                    listReserveByNameChange === 1 ? "not-allowed" : "pointer",
                  border: "none",
                  background: "none",
                  backgroundColor: "none",
                  color:
                    listReserveByNameChange === 1
                      ? "rgba(102, 102, 102, 1)"
                      : "rgba(53, 121, 246, 1)",
                }}
              >
                &lt;
              </button>
              {currentListReserveByNamePages <= 4 ? (
                Array.from(
                  { length: currentListReserveByNamePages },
                  (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageReserveByNameChange(index + 1)}
                      style={{
                        margin: "0 5px",
                        padding: "5px",
                        color:
                          listReserveByNameChange === index + 1
                            ? "rgba(53, 121, 246, 1)"
                            : "rgba(102, 102, 102, 1)",
                        border: "none",
                        background: "none",
                        backgroundColor: "none",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}
                    </button>
                  )
                )
              ) : (
                <>
                  {listReserveByNameChange > 3 && (
                    <>
                      <button
                        onClick={() => handlePageReserveByNameChange(1)}
                        style={{
                          margin: "0 5px",
                          padding: "5px",
                          color:
                            listReserveByNameChange === 1
                              ? "rgba(53, 121, 246, 1)"
                              : "rgba(102, 102, 102, 1)",
                          border: "none",
                          background: "none",
                          backgroundColor: "none",
                          cursor: "pointer",
                        }}
                      >
                        1
                      </button>
                      {listReserveByNameChange > 4 && (
                        <span style={{ margin: "0 5px" }}>...</span>
                      )}
                    </>
                  )}
                  {Array.from({ length: 3 }, (_, index) => {
                    const pageIndex =
                      Math.max(1, listReserveByNameChange - 1) + index;
                    if (pageIndex <= currentListReserveByNamePages) {
                      return (
                        <button
                          key={pageIndex}
                          onClick={() =>
                            handlePageReserveByNameChange(pageIndex)
                          }
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              listReserveByNameChange === pageIndex
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          {pageIndex}
                        </button>
                      );
                    }
                    return null;
                  })}
                  {listReserveByNameChange <
                    currentListReserveByNamePages - 2 && (
                    <>
                      {listReserveByNameChange <
                        currentListReserveByNamePages - 3 && (
                        <span style={{ margin: "0 5px" }}>...</span>
                      )}
                      <button
                        onClick={() =>
                          handlePageReserveByNameChange(
                            currentListReserveByNamePages
                          )
                        }
                        style={{
                          margin: "0 5px",
                          padding: "5px",
                          color:
                            listReserveByNameChange ===
                            currentListReserveByNamePages
                              ? "rgba(53, 121, 246, 1)"
                              : "rgba(102, 102, 102, 1)",
                          border: "none",
                          background: "none",
                          backgroundColor: "none",
                          cursor: "pointer",
                        }}
                      >
                        {currentListReserveByNamePages}
                      </button>
                    </>
                  )}
                </>
              )}

              {/* Right Arrow */}
              <button
                onClick={() =>
                  handlePageReserveByNameChange(listReserveByNameChange + 1)
                }
                disabled={
                  listReserveByNameChange === currentListReserveByNamePages
                }
                style={{
                  margin: "0 5px",
                  padding: "5px",
                  cursor:
                    listReserveByNameChange === currentListReserveByNamePages
                      ? "not-allowed"
                      : "pointer",
                  border: "none",
                  background: "none",
                  backgroundColor: "none",
                  color:
                    listReserveByNameChange === currentListReserveByNamePages
                      ? "rgba(102, 102, 102, 1)"
                      : "rgba(53, 121, 246, 1)",
                }}
              >
                &gt; {/* Right arrow symbol */}
              </button>
            </div>
          </ModalBody>
        </Modal>

        <div className="col-md-12 col-xl-12 mb-3">
          <div className="card" style={{ height: "100%" }}>
            <div className="card-body">
              <label>ยอดสำรองจ่ายที่รอดำเนินการอนุมัติ</label>
              <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <table
                  className="table-style-dashboard-log"
                  style={{ borderCollapse: "collapse", width: "100%" }}
                >
                  <thead style={{ backgroundColor: "rgba(245, 245, 245, 1)" }}>
                    <tr>
                      <th style={{ width: "80px" }}>No.</th>
                      <th>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "start",
                            textAlign: "start",
                          }}
                        >
                          <div style={{ width: "100%" }}>ผู้สำรองจ่าย</div>
                        </div>
                      </th>
                      <th>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "100%" }}>หัวข้อค่าใช้จ่าย</div>
                        </div>
                      </th>
                      <th>รายละเอียด</th>
                      <th>ยอดสำรองจ่าย</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataReserveRows.length > 0 ? (
                      dataReserveRows.map((row, row_index) => (
                        <React.Fragment key={row.emp_id}>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {indexOfFirstRow + row_index + 1}
                            </td>
                            <td>{row.emp_name_th}</td>
                            <td>
                              <div
                                style={{ display: "flex", textAlign: "center" }}
                              >
                                <div style={{ width: "100%" }}>
                                  {row.acc_et_name}
                                </div>
                              </div>
                            </td>
                            <td>
                              {row.org_exp_detail.length > 115
                                ? `${row.org_exp_detail.substring(0, 112)}...`
                                : row.org_exp_detail}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {Number(row.org_exp_amount || 0).toLocaleString(
                                  "en-US",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{ display: "flex", textAlign: "center" }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    backgroundColor: "rgba(249, 168, 37, 0.2)",
                                    color: "rgba(249, 168, 37, 1)",
                                    borderRadius: "25px",
                                    padding: "0px 6px 0 6px",
                                  }}
                                >
                                  {row.emp_status_name}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            height: "100px",
                            width: "100%",
                          }}
                        >
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  onClick={() =>
                    handlePageReserveChange(currentReserveChange - 1)
                  }
                  disabled={currentReserveChange === 1}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      currentReserveChange === 1 ? "not-allowed" : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentReserveChange === 1
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &lt;
                </button>

                {dataReservePages <= 4 ? (
                  Array.from({ length: dataReservePages }, (_, index) => (
                    <button
                      key={index + 1}
                      onClick={() => handlePageReserveChange(index + 1)}
                      style={{
                        margin: "0 5px",
                        padding: "5px",
                        color:
                          currentReserveChange === index + 1
                            ? "rgba(53, 121, 246, 1)"
                            : "rgba(102, 102, 102, 1)",
                        border: "none",
                        background: "none",
                        backgroundColor: "none",
                        cursor: "pointer",
                      }}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {currentReserveChange > 3 && (
                      <>
                        <button
                          onClick={() => handlePageReserveChange(1)}
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentReserveChange === 1
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          1
                        </button>
                        {currentReserveChange > 4 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                      </>
                    )}

                    {Array.from({ length: 3 }, (_, index) => {
                      const pageIndex =
                        Math.max(1, currentReserveChange - 1) + index;
                      if (pageIndex <= dataReservePages) {
                        return (
                          <button
                            key={pageIndex}
                            onClick={() => handlePageReserveChange(pageIndex)}
                            style={{
                              margin: "0 5px",
                              padding: "5px",
                              color:
                                currentReserveChange === pageIndex
                                  ? "rgba(53, 121, 246, 1)"
                                  : "rgba(102, 102, 102, 1)",
                              border: "none",
                              background: "none",
                              backgroundColor: "none",
                              cursor: "pointer",
                            }}
                          >
                            {pageIndex}
                          </button>
                        );
                      }
                      return null;
                    })}

                    {currentReserveChange < dataReservePages - 2 && (
                      <>
                        {currentReserveChange < dataReservePages - 3 && (
                          <span style={{ margin: "0 5px" }}>...</span>
                        )}
                        <button
                          onClick={() =>
                            handlePageReserveChange(dataReservePages)
                          }
                          style={{
                            margin: "0 5px",
                            padding: "5px",
                            color:
                              currentReserveChange === dataReservePages
                                ? "rgba(53, 121, 246, 1)"
                                : "rgba(102, 102, 102, 1)",
                            border: "none",
                            background: "none",
                            backgroundColor: "none",
                            cursor: "pointer",
                          }}
                        >
                          {dataReservePages}
                        </button>
                      </>
                    )}
                  </>
                )}
                <button
                  onClick={() =>
                    handlePageReserveChange(currentReserveChange + 1)
                  }
                  disabled={currentReserveChange === dataReservePages}
                  style={{
                    margin: "0 5px",
                    padding: "5px",
                    cursor:
                      currentReserveChange === dataReservePages
                        ? "not-allowed"
                        : "pointer",
                    border: "none",
                    background: "none",
                    backgroundColor: "none",
                    color:
                      currentReserveChange === dataReservePages
                        ? "rgba(102, 102, 102, 1)"
                        : "rgba(53, 121, 246, 1)",
                  }}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ComponentDashboard3;
