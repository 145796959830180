import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";

function End_user(params) {
  const [end_user, setEnd_user] = useState([]);
  const [relate_product, setRelate_product] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  const [filler_end_user, setFiller_end_user] = useState({
    name: "",
    full_name: "",
    oem_id: getOem(),
  });

  const GetRelate_product = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL + "/customer/get_relate_product",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    
    }).then(async (response) => {
      //console.log(response.data)
     await setRelate_product(response.data);
    }).catch(async (error) => {
        console.log(error);
      });
  };
  const GetEnd_user = async () => {
    
    //console.log(filler_supplier);
    axios({
      method: "post",
      url: Configs.API_URL + "/end_user/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_end_user
    })
      .then(function (response) {
        //console.log(response.data);
        setEnd_user(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetEnd_user();
    GetRelate_product();
  }, []);

  const clearFilter = async () => {
    await setFiller_end_user({
      ...filler_end_user,
      name:"",
      full_name:"",
    });
   
    filler_end_user.name = "";
    filler_end_user.full_name = "";
    GetEnd_user();
   
  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/End_user/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/end_user/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/End_user";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];
 
  for (var index = 0; index < end_user.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["name"] = end_user[index].name;
    rowItem["full_name"] = end_user[index].fullname;
    rowItem["contact"] = end_user[index].contact;
    rowItem["tel"]=(<><a href={"tel:"+end_user[index].end_user_tel}>{end_user[index].end_user_tel}</a></>);
    rowItem["address"] = end_user[index].address;
    rowItem["latitude"] = end_user[index].latitude;
    rowItem["longitude"] = end_user[index].longitude;
    

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
             href={"/Warehouse_Management/Factory_Master_Data/End_user/read/"+ end_user[index].id}
            key={end_user[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
              href={"/Warehouse_Management/Factory_Master_Data/End_user/edit/"+ end_user[index].id}
             key={end_user[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
             key={end_user[index].id}
            className=" btn btn-xs "
             onClick={deldata.bind(this,end_user[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "No. End User",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "full_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact",
        field: "contact",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tel",
        field: "tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 50,
      },
      {
        label: "Latitude",
        field: "latitude",
        sort: "asc",
        width: 50,
      },
      {
        label: "Longtitude",
        field: "longitude",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="end_user" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>End_user{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">End_user</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_end_user.name.trim()}
                    onChange={(e) => {
                      setFiller_end_user({
                        ...filler_end_user,
                        name: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">No. End user</label>{" "}
                </div>

                
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_end_user.full_name.trim()}
                    onChange={(e) => {
                      setFiller_end_user({
                        ...filler_end_user,
                        full_name: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Full Name</label>{" "}
                </div>

                
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetEnd_user();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
         
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">End_user</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " style={{ whiteSpace: 'nowrap' }}/*  style={{ height: "100%" }} */
            >
              <MDBDataTable
                className="table table-head-fixed"
                sortable = {false}
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    {/*  <Footter/> */}
     </div>
     
  );
}

export default End_user;
