import Configs from "../../../../../config";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../../../../assets/font/pdf_font";
import jsPDF from "jspdf";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";

function Price_List_Report(company, priceList) {
  let is_corporation =
    priceList.priceListData[0].pl_ct_id !== null &&
    priceList.priceListData[0].pl_ct_id !==
      "7095dc2b-efeb-433e-af3f-449e9e90bdf2"
      ? true
      : false;
  let Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
  const doc = new jsPDF("p", "mm", "a4");
  let counts = 0; //index < 30
  let sumSubTotal = 0;
  // ลูกค้าที่วไป
  let row = 0;
  for (
    let index = 0;
    index < priceList.priceListProductData.length;
    index += row
  ) {
    doc.setFillColor("#8DC445");
    doc.rect(0, 0, 221, 25, "F");
    doc.addImage(Com_logo, "JPEG", 5, 0, 25, 25);
    doc.rect(0, 0, 5.5, 25, "F");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(20);
    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");

    doc.text(32, 10, "บริษัท คิวท์ โปรดักส์ พลัส จำกัด");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(13);
    doc.text(93, 10, "0105552067869 (สำนักงานใหญ่)");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(12);
    doc.text(
      32,
      15,
      "เลขที่ 5 ซอยลาดพร้าว-วังหิน 32 แยก 1 ถนนลาดพร้าววังหิน แขวงลาดพร้าว กทม. 10230"
    );

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(32, 20, "Tel. +66 (0)-2539-0958-9");

    doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
    doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
    doc.setFont("THSarabunNewBold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(32);
    doc.text(205, 12, "PRICE LIST", "right");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(25);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(205, 21, "ใบรายการราคา", "right");

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      5,
      35,
      `ชื่อบริษัท/Name : ${priceList.priceListData[0].pl_cus_name}`
    );

    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(5, 42, "ที่อยู่/Address :");

    let cus_address = "";
    let temp = [
      priceList.priceListData[0].pl_cus_house_number,
      priceList.priceListData[0].pl_cus_building,
      priceList.priceListData[0].pl_swine,
      priceList.priceListData[0].pl_soi,
      priceList.priceListData[0].pl_road,
      priceList.priceListData[0].pl_sub_district,
      priceList.priceListData[0].pl_district,
      priceList.priceListData[0].pl_province,
      priceList.priceListData[0].pl_postcode,
    ];

    let TotalWord = 0;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] !== null) {
        cus_address += temp[i] + " ";
        TotalWord = TotalWord + 1;
      }
    }
    let word1 = "";
    let word2 = "";
    let wordCount = 0;
    let y_axis = null;
    if (cus_address.length > 70) {
      for (let k = 0; k < temp.length; k++) {
        if (word1.length < 70) {
          if (temp[k] !== null) {
            word1 += temp[k] + " ";
          }
        } else {
          if (temp[k] !== null) {
            word2 += temp[k] + " ";
          }
        }
      }
      y_axis = 63;
    } else {
      word1 = cus_address;
      y_axis = 56;
    }

    if (word2.length > 70) {
      word2 = word2.substring(0, 70) + "...";
    }
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(30, 42, `${word1}`);
    doc.text(30, 49, `${word2}`);
    /*-------------------------------------------------------------------------------*/
    //header
    doc.setFillColor("#8DC445");
    doc.roundedRect(4, 68 - 10, 202, 12, 1, 1, "F");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(11, 73 - 10, "ลำดับ", "center");
    doc.setFontSize(15);
    doc.text(11, 78 - 10, "No.", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(30, 73 - 10, "รูปสินค้า", "center");
    doc.setFontSize(15);
    doc.text(30, 78 - 10, "Picture", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(62, 73 - 10, "บาร์โค้ด", "center");
    doc.setFontSize(15);
    doc.text(62, 78 - 10, "Barcode", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(120, 73 - 10, "รายการสินค้า", "center");
    doc.setFontSize(15);
    doc.text(120, 78 - 10, "Item Description", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(173, 73 - 10, "จำนวน", "center");
    doc.setFontSize(15);
    doc.text(173, 78 - 10, "Qty.", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(196, 73 - 10, "ราคา", "center");
    doc.setFontSize(15);
    doc.text(196, 78 - 10, "Price", "center");

    let gapcheck = 15;
    let isMore1page = false;
    let countItem = 0;
    let c = 0;
    for (
      let i = 0;
      c < priceList.priceListProductData.length;
      i = i + gapcheck
    ) {
      if (index + c < priceList.priceListProductData.length) {
        //Item description to find c size
        let Brand_name =
          priceList.priceListProductData[index + c].productDetail.brand_name !==
            null &&
          priceList.priceListProductData[index + c].productDetail.brand_name !==
            undefined
            ? priceList.priceListProductData[index + c].productDetail.brand_name
            : "";
        let Product_name =
          priceList.priceListData[0].pl_lang === "ENG"
            ? priceList.priceListProductData[index + c].productDetail
                .product_name_eng
            : priceList.priceListData[0].pl_lang === "TH"
            ? priceList.priceListProductData[index + c].productDetail
                .product_name
            : "";
        let Unit_name =
          priceList.priceListData[0].pl_lang === "ENG"
            ? priceList.priceListProductData[index + c].productDetail.pu_name
            : priceList.priceListData[0].pl_lang === "TH"
            ? priceList.priceListProductData[index + c].productDetail
                .pu_name_eng
            : "";
        let allItemDescription = `${Brand_name} ${
          Product_name !== null ? Product_name : ""
        } ${
          priceList.priceListProductData[index + c].productDetail.pd_weight !==
            null &&
          priceList.priceListProductData[index + c].productDetail.pd_weight !==
            undefined &&
          priceList.priceListProductData[index + c].productDetail.pd_weight !==
            "0"
            ? priceList.priceListProductData[
                index + c
              ].productDetail.pd_weight.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : ""
        } ${
          Unit_name !== null &&
          Unit_name !== undefined &&
          priceList.priceListProductData[index + c].productDetail.pd_weight !==
            "0"
            ? Unit_name
            : ""
        }`;
        let tempAllDes = allItemDescription.split(" ");
        let y_axis_des = null;
        let LineArray = [];
        if (tempAllDes.length > 6 && allItemDescription.length > 35) {
          for (let k = 0; k < tempAllDes.length; k++) {
            if (LineArray.length <= 0) {
              if (
                tempAllDes[k] !== "" &&
                tempAllDes[k] !== null &&
                tempAllDes[k] !== undefined
              ) {
                LineArray.push(tempAllDes[k]);
              }
            } else if (LineArray.length > 0) {
              if (LineArray[LineArray.length - 1].length < 35) {
                if (
                  tempAllDes[k].length < 30 &&
                  tempAllDes[k] !== "" &&
                  tempAllDes[k] !== null &&
                  tempAllDes[k] !== undefined
                ) {
                  LineArray[LineArray.length - 1] += " " + tempAllDes[k];
                } else {
                  for (let c = 0; c < tempAllDes[k].length; c += 35) {
                    if (c + 30 <= tempAllDes[k].length) {
                      LineArray.push(tempAllDes[k].substring(c, c + 35));
                    } else {
                      LineArray.push(
                        tempAllDes[k].substring(c, tempAllDes[k].length)
                      );
                    }
                  }
                }
              } else {
                if (
                  tempAllDes[k] !== "" &&
                  tempAllDes[k] !== null &&
                  tempAllDes[k] !== undefined
                ) {
                  LineArray.push(tempAllDes[k]);
                }
              }
            }
          }
          gapcheck = 10 + 6 * LineArray.length;
        } else {
          gapcheck = 15;
          LineArray.push(allItemDescription);
        }
        if (280 - (70 + i) < gapcheck) {
          break;
        } else if (index + c + 1 === priceList.priceListProductData.length) {
          countItem = countItem + 1;
          break;
        } else {
          c++;
          countItem = countItem + 1;
        }
      }
    }
    let gapEachProduct = 15;
    isMore1page = false;
    row = 0;
    if (countItem > 0) {
      for (let i = 0; row <= countItem; i = i + gapEachProduct) {
        if (index + row < priceList.priceListProductData.length) {
          let middleRow = 0;
          let imgMiddleRow = 0;
          //Item description to find row size
          let Brand_name =
            priceList.priceListProductData[index + row].productDetail
              .brand_name !== null &&
            priceList.priceListProductData[index + row].productDetail
              .brand_name !== undefined
              ? priceList.priceListProductData[index + row].productDetail
                  .brand_name
              : "";
          let Product_name =
            priceList.priceListData[0].pl_lang === "ENG"
              ? priceList.priceListProductData[index + row].productDetail
                  .product_name_eng
              : priceList.priceListData[0].pl_lang === "TH"
              ? priceList.priceListProductData[index + row].productDetail
                  .product_name
              : "";
          let Unit_name =
            priceList.priceListData[0].pl_lang === "ENG"
              ? priceList.priceListProductData[index + row].productDetail
                  .pu_name
              : priceList.priceListData[0].pl_lang === "TH"
              ? priceList.priceListProductData[index + row].productDetail
                  .pu_name_eng
              : "";
          let allItemDescription = `${Brand_name} ${
            Product_name !== null ? Product_name : ""
          } ${
            priceList.priceListProductData[index + row].productDetail
              .pd_weight !== null &&
            priceList.priceListProductData[index + row].productDetail
              .pd_weight !== undefined &&
            priceList.priceListProductData[index + row].productDetail
              .pd_weight !== "0"
              ? priceList.priceListProductData[
                  index + row
                ].productDetail.pd_weight.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : ""
          } ${
            Unit_name !== null &&
            Unit_name !== undefined &&
            priceList.priceListProductData[index + row].productDetail
              .pd_weight !== "0"
              ? Unit_name
              : ""
          }`;
          let tempAllDes = allItemDescription.split(" ");
          let y_axis_des = null;
          let LineArray = [];
          if (tempAllDes.length > 6 && allItemDescription.length > 35) {
            for (let k = 0; k < tempAllDes.length; k++) {
              if (LineArray.length <= 0) {
                LineArray.push(tempAllDes[k]);
              } else if (LineArray.length > 0) {
                if (LineArray[LineArray.length - 1].length < 35) {
                  if (tempAllDes[k].length < 30) {
                    LineArray[LineArray.length - 1] += " " + tempAllDes[k];
                  } else {
                    for (let c = 0; c < tempAllDes[k].length; c += 35) {
                      if (c + 30 <= tempAllDes[k].length) {
                        LineArray.push(tempAllDes[k].substring(c, c + 35));
                      } else {
                        LineArray.push(
                          tempAllDes[k].substring(c, tempAllDes[k].length)
                        );
                      }
                    }
                  }
                } else {
                  LineArray.push(tempAllDes[k]);
                }
              }
            }
            gapEachProduct = 10 + 6 * LineArray.length;
            y_axis_des = 86 - 10 + i;
            middleRow = 86 - 10 + i + (6 * LineArray.length) / 2;
            imgMiddleRow = 84 - 10 + i + (6 * LineArray.length) / 2 - 5.5;
          } else {
            gapEachProduct = 15;
            LineArray.push(allItemDescription);
            y_axis_des = 86 - 10 + i;
            middleRow = 89 - 10 + i;
            imgMiddleRow = 81 - 10 + i;
          }

          if (
            80 - 10 + i + gapEachProduct > 250 &&
            (row === countItem ||
              index + row + 1 === priceList.priceListProductData.length)
          ) {
            isMore1page = true;
            break;
          }

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(11, middleRow, (index + row + 1).toString(), "center"); //no.

          //Picture
          let product_Image = `${
            priceList.priceListProductData[index + row].productDetail
              .pci_img_path !== "" &&
            priceList.priceListProductData[index + row].productDetail
              .pci_img_path !== null &&
            priceList.priceListProductData[index + row].productDetail
              .pci_img_path !== undefined
              ? priceList.priceListProductData[
                  index + row
                ].productDetail.pci_img_path?.indexOf("blob") >= 0
                ? priceList.priceListProductData[index + row].productDetail
                    .pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${
                    priceList.priceListProductData[index + row].productDetail
                      .pci_img_name
                  }/${
                    priceList.priceListProductData[index + row].productDetail
                      .pci_img_path
                  }`
              : userdefault_img.imgs
          }`;
          doc.addImage(product_Image, "JPEG", 24, imgMiddleRow, 13, 13);

          //Barcode
          let shelfLife =
            priceList.priceListProductData[index + row].pp_full_shelf_life !==
              null &&
            priceList.priceListProductData[index + row].pp_full_shelf_life !==
              undefined
              ? priceList.priceListProductData[index + row].pp_full_shelf_life
              : "";
          let guarantee =
            priceList.priceListProductData[index + row].pp_garantee !== null &&
            priceList.priceListProductData[index + row].pp_garantee !==
              undefined
              ? priceList.priceListProductData[index + row].pp_garantee
              : "";
          let middleSg = middleRow + 3;
          let middleBc = middleRow - 3;
          let sg = "";
          if (shelfLife !== "" && guarantee !== "") {
            sg = "ShelfLife " + shelfLife + " / GTD " + guarantee + " d.";
          } else if (shelfLife !== "" && guarantee === "") {
            sg = "ShelfLife " + shelfLife + " d.";
          } else if (guarantee !== "" && shelfLife === "") {
            sg = "GTD " + guarantee + " d.";
          } else {
            sg = "";
            middleBc = middleRow;
          }
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            62,
            middleBc,
            `${
              priceList.priceListProductData[index + row].pp_pb_name !== null &&
              priceList.priceListProductData[index + row].pp_pb_name !==
                undefined
                ? priceList.priceListProductData[index + row].pp_pb_name
                : ""
            }`,
            "center"
          );
          doc.text(62, middleSg, `${sg}`, "center");

          // Item Description
          for (let itemDes of LineArray) {
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(85, y_axis_des, `${itemDes}`);
            y_axis_des = y_axis_des + 6;
          }
          doc.text(
            85,
            y_axis_des,
            `${
              priceList.priceListProductData[index + row].productDetail
                .pd_sale_pc_pack !== null
                ? priceList.priceListProductData[index + row].productDetail
                    .pd_sale_pc_pack
                : "-"
            } Pcs./Pack  ${
              priceList.priceListProductData[index + row].productDetail
                .pd_sale_pc_ctn !== null
                ? priceList.priceListProductData[index + row].productDetail
                    .pd_sale_pc_ctn
                : "-"
            } Pcs./Ctn.`
          );

          //Qty.
          let Quantity = priceList.priceListProductData[
            index + row
          ].pp_quantity.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          let Unit =
            priceList.priceListProductData[index + row].pp_quantity_unit ===
            "ctn"
              ? "Ctn."
              : priceList.priceListProductData[index + row].pp_quantity_unit ===
                "pack"
              ? "Pack"
              : "Piece";
          let allQtyUnit = `${Quantity} ${Unit}`;
          if (allQtyUnit.length < 10) {
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(
              173,
              middleRow,
              `${allQtyUnit}`, //
              "center"
            );
          } else {
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(
              173,
              middleRow - 3,
              `${Quantity}`, //
              "center"
            );
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(
              173,
              middleRow + 3,
              `${Unit}`, //
              "center"
            );
          }

          //Price
          let allQty =
            priceList.priceListProductData[index + row].pp_quantity_unit ===
            "pack"
              ? parseFloat(
                  priceList.priceListProductData[index + row].productDetail
                    .pd_sale_pc_pack
                ) *
                parseFloat(
                  priceList.priceListProductData[index + row].pp_quantity
                )
              : priceList.priceListProductData[index + row].pp_quantity_unit ===
                "ctn"
              ? parseFloat(
                  priceList.priceListProductData[index + row].productDetail
                    .pd_sale_pc_ctn
                ) *
                parseFloat(
                  priceList.priceListProductData[index + row].pp_quantity
                )
              : parseFloat(
                  priceList.priceListProductData[index + row].pp_quantity
                ) * 1;
          let origin_Price =
            parseFloat(priceList.priceListProductData[index + row].pp_price) *
            allQty;
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            196,
            middleRow,
            `${(
              origin_Price?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
            "center"
          );
          sumSubTotal += origin_Price;
          doc.setDrawColor("#8DC445");
          doc.rect(5, 80 - 10 + i + gapEachProduct, 200, 0);
          // doc.text(
          //   5,
          //   80 - 10 + i + gapEachProduct,
          //   (80 - 10 + i + gapEachProduct).toString(),
          //   "center"
          // );
        }
        row = row + 1;
        counts = priceList.priceListProductData.length - countItem;
      }
    } else {
      row += 1;
    }
    /*------------------------------------------------------------------------*/
    let checkNumProduct = priceList.priceListProductData.length - index;
    // doc.rect(5, 230 + 25, 200, 0);
    if (index < counts || isMore1page) {
      doc.addPage("a4", "h");
    } else {
      doc.text(5, 230 + 25, "*GTD คือ Guarantee Date", "left");
      doc.setDrawColor("#8DC445");
      doc.rect(5, 232 + 25, 200, 32);
      doc.setFillColor("#8DC445");
      doc.roundedRect(5, 232 + 25, 32, 10, 1, 1, "F");
      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(21, 238 + 25, "หมายเหตุ/Remark", "center");
      let remark =
        priceList.priceListData[0].pl_remark !== null &&
        priceList.priceListData[0].pl_remark !== undefined
          ? priceList.priceListData[0].pl_remark
          : "";
      let remarkArray = remark.split("\n");
      let remark_y_axis = 247 + 25;
      let rowRemark = 0;
      let r = 0;
      for (
        let i = 0;
        i < remarkArray[r]?.length || (rowRemark < 3 && r < 3);
        i += 85
      ) {
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        // for (let r = 0; r < 3; r++) {
        if (
          rowRemark < 2 &&
          remarkArray[r] !== undefined &&
          remarkArray[r]?.length < 85
        ) {
          doc.text(10, remark_y_axis, `${remarkArray[r]}`);
          r += 1;
        } else if (
          rowRemark < 2 &&
          remarkArray[r] !== undefined &&
          remarkArray[r]?.length > 85
        ) {
          doc.text(
            10,
            remark_y_axis,
            `${remarkArray[r].substring(
              i,
              i + 85 > remarkArray[r]?.length ? remarkArray[r]?.length : i + 85
            )}` || ""
          );
        } else if (
          rowRemark === 2 &&
          remarkArray[r] !== undefined &&
          remarkArray[r]?.length > 85
        ) {
          doc.text(
            10,
            remark_y_axis,
            `${
              remarkArray[r].substring(
                i,
                i + 82 > remarkArray[r]?.length
                  ? remarkArray[r]?.length
                  : i + 82
              ) + "..."
            }` || ""
          );
        } else if (
          rowRemark === 2 &&
          remarkArray[r] !== undefined &&
          remarkArray[r]?.length < 85
        ) {
          doc.text(10, remark_y_axis, `${remarkArray[r]}` || "");
        }
        remark_y_axis += 6;
        rowRemark += 1;
      }
    }
  }
  window.open(doc.output("bloburl"));
}

export default Price_List_Report;
