import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import QRCode from "qrcode.react";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import "../../assets/css/table.css";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

function Factory_parameter_add(mode) {
 
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [section, setsection] = useState([]);
  const [filler_section, setFiller_section] = useState({
    id: "",
    section_name: "",
    oem_id: getOem()
 });

  const [process, setprocess] = useState({
    company_id: getOem(),
    id: "",
    section_id: "",
    section_name: "",
    process_name: "",
    is_use: "",
    sort: "",
  });

  const  {id} = useParams();

  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetSection = async () => {
 
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/section/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_section
    
    })
      .then(function (response) {
        //console.log(response.data);
        setsection(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function saveOption(params) {
    const error_list = [];
    if(process.section_id == ""){
      let temp_err = {
        message: "Please enter information in the fields >>> [Section]."
      }
      error_list.push(temp_err);
    }
    if(process.process_name.trim() == ""){
      let temp_err = {
        message: "Please enter information in the fields >>> [Process Name]."
      }
      error_list.push(temp_err);
    }
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
        const temp ={
          company_id: getUser().com,
          oem_id: getOem(),
          section_id: process.section_id,
          process_name: process.process_name.trim(),
        }
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/factoryParameter/process/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href = "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/process";
                });
              }
  
             //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
  
      if (mode.mode === "edit") {
  
        const temp ={
          id:id,
          company_id: getUser().com,
          oem_id: getOem(),
          section_id: process.section_id,
          //section_name: process.section_name,
          process_name: process.process_name.trim(),
        }
        //console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/factoryParameter/process/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/process";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    
    }
   

}

useEffect(() => {
  //console.log("id:"+id);
  GetSection();
  GetProcess_view();
}, []);

const GetProcess_view = async () => {
     
  if ( mode.mode =="read" || mode.mode =="edit"  ) {
    axios({
      method: "get",
      url: Configs.API_URL + "/factoryParameter/process/findProcessByOEM?id="+ id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setprocess({
          company_id: response.data.company_id,
          section_id: response.data.section_id,
          section_name: response.data.section_name,
          process_name: response.data.process_name,
          is_use: response.data.is_use,
          sort: response.data.sort,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
}



 

function cancelOption(params) {
 

  Swal.fire({
    title: 'Do you want to cancel and leave the changes?',
    icon:'warning',
    showCancelButton: true,
    confirmButtonText: `Yes`,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText:`No`,
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/process";
    }
  }
  );
}

  

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="facpara" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
              
                
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-process-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-process"
                    role="tab"
                    aria-controls="custom-tabs-four-process"
                    aria-selected="false"
                  >
                    Process
                  </a>
                </li>
             
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
               
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-process"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-process-tab"
                >
        <h3 class="title ng-binding">{pageMode} Process</h3>
        <div className="tab-custom-content"></div><br></br>
        
                  <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-md-4">
                <div className="form-group ">
               

                              <select
                    className="custom-select select2 form-control"
                    value={process.section_id}
                    disabled={mode.mode === "read" ? true : false}
                    onChange={(e) => {
                      setprocess({
                        ...process,
                        section_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Section
                    </option>
                    {section.map((el) => {
                      return <option value={el.id}>{el.section_name}</option>;
                    })}
                  </select>
                  <label htmlFor="">Section
                  {process.section_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                  </label>
                </div>
             
              </div>
             </div>
             <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    value = {process.process_name.trim()}
                    disabled={disable}
                      onChange={(e) => {
                        setprocess({
                          ...process,
                          process_name: e.target.value.trim(),
                        });
                      }}    
                  />
                  <label htmlFor="">Process Name
                  {process.process_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                  </label>{" "}
                </div>
              </div>
              <div className="col-md-8">
                  </div>
             {/*  <div className="col-md-4">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                  
                  />
                  <label htmlFor="">Process detail</label>{" "}
                </div>
              </div> */}
              

              </div>
            
              {mode.mode === "read" ? (
              <div className="row mb-2">
              <div className="col-sm-1">
                <button
                  type="button"
                  onClick={cancelOption}
                  class="btn btn-block btn-danger "
                >
                  Cancel
                </button>
              </div>
            </div>
            
              ) : (     
                <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    
                      onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
              )}
              </div>
              

                 
              

               

                
              
               

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  {/*     <Footter /> */}
    </div>
  );
}
export default Factory_parameter_add;
