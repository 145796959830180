import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
function MachineInOut(params) {
  const [project, setProject] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
 
  const [filter, setfilter] = useState({
    osl_id:"",
    oem_id: getOem(),
  });
  const [osl,setosl] = useState([]);
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetOSL = async () => {
    let temp = {
      osl_no:"",
      oem_id:getOem(),
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/orderSummary/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        console.log(response.data);
        setosl(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var testdata = [];
    let temp ={
      code_car:"บค-8693 สต",
      in_voice:"IV230145",
      time_out:"8.40",
      time_in:"9.30",
      rate:true,
    }
    let temp2 ={
      code_car:"บจ-4081 สต",
      in_voice:"IV230146",
      time_out:"9.02",
      time_in:"",
      rate:false,
    }
    let temp3 ={
      code_car:"80-4086 สต",
      in_voice:"IV230147",
      time_out:"",
      time_in:"",
      rate:false,
    }
    testdata.push(temp);
    testdata.push(temp2);
    testdata.push(temp3);
    setdriver(testdata);
    GetOSL();
    GetCompany();
  }, []);



  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Project/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/project/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Project";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];
  const [driver,setdriver] = useState([]);
  const [driver_person,setdriver_person] = useState([]);
  const [test,settest] = useState({
    test:"",
  });
  function SetTimeOut(index,e){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var tempData = osl_data;
      //  setosl_data([]);
    //console.log(index,e)
    tempData[index].time_out = moment(new Date()).format();
    if(tempData[index].time_in && tempData[index].time_out){
      var in_ = tempData[index].time_in;
      var out_ = tempData[index].time_out;
      let unix_timestamp = moment(out_).unix() - moment(in_).unix();
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      var date = new Date((unix_timestamp * 1000));
      // Hours part from the timestamp
      var hours = date.getHours() - 7;
      // Minutes part from the timestamp
      var minutes = date.getMinutes();
      // Seconds part from the timestamp
      var seconds = date.getSeconds();
      tempData[index].actual_time = Math.ceil(hours+"."+minutes+seconds);
      tempData[index].actual_time_show = hours+" ชั่วโมง "+minutes+" นาที";
      let temp = {
        product_plan:tempData,
        osl_id:osl_select.id,

      }
      axios({
        method: "post",
        url: Configs.API_URL + "/orderSummary/SetActualTimeByOSL",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data:temp,
      })
        .then(function (response) {
          Load_osl();
          settest({...test,test:""});
        test.test= "";
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "Something went wrong: " + error, "error");
        });

    }
 /*    setosl_data(tempData);
    settest({...test,test:""});
    test.test= ""; */
      }
    })
    
  }

  function SetTimeIn(index,e){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var tempData = osl_data;
      tempData[index].time_in = moment(new Date()).format();
      //console.log(tempData);
      let temp = {
        product_plan:tempData,
        osl_id:osl_select.id,

      }
      axios({
        method: "post",
        url: Configs.API_URL + "/orderSummary/SetActualTimeByOSL",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data:temp,
      })
        .then(function (response) {
          Load_osl();
          settest({...test,test:""});
      test.test= "";
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "Something went wrong: " + error, "error");
        });
     // setosl_data(tempData);
      
    
            //Swal.fire("Error","สินค้าก่อนหน้ายังไม่ออกจากเครื่องนี้","error");
       
      
      }
    })
   
}

 function del_personDriver(e){
   var temp = driver_person;
   setdriver_person([]);
   temp.splice(e.target.id, 1);
   setdriver_person(temp);
   settest({...test,test:""});
   test.test = "";
 }
function SetRate(index){
  var tempData = driver;
  console.log(index);
 /*  setdriver([]);
console.log(index);
tempData[index].rate = true;
setdriver(tempData);
settest({...test,test:""});
test.test= ""; */
}



  
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  function add_person_driver(){
   var t = driver_person;
   setdriver_person([]);
   console.log(statusAdd_driver.status)
   t.push(statusAdd_driver.status);
   setdriver_person(t);
   settest({...test,test:""});
   test.test = "";
  }
  const [statusAdd_driver,setstatusAdd_driver] = useState({
    status:"คนขับ",
  })
  
  const [osl_select,setosl_select] = useState({
    id:"",
  })
  const [osl_data,setosl_data] = useState([]);
  function Load_osl(){
    axios({
      method: "get",
      url: Configs.API_URL + "/orderSummary/findById?id=" + osl_select.id,
      headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
      },
  })
  .then(function(response) {
    setosl_data(response.data.partno_list.product_plan);
     console.log(response.data)
  })
  .catch(function(error) {
      console.log(error);
  });
  }


  for (var index = 0; index < osl_data.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["product_no"] = osl_data[index].product_no;
    rowItem["machine"] = osl_data[index].machine.machine_name;
    rowItem["time"] = osl_data[index].length + " ชม.";
    var actual_time = 0;
    if(osl_data[index].actual_time_show){
      actual_time = osl_data[index].actual_time_show;
    }
    /* if(osl_data[index].time_in && osl_data[index].time_out){
      var in_ = osl_data[index].time_in;
      var out_ = osl_data[index].time_out;
      let unix_timestamp = moment(out_).unix() - moment(in_).unix();
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      var date = new Date((unix_timestamp * 1000));
      // Hours part from the timestamp
      var hours = date.getHours() - 7;
      // Minutes part from the timestamp
      var minutes = "0" + date.getMinutes();
      // Seconds part from the timestamp 
      var seconds = "0" + date.getSeconds();
      actual_time = hours+"."+minutes;
    } */
    rowItem["actual_time"] = actual_time;
    
    var time_out = moment(osl_data[index].time_out).format('YYYY-MM-DD HH:mm:ss');
    var disin = false;
    var disout = false;
    var checkin = osl_data.filter((e)=>{
      return e.machine.id === osl_data[index].machine.id && e.time_in && !e.time_out
    })
    if(checkin.length > 0){
      disin = true;
    }
 
    if(!osl_data[index].time_in){
      disout = true;
    }
    if(!osl_data[index].time_out){
      var time_out = (  <button
        type="button"
        className="btn btn-block btn-info"
        disabled = {disout}
        onClick={SetTimeOut.bind(this,index)}
      >
        ออก
      </button>);
    }
    rowItem["time_out"] = time_out;

    var time_in = 
    moment(osl_data[index].time_in).format('YYYY-MM-DD HH:mm:ss');

  if(!osl_data[index].time_in){
    var time_in = (  <button
      type="button"
      className="btn btn-block btn-warning"
      disabled = {disin}
      onClick={SetTimeIn.bind(this,index)}
    >
      เข้า
    </button>);
  }
  rowItem["time_in"] = time_in;
  
   
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Machine",
        field: "machine",
        sort: "asc",
        width: 50,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 50,
      },
      {
        label: "Actual time",
        field: "actual_time",
        sort: "asc",
        width: 50,
      },
      {
        label: "เวลาเข้า",
        field: "time_in",
        sort: "asc",
        width: 50,
      },
      {
        label: "เวลาออก",
        field: "time_out",
        sort: "asc",
        width: 50,
      },
     
      
    ],
    rows: rowsData,
  };
  
    console.log(osl_data)
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Machine In/OUT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
 
                  </li>
                  <li className="breadcrumb-item active">Machine In/OUT</li>
                </ol>
              </div>
            </div>
          </div>

        {/*   <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_project.project_name1.trim()}
                    onChange={(e) => {
                      setFiller_project({
                        ...filler_project,
                        project_name: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Name</label>{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetProject();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            
            </div>
          </div> */}

         {/*  <div className="container-fluid">
            <div className="row">
              
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div> */}
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Machine In/OUT</h3>
              <div className="card-tools"></div>
            </div>
          <br/>
          <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
            <div className="row mb-2">
          
              <div className="col-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={setosl_select.id}
                    onChange={(e)=>{setosl_select({...osl_select,id:e.target.value})}}
                  >
                    <option value="">Select OSL</option>
                    {osl.map((e)=>{
                      return <option value={e.id}>{e.oslno}</option>;
                    })}
                  </select>
                  <label htmlFor="">Select OSL</label>
                </div>
              </div>
             
            
              <div className="col-md-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary"
                  onClick={Load_osl}
                  >
                    Load OSL
                  </button>
                </div>
              </div>
              </div>
              </div>
            <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                disableRetreatAfterSorting={true}
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>













      <div className="modal fade" id="modal-add_rate">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">ประเมิน</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
              <div className="col-md-12" style={{textAlign: "center" }}>
                <h1>หาดใหญ่กรุ๊ป</h1>
                </div>
                </div>
                <div className="row">
              <div className="col-md-12" style={{textAlign: "center" }}>
                <h2>รบกวนให้คะแนน<br/>ความพึงพอใจ</h2>
                </div>

                <button
                    //  disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                    //onClick={()=>{SetRate(test.test);}}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  ไม่แสดงความคิดเห็น
                  </button>
                </div>
              <div className="row">
              <div className="col-md-10">
              <h3>ได้เหล็กครบ</h3>
              </div>
              <div className="col-md-10" style={{textAlign: "center" }}>
      
           
              <ReactStars
    count={5}
    onChange={ratingChanged}
    size={50}
    activeColor="#ffd700"
  />
                 </div>
              <div className="col-md-1"></div>
              </div>

              <div className="row">
              <div className="col-md-10">
              <h3>ราคาผ่าน</h3>
              </div>
              <div className="col-md-10" style={{textAlign: "center" }}>
      
           
              <ReactStars
    count={5}
    onChange={ratingChanged}
    size={50}
    activeColor="#ffd700"
  />
                 </div>
              <div className="col-md-1"></div>
              </div>

              <div className="row">
              <div className="col-md-10">
              <h3>บริการเร็ว</h3>
              </div>
              <div className="col-md-10" style={{textAlign: "center" }}>
      
           
              <ReactStars
    count={5}
    onChange={ratingChanged}
    size={50}
    activeColor="#ffd700"
  />
                 </div>
              <div className="col-md-1"></div>
              </div>

              
               
              <div className="row">
                <div className="col-1"></div>
                
                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
            
                  {/* {supplier_select.mode === "edit" ? (
                    <>
                     <button
                     disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                    onClick={Edit_Suppier}
                    data-dismiss="modal"
                    className="btn btn-primary" 
                  >
                    Save Change
                  </button>
                  
                    </>
                  ) : (
                    <> */}
                    <button
                    //  disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                    onClick={()=>{SetRate(test.test);}}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  บันทึก
                  </button>
                  
                 {/*  </>
                  )}
                      */}
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>




      <div className="modal fade" id="modal-add_driver">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">เพิ่มผู้ขับ</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
            <div className="col-md-12">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={statusAdd_driver.status}
                    onChange={(e)=>{setstatusAdd_driver({...statusAdd_driver,status:e.target.value});
                    statusAdd_driver.status = e.target.value;
                  }}
                  >
                    <option value="">--เลือกประเภท--</option>
                    <option value="คนขับ">คนขับ</option>
                    <option value="ผู้ช่วย">ผู้ช่วย</option>
            
                  </select>
                  <label htmlFor="">ประเภท</label>
                </div>
              </div>
              </div>

              
               
              <div className="row">
                <div className="col-3">
                 
                <button
        type="button"
        className="btn btn-block btn-info"
        onClick={add_person_driver}
      >
        เพิ่ม
      </button>
                </div>
                
                
              </div>
                      <br/>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  {driver_person.map((e,index)=>{
                    if(e === "คนขับ"){
                      return(<>
     
               
                        <div className="row">
                
                        <div className="col-md-10">
            <div className="form-group ">
              <select
                className="custom-select select2 form-control"
               // value={fillter.month}
               // onChange={(e)=>{setfillter({...fillter,month:e.target.value})}}
              >
                <option value="">--เลือกคนขับ--</option>
                <option value="">นาย ก กก</option>
                <option value="">นาย ข ขข</option>
                <option value="">นาย ค คค</option>
                <option value="">นาย ข งง</option>
        
              </select>
              <label htmlFor="">เลือกคนขับ</label>
            </div>
          </div>
          <div className="col-md-2">
          <div className="form-group ">
          <button
          id = {index}
    type="button"
    className="btn btn-block btn-danger"
    onClick={del_personDriver.bind(this)}
  >
    X
  </button>    </div>
            </div>
          </div>
                  </>)
                    }else{
                      return(<>
     
               
                        <div className="row">
                
                        <div className="col-md-10">
          
                    
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                   /*  value={filler_end_user.name.trim()}
                    onChange={(e) => {
                      setFiller_end_user({
                        ...filler_end_user,
                        name: e.target.value.trim(),
                      });
                    }}  */
                  />
                  <label htmlFor="">ผู้ช่วย</label>{" "}
                </div>


          </div>
          <div className="col-md-2">
          <div className="form-group ">
          <button
     id = {index}
     type="button"
     className="btn btn-block btn-danger"
     onClick={del_personDriver.bind(this)}
  >
    X
  </button>    </div>
            </div>
          </div>
                  </>)
                    }


                     
                  })}
                </div>
                
                
              </div>








            </div>
            <div className="modal-footer justify-content-between">
            
                  {/* {supplier_select.mode === "edit" ? (
                    <>
                     <button
                     disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                    onClick={Edit_Suppier}
                    data-dismiss="modal"
                    className="btn btn-primary" 
                  >
                    Save Change
                  </button>
                  
                    </>
                  ) : (
                    <> */}
                    <button
                    //  disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                    onClick={()=>{SetRate(test.test);}}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  บันทึก
                  </button>
                  
                 {/*  </>
                  )}
                      */}
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>








   {/*   <Footter/> */}
     </div>
     
  );
}

export default MachineInOut;
