import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";
import axios from "axios";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getComConfig,
  getAcademy,
} from "../../../../../Utils/Common";
import Configs from "../../../../../config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Select from "react-select";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../../../../assets/font/pdf_font";
import jsPDF from "jspdf";
import numeral from "numeral";
import moment from "moment";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";

function Sale_Order_Report(company, req_product, statusCheck) {
  // console.log("company", company);
  // console.log("product", req_product);
  // console.log("statusCheck", statusCheck);

  let Sale = req_product.Sale ? req_product.Sale : {};
  let SaleProduct = req_product.SaleProduct ? req_product.SaleProduct : [];
  let lang =
    Sale.so_lang !== null &&
    Sale.so_lang !== undefined &&
    Sale.so_lang.toLowerCase() === "th"
      ? "th"
      : "eng";

  let Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
  const doc = new jsPDF("p", "mm", "a4");
  let counts = 0;
  let row = 0;
  let startPoint = 0;
  for (let index = 0; index < SaleProduct.length; index += row) {
    // pruct.length > 16 เปลี่ยนหน้า
    doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");

    doc.setFillColor("#8DC445");
    doc.rect(0, 0, 221, 18, "F");
    doc.addImage(Com_logo, "JPEG", 5, 0, 18, 18);
    doc.rect(0, 0, 5.5, 18, "F");

    doc.setFont("THSarabunNew");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(20);

    doc.setFont("THSarabunNewBold");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(32);
    doc.text(205, 12, "Sale Order / Pick List", "right");
    doc.setFont("THSarabunNew");
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(16);
    doc.text(205, 17.5, "รายการสั่งซื้อ / ใบเบิกสินค้า", "right");

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(5, 24, `Customer:`);
    let cusName_y_axis = 30;
    let cus_name =
      Sale.so_cus_name !== null && Sale.so_cus_name !== undefined
        ? Sale.so_cus_name
        : "";
    let cusNameArr = [];
    let tempCusName = cus_name.split(" ");
    if (cus_name.length > 30) {
      for (let k = 0; k < tempCusName.length; k++) {
        if (cusNameArr.length <= 0) {
          cusNameArr.push(tempCusName[k]);
        } else if (cusNameArr.length > 0) {
          if (
            (cusNameArr[cusNameArr.length - 1] + " " + tempCusName[k]).length <
            30
          ) {
            cusNameArr[cusNameArr.length - 1] += " " + tempCusName[k];
          } else {
            cusNameArr.push(tempCusName[k]);
          }
        }
      }
    } else {
      cusNameArr.push(cus_name);
    }
    // startPoint = cusNameArr.length > 1 ? 30 + cusNameArr.length * 6 : 30
    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(5, cusName_y_axis, `ชื่อบริษัท/Name : `);
    for (let item of cusNameArr) {
      doc.text(32, cusName_y_axis, `${item}`);
      cusName_y_axis += 6;
    }

    let cus_contact =
      Sale.so_contact_name !== null && Sale.so_contact_name !== undefined
        ? Sale.so_contact_name
        : "";
    let cusContactArr = [];
    let tempCusContact = cus_contact.split(" ");
    if (cus_contact.length > 30) {
      for (let k = 0; k < tempCusContact.length; k++) {
        if (cusContactArr.length <= 0) {
          cusContactArr.push(tempCusContact[k]);
        } else if (cusContactArr.length > 0) {
          if (
            (cusContactArr[cusContactArr.length - 1] + " " + tempCusContact[k])
              .length < 30
          ) {
            cusContactArr[cusContactArr.length - 1] += " " + tempCusContact[k];
          } else {
            cusContactArr.push(tempCusContact[k]);
          }
        }
      }
    } else {
      cusContactArr.push(cus_contact);
    }
    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(5, cusName_y_axis, `ชื่อผู้ติดต่อ/Contact : `);
    for (let item of cusContactArr) {
      doc.text(38, cusName_y_axis, `${item}`);
      cusName_y_axis += 6;
    }

    let cus_house_num =
      Sale.so_cus_house_number !== null &&
      Sale.so_cus_house_number !== undefined
        ? Sale.so_cus_house_number
        : "";
    let cus_building =
      Sale.so_cus_building !== null && Sale.so_cus_building !== undefined
        ? Sale.so_cus_building
        : "";
    let cus_swine =
      Sale.so_swine !== null && Sale.so_swine !== undefined
        ? Sale.so_swine
        : "";
    let cus_soi =
      Sale.so_soi !== null && Sale.so_soi !== undefined ? Sale.so_soi : "";
    let cus_road =
      Sale.so_road !== null && Sale.so_road !== undefined ? Sale.so_road : "";
    let cus_subdistrict =
      Sale.so_sub_district !== null && Sale.so_sub_district !== undefined
        ? Sale.so_sub_district
        : "";
    let cus_district =
      Sale.so_district !== null && Sale.so_district !== undefined
        ? Sale.so_district
        : "";
    let cus_province =
      Sale.so_province !== null && Sale.so_province !== undefined
        ? Sale.so_province
        : "";
    let cus_postcode =
      Sale.so_postcode !== null && Sale.so_postcode !== undefined
        ? Sale.so_postcode
        : "";
    let cus_address = `${cus_house_num} ${cus_building} ${cus_swine} ${cus_soi} ${cus_road} ${cus_subdistrict} ${cus_district} ${cus_province} ${cus_postcode}`;
    let cusAddressArr = [];
    let tempCusAddress = cus_address.split(" ");
    if (cus_address.length > 30) {
      for (let k = 0; k < tempCusAddress.length; k++) {
        if (cusAddressArr.length <= 0) {
          cusAddressArr.push(tempCusAddress[k]);
        } else if (cusAddressArr.length > 0) {
          if (
            (cusAddressArr[cusAddressArr.length - 1] + " " + tempCusAddress[k])
              .length < 30
          ) {
            cusAddressArr[cusAddressArr.length - 1] += " " + tempCusAddress[k];
          } else {
            cusAddressArr.push(tempCusAddress[k]);
          }
        }
      }
    } else {
      cusAddressArr.push(cus_address);
    }

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(5, cusName_y_axis, `ที่อยู่/Address : `);
    for (let item of cusAddressArr) {
      doc.text(30, cusName_y_axis, `${item}`);
      cusName_y_axis += 6;
    }

    // ship to
    let cus_ship_y_axis2 = 30;
    let cus_name_ship =
      Sale.so_csh_name !== null && Sale.so_csh_name !== undefined
        ? Sale.so_csh_name
        : "";
    let cusNameShipArr = [];
    let tempCusNameShip = cus_name_ship.split(" ");
    if (cus_name_ship.length > 30) {
      for (let k = 0; k < tempCusNameShip.length; k++) {
        if (cusNameShipArr.length <= 0) {
          cusNameShipArr.push(tempCusNameShip[k]);
        } else if (cusNameShipArr.length > 0) {
          if (
            (
              cusNameShipArr[cusNameShipArr.length - 1] +
              " " +
              tempCusNameShip[k]
            ).length < 30
          ) {
            cusNameShipArr[cusNameShipArr.length - 1] +=
              " " + tempCusNameShip[k];
          } else {
            cusNameShipArr.push(tempCusNameShip[k]);
          }
        }
      }
    } else {
      cusNameShipArr.push(cus_name_ship);
    }

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(85, 24, `Ship to:`);

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    for (let item of cusNameShipArr) {
      doc.text(85, cus_ship_y_axis2, `${item}`);
      cus_ship_y_axis2 += 6;
    }

    let cus_ship_house_num =
      Sale.so_ship_house_number !== null &&
      Sale.so_ship_house_number !== undefined
        ? Sale.so_ship_house_number
        : "";
    let cus_ship_building =
      Sale.so_ship_building !== null && Sale.so_ship_building !== undefined
        ? Sale.so_ship_building
        : "";
    let cus_ship_swine =
      Sale.so_ship_swine !== null && Sale.so_ship_swine !== undefined
        ? Sale.so_ship_swine
        : "";
    let cus_ship_soi =
      Sale.so_ship_soi !== null && Sale.so_ship_soi !== undefined
        ? Sale.so_ship_soi
        : "";
    let cus_ship_road =
      Sale.so_ship_road !== null && Sale.so_ship_road !== undefined
        ? Sale.so_ship_road
        : "";
    let cus_ship_subdistrict =
      Sale.so_ship_sub_district !== null &&
      Sale.so_ship_sub_district !== undefined
        ? Sale.so_ship_sub_district
        : "";
    let cus_ship_district =
      Sale.so_ship_district !== null && Sale.so_ship_district !== undefined
        ? Sale.so_ship_district
        : "";
    let cus_ship_province =
      Sale.so_ship_province !== null && Sale.so_ship_province !== undefined
        ? Sale.so_ship_province
        : "";
    let cus_ship_postcode =
      Sale.so_ship_postcode !== null && Sale.so_ship_postcode !== undefined
        ? Sale.so_ship_postcode
        : "";
    let cus_ship_address = `${cus_ship_house_num} ${cus_ship_building} ${cus_ship_swine} ${cus_ship_soi} ${cus_ship_road} ${cus_ship_subdistrict} ${cus_ship_district} ${cus_ship_province} ${cus_ship_postcode}`;
    let cusShipAddressArr = [];
    let tempCusShipAddress = cus_ship_address.split(" ");
    if (cus_ship_address.length > 28) {
      for (let k = 0; k < tempCusShipAddress.length; k++) {
        if (cusShipAddressArr.length <= 0) {
          cusShipAddressArr.push(tempCusShipAddress[k]);
        } else if (cusShipAddressArr.length > 0) {
          if (
            (
              cusShipAddressArr[cusShipAddressArr.length - 1] +
              " " +
              tempCusShipAddress[k]
            ).length < 28
          ) {
            cusShipAddressArr[cusShipAddressArr.length - 1] +=
              " " + tempCusShipAddress[k];
          } else {
            cusShipAddressArr.push(tempCusShipAddress[k]);
          }
        }
      }
    } else {
      cusShipAddressArr.push(cus_ship_address);
    }

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(85, 36, `ที่อยู่/Address : `);
    for (let item of cusShipAddressArr) {
      doc.text(109, cus_ship_y_axis2, `${item}`);
      cus_ship_y_axis2 += 6;
    }

    let cus_ship_contact =
      Sale.so_ship_contract_name !== null &&
      Sale.so_ship_contract_name !== undefined
        ? Sale.so_ship_contract_name
        : "";
    let cusShipContactArr = [];
    let tempCusShipContact = cus_ship_contact.split(" ");
    if (cus_ship_contact.length > 25) {
      for (let k = 0; k < tempCusShipContact.length; k++) {
        if (cusShipContactArr.length <= 0) {
          cusShipContactArr.push(tempCusShipContact[k]);
        } else if (cusShipContactArr.length > 0) {
          if (
            (
              cusShipContactArr[cusShipContactArr.length - 1] +
              " " +
              tempCusShipContact[k]
            ).length < 25
          ) {
            cusShipContactArr[cusShipContactArr.length - 1] +=
              " " + tempCusShipContact[k];
          } else {
            cusShipContactArr.push(tempCusShipContact[k]);
          }
        }
      }
    } else {
      cusShipContactArr.push(cus_ship_contact);
    }
    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(85, cus_ship_y_axis2, `ชื่อผู้ติดต่อ/Contact : `);
    for (let item of cusShipContactArr) {
      doc.text(118, cus_ship_y_axis2, `${item}`);
      cus_ship_y_axis2 += 6;
    }

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.setTextColor("#292520");
    doc.setDrawColor("#292520");
    doc.line(158, 25, 163.656, 19.344);
    doc.rect(158, 20, 5, 5);
    doc.text(175, 24, `ใบ SO`, "right");
    doc.rect(181, 20, 5, 5);
    doc.text(205, 24, `ใบเบิกสินค้า`, "right");

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(
      205,
      30,
      `No : ${
        Sale.so_no !== null && Sale.so_no !== undefined ? Sale.so_no : ""
      }`,
      "right"
    );

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(
      205,
      36,
      `วันที่/Date : ${
        Sale.so_date !== null && Sale.so_date !== undefined
          ? moment(Sale.so_date).format("DD/MM/YYYY")
          : ""
      }`,
      "right"
    );

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(
      205,
      42,
      `Ref : ${
        Sale.quo_no !== null && Sale.quo_no !== undefined ? Sale.quo_no : ""
      }`,
      "right"
    );

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.setFont("THSarabunNew");
    doc.text(
      205,
      48,
      `วันที่จัดส่ง : ${
        Sale.so_delivery_date !== null && Sale.so_delivery_date !== undefined
          ? moment(Sale.so_delivery_date).format("DD/MM/YYYY")
          : ""
      }`,
      "right"
    );

    /*-------------------------------------------------------------------------------*/
    //header
    if (cusName_y_axis < 50 && cus_ship_y_axis2 < 50) {
      startPoint = 50;
    } else if (cusName_y_axis > cus_ship_y_axis2) {
      startPoint = cusName_y_axis;
    } else {
      startPoint = cus_ship_y_axis2;
    }

    doc.setFillColor("#8DC445");
    doc.roundedRect(4, startPoint, 202, 12, 1, 1, "F"); //(4, 52, 202, 12, 1, 1, "F");

    let gap1 = startPoint + 5;
    let gap2 = startPoint + 10;
    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(11, gap1, "No.", "center"); //(11, 62, "No.", "center");
    doc.setFontSize(15);
    doc.text(11, gap2, "ลำดับ", "center"); //(11, 57, "ลำดับ", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(24.5, gap1, "Picture", "center"); //(24.5, 57, "Picture", "center");
    doc.setFontSize(15);
    doc.text(24.5, gap2, "รูปสินค้า", "center"); //(24.5, 62, "รูปสินค้า", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(38, gap1, "Item Description", "left"); //(38, 57, "Item Description", "left");
    doc.setFontSize(15);
    doc.text(38, gap2, "รายละเอียดสินค้า", "left"); //(38, 62, "รายละเอียดสินค้า", "left");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(110, gap1, "Customer Barcode", "center"); //(110, 57, "Customer Barcode", "center");
    doc.setFontSize(15);
    doc.text(110, gap2, "บาร์โค้ดลูกค้า", "center"); //(110, 62, "บาร์โค้ดลูกค้า", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(140, gap1, "Qty.", "center"); //(140, 57, "Qty.", "center");
    doc.setFontSize(15);
    doc.text(140, gap2, "จำนวน", "center"); //(140, 62, "จำนวน", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(165, gap1, "Pcs.", "center"); //(165, 57, "Pcs.", "center");
    doc.setFontSize(15);
    doc.text(165, gap2, "ชิ้น", "center"); //(165, 62, "ชิ้น", "center");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(190, gap1, "Exp./lot", "center"); //(190, 57, "Exp./lot", "center");
    doc.setFontSize(15);
    doc.text(190, gap2, "วันหมดอายุ", "center"); //(190, 62, "วันหมดอายุ", "center");

    let isMore1page = false;
    let c = 0;
    row = 0;
    let countItem = 0;
    let gapcheck = 17;
    // check how many item in page
    for (let i = startPoint + 12; c < SaleProduct.length; i = i + gapcheck) {
      if (index + c < SaleProduct.length) {
        // item Description
        let brand_name =
          SaleProduct[index + c].product_data.length > 0
            ? SaleProduct[index + c].product_data[0]?.brand_name !== null
              ? SaleProduct[index + c].product_data[0]?.brand_name
              : ""
            : "";
        let product_name =
          SaleProduct[index + c].product_data.length > 0 && lang === "th"
            ? SaleProduct[index + c].product_data[0]?.product_name !== null &&
              SaleProduct[index + c].product_data[0]?.product_name !== undefined
              ? SaleProduct[index + c].product_data[0]?.product_name
              : ""
            : SaleProduct[index + c].product_data[0]?.product_name_eng !==
                null &&
              SaleProduct[index + c].product_data[0]?.product_name_eng !==
                undefined
            ? SaleProduct[index + c].product_data[0]?.product_name_eng
            : "";
        let weight =
          SaleProduct[index + c].product_data.length > 0
            ? SaleProduct[index + c].product_data[0]?.pd_weight !== null &&
              SaleProduct[index + c].product_data[0]?.pd_weight !== undefined
              ? SaleProduct[index + c].product_data[0]?.pd_weight
              : ""
            : "";
        let unit_name =
          SaleProduct[index + c].product_data.length > 0
            ? SaleProduct[index + c].product_data[0]?.pu_name !== null &&
              SaleProduct[index + c].product_data[0]?.pu_name !== undefined
              ? SaleProduct[index + c].product_data[0]?.pu_name
              : ""
            : "";

        let allItemDescription = `${brand_name + " "}${
          product_name + " "
        }${weight}${unit_name}`;
        let tempAllDes = allItemDescription.split(" ");
        let y_axis_des = 0;
        let LineArray = [];
        if (allItemDescription.length > 25) {
          for (let k = 0; k < tempAllDes.length; k++) {
            if (LineArray.length <= 0) {
              LineArray.push(tempAllDes[k]);
            } else if (LineArray.length > 0) {
              if (LineArray[LineArray.length - 1].length < 25) {
                LineArray[LineArray.length - 1] += " " + tempAllDes[k];
              } else {
                LineArray.push(tempAllDes[k]);
              }
            }
          }
        } else {
          LineArray.push(allItemDescription);
        }
        if (LineArray.length > 1) {
          gapcheck =
            6 * LineArray.length + 10 < 17 ? 17 : 6 * LineArray.length + 10;
        } else {
          gapcheck = 17;
        }
        if (270 - i < gapcheck) {
          break;
        } else if (index + c + 1 === SaleProduct.length) {
          countItem += 1;
          break;
        } else {
          c++;
          countItem += 1;
        }
      }
    }
    let gapEachProduct = 17;
    isMore1page = false;
    row = 0;
    if (countItem > 0) {
      for (let i = startPoint + 12; row <= countItem; i = i + gapEachProduct) {
        if (index + row < SaleProduct.length) {
          // item Description
          let middleRow = 0;
          let imgMiddleRow = 0;
          let brand_name =
            SaleProduct[index + row]?.product_data.length > 0
              ? SaleProduct[index + row]?.product_data[0]?.brand_name !== null
                ? SaleProduct[index + row]?.product_data[0]?.brand_name
                : ""
              : "";
          let product_name =
            SaleProduct[index + row]?.product_data.length > 0 && lang === "th"
              ? SaleProduct[index + row]?.product_data[0]?.product_name !==
                  null &&
                SaleProduct[index + row]?.product_data[0]?.product_name !==
                  undefined
                ? SaleProduct[index + row]?.product_data[0]?.product_name
                : ""
              : SaleProduct[index + row]?.product_data[0]?.product_name_eng !==
                  null &&
                SaleProduct[index + row]?.product_data[0]?.product_name_eng !==
                  undefined
              ? SaleProduct[index + row]?.product_data[0]?.product_name_eng
              : "";
          let weight =
            SaleProduct[index + row]?.product_data.length > 0
              ? SaleProduct[index + row]?.product_data[0]?.pd_weight !== null &&
                SaleProduct[index + row]?.product_data[0]?.pd_weight !== undefined
                ? SaleProduct[index + row]?.product_data[0]?.pd_weight
                : ""
              : "";
          let unit_name =
            SaleProduct[index + row]?.product_data.length > 0
              ? SaleProduct[index + row]?.product_data[0]?.pu_name !== null &&
                SaleProduct[index + row]?.product_data[0]?.pu_name !== undefined
                ? SaleProduct[index + row]?.product_data[0]?.pu_name
                : ""
              : "";
          let barcode =
            SaleProduct[index + row]?.product_data.length > 0
              ? SaleProduct[index + row]?.product_data[0]?.pb_piece !== null &&
                SaleProduct[index + row]?.product_data[0]?.pb_piece !== undefined
                ? SaleProduct[index + row]?.product_data[0]?.pb_piece
                : ""
              : "";
          let piece_pack =
            SaleProduct[index + row]?.product_data.length > 0
              ? SaleProduct[index + row]?.product_data[0]?.pd_sale_pc_pack !==
                  null &&
                SaleProduct[index + row]?.product_data[0]?.pd_sale_pc_pack !==
                  undefined
                ? SaleProduct[index + row]?.product_data[0]?.pd_sale_pc_pack
                : "-"
              : "-";
          let piece_carton =
            SaleProduct[index + row]?.product_data.length > 0
              ? SaleProduct[index + row]?.product_data[0]?.pd_sale_pc_ctn !==
                  null &&
                SaleProduct[index + row]?.product_data[0]?.pd_sale_pc_ctn !==
                  undefined
                ? SaleProduct[index + row]?.product_data[0]?.pd_sale_pc_ctn
                : "-"
              : "-";

          let allItemDescription = `${brand_name + " "}${
            product_name + " "
          }${weight}${unit_name}`;
          let tempAllDes = allItemDescription.split(" ");
          let y_axis_des = 0;
          let LineArray = [];
          if (allItemDescription.length > 25) {
            for (let k = 0; k < tempAllDes.length; k++) {
              if (LineArray.length <= 0) {
                LineArray.push(tempAllDes[k]);
              } else if (LineArray.length > 0) {
                if (LineArray[LineArray.length - 1].length < 25) {
                  LineArray[LineArray.length - 1] += " " + tempAllDes[k];
                } else {
                  LineArray.push(tempAllDes[k]);
                }
              }
            }
            gapEachProduct =
              4.5 * LineArray.length + 15 < 17
                ? 17
                : 4.5 * LineArray.length + 15;
            y_axis_des = i + 6;
            middleRow = i + (LineArray.length * 6) / 2 + 7;
            imgMiddleRow = i + (LineArray.length * 6) / 2;
          } else {
            LineArray.push(allItemDescription);
            gapEachProduct = 17;
            y_axis_des = i + 6;
            middleRow = i + 9;
            imgMiddleRow = i + 4.5;
          }
          if (
            i + gapEachProduct > 260 &&
            (row === countItem || index + row + 1 === SaleProduct.length)
          ) {
            isMore1page = true;
            break;
          }

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(11, middleRow, (index + row + 1).toString(), "center"); //no.
          doc.setDrawColor("#8DC445");

          //Picture
          let product_Image = `${
            SaleProduct[index + row].pci_img_path !== "" &&
            SaleProduct[index + row].pci_img_path !== null &&
            SaleProduct[index + row].pci_img_path !== undefined
              ? SaleProduct[index + row].pci_img_path?.indexOf("blob") >= 0
                ? SaleProduct[index + row].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${SaleProduct[index + row].pci_img_name}/${
                    SaleProduct[index + row].pci_img_path
                  }`
              : userdefault_img.imgs
          }`;
          doc.addImage(product_Image, "JPEG", 19, imgMiddleRow, 9, 9);

          //Item description
          for (let itemDes of LineArray) {
            doc.setFont("THSarabunNew");
            doc.setFontSize(15);
            doc.setTextColor("#292520");
            doc.text(38, y_axis_des, `${itemDes}`, "left");
            y_axis_des += 4.5;
          }
          doc.setFontSize(15);
          doc.text(38, y_axis_des, `${barcode}`, "left");
          doc.setFontSize(15);
          y_axis_des += 4.5;
          doc.text(
            38,
            y_axis_des,
            `${piece_pack + (lang === "th" ? "/แพ็ค" : "/Pack.") + " "}${
              piece_carton + (lang === "th" ? "/ลัง" : "/Ctn.")
            }`,
            "left"
          );

          //Barcode
          let cus_barcode =
            SaleProduct[index + row]?.sop_cus_barcode !== null &&
            SaleProduct[index + row]?.sop_cus_barcode !== undefined
              ? SaleProduct[index + row]?.sop_cus_barcode
              : "";
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(110, middleRow, `${cus_barcode}`, "center");

          //Qty.
          let Qty =
            SaleProduct[index + row]?.sop_quantity !== null &&
            SaleProduct[index + row]?.sop_quantity !== undefined
              ? SaleProduct[index + row]?.sop_quantity
              : "";
          let Qty_unit =
            SaleProduct[index + row]?.sop_quantity_unit !== null &&
            SaleProduct[index + row]?.sop_quantity_unit !== undefined
              ? SaleProduct[index + row]?.sop_quantity_unit.toLowerCase() ===
                  "ctn" && lang === "th"
                ? "ลัง"
                : SaleProduct[index + row]?.sop_quantity_unit.toLowerCase() ===
                    "ctn" && lang === "eng"
                ? "Ctn."
                : SaleProduct[index + row]?.sop_quantity_unit.toLowerCase() ===
                    "pack" && lang === "th"
                ? "แพ็ค"
                : SaleProduct[index + row]?.sop_quantity_unit.toLowerCase() ===
                    "pack" && lang === "eng"
                ? "Pack"
                : SaleProduct[index + row]?.sop_quantity_unit.toLowerCase() ===
                    "piece" && lang === "th"
                ? "ชิ้น"
                : SaleProduct[index + row]?.sop_quantity_unit.toLowerCase() ===
                    "piece" && lang === "eng"
                ? "Pcs."
                : "-"
              : "-";

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            140,
            middleRow,
            `${
              parseInt(Qty)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " "
            }${Qty_unit}`,
            "center"
          );

          //pcs.
          let pcs =
            SaleProduct[index + row]?.sop_quantity !== null &&
            SaleProduct[index + row]?.sop_quantity !== undefined
              ? parseInt(SaleProduct[index + row]?.sop_quantity)
              : 0;

          if (Qty_unit.toLowerCase() === "ctn." || Qty_unit === "ลัง") {
            pcs = pcs * parseInt(piece_carton === "-" ? 0 : piece_carton);
          } else if (Qty_unit.toLowerCase() === "pack" || Qty_unit === "แพ็ค") {
            pcs = pcs * parseInt(piece_pack === "-" ? 0 : piece_pack);
          } else if (Qty_unit.toLowerCase() === "pcs." || Qty_unit === "ชิ้น") {
            pcs = pcs;
          } else {
            pcs = 0;
          }
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            165,
            middleRow,
            `${parseInt(pcs)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
            "center"
          );

          //Exp./lot
          let exp_date =
            SaleProduct[index + row]?.sop_exp_date !== null &&
            SaleProduct[index + row]?.sop_exp_date !== undefined
              ? SaleProduct[index + row]?.sop_exp_date
              : "";
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(190, middleRow, `${exp_date}`, "center");
          doc.rect(4, i + gapEachProduct, 202, 0); //line_break
          // doc.text(
          //   4,
          //   i + gapEachProduct,
          //   (i + gapEachProduct).toString(),
          //   "center"
          // ); //line_break_y_position
        }
        row = row + 1;
        counts = SaleProduct.length - countItem;
      }
    } else {
      row += 1;
    }

    //   /*------------------------------------------------------------------------*/
    if ((index < counts && index + row < SaleProduct.length) || isMore1page) {
      doc.addPage("a4", "h");
    } else {
      doc.setDrawColor("#8DC445");
      doc.rect(4, 262, 202, 0); //last line
      let total_m3 =
        Sale.so_total_m3 !== null && Sale.so_total_m3 !== undefined
          ? (
              parseFloat(Sale.so_total_m3).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0
            )
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
      let total_weight =
        Sale.so_weight !== null && Sale.so_weight !== undefined
          ? (
              parseFloat(Sale.so_weight).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0
            )
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "";
      doc.setFont("THSarabunNewBold");
      doc.setFontSize(10);
      doc.setTextColor("#292520");
      doc.text(11, 270, `Total Weight : ${total_weight}`, "left");
      doc.text(11, 276, `Total M3 : ${total_m3}`, "left");

      doc.setDrawColor("#8DC445");
      doc.rect(120, 286, 35, 0);
      doc.rect(160, 286, 35, 0);

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.setFont("THSarabunNew");
      doc.text(137.5, 292, "ผู้จัดสินค้า", "center");

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.setFont("THSarabunNew");
      doc.text(177.5, 292, "วันที่", "center");
    }
  }
  if (statusCheck === true) {
    return doc.output("datauristring");
  } else {
    window.open(doc.output("bloburl"));
  }
}

export default Sale_Order_Report;
