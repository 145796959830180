import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Configs from "../../../../config";
import moment from "moment";

function Wait_delivery_cuteplus() {
  const [Filter, setFilter] = useState({
    invoice_no: "",
    customer_id: "",
    status: [],
    start_date: "",
    end_date: "",
  });
  const [optionsStatus, setOptionsStatus] = useState([]);
  let [status_list_filter, setStatus_list_filter] = useState([]);
  const [WaitDelivery_Table, setWaitDelivery_Table] = useState({
    data: [],
  });
  const [routeDD, setRouteDD] = useState({
    data: [],
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const [customerDD, setCustomerDD] = useState([]);

  useEffect(async () => {
    await getCustomerDD();
    await getDropdownStatus();
    await getRouteDropDown();
    await FilterWaitDelivery();
  }, []);

  const getRouteDropDown = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/GetRouteDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        // const options = []
        // console.log('response.data route DD.', response.data)
        setRouteDD({ ...routeDD, data: response.data });
        routeDD.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getDropdownStatus = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/GetStatusWaitDelivery",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: { data: "" },
    })
      .then(async function (response) {
        const options = [];
        for (let item of response.data) {
          // console.log('item', item);
          let temp = {
            label: item.bs_name,
            value: item.bs_id,
          };
          options.push(temp);
        }
        // console.log('options', options);
        setOptionsStatus(options);
        // optionsStatus = options;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getCustomerDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log("response.data :>> ", response.data);
        setCustomerDD(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  const [test, setTest] = useState({
    test: "",
  });
  const clearSearch = async () => {
    setStatus_list_filter(() => []);
    status_list_filter = [];

    setFilter({
      invoice_no: "",
      customer_id: "",
      status: [],
      start_date: "",
      end_date: "",
    });
    Filter.invoice_no = "";
    Filter.customer_id = "";
    Filter.status = [];
    Filter.start_date = "";
    Filter.end_date = "";
    setTest({ ...test, test: "" });
    test.test = "";
    // console.log("status_list_filter", status_list_filter);
    await FilterWaitDelivery();
  };

  async function FilterWaitDelivery() {
    // console.log("status_list_filter.length", status_list_filter.length);
    let data = {
      user_id: getUser().fup,
      invoice_no: Filter.invoice_no == "" ? null : Filter.invoice_no.trim(),
      customer_id: Filter.customer_id == "" ? null : Filter.customer_id,
      status: status_list_filter.length === 0 ? null : status_list_filter,
      start_date: Filter.start_date || null,
      end_date: Filter.end_date || null,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/FilterWaitDelivery",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async (response) => {
        // setQuotationAdmin(response.data);
        setWaitDelivery_Table({
          ...WaitDelivery_Table,
          data: [],
        });
        WaitDelivery_Table.data = [];

        if (
          response.data &&
          response.data.length > 0 &&
          response.data.length > 0
        ) {
          // console.log("data FilterWaitDelivery :>> ", response.data);
          await getCustomerContact(response.data);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getCustomerContact = async (wd_list) => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      wd_list: wd_list,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/sale_order/getCustomerContact",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        // setQuotationAdmin(response.data);
        setWaitDelivery_Table({
          ...WaitDelivery_Table,
          data: response.data,
        });
        WaitDelivery_Table.data = response.data;
        // console.log("data :>> ", response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const rowsData = [];
  for (let index = 0; index < WaitDelivery_Table.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["ref_no"] = (
      <div style={{ textAlign: "center" }}>
        {WaitDelivery_Table.data[index].ref_no}
      </div>
    );
    rowItem["ref_no1"] = WaitDelivery_Table.data[index].ref_no;
    //  WaitDelivery_Table.data[index].quo_no

    rowItem["customer"] = (
      <div style={{ textAlign: "center" }}>
        {WaitDelivery_Table.data[index].ref_cus_name}
      </div>
    );
    rowItem["customer1"] = WaitDelivery_Table.data[index].ref_cus_name;

    rowItem["contact"] = (
      <div style={{ textAlign: "center" }}>
        {WaitDelivery_Table.data[index].cus_contact_name
          ? WaitDelivery_Table.data[index].cus_contact_name
          : "-"}
      </div>
    );
    rowItem["contact1"] = WaitDelivery_Table.data[index].cus_contact_name
      ? WaitDelivery_Table.data[index].cus_contact_name
      : "-";

    rowItem["route"] = (
      <div style={{ textAlign: "center" }}>
        {WaitDelivery_Table.data[index].bill_route_id
          ? routeDD.data?.find(
              (el) =>
                el.route_id === WaitDelivery_Table.data[index].bill_route_id
            )?.route_name
          : "-"}
      </div>
    );
    rowItem["route1"] = WaitDelivery_Table.data[index].bill_route_id
      ? routeDD.data?.find(
          (el) => el.route_id === WaitDelivery_Table.data[index].bill_route_id
        )?.route_name
      : "-";

    rowItem["delivery_date"] = (
      <div style={{ textAlign: "center" }}>
        {WaitDelivery_Table.data[index].bill_delivery_date
          ? moment(WaitDelivery_Table.data[index].bill_delivery_date).format(
              "DD/MM/YYYY"
            )
          : "-"}
      </div>
    );

    rowItem["m3"] = (
      <div style={{ textAlign: "center" }}>
        {WaitDelivery_Table.data[index].m3
          ? parseFloat(WaitDelivery_Table.data[index].m3).toFixed(2)
          : "-"}
      </div>
    );

    rowItem["status"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          type="button"
          disabled={true}
          style={{
            opacity: "1",
            width: "100%",
            border: "4px solid",
            borderRadius: "10px",
            paddingTop: ".2rem",
            paddingBottom: ".2rem",
          }}
          className={`btn btn-outline-${
            WaitDelivery_Table.data[index].bs_name === "Cancel"
              ? "info"
              : WaitDelivery_Table.data[index].bs_name === "จัดของสำเร็จ"
              ? "success"
              : WaitDelivery_Table.data[index].bs_name === "สำเร็จ"
              ? "primary"
              : WaitDelivery_Table.data[index].bs_name === "จัดของไม่สำเร็จ"
              ? "danger"
              : WaitDelivery_Table.data[index].bs_name === "ยกเลิก"
              ? "danger"
              : "secondary"
          }`}
        >
          {WaitDelivery_Table.data[index].bs_name}
        </button>
      </div>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={WaitDelivery_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/wait_delivery_cuteplus/View/" +
                WaitDelivery_Table.data[index].bill_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <a
            key={WaitDelivery_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/wait_delivery_cuteplus/Edit/" +
                WaitDelivery_Table.data[index].bill_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const Quotation_data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Ref. No.",
        field: "ref_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ผู้ติดต่อ",
        field: "contact",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Route",
        field: "route",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Delivery Date",
        field: "delivery_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "M3",
        field: "m3",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const [zIndexCalendar, setZIndexCalendar] = useState({
    start: false,
    end: false,
  });

  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>บิลรอจัดส่ง</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">บิลรอจัดส่ง</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.invoice_no}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        invoice_no: e.target.value,
                      });
                      Filter.invoice_no = e.target.value;
                    }}
                  />
                  <label>Ref. No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    type="text"
                    className="form-control"
                    required
                    value={Filter.customer_id}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        customer_id: e.target.value,
                      });
                      Filter.customer_id = e.target.value;
                    }}
                  >
                    <option key="" value="">
                      Select a customer
                    </option>
                    {customerDD.map((option, index) => (
                      <option key={option.cus_name} value={option.cus_id}>
                        {option.cus_name}
                      </option>
                    ))}
                  </select>
                  <label>Customer</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group"
                  style={{
                    zIndex: zIndexCalendar.start === true ? "999" : "500",
                  }}
                  onClick={() => {
                    setZIndexCalendar({
                      ...zIndexCalendar,
                      start: true,
                      end: false,
                    });
                    zIndexCalendar.start = true;
                    zIndexCalendar.end = true;
                  }}
                >
                  <DatePicker
                    required
                    disabled={isDisabled}
                    selected={Filter.start_date}
                    locale="th"
                    wrapperClassName="w-100"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilter({
                        ...Filter,
                        start_date: date,
                      });
                    }}
                    selectsStart
                    showYearDropdown
                    showMonthDropdown
                    startDate={Filter.start_date}
                    maxDate={Filter.end_date}
                    customInput={<CustomInput isDis={isDisabled} />}
                  />
                  <label>วันที่</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group"
                  style={{
                    zIndex: zIndexCalendar.end === true ? "999" : "500",
                  }}
                  onClick={() => {
                    setZIndexCalendar({
                      ...zIndexCalendar,
                      start: true,
                      end: false,
                    });
                    zIndexCalendar.start = true;
                    zIndexCalendar.end = true;
                  }}
                >
                  <DatePicker
                    required
                    disabled={isDisabled}
                    selected={Filter.end_date}
                    locale="th"
                    wrapperClassName="w-100"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilter({
                        ...Filter,
                        end_date: date,
                      });
                    }}
                    selectsStart
                    showYearDropdown
                    showMonthDropdown
                    // startDate={new Date().setDate(new Date().getDate() + 30)}
                    startDate={Filter.start_date}
                    minDate={Filter.start_date}
                    customInput={<CustomInput isDis={isDisabled} />}
                  />
                  <label>ถึงวันที่</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                {/* <label></label> */}
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    options={optionsStatus}
                    value={status_list_filter}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Status
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      FilterWaitDelivery();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>บิลรอจัดส่ง</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={Quotation_data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Wait_delivery_cuteplus;
