import React from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../../config";
import { useState } from "react";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import { useEffect } from "react";

function Logistic_Car() {
  const [Filter, setFilter] = useState({
    name: "",
    contact: "",
  });
  const [LogisticCar, setLogisticCar] = useState({
    data: [],
  });

  const GetLogisticCar = async () => {
    let temp = {
      contact: Filter.contact.trim() || "",
      name: Filter.name.trim() || "",
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getLogisticData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
        setLogisticCar({ data: response.data });
        LogisticCar.data = response.data;
        //   console.log(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function clear() {
    setFilter({
      ...Filter,
      contact: "",
      name: "",
    });
    Filter.contact = "";
    Filter.name = "";
    await GetLogisticCar();
  }

  function delLogistic(e) {
    // console.log('id :>> ', e.lgt_id);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          loc_id: e.lgt_id,
          oem_id: getOem(),
          company_id: getUser().com,
        };
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/logistic/delLogistic",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: data,
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  useEffect(async () => {
    await GetLogisticCar();
  }, []);

  const rowsData = [];
  for (let index = 0; index < LogisticCar.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["logistics"] = LogisticCar.data[index].lgt_name
      ? LogisticCar.data[index].lgt_name
      : "-";

    rowItem["contact"] = LogisticCar.data[index].lgt_contract_name
      ? LogisticCar.data[index].lgt_contract_name
      : "-";

    rowItem["delivery"] = LogisticCar.data[index].ds_name
      ? LogisticCar.data[index].ds_name
      : "-";

    rowItem["transportation"] = LogisticCar.data[index].tt_name
      ? LogisticCar.data[index].tt_name
      : "-";

    rowItem["vehicle"] = LogisticCar.data[index].vt_name
      ? LogisticCar.data[index].vt_name
      : "-";

    rowItem["management"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={LogisticCar.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus/view/" +
                LogisticCar.data[index].lgt_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <a
            key={LogisticCar.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus/edit/" +
                LogisticCar.data[index].lgt_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.deleteBtn ? "hidden" : "visible" }} */
        >
          <a
            key={LogisticCar.data[index]}
            id={LogisticCar.data[index]}
            className=" btn btn-xs "
            onClick={delLogistic.bind(this, LogisticCar.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const LogisticsCarTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Logistics Name",
        field: "logistics",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Contact Name",
        field: "contact",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Delivery Source",
        field: "delivery",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Transportation Type",
        field: "transportation",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Vehicle Type",
        field: "vehicle",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Logistics Car</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Logistics Car</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 col-xl-2">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={Filter.name}
                  onChange={(e) => {
                    setFilter({
                      ...Filter,
                      name: e.target.value,
                    });
                    Filter.name = e.target.value;
                  }}
                />
                <label>Logistics Name</label>
              </div>
            </div>
            <div className="col-12 col-md-4 col-xl-2">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={Filter.contact}
                  onChange={(e) => {
                    setFilter({
                      ...Filter,
                      contact: e.target.value,
                    });
                    Filter.contact = e.target.value;
                  }}
                />
                <label>Contact Name</label>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  className="btn btn-block btn-info"
                  onClick={async () => {
                    await GetLogisticCar();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="col-6 col-md-4 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  className="btn btn-block btn-info"
                  onClick={() => {
                    clear();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
            {/* </div>
          <div className="row"> */}
            <div className="col-12 col-md-4 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    window.location.href =
                      "/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus/add";
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Logistics Car</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            striped
                            bordered
                            hover
                            data={LogisticsCarTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Logistic_Car;
