import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import Resizer from "react-image-file-resizer";
function Pad_Employee(params) {
  const [Employee, setEmployee] = useState([]);
  const [PadConfig, setPadConfig] = useState([]);
  const [disable, setdisable] = useState(null);
  const [mode, setmode] = useState("");
  const [id, setid] = useState("");
  const [Employee_data, setEmployee_data] = useState({
    person_name: "",
    card_number: "",
    person_code: "",
    face_list: [],
    user_profile_name: "",
  });
  const [filler_employee, setfiller_employee] = useState({
    emp_name_th: "",
    emp_type_id: "",
    pad_id: "",
  });
  const [test, settest] = useState({
    test: ""
  });


  useEffect(() => {
    Get_pad_config();

    Swal.fire({
      title: "กำลังดึงข้อมูล PAD",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }, []);

  async function Get_pad_config(params) {
    await axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement +
        "/config?company_id=" +
        getUser().com +
        "&oem_id=" +
        getOem(),
      headers: {
        "X-TTT": Configs.API_TTT,
        Authorization: "Bearer " + getToken(),
      },
    })
      .then(function (response) {
        console.log("pad", response.data);
        if (response.data.data.length !== 0) {
          setPadConfig(response.data.data);
          setfiller_employee({
            ...filler_employee,
            pad_id: response.data.data[0].id,
          });

          filler_employee.pad_id = response.data.data[0].id;
        }
      })
      .catch(function (error) {
        if (
          error.response.data.data ===
          "ส่ง pad_id มาด้วย รูปแบบ Query (Table : config_pad_connection)"
        ) {
          Swal.fire("warning", "กรุนณารอการเชื่อมต่อ", "กรุณาลองใหม่อีกครั้ง");
        }
        /*  console.log(error);
        Swal.fire("Error", error.response.data.message, "error"); */
      });
      Get_pad_employee();

  }
  async function Get_pad_employee() {
    console.log("pad_id", filler_employee.pad_id);
    const emp_group_list = [];
    const employeeList = [];
    if (filler_employee.pad_id !== "") {
      console.log("getpad");

      setTimeout(async () => {
        Swal.fire({
          title: "กำลังดึงข้อมูล Empgrop",
          text: "Loading",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await axios({
          method: "get",
          url:
            Configs.API_URL_hrMagenatement +
            "/pad/group?pad_id=" +
            filler_employee.pad_id,
          headers: {
            "X-TTT": Configs.API_TTT,
            Authorization: "Bearer " + getToken(),
          },
        })
          .then(function (response) {
            if (response.data.status === "success") {
              console.log("data", response.data.data);
              if (response.data.data.data.length !== 0) {
                response.data.data.data.forEach((element) => {
                  emp_group_list.push(element);
                  console.log(element);
                });
              }
            } else if (response.data.status === "error") {
              Swal.fire(
                "Error",
                "ไม่สามารถเชื่อมต่อ Pad ได้",
                "กรุณาลองใหม่อีกครั้ง emp_group_list"
              );
            }
          })
          .catch(function (error) {
            if (
              error.response.data.data ===
              "ส่ง pad_id มาด้วย รูปแบบ Query (Table : config_pad_connection)"
            ) {
              Swal.fire(
                "warning",
                "กรุนณารอการเชื่อมต่อ",
                "กรุณาลองใหม่อีกครั้ง emp_group_list"
              );
            }
          });
      }, 5000);

      setTimeout(async () => {
        Swal.fire({
          title: "กำลังดึงข้อมูล Emp",
          text: "Loading",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        await axios({
          method: "get",
          url:
            Configs.API_URL_hrMagenatement +
            "/pad/personnel?pad_id=" +
            filler_employee.pad_id,
          headers: {
            "X-TTT": Configs.API_TTT,
            Authorization: "Bearer " + getToken(),
          },
        })
          .then(function (response) {
            const emp_data_pad = [];

            if (response.data.status === "success") {
              if (response.data.data.data.length !== 0) {
                response.data.data.data.forEach((element) => {
                  let data = {};
                  data["recognition_type"] = element.recognition_type;
                  data["id"] = element.id;
                  data["type"] = element.type;
                  data["is_admin"] = element.is_admin;
                  data["person_name"] = element.person_name;
                  data["card_number"] = element.card_number;
                  data["person_code"] = element.person_code;
                  data["id_number"] = element.id_number;

                  const group_list_temp = [];
                  element.group_list.forEach((element2) => {
                    group_list_temp.push(
                      emp_group_list.filter((el_group) => {
                        return el_group.id === element2;
                      })[0]
                    );
                  });

                  data["group_list"] = group_list_temp;
                  data["links"] = element.links;
                  data["feature_list"] = element.feature_list;
                  employeeList.push(data);
                });
              }
              Swal.close();
            } else if (response.data.status === "error") {
              Swal.fire(
                "Error",
                "ไม่สามารถเชื่อมต่อ Pad ได้",
                "กรุณาลองใหม่อีกครั้ง employeeList"
              );
            }
          })
          .catch(function (error) {
            Swal.fire("Error", error.response.data.message, "error");
          });

        setEmployee(employeeList);
        console.log(employeeList);
      }, 10000);
    }
  }

  const rowsData = [];
  for (let index = 0; index < Employee.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["emp_name_th"] = Employee[index].person_name;
    rowItem["emp_no"] = Employee[index].person_code;
    if (Employee[index].group_list.length > 0) {
      rowItem["emp_period_time"] = (
        <>
          {Employee[index].group_list.map((el) => {
            return <p>{el.group_name}</p>;
          })}
        </>
      );
    } else {
      rowItem["emp_period_time"] = "";
    }

    rowItem["image"] = <></>;
    rowItem["mgt"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            onClick={() => {
              setmode("edit");
              setdataEdit(index);
              setid(Employee[index].id);
            }}
            id="btn_addedit_excel"
            data-toggle="modal"
            data-target="#modal-addedit"
            key={Employee[index].id}
            className=" btn btn-xs "
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Employee[index].id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={deldata.bind(this, Employee[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ ~ นามสกุล ",
        field: "emp_name_th",
        sort: "asc",
        width: 50,
      },
      {
        label: "รด/รหัส",
        field: "emp_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภท",
        field: "emp_period_time",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูป",
        field: "image",
        sort: "asc",
        width: 50,
      },

      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 80,
      },
    ],
    rows: rowsData,
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        200,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  async function UploadProfile(e) {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    /*  var file_image = dataURLtoFile(image,file.name); */
    setEmployee_data({
      ...Employee_data,
      face_list: [{ data: image }],
      user_profile_name: file.name,
    });
    /*   Employee_data = file.name ; */
    console.log("image", image);
    /*console.log("file_image",file_image); */
  }
  async function search() {
    if (Employee.length > 0 && filler_employee.emp_name_th !== "") {
      let tempEmp = Employee.filter((el) => {
        return el.person_name.includes(filler_employee.emp_name_th);
      });
      setEmployee(tempEmp);
      settest({...test,test:""})
    } else {
      Swal.fire({
        title: "กำลังดึงข้อมูล",
        text: "Loading",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      Get_pad_employee();
    }
  }
  async function ClearFilter() {
    setfiller_employee({
      emp_name_th: "",
      emp_type_id: "",
    }); /* 
  filler_employee.emp_name_th ="";
  filler_employee.emp_type_id = ""; */

    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    Get_pad_employee({
      emp_name_th: "",
      emp_type_id: "",
      pad_id: "",
    });
  }

  async function save_emp_to_pad(params) {
    /* console.log(Employee_data );
  image.split(',')[1] */

    Swal.fire({
      title: "กำลังบันทึกข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const data = {
      person_name: Employee_data.person_name,
      card_number: Employee_data.card_number,
      person_code: Employee_data.person_code,
      face_list: [
        {
          data:
            Employee_data.face_list.length !== 0
              ? Employee_data.face_list[0].data.split(",")[1]
              : "",
        },
      ],
    };
    /* console.log("Employee_data",data); */
  
      if (mode === "add") {
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/pad/personnel?pad_id=" +
            filler_employee.pad_id,
          headers: {
            "X-TTT": Configs.API_TTT,
            Authorization: "Bearer " + getToken(),
          },
          data: data,
        })
          .then(function (response) {
            console.log("resAdd", response.data);
            Swal.close();
          })
          .catch(function (error) {
            /* console.log(error.response.data); */
            if (error.response.data.error.detail === "No picture.") {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href = "/Human_Resource/ข้อมูลพนักงาน/pad_employee";
              });
            } else if (
              error.response.data.error.detail === "Duplicate card number."
            ) {
              Swal.fire(
                "warning",
                "มีหมายเลขบัตรประชาชนนี้อยู่เเล้ว ",
                "กรุณาลองใหม่อีกครั้ง"
              );
            }
          });
      } else {

        if (id !== "") {
         Swal.fire("warning", "กรุณากรอกข้อมูลให้ครบถ้วน", "กรุณาลองใหม่อีกครั้ง");
        }
        console.log("id",id);

        axios({
          method: "put",
          url:
            Configs.API_URL_hrMagenatement +
            "/pad/personnel/" +
            id +
            "?pad_id=" +
            filler_employee.pad_id,
          headers: {
            "X-TTT": Configs.API_TTT,
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(function (response) {
          
            Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              window.location.href = "/Human_Resource/ข้อมูลพนักงาน/pad_employee";
            });
            Swal.close();
          })
          .catch(function (error) {
            if (error.response.data.error.detail === "No picture.") {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href = "/Human_Resource/ข้อมูลพนักงาน/pad_employee";
              });
            } else if (
              error.response.data.error.detail === "Duplicate card number."
            ) {
              Swal.fire(
                "warning",
                "มีหมายเลขบัตรประชาชนนี้อยู่เเล้ว ",
                "กรุณาลองใหม่อีกครั้ง"
              );
            }
          });
      }
 

    setEmployee_data({
      person_name: "",
      card_number: "",
      person_code: "",
      face_list: [],
      user_profile_name: "",
    });

    setid("");
  }

  async function cancle_emp_to_pad(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        setEmployee_data({
          person_name: "",
          card_number: "",
          person_code: "",
          face_list: [],
          user_profile_name: "",
        });
      }
    });
  }

  async function setdataEdit(index) {
    if (Employee.length > 0) {
      setEmployee_data({
        person_name: Employee[index].person_name,
        card_number: Employee[index].card_number,
        person_code: Employee[index].person_code,
        face_list: [],
        user_profile_name: "",
      });
    }
  }

  async function deldata(id) {
    if (id !== null) {
      Swal.fire({
        title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
        text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อลบข้อมูล",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ตกลง",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "กำลังบันทึกข้อมูล",
            text: "Loading",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          axios({
            method: "delete",
            url:
              Configs.API_URL_hrMagenatement +
              "/pad/personnel/" +
              id +
              "?pad_id=" +
              filler_employee.pad_id,
            headers: {
              "X-TTT": Configs.API_TTT,
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
            },
          })
            .then(function (response) {
              if (response.data.status === "success") {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href = "/Human_Resource/ข้อมูลพนักงาน/pad_employee";
                });
              }
            })
            .catch(function (error) {
              if (
                error.response.data.error.detail ===
                "The request api is not found"
              ) {
                Swal.fire(
                  "warning",
                  "ไม่พบข้อมูลที่จะลบ",
                  "กรุณาลองใหม่อีกครั้ง"
                );
              }
            });
        }
      });
    }
  }
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ข้อมูลพนักงาน (On Pad)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">
                    ข้อมูลพนักงาน (On Pad)
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={filler_employee.emp_name_th}
                    onChange={(e) => {
                      setfiller_employee({
                        ...filler_employee,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                </div>
              </div>
              <div
                className="col-md-2 col-sm-12 col-lg-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_emptype"
                    defaultValue={filler_employee.pad_id}
                    value={filler_employee.pad_id}
                    onChange={(e) => {
                      setfiller_employee({
                        ...filler_employee,
                        pad_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      เลือก Pad
                    </option>
                    {PadConfig.map((el) => {
                      return <option value={el.id}>{el.pad_name}</option>;
                      /* if (el.id === filler_employee.pad_id) {
                        return (
                          <option selected value={el.id}>
                            {el.pad_name}
                          </option>
                        );
                      } else {
                        return <option value={el.id}>{el.pad_name}</option>;
                      } */
                    })}
                  </select>
                  <label htmlFor=""> Pad</label>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      search();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      ClearFilter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      id="btn_add"
                      className="btn btn-block btn-primary"
                      data-toggle="modal"
                      data-target="#modal-addedit"
                      onClick={() => {
                        setmode("add");
                        setEmployee_data({
                          person_name: "",
                          card_number: "",
                          person_code: "",
                          face_list: [],
                          user_profile_name: "",
                        });
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div
                className="table-responsive "
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>

            <div className="modal fade" id="modal-addedit">
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">เพิ่มข้อมูลบุคคล{"(pad)"}</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      //onClick={set_bomlist_excel}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12 ">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="text_emp_no"
                            value={Employee_data.person_name}
                            disabled={disable}
                            onChange={(e) => {
                              setEmployee_data({
                                ...Employee_data,
                                person_name: e.target.value,
                              });
                            }}
                          />
                          <label>
                            ชื่อพนักงาน
                            {Employee_data.person_name === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 ">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="text_nickname"
                            value={Employee_data.card_number}
                            disabled={disable}
                            onChange={(e) => {
                              setEmployee_data({
                                ...Employee_data,
                                card_number: e.target.value,
                              });
                            }}
                          />
                          <label>หมายเลขบัตรประชาชน</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 ">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="text_emp_no"
                            value={Employee_data.person_code}
                            disabled={disable}
                            onChange={(e) => {
                              setEmployee_data({
                                ...Employee_data,
                                person_code: e.target.value,
                              });
                            }}
                          />
                          <label>รหัสพนักงาน</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 ">
                        <div className="none-nolmalinput">
                          <label htmlFor="">
                            Image upload{" "}
                            {Employee_data.user_profile_name === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                            :
                          </label>
                          <small className="reccoment-position">
                            200 x 150 px
                          </small>
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                disabled={disable}
                                id="exampleInputFile"
                                accept="image/*"
                                onChange={UploadProfile}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFile"
                              >
                                {Employee_data.user_profile_name}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-1 "></div>
                      <div className="col-10 ">
                        <label htmlFor="exampleInputFile"> </label>
                        <img
                          id="img"
                          alt="..."
                          className="img-fluid rounded shadow border-0"
                          src={
                            Employee_data.face_list.length !== 0
                              ? Employee_data.face_list[0].data
                              : userdefault_img.imgs
                          }
                          style={{
                            width: "500px",
                            height: "300px",
                            position: "relative",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-between">
                    <button
                      type="button"
                      className="btn btn-success"
                      data-dismiss="modal"
                      onClick={save_emp_to_pad}
                    >
                      บันทึก
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={cancle_emp_to_pad}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
      </div>
    </div>
  );
}

export default Pad_Employee;
