import React, { Component, useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import axios from "axios";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import { useParams } from "react-router";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../assets/css/Input.css";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import NumberFormat from "react-number-format";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function ReportSystem() {
  const [company, setCompany] = useState([]);
  const [point_data, setpoint_data] = useState([]);
  const [user_data, setuser_data] = useState([]);
  const [award_point_data, setaward_point_data] = useState([]);
  const [award_user_data, setaward_user_data] = useState([]);
  const [thank_point_data, setthank_point_data] = useState([]);
  const [thank_user_data, setthank_user_data] = useState([]);
  const [redeem_point_data, setredeem_point_data] = useState([]);
  const [redeem_user_data, setredeem_user_data] = useState([]);
  const [user_data_receive, setuser_data_receive] = useState([]);

  const [ReadyData, setReadyData] = useState(false);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);

        let obtion = [];
        response.data.forEach((element) => {
          let el = { value: element.id, label: element.name };
          /*     el["value"]=element.id ;
            el["label"]=element.name; */
          obtion.push(el);
        });
        setCompany(obtion);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetCompany();
  }, []);

  //   const [date_start_null, setdate_start_null] = useState(false);
  //   const [date_end_null, setdate_end_null] = useState(false);
  const [load_date, setload_date] = useState(false);
  //   const [load_user_date, setload_user_date] = useState(false);
  const [report_data, setreport_data] = useState({
    name: "",
    date_start: null,
    date_end: null,
    file_name: "",
    table: "",
  });

  let data_for_table = [];

  for (let index = 0; index < award_point_data.length; index++) {
    const element = {};
    element["no"] = index + 1;
    element["username"] = award_point_data[index].username;
    element["award_point_name"] = award_point_data[index].ap_point_name;
    element["award_point"] = (
      <NumberFormat
        thousandSeparator={true}
        value={Number(award_point_data[index].ap_point) || 0}
        displayType="text"
      />
    );

    element["firstname_lastname"] = award_point_data[index].firstname_lastname;
    element["email"] = award_point_data[index].email || "";
    element["company"] = award_point_data[index].company;
    element["oem"] = award_point_data[index].oem;
    element["create_date"] = award_point_data[index].create_date;
    element["update_date"] = award_point_data[index].update_date;
    data_for_table.push(element);
  }
  const award_point_history = {
    columns: [
      {
        label: "no.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "emp no",
        field: "username",
        sort: "asc",
        width: 50,
      },
      {
        label: "emp name",
        field: "firstname_lastname",
        sort: "asc",
        width: 50,
      },
      {
        label: "award point name",
        field: "award_point_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "award point",
        field: "award_point",
        sort: "asc",
        width: 50,
      },
      {
        label: "company",
        field: "company",
        sort: "asc",
        width: 10,
      },
      /*  {
        label: "email",
        field: "email",
        sort: "asc",
        width: 10,
      }, */

      {
        label: "oem",
        field: "oem",
        sort: "asc",
        width: 10,
      },
      {
        label: "create_date",
        field: "create_date",
        sort: "asc",
        width: 10,
      },
      {
        label: "update_date",
        field: "update_date",
        sort: "asc",
        width: 10,
      },
      /*  {
        label: "remark",
        field: "remark",
        sort: "asc",
        width: 10,
      }, */
    ],
    rows: data_for_table,
  };
  let thank_point_table = [];
  console.log("thank_point_data", thank_point_data);
  for (let index = 0; index < thank_point_data.length; index++) {
    let element = {};
    element["no"] = index + 1;
    element["give_username"] = thank_point_data[index].Giveusername;
    element["received_username"] = thank_point_data[index].Receiveusername;
    element["give_firstname_lastname"] =
      thank_point_data[index].Givefirstname_lastname;
    element["received_firstname_lastname"] =
      thank_point_data[index].Receivefirstname_lastname;
    element["thank_point_name"] = thank_point_data[index].tp_point_name;
    element["thank_point"] = (
      <NumberFormat
        thousandSeparator={true}
        value={Number(thank_point_data[index].tp_point) || 0}
        displayType="text"
      />
    );
    element["email"] = thank_point_data[index].Giveemail || "";
    element["company"] = thank_point_data[index].company;
    element["oem"] = thank_point_data[index].oem;
    element["create_date"] = thank_point_data[index].create_date;
    element["update_date"] = thank_point_data[index].update_date;
    element["remark"] = thank_point_data[index].tph_remark;
    thank_point_table.push(element);
  }
  const thank_point_history = {
    columns: [
      {
        label: "no.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "give emp no",
        field: "give_username",
        sort: "asc",
        width: 50,
      },
      {
        label: "received emp no",
        field: "received_username",
        sort: "asc",
        width: 50,
      },
      {
        label: "give emp name",
        field: "give_firstname_lastname",
        sort: "asc",
        width: 50,
      },
      {
        label: "received emp name",
        field: "received_firstname_lastname",
        sort: "asc",
        width: 50,
      },
      {
        label: "thank point name",
        field: "thank_point_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "thank point",
        field: "thank_point",
        sort: "asc",
        width: 50,
      },
      {
        label: "email",
        field: "email",
        sort: "asc",
        width: 10,
      },
      {
        label: "company",
        field: "company",
        sort: "asc",
        width: 10,
      },
      {
        label: "oem",
        field: "oem",
        sort: "asc",
        width: 10,
      },
      {
        label: "create_date",
        field: "create_date",
        sort: "asc",
        width: 10,
      },
      {
        label: "update_date",
        field: "update_date",
        sort: "asc",
        width: 10,
      },
      {
        label: "remark",
        field: "remark",
        sort: "asc",
        width: 10,
      },
    ],
    rows: thank_point_table,
  };
  let user_detail_table = [];

  for (let index = 0; index < point_data.length; index++) {
    const element = {};
    element["username"] = point_data[index].emp_no;
    element["point"] = (
      <NumberFormat
        thousandSeparator={true}
        value={Number(point_data[index].ep_point) || 0}
        displayType="text"
      />
    );
    element["firstname_lastname"] = point_data[index].emp_name_th;
    element["email"] = point_data[index].e_mail || "";
    element["company"] = point_data[index].company_name;
    element["oem"] = point_data[index].oem_name;

    user_detail_table.push(element);
  }
  const user_detail = {
    columns: [
      {
        label: "username",
        field: "username",
        sort: "asc",
        width: 50,
      },
      {
        label: "point",
        field: "point",
        sort: "asc",
        width: 50,
      },
      {
        label: "firstname lastname",
        field: "firstname_lastname",
        sort: "asc",
        width: 50,
      },
      {
        label: "email",
        field: "email",
        sort: "asc",
        width: 10,
      },
      {
        label: "company",
        field: "company",
        sort: "asc",
        width: 10,
      },
      {
        label: "oem",
        field: "oem",
        sort: "asc",
        width: 10,
      },
    ],
    rows: user_detail_table,
  };
  let redeem_point_table = [];
  console.log("redeem_point_data aaaaa",redeem_point_data);
  for (let index = 0; index < redeem_point_data.length; index++) {
    const element = {};
    element["no"]  = index+1 ;
    element["username"]  = redeem_point_data[index].username ;
    element["firstname_lastname"] =  redeem_point_data[index].firstname_lastname ;
    element["product_name"] =  redeem_point_data[index].product_name ;
    element["product_model"] =  redeem_point_data[index].pm_model_name ;
    element["email"] =  redeem_point_data[index].email || "" ;
    element["address"] = redeem_point_data[index].ea_address ||"" ;
    element["company"] =  redeem_point_data[index].company ;
    element["oem"]  = redeem_point_data[index].oem ;
    element["create_date"]  = redeem_point_data[index].create_date ;
    element["update_date"] =  redeem_point_data[index].update_date ;

    redeem_point_table.push(element);
  }
  const redeem_point = {
    columns: [
      {
        label: "no.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "emp no",
        field: "username",
        sort: "asc",
        width: 50,
      },
      {
        label: "emp name",
        field: "firstname_lastname",
        sort: "asc",
        width: 50,
      },
      {
        label: "product name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },

      {
        label: "product model",
        field: "product_model",
        sort: "asc",
        width: 10,
      },
      {
        label: "email",
        field: "email",
        sort: "asc",
        width: 10,
      },
      {
        label: "address",
        field: "address",
        sort: "asc",
        width: 10,
      },
      {
        label: "company",
        field: "company",
        sort: "asc",
        width: 10,
      },
      {
        label: "oem",
        field: "oem",
        sort: "asc",
        width: 10,
      },
      {
        label: "create_date",
        field: "create_date",
        sort: "asc",
        width: 10,
      },
      {
        label: "update_date",
        field: "update_date",
        sort: "asc",
        width: 10,
      },
    ],
    rows: redeem_point_table,
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const GetPointDetail = async () => {
    /*  Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }); */

    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/summaryReport/filterUserPoint",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: optionSelected,
    })
      .then(function (response) {
        console.log(response.data.point_detail, "GetPointDetail");
        setpoint_data(response.data.point_detail);
        /* point_data = response.data.point_detail; */
        // setuser_data(response.data.user_detail);
        /*  if (response.data.point_detail.length != 0) {
          var req_employee = [];
          for (let i = 0; i < response.data.point_detail.length; i++) {
            req_employee.push(response.data.point_detail[i].ep_emp_id);
          }
          var employee = req_employee;
          axios({
            method: "post",
            url:
              Configs.API_URL_incentive + "/api/summaryReport/filterUserData",
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: employee,
          })
            .then(function (response) {
              console.log(response.data.user_detail, "GetEmployeeList");
              setuser_data(response.data.user_detail);
              Swal.close();
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Swal.close();
        } */
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  async function ChackfileName() {
    console.log("optionSelected2", optionSelected);
    if (optionSelected != null) {
      if (report_data.table == "user") {
        await GetPointDetail();
        setReadyData(true);
        ReadyData = true;
        console.log("GetPointDetail", ReadyData);
      } else {
        if (report_data.date_start !== null || report_data.date_end !== null) {
          if (report_data.table == "award") {
            await GetAwardPointDetail();
          } else if (report_data.table == "thank") {
            await GetThankPointDetail();
          } else if (report_data.table == "redeem") {
            await GetRedeemPointDetail();
          }
          setReadyData(true);
          ReadyData = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "กรุณาเลือก Start Date และ End Date",
            text: "",
          });
        }
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "กรุณาเลือก Company",
        text: "",
      });
    }
    getExport();
    /* if (report_data.date_start != null && report_data.date_end != null) {
     
    } */
  }

  const GetThankPointDetail = async () => {
    /*   Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }); */
    let tempDataThankPoint = [];
    let tempDataThankPoint_re = [];
    await optionSelected.forEach((element) => {
      var get_filler_thank_point = {
        /*  oem_id: getOem(), */
        company_id: element.value,
        tph_date_from: report_data.date_start,
        tph_date_to: report_data.date_end,
      };
      axios({
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/pointHistory/filterThankPointHistory",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: get_filler_thank_point,
      })
        .then(async function (response) {
          console.log(response.data.point_history_list, "GetPointDetail");
          setthank_point_data(response.data.point_history_list);

          if (response.data.point_history_list.length != 0) {
            var req_employeeGive = [];
            var req_employeeReceive = [];
            for (let i = 0; i < response.data.point_history_list.length; i++) {
              req_employeeGive.push(
                response.data.point_history_list[i].user_give
              );
              req_employeeReceive.push(
                response.data.point_history_list[i].user_receive
              );
            }
            var employeeGive = req_employeeGive;
            var employeeReceive = req_employeeReceive;
            await axios({
              method: "post",
              url:
                Configs.API_URL_incentive + "/api/summaryReport/filterUserData",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: employeeGive,
            })
              .then(function (response2) {
                /*   console.log(response.data.user_detail, "GetEmployeeList");
                setthank_user_data(response.data.user_detail); */

                for (
                  let index = 0;
                  index < response.data.point_history_list.length;
                  index++
                ) {
                  let element = response.data.point_history_list[index];
                  if (response2.data.user_detail[index] != null) {
                    element["Giveusername"] =
                      response2.data.user_detail[index].emp_no;
                    element["Givefirstname_lastname"] =
                      response2.data.user_detail[index].emp_name_th;
                    element["Giveemail"] =
                      response2.data.user_detail[index].e_mail;
                    element["company"] =
                      response2.data.user_detail[index].company_name;
                    element["oem"] = response2.data.user_detail[index].oem_name;
                    // console.log("el",response2.data.user_detail[index]);
                    tempDataThankPoint.push(element);
                  }
                }
              })
              .catch(function (error) {
                console.log(error);
              });
            await axios({
              method: "post",
              url:
                Configs.API_URL_incentive + "/api/summaryReport/filterUserData",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: employeeReceive,
            })
              .then(function (response2) {
                /*   console.log(response.data.user_detail, "GetEmployeeList");
                  setthank_user_data(response.data.user_detail); */

                for (
                  let index = 0;
                  index < tempDataThankPoint.length;
                  index++
                ) {
                  let element = tempDataThankPoint[index];
                  if (response2.data.user_detail[index] != null) {
                    element["Receiveusername"] =
                      response2.data.user_detail[index].emp_no;
                    element["Receivefirstname_lastname"] =
                      response2.data.user_detail[index].emp_name_th;
                    element["Receiveemail"] =
                      response2.data.user_detail[index].e_mail;

                    tempDataThankPoint_re.push(element);
                  }
                }
              })
              .then(function (response3) {
                setthank_point_data(tempDataThankPoint_re);
                /*  thank_point_data = tempDataThankPoint_re; */
                console.log("tempDataThankPoint_re", tempDataThankPoint_re);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  const GetRedeemPointDetail = async () => {
    /*   Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }); */

    let tempDataReedeem = [];
    await optionSelected.forEach(async (elementObt) => {
      var get_filler_redeem_point = {
        /*     oem_id: getOem(), */
        company_id: elementObt.value,
        date_start: report_data.date_start,
        date_end: report_data.date_end,
      };
      await axios({
        method: "post",
        url: Configs.API_URL_incentive + "/api/summaryReport/filterRedeemData",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: get_filler_redeem_point,
      })
        .then(async function (response) {
           console.log(response.data.redeem_detail, "GetRedeemDetail");
       /*  setredeem_point_data(response.data.redeem_detail); */
          if (response.data.redeem_detail.length != 0) {
            var req_employee = [];
            for (let i = 0; i < response.data.redeem_detail.length; i++) {
              req_employee.push(response.data.redeem_detail[i].ol_emp_id);
            }
            var employee = req_employee;
            await axios({
              method: "post",
              url:
                Configs.API_URL_incentive + "/api/summaryReport/filterUserData",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: employee,
            })
              .then(function (response2) {

                console.log(response2.data,"response2.data");
                for (
                  let index = 0;
                  index < response.data.redeem_detail.length;
                  index++
                ) {
                  let element = response.data.redeem_detail[index];

                  if (response2.data.user_detail[index] != null) {
                    element["username"] =
                      response2.data.user_detail[index].emp_no;
                    element["firstname_lastname"] =
                      response2.data.user_detail[index].emp_name_th;
                    element["email"] = response2.data.user_detail[index].e_mail;
                    element["company"] =
                      response2.data.user_detail[index].company_name;
                    element["oem"] = response2.data.user_detail[index].oem_name;
                    // console.log("el",response2.data.user_detail[index]);
                    tempDataReedeem.push(element);
                  }
                }
              })
              .finally(function (){
                console.log("tempDataReedeem final",tempDataReedeem);
                setredeem_point_data(tempDataReedeem);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });

    /* if (tempDataReedeem.length > 0) {
      setredeem_point_data(tempDataReedeem);
      redeem_point_data = tempDataReedeem;
      console.log("tempDataReedeem", tempDataReedeem);
    } */
  };

  const GetAwardPointDetail = async () => {
    /*   Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    }); */
    let tempDataAwardPoint = [];
    let tempDataUser = [];
    await optionSelected.forEach(async (elementObt) => {
      var get_filler_award_point = {
        /*  oem_id: getOem(), */
        company_id: elementObt.value,
        ap_point_id: "",
        ap_point_name: "",
        gp_date_from: report_data.date_start,
        gp_date_to: report_data.date_end,
      };
      await axios({
        method: "post",
        url:
          Configs.API_URL_incentive +
          "/api/pointHistory/filterAwardPointHistory",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: get_filler_award_point,
      })
        .then(async function (response) {
          /*   tempDataAwardPoint = response.data.point_history_list; */
          console.log(response.data.point_history_list, "GetPointDetail");
          /*  await  response.data.point_history_list.forEach( async element2 => {
            tempDataAwardPoint.push(element2);
          }); */
          /*  console.log(response.data.point_history_list, "GetPointDetail");
          setaward_point_data(response.data.point_history_list); */
          if (response.data.point_history_list.length != 0) {
            var req_employee = [];
            for (let i = 0; i < response.data.point_history_list.length; i++) {
              req_employee.push(response.data.point_history_list[i].gp_emp_id);
            }
            var employee = req_employee;
            await axios({
              method: "post",
              url:
                Configs.API_URL_incentive + "/api/summaryReport/filterUserData",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: employee,
            })
              .then(function (response2) {
                console.log(response2.data.user_detail, "GetEmployeeList");
                console.log(response.data.point_history_list.length);
                /* setaward_user_data(response.data.user_detail);
                Swal.close(); */
                for (
                  let index = 0;
                  index < response.data.point_history_list.length;
                  index++
                ) {
                  let element = response.data.point_history_list[index];
                  if (response2.data.user_detail[index] != null) {
                    element["username"] =
                      response2.data.user_detail[index].emp_no;
                    element["firstname_lastname"] =
                      response2.data.user_detail[index].emp_name_th;
                    element["email"] = response2.data.user_detail[index].e_mail;
                    element["company"] =
                      response2.data.user_detail[index].company_name;
                    element["oem"] = response2.data.user_detail[index].oem_name;
                    // console.log("el",response2.data.user_detail[index]);
                    tempDataAwardPoint.push(element);
                  }
                }
              })
              .then(function (response3) {
                setaward_point_data(tempDataAwardPoint);

                console.log("tempDataAwardPoint", tempDataAwardPoint);
                /* console.log("tempDataUser",tempDataUser); */
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  const [optionSelected, setOptionSelected] = useState(null);

  const handleChangeSelect = async (selected) => {
    console.log("selected", selected);
    setOptionSelected(selected);
    optionSelected = selected;
  };

  const getExport = () => {
    console.log(report_data.file_name);
    if (report_data.file_name === "award_point_history_") {
      return (
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-block btn-success"
          table="award_point_history"
          filename={
            report_data.file_name + moment(new Date()).format("DD_MM_yyyy")
          }
          sheet="tablexls"
          buttonText="Excel award"
        />
      );
    } else if (report_data.file_name === "thank_point_history_") {
      return (
        <ReactHTMLTableToExcel
          id="thank_point_history"
          className="btn btn-block btn-success"
          table="thank_point_history"
          filename={
            report_data.file_name + moment(new Date()).format("DD_MM_yyyy")
          }
          sheet="tablexls"
          buttonText="Excel thank"
        />
      );
    } else if (report_data.file_name === "redeem_point_") {
      return (
        <ReactHTMLTableToExcel
          id="redeem_point"
          className="btn btn-block btn-success"
          table="redeem_point"
          filename={
            report_data.file_name + moment(new Date()).format("DD_MM_yyyy")
          }
          sheet="tablexls"
          buttonText="Excel redeem"
        />
      );
    } else if (report_data.file_name === "user_detail_") {
      return (
        <ReactHTMLTableToExcel
          id="user_detail"
          className="btn btn-block btn-success"
          table="user_detail"
          filename={
            report_data.file_name + moment(new Date()).format("DD_MM_yyyy")
          }
          sheet="tablexls"
          buttonText="Excel user"
        />
      );
    } else {
      return (
        <button
          type="button"
          id="Excel"
          className="btn btn-block btn-primary"
          onClick={(e) => {
            ChackfileName();
          }}
        >
          Load Data
        </button>
      );
    }
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Report System</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Report System</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Report System</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      {" "}
                      <div className="form-group">
                        <button
                          type="button"
                          id="btn_clear"
                          className="btn btn-block btn-primary inputButton"
                          data-toggle="modal"
                          data-target="#modal-export"
                          data-backdrop="static"
                          data-keyboard="false"
                          onClick={(e) => {
                            setreport_data({
                              ...report_data,
                              name: "User detail point",
                              table: "user",
                              file_name: "user_detail_",
                            });
                            setReadyData(false);
                          }}
                        >
                          User detail point (.xlsx)
                        </button>
                        {/* 
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-block btn-primary inputButton"
                          table={"user_detail"}
                          filename={
                            "user_detail_" +
                            moment(new Date()).format("DDMMyyyy")
                          }
                          sheet="tablexls"
                          buttonText="User detail point (.xlsx)"
                        /> */}
                      </div>
                    </div>
                    <div className="col-4">
                      {" "}
                      <div className="form-group">
                        <button
                          type="button"
                          id="btn_clear"
                          className="btn btn-block btn-primary inputButton"
                          data-toggle="modal"
                          data-target="#modal-export"
                          data-backdrop="static"
                          data-keyboard="false"
                          onClick={(e) => {
                            setreport_data({
                              ...report_data,
                              name: "Award Point History",
                              table: "award",
                              file_name: "award_point_history_",
                            });
                            setReadyData(false);
                          }}
                        >
                          Award Point History (.xlsx)
                        </button>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <button
                          type="button"
                          id="btn_clear"
                          className="btn btn-block btn-primary inputButton"
                          data-toggle="modal"
                          data-target="#modal-export"
                          data-backdrop="static"
                          data-keyboard="false"
                          onClick={(e) => {
                            setreport_data({
                              ...report_data,
                              name: "Thank Point History",
                              table: "thank",
                              file_name: "thank_point_history_",
                            });
                            setReadyData(false);
                          }}
                        >
                          Thank Point History (.xlsx)
                        </button>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <button
                          type="button"
                          id="btn_clear"
                          className="btn btn-block btn-primary inputButton"
                          data-toggle="modal"
                          data-target="#modal-export"
                          data-backdrop="static"
                          data-keyboard="false"
                          onClick={(e) => {
                            setreport_data({
                              ...report_data,
                              name: "Redeem Point",
                              table: "redeem",
                              file_name: "redeem_point_",
                            });
                            setReadyData(false);
                          }}
                        >
                          Redeem Point (.xlsx)
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card m-lg-0" style={{ minHeight: "200px" }}>
                <div
                  className="card-body table-responsive "
                  style={{ whiteSpace: "nowrap", display: "none" }}
                >
                  <MDBDataTable
                    sortable={false}
                    id="award_point_history"
                    className="table table-head-fixed"
                    striped
                    bordered
                    entries={4096}
                    hover
                    fixedHeader
                    data={award_point_history}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="card" style={{ height: "100%" }}>
            
                <div
                  className="card-body table-responsive "
                  style={{ whiteSpace: "nowrap" , display: "none"}}
                >
                  <MDBDataTable
                    sortable={false}
                    id="redeem_point"
                    className="table table-head-fixed"
                    striped
                    bordered
                    hover
                    entries={4096}
                    fixedHeader
                    data={redeem_point}
                  />
        
              </div>
                <div
                  className="card-body table-responsive "
                  style={{ whiteSpace: "nowrap", display: "none" }}
                >
                  <MDBDataTable
                    sortable={false}
                    id="thank_point_history"
                    className="table table-head-fixed"
                    striped
                    bordered
                    entries={4096}
                    hover
                    fixedHeader
                    data={thank_point_history}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
           
              <div className="card" style={{ height: "100%", display: "none" }}>
                <div
                  className="card-body table-responsive "
                  style={{ whiteSpace: "nowrap" }}
                >
                  <MDBDataTable
                    sortable={false}
                    id="user_detail"
                    className="table table-head-fixed"
                    striped
                    entries={4096}
                    bordered
                    hover
                    fixedHeader
                    data={user_detail}
                  />
                </div>
              </div>
            </div>
           
          </div>
        </section>
      </div>
      {/*  <Footter/> */}

      <div class="modal fade show" id="modal-export">
        <div class="modal-dialog modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Report : {report_data.name}</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={async () => {
                  setreport_data({
                    ...report_data,
                    name: "",
                    date_start: null,
                    date_end: null,
                    file_name: "",
                    table: "",
                    data_table_by: [],
                  });
                  report_data.name = "";
                  report_data.date_start = null;
                  report_data.date_end = null;
                  report_data.file_name = "";
                  report_data.table = "";
                  setload_date(false);
                  /* GetPointDetail(); */
                  setOptionSelected(null);
                  optionSelected = null;
                  setReadyData(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              {report_data.name !== "User detail point" ? (
                <>
                  <div className="row">
                    <div className="col-12 mt-2 w-100">
                      <label>
                        Start Date <span style={{ color: "red" }}> *</span>
                      </label>
                      <div className="w-100">
                        <DatePicker
                          style={{ "z-index": "99", width: "100%" }}
                          selected={report_data.date_start}
                          id="end_date"
                          dateFormat={"dd-MM-yyyy"}
                          onChange={async (date) => {
                            if (
                              report_data.date_end != "" &&
                              date > report_data.date_end
                            ) {
                              setreport_data({
                                ...report_data,
                                date_end: null,
                              });
                              report_data.date_end = null;
                            }

                            setreport_data({
                              ...report_data,
                              date_start: date,
                            });
                            report_data.date_start = date;
                            /* ChackfileName(); */
                          }}
                          minDate={""}
                          showYearDropdown
                          showMonthDropdown
                          customInput={<CustomInput />}
                        ></DatePicker>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 mt-2 w-100">
                      <label>
                        End Date <span style={{ color: "red" }}> *</span>
                      </label>
                      <div className="w-100">
                        <DatePicker
                          style={{ "z-index": "99" }}
                          selected={report_data.date_end}
                          id="end_date"
                          dateFormat={"dd-MM-yyyy"}
                          onChange={async (date) => {
                            setreport_data({
                              ...report_data,
                              date_end: date,
                            });
                            report_data.date_end = date;
                            /*  ChackfileName(); */
                          }}
                          minDate={report_data.date_start}
                          showYearDropdown
                          showMonthDropdown
                          customInput={<CustomInput />}
                        ></DatePicker>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col-12 mt-2 w-100">
                  <label>Company</label>

                  <ReactSelect
                    options={company}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    onChange={handleChangeSelect}
                    allowSelectAll={true}
                    value={optionSelected}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
           
              <div style={{ display: "none" }}>
                {/*  <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-block btn-success"
                  table={report_data.table}
                  filename={report_data.file_name + moment(new Date()).format('DD_MM_yyyy')}
                  sheet="tablexls"
                  buttonText="Excel"
                /> */}
                {/* <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-block btn-success"
                  table={report_data.table}
                  filename={
                    report_data.file_name +
                    moment(new Date()).format("DD_MM_yyyy")
                  }
                  sheet="tablexls"
                  buttonText="Excel"
                /> */}
                {/* <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-block btn-success"
                  table={report_data.table}
                  filename={
                    report_data.file_name +
                    moment(new Date()).format("DD_MM_yyyy")
                  }
                  sheet="tablexls"
                  buttonText="Excel"
                /> */}
                {/* <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-block btn-success"
                  table={report_data.table}
                  filename={
                    report_data.file_name +
                    moment(new Date()).format("DD_MM_yyyy")
                  }
                  sheet="tablexls"
                  buttonText="Excel"
                /> */}
              </div>
              <div className="col-md-4">
                {}

                {ReadyData ? (
                  getExport()
                ) : (
                  <button
                    type="button"
                    id="Excel"
                    className="btn btn-block btn-primary"
                    onClick={(e) => {
                      ChackfileName();
                    }}
                  >
                    Load Data
                  </button>
                )}

                {/* { ReadyData &&
                report_data.file_name === "thank_point_history_" ? (
                  <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-block btn-success"
                  table="thank_point_history"
                  filename={
                    report_data.file_name +
                    moment(new Date()).format("DD_MM_yyyy")
                  }
                  sheet="tablexls"
                  buttonText="Excel thank"
                />
                ) : (
                  <button
                    type="button"
                    id="Excel"
                    className="btn btn-block btn-primary"
                    onClick={(e) => {
                      ChackfileName();
                    }}
                  >
                    Load Data
                  </button>
                )}  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportSystem;
