import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../../component/Footter";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import { MDBDataTable } from "mdbreact";
import QRCode from 'qrcode.react';
import "../../../assets/css/Content.css";
import "../../../assets/css/Input.css";
import { userdefault_img, logoTTT } from "../../../routes/imgRoute/imgUrl";
import "../../../assets/css/table.css";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getComConfig, getFeature, getAcademy} from "../../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment";
import sortJsonArray from 'sort-json-array';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import INF from "../../../component/input_feature";
import LOGH from "../../../component/log_detailHistory_feature";
import INDF from "../../../component/input_date_feature";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
function FG_STPInOut(params) {
  const [product_data, setProduct_data] = useState([]);
  const [product_model_option, setproduct_model_option] = useState([]);
  const [loop_option, setloop_option] = useState([]);
  const [warehouse_option, setwarehouse_option] = useState([]);
  const [rack_option, setrack_option] = useState([]);
  const [product_on_rack_option, setproduct_on_rack_option] = useState([]);
  const [load_status_option, setload_status_option] = useState([]);
  const [project_option, setproject_option] = useState([]);
  const [color_option, setcolor_option] = useState([]);
  const [feature, setfeature] = useState([]);
  const [model, setModel] = useState([]);
  const [oem_id, setOem_id] = useState(getOem());
  const [historyFG, setHistoryFG] = useState([]);
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),

  });
  const [lot_out_list,setlot_out_list] = useState([]);
  const [MDBT,setMDBT] =useState(true);
  const [filler_checkbox, setfiller_checkbox] = useState({
    inventory: false,
    virtual_product: false,
    under_min_lot: false,

  });



  const [search_cus_end,setsearch_cus_end] = useState({
    result_cus:"",
    result_end:""
  });

  const [oemfix, setoemfix] = useState();
  //const [checked, setChecked] = useState();
  const [is_fifo, setis_fifo] = useState(true);
  const [color, setcolor] = useState([]);
  var isChecked2 = true;
  const [filter_loop, setFilter_loop] = useState({
    loop_no: "",
    company_id: getUser().com,
  });
  const [loop, setLoop] = useState([]);
  const [filter_warehouse, setFilter_warehouse] = useState({
    id: "",
    code_no: "",
    company_id: getUser().com,
    parent_id: "",
    type: "Warehouse",

  });
  const [warehouse, setWarehouse] = useState([]);
  const [rack, setRack] = useState([]);
  const [product_on_rack, setProduct_on_rack] = useState([]);
  const [load_status, setLoad_status] = useState([]);
  const [warehouse_data, setwarehouse_data] = useState({
    loop_id: "",
    warehouse_id: "",
    rack_id: "",
    product_on_rack_id: "",
    load_status_id: "",
    remark: "",
    description: "",

  });
  const [log_detailFG, setlog_detailFG] = useState({
    id: "",
    product_id: "",
    user_id: "",
    edit_type: "",
    amount: "",
    create_date: "",
    project_id: "",
    fg_lot_no: "",
    exprire_date: "",

  });
  const [project, setProject] = useState([]);
  const [type_select, settype_select] = useState({
    status: "plant_item",
  });

  const [filler_project, setFiller_project] = useState({
    project_name: "",
    company_id: getUser().com,
    oem_id:getOem(),

  });
  const [calender_data, setcalender_data] = useState({
    exp: "",
    now_date: new Date(),
  });

  const [summary_report, setSummary_report] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [customer_option, setcustomer_option] = useState([]);
  const [End_user_option, setEnd_user_option] = useState([]);
 
  const [filler_summary_report, setFiller_summary_report] = useState({
    product_name: "",
    oem_id: getOem(),
  });
  const [end_user, setEnd_user] = useState([]);

  const [Lot_wip_full, setLot_wip_full] = useState([]);
  const [Lot_wip, setLot_wip] = useState([]);
  const [Lot_wip_list,setLot_wip_list] = useState([]);

  const GetEnd_user = async () => {
    
    let temp = {
      oem_id: getOem(),
    } 
    axios({
      method: "post",
      url: Configs.API_URL + "/end_user/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        //console.log(response.data);
        setEnd_user(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value1"] = el.id;
          element["value2"] = el.fullname;
          element["label"] = el.name;
          options.push(element);
        });
        setEnd_user_option(options);

     
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  var config_color = {
    method: "get",
    url: Configs.API_URL + "/product/getProductColor?id=" + getOem(),
    headers: {
      "X-TTT": Configs.API_TTT,
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
  };

  const GetCustomer = async () => {
    var filter_customer = {
      name: "",
      relate_product: "",
      company_id: getUser().com,
      companyGroup_id: ""
    }
    //console.log(filler_customer);
    axios({
      method: "post",
      url: Configs.API_URL + "/customer/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_customer
    })
      .then(function (response) {
        //console.log(response.data);
        setCustomer(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value1"] = el.id;
          element["value2"] = el.full_name;
          element["label"] = el.name;
          options.push(element);
        });
        setcustomer_option(options);

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getcolor = async () => {
    await axios(config_color)
      .then(function (response) {
        setcolor(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setcolor_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {

    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
    }
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return (element.menu === "F/G IN/OUT");
    })
    var feature_data1 = JSON.parse(getFeature()).filter((element) => {
      return (element.feature === "Plant Item view");
    })
    var storage_feature = [];
    for (var i = 0; i < feature_data.length; i++) {
      storage_feature.push(feature_data[i]);
    }
    for (var k = 0; k < feature_data1.length; k++) {
      storage_feature.push(feature_data1[k]);
    }
    setfeature(storage_feature);

    //console.log(feature)
    ////////permission feature//////////  {feature.map((e) =>{
    feature_data.map((e) => {
      if (e.feature === "FG IN/OUT Lot No") {
        // setamount({ ...amount, lot_no: "FG" + moment(new Date()).format("YYYYMMDD") });
      }
      if (e.feature === "FG IN/OUT EXP") {
        setcalender_data({ ...calender_data, exp: new Date() });
      }
      if (e.feature === "FG IN/OUT Color (OUT)") {
        Getcolor();
      }

      if (e.feature === "FG IN/OUT EXP") {
        sethave_exp({ ...have_exp, have: true, name: "Exprire Date" });
        have_exp.have = true;
        have_exp.name = "Exprire Date";
      }

    })

    const script = document.createElement("script");

    script.src = `js/Product`;
    document.body.appendChild(script);
  }, []);


  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const Getamount = async () => {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if(result.isConfirmed){
        
        if (
          amount.amount !== null &&
          amount.amount !== undefined &&
          amount.amount !== "" &&
          amount.id !== null &&
          Number(amount.amount.replace(/,/g, "")) > 0
        ) {
          if (amount.edit_type !== null) {
          /*   if(Lot_wip_list.length === 0 && amount.edit_type === true) {
              Swal.fire("check input", "โปรดเลือก lot Deflux & Wip" , "warning");
            }else{ */

            
            if (((amount.status_sst === "customer" && amount.customer === "") || (amount.status_sst === "end_user" && amount.end_user === "")) && amount.edit_type === true  ) {
             if(amount.customer === "" && amount.status_sst === "customer"){
              Swal.fire("check input", "โปรดกรอก [No. Customer] ให้ถูกต้อง" , "warning");
              
             }else if(amount.end_user === "" && amount.status_sst === "end_user" ){
              Swal.fire("check input", "โปรดกรอก [No. End user] ให้ถูกต้อง" , "warning");
             }
              
            } else {
               
              var temp_amount;
              axios({
                method: "get",
                url: Configs.API_URL + "/FG_STP_InOut/genLotNo_FG?oem_id=" + getOem(),
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
    
              })
                .then(function (response) {
    
    
    
    
    
    
    
    
                  setamount({ ...amount, lot_no: response.data });
                  amount.lot_no = response.data;
    
                  if (amount.edit_type === true) {
                    temp_amount = {
                      id: amount.id,
                      edit_type: amount.edit_type,
                      amount: Number(amount.amount.replace(/,/g, "")),
                      wip_lot:Lot_wip_list,
                      ref_lot_no:amount.ref_lot,
                      lot_no: amount.lot_no,
                      lot_no_select:amount.lot_no_select,
                      exp_date: calender_data.exp,
                      now_date: calender_data.now_date,
                      outLot_wip:lot_out_list,
                      status_sst: amount.status_sst,
                      customer: amount.customer,
                      end_user: amount.end_user,
                      stp_machine: amount.stp_machine,
                    
                    };
    
                  } else {
                    //const test = Load_status;
    
                    temp_amount = {
                      id: amount.id,
                      edit_type: amount.edit_type,
                      lot_no: amount.lot_no,
                      lot_no_select: amount.lot_no_select,
                      amount: Number(amount.amount.replace(/,/g, "")),
                      project_id: amount.project_id,
                      remark: amount.remark || null,
                      color: amount.color || null,
                      warehouse: warehouse_data,
                    };
                  }
    
    
                  //console.log(temp_amount);
    
                
    
                 
    
                    if (temp_amount.amount > 0) {
                      var url_api = Configs.API_URL + "/FG_STP_InOut/amountFGInOut";
                      if (is_fifo === true) {
                        //FIFO ปกติ
                        url_api = Configs.API_URL + "/FG_STP_InOut/amountFGInOut";
                      } else if (is_fifo === false) {
                        //NON-FIFO แบบเลือกตัด
                        url_api = Configs.API_URL + "/FG_STP_InOut/amountFGInOut_NoFIFO";
                      }
                      axios({
                        method: "post",
                        url: url_api,
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                        data: temp_amount,
                      })
                        .then(async (response) => {
                          Swal.fire({
                            title: "Saving",
                            allowEscapeKey: false,
                            allowOutsideClick: false,
                            timer: 300,
                            onOpen: () => {
                              Swal.showLoading();
                            },
                          }).then((result) => {
                            Swal.fire({
                              icon: "success",
                              title: "Save",
                              showConfirmButton: false,
                              timer: 1500,
                            }).then((result) => {
                         
                                GetAllLotWIPSTP();
                           // setMultiLot_outBound([]);
                                genLotNo();
                                console.log(lot_out_list);
                                setlot_out_list([]);
                                setLot_wip_list([]);
                                console.log(lot_out_list);
                                getHistoryFG(amount.id);
                                GetSummaryReport();
                                let temp = {
                                  id: amount.id,
                                  no: "",
                                  name: "",
                                  product_model_id: "",
                                  oem_id: oem_id,
                                  //check:isChecked, 
                                }
                                   //clear radio----------------------------------------
                      var radioButtonArray = document.getElementsByName('check_lot_fg');
                      //console.log(radioButtonArray)
                      for (var i=0; i<radioButtonArray.length; i++)
                      {
                       var radioButton = radioButtonArray[i];
                       radioButton.checked = false;
                      }
                      //clear radio------------------------------------------
                                axios({
                                  method: "get",
                                  url: Configs.API_URL + "/FG_STP_InOut/getlog_detailFG?id=" + temp.id,
                                  headers: {
                                    Authorization: getToken(),
                                    "X-TTT": Configs.API_TTT,
                                    "Content-Type": "application/json",
                                  },
                                })
                                  .then(function (response) {
    
    
                                    setlog_detailFG(response.data);
                                  })
                                  .catch(function (error) {
                                    console.log(error);
                                  });
                                axios({
                                  method: "post",
                                  url: Configs.API_URL + "/product/filter",
                                  headers: {
                                    Authorization: getToken(),
                                    "X-TTT": Configs.API_TTT,
                                    "Content-Type": "application/json",
                                  },
                                  data: temp,
                                })
                                  .then(async (response) => {
    
                                    let set_total_view = response.data.filter((element) => {
                                      return element.id === amount.id;
                                    })
    
                                    //console.log(set_total_view)
                                    console.log(set_total_view[set_total_view.length - 1].fg_amount);
                                    setamount({
                                      ...amount,
                                      total: set_total_view[set_total_view.length - 1].fg_amount,
                                    
                                
                                      amount:"",
                                  
                                      ref_lot: "",
                                      lot_no_select:"",
                                      wip_lot: [],
                                      remark: "",
                                      project_id: "",
                                      status_sst: "",
                                      customer: "",
                                      end_user: "",
                                      stp_machine:"",
                                    });
                                    amount.total = set_total_view[set_total_view.length - 1].fg_amount;
                                    amount.amount = "";
                                    amount.project_id = "";
                                    amount.status_sst = "";
                                    amount.customer = "";
                                    amount.ref_lot= "";
                                    amount.lot_no_select= "";
                                    amount.remark = "";
                                    amount.end_user = "";
                                    amount.wip_lot = [];
                                    amount.stp_machine = "";
                                    selectProject_model.current.select.clearValue();
                             
                                  setLot_wip_list([]);
                               
                                  })
    
                                  .catch(async (error) => {
                                    console.log(error);
                                  });
                              
                            }).then(() => {
                              GetProduct();
                              GetSummaryReport();
                            });
                          });
                        })
                        .catch(async (error) => {
    
                          Swal.fire("Over Stock!", "Check your amount", "warning");
    
                          console.log(error);
                        });
                    } else {
                      Swal.fire("check amount", "amount can't negative", "warning");
                    }
                  
    
                }).catch(function (e) {
                  console.log(e);
                })
    
            }
         // }
          } else {
            Swal.fire("check in bound , out bound", "bound can't null", "warning");
          }
        } else {
          Swal.fire("check amount", "amount can't null", "warning");
        }
      }
      
    });
   
  };

/*   const Getlog_detailFG = async () => {
    axios(config_model)
      .then(async (response) => {

        setlog_detailFG(response.data);

      })
      .catch(async (error) => {
        console.log(error);
      });
  }; */
  function compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  const Getmodel = async () => {
    axios(config_model)
      .then(async (response) => {
        setModel(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        //console.log(options);
        options.sort(compare);
        //console.log(options);
        setproduct_model_option(options);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const GetAllLotWIPSTP = async () => {

    let temp = {
      oem_id: getOem(),
    }
    await axios({
      method: "post",
      url: Configs.API_URL + "/FG_STP_InOut/getAllLotWIP",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        /*   await setmaterail(response.data); */
        setLot_wip_full(response.data);
        const options = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.deflux_lot_no;
          options.push(element);
        });
        setLot_wip(options);
       // setLot_rawmat(response.data);
      /*   let sorted = response.data.sort((a, b) => b.amount - a.amount);
        await setdefluxData(sorted); */

        console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  

  const GetProduct= async () => {
    let oem_fix = "";
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
      oem_fix = "thanasub";
    }
    let temp = {
      no: filler_product.no,
      name: filler_product.name,
      product_model_id: filler_product.product_model_id,
      oem_id: oem_id,
      inventory: filler_checkbox.inventory,
      virtual_product: filler_checkbox.virtual_product,
      under_min_lot: filler_checkbox.under_min_lot,
      oem_fix: oem_fix,
    }



    await axios({
      method: "post",
      url: Configs.API_URL + "/FG_STP_InOut/filterProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
       // console.log(response.data);

        response.data.sort((a, b) => b.fg_amount - a.fg_amount);

         setProduct_data(response.data);
         setMDBT(true);
        //console.log(sorted);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  

  

  

  const GetSummaryReport = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/FG_STP_InOut/summaryReport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_summary_report
    })
      .then(function (response) {
        //console.log(response.data);
        setSummary_report(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  };



  async function form(no, name, id, amount_total, lot_no, edit_type, exprire_date,unit) {
    setLot_wip_list([]);
    setis_fifo(true);
    amount.amount = "";
    amount.status_sst = "";
    amount.customer = "";
    amount.end_user = "";
    setmessage({ ...message, name: name, no: no });
    setcalender_data({...calender_data,exp: new Date()});

    calender_data.exp =new Date();
    setamount({ ...amount, id: id, amount: "", total: amount_total || 0, lot_no: lot_no, edit_type: true, exprire_date: new Date() , unit: unit});
    amount.edit_type = true;
    genLotNo();
    getHistoryFG(id);
    amount.id = id;
    amount.total = amount_total;
    amount.unit = unit;
    setlog_detailFG({ ...log_detailFG });
    setwarehouse_data({
      ...warehouse_data, loop_id: "",
      warehouse_id: "",
      rack_id: "",
      product_on_rack_id: "",
      load_status_id: "",
      remark: "",
      description: "",
    });
    await axios({
      method: "get",
      url: Configs.API_URL + "/FG_STP_InOut/getlog_detailFG?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setlog_detailFG(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    document.getElementById("amount_in").focus();
  }

  const [amount, setamount] = useState({
    id: "",
    edit_type: true,
    amount: "",
    total: "",
    ref_lot:"",
    lot_no: "",
    lot_no_select: "",
    remark: "",
    color: "",
    status_sst: "",
    customer: "",
    end_user: "",
    stp_machine: "",
    //lot_no: "FG" + moment(calender_data.now_date).format('YYYYMMDD'),

  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });
  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product/add";
  }
  function handleChange(value) {
    GetProduct();
  }

  const selectProduct_model = useRef();
  const selectLoop_model = useRef();
  const selectWarehouse_model = useRef();
  const selectRack_model = useRef();
  const selectProduct_on_rack_model = useRef();
  const selectLoad_status_model = useRef();
  const selectProject_model = useRef();
  const selectColor_model = useRef();
  const selectCustomer = useRef();
  const clearfillter = async () => {
    
    setlog_detailFG({ ...log_detailFG });
    await axios({
      method: "get",
      url: Configs.API_URL + "/FG_STP_InOut/getlog_detailFG?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);

        setlog_detailFG(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
      setamount({ ...amount, customer: "",end_user:"" });
     
    if (amount.edit_type == true) {
      genLotNo();

    } else {
      setamount({ ...amount, lot_no: "" });
    }
    await setFiller_product({ ...filler_product, product_model_id: "", name: "", no: "", });
    filler_product.product_model_id = "";
    filler_product.name = "";
    filler_product.no = "";
    //setIsChecked(!isChecked)
    //setChecked(!checked)
    isChecked2 = false;
    if (amount.edit_type === true) {
      {
        feature.map((e) => {
          if (e.featrure === "FG IN Warehouse") {
            selectLoop_model.current.select.clearValue();
            selectWarehouse_model.current.select.clearValue();
            selectRack_model.current.select.clearValue();
            selectProduct_on_rack_model.current.select.clearValue();
            selectLoad_status_model.current.select.clearValue();
          }
        })
      }
      selectProduct_model.current.select.clearValue();

    } else if (amount.edit_type === false) {
      {
        feature.map((e) => {
          if (e.featrure === "FG OUT Project") {
            selectProject_model.current.select.clearValue();
          }
          if (e.featrure === "FG IN/OUT Color (OUT)") {
            selectColor_model.current.select.clearValue();
          }
        })
      }


    }

    GetProduct();
  };
  function addpage() {
    window.location.href =
      "/Warehouse_Management/F_G_In_Out";
  }

  const GetProject = async () => {

    //console.log(filler_project);
    axios({
      method: "post",
      url: Configs.API_URL + "/project/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_project
    })
      .then(function (response) {
        //console.log(response.data);
        setProject(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.project_name;
          options.push(element);
        });
        setproject_option(options);

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  ///////////////fg out to in warehouse feature/////////////////
  const GetLoop = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/loop/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_loop
    })
      .then(function (response) {
        //console.log(response.data);
        setLoop(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.loop_no;
          options.push(element);
        });
        setloop_option(options);

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  useEffect(() => {
    GetProduct();
    GetAllLotWIPSTP();
    GetProject();
    GetCustomer();
    GetEnd_user();
    GetSummaryReport(); 
   /*  Getmodel();
    GetProject();
    Getlog_detailFG();
    GetSummaryReport(); */
    ///////////////fg out to in warehouse feature/////////////////
   /*  GetLoop();
    GetWarehouse();
    GetLoad_status();
   */
  }, []);


  if (amount.amount.length > 0 && oemfix === "thanasub") {
    var input = document.getElementById("amount_in");

    // Execute a function when the user presses a key on the keyboard
    input.addEventListener("keypress", function (event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === "Enter") {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        document.getElementById("btn_save").click();
      }
    });
  }


  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  async function SetDate_and_GetDayoff_start(date) {
    setcalender_data({ ...calender_data, exp: date })
  }

  const rowsData = [];
  //console.log(type_select);
  for (var index = 0; index < product_data.length; index++) {
    var id_toProduct;
    var FG_amount;

    if (type_select.status === "all_item") {
      if (product_data[index].product_plants_item === null) {
        id_toProduct = product_data[index].id;
        FG_amount = product_data[index].fg_amount;
      } else {
        id_toProduct = product_data[index].product_plants_item;
        const find_fg_amount = product_data.filter((element) => {
          return (element.id === product_data[index].product_plants_item);
        })

        FG_amount = find_fg_amount[0].fg_amount;
      }




    } else {
      id_toProduct = product_data[index].id;
      FG_amount = product_data[index].fg_amount;
    }
    const rowItem = {};
    if (product_data[index].fg_amount < product_data[index].min_product) {
      var c = "red";
    } else if (product_data[index].fg_amount >= product_data[index].min_product) {
      var c = "black";
    } else {
      var c = "black";
    }
    for (let indexin = 0; indexin < model.length; indexin++) {
      if (
        model[indexin].id == product_data[index].product_model_id &&
        product_data[index].product_model_id !== undefined
      ) {
        rowItem["product_model_id"] = <div style={{ color: c }}>{"" + model[indexin].name}</div>;
      }
    }
  
    if (FG_amount !== null) {
      if(feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0){
        rowItem["amount"] = (
          <div style={{ color: c }}><NumberFormat
            value={FG_amount.toFixed( 2 ) }
            displayType={"text"}
            thousandSeparator={true}
          /></div>
        );
      }else{
        rowItem["amount"] = (
          <div style={{ color: c }}><NumberFormat
            value={FG_amount}
            displayType={"text"}
            thousandSeparator={true}
          /></div>
        );
      }
    
    } else {
      rowItem["amount"] = <div style={{ color: c }}>0</div>;
    }

    rowItem["num"] = <div style={{ color: c }}>{index + 1}</div>;
    rowItem["no"] = product_data[index].no;
    rowItem["name"] = product_data[index].name;
    
    rowItem["qty_unit"] = <div style={{ color: c }}>{product_data[index].qty_unit}</div>;
    rowItem["min_prod"] = <div style={{ color: c }}><NumberFormat value={product_data[index].min_product === null ? 0 : product_data[index].min_product} displayType={'text'} thousandSeparator={true} /></div>;
    rowItem["unit"] = product_data[index].unit;
    var unit_img = "";
    unit_img = product_data[index].unit;
    rowItem["image"] = (
      <img
        alt="..."
        className="img-fluid rounded "
        src={product_data[index].imaget_path !== null ? Configs.API_URL_IMG + product_data[index].imaget_path : userdefault_img.imgs}
        style={{ width: "50px", height: "50px" }}
      />
    );
    rowItem["qr"] = (
      <div style={{ textAlign: "center" }}>
        <QRCode
          value={product_data[index].no}
          size={50}
          fgColor={"#000000"}
          bgColor={"#ffffff"}
          level={"M"}
          renderAs={"svg"}
          includeMargin={false}
          imageSettings={
            {
              src: logoTTT.imgs,
              height: 15,
              width: 15,
              x: null,
              y: null,
              excavate: true,
            }
          }
        /></div>
    );


    rowItem["track_detail"] = (
        <button
        type="button"
        className="btn btn-block btn-info btn-sm "
        data-toggle="modal"
        data-target="#modal-Track_detail"
        onClick={GetTrack_Lot.bind(this,product_data[index].id,product_data[index].no)
  
        }
      >
        View track
      </button>
      );




    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className=" btn btn-xs "
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={form.bind(
              this,
              product_data[index].no,
              product_data[index].name,
              id_toProduct,
              FG_amount,
              amount.lot_no,
              amount.edit_type,
              calender_data.exp,
              unit_img,
            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );



    //thanasub
    rowItem["sell_price"] = numberWithCommas(product_data[index].sell_price || 0);
    rowItem["supplier"] = product_data[index].supplier || "ไม่ระบุ";
    rowItem["line"] = product_data[index].line;
    rowItem["tel"] = product_data[index].tel;
    //thanasub




    rowsData.push(rowItem);
  }
  {
    feature.map((e) => {
      if (e.feature === "Image") {

      }
    })
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "num",
    /*     sort: "asc", */
        width: 50,
      }, {
        label: "Product No",
        field: "no",
 /*        sort: "asc", */
        width: 50,
      },
      {
        label: "Image",
        field: "image",
    /*     sort: "asc", */
        width: 50,
      },
      {
        label: "QR",
        field: "qr",
     /*    sort: "asc", */
        width: 50,
      },

      {
        label: "Product" + " " + "Name",
        field: "name",
   /*      sort: "asc", */
        width: 100,
      },
      {
        label: "Model Category",
        field: "product_model_id",
   /*      sort: "asc", */
        width: 50,
      },

      {
        label: "Qty Unit",
        field: "qty_unit",
 /*        sort: "asc", */
        width: 50,
      },
      {
        label: "Min Stock",
        field: "min_prod",
/*         sort: "asc", */
        width: 50,
      },
      //thanasub

      {
        label: "Supplier",
        field: "supplier",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line",
        field: "line",
/*         sort: "asc", */
        width: 50,
      },
      {
        label: "Tel.",
        field: "tel",
    /*     sort: "asc", */
        width: 50,
      },
      //thanasub
      {
        label: "Amount",
        field: "amount",
     /*    sort: "asc", */
        width: 50,
      },
      {
        label: "Sell Price",
        field: "sell_price",
/*         sort: "asc", */
        width: 50,
      },
      {
        label: "Unit",
        field: "unit",
    /*     sort: "asc", */
        width: 50,
      },
      {
        label: "Track Detail",
        field: "track_detail",
    /*     sort: "asc", */
        width: 50,
      },
      
      {
        label: "MGT",
        field: "mgt",
   /*      sort: "asc", */
        width: 50,
      },
    ],
    rows: rowsData,
  };
  var Image_feature = feature.filter((e) => {
    return e.feature === "Image";
  })
  var Qty_unit_feature = feature.filter((e) => {
    return e.feature === "Qty_unit";
  })
  var QR_feature = feature.filter((e) => {
    return e.feature === "QR";
  })
  var min_stock_feature = feature.filter((e) => {
    return e.feature === "min_stock";
  })
  if (Image_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "image";
    })
    data.columns.splice(index, 1);
  }
  if (Qty_unit_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "qty_unit";
    })
    data.columns.splice(index, 1);
  }
  if (QR_feature.length === 0) {
    
    var index = data.columns.findIndex((e) => {
      return e.field === "qr";
    })
    data.columns.splice(index, 1);
  }
  if (min_stock_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "min_prod";
    })
    data.columns.splice(index, 1);
  }
  if (oemfix === "thanasub") {
    var index = data.columns.findIndex((e) => {
      return e.field === "no";
    })
    data.columns.splice(index, 1);
    var index2 = data.columns.findIndex((e) => {
      return e.field === "unit";
    })
    data.columns.splice(index2, 1);

    var index3 = data.columns.findIndex((e) => {
      return e.field === "qty_unit";
    })
    if(Qty_unit_feature.length > 0){
      data.columns.splice(index3, 1);
    }
  
  } else {
    var index = data.columns.findIndex((e) => {
      return e.field === "sell_price";
    })
    data.columns.splice(index, 1);
    var index2 = data.columns.findIndex((e) => {
      return e.field === "supplier";
    })
    data.columns.splice(index2, 1);
    var index3 = data.columns.findIndex((e) => {
      return e.field === "line";
    })
    data.columns.splice(index3, 1);
    var index4 = data.columns.findIndex((e) => {
      return e.field === "tel";
    })
    data.columns.splice(index4, 1);
  }


  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }

  const rowsData_sum_report = [];

  for (var index = 0; index < summary_report.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["part_no"] = summary_report[index].no;
    rowItem["pro_name"] = summary_report[index].name;
    if (summary_report[index].edit_type == true) {
      rowItem["edit_type"] = "in";
    } else {
      rowItem["edit_type"] = "out"
    }

    rowItem["amount"] = summary_report[index].amount;
    rowItem["project"] = summary_report[index].project_name;
    rowItem["date"] = moment(summary_report[index].create_date).format('DD/MM/yyyy :: HH:mm');
    rowsData_sum_report.push(rowItem);
  }

  const sum_report = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "pro_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "edit_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Project",
        field: "project",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_sum_report,
  };

  function changes_log_no(e) {
    //console.log(e.target.value);
    var lot_no = e.target.value;
    setamount({
      ...amount,
      lot_no_select: lot_no,
    })
    amount.lot_no_select = lot_no;
    //console.log(amount);
  }
  var unit_name ="";
  unit_name =amount.unit || "";
  const rowsData_lot_detail = [];

  for (var index = 0; index < log_detailFG.length; index++) {
    const rowItem = {};
    rowItem["fg_lot_no"] = log_detailFG[index].fg_lot_no;
    rowItem["ref_lot_no"] = log_detailFG[index].ref_lot_no;
    if(feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0){
      rowItem["amount"] = parseFloat(log_detailFG[index].amount).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +" "+unit_name; 
    }else{
      rowItem["amount"] = parseFloat(log_detailFG[index].amount).toFixed( 0 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +" "+unit_name; 
    }
    //rowItem["amount"] = parseFloat(log_detailFG[index].amount).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;


    rowItem["delivery_date"] = moment(log_detailFG[index].delivery_date)/* .add(7, 'hours') */.format('DD/MM/yyyy');



    /////---feature status_sst ---//////
    rowItem["is_reserved"] = log_detailFG[index].is_reserved === true ? "ติดจอง" : "คลัง";
    rowItem["customer"] = log_detailFG[index].cus_and_end || "";
    rowItem["stp_machine"] = log_detailFG[index].stp_machine || "";
    /////---feature status_sst ---//////
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6"></div>
        <div className="col-4">
          <input
            className="form-check-input"
            type="radio"
            id={index}
            name="check_lot_fg"

            value={log_detailFG[index].fg_lot_no}
            onClick={changes_log_no.bind(
              this
            )}
          />
        </div>
      </div>
    );
    rowsData_lot_detail.push(rowItem);
  }
  const [have_exp, sethave_exp] = useState({
    have: false,
    name: "Create date",
  });


  const Data_lot_detail = {
    columns: [

      ///////// mode no-FIFO ///////
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      ///////// mode no-FIFO ///////
      {
        label: "Lot No",
        field: "fg_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Ref Lot No",
        field: "ref_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Delivery Date",
        field: "delivery_date",
        sort: "asc",
        width: 50,
      },
      /////---feature status_sst ---//////
      {
        label: "สถานะ",
        field: "is_reserved",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลูกค้า/ตัวแทนจำหน่าย",
        field: "customer",
        sort: "asc",
        width: 50,
      },
      {
        label: "เครื่องทอ",
        field: "stp_machine",
        sort: "asc",
        width: 50,
      },

      /////---feature status_sst ---//////
    ],
    rows: rowsData_lot_detail,
  };
  var Status_sst = feature.filter((e) => {
    return e.feature === "status_sst";
  })
  if (Status_sst.length === 0) {
    var index = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "is_reserved";
    })
    Data_lot_detail.columns.splice(index, 1);
    var index2 = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "customer";
    })

    Data_lot_detail.columns.splice(index2, 1);
    var index3 = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "stp_machine";
    })
    Data_lot_detail.columns.splice(index3, 1);
  }

  if (is_fifo === true) {
    var index = Data_lot_detail.columns.findIndex((e) => {
      return e.field === "mgt";
    })
    Data_lot_detail.columns.splice(index, 1);
  }








  function search_data() {
   
      //console.log("plant_item")
      GetProduct();
  
  }






  async function genLotNo() {

    axios({
      method: "get",
      url: Configs.API_URL + "/FG_STP_InOut/genLotNo_FG?oem_id=" + getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },

    })
      .then(function (response) {
        setamount({ ...amount, lot_no: response.data });
        amount.lot_no = response.data;
        //console.log(response.data)
      }).catch(function (e) {
        console.log(e);
      })
  }

  function getHistoryFG(product_id) {
    axios({
      method: "get",
      url: Configs.API_URL + "/FG_STP_InOut/getHistoryFG?id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
        setHistoryFG(response.data);
        //console.log(response.data)
      }).catch(function (e) {
        console.log(e);
      })
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const rowsData_historyFG = [];

  for (var index = 0; index < historyFG.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["fg_lot_no"] = historyFG[index].fg_lot_no;
    rowItem["ref_lot_no"] = historyFG[index].ref_lot_no;
    var status;
    if (historyFG[index].edit_type === true) {
      status = "IN";
    } else {
      status = "OUT";
    }
    rowItem["status"] = status;
    if(feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0){
      rowItem["amount"] = parseFloat(historyFG[index].amount).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" "+unit_name; 
    }else{
      rowItem["amount"] = parseFloat(historyFG[index].amount).toFixed( 0 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" "+unit_name; 
    }
    rowItem["date"] = moment(historyFG[index].create_date).format('DD/MM/yyyy :: HH:mm น.');
    rowItem["is_reserved"] = historyFG[index].is_reserved === true ? "ติดจอง" : "คลัง";
    rowItem["customer"] = historyFG[index].cus_and_end || "";
    rowItem["stp_machine"] = historyFG[index].stp_machine || "";
    rowsData_historyFG.push(rowItem);
  }

  const Data_HistoryFG = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "fg_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Ref Lot No",
        field: "ref_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      /////---feature status_sst ---//////
      {
        label: "สถานะ",
        field: "is_reserved",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลูกค้า/ตัวแทนจำหน่าย",
        field: "customer",
        sort: "asc",
        width: 50,
      },
      {
        label: "เครื่องทอ",
        field: "stp_machine",
        sort: "asc",
        width: 50,
      },

      /////---feature status_sst ---//////
    ],
    rows: rowsData_historyFG,
  };

  if (Status_sst.length === 0) {
    var index = Data_HistoryFG.columns.findIndex((e) => {
      return e.field === "is_reserved";
    })
    Data_HistoryFG.columns.splice(index, 1);
    var index2 = Data_HistoryFG.columns.findIndex((e) => {
      return e.field === "customer";
    })
    Data_HistoryFG.columns.splice(index2, 1);
    var index3 = Data_HistoryFG.columns.findIndex((e) => {
      return e.field === "stp_machine";
    })
    Data_HistoryFG.columns.splice(index3, 1);
  }
  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? '#87CEFA' : '#FFF',
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',

      };
    },

  };

  function searchArrayCus(key){
    var data_cus = customer.filter((e)=>{
      return e.name === key
    });
    var customer_ = "No Customer!!!";
    var customer_id = "";
    if(data_cus.length > 0){
      customer_ = data_cus[0].name +"    "+ data_cus[0].full_name;
      customer_id = data_cus[0].id;
    }
    setamount({...amount,customer:customer_id});
    amount.customer = customer_id;
    setsearch_cus_end({...search_cus_end,result_cus:customer_});
    setsearch_cus_end.result_cus = customer_;
    //console.log(customer_);
  }

  function searchArrayEnd(key){
    var data_end = end_user.filter((e)=>{
      return e.name === key
    }); 
    var enduser_ = "No End user!!!";
    var enduser_id = "";
    if(data_end.length > 0){
      enduser_ = data_end[0].name +"    "+ data_end[0].fullname;
      enduser_id = data_end[0].id;
    }
    setamount({...amount,end_user:enduser_id});
    amount.end_user = enduser_id;
    setsearch_cus_end({...search_cus_end,result_end:enduser_});
    setsearch_cus_end.result_end = enduser_;
    //console.log(customer_);
  }


  const [test,settest] = useState({
    test:"",
  });

  function setDefaultLotOut(){

    //console.log("e",e);
    //console.log("index",index);
    setlot_out_list([]);
    let tempData = Lot_wip_full;
    for(let i of tempData){
      i.isOut = false;
    }
    //tempData[index].isOut = true;
    setLot_wip_full(tempData);
    settest({...test,test:""});
    test.test = "";
  }
  function LotOut(e,index){

  /*   console.log("e",e);
    console.log("index",index); */
    lot_out_list.push(e);
    let tempData = Lot_wip_full;

    tempData[index].isOut = true;
    setLot_wip_full(tempData);
    settest({...test,test:""});
    test.test = "";
  }



const [Tracking_lot,setTracking_lot] = useState([]); 

  async function GetTrack_Lot(product_id,product_no) {
    setmessage({ ...message, name: product_no});
  
    await axios({
      method: "get",
      url: Configs.API_URL + "/FG_STP_InOut/getTrackLot?id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setTracking_lot(response.data)
       //sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });

  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="fginout" submenu="none" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>FG STP In/Out{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">FG STP In/Out</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.no.trim()}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product No.</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.name.trim()}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <Select
                    placeholder=""
                    styles={colourStyles}
                    options={product_model_option}
                    defaultValue={filler_product.product_model_id}
                    ref={selectProduct_model}
                    onChange={(e) => {
                      // console.log(e);
                      if (e !== null) {
                        setFiller_product({
                          ...filler_product,
                          product_model_id: e.value,
                        });
                        filler_product.product_model_id = e.value;
                      }

                      search_data();
                    }}
                  />
                  <label htmlFor="">Model Category</label>
                </div>
              </div>
           





              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                  <label htmlFor="">Category/Model</label>
                </div>
              </div> */}
            </div>
          </div>
          
          <div className="container-fluid">
            <div className="row">
              <div className="col-1"></div>
            



            </div>      </div>


          <div className="container-fluid">
            <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                    setMDBT(false);
                      search_data();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">

                  <button
                    type="button"
                    className="btn btn-block btn-info "
               
                        onClick={()=>{
                            setMDBT(false);
                            clearfillter();
                            setMDBT(true);
                            
                          }
                           }
                     

     
                  >
                    Clear

                  </button>

                </div>
              </div>

              {/* {feature.map((e) => {

                if (e.feature === "Summary Project Report") {
                  return ( */}
               <div className="col-12 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-block btn-primary"
                          table="table-to-xls"
                          filename={"SM" + " " + moment(new Date()).format('DD-MM-yyyy')}
                          sheet="tablexls"
                          buttonText="Summary Project Report"
                        />
                      </div>
                    </div>
              {/*     );
                }
              })} */}

            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Product FG STP</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" style={{ whiteSpace: 'nowrap' }}>
                <MDBDataTable
                  className="table table-head-fixed"
                  disableRetreatAfterSorting={MDBT}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                  width="1000px"
                />
              </div>

              <div className="table-responsive" style={{ display: "none" }}>
                <MDBDataTable
                  className="table table-head-fixed"
                  sortable = {false}
                  striped
                  bordered
                 paging ={false}
                  hover
                  fixedHeader
                  id="table-to-xls"
                  data={sum_report}
                />
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>


        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title"> Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;

                  {
                    <NumberFormat
                      style={
                        { backgroundColor: "#F0FFFF", border: "1px solid #ddd", padding: "10px", width: "100" }}
                        
                      value={feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0 ? (amount.total || 0 ).toFixed(2) : (amount.total || 0 )  }
                      displayType={"text"}
                      thousandSeparator={true}
                    />}

                  &nbsp;&nbsp;&nbsp; items
                </h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                <div className="col-0 col-md-1 col-xl-2"></div>
                  <div className="col-12 col-md-5 col-xl-4" >
                    <h3>Product No : {message.no}</h3>
                 
                  </div>

                  <div className="col-12 col-md-5 col-xl-4" >
                    <h3>Product Name : {message.name}</h3>

                
                  </div>
                </div>



                <div className="row">
                <div className="col-0 col-md-1 col-xl-2"></div>
                
                      <div className="col-0.5">
                        <input
                          type="radio"
                          id="inbound"
                          name="edit_type"
                          checked={amount.edit_type != true ? false : true}
                          value={true}
                          onChange={(e) => {
                            setamount({
                              ...amount,
                              edit_type: e.target.value === "true" ? true : false,
                            });
                            setis_fifo(true);
                          }}
                        />
                      </div>
                      <div className="col-5 col-md-5 col-xl-4" >
                        <label htmlFor="inbound">
                          <h4>In bound</h4>
                        </label>
                      </div>

                      <div className="col-0.5">

<input
  type="radio"
  id="outbound"
  name="edit_type"
  value={false}
  checked={amount.edit_type != false ? false : true}
  onChange={(e) => {
    setamount({
      ...amount,
      edit_type: e.target.value === "false" ? false : true,
    });
  }}
/>
</div>
<div className="col-5 col-md-3 col-xl-2">
<label htmlFor="outbound">
  <h4>Out bound</h4>
</label>
</div>


                    </div>



               
                          
                {amount.edit_type === false ?
                  (<>
                 <div className="row" style={{ whiteSpace: 'nowrap'}} >
                       <div className="col-0 col-md-3 col-xl-3"></div>
                      <div className="col-5 col-md-2 col-xl-2" style = {{textAlign: "right"}}><label for="is_fifo">Manual out FG</label></div>
                      <div className="col-3">
                    
                      <div class="custom-control custom-switch">
                     
<input type="checkbox" class="custom-control-input" id="is_fifo"
 defaultChecked = {is_fifo}
onClick={(e)=>{
  //console.log(e);
  

    setis_fifo(e.target.checked);

 
    setis_fifo(e.target.checked);


 
}}
/>
<label class="custom-control-label" for="is_fifo">First In First Out</label>
</div>
                      
                      </div>
                    </div>
                 
                    {/*   </>);
                    }
                  })} */}
                   
                  </>
                  ) : (
                    <>
                    </>
                  )}

               {/*  {feature.map((e) => {
                  if (e.feature === "FG IN/OUT History") { 
                    return*/} <LOGH onClick={clearfillter} history={Data_HistoryFG} data={Data_lot_detail} />
                    {/*   ;
                }
                })

                } */}
                
                {amount.edit_type === false ? (


                  <div className="row">
                    <div className="col-0 col-md-4 col-xl-4"></div>


                    {/* {feature.map((e) => {
                      if (e.feature === "FG OUT Project") {
                        return (
                          <> */}
                            <div className="col-12 col-md-4 col-xl-4" style={{ "z-index": "98" }}>
                              <div className="form-group ">
                                <Select
                                  options={project_option}
                                  defaultValue={amount.project_id}
                                  ref={selectProject_model}
                                  onChange={(e) => {
                                    //console.log(e);
                                    if (e !== null) {
                                      setamount({
                                        ...amount,
                                        project_id: e.value,
                                      });

                                    }

                                  }}
                                />
                                <label htmlFor="">Project</label>
                              </div>
                            </div>
                      {/*     </>
                        );
                      }
                    })} */}

                    <div className="col-4"></div>
                    <div className="col-4"></div>
                    <div className="col-4">
                      {/*  {feature.map((e) =>{
                    if(e.feature === "FG IN/OUT Lot No"){
                      
                      return (
                        <INF value = {amount.lot_no} disabled = {true} label = {"Lot No"} onChange ={(e) => {
                          setamount({
                            ...amount,
                            lot_no: e.target.value,
                          });
                        }}/>
                      );
                    }
                  })

                  } */}



                    </div>
                    <div className="col-1">
                      <div className="form-group ">
                        {/*   <button
                        type="button"
                        className="btn btn-primary"
                        onClick={clearfillter}
                      >
                        Clear
                      </button> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>

                    <div className="row">
        
                    <div className="col-12 col-md-4 col-xl-4" >
                           <INF value={amount.ref_lot} disabled={false} label={"Ref Lot No"} onChange={(e) => {
                                setamount({
                                  ...amount,
                                  ref_lot: e.target.value,
                                });
                              }} />
                           </div>
                           <div className="col-12 col-md-4 col-xl-4" >

                 
                              <INF value={amount.lot_no} disabled={true} label={"Lot No"} onChange={(e) => {
                                setamount({
                                  ...amount,
                                  lot_no: e.target.value,
                                });
                              }} />
                      
                      </div>
                  
                   
                      <div className="col-12 col-md-4 col-xl-2" >
                                <div class="form-group">
                                  <select
                                    className="custom-select select2 form-control"
                                    value={amount.status_sst}
                                    onChange={(e) =>{
                                      setamount({ ...amount, status_sst: e.target.value })
                                      search_cus_end.result_end = "";
                                      search_cus_end.result_cus = "";
                                    }
                                    
                                    }
                                  >

                                    <option value="Warehouse_STP">Warehouse STP</option>
                                    <option value="customer">Customer</option>
                                    <option value="end_user">End User</option>

                                  </select>

                                  <label htmlFor="">สถานะ :</label>

                               
                              </div>
                              </div>
                              <></>


                  
                      {amount.status_sst === "customer" ? (
                        <>  
                              <div className="col-0 col-md-4 col-xl-4" ></div>
                        <div className="col-12 col-md-4 col-xl-4" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <Select
                    placeholder=""
                    styles={colourStyles}
                    options={customer_option}
                    defaultValue={amount.customer }
                   
                    onChange={(e) => {
                      // console.log(e);
                     
                      setamount({
                          ...amount,
                          customer : e.value1,
                        });
                        amount.customer = e.value1;
                  
                        setsearch_cus_end({
                          ...search_cus_end,
                          result_cus : e.value2,
                        });
                        search_cus_end.result_cus = e.value2;
                  
                   
                    }}
                  />
                  <label htmlFor="">No. Customer </label>
                </div>
              </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                          <INF value={search_cus_end.result_cus} disabled={true}  label={"Result Customer"} /* onChange={(e) => {
                                 setamount({ ...amount, customer: e.target.value })
                              }} */ />
                          </div>
                          </div>
                        </>
                      ) : (
                        <>
                      {amount.status_sst === "end_user" ? (
                        <>
                            <div className="col-0 col-md-4 col-xl-4" ></div>
                        <div className="col-12 col-md-4 col-xl-4" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <Select
                    placeholder=""
                    styles={colourStyles}
                    options={End_user_option}
                    defaultValue={amount.customend_userer }
                    onChange={(e) => {
                      // console.log(e);
                     
                      setamount({
                          ...amount,
                          end_user : e.value1,
                        });
                        amount.end_user = e.value1;
                  
                        setsearch_cus_end({
                          ...search_cus_end,
                          result_end : e.value2,
                        });
                        search_cus_end.result_end = e.value2;
                  
                   
                    }}
                  />
                  <label htmlFor="">No. ENd User</label>
                </div>
              </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                          <INF value={search_cus_end.result_end} disabled={true}  label={"Result End user"} /* onChange={(e) => {
                                 setamount({ ...amount, customer: e.target.value })
                              }} */ />
                          </div>
                          </div>
                   
                        </>
                      ) : (
                        <>
                  
                        </>
                      )}
                        </>
                      )}
 

                     
                 
                 </div>

                  </>
                )}

                
                              {/* <div className="row"> */}
                             


                      {/*   {amount.edit_type === true ? (
                            <>
                              <div className="col-5 col-md-4 col-xl-4" style={{textAlign:"right",padding:"5px"}}>
                              <label htmlFor="">กรุณาเลือก WIP Lot No :</label>
                                 </div>
                                 <div className="col-12 col-md-4 col-xl-4"  style={{padding:"5px"}}>
                             
        
                                 <MultiSelect
         
        
              hasSelectAll = {false}
                options={Lot_wip}
                value={Lot_wip_list}
                onChange={setLot_wip_list}
                labelledBy="Select"
              />
        
        
        
                                  
                                 </div>
        
                              
                                 <div className="col-8 col-md-4 col-xl-2"  style={{padding:"5px"}}>

        
 <button
type="button"
className="btn btn-block btn-info "
data-toggle="modal"
data-target="#modal-lotwip_mgt"
onClick={setDefaultLotOut}
>
Lot Deflux & WIP MGT
</button>
 

     
 </div>
 </>
                        ) : (
                            <></>
                        )}
         */}
                            
                              {/*      </div> */}
                             
                                 {amount.edit_type === true ? (
                                     <>
                                     <br/>
                                                    <div className="row">
                                    <div className="col-0 col-md-4 col-xl-4"></div>
                                    <div className="col-12 col-md-4 col-xl-4">
                              <div class="form-group">
                         <select
                                className="custom-select select2 form-control"
                                value={amount.stp_machine}
                                 onChange={(e) =>
                                  setamount({
                                    ...amount,
                                    stp_machine: e.target.value,
                                  })
                                }
                              >
                                 <option value="">-----</option>
                                <option value="เครื่องทอที่ 1">เครื่องทอที่ 1</option>
                                <option value="เครื่องทอที่ 2">เครื่องทอที่ 2</option>
                               
                              </select>
                              <label htmlFor="">เครื่องทอ</label>
                              </div>
                                   </div>
                                   <div className="col-12 col-md-4 col-xl-4"style={{ "z-index": "101",alignItems:"left" }}>
                          
                          <INDF selected={calender_data.exp} 
                              txt = "Delivery Date"
                          onChange={(date) =>
                            SetDate_and_GetDayoff_start(date)}
                            customInput={<CustomInput />}
                          />
                  
        
                  </div>
                                   
          </div>

                                     </>
                                 ):(
                                    <>    </>
                                 )}
                             

                <div className="row">
                  <div className="col-12 col-md-4 col-xl-4">
                    {amount.edit_type === false ? (
                      <>
                        {feature.map((e) => {
                          if (e.feature === "FG IN/OUT Remark (OUT)") {
                            return (
                              <INF value={amount.remark} label={"Remark"} onChange={(e) => {
                                setamount({
                                  ...amount,
                                  remark: e.target.value,
                                });
                              }} />
                            );
                          }
                        })

                        }</>) : (
                      <>
                      </>
                    )}
                  </div>
                     
                  <div className="col-12 col-md-4 col-xl-4">
                    <div className="form-group ">
                      {feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0 ? (
                        <>
                         <NumberFormat
                        thousandSeparator={true}
                        allowNegative={false}
                        id="amount_in"
                        type="text"
                        className="form-control"
                        decimalScale={2}
                        value={amount.amount}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            amount: e.target.value,
                          });
                        }}
                        autofocus
                        required
                      />
                        </>
                      ) : (
                        <>
                         <NumberFormat
                        thousandSeparator={true}
                        allowNegative={false}
                        id="amount_in"
                        type="text"
                        className="form-control"
                        decimalScale={0}
                        value={amount.amount}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            amount: e.target.value,
                          });
                        }}
                        autofocus
                        required
                      />
                        </>
                      )}
                     {/*  {feature.map((e)=>{
                        var decimalCheck = feature
                        if(e.feature === AmountDecimalScale){
                          return (
                            <>
                            
                            </>
                          ) 
                        }v 
                      })} */}
                     
                      <label htmlFor="">Amount</label>{" "}
                    </div>
                  </div>




                  {amount.edit_type === false ? (
                    <>
                      {feature.map((e) => {
                        if (e.feature === "FG IN/OUT Color (OUT)") {
                          return (
                            <div className="col-4" style={{ "z-index": "98" }}>
                              <div className="form-group ">
                                <Select
                                  options={color_option}
                               
                                  defaultValue={amount.color}
                                  ref={selectColor_model}
                                  onChange={(e) => {
                                    //console.log(e);
                                    if (e !== null) {
                                      setamount({
                                        ...amount,
                                        color: e.value,
                                      });
                                    }

                                  }}
                                />
                                <label htmlFor="">Color</label>
                              </div>
                            </div>
                            /*  <div className="col-4">
                             <div className="form-group">
                             <select
                               className="custom-select"
                               value={amount.color}
                               //disabled={mode.mode === "read" ? true : false}
                               onChange={(e) =>
                                 setamount({
                                   ...amount,
                                   color: e.target.value,
                                 })
                               }
                             >
                               <option value="" selected disabled>
                                 select Color
                               </option>
                               {color.map((el) => {
                                 return (
                                   <option key={el.id} value={el.name}>
                                     {el.name}
                                   </option>
                                 );
                               })}
                             </select>
                             <label htmlFor="">Color</label>
                           </div>
                           </div> */
                          );
                        }
                      })

                      }</>) : (
                    <></>
                  )}

                </div>
               
               


              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  id="btn_save"
                  onClick={Getamount}
                //data-dismiss="modal"
                //aria-label="Close"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>






      <div className="modal fade" id="modal-lotwip_mgt">
      <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">รายการ Lot Deflux & WIP </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">
                <div className="table-responsive">
                <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                          <tr>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>Lot Deflux & WIP No</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>จำนวน</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>น้ำหนักพร้อมใช้งาน</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>Date</th>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>MGT</th>
                          </tr>
                       
                              {Lot_wip_full.map((e,index)=>{
                                if(Lot_wip_list.filter((lot)=> {return lot.label == e.deflux_lot_no}).length > 0){
                                  return(<>
                                    <tr>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>{e.deflux_lot_no}</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>{e.amount} ม้วน</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>{e.weight !== null ? e.weight +" Kg." : "ไม่ระบุ"}</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>{moment(e.create_date).format("DD/MM/yyyy  HH:mm น.")}</td>
                                 <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>
                                 <button
                   type="button"
                   className={e.isOut === true ? "btn btn-block btn-secondary" : "btn btn-block btn-danger"  }
                  
                   key={index}
                   disabled={e.isOut === true ? true : false}
                   onClick={LotOut.bind(this,e,index)}
                   // data-dismiss="modal"
                   // aria-label="Close"
                 >
                   {e.isOut === true ? "Lot OUT." : "Select Lot Out."}
                 </button>
                                 </td>
                                 </tr>
                                 </>);
                                }
                               
                              })}
                          
                        </table>
       
                </div>
              </div>
              </div>
            
              
           
            </div>
            <div className="modal-footer "align="right">
            
                 
               <div className="row">
              {/*      <div className="col-12" align="right"> */}
                   <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
                {/*    </div> */}
               </div>
             
              
          
             
            </div>
          </div>
         
        </div>
       
      </div>





      <div className="modal fade" id="modal-Track_detail"> 
      <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Tracking Detail {message.name}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">
                <div className="table-responsive">
                <table style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%"}}>
                          <tr>
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>FG LOT NO</th>
                            {/* <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>Deflux & WIP LOT NO</th> */}
                            <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>Material LOT NO</th>
                       {/*      <th style = {{textAlign: "center",border: "1px solid #ddd" ,backgroundColor:"#B0C4DE" }}>MGT</th> */}
                          </tr>
                       
                              {Tracking_lot.map((e,index)=>{
                                return(<>
                                   <tr>
                                <td  style = {{textAlign: "center",border: "1px solid #ddd"  }}>{e.fg_lot_no}</td>
                               
                               {/*  <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>
                                  {e.wip_lot_list.map((e2)=>{
                                    return (<>
                                     
                                   <div>
                                      {e2.label}
                                    </div>
                             
                                    </>)
                                  })
                                }
                                </td> */}
                    <td style = {{textAlign: "center",border: "1px solid #ddd"  }}>
                    {e.wip_lot_list.map((e3)=>{
                        return(
                            <>
                    {e3.mat_lot_list.map((e4)=>{
                    return (<>
             
             <div>
                 {e4.label}
                  </div>
             
                     </>)
                     })
}
</>    );
                                 
                                  })
                                }

                    </td>
                           
                                </tr>
                                </>);
                              })}
                          
                        </table>
       
                </div>
              </div>
              </div>
            
              
           
            </div>
            <div className="modal-footer "align="right">
            
                 
               <div className="row">
              {/*      <div className="col-12" align="right"> */}
                   <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
                {/*    </div> */}
               </div>
             
              
          
             
            </div>
          </div>
         
        </div>
       
      </div>





      {/*  <Footter /> */}
    </div>



  );
}
export default FG_STPInOut;
