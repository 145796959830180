import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import axios from "axios";
import Configs from "../../../../config";
import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import { MultiSelect } from "react-multi-select-component";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import "../../../../../src/assets/css/Quotation_CutePlus.css";
import Pick_Product_Admin_Report from "../เบิกสินค้า_Admin/Component/Pick_Product_Admin_Report";
import Select from "react-select";

function Pick_Product_Table() {
  let [Requisition_Table, setRequisition_Table] = useState([]);
  const [saleDD, setSaleDD] = useState([]);
  const [statusDD, setStatusDD] = useState({
    data: [],
  });
  const [CustomerDD, setCustomerDD] = useState([]);
  const [Filter, setFilter] = useState({
    req_no: "",
    req_cus_id: "",
    req_rs_id: [],
    req_created_name: getUser().name + " " + getUser().surname,
    req_oem_id: getOem(),
    req_company_id: getUser().com,
  });

  let [status_list_filter, setStatus_list_filter] = useState([]);

  const delRequisition_Table = () => { };

  const closeModal = useRef();

  useEffect(async () => {
    await getBarcode();
    await getStatusDD();
    await getCustomerDD();
    await GetRequisition();
  }, []);

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  async function getCustomerDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        const cus_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        cus_dd.unshift({
          label: "--Select--",
          value: ""
        })
        setCustomerDD(cus_dd);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getStatusDD() {
    axios({
      method: "get",
      url: Configs.API_URL_cuteplus_sales + "/api/requistion_status/getAll",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const options = [];
        for (let item of response.data) {
          let temp = {
            label: item.rs_name,
            value: item.rs_id,
          };
          options.push(temp);
        }
        setStatusDD({ ...statusDD, data: options });
        statusDD.data = options;
        // console.log("status:>> ", statusDD.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const GetRequisition = async () => {
    const temFilter = {
      req_no: Filter.req_no.trim() === "" ? null : Filter.req_no.trim(),
      req_cus_id: Filter.req_cus_id === "" ? null : Filter.req_cus_id,
      req_rs_id: status_list_filter,
      req_created_name: getUser().fup,
      req_oem_id: getOem(),
      req_company_id: getUser().com,
    };
    console.log("temFilter", temFilter);
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/requisition/filter",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temFilter,
    })
      .then((response) => {
        // console.log("status:>> ", response.data);
        setRequisition_Table(response.data);
        Requisition_Table = response.data;
        /*    const options = []
        for (let item of response.data) {
          let temp = {
            label: item.rs_name,
            value: item.rs_id
          }
          if (temp.label !== "Cancel") {
            options.push(temp);
          }
        }
        setStatusDD({ ...statusDD, data: options });
        statusDD.data = options;
        console.log('status:>> ', statusDD.data);
 */
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [StatusBill_Table, setStatusBill_Table] = useState({
    data: [],
  });

  // const rowsBill = [];
  // for (let index = 0; index < StatusBill_Table.data.length; index += 1) {
  //   const rowItem = {};

  //   rowItem["barcode"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

  //   rowItem["product_name"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

  //   rowItem["product_quantity"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

  //   rowItem["remark"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

  //   rowItem["edit"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

  //   rowItem["his_management"] = (
  //     <div className="row" style={{ flexWrap: "nowrap", textAlign: "center", alignItems: "center" }}>
  //       <div className="col-xl-3 col-md-3 col-xs-3 ">
  //         <a
  //           key={StatusBill_Table.data[index]}
  //           id="btn_delete"
  //           className=" btn btn-xs "
  //           onClick={() => {
  //             window.location.href =
  //               "/Sale_cuteplut/เบิกสินค้า_sale/ViewHistory/" +
  //               StatusBill_Table.data[index].hr_id;
  //           }}
  //         >
  //           <i class="fas fa-eye"></i>
  //         </a>
  //       </div>
  //     </div>
  //   );

  let [company, setCompany] = useState([]);
  let [DataForReport, setDataForReport] = useState({
    data: [],
  });

  async function getReport(dataHisReq) {
    //GET COMPANY NAME
    await axios({
      method: "get",
      url: Configs.API_URL + "/company/findById?id=" + getUser().com,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setCompany(response.data);
        company = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    //GET DATA FOR REPORT
    const hisList = [...dataHisReq];
    const maxIndex = dataHisReq.reduce(
      (acc, cur, index) =>
        cur.hr_version > dataHisReq[acc].hr_version ? index : acc,
      0
    );
    const temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      hr_id: hisList[maxIndex].hr_id,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/history_requisition/admin/GetReportProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus +
            "/api/product/getProductDetailsForReqAdmin",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: response.data,
        })
          .then(async (response2) => {
            setDataForReport({
              ...DataForReport,
              data: response2.data,
            });
            DataForReport.data = response2.data;
            if (DataForReport.data.HisReqProduct.length > 0) {
              let data = new FormData();
              data.append("company_id", getUser().com);
              data.append("oem_id", getOem());
              data.append("user_id", getUser().fup);
              data.append(
                "products",
                JSON.stringify(
                  DataForReport.data.HisReqProduct !== undefined
                    ? DataForReport.data.HisReqProduct
                    : []
                )
              );

              await axios({
                method: "post",
                url:
                  Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "multipart/form-data",
                },
                data: data,
              })
                .then(async (response) => {
                  setDataForReport({
                    ...DataForReport,
                    data: {
                      ...DataForReport.data,
                      HisReqProduct: response.data,
                    },
                  });
                  DataForReport.data.HisReqProduct = response.data;
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Error",
                    text: error.response.data.error.message,
                    icon: "error",
                  });
                });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    Pick_Product_Admin_Report(company, DataForReport.data, false);
  }

  const handleRowDelete = (index) => {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        const tempProductData = [...StatusBill_Table.data];
        tempProductData.splice(index, 1);
        setStatusBill_Table({ ...StatusBill_Table, data: tempProductData });
      }
    });
  };

  //   rowsBill.push(rowItem);
  // }

  const [ReqNo, setReqNo] = useState({
    data: "",
  });

  const [Barcode, setBarcode] = useState({
    data: "",
  });

  const getBarcode = async (id) => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/getBarcode",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id },
    })
      .then(function (response) {
        // console.log(response.data)
        // console.log(StatusBill_Table.data)
        for (let BC of response.data) {
          for (let REQ of StatusBill_Table.data) {
            if (StatusBill_Table.data.rp_pb_id === Barcode.data.pb_id) {
              // setBarcode({ ...Barcode, data: response.data });
              // Barcode.data = response.data;
              // setStatusBill_Table({
              //   ...StatusBill_Table,
              //   rp_barcode: BC.pb_piece,
              // });
            }
          }
        }
        setBarcode({ ...Barcode, data: response.data });
        Barcode.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [REQBill, setREQBill] = useState({
    data: "",
  });

  const getBillfromREQ = async (id) => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/getBillfromREQ",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id },
    })
      .then(function (response) {
        getProductData(id);
        getBarcode();
        for (let SOD of response.data) {
          for (let REQ of StatusBill_Table.data) {
            if (REQ.rp_req_id === SOD.bill_req_id) {
              // console.log(SOD.remark);
              // setStatusBill_Table({
              //   ...StatusBill_Table,
              //   rp_remark: SOD.remark,
              // });
            }
          }
        }

        setREQBill({ ...REQBill, data: response.data });
        REQBill.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getProductData = async (id) => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/requisition/getProductData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        id: id,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(function (response) {
        if (response.data.length > 0) {
          for (let item of response.data) {
            item.product_show_amount = item.rp_quantity;
          }
          setStatusBill_Table({ ...StatusBill_Table, data: response.data });
          StatusBill_Table.data = response.data;
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const checkErrorForSave = async () => {
    let error_list = [];
    for (let index = 0; index < StatusBill_Table.data.length; index += 1) {
      if (StatusBill_Table.data[index].rp_quantity) {
        StatusBill_Table.data[index].rp_quantity =
          StatusBill_Table.data[index].rp_quantity?.trim();
      }
      if (
        StatusBill_Table.data[index].rp_quantity === "" ||
        StatusBill_Table.data[index].rp_quantity === null ||
        StatusBill_Table.data[index].rp_quantity === undefined
      ) {
        let temp_err = {
          message: `กรุณากรอกจำนวนในช่อง Edit`,
        };
        error_list.push(temp_err);
      } else if (Number(StatusBill_Table.data[index].rp_quantity) === 0) {
        let temp_err = {
          message: `กรุณากรอกจำนวนที่ไม่ใช่ 0 ในช่อง Edit`,
        };
        error_list.push(temp_err);
      }
    }

    return error_list;
  };

  async function saveOption() {
    const result = await Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    });

    if (result.isConfirmed) {
      let error_list = [];

      error_list = await checkErrorForSave();

      if (error_list.length > 0) {
        var err_message = "";

        for (var e = 0; e < error_list.length; e++) {
          err_message += "<br/>" + error_list[e].message;
        }

        Swal.fire("Error", err_message, "error");
      } else {
        const temp = [];
        for (let item of StatusBill_Table.data) {
          const tempItem = {
            rp_id: item.rp_id,
            rp_quantity: item.rp_quantity || null,
            rp_quantity_unit: item.rp_quantity_unit || null,
            rp_product_id: item.rp_product_id || null,
            rp_pb_id: item.rp_pb_id || null,
            rp_cus_barcode: item.rp_cus_barcode || null,
            rp_quantity: item.rp_quantity || null,
            rp_quantity_unit: item.rp_quantity_unit || null,
            rp_price: item.rp_price || null,
            rp_total: item.rp_total || null,
            rp_is_use: true,
            // rp_created_date: new Date(),
            rp_updated_date: new Date(),
            // rp_created_by: getUser().fup,
            rp_updated_by: getUser().fup,
            // rp_created_name: null,
            rp_updated_name: getUser().name + " " + getUser().surname,
            // rp_product_name_th: item.rp_product_name_th || null,
            // rp_product_name_eng: item.rp_product_name_eng || null,
            rp_lp_id: item.rp_lp_id || null,
            rp_lot_no: item.rp_lot_no || null,
            rp_exp_date: item.rp_exp_date || null,
            rp_req_id: item.rp_req_id,
            rp_oem_id: getOem(),
            rp_company_id: getUser().com,
            // discount: item.discount?.map((el, index) => {
            //   return {
            //     sod_discount: el.discount || null,
            //     sod_unit_discount: el.unit || null,
            //     sod_sort: index + 1,
            //     sod_is_use: true,
            //     sod_created_date: new Date(),
            //     sod_updated_date: new Date(),
            //     sod_created_by: getUser().fup,
            //     sod_updated_by: getUser().fup,
            //     sod_created_name: getUser().name + " " + getUser().surname,
            //     sod_updated_name: getUser().name + " " + getUser().surname,
            //   };
          };
          temp.push(tempItem);
        }

        try {
          let axiosData = {};

          axiosData = {
            method: "post",
            url:
              Configs.API_URL_cuteplus_sales +
              "/api/requisition/realeditProduct",
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: {
              data: temp,
              company_id: getUser().com,
              oem_id: getOem(),
            },
          };

          await axios(axiosData)
            .then(async (response) => {
              await updateStatusBill(temp);
            })
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(async () => {
                await GetRequisition();
                closeModal.current.click();
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        } catch (error) {
          console.log(error);

          Swal.fire({
            icon: "error",
            title: error.response.data.error[0].errorMessage,
          });
        }
      }
    }
  }

  const updateStatusBill = async (temp) => {
    let data = {
      data: temp,
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/updateStatusBill",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then((response) => {
        // console.log("response.data :>> ", response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const rowsBill = [];
  for (let index = 0; index < StatusBill_Table.data.length; index += 1) {
    const rowItem = {};

    if (REQBill.data[0]?.remark[index]) {
      // rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
      for (let BC of Barcode.data) {
        //   for (let REQ of StatusBill_Table.data) {
        if (StatusBill_Table.data[index].rp_pb_id === BC.pb_id) {
          rowItem["barcode"] = BC.pb_piece ? BC.pb_piece : "-";
        }
      }
      // }
      // }
      // rowItem["barcode"] = Barcode.data[index].pb_piece
      //   ? Barcode.data[index].pb_piece
      //   : "-";

      rowItem["product_name"] = StatusBill_Table.data[index].rp_product_name
        ? StatusBill_Table.data[index].rp_product_name
        : "-";

      rowItem["product_quantity"] = StatusBill_Table.data[index]
        .product_show_amount
        ? StatusBill_Table.data[index].product_show_amount
        : "-";

      rowItem["remark"] = REQBill.data[0]?.remark[index]
        ? REQBill.data[0]?.remark[index]
        : "-";

      rowItem["edit"] = (
        <div style={{ display: "flex", gap: ".5rem" }}>
          <NumberFormat
            type="text"
            className="form-control"
            // required
            decimalScale={0}
            allowNegative={false}
            // allowLeadingZeros={true}
            disabled={false}
            value={StatusBill_Table.data[index].rp_quantity}
            onChange={(item) => {
              const temp = StatusBill_Table.data;

              temp[index].rp_quantity = item.target.value;
              // temp[index].status = "update";
              // temp[index].statusUpdate = true;

              setStatusBill_Table({
                ...StatusBill_Table,
                data: temp,
              });

              StatusBill_Table.data = temp;
              // saveProductData();
              // calSummaryProductTable();
            }}
          />
          <select
            type="text"
            className="form-control"
            disabled={false}
            value={StatusBill_Table.data[index].rp_quantity_unit}
            onChange={(e) => {
              const temp = StatusBill_Table.data;

              temp[index].rp_quantity_unit = e.target.value;
              // temp[index].status = "update";
              // temp[index].statusUpdate = true;

              setStatusBill_Table({
                ...StatusBill_Table,
                data: temp,
              });

              StatusBill_Table.data = temp;
              // saveProductData();
              // calSummaryProductTable();
            }}
          >
            <option value="piece">Piece</option>
            <option value="pack">Pack</option>
            <option value="ctn">Ctn</option>
          </select>
        </div>
      );

      rowItem["his_management"] = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a className="btn btn-xs" onClick={() => handleRowDelete(index)}>
            <i className="fas fa-trash-alt" style={{ color: "red" }} />
          </a>
        </div>
      );

      rowsBill.push(rowItem);
    }
  }

  const bill_data = {
    columns: [
      {
        label: "Barcode",
        field: "barcode",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Quantity",
        field: "product_quantity",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Edit",
        field: "edit",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "his_management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsBill,
  };

  const [historyReq_Table, setHistoryReq_Table] = useState({
    data: [],
  });

  const rowsHis = [];
  for (let index = 0; index < historyReq_Table.data.length; index += 1) {
    const rowItem = {};

    rowItem["his_no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["his_quo_no"] = historyReq_Table.data[index].hr_no;

    rowItem["his_version"] = (
      <div style={{ textAlign: "end" }}>
        {historyReq_Table.data[index].hr_version}
      </div>
    );

    rowItem["his_management"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={historyReq_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/เบิกสินค้า_sale/ViewHistory/" +
                historyReq_Table.data[index].hr_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
      </div>
    );

    rowsHis.push(rowItem);
  }

  const history_data = {
    columns: [
      {
        label: "No.",
        field: "his_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Quotations No.",
        field: "his_quo_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Quotations Version",
        field: "his_version",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "his_management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsHis,
  };

  function sendApprove(data, approved) {
    console.log("sendApprove", data, approved);
  }
  const rowsData = [];
  for (let index = 0; index < Requisition_Table.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["Requisition_no"] = (
      <>
        {Requisition_Table[index].history_versions.length > 0 ? (
          <a
            className=""
            style={{ fontSize: "1.2rem", color: "blue", cursor: "pointer" }}
            data-toggle="modal"
            data-target="#modalQuotationCustomer"
            onClick={() => {
              setHistoryReq_Table({
                ...historyReq_Table,
                data: Requisition_Table[index].history_versions,
              });
              historyReq_Table.data = Requisition_Table[index].history_versions;
            }}
          >
            <u>{Requisition_Table[index].req_no}</u>
          </a>
        ) : (
          Requisition_Table[index].req_no
        )}
      </>
      /* Requisition_Table[index].req_no ? (
      <span style={{ whiteSpace: "pre-line" }}>
        {typeof Requisition_Table[index].req_no === "string"
          ? Requisition_Table[index].req_no
          : "-"}
      </span>
    ) : (
      "-"
    ) */
    );

    rowItem["customer"] = Requisition_Table[index].req_cus_name;

    rowItem["sales_name"] = Requisition_Table[index].req_created_name;

    rowItem["approved_name"] = Requisition_Table[index]
      .req_approve_user_name ? (
      <span style={{ whiteSpace: "pre-line" }}>
        {typeof Requisition_Table[index].req_approve_user_name === "string"
          ? Requisition_Table[index].req_approve_user_name
          : "-"}
      </span>
    ) : (
      "-"
    );
    if (
      Requisition_Table[index].rs_name === "Pending" ||
      Requisition_Table[index].rs_name === null
    ) {
      rowItem["status"] = (
        <>
          {/*  <div>
            <button
              type="button"
              style={{
                borderBlockColor: "#FFA500",
                color: "#FFA500",
                borderColor: "#FFA500",
              }}
              class="btn btn-block btn-outline-primary disabled"
            >
              Pending
            </button>
          </div> */}
          <div
            className="row"
            style={{
              flexWrap: "nowrap",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-12">
              <button
                type="button"
                style={{
                  borderBlockColor: "#FFA500",
                  color: "#FFA500",
                  borderColor: "#FFA500",
                  opacity: "1",
                  width: "100%",
                  border: "4px solid",
                  borderRadius: "10px",
                  paddingTop: ".2rem",
                  paddingBottom: ".2rem",
                }}
                class="btn btn-block btn-outline-primary disabled"
              >
                Pending
              </button>
            </div>
          </div>
        </>
      );
    } else if (Requisition_Table[index].rs_name === "Approve") {
      rowItem["status"] = (
        <>
          <div
            className="row"
            style={{
              flexWrap: "nowrap",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-12">
              <button
                type="button"
                style={{
                  borderBlockColor: "#2F42E8",
                  color: "#2F42E8",
                  borderColor: "#2F42E8",
                  opacity: "1",
                  width: "100%",
                  border: "4px solid",
                  borderRadius: "10px",
                  paddingTop: ".2rem",
                  paddingBottom: ".2rem",
                }}
                class="btn btn-block btn-outline-primary disabled"
              >
                Approve
              </button>
            </div>
          </div>
        </>
      );
    } else if (Requisition_Table[index].rs_name === "Not Approve") {
      rowItem["status"] = (
        <>
          <div
            className="row"
            style={{
              flexWrap: "nowrap",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-12">
              <button
                type="button"
                style={{
                  opacity: "1",
                  width: "100%",
                  border: "4px solid",
                  borderRadius: "10px",
                  paddingTop: ".2rem",
                  paddingBottom: ".2rem",
                }}
                class="btn btn-block btn-outline-danger disabled"
              >
                Not Approve
              </button>
            </div>
          </div>
        </>
      );
    } else if (Requisition_Table[index].rs_name === "Cancel") {
      rowItem["status"] = (
        <>
          <div
            className="row"
            style={{
              flexWrap: "nowrap",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-12">
              <button
                type="button"
                style={{
                  borderBlockColor: "#B22222",
                  color: "#B22222",
                  borderColor: "#B22222",
                  opacity: "1",
                  width: "100%",
                  border: "4px solid",
                  borderRadius: "10px",
                  paddingTop: ".2rem",
                  paddingBottom: ".2rem",
                }}
                class="btn btn-block btn-outline-primary disabled"
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      );
    }

    rowItem["status_bill"] = !Requisition_Table[index].req_rs_pack_id ? (
      ""
    ) : Requisition_Table[index].req_rs_pack_id !==
      "5b9217d8-5124-404e-a502-fbb9f0ebeff9" ? (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          type="button"
          disabled={true}
          style={{
            opacity: "1",
            width: "100%",
            border: "4px solid",
            borderRadius: "10px",
            paddingTop: ".2rem",
            paddingBottom: ".2rem",
          }}
          className={`btn btn-outline-${Requisition_Table[index].req_rs_pack_id ===
            "e243b1f7-380c-4f9b-9ce0-b2cc5c95ccaf" // จัดของสำเร็จ
            ? "success"
            : Requisition_Table[index].req_rs_pack_id ===
              "e468ce5d-8638-4c5a-8d82-1bf3c452804b" // รอจัดของ
              ? "warning"
              : Requisition_Table[index].req_rs_pack_id ===
                "5b9217d8-5124-404e-a502-fbb9f0ebeff9" // จัดของไม่สำเร็จ
                ? "danger"
                : "primary"
            }`}
        >
          {Requisition_Table[index].req_rs_pack_id ===
            "e243b1f7-380c-4f9b-9ce0-b2cc5c95ccaf" // จัดของสำเร็จ
            ? "จัดของสำเร็จ"
            : Requisition_Table[index].req_rs_pack_id ===
              "e468ce5d-8638-4c5a-8d82-1bf3c452804b" // รอจัดของ
              ? "รอจัดของ"
              : Requisition_Table[index].req_rs_pack_id ===
                "5b9217d8-5124-404e-a502-fbb9f0ebeff9" // จัดของไม่สำเร็จ
                ? "จัดของไม่สำเร็จ"
                : "ไม่พบ status นี้"}
        </button>
      </div>
    ) : (
      <>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <button
            type="button"
            disabled={false}
            style={{
              opacity: "1",
              width: "100%",
              border: "4px solid #dc3545",
              borderRadius: "10px",
              paddingTop: ".2rem",
              paddingBottom: ".2rem",
            }}
            className={`btn btn-danger`}
            data-toggle="modal"
            data-target="#modalPO_status"
            onClick={() => {
              setReqNo({
                ...ReqNo,
                data: Requisition_Table[index].req_no,
              });
              ReqNo.data = Requisition_Table[index].req_no;
              getBillfromREQ(Requisition_Table[index].req_id);
            }}
          >
            {"จัดของไม่สำเร็จ"}
          </button>
        </div>
      </>
    );

    rowItem["report"] = (
      <>
        <div>
          <button
            type="button"
            className="btn btn-block btn-primary"
            disabled={
              Requisition_Table[index].history_versions.length > 0
                && (Requisition_Table[index].rs_name === "Approve")
                ? false
                : true
            }
            onClick={() => {
              Swal.fire({
                title: "คุณต้องการจะออก Report นี้ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await getReport(Requisition_Table[index].history_versions);
                }
              });
            }}
          >
            Report
          </button>
        </div>
      </>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Requisition_Table[index].req_id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/เบิกสินค้า_sale/view/" +
                Requisition_Table[index].req_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
          hidden={
            Requisition_Table[index].rs_name === "Success" ||
              Requisition_Table[index].rs_name === "Cancel" ||
              Requisition_Table[index].req_sent_approve === true ||
              Requisition_Table[index].req_rs_pack_id ===
              "e243b1f7-380c-4f9b-9ce0-b2cc5c95ccaf"
              ? true
              : false
          }
        >
          <a
            key={Requisition_Table[index].req_id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/เบิกสินค้า_sale/edit/" +
                Requisition_Table[index].req_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        {/* <div
          className="col-xl-3 col-md-3 col-xs-3 "
        >
          <a
            key={SalesOrder.data[index]}
            id={SalesOrder.data[index]}
            className=" btn btn-xs "
            onClick={delKitting.bind(this, SalesOrder.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div> */}
      </div>
    );

    rowsData.push(rowItem);
  }

  const Requisition_data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Requisition No.",
        field: "Requisition_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        // sort: "asc",
        width: 50,
      },
      /* {
        label: "Price",
        field: "price",
        // sort: "asc",
        width: 50,
      }, */
      {
        label: "Sales Name",
        field: "sales_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Approved Name",
        field: "approved_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status Bill",
        field: "status_bill",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };
  async function clear() {
    setStatus_list_filter([]);
    status_list_filter = [];
    setFilter({
      ...Filter,
      req_no: "",
      req_cus_id: "",
      req_rs_id: [],
    });
    Filter.req_company_id = "";
    Filter.req_no = "";
    Filter.req_cus_id = "";
    Filter.req_rs_id = [];
    GetRequisition();
  }
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>เบิกสินค้า (Sale)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sale</li>
                  <li className="breadcrumb-item active">เบิกสินค้า (Sale)</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.req_no}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        req_no: e.target.value,
                      });
                      Filter.req_no = e.target.value;
                    }}
                  />
                  <label>Requisition No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <Select
                    styles={{
                      control: base => ({
                        ...base,
                        height: 35,
                        minHeight: 39,
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 999,
                      }),
                    }}
                    required
                    value={
                      CustomerDD?.find(el => el.value === Filter.req_cus_id) || { label: "--Select--", value: "" }
                    }
                    options={CustomerDD}
                    filterOption={customFilter}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        req_cus_id: e.value,
                      });
                      Filter.req_cus_id = e.value;
                    }}
                  >
                    {/* <option value="">--Select--</option>
                    {CustomerDD.map((data) => {
                      return (
                        <option value={data.cus_id}>{data.cus_name}</option>
                      );
                    })} */}
                  </Select>
                  <label>Customer</label>
                </div>
              </div>
              {/* <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.req_created_name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        req_created_name: e.target.value,
                      });
                      Filter.req_created_name = e.target.value;
                    }}
                  />
                  <label>Sale Name</label>
                </div>
              </div> */}
              <div className="col-12 col-md-4 col-xl-2">
                {/* <label></label> */}
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    value={status_list_filter}
                    options={statusDD.data}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Status
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      GetRequisition();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Sale_cuteplut/เบิกสินค้า_sale/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Sale_cuteplut/เบิกสินค้า_sale/Add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>เบิกสินค้า (Sale)</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            disableRetreatAfterSorting={true}
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={Requisition_data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="modal fade"
        id="modalQuotationCustomer"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalfoodRedisterLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title" id="modalfoodRedisterLabel">
                <b>View Requisition (Sales)</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              {/* history_data */}
              <div className="row">
                <div className="col-12">
                  <h4>View Version Requisition</h4>
                </div>
              </div>
              <div className="table-responsive">
                <div className="row">
                  <div className="col-12 col-md-12 col-xl-12">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      disableRetreatAfterSorting={true}
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={history_data}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div
              className="modal-footer"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              // ref={closeModalFoodRegister}
              >
                Close
              </button>
              <></>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalPO_status"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalPO_status"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title" id="modalPO_status">
                <b>จัดของไม่สำเร็จ</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              {/* history_data */}
              <div className="row">
                <div className="col-12">
                  <h4>{ReqNo.data}</h4>
                </div>
              </div>
              <div className="table-responsive">
                <div className="row">
                  <div className="col-12 col-md-12 col-xl-12">
                    <MDBDataTable
                      sortable={false}
                      disableRetreatAfterSorting={true}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={bill_data}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div
              className="modal-footer"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                ref={closeModal}
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-primary"
                // data-dismiss="modal"
                onClick={saveOption}
              // ref={closeModalFoodRegister}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* 
  modalPO_status
   */}
    </div>
  );
}

export default Pick_Product_Table;
