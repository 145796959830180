import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getOem,
  getToken,
  getUser,
  getComConfig,
} from "../../../../Utils/Common";
import Configs from "../../../../config";
import moment from "moment";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import Invoice_report from "./Component/Invoice_report";

function Invoice_Table() {
  const [inputSearch, setInputSearch] = useState({
    invoice_no: "",
    customer_id: "",
  });

  const [customerDD, setCustomerDD] = useState({
    data: [],
  });

  const [invoiceData, setInvoiceData] = useState({
    data: [],
  });

  const [resultImportData, setResultImportData] = useState({
    data: [],
  });

  const [modalImportOC, setModalImportOC] = useState(false);

  const [modalResultOC, setModalResultOC] = useState(false);

  const [fileData, setFileData] = useState({
    data: [],
  });

  const [modalFileData, setModalFileData] = useState({
    data: [],
  });

  const [invoiceDataReport, setInvoiceDataReport] = useState({
    data: [],
    customer: [],
    delivery: [],
  });

  const allowedTypes = [
    "text/plain",
    // "image/png",
    // "image/jpeg",
    "text/csv",
    // "application/msword",
    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // "application/pdf",
    // "application/vnd.ms-excel",
    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithOutCommas = (x) => {
    return x.toString().replace(/,/g, "");
  };

  async function getCustomerDD() {
    const temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        setCustomerDD({ data: response.data });
        customerDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function filterInvoice() {
    const temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      invoice_no: inputSearch.invoice_no.trim() || null,
      customer_id: inputSearch.customer_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/filterInvoice",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        if (response.data) {
          setInvoiceData({
            ...invoiceData,
            data: response.data,
          });
          invoiceData.data = response.data;
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveImport() {
    // const temp = {
    //   oem_id: getOem(),
    //   company_id: getUser().com,
    //   user_id: getUser().fup,
    //   user_name: `${getUser().name} ${getUser().surname}`,
    // };

    let data = new FormData();
    data.append("company_id", getUser().com);
    data.append("oem_id", getOem());
    data.append("user_id", getUser().fup);
    data.append("user_name", `${getUser().name} ${getUser().surname}`);
    for (let file of fileData.data) {
      data.append("Profile", file);
      data.append("ProfileName", file.name);
    }

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/saveImport",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(async (response1) => {
        let tempData = {
          oem_id: getOem(),
          company_id: getUser().com,
          user_id: getUser().fup,
          user_name: `${getUser().name} ${getUser().surname}`,
          data: response1.data,
        };

        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/log_product/removeReserve",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(async function () {
            await axios({
              method: "post",
              url: Configs.API_URL_cuteplus + "/api/logistic/autoLogisticInOut",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempData,
            })
              .then(async function (response2) {
                await axios({
                  method: "post",
                  url:
                    Configs.API_URL_cuteplus_sales +
                    "/api/invoice/updateAutoInvDataLoInOut",
                  headers: {
                    Authorization: "Bearer " + getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                  },
                  data: { data: response2.data },
                })
                  .then(async function () {
                    setResultImportData({
                      data: response1.data,
                    });

                    resultImportData.data = response1.data;

                    setFileData({
                      data: [],
                    });

                    setModalFileData({
                      data: [],
                    });

                    setModalResultOC(true);
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: "Error",
                      text: error.response.data.error.message,
                      icon: "error",
                    });
                  });
                // setResultImportData({
                //   data: response1.data,
                // });

                // resultImportData.data = response1.data;

                // setFileData({
                //   data: [],
                // });

                // setModalFileData({
                //   data: [],
                // });

                // setModalResultOC(true);
              })
              .catch((error) => {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
            // setResultImportData({
            //   data: response1.data,
            // });

            // resultImportData.data = response1.data;

            // setFileData({
            //   data: [],
            // });

            // setModalFileData({
            //   data: [],
            // });

            // setModalResultOC(true);
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });

        // setResultImportData({
        //   data: response1.data,
        // });

        // resultImportData.data = response1.data;

        // setFileData({
        //   data: [],
        // });

        // setModalFileData({
        //   data: [],
        // });

        // setModalResultOC(true);
      })
      .catch(async (error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function delInvioce(invoice_id, saleOrder_id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "get",
          url:
            Configs.API_URL_cuteplus_sales +
            `/api/invoice/delInvioce/${invoice_id}/${saleOrder_id}`,
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(async (response) => {
            await filterInvoice();
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  async function getInvoiceDataForReport(id) {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      inv_id: id,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/getInvoiceReport",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        // console.log("INV", response.data);
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus_sales +
            "/api/invoice/getInvoiceProductForReport",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: response.data,
        })
          .then(async (response2) => {
            // console.log("Product_list", response2.data);
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus_sales +
                "/api/invoice/getInvoiceDiscountReport",
              headers: {
                Authorization: "Bearer " + getToken(),
                "Content-Type": "application/json",
                "X-TTT": Configs.API_TTT,
              },
              data: response2.data,
            })
              .then(async (response3) => {
                // console.log("DISCOUNT", response3.data);
                await axios({
                  method: "post",
                  url:
                    Configs.API_URL_cuteplus +
                    "/api/product/getProductForInvoiceReport",
                  headers: {
                    Authorization: "Bearer " + getToken(),
                    "Content-Type": "application/json",
                    "X-TTT": Configs.API_TTT,
                  },
                  data: response3.data,
                })
                  .then(async (response4) => {
                    // console.log("Product", response4.data);
                    setInvoiceDataReport({
                      data: response4.data,
                    });
                    invoiceDataReport.data = response4.data;
                  })
                  .catch((error) => {
                    Swal.fire({
                      title: "Error",
                      text: error.response.data.error.message,
                      icon: "error",
                    });
                  });
              })
              .catch((error) => {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    //GET CUSTOMER
    let newDetails = [];
    if (invoiceDataReport.data.length > 0) {
      if (invoiceDataReport.data[0].inv_cus_id !== null) {
        let data_cus = {
          oem_id: getOem(),
          company_id: getUser().com,
          cus_id: invoiceDataReport.data[0].inv_cus_id,
        };
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus +
            "/api/customer/getCustomerForInvoiceReport",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: data_cus,
        })
          .then((response) => {
            setInvoiceDataReport({
              ...invoiceDataReport,
              customer: response.data,
            });
            invoiceDataReport.customer = response.data;
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    }

    //GET DELIVERY SOURCE
    if (invoiceDataReport.data.length > 0) {
      if (invoiceDataReport.data[0].inv_ds_id !== null) {
        let data_delivery = {
          oem_id: getOem(),
          company_id: getUser().com,
          ds_id: invoiceDataReport.data[0].inv_ds_id,
        };
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/logistic/getDSInInv",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: data_delivery,
        })
          .then((response) => {
            setInvoiceDataReport({
              ...invoiceDataReport,
              delivery: response.data,
            });
            invoiceDataReport.delivery = response.data;
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    }
    try {
      Invoice_report(company, invoiceDataReport, getComConfig());
    } catch (error) {
      console.log(error);
    }
  }

  async function getReport() {
    axios(config_com)
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const [company, setCompany] = useState();
  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  const rowsData1 = [];
  for (let index = 0; index < invoiceData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["invoice_no"] = invoiceData.data[index].inv_no || "";

    rowItem["so_no"] = invoiceData.data[index].inv_so_no || "";

    rowItem["customer"] = invoiceData.data[index].inv_cus_name || "";

    rowItem["price"] = (
      <div style={{ textAlign: "right" }}>
        {parseFloat(invoiceData.data[index].inv_total_price).toLocaleString(
          undefined,
          { minimumFractionDigits: 2, maximumFractionDigits: 2 } || 0
        )}
      </div>
    );

    // rowItem["priceSearch"] =
    //   <div style={{ textAlign: "right" }}>
    //     {invoiceData.data[index].inv_total_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //   </div>;

    rowItem["sales_name"] = invoiceData.data[index].inv_created_name || "";

    rowItem["contact_name"] = invoiceData.data[index].inv_contact_name || "";

    rowItem["status"] = invoiceData.data[index].inv_is_id ? (
      <button
        type="button"
        disabled={true}
        style={{
          opacity: "1",
          width: "100%",
          border: "4px solid",
          borderRadius: "10px",
          paddingTop: ".2rem",
          paddingBottom: ".2rem",
        }}
        className={`${
          invoiceData.data[index].inv_is_id ===
          "4873de5e-5472-437c-b349-a17fb619a965"
            ? "btn btn-outline-success"
            : "btn btn-outline-danger"
        }`}
      >
        {invoiceData.data[index].inv_is_id ===
        "4873de5e-5472-437c-b349-a17fb619a965"
          ? "สำเร็จ"
          : "ยกเลิก"}
      </button>
    ) : (
      ""
    );

    rowItem["report"] = (
      <div>
        <button
          key={invoiceData.data[index].inv_id}
          type="button"
          className="btn btn-block btn-primary"
          onClick={() => {
            Swal.fire({
              title: "คุณต้องการจะออก Report นี้ใช่หรือไม่",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: `ใช่`,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: `ไม่`,
            }).then(async (result) => {
              if (result.isConfirmed) {
                getInvoiceDataForReport(invoiceData.data[index].inv_id);
              }
            });
          }}
        >
          Report
        </button>
      </div>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-4 col-md-4 col-xs-4 ">
          <a
            key={invoiceData.data[index].inv_id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Invoice_Cuteplus/View/" +
                invoiceData.data[index].inv_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>

        {invoiceData.data[index].inv_is_shipping === false &&
        invoiceData.data[index].inv_is_id ===
          "4873de5e-5472-437c-b349-a17fb619a965" ? (
          <>
            <div
              className="col-xl-4 col-md-4 col-xs-4 "
              // hidden={
              //   Quotation_Table.data[index].qs_name === "Success" ||
              //   Quotation_Table.data[index].qs_name === "Cancel" ||
              //   Quotation_Table.data[index].quo_sent_approve === true
              //     ? true
              //     : false
              // }
              // style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }}
            >
              <a
                key={invoiceData.data[index].inv_id}
                id="btn_delete"
                className=" btn btn-xs "
                onClick={() => {
                  window.location.href =
                    "/Warehouse_Management/Invoice_Cuteplus/Edit/" +
                    invoiceData.data[index].inv_id;
                }}
              >
                <i class="fas fa-pencil-alt"></i>
              </a>
            </div>

            {/* <div
              className="col-xl-4 col-md-4 col-xs-4 " 
              // style={{ visibility: perchase_po[index].statusAll.deleteBtn ? "hidden" : "visible" }}
            >
              <a
                key={invoiceData.data[index].inv_id}
                // id={invoiceData.data[index].inv_id}
                className=" btn btn-xs "
                onClick={async () => {
                  await delInvioce(
                    invoiceData.data[index].inv_id,
                    invoiceData.data[index].inv_so_id
                  );
                }}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div> */}
          </>
        ) : (
          <></>
        )}
      </div>
    );

    rowsData1.push(rowItem);
  }

  const invoice_table = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Invoice No.",
        field: "invoice_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "SO No.",
        field: "so_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Price",
        field: "price",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Sales Name",
        field: "sales_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Contact Name",
        field: "contact_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData1,
  };

  const rowsData2 = [];
  for (let index = 0; index < resultImportData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["file_name"] = resultImportData.data[index].file_name;

    if (resultImportData.data[index].status === "add") {
      rowItem["status"] = (
        <Button disabled color="btn btn-success w-100" style={{ opacity: 1 }}>
          Success
        </Button>
      );
    } else if (resultImportData.data[index].status === "update") {
      rowItem["status"] = (
        <Button disabled color="btn btn-warning w-100" style={{ opacity: 1 }}>
          Update
        </Button>
      );
    } else if (resultImportData.data[index].status === "error") {
      rowItem["status"] = (
        <Button disabled color="btn btn-danger w-100" style={{ opacity: 1 }}>
          Error
        </Button>
      );
    }

    rowItem["remark"] = resultImportData.data[index].error;

    rowsData2.push(rowItem);
  }

  const resultImport_table = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "File Name",
        field: "file_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData2,
  };

  const clearSearch = async () => {
    setInputSearch({
      invoice_no: "",
      customer_id: "",
    });

    inputSearch.invoice_no = "";
    inputSearch.customer_id = "";

    await filterInvoice();
  };

  useEffect(async () => {
    await getCustomerDD();
    await filterInvoice();
    await getReport();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Invoice</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Invoice</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={inputSearch.invoice_no}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        invoice_no: e.target.value,
                      });

                      inputSearch.invoice_no = e.target.value;
                    }}
                  />
                  <label>Invoice No.</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    type="text"
                    className="form-control"
                    required
                    value={inputSearch.customer_id}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        customer_id: e.target.value,
                      });

                      inputSearch.customer_id = e.target.value;
                    }}
                  >
                    <option key="" value="">
                      Select a customer
                    </option>

                    {customerDD.data.map((option, index) => (
                      <option key={option.cus_name} value={option.cus_id}>
                        {option.cus_name}
                      </option>
                    ))}
                  </select>
                  <label>Customer</label>
                </div>
              </div>
              {/* </div>

            <div className="row"> */}
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await filterInvoice();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={async () => {
                      setModalImportOC(true);
                    }}
                  >
                    Import Express
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Invoice</h4>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{ overflow: "overlay" }}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={invoice_table}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={modalImportOC}
        toggle={() => {
          setModalFileData({
            data: [],
          });

          setFileData({
            data: [],
          });

          setModalImportOC(false);
        }}
        size="md"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModalFileData({
              data: [],
            });

            setFileData({
              data: [],
            });

            setModalImportOC(false);
          }}
        >
          <h3>Import</h3>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-4">
              <label>
                File Upload <span style={{ color: "red" }}>*</span>
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    // disabled={isDisabled}
                    id="exampleInputFile"
                    accept={allowedTypes}
                    multiple
                    onChange={(e) => {
                      if (e.target.files.length !== 0) {
                        const tempData1 = [];
                        const tempData2 = [];

                        for (let item of e.target.files) {
                          if (allowedTypes.includes(item?.type)) {
                            tempData1.push(item);

                            tempData2.push({
                              File_name: item.name,
                              File_path: URL.createObjectURL(item),
                            });

                            setFileData({
                              data: tempData1,
                            });
                            fileData.data = tempData1;

                            setModalFileData({
                              data: tempData2,
                            });
                            modalFileData.data = tempData2;
                          } else {
                            Swal.fire(
                              "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
                              "กรุณาอัปโหลดประเภทไฟล์ CSV, TEXT",
                              "error"
                            );

                            setFileData({
                              data: [],
                            });

                            setModalFileData({
                              data: [],
                            });

                            document.getElementById("exampleInputFile").value =
                              null;

                            break;
                          }
                        }
                      } else {
                        setFileData({
                          data: [],
                        });

                        setModalFileData({
                          data: [],
                        });

                        document.getElementById("exampleInputFile").value =
                          null;
                      }
                    }}
                  />
                  <label
                    className="custom-file-label textVerticalOverflow-1"
                    htmlFor="exampleInputFile"
                  >
                    {modalFileData.data.map((item) => {
                      return <span>{item.File_name + ", "}</span>;
                    })}
                    {}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setFileData({
                data: [],
              });

              setModalFileData({
                data: [],
              });

              setModalImportOC(false);
            }}
          >
            ยกเลิก
          </Button>
          {File !== null ? (
            <Button
              color="btn btn-info"
              onClick={async () => {
                setModalImportOC(false);

                await saveImport();
              }}
            >
              บันทึก
            </Button>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalResultOC}
        toggle={() => {
          setModalResultOC(false);

          setResultImportData({
            data: [],
          });

          window.location.reload();
        }}
        size="xl"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModalResultOC(false);

            setResultImportData({
              data: [],
            });

            window.location.reload();
          }}
        >
          <h3>Import Excel</h3>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-md-12" style={{ overflow: "overlay" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={resultImport_table}
                />
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="btn btn-info"
            onClick={async () => {
              setModalResultOC(false);

              setResultImportData({
                data: [],
              });

              window.location.reload();
            }}
          >
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Invoice_Table;
