import React, { useCallback, useEffect, useRef, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import Oem from "../../component/OEM";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import {
  getUser,
  getToken,
  setOemlist,
  setOemlist_,
  getOem,
  removeUserSession,
  removeOem,
  getOemlist_,
  getComConfig,
} from "../../Utils/Common";
import { Button, Modal, Input, Tree } from "antd";
import "./FavoriteStyles.css";
import PMRP_NoImage from "../../assets/img/Landing_Page/PMRP-Noimage.png";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { ListManager } from "react-beautiful-dnd-grid";
import { ListManager } from "react-beautiful-dnd-grid";
const { Search } = Input;

const treeData = [];
function Favorite_Table(params) {
  const [favorite, setFavorite] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [originalFavorite, setOriginalFavorite] = useState(favorite);
  const menuFavoriteIdRef = useRef(null);
  const checkedKeyListRef = useRef([]);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menu, setMenu] = useState({});
  const [menuL1, setMenuL1] = useState([]);
  const [menuL2, setMenuL2] = useState([]);
  const [menuL3, setMenuL3] = useState([]);
  const [treeDataReal, settreeDataReal] = useState([]);
  const [menuFavoriteOfUser, setMenuFavoriteOfUser] = useState({
    data: [],
  });
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([
    /* '0-0-0', '0-0-1' */
  ]);
  const [checkedKeys, setCheckedKeys] = useState([
    /* '0-0-0' */
  ]);
  const [tempCheckdKeys, setTempCheckdKeys] = useState({ data: [] });
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const showModal = () => {
    setSearchValue("");
    const newTreeData = [...treeData].map((el) => el);
    settreeDataReal([...newTreeData]);
    setExpandedKeys([...defaultExpandedKeys]);

    const check_list = menuFavoriteOfUser.data.map((el) => el.favorite_menu_id);
    const _find = menuFavoriteOfUser.data.find((el) => el.favorite_is_fav);

    for (const [key, value] of Object.entries(menu)) {
      if (value.has_child) continue;
      value.is_check = check_list.includes(value.id);
    }

    setCheckedKeys(check_list);
    setTimeout(() => {
      if (_find) {
        menuFavoriteIdRef.current = _find.favorite_menu_id;
        const elements = document.querySelectorAll(
          ".start-icon-favorite-check"
        );
        elements.forEach((element) => {
          element.style.color = "#aaa69c";
        });
        document.getElementById(
          `start-icon-${_find.favorite_menu_id}`
        ).style.color = "#ffaf00";
      }
    }, 200);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    Swal.fire({
      title: "คุณต้องการที่จะบันทึกหรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `บันทึก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ยกเลิก`,
    }).then((result) => {
      if (result.isConfirmed) {
        const error_list = [];

        if (checkedKeys.length == 0) {
          error_list.push("กรุณาเลือกเมนู");
        }

        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e];
          }
          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "กำลังบันทึกข้อมูล",
            text: "Loading...",
            allowEscapeKey: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          let key_check = [];
          const sortedMenu = Object.values(menu).sort((a, b) => {
            if (a.sort_fav === undefined) return 1;
            if (b.sort_fav === undefined) return -1;
            return a.sort_fav - b.sort_fav;
          });

          for (const item of sortedMenu) {
            if (item.has_child) continue;
            if (item.is_check) {
              key_check.push(item.id);
            }
          }
          saveMenuFavoriteByUserId(key_check);
        }
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };
  const onCheck = (checkedKeysValue, info) => {
    // console.log("info :>> ", info);
    // console.log('checkedKeys :>> ', checkedKeys);
    // console.log('checkedKeysValue :>> ', checkedKeysValue);
    let key_check = [],
      checkedKeysValueTemp = [];

    for (let item of checkedKeysValue) {
      if (menu[item] && !menu[item].has_child) {
        menu[item].is_check = true;
        key_check.push(item);
      }

      if (!menu[item].has_child) {
        checkedKeysValueTemp.push(item);
      }
    }

    if (checkedKeys.length > checkedKeysValueTemp.length) {
      const idCheckOutList = checkedKeys.filter(
        (el) => !checkedKeysValueTemp.includes(el)
      );
      // console.log('idCheckOutList :>> ', idCheckOutList);
      for (let item of idCheckOutList) {
        if (menu[item]) {
          menu[item].is_check = false;
        }
      }
    }
    /* if (!searchValue.trim()) {
    } else {
      if (checkedKeys.length > checkedKeysValue.length) {
        const idCheckOutList = checkedKeys.filter(
          (el) => !checkedKeysValue.includes(el)
        );
        for (let item of idCheckOutList) {
          if (menu[item]) {
            menu[item].is_check = false;
          }
        }
      }

      for (let item of checkedKeysValue) {
        if (menu[item] && !menu[item].has_child) {
          menu[item].is_check = true;
          key_check.push(item);
        }
      }
    } */

    let not_found_fav_id = true;
    for (const [key, value] of Object.entries(menu)) {
      if (value.has_child) continue;
      if (value.is_check /*  && !key_check.includes(value.id) */) {
        // key_check.push(value.id);
        // console.log('value.id :>> ', value.id);
      }
      if (value.is_check && menuFavoriteIdRef.current == value.id) {
        not_found_fav_id = false;
        break;
      }
    }

    setCheckedKeys(key_check);
    checkedKeyListRef.current = [...key_check];

    if (not_found_fav_id) {
      const elements = document.querySelectorAll(".start-icon-favorite-check");
      elements.forEach((element) => {
        element.style.color = "#aaa69c";
      });
      menuFavoriteIdRef.current = null;
    }
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  const getParentKey = useCallback((key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  }, []);

  const onChange =
    /* useCallback( */
    (e) => {
      const { value } = e.target;
      const newExpandedKeys = [];
      settreeDataReal([...treeData]);

      if (value.trim()) {
        if (!searchValue.trim()) {
          setTempCheckdKeys({ data: [...checkedKeys] });
          tempCheckdKeys.data = [...checkedKeys];
        }

        const l1_id_list = [],
          l2_id_list = [],
          l3_id_list = [];
        for (let item of menuL1) {
          if (
            item.display_name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            l1_id_list.push({
              id: item.id,
              parent_id: item.parent_id,
            });
          }
        }

        for (let item of menuL2) {
          if (
            item.display_name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            l2_id_list.push({
              id: item.id,
              parent_id: item.parent_id,
            });
          }
        }
        // console.log('menuL3 :>> ', menuL3);
        console.log("menuL1 :>> ", menuL1);
        for (let item of menuL3) {
          if (
            item.display_name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            const parent_of_parent_id =
              menuL2.find((el) => el.id === item.parent_id)?.parent_id || null;
            l3_id_list.push({
              id: item.id,
              parent_id: item.parent_id,
              parent_of_parent_id:
                menuL1.find((el) => el.id === parent_of_parent_id)?.id || null,
            });
          }
        }
        // console.log('l3_id_list :>> ', l3_id_list);
        // console.log('l2_id_list :>> ', l2_id_list);
        // console.log('l1_id_list :>> ', l1_id_list);

        const expandedKeysTemp = [],
          expandedKeysObj = {};
        for (let item of l1_id_list) {
          if (!expandedKeysObj[item.id]) {
            expandedKeysTemp.push(item.id);
            expandedKeysObj[item.id] = item.id;

            if (!l2_id_list.some((el) => el.parent_id == item.id)) {
              l2_id_list.push(
                ...menuL2
                  .filter((el) => el.parent_id == item.id)
                  .map((el) => ({ id: el.id, parent_id: item.id }))
              );
            }
          }
        }
        for (let item of l3_id_list) {
          if (!expandedKeysObj[item.id]) {
            expandedKeysTemp.push(item.id);
            expandedKeysObj[item.id] = item.id;
          }

          if (item.parent_id && !expandedKeysObj[item.parent_id]) {
            expandedKeysTemp.push(item.parent_id);
            expandedKeysObj[item.parent_id] = item.parent_id;
          }

          if (
            item.parent_of_parent_id &&
            !expandedKeysObj[item.parent_of_parent_id]
          ) {
            expandedKeysTemp.push(item.parent_of_parent_id);
            expandedKeysObj[item.parent_of_parent_id] =
              item.parent_of_parent_id;
          }
        }

        for (let item of l2_id_list) {
          if (!expandedKeysObj[item.id]) {
            expandedKeysTemp.push(item.id);
            expandedKeysObj[item.id] = item.id;

            expandedKeysTemp.push(
              ...menuL3
                .filter(
                  (el) => el.parent_id == item.id && !expandedKeysObj[el.id]
                )
                .map((el) => el.id)
            );
          }

          if (item.parent_id && !expandedKeysObj[item.parent_id]) {
            expandedKeysTemp.push(item.parent_id);
            expandedKeysObj[item.parent_id] = item.parent_id;
          }
        }

        function filterChild(tree, arr_push) {
          for (let index = 0; index < tree.children.length; index++) {
            const item = { ...tree.children[index] };
            if (expandedKeysTemp.includes(item.key)) {
              if (item.children && item.children.length > 0) {
                item.children = filterChild({ ...item }, []);
              }
              arr_push.push({ ...item });
            }
          }
          return arr_push;
        }

        const data = [];
        for (let index = 0; index < treeData.length; index++) {
          const item = { ...treeData[index] };

          if (expandedKeysTemp.includes(item.key)) {
            if (item.children && item.children.length > 0) {
              item.children = filterChild({ ...item }, []);
            }
            data.push({ ...item });
          }
        }
        let key_check = checkedKeys.filter((el) =>
          expandedKeysTemp.includes(el)
        );
        setCheckedKeys(key_check);
        settreeDataReal(data);
        setExpandedKeys([...expandedKeysTemp]);
      } else {
        const newTreeData = [...treeData].map((el) => el);
        settreeDataReal([...newTreeData]);

        let key_check = [];
        for (const [key, value] of Object.entries(menu)) {
          if (value.has_child) continue;
          if (value.is_check) {
            // console.log('value.id):>> ', value.id);
            key_check.push(value.id);
          }
        }

        setCheckedKeys(key_check);
        newExpandedKeys.push(...defaultExpandedKeys);
        setExpandedKeys(newExpandedKeys);
      }

      if (menuFavoriteIdRef.current) {
        setTimeout(() => {
          const menu_fav_el = document.getElementById(
            `start-icon-${menuFavoriteIdRef.current}`
          );
          if (menu_fav_el) {
            menu_fav_el.style.color = "#ffaf00";
          }
        }, 200);
      }
      // console.log('newExpandedKeys :>> ', newExpandedKeys);
      setSearchValue(value);
      setAutoExpandParent(true);
    }; /* ,
    [treeData, defaultExpandedKeys, getParentKey]
  ); */

  async function GetMenu(params) {
    await axios({
      method: "get",
      url:
        Configs.API_URL +
        "/permission/getMenuByUserID?user_id=" +
        getUser().fup,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        const menu_temp = response.data;
        const menu_temp_obj = {};
        const l1 = [],
          l2 = [],
          l3 = [];
        let check_key_dup = {};
        for (let item of menu_temp) {
          item.has_child = false;
          if (check_key_dup[item.id]) continue;
          check_key_dup[item.id] = item.id;

          const _find = menuFavoriteOfUser.data.find(
            (el) => el.favorite_menu_id == item.id
          );
          item.is_check = _find ? true : false;

          if (_find) {
            item.sort_fav = _find.favorite_sort;
          }

          if (item.level == 1) {
            // key_expenses.push(item.id);
            item.has_child = menu_temp.some((el) => el.parent_id == item.id);
            l1.push(item);
          }

          if (item.level == 2) {
            // key_expenses.push(item.id);
            item.has_child = menu_temp.some((el) => el.parent_id == item.id);
            l2.push(item);
          }

          if (item.level == 3) {
            l3.push(item);
          }

          menu_temp_obj[item.id] = item;
        }

        if (
          getOemlist_() === "Komatsu" &&
          getComConfig().com_name === "SHIMIZU Manufacturing Co., Ltd."
        ) {
          var indexK2 = l3.findIndex((e3) => e3.name === "Raw Material");

          if (indexK2 > -1) {
            l3.splice(indexK2, 1);
          }
        }
        if (
          getOemlist_() === "KPTT ( Japan, America )" &&
          getComConfig().com_name === "Central Spring co., Ltd."
        ) {
          var indexK2 = l3.findIndex((e3) => e3.name === "Raw Material");
          if (indexK2 > -1) {
            l3.splice(indexK2, 1);
          }
        }
        if (getOemlist_() !== "บจก.เอสทีสตีล(2001) ปัตตานี") {
          var check1 = l2.filter((e1) => {
            return e1.name === "RawMatSTP_InOut";
          });
          if (check1.length > 0) {
            var index1 = l2.findIndex((e1) => e1.name === "RawMatSTP_InOut");
            l2.splice(index1, 1);
          }
          var check2 = l2.filter((e2) => {
            return e2.name === "FG STP IN/OUT";
          });
          if (check2.length > 0) {
            var index2 = l2.findIndex((e2) => e2.name === "FG STP IN/OUT");
            l2.splice(index2, 1);
          }
          var check3 = l2.filter((e3) => {
            return e3.name === "Deflux & WIP IN/OUT";
          });
          if (check3.length > 0) {
            var index3 = l2.findIndex(
              (e3) => e3.name === "Deflux & WIP IN/OUT"
            );
            l2.splice(index3, 1);
          }
        } else {
          var check1 = l2.filter((e1) => {
            return e1.name === "Raw Mat IN/OUT";
          });
          if (check1.length > 0) {
            var index1 = l2.findIndex((e1) => e1.name === "Raw Mat IN/OUT");
            l2.splice(index1, 1);
          }
          var check2 = l2.filter((e2) => {
            return e2.name === "F/G IN/OUT";
          });
          if (check2.length > 0) {
            var index2 = l2.findIndex((e2) => e2.name === "F/G IN/OUT");
            l2.splice(index2, 1);
          }
        }

        function getTree(title, key, children = undefined) {
          return {
            title,
            key,
            children: children || [],
          };
        }

        const key_expenses = [];
        const tempTreeData = [],
          tempTreeData2 = [];
        for (let _l1 of l1) {
          const itemTree = getTree(
            !_l1.has_child ? (
              <span>
                {_l1.display_name}{" "}
                <i
                  class="ri-star-fill start-icon-favorite-check"
                  id={`start-icon-${_l1.id}`}
                  style={{ cursor: "pointer", color: "#aaa69c" }}
                  onClick={() => {
                    if (!checkedKeyListRef.current.includes(_l1.id)) {
                      checkedKeyListRef.current.push(_l1.id);
                    }
                    const elements = document.querySelectorAll(
                      ".start-icon-favorite-check"
                    );
                    elements.forEach((element) => {
                      element.style.color = "#aaa69c";
                    });

                    if (
                      menuFavoriteIdRef.current &&
                      menuFavoriteIdRef.current === _l1.id
                    ) {
                      menuFavoriteIdRef.current = null;
                    } else {
                      document.getElementById(
                        `start-icon-${_l1.id}`
                      ).style.color = "#ffaf00";

                      menuFavoriteIdRef.current = _l1.id;
                    }
                  }}
                ></i>{" "}
                {/* Add icon to leaf */}
              </span>
            ) : (
              _l1.display_name
            ),
            _l1.id
          );

          if (_l1.has_child) {
            key_expenses.push(_l1.id);
            itemTree.children = l2
              .filter((el2) => {
                if (el2.parent_id == _l1.id) {
                  if (el2.has_child) {
                    key_expenses.push(el2.id);
                    el2.children = l3
                      .filter((el3) => {
                        if (el3.parent_id == el2.id) {
                          return el3;
                        }
                      })
                      .map((el3) =>
                        getTree(
                          <span>
                            {el3.display_name}{" "}
                            <i
                              class="ri-star-fill start-icon-favorite-check"
                              id={`start-icon-${el3.id}`}
                              style={{ cursor: "pointer", color: "#aaa69c" }}
                              onClick={() => {
                                if (
                                  !checkedKeyListRef.current.includes(el3.id)
                                ) {
                                  checkedKeyListRef.current.push(el3.id);
                                }
                                const elements = document.querySelectorAll(
                                  ".start-icon-favorite-check"
                                );
                                elements.forEach((element) => {
                                  element.style.color = "#aaa69c";
                                });
                                if (
                                  menuFavoriteIdRef.current &&
                                  menuFavoriteIdRef.current === el3.id
                                ) {
                                  menuFavoriteIdRef.current = null;
                                } else {
                                  document.getElementById(
                                    `start-icon-${el3.id}`
                                  ).style.color = "#ffaf00";

                                  menuFavoriteIdRef.current = el3.id;
                                }
                              }}
                            ></i>{" "}
                            {/* Add icon to leaf */}
                          </span>,
                          el3.id
                        )
                      );
                  }
                  return el2;
                }
              })
              .map((el2) =>
                getTree(
                  !el2.has_child ? (
                    <span>
                      {el2.display_name}{" "}
                      <i
                        class="ri-star-fill start-icon-favorite-check"
                        id={`start-icon-${el2.id}`}
                        style={{ cursor: "pointer", color: "#aaa69c" }}
                        onClick={() => {
                          if (!checkedKeyListRef.current.includes(el2.id)) {
                            checkedKeyListRef.current.push(el2.id);
                          }
                          const elements = document.querySelectorAll(
                            ".start-icon-favorite-check"
                          );
                          elements.forEach((element) => {
                            element.style.color = "#aaa69c";
                          });
                          if (
                            menuFavoriteIdRef.current &&
                            menuFavoriteIdRef.current === el2.id
                          ) {
                            menuFavoriteIdRef.current = null;
                          } else {
                            document.getElementById(
                              `start-icon-${el2.id}`
                            ).style.color = "#ffaf00";

                            menuFavoriteIdRef.current = el2.id;
                          }
                        }}
                      ></i>{" "}
                      {/* Add icon to leaf */}
                    </span>
                  ) : (
                    el2.display_name
                  ),
                  el2.id,
                  el2.children && el2.children.length > 0 ? el2.children : null
                )
              );
          }

          tempTreeData.push({ ...itemTree });
          tempTreeData2.push({ ...itemTree });
        }
        treeData.push(...tempTreeData);
        settreeDataReal([...tempTreeData2]);
        setDefaultExpandedKeys(key_expenses);
        setExpandedKeys(key_expenses);

        setMenuL1(l1);
        setMenuL2(l2);
        setMenuL3(l3);
        setMenu(menu_temp_obj);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getMenuFavorite = async (user_id) => {
    axios({
      method: "post",
      url: Configs.API_URL + "/api/favorite/menuFavorite",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(async function (response) {
        const sorted = response.data.sort(
          (a, b) => a.favorite_sort - b.favorite_sort
        );
        setFavorite(sorted);
        setOriginalFavorite(sorted);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const getMenuFavoriteByUserId = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/favorite/getMenuFavoriteByUserId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(async function (response) {
        if (response.data && Array.isArray(response.data)) {
          setMenuFavoriteOfUser({ data: response.data });
          menuFavoriteOfUser.data = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  const saveMenuFavoriteByUserId = async (checkedKeys_params) => {
    const session_user = getUser();
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/favorite/saveMenuFavoriteByUserId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        user_id: session_user.fup,
        company_id: session_user.com,
        oem_id: getOem(),
        menu_select: checkedKeys_params,
        menu_fav_id: menuFavoriteIdRef.current || null,
      },
    })
      .then(async function (response) {
        Swal.close();
        if (response.data) {
          // getMenuFavorite();
          // getMenuFavoriteByUserId();
          handleCancel();
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        Swal.close();
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  useEffect(async () => {
    await getMenuFavoriteByUserId();
    await GetMenu();
    getMenuFavorite();
  }, []);

  useEffect(() => {
    const data = [...new Set([...checkedKeys, ...checkedKeyListRef.current])];
    setCheckedKeys(data);

    if (menuFavoriteIdRef.current) {
      const _menu = { ...menu };
      _menu[menuFavoriteIdRef.current].is_check = true;
      setMenu(_menu);
    }
  }, [JSON.stringify(checkedKeyListRef.current)]);

  // const handleOnDragEnd = (result) => {
  //   if (!result.destination) return;

  //   const sourceIndex = result.source.index;
  //   const destinationIndex = result.destination.index;

  //   if (sourceIndex !== destinationIndex) {
  //     const reorderedItems = [...favorite];
  //     const [removedItem] = reorderedItems.splice(sourceIndex, 1);
  //     reorderedItems.splice(destinationIndex, 0, removedItem);

  //     setFavorite(reorderedItems);
  //   }

  //   setIsChanged(true);
  // };

  const reorderList = (sourceIndex, destinationIndex) => {
    // const { destination, source } = sourceIndex;

    // if (!destination || destination.index === source.index) return;

    // const reorderedItems = Array.from(favorite);
    // const [movedItem] = reorderedItems.splice(source.index, 1);
    // reorderedItems.splice(destination.index, 0, movedItem);

    // setFavorite(reorderedItems);


    if (destinationIndex === sourceIndex) {
      return;
    }
    const updatedList = [...favorite];
    const [movedItem] = updatedList.splice(sourceIndex, 1);
    updatedList.splice(destinationIndex, 0, movedItem);
    setFavorite(updatedList);
    setIsChanged(true);
  };

  const handleSaveDageAndDrop = () => {
    Swal.fire({
      title: `คุณต้องการที่จะบันทึกหรือไม่`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `บันทึก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: Configs.API_URL + "/api/favorite/updateSortMenuFavorite",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: {
            favorite,
          },
        })
          .then(async function (response) {
            setOriginalFavorite(favorite);
            setIsChanged(false);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  };

  const handleCancelDageAndDrop = () => {
    setFavorite(originalFavorite);
    setIsChanged(false);
  };

  const ListElement = ({ item }) => {
    const {
      favorite_menu_id,
      image_path,
      display_name,
      favorite_is_fav,
      path,
    } = item;

    return (
      <div style={{ maxWidth: "290px", minWidth: "290px" }}>
        <div className="favorite-item">
          <a href={"/" + path.split("/").reverse().join("/")}>
            <div style={{ textAlign: "center" }}>
              <div style={{ position: "relative" }}>
                <img
                  alt="..."
                  className="img-fluid oem-1"
                  src={
                    image_path
                      ? image_path.startsWith("data:image")
                        ? image_path
                        : Configs.API_URL_IMG + image_path
                      : PMRP_NoImage
                  }
                />
                {favorite_is_fav && (
                  <i
                    style={{
                      position: "absolute",
                      marginTop: "-8px",
                      marginLeft: "-8px",
                      backgroundColor: "#F1C410",
                      borderRadius: "100%",
                      width: "20px",
                      height: "20px",
                      color: "white",
                      fontSize: "13px",
                      textAlign: "center",
                      lineHeight: "20px",
                    }}
                    className="ri-star-line"
                  ></i>
                )}
              </div>
              <h3
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  wordWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {display_name}
              </h3>
            </div>
          </a>
        </div>
      </div>
    );
  };

  const maxItems = useRef(4);
  const listManegerRef = useRef(null);
  const [getColumnDrag, setGetColumnDrag] = useState({
    columnDrag: 4
  });
  const [hideDiv, setHideDiv] = useState(false);

  async function setSize() {
    setHideDiv(true);
    maxItems.current = 1;
    const divWidth = document.getElementById('item-id')?.offsetWidth;
    // console.log((210 * 4) - 1);

    let temp = Math.round(divWidth / 290);
    // let temp = divWidth >= (210 * 4) - 1 ? 4 : Math.floor(divWidth / 210);
    // console.log(temp);

    /* let value = 4;
    if (divWidth >= (210 * 2) - 1) {
      value = 1;
    } else if (divWidth >= (210 * 3) - 1) {
      value = 2;
    } else if (divWidth >= (210 * 4) - 1) {
      value = 3;
    } */
    setGetColumnDrag({ ...getColumnDrag, columnDrag: temp });
    getColumnDrag.columnDrag = temp;
    setHideDiv(false);
    /* setTimeout(() => {

      setHideDiv(!hideDiv)
    }, 100) */

  }

  useEffect(() => {
    window.addEventListener('resize', setSize);
    return () => window.removeEventListener('resize', setSize);
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Menu Favorite</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Menu Favorite</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={showModal}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              {/* favorite.length */}
              {isChanged && (
                <div className="row mt-2" style={{ marginBottom: "10px" }}>
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      onClick={handleSaveDageAndDrop}
                      className="btn btn-block btn-success"
                      style={{
                        marginBottom: "10px",
                      }}
                      id="btn-save"
                    >
                      บันทึก
                    </button>
                  </div>
                  <div className="col-6 col-md-4 col-xl-2">
                    <button
                      type="button"
                      onClick={handleCancelDageAndDrop}
                      style={{ marginBottom: "10px" }}
                      className="btn btn-block btn-danger"
                      id="btn-cancle"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              )}
              {/* {maxItems.current} / 
                {test.test}
              <div>
                <button
                  onClick={() => {
                    maxItems.current += 1;
                    const temp = test.test + 1;
                    setGetColumnDrag({ test: temp });
                    test.test = temp;
                    console.log('maxItems.current :>> ', maxItems.current);
                    console.log('test.test :>> ', test.test);
                  }}
                >Add MaxItems</button>
                <button
                  onClick={() => {
                    setHideDiv(!hideDiv)
                  }}
                >hide div</button>
              </div> */}
              <div className="card-body">
                <div id="item-id">
                  {!hideDiv && <ListManager
                    style={{ width: '100px' }}
                    rer={listManegerRef}
                    items={favorite}
                    direction="horizontal"
                    maxItems={getColumnDrag.columnDrag ? getColumnDrag.columnDrag : 1}
                    render={(item) => <ListElement item={item} />}
                    onDragEnd={reorderList}
                  />}
                </div>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
          {/* Modal */}
        </section>

        <Modal
          title="Select Menu Favorite"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={(_, { OkBtn, CancelBtn }) => (
            <div>
              {/* <OkBtn />
            <CancelBtn /> */}
              <div className="row mt-2">
                <div className="col-6">
                  <button
                    type="button"
                    onClick={handleOk}
                    className="btn btn-block btn-success"
                    style={{
                      marginBottom: "10px",
                    }}
                    id="btn-save"
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6">
                  <button
                    type="button"
                    onClick={handleCancel}
                    style={{ marginBottom: "10px" }}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          )}
        >
          <Search
            style={{
              marginBottom: 8,
            }}
            placeholder="Search"
            value={searchValue}
            onChange={onChange}
          />
          <div
            style={{
              height: "500px",
              overflow: "auto",
            }}
          >
            <Tree
              checkable
              className="overflow-tree-style"
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              defaultExpandAll={true}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
              treeData={treeDataReal}
            />
          </div>
        </Modal>

        {/* <section>
        <DragDropContext onDragEnd={(result) => onDragEnd(result.source, result.destination)}>
          <DraggableGrid
            items={items}
            renderItem={(item) => (
              <div className="grid-item">
                {item.content}
              </div>
            )}
            getItemId={(item) => item.id}
          />
        </DragDropContext>
        </section> */}
      </div>
    </div>
  );
}

export default Favorite_Table;
