import React, { Component, useEffect, useState ,useRef } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import axios from "axios";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import { Tooltip } from "antd";

import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
function ManageSupplierTable() {
  const [supplier, setSupplier] = useState([]);

  const [oemfix, setoemfix] = useState();
  const [feature, setfeature] = useState([]);
  // const [disable, setdisable] = useState(null);
  const [supp_option, setsupp_option] = useState([]);
  const [filler_supplier, setFiller_supplier] = useState({
    name: "",
    full_name: "",
    company_id: getUser().com,
  });
  // const options = [];
  const selectColor_model = useRef();


  const GetSupplier = async () => {
    //console.log(filler_supplier);
let tempData = {
  supplier_name: filler_supplier.name.trim(),
  supplier_id: filler_supplier.full_name,
  company_id: getUser().com,
}
    
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/manageSupplier/FilterSupplier",
      headers: {
        Authorization: 'Barer ' +getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        console.log(response.data.supplier_list);
        setSupplier(response.data.supplier_list);
        // response.data.forEach(el => {
        //   var element = {};
        //   element["value"] = el.full_name;
        //   element["label"] = el.full_name;
        //   options.push(element);
        // });
        // setsupp_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetSupplierOption = async () => {
    //console.log(filler_supplier);
let tempData = {
  supplier_name: "",
  supplier_id: "",
  company_id: getUser().com,
}
    
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/manageSupplier/FilterSupplier",
      headers: {
        Authorization: 'Barer ' +getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        //console.log(response.data);
        let options = [];
       // setSupplier(response.data);
       response.data.supplier_list.forEach(el => {
          var element = {};
          element["value"] = el.sup_id;
          element["label"] = el.sup_name;
          options.push(element);
        });
        setsupp_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetSupplier();
    GetSupplierOption();
  }, []);

  const clearFilter = async () => {
    await setFiller_supplier({
      ...filler_supplier,
      name: "",
      full_name:"",
    });

    filler_supplier.name = "";
    filler_supplier.full_name = "";
    selectColor_model.current.select.clearValue();
    GetSupplier();
  };
  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? '#87CEFA' : '#FFF',
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',

      };
    },

  };

  function addpage(params) {
    window.location.href =
      "/Human_Resource/intensive_TTT/Manage_Supplier/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL_incentive + "/api/manageSupplier/DeleteSupplierByID/" + id,
          headers: {
            Authorization: 'Barer ' +getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire({
                icon: "success",
                text: "เสร็จสิ้น",
                showConfirmButton: false,
                timer: 1500,
              }
              ).then(() => {
                window.location.href =
                "/Human_Resource/intensive_TTT/Manage_Supplier";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = supplier.map((item, index) => ({
    no: index + 1,
    Sup_name: (
      <Tooltip title={item.sup_abbr_name}>
        <div
          style={{
            width: "100%",
            minWidth: "150px", 
            maxWidth: "150px",  
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.sup_abbr_name}
        </div>
      </Tooltip>
    ),
    Sup_detail: (
      <Tooltip title={item.sup_detail}>
        <div
          style={{
            width: "100%",
            minWidth: "300px", 
            maxWidth: "300px",  
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item.sup_detail}
        </div>
      </Tooltip>
    ),
    Contact_name: (
      <div
        style={{
          width: "100%",
          minWidth: "150px", 
          maxWidth: "150px",  
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {item.sup_contact_name}
      </div>
    ),
    line: (
      <div
        style={{
          width: "100%",
          minWidth: "200px",  
          maxWidth: "200px", 
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {item.sup_line_id}
      </div>
    ),
    Sup_contact_tel: (
      <div
        style={{
          width: "100%",
          minWidth: "150px",  
          maxWidth: "150px",  
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <a href={"tel:" + item.sup_tel}>{item.sup_contact_tel}</a>
      </div>
    ),
    mgt: (
      <div className="row">
        <div className="col-4">
          <a href={"/Human_Resource/intensive_TTT/Manage_Supplier/read/" + item.sup_id} className="btn btn-xs">
            <i className="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-4">
          <a href={"/Human_Resource/intensive_TTT/Manage_Supplier/edit/" + item.sup_id} className="btn btn-xs">
            <i className="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-4">
          <a className="btn btn-xs" onClick={() => deldata(item.sup_id)}>
            <i className="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    ),
  }));
  const data = {
    columns: [{
      label: "No",
      field: "no",
      sort: "asc",
      // width: 50,
    },
    {
      label: "Supplier Name",
      field: "Sup_name",
      sort: "asc",
      // width: 50,
    },
    {
      label: "Supplier Detail",
      field: "Sup_detail",
      sort: "asc",
      // width: 50,
    },
    /* {
      label: "Supplier Full Name",
      field: "full_name",
      sort: "asc",
      width: 50,
    }, */
    {
      label: "Line ID",
      field: "line",
      sort: "asc",
      // width: 50,
    }, 
    {
      label: "Contact Name",
      field: "Contact_name",
      sort: "asc",
      // width: 50,
    },
    {
      label: "Tel",
      field: "Sup_contact_tel",
      sort: "asc",
      // width: 50,
    },
    
    {
      label: "Management",
      field: "mgt",
      sort: "asc",
      // width: 50,
    },],
    rows: rowsData,
  };


  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Supplier{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Supplier</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_supplier.name}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Supplier Name</label>{" "}
                </div>
              </div>

              <div className="col-md-3">
                  <div class="form-group"style={{ "z-index": "97" }}>
                    <Select
                      options={supp_option}
                      defaultValue={filler_supplier.full_name}
                      ref={selectColor_model}
                      onChange={(e) => {
                        if (e !== null) {
                        setFiller_supplier({
                          ...filler_supplier,
                          full_name: e.value,
                        });
                     
                      }

                      }}
                    />
                    <label htmlFor="">
                      Supplier Full Name
                      </label>
                  </div>
                
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetSupplier();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Supplier</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " style = {{whiteSpace: 'nowrap'}} /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
                searching = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*  <Footter/> */}
    </div>
  );
}

export default ManageSupplierTable;
