import React, { useRef } from "react";
import { useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import Price_List_Product from "./Component/Price_List_Product";
import Select from "react-select";

function Price_List_AEV({ mode }) {
  const { id } = useParams();
  const closeModalCustomer = useRef();
  const [disable, setdisable] = useState(null);
  const [pageMode, setPageMode] = useState("");
  const [priceListData, setPriceListData] = useState({
    pl_id: "",
    pl_no: "",
    pl_valid_unit: "",
    pl_cus_id: "",
    pl_cus_name: "",
    pl_cus_house_number: "",
    pl_cus_building: "",
    pl_swine: "",
    pl_soi: "",
    pl_road: "",
    pl_sub_district: "",
    pl_district: "",
    pl_province: "",
    pl_postcode: "",
    pl_pt_id: "",
    pl_term: "",
    pl_cus_remark: "",
    pl_remark: "",
    pl_lang: false, /// true is TH and flase is ENG
    pl_incl_vat: false,
    pl_total_price: "",
    pl_is_use: "",
    pl_created_date: "",
    pl_updated_date: "",
    pl_created_by: "",
    pl_updated_by: "",
    pl_created_name: "",
    pl_updated_name: "",
    pl_ct_id: "",
    pl_ct_name: "",
    price_product: [],
    pl_confirm_customer: false,
  });

  const [paymentTermData, setPaymentTermData] = useState({
    data: [],
  });

  const [on_change_cusName, setOn_change_cusName] = useState({
    data: "",
  });

  // const [quotation_upload_files, setQuotation_upload_files] = useState({
  //   data: [],
  //   new: [],
  //   old: [],
  //   del: [],
  // });

  const [inputDataTab2, setInputDataTab2] = useState({
    data: [],
  });

  const [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });

  const [functionSaveTab2, setFunctionSaveTab2] = useState({
    data: null,
  });

  // const [refQuotationDD, setRefQuotationDD] = useState({
  //   data: [],
  // });

  const [customerTypeData, setCustomerTypeData] = useState({
    data: [],
  });

  // const [is_cus_ref, setIs_cus_ref] = useState({
  //   data: "",
  // });

  // const allowedTypes = ["image/png", "image/jpeg"];
  // const allowedTypes2 = [
  //   "text/csv",
  //   "application/msword",
  //   "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //   "application/pdf",
  //   "application/vnd.ms-excel",
  //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   "image/png",
  //   "image/jpeg",
  // ];
  // const uploadRef = useRef(null);
  const [customerDD, setCustomerDD] = useState({
    dataNomap: [],
    data: [],
  });
  const [customerNameDD, setCustomerNameDD] = useState({
    data: [],
  });
  useEffect(async () => {
    await GetCustomerType();
    if (!id) {
      setPriceListData({
        ...priceListData,
        pl_no: "Auto Generate",
        pl_date: new Date(),
        pl_valid_unit: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        pl_ct_id: "fd5acfc9-6d49-45fc-b7bf-aba6e2536b93",
        pl_ct_name: "นิติบุคคล",
      });
      // await getCurrentQuotationNO();
    }
    if (id) {
      await getPriceListById();
      // await getFileQuotation();
    }
    await getCustomerDropDown();
    // await getQuotationRef();
    await GetPaymentTerm();
    await getProductLogAndDetails();
  }, []);

  useEffect(async () => {
    if (mode === "view") {
      setPageMode("View");
      setdisable(true);
    } else {
      setdisable(false);
      setPageMode(mode === "add" ? "Add" : "Edit");
    }
  }, []);

  const clickCloseModalCustomer = () => {
    closeModalCustomer.current.click();
  };

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  async function GetPaymentTerm() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getPaymentTerm",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setPaymentTermData({
          ...paymentTermData,
          data: response.data,
        });

        paymentTermData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCustomerType() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        setCustomerTypeData({
          ...customerTypeData,
          data: response.data,
        });
        customerTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const addCustomerDetails = async () => {
    const cus_id = priceListData.pl_cus_id;
    // const cus_name_id = priceListData.quo_ccp_id;
    const tempAddress = customerDD.dataNomap?.find((el) => el.cus_id === cus_id);
    // const tempCustomer =
    //   tempAddress.customerList.length > 0
    //     ? tempAddress.customerList.find((el) => el.ccp_id === cus_name_id)
    //     : [];
    // console.log('tempAddress, tempCustomer', tempAddress);
    let checkCusCommon = false;
    if (priceListData.pl_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
      checkCusCommon = true;
    }
    setPriceListData({
      ...priceListData,
      pl_cus_name: tempAddress.cus_name || "",
      pl_cus_house_number:
        tempAddress[
          checkCusCommon ? "cus_house_number" : "cus_branch_house_number"
        ] || "",
      pl_cus_building:
        tempAddress[checkCusCommon ? "cus_building" : "cus_branch_building"] ||
        "",
      pl_swine:
        tempAddress[checkCusCommon ? "cus_swine" : "cus_branch_swine"] || "",
      pl_soi: tempAddress[checkCusCommon ? "cus_soi" : "cus_branch_soi"] || "",
      pl_road:
        tempAddress[checkCusCommon ? "cus_road" : "cus_branch_road"] || "",
      pl_sub_district:
        tempAddress[
          checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"
        ] || "",
      pl_district:
        tempAddress[checkCusCommon ? "cus_district" : "cus_branch_district"] ||
        "",
      pl_province:
        tempAddress[checkCusCommon ? "cus_province" : "cus_branch_province"] ||
        "",
      pl_postcode:
        tempAddress[checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] ||
        "",
      pl_pt_id: tempAddress.cus_pt_id || null,
      pl_term: tempAddress.cus_term || "",
      pl_cus_remark: tempAddress.cus_remark || "",
      // quo_contract_name: tempCustomer.ccp_name || "",
      // quo_contract_email: tempCustomer.ccp_email || "",
      // quo_contract_tel: tempCustomer.ccp_tel || "",
      // quo_gp_standard: tempCustomer.ccp_gp_standard || "",
    });
    priceListData.pl_cus_name = tempAddress.cus_name || "";
    priceListData.pl_cus_house_number =
      tempAddress[
        checkCusCommon ? "cus_house_number" : "cus_branch_house_number"
      ] || "";
    priceListData.pl_cus_building =
      tempAddress[checkCusCommon ? "cus_building" : "cus_branch_building"] ||
      "";
    priceListData.pl_swine =
      tempAddress[checkCusCommon ? "cus_swine" : "cus_branch_swine"] || "";
    priceListData.pl_soi =
      tempAddress[checkCusCommon ? "cus_soi" : "cus_branch_soi"] || "";
    priceListData.pl_road =
      tempAddress[checkCusCommon ? "cus_road" : "cus_branch_road"] || "";
    priceListData.pl_sub_district =
      tempAddress[
        checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"
      ] || "";
    priceListData.pl_district =
      tempAddress[checkCusCommon ? "cus_district" : "cus_branch_district"] ||
      "";
    priceListData.pl_province =
      tempAddress[checkCusCommon ? "cus_province" : "cus_branch_province"] ||
      "";
    priceListData.pl_postcode =
      tempAddress[checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] ||
      "";
    priceListData.pl_pt_id = tempAddress.cus_pt_id || null;
    priceListData.pl_term = tempAddress.cus_term || "";
    priceListData.pl_cus_remark = tempAddress.cus_remark || "";
    // priceListData.pl_contract_name = tempCustomer.ccp_name || "";
    // priceListData.quo_contract_email = tempCustomer.ccp_email || "";
    // priceListData.quo_contract_tel = tempCustomer.ccp_tel || "";
    // priceListData.quo_gp_standard = tempCustomer.ccp_gp_standard || "";

    if (tempAddress.cus_name) {
      setOn_change_cusName({ data: tempAddress.cus_name });
      on_change_cusName.data = tempAddress.cus_name;
    }
  };

  const getCustomerDropDown = async () => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerAndNameDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        const customer_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id, cus_ct_id: el.cus_ct_id }));
        customer_dd.unshift({
          label: "--Select Customer--",
          value: "",
          cus_ct_id: null
        })
        setCustomerDD({ ...customerDD, data: customer_dd, dataNomap: response.data });
        customerDD.data = customer_dd;
        customerDD.dataNomap = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  // const getQuotationRef = async () => {
  //   let temp = {
  //     oem_id: getOem(),
  //     company_id: getUser().com,
  //     user_id: getUser().fup,
  //   };
  //   axios({
  //     method: "post",
  //     url: Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotationRef",
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "Content-Type": "application/json",
  //       "X-TTT": Configs.API_TTT,
  //     },
  //     data: temp,
  //   })
  //     .then((response) => {
  //       // console.log('response.data :>> ', response.data);
  //       setRefQuotationDD({ ...refQuotationDD, data: response.data });
  //       refQuotationDD.data = response.data;
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.response.data.error.message,
  //         icon: "error",
  //       });
  //     });
  // };

  // const getCurrentQuotationNO = async () => {
  //   let temp = {
  //     company_id: getUser().com,
  //     oem_id: getOem(),
  //   };
  //   await axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_cuteplus_sales + "/api/quotation/getCurrentQuotationNO",
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: temp,
  //   })
  //     .then(async function (response) {
  //       // console.log('response', response.data);
  //       setPriceListData({
  //         ...priceListData,
  //         quo_no: response.data,
  //         quo_date: new Date(),
  //       });
  //     })
  //     .catch(function (error) {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.response.data.error.message,
  //         icon: "error",
  //       });
  //     });
  // };

  // const sendApproveQuotation = () => {
  //   Swal.fire({
  //     title: "คุณมั่นใจว่าคุณจะ Send Approve Quotation นี้ใช่หรือไม่",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ไม่`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       let error_list = [];

  //       error_list = await checkErrorForSave();

  //       await functionSaveTab2.data();
  //       error_list.push(...errorListTab2.data);
  //       // console.log('inputDataTab2', inputDataTab2);

  //       if (error_list.length > 0) {
  //         var err_message = "";

  //         for (var e = 0; e < error_list.length; e++) {
  //           err_message += "<br/>" + error_list[e].message;
  //         }

  //         Swal.fire("Error", err_message, "error");
  //         return;
  //       } else {
  //         const temp = {
  //           company_id: getUser().com,
  //           oem_id: getOem(),
  //           user_id: getUser().fup,
  //           user_name: getUser().name + " " + getUser().surname,
  //           data: priceListData,
  //           products: inputDataTab2,
  //         };
  //         await axios({
  //           method: "post",
  //           url:
  //             Configs.API_URL_cuteplus_sales +
  //             `/api/quotation/sendApproveQuotation`,
  //           headers: {
  //             Authorization: "Bearer " + getToken(),
  //             "X-TTT": Configs.API_TTT,
  //             "Content-Type": "application/json",
  //           },
  //           data: temp,
  //         })
  //           .then(async function (response) {
  //             // console.log('response saveDrafQuotation -', response.data);
  //             await deleteImagesAndFilesOfProduct();

  //             Swal.fire({
  //               icon: "success",
  //               title: "Send Approve สำเร็จ",
  //               allowEscapeKey: false,
  //               allowOutsideClick: false,
  //               showConfirmButton: false,
  //               timer: 2000,
  //             }).then(() => {
  //               window.location.href =
  //                 "/Sale_cuteplut/Quotation_Sales_Cuteplut";
  //             });
  //           })
  //           .catch(function (error) {
  //             Swal.fire({
  //               title: "Error",
  //               text: error.response.data.error.message,
  //               icon: "error",
  //             });
  //           });
  //       }
  //     }
  //   });
  // };

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/Sale_cuteplut/Price_List";
        }
      });
    } else {
      window.location.href = "/Sale_cuteplut/Price_List";
    }
  }

  // function isFileImage(file) {
  //   return file && allowedTypes2.includes(file["type"]);
  // }

  // async function encodeImage(file) {
  //   // console.log("file", file);
  //   if (allowedTypes2.includes(file[0]?.type) === false) {
  //     Swal.fire(
  //       "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
  //       "กรุณาอัปโหลดประเภทไฟล์ใหม่",
  //       "error"
  //     );
  //     return;
  //   }
  //   if (file[0].name) {
  //     const regex = /([\u0E00-\u0E7Fก-๙]+)/gmu;
  //     const str = file[0].name;
  //     const m = regex.exec(str);

  //     if (isFileImage(file[0]) === false) {
  //       Swal.fire({
  //         title: "ประเภทไฟล์ไม่ถูกต้อง",
  //         text: "",
  //         icon: "warning",
  //         confirmButtonColor: "#032973",
  //         confirmButtonText: "ตกลง",
  //       });
  //     } else {
  //       const oleTempFileUpload = quotation_upload_files.data;
  //       setQuotation_upload_files({ ...quotation_upload_files, data: [] });

  //       // const newFileUpload = quotaion_save_files.new;
  //       if (file.length > 0) {
  //         for (let index = 0; index < file.length; index += 1) {
  //           const tempDataImg = {
  //             qou_document_name: file[index].name,
  //             qou_path_document: URL.createObjectURL(file[index]),
  //             qou_image: file[index],
  //             oldNew: "new",
  //             status: "add",
  //           };
  //           // console.log('index', index);
  //           oleTempFileUpload.push(tempDataImg);
  //         }

  //         setQuotation_upload_files({
  //           ...quotation_upload_files,
  //           data: oleTempFileUpload,
  //         });
  //         quotation_upload_files.data = oleTempFileUpload;
  //         await saveQuotationFiles();
  //       }
  //     }
  //   }
  // }

  // async function saveQuotationFiles() {
  //   const findCheckTrue = quotation_upload_files.data.filter((item) => {
  //     return item.status !== "del";
  //   });

  //   const addNew = quotation_upload_files.data.filter((item) => {
  //     return item.status === "add" && item.oldNew === "new";
  //   });

  //   const delOld = quotation_upload_files.del.filter((item) => {
  //     return item.status === "del" && item.oldNew === "old";
  //   });

  //   setQuotation_upload_files({
  //     ...quotation_upload_files,
  //     data: findCheckTrue,
  //     new: addNew,
  //     del: delOld,
  //   });

  //   quotation_upload_files.data = findCheckTrue;
  //   quotation_upload_files.new = addNew;
  //   quotation_upload_files.del = delOld;

  //   console.log(
  //     quotation_upload_files.data,
  //     quotation_upload_files.new,
  //     quotation_upload_files.del
  //   );
  // }

  const checkErrorForSave = async () => {
    let error_list = [];
    // console.log('priceListData', priceListData);
    if (on_change_cusName.data) {
      if (on_change_cusName.data?.trim() !== priceListData.pl_cus_name) {
        priceListData.pl_cus_id = "";
      } else {
        const check = customerDD?.data?.find(
          (el) => el.cus_name === priceListData.pl_cus_name
        );

        if (check) {
          priceListData.pl_cus_id = check.cus_id;
        }
      }
    } else {
      priceListData.pl_cus_id = "";
    }

    if (priceListData.pl_term) {
      priceListData.pl_term =
        typeof priceListData.pl_term === "string"
          ? parseFloat(priceListData.pl_term?.replace(/,/g, ""))
          : priceListData.pl_term;
    }

    if (priceListData.pl_cus_name) {
      priceListData.pl_cus_name = priceListData.pl_cus_name?.trim();
    }
    if (priceListData.pl_cus_house_number) {
      priceListData.pl_cus_house_number =
        priceListData.pl_cus_house_number?.trim();
    }
    if (priceListData.pl_cus_building) {
      priceListData.pl_cus_building = priceListData.pl_cus_building?.trim();
    }
    if (priceListData.pl_swine) {
      priceListData.pl_swine = priceListData.pl_swine?.trim();
    }
    if (priceListData.pl_soi) {
      priceListData.pl_soi = priceListData.pl_soi?.trim();
    }
    if (priceListData.pl_road) {
      priceListData.pl_road = priceListData.pl_road?.trim();
    }
    if (priceListData.pl_sub_district) {
      priceListData.pl_sub_district = priceListData.pl_sub_district?.trim();
    }
    if (priceListData.pl_district) {
      priceListData.pl_district = priceListData.pl_district?.trim();
    }
    if (priceListData.pl_postcode) {
      priceListData.pl_postcode = priceListData.pl_postcode?.trim();
    }
    // if (priceListData.pl_contract_email) {
    //   priceListData.pl_contract_email =
    //     priceListData.pl_contract_email?.trim();
    // }
    // if (priceListData.pl_contract_name) {
    //   priceListData.pl_contract_name =
    //     priceListData.pl_contract_name?.trim();
    // }
    if (priceListData.pl_cus_remark) {
      priceListData.pl_cus_remark = priceListData.pl_cus_remark?.trim();
    }
    if (priceListData.pl_remark) {
      priceListData.pl_remark = priceListData.pl_remark?.trim();
    }
    // if (priceListData.quo_final_discount) {
    //   priceListData.quo_final_discount =
    //     typeof priceListData.quo_final_discount === "string"
    //       ? parseFloat(priceListData.quo_final_discount?.replace(/,/g, ""))
    //       : priceListData.quo_final_discount;
    // }
    // if (priceListData.quo_shipping_cost) {
    //   priceListData.quo_shipping_cost =
    //     typeof priceListData.quo_shipping_cost === "string"
    //       ? parseFloat(priceListData.quo_shipping_cost?.replace(/,/g, ""))
    //       : priceListData.quo_shipping_cost;
    // }
    if (priceListData.pl_total_befor_vat) {
      priceListData.pl_total_befor_vat =
        typeof priceListData.pl_total_befor_vat === "string"
          ? parseFloat(priceListData.pl_total_befor_vat?.replace(/,/g, ""))
          : priceListData.pl_total_befor_vat;
    }
    // if (priceListData.quo_contract_tel) {
    //   priceListData.pl_contract_tel =
    //     priceListData.pl_contract_tel?.replace(/-/g, "");
    // }
    // if (priceListData.pl_vat) {
    //   priceListData.pl_vat =
    //     typeof priceListData.pl_vat === "string"
    //       ? parseFloat(priceListData.pl_vat?.replace(/,/g, ""))
    //       : priceListData.pl_vat;
    // }
    if (priceListData.pl_total_price) {
      priceListData.pl_total_price =
        typeof priceListData.pl_total_price === "string"
          ? parseFloat(priceListData.pl_total_price?.replace(/,/g, ""))
          : priceListData.pl_total_price;
    }
    // if (priceListData.quo_version) {
    //   priceListData.quo_version =
    //     typeof priceListData.quo_version === "string"
    //       ? parseFloat(priceListData.quo_version?.replace(/,/g, ""))
    //       : priceListData.quo_version;
    // }

    // if (
    //   priceListData.quo_valid_unit === null ||
    //   priceListData.quo_valid_unit === ""
    // ) {
    //   let temp_err = {
    //     message: `กรุณาเลือก Valid Unit`,
    //   };
    //   error_list.push(temp_err);
    // }

    if (
      priceListData.pl_cus_name === null ||
      priceListData.pl_cus_name?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอก Customer Name`,
      };
      error_list.push(temp_err);
    }

    /* if (
      priceListData.pl_cus_house_number === null ||
      priceListData.pl_cus_house_number?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกบ้านเลขที่`,
      };
      error_list.push(temp_err);
    }

    if (
      priceListData.pl_cus_building === null ||
      priceListData.pl_cus_building?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกอาคาร`,
      };
      error_list.push(temp_err);
    }

    if (
      priceListData.pl_swine === null ||
      priceListData.pl_swine?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกหมู่`,
      };
      error_list.push(temp_err);
    }

    if (priceListData.pl_soi === null || priceListData.pl_soi?.trim() === "") {
      let temp_err = {
        message: `กรุณากรอกซอย`,
      };
      error_list.push(temp_err);
    }

    if (
      priceListData.pl_road === null ||
      priceListData.pl_road?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกถนน`,
      };
      error_list.push(temp_err);
    }

    if (
      priceListData.pl_sub_district === null ||
      priceListData.pl_sub_district?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกแขวง/ตำบล`,
      };
      error_list.push(temp_err);
    }

    if (
      priceListData.pl_district === null ||
      priceListData.pl_district?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกเขต/อำเภอ`,
      };
      error_list.push(temp_err);
    }

    if (
      priceListData.pl_province === null ||
      priceListData.pl_province?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกจังหวัด`,
      };
      error_list.push(temp_err);
    } */

    if (
      priceListData.pl_postcode === null ||
      priceListData.pl_postcode?.trim() === ""
    ) {
      /* let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์`,
      };
      error_list.push(temp_err); */
    } else if (priceListData.pl_postcode?.length !== 5) {
      let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์ให้ครบ 5 หลัก`,
      };
      error_list.push(temp_err);
    }

    // if (
    //   priceListData.pl_contract_name === null ||
    //   priceListData.pl_contract_name.trim() === ""
    // ) {
    //   let temp_err = {
    //     message: `กรุณากรอก Customer Contact Name`,
    //   };
    //   error_list.push(temp_err);
    // }

    // if (priceListData.pl_contract_tel.trim() !== "") {
    //   if (
    //     (priceListData.pl_contract_tel?.replace(/-/g, "").trim()).length !== 10
    //   ) {
    //     let temp_err = {
    //       message: `Contact Tel ต้องมี 10 หลัก`,
    //     };
    //     error_list.push(temp_err);
    //   }
    // }

    if (priceListData.pl_pt_id === null || priceListData.pl_pt_id === "") {
      let temp_err = {
        message: `กรุณาเลือก Payment term`,
      };
      error_list.push(temp_err);
    }

    if (priceListData.pl_term === null || priceListData.pl_term === "") {
      let temp_err = {
        message: `กรุณากรอก term`,
      };
      error_list.push(temp_err);
    }

    return error_list;
  };

  // const deleteImagesAndFilesOfProduct = async () => {
  //   /* await axios({
  //           method: "get",
  //           url: Configs.API_URL_IMG_cuteplus + "/api/upload/delImagesAndFilesOfProduct/" + id,
  //           headers: {
  //               Authorization: getToken(),
  //               "X-TTT": Configs.API_TTT,
  //               "Content-Type": "application/json",
  //           },
  //           // data: tempData1,
  //       }) */
  //   let tempData1 = {
  //     del_list: quotation_upload_files.del,
  //     user_id: getUser().fup,
  //     user_name: `${getUser().name} ${getUser().surname}`,
  //   };
  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL_IMG_cuteplus + "/api/upload/delImagesAndFiles",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: tempData1,
  //   })
  //     .then(async (response) => {
  //       if (response.data) {
  //         await uploadAndSaveImageAdvance(
  //           mode === "edit" ? id : response,
  //           "file_quotation_sales"
  //         );
  //       }
  //     })
  //     .catch(async (error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "ไม่สามารถลบเอกสารได้",
  //       });
  //     });
  // };

  // const uploadAndSaveImageAdvance = async (quo_id, fileGroup) => {
  //   // console.log('inputDataTab2.data?.documentFile', inputDataTab2.data?.documentFile);
  //   // console.log('inputDataTab3.data?.dataFoodRegister', inputDataTab3.data?.dataFoodRegister);
  //   let arrFiles = [];
  //   /*
  //       let temp = {}
  //               temp.name = el.qou_document_name
  //               temp.typeFile = "เอกสาร"
  //               temp.path = el.qou_image
  //               temp.remark = el.remark || ""
  //               return temp
  //       */
  //   if (fileGroup === "file_quotation_sales") {
  //     let data = new FormData();
  //     await quotation_upload_files.new?.forEach(async (el) => {
  //       data.append("Profile", el.qou_image);
  //       data.append("ProfileName", el.qou_document_name);
  //     });
  //     data.append("company_id", getUser().com);
  //     data.append("oem_id", getOem());
  //     data.append("user_id", getUser().fup);
  //     data.append("user_name", `${getUser().name} ${getUser().surname}`);
  //     data.append("Project", "Cute_Products_Plus");
  //     data.append("Menu", "Quotation (Sales)");
  //     data.append("Ref", quo_id);

  //     data.append("group", "file_quotation_sales");

  //     await axios({
  //       method: "post",
  //       url: Configs.API_URL_IMG_cuteplus + "/api/upload/saveDataUploadFile",
  //       headers: {
  //         Authorization: getToken(),
  //         "X-TTT": Configs.API_TTT,
  //         "Content-Type": "multipart/form-data",
  //       },
  //       data: data,
  //     })
  //       .then(async (response) => {})
  //       .catch(async (error) => {
  //         Swal.fire({
  //           icon: "error",
  //           title: "ไม่สามารถอัปโหลดเอกสารได้",
  //         });
  //       });
  //   }
  // };

  // const getFileQuotation = async () => {
  //   await axios({
  //     method: "get",
  //     url:
  //       Configs.API_URL_IMG_cuteplus +
  //       "/api/upload/getDataFileGroupAll/" +
  //       id +
  //       "/file_quotation_sales",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     // data: tempData1,
  //   })
  //     .then(async (response) => {
  //       // console.log(response.data);
  //       if (response.data.length > 0) {
  //         const file_list = response.data?.map((el, idx) => {
  //           return {
  //             ul_id: el.ul_id,
  //             qou_document_name: el.ul_file_name,
  //             qou_path_document: el.ul_path_file,
  //             oldNew: "old",
  //           };
  //         });
  //         setQuotation_upload_files({
  //           ...quotation_upload_files,
  //           data: file_list,
  //         });
  //         quotation_upload_files.data = file_list;
  //         // console.log('file_list', file_list);
  //       }
  //     })
  //     .catch(async (error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "ไม่สามารถอัปโหลดเอกสารได้",
  //       });
  //     });
  // };

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];
        error_list = await checkErrorForSave();
        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);
        if (error_list.length > 0) {
          let err_message = "";

          for (let e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              data: priceListData,
              products: inputDataTab2,
            };
            // console.log('temp', temp);
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus_sales +
                `/api/price_list/${
                  mode === "add" ? "saveDrafPriceList" : "updateDrafPriceList"
                }`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              // .then(async function (response) {
              // console.log('response saveDrafQuotation -', response.data);
              // if (response.data) {
              //   if (
              //     mode === "edit" &&
              //     quotation_upload_files.data.length > 0
              //   ) {
              //     await deleteImagesAndFilesOfProduct();
              //   }
              // }
              // })
              .then(async function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href = "/Sale_cuteplut/Price_List";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  const [product_LogExp, setProduct_LogExp] = useState({
    data: [],
  });

  const getProductLogAndDetails = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      code: null,
      name: null,
      category: null,
      customer_barcode: null,
      customer_product_name: null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductLogAndDetails",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {
        if (response.data.length > 0) {
          setProduct_LogExp({ ...product_LogExp, data: response.data });
          product_LogExp.data = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const generateSO_Quotation = async () => {
  //   Swal.fire({
  //     title: "คุณมั่นใจว่าคุณจะ Generate SO Quotation นี้ใช่หรือไม่",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ไม่`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       let error_list = [];

  //       error_list = await checkErrorForSave();

  //       await functionSaveTab2.data();
  //       error_list.push(...errorListTab2.data);
  //       // console.log('inputDataTab2', inputDataTab2);

  //       if (error_list.length > 0) {
  //         var err_message = "";

  //         for (var e = 0; e < error_list.length; e++) {
  //           err_message += "<br/>" + error_list[e].message;
  //         }

  //         Swal.fire("Error", err_message, "error");
  //         return;
  //       } else {
  //         for (let item of inputDataTab2.data) {
  //           const check_has = product_LogExp.data?.find(
  //             (el) => el.product_id === item.product_id
  //           );
  //           if (check_has && check_has.exp_date_list.length > 0) {
  //             item.exp = check_has.exp_date_list[0].exp;
  //           }
  //         }
  //         const temp = {
  //           company_id: getUser().com,
  //           oem_id: getOem(),
  //           user_id: getUser().fup,
  //           user_name: getUser().name + " " + getUser().surname,
  //           data: priceListData,
  //           products: inputDataTab2,
  //         };
  //         // return console.log('temp', temp);
  //         await axios({
  //           method: "post",
  //           url:
  //             Configs.API_URL_cuteplus_sales +
  //             `/api/quotation/generateSO_Quotation`,
  //           headers: {
  //             Authorization: "Bearer " + getToken(),
  //             "X-TTT": Configs.API_TTT,
  //             "Content-Type": "application/json",
  //           },
  //           data: temp,
  //         })
  //           .then(async function (response) {
  //             // console.log('response saveDrafQuotation -', response.data);
  //             // await deleteImagesAndFilesOfProduct();
  //             if (response.data) {
  //               await addBillfromSO(response.data);
  //               // await addLogInfromSO(response.data);
  //             }
  //           })
  //           .then(async function (response) {
  //             Swal.fire({
  //               icon: "success",
  //               title: "Generate SO สำเร็จ",
  //               allowEscapeKey: false,
  //               allowOutsideClick: false,
  //               showConfirmButton: false,
  //               timer: 2000,
  //             }).then(() => {
  //               window.location.href =
  //                 "/Sale_cuteplut/Quotation_Sales_Cuteplut";
  //             });
  //           })
  //           .catch(function (error) {
  //             Swal.fire({
  //               title: "Error",
  //               text: error.response.data.error.message,
  //               icon: "error",
  //             });
  //           });
  //       }
  //     }
  //   });
  // };

  // const addLogInfromSO = async (response) => {
  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL_cuteplus + `/api/log_product/addLogProduct`,
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: { temp2: response.product_list },
  //   })
  //     .then(async function (response) {})
  //     .catch(function (error) {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.response.data.error.message,
  //         icon: "error",
  //       });
  //     });
  // };

  // const addBillfromSO = async (temp) => {
  //   await axios({
  //     method: "post",
  //     url: Configs.API_URL_cuteplus + `/api/bill/addBillfromSO`,
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: temp,
  //   })
  //     .then(async function (response) {})
  //     .catch(function (error) {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.response.data.error.message,
  //         icon: "error",
  //       });
  //     });
  // };

  // const cancelQuotation = async () => {
  //   Swal.fire({
  //     title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ Quotation นี้ใช่หรือไม่",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ไม่`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const temp = {
  //         company_id: getUser().com,
  //         oem_id: getOem(),
  //         user_id: getUser().fup,
  //         user_name: getUser().name + " " + getUser().surname,
  //         quo_id: id,
  //         quo_no: priceListData.quo_no,
  //       };
  //       await axios({
  //         method: "post",
  //         url:
  //           Configs.API_URL_cuteplus_sales + `/api/quotation/cancelQuotation`,
  //         headers: {
  //           Authorization: "Bearer " + getToken(),
  //           "X-TTT": Configs.API_TTT,
  //           "Content-Type": "application/json",
  //         },
  //         data: temp,
  //       })
  //         .then(async function (response) {
  //           // console.log('response saveDrafQuotation -', response.data);

  //           Swal.fire({
  //             icon: "success",
  //             title: "ยกเลิกรายการ Quotation สำเร็จ",
  //             allowEscapeKey: false,
  //             allowOutsideClick: false,
  //             showConfirmButton: false,
  //             timer: 2000,
  //           }).then(() => {
  //             window.location.href = "/Sale_cuteplut/Quotation_Sales_Cuteplut";
  //           });
  //         })
  //         .catch(function (error) {
  //           Swal.fire({
  //             title: "Error",
  //             text: error.response.data.error.message,
  //             icon: "error",
  //           });
  //         });
  //     }
  //   });
  // };
  const [ref_id_qout, setRef_id_qout] = useState({
    data: "",
  });

  const clearCustomer = async () => {
    setPriceListData({
      ...priceListData,
      pl_cus_name: "",
      pl_cus_house_number: "",
      pl_cus_building: "",
      pl_swine: "",
      pl_soi: "",
      pl_road: "",
      pl_sub_district: "",
      pl_district: "",
      pl_province: "",
      pl_postcode: "",
      pl_pt_id: "",
      pl_term: "",
      pl_cus_remark: "",
      // quo_contract_name: "",
      // quo_contract_email: "",
      // quo_contract_tel: "",
      // quo_gp_standard: "",
    });
    priceListData.pl_cus_name = "";
    priceListData.pl_cus_house_number = "";
    priceListData.pl_cus_building = "";
    priceListData.pl_swine = "";
    priceListData.pl_soi = "";
    priceListData.pl_road = "";
    priceListData.pl_sub_district = "";
    priceListData.pl_district = "";
    priceListData.pl_province = "";
    priceListData.pl_postcode = "";
    priceListData.pl_pt_id = "";
    priceListData.pl_term = "";
    priceListData.pl_cus_remark = "";
    // priceListData.quo_contract_name = "";
    // priceListData.quo_contract_email = "";
    // priceListData.quo_contract_tel = "";
    // priceListData.quo_gp_standard = "";
  };

  const getPriceListById = async (ref_id) => {
    let temp = {
      pl_id: ref_id ? ref_id : id,
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      // isHistory: isHistory,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        `/api/price_list/${"getPriceListById"}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log(response.data);
        const data = response.data[0];
        // console.log(data);
        /* 
                    มีเข้ามาจากการเลือก ref กับ history
                    ดึง ref เลือกเอาข้อมูลที่จำเป็น
                    ดึง history แสดงทั้งหมด
                */
        if ((mode === "edit" || mode === "view") && !ref_id) {
          setPriceListData({
            ...priceListData,
            pl_no: data.pl_no || "",
            pl_date: new Date(data.pl_date) || "",
            // pl_valid_unit: new Date(data.pl_valid_unit) || "",
            // pl_approve_date: data.pl_approve_date || "",
            // pl_approve_user_id: data.pl_approve_user_id || "",
            // pl_approve_user_name: data.pl_approve_user_name || "",
            // pl_version: data.pl_version || "",
            pl_created_name: data.pl_created_name || "",
            pl_updated_name: data.pl_updated_name || "",
            pl_created_date: data.pl_created_date || "",
            pl_updated_date: data.pl_updated_date || "",
            pl_created_by: data.pl_created_by || "",
            pl_updated_by: data.pl_updated_by || "",
          });
          priceListData.pl_no = data.pl_no || "";
          priceListData.pl_date = new Date(data.pl_date) || "";
          // priceListData.pl_valid_unit = new Date(data.pl_valid_unit) || "";
          // priceListData.pl_approve_date = data.pl_approve_date || "";
          // priceListData.pl_approve_user_id = data.pl_approve_user_id || "";
          // priceListData.pl_approve_user_name = data.pl_approve_user_name || "";
          // priceListData.pl_version = data.pl_version || "";
          priceListData.pl_created_name = data.pl_created_name || "";
          priceListData.pl_updated_name = data.pl_updated_name || "";
          priceListData.pl_created_date = data.pl_created_date || "";
          priceListData.pl_updated_date = data.pl_updated_date || "";
          priceListData.pl_created_by = data.pl_created_by || "";
          priceListData.pl_updated_by = data.pl_updated_by || "";
        }
        // console.log("no ref");
        setPriceListData({
          ...priceListData,
          pl_id: mode === "edit" || mode === "view" ? id : "",
          pl_ref_id: ref_id ? ref_id : data.pl_ref_id || "",
          pl_cus_id: data.pl_cus_id || "",
          pl_cus_name: data.pl_cus_name || "",
          pl_cus_house_number: data.pl_cus_house_number || "",
          pl_cus_building: data.pl_cus_building || "",
          pl_swine: data.pl_swine || "",
          pl_soi: data.pl_soi || "",
          pl_road: data.pl_road || "",
          pl_sub_district: data.pl_sub_district || "",
          pl_district: data.pl_district || "",
          pl_province: data.pl_province || "",
          pl_postcode: data.pl_postcode || "",
          pl_ccp_id: data.pl_ccp_id || "",
          pl_contract_name: data.pl_contract_name || "",
          pl_contract_email: data.pl_contract_email || "",
          pl_contract_tel: data.pl_contract_tel || "",
          pl_pt_id: data.pl_pt_id || "",
          pl_term: data.pl_term || "",
          pl_cus_remark: data.pl_cus_remark || "",
          pl_remark: data.pl_remark || "",
          pl_lang: data.pl_lang === "TH" ? true : false, /// true is TH and flase is ENG
          pl_incl_vat: data.pl_incl_vat,
          pl_final_discount_bath: data.pl_final_discount || "",
          pl_shipping_cost: data.pl_shipping_cost || "",
          pl_total_befor_vat: data.pl_total_befor_vat || "",
          pl_vat: data.pl_vat || "",
          pl_total_price: data.pl_total_price || "",
          pl_is_use: data.pl_is_use || "",
          pl_qs_id: data.pl_qs_id || "",
          pl_ct_id: data.pl_ct_id || null,
          pl_ct_name: data.pl_ct_id
            ? customerTypeData.data?.find((el) => el.ct_id === data.pl_ct_id)
                .ct_name || ""
            : null,
          pl_final_discount: data.pl_final_discount_percent || "",
        });
        priceListData.pl_id = mode === "edit" || mode === "view" ? id : "";
        priceListData.pl_ref_id = ref_id ? ref_id : data.pl_ref_id || "";
        priceListData.pl_cus_id = data.pl_cus_id || "";
        priceListData.pl_cus_name = data.pl_cus_name || "";
        priceListData.pl_cus_house_number = data.pl_cus_house_number || "";
        priceListData.pl_cus_building = data.pl_cus_building || "";
        priceListData.pl_swine = data.pl_swine || "";
        priceListData.pl_soi = data.pl_soi || "";
        priceListData.pl_road = data.pl_road || "";
        priceListData.pl_sub_district = data.pl_sub_district || "";
        priceListData.pl_district = data.pl_district || "";
        priceListData.pl_province = data.pl_province || "";
        priceListData.pl_postcode = data.pl_postcode || "";
        priceListData.pl_ccp_id = data.pl_ccp_id || "";
        priceListData.pl_contract_name = data.pl_contract_name || "";
        priceListData.pl_contract_email = data.pl_contract_email || "";
        priceListData.pl_contract_tel = data.pl_contract_tel || "";
        priceListData.pl_pt_id = data.pl_pt_id || "";
        priceListData.pl_term = data.pl_term || "";
        priceListData.pl_cus_remark = data.pl_cus_remark || "";
        priceListData.pl_remark = data.pl_remark || "";
        priceListData.pl_lang = data.pl_lang === "TH" ? true : false; /// true is TH and flase is ENG
        priceListData.pl_incl_vat = data.pl_incl_vat;
        priceListData.pl_final_discount_bath = data.pl_final_discount || "";
        priceListData.pl_shipping_cost = data.pl_shipping_cost || "";
        priceListData.pl_total_befor_vat = data.pl_total_befor_vat || "";
        priceListData.pl_vat = data.pl_vat || "";
        priceListData.pl_total_price = data.pl_total_price || "";
        priceListData.pl_is_use = data.pl_is_use || "";
        priceListData.pl_qs_id = data.pl_qs_id || "";
        priceListData.pl_ct_id = data.pl_ct_id || null;
        priceListData.pl_ct_name = data.pl_ct_id
          ? customerTypeData.data?.find((el) => el.ct_id === data.pl_ct_id)
              .ct_name || ""
          : null;
        priceListData.pl_final_discount = data.pl_final_discount_percent || "";

        if (data.pl_cus_name) {
          setOn_change_cusName({ data: data.pl_cus_name });
          on_change_cusName.data = data.pl_cus_name;
        }

        if (data.pl_ccp_id) {
          await getGP_CustomerContractPoint(data.pl_ccp_id);
        }
        /* priceListData.quo_id = data.quo_id;
                priceListData.quo_no = data.quo_no;
                priceListData.quo_ref_id = data.quo_ref_id || ""; */

        // console.log('priceListData.data', priceListData);
      })
      .catch(function (error) {
        console.log("error", error);
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getGP_CustomerContractPoint = async (ccp_id) => {
    let temp = {
      ccp_id: ccp_id,
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus +
        `/api/customer/${"getGP_CustomerContractPoint"}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data) {
          const temp = { ...priceListData };
          setPriceListData({
            ...priceListData,
            quo_gp_standard: response.data.ccp_gp_standard,
          });
          priceListData.quo_gp_standard = response.data.ccp_gp_standard;
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const Custom_Input_date = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Price List {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sales</li>
                  <li className="breadcrumb-item active">Price List</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-2">
                <div className="col-12 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={() => {
                      cancelOption();
                    }}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={() => {
                      cancelOption();
                    }}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
                {/* <div
                  className="col-12 col-md-3 col-xl-2"
                  hidden={mode !== "edit" ? true : false}
                >
                  <button
                    type="button"
                    onClick={sendApproveQuotation}
                    className="btn btn-block btn-info"
                    id="btn-save"
                  >
                    Send Approve
                  </button>
                </div> */}
              </div>
            )}

            {/* <div className="row mb-2 mt-2">
              <div className="col-12 col-md-6 col-xl-6">
                <h4>
                  Quotation Current Version : {priceListData.quo_version || ""}
                </h4>
              </div>
            </div> */}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div
                className="row"
                style={{ justifyContent: "start", alignItems: "start" }}
              >
                <div
                  className="col-3"
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <h3 className="card-title" style={{ whiteSpace: "nowrap" }}>
                    Price List
                  </h3>
                  {/* <button
                    hidden={mode !== "edit" ? true : false}
                    onClick={async () => {
                      await cancelQuotation();
                    }}
                    style={{ whiteSpace: "nowrap", minWidth: "100px" }}
                    type="button"
                    className="btn btn-block btn-danger"
                  >
                    Cancel Quotation
                  </button> */}
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-5 col-xl-3">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={true}
                      value={priceListData.pl_no}
                    />
                    <label htmlFor="">Price List No.</label>
                  </div>
                </div>
                <div
                  className="col-12 col-md-4 col-xl-3"
                  style={{ "z-index": "99" }}
                >
                  <div className="form-group">
                    <DatePicker
                      disabled={disable}
                      dateFormat={"dd-MM-yyyy"}
                      popperPlacement="bottom"
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"], // don't allow it to flip to be above
                        },
                        preventOverflow: {
                          enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                          enabled: false, // turn off since needs preventOverflow to be enabled
                        },
                      }}
                      selected={priceListData.pl_date}
                      onChange={async (date) => {
                        setPriceListData({
                          ...priceListData,
                          pl_date: date,
                        });
                      }}
                      customInput={<Custom_Input_date isDis={disable} />}
                    ></DatePicker>
                    <label htmlFor="">
                      Date
                      {priceListData.pl_date === "" ||
                      priceListData.pl_date === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h2>
                    <b>Customer Detail</b>
                  </h2>
                </div>
              </div>
              <hr style={{ marginTop: "0" }}></hr>
              <div className="row">
                <div className="col-12 col-md-4 col-xl-4">
                  <div class="form-group">
                    <select
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_ct_id}
                      onChange={(e) => {
                        const value = e.target.value;
                        const ct_name = customerTypeData.data?.find(
                          (el) => el.ct_id === value
                        ).ct_name;
                        setPriceListData({
                          ...priceListData,
                          pl_ct_id: value,
                          pl_ct_name: ct_name,
                          pl_confirm_customer: true,
                          pl_cus_id: null,
                          pl_final_discount: 0,
                          pl_final_discount_bath: 0,
                        });
                        priceListData.pl_ct_id = value;
                        priceListData.pl_ct_name = ct_name;
                        priceListData.pl_confirm_customer = true;
                        priceListData.pl_cus_id = null;
                        priceListData.pl_final_discount = 0;
                        priceListData.pl_final_discount_bath = 0;
                        clearCustomer();
                      }}
                    >
                      <option value="" disabled>
                        -- กรุณาเลือก --
                      </option>
                      {customerTypeData.data.map((data, index) => {
                        return (
                          <option key={data.ct_id} value={data.ct_id}>
                            {data.ct_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ประเภทลูกค้า
                      {priceListData.pl_ct_id === "" ||
                      priceListData.pl_ct_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-5 col-xl-4">
                  <div
                    className="form-group"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={disable}
                      value={priceListData.pl_cus_name}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_cus_name: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="">
                      Customer Name
                      {priceListData.pl_cus_name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <a
                      data-toggle="modal"
                      data-target="#modalQuotationCustomer"
                      style={{ cursor: "pointer" }}
                      hidden={disable}
                      onClick={() => {
                        if (
                          priceListData.pl_cus_id === null ||
                          priceListData.pl_cus_id === ""
                        ) {
                          setPriceListData({
                            ...priceListData,
                            pl_cus_id: "",
                          });
                          priceListData.pl_cus_id = "";
                        }
                      }}
                    >
                      <i class="fas fa-search"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <h4>
                    <b> ที่อยู่</b>
                    {/* {true ? <span style={{ color: "red" }}> *</span> : ""} */}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-2 col-xl-1">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_cus_house_number}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_cus_house_number: e.target.value,
                        });
                        priceListData.pl_cus_house_number = e.target.value;
                      }}
                    />
                    <label>บ้านเลขที่</label>
                  </div>
                </div>

                <div className="col-12 col-md-3 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_cus_building}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_cus_building: e.target.value,
                        });
                        priceListData.pl_cus_building = e.target.value;
                      }}
                    />
                    <label>อาคาร</label>
                  </div>
                </div>

                <div className="col-12 col-md-2 col-xl-1">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_swine}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_swine: e.target.value,
                        });
                        priceListData.pl_swine = e.target.value;
                      }}
                    />
                    <label>หมู่</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_soi}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_soi: e.target.value,
                        });
                        priceListData.pl_soi = e.target.value;
                      }}
                    />
                    <label>ซอย</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_road}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_road: e.target.value,
                        });
                        priceListData.pl_road = e.target.value;
                      }}
                    />
                    <label>ถนน</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_sub_district}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_sub_district: e.target.value,
                        });
                        priceListData.pl_sub_district = e.target.value;
                      }}
                    />
                    <label>แขวง / ตำบล</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_district}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_district: e.target.value,
                        });
                        priceListData.pl_district = e.target.value;
                      }}
                    />
                    <label>เขต / อำเภอ</label>
                  </div>
                </div>

                <div className="col-12 col-md-5 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_province}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_province: e.target.value,
                        });
                        priceListData.pl_province = e.target.value;
                      }}
                    />
                    <label>จังหวัด</label>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-2">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      decimalScale={0}
                      maxLength={5}
                      allowNegative={false}
                      disabled={disable}
                      className="form-control"
                      required
                      value={priceListData.pl_postcode}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_postcode: e.target.value,
                        });
                        priceListData.pl_postcode = e.target.value;
                      }}
                    />
                    <label>รหัสไปรษณีย์</label>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-12 col-md-4 col-xl-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_contract_name}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_contract_name: e.target.value,
                        });
                        priceListData.pl_contract_name = e.target.value;
                      }}
                    />
                    <label>
                      Customer Contact Name
                      {priceListData.quo_contract_name === "" ||
                      priceListData.quo_contract_name === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.quo_contract_email}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          quo_contract_email: e.target.value,
                        });
                        priceListData.quo_contract_email = e.target.value;
                      }}
                    />
                    <label>Contact Email</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-5">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      format={"###-###-####"}
                      required
                      disabled={disable}
                      value={priceListData.quo_contract_tel}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          quo_contract_tel: e.target.value,
                        });
                        priceListData.quo_contract_tel = e.target.value;
                      }}
                    />
                    <label>Contact Tel.</label>
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={priceListData.pl_pt_id}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_pt_id: e.target.value,
                        });
                        priceListData.pl_pt_id = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        -- กรุณาเลือก --
                      </option>
                      {paymentTermData.data.map((data) => {
                        return (
                          <option value={data.pt_id}>{data.pt_name}</option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Payment Term
                      {priceListData.pl_pt_id === "" ||
                      priceListData.pl_pt_id === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-2">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      allowNegative={false}
                      decimalScale={0}
                      disabled={disable}
                      value={priceListData.pl_term}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_term: e.target.value,
                        });
                        priceListData.pl_term = e.target.value;
                      }}
                    />
                    <label>
                      Term
                      {priceListData.pl_term === "" ||
                      priceListData.pl_term === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-12 col-xl-10">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={2}
                      disabled={disable}
                      style={{
                        height: "100px",
                        fontSize: "25px",
                      }}
                      value={priceListData.pl_cus_remark}
                      onChange={(e) => {
                        setPriceListData({
                          ...priceListData,
                          pl_cus_remark: e.target.value,
                        });
                        priceListData.pl_cus_remark = e.target.value;
                      }}
                      defaultValue=""
                      required
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>

              <hr className="mt-0"></hr>
              <div className="row">
                <div className="col-12">
                  <h2>
                    <b>Product</b>
                  </h2>
                </div>
              </div>
              <div>
                <Price_List_Product
                  id={id}
                  mode={mode}
                  setRef_id_qout={setRef_id_qout}
                  ref_id_qout={ref_id_qout}
                  // isHistory={isHistory}
                  isDisabled={disable}
                  priceListData={priceListData}
                  setPriceListData={setPriceListData}
                  inputDataTab2={inputDataTab2}
                  setInputDataTab2={setInputDataTab2}
                  errorListTab2={errorListTab2}
                  setErrorListTab2={setErrorListTab2}
                  functionSaveTab2={functionSaveTab2}
                  setFunctionSaveTab2={setFunctionSaveTab2}
                />
              </div>
              {/* {(mode === "edit" || mode === "view") &&
              (priceListData.quo_qs_id ===
                "f4d7da7a-86b6-4757-a96a-61ae63e642de" ||
                priceListData.quo_qs_id ===
                  "24af7b0f-4c1f-45c7-9bc6-b6a3c2bcbadb") ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        <u>
                          <b>
                            วันที่อนุมัติ :{" "}
                            {moment(
                              new Date(priceListData.quo_approve_date)
                            ).format("DD/MM/YYYY")}
                          </b>
                        </u>
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-6 col-sm-6">
                      <label htmlFor="exampleInputFile">
                        Upload Document{" "}
                      </label>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            style={{ display: "none" }}
                            type="file"
                            className="custom-file-input"
                            id="exampleInputInvoid"
                            accept={allowedTypes}
                            multiple
                            disabled={disable}
                            ref={uploadRef}
                            name="customFile"
                            onChange={(e) => encodeImage(e.target.files)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                            onClick={() => uploadRef.current.click()}
                          >
                            {"Select File"}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6 col-sm-6 d-flex align-items-end">
                      <div className="form-group mb-0 pb-0">
                        <button
                          type="button"
                          hidden={mode === "view" ? true : false}
                          className="btn btn-block btn-primary"
                          onClick={async () => {
                            await generateSO_Quotation();
                          }}
                        >
                          Generate SO
                        </button>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <table class="table">
                      <caption>List of users</caption>
                      <thead>
                        <tr>
                          <th scope="col">ลำดับ</th>
                          <th scope="col">เอกสาร</th>
                          <th scope="col">จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotation_upload_files.data?.map((el, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td style={{ textAlign: "center" }}>
                                  <a
                                    className=" btn btn-xs "
                                    style={{
                                      fontSize: "1.2rem",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    href={
                                      el.qou_path_document &&
                                      el.qou_document_name &&
                                      !el.status
                                        ? Configs.API_URL_IMG_cuteplus +
                                          `/static/${el.qou_document_name}/${el.qou_path_document}`
                                        : el.qou_path_document &&
                                          el.qou_document_name &&
                                          el.status
                                        ? el.qou_path_document
                                        : ""
                                    }
                                    download={
                                      el.qou_document_name
                                        ? el.qou_document_name
                                        : ""
                                    }
                                  >
                                    <u>{el.qou_document_name}</u>
                                  </a>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <a
                                    hidden={mode === "view" ? true : false}
                                    className=" btn btn-xs "
                                    onClick={async () => {
                                      el.status = "del";
                                      let temp = quotation_upload_files.data;
                                      temp[index].status = "del";
                                      setQuotation_upload_files({
                                        ...quotation_upload_files,
                                        del: temp,
                                      });
                                      quotation_upload_files.del = temp;
                                      await saveQuotationFiles();
                                    }}
                                  >
                                    <i
                                      class="fas fa-trash-alt"
                                      style={{ color: "red" }}
                                    ></i>
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )} */}
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
        <div
          className="modal fade"
          id="modalQuotationCustomer"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalfoodRedisterLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              {/* Header */}
              <div className="modal-header">
                <h4 className="modal-title" id="modalfoodRedisterLabel">
                  <b>Customer</b>
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/* Body */}
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <h5>
                      <b>Customer</b>
                      {/* <span style={{ color: "red" }}> *</span> */}
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div class="form-group">
                      <Select
                        type="text"
                        required
                        options={customerDD.data.filter((el, index_el) => el.cus_ct_id === priceListData.pl_ct_id || !el.cus_ct_id)}
                        filterOption={customFilter}
                        value={
                          customerDD.data?.find(el => el.value === priceListData.pl_cus_id)
                          || { label: "--Select Customer--", value: "" }
                        }
                        onChange={(e) => {
                          const customerName = e.value
                            ? customerDD.dataNomap?.find(
                                (el) => el.cus_id === e.value
                              ).customerList || ""
                            : [];
                          setPriceListData({
                            ...priceListData,
                            pl_cus_id: e.value,
                          });

                          const tempCusName = customerName.map(el => {
                            return {
                              label: el.ccp_name,
                              value: el.ccp_id
                            }
                          })
                          tempCusName.unshift({
                            label: "--Select Customer Name--",
                            value: ""
                          })
                          setCustomerNameDD({
                            ...customerNameDD,
                            data: tempCusName,
                          });
                          customerNameDD.data = tempCusName;
                          priceListData.pl_cus_id = e.value;
                        }} // customerDD.data
                      >
                        <option key="" value="">
                          Select a Customer
                        </option>
                        {customerDD.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.cus_id}>
                              {item.cus_name}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <div className="col-md-12">
                    <h5>
                      <b>Customer Name</b>
                      <span style={{ color: "red" }}> *</span>
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <div class="form-group">
                      <select
                        type="text"
                        className="form-control"
                        required
                        disabled={
                          mode !== "view"
                            ? !priceListData.quo_cus_id
                              ? true
                              : false
                            : true
                        }
                        value={priceListData.quo_ccp_id}
                        onChange={(e) => {
                          setPriceListData({
                            ...priceListData,
                            quo_ccp_id: e.target.value,
                          });
                          priceListData.quo_ccp_id = e.target.value;
                        }}
                      >
                        <option key="" value="">
                          Select a Customer Name
                        </option>
                        {customerNameDD?.data?.map((item, index) => {
                          return (
                            <option key={index} value={item.ccp_id}>
                              {item.ccp_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* Footer */}
              <div
                className="modal-footer"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  ref={closeModalCustomer}
                >
                  ยกเลิก
                </button>
                {true ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        setPriceListData({
                          ...priceListData,
                          pl_confirm_customer: true,
                        });
                        priceListData.pl_confirm_customer = true;

                        if (!priceListData.pl_cus_id) {
                          clearCustomer();
                        } else {
                          // if (!priceListData.quo_ccp_id) {
                          //   Swal.fire(
                          //     "Error",
                          //     "กรุณาเลือก Customer name ก่อนทำรายการถัดไป",
                          //     "error"
                          //   );
                          //   return;
                          // }
                          addCustomerDetails();
                        }
                        clickCloseModalCustomer();
                      }}
                    >
                      บันทึก
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Price_List_AEV;
