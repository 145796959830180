import React from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import { useState } from "react";
import {
  getOem,
  getToken,
  getUser,
  getComConfig,
} from "../../../../Utils/Common";
import { useEffect } from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import PO_Report from "./Component/PO_report";

function Table_Purchase_Order_Admin() {
  const handleApproveClick = (index) => {
    let temp = POAdmin.data;
    temp[index].statusApprove = true;
    setPOAdmin({ ...POAdmin, data: temp });
    POAdmin.data = temp;
    updateStatus(temp[index].hpo_no, true);
  };
  const handleNotApproveClick = (index) => {
    let temp = POAdmin.data;
    temp[index].statusApprove = false;
    setPOAdmin({ ...POAdmin, data: temp });
    POAdmin.data = temp;
    updateStatus(temp[index].hpo_no, false);
  };

  const [SupplierDD, setSupplierDD] = useState([]);
  const [statusDD, setStatusDD] = useState({
    data: [],
  });

  const [Filter, setFilter] = useState({
    PO_No: "",
    supplier_name: "",
    create_by: "",
  });

  var [POAdmin, setPOAdmin] = useState({
    data: [],
  });

  const [PO_dataReport, setPO_dataReport] = useState({
    data: [],
    product: [],
    detail: [],
    pic: [],
  });

  const [modalData, setModalData] = useState({
    PO_No: "",
    PO_Date: "",
    Ref: "",
    Status: "",
    Version: "",
    Sup_Name: "",
    Sup_contactName: "",
    Sup_Address: "",
    Contact_Tel: "",
    Contact_Email: "",
    Remark: "",
    Vat: "",
    typeText: "",
    currency: [],
    Discount: [],
    product_Name: [],
    product_Unit: [],
    beforeTotal: [],
    subTotal: "",
    grandTotal: "",
    product: [],
    detail: [],
    discountFinal: "0"
  });

  const [productModal, setProductModal] = useState({
    data: [],
    typeText: "",
    currency: [],
    Discount: [],
    beforeTotal: [],
    subTotal: "",
    grandTotal: "",
    product: [],
    detail: [],
    discountFinal: ""
  });

  const [modalPObyNo, setModalPObyNo] = useState(false);
  const [MDBT, setMDBT] = useState(true);
  useEffect(() => {
    FilterPOAdmin();
    getSupplierDD();
    getStatusDD();
    getReport();
    GetProduct();
  }, []);

  function getSupplierDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getSupplierDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        setSupplierDD(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function getStatusDD() {
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/purchase_order/getPOStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const options = [];
        for (let item of response.data) {
          let temp = {
            label: item.ps_name,
            value: item.ps_id,
          };
          if (temp.label !== "Cancel") {
            options.push(temp);
          }
        }
        setStatusDD({ ...statusDD, data: options });
        statusDD.data = options;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function updateStatus(hpo_no, statusApprove) {
    let data = {
      hpo_no: hpo_no,
      newStatus_id:
        statusApprove === true
          ? "4fce3a38-cc71-4b84-ab05-adc94bc4ac6e"
          : "4c144353-2873-45e5-ae52-8839a62ded57",
      approve_id: getUser().fup,
      approve_name: getUser().name + " " + getUser().surname,
      company_id: getUser().com,
      oem_id: getOem(),
    }
    axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/purchase_order/updatePOStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getPOHistoryData(id) {
    let temp = {
      hpo_id: id,
      company_id: getUser().com,
      oem_id: getOem(),
    }
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/getPOHistoryAndProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        setPO_dataReport({
          ...PO_dataReport,
          data: response.data.PO_detail[0],
          product: response.data.productPO_detail,
        });
        PO_dataReport.data = response.data.PO_detail[0];
        PO_dataReport.product = response.data.productPO_detail;

        let pushData = {
          company_id: getUser().com,
          oem_id: getOem(),
          dropdown_name: "currency",
        };
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus + "/api/product/getDropdownAdvanceProduct",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: pushData,
        })
          .then(function (response) {
            setPO_dataReport({
              ...PO_dataReport,
              currency: response.data
                ? response.data.filter(
                  (el) =>
                    el.currency_id === PO_dataReport.data.hpo_currency_id
                )
                : [],
            });
            PO_dataReport.currency = response.data
              ? response.data.filter(
                (el) => el.currency_id === PO_dataReport.data.hpo_currency_id
              )
              : [];
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });

        let dcArray = [];
        for (let i = 0; i < PO_dataReport.product.length; i++) {
          let data = {
            hpd_id: PO_dataReport.product[i].hpp_id,
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus_sales +
              "/api/purchase_order/getDiscountProductPOHistory",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: data,
          })
            .then((response) => {
              let sublistDC = [];
              for (let y = 0; y < response.data.length; y++) {
                let discount = {};
                discount.dc_value = response.data[y].hpd_discount;
                discount.dc_unit = response.data[y].hpd_unit_discount;
                sublistDC.push(discount);
              }

              dcArray.push(
                sublistDC.length !== 0
                  ? sublistDC
                  : [{ dc_value: "0", dc_unit: "percent" }]
              );
              return dcArray;
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
        PO_dataReport.product.forEach((item, index) => {
          item.discounts = dcArray[index];
        });

        let newDetails = [];
        for (let i = 0; i < PO_dataReport.product.length; i++) {
          let temp = {
            product_id: PO_dataReport.product[i].hpp_product_id,
            company_id: getUser().com,
            oem_id: getOem(),
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus +
              "/api/product/getProductDetailsForPOAdmin",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: temp,
          })
            .then((response) => {
              if (response.data.length !== 0) {
                newDetails.push(response.data[0]);
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
        setPO_dataReport({ ...PO_dataReport, detail: newDetails });
        PO_dataReport.detail = newDetails;

        PO_dataReport.product?.map((el) => {
          const check = Product.data.find(
            (tl) => tl.product_id === el.hpp_product_id
          );
          if (check) {
            el.path_image = check.pci_img_path;
            el.name_image = check.pci_img_name;
            PO_dataReport.pic.push(el);
            return el;
          }
        });

        // let tempData = {
        //   company_id: getUser().com,
        //   oem_id: getOem(),
        // };
        // await axios({
        //   method: "post",
        //   url: Configs.API_URL_cuteplus + "/api/supplier/getPaymentTerm",
        //   headers: {
        //     Authorization: "Bearer " + getToken(),
        //     "X-TTT": Configs.API_TTT,
        //     "Content-Type": "application/json",
        //   },
        //   data: tempData,
        // })
        let tempData = {
          company_id: getUser().com,
          oem_id: getOem(),
          // user_id: getUser().fup,
        };
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/supplier/getConditionTerm",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(async (response) => {
            setPO_dataReport({
              ...PO_dataReport,
              term: response.data
                ? response.data.filter(
                  (el) => el.ct_id === PO_dataReport.data.hpo_sup_ct_id
                )
                : [],
            });
            PO_dataReport.term = response.data
              ? response.data.filter(
                (el) => el.ct_id === PO_dataReport.data.hpo_sup_ct_id
              )
              : [];

            if (PO_dataReport.product.length > 0) {
              PO_Report(company, PO_dataReport, getComConfig());
            } else {
              Swal.fire({
                title: "ไม่สามารถออก Report ที่ไม่มี Product ได้",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  const [Product, setProduct] = useState({
    data: [],
  });

  const GetProduct = async () => {
    let temp = {
      code: null,
      name: null,
      category: null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWherecat2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log('getProduct', response.data)
        if (response) {
          setProduct({ data: response.data });
          Product.data = response.data;
          /////////////////////////////////////
          let data = new FormData();
          data.append("company_id", getUser().com);
          data.append("oem_id", getOem());
          data.append("user_id", getUser().fup);
          data.append("products", JSON.stringify(response.data));

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "multipart/form-data",
            },
            data: data,
          })
            .then(async (response) => {
              setProduct({ data: response.data });
              Product.data = response.data;
              return response.data;
            })
            .catch(async (error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getProductDetails(index, id) {
    let temp = {
      hpo_id: id,
      company_id: getUser().com,
      oem_id: getOem(),
    }
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/getPOHistoryAndProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('getPOandProduct', response.data)
        setProductModal({
          ...productModal,
          data: response.data.PO_detail[0],
          typeText: response.data.PO_detail[0].hpo_lang,
          subTotal: response.data.PO_detail[0].hpo_total_befor_vat,
          grandTotal: response.data.PO_detail[0].hpo_total_price ? parseFloat(response.data.PO_detail[0].hpo_total_price)?.toFixed(2) : "0",
          product: response.data.productPO_detail,
          discountFinal: response.data.PO_detail[0].hpo_final_discount ? parseFloat(response.data.PO_detail[0].hpo_final_discount)?.toFixed(2) : "0"
        });
        productModal.data = response.data.PO_detail[0];
        productModal.typeText = response.data.PO_detail[0].hpo_lang;
        productModal.subTotal = response.data.PO_detail[0].hpo_total_befor_vat;
        productModal.grandTotal = response.data.PO_detail[0].hpo_total_price ? parseFloat(response.data.PO_detail[0].hpo_total_price)?.toFixed(2) : "0"
        productModal.product = response.data.productPO_detail;
        productModal.discountFinal = response.data.PO_detail[0].hpo_final_discount ? parseFloat(response.data.PO_detail[0].hpo_final_discount)?.toFixed(2) : "0";
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    let pushData = {
      company_id: getUser().com,
      oem_id: getOem(),
      dropdown_name: "currency",
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getDropdownAdvanceProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(function (response) {
        setProductModal({
          ...productModal,
          currency: response.data
            ? response.data.filter(
              (el) => el.currency_id === productModal.data.hpo_currency_id
            )
            : [],
        });
        productModal.currency = response.data
          ? response.data.filter(
            (el) => el.currency_id === productModal.data.hpo_currency_id
          )
          : [];
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    let dcArray = [];
    for (let i = 0; i < productModal.product.length; i++) {
      let data = {
        hpd_id: productModal.product[i].hpp_id,
      };
      await axios({
        method: "post",
        url:
          Configs.API_URL_cuteplus_sales +
          "/api/purchase_order/getDiscountProductPOHistory",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: data,
      })
        .then((response) => {
          let sublistDC = [];
          for (let y = 0; y < response.data.length; y++) {
            let discount = {};
            discount.dc_value = response.data[y].hpd_discount;
            discount.dc_unit = response.data[y].hpd_unit_discount;
            sublistDC.push(discount);
          }

          dcArray.push(
            sublistDC.length !== 0
              ? sublistDC
              : [{ dc_value: "0", dc_unit: "percent" }]
          );
          return dcArray;
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.error.message,
            icon: "error",
          });
        });
    }
    productModal.product.forEach((item, index) => {
      item.discounts = dcArray[index];
    });
    // console.log('dc2', productModal.product[0].discounts[1].dc_value);

    let newDetails = [];
    for (let i = 0; i < productModal.product.length; i++) {
      let Id_product = {
        product_id: productModal.product[i].hpp_product_id,
        company_id: getUser().com,
        oem_id: getOem(),
      };
      await axios({
        method: "post",
        url:
          Configs.API_URL_cuteplus +
          "/api/product/GetProductDetailsForQuotAdmin",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: Id_product,
      })
        .then((response) => {
          if (response.data.length !== 0) {
            newDetails.push(response.data[0]);
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.error.message,
            icon: "error",
          });
        });
    }
    // console.log('Details', newDetails)
    setProductModal({ ...productModal, detail: newDetails });
    productModal.detail = newDetails;
    let nameProduct = [];
    let nameUnit = [];
    for (let i = 0; i < productModal.detail.length; i++) {
      // console.log('productModal.detail[i]', productModal.detail[i]);
      if (productModal.typeText === "TH") {
        let productPO_name =
          productModal.detail[i].product_name !== null
            ? productModal.detail[i].product_name
            : "";
        nameProduct.push(productPO_name);

        if (productModal.product[i].hpp_quantity_unit === "Piece") {
          nameUnit.push("ชิ้น");
        } else if (productModal.product[i].hpp_quantity_unit === "pack") {
          nameUnit.push("แพ็ค");
        } else {
          nameUnit.push("ลัง");
        }
      } else if (productModal.typeText === "ENG") {
        let productPO_nameEng =
          productModal.detail[i].product_name_eng !== null
            ? productModal.detail[i].product_name_eng
            : "";
        nameProduct.push(productPO_nameEng);

        if (productModal.product[i].hpp_quantity_unit === "Piece") {
          nameUnit.push("Piece");
        } else if (productModal.product[i].hpp_quantity_unit === "pack") {
          nameUnit.push("Pack");
        } else {
          nameUnit.push("Ctn.");
        }
      } else {
        nameProduct.push(" - ");
      }
    }
    setModalData({
      ...modalData,
      product_Name: nameProduct,
      product_Unit: nameUnit,
    });
    modalData.product_Name = nameProduct;
    modalData.product_Unit = nameUnit;

    let calList = [];
    let sumSubTotal = 0;
    let listDC = [];
    for (let i = 0; i < productModal.product.length; i++) {
      let allQty =
        productModal.product[i].hpp_quantity_unit === "pack"
          ? parseFloat(productModal.detail[i].pd_sale_pc_pack) *
          parseFloat(productModal.product[i].hpp_quantity)
          : productModal.product[i].hpp_quantity_unit === "ctn"
            ? parseFloat(productModal.detail[i].pd_sale_pc_ctn) *
            parseFloat(productModal.product[i].hpp_quantity)
            : parseFloat(productModal.product[i].hpp_quantity);
      let origin_Price =
        parseFloat(productModal.product[i].hpp_prices) * allQty;
      // console.log("origin_Price", origin_Price);

      let textDC = "";
      let newPrice = origin_Price;
      let discountSum = 0;
      for (let j = 0; j < productModal.product[i].discounts.length; j++) {
        if (!productModal.product[i].discounts[j].dc_unit) continue;
        if (productModal.product[i].discounts[j].dc_unit === "percent") {
          /* console.log("first", newPrice);
          console.log(
            "dc%",
            (newPrice *
              parseFloat(productModal.product[i].discounts[j].dc_value)) /
              100
          ); */
          discountSum += (newPrice * parseFloat(productModal.product[i].discounts[j].dc_value) / 100);
          newPrice =
            newPrice -
            (newPrice *
              parseFloat(productModal.product[i].discounts[j].dc_value)) /
            100;
          // console.log("newPrice", newPrice);
        } else {
          // console.log("first", newPrice);
          discountSum += parseFloat(productModal.product[i].discounts[j].dc_value);
          newPrice =
            newPrice -
            parseFloat(productModal.product[i].discounts[j].dc_value);
          /* console.log(
            "dc",
            parseFloat(productModal.product[i].discounts[j].dc_value)
          ); */
          // console.log("newPrice", newPrice);
        }
        textDC +=
          productModal.product[i].discounts[j].dc_unit === "percent"
            ? productModal.product[i].discounts[j].dc_value + "% "
            : productModal.currency[0].currency_symbol +
            productModal.product[i].discounts[j].dc_value +
            " ";
        if (j !== productModal.product[i].discounts.length - 1) {
          textDC += "+ ";
        }
      }
      if (productModal.product[i].discounts.length !== 0) {
        listDC.push(productModal.currency[0].currency_symbol + discountSum);
      } else {
        listDC.push("-");
      }

      let eachPrice = newPrice;
      calList.push(
        eachPrice.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
      sumSubTotal += eachPrice;
    }
    // console.log('listDC', listDC)

    let subTotalOnly = sumSubTotal;
    // let grandTotalValue =sumSubTotal
    //   productModal.data.hpo_incl_vat === true
    //     ? sumSubTotal + sumSubTotal * 0.07
    //     : sumSubTotal;

    // grandTotalValue -= parseFloat(productModal.discountFinal || 0);
    // console.log('productModal', productModal) 
    setProductModal({
      ...productModal,
      Discount: listDC,
      beforeTotal: calList,
      subTotal: subTotalOnly.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      /* grandTotal: productModal.grandTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }), */
    });
    productModal.Discount = listDC;
    productModal.beforeTotal = calList;
    productModal.subTotal = subTotalOnly.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    /* productModal.grandTotal = productModal.grandTotal.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }); */
    // console.log('productModal', productModal)
    getModalData(index);
  }

  async function getModalData(index) {
    // console.log('index', index)
    let data = productModal.data;
    const PO_Date = data.hpo_date.split("T")[0].split("-");
    let formPODate = `${PO_Date[2]}/${PO_Date[1]}/${PO_Date[0]}`;

    const phoneNumber = data.hpo_sup_tel.replace(/-/g, "");
    const formattedPhoneNumber =
      phoneNumber.substring(0, 3) +
      "-" +
      phoneNumber.substring(3, 6) +
      "-" +
      phoneNumber.substring(6);

    setModalData({
      ...modalData,
      PO_No: data.hpo_no,
      PO_Date: formPODate,
      Ref: data.hpo_ref,
      Status: POAdmin.data[index].ps_name,
      Version: data.hpo_version,
      Sup_Name: data.hpo_sup_name,
      Sup_contactName: data.hpo_sup_contact_name || "-",
      Sup_Address: data.hpo_address,
      Contact_Tel: formattedPhoneNumber || "-",
      Contact_Email: data.hpo_sup_contact_email || "-",
      Remark: data.hpo_remark || "-",
      Vat: data.hpo_incl_vat,
      typeText: productModal.typeText,
      currency: productModal.currency[0].currency_symbol,
      Discount: productModal.Discount,
      beforeTotal: productModal.beforeTotal,
      subTotal: productModal.subTotal || "0",
      grandTotal: productModal.grandTotal || "0",
      product: productModal.product,
      detail: productModal.detail,
      discountFinal: POAdmin.data[index].hpo_final_discount
    });
    modalData.PO_No = data.hpo_no;
    modalData.PO_Date = formPODate;
    modalData.Ref = data.hpo_ref;
    modalData.Status = POAdmin.data[index].ps_name;
    modalData.Version = data.hpo_version;
    modalData.Sup_Name = data.hpo_sup_name;
    modalData.Sup_contactName = data.hpo_sup_contact_name || "-";
    modalData.Sup_Address = data.hpo_address;
    modalData.Contact_Tel = formattedPhoneNumber || "-";
    modalData.Contact_Email = data.hpo_sup_contact_email || "-";
    modalData.Remark = data.hpo_remark || "-";
    modalData.Vat = data.hpo_incl_vat;
    modalData.typeText = productModal.typeText;
    modalData.currency = productModal.currency[0].currency_symbol;
    modalData.Discount = productModal.Discount;
    modalData.beforeTotal = productModal.beforeTotal;
    modalData.subTotal = productModal.subTotal || "0";
    modalData.grandTotal = productModal.grandTotal || "0";
    modalData.product = productModal.product;
    modalData.detail = productModal.detail;
    modalData.discountFinal = POAdmin.data[index]?.hpo_final_discount || 0;
    // console.log('modalData', modalData)
  }

  function FilterPOAdmin() {
    let data = {
      user_id: getUser().fup,
      PO_No: Filter.PO_No === "" ? null : Filter.PO_No.trim(),
      supplier_name: Filter.supplier_name === "" ? null : Filter.supplier_name,
      create_by: Filter.create_by === "" ? null : Filter.create_by.trim(),
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/FilterPurchaseOrderAdmin",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        setPOAdmin({
          ...POAdmin,
          data: response.data
            ? response.data.filter(
              (el) => el.ps_name !== "Cancel" && el.hpo_version !== null
            )
            : [],
        });
        POAdmin.data = response.data.filter(
          (el) => el.ps_name !== "Cancel" && el.hpo_version !== null
        );
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function clear() {
    setFilter({
      ...Filter,
      PO_No: "",
      supplier_name: "",
      create_by: "",
    });
    Filter.PO_No = "";
    Filter.supplier_name = "";
    Filter.create_by = "";
    FilterPOAdmin();
  }

  function getReport() {
    axios(config_com)
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const [company, setCompany] = useState();
  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  const PurchaseOrderData = [];
  for (let index = 0; index < POAdmin.data.length; index++) {
    let checkStatus = POAdmin.data[index].ps_name;
    const rowItem = {};
    rowItem["No"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["PO_No"] = (
      <u
        style={{ color: "blue" }}
        key={POAdmin.data[index]}
        id={POAdmin.data[index].hpo_id}
        type="button"
        class="btn"
        onClick={() => {
          setModalPObyNo(true);
          getProductDetails(index, POAdmin.data[index].hpo_id);
        }}
      >
        {POAdmin.data[index].hpo_no}
      </u>
    );
    rowItem["PO_No2"] = POAdmin.data[index].hpo_no;

    rowItem["Supplier_Name"] = POAdmin.data[index].hpo_sup_name;
    rowItem["Price"] = parseFloat(POAdmin.data[index].hpo_total_price)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    rowItem["Create_by"] = POAdmin.data[index].hpo_created_name;
    rowItem["Approve_Name"] =
      POAdmin.data[index].ps_name !== "Pending"
        ? POAdmin.data[index].hpo_approve_user_name
        : "";
    rowItem["Status"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-12">
            <button
              type="button"
              disabled={true}
              style={{
                opacity: "1",
                width: "100%",
                border: "4px solid",
                borderRadius: "10px",
                paddingTop: ".2rem",
                paddingBottom: ".2rem",
              }}
              className={`btn btn-outline-${POAdmin.data[index].ps_name === "Pending"
                  ? "warning"
                  : POAdmin.data[index].ps_name === "Approved"
                    ? "success"
                    : POAdmin.data[index].ps_name === "Not Approved"
                      ? "danger"
                      : "dark"
                }`}
            >
              {POAdmin.data[index].ps_name}
            </button>
          </div>
        </div>
      </>
    );
    rowItem["Report"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-12">
            <button
              key={POAdmin.data[index]}
              id={POAdmin.data[index].hpo_id}
              type="button"
              style={{ backgroundColor: "#007BFF" }}
              className="btn btn-block btn-info"
              onClick={() => {
                getPOHistoryData(POAdmin.data[index].hpo_id);
                setPO_dataReport({ ...PO_dataReport, pic: [] });
                PO_dataReport.pic = [];
              }}
            >
              Report
            </button>
          </div>
        </div>
      </>
    );
    rowItem["Management"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
            minWidth: "240px",
          }}
        >
          <div className="col-6">
            <button
              key={POAdmin.data[index]}
              id={POAdmin.data[index].hpo_id}
              type="button"
              className="btn btn-block"
              style={{ backgroundColor: "#66CDAA" }}
              disabled={checkStatus === "Pending" ? false : true}
              onClick={() => {
                handleApproveClick(index);
              }}
            >
              Approve
            </button>
          </div>
          <div className="col-6">
            <button
              key={POAdmin.data[index]}
              id={POAdmin.data[index].hpo_id}
              type="button"
              className="btn btn-block"
              disabled={checkStatus === "Pending" ? false : true}
              style={{ backgroundColor: "#FFFF00" }}
              onClick={() => {
                handleNotApproveClick(index);
              }}
            >
              Not Approve
            </button>
          </div>
        </div>
      </>
    );
    PurchaseOrderData.push(rowItem);
  }
  const PurchaseOrderTable = {
    columns: [
      {
        label: "No",
        field: "No",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Purchase Order No.",
        field: "PO_No",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "Supplier_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Price",
        field: "Price",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Create by",
        field: "Create_by",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Approve Name",
        field: "Approve_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "Report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "Management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: PurchaseOrderData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Purchase Order (Admin)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Purchase Order(Admin)
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.PO_No}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        PO_No: e.target.value,
                      });
                      Filter.PO_No = e.target.value;
                    }}
                  />
                  <label>PO No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    className="form-control"
                    required
                    value={Filter.supplier_name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        supplier_name: e.target.value,
                      });
                      Filter.supplier_name = e.target.value;
                    }}
                  >
                    <option value="">--Select--</option>
                    {SupplierDD.map((data) => {
                      return (
                        <option value={data.sup_id}>{data.sup_name}</option>
                      );
                    })}
                  </select>
                  <label>Supplier</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.create_by}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        create_by: e.target.value,
                      });
                      Filter.create_by = e.target.value;
                    }}
                  />
                  <label>Create by</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      FilterPOAdmin();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Purchase Order (Admin)</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div
                          className="table-responsive"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <MDBDataTable
                            sortable={false}
                            disableRetreatAfterSorting={MDBT}
                            striped
                            className="table table-head-fixed"
                            bordered
                            hover
                            data={PurchaseOrderTable}
                          />
                          <Modal
                            size="xl"
                            className="Modal_Center_LeftRight"
                            isOpen={modalPObyNo}
                            toggle={() => {
                              setModalData({
                                ...modalData,
                                PO_No: "",
                                PO_Date: "",
                                Ref: "",
                                Status: "",
                                Version: "",
                                Sup_Name: "",
                                Sup_contactName: "",
                                Sup_Address: "",
                                Contact_Tel: "",
                                Contact_Email: "",
                                Remark: "",
                                Vat: "",
                                typeText: "",
                                currency: [],
                                Discount: [],
                                product_Name: [],
                                product_Unit: [],
                                beforeTotal: [],
                                subTotal: "",
                                grandTotal: "",
                                product: [],
                                detail: [],
                              });

                              setModalPObyNo(false);
                            }}
                          >
                            <ModalHeader
                              toggle={() => {
                                setModalData({
                                  ...modalData,
                                  PO_No: "",
                                  PO_Date: "",
                                  Ref: "",
                                  Status: "",
                                  Version: "",
                                  Sup_Name: "",
                                  Sup_contactName: "",
                                  Sup_Address: "",
                                  Contact_Tel: "",
                                  Contact_Email: "",
                                  Remark: "",
                                  Vat: "",
                                  typeText: "",
                                  currency: [],
                                  Discount: [],
                                  product_Name: [],
                                  product_Unit: [],
                                  beforeTotal: [],
                                  subTotal: "",
                                  grandTotal: "",
                                  product: [],
                                  detail: [],
                                });
                                setModalPObyNo(false);
                              }}
                            >
                              <h4 className="modal-title" id="modalContrat">
                                <b style={{ paddingLeft: "1rem" }}>
                                  Purchase Order Detail
                                </b>
                              </h4>
                            </ModalHeader>

                            <ModalBody>
                              <div
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "2rem",
                                }}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Purchase Order No : {modalData.PO_No}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Purchase Order Date : {modalData.PO_Date}
                                    </h4>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Ref. : {modalData.Ref}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Status : {modalData.Status}
                                    </h4>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Version : {modalData.Version}
                                    </h4>
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Supplier Name : {modalData.Sup_Name}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Supplier Contact point name :{" "}
                                      {modalData.Sup_contactName}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Supplier Address : {modalData.Sup_Address}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-5 col-xl-3">
                                    <h4
                                      style={{
                                        flexWrap: "nowrap",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Contact Tel : {modalData.Contact_Tel}
                                    </h4>
                                  </div>
                                  <div className="col-12 col-md-7 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Contact E-mail : {modalData.Contact_Email}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Remark : {modalData.Remark}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      VAT :{" "}
                                      {modalData.Vat === true
                                        ? "include VAT"
                                        : "not include VAT"}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-12 col-xl-12">
                                    <div
                                      className="table-responsive"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      <Table striped bordered hover size="lg">
                                        <thead>
                                          <tr>
                                            <th>No.</th>
                                            <th>Product Code</th>
                                            <th>Product Name</th>
                                            <th>Amount</th>
                                            <th>Retail Price</th>
                                            <th>Discount</th>
                                            <th>Price</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {productModal.product?.map(
                                            (rowData, index) => (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {index + 1}
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {modalData.detail[index] &&
                                                    modalData.detail[index]
                                                      .product_code
                                                    ? modalData.detail[index]
                                                      .product_code
                                                    : "-"}
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  {modalData.product_Name[index]
                                                    ? modalData.product_Name[
                                                    index
                                                    ]
                                                    : "-"}
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {rowData.hpp_quantity?.toString().replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  ) +
                                                    " " +
                                                    (modalData.product_Unit[
                                                      index
                                                    ]
                                                      ? modalData.product_Unit[
                                                      index
                                                      ] || "-"
                                                      : "-")}
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {/* {modalData.currency} */}
                                                  {rowData.hpp_prices?.toString().replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {productModal.Discount[index] ? productModal.Discount[index] : "-"}
                                                </td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {/* {modalData.currency} */}
                                                  {productModal.beforeTotal[index]?.toString().replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                          <tr style={{ background: "#ffffff" }}>
                                            <td
                                              colSpan={5}
                                              style={{
                                                visibility: "hidden",
                                                border: "none",
                                              }}
                                            ></td>
                                            <td style={{ textAlign: "center" }}>
                                              Total
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {/* {modalData.currency} */}
                                              {productModal.subTotal?.toString().replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                            </td>
                                          </tr>
                                          <tr style={{ background: "#ffffff" }}>
                                            <td
                                              colSpan={5}
                                              style={{
                                                visibility: "hidden",
                                                border: "none",
                                              }}
                                            ></td>
                                            <td style={{ textAlign: "center" }}>
                                              Discount final
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {/* {modalData.currency} */}
                                              {productModal.discountFinal?.toString().replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              ) || 0}
                                            </td>
                                          </tr>
                                          <tr style={{ background: "#ffffff" }}>
                                            <td
                                              colSpan={5}
                                              style={{
                                                visibility: "hidden",
                                                border: "none",
                                              }}
                                            ></td>
                                            <td style={{ textAlign: "center" }}>
                                              Total + Vat
                                            </td>
                                            <td style={{ textAlign: "right" }}>
                                              {/* {modalData.currency} */}
                                              {productModal.grandTotal?.toString().replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ModalBody>

                            <ModalFooter>
                              <button
                                type="button"
                                style={{ width: "100px" }}
                                className="btn btn-success"
                                onClick={async () => {
                                  setModalPObyNo(false);
                                  setProductModal({
                                    data: [],
                                    typeText: "",
                                    currency: [],
                                    Discount: [],
                                    beforeTotal: [],
                                    subTotal: "",
                                    grandTotal: "",
                                    product: [],
                                    detail: [],
                                  });
                                  productModal.data = [];
                                  productModal.typeText = "";
                                  productModal.currency = [];
                                  productModal.Discount = [];
                                  productModal.beforeTotal = [];
                                  productModal.subTotal = "";
                                  productModal.grandTotal = "";
                                  productModal.product = [];
                                  productModal.detail = [];
                                }}
                              >
                                ตกลง
                              </button>
                            </ModalFooter>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Table_Purchase_Order_Admin;
