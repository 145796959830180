import React, { Component, useEffect, useRef, useState } from "react";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import DatePicker from "react-datepicker";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import NumberFormat from "react-number-format";
import axios from "axios";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Product from "../../../WarehousMGT/Product";

function Receive_PO_Table() {
  const [MDBT, setMDBT] = useState(true);
  const closeModal = useRef();
  const closeClickModal = () => {
    closeModal.current.click();
  };
  const [receivePOFilter, setReceivePOFilter] = useState({
    t_po_no: "",
    t_supplier_id: "",
    t_recieve_status: "",
  });
  const [supplierDD, setSupplierDD] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState({
    data: [],
  });
  let [status_list_filter, setStatus_list_filter] = useState([]);

  const [test, settest] = useState({
    test: "",
  });
  const [ProductPO, setProductPO] = useState({
    data: [],
  });
  const [CustomerData, setCustomerData] = useState([]);
  const [CustomerOption, setCustomerOption] = useState({
    data: [],
  });

  const [SaleData, setSaleData] = useState([]);
  const [SaleOption, setSaleOption] = useState({
    data: [],
  });
  const [StockInModal, setStockInModal] = useState(false);
  const [StockInSRNo, setStockInSRNo] = useState({
    SRNo: "",
    Product: [],
    IsCal: false,
  });

  useEffect(async () => {
    await getSupplierDD();
    await getDropdownStatus();
    await GetUserSales();
    await GetCustomer();
    await GetProductPO();
  }, []);

  useEffect(async () => {
    if (StockInSRNo.Product.length > 0 && StockInSRNo.IsCal) {
      await SumAmount();
      setStockInSRNo({
        ...StockInSRNo,
        IsCal: false,
      });
      StockInSRNo.IsCal = false;
    }
  }, [StockInSRNo.IsCal]);

  useEffect(() => {
    const temp = StockInSRNo.Product?.map((tl) => {
      tl.sale_list = SaleData?.map((el) => {
        return { sale: "", sale_id: el.value, sale_name: el.label };
      });
      return tl;
    });
    setStockInSRNo({
      ...StockInSRNo,
      Product: temp,
    });
    StockInSRNo.Product = temp;
  }, [SaleData]);

  async function getSupplierDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getSupplierDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setSupplierDD(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getDropdownStatus = async () => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/getPO_ReceiveStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: { data: "" },
    })
      .then(async function (response) {
        const options = [];
        for (let item of response.data) {
          // console.log('item', item);
          let temp = {
            label: item.prs_name,
            value: item.prs_id,
          };
          options.push(temp);
        }
        // console.log('options', options);
        setOptionsStatus({ ...optionsStatus, data: options });
        optionsStatus.data = options;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function GetCustomer() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setCustomerData(response.data);
        let temp = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.cus_id;
          element["label"] = el.cus_name;
          temp.push(element);
        });
        setCustomerOption({
          ...CustomerData,
          data: temp,
        });
        CustomerData.data = temp;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetUserSales() {
    let tempData = {
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/users/getUserSales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // console.log(response.data);
        // setSaleData(response.data);
        let temp = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.fullname;
          element["sales"] = [];
          temp.push(element);
        });
        setSaleOption({
          ...SaleData,
          data: temp,
        });
        SaleOption.data = temp;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const Clearfilter = () => {
    setReceivePOFilter({
      t_po_no: "",
      t_supplier_id: "",
      t_recieve_status: "",
    });
    receivePOFilter.t_po_no = "";
    receivePOFilter.t_supplier_id = "";
    receivePOFilter.t_recieve_status = "";
    setStatus_list_filter([]);
    status_list_filter = [];
    GetProductPO();
  };

  async function GetProductPO() {
    let temp = {
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      po_no: receivePOFilter.t_po_no?.trim() || null,
      sup_id: receivePOFilter.t_supplier_id || null,
      status: status_list_filter.length === 0 ? null : status_list_filter,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/getProductPO_Receive",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        console.log("response.data :>> 1325465", response.data);
        if (
          response.data &&
          response.data.length > 0 &&
          response.data[0].po_products.length > 0
        ) {
          await getDataDetailsProduct(response.data);
        } else {
          setProductPO({ data: [] });
          ProductPO.data = [];
        }
        // setProductPO(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getDataDetailsProduct = async (res) => {
    let temp = {
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      products: res,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus + "/api/product/getDataDetailsProductFor_PO",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        console.log("response.data :>> getDataDetailsProduct", response.data);
        if (response.data) {
          setProductPO({ data: response.data });
          ProductPO.data = response.data;

          if (StockInSRNo.POId) {
            const check = ProductPO.data.find(
              (el) => el.po_id === StockInSRNo.POId
            );

            if (check) {
              console.log('check', check);
              setStockInSRNo({
                ...StockInSRNo,
                SRNo: check.po_no,
                POId: check.po_id,
                SupId: check.po_sup_id,
                Product: check.po_products,
                IsCal: true,
              });
              StockInSRNo.SRNo = check.po_no;
              StockInSRNo.POId = check.po_id;
              StockInSRNo.Product = check.po_products;
              StockInSRNo.IsCal = true;
              setSaleData([]);
              await getImageProductReceive(StockInSRNo.Product);
            }
          }
        }
        // setProductPO(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const checkErrorForSave = async (is_confirm) => {
    let error_list = [];

    if (SaleData.length === 0 && !is_confirm) {
      let temp_err = {
        message: `กรุณาเลือก Sale ก่อนทำรายการถัดไป`,
      };
      error_list.push(temp_err);
    } else {
      for (let item of StockInSRNo.Product) {
        if (item.pop_receive_amount) {
          item.pop_receive_amount =
            typeof item.pop_receive_amount === "string"
              ? parseFloat(item.pop_receive_amount?.replace(/,/g, "") || 0)
              : item.pop_receive_amount || 0;
        }

        if (item.max_receive) {
          item.max_receive =
            typeof item.max_receive === "string"
              ? parseFloat(item.max_receive?.replace(/,/g, "") || 0)
              : item.max_receive || 0;
        }

        if (item.receive) {
          item.receive =
            typeof item.receive === "string"
              ? parseFloat(item.receive?.replace(/,/g, "") || 0)
              : item.receive || 0;
        }

        if (item.remark) {
          item.remark = item.remark?.toString().trim() || null;
        }

        let received = parseFloat(item.pop_receive_amount || 0);
        let max_receive = parseFloat(item.max_receive || 0);
        let balance_receive = max_receive - received;
        let receive_input =
          typeof item.receive === "string"
            ? parseFloat(item.receive?.replace(/,/g, "") || 0)
            : item.receive || 0;
        console.log("in recevie", receive_input);

        if (balance_receive === receive_input) {
          item.is_receive = true;
        } else {
          item.is_receive = false;
        }

        if (receive_input === 0) {
          continue;
        } else if (SaleData.length === 0) {
          let temp_err = {
            message: `กรุณาเลือก Sale ก่อนทำรายการถัดไป`,
          };
          error_list.push(temp_err);
          break;
        }

        if (received === max_receive && receive_input) {
          let temp_err = {
            message: `Product ${item.product_code}, รับสินค้าครบจำจวนแล้ว`,
          };
          error_list.push(temp_err);
        }

        if (receive_input > balance_receive) {
          let temp_err = {
            message: `Product ${item.product_code}, Receive Order (Piece) ไม่สามารถรับสินค้าเกินจำนวนได้`,
          };
          error_list.push(temp_err);
        }

        if (!item.exp_date) {
          let temp_err = {
            message: `Product ${item.product_code}, กรุณาเลือก Exp. Date`,
          };
          error_list.push(temp_err);
        }

        let sum_sale = 0;
        for (let sale of item.sale_list) {
          if (sale.sale) {
            let sale_input = parseFloat(sale.sale || 0);
            sum_sale += sale_input;

            sale.sale =
              typeof sale.sale === "string"
                ? parseFloat(sale.sale?.replace(/,/g, "") || 0)
                : sale.sale || 0;
          }
        }
        // console.log('sum_sale > balance_receive', sum_sale , balance_receive)
        if (item.sale_list.length === 1 && !item.sale_list[0].sale) {
          let temp_err = {
            message: `Product ${item.product_code}, กรุณากรอกจำนวนรับในช่อง ${SaleData[0].label}`,
          };
          error_list.push(temp_err);
        } else if (sum_sale !== receive_input) {
          let temp_err = {
            message:
              SaleData.length === 1
                ? `Product ${item.product_code}, Sale ต้องรับสินค้าเท่ากับจำนวน Receive Order (Piece) เท่านั้น`
                : `Product ${item.product_code}, ผลรวมของ Sale ต้องเท่ากับ Receive Order (Piece)`,
          };
          error_list.push(temp_err);
        }
      }
    }

    return error_list;
  };

  const saveOption = async (status) => {
    console.log("StockInSRNo", StockInSRNo);
    Swal.fire({
      title: status
        ? "คุณมั่นใจว่าคุณจะ Confirm PO นี้ใช่หรือไม่"
        : "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave(status);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              products: StockInSRNo.Product,
              po_no: StockInSRNo.SRNo,
              sup_id: StockInSRNo.SupId,
              po_id: StockInSRNo.POId,
              is_confirm: status,
            };
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus_sales +
                `/api/purchase_order/updatePO_Product_Receive`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                // saveLog
                await SaveLogProduct(response.data, temp, status);
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  };

  const SaveLogProduct = async (is_close, temp, status) => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus + `/api/log_product/AddInLogOfProduct_FromPO`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        Swal.fire({
          icon: "success",
          title: status ? "Confirm PO สำเร็จ" : "บันทึกข้อมูลสำเร็จ",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 2000,
        }).then(async () => {
          if (status === true || is_close === true) {
            setStockInModal(false);
          }
          await GetProductPO();
          /* window.location.href =
                        "/Warehouse_Management/purchase_order_cuteplus"; */
        });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const Custom_Input_date = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const getImageProductReceive = async (products) => {
    let data = new FormData();
    data.append("company_id", getUser().com);
    data.append("oem_id", getOem());
    data.append("user_id", getUser().fup);
    // console.log("products", products);
    data.append("products", JSON.stringify(products));

    await axios({
      method: "post",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    })
      .then(async (response) => {
        if (response.data) {
          setStockInSRNo({
            ...StockInSRNo,
            Product: response.data,
          });
          StockInSRNo.Product = response.data;
        }
      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
        });
      });
    setMDBT(true);
  };

  async function SumAmount() {
    let temp = [...StockInSRNo.Product];

    for (let item of temp) {
      let sum = 1;
      if (item.pop_quantity_unit.toLowerCase() === "pack") {
        sum = parseFloat(item.pd_sale_pc_pack || 0);
      } else if (item.pop_quantity_unit.toLowerCase() === "carton" || item.pop_quantity_unit.toLowerCase() === "ctn") {
        sum = parseFloat(item.pd_sale_pc_ctn || 0);
      }
      item.receive =
        sum * parseFloat(item.pop_quantity || 0) -
        parseFloat(item.pop_receive_amount || 0);
      item.max_receive = sum * parseFloat(item.pop_quantity || 0);
      // console.log('item.receive', item.receive);
    }
    setStockInSRNo({
      ...StockInSRNo,
      Product: temp,
    });
    StockInSRNo.Product = temp;
  }

  const rowsData = [];
  for (let index = 0; index < ProductPO.data.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["sr_no"] = (
      <div style={{ textAlign: "center" }}>
        {" "}
        {ProductPO.data[index].po_no || ""}{" "}
      </div>
    );
    rowItem["sr_no2"] = ProductPO.data[index].po_no || "";

    rowItem["supplier"] = (
      <div style={{ textAlign: "center" }}>
        {ProductPO.data[index].po_sup_name || ""}
      </div>
    );
    rowItem["supplier"] = ProductPO.data[index].po_sup_name || "";

    rowItem["date_exp"] = (
      <div style={{ textAlign: "center" }}>
        {ProductPO.data[index].po_receive_date
          ? moment(new Date(ProductPO.data[index].po_receive_date)).format(
              "DD-MM-YYYY"
            )
          : "-"}
      </div>
    );
    rowItem["date_exp2"] = ProductPO.data[index].po_receive_date
      ? moment(new Date(ProductPO.data[index].po_receive_date)).format(
          "DD-MM-YYYY"
        )
      : "-";

    rowItem["receive_s"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          type="button"
          disabled={true}
          style={{
            opacity: "1",
            width: "100%",
            border: "4px solid",
            borderRadius: "10px",
            paddingTop: ".2rem",
            paddingBottom: ".2rem",
          }}
          className={`btn btn-outline-${
            ProductPO.data[index].prs_name === "รับสินค้าเรียบร้อย"
              ? "success"
              : ProductPO.data[index].prs_name === "รับสินค้าบางส่วน"
              ? "warning"
              : ProductPO.data[index].prs_name === "รอรับสินค้า"
              ? "primary"
              : ProductPO.data[index].prs_name === "Not Approved"
              ? "danger"
              : "primary"
          }`}
        >
          {ProductPO.data[index].prs_name}
        </button>
      </div>
    );
    rowItem["receive_s2"] = ProductPO.data[index].prs_name;

    rowItem["receive_p"] = (
      <div style={{ textAlign: "center" }}>
        {ProductPO.data[index].percentage?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) ||
          0 ||
          0}{" "}
        %
      </div>
    );
    rowItem["receive_p2"] =
      ProductPO.data[index].percentage?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) ||
      0 ||
      0;

    rowItem["management"] = (
      <div>
        {ProductPO.data[index].po_products.length > 0 ? (
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-primary"
                disabled={
                  ProductPO.data[index].prs_name === "รับสินค้าเรียบร้อย"
                    ? true
                    : false
                }
                onClick={async () => {
                  setStockInSRNo({
                    ...StockInSRNo,
                    SRNo: ProductPO.data[index].po_no,
                    SupId: ProductPO.data[index].po_sup_id,
                    POId: ProductPO.data[index].po_id,
                    Product: ProductPO.data[index].po_products,
                    IsCal: true,
                  });
                  StockInSRNo.SRNo = ProductPO.data[index].po_no;
                  StockInSRNo.POId = ProductPO.data[index].po_id;
                  StockInSRNo.Product = ProductPO.data[index].po_products;
                  StockInSRNo.IsCal = true;
                  setSaleData([]);
                  await getImageProductReceive(StockInSRNo.Product);
                  setStockInModal(true);
                }}
              >
                รับสินค้า
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button type="button" className="btn btn-secondary" disabled>
                รับสินค้า
              </button>
            </div>
          </div>
        )}
      </div>
    );
    rowsData.push(rowItem);
  }
  const ProductPOTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        width: 50,
      },
      {
        label: "PO No.",
        field: "sr_no",
        width: 50,
      },
      {
        label: "Supplier Name",
        field: "supplier",
        width: 50,
      },
      {
        label: "Receive Date",
        field: "date_exp",
        width: 50,
      },
      {
        label: "Receive Status",
        field: "receive_s",
        width: 50,
      },
      {
        label: "Receive (%)",
        field: "receive_p",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  const rowsData2 = [];
  for (let index = 0; index < StockInSRNo.Product.length; index += 1) {
    const rowItem = {};
    rowItem["product_code"] = (
      <div style={{ textAlign: "center" }}>
        {StockInSRNo.Product[index].product_code}
      </div>
    );
    rowItem["product_code2"] = StockInSRNo.Product[index].product_code;

    rowItem["barcode"] = (
      <div style={{ textAlign: "center" }}>
        {StockInSRNo.Product[index].pb_piece || "-"}
      </div>
    );
    rowItem["barcode2"] = StockInSRNo.Product[index].pb_piece || "-";

    rowItem["product_name"] = (
      <div style={{ textAlign: "center" }}>
        {StockInSRNo.Product[index].product_name}
      </div>
    );
    rowItem["product_name2"] = StockInSRNo.Product[index].product_name;

    rowItem["product_image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            StockInSRNo.Product[index].pci_img_path !== "" &&
            StockInSRNo.Product[index].pci_img_path !== null &&
            StockInSRNo.Product[index].pci_img_path !== undefined
              ? StockInSRNo.Product[index].pci_img_path?.indexOf("blob") >= 0
                ? StockInSRNo.Product[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${StockInSRNo.Product[index].pci_img_name}/${StockInSRNo.Product[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );
    rowItem["require_quantity"] = (
      <div style={{ textAlign: "center" }}>
        {StockInSRNo.Product[index].pop_quantity +
          " " +
          StockInSRNo.Product[index].pop_quantity_unit}
      </div>
    );

    rowItem["product_cn"] = (
      <div style={{ textAlign: "center" }}>
        {StockInSRNo.Product[index].pop_quantity_unit.toLowerCase() === "piece"
          ? (StockInSRNo.Product[index].pop_receive_amount || 0) +
            "/" +
            StockInSRNo.Product[index].pop_quantity
          : StockInSRNo.Product[index].pop_quantity_unit.toLowerCase() ===
            "pack"
          ? (StockInSRNo.Product[index].pop_receive_amount || 0) +
            "/" +
            parseInt(StockInSRNo.Product[index].pop_quantity) *
              parseInt(StockInSRNo.Product[index].pd_sale_pc_pack)
          : StockInSRNo.Product[index].pop_quantity_unit.toLowerCase() ===
              "carton" ||
            StockInSRNo.Product[index].pop_quantity_unit.toLowerCase() === "ctn"
          ? (StockInSRNo.Product[index].pop_receive_amount || 0) +
            "/" +
            parseInt(StockInSRNo.Product[index].pop_quantity) *
              parseInt(StockInSRNo.Product[index].pd_sale_pc_ctn)
          : "ไม่สามารถคำนวณได้"}
      </div>
    );
    rowItem["receive_order"] = (
      <div className="form-group" style={{ textAlign: "center" }}>
        <NumberFormat
          type="text"
          className="form-control"
          thousandSeparator={true}
          allowNegative={false}
          thousandsGroupStyle="thousand"
          decimalScale={0}
          hidden={StockInSRNo.Product[index].pop_is_receive}
          value={StockInSRNo.Product[index].receive}
          onChange={(e) => {
            let temp = [...StockInSRNo.Product];
            temp[index].receive = e.target.value;
            setStockInSRNo({
              ...StockInSRNo,
              Product: temp,
            });
            StockInSRNo.Product = temp;
          }}
        ></NumberFormat>
      </div>
    );
    rowItem["exp_date"] = (
      <div
        className="col-12"
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "10px",
          flexDirection: "row",
          alignItems: "center",
          minWidth: "160px"
        //   width: "10rem",
        }}
        hidden={StockInSRNo.Product[index].pop_is_receive}
        onClick={() => {
          let temp = [...StockInSRNo.Product];
          for (let item of temp) {
            item.isZIndex = false;
          }
          temp[index].isZIndex = true;
          setStockInSRNo({
            ...StockInSRNo,
            Product: temp,
          });
          StockInSRNo.Product = temp;
        }}
      >
        <div
          className="form-group"
          style={{ zIndex: StockInSRNo.Product[index].isZIndex ? "9999" : "0", width: "100%" }}
        >
          <DatePicker
            wrapperClassName="w-100"
            selected={StockInSRNo.Product[index].exp_date || ""}
            dateFormat={"dd-MM-yyyy"}
            hidden={StockInSRNo.Product[index].pop_is_receive}
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ["bottom"], // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false, // turn off since needs preventOverflow to be enabled
              },
            }}
            onChange={async (date) => {
              let temp = [...StockInSRNo.Product];
              temp[index].exp_date = date;
              setStockInSRNo({
                ...StockInSRNo,
                Product: temp,
              });
              StockInSRNo.Product = temp;
            }}
            customInput={<Custom_Input_date />}
            minDate={new Date()}
          />
        </div>
      </div>
    );
    if (
      StockInSRNo.Product[index].sale_list &&
      StockInSRNo.Product[index].sale_list.length > 0
    ) {
      for (
        let index2 = 0;
        index2 < StockInSRNo.Product[index].sale_list.length;
        index2++
      ) {
        rowItem[`sale${index2 + 1}`] = (
          <div className="form-group" style={{ textAlign: "center" }}>
            <NumberFormat
              type="text"
              className="form-control"
              hidden={StockInSRNo.Product[index].pop_is_receive}
              thousandSeparator={true}
              allowNegative={false}
              thousandsGroupStyle="thousand"
              decimalScale={0}
              value={StockInSRNo.Product[index].sale_list[index2].sale}
              onChange={(e) => {
                let temp = [...StockInSRNo.Product];
                temp[index].sale_list[index2].sale = e.target.value;
                setStockInSRNo({
                  ...StockInSRNo,
                  Product: temp,
                });
                StockInSRNo.Product = temp;
              }}
            ></NumberFormat>
          </div>
        );
      }
    }
    rowItem["remark"] = (
      <div className="form-group" style={{ textAlign: "center", minWidth: "160px" }}>
        <input
          type="text"
          className="form-control"
          hidden={StockInSRNo.Product[index].pop_is_receive}
          value={StockInSRNo.Product[index].remark}
          onChange={(e) => {
            let temp = [...StockInSRNo.Product];
            temp[index].remark = e.target.value;
            setStockInSRNo({
              ...StockInSRNo,
              Product: temp,
            });
            StockInSRNo.Product = temp;
          }}
        ></input>
      </div>
    );
    rowsData2.push(rowItem);
  }

  const StockInTable = {
    columns: [
      {
        label: "Product Code",
        field: "product_code",
        width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        width: 50,
      },
      {
        label: "Product Image",
        field: "product_image",
        width: 50,
      },
      {
        label: "Require Quantity",
        field: "require_quantity",
        width: 50,
      },
      {
        label: "Receive (Piece)",
        field: "product_cn",
        width: 50,
      },
      {
        label: "Receive Order (Piece)",
        field: "receive_order",
        width: 50,
      },
      {
        label: "Exp. Date",
        field: "exp_date",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (SaleData.length > 0) {
    for (let index = 0; index < SaleData.length; index++) {
      StockInTable.columns.splice(-1, 0, {
        label: `${SaleData[index].label}`,
        field: `sale${index + 1}`,
        width: 50,
      });
    }
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Receive By PO {"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Receive By PO</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-3 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={receivePOFilter.t_po_no}
                    onChange={(e) => {
                      setReceivePOFilter({
                        ...receivePOFilter,
                        t_po_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">PO No.</label>{" "}
                </div>
              </div>
              <div
                className="col-6 col-md-3 col-xl-2"
                style={{ zIndex: "989" }}
              >
                <div className="form-group">
                  <select
                    type="text"
                    className="form-control"
                    required
                    value={receivePOFilter.t_supplier_id}
                    onChange={(e) => {
                      setReceivePOFilter({
                        ...receivePOFilter,
                        t_supplier_id: e.target.value || "",
                      });
                      receivePOFilter.t_supplier_id = e.target.value;
                    }}
                  >
                    <option key="" value="">
                      Select a supplier
                    </option>
                    {supplierDD.map((option, index) => (
                      <option key={option.sup_name} value={option.sup_id}>
                        {option.sup_name}
                      </option>
                    ))}
                  </select>
                  <label>Supplier Name</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                {/* <label></label> */}
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    options={optionsStatus.data}
                    value={status_list_filter}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Receive Status
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetProductPO();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => Clearfilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12" style={{ overflow: "overlay" }}>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed"
                          disableRetreatAfterSorting={MDBT}
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={ProductPOTable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>

      <Modal
        isOpen={StockInModal}
        toggle={() => {
          setStockInModal(false);
        }}
        size="xl"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader>
          <div className="row">
            <div className="col-12">
              <h5>Receive</h5>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={StockInSRNo.SRNo}
                ></input>
                <label>PO No.</label>
              </div>
            </div>
            <div className="col-4 col-md-3 col-xl-2">
              <div className="" style={{ position: "relative" }}>
                <MultiSelect
                  className="mt-2"
                  hasSelectAll={false}
                  options={SaleOption.data}
                  value={SaleData}
                  onChange={setSaleData}
                  labelledBy="Select"
                />
                <label
                  style={{
                    position: "absolute",
                    top: "-22px",
                    color: "#004fa4",
                  }}
                >
                  Sales
                </label>
              </div>
            </div>
            <div className="col-4 col-md-3 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-success"
                  onClick={() => {
                    saveOption(false);
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ overflow: "overlay" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  disableRetreatAfterSorting={MDBT}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={StockInTable}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: "0 2rem", display: "block" }}>
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-4 col-md-3 col-xl-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-danger"
                  onClick={() => {
                    setStockInModal(false);
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
            <div className="col-4 col-md-3 col-xl-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    saveOption(true);
                  }}
                >
                  เสร็จสิ้น
                </button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {/*   <Footter/> */}
    </div>
  );
}

export default Receive_PO_Table;
