import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Configs from "../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { useParams } from "react-router";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Modal_IO_History({ dataModal, modalStatus, setModalStatus }) {
  const [tabData, setTabData] = useState({
    mode: "In_Stock",
    in_stock: [],
    history: [],
  });

  const [wrpData, setWrpData] = useState({
    warehouseData: [],
    rackData: [],
    positionData: [],
  });

  async function GetWarehouseForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/configUnai/getWarehouseForDropDownAll`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setWrpData({
          ...wrpData,
          warehouseData: response_data.data.data,
        });
        wrpData.warehouseData = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetRackForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      allData: true,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getRackForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setWrpData({
          ...wrpData,
          rackData: response_data.data.data,
        });
        wrpData.rackData = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetPositionForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      allData: true,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getPositionForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setWrpData({
          ...wrpData,
          positionData: response_data.data.data,
        });
        wrpData.positionData = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetLotProductInWarehouse() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
      product_id: dataModal.prod_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getLotProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setTabData({
          ...tabData,
          in_stock: response_data.data,
        });
        tabData.in_stock = response_data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetHistoryProductInWarehouse() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
      product_id: dataModal.prod_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getHistoryProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setTabData({
          ...tabData,
          history: response_data.data,
        });
        tabData.history = response_data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  function SetTableStockData(index) {
    const rowItem = {};

    rowItem["lot_no"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.in_stock[index].fg_lot_no}
      </span>
    );
    rowItem["lot_no_hide"] = tabData.in_stock[index].fg_lot_no;

    rowItem["warehosue"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {
          wrpData.warehouseData.find(
            (el) => el.value == tabData.in_stock[index].lp_wh_id
          )?.label
        }
      </span>
    );
    rowItem["warehosue_hide"] = wrpData.warehouseData.find(
      (el) => el.value == tabData.in_stock[index].lp_wh_id
    )?.label;

    rowItem["rack"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {
          wrpData.rackData.find(
            (el) => el.value == tabData.in_stock[index].lp_rack_id
          )?.label
        }
      </span>
    );
    rowItem["rack_hide"] = wrpData.rackData.find(
      (el) => el.value == tabData.in_stock[index].lp_rack_id
    )?.label;

    rowItem["position"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {
          wrpData.positionData.find(
            (el) => el.value == tabData.in_stock[index].lp_rp_id
          )?.label
        }
      </span>
    );
    rowItem["position_hide"] = wrpData.positionData.find(
      (el) => el.value == tabData.in_stock[index].lp_rp_id
    )?.label;

    rowItem["amount"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.in_stock[index].lp_amount}
      </span>
    );
    rowItem["amount_hide"] = tabData.in_stock[index].lp_amount;

    return rowItem;
  }

  const rowsStock = [];
  for (let index = 0; index < tabData.in_stock.length; index += 1) {
    rowsStock.push(SetTableStockData(index));
  }

  const tableStock = {
    columns: [
      {
        label: "Lot No",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Warehosue",
        field: "warehosue",
        sort: "asc",
        width: 50,
      },
      {
        label: "Rack",
        field: "rack",
        sort: "asc",
        width: 50,
      },
      {
        label: "Position",
        field: "position",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
    ],

    rows: rowsStock,
  };

  function SetTableHistoryData(index) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["lot_no"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.history[index].fg_lot_no}
      </span>
    );
    rowItem["lot_no_hide"] = tabData.history[index].fg_lot_no;

    rowItem["warehosue"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {
          wrpData.warehouseData.find(
            (el) => el.value == tabData.history[index].lp_wh_id
          )?.label
        }
      </span>
    );
    rowItem["warehosue_hide"] = wrpData.warehouseData.find(
      (el) => el.value == tabData.history[index].lp_wh_id
    )?.label;

    rowItem["rack"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {
          wrpData.rackData.find(
            (el) => el.value == tabData.history[index].lp_rack_id
          )?.label
        }
      </span>
    );
    rowItem["rack_hide"] = wrpData.rackData.find(
      (el) => el.value == tabData.history[index].lp_rack_id
    )?.label;

    rowItem["position"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {
          wrpData.positionData.find(
            (el) => el.value == tabData.history[index].lp_rp_id
          )?.label
        }
      </span>
    );
    rowItem["position_hide"] = wrpData.positionData.find(
      (el) => el.value == tabData.history[index].lp_rp_id
    )?.label;

    rowItem["status"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.history[index].edit_type ? "In" : "Out"}
      </span>
    );
    rowItem["status_hide"] = tabData.history[index].edit_type ? "In" : "Out";

    rowItem["amount"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.history[index].amount}
      </span>
    );
    rowItem["amount_hide"] = tabData.history[index].amount;

    rowItem["date"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {moment(tabData.history[index].create_date).format(
          "DD/MM/YYYY :: HH:mm น."
        )}
      </span>
    );
    rowItem["date_hide"] = moment(tabData.history[index].create_date).format(
      "DD/MM/YYYY :: HH:mm น."
    );

    return rowItem;
  }

  const rowsHistory = [];
  for (let index = 0; index < tabData.history.length; index += 1) {
    rowsHistory.push(SetTableHistoryData(index));
  }

  const tableHistory = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Warehosue",
        field: "warehosue",
        sort: "asc",
        width: 50,
      },
      {
        label: "Rack",
        field: "rack",
        sort: "asc",
        width: 50,
      },
      {
        label: "Position",
        field: "position",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],

    rows: rowsHistory,
  };

  useEffect(async () => {
    await GetWarehouseForDropDown();
    await GetRackForDropDown();
    await GetPositionForDropDown();

    if (modalStatus.is_open) {
      await GetLotProductInWarehouse();
      await GetHistoryProductInWarehouse();
    }
  }, [modalStatus.is_open]);

  return (
    <Modal
      size="xl"
      className="Modal_Center_LeftRight"
      isOpen={modalStatus.is_open}
      toggle={async () => {
        setModalStatus({
          ...modalStatus,
          is_open: false,
        });
        modalStatus.is_open = false;
      }}
    >
      <ModalHeader
        toggle={async () => {
          setModalStatus({
            ...modalStatus,
            is_open: false,
          });
          modalStatus.is_open = false;
        }}
      >
        <h4 className="modal-title" id="modalDelivery">
          <b>
            Item Amount : {dataModal.amount} {dataModal.unit}
          </b>
        </h4>
      </ModalHeader>

      <ModalBody>
        <div className="row">
          <div className="col-12 col-md-6" style={{ textAlign: "center" }}>
            <h3>Product No : {dataModal.prod_no}</h3>
          </div>

          <div className="col-12 col-md-6" style={{ textAlign: "center" }}>
            <h3>Product Name : {dataModal.prod_name}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card card-primary card-outline card-outline-tabs">
              <div className="card-header p-0 border-bottom-0">
                <ul
                  className="nav nav-tabs"
                  id="custom-tabs-four-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className={`nav-link${
                        tabData.mode == "In_Stock" ? " active" : ""
                      }`}
                      id="FG-In-Stock"
                      data-toggle="pill"
                      href="#FG-In-Stock"
                      role="tab"
                      aria-controls="FG-In-Stock"
                      aria-selected="true"
                      onClick={async (event) => {
                        event.preventDefault();

                        setTabData({
                          ...tabData,
                          mode: "In_Stock",
                        });
                      }}
                    >
                      In Stock
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={`nav-link${
                        tabData.mode == "History" ? " active" : ""
                      }`}
                      id="FG-History"
                      data-toggle="pill"
                      href="#FG-History"
                      role="tab"
                      aria-controls="FG-History"
                      aria-selected="true"
                      onClick={async (event) => {
                        event.preventDefault();

                        setTabData({
                          ...tabData,
                          mode: "History",
                        });
                      }}
                    >
                      History
                    </a>
                  </li>
                </ul>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      {tabData.mode == "In_Stock" ? (
                        <>
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={true}
                            data={tableStock}
                          />
                        </>
                      ) : (
                        <>
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={true}
                            data={tableHistory}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          className="btn btn-danger"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            setModalStatus({
              ...modalStatus,
              is_open: false,
            });
            modalStatus.is_open = false;
          }}
        >
          ยกเลิก
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default Modal_IO_History;
