import React from "react";
import moment from "moment";
import jsPDF from "jspdf";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../assets/font/pdf_font";
import { logoTTT } from "../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";

import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../Utils/Common";

function External_dev_train_report(id) {
  function padZero(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  function ThaiNumberToText(Number) {
    Number = Number.replace(/๐/gi, "0");
    Number = Number.replace(/๑/gi, "1");
    Number = Number.replace(/๒/gi, "2");
    Number = Number.replace(/๓/gi, "3");
    Number = Number.replace(/๔/gi, "4");
    Number = Number.replace(/๕/gi, "5");
    Number = Number.replace(/๖/gi, "6");
    Number = Number.replace(/๗/gi, "7");
    Number = Number.replace(/๘/gi, "8");
    Number = Number.replace(/๙/gi, "9");
    return ArabicNumberToText(Number);
  }

  function ArabicNumberToText(Number) {
    var Number = CheckNumber(Number);
    var NumberArray = new Array(
      "ศูนย์",
      "หนึ่ง",
      "สอง",
      "สาม",
      "สี่",
      "ห้า",
      "หก",
      "เจ็ด",
      "แปด",
      "เก้า",
      "สิบ"
    );
    var DigitArray = new Array(
      "",
      "สิบ",
      "ร้อย",
      "พัน",
      "หมื่น",
      "แสน",
      "ล้าน"
    );
    var BahtText = "";
    if (isNaN(Number)) {
      return "ข้อมูลนำเข้าไม่ถูกต้อง";
    } else {
      if (Number - 0 > 9999999.9999) {
        return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
      } else {
        Number = Number.split(".");
        if (Number[1].length > 0) {
          Number[1] = Number[1].substring(0, 2);
        }
        var NumberLen = Number[0].length - 0;
        for (var i = 0; i < NumberLen; i++) {
          var tmp = Number[0].substring(i, i + 1) - 0;
          if (tmp != 0) {
            if (i == NumberLen - 1 && tmp == 1) {
              BahtText += "เอ็ด";
            } else if (i == NumberLen - 2 && tmp == 2) {
              BahtText += "ยี่";
            } else if (i == NumberLen - 2 && tmp == 1) {
              BahtText += "";
            } else {
              BahtText += NumberArray[tmp];
            }
            BahtText += DigitArray[NumberLen - i - 1];
          }
        }
        BahtText += "บาท";
        if (Number[1] == "0" || Number[1] == "00") {
          BahtText += "ถ้วน";
        } else {
          let DecimalLen = Number[1].length - 0;
          for (var i = 0; i < DecimalLen; i++) {
            var tmp = Number[1].substring(i, i + 1) - 0;
            if (tmp != 0) {
              if (i == DecimalLen - 1 && tmp == 1) {
                BahtText += "เอ็ด";
              } else if (i == DecimalLen - 2 && tmp == 2) {
                BahtText += "ยี่";
              } else if (i == DecimalLen - 2 && tmp == 1) {
                BahtText += "";
              } else {
                BahtText += NumberArray[tmp];
              }
              BahtText += DigitArray[DecimalLen - i - 1];
            }
          }
          BahtText += "สตางค์";
        }
        return BahtText;
      }
    }
  }

  function CheckNumber(Number) {
    var decimal = false;
    Number = Number.toString();
    Number = Number.replace(/ |,|บาท|฿/gi, "");
    for (var i = 0; i < Number.length; i++) {
      if (Number[i] == ".") {
        decimal = true;
      }
    }
    if (decimal == false) {
      Number = Number + ".00";
    }
    return Number;
  }

  function getTextWidth(text, font) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
  }

  Swal.fire({
    title: "กำลังดึงข้อมูล",
    text: "Loading",
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  axios({
    method: "post",
    url:
      Configs.API_URL_hrMagenatement +
      "/api/hrManagement/GetCourseAlltap?id=" +
      id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  })
    .then(function (response) {
      const currentDate = new Date();
      const year = currentDate.getFullYear() + 543;
      const fullDate = currentDate.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const allEmp = [];
      if (response.data.data.data4 !== null) {
        allEmp.push(...response.data.data.data4);
      }
      if (response.data.data.data5 !== null) {
        allEmp.push(...response.data.data.data5);
      }

      var img = logoTTT.imgs;

      const doc = new jsPDF("p", "mm", "a4");

      doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.addFileToVFS("THSarabunNew-bold.ttf", Get_font_pdf_th2_bold());
      doc.addFont("THSarabunNew-bold.ttf", "THSarabunNew", "bold");
      doc.addFont("Rocki", "bold");

      for (let index = 0; index < allEmp.length; index++) {
        if (index > 0 && index <= allEmp.length) {
          doc.addPage("p");
        }

        doc.addImage(img, "JPEG", 20, 8, 20, 20);

        doc.setFontSize(20);
        doc.setFont("THSarabunNew", "bold");
        doc.text(105, 32, "แบบขออนุมัติการพัฒนา / ฝึกอบรมภายนอก", "center");

        doc.setFontSize(14);
        doc.setFont("THSarabunNew", "normal");

        doc.text(
          190,
          13,
          `เลขที่อนุมัติ ${padZero(
            Number(response.data.data.data1[0].report_no),
            3
          )}/${year}`,
          "right"
        );
        doc.text(190, 23, `วันที่ ${fullDate}`, "right");

        doc.text(
          32,
          48,
          `ข้าพเจ้า ___________________________________________________ รหัสพนักงาน ______________________`,
          "left"
        );
        doc.text(
          50,
          47,
          `${allEmp[index].emp_name === null ? "" : allEmp[index].emp_name}`,
          "left"
        );
        doc.text(
          158,
          47,
          `${allEmp[index].emp_no === null ? "" : allEmp[index].emp_no}`,
          "left"
        );

        doc.text(
          22,
          55,
          `ตำแหน่ง __________________________________________ ฝ่าย ___________________________________________`,
          "left"
        );
        doc.text(
          40,
          54,
          `${
            allEmp[index].emp_position === null
              ? ""
              : allEmp[index].emp_position
          }`,
          "left"
        );
        doc.text(
          122,
          54,
          `${
            allEmp[index].emp_department === null
              ? ""
              : allEmp[index].emp_department
          }`,
          "left"
        );

        doc.text(
          22,
          62,
          `มีความประสงค์จะขอเข้ารับการ ________________________________________________________________________`,
          "left"
        );
        doc.text(
          70,
          61,
          `${
            response.data.data.data1[0].course_type_name === null
              ? ""
              : response.data.data.data1[0].course_type_name
          }`,
          "left"
        );

        doc.text(
          22,
          69,
          `ชื่อหลักสูตร / โครงการ ______________________________________________________________________________`,
          "left"
        );
        doc.text(
          60,
          68,
          `${
            response.data.data.data1[0].course_name === null
              ? ""
              : response.data.data.data1[0].course_name
          }`,
          "left"
        );

        doc.text(
          22,
          76,
          `บรรยายโดย _______________________________________________________________________________________`,
          "left"
        );
        doc.text(
          45,
          75,
          `${
            response.data.data.data1[0].lecturer_name === null
              ? ""
              : response.data.data.data1[0].lecturer_name
          }`,
          "left"
        );

        doc.text(
          22,
          83,
          `ตั้งแต่ วันที่ ____________________________ ถึงวันที่ ____________________________ เวลา ____________________`,
          "left"
        );
        doc.text(
          45,
          82,
          `${new Date(
            response.data.data.data1[0].begin_date
          ).toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}`,
          "left"
        );
        doc.text(
          105,
          82,
          `${new Date(response.data.data.data1[0].end_date).toLocaleDateString(
            "th-TH",
            {
              year: "numeric",
              month: "long",
              day: "numeric",
            }
          )}`,
          "left"
        );
        doc.text(
          160,
          82,
          `${moment(response.data.data.data1[0].begin_time).format(
            "HH:mm"
          )} - ${moment(response.data.data.data1[0].end_time).format("HH:mm")}`,
          "left"
        );

        doc.text(
          22,
          90,
          `สถานที่ __________________________________________________________________________________________`,
          "left"
        );
        doc.text(
          40,
          89,
          `${response.data.data.data1[0].learn_type_name}${
            response.data.data.data1[0].location === null
              ? ""
              : " : " +
                response.data.data.data1[0].location.replaceAll("\n", " ")
          }`,
          "left"
        );

        // doc.rect(22, 102, 168, 8);
        doc.setFont("THSarabunNew", "bold");

        doc.rect(22, 102, 84, 8);
        doc.text(64, 107.5, `ค่าลงทะเบียน`, "center");

        doc.rect(106, 102, 84, 8);
        doc.text(148, 107.5, `ค่าใช้จ่ายอื่นๆ`, "center");

        doc.setFont("THSarabunNew", "normal");
        // doc.rect(22, 110, 168, 20);
        // doc.rect(22, 110, 84, 20);
        doc.text(
          95,
          117,
          `ค่าลงทะเบียน ........................................ บาท`,
          "right"
        );
        doc.text(
          72.5,
          116,
          `${
            response.data.data.data2 === null
              ? ""
              : response.data.data.data2[0].register_cost === null
              ? ""
              : response.data.data.data2[0].register_cost
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }`,
          "center"
        );

        doc.text(
          95,
          124,
          `ค่าธรรมเนียม ........................................ บาท`,
          "right"
        );
        doc.text(
          72.5,
          123,
          `${
            response.data.data.data2 === null
              ? ""
              : response.data.data.data2[0].fee_cost === null
              ? ""
              : response.data.data.data2[0].fee_cost
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }`,
          "center"
        );

        // doc.rect(106, 110, 84, 20);
        doc.text(
          180,
          117,
          `ค่าเดินทาง ........................................ บาท`,
          "right"
        );
        doc.text(
          157,
          116,
          `${
            response.data.data.data2 === null
              ? ""
              : response.data.data.data2[0].travel_cost === null
              ? ""
              : response.data.data.data2[0].travel_cost
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }`,
          "center"
        );

        doc.text(
          180,
          124,
          `ค่าที่พัก ........................................ บาท`,
          "right"
        );
        doc.text(
          157,
          123,
          `${
            response.data.data.data2 === null
              ? ""
              : response.data.data.data2[0].hotel_cost === null
              ? ""
              : response.data.data.data2[0].hotel_cost
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }`,
          "center"
        );

        if (response.data.data.data3 !== null) {
          for (
            let index = 0;
            index < response.data.data.data3.length;
            index++
          ) {
            doc.text(
              180,
              124 + 7 * (index + 1),
              `${response.data.data.data3[index].course_order} ........................................ บาท`,
              "right"
            );
            doc.text(
              157,
              123 + 7 * (index + 1),
              `${
                response.data.data.data3[index].course_cost === null
                  ? ""
                  : response.data.data.data3[index].course_cost
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }`,
              "center"
            );
          }
        }

        doc.rect(
          22,
          110,
          84,
          response.data.data.data3 === null
            ? 20
            : 20 + 7 * response.data.data.data3.length
        );
        doc.rect(
          106,
          110,
          84,
          response.data.data.data3 === null
            ? 20
            : 20 + 7 * response.data.data.data3.length
        );

        // doc.rect(22, 130 + 7 * (response.data.data.data3.length), 168, 10);
        doc.rect(
          22,
          response.data.data.data3 === null
            ? 130
            : 130 + 7 * response.data.data.data3.length,
          84,
          10
        );
        doc.text(
          95,
          response.data.data.data3 === null
            ? 138
            : 138 + 7 * response.data.data.data3.length,
          `รวม ........................................ บาท`,
          "right"
        );
        doc.text(
          72.5,
          response.data.data.data3 === null
            ? 137
            : 137 + 7 * response.data.data.data3.length,
          `${
            response.data.data.data2 === null
              ? ""
              : response.data.data.data2[0].total_course === null
              ? ""
              : response.data.data.data2[0].total_course
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }`,
          "center"
        );

        doc.rect(
          106,
          response.data.data.data3 === null
            ? 130
            : 130 + 7 * response.data.data.data3.length,
          84,
          10
        );
        doc.text(
          180,
          response.data.data.data3 === null
            ? 138
            : 138 + 7 * response.data.data.data3.length,
          `รวม ........................................ บาท`,
          "right"
        );
        doc.text(
          157,
          response.data.data.data3 === null
            ? 137
            : 137 + 7 * response.data.data.data3.length,
          `${
            response.data.data.data2 === null
              ? ""
              : response.data.data.data2[0].total_other_cost === null
              ? ""
              : response.data.data.data2[0].total_other_cost
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }`,
          "center"
        );

        doc.text(
          105,
          response.data.data.data3 === null
            ? 148
            : 148 + 7 * response.data.data.data3.length,
          `รวมทั้งหมด ............................................................... บาท     (${
            response.data.data.data2 === null
              ? "ศูนย์บาทถ้วน"
              : response.data.data.data2[0].grand_total === null
              ? "ศูนย์บาทถ้วน"
              : ThaiNumberToText(response.data.data.data2[0].grand_total)
          })`,
          "center"
        );

        doc.text(
          response.data.data.data2 === null
            ? 98
            : response.data.data.data2[0].grand_total === null
            ? 98
            : 105 -
              (getTextWidth(
                `รวมทั้งหมด ............................................................... บาท     (${
                  response.data.data.data2 === null
                    ? "ศูนย์บาทถ้วน"
                    : response.data.data.data2[0].grand_total === null
                    ? "ศูนย์บาทถ้วน"
                    : ThaiNumberToText(response.data.data.data2[0].grand_total)
                })`
              ) *
                25.4) /
                96 /
                2 +
              48.61538420783149,
          response.data.data.data3 === null
            ? 147
            : 147 + 7 * response.data.data.data3.length,
          `${
            response.data.data.data2 === null
              ? ""
              : response.data.data.data2[0].grand_total === null
              ? ""
              : response.data.data.data2[0].grand_total
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }`,
          "center"
        );

        // doc.text(
        //   105,
        //   response.data.data.data3 === null
        //     ? 155
        //     : 155 + 7 * response.data.data.data3.length,
        //   `(${
        //     response.data.data.data2 === null
        //       ? "ศูนย์บาทถ้วน"
        //       : response.data.data.data2[0].grand_total === null
        //       ? "ศูนย์บาทถ้วน"
        //       : ThaiNumberToText(response.data.data.data2[0].grand_total)
        //   })`,
        //   "center"
        // );

        if (
          response.data.data.data3 !== null &&
          response.data.data.data3.length >= 4
        ) {
          doc.addPage("p");

          doc.addImage(img, "JPEG", 20, 8, 20, 20);

          doc.setFontSize(20);

          doc.text(105, 32, "แบบขออนุมัติการพัฒนา / ฝึกอบรมภายนอก", "center");
          doc.text(105, 32, "แบบขออนุมัติการพัฒนา / ฝึกอบรมภายนอก", "center");
          doc.text(105, 32, "แบบขออนุมัติการพัฒนา / ฝึกอบรมภายนอก", "center");

          doc.setFontSize(14);

          doc.text(
            190,
            13,
            `เลขที่อนุมัติ ${padZero(
              Number(response.data.data.data1[0].report_no),
              3
            )}/${year}`,
            "right"
          );
          doc.text(190, 23, `วันที่ ${fullDate}`, "right");
        }

        doc.text(
          105,
          response.data.data.data3 === null
            ? 165
            : response.data.data.data3.length >= 4
            ? 48
            : 165 + 7 * response.data.data.data3.length,
          `จึงเรียนมาเพื่อทราบและโปรดพิจารณาอนุมัติ`,
          "center"
        );

        doc.text(
          115,
          response.data.data.data3 === null
            ? 180
            : response.data.data.data3.length >= 4
            ? 63
            : 180 + 7 * response.data.data.data3.length,
          `ลงชื่อ ....................................................... ผู้ขออนุมัติอบรม`,
          "left"
        );
        doc.text(
          115,
          response.data.data.data3 === null
            ? 187
            : response.data.data.data3.length >= 4
            ? 70
            : 187 + 7 * response.data.data.data3.length,
          `(                                                )`,
          "left"
        );

        doc.setLineWidth(0.5);

        doc.text(
          58,
          response.data.data.data3 === null
            ? 196
            : response.data.data.data3.length >= 4
            ? 79
            : 196 + 7 * response.data.data.data3.length,
          `(1) ความคิดเห็นของหัวหน้างาน`,
          "center"
        );
        doc.text(
          90,
          response.data.data.data3 === null
            ? 203
            : response.data.data.data3.length >= 4
            ? 86
            : 203 + 7 * response.data.data.data3.length,
          `................................................................................`,
          "right"
        );
        doc.text(
          90,
          response.data.data.data3 === null
            ? 210
            : response.data.data.data3.length >= 4
            ? 93
            : 210 + 7 * response.data.data.data3.length,
          `................................................................................`,
          "right"
        );

        doc.rect(
          115,
          response.data.data.data3 === null
            ? 192
            : response.data.data.data3.length >= 4
            ? 75
            : 192 + 7 * response.data.data.data3.length,
          3,
          3
        );
        doc.text(
          122,
          response.data.data.data3 === null
            ? 195
            : response.data.data.data3.length >= 4
            ? 78
            : 195 + 7 * response.data.data.data3.length,
          `อนุมัติ`,
          "left"
        );
        doc.rect(
          155,
          response.data.data.data3 === null
            ? 192
            : response.data.data.data3.length >= 4
            ? 75
            : 192 + 7 * response.data.data.data3.length,
          3,
          3
        );
        doc.text(
          162,
          response.data.data.data3 === null
            ? 195
            : response.data.data.data3.length >= 4
            ? 78
            : 195 + 7 * response.data.data.data3.length,
          `ไม่อนุมัติ`,
          "left"
        );
        doc.text(
          115,
          response.data.data.data3 === null
            ? 205
            : response.data.data.data3.length >= 4
            ? 88
            : 205 + 7 * response.data.data.data3.length,
          `ลงชื่อ ....................................................... หัวหน้างาน`,
          "left"
        );
        doc.text(
          115,
          response.data.data.data3 === null
            ? 212
            : response.data.data.data3.length >= 4
            ? 95
            : 212 + 7 * response.data.data.data3.length,
          `(                                                )`,
          "left"
        );

        doc.text(
          58,
          response.data.data.data3 === null
            ? 221
            : response.data.data.data3.length >= 4
            ? 104
            : 221 + 7 * response.data.data.data3.length,
          `(2) ความคิดเห็นของฝ่ายบุคคล`,
          "center"
        );
        doc.text(
          90,
          response.data.data.data3 === null
            ? 228
            : response.data.data.data3.length >= 4
            ? 111
            : 228 + 7 * response.data.data.data3.length,
          `................................................................................`,
          "right"
        );
        doc.text(
          90,
          response.data.data.data3 === null
            ? 235
            : response.data.data.data3.length >= 4
            ? 118
            : 235 + 7 * response.data.data.data3.length,
          `................................................................................`,
          "right"
        );

        doc.rect(
          115,
          response.data.data.data3 === null
            ? 217
            : response.data.data.data3.length >= 4
            ? 100
            : 217 + 7 * response.data.data.data3.length,
          3,
          3
        );
        doc.text(
          122,
          response.data.data.data3 === null
            ? 220
            : response.data.data.data3.length >= 4
            ? 103
            : 220 + 7 * response.data.data.data3.length,
          `อนุมัติ`,
          "left"
        );
        doc.rect(
          155,
          response.data.data.data3 === null
            ? 217
            : response.data.data.data3.length >= 4
            ? 100
            : 217 + 7 * response.data.data.data3.length,
          3,
          3
        );
        doc.text(
          162,
          response.data.data.data3 === null
            ? 220
            : response.data.data.data3.length >= 4
            ? 103
            : 220 + 7 * response.data.data.data3.length,
          `ไม่อนุมัติ`,
          "left"
        );
        doc.text(
          115,
          response.data.data.data3 === null
            ? 230
            : response.data.data.data3.length >= 4
            ? 113
            : 230 + 7 * response.data.data.data3.length,
          `ลงชื่อ ....................................................... ฝ่ายบุคคล`,
          "left"
        );
        doc.text(
          115,
          response.data.data.data3 === null
            ? 237
            : response.data.data.data3.length >= 4
            ? 120
            : 237 + 7 * response.data.data.data3.length,
          `(                                                )`,
          "left"
        );

        doc.text(
          58,
          response.data.data.data3 === null
            ? 246
            : response.data.data.data3.length >= 4
            ? 129
            : 246 + 7 * response.data.data.data3.length,
          `(3) ความคิดเห็นของผู้บริหาร`,
          "center"
        );
        doc.text(
          90,
          response.data.data.data3 === null
            ? 253
            : response.data.data.data3.length >= 4
            ? 136
            : 253 + 7 * response.data.data.data3.length,
          `................................................................................`,
          "right"
        );
        doc.text(
          90,
          response.data.data.data3 === null
            ? 260
            : response.data.data.data3.length >= 4
            ? 143
            : 260 + 7 * response.data.data.data3.length,
          `................................................................................`,
          "right"
        );

        doc.rect(
          115,
          response.data.data.data3 === null
            ? 242
            : response.data.data.data3.length >= 4
            ? 125
            : 242 + 7 * response.data.data.data3.length,
          3,
          3
        );
        doc.text(
          122,
          response.data.data.data3 === null
            ? 245
            : response.data.data.data3.length >= 4
            ? 128
            : 245 + 7 * response.data.data.data3.length,
          `อนุมัติ`,
          "left"
        );
        doc.rect(
          155,
          response.data.data.data3 === null
            ? 242
            : response.data.data.data3.length >= 4
            ? 125
            : 242 + 7 * response.data.data.data3.length,
          3,
          3
        );
        doc.text(
          162,
          response.data.data.data3 === null
            ? 245
            : response.data.data.data3.length >= 4
            ? 128
            : 245 + 7 * response.data.data.data3.length,
          `ไม่อนุมัติ`,
          "left"
        );
        doc.text(
          115,
          response.data.data.data3 === null
            ? 255
            : response.data.data.data3.length >= 4
            ? 138
            : 255 + 7 * response.data.data.data3.length,
          `ลงชื่อ ....................................................... ผู้บริหาร`,
          "left"
        );
        doc.text(
          115,
          response.data.data.data3 === null
            ? 262
            : response.data.data.data3.length >= 4
            ? 145
            : 262 + 7 * response.data.data.data3.length,
          `(                                                )`,
          "left"
        );
      }

      window.open(doc.output("bloburl"));
      Swal.close();
    })
    .catch(function (error) {
      console.log(error);
      Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
    });
}

export default External_dev_train_report;
