import React, { useState, useEffect } from "react";
// import Monitor_title from './Monitor_title.png';
import Monitor_title from '../../assets/img/Dashboard/Vector.svg';
import today from "../../assets/img/Dashboard/today.svg";
import monthly from '../../assets/img/Dashboard/monthly.svg';
import yearly from '../../assets/img/Dashboard/yearly.svg';
import total from '../../assets/img/Dashboard/total.svg';
import detail from '../../assets/img/Dashboard/details.svg'

import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Footter from "../../component/Footter";
import "../../assets/css/IOT_Connect/Content.css";
import "../../assets/css/IOT_Connect/LandingPage.css";
import "../../assets/css/IOT_Connect/DashboardPowerMonitor.css";
import {
  getUser,
  getToken,
  setOemlist,
  getOem,
  getComConfig,
} from "../../Utils/Common";
import Configs from "../../config";
import axios from "axios";

//Import Dashboard components
import SelectDropdown from "../../component/IOT_Connect/Filters/SelectDropdown"
// import Datepicker from "../component/Datepicker";
// import Datepicker from "../component/Filters/Datepicker"
import Datepicker from "../../component/IOT_Connect/Datepicker";
import FormatSelect from "../../component/IOT_Connect/FormatSelect";
import Chart from "../../component/IOT_Connect/Chart";
import SummaryIndicator from "../../component/IOT_Connect/SummaryIndicator"
import DetailIndicator from "../../component/IOT_Connect/DetailIndicator";


const colors = ['rgb(41, 29, 137)', 'rgb(31, 120, 180)', 'rgb(123, 189, 237)', 'rgb(227, 26, 28)', 'rgb(210, 218, 239)', 'rgb(251, 154, 153)'];

function Dashboard_Power_Monitor(params) {
  const [startDate, setStartDate] = useState(new Date());
  const [user, setUser] = useState(getUser());
  const [user_detail, setUser_detail] = useState({});
  const [company, setCompany] = useState({});
  const [oem, setOem] = useState([]);
  const [po_sign, setpo_sign] = useState(getComConfig());
  const [monitorData, setdata] = useState()
  const [size, setSize] = useState({
    width: 1280,
    height: 720,
  });
  

  // Use to store today's energy.
  const [todayEnergy, setTodayEnergy] = useState(0); // Initialize 0.

  // Use to store monthly energy.
  const [monthlyEnergy, setMonthlyEnergy] = useState(0); // Initiialize as 0.

  // Use to store yearly energy.
  const [yearlyEnergy, setYearlyEnergy] = useState(0); // Initialize as 0.

  // Use to store total or entire energy.
  const [entireEnergy, setEntireEnergy] = useState(0); // Initialize as 0.

  // *** Charts content data variable. ***

  // Use to store current datasets. (Used for currrent chart.)
  const [currentChartData, setCurrentChartData] = useState([]); // Initialize as empty.

  // Use to store last demand active datasets. (Used for last demand chart.)
  const [lastDemandChartData, setLastDemandChartData] = useState([]);

  // Use to store power datasets. (Used for power chart.)
  const [powerChartData, setPowerChartData] = useState([]);

  // Use to store energy datasets. (Used for energy chart.)
  const [energyChartData, setEnergyChartData] = useState([]);

  // Use to store voltage datasets. (Used for voltage chart.)
  const [voltageChartData, setVoltageChartData] = useState([]);

  // Use to store frequency datasets. (Used for frequency chart.)
  const [frequencyChartData, setFrequencyChartData] = useState([]);

  const [currentReadingData, setCurrentReadingData] = useState({});

  // Use to store current data details.
  const [currentDetailData, setCurrentDetailData] = useState({
    min: 0.0, 
    max: 0.0,   // Initialize as 0.0.
    avg: 0.0,
  });

  // Use to store power data details.
  const [powerDetailData, setPowerDetailData] = useState({
    min: 0.0,
    max: 0.0,   // Initialize as 0.0.
    avg: 0.0
  });

  // Use to store energy data details
  const [energyDetailData, setEnergyDetailData] = useState({
    min: 0.0,
    max: 0.0,   // Initialize as 0.0.
    avg: 0.0
  });

  // Use to store voltage data details
  const [voltageDetailData, setVoltageDetailData] = useState({
    min: 0.0,
    max: 0.0,   // Initialize as 0.0.
    avg: 0.0
  });

  // Use to store frequency data details
  const [frequencyDetailData, setFrequencyDetailData] = useState({
    min: 0.0,
    max: 0.0,   // Initialize as 0.0.
    avg: 0.0
  });

  // Use to store selected display date format.
  const [selectedFormatDate, setSelectedFormatDate] = useState("day");

  // Use to store selected Monitors.
  const [selectedMonitors, setSelectedMonitors] = useState("");

  // Use to store options
  const [options, setOptions] = useState([]);

  // Use to store selected Date.
  const [selectedDate, setSelectedDate] = useState();

  // Make a static to date.
  const currentDate = new Date();
  const dateStructure = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString(undefined, dateStructure);


  const handleSelectedMonitorChange = (data) => {
    setSelectedMonitors(data);
  }

  const handleSelectedDateChange = (data) => {
    setSelectedDate(data);
  }

  const handleFormatDateChange = (data) => {
    setSelectedFormatDate(data);
  }

  // Fetch Summary Indicators data.
  const fetchStatData = async () => {
    try {
      const [todayResponse, monthlyResponse, yearlyResponse, entireResponse] = await Promise.all([
        axios.request({
          method: "get",
          url: `${Configs.API_URL_IoT_Connect}/dashboard/getDayTotalEnergy?formattedDate=now()`,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
        }),
        axios.request({
          method: "get",
          url: `${Configs.API_URL_IoT_Connect}/dashboard/getMonthTotalEnergy?formattedDate=now()`,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
        }),
        axios.request({
          method: "get",
          url: `${Configs.API_URL_IoT_Connect}/dashboard/getYearTotalEnergy?formattedDate=now()`,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
        }),
        axios.request({
          method: "get",
          url: `${Configs.API_URL_IoT_Connect}/dashboard/getEntireTotalEnergy`,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
        })
      ]);

      const todayEnergyValue = todayResponse.data[0]?.total_energy || 0;
      const monthlyEnergyValue = monthlyResponse.data[0]?.total_energy || 0;
      const yearlyEnergyValue = yearlyResponse.data[0]?.total_energy || 0;
      const entireEnergyValue = entireResponse.data[0]?.total_energy || 0;

      setTodayEnergy(parseFloat(todayEnergyValue));
      setMonthlyEnergy(parseFloat(monthlyEnergyValue));
      setYearlyEnergy(parseFloat(yearlyEnergyValue));
      setEntireEnergy(parseFloat(entireEnergyValue));

    } catch (error) {
      console.error(error);
    }
  };

    const fetchOptions = async () => {
    try {
      const response = await axios.request({
        method: 'get',
        url: `${Configs.API_URL_IoT_Connect}/dashboard/getActiveMonitorsList`,
        headers: {
          Authorization: getToken(),
          'X-TTT': Configs.API_TTT,
          'Content-type': 'application/json',
        }
      });

      setOptions(response.data);
      if (response.data.length > 0) {
        setSelectedMonitors(response.data[0].value);
      }

    } catch (error) {
      console.error('Error Fetching Data:', error);
    }
  };

  // # Helper function to destructure data
  function destructureData(data) {
    return {
      min: data.min,
      max: data.max,
      avg: data.average,
    };
  }

  // Fetch Chart Data
  const fetchChartData = async () => {
  try {
    const response = await axios.request({
      method: "get", //150.95.25.8:6682
      url: `${Configs.API_URL_IoT_Connect}/dashboard/getDataByInformation?id=${selectedMonitors}&selectedFormatDate=${selectedFormatDate}&formattedDate=${selectedDate}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    });

    const currentData = [
      {
        label: response.data.ims_name + "_Current Average",
        data: response.data.ihs_current.data,
        borderColor: colors[0],
        borderWidth: 2,
        pointBackgroundColor: colors[0],
      },
      {
        label: response.data.ims_name + "_Current A",
        data: response.data.phase_a.data,
        borderColor: colors[1],
        borderWidth: 2,
        pointBackgroundColor: colors[1],
      },
      {
        label: response.data.ims_name + "_Current B",
        data: response.data.phase_b.data,
        borderColor: colors[2],
        borderWidth: 2,
        pointBackgroundColor: colors[2],
      },
      {
        label: response.data.ims_name + "_Current C",
        data: response.data.phase_c.data,
        borderColor: colors[3],
        borderWidth: 2,
        pointBackgroundColor: colors[3],
      }
    ];

    const lastDemandData = [
      {
        label: response.data.ims_name,
        data: response.data.phase_d.data,
        borderColor: colors[3],
        borderWidth: 2,
        pointBackgroundColor: colors[3],
      },
    ];

    const voltageData = [
      {
        label: response.data.ims_name,
        data: response.data.ihs_voltage.data,
        borderColor: colors[0],
        borderWidth: 2,
        pointBackgroundColor: colors[0],
      },
    ];

    const powerData = [
      {
        label: response.data.ims_name,
        data: response.data.ihs_power.data,
        borderColor: colors[0],
        borderWidth: 2,
        pointBackgroundColor: colors[0],
      },
    ];

    const energyData = [
      {
        label: response.data.ims_name,
        data: response.data.ihs_energy.data,
        borderColor: colors[0],
        borderWidth: 2,
        pointBackgroundColor: colors[0],
      },
    ];

    const frequencyData = [
      {
        label: response.data.ims_name,
        data: response.data.ihs_frequency.data,
        borderColor: colors[0],
        borderWidth: 2,
        pointBackgroundColor: colors[0],
      },
    ];

    setCurrentChartData(currentData);
    setLastDemandChartData(lastDemandData);
    setVoltageChartData(voltageData);
    setPowerChartData(powerData);
    setEnergyChartData(energyData);
    setFrequencyChartData(frequencyData);

    setCurrentDetailData(response.data.current);
    setVoltageDetailData(response.data.voltage);
    setPowerDetailData(response.data.power);
    setEnergyDetailData(response.data.energy);
    setFrequencyDetailData(response.data.frequency);

  } catch (error) {
    console.log('error fetchChartData', error);
  }
};

  useEffect(() => {
    fetchOptions();
    fetchStatData();
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if(selectedDate != "" && selectedMonitors != "") {
        fetchChartData();
    }
      fetchStatData();
    }

    fetchData();

    const timerId = setInterval(fetchData, 60000);

    return () => {
      clearTimeout(timerId);
    };

  }, [selectedMonitors, selectedDate, selectedFormatDate]);

  // Render
  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <div className="monitor_title">
                <img src={Monitor_title} alt="" />
                <div className="title"><h1>Dashboard <span>Power Monitor</span></h1></div>
              </div>
              <div className="date-display">Today {formattedDate}</div>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/Welcome">Home</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content content-db-power">
        <div className="container-fluid pr-2">
          <div className="overview">Overview</div>
          <hr></hr>
          {/* <br></br> */}
          {/* <div className="grid grid-cols-1 gap-5 lg:grid-cols-4 sm:grid-cols-2"> */}
          <div className="grid-card-db-power-monitor">
            
              <div className="card">
                <SummaryIndicator data={todayEnergy} imgSource={today} title={"Today's"}/>
              </div>

              <div className="card">
                <SummaryIndicator data={monthlyEnergy} imgSource={monthly} title={"Monthly"}/>
              </div>

              <div className="card">
                <SummaryIndicator data={yearlyEnergy} imgSource={yearly} title={"Yearly"}/>
              </div>

              <div className="card">
                <SummaryIndicator data={entireEnergy} imgSource={total} title={"Entire"}/>
              </div>
                        
          </div>

          <div className="grid-search-db-power-monitor filter-container justify-items-center">
            
            <div className="col-span-3 format-date-button-container justify-self-start">
              <FormatSelect formatDate={handleFormatDateChange}/>
            </div>

            <div className="col-span-7 date-picker-container justify-self-start">
              <Datepicker format={selectedFormatDate} date={handleSelectedDateChange}/>
              {/* <Datepicker format={selectedFormatDate} /> */}
            </div>

            <div className="col-span-2 multi-select-container justify-self-end">
              <div className="Date"> 
                {/* <MultiSelectDropdown options={options} selectedOptions={handleSelectedMonitorChange} firstSixSelected={firstSelectedMonitors}/> */}
                <SelectDropdown selectedOption={handleSelectedMonitorChange} options={options} useLabel={false} usePlaceholder={false}/>
              </div>
            </div>
          </div>

          <div className="chart grid grid-cols-1">
            <div className="card chart-monitors">
              <div className="title-chart">
                <h2>Current 3 Phase (A,B,C Avg)</h2>
                <p>Comparison Current</p>
              </div>
            
              <div className="">
                <div className="col-span-2">
                  <div className="current-chart-container">

                    <Chart type={'line'} displayFormat={selectedFormatDate} date={selectedDate} data={currentChartData} unit={"A"}/>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="chart grid grid-cols-1">
            <div className="card chart-monitors">
              <div className="title-chart">
                <h2>Last Demand - Active Power</h2>
              </div>
            
              <div className="">
                <div className="col-span-2">
                  <div className="current-chart-container">

                    <Chart type={'line'} displayFormat={selectedFormatDate} date={selectedDate} data={lastDemandChartData} unit={"A"}/>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid-power-energy-db-p-m">
            <div className="card chart-monitors">
              <div className="title-chart">
                <h2>Power</h2>
                <p>Power Usage</p>
              </div>
            
              {/* <div className="grid grid-cols-3"> */}
              <div className="row">

                <div className="col-12 col-lg-8">
                  <Chart type={'line'} displayFormat={selectedFormatDate} date={selectedDate} data={powerChartData} unit={"W"}/>
                </div>

                <div className="col-12 col-lg-4 justify-self-center">
                  <DetailIndicator title={"Power"} detail={powerDetailData} unit={"W"}/>
                </div>
                
              </div>
              
            </div>

            <div className="card chart-monitors">
              <div className="title-chart">
                <h2>Energy</h2>
                <p>Energy Usage</p>
              </div>
            
              <div className="row">

                <div className="col-12 col-lg-8">
                  <Chart type={'line'} displayFormat={selectedFormatDate} date={selectedDate} data={energyChartData} unit={"kWh"}/>
                </div>

                <div className="col-12 col-lg-4 justify-self-center">
                  <DetailIndicator title={"Energy"} detail={energyDetailData} unit={"kWh"}/>
                </div>
                
              </div>
              
            </div>
          </div>
          <div className="grid-power-energy-db-p-m">
            <div className="card chart-monitors">
              <div className="title-chart">
                <h2>Voltage</h2>
                <p>Voltage Usage</p>
              </div>

            
              <div className="row">
                <div className="col-12 col-lg-8">
                  <Chart type={'line'} displayFormat={selectedFormatDate} date={selectedDate} data={voltageChartData} unit={"V"}/>
                </div>
                
                <div className="col-12 col-lg-4 justify-self-center">
                  <DetailIndicator title={"Voltage"} detail={voltageDetailData} unit={"V"}/>
                </div>

              </div>
              
            </div>
            <div className="card chart-monitors">
              <div className="title-chart">
                <h2>Frequency</h2>
                <p>Frequency Usage</p>
              </div>
            
              <div className="row">

                <div className="col-12 col-lg-8">
                  <Chart type={'line'} displayFormat={selectedFormatDate} date={selectedDate} data={frequencyChartData} unit={"Hz"}/>
                </div>
                
                <div className="col-12 col-lg-4 justify-self-center">
                  <DetailIndicator title={"Frequency"} detail={frequencyDetailData} unit={"Hz"}/>
                </div>
                
              </div>
              
            </div>
          </div>

        </div>
      </section>
    </div>
  );
}
export default Dashboard_Power_Monitor;
