import React, { useEffect, useState, useRef } from "react";
import { renderToString } from "react-dom/server";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import sortJsonArray from "sort-json-array";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import { useParams, useHistory } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Footter from "../../component/Footter";
import Tooling_excel from "../../assets/file/Template_Master_Data_Tooling.xlsx";
import Header from "../../component/Header";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../Utils/Common";
import { Get_font_pdf_mat } from "../../assets/font/pdf_font";
import QRCode2 from "qrcode";
import BTN from "../../component/btn_feature";
import Select from "react-select";
function Tooling(mode) {
  const [brand_option, setbrand_option] = useState([]);
  const select_brand = useRef();
  const [matsize_option, setmatsize_option] = useState([]);
  const select_matsize = useRef();
  const [matspac_option, setmatspac_option] = useState([]);
  const select_matspac = useRef();
  const [supplier_option, setsupplier_option] = useState([]);
  const select_supplier = useRef();
  const [tooling, settooling] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [brand, setBrand] = useState([]);
  const [size, setSize] = useState([]);
  const [spect, setSpect] = useState([]);
  const [oem_data, setOem_data] = useState();
  const [company, setcompany] = useState();
  const [feature, setfeature] = useState([]);
  const history = useHistory();
  const params = useParams();
  const [excel, setexcel] = useState({
    name: "",
    status: null,
  });
  const [tooling_exel, settooling_exel] = useState([]);
  const [filterOption, setfilterOption] = useState({
    tooling_code: "",
    tooling_name: "",
    supplier_id: "",
    brand_id: "",
    oem_id: getOem(),
    company_id: getUser().com,
  });
  const [docMatType, setDocMatType] = useState(null);
  const [docMatSpec, setDocMatSpec] = useState(null);

  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/tooling/getToolingSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_brand = {
    method: "get",
    url:
      Configs.API_URL +
      `/tooling/getToolingBrand?id=${getUser().com}&id2=${getOem()}`,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  useEffect(async () => {
    axios(config_sub)
      .then(function (response) {
        setSupplier(response.data);
        const options = [{ value: "", label: "Select All" }];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setsupplier_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_brand)
      .then(function (response) {
        setBrand(response.data);
        const options = [{ value: "", label: "Select All" }];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.brand_id;
          element["label"] = el.brand_name;
          options.push(element);
        });
        setbrand_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });

    // axios(config_getOem)
    //   .then(function (response) {
    //     response.data.map((el) => {
    //       if (el.id === getOem()) {
    //         /* console.log(el); */
    //         return setOem_data(el);
    //       }
    //     });
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // axios(config_com)
    //   .then(function (response) {
    //     /*  console.log(response.data); */
    //     setcompany(response.data);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    GetTooling();
  }, []);

  const GetTooling = async () => {
    const data = {
      tooling_code: filterOption.tooling_code.trim(),
      tooling_name: filterOption.tooling_name.trim(),
      supplier_id: filterOption.supplier_id.trim(),
      brand_id: filterOption.brand_id.trim(),
      oem_id: getOem(),
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/tooling/filterToolingM",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async (response) => {
        // response.data.sort((a, b) => {
        //   if (a.tooling_code === null) {
        //     return 1;
        //   }

        //   if (b.tooling_code === null) {
        //     return -1;
        //   }

        //   if (a.tooling_code === b.tooling_code) {
        //     return 0;
        //   }

        //   return a.tooling_code < b.tooling_code ? -1 : 1;
        // });

        // var re = fullNumbers ? /[\d\.\-]+|\D+/g : /\d+|\D+/g;
        const collator = new Intl.Collator("en", {
          numeric: true,
          sensitivity: "variant",
        });
        response.data.sort((a, b) =>
          collator.compare(a.tooling_code, b.tooling_code)
        );

        await settooling(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const clearfilterOption = async () => {
    await setfilterOption({
      ...filterOption,
      tooling_code: "",
      tooling_name: "",
      supplier_id: "",
      brand_id: "",
      oem_id: getOem(),
      company_id: getUser().com,
    });

    filterOption.tooling_code = "";
    filterOption.tooling_name = "";
    filterOption.supplier_id = "";
    filterOption.brand_id = "";
    filterOption.oem_id = getOem();
    filterOption.company_id = getUser().com;

    await GetTooling();
  };

  const addpage = () => {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Tooling/add";
  };

  function set_excel() {
    setexcel({ ...excel, status: null, name: "" });
    excel.status = null;
    excel.name = "";
    settooling_exel([]);
    window.location.href = "/Warehouse_Management/Factory_Master_Data/Tooling";
  }

  const upload_tooling_file = async (e) => {
    if (e.target.files[0]) {
      // setexcel({ ...excel, name: e.target.files[0].name });
      /* console.log(e.target.files[0]); */
      var data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("company_id", getUser().com);
      data.append("oem_id", getOem());

      var config_uploadExecl = {
        method: "post",
        url: Configs.API_URL + "/tooling/importExcel",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config_uploadExecl)
        .then(function (response) {
          //console.log(response.data);

          let num = 0;
          for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].status === false) {
              num++;
              /*  console.log(response.data[index].status); */
            }
          }
          /* console.log(num); */
          if (num !== 0) {
            settooling_exel(response.data);
          } else {
            Swal.fire({
              title: "Upload Success",
              text: "Your file has been Upload.",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              /* window.location.href =
                "/Warehouse_Management/Factory_Master_Data/Raw_Materail"; */
              settooling_exel(response.data);
            });
            setexcel({ ...excel, status: null, name: "" });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
        });
    }
  };

  const deleteTooling = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/tooling/deleteTooling?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Tooling";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  };

  const rowsData = [];
  for (var index = 0; index < tooling.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          // alt={tooling[index].tooling_img_name}
          alt="..."
          className="img-fluid rounded"
          src={
            tooling[index].tooling_path_name !== null &&
            tooling[index].tooling_path_name !== "" 
              ? Configs.API_URL_IMG + tooling[index].tooling_path_name
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );

    rowItem["tooling_code"] = tooling[index].tooling_code;

    rowItem["tooling_name"] = tooling[index].tooling_name;

    rowItem["brand"] = tooling[index].brand_name;

    rowItem["min_stock"] = tooling[index].min_stock;

    rowItem["max_stock"] = tooling[index].max_stock;

    rowItem["consumption"] = tooling[index].consumption;

    rowItem["ratio"] = tooling[index].ratio;

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Tooling/read/" +
              tooling[index].tooling_id
            }
            key={tooling[index].tooling_id}
            className=" btn btn-xs " /* onClick={readpage.bind(this,tooling[index].id)} */
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Tooling/edit/" +
              tooling[index].tooling_id
            }
            key={tooling[index].tooling_id}
            className=" btn btn-xs " /* onClick={editpage.bind(this,tooling[index].id)} */
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={tooling[index].tooling_id}
            className="btn btn-xs "
            onClick={deleteTooling.bind(this, tooling[index].tooling_id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tooling Code",
        field: "tooling_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tooling Name",
        field: "tooling_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Brand",
        field: "brand",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min Stock",
        field: "min_stock",
        sort: "asc",
        width: 50,
      },

      {
        label: "Max Stock",
        field: "max_stock",
        sort: "asc",
        width: 50,
      },
      {
        label: "consumption",
        field: "consumption",
        sort: "asc",
        width: 50,
      },
      {
        label: "Ratio",
        field: "ratio",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  // function filterTypeSpec(data) {
  //   //console.log(data);
  //   var Com_logo = Configs.API_URL_IMG
  //     ? ""
  //     : `${Configs.API_URL_IMG + company.logo_path}`;
  //   var OEM_logo = Configs.API_URL_IMG
  //     ? ""
  //     : `${Configs.API_URL_IMG + oem_data.logo_path}`;

  //   const doc = new jsPDF("p", "mm", "a4");

  //   for (let index = 0; index < data.length; index = index + 8) {
  //     //console.log("start");
  //     doc.setDrawColor(0);
  //     doc.setFillColor("#272727");
  //     doc.rect(0, 0, 1080, 31, "F");

  //     doc.addImage(Com_logo, "JPEG", 1, 1, 29, 29);
  //     doc.addImage(OEM_logo, "JPEG", 180, 1, 29, 29);

  //     doc.setTextColor(255, 255, 255);
  //     doc.addFileToVFS("ROCK.TTF", Get_font_pdf_mat());

  //     doc.addFont("ROCK.TTF", "custom", "normal");

  //     doc.setFont("custom");
  //     //doc.setFont('bold')
  //     doc.setFontSize(15);
  //     const a = "Material Type";
  //     doc.text(45, 10, a);

  //     doc.setTextColor(255, 255, 255);
  //     //doc.setFont('bold')
  //     doc.setFontSize(35);
  //     doc.text(45, 25, docMatType);

  //     doc.setTextColor(255, 255, 255);
  //     doc.setFontSize(15);
  //     //doc.setFont('bold')
  //     doc.text(120, 10, "Material Spec");

  //     doc.setTextColor(255, 255, 255);
  //     doc.setFontSize(35);
  //     //.setFont('bold')
  //     doc.text(120, 25, docMatSpec);
  //     //box 1
  //     let img_qr = "";
  //     /* console.log(data[index+1].no.toString()); */
  //     let tepm = "";
  //     if (index < data.length) {
  //       QRCode2.toDataURL(data[index].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 1, 32, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       //doc.setFont('bold')
  //       doc.text(52, 37, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index].material_size_id === size[indexin].id) {
  //           doc.text(53, 60, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       //doc.setFont('bold')
  //       doc.text(7, 85, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(8, 90, data[index].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       //doc.setFont('bold')
  //       doc.text(52, 85, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index].name === null) {
  //         doc.text(53, 90, "None");
  //       } else {
  //         doc.text(53, 90, data[index].name);
  //       }
  //       doc.rect(0, 32, 105, 60);
  //     }

  //     // box 2
  //     if (index + 1 < data.length) {
  //       QRCode2.toDataURL(data[index + 1].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 103, 32, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       doc.text(152, 37, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index + 1].material_size_id === size[indexin].id) {
  //           doc.text(153, 60, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(109, 85, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(110, 90, data[index + 1].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(10);
  //       doc.text(152, 85, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index + 1].name === null) {
  //         doc.text(153, 90, "None");
  //       } else {
  //         doc.text(153, 90, data[index + 1].name);
  //       }
  //       doc.rect(105, 32, 105, 60);
  //     }
  //     //box 3
  //     if (index + 2 < data.length) {
  //       QRCode2.toDataURL(data[index + 2].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 1, 93, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       doc.text(52, 98, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index + 2].material_size_id === size[indexin].id) {
  //           doc.text(52, 125, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(7, 148, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(8, 153, data[index + 2].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(10);
  //       doc.text(52, 148, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index + 2].name === null) {
  //         doc.text(53, 153, "None");
  //       } else {
  //         doc.text(53, 153, data[index + 2].name);
  //       }
  //       doc.rect(0, 92, 105, 63);
  //     }

  //     //box 4
  //     if (index + 3 < data.length) {
  //       QRCode2.toDataURL(data[index + 3].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 103, 93, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       doc.text(152, 98, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index + 3].material_size_id === size[indexin].id) {
  //           doc.text(153, 125, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(109, 148, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(110, 153, data[index + 3].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(10);
  //       doc.text(152, 148, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index + 3].name === null) {
  //         doc.text(153, 153, "None");
  //       } else {
  //         doc.text(153, 153, data[index + 3].name);
  //       }
  //       doc.rect(105, 92, 105, 63);
  //     }

  //     //box 5
  //     if (index + 4 < data.length) {
  //       QRCode2.toDataURL(data[index + 4].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 1, 156, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       doc.text(52, 161, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index + 4].material_size_id === size[indexin].id) {
  //           doc.text(53, 188, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(7, 211, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(8, 216, data[index + 4].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(10);
  //       doc.text(52, 211, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index + 4].name === null) {
  //         doc.text(53, 216, "None");
  //       } else {
  //         doc.text(53, 216, data[index + 4].name);
  //       }
  //       doc.rect(0, 155, 105, 63);
  //     }

  //     //box 6

  //     if (index + 5 < data.length) {
  //       QRCode2.toDataURL(data[index + 5].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 103, 156, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       doc.text(152, 161, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index + 5].material_size_id === size[indexin].id) {
  //           doc.text(153, 188, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(109, 211, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(110, 216, data[index + 5].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(10);
  //       doc.text(152, 211, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index + 5].name === null) {
  //         doc.text(153, 216, "None");
  //       } else {
  //         doc.text(153, 216, data[index + 5].name);
  //       }
  //       doc.rect(105, 155, 105, 63);
  //     }

  //     //box 7
  //     if (index + 6 < data.length) {
  //       QRCode2.toDataURL(data[index + 6].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 1, 218, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       doc.text(52, 223, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index + 6].material_size_id === size[indexin].id) {
  //           doc.text(53, 250, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(7, 273, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(8, 278, data[index + 6].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(10);
  //       doc.text(52, 273, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index + 6].name === null) {
  //         doc.text(53, 278, "None");
  //       } else {
  //         doc.text(53, 278, data[index + 6].name);
  //       }
  //       doc.rect(0, 218, 105, 63);
  //     }

  //     //box 8

  //     if (index + 7 < data.length) {
  //       QRCode2.toDataURL(data[index + 7].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 103, 218, 50, 50);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(15);
  //       doc.text(152, 223, "Material Size");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(52);

  //       for (let indexin = 0; indexin < size.length; indexin++) {
  //         if (data[index + 7].material_size_id === size[indexin].id) {
  //           doc.text(153, 250, size[indexin].name);
  //         }
  //       }

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(109, 273, "Material No");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.text(110, 278, data[index + 7].no);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(10);
  //       doc.text(152, 273, "Material Name");
  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       if (data[index + 7].name === null) {
  //         doc.text(153, 278, "None");
  //       } else {
  //         doc.text(153, 278, data[index + 7].name);
  //       }
  //       doc.rect(105, 218, 105, 63);
  //     }

  //     if (index + 8 < data.length) {
  //       //console.log("next");
  //       doc.addPage("a4", "h");
  //     }
  //   }
  //   window.open(doc.output("bloburl"));
  //   //doc.save("pdf");
  // }

  // function downloadReport() {
  //   const tempMatData = [];
  //   const tempFilter = {
  //     no: "",
  //     name: "",
  //     material_type_id: filterOption.material_type_id,
  //     material_spec_id: filterOption.material_spec_id,
  //     material_size_id: "",
  //     supplier_id: "",
  //     oem_id: getOem(),
  //   };

  //   axios({
  //     method: "post",
  //     url: Configs.API_URL + "/material/filter",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: tempFilter,
  //   })
  //     .then(async (response) => {
  //       filterTypeSpec(response.data);
  //       /*    response.data.forEach(materail => {
  //            tempMatData.push(materail )
  //          });
  //  */
  //       //console.log(tempMatData);
  //     })
  //     .catch(async (error) => {
  //       console.log(error);
  //     });

  //   /*  filterTypeSpec(); */
  //   /* console.log(tempMatData); */
  //   /*  console.log(docMatSpec);
  //    */
  // }

  // function PoReport() {
  //   var Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;

  //   const doc = new jsPDF("p", "mm", "a4");

  //   doc.addImage(Com_logo, "JPEG", 20, 10, 30, 30);
  //   doc.setTextColor(0, 0, 0);
  //   doc.setFontSize(12);
  //   doc.addFont("bold");
  //   //doc.addFont('Angsana New', 'Angsana New','bold');
  //   //doc.setFont('Angsana New');
  //   doc.text(52, 16, "SHIMIZU Manufacturing Co., Ltd.");

  //   doc.setTextColor(0, 0, 0);
  //   doc.setFontSize(12);
  //   doc.addFont("ROCKI.TTF", "custom", "normal");

  //   doc.setFont("custom");
  //   doc.text(52, 20, "571 Prachapattana Road, Tabyao, Ladkrabang");

  //   doc.setTextColor(0, 0, 0);
  //   doc.addFont(
  //     "D:/Project/PMRP/App/src/assets/font/ROCKI.TTF",
  //     "ROCKI",
  //     "normal"
  //   );
  //   doc.setFont("ROCKI"); // set font
  //   doc.setFontSize(10);
  //   doc.text(52, 24, "เลขประจำตัวผู้เสียภาษี 0105539003283");

  //   doc.addFont("test/reference/PTSans.ttf", "PTSans", "normal");
  //   doc.setFont("PTSans"); // set font
  //   doc.setFontSize(10);
  //   doc.text("А ну чики брики и в дамки!", 100, 100);

  //   window.open(doc.output("bloburl"));
  // }

  // function load() {
  //   var status = "0";
  //   Swal.fire({
  //     title: "Loading",
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timer: 500,
  //     onOpen: () => {
  //       Swal.showLoading();
  //       status = "1";
  //     },
  //   }).then(() => {
  //     if (status == "1") {
  //       reportQR();
  //     } else {
  //       alert("tttt");
  //     }
  //   });
  // }

  // function reportQR() {
  //   var Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
  //   var OEM_logo = `${Configs.API_URL_IMG + oem_data.logo_path}`;
  //   var prod_img = `${Configs.API_URL_IMG}`;

  //   const doc = new jsPDF("p", "mm", "a4");
  //   let img_qr = "";
  //   for (let index = 0; index < materail.length; index = index + 8) {
  //     doc.setDrawColor(0);
  //     doc.setFillColor("#272727");
  //     doc.rect(0, 0, 1080, 31, "F");

  //     doc.addImage(Com_logo, "JPEG", 1, 1, 29, 29);
  //     doc.addImage(OEM_logo, "JPEG", 180, 1, 29, 29);
  //     /*  doc.addImage(imgData, 'JPEG',180, 1, 29, 29) */
  //     doc.setTextColor(255, 255, 255);
  //     doc.addFileToVFS("ROCK.TTF", Get_font_pdf_mat());

  //     doc.addFont("ROCK.TTF", "Rocki", "normal");

  //     doc.setFont("Rocki");
  //     doc.setFontSize(40);
  //     //doc.addFileToVFS("ROCKI.ttf", arimoBase64);
  //     //doc.addFont('ROCK', 'ROCKI','normal')
  //     //doc.setFont('ROCKI')

  //     doc.text(42, 20, "Materail LIST");
  //     //console.log(materail);
  //     //box 1
  //     if (index < materail.length) {
  //       QRCode2.toDataURL(materail[index].no.toString(), function (err, url) {
  //         img_qr = url;
  //       });
  //       doc.addImage(img_qr, "JPEG", 10, 32, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index].tooling_path_name,
  //         "JPEG",
  //         48,
  //         35,
  //         30,
  //         30
  //       );
  //       doc.rect(48, 37, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(15, 73, "MAT Code.");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(78, 82, materail[index].no, "right");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       //doc.addFont('Rocki', 'normal');
  //       //doc.setFont('Rocki');
  //       doc.text(78, 88, "MAT Name. : " + materail[index].name, "right");

  //       doc.setDrawColor(192, 192, 192);
  //       doc.rect(0, 32, 105, 60);
  //     }

  //     //box 2

  //     if (index + 1 < materail.length) {
  //       QRCode2.toDataURL(
  //         materail[index + 1].no.toString(),
  //         function (err, url) {
  //           img_qr = url;
  //         }
  //       );
  //       doc.addImage(img_qr, "JPEG", 115, 32, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index + 1].tooling_path_name,
  //         "JPEG",
  //         153,
  //         35,
  //         30,
  //         30
  //       );
  //       doc.rect(153, 37, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       //doc.addFont('App/public/dist/font/ROCKI.TTF.ttf', 'Rocki')
  //       //doc.addFont('Rocki', 'normal');
  //       //doc.setFont('Rocki');
  //       doc.text(120, 73, "MAT Code.");

  //       //doc.addFont('App/public/dist/font/ROCKI.TTF.ttf', 'Rocki')
  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(185, 82, materail[index + 1].no, "right"); //x 120

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       /* doc.addFont('Rocki', 'normal');
  //    doc.setFont('Rocki'); */
  //       doc.text(185, 88, "MAT Name. " + materail[index + 1].name, "right"); //120
  //       doc.setDrawColor(192, 192, 192);
  //       doc.rect(105, 32, 105, 60);
  //     }

  //     //box 3

  //     if (index + 2 < materail.length) {
  //       QRCode2.toDataURL(
  //         materail[index + 2].no.toString(),
  //         function (err, url) {
  //           img_qr = url;
  //         }
  //       );
  //       doc.addImage(img_qr, "JPEG", 10, 92, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index + 2].tooling_path_name,
  //         "JPEG",
  //         48,
  //         95,
  //         30,
  //         30
  //       );
  //       doc.rect(48, 97, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       /*   doc.addFont('Rocki', 'normal');
  //    doc.setFont('Rocki'); */
  //       doc.text(15, 133, "MAT Code.");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(78, 142, materail[index + 2].no, "right");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       /*  doc.addFont('Rocki', 'normal');
  //    doc.setFont('Rocki'); */
  //       doc.text(78, 148, "MAT Name. : " + materail[index + 2].name, "right");

  //       doc.setDrawColor(211, 211, 211);
  //       doc.rect(0, 92, 105, 60);
  //     }
  //     //box 4
  //     if (index + 3 < materail.length) {
  //       QRCode2.toDataURL(
  //         materail[index + 3].no.toString(),
  //         function (err, url) {
  //           img_qr = url;
  //         }
  //       );
  //       doc.addImage(img_qr, "JPEG", 115, 92, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index + 3].tooling_path_name,
  //         "JPEG",
  //         153,
  //         95,
  //         30,
  //         30
  //       );
  //       doc.rect(153, 97, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       /* doc.addFont('Rocki', 'normal');
  //    doc.setFont('Rocki'); */
  //       doc.text(120, 133, "MAT Code.");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(185, 142, materail[index + 3].no, "right");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       /*  doc.addFont('Rocki', 'normal');
  //    doc.setFont('Rocki'); */
  //       doc.text(185, 148, "MAT Name. : " + materail[index + 3].name, "right");

  //       doc.setDrawColor(211, 211, 211);
  //       doc.rect(105, 92, 105, 60);
  //     }
  //     //box 5

  //     if (index + 4 < materail.length) {
  //       QRCode2.toDataURL(
  //         materail[index + 4].no.toString(),
  //         function (err, url) {
  //           img_qr = url;
  //         }
  //       );
  //       doc.addImage(img_qr, "JPEG", 10, 152, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index + 4].tooling_path_name,
  //         "JPEG",
  //         48,
  //         155,
  //         30,
  //         30
  //       );
  //       doc.rect(48, 157, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       /* doc.addFont('Rocki', 'normal');
  //    doc.setFont('Rocki'); */
  //       doc.text(15, 193, "MAT Code.");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(78, 202, materail[index + 4].no, "right");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       /* doc.addFont('Rocki', 'normal');
  //    doc.setFont('Rocki'); */
  //       doc.text(78, 208, "MAT Name. : " + materail[index + 4].name, "right");

  //       doc.setDrawColor(211, 211, 211);
  //       doc.rect(0, 152, 105, 60);
  //     }
  //     //box 6

  //     if (index + 5 < materail.length) {
  //       QRCode2.toDataURL(
  //         materail[index + 5].no.toString(),
  //         function (err, url) {
  //           img_qr = url;
  //         }
  //       );
  //       doc.addImage(img_qr, "JPEG", 115, 152, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index + 5].tooling_path_name,
  //         "JPEG",
  //         153,
  //         155,
  //         30,
  //         30
  //       );
  //       doc.rect(153, 157, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(120, 193, "MAT Code.");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       /*  doc.addFont('Rocki', 'normal');
  //  doc.setFont('Rocki'); */
  //       doc.text(185, 202, materail[index + 5].no, "right");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       /*   doc.addFont('Rocki', 'normal');
  //  doc.setFont('Rocki'); */
  //       doc.text(185, 208, "MAT Name. : " + materail[index + 5].name, "right");

  //       doc.setDrawColor(211, 211, 211);
  //       doc.rect(105, 152, 105, 60);
  //     }
  //     //box 7
  //     if (index + 6 < materail.length) {
  //       QRCode2.toDataURL(
  //         materail[index + 6].no.toString(),
  //         function (err, url) {
  //           img_qr = url;
  //         }
  //       );
  //       doc.addImage(img_qr, "JPEG", 10, 212, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index + 6].tooling_path_name,
  //         "JPEG",
  //         48,
  //         215,
  //         30,
  //         30
  //       );
  //       doc.rect(48, 217, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(15, 253, "MAT Code.");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       /*   doc.addFont('Rocki', 'normal');
  //  doc.setFont('Rocki'); */
  //       doc.text(78, 262, materail[index + 6].no, "right");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       /*  doc.addFont('Rocki', 'normal');
  //  doc.setFont('Rocki'); */
  //       doc.text(78, 268, "MAT Name. : " + materail[index + 6].name, "right");

  //       doc.setDrawColor(211, 211, 211);
  //       doc.rect(0, 212, 105, 60);
  //     }
  //     //box 8

  //     if (index + 7 < materail.length) {
  //       QRCode2.toDataURL(
  //         materail[index + 7].no.toString(),
  //         function (err, url) {
  //           img_qr = url;
  //         }
  //       );
  //       doc.addImage(img_qr, "JPEG", 115, 212, 40, 40);

  //       doc.addImage(
  //         prod_img + materail[index + 7].tooling_path_name,
  //         "JPEG",
  //         153,
  //         215,
  //         30,
  //         30
  //       );
  //       doc.rect(153, 217, 30, 30);

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(12);
  //       doc.addFont("Rocki", "bold");
  //       doc.setFont("Rocki");
  //       doc.text(120, 253, "MAT Code.");

  //       doc.setTextColor(255, 0, 0);
  //       doc.setFontSize(34);
  //       /* doc.addFont('Rocki', 'normal');
  //  doc.setFont('Rocki'); */
  //       doc.text(185, 262, materail[index + 7].no, "right");

  //       doc.setTextColor(0, 0, 0);
  //       doc.setFontSize(9.3);
  //       /*  doc.addFont('Rocki', 'normal');
  //  doc.setFont('Rocki'); */
  //       doc.text(185, 268, "MAT Name. : " + materail[index + 7].name, "right");

  //       doc.setDrawColor(192, 192, 192);
  //       doc.rect(105, 212, 105, 60);
  //     }

  //     if (index + 8 < materail.length) {
  //       doc.addPage("a4", "h");
  //     }
  //   }

  //   window.open(doc.output("bloburl"));
  //   //doc.save("pdf");
  // }

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="materail" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Tooling{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Tooling</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={filterOption.tooling_code}
                    onChange={(e) => {
                      setfilterOption({
                        ...filterOption,
                        tooling_code: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Tooling Code</label>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={filterOption.tooling_name}
                    onChange={(e) => {
                      setfilterOption({
                        ...filterOption,
                        tooling_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Tooling Name</label>
                </div>
              </div>

              <div
                className="col-6 col-md-3 col-xl-2"
                style={{ "z-index": "98" }}
              >
                <div className="form-group">
                  <Select
                    options={supplier_option}
                    ref={select_supplier}
                    value={supplier_option.find(
                      (item) => item.value === filterOption.supplier_id
                    )}
                    onChange={(e) => {
                      //console.log(e);
                      if (e !== null) {
                        setfilterOption({
                          ...filterOption,
                          supplier_id: e.value || "",
                        });
                      }
                    }}
                  />
                  <label htmlFor="">Supplier</label>
                </div>
              </div>

              <div
                className="col-6 col-md-3 col-xl-2"
                style={{ "z-index": "98" }}
              >
                <div className="form-group">
                  <Select
                    options={brand_option}
                    ref={select_brand}
                    value={brand_option.find(
                      (item) => item.value === filterOption.brand_id
                    )}
                    onChange={(e) => {
                      //console.log(e);
                      if (e !== null) {
                        setfilterOption({
                          ...filterOption,
                          brand_id: e.value || "",
                        });
                      }
                    }}
                  />
                  <label htmlFor="">Brand</label>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      GetTooling();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearfilterOption();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2 ">
              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    data-toggle="modal"
                    data-target="#modal-default"
                    data-backdrop="static"
                  >
                    Import Excel
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <a
                    href={Tooling_excel}
                    download="Template_Master_Data_Tooling.xlsx"
                    style={{ fontSize: "" }}
                  >
                    <button type="button" className="btn btn-block btn-info">
                      download template.xls
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Tooling</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }} /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* <QRCode
      
      value={"Ctrl+1"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+2"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+3"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+4"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+5"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+6"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+7"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />
<QRCode
      
      value={"Ctrl+8"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />
    <QRCode
      
      value={"Ctrl+9"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    /> */}
            </div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
        <div className="modal fade" id="modal-default">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Upload Master Tooling</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={set_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput">
                      <label htmlFor="">Tooling file:</label>
                      <small style={{ color: "red" }}>
                        ** Excel from template **
                      </small>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            /* disabled={} */
                            className="custom-file-input"
                            id="exampleInputFile"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e) => {
                              upload_tooling_file(e);
                              e.target.value = null;
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            {excel.name !== null &&
                            excel.name !== "" &&
                            excel.name !== undefined
                              ? excel.name
                              : "Select File"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div
                      className="table-responsive" /* style={{ height: "800px" }} */
                    >
                      <MDBTable striped hover bordered small searching={true}>
                        <MDBTableHead>
                          <tr>
                            <th>No.</th>
                            <th>Tooling Code</th>
                            <th>Status</th>
                            <th>Error</th>
                            <th>Update</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {tooling_exel.map((el, index) => {
                            if (
                              tooling_exel.filter((x) => !x.status_update)
                                .length > 0
                            ) {
                              var status_up = false;
                            } else {
                              var status_up = true;
                            }
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.model}</td>
                                <td style={{ textAlign: "center" }}>
                                  {el.status === "Add" ? (
                                    <span style={{ color: "green" }}>Add</span>
                                  ) : el.status === "Update" ? (
                                    <span style={{ color: "green" }}>
                                      Update
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>False</span>
                                  )}
                                </td>
                                <td>{el.error}</td>
                                <td style={{ textAlign: "center" }}>
                                  {status_up ? (
                                    <span style={{ color: "green" }}>
                                      Success
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>False</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h3>
                      {excel.status !== null ? (
                        <span>
                          {excel.status === true
                            ? "Upload Success"
                            : "Upload Fail"}
                        </span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={set_excel}
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  <Footter /> */}
    </div>
  );
}
export default Tooling;
