import React, { useEffect, useState } from 'react';


const SummaryIndicator = ({ data, imgSource, title}) => {

  const [displayData, setDisplayData] = useState(0);
  const [unit, setUnit] = useState('K');

  useEffect(() => {

    const formatData = (data) => {
      if (data >= 1e9) {
        return (data / 1e9).toFixed(3) + 'B';
      } else if (data >= 1e6) {
        return (data / 1e6).toFixed(3) + 'M';
      } else if (data >= 1e3) {
        return (data / 1e3).toFixed(3) + 'K';
      } else {
        return data.toFixed(3);
      }
    };
  
    const formattedData = formatData(data);
    setDisplayData(formattedData);
  }, [data]);
  


    return (
        <div>
            <div className="card-monitor grid grid-cols-4">
                <div className="col-span-1">
                  <img className="card-image" src={imgSource} />
                </div>
                <div className="col-span-3">
                  <div className="monitor_card card-body">
                    <div className="card-title">{title} Energy</div>
                    
                    <div className="card-value">{displayData} kWh</div>
                  </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryIndicator;