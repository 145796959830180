import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "../Expenses_Approve/Expenses_Approve_Table.css";
import img_pay_slips from "../../../assets/img/accounting/Capa_1.png";
import img_report_icon from "../../../assets/img/accounting/Frame.png";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button as ButtonAnt, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import Select from "react-select";
import { GetStatusFilterAccounting } from "../Utils/Global_Function";

const ACCOUNTING_STATUS = {
  draft: "3d04de87-1ded-4968-b533-e8b2dda0c4d8", // แบบร่าง
  waiting: "a86edd8a-5b08-445c-8377-9def93ceb8c0", // รอดำเนินการ
  approve_not_upload: "99c1692b-8baa-46b3-983a-c5839a48cf84", // อนุมัติ	// อนุมัติแบบไม่ upload
  approve_uploaded: "d4329102-39f6-420a-820f-7c00f98cbcb3", // อนุมัตื	// อนุมัติแบบ upload
  not_approve: "8ab5629d-754c-4eda-8b8e-bd2920fa6167", // ไม่อนุมัติ
};

const CustomInput = ({ value, onClick }) => (
  <div className="input-group" style={{ width: "100%" }}>
    <input
      type="text"
      className="form-control float-left"
      onClick={onClick}
      value={value}
      data-provide="datepicker"
      data-date-language="th-th"
    />{" "}
    <div className="input-group-prepend">
      <span className="input-group-text">
        <i className="far fa-calendar-alt" />
      </span>
    </div>
  </div>
);

function Emp_Expenses_Table() {
  const [disableRetreatAfterSorting, setDisableRetreatAfterSorting] =
    useState(true);

  const [statusDropdown, setStatusDropdown] = useState({ data: [] });

  const [showModal, setShowModal] = useState(false);

  const [modalState, setModalState] = useState("");

  const [dataForSave, setDataForSave] = useState({
    id: "",
    remark_not_approve: "",
  });

  const [fileListApprove, setFileListApprove] = useState({
    data: [],
    onIndex: 0,
  });

  const [filterExpenses, setFilterExpenses] = useState({
    search: "",
    start_date: new Date(moment().startOf("month")),
    end_date: new Date(moment().endOf("month")),
    is_select_all: false,
    status_id: ""
  });

  const [expensesData, setExpensesData] = useState({
    data: [],
  });

  const handleCloseModal = () => {
    setDataForSave({
      ...dataForSave,
      id: "",
      remark_not_approve: "",
    });
    dataForSave.id = "";
    dataForSave.remark_not_approve = "";

    setShowModal(false);
  };

  const handleOpenModal = (state) => {
    setModalState(state);
    setShowModal(true);
  };

  async function GetSignEmpExpenses() {
    setDisableRetreatAfterSorting(false);

    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      search: filterExpenses.search,
      start_date: filterExpenses.start_date,
      end_date: filterExpenses.end_date,
      status_id: filterExpenses.status_id || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/EmpExpense/getSignEmpExpenses",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(function (response) {
        setExpensesData({
          ...expensesData,
          data: response.data.data,
        });
        expensesData.data = response.data.data;

        setDisableRetreatAfterSorting(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  //getEmpExpenses
  async function GetEmpExpenses() {
    setDisableRetreatAfterSorting(false);

    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      search: filterExpenses.search,
      start_date: filterExpenses.start_date,
      end_date: filterExpenses.end_date,
      status_id: filterExpenses.status_id || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/EmpExpense/filterEmpExpense",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(function (response) {
        setExpensesData({
          ...expensesData,
          data: response.data.data,
        });
        expensesData.data = response.data.data;

        setDisableRetreatAfterSorting(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  function clearFilter() {
    setFilterExpenses({
      search: "",
      start_date: new Date(moment().startOf("month")),
      end_date: new Date(moment().endOf("month")),
      is_select_all: false,
      status_id: "",
    });
    filterExpenses.start_date = new Date(moment().startOf("month"));
    filterExpenses.end_date = new Date(moment().endOf("month"));
    filterExpenses.search = "";
    filterExpenses.is_select_all = false;
    filterExpenses.status_id = "";

    GetEmpExpenses();
  }

  function GetDivAlign(value, align) {
    return <div style={{ textAlign: align || "left" }}>{value}</div>;
  }

  async function getFilesApproveByRefId(emp_exp_id) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/getFilesApproveOrgzByRefId",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        emp_exp_id: emp_exp_id,
        user_id: getUser().fup,
      },
    })
      .then(function (response) {
        // console.log("response :>> ", response.data.data);
        if (response.data.data && Array.isArray(response.data.data)) {
          setFileListApprove({ ...fileListApprove, data: response.data.data });
          fileListApprove.data = response.data.data;
        } else {
          setFileListApprove({ ...fileListApprove, data: [] });
          fileListApprove.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function DeleteEmpExpensesData(emp_exp_id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "บันทึก",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/EmpExpense/deleteEmpExpensesData",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: {
            emp_exp_id: emp_exp_id,
            user_id: getUser().fup,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              // setFilterExpenses({
              //   ...filterExpenses,
              //   is_select_all: false,
              // });
              // GetEmpExpenses();
              window.location.reload();
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  function GetBodyModal(modalState) {
    if (modalState === "view_remark_not_approve") {
      return (
        <div /* style={{ padding: "15px" }} */>
          <div>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <p>{dataForSave.remark_not_approve || "-"}</p>
            </div>
          </div>
        </div>
      );
    } else if (modalState === "view_slips") {
      return (
        <div /* style={{ padding: "15px" }} */>
          <div
            style={{
              padding: "15px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              hidden={fileListApprove.data.length == 0}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index - 1 == -1) {
                  index = fileListApprove.data.length - 1;
                } else {
                  index -= 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
                fileListApprove.onIndex = index;
              }}
            >
              {"<"}
            </div>
            <div
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {fileListApprove.data?.[fileListApprove.onIndex]
                ?.emp_file_path ? (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid "
                  src={
                    Configs.API_URL_IMG +
                    fileListApprove.data?.[fileListApprove.onIndex]
                      .emp_file_path
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    objectFit: "contain",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                />
              ) : (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid rounded shadow border-0"
                  src={userdefault_img.imgs}
                  style={{
                    // width: "60px",
                    // height: "60px",
                    position: "relative",
                    objectFit: "cover",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                />
              )}
            </div>
            <div
              hidden={fileListApprove.data.length == 0}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index + 1 == fileListApprove.data.length) {
                  index = 0;
                } else {
                  index += 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
                fileListApprove.onIndex = index;
              }}
            >
              {">"}
            </div>
          </div>
          <div>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <p>{dataForSave.remark_not_approve || "-"}</p>
            </div>
          </div>
        </div>
      );
    }
  }

  function generateDataTable(expenses, index) {
    const element = {};

    element["select"] = (
      <div style={{ textAlign: "center", paddingTop: "0px" }}>
        <input
          style={{ width: "20px", height: "20px" }}
          type="checkbox"
          id="select_all"
          disabled={
            expenses.emp_exp_status !== ACCOUNTING_STATUS.draft &&
            expenses.emp_exp_status !== ACCOUNTING_STATUS.not_approve
          }
          checked={expenses.is_select || false}
          onChange={(e) => {
            const dataTemp = [...expensesData.data];
            dataTemp[index].is_select = e.target.checked;
            setExpensesData({
              ...expensesData,
              data: dataTemp,
            });
            expensesData.data = dataTemp;

            const temp_is_select_all = dataTemp.every((el) => el.is_select);
            setFilterExpenses({
              ...filterExpenses,
              is_select_all: temp_is_select_all,
            });
            filterExpenses.is_select_all = temp_is_select_all;
          }}
        ></input>
      </div>
    );

    element["no"] = GetDivAlign(index + 1, "center");

    element["uep"] = GetDivAlign(expenses.emp_code || '-', 'center');

    element["no_search"] = index + 1;

    element["expenses_date"] = GetDivAlign(
      moment(expenses.emp_exp_start_date).format("DD-MM-YYYY"),
      "center"
    );
    element["expenses_date_search"] = moment(
      expenses.emp_exp_start_date
    ).format("DD-MM-YYYY");

    element["expenses_header_expenses"] = GetDivAlign(
      expenses.acc_et_name || "-",
      "center"
    );
    element["expenses_header_expenses_search"] = expenses.acc_et_name || "-";

    element["expenses_amount"] = GetDivAlign(
      Number(expenses.emp_exp_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-",
      "right"
    );
    element["expenses_amount_search"] =
      Number(expenses.emp_exp_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-";

    element["expenses_status"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{ display: "flex", gap: "5px", alignItems: "center" }}
          className={
            "btn-expenses-user-status" +
            (expenses.emp_exp_status === ACCOUNTING_STATUS.draft
              ? " expenses_user_status_0"
              : expenses.emp_exp_status === ACCOUNTING_STATUS.approve_not_upload
              ? " expenses_user_status_1"
              : expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
              ? " expenses_user_status_2 cursor_expenses_admin_status"
              : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
              ? " expenses_user_status_3 cursor_expenses_admin_status"
              : " expenses_user_status_4")
          }
          onClick={async () => {
            if (
              expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
            ) {
              setDataForSave({
                ...dataForSave,
                id: expenses.emp_exp_id,
                remark_not_approve: expenses.emp_exp_remark,
              });
              dataForSave.id = expenses.emp_exp_id;
              dataForSave.remark_not_approve = expenses.emp_exp_remark;
              getFilesApproveByRefId(expenses.emp_exp_id);
              handleOpenModal("view_slips");
            } else if (
              expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
            ) {
              setDataForSave({
                ...dataForSave,
                id: expenses.emp_exp_id,
                remark_not_approve: expenses.emp_exp_remark,
              });
              dataForSave.id = expenses.emp_exp_id;
              dataForSave.remark_not_approve = expenses.emp_exp_remark;
              handleOpenModal("view_remark_not_approve");
            }
          }}
        >
          {expenses.emp_status_name}
          {expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded ? (
            <i
              style={{ fontSize: "16px" }}
              class="ri-money-dollar-circle-fill"
            ></i>
          ) : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve ? (
            <i style={{ fontSize: "16px" }} class="ri-message-fill"></i>
          ) : (
            <></>
          )}
        </div>
      </div>
    );

    element["mgt"] = (
      <div
        className=""
        style={{
          // flexWrap: "nowrap",
          display: "flex",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{ textAlign: "center", padding: 0 }}
        >
          <a
            href={
              "/accounting/emp_expenses/view/" +
              expenses.emp_exp_id
            }
            id="btn_read"
            key={expenses.rp_id + "_view"}
            className="btn btn-xs "
            onClick={() => {
              sessionStorage.setItem('state_filter_expenses', JSON.stringify({
                search: filterExpenses.search,
                start_date: filterExpenses.start_date,
                end_date: filterExpenses.end_date,
              }));
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>

        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{ textAlign: "center" }}
          hidden={
            expenses.emp_exp_status !== ACCOUNTING_STATUS.draft &&
            expenses.emp_exp_status !== ACCOUNTING_STATUS.waiting
          }
        >
          <a
            href={
              "/accounting/emp_expenses/edit/" +
              expenses.emp_exp_id
            }
            id="btn_edit"
            key={expenses.emp_exp_id + "_edit"}
            className="btn btn-xs"
            onClick={() => {
              sessionStorage.setItem('state_filter_expenses', JSON.stringify({
                search: filterExpenses.search,
                start_date: filterExpenses.start_date,
                end_date: filterExpenses.end_date,
              }));
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{ textAlign: "center" }}
          hidden={
            expenses.emp_exp_status !== ACCOUNTING_STATUS.draft &&
            expenses.emp_exp_status !== ACCOUNTING_STATUS.not_approve
          }
        >
          <a
            key={expenses.emp_exp_id + "_del"}
            id="btn_delete"
            className="btn btn-xs"
            onClick={() => {
              DeleteEmpExpensesData(expenses.emp_exp_id);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    return element;
  }

  const dataExpenses = [];
  for (let index = 0; index < expensesData.data.length; index++) {
    const expenses = expensesData.data[index];
    dataExpenses.push(generateDataTable(expenses, index));
  }

  const data = {
    columns: [
      {
        label: (
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <input
              style={{ width: "20px", height: "20px" }}
              type="checkbox"
              id="select_all"
              checked={filterExpenses.is_select_all}
              onChange={(e) => {
                const dataTemp = [...expensesData.data];
                dataTemp.forEach((el) => {
                  if (
                    el.emp_exp_status === ACCOUNTING_STATUS.draft ||
                    el.emp_exp_status === ACCOUNTING_STATUS.not_approve
                  ) {
                    el.is_select = e.target.checked;
                  }
                });
                setExpensesData({
                  ...expensesData,
                  data: dataTemp,
                });
                expensesData.data = dataTemp;
                setFilterExpenses({
                  ...filterExpenses,
                  is_select_all: e.target.checked,
                });
                filterExpenses.is_select_all = e.target.checked;
              }}
            ></input>
          </div>
        ),
        field: "select",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("No.", "center"),
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("UEP", "center"),
        field: "uep",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("วันที่", "center"),
        field: "expenses_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("หัวข้อค่าใช้จ่าย", "center"),
        field: "expenses_header_expenses",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("ยอดเงิน", "center"),
        field: "expenses_amount",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("สถานะ", "center"),
        field: "expenses_status",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("MGT", "center"),
        field: "mgt",
        // sort: "asc",
        width: 100,
      },
    ],
    rows: dataExpenses,
  };

  async function sendApproveFromEmpToOrgz(emp_id) {
    const tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      emp_id_list: emp_id,
      status_id: ACCOUNTING_STATUS.waiting,
      remark: "",
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/EmpExpense/sendApproveFromEmpToOrgz",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        if (response.data.data !== false) {
          Swal.fire({
            icon: "success",
            title: "ส่งอนุมัติสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            // GetEmpExpenses();
            window.location.reload();
          });
        } else {
          Swal.fire("Error", "ส่งอนุมัติไม่สำเร็จ", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "Error",
          "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
          "error"
        );
      });
  }

  async function GetStatusDropdown() {
    const data = await GetStatusFilterAccounting({
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      type: 1 // 1 is status expenses 2 is tax witholding
    });
    setStatusDropdown({
      data: data
    });
    statusDropdown.data = data;
  }

  useEffect(() => {
    const data2 = sessionStorage.getItem('state_filter_expenses');
    if (data2) {
      const data_filter = JSON.parse(data2);
      setFilterExpenses({
        ...filterExpenses,
        search: data_filter.search,
        start_date: new Date(data_filter.start_date),
        end_date: new Date(data_filter.end_date),
      });
      filterExpenses.search = data_filter.search;
      filterExpenses.start_date = new Date(data_filter.start_date);
      filterExpenses.end_date = new Date(data_filter.end_date);
      sessionStorage.removeItem('state_filter_expenses');
    }
    GetStatusDropdown();
    GetEmpExpenses();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Emp. Expenses {"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">Emp. Expenses</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterExpenses.search}
                    onChange={(e) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterExpenses.start_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        start_date: date,
                      });
                    }}
                    maxDate={new Date(filterExpenses.end_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>Start Date</label>
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterExpenses.end_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        end_date: date,
                      });
                    }}
                    minDate={new Date(filterExpenses.start_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>End Date</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group" /* style={{ zIndex: '100' }} */>
                  <Select
                    // isMulti
                    // isDisabled={isDisabled}
                    options={statusDropdown.data}
                    // isOptionDisabled={(option) => option.disabled}
                    // formatOptionLabel={(label) => label.html}
                    filterOption={(option, searchText) => {
                      if (
                        option.data.label
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                        // || option.data.value
                        //   .toLowerCase()
                        //   .includes(searchText.toLowerCase())
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    value={
                      statusDropdown.data.find(el => el.value == (filterExpenses.status_id || null))
                    }
                    onChange={(e) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        status_id: e.value
                      })
                    }}
                  />
                  <label>Status</label>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      GetEmpExpenses();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
                  
          <div className="container-fluid">
            <div className="row" 
            //ส่งอนุมัติ   
            >
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    disabled={
                      expensesData.data.filter((el) => el.is_select).length ==
                        0 ||
                      expensesData.data
                        .filter((el) => el.is_select)
                        .some(
                          (el) =>
                            el.emp_exp_status !== ACCOUNTING_STATUS.draft &&
                            el.emp_exp_status !== ACCOUNTING_STATUS.not_approve
                        )
                    }
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      const _data = [...expensesData.data]
                        .filter((el) => el.is_select)
                        .map((el) => el.emp_exp_id);

                      sendApproveFromEmpToOrgz(_data);
                    }}
                  >
                    ส่งอนุมัติ 
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/accounting/emp_expenses/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div
              className="card-body table-responsive"
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                disableRetreatAfterSorting={disableRetreatAfterSorting}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            <div className="card-footer"></div>
          </div>

          <Modal
            centered
            isOpen={showModal}
            toggle={handleCloseModal}
            // style={{ width: "50%" }}
          >
            <ModalHeader
              style={{
                /* border: "none" */ paddingTop: "10px",
                paddingBottom: "10px",
              }}
              toggle={
                modalState === "view_remark_not_approve" ||
                modalState === "view_slips"
                  ? handleCloseModal
                  : undefined
              }
            >
              <span style={{ fontSize: "24px", fontWeight: "800" }}>
                {modalState === "view_remark_not_approve"
                  ? "รายละเอียดการไม่อนุมัติ"
                  : modalState === "view_slips"
                  ? "รายละเอียดการอนุมัติ"
                  : ""}
              </span>
            </ModalHeader>

            <ModalBody style={{ padding: "0" }}>
              {GetBodyModal(modalState)}
            </ModalBody>
          </Modal>
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Emp_Expenses_Table;
