import React, { Component, useEffect, useState,useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from  'sort-json-array';
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import QRCode from 'qrcode.react';
import Configs from "../../config";
import { getUser, getOem, getToken,getAcademy } from "../../Utils/Common";
import { userdefault_img,logoTTT } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import moment from "moment";
import Select from "react-select";
function LogisticInOut(params) {

  const selectCompanyGroup = useRef();

  const [companyGroup, setCompanyGroup] = useState([]);
  const [filter_logistic, setFilter_logistic] = useState({
    plate_number: "",
      oem_target: getOem(),
    statusDTC: getUser().com,
    company_id: getUser().com,
    oem_id:getOem()
});
const [logistic, setLogistic] = useState([]);
const [logistic_status, setLogistic_status] = useState([]);
var config_getOem = {
  method: "get",
  url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
  headers: {
    Authorization: getToken(),
    "Content-Type": "application/json",
    "X-TTT": Configs.API_TTT,
  },
};
  useEffect(() => {
    axios(config_getOem)
    .then(function (response) {
   
      const options = [];

      var element2 = {};
      element2["value"] = "";
      element2["label"] = "---ทั้งหมด---";
      options.push(element2);
      response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.name;
        options.push(element);
      });
     
      //console.log(options);
      setCompanyGroup(options);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, []);
 

  
  const GetLogistic = async () => {
    
    //console.log(filler_logistic);
    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/filterLogisticInOut",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_logistic,
    })
      .then(function (response) {
        // console.log(response.data);
        setLogistic(response.data);
        let box_num_arr = []
        for(let car of response.data){
          box_num_arr.push(car.box_number);
        }
        var _data_body = {
          api_token_key: Configs.DTC_TOKEN,
          gps_list: box_num_arr,
        };
    
        axios({
          method: "post",
          url: Configs.DTC_HOST + "/getRealtimeData",
          headers: {
            "Content-Type": "application/json",
          },
          data: _data_body,
        })
          .then(function (response2) { 
              //console.log(response2.data.data)
              setLogistic_status(response2.data.data)
          }).catch(function (error) {
            console.log(error);
          })
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const select_logic = useRef();
 

  

  useEffect(() => {
    GetLogistic();
   /*  Gettable();
    Getmodel();
    GetLogistic(); */
  }, []);

 

  
 
 

  const clearfillter = async () => {
    await setFilter_logistic({...filter_logistic,plate_number: "", oem_target: getOem(), statusDTC: "",company_id: getUser().com,oem_id:getOem()});
    filter_logistic.plate_number = "";
    filter_logistic.oem_target = getOem();
    filter_logistic.statusDTC = "";
    filter_logistic.company_id = getUser().com;
    filter_logistic.oem_id = getOem();
    
    GetLogistic();
  };


  // /////////////////////////////////////////
  var rowData = [];
  for(var index = 0; index < logistic.length; index++){
    const rowItem = {};
    rowItem['plate_no'] = logistic[index].plate_number;
    let oem_name = "";
    let check_oem = companyGroup.filter((e)=>{return e.value === logistic[index].oem_id});
    if(check_oem.length > 0){
      oem_name =check_oem[0].label;
    }
    rowItem['oem'] = oem_name;
    rowItem["logistic_date"] = logistic[index].bill_logistic.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {moment(el.logistic_date).format('DD/MM/yyyy')}{" "}
       </>
        </div>
      );
    });
    rowItem["delivery_sort"] = logistic[index].bill_logistic.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.delivery_sort}{" "}
       </>
        </div>
      );
    });
    rowItem["bill_no"] = logistic[index].bill_logistic.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.bill_no}{" "}
       </>
        </div>
      );
    });

    rowItem["customer"] = logistic[index].bill_logistic.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.customer_name}{" "}
       </>
        </div>
      );
    });

    rowItem["bill_destination"] = logistic[index].bill_logistic.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.bill_destination}{" "}
       </>
        </div>
      );
    });

    
    rowItem["bill_value"] = logistic[index].bill_logistic.map((el, i) => {
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {el.bill_values.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
       </>
        </div>
      );
    });

    rowItem["start_time"] = logistic[index].bill_logistic.map((el, i) => {
      var start_time = "...";
     
      if(el.start_time !== null){
        start_time =moment(el.start_time).format('HH:mm')+ " น.";
      }
      if(el.remark !== null && el.remark !== null){
        start_time = <font color = "red">ยกเลิก</font>;
      }
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {start_time}{" "}
       </>
        </div>
      );
    });

    rowItem["finish_time"] = logistic[index].bill_logistic.map((el, i) => {
      var finish_time = "...";
      if(el.finish_time !== null){
        finish_time =moment(el.finish_time).format('HH:mm')+ " น.";
      }
      if(el.remark !== null && el.remark !== null){
        finish_time = <font color = "red">ยกเลิก</font>;
      }
      return (
        <div style={{whiteSpace: 'nowrap'}}>   
            <>
          {finish_time}{" "}
       </>
        </div>
      );
    });
    
    var status_DTC_Check = logistic_status.filter((e)=>{return e.gps_id === logistic[index].box_number});
    var status_DTC = "";
    if(status_DTC_Check.length > 0){
      status_DTC = status_DTC_Check[0].status_name_th;
    }
    rowItem["status_DTC"]= status_DTC;
    rowItem["mgt"] = (
      <div className = "row">
    <div className="col-12">
    <a
      href={
        "/Warehouse_Management/Logistic_IN_OUT/edit/" +
        logistic[index].id
      }
      key={logistic[index].id}
      className=" btn btn-xs " /* onClick={editpage.bind(this,materail[index].id)} */
    >
      {" "}
      <i class="fas fa-pencil-alt"></i>{" "}
    </a>
  </div>
  </div>
  )


      
    rowData.push(rowItem)
  }





  
 
    const data = {
      columns: [
        {
          label: "ทะเบียน",
          field: "plate_no",
          sort: "asc",
          width: 50,
        },
        {
          label: "บริษัท",
          field: "oem",
          sort: "asc",
          width: 50,
        },
        {
          label: "วันที่", 
          field: "logistic_date",
          sort: "asc",
          width: 50,
        },
        {
          label: "เที่ยวที่", 
          field: "delivery_sort",
          sort: "asc",
          width: 50,
        },
        {
          label: "ใบส่งของ", 
          field: "bill_no",
          sort: "asc",
          width: 50,
        },
        {
          label: "ลูกค้า", 
          field: "customer",
          sort: "asc",
          width: 50,
        },
        {
          label: "หน้างาน", 
          field: "bill_destination",
          sort: "asc",
          width: 50,
        },
      
        {
          label: "มูลค่า", 
          field: "bill_value",
          sort: "asc",
          width: 50,
        },
        {
          label: "เวลาออก", 
          field: "start_time",
          sort: "asc",
          width: 50,
        },
        {
          label: "เวลาเข้า", 
          field: "finish_time",
          sort: "asc",
          width: 50,
        },
        {
          label: "สถานะ", 
          field: "status_DTC",
          sort: "asc",
          width: 50,
        },
        {
          label: "Mgt",
          field: "mgt",
          sort: "asc",
          width: 50,
        },
      ],
      rows: rowData,
    };
  


  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="logisticinout" submenu="none" /> */}
      <div className="content-wrapper">
       
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Logistic IN/OUT{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Logistic IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_logistic.plate_number}
                    onChange={(e) => {
                      setFilter_logistic({
                        ...filter_logistic,
                        plate_number: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ทะเบียนรถ</label>{" "}
               
                </div>
              </div>
          
            {/*   <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filter_logistic.oem_target}
                    onChange={(e)=>{setFilter_logistic({...filter_logistic,oem_target:e.target.value})}}
                  >
                    {companyGroup.map((e)=>{return  <option value={e.value}>{e.label}</option> })}
         
            
                  </select>
                  <label htmlFor="">กลุ่มบริษัท</label>
                </div>
              </div> */}
            
           
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetLogistic();
                      //Gettable();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearfillter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
             

              {/*
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary" onClick={showmessage}>
                    QR Code
                  </button>
                </div>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Logistic IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive"   style={{whiteSpace: 'nowrap'}}   >
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
       
      </div>
     
                     {/*  <Footter/> */}
                      </div>
  );
}
export default LogisticInOut;
