import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";

function Customer_add(mode) {
  const [filter_product, setfilter_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });
  const [test, settest] = useState({
    test: "",
  });
  const [MDBT, setMDBT] = useState(true);
  const [customer, setcustomer] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    name: "",
    full_name: "",
    is_use: "",
    sort: "",
    detail: "",
    contact_tel: "",
    contact_fax: "",
    contact_name: "",
    customer_email: "",
    relate_product_id: "",
    remark: "",
    line_id: "",
    customer_id: "",
  });

  const [product, setProduct] = useState([]);
  const [product_select, setProduct_select] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const { id } = useParams();
  const GetProduct = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product,
    })
      .then(async (response) => {
        //console.log(response.data)
        //setProduct([]);
        for (let item of response.data) {
          var check = product_select.filter((e) => {
            return e.id === item.id;
          });
          if (check.length > 0) {
            item.isPush = true;
          } else {
            item.isPush = false;
          }
        }
        //console.log(response.data)

        setProduct(response.data);
        setMDBT(true);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  useEffect(() => {
    GetProduct();
    if (mode.mode === "read" || mode.mode === "edit") {
      GetCus_view();
    }
  }, []);

  function pushProduct(e) {
    const product_select_temp = product_select;
    var data = product.filter((element) => {
      return element.id === e.target.id;
    });
    const product_p = product;
    setProduct([]);
    for (let i of product_p) {
      if (i.id === e.target.id) {
        i.isPush = true;
      }
    }
    setProduct(product_p);
    //console.log(product_select_temp);
    var checked = product_select_temp.filter((e2) => {
      return e2.id === data[0].id;
    });
    //console.log(checked)
    if (checked.length > 0) {
      console.log("ซ้ำ");
    } else {
      product_select_temp.push(data[0]);
    }
    setProduct_select([]);
    setProduct_select(product_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function removeProduct(e) {
    //console.log(e.target.id);
    const product_p = product;
    setProduct([]);
    for (let i of product_p) {
      if (i.id === e.target.id) {
        i.isPush = false;
      }
    }
    setProduct(product_p);
    const product_select_temp = product_select;
    setProduct_select([]);
    let indexOf = product_select_temp.findIndex(
      (element) => element.id === e.target.id
    );
    product_select_temp.splice(indexOf, 1);
    setProduct_select(product_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
    //console.log(data[0]);
  }
  //console.log(product_select);
  const Product_data = [];
  for (var index = 0; index < product.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["id"] = product[index].id;
    rowItem["product_no"] = product[index].no;
    rowItem["product_name"] = product[index].name;
    rowItem["mgt"] = (
      <>
        <div className="row">
          {product[index].isPush === true ? (
            <>
              <div className="col-12">
                <button
                  type="button"
                  id={product[index].id}
                  onClick={removeProduct.bind(this)}
                  class="btn btn-block btn-danger"
                >
                  Remove
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <button
                  type="button"
                  id={product[index].id}
                  onClick={pushProduct.bind(this)}
                  class="btn btn-block btn-primary "
                >
                  Push
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );
    Product_data.push(rowItem);
  }

  const TableProduct = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Product_data,
  };

  const Product_data_select = [];

  for (var index = 0; index < product_select.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["id"] = product_select[index].id;
    rowItem["product_no"] = product_select[index].no;
    rowItem["product_name"] = product_select[index].name;
    rowItem["mgt"] = (
      <>
        <div className="row">
        <div className="col-0 col-md-3 col-xl-4"/>
          <div className="col-12 col-md-6 col-xl-4">
            <button
              type="button"
              id={product_select[index].id}
              onClick={removeProduct.bind(this)}
              class="btn btn-block btn-danger "
            >
              Remove
            </button>
          </div>
        </div>
      </>
    );
    Product_data_select.push(rowItem);
  }

  const TableProduct_select = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Product_data_select,
  };

  const clearFilter = async () => {
    setfilter_product({
      ...filter_product,
      no: "",
      name: "",
      product_model_id: "",
      oem_id: getOem(),
    });
    filter_product.no = "";
    filter_product.name = "";
    filter_product.product_model_id = "";

    GetProduct();
  };

  function saveOption(params) {
    const error_list = [];
    /*     if(customer.customer_id.trim() == ""){
      let temp_err = {
        massage:"Please enter information in the fields >>> [รหัสลูกค้า]."
      }
      error_list.push(temp_err);
    } */
    if (customer.name == "") {
      let temp_err = {
        massage: "Please enter information in the fields >>> [Customer No].",
      };
      error_list.push(temp_err);
    }
    if (customer.full_name == "") {
      let temp_err = {
        massage: "Please enter information in the fields >>> [Customer Name].",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_massage = "";
      for (var e = 0; e < error_list.length; e++) {
        err_massage += "<br>" + error_list[e].massage;
      }
      Swal.fire("Error", err_massage, "error");
    } else {
      const temp_save = {
        company_id: customer.company_id,
        name: customer.name,
        full_name: customer.full_name,
        detail: customer.detail,
        contact_tel: customer.contact_tel,
        contact_fax: customer.contact_fax,
        contact_name: customer.contact_name,
        customer_email: customer.customer_email,
        remark: customer.remark,
        relate_product: product_select,
        oem_id: customer.oem_id,
        line_id: customer.line_id,
      };
      //console.log(temp_save)
      if (mode.mode === "add") {
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/customer/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Customer";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error.response.data.message);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {
        const temp_edit = {
          id: id,
          company_id: customer.company_id,
          name: customer.name,
          full_name: customer.full_name,
          is_use: customer.is_use,
          detail: customer.detail,
          contact_tel: customer.contact_tel,
          contact_fax: customer.contact_fax,
          contact_name: customer.contact_name,
          customer_email: customer.customer_email,
          remark: customer.remark,
          relate_product: product_select,
          oem_id: customer.oem_id,
          line_id: customer.line_id,
        };

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/customer/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_edit,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Customer";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
    }
  }

  const GetCus_view = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url: Configs.API_URL + "/customer/findById?id=" + id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          setcustomer({
            ...customer,
            company_id: response.data.company_id,
            name: response.data.name,
            is_use: response.data.is_use,
            sort: response.data.sort,
            detail: response.data.detail,
            contact_tel: response.data.contact_tel,
            contact_fax: response.data.contact_fax,
            customer_email: response.data.customer_email,
            relate_product_id: response.data.relate_product_id,
            remark: response.data.remark,
            contact_name: response.data.contact_name,
            full_name: response.data.full_name,
            line_id: response.data.line_id,
            oem_id: response.data.oem_id,
          });
          console.log(response.data);
          for (let e of response.data.relate_product) {
            e.id = e.product_id;
          }

          setProduct_select(response.data.relate_product);

          axios({
            method: "post",
            url: Configs.API_URL + "/product/filter",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: filter_product,
          })
            .then(async (response2) => {
              for (let item of response2.data) {
                var check = response.data.relate_product.filter((e) => {
                  return e.id === item.id;
                });
                if (check.length > 0) {
                  item.isPush = true;
                } else {
                  item.isPush = false;
                }
              }
              //console.log(response.data)

              setProduct(response2.data);
            })
            .catch(async (error) => {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Warehouse_Management/Factory_Master_Data/Customer";
      }
    });
  }

  return (
    <div className="wrapper">
      {/*    <Header />
      <Sidebar menu="warehouse" activemenu="customer" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Customer {pageMode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Customer</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    if
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Customer {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <h3 className="head">Customer Detail</h3>
              <div className="tab-custom-content"></div>
              <br />

              {/*  <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                     //value={customer.customer_id.trim()}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          customer_id: e.target.value.trim(),
                        });
                      }}

                    />
                    <label>
                      รหัสลูกค้า
                    
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.name}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Customer No
                      {customer.name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.full_name}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          full_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Customer Name
                      {customer.full_name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={customer.detail}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          detail: e.target.value,
                        });
                      }}
                    />
                    <label>Customer Detail</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.customer_email}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          customer_email: e.target.value,
                        });
                      }}
                    />
                    <label>Customer Email</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={customer.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          contact_fax: e.target.value,
                        });
                      }}
                    />
                    {/* <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.contact_fax}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          contact_fax: e.target.value,
                        });
                      }}
                    /> */}
                    <label>Contact_Fax</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={customer.contact_tel}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          contact_tel: e.target.value,
                        });
                      }}
                    />

                    <label>Contact Tel</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.line_id}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          line_id: e.target.value,
                        });
                      }}
                    />
                    <label>Line ID</label>
                  </div>
                </div>
              </div>
              {/*  <h3 className="mt-5 mt-5-head">
                Contact Point
              </h3>
              <div className="tab-custom-content">
              </div>
              <br />
              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.contact_name}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          contact_name: e.target.value.trim(),
                        });
                      }}
                    />
                    <label>
                      Contact Name
                  
                    </label>
                  </div>
                </div>
                <div className="col-4">
                  <div class="form-group">
                  <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-################"}
                      value={customer.contact_tel}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          contact_tel: e.target.value,
                        });
                      }}
                    />
              
                    <label>
                      Contact Tel
                    
                    </label>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.line_id}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          line_id: e.target.value.trim(),
                        });
                      }}
                    />
                    <label>
                      Line ID
                    
                    </label>
                  </div>
                </div>
                </div>
 */}

              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={customer.remark}
                      disabled={disable}
                      onChange={(e) => {
                        setcustomer({
                          ...customer,
                          remark: e.target.value,
                        });
                      }}
                    />
                    <label>Remark</label>
                  </div>
                </div>

                <div className="col-12">
                  <h3 className="mt-5 head">Relate Product</h3>
                  <div className="tab-custom-content"></div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="">Select Product</label>
                      <div className="row">
                        <div className="col-6 col-md-4 col-xl-2">
                          <button
                            type="button"
                            class="btn btn-block btn-info "
                            disabled={mode.mode === "read" ? true : false}
                            data-toggle="modal"
                            data-target="#modal-product"
                            // onClick={testassy}
                          >
                            Select Product
                          </button>
                        </div>
                        {/* {product_select_temp.length > 0 && mode.mode != "read" ? (
                        <>
                        <div className="col-2">
                          <button
                            type="button"
                            class="btn btn-block btn-danger "
                            onClick={clear_restore}
                          >
                            Clear
                          </button>
                        </div>
                        </>
                      ):(
                     <></>)} */}
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-12">
                      <div
                        className="table-responsive"
                        style={{ height: "600px", whiteSpace: "nowrap" }}
                      >
                        <MDBDataTable
                          className="table table-head-fixed"
                          sortable={false}
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={TableProduct_select}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* ------------------------------------------------------------------------------------------------------------------ */}
                  <div className="modal fade" id="modal-product">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Product</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput"></div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required="false"
                                      value={filter_product.no}
                                      onChange={(e) => {
                                        setfilter_product({
                                          ...filter_product,
                                          no: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">Product No</label>{" "}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                  <div className="form-group ">
                                    <input
                                      type="text"
                                      className="form-control"
                                      required="false"
                                      value={filter_product.name}
                                      onChange={(e) => {
                                        setfilter_product({
                                          ...filter_product,
                                          name: e.target.value,
                                        });
                                      }}
                                    />
                                    <label htmlFor="">Product Name</label>{" "}
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 col-xl-2">
                                  <div className="form-group ">
                                    <button
                                      type="button"
                                      class="btn btn-block btn-info "
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onClick={() => {
                                        setMDBT(false);
                                        GetProduct();
                                        //setMDBT(true);
                                      }}
                                    >
                                      Search
                                    </button>
                                  </div>
                                </div>

                                <div className="col-6 col-md-4 col-xl-2">
                                  <div className="form-group ">
                                    <button
                                      type="button"
                                      class="btn btn-block btn-info "
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onClick={() => {
                                        setMDBT(false);
                                        clearFilter();
                                        //setMDBT(true);
                                      }}
                                    >
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <br />

                              <div
                                className="table-responsive"
                                style={{
                                  height: "500px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <MDBDataTable
                                  className="table table-head-fixed"
                                  //paging={false}
                                  sortable={false}
                                  disableRetreatAfterSorting={MDBT}
                                  onPageChange={(page) => {
                                    console.log(page);
                                  }}
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={TableProduct}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h3></h3>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <Footter /> */}
    </div>
  );
}
export default Customer_add;
