import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature,getAcademy } from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import moment from 'moment';
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import NumberFormat from "react-number-format";
import Sidebar from "../../component/Sidebar";
import { useParams } from "react-router";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import QRCode2 from "qrcode";
import { Get_font_pdf } from "../../assets/font/pdf_font";
import jsPDF from "jspdf";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
function Product_Approve(params) {
  const [order_product, setOrder_product] = useState([]);
  const [filler_order_product, setFiller_order_product] = useState({
    order_no: "",
    start_date: "",
    end_date: "",
    oem_id: getOem()
  });

  const [feature, setfeature] = useState([]);

  const [disable, setdisable] = useState(null);
  const [disable2, setdisable2] = useState(null);
  const [pageMode, setPageMode] = useState("");
  //const[show,setshow] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    if (params.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (params.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }


  }, []);

  var show;

  feature.map((e) =>{
   
    if(e.feature === "PBN Report"){
      show = 0
    }
    else{
      show = 1
    }
  })

  console.log(show);
  
  const [view_order, setView_order] = useState([]);

  const [view_order2, setView_order2] = useState([]);


  const [filler_view_order, setFiller_view_order] = useState({
    product_order_id: "",
  });
  const [view_order_no, setView_order_no] = useState({
    order_no: "",
    order_id: "",
  });

const[order_index,setorder_index] =  useState("");


const CustomInput = ({ value, onClick }) => ( 
      
  <
  div className = "input-group" >
  < input type = "text"
  className = "form-control float-left"
  onClick = { onClick }
  value = { value }
  /> <div className = "input-group-prepend" >
  <span className = "input-group-text" >
  <i className = "far fa-calendar-alt" />
  </span>

  { /*  <label>test</label> */ } 
  </div> 
  </div>


)





  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const [modal, setModal] = useState(false);
  const toggle_modal = () => setModal(!params);



  /* async function update_order_rrr (e) {

    console.log(e.target.value); 
    var temp2 = view_order;

    temp2[e.target.name].rrr =  (e.target.value); 
    rowsData[e.target.name].rrr =  (
      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat
        format="####-##/#" 
          placeholder="0000-00/0" 
          type = "text"
          style={{width: "100px"}}
          className="form-control"
          required
            allowNegative ={false}
           
            name={e.target.name}
            disabled={disable}
            thousandSeparator={true}
            value={e.target.value || ""} 
          onChange={update_order_rrr.bind(this)}
        />
        
        </div>
        </div>
   
      );
    setView_order(temp2);
  
  } */


  async function update_order_weitght (e) {

    console.log(e.target.value); 
    var temp1 = view_order;

    temp1[e.target.name].weitght =  Number(e.target.value.replace(/,/g, "")); 
    rowsData[e.target.name].weitght =  (
      <div className="col-md-1.5">
        <div className="form-group ">

      
        <NumberFormat
          type = "text"
          style={{width: "100px"}}
          className="form-control"
          required
            allowNegative ={false}
           
            name={e.target.name}
            disabled={disable}
            thousandSeparator={true}
            value={e.target.value || ""} 
          onChange={update_order_weitght.bind(this)}
        />
        </div>
        </div>
        
   
      );
    setView_order(temp1);
  
  }


 /*  async function update_order_length (e) {

   // console.log(e.target.value); 
    var templen = view_order;

    templen[e.target.name].getlength =  Number(e.target.value.replace(/,/g, "")); 
    //console.log(rowsData[e.target.name].getlength);
    rowsData[e.target.name].getlength =  (

      <div className="col-md-1.5">
        <div className="form-group ">
      
        <NumberFormat
          type = "text"
          style={{width: "100px"}}
          className="form-control"
          required
            allowNegative ={false}
           
            name={e.target.name}
            disabled={disable}
            thousandSeparator={true}
            value={e.target.value || ""} 
          onChange={update_order_length.bind(this)}
        />
        
        </div>
        </div>
   
      );
    setView_order(templen);
  
  } */


  async function update_order_dd_start (e) {

    //console.log(e.target.value); 
    var tempdd_start = view_order;

    tempdd_start[e.target.name].dd_start =  (e.target.value); 
    //console.log(rowsData[e.target.name].dd_start);
    rowsData[e.target.name].dd_start =  (
      <div className="col-md-1.5">
        <div className="form-group ">
      
        <NumberFormat
        format="##/##/####" 
        placeholder="DD/MM/YYYY" 
        mask={['D','D', 'M', 'M', 'Y','Y','Y','Y']} 
          type = "text"
          style={{width: "110px"}}
          className="form-control"
          required
            allowNegative ={false}
           
            name={e.target.name}
            disabled={disable}
            thousandSeparator={true}
            value={tempdd_start[e.target.name].dd_start || ""} 
          onChange={update_order_dd_start.bind(this)}
        />
        </div>
        </div>
        
   
      );
    setView_order(tempdd_start);
  
  }


 /*  async function update_order_dd_off_line (e) {

    console.log(e.target.value); 
    var temp2 = view_order;

    temp2[e.target.name].dd_off_line =  (e.target.value); 
    rowsData[e.target.name].dd_off_line =  (
      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat
        format="##/##/####" 
        placeholder="DD/MM/YYYY" 
        mask={['D','D', 'M', 'M', 'Y','Y','Y','Y']}  
          type = "text"
          style={{width: "110px"}}
          className="form-control"
          required
            allowNegative ={false}
           
            name={e.target.name}
            disabled={disable}
            thousandSeparator={true}
            value={e.target.value || ""} 
          onChange={update_order_dd_off_line.bind(this)}
        />
        
        </div>
        </div>
   
      );
    setView_order(temp2);
  
  } */



   /* async function update_order_rrr(e) {

    view_order[e.target.name].rrr = e.target.value;
  
  } */

 /*  async function update_order_length(e, data) {


    view_order[e.target.name].length = e.target.value;
    
  
  } */

  /* async function update_order_weitght(e, data) {
    
    
    view_order[e.target.name].weitght = e.target.value;
    
  
  } */

 /*  async function update_order_dd_start(e,date) {
  
    view_order[e.target.name].dd_start = e.target.value;
    
    
  
  } */

  const [getProductheader, setGetProductheader] = useState({
    pbn_no: "",
    cus_route: "",
    //date_off_line: "",
    //date_loading: "",
    po_no: "",
    osl: "",
  });


  function save_product_detail() {
    // console.log(approve_list_test);
   
       /* const data_update = [];
       for (let index = 0; index < getProductheader.length; index++) {
         const element = {};
         element["pbn_no"] = getProductheader[index].pbn_no;
         element["cus_route"] = getProductheader[index].cus_route;
         element["po_no"] = getProductheader[index].po_no;
         element["date_off_line"] = getProductheader[index].date_off_line;
         element["date_loading"] = getProductheader[index].date_loading;
         element["osl"] = getProductheader[index].osl;

         data_update.push(element);

       } */
       const data_header = {
         pbn_no: getProductheader.pbn_no,
         cus_route:getProductheader.cus_route,
         po_no:getProductheader.po_no,
         //date_off_line:getProductheader.date_off_line,
         //date_loading:getProductheader.date_loading,
         osl:getProductheader.osl,
      };
       const data_body = [];
       for (let index = 0; index < view_order.length; index++) {
         const element = {};
    
         element["id"] = view_order[index].id;
         element["no"] = view_order[index].no;
         element["name"] = view_order[index].name;
         element["year"] = view_order[index].year;
         element["month"] = view_order[index].month;
         element["order"] = view_order[index].order;
         //element["delivery_date"] = view_order[index].delivery_date;
         element["mat_size"] = view_order[index].mat_size;
         element["c_leng"] = view_order[index].c_leng;
         element["mat_lot"] = view_order[index].mat_lot;
         element["getlength"] = view_order[index].getlength;
         element["mat_spec"] = view_order[index].mat_spec;
         element["use_pcs"] = view_order[index].use_pcs;
         element["weitght"] = view_order[index].weitght;
         element["qty_cut"] = view_order[index].qty_cut;
         //element["dd_start"] = view_order[index].dd_start;
         //element["dd_off_line"] = view_order[index].dd_off_line;

 
         data_body.push(element);
       }
       var temp ={
         order_id:view_order_no.order_id,
         header:data_header,
         /* pbn_no:getProductheader.pbn_no,
         cus_route:getProductheader.cus_route,
         po_no:getProductheader.po_no,
         date_off_line:getProductheader.date_off_line,
         date_loading:getProductheader.pbn_no,
         header:getProductheader.date_loading,
         osl:getProductheader.osl, */

         body:data_body
       }
       console.log(view_order);
       console.log(data_header);
       console.log(temp);
       axios({
         method: "post",
         url: Configs.API_URL + "/productPlaning/orderProduction/updateListProduct",
         headers: {
           Authorization: getToken(),
           "Content-Type": "application/json",
           "X-TTT": Configs.API_TTT,
         },
         data: temp,
       })
         .then(function (response) {
           console.log(response.data);
           
           Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 300,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then((result) => {
            Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,})

              .then((result) => {
                /* window.location.href =
                  "/Production_Planing/Order_Production"; */
              });
            })
            
          
             
          /*  let temp1 = response.data.filter(x => x.status = true )
           let temp2 = temp1.filter(x => x.order > 0 ) 
           setView_order(temp2);
           Swal.fire(
             "Upload Success",
             "Your file has been Upload.",
             "success"
           ).then(() => {
             toggle_modal();
           }); */
         })
         .catch(function (error) {
 
           console.log(error);
           Swal.fire(
             "Warning",
             "Year and Month not found!!!",
             "warning"
           ); 
        
         });
       
     
   }

  function cancle_order(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancle order it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/productPlaning/orderProduction/cancle?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Cancle!",
                "Your order has been cancle.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Production_Planing/Order_Production";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }



  const GetProduct_order = async () => {
    
    console.log(filler_order_product);
    axios({
      method: "post",
      url: Configs.API_URL + "/productPlaning/orderProduction/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_order_product
    })
      .then(function (response) {
        console.log(response.data);
        setOrder_product(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  


  useEffect(() => {

    var feature_data = JSON.parse(getFeature()).filter((element)=>{ 
      return (element.menu === "Order Production");
    })
  
    var storage_feature = [];
    for(var i = 0; i < feature_data.length; i++){
      storage_feature.push(feature_data[i]);
    }
    
    setfeature(storage_feature);


    
    GetProduct_order();
   // GetView_order();
   

  }, []);


  





  const clearFilter = async () => {
    await setFiller_order_product({
      ...filler_order_product,
      order_no:"",
      start_date: "",
      end_date: "",

    });
    filler_order_product.order_no = "";
    filler_order_product.start_date =  "";
    filler_order_product.end_date =  "";
    GetProduct_order();
  };


 
 async function form(id,no,list_product,list_header,mode) {

  console.log(list_product);


    if(mode == "read"){
      await GetProduct_order();
    }
    if(mode == "read"){
      setdisable(true);
    }else{
      setdisable(false);
    }
    if(mode == "read"){
      setdisable2(true);
    }else{
      setdisable2(false);
    }
    if(getProductheader.pbn_no!=""){
      getProductheader.pbn_no = "";
      getProductheader.cus_route = "";
      //getProductheader.date_off_line = "";
      //getProductheader.date_loading = "";
      getProductheader.po_no = "";
      getProductheader.osl = "";
    }

    if(getProductheader.cus_route!=""){
      getProductheader.pbn_no = "";
      getProductheader.cus_route = "";
      getProductheader.po_no = "";
      getProductheader.osl = "";
    }
    if(getProductheader.po_no!=""){
      getProductheader.pbn_no = "";
      getProductheader.cus_route = "";
      getProductheader.po_no = "";
      getProductheader.osl = "";
    }
    if(getProductheader.osl != ""){
      getProductheader.pbn_no = "";
      getProductheader.cus_route = "";
      getProductheader.po_no = "";
      getProductheader.osl = "";
    }

    if(list_header!=null){
    
    setGetProductheader({
      ...getProductheader,
      pbn_no:list_header.pbn_no,
      cus_route:list_header.cus_route,
      //date_off_line:list_header.date_off_line,
      //date_loading:list_header.date_loading,
      po_no:list_header.po_no,
      osl:list_header.osl,
      

    });
  }


  setView_order_no({...view_order_no,order_no:no,order_id:id});
    setorder_index(order_index);
 
    setView_order(list_product);
    setFiller_view_order({ ...filler_view_order, product_order_id:id});  
  }
 


   /*  setView_order_no({...view_order_no,order_no:no,order_id:id});
    setorder_index(order_index);
    setView_order(list_product);
  
    setFiller_view_order({ ...filler_view_order, product_order_id:id});  
  } */

  const CLose = async () => {
    GetProduct_order();
   
  }
  var textRmatno = "";
  const rowsData = [];
  console.log(view_order);
  for(var index = 0; index < view_order.length; index++) {
    console.log(view_order[index].mat_lot);
  }
 
  for (var index = 0; index < view_order.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["product_no"] = view_order[index].no;
    rowItem["product_name"] = view_order[index].name;
    rowItem["month"] = view_order[index].month;
    rowItem["year"] = view_order[index].year;
    rowItem["order"] = view_order[index].order;
    rowItem["rm"] = view_order[index].mat_size ;
    //rowItem["cut"] = view_order[index].c_leng;
    rowItem["spec"] = view_order[index].mat_spec;
    rowItem["use_pcs"] = view_order[index].use_pcs = Math.ceil(view_order[index].steel_bar) || 0;
    rowItem["qty_cut"] = view_order[index].qty_cut = view_order[index].order;
   // rowItem["use_pcs"] = view_order[index].use_pcs = ((view_order[index].c_leng*view_order[index].order)/6000).toFixed(2);
    //rowItem["qty_cut"] = view_order[index].qty_cut = ((view_order[index].use_pcs*6000)/view_order[index].c_leng).toFixed(2);
    /* for (var mln of view_order[index].mat_lot.mat_lot){
      textRmatno += mln+",";
             
    } */
    console.log(view_order[index].mat_lot);
    
    if(view_order[index].mat_lot.length > 0){
      var mat_lot ="";
      for(let i2 = 0; i2 < view_order[index].mat_lot.length; i2++){
        if( i2 < view_order[index].mat_lot.length-1){
          mat_lot += view_order[index].mat_lot[i2].mat_lot+",";
        }else if(i2+1 === view_order[index].mat_lot.length){
          mat_lot += view_order[index].mat_lot[i2].mat_lot;
        }
      

      }
    }else{
      mat_lot = "";
    }

    console.log(mat_lot);
    
    rowItem["rrr"] =  mat_lot;
    
    /* rowItem["rrr"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat
          format="####-##/#" 
          placeholder="0000-00/0" 
          //mask={['D','D', 'M', 'M', 'Y','Y','Y','Y']} 
          type = "text"
          style={{width: "100px"}}
          className="form-control"
          required
          allowNegative ={false}
          id={view_order[index]}
          name={index}
          disabled={disable}
          thousandSeparator={true}
          value={view_order[index].rrr || ""}
          onChange={update_order_rrr.bind(this)}       
        />
        
        </div>
        </div>
    ); */
    rowItem["getlength"]  = view_order[index].c_leng;

     /*  <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat 
          type = "text"
          style={{width: "100px"}}
          className="form-control"
          required
          allowNegative ={false}
          name={index}
          disabled={disable}
          thousandSeparator={true}
          value={view_order[index].getlength || ""}
          onChange={update_order_length.bind(this)}
        />
        </div>
        </div>
    ); */


   /*  rowItem["weitght"] = (

      <div className="col-md-1.5">
        <div className="form-group ">
        <NumberFormat
          
          type = "text"
          style={{width: "100px"}}
          className="form-control"
          required
          allowNegative ={false}
          name={index}
          disabled={disable}
          thousandSeparator={true}
          value={view_order[index].weitght || ""}
          onChange={update_order_weitght.bind(this)}
        />
        </div>
        </div>
    ); */
    
    


    rowsData.push(rowItem);
  }

  const datanormal = {

    columns: [
      {
        label: "Item",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qty Target",
        field: "order",
        sort: "asc",
        width: 50,
      },
      
      /* {
        label: "RM",
        field: "rm",
        sort: "asc",
        width: 50,
      },
      {
        label: "CUT",
        field: "cut",
        sort: "asc",
        width: 50,
      },
      {
        label: "RRR",
        field: "rrr",
        sort: "asc",
        width: 50,
      },
      {
        label: "Length",
        field: "getlength",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "USE/PCS",
        field: "use_pcs",
        sort: "asc",
        width: 50,
      },
      {
        label: "Weitght",
        field: "weitght",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qty Cut",
        field: "qty_cut",
        sort: "asc",
        width: 50,
      }, */
      
    ],
    rows: rowsData,
  };

  const data = {

    columns: [
      {
        label: "Item",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qty Target",
        field: "order",
        sort: "asc",
        width: 50,
      },
      {
        label: "RRR No.",
        field: "rrr",
        sort: "asc",
        width: 50,
      },

      {
        label: "Rm Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },

      {
        label: "Rm Size",
        field: "rm",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "CUT",
        field: "cut",
        sort: "asc",
        width: 50,
      }, */
      
      {
        label: "Cut Length",
        field: "getlength",
        sort: "asc",
        width: 50,
      },
      
      {
        label: "RM(pcs.)",
        field: "use_pcs",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "Weitght",
        field: "weitght",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Act Cut(pcs.)",
        field: "qty_cut",
        sort: "asc",
        width: 50,
      },
      
    ],
    rows: rowsData,
  };

  

 
  
  const rowsData_order = [];
 
  
 
  
  for (var index = 0; index < order_product.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["order_no"] = order_product[index].order_no;
  /*   if(order_product[index].is_active == true){
    rowItem["status"] = "OK";
    }else{
    rowItem["status"] = "CANCLE";
    } */
    rowItem["date"] = moment(order_product[index].create_date).format('DD/MM/yyyy ::: HH:mm')+" น.";
   
    
    rowItem["mgt"] = (
      <div className="row">

    {feature.map((e) =>{
      if(e.feature === "PBN Report"){
        return (
        <div className="col-4">
          <a 
          data-toggle="modal"
          data-target="#modal-xl"
            onClick={form.bind(this, 
              order_product[index].id,
              order_product[index].order_no, 
              order_product[index].list_product,
              order_product[index].list_header,
              "edit "
              )}
            className=" btn btn-xs "
          >
            <i class="far fa-calendar-plus"></i>{" "}
          </a>
        </div>
        );
      }
    })}


        <div className="col-4">
          <a 
          data-toggle="modal"
          data-target="#modal-xl"
            onClick={form.bind(this, 
              order_product[index].id,
              order_product[index].order_no, 
              order_product[index].list_product,
              order_product[index].list_header,
              "read"
             
              )}
            className=" btn btn-xs "
          >

            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        
        <div className="col-4">
        <a
            key={order_product[index].id}
            className=" btn btn-xs "
            onClick={cancle_order.bind(this, order_product[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData_order.push(rowItem);
  }
  const data_order = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Order No",
        field: "order_no",
        sort: "asc",
        width: 50,
      },
     /*  {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mgt",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      
   
  
    ],
    rows: rowsData_order,
  };


  async function SetDate_start(date){
    setFiller_order_product({ ...filler_order_product, start_date: date})

   }
    async function SetDate_end(date){
      setFiller_order_product({ ...filler_order_product, end_date: date})

   }

   function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function Pbn_Report(){



  const doc = new jsPDF("l", "mm", "a4");
  for (let index = 0; index < view_order.length; index = index + 22) {
  doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
  doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
  doc.setFont("THSarabunNew");
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(14);
  doc.text(
      7,
      12,
      "ใบคุมล๊อตการผลิต PBN No."
  );
  doc.text(
    7,
    20,
    "งานเสร็จสิ้นภายในวันที่ (Date Off Line)");
 doc.text(
      7,
      27,
      "รายการที่ต้องจัดส่ง มีดังนี้");

 doc.rect(75, 8, 30, 7);
 doc.setFontSize(14);
 doc.setTextColor(0, 0, 0);
 doc.text(90,13, getProductheader.pbn_no,"center");

 doc.rect(75, 15, 30, 7);
 /* doc.setFontSize(14);
 doc.setTextColor(0, 0, 0);
 doc.text(90,20, getProductheader.date_off_line, "center"); */

 doc.text(
  106,
  12,
  "ลูกค้า-เส้นทาง(Route)");
  doc.text(
    106,
    20,
    "งวดโหลดสินค้าวันที่ (Date Loading)");


doc.rect(159, 8, 30, 7);
doc.setFontSize(14);
doc.setTextColor(0, 0, 0);
doc.text(174,13, getProductheader.cus_route,"center");

doc.rect(175, 22, 31, 7);
doc.text(159,27, "PO.NO.");
doc.text(190.5,27, getProductheader.po_no ,"center");


doc.text(230,27, "OSL");
doc.text(240,27, getProductheader.osl);
doc.text(
  247,
  15,
  "Date Issued");

  doc.text(268,15, moment(new Date()).format('DD/MM/yyyy').toString());

doc.rect(175, 15, 31, 7);
/* doc.setFontSize(14);
doc.setTextColor(0, 0, 0);
doc.text(190.5,20, getProductheader.date_loading); */

      doc.rect(7, 29, 11, 6); //item
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(10, 33, "Item");

      doc.rect(18, 29, 23, 6); //Part No.
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(24, 33, "Part No.");

      doc.rect(41, 29, 41, 6); //Part NAME.
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(54, 33, "Part Name");

      doc.rect(82,  29, 18, 6); //QTY.
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(88, 33, "QTY");

      doc.rect(100,  29, 26, 6); //RM
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(111,33, "RM");


      doc.rect(126, 29, 18, 6); //CUT
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(132, 33, "CUT");


      doc.rect(144,  29, 20, 6); //RRR
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(152,33, "RRR");
      
      doc.rect(164,  29, 19, 6); //Length
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(169,33, "Length");
      
      doc.rect(183, 29, 15, 6); //Spec
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(188,33, "Spec");
      
      doc.rect(198, 29, 15, 6); //use/pcs
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(201,33, "use/pcs");
      
      doc.rect(213, 29, 15, 6); //weight
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(216, 33, "weight");
      
      doc.rect(228, 29, 19 , 6); //Qty Cut
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(233, 33, "Qty Cut");
      
      doc.rect(247, 29, 20, 6); //dd. Start
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(251,33, "dd. Start");

      doc.rect(267, 29, 25, 6); //dd Off-Line.
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(272,33, "dd Off-Line");

      
      var row = 0;
      var sum_amount = 0;
      for (let i = 0; row < 22 ; i = i + 6) {
          doc.rect(7, 27 + i + 8, 11, 6); //item
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(12);
          doc.text(12, 31+i+8, (row + 1 + index).toString());

          var product_no;
          var product_name;
          var amount;
          var rm;
          var c_leng;
          var rrr;
          var getlength;
          var spec;
          var use_pcs;
          var weitght;
          var qty_cut;
          //var dd_start;
          //var dd_off_line;

          if (!view_order[row + index]) {

          
            product_no = "";
            product_name = "";
            amount = 0;
            rm = "";
            c_leng = "";
            rrr = "";
            getlength = "";
            spec = "";
            use_pcs = "";
            weitght = "";
            qty_cut = "";
            //dd_start = "";
            //dd_off_line = "";
         
        } else {

         
            product_no = view_order[row + index].no;
            product_name = view_order[row + index].name;
            amount = view_order[row + index].order;
            if(view_order[row + index].mat_size !== null && view_order[row + index].mat_size){
              rm = view_order[row + index].mat_size;
            }else{
              rm = "";
            }

            if(view_order[row + index].c_leng !== null && view_order[row + index].c_leng){
              c_leng = view_order[row + index].c_leng;
            }else{
              c_leng = "";
            }
            if(view_order[row + index].rrr !== null && view_order[row + index].rrr){
              rrr = view_order[row + index].rrr;
            }else{
              rrr = "";
            }
            if(view_order[row + index].getlength !== null && view_order[row + index].getlength){
              getlength = view_order[row + index].getlength;
            }else{
              getlength = "";
            }
            if(view_order[row + index].mat_spec !== null && view_order[row + index].mat_spec){
              spec = view_order[row + index].mat_spec;
            }else{
              spec = "";
            }
            if(view_order[row + index].use_pcs !== null && view_order[row + index].use_pcs){
              use_pcs = view_order[row + index].use_pcs;
            }else{
              use_pcs = "";
            }
            if(view_order[row + index].weitght !== null && view_order[row + index].weitght){
              weitght = view_order[row + index].weitght;
            }else{
              weitght = "";
            }
            if(view_order[row + index].qty_cut !== null && view_order[row + index].qty_cut){
              if(c_leng < 1){
                qty_cut = 0;
              }else{
                qty_cut = view_order[row + index].qty_cut;
              }
          
            }else{
              qty_cut = "";
            }
            /* if(view_order[row + index].dd_start !== null && view_order[row + index].dd_start){
              dd_start = view_order[row + index].dd_start;
            }else{
              dd_start = "";
            }
            if(view_order[row + index].dd_off_line !== null && view_order[row + index].dd_off_line){
              dd_off_line = view_order[row + index].dd_off_line;
            }else{
              dd_off_line = "";
            } */
            
            
 
        }
        sum_amount = parseInt(sum_amount) + parseInt(amount);


          doc.rect(18, 27 + i + 8, 23, 6); //Part No.

          doc.setTextColor(0, 0, 0);
          doc.setFontSize(12);
          doc.text(19, 31+i+8, product_no,"left");
  
          doc.rect(41, 27 + i + 8, 41, 6); //Part NAME.

          doc.setTextColor(0, 0, 0);
          doc.setFontSize(12);
          doc.text(43, 31+i+8, product_name,"left");
         

          var amount_str;
          if (amount == 0) {
              console.log("0 : ", row)
              amount_str = "";
          } else {
              amount_str = amount;
          }

          doc.rect(82, 27 + i + 8, 18, 6); //QTY.
          doc.setTextColor(0, 0, 0);
          doc.setFontSize(12);
          doc.text(99, 31+i+8,numberWithCommas(amount_str).toString(),"right");
          // var amount_str;
          // if (amount == 0) {
          //     console.log("0 : ", row)
          //     amount_str = "";
          // } else {
          //     amount_str = amount;
          // }
          // doc.setTextColor(0, 0, 0)
          // doc.setFontSize(15)
          // doc.text(81, 62 + i, numberWithCommas(amount_str).toString())



          doc.rect(100, 27 + i + 8, 26, 6); //RM.
          doc.text(113, 31+i+8, rm,"center");
          
          doc.rect(126, 27 + i + 8, 18, 6); //CUT
          doc.text(143, 31+i+8, c_leng.toString(),"right");

          doc.rect(144,  27+ i +8, 20, 6); //RRR
          doc.text(154, 31+i+8, rrr,"center");

          doc.rect(164,  27+ i + 8, 19, 6);//length
          doc.text(182, 31+i+8, getlength.toString(),"right");

          doc.rect(183, 27 + i + 8, 15, 6); //Spec
          doc.text(191, 31+i+8, spec,"center");

          doc.rect(198, 27 + i + 8, 15, 6); //use/pcs
          doc.text(212, 31+i+8, use_pcs.toString(),"right");

          doc.rect(213, 27 + i + 8, 15, 6); //weight
          doc.text(227, 31+i+8, numberWithCommas(weitght).toString(),"right");

          doc.rect(228, 27 + i + 8, 19 , 6); //Qty Cut
          doc.text(246, 31+i+8, numberWithCommas(qty_cut).toString(),"right");

          doc.rect(247, 27 + i + 8, 20, 6); //dd. Start
          //doc.text(257, 31+i+8, dd_start,"center");

          doc.rect(267, 27 + i + 8, 25, 6); //dd Off-Line.
          //doc.text(280, 31+i+8, dd_off_line,"center");
          //doc.text(272, 31+i+8, "21/01/2022");
          // if (row == 9) {
          //     doc.setTextColor(0, 0, 0);
          //     doc.setFontSize(15); //total
          //     doc.text(55, 53 + i + 16, "Total >>>");
          //     /*  doc.setTextColor(0, 0, 0)
          //      doc.setFontSize(15) */
          //     doc.text(81, 53 + i + 16, numberWithCommas(sum_amount).toString());
          // } //end if
          if (row == 21) {
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(12); //total
            doc.text(80, 171, "Total >>>","right");
            doc.text(99, 171, numberWithCommas(sum_amount).toString(),"right"); 

            doc.rect(7, 167, 11, 6); //item
            doc.rect(18, 167, 64, 6); //Part No.
       
          doc.rect(82, 167, 18, 6); //QTY.
            doc.rect(100, 167, 192, 6); //RM.
            /* doc.rect(126, 167, 18, 6); //CUT
            doc.rect(144,  167, 20, 6); //RRR
            doc.rect(164,  167, 19, 6);//length
            doc.rect(183, 167, 15, 6); //Spec
            doc.rect(198, 167, 15, 6); //use/pcs
            doc.rect(213,167, 15, 6); //weight
            doc.rect(228, 167, 19 , 6); //Qty Cut
            doc.rect(247,167, 20, 6); //dd. Start
            doc.rect(267,167, 25, 6); //dd Off-Line. */
        


        }



        

          row = row + 1;
          // console.log(row)
          //  } //end for loop
          //  }

      }
      

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12); 
      doc.text(29, 185, "งานวางเเผน");
      doc.text(141, 185, "งานการผลิต");
      doc.text(250, 185, "งานควบคุมคุณภาพ");
      doc.text(18, 180, "________________________");
      doc.text(130, 180, "________________________");
      doc.text(242, 180, "________________________");
      
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.text(20, 192, "Date …..…./ …....…. /……….");

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.text(132, 192, "Date …..…./ …....…. /……….");  

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(13);
      doc.text(244, 192, "Date …..…./ …....…. /……….");

      doc.setFontSize(15);
      doc.text(80, 200, "Original Doc.");
      doc.setTextColor(0, 0, 0);
      doc.rect(70, 195, 40, 7);

      doc.setFontSize(12);
      doc.text(285, 200, "PAN");
        
      doc.setFontSize(15);
      doc.text(194, 200, "Control Doc.");
      doc.setTextColor(0, 0, 0);
      doc.rect(184, 195, 40, 7);
      doc.addPage("l");
    
    }
  window.open(doc.output("bloburl"));

}
  
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="pp" activemenu="order" submenu="none" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Order Production{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Order Production 
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                <input
                    type="text"
                    className="form-control"
                    value={filler_order_product.order_no.trim()}
                    onChange={(e) => {
                      setFiller_order_product({ ...filler_order_product, order_no: e.target.value.trim() });
                    }} 
                    required
                  />
                  <label htmlFor="">Order No.</label>
                  </div>
                  </div>
                 
             
           
            <div className="col-md-0.5">
        <label > Date &nbsp;</label>
        </div>
        
        <div className="col-md-1.5">
        <div className="form-group ">
 
        <DatePicker selected = {filler_order_product.start_date}
        dateFormat = {"dd-MM-yyyy"}
        onChange = {
          async(date) =>
          SetDate_start(date)   
          }
        selectsStart startDate = {filler_order_product.start_date}
        endDate = {filler_order_product.end_date}
        customInput = {<CustomInput/>}
        
        /> 
        </div>
        </div>

        <div className="col-md-0.5">
        <label>&nbsp;&nbsp;  Between  &nbsp;&nbsp;</label> 
        </div> 
        <div className="col-md-1.5">
        <div className="form-group ">
        <DatePicker 
        selected = {filler_order_product.end_date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>
          SetDate_end(date)   
          }
        selectsEnd startDate = { filler_order_product.start_date }
        endDate = { filler_order_product.end_date }
        minDate = { filler_order_product.start_date }
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
        </div>
        </div>
              
      
                  <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      GetProduct_order();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>

            </div>
            {" "}
            
          </div>
          
         
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Order Production</h3>
             
             
             
            
            </div>

            <div className="card-body ">
              <div
                className="table-responsive" /* style={{ height: "500px" }} */
              >
              
                <MDBDataTable
                  //className="table table-head-fixed"
                  striped
                  sortable = {false}
                  id="table-to-xls"
                  bordered
                  hover
                  //fixedHeader
                  data={data_order}
                />

    {/*       <div style={{display:"none"}} >

              <MDBDataTable    
                  className="table table-head-fixed" 
                  striped
                  sortable = {false}
                  entries={4096}
                  id="table-to-xls_all"
                  bordered
                  hover
                  fixedHeader
                  data={data}
                
                />
                </div> */}
        

              </div>
            </div>
            <div className="card-footer">
              {/* <Button ref={modal_click} color="info" onClick={toggle_modal}>111111111</Button> */}
            </div>
          </div>
        </section>
      
      
      </div>
   



      <div className="modal fade" id="modal-xl">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
            
              <h1 className="modal-title">
              {feature.map((e) =>{
   
              if(e.feature === "EditOrder"){
                return (
                      pageMode
                );
                      }
                  })}
                Order No : {view_order_no.order_no}

              {feature.map((e) =>{
                if(e.feature === "PBN Report"){
                return (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={()=>{save_product_detail();Pbn_Report();}}  
                    disabled={disable2}      
                  >
                    Save and Dowload PBN Report
                  </button>                  
                        );
                }
              })}
              
              </h1>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={CLose}
              >
                <span aria-hidden="true">×</span>
              </button>
              
            </div>

            
           



            {feature.map((e) =>{
              if(e.feature === "PBN Report"){
                return (
            <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.pbn_no || ""}
                      
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          pbn_no: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">PBN_NO</label>{" "}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.cus_route}
                     
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          cus_route: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">Cus_Route</label>{" "}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.po_no}
                      
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          po_no: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">PO.NO</label>{" "}
                </div>
              </div>
            </div>
          </div>
            );
            }
            })}


          {feature.map((e) =>{
              if(e.feature === "PBN Report"){
                return (
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-4">
                <div className="form-group ">
                <input
                  type = "text"
                  className="form-control"
                  required
                  disabled={disable}
                  allowNegative ={false}
                  name={index}
                  thousandSeparator={true}
                  value={getProductheader.osl}
                      
                      onChange={(e) => {
                        setGetProductheader({
                          ...getProductheader,
                          osl: e.target.value,
                        });
                      }}
                />
                  <label htmlFor="">OSL</label>
                </div>
              </div>
            </div>
          </div>
          );
          }
          })}







        
        


            <div className="modal-body"  /* onClick={CLose} */>
              <div className="row">
                <div className="col-2"></div>
              </div>
              <div className="row">
              <div
                className="table-responsive" style={{ height: "500px"}} 
              >
                {show === 0 ? (
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  id="table-to-xls"
                  bordered
                  hover
                  fixedHeader
                  data={data}
        
                />
                ) : (
                  <>
                  <MDBDataTable
                  sortable = {false}
                  className="table table-head-fixed"
                  striped
                  id="table-to-xls"
                  bordered
                  hover
                  fixedHeader
                  data={datanormal}
                />
                  </>
                  )}
                </div>
                
                <div className="col-4"></div>
              </div>
              
            </div>

            


            <div className="modal-footer justify-content-center">
              {disable === true ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={CLose}
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
              ) : (
                <>
                <button
                type="button"
                className="btn btn-danger"
                onClick={CLose}
                data-dismiss="modal"
                aria-label="Close"
              >
                Close
              </button>
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={save_product_detail}
                data-dismiss="modal"
                aria-label="Close"
                disabled={true} 
              >
                Save Changes
              </button> */}
              </>
              )}
            </div>

          </div>
        </div>
      </div>


      

     {/*  <Footter /> */}
    </div>
  );
}
export default Product_Approve;
/* Approve Production Item */
