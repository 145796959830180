import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import LOGH from "../../component/log_detailHistory_feature";
import INF from "../../component/input_feature";
import Select from "react-select";
import { tag_point_cts } from "../../component/Report";

function WipShupOutInCTS_mgt(mode) {
  const { product_id } = useParams();
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);

  const [product, setproduct] = useState({
    product_no: "",
    product_name: "",
    product_amount: "",
    plating_plant_id: "",
    product_supplier_name: "",
    nw: "",
  });

  const [product_process, setproduct_process] = useState({
    edit_type: true,
    loss_amount: "",
    /* coil_no: "",
    invoice_no: "", */
    /*  coil_no_target: "", */
    /* invoice_no_target: "", */
    plating_plant_id: "",
    amount: "",
    /* weight_tag: "", */
    /* weight_actual: "", */
    /* grade: "", */
    unit: "weight",
    lotno: "",
    prod_lot_id: "",
    prod_tacking: "",
    estimate_date: new Date(),
    product_order_id: "",
    order_po_number: "",
    out_amount: "",
  });

  const [lotno, setlotno] = useState([]);
  const select_location_model = useRef();
  const [supplier_list, setsupplier_list] = useState([]);
  const select_supplier_model = useRef();
  const [lot_in_stock_list, setlot_in_stock_list] = useState([]);
  const [lot_history_list, setlot_history_list] = useState([]);

  const [coil_no_list, setcoil_no_list] = useState([]);
  const [coil_no_gen, setcoil_no_gen] = useState();
  const [coil_no_genDefault, setcoil_no_genDefault] = useState();
  const [isGen, setisGen] = useState(true);

  const [test, settest] = useState({
    test: "",
  });
  const [po_order, setpo_order] = useState({
    number_po: "",
  });

  const [unitAmount, setunitAmount] = useState("weight");

  const [checkunitAmount, setcheckunitAmount] = useState("weight");

  const getProductView = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipshupOutInCTS/getProductBy?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log("ดาต้าวิวโปรดัก", response.data);
        setproduct({
          ...product,
          product_no: response.data.product_no,
          product_name: response.data.product_name,
          product_amount: response.data.material_amount,
          plating_plant_id: response.data.plating_plant_id,
          product_supplier_name: response.data.material_supplier_name,
          nw: response.data.nw,
        });
        product.plating_plant_id = response.data.plating_plant_id;

        product_process.plating_plant_id = response.data.plating_plant_id;
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPlantShup = async () => {
    /* โรงชุบ */
   /*  let tmp = {
      company_id: getUser().com,
    }; */
    axios({
      method: "get",
      url: Configs.API_URL + "/api/wipshupOutInCTS/getMasterplating_plantByProductId?id="+product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
/*       data: tmp, */
    })
      .then(function (response) {
        /*      setlotno(response.data); */
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;

          options.push(element);
        });
        setsupplier_list(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function getPoNumber(data) {
    console.log("po", data);
    let dateExt = parseInt(moment(new Date()).format("YYYY")) + 543;
    let mount = moment(new Date()).format("MMDD");
    let datefinal =
      "PO" +
      dateExt.toString().slice(2) +
      mount; /* + "-" + moment(new Date()).format('MMDD') */
    let final = {
      dateOfday: datefinal,
      id_plating: data || "",
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/wipshupOutInCTS/genOrderChupIN",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: final,
    })
      .then(function (response) {
        console.log("lotNOMM", response.data);
        setpo_order({ ...po_order, number_po: response.data });
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  const getLotno = async () => {
    let tmp = {
      company_id: getUser().com,
      product_id: product_id,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/wipshupOutInCTS/getLotNo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp,
    })
      .then(function (response) {
        /*      setlotno(response.data); */
        const options = [];
        console.log("lotNo", response.data);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;
          /* element["tacking"] = el.tracking_no;
          element["productorder"] = el.product_order_cts_id; */
          options.push(element);
        });
        setlotno(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotInStock = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipshupOutInCTS/getLotInStock?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //var sort_data_ = response.data.sort((a,b)=> moment(b.material_qc_date).format('YYYYMMDDHHMM') - moment(a.material_qc_date).format('YYYYMMDDHHMM'));
        var sort_data = response.data.sort(
          (a, b) => a.material_qc_status - b.material_qc_status
        );

        setlot_in_stock_list(response.data);
        /* setlot_in_stock_list(sort_data); */
        console.log("อะโกด้า", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotHistory = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipshupOutInCTS/GetLotWipChupHistory?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setlot_history_list(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getPoNumber();
    getProductView();
    getLotno();
    getPlantShup();
    getLotInStock();
    getLotHistory();
    if (product_process.edit_type === true) {
      setproduct_process({ ...product_process, unit: "weight" });
    }
  }, []);

  const row_data_lot_in_stock = [];
  for (let index = 0; index < lot_in_stock_list.length; index++) {
    const rowItem = {};
    rowItem["lot_no"] = lot_in_stock_list[index].product_lot_no;
    console.log(lot_in_stock_list[index].amountWgiht, lot_in_stock_list[index].amountPrice , lot_in_stock_list[index].nw);
    rowItem["AmountWgiht"] =
    (
      <div style={{textAlign:'right'}}>

        {lot_in_stock_list[index].unit === "กิโลกรัม" ? (
        <NumberFormat
          thousandSeparator={true}
          value={parseFloat(lot_in_stock_list[index].amountWgiht).toFixed(4)}
          displayType="text"
        />
      ) : (
        <NumberFormat
          thousandSeparator={true}
          value={(
            (parseFloat(lot_in_stock_list[index].amountPrice *
           lot_in_stock_list[index].nw))/1000
          ).toFixed(4)}
          displayType="text"
        />
      )


        }
      </div>
    )
      
    rowItem["AmountPrice"] =
    (
    <div style={{textAlign:'right'}}>
{
    lot_in_stock_list[index].unit === "ชิ้น" ? (
      <NumberFormat
        thousandSeparator={true}
        value={parseInt(lot_in_stock_list[index].amountPrice)}
        displayType="text"
      />
    ) : (
      <NumberFormat
        thousandSeparator={true}
        value={Math.floor(
          (
            (lot_in_stock_list[index].amountWgiht /
             lot_in_stock_list[index].nw) *
            1000
          )
        ).toFixed(0)}
        displayType="text"
      />
    )
}

    </div>
      )
    
    rowItem["unit"] = lot_in_stock_list[index].unit;
    rowItem["create_date"] = lot_in_stock_list[index].create_date
      ? moment(lot_in_stock_list[index].create_date).format("DD/MM/yyyy")
      : "";
    /*  rowItem["unit"] = lot_in_stock_list[index].unit; */
    rowItem["sub_plating_name"] = lot_in_stock_list[index].sub_plating_name;
    rowItem["phon_plant"] = (
      <>
        <a href={"tel:" + lot_in_stock_list[index].telnumber}>
          {lot_in_stock_list[index].telnumber}
        </a>
      </>
    );

    rowItem["po_no"] = lot_in_stock_list[index].po_no;
    rowItem["tracking_tag_wip_chup"] =
      lot_in_stock_list[index].tracking_tag_wip_chup;

    rowItem["estimate_date"] = lot_in_stock_list[index].estimate_date
      ? moment(lot_in_stock_list[index].estimate_date).format("DD/MM/YYYY")
      : "";

    /*    rowItem["track_report"] = (
      <>
      
        <button
        type="button"
  
        onClick={()=>{linkreport(product_id,lot_in_stock_list[index].tracking_tag_wip_chup);}}
        class="btn btn-block btn-warning "
      >
        Print
      </button>
      
      
      </>
    ); */

    var dis_selected = false;

    if (lot_in_stock_list[index].selected === true) {
      dis_selected = false;
      if (lot_in_stock_list[index].selected === false) {
        dis_selected = true;
      }
    }

    rowItem["mgt"] = (
      <>
        {lot_in_stock_list[index].selected === true ? (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedCoilNo(index);
            }}
            class="btn btn-block btn-info "
          >
            Selected
          </button>
        ) : (
          <button
            type="button"
            disabled={dis_selected}
            onClick={() => {
              SelectedCoilNo(index);
            }}
            class="btn btn-block btn-primary "
          >
            Select
          </button>
        )}
      </>
    );
    row_data_lot_in_stock.push(rowItem);
  }

  const table_lot_in_stock_list = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },

      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },

      {
        label: "วันที่ส่งชุบ",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่คาดรับชุบ",
        field: "estimate_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขที่ใบจ่ายงานชุบ",
        field: "po_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tracking No.",
        field: "tracking_tag_wip_chup",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน (กก.)",
        field: "AmountWgiht",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน (ชิ้น)",
        field: "AmountPrice",
        sort: "asc",
        width: 50,
      },

      {
        label: "หน่วย",
        field: "unit",
        sort: "asc",
        width: 50,
      },

      {
        label: "ชื่อย่อโรงชุบ",
        field: "sub_plating_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "phon_plant",
        sort: "asc",
        width: 50,
      },

      /* {
        label: "Print ป้ายบ่งชี้",
        field: "track_report",
        sort: "asc",
        width: 50,
      }, */
    ],
    rows: row_data_lot_in_stock,
  };

  if (product_process.edit_type === true) {
    var index = table_lot_in_stock_list.columns.findIndex((e) => {
      return e.field === "mgt";
    });
    table_lot_in_stock_list.columns.splice(index, 1);
  }

  const row_data_lot_history = [];
  for (let index = 0; index < lot_history_list.length; index++) {
    const rowItem = {};
    rowItem["lot_no"] = lot_history_list[index].product_lot_no;
    rowItem["Amount"] = 
    (<div style={{textAlign:'right'}}>
      {
lot_history_list[index].product_amount
? parseFloat(lot_history_list[index].product_amount).toFixed(2)
: 0
      }
    </div>)
    ;
    rowItem["create_date"] = lot_history_list[index].create_date
      ? moment(lot_history_list[index].create_date).format("DD/MM/yyyy")
      : "";
    rowItem["status"] =
      lot_history_list[index].edit_type === true ? "ส่งชุบ" : "รับชุบ";
    rowItem["unit"] = lot_history_list[index].unit;
    rowItem["estimate_date"] = lot_history_list[index].estimate_date
      ? moment(lot_history_list[index].estimate_date).format("DD/MM/YYYY")
      : "";
    rowItem["loss_amount"] = 
    (<div style={{textAlign:'right'}}>
      {
lot_history_list[index].loss_amount
? lot_history_list[index].loss_amount
: 0
      }
    </div> 
      )
    
      
      ;
    rowItem["name_sp_plant"] = lot_history_list[index].sub_plating_name;
    rowItem["po_no"] = lot_history_list[index].po_no;
    rowItem["tracking_tag_wip_chup"] =
      lot_history_list[index].tracking_tag_wip_chup;
    rowItem["telnumber"] = (
      <>
        <a href={"tel:" + lot_history_list[index].telnumber}>
          {lot_history_list[index].telnumber}
        </a>
      </>
    );

    row_data_lot_history.push(rowItem);
  }
  const table_lot_history_list = {
    columns: [
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },

      {
        label: "วันที่ส่งชุบ",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่คาดรับชุบ",
        field: "estimate_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขที่ใบจ่ายงานชุบ",
        field: "po_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tracking No.",
        field: "tracking_tag_wip_chup",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "Amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวนที่สูญหาย",
        field: "loss_amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "หน่วย",
        field: "unit",
        sort: "asc",
        width: 50,
      },

      {
        label: "ชื่อย่อโรงชุบ",
        field: "name_sp_plant",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "telnumber",
        sort: "asc",
        width: 50,
      },
    ],
    rows: row_data_lot_history,
  };

  const [approve_person, setapprove_person] = useState({
    product_lot_no: "",
    tracking_tag_wip_chup: "",
    qc_person: "",
  });

  /* function setQcTarget(product_lot_no, tracking_tag_wip_chup, e) {
    setapprove_person({ ...approve_person, product_lot_no: product_lot_no, tracking_tag_wip_chup: tracking_tag_wip_chup });
    approve_person.product_lot_no = product_lot_no;
    approve_person.tracking_tag_wip_chup = tracking_tag_wip_chup;
    saveQcApprove();
  } */

  /*  function saveQcApprove(e) {

    // Swal.fire({
    //   title: 'จำนวนที่สูญหาย',
    //   html: `<input type="text" id="login" class="swal2-input" placeholder="Username">`,
    //   /* confirmButtonText: 'Sign in',
    //   focusConfirm: false, */
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'Yes, confirm it!',
  //   preConfirm: () => {
  //     /* const login = Swal.getPopup().querySelector('#login').value
  //     const password = Swal.getPopup().querySelector('#password').value
  //     if (!login || !password) {
  //       Swal.showValidationMessage(`Please enter login and password`)
  //     }
  //     return { login: login, password: password } */
  //   }
  // }).then((result) => {
  //  /*  Swal.fire(`
  //     Login: ${result.value.login}
  //     Password: ${result.value.password}
  //   `.trim()) */
  // })

  //   Swal.fire({
  //     title: 'จำนวนที่สูญหาย',
  //     text: "You won't be able to revert this!",
  //     html: `<NumberFormat type="text" id="Amount_loss" class="swal2-input" / >`,
  //     // html: `<input type="text" id="Amount_loss" class="swal2-input" >`,
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, confirm it!'
  //   }).then((result) => {
  //     var err_list = [];
  //     if (result.isConfirmed) {
  //       if(approve_person.qc_person.trim() === ""){
  //         err_list.push("กรุณาใส่ชื่อผู้ตรวจ");
  //       }
  //       if(err_list.length > 0){
  //         var err_txt = "";
  //         for(let err of err_list){
  //           err_txt += err + "\n";
  //         }
  //         Swal.fire("ระบบ",err_txt,"error");
  //       }else{
  //         const login = Swal.getPopup().querySelector('#Amount_loss').value
  //         console.log("heere approve",login);
  //         let tmp_qc_approve = {
  //           oem_id: getOem(),
  //           company_id: getUser().com,
  //           product_lot_no: approve_person.product_lot_no,
  //           tracking_tag_wip_chup: approve_person.tracking_tag_wip_chup,
  //           product_id: product_id,
  //           qc_approve_person: approve_person.qc_person,
  //         }
  //         console.log(tmp_qc_approve)
  //         axios({
  //           method: "post",
  //           url: Configs.API_URL /* + "/api/wipshupOutInCTS/qcApproved" */,
  //           headers: {
  //             Authorization: getToken(),
  //             "X-TTT": Configs.API_TTT,
  //             "Content-Type": "application/json",
  //           },
  //           data: tmp_qc_approve,
  //         })
  //           .then(function (response) {
  //             //setlot_history_list(response.data);
  //             if (response.data) {
  //               Swal.fire({
  //                 icon: "success",
  //                 title: "Save",
  //                 showConfirmButton: false,
  //                 timer: 1500,
  //               }).then((result) => {
  //                 getLotInStock();
  //                 getLotHistory();

  //                 setapprove_person({ ...approve_person, product_lot_no: "", tracking_tag_wip_chup: ""/* , qc_person: ""  */});
  //                 approve_person.product_lot_no = "";
  //                 approve_person.tracking_tag_wip_chup = "";
  //               /*   approve_person.qc_person = ""; */
  //               });

  //             }

  //           })
  //           .catch(function (error) {
  //             console.log(error);
  //           });
  //       }

  //     }
  //   })

  // }

  function saveOption() {
    var coil_no = lot_in_stock_list.filter((e) => {
      return e.selected === true;
    });
    console.log(
      "เช็คอเมาส์",
      Number(product_process.out_amount.replace(/,/g, ""))
    );
    console.log("coi", coil_no);
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        var err_list = [];

        console.log("location", product_process);

        if (product_process.unit == "" && product_process.edit_type === true) {
          err_list.push("กรุณากรอก หน่วย.");
        }
        if (
          product_process.amount == "" &&
          product_process.edit_type === true
        ) {
          err_list.push("กรุณากรอก จำนวน.");
        }
        if (product_process.edit_type === true) {
          if (
            product_process.plating_plant_id == "" ||
            product_process.plating_plant_id == undefined
          ) {
            err_list.push("กรุณากรอก โรงชุบ.");
          }
        }
        let amount = 0;
        if (coil_no.length === 0 && product_process.edit_type === false) {
          err_list.push("กรุณาเลือก Lot No. ที่ต้องการ รับชุบ.");
        } else if (coil_no.length > 0) {
          if (
            parseInt(coil_no[0].product_amount) <
              Number(product_process.out_amount.replace(/,/g, "")) &&
            product_process.edit_type === false
          ) {
            amount = coil_no[0].product_amount;
            err_list.push("จำนวนคงเหลือไม่พอตัด เหลือ: ", amount);
          }
          if (
            Number(product_process.out_amount.replace(/,/g, "")) <
              Number(product_process.loss_amount.replace(/,/g, "")) &&
            product_process.edit_type === false
          ) {
            err_list.push("จำนวนสูญหายมากกว่าจำนวนที่จะตัด");
          }
          if (
            Number(product_process.out_amount.replace(/,/g, "")) <= 0 &&
            product_process.edit_type === false
          ) {
            err_list.push("กรุณากรอกจำนวนที่ต้องการจะตัด ต้องมากกว่า 0");
          }
        }

        if (product_process.lotno == "" && product_process.edit_type === true) {
          err_list.push("กรุณาเพิ่ม Lot No. ที่ต้องการ ส่งชุบ.");
        }

        if (err_list.length > 0) {
          //มี err
          var err_message = "";
          for (var e = 0; e < err_list.length; e++) {
            err_message += "<br/>" + err_list[e];
          }
          Swal.fire("ระบบ", err_message, "error");
        } else {
          let tmp_save = {
            product_id: product_id,
            nw: product.nw,
            order_po_number: po_order.number_po,
            product_edit_type: product_process.edit_type,
            product_amount: Number(product_process.amount.replace(/,/g, "")),
            prod_lot_id: product_process.prod_lot_id,
            unit: product_process.unit,
            lotno: product_process.lotno,
            /*  loss_amount: product_process.loss_amount, */
            plating_plant_id: product_process.plating_plant_id,
            prod_tacking: product_process.prod_tacking,
            lot_no_list: coil_no,
            estimate_date: product_process.estimate_date,
            loss_amount: Number(product_process.loss_amount.replace(/,/g, "")),
            product_order_id: product_process.product_order_id,
            out_amount: Number(product_process.out_amount.replace(/,/g, "")),
          };
          console.log("save", tmp_save);
          axios({
            method: "post",
            url: Configs.API_URL + "/api/wipshupOutInCTS/saveInOut",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: tmp_save,
          })
            .then(function (response) {
              //setlot_history_list(response.data);
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  getPoNumber();
                  getLotno();
                  /* getPlantShup(); */

                  getProductView();
                  getLotInStock();
                  getLotHistory();

                  //clear radio----------------------------------------
                  var radioButtonArray =
                    document.getElementsByName("check_coil_no");

                  for (var i = 0; i < radioButtonArray.length; i++) {
                    var radioButton = radioButtonArray[i];
                    radioButton.checked = false;
                  }
                  product_process.edit_type = true;
                  setproduct_process({
                    ...product_process,
                    loss_amount: "",
                    plating_plant_id: "",
                    amount: "",
                    lotno: "",
                    prod_lot_id: "",
                    prod_tacking: "",
                  });
                  product_process.loss_amount = "";
                  product_process.plating_plant_id = "";
                  product_process.invoice_no = "";
                  product_process.amount = "";
                  product_process.lotno = "";
                  product_process.prod_lot_id = "";
                  product_process.prod_tacking = "";
                  setcoil_no_list([]);
                });
              }
            })
            .catch(function (error) {
              Swal.fire("Over Stock!", error.response.data.message, "warning");
              /*   Swal.fire({
                title: 'เลขที่ใบ PO ซ้ำต้องการเจนใหม่หรือไม่?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) { 
        getPoNumber()
      }
    }
    ); */
              console.log(error);
            });
          // ไม่มี err
        }
      }
    });
  }

  function delCoilNoList(index) {
    var tempList = coil_no_list;
    tempList.splice(index, 1);
    setcoil_no_list(tempList);
    var count = 0;
    for (let item of coil_no_list) {
      item.coil_no = parseInt(coil_no_genDefault) + count;
      count++;
    }
    if (tempList.length === 0) {
      setisGen(true);
    }
    settest({ ...test, test: "" });
    test.test = "";
  }

  function changeWeightByTag(value, index) {
    /* console.log(value,index) */
    var tempList = coil_no_list;
    tempList[index].weight_by_tag = value;
    setcoil_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function changeOldCoilByTag(value, index) {
    /*    console.log(value,index) */
    var tempList = coil_no_list;
    tempList[index].old_coil_no = value;
    setcoil_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function SelectedCoilNo(index) {
    var tempList = lot_in_stock_list;

    for (let item of tempList) {
      item.selected = false;
      tempList[index].selected = true;
    }
    setproduct_process({
      ...product_process,
      unit: tempList[index].unit === "กิโลกรัม" ? "weight" : "picecs",
    });
    setlot_in_stock_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Warehouse_Management/wip_shup_out_in";
      }
    });
  }

  const linkreport = async (product_id, tracking) => {
    var get_filler_materail = {
      product_id: product_id,
      tracking: tracking,
      company_key: "CTS",
      oem_id: getOem(),
      company_id: getUser().com,
      product_no: product.product_no,
      product_name: product.product_name,
      /*  material_supplier_id:  product.material_supplier_id, */
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/wipshupOutInCTS/getWipShuploginreport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_materail,
    })
      .then(function (response) {
        if (response.data < 1) {
          Swal.fire({
            icon: "error",
            title: "ไม่มีข้อมูล",
          });
        } else {
          tag_point_cts(response.data, getUser().sub);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  สินค้า ส่งชุบ/รับชุบ{pageMode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    สินค้า ส่งชุบ/รับชุบ Management
                  </li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h2>Amount in warehouse {lot_in_stock_list.length} items</h2>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Product No. : {product.product_no}</h1>
                </div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Product Name : {product.product_name}</h1>
                </div>
              </div>

              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={product_process.edit_type != true ? false : true}
                    value={true}
                    onClick={(e) => {
                      setproduct_process({
                        ...product_process,
                        edit_type: e.target.value === "true" ? true : false,
                      });
                      product_process.edit_type = true;
                      /* lot_in_stock_list([]) */
                      setproduct_process({
                        ...product_process,
                        loss_amount: "",
                        plating_plant_id: "",
                        amount: "",
                        unit: "weight",
                        lotno: "",
                        prod_lot_id: "",
                        prod_tacking: "",
                        out_amount: "",
                        loss_amount: "",
                      });
                      product_process.edit_type = "";
                      product_process.loss_amount = "";
                      product_process.plating_plant_id = "";
                      product_process.amount = "";
                      product_process.unit = "weight";
                      product_process.lotno = "";
                      product_process.prod_lot_id = "";
                      product_process.prod_tacking = "";
                      product_process.loss_amount = "";
                      product_process.out_amount = "";
                      /* product_process.grade = "";
                      product_process.location = "";
                      product_process.unit = ""; */
                      //select_location_model.current.select.clearValue();
                      /* getPoNumber(); */
                    }}
                  />

                  <label htmlFor="inbound">
                    <h4>&nbsp;&nbsp;ส่งชุบ</h4>
                  </label>
                </div>

                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    value={true}
                    onClick={(e) => {
                      setproduct_process({
                        ...product_process,
                        edit_type: e.target.value === "true" ? false : true,
                        /*  edit_type: "", */
                        loss_amount: "",
                        /*   plating_plant_id: "", */
                        amount: "",
                        /* unit: "", */
                        lotno: "",
                        /* prod_lot_id: "",
                        prod_tacking: "", */
                      });

                      /* setlot_in_stock_list([]); */
                      setcoil_no_list([]);
                      /* getPoNumber(); */
                      /* select_location_model.current.select.clearValue();
                      select_supplier_model.current.select.clearValue(); */
                    }}
                  />

                  <label htmlFor="outbound">
                    <h4>&nbsp;&nbsp;รับชุบ</h4>
                  </label>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-4"></div>
              </div>
              <LOGH
                /* approve_cts = {( 
              <INF value={approve_person.qc_person}
                            onChange={(e) => {
                              setapprove_person({
                                ...approve_person,
                                qc_person: e.target.value,
                              });
                            }} label={"ชื่อผู้ตรวจ"} 
                       />
                       )}  */

                history={table_lot_history_list}
                data={table_lot_in_stock_list}
              />

              {product_process.edit_type === true ? (
                <>
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                      <INF
                        value={po_order.number_po}
                        disabled={true}
                        label={"เลขที่ใบจ่ายงานชุบ"}
                      />
                    </div>
                    <div className="col-12 col-md-12 col-xl-4">
                      <div class="form-group">
                        <select
                          className="custom-select select2 form-control"
                          value={product_process.unit}
                          onChange={(e) => {
                            setproduct_process({
                              ...product_process,
                              unit: e.target.value,
                            });

                            setunitAmount(e.target.value);
                          }}
                        >
                          <option value="weight">กิโลกรัม</option>
                          <option value="picecs">ชิ้น</option>
                        </select>

                        <label htmlFor="">หน่วย</label>
                        <label></label>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-4 col-xl-4"
                      style={{ "z-index": "99" }}
                    >
                      <div className="form-group ">
                        <Select
                          options={supplier_list}
                          value={supplier_list.filter((f) => {
                            return f.value === product.plating_plant_id;
                          })}
                          ref={select_supplier_model}
                          onChange={(e) => {
                            if (e !== null) {
                              setproduct_process({
                                ...product_process,
                                plating_plant_id: e.value,
                              });
                              setproduct({
                                ...product,
                                plating_plant_id: e.value,
                              });

                              getPoNumber(e.value);
                            }
                          }}
                        />
                        <label htmlFor="">โรงชุบ</label>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          allowNegative={false}
                          decimalScale={unitAmount === "weight" ? 2 : 0}
                          className="form-control"
                          required
                          thousandSeparator
                          value={product_process.amount}
                          disabled={disable}
                          onChange={(e) => {
                            setproduct_process({
                              ...product_process,
                              amount: e.target.value,
                            });
                          }}
                        />
                        <label>
                          {unitAmount === "weight" ? (
                            <>จำนวน(กิโลกรัม)</>
                          ) : (
                            <>จำนวน(ชิ้น)</>
                          )}

                          {/* {record_oil.liter === "" ? (
                        <span style={{ color: "red" }}> *</span> 
                      ) : (
                        ""
                      )} */}
                        </label>
                      </div>
                    </div>

                    <div
                      className="col-12 col-md-4 col-xl-4"
                      style={{ "z-index": "98" }}
                    >
                      <div className="form-group ">
                        <Select
                          options={lotno}
                          /* defaultValue={product_process.prod_lot_id} */
                          value={
                            lotno.filter((f) => {
                              return f.label === product_process.lotno;
                            }) /*  product_process.prod_lot_id */
                          }
                          /* value={coilNoList.filter((f) => { return f.value === material_process.coil_no })} */
                          ref={select_location_model}
                          onChange={(e) => {
                            if (e !== null) {
                              setproduct_process({
                                ...product_process,
                                prod_lot_id: e.value,
                                lotno: e.label,
                              });
                            }
                          }}
                        />
                        <label htmlFor="">Lot No.</label>
                      </div>
                    </div>
                  </div>

                  <br />

                  <div
                    className="table-responsive"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <table style={{ width: "100%", whiteSpace: "nowrap" }}>
                      <tr>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>

                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "30px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>
                      {coil_no_list.map((e, index) => {
                        return (
                          <>
                            <tr>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <INF
                                  value={e.coil_no}
                                  label={"Coil No."}
                                  /* onChange={(e) => {
                                setproduct_process({
                                  ...product_process,
                                  coil_no: e.target.value,
                                });
                              }} */ disabled
                                />
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                    allowNegative={false}
                                    value={e.weight_by_tag}
                                    onChange={(e) => {
                                      changeWeightByTag(e.target.value, index);
                                    }}
                                  />
                                  <label htmlFor="">น้ำหนักตามป้าย (กก.)</label>{" "}
                                </div>
                              </td>

                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <div className="form-group ">
                                  <NumberFormat
                                    thousandSeparator={false}
                                    /*        decimalScale={2} */
                                    type="text"
                                    className="form-control"
                                    allowNegative={false}
                                    value={e.old_coil_no}
                                    onChange={(e) => {
                                      changeOldCoilByTag(e.target.value, index);
                                    }}
                                  />
                                  <label htmlFor="">
                                    อ้างอิง Coil No. เก่า
                                  </label>{" "}
                                </div>
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "30px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      delCoilNoList(index);
                                    }}
                                    class="btn btn-block btn-danger "
                                  >
                                    X
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                  <hr />
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group ">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={product_process.unit === "weight" ? 2 : 0}
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          value={product_process.out_amount}
                          onChange={(e) => {
                            setproduct_process({
                              ...product_process,
                              out_amount: e.target.value,
                            });
                          }}
                          required
                        />
                        <label htmlFor="">Amount</label>{" "}
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group ">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          value={product_process.loss_amount}
                          onChange={(e) => {
                            setproduct_process({
                              ...product_process,
                              loss_amount: e.target.value,
                            });
                          }}
                          required
                        />
                        <label htmlFor="">จำนวนสูญหาย</label>{" "}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*  <Footter/> */}
    </div>
  );
}
export default WipShupOutInCTS_mgt;
