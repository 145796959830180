import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import "../../../assets/css/Dashboard.css";

function Vertical_Bar_Chart(params) {
  ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

  let newWidth = 1000;
  if (params.DataChart.labels.length > 0) {
    newWidth = 1000 + params.DataChart.labels.length * 20;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <label
          style={{
            fontSize: "23px",
            lineHeight: "normal",
            margin: "0px",
            display: "block",
            textAlign: "center",
            margin: "0px 0px 15px 0px",
          }}
        >
          {params.Text}
        </label>

        <div className="chartAreaWrapper customScroll-2">
          <div
            style={{
              minWidth: "100%",
              width: `${newWidth}px`,
              height: "500px",
            }}
          >
            <Bar
              data={params.DataChart}
              height={"max-content"}
              options={{
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true
                  }
                },
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                    // onClick: (evt, legendItem, legend) => {
                    //   // console.log(evt);
                    //   // console.log(legendItem.text);
                    //   // console.log(legend.chart.data.labels);
                    //   const index = legend.chart.data.labels.indexOf(
                    //     legendItem.text
                    //   );
                    //   legend.chart.toggleDataVisibility(index);
                    //   legend.chart.update();
                    // },

                    // labels: {
                    //   generateLabels: (chart) => {
                    //     // console.log(chart);
                    //     let visibility = [];
                    //     for (let i = 0; i < chart.data.labels.length; i++) {
                    //       if (chart.getDataVisibility(i) === true) {
                    //         visibility.push(false);
                    //       } else {
                    //         visibility.push(true);
                    //       }
                    //     }
                    //     return chart.data.labels.map((label, index) => ({
                    //       text: label,
                    //       strokeStyle: chart.data.datasets[0].borderColor[index],
                    //       fillStyle:
                    //         chart.data.datasets[0].backgroundColor[index],
                    //       hidden: visibility[index],
                    //     }));
                    //   },
                    // },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Vertical_Bar_Chart;
