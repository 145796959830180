import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import QRCode from "qrcode.react";
import Configs from "../../config";
import { getOem, getToken,getAcademy } from "../../Utils/Common";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import Select from "react-select";
import moment from "moment";
function EndUserInOut(params) {


  const [end_option, setend_option] = useState([]);
  const select_end = useRef();

  const [oem_id, setOem_id] = useState(getOem());
  const [end_user, setEnd_user] = useState([]);
  const [bill, setBill] = useState([]);
  const [viewEndBill,setviewEndBill] = useState({
    id:"",
    no_end_user:"",
    end_user_name:"",
    index:"",

  });
  const [filter_end_user, setfilter_end_user] = useState({
    name: "",
    fullname: "",
    oem_id: getOem(),
  });
  const [filter_bill, setfilter_bill] = useState({
    oem_id: getOem(),
  });
  const [test,settest] = useState({
    test:"",
  })





  

/*   const Get_End_user = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/end_user/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_end_user,
    })
      .then(async (response) => {
  
        setEnd_user(response.data);


      
      })
      .catch(async (error) => {
        console.log(error);
      });
  }; */

  function Get_Bill_endUser (){
     axios({
      method: "post",
      url: Configs.API_URL + "/end_user/getBill_end_user",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_end_user,
     
    })
      .then(async (response) => {
        setEnd_user(response.data);
        //setBill(response.data);
        console.log(response.data);

      
      })
      .catch(async (error) => {
        console.log(error);
      });
  };


  useEffect(() => {
   // Get_End_user();
    Get_Bill_endUser();
    //Get_Bill();
  }, []);









  const [message, setmessage] = useState({
    num: "",
    name: "",
  });

  

  const clearfillter = async () => {
    setfilter_end_user({
      ...filter_end_user,
      name: "",
      fullname: "",
    });
    filter_end_user.name = "";
    filter_end_user.fullname = "";
    Get_Bill_endUser();
   // Get_End_user();
  
  };

  // /////////////////////////////////////////


  function form(id,index,no_end_user,name,bill,e){
      console.log(id,index,no_end_user,name,bill,e)
      setviewEndBill({...viewEndBill,id:id,index:index,no_end_user:no_end_user,end_user_name:name});
      viewEndBill.id = id;
      viewEndBill.index = index;
      viewEndBill.no_end_user = no_end_user;
      viewEndBill.end_user_name = name;
      setBill([]);
      setBill(bill);


  }
  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }


  const _enduser_rowsData = [];
  for(var index = 0; index < end_user.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["name"] = end_user[index].name;
    rowItem["full_name"] = end_user[index].fullname;
    rowItem["contact"] =  end_user[index].contact;
    rowItem["address"] =  end_user[index].address;
    rowItem["latitude"] =  end_user[index].latitude;
    rowItem["longitude"] =  end_user[index].longitude;
    
    
  
    rowItem["bill"] = end_user[index].bill.length + "   bills.";


rowItem["mgt"] = (
<div className="row">
<div className="col-4">
  <a
  data-toggle="modal"
  data-target="#modal-xl"
    onClick={form.bind(this,end_user[index].id,index,end_user[index].name,end_user[index].fullname,end_user[index].bill)}
    className=" btn btn-xs "
  >
    {" "}
    <i class="fas fa-eye"></i>{" "}
  </a>
</div>
</div>
);
_enduser_rowsData.push(rowItem);
  }
 
  const _enduser_data = {
    columns: [
      {
        label: "No_",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "No. End user",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Fullname",
        field: "full_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact",
        field: "contact",
        sort: "asc",
        width: 50,
      },
      {
        label: "บิล(รวม)",
        field: "bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "Latitude",
        field: "latitude",
        sort: "asc",
        width: 50,
      },
      {
        label: "Longtitude",
        field: "longitude",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Mgt",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: _enduser_rowsData,
  };


  const _bill_rowsData = [];
  for(var index = 0; index < bill.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["bill_no"] = bill[index].bill_no;
    rowItem["customer_name"] = bill[index].customer_name;
    rowItem["bill_destination"] =  bill[index].bill_destination;
    rowItem["bill_value"] =  bill[index].bill_values;
    rowItem["delivered_date"] =  moment(bill[index].create_date).format('DD/MM/yyyy :: HH:mm น.');
    

    
_bill_rowsData.push(rowItem);
  }
 
  const _bill_data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Bill No.",
        field: "bill_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer Name",
        field: "customer_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Bill Destination",
        field: "bill_destination",
        sort: "asc",
        width: 50,
      },
      {
        label: "bill value",
        field: "bill_value",
        sort: "asc",
        width: 50,
      },
      {
        label: "delivered date",
        field: "delivered_date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: _bill_rowsData,
  };




  
  
   


  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="end_userinout" submenu="none" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>End_user IN/OUT{"  "}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">End_user IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_end_user.name}
                    onChange={(e) => {
                      setfilter_end_user({
                        ...filter_end_user,
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">No. End User</label>{" "}
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_end_user.fullame}
                    onChange={(e) => {
                      setfilter_end_user({
                        ...filter_end_user,
                        fullname: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Name</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                {/*     <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                  <label htmlFor="">Category/Model</label>
                </div> */}
                {/*   <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      Get_Bill_endUser();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearfillter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

              {/*
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary" onClick={showmessage}>
                    QR Code
                  </button>
                </div>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">End_user IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
            {/*   <div
                className="table-responsive" 
              >
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div> */}
              <div
                className="table-responsive" style={{ whiteSpace: 'nowrap' }}
              >
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={_enduser_data}
                />
              </div>
              
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">
                  
                 Bill History : Total bill &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={{
                        backgroundColor: "#F0FFFF",
                        border: "1px solid #ddd",
                        padding: "10px",
                        width: "100",
                      }}
                      value={bill.length}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                  &nbsp;&nbsp;&nbsp; items{" "}
                </h1>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                <div className="col-0 col-md-1 col-xl-2"></div>
                  <div className="col-12 col-md-5 col-xl-4" >
                    <h3>No. End user : {viewEndBill.no_end_user}</h3>
                 
                  </div>

                  <div className="col-12 col-md-5 col-xl-4">
                    <h3>End User name: {viewEndBill.end_user_name}</h3>

                  

                 {/*    <input
                      type="radio"
                      id="outbound"
                      name="edit_type"
                      checked={amount.edit_type != false ? false : true}
                      value={false}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "false" ? false : true,
                        });
                      }}
                    />
                    <label htmlFor="outbound">
                      <h4>  Out bound</h4>
                    </label> */}
                  </div>
                </div>
                <div
                className="table-responsive" style={{ whiteSpace: 'nowrap'}}
              >
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  disableRetreatAfterSorting={true}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={_bill_data}
                />
              </div>
               
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                 // onClick={Getamount}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EndUserInOut;
