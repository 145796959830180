import React, { useState, useEffect } from "react";

import "../../../assets/css/Dashboard.css";

function Bar_Line(params) {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "40px",
          backgroundColor: params.BackgroundColor,
        }}
      >
        <label
          style={{
            color: params.Color,
            fontSize: "25px",
            lineHeight: "normal",
            margin: "0px",
          }}
        >
          {params.Text}
        </label>
      </div>
    </>
  );
}
export default Bar_Line;
