import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../config";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import Sales_Order from "./Component/Sales_Order";
import DatePicker from "react-datepicker";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";

function AEV_Sales_Order({ mode }) {
  const { id } = useParams();
  const [disable, setdisable] = useState(null);
  const [pageMode, setpageMode] = useState("");
  let [categoryData, setCategoryData] = useState([]);
  const [categoryLv1Data, setCategoryLv1Data] = useState([]);
  const [categoryLv2Data, setCategoryLv2Data] = useState([]);
  const [categoryLv3Data, setCategoryLv3Data] = useState([]);
  const [categoryLv4Data, setCategoryLv4Data] = useState([]);
  const [customerTypeData, setCustomerTypeData] = useState({
    data: [],
  });
  const [tabData, setTabData] = useState([]);

  const allowedTypes = ["image/png", "image/jpeg"];
  const allowedTypes2 = [
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/png",
    "image/jpeg",
  ];
  const uploadRef = useRef(null);

  const [sale_order_upload_files, setsale_order_upload_files] = useState({
    data: [],
    new: [],
    old: [],
    del: [],
  });

  const tempOem = {
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    user_name: getUser().name + " " + getUser().surname,
  };

  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = e;
    const fileNames = [];
    for (let i = 0; i < files.length; i++) {
      fileNames.push(files[i].name);
    }
    setSelectedFiles(fileNames);
    encodeImage(files);
  };

  function isFileImage(file) {
    return file && allowedTypes2.includes(file["type"]);
  }

  async function GetCustomerType() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com
    }
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(async (response) => {
        setCustomerTypeData({
          ...customerTypeData,
          data: response.data,
        });
        customerTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function encodeImage(file) {
    // console.log("file", file);
    if (allowedTypes2.includes(file[0]?.type) === false) {
      Swal.fire(
        "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
        "กรุณาอัปโหลดประเภทไฟล์ใหม่",
        "error"
      );
      return;
    }
    if (file[0].name) {
      const regex = /([\u0E00-\u0E7Fก-๙]+)/gmu;
      const str = file[0].name;
      const m = regex.exec(str);

      if (isFileImage(file[0]) === false) {
        Swal.fire({
          title: "ประเภทไฟล์ไม่ถูกต้อง",
          text: "",
          icon: "warning",
          confirmButtonColor: "#032973",
          confirmButtonText: "ตกลง",
        });
      } else {
        const oleTempFileUpload = [];
        setsale_order_upload_files({ ...sale_order_upload_files, data: [] });

        // const newFileUpload = quotaion_save_files.new;
        if (file.length > 0) {
          for (let index = 0; index < file.length; index += 1) {
            const tempDataImg = {
              so_document_name: file[index].name,
              so_path_document: URL.createObjectURL(file[index]),
              so_image: file[index],
              oldNew: "new",
              status: "add",
            };
            // console.log('index', index);
            oleTempFileUpload.push(tempDataImg);
          }

          setsale_order_upload_files({
            ...sale_order_upload_files,
            data: oleTempFileUpload,
            new: oleTempFileUpload,
          });
          sale_order_upload_files.data = oleTempFileUpload;
          sale_order_upload_files.new = oleTempFileUpload;
          await saveSalesOrderFiles();
        }
      }
    }
  }

  async function saveSalesOrderFiles() {
    const findCheckTrue = sale_order_upload_files.data.filter((item) => {
      return item.status !== "del";
    });

    const addNew = sale_order_upload_files.new.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const delOld = sale_order_upload_files.del.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setsale_order_upload_files({
      ...sale_order_upload_files,
      data: findCheckTrue,
      new: addNew,
      del: delOld,
    });

    sale_order_upload_files.data = findCheckTrue;
    sale_order_upload_files.new = addNew;
    sale_order_upload_files.del = delOld;

    console.log(
      sale_order_upload_files.data,
      sale_order_upload_files.new,
      sale_order_upload_files.del
    );
  }

  const deleteImagesAndFilesOfProduct = async (id_response) => {
    let tempData1 = {
      del_list: sale_order_upload_files.del,
      user_id: getUser().fup,
      user_name: `${getUser().name} ${getUser().surname}`,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/delImagesAndFiles",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData1,
    })
      .then(async (response) => {
        // console.log(response);
        if (response.data && sale_order_upload_files.new.length > 0) {
          await uploadAndSaveImageAdvance(
            mode === "edit" ? id : id_response,
            "file_sales_order"
          );
        }
      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถลบเอกสารได้",
        });
      });
  };

  const uploadAndSaveImageAdvance = async (so_id, fileGroup) => {
    let arrFiles = [];
    if (fileGroup === "file_sales_order") {
      let data = new FormData();
      await sale_order_upload_files.new?.forEach(async (el) => {
        data.append("Profile", el.so_image);
        data.append("ProfileName", el.so_document_name);
      });
      data.append("company_id", getUser().com);
      data.append("oem_id", getOem());
      data.append("user_id", getUser().fup);
      data.append("user_name", `${getUser().name} ${getUser().surname}`);
      data.append("Project", "Cute_Products_Plus");
      data.append("Menu", "Sales Order");
      data.append("Ref", so_id);

      data.append("group", "file_sales_order");

      await axios({
        method: "post",
        url: Configs.API_URL_IMG_cuteplus + "/api/upload/saveDataUploadFile",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      })
        .then(async (response) => { })
        .catch(async (error) => {
          Swal.fire({
            icon: "error",
            title: "ไม่สามารถอัปโหลดเอกสารได้",
          });
        });
    }
  };

  const getFileSalesOrder = async () => {
    await axios({
      method: "get",
      url:
        Configs.API_URL_IMG_cuteplus +
        "/api/upload/getDataFileGroupAll/" +
        id +
        "/file_sales_order",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData1,
    })
      .then(async (response) => {
        // console.log(response.data);
        if (response.data.length > 0) {
          const file_list = response.data?.map((el, idx) => {
            return {
              ul_id: el.ul_id,
              so_document_name: el.ul_file_name,
              so_path_document: el.ul_path_file,
              oldNew: "old",
            };
          });
          setsale_order_upload_files({
            ...sale_order_upload_files,
            data: file_list,
          });
          sale_order_upload_files.data = file_list;
          setSelectedFiles(file_list.length > 0 ? [file_list[0].so_document_name] : []);
          // console.log('file_list', file_list);
        }
      })
      .catch(async (error) => {
        console.log('error', error);
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถอัปโหลดเอกสารได้",
        });
      });
  };

  const [modalCustomer, setModalCustomer] = useState(false);

  const [receivedValue, setReceivedValue] = useState("");

  // const receiveDataFromChild = (dataFromChild) => {
  //   setReceivedValue(dataFromChild);
  // };

  async function getCategory() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCategoryData(response.data);
        categoryData = response.data;

        setCategoryLv1Data(
          response.data.filter((item) => {
            return item.cat_level === "1";
          })
        );
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  const modalProductRef = useRef();
  const clickModalProductRef = async () => {
    modalProductRef.current.click();
  };
  const onClickOpenModalProduct = async () => {
    const AddProductTemp = AddProduct.data;
    const ProductTableTemp = Product.data;
    const tempList = ProductTableTemp.map((el) => {
      const check = AddProductTemp.find((tl) => tl.so_id === el.so_id);
      if (check) {
        // console.log("el", el);
        el.check = true;
        return el;
      } else {
        el.check = false;
        return el;
      }
    });
    setProduct({ ...Product, data: tempList });
    Product.data = tempList;
    clickModalProductRef();
  };

  const [inputLang, setInputLang] = useState({
    data: "ENG",
    check: false,
  });

  const [modalInputCustomer, setModalInputCustomer] = useState({
    customer_nameOld: "",
    customerOld: "",
    contactOld: "",
    shipOld: "",
    customer_name: "",
    customer: "",
    contact: "",
    ship: "",
  });

  // const [modalCustomer, setModalCustomer] = useState(false);
  const [functionTab2, setFunctionTab2] = useState({
    save: null,
    quotationSelectProduct: null,
  });

  const [quotationDropDown, setQuotationDropDown] = useState({
    data: [],
  });

  const [customerDropDown, setCustomerDropDown] = useState({
    data: [],
  });

  const [contactDropDown, setContactDropDown] = useState({
    data: [],
    dropdownOld: [],
    dropdown: [],
  });

  const [shipDropDown, setShipDropDown] = useState({
    data: [],
    dropdownOld: [],
    dropdown: [],
  });

  const [shippingByDropDown, setShippingByDropDown] = useState({
    data: [],
  });

  async function GetQuotationDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotationDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setQuotationDropDown({
          ...quotationDropDown,
          data: response.data,
        });
        quotationDropDown.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCusConShipDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCusConShipDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const customer_dd = response.data.customer?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        customer_dd.unshift({
          label: "--Select Customer--",
          value: ""
        })
        setCustomerDropDown({
          ...customerDropDown,
          data: customer_dd,
        });
        customerDropDown.data = customer_dd;

        const contractPoint_dd = response.data.contractPoint/* ?.map(el => ({ label: el.ccp_name, value: el.ccp_id })); */
        /* console.log('contractPoint_dd', contractPoint_dd);
        contractPoint_dd.unshift({
          label: "--Select Contact Name--",
          value: ""
        }) */
        setContactDropDown({
          ...contactDropDown,
          data: contractPoint_dd,
        });
        contactDropDown.data = contractPoint_dd;

        const shipDropDown_dd = response.data.ship/* ?.map(el => ({ label: el.csh_name, value: el.csh_id })); */
        /* shipDropDown_dd.unshift({
          label: "--Select Ship to--",
          value: ""
        }) */
        setShipDropDown({
          ...shipDropDown,
          data: shipDropDown_dd,
        });
        shipDropDown.data = shipDropDown_dd;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetShippingByDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getShippingByDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setShippingByDropDown({
          ...shippingByDropDown,
          data: response.data,
        });
        shippingByDropDown.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCusConShipData() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      customer: modalInputCustomer.customer,
      contact: modalInputCustomer.contact || null,
      ship: modalInputCustomer.ship,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCusConShipData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // clearSonoRef();
        let checkCusCommon = false;
        if (SalesOrder.so_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
          checkCusCommon = true;
        }
        setSalesOrder({
          ...SalesOrder,
          so_cus_house_number:
            response.data.customer[0][checkCusCommon ? "cus_house_number" : "cus_branch_house_number"] || "",
          so_cus_building: response.data.customer[0][checkCusCommon ? "cus_building" : "cus_branch_building"] || "",
          so_swine: response.data.customer[0][checkCusCommon ? "cus_swine" : "cus_branch_swine"] || "",
          so_soi: response.data.customer[0][checkCusCommon ? "cus_soi" : "cus_branch_soi"] || "",
          so_road: response.data.customer[0][checkCusCommon ? "cus_road" : "cus_branch_road"] || "",
          so_sub_district: response.data.customer[0][checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"] || "",
          so_district: response.data.customer[0][checkCusCommon ? "cus_district" : "cus_branch_district"] || "",
          so_province: response.data.customer[0][checkCusCommon ? "cus_province" : "cus_branch_province"] || "",
          so_postcode: response.data.customer[0][checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] || "",
          ///////
          so_contact_name: response.data.contractPoint[0]?.ccp_name || null,
          so_contact_email: response.data.contractPoint[0]?.ccp_email || null,
          so_contact_tel: response.data.contractPoint[0]?.ccp_tel || null,
          ///////
          so_ship_contract_name: response.data.ship[0].csh_conteact_name,
          so_csh_id: response.data.ship[0].csh_id,
          so_csh_name: response.data.ship[0].csh_name,
          so_ship_tel: response.data.ship[0].csh_tel,
          ///////
          so_ship_house_number: response.data.ship[0].csh_house_number,
          so_ship_building: response.data.ship[0].csh_building,
          so_ship_swine: response.data.ship[0].csh_swine,
          so_ship_soi: response.data.ship[0].csh_soi,
          so_ship_road: response.data.ship[0].csh_road,
          so_ship_sub_district: response.data.ship[0].csh_sub_district,
          so_ship_district: response.data.ship[0].csh_district,
          so_ship_province: response.data.ship[0].csh_province,
          so_ship_postcode: response.data.ship[0].csh_postcode,
        });

        /* setInputLang({
          data: "ENG",
          check: false,
        }); */
      })
      .catch(function (error) {
        console.log('error', error)
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  async function GetQuotationSelectData(data) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      quotation: data,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/quotation/getQuotationSelectData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setInputData({
          ...inputData,
          quo_no: data,
          cus_name:
            response.data.length !== 0
              ? response.data[0].quo_cus_name || ""
              : "",
          cus_house_number:
            response.data.length !== 0
              ? response.data[0].quo_cus_house_number || ""
              : "",
          cus_building:
            response.data.length !== 0
              ? response.data[0].quo_cus_building || ""
              : "",
          cus_swine:
            response.data.length !== 0 ? response.data[0].quo_swine || "" : "",
          cus_soi:
            response.data.length !== 0 ? response.data[0].quo_soi || "" : "",
          cus_road:
            response.data.length !== 0 ? response.data[0].quo_road || "" : "",
          cus_sub_district:
            response.data.length !== 0
              ? response.data[0].quo_sub_district || ""
              : "",
          cus_district:
            response.data.length !== 0
              ? response.data[0].quo_district || ""
              : "",
          cus_province:
            response.data.length !== 0
              ? response.data[0].quo_province || ""
              : "",
          cus_postcode:
            response.data.length !== 0
              ? response.data[0].quo_postcode || ""
              : "",
          cus_con_name:
            response.data.length !== 0
              ? response.data[0].quo_contract_name || ""
              : "",
          cus_con_mail:
            response.data.length !== 0
              ? response.data[0].quo_contract_email || ""
              : "",
          cus_con_tel:
            response.data.length !== 0
              ? response.data[0].quo_contract_tel || ""
              : "",
          con_name: "",
          con_place_name: "",
          con_tel: "",
          con_house_number: "",
          con_building: "",
          con_swine: "",
          con_soi: "",
          con_road: "",
          con_sub_district: "",
          con_district: "",
          con_province: "",
          con_postcode: "",
        });

        setInputLang({
          data:
            response.data.length !== 0
              ? response.data[0].quo_lang || "ENG"
              : "ENG",
          check:
            response.data.length !== 0
              ? response.data[0].quo_lang === "TH"
                ? true
                : false
              : false,
        });

        setModalInputCustomer({
          ...modalInputCustomer,
          customerOld:
            response.data.length !== 0 ? response.data[0].quo_cus_id || "" : "",
          contactOld:
            response.data.length !== 0 ? response.data[0].quo_ccp_id || "" : "",
          shipOld: "",
          customer:
            response.data.length !== 0 ? response.data[0].quo_cus_id || "" : "",
          contact:
            response.data.length !== 0 ? response.data[0].quo_ccp_id || "" : "",
          ship: "",
        });

        setContactDropDown({
          ...contactDropDown,
          dropdown:
            response.data.length !== 0
              ? contactDropDown.data.filter((item) => {
                return item.ccp_cus_id === response.data[0].quo_cus_id;
              }) || []
              : [],
          dropdownOld:
            response.data.length !== 0
              ? contactDropDown.data.filter((item) => {
                return item.ccp_cus_id === response.data[0].quo_cus_id;
              }) || []
              : [],
        });

        setShipDropDown({
          ...shipDropDown,
          dropdown:
            response.data.length !== 0
              ? shipDropDown.data.filter((item) => {
                return item.csh_cus_id === response.data[0].quo_cus_id;
              }) || []
              : [],
          dropdownOld:
            response.data.length !== 0
              ? shipDropDown.data.filter((item) => {
                return item.csh_cus_id === response.data[0].quo_cus_id;
              }) || []
              : [],
        });

        await functionTab2.quotationSelectProduct(data, true);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getProductKitting() {
    let tempData = {
      pk_pc_id: id,
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_kitting/getProductKitting",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // setAddProduct(response.data);
        // AddProduct = response.data;
        setAddProduct({
          data: response.data,
          // ...AddProduct,
          // so_id: response.data[0].so_id,
          // product_name: response.data[0].product_name,
          // product_name_eng: response.data[0].product_name_eng,
          // product_company: response.data[0].product_company,
          // product_category: response.data[0].product_category,
        });
        AddProduct.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const [file, setFile] = useState({});

  const [inputData, setInputData] = useState({});

  const [AddProduct, setAddProduct] = useState({
    data: [],
  });
  const [Product, setProduct] = useState({
    data: [],
  });

  useEffect(async () => {
    await GetQuotationDropDown();
    await GetCustomerType();
    await GetCusConShipDropDown();
    await GetShippingByDropDown();
    // await getCustomerDD();
    await getSono();
    // getKitting();
    if (mode === "view") {
      getFileSalesOrder();
      setdisable(true);
      setpageMode("View");
      await getSonoData();
      // await getKittingData();
    } else if (mode === "edit") {
      getFileSalesOrder();
      setdisable(false);
      setpageMode("Edit");
      await getSonoData();
      // await getKittingData();
    } else {
      setSalesOrder({
        ...SalesOrder,
        so_date: new Date(),
        so_ct_id: "fd5acfc9-6d49-45fc-b7bf-aba6e2536b93",
        so_ct_name: "นิติบุคคล"
      });
      SalesOrder.so_date = new Date();
      SalesOrder.so_ct_id = "fd5acfc9-6d49-45fc-b7bf-aba6e2536b93";
      SalesOrder.so_ct_name = "นิติบุคคล";
      setdisable(false);
      setpageMode("Add");
    }
    await GetProduct();
    await functionSaveTab2.getProduct();
  }, []);

  const [ValueformCom, setValueformCom] = useState({});

  // useEffect(() => {
  //   console.log(ValueformCom);
  // }, [ValueformCom]);
  const rowsAddProduct = [];

  const [test, settest] = useState({
    test: "",
  });

  function saveProduct() {
    console.log(Product.data);
    let findchecktrue = Product.data.filter((e) => {
      return e.check === true;
    });
    console.log(findchecktrue);
    if (findchecktrue.length > 0) {
      setAddProduct({
        ...AddProduct,
        data: findchecktrue,
      });
    } else {
      setAddProduct({
        ...AddProduct,
        data: [],
      });
    }
  }

  function cancelSaveToTable() {
    // let oldproduct = Product.data;
    let oldaddproduct = AddProduct.data;
    console.log(oldaddproduct);
    for (let i of Product.data) {
      i.check = false;
    }
    for (let i2 of AddProduct.data) {
      for (let i3 of Product.data) {
        if (i2.so_id === i3.so_id) {
          i3.check = true;
        }
      }
    }
    settest({ ...test, test: "" });
    test.test = "";
  }

  const rowsData = [];
  for (let index = 0; index < Product.data.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["code"] = Product.data[index].product_code
      ? Product.data[index].product_code
      : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="..."
          className="img-fluid rounded shadow border-0"
          src={
            // ""
            Product.data[index].pci_img_path !== "" &&
              Product.data[index].pci_img_path !== null &&
              Product.data[index].pci_img_path !== undefined
              ? Product.data[index].pci_img_path?.indexOf("blob") >= 0
                ? Product.data[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                `${Product.data[index].pci_img_name}/${Product.data[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = Product.data[index].product_name_eng
      ? Product.data[index].product_name_eng
      : "-";

    rowItem["category"] = Product.data[index].cat_name
      ? Product.data[index].cat_name
      : "-";

    rowItem["management"] =
      Product.data[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            // onClick={selectCertificate.bind(this, certificate_data_table_modal[index])}
            onClick={() => {
              let temp = Product.data;
              temp[index].check = true;
              setProduct({
                data: temp,
              });
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            // onClick={deleteCertificate.bind(this, certificate_data_table_modal[index], "modal")}
            onClick={() => {
              let temp = Product.data;
              temp[index].check = false;
              setProduct({
                data: temp,
              });
            }}
          >
            ยกเลิก
          </button>
        </div>
      );

    rowsData.push(rowItem);
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Category",
        field: "category",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const [Filter, setFilter] = useState({
    company: "",
    name: "",
    category: "",
  });

  function clear() {
    let oldaddproduct = AddProduct.data;
    console.log(oldaddproduct);
    for (let i of Product.data) {
      i.check = false;
    }
    for (let i2 of AddProduct.data) {
      for (let i3 of Product.data) {
        if (i2.so_id === i3.so_id) {
          i3.check = true;
        }
      }
    }
    settest({ ...test, test: "" });
    test.test = "";
    setFilter({
      ...Filter,
      company: "",
      name: "",
      category: "",
    });
    Filter.company = "";
    Filter.name = "";
    Filter.category = "";
    GetProduct();
  }

  const [Category, setCategory] = useState({
    data: [],
  });

  const GetCategory = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategory({ data: response.data });
        Category.data = response.data;
        // console.log(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [ProductKitting, setProductKitting] = useState({
    data: [],
  });

  const GetProduct = async () => {
    let temp = {
      company: Filter.company?.trim() || "",
      name: Filter.name?.trim() || "",
      category: Filter.category?.trim() || "",
      id: SalesOrder.so_id || "",
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_kitting/getProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(response.data)
        let temp = response.data;
        for (let i of temp) {
          i.check = false;
        }
        if (mode === "edit") {
          temp = temp.map((el) => {
            const check = AddProduct.data.find(
              (e) => e.pk_kitting_id == el.so_id
            );
            if (check) {
              el.check = true;
            }
            return el;
          });
        }
        setProduct({ data: temp });
        Product.data = temp;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  // const [customerDD, setCustomerDD] = useState([]);

  // async function getCustomerDD() {
  //   let temp = {
  //     oem_id: getOem(),
  //     company_id: getUser().com,
  //   }
  //   axios({
  //     method: "post",
  //     url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "Content-Type": "application/json",
  //       "X-TTT": Configs.API_TTT,
  //     },
  //     data: temp,
  //   })
  //     .then((response) => {
  //       // console.log('response.data :>> ', response.data);
  //       setCustomerDD(response.data);
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         title: "Error!",
  //         text: error.response.data.error[0].errorDis,
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //     });
  // }

  const [SonoRef, setSonoRef] = useState({});

  const [Sono, setSono] = useState({
    data: [],
  });

  const getSono = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getSono",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempOem,
    })
      .then(function (response) {
        setSono({ data: response });
        Sono.data = response;
        // console.log(response)
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const cancelQuotation = async () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ Sales Order นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const temp = {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          user_name: getUser().name + " " + getUser().surname,
          so_id: id,
        };
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus_sales + `/api/sale_order/cancelSO`,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            // console.log('response saveDrafQuotation -', response.data);

            Swal.fire({
              icon: "success",
              title: "ยกเลิกรายการ Sales Order สำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href = "/Sale_cuteplut/Sales_Order_SO_Cuteplus";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  };

  const getSonoRef = async (so_id, so_origin) => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getSonoRef",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { so_id, tempOem },
    })
      .then(async function (response) {
        const {
          so_id,
          so_no,
          so_date,
          so_delivery_date,
          so_ref_so_id,
          ...other
        } = response.data[0];
        setSalesOrder(other);

        if (mode === "add") {
          const currentDate = new Date();
          setSalesOrder((prevSalesOrder) => ({
            ...prevSalesOrder,
            ...other,
            so_date: currentDate,
            so_ref_so_id: response.data[0].so_id,
          }));
        } else {
          const soDate = new Date(response.data[0].so_date);
          const soDeliveryDate = new Date(response.data[0].so_delivery_date);
          setSalesOrder((prevSalesOrder) => ({
            ...prevSalesOrder,
            ...other,
            so_id: id,
            so_no: so_origin.so_no,
            so_ref_so_id: so_id,
            // so_ss_id: so_origin.so_ss_id,
            so_date: soDate,
            so_delivery_date: soDeliveryDate,
          }));
        }
        settest({ ...test, test });
        await functionSaveTab2.getRef_SoProduct(true, so_id);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getSonoData = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getSonoData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id, tempOem },
    })
      .then(async function (response) {
        const { so_date, so_delivery_date, ...other } = response.data[0];
        // setSalesOrder(other);
        const soDate = new Date(response.data[0].so_date);
        const soDeliveryDate = response.data[0].so_delivery_date
          ? new Date(response.data[0].so_delivery_date)
          : "";
        // console.log('other', other);
        setSalesOrder((prevSalesOrder) => ({
          ...prevSalesOrder,
          ...other,
          so_date: soDate,
          so_delivery_date: soDeliveryDate,
          so_total_befor_vat: other.so_final_discount_percent
        }));
        SalesOrder.so_cus_id = other.so_cus_id;
        SalesOrder.so_total_befor_vat = other.so_final_discount_percent;
        // console.log('SalesOrder', SalesOrder);
        /* console.log('response.data[0].so_cus_id', response.data[0].so_ccp_id);
        console.log('contactDropDown.data', contactDropDown.data)
        console.log('shipDropDown.dropdown', shipDropDown.dropdown); */
        /* 
        customer_name: modalInputCustomer.customer_nameOld,
                customer: modalInputCustomer.customerOld,
                contact: modalInputCustomer.contactOld,
                ship: modalInputCustomer.shipOld,
        */
        setModalInputCustomer({
          ...modalInputCustomer,
          customer: response.data[0].so_cus_id || null,
          customerOld: response.data[0].so_cus_id || null,
          customer_name: response.data[0].so_cus_name || null,
          customer_nameOld: response.data[0].so_cus_name || null,
          contact: response.data[0].so_ccp_id || null,
          contactOld: response.data[0].so_ccp_id || null,
          ship: response.data[0].so_csh_id || "",
          shipOld: response.data[0].so_csh_id || "",
        });
        const contactTamp = contactDropDown.data.filter((item) => {
          return item.ccp_cus_id  === response.data[0].so_cus_id;
        }).map((el) => ({ label: el.ccp_name, value: el.ccp_id }));
        contactTamp.unshift({
          label: "--Select Contact Name--",
          value: ""
        });

        setContactDropDown({
          ...contactDropDown,
          dropdown: contactTamp,
          dropdownOld: contactTamp,
        });

        const shipTamp = shipDropDown.data.filter((item) => {
          return item.csh_cus_id  === response.data[0].so_cus_id;
        }).map((el) => ({ label: el.csh_name, value: el.csh_id }));
        shipTamp.unshift({
          label: "--Select Ship to--",
          value: ""
        });

        setShipDropDown({
          ...shipDropDown,
          dropdown: shipTamp,
          dropdownOld: shipTamp,
        });

        settest({ ...test, test });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const clearCus = async () => {
    setModalInputCustomer({
      ...modalInputCustomer,
      customer: "",
      contact: "",
      ship: "",
    });
    modalInputCustomer.customer = "";
    modalInputCustomer.contact = "";
    modalInputCustomer.ship = "";
  };

  const clearSonoRef = async () => {
    setSalesOrder({
      ...SalesOrder,
      // so_id: "",
      // so_no: "",
      so_ref_po: "",
      // so_date: "",
      so_delivery_date: "",
      so_ref_quo_id: "",
      so_ref_so_id: "",
      so_cus_id: "",
      so_cus_name: "",
      so_cus_house_number: "",
      so_cus_building: "",
      so_swine: "",
      so_soi: "",
      so_road: "",
      so_sub_district: "",
      so_district: "",
      so_province: "",
      so_postcode: "",
      so_ccp_id: "",
      so_contact_name: "",
      so_contact_email: "",
      so_contact_tel: "",
      so_csh_id: "",
      so_csh_name: "",
      so_ship_contract_name: "",
      so_ship_tel: "",
      so_ship_house_number: "",
      so_ship_building: "",
      so_ship_swine: "",
      so_ship_soi: "",
      so_ship_road: "",
      so_ship_sub_district: "",
      so_ship_district: "",
      so_ship_province: "",
      so_ship_postcode: "",
      so_ds_id: "",
      so_cus_remark: "",
      so_lang: "",
      so_incl_vat: "",
      so_final_discount: "",
      so_shipping_cost: "",
      so_total_befor_vat: "",
      so_vat: "",
      so_total_price: "",
      so_remark: "",
      so_is_use: "",
      so_ss_id: "",
      so_created_date: "",
      so_updated_date: "",
      so_created_by: "",
      so_updated_by: "",
      so_created_name: "",
      so_updated_name: "",
      so_oem_id: "",
      so_company_id: "",
    });
    // SalesOrder.so_id = "";
    // SalesOrder.so_no = "";
    SalesOrder.so_ref_po = "";
    // SalesOrder.so_date = "";
    SalesOrder.so_delivery_date = "";
    SalesOrder.so_ref_quo_id = "";
    SalesOrder.so_ref_so_id = "";
    SalesOrder.so_cus_id = "";
    SalesOrder.so_cus_name = "";
    SalesOrder.so_cus_house_number = "";
    SalesOrder.so_cus_building = "";
    SalesOrder.so_swine = "";
    SalesOrder.so_soi = "";
    SalesOrder.so_road = "";
    SalesOrder.so_sub_district = "";
    SalesOrder.so_district = "";
    SalesOrder.so_province = "";
    SalesOrder.so_postcode = "";
    SalesOrder.so_ccp_id = "";
    SalesOrder.so_csh_name = "";
    SalesOrder.so_contact_name = "";
    SalesOrder.so_contact_email = "";
    SalesOrder.so_contact_tel = "";
    SalesOrder.so_csh_id = "";
    SalesOrder.so_ship_contract_name = "";
    SalesOrder.so_ship_tel = "";
    SalesOrder.so_ship_house_number = "";
    SalesOrder.so_ship_building = "";
    SalesOrder.so_ship_swine = "";
    SalesOrder.so_ship_soi = "";
    SalesOrder.so_ship_road = "";
    SalesOrder.so_ship_sub_district = "";
    SalesOrder.so_ship_district = "";
    SalesOrder.so_ship_province = "";
    SalesOrder.so_ship_postcode = "";
    SalesOrder.so_ds_id = "";
    SalesOrder.so_cus_remark = "";
    SalesOrder.so_lang = "";
    SalesOrder.so_incl_vat = "";
    SalesOrder.so_final_discount = "";
    SalesOrder.so_shipping_cost = "";
    SalesOrder.so_total_befor_vat = "";
    SalesOrder.so_vat = "";
    SalesOrder.so_total_price = "";
    SalesOrder.so_remark = "";
    SalesOrder.so_is_use = "";
    SalesOrder.so_ss_id = "";
    SalesOrder.so_created_date = "";
    SalesOrder.so_updated_date = "";
    SalesOrder.so_created_by = "";
    SalesOrder.so_updated_by = "";
    SalesOrder.so_created_name = "";
    SalesOrder.so_updated_name = "";
    SalesOrder.so_oem_id = "";
    SalesOrder.so_company_id = "";
  };

  const [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });

  const [functionSaveTab2, setFunctionSaveTab2] = useState({
    data: null,
    getRef_SoProduct: null,
  });

  const checkErrorForSave = async () => {
    let error_list = [];

    if (SalesOrder.so_cus_name) {
      SalesOrder.so_cus_name = SalesOrder.so_cus_name?.trim();
    }
    if (SalesOrder.so_ref_po) {
      SalesOrder.so_ref_po = SalesOrder.so_ref_po?.trim();
    }
    if (SalesOrder.so_cus_house_number) {
      SalesOrder.so_cus_house_number = SalesOrder.so_cus_house_number?.trim();
    }
    if (SalesOrder.so_cus_building) {
      SalesOrder.so_cus_building = SalesOrder.so_cus_building?.trim();
    }
    if (SalesOrder.so_swine) {
      SalesOrder.so_swine = SalesOrder.so_swine?.trim();
    }
    if (SalesOrder.so_soi) {
      SalesOrder.so_soi = SalesOrder.so_soi?.trim();
    }
    if (SalesOrder.so_road) {
      SalesOrder.so_road = SalesOrder.so_road?.trim();
    }
    if (SalesOrder.so_sub_district) {
      SalesOrder.so_sub_district = SalesOrder.so_sub_district?.trim();
    }
    if (SalesOrder.so_district) {
      SalesOrder.so_district = SalesOrder.so_district?.trim();
    }
    if (SalesOrder.so_province) {
      SalesOrder.so_province = SalesOrder.so_province?.trim();
    }
    /* if (SalesOrder.so_postcode) {
      SalesOrder.so_postcode =
        typeof SalesOrder.so_postcode === "string"
          ? parseFloat(SalesOrder.so_postcode?.replace(/,/g, ""))
          : SalesOrder.so_postcode;
    } */
    if (SalesOrder.so_contact_name) {
      SalesOrder.so_contact_name = SalesOrder.so_contact_name?.trim();
    }
    if (SalesOrder.so_contact_email) {
      SalesOrder.so_contact_email = SalesOrder.so_contact_email?.trim();
    }
    if (SalesOrder.so_ship_contract_name) {
      SalesOrder.so_ship_contract_name =
        SalesOrder.so_ship_contract_name?.trim();
    }
    if (SalesOrder.so_csh_name) {
      SalesOrder.so_csh_name = SalesOrder.so_csh_name?.trim();
    }
    if (SalesOrder.so_ship_house_number) {
      SalesOrder.so_ship_house_number = SalesOrder.so_ship_house_number?.trim();
    }
    if (SalesOrder.so_ship_building) {
      SalesOrder.so_ship_building = SalesOrder.so_ship_building?.trim();
    }
    if (SalesOrder.so_ship_swine) {
      SalesOrder.so_ship_swine = SalesOrder.so_ship_swine?.trim();
    }
    if (SalesOrder.so_ship_soi) {
      SalesOrder.so_ship_soi = SalesOrder.so_ship_soi?.trim();
    }
    if (SalesOrder.so_ship_road) {
      SalesOrder.so_ship_road = SalesOrder.so_ship_road?.trim();
    }
    if (SalesOrder.so_ship_sub_district) {
      SalesOrder.so_ship_sub_district = SalesOrder.so_ship_sub_district?.trim();
    }
    if (SalesOrder.so_ship_district) {
      SalesOrder.so_ship_district = SalesOrder.so_ship_district?.trim();
    }
    if (SalesOrder.so_ship_province) {
      SalesOrder.so_ship_province = SalesOrder.so_ship_province?.trim();
    }
    /* if (SalesOrder.so_ship_postcode) {
      SalesOrder.so_ship_postcode =
        typeof SalesOrder.so_ship_postcode === "string"
          ? parseFloat(SalesOrder.so_ship_postcode?.replace(/,/g, ""))
          : SalesOrder.so_ship_postcode;
    } */

    if (
      SalesOrder.so_cus_name === undefined ||
      SalesOrder.so_cus_name === null ||
      SalesOrder.so_cus_name?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอก Customer Name`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_delivery_date === undefined ||
      SalesOrder.so_delivery_date === null
      /* SalesOrder.so_delivery_date?.trim() === "" */
    ) {
      let temp_err = {
        message: `กรุณากรอก Delivery Date`,
      };
      error_list.push(temp_err);
    }
    //////////////
    if (
      SalesOrder.so_cus_house_number === undefined ||
      SalesOrder.so_cus_house_number === null ||
      SalesOrder.so_cus_house_number?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกบ้านเลขที่`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_cus_building === undefined ||
      SalesOrder.so_cus_building === null ||
      SalesOrder.so_cus_building?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกอาคาร`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_swine === undefined ||
      SalesOrder.so_swine === null ||
      SalesOrder.so_swine?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกหมู่`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_soi === undefined ||
      SalesOrder.so_soi === null ||
      SalesOrder.so_soi?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกซอย`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_road === undefined ||
      SalesOrder.so_road === null ||
      SalesOrder.so_road?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกถนน`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_sub_district === undefined ||
      SalesOrder.so_sub_district === null ||
      SalesOrder.so_sub_district?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกแขวง/ตำบล`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_district === undefined ||
      SalesOrder.so_district === null ||
      SalesOrder.so_district?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกเขต/อำเภอ`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_province === undefined ||
      SalesOrder.so_province === null ||
      SalesOrder.so_province?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกจังหวัด`,
      };
      error_list.push(temp_err);
    }

    const postcode = SalesOrder.so_postcode?.toString();
    if (
      SalesOrder.so_postcode === undefined ||
      SalesOrder.so_postcode === null ||
      postcode.length === 0
    ) {
      let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์`,
      };
      error_list.push(temp_err);
    } else {
      if (postcode.length >= 1 && postcode.length <= 4) {
        let temp_err = {
          message: `รหัสไปรษณีย์ต้องมี 5 หลัก`,
        };
        error_list.push(temp_err);
      }
    }

    //////////////
    /* if (SalesOrder.so_contact_tel) {
      const phoneNumber = SalesOrder.so_contact_tel.replace(/-/g, "").trim();
      if (phoneNumber.length != 10) {
        let temp_err = {
          message: `Contact Tel ต้องมี 10 หลัก`,
        };
        error_list.push(temp_err);
      }
    } */
    //////////////
    if (
      SalesOrder.so_ship_contract_name === "" ||
      SalesOrder.so_ship_contract_name === null ||
      SalesOrder.so_ship_contract_name === undefined
    ) {
      let temp_err = {
        message: `กรุณากรอกผู้ติดต่อ`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_csh_name === "" ||
      SalesOrder.so_csh_name === null ||
      SalesOrder.so_csh_name === undefined
    ) {
      let temp_err = {
        message: `กรุณากรอกชื่อที่อยู่`,
      };
      error_list.push(temp_err);
    }
    /* if (SalesOrder.so_ship_tel) {
      const phoneShipNumber = SalesOrder.so_ship_tel.replace(/-/g, "").trim();
      if (phoneShipNumber.length != 10) {
        let temp_err = {
          message: `เบอร์โทรศัพท์ต้องมี 10 หลัก`,
        };
        error_list.push(temp_err);
      }
    } */
    ///////////////

    if (
      SalesOrder.so_ship_house_number === undefined ||
      SalesOrder.so_ship_house_number === null ||
      SalesOrder.so_ship_house_number?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกบ้านเลขที่ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_ship_building === undefined ||
      SalesOrder.so_ship_building === null ||
      SalesOrder.so_ship_building?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกอาคาร (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_ship_swine === undefined ||
      SalesOrder.so_ship_swine === null ||
      SalesOrder.so_ship_swine?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกหมู่ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_ship_soi === undefined ||
      SalesOrder.so_ship_soi === null ||
      SalesOrder.so_ship_soi?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกซอย (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_ship_road === undefined ||
      SalesOrder.so_ship_road === null ||
      SalesOrder.so_ship_road?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกถนน (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_ship_sub_district === undefined ||
      SalesOrder.so_ship_sub_district === null ||
      SalesOrder.so_ship_sub_district?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกแขวง/ตำบล (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_ship_district === undefined ||
      SalesOrder.so_ship_district === null ||
      SalesOrder.so_ship_district?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกเขต/อำเภอ (Ship to)`,
      };
      error_list.push(temp_err);
    }

    if (
      SalesOrder.so_ship_province === undefined ||
      SalesOrder.so_ship_province === null ||
      SalesOrder.so_ship_province?.trim() === ""
    ) {
      let temp_err = {
        message: `กรุณากรอกจังหวัด (Ship to)`,
      };
      error_list.push(temp_err);
    }

    // if (
    //   SalesOrder.so_ship_postcode === undefined ||
    //   SalesOrder.so_ship_postcode === null /* ||
    //   SalesOrder.so_ship_postcode?.trim() === "" */
    // ) {
    //   let temp_err = {
    //     message: `กรุณากรอกรหัสไปรษณีย์ (Ship to)`,
    //   };
    //   error_list.push(temp_err);
    // }

    const postcode2 = SalesOrder.so_ship_postcode?.toString();
    if (
      SalesOrder.so_ship_postcode === undefined ||
      SalesOrder.so_ship_postcode === null ||
      postcode2.length === 0
    ) {
      let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์ (Ship to)`,
      };
      error_list.push(temp_err);
    } else {
      if (postcode2.length >= 1 && postcode2.length <= 4) {
        let temp_err = {
          message: `รหัสไปรษณีย์ต้องมี 5 หลัก (Ship to)`,
        };
        error_list.push(temp_err);
      }
    }

    ////////////

    if (
      SalesOrder.so_ds_id === "" ||
      SalesOrder.so_ds_id === null ||
      SalesOrder.so_ds_id === undefined
    ) {
      let temp_err = {
        message: `กรุณาเลือก Shipping by`,
      };
      error_list.push(temp_err);
    }

    return error_list;
  };

  async function saveOption() {
    const result = await Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    });

    if (result.isConfirmed) {
      let error_list = [];

      error_list = await checkErrorForSave();
      await functionSaveTab2.data();
      error_list.push(...errorListTab2.data);
      // console.log("inputDataTab2", ValueformCom);

      if (error_list.length > 0) {
        var err_message = "";

        for (var e = 0; e < error_list.length; e++) {
          err_message += "<br/>" + error_list[e].message;
        }

        Swal.fire("Error", err_message, "error");
      } else {
        const temp = {
          ...SalesOrder,
          so_id: SalesOrder.so_id || null,
          so_no: SalesOrder.so_no || null,
          so_ref_po: SalesOrder.so_ref_po || SonoRef.so_ref_po || null,
          so_date: SalesOrder.so_date || SonoRef.so_date || new Date(),
          so_delivery_date:
            SalesOrder.so_delivery_date ||
            SonoRef.so_delivery_date ||
            new Date(),
          so_ref_quo_id:
            SalesOrder.so_ref_quo_id || SonoRef.so_ref_quo_id || null,
          so_ref_so_id: SalesOrder.so_ref_so_id || SonoRef.so_ref_so_id || null,
          so_cus_id: SalesOrder.so_cus_id || SonoRef.so_cus_id || null,
          so_cus_name: SalesOrder.so_cus_name || SonoRef.so_cus_name || null,
          so_cus_house_number:
            SalesOrder.so_cus_house_number ||
            SonoRef.so_cus_house_number ||
            null,
          so_cus_building:
            SalesOrder.so_cus_building || SonoRef.so_cus_building || null,
          so_swine: SalesOrder.so_swine || SonoRef.so_swine || null,
          so_soi: SalesOrder.so_soi || SonoRef.so_soi || null,
          so_road: SalesOrder.so_road || SonoRef.so_road || null,
          so_sub_district:
            SalesOrder.so_sub_district || SonoRef.so_sub_district || null,
          so_district: SalesOrder.so_district || SonoRef.so_district || null,
          so_province: SalesOrder.so_province || SonoRef.so_province || null,
          so_postcode: SalesOrder.so_postcode || SonoRef.so_postcode || null,
          so_ccp_id: modalInputCustomer.contact || null,
          so_contact_name:
            SalesOrder.so_contact_name || SonoRef.so_contact_name || null,
          so_contact_email:
            SalesOrder.so_contact_email || SonoRef.so_contact_email || null,
          so_contact_tel:
            SalesOrder.so_contact_tel || SonoRef.so_contact_tel || null,
          so_csh_id: SalesOrder.so_csh_id || SonoRef.so_csh_id || null,
          so_csh_name: SalesOrder.so_csh_name || SonoRef.so_csh_name || null,
          so_ship_contract_name:
            SalesOrder.so_ship_contract_name ||
            SonoRef.so_ship_contract_name ||
            null,
          so_ship_tel: SalesOrder.so_ship_tel || SonoRef.so_ship_tel || null,
          so_ship_house_number:
            SalesOrder.so_ship_house_number ||
            SonoRef.so_ship_house_number ||
            null,
          so_ship_building:
            SalesOrder.so_ship_building || SonoRef.so_ship_building || null,
          so_ship_swine:
            SalesOrder.so_ship_swine || SonoRef.so_ship_swine || null,
          so_ship_soi: SalesOrder.so_ship_soi || SonoRef.so_ship_soi || null,
          so_ship_road: SalesOrder.so_ship_road || SonoRef.so_ship_road || null,
          so_ship_sub_district:
            SalesOrder.so_ship_sub_district ||
            SonoRef.so_ship_sub_district ||
            null,
          so_ship_district:
            SalesOrder.so_ship_district || SonoRef.so_ship_district || null,
          so_ship_province:
            SalesOrder.so_ship_province || SonoRef.so_ship_province || null,
          so_ship_postcode:
            SalesOrder.so_ship_postcode || SonoRef.so_ship_postcode || null,
          so_ds_id: SalesOrder.so_ds_id || SonoRef.SonoRef || null,
          so_cus_remark:
            SalesOrder.so_cus_remark || SonoRef.so_cus_remark || null,
          so_lang: SalesOrder.so_lang || SonoRef.so_lang || null,
          so_incl_vat: ValueformCom.SalesOrder.so_incl_vat || false,
          so_final_discount:
            parseFloat(ValueformCom.SalesOrder.so_final_discount_bath?.toString().replace(/,/g, "") || 0) ||
            SonoRef.so_final_discount_bath ||
            null,
          so_shipping_cost:
            ValueformCom.SalesOrder.so_shipping_cost ||
            SonoRef.so_shipping_cost ||
            null,
          so_total_befor_vat:
            ValueformCom.SalesOrder.so_total_befor_vat ||
            SonoRef.so_total_befor_vat ||
            null,
          so_vat: ValueformCom.SalesOrder.so_vat || SonoRef.so_vat || null,
          so_total_price:
            ValueformCom.SalesOrder.so_total_price ||
            SonoRef.so_total_price ||
            null,
          so_remark:
            ValueformCom.SalesOrder.so_remark || SonoRef.so_remark || null,
          so_is_use: true,
          so_ss_id: SalesOrder.so_ss_id || null,
          so_created_date: new Date(),
          so_updated_date: new Date(),
          so_created_by: getUser().fup,
          so_updated_by: getUser().fup,
          so_created_name: getUser().name + " " + getUser().surname,
          so_updated_name: getUser().name + " " + getUser().surname,
          so_oem_id: getOem(),
          so_company_id: getUser().com,
          so_lang: ValueformCom.SalesOrder.so_lang || null,
          so_total_m3: ValueformCom.SalesOrder.so_total_m3 || null,
          so_ct_id: SalesOrder.so_ct_id || null,
          so_final_discount_percent: parseFloat(ValueformCom.SalesOrder.so_final_discount?.toString().replace(/,/g, "") || 0) ||
            SonoRef.so_final_discount ||
            null,
        };
        if (mode === "edit") {
          delete temp.so_ss_id;
        }
        const temp2 = [];
        const temp3 = [];
        let tempso = null;
        if (ValueformCom.data.length === 0) {
          const temp2Item = {
            sop_so_id: SalesOrder.so_id,
          };
          temp2.push(temp2Item);
        }
        let userDetails = {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          user_name: getUser().name + " " + getUser().surname,
        };
        for (let index = 0; index < ValueformCom.data.length; index++) {
          const item = ValueformCom.data[index].product;
          let index_barcode = 0
          if (SalesOrder.so_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
            index_barcode = item.barcode_id.indexOf(item.bar_code)
          }
          const temp2Item = {
            // sop_id, //gen
            sop_product_id: item.product_id || null,
            sop_pb_id: item.bar_code || null,
            sop_cus_barcode: SalesOrder.so_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2"
              ? item.barcode[index_barcode >= 0 ? index_barcode : 0] || null : item.customer_bar_code || item.barcode[index_barcode >= 0 ? index_barcode : 0] || null,
            sop_cus_code: SalesOrder.so_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2"
              ? item.product_code || null : item.customer_code || item.product_code || null,
            sop_quantity: item.product_quantity || null,
            sop_quantity_unit: item.unit || null,
            sop_price: item.product_price || null,
            sop_total: item.product_price || null,
            sop_is_use: true,
            sop_created_date: new Date(),
            sop_updated_date: new Date(),
            sop_created_by: getUser().fup,
            sop_updated_by: getUser().fup,
            sop_created_name: null,
            sop_updated_name: null,
            sop_product_name_th: item.product_name || null,
            sop_product_name_eng: item.product_name_eng || null,
            sop_lp_id: item.lp_id || null,
            sop_lot_no: item.lot_no || null,
            sop_exp_date: ValueformCom.data[index].exp || null,
            sop_amount: item.log_amount2 || null,
            sop_so_id: SalesOrder.so_id || null,
            discount: item.discount?.map((el, index) => {
              return {
                sod_discount: el.discount || null,
                sod_unit_discount: el.unit || null,
                sod_sort: index + 1,
                sod_is_use: true,
                sod_created_date: new Date(),
                sod_updated_date: new Date(),
                sod_created_by: getUser().fup,
                sod_updated_by: getUser().fup,
                sod_created_name: getUser().name + " " + getUser().surname,
                sod_updated_name: getUser().name + " " + getUser().surname,
              };
            }),
          };

          // for (let index2 = 0; index2 < item.discount.length; index2++) {
          //   const item2 = item.discount[index2];
          //   const temp3Item = {
          //     sod_discound: item2.discount || null,
          //     sod_unit_discount: item2.unit || null,
          //     sod_sort: item2.index + 1,
          //     sod_is_use: true,
          //     sod_created_date: new Date(),
          //     sod_updated_date: new Date(),
          //     sod_created_by: getUser().fup,
          //     sod_updated_by: getUser().fup,
          //     sod_created_name: null,
          //     sod_updated_name: null,
          //   };

          //   temp3.push(temp3Item);
          // }

          temp2.push(temp2Item);
        }

        try {
          let axiosData = {};
          let axiosData1 = {};
          let axiosData21 = {};
          let axiosData2 = {};
          let axiosData3 = {};

          if (mode === "add") {
            axiosData = {
              method: "post",
              url: Configs.API_URL_cuteplus_sales + "/api/sale_order/addSo",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };
            await axios(axiosData).then(async (response) => {
              await deleteImagesAndFilesOfProduct(response.data.so_id).then(
                async (response2) => {
                  if (mode === "add") {
                    tempso = response.data.so_id;
                  }
                  axiosData1 = {
                    method: "post",
                    url: Configs.API_URL_cuteplus + "/api/bill/addBillfromSO",
                    headers: {
                      Authorization: "Bearer " + getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "application/json",
                    },
                    data: response.data,
                  };
                }
              );
            });
            await axios(axiosData1) /* .then(
              (axiosData21 = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus + "/api/log_product/addLogProduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: { temp, temp2, temp3, tempso },
              })
            );
            await axios(axiosData21) */
              .then(async (response) => {
                // console.log(response.data);
                const lp_id = response.data;
                axiosData2 = {
                  method: "post",
                  url:
                    Configs.API_URL_cuteplus_sales +
                    "/api/sale_order_product/addProduct",
                  headers: {
                    Authorization: "Bearer " + getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                  },
                  data: { temp2, temp3, tempso },
                };
              });
          } else if (mode === "edit") {
            axiosData = {
              method: "post",
              url: Configs.API_URL_cuteplus_sales + "/api/sale_order/editSo",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };

            await axios(axiosData).then(
              (axiosData2 = {
                method: "post",
                url:
                  Configs.API_URL_cuteplus_sales +
                  "/api/sale_order_product/editProduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: {
                  temp2: ValueformCom,
                  userDetails,
                  sop_so_id: SalesOrder.so_id || undefined,
                },
              })
            );
          }
          await axios(axiosData2).then(
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href = "/Sale_cuteplut/Sales_Order_SO_Cuteplus";
            })
          );
        } catch (error) {
          console.log(error);

          Swal.fire({
            icon: "error",
            title: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
      }
    }
  }

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          // if (from == "channel") {
          window.location.href = "/Sale_cuteplut/Sales_Order_SO_Cuteplus";
          // } else {
          //   window.location.href =
          //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store";
          // }
        }
      });
    } else {
      // if (from == "channel") {
      window.location.href = "/Sale_cuteplut/Sales_Order_SO_Cuteplus";
      // } else {
      //   window.location.href =
      //     "/Warehouse_Management/Factory_Master_Data_Cuteplus/Modern_Trade_Department_Store";
      // }
    }
  }

  const [SalesOrder, setSalesOrder] = useState({});
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Sales Order {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sale</li>
                  {/* <li className="breadcrumb-item active">
                    Factory Master Data
                  </li> */}
                  <li className="breadcrumb-item active">Sales Order</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode === "edit" || mode === "add" ? (
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-block btn-success"
                      onClick={async () => {
                        // await getSonoRef();
                        // console.log(SalesOrder);
                        // deleteImagesAndFilesOfProduct()

                        await saveOption();
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-xl-2">
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={async () => {
                        cancelOption();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-12 col-md-4 col-xl-2">
                  <button
                    type="button"
                    className="btn btn-block btn-danger"
                    onClick={async () => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  {/* <div> */}
                  <div className="card-header">
                    <div
                      className="row"
                      style={{ justifyContent: "start", alignItems: "start" }}
                    >
                      <div
                        className="col-3"
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <h3
                          className="card-title"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Sales Order (Sales)
                        </h3>
                        <button
                          hidden={mode !== "edit" ? true : false}
                          onClick={async () => {
                            await cancelQuotation();
                          }}
                          style={{ whiteSpace: "nowrap" }}
                          type="button"
                          className="btn btn-block btn-danger"
                        >
                          Cancel Sales Order
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-header">
                    <h3 className="card-title">Sales Order (Sales)</h3>
                  </div> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={
                              mode === "add"
                                ? "Auto Generate"
                                : SalesOrder.so_no
                            }
                          // onChange={(e) => {
                          //   console.log(SalesOrder)
                          //   // setSalesOrder({
                          //   //   ...SalesOrder,
                          //   //   so_no: e.target.value,
                          //   // });
                          // }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Sale Order No.
                            {/* {SalesOrder.so_no === "" ||
                              SalesOrder.so_no === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-2">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ct_id}
                            onChange={async (e) => {
                              const value = e.target.value;
                              const ct_name = customerTypeData.data?.find(el => el.ct_id === value).ct_name
                              setSalesOrder({
                                ...SalesOrder,
                                so_ct_id: value,
                                so_ct_name: ct_name,
                                so_change_customer: true
                              });
                              SalesOrder.so_ct_id = value;
                              SalesOrder.so_ct_name = ct_name;
                              SalesOrder.so_change_customer = true;
                              clearSonoRef();
                              await functionSaveTab2.getProduct();

                              setSalesOrder({
                                ...SalesOrder,
                                so_change_customer: false
                              });
                              SalesOrder.so_change_customer = false;
                            }}
                          >
                            <option value="" disabled>
                              -- กรุณาเลือก --
                            </option>

                            {customerTypeData.data.map((data, index) => {
                              return (
                                <option key={data.ct_id} value={data.ct_id}>
                                  {data.ct_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ประเภทลูกค้า
                            {SalesOrder.so_ct_id === "" ||
                              SalesOrder.so_ct_id === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-2">
                        <div
                          class="form-group"
                          style={{ display: "flex ", alignItems: "baseline" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_cus_name}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                cus_name: e.target.id,
                              });
                              setSalesOrder({
                                ...SalesOrder,
                                so_cus_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Customer Name
                            {SalesOrder.so_cus_name?.trim() === "" ||
                              SalesOrder.so_cus_name === null ||
                              SalesOrder.so_cus_name === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>

                          {mode !== "view" && <a
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            onClick={() => {
                              setModalCustomer(true);
                            }}
                          >
                            <i class="fas fa-search"></i>
                          </a>}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ref_so_id}
                            onChange={async (e) => {
                              const selectedOption =
                                e.target.options[e.target.selectedIndex];
                              const selectedSoId = selectedOption.value;
                              // console.log('selectedSoId', selectedSoId, e.target.value);
                              // const selectedSoNo = selectedOption.dataset.soNo;
                              await clearSonoRef();
                              setSalesOrder({
                                ...SalesOrder,
                                so_ref_so_id: selectedSoId,
                                // so_no: selectedSoNo,
                              });
                              SalesOrder.so_ref_so_id = selectedSoId;
                              // SonoRef.so_no = selectedSoNo;
                              settest("test");
                              if (SalesOrder.so_ref_so_id) {
                                getSonoRef(selectedSoId, SalesOrder);
                              }
                            }}
                          >
                            <option key="" value="" disabled={disable}>
                              --- เลือก ---
                            </option>
                            {Sono.data?.data?.map((option) => (
                              <option
                                key={option.so_no}
                                value={option.so_id}
                                data-so-no={option.so_no} // Set the data attribute for so_no
                              >
                                {option.so_no}
                              </option>
                            ))}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Ref. Sale Order No.
                            {/* {SalesOrder.product_name_eng === "" ||
                              SalesOrder.product_name_eng === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )} */}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-3 col-md-3 col-xl-3"></div> */}
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ref_po}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ref_po: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Ref PO No.
                            {/* {SalesOrder.so_ref_po === "" ||
                              SalesOrder.so_ref_po === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        {/* <label htmlFor="exampleInputFile">
                          Upload Document
                        </label> */}
                        <div
                          className="input-group"
                          style={{ position: "relative", marginTop: "10px" }}
                        >
                          <label
                            htmlFor="exampleInputFile"
                            style={{
                              position: "absolute",
                              top: "-20px",
                              left: "9px",
                              fontSize: "1rem",
                              zIndex: 99,
                            }}
                          >
                            Upload Document
                          </label>
                          <div className="custom-file">
                            <input
                              style={{ display: "none" }}
                              type="file"
                              className="custom-file-input"
                              id="exampleInputInvoid"
                              accept={allowedTypes}
                              disabled={disable}
                              ref={uploadRef}
                              name="customFile"
                              onChange={(e) => handleFileChange(e.target.files)}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                              onClick={() => uploadRef.current.click()}
                            >
                              {selectedFiles.length > 0
                                ? selectedFiles.join(", ")
                                : "-- เลือกไฟล์ --"}
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-3 col-xl-3"></div> */}
                      {/* <div className="row"> */}
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group">
                          <DatePicker
                            required
                            disabled={true}
                            selected={SalesOrder.so_date}
                            locale="th"
                            wrapperClassName="w-100"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_date: date,
                              });
                            }}
                            selectsStart
                            showYearDropdown
                            showMonthDropdown
                            startDate={new Date()}
                            minDate={new Date()}
                            customInput={<CustomInput isDis={true} />}
                          />
                          <label>
                            Sale Order Date
                            {/* {reportDate.startDate === "" ||
                            reportDate.startDate === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-6" style={{ zIndex: 100 }}>
                        <div className="form-group">
                          <DatePicker
                            required
                            disabled={disable}
                            selected={SalesOrder.so_delivery_date}
                            locale="th"
                            wrapperClassName="w-100"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_delivery_date: date,
                              });
                            }}
                            selectsStart
                            showYearDropdown
                            showMonthDropdown
                            // startDate={new Date().setDate(new Date().getDate() + 30)}
                            minDate={new Date()}
                            customInput={<CustomInput isDis={disable} />}
                          />
                          <label>
                            Delivery Date
                            {SalesOrder.so_delivery_date === "" ||
                              SalesOrder.so_delivery_date === null ||
                              SalesOrder.so_delivery_date === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">Customer Detail</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <label>
                          ที่อยู่
                          {SalesOrder.so_cus_house_number === "" ||
                            SalesOrder.so_cus_house_number === null ||
                            SalesOrder.so_cus_house_number === undefined ||
                            SalesOrder.so_cus_building === "" ||
                            SalesOrder.so_cus_building === null ||
                            SalesOrder.so_cus_building === undefined ||
                            SalesOrder.so_swine === "" ||
                            SalesOrder.so_swine === null ||
                            SalesOrder.so_swine === undefined ||
                            SalesOrder.so_soi === "" ||
                            SalesOrder.so_soi === null ||
                            SalesOrder.so_soi === undefined ||
                            SalesOrder.so_road === "" ||
                            SalesOrder.so_road === null ||
                            SalesOrder.so_road === undefined ||
                            SalesOrder.so_sub_district === "" ||
                            SalesOrder.so_sub_district === null ||
                            SalesOrder.so_sub_district === undefined ||
                            SalesOrder.so_district === "" ||
                            SalesOrder.so_district === null ||
                            SalesOrder.so_district === undefined ||
                            SalesOrder.so_province === "" ||
                            SalesOrder.so_province === null ||
                            SalesOrder.so_province === undefined ||
                            SalesOrder.so_postcode === "" ||
                            SalesOrder.so_postcode === null ||
                            SalesOrder.so_postcode === undefined ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_cus_house_number}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_cus_house_number: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            บ้านเลขที่
                            {/* {SalesOrder.so_cus_house_number === "" ||
                            SalesOrder.so_cus_house_number === null ||
                            SalesOrder.so_cus_house_number === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_cus_building}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_cus_building: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            อาคาร
                            {/* {SalesOrder.so_cus_building === "" ||
                            SalesOrder.so_cus_building === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-1 col-xl-1">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_swine}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_swine: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            หมู่
                            {/* {SalesOrder.so_swine === "" ||
                            SalesOrder.so_swine === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_soi}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_soi: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ซอย
                            {/* {SalesOrder.so_soi === "" ||
                            SalesOrder.so_soi === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_road}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_road: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ถนน
                            {/* {SalesOrder.so_road === "" ||
                            SalesOrder.so_road === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-3 col-xl-3"></div> */}
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_sub_district}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_sub_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            แขวง/ตำบล
                            {/* {SalesOrder.so_sub_district === "" ||
                            SalesOrder.so_sub_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_district}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            เขต/อำเภอ
                            {/* {SalesOrder.so_district === "" ||
                            SalesOrder.so_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_province}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_province: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            จังหวัด
                            {/* {SalesOrder.so_province === "" ||
                            SalesOrder.so_province === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-3 col-xl-3"></div> */}
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          {/* <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_postcode}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_postcode: e.target.value,
                              });
                            }}
                          /> */}
                          <NumberFormat
                            type="text"
                            decimalScale={0}
                            maxLength={5}
                            allowNegative={false}
                            allowLeadingZeros={true}
                            disabled={disable}
                            className="form-control"
                            required
                            value={SalesOrder.so_postcode}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_postcode: e.target.value,
                              });
                              SalesOrder.so_postcode = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            รหัสไปรษณีย์
                            {/* {SalesOrder.so_postcode === "" ||
                            SalesOrder.so_postcode === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-8 col-md-8 col-xl-8"></div> */}
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_contact_name}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_contact_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Customer Contact Name
                            {/* {SalesOrder.so_contact_name === "" ||
                            SalesOrder.so_contact_name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-6 col-md-6 col-xl-6"></div> */}
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_contact_email}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_contact_email: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Contact Email
                            {/* {SalesOrder.so_contact_email === "" ||
                            SalesOrder.so_contact_email === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            // format={"###-###-####"}
                            required
                            disabled={disable}
                            value={SalesOrder.so_contact_tel}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_contact_tel: e.target.value,
                              });
                              SalesOrder.so_contact_tel = e.target.value;
                            }}
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_contact_tel}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_contact_tel: e.target.value,
                              });
                            }}
                          /> */}
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Contact Tel.
                            {/* {SalesOrder.so_contact_tel === "" ||
                            SalesOrder.so_contact_tel === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-xl-12">
                        <label>Ship to</label>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_contract_name}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_contract_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ผู้ติดต่อ
                            {SalesOrder.so_ship_contract_name === "" ||
                              SalesOrder.so_ship_contract_name === null ||
                              SalesOrder.so_ship_contract_name === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-6 col-md-6 col-xl-6"></div> */}
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_csh_name}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_csh_name: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ชื่อที่อยู่
                            {SalesOrder.so_csh_name === "" ||
                              SalesOrder.so_csh_name === null ||
                              SalesOrder.so_csh_name === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            // format={"###-###-####"}
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_tel}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_tel: e.target.value,
                              });
                              SalesOrder.so_ship_tel = e.target.value;
                            }}
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_tel}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_tel: e.target.value,
                              });
                            }}
                          /> */}
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            เบอร์โทรศัพท์
                            {/* {SalesOrder.so_ship_tel === "" ||
                            SalesOrder.so_ship_tel === null||
                            SalesOrder.so_ship_tel === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-xl-12">
                        <label>
                          ที่อยู่
                          {SalesOrder.so_ship_house_number === "" ||
                            SalesOrder.so_ship_house_number === null ||
                            SalesOrder.so_ship_house_number === undefined ||
                            SalesOrder.so_ship_building === "" ||
                            SalesOrder.so_ship_building === null ||
                            SalesOrder.so_ship_building === undefined ||
                            SalesOrder.so_ship_swine === "" ||
                            SalesOrder.so_ship_swine === null ||
                            SalesOrder.so_ship_swine === undefined ||
                            SalesOrder.so_ship_soi === "" ||
                            SalesOrder.so_ship_soi === null ||
                            SalesOrder.so_ship_soi === undefined ||
                            SalesOrder.so_ship_road === "" ||
                            SalesOrder.so_ship_road === null ||
                            SalesOrder.so_ship_road === undefined ||
                            SalesOrder.so_ship_sub_district === "" ||
                            SalesOrder.so_ship_sub_district === null ||
                            SalesOrder.so_ship_sub_district === undefined ||
                            SalesOrder.so_ship_district === "" ||
                            SalesOrder.so_ship_district === null ||
                            SalesOrder.so_ship_district === undefined ||
                            SalesOrder.so_ship_province === "" ||
                            SalesOrder.so_ship_province === null ||
                            SalesOrder.so_ship_province === undefined ||
                            SalesOrder.so_ship_postcode === "" ||
                            SalesOrder.so_ship_postcode === null ||
                            SalesOrder.so_ship_postcode === undefined ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_house_number}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_house_number: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            บ้านเลขที่
                            {/* {SalesOrder.so_ship_house_number?.trim() === "" ||
                            SalesOrder.so_ship_house_number === null ||
                            SalesOrder.so_ship_house_number === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_building}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_building: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            อาคาร
                            {/* {SalesOrder.so_ship_building === "" ||
                            SalesOrder.so_ship_building === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-1 col-xl-1">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_swine}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_swine: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            หมู่
                            {/* {SalesOrder.so_ship_swine === "" ||
                            SalesOrder.so_ship_swine === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_soi}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_soi: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ซอย
                            {/* {SalesOrder.so_ship_soi === "" ||
                            SalesOrder.so_ship_soi === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-2 col-xl-2">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_road}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_road: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ถนน
                            {/* {SalesOrder.so_ship_road === "" ||
                            SalesOrder.so_ship_road === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-3 col-md-3 col-xl-3"></div> */}
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_sub_district}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_sub_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            แขวง/ตำบล
                            {/* {SalesOrder.so_ship_sub_district === "" ||
                            SalesOrder.so_ship_sub_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_district}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_district: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            เขต/อำเภอ
                            {/* {SalesOrder.so_ship_district === "" ||
                            SalesOrder.so_ship_district === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ship_province}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_province: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            จังหวัด
                            {/* {SalesOrder.so_ship_province === "" ||
                            SalesOrder.so_ship_province === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                      {/* <div className="col-3 col-md-3 col-xl-3"></div> */}
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <NumberFormat
                            type="text"
                            decimalScale={0}
                            maxLength={5}
                            allowNegative={false}
                            allowLeadingZeros={true}
                            disabled={disable}
                            className="form-control"
                            required
                            value={SalesOrder.so_ship_postcode}
                            onChange={(e) => {
                              setSalesOrder({
                                ...SalesOrder,
                                so_ship_postcode: e.target.value,
                              });
                              SalesOrder.so_ship_postcode = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            รหัสไปรษณีย์
                            {/* {SalesOrder.so_ship_postcode === "" ||
                            SalesOrder.so_ship_postcode === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-8 col-md-8 col-xl-8"></div> */}
                    <div className="row">
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <select
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_ds_id}
                            onChange={(e) => {
                              // console.log("wait:", e.target.value);
                              setSalesOrder({
                                ...SalesOrder,
                                so_ds_id: e.target.value,
                              });
                              SalesOrder.so_ds_id = e.target.value;
                              settest("test");
                              // console.log("success:", e.target.value);
                            }}
                          >
                            <option key="" value="">
                              --- เลือก ---
                            </option>
                            {shippingByDropDown.data?.map((option, index) => (
                              <option
                                key={shippingByDropDown.data[index].ds_name}
                                value={shippingByDropDown.data[index].ds_id}
                              >
                                {/* {console.log(shippingByDropDown.data[index].ds_id)} */}
                                {shippingByDropDown.data[index].ds_name}
                              </option>
                            ))}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Shipping by
                            {SalesOrder.so_ds_id === "" ||
                              SalesOrder.so_ds_id === null ||
                              SalesOrder.so_ds_id === undefined ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={SalesOrder.so_cus_remark}
                            onChange={(e) => {
                              // console.log(SalesOrder.so_incl_vat);
                              setSalesOrder({
                                ...SalesOrder,
                                so_cus_remark: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Remark
                            {/* {SalesOrder.so_cus_remark === "" ||
                            SalesOrder.so_cus_remark === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">Product</h3>
                  </div>
                  <div className="card-body">
                    <Sales_Order
                      mode={mode}
                      id={id}
                      sendid={SonoRef.so_id}
                      cusid={SalesOrder.so_cus_id}
                      inputDataTab2={ValueformCom}
                      setInputDataTab2={setValueformCom}
                      sendinclvat={SalesOrder.so_incl_vat}
                      SalesOrderParent={SalesOrder}
                      setSalesOrderParent={setSalesOrder}
                      // inputDataTab2={inputDataTab2}
                      // setInputDataTab2={setInputDataTab2}
                      errorListTab2={errorListTab2}
                      setErrorListTab2={setErrorListTab2}
                      functionSaveTab2={functionSaveTab2}
                      setFunctionSaveTab2={setFunctionSaveTab2}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            size="sm"
            className="Modal_Center_LeftRight"
            isOpen={modalCustomer}
            toggle={() => {
              setModalInputCustomer({
                ...modalInputCustomer,
                customer_name: modalInputCustomer.customer_nameOld,
                customer: modalInputCustomer.customerOld,
                contact: modalInputCustomer.contactOld,
                ship: modalInputCustomer.shipOld,
              });
              modalInputCustomer.customer_name =
                modalInputCustomer.customer_nameOld;
              modalInputCustomer.customer = modalInputCustomer.customerOld;
              modalInputCustomer.contact = modalInputCustomer.contactOld;
              modalInputCustomer.ship = modalInputCustomer.shipOld;

              setContactDropDown({
                ...contactDropDown,
                dropdown: contactDropDown.dropdownOld,
              });

              setShipDropDown({
                ...shipDropDown,
                dropdown: shipDropDown.dropdownOld,
              });

              setModalCustomer(false);
            }}
          >
            <ModalHeader
            /* toggle={() => {
              setModalInputCustomer({
                ...modalInputCustomer,
                customer_name: modalInputCustomer.customer_nameOld,
                customer: modalInputCustomer.customerOld,
                contact: modalInputCustomer.contactOld,
                ship: modalInputCustomer.shipOld,
              });

              modalInputCustomer.customer_name =
                modalInputCustomer.customer_nameOld;
              modalInputCustomer.customer = modalInputCustomer.customerOld;
              modalInputCustomer.contact = modalInputCustomer.contactOld;
              modalInputCustomer.ship = modalInputCustomer.shipOld;

              setContactDropDown({
                ...contactDropDown,
                dropdown: contactDropDown.dropdownOld,
              });

              setShipDropDown({
                ...shipDropDown,
                dropdown: shipDropDown.dropdownOld,
              });

              setModalCustomer(false);
            }} */
            >
              <h4 className="modal-title" id="modalCustomer">
                <b>Customer</b>
              </h4>
            </ModalHeader>

            <ModalBody>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <Select
                      type="text"
                      // className="form-control"
                      required
                      options={customerDropDown.data}
                      filterOption={customFilter}
                      isDisabled={disable}
                      value={
                        customerDropDown.data?.find(el => el.value === modalInputCustomer.customer) || { label: "--Select Customer--", value: "" }
                      }
                      onChange={async (e) => {
                        setModalInputCustomer({
                          ...modalInputCustomer,
                          customer_name: e.label,
                          customer: e.value,
                          contact: "",
                          ship: "",
                        });

                        const contactTamp = contactDropDown.data.filter(
                          (item) => {
                            return item.ccp_cus_id === e.value;
                          }
                        ).map(el => ({ label: el.ccp_name, value: el.ccp_id }));
                        contactTamp.unshift({
                          label: "--Select Contact Name--",
                          value: ""
                        })

                        setContactDropDown({
                          ...contactDropDown,
                          dropdown: contactTamp,
                        });

                        const shipTamp = shipDropDown.data.filter((item) => {
                          return item.csh_cus_id === e.value;
                        }).map(el => ({ label: el.csh_name, value: el.csh_id  }));
                        shipTamp.unshift({
                          label: "--Select Ship to--",
                          value: ""
                        })

                        setShipDropDown({
                          ...shipDropDown,
                          dropdown: shipTamp,
                        });
                      }}
                    >
                    </Select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Customer
                      {modalInputCustomer.customer === "" ||
                        modalInputCustomer.customer === null ||
                        modalInputCustomer.customer === undefined ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <Select
                      type="text"
                      required
                      isDisabled={disable || shipDropDown.dropdown.length <= 1}
                      options={shipDropDown.dropdown}
                      filterOption={customFilter}
                      value={shipDropDown.dropdown?.find(el => el.value === modalInputCustomer.ship) || {
                        label: "--Select Ship to--",
                        value: ""
                      }}
                      onChange={(e) => {
                        setModalInputCustomer({
                          ...modalInputCustomer,
                          ship: e.value,
                        });
                      }}
                    >
                      {/* <option key="" value="">
                        --Select Ship to--
                      </option>

                      {shipDropDown.dropdown.map((data, index) => {
                        return (
                          <option key={index} value={data.csh_id}>
                            {data.csh_name}
                          </option>
                        );
                      })} */}
                    </Select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Ship to
                      {modalInputCustomer.ship === "" ||
                        modalInputCustomer.ship === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <Select
                      type="text"
                      required
                      isDisabled={
                        disable || contactDropDown.dropdown.length <= 1
                      }
                      options={contactDropDown.dropdown}
                      filterOption={customFilter}
                      value={contactDropDown.dropdown?.find(el => el.value === modalInputCustomer.contact) || {
                        label: "--Select Contact Name--",
                        value: ""
                      }}
                      onChange={(e) => {
                        setModalInputCustomer({
                          ...modalInputCustomer,
                          contact: e.value,
                        });
                      }}
                    >
                      {/* <option key="" value="">
                        --Select Contact Name--
                      </option>

                      {contactDropDown.dropdown.map((data, index) => {
                        return (
                          <option key={index} value={data.ccp_id}>
                            {data.ccp_name}
                          </option>
                        );
                      })} */}
                    </Select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Contact Name
                      {/* {modalInputCustomer.contact === "" ||
                        modalInputCustomer.contact === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

            </ModalBody>

            <ModalFooter style={{ justifyContent: "space-between" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={async () => {
                  setSalesOrder({
                    ...SalesOrder,
                    so_cus_name: modalInputCustomer.customer_nameOld,
                    so_cus_id: modalInputCustomer.customerOld,
                  });
                  settest("test");
                  SalesOrder.so_cus_name = modalInputCustomer.customer_nameOld;
                  SalesOrder.so_cus_id = modalInputCustomer.customerOld;

                  setModalInputCustomer({
                    ...modalInputCustomer,
                    customer_name: modalInputCustomer.customer_nameOld,
                    customer: modalInputCustomer.customerOld,
                    contact: modalInputCustomer.contactOld,
                    ship: modalInputCustomer.shipOld,
                  });
                  modalInputCustomer.customer_name =
                    modalInputCustomer.customer_nameOld;
                  modalInputCustomer.customer = modalInputCustomer.customerOld;
                  modalInputCustomer.contact = modalInputCustomer.contactOld;
                  modalInputCustomer.ship = modalInputCustomer.shipOld;

                  setContactDropDown({
                    ...contactDropDown,
                    dropdown: contactDropDown.dropdownOld,
                  });

                  setShipDropDown({
                    ...shipDropDown,
                    dropdown: shipDropDown.dropdownOld,
                  });

                  setModalCustomer(false);
                }}
              >
                ยกเลิก
              </button>

              <button
                type="button"
                className="btn btn-success"
                disabled={
                  (modalInputCustomer.ship &&
                    // modalInputCustomer.contact &&
                    modalInputCustomer.customer) ||
                    modalInputCustomer.customer === ""
                    ? false
                    : true
                }
                onClick={async () => {
                  // if(SalesOrder.so_cus_name === null || SalesOrder.so_cus_name === undefined || SalesOrder.so_cus_name === ""){
                  //   clearSonoRef();
                  //   clearCus();
                  //   console.log("clear")
                  // }
                  if (
                    !modalInputCustomer.customer &&
                    !modalInputCustomer.ship /* &&
                    !modalInputCustomer.contact */
                  ) {
                    setSalesOrder({
                      ...SalesOrder,
                      so_change_customer: true
                    });
                    SalesOrder.so_change_customer = true;
                    clearSonoRef();
                    await functionSaveTab2.getProduct();

                    setSalesOrder({
                      ...SalesOrder,
                      so_change_customer: false
                    });
                    SalesOrder.so_change_customer = false;
                    setModalCustomer(false);
                    return;
                  }

                  setSalesOrder({
                    ...SalesOrder,
                    so_cus_name: modalInputCustomer.customer_name,
                    so_cus_id: modalInputCustomer.customer,
                  });
                  settest("test");
                  SalesOrder.so_cus_name = modalInputCustomer.customer_name;
                  SalesOrder.so_cus_id = modalInputCustomer.customer;

                  setModalInputCustomer({
                    ...modalInputCustomer,
                    customer_nameOld: modalInputCustomer.customer_name,
                    customerOld: modalInputCustomer.customer,
                    contactOld: modalInputCustomer.contact,
                    shipOld: modalInputCustomer.ship,
                  });

                  modalInputCustomer.customer_nameOld =
                    modalInputCustomer.customer_name;
                  modalInputCustomer.customerOld = modalInputCustomer.customer;
                  modalInputCustomer.contactOld = modalInputCustomer.contact;
                  modalInputCustomer.shipOld = modalInputCustomer.ship;

                  setContactDropDown({
                    ...contactDropDown,
                    dropdownOld: contactDropDown.dropdown,
                  });

                  setShipDropDown({
                    ...shipDropDown,
                    dropdownOld: shipDropDown.dropdown,
                  });

                  setModalCustomer(false);
                  await functionSaveTab2.getProduct();
                  await GetCusConShipData();
                }}
              >
                บันทึก
              </button>
            </ModalFooter>
          </Modal>
        </section>
      </div>
    </div>
  );
}

export default AEV_Sales_Order;
