import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";

import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import DatePicker from "react-datepicker";

import moment from "moment";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";

// import Supplier_Cute_Product from "./Component/Supplier_Cute_Product";

function Logistic_Car_AEV({ mode }) {
  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const validThai = new RegExp(`[\u0E00-\u0E7F]`);
  const validEng = new RegExp(`[a-zA-Z]`);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithOutCommas = (x) => {
    return x.toString().replace(/,/g, "");
  };

  const { id } = useParams();

  const [inputData, setInputData] = useState({
    remark: "",
  });

  const [logisData, setLogisData] = useState({
    mainData: [],
    secData: [],
  });

  const [invData, setInvData] = useState({
    data: [],
  });

  const [selectInvData, setSelectInvData] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [modeInOut, setModeInOut] = useState(true);

  const [modalOC, setModalOC] = useState(false);

  const [StockInModal, setStockInModal] = useState(false);

  const [modalInvData, setModalInvData] = useState({
    data: {},
  });

  // const [expLog, setExpLog] = useState({
  //   data: [],
  // });

  async function GetLogisticInOutData() {
    await axios({
      method: "get",
      url:
        Configs.API_URL_cuteplus + `/api/logistic/getLogisticInOutData/${id}`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setLogisData({
          mainData: response.data.mainData,
          secData: response.data.secData,
        });

        logisData.mainData = response.data.mainData;
        logisData.secData = response.data.secData;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetInvDataLoInOut() {
    const temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      data: logisData.secData,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/getInvDataLoInOut",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response1) => {
        const selectInvDataList = [];
        const invSoAllId = [];
        const tempInvData = response1.data;

        for (let item1 of tempInvData) {
          const productData = [];
          for (let item2 of item1.product) {
            productData.push({
              productId: item2.ip_product_id,
              barcodeId: item2.ip_pb_id,
            });
          }

          invSoAllId.push({
            inv_id: item1.inv_id,
            inv_so_id: item1.inv_so_id,
            product: productData,
          });

          item1.check = false;
          item1.oldNew = "new";
          item1.select = false;
          item1.some = false;
          item1.cancel = false;
          item1.disabled = false;

          // if (mode === "edit" || mode === "view") {
          //   for (let item2 of logisData.secData) {
          //     if (item1.inv_id === item2.li_inv_id) {
          //       item1.check = true;
          //       item1.oldNew = "old";

          //       selectInvDataList.push(item1);
          //     }
          //   }
          // }
        }

        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/bill/getRouteProductInv",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: invSoAllId,
        })
          .then(async (response2) => {
            for (let item1 of tempInvData) {
              const dataFilter2 = response2.data.filter((item2) => {
                return item1.inv_id === item2.inv_id;
              })[0];

              item1["route"] = dataFilter2?.invRouteData;

              let item1Product = item1?.product;

              for (let index = 0; index < item1Product.length; index++) {
                const indexP = dataFilter2?.productData.findIndex(
                  (initem) =>
                    initem.product_id === item1Product[index].ip_product_id &&
                    initem.pb_id === item1Product[index].ip_pb_id
                );

                item1Product[index] = {
                  ...item1Product[index],
                  ...dataFilter2?.productData[indexP],
                  recOrder: "",
                  remark: "",
                  return: "",
                };
              }

              let data = new FormData();
              data.append("company_id", getUser().com);
              data.append("oem_id", getOem());
              data.append("user_id", getUser().fup);
              data.append("products", JSON.stringify(item1Product));

              await axios({
                method: "post",
                url:
                  Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "multipart/form-data",
                },
                data: data,
              })
                .then(async (response) => {
                  if (response.data) {
                    item1Product = response.data;
                  }
                })
                .catch(async (error) => {
                  Swal.fire({
                    icon: "error",
                    title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
                  });
                });

              item1["product"] = item1Product;

              if (mode === "edit" || mode === "view") {
                for (let item2 of logisData.secData) {
                  if (item1.inv_id === item2.li_inv_id) {
                    item1.check = true;
                    item1.oldNew = "old";
                    item1.li_id = item2.li_id;

                    selectInvDataList.push(item1);
                  }
                }
              }
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });

        setSelectInvData({
          ...selectInvData,
          data: selectInvDataList,
        });

        selectInvData.data = selectInvDataList;

        setInvData({
          data: tempInvData,
        });

        invData.data = tempInvData;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  // async function GetProLogExpDate() {
  //   await axios({
  //     method: "get",
  //     url:
  //       Configs.API_URL_cuteplus +
  //       `/api/log_product/GetProLogExpDate/${getUser().com}/${getOem()}`,
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       setExpLog({
  //         data: response.data,
  //       });
  //     })
  //     .catch((error) => {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.response.data.error.message,
  //         icon: "error",
  //       });
  //     });
  // }

  // const cancelInvoice = async () => {
  //   Swal.fire({
  //     title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ Invoice นี้ใช่หรือไม่",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     cancelButtonText: `ไม่`,
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       const temp = {
  //         company_id: getUser().com,
  //         oem_id: getOem(),
  //         user_id: getUser().fup,
  //         user_name: getUser().name + " " + getUser().surname,
  //         inv_id: "",
  //       };
  //       await axios({
  //         method: "post",
  //         url: Configs.API_URL_cuteplus_sales + `/api/invoice/cancelInvoice`,
  //         headers: {
  //           Authorization: "Bearer " + getToken(),
  //           "X-TTT": Configs.API_TTT,
  //           "Content-Type": "application/json",
  //         },
  //         data: temp,
  //       })
  //         .then(async function (response) {
  //           if (response.data.length > 0) {
  //             await updateLogProductInvoice(response.data[0]);
  //           }
  //         })
  //         .catch(function (error) {
  //           Swal.fire({
  //             title: "Error",
  //             text: error.response.data.error.message,
  //             icon: "error",
  //           });
  //         });
  //     }
  //   });
  // };

  // const updateLogProductInvoice = async (invoice) => {
  //   invoice.oem_id = getOem();
  //   invoice.user_id = getUser().fup;
  //   invoice.company_id = getUser().com;
  //   invoice.user_name = getUser().name + " " + getUser().surname;

  //   await axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_cuteplus +
  //       `/api/log_product/updateCancelLogOfInvoiceLIO`,
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: invoice,
  //   })
  //     .then(async function (response) {})
  //     .catch(function (error) {
  //       Swal.fire({
  //         title: "Error",
  //         text: error.response.data.error.message,
  //         icon: "error",
  //       });
  //     });
  // };

  const rowsData1 = [];
  for (let index = 0; index < selectInvData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["select"] = (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5px" }}
      >
        <input
          style={{ width: "18px", aspectRatio: "1/1" }}
          type="checkbox"
          key={selectInvData.data[index].inv_id}
          id={selectInvData.data[index].inv_id}
          disabled={
            (selectInvData.data[index].some ||
              selectInvData.data[index].cancel) &&
            selectInvData.data[index].disabled
          }
          checked={
            !selectInvData.data[index].some &&
            !selectInvData.data[index].cancel &&
            selectInvData.data[index].select
          }
          onClick={async (e) => {
            const tempData = invData.data;

            const findIndex = tempData.findIndex(
              (item) => item.inv_id === selectInvData.data[index].inv_id
            );

            if (findIndex >= 0) {
              tempData[findIndex].select = !tempData[findIndex].select;
              tempData[findIndex].disabled = !tempData[findIndex].disabled;
            }

            setInvData({
              data: tempData,
            });

            invData.data = tempData;
          }}
        />
      </div>
    );

    rowItem["invoice_no"] = selectInvData.data[index].inv_no;

    rowItem["customer_name"] = selectInvData.data[index].inv_cus_name;

    rowItem["contact_name"] = selectInvData.data[index].inv_contact_name;

    rowItem["ship_to"] = selectInvData.data[index].inv_ship_name;

    rowItem[
      "ship_address"
    ] = `${selectInvData.data[index].inv_ship_house_number}
      ${selectInvData.data[index].inv_ship_building}
      ${selectInvData.data[index].inv_ship_swine}
      ${selectInvData.data[index].inv_ship_soi}
      ${selectInvData.data[index].inv_ship_road}
      ${selectInvData.data[index].inv_ship_sub_district}
      ${selectInvData.data[index].inv_ship_district}
      ${selectInvData.data[index].inv_ship_province}
      ${selectInvData.data[index].inv_ship_postcode}`;

    rowItem["route"] = selectInvData.data[index].route[0].route_name;

    rowItem["m3"] = Number(selectInvData.data[index].inv_total_m3 || 0).toFixed(
      2
    );

    rowItem["status"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          textAlign: "center",
          justifyContent: "center",
          minWidth: "240px",
        }}
      >
        <div className="col-6">
          <button
            key={selectInvData.data[index].inv_id}
            id={selectInvData.data[index].inv_id}
            type="button"
            className="btn btn-block btn-warning"
            disabled={selectInvData.data[index].disabled}
            // style={{ backgroundColor: "#FFFF00" }}
            onClick={async (e) => {
              setModalInvData({
                data: selectInvData.data[index],
              });
              setStockInModal(true);
            }}
          >
            รับบางส่วน
          </button>
        </div>
        <div className="col-6">
          <button
            key={selectInvData.data[index].inv_id}
            id={selectInvData.data[index].inv_id}
            type="button"
            className="btn btn-block btn-danger"
            disabled={selectInvData.data[index].disabled}
            onClick={async (e) => {
              Swal.fire({
                title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ Invoice นี้ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const tempData = invData.data;

                  const findIndex = tempData.findIndex(
                    (item) => item.inv_id === selectInvData.data[index].inv_id
                  );

                  if (findIndex >= 0) {
                    tempData[findIndex].select = !tempData[findIndex].select;
                    tempData[findIndex].cancel = !tempData[findIndex].cancel;
                    tempData[findIndex].disabled =
                      !tempData[findIndex].disabled;
                  }

                  setInvData({
                    data: tempData,
                  });

                  invData.data = tempData;
                }
              });
            }}
          >
            ยกเลิก
          </button>
        </div>
      </div>
    );

    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          key={selectInvData.data[index].inv_id}
          id={selectInvData.data[index].inv_id}
          className=" btn btn-xs "
          onClick={async () => {
            const findIndex = invData.data.findIndex(
              (item) => item.inv_id === selectInvData.data[index].inv_id
            );

            if (findIndex >= 0) {
              invData.data[findIndex].check = false;
              invData.data[findIndex].status = "del";
            }

            await saveInvData();
          }}
        >
          <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
        </a>
      </div>
    );

    rowsData1.push(rowItem);
  }

  const logisticInOutTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Select",
        field: "select",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Invoice No.",
        field: "invoice_no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Customer Name",
        field: "customer_name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "ผู้ติดต่อ",
        field: "contact_name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Ship to",
        field: "ship_to",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Ship Address",
        field: "ship_address",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Route",
        field: "route",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "M3",
        field: "m3",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };

  if (modeInOut) {
    const index1 = logisticInOutTable.columns.findIndex((e) => {
      return e.field === "select";
    });
    logisticInOutTable.columns.splice(index1, 1);

    const index2 = logisticInOutTable.columns.findIndex((e) => {
      return e.field === "status";
    });
    logisticInOutTable.columns.splice(index2, 1);
  } else {
    const index = logisticInOutTable.columns.findIndex((e) => {
      return e.field === "management";
    });
    logisticInOutTable.columns.splice(index, 1);
  }

  const rowsData2 = [];
  for (let index = 0; index < invData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["invoice_no"] = invData.data[index].inv_no;

    rowItem["management"] =
      invData.data[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            onClick={() => {
              let tempData = invData.data;

              tempData[index].check = true;
              if (tempData[index].statusUpdate) {
                tempData[index].status = "update";
              } else {
                tempData[index].status = "add";
              }

              setInvData({
                data: tempData,
              });
              invData.data = tempData;
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            onClick={() => {
              let tempData = invData.data;

              tempData[index].check = false;
              tempData[index].status = "del";

              setInvData({
                data: tempData,
              });
              invData.data = tempData;
            }}
          >
            ยกเลิก
          </button>
        </div>
      );

    rowsData2.push(rowItem);
  }

  const invTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Invoice No.",
        field: "invoice_no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData2,
  };

  async function saveInvData() {
    const tempData = invData.data;

    for (let item of tempData) {
      if (item.status === "del") {
        item.select = false;
      }
    }

    setInvData({
      data: tempData,
    });

    invData.data = tempData;

    const findCheckTrue = invData.data.filter((item) => {
      return item.check === true;
    });

    const addNew = invData.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = invData.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = invData.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setSelectInvData({
      ...selectInvData,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    selectInvData.data = findCheckTrue;
    selectInvData.newData = addNew;
    selectInvData.updateData = updateOld;
    selectInvData.delData = delOld;
  }

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const temp = {
          user_id: getUser().fup,
          user_name: `${getUser().name} ${getUser().surname}`,
          company_id: getUser().com,
          oem_id: getOem(),
          logistic_id: id,
          remark: inputData.remark,
          selectData: selectInvData,
        };

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then(async () => {
          await axios({
            method: "post",
            url: Configs.API_URL_cuteplus + "/api/logistic/saveUpdateLogistic",
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(async (response) => {
              await axios({
                method: "post",
                url:
                  Configs.API_URL_cuteplus_sales +
                  "/api/invoice/updateInvDataLoInOut",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(async (response) => {
                  const cancelData = selectInvData.data.filter((item) => {
                    return item.cancel;
                  });

                  for (let item of cancelData) {
                    const temp1 = {
                      company_id: getUser().com,
                      oem_id: getOem(),
                      user_id: getUser().fup,
                      user_name: getUser().name + " " + getUser().surname,
                      inv_id: item.inv_id,
                      inv_no: item.inv_no,
                    };

                    await axios({
                      method: "post",
                      url:
                        Configs.API_URL_cuteplus_sales +
                        `/api/invoice/cancelInvoiceLIO`,
                      headers: {
                        Authorization: "Bearer " + getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                      },
                      data: temp1,
                    })
                      .then(async function (response) {
                        // await axios({
                        //   method: "post",
                        //   url:
                        //     Configs.API_URL_cuteplus +
                        //     `/api/log_product/updateCancelLogOfInvoiceLIO`,
                        //   headers: {
                        //     Authorization: "Bearer " + getToken(),
                        //     "X-TTT": Configs.API_TTT,
                        //     "Content-Type": "application/json",
                        //   },
                        //   data: temp1,
                        // })
                        //   .then(async function (response) {})
                        //   .catch(function (error) {
                        //     Swal.fire({
                        //       title: "Error",
                        //       text: error.response.data.error.message,
                        //       icon: "error",
                        //     });
                        //   });
                      })
                      .catch(function (error) {
                        Swal.fire({
                          title: "Error",
                          text: error.response.data.error.message,
                          icon: "error",
                        });
                      });
                  }

                  const someData = selectInvData.data.filter((item) => {
                    return item.some;
                  });

                  await axios({
                    method: "post",
                    url:
                      Configs.API_URL_cuteplus +
                      "/api/log_product/returnLogOfInvoiceLIO",
                    headers: {
                      Authorization: "Bearer " + getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "application/json",
                    },
                    data: {
                      company_id: getUser().com,
                      oem_id: getOem(),
                      user_id: getUser().fup,
                      user_name: getUser().name + " " + getUser().surname,
                      data: someData,
                    },
                  })
                    .then(async (response) => {})
                    .catch((error) => {
                      Swal.fire({
                        title: "Error",
                        text: error.response.data.error.message,
                        icon: "error",
                      });
                    });
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Error",
                    text: error.response.data.error.message,
                    icon: "error",
                  });
                });
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });

          await Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลสำเร็จ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            window.location.href =
              "/Warehouse_Management/Logistic_Car_IN_OUT_Cuteplus";
          });
        });
      }
      // }
    });
  }

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
            "/Warehouse_Management/Logistic_Car_IN_OUT_Cuteplus";
        }
      });
    } else {
      window.location.href =
        "/Warehouse_Management/Logistic_Car_IN_OUT_Cuteplus";
    }
  }

  function rowTable(index) {
    const rowItem = {};

    rowItem["product_code"] = (
      <div style={{ textAlign: "center" }}>
        {modalInvData.data.product[index]?.product_code}
      </div>
    );
    rowItem["product_code2"] = modalInvData.data.product[index]?.product_code;

    rowItem["barcode"] = (
      <div style={{ textAlign: "center" }}>
        {modalInvData.data.product[index]?.ip_cus_barcode || "-"}
      </div>
    );
    rowItem["barcode2"] =
      modalInvData.data.product[index]?.ip_cus_barcode || "-";

    rowItem["product_name"] = (
      <div style={{ textAlign: "center" }}>
        {modalInvData.data.product[index]?.ip_product_name_th}
      </div>
    );
    rowItem["product_name2"] =
      modalInvData.data.product[index]?.ip_product_name_th;

    rowItem["product_image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            modalInvData.data.product[index]?.pci_img_path !== "" &&
            modalInvData.data.product[index]?.pci_img_path !== null &&
            modalInvData.data.product[index]?.pci_img_path !== undefined
              ? modalInvData.data.product[index]?.pci_img_path?.indexOf(
                  "blob"
                ) >= 0
                ? modalInvData.data.product[index]?.pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${modalInvData.data.product[index]?.pci_img_name}/${modalInvData.data.product[index]?.pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["quantity"] = (
      <div style={{ textAlign: "center" }}>
        {modalInvData.data.product[index]?.ip_quantity +
          " " +
          modalInvData.data.product[index]?.ip_quantity_unit}
      </div>
    );

    rowItem["receive_order"] = (
      <div
        className="form-group"
        style={{ textAlign: "center", minWidth: "100px" }}
      >
        <NumberFormat
          type="text"
          className="form-control"
          thousandSeparator={true}
          allowNegative={false}
          thousandsGroupStyle="thousand"
          decimalScale={0}
          // hidden={StockInSRNo.Product[index].pop_is_receive}
          value={modalInvData.data.product[index]?.recOrder}
          onChange={(e) => {
            let temp = modalInvData.data;

            temp.product[index].recOrder = numberWithOutCommas(e.target.value);

            if (
              Number(temp.product[index].recOrder) >=
              Number(modalInvData.data.product[index]?.ip_quantity)
            ) {
              temp.product[index].ip_exp_date = "";
            }

            setModalInvData({
              ...modalInvData,
              data: temp,
            });
            modalInvData.data = temp;
          }}
        />
      </div>
    );

    // const selectOption = expLog.data.filter((item) => {
    //   return (
    //     item.product_id === modalInvData.data.product[index]?.ip_product_id
    //   );
    // });

    rowItem["exp_date"] = (
      <div
        className="form-group"
        style={{ textAlign: "center", minWidth: "200px" }}
      >
        {/* <select
          type="text"
          className="form-control"
          required
          value={modalInvData.data.product[index]?.exp_date}
          onChange={(e) => {
            let temp = modalInvData.data;

            temp.product[index].exp_date = e.target.value;

            setModalInvData({
              ...modalInvData,
              data: temp,
            });
            modalInvData.data = temp;
          }}
        >
          <option key="" value="">
            --- เลือก ---
          </option>
          {selectOption[0]?.exp_date?.map((option, indexSelect) => (
            <option
              key={moment(
                selectOption[0]?.exp_date[indexSelect] || new Date(),
                "YYYY-MM-DD"
              )
                .add(543, "year")
                .format("DD/MM/YY")}
              value={moment(
                selectOption[0]?.exp_date[indexSelect] || new Date(),
                "YYYY-MM-DD"
              )
                .add(543, "year")
                .format("DD/MM/YY")}
            >
              {moment(
                selectOption[0]?.exp_date[indexSelect] || new Date(),
                "YYYY-MM-DD"
              )
                .add(543, "year")
                .format("DD/MM/YY")}
            </option>
          ))}
        </select> */}
        <DatePicker
          required
          selected={modalInvData.data.product[index]?.ip_exp_date}
          locale="th"
          popperClassName="zI-99"
          wrapperClassName="w-100"
          dateFormat={"dd-MM-yyyy"}
          onChange={(date) => {
            let temp = modalInvData.data;

            temp.product[index].ip_exp_date = date;

            setModalInvData({
              ...modalInvData,
              data: temp,
            });
            modalInvData.data = temp;
          }}
          selectsStart
          showYearDropdown
          showMonthDropdown
          // startDate={new Date().setDate(new Date().getDate() + 30)}
          // minDate={new Date()}
          customInput={<CustomInput />}
          disabled={
            Number(modalInvData.data.product[index]?.recOrder) >=
            Number(modalInvData.data.product[index]?.ip_quantity)
          }
        />
      </div>
    );

    rowItem["remark"] = (
      <div
        className="form-group"
        style={{ textAlign: "center", minWidth: "200px" }}
      >
        <input
          type="text"
          className="form-control"
          required
          value={modalInvData.data.product[index]?.remark}
          onChange={(e) => {
            let temp = modalInvData.data;

            temp.product[index].remark = e.target.value;

            setModalInvData({
              ...modalInvData,
              data: temp,
            });
            modalInvData.data = temp;
          }}
        />
      </div>
    );

    return rowItem;
  }

  const rowsData3 = [];
  for (let index = 0; index < modalInvData.data.product?.length; index += 1) {
    rowsData3.push(rowTable(index));
  }

  const StockInTable = {
    columns: [
      {
        label: "Product Code",
        field: "product_code",
        width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        width: 50,
      },
      {
        label: "Product Image",
        field: "product_image",
        width: 50,
      },
      {
        label: "Quantity",
        field: "quantity",
        width: 50,
      },
      {
        label: "Receive Order",
        field: "receive_order",
        width: 50,
      },
      {
        label: "Exp Date",
        field: "exp_date",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        width: 50,
      },
    ],
    rows: rowsData3,
  };

  useEffect(async () => {
    await GetLogisticInOutData();
    await GetInvDataLoInOut();
    // await GetProLogExpDate();
  }, {});

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Logistic In/Out</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Logistic In/Out</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={async () => {
                      await saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 style={{ margin: "0px" }}>Logistic In/Out</h4>
                  </div>
                  <div className="card-body">
                    <div
                      className="row"
                      style={{ justifyContent: "space-around" }}
                    >
                      <div className="col-12 col-md-5">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={logisData.mainData[0]?.lgt_name}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Logistic Name
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-5">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={Number(
                              logisData.mainData[0]?.lgt_m3 || 0
                            ).toFixed(2)}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Logistic Car M3
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{ justifyContent: "space-around" }}
                    >
                      <div className="col-auto row">
                        <div className="col-auto">
                          <input
                            type="radio"
                            id="inbound"
                            name="edit_type"
                            value={true}
                            checked={
                              // dataForSave.lp_edit_amount != true ? false : true
                              modeInOut !== true ? false : true
                            }
                            onChange={(e) => {
                              setModeInOut(true);
                            }}
                          />
                        </div>
                        <div className="col-auto">
                          <label htmlFor="inbound">
                            <h4>Logistic In</h4>
                          </label>
                        </div>
                      </div>

                      <div className="col-auto row">
                        <div className="col-auto">
                          <input
                            type="radio"
                            id="outbound"
                            name="edit_type"
                            value={false}
                            checked={
                              // dataForSave.lp_edit_amount != false ? false : true
                              modeInOut !== false ? false : true
                            }
                            onChange={(e) => {
                              setModeInOut(false);
                            }}
                          />
                        </div>
                        <div className="col-auto">
                          <label htmlFor="outbound">
                            <h4>Logistic Out</h4>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div
                        style={{
                          width: "100%",
                          border: "1px solid rgba(0,0,0,.125)",
                        }}
                      />
                    </div>

                    {modeInOut ? (
                      <div className="row mb-3">
                        <div className="col-6 col-md-3 col-xl-2">
                          <button
                            type="button"
                            class="btn btn-block btn-primary"
                            disabled={false}
                            onClick={async () => {
                              const tempSelectInv = selectInvData.data;
                              const tempInvTable = invData.data;

                              const tempList = tempInvTable.map((item1) => {
                                const check = tempSelectInv.find(
                                  (item2) => item2.inv_id === item1.inv_id
                                );

                                if (check) {
                                  item1.check = true;
                                  return item1;
                                } else {
                                  item1.check = false;
                                  return item1;
                                }
                              });

                              setInvData({ data: tempList });
                              invData.data = tempList;

                              setModalOC(true);
                            }}
                          >
                            Add Invoice
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="row mb-3">
                      <div
                        className="col-md-12"
                        style={{ overflow: "overlay" }}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={logisticInOutTable}
                          />
                        </div>
                      </div>
                    </div>

                    {modeInOut ? (
                      <div
                        className="row"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <div
                          className="col-12 col-md-5 row"
                          style={{ alignItems: "baseline" }}
                        >
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                              marginLeft: "20px",
                              marginRight: "10px",
                            }}
                          >
                            Total M3
                          </label>
                          <div className="col">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={selectInvData.data
                                  .reduce((first, second) => {
                                    return (first += Number(
                                      second.inv_total_m3 || 0
                                    ));
                                  }, 0)
                                  .toFixed(2)}
                              />
                            </div>
                          </div>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                              marginLeft: "10px",
                              marginRight: "20px",
                            }}
                          >
                            CBM
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div class="form-group">
                            <textarea
                              type="text"
                              className="form-control"
                              required
                              disabled={false}
                              value={inputData.remark}
                              onChange={(e) => {
                                setInputData({
                                  ...inputData,
                                  remark: e.target.value,
                                });

                                inputData.remark = e.target.value;
                              }}
                            />
                            <label
                              style={{
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Remark
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={modalOC}
        toggle={() => {
          setModalOC(false);
        }}
        size="md"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModalOC(false);
          }}
        >
          <h3>Select Invoice</h3>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-md-12" style={{ overflow: "overlay" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={invTable}
                />
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="btn btn-success"
            onClick={async () => {
              setModalOC(false);

              await saveInvData();
            }}
          >
            บันทึก
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setModalOC(false);
            }}
          >
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={StockInModal}
        // toggle={() => {
        //   setStockInModal(false);
        // }}
        size="xl"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader>
          <div className="row">
            <div className="col-12">
              <h5>Logistic Out</h5>
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={modalInvData.data.inv_no}
                ></input>
                <label>Invoice No.</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12" style={{ overflow: "overlay" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  disableRetreatAfterSorting={true}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={StockInTable}
                />
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter style={{ padding: "0 2rem", display: "block" }}>
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-4 col-md-3 col-xl-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-danger"
                  onClick={async (e) => {
                    Swal.fire({
                      title:
                        "คุณต้องการยกเลิกรับบางส่วนของ Invoice นี้ใช่หรือไม่",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: `ใช่`,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      cancelButtonText: `ไม่`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        setStockInModal(false);
                      }
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>

            <div className="col-4 col-md-3 col-xl-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={async (e) => {
                    Swal.fire({
                      title: "คุณต้องการรับบางส่วนของ Invoice นี้ใช่หรือไม่",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: `ใช่`,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      cancelButtonText: `ไม่`,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        let error_list = [];
                        let countData = 0;

                        for (let item of modalInvData.data.product) {
                          if (item.recOrder === "") {
                            countData += 1;
                          }

                          if (
                            Number(item.recOrder) > Number(item.ip_quantity)
                          ) {
                            let temp_err = {
                              message: `${item.product_code} ใส่จำนวนที่รับ มากกว่า จำนวนที่มีอยู่`,
                            };
                            error_list.push(temp_err);
                          }

                          if (
                            Number(item.recOrder) < Number(item.ip_quantity) &&
                            (item.ip_exp_date === null ||
                              item.ip_exp_date === "")
                          ) {
                            let temp_err = {
                              message: `กรุณาใส่ Exp Date ที่ ${item.product_code}`,
                            };
                            error_list.push(temp_err);
                          }
                        }

                        if (countData == modalInvData.data.product.length) {
                          error_list = [
                            {
                              message: `กรุณาใส่จำนวนที่รับอย่างน้อย 1 รายการ`,
                            },
                          ];
                        }

                        if (error_list.length > 0) {
                          var err_message = "";

                          for (var e = 0; e < error_list.length; e++) {
                            err_message += "<br/>" + error_list[e].message;
                          }

                          Swal.fire("Error", err_message, "error");
                        } else {
                          for (
                            let index = 0;
                            index < modalInvData.data.product?.length;
                            index += 1
                          ) {
                            if (
                              modalInvData.data.product[
                                index
                              ].ip_quantity_unit.toLowerCase() === "pack"
                            ) {
                              modalInvData.data.product[index].return =
                                (Number(
                                  modalInvData.data.product[index].ip_quantity
                                ) -
                                  Number(
                                    modalInvData.data.product[index].recOrder ||
                                      0
                                  )) *
                                Number(
                                  modalInvData.data.product[index]
                                    .pd_sale_pc_pack
                                );
                            } else if (
                              modalInvData.data.product[
                                index
                              ].ip_quantity_unit.toLowerCase() === "ctn"
                            ) {
                              modalInvData.data.product[index].return =
                                (Number(
                                  modalInvData.data.product[index].ip_quantity
                                ) -
                                  Number(
                                    modalInvData.data.product[index].recOrder ||
                                      0
                                  )) *
                                Number(
                                  modalInvData.data.product[index]
                                    .pd_sale_pc_ctn
                                );
                            } else {
                              modalInvData.data.product[index].return =
                                Number(
                                  modalInvData.data.product[index].ip_quantity
                                ) -
                                Number(
                                  modalInvData.data.product[index].recOrder || 0
                                );
                            }
                          }

                          const tempData = invData.data;

                          const findIndex = tempData.findIndex(
                            (item) => item.inv_id === modalInvData.data.inv_id
                          );

                          if (findIndex >= 0) {
                            tempData[findIndex].select =
                              !tempData[findIndex].select;
                            tempData[findIndex].some =
                              !tempData[findIndex].some;
                            tempData[findIndex].disabled =
                              !tempData[findIndex].disabled;
                            tempData[findIndex].product =
                              modalInvData.data.product;
                          }

                          setInvData({
                            data: tempData,
                          });

                          invData.data = tempData;

                          setStockInModal(false);
                        }
                      }
                    });
                  }}
                >
                  เสร็จสิ้น
                </button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Logistic_Car_AEV;
