import React, { useEffect, useState } from "react";
import Construccomponent from "../../component/Construction";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import {
  getUser,
  getToken,
  setOemlist,
  getOem,
  getFeature,
} from "../../Utils/Common";
import Configs from "../../config";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
function DeleveryRecord(params) {
  const [color, setcolor] = useState([]);

  console.log(color);

  var config_color = {
    method: "get",
    url: Configs.API_URL + "/product/getProductColor?id=" + getOem(),
    headers: {
      "X-TTT": Configs.API_TTT,
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
  };
  const Getcolor = async () => {
    await axios(config_color)
      .then(function (response) {
        response.data.push({
          id: null,
          name: "default",
        });
        setcolor(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  /* const oem_id = getOem(); */
  const [deliveryRecord, setDeliveryRecord] = useState([]);
  const [oem_id, setOem_id] = useState({
    oem_id: getOem(),
  });
  const [show, setShow] = useState({
    show: 10,
  });
  const [search_table, setSearch_table] = useState({
    key: "",
  });
  const [filler_deliver_record, setFiller_deliver_record] = useState({
    start_date: "",
    end_date: "",
    product_no: "",
    color: "",
  });

  function GetDeliveryRecord(params) {
    let temp = {
      oem_id: oem_id.oem_id,
      start_date: filler_deliver_record.start_date,
      end_date: filler_deliver_record.end_date,
      product_no: filler_deliver_record.product_no,
      color: filler_deliver_record.color,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/history/getDeliveryHistory",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
        console.log(response.data);
        setDeliveryRecord(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const [feature, setfeature] = useState([]);

  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Delivery Record";
    });
    console.log(feature_data);
    setfeature(feature_data);
    GetDeliveryRecord();
    Getcolor();
  }, []);

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const rowsData = [];

  var sum_sell_price = 0;
  var sum_buy_price = 0;
  for (let index = 0; index < deliveryRecord.length; index++) {
    const element = {};
    sum_sell_price +=
      (deliveryRecord[index].sell_price || 0) * deliveryRecord[index].amount;
    sum_buy_price +=
      (deliveryRecord[index].buy_price || 0) * deliveryRecord[index].amount;
    element["no"] = index + 1;
    element["user_id"] = deliveryRecord[index].user_id;
    element["product_id"] = deliveryRecord[index].product_id;
    element["product_no"] = deliveryRecord[index].no;
    element["product_name"] = deliveryRecord[index].product_name;
    element["name"] =
      deliveryRecord[index].name + " " + deliveryRecord[index].sirname;
    /*  element["sirname"]= deliveryRecord[index].sirname; */
    element["edit_type"] =
      deliveryRecord[index].edit_type === false ? "Delivered" : "None";
    element["amount"] = deliveryRecord[index].amount;
    /*   element[""]= deliveryRecord[index].; */
    element["sell_price"] = numberWithCommas(
      deliveryRecord[index].sell_price || 0
    );
    element["buy_price"] = numberWithCommas(
      deliveryRecord[index].buy_price || 0
    );
    element["total_sell_price"] = numberWithCommas(
      (deliveryRecord[index].sell_price || 0) * deliveryRecord[index].amount
    );
    element["create_date"] = moment(deliveryRecord[index].create_date).format(
      "DD/MM/YYYY, HH:mm น."
    );
    element["username"] = deliveryRecord[index].username;
    element["remark"] = deliveryRecord[index].remark;
    let color = null;
    if (deliveryRecord[index].color === "blue") {
      color = "#90CBF9";
    } else if (deliveryRecord[index].color === "red") {
      color = "#FFB1AF";
    } else if (deliveryRecord[index].color === "yellow") {
      color = "#FFEEA5";
    } else if (deliveryRecord[index].color === "green") {
      color = "#C5E8B4";
    } else {
      color = null;
    }
    element["color"] = color;

    rowsData.push(element);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product_No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product_Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "edit_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Sell price",
        field: "sell_price",
        sort: "asc",
        width: 50,
      },

      {
        label: "Total Sell price",
        field: "total_sell_price",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  var Name_feature = feature.filter((e) => {
    return e.feature === "Name";
  });
  var SellPrice_feature = feature.filter((e) => {
    return e.feature === "Sell_price";
  });
  var Color_feature = feature.filter((e) => {
    return e.feature === "Color";
  });
  var SalseReport_feature = feature.filter((e) => {
    return e.feature === "Sales_Report";
  });
  async function SetDate_start(date) {
    setFiller_deliver_record({ ...filler_deliver_record, start_date: date });
  }
  async function SetDate_end(date) {
    setFiller_deliver_record({ ...filler_deliver_record, end_date: date });
  }

  const clearFilter = async () => {
    await setFiller_deliver_record({
      ...filler_deliver_record,
      start_date: "",
      end_date: "",
      product_no: "",
      color: "",
    });

    filler_deliver_record.start_date = "";
    filler_deliver_record.end_date = "";
    filler_deliver_record.product_no = "";
    filler_deliver_record.color = "";
    GetDeliveryRecord();
  };

  /* function filterItems(arr, query) {
  return arr.filter(function(el) {
    return el.no.toLowerCase().indexOf(query.toLowerCase()) !== -1 || el.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 
  })
}

async function search_table_filter(key){
 
  setSearch_table({
    ...search_table,
    key: key,
  });
  const arr_search = filterItems(deliveryRecord,key);
  console.log("arr_search",arr_search)
  await setDeliveryRecord(arr_search);
} */
  //console.log(filterItems(rowsData, search_table.key))

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="pro_group" submenu="factmaster"/> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Delivery Record</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Delivery Record</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Delivery Record</h3>
            </div>
            <div className="card-body table-responsive ">
              <div className="row">
                <div class="col-1.5">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      value={filler_deliver_record.product_no.trim()}
                      onChange={(e) => {
                        setFiller_deliver_record({
                          ...filler_deliver_record,
                          product_no: e.target.value.trim(),
                        });
                      }}
                    />

                    <label htmlFor="">Product No</label>
                  </div>
                </div>
                <div className="col-md-0.5">
                  <label> Date &nbsp;</label>
                </div>

                <div className="col-md-1.5">
                  <div className="form-group ">
                    <DatePicker
                      selected={filler_deliver_record.start_date}
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => SetDate_start(date)}
                      selectsStart
                      startDate={filler_deliver_record.start_date}
                      endDate={filler_deliver_record.end_date}
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>

                <div className="col-md-0.5">
                  <label>&nbsp;&nbsp; Between &nbsp;&nbsp;</label>
                </div>
                <div className="col-md-1.5">
                  <div className="form-group ">
                    <DatePicker
                      selected={filler_deliver_record.end_date}
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => SetDate_end(date)}
                      selectsEnd
                      startDate={filler_deliver_record.start_date}
                      endDate={filler_deliver_record.end_date}
                      minDate={filler_deliver_record.start_date}
                      customInput={<CustomInput />}
                    ></DatePicker>
                  </div>
                </div>
                {Color_feature.length === 1 ? (
                  <>
                    <div className="col-2">
                      <div className="form-group">
                        <select
                          className="custom-select"
                          value={filler_deliver_record.color}
                          //disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setFiller_deliver_record({
                              ...filler_deliver_record,
                              color: e.target.value,
                            })
                          }
                        >
                          <option value="" selected disabled>
                            select Color
                          </option>
                          {color.map((el) => {
                            return (
                              <option key={el.id} value={el.name}>
                                {el.name}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">Color</label>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="row">
                <div className="col-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                      onClick={() => {
                        GetDeliveryRecord();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                      onClick={clearFilter}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                {SalseReport_feature.length === 1 ? (
                  <>
                    <div className="col-8 col-md-3 col-xl-2">
                      <div className="form-group ">
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-block btn-primary"
                          table="table-to-xls"
                          filename={"รายงานสรุปยอดขาย"}
                          sheet="tablexls"
                          buttonText="รายงายสรุปยอดขาย"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* <div class="col-1">
              <div class="form-group">
              <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={search_table.key}
                    onChange={(e) => {
                      search_table_filter(e.target.value);
                    }} 
                  />
                 
                 
             
                    <label htmlFor="">
                    Search
                   
                    </label>
                  </div>
                  </div> */}
              </div>

              <div className="table-responsive" style={{ display: "none" }}>
                <table
                  style={{ border: "1px solid #DCDCDC", width: "100%" }}
                  id="table-to-xls"
                >
                  <tr>
                    <th
                      colSpan="9"
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#CCFFFF",
                      }}
                    >
                      {filler_deliver_record.start_date === "" ? (
                        <>รายงานสรุปยอดขายทั้งหมด</>
                      ) : (
                        <>
                          รายงานสรุปยอดขายวันที่{" "}
                          {moment(
                            filler_deliver_record.start_date || new Date()
                          ).format("DD/MM/YYYY")}{" "}
                          {filler_deliver_record.end_date !== ""
                            ? " - " +
                              moment(filler_deliver_record.end_date).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </>
                      )}
                    </th>
                  </tr>
                  <tr>
                    <th
                      colSpan="3"
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#99FFCC",
                      }}
                    >
                      ยอดขายรวม {numberWithCommas(sum_sell_price.toFixed(2))}{" "}
                      บาท
                    </th>
                    <th
                      colSpan="3"
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#99FFCC",
                      }}
                    >
                      ต้นทุนรวม {numberWithCommas(sum_buy_price.toFixed(2))} บาท
                    </th>
                    <th
                      colSpan="3"
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#99FFCC",
                      }}
                    >
                      กำไรสุทธิ{" "}
                      {numberWithCommas(
                        (sum_sell_price - sum_buy_price).toFixed(2)
                      )}{" "}
                      บาท
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Product No
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Product Name
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Amount
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Buy price
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Sell price
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Date
                    </th>
                    <th
                      style={{
                        border: "1px solid #DCDCDC",
                        textAlign: "center",
                        background: "#B0C4DE",
                      }}
                    >
                      Remark
                    </th>
                  </tr>
                  {rowsData.map((el, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              textAlign: "center",
                              background: "#F0FFFF",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              background: "#F0FFFF",
                            }}
                          >
                            {el.product_no}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              background: "#F0FFFF",
                            }}
                          >
                            {el.product_name}
                          </td>

                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              background: "#F0FFFF",
                            }}
                          >
                            {el.edit_type}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              textAlign: "right",
                              background: "#F0FFFF",
                            }}
                          >
                            {el.amount}
                          </td>

                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              textAlign: "right",
                              background: "#F0FFFF",
                            }}
                          >
                            {el.buy_price}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              textAlign: "right",
                              background: "#F0FFFF",
                            }}
                          >
                            {el.sell_price}
                          </td>

                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              textAlign: "center",
                              background: "#F0FFFF",
                            }}
                          >
                            {el.create_date}
                          </td>
                          <td
                            style={{
                              border: "1px solid #DCDCDC",
                              backgroundColor: el.color,
                              background: "#F0FFFF",
                            }}
                          >
                            {el.remark}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
              <div className="table-responsive" style={{ height: "650px" }}>
                <br />
                <div className="row">
                  <div class="col-4 col-md-3 col-xl-2">
                    <br /> <br />
                    <div class="form-group">
                      <select
                        className="form-control custom-select select2"
                        type="text"
                        required
                        //value={logistic.logistic_type}
                        //disabled={disable}
                        onChange={(e) => {
                          setShow({
                            ...show,
                            show: e.target.value,
                          });
                        }}
                      >
                        <option value={10}>10/{rowsData.length}</option>
                        <option value={20}>20/{rowsData.length}</option>
                        <option value={50}>50/{rowsData.length}</option>
                        <option value={100}>100/{rowsData.length}</option>
                        <option value={rowsData.length}>
                          {rowsData.length} (ALL)
                        </option>
                      </select>
                      <label htmlFor="">Show entries</label>
                    </div>
                  </div>
                  <div class="col-2 col-md-4 col-xl-6"></div>
                  {SellPrice_feature.length === 1 ? (
                    <>
                      <div
                        class="col-12 col-md-5 col-xl-4"
                        style={{ textAlign: "right" }}
                      >
                        <h3>
                          ยอดขายรวม{" "}
                          {numberWithCommas(sum_sell_price.toFixed(2))} บาท
                        </h3>
                        <h3>
                          ยอดทุนรวม {numberWithCommas(sum_buy_price.toFixed(2))}{" "}
                          บาท
                        </h3>
                        <h3>
                          กำไรสุทธิ{" "}
                          {numberWithCommas(
                            (sum_sell_price - sum_buy_price).toFixed(2)
                          )}{" "}
                          บาท
                        </h3>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <MDBTable striped hover bordered small searching={true}>
                  <MDBTableHead>
                    <tr>
                      <th style={{ textAlign: "center" }}>No</th>
                      <th style={{ textAlign: "center" }}>Product No</th>
                      <th style={{ textAlign: "center" }}>Product Name</th>
                      {Name_feature.length === 1 ? (
                        <th style={{ textAlign: "center" }}>Name</th>
                      ) : (
                        <></>
                      )}

                      <th style={{ textAlign: "center" }}>Status</th>
                      <th style={{ textAlign: "center" }}>Amount</th>
                      {SellPrice_feature.length === 1 ? (
                        <>
                          <th style={{ textAlign: "center" }}>Buy price</th>
                          <th style={{ textAlign: "center" }}>Sell price</th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th style={{ textAlign: "center" }}>Date</th>
                      <th style={{ textAlign: "center" }}>Username</th>
                      <th style={{ textAlign: "center" }}>Remark</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {rowsData.map((el, index) => {
                      if (index < show.show) {
                        return (
                          <tr key={index}>
                            <td
                              style={{
                                backgroundColor: el.color,
                                textAlign: "center",
                              }}
                            >
                              {index + 1}
                            </td>
                            <td style={{ backgroundColor: el.color }}>
                              {el.product_no}
                            </td>
                            <td style={{ backgroundColor: el.color }}>
                              {el.product_name}
                            </td>
                            {Name_feature.length === 1 ? (
                              <td style={{ backgroundColor: el.color }}>
                                {el.name}
                              </td>
                            ) : (
                              <></>
                            )}

                            <td style={{ backgroundColor: el.color }}>
                              {el.edit_type}
                            </td>
                            <td
                              style={{
                                backgroundColor: el.color,
                                textAlign: "right",
                              }}
                            >
                              {el.amount}
                            </td>
                            {SellPrice_feature.length === 1 ? (
                              <>
                                <td
                                  style={{
                                    backgroundColor: el.color,
                                    textAlign: "right",
                                  }}
                                >
                                  {el.buy_price}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: el.color,
                                    textAlign: "right",
                                  }}
                                >
                                  {el.sell_price}
                                </td>
                                {/*         <td  style={{ backgroundColor: el.color,textAlign:"right"  }}>{el.total_sell_price}</td> */}
                              </>
                            ) : (
                              <></>
                            )}
                            <td
                              style={{
                                backgroundColor: el.color,
                                textAlign: "center",
                              }}
                            >
                              {el.create_date}
                            </td>
                            <td style={{ backgroundColor: el.color }}>
                              {el.username}
                            </td>
                            <td style={{ backgroundColor: el.color }}>
                              {el.remark}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>

              {/* <MDBDataTable
                className="table table-head-fixed"
                striped
                bordered
                hover
                
                fixedHeader
                data={data}
              /> */}
            </div>
          </div>
        </section>
      </div>

      {/*   <Footter/> */}
    </div>
  );
}
export default DeleveryRecord;
