import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";

function Manage_team(params) {
  const [team, setteam] = useState([]);
  const [filterteam, setFilterTeam] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    team_name: "",
  });
  const GetAllteam = async () => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterTeamAll",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam,
    })
      .then(async function (response) {
        setteam(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  useEffect(() => {
    GetAllteam();
  }, []);

  const dataTeam = [];
  for (let index = 0; index < team.length; index++) {
    const element = {};
    element["no"] = index + 1;
    element["team_name"] = team[index].team_name;
    element["team_head"] = team[index].team_head || "ไม่พบ";
    element["mgt"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            href={"/Human_Resource/organization/manage_team/read/" + team[index].id}
            id="btn_read"
            key={team[index].id + "view"}
            className="btn btn-xs "
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            href={"/Human_Resource/organization/manage_team/edit/" + team[index].id}
            id="btn_edit"
            key={team[index].id + "edit"}
            className=" btn btn-xs "
          >
            {"   "}
            <i class="fas fa-pencil-alt"></i>
            {"   "}
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={team[index].id + "del"}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={deldata.bind(this, team[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    dataTeam.push(element);
  }

  function deldata(id) {
    Swal.fire({
      title: "คุณแน่ใจไหม?",
      text: "คุณจะไม่สามารถย้อนกลับได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่ ลบทิ้ง!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL_hrMagenatement +"/api/hrManagement/delTeamByID?id="+id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data.status === "success") {
              Swal.fire("ลบแล้ว!", "ข้อมูลของคุณถูกลบแล้ว", "เสร็จสิ้น").then(
                () => {
                  GetAllteam();
                }
              );
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "บางอย่างผิดพลาด: " + error, "error");
          });
      }
    });
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Team Name",
        field: "team_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Team Head",
        field: "team_head",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: dataTeam,
  };

  function clearFilter() {
    setFilterTeam({
        oem_id: getOem(),
        company_id: getUser().com,
        team_name: "",
    })
   filterteam.oem_id = getOem();
   filterteam.company_id = getUser().com;
   filterteam.team_name = "" ;

   GetAllteam();

  }

  function addpage(params) {
    window.location.href = "/Human_Resource/organization/manage_team/add";
  }
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Manage Team{"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Organization</li>
                  <li className="breadcrumb-item active">Manage Team</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterteam.team_name}
                    onChange={(e) => {
                      setFilterTeam({
                        ...filterteam,
                        team_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Team Name</label>{" "}
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetAllteam();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                       onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Manage Taem</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Manage_team;
