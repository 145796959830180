import React ,{useState} from "react";

import "./index.css";

const Tree = ({ data = [] }) => {
  return (
    <div className="d-tree">
      
        {data.map(tree=>(
          <TreeNode node = {tree} />

        ))}

      

    </div>
  )
}

const TreeNode = ({node}) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const hasChild = node.children ? true : false;

  return (
    <ul className="d-flex d-tree-node flex-column">
    <li className="d-tree-node border-0">
        <div ClassName="d-flex"  onClick = {e => setChildVisiblity(v => !v)}>

         

          <div className="col  d-tree-head" style = {{border: "1px solid black",borderRadius:"15px",textAlign:"left",paddingLeft: "15px",whiteSpace: "nowrap"}}>
       
          <font color="#808080" >{node.label1}</font>
          <br/>
          <font color="#000" >{node.label2}</font>
          <br/>
          <div className = "row">
            <div className = "col-6"><font color="#808080" >{"(" + node.label3 +")"}</font></div>
              <div className = "col-6" style = {{textAlign:"right"}}>
              {hasChild && (
            <div className ={`d-inline d-tree-toggler ${childVisible ? "active" : ""}`}>
      
      {childVisible ? (<i class="fa fa-arrow-down" aria-hidden="true"></i>):(<i class="fa fa-arrow-right" aria-hidden="true"></i>)}
      
      
           
            </div>
          )}
              </div>
          </div>

         
          
          </div>
        </div>
     

        {
          hasChild && childVisible && <div className="d-tree-content">
            <ul className="d-flex d-tree-container flex-column">
              <Tree data = {node.children}/>

            </ul>
          </div>
        }
    </li>
    </ul>
  )
}

export default Tree;
