import React, { useEffect, useState, useRef } from "react";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Select from "react-select";
import NumberFormat from "react-number-format";

function WarehouseMaster() {
  const CloseAddWarehouseModal = useRef();
  const clickCloseAddWarehouseModal = async () => {
    CloseAddWarehouseModal.current.click();
  };
  const [warehouseData, setWarehouseData] = useState([]);
  const [filterSearch, setFilterSearch] = useState({
    filter: "",
  });
  const [itemsData, setItemsData] = useState({
    warehouse_name: "",
    size_unit: "",
    warehouse_width: "",
    warehouse_depth: "",
    warehouse_height: "",
  });
  const [sizeUnitDD, setsizeUnitDD] = useState([]);

  const [disAftSort, setdisAftSort] = useState(false);

  const rowsData = [];
  for (let index = 0; index < warehouseData.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["items_name"] = (
      <div style={{ textAlign: "left" }}>
        {warehouseData[index].wh_name || "-"}
      </div>
    );
    rowItem["items_name2"] = warehouseData[index].wh_name;
    // rowItem["size_unit"] = (
    //   <div style={{ textAlign: "center" }}>
    //     {sizeUnitDD.find((el) => el.value === warehouseData[index].wh_unit_id)
    //       ?.label || "-"}
    //   </div>
    // );
    // rowItem["items_width"] = (
    //   <div style={{ textAlign: "center" }}>
    //     {warehouseData[index].wh_width || "-"}
    //   </div>
    // );
    // rowItem["items_height"] = (
    //   <div style={{ textAlign: "center" }}>
    //     {warehouseData[index].wh_height || "-"}
    //   </div>
    // );
    // rowItem["items_depth"] = (
    //   <div style={{ textAlign: "center" }}>
    //     {warehouseData[index].wh_depth || "-"}
    //   </div>
    // );

    rowItem["Number_of_Rack"] = (
      <div style={{ textAlign: "left" }}>
        {warehouseData[index].number_of_rack || "-"}
      </div>
    );

    rowItem["Number_of_Rack2"] = warehouseData[index].number_of_rack;

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "left" }}>
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={warehouseData[index].wh_id}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/IoT_Master_Data/Warehouse/View/" + warehouseData[index].wh_id;
            }}
          >
            <i
              class="fas fa-eye"
              style={{
                fontSize: "14px",
              }}
            ></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={warehouseData[index].wh_id}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/IoT_Master_Data/Warehouse/Edit/" + warehouseData[index].wh_id;
            }}
          >
            <i
              class="fas fa-pencil-alt"
              style={{
                fontSize: "14px",
              }}
            ></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={warehouseData[index].wh_id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              delWarehouse(warehouseData[index].wh_id);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const WarehouseTableColumns = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Warehouse Name"}</div>,
        field: "items_name",
        width: 50,
      },
      // {
      //   label: <div style={{ textAlign: "center" }}>{"Size Unit"}</div>,
      //   field: "size_unit",
      //   width: 50,
      // },
      // {
      //   label: <div style={{ textAlign: "center" }}>{"Width"}</div>,
      //   field: "items_width",
      //   width: 50,
      // },
      // {
      //   label: <div style={{ textAlign: "center" }}>{"Depth"}</div>,
      //   field: "items_depth",
      //   width: 50,
      // },
      // {
      //   label: <div style={{ textAlign: "center" }}>{"Height"}</div>,
      //   field: "items_height",
      //   width: 50,
      // },
      {
        label: <div style={{ textAlign: "left" }}>{"Number of Rack"}</div>,
        field: "Number_of_Rack",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Management"}</div>,
        field: "management",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  async function getSizeUnit() {
    // let temp = {
    //   company_id: getUser().com,
    //   oem_id: getOem(),
    // };

    let testSizeUnit = [
      { label: "mm", value: "59e65108-210e-4310-a7a5-8d66080fafad" },
      { label: "cm", value: "337071cb-c903-45da-83f7-9230e36800a1" },
      { label: "m", value: "4edae763-ec1f-4366-9c30-52da4375eb7f" },
    ];

    setsizeUnitDD(testSizeUnit);
    // await axios
    //   .request({
    //     method: "POST",
    //     url: Configs.API_URL_AIOI_Back + "/api/warehouse/getSizeUnit",
    //     headers: {
    //       Authorization: getToken(),
    //       "X-TTT": Configs.API_TTT,
    //       "Content-Type": "application/json",
    //     },
    //     data: temp,
    //   })
    //   .then((response) => {
    //     setsizeUnitDD(response.data);
    //   })
    //   .catch((error) => {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Error",
    //       text: "getSizeUnit Error",
    //     });
    //   });
  }

  async function getWarehouse() {
    let tempSearch = {
      filter: filterSearch.filter?.trim() || null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios
      .request({
        method: "POST",
        url: Configs.API_URL_AIOI_Back + "/api/warehouse/getWarehouse",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempSearch,
      })
      .then((response) => {
        setWarehouseData(response.data.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "getWarehouse Error",
        });
      });
  }

  async function clearSearch() {
    setFilterSearch({
      filter: "",
    });
    filterSearch.filter = "";
    await getWarehouse();
  }

  async function getLotProductInWarehouse(wh_id) {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: null,
      warehouse_id: wh_id,
      product_id: null,
    };

    return await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getLotProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        if (response_data.data.length > 0) {
          return true;
        } else {
          return false;
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function delWarehouse(wh_id) {
    let tempDel = {
      wh_id: wh_id,
      oem_id: getOem(),
      company_id: getUser().com,
      fup_id: getUser().fup,
      user: getUser().name + " " + getUser().surname,
    };

    Swal.fire({
      title: "คุณมั่นใจหรือไม่ที่จะลบ Warehouse นี้",
      // text: "คุณต้องการลบ Warehouse นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let checkHas = await getLotProductInWarehouse(wh_id);
        if (checkHas) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "ไม่สามารถลบ Warehouse เนื่องจากมี Product อยู่ในระบบ",
          });
        } else {
          await axios
            .request({
              method: "POST",
              url: Configs.API_URL_AIOI_Back + "/api/warehouse/delWarehouse",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: tempDel,
            })
            .then(async () => {
              Swal.fire({
                icon: "success",
                title: "ลบ Warehouse สำเร็จ",
                showConfirmButton: false,
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1500,
              }).then(async () => {
                await getWarehouse();
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "delWarehouse Error",
              });
            });
        }
      }
    });
  }

  useEffect(() => {
    getWarehouse();
    getSizeUnit();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Warehouse</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">IoT Master Data</li>
                  <li className="breadcrumb-item active">Warehouse</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={filterSearch.filter}
                    onChange={(e) => {
                      setdisAftSort(true);
                      setFilterSearch({
                        ...filterSearch,
                        filter: e.target.value,
                      });
                      filterSearch.filter = e.target.value;
                    }}
                  />
                  <label>Warehouse Name</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      setdisAftSort(false);
                      await getWarehouse();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    id="btn_add"
                    className="btn btn-block btn-primary"
                    type="button"
                    data-toggle="modal"
                    data-target="#modal-add_warehouse"
                    class="btn btn-block btn-primary"
                    onClick={() => {
                      // clearAdd();
                      window.location.href = "/IoT_Master_Data/Warehouse/Add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Warehouse</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            // disableRetreatAfterSorting={disAftSort}
                            disableRetreatAfterSorting={true}
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={WarehouseTableColumns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Modal */}
      </div>
    </div>
  );
}

export default WarehouseMaster;
