import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { useParams } from "react-router";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import DatePicker from "react-datepicker";
import moment from "moment";
import NumberFormat from "react-number-format";

function Data_express(nise) {
  const [company, setCompany] = useState([]);
  const [relate_product, setRelate_product] = useState([]);
  const { target_u } = useParams();
  const [saleDT, setSales] = useState([]);
  const [oilDT, setOil] = useState([]);
  const [pageModesale, setpageModesale] = useState("");
  const [montiyDT, setmontiy] = useState([]);
  const [mode_oil, setmode_oil] = useState({
    value: false,
    mode: "add",
    id: "",
  });
  const [mode_sale, setmode_sale] = useState({
    value: false,
    mode: "add",
    id: "",
  });
  const [mode_monthly, setmode_monthly] = useState({
    value: false,
    mode: "add",
    id: "",
  });
  
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  useEffect(() => {
    GetSales();
    GetOil();
    Getmontiy();
    axios(config_getOem)
    .then(function (response) {
      const options = [];
      var element2 = {};
      element2["value"] = "All_OEM";
      element2["label"] = "---ทั้งหมด---";
      options.push(element2);
      response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.name;
        options.push(element);
        if (el.id === getOem()) {
          setCurrentOEM({ label: el.name, value: el.id });
        }
      });

      setCompany(options);
    })
    .catch(function (error) {
      console.log(error);
    });
}, []);
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [monthyenr, setmonthyenr] = useState({
    input_month: "",
    input_year: "",
    company_id: getUser().com,
    oem_id: getOem(),
  });

  /* console.log(getUser().com); */
  const [filler_sales, setFiller_sales] = useState({
    sale_value: "",
    company_id: getUser().com,
    oem_id: getOem(),
  });

  const [filler_express_record, setFiller_express_record] = useState({
    start_date: "",
    end_date: "",
  });
  async function SetDate_start(date) {
    setFiller_express_record({ ...filler_express_record, start_date: date });
  }
  async function SetDate_end(date) {
    setFiller_express_record({ ...filler_express_record, end_date: date });
  }
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  /*  
function set_oil_input(mode) {
  if (mode === "view") { 
    setmode_oil({value:true});
    mode_oil.value = true;
  } else {
    setmode_oil({value:false});
    mode_oil.value = false;
  }

};

function set_monthly_input(mode) {
  if (mode === "view") { 
    setmode_sale({value:true});
    mode_sale.value = true;
  } else {
    setmode_sale({value:false});
    mode_sale.value = false;
  }
   }; */
  function save_sales() {
    var data_sales = {
      sale_value: Number(
        express_select.sale_value.toString().replace(/,/g, "")
      ),
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/express/add_sales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data_sales,
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "บันทึกเสร็จสิ้น",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetSales();
          });
        }
        console.log("หน้าบ้าน", response.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  function save_sales_edit() {
    var data_sales_edit = {
      id: mode_sale.id,
      sale_value: Number(
        express_select.sale_value.toString().replace(/,/g, "")
      ),
      company_id: getUser().com,
      oem_id: getOem(),
    };
    console.log(data_sales_edit);
    axios({
      method: "post",
      url: Configs.API_URL + "/express/editSales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data_sales_edit,
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "บันทึกเสร็จสิ้น",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetSales();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function save_oil_edit() {
    var data_oil_edit = {
      id: mode_oil.id,
      oil_value: oil_select.oil_value,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    console.log(data_oil_edit);
    axios({
      method: "post",
      url: Configs.API_URL + "/express/editoils",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data_oil_edit,
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetOil();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function save_montiy_edit() {
    var data_montiy_edit = {
      id: mode_monthly.id,
      category_item: montiy_select.category_item,
      steel_bar: Number(montiy_select.steel_bar.toString().replace(/,/g, "")),

      wiremesh: Number(montiy_select.wiremesh.toString().replace(/,/g, "")),
      black_character: Number(
        montiy_select.black_character.toString().replace(/,/g, "")
      ),
      gi_character: Number(
        montiy_select.gi_character.toString().replace(/,/g, "")
      ),
      color: Number(montiy_select.color.toString().replace(/,/g, "")),
      steel_binding_wire: Number(
        montiy_select.steel_binding_wire.toString().replace(/,/g, "")
      ),
      nail: Number(montiy_select.nail.toString().replace(/,/g, "")),
      other: Number(montiy_select.other.toString().replace(/,/g, "")),
      kpi: Number(montiy_select.kpi.toString().replace(/,/g, "")),
      deformed_bar: Number(
        montiy_select.deformed_bar.toString().replace(/,/g, "")
      ),
      input_month: montiy_select.input_month,
      input_year: montiy_select.input_year,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/express/editmontiy",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data_montiy_edit,
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "บันทึกเสร็จสิ้น",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            Getmontiy();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  const GetSales = async () => {
    const tempfilter_sales = {
      company_id: getUser().com,
      oem_id: getOem(),
      dateFrom: filler_express_record.start_date,
      dateTo: filler_express_record.end_date,
    };
    console.log("filter", tempfilter_sales);
    axios({
      method: "post",
      url: Configs.API_URL + "/express/filtersales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempfilter_sales,
    })
      .then(function (response) {
        console.log("sale_data", response.data);
        setSales(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function save_oil() {
    var data_oil = {
      oil_value: oil_select.oil_value,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/express/add_oil",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data_oil,
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "บันทึกเสร็จสิ้น",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetOil();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  function save_montiy() {
    var data_montiy = {
      input_year: montiy_select.input_year,
      input_month: montiy_select.input_month,
      category_item: montiy_select.category_item,
      steel_bar: Number(montiy_select.steel_bar.toString().replace(/,/g, "")),

      wiremesh: Number(montiy_select.wiremesh.toString().replace(/,/g, "")),
      black_character: Number(
        montiy_select.black_character.toString().replace(/,/g, "")
      ),
      gi_character: Number(
        montiy_select.gi_character.toString().replace(/,/g, "")
      ),
      color: Number(montiy_select.color.toString().replace(/,/g, "")),
      steel_binding_wire: Number(
        montiy_select.steel_binding_wire.toString().replace(/,/g, "")
      ),
      nail: Number(montiy_select.nail.toString().replace(/,/g, "")),
      other: Number(montiy_select.other.toString().replace(/,/g, "")),
      kpi: Number(montiy_select.kpi.toString().replace(/,/g, "")),
      deformed_bar: Number(
        montiy_select.deformed_bar.toString().replace(/,/g, "")
      ),
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/express/add_montiy",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data_montiy,
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "บันทึกเสร็จสิ้น",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            Getmontiy();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  const GetOil = async () => {
    const tempfilter_oil = {
      company_id: getUser().com,
      oem_id: getOem(),
      dateFrom: filler_express_record2.start_date,
      dateTo: filler_express_record2.end_date,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/express/filteroil",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempfilter_oil,
    })
      .then(function (response) {
       console.log("น้ำมันที่รัก", response.data);
        setOil(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getmontiy = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/express/filtermontiy",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: monthyenr,
    })
      .then(function (response) {
        /*  console.log("_data", response.data); */
        setmontiy(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  };

  const [filler_express_record2, setFiller_express_record2] = useState({
    start_date: "",
    end_date: "",
  });
  async function SetDate_start2(date) {
    setFiller_express_record2({ ...filler_express_record2, start_date: date });
  }
  async function SetDate_end2(date) {
    setFiller_express_record2({ ...filler_express_record2, end_date: date });
  }
  const CustomInput2 = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  const [express_select, setexpress_select] = useState({
    id: "",
    sale_value: "",
    mode: "add",
    index: "",
  });
  const [express_select_data, setexpress_select_data] = useState([]);
  function Add_sales() {
    if (express_select.sale_value == "view") {
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกตัวเลข",
      });
    } else {
      save_sales();
      var tempData = express_select_data;
      //console.log(tempData)
      setexpress_select_data([]);
      let temp = {
        sale_value: express_select.sale_value,
        sort: tempData.length + 1,
        is_active: false,
        create_date: moment(new Date()).format(),
      };

      tempData.push(temp);
      tempData.sort((a, b) => b.sort - a.sort);
      GetSales();

      setexpress_select_data(tempData);
      setexpress_select({ ...express_select, id: "", sale_value: "" });
      express_select.id = "";
      express_select.sale_value = "";
    }
  }

  function Edit_sales() {
    if (express_select.sale_value == "") {
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกตัวเลข",
      });
    } else {
      Swal.fire({
        title: "คุณต้องการแก้ไขข้อมูลใช่หรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
      }).then((result) => {
        if (result.isConfirmed) {
          save_sales_edit();
          var tempData = express_select_data;
          console.log(tempData);
          setexpress_select_data([]);
          let temp = {
            sale_value: express_select.sale_value,
          };

          tempData.push(temp);

          setexpress_select_data(tempData);
          setexpress_select({ ...express_select, id: "", sale_value: "" });
          express_select.id = "";
          express_select.sale_value = "";
          /*  express_select.current.select.clearValue(); */
        }
      });
    }
  }

  const [oil_select, setoil_select] = useState({
    id: "",
    oil_value: "",
    mode: "",
    index: "",
  });
  const [oil_select_data, setoil_select_data] = useState([]);

  function Add_oil() {
    const error_list3 = [];
   
    if (oil_select.oil_value == "view" || oil_select.oil_value === "") {
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกตัวเลข",
      });
    } else {
      save_oil();
      var tempData2 = oil_select_data;
      //console.log(tempData)
      setoil_select_data([]);
      let temp = {
        oil_value: oil_select.oil_value,
        sort: tempData2.length + 1,
        is_active: false,
        create_date: moment(new Date()).format(),
      };
      console.log(temp);

      tempData2.push(temp);
      tempData2.sort((a, b) => b.sort - a.sort);
      GetOil();

      setoil_select_data(tempData2);
      setoil_select({ ...oil_select, id: "", oil_value: "" });
      oil_select.id = "";
      oil_select.oil_value = "";

      console.log(tempData2);
    }
  }

  function Edit_oil() {
    if (oil_select.oil_value == "" ){
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกตัวเลข",
      });
    } else {
      Swal.fire({
        title: "คุณต้องการแก้ไขข้อมูลใช่หรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
      }).then((result) => {
        if (result.isConfirmed) {
          save_oil_edit();
          var tempData2 = oil_select_data;
          setoil_select_data([]);
          let temp = {
            oil_value: oil_select.oil_value,
          };
          tempData2.push(temp);

          setoil_select_data(tempData2);
          setoil_select({
            ...oil_select,
            id: "",
            oil_value: "",
            mode: "",
            index: "",
          });
          oil_select.id = "";
          oil_select.oil_value = "";
        }
      });
    }
  }

  const [montiy_select, setmontiy_select] = useState({
    id: "",
    input_year: "",
    input_month: "",
    category_item: "",
    steel_bar: "",
    deformed_bar: "",
    wiremesh: "",
    black_character: "",
    gi_character: "",
    color: "",
    steel_binding_wire: "",
    nail: "",
    other: "",
    kpi: "",
    mode: "",
    index: "",
  });
  const [montiy_select_data, setmontiy_select_data] = useState([]);
  function Add_montiy() {
    if (montiy_select.input_year == "" || montiy_select.input_month == "") {
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกปีและเดือน",
      });
    } else {
      save_montiy();

      var tempData3 = montiy_select_data;
      //console.log(tempData)
      setmontiy_select_data([]);
      let temp = {
        input_year: montiy_select.input_year,
        input_month: montiy_select.input_month,
        category_item: montiy_select.category_item,
        steel_bar: montiy_select.steel_bar,
        deformed_bar: montiy_select.deformed_bar,
        wiremesh: montiy_select.wiremesh,
        black_character: montiy_select.black_character,
        gi_character: montiy_select.gi_character,
        color: montiy_select.color,
        steel_binding_wire: montiy_select.steel_binding_wire,
        nail: montiy_select.nail,
        other: montiy_select.other,
        kpi: montiy_select.kpi,
        sort: tempData3.length + 1,
        is_active: false,
      };
      console.log(temp);

      tempData3.push(temp);
      tempData3.sort((a, b) => b.sort - a.sort);

      setmontiy_select_data(tempData3);
      setmontiy_select({
        ...montiy_select,
        id: "",
        input_year: "",
        input_month: "",
        category_item: "",
        steel_bar: "",
        deformed_bar: "",
        wiremesh: "",
        black_character: "",
        gi_character: "",
        color: "",
        steel_binding_wire: "",
        nail: "",
        other: "",
        kpi: "",
      });
      Getmontiy();
    }
  }

  function Edit_montiy() {
    console.log("0000", montiy_select);
    if (montiy_select.input_year == 0 || montiy_select.input_month == 0) {
      Swal.fire({
        icon: "error",
        title: "กรุณากรอกปีและเดือน",
      });
    } else {
      Swal.fire({
        title: "คุณต้องการแก้ไขข้อมูลใช่หรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
      }).then((result) => {
        if (result.isConfirmed) {
          save_montiy_edit();
          var tempData3 = montiy_select_data;
          console.log(tempData3);
          setmontiy_select_data([]);
          let temp = {
            input_year: montiy_select.input_year,
            input_month: montiy_select.input_month,
            category_item: montiy_select.category_item,
            steel_bar: montiy_select.steel_bar,
            deformed_bar: montiy_select.deformed_bar,
            wiremesh: montiy_select.wiremesh,
            black_character: montiy_select.black_character,
            gi_character: montiy_select.gi_character,
            color: montiy_select.color,
            steel_binding_wire: montiy_select.steel_binding_wire,
            nail: montiy_select.nail,
            other: montiy_select.other,
            kpi: montiy_select.kpi,
          };

          tempData3.push(temp);

          setexpress_select_data(tempData3);
          setmontiy_select({
            ...montiy_select,
            id: "",
            input_year: "",
            input_month: "",
            category_item: "",
            steel_bar: "",
            deformed_bar: "",
            wiremesh: "",
            black_character: "",
            gi_character: "",
            color: "",
            steel_binding_wire: "",
            nail: "",
            other: "",
            kpi: "",
          });
          Getmontiy();
        }
      });
    }
  }

  function search_sales(params) {
    /*     console.log(filler_express_record); */
    GetSales();
  }
  function search_oil(params) {
    /*     console.log(filler_express_record2); */
    GetOil();
  }

  var sale_text = ["  บาท"];
  var oil_text = [" ลิตร"];

  function sale_from_read(id, sale_value, mode, e) {
    if (mode === "view") {
      setmode_sale({ ...mode_sale, value: true, mode: mode });
      mode_sale.mode = mode;
      mode_sale.value = true;
    } else if (mode === "add") {
      setmode_sale({ ...mode_sale, value: false, mode: mode, id: id });
      mode_sale.mode = mode;
      mode_sale.value = false;
    } else {
      setmode_sale({ ...mode_sale, value: false, mode: mode, id: id });
      mode_sale.mode = mode;
      mode_sale.value = false;

      mode_sale.id = id;
    }
    console.log(id);

    setexpress_select({
      ...express_select,
      sale_value: sale_value,
    });

    express_select.sale_value = sale_value;
  }
  function oil_from_read(id, oil_value, mode, e) {
    if (mode === "view") {
      setmode_oil({ ...mode_oil, value: true, mode: mode });
      mode_oil.mode = mode;
      mode_oil.value = true;
    } else {
      setmode_oil({ ...mode_oil, value: false, mode: mode, id: id });
      mode_oil.mode = mode;
      mode_oil.value = false;
      mode_oil.id = id;
    }
    setoil_select({
      ...oil_select,
      oil_value: oil_value,
    });
    oil_select.oil_value = oil_value;
  }

  function montiy_from_read(
    id,
    input_year,
    input_month,
    category_item,
    steel_bar,
    deformed_bar,
    wiremesh,
    black_character,
    gi_character,
    color,
    steel_binding_wire,
    nail,
    other,
    kpi,
    mode,
    e
  ) {
    if (mode === "view") {
      setmode_monthly({ value: true, mode: mode });
      mode_monthly.mode = mode;
      mode_monthly.value = true;
    } else if (mode === "0") {
      setmode_monthly({ value: false, mode: mode, id: id });
      mode_monthly.mode = mode;
      mode_monthly.value = false;
      mode_monthly.id = id;
    } else {
      setmode_monthly({ value: false, mode: mode, id: id });
      mode_monthly.mode = mode;
      mode_monthly.value = false;
      mode_monthly.id = id;
    }

    setmontiy_select({
      ...montiy_select,
      input_year: input_year,
      input_month: input_month,
      category_item: category_item,
      steel_bar: steel_bar,
      deformed_bar: deformed_bar,
      wiremesh: wiremesh,
      black_character: black_character,
      gi_character: gi_character,
      color: color,
      steel_binding_wire: steel_binding_wire,
      nail: nail,
      other: other,
      kpi: kpi,
    });
    montiy_select.input_year = input_year;
    montiy_select.input_month = input_month;
    montiy_select.category_item = category_item;
    montiy_select.steel_bar = steel_bar;
    montiy_select.deformed_bar = deformed_bar;
    montiy_select.wiremesh = wiremesh;
    montiy_select.black_character = black_character;
    montiy_select.color = color;
    montiy_select.steel_binding_wire = steel_binding_wire;
    montiy_select.nail = nail;
    montiy_select.kpi = kpi;
  }

  /*      function saveOptionsale(mode) {
      if (mode === "add") {
  var data_sales ={
   sale_value: express_select.sale_value,
   company_id: getUser().com,
   oem_id: getOem()
  }
 axios({
   method: "post",
   url: Configs.API_URL + "/express/add_sales",
   headers: {
     Authorization: getToken(),
     "X-TTT": Configs.API_TTT,
     "Content-Type": "application/json",
   },
   data: data_sales,
 })
 
   .then(function (response) {
    setFiller_sales(response.data);
     //console.log(response.data)
    
     
     ;
   })
   .catch(function (error) {
     console.log(error);
     
   })}
   if (mode === "edit") {
    const tempfilter_edit_sale = {
      
      id: express_select.id,
      sale_value: express_select.sale_value,
      company_id: getUser().com,
      oem_id: getOem()
  
    
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/express/filtersaleEdit",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
       data: tempfilter_edit_sale
    })
      .then(function (response) {
        console.log("save_data",response.data);
        setSales(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });

   }} */

  var rowssales = [];

  for (var index = 0; index < saleDT.length; index++) {
    /* console.log("chack",saleDT) */
    const rowItem = {};
    rowItem["no"] = index + 1;
    var sale_value = 0
    sale_value =  parseFloat(saleDT[index].sale_value || 0)
    rowItem["sales"] = sale_value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sale_text
    /* moment(Date (saleDT[index].update_date)).format(
      "DD/MM/yyyy :: HH:mm น."
    ); */
    /*  moment(Date(logistic[index].create_date)).format("DD-MM-yyyy HH:MM") */
    rowItem["update_date"] = moment(saleDT[index].update_date).format(
      "DD/MM/yyy :: HH:mm น."
    );
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6">
          <a
            onClick={
              sale_from_read.bind(
                this,
                saleDT[index].id,
                saleDT[index].sale_value,
                "view"
              )

              /*  set_sale_input.bind(this,"view") */
            }
            data-toggle="modal"
            data-target="#modal-add_express_rerationship"
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-6">
          <a
            onClick={sale_from_read.bind(
              this,
              saleDT[index].id,
              saleDT[index].sale_value,
              "edit"
            )}
            data-toggle="modal"
            data-target="#modal-add_express_rerationship"
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
      </div>
    );
    rowssales.push(rowItem);
  }

  const datasales = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ยอดขาย",
        field: "sales",
        sort: "asc",
        width: 50,
      },
      {
        label: "updateครั้งล่าสุด",
        field: "update_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowssales,
  };

  // var rowsoil = [];

  // for (var index = 0; index < oilDT.length; index++) {
  //   /*  console.log("chack", oilDT); */
  //   const rowItem = {};
  //   // rowItem["no"] = index + 1;
  //   var oil_value = 0
  //   oil_value =  parseFloat(oilDT[index].oil_value || 0)
  //   rowItem["oil"] = oil_value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + oil_text;
  //   /* moment(Date (saleDT[index].update_date)).format(
  //     "DD/MM/yyyy :: HH:mm น."
  //   ); */
  //   /*  moment(Date(logistic[index].create_date)).format("DD-MM-yyyy HH:MM") */
  //   rowItem["update_date"] = moment(oilDT[index].update_date).format(
  //     "DD/MM/yyy :: HH:mm น."
  //   );
  //   // rowItem["mgt"] = (
  //   //   <div className="row">
  //   //     <div className="col-6">
  //   //       <a
  //   //         onClick={
  //   //           oil_from_read.bind(
  //   //             this,
  //   //             oilDT[index].id,
  //   //             oilDT[index].oil_value,
  //   //             "view"
  //   //           )

  //   //           /*  set_oil_input.bind(this,"view") */
  //   //         }
  //   //         data-toggle="modal"
  //   //         data-target="#modal-add_oil_rerationship"
  //   //       >
  //   //         <i class="fas fa-eye"></i>
  //   //       </a>
  //   //     </div>
  //   //     <div className="col-6">
  //   //       <a
  //   //         onClick={oil_from_read.bind(
  //   //           this,
  //   //           oilDT[index].id,
  //   //           oilDT[index].oil_value,
  //   //           "edit"
  //   //         )}
  //   //         data-toggle="modal"
  //   //         data-target="#modal-add_oil_rerationship"
  //   //       >
  //   //         <i class="fas fa-pencil-alt"></i>
  //   //       </a>
  //   //     </div>
  //   //   </div>
  //   // );
  //   rowsoil.push(rowItem);
  // }

  // const dataoil = {
  //   columns: [
  //     // {
  //     //   label: "ลำดับ",
  //     //   field: "no",
  //     //   sort: "asc",
  //     //   width: 50,
  //     // },
  //     {
  //       label: "น้ำมัน",
  //       field: "oil",
  //       sort: "asc",
  //       width: 50,
  //     },
  //     {
  //       label: "updateครั้งล่าสุด",
  //       field: "update_date",
  //       sort: "asc",
  //       width: 50,
  //     },
  //     // {
  //     //   label: "จัดการ",
  //     //   field: "mgt",
  //     //   sort: "asc",
  //     //   width: 50,
  //     // },
  //   ],
  //   rows: rowsoil,
  // };
  /*   console.log(montiyDT); */
  const rowsDatamontiy = [];
  /*   console.log(montiy_select_data); */
  for (var index = 0; index < montiyDT.length; index++) {
    //console.log(rowsData);
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["input_year"] = montiyDT[index].input_year;
    var input_month = "";
    if (montiyDT[index].input_month == 1) {
      input_month = "มกราคม";
    } else if (montiyDT[index].input_month == 2) {
      input_month = "กุมภาพันธ์";
    } else if (montiyDT[index].input_month == 3) {
      input_month = "มีนาคม";
    } else if (montiyDT[index].input_month == 4) {
      input_month = "เมษายน";
    } else if (montiyDT[index].input_month == 5) {
      input_month = "พฤษภาคม";
    } else if (montiyDT[index].input_month == 6) {
      input_month = "มิถุนายน";
    } else if (montiyDT[index].input_month == 7) {
      input_month = "กรกฎาคม";
    } else if (montiyDT[index].input_month == 8) {
      input_month = "สิงหาคม";
    } else if (montiyDT[index].input_month == 9) {
      input_month = "กันยายน";
    } else if (montiyDT[index].input_month == 10) {
      input_month = "ตุลาคม";
    } else if (montiyDT[index].input_month == 11) {
      input_month = "พฤศจิกายน";
    } else if (montiyDT[index].input_month == 12) {
      input_month = "ธันวาคม";
    }

    rowItem["month"] = input_month
    rowItem["category_item"] = montiyDT[index].category_item;
    rowItem["steel_bar"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].steel_bar.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["deformed_bar"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].deformed_bar.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["wiremesh"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].wiremesh.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["black_character"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].black_character.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["gi_character"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].gi_character.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["color"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].color.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["steel_binding_wire"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].steel_binding_wire.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["nail"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].nail.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["other"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].other.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["kpi"] = (
      <NumberFormat
        displayType="text"
        thousandSeparator={true}
        type="text"
        value={montiyDT[index].kpi.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      />
    );
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6" style={{ margin: " 10px 0 10px 0" }}>
          <a
            onClick={montiy_from_read.bind(
              this,
              montiyDT[index].id,
              montiyDT[index].input_year,
              montiyDT[index].input_month,
              montiyDT[index].category_item,
              montiyDT[index].steel_bar,
              montiyDT[index].deformed_bar,
              montiyDT[index].wiremesh,
              montiyDT[index].black_character,
              montiyDT[index].gi_character,
              montiyDT[index].color,
              montiyDT[index].steel_binding_wire,
              montiyDT[index].nail,
              montiyDT[index].other,
              montiyDT[index].kpi,
              "view"
            )}
            data-toggle="modal"
            data-target="#modal-add_monthly_data_rerationship"
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-6" style={{ margin: " 10px 0 10px 0" }}>
          <a
            onClick={montiy_from_read.bind(
              this,
              montiyDT[index].id,
              montiyDT[index].input_year,
              montiyDT[index].input_month,
              montiyDT[index].category_item,
              montiyDT[index].steel_bar,
              montiyDT[index].deformed_bar,
              montiyDT[index].wiremesh,
              montiyDT[index].black_character,
              montiyDT[index].gi_character,
              montiyDT[index].color,
              montiyDT[index].steel_binding_wire,
              montiyDT[index].nail,
              montiyDT[index].other,
              montiyDT[index].kpi,
              "edit"
            )}
            data-toggle="modal"
            data-target="#modal-add_monthly_data_rerationship"
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsDatamontiy.push(rowItem);
  }

  const datamontiy = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ปี",
        field: "input_year",
        sort: "asc",
        width: 50,
      },
      {
        label: "เดือน",
        field: "month",
        sort: "asc",
        width: 50,
      },
     /*  {
        label: "ประเภทสินค้า",
        field: "category_item",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "เหล็กเส้นก่อสร้าง",
        field: "steel_bar",
        sort: "asc",
        width: 50,
      },
      {
        label: "เหล็กข้ออ้อย",
        field: "deformed_bar",
        sort: "asc",
        width: 50,
      },
      {
        label: "ไวร์เมช",
        field: "wiremesh",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปพรรณแบบดำ",
        field: "black_character",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปพรรณ GI แบบ กัลวาไนซ์",
        field: "gi_character",
        sort: "asc",
        width: 50,
      },
      {
        label: "สี",
        field: "color",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลวดผูกเหล็ก",
        field: "steel_binding_wire",
        sort: "asc",
        width: 50,
      },
      {
        label: "ตะปู",
        field: "nail",
        sort: "asc",
        width: 50,
      },
      {
        label: "อื่นๆ",
        field: "other",
        sort: "asc",
        width: 50,
      },
     /*  {
        label: "KPI",
        field: "kpi",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDatamontiy,
  };

  const clearsale = async () => {
    setFiller_express_record({
      start_date: "",
      end_date: "",
    });

    filler_express_record.start_date = "";
    filler_express_record.end_date = "";
    /*    console.log("clear_sale"); */

    GetSales();
    // window.location.reload();
  };

  const clearoil = async () => {
    setFiller_express_record2({
      start_date: "",
      end_date: "",
    });
    filler_express_record2.start_date = "";
    filler_express_record2.end_date = "";

    GetOil();

    // window.location.reload();
  };
  const clearmontiy = async () => {
    setmonthyenr({
      ...monthyenr,

      input_month: "",
      input_year: "",
    });

    monthyenr.input_month = "";
    monthyenr.input_year = "";
    Getmontiy();
  };

  if (target_u === "Data_express") {
    var class_active_Data_express = "tab-pane fade show active";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_Data_express = "nav-link active";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link";
  } else if (target_u === "rack") {
    var class_active_Data_express = "tab-pane fade";
    var class_active_rack = "tab-pane fade show active";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_Data_express = "nav-link";
    var nav_active_rack = "nav-link active";
    var nav_active_rack_floor = "nav-link";
  } else if (target_u === "rack_floor") {
    var class_active_Data_express = "tab-pane fade";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade show active";

    var nav_active_Data_express = "nav-link";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link active";
  } else if (target_u === "shelf") {
    var class_active_Data_express = "tab-pane fade";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_Data_express = "nav-link";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link";
  } else {
    var class_active_Data_express = "tab-pane fade show active";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_Data_express = "nav-link active";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link";
  }

  return (
    
    <div className="wrapper">
      
      <div className="content-wrapper">


        <section className="content-header">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ข้อมูล Express{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                Warehouse management    
                  </li>
                  <li className="breadcrumb-item active">ข้อมูล Express</li>
                </ol>
              </div>
            </div>
          </div>

       
        </section>
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={nav_active_Data_express}
                    id="custom-tabs-four-Data_express-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-Data_express"
                    role="tab"
                    aria-controls="custom-tabs-four-Data_express"
                    aria-selected="false"
                  >
                    ยอดขาย
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={nav_active_rack}
                    id="custom-tabs-four-rack-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-rack"
                    role="tab"
                    aria-controls="custom-tabs-four-rack"
                    aria-selected="false"
                  >
                    น้ำมัน (สุทธิ)
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={nav_active_rack_floor}
                    id="custom-tabs-four-rack_floor-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-rack_floor"
                    role="tab"
                    aria-controls="custom-tabs-four-rack_floor"
                    aria-selected="false"
                  >
                    ข้อมูลรายเดือน
                  </a>
                </li>
              </ul>
            </div>

            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                {/* ******************************* TAP 1 ********************************* */}
                <div
                  className={class_active_Data_express}
                  id="custom-tabs-four-Data_express"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-Data_express-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <div className="container-fluid">
                    <div className="row mb-6">
                      <div className="col-md-0.5">
                        <div className="form-group "></div>
                      </div>

                      <div className="row mb-2">
                        <div className="col-md-0.5">
                          <label> วันที่ &nbsp;</label>
                        </div>

                        <div className="col-md-1.0" style={{ "z-index": "99" }}>
                          <div className="form-group ">
                            <DatePicker
                              selected={filler_express_record.start_date}
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => SetDate_start(date)}
                              selectsStart
                              startDate={filler_express_record.start_date}
                              endDate={filler_express_record.end_date}
                              customInput={<CustomInput />}
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <label>&nbsp;&nbsp; ถึง &nbsp;&nbsp;</label>

                          <div
                            className="col-md-1.0"
                            style={{ "z-index": "99" }}
                          >
                            <div className="form-group ">
                              <DatePicker
                                selected={filler_express_record.end_date}
                                dateFormat={"dd-MM-yyyy"}
                                onChange={async (date) => SetDate_end(date)}
                                selectsEnd
                                startDate={filler_express_record.start_date}
                                endDate={filler_express_record.end_date}
                                minDate={filler_express_record.start_date}
                                customInput={<CustomInput />}
                              ></DatePicker>
                            </div>
                          </div>
                        </div>
                      </div>

                      <label>&nbsp;&nbsp; &nbsp;&nbsp;</label>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              search_sales();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              clearsale();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-custom-content"></div>
                  <div className="col-md-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-add_express_rerationship"
                        class="btn btn-block btn-primary "
                        onClick={() => {
                          setexpress_select({
                            ...express_select,
                            id: "",
                            express: "",
                            sale_value: "",
                            mode: "add",
                            index: "",
                            minimum_order: "",
                            lead_time_days: "",
                            remark: "",
                          });
                          sale_from_read(this, "view");
                          setmode_sale({ ...mode_sale, mode: "add" });
                          mode_sale.mode = "add";
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={datasales}
                    />
                  </div>
                </div>

                <div className="modal fade" id="modal-add_express_rerationship">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-label">ยอดขาย</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-9">
                            <div className="form-group">
                              <NumberFormat
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                type="text"
                                className="form-control"
                                value={express_select.sale_value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_sale.value}
                                onChange={(e) =>
                                  setexpress_select({
                                    ...express_select,
                                    sale_value: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">ยอดขาย</label>
                            </div>
                          </div>

                          <h2>บาท</h2>
                          <div className="col-1"></div>
                        </div>

                        <div className="row">
                          <div className="col-1"></div>

                          <div className="col-1"></div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-end">
                        {mode_sale.mode === "edit" ? (
                          <>
                            <button
                              type="button"
                              onClick={Edit_sales}
                              data-dismiss="modal"
                              className="btn btn-primary"
                            >
                              Save Change
                            </button>
                          </>
                        ) : (
                          <>
                            {mode_sale.mode === "view" ? (
                              <></>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  onClick={Add_sales}
                                  data-dismiss="modal"
                                  className="btn btn-primary"
                                >
                                  Add
                                </button>
                                {/* ไม่ใช่ */}
                              
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* ******************************* TAP 2 ********************************* */}
                <div
                  className={class_active_rack}
                  id="custom-tabs-four-rack"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-rack-tab"
                >
                  {/* <div className="row">
                    <div className="col-4"></div>
                  </div> */}

                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-1.5">
                        <div className="form-group "></div>
                      </div>
                      <div className="tab-custom-content"></div>
                  <div className="col-md-2">
                    <div className="form-group ">
                    {getOem() === currentOEM.value && currentOEM.label ==="บจก.สตูลค้าเหล็ก" ? (
                        <>
                        <button
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-add_oil_rerationship"
                        class="btn btn-block btn-primary "
                        onClick={() => {
                          setoil_select({
                            ...oil_select,
                            id: "",
                            express: "",
                            oil_value: "",
                            mode: "add",
                            index: "",
                            minimum_order: "",
                            lead_time_days: "",
                            remark: "",
                          });
                          oil_from_read(this, "view");
                          setmode_sale({ ...mode_oil, mode: "add" });
                          mode_oil.mode = "add";
                        }}
                      >
                        Add
                      </button>
                        </>
                      ) : (
                        <>
                        </>
                      )}
                      
                      
                      {/* ใช่ */}
                    </div>
                  </div>


                      {/* <div className="row mb-2">
                        <div className="col-md-0.5">
                          <label> วันที่ &nbsp;</label>
                        </div>

                        <div className="col-md-1.5" style={{ "z-index": "99" }}>
                          <div className="form-group ">
                            <DatePicker
                              selected={filler_express_record2.start_date}
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => SetDate_start2(date)}
                              selectsStart
                              startDate={filler_express_record2.start_date}
                              endDate={filler_express_record2.end_date}
                              customInput={<CustomInput2 />}
                            />
                          </div>
                        </div>
                        <div className="row mb-2">
                          <label>&nbsp;&nbsp; ถึง &nbsp;&nbsp;</label>

                          <div
                            className="col-md-1.5"
                            style={{ "z-index": "99" }}
                          >
                            <div className="form-group ">
                              <DatePicker
                                selected={filler_express_record2.end_date}
                                dateFormat={"dd-MM-yyyy"}
                                onChange={async (date) => SetDate_end2(date)}
                                selectsEnd
                                startDate={filler_express_record2.start_date}
                                endDate={filler_express_record2.end_date}
                                minDate={filler_express_record2.start_date}
                                customInput={<CustomInput2 />}
                              ></DatePicker>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <label>&nbsp;&nbsp; &nbsp;&nbsp;</label> */}

                      {/* <div className="col-md-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              search_oil();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div> */}

                      {/* <div className="col-md-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              clearoil();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2"></div>
                  </div>
                  {/* <div className="tab-custom-content"></div>
                  <div className="col-md-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-add_oil_rerationship"
                        class="btn btn-block btn-primary "
                        onClick={() => {
                          setoil_select({
                            ...oil_select,
                            id: "",
                            express: "",
                            oil_value: "",
                            mode: "add",
                            index: "",
                            minimum_order: "",
                            lead_time_days: "",
                            remark: "",
                          });
                          oil_from_read(this, "view");
                          setmode_sale({ ...mode_oil, mode: "add" });
                          mode_oil.mode = "add";
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div> */}


                  <div className="row">
                  <div className="col-12">
                    <table class="center" style = {{/* border: "4px solid #ddd", */position: "sticky", center: "0" ,"z-index": "1",height: "300px",width: "100%"}}>
                      <tr>
                      {oilDT.map((e) => {
                          let coloras = "green";
                          if (parseFloat(e.oil_value) <= 50.00){
                            coloras = "red";
                          }
                          return (
                      <>
                      <th style = {{width: "50%",/* border: "4px solid #ddd", */textAlign:"right",padding: "15px"}} ><h1 style= {{color:coloras,fontSize:"50px"}}>  <i class="fa fa-gas-pump"></i> จำนวนน้ำมันคงเหลือ  </h1></th>
                      <th style = {{width: "50%",/* border: "4px solid #ddd", */ textAlign:"left",padding: "15px"}} ><h1 style= {{color:coloras,fontSize:"50px"}}>   {parseFloat(e.oil_value).toFixed(2)}    ลิตร </h1></th>
                     
                      </>
                       );
                    })}
                      </tr>
                    </table>
                    </div>
                {/*   <div className="col-6 col-md-6 col-xl-6">
                    <table class="center" style = {{border: "0px solid #ddd",position: "sticky", center: "0" ,"z-index": "1",height: "300px",width: "100%",color:"black"}}>
                      <tr>
                        {oilDT.map((e) => {
                          let coloras = "green";
                          if (parseFloat(e.oil_value) < 50.00){
                            coloras = "red";
                          }
                          return (
                            <th style = {{ textAlign:"left",padding: "15px"}}> <h1 style= {{color:coloras,fontSize:"50px"}} > {parseFloat(e.oil_value).toFixed(2)}    ลิตร. </h1></th>
                          );
                        })}
                      
                      
                       
                      </tr>
                    </table>
                    </div> */}
                  </div>
                </div>
                    {/* <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={dataoil}
                    /> */}
                    {/* </div>
                  </div>
                </div> */}

                <div className="modal fade" id="modal-add_oil_rerationship">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-label">น้ำมัน </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-9">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                decimalScale={2}
                                allowNegative={false}
                                value={oil_select.oil_value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_oil.value}
                                onChange={(e) =>
                                  setoil_select({
                                    ...oil_select,
                                    oil_value: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="">น้ำมัน 
                              {oil_select.oil_value === "" ? (<span style={{ color: "red" }}> *</span>):("")}
                              </label>
                            </div>
                          </div>
                          <h2>ลิตร</h2>
                          <div className="col-1"></div>
                        </div>

                        <div className="row">
                          <div className="col-1"></div>

                          <div className="col-1"></div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-end">
                        {mode_oil.mode === "edit" ? (
                          <>
                            <button
                              type="button"
                              onClick={Edit_oil}
                              data-dismiss="modal"
                              className="btn btn-primary"
                            >
                              Save Change
                            </button>
                          </>
                        ) : (
                          <>
                            {mode_oil.mode === "view" ? (
                              <></>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  onClick={Add_oil}
                                  data-dismiss="modal"
                                  className="btn btn-primary"
                                  
                                >
                                  Add
                                </button>
                               
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* ******************************* TAP 3 ********************************* */}
                <div
                  className={class_active_rack_floor}
                  id="custom-tabs-four-rack_floor"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-rack_floor-tab"
                >
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-1.5">
                        <div className="form-group "></div>
                      </div>
                      <label htmlFor="">ปี&nbsp;</label>
                      <div className="col-sm-2">
                        <div className="form-group">
                          <select
                            className="custom-select select2 form-control"
                            value={monthyenr.input_year}
                            onChange={(e) => {
                              setmonthyenr({
                                ...monthyenr,
                                input_year: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                          </select>
                        </div>
                      </div>
                      <label htmlFor="">&nbsp;เดือน&nbsp;</label>
                      <div className="col-sm-2">
                        <div className="form-group">
                          <select
                            className="custom-select select2 form-control"
                            value={monthyenr.input_month}
                            onChange={(e) => {
                              setmonthyenr({
                                ...monthyenr,
                                input_month: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select</option>
                            <option value="1">มกราคม</option>
                            <option value="2">กุมภาพันธ์</option>
                            <option value="3">มีนาคม</option>
                            <option value="4">เมษายน</option>
                            <option value="5">พฤษภาคม</option>
                            <option value="6">มิถุนายน</option>
                            <option value="7">กรกฎาคม</option>
                            <option value="8">สิงหาคม</option>
                            <option value="9">กันยายน</option>
                            <option value="10">ตุลาคม</option>
                            <option value="11">พฤศจิกายน</option>
                            <option value="12">ธันวาคม</option>
                          </select>
                        </div>
                      </div>

                      <label>&nbsp;&nbsp; &nbsp;&nbsp;</label>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={Getmontiy}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => clearmontiy()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2"></div>
                  </div>
                  <div className="tab-custom-content"></div>
                  <div className="col-md-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        data-toggle="modal"
                        data-target="#modal-add_monthly_data_rerationship"
                        class="btn btn-block btn-primary "
                        onClick={() => {
                          montiy_from_read(this, "view");
                          setmontiy_select({
                            ...montiy_select,
                            id: "",
                            input_year: "",
                            input_month: "",
                            category_item: "",
                            steel_bar: "",
                            deformed_bar: "",
                            wiremesh: "",
                            black_character: "",
                            gi_character: "",
                            color: "",
                            steel_binding_wire: "",
                            nail: "",
                            other: "",
                            kpi: "",
                            mode: "",
                            index: "",
                          });

                          /*  setmode_monthly({...mode_sale,mode:"add"});
                          mode_monthly.mode = "add"; */
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className="row table-responsive ">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={datamontiy}
                    />
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="modal-add_monthly_data_rerationship"
                >
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-label">ข้อมูลรายเดือน</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-1"></div>
                        </div>
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-sm-5">
                            <div className="form-group">
                              <select
                                className="custom-select select2 form-control"
                                value={montiy_select.input_year}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    input_year: e.target.value.trim(),
                                  })
                                }
                              >
                                <option value="">Select</option>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                              </select>

                              <label htmlFor="">ปี</label>
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="form-group">
                              <select
                                className="custom-select select2 form-control"
                                value={montiy_select.input_month}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    input_month: e.target.value.trim(),
                                  })
                                }
                              >
                                <option value="">Select</option>
                                <option value="1">มกราคม</option>
                                <option value="2">กุมภาพันธ์</option>
                                <option value="3">มีนาคม</option>
                                <option value="4">เมษายน</option>
                                <option value="5">พฤษภาคม</option>
                                <option value="6">มิถุนายน</option>
                                <option value="7">กรกฎาคม</option>
                                <option value="8">สิงหาคม</option>
                                <option value="9">กันยายน</option>
                                <option value="10">ตุลาคม</option>
                                <option value="11">พฤศจิกายน</option>
                                <option value="12">ธันวาคม</option>
                              </select>
                              <label htmlFor="">เดือน</label>
                            </div>
                          </div>

                          <div className="col-1"></div>
                        </div>
                        {/* <div className="row">
                          <div className="col-1"></div>
                          <div className="col-sm-5">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                value={montiy_select.category_item}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    category_item: e.target.value.trim(),
                                  })
                                }
                              ></input>
                              <label htmlFor="">ประเภทสินค้า</label>
                            </div>
                          </div>

                          <div className="col-1"></div>
                        </div> */}
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.steel_bar.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    steel_bar: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">เหล็กเส้นก่อสร้าง</label>
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.deformed_bar.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    deformed_bar: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">เหล็กข้ออ้อย</label>
                            </div>
                          </div>

                          <div className="col-1"></div>
                        </div>
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.wiremesh.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    wiremesh: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">ไวร์เมช</label>
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.black_character.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    black_character: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">รูปพรรณแบบดำ</label>
                            </div>
                          </div>

                          <div className="col-1"></div>
                        </div>
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.gi_character.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    gi_character: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">รูปพรรณ GI แบบ กัลวาไนซ์</label>
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.color.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    color: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">สี</label>
                            </div>
                          </div>

                          <div className="col-1"></div>
                        </div>
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.steel_binding_wire.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    steel_binding_wire: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">ลวดผูกเหล็ก</label>
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.nail.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    nail: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">ตะปู</label>
                            </div>
                          </div>

                          <div className="col-1"></div>
                        </div>
                        <div className="row">
                          <div className="col-1"></div>
                          <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.other.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    other: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">อื่นๆ</label>
                            </div>
                          </div>

                       {/*    <div className="col-sm-5">
                            <div className="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                value={montiy_select.kpi.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={mode_monthly.value}
                                onChange={(e) =>
                                  setmontiy_select({
                                    ...montiy_select,
                                    kpi: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">KPI</label>
                            </div>
                          </div> */}

                          <div className="col-1"></div>
                        </div>

                        <div className="row">
                          <div className="col-1"></div>

                          <div className="col-1"></div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-end">
                        {mode_monthly.mode === "edit" ? (
                          <>
                            <button
                              type="button"
                              onClick={Edit_montiy}
                              data-dismiss="modal"
                              className="btn btn-primary"
                            >
                              Save Change
                            </button>
                          </>
                        ) : (
                          <>
                            {mode_monthly.mode === "view" ? (
                              <></>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  onClick={Add_montiy}
                                  data-dismiss="modal"
                                  className="btn btn-primary"
                                >
                                  Add
                                </button>

                              
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Data_express;
