import React, { useEffect, useState, useRef } from "react";

import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../../config";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Table_Route_Cuteplus() {
  const [RouteDelDate, setRouteDelDate] = useState([]);
  const [Filter, setFilter] = useState({
    route_name: "",
    route_rdd_id: "",
  });
  const [Route, setRoute] = useState([]);
  const selectRoutedays = useRef();
  useEffect(() => {
    getRouteDelDate();
    FilterRoute();
  }, []);

  function getRouteDelDate() {
    axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/route_delivery_date/getAll",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let tempArr = [];
        response.data.forEach((element) => {
          const el = {};
          el["label"] = element.rdd_name;
          el["value"] = element.rdd_id;
          tempArr.push(el);
        });
        setRouteDelDate(tempArr);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function FilterRoute() {
    let data = {
      route_name: Filter.route_name == "" ? null : Filter.route_name.trim(),
      route_rdd_id: Filter.route_rdd_id == "" ? null : Filter.route_rdd_id,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    console.log(data);
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/Route/filter",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        setRoute(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function clear() {
    setFilter({
      ...Filter,
      route_name: "",
      route_rdd_id: "",
    });
    Filter.route_name = "";
    Filter.route_rdd_id = "";
    FilterRoute();
  }
  function delRoute(e) {
    // console.log(e);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          route_updated_by: getUser().fup,
          route_updated_name: getUser().name + " " + getUser().surname,
        };
        // console.log(data);
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/Route/del/" + e,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            if (response.data) {
              Swal.fire({
                text: "เสร็จสิ้น",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
              }).then((result) => {
                window.location.reload();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  const RouteData = [];
  for (let index = 0; index < Route.length; index++) {
    const rowItem = {};
    rowItem["No"] = index + 1;
    rowItem["route_name"] = Route[index].route_name;
    rowItem["route_detail"] = Route[index].route_detail;
    rowItem["rdd_name"] = Route[index].rdd_name;
    rowItem["color"] = (
      <div
        className="color-palette"
        style={{ backgroundColor: `${Route[index].route_color_code}` }}
      >
        <span style={{ color: `${Route[index].route_color_code}` }}>
          {Route[index].route_color_code}
        </span>
      </div>
    );

    rowItem["Management"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-4">
            <a
              href={
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Route/view/" +
                Route[index].route_id
              }
              key={Route[index].route_id}
              className=" btn btn-xs "
            >
              <i class="fas fa-eye"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
              href={
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Route/edit/" +
                Route[index].route_id
              }
              key={Route[index].route_id}
              className=" btn btn-xs "
            >
              {" "}
              <i class="fas fa-pencil-alt"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
              key={Route[index].route_id}
              className=" btn btn-xs "
              onClick={delRoute.bind(this, Route[index].route_id)}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      </>
    );

    RouteData.push(rowItem);
  }
  const RouteTable = {
    columns: [
      {
        label: "No",
        field: "No",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Color",
        field: "color",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Route Name",
        field: "route_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Delivary Day",
        field: "rdd_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Route Detail",
        field: "route_detail",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "Management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: RouteData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Route</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Route</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.route_name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        route_name: e.target.value,
                      });
                      Filter.route_name = e.target.value;
                    }}
                  />
                  <label>Route Name</label>
                </div>
              </div>
              <div
                className="col-12 col-md-4 col-xl-2"
                style={{ "z-index": "98" }}
              >
                <div class="form-group">
                  <Select
                    options={RouteDelDate}
                    value={RouteDelDate.filter((e) => {
                      return e.value === Filter.route_rdd_id;
                    })}
                    ref={selectRoutedays}
                    onChange={(e) => {
                      if (e != null) {
                        setFilter({
                          ...Filter,
                          route_rdd_id: e.value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="">Delivery Day</label>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      FilterRoute();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    window.location.href =
                      "/Warehouse_Management/Factory_Master_Data_Cuteplus/Route/add";
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Route</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div
                          className="table-responsive"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <MDBDataTable
                            sortable={false}
                            striped
                            className="table table-head-fixed"
                            bordered
                            hover
                            data={RouteTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Table_Route_Cuteplus;
