import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import qrcode from "qrcode.react";
import INF from "../../component/input_feature";
import Select from "react-select";

function Driver_person(params) {

  const [company, setCompany] = useState([]);

 
  const [driver,setdriver] = useState([]);
  const [starType,setstarType]= useState([]);
  const [starValue,setstarValue]= useState([]);
  const [list_person,setlist_person] = useState([]);
  const [driver_person,setdriver_person] = useState([]);
  const [test,settest] = useState({
    test:"",
  });
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [companyGroup, setCompanyGroup] = useState([]);
  const [statusAdd_driver,setstatusAdd_driver] = useState({
    status:"คนขับ",
    id_person:"",
    person_name:"",
    
  })
  
  const STAR = useRef();
  const [textChange,settextChange] = useState({
    value:"",
  });

  const [select_per,setselect_per] = useState({
    id:"",
    index:"",
    delivery_sort:"",
    date:"",
    plate_number: "",
    bill_no:"",
    mode:"",
  });
  const [cancleBill,setcancleBill] = useState({
    id:"",
    index:"",
    delivery_sort:"",
    date:"",
    plate_number: "",
    bill_no:"",
    mode:"",
    remark:"",
  });

  const [select_star,setselect_star] = useState({
    id:"",
    index:"",
    bill_id:"",
    delivery_sort:"",
    date:"",
    plate_number: "",
    bill_no:"",
    end_user:"",
  });
  const [search_cus_end,setsearch_cus_end] = useState({
    result_cus:"",
    result_end:""
  });
  const [end_user, setEnd_user] = useState([]);

  const [jugde,setjugde] = useState({
    name:"",
    index:"",
  });





  const GetEnd_user = async () => {
    
    let temp = {
      oem_id: getOem(),
    } 
    axios({
      method: "post",
      url: Configs.API_URL + "/end_user/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        //console.log(response.data);
        setEnd_user(response.data);
        const options = [];
        var element2 = {};
        element2["value"] = "";
        element2["label"] = "---ไม่ระบุ---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.fullname;
          options.push(element);
        });
        //console.log(options);
       
        //console.log(options);
        setEnd_user(options);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isSelected ? '#87CEFA' : '#FFF',
        color: '#000',
        cursor: isDisabled ? 'not-allowed' : 'default',

      };
    },

  };

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetStarType = async () => {
    let temp = {
      oem_id:getOem(),
      company_id:getUser().com,
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/driver/getStarType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },data:temp,
    })
      .then(function (response) {
      /*   console.log(response.data); */
      setstarType(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetDriver_person = async () => {
    let temp = {
      company_id:getUser().com,
      oem_id:getOem(),
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/driver/getDriver_person",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },data:temp,
    })
      .then(function (response) {
         console.log(response.data); 
         setlist_person(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function pad(d) {
    if(d < 10){
        return '00' + d.toString();
    }else if (d< 100){
        return '0'+d.toString();
    }else{
      return d.toString();
    }
    
}

  const GetDriver = async () => {
    let temp = {
      oem_id:getOem(),
      company_id:getUser().com,
    }
    //console.log(filler_project);
    axios({
      method: "post",
      url: Configs.API_URL + "/driver/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        //console.log(response.data);
       
        var tempSort = response.data;
        for(let sort of tempSort){
          //sort.x = moment(sort.logistic_date).format('yyyy') + moment(sort.logistic_date).format('MM') + moment(sort.logistic_date).format('DD') + pad(sort.delivery_sort);
          sort.x = sort.plate_number;
        }
        tempSort.sort((a,b)=> a.x.localeCompare(b.x));
       // tempSort.sort((a,b)=> a.delivery_sort - b.delivery_sort);
       //  tempSort.sort((a,b)=> a.star_data.length - b.star_data.length); 
        setdriver(tempSort);
        /* var tempHaveStar = [];
        for(let item of tempSort){
          if(item.star_data.length > 0){
            tempHaveStar.push(item);
          }
        } */
       
        //console.log(bill_p)
        
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function save_personByBill(index,e){
  
    var tempData = driver;
    var temp = {
      driver:tempData[index],
      driver_person:driver_person,
    };
    console.log(index,temp);
    axios({
      method: "post",
      url: Configs.API_URL + "/driver/saveDriver_person",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetDriver();
          });
        }
        
     
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }

  function save_cancleBill(index,e){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var tempData = driver;
        var temp = {
          driver:tempData[index],
          driver_person:driver_person,
          remark:cancleBill.remark,
        };
        console.log(index,temp);
        axios({
          method: "post",
          url: Configs.API_URL + "/driver/cancleBillDriver",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                GetDriver();
              });
            }
            
         
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      })
   
    
  }


  function save_StarRate(index,e){
 console.log(index)
    var tempData = driver;
    var temp = {
      driver:tempData[index],
      star_Value:starValue,
      oem_id:getOem(),
      company_id:getUser().com,
      end_user:select_star.end_user,
    };
    console.log(index,temp);
    axios({
      method: "post",
      url: Configs.API_URL + "/driver/saveStar",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetDriver();
          });
        }
        
     
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }

  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  useEffect(() => {
    GetEnd_user();
    GetStarType();
    GetDriver_person();
    GetDriver();
    GetCompany();

    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
          if (el.id === getOem()) {
            setCurrentOEM({ label: el.name, value: el.id });
          }
        });
       
        console.log(options);
        setCompanyGroup(options);
      })
      .catch(function (error) {
        console.log(error);
      });



  }, []);

  
  function searchArrayEnd(key){
    var data_end = end_user.filter((e)=>{
      return e.name === key
    }); 
    var enduser_ = "No End user!!!";
    var enduser_id = "";
    if(data_end.length > 0){
      enduser_ = data_end[0].name +"    "+ data_end[0].fullname;
      enduser_id = data_end[0].id;
    }
    
    
    setselect_star({...select_star,end_user:enduser_id});
    select_star.end_user = enduser_id
    setsearch_cus_end({...search_cus_end,result_end:enduser_});
    setsearch_cus_end.result_end = enduser_;
    //console.log(customer_);
  }
  

  const rowsData = [];
  
  function SetTimeOut(index,e){
    console.log(index)
    console.log(jugde.name)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var tempData = driver;
        var temp_ = tempData.filter((e)=>{return e.delivery_sort === tempData[index].delivery_sort && e.logistic_car_id === tempData[index].logistic_car_id });
        var temp_2 = {
          data:temp_,
          approve_person:jugde.name,
        }
        console.log(temp_)
        axios({
          method: "post",
          url: Configs.API_URL + "/driver/saveTime_Out",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_2
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                GetDriver();
                setjugde({...jugde,index:"",name:"",});
                jugde.index = "";
                jugde.name = "";
              });
            }
            
         
          })
          .catch(function (error) {
            console.log(error);
          });
    
      }
    })
    
  }
  function SetTimeIn(index,e){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, confirm it!'
    }).then((result) => {
      if (result.isConfirmed) {
        var tempData = driver;
        var temp_ = tempData.filter((e)=>{return e.delivery_sort === tempData[index].delivery_sort && e.logistic_car_id ===tempData[index].logistic_car_id });
       
        axios({
          method: "post",
          url: Configs.API_URL + "/driver/saveTime_In",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                GetDriver();
              });
            }
            
         
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    })
   
}



const [customer_star,setcustomer_star] = useState({
  name:""
});

const [bill_detail,setbill_detail] = useState({
    bill_no:"",
    bill_oem_id:"",
    bill_values:"",
    bill_destination:"",
    create_bill:"",
    create_time:"",
    delivery_bill:"",
    delivery_time:"",
    customer_name:"",
    weight:"",
    remark:"",
    status_bill:"",
});
  
function formBillDetail(data_detail,e){
console.log(data_detail)
setbill_detail({...bill_detail,
    bill_no:data_detail.bill_no,
    bill_oem_id:data_detail.bill_oem_id,
    bill_values:data_detail.bill_values,
    bill_destination: data_detail.bill_destination,
    create_bill:data_detail.create_bill,
    create_time:data_detail.create_time,
    delivery_bill:data_detail.delivery_bill,
    delivery_time:data_detail.delivery_time,
    customer_name:data_detail.customer_name,
    weight:data_detail.weight,
    remark:data_detail.remark,
    status_bill:data_detail.is_pack === true ? "จัดแล้ว":"ยังไม่ได้จัด",
});
settest({...test,test:""});
test.test = "";

}

async function formStar(index,bill_no,star_data,customer_name,e){
  //console.log( index,bill_no,star_data)
  setcustomer_star({...customer_star,name:customer_name});
  customer_star.name = customer_name;
  var tempData = starType;
  await setstarType([]);
  setsearch_cus_end({...search_cus_end,result_end:""});
  search_cus_end.result_end = "";
 
  //STAR.current.setCurrentValue = 0;
  var tempData2 = [];
  for(let i of tempData){
    var target = star_data.filter((e)=>{
      return e.star_type_id === i.id;
    })
    var star_v = "";
    if(target.length>0){
      star_v = target[0].star_value
    }
    i.star_Value = star_v;
    let temp = {
      id_star:i.id,
      star_name:i.star_type_name,
      values:star_v,
    }
    tempData2.push(temp);

  }
  
  var enduser_ = "No End user!!!";
  var data_end = end_user.filter((e)=>{
    return e.id === driver[index].end_user;
  })
 
  if(data_end.length > 0){
    enduser_ = data_end[0].name +"    "+ data_end[0].fullname;
   
  }
  
  console.log(enduser_)
 
  setsearch_cus_end({...search_cus_end,result_end:enduser_});
  setsearch_cus_end.result_end = enduser_;
setstarValue(tempData2);
setstarType(tempData);
setselect_star({...select_star,
  id:driver[index].id,
  index:index,
  bill_id:driver[index].bill_id,
  delivery_sort:driver[index].delivery_sort,
  date:driver[index].logistic_date,
  plate_number: driver[index].plate_number,
  bill_no:driver[index].bill_no,
  end_user:driver[index].end_user,
})
select_star.id = driver[index].id;
select_star.index = index;
select_star.bill_id = driver[index].bill_id;
select_star.delivery_sort = driver[index].delivery_sort;
select_star.date = driver[index].logistic_date;
select_star.plate_number = driver[index].plate_number;
select_star.bill_no = driver[index].bill_no;
select_star.end_user = driver[index].end_user;


//console.log(tempData2)
}


function ratingChanged (index,newRating){
  console.log(index,newRating);
  var tempData = starValue;
  console.log(starValue)
  setstarValue([]);
  tempData[index].values = newRating;
  setstarValue(tempData);
  settest({...test,test:""});
  test.test = "";

};


  for (var index = 0; index < driver.length; index++) {
    const rowItem = {};
    rowItem["delivery_sort"] = driver[index].delivery_sort;
    rowItem["logistic_date"] = moment(driver[index].logistic_date).format('DD/MM/yyyy');
    rowItem["plate_number"] = driver[index].plate_number;
 
    rowItem["bill_no"] = (

    <a  data-toggle="modal"
    data-target="#modal-bill_detail"
    href = ""
    onClick={formBillDetail.bind(this,driver[index].bill_detail)}
    >
          {driver[index].bill_no}
   
    </a>);
    var dis_in = true;
    var  dis_star = false;
  /*   var dis_out = true;
    if(driver[index].person.length > 0){
      dis_out = false
    } */

    var time_out = moment(driver[index].start_time).format('YYYY-MM-DD HH:mm:ss');
    var disin = false;
    var disout = false;
   /*  var check_cancle = driver.filter((e)=>{
      return e.logistic_car_id=== driver[index].logistic_car_id && e.start_time && !e.finish_time
    }) */
    var checkout = driver.filter((e)=>{
      return e.logistic_car_id === driver[index].logistic_car_id && e.start_time && !e.finish_time && e.remark === null
    })
    //console.log(driver[index].driver_person_data.length)
    if( checkout.length > 0 || driver[index].driver_person_data.length === 0){
      disout = true;
    }
   

    if(!driver[index].start_time){
      disin= true;
    }
 /*    if(!driver[index].finish_time){
      var time_out = (  <button
        type="button"
        className="btn btn-block btn-info"
        disabled = {disout}
        onClick={SetTimeOut.bind(this,index)}
      >
        ออก
      </button>);
    } */
 /*    approve_person */
    var time_out = (
      <>
       <div>{moment(driver[index].start_time).format('DD/MM/yyyy :: HH:mm น.')}</div>
       <hr/>
    <div>{driver[index].approve_person || ""}</div>
      </>
   
    );
  
    if(( driver[index].start_time === "" || driver[index].start_time === null) /* || !driver[index].finish_time */){
    
        dis_in = true;
        dis_star = true;
      var time_out = (  <button
        disabled={disout}
        type="button"
        className="btn btn-block btn-info"
          id = {index}
        data-toggle="modal"
        data-target="#modal-input_the_judge"
        onClick={formJugde.bind(this,"ทดสอบชื่อผู้ตรวจ")}
        //onClick={SetTimeOut.bind(this,index)}
      >
        ออกจากบริษัท
      </button>);
    }

   
   
    var check_2 = driver.filter((e)=>{ return e.logistic_car_id === driver[index].logistic_car_id && e.star_data.length === 0 && e.delivery_sort === driver[index].delivery_sort && moment(e.logistic_date).format('DD/MM/yyyy') ===  moment(driver[index].logistic_date).format('DD/MM/yyyy') && e.remark === null});
    var time_in = 
    moment(driver[index].finish_time).format('DD/MM/yyyy :: HH:mm น.');
   if(driver[index].star_data.length > 0 && check_2.length === 0){
    dis_in = false
   }
  if( driver[index].finish_time === "" || driver[index].finish_time === null){
    var time_in = (  <button
    disabled = {dis_in}
      type="button"
      className="btn btn-block btn-warning"
      onClick={SetTimeIn.bind(this,index)}
    >
      เข้าบริษัท
    </button>);
  }
 
  
 
     //console.log(driver[index].driver_person_data);
    rowItem["driver"] = driver[index].driver_person_data.map((el, i) => {
      let name_driver = list_person.filter((nd)=>{return nd.id === el.driver_person_id});
      let name_driver_ = ""
      if(name_driver.length > 0){
        name_driver_ = name_driver[0].driver_name_th;
      }
      return (
        <div>
          {el.driver_person_id === null ?(
            <>
          {el.remark}{" "}
          </>
          ):(
            <>
            {name_driver_}{" "}
            </>
          )}
      
        {/*   {i < SparePart[index].product_ref.length - 1 ? "," : ""} */}
        </div>
      );
    });
  
  
   var rate = (
    <button
  
      type="button"
      className="btn btn-block btn-info"
      disabled = {dis_star}
      data-toggle="modal"
      data-target="#modal-add_rate"
      onClick={formStar.bind(this,  
        index,
        driver[index].bill_no,
        driver[index].star_data,
        driver[index].customer_name
        )}
    >
     ประเมิน
    </button>
   );
  
   if(driver[index].star_data.length > 0){
     rate = (
      <button
  
      type="button"
      className="btn btn-block btn-success"
      disabled = {true}
  /*     data-toggle="modal"
      data-target="#modal-add_rate"
      onClick={formStar.bind(this,
        index,
        driver[index].bill_no,
        driver[index].star_data,
        driver[index].customer_name)} */
    >
     ประเมินแล้ว
    </button>
     );
   }

  
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6">
          <a
              type="button"
    
                    data-toggle="modal"
                    data-target="#modal-add_driver"
            className=" btn  btn-block btn-xs "
            id = {driver[index].bill_no}
 
            onClick={form_person.bind(
              this,
              index,
              driver[index].bill_no,
              driver[index].driver_person_data,
              driver[index].start_time || "",
            )}
          >
            <i class="fa fa-user"></i>
          </a>
        </div>
                {driver[index].start_time !== null && driver[index].star_data.length == 0 ? (
                    <div className="col-6">
                    <a
                        type="button"
                              disabled={driver[index].start_time === null ? true : false}
                              data-toggle="modal"
                              data-target="#modal-cancleBill"
                      className=" btn  btn-block btn-xs "
                      id = {driver[index].bill_no}
          
                      onClick={form_cancle.bind(
                        this,
                        index,
                        driver[index].bill_no,
                        driver[index].driver_person_data,
                        driver[index].star_data.length,
                      )}
                    >
                <i class="fas fa-times-circle"></i>
                    
                    </a>
                  </div>
                ) : (<></>)}
      
    
      </div>
    );
    if(driver[index].remark !== null){
      time_out = <font color = "red">ยกเลิก</font>;
      time_in = <font color = "red">ยกเลิก</font>;
      rate =  <font color = "red">ยกเลิก</font>;
      rowItem["mgt"] = <font color = "red">ยกเลิก</font>;
    }
  
    rowItem["time_out"] = time_out;
    rowItem["time_in"] = time_in;
    rowItem["rate"] = rate;
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "ทะเบียน",
        field: "plate_number",
        sort: "asc",
        width: 50,
        
      },
    /*   {
        label: "วันที่",
        field: "logistic_date",
        sort: "asc",
        width: 50,
      }, */
  /*     {
        label: "เที่ยวที่",
        field: "delivery_sort",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "ใบส่งของ",
        field: "bill_no",
        sort: "asc",
        width: 50,
      },
  /*     {
        label: "เวลาออก",
        field: "time_out",
        sort: "asc",
        width: 50,
      },
      {
        label: "เวลาเข้า",
        field: "time_in",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "ผู้ขับ",
        field: "driver",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเมิน",
        field: "rate",
        sort: "asc",
        width: 50,
      },
    /*   {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      } */
    ],
    rows: rowsData,
  };

 
  function add_person_driver(){
   var t = driver_person;
   setdriver_person([]);
   

   t.push(statusAdd_driver);
   setdriver_person(t);
   settest({...test,test:""});
   test.test = "";
   setstatusAdd_driver({...statusAdd_driver,
     id_person:"",
   person_name:"",
   });
   statusAdd_driver.id_person = "";
   statusAdd_driver.person_name = "";
  }
  function del_personDriver(e){
    var temp = driver_person;
    setdriver_person([]);
    temp.splice(e.target.id, 1);
    setdriver_person(temp);
    settest({...test,test:""});
    test.test = "";
  }


  function Changedriver_person(e){ 
    settextChange({...textChange,value:e.target.value});  
    textChange.value = e.target.value;                                                                                                                         
    var temp = driver_person;
/*     setdriver_person([]);
    console.log(e.target.id) */
    if(temp[e.target.id].status ==="คนขับ"){
      temp[e.target.id].id_person = e.target.value;
    }else{
          
      temp[e.target.id].person_name = textChange.value;

    }


    console.log(temp)
    setdriver_person(temp);
    settest({...test,test:""});
    test.test = "";
  }
  
  function form_person(index,bill_no,driver_person_data,start_time,e){ 
  console.log(index,bill_no,driver_person_data) 
 
  var temp_driver  = [];
  for(let item of driver_person_data){

    let temp_ = {
      status:item.driver_person_id == null ? "ผู้ช่วย" : "คนขับ",
      id_person:item.driver_person_id || "",
      person_name:item.remark || "",
    }
    temp_driver.push(temp_);
  } 
/*   setdriver_person([]); */

 
  
  setstatusAdd_driver({...statusAdd_driver,
    status:"คนขับ",
    id_person:"",
    person_name:"",  
  });
  statusAdd_driver.status= "คนขับ"; 
  statusAdd_driver.id_person = "";
  statusAdd_driver.person_name = "";
  let temp2 = [];
let temp = {
  status:"คนขับ",
  id_person:"",
  person_name:""
}
  temp2.push(temp);
  temp2.push(statusAdd_driver);
  setdriver_person(temp2);
/*   settest({...test,test:""});
  test.test = ""; */
if(driver_person_data.length > 0){
  setdriver_person([]);
 setdriver_person(temp_driver)
}
var dis = false ;
if(start_time !== ""){
  dis = true
}
   setselect_per({...select_per,
    id:driver[index].id,
    index:index,
    delivery_sort:driver[index].delivery_sort,
    date:driver[index].logistic_date,
    plate_number: driver[index].plate_number,
    bill_no:driver[index].bill_no,
    dis:dis,
  })
  select_per.id = driver[index].id;
  select_per.index = index;
  select_per.delivery_sort = driver[index].delivery_sort;
  select_per.date = driver[index].logistic_date;
  select_per.plate_number = driver[index].plate_number;
  select_per.bill_no = driver[index].bill_no;
  select_per.dis = dis;



  }


  function form_cancle(index,bill_no,driver_person_data,star,e){ 
    //console.log(index,bill_no,driver_person_data) 
   

   
   

     setcancleBill({...cancleBill,
      id:driver[index].id,
      index:index,
      delivery_sort:driver[index].delivery_sort,
      date:driver[index].logistic_date,
      plate_number: driver[index].plate_number,
      bill_no:driver[index].bill_no,
      remark:driver[index].remark || "",
    })
    cancleBill.id = driver[index].id;
    cancleBill.index = index;
    cancleBill.delivery_sort = driver[index].delivery_sort;
    cancleBill.date = driver[index].logistic_date;
    cancleBill.plate_number = driver[index].plate_number;
    cancleBill.bill_no = driver[index].bill_no;
    cancleBill.remark = driver[index].remark || "";
  
  
  
    }

    function formJugde(name,e){
      console.log(name)
      console.log(e.target.id)
      setjugde({...jugde,name:name,index:e.target.id});

      jugde.name = name;
      jugde.index = e.target.id;
    }

  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>พนักงานขับรถ{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
                
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse management
                  </li>
                  <li className="breadcrumb-item active">พนักงานขับรถ</li>
                </ol>
              </div>
            </div>
          </div>

       
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">พนักงานขับรถ</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body  "
            >
                <div className="row">
                    <div className="col-xl-3 col-md-6 col-sm-12">
                    <button 
                    className="btn btn-block btn-primary"
                    onClick={GetDriver}
                    >
                        โหลดข้อมูลอีกครั้ง
                        </button>
                    </div>
                </div>
               <br/>
                
                <div
            className="table-responsive"
            style={{whiteSpace: 'nowrap'}}   
   /*          style={{ height: "350px" }} */
          >
              <MDBDataTable
                className="table table-head-fixed"
                sortable = {false}
                disableRetreatAfterSorting={true}
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
                </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>













      <div className="modal fade" id="modal-add_rate">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">ประเมิน</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
              <div className="col-md-12" style={{textAlign: "center" }}>
                <h1>คุณ {customer_star.name}</h1>
                </div>
                </div>

                <div className="row">
              <div className="col-md-12" style={{textAlign: "center" }}>
                <h2>รบกวนให้คะแนน<br/>ความพึงพอใจ</h2>
                </div>

             
                 
                <div className="col-2"></div>
                       
                          <div className="col-8">
                            <div class="form-group">
                            <div className="col-12" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <Select
                    placeholder=""
                   /*  disabled = {starType.filter((e2)=> {return e2.star_Value !== ""}).length > 0  ? true : false} */
                   isDisabled={starType.filter((e2)=> {return e2.star_Value !== ""}).length > 0  ? true : false}
                    options={end_user}
                    //value={select_star.end_user}
                    value={end_user.filter((e) => {
                      return e.value === select_star.end_user;
                    })}
               
                    onChange={(e) => {
                      // console.log(e);
                      if (e !== null) {
                        setselect_star({
                          ...select_star,
                          end_user: e.value,
                        });
                        select_star.end_user = e.value;
                      }

                      //search_data();
                    }}
                  />
                  <label htmlFor="">End User</label>
                </div>
              </div>
                      {/*     <INF value={search_cus_end.result_end} disabled={true}  label={"Result End user"}  /> */}
                          </div>
                          </div>
                <button
                 disabled = {starType.filter((e2)=> {return e2.star_Value !== ""}).length > 0  ? true : false}
                    //  disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                    onClick={save_StarRate.bind(this,select_star.index)}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  ไม่แสดงความคิดเห็น
                  </button>
                </div>
             
                {starType.map((e,index)=>{
                    
             //console.log(e)
                    let dis_star = starType.filter((e2)=> {return e2.star_Value !== ""});

        
              return (
                <>
                  <div className="row">
               
      <div className="col-md-10">
      <h3>{e.star_type_name}{/* {e.star_Value} */}</h3>
      </div>
      <div className="col-md-10" style={{textAlign: "center" }}>

 
     <ReactStars
     count={5}
     edit = {dis_star.length > 0 ? false : true}
     /* edit = {select_sat} */
     //setCurrentValue = {star_val}
     value = {e.star_Value}
     onChange={ratingChanged.bind(this,index)}
     size={50}
     activeColor="#ffd700"
   />




         </div>
      <div className="col-md-1"></div>
      </div>
                </>
          );
       
                    
               
                
                })}
               

            
              
               
              <div className="row">
                <div className="col-1"></div>
                
                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
            
                 
                    <button
                    //  disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                    disabled = {
                      
                      starType.filter((e2)=> {return e2.star_Value !== ""}).length > 0  ? true : false}
                    onClick={save_StarRate.bind(this,select_star.index)}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  บันทึก
                  </button>
                  
                 {/*  </>
                  )}
                      */}
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>




      <div className="modal fade" id="modal-add_driver">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">เพิ่มผู้ขับ   [{select_per.plate_number}]</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
            <div className="col-md-12">
                <div className="form-group ">
                  <select
                  disabled = {select_per.dis}
                    className="custom-select select2 form-control"
                    value={statusAdd_driver.status}
                    onChange={(e)=>{setstatusAdd_driver({...statusAdd_driver,status:e.target.value});
                    //statusAdd_driver.status = e.target.value;
                  }}
                  >
                    <option value="">--เลือกประเภท--</option>
                    <option value="คนขับ">คนขับ</option>
                    <option value="ผู้ช่วย">ผู้ช่วย</option>
            
                  </select>
                  <label htmlFor="">ประเภท</label>
                </div>
              </div>
              </div>

              
               
              <div className="row">
                <div className="col-3">
                 
                <button
                       disabled = {select_per.dis}
        type="button"
        className="btn btn-block btn-info"
        onClick={add_person_driver}
      >
        เพิ่ม
      </button>
                </div>
                
                
              </div>
                      <br/>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  {driver_person.map((e,index)=>{
                   

                    if(e.status === "คนขับ"){
                      return(<>
     
               
                        <div className="row">
                
                        <div className="col-md-10">
            <div className="form-group ">
              <select
                className="custom-select select2 form-control"
                id={index}
                disabled = {select_per.dis}
                value = {e.id_person}
                onChange={Changedriver_person.bind(this)}
              >
                 <option value="">--เลือกคนขับ--</option>
                {list_person.map((list)=>{
                  return (<option value={list.id}>{list.driver_name_th} ({list.nickname})</option>)
                })}
               
              
              {/*   <option value="นาย ข ขข">นาย ข ขข</option>
                <option value="นาย ค คค">นาย ค คค</option>
                <option value="นาย ง งง">นาย ง งง</option> */}
        
              </select>
              <label htmlFor="">เลือกคนขับ</label>
            </div>
          </div>
          <div className="col-md-2">
          <div className="form-group ">
          <button
                 disabled = {select_per.dis}
          id = {index}
    type="button"
    className="btn btn-block btn-danger"
    onClick={del_personDriver.bind(this)}
  >
    X
  </button>    </div>
            </div>
          </div>
                  </>)
                    }else{
                      return(<>
     
               
                        <div className="row">
                
                        <div className="col-md-10">
          
                    
                <div className="form-group ">
                  
                <input
                       disabled = {select_per.dis}
                    type="text"
                    className="form-control"
                    required="false"    
                    value={e.person_name}
                    id = {index}
  
                    onChange={Changedriver_person.bind(this)}
                  />
                  <label htmlFor="">ผู้ช่วย</label>{" "}
                </div>


          </div>
          <div className="col-md-2">
          <div className="form-group ">
          <button
                 disabled = {select_per.dis}
     id = {index}
     type="button"
     className="btn btn-block btn-danger"
     onClick={del_personDriver.bind(this)}
  >
    X
  </button>    </div>
            </div>
          </div>
                  </>)
                    }


                     
                  })}
                </div>
                
                
              </div>








            </div>
            <div className="modal-footer justify-content-between">
            
              
                    <button
         
                    type="button"
                   //disabled = {select_per.dis}
                   //disabled = {checkper_drive.length > 0 ? false : true}
                   disabled = {driver_person.filter((e)=>{return e.id_person !== ""}).length > 0 && select_per.dis === false ? false : true}
                   //disabled = {driver_person.filter((e)=>{return e.id_person === ""}).length > 0 ? true : false} 
                     onClick={save_personByBill.bind(this,select_per.index)} 
                   data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  บันทึก
                  </button>
                  
           
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>


      <div className="modal fade" id="modal-cancleBill">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">ยกเลิกการส่ง   [{cancleBill.plate_number}][{cancleBill.bill_no}]</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            <div className="row">
            <div className="col-md-12">
            <div className="form-group ">
                  
                  <input
                      disabled = {cancleBill.dis}
                      type="text"
                      className="form-control"
                      required="false"    
                      value={cancleBill.remark}
                      //id = {index}
    
                     onChange={(e)=>{setcancleBill({...cancleBill,remark:e.target.value})}}
                    />
                    <label htmlFor="">เหตุผลในการยกเลิก</label>{" "}
                  </div>
  
              </div>
              </div>
              
            






            </div>
            <div className="modal-footer justify-content-between">
            
              
                    <button
         
                    type="button"
                    disabled = {cancleBill.remark.trim() === "" ? true : false}
                     onClick={save_cancleBill.bind(this,cancleBill.index)} 
                   data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  บันทึก
                  </button>
                  
           
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>










      <div className="modal fade" id="modal-input_the_judge">
      <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">ลงชื่อผู้ตรวจ</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">

             
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={jugde.name}
                    onChange={(e) => {
                      setjugde({
                        ...jugde,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อผู้ตรวจ</label>{" "}
                </div>
       
                </div>
              </div>

            
              
               
              <div className="row">
                <div className="col-1"></div>
                
                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
            
                 
                    <button
                    //  disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                    type="button"
                 /*    disabled = {
                      
                      starType.filter((e2)=> {return e2.star_Value !== ""}).length > 0  ? true : false}
                    onClick={save_StarRate.bind(this,select_star.index)} */
                    disabled = {jugde.name.trim() === "" ? true : false}
                    onClick={SetTimeOut.bind(this,jugde.index)}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                  บันทึก
                  </button>
                  
                 {/*  </>
                  )}
                      */}
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
          
             
            </div>
          </div>
         
        </div>
       
      </div>




      <div className="modal fade" id="modal-bill_detail">
      <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">รายละเอียดใบส่งของ</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">
             
                  <h4>เลขที่ใบส่งของ{" : "}{bill_detail.bill_no || ""}</h4>
                  <h4>วันที่ออกบิล{" : "}{moment(bill_detail.create_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.create_time || "ไม่ระบุ"}{" "}น.</h4>
                  <h4>วันที่นัดส่งสินค้า{" : "}{moment(bill_detail.delivery_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.delivery_time || "ไม่ระบุ"}{" "}น.</h4>
                  <h4>ชื่อลูกค้า{" : "}{bill_detail.customer_name || "ไม่ระบุ"}</h4>
                  <h4>หน้างาน{" : "}{bill_detail.bill_destination || "ไม่ระบุ"}</h4>
                  <h4>มูลค่า{" : "}{bill_detail.bill_values || "ไม่ระบุ"}</h4>
                  <h4>{getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์"? "จำนวนคิว":"น้ำหนัก(ตัน)"}{" : "}{bill_detail.weight || "ไม่ระบุ"}</h4>
                  <h4>สถานะ{" : "}{bill_detail.status_bill || "ไม่ระบุ"}</h4>
                  <h4>หมายเหตุ{" : "}{bill_detail.remark || "ไม่ระบุ"}</h4>
       
                </div>
              </div>

            
              
           
            </div>
            <div className="modal-footer "align="right">
            
                 
               <div className="row">
              {/*      <div className="col-12" align="right"> */}
                   <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
                {/*    </div> */}
               </div>
             
              
          
             
            </div>
          </div>
         
        </div>
       
      </div>











   {/*   <Footter/> */}
     </div>
     
  );
}

export default Driver_person;
