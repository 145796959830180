import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import Construccomponent from "../../component/Construction";
import { MDBDataTable } from "mdbreact";

function Product_Grouping(params) {

    
  const rowsData = [];
  for (var index = 0; index < 5; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["processname"] = "processname"+index;
    rowItem["order"] = "order"+index;
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No_",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Process_Name",
        field: "processname",
        sort: "asc",
        width: 50,
      }
      ,
      {
        label: "Order",
        field: "order",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  };
    return(
        <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="pro_group" submenu="factmaster"/> */}
      <Construccomponent name="Product Grouping"/>
      {/* <div className="content-wrapper">
        
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Product Grouping</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                  Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product Grouping</li>
                </ol>
              </div>
            </div>
          </div>
         
         
        </section>
       
        <section className="content">
     
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Product Grouping</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body table-responsive ">
              
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              
            </div>
           
            <div className="card-footer"></div>
          
          </div>
        
        </section>
       
      </div> */}

 {/*      <Footter /> */}
    </div>
    );
}
export default Product_Grouping