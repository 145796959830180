import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { useParams } from "react-router";
import { getOem, getToken, getUser, getFeature } from "../../Utils/Common";
import TableLEK from "../../component/tableLEKTTT/table";

function Warehouse(params) {
  const [feature, setfeature] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [warehouse_product, setWarehouse_prodduct] = useState([]);
  const [warehouse_rack, setWarehouse_rack] = useState([]);
  const [Warehouse_rack_floor, setWarehouse_rack_floor] = useState([]);
  const [company, setCompany] = useState([]);
  const [relate_product, setRelate_product] = useState([]);
  const { target_u } = useParams();

  /* console.log(getUser().com); */
  const [filler_warehouse, setFiller_warehouse] = useState({
    code_no: "",
    location: "",
    company_id: getUser().com,
    all_search:''
  });
  const [filler_warehouse_rack, setFiller_warehouse_rack] = useState({
    code_no: "",
    location: "",
    company_id: getUser().com,
  });
  const [filler_warehouse_rack_floor, setFiller_warehouse_rack_floor] = useState({
    code_no: "",
    location: "",
    company_id: getUser().com,
  });
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);


  const GetWarehouse = async (page, size, search) => {

    //console.log(filler_customer);
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }
    var temps = {
      code_no: (filler_warehouse.code_no || '').trim(),
      location: filler_warehouse.location,
      company_id: getUser().com,
      page: page || 1,
      size: size || 10,
      all_search: (filler_warehouse.all_search || "").trim()
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temps
    })
      .then(function (response) {
        //console.log(response.data);
        setWarehouse(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        Swal.close();



      })
      .catch(function (error) {
        Swal.close();
        console.log(error);
      });
  };


  const GetWarehouse_rack = async () => {

    //console.log(filler_customer);
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter_rack",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_warehouse_rack
    })
      .then(function (response) {
        //console.log(response.data);
        setWarehouse_rack(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const GetWarehouse_rack_floor = async () => {

    //console.log(filler_customer);
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse/filter_rack_floor",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_warehouse_rack_floor
    })
      .then(function (response) {
        //console.log(response.data);
        setWarehouse_rack_floor(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  };





  useEffect(() => {
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Warehouse";
    });
    setfeature(feature_data);
    ////////permission feature//////////
    GetWarehouse(1, 10, 'search');
    GetWarehouse_rack();
    GetWarehouse_rack_floor();
    getWarehoueProduct();
  }, []);



  const clearFilter = async () => {
    await setFiller_warehouse({
      ...filler_warehouse,
      code_no: "",
      location: "",
      all_search:""

    });
    filler_warehouse.code_no = "";
    filler_warehouse.location = "";
    filler_warehouse.all_search = "";
    GetWarehouse(1, 10, 'search');


    await setFiller_warehouse_rack({
      ...filler_warehouse_rack,
      code_no: "",
      location: "",

    });
    filler_warehouse_rack.code_no = "";
    filler_warehouse_rack.location = "";
    GetWarehouse_rack();

    await setFiller_warehouse_rack_floor({
      ...filler_warehouse_rack_floor,
      code_no: "",
      location: "",

    });
    filler_warehouse_rack_floor.code_no = "";
    filler_warehouse_rack_floor.location = "";
    GetWarehouse_rack_floor();

  }


  function addpagewarehouse(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Warehouse/add";
  }
  function addpagerack(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Warehouse/add_rack";
  }
  function addpagerack_floor(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Warehouse/add_rack_floor";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/warehouse/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Warehouse/status/warehouse";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error.response.data.message, "error");
          });
      }
    });
  }


  function deldata_rack(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/warehouse/del_rack?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Warehouse/status/rack";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error.response.data.message, "error");
          });
      }
    });
  }


  function deldata_rack_floor(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/warehouse/del_rack_floor?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Warehouse/status/rack_floor";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error.response.data.message, "error");
          });
      }
    });
  }



  const rowsDatawarehouse = [];

  for (var index = 0; index < warehouse.length; index++) {
    //console.log(rowsData);
    const rowItem = {};
    /*     rowItem["no"] = index + 1; */
    rowItem["Code_no"] = warehouse[index].code_no;
    rowItem["Description"] = warehouse[index].description;
    rowItem["Location"] = warehouse[index].location;
    rowItem["Capacity"] = warehouse[index].capacity;
    let relate_product_temp = relate_product.filter((e) => {
      if (e.warehouse_id === warehouse[index].id) {
        return e.name;
      }
    });
    if (relate_product_temp.length > 0) {
      var relate_product_n = "";




      for (let r = 0; r < relate_product_temp.length; r++) {
        relate_product_n += relate_product_temp[r].name;
        if (r < relate_product_temp.length) {
          relate_product_n += ", ";
        }


      }
      rowItem["Relate_Product"] = relate_product_n;
    } else {
      rowItem["Relate_Product"] = "";
    }



    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Warehouse/read/" + warehouse[index].id}
            key={warehouse[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Warehouse/edit/" + warehouse[index].id}
            key={warehouse[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={warehouse[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, warehouse[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsDatawarehouse.push(rowItem);

  }

  const datawarehouse = {
    columns: [
      /*     {
            label: "No",
            field: "no",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "Warehouse No",
        field: "Code_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "Description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Location",
        field: "Location",
        sort: "asc",
        width: 50,
      },
      {
        label: "Capacity",
        field: "Capacity",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDatawarehouse,
  };







  const rowsDatarack = [];

  for (var index = 0; index < warehouse_rack.length; index++) {
    //console.log(rowsData);
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["Code_no"] = warehouse_rack[index].code_no;
    rowItem["Description"] = warehouse_rack[index].description;
    rowItem["Location"] = warehouse_rack[index].location;
    rowItem["Capacity"] = warehouse_rack[index].capacity;
    let relate_product_temp = relate_product.filter((e) => {
      if (e.warehouse_id === warehouse_rack[index].id) {
        return e.name;
      }
    });
    if (relate_product_temp.length > 0) {
      var relate_product_n = "";




      for (let r = 0; r < relate_product_temp.length; r++) {
        relate_product_n += relate_product_temp[r].name;
        if (r < relate_product_temp.length) {
          relate_product_n += ", ";
        }


      }
      rowItem["Relate_Product"] = relate_product_n;
    } else {
      rowItem["Relate_Product"] = "";
    }



    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Warehouse/read_rack/" + warehouse_rack[index].id}
            key={warehouse_rack[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Warehouse/edit_rack/" + warehouse_rack[index].id}
            key={warehouse_rack[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={warehouse_rack[index].id}
            className=" btn btn-xs "
            onClick={deldata_rack.bind(this, warehouse_rack[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsDatarack.push(rowItem);

  }

  const datarack = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Rack No",
        field: "Code_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "Description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Location",
        field: "Location",
        sort: "asc",
        width: 50,
      },
      {
        label: "Capacity",
        field: "Capacity",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDatarack,
  };


  function getWarehoueProduct() {
    axios({
      method: "get",
      url: Configs.API_URL + "/warehouse/getWarehoueProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setWarehouse_prodduct(response.data);
      }).catch(function (error) {
        console.log(error);
      });

  }




  const rowsDatarack_floor = [];
  console.log(Warehouse_rack_floor);
  for (var index = 0; index < Warehouse_rack_floor.length; index++) {
    //console.log(rowsData);
    const rowItem = {};
    var name_parent;
    var old_parent_id;
    rowItem["no"] = index + 1;
    rowItem["Code_no"] = Warehouse_rack_floor[index].code_no;
    rowItem["Description"] = Warehouse_rack_floor[index].description;
    rowItem["Location"] = Warehouse_rack_floor[index].location;
    var product = "";
    for (let i of warehouse_product) {
      if (i.warehouse_id === Warehouse_rack_floor[index].id) {
        product += i.no + ", ";
      }
    }
    console.log(product)
    rowItem["Product"] = product;
    rowItem["Capacity"] = Warehouse_rack_floor[index].capacity;
    for (var index2 = 0; index2 < warehouse_rack.length; index2++) {
      if (warehouse_rack[index2].id === Warehouse_rack_floor[index].parent_id) {

        name_parent = warehouse_rack[index2].code_no;
        old_parent_id = warehouse_rack[index2].parent_id;
      }
    }
    console.log("parent_id", Warehouse_rack_floor[index].parent_id)

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Warehouse/rack_floor/read/" +
              Warehouse_rack_floor[index].id +
              "&" +
              Warehouse_rack_floor[index].parent_id +
              "&" +
              old_parent_id
            }
            key={Warehouse_rack_floor[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={"/Warehouse_Management/Factory_Master_Data/Warehouse/rack_floor/edit/" +
              Warehouse_rack_floor[index].id +
              "&" +
              Warehouse_rack_floor[index].parent_id +
              "&" +
              old_parent_id
            }
            key={Warehouse_rack_floor[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={Warehouse_rack_floor[index].id}
            className=" btn btn-xs "
            onClick={deldata_rack_floor.bind(this, Warehouse_rack_floor[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsDatarack_floor.push(rowItem);

  }

  const datarack_floor = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Shelf no",
        field: "Code_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "Description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Location",
        field: "Location",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product",
        field: "Product",
        sort: "asc",
        width: 50,
      },
      {
        label: "Capacity",
        field: "Capacity",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDatarack_floor,
  };



  if (target_u === "warehouse") {
    var class_active_warehouse = "tab-pane fade show active";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_warehouse = "nav-link active";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link";

  } else if (target_u === "rack") {
    var class_active_warehouse = "tab-pane fade";
    var class_active_rack = "tab-pane fade show active";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_warehouse = "nav-link";
    var nav_active_rack = "nav-link active";
    var nav_active_rack_floor = "nav-link";

  } else if (target_u === "rack_floor") {
    var class_active_warehouse = "tab-pane fade";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade show active";

    var nav_active_warehouse = "nav-link";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link active";

  } else if (target_u === "shelf") {
    var class_active_warehouse = "tab-pane fade";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_warehouse = "nav-link";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link";

  } else {
    var class_active_warehouse = "tab-pane fade show active";
    var class_active_rack = "tab-pane fade";
    var class_active_rack_floor = "tab-pane fade";

    var nav_active_warehouse = "nav-link active";
    var nav_active_rack = "nav-link";
    var nav_active_rack_floor = "nav-link";

  }

  return (
    <div className="wrapper">
      {/*  <Header />
      <Sidebar menu="warehouse" activemenu="facpara" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={nav_active_warehouse}
                    id="custom-tabs-four-warehouse-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-warehouse"
                    role="tab"
                    aria-controls="custom-tabs-four-warehouse"
                    aria-selected="false"
                  >
                    Warehouse
                  </a>
                </li>
                {feature.map((e) => {
                  if (e.feature === "Rack_Tab") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={nav_active_rack}
                            id="custom-tabs-four-rack-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-rack"
                            role="tab"
                            aria-controls="custom-tabs-four-rack"
                            aria-selected="false"
                          >
                            Rack
                          </a>
                        </li>
                      </>
                    );
                  }
                })}
                {feature.map((e) => {
                  if (e.feature === "Product_On_Shelf_Tab") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={nav_active_rack_floor}
                            id="custom-tabs-four-rack_floor-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-rack_floor"
                            role="tab"
                            aria-controls="custom-tabs-four-rack_floor"
                            aria-selected="false"
                          >
                            Product on Shelf
                          </a>
                        </li>
                      </>
                    );
                  }
                })}


              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className={class_active_warehouse}
                  id="custom-tabs-four-warehouse"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-warehouse-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">

                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_warehouse.all_search}
                            onChange={(e) => {
                              setFiller_warehouse({
                                ...filler_warehouse,
                                all_search: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Search</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">

                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_warehouse.code_no}
                            onChange={(e) => {
                              setFiller_warehouse({
                                ...filler_warehouse,
                                code_no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Warehouse No</label>{" "}
                        </div>
                      </div>

                      {/* <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filler_warehouse.location}
                    onChange={(e) => {
                      setFiller_warehouse({
                        ...filler_warehouse,
                        location: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">location</label>{" "}
                </div>
              </div> */}

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              GetWarehouse(1, 10, 'search');
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => clearFilter()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-custom-content"></div>
                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addpagewarehouse}
                      >
                        Add
                      </button>
                    </div>
                  </div>
{/*                   <div className="row" style={{ border: '1px solid red' }}> */}
                 {/*    <div className="card"> */}
                      <div
                        className="card-body table-responsive "
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <TableLEK
                          headTable={datawarehouse.columns}
                          dataTable={rowsDatawarehouse}
                          countPage={countPage}
                          countData={countData}
                          entries={entries}
                          isSearch={search}
                          callFnc={GetWarehouse}


                        />
                      </div> {/* </div> */}

                          {/* <MDBDataTable
                    sortable = {false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={datawarehouse}
                    /> */}
              {/*     </div> */}
                  {/*   <div className="tab-custom-content"></div>
                  <div className="row">

                  </div> */}
                </div>
                <div
                  className={class_active_rack}
                  id="custom-tabs-four-rack"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-rack-tab"
                >
                  <h3 class="title ng-binding">
                    Warehouse &gt; Rack
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">

                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_warehouse_rack.code_no.trim()}
                            onChange={(e) => {
                              setFiller_warehouse_rack({
                                ...filler_warehouse_rack,
                                code_no: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Rack No</label>{" "}
                        </div>
                      </div>

                      {/* <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filler_warehouse.location}
                    onChange={(e) => {
                      setFiller_warehouse_rack({
                        ...filler_warehouse,
                        location: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">location</label>{" "}
                </div>
              </div> */}

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              GetWarehouse_rack();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => clearFilter()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4"></div>
                  </div>
                  <div className="tab-custom-content"></div>
                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addpagerack}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={datarack}
                    />
                  </div>
                </div>
                <div
                  className={class_active_rack_floor}
                  id="custom-tabs-four-rack_floor"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-rack_floor-tab"
                >
                  <h3 class="title ng-binding">Warehouse &gt; Shelf</h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">

                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_warehouse_rack_floor.code_no.trim()}
                            onChange={(e) => {
                              setFiller_warehouse_rack_floor({
                                ...filler_warehouse_rack_floor,
                                code_no: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Shelf No</label>{" "}
                        </div>
                      </div>

                      {/* <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filler_warehouse.location}
                    onChange={(e) => {
                      setFiller_warehouse({
                        ...filler_warehouse,
                        location: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">location</label>{" "}
                </div>
              </div> */}

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              GetWarehouse_rack_floor();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => clearFilter()}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4"></div>
                  </div>
                  <div className="tab-custom-content"></div>
                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addpagerack_floor}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={datarack_floor}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

  );
}

export default Warehouse;
