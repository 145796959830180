import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";

function AEV_Knowledge_Management({ mode }) {
  const { id } = useParams();

  const [pageMode, setPageMode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const [inputData, setInputData] = useState({
    KM_Name: "",
    KM_Category: "",
    KM_Url: "",
    KM_Description: "",
    KM_Document: {
      File_Name: "",
      File_Path: "",
    },
  });

  const [KMdata, setKMdata] = useState({
    data: [],
  });

  const [documentUploadList, setDocumentUploadList] = useState({
    data: [],
    new: [],
    old: [],
    del: [],
  });

  const allowedTypes = [
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/png",
    "image/jpeg"
  ];

  const uploadRef = useRef(null);

  const [MDBT, setMDBT] = useState(true);

  const [KMcategoryDD, setKMcategoryDD] = useState([]);

  function saveOption() {
    const errorList = [];
    let checkerrorName = []
    for (let i = 0; i < KMdata.data.length; i++) {
      if (mode === "add") {
        if (inputData.KM_Name.trim() === KMdata.data[i].km_name) {
          checkerrorName.push("error");
        }
      } else {
        if (
          inputData.KM_Name.trim() === KMdata.data[i].km_name &&
          id !== KMdata.data[i].km_id
        ) {
          checkerrorName.push("error"); 
        }
      }
    }
    if (checkerrorName.length > 0) {
      errorList.push("KM Name ซํ้า กรุณากรอกใหม่อีกครั้ง");
    }
    if (inputData.KM_Name.trim() === "" || inputData.KM_Name.trim() === " ") {
      errorList.push("กรุณากรอก KM Name");
    }
    if (inputData.KM_Category === "") {
      errorList.push("กรุณาเลือก KM Category");
    }
    if (errorList.length > 0) {
      let err_message = "";
      for (let index = 0; index < errorList.length; index++) {
        err_message += errorList[index] + "<br>";
      }
      Swal.fire({
        icon: "error",
        title: "Error!",
        html: err_message,
        confirmButtonText: "OK",
      });
      return;
    } else {
      if (mode === "add") {
        addKM();
      } else if (mode === "edit") {
        editKM();
      }
    }
  }

  const delKMFile = async () => {
    console.log('documentUploadList', documentUploadList.del.length)
    if (documentUploadList.del.length > 0) {
      let temp = {
        del_list: documentUploadList.del,
        user_id: getUser().fup,
        user_name: `${getUser().name} ${getUser().surname}`
      }
      await axios({
        method: "post",
        url: Configs.API_URL_IMG_cuteplus + "/api/upload/delImagesAndFiles",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: temp,
      })
        .then(async (response) => {
        })
        .catch(async (error) => {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาดในการลบเอกสาร",
          });
        });
    } else {
      await uploadAndSaveKMdoc(id, "file_km");
    }
  };

  const uploadAndSaveKMdoc = async (km_id, fileGroup) => {
    if (fileGroup === "file_km") {
      let data = new FormData();
      await documentUploadList.new?.forEach(async el => {
        data.append("Profile", el.km_image);
        data.append("ProfileName", el.km_document_name);
      });
      data.append("company_id", getUser().com);
      data.append("oem_id", getOem());
      data.append("user_id", getUser().fup);
      data.append("user_name", `${getUser().name} ${getUser().surname}`);
      data.append("Project", "Cute_Products_Plus");
      data.append("Menu", "Knowledge management");
      data.append("Ref", km_id);
      data.append("group", "file_km");

      await axios({
        method: "post",
        url: Configs.API_URL_IMG_cuteplus + "/api/upload/saveDataUploadFile",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      })
        .then(async (response) => {
          // console.log('doc', documentUploadList)
        })
        .catch(async (error) => {
          // console.log("error", error);
          Swal.fire({
            icon: "error",
            title: "ไม่สามารถอัปโหลดเอกสารได้",
          });
        });
    }
  }

  async function GetKM_basic() {
    let temp = {
        company_id: getUser().com,
        oem_id: getOem(),
        name: null
    }
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/km/getKMfilter",
      headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
          setKMdata({ data: response.data });
          KMdata.data = response.data;
          console.log('KMdata', KMdata.data)
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getFileKM = async () => {
    await axios({
      method: "get",
      url:
        Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileGroupAll/" + id + "/file_km",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData1,
    })
      .then(async (response) => {
        console.log(response.data);
        if (response.data.length > 0) {
          const filter_file = response.data.filter((el) => el.ul_is_use === true && el.ul_is_active === true);
          const file_list = filter_file?.map((el) => {
            return {
              ul_id: el.ul_id,
              km_document_name: el.ul_file_name,
              km_path_document: el.ul_path_file,
              oldNew: "old"
            };
          });
          setDocumentUploadList({
            ...documentUploadList,
            data: file_list,
          });
          documentUploadList.data = file_list;
          // console.log('file_list', documentUploadList.data);
        }
      })
      .catch(async (error) => {
        console.log("error", error);
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาดในการดึงข้อมูลเอกสาร",
        });
      });
  };

  function addKM() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        let savedData = {
          oem_id: getOem(),
          company_id: getUser().com,
          user_id: getUser().fup,
          user_name: getUser().name + getUser().surname,
          KM_Name: inputData.KM_Name !== "" ? inputData.KM_Name.trim() : null,
          KM_Category:
            inputData.KM_Category !== "" ? inputData.KM_Category : null,
          KM_Url: inputData.KM_Url !== "" ? inputData.KM_Url.trim() : null,
          KM_Description:
            inputData.KM_Description !== ""
              ? inputData.KM_Description.trim()
              : null,
        };
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/km/saveKMdata",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: savedData,
        })
          .then(async function (response) {
            if (response.data) {
              await uploadAndSaveKMdoc(response.data, "file_km");
            }
          })
          .then(async function (response) {
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href =
                "/Warehouse_Management/Knowledge_management_Cuteplus";
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  function editKM() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log("confirm Edit");
        let data = {
          km_id: id,
          oem_id: getOem(),
          company_id: getUser().com,
          user_id: getUser().fup,
          user_name: getUser().name + getUser().surname,
          KM_Name: inputData.KM_Name !== "" ? inputData.KM_Name.trim() : null,
          KM_Category:
            inputData.KM_Category !== "" ? inputData.KM_Category : null,
          KM_Url: inputData.KM_Url !== "" ? inputData.KM_Url.trim() : null,
          KM_Description:
            inputData.KM_Description !== ""
              ? inputData.KM_Description.trim()
              : null,
          // KM_Document:
          //   inputData.KM_Document !== ""
          //     ? inputData.KM_Document
          //     : null,
        };
        // console.log("edit", data);
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/km/editKMdata",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(async function (response) {
            if (response.data) {
              if (mode === "edit" && documentUploadList.data.length > 0) {
                await delKMFile();
              }
            }
          })
          .then(async function (response) {
            Swal.fire({
              icon: "success",
              title: "บันทึกข้อมูลสำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href =
                "/Warehouse_Management/Knowledge_management_Cuteplus";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  function GetKMDetail() {
    let data = {
      km_id: id,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/km/getKMDetail",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          response.data = response.data[0];
          setInputData({
            ...inputData,
            KM_Name:
              response.data.km_name !== null ? response.data.km_name : "",
            KM_Category:
              response.data.km_ck !== null ? response.data.km_ck : "",
            KM_Url: response.data.km_url !== null ? response.data.km_url : "",
            KM_Description:
              response.data.km_description !== null
                ? response.data.km_description
                : "",
            // KM_Document:
            //   response.data.lgt_gross_weight !== null ? response.data.lgt_gross_weight : "",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function cancelOption() {
    if (mode === "view") {
      window.location.href = "/Warehouse_Management/Knowledge_management_Cuteplus";
    } else {
      Swal.fire({
        title: "คุณต้องการยกเลิกหรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
            "/Warehouse_Management/Knowledge_management_Cuteplus";
        }
      });
    }
  }

  async function getDropdownKMcategory() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/km/GetKMcategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setKMcategoryDD(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function isFileImage(file) {
    return file && allowedTypes.includes(file["type"]);
  }

  async function encodeImage(file) {
    // console.log("file", file);
    if (allowedTypes.includes(file[0]?.type) === false) {
      Swal.fire(
        "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
        "กรุณาอัปโหลดประเภทไฟล์ใหม่",
        "error"
      );
      return;
    }
    if (file[0].name) {
      const regex = /([\u0E00-\u0E7Fก-๙]+)/gmu;
      const str = file[0].name;
      const m = regex.exec(str);

      if (isFileImage(file[0]) === false) {
        Swal.fire({
          title: "ประเภทไฟล์ไม่ถูกต้อง",
          text: "",
          icon: "warning",
          confirmButtonColor: "#032973",
          confirmButtonText: "ตกลง",
        });
      } else {
        const oleTempFileUpload = documentUploadList.data;
        setDocumentUploadList({ ...documentUploadList, data: [] });

        // const newFileUpload = quotaion_save_files.new;
        if (file.length > 0) {
          for (let index = 0; index < file.length; index += 1) {
            const tempDataImg = {
              km_document_name: file[index].name,
              km_path_document: URL.createObjectURL(file[index]),
              km_image: file[index],
              oldNew: "new",
              status: "add",
            };
            // console.log('tempFileUpload', tempFileUpload[0],tempFileUpload[0].sbd_document_name, tempFileUpload[0].sbd_path_document);
            oleTempFileUpload.push(tempDataImg);
          }
          setDocumentUploadList({
            ...documentUploadList,
            data: oleTempFileUpload,
          });
          documentUploadList.data = oleTempFileUpload;
          // await manageKMFiles();
          const findCheckTrue = documentUploadList.data.filter((item) => {
            return item.status !== "del";
          });

          const addNew = documentUploadList.data.filter((item) => {
            return item.status === "add" && item.oldNew === "new";
          });

          const delOld = documentUploadList.data.filter((item) => {
            return item.status === "del" && item.oldNew === "old";
          });

          setDocumentUploadList({
            ...documentUploadList,
            data: findCheckTrue,
            new: addNew,
            del: delOld,
          });
          documentUploadList.data = findCheckTrue;
          documentUploadList.new = addNew;
          documentUploadList.del = delOld;
        }
      }
    }
  }

  async function manageKMFiles(index) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    })
      .then((result) => {
        if (result.isConfirmed) {
          let temp = documentUploadList.data
          temp[index].status = "del";
          setDocumentUploadList({
            ...documentUploadList, del: temp
          })
          documentUploadList.del = temp
          // console.log('del', documentUploadList.del)
          const findCheckTrue = documentUploadList.data.filter((item) => {
            return item.status !== "del";
          });

          const addNew = documentUploadList.data.filter((item) => {
            return item.status === "add" && item.oldNew === "new";
          });

          const delOld = documentUploadList.data.filter((item) => {
            return item.status === "del" && item.oldNew === "old";
          });

          setDocumentUploadList({
            ...documentUploadList,
            data: findCheckTrue,
            new: addNew,
            del: delOld,
          });

          documentUploadList.data = findCheckTrue;
          documentUploadList.new = addNew;
          documentUploadList.del = delOld;

          // console.log('documentUploadList',
          //   documentUploadList.del)

          // Swal.fire({
          //   icon: "success",
          //   title: "ลบข้อมูลสำเร็จ",
          //   allowEscapeKey: false,
          //   allowOutsideClick: false,
          //   showConfirmButton: false,
          //   timer: 2000,
          // });
        }
      })
  }

  useEffect(async () => {
    if (mode === "add") {
      setPageMode("Add");
      setIsDisabled(false);
      await GetKM_basic();
      await getDropdownKMcategory();
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
      await GetKMDetail();
      await GetKM_basic();
      await getFileKM();
      await getDropdownKMcategory();
    } else if (mode === "view") {
      setPageMode("View");
      setIsDisabled(true);
      await GetKMDetail();
      await getFileKM();
      await getDropdownKMcategory();
    }
  }, []);

  const rowsData = [];
    for (let index = 0; index < documentUploadList.data.length; index += 1) {
      const rowItem = {};

      rowItem["no"] = <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>{index + 1}</div>;
      rowItem["no2"] = index + 1

      rowItem["kmFileName"] =
        <div style={{ whiteSpace: "nowrap", width: "100", textAlign: "center" }}>
          <p style={{ fontSize: "1.2rem", }}>
            {documentUploadList.data[index].km_document_name}
          </p>
        </div>
      rowItem["kmFileName2"] = documentUploadList.data[index].km_document_name

      rowItem["management"] = (
        <div className="row" style={{ flexWrap: "nowrap", textAlign: "center", justifyContent: "center" }}>
          <div className="col-12">
            <div
              key={documentUploadList.data[index]}
              id={documentUploadList.data[index]}
              hidden={mode === "view" ? true : false}
              className=" btn btn-xs "
              onClick={async () => {
                documentUploadList.status = "del";
                await manageKMFiles(index);
              }}
            >
              <i class="fas fa-trash-alt" style={{ color: "red" }}/>
            </div>
          </div>
        </div>
      );

      rowsData.push(rowItem);
  }

  const fileKM_Table = {
      columns: [
          {
              label: "ลำดับ",
              field: "no",
              // sort: "asc",
              width: 50,
          },
          {
              label: "เอกสาร",
              field: "kmFileName",
              // sort: "asc",
              width: 50,
          },
          {
              label: "จัดการ",
              field: "management",
              // sort: "asc",
              width: 50,
          },
      ],

      rows: rowsData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-sm-4">
                <h1>Knowledge management (Basic) {pageMode}</h1>
              </div>
              <div className="col-sm-8">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Knowledge management(Basic)
                  </li>
                  <li className="breadcrumb-item active">
                    Knowledge management(Basic) {pageMode}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    {mode === "view" ? "ย้อนกลับ" : "ยกเลิก"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={() => {
                      saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 style={{ margin: "0px" }}>
                      Knowledge management (Basic) Detail
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-6">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.KM_Name}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                KM_Name: e.target.value,
                              });
                              inputData.KM_Name = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            KM Name
                            {inputData.KM_Name === "" ||
                              inputData.KM_Name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-6">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.KM_Category}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                KM_Category: e.target.value,
                              });
                              inputData.KM_Category = e.target.value;
                            }}
                          >
                            <option value="">-- Please Select --</option>
                            {KMcategoryDD?.map((data, index) => {
                              return (
                                <option key={index} value={data.ck_id}>
                                  {data.ck_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            KM Category
                            {inputData.KM_Category === "" ||
                              inputData.KM_Category === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.KM_Url}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                KM_Url: e.target.value,
                              });
                              inputData.KM_Url = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            KM URL
                            {/* {inputData.KM_Url === "" ||
                                inputData.KM_Url === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )} */}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            className="form-control"
                            style={{ minHeight: "10rem" }}
                            required
                            disabled={isDisabled}
                            value={inputData.KM_Description}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                KM_Description: e.target.value,
                              });
                              inputData.KM_Description = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            KM Description
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* {
                            // ((mode === "edit" || mode === "view") && (quotation_data.quo_qs_id === "f4d7da7a-86b6-4757-a96a-61ae63e642de" || quotation_data.quo_qs_id === "24af7b0f-4c1f-45c7-9bc6-b6a3c2bcbadb")) ?
                                <> */}
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-6 col-sm-6">
                        <label htmlFor="exampleInputFile">
                          KM Document{" "}
                        </label>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              style={{ display: "none" }}
                              type="file"
                              className="custom-file-input"
                              id="exampleInputInvoid"
                              accept={allowedTypes}
                              multiple
                              disabled={isDisabled}
                              ref={uploadRef}
                              name="customFile"
                              onChange={(e) => encodeImage(e.target.files)}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                              onClick={() => uploadRef.current.click()}
                            >
                              {"Select File"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div className="table-responsive" style={{ whiteSpace: "nowrap" }}>
                          <MDBDataTable
                            sortable={false}
                            disableRetreatAfterSorting={MDBT}
                            striped
                            className="table table-head-fixed"
                            bordered
                            hover
                            data={fileKM_Table}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AEV_Knowledge_Management;
