import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";

function Wire_Ready_toUse_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [wire, setwire] = useState({
    company_id: getUser().com,
    oem_id:getOem(),
    diameter_no: "",
    diameter_name:"",
    wire_weight: 0,
    wire_amount: 0,
   
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  useEffect(() => {
    GetCompany();
    //console.log("id:"+id);
    Get_view_wire();
  }, []);


  
  

  const Get_view_wire = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/Wire_Ready_toUse/findById?id="+ id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log(response.data);
          setwire({
            company_id: response.data.company_id,
            oem_id: response.data.oem_id,
            diameter_no: response.data.diameter_no,
            diameter_name: response.data.diameter.trim(),
            wire_weight: response.data.weight,
            wire_amount: response.data.amount,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  
  
  function saveOption(params) {
    const error_list = [];
    if(wire.diameter_no.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Diameter No.]."
      }
      error_list.push(temp_err);
    }
    if(wire.diameter_name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Diameter Name]."
      }
      error_list.push(temp_err);
    }
    // if(wire.wire_weight == ""){
    //   let temp_err ={
    //     message:"Please enter information in the fields >>> [Wire Weight]."
    //   }
    //   error_list.push(temp_err);
    // }
    
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
        const temp ={

          company_id: wire.company_id,
          oem_id: wire.oem_id,
          diameter_no: wire.diameter_no.trim(),
          diameter_name: wire.diameter_name.trim(),
          wire_weight: wire.wire_weight,
          wire_amount: wire.wire_amount,
         }
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/Wire_Ready_toUse/addwire",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {

        const temp ={
          id:id,
          company_id: wire.company_id,
          oem_id: wire.oem_id,
          diameter_no: wire.diameter_no.trim(),
          diameter_name: wire.diameter_name.trim(),
          wire_weight: wire.wire_weight,
          wire_amount: wire.wire_amount,
         }
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/Wire_Ready_toUse/Updatewire?id="+id,
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }
  
  }

  

  function cancelOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Wire_Ready_toUse";
      }
    }
    );
  }
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ลวดพร้อมใช้ {pageMode}{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">ลวดพร้อมใช้</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row col-mb-3">
                <div className="col-sm-3">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-3 col-md-2 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-3 col-md-2 col-xl-1">
                  <button
                    type="button"
                    if
                      onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">ลวดพร้อมใช้ {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3 className="mt-5-head">
            ลวดพร้อมใช้ Detail
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>
              <div className="row">
            
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      decimalScale = {0}
                      value={wire.diameter_no}
                      disabled={disable}
                      onChange={(e) => {
                        setwire({
                          ...wire,
                          diameter_no: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                    Diameter No.
                      {wire.diameter_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={wire.diameter_name}
                      disabled={disable}
                      onChange={(e) => {
                        setwire({
                          ...wire,
                          diameter_name: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                    Diameter Name
                      {wire.diameter_name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      decimalScale = {2}
                      required
                      placeholder="0"
                      value={wire.wire_weight}
                      disabled={true}
                      onChange={(e) => {
                        setwire({
                          ...wire,
                          wire_weight: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                    Weight(kg)
                      {/* {wire.wire_weight.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      decimalScale = {0}
                      placeholder="0"
                      required
                      value={wire.wire_amount}
                      disabled={true}
                      onChange={(e) => {
                        setwire({
                          ...wire,
                          wire_amount: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                    amount
                      {/* {wire.wire_amount.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>
  );
}
export default Wire_Ready_toUse_add;
