import React, { useState, useEffect } from "react";

import "../../../../assets/css/ProductService.css";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

function Product_Display_Card({ data }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          maxWidth: "100px",
          maxHeight: "100px",
        }}
      >
        <img
          className="img-response-slice"
          style={{
            display: "flex",
            boxSizing: "border-box",
            backgroundColor: "#f5f5f5",
            border: "0.2px solid #003583",
            borderRadius: "15px",
            width: "100px",
            height: "100px",
            borderColor: "#003583",
            /*    width: "auto",
                        height: "auto", */
            alignSelf: "center",
          }}
          /*  onClick={()=>{console.log(data.pi_img_path);}} */
          src={
            data.pi_img_path !== "" &&
            data.pi_img_path !== null &&
            data.pi_img_path !== undefined
              ? Configs.API_URL_IMG_incentive + data.pi_img_path
              : userdefault_img.imgs
          }
          alt={data.pi_img_name || "..."}
        />
      </div>
    </>
  );
}

export default Product_Display_Card;
