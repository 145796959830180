import React from "react";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { useEffect } from "react";
import { useState } from "react";
import { getToken, getUser, getOem, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import moment from "moment";
import Swal from "sweetalert2";

function Tooling_inquiry() {
  const [MDBT, setMDBT] = useState(true);
  const [toolingInquiry, setToolingInquiry] = useState({
    id: "",
    inquiry_id: "",
    inquiry_no: "",
    tooling_id: "",
    amount: "",
  });
  const [toolingInquiryLsit, setToolingInquiryLsit] = useState([]);
  const [inquiryFilter, setInquiryFilter] = useState({
    inquiry_no: "",
  });

  const clearFilter = async () => {
    await setInquiryFilter({
      inquiry_no: "",
    });
    inquiryFilter.inquiry_no = "";

    FilterToolingInquiry();
  };
const [test,settest]=useState({test:""})
  const FilterToolingInquiry = async () => {
    // Swal.fire({
    //   title: "Loading",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   timer: 100,
    //   onOpen: () => {
    //     Swal.showLoading();
    //   },
    // }).then((result) => {
    let pushData = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      inquiry_id: inquiryFilter.inquiry_no.trim() || null,
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/tooling/getToolingInquiry",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        console.log("response", response);
        const arrList = [];
        for (let item of response.data) {
          if (item.amount == null) {
            item.amount = 0;
          }
          arrList.push(item);
        }
        setToolingInquiry(arrList);
        setMDBT(true);
        // Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
    // });
  };
  const getToolingInquiryByIdApi = async (id) => {
    axios({
      method: "get",
      url: Configs.API_URL + "/tooling/getSuppToolingInquiryByIdApi?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.data) {
          console.log(response.data);
          console.log("toolingsupplier", response.data);
          setToolingInquiryLsit(response.data);
          // if (response.data.tooling.length > 0) {
          //   for (let item of response.data.tooling) {
          //     let temp = {};
          //     temp.amount = item.amount || "";
          //     temp.brand_id = item.brand_id || "";
          //     temp.brand_name = item.brand_name || "";
          //     temp.consumption = item.consumption || "";
          //     temp.id = item.id || "";
          //     temp.inquiry_id = item.inquiry_id || "";
          //     temp.is_active = item.is_active || "";
          //     temp.is_use = item.is_use || "";
          //     temp.max_stock = item.max_stock || "";
          //     temp.min_stock = item.min_stock || "";
          //     temp.ratio = item.ratio || "";
          //     temp.status_edit = item.status_edit || "";
          //     temp.status = true;
          //     temp.tooling_code = item.tooling_code || "";
          //     temp.tooling_detail = item.tooling_detail || "";
          //     temp.tooling_id = item.tooling_id || "";
          //     temp.tooling_img_name = item.tooling_img_name || "";
          //     temp.tooling_name = item.tooling_name || "";
          //     temp.tooling_path_name = item.tooling_path_name || "";

          //     toolingsupplier.push(temp);
          //   }
          //   console.log("toolingsupplier",toolingsupplier);
          //   setToolingInquiryLsit(toolingsupplier);
          // }
          // setTest({ ...test, test: '' });
        }
      })
      .catch(function (error) {
        console.log("error", error.response.data);
        Swal.fire("Error", error.response.data.message, "error");
      });
  };
  function sendEmailService(id) {
    getToolingInquiryByIdApi(id);
    // console.log('toolingInq', toolingInq);
    // console.log('e', e);
    // function sent email
    // let index = e.target.id;
    // console.log('index', index);
    
  }
  function deleteinquery(id) {
    Swal.fire({
      title: "คุณต้องการลบรายการ Tooling Inquiry ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        /* Swal.fire({
                    title: "error",
                    text: "ยังไม่พร้อมใช้งาน",
                    icon: "error",
                    timer: 2000,
                })
                return; */

        // let pushData = {
        //     user_id: getUser().fup,
        //     company_id: getUser().com,
        //     oem_id: getOem(),
        //     toolingInq: toolingInq,
        // };
        axios({
          method: "get",
          url: Configs.API_URL + "/tooling/tooling_inquery_del?id=" + id,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
        })
          .then(async function (response) {
            console.log("response", response);
            if (response.data === true) {
              Swal.fire("สำเร็จ", "ลบข้อมูลสำเร็จ", "success");
            }
            FilterToolingInquiry();
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.message,
              "error"
            );
          });

        //  console.log('response', response);
        // Swal.fire("สำเร็จ", "ลบข้อมูลสำเร็จ", "success");
      }
    });
  }
  useEffect(() => {
    FilterToolingInquiry();
  }, []);

  function numberWithCommas(x) {
    return (
      <NumberFormat
        thousandSeparator={true}
        value={x || 0}
        displayType="text"
      />
    );
    /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
  }
  function sendEmailSelectTooling(data,index) {
    if (data.send_email) {
      toolingInquiryLsit[index].send_email  = false ;
    }else{
      toolingInquiryLsit[index].send_email  = true ;
    }
    settest({...test,test:""});
    test.test = "";
    // console.log("select",data,index);
  }

  function sendListEmailService() {
    console.log("toolingInquiryLsit",toolingInquiryLsit); 

    Swal.fire({
        title: "คุณต้องการส่งเมลใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
    }).then((result) => {
        if (result.isConfirmed) {
            // Swal.fire({
            //     title: "error",
            //     text: "ยังไม่พร้อมใช้งาน",
            //     icon: "error",
            //     timer: 2000,
            // })
            // return;
            const toolingInquiryLsit_temp = toolingInquiryLsit.filter((el)=>{
                                                    return el.send_email === true;
                                            });
            let pushData = {
                user_id: getUser().fup,
                company_id: getUser().com,
                oem_id: getOem(),
                toolingInq: toolingInquiryLsit_temp,
            };
            axios({
                method: "post",
                url: Configs.API_URL + "/tooling/sendEmailToolingInquiry",
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
                data: pushData,
            })
                .then(async function (response) {
                    console.log('response', response);
                    Swal.fire("สำเร็จ", "ส่งเมลสำเร็จ", "success");
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.fire("Error", "เกิดข้อผิดพลาด: " + error.response.data.message, "error");
                });
              //  console.log('response', response);
              // Swal.fire("สำเร็จ", "ส่งเมลสำเร็จ", "success");
        }
    })
  }
  const rowsData = [];
  for (let index = 0; index < toolingInquiry.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["inquiry_no"] = toolingInquiry[index].inquiry_no;

    rowItem["toolingAmount"] = (
      <div style={{ textAlign: "end" }}>
        {numberWithCommas(toolingInquiry[index].amount || 0)}
      </div>
    );

    rowItem["sendEmail"] = (
      <>
        <div className="row">
          <div className="col-12">
            <a
              style={{ color: "white" }}
              className="btn btn-block btn-primary"
              data-toggle="modal"
              data-target="#modal-send-email"
              onClick={sendEmailService.bind(this, toolingInquiry[index].id)}
            >
              {"Send Email"}
            </a>
            {/* <button
                            type="button"
                            id={index}
                            onClick={sendEmailService.bind(
                                this,
                                toolingInquiry[index]
                            )}
                            class={"btn btn-block btn-primary "}
                        >
                            Send Email
                        </button> */}
          </div>
        </div>
      </>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={toolingInquiry[index].id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Tooling_Inquiry/read/" +
                toolingInquiry[index].id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        {toolingInquiry[index].is_active === false ? (
          <></>
        ) : (
          <>
            <div className="col-xl-3 col-md-3 col-xs-3 ">
              <a
                key={toolingInquiry[index].id}
                id="btn_edit"
                className=" btn btn-xs "
                onClick={() => {
                  window.location.href =
                    "/Warehouse_Management/Tooling_Inquiry/edit/" +
                    toolingInquiry[index].id;
                }}
              >
                <i class="fas fa-pencil-alt"></i>
              </a>
            </div>
            <div className="col-xl-3 col-md-3 col-xs-3 ">
              <a
                key={toolingInquiry[index].id}
                id="btn_delete"
                className=" btn btn-xs "
                onClick={deleteinquery.bind(this, toolingInquiry[index].id)}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        )}
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Inquiry No.",
        field: "inquiry_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Tooling Request Amount",
        field: "toolingAmount",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Send Email",
        field: "sendEmail",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const rowssuptable = [];
  for (let index = 0; index < toolingInquiryLsit.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["select"] = (
      <div className="row">
        <div className="col-12">
          <div className="form-group ">
            <button
              type="button"
              id={index}
              className={
                toolingInquiryLsit[index].send_email
                  ? "btn btn-block btn-danger"
                  : "btn btn-block btn-primary"
              }
              onClick={sendEmailSelectTooling.bind(
                  this,
                  toolingInquiryLsit[index],
                  index
              )}
            >
              {toolingInquiryLsit[index].send_email ? "Selected" : "Select"}
            </button>
          </div>
        </div>
      </div>
    );

    rowItem["full_name"] = toolingInquiryLsit[index].full_name;
    rowItem["supp_name"] = toolingInquiryLsit[index].name;

    rowssuptable.push(rowItem);
  }
  const suptable = {
    columns: [
      
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Full Name",
        field: "full_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Supplier Name",
        field: "supp_name",
        // sort: "asc",
        width: 50,
      },{
        label: "Select",
        field: "select",
        // sort: "asc",
        width: 50,
      },
      /* {
        label: "Order Amount",
        field: "order_amount",
        // sort: "asc",
        width: 50,
      }, 
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      }, */
    ],

    rows: rowssuptable,
  };
  return (
    <div className="wrapper">
      {/* <Header />
          <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Tooling Inquiry
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  {/* <li className="breadcrumb-item active">Human Resource</li> */}
                  <li className="breadcrumb-item active">Warehouse</li>
                  <li className="breadcrumb-item active">Tooling Inquiry</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    id="text_empid"
                    required="false"
                    value={inquiryFilter.inquiry_no}
                    onChange={(e) => {
                      setInquiryFilter({
                        ...inquiryFilter,
                        inquiry_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Inquiry No.</label>{" "}
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      FilterToolingInquiry();
                      setMDBT(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearFilter();
                      setMDBT(false);
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Warehouse_Management/Tooling_Inquiry/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Tooling Inquiry</h3>
            </div>

            <div className="card-body">
              <div
                className="table-responsive "
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                  disableRetreatAfterSorting={MDBT}
                />
              </div>
            </div>
            <div className="card-footer" />
          </div>
        </section>
      </div>
      <div className="modal fade" id="modal-send-email">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Sending Email</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                //onClick={set_bomlist_excel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 ">
                  <div className="none-nolmalinput"></div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive" style={{ height: "300px" }}>
                    <MDBDataTable
                      className="table table-head-fixed"
                      entries={4096}
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={suptable}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">{/* <h3></h3> */}</div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                // id={index}
                onClick={()=>sendListEmailService()}
                className="btn btn-primary"
                // data-dismiss="modal"
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tooling_inquiry;
