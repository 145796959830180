import Configs from "../../../../../config";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../../../../assets/font/pdf_font";
import jsPDF from "jspdf";
import numeral from "numeral";
import moment from "moment";
import QRCode2 from "qrcode";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";

function Invoice_report(company, invoice, statusCheck) {
  // console.log("company", company);
  // console.log("invoice", invoice);
  // console.log("statusCheck", statusCheck);

  let data = invoice.data.length > 0 ? invoice.data[0] : [];
  let customer = invoice.customer.length > 0 ? invoice.customer[0] : [];
  let delivery = invoice.delivery.length > 0 ? invoice.delivery[0] : [];

  let lang = data.inv_lang;

  function ThaiNumberToText(Number) {
    Number = Number.replace(/๐/gi, "0");
    Number = Number.replace(/๑/gi, "1");
    Number = Number.replace(/๒/gi, "2");
    Number = Number.replace(/๓/gi, "3");
    Number = Number.replace(/๔/gi, "4");
    Number = Number.replace(/๕/gi, "5");
    Number = Number.replace(/๖/gi, "6");
    Number = Number.replace(/๗/gi, "7");
    Number = Number.replace(/๘/gi, "8");
    Number = Number.replace(/๙/gi, "9");
    return ArabicNumberToText(Number);
  }

  function ArabicNumberToText(Number) {
    var Number = CheckNumber(Number);
    var NumberArray = new Array(
      "ศูนย์",
      "หนึ่ง",
      "สอง",
      "สาม",
      "สี่",
      "ห้า",
      "หก",
      "เจ็ด",
      "แปด",
      "เก้า",
      "สิบ"
    );
    var DigitArray = new Array(
      "",
      "สิบ",
      "ร้อย",
      "พัน",
      "หมื่น",
      "แสน",
      "ล้าน"
    );
    var BahtText = "";
    if (isNaN(Number)) {
      return "ข้อมูลนำเข้าไม่ถูกต้อง";
    } else {
      if (Number - 0 > 9999999.9999) {
        return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
      } else {
        Number = Number.split(".");
        if (Number[1].length > 0) {
          Number[1] = Number[1].substring(0, 2);
        }
        var NumberLen = Number[0].length - 0;
        for (var i = 0; i < NumberLen; i++) {
          var tmp = Number[0].substring(i, i + 1) - 0;
          if (tmp != 0) {
            if (i == NumberLen - 1 && tmp == 1) {
              BahtText += "เอ็ด";
            } else if (i == NumberLen - 2 && tmp == 2) {
              BahtText += "ยี่";
            } else if (i == NumberLen - 2 && tmp == 1) {
              BahtText += "";
            } else {
              BahtText += NumberArray[tmp];
            }
            BahtText += DigitArray[NumberLen - i - 1];
          }
        }
        BahtText += "บาท";
        if (Number[1] == "0" || Number[1] == "00") {
          BahtText += "ถ้วน";
        } else {
          let DecimalLen = Number[1].length - 0;
          for (var i = 0; i < DecimalLen; i++) {
            var tmp = Number[1].substring(i, i + 1) - 0;
            if (tmp != 0) {
              if (i == DecimalLen - 1 && tmp == 1) {
                BahtText += "เอ็ด";
              } else if (i == DecimalLen - 2 && tmp == 2) {
                BahtText += "ยี่";
              } else if (i == DecimalLen - 2 && tmp == 1) {
                BahtText += "";
              } else {
                BahtText += NumberArray[tmp];
              }
              BahtText += DigitArray[DecimalLen - i - 1];
            }
          }
          BahtText += "สตางค์";
        }
        return BahtText;
      }
    }
  }

  function CheckNumber(Number) {
    var decimal = false;
    Number = Number.toString();
    Number = Number.replace(/ |,|บาท|฿/gi, "");
    for (var i = 0; i < Number.length; i++) {
      if (Number[i] == ".") {
        decimal = true;
      }
    }
    if (decimal == false) {
      Number = Number + ".00";
    }
    return Number;
  }

  const doc = new jsPDF("p", "mm", "a4");
  var counts = 0;
  let img_qr = "";
  // let sumSubTotal = 0
  let row = 0;
  let all_total_price = [];
  for (let index = 0; index < data.product_list.length; index += row) {
    // let cus_tel = customer.cus_tel !== null && customer.cus_tel !== undefined ? customer.cus_tel : "";
    let cus_code =
      customer.cus_no !== null && customer.cus_no !== undefined
        ? customer.cus_no
        : "";
    let cus_tax_no =
      customer.cus_tax_no !== null && customer.cus_tax_no !== undefined
        ? customer.cus_tax_no
        : "";
    doc.setTextColor("#000000");
    doc.setFontSize(20);
    doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
    doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
    doc.setFont("THSarabunNewBold");
    doc.text(8, 20, "บริษัท คิวท์ โปรดักส์ พลัส จำกัด");

    doc.setTextColor("#000000");
    doc.setFontSize(14);
    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(72, 20, `0105552067869 (สำนักงานใหญ่)`);

    doc.setTextColor("#000000");
    doc.setFontSize(14);
    doc.text(
      8,
      26,
      "เลขที่ 5 ซ.ลาดพร้าว-วังหิน 32 แยก 1 ถ.ลาดพร้าว-วังหิน แขวงลาดพร้าว เขตลาดพร้าว กทม. 10230"
    );

    doc.setDrawColor("#000000");
    doc.roundedRect(142, 15, 60, 15, 5, 2);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(8, 38, `รหัสลูกค้า ${cus_code}`);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(48, 38, `TAX ID ${cus_tax_no} สำนักงานใหญ่`);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      8,
      44,
      `${
        data.inv_cus_name !== null && data.inv_cus_name !== undefined
          ? data.inv_cus_name
          : ""
      }`
    );

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      138,
      44,
      `เลขที่ ${
        data.inv_no !== null && data.inv_no !== undefined ? data.inv_no : ""
      }`
    );

    const phoneCusNumber =
      data.inv_contact_tel !== null && data.inv_contact_tel !== undefined
        ? data.inv_contact_tel
        : "";
    let CusTel =
      phoneCusNumber.substring(0, 3) +
      "-" +
      phoneCusNumber.substring(3, 6) +
      "-" +
      phoneCusNumber.substring(6);
    let cus_addressP1 =
      (data.inv_cus_house_number !== null &&
      data.inv_cus_house_number !== undefined
        ? "เลขที่ " + data.inv_cus_house_number + " "
        : "") +
      (data.inv_cus_building !== null && data.inv_cus_building !== undefined
        ? data.inv_cus_building + " "
        : "") +
      (data.inv_swine !== null && data.inv_swine !== undefined
        ? data.inv_swine + " "
        : "") +
      (data.inv_soi !== null && data.inv_soi !== undefined
        ? "ซ." + data.inv_soi + " "
        : "") +
      (data.inv_road !== null && data.inv_road !== undefined
        ? data.inv_road + " "
        : "");
    let cus_addressP2 =
      (data.inv_sub_district !== null && data.inv_sub_district !== undefined
        ? "เขต " + data.inv_sub_district + " "
        : "") +
      (data.inv_district !== null && data.inv_district !== undefined
        ? "แขวง " + data.inv_district + " "
        : "") +
      (data.inv_province !== null && data.inv_province !== undefined
        ? data.inv_province + " "
        : "") +
      (data.inv_postcode !== null && data.inv_postcode !== undefined
        ? data.inv_postcode + " "
        : "") +
      "โทร " +
      CusTel;
    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(8, 50, `${cus_addressP1}`);

    // const Invoice_Date = data.inv_date.split("T")[0].split("-")
    // let formPODate = `${Invoice_Date[2]}/${Invoice_Date[1]}/${Invoice_Date[0]}`
    let formPODate =
      (data.inv_date !== null) & (data.inv_date !== undefined)
        ? moment(data.inv_date).format("DD/MM/YY")
        : "";
    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(138, 50, `วันที่ ${formPODate}`);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(8, 56, `${cus_addressP2}`);

    // let cus_payment_term = customer.pt_name !== null && customer.pt_name !== undefined
    //     && data.inv_lang !== null && data.inv_lang !== undefined && data.inv_lang === "TH"
    //     ? customer.pt_name.toLowerCase() === "cash" ? "แคช" : customer.pt_name.toLowerCase() === "credit" ? "เครดิต" : ""
    //     : customer.pt_name !== null && customer.pt_name !== undefined && data.inv_lang !== null && data.inv_lang !== undefined && data.inv_lang === "ENG"
    //         ? customer.pt_name.toLowerCase() === "cash" ? "Cash" : customer.pt_name.toLowerCase() === "credit" ? "Credit" : ""
    //         : ""

    let cus_payment_term =
      customer.pt_name !== null && customer.pt_name !== undefined
        ? customer.pt_name
        : "-";

    let cus_term =
      customer.cus_term !== null && customer.cus_term !== undefined
        ? customer.cus_term
        : "-";
    let deadline =
      moment(formPODate, "DD/MM/YYYY")
        .add(cus_term === "" ? 0 : cus_term, "days")
        .format("DD/MM/YY") || "";
    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      138,
      64,
      `${cus_payment_term} ${cus_term} วัน ครบกำหนด ${deadline}`
    );

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(8, 70, `สถานที่จัดส่ง`);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      138,
      70,
      `เลขที่ใบสั่งขาย ${
        data.inv_so_no !== null && data.inv_so_no !== undefined
          ? data.inv_so_no
          : ""
      }`
    );

    const phoneShipNumber =
      data.inv_ship_tel !== null && data.inv_ship_tel !== undefined
        ? data.inv_ship_tel.replace(/-/g, "")
        : "";
    let ShipTel =
      phoneShipNumber.substring(0, 3) +
      "-" +
      phoneShipNumber.substring(3, 6) +
      "-" +
      phoneShipNumber.substring(6);
    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      8,
      76,
      `ชื่อผู้รับ ${
        data.inv_ship_contract_name !== null &&
        data.inv_ship_contract_name !== undefined
          ? data.inv_ship_contract_name
          : ""
      }`
    );
    doc.text(48, 76, `โทร ${ShipTel}`);

    let Sales_name =
      data.inv_created_name !== null && data.inv_created_name !== undefined
        ? data.inv_created_name
        : "";
    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(138, 76, `พนักงานขาย ${Sales_name}`);

    let ship_addressP1 =
      (data.inv_ship_house_number !== null &&
      data.inv_ship_house_number !== undefined
        ? "เลขที่ " + data.inv_ship_house_number + " "
        : "") +
      (data.inv_ship_building !== null && data.inv_ship_building !== undefined
        ? data.inv_ship_building + " "
        : "") +
      (data.inv_ship_swine !== null && data.inv_ship_swine !== undefined
        ? data.inv_ship_swine + " "
        : "");
    let ship_addressP2 =
      (data.inv_ship_soi !== null && data.inv_ship_soi !== undefined
        ? "ซ. " + data.inv_ship_soi + " "
        : "") +
      (data.inv_ship_road !== null && data.inv_ship_road !== undefined
        ? data.inv_ship_road + " "
        : "") +
      (data.inv_ship_sub_district !== null &&
      data.inv_ship_sub_district !== undefined
        ? "เขต " + data.inv_ship_sub_district + " "
        : "") +
      (data.inv_ship_district !== null && data.inv_ship_district !== undefined
        ? "แขวง " + data.inv_ship_district + " "
        : "") +
      (data.inv_ship_province !== null && data.inv_ship_province !== undefined
        ? data.inv_ship_province + " "
        : "") +
      (data.inv_ship_postcode !== null && data.inv_ship_postcode !== undefined
        ? data.inv_ship_postcode
        : "");
    let ship_name =
      data.inv_ship_name !== null && data.inv_ship_name !== undefined
        ? data.inv_ship_name
        : "";
    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(8, 82, `${ship_name}  ${ship_addressP1}`);
    doc.text(8, 88, `${ship_addressP2}`);

    let shipping_by =
      invoice.delivery.length > 0
        ? delivery.ds_name !== null && delivery.ds_name !== undefined
          ? delivery.ds_name
          : ""
        : "";
    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(138, 82, `ขนส่งโดย ${shipping_by}`);

    /*-------------------------------------------------------------------------------*/
    //header

    doc.setDrawColor("#000000");
    doc.rect(7, 108, 195, 0);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.text(12, 104, "ลำดับ", "center");
    doc.setDrawColor("#000000");
    doc.rect(17, 96, 0, 12);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.text(57.5, 104, "รายการสินค้า", "center");
    doc.rect(98, 96, 0, 12);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.text(110.5, 104, "จำนวน", "center");
    doc.rect(123, 96, 0, 12);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.text(135.5, 104, "ราคา", "center");
    doc.rect(148, 96, 0, 12);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.text(160.5, 104, "ส่วนลด", "center");
    doc.rect(173, 96, 0, 12);

    doc.setTextColor("#000000");
    doc.setFontSize(15);
    doc.text(188, 104, "จำนวนเงิน", "center");
    row = 0;
    // for (let index = 0; index < invoice.data.length; index += 7) {
    QRCode2.toDataURL(data.inv_no, function (err, url) {
      img_qr = url;
    });
    let gapcheck = 15;
    let isMore1page = false;
    let countItem = 0;
    let c = 0;
    for (let i = 0; c < data.product_list.length; i = i + gapcheck) {
      if (index + c < data.product_list.length) {
        let Product_name =
          lang === "TH"
            ? data.product_list[index + c].ip_product_name_th !== null &&
              data.product_list[index + c].ip_product_name_th !== undefined
              ? data.product_list[index + c].ip_product_name_th
              : ""
            : data.product_list[index + c].ip_product_name_eng !== null &&
              data.product_list[index + c].ip_product_name_eng !== undefined
            ? data.product_list[index + c].ip_product_name_eng
            : "";
        let Brand_name =
          data.product_list[index + c].product_data.length > 0
            ? data.product_list[index + c].product_data[0].brand_name !==
                null &&
              data.product_list[index + c].product_data[0].brand_name !==
                undefined
              ? data.product_list[index + c].product_data[0].brand_name
              : ""
            : "";
        let Weight =
          data.product_list[index + c].product_data.length > 0
            ? data.product_list[index + c].product_data[0].pd_weight !== null &&
              data.product_list[index + c].product_data[0].pd_weight !==
                undefined
              ? data.product_list[index + c].product_data[0].pd_weight
              : ""
            : "";
        let Unit_name =
          data.product_list[index + c].product_data.length > 0
            ? data.product_list[index + c].product_data[0].pu_name !== null &&
              data.product_list[index + c].product_data[0].pu_name !== undefined
              ? data.product_list[index + c].product_data[0].pu_name
              : ""
            : "";
        let y_axis_des = 0;
        let allItemDescription = `${Brand_name + " "} ${
          Product_name + " "
        } ${Weight}${Unit_name} `;
        let tempAllDes = allItemDescription.split(" ");
        let LineArray = [];
        if (tempAllDes.length > 6 && allItemDescription.length > 35) {
          for (let k = 0; k < tempAllDes.length; k++) {
            if (LineArray.length <= 0) {
              if (
                tempAllDes[k] !== "" &&
                tempAllDes[k] !== null &&
                tempAllDes[k] !== undefined
              ) {
                LineArray.push(tempAllDes[k]);
              }
            } else if (LineArray.length > 0) {
              if (LineArray[LineArray.length - 1].length < 35) {
                if (
                  tempAllDes[k].length < 30 &&
                  tempAllDes[k] !== "" &&
                  tempAllDes[k] !== null &&
                  tempAllDes[k] !== undefined
                ) {
                  LineArray[LineArray.length - 1] += " " + tempAllDes[k];
                } else {
                  for (let c = 0; c < tempAllDes[k].length; c += 35) {
                    if (c + 30 <= tempAllDes[k].length) {
                      LineArray.push(tempAllDes[k].substring(c, c + 35));
                    } else {
                      LineArray.push(
                        tempAllDes[k].substring(c, tempAllDes[k].length)
                      );
                    }
                  }
                }
              } else {
                if (
                  tempAllDes[k] !== "" &&
                  tempAllDes[k] !== null &&
                  tempAllDes[k] !== undefined
                ) {
                  LineArray.push(tempAllDes[k]);
                }
              }
            }
          }
          gapcheck = 10 + 6 * LineArray.length;
        } else {
          LineArray.push(allItemDescription);
          gapcheck = 15;
        }
        if (260 - (108 + i) < gapcheck) {
          break;
        } else if (260 - (108 + i) >= 260 - 213) {
          countItem += 1;
        } else if (index + c + 1 === data.product_list.length) {
          countItem += 1;
          break;
        } else {
          countItem += 1;
          c += 1;
        }
      }
    }
    let gapEachProduct = 15;
    isMore1page = false;
    row = 0;
    if (countItem > 0) {
      for (let i = 0; row < data.product_list.length; i = i + gapEachProduct) {
        if (index + row < data.product_list.length) {
          let Product_name =
            lang === "TH"
              ? data.product_list[index + row].ip_product_name_th !== null &&
                data.product_list[index + row].ip_product_name_th !== undefined
                ? data.product_list[index + row].ip_product_name_th
                : ""
              : data.product_list[index + row].ip_product_name_eng !== null &&
                data.product_list[index + row].ip_product_name_eng !== undefined
              ? data.product_list[index + row].ip_product_name_eng
              : "";
          let Brand_name =
            data.product_list[index + row].product_data.length > 0
              ? data.product_list[index + row].product_data[0].brand_name !==
                  null &&
                data.product_list[index + row].product_data[0].brand_name !==
                  undefined
                ? data.product_list[index + row].product_data[0].brand_name
                : ""
              : "";
          let Weight =
            data.product_list[index + row].product_data.length > 0
              ? data.product_list[index + row].product_data[0].pd_weight !==
                  null &&
                data.product_list[index + row].product_data[0].pd_weight !==
                  undefined
                ? data.product_list[index + row].product_data[0].pd_weight
                : ""
              : "";
          let Unit_name =
            data.product_list[index + row].product_data.length > 0
              ? data.product_list[index + row].product_data[0].pu_name !==
                  null &&
                data.product_list[index + row].product_data[0].pu_name !==
                  undefined
                ? data.product_list[index + row].product_data[0].pu_name
                : ""
              : "";
          let y_axis_des = 0;
          let allItemDescription = `${Brand_name + " "} ${
            Product_name + " "
          } ${Weight}${Unit_name} `;
          let tempAllDes = allItemDescription.split(" ");
          let LineArray = [];
          if (allItemDescription.length > 35) {
            for (let k = 0; k < tempAllDes.length; k++) {
              if (LineArray.length <= 0) {
                if (
                  tempAllDes[k] !== "" &&
                  tempAllDes[k] !== null &&
                  tempAllDes[k] !== undefined
                ) {
                  LineArray.push(tempAllDes[k]);
                }
              } else if (LineArray.length > 0) {
                if (LineArray[LineArray.length - 1].length < 35) {
                  if (
                    tempAllDes[k].length < 30 &&
                    tempAllDes[k] !== "" &&
                    tempAllDes[k] !== null &&
                    tempAllDes[k] !== undefined
                  ) {
                    LineArray[LineArray.length - 1] += " " + tempAllDes[k];
                  } else {
                    for (let c = 0; c < tempAllDes[k].length; c += 35) {
                      if (c + 30 <= tempAllDes[k].length) {
                        LineArray.push(tempAllDes[k].substring(c, c + 35));
                      } else {
                        LineArray.push(
                          tempAllDes[k].substring(c, tempAllDes[k].length)
                        );
                      }
                    }
                  }
                } else {
                  if (
                    tempAllDes[k] !== "" &&
                    tempAllDes[k] !== null &&
                    tempAllDes[k] !== undefined
                  ) {
                    LineArray.push(tempAllDes[k]);
                  }
                }
              }
            }
            gapEachProduct = 10 + 6 * LineArray.length;
            y_axis_des = 113 + i;
          } else {
            LineArray.push(allItemDescription);
            gapEachProduct = 15;
            y_axis_des = 113 + i;
          }
          if (
            108 + i + gapEachProduct > 213 &&
            (row === countItem || index + row + 1 === data.product_list.length)
          ) {
            isMore1page = true;
            break;
          }
          // doc.setDrawColor("#987654");
          // doc.rect(7, 260, 195, 0);

          doc.setTextColor("#000000");
          doc.setFontSize(15);
          doc.text(12, y_axis_des, (index + row + 1).toString(), "center"); //no.

          // Item description
          let Product_code =
            data.product_list[index + row].product_data.length > 0
              ? data.product_list[index + row].product_data[0].pb_piece !==
                  null &&
                data.product_list[index + row].product_data[0].pb_piece !==
                  undefined
                ? data.product_list[index + row].product_data[0].pb_piece
                : ""
              : "";
          let Barcode =
            data.product_list[index + row].ip_cus_barcode !== null &&
            data.product_list[index + row].ip_cus_barcode !== undefined
              ? data.product_list[index + row].ip_cus_barcode
              : "";

          doc.setTextColor("#000000");
          doc.setFontSize(15);
          doc.text(20, y_axis_des, `${Product_code} / ${Barcode}`); //`xxxxxBarcodexxxxx / xxxCusCodexxx`s
          for (let item of LineArray) {
            if (item !== "") {
              doc.setTextColor("#000000");
              doc.setFontSize(15);
              doc.text(20, y_axis_des + 6, item);
              y_axis_des += 6;
            }
          }

          //Qty.
          y_axis_des = 113 + i;

          let Unit =
            data.product_list[index + row].ip_quantity_unit !== null &&
            data.product_list[index + row].ip_quantity_unit !== undefined
              ? lang === "TH"
                ? data.product_list[
                    index + row
                  ].ip_quantity_unit.toLowerCase() === "pack"
                  ? "แพ็ค"
                  : data.product_list[
                      index + row
                    ].ip_quantity_unit.toLowerCase() === "ctn"
                  ? "ลัง"
                  : data.product_list[
                      index + row
                    ].ip_quantity_unit.toLowerCase() === "piece"
                  ? "ชิ้น"
                  : ""
                : data.product_list[
                    index + row
                  ].ip_quantity_unit.toLowerCase() === "pack"
                ? "Pck."
                : data.product_list[
                    index + row
                  ].ip_quantity_unit.toLowerCase() === "ctn"
                ? "Ctn."
                : data.product_list[
                    index + row
                  ].ip_quantity_unit.toLowerCase() === "piece"
                ? "Pcs."
                : ""
              : "";

          let Quantity =
            data.product_list[index + row].ip_quantity !== null &&
            data.product_list[index + row].ip_quantity !== undefined
              ? parseInt(data.product_list[index + row].ip_quantity)
              : 0;
          let Quantity_piece = 0;
          doc.setTextColor("#000000");
          doc.setFontSize(15);
          if (Unit === "Ctn." || Unit === "ลัง") {
            Quantity_piece =
              Quantity * data.product_list[index + row].product_data.length > 0
                ? data.product_list[index + row].product_data[0]
                    .pd_sale_pc_ctn !== null &&
                  data.product_list[index + row].product_data[0]
                    .pd_sale_pc_ctn !== undefined
                  ? parseInt(
                      data.product_list[index + row].product_data[0]
                        .pd_sale_pc_ctn
                    )
                  : 1
                : 1;
            doc.text(110.5, y_axis_des, `${Quantity} ${Unit}`, "center");
            if (lang === "TH") {
              doc.text(
                110.5,
                y_axis_des + 6,
                `${Quantity_piece} ชิ้น`,
                "center"
              );
            } else {
              doc.text(
                110.5,
                y_axis_des + 6,
                `${Quantity_piece} Pcs.`,
                "center"
              );
            }
          } else if (Unit === "Pck." || Unit === "แพ็ค") {
            Quantity_piece =
              Quantity *
              (data.product_list[index + row].product_data.length > 0
                ? data.product_list[index + row].product_data[0]
                    .pd_sale_pc_pack !== null &&
                  data.product_list[index + row].product_data[0]
                    .pd_sale_pc_pack !== undefined
                  ? parseInt(
                      data.product_list[index + row].product_data[0]
                        .pd_sale_pc_pack
                    )
                  : 1
                : 1);
            doc.text(110.5, y_axis_des, `${Quantity} ${Unit}`, "center");
            if (lang === "TH") {
              doc.text(
                110.5,
                y_axis_des + 6,
                `${Quantity_piece} ชิ้น`,
                "center"
              );
            } else {
              doc.text(
                110.5,
                y_axis_des + 6,
                `${Quantity_piece} Pcs.`,
                "center"
              );
            }
          } else {
            Quantity_piece = Quantity;
            doc.text(110.5, y_axis_des, `${Quantity_piece} ${Unit}`, "center");
          }

          //Price
          let price =
            data.product_list[index + row].ip_price !== null &&
            data.product_list[index + row].ip_price !== undefined
              ? parseFloat(data.product_list[index + row].ip_price)
              : 0;
          let Total_price = (
            (price * parseFloat(Quantity_piece)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          doc.setTextColor("#000000");
          doc.setFontSize(15);
          doc.text(145, y_axis_des, `${Total_price}`, "right");

          //Discount
          if (data.product_list[index + row].discount.length > 0) {
            for (
              let index2 = 0;
              index2 < data.product_list[index + row].discount.length;
              index2 += 4
            ) {
              let discount =
                data.product_list[index + row].discount
                  .reduce((sum, item) => sum + parseFloat(item.id_discount), 0)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || 0;
              let discount_unit =
                data.product_list[index + row].discount[index2]
                  .id_unit_discount !== null &&
                data.product_list[index + row].discount[index2]
                  .id_unit_discount !== undefined
                  ? data.product_list[index + row].discount[
                      index2
                    ].id_unit_discount.toLowerCase() === "percent"
                    ? "%"
                    : data.product_list[index + row].discount[
                        index2
                      ].id_unit_discount.toLowerCase() === "bath"
                    ? lang === "TH"
                      ? "บาท"
                      : "Baht"
                    : ""
                  : "";

              let discount_price = 0;
              if (discount_unit === "%") {
                discount_price =
                  (parseFloat(Total_price.replace(/,/g, "")) *
                    parseFloat(discount)) /
                  100;
              } else {
                discount_price = parseFloat(discount);
              }

              // ส่วนลดต่อ product

              doc.setTextColor("#000000");
              doc.setFontSize(15);
              doc.text(
                170 + index2,
                y_axis_des,
                `${discount}${discount_unit}`,
                "right"
              );
              if (data.product_list[index + row].discount.length > 1) {
                doc.text(173 + index2, y_axis_des, `+`, "right");
              }

              //ราคารวมต่อ product หลังหักส่วนลด
              let Total_price_discount = (
                parseFloat(Total_price.replace(/,/g, "")) -
                parseFloat(discount_price)
              )
                .toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              all_total_price.push(Total_price_discount.replace(/,/g, ""));
              doc.setTextColor("#000000");
              doc.setFontSize(15);
              doc.text(200, y_axis_des, `${Total_price_discount}`, "right");
            }
          } else {
            doc.setTextColor("#000000");
            doc.setFontSize(15);
            doc.text(170, y_axis_des, `-`, "right");

            let Total_price_discount = Total_price;
            all_total_price.push(Total_price_discount.replace(/,/g, ""));
            doc.setTextColor("#000000");
            doc.setFontSize(15);
            doc.text(200, y_axis_des, `${Total_price_discount}`, "right");
          }
        }
        row = row + 1;
        counts = data.product_list.length - countItem;
        // counts = 10 - 7;
      }
    } else {
      row += 1;
    }
    // จบการวนลูปสินค้า
    let sum_total_price = all_total_price.reduce(
      (a, b) => parseFloat(a) + parseFloat(b),
      0
    );

    /*------------------------------------------------------------------------*/

    // var checkNumProduct = invoice.data.length - index
    // var checkNumProduct = 10 - index
    doc.setDrawColor("#000000");
    doc.roundedRect(7, 96, 195, 164, 2, 2);

    doc.setDrawColor("#000000");
    doc.roundedRect(7, 262, 61, 30, 2, 2);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(37.5, 270, "ได้รับสินค้าข้างต้นคืนเรียบร้อยเเล้ว", "center");
    doc.setDrawColor("#000000");
    doc.rect(13, 283, 50, 0);
    doc.text(37.5, 290, "แผนกรับสินค้า", "center");

    doc.setDrawColor("#000000");
    doc.roundedRect(69, 262, 61, 30, 2, 2);
    doc.text(99.5, 270, "ได้เก็บสินค้าข้างต้นในคลังสินค้าแล้ว", "center");
    doc.setDrawColor("#000000");
    doc.rect(74, 283, 50, 0);
    doc.text(99.5, 290, "แผนกคลังสินค้า", "center");

    doc.setDrawColor("#000000");
    doc.roundedRect(131, 262, 72, 30, 2, 2);
    doc.text(136, 270, "ในนาม บริษัท คิวท์ โปรดักส์ พลัส จำกัด");
    doc.setDrawColor("#000000");
    doc.text(136, 286, "ผู้รับมอบอำนาจ _____________________");

    if (index < counts || isMore1page) {
      doc.setDrawColor("#000000");
      doc.rect(17, 108, 0, 152); //No.
      doc.rect(98, 108, 0, 152); //Item Description
      doc.rect(123, 108, 0, 152); //Qty.
      doc.rect(148, 108, 0, 152); //Price
      doc.rect(173, 108, 0, 152); //Discount
      doc.addPage("a4", "h");
    } else {
      doc.setDrawColor("#000000");
      doc.rect(7, 213, 195, 0);
      doc.rect(173, 213, 0, 47);
      doc.setDrawColor("#000000");
      doc.rect(17, 108, 0, 105); //No.
      doc.rect(98, 108, 0, 105); //Item Description
      doc.rect(123, 108, 0, 105); //Qty.
      doc.rect(148, 108, 0, 105); //Price
      doc.rect(173, 108, 0, 105); //Discount
      let remark =
        data.inv_remark !== null && data.inv_remark !== undefined
          ? data.inv_remark
          : "";
      doc.addImage(img_qr, "JPEG", 9, 215, 40, 40);
      doc.text(90, 223.6, `${remark}`, "center");
      // doc.setDrawColor('#000000')
      // doc.roundedRect(7, 96, 195, 59 + gap, 2, 2);
      // doc.rect(7, (108 + gap), 195, 0)
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 218, "รวมเป็นเงิน");

      //value Sub Total
      // let subTotal = sumSubTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      let total_price = parseFloat(sum_total_price)
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(200, 218, `${total_price}`, "right");
      // let subTotal = parseFloat(data.hq_total_befor_vat).toFixed(2)

      //value UserDiscount
      let final_discount =
        data.inv_final_discount !== null &&
        data.inv_final_discount !== undefined
          ? data.inv_final_discount
          : 0;
      let cal_final_discount = (
        (parseFloat(total_price.replace(/,/g, "")) *
          parseFloat(final_discount)) /
        100
      )
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 223.6, "หักส่วนลด");
      doc.text(170, 223.6, `${final_discount}%`, "right");

      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(200, 223.6, `${cal_final_discount}`, "right");

      //value after discount
      let total_discount = (
        parseFloat(total_price.replace(/,/g, "")) -
        parseFloat(cal_final_discount.replace(/,/g, ""))
      )
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 229.2, "ยอดหลังหักส่วนลด");

      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(200, 229.2, `${total_discount}`, "right");

      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 234.8, "หักเงินมัดจำ");

      //value Deposit
      let deposit = parseFloat(
        data.inv_deposit !== null && data.inv_deposit !== undefined
          ? data.inv_deposit
          : 0
      )
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(200, 234.8, `${deposit}`, "right");

      //value Shipping
      let shipping_cost = parseFloat(
        data.inv_shipping_cost !== null && data.inv_shipping_cost !== undefined
          ? data.inv_shipping_cost
          : 0
      )
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 240.4, "ค่าส่งสินค้า");

      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(200, 240.4, `${shipping_cost}`, "right");

      //value Products
      let vat_value = 0;

      let total_vat = 0;
      if (data.inv_incl_vat === false || data.inv_incl_vat === null) {
        vat_value = 0;
        total_vat = parseFloat(total_discount.replace(/,/g, ""));
      } else if (data.inv_incl_vat === true) {
        vat_value = parseFloat(total_discount.replace(/,/g, "")) * 0.07;
        total_vat = parseFloat(total_discount.replace(/,/g, ""));
      }
      vat_value = vat_value
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      total_vat = total_vat
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let grand_total =
        parseFloat(total_vat.replace(/,/g, "")) +
        parseFloat(vat_value.replace(/,/g, ""));
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 246, "ราคาสินค้า");

      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(200, 246, `${total_vat}`, "right");

      //value Vat

      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 251.6, "ภาษีมูลค่าเพิ่ม");
      doc.text(170, 251.6, "7%", "right");
      doc.text(200, 251.6, `${vat_value}`, "right");

      let grand_total_ship = (
        parseFloat(grand_total) -
        parseFloat(deposit.replace(/,/g, "")) +
        parseFloat(shipping_cost.replace(/,/g, ""))
      )
        .toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let Thai_grand_total_ship = ArabicNumberToText(
        parseFloat(grand_total_ship.replace(/,/g, ""))
      );
      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(140, 257.2, "จำนวนรวมทั้งสิ้น");
      doc.text(200, 257.2, `${grand_total_ship}`, "right");

      doc.setTextColor("#000000");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(90, 257.2, `(${Thai_grand_total_ship})`, "center");
    }
    // }
  }

  if (statusCheck === true) {
    return doc.output("datauristring");
  } else {
    window.open(doc.output("bloburl"));
  }
}

export default Invoice_report;
