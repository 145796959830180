import React, { useEffect, useState } from "react";

import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import {
    getOem,
    getToken,
    getUser,
} from "../../../../Utils/Common";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import NumberFormat from "react-number-format";
import { useParams } from "react-router-dom";

function OrderInquiry_Add(mode) {

    const { id } = useParams();
    const [test, settest] = useState({
        test: ""
    })
    const [pageMode, setpageMode] = useState("")

    const [dateRange, setDateRange] = useState([null, null]);
    let [startDate, endDate] = dateRange;
    const [FilterOrderInquiry, setFilterOrderInquiry] = useState({
        product_no: "",
        product_name: "",
        user_redeem_name: "",
    })
    const [OrderInquiry, setOrderInquiry] = useState([])

    const [selectAll, setselectAll] = useState(true);
    const [selectItem, setselectItem] = useState([]);
    // const [deselectItem, setdeselectItem] = useState([])
    let Sum = selectItem.reduce((a, c) => a + parseInt(c.ol_amount), 0);
    const [AutoGen, setAutoGen] = useState({
        inquiry_no: "",
        remark: "",
    })
    // const [first, setfirst] = useState(1)

    useEffect(() => {
        if (mode.mode === "add") {
            setpageMode("Add");
            FilterOrderList(1);
            AutoGenInquiryNo();
        }
    }, []);

    function AddOrderInquiry() {
        if (selectItem.length === 0) {
            Swal.fire({
                title: "กรุณาเลือกรายการสั่งซื้อ",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "ตกลง",
                confirmButtonColor: "#3085d6",
            })
            return;
        }
        const data = {
            inquiry_no: AutoGen.inquiry_no,
            product_no: FilterOrderInquiry.product_no,
            product_name: FilterOrderInquiry.product_name,
            user_redeem_name: FilterOrderInquiry.user_redeem_name,
            remark: AutoGen.remark === null ? "" : AutoGen.remark.trim(),
            amount: Sum,
            orderList: selectItem,
        }
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/orderInquiry/AddOrderInquiry",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then((response) => {
                if (response.data === "OK") {
                    Swal.fire({
                        title: "บันทึกข้อมูลสำเร็จ",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonText: "ตกลง",
                        confirmButtonColor: "#3085d6",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/Human_Resource/intensive_TTT/Order_Inquiry";
                        }
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    title: "Error!",
                    text: error.response.data.error[0].errorDis,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });

    }

    function saveOption() {
        Swal.fire({
            title: "คุณต้องการบันทึกใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ใช่",
            confirmButtonColor: "#3085d6",
            cancelButtonText: "ไม่",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.isConfirmed) {
                if (pageMode === "Add") {
                    AddOrderInquiry();
                }
            }
        })
    }


    function cancelOption() {
        Swal.fire({
            title: "คุณต้องการยกเลิกใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "ใช่",
            confirmButtonColor: "#3085d6",
            cancelButtonText: "ไม่",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = "/Human_Resource/intensive_TTT/Order_Inquiry";
            }
        })
    }

    function AutoGenInquiryNo() {
        axios({
            method: "get",
            url: Configs.API_URL_incentive + "/api/orderInquiry/AutoGenInquiryNo",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
        })
            .then(function (response) {
                if (response.data) {
                    setAutoGen({
                        ...AutoGen,
                        inquiry_no: response.data
                    })
                    AutoGen.inquiry_no = response.data;
                    settest({ test: "" })
                    test.test = "";
                }
            })
            .catch(function (error) {
                Swal.fire({
                    title: "Error!",
                    text: error.response.data.error[0].errorDis,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });
    }

    function FilterOrderList(round) {
        let data = {
            product_no: FilterOrderInquiry.product_no.trim(),
            product_name: FilterOrderInquiry.product_name.trim(),
            user_redeem_name: FilterOrderInquiry.user_redeem_name.trim(),
            start_date: startDate || null,
            end_date: endDate || null,
            company_id: getUser().com,
            oem_id: getOem(),
        }
        console.log(data);
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/orderInquiry/FilterOrderList",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data
        })
            .then(function (response) {
                if (response.data.length >= 0) {
                    if (round == "1") {
                        console.log("1", response.data);
                        const temp = structuredClone(response.data);
                        const temp2 = structuredClone(response.data);
                        setOrderInquiry(temp);
                        setselectItem(temp2);
                        setselectAll(true);
                        settest({ ...test, test: "" })
                        test.test = ""
                    }
                    else {
                        console.log("2", response.data);
                        setOrderInquiry([])
                        const temp = structuredClone(response.data);
                        console.log("temp", temp);
                        setOrderInquiry(temp);
                        const temp2 = structuredClone(selectItem)
                        const find = temp2.filter((item) => {
                            return temp.some((item2) => {
                                return item2.ol_id === item.ol_id;
                            });
                        });
                        console.log("find", find);
                        if (find.length >= temp.length) {
                            setselectAll(true);
                        }
                        else{
                            setselectAll(false);
                        }
                        
                        // setselectItem(temp2);
                        settest({ ...test, test: "" })
                        test.test = ""
                    }
                }
                else{
                    setOrderInquiry([])
                    // setselectItem([])
                    settest({ ...test, test: "" })
                    test.test = ""
                    setselectAll(false);
                }
            })
            .catch(function (error) {
                Swal.fire({
                    title: "Error!",
                    text: error.response.data.error[0].errorDis,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            });
    }

    function clearFilter() {
        console.log("clear");
        settest({ ...test, test: "" })
        test.test = ""
        // setselectAll(false);
        setFilterOrderInquiry({
            product_no: "",
            product_name: "",
            user_redeem_name: "",
        });
        FilterOrderInquiry.product_no = "";
        FilterOrderInquiry.product_name = "";
        FilterOrderInquiry.user_redeem_name = "";
        setDateRange([null, null]);
        startDate = null;
        endDate = null;
        settest({ ...test, test: "" })
        test.test = ""
        FilterOrderList(2);
    }

    function deldata(id) {
        if (selectItem.length === 1) {
            Swal.fire({
                title: "Error!",
                text: "กรุณาเลือกอย่างน้อย 1 รายการ",
                icon: "error",
                confirmButtonText: "OK",
            });
            return;
        }
        let temp = OrderInquiry;
        let temp2 = selectItem;
        let numIndex = OrderInquiry.findIndex((x) => x.ol_id === id);
        let numIndex2 = selectItem.findIndex((x) => x.ol_id === id);
        // console.log(numIndex);
        temp.splice(numIndex, 1);
        // console.log(numIndex2);
        temp2.splice(numIndex2, 1);
        setOrderInquiry(temp);
        setselectItem(temp2);
        settest({ ...test, test: "" })
        test.test = ""
    }

    const OrderInquiryTableData = [];
    for (let index = 0; index < OrderInquiry.length; index++) {
        const rowItem = {};
        rowItem["select"] = (
            <div style={{ textAlign: "center" }}>
                <input
                    type="checkbox"
                    id={"defaultUnchecked" + index}
                    name={"defaultUnchecked" + index}
                    checked={selectItem.find((x) => x.ol_id === OrderInquiry[index].ol_id) ? true : false}
                    // value={selectItem[index].ol_id}
                    onChange={(e) => {
                        if (e.target.checked) {
                            console.log("true");
                            const temp = OrderInquiry[index];
                            const temp2 = selectItem;
                            // const temp3 = deselectItem;
                            const data = temp2.find((x) => x.ol_id === temp.ol_id);
                            // console.log(data);
                            if (!data) {
                                // const numIndex = deselectItem.findIndex((x) => x.ol_id === temp.ol_id);
                                // temp3.splice(numIndex, 1);
                                // setdeselectItem(temp3);
                                temp2.push(temp);
                                setselectItem(temp2);
                            }
                            settest({ ...test, test: "" })
                            test.test = ""
                            if (selectItem.length >= OrderInquiry.length) {
                                setselectAll(true);
                            }
                        }
                        else {
                            console.log("false");
                            const temp = OrderInquiry[index];
                            const temp2 = selectItem;
                            // const temp3 = deselectItem;
                            const data = temp2.find((x) => x.ol_id === temp.ol_id);
                            console.log(data);
                            if (data) {
                                const numIndex = selectItem.findIndex((x) => x.ol_id === temp.ol_id);
                                temp2.splice(numIndex, 1);
                                setselectItem(temp2);
                                // temp3.push(data);
                                // setdeselectItem(temp3);
                            }
                            settest({ ...test, test: "" })
                            test.test = ""
                            if (selectAll === true) {
                                setselectAll(false);
                            }
                        }
                    }}

                />
            </div>
        )
        rowItem["No"] = index + 1;
        rowItem["Product_No"] = OrderInquiry[index].product_no;
        rowItem["Product_Name"] = OrderInquiry[index].ol_product_name;
        rowItem["Product_Model"] = OrderInquiry[index].ol_model_name;
        rowItem["Amount"] = (
            <div style={{ textAlign: "right" }}>
                <NumberFormat
                    value={OrderInquiry[index].ol_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                />
            </div>
        );
        rowItem["User_Redeem_Name"] = OrderInquiry[index].ol_emp_name;
        rowItem["Redeem_Date"] = (OrderInquiry[index].ol_create_date === null ? "" : moment(OrderInquiry[index].ol_create_date).format("DD/MM/YYYY HH:mm:ss"));
        rowItem["Management"] = (
            <div className="row">
                <div className="col-12" style={{ textAlign: "center" }}>
                    <a
                        key={OrderInquiry[index].ol_id}
                        className=" btn btn-xs "
                        onClick={deldata.bind(this, OrderInquiry[index].ol_id)}
                    >
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </div>
            </div>
        );
        OrderInquiryTableData.push(rowItem);
    }
    const OrderInquiryTable = {
        columns: [
            {
                label: (
                    <div style={{ textAlign: "center" }}>
                        <input
                            type="checkbox"
                            id={"defaultUnchecked"}
                            name={"defaultUnchecked"}
                            checked={selectAll}
                            // onChange={(e) => {
                            //     console.log(e.target.checked);
                            //     if (e.target.checked) {
                            //         console.log("true");
                            //         setselectAll(true);
                            //         const temp = structuredClone(OrderInquiry);
                            //         const temp2 = structuredClone(selectItem);
                            //         const checkOrderInSelect = temp.filter(obj1 => !temp2.some(obj2 => obj2.ol_id === obj1.ol_id));
                            //         console.log(checkOrderInSelect);
                            //         if (checkOrderInSelect.length > 0) {
                            //             setselectItem(selectItem.concat(checkOrderInSelect));
                            //         }
                            //         else if (checkOrderInSelect.length === 0) {
                            //             setselectItem(temp);
                            //         }
                            //     }
                            //     else {
                            //         console.log("false");
                            //         setselectAll(false);
                            //         const temp = structuredClone(OrderInquiry);
                            //         console.log(temp);
                            //         const temp2 = structuredClone(selectItem);
                            //         console.log(temp2);
                            //         const checkselectItemNotInTable = temp2.filter(obj1 => !temp.some(obj2 => obj2.ol_id === obj1.ol_id));
                            //         console.log(checkselectItemNotInTable);
                            //         setselectItem(checkselectItemNotInTable);
                            //     }
                            // }}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    console.log("true");
                                    setselectAll(true);
                                    const checkOrderInSelect = OrderInquiry.filter(
                                        obj1 => !selectItem.some(obj2 => obj2.ol_id === obj1.ol_id)
                                    );
                                    if (checkOrderInSelect.length > 0) {
                                        setselectItem(selectItem.concat(checkOrderInSelect));
                                    } else {
                                        setselectItem(structuredClone(OrderInquiry));
                                    }
                                } else {
                                    console.log("false");
                                    setselectAll(false);
                                    setselectItem([]);
                                }
                            }}
                        />
                    </div>
                ),
                field: "select",
                sort: "asc",
                width: 150,
            },
            {
                label: "No",
                field: "No",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product No",
                field: "Product_No",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product Name",
                field: "Product_Name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Product Model",
                field: "Product_Model",
                sort: "asc",
                width: 150,
            },
            {
                label: "Amount",
                field: "Amount",
                sort: "asc",
                width: 150,
            },
            {
                label: "User Redeem Name",
                field: "User_Redeem_Name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Redeem Date",
                field: "Redeem_Date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Management",
                field: "Management",
                sort: "asc",
                width: 150,
            },
        ],
        rows: OrderInquiryTableData,
    };

    const CustomInput = ({ value, onClick }) => (
        <div className="input-group">
            <input
                style={{ fontSize: "inherit" }}
                type="text"
                className="form-control float-left"
                onClick={onClick}
                value={value}
                data-provide="datepicker"
                data-date-language="th-th"
            />{" "}
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="far fa-calendar-alt" />
                </span>
            </div>
        </div>
    );

    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">
                                <h1>{pageMode} Order Inquiry</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-md-4 col-xl-3">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-success"
                                        onClick={() => {
                                            saveOption();
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-3">
                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-danger"
                                        onClick={() => {
                                            cancelOption();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-xl-3">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={pageMode === "Add" ? "Auto Generate" : AutoGen.inquiry_no}
                                                        disabled
                                                    />
                                                    <label htmlFor="">Inquiry No. (Auto Generate)</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-8 col-xl-6">
                                                <div className="form-group">
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        id="remark"
                                                        required={false}
                                                        value={AutoGen.remark}
                                                        rows={3}
                                                        onChange={(e) => {
                                                            setAutoGen({
                                                                ...AutoGen,
                                                                remark: e.target.value,
                                                            });
                                                            AutoGen.remark = e.target.value;
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    />
                                                    <label htmlFor="">หมายเหตุ</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-xl-3">
                                                <label style={{ margin: "0px 0px" }}>Product No.</label>
                                                <div className="form-group" style={{ margin: "0px 0px", padding: "0px 0px" }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required={false}
                                                        value={FilterOrderInquiry.product_no}
                                                        onChange={(e) => {
                                                            setFilterOrderInquiry({
                                                                ...FilterOrderInquiry,
                                                                product_no: e.target.value,
                                                            });
                                                            FilterOrderInquiry.product_no = e.target.value;
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-3">
                                                <label style={{ margin: "0px 0px" }}>Product Name</label>
                                                <div className="form-group" style={{ margin: "0px 0px", padding: "0px 0px" }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required={false}
                                                        value={FilterOrderInquiry.product_name}
                                                        onChange={(e) => {
                                                            setFilterOrderInquiry({
                                                                ...FilterOrderInquiry,
                                                                product_name: e.target.value,
                                                            });
                                                            FilterOrderInquiry.product_name = e.target.value;
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-3">
                                                <label style={{ margin: "0px 0px" }} >User Redeem Name</label>
                                                <div className="form-group" style={{ margin: "0px 0px", padding: "0px 0px" }}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        required={false}
                                                        value={FilterOrderInquiry.user_redeem_name}
                                                        onChange={(e) => {
                                                            setFilterOrderInquiry({
                                                                ...FilterOrderInquiry,
                                                                user_redeem_name: e.target.value,
                                                            });
                                                            FilterOrderInquiry.user_redeem_name = e.target.value;
                                                            settest({ ...test, test: "" })
                                                            test.test = ""
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-3">
                                                <label style={{ margin: "0px 0px" }}>Redeem Date</label>
                                                <div className="form-group" style={{ fontSize: "initial", margin: "0px 0px", padding: "0px 0px", zIndex: "99" }}>

                                                    <DatePicker
                                                        className="form-control"
                                                        wrapperClassName="w-100"
                                                        locale="th"
                                                        id="redeem_date"
                                                        dateFormat={"dd-MM-yyyy"}
                                                        value={
                                                            startDate && endDate ? `${moment(startDate).format("DD/MM/YYYY")} - ${moment(endDate).format("DD/MM/YYYY")}` : ""
                                                        }
                                                        selected={startDate}
                                                        onChange={async (update) => {
                                                            console.log(update);
                                                            setDateRange(update);
                                                        }}
                                                        shouldCloseOnSelect={
                                                            !(!startDate || Boolean(startDate && endDate))
                                                        }
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsRange={true}
                                                        // isClearable={true}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        disabledKeyboardNavigation
                                                        yearDropdownItemNumber={15}
                                                        scrollableYearDropdown
                                                        // todayButton="Today"
                                                        customInput={<CustomInput />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-6 col-md-3 col-xl-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-info"
                                                    onClick={() => {
                                                        console.log("search");
                                                        FilterOrderList(2);
                                                    }}
                                                >
                                                    Search
                                                </button>
                                            </div>
                                            <div className="col-6 col-md-3 col-xl-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-block btn-info"
                                                    onClick={() => {
                                                        // setfirst(2)
                                                        clearFilter();
                                                    }}
                                                >
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12 col-md-6 col-xl-4">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h5>
                                                            Total Select :
                                                            <input
                                                                style={{ width: "40px", margin: "0px 5px" }}
                                                                type="text"
                                                                value={selectItem.length}
                                                                disabled
                                                            />
                                                        </h5>
                                                    </div>
                                                    <div className="col-6">
                                                        <h5>Total Amount :
                                                            <input
                                                                style={{ width: "40px", margin: "0px 5px" }}
                                                                type="text"
                                                                value={Sum}
                                                                disabled
                                                            />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card-body table-responsive " style={{ whiteSpace: 'nowrap' }}>
                                                    <MDBDataTable
                                                        sortable={false}
                                                        className="table table-head-fixed"
                                                        striped
                                                        bordered
                                                        hover
                                                        fixedHeader
                                                        disableRetreatAfterSorting={true}
                                                        data={OrderInquiryTable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default OrderInquiry_Add