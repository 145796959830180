import React, { Component, useEffect, useState, useRef  } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment"; 
function Wait_Delivery(params) {

  const [filter_bill, setfilter_bill] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    bill_no:"",
    oem_target:getOem(),
    date_start:"",
    date_end:"",
    is_pack:"",
    is_delivery:"",
    is_rated:"",
    is_receive:"",
  });
  const [companyGroup, setCompanyGroup] = useState([]);
  const [bill, setBill] = useState([]);

  //const [model_category, setmodel_category] = useState([]);
  const selectCompanyGroup = useRef();
  const CustomInput = ({ value, onClick }) => ( 
      
    <
    div className = "input-group" >
    < input type = "text"
    className = "form-control float-left"
    onClick = { onClick }
    value = { value }
    /> <div className = "input-group-prepend" >
    <span className = "input-group-text" >
    <i className = "far fa-calendar-alt" />
    </span>
  
    { /*  <label>test</label> */ } 
    </div> 
    </div>
  
  
  )
  /* const Getmodel_category= async () => {
    let temp = {
     model_category: "",
     oem_id: getOem(),
     
    }
   //console.log(filler_supplier);
   axios({
     method: "post",
     url: Configs.API_URL +  "/material/filtermodel_category",
     headers: {
       Authorization: getToken(),
       "X-TTT": Configs.API_TTT,
       "Content-Type": "application/json",
     },
     data: temp
   })
     .then(function (response) {
       // console.log(response.data);
       setmodel_category(response.data);
    
     })
     .catch(function (error) {
       console.log(error);
     });
 }; */

   
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  
  const Get_Bill = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/bill/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_bill,
    })
      .then(async (response) => {
        var sort = response.data.sort((a,b)=>(b.bill_no).localeCompare(a.bill_no))
        
        setBill(sort);

        console.log("Get_Bill",response.data,filter_bill.is_receive);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  

  

  useEffect(() => {
    axios(config_getOem)
    .then(function (response) {
   
      const options = [];

      var element2 = {};
      element2["value"] = "";
      element2["label"] = "---ทั้งหมด---";
      options.push(element2);
      response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.name;
        options.push(element);
        if (el.id === getOem()) {
          setCurrentOEM({ label: el.name, value: el.id });
        }
      });
     
      console.log(options);
      setCompanyGroup(options);
    })
    .catch(function (error) {
      console.log(error);
    });

    Get_Bill();
   // Getmodel_category();
  }, []);
 
   const clearFilter = async () => {
     setfilter_bill({
      ...filter_bill,
      bill_no:"",
      oem_target:getOem(),
      date_start:"",
      date_end:"",
      is_pack:"",
      is_delivery:"",
      is_rated:"",
      is_receive:"",
    }); 
   
    filter_bill.bill_no = "";
    filter_bill.oem_target = getOem();
    filter_bill.date_start = "";
    filter_bill.date_end = "";
    filter_bill.is_pack = ""; 
    filter_bill.is_delivery = "";
    filter_bill.is_rated = "";
    filter_bill.is_receive = "";
    //selectCompanyGroup.current.select.clearValue();
    Get_Bill();
   
  }; 

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/wait_delivery/add";}

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/bill/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/wait_delivery";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
 
 

  const [bill_detail,setbill_detail] = useState({
    bill_no:"",
    bill_oem_id:"",
    bill_values:"",
    bill_destination:"",
    create_bill:"",
    create_time:"",
    delivery_bill:"",
    delivery_time:"",
    customer_name:"",
    weight:"",
    remark:"",
    status_bill:"",
    person_sent:"",
    is_receive:false


  });
  function formBillDetail(data_detail,e){
    console.log("data_detail",data_detail)
    setbill_detail({...bill_detail,
      bill_no:data_detail.bill_no,
      bill_oem_id:data_detail.bill_oem_id,
      bill_values:data_detail.bill_values,
      bill_destination: data_detail.bill_destination,
      create_bill:data_detail.create_bill,
      create_time:data_detail.create_time,
      delivery_bill:data_detail.delivery_bill,
      delivery_time:data_detail.delivery_time,
      customer_name:data_detail.customer_name,
      weight:data_detail.weight,
      remark:data_detail.remark,
      status_bill:data_detail.is_pack === true ? "จัดแล้ว":"ยังไม่ได้จัด",
      latitude:data_detail.latitude,
      longitude:data_detail.longitude,
      remark:data_detail.remark,
      weight_meter:data_detail.weight_meter,
      compressive_strength:data_detail.compressive_strength,
      person_sent:data_detail.person_sent,
      is_receive:data_detail.is_receive


  
    
    })
  }
  console.log("sss",bill_detail.bill_no);
  const rowsData = [];
 
   for (var index = 0; index < bill.length; index++) {
    //console.log(rowsData);
    const rowItem = {};
    rowItem["no"] = index + 1;
    // rowItem["bill_no"] = bill[index].bill_no;
    rowItem["bill_no"] = (

      <a  data-toggle="modal"
      data-target="#modal-bill_detail"
      href = ""
      onClick={formBillDetail.bind(this,bill[index])}
      >
            {bill[index].bill_no}
     
      </a>);


    var oem_target = companyGroup.filter((e)=>{
      return e.value === bill[index].bill_oem_id;
    })
    var oem_ = "";
    if(oem_target.length > 0){
      oem_ = oem_target[0].label;
    }
    rowItem["oem_target"] = oem_;
    rowItem["create_bill"] = moment(bill[index].create_bill).format('DD/MM/YYYY') /* + " : "+ (bill[index].create_bill_time || "09.00" )*/;
    rowItem["delivery_bill"] =bill[index].delivery_bill === null ?"" : moment(bill[index].delivery_bill).format('DD/MM/YYYY');
   /*  var model_category_ = model_category.filter((e)=>{return e.id === bill[index].product_model_id})
    var model_category_txt = "";
    if(model_category_.length > 0){
      model_category_txt = model_category_[0].name;
    }
    rowItem["model_category"] = model_category_txt; */
    rowItem["cus_name"] = bill[index].customer_name;
    rowItem["destination"] = bill[index].bill_destination;
    var status_bill = "ยังไม่ได้จัด";
    if(bill[index].is_pack === true){
      status_bill = "จัดแล้ว";
    }
    if(bill[index].is_pack === false){
      status_bill = "ยังไม่ได้จัด";
    }
    if(bill[index].is_rated === true){
      status_bill = "ประเมินแล้ว";
    }
  /*   if(bill[index].is_pack === true && bill[index].is_delivery === true){
      status_bill = "ส่งแล้ว";
    } */
    rowItem["status_bill"] = status_bill;

   console.log(filter_bill.is_rated)

    
    if (filter_bill.is_rated === true) {
      rowItem["mgt"] = (
        <div className="row">
          <div className="col-4">
            <a
                href={"/Warehouse_Management/wait_delivery/read/"+ bill[index].id}
               key={bill[index].id}
              className=" btn btn-xs "
            >
              <i class="fas fa-eye"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
                 href={"/Warehouse_Management/wait_delivery/edit/"+ bill[index].id}
                key={bill[index].id}
              className=" btn btn-xs "
            >
              {" "}
              <i class="fas fa-pencil-alt"></i>{" "}
            </a>
          </div>
        
          
        </div>
      );

    } else {
      rowItem["mgt"] = (
        <div className="row">
          <div className="col-4">
            <a
                href={"/Warehouse_Management/wait_delivery/read/"+ bill[index].id}
               key={bill[index].id}
              className=" btn btn-xs "
            >
              <i class="fas fa-eye"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
                 href={"/Warehouse_Management/wait_delivery/edit/"+ bill[index].id}
                key={bill[index].id}
              className=" btn btn-xs "
            >
              {" "}
              <i class="fas fa-pencil-alt"></i>{" "}
            </a>
          </div>
          <div className="col-4">
        <a
           key={bill[index].id}
          className=" btn btn-xs "
           onClick={
            deldata.bind(this,bill[index].id)
          }
        >
          <i class="fas fa-trash-alt"></i>
        </a>
      </div>
          
        </div>
      );

    }
  



    rowsData.push(rowItem);
   
  } 

  const data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขที่ใบส่งสินค้า",
        field: "bill_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "กลุ่มบริษัท",
        field: "oem_target",
        sort: "asc",
        width: 50,
      },
 /*      {
        label: "Model&Category",
        field: "model_category",
        sort: "asc",
        width: 50,
        
      }, */
      {
        label: "วันที่ออกบิล",
        field: "create_bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่นัดส่งสินค้า",
        field: "delivery_bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลูกค้า",
        field: "cus_name",
        sort: "asc",
        width: 50,
      },
      
      {
        label: "หน้างาน",
        field: "destination",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะบิล",
        field: "status_bill",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    //   {
    //     label: "Cus_contact_fax",
    //     field: "Cus_contact_fax",
    //     sort: "asc",
    //     width: 50,
    //   },
    //   {
    //     label: "Cus_email",
    //     field: "Cus_email",
    //     sort: "asc",
    //     width: 50,
    //   },
    //   {
    //     label: "Relate Product",
    //     field: "Relate_Product",
    //     sort: "asc",
    //     width: 50,
    //   },
    //   {
    //     label: "Management",
    //     field: "mgt",
    //     sort: "asc",
    //     width: 50,
    //   },
    ],
    rows: rowsData,
  };
  
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="customer" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>บิลรอจัดส่ง{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">บิลรอจัดส่ง</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required   
                    value={filter_bill.bill_no}
                    onChange={(e) => {
                      setfilter_bill({
                        ...filter_bill,
                        bill_no: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">เลขบิล</label>{" "}
                </div>
              </div>
           {/*    <div className="col-md-2" style={{ "z-index": "98" }}>
                <div className="form-group ">
                <Select
                            options={companyGroup}
                   
                            value={companyGroup.filter((e) => {
                              return e.value === filter_bill.oem_target;
                            })}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setfilter_bill({
                                  ...filter_bill,
                                  oem_target: e.value,
                                });
                      
                              }
                            }}
                          />
              
                  <label htmlFor="">กลุ่มบริษัท</label>
                </div>
              </div> */}

              <div className="col-md-0.5">
        <label > วันที่ &nbsp;</label>
        </div>
              <div className="col-md-2" style={{ "z-index": "99" }}>
                <div className="form-group ">
                <DatePicker 
                 selected = {filter_bill.date_start} 
        dateFormat = {"dd-MM-yyyy"}
        onChange = {
          async(date) =>{
            setfilter_bill({
              ...filter_bill,
              date_start: date,
            });
          }
         
          }
        selectsStart startDate = {filter_bill.date_start}
        endDate = {filter_bill.date_end}
        customInput = {<CustomInput/>}
        
        /> 
                </div>
              </div>
              <div className="col-md-0.5">
        <label>&nbsp;&nbsp;  ถึง  &nbsp;&nbsp;</label> 
        </div> 
              <div className="col-md-2" style={{ "z-index": "99" }}>
                <div className="form-group ">
                <DatePicker 
        selected = {filter_bill.date_end}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>{
            setfilter_bill({
              ...filter_bill,
              date_end: date,
            });
          }
         
          }
        selectsEnd startDate = { filter_bill.date_start }
        endDate = { filter_bill.date_end }
        minDate = { filter_bill.date_start }
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
                </div>
              </div>
            
      

              <div className="col 1">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      Get_Bill();
                     //console.log(filter_bill)
                    }}
                  >
                    ค้นหา
                  </button>
                </div>
              </div>

              <div className="col 1">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    ล้างค่า
                  </button>
                </div>
              </div>
           
            </div>
          </div>
         
          <div className="container-fluid">
            <div className="row">
              
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    เพิ่ม
                  </button>
                </div>
              </div>
              <div className="col-2">
             
             &nbsp;&nbsp;&nbsp;
                
                <input type="checkbox"
                id= "is_pack"
                name = "filterBill"
                className="form-check-input"
                 onChange={(event) => {
              setfilter_bill({ ...filter_bill, is_pack: event.currentTarget.checked,is_rated:false,is_receive:false });
              filter_bill.is_rated = false;
              filter_bill.is_receive = false;
              filter_bill.is_pack = event.currentTarget.checked;
              filter_bill.is_noview = false;
              Get_Bill();
            
                 }}
            checked={filter_bill.is_pack}

                    />
              <label htmlFor="is_pack">
                  จัดเเล้ว
          
                </label>
                
                </div>
             
                {getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์" ? (
                       <>
                 {/*  <div className="col-3"> */}
                 <div className="col-2">
             &nbsp; 
                
                <input type="checkbox"
                id= "is_rated"
                name = "filterBill"
                className="form-check-input"
                 onChange={(event) => {
              setfilter_bill({ ...filter_bill, is_rated: event.currentTarget.checked,is_pack:false,is_receive:false });
              filter_bill.is_rated = event.currentTarget.checked;
              filter_bill.is_pack = false;
              filter_bill.is_receive  = false;
              Get_Bill();
             
                 }}
            checked={filter_bill.is_rated}
 
                    />
              <label htmlFor="is_rated">
                  ประเมินแล้ว
          
                </label>
                </div>
               {/*  </div> */}
                </>
                      ) : (
                        <>
           &nbsp; 
           <div className="col-2">
               <input type="checkbox"
               id= "is_receive"
               name = "filterBill"
               className="form-check-input"
                onChange={(event) => {
             setfilter_bill({ ...filter_bill, is_receive: event.currentTarget.checked,is_pack:false,is_rated:false});
             filter_bill.is_rated = false;
             filter_bill.is_pack = false;
             filter_bill.is_receive = event.currentTarget.checked;
             
             Get_Bill();
            
                }}
           checked={filter_bill.is_receive}

                   />
             <label htmlFor="is_receive">
                 รับสินค้าเอง
         
               </label>
               </div>

               <div className="col-2">
             
             &nbsp; 
                
                <input type="checkbox"
                id= "is_rated"
                name = "filterBill"
                className="form-check-input"
                 onChange={(event) => {
              setfilter_bill({ ...filter_bill, is_rated: event.currentTarget.checked,is_pack:false,is_receive:false });
              filter_bill.is_rated = event.currentTarget.checked;
              filter_bill.is_pack = false;
              filter_bill.is_receive  = false;
              Get_Bill();
                 }}
            checked={filter_bill.is_rated}
 
                    />
              <label htmlFor="is_rated">
                  ประเมินแล้ว
          
                </label>
                
                </div>
               </>
                 )}
       
                
              
             
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">ใบส่งสินค้า</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
                   <div className="table-responsive" >
              <MDBDataTable
                className="table table-head-fixed"
                sortable = {false}
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>


      <div className="modal fade" id="modal-bill_detail">
      <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">รายละเอียดใบส่งของ</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
        
            <div className="row">
                <div className="col-12">
             
                  <h4>เลขที่ใบส่งของ{" : "}{bill_detail.bill_no || ""}</h4>
                  <h4>วันที่ออกบิล{" : "}{bill_detail.create_bill === null || "" ?(<>
                    {bill_detail.create_bill || "ไม่ระบุ"} เวลา {bill_detail.create_time || "ไม่ระบุ"}{" "}น.
                  </>):(<>
                    {moment(bill_detail.create_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.create_time || "ไม่ระบุ"}{" "}น.
                  </>
                  )}
                  </h4>
                  {/* <h4>วันที่ออกบิล{" : "}{moment(bill_detail.create_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.create_time || "ไม่ระบุ"}{" "}น.</h4> */}
                  <h4>วันที่นัดส่งสินค้า{" : "}{bill_detail.delivery_bill === null || "" ?(<>
                    {bill_detail.delivery_bill || "ไม่ระบุ"} เวลา {bill_detail.delivery_time || "ไม่ระบุ"}{" "}น.
                  </>):(<>
                    {moment(bill_detail.delivery_bill).format('DD/MM/yyyy') || ""} เวลา {bill_detail.delivery_time || "ไม่ระบุ"}{" "}น.
                  </>
                  )}
                  </h4>
                  {bill_detail.is_receive === true || "" ?(<>
                    <h4>ผู้จัดสินค้า{" : "}{bill_detail.person_sent}</h4>
                  </>):(<>
                   
                  </>
                  )}
                  <h4>ชื่อลูกค้า{" : "}{bill_detail.customer_name || "ไม่ระบุ"}</h4>
                  <h4>หน้างาน{" : "}{bill_detail.bill_destination || "ไม่ระบุ"}</h4>
                  <h4>มูลค่า{" : "}{bill_detail.bill_values || "ไม่ระบุ"}</h4>
                  <h4>{getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์"? "จำนวนคิว":"น้ำหนัก(ตัน)"}{" : "}{bill_detail.weight || "ไม่ระบุ"}</h4>
                  <h4>สถานะ{" : "}{bill_detail.status_bill || "ไม่ระบุ"}</h4>
                  <h4>latitude{" : "}{bill_detail.latitude || "ไม่ระบุ"}</h4>
                  <h4>longitude{" : "}{bill_detail.longitude || "ไม่ระบุ"}</h4>
                  <h4>remark{" : "}{bill_detail.remark || "ไม่ระบุ"}</h4>
                  {getOem() === currentOEM.value && currentOEM.label === "บจก.เอสที คอนกรีตโปรดักส์"?(<>
                    <h4>น้ำหนักจากเครื่องชั่ง{" : "} {bill_detail.weight_meter || "0"}  </h4>
                    <h4>แรงอัด{" : "} {bill_detail.compressive_strength || "0"}</h4>
                    <h4>ข้อมูลน้ำหนักและเเรงอัดล่าสุด{" : "}{bill_detail.delivery_bill === null || "" ?(<>
                    {bill_detail.weight_meter_updated || "ไม่ระบุ"}{" "}น.
                  </>):(<>
                    {moment(bill_detail.weight_meter_updated).format('DD/MM/yyyy เวลา HH:mm') || ""}{" "}น.
                  </>
                  )}
                  </h4>
                  </>
                  ):(
                    <>  
                    
                    </>
                  )}
                 
                </div>
              </div>

            
              
           
            </div>
            <div className="modal-footer "align="right">
            
                 
               <div className="row">
              {/*      <div className="col-12" align="right"> */}
                   <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
                {/*    </div> */}
               </div>
             
              
          
             
            </div>
          </div>
         
        </div>
       
      </div>

   {/*   <Footter/> */}
     </div>
     
  );
}

export default Wait_Delivery;
