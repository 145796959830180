import React, { useEffect, useState } from "react";
import Tab from "./Tab";

function Rack_Component({
  tabs,
  handleUpdateTabs,
  tabsOld,
  mode,
  sizeUnit,
  wh_id,
}) {
  return (
    <div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs.map((tab, index) => (
          <li className="nav-item" key={index}>
            <a
              className={index === 0 ? "nav-link active" : "nav-link"}
              id={`tab-${index}-tab`}
              data-toggle="tab"
              href={`#tab-${index}`}
              role="tab"
              aria-controls={`tab-${index}`}
              aria-selected={index === 0 ? "true" : "false"}
            >
              Rack {tab.rack_name}
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {tabs.map((tab, index) => (
          <div
            className={
              index === 0 ? "tab-pane fade show active" : "tab-pane fade"
            }
            id={`tab-${index}`}
            role="tabpanel"
            aria-labelledby={`tab-${index}-tab`}
            key={index}
          >
            <Tab
              tab={tab}
              handleTabs={handleUpdateTabs}
              idx={index}
              tabOld={tabsOld}
              mode={mode}
              sizeUnit={sizeUnit}
              warehouse_id={wh_id}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Rack_Component;
