import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import { MultiSelect } from "react-multi-select-component";
import {
  getComConfig,
  getOem,
  getToken,
  getUser,
} from "../../../../Utils/Common";
import "../../../../../src/assets/css/Quotation_CutePlus.css";
import Price_List_Report from "./Component/Price_List_Report";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import moment from "moment";
import Select from "react-select";

function Price_List_Table() {
  const [filterSearch, setFilterSearch] = useState({
    priceListNo: "",
    customerId: "",
  });
  const [customerDD, setCustomerDD] = useState([]);
  let [priceListTable, setPriceListTable] = useState({
    data: [],
  });
  let [priceListDataReport, setPriceListDataReport] = useState({
    priceListData: [],
    priceListProductData: [],
  });

  useEffect(async () => {
    await getCustomerDD();
    await getPriceList();
  }, []);

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };


  async function getCustomerDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        const cus_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        cus_dd.unshift({
          label: "--Select--",
          value: ""
        })
        setCustomerDD(cus_dd);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          // text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getPriceList() {
    let filter = {
      oem_id: getOem(),
      company_id: getUser().com,
      priceListNo:
        filterSearch.priceListNo === null || filterSearch.priceListNo === ""
          ? null
          : filterSearch.priceListNo?.trim(),
      customerId:
        filterSearch.customerId === null || filterSearch.customerId === ""
          ? null
          : filterSearch.customerId,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/price_list/getPriceList",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: filter,
    })
      .then((response) => {
        setPriceListTable({
          ...priceListTable,
          data: response.data,
        });
        priceListTable.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          // text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function clearSearch() {
    setFilterSearch({
      priceListNo: "",
      customerId: "",
    });
    filterSearch.priceListNo = "";
    filterSearch.customerId = "";
    await getPriceList();
  }

  async function deletePriceList(id) {
    Swal.fire({
      title: "คุณต้องการจะลบ Price List นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus_sales + "/api/price_list/deletePriceList",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: {
            pl_id: id,
            oem_id: getOem(),
            company_id: getUser().com,
          },
        })
          .then(async (response) => {
            Swal.fire({
              title: "ลบ Price List สำเร็จ",
              icon: "success",
            });
            await getPriceList();
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  async function getPriceListData(pl_id) {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      pl_id: pl_id,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/price_list/getPriceListDataById",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        if (response.data.priceListData?.length > 0) {
          setPriceListDataReport({
            ...priceListDataReport,
            priceListData: response.data.priceListData,
          });
          priceListDataReport.priceListData = response.data.priceListData;
        }
        let productDetail = [];
        if (response.data.priceListProductData?.length > 0) {
          for (let item of response.data.priceListProductData) {
            let productData = {
              product_id: item.pp_product_id,
              oem_id: getOem(),
              company_id: getUser().com,
            };
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus +
                "/api/product/GetProductDetailsForQuotAdmin", // ใช้ซ้ำกับ Quotation
              headers: {
                Authorization: "Bearer " + getToken(),
                "Content-Type": "application/json",
                "X-TTT": Configs.API_TTT,
              },
              data: productData,
            })
              .then(async (response) => {
                if (response.data) {
                  let data = new FormData();
                  data.append("company_id", getUser().com);
                  data.append("oem_id", getOem());
                  data.append("user_id", getUser().fup);
                  data.append("products", JSON.stringify(response.data));
                  await axios({
                    method: "post",
                    url:
                      Configs.API_URL_IMG_cuteplus +
                      "/api/upload/getImageProduct",
                    headers: {
                      Authorization: getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "multipart/form-data",
                    },
                    data: data,
                  }).then(async (response) => {
                    if (response.data?.length > 0) {
                      item.productDetail = response.data[0];
                    }
                    productDetail.push(item);
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          }
          setPriceListDataReport({
            ...priceListDataReport,
            priceListProductData: productDetail,
          });
          priceListDataReport.priceListProductData = productDetail;
        }

        let company = null;
        await axios({
          method: "get",
          url: Configs.API_URL + "/company/findById?id=" + getUser().com,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(async (response) => {
            company = response.data;
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
        Price_List_Report(company, priceListDataReport);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error,
          // text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  const rowsData = [];
  for (let index = 0; index < priceListTable.data?.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["no2"] = index + 1; // for search
    rowItem["price_list_no"] = (
      <div style={{ textAlign: "left" }}>
        {priceListTable.data[index]?.pl_no === null ||
        priceListTable.data[index]?.pl_no === ""
          ? "-"
          : priceListTable.data[index]?.pl_no}
      </div>
    );
    rowItem["price_list_no2"] = // for search
      priceListTable.data[index]?.pl_no === null ||
      priceListTable.data[index]?.pl_no === ""
        ? "-"
        : priceListTable.data[index]?.pl_no;
    rowItem["customer"] = (
      <div style={{ textAlign: "left" }}>
        {priceListTable.data[index]?.pl_cus_name || "-"}
      </div>
    );
    rowItem["customer2"] = priceListTable.data[index]?.pl_cus_name || "-"; // for search
    rowItem["sales_name"] = (
      <div style={{ textAlign: "left" }}>
        {priceListTable.data[index]?.pl_created_name || "-"}
      </div>
    );
    rowItem["create_date"] = (
      <div style={{ textAlign: "left" }}>
        {priceListTable.data[index]?.pl_created_date === null ||
        priceListTable.data[index]?.pl_created_date === ""
          ? "-"
          : moment(priceListTable.data[index]?.pl_created_date).format(
              "DD/MM/YYYY"
            )}
      </div>
    );
    rowItem["create_date2"] = // for search
      priceListTable.data[index]?.pl_created_date === null ||
      priceListTable.data[index]?.pl_created_date === ""
        ? "-"
        : moment(priceListTable.data[index]?.pl_created_date).format(
            "DD/MM/YYYY"
          );
    rowItem["report"] = (
      <>
        <div>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              Swal.fire({
                title: "คุณต้องการจะออก Report นี้ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  // Swal.fire({
                  //   icon: "info",
                  //   title: "กำลังทำหน้า Report...",
                  //   text: "กรุณารอสักครู่...",
                  //   showConfirmButton: true,
                  //   allowEnterKey: false,
                  //   allowEscapeKey: false,
                  //   allowOutsideClick: false,
                  // });
                  await getPriceListData(priceListTable.data[index].pl_id);
                  // setQuotation_history({ ...quotation_history, pic: [] });
                  // quotation_history.pic = [];
                }
              });
            }}
          >
            Report
          </button>
        </div>
      </>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={priceListTable.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/Price_List/View/" +
                priceListTable.data[index].pl_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={priceListTable.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/Price_List/Edit/" +
                priceListTable.data[index].pl_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={priceListTable.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={async () => {
              await deletePriceList(priceListTable.data[index].pl_id);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }
  const priceListTableData = {
    columns: [
      {
        label: "No.",
        field: "no",
        width: 50,
      },
      {
        label: "Price List No.",
        field: "price_list_no",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        width: 50,
      },
      {
        label: "Sales Name",
        field: "sales_name",
        width: 50,
      },
      {
        label: "Create Date",
        field: "create_date",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        width: 50,
      },
    ],

    rows: rowsData,
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Price List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sale</li>
                  <li className="breadcrumb-item active">Price List</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={filterSearch.priceListNo}
                    onChange={(e) => {
                      setFilterSearch({
                        ...filterSearch,
                        priceListNo: e.target.value,
                      });
                      filterSearch.priceListNo = e.target.value;
                    }}
                  />
                  <label>Price List No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <Select
                    type="text"
                    required
                    styles={{
                      control: base => ({
                        ...base,
                        height: 35,
                        minHeight: 39,
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 999,
                      }),
                    }}
                    options={customerDD}
                    filterOption={customFilter}
                    value={
                      customerDD?.find(el => el.value === filterSearch.customerId) || { label: "--Select--", value: "" }
                    }
                    onChange={(e) => {
                      setFilterSearch({
                        ...filterSearch,
                        customerId: e.value,
                      });
                      filterSearch.customerId = e.value;
                    }}
                  >
                    {/* <option key="" value="">
                      Select a customer
                    </option>
                    {customerDD.map((option, index) => (
                      <option key={option.cus_name} value={option.cus_id}>
                        {option.cus_name}
                      </option>
                    ))} */}
                  </Select>
                  <label>Customer</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await getPriceList();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href = "/Sale_cuteplut/Price_List/Add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Price List</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={priceListTableData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="modal fade"
        id="modalQuotationCustomer"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalfoodRedisterLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title" id="modalfoodRedisterLabel">
                <b>View Quotation (Sales)</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              {/* history_data */}
              <div className="row">
                <div className="col-12">
                  <h4>View Version Quotation</h4>
                </div>
              </div>
              <div className="table-responsive">
                <div className="row">
                  <div className="col-12 col-md-12 col-xl-12">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={"history_data"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div
              className="modal-footer"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // ref={closeModalFoodRegister}
              >
                Close
              </button>
              <></>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Price_List_Table;
