import React, { useRef, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import NumberFormat from "react-number-format";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Pick_Product_Product from "./Component/Pick_Product_Product";
import Select from "react-select";

function Pick_Product_AEV({ mode, isHistory }) {
  const { id } = useParams();

  const [isDisabled, setIsDisabled] = useState(false);

  const [pageMode, setPageMode] = useState("");

  const [inputData, setInputData] = useState({
    req_id: "",
    req_version: "",
    req_no: "Auto Generate",
    quo_no: "",
    quo_no_real: "",
    req_date: new Date(),
    req_delivery_date: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    cus_name: "",
    cus_con_name: "",
    cus_house_number: "",
    cus_building: "",
    cus_swine: "",
    cus_soi: "",
    cus_road: "",
    cus_sub_district: "",
    cus_district: "",
    cus_province: "",
    cus_postcode: "",
    cus_con_name: "",
    cus_con_mail: "",
    cus_con_tel: "",
    con_name: "",
    con_place_name: "",
    con_tel: "",
    con_house_number: "",
    con_building: "",
    con_swine: "",
    con_soi: "",
    con_road: "",
    con_sub_district: "",
    con_district: "",
    con_province: "",
    con_postcode: "",
    ship_by: "",
    remark_1: "",
    remark_2: "",
    m3: "",
    weight: "",
    count: 0
  });

  const [inputDataTab2, setInputDataTab2] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [inputLang, setInputLang] = useState({
    data: "ENG",
    check: false,
  });

  const [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });

  const [functionTab2, setFunctionTab2] = useState({
    save: null,
    quotationSelectProduct: null,
    GetProduct: null
  });

  const [quotationDropDown, setQuotationDropDown] = useState({
    data: [],
  });

  const [customerDropDown, setCustomerDropDown] = useState({
    data: [],
  });

  const [contactDropDown, setContactDropDown] = useState({
    data: [],
    dropdownOld: [],
    dropdown: [],
  });

  const [shipDropDown, setShipDropDown] = useState({
    data: [],
    dropdownOld: [],
    dropdown: [],
  });

  const [shippingByDropDown, setShippingByDropDown] = useState({
    data: [],
  });

  const [modalCustomer, setModalCustomer] = useState(false);

  const [modalInputCustomer, setModalInputCustomer] = useState({
    customerOld: "",
    contactOld: "",
    shipOld: "",
    customer: "",
    contact: "",
    ship: "",
  });

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  async function GetQuotationDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotationDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setQuotationDropDown({
          ...quotationDropDown,
          data: response.data,
        });
        quotationDropDown.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCusConShipDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCusConShipDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const customer_dd = response.data.customer?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        customer_dd.unshift({
          label: "--Select Customer--",
          value: ""
        })
        setCustomerDropDown({
          ...customerDropDown,
          data: customer_dd,
        });
        customerDropDown.data = customer_dd;

        setContactDropDown({
          ...contactDropDown,
          data: response.data.contractPoint,
        });
        contactDropDown.data = response.data.contractPoint;

        setShipDropDown({
          ...shipDropDown,
          data: response.data.ship,
        });
        shipDropDown.data = response.data.ship;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetShippingByDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getShippingByDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setShippingByDropDown({
          ...shippingByDropDown,
          data: response.data,
        });
        shippingByDropDown.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCusConShipData() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      customer: modalInputCustomer.customer,
      contact: modalInputCustomer.contact,
      ship: modalInputCustomer.ship,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCusConShipData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // setInputData({
        //   ...inputData,
        //   cus_name: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_name
        //     : inputData.cus_name,
        //   cus_house_number: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_house_number
        //     : inputData.cus_house_number,
        //   cus_building: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_building
        //     : inputData.cus_building,
        //   cus_swine: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_swine
        //     : inputData.cus_swine,
        //   cus_soi: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_soi
        //     : inputData.cus_soi,
        //   cus_road: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_road
        //     : inputData.cus_road,
        //   cus_sub_district: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_sub_district
        //     : inputData.cus_sub_district,
        //   cus_district: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_district
        //     : inputData.cus_district,
        //   cus_province: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_province
        //     : inputData.cus_province,
        //   cus_postcode: response.data.customer.length !== 0
        //     ? response.data.customer[0].cus_postcode
        //     : inputData.cus_postcode,
        //   cus_con_name: response.data.contractPoint.length !== 0
        //     ? response.data.contractPoint[0].ccp_name
        //     : inputData.cus_con_name,
        //   cus_con_mail: response.data.contractPoint.length !== 0
        //     ? response.data.contractPoint[0].ccp_email
        //     : inputData.cus_con_mail,
        //   cus_con_tel: response.data.contractPoint.length !== 0
        //     ? response.data.contractPoint[0].ccp_tel
        //     : inputData.cus_con_tel,
        //   con_name: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_conteact_name
        //     : inputData.con_name,
        //   con_place_name: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_name
        //     : inputData.con_place_name,
        //   con_tel: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_tel
        //     : inputData.con_tel,
        //   con_house_number: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_house_number
        //     : inputData.con_house_number,
        //   con_building: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_building
        //     : inputData.con_building,
        //   con_swine: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_swine
        //     : inputData.con_swine,
        //   con_soi: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_soi
        //     : inputData.con_soi,
        //   con_road: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_road
        //     : inputData.con_road,
        //   con_sub_district: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_sub_district
        //     : inputData.con_sub_district,
        //   con_district: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_district
        //     : inputData.con_district,
        //   con_province: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_province
        //     : inputData.con_province,
        //   con_postcode: response.data.ship.length !== 0
        //     ? response.data.ship[0].csh_postcode
        //     : inputData.con_postcode,
        // });

        setInputData({
          ...inputData,
          quo_no: "",
          cus_name:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_name || ""
              : "",
          cus_house_number:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_house_number || ""
              : "",
          cus_building:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_building || ""
              : "",
          cus_swine:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_swine || ""
              : "",
          cus_soi:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_soi || ""
              : "",
          cus_road:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_road || ""
              : "",
          cus_sub_district:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_sub_district || ""
              : "",
          cus_district:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_district || ""
              : "",
          cus_province:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_province || ""
              : "",
          cus_postcode:
            response.data.customer.length !== 0
              ? response.data.customer[0].cus_postcode || ""
              : "",
          cus_con_name:
            response.data.contractPoint.length !== 0
              ? response.data.contractPoint[0].ccp_name || ""
              : "",
          cus_con_mail:
            response.data.contractPoint.length !== 0
              ? response.data.contractPoint[0].ccp_email || ""
              : "",
          cus_con_tel:
            response.data.contractPoint.length !== 0
              ? response.data.contractPoint[0].ccp_tel || ""
              : "",
          con_name:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_conteact_name || ""
              : "",
          con_place_name:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_name || ""
              : "",
          con_tel:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_tel || ""
              : "",
          con_house_number:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_house_number || ""
              : "",
          con_building:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_building || ""
              : "",
          con_swine:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_swine || ""
              : "",
          con_soi:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_soi || ""
              : "",
          con_road:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_road || ""
              : "",
          con_sub_district:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_sub_district || ""
              : "",
          con_district:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_district || ""
              : "",
          con_province:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_province || ""
              : "",
          con_postcode:
            response.data.ship.length !== 0
              ? response.data.ship[0].csh_postcode || ""
              : "",
        });

        setInputLang({
          data: "ENG",
          check: false,
        });

        await functionTab2.quotationSelectProduct("", true);
        await functionTab2.GetProduct();
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetQuotationSelectData(data) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      quotation: data,
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/quotation/getQuotationSelectData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setInputData({
          ...inputData,
          quo_no: data,
          cus_name:
            response.data.length !== 0
              ? response.data[0].quo_cus_name || ""
              : "",
          cus_house_number:
            response.data.length !== 0
              ? response.data[0].quo_cus_house_number || ""
              : "",
          cus_building:
            response.data.length !== 0
              ? response.data[0].quo_cus_building || ""
              : "",
          cus_swine:
            response.data.length !== 0 ? response.data[0].quo_swine || "" : "",
          cus_soi:
            response.data.length !== 0 ? response.data[0].quo_soi || "" : "",
          cus_road:
            response.data.length !== 0 ? response.data[0].quo_road || "" : "",
          cus_sub_district:
            response.data.length !== 0
              ? response.data[0].quo_sub_district || ""
              : "",
          cus_district:
            response.data.length !== 0
              ? response.data[0].quo_district || ""
              : "",
          cus_province:
            response.data.length !== 0
              ? response.data[0].quo_province || ""
              : "",
          cus_postcode:
            response.data.length !== 0
              ? response.data[0].quo_postcode || ""
              : "",
          cus_con_name:
            response.data.length !== 0
              ? response.data[0].quo_contract_name || ""
              : "",
          cus_con_mail:
            response.data.length !== 0
              ? response.data[0].quo_contract_email || ""
              : "",
          cus_con_tel:
            response.data.length !== 0
              ? response.data[0].quo_contract_tel || ""
              : "",
          con_name: "",
          con_place_name: "",
          con_tel: "",
          con_house_number: "",
          con_building: "",
          con_swine: "",
          con_soi: "",
          con_road: "",
          con_sub_district: "",
          con_district: "",
          con_province: "",
          con_postcode: "",
        });

        setInputLang({
          data:
            response.data.length !== 0
              ? response.data[0].quo_lang || "ENG"
              : "ENG",
          check:
            response.data.length !== 0
              ? response.data[0].quo_lang === "TH"
                ? true
                : false
              : false,
        });

        setModalInputCustomer({
          ...modalInputCustomer,
          customerOld:
            response.data.length !== 0 ? response.data[0].quo_cus_id || "" : "",
          contactOld:
            response.data.length !== 0 ? response.data[0].quo_ccp_id || "" : "",
          shipOld: "",
          customer:
            response.data.length !== 0 ? response.data[0].quo_cus_id || "" : "",
          contact:
            response.data.length !== 0 ? response.data[0].quo_ccp_id || "" : "",
          ship: "",
        });
        modalInputCustomer.customer = response.data.length !== 0 ? response.data[0].quo_cus_id || "" : "";

        const contactTamp = contactDropDown.data.filter((item) => {
          return item.ccp_cus_id === response.data[0].quo_cus_id;
        }).map((el) => ({ label: el.ccp_name, value: el.ccp_id })) || []
        setContactDropDown({
          ...contactDropDown,
          dropdown:
            response.data.length !== 0
              ? contactTamp
              : [],
          dropdownOld:
            response.data.length !== 0
              ? contactTamp
              : [],
        });

        const shipTamp = shipDropDown.data.filter((item) => {
          return item.csh_cus_id === response.data[0].quo_cus_id;
        }).map((el) => ({ label: el.csh_name, value: el.csh_id })) || []
        setShipDropDown({
          ...shipDropDown,
          dropdown:
            response.data.length !== 0
              ? shipTamp
              : [],
          dropdownOld:
            response.data.length !== 0
              ? shipTamp
              : [],
        });

        await functionTab2.quotationSelectProduct(data, true);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveOption() {

    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];



        if (
          inputData.cus_name === null ||
          inputData.cus_name?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Customer Name",
          };
          error_list.push(temp_err);
        }

        if (
          inputDataTab2.data.length === 0
        ) {
          let temp_err = {
            message: "กรุณาเพิ่ม Product อย่างน้อย 1 รายการ",
          };
          error_list.push(temp_err);
        }

        await functionTab2.save();
        error_list.push(...errorListTab2.data);

        if (error_list.length > 0) {
          // let temp_err = {
          //   message: `- Tab Customer Add -`,
          // };
          // error_list.unshift(temp_err);

          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          let axiosData = {};

          const temp = {
            tab1: inputData,
            tab1_1: modalInputCustomer,
            tab1_2: inputLang,
            tab2: inputDataTab2,
            tab2_1: shipDropDown,
            user_id: getUser().fup,
            user_name: `${getUser().name} ${getUser().surname}`,
            company_id: getUser().com,
            oem_id: getOem(),
          };
          // console.log(temp);
          if (mode === "add") {
            axiosData = {
              method: "post",
              url: Configs.API_URL_cuteplus_sales + "/api/requisition/addRequisition",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };
          }

          if (mode === "edit") {
            axiosData = {
              method: "post",
              url: Configs.API_URL_cuteplus_sales + "/api/requisition/updateRequisition",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };
          }

          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(() => {
            axios(axiosData)
              .then(function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href =
                    "/Sale_cuteplut/เบิกสินค้า_sale";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  const sendApproveRequisition = () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะ Send Approve ใบเบิกสินค้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];
        if (inputData.quo_no) {
          const check_has = quotationDropDown.data?.find(el => el.quo_id === inputData.quo_no)
          if (check_has) {
            inputData.quo_no_real = check_has.quo_no
          }
        }

        if (
          inputData.cus_name === null ||
          inputData.cus_name?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Customer Name",
          };
          error_list.push(temp_err);
        }

        if (
          inputDataTab2.data.length === 0
        ) {
          let temp_err = {
            message: "กรุณาเพิ่ม Product อย่างน้อย 1 รายการ",
          };
          error_list.push(temp_err);
        }

        await functionTab2.save();
        error_list.push(...errorListTab2.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
          return;
        } else {
          const temp = {
            company_id: getUser().com,
            oem_id: getOem(),
            user_id: getUser().fup,
            user_name: getUser().name + " " + getUser().surname,
            tab1: inputData,
            tab1_1: modalInputCustomer,
            tab1_2: inputLang,
            tab2: inputDataTab2,
            tab2_1: shipDropDown,
          }
          await axios({
            method: "post",
            url: Configs.API_URL_cuteplus_sales + `/api/requisition/sendApproveRequisition`,
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(async function (response) {

              Swal.fire({
                icon: "success",
                title: "Send Approve สำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.href =
                  "/Sale_cuteplut/เบิกสินค้า_sale";
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });

        }
      }
    });
  }

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/Sale_cuteplut/เบิกสินค้า_sale";
        }
      });
    } else {
      window.location.href = "/Sale_cuteplut/เบิกสินค้า_sale";
    }
  }

  const GetRequisitionById = async () => {
    let temp = {
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      req_id: id,
      isHistory: isHistory
    }

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/requisition/GetRequisitionById",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        const res = response.data[0];
        if (res) {
          setModalInputCustomer({
            ...modalInputCustomer,
            customerOld:
              res.req_cus_id || "",
            contactOld:
              res.req_ccp_id || "",
            shipOld: res.req_csh_id || "",
            customer:
              res.req_cus_id || "",
            contact:
              res.req_ccp_id || "",
            ship: res.req_csh_id || "",
          });

          setInputData({
            ...inputData,
            req_id: res.req_id,
            req_version: res.req_version,
            req_no: res.req_no,
            quo_no: res.req_quo_id || "",
            req_date: res.req_date ? new Date(res.req_date) : "",
            req_delivery_date: res.req_delivery_date ? new Date(res.req_delivery_date) : "",
            cus_name: res.req_cus_name,
            cus_con_name: res.req_contact_name,
            cus_house_number: res.req_cus_house_number,
            cus_building: res.req_cus_building,
            cus_swine: res.req_swine,
            cus_soi: res.req_soi,
            cus_road: res.req_road,
            cus_sub_district: res.req_sub_district,
            cus_district: res.req_district,
            cus_province: res.req_province,
            cus_postcode: res.req_postcode,
            cus_con_mail: res.req_contact_email,
            cus_con_tel: res.req_contact_tel,
            con_name: res.req_ship_contract_name,
            con_place_name: res.req_csh_name,
            con_tel: res.req_ship_tel,
            con_house_number: res.req_ship_house_number,
            con_building: res.req_ship_building,
            con_swine: res.req_ship_swine,
            con_soi: res.req_ship_soi,
            con_road: res.req_ship_road,
            con_sub_district: res.req_ship_sub_district,
            con_district: res.req_ship_district,
            con_province: res.req_ship_province,
            con_postcode: res.req_ship_postcode,
            ship_by: res.req_ds_id || "",
            remark_1: res.req_cus_remark,
            remark_2: res.req_remark,
          });

          setInputLang({
            data:
              response.data.length !== 0
                ? response.data[0].req_lang || "ENG"
                : "ENG",
            check:
              response.data.length !== 0
                ? response.data[0].req_lang === "TH"
                  ? true
                  : false
                : false,
          });

          const contactTamp = contactDropDown.data.filter((item) => {
            return item.ccp_cus_id === response.data[0].req_cus_id;
          }).map((el) => ({ label: el.ccp_name, value: el.ccp_id })) || []
          contactTamp.unshift({
            label: "--Select Contact Name--",
            value: ""
          });
          setContactDropDown({
            ...contactDropDown,
            dropdown:
              response.data.length !== 0
                ? contactTamp
                : [],
            dropdownOld:
              response.data.length !== 0
                ? contactTamp
                : [],
          });

          const shipTamp = shipDropDown.data.filter((item) => {
            return item.csh_cus_id === response.data[0].req_cus_id;
          }).map((el) => ({ label: el.csh_name, value: el.csh_id })) || []
          shipTamp.unshift({
            label: "--Select Ship to--",
            value: ""
          });
          setShipDropDown({
            ...shipDropDown,
            dropdown:
              response.data.length !== 0
                ? shipTamp
                : [],
            dropdownOld:
              response.data.length !== 0
                ? shipTamp
                : [],
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const cancelRequisition = async () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ ใบเบิกสินค้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {

        const temp = {
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          user_name: getUser().name + " " + getUser().surname,
          ref_id: id,
          status_id: "7019fcdb-ea83-4bc0-8f09-a3cddfe50467",
          form_table: true
        }
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus_sales + `/api/requisition/updateStatusRequisition`,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            // console.log('response saveDrafQuotation -', response.data);

            Swal.fire({
              icon: "success",
              title: "ยกเลิกรายการใบเบิกสินค้า สำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href =
                "/Sale_cuteplut/เบิกสินค้า_sale";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  useEffect(async () => {
    await GetQuotationDropDown();
    await GetCusConShipDropDown();
    await GetShippingByDropDown();

    if (mode === "add") {
      setPageMode("Add");
      setIsDisabled(false);
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
      // await GetSupplierData();
    } else if (mode === "view") {
      setPageMode("Read");
      setIsDisabled(true);
      // await GetSupplierData();
    }

    if (id)
      await GetRequisitionById();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>เพิ่มใบเบิกสินค้า</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sales</li>
                  <li className="breadcrumb-item active">เบิกสินค้า</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={async () => {
                      await saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                {mode === "edit" ? (
                  <div className="col-6 col-md-3 col-xl-2">
                    <button
                      type="button"
                      class="btn btn-block btn-info"
                      onClick={() => {
                        sendApproveRequisition();
                      }}
                    >
                      Send Approve
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div className="row mt-2">
              <div className="col-6 col-md-6 col-xl-6">
                <h4>เวอร์ชั่นใบเบิกสินค้า : {inputData.req_version}</h4>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div
                className="row"
                style={{ justifyContent: "start", alignItems: "start" }}
              >
                <div
                  className="col-3"
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <h3 className="card-title" style={{ whiteSpace: "nowrap" }}>
                    ใบเบิกสินค้า (Sales)
                  </h3>
                  {mode === "edit" ? (
                    <button
                      style={{ whiteSpace: "nowrap" }}
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={() => {
                        cancelRequisition();
                      }}
                    >
                      ยกเลิกใบเบิกสินค้า
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={true}
                      value={inputData.req_no}
                    // onChange={(e) => {
                    //   setInputData({
                    //     ...inputData,
                    //     req_no: e.target.value,
                    //   });
                    // }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Requisition No.
                      {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.quo_no}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          quo_no: e.target.value,
                        });

                        GetQuotationSelectData(e.target.value);
                      }}
                    >
                      <option key="" value="">
                        --Select Quotation--
                      </option>

                      {quotationDropDown.data.map((data, index) => {
                        return (
                          <option key={index} value={data.quo_id}>
                            {data.quo_no}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Quotation No.
                      {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <DatePicker
                      required
                      disabled={true}
                      selected={inputData.req_date}
                      locale="th"
                      wrapperClassName="w-100"
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => {
                        setInputData({
                          ...inputData,
                          req_date: date,
                        });
                      }}
                      selectsStart
                      showYearDropdown
                      showMonthDropdown
                      startDate={new Date()}
                      minDate={new Date()}
                      customInput={<CustomInput isDis={true} />}
                    />
                    <label>
                      Requisition Date
                      {/* {reportDate.startDate === "" ||
                      reportDate.startDate === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <DatePicker
                      required
                      disabled={isDisabled}
                      selected={inputData.req_delivery_date}
                      locale="th"
                      wrapperClassName="w-100"
                      dateFormat={"dd-MM-yyyy"}
                      onChange={async (date) => {
                        setInputData({
                          ...inputData,
                          req_delivery_date: date,
                        });
                      }}
                      selectsStart
                      showYearDropdown
                      showMonthDropdown
                      // startDate={new Date().setDate(new Date().getDate() + 30)}
                      minDate={new Date()}
                      customInput={<CustomInput isDis={isDisabled} />}
                    />
                    <label>
                      Delivery Date
                      {/* {reportDate.startDate === "" ||
                      reportDate.startDate === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label
                    style={{
                      fontSize: "24px",
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    Customer Detail
                  </label>
                </div>
              </div>

              <hr style={{ margin: "0px 0px 20px 0px" }} />

              <div className="row">
                <div className="col-12 col-md-6">
                  <div
                    class="form-group"
                    style={{ display: "flex ", alignItems: "baseline" }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_name}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_name: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Customer Name
                      {inputData.cus_name === "" ||
                        inputData.cus_name === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>

                    <a
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => {
                        setModalCustomer(true);
                      }}
                    >
                      <i class="fas fa-search"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label
                    style={{
                      fontSize: "24px",
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    ที่อยู่
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_house_number}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_house_number: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      บ้านเลขที่
                      {/* {inputData.customer_address.house_number === "" ||
                                inputData.customer_address.house_number === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_building}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_building: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      อาคาร
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_swine}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_swine: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      หมู่
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_soi}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_soi: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ซอย
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_road}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_road: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ถนน
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_sub_district}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_sub_district: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      แขวง/ตำบล
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_district}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_district: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      เขต/อำเภอ
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-5">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_province}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_province: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      จังหวัด
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      required
                      decimalScale={0}
                      allowNegative={false}
                      format={"#####"}
                      disabled={isDisabled}
                      value={inputData.cus_postcode}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_postcode: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      รหัสไปรษณีย์
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_con_name}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_con_name: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Customer Contact Name
                      {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.cus_con_mail}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_con_mail: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Contact E-mail
                      {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      // format={"###-###-####"}
                      required
                      disabled={isDisabled}
                      value={inputData.cus_con_tel}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          cus_con_tel: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Contact Tel.
                      {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label
                    style={{
                      fontSize: "24px",
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    Ship to
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_name}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_name: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ผู้ติดต่อ
                      {/* {inputData.cus_name === "" ||
                      inputData.cus_name === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_place_name}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_place_name: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ชื่อที่อยู่
                      {/* {inputData.cus_name === "" ||
                      inputData.cus_name === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      // format={"###-###-####"}
                      required
                      disabled={isDisabled}
                      value={inputData.con_tel}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_tel: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      เบอร์โทรศัพท์
                      {/* {inputData.office_address.zip_code === "" ||
                                inputData.office_address.zip_code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label
                    style={{
                      fontSize: "24px",
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    ที่อยู่
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_house_number}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_house_number: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      บ้านเลขที่
                      {/* {inputData.customer_address.house_number === "" ||
                                inputData.customer_address.house_number === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_building}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_building: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      อาคาร
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_swine}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_swine: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      หมู่
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_soi}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_soi: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ซอย
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_road}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_road: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      ถนน
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_sub_district}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_sub_district: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      แขวง/ตำบล
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_district}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_district: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      เขต/อำเภอ
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-5">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.con_province}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_province: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      จังหวัด
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-3">
                  <div class="form-group">
                    <NumberFormat
                      type="text"
                      className="form-control"
                      required
                      decimalScale={0}
                      format={"#####"}
                      allowNegative={false}
                      disabled={isDisabled}
                      value={inputData.con_postcode}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          con_postcode: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      รหัสไปรษณีย์
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.ship_by}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          ship_by: e.target.value,
                        });
                      }}
                    >
                      <option key="" value="" disabled>
                        --Select Shipping--
                      </option>

                      {shippingByDropDown.data.map((data, index) => {
                        return (
                          <option key={index} value={data.ds_id}>
                            {data.ds_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Shipping by
                      {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.remark_1}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          remark_1: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Remark
                      {/* {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <label
                    style={{
                      fontSize: "24px",
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    Product
                    {inputDataTab2.data.length === 0 ? (
                      <span style={{ color: "red" }}> *</span>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              </div>

              <hr style={{ margin: "0px 0px 20px 0px" }} />

              <div>
                <Pick_Product_Product
                  id={id}
                  mode={mode}
                  isHistory={isHistory}
                  isDisabled={isDisabled}
                  inputData={inputData}
                  setInputData={setInputData}
                  inputDataTab2={inputDataTab2}
                  cus_id_filter={modalInputCustomer.customer}
                  setInputDataTab2={setInputDataTab2}
                  errorListTab2={errorListTab2}
                  setErrorListTab2={setErrorListTab2}
                  functionTab2={functionTab2}
                  setFunctionTab2={setFunctionTab2}
                  inputLang={inputLang}
                  setInputLang={setInputLang}
                />
              </div>

              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <textarea
                      style={{ minHeight: "5rem" }}
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={inputData.remark_2}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          remark_2: e.target.value,
                        });
                      }}
                    />
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Remark
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>

        <Modal
          size="sm"
          className="Modal_Center_LeftRight"
          isOpen={modalCustomer}
          toggle={() => {
            setModalInputCustomer({
              ...modalInputCustomer,
              customer: modalInputCustomer.customerOld,
              contact: modalInputCustomer.contactOld,
              ship: modalInputCustomer.shipOld,
            });

            modalInputCustomer.customer = modalInputCustomer.customerOld;
            modalInputCustomer.contact = modalInputCustomer.contactOld;
            modalInputCustomer.ship = modalInputCustomer.shipOld;

            setContactDropDown({
              ...contactDropDown,
              dropdown: contactDropDown.dropdownOld,
            });

            setShipDropDown({
              ...shipDropDown,
              dropdown: shipDropDown.dropdownOld,
            });

            setModalCustomer(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setModalInputCustomer({
                ...modalInputCustomer,
                customer: modalInputCustomer.customerOld,
                contact: modalInputCustomer.contactOld,
                ship: modalInputCustomer.shipOld,
              });

              modalInputCustomer.customer = modalInputCustomer.customerOld;
              modalInputCustomer.contact = modalInputCustomer.contactOld;
              modalInputCustomer.ship = modalInputCustomer.shipOld;

              setContactDropDown({
                ...contactDropDown,
                dropdown: contactDropDown.dropdownOld,
              });

              setShipDropDown({
                ...shipDropDown,
                dropdown: shipDropDown.dropdownOld,
              });

              setModalCustomer(false);
            }}
          >
            <h4 className="modal-title" id="modalCustomer">
              <b>Customer</b>
            </h4>
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    type="text"
                    required
                    options={customerDropDown.data}
                    filterOption={customFilter}
                    isDisabled={isDisabled}
                    value={
                      customerDropDown.data?.find(el => el.value === modalInputCustomer.customer) || { label: "--Select Customer--", value: "" }
                    }
                    onChange={(e) => {
                      setModalInputCustomer({
                        ...modalInputCustomer,
                        customer: e.value,
                        contact: "",
                        ship: "",
                      });

                      const contactTamp = contactDropDown.data.filter(
                        (item) => {
                          return item.ccp_cus_id === e.value;
                        }
                      ).map(el => ({ label: el.ccp_name, value: el.ccp_id }));
                      contactTamp.unshift({
                        label: "--Select Contact Name--",
                        value: ""
                      })

                      setContactDropDown({
                        ...contactDropDown,
                        dropdown: contactTamp,
                      });

                      const shipTamp = shipDropDown.data.filter((item) => {
                        return item.csh_cus_id === e.value;
                      }).map(el => ({ label: el.csh_name, value: el.csh_id }));
                      shipTamp.unshift({
                        label: "--Select Ship to--",
                        value: ""
                      })
                      setShipDropDown({
                        ...shipDropDown,
                        dropdown: shipTamp,
                      });
                    }}
                  >
                    {/* <option key="" value="">
                      --Select Customer--
                    </option>

                    {customerDropDown.data.map((data, index) => {
                      return (
                        <option key={index} value={data.cus_id}>
                          {data.cus_name}
                        </option>
                      );
                    })} */}
                  </Select>
                  <label
                    style={{
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    Customer
                    {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    type="text"
                    required
                    options={contactDropDown.dropdown}
                    filterOption={customFilter}
                    isDisabled={isDisabled || contactDropDown.dropdown.length <= 1}
                    value={
                      contactDropDown.dropdown?.find(el => el.value === modalInputCustomer.contact)
                      || { label: "--Select Contact Name--", value: "" }
                    }
                    onChange={(e) => {
                      setModalInputCustomer({
                        ...modalInputCustomer,
                        contact: e.value,
                      });
                    }}
                  >
                    {/*  <option key="" value="">
                      --Select Contact Name--
                    </option>

                    {contactDropDown.dropdown.map((data, index) => {
                      return (
                        <option key={index} value={data.ccp_id}>
                          {data.ccp_name}
                        </option>
                      );
                    })} */}
                  </Select>
                  <label
                    style={{
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    Contact Name
                    {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    type="text"
                    required
                    options={shipDropDown.dropdown}
                    filterOption={customFilter}
                    isDisabled={isDisabled || shipDropDown.dropdown.length <= 1}
                    value={
                      shipDropDown.dropdown?.find(el => el.value === modalInputCustomer.ship)
                      || { label: "--Select Ship to--", value: "" }
                    }
                    onChange={(e) => {
                      setModalInputCustomer({
                        ...modalInputCustomer,
                        ship: e.value,
                      });
                    }}
                  >
                    {/* <option key="" value="">
                      --Select Ship to--
                    </option>

                    {shipDropDown.dropdown.map((data, index) => {
                      return (
                        <option key={index} value={data.csh_id}>
                          {data.csh_name}
                        </option>
                      );
                    })} */}
                  </Select>
                  <label
                    style={{
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    Ship to
                    {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                  </label>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter style={{ justifyContent: "space-between" }}>
            <button
              type="button"
              className="btn btn-danger"
              onClick={async () => {
                setModalInputCustomer({
                  ...modalInputCustomer,
                  customer: modalInputCustomer.customerOld,
                  contact: modalInputCustomer.contactOld,
                  ship: modalInputCustomer.shipOld,
                });

                modalInputCustomer.customer = modalInputCustomer.customerOld;
                modalInputCustomer.contact = modalInputCustomer.contactOld;
                modalInputCustomer.ship = modalInputCustomer.shipOld;

                setContactDropDown({
                  ...contactDropDown,
                  dropdown: contactDropDown.dropdownOld,
                });

                setShipDropDown({
                  ...shipDropDown,
                  dropdown: shipDropDown.dropdownOld,
                });

                setModalCustomer(false);
              }}
            >
              ยกเลิก
            </button>

            <button
              type="button"
              className="btn btn-success"
              onClick={async () => {
                setModalInputCustomer({
                  ...modalInputCustomer,
                  customerOld: modalInputCustomer.customer,
                  contactOld: modalInputCustomer.contact,
                  shipOld: modalInputCustomer.ship,
                });

                modalInputCustomer.customerOld = modalInputCustomer.customer;
                modalInputCustomer.contactOld = modalInputCustomer.contact;
                modalInputCustomer.shipOld = modalInputCustomer.ship;

                setContactDropDown({
                  ...contactDropDown,
                  dropdownOld: contactDropDown.dropdown,
                });

                setShipDropDown({
                  ...shipDropDown,
                  dropdownOld: shipDropDown.dropdown,
                });

                setModalCustomer(false);

                await GetCusConShipData();
              }}
            >
              บันทึก
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Pick_Product_AEV;
