import React from "react";
import Construccomponent from "../../component/Construction";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
function LogMonitory(params) {
  return (
    <div className="wrapper">
  {/*     <Header />
      <Sidebar menu="qc" activemenu="lm" submenu="none" /> */}
      <Construccomponent name="Log Monitory" />
      {/* <Footter /> */}
    </div>
  );
}
export default LogMonitory;
