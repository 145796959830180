import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";

function WinSpeedHandheld(params) {
    const [stage,setStage] = useState({ stage:'',item:'',isDis:false});
    const [list_scan_item, setList_scan_item] = useState([]);
    const [test,settest] = useState({test:''});
   
    if(stage.stage !== '' && stage.item.length == 11 ){
        let temp = {
            tag_no:stage.item,
        };

        axios({
            method: "post",
            url: Configs.API_URL + "/api/winSpeed/checkTag",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              //console.log(response.data);
              if(response.data.length > 0){

          
              let tempData = list_scan_item;
        
              let temp = {
                  item:response.data[0].MAT_ID,
                  amount:0,
      
              };
              let check = tempData.filter((e)=>{ return e.item === temp.item});
              if(check.length == 0){
                  tempData.push(temp);
              }
      
              setList_scan_item(tempData);
             
          
            }else{
                Swal.fire('Error','This item is not defind!!','error');
            }

            setStage({...stage,item:''});
            stage.item = '';
            })
            .catch(function (error) {
              console.log(error);
            });
            setStage({...stage,item:''});
            stage.item = '';
    }

    function remove(index){
        Swal.fire({
            title: "Do you want to Remove Item?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `Yes`,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `No`,
          }).then((result) => {
            if (result.isConfirmed) {
        let tempData = list_scan_item;
        tempData.splice(index, 1);
        setList_scan_item(tempData);
        settest({...test,test:''});
        test.test = '';
            }});

    }


   async function saveOption(){
    Swal.fire({
        title: "Do you want to Save List Item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
            const error_list = [];
            let check_amount = list_scan_item.filter((e)=>{return e.amount == 0 || e.amount == ''});
            if(check_amount.length > 0){
                let temp_err ={
                    message:"กรุณากรอกจำนวน amount ของ item !!!",
                  }
                  error_list.push(temp_err);
            }
            
            if(error_list.length > 0){
              var err_message = "";
              for(var e =0; e < error_list.length;e++){
                err_message += "<br/>"+error_list[e].message;
              }
              Swal.fire("Error", err_message, "error");
            }else{
                let temp = {
                    tag_list:list_scan_item,
                    stage:stage.stage,
                }
                axios({
                    method: "post",
                    url: Configs.API_URL + "/api/winSpeed/saveHandheld",
                    headers: {
                      Authorization: getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "application/json",
                    },
                    data: temp,
                  })
                    .then(function (response) {
                        Swal.fire('Success','ดำเนินรายการเรียบร้อย','success');
                        setList_scan_item([]);
                        setStage({...stage,stage:'',item:'',isDis:false});
                        stage.stage = '';
                        stage.item = '';
                        stage.isDis = false;
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
            }
        }})
    }


  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>WinSpeed Handheld</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">WinSpeed Handheld</li>
                </ol>
              </div>
            </div>
          </div>


        </section>

        <section className="content"> 
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WinSpeed Handheld</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body"
            
            >
               <div className="row">
                            <div className="col-6 col-md-3 col-xl-3 mb-2">
                            <button className="btn btn-block btn-outline-success"   
                            onClick={()=>{setStage({...stage,stage:'MAT IN'})}}
                            disabled ={stage.isDis}
                            >MAT IN</button>
                            </div>
                            <div className="col-6 col-md-3 col-xl-3 mb-2">
                            <button className="btn btn-block btn-outline-danger"
                                        onClick={()=>{setStage({...stage,stage:'MAT OUT'})}}
                                        disabled ={stage.isDis}
                            >MAT OUT</button>
                            </div>
                            {/* <div className="col-6 col-md-3 col-xl-3 mb-2">
                            <button className="btn btn-block btn-outline-success" 
                                        onClick={()=>{setStage({...stage,stage:'FG IN'})}}
                                        disabled ={stage.isDis}
                            >FG IN</button>
                            </div>
                            <div className="col-6 col-md-3 col-xl-3 mb-2">
                            <button className="btn btn-block btn-outline-danger" 
                                        onClick={()=>{setStage({...stage,stage:'FG OUT'})}}
                                        disabled ={stage.isDis}
                            >FG OUT</button>
                            </div> */}
                            </div>
                            <br/>
                            <h2>Process Select : {stage.stage} </h2>
                            <hr/>

                            <div className="row">
                                <div className="col-12">
                                    <label>Scan Item</label>
                                    <input type ='text' className="form-control" disabled={stage.stage !== '' && stage.isDis == false ? false : true } value = {stage.item} onChange={(e)=>{setStage({...stage,item:e.target.value})}}/>
                                </div>

                            
                            </div>

                            {list_scan_item.map((e,index)=>{
                                return (<>
                                   <div className="row mt-2 " style = {{backgroundColor:'#ddd',marginLeft:'1px',marginRight:'1x'}}>
                                <div className="col-6 form-control">
                                    {e.item}
                                </div>
                                <div className="col-3">
                                <NumberFormat
                        thousandSeparator={true}
                        allowNegative={false}
                       
                        type="text"
                        className="form-control"
                        decimalScale={0}
                        value={e.amount}
                        onChange={(n) => {
                          let tempData = list_scan_item;
                          tempData[index].amount = n.target.value;
                          setList_scan_item(tempData);
                          settest({...test,test:''});
                          test.test = '';
                        }}
                        disabled = {stage.isDis}
                        autofocus
                        required
                      />
                                    
                                    
                                </div>
                                <div className="col-3 form-control" style ={{textAlign:'right',padding:'0px 0px'}}>
                                  <button className="btn btn-danger" style ={{width:'100%'}} onClick={()=>{remove(index);}}
                                  disabled = {stage.isDis}
                                  >X</button>
                                </div>
                            </div>
                                </>);
                            })}


                            <div className="row mt-4">
                                <div className="col-12">
                                    {stage.isDis == false ? (

<button className="btn btn-primary" style ={{width:'100%'}} onClick={()=>{
    setStage({...stage,isDis:true})
}}
disabled = {list_scan_item.length > 0 ? false : true}

  >Scan Finish</button>
                                    ):(
                                        <button className="btn btn-primary" style ={{width:'100%'}} onClick={()=>{
                                            setStage({...stage,isDis:false})
                                        }}
                            
                                          >Scan Again</button>

                                    )}
                               
                                </div>
                            </div>


                            <div className="row mt-4">
                                <div className="col-12">
                                    <button className="btn btn-block btn-success" disabled = {stage.isDis ? false : true} onClick={()=>{saveOption();}}>Save</button>
                                </div>
                            </div>
                         
            </div>
 
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
      </div>
    </div>
  );
}
export default WinSpeedHandheld;
