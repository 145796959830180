import React, { useEffect, useRef, useState } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import PointCartLine from "../Incentive_User_component/Point_Cart_Line";
import "../../../../assets/css/thankPoint.css";
import UserComponent from "../Incentive_User_Thank_Point/UserComponent";
import UserSelectComponent from "../Incentive_User_Thank_Point/UserSelectComponent";
import saveMoney from "../../../../assets/img/incentive/save_money1.png";
import "../../../../assets/css/add-on-1.css";

function IncentiveUserThankPoint() {
  /* const selectRef = useRef();
    function OnclickDropdown() {
        console.log("click");
        // selectRef.current.focus();
        document.getElementById("thank_point").click();
    } */
  const [test, settest] = useState({
    test: "",
  });
  const [User, setUser] = useState([]);
  const [UserFilter, setUserFilter] = useState([]);
  const [SelectUser, setSelectUser] = useState([]);
  // console.log("SelectUser", SelectUser);
  const [filterEmp, setfilterEmp] = useState({
    emp_name: "",
    emp_nick_name: "",
    emp_no: "",
  });
  const [thank_point, setthank_point] = useState([]);

  const [GiveThankPoint, setGiveThankPoint] = useState({
    tph_thank_point_id: "",
    tph_thank_point_name: "",
    tph_point: "",
    tph_remark: "",
  });

  useEffect(() => {
    getAllEmployeeComOem(); // ดึงข้อมูลพนักงานทั้งหมด
    GetThankPointData(); // ดึงข้อมูล thank point ทั้งหมด
  }, []);

  function deSelect(item) {
    let user = UserFilter;
    let select = SelectUser;
    let data = user.map((x) => {
      if (x.emp_name_th === item.emp_name_th) {
        x.check = false;
      }
      return x;
    });
    let data2 = select.findIndex((x) => {
      return x.emp_name_th === item.emp_name_th;
    });
    select.splice(data2, 1);
    setSelectUser(select);
    setUserFilter(data);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function getAllEmployeeComOem() {
    let data = {
      company_id: getUser().com,
      oem_id: getOem(),
      fup: getUser().fup,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getAllEmployeeComOem",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        console.log(res.data.data);
        let newdata = res.data.data;
        newdata.map((x) => {
          x.check = false;
        });
        setUser(newdata);
        setUserFilter(newdata);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const GetThankPointData = async () => {
    // console.log(filler_thank_point);
    var get_filler_thank_point = {
      oem_id: getOem(),
      company_id: getUser().com,
      tp_point_id: null,
      tp_point_name: null,
      tp_point_min: null,
      tp_point_max: null,
    };
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/thankPoint/filterThankPoint",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_thank_point,
    })
      .then(function (response) {
        console.log(response.data.thank_point_list, "ds");
        setthank_point(response.data.thank_point_list);
      })
      .catch(function (error) {
        console.log(error);
      });
    // console.log(thank_point);
  };

  function GiveTP(value) {
    let tp_point = thank_point.filter((x) => x.tp_id === value)[0].tp_point;
    let tp_point_name = thank_point.filter((x) => x.tp_id === value)[0]
      .tp_point_name;
    setGiveThankPoint({
      ...GiveThankPoint,
      tph_thank_point_id: value,
      tph_point: tp_point,
      tph_thank_point_name: tp_point_name,
    });
    GiveThankPoint.tph_thank_point_id = value;
    GiveThankPoint.tph_point = tp_point;
    GiveThankPoint.tph_thank_point_name = tp_point_name;
    settest({ ...test, test: "" });
    test.test = "";
  }

  function clear() {
    let user = UserFilter;
    let select = SelectUser;
    let data = user.map((x) => {
      x.check = false;
      return x;
    });
    setUserFilter(data);
    setSelectUser([]);
    setGiveThankPoint({
      ...GiveThankPoint,
      tph_thank_point_id: "",
      tph_point: "",
      tph_thank_point_name: "",
      tph_remark: "",
    });
    GiveThankPoint.tph_thank_point_id = "";
    GiveThankPoint.tph_point = "";
    GiveThankPoint.tph_thank_point_name = "";
    GiveThankPoint.tph_remark = "";
    setfilterEmp({
      ...filterEmp,
      emp_name: "",
      emp_nick_name: "",
      emp_no: "",
    });
    filterEmp.emp_name = "";
    filterEmp.emp_nick_name = "";
    filterEmp.emp_no = "";
    filterEmployeeFront(
      filterEmp.emp_name,
      filterEmp.emp_nick_name,
      filterEmp.emp_no
    );
    settest({ ...test, test: "" });
    test.test = "";
  }

  function filterEmployeeFront(name, nickname, no) {
    console.log(name, nickname, no);
    // Convert empty or undefined arguments to null
    let name2 = name ? name.trim() : null;
    let nickname2 = nickname ? nickname.trim() : null;
    let no2 = no ? no.trim() : null;

    console.log(name, nickname, no);
    if (name === null && nickname === null && no === null) {
      console.log("test");
      getAllEmployeeComOem();
    }
    // Filter User array based on provided arguments
    let filEmp = User.filter((item) => {
      return (
        (name2 == null ||
          (item.emp_name_th
            ? item.emp_name_th.toLowerCase().includes(name2.toLowerCase())
            : false)) &&
        (nickname2 == null ||
          (item.nickname !== null
            ? item.nickname.toLowerCase().includes(nickname2.toLowerCase())
            : false)) &&
        (no2 == null ||
          (item.emp_no
            ? item.emp_no.toLowerCase().includes(no2.toLowerCase())
            : false))
      );
    });

    // Return filtered result
    console.log(filEmp);
    setUserFilter(filEmp);
    // return filEmp;
  }

  function save() {
    const error_list = [];
    if (SelectUser.length === 0) {
      let temp_err = {
        message: "กรุณาเลือกพนักงาน",
      };
      error_list.push(temp_err);
    }
    if (GiveThankPoint.tph_thank_point_id === "") {
      let temp_err = {
        message: "กรุณาเลือกแต้มน้ำใจ",
      };
      error_list.push(temp_err);
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      Swal.fire({
        title: "ยืนยันการบันทึกข้อมูล",
        text: "ต้องการบันทึกข้อมูลหรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            tph_thank_point_id: GiveThankPoint.tph_thank_point_id || null,
            tph_thank_point_name: GiveThankPoint.tph_thank_point_name || null,
            tph_point: GiveThankPoint.tph_point || null,
            tph_remark: GiveThankPoint.tph_remark.trim() || null,
            tph_emp_id: SelectUser,
            fup: getUser().fup,
          };
          axios({
            method: "post",
            url:
              Configs.API_URL_incentive + "/api/thankPoint/SaveGiveThankPoint",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: data,
          })
            .then(function (response) {
              Swal.fire({
                title: "มอบแต้มน้ำใจสำเร็จ",
                iconHtml: `<img src=${saveMoney} style="width: 100px;" />`,
                customClass: {
                  icon: "no-border",
                },
                timer: 2000,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Error!",
                text: error.response.data.error[0].errorDis,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        }
      });
    }
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-6">
                <h1>แต้มน้ำใจ</h1>
              </div>
              <div className="col-12 col-md-8 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Incentive Point</li>
                  <li className="breadcrumb-item active">เเต้มน้ำใจ</li>
                </ol>
              </div>
            </div>
            {/*  Home / Human Resource/ Incentive Point/ เเต้มน้ำใจ */}
            <div className="row">
              <PointCartLine />
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-12 col-md-12 col-xl-12">
                <div
                  className="bodyThankPoint"
                  style={{ padding: "10px 10px 5px 20px" }}
                >
                  <h3 className="">การมอบเเต้มน้ำใจ</h3>
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: "20px 0px" }}>
              <div className="col-12 col-md-12 col-xl-12">
                <div className="bodyThankPoint2">
                  <div className="row">
                    <div
                      className="col-3 col-md-2 col-xl-2 center-left"
                      style={{ padding: "0px" }}
                    >
                      <h5>
                        <b>ขั้นตอนที่ 1 :</b>
                      </h5>
                    </div>
                    <div className="col-9 col-md-10 col-xl-10">
                      <h5>
                        ผู้ใช้งานสามารถมอบแต้มน้ำใจให้กับผู้ใช้งานท่านอื่นได้
                        โดยเลือกรายชื่อผู้ใช้งานที่ต้องการมอบคะแนนให้
                        ที่ด้านล่าง (สามารถเลือกได้มากกว่า 1 คน)
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div
                  className="bodyThankPoint"
                  style={{ paddingBottom: "10px" }}
                >
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="emp_name"
                          name="emp_name"
                          required={true}
                          value={filterEmp.emp_name}
                          onChange={(e) => {
                            setfilterEmp({
                              ...filterEmp,
                              emp_name: e.target.value,
                            });
                            filterEmp.emp_name = e.target.value;
                            filterEmployeeFront(
                              e.target.value,
                              filterEmp.emp_nick_name,
                              filterEmp.emp_no
                            );
                          }}
                        />
                        <label htmlFor="">
                          ค้นหารายชื่อผู้ใช้งาน (ชื่อจริง - นามสกุล)
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="emp_nick_name"
                          name="emp_nick_name"
                          required={true}
                          value={filterEmp.emp_nick_name}
                          onChange={(e) => {
                            setfilterEmp({
                              ...filterEmp,
                              emp_nick_name: e.target.value,
                            });
                            filterEmp.emp_nick_name = e.target.value;
                            filterEmployeeFront(
                              filterEmp.emp_name,
                              e.target.value,
                              filterEmp.emp_no
                            );
                          }}
                        />
                        <label htmlFor="">
                          ค้นหารายชื่อผู้ใช้งาน (ชื่อเล่น)
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="emp_no"
                          name="emp_no"
                          required={true}
                          value={filterEmp.emp_no}
                          onChange={(e) => {
                            setfilterEmp({
                              ...filterEmp,
                              emp_no: e.target.value,
                            });
                            filterEmp.emp_no = e.target.value;
                            filterEmployeeFront(
                              filterEmp.emp_name,
                              filterEmp.emp_nick_name,
                              e.target.value
                            );
                          }}
                        />
                        <label htmlFor="">รหัสพนักงาน</label>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row mb-3 customScroll-1"
                    style={{
                      height: "260px",
                      width: "auto",
                      overflowY: "scroll",
                    }}
                  >
                    {UserFilter.map((item, index) => {
                      // console.log(item.emp_name_th.length);
                      return (
                        <div
                          className="col-12 col-md-4 col-xl-3"
                          key={item.id}
                          onClick={() => {
                            let checkSelect = SelectUser.filter((item2) => {
                              return item2.id === item.id;
                            });
                            if (checkSelect.length === 0) {
                              let data = SelectUser;
                              item.check = true;
                              // console.log(data);
                              setSelectUser([]);
                              data.push(item);
                              // console.log(data);
                              setSelectUser(data);
                              settest({ ...test, test: "" });
                              test.test = "";
                            } else {
                              let data = SelectUser;
                              item.check = false;
                              setSelectUser([]);
                              let checkIndex = data.findIndex((item2) => {
                                return item2.id === item.id;
                              });
                              // console.log(checkIndex);
                              data.splice(checkIndex, 1);
                              setSelectUser(data);
                              settest({ ...test, test: "" });
                              test.test = "";
                            }
                          }}
                        >
                          <UserComponent detail={item} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ margin: "20px 0px" }}>
              <div className="col-12 col-md-12 col-xl-12">
                <div className="bodyThankPoint2">
                  <div className="row">
                    <div
                      className="col-3 col-md-2 col-xl-2 center-left"
                      style={{ padding: "0px" }}
                    >
                      <h5>
                        <b>ขั้นตอนที่ 2 :</b>
                      </h5>
                    </div>
                    <div className="col-9 col-md-9 col-xl-9">
                      <h5>
                        ผู้ใช้งานสามารถมอบแต้มน้ำใจ
                        โดยเลือกแต้มน้ำใจที่ต้องการมอบ
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="bodyThankPoint">
                  <div className="row">
                    <div className="col-12 col-md-2 col-xl-2 center-left">
                      <h5>
                        มอบแต้มน้ำใจให้
                        {SelectUser.length == 0 ? (
                          <labal style={{ color: "red" }}>*</labal>
                        ) : (
                          ""
                        )}{" "}
                        :
                      </h5>
                    </div>
                    <div
                      className="col-12 col-md-9 col-xl-9"
                      style={{ maxHeight: "190px" }}
                    >
                      <div
                        className="row mb-3 customScroll-1"
                        style={{
                          height: "100%",
                          width: "auto",
                          overflowY: "scroll",
                        }}
                      >
                        {SelectUser.map((item, index) => {
                          return (
                            <div
                              className="col-12 col-md-6 col-xl-6"
                              key={item.productName}
                            >
                              <UserSelectComponent
                                detail={item}
                                deSelect={() => deSelect(item)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-md-2 col-xl-2 center-left">
                      <h5>
                        แต้มน้ำใจ
                        {GiveThankPoint.tph_thank_point_id === "" ? (
                          <labal style={{ color: "red" }}>*</labal>
                        ) : (
                          ""
                        )}
                        :
                      </h5>
                    </div>
                    <div className="col-12 col-md-5 col-xl-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <select
                          className="form-control"
                          id="thank_point"
                          name="thank_point"
                          // ref={selectRef}
                          // required={true}
                          value={GiveThankPoint.tph_thank_point_id}
                          onChange={(e) => {
                            GiveTP(e.target.value);
                          }}
                          /* onClick={() => {
                                                    // OnclickDropdown()
                                                    console.log("click2");
                                                }} */
                        >
                          <option value="">---เลือกแต้มน้ำใจ---</option>
                          {thank_point.map((item, index) => {
                            return (
                              <option key={index} value={item.tp_id}>
                                {item.tp_point_name}
                                {/* {" "}({item.tp_point} แต้ม) */}
                              </option>
                            );
                          })}
                        </select>
                        <div
                          style={{
                            position: "absolute",
                            top: "15px",
                            right: "30px",
                          }}
                          /* onClick={() => {
                                                    OnclickDropdown();
                                                }} */
                        >
                          {GiveThankPoint.tph_thank_point_id !== "" ? (
                            <labal
                              style={{
                                color: "#0395FF",
                                fontWeight: "700",
                                fontSize: "20px",
                              }}
                            >
                              {GiveThankPoint.tph_point} คะแนน
                            </labal>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-2 col-xl-2 center-left">
                      <h5>ข้อความที่ส่งถึง :</h5>
                    </div>
                    <div className="col-12 col-md-9 col-xl-9">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          rows="5"
                          value={GiveThankPoint.tph_remark}
                          onChange={(e) => {
                            setGiveThankPoint({
                              ...GiveThankPoint,
                              tph_remark: e.target.value,
                            });
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div
                className="col-6 col-md-6 col-xl-6"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <button
                  className="btn btn-primary button"
                  style={{
                    background: "#F0F9FF",
                    border: "0.5px solid #003583",
                    borderRadius: "10px",
                    color: "#003583",
                  }}
                  onClick={() => {
                    clear();
                  }}
                >
                  ล้างข้อมูล
                </button>
              </div>
              <div
                className="col-6 col-md-6 col-xl-6"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <button
                  className="btn btn-success button"
                  style={{
                    background: "#003583",
                    border: "0.5px solid #003583",
                    borderRadius: "10px",
                    color: "#FFFFFF",
                  }}
                  onClick={() => {
                    save();
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default IncentiveUserThankPoint;
