import { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import './Datepicker.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

const Datepicker = ({ format, date }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleSelectedDateChange = dataDate => {
        if (dataDate === null) {
            setSelectedDate(null);
            date(null);
        } else {
            setSelectedDate(dataDate);

            let day = String(dataDate.getDate()).padStart(2, '0');
            let monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];
            let month = monthNames[dataDate.getMonth()];
            let year = dataDate.getFullYear();

            date(day + ' ' + month + ' ' + year);
        }
    }

    useEffect(() => {
        handleSelectedDateChange(selectedDate);
    }, []);



    return (
        <div>
            <Calendar 
                value={selectedDate} 
                onChange={(e) => handleSelectedDateChange(e.value)}
                // showButtonBar
                showIcon
                view={format === "day" ? "date" : format}
                dateFormat={format === "day" ? 
                    "dd MM yy"
                    : format === "month"
                    ? "MM yy"
                    : format === "year"
                    ? "yy"
                    : "dd MM yy"} // Set the desired date format
            />
        </div>
    )
}

export default Datepicker;
