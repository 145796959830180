import React, { useState, useRef } from "react";
import { Button as ButtonAnt, Upload, Modal, Progress, Select, InputNumber, Input } from "antd";
import { UploadOutlined, CameraOutlined, EditOutlined, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import styles from './CountingFeature.module.css'; // Import CSS module
import Swal from "sweetalert2";
import { Option } from "antd/es/mentions";

function CountingFeature() {
  const { TextArea } = Input;

  // กำหนดสีพื้นฐานที่ใช้ใน UI
  const colors = {
    primary: "#004080",
    secondary: "#333333",
    lightBackground: "#f8f9fa",
    textLight: "#ffffff",
    headerText: "#000000",
    border: "#4A90E2",
  };

  // สร้าง state สำหรับเก็บค่าของคลาสที่เลือก
  const [selectedClass, setSelectedClass] = useState("hexagonal_bar"); // ค่าเริ่มต้น
  const [outputData, setOutputData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // สำหรับการเปิด/ปิดโหมดแก้ไข JSON
  const [editedJson, setEditedJson] = useState(""); // เก็บ JSON ที่แก้ไข
  const [editedValues, setEditedValues] = useState({});
  const videoRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isClickAtCamera, setIsClickAtCamera] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); // เพิ่มสถานะการประมวลผล


  // ฟังก์ชันสำหรับเปลี่ยนค่าคลาสที่เลือก
  const handleClassChange = (value) => {
    setSelectedClass(value);
  };

  const getClassDisplayName = (selectedClass) => {
    switch (selectedClass) {
      case "hexagonal_bar":
        return "Hexagonals";
      case "hollowround_bar":
        return "Hollow Round Bars";
      case "round_bar":
        return "Round Bars";
      case "square_bar":
        return "Square Bars";
      default:
        return selectedClass;
    }
  };

  const getClassBackendName = (selectedClass) => {
    switch (selectedClass) {
      case "Hexagonals":
        return "hexagonal_bar";
      case "Hollow Round Bars":
        return "hollowround_bar";
      case "Round Bars":
        return "round_bar";
      case "Square Bars":
        return "square_bar";
      default:
        return selectedClass;
    }
  };

  // ฟังก์ชันสำหรับจัดการการอัปโหลดไฟล์
  const handleFileUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("selected_class", selectedClass); // เพิ่มค่า selected_class ลงใน FormData

    setShowProgress(true);
    setProgress(10);
    setIsProcessing(true);

    try {
      // ส่งค่า selected_class ไปใน request body แทนที่จะใช้ params
      const response = await axios.post("http://150.95.25.8:8080/detect-and-count", data, {
        headers: {
          "X-API-KEY": "TTT-Counting",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          setProgress(Math.round((loaded / total) * 100));
        }
      });

      if (response.data.message === "NoDetection") {
        const classDisplayName = getClassDisplayName(selectedClass);
        Swal.fire({
          icon: 'warning',
          title: 'No Detection',
          text: `ไม่พบเหล็กประเภท ${classDisplayName} ในภาพ กรุณาลองใหม่อีกครั้ง`,
          confirmButtonText: 'ตกลง',
        }).then(() => {
          window.location.reload(); // รีเฟรชหน้าเว็บเพจใหม่
        });
      } else if (response.data) {
        setOutputData(response.data.detected_classes_count);
        setEditedJson(JSON.stringify(response.data.detected_classes_count, null, 2));
        setImageUrl(`data:image/jpeg;base64,${response.data.annotated_image}`);
      }
      setShowProgress(false);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'เกิดข้อผิดพลาดในการประมวลผลไฟล์หรือการอัพโหลดไฟล์ กรุณาลองใหม่อีกครั้ง',
        confirmButtonText: 'ตกลง',
      });
      setShowProgress(false);
      setProgress(0);
    } finally {
      setIsProcessing(false);
    }
  };



  // ฟังก์ชันสำหรับจัดการความสำเร็จในการอัปโหลดไฟล์
  const handleUploadSuccess = async (file) => {
    setFileList(file.fileList);
    if (file.file && isClickAtCamera) {
      await handleFileUpload(file.file);
      setIsClickAtCamera(false);
    }
  };

  // ฟังก์ชันสำหรับเปิดกล้องเพื่อถ่ายภาพ
  const handleTakePhoto = () => {
    setIsCameraOpen(true);
    navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
      if (permissionStatus.state === 'granted') {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
            }
          })
          .catch((err) => {
            console.error("Error accessing camera: ", err);
            Swal.fire({
              icon: 'error',
              title: 'Camera Error',
              text: `ไม่สามารถเข้าถึงกล้องได้: ${err.message}`,
              confirmButtonText: 'ตกลง',
            });
            setIsCameraOpen(false);
          });
      } else if (permissionStatus.state === 'prompt') {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
            }
          })
          .catch((err) => {
            console.error("Error accessing camera: ", err);
            Swal.fire({
              icon: 'error',
              title: 'Camera Error',
              text: `ไม่สามารถเข้าถึงกล้องได้: ${err.message}`,
              confirmButtonText: 'ตกลง',
            });
            setIsCameraOpen(false);
          });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Camera Permission Denied',
          text: 'การเข้าถึงกล้องถูกปฏิเสธ กรุณาอนุญาตให้เข้าถึงกล้องผ่านการตั้งค่าของเบราว์เซอร์',
          confirmButtonText: 'ตกลง',
        });
        setIsCameraOpen(false);
      }
    }).catch((err) => {
      console.error("Permission error: ", err);
      Swal.fire({
        icon: 'error',
        title: 'Permission Error',
        text: `ไม่สามารถตรวจสอบสิทธิ์กล้องได้: ${err.message}`,
        confirmButtonText: 'ตกลง',
      });
      setIsCameraOpen(false);
    });
  };

  // ฟังก์ชันสำหรับปิดกล้อง
  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCameraOpen(false);
  };


  // ฟังก์ชันสำหรับจับภาพจากกล้องและอัปโหลดทันที
  const captureImage = () => {
    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const newFile = new File([blob], `captured-${Date.now()}.jpeg`, { type: "image/jpeg" });

      setFileList([...fileList, { uid: newFile.name, name: newFile.name, status: "done", url: URL.createObjectURL(blob) }]);
      handleFileUpload(newFile);
      setIsCameraOpen(false);
      stopCamera();
    }, "image/jpeg");
  };

  // ฟังก์ชันสำหรับเปิด/ปิดโหมดการแก้ไข JSON
  const toggleEditJSON = () => {
    setIsEditing(!isEditing);
  };

  const handleValueChange = (key, value) => {
    if (typeof value === 'number') {
      setEditedValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleKeyPress = (event) => {
    const key = event.key;
    if (!/[0-9]/.test(key) && !['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(key)) {
      event.preventDefault();
    }
  };

  // ฟังก์ชันสำหรับบันทึก JSON ที่แก้ไข
  const saveEditedJSON = () => {
    try {
      // รวม JSON ที่มี key-value ตามที่แก้ไข
      const updatedOutput = { ...outputData, ...editedValues };
      setOutputData(updatedOutput);
      setIsEditing(false); // ออกจากโหมดแก้ไข
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'บันทึกการแก้ไข JSON เรียบร้อยแล้ว',
        confirmButtonText: 'ตกลง',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'รูปแบบ JSON ไม่ถูกต้อง กรุณาตรวจสอบและแก้ไขใหม่อีกครั้ง',
        confirmButtonText: 'ตกลง',
      });
    }
  };

  // ฟังก์ชันสำหรับลบข้อมูลใน state ทั้งหมด
  const handleClear = () => {
    setFileList([]);
    setOutputData(null);
    setImageUrl(null);
    setEditedJson("");
    setProgress(0);
    Swal.fire({
      icon: 'success',
      title: 'Cleared',
      text: 'ข้อมูลทั้งหมดถูกลบเรียบร้อย!',
      confirmButtonText: 'OK',
    });
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-sm-6">
                <h1 className={styles.headerTitle}>Counting Feature</h1>
              </div>
              <div className="col">
                <ol className={`breadcrumb float-sm-right ${styles.breadcrumbStyle}`}>
                  <li className="breadcrumb-item"><a href="#" style={{ textDecoration: "none", color: colors.primary }}>Home</a></li>
                  <li className="breadcrumb-item active">AI Feature</li>
                  <li className="breadcrumb-item active">Counting Feature</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="p-3">
          <div className={styles.card}>
            <div className={styles.cardHeader}>Counting Feature</div>
            <div className="card-body">
              <p className="text-muted">
                ฟีเจอร์นี้ใช้โมเดลการนับที่พัฒนาจาก AI ซึ่งสามารถตรวจจับและนับเหล็กประเภทต่าง ๆ ได้โดยอัตโนมัติ ทั้งเหล็กทรงสี่เหลี่ยม เหล็กทรงกลม และเหล็กหกเหลี่ยม ช่วยเพิ่มประสิทธิภาพในการคัดแยกและตรวจสอบได้อย่างแม่นยำ
              </p>
            </div>
          </div>

          {/* ส่วนเลือก Class ที่จะตรวจจับ */}
          <div className={styles.card}>
            <div className={styles.cardHeader}>Select Class</div>
            <div className="card-body">
              {/* แก้ไขค่า value ให้สอดคล้องกับ backend */}
              <Select
                value={selectedClass}
                style={{ width: 200 }}
                onChange={handleClassChange}
                disabled={isProcessing}
              >
                <Option value="hexagonal_bar">Hexagonals</Option>
                <Option value="hollowround_bar">Hollow Round Bars</Option>
                <Option value="round_bar">Round Bars</Option>
                <Option value="square_bar">Square Bars</Option>
              </Select>
            </div>
          </div>

          {/* ส่วนอัปโหลดไฟล์ */}
          <div className={styles.card}>
            <div className={styles.cardHeader}>Upload Files</div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div style={{ display: "flex", alignItems: "flex-start", gap: "10px", flexWrap: "wrap" }}>
                    {/* Container to keep the buttons fixed */}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                      <ButtonAnt
                        icon={<CameraOutlined />}
                        onClick={handleTakePhoto}
                        className={styles.cameraButton}
                        style={{ backgroundColor: "#3579F626", borderColor: "#1976D2", color: "#3579F6", minWidth: "200px", marginTop: "10px" }}
                        disabled={isProcessing}
                      >
                        Take Photo
                      </ButtonAnt>

                      <Upload
                        listType="picture"
                        accept=".jpg,.png,.jpeg"
                        fileList={fileList} // ใช้ fileList ที่เก็บไว้ใน state เพื่อลบไฟล์ที่แสดงเมื่อกด Clear
                        beforeUpload={(file) => {
                          handleFileUpload(file);
                          return false; // หยุดการอัพโหลดเริ่มต้นของ antd
                        }}
                        onChange={handleUploadSuccess}
                        showUploadList={{ showRemoveIcon: true }}
                        disabled={isProcessing}
                      >
                        <ButtonAnt
                          icon={<UploadOutlined />}
                          style={{
                            backgroundColor: "#3579F626",
                            borderColor: "#1976D2",
                            color: "#3579F6",
                            minWidth: "200px",
                            marginTop: "10px",
                          }}
                          className={styles.uploadButton}
                          disabled={isProcessing}
                        >
                          Choose files
                        </ButtonAnt>
                      </Upload>

                      {/* ปุ่ม Clear */}
                      <ButtonAnt
                        onClick={handleClear} // ปุ่ม Clear ที่เพิ่งเพิ่ม
                        style={{
                          backgroundColor: "#ff4d4f26",
                          borderColor: "#ff4d4f",
                          color: "#ff4d4f",
                          minWidth: "200px",
                          marginTop: "10px",
                        }}
                        disabled={isProcessing}
                      >
                        Clear
                      </ButtonAnt>
                      {/* Progress Bar Section */}
                      {showProgress && (
                        <div style={{ marginBottom: "0px", width: "100%" }}> {/* ใช้ width: 100% เพื่อให้ responsive */}
                          <h4>Processing Progress</h4>
                          <div style={{ padding: "0 15px", width: "100%" }}> {/* เพิ่ม container รอบๆ progress bar */}
                            <Progress percent={progress} status="active" style={{ width: "100%" }} /> {/* เปลี่ยน width เป็น 100% */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Output Section */}
          <div className={styles.card}>
            <div className={styles.cardHeader}>Output Result</div>
            <div className="card-body">
              {imageUrl && <img src={imageUrl} alt="Captured File" className={styles.uploadedImage} />}
              {outputData ? (
                <div>
                  {/* JSON Editor Section */}
                  {isEditing ? (
                    <div>
                      {Object.entries(outputData).map(([key, value]) => (
                        <div key={key} style={{ marginBottom: "10px" }}>
                          <label style={{ fontWeight: "bold" }}>{getClassDisplayName(key)}: </label>
                          <InputNumber
                            value={editedValues[key] || value}
                            onChange={(value) => handleValueChange(key, value)}
                            onKeyDown={handleKeyPress}
                            style={{ width: "100px", marginLeft: "10px" }}
                            min={0}
                            step={1}
                          />
                        </div>
                      ))}
                      <ButtonAnt icon={<SaveOutlined />} onClick={saveEditedJSON} style={{ marginRight: "10px" }}>
                        Save JSON
                      </ButtonAnt>
                    </div>
                  ) : (
                    <pre className={styles.outputResult}>{JSON.stringify(outputData, null, 2)}</pre>
                  )}
                  <div style={{ marginTop: "10px" }}>
                    {!isEditing && (
                      <ButtonAnt
                        icon={<EditOutlined />}
                        onClick={() => setIsEditing(true)}
                        style={{ marginRight: "10px" }}>
                        Edit JSON
                      </ButtonAnt>
                    )}
                  </div>
                </div>
              ) : (
                <p>No output data available. Please upload a file to get the result.</p>
              )}
            </div>
          </div>
        </section>

        {/* Modal สำหรับกล้องถ่ายภาพ */}
        <Modal
          title="Camera"
          visible={isCameraOpen}
          onCancel={() => setIsCameraOpen(false)}
          footer={[
            <ButtonAnt key="capture" type="primary" onClick={captureImage}>
              Capture Photo
            </ButtonAnt>,
            <ButtonAnt key="stop" onClick={stopCamera}>
              Stop Camera
            </ButtonAnt>,
            <ButtonAnt key="cancel" onClick={() => setIsCameraOpen(false)}>
              Cancel
            </ButtonAnt>,
          ]}
        >
          <video ref={videoRef} autoPlay style={{ width: "100%" }} />
        </Modal>
      </div>
    </div>
  );
}

export default CountingFeature;
