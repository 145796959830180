import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import axios from "axios";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Sidebar from "../../component/Sidebar";
let date = new Date();
let dateformatYear = date.getFullYear();
let dateformatMonth = date.getMonth()+1;
function Mat_Approve(params) {
  const [materail, setmaterail] = useState([]);
  const [mat_amount, setmat_amount] = useState([]);
  const [testtotal, settesttotal] = useState(0)

  const [fillter, setfillter] = useState({
    no: "",
    name: "",
    year: dateformatYear,
    month: dateformatMonth,
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    material_supplier_id: "",
    oem_id: getOem(),
  });

  const [loadsug, setLoadsug] = useState({
    year: "",
    month: "",
    oem_id: getOem(),
  });

  const [supplier, setSupplier] = useState([]);
  const [type, setType] = useState([]);
  const [size, setSize] = useState([]);
  const [spect, setSpect] = useState([]);

  

  useEffect(() => {

    axios(config_sub)
      .then(function (response) {
        setSupplier(response.data);
        
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_type)
      .then(function (response) {
        setType(response.data);
      
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_spec)
      .then(function (response) {
        setSpect(response.data);
        
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_size)
      .then(function (response) {
        setSize(response.data);
        
      })
      .catch(function (error) {
        console.log(error);
      });

   /*  axios(config_loadsug)
      .then(function (response) {
        setmaterail(response.data);
      })
      .catch(function (error) {
        console.log(error);
      }); */

    /* Gettable(); */
  }, []);

  var config_type = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialType?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  var config_spec = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSpec?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_size = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSize?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_loadsug = {
    method: "get",
    url: Configs.API_URL + "/materialPlaning/FilterOrderForcast",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const savedata = (e, index) => {
    
    
    /* axios({
      method: "post",
      url: Configs.API_URL + "materialPlaning/updateMaterialForcast",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: mat_amount,
    })
      .then(async (response) => {
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 2000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            window.location.href = "/Warehouse_Management/WIP_IN_OUT";
          });
        });
        console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      }); */
  };

  function Load_sug (){
    if (fillter.year !=="" && fillter.month !="") {
      const settogetdata ={
        year: fillter.year,
        month: fillter.month,
        oem_id: getOem(),
      }
      Gettable(settogetdata);
      
    }else{
      Swal.fire("Warning", "Please Select Year and Month or Suggression", "warning");
    }
  }
  const Gettable = async (getfillter) => {

    await axios({
      method: "post",
      url: Configs.API_URL + "/materialPlaning/FilterOrderForcast",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: getfillter,
    })
      .then(async (response) => {

        const temp_data = [];
        for (let index = 0; index < response.data.length; index++) {
          const element = {};
          
          element["buy_price"]= response.data[index].buy_price;
          element["material_size"]= response.data[index].material_size;
          element["material_spec"]= response.data[index].material_spec;
          element["material_supplier"]= response.data[index].material_supplier;
          element["material_supplier_id"]= response.data[index].material_supplier_id;
          element["material_type"]= response.data[index].material_type;
          element["material_unit"]= response.data[index].material_unit;
          element["name"]= response.data[index].name;
          element["no"]= response.data[index].no;
          element["oem_id"]= response.data[index].oem_id;
          element["product_amount"]= response.data[index].product_amount;
          element["product_forcast_id"]= response.data[index].product_forcast_id;
          element["product_material_id"]= response.data[index].product_material_id;
          element["year_month"]= response.data[index].year_month;
          element["in_hand_amount"]= response.data[index].in_hand_amount;
          element["nmo"]= response.data[index].product_amount;
          element["total"]= response.data[index].buy_price * response.data[index].product_amount;

          temp_data.push(element);
        }
        
        let sorted = temp_data.sort((a, b) => b.nmo -  a.nmo);
      
        setmaterail(sorted);
       /*  console.log(sorted); */

      })
      .catch(async (error) => {
        console.log(error);
      });
  };

 function search_mat(){
    Gettable(fillter);
   /* console.log(materail.length); */
 }

 function clear_fillter(){
  setfillter({...fillter,
    no: "",
    name: "",
   /*  year: "",
    month: "", */
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    material_supplier_id: "",
    oem_id: getOem(),
  });
  fillter.no ="";
  fillter.name ="";
 /*  fillter.year ="";
  fillter.month =""; */
  fillter.material_type_id ="";
  fillter.material_spec_id ="";
  fillter.material_size_id ="";
  fillter.material_supplier_id ="";
  fillter.oem_id = getOem();
  /* setmaterail([]); */
  Load_sug ()
 }
 function approve_product() {
  /* console.log(approve_list.length); */
  if (fillter.year !== "" && fillter.month !== "" /* && approve_list.length!== 0 */) {
    
    
    const tempdataForupdate = []
    for (let index = 0; index < materail.length; index++) {
      const element = {};
        element["product_material_id"]=materail[index].product_material_id
        element[ "product_forcast_id"]=materail[index].product_forcast_id
        element["amount"]=materail[index].nmo
        element ["product_amount"]=materail[index].product_amount
        tempdataForupdate.push(element);
    }
    console.log(tempdataForupdate);


    
    axios({
      method: 'post',
      url:Configs.API_URL+ '/materialPlaning/updateMaterialForcast',
      headers: { 
        'Authorization': getToken(),
        'Content-Type': 'application/json', 
        'X-TTT': Configs.API_TTT
      },
      data : tempdataForupdate
    })
    .then(function (response) {
      console.log(response.data);

      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 2000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {

        Swal.fire({
          icon: "success",
          title: "Save",
          showConfirmButton: false,
          timer: 1500,
        })/* .then((result) => {
          window.location.href =
            "/Material_Requirement_Planning/Approve_Material_item";
        }); */

      });
    })
    .catch(function (error) {
      console.log(error);
    });

     
  } else {
    Swal.fire("Warning", "Please Select Year and Month or Suggression", "warning");
  }
}
const reData = ()=>{


}
const rowsData = [];
for (var index = 0; index < materail.length; index++) {
  const rowItem = {};
  rowItem["no"] = index + 1;
  rowItem["mat_code"] =materail[index].no;
  rowItem["mat_name"] =materail[index].name;
  rowItem["mat_type"] =materail[index].material_type;
  rowItem["unit"] =materail[index].material_unit;
  rowItem["spec"] =materail[index].material_spec;
  rowItem["size"] =materail[index].material_size;
  rowItem["in"] = materail[index].in_hand_amount;
  rowItem["buy"] = materail[index].buy_price;
  rowItem["nms"] =materail[index].product_amount;

  rowItem["npq"] =(
    <NumberFormat
      className="form-control"
      value={materail[index].nmo}
      required
      name={index}
      thousandSeparator={true}
      onChange={approve_update.bind(this)}
    />
  )
  rowItem["total"] =materail[index].total ;
  rowItem["matid"] = materail[index].product_material_id;    
  rowItem["matof"] = materail[index].product_forcast_id;  
  rowItem["pro"] = materail[index].product_amount;
  rowItem["supplier"] = materail[index].material_supplier;

  rowsData.push(rowItem);
}


 const data = {
   columns: [
     {
       label: "NO.",
       field: "no",
       sort: "asc",
       width: 50,
     },
     {
       label: "Mat_Code",
       field: "mat_code",
       sort: "asc",
       width: 50,
     },
     {
      label: "Mat_Name",
      field: "mat_name",
      sort: "asc",
      width: 50,
    },
     {
       label: "Mat_Type",
       field: "mat_type",
       sort: "asc",
       width: 50,
     },
     {
       label: "Unit_by",
       field: "unit",
       sort: "asc",
       width: 50,
     },
     {
       label: "Mat_Spec",
       field: "spec",
       sort: "asc",
       width: 50,
     },
     {
       label: "Mat_size",
       field: "size",
       sort: "asc",
       width: 50,
     },
     {
       label: "In_Hand",
       field: "in",
       sort: "asc",
       width: 50,
     },
     {
       label: "Buy_Price",
       field: "buy",
       sort: "asc",
       width: 50,
     },
     {
      label: "Product_Amount",
      field: "pro",
      sort: "asc",
      width: 50,
    },
     {
       label: "Next_Month_Suggestion",
       field: "nms",
       sort: "asc",
       width: 50,
     },
     {
       label: "Next_Order",
       field: "npq",
       sort: "asc",
       width: 50,
     },
     {
       label: "Total_Price",
       field: "total",
       sort: "asc",
       width: 50,
     },
   ],
   rows: rowsData,
 };


 function approve_update (e) {
  /*  console.log(e.target.value); */

  var tepm_mat = materail;

   tepm_mat[e.target.name].nmo = Number(e.target.value.replace(/,/g, ""));
   tepm_mat[e.target.name].total = tepm_mat[e.target.name].buy_price * Number(e.target.value.replace(/,/g, ""));
 
rowsData[e.target.name].nmo= Number(e.target.value.replace(/,/g, ""));
rowsData[e.target.name].total  = tepm_mat[e.target.name].buy_price * Number(e.target.value.replace(/,/g, ""));

   let sorted = tepm_mat.sort((a, b) => b.in_hand_amount-  a.in_hand_amount);
      
   setmaterail(sorted);
   /* rowsData[e.target.name].total = materail[e.target.name].buy_price * Number(e.target.value.replace(/,/g, "")); */
 /*   setmaterail(tepm_mat); */
   /* console.log(materail);  */
 }
function load(){
  Swal.fire({
    title: "Saving",
    allowEscapeKey: false,
    allowOutsideClick: false,
    timer: materail.length*40,
    onOpen: () => {
      Swal.showLoading();
    },
  })
}
 

  return (

    <div className="wrapper">
    <Header />
    <Sidebar menu="mrp" activemenu="ami" submenu="none" />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Approve Material Item</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Approve Material Item
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.year}
                    onChange={(e)=>{setfillter({...fillter,year:e.target.value})}}
                  >
                    <option value="">Select Year</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                  <label htmlFor="">Year</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.month}
                    onChange={(e)=>{setfillter({...fillter,month:e.target.value})}}
                  >
                    <option value="">Select Month</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label htmlFor="">Month</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary"
                  onClick={Load_sug}>
                    Load Suggestion
                  </button>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <a  onClick={load}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="table-to-xls_all"
                    filename={"test"+ " " + Date()}
                    sheet="tablexls"
                    buttonText="Download as XLS"
                   
                  />
                  </a>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary "
                  onClick={approve_product}>
                    Save To Database
                  </button>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  {/* <button
                    type="button"
                    className="btn btn-block btn-info  "
                  
                  >
                    Search
                  </button> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={fillter.no}
                    onChange={(e) => {
                      setfillter({ ...fillter, no: e.target.value });
                    }}
                  />
                  <label htmlFor="">Materail No</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={fillter.name}
                    onChange={(e) => {
                      setfillter({ ...fillter, name: e.target.value });
                    }}
                  />
                  <label htmlFor="">Materail Name</label>
                </div>
              </div>

              <div className="col-1">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_type_id}
                    onChange={(e) => {
                      setfillter({
                        ...fillter,
                        material_type_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Type</option>
                    {type.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Type</label>
                </div>
              </div>

              <div className="col-1">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_spec_id}
                    onChange={(e) => {
                      setfillter({
                        ...fillter,
                        material_spec_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select Spec{" "}
                    </option>
                    {spect.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Spec</label>
                </div>
              </div>
              <div className="col-1">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_size_id}
                    onChange={(e) => {
                      setfillter({
                        ...fillter,
                        material_size_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select Size{" "}
                    </option>
                    {size.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Size</label>
                </div>
              </div>
              <div className="col-1">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_supplier_id}
                    onChange={(e) => {
                      setfillter({
                        ...fillter,
                        material_supplier_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select Supplier{" "}
                    </option>
                    {supplier.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Supp</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                  onClick={search_mat}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                  onClick={clear_fillter}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*  <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary ">
                    Browse Forcast file
                  </button>
                  <a href="#" style={{ fontSize: "13px" }}>
                    {" "}
                    download template.xls
                  </a>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary">
                    Load Form Service
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary ">
                    Save To Database
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary ">
                    Save To Database
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Approve Material Item</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-head-fixed"
                  table
                  id="table-to-xls"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              <div style = {{display:"none"}}>
                <MDBDataTable
                  className="table table-head-fixed"
                  table
                  id="table-to-xls_all"
                  entries={4096}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
                </div>

              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
      </div>
      </div>
  );
}
export default Mat_Approve;
