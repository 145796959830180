/* import Construccomponent from "../../component/Construction"; */
import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Footter from "../../component/Footter";
import sortJsonArray from  'sort-json-array';
import Header from "../../component/Header";
import { logo_kubota } from "../../routes/imgRoute/imgUrl";
import Sidebar from "../../component/Sidebar";
/* import Xlsx from "../../assets/temp/tempOrderForcast.xlsx"; */
import axios from "axios";
import Configs from "../../config";
import Swal from "sweetalert2";
import { getOem, getToken } from "../../Utils/Common";
import FormData from "form-data";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Product_forcast_excel from "../../assets/file/upload_OrderForcast.xlsx";
import BTN from "../../component/btn_feature";
let date = new Date();
let dateformatYear = date.getFullYear();
let dateformatMonth = date.getMonth()+1;
function Uploadorderforecast(params) {
  const [orderForcast, setOrderForcast] = useState([]);
  const [filler_Order, setfiller_Order] = useState({
    oem_id: getOem(),
    year: dateformatYear,
    month: dateformatMonth,
    save: false,
  });
  const inputFile = useRef(null);
  const inputFile2 = useRef(null);
  const [message, setmessage] = useState({
    message: "",
  });
  const [upload, setupload] = useState([]);
  const [upload_temp, setupload_temp] = useState();
  const [namefile, setNamefile] = useState({
    upload_stat: true,
  });

  /* const Gettable = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/productPlaning/FilterOrderForcast",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_Order,
    })
      .then(async (response) => {
        await setfiller_Order(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }; */

  const rowsData = [];
  for (var index = 0; index < orderForcast.length; index++) {
    const rowItem = {};

    if (orderForcast[index].n1 == null) {
      rowItem["n1"] = "null";
    } else {
      rowItem["n1"] = orderForcast[index].n1;
    }
    if (orderForcast[index].n2 == null) {
      rowItem["n2"] = "null";
    } else {
      rowItem["n2"] = orderForcast[index].n2;
    }
    if (orderForcast[index].n3 == null) {
      rowItem["n3"] = "null";
    } else {
      rowItem["n3"] = orderForcast[index].n3;
    }
    if (orderForcast[index].n4 == null) {
      rowItem["n4"] = "null";
    } else {
      rowItem["n4"] = orderForcast[index].n4;
    }

    rowItem["Item"] = index + 1;
    rowItem["Part_No"] = orderForcast[index].no;
    rowItem["Part_Name"] = orderForcast[index].name;
    rowItem["WIP"] = orderForcast[index].wip;
    rowItem["FG"] = orderForcast[index].fg;
    rowItem["In_Hand"] = orderForcast[index].in_hand;
    rowItem["N"] = "N" + index;
    rowItem["Delivery"] = orderForcast[index].delivery;
    rowItem["Pending"] = orderForcast[index].pending;
    rowItem["N1"] = <div style={{ color: "tomato" }}>{rowItem["n1"]}</div>;
    rowItem["N2"] = <div style={{ color: "tomato" }}>{rowItem["n2"]}</div>;
    rowItem["N3"] = <div style={{ color: "tomato" }}>{rowItem["n3"]}</div>;
    rowItem["N4"] = <div style={{ color: "tomato" }}>{rowItem["n4"]}</div>;
    /*  rowItem["wt_pc"] = index;
    rowItem["mat_kg"] = index;
    rowItem["cos_pc"] = index;
    rowItem["mat_pupp"] = index; 
    rowItem["remark"] = index;
    */

    rowsData.push(rowItem);
  }
  /*   console.log(namefile.file);
  console.log(filler_Order);
 */
  function Uploadfile_kubota(e){
    if (filler_Order.year !=="" && filler_Order.month !=="") {
      console.log("yes1");
      if (
        e.target.files[0] !== "" &&
        e.target.files[0] !== null &&
        e.target.files[0] !== undefined
      ) {
        setupload_temp(e.target.files[0]);
        
        const data = new FormData();

        data.append("file", e.target.files[0]);
        data.append("oem_id", filler_Order.oem_id);
        data.append("year", filler_Order.year);
        data.append("month", filler_Order.month);
      

        const config_upload = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importReadOrderForcastByKTC",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };
/* console.log(config_upload); */
console.log("yes2_ktc");
        axios(config_upload)
          .then(function (response) {
            console.log(response.data);
            console.log("yes3_ktc");
            let test =  sortJsonArray(response.data,'no','asc');
           

            setupload(test);

            let num = 0;
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].status === false) {
                num++;
              }
            }

            if (num !== 0) {
              setNamefile({ ...namefile, upload_stat: true });
            } else {
              setfiller_Order({ ...filler_Order, save: true });
              setNamefile({ ...namefile, upload_stat: false });
            }
          })
          .catch(function (error) {

            Swal.fire({
              title: "Error File Xlsx",
              text: "File Xlsx can't null "+error.message,
              icon: "warning",
              
              confirmButtonColor: "#3085d6",
              
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/Production_Planing/Upload_Order_Forecast";
              }
              
         }); 
           
           
          });
      }
    }else{
      Swal.fire("Please select year and month.", "Year and cannot blank", "warning");
    }
  }
  function Uploadfile(e) {
    /* console.log(filler_Order.year !=="" && filler_Order.month !=="")  */
    if (filler_Order.year !=="" && filler_Order.month !=="") {
      console.log("yes1");
      if (
        e.target.files[0] !== "" &&
        e.target.files[0] !== null &&
        e.target.files[0] !== undefined
      ) {
        setupload_temp(e.target.files[0]);
      /*   console.log(e.target.files[0]);
        console.log(filler_Order); */
        const data = new FormData();

        data.append("file", e.target.files[0]);
        data.append("oem_id", filler_Order.oem_id);
        data.append("year", filler_Order.year);
        data.append("month", filler_Order.month);  
        /* data.append("save", filler_Order.save); */

        const config_upload = {
          method: "post",
          url: Configs.API_URL + "/productPlaning/importReadOrderForcast",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };
console.log("yes2");
        axios(config_upload)
          .then(function (response) {
            console.log(response.data);
            console.log("yes3");
            let test =  sortJsonArray(response.data,'no','asc');
           

            setupload(test);

            let num = 0;
            for (let index = 0; index < response.data.length; index++) {
              if (response.data[index].status === false) {
                num++;
              }
            }

            if (num !== 0) {
              setNamefile({ ...namefile, upload_stat: true });
            } else {
              setfiller_Order({ ...filler_Order, save: true });
              setNamefile({ ...namefile, upload_stat: false });
            }
          })
          .catch(function (error) {

            Swal.fire({
              title: "Error File Xlsx",
              text: "File Xlsx can't null "+error.message,
              icon: "warning",
              /* showCancelButton: true, */
              confirmButtonColor: "#3085d6",
              /* cancelButtonColor: "#d33", */
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/Production_Planing/Upload_Order_Forecast";
              }
              
         });
           
           
          });
      }
    }else{
      Swal.fire("Please select year and month.", "Year and cannot blank", "warning");
    }
  }

  function Upload_file_toBase() {
    if (
      upload_temp !== null &&
      upload_temp !== "" &&
      upload_temp !== undefined
    ) {
      console.log(upload_temp);
      console.log(filler_Order);
      const data = new FormData();

      data.append("file", upload_temp);
      data.append("oem_id", filler_Order.oem_id);
      data.append("year", filler_Order.year);
      data.append("month", filler_Order.month);
      data.append("save", filler_Order.save);

      const config_upload = {
        method: "post",
        url: Configs.API_URL + "/productPlaning/importReadOrderForcast",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config_upload)
        .then(function (response) {
          Swal.fire(
            "Upload Success",
            "Your file has been Upload.",
            "success"
          ).then(() => {
            window.location.href = "/Production_Planing/Upload_Order_Forecast";
          });
        })
        .catch(function (error) {
          console.log(error.message);
          Swal.fire("Error File Xlsx", "File Xlsx can't null", "warning");
        });
    }
  }
 function clearData(){
  window.location.href = "/Production_Planing/Upload_Order_Forecast";
 }
  useEffect(() => {
    /*     Gettable(); */
  }, []);

  /* async function selectmonth(e) {
    await setfiller_Order({ ...filler_Order, month: e.target.value });
    filler_Order.month = e.target.value;
   
  }
  async function selectyear(e) {
    await setfiller_Order({ ...filler_Order, year: e.target.value });
    filler_Order.year = e.target.value;
   
  } */

  function showmessage() {
    /*   Swal.fire({
      icon: "info",
      title: "Waiting for connect service API from K-Planet",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    }); */

    Swal.fire({
      title: "Comming Soon",
      text: "Waiting for connect service API from K-Planet",
      imageUrl: logo_kubota.imgs,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
    });
  }

  const onButtonClick = () => {
    inputFile.current.click();
  };


const onButtonClick2 = () => {
    inputFile2.current.click();
  /*  Swal.fire("Waiting for KUBOTA", "waiting", "Info"); */
  };

  

  let open = "xxxx";
  const rowsUpload = [];

  for (var indexin = 0; indexin < upload.length; indexin++) {
    const rowUp = {};

    rowUp["num"] = indexin + 1;
    if(upload[indexin].error == "not found"){
      rowUp["no"] =<span style={{color:"red"}}>{upload[indexin].no}</span>;
    }else{
      rowUp["no"] = upload[indexin].no;
    }
    rowUp["name"] = upload[indexin].name;
    rowUp["wip"] = upload[indexin].wip;
    rowUp["fg"] = upload[indexin].fg;
    rowUp["in_hand"] = upload[indexin].in_hand;
    rowUp["delivery"] = upload[indexin].delivery;
    rowUp["pending"] = upload[indexin].pending;
    rowUp["amount"] = upload[indexin].amount_forcast;
    if(upload[indexin].n1 == "fill in here too!!"){
      rowUp["N1"] = <span style={{color:"red"}}>{upload[indexin].n1}</span>;
    }else{
      rowUp["N1"] = upload[indexin].n1;
    }
    if(upload[indexin].n2 == "fill in here too!!"){
      rowUp["N2"] = <span style={{color:"red"}}>{upload[indexin].n2}</span>;
    }else{
      rowUp["N2"] = upload[indexin].n2;
    }
    if(upload[indexin].n3 == "fill in here too!!"){
      rowUp["N3"] = <span style={{color:"red"}}>{upload[indexin].n3}</span>;
    }else{
      rowUp["N3"] = upload[indexin].n3;
    }
    if(upload[indexin].n4 == "fill in here too!!"){
      rowUp["N4"] = <span style={{color:"red"}}>{upload[indexin].n4}</span>;
    }else{
      rowUp["N4"] = upload[indexin].n4;
    }
    rowUp["status"] = upload[indexin].status ? <span style={{color:"green"}}> true</span> : <span style={{color:"red"}}> false</span>;
    rowUp["error"] = <span style={{color:"red"}}>{upload[indexin].error}</span>;
    rowsUpload.push(rowUp);
  }

  const data = {
    columns: [
      {
        label: "Item",
        field: "num",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "WIP",
        field: "wip",
        sort: "asc",
        width: 50,
      },
      {
        label: "FG",
        field: "fg",
        sort: "asc",
        width: 50,
      },
      {
        label: "In_Hand",
        field: "in_hand",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Delivery",
        field: "delivery",
        sort: "asc",
        width: 50,
      },
      {
        label: "Pending",
        field: "pending",
        sort: "asc",
        width: 50,
      },
      {
        label: "N1",
        field: "N1",
        sort: "asc",
        width: 50,
      },
      {
        label: "N2",
        field: "N2",
        sort: "asc",
        width: 50,
      },
      {
        label: "N3",
        field: "N3",
        sort: "asc",
        width: 50,
      },
      {
        label: "N4",
        field: "N4",
        sort: "asc",
        width: 50,
      },  
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      }, 
      {
        label: "Error Message",
        field: "error",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsUpload,
  };

  return (
    <div className="wrapper">
      <Header />
      <Sidebar menu="pp" activemenu="uof" submenu="none" />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Upload Order Forecast</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Upload Order Forecast
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_Order.year}
                    onChange={(e) =>
                      setfiller_Order({ ...filler_Order, year: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                  <label htmlFor="">Year</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_Order.month}
                    onChange={(e) =>
                      setfiller_Order({
                        ...filler_Order,
                        month: e.target.value,
                      })
                    }
                  >
                    <option value="Sletect">Sletect</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label htmlFor="">Month</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
            {/*   <div className="col-2">
                <div className="form-group ">
                  <button
                    className="btn btn-block btn-primary"
                    onClick={onButtonClick}
                  >
                    Browse Forecast file
                  </button>
                  <input
                    type="file"
                    onChange={Uploadfile}
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                  />
                </div>
              </div> */}
              <BTN onClick = {onButtonClick} name = {"Browse Forecast file"} />
              <input
                    type="file"
                    onChange={Uploadfile}
                    id="file"
                    ref={inputFile}
                    style={{ display: "none" }}
                  />
          
             <BTN onClick = {onButtonClick2} name = {"Browse KUBOTA Forecast file"} />
              <input
                    type="file"
                    onChange={Uploadfile_kubota}
                    id="file"
                    ref={inputFile2}
                    style={{ display: "none" }}
                  />
             
             <BTN onClick = {showmessage} name = {"Load Form Service"} />
              {/* <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={showmessage}
                  >
                    Load Form Service
                  </button>
                </div>
              </div> */}
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className={"btn btn-block btn-primary "}
                    disabled={namefile.upload_stat}
                    onClick={Upload_file_toBase}
                  >
                    Save To Database
                  </button>
                </div>
              </div>
         
               {/*  <a 
                    href={Product_forcast_excel}
                    download="upload_Product_forcast.xlsx"
                    //style={{ fontSize: "13px" }} 
                  > */}
                     <BTN href = {Product_forcast_excel} download ={"upload_Product_forcast.xlsx"} name = {"download template.xls"} />
                    {/*  <button
                    className="btn btn-block btn-primary"
                     >
                       download template.xls
                     </button> */}
                  {/*  </a>    */}
                  <input
                    type="file"
                    onChange={Uploadfile_kubota}
                    id="file"
                    ref={inputFile2}
                    style={{ display: "none" }}
                  />
          
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className={"btn btn-block btn-primary "}
                    
                    onClick={clearData}
                  >
                    Clear for new upload
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Upload Order Forecast</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>

      <Footter />
    </div>
  );
}
export default Uploadorderforecast;
/* <div className="content-wrapper">

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Upload Order Forecast</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                  Upload Order Forecast
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                <select className="custom-select select2 form-control">
                    <option value="">Seclect Year</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <label htmlFor="">Year</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">

                  <select className="custom-select select2 form-control">
                    <option value="">Seclect Month</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label htmlFor="">Month</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary ">
                    Browse Forecast file
                  </button>
                  <a href="#" style={{ fontSize: "13px" }}>
                    {" "}
                    download template.xls
                  </a>
                </div>
                </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"

                  >
                    Load Form Service
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary ">
                    Save To Database
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Upload Order Forecast</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" style={{ height: "500px" }}>

              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>

      </div> */
