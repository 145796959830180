import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import Configs from "../../../config";
import axios from "axios";
import Swal from "sweetalert2";

function Config_AIOI_Table() {
  const [searchData, setSearchData] = useState({
    text: "",
  });

  const [configData, setConfigData] = useState({
    data: [],
  });

  async function GoToPage(mode) {
    if (mode == "Add") {
      window.location.href = "/IoT_Master_Data/Config_AIOI_Add";
    } else if (mode == "Edit") {
      window.location.href = `/IoT_Master_Data/Config_AIOI_Edit/${""}`;
    } else if (mode == "View") {
      window.location.href = `/IoT_Master_Data/Config_AIOI_View/${""}`;
    }
  }

  async function FilterConfigAIOI() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      searchData: searchData,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/config_aioi/filterConfigAIOIData`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        const temp_main = response_data.data.data;

        setConfigData({
          ...configData,
          data: temp_main,
        });
        configData.data = temp_main;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function ClearSearch() {
    setSearchData({
      text: "",
    });
    searchData.text = "";

    await FilterConfigAIOI();
  }

  async function DeleteConfig(ca_id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "กำลังลบข้อมูล",
          text: "Loading",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const send_data = {
          user_id: getUser().fup,
          company_id: getUser().com,
          oem_id: getOem(),
          ca_id: ca_id,
        };

        await axios({
          method: "POST",
          url: `${Configs.API_URL_AIOI_Back}/api/config_aioi/deleteConfigAIOIData`,
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: send_data,
        })
          .then(async (response_data) => {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(async () => {
              window.location.reload();
            });
          })
          .catch(async (error) => {
            console.log(error);
          });
      }
    });
  }

  function SetConfigTableData(index) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["controller_name"] = configData.data[index].ca_con_name;

    rowItem["controller_address"] = configData.data[index].ca_con_address;

    rowItem["controller_port"] = configData.data[index].ca_con_port;

    rowItem["connect_address"] = configData.data[index].ca_connect_address;

    rowItem["warehouse"] = configData.data[index].wh_name;

    rowItem["remark"] = configData.data[index].ca_remark;

    rowItem["management"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap", margin: "0 auto" }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={`view_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              window.location.href = `/IoT_Master_Data/Config_AIOI_View/${configData.data[index].ca_id}`;
            }}
          >
            <i class="fas fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            key={`edit_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              window.location.href = `/IoT_Master_Data/Config_AIOI_Edit/${configData.data[index].ca_id}`;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={`delete_${index}`}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={async () => {
              await DeleteConfig(configData.data[index].ca_id);
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    return rowItem;
  }

  const ConfigTableData = [];
  for (let index = 0; index < configData.data.length; index += 1) {
    ConfigTableData.push(SetConfigTableData(index));
  }

  const ConfigTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Controller Name",
        field: "controller_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Controller Address",
        field: "controller_address",
        sort: "asc",
        width: 50,
      },
      {
        label: "Controller Port",
        field: "controller_port",
        sort: "asc",
        width: 50,
      },
      {
        label: "Connect Address",
        field: "connect_address",
        sort: "asc",
        width: 50,
      },
      {
        label: "Warehouse",
        field: "warehouse",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        sort: "asc",
        width: 50,
      },
    ],

    rows: ConfigTableData,
  };

  useEffect(async () => {
    await FilterConfigAIOI();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-sm-4 col-xl-6">
                <h1>Config AIOI</h1>
              </div>
              <div className="col-12 col-sm-8 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">IoT Master Data</li>
                  <li className="breadcrumb-item active">Config AIOI</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-xl-3">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={searchData.text}
                    onChange={(e) => {
                      setSearchData({
                        ...searchData,
                        text: e.target.value,
                      });
                    }}
                  />
                  <label>Search</label>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-1">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await FilterConfigAIOI();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-1">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await ClearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    id="btn_add"
                    className="btn btn-block btn-primary"
                    type="button"
                    onClick={async () => {
                      await GoToPage("Add");
                    }}
                  >
                    Add Config
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    {/* <h4>Config AIOI</h4> */}
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div className="table-responsive">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={true}
                            data={ConfigTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Config_AIOI_Table;
