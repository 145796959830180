import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

import Supplier_Cute_Product from "./Component/Supplier_Cute_Product";

function AEV_Supplier_Cuteplus({ mode }) {
  const validThai = new RegExp(`[\u0E00-\u0E7F]`);
  const validEng = new RegExp(`[a-zA-Z]`);

  const { id } = useParams();

  const [pageMode, setPageMode] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);

  const [inputData, setInputData] = useState({
    Supplier_Id: "",
    Supplier_Code: "",
    Supplier_Name: "",
    Supplier_Full_Name: "",
    Supplier_Type: "",
    Supplier_Detail: "",
    Supplier_Address: "",
    Official_Email: "",
    Official_Line: "",
    Contact_Fax: "",
    Contact_Name_1: "",
    Contact_Email_1: "",
    Contact_Tel_1: "",
    Contact_Name_2: "",
    Contact_Email_2: "",
    Contact_Tel_2: "",
    Payment_Term: "",
    Term_Type: "",
    Term: "",
    Condition_Term: "",
    Remark: "",
    oem_id: getOem(),
    company_id: getUser().com,
    user_id: getUser().fup,
  });

  const [inputDataTab2, setInputDataTab2] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });

  const [functionSaveTab2, setFunctionSaveTab2] = useState({
    data: null,
  });

  const [supplierTypeData, setSupplierTypeData] = useState({
    data: [],
  });

  const [paymentTermData, setPaymentTermData] = useState({
    data: [],
  });

  const [conditionTermData, setConditionTermData] = useState({
    data: [],
  });

  async function GetSupplierType() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getSupplierType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setSupplierTypeData({
          ...supplierTypeData,
          data: response.data,
        });

        supplierTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetPaymentTerm() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getPaymentTerm",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setPaymentTermData({
          ...paymentTermData,
          data: response.data,
        });

        paymentTermData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const [CountryDD, setCountryDD] = useState({
    data: [],
  });

  async function getCountryDD() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getCountryDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCountryDD({
          ...CountryDD,
          data: response.data,
        });

        CountryDD.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetConditionTerm() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getConditionTerm",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setConditionTermData({
          ...conditionTermData,
          data: response.data,
        });

        conditionTermData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetSupplierData() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      Supplier_Id: id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getSupplierByID",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setInputData({
          ...inputData,
          Supplier_Id: response.data[0].sup_id,
          Supplier_Code: response.data[0].sup_code,
          Supplier_Name: response.data[0].sup_name,
          Supplier_Full_Name: response.data[0].sup_full_name,
          Supplier_Type: response.data[0].sup_st_id,
          Supplier_Country: response.data[0].sup_country_id === null ? '' : response.data[0].sup_country_id,
          Supplier_Detail: response.data[0].sup_detail,
          Supplier_Address: response.data[0].sup_address,
          Official_Email: response.data[0].sup_official_email,
          Official_Line: response.data[0].sup_official_line,
          Contact_Fax: response.data[0].sup_fax,
          Contact_Name_1: response.data[0].sup_contract_name,
          Contact_Email_1: response.data[0].sup_contract_email,
          Contact_Tel_1: response.data[0].sup_contract_tel,
          Contact_Name_2: response.data[0].sup_contract_name2,
          Contact_Email_2: response.data[0].sup_contract_email2,
          Contact_Tel_2: response.data[0].sup_contract_tel2,
          Payment_Term: response.data[0].sup_pt_id,
          Term_Type: response.data[0].sup_term_type,
          Term: response.data[0].sup_term,
          Condition_Term: response.data[0].sup_ct_id,
          Remark: response.data[0].sup_remark,
        });

        // setInputDataTab2({
        //   ...inputDataTab2,
        //   data: response.data.data2,
        // });
        // inputDataTab2.data = response.data.data2;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];

        if (
          inputData.Supplier_Code === null ||
          inputData.Supplier_Code?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Supplier Code",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Supplier_Name === null ||
          inputData.Supplier_Name?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Supplier Name",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Supplier_Type === null ||
          inputData.Supplier_Type?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือก Supplier Type",
          };
          error_list.push(temp_err);
        }
        console.log(inputData.Supplier_Type)
        if (
          inputData.Supplier_Type === '8f577987-c883-463f-8256-a4b19523e812' &&
          (inputData.Supplier_Country === null ||
          inputData.Supplier_Country?.trim() === "")
        ) {
          let temp_err = {
            message: "กรุณาเลือก Supplier Country",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Supplier_Address === null ||
          inputData.Supplier_Address?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Supplier Address",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Contact_Name_1 === null ||
          inputData.Contact_Name_1?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Contact Name 1",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Contact_Email_1 === null ||
          inputData.Contact_Email_1?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Contact E-mail 1",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Contact_Tel_1 === null ||
          inputData.Contact_Tel_1?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก Contact Tel 1",
          };
          error_list.push(temp_err);
        } /* else if (inputData.Contact_Tel_1.replace(/[^\d.]/g, "").length < 9) {
          let temp_err = {
            message: "กรุณากรอก Contact Tel 1 ให้ครบ 9 หรือ 10 หมายเลข",
          };
          error_list.push(temp_err);
        } */

        if (
          inputData.Payment_Term === null ||
          inputData.Payment_Term?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือก Payment Term",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Term_Type === null ||
          inputData.Term_Type?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือก Term Type",
          };
          error_list.push(temp_err);
        }

        if (inputData.Term === null || inputData.Term?.trim() === "") {
          let temp_err = {
            message: "กรุณากรอก Term",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.Condition_Term === null ||
          inputData.Condition_Term?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือก Condition Term",
          };
          error_list.push(temp_err);
        }

        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);

        if (error_list.length > 0) {
          let temp_err = {
            message: `- Tab Supplier Add -`,
          };
          error_list.unshift(temp_err);

          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          let axiosData = {};

          const temp = {
            tab1: {
              Supplier_Id: inputData.Supplier_Id,
              Supplier_Code: inputData.Supplier_Code,
              Supplier_Name: inputData.Supplier_Name,
              Supplier_Full_Name: inputData.Supplier_Full_Name,
              Supplier_Type: inputData.Supplier_Type,
              Supplier_Country: inputData.Supplier_Country === '' ? null : inputData.Supplier_Country,
              Supplier_Detail: inputData.Supplier_Detail,
              Supplier_Address: inputData.Supplier_Address,
              Official_Email: inputData.Official_Email,
              Official_Line: inputData.Official_Line,
              Contact_Fax: inputData.Contact_Fax,
              Contact_Name_1: inputData.Contact_Name_1,
              Contact_Email_1: inputData.Contact_Email_1,
              Contact_Tel_1: inputData.Contact_Tel_1,
              Contact_Name_2: inputData.Contact_Name_2,
              Contact_Email_2: inputData.Contact_Email_2,
              Contact_Tel_2: inputData.Contact_Tel_2,
              Payment_Term: inputData.Payment_Term,
              Term_Type: inputData.Term_Type,
              Term: inputData.Term,
              Condition_Term: inputData.Condition_Term,
              Remark: inputData.Remark,
            },
            tab2: inputDataTab2,
            user_id: getUser().fup,
            user_name: `${getUser().name} ${getUser().surname}`,
            company_id: getUser().com,
            oem_id: getOem(),
          };

          if (mode === "add") {
            axiosData = {
              method: "post",
              url: Configs.API_URL_cuteplus + "/api/supplier/addSupplier",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };
          }

          if (mode === "edit") {
            axiosData = {
              method: "patch",
              url: Configs.API_URL_cuteplus + "/api/supplier/updateSupplier",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };
          }

          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(() => {
            axios(axiosData)
              .then(function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus";
        }
      });
    } else {
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data_Cuteplus/Supplier_Cuteplus";
    }
  }

  useEffect(async () => {
    await GetSupplierType();
    await GetPaymentTerm();
    await GetConditionTerm();
    await getCountryDD();

    if (mode === "add") {
      setPageMode("Add");
      setIsDisabled(false);
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
      await GetSupplierData();
    } else if (mode === "view") {
      setPageMode("Read");
      setIsDisabled(true);
      await GetSupplierData();
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Supplier</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">
                    Supplier {pageMode}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={async () => {
                      console.log(inputData);
                      await saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Supplier Code : {inputData.Supplier_Code}
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Supplier Name : {inputData.Supplier_Name}
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* <div className="card">
                  <div className="card-header">
                    <h4 style={{ margin: "0px" }}>Supplier {pageMode}</h4>
                  </div> */}

                <div className="card card-primary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id={"tab-1"}
                          data-toggle="pill"
                          href={"#Supplier_Add-tab"}
                          role="tab"
                          aria-controls={"Supplier_Add-tab"}
                          aria-selected="true"
                        >
                          Supplier Add
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"tab-2"}
                          data-toggle="pill"
                          href={"#Supplier_Product-tab"}
                          role="tab"
                          aria-controls={"Supplier_Product-tab"}
                          aria-selected="false"
                        >
                          Supplier Product
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-four-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id={"Supplier_Add-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-1"}
                      >
                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Supplier Detail
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Supplier_Code}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Supplier_Code: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Supplier Code
                                {inputData.Supplier_Code === "" ||
                                inputData.Supplier_Code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Supplier_Name}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Supplier_Name: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Supplier Name
                                {inputData.Supplier_Name === "" ||
                                inputData.Supplier_Name === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Supplier_Full_Name}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Supplier_Full_Name: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Supplier Full Name
                                {/* {inputData.task === "" ||
                            inputData.task === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Supplier_Type}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Supplier_Type: e.target.value,
                                    Supplier_Country: e.target.value === "8f577987-c883-463f-8256-a4b19523e812" ? inputData.Supplier_Country : "",
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  ---- เลือก ----
                                </option>
                                {supplierTypeData.data.map((e) => {
                                  return (
                                    <option value={e.st_id}>{e.st_name}</option>
                                  );
                                })}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Supplier Type
                                {inputData.Supplier_Type === "" ||
                                inputData.Supplier_Type === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <select
                                type="text" 
                                className="form-control"
                                required
                                disabled={isDisabled ? true : inputData.Supplier_Type === "8f577987-c883-463f-8256-a4b19523e812" ? false : true}
                                value={inputData.Supplier_Country}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Supplier_Country: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  ---- เลือก ----
                                </option>
                                {CountryDD.data.map((e) => {
                                  return (
                                    <option value={e.country_id}>{e.country_name}</option>
                                  );
                                })}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Country
                                {inputData.Supplier_Type === "8f577987-c883-463f-8256-a4b19523e812" && inputData.Supplier_Country === "" ||
                                inputData.Supplier_Country === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div class="form-group">
                              <textarea
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Supplier_Detail}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Supplier_Detail: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Supplier Detail
                                {/* {inputData.Supplier_Detail === "" ||
                            inputData.Supplier_Detail === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div class="form-group">
                              <textarea
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Supplier_Address}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Supplier_Address: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Supplier Address
                                {inputData.Supplier_Address === "" ||
                                inputData.Supplier_Address === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-4">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Official_Email}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Official_Email: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Official E-mail
                                {/* {inputData.Official_Email === "" ||
                            inputData.Official_Email === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-4">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Official_Line}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Official_Line: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Official Line
                                {/* {inputData.Official_Line === "" ||
                            inputData.Official_Line === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-4">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                required
                                decimalScale={0}
                                allowLeadingZeros={true}
                                allowNegative={false}
                                disabled={isDisabled}
                                value={inputData.Contact_Fax}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Contact_Fax: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact Fax
                                {/* {inputData.Contact_Fax === "" ||
                            inputData.Contact_Fax === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Contact Point
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Contact_Name_1}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Contact_Name_1: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact Name 1
                                {inputData.Contact_Name_1 === "" ||
                                inputData.Contact_Name_1 === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Contact_Email_1}
                                onChange={(e) => {
                                  if (
                                    !validThai.test(
                                      e.target.value.toString().trim()
                                    )
                                  ) {
                                    setInputData({
                                      ...inputData,
                                      Contact_Email_1: e.target.value,
                                    });
                                  }
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact E-mail 1
                                {inputData.Contact_Email_1 === "" ||
                                inputData.Contact_Email_1 === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                // format={"###-###-####"}
                                disabled={isDisabled}
                                value={inputData.Contact_Tel_1}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Contact_Tel_1: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact Tel 1
                                {inputData.Contact_Tel_1 === "" ||
                                inputData.Contact_Tel_1 === null
                                ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Contact_Name_2}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Contact_Name_2: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact Name 2
                                {/* {inputData.Contact_Name_2 === "" ||
                            inputData.Contact_Name_2 === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Contact_Email_2}
                                onChange={(e) => {
                                  if (
                                    !validThai.test(
                                      e.target.value.toString().trim()
                                    )
                                  ) {
                                    setInputData({
                                      ...inputData,
                                      Contact_Email_2: e.target.value,
                                    });
                                  }
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact E-mail 2
                                {/* {inputData.Contact_Email_2 === "" ||
                            inputData.Contact_Email_2 === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                // format={"###-###-####"}
                                disabled={isDisabled}
                                value={inputData.Contact_Tel_2}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Contact_Tel_2: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact Tel 2
                                {/* {inputData.Contact_Tel_2 === "" ||
                            inputData.Contact_Tel_2 === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Payment Term
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-5">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Payment_Term}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Payment_Term: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  ---- เลือก ----
                                </option>
                                {paymentTermData.data.map((e) => {
                                  return (
                                    <option value={e.pt_id}>{e.pt_name}</option>
                                  );
                                })}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Payment Term
                                {inputData.Payment_Term === "" ||
                                inputData.Payment_Term === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Term_Type}
                                onChange={(e) => {
                                  let maxTerm = inputData.Term || "";

                                  if (
                                    e.target.value === "วัน" &&
                                    maxTerm !== ""
                                  ) {
                                    maxTerm = Math.floor(maxTerm);
                                  } else if (
                                    e.target.value === "เปอร์เซ็นต์" &&
                                    maxTerm > 100
                                  ) {
                                    maxTerm = 100;
                                  }

                                  setInputData({
                                    ...inputData,
                                    Term: maxTerm,
                                    Term_Type: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  ---- เลือก ----
                                </option>
                                <option value="วัน">Day (วัน)</option>
                                <option value="เปอร์เซ็นต์">
                                  % (เปอร์เซ็นต์)
                                </option>
                                {/* {paymentTermData.data.map((e) => {
                              return (
                                <option value={[e.pt_id, e.pt_type]}>
                                  {e.pt_name}
                                </option>
                              );
                            })} */}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Term Type
                                {inputData.Term_Type === "" ||
                                inputData.Term_Type === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-4">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                required
                                decimalScale={
                                  inputData.Term_Type === "วัน" ? 0 : 2
                                }
                                allowNegative={false}
                                isAllowed={(values) => {
                                  const { formattedValue, floatValue } = values;
                                  return inputData.Term_Type === "วัน"
                                    ? values
                                    : formattedValue === "" ||
                                        floatValue <= 100;
                                }}
                                disabled={
                                  isDisabled || inputData.Term_Type === 0
                                }
                                value={inputData.Term}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Term: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Term (จำนวน)
                                {/* {inputData.Term_Type !== "" ? (
                                  inputData.Term_Type === "วัน" ? (
                                    <span> ( วัน )</span>
                                  ) : (
                                    <span> ( % )</span>
                                  )
                                ) : (
                                  ""
                                )} */}
                                {inputData.Term === "" ||
                                inputData.Term === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Condition Term
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-5">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Condition_Term}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Condition_Term: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  ---- เลือก ----
                                </option>
                                {conditionTermData.data.map((e) => {
                                  return (
                                    <option value={e.ct_id}>{e.ct_name}</option>
                                  );
                                })}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Condition Term
                                {inputData.Condition_Term === "" ||
                                inputData.Condition_Term === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div class="form-group">
                              <textarea
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.Remark}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    Remark: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Remark
                                {/* {inputData.task === "" ||
                            inputData.task === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"Supplier_Product-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-2"}
                      >
                        <Supplier_Cute_Product
                          id={id}
                          mode={mode}
                          isDisabled={isDisabled}
                          inputDataTab2={inputDataTab2}
                          setInputDataTab2={setInputDataTab2}
                          errorListTab2={errorListTab2}
                          setErrorListTab2={setErrorListTab2}
                          functionSaveTab2={functionSaveTab2}
                          setFunctionSaveTab2={setFunctionSaveTab2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AEV_Supplier_Cuteplus;
