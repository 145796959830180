import React from "react";

function UserSelectComponent({ detail, deSelect }) {
  // console.log("detail", detail);
  return (
    <>
      <div
        style={{
          boxSizing: "border-box",
          background: "#FFFFFF",
          border: "0.5px solid #003583",
          borderRadius: "50px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "flex-start",
          }}
        >
          <label
            style={{
              color: "#000000",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "20px",
              padding: "0px 20px",
            }}
          >
            {(detail.emp_name_th !== null ? detail.emp_name_th : "-") +
              " " +
              "(" +
              (detail.nickname !== null ? detail.nickname : "-") +
              ")" +
              " " +
              (detail.emp_no !== null ? detail.emp_no : "-")}
          </label>
        </div>
        <a
          onClick={() => {
            deSelect(detail);
          }}
        >
          <div
            style={{
              display: "inline-flex",
              position: "absolute",
              right: "20px",
              fontSize: "20px",
            }}
          >
            <label
              style={{
                color: "#000000",
              }}
            >
              x
            </label>
          </div>
        </a>
      </div>
    </>
  );
}

export default UserSelectComponent;
