import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";

function Order_Summary_Report(params) {
  const [OSL, setOSL] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  const [filler_OSL, setFiller_OSL] = useState({
    osl_no: "",
    is_draft:false,
    oem_id: getOem(),
  });
  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */



  const GetOSL = async () => {
    

    axios({
      method: "post",
      url: Configs.API_URL + "/orderSummary/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_OSL
    })
      .then(function (response) {
        console.log(response.data);
        setOSL(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetOSL();

  }, []);

  const clearFilter = async () => {
    await setFiller_OSL({
      ...filler_OSL,
      osl_no:"",
    });
   
    filler_OSL.osl_no = "";
    GetOSL();
   
  };

  function addpage(params) {
    window.location.href =
      "/Production_Planing/Order_Summary_Report/add";
  }

  /* function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/project/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Project";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  } */
  const rowsData = [];
 
  for (var index = 0; index < OSL.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["osl_no"] = OSL[index].oslno;
    rowItem["po_ref"] = OSL[index].refpo;
    rowItem["customer"] = OSL[index].customer;
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
             href={"/Production_Planing/Order_Summary_Report/read/"+ OSL[index].id}
            key={OSL[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
              href={"/Production_Planing/Order_Summary_Report/edit/"+ OSL[index].id}
             key={OSL[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      {/*   <div className="col-4">
          <a
             key={OSL[index].id}
            className=" btn btn-xs "
            // onClick={deldata.bind(this,OSL[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div> */}
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "OSL No",
        field: "osl_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "PO ref",
        field: "po_ref",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
     
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="pp" activemenu="osr" submenu="none" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Order Summary Report{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Production Planning
                  </li>
                  <li className="breadcrumb-item active">Order Summary Report</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_OSL.osl_no.trim()}
                    onChange={(e) => {
                      setFiller_OSL({
                        ...filler_OSL,
                        osl_no: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">OSL No</label>{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetOSL();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </section>


        <section className="content">

        <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-osl-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-osl"
                    role="tab"
                    aria-controls="custom-tabs-four-osl"
                    aria-selected="false"
                    onClick={()=>{setFiller_OSL({...filler_OSL,is_draft:false});filler_OSL.is_draft=false;  GetOSL();}}
                  >
                    OSL
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-four-osl_draft-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-osl_draft"
                    role="tab"
                    aria-controls="custom-tabs-four-osl_draft"
                    aria-selected="false"
                    onClick={()=>{setFiller_OSL({...filler_OSL,is_draft:true});filler_OSL.is_draft=true;   GetOSL();}}
                  >
                    OSL Draft
                  </a>
                </li>
               
              </ul>
            </div>

            <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-osl"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-osl-tab"
                >
         {/*    <div className="card-header">
            
              <h3 className="card-title">Factory Work Hours</h3>
              <div className="card-tools"></div>
            </div> */}

            <div className="card-body">

            <div className="container-fluid">
            <h2>Order Summary Report</h2>
            <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
          </div>

        

                        
            </div>
            
          </div>



          <div
                  className="tab-pane fade"
                  id="custom-tabs-four-osl_draft"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-osl_draft-tab"
                >
                   <div className="card-body">

<div className="container-fluid">
<h2>Order Summary Report Draft</h2>
<div
  className="card-body table-responsive " /*  style={{ height: "100%" }} */
>
  <MDBDataTable
  sortable = {false}
    className="table table-head-fixed"
    striped
    bordered
    hover
    fixedHeader
    data={data}
  />
</div>
</div>



            
</div>
        
            
          </div>


          </div>
            </div>






       
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
  {/*    <Footter/> */}
     </div>
     
  );
}

export default Order_Summary_Report;
