import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature, getComConfig, getAcademy } from "../../Utils/Common";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import {
    Do_CTS_Report,
    daily_delivery_report,
    daily_sale_report,
    daily_travel_report,
    month_travel_report,
    Repair_Cost_Report,
    monthly_fuel_consumption_summary,
    Wire_mace_STP,

} from "../../component/Report";
/* import { Chart } from 'react-charts'; */
import Chart from "react-google-charts";
import html2canvas from "html2canvas";

function Report_PDF_CTS(params) {
    const [company, setcompany] = useState();
    const [companyGroup, setCompanyGroup] = useState([]);
    const selectCompanyGroup = useRef();
    const [all_OEM, setall_OEM] = useState([]);
    const [Oem, setOem] = useState([]);
    const [logistic_status, setLogistic_status] = useState([]);


    const [report_data, setreport_data] = useState([]);

    const [filler_report_do, setfiller_report_do] = useState({
        start_date: new Date(),
        end_date: new Date(),
        oem_id: getOem(),
        company_id: getUser().com,

    });

    async function SetDate_start(date) {
        setfiller_report_do({
            ...filler_report_do, start_date: date
        });
        filler_report_do.start_date = date;
    }
    async function SetDate_end(date) {
        setfiller_report_do({
            ...filler_report_do, end_date: date
        });
        filler_report_do.end_date = date;
    }

    function clear_fillter() {
        setfiller_report_do({
            ...filler_report_do,
            start_date: new Date(),
            end_date: new Date(),
            oem_id: getOem(),
            company_id: getUser().com,
        });
        filler_report_do.start_date = new Date();
        filler_report_do.end_date = new Date();
        filler_report_do.oem_id = getOem();
        filler_report_do.company_id = getUser().com;
    }

    // async function setStartDate(date) {
    //     set_report_repair_cost({
    //         ...report_repair_cost,
    //         start_month: date,
    //     });

    // }

    const [year, setYear] = useState([
        { label: "2022", value: 2022 },
        { label: "2023", value: 2023 },
        { label: "2024", value: 2024 },
        { label: "2025", value: 2025 },
        { label: "2026", value: 2026 },
        { label: "2026", value: 2026 },
        { label: "2027", value: 2027 },
        { label: "2028", value: 2028 },
        { label: "2029", value: 2029 },
        { label: "2030", value: 2030 },
    ]);

    const [mounth, setMounth] = useState([
        { label: "มกราคม", value: 1 },
        { label: "กุมภาพันธ์", value: 2 },
        { label: "มีนาคม", value: 3 },
        { label: "เมษายน", value: 4 },
        { label: "พฤษภาคม", value: 5 },
        { label: "มิถุนายน", value: 6 },
        { label: "กรกฎาคม", value: 7 },
        { label: "สิงหาคม", value: 8 },
        { label: "กันยายน", value: 9 },
        { label: "ตุลาคม", value: 10 },
        { label: "พฤศจิกายน", value: 11 },
        { label: "ธันวาคม", value: 12 },
    ]);


    const CustomInput = ({ value, onClick }) => (

        <
            div className="input-group" >
            < input type="text"
                className="form-control float-left"
                onClick={onClick}
                value={value}
            /> <div className="input-group-prepend" >
                <span className="input-group-text" >
                    <i className="far fa-calendar-alt" />
                </span>

                { /*  <label>test</label> */}
            </div>
        </div>
    );


    const [do_export_file, set_do_export_file] = useState([]);


    var config_com = {
        method: "get",
        url: Configs.API_URL + "/company/findById?id=" + getUser().com,
        headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
        },
    };
    var config_alloem = {
        method: "get",
        url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
        headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
        },
    };


    const get_report_do = async () => {
        const error_list = [];
        if(filler_report_do.start_date == ""){
            let temp_err ={
              message:"Please enter information in the fields >>> [วันที่เริ่มต้น]."
            }
            error_list.push(temp_err);
          }
        if(filler_report_do.end_date == ""){
            let temp_err ={
              message:"Please enter information in the fields >>> [วันสิ้นสุด]."
            }
            error_list.push(temp_err);
          }

          if(error_list.length > 0){
            var err_message = "";
            for(var e =0; e < error_list.length;e++){
              err_message += "<br/>"+error_list[e].message;
            }
            Swal.fire("Error", err_message, "error");
        }else{

        
        var data_do_report = {
            company_id: getUser().com,
            oem_id: getOem(),
            start_date: filler_report_do.start_date,
            end_date: filler_report_do.end_date,
        }
        
        axios({
            method: "post",
            url: Configs.API_URL + "/api/report/DOReport",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data_do_report,
        })
            .then(function (response) {
                //console.log("repos", response.data);
                set_do_export_file(response.data);
                //Do_CTS_Report(response.data);

            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }


    const rowsData = [];
    // for (var index = 0; index < do_export_file.length; index++) {
    //   const rowItem = {};
    //   rowItem["no"] = index + 1;
    //   rowItem["mat_code"] = do_export_file[index].no;
    //   rowItem["mat_name"] = do_export_file[index].name;
    //   rowItem["mat_type"] = do_export_file[index].material_type;
    //   rowItem["unit"] = do_export_file[index].material_unit;
    //   rowItem["spec"] = do_export_file[index].material_spec;
    //   rowItem["size"] = do_export_file[index].material_size;
    //   rowItem["in"] = do_export_file[index].in_hand_amount;
    //   rowItem["buy"] = do_export_file[index].buy_price;
    //   rowItem["nms"] = do_export_file[index].in_hand_amount;
    //   rowItem["npq"] = do_export_file[index].in_hand_amount;
    //   rowItem["total"] = do_export_file[index].buy_price * do_export_file[index].product_amount;
    //   rowItem["matid"] = do_export_file[index].product_material_id;
    //   rowItem["matof"] = do_export_file[index].product_forcast_id;
    //   rowItem["pro"] = do_export_file[index].product_amount;
    //   rowItem["supplier"] = do_export_file[index].material_supplier;

    //   rowsData.push(rowItem);
    // }

    const rowItem = {};
    rowItem["no"] = '1';
    rowItem["supplier_code"] = 'Supplier Code';
    rowItem["supplier_name"] = 'Supplier  Name';
    rowItem["product"] = 'Product';
    rowItem["item_number"] = 'Item Number';
    rowItem["item_description"] = 'Item Description';
    rowItem["job_number"] = 'Job Number';
    rowItem["PO"] = 'PO';
    rowItem["po_qty"] = 'PO QTY';
    rowItem["do_qty"] = 'DO QTY';
    rowItem["gr_qty"] = 'GR QTY'
    rowItem["po_elivery_date"] = 'PO Delivery Date';
    rowItem["time"] = 'Time';
    rowItem["status_name"] = 'Status Name';
    rowItem["confirm_do_date"] = 'Confirm Do Date';
    rowItem["do_time"] = 'Do Time';
    rowsData.push(rowItem);
    // }
    const data = {
        columns: [
            {
                label: "NO.",
                field: "no",
                sort: "asc",
                width: 50,
            },
            {
                label: "Supplier Code",
                field: "supplier_code",
                sort: "asc",
                width: 50,
            },
            {
                label: "Supplier  Name",
                field: "supplier_name",
                sort: "asc",
                width: 50,
            },
            {
                label: "product",
                field: "product",
                sort: "asc",
                width: 50,
            },
            {
                label: "item_number",
                field: "item_number",
                sort: "asc",
                width: 50,
            },
            {
                label: "Item Description",
                field: "item_description",
                sort: "asc",
                width: 50,
            },
            {
                label: "job_number",
                field: "job_number",
                sort: "asc",
                width: 50,
            },
            {
                label: "PO",
                field: "PO",
                sort: "asc",
                width: 50,
            },
            {
                label: "po_qty",
                field: "po_qty",
                sort: "asc",
                width: 50,
            },
            {
                label: "do_qty",
                field: "do_qty",
                sort: "asc",
                width: 50,
            },
            {
                label: "gr_qty",
                field: "gr_qty",
                sort: "asc",
                width: 50,
            },
            {
                label: "po_elivery_date",
                field: "po_elivery_date",
                sort: "asc",
                width: 50,
            },
            {
                label: "time",
                field: "time",
                sort: "asc",
                width: 50,
            },
            {
                label: "status_name",
                field: "status_name",
                sort: "asc",
                width: 50,
            },
            {
                label: "confirm_do_date",
                field: "confirm_do_date",
                sort: "asc",
                width: 50,
            },
            {
                label: "do_time",
                field: "do_time",
                sort: "asc",
                width: 50,
            },
        ],
        rows: rowsData,
    };

    return (
        <div className="wrapper">
            {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Report PDF CTS{"  "}<a target="_blank" href={getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>

                                    <li className="breadcrumb-item active">
                                        Factory Master Data
                                    </li>
                                    <li className="breadcrumb-item active">Report PDF</li>
                                </ol>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="card-title">รายงานสรุป</h3>
                                    <p class="card-text"></p>

                                    <div className="container-fluid">
                                        <div className="row">

                                            <div className="col-4">
                                                <div className="form-group ">
                                                    <button
                                                        //disabled = {true}
                                                        type="button"
                                                        className="btn btn-block btn-outline-primary"
                                                        data-toggle="modal"
                                                        data-target="#modal-fromDoReport"
                                                        onClick={get_report_do}
                                                    >
                                                        DO Report
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-4">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="card-title">รายงานสถานะ</h3>
                                    <p class="card-text"></p>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*  <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*   <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*       <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="card-title">รายงานกราฟ</h3>
                                    <p class="card-text"></p>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    <div
                                                        className="table-responsive" style={{ display: "none" }}
                                                    >
                                                        {/* <MDBDataTable
                                                            className="table table-head-fixed"
                                                            table
                                                            id="table-to-xls"
                                                            entries={4096}
                                                            striped
                                                            bordered
                                                            hover
                                                            fixedHeader
                                                            data={data}
                                                        /> */}
                                                        <table id="table-to-xls" className="table table-head-fixed" border="1">
                                                            <thead>


                                                                <tr>
                                                                    <th colspan="15"> Delivery Order Report  All PO

                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Supplier Code</th>
                                                                    <th>Supplier Name</th>
                                                                    <th>product</th>
                                                                    <th>Item Number</th>
                                                                    <th>Item Description</th>
                                                                    <th>Job Number</th>
                                                                    <th>PO</th>
                                                                    <th>PO QTY</th>
                                                                    <th>DO QTY</th>
                                                                    <th>GR QTY</th>
                                                                    <th>Po Elivery Date</th>
                                                                    <th>Time</th>
                                                                    <th>Status Name</th>
                                                                    <th>Confirm Do Date</th>
                                                                    <th>Do Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {do_export_file.map((el) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{el.supplier_code}</td>
                                                                                <td>{el.supplier_name}</td>
                                                                                <td>{el.product}</td>
                                                                                <td>{el.item_number}</td>
                                                                                <td>{el.item_description}</td>
                                                                                <td>{el.job_number}</td>
                                                                                <td>{el.po}</td>
                                                                                <td>{el.po_qty}</td>
                                                                                <td>{el.do_qty}</td>
                                                                                <td>{el.gr_qty}</td>
                                                                                <td>{el.po_delivery_date}</td>
                                                                                <td>{el.time}</td>
                                                                                <td>{el.status_name}</td>
                                                                                <td>{el.confirm_do_date}</td>
                                                                                <td>{el.do_time}</td>
                                                                            </tr>

                                                                        </>
                                                                    )
                                                                })}


                                                            </tbody>

                                                        </table>

                                                    </div>
                                                    {/*    <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*    <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*      <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="card-body">
                                    <h3 class="card-title">รายงานการเข้าพื้นที่</h3>
                                    <p class="card-text"></p>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*   <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*   <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group ">
                                                    {/*    <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </section>




                {/*  ///////////////////////////////      รายงานเที่ยววิ่งประจำเดือน  ////////////////////////// */}
                <div className="modal fade" id="modal-fromDoReport">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">DO Report</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    //onClick={set_bomlist_excel}
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="none-nolmalinput"></div>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">


                                            <div className="row">
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <div className="col-md-4">
                                                        <label>วันที่เริ่มต้น</label>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ "z-index": "99" }}>

                                                        <div className="form-group ">
                                                            <DatePicker
                                                                // disabled={true}
                                                                //value={filler_report_do.start_date}
                                                                selected={filler_report_do.start_date}
                                                                dateFormat={"dd-MM-yyyy"}
                                                                onChange={async (date) => {SetDate_start(date);get_report_do();}}
                                                                //onClick={get_report_do}
                                                                maxDate ={filler_report_do.end_date}
                                                                startDate={filler_report_do.start_date}
                                                                endDate={filler_report_do.end_date} 
                                                                customInput={<CustomInput />}
                                                                selectsStart
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12 col-lg-12">
                                                    <div className="col-md-1">
                                                        <label>ถึง</label>
                                                    </div>
                                                    <div className="col-sm-10 col-md-10 col-lg-10">
                                                        <div className="form-group">
                                                            <DatePicker
                                                                // disabled={true}
                                                                value={filler_report_do.end_date}
                                                                selected={filler_report_do.end_date}
                                                                dateFormat={"dd-MM-yyyy"}
                                                                onChange={async (date) => {SetDate_end(date);get_report_do();}}
                                                                //onClick={get_report_do}
                                                                //startDate={filler_report_do.end_date}
                                                                customInput={<CustomInput />}
                                                                startDate={filler_report_do.start_date}
                                                                minDate={filler_report_do.start_date}
                                                                endDate={filler_report_do.end_date}
                                                                selectsEnd
                                                            />


                                                        </div>

                                                    </div>

                                                </div>


                                            </div>




                                        </div>
                                        <br />


                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer justify-content-between">
                                <div className="col-md-6">
                                    <div className="form-group " onClick={get_report_do}>
                                        <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="btn btn-block btn-primary"
                                            table="table-to-xls"
                                            filename={"DO Report" +
                                            moment(new Date()).format("DD-MM-yyyy")}
                                            sheet="tablexls"
                                            buttonText="Download as XLS"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                    <button
                                        type="button"
                                        data-dismiss="modal"
                                        className="btn btn-danger"
                                        onClick={clear_fillter}
                                    >
                                        Close
                                    </button>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* /.content */}
            </div>
            {/*  <Footter/> */}
        </div>


    );
}

export default Report_PDF_CTS;
