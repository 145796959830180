import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Configs from "../../../../../../config";
import { getToken } from "../../../../../../Utils/Common";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Table_Category_Component({ mode, dataTable, getCategoryTable, reset }) {
  const history = useHistory();

  const [categoryFilter, setCategoryFilter] = useState({
    cat_filter1: "",
    cat_filter2: "",
    cat_filter3: "",
    cat_filter4: "",
  });

  function paramPathLink(path) {
    history.push({
      pathname: path,
      state: { level: mode },
    });
  }

  function deleteCategory(e) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "patch",
          url:
            Configs.API_URL_cuteplus +
            "/api/category/isUseCategory/" +
            e.cat_id,
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(function (error) {
            Swal.fire("Error", error.response.data.error.message, "error");
          });
      }
    });
  }

  const Clearfilter = () => {
    setCategoryFilter({
      ...categoryFilter,
      cat_filter1: "",
      cat_filter2: "",
      cat_filter3: "",
      cat_filter4: "",
    });

    categoryFilter.cat_filter1 = "";
    categoryFilter.cat_filter2 = "";
    categoryFilter.cat_filter3 = "";
    categoryFilter.cat_filter4 = "";

    getCategoryTable(null, mode);
  };

  const rowsData = [];
  for (let index = 0; index < dataTable.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["cat_1"] = dataTable[index].cat_name1
      ? dataTable[index].cat_name1
      : "-";

    if (mode === "lv2") {
      rowItem["cat_2"] = dataTable[index].cat_name2
        ? dataTable[index].cat_name2
        : "-";
    } else if (mode === "lv3") {
      rowItem["cat_2"] = dataTable[index].cat_name2
        ? dataTable[index].cat_name2
        : "-";
      rowItem["cat_3"] = dataTable[index].cat_name3
        ? dataTable[index].cat_name3
        : "-";
    } else if (mode === "lv4") {
      rowItem["cat_2"] = dataTable[index].cat_name2
        ? dataTable[index].cat_name2
        : "-";
      rowItem["cat_3"] = dataTable[index].cat_name3
        ? dataTable[index].cat_name3
        : "-";
      rowItem["cat_4"] = dataTable[index].cat_name4
        ? dataTable[index].cat_name4
        : "-";
    }

    rowItem["remark"] = dataTable[index].cat_remark
      ? dataTable[index].cat_remark
      : "-";

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={dataTable[index].cat_id}
            id={"btn_view" + dataTable[index].cat_id}
            className=" btn btn-xs "
            onClick={() => {
              paramPathLink(
                `/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/view/${dataTable[index].cat_id}`
              );
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>

        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={dataTable[index].cat_id}
            id={"btn_edit" + dataTable[index].cat_id}
            className=" btn btn-xs "
            onClick={() => {
              paramPathLink(
                `/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/edit/${dataTable[index].cat_id}`
              );
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={dataTable[index].cat_id}
            id={"btn_delete" + dataTable[index].cat_id}
            className=" btn btn-xs "
            onClick={deleteCategory.bind(this, dataTable[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Category Lv.1",
        field: "cat_1",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  if (mode === "lv2") {
    data.columns.splice(2, 0, {
      label: "Category Lv.2",
      field: "cat_2",
      sort: "asc",
      width: 80,
    });
  } else if (mode === "lv3") {
    data.columns.splice(
      2,
      0,
      {
        label: "Category Lv.2",
        field: "cat_2",
        sort: "asc",
        width: 80,
      },
      {
        label: "Category Lv.3",
        field: "cat_3",
        sort: "asc",
        width: 80,
      }
    );
  } else if (mode === "lv4") {
    data.columns.splice(
      2,
      0,
      {
        label: "Category Lv.2",
        field: "cat_2",
        sort: "asc",
        width: 80,
      },
      {
        label: "Category Lv.3",
        field: "cat_3",
        sort: "asc",
        width: 80,
      },
      {
        label: "Category Lv.4",
        field: "cat_4",
        sort: "asc",
        width: 80,
      }
    );
  }

  useEffect(() => {
    if (reset !== "" && reset === mode) {
      Clearfilter();
    }
  }, [reset]);

  return (
    <>
      <div
        className="row"
        style={{ alignItems: "center", marginTop: "1rem", width: "100%" }}
      >
        <div className="col-12 col-md-3 col-xl-2">
          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              required="false"
              value={categoryFilter.cat_filter1}
              onChange={(e) => {
                setCategoryFilter({
                  ...categoryFilter,
                  cat_filter1: e.target.value,
                });
              }}
            />
            <label htmlFor="">Cat Lv.1</label>{" "}
          </div>
        </div>

        {mode === "lv2" ? (
          <div className="col-12 col-md-3 col-xl-2">
            <div className="form-group ">
              <input
                type="text"
                className="form-control"
                required="false"
                value={categoryFilter.cat_filter2}
                onChange={(e) => {
                  setCategoryFilter({
                    ...categoryFilter,
                    cat_filter2: e.target.value,
                  });
                }}
              />
              <label htmlFor="">Cat Lv.2</label>{" "}
            </div>
          </div>
        ) : mode === "lv3" ? (
          <>
            <div className="col-12 col-md-3 col-xl-2">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={categoryFilter.cat_filter2}
                  onChange={(e) => {
                    setCategoryFilter({
                      ...categoryFilter,
                      cat_filter2: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">Cat Lv.2</label>{" "}
              </div>
            </div>

            <div className="col-12 col-md-3 col-xl-2">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={categoryFilter.cat_filter3}
                  onChange={(e) => {
                    setCategoryFilter({
                      ...categoryFilter,
                      cat_filter3: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">Cat Lv.3</label>{" "}
              </div>
            </div>
          </>
        ) : mode === "lv4" ? (
          <>
            <div className="col-12 col-md-3 col-xl-2">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={categoryFilter.cat_filter2}
                  onChange={(e) => {
                    setCategoryFilter({
                      ...categoryFilter,
                      cat_filter2: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">Cat Lv.2</label>{" "}
              </div>
            </div>

            <div className="col-12 col-md-3 col-xl-2">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={categoryFilter.cat_filter3}
                  onChange={(e) => {
                    setCategoryFilter({
                      ...categoryFilter,
                      cat_filter3: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">Cat Lv.3</label>{" "}
              </div>
            </div>

            <div className="col-12 col-md-3 col-xl-2">
              <div className="form-group ">
                <input
                  type="text"
                  className="form-control"
                  required="false"
                  value={categoryFilter.cat_filter4}
                  onChange={(e) => {
                    setCategoryFilter({
                      ...categoryFilter,
                      cat_filter4: e.target.value,
                    });
                  }}
                />
                <label htmlFor="">Cat Lv.4</label>{" "}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <div className="row">
        <div className="col-6 col-md-3 col-xl-2">
          <div className="form-group ">
            <button
              type="button"
              className="btn btn-block btn-info "
              onClick={async () => {
                let temp = null;

                if (mode === "lv1") {
                  temp = {
                    cat_filter1: categoryFilter.cat_filter1?.trim(),
                    level: 1,
                  };
                } else if (mode === "lv2") {
                  temp = {
                    cat_filter1: categoryFilter.cat_filter1?.trim(),
                    cat_filter2: categoryFilter.cat_filter2?.trim(),
                    level: 2,
                  };
                } else if (mode === "lv3") {
                  temp = {
                    cat_filter1: categoryFilter.cat_filter1?.trim(),
                    cat_filter2: categoryFilter.cat_filter2?.trim(),
                    cat_filter3: categoryFilter.cat_filter3?.trim(),
                    level: 3,
                  };
                } else if (mode === "lv4") {
                  temp = {
                    cat_filter1: categoryFilter.cat_filter1?.trim(),
                    cat_filter2: categoryFilter.cat_filter2?.trim(),
                    cat_filter3: categoryFilter.cat_filter3?.trim(),
                    cat_filter4: categoryFilter.cat_filter4?.trim(),
                    level: 4,
                  };
                }

                await getCategoryTable(temp, mode);
              }}
            >
              Search
            </button>
          </div>
        </div>

        <div className="col-6 col-md-3 col-xl-2">
          <div className="form-group ">
            <button
              type="button"
              className="btn btn-block btn-info"
              onClick={() => Clearfilter()}
            >
              Clear
            </button>
          </div>
        </div>

        <div className="col-12 col-md-3 col-xl-2">
          <div className="form-group ">
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={() =>
                paramPathLink(
                  "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/add"
                )
              }
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className="table-responsive " style={{ whiteSpace: "nowrap" }}>
        <MDBDataTable
          sortable={false}
          className="table table-head-fixed"
          striped
          bordered
          hover
          fixedHeader
          data={data}
        />
      </div>
    </>
  );
}

export default Table_Category_Component;
