import { useState } from "react";

const defaultConfig = {
    label: true,
    placeholder: true,
    title: "Select an Options",
};

function SelectDropdown({
    options = [],
    selectedOption,
    useLabel = defaultConfig.label,
    usePlaceholder = defaultConfig.placeholder,
    title = defaultConfig.title,
}) {
    // Select dropdown label
    const [label, setLabel] = useState(title);

    // Select dropdown placeholder
    const [placeholder, setPlaceholder] = useState(title);

    // Selected options
    const [selectedOptions, setSelectedOption] = useState("");

    // Function to handle when component change
    function handleOptionChange(event) {
        if (typeof selectedOption === "function") {
            selectedOption(event.target.value);
        }
    }

    return (
        <div>
            {/* Select dropdown label */}
            {useLabel ? (
                <label
                    name="dropdown-label"
                    id="dropdown-label"
                    className="dropdown-label"
                    htmlFor="dropdown"
                >
                    {label}
                </label>
            ) : (
                ""
            )}

            {/* Select dropdown body */}
            <select
                name="dropdown"
                id="dropdown-body"
                className="dropdown-body form-control form-control-lg"
                size={"lg"}
                onChange={handleOptionChange}
            >
                {usePlaceholder ? <option value="">{placeholder}</option> : ""}

                {options.map((option, index) => (
                    <option
                        name={option}
                        id="option"
                        className="option-body"
                        key={index}
                        value={option.value}
                        disabled={option.disabled}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default SelectDropdown;
