import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { dashboard } from "..";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import moment from "moment";
import Logistic_cars_details from "../../assets/file/Logistic_cars_details.xlsx";
import Driver_details from "../../assets/file/Driver_details.xlsx";

import { useParams } from "react-router";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

function External_data(params) {
  const [test, settest] = useState({
    test: "",
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const toggle_modal = () => setModal(!modal);
  const toggle_modal2 = () => setModal2(!modal2);
  const toggle_modal3 = () => setModal3(!modal3);
  const [getstats, setstats] = useState([]);

  const [checktext, setchecktext] = useState("");
  const [cltext, setcltext] = useState("");
  const [externaldata, setexternaldata] = useState({
    company_id: getUser().com,
    file_name: "",
    data: "",
    template: "",
    oem_id: getOem(),
    status_upload: "",
    upload_detail: "",
  });

  const rowsData = [];

  let [repairView, setrepairView] = useState([]);
  const [repairStatus, setrepairStatus] = useState({
    success: "",
    not_success: "",
  });

  const [driverDetailStatus, setdriverDetailStatus] = useState({
    success: "",
    not_success: "",
  });
  const [driverDetailView, setdriverDetailView] = useState([]);

  const [logisticDetailView, setlogisticDetailView] = useState([]);
  const [logisticDetailStatus, setlogisticDetailStatus] = useState({
    success: "",
    not_success: "",
  });

  async function updateRepairValues() {
    let timerInterval;
    Swal.fire({
      title: "กำลังดำเนินการ...",
      html: "<b></b>",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      /*     if (result.dismiss === Swal.DismissReason.timer) {
      console.log('I was closed by the timer')
    } */
    });
    var config = {
      method: "get",
      url: "https://script.google.com/macros/s/AKfycbz833PMLB169WLSmWTBRg4CVX_5yMnhAnrvfg6LMtu7xHAvZpinXW34qMqcj8xp2ExQ9Q/exec",
      headers: {},
    };
   
   await axios(config)
      .then(async function (response) {
        /*   console.log(response.data) */

        let carMN;
        for (let i = 0; i < response.data.length; i++) {
          if (
            response.data[i].maintenance_number !== "" &&
            response.data[i].maintenance_number !== null &&
            response.data[i].maintenance_number !== undefined &&
            response.data[i].maintenance_number !== "-"
          ) {
            carMN = response.data[i].maintenance_number;
          }
          response.data[i].maintenance_number = carMN;
        }

        var count = Math.ceil(response.data.length / 100);

      
        for (let index = 1; index <= count; index++) {
          var filter = response.data.filter((e, index2) => {
            return index2 < index * 100 && index2 > index * 100 - 101;
          });
          console.log(index,count);
          let temp = {
            repair_data: filter,
            oem_id: getOem(),
            company_id: getUser().com,
            lastround: index == count ?  true: false,
          };
          
       await   axios({
            method: "post",
            url: Configs.API_URL + "/externalData/updateRepairValues",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(async function (response2) {
              let arr_repair =[];
              console.log(index,response2.data);
              for (let item of response2.data) {
                repairView.push(item); //arr_repair.push(item);
              }
              /* setrepairView(arr_repair); */
              
             
               /*  console.log("repairView11",repairView); */
              
              //console.log("arr_repair",arr_repair);
            
              // if (index === 1) {
              //   var check = repairView.filter((e) => {
              //     return e.isStatus === false;
              //   });
              //   var check2 = repairView.filter((e) => {
              //     return e.isStatus === true;
              //   });

              //   setrepairStatus({
              //     ...repairStatus,
              //     success: check2.length,
              //     not_success: check.length,
              //   });
              //   setrepairStatus("อัพเดตรายการค่าซ่อมไม่สำเร็จ");

              //    Swal.fire(
              //      "อัพโหลดค่าซ่อมสำเร็จ",
              //      "ค่าซ่อมได้ถูกอัพโหลดเข้าฐานข้อมูลเรียบร้อย",
              //      "success"
              //    ).then(() => {
              //      toggle_modal();
              //      getexternaldate();
              //      var cl = "green";
              //      setchecktext("ตรง Template");
              //      setcltext(cl);
              //    });
              // }
            })
            .catch(function (error) {
              // Swal.fire(
              //   "อัพโหลดค่าซ่อมไม่สำเร็จ !!!",
              //   "เกิดข้อผิดพลาดบางอย่างจึงไม่สามารถอัพโหลดได้",
              //   "error"
              // );
              // getexternaldate();
              // var cl = "red";
              // setchecktext("ไม่ตรง Template");
              // setcltext(cl);
            });
           
        }
     
      })
      .catch(function (error) {
        console.log(error);
      });
          toggle_modal();
           getexternaldate();
           var cl = "green";
           setchecktext("ตรง Template");
           setcltext(cl);
  }

  const upload_file = async (e) => {
    if (e.target.files[0]) {
      const temp = {
        file_names: e.target.files[0].name,
        templates: externaldata.template,
        data: externaldata.data,
        oem_id: externaldata.oem_id,
        company_id: externaldata.company_id,
        status_upload: externaldata.status_upload,
        upload_detail: externaldata.upload_detail || null,
        // upload_detail:externaldata.upload_detail || null,
      };
      console.log("temp เทม", temp);
      Swal.fire({
        title: "Saveing",
        allowEscapekey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        if (
          (temp.file_names === "ค่าซ่อม.xlsx" &&
            temp.templates === "ค่าซ่อม.xlsx") ||
          (temp.file_names === "ค่าซ่อม.xls" &&
            temp.templates === "ค่าซ่อม.xls")
        ) {
        } else if (
          (temp.file_names === "ค่าเเรง.xlsx" &&
            temp.templates === "ค่าเเรง.xlsx") ||
          (temp.file_names === "ค่าเเรง.xls" &&
            temp.templates === "ค่าเเรง.xls")
        ) {
        } else if (
          (temp.file_names === "SellKPI.xlsx" &&
            temp.templates === "SellKPI.xlsx") ||
          (temp.file_names === "SellKPI.xls" &&
            temp.templates === "SellKPI.xls")
        ) {
        } else if (
          (temp.file_names === "Logistic_cars_details.xlsx" &&
            temp.templates === "Logistic_cars_details.xlsx") ||
          (temp.file_names === "Logistic_cars_details.xls" &&
            temp.templates === "Logistic_cars_details.xls")
        ) {
          var data = new FormData();
          data.append("file", e.target.files[0]);
          data.append("file_name", e.target.files[0].name);
          data.append("oem_id", getOem());
          data.append("company_id", getUser().com);
          // console.log("ดาต้าที่ส่ง", data);
          // console.log("ชื่อ", e.target.files[0].name);
          // console.log("ไฟล์ที่ส่ง", e.target.files[0]);
          var config_uploadExecl = {
            method: "post",
            url:
              Configs.API_URL +
              "/externalData/importExcel_Logistic_cars_detail",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config_uploadExecl)
            .then(function (response5) {
              /*      console.log("respornsss", response5.data);
              setcltext("");
              var cl = "green";
              setchecktext("ตรง Template");
              setcltext(cl);
              getexternaldate(); */

              setlogisticDetailView(response5.data.status_import);
              /// pop up ยังไม่ขึ้น ตรงนี้ะนะ
              let checkd = response5.data.status_import.filter((e) => {
                return e.status_import === false;
              });
              let checkd2 = response5.data.status_import.filter((e) => {
                return e.status_import === true;
              });
              //toggle_modal2();
              setlogisticDetailStatus({
                ...logisticDetailView,
                success: checkd2.length,
                not_success: checkd.length,
              });
              setcltext("");
              Swal.fire(
                "อัพโหลด Logistic_car details สำเร็จ",
                "Logistic_car details ได้ถูกอัพโหลดเข้าฐานข้อมูลเรียบร้อย",
                "success"
              ).then(() => {
                toggle_modal3();
                var cl = "green";
                setchecktext("ตรง Template");
                setcltext(cl);
                getexternaldate();
                // toggle_modal3();
              });

              document.getElementById("exampleInputFile").value = "";

              /*   window.location.href =
              "/Warehouse_Management/External_data"; */
              document.getElementById("exampleInputFile").value = "";
            })
            .catch(function (error) {
              var config_uploadExecl = {
                method: "post",
                url: Configs.API_URL + "/externalData/externaldata",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
              axios(config_uploadExecl).then(function (response) {
                setcltext("");
                var cl = "green";
                setchecktext("ตรง Template");
                setcltext(cl);
                getexternaldate();
                Swal.fire({
                  icon: "success",
                  title: "Upload Suceecs",
                  text: "รูปแบบถูกต้อง",
                  showConfirmButton: false,
                  timer: 1500,
                });
                // window.location.href =
                //   "/Warehouse_Management/External_data";
                document.getElementById("exampleInputFile").value = "";
              });
              // console.log(error);
              // Swal.fire("ไม่สามารถอัพโหลดได้", "ไม่มีข้อมูลใน Elsx", "error");
            });
        } else if (
          (temp.file_names === "Driver_details.xlsx" &&
            temp.templates === "Driver_details.xlsx") ||
          (temp.file_names === "Driver_details.xls" &&
            temp.templates === "Driver_details.xls")
        ) {
          var data = new FormData();
          data.append("file", e.target.files[0]);
          data.append("file_name", e.target.files[0].name);
          data.append("oem_id", getOem());
          data.append("company_id", getUser().com);

          // console.log("ดาต้าที่ส่ง", data);

          var config_uploadExecl = {
            method: "post",
            url: Configs.API_URL + "/externalData/importExcel_Driver_detail",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config_uploadExecl)
            .then(function (response6) {
              console.log(response6.data);

              setdriverDetailView(response6.data.status_import);
              /// pop up ยังไม่ขึ้น ตรงนี้ะนะ
              let checkd = response6.data.status_import.filter((e) => {
                return e.status_import === false;
              });
              let checkd2 = response6.data.status_import.filter((e) => {
                return e.status_import === true;
              });
              //toggle_modal2();
              setdriverDetailStatus({
                ...driverDetailStatus,
                success: checkd2.length,
                not_success: checkd.length,
              });
              setcltext("");
              Swal.fire(
                "อัพโหลด Driver details สำเร็จ",
                "Driver details ได้ถูกอัพโหลดเข้าฐานข้อมูลเรียบร้อย",
                "success"
              ).then(() => {
                var cl = "green";
                setchecktext("ตรง Template");
                setcltext(cl);
                getexternaldate();
                toggle_modal2();
              });

              document.getElementById("exampleInputFile").value = "";
              /*    window.location.href =
                    "/Warehouse_Management/External_data"; */
            })
            .catch(function (error) {
              var config_uploadExecl = {
                method: "post",
                url: Configs.API_URL + "/externalData/externaldata",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
              axios(config_uploadExecl).then(function (response7) {
                console.log("respornsss", response7.data);
                setcltext("");
                var cl = "green";
                setchecktext("ตรง Template");
                setcltext(cl);
                getexternaldate();
                Swal.fire(
                  "อัพโหลด Driver details สำเร็จ",
                  "Driver details ได้ถูกอัพโหลดเข้าฐานข้อมูลเรียบร้อย",
                  "success"
                ).then(() => {
                  toggle_modal2();
                  getexternaldate();
                  var cl = "green";
                  setchecktext("ตรง Template");
                  setcltext(cl);
                });
                // window.location.href =
                //   "/Warehouse_Management/External_data";
                document.getElementById("exampleInputFile").value = "";
              });
              console.log(error);
              // Swal.fire("ไม่สามารถอัพโหลดได้", "ไม่มีข้อมูลใน Elsx", "error");
            });
        } else {
          var config_uploadExecl = {
            method: "post",
            url: Configs.API_URL + "/externalData/externaldata",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          };

          axios(config_uploadExecl)
            .then(function (response) {
              //console.log("respornsss", response7.data);
              setcltext("");
              var cl = "red";
              setchecktext("ไม่ตรง Template");
              setcltext(cl);
              getexternaldate();
              Swal.fire({
                icon: "error",
                title: "Upload Fail",
                text: "รูปแบบไม่ถูกต้อง",
                showConfirmButton: false,
                timer: 1500,
              });
              // window.location.href =
              //   "/Warehouse_Management/External_data";
              document.getElementById("exampleInputFile").value = "";
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message + error, "error");
            });
          setcltext("");
          var cl = "red";
          setchecktext("ไม่ตรง Template");
          setcltext(cl);
        }
      });
    } else {
      console.log("Erorr");
      // Swal.fire("Error","ไม่มีข้อมูลใน exsle", "error");
    }
  };

  useEffect(() => {
    getexternaldate();
  }, []);

  const getexternaldate = async () => {
    const getdate = {
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/externalData/getexterDate",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: getdate,
    })
      .then(function (response) {
        setstats(response.data);
        /* console.log("ไฟล์", response.data);
        console.log("สะเตตัด", setstats); */
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("ErrorStatus", error, "errorstatus");
      });
  };

  // var config_getOem = {
  //   method: "get",
  //   url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
  //   headers: {
  //     Authorization: getToken(),
  //     "Content-Type": "application/json",
  //     "X-TTT": Configs.API_TTT,
  //   },
  // };
  // const GetCompany = async () => {
  //   axios({
  //     method: "get",
  //     url: Configs.API_URL + "/company/all",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then(function (response) {
  //       /*   console.log(response.data); */
  //       setCompany(response.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  // const {id} = useParams();

  // const selectCompanyGroup = useRef();
  //   useEffect(() => {
  //     GetMat_stc();
  //     GetCompany();
  //     axios(config_getOem)
  //     .then(function (response) {
  //       const options = [];
  // var element2 = {};
  //       element2["value"] = "All_OEM";
  //       element2["label"] = "---ทั้งหมด---";
  //       options.push(element2);
  //       response.data.forEach((el) => {
  //         var element = {};
  //         element["value"] = el.id;
  //         element["label"] = el.name;
  //         options.push(element);
  //       });
  //       console.log(options);
  //       setCompany(options);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  //   }, []);
  const clickclear = async () => {
    setchecktext("");
    await setexternaldata({
      ...externaldata,
      file_name: "",
    });
    externaldata.file_name = "";
  };

  var ctext = "";
  var textcheck = "";
  var cl = "";
  // function addpage(params) {
  //   window.location.href = "/Warehouse_Management/Record_oil/add";
  // }

  // function importExcel_Driver_detail() {

  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios({
  //         method: "get",
  //         url: Configs.API_URL + "/externalData/importExcel_Driver_detail",
  //         headers: {
  //           Authorization: getToken(),
  //           "Content-Type": "application/json",
  //           "X-TTT": Configs.API_TTT,
  //         },
  //       })
  //         .then(function (response) {
  //           if (response.data === true) {
  //             Swal.fire(
  //               "Deleted!",
  //               "Your file has been deleted.",
  //               "success"
  //             ).then(() => {
  //               // window.location.href = "/Warehouse_Management/Record_oil";
  //               // getexternaldate();
  //             });
  //           }
  //         })
  //         .catch(function (error) {
  //           console.log(error);
  //           Swal.fire("Error", "Something went wrong: " + error, "error");
  //         });
  //     }
  //   });
  // }

  //   function setstatus_template(temp) {
  // console.log("เช็คฟังชั่น เช็คเทม",temp);
  //     if (temp.file_name != temp.template) {
  //       setcltext("");
  //       setchecktext("");
  //       ctext = "ไม่ตรงtemplate"
  //       textcheck = "ไม่ตรงtemplate";
  //       cl = "red";
  //     }
  //     else {
  //       setcltext("");
  //       setchecktext("");
  //       ctext = "ใบจัดส่งสินค้า";
  //       textcheck = "ใบจัดส่งสินค้า";
  //       cl = "green";
  //     }
  //     setcltext(cl);
  //     setchecktext(textcheck);
  //   }

  for (let index = 0; index < getstats.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["template"] = getstats[index].external_name;
    rowItem["upload Date"] = moment(getstats[index].update_date).format(
      "DD/MM/yyyy , HH:mm"
    );
    rowItem["Status"] = getstats[index].status_upload;
    rowItem["user"] = getstats[index].name + " " + getstats[index].sirname;
    // rowItem["mgt"] = (
    //   <div className="row">

    //     <div className="col-4">
    //       <a
    //         //  key={getstats[index].id}
    //         className=" btn btn-xs "
    //       //  onClick={deldata.bind(this,getstats[index].id)}
    //       >
    //         <i class="fas fa-trash-alt"></i>
    //       </a>
    //     </div>
    //   </div>
    // );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "NO.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Template",
        field: "template",
        sort: "asc",
        width: 50,
      },
      {
        label: "Upload Date",
        field: "upload Date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        sort: "asc",
        width: 50,
      },
      {
        label: "User",
        field: "user",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: "Mgt",
      //   field: "mgt",
      //   sort: "asc",
      //   width: 50,
      // },
    ],
    rows: rowsData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 >
                  External Data{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">External Data</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title" onClick={console.log(repairView)}>External Data</h3>
            </div>

            <div className="card-body">
              <div className="row">
                {/* <div className="row">
                </div> */}

                <div className="col-6.5 ">
                  <div className="none-nolmalinput">
                    <div className="row">
                      <div className="row">
                        <div className="col-11">
                          <div>
                            <label htmlFor="">Template: </label>

                            <select
                              className="custom-select select2 form-control"
                              //onClick={clickclear}
                              value={externaldata.template}
                              onChange={(e) => {
                                setexternaldata({
                                  ...externaldata,
                                  template: e.target.value,
                                });
                                setchecktext("");
                              }}
                            >
                              <option value="">Select</option>
                              <option value="ค่าซ่อม.xlsx">ค่าซ่อม</option>
                              {/*       <option value="ค่าเเรง.xlsx">ค่าเเรง</option>
                          <option value="SellKPl.xlsx">SellKPl</option>  */}
                              <option value="Logistic_cars_details.xlsx">
                                Logistics_Cars_Details
                              </option>
                              <option value="Driver_details.xlsx">
                                Driver_detail
                              </option>
                            </select>
                          </div>
                        </div>
                        {externaldata.template === "ค่าซ่อม.xlsx" ? (
                          <>
                            <div className="col-md-12">
                              <div className="form-group col-md-6">
                                <button
                                  className="btn btn-primary"
                                  onClick={updateRepairValues}
                                >
                                  อัพเดต
                                </button>
                                &nbsp;
                                <a
                                  href="https://docs.google.com/spreadsheets/d/1XNdn6dJqmhDwn3opuL-Ir5WsHOMWFfd_OG8pJcQ28Os/edit#gid=0"
                                  target="_blank"
                                  // download="upload_Logistic_cars_details.xlsx"
                                  style={{ fontSize: "" }}
                                >
                                  <button
                                    className="btn btn-primary"
                                    // onClick={updateRepairValues}
                                  >
                                    ค่าซ่อม.template
                                  </button>
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12">
                              <div className="form-group col-md-6">
                                {externaldata.template ===
                                "Logistic_cars_details.xlsx" ? (
                                  <>
                                    <a
                                      href={Logistic_cars_details}
                                      download="Logistic_cars_details.xlsx"
                                      style={{ fontSize: "" }}
                                    >
                                      <button
                                        className="btn btn-primary"
                                        disabled={
                                          externaldata.template === "" ||
                                          externaldata.template ===
                                            "ค่าซ่อม.xlsx"
                                            ? true
                                            : false
                                        }
                                        // onClick={updateRepairValues}
                                      >
                                        Logistic_Cars_Details.template
                                      </button>
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    {externaldata.template ===
                                    "Driver_details.xlsx" ? (
                                      <>
                                        <a
                                          href={Driver_details}
                                          download="Driver_details.xlsx"
                                          style={{ fontSize: "" }}
                                        >
                                          <button
                                            className="btn btn-primary"
                                            disabled={
                                              externaldata.template === "" ||
                                              externaldata.template ===
                                                "ค่าซ่อม.xlsx"
                                                ? true
                                                : false
                                            }
                                            // onClick={updateRepairValues}
                                          >
                                            Driver_details.template
                                          </button>
                                        </a>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-9">
                        <label htmlFor="">External file:</label>
                        <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small>
                        <div className="input-group">
                          <div className="custom-file">
                            <input
                              // onClick={clickclear}
                              type="file"
                              required
                              disabled={
                                externaldata.template === "" ||
                                externaldata.template === "ค่าซ่อม.xlsx"
                                  ? true
                                  : false
                              }
                              className="custom-file-input"
                              id="exampleInputFile"
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={upload_file}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="exampleInputFile"
                            >
                              {externaldata.file_name !== null &&
                              externaldata.file_name !== "" &&
                              externaldata.file_name !== undefined
                                ? externaldata.file_name
                                : "Select File"}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                      <div col-12> 
                      <button className="btn btn-primary"
                            // onClick={updateRepairValues}
                            >
                              ดาวโหลดเทมเพต
                            </button>
                      </div>
                      </div> */}
                  {/* {externaldata.template === "ค่าซ่อม.xlsx" ? (
                          <div className="col-3">
                          <div className="form-group">
                            <button className="btn btn-primary"
                            onClick={updateRepairValues}
                            >
                              download template
                            </button>
                            </div>
                            </div>
                      ):(
                        <>
                   
                        </>
                      )} */}

                  {/* <br></br> */}
                  <div className="select-check"></div>
                </div>
                <div className="col-5 ">
                  <br></br>
                  <div className="row">
                    <div>
                      {/* <a onClick={setstatus_template}>       */}
                      <a>
                        <h4>
                          Template Check :{" "}
                          <span style={{ color: cltext }}>{checktext}</span>
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>

                {/* <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-info"
                      
                      >
                        Clear data
                      </button>
                    </div>
                  </div> */}

                {/*   <div className="col-2">
                    <div className="form-group ">
                    <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={set_excel}
                aria-label="Close"
              >
                        Brows File
                      </button>
                    </div>
                  </div> */}
              </div>
              <div className="col 12">
                <div
                  className="table-responsive" /* style={{ height: "500px" }} */
                >
                  <MDBDataTable
                    className="table table-head-fixed"
                    table
                    sortable={false}
                    /*   id="table-to-xls" */
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={data}
                  />
                </div>

                <div className="table-responsive" style={{ display: "none" }}>
                  <MDBDataTable
                    className="table table-head-fixed"
                    table
                    sortable={false}
                    id="table-to-xls"
                    entries={4096}
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          isOpen={modal}
          toggle={toggle_modal}
          className="modal-dialog modal-xl"
        >
          <ModalHeader toggle={toggle_modal}>
            <h1>
              รายการค่าซ่อม ::: [{" "}
              <font color="green">สำเร็จ {repairStatus.success}</font> /{" "}
              <font color="red"> ไม่สำเร็จ {repairStatus.not_success}</font>]
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-4">
                <label>ตารางแสดงสถานะการอัพเดตค่าซ่อม</label>
              </div>
            </div>
            <div className="table-responsive" style={{ height: "500px" }}>
              <MDBTable striped hover bordered small searching={true}>
                <MDBTableHead>
                  <tr>
                    <th>No.</th>
                    <th>เลขที่ใบขออนุมัติซ่อม</th>
                    <th>ทะเบียนรถ</th>
                    <th>สถานะอัพเดต</th>
                    <th>หมายเหตุ</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {repairView.map((el, index) => {
                    var color;
                    if (el.isStatus === false) {
                      color = "red";
                    }
                    return (
                      <tr id={index}>
                        <td>
                          <font color={color}>{index + 1}</font>
                        </td>
                        <td>
                          <font color={color}>{el.maintenance_number}</font>
                        </td>
                        <td>
                          <font color={color}>{el.logistic_car_id}</font>
                        </td>
                        <td>
                          <font color={color}>
                            {el.isStatus ? "สำเร็จ" : "ไม่สำเร็จ"}
                          </font>
                        </td>
                        <td>
                          <font color={color}>{el.isStatus_txt}</font>
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>
          </ModalBody>
          <ModalFooter>
            {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle_modal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modal2}
          toggle={toggle_modal2}
          className="modal-dialog modal-xl"
        >
          <ModalHeader toggle={toggle_modal2}>
            <h1>
              รายการ Driver details ::: [{" "}
              <font color="green">สำเร็จ {driverDetailStatus.success}</font> /{" "}
              <font color="red">
                {" "}
                ไม่สำเร็จ {driverDetailStatus.not_success}
              </font>
              ]
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-4">
                <label>ตารางแสดงสถานะการอัพเดต Driver details</label>
              </div>
            </div>
            <div className="table-responsive" style={{ height: "500px" }}>
              <MDBTable striped hover bordered small searching={true}>
                <MDBTableHead>
                  <tr>
                    <th>No.</th>
                    <th>รหัสผู้ใช้รถ</th>
                    <th>ชื่อ-นามสกุล</th>
                    <th>สถานะอัพเดต</th>
                    <th>หมายเหตุ</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {driverDetailView.map((el, index) => {
                    var color;
                    if (el.status_import === false) {
                      color = "red";
                    }
                    return (
                      <tr id={index}>
                        <td>
                          <font color={color}>{index + 1}</font>
                        </td>
                        <td>
                          <font color={color}>{el.id_person}</font>
                        </td>
                        <td>
                          <font color={color}>
                            {el.driver_name_th}
                            {" [" + el.oem_name_text + "]"}
                          </font>
                        </td>
                        <td>
                          <font color={color}>
                            {el.status_import ? "สำเร็จ" : "ไม่สำเร็จ"}
                          </font>
                        </td>
                        <td>
                          <font color={color}>{el.err_list}</font>
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>
          </ModalBody>
          <ModalFooter>
            {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle_modal2}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modal3}
          toggle={toggle_modal3}
          className="modal-dialog modal-xl"
        >
          <ModalHeader toggle={toggle_modal3}>
            <h1>
              รายการ Logistc Car details ::: [{" "}
              <font color="green">สำเร็จ {logisticDetailStatus.success}</font> /{" "}
              <font color="red">
                {" "}
                ไม่สำเร็จ {logisticDetailStatus.not_success}
              </font>
              ]
            </h1>
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-4">
                <label>ตารางแสดงสถานะการอัพเดต Logistc Car details</label>
              </div>
            </div>
            <div className="table-responsive" style={{ height: "500px" }}>
              <MDBTable striped hover bordered small searching={true}>
                <MDBTableHead>
                  <tr>
                    <th>No.</th>
                    <th>ทะเบียน</th>
                    <th>ประเภท</th>
                    <th>สังกัด</th>
                    <th>สถานะอัพเดต</th>
                    <th>หมายเหตุ</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {logisticDetailView.map((el, index) => {
                    var color;
                    if (el.status_import === false) {
                      color = "red";
                    }
                    return (
                      <tr id={index}>
                        <td>
                          <font color={color}>{index + 1}</font>
                        </td>
                        <td>
                          <font color={color}>{el.plate_number}</font>
                        </td>
                        <td>
                          <font color={color}>{el.car_type_id}</font>
                        </td>
                        <td>
                          <font color={color}>{el.oem_id}</font>
                        </td>
                        <td>
                          <font color={color}>
                            {el.status_import ? "สำเร็จ" : "ไม่สำเร็จ"}
                          </font>
                        </td>
                        <td>
                          <font color={color}>{el.err_list}</font>
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>
          </ModalBody>
          <ModalFooter>
            {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
            <Button color="secondary" onClick={toggle_modal3}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}
export default External_data;
