import React, { Component, useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import NumberFormat from "react-number-format";
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
  getFeature,
} from "../../Utils/Common";
import axios from "axios";
import Configs from "../../config";
import Swal from "sweetalert2";
import {
  checkDataEmployee_garantee,
  checkDataEmployee_garantor,
  checkDataEmployee_talen,
  checkDataEmployee_Work,
  checkDataSame_Garantee,
  checkDataSame_Garantor,
  checkDataSame_Talent,
} from "../../Utils/Employee_history";
import moment from "moment";
import { useParams } from "react-router";
import Select from "react-select";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
// import Employee_add_Info from "./Employee_add_Info";

function Info(mode) {
  const inputElement = useRef();
  const closeWorkModal = useRef();
  const closeEduModal = useRef();

  const focusInput = () => {
    inputElement.current.click();
  };
  const clickCloseEduModal = () => {
    closeEduModal.current.click();
  };
  const clickCloseWorkModal = () => {
    closeWorkModal.current.click();
  };
  const [employeeToAdd, setemployeeToAdd] = useState([]);

  const [Image, setImage] = useState({
    data: null,
    emp_image_name: null,
    emp_image_path: null,
    status: false,
  });

  let CalculateAge = (date) => {
    let today = new Date();
    let birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setemployee({ ...employee, emp_age: age });
    employee.emp_age = age;
    settest({ ...test, test: "" });
    test.test = "";
    return age;
  };
  const [feature, setfeature] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [closeModal, setCloseModal] = useState({
    checkData: null,
  });
  const [modal, setModal] = useState({
    check: false,
    index: -1,
  });
  const [disable, setdisable] = useState(null);
  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "ประวัติพนักงาน";
    });
    setfeature(feature_data);
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "edit") {
      setPageMode("Edit");
      setdisable(false);
    } else {
      setPageMode("Add");
      setdisable(false);
    }
  }, []);

  useEffect(() => {
    filterProvince();
    filterNation();
    filterNameTitle();
    filterNameGender();
    filterReligion();
    filterSoldierStatus();
    filterPersonStatus();
    filterAddressStatus();
    filterEducationLevel();
    GetScoreNameEmp();
    GetMasterWorkType();
    getEmployeeHistory();
  }, []);

  useEffect(() => {
    setEmp_talent(editEmp);
    setEmp_History_Work(editEmp);
    seteducation(EditEducation);
  }, [modal]);

  const [checkTable, setCheckTable] = useState({
    check: false,
  });
  useEffect(() => {
    setTableEdu();
    setTableWork();
    setTableTalent();
  }, [checkTable]);

  function setTableEdu() {
    if (
      mode.mode == "edit" &&
      education.emp_edu_gpa !== "" &&
      education.emp_edu_major !== "" &&
      education.emp_edu_major
    ) {
      const emp_edu = employee.emp_educationLevel;
      emp_edu.push(education);
      setemployee({ ...employee, emp_educationLevel: emp_edu });
      seteducation(resetEducation);
    }
    if (
      mode.mode == "read" &&
      education.emp_edu_gpa !== "" &&
      education.emp_edu_major !== "" &&
      education.emp_edu_major
    ) {
      const emp_edu = employee.emp_educationLevel;
      emp_edu.push(education);
      setemployee({ ...employee, emp_educationLevel: emp_edu });
      seteducation(resetEducation);
    }
  }
  function setTableWork() {
    if (
      mode.mode == "edit" &&
      emp_history_work.emp_work_type !== "" &&
      emp_history_work.emp_work_type_id
    ) {
      const emp_work = employee.emp_work_history;
      emp_work.push(emp_history_work);
      setemployee({ ...employee, emp_work_history: emp_work });
      setEmp_History_Work(resetEmp_History_Work);
    }
    if (
      mode.mode == "read" &&
      emp_history_work.emp_work_type !== "" &&
      emp_history_work.emp_work_type_id
    ) {
      const emp_work = employee.emp_work_history;
      emp_work.push(emp_history_work);
      setemployee({ ...employee, emp_work_history: emp_work });
      setEmp_History_Work(resetEmp_History_Work);
    }
  }
  function setTableTalent() {
    if (
      mode.mode == "edit" &&
      emp_talent.emp_language !== "" &&
      emp_talent.emp_project
    ) {
      const emp_talent1 = employee.emp_talen;
      emp_talent1.push(emp_talent);
      setemployee({ ...employee, emp_talen: emp_talent1 });
      setEmp_talent(resetEmp_talent);
    }
    if (
      mode.mode == "read" &&
      emp_talent.emp_language !== "" &&
      emp_talent.emp_project
    ) {
      const emp_talent1 = employee.emp_talen;
      emp_talent1.push(emp_talent);
      setemployee({ ...employee, emp_talen: emp_talent1 });
      setEmp_talent(resetEmp_talent);
    }
  }
  // ***************************** TEST **************************

  const { id } = useParams();
  const data5Clone = {
    emp_was_union_member: "",
    emp_was_ever_been_judged: "",
    emp_was_ever_been_judged_because: "",
    emp_employee_judged_id: "",
    emp_reason_to_choose_work: "",
    emp_reason_to_match_work: "",
    emp_feature_which_success: "",
    emp_has_ideal_work: "",
    emp_target_next_three_year: "",
    emp_recommender: "",
    emp_relationship_1: "",
    emp_know_person_in_work: "",
    emp_relationship_2: "",
    emp_can_over_time: "",
    emp_can_over_time_because: "",
    emp_can_over_time_choose: "",
    emp_can_work_holiday: "",
    emp_can_work_holiday_because: "",
    emp_can_work_night: "",
    emp_can_work_night_because: "",
    emp_want_position: "",
    emp_want_money_day: "",
    emp_want_money_month: "",
    emp_want_other: "",
    emp_can_start_now: "",
    emp_can_start_now_because: "",
    emp_start_date: "",
    emp_phone: "",
    emp_location: "",
  };

  const data4Clone = {
    emp_four_languge_TH: "",
    emp_four_languge_EN: "",
    emp_four_printer: false,
    emp_four_fax: false,
    emp_four_com: false,
    emp_four_moto: false,
    emp_four_car: false,
    emp_four_truck: false,
    emp_four_vehicle_choose: "",
    emp_four_vehicle_which: "",
    emp_four_program: "",
    emp_two_experience: "",
  };

  const resetData1 = {
    emp_position: "",
    emp_money_want: "",
    emp_name_title_th: "",
    emp_name_th: "",
    emp_surname_th: "",
    emp_name_title_eng: "",
    emp_name_eng: "",
    emp_surname_eng: "",
    emp_sex: "",
    emp_height: "",
    emp_weight: "",
    emp_birthday: "",
    emp_age: "",
    emp_nationality: "",
    emp_ethnicity: "",
    emp_religion: "",
    emp_national_id: "",
    emp_status: "",
    emp_child_num: "",
    emp_military_status: "",
    emp_military_status_other: "",
    emp_monk_status: "",
    emp_monk_status_ever: "",
    emp_housing_status: "",
    emp_address_no: "",
    emp_address_soi: "",
    emp_address_road: "",
    emp_address_subdistrict: "",
    emp_address_district: "",
    emp_address_province: "",
    emp_address_postcode: "",
    emp_address_tel: "",
    emp_mobile: "",
    emp_email: "",
    emp_father_name: "",
    emp_father_age: "",
    emp_father_job: "",
    emp_father_mobile: "",
    emp_mother_name: "",
    emp_mother_age: "",
    emp_mother_job: "",
    emp_mother_mobile: "",
    emp_relationship_name: "",
    emp_relationship_age: "",
    emp_relationship_job: "",
    emp_relationship_workplace: "",
    emp_relationship_position: "",
    emp_relationship_mobile: "",
    emp_chlidren_number: "",
    emp_sibling_number: "",
    emp_brother_number: "",
    emp_sister_number: "",
    emp_rank_children: "",
    emp_line_id: "",
    emp_nickname: "",
    emp_contract_name: "",
    emp_contract_relation: "",
    emp_contract_tel: "",
    emp_insurer_name: "",
    emp_insurer_relation: "",
    emp_insurer_tel: "",
    emp_sibling: [],
  };

  const getEmployeeHistory = async () => {
    /* if (mode.mode == "add"){
      const fup = getUser().fup;
      Swal.fire({
        title: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 1500,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios({
        method: "get",
        url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getCitizenById?id=" + fup,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (response.data.data === null) {
            Swal.fire({
              icon: "Error",
              text: "ไม่พบข้อมูลในประวัติพนักงาน",
              confirmButtonText: "ตกลง",
            });
          } else {
            window.location.href = "/Human_Resource/ข้อมูลพนักงาน/employee_history/read/" + response.data.data;
          }
        })
        .catch(function (error) {
          Swal.fire({
            icon: "Error",
            text: "ไม่พบข้อมูลในประวัติพนักงาน",
            confirmButtonText: "ตกลง",
          });
        });
    } */
    if (mode.mode == "read") {
      Swal.fire({
        title: "Loading...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 1500,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios({
        method: "get",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/hrManagement/getEmployeeHistory?id=" +
          getUser().fup,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (response.data.data === null) {
            Swal.fire({
              icon: "Error",
              text: "ไม่พบข้อมูลในประวัติพนักงาน",
            }).then(() => {
              // window.location.href = "/Human_Resource/ข้อมูลพนักงาน/employee_history/adde/" + id;
            });
          }
          let data = response.data.data.data;
          if (response.data.data.data === null) {
            console.log("No Data for HR");
          } else {
            setemployeeToAdd(data);
          }
          let data1Copy = response.data.data.data1;
          if (response.data.data.data1 === null) {
            console.log("resetData1");
            data1Copy = [resetData1];
          }
          let data4Copy = response.data.data.data4;
          if (response.data.data.data4 === null) {
            console.log("resetData4");
            data4Copy = [data4Clone];
          }
          let data5Copy = response.data.data.data5;
          if (response.data.data.data5 === null) {
            console.log("resetData5");
            data5Copy = [data5Clone];
          }
          let eduCopy = response.data.data.tableEducation;
          if (response.data.data.tableEducation === null) {
            console.log("resetEducation");
            eduCopy = [resetEducation];
          }
          let workCopy = response.data.data.tableWork;
          if (response.data.data.tableWork === null) {
            console.log("resetEmp_History_Work");
            workCopy = [resetEmp_History_Work];
          }
          let talentCopy = response.data.data.tableTalent;
          if (response.data.data.tableTalent === null) {
            console.log("resetEmp_talent");
            talentCopy = [resetEmp_talent];
          }
          setemployee({
            ...employee,
            emp_position: data1Copy[0].emp_position || "",
            emp_money_want: data1Copy[0].emp_money_want || "",
            emp_name_title_th: data1Copy[0].emp_name_title_th || "",
            emp_name_th: data1Copy[0].emp_name_th || "",
            emp_surname_th: data1Copy[0].emp_surname_th || "",
            emp_name_title_eng: data1Copy[0].emp_name_title_eng || "",
            emp_name_eng: data1Copy[0].emp_name_eng || "",
            emp_surname_eng: data1Copy[0].emp_surname_eng || "",
            emp_sex: data1Copy[0].emp_sex || "",
            emp_height: data1Copy[0].emp_height || "",
            emp_weight: data1Copy[0].emp_weight || "",
            emp_birthday:
              data1Copy[0].emp_birthday === ""
                ? ""
                : new Date(data1Copy[0].emp_birthday),
            emp_age:
              CalculateAge(
                data1Copy[0].emp_birthday === ""
                  ? ""
                  : new Date(data1Copy[0].emp_birthday)
              ) || "",
            emp_nationality: data1Copy[0].emp_nationality || "",
            emp_ethnicity: data1Copy[0].emp_ethnicity || "",
            emp_religion: data1Copy[0].emp_religion || "",
            emp_national_id: data1Copy[0].emp_national_id || "",
            emp_status: data1Copy[0].emp_status || "",
            emp_child_num: data1Copy[0].emp_child_num || "",
            emp_military_status: data1Copy[0].emp_military_status || "",
            emp_military_status_other:
              data1Copy[0].emp_military_status_other || "",
            emp_monk_status:
              data1Copy[0].emp_monk_status === ""
                ? ""
                : data1Copy[0].emp_monk_status === true
                ? "2"
                : "1",
            emp_monk_status_ever: data1Copy[0].emp_monk_status_ever || "",
            emp_housing_status: data1Copy[0].emp_housing_status || "",
            emp_address_no: data1Copy[0].emp_address_no || "",
            emp_address_soi: data1Copy[0].emp_address_soi || "",
            emp_address_road: data1Copy[0].emp_address_road || "",
            emp_address_subdistrict: data1Copy[0].emp_address_subdistrict || "",
            emp_address_district: data1Copy[0].emp_address_district || "",
            emp_address_province: data1Copy[0].emp_address_province || "",
            emp_address_postcode: data1Copy[0].emp_address_postcode || "",
            emp_address_tel: data1Copy[0].emp_address_tel || "",
            emp_mobile: data1Copy[0].emp_mobile || "",
            emp_email: data1Copy[0].emp_email || "",
            emp_father_name: data1Copy[0].emp_father_name || "",
            emp_father_age: data1Copy[0].emp_father_age || "",
            emp_father_job: data1Copy[0].emp_father_job || "",
            emp_father_mobile: data1Copy[0].emp_father_mobile || "",
            emp_mother_name: data1Copy[0].emp_mother_name || "",
            emp_mother_age: data1Copy[0].emp_mother_age || "",
            emp_mother_job: data1Copy[0].emp_mother_job || "",
            emp_mother_mobile: data1Copy[0].emp_mother_mobile || "",
            emp_relationship_name: data1Copy[0].emp_relationship_name || "",
            emp_relationship_age: data1Copy[0].emp_relationship_age || "",
            emp_relationship_job: data1Copy[0].emp_relationship_job || "",
            emp_relationship_workplace:
              data1Copy[0].emp_relationship_workplace || "",
            emp_relationship_position:
              data1Copy[0].emp_relationship_position || "",
            emp_relationship_mobile: data1Copy[0].emp_relationship_mobile || "",
            emp_chlidren_number: data1Copy[0].emp_chlidren_number || "",
            emp_sibling_number: data1Copy[0].emp_sibling_number || "",
            emp_brother_number: data1Copy[0].emp_brother_number || "",
            emp_sister_number: data1Copy[0].emp_sister_number || "",
            emp_rank_children: data1Copy[0].emp_rank_children || "",
            emp_line_id: data1Copy[0].emp_line_id || "",
            emp_nickname: data1Copy[0].emp_nickname || "",
            emp_contract_name: data1Copy[0].emp_contract_name || "",
            emp_contract_relation: data1Copy[0].emp_contract_relation || "",
            emp_contract_tel: data1Copy[0].emp_contract_tel || "",
            emp_insurer_name: data1Copy[0].emp_insurer_name || "",
            emp_insurer_relation: data1Copy[0].emp_insurer_relation || "",
            emp_insurer_tel: data1Copy[0].emp_insurer_tel || "",
            emp_image_path: data1Copy[0].emp_image_path || "",
            emp_image_name: data1Copy[0].emp_image_name || "",

            emp_was_union_member: data5Copy[0].emp_was_union_member
              ? data5Copy[0].emp_was_union_member
              : "",
            emp_was_ever_been_judged:
              data5Copy[0].emp_was_ever_been_judged === ""
                ? ""
                : data5Copy[0].emp_was_ever_been_judged === true
                ? "1"
                : "2",
            emp_was_ever_been_judged_because:
              data5Copy[0].emp_was_ever_been_judged_because || "",
            emp_employee_judged_id: data5Copy[0].emp_employee_judged_id,
            emp_reason_to_choose_work: data5Copy[0].emp_reason_to_choose_work,
            emp_reason_to_match_work: data5Copy[0].emp_reason_to_match_work,
            emp_feature_which_success: data5Copy[0].emp_feature_which_success,
            emp_has_ideal_work: data5Copy[0].emp_has_ideal_work,
            emp_target_next_three_year: data5Copy[0].emp_target_next_three_year,
            emp_recommender: data5Copy[0].emp_recommender,
            emp_relationship_1: data5Copy[0].emp_relationship_1,
            emp_know_person_in_work: data5Copy[0].emp_know_person_in_work,
            emp_relationship_2: data5Copy[0].emp_relationship_2,
            emp_can_over_time:
              data5Copy[0].emp_can_over_time === ""
                ? ""
                : data5Copy[0].emp_can_over_time === true
                ? "2"
                : "1",
            emp_can_over_time_because: data5Copy[0].emp_can_over_time_because,
            emp_can_over_time_choose: emp_over_time_choose_reverse(
              data5Copy[0].emp_can_over_time_choose
            ), /// edit
            emp_can_work_holiday:
              data5Copy[0].emp_can_work_holiday === ""
                ? ""
                : data5Copy[0].emp_can_work_holiday === true
                ? "2"
                : "1",
            emp_can_work_holiday_because:
              data5Copy[0].emp_can_work_holiday_because,
            emp_can_work_night:
              data5Copy[0].emp_can_work_night === ""
                ? ""
                : data5Copy[0].emp_can_work_nigh === true
                ? "2"
                : "1",
            emp_can_work_night_because: data5Copy[0].emp_can_work_night_because,
            emp_want_position: data5Copy[0].emp_want_position,
            emp_want_money_day: data5Copy[0].emp_want_money_day,
            emp_want_money_month: data5Copy[0].emp_want_money_month,
            emp_want_other: data5Copy[0].emp_want_other,
            emp_can_start_now:
              data5Copy[0].emp_can_start_now === ""
                ? ""
                : data5Copy[0].emp_can_start_now === true
                ? "1"
                : "2",
            emp_can_start_now_because:
              data5Copy[0].emp_can_start_now_because || "",
            emp_start_date:
              data5Copy[0].emp_start_date === ""
                ? ""
                : new Date(data5Copy[0].emp_start_date),
            emp_phone: data5Copy[0].emp_phone,
            emp_location: data5Copy[0].emp_location,

            emp_four_languge_TH: parseInt(
              data4Copy[0].emp_four_languge_th
                ? data4Copy[0].emp_four_languge_th
                : 0
            ),
            emp_four_languge_EN: parseInt(
              data4Copy[0].emp_four_languge_en
                ? data4Copy[0].emp_four_languge_en
                : 0
            ),
            emp_four_printer: data4Copy[0].emp_four_printer,
            emp_four_fax: data4Copy[0].emp_four_fax,
            emp_four_com: data4Copy[0].emp_four_com,
            emp_four_moto: data4Copy[0].emp_four_moto,
            emp_four_car: data4Copy[0].emp_four_car,
            emp_four_truck: data4Copy[0].emp_four_truck,
            emp_four_vehicle_choose:
              data4Copy[0].emp_four_vehicle_choose === ""
                ? ""
                : data4Copy[0].emp_four_vehicle_choose === false
                ? "2"
                : "1",
            emp_four_vehicle_which: data4Copy[0].emp_four_vehicle_which,
            emp_four_program: data4Copy[0].emp_four_program,
            emp_two_experience: data4Copy[0].emp_two_experience,
          });
          for (let i = 0; i < eduCopy.length; i++) {
            seteducation({
              emp_edu_level_name: eduCopy[i].emp_edu_level_name,
              emp_edu_level: eduCopy[i].emp_edu_level,
              emp_edu_major: eduCopy[i].emp_edu_major,
              emp_edu_school: eduCopy[i].emp_edu_school,
              emp_edu_start_date: eduCopy[i].emp_edu_start_date,
              emp_edu_end_date: eduCopy[i].emp_edu_end_date,
              emp_edu_gpa: eduCopy[i].emp_edu_gpa,
            });
            setCheckTable({ check: !checkTable.check });
          }

          for (let i = 0; i < workCopy.length; i++) {
            setEmp_History_Work({
              emp_work_type: workCopy[i].emp_work_type,
              emp_work_type_id: workCopy[i].emp_work_type_id,
              emp_work_name: workCopy[i].emp_work_name,
              emp_work_positon: workCopy[i].emp_work_positon,
              emp_work_start:
                workCopy[i].emp_work_start === ""
                  ? null
                  : new Date(workCopy[i].emp_work_start),
              emp_work_finish:
                workCopy[i].emp_work_finish === ""
                  ? null
                  : new Date(workCopy[i].emp_work_finish),
              emp_work_details: workCopy[i].emp_work_details,
              emp_work_leave: workCopy[i].emp_work_leave,
            });
            setCheckTable({ check: !checkTable.check });
          }

          for (let i = 0; i < talentCopy.length; i++) {
            setEmp_talent({
              emp_language: talentCopy[i].emp_language,
              // emp_experience: talentCopy[i].emp_experience,
              emp_honor: talentCopy[i].emp_honor,
              emp_project: talentCopy[i].emp_project,
              emp_read: talentCopy[i].emp_read,
              emp_read_name: talentCopy[i].emp_read_name,
              emp_write: talentCopy[i].emp_write,
              emp_write_name: talentCopy[i].emp_write_name,
              emp_speak: talentCopy[i].emp_speak,
              emp_speak_name: talentCopy[i].emp_speak_name,
              emp_listen: talentCopy[i].emp_listen,
              emp_listen_name: talentCopy[i].emp_listen_name,
            });
            setCheckTable({ check: !checkTable.check });
          }
          setImage({
            ...Image,
            status: false,
          });
          Swal.close();
        })
        .catch(function (error) {
          Swal.fire({
            icon: "warning",
            title: "กรุณากรอกข้อมูลให้ครบถ้วน",
            text: error.response.data.error.message,
          });
        });
    }
  };

  const [getEducationLevel, setgetEducationLevel] = useState([]);
  const [getAddressStatus, setGetAddressStatus] = useState([]);
  const [getMasterWorkType, setGetMasterWorkType] = useState([]);
  const [EditEducation, setEditEducation] = useState([]);
  const [editEmp, setEditEmp] = useState([]);
  const [getScoreNameEmp, setGetScoreNameEmp] = useState([]);

  const GetScoreNameEmp = async () => {
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getScoreNameEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setGetScoreNameEmp(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetMasterWorkType = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getMasterWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setGetMasterWorkType(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const filterEducationLevel = async () => {
    axios({
      method: "GET",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getEducationLevel",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setgetEducationLevel(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterAddressStatus = async () => {
    axios({
      method: "GET",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getAddressStatus",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data);
        setGetAddressStatus(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [getPersonStatus, setgetPersonStatus] = useState([]);
  const filterPersonStatus = async () => {
    axios({
      method: "GET",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getPersonStatus",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data);
        setgetPersonStatus(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [getSoldierStatus, setGetSoldierStatus] = useState([]);
  const filterSoldierStatus = async () => {
    axios({
      method: "GET",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getSoldierStatus",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data);
        setGetSoldierStatus(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [getReligion, setgetReligion] = useState([]);
  const filterReligion = async () => {
    axios({
      method: "GET",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getReligion",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data);
        setgetReligion(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [getGender, setgetGender] = useState([]);
  const filterNameGender = async () => {
    axios({
      method: "GET",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getGender",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data);
        setgetGender(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [getNameTitle, setgetNameTitle] = useState([]);
  const filterNameTitle = async () => {
    axios({
      method: "GET",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getNameTitle",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response.data);
        setgetNameTitle(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [getProvince, setgetProvince] = useState([]);
  const [Province, setProvince] = useState([]);
  const select_Province = useRef();
  const filterProvince = async () => {
    axios({
      method: "GET",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getProvince",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setgetProvince(response.data.data);
        const options = [{ value: "", label: "Select" }];
        response.data.data.forEach((el) => {
          let element = {};
          element["value"] = el.province_id;
          element["label"] = el.province_name;
          options.push(element);
        });
        setProvince(options);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [getNation, setgetNation] = useState([]);
  const filterNation = async () => {
    let data = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "POST",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getNation",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        // console.log(response.data);
        setgetNation(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function addAndEditTable_Garantor(checkData, dataPush) {
    if (closeModal.checkData === true) {
      return;
    } else if (!checkData && !modal.check) {
      if (
        dataPush.emp_garantor_name !== "" ||
        dataPush.emp_garantor_relation !== "" ||
        dataPush.emp_garantor_location_work !== ""
      ) {
        employee.emp_guarantor.push(dataPush);
        setEmp_garantor(resetEmp_garantor);
      }
    } else if (!checkData && modal.check) {
      const emp_garantor1 = employee.emp_guarantor;
      // console.log(emp_garantor);
      emp_garantor1[modal.index] = dataPush;
      setemployee({ ...employee, emp_guarantor: emp_garantor1 });
      employee.emp_guarantor = emp_garantor1;
      // console.log('bottom');
    }
    setModal({ check: false, index: -1 });
    setCloseModal({ checkData: false });
    closeModal.checkData = false;
    settest({ ...test, test: "" });
  }

  function addAndEditTable_Garantee(checkData, dataPush) {
    if (closeModal.checkData === true) {
      return;
    } else if (!checkData && !modal.check) {
      if (
        dataPush.emp_garantee_name !== "" ||
        dataPush.emp_garantee_relation !== "" ||
        dataPush.emp_garantee_location_work !== ""
      ) {
        employee.emp_guarantee.push(dataPush);
        setEmp_garantee(resetEmp_garantee);
      }
    } else if (!checkData && modal.check) {
      const emp_garantee1 = employee.emp_guarantee;
      emp_garantee1[modal.index] = dataPush;
      setemployee({ ...employee, emp_guarantee: emp_garantee1 });
      employee.emp_guarantee = emp_garantee1;
    }
    setModal({ check: false, index: -1 });
    setCloseModal({ checkData: false });
    closeModal.checkData = false;
    settest({ ...test, test: "" });
  }
  const [test, settest] = useState({
    test: "",
  });

  function addAndEditTable_Talent(checkData, dataPush) {
    if (closeModal.checkData === true) {
      return;
    } else if (!checkData && !modal.check) {
      if (
        dataPush.emp_language !== "" ||
        dataPush.emp_project !== "" ||
        dataPush.emp_honor !== ""
      ) {
        employee.emp_talen.push(dataPush);
        setEmp_talent(resetEmp_talent);
      }
    } else if (!checkData && modal.check) {
      const emp_talent1 = employee.emp_talen;
      emp_talent1[modal.index] = dataPush;
      setemployee({ ...employee, emp_talen: emp_talent1 });
      employee.emp_talen = emp_talent1;
    }
    setModal({ check: false, index: -1 });
    setCloseModal({ checkData: false });
    closeModal.checkData = false;
    settest({ ...test, test: "" });
  }

  function addAndEditTable_WorkHistory(checkData, dataPush) {
    if (closeModal.checkData === true) {
      return;
    } else if (!checkData && !modal.check) {
      if (
        dataPush.emp_work_name !== "" ||
        dataPush.emp_work_type !== "" ||
        dataPush.emp_work_details !== ""
      ) {
        employee.emp_work_history.push(dataPush);
        setEmp_History_Work(resetEmp_History_Work);
      }
    } else if (!checkData && modal.check) {
      const emp_work1 = employee.emp_work_history;
      emp_work1[modal.index] = dataPush;
      setemployee({ ...employee, emp_work_history: emp_work1 });
      employee.emp_work_history = emp_work1;
    }
    setModal({ check: false, index: -1 });
    setCloseModal({ checkData: false });
    closeModal.checkData = false;
    settest({ ...test, test: "" });
  }

  const [employee, setemployee] = useState({
    emp_position: "",
    emp_money_want: "",
    emp_name_title_th: "",
    emp_name_th: "",
    emp_surname_th: "",
    emp_name_title_eng: "",
    emp_name_eng: "",
    emp_surname_eng: "",
    emp_sex: "",
    emp_height: "",
    emp_weight: "",
    emp_birthday: "",
    emp_age: "",
    emp_nationality: "",
    emp_ethnicity: "",
    emp_religion: "",
    emp_national_id: "",
    emp_line_id: "",
    emp_nickname: "",
    emp_status: "",
    emp_child_num: "",
    emp_military_status: "",
    emp_military_status_other: "",
    emp_monk_status: "",
    emp_monk_status_ever: "",
    emp_housing_status: "",
    emp_address_no: "",
    emp_address_soi: "",
    emp_address_road: "",
    emp_address_subdistrict: "",
    emp_address_district: "",
    emp_address_province: "",
    emp_address_postcode: "",
    emp_address_tel: "",
    emp_mobile: "",
    emp_email: "",
    emp_father_name: "",
    emp_father_age: "",
    emp_father_job: "",
    emp_father_mobile: "",
    emp_mother_name: "",
    emp_mother_age: "",
    emp_mother_job: "",
    emp_mother_mobile: "",
    emp_relationship_name: "",
    emp_relationship_age: "",
    emp_relationship_job: "",
    emp_relationship_workplace: "",
    emp_relationship_position: "",
    emp_relationship_mobile: "",
    emp_chlidren_number: "",
    emp_sibling_number: "",
    emp_brother_number: "",
    emp_sister_number: "",
    emp_rank_children: "",
    emp_contract_name: "",
    emp_contract_relation: "",
    emp_contract_tel: "",
    emp_insurer_name: "",
    emp_insurer_relation: "",
    emp_insurer_tel: "",
    emp_sibling: [],
    // tap2
    emp_educationLevel: [],
    // tap 3
    emp_work_history: [],
    // tap4-5
    emp_was_union_member: "",
    emp_was_ever_been_judged: "",
    emp_was_ever_been_judged_because: "",
    emp_employee_judged_id: "",
    emp_reason_to_choose_work: "",
    emp_reason_to_match_work: "",
    emp_feature_which_success: "",
    emp_has_ideal_work: "",
    emp_target_next_three_year: "",
    emp_recommender: "",
    emp_relationship_1: "",
    emp_know_person_in_work: "",
    emp_relationship_2: "",
    emp_can_over_time: null,
    emp_can_over_time_because: "",
    emp_can_over_time_choose: "",
    emp_can_work_holiday: null,
    emp_can_work_holiday_because: "",
    emp_can_work_night: null,
    emp_can_work_night_because: "",
    emp_want_position: "",
    emp_want_money_day: "",
    emp_want_money_month: "",
    emp_want_other: "",
    emp_can_start_now: null,
    emp_can_start_now_because: "",
    emp_start_date: "",
    emp_phone: "",
    emp_location: "",
    emp_guarantee: [],
    emp_guarantor: [],
    emp_talen: [],
    emp_four_languge_TH: "",
    emp_four_languge_EN: "",
    emp_four_printer: false,
    emp_four_fax: false,
    emp_four_com: false,
    emp_four_moto: false,
    emp_four_car: false,
    emp_four_truck: false,
    emp_four_vehicle_choose: "",
    emp_four_vehicle_which: "",
    emp_four_program: "",
    emp_two_experience: "",
    emp_image_name: "",
    emp_image_path: "",
  });
  // console.log(employee);

  const [sibling, setsibling] = useState({
    emp_sibling_name: "",
    emp_sibling_age: "",
    emp_sibling_job: "",
    emp_sibling_mobile: "",
  });
  const resetSibling = {
    emp_sibling_name: "",
    emp_sibling_age: "",
    emp_sibling_job: "",
    emp_sibling_mobile: "",
  };

  // tap2
  const [education, seteducation] = useState({
    emp_edu_level_name: "",
    emp_edu_level: "",
    emp_edu_major: "",
    emp_edu_school: "",
    emp_edu_start_date: "",
    emp_edu_end_date: "",
    emp_edu_gpa: "",
  });
  const resetEducation = {
    emp_edu_level_name: "",
    emp_edu_level: "",
    emp_edu_major: "",
    emp_edu_school: "",
    emp_edu_start_date: "",
    emp_edu_end_date: "",
    emp_edu_gpa: "",
  };
  const rowsDataTap2 = [];

  for (let index = 0; index < employee.emp_educationLevel.length; index++) {
    const rowItem = {};
    rowItem["edu_level"] =
      employee.emp_educationLevel[index].emp_edu_level_name;
    rowItem["edu_major"] = employee.emp_educationLevel[index].emp_edu_major;
    rowItem["edu_school"] = employee.emp_educationLevel[index].emp_edu_school;
    rowItem["edu_start"] =
      employee.emp_educationLevel[index].emp_edu_start_date;
    rowItem["edu_end"] = employee.emp_educationLevel[index].emp_edu_end_date;
    rowItem["edu_grade"] = employee.emp_educationLevel[index].emp_edu_gpa;
    rowItem["mgt"] = (
      <div className="row">
        {mode.mode !== "read" ? (
          <>
            <div className="col-4">
              <a
                className=" btn btn-xs "
                data-toggle="modal"
                data-target="#modal-edu"
                onClick={() => {
                  setEditEducation(employee.emp_educationLevel[index]);
                  setModal({ check: true, index: index });
                }}
              >
                {" "}
                <i class="fas fa-pencil-alt"></i>{" "}
              </a>
            </div>
            <div className="col-4">
              <a
                // key={project[index].id}
                className=" btn btn-xs "
                onClick={() => {
                  const edu = employee.emp_educationLevel;
                  edu.splice(index, 1);
                  setemployee({ ...employee, emp_educationLevel: edu });
                }}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        ) : (
          " "
        )}
      </div>
    );

    rowsDataTap2.push(rowItem);
  }
  const educationalTable = {
    columns: [
      {
        label: "ระดับการศึกษา",
        field: "edu_level",
        sort: "asc",
        width: 50,
      },
      {
        label: "สาขาวิชา",
        field: "edu_major",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานศึกษา",
        field: "edu_school",
        sort: "asc",
        width: 50,
      },
      {
        label: "ปีที่เริ่ม",
        field: "edu_start",
        sort: "asc",
        width: 50,
      },

      {
        label: "ปีที่จบ",
        field: "edu_end",
        sort: "asc",
        width: 50,
      },
      {
        label: "เกรดเฉลี่ย",
        field: "edu_grade",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDataTap2, //ยังไม่ได้ใส่ข้อมูล
  };
  // tap 3 ****************************************************

  // tap3
  const [emp_history_work, setEmp_History_Work] = useState({
    emp_work_type: "",
    emp_work_type_id: "",
    emp_work_name: "",
    emp_work_positon: "",
    emp_work_start: "",
    emp_work_finish: "",
    emp_work_details: "",
    emp_work_leave: "",
  });
  const resetEmp_History_Work = {
    emp_work_type: "",
    emp_work_type_id: "",
    emp_work_name: "",
    emp_work_positon: "",
    emp_work_start: "",
    emp_work_finish: "",
    emp_work_details: "",
    emp_work_leave: "",
  };

  const rowsDataTap3 = [];

  for (let index = 0; index < employee.emp_work_history.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["work_type"] = employee.emp_work_history[index].emp_work_type;
    rowItem["name_work"] = employee.emp_work_history[index].emp_work_name;
    rowItem["position_work"] =
      employee.emp_work_history[index].emp_work_positon;
    rowItem["start_work"] = moment(
      employee.emp_work_history[index].emp_work_start
    ).format("MM/YYYY");
    rowItem["finish_work"] = moment(
      employee.emp_work_history[index].emp_work_finish
    ).format("MM/YYYY");
    rowItem["work_details"] = employee.emp_work_history[index].emp_work_details;
    rowItem["reason_leaving"] = employee.emp_work_history[index].emp_work_leave;
    rowItem["mgt"] = (
      <div className="row">
        {mode.mode !== "read" ? (
          <>
            <div className="col-4">
              <a
                className=" btn btn-xs "
                data-toggle="modal"
                data-target="#modal-tab-3"
                onClick={() => {
                  setEditEmp(employee.emp_work_history[index]);
                  setModal({ check: true, index: index });
                }}
              >
                {" "}
                <i class="fas fa-pencil-alt"></i>{" "}
              </a>
            </div>
            <div className="col-4">
              <a
                className=" btn btn-xs"
                onClick={() => {
                  const emp_work = employee.emp_work_history;
                  emp_work.splice(index, 1);
                  setemployee({ ...employee, emp_work_history: emp_work });
                  employee.emp_work_history = emp_work;
                }}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        ) : (
          " "
        )}
      </div>
    );
    rowsDataTap3.push(rowItem);
  }

  const Tables_Emp_Work = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "รูปแบบงาน",
        field: "work_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อที่ทำงาน และสถานที่ตั้ง",
        field: "name_work",
        sort: "asc",
        width: 50,
      },
      {
        label: "ตำแหน่งเริ่มต้น",
        field: "position_work",
        sort: "asc",
        width: 50,
      },
      {
        label: "เริ่มงาน เดือน / พ.ศ.",
        field: "start_work",
        sort: "asc",
        width: 50,
      },
      {
        label: "ออกจากงาน เดือน / พ.ศ.",
        field: "finish_work",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลักษณะงานที่ทำ",
        field: "work_details",
        sort: "asc",
        width: 50,
      },
      {
        label: "สาเหตุที่ออก",
        field: "reason_leaving",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDataTap3,
  };

  // tap 4-5 *****************************************************

  const resetEmp_garantor = {
    emp_garantor_name: "",
    emp_garantor_relation: "",
    emp_garantor_location_work: "",
    emp_garantor_position: "",
    emp_garant_phone: "",
  };

  const resetEmp_garantee = {
    emp_garantee_name: "",
    emp_garantee_relation: "",
    emp_garantee_location_work: "",
    emp_garantee_position: "",
    emp_garantee_phone: "",
  };

  const resetEmp_talent = {
    emp_language: "",
    // emp_experience: "",
    emp_honor: "",
    emp_project: "",
    emp_read: "",
    emp_read_name: "",
    emp_write: "",
    emp_write_name: "",
    emp_speak: "",
    emp_speak_name: "",
    emp_listen: "",
    emp_listen_name: "",
  };

  const [employee_can_over_time, setemployee_can_over_time] = useState([]);
  const [employee_can_over_time_choose, setemployee_can_over_time_choose] =
    useState([]);
  const [employee_can_start_now, setemployee_can_start_now] = useState([]);
  const [emp_garantee, setEmp_garantee] = useState({
    emp_garantee_name: "",
    emp_garantee_relation: "",
    emp_garantee_location_work: "",
    emp_garantee_position: "",
    emp_garantee_phone: "",
  });

  const [emp_talent, setEmp_talent] = useState({
    emp_language: "",
    // emp_experience: "",
    emp_honor: "",
    emp_project: "",
    emp_read: "",
    emp_read_name: "",
    emp_write: "",
    emp_write_name: "",
    emp_speak: "",
    emp_speak_name: "",
    emp_listen: "",
    emp_listen_name: "",
  });
  const [checkedPrint, setCheckedPrint] = useState(false);
  const [checkedFax, setCheckedFax] = useState(false);
  const [checkedCom, setCheckedCom] = useState(false);
  const [checkedMoto, setCheckedMoto] = useState(false);
  const [checkedCar, setCheckedCar] = useState(false);
  const [checkedTruck, setCheckedTruck] = useState(false);

  const [emp_garantor, setEmp_garantor] = useState({
    emp_garantor_name: "",
    emp_garantor_relation: "",
    emp_garantor_location_work: "",
    emp_garantor_position: "",
    emp_garant_phone: "",
  });

  const emp_over_time_choose = () => {
    if (employee.emp_can_over_time_choose == 1)
      return "วันละ 2 ชั่วโมง สัปดาห์ละ 6 วัน รวม 12 ชั่วโมง";
    else if (employee.emp_can_over_time_choose == 2)
      return "คนละ 3 ชั่วโมง สัปดาห์ละ 6 วัน รวม 18 ชั่วโมง";
    else return "คนละ 4 ชั่วโมง สัปดาห์ละ 6 วัน รวม 24 ชั่วโมง";
  };

  const emp_over_time_choose_reverse = (target) => {
    if (target === "") return "";
    if (target == "วันละ 2 ชั่วโมง สัปดาห์ละ 6 วัน รวม 12 ชั่วโมง") return 1;
    else if (target == "คนละ 3 ชั่วโมง สัปดาห์ละ 6 วัน รวม 18 ชั่วโมง")
      return 2;
    else return 3;
  };

  // end tap 4-5

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  // table Tap 4- 5
  const rowsDataPage4 = [];

  for (let index = 0; index < employee.emp_talen.length; index++) {
    const rowItem = {};
    rowItem["language"] = employee.emp_talen[index].emp_language;
    rowItem["write"] = employee.emp_talen[index].emp_write_name;
    rowItem["read"] = employee.emp_talen[index].emp_read_name;
    rowItem["speak"] = employee.emp_talen[index].emp_speak_name;
    rowItem["project"] = employee.emp_talen[index].emp_project;
    rowItem["honor"] = employee.emp_talen[index].emp_honor;
    rowItem["listen"] = employee.emp_talen[index].emp_listen_name;
    rowItem["manage"] = (
      <div className="row">
        {mode.mode !== "read" ? (
          <>
            <div className="col-4">
              <a
                data-toggle="modal"
                data-target="#modal-tab-2"
                className=" btn btn-xs "
                onClick={() => {
                  setEditEmp(employee.emp_talen[index]);
                  setModal({ check: true, index: index });
                }}
              >
                {" "}
                <i class="fas fa-pencil-alt"></i>{" "}
              </a>
            </div>
            <div className="col-4">
              <a
                //  key={emp_garantee[index].emp_garantee_name}
                className=" btn btn-xs "
                onClick={(e) => {
                  // console.log(employee.emp_talen[index]);
                  const emp_talen = employee.emp_talen;
                  emp_talen.splice(index, 1);
                  setemployee({ ...employee, emp_talen: emp_talen });
                  employee.emp_talen = emp_talen;
                  // console.log('rowsDataPage4', rowsDataPage4)
                }}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        ) : (
          " "
        )}
      </div>
    );
    // console.log('table4' + rowItem);
    rowsDataPage4.push(rowItem);
  }

  const TableEmployeeTap4 = {
    columns: [
      {
        label: "ภาษาต่างประเทศ",
        field: "language",
        sort: "asc",
        width: 50,
      },
      {
        label: "เขียน",
        field: "write",
        sort: "asc",
        width: 50,
      },
      {
        label: "อ่าน",
        field: "read",
        sort: "asc",
        width: 50,
      },

      {
        label: "พูด",
        field: "speak",
        sort: "asc",
        width: 50,
      },
      {
        label: "ฟัง",
        field: "listen",
        sort: "asc",
        width: 10,
      },
      {
        label: "โครงการ และผลงานอื่น ๆ",
        field: "project",
        sort: "asc",
        width: 50,
      },
      {
        label: "เกียรติประวัติ/ประกาศนียบัตร",
        field: "honor",
        sort: "asc",
        width: 10,
      },
      {
        label: "จัดการ",
        field: "manage",
        sort: "asc",
        width: 10,
      },
    ],
    rows: rowsDataPage4,
  };

  // end table tap 4-5
  function saveOption(params) {
    const error_list = [];
    if (employee.emp_position == "") {
      let temp_err = {
        message: "กรุณากรอก ตำแหน่ง",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_money_want < 0) {
      let temp_err = {
        message: "กรุณากรอก เงินเดือนที่ต้องการ",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_name_title_th == "") {
      let temp_err = {
        message: "กรุณาเลือก คำนำหน้า",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_name_th == "") {
      let temp_err = {
        message: "กรุณากรอก ชื่อ (ไทย)",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_surname_th == "") {
      let temp_err = {
        message: "กรุณากรอก นามสกุล (ไทย)",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_name_title_eng == "") {
      let temp_err = {
        message: "กรุณาเลือก คำนำหน้า (อังกฤษ)",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_name_eng == "") {
      let temp_err = {
        message: "กรุณากรอก ชื่อ (อังกฤษ)",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_surname_eng == "") {
      let temp_err = {
        message: "กรุณากรอก นามสกุล (อังกฤษ)",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_sex == "") {
      let temp_err = {
        message: "กรุณาเลือก เพศสภาพ",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_height == "") {
      let temp_err = {
        message: "กรุณากรอก ส่วนสูง (เซนติเมตร)",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_weight < 0) {
      let temp_err = {
        message: "กรุณากรอก น้ำหนัก (กิโลกรัม)",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_birthday == "") {
      let temp_err = {
        message: "กรุณาเลือก วันเกิด",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_age < 0) {
      let temp_err = {
        message: "กรุณากรอก อายุ",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_religion == "") {
      let temp_err = {
        message: "กรุณากรอก ศาสนา",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_national_id == "") {
      let temp_err = {
        message: "กรุณากรอก เลขบัตรประชาชน",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_status === "") {
      let temp_err = {
        message: "กรุณาเลือก สถานภาพ",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_sex !== "976355c6-8c93-4341-a531-ba5c6e5478a5") {
      if (employee.emp_military_status == "") {
        let temp_err = {
          message: "กรุณาเลือก ภาวะทางทหาร",
        };
        error_list.push(temp_err);
      }
      if (
        employee.emp_military_status ==
          "18ca6e1d-d1f4-4a69-8d42-54c58c831243" &&
        employee.emp_military_status_other == ""
      ) {
        let temp_err = {
          message: "กรุณากรอก ภาวะทางทหาร(อื่นๆ)",
        };
        error_list.push(temp_err);
      }
      if (employee.emp_monk_status == "") {
        let temp_err = {
          message: "กรุณาเลือก เคยอุปสมบทหรือไม่",
        };
        error_list.push(temp_err);
      }
      if (
        employee.emp_monk_status == "2" &&
        employee.emp_monk_status_ever == ""
      ) {
        let temp_err = {
          message: "กรุณากรอก ระยะเวลาการอุปสมบท",
        };
        error_list.push(temp_err);
      }
    }
    if (employee.emp_address_no == "") {
      let temp_err = {
        message: "กรุณากรอก เลขที่",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_address_subdistrict == "") {
      let temp_err = {
        message: "กรุณากรอก ตำบล/แขวง",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_address_district == "") {
      let temp_err = {
        message: "กรุณากรอก อำเภอ/เขต",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_address_province == "") {
      let temp_err = {
        message: "กรุณากรอก จังหวัด",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_address_postcode == "") {
      let temp_err = {
        message: "กรุณากรอก รหัสไปรษณีย์",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_mobile == "") {
      let temp_err = {
        message: "กรุณากรอก เบอร์มือถือ",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_email == "") {
      let temp_err = {
        message: "กรุณากรอก E-mail",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_sibling_number == "") {
      let temp_err = {
        message: "กรุณากรอก จำนวนพี่น้อง",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_rank_children == "") {
      let temp_err = {
        message: "กรุณากรอก เป็นบุตรคนที่",
      };
      error_list.push(temp_err);
    }
    // tab 2
    if (employee.emp_educationLevel.length === 0) {
      let temp_err = {
        message: "กรุณาเพิ่มข้อมูล ระดับการศึกษา อย่างน้อย 1 ระดับ",
      };
      error_list.push(temp_err);
    }
    // tab 3
    if (employee.emp_work_history.length === 0) {
      let temp_err = {
        message: "กรุณาเพิ่มข้อมูล การฝึกงานหรือการทำงาน อย่างน้อย 1 รายการ",
      };
      error_list.push(temp_err);
    }
    // tap 4-5
    if (employee.emp_was_ever_been_judged === "") {
      let temp_err = {
        message: "กรุณาเลือกตัวเลือก ที่ให้ไว้ในช่อง",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_can_over_time === null) {
      let temp_err = {
        message: "กรุณาเลือก ว่าทำงานล่วงเวลาได้หรือไม่",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_can_over_time === "2") {
      if (employee.emp_can_over_time_choose == "") {
        let temp_err = {
          message: "กรุณาเลือก ว่าทำงานล่วงเวลาได้ไม่เกินเท่าไหร่",
        };
        error_list.push(temp_err);
      }
    }
    if (employee.emp_can_start_now === null) {
      let temp_err = {
        message: "กรุณาเลือก ว่าท่านสามารถเริ่มงานได้เลยหรือไม่",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_start_date == "") {
      let temp_err = {
        message: "กรุณากรอก ว่าท่านสามารถเริ่มงานได้วันที่",
      };
      error_list.push(temp_err);
    }
    if (employee.emp_phone == "" || employee.emp_location == "") {
      let temp_err = {
        message: "กรุณากรอก เบอร์โทรศัพท์ และสถานที่ที่สะดวกในการติดต่อ",
      };
      error_list.push(temp_err);
    }
    if (checkDataSame_Garantee(employee) > 0) {
      let temp_err = {
        message: "ชื่อข้อมูลในตาราง ผู้รับรองซ้ำกัน",
      };
      error_list.push(temp_err);
    }
    if (checkDataSame_Garantor(employee) > 0) {
      let temp_err = {
        message: "ชื่อข้อมูลในตาราง ผู้ค้ำประกันซ้ำกัน",
      };
      error_list.push(temp_err);
    }
    if (checkDataSame_Talent(employee) > 0) {
      let temp_err = {
        message: "ชื่อข้อมูลในตาราง ภาษาซ้ำกัน",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        console.log(mode.mode);
        const temp = {
          emp_position: employee.emp_position.trim(),
          emp_money_want: parseInt(employee.emp_money_want.replace(",", "")),
          emp_name_title_th: employee.emp_name_title_th,
          emp_name_th: employee.emp_name_th.trim(),
          emp_surname_th: employee.emp_surname_th.trim(),
          emp_name_title_eng: employee.emp_name_title_eng,
          emp_name_eng: employee.emp_name_eng.trim(),
          emp_surname_eng: employee.emp_surname_eng.trim(),
          emp_sex: employee.emp_sex,
          emp_height: parseInt(employee.emp_height),
          emp_weight: parseInt(employee.emp_weight),
          emp_birthday: employee.emp_birthday,
          emp_age: employee.emp_age,
          emp_nationality: employee.emp_nationality.trim(),
          emp_ethnicity: employee.emp_ethnicity.trim(),
          emp_religion: employee.emp_religion.trim(),
          emp_national_id: employee.emp_national_id.trim(),
          emp_line_id: employee.emp_line_id.trim(),
          emp_nickname: employee.emp_nickname.trim(),
          emp_status: employee.emp_status,
          emp_child_num: parseInt(employee.emp_child_num),
          emp_military_status: employee.emp_military_status,
          emp_military_status_other: employee.emp_military_status_other.trim(),
          emp_monk_status: employee.emp_monk_status == "2" ? true : false,
          emp_monk_status_ever: employee.emp_monk_status_ever.trim(),
          emp_housing_status: employee.emp_housing_status,
          emp_address_no: employee.emp_address_no.trim(),
          emp_address_soi: employee.emp_address_soi.trim(),
          emp_address_road: employee.emp_address_road.trim(),
          emp_address_subdistrict: employee.emp_address_subdistrict.trim(),
          emp_address_district: employee.emp_address_district.trim(),
          emp_address_province: employee.emp_address_province.trim(),
          emp_address_postcode: parseInt(employee.emp_address_postcode),
          emp_address_tel: employee.emp_address_tel.trim(),
          emp_mobile: employee.emp_mobile.trim(),
          emp_email: employee.emp_email.trim(),
          emp_father_name: employee.emp_father_name.trim(),
          emp_father_age: parseInt(employee.emp_father_age),
          emp_father_job: employee.emp_father_job.trim(),
          emp_father_mobile: employee.emp_father_mobile.trim(),
          emp_mother_name: employee.emp_mother_name.trim(),
          emp_mother_age: parseInt(employee.emp_mother_age),
          emp_mother_job: employee.emp_mother_job.trim(),
          emp_mother_mobile: employee.emp_mother_mobile.trim(),
          emp_relationship_name: employee.emp_relationship_name.trim() || null,
          emp_relationship_age: employee.emp_relationship_age.trim() || null,
          emp_relationship_job: employee.emp_relationship_job.trim() || null,
          emp_relationship_workplace:
            employee.emp_relationship_workplace.trim() || null,
          emp_relationship_position:
            employee.emp_relationship_position.trim() || null,
          emp_relationship_mobile:
            employee.emp_relationship_mobile.trim() || null,
          emp_chlidren_number: employee.emp_chlidren_number.trim() || null,
          emp_sibling_number: parseInt(employee.emp_sibling_number) || null,
          emp_brother_number: parseInt(employee.emp_brother_number) || null,
          emp_sister_number: parseInt(employee.emp_sister_number) || null,
          emp_rank_children: parseInt(employee.emp_rank_children) || null,
          emp_contract_name: employee.emp_contract_name.trim() || null,
          emp_contract_tel: employee.emp_contract_tel || null,
          emp_contract_relation: employee.emp_contract_relation.trim() || null,
          emp_insurer_name: employee.emp_insurer_name.trim() || null,
          emp_insurer_tel: employee.emp_insurer_tel || null,
          emp_insurer_relation: employee.emp_insurer_relation.trim() || null,
          emp_sibling: employee.emp_sibling,

          // tap2
          emp_educationLevel: employee.emp_educationLevel,

          // tap3
          emp_history_work: employee.emp_work_history,

          // tap 4-5
          emp_was_union_member: employee.emp_was_union_member.trim() || null,
          emp_was_ever_been_judged:
            employee.emp_was_ever_been_judged == 1 ? true : false,
          emp_was_ever_been_judged_because:
            employee.emp_was_ever_been_judged_because.trim() || null,
          emp_reason_to_choose_work:
            employee.emp_reason_to_choose_work.trim() || null,
          emp_reason_to_match_work:
            employee.emp_reason_to_match_work.trim() || null,
          emp_feature_which_success:
            employee.emp_feature_which_success.trim() || null,
          emp_has_ideal_work: employee.emp_has_ideal_work.trim() || null,
          emp_target_next_three_year:
            employee.emp_target_next_three_year.trim() || null,
          emp_recommender: employee.emp_recommender.trim() || null,
          emp_relationship_1: employee.emp_relationship_1.trim() || null,
          emp_know_person_in_work:
            employee.emp_know_person_in_work.trim() || null,
          emp_relationship_2: employee.emp_relationship_2.trim() || null,
          emp_can_over_time: employee.emp_can_over_time == 2 ? true : false,
          emp_can_over_time_because: employee.emp_can_over_time_because || null,
          emp_can_over_time_choose: emp_over_time_choose() || null,
          emp_can_work_holiday:
            employee.emp_can_work_holiday == 2 ? true : false,
          emp_can_work_holiday_because:
            employee.emp_can_work_holiday_because || null,
          emp_can_work_night: employee.emp_can_work_night == 2 ? true : false,
          emp_can_work_night_because:
            employee.emp_can_work_night_because.trim() || null,
          emp_want_position: employee.emp_want_position.trim() || null,
          emp_want_money_day:
            parseInt(employee.emp_want_money_day.replace(",", "")) || null,
          emp_want_money_month:
            parseInt(employee.emp_want_money_month.replace(",", "")) || null,
          emp_want_other: employee.emp_want_other.trim() || null,
          emp_can_start_now: employee.emp_can_start_now == 1 ? true : false,
          emp_can_start_now_because:
            employee.emp_can_start_now_because.trim() || null,
          emp_start_date: employee.emp_start_date || null,
          emp_phone: employee.emp_phone || null,
          emp_location: employee.emp_location.trim() || null,
          emp_guarantee: employee.emp_guarantee || null,
          emp_guarantor: employee.emp_guarantor || null,
          emp_talen: employee.emp_talen || null,
          emp_four_languge_TH: employee.emp_four_languge_TH || null,
          emp_four_languge_EN: employee.emp_four_languge_EN || null,
          emp_four_printer: employee.emp_four_printer,
          emp_four_fax: employee.emp_four_fax,
          emp_four_com: employee.emp_four_com,
          emp_four_moto: employee.emp_four_moto,
          emp_four_car: employee.emp_four_car,
          emp_four_truck: employee.emp_four_truck,
          emp_four_vehicle_choose:
            employee.emp_four_vehicle_choose == 2 ? false : true,
          emp_four_vehicle_which: employee.emp_four_vehicle_which || null,
          emp_four_program: employee.emp_four_program || null,
          emp_four_experience: employee.emp_two_experience || null,
          emp_login_user_fup: getUser().fup,
          emp_company_id: getUser().com,
          emp_oem_id: getOem(),
          // end tap 4-5
        };
        Swal.fire({
          title: "ต้องการที่จะบันทึกข้อมูล ใช่หรือไม่",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: `ใช่`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `ไม่`,
        })
          .then(() => {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1500,
            });
          })
          .then((result) => {
            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/hrManagement/saveEmployeeAlltap",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(function (response) {
                if (response.data) {
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {});
                  window.location.href =
                    "/Human_Resource/ข้อมูลพนักงาน/Employee";
                }
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกข้อมูลใหม่",
                  text: error,
                });
              });
          });
      } else if (mode.mode === "edit") {
        console.log("edit");
        const temp1 = {
          emp_id: id,
          emp_login_user_fup: getUser().fup,
          emp_company_id: getUser().com,
          emp_oem_id: getOem(),
          emp_position: employee.emp_position.trim() || null,
          emp_money_want:
            parseInt(employee.emp_money_want.replace(",", "")) || null,
          emp_name_title_th: employee.emp_name_title_th || null,
          emp_name_th: employee.emp_name_th.trim() || null,
          emp_surname_th: employee.emp_surname_th.trim() || null,
          emp_name_title_eng: employee.emp_name_title_eng || null,
          emp_name_eng: employee.emp_name_eng.trim() || null,
          emp_surname_eng: employee.emp_surname_eng.trim() || null,
          emp_sex: employee.emp_sex || null,
          emp_height: parseInt(employee.emp_height) || null,
          emp_weight: parseInt(employee.emp_weight) || null,
          emp_birthday: employee.emp_birthday || null,
          emp_age: employee.emp_age || null,
          emp_nationality: employee.emp_nationality || null,
          emp_ethnicity:
            employee.emp_ethnicity === null
              ? null
              : employee.emp_ethnicity.trim(),
          emp_religion: employee.emp_religion || null,
          emp_national_id: employee.emp_national_id || null,
          emp_line_id: employee.emp_line_id || null,
          emp_nickname: employee.emp_nickname || null,
          emp_status: employee.emp_status || null,
          emp_child_num: parseInt(employee.emp_child_num) || null,
          emp_military_status: employee.emp_military_status || null,
          emp_military_status_other:
            employee.emp_military_status_other == null
              ? null
              : employee.emp_military_status_other.trim(),
          emp_monk_status: employee.emp_monk_status == "2" ? true : false,
          emp_monk_status_ever: employee.emp_monk_status_ever || null,
          emp_housing_status: employee.emp_housing_status || null,
          emp_address_no: employee.emp_address_no.trim() || null,
          emp_address_soi:
            employee.emp_address_soi === null
              ? null
              : employee.emp_address_soi.trim(),
          emp_address_road:
            employee.emp_address_road === null
              ? null
              : employee.emp_address_road.trim(),
          emp_address_subdistrict:
            employee.emp_address_subdistrict === null
              ? null
              : employee.emp_address_subdistrict.trim(),
          emp_address_district:
            employee.emp_address_district === null
              ? null
              : employee.emp_address_district.trim(),
          emp_address_province: employee.emp_address_province || null,
          emp_address_postcode: employee.emp_address_postcode || null,
          emp_address_tel:
            employee.emp_address_tel === null ? null : employee.emp_address_tel,
          emp_mobile: employee.emp_mobile || null,
          emp_email: employee.emp_email.trim() || null,
          emp_father_name:
            employee.emp_father_name === null
              ? null
              : employee.emp_father_name.trim(),
          emp_father_age: parseInt(employee.emp_father_age) || null,
          emp_father_job:
            employee.emp_father_job === null
              ? null
              : employee.emp_father_job.trim(),
          emp_father_mobile: employee.emp_father_mobile || null,
          emp_mother_name:
            employee.emp_mother_name === null
              ? null
              : employee.emp_mother_name.trim(),
          emp_mother_age: parseInt(employee.emp_mother_age) || null,
          emp_mother_job:
            employee.emp_mother_job === null
              ? null
              : employee.emp_mother_job.trim(),
          emp_mother_mobile:
            employee.emp_mother_mobile === null
              ? null
              : employee.emp_mother_mobile,
          emp_relationship_name:
            employee.emp_relationship_name == null
              ? null
              : employee.emp_relationship_name.trim(),
          emp_relationship_age:
            employee.emp_relationship_age == null
              ? null
              : employee.emp_relationship_age,
          emp_relationship_job:
            employee.emp_relationship_job == null
              ? null
              : employee.emp_relationship_job.trim(),
          emp_relationship_workplace:
            employee.emp_relationship_workplace == null
              ? null
              : employee.emp_relationship_workplace.trim(),
          emp_relationship_position:
            employee.emp_relationship_position == null
              ? null
              : employee.emp_relationship_position.trim(),
          emp_relationship_mobile:
            employee.emp_relationship_mobile == null
              ? null
              : employee.emp_relationship_mobile,
          emp_chlidren_number:
            employee.emp_chlidren_number == null
              ? null
              : employee.emp_chlidren_number,
          emp_sibling_number:
            employee.emp_sibling_number == null
              ? null
              : parseInt(employee.emp_sibling_number),
          emp_brother_number:
            employee.emp_brother_number == null
              ? null
              : parseInt(employee.emp_brother_number),
          emp_sister_number:
            employee.emp_sister_number == null
              ? null
              : parseInt(employee.emp_sister_number),
          emp_rank_children:
            employee.emp_rank_children == null
              ? null
              : parseInt(employee.emp_rank_children),
          emp_sibling: employee.emp_sibling,
          emp_contract_name:
            employee.emp_contract_name == null
              ? null
              : employee.emp_contract_name.trim(),
          emp_contract_tel:
            employee.emp_contract_tel == null
              ? null
              : employee.emp_contract_tel,
          emp_contract_relation:
            employee.emp_contract_relation == null
              ? null
              : employee.emp_contract_relation.trim(),
          emp_insurer_name:
            employee.emp_insurer_name == null
              ? null
              : employee.emp_insurer_name.trim(),
          emp_insurer_tel:
            employee.emp_insurer_tel == null ? null : employee.emp_insurer_tel,
          emp_insurer_relation:
            employee.emp_insurer_relation == null
              ? null
              : employee.emp_insurer_relation.trim(),
          emp_image_name: employee.emp_image_name || null, //////////////////////
          emp_image_path: employee.emp_image_path || null, ///////////////////////

          // tap2
          emp_educationLevel: employee.emp_educationLevel,

          // tap3
          emp_history_work: employee.emp_work_history,

          // tap 4-5
          emp_was_union_member:
            employee.emp_was_union_member == null
              ? null
              : employee.emp_was_union_member.trim(),
          emp_was_ever_been_judged:
            employee.emp_was_ever_been_judged == 1 ? true : false,
          emp_was_ever_been_judged_because:
            employee.emp_was_ever_been_judged_because == null
              ? null
              : employee.emp_was_ever_been_judged_because.trim(),
          emp_reason_to_choose_work:
            employee.emp_reason_to_choose_work == null
              ? null
              : employee.emp_reason_to_choose_work.trim(),
          emp_reason_to_match_work:
            employee.emp_reason_to_match_work == null
              ? null
              : employee.emp_reason_to_match_work.trim(),
          emp_feature_which_success:
            employee.emp_feature_which_success == null
              ? null
              : employee.emp_feature_which_success.trim(),
          emp_has_ideal_work:
            employee.emp_has_ideal_work == null
              ? null
              : employee.emp_has_ideal_work.trim(),
          emp_target_next_three_year:
            employee.emp_target_next_three_year == null
              ? null
              : employee.emp_target_next_three_year.trim(),
          emp_recommender:
            employee.emp_recommender == null
              ? null
              : employee.emp_recommender.trim(),
          emp_relationship_1:
            employee.emp_relationship_1 == null
              ? null
              : employee.emp_relationship_1.trim(),
          emp_know_person_in_work:
            employee.emp_know_person_in_work == null
              ? null
              : employee.emp_know_person_in_work.trim(),
          emp_relationship_2:
            employee.emp_relationship_2 == null
              ? null
              : employee.emp_relationship_2.trim(),
          emp_can_over_time: employee.emp_can_over_time == 2 ? true : false,
          emp_can_over_time_because:
            employee.emp_can_over_time_because == null
              ? null
              : employee.emp_can_over_time_because.trim(),
          emp_can_over_time_choose: emp_over_time_choose() || null,
          emp_can_work_holiday:
            employee.emp_can_work_holiday == 2 ? true : false,
          emp_can_work_holiday_because:
            employee.emp_can_work_holiday_because == null
              ? null
              : employee.emp_can_work_holiday_because.trim(),
          emp_can_work_night: employee.emp_can_work_night == 2 ? true : false,
          emp_can_work_night_because:
            employee.emp_can_work_night_because == null
              ? null
              : employee.emp_can_work_night_because.trim(),
          emp_want_position:
            employee.emp_want_position == null
              ? null
              : employee.emp_want_position.trim(),
          emp_want_money_day:
            employee.emp_want_money_day == null
              ? null
              : parseInt(employee.emp_want_money_day.replace(",", "")),
          emp_want_money_month:
            employee.emp_want_money_month == null
              ? null
              : parseInt(employee.emp_want_money_month.replace(",", "")),
          emp_want_other:
            employee.emp_want_other == null
              ? null
              : employee.emp_want_other.trim(),
          emp_can_start_now: employee.emp_can_start_now == 1 ? true : false,
          emp_can_start_now_because:
            employee.emp_can_start_now_because == null
              ? null
              : employee.emp_can_start_now_because.trim() || null,
          emp_start_date: employee.emp_start_date || null,
          emp_phone: employee.emp_phone || null,
          emp_location: employee.emp_location.trim() || null,
          emp_guarantee: employee.emp_guarantee || null,
          emp_guarantor: employee.emp_guarantor || null,
          emp_talen: employee.emp_talen || null,
          emp_four_languge_TH: employee.emp_four_languge_TH || null,
          emp_four_languge_EN: employee.emp_four_languge_EN || null,
          emp_four_printer: employee.emp_four_printer,
          emp_four_fax: employee.emp_four_fax,
          emp_four_com: employee.emp_four_com,
          emp_four_moto: employee.emp_four_moto,
          emp_four_car: employee.emp_four_car,
          emp_four_truck: employee.emp_four_truck,
          emp_four_vehicle_choose:
            employee.emp_four_vehicle_choose == 2 ? false : true,
          emp_four_vehicle_which: employee.emp_four_vehicle_which || null,
          emp_four_program:
            employee.emp_four_program === null
              ? null
              : employee.emp_four_program,
          emp_four_experience:
            employee.emp_two_experience === null
              ? null
              : employee.emp_two_experience,

          // end tap 4-5
        };
        console.log(temp1);
        Swal.fire({
          title: "ต้องการที่จะบันทึกข้อมูล ใช่หรือไม่",
          icon: "info",
          showCancelButton: true,
          confirmButtonText: `ใช่`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `ไม่`,
        }).then(() => {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 1500,
            didOpen: () => {
              Swal.showLoading();
            },
          }).then((result) => {
            axios({
              method: "post",
              url:
                Configs.API_URL_hrMagenatement +
                "/api/hrManagement/saveEditEmployeeHistory",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp1,
            })
              .then(function (response) {
                if (response.data) {
                  Swal.fire({
                    icon: "success",
                    title: "Save",
                    showConfirmButton: false,
                    timer: 1500,
                  }).then((result) => {
                    window.location.href =
                      "/Human_Resource/ข้อมูลพนักงาน/Employee";
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
                Swal.fire({
                  icon: "warning",
                  title: "กรุณากรอกข้อมูลใหม่",
                  text: error.response.data.error.message,
                });
              });
          });
        });
      }
    }
  }

  function saveEducation() {
    const error_list = [];
    if (education.emp_edu_level == "") {
      let temp_err = {
        message: "กรุณากรอก ระดับการศึกษา",
      };
      error_list.push(temp_err);
    }
    if (education.emp_edu_major == "") {
      let temp_err = {
        message: "กรุณากรอก สาขาวิชา",
      };
      error_list.push(temp_err);
    }
    if (education.emp_edu_school == "") {
      let temp_err = {
        message: "กรุณากรอก สถานศึกษา",
      };
      error_list.push(temp_err);
    }
    if (education.emp_edu_start_date == "") {
      let temp_err = {
        message: "กรุณากรอก ปีที่เริ่มศึกษา",
      };
      error_list.push(temp_err);
    }
    if (education.emp_edu_end_date == "") {
      let temp_err = {
        message: "กรุณากรอก ปีที่จบศึกษา",
      };
      error_list.push(temp_err);
    }
    if (education.emp_edu_gpa == "") {
      let temp_err = {
        message: "กรุณากรอก เกรดเฉลี่ย",
      };
      error_list.push(temp_err);
    }
    else if (education.emp_edu_gpa != "") {
      // console.log('parseFloat(education.emp_edu_gpa) ', parseFloat(education.emp_edu_gpa) );
      if (parseFloat(education.emp_edu_gpa) > 4.00) {
        let temp_err = {
          message: "เกรดเฉลี่ย ต้องไม่เกิน 4.00",
        };
        error_list.push(temp_err);
      }
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }
    return error_list.length > 0 ? true : false;
  }

  function cancelOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
      }
    });
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ประวัติพนักงาน</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Employee_history</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
            {/* button บันทึก และ ยกเลิก ยังไม่link */}
            <div className="container-fluid">
              {mode.mode === "read" ? (
                ""
              ) : /* <div className="row mb-2">
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={() => {
                        window.location.href = "/Human_Resource/ข้อมูลพนักงาน/employee_history/edit/" + id;
                      }}
                      class="btn btn-block btn-success "
                    >
                      แก้ไข
                    </button>
                  </div>
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={() => {
                        window.location.href = "/Human_Resource/ข้อมูลพนักงาน/Employee";
                      }}
                      class="btn btn-block btn-danger "
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div> */
              mode.mode === "edit" ? (
                <div className="row mb-2">
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={saveOption}
                      class="btn btn-block btn-success "
                    >
                      บันทึก
                    </button>
                  </div>
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={cancelOption}
                      class="btn btn-block btn-danger "
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row mb-2">
                  <div className="col-6 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={saveOption}
                      class="btn btn-block btn-success "
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-page-1-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-page-1"
                    role="tab"
                    aria-controls="custom-tabs-four-page-1"
                    aria-selected="true"
                  >
                    ข้อมูลส่วนบุคคล
                  </a>
                </li>
                <li className="nav-item">
                  {mode.mode === "read" || mode.mode === "edit" ? (
                    <a
                      className="nav-link"
                      id="custom-tabs-four-page-2-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-2"
                      role="tab"
                      aria-controls="custom-tabs-four-page-2"
                      aria-selected="false"
                    >
                      ประวัติการศึกษา
                    </a>
                  ) : /* isSecondTabEnabled ===  */ true ? (
                    <a
                      className={"nav-link " /*  activeTab.ActiveTab2 */}
                      id="custom-tabs-four-page-2-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-2"
                      role="tab"
                      aria-controls="custom-tabs-four-page-2"
                      aria-selected="false"
                    >
                      ประวัติการศึกษา
                    </a>
                  ) : (
                    <a
                      className="nav-link disabled"
                      id="custom-tabs-four-page-2-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-2"
                      role="tab"
                      aria-controls="custom-tabs-four-page-2"
                      aria-selected="false"
                    >
                      ประวัติการศึกษา
                    </a>
                  )}
                </li>
                <li className="nav-item">
                  {mode.mode === "read" || mode.mode === "edit" ? (
                    <a
                      className="nav-link"
                      id="custom-tabs-four-page-3-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-3"
                      role="tab"
                      aria-controls="custom-tabs-four-page-3"
                      aria-selected="false"
                    >
                      ประวัติการฝึกงาน/ทำงาน
                    </a>
                  ) : /* isThridTabEnabled ===  */ true ? (
                    <a
                      className={"nav-link " /* + activeTab.ActiveTab3 */}
                      id="custom-tabs-four-page-3-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-3"
                      role="tab"
                      aria-controls="custom-tabs-four-page-3"
                      aria-selected="false"
                    >
                      ประวัติการฝึกงาน/ทำงาน
                    </a>
                  ) : (
                    <a
                      className="nav-link disabled"
                      id="custom-tabs-four-page-3-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-3"
                      role="tab"
                      aria-controls="custom-tabs-four-page-3"
                      aria-selected="false"
                    >
                      ประวัติการฝึกงาน/ทำงาน
                    </a>
                  )}
                </li>
                <li className="nav-item">
                  {mode.mode === "read" || mode.mode === "edit" ? (
                    <a
                      className="nav-link"
                      id="custom-tabs-four-page-4-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-4"
                      role="tab"
                      aria-controls="custom-tabs-four-page-4"
                      aria-selected="false"
                    >
                      ความสามารถพิเศษ
                    </a>
                  ) : /* isFourthTabEnabled ===  */ true ? (
                    <a
                      className={"nav-link " /* + activeTab.ActiveTab4 */}
                      id="custom-tabs-four-page-4-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-4"
                      role="tab"
                      aria-controls="custom-tabs-four-page-4"
                      aria-selected="false"
                    >
                      ความสามารถพิเศษ
                    </a>
                  ) : (
                    <a
                      className="nav-link disabled"
                      id="custom-tabs-four-page-4-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-4"
                      role="tab"
                      aria-controls="custom-tabs-four-page-4"
                      aria-selected="false"
                    >
                      ความสามารถพิเศษ
                    </a>
                  )}
                </li>
                <li className="nav-item">
                  {mode.mode === "read" || mode.mode === "edit" ? (
                    <a
                      className="nav-link "
                      id="custom-tabs-four-page-5-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-5"
                      role="tab"
                      aria-controls="custom-tabs-four-page-5"
                      aria-selected="false"
                    >
                      ข้อมูลอื่นๆ
                    </a>
                  ) : /* isFifthTabEnabled ===  */ true ? (
                    <a
                      className={"nav-link " /* + activeTab.ActiveTab5 */}
                      id="custom-tabs-four-page-5-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-5"
                      role="tab"
                      aria-controls="custom-tabs-four-page-5"
                      aria-selected="false"
                    >
                      ข้อมูลอื่นๆ
                    </a>
                  ) : (
                    <a
                      className="nav-link disabled"
                      id="custom-tabs-four-page-5-tab"
                      data-toggle="pill"
                      href="#custom-tabs-four-page-5"
                      role="tab"
                      aria-controls="custom-tabs-four-page-5"
                      aria-selected="false"
                    >
                      ข้อมูลอื่นๆ
                    </a>
                  )}
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-page-1"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-1-tab"
                >
                  {/* ส่วนแรก */}
                  <div className="">
                    <h3 className="mb-2  mt-5-head">ข้อมูลการสมัครงาน</h3>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-6">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_position"
                          value={employee.emp_position}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_position: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ตำแหน่ง
                          {employee.emp_position === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          thousandsGroupStyle="thousand"
                          thousandSeparator=","
                          allowNegative={false}
                          required
                          id="text_money_want"
                          value={employee.emp_money_want}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_money_want: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เงินเดือนที่ต้องการ
                          {employee.emp_money_want === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* ส่วน 2 */}
                  <div className="">
                    <h3 className="mb-2  mt-5-head">ข้อมูลบุคคล</h3>
                  </div>

                  <div
                    className="row"
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignContent: "flex-end",
                      marginBottom: "2rem",
                    }}
                  >
                    <div className="col-6 col-md-3 col-xl-3">
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="exampleInputFile"
                            disabled={disable}
                            accept="image/*"
                            onChange={(e) => {
                              console.log(e.target.files[0] !== undefined);
                              if (e.target.files[0] === undefined) {
                                if (
                                  employee.emp_image_path !== null &&
                                  employee.emp_image_path !== ""
                                ) {
                                  setImage({
                                    ...Image,
                                    data: e.target.files[0],
                                    emp_image_name: employee.emp_image_name,
                                    emp_image_path: employee.emp_image_path,
                                    status: false,
                                  });
                                } else {
                                  setImage({
                                    ...Image,
                                    data: null,
                                    emp_image_name: null,
                                    emp_image_path: null,
                                    status: false,
                                  });
                                }
                              } else {
                                setemployee({
                                  ...employee,
                                  emp_image_path: null,
                                  emp_image_name: null,
                                });
                                employee.emp_image_path = null;
                                employee.emp_image_name = null;
                                setImage({
                                  ...Image,
                                  data: e.target.files[0],
                                  emp_image_name: e.target.files[0].name,
                                  emp_image_path: URL.createObjectURL(
                                    e.target.files[0]
                                  ),
                                  status: true,
                                });
                              }
                              // setemployee({
                              //   ...employee,
                              //   emp_image_name: e.target.files[0].name,
                              //   emp_image_path: URL.createObjectURL(e.target.files[0]),
                              // });
                              // setImage({
                              //   ...Image,
                              //   data: e.target.files[0],
                              //   emp_image_name: e.target.files[0].name,
                              //   emp_image_path: URL.createObjectURL(e.target.files[0]),
                              //   status: true,
                              // })
                            }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            {Image.status === false
                              ? employee.emp_image_name !== "" &&
                                employee.emp_image_name !== null
                                ? employee.emp_image_name
                                : "อัพโหลดรูปภาพ"
                              : Image.emp_image_name !== "" &&
                                Image.emp_image_name !== null
                              ? Image.emp_image_name
                              : "อัพโหลดรูปภาพ"}
                            {/* {employee.emp_image_name !== "" && employee.emp_image_name !== null
                              ? employee.emp_image_name
                              : Image.emp_image_name !== "" && Image.emp_image_name !== null
                                ? Image.emp_image_name
                                : ("อัพโหลดรูปภาพ")} */}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-6 col-md-3 col-xl-3"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {Image.status === true ? (
                        <div className="form-group">
                          <img
                            src={
                              employee.emp_image_path !== null &&
                              employee.emp_image_path !== ""
                                ? Configs.API_URL_IMG + employee.emp_image_path
                                : Image.emp_image_path !== null &&
                                  Image.emp_image_path !== ""
                                ? Image.emp_image_path
                                : userdefault_img.imgs
                            }
                            className="img-fluid"
                            alt="Profile Image"
                            style={{ width: "150px", height: "150px" }}
                          />
                          <label htmlFor="">รูปภาพ</label>
                        </div>
                      ) : (
                        <div className="form-group">
                          <img
                            src={
                              employee.emp_image_path !== null &&
                              employee.emp_image_path !== ""
                                ? Configs.API_URL_IMG + employee.emp_image_path
                                : Image.emp_image_path !== null &&
                                  Image.emp_image_path !== ""
                                ? Image.emp_image_path
                                : userdefault_img.imgs
                            }
                            className="img-fluid"
                            alt="Profile Image"
                            style={{ width: "150px", height: "150px" }}
                          />
                          <label htmlFor="">รูปภาพ</label>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* ส่วน 2 row 1 */}
                  <div className="row">
                    <div className="col-12 col-md-2 col-xl-2">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="name_title_th"
                          value={employee.emp_name_title_th}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_name_title_th: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {getNameTitle.map((el) => {
                            return (
                              <option value={el.id}>{el.title_name}</option>
                            );
                          })}
                        </select>
                        <label htmlFor="">
                          คำนำหน้า{" "}
                          {employee.emp_name_title_th === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-5 col-xl-5">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_name_th"
                          value={employee.emp_name_th}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_name_th: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ชื่อ (ไทย)
                          {employee.emp_name_th === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-5 col-xl-5">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_surname_th"
                          value={employee.emp_surname_th}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_surname_th: e.target.value,
                            });
                          }}
                        />
                        <label>
                          นามสกุล (ไทย)
                          {employee.emp_surname_th === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-2 col-xl-2">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="name_title_eg"
                          value={employee.emp_name_title_eng}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_name_title_eng: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {getNameTitle.map((el) => {
                            return (
                              <option value={el.id}>{el.title_name_eg}</option>
                            );
                          })}
                        </select>
                        <label htmlFor="">
                          Title{" "}
                          {employee.emp_name_title_eng === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-5 col-xl-5">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_name_eg"
                          value={employee.emp_name_eng}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_name_eng: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ชื่อ (อังกฤษ)
                          {employee.emp_name_eng === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-5 col-xl-5">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_sername_eg"
                          value={employee.emp_surname_eng}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_surname_eng: e.target.value,
                            });
                          }}
                        />
                        <label>
                          นามสกุล (อังกฤษ)
                          {employee.emp_surname_eng === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 col-xl-3">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="sex"
                          value={employee.emp_sex}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_sex: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {getGender.map((el) => {
                            return (
                              <option value={el.id}>{el.gender_name}</option>
                            );
                          })}
                        </select>
                        <label htmlFor="">
                          เพศสภาพ{" "}
                          {employee.emp_sex === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="###"
                          required
                          allowNegative={false}
                          id="text_height"
                          value={employee.emp_height}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_height: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ส่วนสูง (เซนติเมตร)
                          {employee.emp_height === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="###"
                          required
                          allowNegative={false}
                          id="text_weight"
                          value={employee.emp_weight}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_weight: e.target.value,
                            });
                          }}
                        />
                        <label>
                          น้ำหนัก (กิโลกรัม)
                          {employee.emp_weight === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-xl-3">
                      <div class="form-group">
                        <DatePicker
                          selected={employee.emp_birthday}
                          disabled={disable}
                          wrapperClassName="w-100"
                          id="text_birthday"
                          dateFormat={"dd-MM-yyyy"}
                          locale="th"
                          onChange={(date) => {
                            CalculateAge(date);
                            setemployee({
                              ...employee,
                              emp_birthday: date,
                            });
                            settest({ ...test, test: "" });
                            test.test = "";
                          }}
                          maxDate={new Date()}
                          showYearDropdown
                          showMonthDropdown
                          customInput={<CustomInput />}
                        ></DatePicker>

                        <label>
                          วันเกิด
                          {employee.emp_birthday === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* {console.log(employee.emp_birthday)} */}
                  {/* {console.log(employee.emp_birthday - new Date().getFullYear())} */}
                  <div className="row">
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <input
                          type="number"
                          className="form-control"
                          min={0}
                          max={99}
                          required
                          id="text_age"
                          value={employee.emp_age}
                          disabled="true"
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_age: e.target.value,
                            });
                          }}
                        />
                        <label>
                          อายุ
                          {employee.emp_age === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <select
                          type="text"
                          className="form-control"
                          required
                          id="text_nationality"
                          value={employee.emp_nationality}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_nationality: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {getNation.map((el) => {
                            return (
                              <option value={el.id}>{el.emp_nation}</option>
                            );
                          })}
                        </select>
                        <label>
                          สัญชาติ
                          {/* {employee.emp_nationality === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_ethnicity"
                          value={employee.emp_ethnicity}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_ethnicity: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เชื้อชาติ
                          {/* {employee.emp_ethnicity === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <select
                          type="text"
                          className="form-control"
                          required
                          id="text_religion"
                          value={employee.emp_religion}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_religion: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {getReligion.map((el) => {
                            return (
                              <option value={el.id}>{el.religion_name}</option>
                            );
                          })}
                        </select>
                        <label>
                          ศาสนา
                          {employee.emp_religion === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* ส่วน 2 row 3 */}
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-6">
                      <div class="form-group">
                        <NumberFormat
                          className="form-control"
                          required
                          id="text_mobile"
                          thousandSeparator={false}
                          format={"#-####-#####-##-#"}
                          value={employee.emp_national_id}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_national_id: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เลขบัตรประชาชน
                          {employee.emp_national_id === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_national_id_form"
                          value={employee.emp_line_id}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_line_id: e.target.value,
                            });
                          }}
                        />
                        <label>Line Id</label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_nickname"
                          value={employee.emp_nickname}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_nickname: e.target.value,
                            });
                          }}
                        />
                        <label>ชื่อเล่น</label>
                      </div>
                    </div>
                  </div>
                  {/* ส่วน 2 row 4 */}
                  <div className="row">
                    <div className="col-6 col-md-6 col-xl-2">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="text_status"
                          value={employee.emp_status}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_status: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {getPersonStatus.map((el) => {
                            return (
                              <option value={el.id}>
                                {el.person_status_name}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          สถานภาพ
                          {employee.emp_status === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    {/* สมรส */}
                    {/* employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" */ true ? (
                      <div className="col-6 col-md-6 col-xl-2">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            format="##"
                            required
                            allowNegative={false}
                            id="text_child_num"
                            value={employee.emp_child_num}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_child_num: e.target.value,
                              });
                            }}
                          />
                          <label>
                            จำนวนบุตร
                            {/* {employee.emp_child_num === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_sex ===
                    "4dc937ba-0a92-466f-9bcf-0cd08d92736b" ? (
                      <div className="col-6 col-md-6 col-xl-2">
                        <div class="form-group">
                          <select
                            className="form-control custom-select select2"
                            type="text"
                            required
                            id="text_military_status"
                            value={employee.emp_military_status}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_military_status: e.target.value,
                              });
                            }}
                          >
                            <option value="" disabled selected>
                              --เลือก--
                            </option>
                            {getSoldierStatus.map((el) => {
                              return (
                                <option value={el.id}>
                                  {el.soldier_status_name}
                                </option>
                              );
                            })}
                          </select>
                          <label>
                            ภาวะทางทหาร
                            {employee.emp_military_status === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_military_status ===
                    "18ca6e1d-d1f4-4a69-8d42-54c58c831243" &&
                    employee.emp_sex ===
                    "4dc937ba-0a92-466f-9bcf-0cd08d92736b" ? (
                      <div className="col-6 col-md-6 col-xl-2">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="text_military_status"
                            value={employee.emp_military_status_other}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_military_status_other: e.target.value,
                              });
                            }}
                          />
                          <label>
                            อื่นๆ
                            {employee.emp_military_status_other === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_sex ===
                    "4dc937ba-0a92-466f-9bcf-0cd08d92736b" ? (
                      <div className="col-6 col-md-6 col-xl-2">
                        <div class="form-group">
                          <select
                            className="form-control custom-select select2"
                            type="text"
                            required
                            id="text_monk_status"
                            value={employee.emp_monk_status}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_monk_status: e.target.value,
                              });
                            }}
                          >
                            <option value="" disabled selected>
                              --เลือก--
                            </option>
                            <option value="1">ไม่เคย</option>
                            <option value="2">เคย</option>
                          </select>
                          <label>
                            เคยอุปสมบทหรือไม่
                            {employee.emp_monk_status === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_monk_status === "2" && employee.emp_sex ===
                    "4dc937ba-0a92-466f-9bcf-0cd08d92736b" ? (
                      <div className="col-6 col-md-6 col-xl-2">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            id="text_monk_status"
                            value={employee.emp_monk_status_ever}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_monk_status_ever: e.target.value,
                              });
                            }}
                          />
                          <label>
                            ระยะเวลาการอุปสมบท
                            {employee.emp_monk_status_ever === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* ส่วน 2 row 5 */}
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-12">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="text_housing_status"
                          value={employee.emp_housing_status}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_housing_status: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            --เลือก--
                          </option>
                          {getAddressStatus.map((el) => {
                            return (
                              <option value={el.id}>
                                {el.address_status_name}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">
                          บ้านพักที่อยู่อาศัย
                          {/* {employee.emp_housing_status === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* ส่วน 3 */}
                  <div className="">
                    <h3 className="mb-2  mt-5-head">ข้อมูลเพื่อติดต่อกลับ</h3>
                  </div>
                  {/* ส่วน 3 row 1 */}
                  <div className="">
                    <h4 className="">ข้อมูลที่อยู่ปัจจุบัน</h4>
                  </div>
                  {/* ส่วน 3 row 2 */}
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_address_no"
                          value={employee.emp_address_no}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_address_no: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เลขที่
                          {employee.emp_address_no === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_address_soi"
                          value={employee.emp_address_soi}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_address_soi: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ซอย
                          {/* {employee.emp_address_soi === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_address_road"
                          value={employee.emp_address_road}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_address_road: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ถนน
                          {/* {employee.emp_address_road === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_address_subdistrict"
                          value={employee.emp_address_subdistrict}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_address_subdistrict: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ตำบล/แขวง
                          {employee.emp_address_subdistrict === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_address_district"
                          value={employee.emp_address_district}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_address_district: e.target.value,
                            });
                          }}
                        />
                        <label>
                          อำเภอ/เขต
                          {employee.emp_address_district === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-6 col-md-4 col-xl-2"
                      style={{ "z-index": "98" }}
                    >
                      <div class="form-group">
                        <Select
                          type="text"
                          id="text_address_province"
                          ref={select_Province}
                          value={Province.find(
                            (item) =>
                              item.value === employee.emp_address_province
                          )}
                          isDisabled={disable}
                          options={Province}
                          onChange={(e) => {
                            if (e !== null) {
                              setemployee({
                                ...employee,
                                emp_address_province: e.value || "",
                              });
                            }
                          }}
                        ></Select>
                        <label>
                          จังหวัด
                          {employee.emp_address_province === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* ส่วน 3 row 3 */}
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          required
                          id="text_address_postcode"
                          format={"#####"}
                          value={employee.emp_address_postcode}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_address_postcode: e.target.value,
                            });
                          }}
                        />
                        <label>
                          รหัสไปรษณีย์
                          {employee.emp_address_postcode === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <NumberFormat
                          className="form-control"
                          required
                          thousandSeparator={false}
                          format={"##-###-####"}
                          id="text_address_tel"
                          value={employee.emp_address_tel}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_address_tel: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เบอร์โทรศัพท์บ้าน
                          {/* {employee.emp_address_tel === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-2">
                      <div class="form-group">
                        <NumberFormat
                          className="form-control"
                          required
                          id="text_mobile"
                          thousandSeparator={false}
                          format={"###-###-####"}
                          value={employee.emp_mobile}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_mobile: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เบอร์มือถือ
                          {employee.emp_mobile === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-12 col-xl-6">
                      <div class="form-group">
                        <input
                          type="email"
                          className="form-control"
                          required
                          id="text_address_email"
                          value={employee.emp_email}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_email: e.target.value,
                            });
                          }}
                        />
                        <label>
                          E-mail
                          {employee.emp_email === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* ส่วน 4 */}
                  <div className="">
                    <h3 className="mb-2  mt-5-head">ประวัติครอบครัว</h3>
                  </div>
                  {/* ส่วน 4 row 1 */}
                  <div className="row">
                    <div className="col-8 col-md-4 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_father_name"
                          value={employee.emp_father_name}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_father_name: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ชื่อ-สกุล บิดา
                          {/* {employee.emp_father_name === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-md-2 col-xl-2">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="##"
                          required
                          allowNegative={false}
                          id="text_father_age"
                          value={employee.emp_father_age}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_father_age: e.target.value,
                            });
                          }}
                        />
                        <label>
                          อายุ บิดา
                          {/* {employee.emp_father_age === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_father_job"
                          value={employee.emp_father_job}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_father_job: e.target.value,
                            });
                          }}
                        />
                        <label>
                          อาชีพ บิดา
                          {/* {employee.emp_father_job === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          thousandSeparator={false}
                          format={"###-###-####"}
                          className="form-control"
                          required
                          id="text_father_mobile"
                          value={employee.emp_father_mobile}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_father_mobile: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เบอร์มือถือ บิดา
                          {/* {employee.emp_father_mobile === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    {/* ส่วน 4 row 2 */}
                    <div className="col-8 col-md-4 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_mother_name"
                          value={employee.emp_mother_name}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_mother_name: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ชื่อ-สกุล มารดา
                          {/* {employee.emp_mother_name === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-md-2 col-xl-2">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="##"
                          required
                          allowNegative={false}
                          id="text_mother_age"
                          value={employee.emp_mother_age}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_mother_age: e.target.value,
                            });
                          }}
                        />
                        <label>
                          อายุ มารดา
                          {/* {employee.emp_mother_age === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          id="text_family_job"
                          value={employee.emp_mother_job}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_mother_job: e.target.value,
                            });
                          }}
                        />
                        <label>
                          อาชีพ มารดา
                          {/* {employee.emp_mother_job === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-3 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          thousandSeparator={false}
                          format={"###-###-####"}
                          className="form-control"
                          required
                          id="text_mother_mobile"
                          value={employee.emp_mother_mobile}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_mother_mobile: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เบอร์มือถือ มารดา
                          {/* {employee.emp_mother_mobile === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )} */}
                        </label>
                      </div>
                    </div>

                    {/* ส่วน 4 row 3 */}
                    {employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" ? (
                      <div className="col-8 col-md-4 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={employee.emp_relationship_name}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_relationship_name: e.target.value,
                              });
                            }}
                          />
                          <label>ชื่อ-สกุล ภรรยา/สามี</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" ? (
                      <div className="col-4 col-md-2 col-xl-2">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            format="##"
                            required
                            allowNegative={false}
                            value={employee.emp_relationship_age}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_relationship_age: e.target.value,
                              });
                            }}
                          />
                          <label>อายุ ภรรยา/สามี</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" ? (
                      <div className="col-6 col-md-3 col-xl-3">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="text_relationship_job"
                            required="false"
                            value={employee.emp_relationship_job}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_relationship_job: e.target.value,
                              });
                            }}
                          />
                          <label>อาชีพ ภรรยา/สามี</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" ? (
                      <div className="col-6 col-md-3 col-xl-3">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="text_relationship_workplace"
                            required="false"
                            value={employee.emp_relationship_workplace}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_relationship_workplace: e.target.value,
                              });
                            }}
                          />
                          <label>สถานที่ทำงาน ภรรยา/สามี</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* ส่วน 4 row 4 */}
                    {employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" ? (
                      <div className="col-6 col-md-4  col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="text_relationship_position"
                            required="false"
                            value={employee.emp_relationship_position}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_relationship_position: e.target.value,
                              });
                            }}
                          />
                          <label>ตำแหน่ง ภรรยา/สามี</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" ? (
                      <div className="col-6 col-md-4 col-xl-4">
                        <div class="form-group">
                          <NumberFormat
                            thousandSeparator={false}
                            format={"###-###-####"}
                            className="form-control"
                            id="text_relationship_mobile"
                            required="false"
                            value={employee.emp_relationship_mobile}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_relationship_mobile: e.target.value,
                              });
                            }}
                          />
                          <label>เบอร์มือถือ ภรรยา/สามี</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee.emp_status ===
                    "9a6b271c-2cd8-421b-a386-83a87729ab17" ? (
                      <div className="col-6 col-md-4 col-xl-4">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            id="text_chlidren_number"
                            className="form-control"
                            format="##"
                            required
                            allowNegative={false}
                            value={employee.emp_chlidren_number}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_chlidren_number: e.target.value,
                              });
                            }}
                          />
                          <label>มีบุตรกี่คน</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-6 col-md-4 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="##"
                          required
                          allowNegative={false}
                          id="text_sibling_number"
                          value={employee.emp_sibling_number}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_sibling_number: e.target.value,
                            });
                          }}
                        />
                        <label>
                          จำนวนพี่น้อง (รวมผู้สมัคร)
                          {employee.emp_sibling_number === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="##"
                          required
                          allowNegative={false}
                          id="text_brother_number"
                          value={employee.emp_brother_number}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_brother_number: e.target.value,
                            });
                          }}
                        />
                        <label>ชายกี่คน</label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="##"
                          required
                          allowNegative={false}
                          id="text_sister_number"
                          value={employee.emp_sister_number}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_sister_number: e.target.value,
                            });
                          }}
                        />
                        <label>หญิงกี่คน</label>
                      </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          format="##"
                          required
                          allowNegative={false}
                          id="text_rank_children"
                          value={employee.emp_rank_children}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_rank_children: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เป็นบุตรคนที่...
                          {employee.emp_rank_children === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-4 col-xl-4">
                      <h3 className="mb-2  mt-5-head">บุคคลที่ติดต่อได้</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-4 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="text_contract_name"
                          required
                          value={employee.emp_contract_name}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_contract_name: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ชื่อ-สกุล
                          {employee.emp_contract_name === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          thousandSeparator={false}
                          format={"###-###-###"}
                          className="form-control"
                          id="text_contract_tel"
                          required
                          value={employee.emp_contract_tel}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_contract_tel: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เบอร์โทร
                          {employee.emp_contract_tel === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="text_contract_relation"
                          required
                          value={employee.emp_contract_relation}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_contract_relation: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เกี่ยวข้องเป็น
                          {employee.emp_contract_relation === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-4 col-xl-4">
                      <h3 className="mb-2  mt-5-head">บุคคลค้ำประกัน</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-4 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="text_insurer_name"
                          required
                          value={employee.emp_insurer_name}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_insurer_name: e.target.value,
                            });
                          }}
                        />
                        <label>
                          ชื่อ-สกุล
                          {employee.emp_insurer_name === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          thousandSeparator={false}
                          format={"###-###-###"}
                          className="form-control"
                          id="text_insurer_tel"
                          required
                          value={employee.emp_insurer_tel}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_insurer_tel: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เบอร์โทร
                          {employee.emp_insurer_tel === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="text_insurer_relation"
                          required
                          value={employee.emp_insurer_relation}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_insurer_relation: e.target.value,
                            });
                          }}
                        />
                        <label>
                          เกี่ยวข้องเป็น
                          {employee.emp_insurer_relation === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Tap 2 */}

                <div
                  className="tab-pane fade"
                  id="custom-tabs-four-page-2"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-2-tab"
                >
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                      <h3 className="mb-2  mt-5-head">ประวัติการศึกษา</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-2">
                      <div className="form-group">
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={disable}
                          data-toggle="modal"
                          data-target="#modal-edu"
                          onClick={() => {
                            seteducation(resetEducation);
                            setCloseModal({ checkData: true });
                            closeModal.checkData = true;
                            settest({ ...test, test: "" });
                          }}
                        >
                          เพิ่มประวัติการศึกษา
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-xl-12">
                      <div>
                        <MDBDataTable
                          className="table table-head-fixed"
                          sortable={false}
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={educationalTable}
                        />
                      </div>
                    </div>
                    {/* Add education data table */}
                    <div className="modal fade" id="modal-edu">
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">
                              เพิ่มประวัติการศึกษา
                            </h4>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              ref={closeEduModal}
                              onClick={() => {
                                setModal({ check: false, index: -1 });
                              }}
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="none-nolmalinput"></div>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-3">
                                    <div className="form-group ">
                                      <select
                                        type="text"
                                        className="form-control"
                                        disabled={disable}
                                        required
                                        value={education.emp_edu_level}
                                        onChange={(e) => {
                                          const _find = getEducationLevel.find(
                                            (where) =>
                                              where.id === e.target.value
                                          );
                                          seteducation({
                                            ...education,
                                            emp_edu_level: e.target.value,
                                            emp_edu_level_name:
                                              _find.education_levels_name,
                                          });
                                        }}
                                      >
                                        <option value="">
                                          เลือกระดับการศึกษา
                                        </option>
                                        {getEducationLevel.map((el) => (
                                          <option value={el.id}>
                                            {el.education_levels_name}
                                          </option>
                                        ))}
                                      </select>
                                      <label htmlFor="">
                                        ระดับการศึกษา
                                        {education.emp_edu_level === "" ? (
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-3">
                                    <div className="form-group ">
                                      <input
                                        type="text"
                                        className="form-control"
                                        required="false"
                                        disabled={disable}
                                        value={education.emp_edu_major}
                                        onChange={(e) => {
                                          seteducation({
                                            ...education,
                                            emp_edu_major: e.target.value,
                                          });
                                        }}
                                      />
                                      <label htmlFor="">
                                        สาขาวิชา
                                        {education.emp_edu_major === "" ? (
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-3">
                                    <div className="form-group ">
                                      <input
                                        type="text"
                                        className="form-control"
                                        required="false"
                                        disabled={disable}
                                        value={education.emp_edu_school}
                                        onChange={(e) => {
                                          seteducation({
                                            ...education,
                                            emp_edu_school: e.target.value,
                                          });
                                        }}
                                      />
                                      <label htmlFor="">
                                        สถานศึกษา
                                        {education.emp_edu_school === "" ? (
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </label>{" "}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-3">
                                    <div className="form-group ">
                                      <NumberFormat
                                        type="text"
                                        className="form-control"
                                        format="####"
                                        required
                                        allowNegative={false}
                                        disabled={disable}
                                        value={education.emp_edu_start_date}
                                        onChange={(e) => {
                                          seteducation({
                                            ...education,
                                            emp_edu_start_date: e.target.value,
                                          });
                                        }}
                                      />
                                      <label htmlFor="">
                                        ปีที่เริ่มการศึกษา
                                        {education.emp_edu_start_date === "" ? (
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </label>{" "}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-3">
                                    <div className="form-group ">
                                      <NumberFormat
                                        type="text"
                                        className="form-control"
                                        format="####"
                                        required
                                        allowNegative={false}
                                        disabled={disable}
                                        value={education.emp_edu_end_date}
                                        onChange={(e) => {
                                          seteducation({
                                            ...education,
                                            emp_edu_end_date: e.target.value,
                                          });
                                        }}
                                      />
                                      <label htmlFor="">
                                        ปีที่จบการศึกษา
                                        {education.emp_edu_end_date === "" ? (
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </label>{" "}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-3">
                                    <div className="form-group ">
                                      <NumberFormat
                                        type="text"
                                        className="form-control"
                                        format="#.##"
                                        // decimalScale={2}
                                        required
                                        allowNegative={false}
                                        disabled={disable}
                                        value={education.emp_edu_gpa}
                                        onChange={(e) => {
                                          seteducation({
                                            ...education,
                                            emp_edu_gpa: e.target.value,
                                          });
                                        }}
                                      />
                                      <label htmlFor="">
                                        เกรดเฉลี่ย
                                        {education.emp_edu_gpa === "" ? (
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </label>{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-0 col-md-4 col-xl-5"></div>
                                  <div className="col-12 col-md-4 col-xl-2">
                                    <div className="form-group ">
                                      <button
                                        type="button"
                                        class="btn btn-block btn-info "
                                        // data-dismiss="modal"
                                        disabled={disable}
                                        onClick={() => {
                                          const checkData = saveEducation();
                                          setCloseModal({ checkData });
                                          closeModal.checkData = checkData;

                                          if (closeModal.checkData === true) {
                                            return;
                                          } else if (
                                            !checkData &&
                                            !modal.check
                                          ) {
                                            if (
                                              education.emp_edu_level !== "" ||
                                              education.emp_edu_school !== ""
                                            ) {
                                              employee.emp_educationLevel.push(
                                                education
                                              );
                                              seteducation(resetEducation);
                                            }
                                          } else if (
                                            !checkData &&
                                            modal.check
                                          ) {
                                            const edu =
                                              employee.emp_educationLevel;
                                            edu[modal.index] = education;
                                            setemployee({
                                              ...employee,
                                              emp_educationLevel: edu,
                                            });
                                          }
                                          setModal({ check: false, index: -1 });
                                          setCloseModal({ checkData: false });
                                          closeModal.checkData = false;
                                          settest({ ...test, test: "" });
                                          if (!checkData) {
                                            clickCloseEduModal();
                                          }
                                        }}
                                      >
                                        เพิ่มข้อมูล
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*************************************************** Tap 3 ****************************************************/}
                <div
                  className="tab-pane fade"
                  id="custom-tabs-four-page-3"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-3-tab"
                >
                  <div>
                    <h3
                      className="mb-2 mt-5-head mb-2"
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      ประวัติการฝึกงาน/ทำงาน{" "}
                    </h3>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="tab-custom-content"></div>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="">
                            ประวัติการทำงานอย่างละเอียด ตามลำดับ (
                            ตั้งแต่ทำงานตครั้งแรกถึงปัจจุบัน )
                            {employee.emp_work_history === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <div className="row">
                            <div className="col-6 col-md-4 col-xl-2">
                              <button
                                type="button"
                                class="btn btn-block btn-info "
                                disabled={disable}
                                data-toggle="modal"
                                data-target="#modal-tab-3"
                                onClick={() => {
                                  setEmp_History_Work(resetEmp_History_Work);
                                  setCloseModal({ checkData: true });
                                  closeModal.checkData = true;
                                  settest({ ...test, test: "" });
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-12">
                          <div
                            className="table-responsive"
                            style={{ height: "450px", whiteSpace: "nowrap" }} //, whiteSpace: 'nowrap'
                          >
                            <MDBDataTable
                              className="table table-head-fixed"
                              sortable={false}
                              striped
                              bordered
                              hover
                              fixedHeader
                              data={Tables_Emp_Work}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal fade" id="modal-tab-3">
                        <div className="modal-dialog modal-xl">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title">
                                เพิ่มประวัติการทำงาน และการฝึกงาน
                              </h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                ref={closeWorkModal}
                                onClick={() =>
                                  setModal({ check: false, index: -1 })
                                }
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12 ">
                                  <div className="none-nolmalinput"></div>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-6 col-md-2 col-xl-2">
                                      <div class="form-group ">
                                        <select
                                          className="form-control custom-select select2"
                                          type="text"
                                          required
                                          disabled={disable}
                                          id="text_nation_id"
                                          value={
                                            emp_history_work.emp_work_type_id
                                          }
                                          onChange={(e) => {
                                            // console.log('เช็ค value',e.target.value);
                                            // console.log('เช็ค2',e.nativeEvent.target);
                                            const _find =
                                              getMasterWorkType.find(
                                                (where) =>
                                                  where.id === e.target.value
                                              );
                                            // console.log('_find', _find)
                                            setEmp_History_Work({
                                              ...emp_history_work,
                                              emp_work_type_id: e.target.value,
                                              emp_work_type:
                                                _find?.work_type_name ?? null,
                                            });
                                          }}
                                        >
                                          <option value="" disabled selected>
                                            ----เลือก----
                                          </option>
                                          {getMasterWorkType.map((el) => {
                                            return (
                                              <option
                                                value={el.id}
                                                label={el.work_type_name}
                                              />
                                            );
                                          })}
                                        </select>
                                        <label htmlFor="">
                                          รูปแบบงาน
                                          {emp_history_work.emp_work_type_id ===
                                          "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-4">
                                      <div className="form-group ">
                                        <input
                                          type="text"
                                          className="form-control"
                                          required="false"
                                          disabled={disable}
                                          value={emp_history_work.emp_work_name}
                                          onChange={(e) =>
                                            setEmp_History_Work({
                                              ...emp_history_work,
                                              emp_work_name: e.target.value,
                                            })
                                          }
                                        />
                                        <label htmlFor="">
                                          ชื่อที่ทำงาน และสถานที่ตั้ง
                                          {emp_history_work.emp_work_name ===
                                          "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>{" "}
                                      </div>
                                    </div>
                                    {emp_history_work.emp_work_type ===
                                    "ฝึกงาน" ? (
                                      ""
                                    ) : (
                                      <div className="col-12 col-md-4">
                                        <div className="form-group ">
                                          <input
                                            type="text"
                                            className="form-control"
                                            disabled={disable}
                                            required="false"
                                            value={
                                              emp_history_work.emp_work_type ===
                                              "ฝึกงาน"
                                                ? ""
                                                : emp_history_work.emp_work_positon
                                            }
                                            onChange={(e) =>
                                              setEmp_History_Work({
                                                ...emp_history_work,
                                                emp_work_positon:
                                                  e.target.value,
                                              })
                                            }
                                          />
                                          <label htmlFor="">
                                            ตำแหน่งเริ่มต้น{" "}
                                            {emp_history_work.emp_work_positon ===
                                            "" ? (
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </label>{" "}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-12 col-xl-6">
                                      <div className="form-group">
                                        {" "}
                                        <textarea
                                          className="form-control"
                                          rows={2}
                                          style={{
                                            height: "100px",
                                            fontSize: "25px",
                                          }}
                                          disabled={disable}
                                          value={
                                            emp_history_work.emp_work_details
                                          }
                                          onChange={(e) =>
                                            setEmp_History_Work({
                                              ...emp_history_work,
                                              emp_work_details: e.target.value,
                                            })
                                          }
                                          defaultValue={""}
                                          required
                                        />
                                        <label>
                                          ลักษณะงานที่ทำ
                                          {emp_history_work.emp_work_details ===
                                          "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-md-6 col-xl-4">
                                      <div class="form-group">
                                        <DatePicker
                                          selected={
                                            emp_history_work.emp_work_start
                                          }
                                          disabled={disable}
                                          wrapperClassName="w-100"
                                          id="text_national_exp_date"
                                          dateFormat={"dd-MM-yyyy"}
                                          locale="th"
                                          onChange={async (date) => {
                                            setEmp_History_Work({
                                              ...emp_history_work,
                                              emp_work_start: date,
                                            });
                                          }}
                                          startDate={
                                            emp_history_work.emp_work_start
                                          }
                                          showYearDropdown
                                          showMonthDropdown
                                          customInput={<CustomInput />}
                                        ></DatePicker>
                                        <label>
                                          วันที่เริ่มงาน
                                          {emp_history_work.emp_work_start ===
                                          "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-xl-4">
                                      <div class="form-group">
                                        <DatePicker
                                          selected={
                                            emp_history_work.emp_work_finish
                                          }
                                          disabled={disable}
                                          wrapperClassName="w-100"
                                          id="text_national_exp_date"
                                          dateFormat={"dd-MM-yyyy"}
                                          locale="th"
                                          onChange={async (date) => {
                                            setEmp_History_Work({
                                              ...emp_history_work,
                                              emp_work_finish: date,
                                            });
                                          }}
                                          startDate={
                                            emp_history_work.emp_work_finish
                                          }
                                          minDate={
                                            emp_history_work.emp_work_start
                                          }
                                          showYearDropdown
                                          showMonthDropdown
                                          customInput={<CustomInput />}
                                        ></DatePicker>
                                        <label>
                                          {emp_history_work.emp_work_type ===
                                          "ฝึกงาน"
                                            ? "วันสิ้นสุดการฝึกงาน"
                                            : "วันที่ออกจากงาน"}
                                          {emp_history_work.emp_work_finish ===
                                          "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {emp_history_work.emp_work_type ===
                                  "ฝึกงาน" ? (
                                    ""
                                  ) : (
                                    <div className="row">
                                      <div className="col-12 col-md-12 col-xl-6">
                                        <div className="form-group">
                                          {" "}
                                          <textarea
                                            style={{
                                              height: "100px",
                                              fontSize: "25px",
                                            }}
                                            className="form-control"
                                            rows={2}
                                            disabled={disable}
                                            value={
                                              emp_history_work.emp_work_type ===
                                              "ฝึกงาน"
                                                ? ""
                                                : emp_history_work.emp_work_leave
                                            }
                                            onChange={(e) =>
                                              setEmp_History_Work({
                                                ...emp_history_work,
                                                emp_work_leave: e.target.value,
                                              })
                                            }
                                            defaultValue={""}
                                            required
                                          />
                                          <label>
                                            สาเหตุที่ออก
                                            {emp_history_work.emp_work_leave ===
                                            "" ? (
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                *
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className="row"></div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-group ">
                                        <button
                                          type="button"
                                          class="btn btn-block btn-info "
                                          id="modal-close1"
                                          disabled={disable}
                                          onClick={() => {
                                            const checkData =
                                              checkDataEmployee_Work(
                                                emp_history_work,
                                                employee.emp_work_history
                                              );
                                            setCloseModal({ checkData });
                                            closeModal.checkData = checkData;
                                            addAndEditTable_WorkHistory(
                                              checkData,
                                              emp_history_work
                                            );
                                            if (!checkData) {
                                              clickCloseWorkModal();
                                            }
                                          }}
                                        >
                                          เพิ่มข้อมูล
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <h3></h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-four-page-4"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-4-tab"
                >
                  <div>
                    <h3 className="mb-2 mt-5-head mb-2">ความสามารถพิเศษ</h3>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="tab-custom-content"></div>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="">ความรู้เพิ่มเติม</label>
                          <div className="row">
                            <div className="col-6 col-md-4 col-xl-2">
                              <button
                                type="button"
                                class="btn btn-block btn-info "
                                disabled={disable}
                                data-toggle="modal"
                                data-target="#modal-tab-2"
                                onClick={() => {
                                  setEmp_talent(resetEmp_talent);
                                  setCloseModal({ checkData: true });
                                  closeModal.checkData = true;
                                  settest({ ...test, test: "" });
                                }}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-12">
                          <div
                            className="table-responsive"
                            style={{ height: "350px", whiteSpace: "nowrap" }}
                          >
                            <MDBDataTable
                              className="table table-head-fixed"
                              sortable={false}
                              striped
                              bordered
                              hover
                              fixedHeader
                              data={TableEmployeeTap4}
                            />
                          </div>
                        </div>
                      </div>

                      {/* ------------------------------------------------------------------------------------------------------------------ */}

                      <div className="modal fade" id="modal-tab-2">
                        <div className="modal-dialog modal-xl">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title">
                                เพิ่มความสามารถและผลงาน
                              </h4>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                ref={inputElement}
                                onClick={() => {
                                  setModal({ check: false, index: -1 });
                                }}
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-12 ">
                                  <div className="none-nolmalinput"></div>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-12">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group ">
                                        <input
                                          type="text"
                                          className="form-control"
                                          required="false"
                                          disabled={disable}
                                          value={emp_talent.emp_language}
                                          onChange={(e) =>
                                            setEmp_talent({
                                              ...emp_talent,
                                              emp_language: e.target.value,
                                            })
                                          }
                                        />
                                        <label htmlFor="">ภาษาต่างประเทศ</label>{" "}
                                      </div>
                                    </div>
                                    <div className="col-6 col-md-2 col-xl-2">
                                      <div class="form-group ">
                                        <select
                                          className="form-control custom-select select2"
                                          type="text"
                                          required
                                          id="text_nation_id"
                                          value={emp_talent.emp_speak}
                                          disabled={disable}
                                          onChange={(e) => {
                                            // console.log('เช็ค value',e.target.value);
                                            // console.log('เช็ค2',e.nativeEvent.target);
                                            const _find = getScoreNameEmp.find(
                                              (where) =>
                                                where.id === e.target.value
                                            );
                                            // console.log('_find', _find)
                                            setEmp_talent({
                                              ...emp_talent,
                                              emp_speak: e.target.value,
                                              emp_speak_name:
                                                _find?.score_name ?? null,
                                            });
                                          }}
                                        >
                                          <option value="" disabled selected>
                                            ----เลือก----
                                          </option>
                                          {getScoreNameEmp.map((el) => {
                                            return (
                                              <option
                                                value={el.id}
                                                label={el.score_name}
                                              />
                                            );
                                          })}
                                        </select>
                                        <label htmlFor="">
                                          พูด
                                          {emp_talent.emp_language === "" ? (
                                            ""
                                          ) : emp_talent.emp_speak === "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6 col-md-2 col-xl-2">
                                      <div class="form-group ">
                                        <select
                                          className="form-control custom-select select2"
                                          type="text"
                                          required
                                          id="text_nation_id"
                                          value={emp_talent.emp_listen}
                                          disabled={disable}
                                          onChange={(e) => {
                                            // console.log('เช็ค value',e.target.value);
                                            // console.log('เช็ค2',e.nativeEvent.target);
                                            const _find = getScoreNameEmp.find(
                                              (where) =>
                                                where.id === e.target.value
                                            );
                                            // console.log('_find', _find)
                                            setEmp_talent({
                                              ...emp_talent,
                                              emp_listen: e.target.value,
                                              emp_listen_name:
                                                _find?.score_name ?? null,
                                            });
                                          }}
                                        >
                                          <option value="" disabled selected>
                                            ----เลือก----
                                          </option>
                                          {getScoreNameEmp.map((el) => {
                                            return (
                                              <option
                                                value={el.id}
                                                label={el.score_name}
                                              />
                                            );
                                          })}
                                        </select>
                                        <label htmlFor="">
                                          ฟัง
                                          {emp_talent.emp_language === "" ? (
                                            ""
                                          ) : emp_talent.emp_listen === "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6 col-md-2 col-xl-2">
                                      <div class="form-group ">
                                        <select
                                          className="form-control custom-select select2"
                                          type="text"
                                          required
                                          id="text_nation_id"
                                          value={emp_talent.emp_write}
                                          disabled={disable}
                                          onChange={(e) => {
                                            const _find = getScoreNameEmp.find(
                                              (where) =>
                                                where.id === e.target.value
                                            );
                                            setEmp_talent({
                                              ...emp_talent,
                                              emp_write: e.target.value,
                                              emp_write_name:
                                                _find?.score_name ?? null,
                                            });
                                          }}
                                        >
                                          <option value="" disabled selected>
                                            ----เลือก----
                                          </option>
                                          {getScoreNameEmp.map((el) => {
                                            return (
                                              <option value={el.id}>
                                                {el.score_name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        <label htmlFor="">
                                          เขียน
                                          {emp_talent.emp_language === "" ? (
                                            ""
                                          ) : emp_talent.emp_write === "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-6 col-md-2 col-xl-2">
                                      <div class="form-group ">
                                        <select
                                          className="form-control custom-select select2"
                                          type="text"
                                          required
                                          id="text_nation_id"
                                          value={emp_talent.emp_read}
                                          disabled={disable}
                                          onChange={(e) => {
                                            const _find = getScoreNameEmp.find(
                                              (where) =>
                                                where.id === e.target.value
                                            );
                                            setEmp_talent({
                                              ...emp_talent,
                                              emp_read: e.target.value,
                                              emp_read_name:
                                                _find?.score_name ?? null,
                                            });
                                          }}
                                        >
                                          <option value="" disabled selected>
                                            ----เลือก----
                                          </option>
                                          {getScoreNameEmp.map((el) => {
                                            return (
                                              <option value={el.id}>
                                                {el.score_name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        <label htmlFor="">
                                          อ่าน
                                          {emp_talent.emp_language === "" ? (
                                            ""
                                          ) : emp_talent.emp_read === "" ? (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-12 col-md-12 col-xl-4">
                                      <div className="form-group">
                                        {" "}
                                        <textarea
                                          style={{
                                            height: "100px",
                                            fontSize: "25px",
                                          }}
                                          className="form-control"
                                          rows={2}
                                          // placeholder="enter"
                                          disabled={disable}
                                          value={emp_talent.emp_project}
                                          onChange={(e) =>
                                            setEmp_talent({
                                              ...emp_talent,
                                              emp_project: e.target.value,
                                            })
                                          }
                                          defaultValue={""}
                                          required
                                        />
                                        <label>โครงการ และผลงานอื่น ๆ</label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-xl-4">
                                      <div className="form-group">
                                        {" "}
                                        <textarea
                                          style={{
                                            height: "100px",
                                            fontSize: "25px",
                                          }}
                                          className="form-control"
                                          rows={2}
                                          // placeholder="enter"
                                          disabled={disable}
                                          value={emp_talent.emp_honor}
                                          onChange={(e) =>
                                            setEmp_talent({
                                              ...emp_talent,
                                              emp_honor: e.target.value,
                                            })
                                          }
                                          defaultValue={""}
                                          required
                                        />
                                        <label>เกียรติบัตร/ประกาศนียบัตร</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row"></div>

                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-group ">
                                        <button
                                          type="button"
                                          id="modal-close"
                                          class="btn btn-block btn-info "
                                          // data-dismiss={closeModal.checkData === false ? "modal" : ""}
                                          disabled={disable}
                                          // onClick={fillterdata}
                                          onClick={() => {
                                            const checkData =
                                              checkDataEmployee_talen(
                                                emp_talent,
                                                employee.emp_talen
                                              );
                                            setCloseModal({ checkData });
                                            closeModal.checkData = checkData;
                                            addAndEditTable_Talent(
                                              checkData,
                                              emp_talent
                                            );
                                            if (!checkData) {
                                              focusInput();
                                            }
                                          }}
                                        >
                                          เพิ่มข้อมูล
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <h3></h3>
                                </div>
                              </div>
                            </div>
                            {/* <div className="modal-footer justify-content-between">
                              <button
                                type="button"
                                className="btn btn-default"
                                data-dismiss="modal"
                              >
                                Close
                              </button>

                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <hr />
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-4">
                      <label htmlFor="">
                        ท่านสามารถใช้เครื่องใช้สำนักงานอะไรได้บ้าง
                      </label>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          disabled={disable}
                          onChange={() => {
                            setCheckedPrint(!checkedPrint);
                            setemployee({
                              ...employee,
                              emp_four_printer: !checkedPrint,
                            });
                          }}
                          checked={employee.emp_four_printer}
                          id="chk_remember1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk_remember1"
                        >
                          เครื่องถ่ายเอกสาร
                        </label>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          disabled={disable}
                          onChange={() => {
                            setCheckedFax(!checkedFax);
                            setemployee({
                              ...employee,
                              emp_four_fax: !checkedFax,
                            });
                          }}
                          checked={employee.emp_four_fax}
                          id="chk_remember2"
                        />
                        <label
                          htmlFor="chk_remember2"
                          className="form-check-label"
                        >
                          เครื่องโทรสาร
                        </label>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          disabled={disable}
                          id="chk_remember3"
                          onChange={() => {
                            setCheckedCom(!checkedCom);
                            setemployee({
                              ...employee,
                              emp_four_com: !checkedCom,
                            });
                          }}
                          checked={employee.emp_four_com}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk_remember3"
                        >
                          เครื่องคอมพิวเตอร์
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      <div className="form-group">
                        {" "}
                        <textarea
                          style={{ height: "100px", fontSize: "25px" }}
                          className="form-control"
                          rows={4}
                          disabled={disable}
                          value={employee.emp_four_program}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_four_program: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>ท่านใช้โปรแกรมสำเร็จรูปอะไรได้บ้าง</label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      <div className="form-group">
                        {" "}
                        <textarea
                          style={{ height: "100px", fontSize: "25px" }}
                          className="form-control"
                          rows={4}
                          disabled={disable}
                          value={employee.emp_two_experience}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_two_experience: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>ประสบการณ์ ความชำนาญ หรือความสามารถอื่นๆ</label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-4">
                      <label htmlFor="">
                        ท่านขับขี่ยานพาหนะอะไร ได้บ้าง
                        {employee.emp_guarantor === null ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          disabled={disable}
                          id="chk_remember4"
                          onChange={() => {
                            setCheckedMoto(!checkedMoto);
                            setemployee({
                              ...employee,
                              emp_four_moto: !checkedMoto,
                            });
                          }}
                          checked={employee.emp_four_moto}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk_remember4"
                        >
                          รถจักรยานยนต์
                        </label>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          disabled={disable}
                          id="chk_remember5"
                          onChange={() => {
                            setCheckedCar(!checkedCar);
                            setemployee({
                              ...employee,
                              emp_four_car: !checkedCar,
                            });
                          }}
                          checked={employee.emp_four_car}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk_remember5"
                        >
                          รถยนต์
                        </label>
                      </div>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          disabled={disable}
                          id="chk_remember6"
                          onChange={() => {
                            setCheckedTruck(!checkedTruck);
                            setemployee({
                              ...employee,
                              emp_four_truck: !checkedTruck,
                            });
                          }}
                          checked={employee.emp_four_truck}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chk_remember6"
                        >
                          รถบรรทุก
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="text_nation_id"
                          value={employee.emp_four_vehicle_choose}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_four_vehicle_choose: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            ----เลือก----
                          </option>
                          <option value="1">มี</option>
                          <option value="2">ไม่มี</option>
                        </select>
                        <label>ท่านมีพาหนะของตนเองหรือไม่</label>
                      </div>
                    </div>
                    {employee.emp_four_vehicle_choose === "1" ? (
                      <div className="col-12 col-md-12 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            disabled={disable}
                            value={employee.emp_four_vehicle_which}
                            onChange={(e) =>
                              setemployee({
                                ...employee,
                                emp_four_vehicle_which: e.target.value,
                              })
                            }
                            required
                          />
                          <label htmlFor="">มีพาหนะอะไรบ้าง</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="custom-tabs-four-page-5"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-page-5-tab"
                >
                  <div>
                    <h3 className="mb-2 mt-5-head mb-2">ข้อมูลเพิ่มเติม</h3>
                  </div>
                  <div className="row">
                    <div className="col-5 col-md-5 col-xl-6">
                      <div class="form-group">
                        <select
                          className="form-control custom-select select2 mt-3"
                          type="text"
                          required
                          id="text_nation_id"
                          value={employee.emp_was_ever_been_judged}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_was_ever_been_judged: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            ----เลือก----
                          </option>
                          <option value="1">เคย</option>
                          <option value="2">ไม่เคย</option>
                        </select>
                        <label>
                          เคยต้องคำพิพากษา
                          หรือคำสั่งที่ชอบด้วยกฎหมายในคดีใดหรือไม่
                          {employee.emp_was_ever_been_judged === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>

                    {employee.emp_was_ever_been_judged == "1" ? (
                      <div className="col-5 col-md-5 col-xl-6">
                        <div class="form-group mt-3">
                          <input
                            type="text"
                            disabled={disable}
                            className="form-control"
                            value={employee.emp_was_ever_been_judged_because}
                            onChange={(e) =>
                              setemployee({
                                ...employee,
                                emp_was_ever_been_judged_because:
                                  e.target.value,
                              })
                            }
                            required
                            placeholder=""
                          />
                          <label htmlFor="">เพราะอะไร..</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 col-xl-7">
                      <div className="form-group">
                        {" "}
                        <textarea
                          style={{ height: "100px", fontSize: "25px" }}
                          className="form-control"
                          rows={3}
                          disabled={disable}
                          value={employee.emp_reason_to_match_work}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_reason_to_match_work: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>เหตุผลที่ท่านคิดว่าเหมาะสมกับงานที่สมัคร</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 col-xl-8">
                      <div className="form-group">
                        {" "}
                        <textarea
                          style={{ height: "100px", fontSize: "25px" }}
                          className="form-control"
                          rows={3}
                          disabled={disable}
                          value={employee.emp_feature_which_success}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_feature_which_success: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>
                          ท่านคิดว่าคุณสมบัติใด
                          ที่จะทำให้ท่านประสบความสำเร็จในการงาน
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 col-xl-8">
                      <div className="form-group">
                        {" "}
                        <textarea
                          style={{ height: "100px", fontSize: "25px" }}
                          className="form-control"
                          rows={2}
                          disabled={disable}
                          value={employee.emp_has_ideal_work}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_has_ideal_work: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>ท่านมีอุดมคติในการทำงานอย่างไร</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 col-xl-8">
                      <div className="form-group">
                        {" "}
                        <textarea
                          style={{ height: "100px", fontSize: "25px" }}
                          className="form-control"
                          rows={2}
                          disabled={disable}
                          value={employee.emp_target_next_three_year}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_target_next_three_year: e.target.value,
                            })
                          }
                          defaultValue={""}
                          required
                        />
                        <label>
                          ท่านตั้งเป้าหมายในอนาคตของท่านอีก 3 ปี
                          ข้างหน้าไว้อย่างไร
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          disabled={disable}
                          value={employee.emp_recommender}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_recommender: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="">
                          มีผู้แนะนำท่านมาสมัครงานหรือไม่ ชื่อ ~ สกุล
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-2">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          disabled={disable}
                          required
                          value={employee.emp_relationship_1}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_relationship_1: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="">ความสัมพันธ์</label>
                      </div>
                    </div>

                    <div className="col-12 col-md-5 col-xl-4">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          disabled={disable}
                          required
                          value={employee.emp_know_person_in_work}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_know_person_in_work: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="">
                          ท่านรู้จักพนักงานของบริษัท ฯ หรือไม่ ชื่อ ~ สกุล
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-xl-2">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          disabled={disable}
                          required
                          value={employee.emp_relationship_2}
                          onChange={(e) =>
                            setemployee({
                              ...employee,
                              emp_relationship_2: e.target.value,
                            })
                          }
                        />
                        <label htmlFor="">ความสัมพันธ์</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-5">
                      <div className="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          disabled={disable}
                          required
                          id="text_nation_id"
                          value={employee.emp_can_over_time}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_can_over_time: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            ----เลือก----
                          </option>
                          <option value="1">ทำไม่ได้</option>
                          <option value="2">ทำได้</option>

                          {employee_can_over_time.map((el, index) => {
                            return (
                              <option key={index} value={el.emp_can_over_time}>
                                {el.emp_can_over_time}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          ท่านสามารถทำงานล่วงเวลาได้หรือไม่
                          {employee.emp_can_over_time === null ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    {employee.emp_can_over_time == "1" ? (
                      <div className="col-12 col-md-5 col-xl-5">
                        <div class="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required
                            placeholder=""
                            value={employee.emp_can_over_time_because}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_can_over_time_because: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">เนื่องจาก..</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-7 col-xl-7">
                      <div class="form-group ">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="text_nation_id"
                          value={employee.emp_can_over_time_choose}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_can_over_time_choose: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            -------------เลือก-----------
                          </option>
                          <option value="1">
                            วันละ 2 ชั่วโมง สัปดาห์ละ 6 วัน รวม 12 ชั่วโมง
                          </option>
                          <option value="2">
                            คนละ 3 ชั่วโมง สัปดาห์ละ 6 วัน รวม 18 ชั่วโมง
                          </option>
                          <option value="3">
                            คนละ 4 ชั่วโมง สัปดาห์ละ 6 วัน รวม 24 ชั่วโมง
                          </option>
                          {employee_can_over_time_choose.map((el) => {
                            return (
                              <option value={el.emp_can_over_time_choose}>
                                {el.emp_can_over_time_choose}
                              </option>
                            );
                          })}
                        </select>
                        <label htmlFor="">
                          ถ้าท่านสามารถทำงานล่วงเวลาได้
                          ไม่เกิน(เลือกข้อใดข้อหนึ่ง)
                          {employee.emp_can_over_time === "2" &&
                          employee.emp_can_over_time_choose === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-5">
                      <div className="form-group">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="text_nation_id"
                          value={employee.emp_can_start_now}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_can_start_now: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled selected>
                            ----เลือก----
                          </option>
                          <option value="1">ได้เลย</option>
                          <option value="2">ไม่ได้</option>

                          {employee_can_start_now.map((el) => {
                            return (
                              <option value={el.emp_can_start_now}>
                                {el.emp_can_start_now}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          ท่านสามารถเริ่มงานได้เลยหรือไม่
                          {employee.emp_can_start_now === null ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    {employee.emp_can_start_now == "2" ? (
                      <div className="col-12 col-md-6 col-xl-6">
                        <div class="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required
                            placeholder=""
                            value={employee.emp_can_start_now_because}
                            disabled={disable}
                            onChange={(e) => {
                              setemployee({
                                ...employee,
                                emp_can_start_now_because: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">เนื่องจาก..</label>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* row 15 ----------------------------------------- */}

                  {/* row 16 ----------------------------------------- */}
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                      <div class="form-group">
                        <DatePicker
                          selected={employee.emp_start_date}
                          disabled={disable}
                          wrapperClassName="w-100"
                          id="text_national_exp_date"
                          dateFormat={"dd-MM-yyyy"}
                          locale="th"
                          onChange={async (date) => {
                            setemployee({
                              ...employee,
                              emp_start_date: date,
                            });
                            // console.log(date, "aaa");
                          }}
                          startDate={employee.emp_start_date}
                          showYearDropdown
                          showMonthDropdown
                          customInput={<CustomInput />}
                        ></DatePicker>
                        <label>
                          สามารถเริ่มงานได้วันที่
                          {employee.emp_start_date === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* row 16 ----------------------------------------- */}

                  {/* row 17 ----------------------------------------- */}
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-5">
                      <div class="form-group ">
                        <NumberFormat
                          type="text"
                          format={"###-###-####"}
                          className="form-control"
                          required
                          placeholder=""
                          value={employee.emp_phone}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_phone: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="">
                          เบอร์โทรศัพท์ที่ติดต่อท่านได้สะดวกที่สุด หมายเลข
                          {employee.emp_phone === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div class="form-group ">
                        <input
                          type="text"
                          className="form-control"
                          required
                          placeholder=""
                          value={employee.emp_location}
                          disabled={disable}
                          onChange={(e) => {
                            setemployee({
                              ...employee,
                              emp_location: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="">
                          ที่อยู่ ที่สะดวกต่อการติดต่อ
                          {employee.emp_location === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-xl-2"></div>
                    <div
                      className="col-12 col-md-12 col-xl-8"
                      style={{
                        // border: "2px solid red",
                        textAlign: "center",
                        font: "2rem",
                      }}
                    >
                      <div style={{ border: '2px solid red', width: '90%', padding: '10px', margin: '0 auto' }}>
                        <span
                          style={{ 
                            fontSize: '1.5rem',
                            fontWeight: '500'
                          }}
                        >
                          &emsp;&emsp;ข้าพเจ้าขอรับรองว่า
                          ข้อมูลและรายละเอียดทั้งหมดข้างต้นนี้
                          เป็นความจริงทุกประการ
                          หากปรากฏในภายหลังว่าข้อความตอนหนึ่งตอนใดไม่เป็นความจริงตามนี้แล้ว
                          ข้าพเจ้ายินยอม ให้บริษัทฯ บอกเลิกจ้างได้ทันที
                          โดยไม่ขอรับเงินค่าชดเชยใดๆ และจะไม่ใช้สิทธิ์เรียก
                          ร้องโดยประการใด ๆ ทั้งสิ้น
                          ข้าพเจ้าเข้าใจและยินยอมที่จะปฏิบัติตามกฎระเบียบของบริษัทฯ
                          และพร้อมที่จะขอทดลองงานภายใน 90 วัน
                          ตามเงื่อนไขทุกประการ
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Info;
