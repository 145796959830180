import Login_BG_img from "../../assets/img/Login/Login_BG.png";
import Login_BG2_img from "../../assets/img/Login/Login_BG2.png";
import logoTTT_img from "../../assets/img/button_menu/logoTTT.png";
import logo_smz_img from "../../assets/img/Logo/logo_smz.png";
import logo_kubota_img from "../../assets/img/Logo/logo_kubota.png";
import down_menulogoTTT_02_img from "../../assets/img/Logo/down_menulogoTTT_02.png";
import LogoPMRP_TTT_08_img from "../../assets/img/Logo/LogoPMRP_TTT_08.png";
import Icon_Materailplaning_img from "../../assets/img/button_menu/Icon_Materailplaning.png";
import Icon_productionplanning_img from "../../assets/img/button_menu/Icon_productionplanning.png";
import Icon_warehousemanage_img from "../../assets/img/button_menu/Icon_warehousemanage.png";
import Icon_deliveryaddon_img from "../../assets/img/button_menu/Icon_deliveryaddon.png";
import Icon_quality_control_img from "../../assets/img/button_menu/Icon_quality_control.png";
import menu_bt_blank_img from "../../assets/img/button_menu/menu_bt_blank.png";
import Icon_costmonitoring_img from "../../assets/img/button_menu/Icon_costmonitoring.png";
import userdefault  from '../../assets/img/userlogo/user.jpg';
import Icon_factory_img from  "../../assets/img/Landing_Page/Icon_factory.png";
import factory_img from  "../../assets/img/Landing_Page/factory.jpg";
import Icon_contact_img from  "../../assets/img/Landing_Page/Icon_contact.png";
import Icon_Choose_img from  "../../assets/img/Landing_Page/Icon_Choose.png";
import Icon_settings_img from "../../assets/img/button_menu/Icon_settings.png"
import Icon_logout_img from "../../assets/img/button_menu/Icon_logout.png"
import LogoTTT_2 from "../../assets/img/Logo/logoTTT2.png";
import number_1 from "../../assets/img/incentive/1.png";
import number_2 from "../../assets/img/incentive/2.png";
import number_3 from "../../assets/img/incentive/3.png";
import number_4 from "../../assets/img/incentive/4.png";
import number_5 from "../../assets/img/incentive/5.png";
import product_suggest_1 from "../../assets/img/incentive/suggest_1.png";
import product_suggest_2 from "../../assets/img/incentive/suggest_2.png";
import Ai_default_ocr from "../../view2/AI_Feature/ocr_image/default_image.png"
import Icon_File_Noti from "../../assets/img/booking/File_Icon_Noti.jpg";


export const LogoTTT2 ={
    imgs:LogoTTT_2
}
export const Icon_settings ={
    imgs:Icon_logout_img
}
export const Icon_logout ={
    imgs:Icon_settings_img
}
export const Login_BG ={
    imgs:Login_BG_img
}

export const Login_BG2 ={
    imgs:Login_BG2_img
}

export const logoTTT ={
    imgs:logoTTT_img
}

export const logo_smz ={
    imgs:logo_smz_img
}

export const logo_kubota ={
    imgs:logo_kubota_img
}
export const down_menulogoTTT_02 ={
    imgs:down_menulogoTTT_02_img
}
export const LogoPMRP_TTT_08 ={
    imgs:LogoPMRP_TTT_08_img
}
export const Icon_Materailplaning ={
    imgs:Icon_Materailplaning_img
}
export const Icon_productionplanning ={
    imgs:Icon_productionplanning_img
}

export const Icon_warehousemanage ={
    imgs:Icon_warehousemanage_img
}

export const Icon_deliveryaddon ={
    imgs:Icon_deliveryaddon_img
}

export const Icon_quality_control ={
    imgs:Icon_quality_control_img
}

export const menu_bt_blank ={
    imgs:menu_bt_blank_img
}

export const Icon_costmonitoring ={
    imgs:Icon_costmonitoring_img
}

export const Icon_Choose ={
    imgs:Icon_Choose_img
}

export const Icon_contact ={
    imgs:Icon_contact_img
}
export const factory ={
    imgs:factory_img
}
export const Icon_factory ={
    imgs:Icon_factory_img
}

export const userdefault_img ={
    imgs:userdefault  
}

export const numberProduct ={
    img_1: number_1,
    img_2: number_2,
    img_3: number_3,
    img_4: number_4,
    img_5: number_5,
}

export const productSuggest ={
    img_1: product_suggest_1,
    img_2: product_suggest_2,
}

export const Aifeature ={
    imgs:Ai_default_ocr
}

export const IconFileNoti ={
    imgs: Icon_File_Noti
}