import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import moment from "moment";

import Rack_Component from "../Warehouse/Component/Rack_Component";
import NumberFormat from "react-number-format";

function WarehouseAVE({ mode }) {
  const { id } = useParams();

  const [sizeUnit, setSizeUnit] = useState("");

  const [productData, setProductData] = useState([]);

  const [warehouseData, setWarehouseData] = useState({
    warehouse_name: "",
    warehouse_width: "",
    warehouse_depth: "",
    warehouse_height: "",
  });
  // state tabs
  let [tabs, setTabs] = useState([]);

  // state เพื่อเก็บข้อมูลที่ได้จากการเรียกใช้ API getWarehouseByID
  const [tabsOld, settabsOld] = useState([]);

  // state delete tabs
  const [deleteTabs, setDeleteTabs] = useState([]);

  // temp state for position product
  const [positionProduct, setPositionProduct] = useState([]);

  // state delete position product
  const [deletePositionProduct, setDeletePositionProduct] = useState([]);

  const [productLog, setProductLog] = useState({
    in_stock: [],
    in_lot: [],
  });

  function backPeviousPage() {
    if (mode !== "View") {
      Swal.fire({
        title: "คุณต้องการยกเลิกหรือไม่?",
        text: "ข้อมูลที่คุณกรอกจะไม่ถูกบันทึก!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่",
        cancelButtonText: "ไม่",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/IoT_Master_Data/Warehouse";
        }
      });
    } else {
      window.location.href = "/IoT_Master_Data/Warehouse";
    }
  }

  function numberToAlphabet(num) {
    let result = "";
    while (num > 0) {
      const remainder = (num - 1) % 26; // Adjusting for 1-based indexing
      result = String.fromCharCode(65 + remainder) + result; // 65 is ASCII code for 'A'
      num = Math.floor((num - 1) / 26); // Adjusting for 1-based indexing
    }
    return result;
  }

  async function AddRack() {
    let tapLength = tabs.length;
    // check rack area is not over warehouse area
    let warehouseArea =
      warehouseData.warehouse_width * warehouseData.warehouse_depth;
    let all_rack_area = 0;
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].rack_width && tabs[i].rack_depth) {
        all_rack_area += tabs[i].rack_width * tabs[i].rack_depth;
      } else {
        Swal.fire("Error", "กรุณากรอกขนาดของ Rack", "error");
        return;
      }
    }
    if (all_rack_area >= warehouseArea) {
      Swal.fire(
        "ไม่สามารถเพิ่ม Rack ใหม่ได้",
        "พื้นที่รวมของ Rack เกินพื้นที่ของ Warehouse",
        "error"
      );
    } else {
      let alphabet = numberToAlphabet(tapLength + 1);
      const newTab = {
        index: tabs.length,
        rack_name: alphabet,
        rack_width: "",
        rack_depth: "",
        rack_height: "",
        rack_axis_x: "",
        rack_position_format_x: "1", // 1 = Alphabet, 2 = Number
        rack_separate_char_x: "",
        rack_axis_y: "",
        rack_position_format_y: "1", // 1 = Alphabet, 2 = Number
        rack_separate_char_y: "",
        rack_is_axis_z: false,
        rack_axis_z: "",
        rack_position_format_z: "1", // 1 = Alphabet, 2 = Number
        rack_separate_char_z: "",
        tableArray: [],
        tableArrayDup: [],
      };
      // Add the new tab to the tabs array
      setTabs([...tabs, newTab]);
    }
  }

  async function DeleteRack() {
    Swal.fire({
      title: "คุณต้องการลบ Rack ใช่หรือไม่?",
      text: "ระบบจะลบ Rack ล่าสุดที่เพิ่มเข้ามา และข้อมูลที่คุณกรอกจะหายไป!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        // push remove tab to deleteTabs if tab has rack_id
        if (tabs[tabs.length - 1].rack_id) {
          setDeleteTabs([...deleteTabs, tabs[tabs.length - 1]]);
        }
        // remove the last tab from the tabs array
        setTabs(tabs.slice(0, -1));
      }
    });
  }

  async function handleUpdateTabs(index, newTab, isSave) {
    setPositionProduct(newTab.tableArrayDup);
    let tableArrayTemp = [];
    if (newTab.rack_axis_x && newTab.rack_axis_y) {
      let count = 0;
      for (let i = 0; i < newTab.rack_axis_x; i++) {
        for (let j = 0; j < newTab.rack_axis_y; j++) {
          if (newTab.rack_is_axis_z) {
            for (let k = 0; k < newTab.rack_axis_z; k++) {
              // Generate position based on newTab.position_format_x, newTab.position_format_y, newTab.position_format_z
              let position = generatePosition(newTab, i, j, k);
              // Check if the tempId is same
              let isSamewithOldPosition = false;
              if (
                mode === "Edit" &&
                tabsOld[index]?.tableArray[count]?.tempId ===
                  newTab.tableArray[count]?.tempId
              ) {
                isSamewithOldPosition = true;
              }

              let findIndexDup = newTab.tableArrayDup.findIndex(
                (x) => x.tempId === positionProduct[count]?.tempId
              );

              if (findIndexDup !== -1 && !isSave) {
                tableArrayTemp.push({
                  tempId: "xyz" + tableArrayTemp.length,
                  rp_id: newTab.tableArrayDup[findIndexDup].rp_id,
                  position,
                  product: newTab.tableArrayDup[findIndexDup].product || [],
                  ref_size: newTab.tableArrayDup[findIndexDup].ref_size || null,
                  is_in_stock: newTab.tableArrayDup[findIndexDup].is_in_stock,
                  in_stock: newTab.tableArrayDup[findIndexDup].in_stock,
                });
              } else {
                tableArrayTemp.push({
                  tempId: "xyz" + tableArrayTemp.length,
                  rp_id: isSamewithOldPosition
                    ? tabsOld[index]?.tableArray[count]?.rp_id
                    : null,
                  position,
                  product: newTab.tableArray[count]?.product || [],
                  ref_size: newTab.tableArray[count]?.ref_size || null,
                  is_in_stock: newTab.tableArray[count]?.is_in_stock,
                  in_stock: newTab.tableArray[count]?.in_stock,
                });
              }
              count++;
            }
          } else {
            // Generate position without axis_z
            let position = generatePosition(newTab, i, j);
            // Check if the position is the same with the old position
            let isSamewithOldPosition = false;
            if (
              mode === "Edit" &&
              tabsOld[index]?.tableArray[count]?.tempId ===
                newTab.tableArray[count]?.tempId
            ) {
              isSamewithOldPosition = true;
            }
            let findIndexDup = newTab.tableArrayDup.findIndex(
              (x) => x.tempId === positionProduct[count]?.tempId
            );
            if (findIndexDup !== -1 && !isSave) {
              tableArrayTemp.push({
                tempId: "xyz" + tableArrayTemp.length,
                rp_id: newTab.tableArrayDup[findIndexDup].rp_id,
                position,
                product: newTab.tableArrayDup[findIndexDup].product || [],
                ref_size: newTab.tableArrayDup[findIndexDup].ref_size || null,
                is_in_stock: newTab.tableArrayDup[findIndexDup].is_in_stock,
                in_stock: newTab.tableArrayDup[findIndexDup].in_stock,
              });
            } else {
              tableArrayTemp.push({
                tempId: "xyz" + tableArrayTemp.length,
                rp_id: isSamewithOldPosition
                  ? tabsOld[index]?.tableArray[count]?.rp_id
                  : null,
                position,
                product: newTab.tableArray[count]?.product || [],
                ref_size: newTab.tableArray[count]?.ref_size || null,
                is_in_stock: newTab.tableArray[count]?.is_in_stock,
                in_stock: newTab.tableArray[count]?.in_stock,
              });
            }
            count++;
          }
        }
      }
      if (isSave) {
        newTab.tableArrayDup = newTab.tableArray;
      }
    }
    newTab.tableArray = tableArrayTemp;

    const newTabs = [...tabs];
    newTabs[index] = newTab;
    setTabs(newTabs);
  }

  function generatePosition(newTab, x, y, z) {
    let firstChar = getPositionChar(newTab.rack_position_format_x, x);
    let secondChar = getPositionChar(newTab.rack_position_format_y, y);
    let thirdChar =
      z !== undefined ? getPositionChar(newTab.rack_position_format_z, z) : "";

    return (
      firstChar?.toString() +
      (newTab.rack_separate_char_x || "") +
      secondChar?.toString() +
      (newTab.rack_separate_char_y || "") +
      thirdChar?.toString() +
      (z !== undefined ? newTab.rack_separate_char_z || "" : "")
    );
  }

  function getPositionChar(format, value) {
    if (format === "2") {
      return value + 1;
    } else if (format === "1") {
      return numberToAlphabet(value + 1);
    } else {
      return "";
    }
  }

  async function getSizeUnit() {
    let temp = {
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios
      .request({
        method: "POST",
        url: Configs.API_URL_AIOI_Back + "/api/sizeUnit/getSizeUnit",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: temp,
      })
      .then((response) => {
        setSizeUnit(response.data.data[0].sc_name);
      })
      .catch((error) => {
        Swal.fire("Error", "Error getSizeUnit", "error");
      });
  }

  async function checkSave() {
    let error = [];
    let warehouseSize = "";
    let wh_width = 0;
    let wh_depth = 0;
    let wh_height = 0;
    if (
      warehouseData.warehouse_name?.trim() === "" ||
      !warehouseData.warehouse_name
    ) {
      error.push("กรุณากรอก Warehouse Name");
    }
    if (warehouseData.warehouse_width === 0 || !warehouseData.warehouse_width) {
      error.push("กรุณากรอก Width");
    } else {
      wh_width = Number(warehouseData.warehouse_width);
    }
    if (warehouseData.warehouse_depth === 0 || !warehouseData.warehouse_depth) {
      error.push("กรุณากรอก Depth");
    } else {
      wh_depth = Number(warehouseData.warehouse_depth);
    }
    if (
      warehouseData.warehouse_height === 0 ||
      !warehouseData.warehouse_height
    ) {
      error.push("กรุณากรอก Height");
    } else {
      wh_height = Number(warehouseData.warehouse_height);
    }
    let warehouseArea = wh_width * wh_depth;
    let all_rack_area = 0;
    // let all_height = 0;
    if (tabs.length > 0) {
      for (let i = 0; i < tabs.length; i++) {
        let errorMessage = "Rack ลำดับที่ " + (i + 1) + " :";
        if (tabs[i].rack_name?.trim() === "" || !tabs[i].rack_name) {
          errorMessage += " กรุณากรอก Rack Name <br>";
        }
        if (Number(tabs[i].rack_width) === 0 || !tabs[i].rack_width) {
          errorMessage += " กรุณากรอก Width <br>";
        } else {
          if (Number(tabs[i].rack_width) > wh_width) {
            errorMessage += " Width ต้องไม่เกิน Width ของ Warehouse <br>";
          }
        }
        if (Number(tabs[i].rack_depth) === 0 || !tabs[i].rack_depth) {
          errorMessage += " กรุณากรอก Depth <br>";
        } else {
          if (Number(tabs[i].rack_depth) > wh_depth) {
            errorMessage += " Depth ต้องไม่เกิน Depth ของ Warehouse <br>";
          }
        }
        if (Number(tabs[i].rack_height) === 0 || !tabs[i].rack_height) {
          errorMessage += " กรุณากรอก Height <br>";
        } else {
          if (Number(tabs[i].rack_height) > wh_height) {
            errorMessage += " Height ต้องไม่เกิน Height ของ Warehouse <br>";
          }
        }
        if (Number(tabs[i].rack_axis_x) === 0 || !tabs[i].rack_axis_x) {
          errorMessage += " กรุณากรอก Axis X <br>";
        }
        if (Number(tabs[i].rack_axis_y) === 0 || !tabs[i].rack_axis_y) {
          errorMessage += " กรุณากรอก Axis Y <br>";
        }
        if (tabs[i].rack_is_axis_z) {
          if (Number(tabs[i].rack_axis_z) === 0 || !tabs[i].rack_axis_z) {
            errorMessage += " กรุณากรอก Axis Z";
          }
        }
        if (errorMessage !== "Rack ลำดับที่ " + (i + 1) + " :") {
          error.push(errorMessage);
        }

        if (tabs[i].rack_width && tabs[i].rack_depth) {
          all_rack_area +=
            Number(tabs[i].rack_width) * Number(tabs[i].rack_depth);
        }
      }
      const canFit = RectangleContainer({
        width: wh_width,
        height: wh_depth,
        smallerRectangles: tabs,
      });
      if (!canFit) {
        error.push("พื้นที่ของ Rack รวมต้องไม่เกินพื้นที่ของ Warehouse");
      }
    }

    // if (all_rack_area > warehouseArea) {
    //   error.push("พื้นที่ของ Rack รวมต้องไม่เกินพื้นที่ของ Warehouse");
    // }

    // error.push("XXXXX");

    let err_message = "";
    if (error.length > 0) {
      for (let index = 0; index < error.length; index++) {
        err_message += error[index] + "<br>";
      }
    }
    return err_message;
  }

  async function saveWarehouse() {
    let error = await checkSave();
    if (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด!",
        html: error,
        icon: "error",
      });
    } else {
      let tempSave = {
        oem_id: getOem(),
        company_id: getUser().com,
        user: getUser().name + " " + getUser().surname,
        fup: getUser().fup,
        warehouse_name: warehouseData.warehouse_name?.trim() || null,
        warehouse_width: warehouseData.warehouse_width,
        warehouse_depth: warehouseData.warehouse_depth,
        warehouse_height: warehouseData.warehouse_height,
        rack: tabs,
      };

      await axios
        .request({
          method: "POST",
          url: Configs.API_URL_AIOI_Back + "/api/warehouse/addWarehouse",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempSave,
        })
        .then((response) => {
          if (response.data.data === true) {
            Swal.fire({
              title: "บันทึกสำเร็จ",
              icon: "success",
              showConfirmButton: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1500,
            }).then(() => {
              window.location.href = "/IoT_Master_Data/Warehouse";
            });
          } else {
            Swal.fire("Error", response.data.data, "error");
          }
        })
        .catch((error) => {
          Swal.fire("Error", "Error saveWarehouse", "error");
        });
    }
  }

  async function getWarehouse() {
    let tempGetByID = {
      oem_id: getOem(),
      company_id: getUser().com,
      warehouse_id: id,
    };
    await axios
      .request({
        method: "POST",
        url: Configs.API_URL_AIOI_Back + "/api/warehouse/getWarehouseByID",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempGetByID,
      })
      .then((response) => {
        let temp = response.data.data[0];
        setWarehouseData({
          warehouse_name: temp.warehouse_name,
          warehouse_width: temp.warehouse_width,
          warehouse_depth: temp.warehouse_depth,
          warehouse_height: temp.warehouse_height,
        });
        for (let i = 0; i < temp.rack.length; i++) {
          for (let j = 0; j < temp.rack[i].tableArray.length; j++) {
            //
            temp.rack[i].tableArray[j].tempId = "xyz" + j;
            temp.rack[i].tableArrayDup[j].tempId = "xyz" + j;

            for (
              let k = 0;
              k < temp.rack[i].tableArray[j].product.length;
              k++
            ) {
              let pidx = productData.findIndex(
                (x) => x.id === temp.rack[i].tableArray[j].product[k].id
              );
              if (pidx !== -1) {
                temp.rack[i].tableArray[j].product[k].no = productData[pidx].no;
                temp.rack[i].tableArray[j].product[k].name =
                  productData[pidx].name;
                temp.rack[i].tableArrayDup[j].product[k].no =
                  productData[pidx].no;
                temp.rack[i].tableArrayDup[j].product[k].name =
                  productData[pidx].name;
                temp.rack[i].tableArray[j].product[k].is_in_stock =
                  productData[pidx].is_in_stock;
                temp.rack[i].tableArray[j].product[k].in_stock =
                  productData[pidx].in_stock;
                temp.rack[i].tableArrayDup[j].product[k].is_in_stock =
                  productData[pidx].is_in_stock;
                temp.rack[i].tableArrayDup[j].product[k].in_stock =
                  productData[pidx].in_stock;
              }
            }
          }
        }
        setTabs(temp.rack);
        let rackOld = JSON.parse(JSON.stringify(temp.rack));
        settabsOld(rackOld);
      })
      .catch((error) => {
        Swal.fire("Error", "Error getWarehouse", "error");
      });
  }

  async function editWarehouse() {
    let error = await checkSave();
    if (error) {
      Swal.fire({
        title: "เกิดข้อผิดพลาด!",
        html: error,
        icon: "error",
      });
    } else {
      Swal.fire({
        title: "กำลังบันทึกข้อมูล",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let tempSave = {
        oem_id: getOem(),
        company_id: getUser().com,
        user: getUser().name + " " + getUser().surname,
        fup: getUser().fup,
        warehouse_id: id,
        warehouse_name: warehouseData.warehouse_name?.trim() || null,
        warehouse_width: warehouseData.warehouse_width,
        warehouse_depth: warehouseData.warehouse_depth,
        warehouse_height: warehouseData.warehouse_height,
        rack: tabs,
        deleteRack: deleteTabs,
      };

      await axios
        .request({
          method: "POST",
          url: Configs.API_URL_AIOI_Back + "/api/warehouse/editWarehouse",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempSave,
        })
        .then((response) => {
          Swal.close();
          if (response.data.data === true) {
            Swal.fire({
              title: "บันทึกสำเร็จ",
              icon: "success",
              showConfirmButton: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1500,
            }).then(() => {
              window.location.href = "/IoT_Master_Data/Warehouse";
            });
          } else {
            Swal.fire("Error", response.data.data, "error");
          }
        })
        .catch((error) => {
          Swal.close();
          Swal.fire("Error", "Error saveWarehouse", "error");
        });
    }
  }

  // getProduct
  async function getProduct() {
    let tempfilter = {
      filter: null,
      // company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios
      .request({
        method: "post",
        url: Configs.API_URL + "/product/filterproductformodal",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempfilter,
      })
      .then((response) => {
        setProductData(response.data);
      })
      .catch((error) => {
        Swal.fire("Error", "Cannot get product", "error");
      });
  }

  function RectangleContainer({ width, height, smallerRectangles }) {
    // Calculate total area of the container
    const containerArea = width * height;

    // Calculate total area of the smaller rectangles
    const totalArea = smallerRectangles.reduce(
      (acc, rect) =>
        acc + Number(rect.rack_width || 0) * Number(rect.rack_depth || 0),
      0
    );

    // Check if any single smaller rectangle has an area equal to or greater than the container's area
    const hasLargeRectangle = smallerRectangles.some(
      (rect) =>
        Number(rect.rack_width || 0) * Number(rect.rack_depth || 0) >=
        containerArea
    );

    // If any single smaller rectangle is too large, return false
    if (hasLargeRectangle || totalArea > containerArea) {
      return false;
    }

    // Sort smaller rectangles by area in descending order
    const sortedRectangles = smallerRectangles
      .slice()
      .sort((a, b) => b.width * b.height - a.width * a.height);

    // Initialize variables for positioning
    let currentX = 0;
    let currentY = 0;
    let maxHeightInRow = 0;

    // Function to check if a rectangle fits in the given space
    const doesFit = (rectWidth, rectHeight) => {
      return currentX + rectWidth <= width && currentY + rectHeight <= height;
    };

    // Calculate positions for each rectangle
    for (const rect of sortedRectangles) {
      // Initialize position property for each rectangle
      rect.position = { x: 0, y: 0 };

      // Check if the rectangle fits in its original orientation
      if (doesFit(Number(rect.rack_width || 0), Number(rect.rack_depth || 0))) {
        rect.position = { x: currentX, y: currentY };
        currentX += Number(rect.rack_width || 0);
        maxHeightInRow = Math.min(maxHeightInRow, Number(rect.rack_depth || 0));
        continue;
      }

      // Check if the rectangle fits in its rotated orientation
      if (doesFit(Number(rect.rack_depth || 0), Number(rect.rack_width || 0))) {
        rect.position = { x: currentX, y: currentY };
        currentX += Number(rect.rack_depth || 0);
        maxHeightInRow = Math.min(maxHeightInRow, Number(rect.rack_width || 0));
        continue;
      }

      // If neither orientation fits, move to the next row
      currentX = 0;
      currentY += maxHeightInRow;
      maxHeightInRow = 0;

      // Check again if the rectangle fits in its original orientation
      if (doesFit(Number(rect.rack_width || 0), Number(rect.rack_depth || 0))) {
        rect.position = { x: currentX, y: currentY };
        currentX += Number(rect.rack_width || 0);
        maxHeightInRow = Math.min(maxHeightInRow, Number(rect.rack_depth || 0));
        continue;
      }

      // Check again if the rectangle fits in its rotated orientation
      if (doesFit(Number(rect.rack_depth || 0), Number(rect.rack_width || 0))) {
        rect.position = { x: currentX, y: currentY };
        currentX += Number(rect.rack_depth || 0);
        maxHeightInRow = Math.min(maxHeightInRow, Number(rect.rack_width || 0));
        continue;
      }

      // If neither orientation fits, the rectangle cannot be placed in the container
      return false;
    }

    // All rectangles fit inside the container
    return true;
  }

  async function GetStockProductInWarehouse() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
      warehouse_id: id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getStockProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response) => {
        for (let i = 0; i < productData.length; i++) {
          let idx = response.data.findIndex(
            (x) => x.product_id === productData[i].id
          );
          if (idx !== -1) {
            productData[i].is_in_stock = true;
            productData[i].in_stock = response.data[idx].lp_amount;
          } else {
            productData[i].is_in_stock = false;
            productData[i].in_stock = 0;
          }
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  useEffect(async () => {
    await getSizeUnit();
    await getProduct();
  }, []);

  useEffect(async () => {
    if (mode !== "Add" && id) {
      await GetStockProductInWarehouse();
      await getWarehouse();
    }
  }, [productData]);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>
                    Warehouse {mode}
                    {"  "}
                  </h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">IoT Master Data</li>
                    <li className="breadcrumb-item active">Warehouse</li>
                    <li className="breadcrumb-item active">{mode}</li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              {mode === "View" ? (
                <div className="row mb-2">
                  <div className="col-4 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={() => {
                        backPeviousPage();
                      }}
                      class="btn btn-block btn-danger "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row mb-2">
                  <div className="col-4 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={async () => {
                        if (mode === "Add") {
                          saveWarehouse();
                        } else if (mode === "Edit") {
                          await editWarehouse();
                        }
                      }}
                      class="btn btn-block btn-success "
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-4 col-md-3 col-xl-1">
                    <button
                      type="button"
                      onClick={() => {
                        backPeviousPage();
                      }}
                      class="btn btn-block btn-danger "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}

              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4 ">
                      <div class="form-group">
                        <input
                          type="text"
                          className="form-control"
                          required
                          value={warehouseData.warehouse_name}
                          onChange={(e) => {
                            setWarehouseData({
                              ...warehouseData,
                              warehouse_name: e.target.value,
                            });
                          }}
                          disabled={mode === "View"}
                        />
                        <label>
                          Warehouse Name{" "}
                          {warehouseData.warehouse_name?.trim() === "" ||
                          !warehouseData.warehouse_name ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          allowLeadingZeros={false}
                          allowEmptyFormatting={false}
                          thousandSeparator={true}
                          thousandsGroupStyle="thousand"
                          decimalScale={2}
                          required
                          value={warehouseData.warehouse_width}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            let temp = value;
                            setWarehouseData({
                              ...warehouseData,
                              warehouse_width: temp,
                            });
                          }}
                          disabled={mode === "View"}
                        />
                        <label>
                          Width({sizeUnit})
                          {warehouseData.warehouse_width === 0 ||
                          !warehouseData.warehouse_width ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          allowLeadingZeros={false}
                          allowEmptyFormatting={false}
                          thousandSeparator={true}
                          thousandsGroupStyle="thousand"
                          decimalScale={2}
                          required
                          value={warehouseData.warehouse_depth}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            let temp = value;
                            setWarehouseData({
                              ...warehouseData,
                              warehouse_depth: temp,
                            });
                          }}
                          disabled={mode === "View"}
                        />
                        <label>
                          Depth({sizeUnit}){" "}
                          {warehouseData.warehouse_depth === 0 ||
                          !warehouseData.warehouse_depth ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="col-12 col-md-4 col-xl-4">
                      <div class="form-group">
                        <NumberFormat
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          allowLeadingZeros={false}
                          allowEmptyFormatting={false}
                          thousandSeparator={true}
                          thousandsGroupStyle="thousand"
                          decimalScale={2}
                          required
                          value={warehouseData.warehouse_height}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            let temp = value;
                            setWarehouseData({
                              ...warehouseData,
                              warehouse_height: temp,
                            });
                          }}
                          disabled={mode === "View"}
                        />
                        <label>
                          Height({sizeUnit}){" "}
                          {warehouseData.warehouse_height === 0 ||
                          !warehouseData.warehouse_height ? (
                            <span className="text-danger">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-2 col-xl-2">
                      <button
                        type="button"
                        onClick={() => {
                          AddRack();
                        }}
                        // data-dismiss="modal"
                        className="btn btn-block btn-primary"
                        hidden={mode === "View"}
                      >
                        Add Rack
                      </button>
                    </div>
                    {tabs.length > 0 ? (
                      <div className="col-4 col-md-2 col-xl-2">
                        <button
                          type="button"
                          onClick={() => {
                            DeleteRack();
                          }}
                          // data-dismiss="modal"
                          className="btn btn-block btn-danger"
                          hidden={mode === "View"}
                        >
                          Delete Rack
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="content pl-4 pr-4">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <Rack_Component
                tabs={tabs}
                handleUpdateTabs={handleUpdateTabs}
                tabsOld={tabsOld}
                mode={mode}
                sizeUnit={sizeUnit}
                wh_id={id}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default WarehouseAVE;
