import React from "react";
import Construccomponent from "../../component/Construction";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
function QIAI(params) {
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="qc" activemenu="qiai" submenu="none" /> */}
      <Construccomponent name="Quanlity Inspection (AI)" />
      {/* <Footter /> */}
    </div>
  );
}
export default QIAI;
