import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
import Configs from "../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

import Basic_Data from "./Component/Basic_Data";
import Advance_Data from "./Component/Advance_Data";
import FDA from "./Component/FDA";
import Supplier from "./Component/Supplier";

function AEV_Product({ mode }) {
  const { id } = useParams();

  const [pageMode, setPageMode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const [inputData, setInputData] = useState({
    Product_Id: id,
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    user_name: getUser().name + " " + getUser().surname,
  });

  let [inputDataTab1, setInputDataTab1] = useState({
    data: {},
  });
  let [inputDataTab2, setInputDataTab2] = useState({
    data: {},
  });
  let [inputDataTab3, setInputDataTab3] = useState({
    data: {},
  });
  let [inputDataTab4, setInputDataTab4] = useState({
    data: {},
  });

  let [errorListTab1, setErrorListTab1] = useState({
    data: [],
  });
  let [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });
  let [errorListTab3, setErrorListTab3] = useState({
    data: [],
  });
  let [errorListTab4, setErrorListTab4] = useState({
    data: [],
  });

  let [functionSaveTab1, setFunctionSaveTab1] = useState({
    data: null,
  });
  let [functionSaveTab2, setFunctionSaveTab2] = useState({
    data: null,
    data2: null,
  });
  let [functionSaveTab3, setFunctionSaveTab3] = useState({
    data: null,
    data2: null,
  });
  let [functionSaveTab4, setFunctionSaveTab4] = useState({
    data: null,
  });

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];

        await functionSaveTab1.data();
        error_list.push(...errorListTab1.data);
        if (inputDataTab1?.data?.Is_Product_Kitting === false) {
          await functionSaveTab2.data();
          error_list.push(...errorListTab2.data);

          await functionSaveTab3.data();
          error_list.push(...errorListTab3.data);

          await functionSaveTab4.data();
          error_list.push(...errorListTab4.data);
        }
        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          await functionSaveTab2.data2();

          await functionSaveTab3.data2();
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            let axiosData = {};

            const temp = inputData;
            temp["tab_1"] = inputDataTab1.data;
            temp["tab_2"] = inputDataTab2.data;
            temp["tab_3"] = inputDataTab3.data;
            temp["tab_4"] = inputDataTab4.data.map(el => {
              el.buy_price = el.buy_price?.replace(/,/g, '');
              el.exchange_rate = el.exchange_rate?.replace(/,/g, '');
              return el;
            });

            let checkUpload = true;

            if (mode === "add") {
              axiosData = {
                method: "post",
                url: Configs.API_URL_cuteplus + "/api/product/CheckAddProduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
            }

            if (mode === "edit") {
              axiosData = {
                method: "post",
                url: Configs.API_URL_cuteplus + "/api/product/CheckEditProduct",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              };
            }

            await axios(axiosData)
              .then(async function (response) {
                if (mode === "add") {
                  axiosData = {
                    method: "post",
                    url: Configs.API_URL_cuteplus + "/api/product/AddProduct",
                    headers: {
                      Authorization: "Bearer " + getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "application/json",
                    },
                    data: temp,
                  };
                }

                if (mode === "edit") {
                  axiosData = {
                    method: "post",
                    url: Configs.API_URL_cuteplus + "/api/product/EditProduct",
                    headers: {
                      Authorization: "Bearer " + getToken(),
                      "X-TTT": Configs.API_TTT,
                      "Content-Type": "application/json",
                    },
                    data: temp,
                  };
                }

                if (checkUpload) {
                  await axios(axiosData)
                    .then(async function (response) {
                      if (response) {
                        if (mode === "edit") {
                          await deleteImagesAndFilesOfProduct(inputDataTab2.data?.p_advance_upload_files.del, "advance");
                          await deleteImagesAndFilesOfProduct(inputDataTab3.data?.p_fda_upload_files.del, "fda");
                        } else {
                          await uploadAndSaveImageAdvance(mode === "edit" ? id : response.data, "imgs_product_advance");
                          await uploadAndSaveImageAdvance(mode === "edit" ? id : response.data, "files_product_fda");
                        }
                      }
                      Swal.fire({
                        icon: "success",
                        title: "บันทึกข้อมูลสำเร็จ",
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        showConfirmButton: false,
                        timer: 2000,
                      }).then(() => {
                        window.location.href =
                          "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus";
                      });
                    })
                    .catch(function (error) {
                      Swal.fire({
                        title: "Error",
                        text: error.response.data.error.message,
                        icon: "error",
                      });
                    });
                }
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
            "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus";
        }
      });
    } else {
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_plus";
    }
  }

  const deleteImagesAndFilesOfProduct = async (del_list, saveOf) => {
    let tempData1 = {
      del_list: del_list, //inputDataTab2.data?.p_advance_upload_files.del,
      user_id: getUser().fup,
      user_name: `${getUser().name} ${getUser().surname}`
    }
    await axios({
      method: "post",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/delImagesAndFiles",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData1
    })
      .then(async (response) => {
        if (response.data) {
          if (saveOf === "advance") {
            await uploadAndSaveImageAdvance(mode === "edit" ? id : response, "imgs_product_advance");
          } else if (saveOf === "fda") {
            await uploadAndSaveImageAdvance(mode === "edit" ? id : response, "files_product_fda");
          }
        }
      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถลบเอกสารได้",
        });
      });
  }

  const uploadAndSaveImageAdvance = async (product_id, fileGroup) => {
    let arrFiles = []

    if (fileGroup === "imgs_product_advance") {
      arrFiles = inputDataTab2.data?.p_advance_upload_files.data?.map(el => {
        let temp = {}
        temp.name = el.sbd_document_name
        temp.typeFile = "ภาพ"
        temp.path = el.sbd_path_document
        temp.isProfile = el.is_checked

        if (el.ul_id) temp.ul_id = el.ul_id;
        return temp
      });
    } else {
      arrFiles = inputDataTab3.data?.p_fda_upload_files.new?.map(el => {
        let temp = {}
        temp.name = el.file_name
        temp.typeFile = "เอกสาร"
        temp.path = el.file
        temp.remark = el.remark
        return temp
      });
    }
    let data = new FormData();
    data.append("company_id", getUser().com);
    data.append("oem_id", getOem());
    data.append("user_id", getUser().fup);
    data.append(
      "user_name",
      `${getUser().name} ${getUser().surname}`
    );
    data.append("Project", "Cute_Products_Plus");
    data.append("Menu", "Product");
    data.append("fileGroup", fileGroup);
    data.append("Ref", product_id);
    data.append("Profile", JSON.stringify(arrFiles));
    await axios({
      method: "post",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/saveDataUploadFile2",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    })
      .then(async (response) => {

      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถอัปโหลดเอกสารได้",
        });
      });
  }

  useEffect(async () => {
    if (mode === "add") {
      setPageMode("Add");
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
    } else if (mode === "view") {
      setPageMode("Read");
      setIsDisabled(true);
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Product</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={async () => {
                      await saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Product Company : {inputDataTab1.data.Product_Company}
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Product Name : {inputDataTab1.data.Product_Name_Eng}
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id={"tab-1"}
                          data-toggle="pill"
                          href={"#Basic_Data-tab"}
                          role="tab"
                          aria-controls={"Basic_Data-tab"}
                          aria-selected="true"
                        >
                          Basic Data
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"tab-2"}
                          data-toggle="pill"
                          href={"#Advance_Data-tab"}
                          role="tab"
                          aria-controls={"Advance_Data-tab"}
                          aria-selected="false"
                        >
                          Advance Data
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"tab-3"}
                          data-toggle="pill"
                          href={"#FDA-tab"}
                          role="tab"
                          aria-controls={"FDA-tab"}
                          aria-selected="false"
                        >
                          FDA
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"tab-4"}
                          data-toggle="pill"
                          href={"#Supplier-tab"}
                          role="tab"
                          aria-controls={"Supplier-tab"}
                          aria-selected="false"
                        >
                          Supplier
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-four-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id={"Basic_Data-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-1"}
                      >
                        <Basic_Data
                          id={id}
                          mode={mode}
                          isDisabled={isDisabled}
                          inputData={inputDataTab1}
                          setInputData={setInputDataTab1}
                          errorList={errorListTab1}
                          setErrorList={setErrorListTab1}
                          functionSave={functionSaveTab1}
                          setFunctionSave={setFunctionSaveTab1}
                        />
                      </div>

                      <div
                        className="tab-pane fade"
                        id={"Advance_Data-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-2"}
                      >
                        {/* <Advance_Data /> */}
                        <Advance_Data
                          id={id}
                          mode={mode}
                          isDisabled={isDisabled}
                          Is_Product_Kitting={inputDataTab1?.data?.Is_Product_Kitting}
                          inputData={inputDataTab2}
                          setInputData={setInputDataTab2}
                          errorList={errorListTab2}
                          setErrorList={setErrorListTab2}
                          functionSave={functionSaveTab2}
                          setFunctionSave={setFunctionSaveTab2}
                        />
                      </div>

                      <div
                        className="tab-pane fade"
                        id={"FDA-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-3"}
                      >
                        <FDA
                          id={id}
                          mode={mode}
                          isDisabled={isDisabled}
                          Is_Product_Kitting={inputDataTab1?.data?.Is_Product_Kitting}
                          inputData={inputDataTab3}
                          setInputData={setInputDataTab3}
                          errorList={errorListTab3}
                          setErrorList={setErrorListTab3}
                          functionSave={functionSaveTab3}
                          setFunctionSave={setFunctionSaveTab3}
                        />
                      </div>

                      <div
                        className="tab-pane fade"
                        id={"Supplier-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-4"}
                      >
                        <Supplier
                          id={id}
                          mode={mode}
                          isDisabled={isDisabled}
                          Is_Product_Kitting={inputDataTab1?.data?.Is_Product_Kitting}
                          inputData={inputDataTab4}
                          setInputData={setInputDataTab4}
                          errorList={errorListTab4}
                          setErrorList={setErrorListTab4}
                          functionSave={functionSaveTab4}
                          setFunctionSave={setFunctionSaveTab4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AEV_Product;
