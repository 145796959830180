import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../config";
import { getOem, getToken, getUser } from "./Common";

export const checkDataSame_Garantee = (employee) => {
  let b = [];
  let c = [];
  for (let i = 0; i < employee.emp_guarantee.length; i++) {
    if (b.indexOf(employee.emp_guarantee[i].emp_garantee_name) < 0) {
    } else {
      c.push(employee.emp_guarantee[i]);
    }
  }
  return c.length;
};
export const checkDataSame_Garantor = (employee) => {
  let b = [];
  let c = [];
  for (let i = 0; i < employee.emp_guarantor.length; i++) {
    if (b.indexOf(employee.emp_guarantor[i].emp_garantor_name) < 0) {
    } else {
      c.push(employee.emp_guarantor[i]);
    }
  }
  return c.length;
};

export const checkDataSame_WorkHistory = (employee) => {
  let b = [];
  let c = [];
  for (let i = 0; i < employee.emp_work_history.length; i++) {
    if (b.indexOf(employee.emp_work_history[i].emp_work_name) < 0) {
    } else {
      c.push(employee.emp_work_history[i]);
    }
  }
  return c.length;
};

export const checkDataSame_Talent = (employee) => {
  let b = [];
  let c = [];
  for (let i = 0; i < employee.emp_talen.length; i++) {
    if (b.indexOf(employee.emp_talen[i].emp_language) < 0) {
    } else {
      c.push(employee.emp_talen[i]);
    }
  }
  return c.length;
};

export const checkDataEmployee_Work = (target, check) => {
  // const modal = document.getElementById('modal-close1');
  // modal.removeAttribute("data-dismiss", "modal");
  const error_list = [];
  // for (let i = 0; i < check.length; i++) {
  //     if (check[i].emp_language == target.emp_language){
  //         let temp_err = {
  //             message: "ข้อมูลภาษา ซ้ำกัน",
  //         };
  //         error_list.push(temp_err);
  //     }
  // }
  if (target.emp_work_type === "") {
    if (target.emp_work_name == "") {
      let temp_err = {
        message: "กรุณเลือกรูปแบบงาน",
      };
      error_list.push(temp_err);
    }
  }
  if (target.emp_work_type === "ฝึกงาน") {
    target.emp_work_leave = "";
    target.emp_work_positon = "";
    if (target.emp_work_name == "") {
      let temp_err = {
        message: "กรุณากรอก สถานที่ทำงาน",
      };
      error_list.push(temp_err);
    }
    if (target.emp_work_details == "") {
      let temp_err = {
        message: "กรุณากรอก ลักษณะงานที่ทำ",
      };
      error_list.push(temp_err);
    }
    if (target.emp_work_start == "") {
      let temp_err = {
        message: "กรุณาเลือก วันที่เริ่มฝึกงาน",
      };
      error_list.push(temp_err);
    }

    if (target.emp_work_finish == "") {
      let temp_err = {
        message: "กรุณาเลือก วันที่สิ้นสุดฝึกงาน",
      };
      error_list.push(temp_err);
    }
  } else if (target.emp_work_type === "ทำงาน") {
    if (target.emp_work_name == "") {
      let temp_err = {
        message: "กรุณากรอก สถานที่ทำงาน",
      };
      error_list.push(temp_err);
    }

    if (target.emp_work_positon == "") {
      let temp_err = {
        message: "กรุณากรอก ตำแหน่งเริ่มต้น",
      };
      error_list.push(temp_err);
    }

    if (target.emp_work_start == "") {
      let temp_err = {
        message: "กรุณาเลือก วันที่เริ่มทำงาน",
      };
      error_list.push(temp_err);
    }

    if (target.emp_work_finish == "") {
      let temp_err = {
        message: "กรุณาเลือก วันที่ออกจากงาน",
      };
      error_list.push(temp_err);
    }

    if (target.emp_work_details == "") {
      let temp_err = {
        message: "กรุณากรอก ลักษณะงานที่ทำ",
      };
      error_list.push(temp_err);
    }

    if (target.emp_work_leave == "") {
      let temp_err = {
        message: "กรุณากรอก สาเหตุที่ออกจากงาน",
      };
      error_list.push(temp_err);
    }
  }

  if (error_list.length > 0) {
    var err_message = "";
    for (var e = 0; e < error_list.length; e++) {
      err_message += "<br/>" + error_list[e].message;
    }

    Swal.fire("Error", err_message, "error");
    return true;
  } else {
    // modal.setAttribute("data-dismiss", "modal");
    return false;
  }
};

export const checkDataEmployee_talen = (target, check) => {
  // const modal = document.getElementById('modal-close');
  // modal.removeAttribute("data-dismiss", "modal");
  const error_list = [];
  // for (let i = 0; i < check.length; i++) {
  //     if (check[i].emp_language == target.emp_language){
  //         let temp_err = {
  //             message: "ข้อมูลภาษา ซ้ำกัน",
  //         };
  //         error_list.push(temp_err);
  //     }
  // }

  if (target.emp_language !== "") {
    // let temp_err = {
    //     message: "กรุณากรอก ภาษา",
    // };
    // error_list.push(temp_err);
    if (target.emp_read_name == "") {
      let temp_err = {
        message: "กรุณาเลือก ระดับการอ่าน",
      };
      error_list.push(temp_err);
    }

    if (target.emp_write_name == "") {
      let temp_err = {
        message: "กรุณาเลือก ระดับการเขียน",
      };
      error_list.push(temp_err);
    }

    if (target.emp_listen_name == "") {
      let temp_err = {
        message: "กรุณาเลือก ระดับการฟัง",
      };
      error_list.push(temp_err);
    }

    if (target.emp_speak_name == "") {
      let temp_err = {
        message: "กรุณาเลือก ระดับการพูด",
      };
      error_list.push(temp_err);
    }
  }

  if (error_list.length > 0) {
    var err_message = "";
    for (var e = 0; e < error_list.length; e++) {
      err_message += "<br/>" + error_list[e].message;
    }

    Swal.fire("Error", err_message, "error");
    return true;
  } else {
    // modal.setAttribute("data-dismiss", "modal");
    return false;
  }
};

export const checkDataEmployee_garantee = (target, check) => {
  const error_list = [];
  // for (let i = 0; i < check.length; i++) {
  //     if (check[i].emp_garantee_name == target.emp_garantee_name){
  //         let temp_err = {
  //             message: "ชื่อบุคคลที่รับรองท่าน ซ้ำกัน",
  //         };
  //         error_list.push(temp_err);
  //     }
  // }

  if (target.emp_garantee_name == "") {
    let temp_err = {
      message: "กรุณากรอก ชื่อบุคคลที่รับรองท่าน",
    };
    error_list.push(temp_err);
  }

  if (target.emp_garantee_relation == "") {
    let temp_err = {
      message: "กรุณากรอก ความสัมพันธ์",
    };
    error_list.push(temp_err);
  }

  /* if (target.emp_garantee_location_work == "") {
        let temp_err = {
            message: "กรุณากรอก สถานที่ทำงาน",
        };
        error_list.push(temp_err);
    } */

  /* if (target.emp_garantee_position == "") {
        let temp_err = {
            message: "กรุณากรอก ตำแหน่ง",
        };
        error_list.push(temp_err);
    } */

  if (target.emp_garantee_phone == "") {
    let temp_err = {
      message: "กรุณากรอก เบอร์โทรศัพท์",
    };
    error_list.push(temp_err);
  }

  if (error_list.length > 0) {
    var err_message = "";
    for (var e = 0; e < error_list.length; e++) {
      err_message += "<br/>" + error_list[e].message;
    }

    Swal.fire("Error", err_message, "error");
    return true;
  }
  return false;
};

export const checkDataEmployee_garantor = (target, check) => {
  const error_list = [];

  // console.log("target", target);
  // for (let i = 0; i < check.length; i++) {
  //     if (check[i].emp_garantor_name == target.emp_garantor_name){
  //         let temp_err = {
  //             message: "ชื่อบุคคลที่รับรองท่าน ซ้ำกัน",
  //         };
  //         error_list.push(temp_err);
  //     }
  // }

  if (target.emp_garantor_name == "") {
    let temp_err = {
      message: "กรุณากรอก ชื่อบุคคลที่รับรองท่าน",
    };
    error_list.push(temp_err);
  }

  if (target.emp_garantor_relation == "") {
    let temp_err = {
      message: "กรุณากรอก ความสัมพันธ์",
    };
    error_list.push(temp_err);
  }

  /* if (target.emp_garantor_location_work == "") {
        let temp_err = {
            message: "กรุณากรอก สถานที่ทำงาน",
        };
        error_list.push(temp_err);
    } */

  /* if (target.emp_garantor_position == "") {
        let temp_err = {
            message: "กรุณากรอก ตำแหน่ง",
        };
        error_list.push(temp_err);
    } */

  if (target.emp_garant_phone == "") {
    let temp_err = {
      message: "กรุณากรอก เบอร์โทรศัพท์",
    };
    error_list.push(temp_err);
  }

  if (error_list.length > 0) {
    var err_message = "";
    for (var e = 0; e < error_list.length; e++) {
      err_message += "<br/>" + error_list[e].message;
    }

    Swal.fire("Error", err_message, "error");
    return true;
  }
  return false;
};

export const upload_fileToDBBase = async (file) => {
  // console.log("filname", e.target.files);
  let temp = "";
  return new Promise(async (resolve, reject) => {
    try {
      if (file) {
        // const file = file;
        // console.log("ss", file);
        var data = new FormData();
        data.append("Profile", file);

        var config_uploadProfile = {
          method: "post",
          url: Configs.API_URL_IMG_cuteplus + "/api/upload/uploadFile",
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        await axios(config_uploadProfile)
          .then(function (response) {
            // console.log("filre", response.data[0].path);
            if (response.data) {
              temp = response.data[0].path;
              resolve(response.data[0].path);
            } else {
              Swal.fire("Error", "File name !!!", "error");
              reject(null);
            }
            resolve(response.data[0].path);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "File name !!!", "error");
            reject(error);
          });
      }
      resolve(temp);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const getDateCompo = async (data, data1, data2, path) => {
  // console.log("data", data);
  // console.log("data1", data1);
  // console.log("data2", data2);

  let pushData = {
    user_id: getUser().fup,
    company_id: getUser().com,
    oem_id: getOem(),
    product_av: data,
    product_pdf: data1,
    product_img: data2,
  };
  // console.log("pushData", pushData);
  await axios({
    method: "post",
    url: Configs.API_URL_cuteplus + "/api/product" + path,
    headers: {
      Authorization: "Bearer " + getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: pushData,
  })
    .then(async function (response) {
      // console.log("response", response);
      if (response.data) {
        Swal.fire({
          icon: "success",
          title: path == "/saveTestAdvance" ? "Saved" : "Updated",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          // window.location.href = "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category";
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
    });
};

export const getDropdown = (dd_name) => {
  return new Promise(async (resolve, reject) => {
    let pushData = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      dropdown_name: dd_name,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getDropdownAdvanceProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(function (response) {
        // console.log("response0------", response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
        Swal.fire({
          icon: "warning",
          title: "เกิดข้อผิดพลาด",
          text: error.response.data.error.message,
        });
        reject([]); // Reject the promise with the error
      });
  });
};

export function isFileImage(file) {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

  return file && acceptedImageTypes.includes(file["type"]);
}

export async function DownloadFile(product_file, by) {
  let file = {
    name: "",
    path: "",
  };
  if (by === "flow") {
    file.name = product_file.file_name_flow;
    file.path = product_file.file_path_flow;
  } else {
    file.name = product_file.file_name_ingre;
    file.path = product_file.file_path_ingre;
  }
  await axios({
    method: "get",
    url: Configs.API_URL_IMG_cuteplus + file.path,
    headers: {
      Authorization: "Bearer " + getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    responseType: "blob",
  }).then(async (response) => {
    const type = response.headers["content-type"];
    const blob = new Blob([response.data]);
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", file.name); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export const product_variable = {
  id: "",
  product_image: "", // R
  product_image_name: "", // R
  product_image_path: "", // R
  product_detail: "",
  license: "", // R
  shelf_life_full: "", // R
  garantee: "", // R
  weight: "", // R
  unit_weight: "", // R
  product_time: "", // R
  delivery_time: "", // R
  // Selling Price
  sp_pc: "", // R
  sp_pack: "", // R
  sp_ctn: "", // R
  // Selling Cost
  sc_pc: "", // R
  sc_pack: "", // R
  sc_ctn: "", // R
  // Sale Unit
  su_pc_pack: "", // R
  su_pc_ctn: "", // R
  su_pack_ctn: "", // R
  // Import Price
  pc: "",
  ctn: "",
  currency: "",
  // Pallet
  ctn_layer: "",
  layer: "",
  ctn_x_plt: "",
  height: "",
  // TARIF CODE
  original_side: "",
  rate_os: "",
  thailand: "",
  rate_th: "",
  // Agreement
  form_type: "",
  rate_ft: "",
  // Dimention
  pc_w: "",
  pc_l: "",
  pc_h: "",
  pack_w: "",
  pack_l: "",
  pack_h: "",
  ctn_w: "",
  ctn_l: "",
  ctn_h: "",
  // Auto Cal
  m3_auto_cal: "",
  //////////
  net_weight: "", // R
  unit_net_weight: "", // R
  gross_weight: "", // R
  unit_gross_weigth: "", // R
  moq: "", // R
  carton_from_supplier: "", // R
  // Container Size
  container_20: "", // R
  container_20_type: "", // R
  container_40: "", // R
  container_40_type: "", // R
  art_work: false,
  remark: "",
};
