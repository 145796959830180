
import jsPDF from "jspdf";
import { Get_font_pdf, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature, getAcademy, getComConfig } from "../../Utils/Common";
import moment from "moment";
import QRCode2 from "qrcode";
//import { getUser, getToken, setOem ,removeOem ,getOem } from "../../Utils/Common";
function Perchase_po_address(reportdata, company, status) {
  console.log(company, status)

  var Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
  // var OEM_logo = `${Configs.API_URL_IMG + oem_data.logo_path}`;
  var prod_img = `${Configs.API_URL_IMG}`;
  var com_chackname = getComConfig().com_name ? getComConfig().com_name : "TTT";
  const doc = new jsPDF("p", "mm", "a4");
  console.log(reportdata)

  doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
  doc.addFileToVFS('THSarabunNew.ttf', Get_font_pdf_th2())
  doc.addFont("ROCK.TTF", "Rocki", "normal");
  doc.setFont("Rocki");
  doc.setFont("THSarabunNew");
  var counts = 0;
  for (let index = 0; index < reportdata.addressReport.length; index = index + 8) {



    /* doc.setDrawColor(0);
        doc.setFillColor("#272727");
        doc.rect(0, 0, 1080, 31, "F"); */

    // doc.addImage(Com_logo, "JPEG", 1, 1, 29, 29);
    // doc.addImage(OEM_logo, "JPEG", 180, 1, 29, 29);

    /* doc.setTextColor(0, 0, 0);
    
   if (com_chackname === "Central Spring co., Ltd.") {
    doc.setFontSize(30);
    doc.text(105, 20, "CENTRAL SPRING PART LIST", "center");
   } else {
    doc.setFontSize(40);
    doc.text(105, 20, "SHIMIZU PART LIST", "center");
   } */
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(18)

    doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
    doc.setFont('THSarabunNew')

    /*  doc.addFont('ANGSA.ttf', 'custom', 'normal');
     doc.setFont('custom'); */
    doc.text(105, 28, 'กรุณาส่งของตามที่อยู่นี้ (PLEASE DELIVER THE GOODS TO)', 'center')


    var row = 0;



    for (let i = 0; row < 8; i = i + 8) {

      var x = 0
      var y = 0
      let img_qr = "";

      if (!reportdata.addressReport[index + row]) {


      } else {


        if (row === 1) {
          x = 90
          y = 0
        }
        if (row === 2) {
          x = 0
          y = 60
        }
        if (row === 3) {
          x = 90
          y = 60
        }
        if (row === 4) {
          x = 0
          y = 120
        }
        if (row === 5) {
          x = 90
          y = 120
        }
        if (row === 6) {
          x = 0
          y = 180
        }
        if (row === 7) {
          x = 90
          y = 180
        }
        doc.setDrawColor(0, 0, 0);
        /*  QRCode2.toDataURL(
             reportdata[index+row].no.toString(),
             function (err, url) {
               img_qr = url;
             }
           ); */
        /*  doc.addImage( reportdata[index+row].img_qr, "JPEG", 15+x, 32+y, 40, 40);
   if (reportdata[index+row].imaget_path !== '' ) {
     doc.addImage(
       prod_img + reportdata[index+row].imaget_path,
       "JPEG",
       56+x,
       37+y,
       30,
       30
     ); 
   } */

        // doc.rect(56+x, 37+y, 30, 30);

        doc.setDrawColor(0, 0, 0);
        ////////row1////////
        doc.rect(20 + x, 32 + y, 80, 10);

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(16)
        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        doc.text(22 + x, 39 + y, `Order id : ${reportdata.po_no}`, 'left');
        ////////row 2////////

        doc.rect(20 + x, 32 + y, 80, 20);
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(16)
        doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
        doc.setFont('THSarabunNew')
        doc.text(22 + x, 49 + y, `ชื่อผู้รับ `, 'left');
        doc.text(42 + x, 49 + y, `${reportdata.addressReport[index].emp_name || ""}  ${reportdata.addressReport[index].emp_lastname || ""}`, 'left');
        ///////row 3 /////////
        doc.rect(20 + x, 42 + y, 20, 50);
        doc.text(30 + x, 67 + y, `ที่อยู่ `, 'center');
        let rowLoop = 0;
        const splitTitle = doc.splitTextToSize(reportdata.addressReport[index].emp_address || null, 60);
        for (let idx = 0; idx < splitTitle.length; idx++) {
          doc.text(42 + x, 63 + y + rowLoop, `${splitTitle[idx]}`, 'left');
          rowLoop += 5;
        }

        /////////row 4/////////////
        doc.rect(20 + x, 82 + y, 80, 10);
        doc.text(22 + x, 88 + y, `เบอร์โทร `, 'left');
        doc.text(42 + x, 88 + y, `${'00000000000'}`, 'left');
        doc.setDrawColor(0, 0, 0);
        doc.rect(20 + x, 32 + y, 80, 60);
        // doc.setDrawColor(255, 0, 0);
        /* doc.setDrawColor(192, 192, 192);
        doc.rect(0 + x, 32 + y, 105, 60); */
      }
      
      // console.log('splitTitle', splitTitle);
      //box 2



      row = row + 1;
      counts = reportdata.length - 8;



    }



    if (index < counts) { doc.addPage("l"); }

  }



  if (status === true) {
    return doc.output('datauristring');
} else {
    window.open(doc.output("bloburl"));
}


}


export default Perchase_po_address;
