import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import React, { useState, useEffect } from "react";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import moment from "moment";

function Table_Knowledge_Management() {
    const [Filter, setFilter] = useState({
        name: "",
    });

    const [KMdata, setKMdata] = useState({
        data: [],
    });

    const [modalKM, setModalKM] = useState(false);

    const [documentModalList, setDocumentModalList] = useState({
        data: [],
        new: [],
        old: [],
        del: [],
    });

    const [MDBT, setMDBT] = useState(true);

    async function GetKM_basic() {
        let temp = {
            oem_id: getOem(),
            company_id: getUser().com,
            name: Filter.name === "" ? null : Filter.name.trim()
        }
        axios({
            method: "post",
            url: Configs.API_URL_cuteplus + "/api/km/getKMfilter",
            headers: {
                Authorization: "Bearer " + getToken(),
                "Content-Type": "application/json",
                "X-TTT": Configs.API_TTT,
            },
            data: temp,
        })
            .then(function (response) {
                setKMdata({ data: response.data });
                KMdata.data = response.data;
            })
            .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
            });
    };

    function clear() {
        setFilter({
            ...Filter,
            name: ""
        });
        Filter.name = "";
        GetKM_basic();
    }

    function delKMdata(e) {
        // console.log('id :>> ', e.km_id);
        Swal.fire({
            title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
            text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            cancelButtonText: `ไม่`,
        }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    km_id: e.km_id,
                    oem_id: getOem(),
                    company_id: getUser().com
                }
                axios({
                    method: "post",
                    url:
                        Configs.API_URL_cuteplus + "/api/km/delKMdata",
                    headers: {
                        Authorization: "Bearer " + getToken(),
                        "Content-Type": "application/json",
                        "X-TTT": Configs.API_TTT,
                    },
                    data: data,
                })
                    .then(function (response) {
                        Swal.fire({
                            icon: "success",
                            text: "เสร็จสิ้น",
                            showConfirmButton: false,
                            timer: 1000,
                        }).then(() => {
                            window.location.reload();
                        });
                    })
                    .catch(function (error) {
                        Swal.fire({
                          title: "Error",
                          text: error.response.data.error.message,
                          icon: "error",
                        });
                    });
            }
        });
    }

    const getFileKM = async (id) => {
        await axios({
            method: "get",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileGroupAll/" + id + "/file_km",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            // data: tempData1,
        })
            .then(async (response) => {
                // console.log(response.data);
                if (response.data.length > 0) {
                    const filter_file = response.data.filter((el) => el.ul_is_use === true && el.ul_is_active === true);
                    const file_list = filter_file?.map((el, idx) => {
                        return {
                            ul_id: el.ul_id,
                            km_document_name: el.ul_file_name,
                            km_path_document: el.ul_path_file,
                        }
                    })
                    setDocumentModalList({
                        ...documentModalList,
                        data: file_list
                    });
                    documentModalList.data = file_list;
                    // console.log('file_list', file_list);
                }
            })
            .catch(async (error) => {
                Swal.fire({
                    icon: "error",
                    title: "ไม่สามารถอัปโหลดเอกสารได้",
                });
            });
    }

    useEffect(async () => {
        await GetKM_basic();
    }, []);

    const rowsData = [];
    for (let index = 0; index < KMdata.data.length; index += 1) {
        const rowItem = {};

        rowItem["no"] = <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>{index + 1}</div>;
        rowItem["no2"] = index + 1

        rowItem["kmName"] =
            <div style={{ whiteSpace: "nowrap", width: "100", textAlign: "center" }}>
                {KMdata.data[index].km_name ? KMdata.data[index].km_name : "-"}
            </div>
        rowItem["kmName2"] = KMdata.data[index].km_name ? KMdata.data[index].km_name : "-"

        rowItem["description"] =
            <div style={{ whiteSpace: "nowrap", minWidth: "200", textAlign: "center" }}>
                {KMdata.data[index].km_description ? KMdata.data[index].km_description : "-"}
            </div>
        rowItem["description2"] = KMdata.data[index].km_description ? KMdata.data[index].km_description : "-"

        rowItem["kmCategory"] =
            <div style={{ textAlign: "center" }}>
                {KMdata.data[index].ck_name ? KMdata.data[index].ck_name : "-"}
            </div>
        rowItem["kmCategory2"] = KMdata.data[index].ck_name ? KMdata.data[index].ck_name : "-"

        rowItem["kmURL"] =
            <div style={{ maxWidth: "200" }}>
              { KMdata.data[index].km_url ?
                (
                    <div>{ KMdata.data[index].km_url }</div>
                ):(
                    <div style={{ textAlign: "center" }}> - </div>
                )
              }
            </div>
        rowItem["kmURL2"] = KMdata.data[index].km_url ? KMdata.data[index].km_url : "-"

        rowItem["document"] = (
            <div className="row" style={{ flexWrap: "nowrap", textAlign: "center", justifyContent: "center" }}>
                <div className="col-12">
                    <button
                        key={KMdata.data[index]}
                        id={KMdata.data[index].km_id}
                        type="button"
                        className="btn btn-primary"
                        style={{ width: "100%", borderRadius: "10px" }}
                        onClick={async () => {
                            getFileKM(KMdata.data[index].km_id);
                            setModalKM(true);
                            // console.log('documentModalList', documentModalList)
                        }}
                    >
                        Download
                    </button>
                </div>
            </div>
        );

        rowItem["management"] = (
            <div className="row" style={{ flexWrap: "nowrap", textAlign: "center", justifyContent: "center" }}>
                <div className="col-xl-3 col-md-3 col-xs-3 ">
                    <a
                        key={KMdata.data[index]}
                        id="btn_delete"
                        className=" btn btn-xs "
                        onClick={() => {
                            window.location.href =
                                "/Warehouse_Management/Knowledge_management_Cuteplus/view/" + KMdata.data[index].km_id;
                        }}
                    >
                        <i class="fas fa-eye"></i>
                    </a>
                </div>
                <div
                    className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
                >
                    <a
                        key={KMdata.data[index]}
                        id="btn_delete"
                        className=" btn btn-xs "
                        onClick={() => {
                            window.location.href =
                                "/Warehouse_Management/Knowledge_management_Cuteplus/edit/" + KMdata.data[index].km_id;
                        }}
                    >
                        <i class="fas fa-pencil-alt"></i>
                    </a>
                </div>
                <div
                    className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.deleteBtn ? "hidden" : "visible" }} */
                >
                    <a
                        key={KMdata.data[index]}
                        id={KMdata.data[index]}
                        className=" btn btn-xs "
                        onClick={delKMdata.bind(this, KMdata.data[index])}
                    >
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </div>
            </div>
        );

        rowsData.push(rowItem);
    }

    const KM_Table = {
        columns: [
            {
                label: "No.",
                field: "no",
                // sort: "asc",
                width: 50,
            },
            {
                label: (
                    <div style={{ whiteSpace: "nowrap" }}>
                        KM Name
                    </div>
                ),
                field: "kmName",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Description",
                field: "description",
                // sort: "asc",
                width: 50,
            },
            {
                label: (
                    <div style={{ whiteSpace: "nowrap" }}>
                        KM Category
                    </div>
                ),
                field: "kmCategory",
                // sort: "asc",
                width: 50,
            },
            {
                label: "KM URL",
                field: "kmURL",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Document",
                field: "document",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Management",
                field: "management",
                // sort: "asc",
                width: 50,
            },
        ],

        rows: rowsData,
    };

    const rowDocDownload = [];
    for (let index = 0; index < documentModalList.data.length; index += 1) {
        const rowItem = {};

        rowItem["document_file"] =
          (
            <div style={{ paddingLeft: "1rem" }}>
                {documentModalList.data[index].km_document_name}
            </div>
          );

        rowItem["download_file"] = (
            <div className="row" style={{ flexWrap: "nowrap", textAlign: "center", justifyContent: "center" }}>
                <div className="col-12">
                    <a
                        href={documentModalList.data[index].km_path_document && documentModalList.data[index].km_document_name ? Configs.API_URL_IMG_cuteplus + `/static/${documentModalList.data[index].km_document_name}/${documentModalList.data[index].km_path_document}` : ""}
                        download={documentModalList.data[index].km_document_name ? documentModalList.data[index].km_document_name : ""}
                    >
                      <button
                        type="button"
                        class="btn btn-primary"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        Download
                      </button>
                    </a>
                </div>
            </div>
        );

        rowDocDownload.push(rowItem);
    }

    const fileKM_Table = {
        columns: [
            {
                label: "Document File",
                field: "document_file",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Download",
                field: "download_file",
                // sort: "asc",
                width: 50,
            },
        ],

        rows: rowDocDownload,
    };

    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-4 col-md-4 col-xl-6">
                                <h1>Knowledge management(Basic)</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/Welcome">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Warehouse management
                                    </li>
                                    <li className="breadcrumb-item active">Knowledge management(Basic)</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 col-xl-2">
                            <div class="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    required
                                    value={Filter.name}
                                    onChange={(e) => {
                                        setFilter({
                                            ...Filter,
                                            name: e.target.value,
                                        });
                                        Filter.name = e.target.value;
                                    }}
                                />
                                <label>KM Name</label>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="form-group ">
                                <button
                                    type="button"
                                    className="btn btn-block btn-info"
                                    onClick={() => {
                                        GetKM_basic();
                                    }}
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-xl-2">
                            <div className="form-group ">
                                <button
                                    type="button"
                                    className="btn btn-block btn-info"
                                    onClick={() => {
                                        clear();
                                    }}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 col-xl-2">
                            <button
                                type="button"
                                className="btn btn-block btn-primary"
                                onClick={() => {
                                    window.location.href =
                                        "/Warehouse_Management/Knowledge_management_Cuteplus/add";
                                }}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </section>
                <section className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-12 col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Knowledge Management(Basic)</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-xl-12">
                                                    <MDBDataTable
                                                        sortable={false}
                                                        disableRetreatAfterSorting={MDBT}
                                                        striped
                                                        bordered
                                                        hover
                                                        data={KM_Table}
                                                    />
                                                    <Modal
                                                        size="md"
                                                        className="modal-dialog modal-dialog-centered modal-md"
                                                        isOpen={modalKM}
                                                        toggle={() => {
                                                            setModalKM(false);
                                                        }}
                                                    >
                                                        <ModalHeader
                                                            toggle={() => {
                                                                setModalKM(false);
                                                            }}
                                                        >
                                                            <h4 className="modal-title" id="modalKM">
                                                                <b style={{ paddingLeft: "1rem" }}>Download Document</b>
                                                            </h4>
                                                        </ModalHeader>

                                                        <ModalBody>
                                                            <div className="row">
                                                                <div className="col-12 col-md-12 col-xl-12">
                                                                    <MDBDataTable
                                                                        sortable={false}
                                                                        disableRetreatAfterSorting={MDBT}
                                                                        striped
                                                                        bordered
                                                                        hover
                                                                        data={fileKM_Table}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </ModalBody>

                                                        <ModalFooter>
                                                            <button
                                                                type="button"
                                                                style={{ width: "100px" }}
                                                                className="btn btn-danger"
                                                                onClick={async () => {
                                                                    setModalKM(false);
                                                                }}
                                                            >
                                                                ปิด
                                                            </button>
                                                        </ModalFooter>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Table_Knowledge_Management;
