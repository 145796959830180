import Configs from "../../../../../config";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../../../../assets/font/pdf_font";
import jsPDF from "jspdf";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";

function Quotation_report(company, toolings, statusCheck) {
  let is_corporation =
    toolings.data[0].hq_ct_id !== null &&
    (toolings.data[0].hq_ct_name !== "ลูกค้าทั่วไป" ||
      toolings.data[0].hq_ct_id !== "7095dc2b-efeb-433e-af3f-449e9e90bdf2")
      ? true
      : false;
  let Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
  const doc = new jsPDF("p", "mm", "a4");
  let counts = 0; //index < 30
  let sumSubTotal = 0;
  if (is_corporation) {
    // นิติบุคคล
    let row = 0;
    for (let index = 0; index <= toolings.product.length; index += row) {
      doc.setFillColor("#8DC445");
      doc.rect(0, 0, 221, 25, "F");
      doc.addImage(Com_logo, "JPEG", 5, 0, 25, 25);
      doc.rect(0, 0, 5.5, 25, "F");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(20);
      doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");

      doc.text(32, 10, "บริษัท คิวท์ โปรดักส์ พลัส จำกัด");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(13);
      doc.text(93, 10, "0105552067869 (สำนักงานใหญ่)");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(12);
      doc.text(
        32,
        15,
        "เลขที่ 5 ซอยลาดพร้าว-วังหิน 32 แยก 1 ถนนลาดพร้าววังหิน แขวงลาดพร้าว กทม. 10230"
      );

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(32, 20, "Tel. +66 (0)-2539-0958-9");

      doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
      doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
      doc.setFont("THSarabunNewBold");
      doc.setTextColor("#FFFFFF");
      doc.setFontSize(32);
      doc.text(205, 12, "QUOTATION", "right");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(25);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(205, 21, "ใบเสนอราคา", "right");

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(5, 35, `ชื่อบริษัท/Name : ${toolings.data[0].hq_cus_name}`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "right");
      doc.setFont("THSarabunNew");
      doc.text(
        205,
        35,
        `ใบเสนอราคา เลขที่/Quotation No. : ${toolings.data[0].hq_no}`,
        "right"
      );

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        5,
        42,
        `ชื่อผู้ติดต่อ/Contact : ${toolings.data[0].hq_contract_name}`
      );

      const start_Date = toolings.data[0].hq_date.split("T")[0].split("-");
      let startDate = `${start_Date[2]}/${start_Date[1]}/${start_Date[0]}`;
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(205, 42, `วันที่/Date : ${startDate}`, "right");

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(5, 49, "ที่อยู่/Address :");

      let cus_address = "";
      let temp = [
        toolings.data[0].hq_cus_house_number,
        toolings.data[0].hq_cus_building,
        toolings.data[0].hq_swine,
        toolings.data[0].hq_soi,
        toolings.data[0].hq_road,
        toolings.data[0].hq_sub_district,
        toolings.data[0].hq_district,
        toolings.data[0].hq_province,
        toolings.data[0].hq_postcode,
      ];
      let TotalWord = 0;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i] !== null) {
          cus_address += temp[i] + " ";
          TotalWord = TotalWord + 1;
        }
      }
      let word1 = "";
      let word2 = "";
      let wordCount = 0;
      let y_axis = null;
      if (TotalWord > 6 && cus_address.length > 70) {
        for (let k = 0; k < temp.length; k++) {
          if (wordCount < 5 || word1.length < 50) {
            if (temp[k] !== null) {
              word1 += temp[k] + " ";
              wordCount += 1;
            }
          } else {
            if (temp[k] !== null) {
              word2 += temp[k] + " ";
            }
          }
        }
        y_axis = 63;
      } else {
        word1 = cus_address;
        y_axis = 56;
      }

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(30, 49, `${word1}`);
      doc.text(30, 56, `${word2}`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(186, 49, "ยืนยันราคาภายใน/Valid until :", "right");

      let validDate = toolings.data[0].hq_valid_unit;
      const [startDay, startMonth, startYear] = startDate.split("/");
      const [endDay, endMonth, endYear] = validDate.split("/");
      const checkStartDate = new Date(startYear, startMonth - 1, startDay);
      const checkEndDate = new Date(endYear, endMonth - 1, endDay);
      const timeDifference = checkEndDate - checkStartDate;
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      doc.text(187, 49, `${daysDifference} วัน`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(5, y_axis, "Tel :");

      const phoneNumber = toolings.data[0].hq_contract_tel.replace(/-/g, "");
      let formattedPhoneNumber =
        phoneNumber.substring(0, 3) +
        "-" +
        phoneNumber.substring(3, 6) +
        "-" +
        phoneNumber.substring(6);
      doc.text(14, y_axis, `${formattedPhoneNumber || ""}`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        48,
        y_axis,
        `Email : ${toolings.data[0].hq_contract_email || ""}`
      );

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        206,
        56,
        `เงื่อนไขการชำระเงิน/Term&Payment : ${toolings.term[0].pt_name} ${
          toolings.data[0].hq_term || ""
        } วัน`,
        "right"
      );

      /*-------------------------------------------------------------------------------*/
      //header

      doc.setFillColor("#8DC445");
      doc.roundedRect(4, 68, 202, 12, 1, 1, "F");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(11, 73, "ลำดับ", "center");
      doc.setFontSize(15);
      doc.text(11, 78, "No.", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(24.5, 73, "รูปสินค้า", "center");
      doc.setFontSize(15);
      doc.text(24.5, 78, "Picture", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(49, 73, "บาร์โค้ด", "center");
      doc.setFontSize(15);
      doc.text(49, 78, "Barcode", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(97.5, 73, "รายการสินค้า", "center");
      doc.setFontSize(15);
      doc.text(97.5, 78, "Item Description", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(142, 73, "จำนวน", "center");
      doc.setFontSize(15);
      doc.text(142, 78, "Qty.", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(160, 73, "ราคา", "center");
      doc.setFontSize(15);
      doc.text(160, 78, "Price", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(178, 73, "ส่วนลด", "center");
      doc.setFontSize(15);
      doc.text(178, 78, "Discount", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(196, 73, "จำนวนเงิน", "center");
      doc.setFontSize(15);
      doc.text(196, 78, "Total", "center");

      let gapEachProduct = 15;
      let isMore1page = false;
      row = 0;
      for (let i = 0; row < 10; i = i + gapEachProduct) {
        if (index + row < toolings.product.length) {
          let middleRow = 0;
          let imgMiddleRow = 0;
          //Item description to find row size
          let Brand_name =
            toolings.detail[index + row].brand_name !== null &&
            toolings.detail[index + row].brand_name !== undefined
              ? toolings.detail[index + row].brand_name
              : "";
          let Product_name =
            toolings.data[0].hq_lang === "ENG"
              ? toolings.product[index + row].hqp_product_name_eng ||
                toolings.detail[index + row].product_name_eng
              : toolings.data[0].hq_lang === "TH"
              ? toolings.product[index + row].hqp_product_name_th ||
                toolings.detail[index + row].product_name
              : "";
          let weight =
            toolings.detail[index + row].pd_weight !== null &&
            toolings.detail[index + row].pd_weight !== undefined &&
            toolings.detail[index + row].pd_weight !== "0"
              ? toolings.detail[index + row].pd_weight.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              : "";
          let Unit_name =
            toolings.data[0].hq_lang === "ENG"
              ? toolings.detail[index + row].pu_name
              : toolings.data[0].hq_lang === "TH"
              ? toolings.detail[index + row].pu_name_eng
              : "";
          let allItemDescription = `${Brand_name} ${
            Product_name !== null ? Product_name : ""
          } ${weight} ${
            Unit_name !== null &&
            Unit_name !== undefined &&
            toolings.detail[index + row].pd_weight !== "0"
              ? Unit_name
              : ""
          }`;
          let tempAllDes = allItemDescription.split(" ");
          let y_axis_des = null;
          let LineArray = [];
          if (tempAllDes.length > 6 && allItemDescription.length > 30) {
            for (let k = 0; k < tempAllDes.length; k++) {
              if (LineArray.length <= 0) {
                LineArray.push(tempAllDes[k]);
              } else if (LineArray.length > 0) {
                if (LineArray[LineArray.length - 1].length < 30) {
                  LineArray[LineArray.length - 1] += " " + tempAllDes[k];
                } else {
                  LineArray.push(tempAllDes[k]);
                }
              }
            }
            gapEachProduct = 10 + 6 * LineArray.length;
            y_axis_des = 86 + i;
            middleRow = 86 + i + (6 * LineArray.length) / 2;
            imgMiddleRow = 84 + i + (6 * LineArray.length) / 4;
          } else {
            gapEachProduct = 15;
            LineArray.push(allItemDescription);
            y_axis_des = 86 + i;
            middleRow = 89 + i;
            imgMiddleRow = 81 + i;
          }
          if (230 - (80 + i) < gapEachProduct) {
            isMore1page = true;
            break;
          }

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(11, middleRow, (index + row + 1).toString(), "center"); //no.

          //Picture
          let product_Image = `${
            toolings.pic[index + row]?.path_image !== "" &&
            toolings.pic[index + row]?.path_image !== null &&
            toolings.pic[index + row]?.path_image !== undefined
              ? toolings.pic[index + row]?.path_image?.indexOf("blob") >= 0
                ? toolings.pic[index + row]?.path_image
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${toolings.pic[index + row]?.path_name}/${
                    toolings.pic[index + row]?.path_image
                  }`
              : userdefault_img.imgs
          }`;
          doc.addImage(product_Image, "JPEG", 18, imgMiddleRow, 13, 13);

          //Barcode
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            49,
            middleRow,
            `${
              toolings.product[index + row].hqp_cus_barcode !== null &&
              toolings.product[index + row].hqp_cus_barcode !== undefined
                ? toolings.product[index + row].hqp_cus_barcode
                : ""
            }`,
            "center"
          );

          // Item Description
          for (let itemDes of LineArray) {
            doc.setTextColor("#292520"); //292520
            doc.setFontSize(15);
            doc.text(66, y_axis_des, `${itemDes}`);
            y_axis_des = y_axis_des + 6;
          }
          doc.text(
            66,
            y_axis_des,
            `${
              toolings.product[index + row].pd_sale_pc_pack !== null
                ? toolings.detail[index + row].pd_sale_pc_pack
                : "-"
            } /Pack  ${
              toolings.product[index + row].pd_sale_pc_ctn !== null
                ? toolings.detail[index + row].pd_sale_pc_ctn
                : "-"
            } /Ctn.`
          );

          //Qty.
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          let Unit =
            toolings.product[index + row].hqp_quantity_unit === "ctn"
              ? "Ctn."
              : toolings.product[index + row].hqp_quantity_unit === "pack"
              ? "Pack"
              : "Piece";
          doc.text(
            142,
            middleRow,
            `${toolings.product[index + row].hqp_quantity.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )} ${Unit}`,
            "center"
          );

          //Price
          let allQty =
            toolings.product[index + row].hqp_quantity_unit === "pack"
              ? parseFloat(toolings.detail[index + row].pd_sale_pc_pack) *
                parseFloat(toolings.product[index + row].hqp_quantity)
              : toolings.product[index + row].hqp_quantity_unit === "ctn"
              ? parseFloat(toolings.detail[index + row].pd_sale_pc_ctn) *
                parseFloat(toolings.product[index + row].hqp_quantity)
              : parseFloat(toolings.product[index + row].hqp_quantity) * 1;
          let origin_Price =
            parseFloat(toolings.product[index + row].hqp_retail_price) * allQty;

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            160,
            middleRow,
            `${(
              origin_Price?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
            "center"
          );

          //GP% Discount
          if (is_corporation) {
            let discount =
              toolings.product[index + row].hqp_gp_percent !== null &&
              toolings.product[index + row].hqp_gp_percent !== "0" &&
              toolings.product[index + row].hqp_gp_percent !== "NaN"
                ? parseFloat(
                    toolings.product[index + row].hqp_gp_percent
                  )?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || 0
                : 0;
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(
              178,
              middleRow,
              `${
                discount !== 0
                  ? discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                    "%"
                  : "-"
              }`,
              "center"
            );

            //Total
            let sumPrice =
              (origin_Price - (origin_Price * discount) / 100)?.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              ) || 0;
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(
              205,
              middleRow,
              `${sumPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
              "right"
            );
            sumSubTotal += origin_Price - (origin_Price * discount) / 100;
          } else {
            sumSubTotal += origin_Price;
          }
          doc.setDrawColor("#8DC445");
          doc.rect(5, 80 + i, 200, 0);
        } else if (index + row === toolings.product.length) {
          doc.setDrawColor("#8DC445");
          doc.rect(5, 80 + i, 200, 0);
        }
        row = row + 1;
        counts = toolings.product.length - 10;
      }
      /*------------------------------------------------------------------------*/
      let checkNumProduct = toolings.product.length - index;
      doc.rect(5, 230, 200, 0);
      if (checkNumProduct < 10 && !isMore1page) {
        doc.setDrawColor("#8DC445");
        doc.rect(5, 232, 137, 32);
        doc.setFillColor("#8DC445");
        doc.roundedRect(5, 232, 32, 10, 1, 1, "F");
        doc.setTextColor("#FFFFFF");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(21, 238, "หมายเหตุ/Remark", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(10, 247, `${toolings.data[0].hq_remark}` || "");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 236, "รวมเงิน/Sub Total :", "right");

        //value Sub Total
        let subTotal = sumSubTotal.toFixed(2);
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(
          205,
          236,
          `${subTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          "right"
        );

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 241, "ส่วนลด :", "right");

        //value UserDiscount
        let finalDiscount =
          (
            parseFloat(
              toolings.data[0].hq_final_discount_percent
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0
          ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(205, 241, `${finalDiscount}%` || "- ", "right");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 246, "ค่าจัดส่ง :", "right");

        //value shipping
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(
          205,
          246,
          `${
            parseFloat(toolings.data[0].hq_shipping_cost)?.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ) || 0
          }` || "- ",
          "right"
        );

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 251, "ภาษีมูลค่าเพิ่ม/Vat 7% :", "right");

        //value Vat
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        let vatValue =
          toolings.data[0].hq_vat === "0"
            ? "-  "
            : parseFloat(toolings.data[0].hq_vat).toFixed(2);
        doc.text(
          205,
          251,
          `${vatValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          "right"
        );

        let subTotal_GP =
          subTotal - (subTotal * parseFloat(finalDiscount)) / 100;
        let sumGrand =
          toolings.data[0].hq_incl_vat === true
            ? subTotal_GP + subTotal_GP * 0.07
            : subTotal_GP;
        let grandTotal = (
          sumGrand + parseFloat(toolings.data[0].hq_shipping_cost)
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        doc.setFillColor("#8DC445");
        doc.roundedRect(144, 253, 62, 10, 1, 1, "F");
        doc.setTextColor("#FFFFFF");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(160.5, 258, "จำนวนเงินทั้งสิ้น", "center");
        doc.text(160.5, 261.5, "GRAND TOTAL", "center");
        doc.text(179, 259.5, ":", "right");
        doc.text(
          205,
          259.5,
          `${grandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          "right"
        );

        doc.rect(15, 284, 35, 0);
        doc.rect(55, 284, 35, 0);
        doc.rect(120, 284, 35, 0);
        doc.rect(160, 284, 35, 0);

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(32.5, 289.5, "ผู้เสนอราคา", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(32.5, 294, "Authorized By", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(72.5, 289.5, "วันที่", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(72.5, 294, "Date", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(137.5, 289.5, "ผู้สั่งซื้อ", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(137.5, 294, "Buyer Approved", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(177.5, 289.5, "วันที่", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(177.5, 294, "Date", "center");
      }

      if (index < counts || isMore1page) {
        doc.addPage("a4", "h");
      }
    }
  } else {
    // ลูกค้าที่วไป
    let row = 0;
    for (let index = 0; index <= toolings.product.length; index += row) {
      doc.setFillColor("#8DC445");
      doc.rect(0, 0, 221, 25, "F");
      doc.addImage(Com_logo, "JPEG", 5, 0, 25, 25);
      doc.rect(0, 0, 5.5, 25, "F");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(20);
      doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");

      doc.text(32, 10, "บริษัท คิวท์ โปรดักส์ พลัส จำกัด");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(13);
      doc.text(93, 10, "0105552067869 (สำนักงานใหญ่)");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(12);
      doc.text(
        32,
        15,
        "เลขที่ 5 ซอยลาดพร้าว-วังหิน 32 แยก 1 ถนนลาดพร้าววังหิน แขวงลาดพร้าว กทม. 10230"
      );

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(32, 20, "Tel. +66 (0)-2539-0958-9");

      doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
      doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
      doc.setFont("THSarabunNewBold");
      doc.setTextColor("#FFFFFF");
      doc.setFontSize(32);
      doc.text(205, 12, "QUOTATION", "right");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(25);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(205, 21, "ใบเสนอราคา", "right");

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(5, 35, `ชื่อบริษัท/Name : ${toolings.data[0].hq_cus_name}`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "right");
      doc.setFont("THSarabunNew");
      doc.text(
        205,
        35,
        `ใบเสนอราคา เลขที่/Quotation No. : ${toolings.data[0].hq_no}`,
        "right"
      );

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        5,
        42,
        `ชื่อผู้ติดต่อ/Contact : ${toolings.data[0].hq_contract_name}`
      );

      const start_Date = toolings.data[0].hq_date.split("T")[0].split("-");
      let startDate = `${start_Date[2]}/${start_Date[1]}/${start_Date[0]}`;
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(205, 42, `วันที่/Date : ${startDate}`, "right");

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(5, 49, "ที่อยู่/Address :");

      let cus_address = "";
      let temp = [
        toolings.data[0].hq_cus_house_number,
        toolings.data[0].hq_cus_building,
        toolings.data[0].hq_swine,
        toolings.data[0].hq_soi,
        toolings.data[0].hq_road,
        toolings.data[0].hq_sub_district,
        toolings.data[0].hq_district,
        toolings.data[0].hq_province,
        toolings.data[0].hq_postcode,
      ];

      let TotalWord = 0;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i] !== null) {
          cus_address += temp[i] + " ";
          TotalWord = TotalWord + 1;
        }
      }
      let word1 = "";
      let word2 = "";
      let wordCount = 0;
      let y_axis = null;
      if (TotalWord > 6 && cus_address.length > 70) {
        for (let k = 0; k < temp.length; k++) {
          if (wordCount < 5 || word1.length < 50) {
            if (temp[k] !== null) {
              word1 += temp[k] + " ";
              wordCount += 1;
            }
          } else {
            if (temp[k] !== null) {
              word2 += temp[k] + " ";
            }
          }
        }
        y_axis = 63;
      } else {
        word1 = cus_address;
        y_axis = 56;
      }

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(30, 49, `${word1}`);
      doc.text(30, 56, `${word2}`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(186, 49, "ยืนยันราคาภายใน/Valid until :", "right");

      let validDate = toolings.data[0].hq_valid_unit;
      const [startDay, startMonth, startYear] = startDate.split("/");
      const [endDay, endMonth, endYear] = validDate.split("/");
      const checkStartDate = new Date(startYear, startMonth - 1, startDay);
      const checkEndDate = new Date(endYear, endMonth - 1, endDay);
      const timeDifference = checkEndDate - checkStartDate;
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      doc.text(187, 49, `${daysDifference} วัน`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(5, y_axis, "Tel :");

      const phoneNumber = toolings.data[0].hq_contract_tel.replace(/-/g, "");
      let formattedPhoneNumber =
        phoneNumber.substring(0, 3) +
        "-" +
        phoneNumber.substring(3, 6) +
        "-" +
        phoneNumber.substring(6);
      doc.text(14, y_axis, `${formattedPhoneNumber || ""}`);

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        48,
        y_axis,
        `Email : ${toolings.data[0].hq_contract_email || ""}`
      );

      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        206,
        56,
        `เงื่อนไขการชำระเงิน/Term&Payment : ${toolings.term[0].pt_name} ${
          toolings.data[0].hq_term || ""
        } วัน`,
        "right"
      );
      /*-------------------------------------------------------------------------------*/
      //header
      doc.setFillColor("#8DC445");
      doc.roundedRect(4, 68, 202, 12, 1, 1, "F");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(11, 73, "ลำดับ", "center");
      doc.setFontSize(15);
      doc.text(11, 78, "No.", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(35, 73, "รูปสินค้า", "center");
      doc.setFontSize(15);
      doc.text(35, 78, "Picture", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(65, 73, "บาร์โค้ด", "center");
      doc.setFontSize(15);
      doc.text(65, 78, "Barcode", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(120, 73, "รายการสินค้า", "center");
      doc.setFontSize(15);
      doc.text(120, 78, "Item Description", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(178, 73, "จำนวน", "center");
      doc.setFontSize(15);
      doc.text(178, 78, "Qty.", "center");

      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.text(196, 73, "ราคา", "center");
      doc.setFontSize(15);
      doc.text(196, 78, "Price", "center");

      let gapEachProduct = 15;
      let isMore1page = false;
      row = 0;
      for (let i = 0; row < 10; i = i + gapEachProduct) {
        if (index + row < toolings.product.length) {
          let middleRow = 0;
          let imgMiddleRow = 0;
          //Item description to find row size
          let Brand_name =
            toolings.detail[index + row].brand_name !== null &&
            toolings.detail[index + row].brand_name !== undefined
              ? toolings.detail[index + row].brand_name
              : "";
          let Product_name =
            toolings.data[0].hq_lang === "ENG"
              ? toolings.product[index + row].hqp_product_name_eng ||
                toolings.detail[index + row].product_name_eng
              : toolings.data[0].hq_lang === "TH"
              ? toolings.product[index + row].hqp_product_name_th ||
                toolings.detail[index + row].product_name
              : "";
          let Unit_name =
            toolings.data[0].hq_lang === "ENG"
              ? toolings.detail[index + row].pu_name
              : toolings.data[0].hq_lang === "TH"
              ? toolings.detail[index + row].pu_name_eng
              : "";
          let allItemDescription = `${Brand_name} ${
            Product_name !== null ? Product_name : ""
          } ${
            toolings.detail[index + row].pd_weight !== null &&
            toolings.detail[index + row].pd_weight !== undefined &&
            toolings.detail[index + row].pd_weight !== "0"
              ? toolings.detail[index + row].pd_weight.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )
              : ""
          } ${
            Unit_name !== null &&
            Unit_name !== undefined &&
            toolings.detail[index + row].pd_weight !== "0"
              ? Unit_name
              : ""
          }`;
          let tempAllDes = allItemDescription.split(" ");
          let y_axis_des = null;
          let LineArray = [];
          if (tempAllDes.length > 6 && allItemDescription.length > 40) {
            for (let k = 0; k < tempAllDes.length; k++) {
              if (LineArray.length <= 0) {
                LineArray.push(tempAllDes[k]);
              } else if (LineArray.length > 0) {
                if (LineArray[LineArray.length - 1].length < 40) {
                  LineArray[LineArray.length - 1] += " " + tempAllDes[k];
                } else {
                  LineArray.push(tempAllDes[k]);
                }
              }
            }
            gapEachProduct = 10 + 6 * LineArray.length;
            y_axis_des = 86 + i;
            middleRow = 86 + i + (6 * LineArray.length) / 2;
            imgMiddleRow = 84 + i + (6 * LineArray.length) / 4;
          } else {
            gapEachProduct = 15;
            LineArray.push(allItemDescription);
            y_axis_des = 86 + i;
            middleRow = 89 + i;
            imgMiddleRow = 81 + i;
          }
          if (230 - (80 + i) < gapEachProduct) {
            isMore1page = true;
            break;
          }

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(11, middleRow, (index + row + 1).toString(), "center"); //no.

          //Picture
          let product_Image = `${
            toolings.pic[index + row].path_image !== "" &&
            toolings.pic[index + row].path_image !== null &&
            toolings.pic[index + row].path_image !== undefined
              ? toolings.pic[index + row].path_image?.indexOf("blob") >= 0
                ? toolings.pic[index + row].path_image
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${toolings.pic[index + row].path_name}/${
                    toolings.pic[index + row].path_image
                  }`
              : userdefault_img.imgs
          }`;
          doc.addImage(product_Image, "JPEG", 27, imgMiddleRow, 13, 13);

          //Barcode
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            65,
            middleRow,
            `${
              toolings.product[index + row].hqp_cus_barcode !== null &&
              toolings.product[index + row].hqp_cus_barcode !== undefined
                ? toolings.product[index + row].hqp_cus_barcode
                : ""
            }`,
            "center"
          );

          // Item Description
          for (let itemDes of LineArray) {
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(85, y_axis_des, `${itemDes}`);
            y_axis_des = y_axis_des + 6;
          }
          doc.text(
            85,
            y_axis_des,
            `${
              toolings.product[index + row].pd_sale_pc_pack !== null
                ? toolings.detail[index + row].pd_sale_pc_pack
                : "-"
            } /Pack  ${
              toolings.product[index + row].pd_sale_pc_ctn !== null
                ? toolings.detail[index + row].pd_sale_pc_ctn
                : "-"
            } /Ctn.`
          );

          //Qty.
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          let Unit =
            toolings.product[index + row].hqp_quantity_unit === "ctn"
              ? "Ctn."
              : toolings.product[index + row].hqp_quantity_unit === "pack"
              ? "Pack"
              : "Piece";
          doc.text(
            178,
            middleRow,
            `${toolings.product[index + row].hqp_quantity.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )} ${Unit}`,
            "center"
          );

          //Price
          let allQty =
            toolings.product[index + row].hqp_quantity_unit === "pack"
              ? parseFloat(toolings.detail[index + row].pd_sale_pc_pack) *
                parseFloat(toolings.product[index + row].hqp_quantity)
              : toolings.product[index + row].hqp_quantity_unit === "ctn"
              ? parseFloat(toolings.detail[index + row].pd_sale_pc_ctn) *
                parseFloat(toolings.product[index + row].hqp_quantity)
              : parseFloat(toolings.product[index + row].hqp_quantity) * 1;
          let origin_Price =
            parseFloat(toolings.product[index + row].hqp_retail_price) * allQty;

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            196,
            middleRow,
            `${(
              origin_Price?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0
            ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
            "center"
          );
          sumSubTotal += origin_Price;
          doc.setDrawColor("#8DC445");
          doc.rect(5, 80 + i, 200, 0);
        } else if (index + row === toolings.product.length) {
          doc.setDrawColor("#8DC445");
          doc.rect(5, 80 + i, 200, 0);
        }
        row = row + 1;
        counts = toolings.product.length - 10;
      }
      /*------------------------------------------------------------------------*/
      let checkNumProduct = toolings.product.length - index;
      doc.rect(5, 230, 200, 0);
      if (checkNumProduct < 10 && !isMore1page) {
        doc.setDrawColor("#8DC445");
        doc.rect(5, 232, 137, 32);
        doc.setFillColor("#8DC445");
        doc.roundedRect(5, 232, 32, 10, 1, 1, "F");
        doc.setTextColor("#FFFFFF");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(21, 238, "หมายเหตุ/Remark", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(10, 247, `${toolings.data[0].hq_remark}` || "");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 236, "รวมเงิน/Sub Total :", "right");

        //value Sub Total
        let subTotal = sumSubTotal.toFixed(2);
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(
          205,
          236,
          `${subTotal.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          "right"
        );

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 241, "ส่วนลด :", "right");

        //value UserDiscount
        let finalDiscount =
          (
            parseFloat(
              toolings.data[0].hq_final_discount_percent
            )?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || 0
          ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(205, 241, `${finalDiscount}%` || "- ", "right");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 246, "ค่าจัดส่ง :", "right");

        //value shipping
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(
          205,
          246,
          `${toolings.data[0].hq_shipping_cost.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}` || "- ",
          "right"
        );

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(179, 251, "ภาษีมูลค่าเพิ่ม/Vat 7% :", "right");

        //value Vat
        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        let vatValue =
          toolings.data[0].hq_vat === "0"
            ? "-  "
            : parseFloat(toolings.data[0].hq_vat).toFixed(2);
        doc.text(
          205,
          251,
          `${vatValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          "right"
        );

        let subTotal_GP =
          subTotal - (subTotal * parseFloat(finalDiscount)) / 100;
        let sumGrand =
          toolings.data[0].hq_incl_vat === true
            ? subTotal_GP + subTotal_GP * 0.07
            : subTotal_GP;
        let grandTotal = (
          sumGrand + parseFloat(toolings.data[0].hq_shipping_cost)
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        doc.setFillColor("#8DC445");
        doc.roundedRect(144, 253, 62, 10, 1, 1, "F");
        doc.setTextColor("#FFFFFF");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(160.5, 258, "จำนวนเงินทั้งสิ้น", "center");
        doc.text(160.5, 261.5, "GRAND TOTAL", "center");
        doc.text(179, 259.5, ":", "right");
        doc.text(
          205,
          259.5,
          `${grandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
          "right"
        );

        doc.rect(15, 284, 35, 0);
        doc.rect(55, 284, 35, 0);
        doc.rect(120, 284, 35, 0);
        doc.rect(160, 284, 35, 0);

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(32.5, 289.5, "ผู้เสนอราคา", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(32.5, 294, "Authorized By", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(72.5, 289.5, "วันที่", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(72.5, 294, "Date", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(137.5, 289.5, "ผู้สั่งซื้อ", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(137.5, 294, "Buyer Approved", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(177.5, 289.5, "วันที่", "center");

        doc.setTextColor("#292520");
        doc.setFontSize(15);
        doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
        doc.setFont("THSarabunNew");
        doc.text(177.5, 294, "Date", "center");
      }

      if (index < counts || isMore1page) {
        doc.addPage("a4", "h");
      }
    }
  }

  if (statusCheck === true) {
    return doc.output("datauristring");
  } else {
    window.open(doc.output("bloburl"));
  }
}

export default Quotation_report;
