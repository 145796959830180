import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

function Horizontal_Bar_Chart({ /* Text, */ DataChart, HeightChart }) {
  ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const options = {
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        ticks: {
          stepSize: 1,
        },
        grace: "50%",
      },
    },
    layout: {
      padding: {
        bottom: 10,
      },
    },
    plugins: {
      legend: {
        labels: {
          boxWidth: 15,
          padding: 15,
        },
        align: "start",
      },
      datalabels: {
        formatter: function (value) {
          const str_show = Number(value || 0)
            ? Number(value || 0).toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            : 0;
          return str_show;
        },
        align: "end",
        anchor: "end",
        color: "#000000",
        font: {
          size: 10,
        },
        // listeners: {
        //   enter: function (context) {
        //     const chart = context?.chart;
        //     if (chart) {
        //       const tooltip = chart.tooltip;
        //       tooltip.setActiveElements([
        //         {
        //           datasetIndex: context.datasetIndex,
        //           index: context.dataIndex,
        //         },
        //       ]);
        //       chart.update();
        //     }
        //   },
        //   leave: function (context) {
        //     const chart = context?.chart;
        //     if (chart) {
        //       const tooltip = chart.tooltip;
        //       tooltip.setActiveElements([]);
        //       chart.update();
        //     }
        //   },
        // },
      },
    },
  };

  const plugin = {
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 10;
      };
    },

    // afterUpdate(chart) {
    //   const legend = chart.legend;
    //   legend.left += 40;
    //   for (const box of legend.legendHitBoxes) {
    //     box.left += 40;
    //   }
    // },
  };

  return (
    <>
      <div style={{ width: "100%", height: `calc(100% + ${HeightChart})` }}>
        <Bar data={DataChart} plugins={[plugin]} options={options} />
      </div>
    </>
  );
}
export default Horizontal_Bar_Chart;
