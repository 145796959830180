import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import moment from "moment";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import QRCode2 from "qrcode";
import QRCode from "qrcode.react";
import {

  tag_point_cts
} from "../../component/Report";

import Select from "react-select";
import TableLEK from "../../component/tableLEKTTT/table";

function WipProdInOutCTS(params) {

  const [product, setproduct] = useState([]);
  const [test,settest] = useState({
    test:'',
  });
  const [headTable, setheadTable] = useState(
    [
     
      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "product_image",
        sort: "asc",
        width: 50,
      },
      /*     {
            label: "QR",
            field: "material_qr",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      /*   {
          label: "Min Stock",
          field: "material_min_stock",
          sort: "asc",
          width: 50,
        }, */
      /*      {
               label: "Amount",
               field: "material_amount",
               sort: "asc",
               width: 50,
             }, */
      {
        label: "Amount(กก.)",
        field: "amount_kg",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "amount_piece",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot Detail",
        field: "lot_detail",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ]
  );
  const [valueData, setvalueData] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);

  const [filter_product, setfilter_product] = useState({
    product_no: "",
    product_name: "",
    oem_id: getOem(),
    company_id: getUser().com,
    search:''
  });

  const GetProduct = async (page,size,search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if(search){
      setSearch(1);
  }else{
      setSearch(0);
  }
    var get_filler_product = {
      product_no: filter_product.product_no.trim(),
      product_name: filter_product.product_name.trim(),

      company_key: "CTS",
      oem_id: getOem(),
      company_id: getUser().com,
      page:page || 1,
      size:size || 10,
      search: filter_product.search.trim(),
    }

    /*  console.log(filler_materail); */
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/wipProdInOut/filter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_product
    })
      .then(function (response) {


    /*     response.data.datasort((a, b) => a.no.localeCompare(b.no)); */



        setproduct(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({...test,test:''})
        test.test = '';
        Swal.close();

      })
      .catch(function (error) {
        console.log(error);
      });
    Swal.close();
  };

  useEffect(() => {

    GetProduct(1,10,'search');
    

  }, []);

  const Clearfilter = async () => {
    await setfilter_product({
      ...filter_product,
      product_no: "",
      product_name: "",
      search:''
    });

    filter_product.product_no = "";
    filter_product.product_name = "";
    filter_product.search = "";


    GetProduct(1,10,'search');

  };


  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const rowsData = [];

  for (var index = 0; index < product.length; index++) {

    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["product_no"] = product[index].no;
    rowItem["product_image"] = (
        /* product_data[index].image_path */ <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            product[index].image_path !== null
              ? Configs.API_URL_IMG + product[index].image_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
    rowItem["product_name"] = product[index].name;
    var sum_amount = 0;
    for (let item of product[index].lot_in_stock) {
      sum_amount += parseInt(item.amount);
    }


    rowItem["amount_kg"] = (
      <div style={{textAlign:'right'}}>

        {sum_amount === 0 ? 0 : <NumberFormat
          thousandSeparator={true}
          value={sum_amount}
          displayType="text"
        />}

      </div>
    )
    rowItem["amount_piece"] = 
    (
      <div style={{textAlign:'right'}}>


      
      {
    product[index].nw !== "" ? <NumberFormat
      thousandSeparator={true}
      value={Math.floor(sum_amount / product[index].nw * 1000)}
      displayType="text"
    /> : "ไมีมี N/W"
      }
      </div>
    )
    if (product[index].no === '05515-5080-0') {
      /*         console.log(lot_no) */

    }
    var lot = [];
    for (let i of product[index].lot_in_stock) {

      var check = lot.filter((e) => { return e === i.lot_no });
      if (check.length == 0) {
        lot.push(i.lot_no);
      }

    }
    rowItem["lot_detail"] = lot.map((e, i) => {

      /*      if(e.amount > 0 ){ */
      var check = product[index].lot_in_stock.filter((s) => { return s.amount > 0 && s.lot_no === e });
      if (check.length > 0) {
        return (

          <>

            <div><b>{e}</b></div>
            {product[index].lot_in_stock.map((e2) => {
              if (e === e2.lot_no && e2.amount > 0) {
                return (
                  <> <div>&nbsp;&nbsp;&nbsp;{e2.box_no}</div></>
                )
              }
            })}
            <hr />
          </>
        )
      }

      /*  } */

    })

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={

              "/Warehouse_Management/wip_prod_in_out/mgt/" +
              product[index].id

            }
            key={product[index].id}
            className=" btn btn-xs " /* onClick={editpage.bind(this,materail[index].id)} */
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "product_image",
        sort: "asc",
        width: 50,
      },
      /*     {
            label: "QR",
            field: "material_qr",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      /*   {
          label: "Min Stock",
          field: "material_min_stock",
          sort: "asc",
          width: 50,
        }, */
      /*      {
               label: "Amount",
               field: "material_amount",
               sort: "asc",
               width: 50,
             }, */
      {
        label: "Amount(กก.)",
        field: "amount_kg",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "amount_piece",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot Detail",
        field: "lot_detail",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  };


  const linkreport = async () => {

    axios({
      method: "post",
      url: Configs.API_URL + "/api/wipProdInOut/getWipProdinreport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product
    })
      .then(function (response) {
        console.log(response.data)
        if (response.data.length < 1) {
          Swal.fire({
            icon: "error",
            title: "ไม่มีข้อมูล",
          });
        } else {
          tag_point_cts(response.data, getUser().sub)
        }


      })
      .catch(function (error) {
        console.log(error);
      });


  }


  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>WIP Prod IN/OUT{"  "}<a target="_blank" href={getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>


                  <li className="breadcrumb-item active">WIP Prod IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-3">
            <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">

                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_product.search}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">

                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_product.product_no}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        product_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product No.</label>{" "}
                </div>
              </div>
              <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">

                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_product.product_name}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        product_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetProduct(1,10,'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => Clearfilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => linkreport()}
                  >
                    Print ป้ายชี้บ่ง
                  </button>
                </div>
              </div>


            </div>
          </div>




        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WIP Prod IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

                    <div style= {{padding:'2%'}}>
            <TableLEK 
                                
                                headTable = {headTable}
                                dataTable = {rowsData}
                                countPage = {countPage}
                                countData = {countData}
                                entries = {entries}
                                isSearch = {search}
                                callFnc ={GetProduct}
                                
                                
                                />
                                </div>
           {/*  <div
              className="card-body table-responsive " style={{ whiteSpace: 'nowrap' }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div> */}
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}

      </div>
      {/*   <Footter/> */}
    </div>

  );
}
export default WipProdInOutCTS;
