import React, { useState, useEffect, useRef } from "react";
import { Button as ButtonAnt, Upload, Modal, Progress } from "antd";
import { UploadOutlined, CameraOutlined , DeleteOutlined} from "@ant-design/icons";
import axios from "axios";
import styles from './CountingFeature.module.css';
import moment from "moment";
import Swal from "sweetalert2";
import Configs from "../../config";
import { Aifeature } from "../../routes/imgRoute/imgUrl";
import ImageZoom from './ImageZoom';

function Ocr_Feature() {
  const colors = {
    primary: "#004080",
    secondary: "#333333",
    lightBackground: "#f8f9fa",
    textLight: "#ffffff",
    headerText: "#000000",
    border: "#4A90E2",
  };

  const [fileData, setFileData] = useState({ file_name: "", file_path: "", file_id: "" });
  const [outputData, setOutputData] = useState(null);
  const [rawJsonData, setRawJsonData] = useState(null);
  const [showJson, setShowJson] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [progressInterval, setProgressInterval] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [empData, setEmpData] = useState({
    store: "",
    invoice: "",
    cost_date: null,
    amount: "",
    vat: "",
    detected_image: "",
  });
  const [fileList, setFileList] = useState([]);
  const [uploadKey, setUploadKey] = useState(Date.now());
  const [isClickAtCamera, setIsClickAtCamera] = useState(false);
  const uploadRef = useRef(null);
  const wsRef = useRef(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);

  const getToken = () => {
    return localStorage.getItem("authToken") || "";
  };

  const setupWebSocket = () => {
    wsRef.current = new WebSocket("ws://150.95.25.8:8000/ws/progress/");
    wsRef.current.onopen = () => {
      console.log("WebSocket connection opened");
    };
    wsRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setProgress(data.progress);
    };
    wsRef.current.onclose = () => {
      console.log("WebSocket connection closed");
    };
  };

  useEffect(() => {
    setupWebSocket();
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  const simulateProgress = () => {
    clearInterval(progressInterval);
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 99) {
          clearInterval(interval);
          return prev;
        }
        return Math.min(Math.floor(prev + 1), 99);
      });
    }, 500);
    setProgressInterval(interval);
  };

  const handleFileUpload = async (file) => {
    const data = new FormData();
    data.append("file", file);
  
    setShowProgress(true);
    simulateProgress();
  
    try {
      const response = await axios.post("http://localhost:8000/upload/", data, {
        headers: {
          "X-API-KEY": "TTT-OCR", 
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "multipart/form-data",
        },
      });
      
      if (response.data) {
        const ocrResult = response.data.ocr_result;
  
        if (!ocrResult.company_name && !ocrResult.invoice_number && !ocrResult.total_value) {
          Swal.fire({
            icon: 'warning',
            title: 'ไม่พบข้อความที่ตรวจจับได้',
            text: 'ไม่พบข้อความในไฟล์ที่คุณอัพโหลด กรุณาอัพโหลดไฟล์ใหม่ที่ชัดเจนขึ้น',
            confirmButtonText: 'ตกลง',
          });
          setProgress(0);
          clearInterval(progressInterval);
          setShowProgress(false);
          return;
        }
  
        const isValidDate = moment(ocrResult.sale_date, "YYYY-MM-DD", true).isValid();
        const formattedDate = isValidDate ? moment(ocrResult.sale_date, "YYYY-MM-DD").toDate() : null;
  
        setEmpData((prevEmpData) => ({
          ...prevEmpData,
          store: ocrResult.company_name?.trim(),
          invoice: ocrResult.invoice_number,
          cost_date: formattedDate,
          amount: ocrResult.total_value,
          vat: ocrResult.vat_text, 
          detected_image: ocrResult.detected_image,
        }));
  
        const filteredJsonData = { ...response.data };
        delete filteredJsonData.ocr_result.detected_image;
  
        setRawJsonData(filteredJsonData);
  
        setProgress(100);
        clearInterval(progressInterval);
        setShowProgress(false);
      }
    } catch (error) {
      console.error("File upload error:", error);
      Swal.fire({
        icon: 'error',
        title: 'ข้อผิดพลาด',
        text: 'เกิดข้อผิดพลาดในการประมวลผลไฟล์หรือการอัพโหลดไฟล์ กรุณาลองใหม่อีกครั้ง',
        confirmButtonText: 'ตกลง',
      });
      setProgress(0);
      clearInterval(progressInterval);
      setShowProgress(false);
    }
  };
  
  const handleUploadSuccess = async (file) => {
    setFileList(file.fileList);
    if (file.file && isClickAtCamera) {
      await handleFileUpload(file.file);
      setIsClickAtCamera(false);
    }
  };

  const handleTakePhoto = () => {
    setIsCameraOpen(true);  
    const constraints = {
      video: {
        facingMode: { ideal: isMobileDevice() ? "environment" : "user" }
      }
    };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((err) => console.error("Error accessing camera: ", err));
  };
  
  // ฟังก์ชันตรวจสอบว่าขณะนี้กำลังใช้บนอุปกรณ์มือถือหรือไม่
  const isMobileDevice = () => {
    return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf('IEMobile') !== -1;
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsCameraOpen(false);
  };

  const captureImage = () => {
    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      const newFile = new File([blob], `captured-${Date.now()}.jpeg`, { type: "image/jpeg" });

      setFileList([...fileList, { uid: newFile.name, name: newFile.name, status: "done", url: URL.createObjectURL(blob) }]);
      handleFileUpload(newFile);
      setIsCameraOpen(false);
      stopCamera();
    }, "image/jpeg");
  };

  const handleClear = () => {
    // Reset all state values to their initial state
    setFileData({ file_name: "", file_path: "", file_id: "" });
    setOutputData(null);
    setRawJsonData(null);
    setShowJson(false);
    setProgress(0);
    setImageUrl(null);
    setEmpData({
      store: "",
      invoice: "",
      cost_date: null,
      amount: "",
      vat: "",
      detected_image: "",  
    });
    setFileList([]); // เคลียร์ fileList
    setUploadKey(Date.now()); // สร้าง key ใหม่เพื่อรีเฟรช Upload component
    setShowProgress(false);
    if (wsRef.current) {
      wsRef.current.close(); 
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-sm-6">
                <h1 className={styles.headerTitle}>OCR Feature</h1>
              </div>
              <div className="col">
                <ol className={`breadcrumb float-sm-right ${styles.breadcrumbStyle}`}>
                  <li className="breadcrumb-item"><a href="" style={{ textDecoration: "none", color: colors.primary }}>Home</a></li>
                  <li className="breadcrumb-item active">AI Feature</li>
                  <li className="breadcrumb-item active">OCR Feature</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="p-3">

          <div className={styles.card}>
            <div className={styles.cardHeader}>OCR Feature</div>
            <div className="card-body">
              <p className="text-muted">
                ฟีเจอร์นี้ใช้โมเดลการพัฒนาจาก AI ซึ่งสามารถตรวจจับข้อความจากใบเสร็จและใบแจ้งหนี้ได้โดยอัตโนมัติ โมเดล AI นี้รองรับรูปแบบและการจัดวางข้อความของใบแจ้งหนี้และใบเสร็จ เพื่อให้การดึงข้อมูลมีประสิทธิภาพและความแม่นยำสำหรับการประมวลผลและวิเคราะห์
              </p>
            </div>
          </div>

          {/* Upload Section */}
          <div className={styles.card}>
            <div className={styles.cardHeader}>Upload Files</div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-5">
                  <div style={{ display: "flex", alignItems: "flex-start", gap: "10px", flexWrap: "wrap" }}>
                    {/* Container to keep the buttons fixed */}
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>
                      {/* เรียกใช้ฟังก์ชัน handleFileUpload ทันทีเมื่อเลือกไฟล์ */}
                        {/* Take Photo Button */}
                        <ButtonAnt
                          icon={<CameraOutlined />}
                          onClick={handleTakePhoto}
                          className={styles.cameraButton}
                          style={{
                            marginBottom: "11px",
                            backgroundColor: "#3579F626",
                            borderColor: "#1976D2",
                            color: "#3579F6",
                            minWidth: "200px",
                          }}
                          disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                        >
                          Take Photo
                        </ButtonAnt>

                        {/* Upload Files Button */}
                        <Upload
                          key={uploadKey} // ใช้ key ใหม่เพื่อบังคับให้รีเฟรช component
                          listType="picture"
                          accept=".jpg,.png,.jpeg,.pdf"
                          beforeUpload={(file) => {
                            handleFileUpload(file);
                            return false;
                          }}
                          fileList={fileList}
                          onChange={handleUploadSuccess}
                          showUploadList={{ showRemoveIcon: true }}
                          disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                        >
                          <ButtonAnt
                            icon={<UploadOutlined />}
                            style={{
                              backgroundColor: showProgress ? "#d3d3d3" : "#3579F626",
                              borderColor: "#1976D2",
                              color: showProgress ? "#808080" : "#3579F6",
                              minWidth: "200px",
                            }}
                            className={styles.uploadButton}
                            disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                          >
                            Choose files
                          </ButtonAnt>
                        </Upload>

                        {/* Clear Button */}
                        <ButtonAnt
                          icon={<DeleteOutlined />}
                          style={{
                            backgroundColor: "#ff4d4f26",
                            borderColor: "#ff4d4f",
                            color: "#ff4d4f",
                            minWidth: "200px",
                            marginTop: "10px",
                          }}
                          className={styles.clearButton}
                          onClick={handleClear}
                          disabled={showProgress} // ปิดการใช้งานเมื่อ progress ทำงาน
                        >
                          Clear
                        </ButtonAnt>

                        {/* Progress Bar Section */}
                        {showProgress && (
                          <div style={{ marginBottom: "0px", width: "100%" }}> {/* ใช้ width: 100% เพื่อให้ responsive */}
                            <h4>Processing Progress</h4>
                            <div style={{ padding: "0 15px", width: "100%" }}> {/* เพิ่ม container รอบๆ progress bar */}
                              <Progress percent={progress} status="active" style={{ width: "100%" }} /> {/* เปลี่ยน width เป็น 100% */}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Output Section */}
          <div className={styles.card}>
            <div className="card-body">
              <div className="row justify-content-center">
                {/* Container for Image and Table */}
                <div className="col-12 col-md-10" style={{ border: "2px solid #4A90E2", borderRadius: "8px", padding: "15px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}>
                  <div className="row">
                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center" style={{ height: '400px', overflow: 'hidden' }}>
                        {true && (
                          <div
                            style={{ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
                            onClick={() => setIsImageModalVisible(true)} // Open modal on image click
                          >
                            <img
                              src={empData.detected_image ? `data:image/jpeg;base64,${empData.detected_image}` : Aifeature.imgs}
                              alt="Detected"
                              style={{
                                maxWidth: "100%",
                                height: "auto",
                                maxHeight: "350px",
                                borderRadius: "8px",
                                cursor: "pointer", // Indicate that the image is clickable
                              }}
                            />
                          </div>
                        )}
                      </div>

                    {/* Right Column: OCR Result in Table */}
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
                      <h4>Example Result</h4>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Flat Fields</th>
                            <th>Values</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>Invoice Number</td>
                          <td>
                            {empData.detected_image ? (  
                              <input
                                type="text"
                                className="form-control"
                                value={empData.invoice || ""}  
                                onChange={(e) => {
                                  const newInvoice = e.target.value;
                                  setEmpData((prev) => ({ ...prev, invoice: newInvoice }));
                                  setRawJsonData((prev) => ({
                                    ...prev,
                                    ocr_result: {
                                      ...prev.ocr_result,
                                      invoice_number: newInvoice,
                                    },
                                  }));
                                }}
                              />
                            ) : (
                              "" 
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Invoice Date</td>
                          <td>
                            {empData.detected_image ? (
                              <input
                                type="date"
                                className="form-control"
                                value={empData.cost_date ? moment(empData.cost_date).format("YYYY-MM-DD") : ""}
                                onChange={(e) => {
                                  const newDate = e.target.value;
                                  setEmpData((prev) => ({ ...prev, cost_date: new Date(newDate) }));
                                  setRawJsonData((prev) => ({
                                    ...prev,
                                    ocr_result: {
                                      ...prev.ocr_result,
                                      sale_date: newDate,
                                    },
                                  }));
                                }}
                              />
                            ) : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Amount</td>
                          <td>
                            {empData.detected_image ? (
                              <input
                                type="text"
                                className="form-control"
                                value={empData.amount || ""}
                                onChange={(e) => {
                                  const newAmount = e.target.value;
                                  setEmpData((prev) => ({ ...prev, amount: newAmount }));
                                  setRawJsonData((prev) => ({
                                    ...prev,
                                    ocr_result: {
                                      ...prev.ocr_result,
                                      total_value: newAmount,
                                    },
                                  }));
                                }}
                              />
                            ) : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>VAT</td>
                          <td>
                            {empData.detected_image ? (
                              <input
                                type="text"
                                className="form-control"
                                value={empData.vat || ""}
                                onChange={(e) => {
                                  const newVat = e.target.value;
                                  setEmpData((prev) => ({ ...prev, vat: newVat }));
                                  setRawJsonData((prev) => ({
                                    ...prev,
                                    ocr_result: {
                                      ...prev.ocr_result,
                                      vat_text: newVat,
                                    },
                                  }));
                                }}
                              />
                            ) : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>Store</td>
                          <td>
                            {empData.detected_image ? (
                              <input
                                type="text"
                                className="form-control"
                                value={empData.store || ""}
                                onChange={(e) => {
                                  const newStore = e.target.value;
                                  setEmpData((prev) => ({ ...prev, store: newStore }));
                                  setRawJsonData((prev) => ({
                                    ...prev,
                                    ocr_result: {
                                      ...prev.ocr_result,
                                      company_name: newStore,
                                    },
                                  }));
                                }}
                              />
                            ) : ""}
                          </td>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* JSON Raw Data Card with Show/Hide Button */}
          <div className={styles.card}>
            <div className="card-body">
              <ButtonAnt type="primary" onClick={() => setShowJson(!showJson)}>
                {showJson ? "Hide JSON" : "Show JSON"}
              </ButtonAnt>
              {showJson && (
                <div style={{ marginTop: "15px" }}>
                  <h4>Raw JSON Output</h4>
                  <pre style={{ background: "#f8f9fa", padding: "15px", borderRadius: "5px", border: "1px solid #4A90E2", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                    {JSON.stringify(rawJsonData, null, 2)}
                  </pre>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Modal for Camera Capture */}
        <Modal
          title="Camera"
          visible={isCameraOpen}
          onCancel={stopCamera}
          footer={[
            <ButtonAnt key="capture" type="primary" onClick={captureImage}>
              Capture Photo
            </ButtonAnt>,
            <ButtonAnt key="stop" onClick={stopCamera}>
              Stop Camera
            </ButtonAnt>,
            <ButtonAnt key="cancel" onClick={() => setIsCameraOpen(false)}>
              Cancel
            </ButtonAnt>,
          ]}
        >
          <video ref={videoRef} autoPlay style={{ width: "100%" }} />
        </Modal>

        {/* Modal for Enlarged Image */}
        <Modal
          title="Detected Image"
          visible={isImageModalVisible}
          onCancel={() => setIsImageModalVisible(false)}
          footer={null}
          centered
          bodyStyle={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            padding: 0 
          }} 
          style={{ 
            maxWidth: '90vw', 
            width: 'auto',  
            maxHeight: '90vh' 
          }}
        >
          <div style={{ border: '4px solid #4A90E2', borderRadius: '8px', padding: '10px' }}> 
            <ImageZoom
              src={empData.detected_image ? `data:image/jpeg;base64,${empData.detected_image}` : Aifeature.imgs}
              zoom="300" 
              alt="Zoomable image"
              height={550} 
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Ocr_Feature;
