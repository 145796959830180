import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import { Card, Label } from "reactstrap";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";

function Leave_information_remaining(params) {
  const [getEmployeeWorkType, setgetEmployeeWorkType] = useState([]);
  const [allEmployee, setAllEmployee] = useState({
    data: [],
  });
  const [tableEmpTypeWork, setTableEmpTypeWork] = useState({
    data: [],
  });


  const [test, setTest] = useState({
    test: "",
  });
  const [historyId, setHistoryId] = useState("");
  const [modeChangeModal, setModeChangeModal] = useState("");
  const [Employee, setEmployee] = useState([]);
  const [LeaveTypeOption, setLeaveTypeOption] = useState([]);
  const [YearStock, setYearStock] = useState([]);
  const [searchYear, setsearchYear] = useState({
    year: "",
  });
  const [historyLeave, sethistoryLeave] = useState([]);
  const [viewSelect, setviewSelect] = useState({
    name: "",
  });
  const [filter_employee, setfilter_employee] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    emp_no: "",
    emp_name_th: "",
    emp_type_id: "",
    year: moment(new Date()).format("YYYY"),
  });

  const [dateZindex, setdateZindex] = useState({
    dropdown1: "98",
    datehis1: "98",
    datehis2: "98",
  });

  const start_date_his = moment(new Date()).startOf("years");
  const end_date_his = moment(new Date()).endOf("years");
  const [filter_history, setfilter_history] = useState({
    leave_type: "",
    start_date: new Date(start_date_his),
    finish_date: new Date(end_date_his),
  });

  const [data_entLeave, setdata_entLeave] = useState({
    pak_ron: "",
    la_kit: "",
    sick: "",
    out_pak_ron: "",
    out_la_kit: "",
    out_sick: "",
  });
  let [data_leave, setdata_leave] = useState({
    name: "",
    leave_status: "",
    detail: "",
    day_status: "",
    leave_half_day: "",
    start_date: "",
    finish_date: "",
    start_time: "",
    finish_time: "",
    file_name: "",
    file_path: "",
  });

  async function getEntLeaveByEmpId(name, emp_id) {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_name_th: name,
      year: moment(new Date()).format("YYYY"),
    };

    //console.log(filler_employee);
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeLeaveByAdmin",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(function (response2) {
        let temp = {
          emp_id: emp_id,
          balance_leave: response2.data.data[0],
          year: moment().format("YYYY")
        };
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/getEntLeaveByEmpId",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            setdata_entLeave({
              ...data_entLeave,
              all_pak_ron: response.data.data.all_vacation_leave,
              all_la_kit: response.data.data.all_personal_leave,
              all_sick: response.data.data.all_sick_leave,
              pak_ron: response.data.data.ent_vacation_leave,
              la_kit: response.data.data.ent_personal_leave,
              sick: response.data.data.ent_sick_leave,
              out_pak_ron: response.data.data.out_vacation_leave,
              out_la_kit: response.data.data.out_personal_leave,
              out_sick: response.data.data.out_sick_leave,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error2) {
        console.log(error2);
      });
  }
  async function getNameEmpById(emp_id) {
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeById?id=" +
        emp_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setdata_leave({
          ...data_leave,
          name: response.data.data[0].emp_name_th,
        });
        getEntLeaveByEmpId(response.data.data[0].emp_name_th, emp_id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const upload_file = async (e) => {
    console.log("filname", e.target.files);
    if (e.target.files[0]) {
      const file = e.target.files[0];
      console.log("ss", file);
      const regex = /([\u0E00-\u0E7F]+)/gmu;
      const str = file.name;
      let m = regex.exec(str);
      var data = new FormData();
      data.append("Profile", file);

      var config_uploadProfile = {
        method: "post",
        url: Configs.API_URL + "/upload/profile",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config_uploadProfile)
        .then(function (response) {
          if (response.data.status) {
            /*  console.log("filre",response.data); */

            setdata_leave({
              ...data_leave,
              file_name: response.data.data.orgin_name,
              file_path: response.data.data.path,
              /* image: response.data.data, */
            });
          }
          Swal.fire({
            icon: "success",
            title: "อัปโหลดไฟล์สำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "File name !!!", "error");
        });
      //  }
    }
  };

  function cancle() {
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องการยกเลิกใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        setModeChangeModal("view");
        setdata_leave({
          name: "",
          leave_status: "",
          detail: "",
          day_status: "",
          leave_half_day: "",
          start_date: "",
          finish_date: "",
          start_time: "",
          finish_time: "",
          file_name: "",
          file_path: "",
        });
        setTest({ ...test, test: "" });
      }
    });
  }

  async function SaveLeaveEmpData() {
    Swal.fire({
      title: "ระบบ?",
      text: "คุณต้องการแก้ไขข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then((result) => {
      if (result.isConfirmed) {
        const error_list = [];
        if (data_leave.leave_status == "") {
          let temp_err = {
            message: "โปรดเลือกประเภทการลา.",
          };
          error_list.push(temp_err);
        }
        if (data_leave.detail.trim() == "") {
          let temp_err = {
            message: "กรอกข้อมูลรายละเอียด.",
          };
          error_list.push(temp_err);
        }

        if (data_leave.day_status == "") {
          let temp_err = {
            message:
              "โปรดเลือกว่าจะลาเต็มวัน ลาเป็นช่วงเวลา ลาครึ่งวันเช้าหรือลาครึ่งวันบ่าย",
          };
          error_list.push(temp_err);
        }

        if (data_leave.start_date == "") {
          let temp_err = {
            message: "โปรดเลือกวันที่เริ่มลา.",
          };
          error_list.push(temp_err);
        }
        if (data_leave.start_date == "") {
          let temp_err = {
            message: "โปรดเลือกวันที่สิ้นสุดการลา.",
          };
          error_list.push(temp_err);
        }

        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Warning", err_message, "warning");
        } else {
          var temp = {
            emp_id: historyId,
            data_leave: data_leave,
          };
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement +
              "/api/hrManagement/updateEmployeeByAdmin",
            //saveEmpLeaveByAdmin
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response2) {
              if (response2.data.data) {
                Swal.fire({
                  icon: "success",
                  title: "แก้ไขสำเร็จ",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  getLeaveHistoryByEmpId(viewSelect.emp_id, viewSelect.name);
                  // window.location.href =
                  //   "/Human_Resource/leave_information_admin";
                });
              }
            })
            .catch(function (error) {
              console.log(error.response);
              Swal.fire("Error", error.response.data.error.message, "error");
            });
        }
      }
    });
  }

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        // console.log(response.data.data,"ddd",);
        const arrList = [];
        // setgetEmployeeWorkType(response.data.data);
        setgetEmployeeWorkType([]);
        for (let item of response.data.data) {
          // console.log('item', item);
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
          };
          arrList.push(temp);
          getEmployeeWorkType.push(temp);
        }
        setgetEmployeeWorkType(arrList);
        setTest({ ...test, test: "" });
        /* 
        id
        contract_type_name
        */

        // console.log('getEmployeeWorkType', getEmployeeWorkType);
      })
      .then(() => GetEmployee())
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: filter_employee.emp_no.trim(),
      emp_name_th: filter_employee.emp_name_th.trim(),
      emp_type_id: filter_employee.emp_type_id,
      year: filter_employee.year,
    };
    //console.log(filler_employee);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getEmployeeLeaveByAdmin",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        console.log(response.data, "ds");
        const checkFeature = featrue.data.find(el => el.feature === "leave_day_ttt");
        if (checkFeature) {
          const allEmp = response.data.data;
          console.log('allEmp', allEmp);
          console.log('getEmployeeWorkType', getEmployeeWorkType);
          // setAllEmployee([]);
          const arrList = getEmployeeWorkType.map((item) => {
            const temp = {
              rows: null,
              columns: null,
            };
            temp.columns = item.contract_type_name;
            temp.id = item.id;
            if (item.id === "9a729476-553f-48c4-9ffe-090c02ae66ee") {
              temp.rows = allEmp.filter(
                (el) =>
                  el.emp_status_type_id ===
                  "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" ||
                  el.is_active === false
              );
            } else {
              temp.rows = allEmp.filter(
                (el) =>
                  el.emp_contract_type_id === item.id && el.is_active === true
              );
            }

            return temp;
          });
          // console.log('arrList', arrList);

          setAllEmployee({ ...allEmployee, data: arrList });
          allEmployee.data = arrList;
          setTest({ ...test, test: "" });
          await spliceEmpToTypeWork();
          setEmployee(response.data.data);
        } else {
          const arrList = response.data.data.filter(el => el.is_use === true && el.is_active === true);
          setEmployee(arrList);
        }
        setsearchYear({ ...searchYear, year: filter_employee.year });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function spliceEmpToTypeWork() {
    const arrLis = [];
    for (let index = 0; index < allEmployee.data.length; index++) {
      const rowsData = [];
      // console.log('allEmployee[index', allEmployee[index]);
      for (
        let index2 = 0;
        index2 < allEmployee.data[index].rows.length;
        index2++
      ) {
        const Employee = allEmployee.data[index].rows;
        // console.log('Employee[index2]', Employee[index2]);
        const rowItem = {};
        rowItem["no"] = index2 + 1;

        rowItem["name"] = Employee[index2].name || null;
        rowItem["remain_pak_ron"] = Employee[index2].remain_pak_ron;
        rowItem["remain_sick"] = Employee[index2].remain_sick;
        rowItem["remain_la_kit"] = Employee[index2].remain_la_kit;

        rowsData.push(rowItem);
      }
      const data = {
        columns: [
          {
            label: "ลำดับ",
            field: "no",
            sort: "asc",
            width: 50,
          },
          {
            label: "ชื่อ - นามสกุล",
            field: "name",
            sort: "asc",
            width: 50,
          },
          {
            label: "พักร้อน",
            field: "remain_pak_ron",
            sort: "asc",
            width: 50,
          },
          {
            label: "ลาป่วย",
            field: "remain_sick",
            sort: "asc",
            width: 50,
          },
          {
            label: "ลากิจ",
            field: "remain_la_kit",
            sort: "asc",
            width: 50,
          },
          // {
          //   label: "จัดการ",
          //   field: "mgt",
          //   sort: "asc",
          //   width: 50,
          // },
        ],
        rows: rowsData,
      };

      // tableEmpTypeWork.push(data);
      arrLis.push(data);
    }
    // console.log('arrLis', arrLis);
    setTableEmpTypeWork({ ...tableEmpTypeWork, data: arrLis });
    tableEmpTypeWork.data = arrLis;
    setTest({ ...test, test: "" });
  }

  const getLeaveType = async () => {
    //console.log(filler_employee);
    axios({
      method: "get",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getLeaveType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        /*   console.log(response.data, "ds"); */

        const options = [];
        var tmp = {
          value: "",
          label: "ทั้งหมด",
        };
        options.push(tmp);
        response.data.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.status_name;
          options.push(element);
        });
        setLeaveTypeOption(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [featrue, setFeatrue] = useState({
    data: [],
  })
  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "ข้อมูลวันลาคงเหลือ";
    });
    setFeatrue({ data: feature_data });
    featrue.data = feature_data;
    console.log('featrue.data', featrue.data);
    var this_yaer = parseInt(moment(new Date()).format("YYYY"));

    var temp_year = [];
    temp_year.push(this_yaer);
    for (var index = 0; index < 5; index++) {
      var old_year = this_yaer - (index + 1);
      var next_year = this_yaer + (index + 1);
      temp_year.push(old_year);
      temp_year.push(next_year);
    }

    temp_year.sort((a, b) => a - b);
    setYearStock(temp_year);
    GetEmployeeWorkType();
    GetEmployee();
    getLeaveType();
  }, []);

  const rowsData = [];
  for (var index = 0; index < Employee.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["name"] = Employee[index].name;
    rowItem["remain_pak_ron"] = Employee[index].remain_pak_ron;
    rowItem["remain_sick"] = Employee[index].remain_sick;
    rowItem["remain_la_kit"] = Employee[index].remain_la_kit;
    // rowItem["mgt"] = (
    //   <>
    //     <div className="row">
    //       <div className="col-4">
    //         <a
    //           key={Employee[index].id}
    //           id={Employee[index].id}
    //           className=" btn btn-xs "
    //           data-toggle="modal"
    //           data-target="#modal-viewLeaveHistory"
    //           data-backdrop="static"
    //           onClick={getLeaveHistoryByEmpId.bind(
    //             this,
    //             Employee[index].id,
    //             Employee[index].name
    //           )}
    //         >
    //           <i class="fas fa-eye"></i>
    //         </a>
    //       </div>
    //       <div className="col-4">
    //         <a
    //           href={
    //             "/Human_Resource/ข้อมูลการปฏิบัติงาน/ข้อมูลวันลาคงเหลือ/mgt/" +
    //             Employee[index].id
    //           }
    //           key={Employee[index].id}
    //           className=" btn btn-xs "
    //         >
    //           {" "}
    //           <i class="fas fa-pencil-alt"></i>{" "}
    //         </a>
    //       </div>
    //     </div>
    //   </>
    // );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "พักร้อน",
        field: "remain_pak_ron",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลาป่วย",
        field: "remain_sick",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลากิจ",
        field: "remain_la_kit",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: "จัดการ",
      //   field: "mgt",
      //   sort: "asc",
      //   width: 50,
      // },
    ],
    rows: rowsData,
  }; /* ประจำเเค่ละปี */

  const rowsDataHistory = [];
  for (var index = 0; index < historyLeave.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["date"] =
      moment(historyLeave[index].start_date).format("DD/MM/YYYY") +
      " - " +
      moment(historyLeave[index].finish_date).format("DD/MM/YYYY");
    rowItem["time"] =
      historyLeave[index].day_type == "half_day"
        ? moment(historyLeave[index].start_time).format("HH:mm") +
        "-" +
        moment(historyLeave[index].finish_time).format("HH:mm")
        : "-";
    rowItem["leave_type"] = historyLeave[index].leave_type;
    rowItem["day_type"] = historyLeave[index].leave_half_day;
    rowItem["detail"] = historyLeave[index].detail;
    rowItem["file"] =
      historyLeave[index].file_path !== "" ? (
        <>
          <a href={Configs.API_URL_IMG + historyLeave[index].file_path}>
            <button type="button" className="btn btn-block btn-info  ">
              Download
            </button>
          </a>
        </>
      ) : (
        ""
      );
    rowItem["mgt"] = (
      <>
        {parseInt(moment(historyLeave[index].start_date).format("YYYY")) >=
          parseInt(new Date().getFullYear()) ||
          parseInt(moment(historyLeave[index].finish_date).format("YYYY")) >=
          parseInt(new Date().getFullYear()) ? (
          <div className="row">
            <div className="col-4">
              <a
                key={historyLeave[index].id}
                id={historyLeave[index].id}
                className=" btn btn-xs "
                /* data-toggle="modal"
                data-target="#modal-viewLeaveHistory" */
                onClick={getLeaveHistoryByHistoryId.bind(
                  this,
                  historyLeave[index].id
                )}
              >
                <i class="fas fa-pencil-alt"></i>
              </a>
            </div>
            <div className="col-4">
              <a
                // href={"/Human_Resource/leave_information_admin/mgt/" + Employee[index].id}
                key={historyLeave[index].id}
                id={historyLeave[index].id}
                onClick={deldata.bind(this, historyLeave[index].id)}
                className=" btn btn-xs "
              >
                {" "}
                <i class="fas fa-trash-alt"></i>{" "}
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );

    rowsDataHistory.push(rowItem);
  }

  const dataHistory = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ช่วงวันที่ลา",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "ช่วงเวลา",
        field: "time",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทการลา",
        field: "leave_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "เต็มวัน/ช่วงเวลา",
        field: "day_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายละเอียด",
        field: "detail",
        sort: "asc",
        width: 50,
      },
      {
        label: "เอกสาร",
        field: "file",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDataHistory,
  }; /* ประจำเเค่ละปี */

  function deldata(id) {
    // console.log("id", id);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/hrManagement/delHistoryLeaveById?id=" +
            id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              let arrList = [];
              arrList = historyLeave.filter((his) => his.id !== id);
              sethistoryLeave(arrList);
              setTest({ ...test, test: "" });
              // window.location.href = "/Human_Resource/HRD/External_dev_train";
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }
    });
  }

  function clearFilter() {
    setfilter_employee({
      ...filter_employee,
      emp_name_th: "",
      year: moment(new Date()).format("YYYY"),
    });
    filter_employee.emp_name_th = "";
    filter_employee.year = moment(new Date()).format("YYYY");
    GetEmployee();
  }

  function getLeaveHistoryByEmpId(emp_id, name, g) {
    setModeChangeModal("view");
    setHistoryId(emp_id);
    // localStorage.setItem('emp_id', emp_id);
    getNameEmpById(emp_id);
    setTest({ ...test, test: "" });
    var emp_id_ = "";
    if (emp_id && name) {
      setviewSelect({ ...viewSelect, name: name, emp_id: emp_id });
      viewSelect.name = name;
      viewSelect.emp_id = emp_id;
      emp_id_ = emp_id;
    } else {
      emp_id_ = viewSelect.emp_id;
    }

    /*  console.log("e",emp_id) */
    /* console.log("f",f,g) */
    var temp = {
      emp_id: emp_id_,
      start_date: filter_history.start_date,
      finish_date: filter_history.finish_date,
      leave_type: filter_history.leave_type,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getLeaveHistoryByEmpId",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        const res = response.data.data;
        const arr = [];
        for (let item of res) {
          item.start_time =
            item.day_type === "half_day"
              ? convertDateTime(item.start_time)
              : "";
          item.finish_time =
            item.day_type === "half_day"
              ? convertDateTime(item.finish_time)
              : "";
          arr.push(item);
        }
        sethistoryLeave(arr);
        console.log(response.data, "ds");
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getLeaveHistoryByHistoryId(his_id) {
    setModeChangeModal("edit");
    const arr = historyLeave.filter((his) => {
      return his.id === his_id;
    });
    console.log("arr", convertDateTime("22:00"));
    setdata_leave(arr.length > 0 ? arr[0] : []);
    data_leave = arr.length > 0 ? arr[0] : [];
    data_leave.start_date = new Date(data_leave.start_date);
    data_leave.finish_date = new Date(data_leave.finish_date);
    data_leave.start_time =
      data_leave.day_type === "half_day" ? data_leave.start_time : "";
    data_leave.finish_time =
      data_leave.day_type === "half_day" ? data_leave.finish_time : "";
    setTest({ ...test, test: "" });
  }

  function convertDateTime(time) {
    const [hours, minutes] = time.split(":");
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      parseInt(hours),
      parseInt(minutes)
    );
  }

  function clearFilter_history() {
    setfilter_history({
      ...filter_history,
      leave_type: "",
      start_date: new Date(start_date_his),
      finish_date: new Date(end_date_his),
    });
    filter_history.leave_type = "";
    filter_history.start_date = new Date(start_date_his);
    filter_history.finish_date = new Date(end_date_his);
    getLeaveHistoryByEmpId(viewSelect.emp_id, viewSelect.name);
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  ข้อมูลวันลาพนักงาน{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">ข้อมูลวันลาพนักงาน</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filter_employee.year}
                    onChange={(e) =>
                      setfilter_employee({
                        ...filter_employee,
                        year: e.target.value,
                      })
                    }
                  >
                    {YearStock.map((e) => {
                      return <option value={e}>{e + 543}</option>;
                    })}
                  </select>
                  <label htmlFor="">ข้อมูลลาของปี</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={filter_employee.emp_name_th}
                    onChange={(e) => {
                      setfilter_employee({
                        ...filter_employee,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetEmployee();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h4>
                ข้อมูลการลาสะสมประจำปี{" "}
                {parseInt(
                  moment(searchYear.year || new Date()).format("YYYY")
                ) + 543}
              </h4>
            </div>

            {/* /////////////////////////////////////////////////////////////////////// */}
            {
              featrue.data.find(el => el.feature === "leave_day_ttt") === undefined ?
                <>
                  <div
                    className="card-body table-responsive "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      id="table-to-xls"
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={data}
                    />
                  </div>
                </>
                :
                <>
                  <>
                    <div className="card card-primary card-outline card-outline-tabs">
                      <div className="card-header p-0 border-bottom-0">
                        <ul
                          className="nav nav-tabs"
                          id="custom-tabs-four-tab"
                          role="tablist"
                        >
                          {allEmployee.data.map((el, index) => {
                            if (index === 0)
                              return (
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    id={el.id}
                                    data-toggle="pill"
                                    href={"#" + el.columns + "-teb"}
                                    role="tab"
                                    aria-controls={el.columns + "-teb"}
                                    aria-selected="true"
                                  >
                                    {el.columns + ` (${el.rows.length})`}
                                  </a>
                                </li>
                              );
                            else
                              return (
                                <li className="nav-item">
                                  {/* {mode.mode === "read" || mode.mode === "edit" ? */}
                                  <a
                                    className="nav-link"
                                    id={el.id}
                                    data-toggle="pill"
                                    href={"#" + el.columns + "-teb"}
                                    role="tab"
                                    aria-controls={el.columns + "-teb"}
                                    aria-selected="false"
                                  >
                                    {el.columns + ` (${el.rows.length})`}
                                  </a>
                                  {/* } */}
                                </li>
                              );
                          })}

                          {/* <li className="nav-item">
                  <a className="nav-link" id="custom-tabs-four-page-3-tab" data-toggle="pill" href="#custom-tabs-four-page-3" role="tab" aria-controls="custom-tabs-four-page-3" aria-selected="false" >Supplier</a>
                </li> */}
                        </ul>
                      </div>
                      <div className="card-body">
                        <div
                          className="tab-content"
                          id="custom-tabs-four-tabContent"
                        >
                          {allEmployee.data.map((el, index) => {
                            if (index === 0)
                              return (
                                <div
                                  className="tab-pane fade show active"
                                  id={el.columns + "-teb"}
                                  role="tabpanel"
                                  aria-labelledby={el.id}
                                >
                                  {/* ส่วนแรก */}
                                  <div
                                    className="table-responsive "
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <MDBDataTable
                                      sortable={false}
                                      className="table table-head-fixed"
                                      striped
                                      bordered
                                      hover
                                      fixedHeader
                                      data={tableEmpTypeWork.data[index]}
                                    />
                                  </div>
                                </div>
                              );
                            else
                              return (
                                <div
                                  className="tab-pane fade"
                                  id={el.columns + "-teb"}
                                  role="tabpanel"
                                  aria-labelledby={el.id}
                                >
                                  <div
                                    className="table-responsive "
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <MDBDataTable
                                      sortable={false}
                                      className="table table-head-fixed"
                                      striped
                                      bordered
                                      hover
                                      fixedHeader
                                      data={tableEmpTypeWork.data[index]}
                                    />
                                  </div>
                                </div>
                              );
                          })}

                          {/* Tap 2 */}

                          {/*************************************************** Tap 3 ****************************************************/}
                          {/* <div className="tab-pane fade" id="custom-tabs-four-page-3" role="tabpanel" aria-labelledby="custom-tabs-four-page-3-tab">
                  Test 3
                </div> */}

                          {/*************************************************** End ********************************************************/}
                        </div>
                      </div>

                      {/* /.card-body */}
                      <div className="card-footer"></div>
                      {/* /.card-footer*/}
                    </div>
                  </>
                </>
            }


            {/* /////////////////////////////////////////////////////////////////////// */}




            <div className="modal fade" id="modal-viewLeaveHistory">
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">
                      ประวัติการลาของ [{viewSelect.name}] <br /> Mode :{" "}
                      {modeChangeModal == "edit"
                        ? "Edit History"
                        : "View History"}
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => clearFilter()}
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {modeChangeModal === "view" ? (
                      <>
                        <div className="row">
                          <div
                            className="col-11 col-md-3 col-xl-2"
                            style={{ "z-index": dateZindex.dropdown1 }}
                          >
                            <div
                              className="form-group"
                              onClick={() => {
                                setdateZindex({
                                  ...dateZindex,
                                  dropdown1: "99",
                                  datehis1: "98",
                                  datehis2: "98",
                                });
                                dateZindex.dropdown1 = "99";
                                dateZindex.datehis1 = "98";
                                dateZindex.datehis2 = "98";
                              }}
                            >
                              <Select
                                options={LeaveTypeOption}
                                /*    ref = {select_matype} */
                                value={LeaveTypeOption.filter((e) => {
                                  return e.value === filter_history.leave_type;
                                })}
                                onChange={(e) => {
                                  //console.log(e);
                                  if (e !== null) {
                                    setfilter_history({
                                      ...filter_history,
                                      leave_type: e.value,
                                    });
                                  }
                                }}
                              />

                              <label htmlFor="">ประเภทการลา</label>
                            </div>
                          </div>

                          <div
                            className="col-11 col-md-3 col-xl-2"
                            style={{ "z-index": dateZindex.datehis1 }}
                          >
                            <div
                              className="form-group "
                              onClick={() => {
                                setdateZindex({
                                  ...dateZindex,
                                  dropdown1: "98",
                                  datehis1: "99",
                                  datehis2: "98",
                                });
                                dateZindex.dropdown1 = "98";
                                dateZindex.datehis1 = "99";
                                dateZindex.datehis2 = "98";
                              }}
                            >
                              <DatePicker
                                selected={filter_history.start_date}
                                className="form-control"
                                dateFormat={"dd-MM-yyyy"}
                                customInput={<CustomInput />}
                                onChange={async (date) => {
                                  setfilter_history({
                                    ...filter_history,
                                    start_date: date,
                                  });
                                }}
                                maxDate={filter_history.finish_date}
                              />
                              <label>เริ่มต้น</label>{" "}
                            </div>
                          </div>

                          {/*     <div className="col-1 col-md-0.5 col-xl-0.5"></div> */}

                          <div
                            className="col-11 col-md-3 col-xl-2"
                            style={{ "z-index": dateZindex.datehis2 }}
                          >
                            <div
                              className="form-group "
                              onClick={() => {
                                setdateZindex({
                                  ...dateZindex,
                                  dropdown1: "98",
                                  datehis1: "98",
                                  datehis2: "99",
                                });
                                dateZindex.dropdown1 = "98";
                                dateZindex.datehis1 = "98";
                                dateZindex.datehis2 = "99";
                              }}
                            >
                              <DatePicker
                                selected={filter_history.finish_date}
                                className="form-control"
                                dateFormat={"dd-MM-yyyy"}
                                customInput={<CustomInput />}
                                onChange={async (date) => {
                                  setfilter_history({
                                    ...filter_history,
                                    finish_date: date,
                                  });
                                }}
                                minDate={filter_history.start_date}
                              />
                              <label>สิ้นสุด</label>{" "}
                            </div>
                          </div>

                          <div className="col-4 col-md-3 col-xl-2">
                            <div className="form-group ">
                              <button
                                type="button"
                                className="btn btn-block btn-info  "
                                onClick={() => {
                                  getLeaveHistoryByEmpId(
                                    viewSelect.emp_id,
                                    viewSelect.name
                                  );
                                }}
                              >
                                Search
                              </button>
                            </div>
                          </div>

                          <div className="col-4 col-md-3 col-xl-2">
                            <div className="form-group ">
                              <button
                                type="button"
                                className="btn btn-block btn-info"
                                onClick={() => clearFilter_history()}
                              >
                                Clear
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className=" table-responsive "
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <MDBDataTable
                            sortable={false}
                            id="table-to-xls"
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={dataHistory}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <section className="content-header">
                          <div className="container-fluid">
                            <div className="row mb-2">
                              <div className="col-6 col-md-3 col-xl-1">
                                <button
                                  type="button"
                                  id="btn_save"
                                  onClick={SaveLeaveEmpData}
                                  class="btn btn-block btn-success "
                                >
                                  บันทึก
                                </button>
                              </div>
                              <div className="col-6 col-md-3 col-xl-1">
                                <button
                                  type="button"
                                  id="btn_cancle"
                                  onClick={cancle}
                                  class="btn btn-block btn-danger "
                                >
                                  ยกเลิก
                                </button>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="content">
                          <div className="card">
                            <div className="card-header">
                              <div className="row">
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>สิทธิการลา</h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    <font color="green">
                                      ลาพักร้อน {data_entLeave.all_pak_ron} วัน
                                    </font>
                                  </h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    <font color="blue">
                                      ลากิจ {data_entLeave.all_la_kit} วัน
                                    </font>
                                  </h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    <font color="red">
                                      ลาป่วย {data_entLeave.all_sick} วัน
                                    </font>
                                  </h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    ประจำปี {"  "}
                                    {parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) + 543}
                                  </h4>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>วันลาคงเหลือ</h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    <font color="green">
                                      ลาพักร้อน{" "}
                                      {parseFloat(data_entLeave.pak_ron)} วัน
                                    </font>
                                  </h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    <font color="blue">
                                      ลากิจ {parseFloat(data_entLeave.la_kit)}{" "}
                                      วัน
                                    </font>
                                  </h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    <font color="red">
                                      ลาป่วย {parseFloat(data_entLeave.sick)}{" "}
                                      วัน
                                    </font>
                                  </h4>
                                </div>
                                <div className="col-6 col-md-3 col-xl-2">
                                  <h4>
                                    ประจำปี {"  "}
                                    {parseInt(
                                      moment(new Date()).format("YYYY")
                                    ) + 543}
                                  </h4>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card-body"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <div className="row">
                                <div
                                  className="col-1 col-md-1 col-xl-1"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="pak_ron_rdo"
                                    checked={
                                      data_leave.leave_type === "ลาพักร้อน"
                                    }
                                    name="type_staus"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        leave_type: "ลาพักร้อน",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-5 col-md-1 col-xl-1">
                                  <label htmlFor="pak_ron_rdo">ลาพักร้อน</label>
                                </div>

                                <div
                                  className="col-1    col-md-0.5 col-xl-0.5"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={data_leave.leave_type === "ลากิจ"}
                                    id="la_kit_rdo"
                                    name="type_staus"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        leave_type: "ลากิจ",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-5 col-md-1 col-xl-1">
                                  <label htmlFor="la_kit_rdo">ลากิจ</label>
                                </div>

                                <div
                                  className="col-1 col-md-0.5 col-xl-0.5"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={data_leave.leave_type === "ลาป่วย"}
                                    id="sick_rdo"
                                    name="type_staus"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        leave_type: "ลาป่วย",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-5  col-md-1 col-xl-1">
                                  <label htmlFor="sick_rdo">ลาป่วย</label>
                                </div>

                                <div
                                  className="col-1 col-md-0.5 col-xl-0.5"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={
                                      data_leave.leave_type ===
                                      "ปฏิบัติงานนอกสถานที่"
                                    }
                                    id="out_site_rdo"
                                    name="type_staus"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        leave_type: "ปฏิบัติงานนอกสถานที่",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-1 col-md-1 col-xl-1">
                                  <label htmlFor="out_site_rdo">
                                    ปฏิบัติงานนอกสถานที่
                                  </label>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-1"></div>
                                <div className="col-10 col-md-6 col-xl-6">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      value={data_leave.detail}
                                      onChange={(e) => {
                                        setdata_leave({
                                          ...data_leave,
                                          detail: e.target.value,
                                        });
                                      }}
                                    />
                                    <Label htmlFor="">รายละเอียด</Label>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="col-1 col-md-1 col-xl-1"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={data_leave.day_type === "full_day"}
                                    id="full_day_rdo"
                                    name="type_staus_day"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        day_type: "full_day",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-5 col-md-1 col-xl-1">
                                  <label htmlFor="full_day_rdo">
                                    ลาทั้งวัน
                                  </label>
                                </div>

                                <div
                                  className="col-1 col-md-1 col-xl-1"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={data_leave.day_type === "half_day"}
                                    id="half_day_rdo"
                                    name="type_staus_day"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        day_type: "half_day",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-5 col-md-1 col-xl-1">
                                  <label htmlFor="half_day_rdo">
                                    ลาช่วงเวลา
                                  </label>
                                </div>

                                <div
                                  className="col-1 col-md-1 col-xl-1"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={
                                      data_leave.day_type === "morning_day"
                                    }
                                    id="morning_rdo"
                                    name="type_staus_day"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        day_type: "morning_day",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-5 col-md-1 col-xl-1">
                                  <label htmlFor="morning_rdo">
                                    ลาครึ่งเช้า
                                  </label>
                                </div>

                                <div
                                  className="col-1 col-md-1 col-xl-1"
                                  style={{ textAlign: "right" }}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    checked={
                                      data_leave.day_type === "afternoon_day"
                                    }
                                    id="afternoon_rdo"
                                    name="type_staus_day"
                                    onChange={() => {
                                      setdata_leave({
                                        ...data_leave,
                                        day_type: "afternoon_day",
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-5 col-md-1 col-xl-1">
                                  <label htmlFor="afternoon_rdo">
                                    ลาครึ่งบ่าย
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-1"></div>

                                <div
                                  className="col-11 col-md-3 col-xl-2"
                                  style={{ "z-index": dateZindex.date1 }}
                                >
                                  <div
                                    className="form-group "
                                    onClick={() => {
                                      setdateZindex({
                                        ...dateZindex,
                                        time2: "98",
                                        time1: "98",
                                        date1: "99",
                                        date2: "98",
                                      });
                                      dateZindex.time2 = "98";
                                      dateZindex.time1 = "98";
                                      dateZindex.date1 = "99";
                                      dateZindex.date2 = "98";
                                    }}
                                  >
                                    <DatePicker
                                      selected={data_leave.start_date}
                                      // value={data_leave.start_date}
                                      className="form-control"
                                      dateFormat={"dd-MM-yyyy"}
                                      customInput={<CustomInput />}
                                      onChange={async (date) => {
                                        setdata_leave({
                                          ...data_leave,
                                          start_date: date,
                                        });
                                      }}
                                    // maxDate={data_leave.finish_date}
                                    />
                                    <label>
                                      วันที่เริ่มลา
                                      {/*    {data_leave.lev_start_date === "" ? (
                <span style={{ color: "red" }}> *</span>
            ) : (
                ""
            )} */}
                                    </label>{" "}
                                  </div>
                                </div>

                                <div className="col-1 col-md-0.5 col-xl-0.5"></div>

                                <div
                                  className="col-11 col-md-3 col-xl-2"
                                  style={{ "z-index": dateZindex.date2 }}
                                >
                                  <div
                                    className="form-group "
                                    onClick={() => {
                                      setdateZindex({
                                        ...dateZindex,
                                        time2: "98",
                                        time1: "98",
                                        date1: "98",
                                        date2: "99",
                                      });
                                      dateZindex.time2 = "98";
                                      dateZindex.time1 = "98";
                                      dateZindex.date1 = "98";
                                      dateZindex.date2 = "99";
                                    }}
                                  >
                                    <DatePicker
                                      selected={data_leave.finish_date}
                                      // value={data_leave.finish_date}
                                      className="form-control"
                                      dateFormat={"dd-MM-yyyy"}
                                      customInput={<CustomInput />}
                                      //onChange={async (date) => SetDate_end_fg(date)}
                                      onChange={async (date) => {
                                        setdata_leave({
                                          ...data_leave,
                                          finish_date: date,
                                        });
                                      }}
                                      minDate={data_leave.start_date}
                                    />
                                    <label>
                                      วันที่สิ้นสุดการลา
                                      {/*  {data_leave.lev_end_date === "" ? (
                <span style={{ color: "red" }}> *</span>
            ) : (
                ""
            )} */}
                                    </label>{" "}
                                  </div>
                                </div>
                              </div>

                              {data_leave.day_type === "half_day" ? (
                                <>
                                  <div className="row">
                                    <div className="col-1"></div>

                                    <div
                                      className="col-11 col-md-3 col-xl-2"
                                      style={{ "z-index": dateZindex.time1 }}
                                    >
                                      <div
                                        className="form-group "
                                        onClick={() => {
                                          setdateZindex({
                                            ...dateZindex,
                                            time1: "99",
                                            time2: "98",
                                            date1: "98",
                                            date2: "98",
                                          });
                                          dateZindex.time1 = "99";
                                          dateZindex.time2 = "98";
                                          dateZindex.date1 = "98";
                                          dateZindex.date2 = "98";
                                        }}
                                      >
                                        <DatePicker
                                          selected={data_leave.start_time}
                                          // value={data_leave.start_time}
                                          className="form-control"
                                          /*   dateFormat={"HH:mm"} */
                                          showTimeSelect
                                          showTimeSelectOnly
                                          customInput={<CustomInput />}
                                          onChange={async (time) => {
                                            console.log(time);
                                            setdata_leave({
                                              ...data_leave,
                                              start_time: time,
                                            });
                                          }}
                                          timeCaption="เวลา"
                                          dateFormat="HH:mm"
                                          timeFormat="HH:mm"
                                        /*        maxTime={data_leave.finish_time} */
                                        />
                                        <label>เวลาเริ่มต้น</label>{" "}
                                      </div>
                                    </div>
                                    <div className="col-1 col-md-0.5 col-xl-0.5"></div>
                                    <div
                                      className="col-11 col-md-3 col-xl-2"
                                      style={{ "z-index": dateZindex.time2 }}
                                    >
                                      <div
                                        className="form-group "
                                        onClick={() => {
                                          setdateZindex({
                                            ...dateZindex,
                                            time2: "99",
                                            time1: "98",
                                            date1: "98",
                                            date2: "98",
                                          });
                                          dateZindex.time2 = "99";
                                          dateZindex.time1 = "98";
                                          dateZindex.date1 = "98";
                                          dateZindex.date2 = "98";
                                        }}
                                      >
                                        <DatePicker
                                          selected={data_leave.finish_time}
                                          // value={data_leave.finish_time}
                                          className="form-control"
                                          dateFormat="HH:mm"
                                          timeFormat="HH:mm"
                                          showTimeSelect
                                          showTimeSelectOnly
                                          customInput={<CustomInput />}
                                          timeCaption="เวลา"
                                          onChange={async (time) => {
                                            console.log(time);
                                            setdata_leave({
                                              ...data_leave,
                                              finish_time: time,
                                            });
                                          }}
                                        /*  minTime={data_leave.start_time} */
                                        />
                                        <label>เวลาสิ้นสุด</label>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div className="row">
                                <div className="col-1"></div>
                                <div className="col-10 col-md-6 col-xl-6">
                                  <label htmlFor="">แนบไฟล์ที่เกี่ยวข้อง</label>
                                  {/* <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small> */}
                                  <div className="input-group">
                                    <div className="custom-file">
                                      <input
                                        // onClick={clickclear}
                                        type="file"
                                        required
                                        className="custom-file-input"
                                        id="exampleInputFile"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,image/png, image/jpeg"
                                        onChange={upload_file}
                                      />
                                      <label
                                        className="custom-file-label"
                                        htmlFor="exampleInputFile"
                                      >
                                        {data_leave.file_name !== null &&
                                          data_leave.file_name !== "" &&
                                          data_leave.file_name !== undefined
                                          ? data_leave.file_name
                                          : "Select File"}
                                      </label>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>

                            {/* /.card-body */}
                            <div className="card-footer"></div>
                            {/* /.card-footer*/}
                          </div>
                          {/* /.card */}
                        </section>
                      </>
                    )}
                  </div>

                  <div className="modal-footer justify-content-between">
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-1"> </div>
                      <div className="col-4"></div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={() => clearFilter()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>


            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Leave_information_remaining;
