import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "../Expenses_Approve/Expenses_Approve_Table.css";
import img_pay_slips from "../../../assets/img/accounting/Capa_1.png";
import img_report_icon from "../../../assets/img/accounting/Frame.png";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button as ButtonAnt, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import Select from "react-select";
import { GetStatusFilterAccounting } from "../Utils/Global_Function";

const DATATEST = [
  /* {
    expenses_id: "1",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 69,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_1", // id master
  },
  {
    expenses_id: "2",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "Smart Clinic",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 500,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_2", // id master
  },
  {
    expenses_id: "3",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "Komatsu",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 25000,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_3", // id master
  },
  {
    expenses_id: "4",
    expenses_date: "2023-05-04 12:04:49.471+07",
    expenses_type_expenses: "ค่าใช้จ่ายวันกีฬา",
    expenses_project: "Cute Products Plus",
    expenses_header_expenses: "ค่าเดินทาง",
    expenses_amount: 1450,
    expenses_payer: "ภูบดี วายพา (Bee)",
    expenses_status: "status_4", // id master
  }, */
];

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ACCOUNTING_STATUS = {
  draft: "3d04de87-1ded-4968-b533-e8b2dda0c4d8", // แบบร่าง
  waiting: "a86edd8a-5b08-445c-8377-9def93ceb8c0", // รอดำเนินการ
  approve_not_upload: "99c1692b-8baa-46b3-983a-c5839a48cf84", // อนุมัติ	// อนุมัติแบบไม่ upload
  approve_uploaded: "d4329102-39f6-420a-820f-7c00f98cbcb3", // อนุมัตื	// อนุมัติแบบ upload
  not_approve: "8ab5629d-754c-4eda-8b8e-bd2920fa6167", // ไม่อนุมัติ
};

function Organization_Expenses_Table({ tabOn }) {
  const [disableRetreatAfterSorting, setDisableRetreatAfterSorting] =
    useState(true);

  const [statusDropdown, setStatusDropdown] = useState({ data: [] });

  const [allEmployee, setAllEmployee] = useState({ data: [] });

  const [TabActive, setTabActive] = useState(tabOn || "2");

  const [previewOpen, setPreviewOpen] = useState(false);

  const [previewImage, setPreviewImage] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [modalState, setModalState] = useState("");

  const [dataForSave, setDataForSave] = useState({
    id: "",
    remark_not_approve: "",
    data: "",
    emp_paid_id: "",
    emp_support_paid_id: "",
    emp_support_paid_name: "",
  });

  const [fileList, setFileList] = useState([]);

  const [fileListApprove, setFileListApprove] = useState({
    data: [],
    onIndex: 0,
  });

  const [filterExpenses, setFilterExpenses] = useState({
    search: "",
    start_date: new Date(moment().startOf("month")),
    end_date: new Date(moment().endOf("month")),
    is_select_all: false,
    status_id: ""
  });

  const [expensesData, setExpensesData] = useState({
    data: DATATEST,
    sum_amount: 0,
    start_d: new Date(moment().startOf("month")),
    end_d: new Date(moment().endOf("month")),
  });
  const [modalUploadSlips, setModalUploadSlips] = useState({ is_all: false, emp_list: [] })
  
  const handleCloseModal = () => {
    setDataForSave({
      ...dataForSave,
      id: "",
      remark_not_approve: "",
    });
    dataForSave.id = "";
    dataForSave.remark_not_approve = "";
    setFileList([]);
    setShowModal(false);
  };

  const handleOpenModal = (state) => {
    setModalState(state);
    setShowModal(true);
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    // console.log("handleRemove",file.img_id);
    // setDelIMG([...delIMG, file.img_id]);
    setFileList(newFileList);
  };

  const handleUploadSuccess = (file) => {
    // console.log("file.fileList :>> ", file.fileList);
    setFileList(file.fileList);
  };

  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data.filter((el) => (
            el.emp_status_type_id !== "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" // ลาออก
            && el.is_active !== false
          ));
          setAllEmployee({ ...allEmployee, data: data });
          allEmployee.data = data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function GetAllExpenses() {
    setDisableRetreatAfterSorting(false);
    let data = {
      company_id: getUser().com,
      oem_id: getOem(),
      search: filterExpenses.search,
      start_date: filterExpenses.start_date,
      end_date: filterExpenses.end_date,
      user_id: getUser().fup,
      status_id: filterExpenses.status_id
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/filterExpensesOfOrgz",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          const _sum_amount = response.data.data.reduce(
            (accumulator, currentValue) =>
              accumulator +
              Number(
                currentValue.emp_exp_status == ACCOUNTING_STATUS.not_approve
                  ? 0
                  : currentValue.emp_exp_amount || 0
              ),
            0
          );
          setExpensesData({
            ...expensesData,
            data: response.data.data,
            sum_amount: _sum_amount,
            start_d: data.start_date,
            end_d: data.end_date,
          });
          expensesData.data = response.data.data;
          expensesData.sum_amount = _sum_amount;
          expensesData.start_d = data.start_date;
          expensesData.end_d = data.end_date;
          setFilterExpenses({
            ...filterExpenses,
            is_select_all: false,
          });
          filterExpenses.is_select_all = false;
        }
        setDisableRetreatAfterSorting(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function GetAllEmpExpenses() {
    setDisableRetreatAfterSorting(false);
    let data = {
      company_id: getUser().com,
      oem_id: getOem(),
      search: filterExpenses.search,
      start_date: filterExpenses.start_date,
      end_date: filterExpenses.end_date,
      user_id: getUser().fup,
      status_id: filterExpenses.status_id,
      not_include_status: [
        ACCOUNTING_STATUS.draft /* ,
        ACCOUNTING_STATUS.waiting */,
      ],
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/filterExpensesOfEmp",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          const _sum_amount = response.data.data.reduce(
            (accumulator, currentValue) =>
              accumulator +
              Number(
                currentValue.emp_exp_status == ACCOUNTING_STATUS.not_approve
                  ? 0
                  : currentValue.emp_exp_amount || 0
              ),
            0
          );
          setExpensesData({
            ...expensesData,
            data: response.data.data,
            sum_amount: _sum_amount,
            start_d: data.start_date,
            end_d: data.end_date,
          });
          expensesData.data = response.data.data;
          expensesData.sum_amount = _sum_amount;
          expensesData.start_d = data.start_date;
          expensesData.end_d = data.end_date;
        }
        setDisableRetreatAfterSorting(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  function clearFilter() {
    setFilterExpenses({
      search: "",
      start_date: new Date(moment().startOf("month")),
      end_date: new Date(moment().endOf("month")),
      is_select_all: false,
      status_id: ""
    });
    filterExpenses.start_date = new Date(moment().startOf("month"));
    filterExpenses.end_date = new Date(moment().endOf("month"));
    filterExpenses.search = "";
    filterExpenses.status_id = "";
    filterExpenses.is_select_all = false;
    if (TabActive == "2") {
      GetAllExpenses();
    } else {
      GetAllEmpExpenses();
    }
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group" style={{ width: "100%" }}>
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  function GetDivAlign(value, align) {
    return <div style={{ textAlign: align || "left" }}>{value}</div>;
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  async function ApproveHaveFiles() {
    const error_list = [];
    if (dataForSave.emp_support_paid_id.trim() == "") {
      let temp_err = {
        message: "กรุณาเลือกผู้สำรองจ่าย",
      };
      error_list.push(temp_err);
    }
    if (fileList.length == 0) {
      let temp_err = {
        message: "กรุณาอัปโหลดรูปภาพ",
      };
      error_list.push(temp_err);
    }
    if (dataForSave.remark_not_approve.trim() == "") {
      let temp_err = {
        message: "กรุณากรอก Remark",
      };
      error_list.push(temp_err);
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
      return;
    }

    Swal.fire({
      title: "คุณมั่นใจที่จะแนบหลักฐานการชำระเงิน (สำรองจ่าย) หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `บันทึก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        for (let item of fileList) {
          data.append("Profile", item.originFileObj);
        }
        data.append("typeFile", "exp_upload_file");

        await axios({
          method: "post",
          url: Configs.API_URL + "/upload/document",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(async function (response) {
            if (response.data.status) {
              const file_list = response.data.data?.map((el) => {
                return { name: el.orgin_name, path: el.path };
              });
              await axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/orgzExpenses/ApproveHaveFiles",
                headers: {
                  Authorization: getToken(),
                  "Content-Type": "application/json",
                  "X-TTT": Configs.API_TTT,
                },
                data: {
                  emp_exp_id: Array.isArray(dataForSave.id)
                    ? dataForSave.id
                    : [dataForSave.id],
                  file_list: file_list,
                  remark: dataForSave.remark_not_approve,
                  user_id: getUser().fup,
                  status_id: ACCOUNTING_STATUS.approve_uploaded,
                  emp_support_paid_id: dataForSave?.emp_support_paid_id || dataForSave?.emp_paid_id
                },
              })
                .then(function (response) { })
                .catch(function (error) {
                  console.log(error);
                });

              Swal.fire({
                icon: "success",
                title: "ทำรายการสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                handleCloseModal();
                setFileList([]);
                setFilterExpenses({
                  ...filterExpenses,
                  is_select_all: false,
                });
                if (TabActive == "2") {
                  GetAllExpenses();
                } else {
                  GetAllEmpExpenses();
                }
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  async function getFilesApproveById(emp_exp_id) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        (TabActive == '2'
          ? "/api/orgzExpenses/getFilesApproveOrgzById"
          : "/api/orgzExpenses/getFilesApproveOrgzByRefId"),
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        emp_exp_id: emp_exp_id,
        user_id: getUser().fup,
      },
    })
      .then(function (response) {
        // console.log("response :>> ", response.data.data);
        if (response.data.data && Array.isArray(response.data.data)) {
          setFileListApprove({ ...fileListApprove, data: response.data.data });
          fileListApprove.data = response.data.data;
        } else {
          setFileListApprove({ ...fileListApprove, data: [] });
          fileListApprove.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  
  async function getEmpCheckStatus(emp_exp_id) {
     return  await axios({
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/orgzExpenses/getEmpCheckStatus",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: {
          emp_exp_id: emp_exp_id,
          status : ACCOUNTING_STATUS.waiting
        },
      })
      .then(function (response) {
        if(response.data.data.length>0){
          return true
        }else {
          return false
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function delData(emp_exp_id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "บันทึก",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement +
            "/api/orgzExpenses/deleteOrgzExpensesById",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: {
            emp_exp_id: emp_exp_id,
            user_id: getUser().fup,
          },
        })
          .then(function (response) {
            // console.log("response :>> ", response.data.data);
            if (response.data.data) {
              Swal.fire({
                icon: "success",
                text: "เสร็จสิ้น",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                setFilterExpenses({
                  ...filterExpenses,
                  is_select_all: false,
                });
                if (TabActive == "2") {
                  GetAllExpenses();
                } else {
                  GetAllEmpExpenses();
                }
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }

  
  function GetBodyModal(modalState) {
    if (modalState === "not_approve") {
      return (
        <div style={{ padding: "15px" }}>
          <textarea
            type="text"
            className="form-control"
            required
            rows={4}
            id="award_point_name"
            value={dataForSave.remark_not_approve}
            onChange={(e) => {
              // check_rf_name(e);
              setDataForSave({
                ...dataForSave,
                remark_not_approve: e.target.value,
              });
              dataForSave.remark_not_approve = e.target.value;
            }}
          />
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={() => {
                SendStatusExpensesByOrgz("not_aprrove");
              }}
              className="btn btn-success"
              id="btn-save"
            >
              Save
            </button>
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={handleCloseModal}
              className="btn btn-danger"
              id="btn-cancle"
            >
              Cancel
            </button>
          </div>
        </div>
      );
    } else if (modalState === "upload_slips") {
      return (
        <div /* style={{ padding: '15px'}} */>
          <div style={{ padding: '15px' }}>
            <div className="form-group" style={{ margin: "0" }}>
              <Select
                filterOption={(option, searchText) => {
                  if (
                    option.data.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                    // || option.data.value
                    //   .toLowerCase()
                    //   .includes(searchText.toLowerCase())
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                options={[
                  ...allEmployee["data"]
                    .filter((el) => (![dataForSave.emp_paid_id, ...modalUploadSlips.emp_list].includes(el.emp_id)))
                    .map(
                      (el) => ({
                        value: el.emp_id,
                        label: el.emp_name_th,
                      })
                    ),
                ]}
                value={
                  dataForSave.emp_support_paid_id != null
                    ? {
                      value: dataForSave.emp_support_paid_id,
                      label: dataForSave.emp_support_paid_name,
                    }
                    : null
                }
                onChange={(selectedOptions) => {
                  setDataForSave({
                    ...dataForSave,
                    emp_support_paid_id: selectedOptions.value || "",
                    emp_support_paid_name: selectedOptions.label || "",
                  });
                }}
              />
              <label style={{ marginTop: "-4px" }}>
                ผู้สำรองจ่าย{" "}
                <span style={{ color: "red" }}>{" *"}</span>
              </label>
            </div>
          </div>
          <div
            className="area-upload-files-expenses-user"
            style={{
              /* display: "flex",
              flexDirection: "column",
              alignItems: "center", */
              padding: "15px",
            }}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              fileList={fileList}
              onRemove={handleRemove}
              onPreview={handlePreview}
              onChange={handleUploadSuccess}
              accept=".jpg,.png"
              multiple
            >
              <ButtonAnt
                type="primary"
                style={{ backgroundColor: "#3579F626", color: "#3579F6" }}
              >
                Choose files
              </ButtonAnt>
            </Upload>
          </div>
          <div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "800",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <textarea
                type="text"
                className="form-control"
                required
                rows={4}
                id="award_point_name"
                value={dataForSave.remark_not_approve}
                onChange={(e) => {
                  // check_rf_name(e);
                  setDataForSave({
                    ...dataForSave,
                    remark_not_approve: e.target.value,
                  });
                  dataForSave.remark_not_approve = e.target.value;
                }}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              paddingBottom: "15px",
            }}
          >
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={ApproveHaveFiles}
              className="btn btn-success"
              id="btn-save"
            >
              Save
            </button>
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={handleCloseModal}
              className="btn btn-danger"
              id="btn-cancle"
            >
              Cancel
            </button>
          </div>
        </div>
      );
    } else if (modalState === "view_remark_not_approve") {
      return (
        <div /* style={{ padding: "15px" }} */>
          <div>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <p>{dataForSave.remark_not_approve || "-"}</p>
            </div>
          </div>
        </div>
      );
    } else if (modalState === "view_slips") {
      return (
        <div /* style={{ padding: "15px" }} */>
          <div
            style={{
              padding: "15px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              hidden={fileListApprove.data.length == 0}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index - 1 == -1) {
                  index = fileListApprove.data.length - 1;
                } else {
                  index -= 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
                fileListApprove.onIndex = index;
              }}
            >
              {"<"}
            </div>
            <div
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {fileListApprove.data?.[fileListApprove.onIndex]
                ?.emp_file_path ? (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid "
                  src={
                    Configs.API_URL_IMG +
                    fileListApprove.data?.[fileListApprove.onIndex]
                      .emp_file_path
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    objectFit: "contain",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                />
              ) : (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid rounded shadow border-0"
                  src={userdefault_img.imgs}
                  style={{
                    // width: "60px",
                    // height: "60px",
                    position: "relative",
                    objectFit: "cover",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                />
              )}
              {/* <img
                id="img"
                alt="..."
                className="img-fluid rounded shadow border-0"
                src={
                  fileListApprove.data?.[fileListApprove.onIndex].emp_file_path !== "" &&
                  fileListApprove.data?.[fileListApprove.onIndex].emp_file_path !== null &&
                  fileListApprove.data?.[fileListApprove.onIndex].emp_file_path !== undefined
                    ? Configs.API_URL_IMG + fileListApprove.data?.[fileListApprove.onIndex].emp_file_path
                    : userdefault_img.imgs
                }
                style={{
                  // width: "60px",
                  // height: "60px",
                  position: "relative",
                  objectFit: "cover",
                  display: "block",
                  "margin-left": "auto",
                  "margin-right": "auto",
                }}
              /> */}
              {/* <img src={fileListApprove.data[fileListApprove.onIndex].emp_file_path} /> */}
            </div>
            <div
              hidden={fileListApprove.data.length == 0}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index + 1 == fileListApprove.data.length) {
                  index = 0;
                } else {
                  index += 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
                fileListApprove.onIndex = index;
              }}
            >
              {">"}
            </div>
          </div>
          <div>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <p>{dataForSave.remark_not_approve || "-"}</p>
            </div>
          </div>
        </div>
      );
    }
  }

  async function fetchAndPreviewPDF(emp_exp_id, is_table_emp) {
    Swal.fire({
      title: "กำลังออกรายงาน",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const response = await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/orgzExpenses/viewReportBillPay",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        emp_exp_id: Array.isArray(emp_exp_id) ? emp_exp_id : [emp_exp_id],
        user_id: getUser().fup,
        url: Configs.API_URL_IMG,
        is_table_emp,
      },
    });
    // console.log("response.data.data :>> ", response.data.data);
    const data = await response.data.data;

    // // Decode the base64 PDF string
    // const byteCharacters = atob(data.base64);
    // const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
    // const byteArray = new Uint8Array(byteNumbers);

    // // Create a Blob from the byte array
    // const blob = new Blob([byteArray], { type: "application/pdf" });

    // // Create a URL for the Blob and open it in a new tab
    // const pdfUrl = URL.createObjectURL(blob);
    // window.open(pdfUrl, "_blank");

    // Create a data URL for the PDF
    const pdfDataUrl = `data:application/pdf;base64,${data.base64}`;
    // console.log('pdfDataUrl :>> ', pdfDataUrl);
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = data.file_name || "downloaded_file.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after click

    if (data.file_name) {
      await delFileReport(data.file_name);
    }
    Swal.close();
  }
  

  async function delFileReport(file_name) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/orgzExpenses/delReportBillPay",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        file_name: file_name,
        folder: `${getUser().fup.replace(/-/g, "_")}`,
      },
    }).catch((error) => {
      console.log("error :>> ", error);
    });
  }

  async function SendStatusExpensesByOrgz(state) {
    const data = {
      emp_exp_id: dataForSave.id,
      status_id: "",
      remark: dataForSave.remark_not_approve.trim(),
      user_id: getUser().fup,
      data: "",
      draft_status: "",
      state: state,
    };
    if (state === "not_aprrove") {
      data.status_id = ACCOUNTING_STATUS.not_approve;
      if (!data.remark) {
        Swal.fire("Error", `กรุณากรอก Remark`, "error");
        return;
      }
    } else if (state === "aprrove") {
      data.status_id = ACCOUNTING_STATUS.approve_not_upload;
      data.remark = null;
      data.data = dataForSave.data;
      data.draft_status = ACCOUNTING_STATUS.draft;
    }
    handleCloseModal();
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/SendStatusExpensesByOrgz",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data) {
          Swal.fire({
            icon: "success",
            title: "ทำรายการสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            if (TabActive == "2") {
              GetAllExpenses();
            } else {
              GetAllEmpExpenses();
              if (state === "aprrove") {
                window.location.href = "/accounting/organization_expenses/edit/" + response.data.data;
              }
            }
            setDataForSave({
              ...dataForSave,
              remark_not_approve: "",
            });
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function sendApproveFromOrgzToAdmin(org_id) {
    const data = {
      org_id_list: org_id,
      status_id: ACCOUNTING_STATUS.waiting,
      user_id: getUser().fup,
      remark: "",
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/sendApproveFromOrgzToAdmin",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async function (response) {
        if (response.data.data !== false) {
          Swal.fire({
            icon: "success",
            title: "ส่งอนุมัติสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            if (TabActive == "2") {
              GetAllExpenses();
            } else {
              GetAllEmpExpenses();
            }
            setFilterExpenses({
              ...filterExpenses,
              is_select_all: false,
            });
            filterExpenses.is_select_all = false;
          });
        } else {
          Swal.fire("Error", "ส่งอนุมัติไม่สำเร็จ", "error");
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "Error",
          "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
          "error"
        );
      });
  }

  function disabledBtnFile() {
    const _data = [...expensesData.data].filter(
      (el) =>
        el.is_select && el.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
    );
    if (_data.length === 0) return true;
    return false;
    // return !_data.every(el => el.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded);
  }

  function generateDataTable(expenses, index) {
    const element = {};
    // console.log(expenses.emp_ref_id);
    element["select"] = (
      <div style={{ textAlign: "center", paddingTop: "0px" }}>
        <input
          style={{ width: "20px", height: "20px" }}
          type="checkbox"
          id="select_all"
          checked={expenses.is_select || false}
          onChange={(e) => {
            const dataTemp = [...expensesData.data];
            dataTemp[index].is_select = e.target.checked;
            setExpensesData({
              ...expensesData,
              data: dataTemp,
            });
            expensesData.data = dataTemp;

            const temp_is_select_all = dataTemp.every((el) => el.is_select);
            setFilterExpenses({
              ...filterExpenses,
              is_select_all: temp_is_select_all,
            });
            filterExpenses.is_select_all = temp_is_select_all;
          }}
        ></input>
      </div>
    );

    element["no"] = GetDivAlign(index + 1, "center");
    element["cep"] = GetDivAlign(expenses.cep_code || "-", "center");
    element["uep"] = GetDivAlign(expenses.uep_code || "-", "center");
    element["no_search"] = index + 1;

    element["expenses_date"] = GetDivAlign(
      moment(expenses.emp_exp_start_date).format("DD-MM-YYYY"),
      "center"
    );
    element["expenses_date_search"] = moment(
      expenses.emp_exp_start_date
    ).format("DD-MM-YYYY");

    element["expenses_type_expenses"] = GetDivAlign(
      <div style={{ minWidth: "120px" }}>{expenses.acc_cc_name || "-"}</div>,
      "left"
    );
    element["expenses_type_expenses_search"] = expenses.acc_cc_name || "-";

    element["expenses_project"] = GetDivAlign(expenses.acc_project_name || "-", "left");

    element["expenses_header_expenses"] = GetDivAlign(
      expenses.acc_et_name || "-",
      "center"
    );
    element["expenses_header_expenses_search"] = expenses.acc_et_name || "-";

    element["expenses_amount"] = GetDivAlign(
      Number(expenses.emp_exp_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-",
      "right"
    );
    element["expenses_amount_search"] =
      Number(expenses.emp_exp_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-";
    element["expenses_payer"] = GetDivAlign(
      <div style={{ minWidth: "120px" }}>
        {expenses.emp_name_th
          ? `${expenses.emp_name_th} (${expenses.nickname})`
          : "-"}
        {expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded ? (
          <p
            style={{
              color: "#1DC256",
              fontSize: "16px",
              padding: 0,
              margin: 0,
            }}
          >
            จ่ายแล้ว
          </p>
        ) : (
          <></>
        )}
      </div>,
      "left"
    );
    element["expenses_reserve_payer"] = GetDivAlign(
      <div style={{ minWidth: "120px" }}>
        {expenses.name2 && expenses.name2 !== expenses.emp_name_th
          ? `${expenses.name2} (${expenses.nickname2})`
          : "-"}
        {expenses.name2 &&
          expenses.name2 !== expenses.emp_name_th &&
          expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded ? (
          <p
            style={{
              color: "#1DC256",
              fontSize: "16px",
              padding: 0,
              margin: 0,
            }}
          >
            จ่ายแล้ว
          </p>
        ) : (
          <></>
        )}
      </div>,
      "left"
    );
    element["expenses_payer_search"] =
      expenses.emp_name_th ||
        "-" + expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
        ? "จ่ายแล้ว"
        : "";

    element["expenses_status"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {TabActive == "1" &&
          expenses.emp_exp_status == ACCOUNTING_STATUS.waiting ? (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <div
              className="expenses-admin-status_1 btn-expenses-admin-status4 cursor_expenses_admin_status"
              id="approve-admin"
              key="approve-admin"
              onClick={async () => {
                const getData = await  getEmpCheckStatus(expenses.emp_exp_id)
                if(getData === true){
                       Swal.fire({
                          title: "คุณต้องการที่จะอนุมัติหรือไม่",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: `บันทึก`,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          cancelButtonText: `ยกเลิก`,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            setDataForSave({
                              ...dataForSave,
                              id: expenses.emp_exp_id,
                              data: expenses,
                            });
                            dataForSave.id = expenses.emp_exp_id;
                            dataForSave.data = expenses;
                            SendStatusExpensesByOrgz("aprrove");
                          }
                        });
                 }else{
                  Swal.fire({
                    title: "Saving",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    timer: 1000,
                    onOpen: () => {
                      Swal.showLoading();
                    },
                  })
                    .then(function (response) {
                      Swal.fire({
                        icon: "error",
                        title: "ข้อมูลมีการเปลี่ยนแปลง",
                        showConfirmButton: false,
                        timer: 1500,
                      }).then(() => {
                        window.location.href =
                          "/accounting/organization_expenses";
                      });
                      
                    })
                 }
                // alert('test')
                
              }}
            >
              อนุมัติ
            </div>

            <div
              className="expenses-admin-status_4 btn-expenses-admin-status4 cursor_expenses_admin_status"
              id="not-approve-admin"
              key="not-approve-admin"
              onClick={() => {
                Swal.fire({
                  title: "คุณต้องการที่จะไม่อนุมัติหรือไม่",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: `บันทึก`,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: `ยกเลิก`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    setDataForSave({
                      ...dataForSave,
                      id: expenses.emp_exp_id,
                      data: expenses,
                    });
                    dataForSave.id = expenses.emp_exp_id;
                    dataForSave.data = expenses;
                    handleOpenModal("not_approve");
                  }
                });
              }}
            >
              ไม่อนุมัติ
            </div>
          </div>
        ) : TabActive == "1" && expenses.emp_exp_status !== ACCOUNTING_STATUS.approve_uploaded ? (
          <div
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
            className={
              (expenses.emp_exp_status ===
                ACCOUNTING_STATUS.approve_not_upload ||
                expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
                ? "expenses-admin-status_2"
                : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
                  ? "expenses-admin-status_3"
                  : "expenses-admin-status_4") + " btn-expenses-admin-status"
            }
            onClick={() => {
              /* if (expenses.emp_exp_status === "status_1") {
                handleOpenModal("view_slips");
              } else if (expenses.emp_exp_status === "status_3") {
                handleOpenModal("view_remark_not_approve");
              } */
            }}
          >
            {expenses.emp_exp_status === ACCOUNTING_STATUS.approve_not_upload ||
              expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
              ? expenses.emp_status_name || "อนุมัติ"
              : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
                ? "ไม่อนุมัติ"
                : "-"}
            {/* {expenses.emp_exp_status === "status_1" || expenses.emp_exp_status === "status_2"? (
              <i
                style={{ fontSize: "16px" }}
                class="ri-money-dollar-circle-fill"
              ></i>
            ) : expenses.emp_exp_status === "status_3" ? (
              <i style={{ fontSize: "16px" }} class="ri-message-fill"></i>
            ) : (
              <></>
            )} */}
          </div>
        ) : (
          <div
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
            className={
              (expenses.emp_exp_status === ACCOUNTING_STATUS.draft
                ? " expenses_user_status_0"
                : expenses.emp_exp_status ===
                  ACCOUNTING_STATUS.approve_not_upload
                  ? "expenses_user_status_1"
                  : expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
                    ? "expenses_user_status_2 cursor_expenses_admin_status"
                    : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
                      ? "expenses_user_status_3 cursor_expenses_admin_status"
                      : "expenses_user_status_4 ") + " btn-expenses-user-status"
            }
            onClick={async () => {
              if (
                expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
              ) {
                setDataForSave({
                  ...dataForSave,
                  id: expenses.emp_exp_id,
                  remark_not_approve: expenses.emp_exp_remark,
                });
                dataForSave.id = expenses.emp_exp_id;
                dataForSave.remark_not_approve = expenses.emp_exp_remark;
                getFilesApproveById(expenses.emp_exp_id);
                handleOpenModal("view_slips");
              } else if (
                expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
              ) {
                setDataForSave({
                  ...dataForSave,
                  id: expenses.emp_exp_id,
                  remark_not_approve: expenses.emp_exp_remark,
                });
                dataForSave.id = expenses.emp_exp_id;
                dataForSave.remark_not_approve = expenses.emp_exp_remark;
                handleOpenModal("view_remark_not_approve");
              }
            }}
          >
            {expenses.emp_status_name}
            {expenses.emp_exp_status ===
              ACCOUNTING_STATUS.approve_uploaded /*  || expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded */ ? (
              <i
                style={{ fontSize: "16px" }}
                class="ri-money-dollar-circle-fill"
              ></i>
            ) : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve ? (
              <i style={{ fontSize: "16px" }} class="ri-message-fill"></i>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );

    element["mgt"] = (
      <div
        className=""
        style={{
          // flexWrap: "nowrap",
          display: "flex",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          hidden={TabActive === "1"}
          style={{
            textAlign: "center",
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
        >
          <a
            key={expenses.emp_exp_id + "del"}
            id="btn_delete"
            style={{ border: "none", cursor: "pointer" }}
            className=" btn btn-xs "
            onClick={() => {
              /* setDataForSave({
                ...dataForSave,
                id: expenses.emp_exp_id,
                remark_not_approve: "",
              });
              dataForSave.id = expenses.emp_exp_id;
              dataForSave.remark_not_approve = "";
              handleOpenModal("upload_slips"); */
              fetchAndPreviewPDF(expenses.emp_exp_id, TabActive == "1");
            }}
          >
            <img width={18} src={img_report_icon} />
          </a>
        </div>
        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{
            textAlign: "center",
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
          hidden={
            (expenses.emp_exp_status !== ACCOUNTING_STATUS.not_approve &&
              expenses.emp_exp_status !== ACCOUNTING_STATUS.draft) ||
            TabActive == "1"
          }
        >
          <a
            href={
              "/accounting/organization_expenses/edit/" +
              expenses.emp_exp_id
            }
            id="btn_edit"
            style={{ border: "none", cursor: "pointer" }}
            key={expenses.emp_exp_id + "edit"}
            className=" btn btn-xs "
            onClick={() => {
              sessionStorage.setItem('state_filter_org_expenses', JSON.stringify({
                search: filterExpenses.search,
                start_date: filterExpenses.start_date,
                end_date: filterExpenses.end_date,
              }));
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        {expenses.emp_ref_id === null && (
          <div
            // className="col-xl-3 col-md-3 col-xs-3"
            style={{
              textAlign: "center",
              padding: 0,
              border: "none",
              cursor: "pointer",
            }}
            hidden={
              expenses.emp_ref_id ||
              expenses.emp_exp_status === (ACCOUNTING_STATUS.waiting || ACCOUNTING_STATUS.approve_not_upload || ACCOUNTING_STATUS.approve_uploaded) ||
              TabActive == "1"
            }
          >
            <a
              key={expenses.emp_exp_id + "del"}
              id="btn_delete"
              style={{ border: "none", cursor: "pointer" }}
              className=" btn btn-xs "
              onClick={() => {
                delData(expenses.emp_exp_id);
              }}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        )}
        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{
            textAlign: "center",
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
        >
          <a
            href={
              (TabActive == "1"
                ? "/accounting/emp_expenses_approve/view/"
                : "/accounting/organization_expenses/view/") +
              expenses.emp_exp_id
            }
            id="btn_read"
            style={{ border: "none", cursor: "pointer" }}
            key={expenses.rp_id + "view"}
            className="btn btn-xs "
            onClick={() => {
              sessionStorage.setItem('state_filter_org_expenses', JSON.stringify({
                search: filterExpenses.search,
                start_date: filterExpenses.start_date,
                end_date: filterExpenses.end_date,
              }));
            }}
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div
          hidden={
            expenses.is_reserve ||
            expenses.emp_exp_status !== ACCOUNTING_STATUS.draft ||
            TabActive == "1"
          }
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{
            textAlign: "center",
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
        >
          <a
            key={expenses.emp_exp_id + "del"}
            id="btn_delete"
            style={{ border: "none", cursor: "pointer" }}
            className=" btn btn-xs "
            onClick={() => {
              setDataForSave({
                ...dataForSave,
                id: expenses.emp_exp_id,
                remark_not_approve: "",
                emp_paid_id: expenses.emp_exp_paid_by_old,
                emp_support_paid_id: "",
                emp_support_paid_name: "",
              });
              dataForSave.id = expenses.emp_exp_id;
              dataForSave.remark_not_approve = "";
              dataForSave.emp_paid_id = expenses.emp_exp_paid_by_old;
              dataForSave.emp_support_paid_id = "";
              dataForSave.emp_support_paid_name = "";

              setModalUploadSlips({
                ...modalUploadSlips,
                is_all: false,
                emp_list: []
              });
              modalUploadSlips.is_all = false;
              modalUploadSlips.emp_list = [];
              handleOpenModal("upload_slips");
            }}
          >
            <img width={16} src={img_pay_slips} />
          </a>
        </div>
      </div>
    );
    return element;
  }

  const dataExpenses = [];
  for (let index = 0; index < expensesData.data.length; index++) {
    const expenses = expensesData.data[index];
    dataExpenses.push(generateDataTable(expenses, index));
  }

  const data = {
    columns: [
      {
        label: (
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <input
              style={{ width: "20px", height: "20px" }}
              type="checkbox"
              id="select_all"
              checked={filterExpenses.is_select_all}
              onChange={(e) => {
                const dataTemp = [...expensesData.data];
                dataTemp.forEach((el) => {
                  el.is_select = e.target.checked;
                });
                setExpensesData({
                  ...expensesData,
                  data: dataTemp,
                });
                expensesData.data = dataTemp;
                setFilterExpenses({
                  ...filterExpenses,
                  is_select_all: e.target.checked,
                });
                filterExpenses.is_select_all = e.target.checked;
              }}
            ></input>
          </div>
        ),
        field: "select",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("No.", "center"),
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("UEP", "center"),
        field: "uep",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("CEP", "center"),
        field: "cep",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("วันที่", "center"),
        field: "expenses_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("รายการค่าใช้จ่าย", "center"),
        field: "expenses_type_expenses",
        // sort: "asc",
        width: 100,
      },
      {
        label: GetDivAlign("โครงการ", "center"),
        field: "expenses_project",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("หัวข้อค่าใช้จ่าย", "center"),
        field: "expenses_header_expenses",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("ยอดเงิน", "center"),
        field: "expenses_amount",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("ผู้จ่าย", "center"),
        field: "expenses_payer",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("ผู้สำรองจ่าย", "center"),
        field: "expenses_reserve_payer",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("สถานะ", "center"),
        field: "expenses_status",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("MGT", "center"),
        field: "mgt",
        // sort: "asc",
        width: 100,
      },
    ],
    rows: dataExpenses,
  };
  if (TabActive == "1") {
    data.columns.splice(0, 1);
    // data.columns.splice(1, 1);
    data.columns.splice(3, 1);
  } else if (TabActive == "2") {
    // data.columns.splice(2, 1);
  }

  useEffect(async () => {
    const data = await GetStatusFilterAccounting({
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      type: 1 // 1 is status expenses 2 is tax witholding
    });
    setStatusDropdown({
      data: data
    });
    statusDropdown.data = data;
  }, [])

  useEffect(() => {
    const data2 = sessionStorage.getItem('state_filter_org_expenses');
    if (data2) {
      const data_filter = JSON.parse(data2);
      setFilterExpenses({
        ...filterExpenses,
        search: data_filter.search,
        start_date: new Date(data_filter.start_date),
        end_date: new Date(data_filter.end_date),
      });
      filterExpenses.search = data_filter.search;
      filterExpenses.start_date = new Date(data_filter.start_date);
      filterExpenses.end_date = new Date(data_filter.end_date);
      sessionStorage.removeItem('state_filter_org_expenses');
    }
    if (TabActive == "2") {
      GetEmployee();
      GetAllExpenses();
    } else {
      GetAllEmpExpenses();
    }
  }, [TabActive]);

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Organization Expenses {"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">
                    Organization Expenses
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterExpenses.search}
                    onChange={(e) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterExpenses.start_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        start_date: date,
                      });
                    }}
                    maxDate={new Date(filterExpenses.end_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>Start Date</label>
                </div>
              </div>
              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterExpenses.end_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        end_date: date,
                      });
                    }}
                    minDate={new Date(filterExpenses.start_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>End Date</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group"/*  style={{ zIndex: '100' }} */>
                  <Select
                    // isMulti
                    // isDisabled={isDisabled}
                    options={statusDropdown.data}
                    // isOptionDisabled={(option) => option.disabled}
                    // formatOptionLabel={(label) => label.html}
                    filterOption={(option, searchText) => {
                      if (
                        option.data.label
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                        // || option.data.value
                        //   .toLowerCase()
                        //   .includes(searchText.toLowerCase())
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    value={
                      statusDropdown.data.find(el => el.value == (filterExpenses.status_id || null))
                    }
                    onChange={(e) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        status_id: e.value
                      })
                    }}
                  />
                  <label>Status</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary  "
                    onClick={() => {
                      if (TabActive == "2") {
                        GetAllExpenses();
                      } else {
                        GetAllEmpExpenses();
                      }
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    disabled={!expensesData.data.some((el) => el.is_select)}
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      // window.location.href =
                      //   "/accounting/Emp_Expenses_Admin/Add";
                      const _data = [...expensesData.data]
                        .filter((el) => el.is_select)
                        .map((el) => el.emp_exp_id);
                      fetchAndPreviewPDF(_data);
                    }}
                  >
                    ออกใบสำคัญจ่าย
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    disabled={
                      [...expensesData.data].filter((el) => el.is_select)
                        .length === 0 ||
                      ![...expensesData.data]
                        .filter((el) => el.is_select)
                        .every(
                          (el) =>
                            el.emp_exp_status ===
                            ACCOUNTING_STATUS.draft &&
                            !el.is_reserve
                        )
                    }
                    onClick={() => {
                      const _data = [...expensesData.data]
                        .filter(
                          (el) =>
                            el.is_select &&
                            el.emp_exp_status ===
                            ACCOUNTING_STATUS.draft &&
                            !el.is_reserve
                        )
                        .map((el) => el.emp_exp_id);
                      setDataForSave({
                        ...dataForSave,
                        id: _data,
                        remark_not_approve: "",
                      });
                      dataForSave.id = _data;
                      dataForSave.remark_not_approve = "";

                      const _data2 = [...expensesData.data]
                        .filter(
                          (el) =>
                            el.is_select &&
                            el.emp_exp_status ===
                            ACCOUNTING_STATUS.draft &&
                            !el.is_reserve
                        )
                        .map((el) => el.emp_exp_paid_by);
                      setModalUploadSlips({
                        ...modalUploadSlips,
                        is_all: true,
                        emp_list: _data2
                      });
                      modalUploadSlips.is_all = true;
                      modalUploadSlips.emp_list = _data2;
                      handleOpenModal("upload_slips");
                    }}
                  >
                    แนบไฟล์ All
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    disabled={
                      expensesData.data.filter((el) => el.is_select).length ==
                      0 ||
                      !expensesData.data
                        .filter((el) => el.is_select)
                        .every(
                          (el) =>
                            (el.emp_exp_status == ACCOUNTING_STATUS.draft ||
                              el.emp_exp_status == ACCOUNTING_STATUS.not_approve) &&
                            (el.emp_exp_is_fc || el.emp_exp_is_cp || el.emp_exp_is_cos) &&
                            el.emp_exp_cc_id
                        )
                    }
                    onClick={() => {
                      Swal.fire({
                        title: `คุณต้องการที่จะ${"ส่งอนุมัติ"}หรือไม่`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: true ? "ใช่" : `บันทึก`,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: true ? "ไม่" : `ยกเลิก`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const _data = [...expensesData.data]
                            .filter(
                              (el) =>
                                el.is_select &&
                                (el.emp_exp_status == ACCOUNTING_STATUS.draft ||
                                  el.emp_exp_status ==
                                  ACCOUNTING_STATUS.not_approve)
                            )
                            .map((el) => el.emp_exp_id);
                          sendApproveFromOrgzToAdmin(_data);
                        }
                      });
                    }}
                  >
                    ส่งอนุมัติ
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/accounting/organization_expenses/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                }}
              >
                สรุปยอดค่าใช้จ่ายวันที่
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                  color: "#3579F6",
                  padding: "0px 10px",
                }}
              >
                {`${moment(expensesData.start_d).format(
                  "DD MMM YY"
                )} - ${moment(expensesData.end_d).format("DD MMM YY")}`}
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                }}
              >
                ทั้งหมด :
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                  padding: "0px 10px",
                  color: "#3579F6",
                }}
              >
                {Number(expensesData.sum_amount || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" key={"1"}>
                  <a
                    className={`nav-link ${TabActive == "1" ? "active" : ""}`}
                    id={`tab-${"1"}-tab`}
                    data-toggle="tab"
                    href={`#tab-${"1"}`}
                    role="tab"
                    aria-controls={`tab-${"1"}`}
                    aria-selected={"true"}
                    onClick={() => {
                      if (TabActive != "1") {
                        setExpensesData({
                          ...expensesData,
                          data: [],
                        });
                        expensesData.data = [];
                      }
                      setTabActive("1");
                    }}
                  >
                    Approve Emp.
                  </a>
                </li>
                <li className="nav-item" key={"2"}>
                  <a
                    className={`nav-link ${TabActive == "2" ? "active" : ""}`}
                    id={`tab-${"2"}-tab`}
                    data-toggle="tab"
                    href={`#tab-${"2"}`}
                    role="tab"
                    aria-controls={`tab-${"2"}`}
                    aria-selected={"false"}
                    onClick={() => {
                      if (TabActive != "2") {
                        setExpensesData({
                          ...expensesData,
                          data: [],
                        });
                        expensesData.data = [];
                      }
                      setTabActive("2");
                    }}
                  >
                    All Expenses
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade ${TabActive == "1" ? "show active" : ""
                    }`}
                  id={`tab-${"1"}`}
                  role="tabpanel"
                  aria-labelledby={`tab-${"1"}-tab`}
                  key={"1"}
                  style={{ padding: "1.25rem" }}
                >
                  <div
                    className="table-responsive "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      disableRetreatAfterSorting={disableRetreatAfterSorting}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={data}
                    />
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${TabActive == "2" ? "show active" : ""
                    }`}
                  id={`tab-${"2"}`}
                  role="tabpanel"
                  aria-labelledby={`tab-${"2"}-tab`}
                  key={"2"}
                  style={{ padding: "1.25rem" }}
                >
                  <div
                    className="table-responsive "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      disableRetreatAfterSorting={disableRetreatAfterSorting}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={data}
                    />
                  </div>
                </div>
              </div>
              <div className="card-footer"></div>
            </div>
          </div>
          {/* /.card */}
          {/* Modal */}
          <Modal
            centered
            isOpen={showModal}
            toggle={handleCloseModal}
          // style={{ width: "50%" }}
          >
            <ModalHeader
              style={{
                /* border: "none" */ paddingTop: "10px",
                paddingBottom: "10px",
              }}
              toggle={
                modalState === "view_remark_not_approve" ||
                  modalState === "view_slips"
                  ? handleCloseModal
                  : undefined
              }
            >
              <span style={{ fontSize: "24px", fontWeight: "800" }}>
                {modalState === "upload_slips"
                  ? "แนบหลักฐานการชำระเงิน (สำรองจ่าย)"
                  : modalState === "view_remark_not_approve"
                    ? "รายละเอียดการไม่อนุมัติ"
                    : modalState === "view_slips"
                      ? "รายละเอียดการอนุมัติ"
                      : "Remark"}
              </span>
            </ModalHeader>
            <ModalBody style={{ padding: "0" }}>
              {GetBodyModal(modalState)}
            </ModalBody>
          </Modal>
          {previewImage && (
            <div className="expenses-image-popup" id="expenses-image-popup">
              <Image
                className="image"
                wrapperStyle={{ display: "none", zIndex: 1051 }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            </div>
          )}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Organization_Expenses_Table;
