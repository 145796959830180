import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature } from "../../Utils/Common";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { WIP_SSTInOut_add } from "..";
import DatePicker from "react-datepicker";
function WIP_SST_adds(mode) {

  const [filler_order_product, setFiller_order_product] = useState({
    order_no: "",
    start_date: "",
    end_date: "",
    oem_id: getOem(),
  });
  const rowsData = [];

  const CustomInput = ({ value, onClick }) => ( 
      
    <
    div className = "input-group" >
    < input type = "text"
    className = "form-control float-left"
    onClick = { onClick }
    value = { value }
    /> <div className = "input-group-prepend" >
    <span className = "input-group-text" >
    <i className = "far fa-calendar-alt" />
    </span>
  
    { /*  <label>test</label> */ } 
    </div> 
    </div>
  
  
  )
  async function SetDate_start(date){
    setFiller_order_product({ ...filler_order_product, start_date: date})

   }
    async function SetDate_end(date){
      setFiller_order_product({ ...filler_order_product, end_date: date})

   }
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [type_select, settype_select] = useState({
    status: "plant_item",
  });
  const [model, setModel] = useState([]);


  const [feature, setfeature] = useState([]);
  const [filter_product, setfilter_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });
  const [test, settest] = useState({
    test: "",
  });
  const [MDBT,setMDBT] =useState(true);
  const [product,setProduct] = useState([]);
  const [product_select,setProduct_select] = useState([]);

  const { id } = useParams();





  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Spare Part";
    });
    setfeature(feature_data);

    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const [SparePart, setSparePart] = useState({
    spare_part_no: "",
    spare_part_name: "",
    product_ref: "",
    description: "",
    company_id: getUser().com,
    amount: 0,
  });

  const GetProduct = async () => {
   
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product,
    })
      .then(async (response) => {
        console.log(response.data)
        //setProduct([]);
        for(let item of response.data){
          var check = product_select.filter((e)=>{
            return e.product_id === item.id
          });
          if(check.length > 0){
            item.isPush = true;
          }else{
            item.isPush = false;
          }
      
        }
        console.log(response.data)

         setProduct(response.data);
         setMDBT(true);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function getSparePart_view(){
    axios({
      method: "get",
      url: Configs.API_URL + "/sparePart/findById?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log(response.data);
        setSparePart({
          spare_part_no: response.data.spare_part_no,
          spare_part_name: response.data.spare_part_name || "",
          product_ref: response.data.product_ref,
          description: response.data.description || "",
          amount:
            response.data.amount ||
            Number(response.data.toString().replace(/,/g, "")),
        });
        setProduct_select(response.data.product_ref)
        axios({
         method: "post",
         url: Configs.API_URL + "/product/filter",
         headers: {
           Authorization: getToken(),
           "X-TTT": Configs.API_TTT,
           "Content-Type": "application/json",
         },
         data: filter_product,
       })
         .then(async (response2) => {
           //console.log(response.data)
           setProduct([]);
           for(let item of response2.data){
             var check = response.data.product_ref.filter((e)=>{
               return e.product_id === item.id
             });
             if(check.length > 0){
               item.isPush = true;
             }else{
               item.isPush = false;
             }
         
           }
           //console.log(response.data)
   
            setProduct(response2.data);
          
         })
         .catch(async (error) => {
           console.log(error);
         });
  
       
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const Getmodel = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/product/getProductModel?id=" + getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        //console.log(response.data);
        setModel(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetProduct();
    Getmodel();
    if(mode.mode !== "add"){
      getSparePart_view();
    }
  }, []);

  function cancelOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/WIP_SSTInOut";
      }
    }
    );
  }

  function saveOption(params) {
    Swal.fire({
      title: "Do you want to save the changes?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        
        if(mode.mode ==='add'){
          var temp_save = {
            spare_part_no: SparePart.spare_part_no,
            spare_part_name: SparePart.spare_part_name,
            product_ref: product_select,
            description: SparePart.description,
            company_id: getUser().com,
            amount: Number(SparePart.amount),
          };

          axios({
            method: "post",
            url: Configs.API_URL + "/sparePart/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save,
          })
            .then(function (response) {
              console.log(response.data);
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Spare_Part";
              });
            })
            .catch(async (error) => {
              console.log(error);
  
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
        else{
          var temp_save = {
            spare_part_id:id,
            spare_part_no: SparePart.spare_part_no,
            spare_part_name: SparePart.spare_part_name,
            product_ref: product_select,
            description: SparePart.description,
            company_id: getUser().com,
            amount: Number(SparePart.amount),
          };
          console.log(temp_save);
          axios({
            method: "post",
            url: Configs.API_URL + "/sparePart/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save,
          })
            .then(async (response) => {
              console.log(response.data);
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Spare_Part";
              });
            })
            .catch(async (error) => {
              console.log(error);
  
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
       

        
        
      } else if (result.isDenied) {
        Swal.fire({
          icon: "warning",
          title: "Canceling",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          window.location.href =
            "/Warehouse_Management/Factory_Master_Data/Spare_Part";
        });
      }
    });
  }

 
  
  function pushProduct(e){
    const product_select_temp = product_select;
      var data = product.filter((element)=>{ 
        return (element.id === e.target.id);
      })
      const product_p = product;
      setProduct([]);
      for(let i of product_p){
        if(i.id === e.target.id){
          i.isPush = true;
        }
      }
      setProduct(product_p);
      //console.log(product_select_temp);
      var checked = product_select_temp.filter((e2)=>{
        return e2.product_id === data[0].id;
      })
      //console.log(checked)
      if(checked.length > 0){
        console.log("ซ้ำ")
      }else{
        let temp = {
          id:"",
          product_id:data[0].id,
          sparepart_id:"",
          name:data[0].name,
          no:data[0].no,
        };
        product_select_temp.push(temp);
      }
       setProduct_select([]);
       setProduct_select(product_select_temp);
       settest({...test,test:""});
       test.test = "";
    }
  
    function removeProduct(e){
      //console.log(e.target.id);
      const product_p = product;
      setProduct([]);
      for(let i of product_p){
        if(i.id === e.target.id){
          i.isPush = false;
        }
      }
      setProduct(product_p);
      const product_select_temp = product_select;
      setProduct_select([]);
      let indexOf = product_select_temp.findIndex((element)=> element.product_id ===  e.target.id) 
      product_select_temp.splice(indexOf, 1);
      setProduct_select(product_select_temp);
      settest({...test,test:""});
       test.test = "";
      //console.log(data[0]);    
    }
    
    console.log(product_select);
    const Product_data = [];
    for(var index = 0; index < product.length;index++){
      const rowItem = {};
  
      rowItem["no"] = index +1 ;
      rowItem["id"] = product[index].id;
      rowItem["product_no"] = product[index].no;
      rowItem["product_name"] = product[index].name;
      rowItem["mgt"] = (
        <>
        {product[index].isPush === true ? (
            <>
            <div className="col-12">
                    <button
                      type="button"
                      id = {product[index].id}
                      onClick={removeProduct.bind(this)}
                      class="btn btn-block btn-danger "
                    >
                      Remove
                    </button>
                  </div>
          </>
        ):(
          <>
          <div className="col-12">
          <button
            type="button"
            id = {product[index].id}
            onClick={pushProduct.bind(this)}
            class="btn btn-block btn-primary "
          >
            Push
          </button>
        </div>
        </>
        )}
       
        </>
      );
      Product_data.push(rowItem);
    }
  
    const TableProduct = {
      columns: [
        {
          label: "No",
          field: "no",
          sort: "asc",
          width: 50,
        },
        {
          label: "Product No",
          field: "product_no",
          sort: "asc",
          width: 50,
        },
        {
         label: "Product Name",
         field: "product_name",
         sort: "asc",
         width: 50,
       },
        
        {
          label: "Management",
          field: "mgt",
          sort: "asc",
          width: 50,
        },
      ],
      rows: Product_data,
    };
  
  
    const Product_data_select = [];
  
    for(var index = 0; index < product_select.length;index++){
      const rowItem = {};
  
      rowItem["no"] = index +1 ;
      rowItem["id"] = product_select[index].id;
      rowItem["product_no"] = product_select[index].no;
      rowItem["product_name"] = product_select[index].name;
      rowItem["mgt"] = (
        <>
        <div className="col-6">
                    <button
                      type="button"
                      id = {product_select[index].product_id}
                      onClick={removeProduct.bind(this)}
                      class="btn btn-block btn-danger "
                    >
                      Remove
                    </button>
                  </div>
        </>
      );
      Product_data_select.push(rowItem);
    }
   
   
    
  
    const TableProduct_select = {
      columns: [
        {
          label: "No",
          field: "no",
          sort: "asc",
          width: 50,
        },
        {
          label: "Product No",
          field: "product_no",
          sort: "asc",
          width: 50,
        },
        {
         label: "Product Name",
         field: "product_name",
         sort: "asc",
         width: 50,
       },
        
        {
          label: "Management",
          field: "mgt",
          sort: "asc",
          width: 50,
        },
      ],
      rows: Product_data_select,
    };
  
  
    const clearFilter = async () => {
       setfilter_product({
        ...filter_product,
        no: "",
        name: "",
        product_model_id: "",
        oem_id: getOem()
      }); 
      filter_product.no = "";
      filter_product.name = "";
      filter_product.product_model_id = "";
     
      GetProduct();
     
    }; 

  
    for (let i = 1; i < 10; i++) {
      const rowItem = {}
      rowItem["no"] = i;
      rowItem["oder"] = "Test oder";
      rowItem["productcount"] = "1000";
  
      rowItem["long"] = "50";
     
      rowItem["cutcount"] = "25";

      rowsData.push(rowItem);
    }
  
    const data = {
      columns: [
        {
          label: "NO.",
          field: "no",
          sort: "asc",
          width: 50,
        },
        {
          label: "Oder",
          field: "oder",
          sort: "asc",
          width: 50,
        },
        {
          label: "จำนวนการผลิต",
          field: "productcount",
          sort: "asc",
          width: 50,
        },
        {
          label: "ความยาวลวด",
          field: "long",
          sort: "asc",
          width: 50,
        },
        {
          label: "จำนวนท่อน",
          field: "cutcount",
          sort: "asc",
          width: 50,
        },
  
      ],
      rows: rowsData,
    };




  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>WIP SST{pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">WIP SST</li>
                  <li className="breadcrumb-item active">
                  WIP SST {pageMode}{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    if
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WIP SST{pageMode}</h3>
            </div>
            <div className="card-body">
              <h3 className="mt-5-head">WIP SST Detail</h3>
              <div className="tab-custom-content"></div>
 

              <div className="row">
                <div className="col-3">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={SparePart.spare_part_no}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          spare_part_no: e.target.value,
                        });
                      }}
                    />
                    <label>
                      เลขใบสั่งซื้อ
                      {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
             
                <div className="col-md-0.5">
        <label > Start Date &nbsp;</label>
        </div>
              <div className="col-md-2">
                <div className="form-group ">
                <DatePicker selected = {filler_order_product.start_date}
        dateFormat = {"dd-MM-yyyy"}
        onChange = {
          async(date) =>
          SetDate_start(date)   
          }
        selectsStart startDate = {filler_order_product.start_date}
        endDate = {filler_order_product.end_date}
        customInput = {<CustomInput/>}
        
        /> 
                </div>
              </div>
              <div className="col-md-0.5">
        <label>&nbsp;&nbsp;  Between  &nbsp;&nbsp;</label> 
        </div> 
              <div className="col-md-2">
                <div className="form-group ">
                <DatePicker 
        selected = {filler_order_product.end_date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>
          SetDate_end(date)   
          }
        selectsEnd startDate = { filler_order_product.start_date }
        endDate = { filler_order_product.end_date }
        minDate = { filler_order_product.start_date }
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
                </div>
              </div>
              <div className="col-md-0.5">
        <label>&nbsp;&nbsp;  Date  &nbsp;&nbsp;</label> 
        </div> 
              <div className="col-md-2">
                <div className="form-group ">
                <DatePicker 
        selected = {filler_order_product.end_date}
        // dateFormat = { "dd-MM-yyyy" }
        // onChange = {
        //   async(date) =>
        //   SetDate_end(date)   
        //   }
        // selectsEnd startDate = { filler_order_product.start_date }
        // endDate = { filler_order_product.end_date }
        // minDate = { filler_order_product.start_date }
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
                </div>
              </div>
              </div>
             
              <div className="row">
                <div className="col-1">
                  <div class="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    // onClick={addpage}
                  >
                    Add
                  </button>
                  </div>
                </div>


              </div>
              <div className="col-4">
                  <div class="form-group">
                  <Select
                          // options={product_model_option}
                          // defaultValue={filler_product.product_model_id}
                          // ref = {selectProduct_model}
                          // onChange={(e) => {
                          //  // console.log(e);
                          //  if(e !== null){
                          //   setFiller_product({
                          //     ...filler_product,
                          //     product_model_id: e.value,
                          //   });
                          //  }
                          
                          // }}
                        />
                         <label htmlFor="">Order</label>
                  </div>
                </div>
                <div className="row">
                <div className="col-2">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={SparePart.spare_part_no}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          spare_part_no: e.target.value,
                        });
                      }}
                    />
                    <label>
                      จำนวนการผลิต
                      {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    
                  </div>
                  
                </div>
                <div className="col-2">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={SparePart.spare_part_no}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          spare_part_no: e.target.value,
                        });
                      }}
                    />
                    <label>
                      ความยาวรวด
                      {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    
                  </div>
                  
                </div>
                <div className="col-2">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={SparePart.spare_part_no}
                      disabled={disable}
                      onChange={(e) => {
                        setSparePart({
                          ...SparePart,
                          spare_part_no: e.target.value,
                        });
                      }}
                    />
                    <label>
                      จำนวนท่อน
                      {SparePart.spare_part_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    
                  </div>
                  
                </div>
                </div>
                <div className="row">
               
                <div className="col 12">

<div

  className="table-responsive" /* style={{ height: "500px" }} */
>



  <MDBDataTable
    className="table table-head-fixed"
    table
    sortable = {false}
    /*   id="table-to-xls" */
    striped
    bordered
    hover
    fixedHeader
    data={data}
  />
</div>

<div
  className="table-responsive" style={{ display: "none" }}
>
  <MDBDataTable
  sortable = {false}
    className="table table-head-fixed"
    table
    id="table-to-xls"
    entries={4096}
    striped
    bordered
    hover
    fixedHeader
    data={data}
  />
</div>

</div>
       

             

                  </div>

       
            
           
              </div>
              
              </div>
        </section>

        <div className="modal fade" id="modal-product">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Product</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={filter_product.no}
                            disabled={mode.mode === "read" ? true : false}
                            onChange={(e) =>
                              setfilter_product({
                                ...filter_product,
                                no: e.target.value,
                              })
                            }
                            required
                          />
                          <label htmlFor="">Product No</label>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            value={filter_product.name}
                            disabled={mode.mode === "read" ? true : false}
                            onChange={(e) =>
                              setfilter_product({
                                ...filter_product,
                                name: e.target.value,
                              })
                            }
                            required
                          />
                          <label htmlFor="">Product Name</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <br />
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={mode.mode === "read" ? true : false}
                          //onClick={fillterdata}
                        >
                          Search
                        </button>
                      </div>
                      <div className="col-3">
                        <br />
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={mode.mode === "read" ? true : false}
                        /*   onClick={() => {
                            clear_fillter();
                          }} */
                        >
                          Clear
                        </button>
                      </div>
                      {/* {feature.map((e) => {
                        if (e.feature === "Import Spare Part") {
                          return (
                            <div className="col-3">
                              <br />
                              <button
                                type="button"
                                class="btn btn-block btn-info "
                                disabled={mode.mode === "read" ? true : false}
                                data-toggle="modal"
                                data-target="#modal-import"
                                // onClick={testassy}
                              >
                                Import Spare Part
                              </button>
                            </div>
                          );
                        }
                      })} */}
                    </div>
                    <br />
                    <div
                      className="table-responsive"
                      style={{ height: "300px" }}
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        sortable = {false}
                        striped
                        bordered
                        hover
                        fixedHeader
                        //data={data}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h3></h3>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WIP_SST_adds;
