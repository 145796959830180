import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import GridTable from "@nadavshaar/react-grid-table";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";

import axios from "axios";
import sortJsonArray from "sort-json-array";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";

function Production_Line(params) {
  const [productionLine, setProductionLine] = useState([]);
  const [product_data, setProduct_data] = useState([]);
  const [lines, setLines] = useState([]);
  const [process, setProcess] = useState([]);
  const [section, setsection] = useState([]);
  const [filler_section, setFiller_section] = useState({
    id: "",
    section_name: "",
    oem_id: getOem(),
  });
  function GetLines(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/productionLine/getLines",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setLines(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function GetProcess(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/productionLine/getProcess",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setProcess(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  
  const GetSection = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/section/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_section,
    })
      .then(function (response) {
        console.log(response.data);
        setsection(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function GetProductionLine(params) {
    axios({
      method: "get",
      url:
        Configs.API_URL + "/productionLine/productionLineByOEM/?id=" + getOem(),
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        //console.log("test");
        //console.log(response.data);
        setProductionLine(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const GetProduct = async () => {
    const filler_product = {
      no: "",
      name: "",
      product_model_id: "",
      oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product,
    })
      .then(async (response) => {
        let test = sortJsonArray(response.data, "no", "asc");
        await setProduct_data(test);
        //console.log(test);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetProductionLine();
    GetProduct();
    GetLines();
    GetProcess();
    GetSection();
  }, []);

  const temp_productLine = [];

  /*   for (let i = 0; i < productionLine.length; i++) {
    const element = {};

    for (let j = 0; j < product_data.length; j++) {
    
      
    }


    
  } */

  for (let index = 0; index < product_data.length; index++) {
    const element = {};
    const element2 = [];

    element["product"] = product_data[index];

    for (let iLine = 0; iLine < lines.length; iLine++) {
      const element4 = {};
      const element3 = [];

      element4["line"] = lines[iLine].line_name;
      for (let indexIn = 0; indexIn < productionLine.length; indexIn++) {
        if (
          product_data[index].id === productionLine[indexIn].product_id &&
          productionLine[indexIn].line_id === lines[iLine].id
        ) {
          element3.push(productionLine[indexIn]);
        }
      }
      if (element3.length !== 0) {
        
        const process_temp2 = [];
        let total = 0;
        for (let line_detail = 0; line_detail < process.length; line_detail++) {
          const process_temp = {};
         process_temp["process_name"] = process[line_detail].process_name;
         process_temp["process_cycle"] =0;
             process_temp2.push(process_temp)    
  
     }
     
     for (let process_i = 0; process_i < element3.length; process_i++) {
      let temp_total =0;
        for (let x = 0; x < process_temp2.length; x++) {
      
          if (process_temp2[x].process_name===element3[process_i].process_name) {
            process_temp2[x].process_cycle =element3[process_i].cycle_time;
            total = total +element3[process_i].cycle_time;
           }
        }
     
         
         
         
        }
           

        element4["line_detail"] = process_temp2;
        element4["Total"] = total;
        element2.push(element4);
      }
    }

    if (element2.length !== 0) {
      element["productLine"] = element2;
    }else{
      element["productLine"] = [];
    }
    temp_productLine.push(element);
  }

  function Test(params) {
    //console.log(temp_productLine);
  }

  const data = [];
  for (let index = 0; index < 50; index++) {
    const element = {};
    element["item"] = index + 1;
    element["Part_no"] = index + 1;
    element["Lines"] = "Line_" + index + 1;
    element["Part_name"] = index + 1;
    element["Job_WIP"] = index + 1;
    element["Cutting"] = index + 1;
    element["Cut_In"] = index + 1;
    element["Cut_Out"] = index + 1;
    element["Pre_forming"] = index + 1;
    element["Drill"] = index + 1;
    element["Finish_side_A"] = index + 1;
    element["Finish_side_B"] = index + 1;
    element["Thread_Rolling"] = index + 1;
    element["Facing"] = index + 1;
    element["Milling"] = index + 1;
    element["Grinding"] = index + 1;
    element["Thread_Tapping"] = index + 1;
    element["Finishing"] = index + 1;
    element["BQnT"] = index + 1;
    element["Heat_Treatment"] = index + 1;
    element["welding"] = index + 1;
    element["Normalizing"] = index + 1;
    element["Induction"] = index + 1;
    element["Zinc_Plating"] = index + 1;
    element["QC"] = index + 1;
    element["QA"] = index + 1;
    element["packing"] = index + 1;
    element["Store_FG"] = index + 1;
    element["Total"] = index + 1;
    data.push(element);
  }
  const Username = ({
    tableManager,
    value,
    field,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div
        className="rgt-cell-inner"
        style={{ display: "flex", alignItems: "center", overflow: "hidden" }}
      >
        <img src={data.avatar} alt="user avatar" />
        <span className="rgt-text-truncate" style={{ marginLeft: 10 }}>
          {value}
        </span>
      </div>
    );
  };

  let rows = [
    {
      id: 1,
      username: "wotham0",
      gender: "Male",
      last_visited: "12/08/2019",
      test: { x: 1, y: 2 },
      avatar:
        "https://robohash.org/atquenihillaboriosam.bmp?size=32x32&set=set1",
    },
    {
      id: 2,
      username: "dbraddon2",
      gender: "Female",
      last_visited: "16/07/2018",
      test: { x: 3, y: 4 },
      avatar: "https://robohash.org/etsedex.bmp?size=32x32&set=set1",
    },
    {
      id: 3,
      username: "dridett3",
      gender: "Male",
      last_visited: "20/11/2016",
      test: { x: 5, y: 8 },
      avatar: "https://robohash.org/inimpeditquam.bmp?size=32x32&set=set1",
    },
    {
      id: 4,
      username: "gdefty6",
      gender: "Female",
      last_visited: "03/08/2019",
      test: { x: 7, y: 4 },
      avatar: "https://robohash.org/nobisducimussaepe.bmp?size=32x32&set=set1",
    },
    {
      id: 5,
      username: "hbeyer9",
      gender: "Male",
      last_visited: "10/10/2016",
      test: { x: 2, y: 2 },
      avatar: "https://robohash.org/etconsequatureaque.jpg?size=32x32&set=set1",
    },
  ];

  const columns = [
    {
      id: 1,
      field: "username",
      label: "Username",
      cellRenderer: Username,
    },
    {
      id: 2,
      field: "gender",
      label: "Gender",
    },
    {
      id: 3,
      field: "last_visited",
      label: "Last Visited",
      sort: ({ a, b, isAscending }) => {
        let aa = a.split("/").reverse().join(),
          bb = b.split("/").reverse().join();
        return aa < bb
          ? isAscending
            ? -1
            : 1
          : aa > bb
          ? isAscending
            ? 1
            : -1
          : 0;
      },
    },
    {
      id: 4,
      field: "test",
      label: "Score",
      getValue: ({ value, column }) => value.x + value.y,
    },
  ];
  console.log(section)
  console.log(process)
  return (
    <div className="wrapper">
    {/*   <Header />
      <Sidebar menu="warehouse" activemenu="pro_line" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Production Line</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Production Line</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title" onClick={Test}>
                Production Line
              </h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              {/* <div
                className="table-responsive table-bordered"
                style={{ width: "100%"}}
              > */}
                <div
                      className="table-responsive table-bordered" 
                      style={{ height: "500px" }}
                    >
                <table>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center"}} colSpan="3">
                        Production Line
                      </th>
                      <th rowSpan="3" style={{ width: "10px" }}>
                        Lines
                      </th>
                      <th style={{ width: "60px" ,textAlign:"center" }}>Process</th>
                      {process.map((el, i) => {
                      return (
                        <th style={{ width: "60px" ,textAlign:"center"  }}>{i+1}</th>
                      )})} 
                      {/* <th style={{ width: "60px" ,textAlign:"center"  }}>1</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>2</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>3</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>4</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>5</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>7</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>6</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>8</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>9</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>10</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>11</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>12</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>13</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>14</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>15</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>16</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>17</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>18</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>19</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>20</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>21</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>22</th>
                      <th style={{ width: "60px" ,textAlign:"center"  }}>23</th> */}
                      <th rowSpan="3">Total_time_in_Second_per_piece </th>
                    </tr>
                    <tr>
                      <th style={{ textAlign: "center" }} colSpan="3">
                        LOGO
                      </th>
                      {/* <th style={{ width: "10px" }}></th> */}
                      <th style={{ width: "10px" }}>Section</th>
                    {/*   {section.map((el) => {
                              return (
                                <th
                                colSpan={3}
                                style={{ width: "600px", textAlign: "center" }}
                              >
                              {el.section_name}
                              </th>
                              );
                            })} */}
                      <th
                        colSpan="6"
                        style={{ width: "180px", textAlign: "center" }}
                      >
                        Store Raw Material
                      </th>
                      <th
                        colSpan="13"
                        style={{ width: "600px", textAlign: "center" }}
                      >
                        Production Process
                      </th>
                      <th
                        colSpan="20"
                        style={{ width: "380px", textAlign: "center" }}
                      >
                        Out sources{" "}
                      </th>
                      <th
                        colSpan="1"
                        style={{ width: "250px", textAlign: "center" }}
                      >
                        Final Inspections
                      </th>
                      {/*    <th rowSpan="3"> </th> */}
                    </tr>

                    <tr>
                      <th
                        style={{
                          textAlign: "center",
                          backgroundColor: "",
                          width: "25px",
                        }}
                      >
                        Item
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          backgroundColor: "",
                          width: "50px",
                        }}
                      >
                        Part_no
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          backgroundColor: "",
                          width: "80px",
                        }}
                      >
                        Part_name
                      </th>

                       <th rawSpan="3" style={{ width: "10px" }}>Process</th> 
                      {process.map((el, i) => {
                      return (
                        <th style={{ width: "60px" ,textAlign:"center"  }}>{el.process_name}</th>
                      )})}
                      {/* <th style={{ width: "60px" }}>Job/WIP</th>
                      <th style={{ width: "60px" }}>Cutting</th>
                      <th style={{ width: "60px" }}>Cut_In</th>
                      <th style={{ width: "60px" }}>Cut_Out</th>
                      <th style={{ width: "60px" }}>Pre_forming</th>
                      <th style={{ width: "60px" }}>Drill</th>
                      <th style={{ width: "60px" }}>Finish_side_A</th>
                      <th style={{ width: "60px" }}>Finish_side_B</th>
                      <th style={{ width: "60px" }}>Thread_Rolling</th>
                      <th style={{ width: "60px" }}>Facing</th>
                      <th style={{ width: "60px" }}>Milling</th>
                      <th style={{ width: "60px" }}>Grinding</th>
                      <th style={{ width: "60px" }}>Thread_Tapping</th>
                      <th style={{ width: "60px" }}>Finishing</th>
                      <th style={{ width: "60px" }}>{"BQ&T"}</th>
                      <th style={{ width: "60px" }}>Heat_Treatment</th>
                      <th style={{ width: "60px" }}>welding</th>
                      <th style={{ width: "60px" }}>Normalizing</th>
                      <th style={{ width: "60px" }}>Induction</th>
                      <th style={{ width: "60px" }}>Zinc_Plating </th>
                      <th style={{ width: "60px" }}>QC</th>
                      <th style={{ width: "60px" }}>QA</th>
                      <th style={{ width: "60px" }}>packing </th>
                      <th style={{ width: "60px" }}>Store_FG</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {temp_productLine.length!==0? temp_productLine.map((el, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{el.product.no}</td>
                          <td>{el.product.name}</td>
                     
                      {el.productLine !==0 ?el.productLine.map((el2, i) => {
                              return(<>
                               <td key={i}>{el2.line}</td>
                               <td></td>
                              {el2.line_detail.map((el3,index)=>{
                                  return(
                                    <td style={{textAlign:"center"}} key={index}>
                                      {el3.process_cycle}
                                    </td>
                                  )
                              })
                              
                              }
                               <td style={{ width: "60px" , textAlign:"center"}}>{el2.Total}</td>
                              
                                    </>
                              )
                          }):<td >{"none"}</td>
                        }
                        </tr>
                      );
                    }):<tr><td></td></tr>}
                    {/*  {data.map((el) => {
                      return (
                        <tr>
                          <td>{el.item}</td>
                          <td>{el.Part_no}</td>
                          <td>{el.Part_name}</td>
                          <td>{el.Lines}</td>
                          <td>{el.Job_WIP}</td>
                          <td>{el.Cutting}</td>
                          <td>{el.Cut_In}</td>
                          <td>{el.Cut_Out}</td>
                          <td>{el.Pre_forming}</td>
                          <td>{el.Drill}</td>
                          <td>{el.Finish_side_A}</td>
                          <td>{el.Finish_side_B}</td>
                          <td>{el.Thread_Rolling}</td>
                          <td>{el.Facing}</td>
                          <td>{el.Milling}</td>
                          <td>{el.Grinding}</td>
                          <td>{el.Thread_Tapping}</td>
                          <td>{el.Finishing}</td>
                          <td>{el.BQnT}</td>
                          <td>{el.Heat_Treatment}</td>
                          <td>{el.welding}</td>
                          <td>{el.Normalizing}</td>
                          <td>{el.Induction}</td>
                          <td>{el.Zinc_Plating}</td>
                          <td>{el.QC}</td>
                          <td>{el.QA}</td>
                          <td>{el.packing}</td>
                          <td>{el.Store_FG}</td>
                          <td>{el.Total}</td>
                        </tr>
                      );
                    })} */}
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
            </div>

            <div className="card-footer"></div>
          </div>
        </section>
      </div>
{/* <Footter/> */}
</div>
      
  );
}
export default Production_Line;
