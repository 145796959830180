import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import QRCode from "qrcode.react";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import "../../assets/css/table.css";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature } from "../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";


function Factory_parameter() {
  const [feature, setfeature] = useState([]);
  const [model_option, setmodel_option] = useState([]);
  const [tooling_ratio, setTooling_ration] = useState({
    ratio: '',
    id: ''
  });
  const select_modelop = useRef();
  const [model, setModel] = useState([]);
  const [section, setsection] = useState([]);
  const [filler_section, setFiller_section] = useState({
    id: "",
    section_name: "",
    oem_id: getOem(),
  });
  //console.log(section);
  const [process, setprocess] = useState([]);
  const [filler_process, setFiller_process] = useState({
    oem_id: getOem(),
    section_id: "",
    section_name: "",
    process_name: "",
  });

  const [line, setline] = useState([]);
  const [filler_line, setFiller_line] = useState({
    oem_id: getOem(),
    id: "",
    line_name: "",
  });
  const [machine, setmachine] = useState([]);
  const [filler_machine, setFiller_machine] = useState({
    oem_id: getOem(),
    id: "",
    machine_name: "",
  });

  const [machine_process, setmachine_process] = useState([]);

  const [line_machine, setline_machine] = useState([]);

  const [oem_id, setOem_id] = useState(getOem());
  const { target_u } = useParams();

  const saveToolingRatio = async () => {
    tooling_ratio.ratio = Number(String(tooling_ratio.ratio).replace(/,/g, "")) || 0;
    const temp = {
      tooling: tooling_ratio || 0,
      emp_oem_id: getOem(),
      emp_fup: getUser().fup,
      emp_company_id: getUser().com,
    }
    Swal.fire({
      title: "ต้องการที่จะอัพเดท Ratio ใช่หรือไม่",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: Configs.API_URL + "/factoryParameter/tooling/updateRatio",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                if (tooling_ratio.ratio === 0 || tooling_ratio.ratio === '') {
                  tooling_ratio.ratio = 0;
                  setTooling_ration({ ratio: 0 })
                }
              })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    })

  }

  const GetToolingRatio = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        `/factoryParameter/tooling/getRatio`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (response.data.length > 0) {
          setTooling_ration({ ratio: response.data[0].value, id: response.data[0].id });
          tooling_ratio.ratio = response.data[0].value;
          tooling_ratio.id = response.data[0].id;
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const GetSection = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/section/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_section,
    })
      .then(function (response) {
        console.log(response.data);
        setsection(response.data);
        const options = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.section_name;
          options.push(element);
        });
        setmodel_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetProcess = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/process/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_process,
    })
      .then(function (response) {
        console.log(response.data);
        setprocess(response.data);

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetLine = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/line/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_line,
    })
      .then(function (response) {
        //console.log(response.data);
        setline(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetMachine = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/machine/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_machine,
    })
      .then(function (response) {
        //console.log(response.data);
        setmachine(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetMachineProcess = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/machine_process/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_machine,
    })
      .then(function (response) {
        //console.log(response.data);
        setmachine_process(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetLineMachine = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/line_machine/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_line,
    })
      .then(function (response) {
        //console.log(response.data);
        setline_machine(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  useEffect(() => {
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Factory Parameter";
    });
    setfeature(feature_data);
    ////////permission feature//////////
    GetSection();
    GetProcess();
    GetMachine();
    GetLineMachine();
    GetLine();
    GetToolingRatio();
    GetMachineProcess();

  }, []);

  const clearFilter = async () => {
    await setFiller_section({
      ...filler_section,
      section_name: "",
      id: "",
    });

    filler_section.section_name = "";
    GetSection();
    await setFiller_process({
      ...filler_process,
      section_name: "",
      process_name: "",
      section_id: "",
    });
    filler_process.section_name = "";
    filler_process.section_id = "";
    filler_process.process_name = "";
    GetProcess();
    await setFiller_line({
      ...filler_line,
      line_name: "",
      id: "",
    });

    filler_line.line_name = "";
    GetLine();

    await setFiller_machine({
      ...filler_machine,
      machine_name: "",
      id: "",
    });

    filler_machine.machine_name = "";
    GetMachine();
  };

  const sectionrowsData = [];

  for (var index = 0; index < section.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    /*   rowItem["id"] = section[index].id; */
    rowItem["section"] = section[index].section_name;
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/section/read/" +
              section[index].id
            }
            key={section[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/section/edit/" +
              section[index].id
            }
            key={section[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={section[index].id}
            className=" btn btn-xs "
            onClick={del_section.bind(this, section[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    sectionrowsData.push(rowItem);
  }

  const sectiondata = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      /*   {
          label: "ID",
          field: "id",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Section",
        field: "section",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: sectionrowsData,
  };

  const processrowsData = [];

  for (var index = 0; index < process.length; index++) {
    const rowItem_process = {};
    rowItem_process["no"] = index + 1;
    rowItem_process["section_name"] = process[index].section_name;
    rowItem_process["process_name"] = process[index].process_name;
    rowItem_process["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/process/read/" +
              process[index].id
            }
            key={process[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/process/edit/" +
              process[index].id
            }
            key={process[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={process[index].id}
            className=" btn btn-xs "
            onClick={del_process.bind(this, process[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    processrowsData.push(rowItem_process);
  }
  const processdata = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Section",
        field: "section_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Process",
        field: "process_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: processrowsData,
  };

  const linerowsData = [];
  for (var index = 0; index < line.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["line"] = line[index].line_name;

    let line_machine_temp = line_machine.filter((e) => {

      if (e.id === line[index].id) {
        return e.machine_name;
      }
    });

    if (line_machine_temp.length > 0) {
      var machine_n = "";
      for (var i = 0; i < line_machine_temp.length; i++) {

        machine_n += '\r\n' + line_machine_temp[i].machine_name;
        if (i < line_machine_temp.length) {
          machine_n += ", ";
        }
      }
      rowItem["machine"] = machine_n;
    } else {
      rowItem["machine"] = "";
    }


    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/line/read/" +
              line[index].id
            }
            key={line[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/line/edit/" +
              line[index].id
            }
            key={line[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={line[index].id}
            className=" btn btn-xs "
            onClick={del_line.bind(this, line[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    linerowsData.push(rowItem);
  }
  const linedata = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line",
        field: "line",
        sort: "asc",
        width: 50,
      },
      {
        label: "Machine",
        field: "machine",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: linerowsData,
  };

  const machinerowsData = [];

  for (var index = 0; index < machine.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["machine"] = machine[index].machine_name;
    let machine_process_temp = machine_process.filter((element) => {
      if (element.id === machine[index].id) {
        return element.process_name;
      }
    });

    // console.log(machine_process_temp);
    if (machine_process_temp.length > 0) {
      var process_n = "";
      for (var i = 0; i < machine_process_temp.length; i++) {
        //console.log(machine_process_temp[i].process_name);
        process_n += machine_process_temp[i].process_name;
        if (i < machine_process_temp.length) {
          process_n += ", ";
        }
      }
      rowItem["process"] = process_n;
    } else {
      rowItem["process"] = "";
    }
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/machine/read/" +
              machine[index].id
            }
            key={machine[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/machine/edit/" +
              machine[index].id
            }
            key={machine[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={machine[index].id}
            className=" btn btn-xs "
            onClick={del_machine.bind(this, machine[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    machinerowsData.push(rowItem);
  }
  const machinedata = {
    columns: [
      {
        label: "No_",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Machine",
        field: "machine",
        sort: "asc",
        width: 50,
      },
      {
        label: "Process",
        field: "process",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: machinerowsData,
  };

  const option_section = [];
  for (var index = 0; index < section.length; index++) {
    var sec_id = section[index].id;
    var sec_name = section[index].section_name;

    option_section.push(<option value={sec_id}>{sec_name}</option>);
  }

  function addpage_section() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Factory_parameter/add_section";
  }

  function addpage_process() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Factory_parameter/add_process";
  }
  function addpage_line() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Factory_parameter/add_line";
  }
  function addpage_machine() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Factory_parameter/add_machine";
  }
  function del_section(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/factoryParameter/section/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/section";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  function del_process(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/factoryParameter/process/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/process";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  function del_line(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/factoryParameter/line/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/line";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  function del_machine(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/factoryParameter/machine/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Factory_Parameter/status/machine";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  if (target_u === "section") {
    var class_active_section = "tab-pane fade show active";
    var class_active_process = "tab-pane fade";
    var class_active_line = "tab-pane fade";
    var class_active_machine = "tab-pane fade";
    var class_active_ratio = "tab-pane fade";
    var nav_active_section = "nav-link active";
    var nav_active_process = "nav-link";
    var nav_active_line = "nav-link";
    var nav_active_machine = "nav-link";
    var nav_active_ratio = "nav-link";
  } else if (target_u === "process") {
    var class_active_section = "tab-pane fade";
    var class_active_process = "tab-pane fade show active";
    var class_active_line = "tab-pane fade";
    var class_active_machine = "tab-pane fade";
    var class_active_ratio = "tab-pane fade";
    var nav_active_section = "nav-link";
    var nav_active_process = "nav-link active";
    var nav_active_line = "nav-link";
    var nav_active_machine = "nav-link";
    var nav_active_ratio = "nav-link";
  } else if (target_u === "line") {
    var class_active_section = "tab-pane fade";
    var class_active_process = "tab-pane fade";
    var class_active_line = "tab-pane fade show active";
    var class_active_machine = "tab-pane fade";
    var class_active_ratio = "tab-pane fade";
    var nav_active_section = "nav-link";
    var nav_active_process = "nav-link";
    var nav_active_line = "nav-link active";
    var nav_active_machine = "nav-link";
    var nav_active_ratio = "nav-link";
  } else if (target_u === "machine") {
    var class_active_section = "tab-pane fade";
    var class_active_process = "tab-pane fade";
    var class_active_line = "tab-pane fade";
    var class_active_machine = "tab-pane fade show active";
    var class_active_ratio = "tab-pane fade";
    var nav_active_section = "nav-link";
    var nav_active_process = "nav-link";
    var nav_active_line = "nav-link";
    var nav_active_machine = "nav-link active";
    var nav_active_ratio = "nav-link";
  } else if (target_u === "ratio") {
    var class_active_section = "tab-pane fade";
    var class_active_process = "tab-pane fade";
    var class_active_line = "tab-pane fade";
    var class_active_machine = "tab-pane fade";
    var class_active_ratio = "tab-pane fade show active";
    var nav_active_section = "nav-link";
    var nav_active_process = "nav-link";
    var nav_active_line = "nav-link";
    var nav_active_machine = "nav-link";
    var nav_active_ratio = "nav-link active";
  } else {
    var class_active_section = "tab-pane fade show active";
    var class_active_process = "tab-pane fade";
    var class_active_line = "tab-pane fade";
    var class_active_machine = "tab-pane fade";
    var class_active_ratio = "tab-pane fade";
    var nav_active_section = "nav-link active";
    var nav_active_process = "nav-link";
    var nav_active_line = "nav-link";
    var nav_active_machine = "nav-link";
    var nav_active_ratio = "nav-link";
  }

  return (
    <div className="wrapper">
      {/*  <Header />
      <Sidebar menu="warehouse" activemenu="facpara" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={nav_active_section}
                    id="custom-tabs-four-section-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-section"
                    role="tab"
                    aria-controls="custom-tabs-four-section"
                    aria-selected="false"
                  >
                    Section
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={nav_active_process}
                    id="custom-tabs-four-process-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-process"
                    role="tab"
                    aria-controls="custom-tabs-four-process"
                    aria-selected="false"
                  >
                    Process
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={nav_active_line}
                    id="custom-tabs-four-line-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-line"
                    role="tab"
                    aria-controls="custom-tabs-four-line"
                    aria-selected="false"
                  >
                    Line
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={nav_active_machine}
                    id="custom-tabs-four-machine-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-machine"
                    role="tab"
                    aria-controls="custom-tabs-four-machine"
                    aria-selected="true"
                  >
                    Machine
                  </a>
                </li>
                {feature.map((e) => {
                  if (e.feature === "tooling_params") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={nav_active_ratio}
                            id="custom-tabs-four-ratio-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-ratio"
                            role="tab"
                            aria-controls="custom-tabs-four-ratio"
                            aria-selected="true"
                          >
                            {e.feature_name}
                          </a>
                        </li>
                      </>
                    );
                  }
                })}

              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className={class_active_section}
                  id="custom-tabs-four-section"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-section-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Factory Parameter &gt; Section
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_section.section_name.trim()}
                            onChange={(e) => {
                              setFiller_section({
                                ...filler_section,
                                section_name: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Section</label>{" "}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              GetSection();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-custom-content"></div>
                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addpage_section}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={sectiondata}
                    />
                  </div>
                  {/*   <div className="tab-custom-content"></div>
                  <div className="row">

                  </div> */}
                </div>
                <div
                  className={class_active_process}
                  id="custom-tabs-four-process"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-process-tab"
                >
                  <h3 class="title ng-binding">
                    Factory Parameter &gt; Process
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-4" style={{ "z-index": "98" }}>
                        <div className="form-group ">

                          <Select
                            options={model_option}
                            ref={select_modelop}
                            defaultValue={filler_process.section_id}
                            onChange={(e) => {
                              //console.log(e);
                              if (e !== null) {
                                setFiller_process({
                                  ...filler_process,
                                  section_id: e.value || "",
                                });
                              }

                            }}
                          />


                          {/* <select
                            className="custom-select select2 form-control"
                            value={filler_process.section_id}
                            onChange={(e) => {
                              setFiller_process({
                                ...filler_process,
                                section_id: e.target.value,
                              });
                            }}
                          >
                            <option value="" disabled selected>
                              Select Model
                            </option>
                            {section.map((el) => {
                              return (
                                <option value={el.id}>{el.section_name}</option>
                              );
                            })}
                          </select> */}

                          <label htmlFor="">Section </label>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_process.process_name.trim()}
                            onChange={(e) => {
                              setFiller_process({
                                ...filler_process,
                                process_name: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Process</label>{" "}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              GetProcess();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4"></div>
                  </div>
                  <div className="tab-custom-content"></div>
                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addpage_process}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={processdata}
                    />
                  </div>
                </div>
                <div
                  className={class_active_line}
                  id="custom-tabs-four-line"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-line-tab"
                >
                  <h3 class="title ng-binding">Factory Parameter &gt; Line</h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_line.line_name.trim()}
                            onChange={(e) => {
                              setFiller_line({
                                ...filler_line,
                                line_name: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Line</label>{" "}
                        </div>
                      </div>

                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_machine.machine_name.trim()}
                            onChange={(e) => {
                              setFiller_machine({
                                ...filler_machine,
                                machine_name: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Machine</label>{" "}
                        </div>
                      </div>

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              GetLine();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              clearFilter();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4"></div>
                  </div>
                  <div className="tab-custom-content"></div>
                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addpage_line}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={linedata}
                    />
                  </div>
                </div>
                <div
                  className={class_active_machine}
                  id="custom-tabs-four-machine"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-machine-tab"
                >
                  <h3 class="title ng-binding">
                    Factory Parameter &gt; Machine
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_machine.machine_name.trim()}
                            onChange={(e) => {
                              setFiller_machine({
                                ...filler_machine,
                                machine_name: e.target.value.trim(),
                              });
                            }}
                          />
                          <label htmlFor="">Machine</label>{" "}
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              GetMachine();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              clearFilter();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4"></div>
                  </div>
                  <div className="tab-custom-content"></div>
                  <div className="col-2">
                    <div className="form-group ">
                      <button
                        type="button"
                        className="btn btn-block btn-primary"
                        onClick={addpage_machine}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={machinedata}
                    />
                  </div>
                </div>
                {feature.map((e) => {
                  if (e.feature === "tooling_params") {
                    return (
                      <>
                        <div
                          className={class_active_ratio}
                          id="custom-tabs-four-ratio"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-four-ratio-tab"
                        >
                          {/*  <h3 class="title ng-binding">
                    Factory Parameter &gt; Ratio
                  </h3>
                  <div className="container-fluid">
                    <div className="col-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          className="btn btn-block btn-success"
                          onClick={addpage_machine}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div> */}
                          <section className="">
                            <div className="container-fluid">
                              <div className="row mb-2">
                                <div className="col-sm-6">
                                  <h1 style={{ fontWeight: '900' }}>
                                    Tooling Ratio{"  "}
                                    {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </section>
                          <div className="tab-custom-content">


                            <div className="col-6 col-md-3 col-xl-3">
                              <div className="form-group ">
                                <button
                                  type="button"
                                  className="btn btn-block btn-success"
                                  onClick={saveToolingRatio}
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="" /* style={{ display: 'flex', gap: '10px', alignItems: 'center' }} */>
                            <div className="" style={{
                              display: 'flex',
                              columnGap: '10px',
                              flexWrap: 'wrap',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}>
                              <p style={{ fontSize: '1.3rem', whiteSpace: 'nowrap', /*'end', width: '100%'  */ }}>อัตราส่วนสำรองเครื่องมือ (Ratio - Month): </p>
                              <div className="form-group " style={{ /* width: '10em', textAlign: 'end' */ }}>
                                <NumberFormat
                                  className="form-control"
                                  required
                                  allowNegative={false}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                  value={tooling_ratio.ratio}
                                  onChange={(e) =>
                                    setTooling_ration({
                                      ...tooling_ratio,
                                      ratio: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="">

                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }
                })}

              </div>
            </div>
          </div>
        </section>
      </div>
      {/*  <Footter /> */}
    </div>
  );
}
export default Factory_parameter;
