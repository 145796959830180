import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import sortJsonArray from "sort-json-array";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import { getOem, getToken, getUser, getFeature,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import QRCode from "qrcode.react";
import moment from "moment";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import INF from "../../component/input_feature";
import LOGH from "../../component/log_detailHistory_feature";
import { NUMBER_BINARY_OPERATORS } from "@babel/types";
import INDF from "../../component/input_date_feature";
import Select from "react-select";
function RawMatInOut(params) {
  const [materail, setmaterail] = useState([]);
  const [mat_type_option, setmat_type_option] = useState([]);
  const [mat_spec_option, setmat_spec_option] = useState([]);
  const [mat_size_option, setmat_size_option] = useState([]);
  const [mat_supp_option, setmat_supp_option] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [type, setType] = useState([]);
  const [size, setSize] = useState([]);
  const [spect, setSpect] = useState([]);
  const [oem_select, setoem_select] = useState({
    oem_id:getOem(),
  });
  const [history, sethistory] = useState({
    id: "",
    material_id: "",
    edit_type: "",
    amount: "",
    create_date: "",
    mat_lot_no: "",
  });
  const [feature, setfeature] = useState([]);
  const [history_Select, sethistory_Select] = useState({
    isSelect_status: false,
  });
  const [historyMAT, setHistoryMAT] = useState([]);
  const [amount, setamount] = useState({
    id: "",
    edit_type: true,
    amount: "",
    total: "",
    lot_no: "",
    buy_price: "",
    exprire_date: "",
  });
  const [calender_data, setcalender_data] = useState({
    exp: "",
    now_date: new Date(),
  });
  const [isEXP, setisEXP] = useState(false);
  const [fillter, setfillter] = useState({
    no: "",
    name: "",
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    material_supplier_id: "",
    material_unit_id: "",
    minimum_lot: null,
    oem_id: getOem(), 
    company_id: getUser().com,
    companyGroup_id: "",
    with_oem:true,
  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });
  const [companyGroup, setCompanyGroup] = useState([]);
/*   const [companyGroup, setCompanyGroup] = useState([{ label: 'All Company', value: '' },
  { label: 'บจก.สตูลค้าเหล็ก', value: 'SST' },
  { label: 'บจก.เอสทีสตีล(2001)', value: 'STS' },
  { label: 'บจก.เอสทีซีวิล(2001)', value: 'STcivil' },
  { label: 'บจก.เอสทีสตีล(2001) ปัตตานี', value: 'STP' },
  { label: 'บจก.เอสที คอนกรีตโปรดักส์', value: 'STC' }]); */

/*   element["value"] = el.id;
  element["label"] = el.name; */
  var config_type = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialType?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  var config_spec = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSpec?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_size = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSize?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  useEffect(() => {
    
    ////////permission feature//////////
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Raw Mat IN/OUT";
    });
    setfeature(feature_data);
    ////////permission feature//////////

    feature_data.map((e) => {
      if (e.feature === "MAT EXP") {
        setcalender_data({ ...calender_data, exp: new Date() });
        setisEXP(true);
      }
      if (e.feature === "SHIMIZU_Filter") {
        setfillter({ ...fillter, with_oem: false });
        fillter.with_oem = false;
      }
    });

    axios(config_getOem)
      .then(function (response) {
       
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
       
        //console.log(options);
        setCompanyGroup(options);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_sub)
      .then(function (response) {
        setSupplier(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });

        
        //console.log(options);
        setmat_supp_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_type)
      .then(function (response) {
        setType(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setmat_type_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_spec)
      .then(function (response) {
        setSpect(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setmat_spec_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_size)
      .then(function (response) {
        setSize(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setmat_size_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
    Getdata();


 /*    const  groupComtemp = [
        { label: 'All Company', value: '' },
        { label: 'บจก.สตูลค้าเหล็ก', value: 'SST' },
        { label: 'บจก.เอสทีสตีล(2001)', value: 'STS' },
        { label: 'บจก.เอสทีซีวิล(2001)', value: 'STcivil' },
        { label: 'บจก.เอสทีสตีล(2001) ปัตตานี', value: 'STP' },
        { label: 'บจก.เอสที คอนกรีตโปรดักส์', value: 'STC' }
      ];

      const options_com = [];

      groupComtemp.forEach((el) => {
        var element = {};
        element["value"] = el.value;
        element["label"] = el.label;
        options_com.push(element);
      });

      console.log(groupComtemp);
      console.log(options_com); 
    setCompanyGroup(options_com);*/
  }, []);

  // const Get_history = async (id) => {
  //   await axios({
  //     method: "get",
  //     url: Configs.API_URL + "/material/gethistory?id=" + id,
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: history,
  //   })
  //   .then(async (response) => {
  //     console.log(response.data);
  //   })
  //   .catch(async (error) => {
  //     console.log(error);
  //   });
  // };

  const Getdata = async () => {
   // console.log(fillter.oem_id);
  /*   if(fillter.oem_id === "All_OEM"){
      fillter.with_oem = false;
    }else{
      fillter.with_oem = true;
    } */
    await axios({
      method: "post",
      url: Configs.API_URL + "/material/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: fillter,
    })
      .then(async (response) => {
        /*   await setmaterail(response.data); */

        let sorted = response.data.sort((a, b) => b.amount - a.amount);
        await setmaterail(sorted);

        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  const selectMat_type = useRef();
  const selectMat_spec = useRef();
  const selectMat_size = useRef();
  const selectMat_supp = useRef();
  const selectCompanyGroup = useRef(); 

  const clearfillter = async () => {
    await setfillter({
      ...fillter,
      product_model_id: "",
      name: "",
      no: "",
      material_type_id: "",
      material_spec_id: "",
      material_size_id: "",
      material_supplier_id: "",
      minimum_lot: "",
      oem_id: getOem(),
      companyGroup_id: "",
    });
    fillter.product_model_id = "";
    fillter.name = "";
    fillter.no = "";
    fillter.material_type_id = "";
    fillter.material_spec_id = "";
    fillter.material_size_id = "";
    fillter.material_supplier_id = "";
    fillter.minimum_lot = "";
    fillter.oem_id = getOem();
    fillter.companyGroup_id = "";
    
      feature.map((e) => {
        if (e.feature === "Mat Type") {
          selectMat_type.current.select.clearValue();
        }
        if (e.feature === "Mat Spec") {
          selectMat_spec.current.select.clearValue();
        }
        if (e.feature === "Mat Size") {
          selectMat_size.current.select.clearValue();
        }
        if (e.feature === "Mat Supplier") {
          selectMat_supp.current.select.clearValue();
        }
     /*    if (e.feature === "Group_Company") {
      
          selectCompanyGroup.current.select.clearValue();
        } */
        
      })
    
   
   
    
   
    Getdata();
  };

  // console.log(amount);
  const Getamount = async () => {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if(result.isConfirmed){
        if (
          amount.amount !== null &&
          amount.amount !== undefined &&
          amount.amount !== "" &&
          Number(amount.amount.replace(/,/g, "")) > 0
        ) {
          if (amount.edit_type !== null && amount.edit_type !== undefined) {
            //console.log(amount.lot_no);
            axios({
              method: "get",
              url: Configs.API_URL + "/handheld/genLotNo_material?oem_id="+oem_select.oem_id,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              /* data: tracking, */
            })
              .then(function (response) {
                setamount({ ...amount, lot_no: response.data });
                amount.lot_no = response.data;
                console.log(response.data)
            
            const temp_amount = {
              id: amount.id,
              edit_type: amount.edit_type,
              amount: Number(amount.amount.replace(/,/g, "")),
              lot_no: amount.lot_no,
              buy_price: amount.buy_price,
              exp_date: calender_data.exp,
              remark:amount.remark
            };
            
            console.log(temp_amount);
    
            axios({
              method: "post",
              url: Configs.API_URL + "/material/amountInOut",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp_amount,
            })
              .then(async (response) => {
                if (response.data) {
                  Swal.fire({
                    title: "Saving",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    timer: 300,
                    onOpen: () => {
                      Swal.showLoading();
                    },
                  }).then((result) => {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      genLotNo();
                      getHistoryMAT(amount.id);
                      setamount({...amount,
                        amount: "",
                        remark: "",
                      });
                      amount.amount = "";
                      amount.remark = "";
                     
                      // setmessage({
                      //   num: "",
                      //   name: "",
                      // });
                      // message.num = "";
                      // message.name = "";
                      // setamount({
                      //   id: "",
                      //   edit_type: null,
                      //   amount: "",
                      // });
                      // amount.id = "";
                      // amount.edit_type = null;
                      // amount.amount = "";
                      axios({
                        method: "get",
                        url:
                          Configs.API_URL + "/material/gethistory?id=" + amount.id,
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                      })
                        .then(async (response) => {
                          sethistory(response.data);
                          Getdata();
                          //console.log(response.data);
                        })
                        .catch(async (error) => {
                          console.log(error);
                        });
                      let temp = {
                        no: message.no,
                        name: "",
                        material_type_id: "",
                        material_spec_id: "",
                        material_size_id: "",
                        material_supplier_id: "",
                        material_unit_id: "",
                        oem_id: getOem(),
                      };
                      console.log(fillter.oem_id);
                      if(fillter.oem_id === "All_OEM"){
                        fillter.with_oem = false;
                      }else{
                        fillter.with_oem = true;
                      }
                      axios({
                        method: "post",
                        url: Configs.API_URL + "/material/filter",
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                        data: fillter,
                      })
                        .then(async (response) => {
                          /*   await setmaterail(response.data); */
    
                          //let sorted = response.data.sort((a, b) => b.amount - a.amount);
                          //await setmaterail(sorted);
                          let set_total_view = response.data.filter((element) => {
                            return element.id === amount.id;
                          });
                          console.log(set_total_view);
                          console.log(
                            set_total_view[set_total_view.length - 1].amount
                          );
                          setamount({
                            ...amount,
                            total: set_total_view[set_total_view.length - 1].amount,
                            amount: "",
                          });
                          amount.total = set_total_view[set_total_view.length - 1].amount;
                          console.log("total",amount);
                        })
                        .catch(async (error) => {
                          console.log(error);
                        });
    
                      //window.location.href = "/Warehouse_Management/Raw_Mat_In_Out";
                    });
                  });
                }
    
                //console.log(response.data);
              })
              .catch(async (error) => {
                Swal.fire("Over Stock!", "Check your amount", "warning");
                // .then(
                //   (resault) => {
                //     window.location.href = "/Warehouse_Management/Raw_Mat_In_Out";
                //   }
                // );
                console.log(error);
              });
            })
            .catch(function (e) {
              console.log(e);
            });
          } else {
            Swal.fire("check in bound , out bound", "bound can't null", "warning");
          }
        } else {
          Swal.fire("check amount", "amount can't null", "warning");
        }
      }
      
    });
    
  };

  function fillterdata() {
    ///* console.log(type); */ console.log(rowsData);
    Getdata();
  }

  async function setoutbound(e) {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Raw Mat IN/OUT";
    });

    ////////permission feature//////////  {feature.map((e) =>{
    feature_data.map((e) => {
      if (e.feature === "Mat IN/OUT Lot No") {
        setamount({
          ...amount,
          lot_no:moment(new Date()).format("YYYYMMDD"),
        });
      }
    });
    setamount({
      ...amount,
      edit_type: e.target.value === "false" ? false : true,
      lot_no: "",
    });
    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/material/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    sethistory_Select({ ...history_Select, isSelect_status: true });
  }

  async function setinbound(e) {
    //console.log("in");
    setamount({
      ...amount,
      edit_type: e.target.value === "true" ? true : false,
      lot_no: "",
    });
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Raw Mat IN/OUT";
    });

    ////////permission feature//////////  {feature.map((e) =>{
    feature_data.map((e1) => {
      if (e1.feature === "Mat IN/OUT Lot No") {
        //console.log("in2")
        setamount({
          ...amount,
          edit_type: e.target.value === "true" ? true : false,
          lot_no: moment(new Date()).format("YYYYMMDD"),
        });
      }
    });

    sethistory({ ...history });

    await axios({
      method: "get",
      url: Configs.API_URL + "/material/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    sethistory_Select({ ...history_Select, isSelect_status: false });
  }

  async function form(no, name, id, amount_total, edit_type,oem, exprire_date) {
    
    oem_select.oem_id = oem;
    //console.log(oem)
    //console.log(oem_select.oem_id)
    getHistoryMAT(id);
    genLotNo();
    //console.log(edit_type);
    setmessage({ ...message, name: name, no: no });
    setamount({
      ...amount,
      amount: "",
      total: amount_total,
      id: id,
      lot_no: "",
      edit_type: true,
      exprire_date: exprire_date,
    });
    amount.id = id;
    amount.amount = "";
    amount.total = amount_total;
    amount.edit_type = true;
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Raw Mat IN/OUT";
    });

    ////////permission feature//////////  {feature.map((e) =>{
    feature_data.map((e) => {
      if (e.feature === "Mat IN/OUT Lot No") {
        setamount({
          ...amount,
          amount: "",
          total: amount_total,
          id: id,
          lot_no: moment(new Date()).format("YYYYMMDD"),
        });
      }
    });

    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/material/gethistory?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    // Get_history({id});
  }
  function select(e) {
    //  console.log("data " , e);
    sethistory_Select({ ...history_Select, isSelect_status: true });
    setamount({ ...amount, lot_no: e.target.value });
    // Get_history({id});
  }
  async function reset_select() {
    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/material/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });

    if (amount.edit_type == true) {
      setamount({
        ...amount,
        lot_no: "MAT" + moment(new Date()).format("YYYYMMDD"),
      });
      sethistory_Select({ ...history_Select, isSelect_status: false });
    } else {
      setamount({ ...amount, lot_no: "" });
      sethistory_Select({ ...history_Select, isSelect_status: false });
    }
    // e.target.checked = false;
    // Get_history({id});
  }

  const rowsData = [];
  for (var index = 0; index < materail.length; index++) {
    const rowItem = {};
    if (materail[index].amount < materail[index].minimum_lot) {
      var c = "red";
    } else if (materail[index].amount >= materail[index].minimum_lot) {
      var c = "black";
    } else {
      var c = "black";
    }
    rowItem["num"] = <div style={{ color: c }}>{index + 1}</div>;
    rowItem["mat_code"] = materail[index].no;

    /*  rowItem["unit_by"] = materail[index].material_unit_id; */

    for (let indexin = 0; indexin < spect.length; indexin++) {
      if (materail[index].material_spec_id === spect[indexin].id) {
        rowItem["spec"] = spect[indexin].name;
      } /* else {
        rowItem["spec"] = "none"
      } */
    }
    for (let indexin = 0; indexin < size.length; indexin++) {
      if (materail[index].material_size_id === size[indexin].id) {
        rowItem["mat_size"] = size[indexin].name;
      } /* else{
      rowItem["mat_size"] = "none"
    } */
    }
    for (let indexin = 0; indexin < type.length; indexin++) {
      if (materail[index].material_type_id === type[indexin].id) {
        rowItem["mat_type"] = type[indexin].name;
      } /* else{
    rowItem["mat_type"] = "none"
  } */
    }
    for (let indexin = 0; indexin < supplier.length; indexin++) {
      if (materail[index].material_supplier_id === supplier[indexin].id) {
        rowItem["mat_supp"] = supplier[indexin].name;
      } /* else {
        rowItem["mat_pupp"] = "none";
      } */
    }

    rowItem["Min_lot_sup"] = (
      <NumberFormat
        value={
          materail[index].minimum_order_supplier === null
            ? 0
            : materail[index].minimum_order_supplier
        }
        displayType={"text"}
        thousandSeparator={true}
      />
    );
    rowItem["Min_lot"] = (
      <div style={{ color: c }}>
        <NumberFormat
          value={
            materail[index].minimum_lot === null
              ? 0
              : materail[index].minimum_lot
          }
          displayType={"text"}
          thousandSeparator={true}
        />
      </div>
    );

    if (
      materail[index].material_unit_id ===
      "505a84a4-6c71-4fbf-9dbd-8c63644221f5"
    ) {
      rowItem["unit_id"] = "Piece";
    } else {
      rowItem["unit_id"] = "Weight";
    }
    rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            materail[index].imaget_path !== null
              ? Configs.API_URL_IMG + materail[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
    rowItem["qr"] = (
      <div style={{ textAlign: "center" }}>
        <QRCode
          value={materail[index].no}
          size={50}
          fgColor={"#000000"}
          bgColor={"#ffffff"}
          level={"M"}
          renderAs={"svg"}
          includeMargin={false}
          imageSettings={{
            src: logoTTT.imgs,
            height: 15,
            width: 15,
            x: null,
            y: null,
            excavate: true,
          }}
        />
      </div>
    );

    rowItem["name"] = materail[index].name;
    if(feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0){
    rowItem["amount"] = (
      /* materail[index].amount; */ <div style={{ color: c }}>
        <NumberFormat
          value={parseFloat(materail[index].amount || 0).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
      </div>
    );
    }else{
      rowItem["amount"] = (
        <div style={{ color: c }}><NumberFormat
          value={materail[index].amount || 0}
          displayType={"text"}
          thousandSeparator={true}
        /></div>
      );
    }
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className="btn btn-xs "
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={form.bind(
              this,
              materail[index].no,
              materail[index].name,
              materail[index].id,
              materail[index].amount || 0,
              amount.edit_type,
              materail[index].oem_id,
            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "num",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material No.",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
        label: "QR",
        field: "qr",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Unit By",
        field: "unit_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "Size",
        field: "mat_size",
        sort: "asc",
        width: 50,
      },

      {
        label: "Min Stock",
        field: "Min_lot",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "Min_Lot_Sup",
        field: "Min_lot_sup",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "mat_supp",
        sort: "asc",
        width: 50,
      },
      /*  {
         label: "Total(Weight)",
         field: "weight",
         sort: "asc",
         width: 50,
       }, */
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  var Type_feature = feature.filter((e) => {
    return e.feature === "Mat Type";
  });
  var Spec_feature = feature.filter((e) => {
    return e.feature === "Mat Spec";
  });
  var Size_feature = feature.filter((e) => {
    return e.feature === "Mat Size";
  });
  var QR_feature = feature.filter((e) => {
    return e.feature === "QR";
  });

  if (Type_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "mat_type";
    });
    data.columns.splice(index, 1);
  }
  if (Spec_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "spec";
    });
    data.columns.splice(index, 1);
  }
  if (Size_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "mat_size";
    });
    data.columns.splice(index, 1);
  }
  if (QR_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "qr";
    });
    data.columns.splice(index, 1);
  }

  const rowsData_history = [];

  for (var index = 0; index < history.length; index++) {
    const rowItem = {};
    rowItem["lot_no"] = history[index].mat_lot_no;
    if(feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0){
      rowItem["items"] = parseFloat(history[index].amount).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
    }else{
      rowItem["items"] = history[index].amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ;
    }

    rowItem["exprire_date"] = moment(history[index].exprire_date).format(
      "DD/MM/yyyy"
    );
    /*    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6"></div>
        <div className="col-4">
          <input
            className="form-check-input"
            type="radio"
            id={index}
            name="select_history"
            value={history[index].mat_lot_no}
            // checked={history_reset.isReset_status != true ? false : true}
            onClick={select.bind(this)}
          />
        </div>
      </div>
    ); */
    rowsData_history.push(rowItem);
  }

  const data_history = {
    columns: [
      /*  {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "items",
        field: "items",
        sort: "asc",
        width: 50,
      },
      isEXP === true
        ? {
            label: "Exprire date",
            field: "exprire_date",
            sort: "asc",
            width: 50,
          }
        : {
            label: "Create date",
            field: "exprire_date",
            sort: "asc",
            width: 50,
          },
    ],
    rows: rowsData_history,
  };

  function showmessage() {
    Swal.fire({
      icon: "info",
      title: "Waiting for connect service API QR",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
    });
  }

  function close() {
    window.location.href = "/Warehouse_Management/Raw_Mat_In_Out";
  }

  function getHistoryMAT(material_id) {
    axios({
      method: "get",
      url: Configs.API_URL + "/material/getHistoryMAT?id=" + material_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
        setHistoryMAT(response.data);
        console.log(response.data);
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const rowsData_historyMAT = [];

  for (var index = 0; index < historyMAT.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["mat_lot_no"] = historyMAT[index].mat_lot_no;

    var status;
    if (historyMAT[index].edit_type === true) {
      status = "IN";
    } else {
      status = "OUT";
    }
    rowItem["status"] = status;
    if(feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0){
      rowItem["amount"] = parseFloat(historyMAT[index].amount).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
      rowItem["amount"] = historyMAT[index].amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  
    rowItem["date"] = moment(historyMAT[index].create_date).format(
      "DD/MM/yyyy :: HH:mm น."
    );

    rowsData_historyMAT.push(rowItem);
  }

  const Data_HistoryMAT = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "mat_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_historyMAT,
  };
  async function genLotNo() {
    axios({
      method: "get",
      url: Configs.API_URL + "/handheld/genLotNo_material?oem_id="+oem_select.oem_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
      setamount({ ...amount, lot_no: response.data });
        amount.lot_no = response.data;
        console.log(response.data)
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  async function SetDate_and_GetDayoff_start(date) {
    setcalender_data({ ...calender_data, exp: date });
  }
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="rawmatinout" submenu="none" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Raw Material IN/OUT{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
            
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">
                    Raw Material IN/OUT
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={fillter.no.trim()}
                    onChange={(e) => {
                      setfillter({ ...fillter, no: e.target.value.trim() });
                    }}
                    required
                  />
                  <label htmlFor="">Mat No.</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    value={fillter.name.trim()}
                    onChange={(e) => {
                      setfillter({ ...fillter, name: e.target.value.trim() });
                    }}
                    required
                  />
                  <label htmlFor="">Mat Name</label>
                </div>
              </div>
              {feature.map((e) => {
                
                if (e.feature === "Mat Type") {
                  return (
                    <>
                      <div className="col-md-2" style={{ "z-index": "99" }}>
                        <div className="form-group ">
                          <Select
                            options={mat_type_option}
                            ref={selectMat_type}
                            defaultValue={fillter.material_type_id}
                            onChange={(e) => {
                              //console.log(e);
                              if (e !== null) {
                                setfillter({
                                  ...fillter,
                                  material_type_id: e.value || "",
                                });
                              }
                            }}
                          />
                          <label htmlFor="">Mat Type</label>
                        </div>
                      </div>
                    </>
                  );
                }
                if (e.feature === "Mat Spec") {
                  return (
                    <>
                      <div className="col-md-2" style={{ "z-index": "99" }}>
                        <div className="form-group ">
                          <Select
                            options={mat_spec_option}
                            ref={selectMat_spec}
                            defaultValue={fillter.material_spec_id}
                            onChange={(e) => {
                              // console.log(e);
                              if (e !== null) {
                                setfillter({
                                  ...fillter,
                                  material_spec_id: e.value,
                                });
                              }
                            }}
                          />
                          <label htmlFor="">Mat Spec</label>
                        </div>
                      </div>
                    </>
                  );
                }
              
                if (e.feature === "Mat Size") {
                  return (
                    <>
                      <div className="col-md-2" style={{ "z-index": "99" }}>
                        <div className="form-group ">
                          <Select
                            options={mat_size_option}
                            ref={selectMat_size}
                            defaultValue={fillter.material_size_id}
                            onChange={(e) => {
                              // console.log(e);
                              if (e !== null) {
                                setfillter({
                                  ...fillter,
                                  material_size_id: e.value,
                                });
                              }
                            }}
                          />
                          <label htmlFor="">Mat Size</label>
                        </div>
                      </div>
                    </>
                  );
                }
                
                if (e.feature === "Mat Supplier") {
                  return (
                    <>
                      <div className="col-md-2" style={{ "z-index": "99" }}>
                        <div className="form-group ">
                          <Select
                            options={mat_supp_option}
                            defaultValue={fillter.material_supplier_id}
                            ref={selectMat_supp}
                            onChange={(e) => {
                              // console.log(e);
                              if (e !== null) {
                                setfillter({
                                  ...fillter,
                                  material_supplier_id: e.value,
                                });
                              }
                            }}
                          />
                          <label htmlFor="">Mat Supplier</label>
                        </div>
                      </div>
                    </>
                  );
                }
              
              
              })}

               {feature.map((e) => {
               /*  if (e.feature === "Group_Company") {
                  return (
                    <>
                   
                      <div className="col-md-2" style={{ "z-index": "99" }}>
                        <div className="form-group ">
                          <Select
                            options={companyGroup}
                            defaultValue={fillter.oem_id}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setfillter({
                                  ...fillter,
                                  oem_id: e.value,
                                });
                      
                              }
                            }}
                          />
         
                          <label> กลุ่มบริษัท </label>
                        </div>
                      </div>
                    </>
                  );
                } */
                 if (e.feature === "Min Stock ") {
                  return (
                    <>
                    <div className="col-1"></div>
                        <div className="col-2" style={{ "z-index": "99" }}>

                <input
                className="form-check-input"
                type="checkbox"
                id="ch_under_min_lot"
                onChange={(event) => {
                //console.log(event.currentTarget.checked);
                setfillter({ ...fillter, minimum_lot: event.currentTarget.checked });
                fillter.minimum_lot = event.currentTarget.checked;
                Getdata()
                /*  isChecked2 = setIsChecked(event.currentTarget.checked);
                  search_check(event.currentTarget.checked) */
                }}
                //onClick = {search_check.bind(this)}
                //checked={isChecked2 === true ? true : false}
                checked={fillter.minimum_lot}
                />
                <label htmlFor="ch_under_min_lot">
                Min Stock
                </label>

                </div>
                    </>
                  );
                }
              })} 
            </div>
          </div>

          <div classNam e="container-fluid">
            <div className="row mb-2 ">
            <div className="col-4 col-md-3 col-xl-2">
            <div className="form-group ">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm "
                  onClick={fillterdata}
                >
                  Search
                </button>
              </div> </div>
              <div className="col-4 col-md-3 col-xl-2">
              <div className="form-group ">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm"
                  onClick={() => {
                    clearfillter();
                  }}
                >
                  Clear
                </button>
              </div> </div>
              {/*
              <div className=" col-sm-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-sm "
                  onClick={showmessage}
                >
                  QR Code
                </button>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Raw Material IN/OUT </h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div
                className="table-responsive"  style={{ whiteSpace: 'nowrap' }} /*s tyle={{ height: "400px" }} */
              >
                <MDBDataTable
                  className="table table-head-fixed"
                  sortable = {false}
                  striped
                  bordered
                  hover
                  order={["amount", "asc"]}
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">
                  Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                  {feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0 ? (
  <NumberFormat
  style={{
    backgroundColor: "#F0FFFF",
    border: "1px solid #ddd",
    padding: "10px",
    width: "100",
  }}
  decimalScale={2}
/*                     value={amount.total|| 0 } */
  value={parseFloat(amount.total|| 0 ).toFixed( 2 ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
  displayType={"text"}
  thousandSeparator={true}
/>
                  ) : (
                    <NumberFormat
                    style={{
                      backgroundColor: "#F0FFFF",
                      border: "1px solid #ddd",
                      padding: "10px",
                      width: "100",
                    }}
                 
/*                     value={amount.total|| 0 } */
                    value={(amount.total|| 0 )}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  )
                  
                  
                  }
                  &nbsp;&nbsp;&nbsp; items
                </h1>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  //onClick={close}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                  <div className="col-6 col-md-4 col-xl-4">
                    <h3>Mat No : {message.no}</h3>
                    <input
                      type="radio"
                      id="inbound"
                      name="edit_type"
                      checked={amount.edit_type != true ? false : true}
                      value={true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "true" ? true : false,
                        });
                      }}
                      /*   checked={amount.edit_type}
                      value="true"
                      onChange={(e) => {
                         setinbound(e);
                      }} */
                    />

                    <label htmlFor="inbound">
                      <h4> In bound</h4>
                    </label>
                  </div>
                  <div className="col-6 col-md-4 col-xl-4">
                    <h3>Material Name : {message.name}</h3>

                    <input
                      type="radio"
                      id="outbound"
                      name="edit_type"
                      value={false}
                      checked={amount.edit_type != false ? false : true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "false" ? false : true,
                        });
                      }}
                    />
                    <label htmlFor="outbound">
                      <h4> Out bound</h4>
                    </label>
                  </div>
                </div>
                {/* <div
                  style={{
                    color: "#000000",
                    //width:"70px",
                    background: "#D3D3D3",
                    border: " 7px solid #FFFFFF",
                    borderradius: "20px",
                  }}
                >
                  <p>Log Detail : History</p>
                  <div className="row">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="col-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={reset_select}
                      >
                        Clear
                      </button>
                    </div>
                  </div>

                  <div className="card-body">
                    <div
                      className="table-responsive"
                      style={{ height: "300px" }}
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        searching={false}
                        sortable = {false}
                        striped
                        bordered
                        hover
                        data={data_history}
                      />
                    </div>
                  </div>
                </div> */}

                {feature.map((e) => {
                  if (e.feature === "Mat IN/OUT History") {
                    return (
                      <LOGH
                        onClick={reset_select}
                        history={Data_HistoryMAT}
                        data={data_history}
                      />
                    );
                  }
                })}
                {/* <LOGH onClick ={reset_select} data = {data_history} /> */}
                <div className="row"></div>
                <div className="row">
                  <div className="col-4"></div>

                  {amount.edit_type === true ? (
                    <>
             <div className="col-12 col-md-4 col-xl-4">
                        {feature.map((e) => {
                          if (e.feature === "Mat IN/OUT Lot No") {
                            return (
                              <INF
                                value={amount.lot_no}
                                disabled={true}
                                label={"Lot No"}
                                onChange={(e) => {
                                  setamount({
                                    ...amount,
                                    lot_no: e.target.value,
                                  });
                                }}
                              />
                            );
                          }
                        })}
                      </div>

                      <div className="col-12 col-md-4 col-xl-4">
                        {feature.map((e) => {
                          if (e.feature === "MAT EXP") {
                            return (
                              <INDF
                                selected={calender_data.exp}
                                txt = "EXP Date"
                                onChange={(date) =>
                                  SetDate_and_GetDayoff_start(date)
                                }
                                customInput={<CustomInput />}
                              />
                            );
                          }
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                    </>
                  )}

                  <div className="col-4"></div>
                  <div className="col-12 col-md-4 col-xl-4">
                    <div className="form-group ">
                    {feature.filter((e)=>{ return e.feature === "AmountDecimalScale" }).length > 0 ? (
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        value={amount.amount}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            amount: e.target.value,
                          });
                        }}
                        required
                      />
                    ) : (
                      <NumberFormat
                      thousandSeparator={true}
                      decimalScale={0}
                      type="text"
                      className="form-control"
                      allowNegative={false}
                      value={amount.amount}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          amount: e.target.value,
                        });
                      }}
                      required
                    />
                    )}
                      <label htmlFor="">Amount</label>{" "}
                    </div>
                  </div>
                </div>
                {feature.map((e) => {
                  if (e.feature === "Remark") {
                    return (
                      <div className="row">
                             <div className="col-0 col-md-4 col-xl-4"></div>
                        <div className="col-12 col-md-4 col-xl-4">
                          <INF
                            value={amount.remark}
                            disabled={false}
                            label={"Remark"}
                            onChange={(e) => {
                              setamount({
                                ...amount,
                                remark: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={Getamount}
                  // data-dismiss="modal"
                  // aria-label="Close"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*    <Footter /> */}
    </div>
  );
}
export default RawMatInOut;
