import React, { useState, useEffect } from "react";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

function Product_Kitting_Table() {
  const [Filter, setFilter] = useState({
    company: "",
    name: "",
    // category: "",
  });

  function clear() {
    setFilter({
      ...Filter,
      company: "",
      name: "",
      // category: "",
    });
    Filter.company = "";
    Filter.name = "";
    //   Filter.category = "";
    getKitting();
  }

  const [ProductKitting, setProductKitting] = useState({
    data: [],
  });

  const getKitting = async () => {
    let temp = {
      company: Filter.company.trim() || "",
      name: Filter.name.trim() || "",
      company_id: getUser().com,
      oem_id: getOem(),
      // category: Filter.category.trim() || "",
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_kitting/getproduct_kitting",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        // setProductKitting({ data: response.data });
        // ProductKitting.data = response.data;
        // console.log(ProductKitting.data);
        if (response) {
          for (let item of response.data) {
            /* const product = item;
            if (Array.isArray(product.barcode) && product.barcode.length > 0) {
              const arrlist = product.barcode;
              let str = "";
              str = arrlist.join(",\n")
              product.barcode = str;
            } */
            /* if (Array.isArray(product.kittings) && product.kittings.length > 0) {
              const arrlist = product.kittings;
              let str = "";
              str = arrlist.join(",\n")
              product.barcode = str;
            } */
          }
          setProductKitting({ data: response.data });
          ProductKitting.data = response.data;
          /////////////////////////////////////
          let data = new FormData();
          data.append("company_id", getUser().com);
          data.append("oem_id", getOem());
          data.append("user_id", getUser().fup);
          data.append("products", JSON.stringify(response.data));

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "multipart/form-data",
            },
            data: data,
          })
            .then(async (response) => {
              setProductKitting({ data: response.data });
              ProductKitting.data = response.data;
              return response.data;
            })
            .catch(async (error) => {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
              });
            });

          /////////////////////////////////////
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  function delKitting(e) {
    // console.log("id", e.product_id);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        let temp = {
          id: e.product_id.trim() || "",
          company_id: getUser().com,
          oem_id: getOem(),
          // category: Filter.category.trim() || "",
        };
        axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus +
            "/api/product_kitting/delproduct_kitting",
          // e.product_id,
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: temp,
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              let arrList = [];
              window.location.reload();
              /* arrList = perchase_po.filter((po) => po.perchaseTable.po_id !== e.perchaseTable.po_id);
                        setPerchase_po(arrList);
                        setTest({ ...test, test: '' }); */
              // window.location.href = "/Human_Resource/HRD/External_dev_train";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  const rowsData = [];
  for (let index = 0; index < ProductKitting.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["product_code"] = ProductKitting.data[index].product_code
      ? ProductKitting.data[index].product_code
      : "-";
    rowItem["barcode"] = ProductKitting.data[index].barcode ? (
      <span style={{ whiteSpace: "pre-line" }}>
        {ProductKitting.data[index].barcode.join(",\n") || "-"}
      </span>
    ) : (
      "-"
    );
    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="..."
          className="img-fluid rounded shadow border-0"
          src={
            ProductKitting.data[index].pci_img_path !== "" &&
              ProductKitting.data[index].pci_img_path !== null &&
              ProductKitting.data[index].pci_img_path !== undefined
              ? ProductKitting.data[index].pci_img_path?.indexOf("blob") >= 0
                ? ProductKitting.data[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                `/static/${ProductKitting.data[index].pci_img_name}/${ProductKitting.data[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );
    rowItem["product_name_eng"] = ProductKitting.data[index].product_name_eng
      ? ProductKitting.data[index].product_name_eng
      : "-";
    rowItem["product_category"] = ProductKitting.data[index].kitting ? (
      <span style={{ whiteSpace: "pre-line" }}>
        {ProductKitting.data[index].kitting.join(",\n") || "-"}
      </span>
    ) : (
      "-"
    );
    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={ProductKitting.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_kitting/view/" +
                ProductKitting.data[index].product_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <a
            key={ProductKitting.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/product_kitting/edit/" +
                ProductKitting.data[index].product_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        {/* <div
          className="col-xl-3 col-md-3 col-xs-3 "
        >
          <a
            key={ProductKitting.data[index]}
            id={ProductKitting.data[index]}
            className=" btn btn-xs "
            onClick={delKitting.bind(this, ProductKitting.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div> */}
      </div>
    );

    rowsData.push(rowItem);
  }

  const ProductKittingTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name_eng",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Category",
        field: "product_category",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  useEffect(async () => {
    getKitting();
    // GetCategory();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Product Kitting</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product Kitting</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.company}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        company: e.target.value,
                      });
                      Filter.company = e.target.value;
                    }}
                  />
                  <label>Product Code</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        name: e.target.value,
                      });
                      Filter.name = e.target.value;
                    }}
                  />
                  <label>Product Name</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      getKitting();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Kitting_CutePlus/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product Kitting</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={ProductKittingTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Product_Kitting_Table;
