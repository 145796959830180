import React, { Component, useEffect, useState, useRef } from "react";
//import { MDBDataTable } from "mdbreact";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import Swal from "sweetalert2";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import {
    getOem,
    getToken,
    getUser,
    getFeature,
    getAcademy,
} from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import Resizer from "react-image-file-resizer";
import moment, { min } from "moment";
import Select from "react-select";

function Tooling_add(mode) {
    const { id } = useParams();
    const [page_mode, setPageMode] = useState('Add');
    const [supplier, setSupplier] = useState([]);
    const [supplier_option, setSupplier_option] = useState([]);
    const [supplier_select_data, setSupplier_select_data] = useState([]);
    const [supplier_select, setsupplier_select] = useState({
        id: "",
        supplier: "",
        buy_price: "",
        minimum_order: "",
        lead_time: "",
        mat_cycle_time: "",
        remark: "",
        mode: "",
        index: "",

    });

    const [toolingData, setToolingData] = useState({
        toolingId: '',
        toolingNo: '',
        toolingName: '',
        code: '',
        name: '',
        brand: '',
        image: '',
        image_name: '',
        image_path: '',
        description: '',
        minStock: '',
        maxStock: '',
        consumtion: '',
        ratio: '',
    });
    const [disable, setdisable] = useState(false);
    useEffect(async () => {
        if (mode.mode === "edit") {
            getToolingDataByIdApi(id);
            setPageMode('Edit');
            setdisable(false);
        }

        if (mode.mode === "view") {
            getToolingDataByIdApi(id);
            setPageMode('View');
            setdisable(true);
        }
        setTest({ ...test, test: "" })
        test.test = "";
    }, []);

    const backPeviousPage = () => {
        Swal.fire({
            title: "คุณต้องการที่จะออกจากหน้านี้ ใช่หรือไม่",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `ใช่`,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `ไม่`,
        })
            .then((res) => {
                if (res.isConfirmed) {
                    window.location.href = "/Warehouse_Management/Factory_Master_Data/Tooling";
                }
            })
    }

    const getToolingDataByIdApi = async (id) => {
        if (mode.mode === "edit" || mode.mode === "view") {
            axios({
                method: "get",
                url:
                    Configs.API_URL + "/tooling/getToolingDataByIdApi?id=" + id,
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
            })
                .then(function (response) {
                    if (response.data) {
                        // console.log(response.data);
                        if (response.data.tooling.length > 0) {
                            const tooling = response.data.tooling[0];
                            setToolingData({
                                toolingId: tooling.id,
                                toolingNo: tooling.tooling_code || '',
                                toolingName: tooling.tooling_name || '',
                                code: tooling.tooling_code || '',
                                name: tooling.tooling_name || '',
                                brand: tooling.brand_id,
                                image: '',
                                image_name: tooling.tooling_img_name || '',
                                image_path: tooling.tooling_path_name || '',
                                description: tooling.tooling_detail || '',
                                minStock: tooling.min_stock || '',
                                maxStock: tooling.max_stock || '',
                                consumtion: tooling.consumption || '',
                                ratio: tooling.ratio || '',
                            });
                        }
                        /*  tooling.amount          
                         tooling.company_id                 
                         tooling.created_by
                         tooling.created_date                  
                         tooling.is_active
                         tooling.is_use       
                         tooling.oem_id
                         tooling.updated_by
                         tooling.updated_date */

                        if (response.data.tooling_supplier.length > 0) {
                            const toolingsupplier = response.data.tooling_supplier;
                            for (let item of toolingsupplier) {
                                item.status = 'defualt';
                                item.buy_price = item.buy_price || 0;
                                item.minimum_order = item.minimum_order || '';
                                item.remark = item.remark || '';
                            }
                            setSupplier_select_data(toolingsupplier);
                        }

                        setTest({ ...test, test: '' });
                        /* Swal.fire({
                            icon: "success",
                            title: "Save",
                            showConfirmButton: false,
                            timer: 1500,
                        }).then((result) => {
                            // console.log(response.data);
                            // window.location.href = "/Warehouse_Management/Factory_Master_Data/Tooling";
                        }); */
                    }

                })
                .catch(function (error) {
                    console.log('error', error.response.data);
                    Swal.fire("Error", error.response.data.message, "error");
                });
        }
    }

    const [getBrandTooling, setGetBrandTooling] = useState([]);
    const saveToolingApi = async () => {

        toolingData.toolingNo = toolingData.toolingNo.trim();
        toolingData.toolingName = toolingData.toolingName.trim();
        toolingData.code = toolingData.code.trim();
        toolingData.name = toolingData.name.trim();
        toolingData.description = toolingData.description.trim();
        toolingData.minStock = Number(String(toolingData.minStock).replace(/,/g, "")) || null;
        toolingData.maxStock = Number(String(toolingData.maxStock).replace(/,/g, "")) || null;
        toolingData.consumtion = Number(String(toolingData.consumtion).replace(/,/g, "")) || null;
        toolingData.ratio = Number(String(toolingData.ratio).replace(/,/g, "")) || null;
        for (let item of supplier_select_data) {
            item.buy_price = Number(String(item.buy_price).replace(/,/g, "")) || null;
            item.minimum_order = Number(String(item.minimum_order).replace(/,/g, "")) || null;
            item.lead_time = parseInt(item.lead_time) || null;
            item.remark = item.remark.trim();
        }

        const error_list = [];

        if (toolingData.code === '') {
            let temp_err = {
                message: "กรุณากรอกข้อมูลที่ช่อง Tooling code.",
            };
            error_list.push(temp_err);
        }

        if (toolingData.name === '') {
            let temp_err = {
                message: "กรุณากรอกข้อมูลที่ช่อง Tooling name.",
            };
            error_list.push(temp_err);
        }

        if (toolingData.brand === '') {
            let temp_err = {
                message: "กรุณาเลือกข้อมูลที่ช่อง Brand",
            };
            error_list.push(temp_err);
        }

        if (toolingData.minStock != null && toolingData.maxStock != null) {
            if (toolingData.minStock > toolingData.maxStock) {
                let temp_err = {
                    message: "กรุณาแก้ไขข้อมูล Min Stock ต้องน้อยกว่า Max Stock",
                };
                error_list.push(temp_err);
            }
        }
        /* toolingData.minStock
toolingData.maxStock */

        if (error_list.length > 0) {
            var err_message = "";
            for (var e = 0; e < error_list.length; e++) {
                err_message += "<br/>" + error_list[e].message;
            }
            Swal.fire("Error", err_message, "error");
        } else {
            if (mode.mode === "add") {
                Swal.fire({
                    title: "ต้องการที่จะบันทึกข้อมูล ใช่หรือไม่",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: `ใช่`,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: `ไม่`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let toolingData1 = {
                            tooling: toolingData,
                            emp_oem_id: getOem(),
                            emp_fup: getUser().fup,
                            emp_company_id: getUser().com,
                            supplierData: supplier_select_data
                        }
                        axios({
                            method: "post",
                            url:
                                Configs.API_URL + "/tooling/addToolingApi",
                            headers: {
                                Authorization: getToken(),
                                "X-TTT": Configs.API_TTT,
                                "Content-Type": "application/json",
                            },
                            data: toolingData1,
                        })
                            .then(function (response) {
                                if (response.data) {
                                    // console.log(response.data);
                                    Swal.fire({
                                        icon: "success",
                                        title: "Save",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    }).then((result) => {
                                        // console.log(response.data);
                                        window.location.href = "/Warehouse_Management/Factory_Master_Data/Tooling";
                                    });
                                }

                            })
                            .catch(function (error) {
                                console.log('error', error.response.data);
                                Swal.fire("Error", error.response.data.message, "error");
                            });
                    }
                });
            } else if (mode.mode === "edit") {
                Swal.fire({
                    title: "ยืนยันที่จะแก้ไขข้อมูล ใช่หรือไม่",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: `ใช่`,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: `ไม่`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        let toolingData1 = {
                            tooling: toolingData,
                            emp_oem_id: getOem(),
                            emp_fup: getUser().fup,
                            emp_company_id: getUser().com,
                            supplierData: supplier_select_data
                        }
                        axios({
                            method: "post",
                            url:
                                Configs.API_URL + "/tooling/updateToolingApi",
                            headers: {
                                Authorization: getToken(),
                                "X-TTT": Configs.API_TTT,
                                "Content-Type": "application/json",
                            },
                            data: toolingData1,
                        })
                            .then(function (response) {
                                if (response.data) {
                                    // console.log(response.data);
                                    Swal.fire({
                                        icon: "success",
                                        title: "Save",
                                        showConfirmButton: false,
                                        timer: 1500,
                                    }).then((result) => {
                                        // console.log(response.data);
                                        window.location.href = "/Warehouse_Management/Factory_Master_Data/Tooling";
                                    });
                                }

                            })
                            .catch(function (error) {
                                console.log('error', error.response.data);
                                Swal.fire("Error", error.response.data.message, "error");
                            });
                    }
                });
            }
        }
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                400,
                300,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);

                },
                // "base64"
            );
        });
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    function isFileImage(file) {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

        return file && acceptedImageTypes.includes(file['type'])
    }
    async function setproductLogo(e) {

        // if (materailData.no !== "") {

        const file = e.target.files[0];


        const regex = /([\u0E00-\u0E7F]+)/gmu;
        const str = file.name;
        let m = regex.exec(str);
        //console.log("image is :",isFileImage(file)); 

        if (m !== null || isFileImage(file) === false) {
            //console.log("ชื่อไฟล์ไม่ถูกต้อง"); 
            Swal.fire(
                'Error',
                'File name or type are not correct!!!',
                'error'
            )
        } else {
            const image = await resizeFile(file);


            var file_image = dataURLtoFile(image, file.name);
            var data = new FormData();
            data.append("Profile", file_image);
            data.append("typeFile", toolingData.code);

            var config_uploadProfile = {
                method: "post",
                url: Configs.API_URL + "/upload/profile",
                headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config_uploadProfile)
                .then(function (response) {
                    if (response.data.status) {
                        /* console.log(response.data); */

                        setToolingData({
                            ...toolingData,
                            image_name: response.data.data.orgin_name,
                            image_path: response.data.data.path,
                            image: response.data.data,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    function form_supplier(supplier_name, buy_price, minimum_order, lead_time, mat_cycle_time, remark, mode, index) {
        // console.log("lead", lead_time);

        let id = supplier.filter((e) => {
            return e.name === supplier_name;
        })
        setsupplier_select({ ...supplier_select, id: id[0].id, supplier: supplier_name, buy_price: buy_price, mode: mode, index: index, minimum_order: minimum_order, lead_time: lead_time, mat_cycle_time: mat_cycle_time, remark: remark, status: 'update' });
    }
    const [test, setTest] = useState({
        test: ''
    });
    function setSupplier_active(supplier, e) {
        // console.log("lead", lead_time)
        //console.log(supplier_id,supplier_name,buy_price)
        let index = e.target.id;
        // console.log('index', index);
        // console.log('supplier_select_data', supplier_select_data);
        let tempData = supplier_select_data;
        // console.log('supplier', supplier, supplier.is_active);
        setSupplier_select_data([]);
        setTest({ ...test, test: '' });
        tempData[index].is_active = !tempData[index].is_active;
        tempData[index].status = 'update';
        tempData.sort((a, b) => b.sort - a.sort);
        tempData.sort((a, b) => b.is_active - a.is_active);
        // console.log('tempData', tempData);
        setSupplier_select_data(tempData);
    }

    function Remove_Supplier(e) {
        var tempData = supplier_select_data;
        setSupplier_select_data([]);
        tempData[e.target.id].status = 'delete';  /* .splice(e.target.id, 1); */
        setSupplier_select_data(tempData);
        setsupplier_select({ ...supplier_select, id: "", supplier: "", buy_price: "", mode: "", index: "", minimum_order: "", lead_time: "", remark: "" });
        supplier_select.id = "";
        supplier_select.buy_price = "";
        supplier_select.minimum_order = "";
        supplier_select.lead_time = "";
        supplier_select.remark = "";
        select_supplier.current.select.clearValue();
    }

    const select_supplier = useRef();

    let config_sub = {
        method: "get",
        url: Configs.API_URL + "/tooling/getToolingSupplier?id=" + getUser().com,
        headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
        },
    };


    let config_brand = {
        method: "get",
        url:
            Configs.API_URL +
            `/tooling/getToolingBrand?id=${getUser().com}&id2=${getOem()}`,
        headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
        },
    };

    useEffect(async () => {
        axios(config_sub)
            .then(function (response) {
                setSupplier(response.data);
                const options = [{ value: "", label: "Select All" }];
                response.data.forEach((el) => {
                    var element = {};
                    element["value"] = el.id;
                    element["label"] = el.name;
                    options.push(element);
                });
                setSupplier_option(options);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios(config_brand)
            .then(function (response) {
                // setBrand(response.data);
                const options = [{ value: "", label: "Select All" }];
                response.data.forEach((el) => {
                    var element = {};
                    element["value"] = el.brand_id;
                    element["label"] = el.brand_name;
                    options.push(element);
                });
                setGetBrandTooling(options);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    function numberWithCommas(x) {
        return (
            <NumberFormat
                thousandSeparator={true}
                value={x || 0}
                displayType="text"
            />
        );
        /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    }
    var rowsSupplier = [];

    for (var index = 0; index < supplier_select_data.length; index++) {
        //console.log(customer_product_data)
        const rowItem = {};
        if (supplier_select_data[index].status !== 'delete') {
            rowItem["no"] = index + 1;
            let sup_name = supplier.filter((e) => {
                return e.id === supplier_select_data[index].supplier_id;
            })
            var name = "";
            if (sup_name.length > 0) {
                name = sup_name[0].name;
            }

            rowItem["supplier"] = name;
            rowItem["buy_price"] = (<div style={{ textAlign: 'end' }} >{numberWithCommas(supplier_select_data[index].buy_price)}</div>)
            rowItem["minimum_order"] = (<div style={{ textAlign: 'end' }} >{numberWithCommas(supplier_select_data[index].minimum_order)}</div>)
            rowItem["lead_time"] = (<div style={{ textAlign: 'end' }} >{supplier_select_data[index].lead_time}</div>)
            rowItem["mat_cycle_time"] = supplier_select_data[index].mat_cycle_time;
            rowItem["remark"] = supplier_select_data[index].remark;

            var date = moment(supplier_select_data[index].updated_date).format('DD/MM/yyyy :: HH:mm น.');
            //}
            //console.log(supplier_select_data[index].create_date)
            rowItem["create_date"] = date;
            rowItem["status"] = (
                <>
                    <div className="row">
                        <div className="col-12">
                            <button
                                type="button"
                                id={index}
                                disabled={disable}
                                onClick={setSupplier_active.bind(
                                    this,
                                    supplier_select_data[index]
                                )}
                                class={supplier_select_data[index].is_active === true ? "btn btn-block btn-success " : "btn btn-block btn-primary "}
                            >
                                ACTIVE
                            </button>
                        </div>
                    </div>
                </>
            );
            rowItem["mgt"] = (
                <>
                    {supplier_select_data[index].is_active === false && !disable ? (
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <a
                                        data-toggle="modal"
                                        data-target="#modal-add_supplier_rerationship"
                                        onClick={form_supplier.bind(
                                            this,
                                            name,
                                            supplier_select_data[index].buy_price,
                                            supplier_select_data[index].minimum_order || "",
                                            supplier_select_data[index].lead_time || "",
                                            supplier_select_data[index].mat_cycle_time || "",
                                            supplier_select_data[index].remark || "",
                                            "edit",
                                            index,
                                            supplier_select_data[index]
                                        )}
                                    >
                                        <i class="fas fa-pencil-alt"></i>
                                    </a>
                                </div>

                                <div className="col-6">
                                    <i
                                        id={index}
                                        onClick={Remove_Supplier.bind(
                                            this

                                        )}
                                        className="fa fa-trash  icon-sm"
                                    ></i>
                                </div>
                            </div>
                        </>
                    ) : ''}


                </>
            );
        }


        rowsSupplier.push(rowItem);
    }

    const looptabel = [];
    looptabel.push(
        {
            label: "No",
            field: "no",
            sort: "asc",
            width: 50,
        },
        {
            label: "Supplier",
            field: "supplier",
            sort: "asc",
            width: 50,
        },

        {
            label: "Buy Price",
            field: "buy_price",
            sort: "asc",
            width: 50,
        },

        {
            label: "Minimum Order",
            field: "minimum_order",
            sort: "asc",
            width: 50,
        },
        {
            label: "Lead Time (Days)",
            field: "lead_time",
            sort: "asc",
            width: 50,
        },)

    looptabel.push(
        {
            label: "Remark ",
            field: "remark",
            sort: "asc",
            width: 50,
        },
        {
            label: "Date",
            field: "create_date",
            sort: "asc",
            width: 50,
        },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 50,
        },
        {
            label: "MGT",
            field: "mgt",
            sort: "asc",
            width: 50,
        },
    )
    const dataSupplier = {
        columns: looptabel,
        rows: rowsSupplier,
    };

    function Add_Suppier() {
        var tempData = supplier_select_data;
        //console.log(tempData)
        setSupplier_select_data([]);
        let temp = {
            supplier_id: supplier_select.id,
            buy_price: supplier_select.buy_price || 0,
            minimum_order: supplier_select.minimum_order || 0,
            lead_time: supplier_select.lead_time || 0,
            mat_cycle_time: supplier_select.mat_cycle_time || 0,
            remark: supplier_select.remark.trim(),
            sort: tempData.length + 1,
            is_active: false,
            status: 'add',
            create_date: moment(new Date()).format()
        }
        // console.log(temp)

        /*  let check = tempData.filter((e)=>{
           return e.supplier_id === temp.supplier_id && e.buy_price === temp.buy_price;
         });
         if(check.length > 0){
           Swal.fire("Error", "This supplier and buy price has aready!!!", "error");
         }else{ */
        tempData.push(temp);
        tempData.sort((a, b) => b.sort - a.sort);
        //}
        tempData.sort((a, b) => b.is_active - a.is_active);

        setSupplier_select_data(tempData);
        setsupplier_select({ ...supplier_select, id: "", buy_price: "", minimum_order: "", lead_time: "", mat_cycle_time: "", remark: "" });
        supplier_select.id = "";
        supplier_select.buy_price = "";
        supplier_select.minimum_order = "";
        supplier_select.lead_time = "";
        supplier_select.mat_cycle_time = "";
        supplier_select.remark = "";

        select_supplier.current.select.clearValue();
        // console.log(tempData)
    }

    function Edit_Suppier() {
        var tempData = supplier_select_data;
        setSupplier_select_data([]);

        //console.log(tempData[supplier_select.index]);
        tempData[supplier_select.index].supplier_id = supplier_select.id;
        tempData[supplier_select.index].buy_price = supplier_select.buy_price || 0;
        tempData[supplier_select.index].minimum_order = supplier_select.minimum_order || 0;
        tempData[supplier_select.index].lead_time = supplier_select.lead_time || 0;
        tempData[supplier_select.index].mat_cycle_time = supplier_select.mat_cycle_time || 0;
        tempData[supplier_select.index].remark = supplier_select.remark.trim();
        tempData[supplier_select.index].status = 'update';
        //console.log(tempData);
        setSupplier_select_data(tempData);
        setsupplier_select({ ...supplier_select, id: "", supplier: "", buy_price: "", mode: "", index: "", minimum_order: "", lead_time: "", mat_cycle_time: "", remark: "" });
        supplier_select.id = "";
        supplier_select.buy_price = "";
        supplier_select.minimum_order = "";
        supplier_select.lead_time = "";
        supplier_select.mat_cycle_time = "";
        supplier_select.remark = "";
        select_supplier.current.select.clearValue();
    }

    return (
        <div className="wrapper">
            {/* <Header />
        <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
            <div className="content-wrapper">
                <section className="content-header">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>
                                        Tooling {page_mode}
                                        {"  "}
                                        <a target="_blank" href={getAcademy()}>
                                            <i class="fa fa-info-circle"></i>
                                        </a>
                                    </h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="#">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Tooling</li>
                                        <li className="breadcrumb-item active">{page_mode}</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            {mode.mode === "view" ? (
                                <div className="row mb-2">
                                    <div className="col-4 col-md-3 col-xl-1">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                backPeviousPage();
                                            }}
                                            class="btn btn-block btn-danger "
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="row mb-2">
                                    <div className="col-4 col-md-3 col-xl-1">
                                        <button
                                            type="button"
                                            onClick={saveToolingApi}
                                            class="btn btn-block btn-success "
                                        >
                                            Save
                                        </button>
                                    </div>
                                    <div className="col-4 col-md-3 col-xl-1">
                                        <button
                                            type="button"
                                            onClick={() => {
                                                backPeviousPage();
                                            }}
                                            class="btn btn-block btn-danger "
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-12 col-md-6 col-xl-3">
                                    <h1>Tooling No: {toolingData.code}</h1>
                                </div>
                                <div className="col-12 col-md-6 col-xl-3">
                                    <h1>Tooling Name: {toolingData.name/* toolingData.toolingName */}</h1>
                                </div>
                            </div>
                        </div>

                        {/* /.container-fluid */}
                    </section>
                </section>
                <section className="content">
                    <div className="card card-primary card-outline card-outline-tabs">
                        <div className="card-header p-0 border-bottom-0">
                            <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="custom-tabs-four-page-1-tab" data-toggle="pill" href="#custom-tabs-four-page-1" role="tab" aria-controls="custom-tabs-four-page-1" aria-selected="true">Basic Information</a>
                                </li>
                                <li className="nav-item">
                                    {/* {mode.mode === "read" || mode.mode === "edit" ? */}
                                    <a className="nav-link" id="custom-tabs-four-page-2-tab" data-toggle="pill" href="#custom-tabs-four-page-2" role="tab" aria-controls="custom-tabs-four-page-2" aria-selected="false" >Advance Information</a>
                                    {/* } */}
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="custom-tabs-four-page-3-tab" data-toggle="pill" href="#custom-tabs-four-page-3" role="tab" aria-controls="custom-tabs-four-page-3" aria-selected="false" >Supplier</a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content" id="custom-tabs-four-tabContent">
                                <div className="tab-pane fade show active" id="custom-tabs-four-page-1" role="tabpanel" aria-labelledby="custom-tabs-four-page-1-tab">
                                    {/* ส่วนแรก */}
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-4">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={toolingData.code}
                                                    disabled={disable}
                                                    onChange={(e) =>
                                                        setToolingData({
                                                            ...toolingData,
                                                            code: e.target.value,
                                                        })
                                                    }
                                                    required
                                                />
                                                <label htmlFor="">
                                                    Tooling Code
                                                    {toolingData.code.trim() === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-4">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    disabled={disable}
                                                    value={toolingData.name}
                                                    onChange={(e) =>
                                                        setToolingData({
                                                            ...toolingData,
                                                            name: e.target.value,
                                                        })
                                                    }
                                                    required
                                                />
                                                <label htmlFor="">
                                                    Tooling Name{" "}
                                                    {toolingData.name.trim() === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-4">
                                            <div className="form-group">
                                                <select
                                                    className="custom-select select2 form-control"
                                                    value={toolingData.brand}
                                                    disabled={disable}
                                                    type="text"
                                                    required
                                                    id="brand"
                                                    onChange={(e) => {
                                                        setToolingData({
                                                            ...toolingData,
                                                            brand: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    {getBrandTooling.map((el) => {
                                                        return (
                                                            <option key={el.value} value={el.value}>
                                                                {el.label === 'Select All' ? 'Select---' : el.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <label htmlFor="">Brand
                                                    {toolingData.brand.trim() === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* Tap 2 */}

                                <div className="tab-pane fade" id="custom-tabs-four-page-2" role="tabpanel" aria-labelledby="custom-tabs-four-page-2-tab">
                                    <div className="row">
                                        <div
                                            className="col-12 col-md-8 col-xl-6"
                                            style={{ textAlign: "center" }}
                                        >
                                            <img
                                                id="img"
                                                alt="..."
                                                className="img-fluid rounded shadow border-0"
                                                src={
                                                    toolingData.image_path !== "" &&
                                                        toolingData.image_path !== null &&
                                                        toolingData.image_path !== undefined
                                                        ? Configs.API_URL_IMG + toolingData.image_path
                                                        : userdefault_img.imgs
                                                }
                                                style={{ width: "400px", height: "300px", marginBottom: '10px' }}
                                            />
                                            {/* <span style={{ color: "red", fontSize: "12px" }}>
                              Recommend Size:400x300px{" "}
                            </span> */}
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-4" style={{ marginBottom: '10px' }}>
                                            <label htmlFor="exampleInputFile">
                                                Tooling Image{" "}
                                                <span style={{ color: "red" }}>
                                                    {" "}
                                                    size(400 x 300) px
                                                </span>
                                            </label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="exampleInputFile"
                                                        disabled={disable}
                                                        accept="image/*"
                                                        onChange={setproductLogo}
                                                    />
                                                    <label
                                                        className="custom-file-label"
                                                        htmlFor="exampleInputFile"
                                                    >
                                                        {toolingData.image_name !== ""
                                                            ? toolingData.image_name
                                                            : "Select Image"}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-10 col-xl-8">
                                            <div className="form-group">
                                                {" "}
                                                <textarea
                                                    style={{ fontSize: '1.3em' }}
                                                    className="form-control"
                                                    rows={3}
                                                    placeholder="Description"
                                                    disabled={disable}
                                                    value={toolingData.description}
                                                    onChange={(e) =>
                                                        setToolingData({
                                                            ...toolingData,
                                                            description: e.target.value,
                                                        })
                                                    }
                                                    defaultValue={""}
                                                    required
                                                />
                                                <label>Tooling Description</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <div className="form-group">
                                                <NumberFormat
                                                    className="form-control"
                                                    required
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                    disabled={disable}
                                                    value={toolingData.minStock}
                                                    onChange={(e) =>
                                                        setToolingData({
                                                            ...toolingData,
                                                            minStock: e.target.value,
                                                        })
                                                    }
                                                />
                                                <label htmlFor="">Min Stock (pcs.)</label>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-xl-3">
                                            <div className="form-group">
                                                {" "}
                                                <NumberFormat
                                                    className="form-control"
                                                    required
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                    value={toolingData.maxStock}
                                                    disabled={disable}
                                                    onChange={(e) =>
                                                        setToolingData({
                                                            ...toolingData,
                                                            maxStock: e.target.value,
                                                        })
                                                    }
                                                />
                                                <label htmlFor="">Max Stock (pcs.)</label>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-xl-3">
                                            <div className="form-group">
                                                {" "}
                                                <NumberFormat
                                                    className="form-control"
                                                    required
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                    value={toolingData.consumtion}
                                                    disabled={disable}
                                                    onChange={(e) =>
                                                        setToolingData({
                                                            ...toolingData,
                                                            consumtion: e.target.value,
                                                        })
                                                    }
                                                />
                                                <label htmlFor="">Consumption (pcs/month)</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-xl-3">
                                            <div className="form-group">
                                                {" "}
                                                <NumberFormat
                                                    className="form-control"
                                                    required
                                                    allowNegative={false}
                                                    thousandSeparator={true}
                                                    value={toolingData.ratio}
                                                    disabled={disable}
                                                    onChange={(e) =>
                                                        setToolingData({
                                                            ...toolingData,
                                                            ratio: e.target.value,
                                                        })
                                                    }
                                                />
                                                <label htmlFor="">Ratio</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*************************************************** Tap 3 ****************************************************/}
                                <div className="tab-pane fade" id="custom-tabs-four-page-3" role="tabpanel" aria-labelledby="custom-tabs-four-page-3-tab">
                                    <div className="row">
                                        <div className="col-12 col-md-4 col-xl-3">
                                            <div class="form-group">
                                                <button
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#modal-add_supplier_rerationship"
                                                    //onClick={saveOption}
                                                    class="btn btn-block btn-primary "
                                                    disabled={disable}
                                                    onClick={() => {
                                                        setsupplier_select({
                                                            ...supplier_select, id: "",
                                                            supplier: "",
                                                            buy_price: "",
                                                            mode: "add",
                                                            index: "",
                                                            minimum_order: "",
                                                            lead_time: "",
                                                            remark: "",
                                                        });
                                                    }}
                                                >
                                                    Add Relationship Supplier&nbsp;
                                                    <span>
                                                        <i className="fa fa-plus-circle"></i>
                                                    </span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-12">


                                            <div className="table-responsive" style={{ whiteSpace: 'nowrap' }} /*  style={{ height: "600px" }} */ >
                                                <MDBDataTable
                                                    sortable={false}
                                                    className="table table-head-fixed"
                                                    disableRetreatAfterSorting={true}
                                                    striped
                                                    bordered
                                                    hover
                                                    fixedHeader
                                                    data={dataSupplier}
                                                />



                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal fade" id="modal-add_supplier_rerationship">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h4 className="modal-label">Add Supplier Rerationship</h4>
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">×</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-1"></div>
                                                        <div className="col-10">

                                                            <div className="form-group">
                                                                <Select
                                                                    disabled={disable}
                                                                    options={supplier_option}
                                                                    ref={select_supplier}
                                                                    value={supplier_option.filter((e) => {
                                                                        return e.label === supplier_select.supplier
                                                                    })}
                                                                    onChange={(e) => {
                                                                        //console.log(e);
                                                                        if (e !== null) {

                                                                            setsupplier_select({
                                                                                ...supplier_select,
                                                                                id: e.value || "",
                                                                                supplier: e.label || "",
                                                                            });
                                                                        }

                                                                    }}
                                                                />


                                                                <label htmlFor="">Supplier :</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-1"></div>
                                                        <div className="col-10">
                                                            <div className="form-group">
                                                                <NumberFormat
                                                                    allowNegative={false}
                                                                    type="text"
                                                                    className="form-control"
                                                                    thousandSeparator={true}
                                                                    disabled={disable}
                                                                    value={supplier_select.buy_price}
                                                                    onChange={(e) =>
                                                                        setsupplier_select({ ...supplier_select, buy_price: e.target.value })
                                                                    }
                                                                    required
                                                                />

                                                                <label htmlFor="">Buy Price :</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-1"></div>
                                                        <div className="col-10">
                                                            <div className="form-group">
                                                                <NumberFormat
                                                                    allowNegative={false}
                                                                    thousandSeparator={true}
                                                                    decimalScale={0}
                                                                    type="text"
                                                                    className="form-control"
                                                                    disabled={disable}
                                                                    value={supplier_select.minimum_order}
                                                                    onChange={(e) =>
                                                                        setsupplier_select({ ...supplier_select, minimum_order: e.target.value })
                                                                    }
                                                                    required
                                                                />

                                                                <label htmlFor="">Minimum Order :</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-1"></div>
                                                        <div className="col-10">
                                                            <div className="form-group">
                                                                <NumberFormat
                                                                    allowNegative={false}
                                                                    type="text"
                                                                    disabled={disable}
                                                                    className="form-control"
                                                                    thousandSeparator={false}
                                                                    value={supplier_select.lead_time}
                                                                    onChange={(e) =>
                                                                        setsupplier_select({ ...supplier_select, lead_time: e.target.value })
                                                                    }
                                                                    required
                                                                />

                                                                <label htmlFor="">Lead Time (Days) :</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-1"></div>
                                                        <div className="col-10">
                                                            <div className="form-group">
                                                                <input
                                                                    allowNegative={false}
                                                                    type="text"
                                                                    className="form-control"
                                                                    disabled={disable}
                                                                    value={supplier_select.remark}
                                                                    onChange={(e) =>
                                                                        setsupplier_select({ ...supplier_select, remark: e.target.value })
                                                                    }
                                                                    required
                                                                />

                                                                <label htmlFor="">Remark :</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-1"></div>
                                                    </div>





                                                    <div className="row">
                                                        <div className="col-1"></div>

                                                        <div className="col-1"></div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer justify-content-between">

                                                    {supplier_select.mode === "edit" ? (
                                                        <>
                                                            <button
                                                                disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                                                                type="button"
                                                                onClick={Edit_Suppier}
                                                                data-dismiss="modal"
                                                                className="btn btn-primary"
                                                            >
                                                                Save Change
                                                            </button>

                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                disabled={(supplier_select.id === "" || supplier_select.buy_price === "") || disable ? true : false}
                                                                type="button"
                                                                onClick={Add_Suppier}
                                                                data-dismiss="modal"
                                                                className="btn btn-primary"
                                                            >
                                                                Add
                                                            </button>

                                                        </>
                                                    )}

                                                    <button
                                                        type="button"
                                                        className="btn btn-default"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                                                    </button>


                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                {/*************************************************** End ********************************************************/}
                            </div>
                        </div>
                    </div>
                </section >
            </div >

        </div >

    )
}
export default Tooling_add;
