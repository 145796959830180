/**
* ชื่อไฟล์: historyTable.js
* คําอธิบาย: ตารางของ History
* Input: -
* Output: ประวัติการใช้งานของมอนิเตอร์
* ชื่อผู้เขียน/แก้ไข: ณัฐวุฒิ สมดุลยกนก
* วันที่จัดทํา/แก้ไข: 11 กันยายน 2566
*/

import React, { useState, useEffect } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import "../../assets/css/table.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the default styles
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { Card } from "antd";

const styleShowEntires = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  marginBottom: "1rem"
}
function HistoryTable(params) {

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  
  const rowsData = params.data;

  // console.log(rowsData);

  const [searchID, setSearchID] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");

  const [this_page, setthis_page] = useState({ page: 1 });
  const [show_entries, setshow_entries] = useState({ entries: 10 });
  const [all_page, setall_page] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);

  const renderPageButtons = () => {
    const pageButtons = [];
    const numPages = Math.ceil(filteredData.length / show_entries.entries);
  
    // Calculate the range of page numbers to display
    const startPage = Math.max(1, this_page.page - 2);
    const endPage = Math.min(numPages, startPage + 4);
  
    // Add the first page button explicitly only if not in the range
    if (startPage > 1) {
      pageButtons.push(
        <div className="col-0.5" key={1}>
          <button
            type="button"
            className={`btn btn-block ${
              1 === this_page.page ? "btn-primary" : "btn-outline-primary"
            } border`}
            onClick={() => setPage(1)}
          >
            1
          </button>
        </div>
      );
    }
  
    // Add ellipsis if necessary before the start page
    if (startPage > 2) {
      pageButtons.push(
        <div className="col-0.5" key="ellipsis1">
          <button
            type="button"
            className="btn btn-block btn-outline-primary border"
            disabled={true}
          >
            ...
          </button>
        </div>
      );
    }
  
    // Add page buttons in the calculated range
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <div className="col-0.5" key={i}>
          <button
            type="button"
            className={`btn btn-block ${
              i === this_page.page ? "btn-primary" : "btn-outline-primary"
            } border`}
            onClick={() => setPage(i)}
          >
            {i}
          </button>
        </div>
      );
    }
  
    // Add ellipsis if necessary after the end page
    if (endPage < numPages - 1) {
      pageButtons.push(
        <div className="col-0.5" key="ellipsis2">
          <button
            type="button"
            className="btn btn-block btn-outline-primary border"
            disabled={true}
          >
            ...
          </button>
        </div>
      );
    }
  
    return pageButtons;
  };
  
  
  
  const handleSearchClick = () => {
  // Create a copy of the filtered data to apply new filters
  let filteredRows = [...rowsData];

  // Apply the search criteria on the copy of filtered data
  if (searchID) {
    filteredRows = filteredRows.filter((el) =>
      el.ims_monitor_id.toString().toLowerCase().includes(searchID?.trim().toLowerCase())
    );
  }

  if (searchName) {
    filteredRows = filteredRows.filter((el) =>
      el.ims_name.toLowerCase().includes(searchName?.trim().toLowerCase())
    );
  }

  if (searchDate) {
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(searchDate);
    filteredRows = filteredRows.filter((el) =>
      el.ihs_timestamp.toLowerCase().includes(formattedDate.toLowerCase())
    );
  }

  // Set the filtered data based on the applied criteria
  setFilteredData(filteredRows);
};
  
    // Add these states to your component
    const [filteredData, setFilteredData] = useState(rowsData);
  
    const prepareDataForCSV = () => {
      const csvData = filteredData.map((el) => ({
        'Monitor Name': el.ims_name,
        'Monitor ID': el.ims_monitor_id,
        'Frequency API (minute)': el.ims_frequency_api,
        'ihs_power (W)': el.ihs_power,
        'ihs_energy (kWh)': el.ihs_energy,
        'Voltage (V)': el.ihs_voltage,
        'Current (A)': el.ihs_current,
        'Frequency (Hz)': el.ihs_frequency,
        'Add Date': el.ihs_timestamp,
      }));
    
      return csvData;
    };

    useEffect(() => {
      // Filter the data based on the global search
      const globalFilteredRows = rowsData.filter((el) => {
        // console.log("🚀 ~ file: historyTable.js:308 ~ HistoryTable ~ ihs_power:", el.ihs_power)
        // console.log("🚀 ~ file: historyTable.js:308 ~ HistoryTable ~ ihs_power:", el.ihs_energy)
        // console.log("🚀 ~ file: historyTable.js:308 ~ HistoryTable ~ ihs_power:", el.ihs_frequency)
        // console.log("🚀 ~ file: historyTable.js:308 ~ HistoryTable ~ freq:", typeof el.ihs_frequency)
        // console.log("🚀 ~ file: historyTable.js:308 ~ HistoryTable ~ ihs_power:", typeof el.ihs_power)

        let matchGlobalName = true;
        let matchGlobalID = true;
        let matchGlobalFrequencyAPI = true;
        let matchGlobalPower = true;
        let matchGlobalEnergy = true;
        let matchGlobalVoltage = true;
        let matchGlobalCurrent  = true;
        let matchGlobalFrequency = true;
        let matchGlobalDate = true;
        if (globalSearch) {
          matchGlobalName = el.ims_name.toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          matchGlobalID = el.ims_monitor_id.toString().toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          matchGlobalFrequencyAPI = el.ims_frequency_api.toString().toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          matchGlobalPower = el.ihs_power.toString().toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          if(el.ihs_energy != null)
          matchGlobalEnergy = el.ihs_energy.toString().toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          if(el.ihs_voltage != null)
          matchGlobalVoltage = el.ihs_voltage.toString().toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          if(el.ihs_current != null)
          matchGlobalCurrent = el.ihs_current.toString().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          if(el.ihs_frequency != null)
          matchGlobalFrequency = el.ihs_frequency.toString().toLowerCase().includes(globalSearch.toLowerCase());
        }
        if (globalSearch) {
          matchGlobalDate = el.ihs_timestamp.toLowerCase().includes(globalSearch.toLowerCase());
        }

        return matchGlobalName || matchGlobalID || matchGlobalFrequencyAPI || matchGlobalPower || matchGlobalEnergy ||
              matchGlobalVoltage || matchGlobalCurrent || matchGlobalFrequency || matchGlobalDate;
      });
    
      // Filter the data based on other filters

        setFilteredData(globalFilteredRows);
    }, [globalSearch, rowsData]);
  
    const clearSearchData = () => {
      setSearchID("");
      setSearchName("");
      setSearchDate("");
      setGlobalSearch("");
      setPage(1);
      setshow_entries({ entries: 10 });
      setFilteredData(rowsData);
    };
  
    var BN = 0;
    var FN = 0;
  
    function setPage(page) {
      /*  console.log("this page : ", page); */
      setthis_page({ ...this_page, page: page });
    }
  
    function previous_page() {
      let page_ = this_page.page - 1;
      if (page_ >= 1) {
        setthis_page({ ...this_page, page: page_ });
      }
    }
  
    function next_page() {
      let page_ = this_page.page + 1;
      if (page_ <= totalPageCount) {
        setthis_page({ ...this_page, page: page_ });
      }
    }
  
    function performSearch(event) {
      event.preventDefault(); // Prevent default form submission behavior
      setPage(1); // Reset to the first page
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(searchDate);
      setFilteredData(
        rowsData.filter(
          (el) =>
          el.ims_monitor_id.toLowerCase().includes(searchID.toLowerCase()) &&
          el.ims_name.toLowerCase().includes(searchName.toLowerCase()) &&
          el.date.includes(formattedDate)
        )
      );
    }
    
  
    async function setEntries(entries) {
      await setshow_entries({ ...show_entries, entries: entries });
      var page_arr = [];
      let page = 1;
  
      // console.log("showAllPage", rowsData.length);
      for (let i = 0; i < rowsData.length; i++) {
        if (Math.ceil(i / entries) >= page) {
          // console.log(Math.ceil(i / entries), page);
          let temp = {
            page: page,
          };
          page_arr.push(temp);
          page++;
        }
      }
      await setall_page(page_arr);
      await setPage(1);
    }
  
  const totalPageCount = Math.ceil(filteredData.length / show_entries.entries);


  return (
    <div>
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-2">
            <div className="form-group ">
              <input
                type="text"
                className="form-control"
                id="text_empid"
                required="false"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
              <label htmlFor="">Monitor Name</label>{" "}
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group ">
              <input
                type="text"
                className="form-control"
                id="text_empid"
                required="false"
                value={searchID}
                onChange={(e) => setSearchID(e.target.value)}
              />
              <label htmlFor="">Monitor ID</label>{" "}
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group ">
              <DatePicker
                dateFormat="dd/MM/yyyy" // Set the desired date format
                selected={searchDate}
                onChange={(date) => setSearchDate(date)}
                customInput={<CustomInput />}
                wrapperClassName="w-100"
              />  
              <label htmlFor="">Date</label>{" "}
            </div>
          </div>
          <div className="col-6 col-md-4 col-xl-2">
            <div className="form-group ">
              <button
                type="button"
                id="btn_search"
                className="btn btn-block btn-info  "
                onClick={() => handleSearchClick()}
              >
                Search
              </button>
            </div>
          </div>
          <div className="col-6 col-md-4 col-xl-2">
            <div className="form-group ">
              <button
                type="button"
                id="btn_clear"
                className="btn btn-block btn-info"
                onClick={() => clearSearchData()}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="col-6 col-md-4 col-xl-2">
            <div className="form-group ">
              <CSVLink
                data={prepareDataForCSV()}
                filename={'monitor_data.csv'}
                className="btn btn-block btn-success"
                target="_blank"
              >
                Export CSV
                <FontAwesomeIcon icon={faDownload} className="download"/>
              </CSVLink>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="col-10">
        <div className="label-filter">
          <label>Monitor Name</label>
          <label>Monitor ID</label>
          <label className="label-date">Date</label>
        </div>
        <div class="form-group">
          <form onSubmit={performSearch}>
            <div className="filter">
              <input
                type="text"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
                <input
                type="text"
                value={searchID}
                onChange={(e) => setSearchID(e.target.value)}
              />  
              // <span class="input-group-text" id="basic-addon1">@</span>
              <DatePicker   
                className="date-picker"
                dateFormat="dd/MM/yyyy" // Set the desired date format
                selected={searchDate}
                onChange={(date) => setSearchDate(date)}
              />  
              <button
                type="button" // This will trigger the form submission
                className="btn btn-primary"
                onClick={handleSearchClick}
              >
                Search
              </button>
              <button
                type="button" // This will trigger the form submission
                className="btn btn-primary"
                onClick={clearSearchData}
              >
                Clear
              </button>
            </div>
          </form>
          <CSVLink
            data={prepareDataForCSV()}
            filename={'monitor_data.csv'}
            className="btn btn-success"
            target="_blank"
          >
            Export CSV
            <FontAwesomeIcon icon={faDownload} className="download"/>
          </CSVLink>
        </div>
      </div> */}
      <Card>
        <div className="table-frame">
          <div className="selected-entries" style={styleShowEntires}>
            <div className="entries">
              <label htmlFor="">Show entries</label><br/>
              <select
                  className="form-control custom-select select2"
                  type="text"
                  required
                  onChange={(e) => setEntries(e.target.value)}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={rowsData.length}>{rowsData.length} (ALL)</option>
              </select>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <form>
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={globalSearch}
                onChange={(e) => setGlobalSearch(e.target.value)}
                style={{ marginRight: "1.5rem" }}
              />
              </form>
            </div>
          </div>
          

        <div className="table-responsive">
          <MDBTable
            className="table"
            striped
            bordered
            hover
            // fixedHeader
          >
            <MDBTableHead>
              <tr>
                <th style={{ textAlign: "center"}}>No</th>
                <th style={{ textAlign: "center"}}>Monitor Name</th>
                <th style={{ textAlign: "center"}}>Monitor ID</th>
                <th style={{ textAlign: "center"}}>Frequency API (minute)</th>
                <th style={{ textAlign: "center"}}>Power (W)</th>
                <th style={{ textAlign: "center"}}>Energy (kWh)</th>
                <th style={{ textAlign: "center"}}>Voltage (V)</th>
                <th style={{ textAlign: "center"}}>Current (A)</th>
                <th style={{ textAlign: "center"}}>Frequency (Hz)</th>
                <th style={{ textAlign: "center"}}>Add Date</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {filteredData.slice((this_page.page - 1) * show_entries.entries, this_page.page * show_entries.entries)
                .map((el, index) => {
                  const startIndex = (this_page.page - 1) * show_entries.entries;
                  const endIndex = Math.min(startIndex + show_entries.entries, filteredData.length);
                  BN = startIndex + 1;
                  FN = endIndex;
                  const dataIndex = (this_page.page - 1) * show_entries.entries + index;
                  return (
                    <tr key={dataIndex}>
                      <td style={{ textAlign: "center" }}>{dataIndex + 1}</td>
                      <td style={{ textAlign: "center" }}>{el.ims_name}</td>
                      <td style={{ textAlign: "center" }}>{el.ims_monitor_id}</td>
                      <td style={{ textAlign: "center" }}>{el.ims_frequency_api}</td>
                      <td style={{ textAlign: "center" }}>{el.ihs_power}</td>
                      <td style={{ textAlign: "center" }}>{el.ihs_energy}</td>
                      <td style={{ textAlign: "center" }}>{el.ihs_voltage}</td>
                      <td style={{ textAlign: "center" }}>{el.ihs_current}</td>
                      <td style={{ textAlign: "center" }}>{el.ihs_frequency}</td>
                      <td style={{ textAlign: "center" }}>{el.ihs_timestamp}</td>
                      {/* <td style={{ textAlign: "center" }}>{dataIndex + 1}</td>
                      <td style={{ textAlign: "center" }}>{el.monitor_name}</td>
                      <td style={{ textAlign: "center" }}>{el.monitor_id}</td>
                      <td style={{ textAlign: "center" }}>{el.frequency_api}</td>
                      <td style={{ textAlign: "center" }}>{el.power}</td>
                      <td style={{ textAlign: "center" }}>{el.energy}</td>
                      <td style={{ textAlign: "center" }}>{el.voltage}</td>
                      <td style={{ textAlign: "center" }}>{el.current}</td>
                      <td style={{ textAlign: "center" }}>{el.frequency}</td>
                      <td style={{ textAlign: "center" }}>{el.date}</td> */}
                    </tr>
                  );
                })}
            </MDBTableBody>
            <MDBTableHead>
              <tr>
                <th style={{ textAlign: "center"}}>No</th>
                <th style={{ textAlign: "center"}}>Monitor Name</th>
                <th style={{ textAlign: "center"}}>Monitor ID</th>
                <th style={{ textAlign: "center"}}>Frequency API (minute)</th>
                <th style={{ textAlign: "center"}}>Power (W)</th>
                <th style={{ textAlign: "center"}}>Energy (kWh)</th>
                <th style={{ textAlign: "center"}}>Voltage (V)</th>
                <th style={{ textAlign: "center"}}>Current (A)</th>
                <th style={{ textAlign: "center"}}>Frequency (Hz)</th>
                <th style={{ textAlign: "center"}}>Add Date</th>
              </tr>
            </MDBTableHead>
          </MDBTable>
        </div>

        <div className="row b">
          <div className="col-5">
            <p>Showing {BN} to {FN} of {filteredData.length} entries</p>
          </div>
          <div className="paginate-iot">
          <div className="col-0.5">
            <button
              type="button"
              className="btn btn-block btn-outline-primary border"
              onClick={previous_page}
              disabled={this_page.page === 1}
            >
              Previous
            </button>
          </div>

          {renderPageButtons()}

          <div className="col-0.5">
            <button
              type="button"
              className="btn btn-block btn-outline-primary border"
              onClick={next_page}
              disabled={this_page.page === totalPageCount}
            >
              Next
            </button>
          </div>
          </div>
        </div>
      </div>
    </Card>

      
    </div>
  );
}

export default HistoryTable;