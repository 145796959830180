import React, { Component, useEffect, useState, useRef } from "react";
// import Footter from "../../component/Footter";
// import Header from "../../component/Header";
// import sortJsonArray from "sort-json-array";
// import Sidebar from "../../component/Sidebar";
// import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import moment from "moment";
import { useParams } from "react-router";
import Select from "react-select";

function Mat_stc(params) {
  const [mat_type, setmat_type] = useState([]);
  const [company, setCompany] = useState([]);
  const [GetMatSTC, setMatSTC] = useState([]);
  /* console.log(getUser().com); */

  const [nowaday_year, setNowaday_year] = useState(moment(new Date()).year());

  const [nowaday_mounth, setNowaday_mounth] = useState(
    moment(new Date()).month()
  );
  const [MatStc_select, setMatSTC_select] = useState({
    company_id: getUser().com,
    year: "",
    month: "",
    oem_id: getOem(),
  });
  const [filler_mat_type, setFiller_mat_type] = useState({
    company_id: getUser().com,
    year: "",
    month: "",
    oem_id: getOem(),
    //  company_id: getUser().com,
  });
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const select_supplier = useRef();
  const [year, setYear] = useState([
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
    { label: "2026", value: 2026 },
    { label: "2026", value: 2026 },
    { label: "2027", value: 2027 },
    { label: "2028", value: 2028 },
    { label: "2029", value: 2029 },
    { label: "2030", value: 2030 },
  ]);

  const [mounth, setMounth] = useState([
    { label: "มกราคม", value: 1 },
    { label: "กุมภาพันธ์", value: 2 },
    { label: "มีนาคม", value: 3 },
    { label: "เมษายน", value: 4 },
    { label: "พฤษภาคม", value: 5 },
    { label: "มิถุนายน", value: 6 },
    { label: "กรกฎาคม", value: 7 },
    { label: "สิงหาคม", value: 8 },
    { label: "กันยายน", value: 9 },
    { label: "ตุลาคม", value: 10 },
    { label: "พฤศจิกายน", value: 11 },
    { label: "ธันวาคม", value: 12 },
  ]);

  const [supplier_select_data, setSupplier_select_data] = useState([]);

  function Add_Suppier() {
    const error_list = [];

    if (filler_mat_type.month == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Month].",
      };
      error_list.push(temp_err);
    }
    if (filler_mat_type.year == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Year].",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      setSupplier_select_data([]);
      let temp = {
        company_id: filler_mat_type.company_id,
        month: filler_mat_type.month,
        year: filler_mat_type.year,
        oem_id: filler_mat_type.oem_id,
      };
      console.log("แอด supp", temp);
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/materialSTC/addmaterialSTC",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                // window.location.href =
                // "/Warehouse_Management/Mat_stc";
                GetMat_stc();
              });
            }
            console.log("เช็คออด", response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    }
    // tempData.push(temp);
    // tempData.sort((a,b)=>b.sort - a.sort);
    setMatSTC_select({
      ...filler_mat_type,
      company_id: getUser().com,
      month: "",
      year: "",
      oem_id: getOem(),
    });
    filler_mat_type.company_id = getUser().com;
    filler_mat_type.year = "";
    filler_mat_type.month = "";
    filler_mat_type.oem_id = getOem();
  }

  // function Edit_Suppier(){
  //   var tempData = supplier_select_data;
  //   setSupplier_select_data([]);

  //   console.log(tempData[filler_mat_type.index]);
  //   tempData[filler_mat_type.index].month = filler_mat_type.month;
  //   tempData[filler_mat_type.index].year = filler_mat_type.year;

  //   console.log(tempData);
  //   setSupplier_select_data(tempData);
  //   setsupplier_select({...filler_mat_type,month:"",year:""});

  //   filler_mat_type.month = "";
  //   filler_mat_type.year = "";
  // }

  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function GetMat_stc() {
 
    axios({
      method: "post",
      url: Configs.API_URL + "/materialSTC/getMat_Stc_Month_year",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: MatStc_select,
    })
      .then(function (response) {
        setMatSTC(response.data);
        console.log("Axios เช็ค", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetMat_stc();
    GetCompany();
    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
          if (el.id === getOem()) {
            setCurrentOEM({ label: el.name, value: el.id });
          }
        });

        setCompany(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // console.log(id);
  const clearFilter = async () => {
    await setMatSTC_select({
      ...MatStc_select,
      year: "",
      month: "",
    });
    MatStc_select.year = "";
    MatStc_select.month = "";
    GetMat_stc();
  };

  function addpage(params) {
    window.location.href = "/Warehouse_Management/Mat_stc/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/supplier/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href = "/Warehouse_Management/Mat_stc";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const clickclear = async () => {
    await setFiller_mat_type({
      ...filler_mat_type,
      year: "",
      month: "",
    });
    filler_mat_type.year = "";
    filler_mat_type.month = "";
    GetMat_stc();
  };
  function addpage(params) {
    window.location.href = "/Warehouse_Management/Mat_stc/add";
  }

  const rowsData = [];

  for (var index = 0; index < GetMatSTC.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["year"] = GetMatSTC[index].year;

    if (GetMatSTC[index].month == 1) {
      rowItem["month"] = "มกราคม";
    } else if (GetMatSTC[index].month == 2) {
      rowItem["month"] = "กุมภาพันธ์";
    } else if (GetMatSTC[index].month == 3) {
      rowItem["month"] = "มีนาคม";
    } else if (GetMatSTC[index].month == 4) {
      rowItem["month"] = "เมษายน";
    } else if (GetMatSTC[index].month == 5) {
      rowItem["month"] = "พฤษภาคม";
    } else if (GetMatSTC[index].month == 6) {
      rowItem["month"] = "มิถุนายน";
    } else if (GetMatSTC[index].month == 7) {
      rowItem["month"] = "กรกฎาคม";
    } else if (GetMatSTC[index].month == 8) {
      rowItem["month"] = "สิงหาคม";
    } else if (GetMatSTC[index].month == 9) {
      rowItem["month"] = "กันยายน";
    } else if (GetMatSTC[index].month == 10) {
      rowItem["month"] = "ตุลาคม";
    } else if (GetMatSTC[index].month == 11) {
      rowItem["month"] = "พฤศจิกายน";
    } else if (GetMatSTC[index].month == 12) {
      rowItem["month"] = "ธันวาคม";
    }

    // rowItem["month"] = GetMatSTC[index].month;
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-md-1 col-ms-1 col-lg-1">
          {getOem() === currentOEM.value && currentOEM.label ==="บจก.เอสที คอนกรีตโปรดักส์" ? (
            <>
              <a
                href={
                  "/Warehouse_Management/Mat_stc/edit/" +
                  GetMatSTC[index].id +
                  "/" +
                  GetMatSTC[index].year +
                  GetMatSTC[index].month
                }
                key={GetMatSTC[index].id}
                className=" btn btn-xs "
              >
                {" "}
                <i class="fas fa-pencil-alt"></i>{" "}
              </a>
            </>
          ) : (
            <> <>
            <a
              href={
                "/Warehouse_Management/Mat_stc/read/" +
                GetMatSTC[index].id +
                "/" +
                GetMatSTC[index].year +
                GetMatSTC[index].month
              }
              key={GetMatSTC[index].id}
              className=" btn btn-xs "
            >
              {" "}
              <i class="fas fa-eye"></i>{" "}
            </a>
          </></>
          )}
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ปี",
        field: "year",
        sort: "asc",
        width: 50,
      },
      {
        label: "เดือน",
        field: "month",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mgt",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>วัตถุดิบ STC{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  {/*  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li> */}
                  <li className="breadcrumb-item active">วัตถุดิบ STC</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={MatStc_select.year}
                    onChange={(e) => {
                      setMatSTC_select({
                        ...MatStc_select,
                        year: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {year.map((el) => {
                      return (
                        <option
                          value={el.value}

                          /* selected={
                             nowaday_year === el.value ? true : false
                          } */
                        >
                          {el.label}
                        </option>
                      );
                    })}
                  </select>

                  <label htmlFor="">ปี</label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={MatStc_select.month}
                    onChange={(e) => {
                      setMatSTC_select({
                        ...MatStc_select,
                        month: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {mounth.map((el) => {
                      return (
                        <option
                          value={el.value}
                          /* selected={
                             nowaday_mounth === el.value ? true : false
                          } */
                        >
                          {el.label}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">เดือน</label>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={GetMat_stc}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
           <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  {getOem() === currentOEM.value && currentOEM.label ==="บจก.เอสที คอนกรีตโปรดักส์"? (
                    <>
                      <button
                        type="button"
                        id="txt"
                        data-toggle="modal"
                        data-target="#modal-add_supplier_rerationship"
                        onClick={clickclear}
                        class="btn btn-block btn-primary "
                        onChange={(e) => {
                          filler_mat_type({
                            ...setFiller_mat_type,
                            company_id: "",
                            month: "",
                            years: "",
                          });
                        }}
                      >
                        Add
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Express</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "   style={{ whiteSpace: 'nowrap' }} 
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>

            <div className="modal fade" id="modal-add_supplier_rerationship">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">Add</h4>

                    <button
                      // onClick={clickclear}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-1 col-sm-1 col-lg-1"></div>
                      <div className="col-md-10 col-sm-10 col-lg-10">
                        <div className="form-group">
                          <select
                            className="custom-select select2 form-control"
                            value={filler_mat_type.year}
                            onChange={(e) => {
                              setFiller_mat_type({
                                ...filler_mat_type,
                                year: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select</option>
                            {year.map((el) => {
                              return (
                                <option
                                  value={el.value}
                                  /* selected={
                                     nowaday_year === el.value
                                      ? true
                                      : false
                                  } */
                                >
                                  {el.label}
                                </option>
                              );
                            })}
                          </select>
                          <label htmlFor="">ปี :</label>
                        </div>
                      </div>
                      <div className="col-md-1 col-sm-1 col-lg-1"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-1 col-sm-1 col-lg-1"></div>
                      <div className="col-md-10 col-sm-10 col-lg-10">
                        <div className="form-group">
                          <select
                            className="custom-select select2 form-control"
                            value={filler_mat_type.month}
                            onChange={(e) => {
                              setFiller_mat_type({
                                ...filler_mat_type,
                                month: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select</option>
                            {mounth.map((el) => {
                              return (
                                <option
                                  value={el.value}
                                  /*  selected={
                                      nowaday_mounth === el.value
                                      ? true
                                      : false
                                  } */
                                >
                                  {el.label}
                                </option>
                              );
                            })}
                          </select>
                          <label htmlFor="">เดือน</label>
                        </div>
                      </div>
                      <div className="col-md-1 col-sm-1 col-lg-1"></div>
                    </div>

                    <div className="row">
                      <div className="col-md-1 col-sm-1 col-lg-1"></div>

                      <div className="col-md-1 col-sm-1 col-lg-1"></div>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-between">
                    {filler_mat_type.mode === "edit" ? (
                      <>
                        <button
                          //  disabled={filler_mat_type.id === "" || filler_mat_type.buy_price === "" ? true : false}
                          type="button"
                          // onClick={Edit_Suppier}
                          data-dismiss="modal"
                          className="btn btn-primary"
                        >
                          Save Change
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          //disabled={supplier_select.id === "" || supplier_select.buy_price === "" ? true : false}
                          type="button"
                          onClick={Add_Suppier}
                          data-dismiss="modal"
                          className="btn btn-primary"
                        >
                          Add
                        </button>
                      </>
                    )}

                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={() => clickclear()}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*  <Footter/> */}
    </div>
  );
}

export default Mat_stc;
