import React, { useState, useEffect } from 'react'
import detailImg from '../../assets/img/Dashboard/details.svg'

const DetailIndicator = ({ title, detail, unit }) => {

    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [avg, setAvg] = useState(0);


    useEffect(() => {

      const formatData = (data) => {
        if(data){
          if (data >= 1e9) {
            return (data / 1e9).toFixed(2) + 'B';
          } else if (data >= 1e6) {
            return (data / 1e6).toFixed(2) + 'M';
          } else if (data >= 1e3) {
            return (data / 1e3).toFixed(2) + 'K';
          } else {
            return data.toFixed(2);
          }
        } else {
          return (0).toFixed(2);
        }
      };

      if(detail){
        setMin(formatData(detail.min));
        setMax(formatData(detail.max));
        setAvg(formatData(detail.avg));
      } else {
        setMin(0.0);
        setMax(0.0);
        setAvg(0.0);
      }
      

      }, [detail]);

    return (
        <div>
        <div className="card-list px-3">
            <div className="card-headers">
              <div className="d-flex title-type mb-2">
                <img src={detailImg} alt="" />
                <h2 className='mx-1 my-0'> {title} details</h2>
              </div>
              <div className="monitor-list pb-1" style={{ borderBottom: '1px solid #777777'}}>
                <p className='m-0'>Type</p>
                <p className='m-0'>Value</p>
              </div>
              {/* <hr/> */}
            </div>
            <div className="listt">
              
              <div className="monitor-list">
                <p className='m-0'>Minimum</p>
                <div class="list-value">{min} {unit}</div>
              </div>
              <div className="monitor-list">
                <p className='m-0'>Maximum</p>
                <div class="list-value">{max} {unit}</div>
              </div>
              <div className="monitor-list">
                <p className='m-0'>Average</p>
                <div class="list-value">{avg} {unit}</div>
              </div>
              
            </div>
        </div>
      </div>
    );
};

export default DetailIndicator;