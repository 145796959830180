import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import "./ComponentWH.css";
import axios from "axios";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Configs from "../../../../config";
import AxisInput from "./AxisInput";
import ModalDisplayProduct from "./ModalDisplayProduct";

function ConfigWarehouse({
  rackData,
  setrackData,
  switchBtn,
  setswitchBtn,
  setwarehouseData,
  warehouseData,
  setSizeRoom,
  sizeRoom,
  initialData,
  animationFrameId,
  GAP,
  position,
  functionComponent,
  setFunctionComponent,
  useZAxis,
  setUseZAxis,
  actionListRack,
  setActionListRack,
  dataOfWarehouse,
  setDataOfWarehouse,
  viewOnly,
  NOT_USE_NOW,
  modelType,
  actionActive,
  showRackPopup
}) {
  const [isEdit, setIsEdit] = useState({
    wh_edit: false,
    rack_edit: false,
  });

  async function saveRack() {
    Swal.fire({
      title: `ต้องการที่จะ${
        switchBtn.mode == "edit_rack" ? "แก้ไข" : "เพิ่ม"
      } Rack ใช่หรือไม่`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = checkErrorRack(switchBtn.mode);
        if (error_list.length > 0) {
          Swal.fire(
            `${
              switchBtn.mode == "edit_rack" ? "แก้ไข" : "เพิ่ม"
            } Rack ไม่สำเร็จ`,
            error_list.join("<br />"),
            "error"
          );
          return;
        }

        if (switchBtn.mode == "edit_rack") {
          await ApiSaveRackByWHId(rackData, warehouseData.id, "edit");
        } else {
          await ApiSaveRackByWHId(rackData, warehouseData.id, "add");
        }
      }
    });
  }

  async function ApiSaveRackByWHId(rackData, wh_id, mode) {
    if (
      rackData.rack_id &&
      actionListRack.data[rackData.rack_id].actionList.includes("scale")
    ) {
      const temp = { ...actionListRack.data };
      temp[rackData.rack_id].actionList.splice(
        temp[rackData.rack_id].actionList.indexOf("scale"),
        1
      );
      setActionListRack({ ...actionListRack, data: { ...temp } });
    }
    const data = {
      rackData,
      wh_id,
      useZAxis,
      position_list: positionRack.data,
      oem_id: getOem(),
      user: getUser().name + " " + getUser().surname,
      fup: getUser().fup,
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_AIOI_Back +
        `/api/warehouse/${
          mode == "edit" ? "editRackOfWarehouse3D" : "addRackOfWarehouse3D"
        }`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async function (response) {
        if (response.data.data && !response.data.data.error) {
          Swal.fire({
            icon: "success",
            title: mode == "edit" ? "Edit" : "Save",
            showConfirmButton: false,
            timer: 1500,
          });
          clearRackInput();
          if (functionComponent.GetWarehouseAPI) {
            await functionComponent.GetWarehouseAPI(wh_id);
          }
        } else {
          Swal.fire("Error", response.data.data.error.message, "error");
        }
      })
      .catch(function (error) {
        console.log("error", error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  async function generateRoom(paramSizeRoom, rackList, positionListParams, is_change_wh) {
    const _size = paramSizeRoom ? paramSizeRoom : sizeRoom;
    setwarehouseData((prev) => ({
      ...prev,
      width: Number(_size.width_input || 1),
      height: Number(_size.height_input || 1),
      depth: Number(_size.depth_input || 1),
    }));
    warehouseData.width = Number(_size.width_input || 1);
    warehouseData.height = Number(_size.height_input || 1);
    warehouseData.depth = Number(_size.depth_input || 1);

    let unit =
      warehouseData.unit == "mm." ? 0.01 : warehouseData.unit == "m." ? 1 : 0.1;

    cancelAnimationFrame(animationFrameId);
    await initialData(
      warehouseData.width * unit,
      warehouseData.height * unit,
      warehouseData.depth * unit,
      undefined,
      rackList,
      positionListParams,
      null,
      is_change_wh
    );
  }
  function handleInputChange(value, setState, state, key, editPosition) {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
    state[key] = value;

    if (editPosition && rackData.axis_x_input && rackData.axis_y_input) {
      handlePositionOfRack();
    }
  }
  // console.log('rackData in Config WH compo', rackData);

  const [positionRack, setPositionRack] = useState({
    data: [],
  });

  function handlePositionOfRack() {
    let positionListTemp = [];
    let runIdIndex = 0;
    for (let x = 0; x < rackData.axis_x_input; x++) {
      for (let y = 0; y < rackData.axis_y_input; y++) {
        if (useZAxis.is_use) {
          for (let z = 0; z < rackData.axis_z_input; z++) {
            let pos = generatePosition(x, y, z);

            positionListTemp.push({
              rp_id:
                rackData.position_list.length > runIdIndex
                  ? rackData.position_list[runIdIndex]?.rp_id
                  : null,
              pos_id: "uuid_fake" + pos,
              pos_name: pos,
              pos_prod_list: [],
            });
            runIdIndex++;
          }
        } else {
          let pos = generatePosition(x, y);

          positionListTemp.push({
            rp_id:
              rackData.position_list.length > runIdIndex
                ? rackData.position_list[runIdIndex]?.rp_id
                : null,
            pos_id: "uuid_fake" + pos,
            pos_name: pos,
            pos_prod_list: [],
          });
          runIdIndex++;
        }
      }
    }

    setDataOfWarehouse({
      ...dataOfWarehouse,
      position: [...positionListTemp],
    });
    dataOfWarehouse.position = [...positionListTemp];

    setPositionRack({ ...positionRack, data: positionListTemp });
    positionRack.data = positionListTemp;
  }

  function generatePosition(x, y, z) {
    let firstChar = getPositionChar(rackData.axis_x_pos_input, x);
    let secondChar = getPositionChar(rackData.axis_y_pos_input, y);
    let thirdChar =
      z !== undefined ? getPositionChar(rackData.axis_z_pos_input, z) : "";

    return (
      firstChar?.toString() +
      (rackData.axis_x_chr_input || "") +
      secondChar?.toString() +
      (rackData.axis_y_chr_input || "") +
      thirdChar?.toString() +
      (z !== undefined ? rackData.axis_z_chr_input || "" : "")
    );
  }

  function getPositionChar(format, value) {
    if (format == "2") {
      return value + 1;
    } else if (format == "1") {
      return numberToAlphabet(value + 1);
    } else {
      return "";
    }
  }

  function numberToAlphabet(num) {
    let result = "";
    while (num > 0) {
      const remainder = (num - 1) % 26; // Adjusting for 1-based indexing
      result = String.fromCharCode(65 + remainder) + result; // 65 is ASCII code for 'A'
      num = Math.floor((num - 1) / 26); // Adjusting for 1-based indexing
    }
    return result;
  }

  function clearWarehouseInput(isCancel) {
    setSizeRoom({
      ...sizeRoom,
      width_input: sizeRoom.width || 36,
      height_input: sizeRoom.height || 24,
      depth_input: sizeRoom.depth || 12,
      name_input: sizeRoom.name || null,
    });
    sizeRoom.width_input = sizeRoom.width || 36;
    sizeRoom.height_input = sizeRoom.height || 24;
    sizeRoom.depth_input = sizeRoom.depth || 12;
    sizeRoom.name_input = sizeRoom.name || null;
  }

  function clearRackInput(isCancel) {
    setrackData({
      ...rackData,
      rack_name_input: isCancel ? rackData.rack_name : "",
      width_input: isCancel ? rackData.width : 0,
      depth_input: isCancel ? rackData.depth : 0,
      height_input: isCancel ? rackData.height : 0,
      axis_x_input: isCancel ? rackData.axis_x : 0,
      axis_x_pos_input: isCancel ? rackData.axis_x_pos : 1,
      axis_x_chr_input: isCancel ? rackData.axis_x_chr : "",
      axis_y_input: isCancel ? rackData.axis_y : 0,
      axis_y_pos_input: isCancel ? rackData.axis_y_pos : 1,
      axis_y_chr_input: isCancel ? rackData.axis_y_chr : "",
      axis_z_input: isCancel ? rackData.axis_z : 0,
      axis_z_pos_input: isCancel ? rackData.axis_z_pos : 1,
      axis_z_chr_input: isCancel ? rackData.axis_z_chr : "",
    });
    rackData.rack_name_input = isCancel ? rackData.rack_name : "";
    rackData.width_input = isCancel ? rackData.width : 0;
    rackData.depth_input = isCancel ? rackData.depth : 0;
    rackData.height_input = isCancel ? rackData.height : 0;
    rackData.axis_x_input = isCancel ? rackData.axis_x : 0;
    rackData.axis_x_pos_input = isCancel ? rackData.axis_x_pos : 1;
    rackData.axis_x_chr_input = isCancel ? rackData.axis_x_chr : "";
    rackData.axis_y_input = isCancel ? rackData.axis_y : 0;
    rackData.axis_y_pos_input = isCancel ? rackData.axis_y_pos : 1;
    rackData.axis_y_chr_input = isCancel ? rackData.axis_y_chr : "";
    rackData.axis_z_input = isCancel ? rackData.axis_z : 0;
    rackData.axis_z_pos_input = isCancel ? rackData.axis_z_pos : 1;
    rackData.axis_z_chr_input = isCancel ? rackData.axis_z_chr : "";
  }

  function handleSwitchBtn(key) {
    const _toggle = !switchBtn[key];
    setswitchBtn({
      ...switchBtn,
      [key]: _toggle,
      [key == "wh" ? "rack" : "wh"]: _toggle ? false : undefined,
    });
  }

  function handleIsEdit(value, key) {
    setIsEdit({
      ...isEdit,
      [key]: value,
    });
    isEdit[key] = value;
  }

  function handleSelectUnit(unit, of) {
    if (of == "wh") {
      setwarehouseData({
        ...warehouseData,
        unit,
      });
      warehouseData.unit = unit;
    } else {
      setrackData({
        ...rackData,
        unit,
      });
      rackData.unit = unit;
    }
  }

  function checkErrorRack() {
    const error_list = [];
    /* if (!rackData.rows) {
      error_list.push("กรุณากรอก Rows");
    }
    if (!rackData.columns) {
      error_list.push("กรุณากรอก Columns");
    } */
    if (!rackData.rack_name_input?.trim()) {
      error_list.push("กรุณากรอก Rack Name");
    }
    if (!Number(rackData.width_input)) {
      error_list.push("กรุณากรอก Width");
    }
    if (!Number(rackData.depth_input)) {
      error_list.push("กรุณากรอก Depth");
    }
    if (!Number(rackData.height_input)) {
      error_list.push("กรุณากรอก Height");
    }

    if (!Number(rackData.axis_x_input)) {
      error_list.push("กรุณากรอก Axis-x");
    }
    if (!rackData.axis_x_pos_input) {
      error_list.push("กรุณากรอก Position Format");
    }
    /* if (!rackData.axis_x_chr_input) {
      error_list.push("กรุณากรอก Se");
    } */

    if (!Number(rackData.axis_y_input)) {
      error_list.push("กรุณากรอก Axis-y");
    }
    if (!rackData.axis_y_pos_input) {
      error_list.push("กรุณากรอก Position Format");
    }
    /* if (!rackData.axis_y_chr_input) {
      error_list.push("กรุณากรอก Height");
    } */

    if (useZAxis.is_use) {
      if (!Number(rackData.axis_z_input)) {
        error_list.push("กรุณากรอก Axis-z");
      }
      if (!rackData.axis_z_pos_input) {
        error_list.push("กรุณากรอก Position Format");
      }
      /* if (!rackData.axis_z_chr_input) {
        error_list.push("กรุณากรอก Height");
      } */
    } else {
      setrackData({
        ...rackData,
        axis_z_input: 0,
        axis_z_pos_input: 1,
        axis_z_chr_input: "",
      });
      rackData.axis_z_input = 0;
      rackData.axis_z_pos_input = 1;
      rackData.axis_z_chr_input = "";
    }
    /* console.log('rackData.width_input', rackData.width_input);
    console.log('sizeRoom.width', sizeRoom.width)
    console.log('rackData.width_input > sizeRoom.width', rackData.width_input > sizeRoom.width); */
    if (switchBtn.mode == "edit_rack" || switchBtn.mode == "add_rack") {
      if (Number(rackData.width_input) > Number(sizeRoom.width)) {
        error_list.push("กรุณากรอก Width ให้ต่ำกว่า Width ของ Warehouse");
      }
      if (Number(rackData.depth_input) > Number(sizeRoom.depth)) {
        error_list.push("กรุณากรอก Depth ให้ต่ำกว่า Depth ของ Warehouse");
      }
      if (Number(rackData.height_input) > Number(sizeRoom.height)) {
        error_list.push("กรุณากรอก Height ให้ต่ำกว่า Height ของ Warehouse");
      }
    }

    return error_list;
  }

  const [modalDisplayProduct, setModalDisplayProduct] = useState({
    is_open: false,
    data: [],
    rackData: {},
    capacity_of_position: [],
    wh_name: "",
  });

  function SumAmountAndGroupLogPOS(temp2, pos_param) {
    const temp = [];
    const obj_dup = {};
    let runIndex = 0;

    for (let item of dataOfWarehouse.log_list) {
      if (item.lp_rp_id == pos_param.rp_id && !obj_dup[item.product_id]) {
        obj_dup[item.product_id] = {
          product_id: item.product_id,
          index: runIndex,
        };

        item.sum_amount = item.lp_amount;
        temp.push({ ...item });
        runIndex++;
      } else if (item.lp_rp_id == pos_param.rp_id && obj_dup[item.product_id]) {
        // console.log('mber(item.lp_amount)', Number(item.lp_amount));
        temp[obj_dup[item.product_id].index].sum_amount =
          Number(temp[obj_dup[item.product_id].index].sum_amount) +
          Number(item.lp_amount);
        // console.log('Number(temp[obj_dup[item.product_id].index].sum_amount)', Number(temp[obj_dup[item.product_id].index].sum_amount));
      }
    }
    return temp;
  }

  function GetColorOfPositionProduct(pos_param) {
    /* const prod_in_position = rackData.rack_list
      ?.find((_item) => _item.rack_id === rackData.rack_id)
      ?.position_list?.find((_item) => _item.rp_id == pos_param.rp_id)
      ?.product || [];
    if (prod_in_position.length === 0) return "secondary";
    
    const temp = [ ...SumAmountAndGroupLogPOS([], pos_param) ];

    if (!temp.length) return "success";

    const pos_isnt_full = prod_in_position.some(_item => 
      temp.find(_item2 => _item2.product_id === _item.id && Number(_item2.sum_amount) !== Number(_item.quantity))
      );

    return pos_isnt_full ? "warning" : "danger"; */

    const temp = [...SumAmountAndGroupLogPOS([], pos_param)];

    const prod_in_position =
      rackData.rack_list
        ?.find((_item) => _item.rack_id === rackData.rack_id)
        ?.position_list?.find((_item) => _item.rp_id == pos_param.rp_id)
        ?.product || [];

    const pos_isnt_full = prod_in_position.every((_item) =>
      temp.find(
        (_item2) =>
          _item2.product_id === _item.id &&
          _item?.quantity &&
          Number(_item2.sum_amount) == Number(_item?.quantity)
      )
    );
    let str = ""

    if (prod_in_position.length > 0) str = 'success';
    else if (prod_in_position.length === 0) str = 'secondary';
    
    if (temp.length > 0) str = "warning";
    if (pos_isnt_full && prod_in_position.length > 0 && temp.length === prod_in_position.length) str = "danger";

    return str;
  }

  useEffect(() => {
    setFunctionComponent({
      ...functionComponent,
      generateRoom: generateRoom,
    });
    functionComponent.generateRoom = generateRoom;

    if (rackData.is_select_rack) {
      // console.log('useEffect rackData in config wh comp', rackData);
      handleInputChange(false, setrackData, rackData, "is_select_rack", true);
    }
  }, [rackData.is_select_rack, warehouseData.id]);

  const bottomRef = React.useRef(null);

  useEffect(() => {
    if (viewOnly && switchBtn.rack) {
      bottomRef.current.scrollTo({
        top: 680 /* bottomRef.current.scrollHeight */,
        behavior: "smooth",
      });
    }
  }, [switchBtn.rack]);

  useEffect(() => {
    if (switchBtn.mode == "add" || switchBtn.mode == "edit") {
      handleIsEdit(true, "wh_edit");
    } else if (switchBtn.mode == "select") {
      handleIsEdit(false, "wh_edit");
      handleIsEdit(false, "rack_edit");
    } else if (switchBtn.mode == "add_rack" || switchBtn.mode == "edit_rack") {
      handleIsEdit(true, "rack_edit");
    }
  }, [switchBtn.mode]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: "999",
          ...position,
          gap: "5px",
        }}
      >
        {/* Warehouse */}
        <div hidden={viewOnly} className="bg-popup-main-wh3d mb-3">
          <div className="bg-popup-wh3d-bg">
            <div className="bg-popup-wh3d">
              <div className="icon-wh3d">
                <div className="icon-bg-wh3d">
                  <i class="	fas fa-warehouse"></i>
                </div>
              </div>
              <div className="bg-popup-wh3d-header">
                <h3 className="bg-popup-wh3d-header-text">Warehouse</h3>
                {switchBtn.wh ? (
                  <i
                    class="	far fa-arrow-alt-circle-down"
                    onClick={() => handleSwitchBtn("wh")}
                  ></i>
                ) : (
                  <i
                    class="	far fa-arrow-alt-circle-up"
                    onClick={() => handleSwitchBtn("wh")}
                  ></i>
                )}
                {/* 	far fa-arrow-alt-circle-up */}
              </div>
            </div>
            {switchBtn.wh ? (
              <div className="bg-popup-wh3d-details">
                <div className="p-1 pr-3 mt-2 row align-items-center">
                  <div className="col-12">
                    <div class="form-group mb-0 pb-0">
                      <input
                        placeholder="Warehouse Name"
                        className={`pl-1 popup-input-wh3d${
                          !isEdit.wh_edit ? "-disabled" : ""
                        }`}
                        value={sizeRoom.name_input}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            setSizeRoom,
                            sizeRoom,
                            "name_input"
                          )
                        }
                        disabled={!isEdit.wh_edit}
                      />
                      <label>
                        Warehouse Name
                        <span
                          hidden={sizeRoom.name_input}
                          style={{ color: "red" }}
                        >
                          *
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="p-1 row align-items-center ">
                  <div className="col-3">
                    <span>
                      Width
                      <span
                        hidden={sizeRoom.width_input}
                        style={{ color: "red" }}
                      >
                        *
                      </span>
                      :{" "}
                    </span>
                  </div>
                  <div className="col-7">
                    <NumberFormat
                      className={`popup-input-wh3d${
                        !isEdit.wh_edit ? "-disabled" : ""
                      }`}
                      disabled={!isEdit.wh_edit}
                      decimalScale={2}
                      value={sizeRoom.width_input}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setSizeRoom,
                          sizeRoom,
                          "width_input"
                        )
                      }
                    />
                  </div>
                  <div className="col-2">
                    <span> {warehouseData.unit}</span>
                  </div>
                </div>
                <div className="p-1 row align-items-center">
                  <div className="col-3">
                    <span>
                      Depth
                      <span
                        hidden={sizeRoom.depth_input}
                        style={{ color: "red" }}
                      >
                        *
                      </span>
                      :{" "}
                    </span>
                  </div>
                  <div className="col-7">
                    <NumberFormat
                      className={`popup-input-wh3d${
                        !isEdit.wh_edit ? "-disabled" : ""
                      }`}
                      disabled={!isEdit.wh_edit}
                      decimalScale={2}
                      value={sizeRoom.depth_input}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setSizeRoom,
                          sizeRoom,
                          "depth_input"
                        )
                      }
                    />
                  </div>
                  <div className="col-2">
                    <span> {warehouseData.unit}</span>
                  </div>
                </div>
                <div className="p-1 row align-items-center">
                  <div className="col-3">
                    <span>
                      Height
                      <span
                        hidden={sizeRoom.height_input}
                        style={{ color: "red" }}
                      >
                        *
                      </span>
                      :{" "}
                    </span>
                  </div>
                  <div className="col-7">
                    <NumberFormat
                      className={`popup-input-wh3d${
                        !isEdit.wh_edit ? "-disabled" : ""
                      }`}
                      disabled={!isEdit.wh_edit}
                      decimalScale={2}
                      value={sizeRoom.height_input}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setSizeRoom,
                          sizeRoom,
                          "height_input"
                        )
                      }
                    />
                  </div>
                  <div className="col-2">
                    <span> {warehouseData.unit}</span>
                  </div>
                </div>
                {/* <div className="p-2 d-flex justify-content-between">
                  <button
                    className={`btn${
                      warehouseData.unit == "mm." ? "" : "-outline"
                    }-primary btn-sm btn-unit-wh3d`}
                    type="button"
                    onClick={() => handleSelectUnit("mm.", "wh")}
                  >
                    mm.
                  </button>
                  <button
                    className={`btn${
                      warehouseData.unit == "cm." ? "" : "-outline"
                    }-primary btn-sm btn-unit-wh3d`}
                    type="button"
                    onClick={() => handleSelectUnit("cm.", "wh")}
                  >
                    cm.
                  </button>
                  <button
                    className={`btn${
                      warehouseData.unit == "m." ? "" : "-outline"
                    }-primary btn-sm btn-unit-wh3d`}
                    type="button"
                    onClick={() => handleSelectUnit("m.", "wh")}
                  >
                    m.
                  </button>
                </div> */}
                {!NOT_USE_NOW ? (
                  <div
                    className="p-2 d-flex"
                    style={{ gap: "5px" }}
                  >
                    <button
                      type="button"
                      style={{ marginTop: ".5rem" }}
                      className={`btn-block btn-${
                        switchBtn.mode == "add" || switchBtn.mode == "edit"
                          ? "danger"
                          : "warning"
                      } btn-sm`}
                      onClick={() => {
                        if (
                          switchBtn.mode == "add" &&
                          functionComponent.cancelAdd
                        ) {
                          functionComponent.cancelAdd();
                        } else if (switchBtn.mode == "edit") {
                          clearWarehouseInput(true);
                          setswitchBtn({ ...switchBtn, mode: null });
                          switchBtn.mode = null;
                        } else if (
                          !switchBtn.mode ||
                          switchBtn.mode == "select"
                        ) {
                          setswitchBtn({ ...switchBtn, mode: "edit" });
                          switchBtn.mode = "edit";
                        }
                        const isBool = isEdit.wh_edit;
                        handleIsEdit(!isBool, "wh_edit");
                      }}
                    >
                      {switchBtn.mode == "add" || switchBtn.mode == "edit"
                        ? "Cancel"
                        : "Edit"}
                    </button>
                    <button
                      type="button"
                      style={{ marginTop: ".5rem" }}
                      className=" btn-block btn-primary btn-sm"
                      // disabled={switchBtn.mode != "add"}
                      onClick={() => {
                        // console.log('switchBtn.mode', switchBtn.mode);
                        // console.log('functionComponent.SaveWarehouse', functionComponent.SaveWarehouse);
                        if (
                          functionComponent.SaveWarehouse &&
                          (switchBtn.mode == "add" || switchBtn.mode == "edit")
                        )
                          functionComponent.SaveWarehouse(
                            sizeRoom,
                            switchBtn.mode
                          );
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Rack */}
       <div hidden={((viewOnly && !rackData.rack_name_input && showRackPopup))/* switchBtn.rack */} >
        <div className="bg-popup-main-wh3d">
          <div className="bg-popup-wh3d-bg">
            <div className="bg-popup-wh3d">
              <div className="icon-wh3d">
                <div className="icon-bg-wh3d">
                  <i class="	fas fa-boxes"></i>
                </div>
              </div>
              <div className="bg-popup-wh3d-header">
                <h3 className="bg-popup-wh3d-header-text">Rack</h3>
                {switchBtn.rack ? (
                  <i
                    class="	far fa-arrow-alt-circle-down"
                    onClick={() => {
                      if (switchBtn.mode != "add") {
                        handleSwitchBtn("rack");
                      }
                    }}
                  ></i>
                ) : (
                  <i
                    class="	far fa-arrow-alt-circle-up"
                    onClick={() => {
                      if (switchBtn.mode != "add") {
                        handleSwitchBtn("rack");
                      }
                    }}
                  ></i>
                )}
                {/* 	far fa-arrow-alt-circle-up */}
              </div>
            </div>
            {switchBtn.rack ? (
              <div className="bg-popup-wh3d-details" ref={bottomRef}>
                <div className="p-1 row align-items-center mt-1">
                {
                  viewOnly || NOT_USE_NOW ? <></> : (
                    <div className="col-3">
                  
                      <button
                        hidden={viewOnly || NOT_USE_NOW}
                        className={`btn-primary btn-sm d-flex align-items-center`}
                        style={{ height: "30px" }}
                        type="button"
                        onClick={() => {
                          setPositionRack({ data: [] });
                          positionRack.data = [];
                          clearRackInput();
                          setUseZAxis({
                            ...useZAxis,
                            is_use: false,
                          });
                          useZAxis.is_use = false;
                          setswitchBtn({
                            ...switchBtn,
                            mode: "add_rack",
                          });
                          switchBtn.mode = "add_rack";
                        }}
                      >
                        <span
                          style={{
                            fontSize: "2rem",
                            height: "20px",
                            lineHeight: "60%",
                          }}
                        >
                          +
                        </span>
                      </button>
                    </div>
                      )
                    }

                  <div className="col-12 pr-3">
                    <div class="form-group mb-0">
                      <input
                        placeholder="Rack Name"
                        className={`pl-1 popup-input-wh3d${
                          !isEdit.rack_edit ? "-disabled" : ""
                        }`}
                        value={rackData.rack_name_input}
                        disabled={!isEdit.rack_edit}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            setrackData,
                            rackData,
                            "rack_name_input"
                          )
                        }
                      />
                      <label>
                        Rack Name
                        <span
                          hidden={rackData.rack_name_input}
                          style={{ color: "red" }}
                        >
                          *
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="p-1 row align-items-center ">
                  <div className="col-3">
                    <span>
                      Width
                      <span
                        hidden={rackData.width_input}
                        style={{ color: "red" }}
                      >
                        *
                      </span>
                      :{" "}
                    </span>
                  </div>
                  <div className="col-7">
                    <NumberFormat
                      className={`popup-input-wh3d${
                        !isEdit.rack_edit ? "-disabled" : ""
                      }`}
                      value={rackData.width_input}
                      disabled={!isEdit.rack_edit}
                      decimalScale={2}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setrackData,
                          rackData,
                          "width_input"
                        )
                      }
                    />
                  </div>
                  <div className="col-2">
                    <span> {rackData.unit}</span>
                  </div>
                </div>
                <div className="p-1 row align-items-center">
                  <div className="col-3">
                    <span>
                      Depth
                      <span
                        hidden={rackData.depth_input}
                        style={{ color: "red" }}
                      >
                        *
                      </span>
                      :{" "}
                    </span>
                  </div>
                  <div className="col-7">
                    <NumberFormat
                      className={`popup-input-wh3d${
                        !isEdit.rack_edit ? "-disabled" : ""
                      }`}
                      value={rackData.depth_input}
                      disabled={!isEdit.rack_edit}
                      decimalScale={2}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setrackData,
                          rackData,
                          "depth_input"
                        )
                      }
                    />
                  </div>
                  <div className="col-2">
                    <span> {rackData.unit}</span>
                  </div>
                </div>
                <div className="p-1 row align-items-center">
                  <div className="col-3">
                    <span>
                      Height
                      <span
                        hidden={rackData.height_input}
                        style={{ color: "red" }}
                      >
                        *
                      </span>
                      :{" "}
                    </span>
                  </div>
                  <div className="col-7">
                    <NumberFormat
                      className={`popup-input-wh3d${
                        !isEdit.rack_edit ? "-disabled" : ""
                      }`}
                      value={rackData.height_input}
                      disabled={!isEdit.rack_edit}
                      decimalScale={2}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          setrackData,
                          rackData,
                          "height_input"
                        )
                      }
                    />
                  </div>
                  <div className="col-2">
                    <span> {rackData.unit}</span>
                  </div>
                </div>
                <AxisInput
                  isEdit={isEdit}
                  rackData={rackData}
                  setrackData={setrackData}
                  key={"x"}
                  axis={"x"}
                  handleInputChange={handleInputChange}
                />
                <AxisInput
                  isEdit={isEdit}
                  rackData={rackData}
                  setrackData={setrackData}
                  key={"y"}
                  axis={"y"}
                  handleInputChange={handleInputChange}
                />
                <>
                  <div className="p-1 px-3 row align-items-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id={"customCheckbox" + 1}
                        disabled={!isEdit.rack_edit}
                        checked={useZAxis.is_use}
                        onChange={(e) => {
                          const isBool = useZAxis.is_use;
                          setUseZAxis({
                            is_use: !isBool,
                          });
                          useZAxis.is_use = !isBool;
                          // tab.rack_axis_z = "";
                          // tab.rack_position_format_z = "1";
                          // tab.rack_separate_char_z = "";
                          // updateTabsData("rack_is_axis_z", e.target.checked);
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`customCheckbox${1}`}
                      >
                        Use Axis-Z
                      </label>
                    </div>
                  </div>
                  {useZAxis.is_use && (
                    <AxisInput
                      isEdit={isEdit}
                      rackData={rackData}
                      setrackData={setrackData}
                      key={"z"}
                      axis={"z"}
                      handleInputChange={handleInputChange}
                    />
                  )}
                </>
                <div className="p-2">
                  <div className="popup-status-rack">
                    Rack: {rackData.rack_name}
                  </div>
                </div>
                <div className="p-2 row" style={{ gap: "0px" }}>
                  {positionRack.data.map((el, index) => (
                    <div
                      key={index}
                      className="col-3 p-0 mb-1 d-flex justify-content-center"
                    >
                      <button
                        className={`btn-${GetColorOfPositionProduct(
                          el
                        )} btn-sm d-flex align-items-center px-0`}
                        style={{
                          height: "30px",
                          minWidth: "45px",
                          maxWidth: "45px",
                          border:
                            rackData.on_position_rp_id &&
                            rackData.on_position_rp_id === el.rp_id
                              ? "2px solid red"
                              : undefined,
                          boxShadow:
                            rackData.on_position_rp_id &&
                            rackData.on_position_rp_id === el.rp_id
                              ? "0 0 10px 1px red"
                              : undefined,
                        }}
                        type="button"
                        onClick={() => {
                          const temp = [...SumAmountAndGroupLogPOS([], el)];

                          let capacity_of_position =
                            rackData.rack_list
                              ?.find((item) => item.rack_id === rackData.rack_id)
                              ?.position_list?.find(
                                (item) => item.rp_id == el.rp_id
                              )?.product || [];
                          // console.log('capacity_of_position', capacity_of_position);

                          if (temp.length > 0) {
                            const { rack_list, ...other } = rackData;
                            other.pos_name = el.pos_name;
                            /* console.log('rackData.rack_id', rackData.rack_id);
                            console.log('rackData.rack_list', rackData.rack_list); */

                            setModalDisplayProduct({
                              ...modalDisplayProduct,
                              is_open: true,
                              data: [...temp],
                              rackData: other,
                              capacity_of_position: capacity_of_position,
                              wh_name: sizeRoom.name,
                            });
                            modalDisplayProduct.is_open = true;
                            modalDisplayProduct.data = [...temp];
                            modalDisplayProduct.rackData = other;
                            modalDisplayProduct.capacity_of_position =
                              capacity_of_position;
                            modalDisplayProduct.wh_name = sizeRoom.name;
                          }
                        }}
                      >
                        <span
                          style={{
                            fontSize: "0.9rem",
                            margin: "0 auto",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {el.pos_name}
                        </span>
                      </button>
                    </div>
                  ))}
                </div>
                <div className="p-2">
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    <div className="btn-danger btn-sm popup-rack-colors-status"></div>
                    <span style={{ whiteSpace: "nowrap" }}>
                      can not add product.
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    <div className="btn-success btn-sm popup-rack-colors-status"></div>
                    <span style={{ whiteSpace: "nowrap" }}>can add product.</span>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    <div className="btn-warning btn-sm popup-rack-colors-status"></div>
                    <span style={{ whiteSpace: "nowrap" }}>
                      can add little more product.
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    <span style={{ color: "red" }}>*</span>
                    <div className="btn-secondary btn-sm popup-rack-colors-status"></div>
                    <span style={{ whiteSpace: "nowrap" }}>no have product.</span>
                  </div>
                </div>
                {
                  viewOnly || NOT_USE_NOW ? <></> : (
                    <div
                      className="p-2 d-flex"
                      hidden={viewOnly || NOT_USE_NOW}
                      style={{ gap: "5px" }}
                    >
                      <button
                        type="button"
                        style={{ marginTop: ".5rem" }}
                        // hidden={!isEdit.rack_edit}
                        className={`btn-block btn-${
                          switchBtn.mode == "add_rack" ? "danger" : "warning"
                        } btn-sm`}
                        // disabled={switchBtn.mode != "add_rack" || switchBtn.mode != "edit_rack"}
                        onClick={() => {
                          const isBool = isEdit.rack_edit;
                          handleIsEdit(!isBool, "rack_edit");

                          if (switchBtn.mode == "add_rack") {
                            clearRackInput();
                            setswitchBtn({ ...switchBtn, mode: null });
                            switchBtn.mode = null;
                          } else if (switchBtn.mode == "edit_rack") {
                            clearRackInput(true);
                            setswitchBtn({ ...switchBtn, mode: null });
                            switchBtn.mode = null;
                          } else if (
                            switchBtn.mode == "select" ||
                            switchBtn.mode == null
                          ) {
                            setswitchBtn({ ...switchBtn, mode: "edit_rack" });
                            switchBtn.mode = "edit_rack";
                            // console.log("switchBtn.mode", switchBtn.mode);
                          }
                        }}
                      >
                        {switchBtn.mode == "add_rack" || switchBtn.mode == "edit_rack"
                          ? "Cancel"
                          : "Edit"}
                      </button>
                      <button
                        type="button"
                        style={{ marginTop: ".5rem" }}
                        className=" btn-block btn-primary btn-sm"
                        // disabled={switchBtn.mode != "add_rack" || switchBtn.mode == "edit_rack"}
                        onClick={() => {
                          // console.log("switchBtn.mode", switchBtn.mode);
                          if (
                            switchBtn.mode == "add_rack" ||
                            switchBtn.mode == "edit_rack"
                          ) {
                            saveRack();
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                  )
                }
                
              </div>
            ) : (
              ""
            )}
          </div>
          <ModalDisplayProduct
            modelType={modelType}
            modalDisplayProduct={modalDisplayProduct}
            setModalDisplayProduct={setModalDisplayProduct}
          />
        </div>
      </div> 
      </div>
      {/* <div
        hidden={viewOnly || actionActive.btn != '2D'}
        style={{
          position: viewOnly ? "absolute" : "fixed",
          zIndex: "999",
          width: '100%',
          display: 'flex',
          justifyContent: 'center', 
          alignItems: 'center', 
          paddingRight: '300px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <div className="bg-popup-wh3d-bg" style={{ width: '150px' }}>
            <div className="bg-popup-wh3d" style={{ width: '150px', height: '40px', paddingLeft: '8.5px' }}>
              <div className="p-2 row" style={{ display: 'flex', justifyContent: 'center',  width: '150px' }}>
                <p style={{ color: 'white', fontSize: '20px' }}>Rack:</p>
                  {positionRack.data.map((el, index) => (
                    <p style={{ color: 'white', fontSize: '20px' }}>{" "}{rackData.on_position_rp_id && rackData.on_position_rp_id === el.rp_id ? el.pos_name : undefined}</p>
                  ))}
              </div>  
            </div> 
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ConfigWarehouse;
