import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import { nullFormat } from "numeral";

function Warehouse_add_warehouse(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [filter_warehouse_type, setFilter_warehouse_type] = useState({
    type: "",
    company_id: getUser().com,
  });
  const [warehouse_type, setwarehouse_type] = useState([]);
  const [warehouse, setwarehouse] = useState({
    company_id: getUser().com,
    Warehouse_id: "",
    code_no: "",
    description: "",
    location: "",
    capacity: "",
    parent_id: "",
    warehouse_type_id:'41743525-fedb-444e-931e-f4ed6d78f909',
    is_use:"",
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

 
  
  useEffect(() => {
    //console.log("id:"+id);
    GetWarehouse_view();
    GetWarehouse_type();
  }, []);


  const GetWarehouse_view = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/warehouse/findById?id="+ id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response.data);
          setwarehouse({
            company_id: response.data.company_id,
            warehouse_id: response.data.id,
            code_no: response.data.code_no || "",
            description: response.data.description || "",
            location: response.data.location || "",
            capacity: response.data.capacity || "",
            parent_id: response.data.parent_id || "",
            is_use:response.data.is_use,
            warehouse_type_id:response.data.warehouse_type_id,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }

  const GetWarehouse_type = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/warehouse_type/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data:filter_warehouse_type,
      
    
    })
      .then(function (response) {
        //console.log(response.data);
        setwarehouse_type(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }; 
  
  
  function saveOption(params) {
    const error_list = [];
    if(warehouse.code_no.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Code No]."
      }
      error_list.push(temp_err);
    }
    if(warehouse.description == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Description]."
      }
      error_list.push(temp_err);
    }
    if(warehouse.location == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Location]."
      }
      error_list.push(temp_err);
    }
    if(warehouse.capacity == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Capacity]."
      }
      error_list.push(temp_err);
    }
    /* if(warehouse.parent_id == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Parent id]."
      }
      error_list.push(temp_err);
    } */
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
      if (mode.mode === "add") {
        const temp ={
        
          company_id: warehouse.company_id,
          code_no: warehouse.code_no.trim(),
          description: warehouse.description,
          location: warehouse.location,
          capacity: warehouse.capacity,
          warehouse_type_id: warehouse.warehouse_type_id,
          parent_id: null
        }
        console.log(temp);
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/warehouse/add_warehouse",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Warehouse/status/warehouse";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
      if (mode.mode === "edit") {

        const temp ={
          warehouse_id: id,
          company_id: warehouse.company_id,
          code_no: warehouse.code_no.trim(),
          description: warehouse.description,
          location: warehouse.location,
          capacity: warehouse.capacity,
          warehouse_type_id: warehouse.warehouse_type_id,
          parent_id: null
    
        }
        //console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/warehouse/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Warehouse/status/warehouse";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }
  
  }

  

  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if(result.isConfirmed){
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Warehouse/status/warehouse";
      }
      
    });
  }
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Warehouse {pageMode} </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Warehouse</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                {/*   <li className="breadcrumb-item active">warehouse</li> */}
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    if
                      onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Warehouse {pageMode} </h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
            <h3 className="mt-5-head">
                Warehouse Detail
                  </h3>
                <div className="tab-custom-content">
                 </div>
                 <br/>

              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={warehouse.code_no}
                      disabled={disable}
                      onChange={(e) => {
                        setwarehouse({
                          ...warehouse,
                          code_no: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      Warehouse No
                      {warehouse.code_no.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

               
              </div>

              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={warehouse.description}
                      disabled={disable}
                      onChange={(e) => {
                        setwarehouse({
                          ...warehouse,
                          description: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      Description
                      {warehouse.description === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={warehouse.location}
                      disabled={disable}
                      onChange={(e) => {
                        setwarehouse({
                          ...warehouse,
                          location: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      Location
                      {warehouse.location === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                     /* NumberFormat */
                      /* type="number" */
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="form-control"
                      required
                      thousandSeparator={true}
                      allowNegative ={false}
                      value={warehouse.capacity}
                      disabled={disable}
                      onChange={(e) => {
                        setwarehouse({
                          ...warehouse,
                          capacity: e.target.value,
                        });
                      }}
                    />
                    
                    <label>
                      Capacity
                      {warehouse.capacity === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row" style = {{display:"none"}}>
                <div className="col-4">
                  <div class="form-group">
                    <select className="form-control "
                      type="text"
                   
                      required
                      value={warehouse.warehouse_type_id}
                      disabled={true}
                      onChange={(e) => {
                        setwarehouse({
                          ...warehouse,
                          warehouse_type_id: e.target.value,
                        });
                      }}
                
                    >
                    <option value="" disabled selected>
                      Select Warehouse Type
                    </option>
                     {warehouse_type.map((el) => {
                      return <option value={el.id}>{el.type}</option>;
                    })}
                    </select>
                    <label>
                      Warehouse Type
                      {warehouse.warehouse_type_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

       

             
              
                
                 
            
              
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>
  );
}
export default Warehouse_add_warehouse;
