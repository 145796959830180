import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Configs from "../../../../config";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MultiSelect } from "react-multi-select-component";
import Select from "react-select";
import Swal from "sweetalert2";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

function Product_CN_Table() {
  const [ProductCN, setProductCN] = useState({
    data: [],
  });

  const [CustomerData, setCustomerData] = useState({
    data: [],
  });

  const [CustomerOption, setCustomerOption] = useState({
    data: [],
  });

  const [SearchInput, setSearchInput] = useState({
    SRNo: "",
    CustomerID: "",
  });

  let [SaleData, setSaleData] = useState([]);

  const [SaleOption, setSaleOption] = useState({
    data: [],
  });

  let [StockInModal, setStockInModal] = useState(false);

  const [StockInSRNo, setStockInSRNo] = useState({
    SRId: "",
    SRNo: "",
    Product: [],
    IsCal: false,
    is_waste: false,
  });

  const [resultImportData, setResultImportData] = useState({
    data: [],
  });

  const [modalImportOC, setModalImportOC] = useState(false);

  const [modalResultOC, setModalResultOC] = useState(false);

  const [fileData, setFileData] = useState({
    data: [],
  });

  const [modalFileData, setModalFileData] = useState({
    data: [],
  });

  let [isStockConfirmed, setisStockConfirmed] = useState(false);

  let [invoiceRef, setInvoiceRef] = useState({
    data: null,
  });

  const allowedTypes = [
    "text/plain",
    // "image/png",
    // "image/jpeg",
    "text/csv",
    // "application/msword",
    // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    // "application/pdf",
    // "application/vnd.ms-excel",
    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const numberWithCommas = (x) => {
    return parseFloat(x.toString()).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithOutCommas = (x) => {
    return x.toString().replace(/,/g, "");
  };

  const rowsData3 = [];
  for (let index = 0; index < resultImportData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["file_name"] = resultImportData.data[index].file_name;

    if (resultImportData.data[index].status === "add") {
      rowItem["status"] = (
        <Button disabled color="btn btn-success w-100" style={{ opacity: 1 }}>
          Success
        </Button>
      );
    } else if (resultImportData.data[index].status === "update") {
      rowItem["status"] = (
        <Button disabled color="btn btn-warning w-100" style={{ opacity: 1 }}>
          Update
        </Button>
      );
    } else if (resultImportData.data[index].status === "error") {
      rowItem["status"] = (
        <Button disabled color="btn btn-danger w-100" style={{ opacity: 1 }}>
          Error
        </Button>
      );
    }

    rowItem["remark"] = resultImportData.data[index].error;

    rowsData3.push(rowItem);
  }

  const resultImport_table = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "File Name",
        field: "file_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData3,
  };

  async function saveImport() {
    let data = new FormData();
    data.append("company_id", getUser().com);
    data.append("oem_id", getOem());
    data.append("user_id", getUser().fup);
    data.append("user_name", `${getUser().name} ${getUser().surname}`);
    for (let file of fileData.data) {
      data.append("Profile", file);
      data.append("ProfileName", file.name);
    }

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/getInvoiceFromFile",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(async function (response1) {
        let tempData = {
          oem_id: getOem(),
          company_id: getUser().com,
          user_id: getUser().fup,
          user_name: `${getUser().name} ${getUser().surname}`,
          data: response1.data,
        };

        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/product_cn/saveImport",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(async function (response2) {
            setResultImportData({
              data: response2.data,
            });

            resultImportData.data = response2.data;

            setFileData({
              data: [],
            });

            setModalFileData({
              data: [],
            });

            setModalResultOC(true);
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch(async (error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  
  const GetQuantityProductCN = (item) => {
    let sum = 0;
    for (let el of item.detail_CN_product) {
      let unit = 1;
      if (el.crp_quantity_unit?.toLowerCase() === "pack") {
        unit = parseFloat(el.pd_sale_pc_pack || 0)
      } else if (el.crp_quantity_unit?.toLowerCase() === "ctn" || el.crp_quantity_unit?.toLowerCase() === "caton") {
        unit = parseFloat(el.pd_sale_pc_ctn  || 0)
      }
      sum += (parseFloat(el.crp_quantity || 0) * unit)
    }
    return sum + ' Piece'
  }

  async function GetCustomer() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        setCustomerData({
          data: response.data,
        });
        CustomerData.data = response.data;
        let temp = response.data;
        /* let temp = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.cus_id;
          element["label"] = el.cus_name;
          temp.push(element);
        }); */
        setCustomerOption({
          ...CustomerOption,
          data: temp,
        });
        CustomerOption.data = temp;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetUserSales() {
    let tempData = {
      company_id: getUser().com,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/users/getUserSales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        let temp = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.fullname;
          temp.push(element);
        });
        setSaleOption({
          ...SaleOption,
          data: temp,
        });
        SaleOption.data = temp;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetProductCN() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      SRNo: SearchInput.SRNo.trim() || null,
      CustomerID: SearchInput.CustomerID || null,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_cn/getProductCN",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        setProductCN({
          data: response.data,
        });
        ProductCN.data = response.data;
        if (StockInSRNo.SRId) {
          let data = ProductCN.data.find((el) => el.pc_id === StockInSRNo.SRId);
          if (data) {
            setStockInSRNo({
              ...StockInSRNo,
              SRId: data.pc_id,
              SRNo: data.pc_no,
              Product: data.detail_CN_product,
              IsCal: true,
              is_waste: false,
            });
            setSaleData([]);
            StockInSRNo.SRId = data.pc_id;
            StockInSRNo.SRNo = data.pc_no;
            StockInSRNo.Product = data.detail_CN_product;
            StockInSRNo.IsCal = true;
            StockInSRNo.is_waste = false;
            SaleData = [];
            await GetProduct();
            if (!isStockConfirmed) {
              setStockInModal(true);
            }
          }
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const GetProduct = async () => {
    let data = new FormData();
    data.append("company_id", getUser().com);
    data.append("oem_id", getOem());
    data.append("user_id", getUser().fup);
    data.append("products", JSON.stringify(StockInSRNo.Product));

    await axios({
      method: "post",
      url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    })
      .then(async (response) => {
        setStockInSRNo({
          ...StockInSRNo,
          Product: response.data,
        });
        StockInSRNo.Product = response.data;
      })
      .catch(async (error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const Custom_Input_date = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  async function DeleteProductCN(pc_id) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          oem_id: getOem(),
          company_id: getUser().com,
          pc_id: pc_id,
        };
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/product_cn/DeleteProductCN",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: data,
        })
          .then((response) => {
            if (response) {
              Swal.fire({
                title: "Success!",
                text: "ลบข้อมูลสำเร็จ",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  GetProductCN();
                }
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  async function SaveDraft(isDraft) {
    if (isDraft === false) {
      setisStockConfirmed(true);
      isStockConfirmed = true;
    }
    let error_list = [];
    if (SaleData.length === 0 && isDraft === true) {
      let temp_err = {
        message: `กรุณาเลือก Sale ก่อนทำรายการถัดไป`,
      };
      error_list.push(temp_err);
    } else {
      for (let item of StockInSRNo.Product) {
        if (item.crp_receive_amount) {
          item.crp_receive_amount =
            typeof item.crp_receive_amount === "string"
              ? parseFloat(item.crp_receive_amount?.replace(/,/g, "") || 0)
              : item.crp_receive_amount || 0;
        }

        if (item.total_piece) {
          item.total_piece =
            typeof item.total_piece === "string"
              ? parseFloat(item.total_piece?.replace(/,/g, "") || 0)
              : item.total_piece || 0;
        }

        if (item.recieve) {
          item.recieve =
            typeof item.recieve === "string"
              ? parseFloat(item.recieve?.replace(/,/g, "") || 0)
              : item.recieve || 0;
        }

        if (item.remark) {
          item.remark = item.remark?.toString().trim() || null;
        }

        let received = parseFloat(item.crp_receive_amount || 0);
        let max_receive = parseFloat(item.total_piece || 0);
        let balance_receive = max_receive - received;
        let receive_input =
          typeof item.recieve === "string"
            ? parseFloat(item.recieve?.replace(/,/g, "") || 0)
            : item.recieve || 0;

        if (balance_receive === receive_input) {
          item.is_receive = true;
        } else {
          item.is_receive = false;
        }

        if (receive_input === 0) {
          continue;
        } else if (SaleData.length === 0) {
          let temp_err = {
            message: `กรุณาเลือก Sale ก่อนทำรายการถัดไป`,
          };
          error_list.push(temp_err);
          break;
        }

        if (received === max_receive && receive_input) {
          let temp_err = {
            message: `Product ${item.product_name} : รับสินค้าครบจำจวนแล้ว`,
          };
          error_list.push(temp_err);
        }

        if (receive_input > balance_receive) {
          let temp_err = {
            message: `Product ${item.product_name} : Receive Order (Piece) ไม่สามารถรับสินค้าเกินจำนวนได้`,
          };
          error_list.push(temp_err);
        }

        if (
          item.exp_date === null ||
          item.exp_date === "" ||
          item.exp_date === undefined
        ) {
          let temp_err = {
            message: `Product ${item.product_name} : กรุณาเลือก Exp. Date`,
          };
          error_list.push(temp_err);
        }

        let sum_sale = 0;
        for (let sale of item.sale_list) {
          if (sale.sale) {
            let sale_input = parseFloat(sale.sale || 0);
            sum_sale += sale_input;

            sale.sale =
              typeof sale.sale === "string"
                ? parseFloat(sale.sale?.replace(/,/g, "") || 0)
                : sale.sale || 0;
          }
        }

        if (item.sale_list.length === 1 && !item.sale_list[0].sale) {
          let temp_err = {
            message: `Product ${item.product_name} : กรุณากรอกจำนวนรับในช่อง ${SaleData[0].label}`,
          };
          error_list.push(temp_err);
        } else if (sum_sale !== receive_input) {
          let temp_err = {
            message: `Product ${item.product_name} : ผลรวมของ Sale ต้องเท่ากับ Receive Order (Piece)`,
          };
          error_list.push(temp_err);
        }
      }
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
      return;
    }
    let data = {
      oem_id: getOem(),
      company_id: getUser().com,
      // product_cn: ProductCN.data,
      StockInSRNo: StockInSRNo,
      user_name: getUser().name + " " + getUser().surname,
      fup: getUser().fup,
      isDraft: isDraft,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_cn/SaveDraft",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(async (response) => {
        Swal.fire({
          title: "Success!",
          text: "บันทึกข้อมูลสำเร็จ",
          icon: "success",
        }).then(async (result) => {
          if (isStockConfirmed) {
            setStockInModal(false);
            StockInModal = false;
            await GetProductCN();
          } else {
            await GetProductCN();
            await getInvoiceSaleForCN(invoiceRef.data);
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getInvoiceSaleForCN(inv_no) {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      inv_no: inv_no,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/getInvoiceSaleForCN",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        if (response.data.length > 0) {
          let sale = SaleOption.data.filter(
            (el) => el.value === response.data[0].inv_sale_id
          );
          setSaleData(sale);
          SaleData = sale;
        } else {
          setSaleData([]);
          SaleData = [];
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  useEffect(async () => {
    await GetUserSales();
    await GetCustomer();
    await GetProductCN();
  }, []);

  const rowsData = [];
  for (let index = 0; index < ProductCN.data.length; index += 1) {
    let ever_receive = [];
    if (ProductCN.data[index].detail_CN_product.length > 0) {
      ever_receive = ProductCN.data[index].detail_CN_product.filter(
        (item, index2) => parseInt(item.crp_receive_amount || 0) > 0
      );
    }
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["sr_no"] = (
      <div style={{ textAlign: "center" }}>
        {" "}
        {
          ProductCN.data[index].pc_no !== null
          ? ProductCN.data[index].pc_no
          : ""
        }
      </div>
    );
    rowItem["sr_no1"] = ProductCN.data[index].pc_no !== null ? ProductCN.data[index].pc_no : "" // เอาไว้ search
    rowItem["customer"] = (
      <div style={{ textAlign: "center" }}>
        {
        ProductCN.data[index].pc_cus_name
        }
      </div>
    );
    rowItem["customer1"] = ProductCN.data[index].pc_cus_name // เอาไว้ search
    rowItem["quantity"] = (
      <div style={{ textAlign: "center" }}>
        {ProductCN.data[index].detail_CN_product.length > 0
          ? GetQuantityProductCN(ProductCN.data[index])
          : "-"}
      </div>
    );
    rowItem["price"] = (
      <div style={{ textAlign: "center" }}>
        {ProductCN.data[index].pc_total_price !== null
          ? numberWithCommas(ProductCN.data[index].pc_total_price)
          : ""}
      </div>
    );
    rowItem["price1"] = ProductCN.data[index].pc_total_price // เอาไว้ search
    rowItem["ref_document"] = (
      <div style={{ textAlign: "center" }}>
        {ProductCN.data[index].pc_ref_no !== null
          ? ProductCN.data[index].pc_ref_no
          : ""}
      </div>
    );
    rowItem["ref_document1"] = ProductCN.data[index].pc_ref_no // เอาไว้ search
    rowItem["create_by"] = (
      <div style={{ textAlign: "center" }}>
        {ProductCN.data[index].pc_created_name !== null
          ? ProductCN.data[index].pc_created_name
          : ""}
      </div>
    );
    rowItem["create_by1"] = ProductCN.data[index].pc_created_name // เอาไว้ search
    rowItem["stock"] = (
      <div>
        {ProductCN.data[index].detail_CN_product.length > 0 &&
        ProductCN.data[index].pc_is_success !== true ? (
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={async () => {
                  setStockInSRNo({
                    ...StockInSRNo,
                    SRId: ProductCN.data[index].pc_id,
                    SRNo: ProductCN.data[index].pc_no,
                    Product: ProductCN.data[index].detail_CN_product,
                    IsCal: true,
                    is_waste: false,
                  });
                  StockInSRNo.SRId = ProductCN.data[index].pc_id;
                  StockInSRNo.SRNo = ProductCN.data[index].pc_no;
                  StockInSRNo.Product = ProductCN.data[index].detail_CN_product;
                  StockInSRNo.IsCal = true;
                  StockInSRNo.is_waste = false;
                  setInvoiceRef({
                    data: ProductCN.data[index].pc_ref_no
                  });
                  invoiceRef.data = ProductCN.data[index].pc_ref_no;
                  await GetProduct();
                  await getInvoiceSaleForCN(ProductCN.data[index].pc_ref_no);
                  setStockInModal(true);
                }}
              >
                Stock In
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12" style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={true}
              >
                Stock In
              </button>
            </div>
          </div>
        )}
      </div>
    );
    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={ProductCN.data[index].pc_id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Product_CN/View/" +
                ProductCN.data[index].pc_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>

        {ProductCN.data[index].pc_is_success === false &&
        ever_receive.length === 0 ? (
          <>
            <div className="col-xl-3 col-md-3 col-xs-3 ">
              <a
                key={ProductCN.data[index].pc_id}
                id="btn_delete"
                className=" btn btn-xs "
                onClick={() => {
                  window.location.href =
                    "/Warehouse_Management/Product_CN/Edit/" +
                    ProductCN.data[index].pc_id;
                }}
              >
                <i class="fas fa-pencil-alt"></i>
              </a>
            </div>

            <div
              className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.deleteBtn ? "hidden" : "visible" }} */
            >
              <a
                key={ProductCN.data[index].pc_id}
                // id={ProductCN.data[index].pc_id}
                className=" btn btn-xs "
                onClick={async () => {
                  await DeleteProductCN(ProductCN.data[index].pc_id);
                }}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );

    rowsData.push(rowItem);
  }
  const ProductCNTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        width: 50,
      },
      {
        label: "SR No.",
        field: "sr_no",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        width: 50,
      },
      {
        label: "Quantity",
        field: "quantity",
        width: 50,
      },
      {
        label: "Price",
        field: "price",
        width: 50,
      },
      {
        label: "Ref. Document",
        field: "ref_document",
        width: 50,
      },
      {
        label: "Create By",
        field: "create_by",
        width: 50,
      },
      {
        label: "Stock",
        field: "stock",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  async function SumAmount() {
    let temp = [...StockInSRNo.Product];

    for (let item of temp) {
      let sum = 1;
      if (item.crp_quantity_unit.toLowerCase() === "pack") {
        sum =
          parseInt(item.pd_sale_pc_pack !== null ? item.pd_sale_pc_pack : 0) ||
          0;
      } else if (item.crp_quantity_unit.toLowerCase() === "ctn") {
        sum =
          parseInt(
            item.pd_sale_pc_ctn !== null ? item.pd_sale_pc_ctn : 0
          ) || 0;
      }
      item.recieve =
        sum * parseInt(item.crp_quantity || 0) -
        parseInt(item.crp_receive_amount || 0);
      item.total_piece = sum * parseInt(item.crp_quantity || 0);
    }
    setStockInSRNo({
      ...StockInSRNo,
      Product: temp,
    });
    StockInSRNo.Product = temp;
  }
  useEffect(async () => {
    if (StockInSRNo.Product.length > 0 && StockInSRNo.IsCal) {
      await SumAmount();
      setStockInSRNo({
        ...StockInSRNo,
        IsCal: false,
      });
      StockInSRNo.IsCal = false;
    }
  }, [StockInSRNo.IsCal]);

  const rowsData2 = [];
  for (let index = 0; index < StockInSRNo.Product.length; index += 1) {
    const rowItem = {};
    rowItem["product_code"] = (
      <div style={{ textAlign: "center" }}>{index + 1}</div>
    );
    rowItem["barcode"] = (
      <div style={{ textAlign: "center" }}>
        {
          StockInSRNo.Product[index].pb_piece
        }
      </div>
    );
    rowItem["barcode1"] = StockInSRNo.Product[index].pb_piece // เอาไว้ search
    rowItem["product_name"] = (
      <div style={{ textAlign: "center" }}>
        {
          StockInSRNo.Product[index].product_name
        }
      </div>
    );
    rowItem["product_name1"] = StockInSRNo.Product[index].product_name // เอาไว้ search
    rowItem["product_image"] = (
      <div style={{ textAlign: "center" }}>
        <div className="row">
          <div className="col-12">
            <img
              id="img"
              alt="..."
              className="img-fluid rounded shadow border-0"
              src={
                StockInSRNo.Product[index].pci_img_path !== "" &&
                StockInSRNo.Product[index].pci_img_path !== null &&
                StockInSRNo.Product[index].pci_img_path !== undefined
                  ? StockInSRNo.Product[index].pci_img_path?.indexOf("blob") >=
                    0
                    ? StockInSRNo.Product[index].pci_img_path
                    : Configs.API_URL_IMG_cuteplus +
                      `/static/${StockInSRNo.Product[index].pci_img_name}/${StockInSRNo.Product[index].pci_img_path}`
                  : userdefault_img.imgs
              }
              onError={(e) => {
                e.target.src = userdefault_img.imgs;
              }}
              style={{
                width: "70px",
                height: "70px",
                position: "relative",
              }}
            />
          </div>
        </div>
      </div>
    );
    rowItem["require_quantity"] = (
      <div style={{ textAlign: "center" }}>
        {StockInSRNo.Product[index].crp_quantity +
          " " +
          (StockInSRNo.Product[index].crp_quantity_unit !== null
            ? StockInSRNo.Product[index].crp_quantity_unit
            : "")}
      </div>
    );
    rowItem["product_cn"] = (
      <div style={{ textAlign: "center" }}>
        {StockInSRNo.Product[index].crp_quantity_unit !== null &&
        StockInSRNo.Product[index].crp_quantity_unit.toLowerCase() === "piece"
          ? (StockInSRNo.Product[index].crp_receive_amount || 0) +
            "/" +
            StockInSRNo.Product[index].crp_quantity
          : StockInSRNo.Product[index].crp_quantity_unit !== null &&
            StockInSRNo.Product[index].crp_quantity_unit.toLowerCase() ===
              "pack"
          ? (StockInSRNo.Product[index].crp_receive_amount || 0) +
            "/" +
            parseInt(StockInSRNo.Product[index].crp_quantity) *
              parseInt(StockInSRNo.Product[index].pd_sale_pc_pack)
          : StockInSRNo.Product[index].crp_quantity_unit !== null &&
            StockInSRNo.Product[index].crp_quantity_unit.toLowerCase() ===
              "ctn"
          ? (StockInSRNo.Product[index].crp_receive_amount || 0) +
            "/" +
            parseInt(StockInSRNo.Product[index].crp_quantity) *
              parseInt(StockInSRNo.Product[index].pd_sale_pc_ctn)
          : ""}
      </div>
    );
    {
      if (StockInSRNo.Product[index].crp_is_receive === false) {
        rowItem["receive_order"] = (
          <div className="form-group" style={{ textAlign: "center" }}>
            <NumberFormat
              type="text"
              className="form-control"
              thousandSeparator={true}
              thousandsGroupStyle="thousand"
              decimalScale={0}
              value={StockInSRNo.Product[index].recieve}
              onChange={(e) => {
                let temp = [...StockInSRNo.Product];
                temp[index].recieve = e.target.value;
                setStockInSRNo({
                  ...StockInSRNo,
                  Product: temp,
                });
                StockInSRNo.Product = temp;
              }}
            ></NumberFormat>
          </div>
        );
        rowItem["exp_date"] = (
          <div
            className="col-12"
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "5px",
              flexDirection: "row",
              alignItems: "center",
              minWidth: "150px"
              // width: "100%",
            }}
            onClick={() => {
              let temp = [...StockInSRNo.Product];
              for (let item of temp) {
                item.isZIndex = false;
              }
              temp[index].isZIndex = true;
              setStockInSRNo({
                ...StockInSRNo,
                Product: temp,
              });
              StockInSRNo.Product = temp;
            }}
          >
            <div
              className="form-group"
              style={{
                zIndex: StockInSRNo.Product[index].isZIndex ? "9999" : "0",
                width: "100%",
              }}
            >
              <DatePicker
                selected={StockInSRNo.Product[index].exp_date}
                dateFormat={"dd-MM-yyyy"}
                wrapperClassName="w-100"
                popperPlacement="bottom"
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"], // don't allow it to flip to be above
                  },
                  preventOverflow: {
                    enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                  },
                  hide: {
                    enabled: false, // turn off since needs preventOverflow to be enabled
                  },
                }}
                onChange={async (date) => {
                  let temp = [...StockInSRNo.Product];
                  temp[index].exp_date = date;
                  setStockInSRNo({
                    ...StockInSRNo,
                    Product: temp,
                  });
                  StockInSRNo.Product = temp;
                }}
                customInput={<Custom_Input_date />}
              />
            </div>
          </div>
        );
        if (
          StockInSRNo.Product[index].sale_list &&
          StockInSRNo.Product[index].sale_list.length > 0
        ) {
          for (
            let index2 = 0;
            index2 < StockInSRNo.Product[index].sale_list.length;
            index2++
          ) {
            rowItem[`sale${index2 + 1}`] = (
              <div className="form-group" style={{ textAlign: "center", width: "100px"}}>
                <input
                  type="text"
                  className="form-control"
                  key={index2}
                  value={StockInSRNo.Product[index].sale_list[index2].sale}
                  onChange={(e) => {
                    let temp = [...StockInSRNo.Product];
                    temp[index].sale_list[index2].sale = e.target.value;
                    setStockInSRNo({
                      ...StockInSRNo,
                      Product: temp,
                    });
                    StockInSRNo.Product = temp;
                  }}
                ></input>
              </div>
            );
          }
        }
        rowItem["remark"] = (
          <div className="form-group" style={{ textAlign: "center", width: "100px"}}>
            <input
              type="text"
              className="form-control"
              value={StockInSRNo.Product[index].remark}
              onChange={(e) => {
                let temp = [...StockInSRNo.Product];
                temp[index].remark = e.target.value;
                setStockInSRNo({
                  ...StockInSRNo,
                  Product: temp,
                });
                StockInSRNo.Product = temp;
              }}
            ></input>
          </div>
        );
      }
    }
    rowsData2.push(rowItem);
  }
  const StockInTable = {
    columns: [
      {
        label: "Product Code",
        field: "product_code",
        width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        width: 50,
      },
      {
        label: "Product Image",
        field: "product_image",
        width: 50,
      },
      {
        label: "Require Quantity",
        field: "require_quantity",
        width: 50,
      },
      {
        label: "Product CN (Piece)",
        field: "product_cn",
        width: 50,
      },
      {
        label: "Receive Order (Piece)",
        field: "receive_order",
        width: 50,
      },
      {
        label: "Exp. Date",
        field: "exp_date",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (SaleData.length > 0) {
    for (let index = 0; index < SaleData.length; index++) {
      StockInTable.columns.splice(-1, 0, {
        label: `${SaleData[index].label}`,
        field: `sale${index + 1}`,
        width: 50,
      });
    }
  }

  useEffect(() => {
    const temp = StockInSRNo.Product?.map((tl) => {
      tl.sale_list = SaleData?.map((el) => {
        return { sale: "", sale_name: el.label, sale_id: el.value };
      });
      return tl;
    });
    setStockInSRNo({
      ...StockInSRNo,
      Product: temp,
    });
    StockInSRNo.Product = temp;
  }, [SaleData]);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Product CN</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Product CN</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-3 col-xl-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={SearchInput.SRNo}
                    onChange={(e) => {
                      setSearchInput({
                        ...SearchInput,
                        SRNo: e.target.value,
                      });
                    }}
                  ></input>
                  <label>SR No.</label>
                </div>
              </div>
              <div className="col-12 col-md-3 col-xl-2">
                <div className="form-group" style={{ zIndex: 99 }}>
                  <select
                    className="form-control select2"
                    required
                    value={SearchInput.CustomerID}
                    onChange={(e) => {
                      setSearchInput({
                        ...SearchInput,
                        CustomerID: e.target.value,
                      });
                      SearchInput.CustomerID = e.target.value;
                    }}
                  >
                    <option value="">Select Customer</option>
                    {CustomerOption.data.map((data, index) => (
                      <option key={index} value={data.cus_id}>
                        {data.cus_name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="">Customer</label>
                </div>
              </div>
              <div className="col-12 col-md-2 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      GetProductCN();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-2 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      setSearchInput({
                        SRNo: "",
                        CustomerID: "",
                      });
                      SearchInput.SRNo = "";
                      SearchInput.CustomerID = "";
                      GetProductCN();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-2 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={async () => {
                      setModalImportOC(true);
                    }}
                  >
                    Import Express
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12" style={{ overflow: "overlay" }}>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed"
                          striped
                          bordered
                          hover
                          fixedHeader
                          disableRetreatAfterSorting={true}
                          data={ProductCNTable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        isOpen={StockInModal}
        toggle={() => {
          setStockInModal(false);
        }}
        size="xl"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader>
          <div className="row">
            <div className="col-12">
              <h5>Stock In</h5>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={StockInSRNo.SRNo}
                ></input>
                <label>SR No.</label>
              </div>
            </div>
            <div className="col-4 col-md-3 col-xl-2">
              <div className="form-group">
                <select
                  className="form-control select2"
                  value={StockInSRNo.is_waste}
                  onChange={(e) => {
                    setStockInSRNo({
                      ...StockInSRNo,
                      is_waste: e.target.value === "true" ? true : false,
                    });
                    StockInSRNo.is_waste =
                      e.target.value === "true" ? true : false;
                  }}
                >
                  <option value={false} selected>
                    Stock In
                  </option>
                  <option value={true}>Waste</option>
                </select>
                <label>Receive Type</label>
              </div>
            </div>
            <div className="col-4 col-md-3 col-xl-2">
              <div className="" style={{ position: "relative" }}>
                <MultiSelect
                  className="mt-2"
                  hasSelectAll={false}
                  options={SaleOption.data}
                  value={SaleData}
                  onChange={setSaleData}
                  labelledBy="Select"
                />
                <label
                  style={{
                    position: "absolute",
                    top: "-22px",
                    color: "#004fa4",
                  }}
                >
                  Sales
                </label>
              </div>
            </div>
            <div className="col-4 col-md-3 col-xl-2">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-success"
                  onClick={async () => {
                    await SaveDraft(true);
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ overflow: "overlay" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  disableRetreatAfterSorting={true}
                  data={StockInTable}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ padding: "0 2rem", display: "block" }}>
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-4 col-md-3 col-xl-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-danger"
                  onClick={() => {
                    setStockInModal(false);
                  }}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
            <div className="col-4 col-md-3 col-xl-3">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={async () => {
                    await SaveDraft(false);
                  }}
                >
                  เสร็จสิ้น
                </button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalImportOC}
        toggle={() => {
          setModalFileData({
            data: [],
          });

          setFileData({
            data: [],
          });

          setModalImportOC(false);
        }}
        size="md"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModalFileData({
              data: [],
            });

            setFileData({
              data: [],
            });

            setModalImportOC(false);
          }}
        >
          <h3>Import</h3>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-4">
              <label>
                File Upload <span style={{ color: "red" }}>*</span>
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    // disabled={isDisabled}
                    id="exampleInputFile"
                    accept={allowedTypes}
                    multiple
                    onChange={(e) => {
                      if (e.target.files.length !== 0) {
                        const tempData1 = [];
                        const tempData2 = [];

                        for (let item of e.target.files) {
                          if (allowedTypes.includes(item?.type)) {
                            tempData1.push(item);

                            tempData2.push({
                              File_name: item.name,
                              File_path: URL.createObjectURL(item),
                            });

                            setFileData({
                              data: tempData1,
                            });
                            fileData.data = tempData1;

                            setModalFileData({
                              data: tempData2,
                            });
                            modalFileData.data = tempData2;
                          } else {
                            Swal.fire(
                              "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
                              "กรุณาอัปโหลดประเภทไฟล์ CSV, TEXT",
                              "error"
                            );

                            setFileData({
                              data: [],
                            });

                            setModalFileData({
                              data: [],
                            });

                            document.getElementById("exampleInputFile").value =
                              null;

                            break;
                          }
                        }
                      } else {
                        setFileData({
                          data: [],
                        });

                        setModalFileData({
                          data: [],
                        });

                        document.getElementById("exampleInputFile").value =
                          null;
                      }
                    }}
                  />
                  <label
                    className="custom-file-label textVerticalOverflow-1"
                    htmlFor="exampleInputFile"
                  >
                    {modalFileData.data.map((item) => {
                      return <span>{item.File_name + ", "}</span>;
                    })}
                    {}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setFileData({
                data: [],
              });

              setModalFileData({
                data: [],
              });

              setModalImportOC(false);
            }}
          >
            ยกเลิก
          </Button>
          {File !== null ? (
            <Button
              color="btn btn-info"
              onClick={async () => {
                setModalImportOC(false);

                await saveImport();
              }}
            >
              บันทึก
            </Button>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalResultOC}
        toggle={() => {
          setModalResultOC(false);

          setResultImportData({
            data: [],
          });

          window.location.reload();
        }}
        size="xl"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModalResultOC(false);

            setResultImportData({
              data: [],
            });

            window.location.reload();
          }}
        >
          <h3>Import Excel</h3>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-md-12" style={{ overflow: "overlay" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={resultImport_table}
                />
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="btn btn-info"
            onClick={async () => {
              setModalResultOC(false);

              setResultImportData({
                data: [],
              });

              window.location.reload();
            }}
          >
            ตกลง
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Product_CN_Table;
