import React, { Children, Component, useEffect, useState } from "react";
import Configs from "../../../../config";
import axios from "axios";
import { useParams } from "react-router";
import Swal from "sweetalert2";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getOem,
  getToken,
  getUser,
  getAcademy,
} from "../../../../Utils/Common";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { Tooltip } from "antd";
import TTT_Person from "../../../../assets/img/booking/Person_Default.png"

function Manage_Documents_Add(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const { id } = useParams();
  const [nameNotNull, setNameNotNull] = useState(false);
  const [dataFileDoc, setDataFileDoc] = useState({
    rf_name: "",
    rc_id: "",
    file_name: "",
    file_path: "",
    file: "",
    rp_rf_id: "",
    rf_start_date: "",
    rf_end_date: ""
  });
  const [employeeReadedFile, setEmployeeReadedFile] = useState({ data: [] });
  const [allEmployee, setAllEmployee] = useState({ data: [] });
  const [filCategory, setFilterCategory] = useState([]);
  const [filterteam, setFilterTeam] = useState({
    cat_name: "",
    file_name: "",
    rc_id: "",
  });
  useEffect(() => {
    if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else if (mode.mode === "view") {
      setdisable(true);
      setPageMode("View");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
    getDataByID(id);
    // let id = "ed61b4af-a1b2-4bae-90d2-94f7befaa430"
    // GetSubDepartmerntList(id);
    // GetDepartmentData();
  }, []);

  function cancle_add_award_point(params) {
    if (mode.mode == 'view') {
      window.location.href = "/Human_Resource/company_regulations/Manage_documents";
      return
    }
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Human_Resource/company_regulations/Manage_documents";
      }
    });
  }

  function getDataByID(id) {
    if (mode.mode == "view" || mode.mode == "edit") {
      var temp = {
        rp_id: id,
      };
      axios({
        method: "post",
        url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterCategoryByID",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      })
        .then(function (response) {
          setDataFileDoc({
            rf_name: response.data.data[0].rf_name || "",
            rc_id: response.data.data[0].rc_id || "",
            file_name: response.data.data[0].rf_name_file || "",
            file_path: response.data.data[0].rf_path_file || "",
            rp_rf_id: response.data.data[0].rp_rf_id || "",
            rf_start_date: response.data.data[0].rf_start_date
              ? new Date(response.data.data[0].rf_start_date) 
              : "",
            rf_end_date: response.data.data[0].rf_end_date 
              ? new Date(response.data.data[0].rf_end_date) 
              : "",
          });

          if (mode.mode == 'view') {
            GetEmpReadRuleById(response.data.data[0].rp_rf_id || "");
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  function isFileImage(file) {
    const acceptedImageTypes = ["image/jpeg", "image/png", "application/pdf"];
    return file && acceptedImageTypes.includes(file["type"]);
  }

  function SaveDataFile(filePath) {
    if (mode.mode === "add") {
      const temp = {
        rf_name: dataFileDoc.rf_name.trim(),
        rc_id: dataFileDoc.rc_id,
        file_name: dataFileDoc.file_name.trim(),
        file_path: filePath,
        file: dataFileDoc.file,
        start_date: dataFileDoc.rf_start_date,
        end_date: dataFileDoc.rf_end_date,
        user_id: getUser().fup
      };
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/addFileDocument",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data.data !== false) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href ="/Human_Resource/company_regulations/Manage_documents";
              });
            } else {
              Swal.fire(
                "Error",
                "ชื่อไฟล์นี้มีอยู่ในระบบแล้ว",
                "error"
              );
            }
            //console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }
    if (mode.mode === "edit") {
      let temp;
      if (filePath) {
        temp = {
          rp_id: id,
          rf_name: dataFileDoc.rf_name.trim(),
          rc_id: dataFileDoc.rc_id,
          file_name: dataFileDoc.file_name.trim(),
          file_path: filePath,
          file: dataFileDoc.file,
          rp_rf_id: dataFileDoc.rp_rf_id,
          start_date: dataFileDoc.rf_start_date,
          end_date: dataFileDoc.rf_end_date,
        };
      } else {
        temp = {
          rp_id: id,
          rf_name: dataFileDoc.rf_name.trim(),
          rc_id: dataFileDoc.rc_id,
          file_name: dataFileDoc.file_name.trim(),
          file_path: dataFileDoc.file_path,
          file: dataFileDoc.file,
          rp_rf_id: dataFileDoc.rp_rf_id,
          start_date: dataFileDoc.rf_start_date,
          end_date: dataFileDoc.rf_end_date,
        };
      }
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/companyRegulations/editFileDocument",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data.data !== false) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href ="/Human_Resource/company_regulations/Manage_documents";
              });
            } else {
              Swal.fire(
                "Error",
                "ชื่อไฟล์นี้มีอยู่ในระบบแล้ว",
                "error"
              );
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire(
              "Error",
              "เกิดข้อผิดพลาด: " + error.response.data.error[0].errorDis,
              "error"
            );
          });
      });
    }
  }

  async function uploadFile() {
    const data = new FormData();
    data.append("Profile", dataFileDoc.file);
    Swal.fire({
      title: "คุณต้องการที่จะบันทึกหรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const error_list = [];
        if (dataFileDoc.rf_name.trim() == "") {
          let temp_err = {
            message: "กรุณากรอกชื่อไฟล์",
          };
          error_list.push(temp_err);
        }
        if (!dataFileDoc.rf_start_date) {
          let temp_err = {
            message: "กรุณากรอก Start Date",
          };
          error_list.push(temp_err);
        }
        if (!dataFileDoc.rf_end_date) {
          let temp_err = {
            message: "กรุณากรอก End Date",
          };
          error_list.push(temp_err);
        }
        if (dataFileDoc.rc_id == "") {
          let temp_err = {
            message: "กรุณาเลือกประเภทบันทึกข้อความ",
          };
          error_list.push(temp_err);
        }
        if (dataFileDoc.file_name == "") {
          let temp_err = {
            message: "กรุณาอัปโหลดไฟล์",
          };
          error_list.push(temp_err);
        }
        if (error_list.length > 0) {
          var err_message = "";
          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        } else {
          var config_uploadProfile = {
            method: "post",
            url: Configs.API_URL + "/upload/profile",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config_uploadProfile)
            .then(function (response) {
              if (response.data.status) {
                setDataFileDoc({
                  ...dataFileDoc,
                  file_path: response.data.data.path,
                });
                SaveDataFile(response.data.data.path);
              } else if (!response.data.status && mode.mode === "edit") {
                SaveDataFile();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    })
  }
  
  async function check_rf_name(e) {
    if (e.target.value.trim() === "") {
      setDataFileDoc({
        ...dataFileDoc,
        rf_name: e.target.value,
      });
      setNameNotNull(true);
    } else if (e.target.value.trim().length >= 200) {
      setNameNotNull(false);
    } else {
      setDataFileDoc({
        ...dataFileDoc,
        rf_name: e.target.value,
      });
      setNameNotNull(false);
    }
  }

  const GetAllCatgory = async () => {
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/filterManageMessageType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filterteam, // Sending filterteam as the request body
    })
      .then(async function (response) {
        const activeItems = response.data.data.filter(item => {
          return item.rc_is_active === true;
        });
        
        if (Array.isArray(response.data.data) && activeItems.length > 0) {
            setFilterCategory(activeItems);
        } else {
            setFilterCategory([]);
        }
        // setFilterCategory(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  
  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          const data = response.data.data.filter(el => {
            return el.emp_status_type_id !==  "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" && el.is_active !== false;
          }).sort((a, b) => (`${a.emp_no}`.localeCompare(b.emp_no)));
          setAllEmployee({ ...allEmployee, data: data });
          allEmployee.data = data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetEmpReadRuleById = async (rf_id) => {
    if (!rf_id) return;
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/companyRegulations/GetEmpReadRuleById",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        rf_id: rf_id,
        user_id: getUser().fup,
        company_id: getUser().com,
        oem_id: getOem(),
      },
    })
      .then(async function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          const data = response.data.data.map(el => (el.rir_user_id));
          setEmployeeReadedFile({ data });
          employeeReadedFile.data = data;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  };

  useEffect(() => {
    GetAllCatgory();
    GetEmployee();
  }, []);

  const upload_file = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (isFileImage(file) === true) {
        setDataFileDoc({
          ...dataFileDoc,
          file_name: file.name,
          file: file,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid File Type",
          text: "Please upload a file of type: JPG, PNG, or PDF only.",
        });
      }
    }
  };

  const uniqueCategories = Array.from(
    new Set(filCategory.map(el => el.rc_id))
  ).map(id => {
    return filCategory.find(el => el.rc_id === id);
  });

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group" style={{ width: "100%" }}>
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>จัดการเอกสาร</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">กฏระเบียบบริษัท</li>
                  <li className="breadcrumb-item active">จัดการเอกสาร</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode.mode === "view" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={uploadFile}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancle_add_award_point}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">จัดการเอกสาร {pageMode}</h3>
            </div>
            <div className="card-body" style={{ minHeight: '500px' }}>
              <div className="row">
                <div className="col-12 col-md-6 col-xl-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      id="award_point_name"
                      value={dataFileDoc.rf_name}
                      // placeholder="ตัวอย่าง สมศรี เรืองศักดา,Somsri Ruangsakda"
                      disabled={disable}
                      onChange={(e) => {
                        check_rf_name(e);
                      }}
                    />
                    <label>
                      ชื่อไฟล์
                      <span style={{ color: "red" }}>{ !disable ? " *" : ""}</span>
                    </label>{" "}
                    {nameNotNull ? (
                      <span style={{ color: "red" }}>
                        *กรุณากรอกชื่อไฟล์
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-6">
                  <div class={`form-group hr-log-add-datepicker-custom ${disable ? "hr-log-date-disable" : ""}`} style={{ zIndex: "97" }}>
                    <DatePicker
                      // disabled={true}
                      selected={dataFileDoc.rf_start_date
                        // new Date(moment(new Date()).add(1, "day"))
                      }
                      required
                      locale="th"
                      id="text_start_work"
                      dateFormat={"dd-MM-yyyy"}
                      disabled={disable}
                      onChange={async (date) => {
                        setDataFileDoc({
                          ...dataFileDoc,
                          rf_start_date: date,
                        });
                      }}
                      placeholderText="Start Date"
                      maxDate={dataFileDoc.rf_end_date ? new Date(dataFileDoc.rf_end_date) : undefined}
                      minDate={new Date()}
                      customInput={<CustomInput />}
                    ></DatePicker>
                    <label>Start Date
                    <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-6">
                  <div class={`form-group hr-log-add-datepicker-custom ${disable ? "hr-log-date-disable" : ""}`} style={{ zIndex: "96" }}>
                    <DatePicker
                      // disabled={true}
                      selected={dataFileDoc.rf_end_date
                        // new Date(moment(new Date()).add(1, "day"))
                      }
                      required
                      locale="th"
                      id="text_start_work"
                      dateFormat={"dd-MM-yyyy"}
                      disabled={disable}
                      onChange={async (date) => {
                        setDataFileDoc({
                          ...dataFileDoc,
                          rf_end_date: date,
                        });
                      }}
                      placeholderText="End Date"
                      minDate={dataFileDoc.rf_start_date ? new Date(dataFileDoc.rf_start_date) : new Date()}
                      // maxDate={dataFileDoc.rf_start_date ? new Date(moment(dataFileDoc.rf_start_date).startOf("D")) : undefined}
                      customInput={<CustomInput />}
                    ></DatePicker>
                    <label>End Date
                    <span style={{ color: "red" }}>{!disable ? " *" : ""}</span>
                    </label>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div
                    className="col-12 col-md-6 col-xl-6"
                    style={{ "z-index": "94" }}
                >
                    <div className="form-group ">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="drop_emptype"
                          value={dataFileDoc.rc_id}
                          disabled={disable}
                          onChange={(e) => {
                            setDataFileDoc({
                              ...dataFileDoc,
                              rc_id: e.target.value,
                            }); 
                          }}
                        >
                          <option value="" disabled selected>
                          เลือก
                          </option>
                          {uniqueCategories.map((el) => {
                          return <option value={el.rc_id}>{el.rc_name}</option>;
                          })}
                        </select>
                    <label htmlFor="" style={{ marginTop: "-4px" }}>เลือกประเภทบันทึกข้อความ<span style={{ color: "red" }}>{ !disable ? " *" : ""}</span></label>
                    </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-6">
                  <label htmlFor="">แนบไฟล์ที่เกี่ยวข้อง<span style={{ color: "red" }}>{ !disable ? " (PDF, PNG, JPEG) *" : " (PDF, PNG, JPEG)"}</span></label>
                  {/* <small style={{ color: "red" }}>
                          ** Excel Form template **
                        </small> */}
                  <div className="input-group">
                    <div className="custom-file">
                      <input
                        // onClick={clickclear}
                        type="file"
                        required
                        className="custom-file-input"
                        id="exampleInputFile"
                        accept="application/pdf, image/png, image/jpeg"
                        onChange={upload_file}
                        disabled={disable}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {dataFileDoc.file_name !== "" && 
                            dataFileDoc.file_name !== null && 
                            dataFileDoc.file_name !== undefined

                              ? dataFileDoc.file_name.length > 25
                                ? dataFileDoc.file_name.substring(0, 25) +
                                  "..."
                                : dataFileDoc.file_name
                              : "Select Image"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div hidden={mode.mode !== 'view'} className="row mt-2">
                <div className="col-12" style={{ fontWeight: '700' }}>
                  <span>รับทราบ</span> <span style={{ color: '#1DC256' }}>{employeeReadedFile.data.length || 0}</span> คน
                </div>
                {
                  allEmployee.data.filter(el => employeeReadedFile.data.includes(el.emp_id)).map((_item, _index) => {
                    return (
                      <div className="col-auto">
                        <Tooltip
                      title={`${_item.emp_no || '-'}`}
                      placement="bottom"
                    >
                        <img 
                          alt="..." 
                          className="img-fluid" style={{ width: "56px", height: "56px", objectFit: "cover", marginBottom: '10px', borderRadius: '50%' }} 
                          src={_item?.file_path
                            ? Configs.API_URL_IMG + _item.file_path
                            : TTT_Person} 
                        />
                        </Tooltip>
                      </div>
                    )
                  })
                }
              </div>
              <div hidden={mode.mode !== 'view'} className="row mt-2">
                <div className="col-12" style={{ fontWeight: '700' }}>
                  <span>ยังไม่รับทราบ</span> <span style={{ color: '#E42726' }}>{allEmployee.data.filter(el => !employeeReadedFile.data.includes(el.emp_id)).length || 0}</span> คน
                </div>
                {
                  allEmployee.data.filter(el => !employeeReadedFile.data.includes(el.emp_id)).map((_item, _index) => {
                    return (
                      <div className="col-auto">
                        <Tooltip
                      title={`${_item.emp_no || '-'}`}
                      placement="bottom"
                    >
                        <img 
                          alt="..." 
                          className="img-fluid" style={{ width: "56px", height: "56px", objectFit: "cover", marginBottom: '10px', borderRadius: '50%' }} 
                          src={_item?.file_path
                            ? Configs.API_URL_IMG + _item.file_path
                            : TTT_Person} 
                        />
                        </Tooltip>
                      </div>
                    )
                  })
                }
              </div>
              {/* card-body */}
            </div>
            {/* card */}
          </div>
        </section>
      </div>
    </div>
  );
}

export default Manage_Documents_Add;
