import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature,getAcademy } from "../../Utils/Common";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";
import Select from "react-select";
function Tow_truck_adds(mode) {
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);




  const [feature, setfeature] = useState([]);
  const [tow_truck, settow_truck] = useState({
    plate_number:"",
    chassis_number:"",
    model_car:"",
    car_type_id:"",
    truck_detail:"",
    oem_id:getOem(),
    company_id: getUser().com,
  })
  const [test, settest] = useState({
    test: "",
  });
 const [car_type,setcar_type] = useState([]);

  const { id } = useParams();


  const GetCarType = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL + "/logistic/GetCarType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    
    })
      .then(async (response) => {
       
        setcar_type(response.data);

     
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const GetTowTruck = async () => {
    await axios({
      method: "get",
      url: Configs.API_URL + "/logistic/findTowTruckById?id="+id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    
    })
      .then(async (response) => {
       
       console.log(response.data);
        settow_truck({
          plate_number:response.data.plate_number || "",
          chassis_number: response.data.chassis_number || "",
          model_car: response.data.model_car || "",
          car_type_id: response.data.car_type_id || "",
          truck_detail:response.data.truck_detail || "",
        })
     
      })
      .catch(async (error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Tow Tr";
    });
    setfeature(feature_data);

    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }

    GetCarType();
    if(mode.mode !== "add"){
      GetTowTruck();
    }
    
  }, []);

  

  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if(result.isConfirmed){
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Tow_truck";
      }
      
    });
  }

  function saveOption(params) {
    const error_list = [];
    if(tow_truck.plate_number.trim() == ""){
      let temp_err = {
        message:"กรุณากรอกข้อมูลที่ช่อง >>> [ทะเบียนรถ]."
      }
      error_list.push(temp_err);
    }
    if(tow_truck.chassis_number.trim() == ""){
      let temp_err = {
        message:"กรุณากรอกข้อมูลที่ช่อง >>> [เลขตัวถัง]."
      }
      error_list.push(temp_err);
    }

    if(tow_truck.model_car.trim() == ""){
      let temp_err = {
        message:"กรุณากรอกข้อมูลที่ช่อง >>> [รุ่นแบบ]."
      }
      error_list.push(temp_err);
    }
  
    if(tow_truck.car_type_id == ""){
      let temp_err = {
        message:"กรุณากรอกข้อมูลที่ช่อง >>> [ประเภท]."
      }
      error_list.push(temp_err);
    }
    if(error_list.length > 0){
      var err_message = "";
      for(var e =0;e< error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");

    }else{

    Swal.fire({
      title: "Do you want to save the changes?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `Save`,
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        
        if(mode.mode ==='add'){
          let temp ={
            plate_number:tow_truck.plate_number,
            chassis_number:tow_truck.chassis_number,
            model_car:tow_truck.model_car,
            car_type_id:tow_truck.car_type_id,
            truck_detail:tow_truck.truck_detail,
            oem_id:tow_truck.oem_id,
            company_id:tow_truck.company_id,
      
          }

          axios({
            method: "post",
            url: Configs.API_URL + "/logistic/addTowTruck",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
             // console.log(response.data);
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Tow_truck";
              });
            })
            .catch(async (error) => {
              console.log(error);
  
              Swal.fire("Error", error.response.data.message, "error");
            });
        }else  if(mode.mode ==='edit'){
      
          let temp ={
            id:id,
            plate_number:tow_truck.plate_number,
            chassis_number:tow_truck.chassis_number,
            model_car:tow_truck.model_car,
            car_type_id:tow_truck.car_type_id,
            truck_detail:tow_truck.truck_detail,
            oem_id:tow_truck.oem_id,
            company_id:tow_truck.company_id,
      
          }
          axios({
            method: "post",
            url: Configs.API_URL + "/logistic/editTowTruck",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
             // console.log(response.data);
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Tow_truck";
              });
            })
            .catch(async (error) => {
              console.log(error);
  
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
     
       

        
      
      }
    });
  }
  }

 







  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Tow Truck {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Tow Truck</li>
                  <li className="breadcrumb-item active">
                  Tow Truck {pageMode}{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                     <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-2 col-xl-1">
                  <button
                    type="button"
                    if
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Tow Truck {pageMode}</h3>
            </div>
            <div className="card-body">
          

 

              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={tow_truck.plate_number}
                      disabled={disable}
                      onChange={(e) => {
                        settow_truck({
                          ...tow_truck,
                          plate_number: e.target.value,
                        });
                      }}
                    />
                    <label>
                      ทะเบียนรถ
                      {tow_truck.plate_number.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={tow_truck.chassis_number}
                      disabled={disable}
                      onChange={(e) => {
                        settow_truck({
                          ...tow_truck,
                          chassis_number: e.target.value,
                        });
                      }}
                    />
                    <label>
                      เลขตัวถัง
                      {tow_truck.chassis_number.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
             
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={tow_truck.model_car}
                      disabled={disable}
                      onChange={(e) => {
                        settow_truck({
                          ...tow_truck,
                          model_car: e.target.value,
                        });
                      }}
                    />
                    <label>
                      รุ่นเเบบ
                      {tow_truck.model_car.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={tow_truck.car_type_id}
                    disabled={disable}
                    onChange={(e)=>{settow_truck({...tow_truck,car_type_id:e.target.value});
                   
                  }}
                  >
                    <option value="">--เลือกประเภท--</option>
                    {car_type.map((e)=>{
                      return (<>
                      <option value={e.id}>{e.name}</option> 
                      </>)
                    })}
                  
            
                  </select>
                  <label htmlFor="">ประเภท
                  {tow_truck.car_type_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                  </label>
                </div>
                </div>
              </div>

              <div className="row">
              <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                    <textarea
                      type="text"
                      className="form-control"
                      required
                      style={{ height: "100px", resize: "none" }}
                      value={tow_truck.truck_detail}
                      disabled={disable}
                      onChange={(e) => {
                        settow_truck({
                          ...tow_truck,
                          truck_detail: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Detail
                    
                    </label>
                  </div>
                </div>
              </div>
            
           
              </div>
              
              </div>
        </section>
        </div>
        </div>
       
  );
}

export default Tow_truck_adds;
