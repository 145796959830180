import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { MDBDataTable } from "mdbreact";
import { MultiSelect } from "react-multi-select-component";
import {
  getComConfig,
  getOem,
  getToken,
  getUser,
} from "../../../../Utils/Common";
import "../../../../../src/assets/css/Quotation_CutePlus.css";
import PO_Report from "../Purchase_Order_Admin/Component/PO_report";

function Purchase_order_Table() {
  const [Purchase_order_Table, setPurchase_order_Table] = useState({
    data: [],
  });

  const [historyPO_Table, setHistoryPO_Table] = useState({
    data: [],
  });

  const [optionsStatus, setOptionsStatus] = useState({
    data: [],
  });

  let [status_list_filter, setStatus_list_filter] = useState([]);

  const [company, setCompany] = useState();

  const [supplierDD, setSupplierDD] = useState([]);

  const [Filter, setFilter] = useState({
    po_No: "",
    supplier_name: "",
    status: [],
  });

  const [PO_dataReport, setPO_dataReport] = useState({
    data: [],
    product: [],
    detail: [],
    pic: [],
  });
  const [Product, setProduct] = useState({
    data: [],
  });

  useEffect(async () => {
    await getSupplierDD();
    await getDropdownStatus();
    await GetProduct();
    await getReport();
    await FilterPurchaseOrder();
  }, []);

  async function getReport() {
    axios(config_com)
      .then(function (response) {
        setCompany(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  const GetProduct = async () => {
    let temp = {
      code: null,
      name: null,
      category: null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWherecat2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log('getProduct', response.data)
        if (response) {
          setProduct({ data: response.data });
          Product.data = response.data;
          /////////////////////////////////////
          let data = new FormData();
          data.append("company_id", getUser().com);
          data.append("oem_id", getOem());
          data.append("user_id", getUser().fup);
          data.append("products", JSON.stringify(response.data));

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "multipart/form-data",
            },
            data: data,
          })
            .then(async (response) => {
              setProduct({ data: response.data });
              Product.data = response.data;
              return response.data;
            })
            .catch(async (error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getPOHistoryData(arr) {
    const hisList = [...arr];
    const maxIndex = arr.reduce(
      (acc, cur, index) =>
        cur.hpo_version > arr[acc].hpo_version ? index : acc,
      0
    );
    let temp = {
      hpo_id: hisList[maxIndex].hpo_id,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/getPOHistoryAndProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        console.log("response.data", response.data);
        setPO_dataReport({
          ...PO_dataReport,
          data: response.data.PO_detail[0],
          product: response.data.productPO_detail,
        });
        PO_dataReport.data = response.data.PO_detail[0];
        PO_dataReport.product = response.data.productPO_detail;

        let pushData = {
          company_id: getUser().com,
          oem_id: getOem(),
          dropdown_name: "currency",
        };
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus + "/api/product/getDropdownAdvanceProduct",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: pushData,
        })
          .then(function (response) {
            console.log("response", response);
            setPO_dataReport({
              ...PO_dataReport,
              currency: response.data
                ? response.data.filter(
                    (el) =>
                      el.currency_id === PO_dataReport.data.hpo_currency_id
                  )
                : [],
            });
            PO_dataReport.currency = response.data
              ? response.data.filter(
                  (el) => el.currency_id === PO_dataReport.data.hpo_currency_id
                )
              : [];
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });

        let dcArray = [];
        for (let i = 0; i < PO_dataReport.product.length; i++) {
          let data = {
            hpd_id: PO_dataReport.product[i].hpp_id,
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus_sales +
              "/api/purchase_order/getDiscountProductPOHistory",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: data,
          })
            .then((response) => {
              let sublistDC = [];
              for (let y = 0; y < response.data.length; y++) {
                let discount = {};
                discount.dc_value = response.data[y].hpd_discount;
                discount.dc_unit = response.data[y].hpd_unit_discount;
                sublistDC.push(discount);
              }

              dcArray.push(
                sublistDC.length !== 0
                  ? sublistDC
                  : [{ dc_value: "0", dc_unit: "Percent" }]
              );
              return dcArray;
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
        PO_dataReport.product.forEach((item, index) => {
          item.discounts = dcArray[index];
        });

        let newDetails = [];
        for (let i = 0; i < PO_dataReport.product.length; i++) {
          let temp = {
            product_id: PO_dataReport.product[i].hpp_product_id,
            company_id: getUser().com,
            oem_id: getOem(),
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus +
              "/api/product/getProductDetailsForPOAdmin",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: temp,
          })
            .then((response) => {
              console.log("response.data", response.data);
              if (response.data.length !== 0) {
                newDetails.push(response.data[0]);
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
        setPO_dataReport({ ...PO_dataReport, detail: newDetails });
        PO_dataReport.detail = newDetails;

        PO_dataReport.product?.map((el) => {
          const check = Product.data.find(
            (tl) => tl.product_id === el.hpp_product_id
          );
          if (check) {
            el.path_image = check.pci_img_path || null;
            el.name_image = check.pci_img_name || null;
            PO_dataReport.pic.push(el);
            return el;
          }
        });

        // let tempData = {
        //   company_id: getUser().com,
        //   oem_id: getOem(),
        // };
        // await axios({
        //   method: "post",
        //   url: Configs.API_URL_cuteplus + "/api/supplier/getPaymentTerm",
        //   headers: {
        //     Authorization: "Bearer " + getToken(),
        //     "X-TTT": Configs.API_TTT,
        //     "Content-Type": "application/json",
        //   },
        //   data: tempData,
        // })
        let tempData = {
          company_id: getUser().com,
          oem_id: getOem(),
          // user_id: getUser().fup,
        };
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/supplier/getConditionTerm",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(async (response) => {
            console.log("res getConditionTerm", response.data);
            setPO_dataReport({
              ...PO_dataReport,
              term: response.data
                ? response.data.filter(
                    (el) => el.ct_id === PO_dataReport.data.hpo_sup_ct_id
                  )
                : [],
            });
            PO_dataReport.term = response.data
              ? response.data.filter(
                  (el) => el.ct_id === PO_dataReport.data.hpo_sup_ct_id
                )
              : [];

            if (PO_dataReport.product.length > 0) {
              PO_Report(company, PO_dataReport, getComConfig());
            } else {
              Swal.fire({
                title: "ไม่สามารถออก Report ที่ไม่มี Product ได้",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const clearSearch = async () => {
    setStatus_list_filter(() => []);
    status_list_filter = [];

    setFilter({
      po_No: "",
      supplier_name: "",
      status: [],
    });
    Filter.po_No = "";
    Filter.supplier_name = "";
    Filter.status = [];
    await FilterPurchaseOrder();
  };

  async function FilterPurchaseOrder() {
    // console.log('status_list_filter.length', status_list_filter.length);
    let data = {
      user_id: getUser().fup,
      po_No: Filter.po_No == "" ? null : Filter.po_No.trim(),
      supplier_name: Filter.supplier_name == "" ? null : Filter.supplier_name,
      status: status_list_filter.length === 0 ? null : status_list_filter,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/purchase_order/FilterPurchaseOrder",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        // setQuotationAdmin(response.data);
        setPurchase_order_Table({
          ...Purchase_order_Table,
          data: response.data,
        });
        Purchase_order_Table.data = response.data;
        // console.log('data :>> ', response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getSupplierDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/supplier/getSupplierDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setSupplierDD(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getDropdownStatus = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/purchase_order/getPOStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: { data: "" },
    })
      .then(async function (response) {
        const options = [];
        for (let item of response.data) {
          // console.log('item', item);
          let temp = {
            label: item.ps_name,
            value: item.ps_id,
          };
          options.push(temp);
        }
        // console.log('options', options);
        setOptionsStatus({ ...optionsStatus, data: options });
        optionsStatus.data = options;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const rowsData = [];
  for (let index = 0; index < Purchase_order_Table.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    // console.log('Purchase_order_Table.data[index].history_versions', Purchase_order_Table.data[index].history_versions);
    rowItem["po_no"] = (
      <>
        {Purchase_order_Table.data[index].history_versions.length > 0 ? (
          <a
            className=""
            style={{ fontSize: "1.2rem", color: "blue", cursor: "pointer" }}
            data-toggle="modal"
            data-target="#modalQuotationCustomer"
            onClick={() => {
              setHistoryPO_Table({
                ...historyPO_Table,
                data: Purchase_order_Table.data[index].history_versions,
              });
              historyPO_Table.data =
                Purchase_order_Table.data[index].history_versions;
            }}
          >
            <u>{Purchase_order_Table.data[index].po_no}</u>
          </a>
        ) : (
          Purchase_order_Table.data[index].po_no
        )}
      </>
    );
    //  Purchase_order_Table.data[index].quo_no

    rowItem["po_sup_name"] = Purchase_order_Table.data[index].po_sup_name;

    rowItem["po_total_price"] = (
      <div style={{ textAlign: "end" }}>
        {parseFloat(Purchase_order_Table.data[index].po_total_price)
          ?.toFixed(2)
          ?.toString()
          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </div>
    );

    rowItem["po_created_by"] =
      Purchase_order_Table.data[index].po_created_name || "-";

    rowItem["po_sup_contact_name"] =
      Purchase_order_Table.data[index].po_sup_contact_name || "-";

    rowItem["status"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          type="button"
          disabled={true}
          style={{
            opacity: "1",
            width: "100%",
            border: "4px solid",
            borderRadius: "10px",
            paddingTop: ".2rem",
            paddingBottom: ".2rem",
          }}
          className={`btn btn-outline-${
            Purchase_order_Table.data[index].ps_name === "Cancel"
              ? "dark"
              : Purchase_order_Table.data[index].ps_name === "Success"
              ? "success"
              : Purchase_order_Table.data[index].ps_name === "Approved"
              ? "success"
              : Purchase_order_Table.data[index].ps_name === "Not Approved"
              ? "danger"
              : "warning"
          }`}
        >
          {Purchase_order_Table.data[index].ps_name}
        </button>
      </div>
    );

    rowItem["report"] = (
      <>
        <div>
          <button
            disabled={
              Purchase_order_Table.data[index].ps_name === "Pending" ||
              Purchase_order_Table.data[index].ps_name === "Not Approve" ||
              Purchase_order_Table.data[index].ps_name === "Cancel"
                ? true
                : false
            }
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              Swal.fire({
                title: "คุณต้องการจะออก Report นี้ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  getPOHistoryData(
                    Purchase_order_Table.data[index].history_versions
                  );
                  setPO_dataReport({ ...PO_dataReport, pic: [] });
                  PO_dataReport.pic = [];
                }
              });
            }}
          >
            Report
          </button>
        </div>
      </>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Purchase_order_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/purchase_order_cuteplus/View/" +
                Purchase_order_Table.data[index].po_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 "
          hidden={
            Purchase_order_Table.data[index].ps_name === "Success" ||
            Purchase_order_Table.data[index].ps_name === "Cancel" ||
            Purchase_order_Table.data[index].po_sent_approve === true ||
            Purchase_order_Table.data[index].po_prs_id ===
              "be9837e6-ded9-476b-9899-d0646bc4b537" ||
            Purchase_order_Table.data[index].po_prs_id ===
              "6d81dec1-3826-40f1-b6fa-fbb6829e6d88"
              ? true
              : false
          } /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <a
            key={Purchase_order_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/purchase_order_cuteplus/Edit/" +
                Purchase_order_Table.data[index].po_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const Purchase_data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "PO No.",
        field: "po_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "po_sup_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Price",
        field: "po_total_price",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Create By",
        field: "po_created_by",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Contact Name",
        field: "po_sup_contact_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const rowsHis = [];
  for (let index = 0; index < historyPO_Table.data.length; index += 1) {
    const rowItem = {};

    rowItem["his_no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["hpo_no"] = historyPO_Table.data[index].hpo_no;

    rowItem["hpo_version"] = (
      <div style={{ textAlign: "end" }}>
        {historyPO_Table.data[index].hpo_version}
      </div>
    );

    rowItem["his_management"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={historyPO_Table.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/purchase_order_cuteplus/ViewHistory/" +
                historyPO_Table.data[index].hpo_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
      </div>
    );

    rowsHis.push(rowItem);
  }

  const history_data = {
    columns: [
      {
        label: "No.",
        field: "his_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "PO No.",
        field: "hpo_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "PO Version",
        field: "hpo_version",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "his_management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsHis,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-6">
                <h1>Purchase Order (PO)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Purchase Order (PO)
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.po_No}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        po_No: e.target.value,
                      });
                      Filter.po_No = e.target.value;
                    }}
                  />
                  <label>PO No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    type="text"
                    className="form-control"
                    required
                    value={Filter.supplier_name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        supplier_name: e.target.value,
                      });
                      Filter.supplier_name = e.target.value;
                    }}
                  >
                    <option key="" value="">
                      Select a supplier
                    </option>
                    {supplierDD.map((option, index) => (
                      <option key={option.sup_name} value={option.sup_id}>
                        {option.sup_name}
                      </option>
                    ))}
                  </select>
                  <label>Supplier</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                {/* <label></label> */}
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    options={optionsStatus.data}
                    value={status_list_filter}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Status
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      //   GetProduct();
                      FilterPurchaseOrder();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Warehouse_Management/purchase_order_cuteplus/Add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Purchase Order</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={Purchase_data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="modal fade"
        id="modalQuotationCustomer"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalfoodRedisterLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title" id="modalfoodRedisterLabel">
                <b>View Purchase Order</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              {/* history_data */}
              <div className="row">
                <div className="col-12">
                  <h4>View Version PO</h4>
                </div>
              </div>
              <div className="table-responsive">
                <div className="row">
                  <div className="col-12 col-md-12 col-xl-12">
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={history_data}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Footer */}
            <div
              className="modal-footer"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                // ref={closeModalFoodRegister}
              >
                Close
              </button>
              <></>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchase_order_Table;
