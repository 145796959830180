import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import { Card } from "reactstrap";

function Time_working_person(params) {
  const [Employee, setEmployee] = useState([]);
  const [saveEmployee, setsaveEmployee] = useState([]);
  const [emptype, setemptype] = useState([]);
  const [empoptype, setempoptype] = useState([]);
  const [tabel, settabel] = useState([]);
  let [loopex, setloop] = useState([]);
  const [MDBT, setMDBT] = useState(true);
  const user_id = JSON.parse(sessionStorage.getItem("user")) || [];
  /* console.log(getUser().com); */
  const [filler_employee, setfiller_employee] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    emp_no: "",
    emp_name_th: "",
    emp_type_id: "",
    startDate: new Date(moment(new Date()).add(1, "day")),
    endDate: new Date(moment(new Date()).add(1, "day")),
    user_id: user_id.fup,
    time_id: "",
  });
  const [ckaektime_id, setckaektime_id] = useState({
    emp_no: "",
    emp_name_th: "",
    time_id: "",
  });
  const [disabletype, setdisabletype] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [getdate, setgetdate] = useState({
    day_th: "",
    month_th: "",
    year_th: "",
    swich: false,
  });
  const [forgetdate, setforgetdate] = useState({
    startDate: "",
  });
  const [currentDateSerch, setcurrentDateSerch] = useState(new Date());
  const [test, settest] = useState({
    test: ""
  });
  const stock_month = [
    {th:"มกราคม",eng:"January"},
    {th:"กุมภาพันธ์",eng:"February"},
    {th:"มีนาคม",eng:"March"},
    {th:"เมษายน",eng:"April"},
    {th:"พฤษภาคม",eng:"May"},
    {th:"มิถุนายน",eng:"June"},
    {th:"กรกฎาคม",eng:"July"},
    {th:"สิงหาคม",eng:"August"},
    {th:"กันยายน",eng:"September"},
    {th:"ตุลาคม",eng:"October"},
    {th:"พฤศจิกายน",eng:"November"},
    {th:"ธันวาคม",eng:"December"},
  ];
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );
  async  function defaultSelectview(config_period_time_id) {
    console.log(config_period_time_id);
      setckaektime_id({
        ...ckaektime_id,
        time_id: config_period_time_id,
      });
      ckaektime_id.time_id = config_period_time_id;
    }
  function chcek_box(chaek, emp_id, config_period_time_id, per_id,current_config_id) {
    var tabelindex = [];
    console.log(chaek, emp_id, config_period_time_id, per_id);
    // console.log("e",e)
    if (chaek === true) {
      setloop([]);
      loopex = [] ;
    }else{
      tabelindex = loopex;
    }
    
    var check = loopex.filter((e) => {
      return e.emp_id === emp_id;
    });
  //   //  console.log("check",check)
    if (check.length > 0) {
      var objIndex = loopex.findIndex((e) => e.emp_id == emp_id);
      loopex.splice(objIndex, 1);

    } else {
      let as = {
        emp_id: emp_id,
        config_period_time_id: config_period_time_id,
        per_id: per_id || null,
        startDate: currentDateSerch,
      };
      // loopex.push(as);
        // console.log("as",as)
      //   if (chaek === true) {
      //     tabelindex.push(as);
      //     setloop(tabelindex);
      //     loopex = tabelindex; 
      // } else {
        defaultSelectview(current_config_id);
        tabelindex.push(as);
        setloop(tabelindex);
        loopex = tabelindex;
        // console.log("tabelindex",tabelindex);
      // }
        
       
       
    
      // settest({...test,test:""})
    }
    settest({...test,test:""})
  //   console.log("loopex",loopex);
    // setMDBT(true);
    // GetEmployee();
  }
  function Check_loop_save() {
    var loopall_tabel = [];
      console.log("loopex",loopex,ckaektime_id.time_id )

    if (ckaektime_id.time_id === "" && loopex.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "กรุณาระบุกะเวลา หรือ พนักงาน",
        text: "เเจ้งเตือน",
      });
    } else {
      for (let i of loopex) {
        const date_dif = moment(filler_employee.endDate).diff(
          filler_employee.startDate,
          "days"
        );
          // console.log("date_dif", date_dif);
        for (let index = 0; index <= date_dif; index++) {
          console.log(
            moment(filler_employee.startDate)
              .add(index, "day")
              .format("yyyy-MM-DD")
          );

          let as = {
            emp_id: i.emp_id,
            config_period_time_id: ckaektime_id.time_id,
            per_id: i.per_id,
            set_date: moment(filler_employee.startDate)
              .add(index, "day")
              .format("yyyy-MM-DD"),
            user_id: user_id.fup,
          };

          loopall_tabel.push(as);
        }
        //console.log("date_dif",date_dif);
        /*  let as = {
          emp_id: i.emp_id,
          config_period_time_id: ckaektime_id.time_id,
          per_id: i.per_id,
          set_date: moment(filler_employee.startDate).format("yyyy-MM-DD"),
          user_id: user_id.fup,
        };
        loopall_tabel.push(as); */
      }
      // console.log("loopall_tabel", loopall_tabel);
      savetableperiodtime(loopall_tabel);
    
    }
  }
  const handleChangeSerch = (date) => {
    // console.log("date",date);
    var month = stock_month.filter((ei) => {
      return ei.th === moment(new Date(date)).format("MMMM");
    });
    // console.log("month", moment(new Date(date)).format("MMMM"));
    setgetdate({
      ...getdate,
      day_th: moment(new Date(date)).format("DD"),
      year_th: moment(new Date(date)).add(543, "y").format("YYYY"),
      month_th: month[0].th,
    });
    getdate.day_th = moment(new Date(date)).format("DD");
    getdate.year_th = moment(new Date(date)).add(543, "y").format("YYYY");
    getdate.month_th = month[0].th;

    if (
      moment(new Date()).format("YYYY-MM-DD 00:00") <
      moment(date).format("YYYY-MM-DD 00:00")
    ) {
      setdisabletype(false);
    } else {
      setdisabletype(true);
    }

    setStartDate(date);
    GetEmployee(date);

  };
  const handleChange = (date) => {
    /*   var month = stock_month.filter((ei) => {
      return ei.eng === moment(new Date()).format("MMMM");
    });

    setgetdate({
      ...getdate,
      day_th: moment(new Date(date)).format("DD"),
      year_th: moment(new Date(date)).add(543, "y").format("YYYY"),
      month_th: month[0].th,
    });
    getdate.day_th = moment(new Date(date)).format("DD");
    getdate.year_th = moment(new Date(date)).add(543, "y").format("YYYY");
    getdate.month_th = month[0].th; */

    if (
      moment(new Date()).format("YYYY-MM-DD 00:00") <
      moment(date).format("YYYY-MM-DD 00:00")
    ) {
      setdisabletype(false);
    } else {
      setdisabletype(true);
    }

    filler_employee.startDate = moment(date).format("YYYY-MM-DD 00:00");

    /*   setStartDate(date);
   GetEmployee(date); */
  };
  const handleClick = (e) => {
    e.preventDefault();

    setIsOpen(!isOpen);
  };

  const GetemployeeTyer = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    /*  console.log("get_com_oem",get_com_oem); */
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        /*  console.log(response.data.data,"dddd"); */
        setemptype(response.data.data);
        setempoptype(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetEmployee = async (date) => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: filler_employee.emp_no.trim(),
      emp_name_th: filler_employee.emp_name_th.trim(),
      emp_type_id: filler_employee.emp_type_id,
      startDate: date,
    };
    console.log(get_filler_employee.startDate);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterEmployeetabelperiodtime",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(function (response) {
        setMDBT(true);
        setEmployee(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const Gettableperiodtime = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/getTabelperiodtime",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        settabel(response.data.data);

        if (response.data.data.length > 0) {
          setckaektime_id({
            ...ckaektime_id,
            time_id: response.data.data[0].time_working_id,
          });
          setdisabletype(true);
        }

        /*  console.log(response.data.data); */
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    setforgetdate({ startDate: moment(new Date()).add(1, "day") });
    forgetdate.startDate = moment(new Date()).add(1, "day");

    var month = stock_month.filter((ei) => {
      return ei.eng === moment(new Date()).locale("en").format("MMMM");
    });

    setgetdate({
      ...getdate,
      day_th: moment(new Date()).format("DD"),
      year_th: moment(new Date()).add(543, "y").format("YYYY"),
      month_th: month[0].th,
    });
    getdate.day_th = moment(new Date()).format("DD");
    getdate.year_th = moment(new Date()).add(543, "y").format("YYYY");
    getdate.month_th = month[0].th;
    setdisabletype(true);
    /*   getdate.month_th  = month[0].th */

    // getdate.year_th = moment(new Date()).add(543, 'y').format('YYYY');
    GetemployeeTyer();
    Gettableperiodtime();
    GetEmployee(new Date());
  }, []);

  const clearFilter = async () => {
    setMDBT(false);
    await setfiller_employee({
      ...filler_employee,
      emp_name_th: "",
      emp_no: "",
      emp_type_id: "",
    });
    setloop([]);
    filler_employee.emp_name_th = "";
    filler_employee.emp_no = "";
    filler_employee.emp_type_id = "";
    var month = stock_month.filter((ei) => {
      return ei.eng === moment(new Date()).format("MMMM");
    });

    setgetdate({
      ...getdate,
      day_th: moment(new Date()).format("DD"),
      year_th: moment(new Date()).add(543, "y").format("YYYY"),
      month_th: month[0].th,
    });
    getdate.day_th = moment(new Date()).format("DD");
    getdate.year_th = moment(new Date()).add(543, "y").format("YYYY");
    getdate.month_th = month[0].th;

    setStartDate(new Date());

    setdisabletype(false);
    GetEmployee(currentDateSerch);
  };

  function addpage(params) {
    window.location.href = "/Human_Resource/workingkaday/time_working_group";
  }

  /* var loopdatasave = [] */

  const savetableperiodtime = async (data) => {
    /* console.log(data, "dda"); */
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/hrManagement/updatekaworkinday",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    }).then(async function (response) {
      Swal.fire({
        icon: "success",
        title: "Save",
        showConfirmButton: false,
        timer: 1500,
      });
      // setMDBT(true);
     await  GetEmployee(currentDateSerch);
    });
    // setMDBT(true);
    // GetEmployee();
    /* await addpage().catch(function (error) {
      console.log(error);
    }) */;
  };

  const rowsData = [];
  const looptabel = [];
  looptabel.push(
    {
      label: "No",
      field: "no",
      sort: "asc",
      width: 50,
    },
    {
      label: "ชื่อ - นามสกุล",
      field: "emp_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "รหัสพนักงาน",
      field: "emp_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "ปกติ",
      field: "emp_normal",
      sort: "asc",
      width: 50,
    }
  );
  /*  console.log(Employee) */
  for (let i = 0; i < tabel.length; i++) {
    if (tabel[i].label !== "ปกติ") {
      looptabel.push({
        label: tabel[i].label,
        field: tabel[i].field,
        sort: "asc",
        width: 50,
      });
    }
  }
  looptabel.push({
    label: "จัดการ",
    field: "mgt",
    sort: "asc",
    width: 50,
  });
  for (var index = 0; index < Employee.length; index++) {
    /*    console.log(Employee[index].emp_name_th) */
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["emp_name"] = Employee[index].emp_name_th;
    rowItem["emp_no"] = Employee[index].emp_no;

    for (let io = 0; io < tabel.length; io++) {
      if (
        tabel[io].label === Employee[index].period_time_name &&
        Employee[index].period_time_name !== "ปกติ"
      ) {
        rowItem[tabel[io].field] = (
          <div class="text-center ">
            <i class="fa fa-check-circle " style={{ color: "#00B331" }}></i>
          </div>
        );
      } else if (
        Employee[index].period_time_name === null ||
        Employee[index].period_time_name === "ปกติ"
      ) {
        rowItem["emp_normal"] = (
          <div class="text-center">
            <i class="fa fa-check-circle " style={{ color: "#00B331" }}></i>
          </div>
        );
      }
    }

    if (
      moment(new Date()).format("YYYY-MM-DD 00:00") <
      moment(startDate).format("YYYY-MM-DD 00:00")
    ) {
      rowItem["mgt"] = (
        <div className="row text-center">
          <div className="col">
            <a
              data-toggle="modal"
              data-target="#set_period_time"
              key={Employee[index].emp_id}
              onClick={chcek_box.bind(
                this,
                true,
                Employee[index].emp_id,
                Employee[index].config_period_time_id,
                Employee[index].per_id,
                Employee[index].period_time_id,
              )
            }
            >
              {" "}
              <i class="fas fa-pencil-alt"></i>{" "}
            </a>
          </div>
        </div>
      );
    } else {
      rowItem["mgt"] = (
        <div className="row text-center">
          <div className="col">
            <a data-toggle="modal" data-target="#set_period_time" onClick={defaultSelectview.bind(this,Employee[index].period_time_id)}>
              {" "}
              <i class="fas fa-eye"></i>{" "}
            </a>
          </div>
        </div>
      );
    }

    rowsData.push(rowItem);
  }

  const data = {
    columns: looptabel,
    rows: rowsData,
  };

  const rowsData2 = [];
  const looptabe2 = [];
  looptabe2.push(
    {
      label: "No",
      field: "no",
      sort: "asc",
      width: 50,
    },
    {
      label: "ชื่อ - นามสกุล",
      field: "emp_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "รด/รหัส",
      field: "emp_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "จัดการ",
      field: "mgt",
      sort: "asc",
      width: 30,
    }
  );

  for (var index = 0; index < Employee.length; index++) {
    /*    console.log(Employee[index].emp_name_th) */
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["emp_name"] = Employee[index].emp_name_th;
    rowItem["emp_no"] = Employee[index].emp_no;

    var check = loopex.filter((e) => {
      return e.emp_id === Employee[index].emp_id;
    });
    if (check.length > 0) {
      /*   console.log("dddx",loopex) */
      var check_viwe = true;
    } else {
      /*  console.log("dddz",loopex) */
      var check_viwe = false;
    }
    rowItem["mgt"] = (
      <div className="row text-center">
        <div class="custom-control custom-switch ">
          <input
            type="checkbox"
            class="form-check-input"
            id="is_fifo"
            checked={check_viwe}
            key={Employee[index].emp_id}
            onClick={chcek_box.bind(
              this,
              false,
              Employee[index].emp_id,
              Employee[index].config_period_time_id,
              Employee[index].per_id,
              Employee[index].period_time_id,
            )}
          />
        </div>
      </div>
    );

    rowsData2.push(rowItem);
  }
  const data2 = {
    columns: looptabe2,
    rows: rowsData2,
  };

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>ข้อมูลกะการทำงานพนักงาน</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">
                    ข้อมูลกะการทำงานพนักงาน
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_employee.emp_no}
                    onChange={(e) => {
                      setfiller_employee({
                        ...filler_employee,
                        emp_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">รด/รหัส</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_employee.emp_name_th}
                    onChange={(e) => {
                      setfiller_employee({
                        ...filler_employee,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                </div>
              </div>
              <div
                className="col-md-2 col-sm-12 col-lg-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_emptype"
                    value={filler_employee.emp_type_id}
                    onChange={(e) => {
                      setfiller_employee({
                        ...filler_employee,
                        emp_type_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected></option>
                    {emptype.map((el) => {
                      return (
                        <option value={el.period_time_id}>
                          {el.period_time}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor=""> ประเภทพนักงาน</label>
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetEmployee(currentDateSerch);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    data-toggle="modal"
                    data-target="#set_allperiod_time"
                  >
                    กำหนดกะทำงาน
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <ReactHTMLTableToExcel
                    id="test1-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="TimeWorkinPerson_table"
                    filename={"TimeWorkinPerson"}
                    sheet="tablexls"
                    buttonText="Export Excel"
                  />
                </div>
                {/* <div className="form-group ">

                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                
                  >
                    Export Excel
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <div className="col-3 col-md-3 col-xl-6">
                <h1>
                  ข้อมูลประจำวันที่
                  {" " +
                    getdate.day_th +
                    " " +
                    getdate.month_th +
                    " " +
                    getdate.year_th}{" "}
                  {"  "}
                </h1>
              </div>

              <div
                className="col-12 col-md-12 col-xl-8"
                style={{ "z-index": "99" }}
              >
                <div class="form-group">
                  <DatePicker
                    // disabled={true}

                    selected={currentDateSerch || new Date()}
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setcurrentDateSerch(date);
                      handleChangeSerch(date);
                    }}
                    selectsStart
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>วันที่เปลียนกะ</label>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="set_period_time"
              tabindex="-1"
              role="dialog"
              aria-labelledby="set_period_timetiter"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div className="modal-header">
                    <h3>ระบุเลือกกะการทำงาน</h3>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      //onClick={set_bomlist_excel}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="d-flex justify-content-center">
                      <div className="form-group  col-10  ">
                        <select
                          className="form-control custom-select select2"
                          type="text"
                          required
                          id="drop_emptype2"
                          value={ckaektime_id.time_id}
                          disabled={disabletype}
                          onChange={(e) => {
                            setckaektime_id({
                              ...ckaektime_id,
                              time_id: e.target.value,
                            });
                            /* Check_loop_save(e.target.value) */
                          }}
                        >
                          <option value="" disabled selected>
                            เลือก
                          </option>
                          {empoptype.map((el) => {
                            return (
                              <option value={el.period_time_id}>
                                {el.period_time}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer d-flex justify-content-center">
                    <button
                      type="button"
                      class="btn btn-primary "
                      disabled={disabletype}
                      onClick={Check_loop_save}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade "
              id="set_allperiod_time"
              aria-labelledby="set_period_timetiter"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-xl"
                role="document"
              >
                <div class="modal-content">
                  <div className="modal-header">
                    <h3>ระบุเลือกกะการทำงาน</h3>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      //onClick={set_bomlist_excel}
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_employee.emp_no}
                            onChange={(e) => {
                              setfiller_employee({
                                ...filler_employee,
                                emp_no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">รด/รหัส</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_employee.emp_name_th}
                            onChange={(e) => {
                              setfiller_employee({
                                ...filler_employee,
                                emp_name_th: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">ชื่อพนักงาน</label>{" "}
                        </div>
                      </div>
                      <div
                        className="col-md-2 col-sm-12 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <select
                            className="form-control custom-select select2"
                            type="text"
                            required
                            id="drop_emptype"
                            value={filler_employee.emp_type_id}
                            onChange={(e) => {
                              setfiller_employee({
                                ...filler_employee,
                                emp_type_id: e.target.value,
                              });
                            }}
                          >
                            <option value="" disabled selected></option>
                            {emptype.map((el) => {
                              return (
                                <option value={el.period_time_id}>
                                  {el.period_time}
                                </option>
                              );
                            })}
                          </select>
                          <label htmlFor=""> ประเภทพนักงาน</label>
                        </div>
                      </div>
                      <div className="col-4 col-md-3 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info  "
                            onClick={() => {
                              setMDBT(false);
                              GetEmployee();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      <div className="col-4 col-md-3 col-xl-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            onClick={() => {
                              setMDBT(false);
                              clearFilter();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-4 col-md-3 col-xl-2"
                        style={{ "z-index": "99" }}
                      >
                        <div class="form-group">
                          <DatePicker
                            // disabled={true}
                            selected={
                              filler_employee.startDate ||
                              new Date(moment(new Date()).add(1, "day"))
                            }
                            locale="th"
                            id="text_start_work"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setfiller_employee({
                                ...filler_employee,
                                startDate: date,
                              });
                              handleChange(date);
                            }}
                            minDate={new Date(forgetdate.startDate)}
                            customInput={<CustomInput />}
                          ></DatePicker>
                          <label>วันที่เริ่มเปลียนกะ</label>
                        </div>
                      </div>
                      <div
                        className="col-4 col-md-3 col-xl-2"
                        style={{ "z-index": "99" }}
                      >
                        <div class="form-group ">
                          <DatePicker
                            // disabled={true}
                            minDate={new Date(forgetdate.startDate)}
                            selected={
                              filler_employee.endDate ||
                              new Date(moment(new Date()).add(1, "day"))
                            }
                            locale="th"
                            id="text_end_work"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setfiller_employee({
                                ...filler_employee,
                                endDate: date,
                              });
                            }}
                            selectsStart
                            customInput={<CustomInput />}
                          ></DatePicker>
                          <label>วันที่สิ้นสุดเปลียนกะ</label>
                        </div>
                      </div>
                      <div className="col-4 col-md-3 col-xl-2">
                        <div className="form-group ">
                          <label>ช่วงเวลา</label>
                          <select
                            className="form-control custom-select select2"
                            type="text"
                            required
                            id="drop_emptype"
                            value={ckaektime_id.time_id}
                            disabled={disabletype}
                            onChange={(e) => {
                              setckaektime_id({
                                ...ckaektime_id,
                                time_id: e.target.value,
                              });
                              /* Check_loop_save() */
                            }}
                          >
                            <option value="" disabled selected>
                              select
                            </option>
                            {emptype.map((el) => {
                              return (
                                <option value={el.period_time_id}>
                                  {el.period_time}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="form-group  col-12 ">
                        <div
                          className="card-body table-responsive "
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed "
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={MDBT}
                            data={data2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer d-flex justify-content-center">
                    <button
                      type="button"
                      class="btn btn-primary "
                      disabled={disabletype}
                      onClick={Check_loop_save}
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed "
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />

              <table id="TimeWorkinPerson_table" style={{ display: "none" }}>
                <tr>
                  <th>No</th>
                  <th>ชื่อ - นามสกุล</th>
                  <th>รด/รหัส</th>
                  <th>ปกติ</th>
                  <th>วันทำงาน</th>
                </tr>
                {Employee.map((e, index) => {
                  return (
                    <>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{e.emp_name_th}</td>
                        <td>{e.emp_no}</td>
                        <td></td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Time_working_person;
