import React from "react";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Configs from "../../../../config";

function UserComponent(detailUser) {
  // console.log("detailUser", detailUser.detail);
  let detail = detailUser.detail;
  // console.log("detail", detail);
  /* productImage: "https://picsum.photos/1200/1000",
          productName: "ทดสอบตัวที่ 20",
          productDetail: "ทดสอบตัวที่ 20",
          productValue: "10,000",
          productSold: "10,000",
          discount: "10",
          discountValue: "9,000", */
  return (
    <>
      {detail.check === false ? (
        <div
          style={{
            display: "flex",
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #003583",
            borderRadius: "50px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              boxShadow: "1px 1px 4px 1px rgba(0, 0, 0, 0.25)",
              borderRadius: "50px",
              width: "61px",
              height: "61px",
              margin: "4px 0px 4px 4px",
            }}
          >
            <img
              src={
                detail.emp_img !== null && detail.emp_img !== undefined
                  ? Configs.API_URL_IMG + detail.emp_img
                  : userdefault_img.imgs
              }
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              margin: "0px 0px 0px 8px",
            }}
          >
            <label
              style={{
                color: "#0395FF",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              {/* พีรวิชญ์ ธนะศรีรังกูล */}
              {detail.emp_name_th
                ? detail.emp_name_th.length >= 25
                  ? detail.emp_name_th.substring(0, 25) + "..."
                  : detail.emp_name_th
                : "-"}
            </label>
            <label
              style={{
                color: "#003583",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              ชื่อเล่น
              <p
                style={{
                  display: "inline",
                  color: "#003583",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginLeft: "50px",
                }}
              >
                {/* :พี */}
                {": " + (detail.nickname ? detail.nickname : "-")}
              </p>
            </label>
            <label
              style={{
                color: "#003583",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              รหัสพนักงาน
              <p
                style={{
                  display: "inline",
                  color: "#003583",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginLeft: "20px",
                }}
              >
                {/* :TTTPro16 */}
                {": " + (detail.emp_no ? detail.emp_no : "-")}
              </p>
            </label>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            boxSizing: "border-box",
            background: "#003583",
            border: "0.5px solid #003583",
            borderRadius: "50px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              boxShadow: "1px 1px 4px 1px rgba(0, 0, 0, 0.25)",
              borderRadius: "50px",
              width: "61px",
              height: "61px",
              margin: "4px 0px 4px 4px",
            }}
          >
            <img
              src={
                detail.emp_img !== null && detail.emp_img !== undefined
                  ? Configs.API_URL_IMG + detail.emp_img
                  : userdefault_img.imgs
              }
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              margin: "0px 0px 0px 8px",
            }}
          >
            <label
              style={{
                color: "#FFFFFF",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              {/* พีรวิชญ์ ธนะศรีรังกูล */}
              {detail.emp_name_th
                ? detail.emp_name_th.length >= 25
                  ? detail.emp_name_th.substring(0, 25) + "..."
                  : detail.emp_name_th
                : "-"}
            </label>
            <label
              style={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              ชื่อเล่น
              <p
                style={{
                  display: "inline",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginLeft: "50px",
                }}
              >
                {/* :พี */}
                {": " + (detail.nickname ? detail.nickname : "-")}
              </p>
            </label>
            <label
              style={{
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "normal",
                margin: "0px",
              }}
            >
              รหัสพนักงาน
              <p
                style={{
                  display: "inline",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginLeft: "20px",
                }}
              >
                {/* :TTTPro16 */}
                {": " + (detail.emp_no ? detail.emp_no : "-")}
              </p>
            </label>
          </div>
        </div>
      )}
    </>
  );
}

export default UserComponent;
