/**
* ชื่อไฟล์: addMonitor.js
* คําอธิบาย: เพิ่มมอนิเตอร์
* Input: ข้อมูลมอนิเตอร์ที่ต้องการเพิ่ม
* Output: มอนิเตอร์ได้รับการเพิ่ม
* ชื่อผู้เขียน/แก้ไข: ณัฐวุฒิ สมดุลยกนก
* วันที่จัดทํา/แก้ไข: 14 กันยายน 2566
*/


import React, { useState, useEffect } from "react";
/* import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import Footter from "../component/Footter"; */
import "../../assets/css/IOT_Connect/Content.css";
import "../../assets/css/IOT_Connect/LandingPage.css";
import "../../assets/css/IOT_Connect/addMonitor.css";
/* import Oem from "../component/OEM";
import CustomerService from "../component/CustomerService"; */
import { useLocation, useHistory } from 'react-router-dom';
import view from '../../assets/img/Dashboard/addUnai.svg';

import {
    getUser,
    getToken,
    setOemlist,
    getOem,
    getComConfig,
  } from "../../Utils/Common";
  import Configs from "../../config";
import axios from "axios";
/* import {
    Icon_factory,
    factory,
    userdefault_img,
    Icon_contact,
    Icon_Choose,
} from "../routes/imgRoute/imgUrl"; */
import Swal from "sweetalert2";
function AddMonitor(params) {
    const location = useLocation();
    const [imsMonitorIds, setImsMonitorIds] = useState([]);
    const [imsMonitorName, setImsMonitorName] = useState([]);

    useEffect(() => {
        // When the component mounts, set imsMonitorIds from the location.state
        setImsMonitorIds(location.state.imsMonitorIds);
        setImsMonitorName(location.state.imsMonitorName);
    }, [location.state.imsMonitorIds, location.state.imsMonitorName]);

    const [dataInput, addData] = useState({

    // console.log("type : " + typeof(dataInput.monitorId));

    // const missingFields = [];
    // const alreadyFields = [];
    // // Parse monitorId to an integer
    // const monitorId = parseInt(dataInput.monitorId, 10);
    // console.log("type monitor id: " + typeof(monitorId));


    // // Check if the required fields are filled
    // if (imsMonitorIds.includes(monitorId)) {
    //   alreadyFields.push('Monitor ID : ' + monitorId);
    // }
    // if (imsMonitorName.includes(dataInput.monitorName)) {
    //   alreadyFields.push('Monitor Name : ' + dataInput.monitorName);
    // }
    // if (!monitorId) {
    //   missingFields.push('Monitor ID');
    // }
    // if (!dataInput.monitorName) {
    //   missingFields.push('Monitor Name');
    // }
    // if (!dataInput.monitorLocation) {
    //   missingFields.push('Location');
    // }
    // if (dataInput.minute + dataInput.hour == 0) {
    //   missingFields.push('Frequency');
    // }

    // // Check if the required fields are filled
    // if (missingFields.length > 0) {
    //   Swal.fire({
    //     title: 'Error',
    //     html: `Please fill in the following required fields: <br>${missingFields.join('<br>')}`,
    //     icon: 'error',
    //     confirmButtonColor: '#1A56DB',
    //     customClass: {
    //       cancelButton: 'custom-swal-cancel-button',
    //     },
    //   });
    //   return; // Don't proceed if required fields are missing
    // } 
    // else if (alreadyFields.length > 0){
    //   Swal.fire({
    //     title: 'Error',
    //     html: `This <br>${alreadyFields.join('<br>')} <br>is already in use.`,
    //     icon: 'error',
    //     confirmButtonColor: '#1A56DB',
    //     customClass: {
    //       cancelButton: 'custom-swal-cancel-button',
    //     },
    //   });
    //   return;
    // }
    

    // // Calculate monitorFrequency
    // const monitorFrequency = (parseInt(dataInput.hour, 10) * 60) + parseInt(dataInput.minute, 10);

    // // Include monitorFrequency in the dataInput object
    // dataInput.monitorFrequency = monitorFrequency;

    // // Define the configuration for the POST request
    // var config_add = {
    //   method: "post",
    //   url: 'http://150.95.25.8:6682/powerMonitorConnection/add', // Make sure this URL matches your server endpoint
    //   headers: {
    //     Authorization: getToken(),
    //     "X-TTT": Configs.API_TTT,
    //     "Content-Type": "application/json",
    //   },
    //   data: dataInput, // Include the monitorData in the request body
    // };

    // Swal.fire({
    //   title: 'Confirm Add Monitor',
    //   text: 'Are you sure you want to add Power monitor?',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   reverseButtons: true,
    //   confirmButtonColor: '#1A56DB',
    //   confirmButtonText: 'Confirm',
    //   customClass: {
    //     cancelButton: 'custom-swal-cancel-button',
    //   },
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     axios(config_add);
    //     window.location.href = '/IoT_Master_Data/PowerMonitorConnection';
    //   }
    // }).catch((error) => {
    //   // Handle any errors that occur during the request
    //   console.error(error);
    //   Swal.fire({
    //     title: 'Error',
    //     text: 'An error occurred while adding the power monitor. Please try again later.',
    //     icon: 'error',
    //     confirmButtonColor: '#1A56DB',
    //     customClass: {
    //       cancelButton: 'custom-swal-cancel-button',
    //     },
    //   });
        monitorId: "",
        monitorName: "",
        monitorDetail: "",
        monitorLocation: "",
        monitorRemark: "",
        monitorFrequency: "",
        hour: "0",
        minute: "0",
    });

    const handleClickAdd = (event) => {
        event.preventDefault();

        console.log("type : " + typeof (dataInput.monitorId));

        const missingFields = [];
        const alreadyFields = [];
        // Parse monitorId to an integer
        const monitorId = parseInt(dataInput.monitorId, 10);
        console.log("type monitor id: " + typeof (monitorId));


        // Check if the required fields are filled
        if (imsMonitorIds.includes(monitorId)) {
            alreadyFields.push('Monitor ID : ' + monitorId);
        }
        if (imsMonitorName.includes(dataInput.monitorName)) {
            alreadyFields.push('Monitor Name : ' + dataInput.monitorName);
        }
        if (!monitorId) {
            missingFields.push('Monitor ID');
        }
        if (!dataInput.monitorName) {
            missingFields.push('Monitor Name');
        }
        if (!dataInput.monitorLocation) {
            missingFields.push('Location');
        }
        if (dataInput.minute + dataInput.hour == 0) {
            missingFields.push('Frequency');
        }

        // Check if the required fields are filled
        if (missingFields.length > 0) {
            Swal.fire({
                title: 'Error',
                html: `Please fill in the following required fields: <br>${missingFields.join('<br>')}`,
                icon: 'error',
                confirmButtonColor: '#1A56DB',
                customClass: {
                    cancelButton: 'custom-swal-cancel-button',
                },
            });
            return; // Don't proceed if required fields are missing
        }
        else if (alreadyFields.length > 0) {
            Swal.fire({
                title: 'Error',
                html: `This <br>${alreadyFields.join('<br>')} <br>is already in use.`,
                icon: 'error',
                confirmButtonColor: '#1A56DB',
                customClass: {
                    cancelButton: 'custom-swal-cancel-button',
                },
            });
            return;
        }

        // Calculate monitorFrequency
        const monitorFrequency = (parseInt(dataInput.hour, 10) * 60) + parseInt(dataInput.minute, 10);

        // Include monitorFrequency in the dataInput object
        dataInput.monitorFrequency = monitorFrequency;

        // Define the configuration for the POST request
        var config_add = {
            method: "post",
            url: `${Configs.API_URL_IoT_Connect}/powerMonitorConnection/add`, // Make sure this URL matches your server endpoint
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: dataInput, // Include the monitorData in the request body
        };

        Swal.fire({
            title: 'Success',
            text: 'Power monitor added successfully!',
            icon: 'success',
            confirmButtonColor: '#1A56DB',
            customClass: {
                cancelButton: 'custom-swal-cancel-button',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios(config_add);
                window.location.href = '/IoT_Master_Data/PowerMonitorConnection';
            }
        }).catch((error) => {
            // Handle any errors that occur during the request
            console.error(error);
            Swal.fire({
                title: 'Error',
                text: 'An error occurred while adding the power monitor. Please try again later.',
                icon: 'error',
                confirmButtonColor: '#1A56DB',
                customClass: {
                    cancelButton: 'custom-swal-cancel-button',
                },
            });
        });

        // // Send a POST request to your server using the config_add object
        //   .then(() => {
        //     // Handle the response from the server, e.g., show a success message    
        //   })

    };

    const handleClickCancel = (event) => {
        event.preventDefault();
        {
            Swal.fire({
                title: 'Cancel',
                text: 'Are you sure you want to cancel this process?',
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                // cancelButtonColor: '#FFF',
                confirmButtonColor: '#1A56DB',
                confirmButtonText: 'Confirm',
                customClass: {
                    cancelButton: 'custom-swal-cancel-button', // Apply the custom class to the cancel button
                },

            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/IoT_Master_Data/PowerMonitorConnection';
                }
            });
        };
    };

    return (
        <div className="content-wrapper bg-[#FEFEFE]">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">

                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/Welcome">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/IoT_Master_Data/PowerMonitorConnection">Power Connection</a>
                                </li>
                                <li className="breadcrumb-item active">Add Power Monitor</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div class="px-0 mx-auto max-w-7xl pb-5 sm:px-4 mx-bt">
                        <div class="form-frame w-full px-8 pt-4 pb-2 mx-auto bg-white rounded-[10px] sm:rounded-lg sm:w-full md:w-8/12 lg:w-full xl:w-full sm:px-6">
                            <div className="add-title-iot">
                                <img src={view} />
                                <h1 class="title-add text-center text-gray-900">Add Power Monitor</h1>
                            </div>

                            <>
                                <div className="row mx-3">
                                    <div className="col-12 col-md-6 col-xl-4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                maxlength="6"
                                                className="form-control"
                                                value={dataInput.monitorId}
                                                onChange={(e) =>
                                                    addData({ ...dataInput, monitorId: e.target.value })
                                                }
                                                required
                                            />
                                            <label htmlFor="">
                                            Monitor ID 
                                                {dataInput.monitorId.trim() === "" ? (
                                                    <span style={{ color: "red" }}> *</span>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-3">
                                        <div className="col-12 col-md-6 col-xl-4">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={dataInput.monitorName}
                                                    onChange={(e) =>
                                                        addData({ ...dataInput, monitorName: e.target.value })
                                                    }
                                                    required
                                                />
                                                <label htmlFor="">
                                                Monitor Name
                                                    {dataInput.monitorName.trim() === "" ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                </div>
                                <div className="row mx-3">
                                    <div className="col-12 col-md-8 col-xl-6">
                                        <div className="form-group">
                                            <textarea
                                                style={{ fontSize: '1.3em' }}
                                                className="form-control"
                                                rows={2}
                                                placeholder=""
                                                value={dataInput.monitorDetail}
                                                onChange={(e) =>
                                                    addData({ ...dataInput, monitorDetail: e.target.value })
                                                }
                                                required
                                            />
                                            <label htmlFor="">
                                            Monitor Detail
                                                {dataInput.monitorDetail.trim() === "" ? (
                                                    <span style={{ color: "red" }}> *</span>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-3">
                                    <div className="col-12 col-md-8 col-xl-6">
                                        <div className="form-group">
                                            <textarea
                                                style={{ fontSize: '1.3em' }}
                                                className="form-control"
                                                rows={2}
                                                placeholder=""
                                                value={dataInput.monitorLocation}
                                                onChange={(e) =>
                                                    addData({ ...dataInput, monitorLocation: e.target.value })
                                                }
                                                required
                                            />
                                            <label htmlFor="">
                                            Location
                                                {dataInput.monitorLocation.trim() === "" ? (
                                                    <span style={{ color: "red" }}> *</span>
                                                ) : (
                                                    ""
                                                )}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-3">
                                    <div className="col-12 col-md-8 col-xl-6">
                                        <div className="form-group">
                                            <textarea
                                                style={{ fontSize: '1.3em' }}
                                                className="form-control"
                                                rows={2}
                                                placeholder=""
                                                value={dataInput.monitorRemark}
                                                onChange={(e) =>
                                                    addData({ ...dataInput, monitorRemark: e.target.value })
                                                }
                                                required
                                            />
                                            <label htmlFor="">
                                            Remark
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-3">
                                    <label className="block" >
                                        <div className="block mb-1  text-gray-700 font-normal">Frequency API <span>*</span></div>
                                        <div className="time-selected flex items-center" style={{ display: "flex", flexDirection: "row"}}>
                                            <input class="form-control freq" type="number" min="00" max="24" placeholder="0" required
                                                onChange={(e) => addData({ ...dataInput, hour: e.target.value })}
                                            />
                                            <div className="hour-freq ml-2 font-normal" style={{ display: "flex", alignItems: "flex-end"}}>hour</div>
                                            <input class="form-control freq ml-2" type="number" min="00" max="60" placeholder="0" required
                                                onChange={(e) => addData({ ...dataInput, minute: e.target.value })} />
                                            <div className="min-freq ml-2 font-normal" style={{ display: "flex", alignItems: "flex-end"}}>minute</div>
                                        </div>
                                    </label>
                                </div>
                            </>
                            {/* <form className="mt-[25px] mb-2 space-y-4">
                                <label className="block">
                                    <div className="block mb-1 text-gray-700 font-normal">Monitor ID <span>*</span></div>
                                    <input className="form-input" type="text" maxlength="6" required onChange={(e) => addData({ ...dataInput, monitorId: e.target.value })} />
                                </label>
                                <label className="block">
                                    <div className="block mb-1  text-gray-700 font-normal">Monitor Name<span>*</span></div>
                                    <input className="form-input" type="text" required onChange={(e) => addData({ ...dataInput, monitorName: e.target.value })} />
                                </label>
                                <label className="block">
                                    <div className="block mb-1  text-gray-700 font-normal">Monitor Detail</div>
                                    <textarea className="form-input area" id="detail" rows="3" onChange={(e) => addData({ ...dataInput, monitorDetail: e.target.value })}></textarea>
                                </label>
                                <label className="block">
                                    <div className="block mb-1  text-gray-700 font-normal">Location <span>*</span></div>
                                    <textarea className="form-input area" id="location" rows="3" required onChange={(e) => addData({ ...dataInput, monitorLocation: e.target.value })}></textarea>
                                </label>
                                <label className="block">
                                    <div className="block mb-1  text-gray-700 font-normal">Remark</div>
                                    <textarea className="form-input area" id="remark" rows="3" onChange={(e) => addData({ ...dataInput, monitorRemark: e.target.value })}></textarea>
                                </label>
                                <label className="block">
                                    <div className="block mb-1  text-gray-700 font-normal">Frequency API <span>*</span></div>
                                    <div className="time-selected flex items-center">
                                        <input class="form-input freq" type="number" min="00" max="24" placeholder="0" required
                                            onChange={(e) => addData({ ...dataInput, hour: e.target.value })}
                                        />
                                        <div className="hour-freq ml-2 font-normal">hour</div>
                                        <input class="form-input freq ml-2" type="number" min="00" max="60" placeholder="0" required
                                            onChange={(e) => addData({ ...dataInput, minute: e.target.value })} />
                                        <div className="min-freq ml-2 font-normal">minute</div>
                                    </div>
                                </label>

                                
                            </form> */}
                            {/* <div className="line"></div> */}
                            <hr/>
                            <div className="bottom-phase-iot pr-4 pt-3">
                                <input type="submit" className="form-button btn btn-danger cancel" value="Cancel" onClick={handleClickCancel} />
                                <input type="submit" className="form-button btn btn-primary add" value="Add Monitor" onClick={handleClickAdd} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default AddMonitor;
