import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
import Configs from "../../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
} from "../../../../../../Utils/Common";

import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { getDropdown } from "../../../../../../Utils/Employee_history";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Supplier({
  id,
  mode,
  isDisabled,
  Is_Product_Kitting,
  inputData,
  setInputData,
  errorList,
  setErrorList,
  functionSave,
  setFunctionSave,
}) {
  const [tabData, setTabData] = useState({
    SupplierTab_id: "",
    supplier_id: "",
    supplier_product_code: "",
    original_from_country_id: "",
    factory_certificate: "",
    assembly_factory_name: "",
  });

  const [supplier_select, setsupplier_select] = useState({
    id: "",
    supplier: "",
    buy_price: "",
    currency: "",
    exchange_rate: "",
    exchange_date: "",
    remark: "",
    mode: "",
    index: "",
  });

  const [supplierList, setSupplierList] = useState({
    data: [],
    new: [],
    update: [],
    delete: []
  });

  const [countChange, setCountChange] = useState({
    data: 0
  });

  const [dropDownData, setDropDownData] = useState({
    currency: []
  });

  const [SupplierData, setSupplierData] = useState([]);

  const [CountryData, setConutryData] = useState([]);

  const resetSupplierSelect = () => {
    setsupplier_select({
      id: "",
      supplier: "",
      buy_price: "",
      currency: "",
      exchange_rate: "",
      exchange_date: "",
      remark: "",
      mode: "",
      index: "",
    })
    supplier_select.id = "";
    supplier_select.supplier = "";
    supplier_select.buy_price = "";
    supplier_select.currency = "";
    supplier_select.exchange_rate = "";
    supplier_select.exchange_date = "";
    supplier_select.remark = "";
    supplier_select.mode = "";
    supplier_select.index = "";
  }

  const SetEditSupplierTable = (index, event) => {
    setsupplier_select({
      id: supplierList.data[index].id,
      supplier: supplierList.data[index].supplier_id,
      buy_price: supplierList.data[index].buy_price,
      currency: supplierList.data[index].currency_id,
      exchange_rate: supplierList.data[index].exchange_rate,
      exchange_date: new Date(supplierList.data[index].exchange_date),
      remark: supplierList.data[index].remark,
      mode: supplierList.data[index].id ? "edit" : "add",
      index: index,
    })
    supplier_select.id = supplierList.data[index].id;
    supplier_select.supplier = supplierList.data[index].supplier_id;
    supplier_select.buy_price = supplierList.data[index].buy_price;
    supplier_select.currency = supplierList.data[index].currency_id;
    supplier_select.exchange_rate = supplierList.data[index].exchange_rate;
    supplier_select.exchange_date = new Date(supplierList.data[index].exchange_date);
    supplier_select.remark = supplierList.data[index].remark;
    supplier_select.mode = supplierList.data[index].id ? "edit" : "add";
    supplier_select.index = index;
  }

  const Edit_Suppier = () => {
    var tempData = supplierList.data;
    setSupplierList({
      ...supplierList,
      data: []
    });

    if (supplier_select.index >= 0) {
      const idx = supplier_select.index;

      tempData[idx].id = supplier_select.id || '';
      tempData[idx].supplier_id = supplier_select.supplier || '';
      tempData[idx].buy_price = supplier_select.buy_price || '';
      tempData[idx].currency_id = supplier_select.currency || '';
      tempData[idx].exchange_rate = supplier_select.exchange_rate || '';
      tempData[idx].exchange_date = supplier_select.exchange_date || '';
      tempData[idx].remark = supplier_select.remark?.trim() || '';
      tempData[idx].status = "edit";
      tempData[idx].create_date = new Date();
    }

    tempData.sort((a, b) => b.is_active - a.is_active);

    setSupplierList({
      ...supplierList,
      data: tempData
    });
    supplierList.data = tempData;

    resetSupplierSelect();
    setCountChange((prev) => {
      return { data: (prev.data + 1) }
    });
  }

  const Add_Suppier = () => {
    var tempData = supplierList.data;
    setSupplierList({
      ...supplierList,
      data: []
    });

    let temp = {
      supplier_id: supplier_select.supplier,
      buy_price: supplier_select.buy_price || 0,
      currency_id: supplier_select.currency || '',
      exchange_rate: supplier_select.exchange_rate || 0,
      exchange_date: supplier_select.exchange_date || '',
      remark: supplier_select.remark?.trim(),
      sort: tempData.length + 1,
      is_active: (tempData.length === 0) ? true : false,
      status: 'add',
      create_date: new Date()
    }
    tempData.push(temp);
    tempData.sort((a, b) => b.is_active - a.is_active);

    setSupplierList({
      ...supplierList,
      data: tempData
    });
    supplierList.data = tempData;

    resetSupplierSelect();
    setCountChange((prev) => {
      return { data: (prev.data + 1) }
    });
  }

  const GetSupplierNameById = (id) => {
    return SupplierData?.find(el => el.sup_id === id)?.sup_name || ''
  }

  const GetCurrencyNameById = (id) => {
    return dropDownData.currency?.find(el => el.currency_id === id)?.currency_name || ''
  }

  async function GetSupplier() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetSupplier",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setSupplierData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetCountry() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCountry",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setConutryData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetSupplierTab() {
    let tempData = {
      Product_Id: id,
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetSupplierTab",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // setConutryData(response.data);

        if (response.data.length > 0) {
          const tempList = [];
          for (let item of response.data) {
            tempList.push({
              id: item.spc_id,
              supplier_id: item.spc_psc_id,
              buy_price: item.ps_buy_price,
              currency_id: item.ps_currency_id,
              exchange_rate: item.ps_exchange_rate,
              exchange_date: item.ps_exchange_date,
              remark: item.ps_remark,
              status: "edit",
              is_active: item.ps_is_active
            });
          }
          setSupplierList({
            ...supplierList,
            data: tempList
          });
          supplierList.data = tempList
          /* setTabData({
            SupplierTab_id: response.data[0].spc_id || '',
            supplier_id: response.data[0].spc_psc_id || '',
            supplier_product_code: response.data[0].spc_supplier_product_code || '',
            original_from_country_id: response.data[0].spc_country_id || '',
            factory_certificate: response.data[0].spc_factory_certificate || '',
            assembly_factory_name: response.data[0].spc_factory_name || '',
          });
          tabData.SupplierTab_id = response.data[0].spc_id || '';
          tabData.supplier_id = response.data[0].spc_psc_id || '';
          tabData.supplier_product_code = response.data[0].spc_supplier_product_code || '';
          tabData.original_from_country_id = response.data[0].spc_country_id || '';
          tabData.factory_certificate = response.data[0].spc_factory_certificate || '';
          tabData.assembly_factory_name = response.data[0].spc_factory_name || ''; */
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function CheckDataTab_4() {
    if (tabData.supplier_product_code) {
      tabData.supplier_product_code = tabData.supplier_product_code.trim();
    }

    if (tabData.factory_certificate) {
      tabData.factory_certificate = tabData.factory_certificate.trim();
    }

    if (tabData.assembly_factory_name) {
      tabData.assembly_factory_name = tabData.assembly_factory_name.trim();
    }

    const error_list = [];

    /* if (tabData.supplier_id === "" || tabData.supplier_id === null) {
      let temp_err = {
        message: "กรุณาเลือก Supplier",
      };
      error_list.push(temp_err);
    }

    if (
      tabData.supplier_product_code === "" ||
      tabData.supplier_product_code === null
    ) {
      let temp_err = {
        message: "กรุณากรอก Supplier Product Code",
      };
      error_list.push(temp_err);
    }

    if (
      tabData.original_from_country_id === "" ||
      tabData.original_from_country_id === null
    ) {
      let temp_err = {
        message: "กรุณาเลือก Original from Country",
      };
      error_list.push(temp_err);
    }

    if (
      tabData.assembly_factory_name === "" ||
      tabData.assembly_factory_name === null
    ) {
      let temp_err = {
        message: "กรุณากรอก Assembly Factory Name",
      };
      error_list.push(temp_err);
    } */

    setErrorList({
      ...errorList,
      data: error_list,
    });
    errorList.data = error_list;
  }

  useEffect(async () => {
    await GetSupplier();
    await GetCountry();
    const currencyDD = await getDropdown("currency");
    setDropDownData({
      ...dropDownData,
      currency: currencyDD
    });
    dropDownData.currency = currencyDD;
    if (mode === "edit" || mode === "view") {
      await GetSupplierTab();
    }
  }, []);

  useEffect(async () => {
    setInputData({
      ...inputData,
      data: supplierList.data,
    });

    setFunctionSave({
      ...functionSave,
      data: CheckDataTab_4,
    });
  }, [supplierList.data, countChange.data]);

  const supplierRow = [];
  let sup_row_index = 0;
  for (let index = 0; index < supplierList.data.length; index++) {
    const rowItem = [];

    if (supplierList.data[index].status === "delete") {
      continue;
    }

    rowItem["no"] = (sup_row_index + 1);

    rowItem["supplier"] = GetSupplierNameById(supplierList.data[index].supplier_id);

    rowItem["buy_price"] = (<div style={{ textAlign: 'end' }}>{supplierList.data[index].buy_price}</div>);;

    rowItem["currency"] = GetCurrencyNameById(supplierList.data[index].currency_id);

    rowItem["ex_rate"] = (<div style={{ textAlign: 'end' }}>{supplierList.data[index].exchange_rate}</div>);

    rowItem["ex_date"] = moment(supplierList.data[index].exchange_date).format("DD/MM/YYYY");

    rowItem["remark"] = supplierList.data[index].remark || '-';

    rowItem["status"] = (
      <>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              id={index}
              disabled={isDisabled}
              onClick={() => {
                const tempList = [...supplierList.data];
                if (tempList[index].is_active) return;
                setSupplierList({ ...supplierList, data: [] })

                for (let idx = 0; idx < tempList.length; idx++) {
                  tempList[idx].is_active = false;
                  if (index === idx) {
                    tempList[idx].is_active = true;
                  }
                }
                tempList.sort((a, b) => b.is_active - a.is_active);

                setSupplierList({ ...supplierList, data: tempList })
                supplierList.data = tempList;

              }/* setSupplier_active.bind(
                this,
                supplierList.data[index]
              ) */}
              class={supplierList.data[index].is_active === true ? "btn btn-block btn-success " : "btn btn-block btn-primary "}
            >
              ACTIVE
            </button>
          </div>
        </div>
      </>
    );

    rowItem["mgt"] = (
      <>
        {supplierList.data[index].is_active === false && !isDisabled ? (
          <>
            <div className="row">
              <div className="col-6">
                <a
                  data-toggle="modal"
                  data-target="#modal-add_supplier_rerationship"
                  onClick={SetEditSupplierTable.bind(this, index)}
                /* onClick={form_supplier.bind(
                  this,
                  name,
                  supplier_select_data[index].buy_price,
                  supplier_select_data[index].minimum_order || "",
                  supplier_select_data[index].lead_time || "",
                  supplier_select_data[index].mat_cycle_time || "",
                  supplier_select_data[index].remark || "",
                  "edit",
                  index,
                  supplier_select_data[index]
                )} */
                >
                  <i class="fas fa-pencil-alt"></i>
                </a>
              </div>

              <div className="col-6">
                <i
                  id={index}
                  onClick={() => {
                    const tempList = [...supplierList.data];
                    setSupplierList({ ...supplierList, data: [] });

                    tempList[index].status = "delete";

                    setSupplierList({ ...supplierList, data: tempList });
                    supplierList.data = tempList;
                    setCountChange((prev) => {
                      return { data: (prev.data + 1) }
                    });
                  }}
                  /* onClick={Remove_Supplier.bind(
                    this
                  )} */
                  className="fa fa-trash  icon-sm"
                ></i>
              </div>
            </div>
          </>
        ) : ''}
      </>
    );
    sup_row_index++;
    supplierRow.push(rowItem);
  }

  const dataSupplier = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "supplier",
        sort: "asc",
        width: 50,
      },
      {
        label: "Buy Price",
        field: "buy_price",
        sort: "asc",
        width: 50,
      },
      {
        label: "Currency",
        field: "currency",
        sort: "asc",
        width: 50,
      },
      {
        label: "Exchange Rage",
        field: "ex_rate",
        sort: "asc",
        width: 50,
      },
      {
        label: "Exchange Date",
        field: "ex_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ],
    rows: supplierRow,
  }

  const Custom_Input_date = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <>
      {/* <div className="row">
        <div className="col-12 col-md-6 col-xl-4">

        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-12 col-md-12 col-xl-12">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.supplier_product_code}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  supplier_product_code: e.target.value,
                });
                tabData.supplier_product_code = e.target.value;
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Supplier Product Code
              {!Is_Product_Kitting && (tabData.supplier_product_code === "" ||
                tabData.supplier_product_code === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div> */}

      {/* <div className="row">
        <div className="col-12 col-md-4 col-xl-4">
          <div class="form-group">
            <select
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.original_from_country_id}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  original_from_country_id: e.target.value,
                });
                tabData.original_from_country_id = e.target.value;
              }}
            >
              <option value="" disabled>
                --- กรุณาเลือก ---
              </option>
              {CountryData.map((data, index) => {
                return (
                  <option key={index} value={data.id}>
                    {data.name}
                  </option>
                );
              })}
            </select>
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Original from Country
              {!Is_Product_Kitting && (tabData.original_from_country_id === "" ||
                tabData.original_from_country_id === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

      </div> */}

      {/* <div className="row">
        <div className="col-12 col-md-12 col-xl-12">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.assembly_factory_name}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  assembly_factory_name: e.target.value,
                });
                tabData.assembly_factory_name = e.target.value;
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Assembly Factory Name
              {!Is_Product_Kitting && (tabData.assembly_factory_name === "" ||
                tabData.assembly_factory_name === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div> */}


      <div className="row">
        <div className="col-12 col-md-4 col-xl-3">
          <div class="form-group">
            <button
              type="button"
              data-toggle="modal"
              data-target="#modal-add_supplier_rerationship"
              //onClick={saveOption}
              class="btn btn-block btn-primary "
              disabled={isDisabled}
              onClick={() => {
                setsupplier_select({
                  ...supplier_select,
                  id: "",
                  supplier: "",
                  buy_price: "",
                  currency: "",
                  exchange_rate: "",
                  exchange_date: new Date(),
                  remark: "",
                  mode: "add",
                  index: "",
                });
              }}
            >
              Add Relationship Supplier&nbsp;
              <span>
                <i className="fa fa-plus-circle"></i>
              </span>
            </button>

          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive" style={{ whiteSpace: 'nowrap' }} /*  style={{ height: "600px" }} */ >
            <MDBDataTable
              sortable={false}
              className="table table-head-fixed"
              disableRetreatAfterSorting={true}
              striped
              bordered
              hover
              fixedHeader
              data={dataSupplier}
            />
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-add_supplier_rerationship">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">{supplier_select.mode === "add" ? "Add" : "Edit"} Supplier Rerationship</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={isDisabled}
                      value={supplier_select.supplier}
                      onChange={(e) => {
                        setsupplier_select({
                          ...supplier_select,
                          supplier: e.target.value,
                        });
                        supplier_select.supplier = e.target.value;
                      }}
                    >
                      <option value="" disabled>
                        --- กรุณาเลือก ---
                      </option>
                      {SupplierData.map((data, index) => {
                        return (
                          <option key={index} value={data.sup_id}>
                            {data.sup_name}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Supplier
                      {!Is_Product_Kitting && (supplier_select.supplier === "" || supplier_select.supplier === null) ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      type="text"
                      className="form-control"
                      thousandSeparator={true}
                      disabled={isDisabled}
                      value={supplier_select.buy_price}
                      onChange={(e) =>
                        setsupplier_select({ ...supplier_select, buy_price: e.target.value })
                      }
                      required
                    />

                    <label htmlFor="">Buy Price :
                      {!Is_Product_Kitting && (supplier_select.buy_price === "" || supplier_select.buy_price === null) ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group" style={{ position: "relative" }}>
                    <select
                      required
                      className="form-control"
                      id="thank_point"
                      name="thank_point"
                      disabled={isDisabled}
                      value={supplier_select.currency}
                      onChange={(e) => {
                        setsupplier_select({
                          ...supplier_select,
                          currency: e.target.value,
                        });
                        supplier_select.currency = e.target.value;
                      }}
                    >
                      <option value="">---เลือก Currency---</option>
                      {dropDownData?.currency?.map((item, index) => {
                        return (
                          <option key={index} value={item.currency_id}>
                            {item.currency_name}
                          </option>
                        );
                      })}
                    </select>
                    <label>Currency
                      {!Is_Product_Kitting && (supplier_select.currency === "" || supplier_select.currency === null) ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>


              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      type="text"
                      disabled={isDisabled}
                      className="form-control"
                      thousandSeparator={true}
                      decimalScale={2}
                      value={supplier_select.exchange_rate}
                      onChange={(e) =>
                        setsupplier_select({ ...supplier_select, exchange_rate: e.target.value })
                      }
                      required
                    />

                    <label htmlFor="">Exchange Rate :
                      {!Is_Product_Kitting && (supplier_select.exchange_rate === "" || supplier_select.exchange_rate === null) ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group date-supplier-modal-cuteplus">
                    <DatePicker
                      disabled={isDisabled}
                      dateFormat={"dd-MM-yyyy"}
                      popperPlacement="bottom"
                      wrapperClassName="w-100"
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"], // don't allow it to flip to be above
                        },
                        preventOverflow: {
                          enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                          enabled: false, // turn off since needs preventOverflow to be enabled
                        },
                      }}
                      selected={supplier_select.exchange_date}
                      onChange={async (date) => {
                        setsupplier_select({
                          ...supplier_select,
                          exchange_date: date,
                        });
                      }}
                      // selectsEnd
                      // startDate={supplier_select.quo_date}
                      minDate={new Date()}
                      customInput={<Custom_Input_date isDis={isDisabled} />}
                    ></DatePicker>

                    <label htmlFor="" style={supplier_select.exchange_date ? { color: 'rgb(7, 92, 175)', fontSize: '20px', transform: 'translateY(8px)' } : { }}>Exchange Date :
                      {!Is_Product_Kitting && (supplier_select.exchange_date === "" || supplier_select.exchange_date === null) ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <input
                      allowNegative={false}
                      type="text"
                      maxLength={43}
                      className="form-control"
                      disabled={isDisabled}
                      value={supplier_select.remark}
                      onChange={(e) =>
                        setsupplier_select({ ...supplier_select, remark: e.target.value })
                      }
                      required
                    />

                    <label htmlFor="">Remark :</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>

                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">

              {supplier_select.mode === "edit" ? (
                <>
                  <button
                    disabled={(supplier_select.supplier === "" || supplier_select.buy_price === ""
                      || supplier_select.currency === "" || supplier_select.exchange_rate === ""
                      || supplier_select.exchange_date === "") ? true : false}
                    type="button"
                    onClick={Edit_Suppier}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save Change
                  </button>

                </>
              ) : (
                <>
                  <button
                    disabled={
                      (supplier_select.supplier === "" || supplier_select.buy_price === ""
                        || supplier_select.currency === "" || supplier_select.exchange_rate === ""
                        || supplier_select.exchange_date === "")
                        || isDisabled ? true : false}
                    type="button"
                    onClick={Add_Suppier}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add
                  </button>

                </>
              )}

              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>


            </div>
          </div>

        </div>

      </div>
    </>
  );
}

export default Supplier;
