import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
    getOem,
    getToken,
    getFeature,
    getUser,
    getAcademy,
    getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import 'moment/locale/th';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";
import jsPDF from "jspdf";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import { userdefault_img, logo_smz } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";

function Travel_expenses_AEV(mode) {
    moment.locale('th');
    useEffect(async () => {
        getUserName();
        if (mode.mode === "edit") {
            setdisable(false);
            settest({ ...test, test: "" })
            test.test = "";
            dateMonthYear(start_date, end_date);
        }
        if (mode.mode === "view") {
            setdisable(true);
            settest({ ...test, test: "" })
            test.test = "";
            dateMonthYear(start_date, end_date);
        }
    }, []);
    const [disable, setdisable] = useState(false);
    const [test, settest] = useState({
        test: "",
    })
    const { id, start_date, end_date } = useParams();

    const [UserTravelCost, setUserTravelCost] = useState([]);
    const [UserNameTH, setUserNameTH] = useState({
        user_name_th: "",
    });
    // console.log("UserTravelCost", UserTravelCost);
    const [MonthYear, setMonthYear] = useState({
        monthName: "",
        start_day: "",
        start_month: "",
        start_year: "",
        end_day: "",
        end_month: "",
        end_year: "",
        month: "",
        year: "",
    });
    console.log("MonthYear", MonthYear);
    const [firstHalf, setfirstHalf] = useState([])
    // console.log("firstHalf", firstHalf);
    const [secondHalf, setsecondHalf] = useState([])
    // console.log("secondHalf", secondHalf);

    function getUserName() {
        axios({
            method: "get",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getUserName?id=" + id,
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            }
        }).then((res) => {
            console.log("res", res);
            if (res.data.status === "success") {
                setUserNameTH({
                    ...UserNameTH,
                    user_name_th: res.data.data[0].emp_name_th,
                });
                UserNameTH.user_name_th = res.data.data[0].emp_name_th;
            }
        })
    };

    function dateMonthYear(start_date, end_date) {
        const start_date_newDate = new Date(start_date);
        const end_date_newDate = new Date(end_date);
        setMonthYear({
            ...MonthYear,
            start_day: String(new Date(
                start_date_newDate.getFullYear(),
                start_date_newDate.getMonth(),
                start_date_newDate.getDate()
            ).getDate()),
            start_month: String(start_date_newDate.getMonth() + 1),
            start_year: String(start_date_newDate.getFullYear()),
            month: String(start_date_newDate.getMonth() + 1),
            year: String(start_date_newDate.getFullYear()),
            end_day: String(new Date(
                end_date_newDate.getFullYear(),
                end_date_newDate.getMonth(),
                end_date_newDate.getDate()
            ).getDate()),
            end_month: String(end_date_newDate.getMonth() + 1),
            end_year: String(end_date_newDate.getFullYear()),
        });
        MonthYear.start_day = String(new Date(
            start_date_newDate.getFullYear(),
            start_date_newDate.getMonth(),
            start_date_newDate.getDate()
        ).getDate());
        MonthYear.start_month = String(start_date_newDate.getMonth() + 1);
        MonthYear.start_year = String(start_date_newDate.getFullYear());
        MonthYear.month = String(start_date_newDate.getMonth() + 1);
        MonthYear.year = String(start_date_newDate.getFullYear());
        MonthYear.end_day = String(new Date(
            end_date_newDate.getFullYear(),
            end_date_newDate.getMonth(),
            end_date_newDate.getDate()
        ).getDate());
        MonthYear.end_month = String(end_date_newDate.getMonth() + 1);
        MonthYear.end_year = String(end_date_newDate.getFullYear());

        getDaysInMonth(String(start_date_newDate.getMonth() + 1), String(start_date_newDate.getFullYear()));
    }
    function getDaysInMonth(month, year) {
        let daysInMonth = "";
        if (month !== "" && month !== undefined && month !== null && year !== "" && year !== undefined && year !== null) {
            const selectedMonth = moment(`${year}-${month}`, 'YYYY-MM');
            console.log("selectedMonth", selectedMonth);
            setMonthYear({
                ...MonthYear,
                monthName: selectedMonth.format('MMMM'),
                end_day: String(selectedMonth.daysInMonth()),
                month: month,
                year: year,
            });
            MonthYear.monthName = selectedMonth.format('MMMM');
            MonthYear.end_day = String(selectedMonth.daysInMonth());
            MonthYear.month = month;
            MonthYear.year = year;
            daysInMonth = selectedMonth.daysInMonth();
        } else if ((month !== "" && month !== undefined && month !== null) || (year !== "" && year !== undefined && year !== null)) {
            if ((month !== "" && month !== undefined && month !== null)) {
                setMonthYear({
                    ...MonthYear,
                    monthName: moment().month(month - 1).format('MMMM'),
                    end_day: String(moment().month(month - 1).year(moment().year()).daysInMonth()),
                    month: month,
                    year: moment().year(),
                });
                MonthYear.monthName = moment().month(month - 1).format('MMMM');
                MonthYear.end_day = String(moment().month(month - 1).year(moment().year()).daysInMonth());
                MonthYear.month = month;
                MonthYear.year = moment().year();
                settest({ ...test, test: "" })
                test.test = "";
                const haveMonth = moment().month(month - 1).year(moment().year());
                daysInMonth = haveMonth.daysInMonth();
            }
            else {
                setMonthYear({
                    ...MonthYear,
                    monthName: moment().format('MMMM'),
                    end_day: String(moment().month(moment().month()).year(year).daysInMonth()),
                    month: moment().month(),
                    year: year,
                });
                MonthYear.monthName = moment().format('MMMM');
                MonthYear.end_day = String(moment().month(moment().month()).year(year).daysInMonth());
                MonthYear.month = moment().month();
                MonthYear.year = year;
                settest({ ...test, test: "" })
                test.test = "";
                const currentMonth = moment().month();
                const currentDate = moment().year(year).month(currentMonth);
                daysInMonth = currentDate.daysInMonth();
            }
        } else {
            setMonthYear({
                ...MonthYear,
                monthName: moment().format('MMMM'),
                end_day: String(moment().daysInMonth()),
                month: moment().month() + 1,
                year: moment().year(),
            });
            MonthYear.monthName = moment().format('MMMM');
            MonthYear.end_day = String(moment().daysInMonth());
            MonthYear.month = moment().month() + 1;
            MonthYear.year = moment().year();
            settest({ ...test, test: "" })
            test.test = "";
            daysInMonth = moment().daysInMonth();
        }
        let dataList = [];
        for (let i = 1; i <= daysInMonth; i++) {
            let data = {
                day: String(i),
                count: "",
                extra_cost: "",
            }
            dataList.push(data);
        }
        setUserTravelCost(dataList);
        setfirstHalf(dataList.slice(0, 15));
        setsecondHalf(dataList.slice(15, daysInMonth));
        settest({ ...test, test: "" })
        test.test = "";
        let data = {
            employee_id: id,
            month: MonthYear.month,
            year: MonthYear.year,
        }
        axios({
            method: "post",
            url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getExtraCostByEmployeeId",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: data,
        })
            .then(function (response) {
                if (response.data.data.length > 0) {
                    // console.log("response.data.data", response.data.data);
                    let copy = response.data.data;
                    for (let index = 1; index <= dataList.length; index++) {
                        let temp = copy.filter((item) => (moment(item.day_cost).format("D")) === String(index));
                        if (temp.length > 0) {
                            dataList[index - 1].count = String(temp[0].count);
                            dataList[index - 1].extra_cost = String(temp[0].extra_cost);
                        }
                    }
                    // console.log("dataList1111", dataList);
                    setUserTravelCost(dataList);
                    setfirstHalf(dataList.slice(0, 15));
                    setsecondHalf(dataList.slice(15, dataList.length));
                    settest({ ...test, test: "" })
                    test.test = "";
                }
                else {
                    // console.log("dataList2222", dataList);
                    setUserTravelCost(dataList);
                    setfirstHalf(dataList.slice(0, 15));
                    setsecondHalf(dataList.slice(15, dataList.length));
                    settest({ ...test, test: "" })
                    test.test = "";
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function saveExtraCost() {

        Swal.fire({
            title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
            text: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: "ไม่",
        }).then((result) => {
            if (result.isConfirmed) {
                let check = [];
                for (let index = 0; index < UserTravelCost.length; index++) {
                    if ((UserTravelCost[index].count !== "" && UserTravelCost[index].extra_cost === "") || (UserTravelCost[index].count === "" && UserTravelCost[index].extra_cost !== "")) {
                        check.push(UserTravelCost[index]);
                    }
                }
                if (check.length > 0) {
                    Swal.fire({
                        title: "กรุณากรอกข้อมูลให้ครบถ้วน",
                        text: "กรุณากรอกข้อมูลวันที่" + check[0].day + "ให้ครบถ้วน",
                        icon: "warning",
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "ตกลง",
                        cancelButtonText: "ไม่",
                    })
                    return;
                }
                let data = {
                    employee_id: id,
                    month: MonthYear.month,
                    year: MonthYear.year,
                    extra_cost: UserTravelCost,
                    by: getUser().fup,
                };
                axios({
                    method: "post",
                    url: Configs.API_URL_hrMagenatement + "/api/hrManagement/saveExtraCost",
                    headers: {
                        Authorization: getToken(),
                        "X-TTT": Configs.API_TTT,
                        "Content-Type": "application/json",
                    },
                    data: data,
                })
                    .then(function (response) {
                        window.location.href = `/Human_Resource/ค่าเที่ยว/${moment(`${MonthYear.year}-${MonthYear.month}-${MonthYear.start_day}`, 'YYYY-MM-DD').format("YYYY-MM-DD")}/${moment(`${MonthYear.year}-${MonthYear.month}-${MonthYear.end_day}`, 'YYYY-MM-DD').format("YYYY-MM-DD")}`
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            };
        })
    }

    function cancelOption(params) {
        Swal.fire({
            title: "คุณต้องการยกเลิกใช่หรือไม่?",
            text: "คุณต้องการยกเลิกใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: "ไม่",
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = `/Human_Resource/ค่าเที่ยว/${start_date}/${end_date}`
            }
        })
    }

    const CustomInput = ({ value, onClick }) => (
        <div className="input-group">
            <input
                type="text"
                className="form-control float-left"
                onClick={onClick}
                value={value}
            />{" "}
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="far fa-calendar-alt" />
                </span>
            </div>
        </div>
    );

    return (
        <div className="content-wrapper">
            {/* Header */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1> เพิ่มค่าเที่ยว [{UserNameTH.user_name_th}] </h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {mode.mode === "edit" ? (
                        <div className="col-4 col-md-2 col-xl-2">
                            <div className="form-group">
                                <button
                                    className="btn btn-success btn-block"
                                    onClick={() => {
                                        saveExtraCost();
                                    }}
                                >
                                    บันทึก
                                </button>
                            </div>
                        </div>
                    ) : ("")}
                    <div className="col-4 col-md-2 col-xl-2">
                        <div className="form-group">
                            <button
                                className="btn btn-danger btn-block"
                                onClick={() => {
                                    cancelOption();
                                }}
                            >
                                ยกเลิก
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 col-md-2 col-xl-2">
                        <div className="form-group">
                            <label htmlFor="month">Month</label>
                            <DatePicker
                                id="month"
                                className="form-control"
                                locale="th"
                                value={MonthYear.monthName}
                                dropdownMode="scroll"
                                customInput={<CustomInput />}
                                dateFormat="MMMM"
                                showMonthYearPicker
                                showFullMonthYearPicker="false"
                                showTwoColumnMonthYearPicker="false"
                                showFourColumnMonthYearPicker="false"
                                onChange={(e) => {
                                    setMonthYear({
                                        ...MonthYear,
                                        monthName: moment(e).format("MMMM"),
                                        month: moment(e).format("M"),
                                    });
                                    MonthYear.monthName = moment(e).format("MMMM");
                                    MonthYear.month = moment(e).format("M");
                                    settest({ ...test, test: "" })
                                    test.test = "";
                                    getDaysInMonth(moment(e).format("M"), MonthYear.year)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-4 col-md-2 col-xl-2">
                        <div className="form-group">
                            <label htmlFor="year">Year</label>
                            <DatePicker
                                id="year"
                                className="form-control"
                                wrapperClassName="w-100"
                                selected={new Date()}
                                customInput={<CustomInput />}
                                value={MonthYear.year}
                                dateFormat="yyyy"
                                showYearPicker
                                onChange={(e) => {
                                    setMonthYear({
                                        ...MonthYear,
                                        year: moment(e).format("YYYY"),
                                    });
                                    MonthYear.year = moment(e).format("YYYY");
                                    settest({ ...test, test: "" })
                                    test.test = "";
                                    getDaysInMonth(MonthYear.month, moment(e).format("YYYY"))
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* Body */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 style={{ textDecoration: "underline" }}>ค่าเที่ยว</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-xl-6">
                                            {firstHalf.map((item) => (
                                                <div className="row" key={item.day}>
                                                    <div className="col-2 col-md-2 col-xl-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="day"
                                                                value={item.day}
                                                                disabled="true"
                                                                onChange={(e) => { "" }}
                                                            />
                                                            <label htmlFor="day">Day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-xl-4">
                                                        <div className="form-group">
                                                            <NumberFormat
                                                                type="text"
                                                                className="form-control"
                                                                id="count"
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator={true}
                                                                allowNegative={false}
                                                                value={item.count}
                                                                disabled={disable}
                                                                onChange={(e) => {
                                                                    // console.log(UserTravelCost);
                                                                    // console.log(item);
                                                                    const updatedcount = [...UserTravelCost];
                                                                    // console.log(updatedcount );
                                                                    updatedcount[Number(item.day) - 1] = {
                                                                        ...updatedcount[Number(item.day) - 1],
                                                                        count: e.target.value,
                                                                    };
                                                                    setUserTravelCost(updatedcount);
                                                                    setfirstHalf(updatedcount.slice(0, 15));
                                                                }}
                                                            />
                                                            <label htmlFor="count">จำนวนเที่ยว</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-xl-4">
                                                        <div className="form-group">
                                                            <NumberFormat
                                                                type="text"
                                                                className="form-control"
                                                                id="extra_cost"
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator={true}
                                                                allowNegative={false}
                                                                value={item.extra_cost}
                                                                disabled={disable}
                                                                onChange={(e) => {
                                                                    const updatedextra_cost = [...UserTravelCost];
                                                                    // console.log(updatedextra_cost);
                                                                    updatedextra_cost[Number(item.day) - 1] = {
                                                                        ...updatedextra_cost[Number(item.day) - 1],
                                                                        extra_cost: e.target.value,
                                                                    };
                                                                    setUserTravelCost(updatedextra_cost);
                                                                    setfirstHalf(updatedextra_cost.slice(0, 15));
                                                                }}
                                                            />
                                                            <label htmlFor="extra_cost">ค่าเที่ยว</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-6 col-md-6 col-xl-6">
                                            {secondHalf.map((item) => (
                                                <div className="row" key={item.day}>
                                                    <div className="col-2 col-md-2 col-xl-2">
                                                        <div className="form-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="day_second"
                                                                value={item.day}
                                                                disabled="true"
                                                            />
                                                            <label htmlFor="day_second">Day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-xl-4">
                                                        <div className="form-group">
                                                            <NumberFormat
                                                                type="text"
                                                                className="form-control"
                                                                id="count_second"
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator={true}
                                                                allowNegative={false}
                                                                value={item.count}
                                                                disabled={disable}
                                                                onChange={(e) => {
                                                                    // console.log(item);
                                                                    const updatedcount = [...UserTravelCost];
                                                                    updatedcount[Number(item.day) - 1] = {
                                                                        ...updatedcount[Number(item.day) - 1],
                                                                        count: e.target.value,
                                                                    };
                                                                    setUserTravelCost(updatedcount);
                                                                    setsecondHalf(updatedcount.slice(15, updatedcount.length));
                                                                }}
                                                            />
                                                            <label htmlFor="count_second">จำนวนครั้ง</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-xl-4">
                                                        <div className="form-group">
                                                            <NumberFormat
                                                                type="text"
                                                                className="form-control"
                                                                id="extra_cost_second"
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator={true}
                                                                value={item.extra_cost}
                                                                allowNegative={false}
                                                                disabled={disable}
                                                                onChange={(e) => {
                                                                    const updatedextra_cost = [...UserTravelCost];
                                                                    updatedextra_cost[Number(item.day) - 1] = {
                                                                        ...updatedextra_cost[Number(item.day) - 1],
                                                                        extra_cost: e.target.value,
                                                                    };
                                                                    setUserTravelCost(updatedextra_cost);
                                                                    setsecondHalf(updatedextra_cost.slice(15, updatedextra_cost.length));
                                                                }}
                                                            />
                                                            <label htmlFor="extra_cost_second">ค่าเที่ยว</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Travel_expenses_AEV