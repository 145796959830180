import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../../Utils/Common";

import { userdefault_img } from "../../../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";

function Customer_Cute_Product({
  id,
  mode,
  isDisabled,
  inputDataTab2,
  setInputDataTab2,
  errorListTab2,
  setErrorListTab2,
  functionSaveTab2,
  setFunctionSaveTab2,
}) {
  const modalProductRef = useRef();

  const [categoryData, setCategoryData] = useState({
    data: [],
  });

  const [productData, setProductData] = useState({
    data: [],
    filterData: [],
  });

  const [selectProductData, setSelectProductData] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  let [searchData, setSearchData] = useState({
    code: "",
    name: "",
    category: "",
    barcode: "",
  });

  async function openModalProduct() {
    setSearchData({
      ...searchData,
      code: "",
      name: "",
      barcode: "",
      category: "",
    });
    searchData.code = "";
    searchData.name = "";
    searchData.barcode = "";
    searchData.category = "";
    await GetProduct(true);
    const tempSelectProduct = selectProductData.data;
    const tempProductTable = productData.data;

    const tempList = tempProductTable.map((item1) => {
      const check = tempSelectProduct.find(
        (item2) => item2.product_id === item1.product_id
      );

      if (check) {
        item1.check = true;
        return item1;
      } else {
        item1.check = false;
        return item1;
      }
    });

    setProductData({ ...productData, data: tempList });
    productData.data = tempList;

    modalProductRef.current.click();
  }

  async function GetSupplierRelationProduct() {
    // let tempData = {
    //   company_id: getUser().com,
    //   oem_id: getOem(),
    //   user_id: getUser().fup,
    // };

    await axios({
      method: "get",
      url:
        Configs.API_URL_cuteplus +
        "/api/supplier/getSupplierRelationProductBySupplierID/" +
        id,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData,
    })
      .then(function (response) {
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data,
        });

        inputDataTab2.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetCategoryLv2() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategoryLv2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategoryData({ data: response.data });
        categoryData.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProduct(checkSearch) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      code: searchData.code.trim() || null,
      name: searchData.name.trim() || null,
      category: searchData.category.trim() || null,
      barcode: searchData.barcode.trim() || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {
        const tempRes = response.data;
        const selectProductDataList = [];

        for (let item1 of tempRes) {
          let tempImage = {
            company_id: getUser().com,
            oem_id: getOem(),
            // user_id: getUser().fup,
            ul_project: "Cute_Products_Plus",
            ul_type: "ภาพ",
            ul_ref_id: item1.product_id,
            // ul_menu_id: ,
            ul_is_profile: true,
            // ul_file_group: ,
          };

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileOne",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: tempImage,
          }).then(function (response) {
            item1.image = response.data;
          });

          if (
            selectProductData.data.filter((e) => {
              return e.product_id === item1.product_id;
            }).length === 0
          ) {
            item1.check = false;
            item1.supplier_bar_code = "";
            item1.oldNew = "new";
            if (item1.barcode_id.length === 1) {
              item1.isBarSelect = false;
              item1.bar_code = item1.barcode_id[0];
            } else {
              item1.isBarSelect = true;
              item1.bar_code = "";
            }

            if (mode === "edit" || mode === "view") {
              for (let item2 of inputDataTab2.data) {
                if (item1.product_id === item2.srp_prd_id) {
                  item1.check = true;
                  item1.supplier_bar_code = item2.srp_barcode;
                  item1.oldNew = "old";
                  item1.bar_code = item2.srp_pb_id;
                  item1.supRePro = item2.srp_id;

                  selectProductDataList.push(item1);
                }
              }
            }
          }
        }

        if (!checkSearch) {
          setSelectProductData({
            ...selectProductData,
            data: selectProductDataList,
          });
          selectProductData.data = selectProductDataList;
        }

        if (productData.data?.length <= 0) {
          setProductData({
            data: tempRes,
            filterData: tempRes,
          });
          productData.data = tempRes;
          productData.filterData = tempRes;
        } else {
          setProductData({
            ...productData,
            filterData: tempRes,
          });
          productData.filterData = tempRes;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const rowsData1 = [];
  for (let index = 0; index < productData.filterData.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["code"] = productData.filterData[index].product_code
      ? productData.filterData[index].product_code
      : "-";

    rowItem["barcode"] = productData.data[index].barcode[0] ? productData.data[index].barcode[0] : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            productData.filterData[index].image
              ? productData.filterData[index].image.ul_path_file !== undefined &&
                productData.filterData[index].image.ul_path_file !== null &&
                productData.filterData[index].image.ul_path_file !== ""
                ? productData.filterData[index].image.ul_path_file?.indexOf("blob") >=
                  0
                  ? productData.filterData[index].image.ul_path_file
                  : Configs.API_URL_IMG_cuteplus +
                    `/static/${productData.filterData[index].image.ul_file_name}/${productData.filterData[index].image.ul_path_file}`
                : userdefault_img.imgs
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = productData.filterData[index].product_name_eng
      ? productData.filterData[index].product_name_eng
      : "-";

    rowItem["category"] = productData.filterData[index].cat_name
      ? productData.filterData[index].cat_name
      : "-";

    rowItem["management"] =
      productData.filterData[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            onClick={() => {
              let tempData = productData.filterData;

              tempData[index].check = true;
              if (tempData[index].statusUpdate) {
                tempData[index].status = "update";
              } else {
                tempData[index].status = "add";
              }

              setProductData({
                ...productData,
                filterData: tempData,
              });

              let SelectIndex = productData.data.findIndex((item) => {
                return item.product_id === tempData[index].product_id;
              });
              if (SelectIndex !== -1) {
                productData.data[SelectIndex].check = true;
                if (productData.data[SelectIndex].statusUpdate) {
                  productData.data[SelectIndex].status = "update";
                } else {
                  productData.data[SelectIndex].status = "add";
                }
              }
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            onClick={() => {
              let tempData = productData.filterData;

              tempData[index].check = false;
              tempData[index].status = "del";

              setProductData({
                ...productData,
                filterData: tempData,
              });

              let SelectIndex = productData.data.findIndex((item) => {
                return item.product_id === tempData[index].product_id;
              });
              if (SelectIndex !== -1) {
                productData.data[SelectIndex].check = false;
                if (productData.data[SelectIndex].statusUpdate) {
                  productData.data[SelectIndex].status = "update";
                } else {
                  productData.data[SelectIndex].status = "add";
                }
              }
            }}
          >
            ยกเลิก
          </button>
        </div>
      );

    rowsData1.push(rowItem);
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Category Lv2",
        field: "category",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };

  const rowsData2 = [];
  for (let index = 0; index < selectProductData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = selectProductData.data[index].product_code
      ? selectProductData.data[index].product_code
      : "-";

    rowItem["bar_code"] =
      selectProductData.data[index].isBarSelect === false ? (
        <select
          type="text"
          className="form-control"
          disabled={true}
          defaultValue={""}
          value={selectProductData.data[index].bar_code}
        >
          <option value="" disabled>
            -- เลือก --
          </option>

          <option value={selectProductData.data[index].barcode_id[0]}>
            {selectProductData.data[index].barcode[0]}
          </option>
        </select>
      ) : (
        <select
          type="text"
          className="form-control"
          disabled={isDisabled}
          value={selectProductData.data[index].bar_code}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].bar_code = e.target.value;

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
          }}
        >
          <option value="" disabled>
            -- เลือก --
          </option>

          {selectProductData.data[index].barcode_id.map((item, indexIn) => {
            return (
              <option value={item}>
                {selectProductData.data[index].barcode[indexIn]}
              </option>
            );
          })}
        </select>
      );

    rowItem["supplier_bar_code"] = (
      <NumberFormat
        type="text"
        className="form-control"
        required
        decimalScale={0}
        allowNegative={false}
        allowLeadingZeros={true}
        disabled={isDisabled}
        value={selectProductData.data[index].supplier_bar_code}
        onChange={async (e) => {
          const temp = selectProductData.data;

          temp[index].supplier_bar_code = e.target.value;

          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].oldNew === "old") {
            temp[index].status = "update";
            temp[index].statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["product_name"] = selectProductData.data[index].product_name_eng
      ? selectProductData.data[index].product_name_eng
      : "-";

    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          key={selectProductData.data[index].product_id}
          id={selectProductData.data[index].product_id}
          className=" btn btn-xs "
          onClick={async () => {
            const findIndex = productData.data.findIndex(
              (item) =>
                item.product_id === selectProductData.data[index].product_id
            );

            if (findIndex >= 0) {
              productData.data[findIndex].check = false;
              productData.data[findIndex].status = "del";
            }

            await saveProductData();
          }}
        >
          <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
        </a>
      </div>
    );

    rowsData2.push(rowItem);
  }

  const selectProducTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Supplier Item Code",
        field: "supplier_bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (isDisabled) {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "management";
    });

    selectProducTable.columns.splice(index1, 1);
  }

  async function saveProductData() {
    const findCheckTrue = productData.data.filter((item) => {
      return item.check === true;
    });

    const addNew = productData.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = productData.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = productData.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setSelectProductData({
      ...selectProductData,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    selectProductData.data = findCheckTrue;
    selectProductData.newData = addNew;
    selectProductData.updateData = updateOld;
    selectProductData.delData = delOld;
  }

  async function clearSearch() {
    setSearchData({
      ...searchData,
      code: "",
      name: "",
      category: "",
    });

    searchData.code = "";
    searchData.name = "";
    searchData.category = "";

    await GetProduct(true);
  }

  async function checkErrorSelectProductData() {
    if (selectProductData.data.length > 0) {
      const error_list = [];
      let number = 1;

      for (let item of selectProductData.data) {
        if (item.bar_code === null || item.bar_code.trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณาเลือก Barcode`,
          };

          error_list.push(temp_err);
        }

        if (
          item.supplier_bar_code === null ||
          item.supplier_bar_code.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอก Supplier Item Code`,
          };

          error_list.push(temp_err);
        }

        number += 1;
      }

      if (error_list.length > 0) {
        let temp_err = {
          message: `- Tab Supplier Product -`,
        };
        error_list.unshift(temp_err);
      }

      setErrorListTab2({
        ...errorListTab2,
        data: error_list,
      });
      errorListTab2.data = error_list;
    }
  }

  useEffect(async () => {
    if (mode === "edit" || mode === "view") {
      await GetSupplierRelationProduct();
    }
    await GetCategoryLv2();
    await GetProduct(false);
  }, []);

  useEffect(async () => {
    setInputDataTab2({
      ...inputDataTab2,
      data: selectProductData.data,
      newData: selectProductData.newData,
      updateData: selectProductData.updateData,
      delData: selectProductData.delData,
    });

    inputDataTab2.data = selectProductData.data;
    inputDataTab2.newData = selectProductData.newData;
    inputDataTab2.updateData = selectProductData.updateData;
    inputDataTab2.delData = selectProductData.delData;

    setFunctionSaveTab2({
      ...functionSaveTab2,
      data: checkErrorSelectProductData,
    });

    functionSaveTab2.data = checkErrorSelectProductData;
  }, [
    selectProductData.data,
    selectProductData.newData,
    selectProductData.updateData,
    selectProductData.delData,
    functionSaveTab2.data,
    errorListTab2.data
  ]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-md-3 col-xl-2">
          <button
            hidden={true}
            data-toggle="modal"
            data-target="#modalProduct"
            ref={modalProductRef}
          />
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDisabled}
            onClick={async () => {
              await openModalProduct();
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-md-12" style={{ overflow: "overlay" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <MDBDataTable
              sortable={false}
              className="table table-head-fixed"
              striped
              bordered
              hover
              fixedHeader
              data={selectProducTable}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProduct"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProduct"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{ width: "70%" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="modalProduct">
                <b>Add Product</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.code}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          code: e.target.value,
                        });
                        searchData.code = e.target.value;
                      }}
                    />
                    <label>Product Code</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          barcode: e.target.value,
                        });
                        searchData.barcode = e.target.value;
                      }}
                    />
                    <label>Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          name: e.target.value,
                        });
                        searchData.name = e.target.value;
                      }}
                    />
                    <label>Product Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      value={searchData.category}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          category: e.target.value,
                        });
                        searchData.category = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select a category
                      </option>
                      {categoryData.data.map((option, index) => (
                        <option
                          key={categoryData.data[index].cat_id}
                          value={categoryData.data[index].cat_id}
                        >
                          {categoryData.data[index].cat_name}
                        </option>
                      ))}
                    </select>
                    <label>Category Lv2</label>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await GetProduct(true);
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await clearSearch();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <div className="col-md-12" style={{ overflow: "overlay" }}>
                  <div
                    style={{
                      height: "450px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MDBDataTable
                      sortable={false}
                      striped
                      bordered
                      hover
                      data={ProductTable}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={async () => {
                  await saveProductData();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Customer_Cute_Product;
