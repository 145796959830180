import Configs from "../../../../../config";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  Get_font_pdf_th,
  Get_font_pdf_th2,
  Get_font_pdf_th2_bold,
} from "../../../../../assets/font/pdf_font";
import jsPDF from "jspdf";
import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";

function Quotation_report(company, toolings, statusCheck) {
  console.log("Quotation_report", toolings);
  var Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;

  const doc = new jsPDF("p", "mm", "a4");
  var counts = 0; //index < 30
  let sumSubTotal = 0;
  var row = 0;
  let c = 0;
  let itemLenght = toolings.product.length;
  for (let index = 0; index < toolings.product.length; index += row) {
    doc.setFillColor("#8DC445");
    doc.rect(0, 0, 221, 25, "F");
    doc.addImage(Com_logo, "JPEG", 5, 0, 25, 25);
    doc.rect(0, 0, 5.5, 25, "F");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(20);
    doc.addFileToVFS("THSarabunNewBold.ttf", Get_font_pdf_th2_bold());
    doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
    doc.setFont("THSarabunNewBold");
    doc.text(32, 10, "Cute Products Plus Co., Ltd.");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(13);
    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(96, 10, "0105552067869 (Head Office)");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(12);
    doc.text(
      32,
      15,
      "5 Soi LatPhrao-Wanghin 32 Yek 1, LatPhrao-Wanghin Rd. LatPhrao, Bangkok 10230"
    );

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(32, 20, "Tel. +66 (0)-2539-0958-9");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(32);
    doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
    doc.setFont("THSarabunNewBold");
    doc.text(205, 12, "Purchase Order", "right");

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(25);
    doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
    doc.setFont("THSarabunNewBold");
    doc.text(205, 22, "ใบสั่งซื้อ", "right");

    doc.setTextColor("#000000");
    doc.setFontSize(20);
    doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
    doc.setFont("THSarabunNewBold");
    doc.text(5, 35, "SUPPLIER");

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      28,
      35,
      `${
        toolings.data.hpo_sup_name !== null &&
        toolings.data.hpo_sup_name !== undefined
          ? toolings.data.hpo_sup_name
          : "-"
      }`
    );
    // doc.text(35, 35, `${toolings.data[0].hq_cus_name}`)

    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "right");
    doc.setFont("THSarabunNew");
    doc.text(
      205,
      35,
      `Purchase Order No. : ${
        toolings.data.hpo_no !== null && toolings.data.hpo_no !== undefined
          ? toolings.data.hpo_no
          : "-"
      }`,
      "right"
    );
    // doc.text(188, 35, `${toolings.data[0].hq_no}`, 'left')

    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      5,
      40,
      `${
        toolings.data.hpo_address !== null &&
        toolings.data.hpo_address !== undefined
          ? toolings.data.hpo_address
          : "-"
      }`
    );

    const PO_Date = toolings.data.hpo_date.split("T")[0].split("-");
    let formPODate = `${PO_Date[2]}/${PO_Date[1]}/${PO_Date[0]}`;
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(205, 42, `Date : ${formPODate}`, "right");

    const phoneNumber =
      toolings.data.hpo_sup_tel !== null &&
      toolings.data.hpo_sup_tel !== undefined
        ? toolings.data.hpo_sup_tel.replace(/-/g, "")
        : "-";
    let formattedPhoneNumber =
      phoneNumber.substring(0, 3) +
      "-" +
      phoneNumber.substring(3, 6) +
      "-" +
      phoneNumber.substring(6);
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(5, 45, `Tel. ${formattedPhoneNumber || "-"}`);

    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(186, 49, "Trade Term :", "right");
    doc.text(
      187,
      49,
      `${
        toolings.term.length > 0
          ? toolings.term[0].ct_name !== null &&
            toolings.term[0].ct_name !== undefined
            ? toolings.term[0].ct_name
            : ""
          : ""
      }`
    );
    // doc.text(202, 49, `${toolings.term[0].pt_name}`)
    // doc.text(30, 56, `${word2}`)

    doc.setTextColor("#000000");
    doc.setFontSize(20);
    doc.addFont("THSarabunNewBold.ttf", "THSarabunNewBold", "normal");
    doc.setFont("THSarabunNewBold");
    doc.text(5, 54, "SHIP TO");
    // doc.text(14, 56, `${formattedPhoneNumber || '-'}`)

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(28, 54, "Cute Products Plus Co., Ltd");
    // doc.text(61, 56, `${toolings.data[0].hq_contract_email || ''}`)

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(
      5,
      59,
      "5 Soi LatPhrao-Wanghin 32 Yek 1, LatPhrao-Wanghin Rd. LatPhrao, Bangkok 10230"
    );

    doc.setTextColor("#292520");
    doc.setFontSize(15);
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew");
    doc.text(5, 64, "Tel. +66 (0)-2539-0958-9");

    /*-------------------------------------------------------------------------------*/
    //header

    doc.setFillColor("#8DC445");
    doc.roundedRect(4, 68, 202, 12, 1, 1, "F");

    doc.setTextColor("#FFFFFF");
    // doc.setFontSize(15)
    // doc.text(11, 73, 'ลำดับ', 'center')
    doc.setFontSize(15);
    doc.text(11, 75, "No.", "center");
    // doc.rect(5, 77, 12, 13)

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(24.5, 75, "Picture", "center");
    // doc.rect(17, 77, 19, 13)

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(49, 73, "Barcode/", "center");
    doc.setFontSize(15);
    doc.text(49, 78, "Products Code", "center");
    // doc.rect(36, 77, 38, 13)

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(97.5, 75, "Item Description", "center");
    // doc.rect(74, 77, 59, 13)

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(136, 75, "Qty.", "center");
    // doc.rect(133, 77, 18, 13)

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(154, 75, "Price", "center");
    // doc.rect(151, 77, 18, 13)

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(172, 75, "Discount", "center");
    // doc.rect(169, 77, 18, 13)

    doc.setTextColor("#FFFFFF");
    doc.setFontSize(15);
    doc.text(194, 75, "Total Amount", "center");
    // doc.rect(187, 77, 18, 13)
    var Currency =
      toolings.currency.length > 0
        ? toolings.currency[0].currency_symbol !== null &&
          toolings.currency[0].currency_symbol !== undefined
          ? toolings.currency[0].currency_symbol
          : ""
        : "";
    let countItemRow = 0;
    let gapCheck = 17;
    c = 0;
    // check how many item can be in 1 page
    for (let i = 0; c < toolings.product.length; i = i + gapCheck) {
      if (index + c < toolings.product.length) {
        //Barcode&ProductCode
        //Item description
        let Brand_name =
          toolings.detail[index + c].brand_name !== null &&
          toolings.detail[index + c].brand_name !== undefined
            ? toolings.detail[index + c].brand_name
            : "";
        let Product_name =
          toolings.data.hpo_lang === "ENG"
            ? toolings.detail[index + c].product_name_eng !== null &&
              toolings.detail[index + c].product_name_eng !== undefined
              ? toolings.detail[index + c].product_name_eng
              : ""
            : toolings.data.hpo_lang === "TH"
            ? toolings.detail[index + c].product_name !== null &&
              toolings.detail[index + c].product_name !== undefined
              ? toolings.detail[index + c].product_name
              : ""
            : "";
        let Unit_name =
          toolings.data.hpo_lang === "ENG"
            ? toolings.detail[index + c].pu_name_eng !== null &&
              toolings.detail[index + c].pu_name_eng !== undefined
              ? toolings.detail[index + c].pu_name_eng
              : ""
            : toolings.data.hpo_lang === "TH"
            ? toolings.detail[index + c].pu_name !== null &&
              toolings.detail[index + c].pu_name !== undefined
              ? toolings.detail[index + c].pu_name
              : ""
            : "";

        let allDes =
          Brand_name +
          " " +
          Product_name +
          " " +
          toolings.detail[index + c].pd_weight +
          " " +
          Unit_name;
        let tempAllDes = allDes.split(" ");
        let lineArray = [];
        if (allDes.length > 30) {
          for (let k = 0; k < tempAllDes.length; k++) {
            if (lineArray.length <= 0) {
              lineArray.push(tempAllDes[k]);
            } else if (lineArray.length > 0) {
              if (lineArray[lineArray.length - 1].length < 25) {
                lineArray[lineArray.length - 1] += " " + tempAllDes[k];
              } else {
                lineArray.push(tempAllDes[k]);
              }
            }
          }
          gapCheck =
            6 * lineArray.length + 6 < 17 ? 17 : 6 * lineArray.length + 6;
        } else {
          lineArray.push(allDes);
          gapCheck = 17;
        }
        if (280 - (70 + i) < gapCheck) {
          break;
        } else if (index + c + 1 === toolings.product.length) {
          countItemRow += 1;
          break;
        } else {
          c++;
          countItemRow += 1;
        }
      }
    }
    let gapEachProduct = 17;
    let isMore1page = false;
    row = 0;
    if (countItemRow > 0) {
      for (let i = 0; row <= countItemRow; i = i + gapEachProduct) {
        if (index + row < toolings.product.length) {
          let middleRow = 0;
          let imgMiddleRow = 0;

          //Barcode&ProductCode
          //Item description
          let Brand_name =
            toolings.detail[index + row].brand_name !== null &&
            toolings.detail[index + row].brand_name !== undefined
              ? toolings.detail[index + row].brand_name
              : "";
          let Product_name =
            toolings.data.hpo_lang === "ENG"
              ? toolings.detail[index + row].product_name_eng !== null &&
                toolings.detail[index + row].product_name_eng !== undefined
                ? toolings.detail[index + row].product_name_eng
                : ""
              : toolings.data.hpo_lang === "TH"
              ? toolings.detail[index + row].product_name !== null &&
                toolings.detail[index + row].product_name !== undefined
                ? toolings.detail[index + row].product_name
                : ""
              : "";
          let Unit_name =
            toolings.data.hpo_lang === "ENG"
              ? toolings.detail[index + row].pu_name !== null &&
                toolings.detail[index + row].pu_name !== undefined
                ? toolings.detail[index + row].pu_name
                : ""
              : toolings.data.hpo_lang === "TH"
              ? toolings.detail[index + row].pu_name_eng !== null &&
                toolings.detail[index + row].pu_name_eng !== undefined
                ? toolings.detail[index + row].pu_name_eng
                : ""
              : "";

          let allDes =
            Brand_name +
            " " +
            Product_name +
            " " +
            toolings.detail[index + row].pd_weight +
            " " +
            Unit_name;
          let tempAllDes = allDes.split(" ");
          let y_axis_des = 0;
          let lineArray = [];
          if (allDes.length > 30) {
            for (let i = 0; i < tempAllDes.length; i++) {
              if (lineArray.length <= 0) {
                lineArray.push(tempAllDes[i]);
              } else if (lineArray.length > 0) {
                if (lineArray[lineArray.length - 1].length < 25) {
                  lineArray[lineArray.length - 1] += " " + tempAllDes[i];
                } else {
                  lineArray.push(tempAllDes[i]);
                }
              }
            }
            gapEachProduct =
              6 * lineArray.length + 6 < 17 ? 17 : 6 * lineArray.length + 6;
            y_axis_des = 86 + i;
            middleRow = 86 + i + (2 * lineArray.length + 1);
            imgMiddleRow = 80 + i + (3 * lineArray.length + 1) / 2;
          } else {
            lineArray.push(allDes);
            gapEachProduct = 17;
            y_axis_des = 86 + i;
            middleRow = 85 + i + 4.5;
            imgMiddleRow = 80 + i + 1.5;
          }
          if (
            80 + i + gapEachProduct > 235 &&
            (row === countItemRow ||
              index + row + 1 === toolings.product.length)
          ) {
            isMore1page = true;
            break;
          }
          for (let itemDes of lineArray) {
            doc.setTextColor("#292520");
            doc.setFontSize(15);
            doc.text(66, y_axis_des, `${itemDes}`);
            y_axis_des += 5;
          }
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            66,
            y_axis_des,
            `${
              toolings.detail[index + row].pd_sale_pc_pack !== null &&
              toolings.detail[index + row].pd_sale_pc_pack !== undefined &&
              toolings.detail[index + row].pd_sale_pc_pack !== ""
                ? parseInt(toolings.detail[index + row].pd_sale_pc_pack) > 0
                  ? toolings.detail[index + row].pd_sale_pc_pack
                  : "-"
                : "-"
            } /Pack  ${
              toolings.detail[index + row].pd_sale_pc_ctn !== null &&
              toolings.detail[index + row].pd_sale_pc_ctn !== undefined &&
              toolings.detail[index + row].pd_sale_pc_ctn !== ""
                ? parseInt(toolings.detail[index + row].pd_sale_pc_ctn) > 0
                  ? toolings.detail[index + row].pd_sale_pc_ctn
                  : "-"
                : "-"
            } /Ctn.`
          );

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            49,
            middleRow - 3,
            `${
              toolings.detail[index + row].pb_piece !== null &&
              toolings.detail[index + row].pb_piece !== undefined
                ? toolings.detail[index + row].pb_piece
                : "-"
            }`,
            "center"
          );
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            49,
            middleRow + 3,
            `${
              toolings.detail[index + row].product_code !== null &&
              toolings.detail[index + row].product_code !== undefined
                ? toolings.detail[index + row].product_code
                : "-"
            }`,
            "center"
          );

          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(11, middleRow, (index + row + 1).toString(), "center"); //no.

          //Picture
          var product_Image = `${
            toolings.pic[index + row].path_image !== "" &&
            toolings.pic[index + row].path_image !== null &&
            toolings.pic[index + row].path_image !== undefined
              ? toolings.pic[index + row].path_image?.indexOf("blob") >= 0
                ? toolings.pic[index + row].path_image
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${toolings.pic[index + row].path_name}/${
                    toolings.pic[index + row].path_image
                  }`
              : userdefault_img.imgs
          }`;
          doc.addImage(product_Image, "JPEG", 18, imgMiddleRow, 13, 13);

          //Qty.
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          let Unit =
            toolings.product[index + row].hpp_quantity_unit === "ctn"
              ? "Ctn."
              : toolings.product[index + row].hpp_quantity_unit === "pack"
              ? "Pack"
              : "Piece";
          doc.text(
            136,
            middleRow,
            `${toolings.product[index + row].hpp_quantity.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )} ${Unit}`,
            "center"
          );
          // doc.text(136, middleRow, ``);

          //Price
          let allQty =
            toolings.product[index + row].hpp_quantity_unit === "pack"
              ? parseFloat(
                  toolings.detail[index + row].pd_sale_pc_pack !== null &&
                    toolings.detail[index + row].pd_sale_pc_pack !== undefined
                    ? toolings.detail[index + row].pd_sale_pc_pack
                    : 0
                ) *
                parseFloat(
                  toolings.product[index + row].hpp_quantity !== null &&
                    toolings.product[index + row].hpp_quantity !== undefined
                    ? toolings.product[index + row].hpp_quantity
                    : 0
                )
              : toolings.product[index + row].hpp_quantity_unit === "ctn"
              ? parseFloat(
                  toolings.detail[index + row].pd_sale_pc_ctn !== null &&
                    toolings.detail[index + row].pd_sale_pc_ctn !== undefined
                    ? toolings.detail[index + row].pd_sale_pc_ctn
                    : 0
                ) *
                parseFloat(
                  toolings.product[index + row].hpp_quantity !== null &&
                    toolings.product[index + row].hpp_quantity !== undefined
                    ? toolings.product[index + row].hpp_quantity
                    : 0
                )
              : parseFloat(
                  toolings.product[index + row].hpp_quantity !== null &&
                    toolings.product[index + row].hpp_quantity !== undefined
                    ? toolings.product[index + row].hpp_quantity
                    : 0
                );
          let origin_Price =
            parseFloat(
              toolings.product[index + row].hpp_prices !== null &&
                toolings.product[index + row].hpp_prices !== undefined
                ? toolings.product[index + row].hpp_prices
                : 0
            ) * allQty;
          let originPrice = origin_Price.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(154, middleRow, `${Currency}${originPrice}`, "center");

          //Discount
          let textDC = "";
          let dcValue = 0;
          let priceSubDC = origin_Price;
          for (
            let j = 0;
            j < toolings.product[index + row].discounts.length;
            j++
          ) {
            if (
              toolings.product[index + row].discounts[j].dc_unit === "percent"
            ) {
              dcValue +=
                (priceSubDC *
                  parseFloat(
                    toolings.product[index + row].discounts[j].dc_value !==
                      null &&
                      toolings.product[index + row].discounts[j].dc_value !==
                        undefined
                      ? toolings.product[index + row].discounts[j].dc_value
                      : 0
                  )) /
                100;
              priceSubDC =
                priceSubDC -
                (priceSubDC *
                  parseFloat(
                    toolings.product[index + row].discounts[j].dc_value !==
                      null &&
                      toolings.product[index + row].discounts[j].dc_value !==
                        undefined
                      ? toolings.product[index + row].discounts[j].dc_value
                      : 0
                  )) /
                  100;
            } else {
              dcValue += parseFloat(
                toolings.product[index + row].discounts[j].dc_value !== null &&
                  toolings.product[index + row].discounts[j].dc_value !==
                    undefined
                  ? toolings.product[index + row].discounts[j].dc_value
                  : 0
              );
              priceSubDC =
                priceSubDC -
                parseFloat(
                  toolings.product[index + row].discounts[j].dc_value !==
                    null &&
                    toolings.product[index + row].discounts[j].dc_value !==
                      undefined
                    ? toolings.product[index + row].discounts[j].dc_value
                    : 0
                );
            }
            textDC +=
              toolings.product[index + row].discounts[j].dc_unit === "percent"
                ? (toolings.product[index + row].discounts[j].dc_value !==
                    null &&
                  toolings.product[index + row].discounts[j].dc_value !==
                    undefined
                    ? toolings.product[index + row].discounts[j].dc_value
                    : 0) + "%"
                : Currency +
                    toolings.product[index + row].discounts[j].dc_value !==
                    null &&
                  toolings.product[index + row].discounts[j].dc_value !==
                    undefined
                ? toolings.product[index + row].discounts[j].dc_value
                : 0;
            if (j !== toolings.product[index + row].discounts.length - 1) {
              textDC += "+";
            }
          }
          let eachDC =
            toolings.product[index + row].discounts.length !== 0 ? textDC : "-";
          let eachPrice = priceSubDC;
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            178,
            middleRow,
            `${Currency}${dcValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            "right"
          );

          //Total Amount
          doc.setTextColor("#292520");
          doc.setFontSize(15);
          doc.text(
            205,
            middleRow,
            `${Currency}${eachPrice.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
            "right"
          );
          sumSubTotal += eachPrice;

          doc.setDrawColor("#8DC445");
          doc.rect(4, 80 + i + gapEachProduct, 202, 0); //No.
          // doc.text(4, 82 + i + gapEachProduct, (82 + i + gapEachProduct).toString()); //No.
        }
        row = row + 1;
        counts = itemLenght - countItemRow;
      }
    } else {
      row += 1;
    }

    /*------------------------------------------------------------------------*/

    var checkNumProduct = toolings.product.length - index;
    if (index < counts || isMore1page) {
      doc.addPage("a4", "h");
    } else {
      let gapLines = 154;
      doc.setDrawColor("#8DC445");
      doc.rect(4, 80.5 + gapLines, 202, 0);
      doc.rect(5, 82 + gapLines, 137, 28);

      doc.setFillColor("#8DC445");
      doc.roundedRect(5, 82 + gapLines, 32, 10, 1, 1, "F");
      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(21, 88 + gapLines, "หมายเหตุ/Remark", "center");

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        10,
        97 + gapLines,
        `${
          toolings.data.hpo_remark !== null &&
          toolings.data.hpo_remark !== undefined
            ? toolings.data.hpo_remark
            : ""
        }`
      );

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(179, 86 + gapLines, "Sub Total :", "right");
      // doc.rect(142, 203, 37, 8)

      //value Sub Total
      let subTotal = sumSubTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(205, 86 + gapLines, `${Currency}${subTotal}`, "right");
      // let subTotal = parseFloat(toolings.data[0].hq_total_befor_vat).toFixed(2)
      // doc.rect(179, 203, 26, 8)

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(179, 91 + gapLines, "Discount :", "right");
      // doc.rect(142, 211, 37, 8)

      //value UserDiscount
      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(
        205,
        91 + gapLines,
        `${Currency}${
          toolings.data.hpo_final_discount !== null
            ? parseFloat(toolings.data.hpo_final_discount).toLocaleString(
                undefined,
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )
            : "0.00"
        }`,
        "right"
      );
      // doc.rect(179, 211, 26, 8)

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(179, 96 + gapLines, "Vat :", "right");
      // doc.rect(142, 227, 37, 8)

      //value Vat
      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      let vatValue =
        toolings.data.hpo_incl_vat === false &&
        (toolings.data.hpo_vat === "0" || toolings.data.hpo_vat === null)
          ? " 0.00"
          : parseFloat(toolings.data.hpo_vat).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
      doc.text(205, 96 + gapLines, `${Currency}${vatValue}`, "right");
      // doc.rect(179, 227, 26, 8)

      // let grandTotal = parseFloat(toolings.data[0].hq_total_price).toFixed(2)
      let sumGrand =
        toolings.data.hpo_incl_vat === true
          ? sumSubTotal + sumSubTotal * 0.07
          : sumSubTotal;
      let subTotal_GP =
        sumGrand -
        parseFloat(
          toolings.data.hpo_final_discount !== null &&
            toolings.data.hpo_final_discount !== undefined
            ? toolings.data.hpo_final_discount
            : 0
        );
      let grandTotal = subTotal_GP.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      doc.setFillColor("#8DC445");
      doc.roundedRect(144, 98 + gapLines, 62, 10, 1, 1, "F");
      doc.setTextColor("#FFFFFF");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(179, 104.5 + gapLines, "GRAND TOTAL :", "right");
      doc.text(205, 104.5 + gapLines, `${Currency}${grandTotal}`, "right");

      doc.rect(15, 284, 35, 0);
      doc.rect(55, 284, 35, 0);
      doc.rect(120, 284, 35, 0);
      doc.rect(160, 284, 35, 0);

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(32.5, 289.5, "Authorized By", "center");

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(72.5, 289.5, "Date", "center");

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(137.5, 289.5, "Approved", "center");

      doc.setTextColor("#292520");
      doc.setFontSize(15);
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.setFont("THSarabunNew");
      doc.text(177.5, 289.5, "Date", "center");
    }
    // else if (checkNumProduct > 10) {
    //   doc.setDrawColor("#8DC445");
    //   doc.rect(5, 230, 200, 0);
    // }
  }

  if (statusCheck === true) {
    return doc.output("datauristring");
  } else {
    window.open(doc.output("bloburl"));
  }
}

export default Quotation_report;
