import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import TableLEK from "../../component/tableLEKTTT/table";

function Warehouse_History_CTS(params) {

  const [test, settest] = useState({
    test: '',
  });
  const [headTable, setheadTable] = useState(
    [

    ]
  );
  const [valueData, setvalueData] = useState([]);
  const [countPage, setCountPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);

  const [material_, setMaterial_] = useState([]);
  const [material_header, setMaterial_header] = useState([]);



  const [cutting, setcutting] = useState([]);
  const [filler_cutting, setFiller_cutting] = useState({
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
  });
  const [feature, setfeature] = useState([]);
  const [material, setmaterial] = useState([]);
  const [mat_lot_view_list, setmat_lot_view_list] = useState({
    deflux_lot: [],
    mat_lot: "",
  });
  const [header_list_mat, setheader_list_mat] = useState([]);
  var startdate = moment(new Date()).startOf("month");
  var enddate = moment(new Date()).endOf("month");
  const [filler_material, setFiller_material] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
    date_start: new Date(startdate),
    date_end: new Date(enddate),
    in: true,
    out: true,
    all_search: ''
  });

  //deflux_STP

  const [deflux_lot_view_list, setdeflux_lot_view_list] = useState({
    deflux_lot: "",
    mat_lot: [],
  });

  //deflux_STP

  const [showActive, setShowActive] = useState("");

  const [product_fg, setproduct_fg] = useState([]);

  //-------------have project
  const [product_header_list_mat, setproduct_header_list_mat] = useState([]);
  const [filler_product_fg, setFiller_product_fg] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
    project_name: "",
    date_start_fg: new Date(startdate),
    date_end_fg: new Date(enddate),
    in: true,
    out: true,
    all_search:''
  });

  const [wip_prod, setwip_prod] = useState([]);
  const [wip_prod_, setwip_prod_] = useState([]);
  const [filler_wip_prod, setFiller_wip_prod] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    no: "",
    name: "",
    date_start_wp: new Date(startdate),
    date_end_wp: new Date(enddate),
    in: true,
    out: true,
    all_search:''
  });
  /* ลูกชุบ */
  const [wip_chup, setwip_chup] = useState([]);
  const [filler_wip_chup, setFiller_wip_chup] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    no: "",
    name: "",
    date_start_cp: new Date(startdate),
    date_end_cp: new Date(enddate),
    in: true,
    out: true,
    all_search: ''
  });
  /* ลูกชุบ */
  /* pack */
  const [pack_arry, setpack_arry] = useState([]);
  const [filler_pack, setFiller_pack] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    no: "",
    name: "",
    date_start_pk: new Date(startdate),
    date_end_pk: new Date(enddate),
    in: true,
    out: true,
    all_search: ''
  });
  /* pack */
  const [ro, setro] = useState([]);
  const GetMaterial = async () => {
    var temp = {
      company_id: filler_material.company_id,
      oem_id: filler_material.oem_id,
      no: filler_material.no.trim(),
      name: filler_material.name.trim(),
      edit_type: filler_material.edit_type,
      amount: filler_material.amount,
      create_date: filler_material.create_date,
      date_start: filler_material.date_start,
      date_end: filler_material.date_end,
      in: filler_material.in,
      out: filler_material.out,
    };
    // console.log(temp);
    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterMaterial",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        response.data.heder.splice(0, 0, {
          label: "No.",
          field: "number",
          sort: "asc",
          width: 50,
        });
        // console.log("index -1", response.data.heder.splice(-1, 0, ({label: 'number', field: 'number', sort: 'asc', width: 50})));
        // console.log("re",response.data.heder);
        setheader_list_mat(response.data.heder);
        setmaterial(response.data.bodyValue);
        setro(response.data.he);
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        console.log(error);
      });
  };

  const GetMaterial_ = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }
    var temp = {
      company_id: filler_material.company_id,
      oem_id: filler_material.oem_id,
      no: filler_material.no.trim(),
      name: filler_material.name.trim(),
      edit_type: filler_material.edit_type,
      amount: filler_material.amount,
      create_date: filler_material.create_date,
      date_start: filler_material.date_start,
      date_end: filler_material.date_end,
      in: filler_material.in,
      out: filler_material.out,
      page: page || 1,
      size: size || 10,
      all_search: (filler_material.all_search || '').trim()
    };
    // console.log(temp);
    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterMaterial_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        /*        response.data.heder.splice(0, 0, {
                 label: "No.",
                 field: "number",
                 sort: "asc",
                 width: 50,
               });
               // console.log("index -1", response.data.heder.splice(-1, 0, ({label: 'number', field: 'number', sort: 'asc', width: 50})));
               // console.log("re",response.data.heder);
               setheader_list_mat(response.data.heder);
               setmaterial(response.data.bodyValue);
               setro(response.data.he); */
        setMaterial_header(response.data.header);
        setMaterial_(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        Swal.close();
        console.log(error);
      });
  };

  //--------------------มี project
  const GetProduct_fg = async () => {
    var temp = {
      company_id: filler_product_fg.company_id,
      oem_id: filler_product_fg.oem_id,
      no: filler_product_fg.no.trim(),
      name: filler_product_fg.name.trim(),
      edit_type: filler_product_fg.edit_type,
      amount: filler_product_fg.amount,
      create_date: filler_product_fg.create_date,
      project_name: filler_product_fg.project_name,
      date_start_fg: filler_product_fg.date_start_fg,
      date_end_fg: filler_product_fg.date_end_fg,
      in: filler_product_fg.in,
      out: filler_product_fg.out,
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterlogFgEditCts",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        /* response.data.hederProduct.splice(0, 0, ({label: 'ลำดับ', field: 'number', sort: 'asc', width: 50}))
                response.data.hederProduct.splice(6, 0, ({label: 'Lot No', field: 'lot_no', sort: 'asc', width: 50}))
                response.data.hederProduct.splice(7, 0, ({label: 'Customer', field: 'Customer', sort: 'asc', width: 50})) */

        /* setproduct_header_list_mat(response.data.hederProduct);
                setproduct_fg(response.data.bodyProductValue); */
        setproduct_fg(response.data);
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        // GetProduct_fg();
        console.log(error);
      });
  };


  const [product_fg_, setproduct_fg_] = useState([]);

  const GetProduct_fg_ = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }
    var temp = {
      company_id: filler_product_fg.company_id,
      oem_id: filler_product_fg.oem_id,
      no: filler_product_fg.no.trim(),
      name: filler_product_fg.name.trim(),
      edit_type: filler_product_fg.edit_type,
      amount: filler_product_fg.amount,
      create_date: filler_product_fg.create_date,
      project_name: filler_product_fg.project_name,
      date_start_fg: filler_product_fg.date_start_fg,
      date_end_fg: filler_product_fg.date_end_fg,
      in: filler_product_fg.in,
      out: filler_product_fg.out,
      page: page || 1,
      size: size || 10,
      all_search: (filler_product_fg.all_search || '').trim(),
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterlogFgEditCts_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {

        setproduct_fg_(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        Swal.close();
        Swal.fire("Error", error.response.data.message, "error");
        // GetProduct_fg();
        console.log(error);
      });
  };

  const GetWipprod = async () => {
    var temp = {
      /*  company_id: filler_wip_prod.company_id, */
      oem_id: filler_wip_prod.oem_id,
      no: filler_wip_prod.no.trim(),
      name: filler_wip_prod.name.trim(),
      date_start_wp: filler_wip_prod.date_start_wp,
      date_end_wp: filler_wip_prod.date_end_wp,
      in: filler_wip_prod.in,
      out: filler_wip_prod.out,
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/Wipprodfilter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        /* setproduct_header_list_mat(response.data); */
        setwip_prod(response.data);
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        // GetProduct_fg();
        console.log(error);
      });
  };
  const GetWipprod_ = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }
    var temp = {
      /*  company_id: filler_wip_prod.company_id, */
      oem_id: filler_wip_prod.oem_id,
      no: filler_wip_prod.no.trim(),
      name: filler_wip_prod.name.trim(),
      date_start_wp: filler_wip_prod.date_start_wp,
      date_end_wp: filler_wip_prod.date_end_wp,
      in: filler_wip_prod.in,
      out: filler_wip_prod.out,
      page: page || 1,
      size: size || 10,
      all_search: (filler_wip_prod.all_search || '').trim()
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/Wipprodfilter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        /* setproduct_header_list_mat(response.data); */
        //setwip_prod(response.data);
        setwip_prod_(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        Swal.close();
        // GetProduct_fg();
        console.log(error);
      });
  };

  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [companys, setCompany] = useState([]);
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  /* ลูกชุบ */
  const GetWipChup = async () => {
    var temp = {
      oem_id: filler_wip_chup.oem_id,
      no: filler_wip_chup.no.trim(),
      name: filler_wip_chup.name.trim(),
      date_start_cp: filler_wip_chup.date_start_cp,
      date_end_cp: filler_wip_chup.date_end_cp,
      in: filler_wip_chup.in,
      out: filler_wip_chup.out,
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterChuplog",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setwip_chup(response.data);
        console.log("GetChup", response.data);
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        // GetProduct_fg();
        console.log(error);
      });
  };

  const [wip_chup_, setwip_chup_] = useState([]);
  const GetWipChup_ = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }
    var temp = {
      oem_id: filler_wip_chup.oem_id,
      no: filler_wip_chup.no.trim(),
      name: filler_wip_chup.name.trim(),
      date_start_cp: filler_wip_chup.date_start_cp,
      date_end_cp: filler_wip_chup.date_end_cp,
      in: filler_wip_chup.in,
      out: filler_wip_chup.out,
      page: page || 1,
      size: size || 10,
      all_search: (filler_wip_chup.all_search || '').trim(),
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterChuplog_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {

        // console.log("GetChup", response.data);
        setwip_chup_(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        Swal.close();
        // GetProduct_fg();
        console.log(error);
      });
  };
  /* ลูกชุบ */
  /* pack */
  const GetLogInOutpack = async () => {
    var temp = {
      oem_id: filler_pack.oem_id,
      no: filler_pack.no.trim(),
      name: filler_pack.name.trim(),
      date_start_pack: filler_pack.date_start_pk,
      date_end_pack: filler_pack.date_end_pk,
      in: filler_pack.in,
      out: filler_pack.out,
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterlogPackCts",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setpack_arry(response.data);
        console.log("pack", response.data);
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        // GetProduct_fg();
        console.log(error);
      });
  };


  const [pack_data_, set_pack_data_] = useState([]);
  const GetLogInOutpack_ = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }
    var temp = {
      oem_id: filler_pack.oem_id,
      no: filler_pack.no.trim(),
      name: filler_pack.name.trim(),
      date_start_pack: filler_pack.date_start_pk,
      date_end_pack: filler_pack.date_end_pk,
      in: filler_pack.in,
      out: filler_pack.out,
      page: page || 1,
      size: size || 10,
      all_search: (filler_pack.all_search || '').trim()
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/api/warehouseCTS/filterlogPackCts_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        set_pack_data_(response.data.data);

        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        settest({ ...test, test: '' })
        test.test = '';
        Swal.close();
      })
      .catch(function (error) {
        Swal.fire("Error", error.response.data.message, "error");
        Swal.close();
        // GetProduct_fg();
        console.log(error);
      });
  };
  /* pack */
  useEffect(() => {
    // var feature_data = JSON.parse(getFeature()).filter((element) => {
    //     return element.menu === "Warehouse History";
    // });
    // console.log(feature_data);
    // console.log("ฟีลเจอร์",feature_data[0].feature);
    setShowActive("Material");

    // setfeature(feature_data);

    GetCompany();

    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
          if (el.id === getOem()) {
            setCurrentOEM({ ...currentOEM, label: el.name, value: el.id });
            currentOEM.label = el.name;
            currentOEM.value = el.id;
          }
        });

        if (showActive == 'Material') {
          GetMaterial_(1, 10, 'search');
          GetMaterial();
        } else if (showActive == 'wip prod') {
          GetWipprod_(1, 10, 'search');
          GetWipprod();
        } else if (showActive == 'wip chup') {
          GetWipChup_(1, 10, 'search');
          GetWipChup(); /* ลูกชุบ */
        } else if (showActive == 'pack') {
          GetLogInOutpack(); /* pack */
          GetLogInOutpack_(1, 10, 'search'); /* pack */
        } else if (showActive == 'Product FG') {
          GetProduct_fg();
          GetProduct_fg_(1, 10, 'search');
        } else {
          GetMaterial_(1, 10, 'search');
          GetMaterial();
        }
        setCompany(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  //console.log("nameOEM",currentOEM)
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );
  async function SetDate_start(date) {
    setFiller_material({ ...filler_material, date_start: date });
  }
  async function SetDate_end(date) {
    setFiller_material({ ...filler_material, date_end: date });
  }
  async function SetDate_start_fg(date) {
    setFiller_product_fg({ ...filler_product_fg, date_start_fg: date });
  }
  async function SetDate_end_fg(date) {
    setFiller_product_fg({ ...filler_product_fg, date_end_fg: date });
  }

  const clearFilter = async () => {
    /*   await setFiller_material({
        ...filler_material,
        no: "",
        name: "",
        create_date: "",
        date_start: "",
        date_end: "",
        in: true,
        out: true,
      });
      filler_material.no = "";
      filler_material.name = "";
      filler_material.create_date = "";
      filler_material.date_start = "";
      filler_material.date_end = "";
      GetMaterial();
      GetMaterial_(1,10,'search'); */

    /*  await setFiller_product_fg({
       ...filler_product_fg,
       no: "",
       name: "",
       create_date: "",
       project_name: "",
       date_start_fg: "",
       date_end_fg: "",
       in: true,
       out: true,
     });
     filler_product_fg.no = "";
     filler_product_fg.name = "";
     filler_product_fg.create_date = "";
     filler_product_fg.project_name = "";
     filler_product_fg.date_start_fg = "";
     filler_product_fg.date_end_fg = "";
     GetProduct_fg(); */

    /* await setFiller_wip_prod({
      ...filler_wip_prod,

      no: "",
      name: "",
      date_start_wp: "",
      date_end_wp: "",
      in: true,
      out: true,
    });

    filler_wip_prod.no = "";
    filler_wip_prod.name = "";
    filler_wip_prod.date_start_wp = "";
    filler_wip_prod.date_end_wp = "";
    GetWipprod(); */

    /*     await setFiller_wip_chup({
          ...filler_wip_chup,
          no: "",
          name: "",
          date_start_cp: "",
          date_end_cp: "",
          in: true,
          out: true,
        });
        filler_wip_chup.no = "";
        filler_wip_chup.name = "";
        filler_wip_chup.date_start_cp = "";
        filler_wip_chup.date_end_cp = "";
    
        GetWipChup(); */
    /*  setFiller_pack({
       ...filler_pack,
       no: "",
       name: "",
       date_start_pk: "",
       date_end_pk: "",
       in: true,
       out: true,
     });
     filler_pack.no = "";
     filler_pack.name = "";
     filler_pack.date_start_pk = "";
     filler_pack.date_end_pk = "";
     GetLogInOutpack(); */
  };

  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail/add";
  }

  function formMatLot(mat_detail, mat_lot, e) {
    //console.log(deflux_detail)
    setmat_lot_view_list({
      ...mat_lot_view_list,
      mat_lot: mat_lot,
      deflux_lot: mat_detail,
    });
    mat_lot_view_list.deflux_lot = mat_detail;
    mat_lot_view_list.mat_lot = mat_lot;
  }
  const materialrowsData = [];
  //console.log(material, "dd");
  for (var index = 0; index < material.length; index++) {
    const rowItem = {};
    // console.log("sadasd",ro[index]," : ",material[index]);

    rowItem["number"] = index + 1;
    rowItem["material no "] = material[index]["material no "];
    rowItem["material name"] = material[index]["material name"];
    rowItem["activity type"] = material[index]["activity type"];
    rowItem["coil no"] = material[index]["coil no"];
    rowItem["invoice no"] = material[index]["invoice no"];
    rowItem["amount"] = material[index].amount;
    rowItem["check_date"] =
      moment(material[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["tag weight"] = material[index]["tag weight"];
    rowItem["actual_weight"] = material[index].actual_weight;
    rowItem["grade"] = material[index].grade;
    rowItem["supplier name"] = material[index]["supplier name"];
    rowItem["location name"] = material[index]["location name"];
    rowItem["oem"] = material[index].oem;
    rowItem["company"] = material[index].company;
    rowItem["date"] =
      moment(material[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["username"] = material[index].username || "";
    if (material[index]["qc date"] !== null) {
      rowItem["qc date"] =
        moment(material[index]["qc date"]).format("DD/MM/yyyy ::: HH:mm") || "";
    }
    rowItem["qc by"] = material[index]["qc by"] || "";
    rowItem["supplier coil"] = material[index]["supplier coil"] || "";
    rowItem["ref coil no"] = material[index]["ref coil no"] || "";
    /*     rowItem["username"] = material[index].username || "";
     */

    materialrowsData.push(rowItem);
  }

  const materialrowsData_ = [];
  //console.log(material, "dd");
  for (var index = 0; index < material_.length; index++) {
    const rowItem = {};
    // console.log("sadasd",ro[index]," : ",material[index]);

    rowItem["number"] = index + 1;
    rowItem["material no "] = material_[index]["material no "];
    rowItem["material name"] = material_[index]["material name"];
    rowItem["activity type"] = material_[index]["activity type"];
    rowItem["coil no"] = material_[index]["coil no"];
    rowItem["invoice no"] = material_[index]["invoice no"];
    rowItem["amount"] = material_[index].amount;
    rowItem["check_date"] =
      moment(material_[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["tag weight"] = material_[index]["tag weight"];
    rowItem["actual_weight"] = material_[index].actual_weight;
    rowItem["grade"] = material_[index].grade;
    rowItem["supplier name"] = material_[index]["supplier name"];
    rowItem["location name"] = material_[index]["location name"];
    rowItem["oem"] = material_[index].oem;
    rowItem["company"] = material_[index].company;
    rowItem["date"] =
      moment(material_[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["username"] = material_[index].username || "";
    if (material_[index]["qc date"] !== null) {
      rowItem["qc date"] =
        moment(material_[index]["qc date"]).format("DD/MM/yyyy ::: HH:mm") || "";
    }
    rowItem["qc by"] = material_[index]["qc by"] || "";
    rowItem["supplier coil"] = material_[index]["supplier coil"] || "";
    rowItem["ref coil no"] = material_[index]["ref coil no"] || "";
    /*     rowItem["username"] = material[index].username || "";
     */

    materialrowsData_.push(rowItem);
  }

  const product_fg_rowsData = [];

  for (var index = 0; index < product_fg.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = product_fg[index].product_no;
    rowItem["product_name"] = product_fg[index].product_name;
    rowItem["activity_type"] =
      product_fg[index].edit_type === true ? "IN" : "OUT";
    rowItem["amount"] = product_fg[index].amount;
    rowItem["lot_no"] = product_fg[index].lot_no;
    rowItem["customer_name"] = product_fg[index].customer_name;
    rowItem["project_name"] = product_fg[index].project_name;
    /* rowItem["po_no"] = product_fg[index].order_no || ""; */
    rowItem["oem_name"] = product_fg[index].oem_name || "";
    rowItem["exp_date"] = product_fg[index].exp_date
      ? moment(product_fg[index].exp_date).format("DD/MM/yyyy ::: HH:mm:ss a  ")
      : "";

    rowItem["create_date"] = moment(product_fg[index].create_date).format(
      "DD/MM/yyyy ::: HH:mm:ss a  "
    );
    //rowItem["date"] = moment(product_fg[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["Remark"] = product_fg[index].remark || "";
    rowItem["username"] = product_fg[index].username || "";
    product_fg_rowsData.push(rowItem);
  }

  const product_fg_rowsData_ = [];

  for (var index = 0; index < product_fg_.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = product_fg_[index].product_no;
    rowItem["product_name"] = product_fg_[index].product_name;
    rowItem["activity_type"] =
      product_fg_[index].edit_type === true ? "IN" : "OUT";
    rowItem["amount"] = product_fg_[index].amount;
    rowItem["lot_no"] = product_fg_[index].lot_no;
    rowItem["customer_name"] = product_fg_[index].customer_name;
    rowItem["project_name"] = product_fg_[index].project_name;
    /* rowItem["po_no"] = product_fg_[index].order_no || ""; */
    rowItem["oem_name"] = product_fg_[index].oem_name || "";
    rowItem["exp_date"] = product_fg_[index].exp_date
      ? moment(product_fg_[index].exp_date).format("DD/MM/yyyy ::: HH:mm:ss a  ")
      : "";

    rowItem["create_date"] = moment(product_fg_[index].create_date).format(
      "DD/MM/yyyy ::: HH:mm:ss a  "
    );
    //rowItem["date"] = moment(product_fg_[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["Remark"] = product_fg_[index].remark || "";
    rowItem["username"] = product_fg_[index].username || "";
    product_fg_rowsData_.push(rowItem);
  }

  /* ลูกชุบ */
  const wipChup_rowsData = [];

  for (var index = 0; index < wip_chup.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = wip_chup[index].product_no;
    rowItem["product_name"] = wip_chup[index].product_name;
    rowItem["activity_type"] =
      wip_chup[index].edit_type === true ? "IN" : "OUT";
    rowItem["amount"] = wip_chup[index].amount;
    rowItem["amount_weight"] = wip_chup[index].weight_amount ? (
      <NumberFormat
        thousandSeparator={true}
        value={parseFloat(wip_chup[index].weight_amount).toFixed(2)}
        displayType="text"
      />
    ) : (
      <NumberFormat
        thousandSeparator={true}
        value={parseFloat(
          (parseFloat(wip_chup[index].picecs_amount) *
            parseFloat(wip_chup[index].nw)) /
          1000
        ).toFixed(2)}
        displayType="text"
      />
    );
    rowItem["amount_price"] = wip_chup[index].picecs_amount ? (
      <NumberFormat
        thousandSeparator={true}
        value={parseInt(wip_chup[index].picecs_amount)}
        displayType="text"
      />
    ) : (
      <NumberFormat
        thousandSeparator={true}
        value={Math.floor(
          (parseFloat(wip_chup[index].weight_amount) /
            parseFloat(wip_chup[index].nw)) *
          1000
        )}
        displayType="text"
      />
    );
    rowItem["lot_no"] = wip_chup[index].lot_no;
    rowItem["tracking_tag"] = wip_chup[index].tracking_tag_wip_chup;
    rowItem["actual_date"] = wip_chup[index].actual_date
      ? moment(wip_chup[index].actual_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "-";
    rowItem["estimate_date"] = wip_chup[index].estimate_date
      ? moment(wip_chup[index].estimate_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    rowItem["customer_name"] = wip_chup[index].customer_name;
    rowItem["project_name"] = wip_chup[index].project_name;
    rowItem["loss_amount"] = wip_chup[index].loss_amount
      ? parseInt(wip_chup[index].loss_amount)
      : 0;
    rowItem["po_no"] = wip_chup[index].po_no || "";
    rowItem["oem_name"] = wip_chup[index].oem_name || "";
    rowItem["plant_code"] = wip_chup[index].plant_code || "";
    /*    rowItem["plant_telnumber"] = wip_chup[index].plant_telnumber || ""; */
    rowItem["plant_telnumber"] = (
      <>
        <a href={"tel:" + wip_chup[index].plant_telnumber}>
          {wip_chup[index].plant_telnumber}
        </a>
      </>
    );

    rowItem["create_date"] = wip_chup[index].create_date
      ? moment(wip_chup[index].create_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    //rowItem["date"] = moment(wip_chup[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["Remark"] = wip_chup[index].remark || "";
    rowItem["username"] = wip_chup[index].username || "";
    wipChup_rowsData.push(rowItem);
  }

  const wipChup_rowsData_ = [];

  for (var index = 0; index < wip_chup_.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = wip_chup_[index].product_no;
    rowItem["product_name"] = wip_chup_[index].product_name;
    rowItem["activity_type"] =
      wip_chup_[index].edit_type === true ? "IN" : "OUT";
    rowItem["amount"] = wip_chup_[index].amount;
    rowItem["amount_weight"] = wip_chup_[index].weight_amount ? (
      <NumberFormat
        thousandSeparator={true}
        value={parseFloat(wip_chup_[index].weight_amount).toFixed(2)}
        displayType="text"
      />
    ) : (
      <NumberFormat
        thousandSeparator={true}
        value={parseFloat(
          (parseFloat(wip_chup_[index].picecs_amount) *
            parseFloat(wip_chup_[index].nw)) /
          1000
        ).toFixed(2)}
        displayType="text"
      />
    );
    rowItem["amount_price"] = wip_chup_[index].picecs_amount ? (
      <NumberFormat
        thousandSeparator={true}
        value={parseInt(wip_chup_[index].picecs_amount)}
        displayType="text"
      />
    ) : (
      <NumberFormat
        thousandSeparator={true}
        value={Math.floor(
          (parseFloat(wip_chup_[index].weight_amount) /
            parseFloat(wip_chup_[index].nw)) *
          1000
        )}
        displayType="text"
      />
    );
    rowItem["lot_no"] = wip_chup_[index].lot_no;
    rowItem["tracking_tag"] = wip_chup_[index].tracking_tag_wip_chup;
    rowItem["actual_date"] = wip_chup_[index].actual_date
      ? moment(wip_chup_[index].actual_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "-";
    rowItem["estimate_date"] = wip_chup_[index].estimate_date
      ? moment(wip_chup_[index].estimate_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    rowItem["customer_name"] = wip_chup_[index].customer_name;
    rowItem["project_name"] = wip_chup_[index].project_name;
    rowItem["loss_amount"] = wip_chup_[index].loss_amount
      ? parseInt(wip_chup_[index].loss_amount)
      : 0;
    rowItem["po_no"] = wip_chup_[index].po_no || "";
    rowItem["oem_name"] = wip_chup_[index].oem_name || "";
    rowItem["plant_code"] = wip_chup_[index].plant_code || "";
    /*    rowItem["plant_telnumber"] = wip_chup_[index].plant_telnumber || ""; */
    rowItem["plant_telnumber"] = (
      <>
        <a href={"tel:" + wip_chup_[index].plant_telnumber}>
          {wip_chup_[index].plant_telnumber}
        </a>
      </>
    );

    rowItem["create_date"] = wip_chup_[index].create_date
      ? moment(wip_chup_[index].create_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    //rowItem["date"] = moment(wip_chup_[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["Remark"] = wip_chup_[index].remark || "";
    rowItem["username"] = wip_chup_[index].username || "";
    wipChup_rowsData_.push(rowItem);
  }

  /* ลูกชุบ */
  /* ลูกชุบ */
  const pack_rowsData = [];

  for (var index = 0; index < pack_arry.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = pack_arry[index].product_no;
    rowItem["product_name"] = pack_arry[index].product_name;
    rowItem["activity_type"] =
      pack_arry[index].edit_type === true ? "IN" : "OUT";
    rowItem["amount"] = pack_arry[index].amount;
    rowItem["amount_weight"] =
      pack_arry[index].weight_amount !== null ? (
        <NumberFormat
          thousandSeparator={true}
          value={parseFloat(pack_arry[index].weight_amount).toFixed(2)}
          displayType="text"
        />
      ) : (
        <NumberFormat
          thousandSeparator={true}
          value={Math.floor(
            (parseFloat(pack_arry[index].picecs_amount) *
              parseFloat(pack_arry[index].nw)) /
            1000
          ).toFixed(2)}
          displayType="text"
        />
      );
    rowItem["amount_price"] =
      pack_arry[index].picecs_amount === null ? (
        <NumberFormat
          thousandSeparator={true}
          value={parseInt(pack_arry[index].picecs_amount)}
          displayType="text"
        /> /* "test2" */
      ) : (
        /* "test"; */
        <NumberFormat
          thousandSeparator={true}
          value={Math.floor(
            (parseFloat(pack_arry[index].weight_amount) /
              parseFloat(pack_arry[index].nw)) *
            1000
          )}
          displayType="text"
        />
      );
    /*  Math.floor(
          parseFloat(pack_arry[index].weight_amount) /
            parseFloat(pack_arry[index].nw)
        ) || 0; */
    rowItem["lot_no"] = pack_arry[index].pack_lot_no;
    rowItem["actual_date"] = pack_arry[index].actual_date
      ? moment(pack_arry[index].actual_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "-";
    rowItem["estimate_date"] = pack_arry[index].estimate_date
      ? moment(pack_arry[index].estimate_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    rowItem["customer_name"] = pack_arry[index].customer_name;
    rowItem["project_name"] = pack_arry[index].project_name;
    rowItem["po_no"] = pack_arry[index].chup_po_no || "";
    rowItem["oem_name"] = pack_arry[index].oem_name || "";
    rowItem["plant_code"] = pack_arry[index].plant_code || "";
    rowItem["pack_no"] = pack_arry[index].pack_no || "";
    rowItem["pack_loss"] = pack_arry[index].pack_loss
      ? parseInt(pack_arry[index].pack_loss)
      : 0;
    /*    rowItem["plant_telnumber"] = pack_arry[index].plant_telnumber || ""; */
    rowItem["plant_telnumber"] = (
      <>
        <a href={"tel:" + pack_arry[index].telnumber}>
          {pack_arry[index].telnumber}
        </a>
      </>
    );
    rowItem["create_date"] = pack_arry[index].create_date
      ? moment(pack_arry[index].create_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    //rowItem["date"] = moment(pack_arry[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["Remark"] = pack_arry[index].remark || "";
    rowItem["username"] = pack_arry[index].username || "";
    pack_rowsData.push(rowItem);
  }

  const pack_rowsData_ = [];

  for (var index = 0; index < pack_data_.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = pack_data_[index].product_no;
    rowItem["product_name"] = pack_data_[index].product_name;
    rowItem["activity_type"] =
      pack_data_[index].edit_type === true ? "IN" : "OUT";
    rowItem["amount"] = pack_data_[index].amount;
    rowItem["amount_weight"] =
      pack_data_[index].weight_amount !== null ? (
        <NumberFormat
          thousandSeparator={true}
          value={parseFloat(pack_data_[index].weight_amount).toFixed(2)}
          displayType="text"
        />
      ) : (
        <NumberFormat
          thousandSeparator={true}
          value={Math.floor(
            (parseFloat(pack_data_[index].picecs_amount) *
              parseFloat(pack_data_[index].nw)) /
            1000
          ).toFixed(2)}
          displayType="text"
        />
      );
    rowItem["amount_price"] =
      pack_data_[index].picecs_amount === null ? (
        <NumberFormat
          thousandSeparator={true}
          value={parseInt(pack_data_[index].picecs_amount)}
          displayType="text"
        /> /* "test2" */
      ) : (
        /* "test"; */
        <NumberFormat
          thousandSeparator={true}
          value={Math.floor(
            (parseFloat(pack_data_[index].weight_amount) /
              parseFloat(pack_data_[index].nw)) *
            1000
          )}
          displayType="text"
        />
      );
    /*  Math.floor(
          parseFloat(pack_data_[index].weight_amount) /
            parseFloat(pack_data_[index].nw)
        ) || 0; */
    rowItem["lot_no"] = pack_data_[index].pack_lot_no;
    rowItem["actual_date"] = pack_data_[index].actual_date
      ? moment(pack_data_[index].actual_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "-";
    rowItem["estimate_date"] = pack_data_[index].estimate_date
      ? moment(pack_data_[index].estimate_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    rowItem["customer_name"] = pack_data_[index].customer_name;
    rowItem["project_name"] = pack_data_[index].project_name;
    rowItem["po_no"] = pack_data_[index].chup_po_no || "";
    rowItem["oem_name"] = pack_data_[index].oem_name || "";
    rowItem["plant_code"] = pack_data_[index].plant_code || "";
    rowItem["pack_no"] = pack_data_[index].pack_no || "";
    rowItem["pack_loss"] = pack_data_[index].pack_loss
      ? parseInt(pack_data_[index].pack_loss)
      : 0;
    /*    rowItem["plant_telnumber"] = pack_data_[index].plant_telnumber || ""; */
    rowItem["plant_telnumber"] = (
      <>
        <a href={"tel:" + pack_data_[index].telnumber}>
          {pack_data_[index].telnumber}
        </a>
      </>
    );
    rowItem["create_date"] = pack_data_[index].create_date
      ? moment(pack_data_[index].create_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "ไม่ระบุ";
    //rowItem["date"] = moment(pack_data_[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["Remark"] = pack_data_[index].remark || "";
    rowItem["username"] = pack_data_[index].username || "";
    pack_rowsData_.push(rowItem);
  }

  /* ลูกชุบ */

  const data_material = {
    columns: header_list_mat,

    rows: materialrowsData,
  };
  const data_material_ = {
    columns: material_header,

    rows: materialrowsData_,
  };

  // console.log("เข้าว่าง",header_list_mat[0].oem_id !== "");
  //   if(header_list_mat[0].oem_id !== ""){

  var check1 = data_material.columns.filter((e1) => {
    return e1.field === "oem_id";
  });
  if (check1.length > 0) {
    var index1 = data_material.columns.findIndex((e1) => e1.field === "oem_id");
    data_material.columns.splice(index1, 1);
  }
  var check2 = data_material.columns.filter((e1) => {
    return e1.field === "company_id";
  });
  if (check2.length > 0) {
    var index2 = data_material.columns.findIndex(
      (e1) => e1.field === "company_id"
    );
    data_material.columns.splice(index2, 1);
  }
  var check3 = data_material.columns.filter((e1) => {
    return e1.field === "check_date";
  });
  if (check3.length > 0) {
    var index3 = data_material.columns.findIndex(
      (e1) => e1.field === "check_date"
    );
    data_material.columns.splice(index3, 1);
  }
  var check4 = data_material.columns.filter((e1) => {
    return e1.field === "company";
  });
  if (check4.length > 0) {
    var index4 = data_material.columns.findIndex(
      (e1) => e1.field === "company"
    );
    data_material.columns.splice(index4, 1);
  }
  //   }

  const [fg_header, set_fg_header] = useState([
    {
      label: "Product No.",
      field: "product_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Product Name",
      field: "product_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "Status",
      field: "activity_type",
      sort: "asc",
      width: 50,
    },
    {
      label: "Lot No.",
      field: "lot_no",
      sort: "asc",
      width: 50,
    },
    /*  {
          label: "เลขที่ใบสั่งผลิต",
          field: "po_no",
          sort: "asc",
          width: 50,
        }, */
    {
      label: "Date",
      field: "create_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "Exprire Date",
      field: "exp_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "Amount (ชิ้น)",
      field: "amount",
      sort: "asc",
      width: 50,
    },

    {
      label: "Project",
      field: "project_name",
      sort: "asc",
      width: 50,
    },

    {
      label: "oem",
      field: "oem_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "Customer Name",
      field: "customer_name",
      sort: "asc",
      width: 50,
    },

    {
      label: "Remark",
      field: "Remark",
      sort: "asc",
      width: 50,
    },

    {
      label: "username",
      field: "username",
      sort: "asc",
      width: 50,
    },
  ]);

  const data_product_fg = {
    columns: [
      {
        label: "No.",
        field: "number",
        sort: "asc",
        width: 50,
      },

      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      /*  {
            label: "เลขที่ใบสั่งผลิต",
            field: "po_no",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "Date",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Exprire Date",
        field: "exp_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount (ชิ้น)",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "Project",
        field: "project_name",
        sort: "asc",
        width: 50,
      },

      {
        label: "oem",
        field: "oem_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer Name",
        field: "customer_name",
        sort: "asc",
        width: 50,
      },

      {
        label: "Remark",
        field: "Remark",
        sort: "asc",
        width: 50,
      },

      {
        label: "username",
        field: "username",
        sort: "asc",
        width: 50,
      },
    ],
    rows: product_fg_rowsData,
  };



  const [wip_chup_header, set_wip_chup_header] = useState([
    {
      label: "Product No.",
      field: "product_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Product Name",
      field: "product_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "เลขที่ใบจ่ายงานชุบ",
      field: "po_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "สถานะ",
      field: "activity_type",
      sort: "asc",
      width: 50,
    },
    {
      label: "Lot No.",
      field: "lot_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Tracking Tag.",
      field: "tracking_tag",
      sort: "asc",
      width: 50,
    },

    {
      label: "วันที่ส่งชุบ",
      field: "create_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "วันที่คาดรับงาน",
      field: "estimate_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "วันที่รับชุบ",
      field: "actual_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "Amount(กก.)",
      field: "amount_weight",
      sort: "asc",
      width: 50,
    },
    {
      label: "Amount(ชิ้น)",
      field: "amount_price",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวนสูญหาย",
      field: "loss_amount",
      sort: "asc",
      width: 50,
    },

    {
      label: "ชื่อย่อโรงชุบ.",
      field: "plant_code",
      sort: "asc",
      width: 50,
    },
    {
      label: "เบอร์โทรโรงชุบ",
      field: "plant_telnumber",
      sort: "asc",
      width: 50,
    },

    /* {
          label: "oem",
          field: "oem_name",
          sort: "asc",
          width: 50,
        }, */
    /* {
          label: "Customer Name",
          field: "customer_name",
          sort: "asc",
          width: 50,
        }, */

    /*  {
          label: "Remark",
          field: "Remark",
          sort: "asc",
          width: 50,
        }, */

    {
      label: "username",
      field: "username",
      sort: "asc",
      width: 50,
    },
  ]);
  const data_wipChup = {
    columns: [
      {
        label: "No.",
        field: "number",
        sort: "asc",
        width: 50,
      },

      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขที่ใบจ่ายงานชุบ",
        field: "po_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tracking Tag.",
        field: "tracking_tag",
        sort: "asc",
        width: 50,
      },

      {
        label: "วันที่ส่งชุบ",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่คาดรับงาน",
        field: "estimate_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่รับชุบ",
        field: "actual_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(กก.)",
        field: "amount_weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "amount_price",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวนสูญหาย",
        field: "loss_amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "ชื่อย่อโรงชุบ.",
        field: "plant_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "plant_telnumber",
        sort: "asc",
        width: 50,
      },

      /* {
            label: "oem",
            field: "oem_name",
            sort: "asc",
            width: 50,
          }, */
      /* {
            label: "Customer Name",
            field: "customer_name",
            sort: "asc",
            width: 50,
          }, */

      /*  {
            label: "Remark",
            field: "Remark",
            sort: "asc",
            width: 50,
          }, */

      {
        label: "username",
        field: "username",
        sort: "asc",
        width: 50,
      },
    ],
    rows: wipChup_rowsData,
  };

  const [pack_header, set_pack_data] = useState([
    {
      label: "Product No.",
      field: "product_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Product Name",
      field: "product_name",
      sort: "asc",
      width: 50,
    },
    /* {
          label: "เลขที่ใบสั่งผลิต",
          field: "po_no",
          sort: "asc",
          width: 50,
        }, */
    {
      label: "สถานะ",
      field: "activity_type",
      sort: "asc",
      width: 50,
    },
    {
      label: "Lot No.",
      field: "lot_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Pack No.",
      field: "pack_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Date",
      field: "create_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "Amount(กก.)",
      field: "amount_weight",
      sort: "asc",
      width: 50,
    },
    {
      label: "Amount(ชิ้น)",
      field: "amount_price",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวนสูญหาย",
      field: "pack_loss",
      sort: "asc",
      width: 50,
    },
    /*  {
          label: "วันที่คาดรับงาน",
          field: "estimate_date",
          sort: "asc",
          width: 50,
        }, */
    /* {
          label: "วันที่รับ",
          field: "actual_date",
          sort: "asc",
          width: 50,
        }, */
    {
      label: "ชื่อย่อโรงชุบ.",
      field: "plant_code",
      sort: "asc",
      width: 50,
    },
    {
      label: "เบอร์โทรโรงชุบ",
      field: "plant_telnumber",
      sort: "asc",
      width: 50,
    },

    /* {
          label: "oem",
          field: "oem_name",
          sort: "asc",
          width: 50,
        }, */
    /* {
          label: "Customer Name",
          field: "customer_name",
          sort: "asc",
          width: 50,
        }, */

    /*  {
          label: "Remark",
          field: "Remark",
          sort: "asc",
          width: 50,
        }, */

    {
      label: "username",
      field: "username",
      sort: "asc",
      width: 50,
    },
  ]);
  const data_pack = {
    columns: [
      {
        label: "No.",
        field: "number",
        sort: "asc",
        width: 50,
      },

      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      /* {
            label: "เลขที่ใบสั่งผลิต",
            field: "po_no",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "สถานะ",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Pack No.",
        field: "pack_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(กก.)",
        field: "amount_weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount(ชิ้น)",
        field: "amount_price",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวนสูญหาย",
        field: "pack_loss",
        sort: "asc",
        width: 50,
      },
      /*  {
            label: "วันที่คาดรับงาน",
            field: "estimate_date",
            sort: "asc",
            width: 50,
          }, */
      /* {
            label: "วันที่รับ",
            field: "actual_date",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "ชื่อย่อโรงชุบ.",
        field: "plant_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรโรงชุบ",
        field: "plant_telnumber",
        sort: "asc",
        width: 50,
      },

      /* {
            label: "oem",
            field: "oem_name",
            sort: "asc",
            width: 50,
          }, */
      /* {
            label: "Customer Name",
            field: "customer_name",
            sort: "asc",
            width: 50,
          }, */

      /*  {
            label: "Remark",
            field: "Remark",
            sort: "asc",
            width: 50,
          }, */

      {
        label: "username",
        field: "username",
        sort: "asc",
        width: 50,
      },
    ],
    rows: pack_rowsData,
  };
  /* const data_product_fg = {
        columns: product_header_list_mat,
           
        rows: product_fg_rowsData,
    }; */
  // var check1 = data_product_fg.columns.filter((e1)=> {return e1.field === "oem_id"});
  // if(check1.length > 0){
  //   var index1 =  data_product_fg.columns.findIndex((e1)=>  e1.field === "oem_id");
  //   data_product_fg.columns.splice(index1,1);
  // }
  // var check2 = data_product_fg.columns.filter((e1)=> {return e1.field === "company_id"});
  // if(check2.length > 0){
  //   var index2 =  data_product_fg.columns.findIndex((e1)=>  e1.field === "company_id");
  //   data_product_fg.columns.splice(index2,1);
  // }
  // var check3 = data_product_fg.columns.filter((e1)=> {return e1.field === "check_date"});
  // if(check3.length > 0){
  //   var index3 =  data_product_fg.columns.findIndex((e1)=>  e1.field === "check_date");
  //   data_product_fg.columns.splice(index3,1);
  // }
  // var check3 = data_product_fg.columns.filter((e1)=> {return e1.field === "id"});
  // if(check3.length > 0){
  //   var index3 =  data_product_fg.columns.findIndex((e1)=>  e1.field === "id");
  //   data_product_fg.columns.splice(index3,1);
  // }
  // var check4 = data_product_fg.columns.filter((e1)=> {return e1.field === "stp_machine"});
  // if(check4.length > 0){
  //   var index4 =  data_product_fg.columns.findIndex((e1)=>  e1.field === "stp_machine");
  //   data_product_fg.columns.splice(index4,1);
  // }
  // var check5 = data_product_fg.columns.filter((e1)=> {return e1.field === "company"});
  // if(check5.length > 0){
  //   var index5 =  data_product_fg.columns.findIndex((e1)=>  e1.field === "company");
  //   data_product_fg.columns.splice(index5,1);
  // }

  // var check6 = data_product_fg.columns.filter((e1)=> {return e1.field === "company"});
  // if(check6.length > 0){
  //   var index6 =  data_product_fg.columns.findIndex((e1)=>  e1.field === "company");
  //   data_product_fg.columns.splice(index6,1);
  // }

  const wip_prod_rowsData = [];

  for (var index = 0; index < wip_prod.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = wip_prod[index].product_no;
    rowItem["product_name"] = wip_prod[index].product_no;
    if (wip_prod[index].edit_type === true) {
      var edit_type = "in";
    } else {
      var edit_type = "out";
    }
    rowItem["edit_type"] = edit_type;
    rowItem["lot_no"] = wip_prod[index].lot_no;
    rowItem["box_no"] = wip_prod[index].box_no;
    rowItem["ref_box_no"] = wip_prod[index].ref_box_no;

    rowItem["amountg"] =
      wip_prod[index].amount === null ? (
        "0"
      ) : (
        <NumberFormat
          thousandSeparator={true}
          value={parseFloat(wip_prod[index].amount).toFixed(2)}
          displayType="text"
        />
      );
    /*  parseFloat(wip_prod[index].amount)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    rowItem["amountp"] =
      wip_prod[index].amount === null ? (
        "0"
      ) : (
        <NumberFormat
          thousandSeparator={true}
          value={Math.floor(
            (
              (parseFloat(wip_prod[index].amount) /
                parseFloat(wip_prod[index].nw)) *
              1000
            ).toFixed(2)
          )}
          displayType="text"
        />
      ); /*  parseFloat(wip_prod[index].amount / wip_prod[index].nw)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    if (wip_prod[index].loss !== null) {
      rowItem["loss_amount"] = parseFloat(wip_prod[index].loss)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      rowItem["loss_amount"] = "0";
    }
    rowItem["full_name"] = wip_prod[index].full_name;
    rowItem["name_oem"] = wip_prod[index].name_oem;
    rowItem["create_date"] = wip_prod[index].create_date
      ? moment(wip_prod[index].create_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "-";
    rowItem["usename"] = wip_prod[index].usename;

    wip_prod_rowsData.push(rowItem);
  }

  const wip_prod_rowsData_ = [];

  for (var index = 0; index < wip_prod_.length; index++) {
    const rowItem = {};
    rowItem["number"] = index + 1;
    rowItem["product_no"] = wip_prod_[index].product_no;
    rowItem["product_name"] = wip_prod_[index].product_no;
    if (wip_prod_[index].edit_type === true) {
      var edit_type = "in";
    } else {
      var edit_type = "out";
    }
    rowItem["edit_type"] = edit_type;
    rowItem["lot_no"] = wip_prod_[index].lot_no;
    rowItem["box_no"] = wip_prod_[index].box_no;
    rowItem["ref_box_no"] = wip_prod_[index].ref_box_no;

    rowItem["amountg"] =
      wip_prod_[index].amount === null ? (
        "0"
      ) : (
        <NumberFormat
          thousandSeparator={true}
          value={parseFloat(wip_prod_[index].amount).toFixed(2)}
          displayType="text"
        />
      );
    /*  parseFloat(wip_prod_[index].amount)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    rowItem["amountp"] =
      wip_prod_[index].amount === null ? (
        "0"
      ) : (
        <NumberFormat
          thousandSeparator={true}
          value={Math.floor(
            (
              (parseFloat(wip_prod_[index].amount) /
                parseFloat(wip_prod_[index].nw)) *
              1000
            ).toFixed(2)
          )}
          displayType="text"
        />
      ); /*  parseFloat(wip_prod_[index].amount / wip_prod_[index].nw)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    if (wip_prod_[index].loss !== null) {
      rowItem["loss_amount"] = parseFloat(wip_prod_[index].loss)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      rowItem["loss_amount"] = "0";
    }
    rowItem["full_name"] = wip_prod_[index].full_name;
    rowItem["name_oem"] = wip_prod_[index].name_oem;
    rowItem["create_date"] = wip_prod_[index].create_date
      ? moment(wip_prod_[index].create_date).format("DD/MM/yyyy ::: HH:mm:ss")
      : "-";
    rowItem["usename"] = wip_prod_[index].usename;

    wip_prod_rowsData_.push(rowItem);
  }

  var wipthaedtabel = [];

  const [wipthaedtabel_, setwipthaedtabel_] = useState([
    {
      label: "product no",
      field: "product_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "product name",
      field: "product_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "สถานะ",
      field: "edit_type",
      sort: "asc",
      width: 50,
    },
    {
      label: "lot no",
      field: "lot_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "box no",
      field: "box_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "ref box no",
      field: "ref_box_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวน (กก.)",
      field: "amountg",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวน (ชิ้น.)",
      field: "amountp",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวนสูญหาย",
      field: "loss_amount",
      sort: "asc",
      width: 50,
    },
    {
      label: "Customer",
      field: "full_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "oem",
      field: "name_oem",
      sort: "asc",
      width: 50,
    },
    {
      label: "date",
      field: "create_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "username",
      field: "usename",
      sort: "asc",
      width: 50,
    }
  ]);

  wipthaedtabel.push(
    {
      label: "No.",
      field: "number",
      sort: "asc",
      width: 50,
    },
    {
      label: "product no",
      field: "product_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "product name",
      field: "product_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "สถานะ",
      field: "edit_type",
      sort: "asc",
      width: 50,
    },
    {
      label: "lot no",
      field: "lot_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "box no",
      field: "box_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "ref box no",
      field: "ref_box_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวน (กก.)",
      field: "amountg",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวน (ชิ้น.)",
      field: "amountp",
      sort: "asc",
      width: 50,
    },
    {
      label: "จำนวนสูญหาย",
      field: "loss_amount",
      sort: "asc",
      width: 50,
    },
    {
      label: "Customer",
      field: "full_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "oem",
      field: "name_oem",
      sort: "asc",
      width: 50,
    },
    {
      label: "date",
      field: "create_date",
      sort: "asc",
      width: 50,
    },
    {
      label: "username",
      field: "usename",
      sort: "asc",
      width: 50,
    }
  );

  const data_wip_prod = {
    columns: wipthaedtabel,

    rows: wip_prod_rowsData,
  };

  function ClearStage() {
    setFiller_material({
      ...filler_material,
      no: "",
      name: "",
      create_date: "",
      date_start: new Date(startdate),
      date_end: new Date(enddate),
      in: true,
      out: true,
      all_search: '',
    });
    filler_material.no = "";
    filler_material.name = "";
    filler_material.create_date = "";
    filler_material.all_search = "";
    filler_material.date_start = new Date(startdate);
    filler_material.date_end = new Date(enddate);
    setFiller_product_fg({
      ...filler_product_fg,
      no: "",
      name: "",
      create_date: "",
      project_name: "",
      date_start_fg: new Date(startdate),
      date_end_fg: new Date(enddate),
      in: true,
      out: true,
      all_search: '',
    });
    filler_product_fg.no = "";
    filler_product_fg.name = "";
    filler_product_fg.create_date = "";
    filler_product_fg.project_name = "";
    filler_product_fg.all_search = "";
    filler_product_fg.date_start_fg = new Date(startdate);
    filler_product_fg.date_end_fg = new Date(enddate);

    setFiller_wip_prod({
      ...filler_wip_prod,

      no: "",
      name: "",
      date_start_wp: new Date(startdate),
      date_end_wp: new Date(enddate),
      in: true,
      out: true,
      all_search: '',
    });

    filler_wip_prod.no = "";
    filler_wip_prod.name = "";
    filler_wip_prod.all_search = "";
    filler_wip_prod.date_start_wp = new Date(startdate);
    filler_wip_prod.date_end_wp = new Date(enddate);

    setFiller_wip_chup({
      ...filler_wip_chup,
      no: "",
      name: "",
      all_search: "",
      date_start_cp: new Date(startdate),
      date_end_cp: new Date(enddate),
      in: true,
      out: true,
    });
    filler_wip_chup.no = "";
    filler_wip_chup.name = "";
    filler_wip_chup.all_search = "";
    filler_wip_chup.date_start_cp = new Date(startdate);
    filler_wip_chup.date_end_cp = new Date(enddate);
    setFiller_pack({
      ...filler_pack,
      no: "",
      name: "",
      all_search: "",
      date_start_pk: new Date(startdate),
      date_end_pk: new Date(enddate),
      in: true,
      out: true,
    });
    filler_pack.no = "";
    filler_pack.name = "";
    filler_pack.all_search = "";
    filler_pack.date_start_pk = new Date(startdate);
    filler_pack.date_end_pk = new Date(enddate);

  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="snh" activemenu="history" submenu="none"/> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Warehouse History CTS{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse management
                  </li>
                  <li className="breadcrumb-item active">Warehouse History</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                {/* {feature.map((e) => {
                                    if (e.feature === "Material") {
                                        return (
                                            <> */}
                <li className="nav-item">
                  <a
                    className={
                      showActive === "Material"
                        ? " nav-link active"
                        : "nav-link"
                    }
                    id="custom-tabs-four-material-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-material"
                    role="tab"
                    aria-controls="custom-tabs-four-material"
                    aria-selected="false"
                    onClick={(e) => {
                      ClearStage()
                      GetMaterial();
                      GetMaterial_(1, 10, 'search');
                    }}
                  >
                    Material
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      showActive === "wip prod"
                        ? " nav-link active"
                        : "nav-link"
                    }
                    id="custom-tabs-four-wip_prod-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-wip_prod"
                    role="tab"
                    aria-controls="custom-tabs-four-wip_prod"
                    aria-selected="false"
                    onClick={(e) => {
                      ClearStage()
                      GetWipprod();
                      GetWipprod_(1, 10, 'search');
                    }}
                  >

                    Wip Prod
                  </a>
                </li>

                {/* </>
                                        );
                                    }
                                })} */}

                {/* {feature.map((e) => {
                                    if (e.feature === "Product FG") {
                                        return (
                                            <> */}

                {/* </>
                                        );
                                    }
                                })} */}
                <li className="nav-item">
                  <a
                    className={
                      showActive === "wip chup"
                        ? " nav-link active"
                        : "nav-link"
                    }
                    /*          className="nav-link" */
                    id="custom-tabs-four-wip_chup-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-wip_chup"
                    role="tab"
                    aria-controls="custom-tabs-four-wip_chup"
                    aria-selected="false"
                    onClick={() => {
                      ClearStage()
                      GetWipChup_(1, 10, 'search');
                      GetWipChup();
                    }}
                  >
                    Wip ส่ง/รับ ชุบ
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      showActive === "pack" ? " nav-link active" : "nav-link"
                    }
                    /*          className="nav-link" */
                    id="custom-tabs-four-pack-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-pack"
                    role="tab"
                    aria-controls="custom-tabs-four-pack"
                    aria-selected="false"
                    onClick={() => {
                      ClearStage()
                      GetLogInOutpack();
                      GetLogInOutpack_(1, 10, 'search');
                    }}
                  >
                    Pack
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      showActive === "Product FG"
                        ? " nav-link active"
                        : "nav-link"
                    }
                    /*          className="nav-link" */
                    id="custom-tabs-four-product_fg-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-product_fg"
                    role="tab"
                    aria-controls="custom-tabs-four-product_fg"
                    aria-selected="false"
                    onClick={() => {
                      ClearStage()
                      GetProduct_fg();
                      GetProduct_fg_(1, 10, 'search');
                    }}
                  >
                    Product Fg
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className={
                    showActive === "Material"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-material"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-material-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History CTS &gt; Material
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_material.all_search}
                            onChange={(e) => {
                              setFiller_material({
                                ...filler_material,
                                all_search: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Search</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_material.no}
                            onChange={(e) => {
                              setFiller_material({
                                ...filler_material,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Material No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_material.name}
                            onChange={(e) => {
                              setFiller_material({
                                ...filler_material,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Material Name.</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>&nbsp;&nbsp; วันที่</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_material.date_start}
                            className="form-control"
                            // //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            // onChange={async (date) => SetDate_start_fg(date)}
                            onChange={async (date) => {
                              setFiller_material({
                                ...filler_material,
                                date_start: date,
                              });
                            }}
                            selectsStart
                            startDate={filler_material.date_start}
                            endDate={filler_material.date_end}
                          />
                        </div>
                      </div>
                      {/*      <div> */}
                      <label>ถึง</label>
                      {/*     </div> */}
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_material.date_end}
                            className="form-control"
                            //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            onChange={async (date) => {
                              setFiller_material({
                                ...filler_material,
                                date_end: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_material.date_start}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_material.date_end}
                            minDate={filler_material.date_start}
                          />
                        </div>
                      </div>

                      <div className="mx-1"></div>
                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_material.in}
                          onChange={(e) => {
                            setFiller_material({
                              ...filler_material,
                              in: e.target.checked,
                            });
                            filler_material.in = e.target.checked;
                            GetMaterial();
                          }}
                        />
                        <label>Status-In</label>
                      </div>

                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_material.out}
                          onChange={(e) => {
                            setFiller_material({
                              ...filler_material,
                              out: e.target.checked,
                            });
                            filler_material.out = e.target.checked;
                            GetMaterial();
                          }}
                        />
                        <label>Status-Out</label>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetMaterial();
                              GetMaterial_(1, 10, 'search');
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              setFiller_material({
                                ...filler_material,
                                no: "",
                                name: "",
                                create_date: "",
                                date_start: new Date(startdate),
                                date_end: new Date(enddate),
                                in: true,
                                out: true,
                                all_search: '',
                              });
                              filler_material.no = "";
                              filler_material.name = "";
                              filler_material.create_date = "";
                              filler_material.all_search = "";
                              filler_material.date_start = new Date(startdate);
                              filler_material.date_end = new Date(enddate);
                              GetMaterial();
                              GetMaterial_(1, 10, 'search');
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          {/* <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_material"
                            filename={
                              "HistoryMat" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div

                      style={{ whiteSpace: "nowrap", padding: '2%' }}
                    /* style={{ height: "500px" }} */
                    >

                      <TableLEK
                        headTable={material_header}
                        dataTable={materialrowsData_}
                        countPage={countPage}
                        countData={countData}
                        entries={entries}
                        isSearch={search}
                        callFnc={GetMaterial_}


                      />
                      {/*  <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_material}
                      /> */}
                    </div>
                    <div style={{ display: "none" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        id="table-to-xls_material"
                        sortable={false}
                        bordered
                        paging={false}
                        hover
                        fixedHeader
                        data={data_material}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "Product FG"
                      ? "tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-product_fg"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-product_fg-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History CTS &gt; Product Fg
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_product_fg.all_search}
                            onChange={(e) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                all_search: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Search</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_product_fg.no}
                            onChange={(e) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_product_fg.name}
                            onChange={(e) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Name.</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>วันที่ </label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group">
                          <DatePicker
                            selected={filler_product_fg.date_start_fg}
                            className="form-control"
                            // //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            // onChange={async (date) => SetDate_start_fg(date)}
                            onChange={async (date) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                date_start_fg: date,
                              });
                            }}
                            selectsStart
                            startDate={filler_product_fg.date_start_fg}
                            endDate={filler_product_fg.date_end_fg}
                          />
                        </div>
                      </div>
                      <div>
                        <label>ถึง</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group">
                          <DatePicker
                            selected={filler_product_fg.date_end_fg}
                            className="form-control"
                            //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            onChange={async (date) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                date_end_fg: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_product_fg.date_start_fg}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_product_fg.date_end_fg}
                            minDate={filler_product_fg.date_start_fg}
                          />
                        </div>
                      </div>

                      <div className="mx-1"></div>
                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_product_fg.in}
                          onChange={(e) => {
                            setFiller_product_fg({
                              ...filler_product_fg,
                              in: e.target.checked,
                            });
                            filler_product_fg.in = e.target.checked;
                            GetProduct_fg_(1, 10, 'search');
                            GetProduct_fg();

                          }}
                        />
                        <label>Status-In</label>
                      </div>

                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_product_fg.out}
                          onChange={(e) => {
                            setFiller_product_fg({
                              ...filler_product_fg,
                              out: e.target.checked,
                            });
                            filler_product_fg.out = e.target.checked;
                            GetProduct_fg_(1, 10, 'search');
                            GetProduct_fg();
                          }}
                        />
                        <label>Status-Out</label>
                      </div>

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetProduct_fg_(1, 10, 'search');
                              GetProduct_fg();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                no: "",
                                name: "",
                                create_date: "",
                                project_name: "",
                                date_start_fg: new Date(startdate),
                                date_end_fg: new Date(enddate),
                                in: true,
                                out: true,
                                all_search: '',
                              });
                              filler_product_fg.no = "";
                              filler_product_fg.name = "";
                              filler_product_fg.create_date = "";
                              filler_product_fg.project_name = "";
                              filler_product_fg.all_search = "";
                              filler_product_fg.date_start_fg = new Date(startdate);
                              filler_product_fg.date_end_fg = new Date(enddate);
                              GetProduct_fg_(1, 10, 'search');
                              GetProduct_fg();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          {/*  <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_productFG"
                            filename={
                              "HistoryFG" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ padding: '2%' }}
                    /*  style={{ height: "500px" }} */
                    >
                      {/*  <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_product_fg}
                      /> */}
                      <TableLEK
                        headTable={fg_header}
                        dataTable={product_fg_rowsData_}
                        countPage={countPage}
                        countData={countData}
                        entries={entries}
                        isSearch={search}
                        callFnc={GetProduct_fg_}


                      />
                    </div>
                    <div
                      className="table-responsive"
                      style={{ display: "none" }}
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        entries={4096}
                        striped
                        sortable={false}
                        bordered
                        id="table-to-xls_productFG"
                        paging={false}
                        hover
                        fixedHeader
                        data={data_product_fg}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "wip prod"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-wip_prod"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-wip_prod-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History CTS &gt; Wip Prod
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_wip_prod.all_search}
                            onChange={(e) => {
                              setFiller_wip_prod({
                                ...filler_wip_prod,
                                all_search: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Search</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_wip_prod.no}
                            onChange={(e) => {
                              setFiller_wip_prod({
                                ...filler_wip_prod,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_wip_prod.name}
                            onChange={(e) => {
                              setFiller_wip_prod({
                                ...filler_wip_prod,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Name.</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>&nbsp;&nbsp; วันที่</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_wip_prod.date_start_wp}
                            className="form-control"
                            // //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            // onChange={async (date) => SetDate_start_fg(date)}
                            onChange={async (date) => {
                              setFiller_wip_prod({
                                ...filler_wip_prod,
                                date_start_wp: date,
                              });
                            }}
                            selectsStart
                            startDate={filler_wip_prod.date_start_wp}
                            endDate={filler_wip_prod.date_end_wp}
                          />
                        </div>
                      </div>
                      {/*      <div> */}
                      <label>ถึง</label>
                      {/*     </div> */}
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_wip_prod.date_end_wp}
                            className="form-control"
                            //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            onChange={async (date) => {
                              setFiller_wip_prod({
                                ...filler_wip_prod,
                                date_end_wp: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_wip_prod.date_start_wp}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_wip_prod.date_end_wp}
                            minDate={filler_wip_prod.date_start_wp}
                          />
                        </div>
                      </div>

                      {/* TODO: add checkbox too.  */}
                      <div className="mx-1"></div>

                      <div className="p-1 m-2 my-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_wip_prod.in}
                          onChange={(e) => {
                            setFiller_wip_prod({
                              ...filler_wip_prod,
                              in: e.target.checked,
                            });
                            filler_wip_prod.in = e.target.checked;
                            GetWipprod();
                            GetWipprod_(1, 10, 'search');
                          }}
                        />
                        <label>Status-In</label>
                      </div>

                      <div className="p-1 m-2 my-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_wip_prod.out}
                          onChange={(e) => {
                            setFiller_wip_prod({
                              ...filler_wip_prod,
                              out: e.target.checked,
                            });
                            filler_wip_prod.out = e.target.checked;
                            GetWipprod();
                            GetWipprod_(1, 10, 'search');
                          }}
                        />
                        <label>Status-Out</label>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetWipprod_(1, 10, 'search');
                              GetWipprod();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              setFiller_wip_prod({
                                ...filler_wip_prod,

                                no: "",
                                name: "",
                                date_start_wp: new Date(startdate),
                                date_end_wp: new Date(enddate),
                                in: true,
                                out: true,
                                all_search: '',
                              });

                              filler_wip_prod.no = "";
                              filler_wip_prod.name = "";
                              filler_wip_prod.all_search = "";
                              filler_wip_prod.date_start_wp = new Date(startdate);
                              filler_wip_prod.date_end_wp = new Date(enddate);
                              GetWipprod_(1, 10, 'search');
                              GetWipprod();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          {/* <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_wip_prod"
                            filename={
                              "HistoryWipProd" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div

                      style={{ whiteSpace: "nowrap" }}
                    /* style={{ height: "500px" }} */
                    >

                      <TableLEK
                        headTable={wipthaedtabel_}
                        dataTable={wip_prod_rowsData_}
                        countPage={countPage}
                        countData={countData}
                        entries={entries}
                        isSearch={search}
                        callFnc={GetWipprod_}


                      />
                      {/*  <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_wip_prod}
                      /> */}
                    </div>
                    <div style={{ display: "none" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        id="table-to-xls_wip_prod"
                        sortable={false}
                        bordered
                        paging={false}
                        hover
                        fixedHeader
                        data={data_wip_prod}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "wip chup"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-wip_chup"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-wip_chup-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>
                  {/* ตรงนี้ */}
                  <h3 class="title ng-binding">
                    Warehouse History CTS &gt; wip_chup
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_wip_chup.all_search}
                            onChange={(e) => {
                              setFiller_wip_chup({
                                ...filler_wip_chup,
                                all_search: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Search</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_wip_chup.no}
                            onChange={(e) => {
                              setFiller_wip_chup({
                                ...filler_wip_chup,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_wip_chup.name}
                            onChange={(e) => {
                              setFiller_wip_chup({
                                ...filler_wip_chup,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Name.</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>&nbsp;&nbsp; วันที่</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_wip_chup.date_start_cp}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setFiller_wip_chup({
                                ...filler_wip_chup,
                                date_start_cp: date,
                              });
                            }}
                            selectsStart
                            startDate={filler_wip_chup.date_start_cp}
                            endDate={filler_wip_chup.date_end_cp}
                          />
                        </div>
                      </div>
                      {/*      <div> */}
                      <label>ถึง</label>
                      {/*     </div> */}
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_wip_chup.date_end_cp}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setFiller_wip_chup({
                                ...filler_wip_chup,
                                date_end_cp: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_wip_chup.date_start_cp}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_wip_chup.date_end_cp}
                            minDate={filler_wip_chup.date_start_cp}
                          />
                        </div>
                      </div>

                      <div className="mx-1"></div>
                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_wip_chup.in}
                          onChange={(e) => {
                            setFiller_wip_chup({
                              ...filler_wip_chup,
                              in: e.target.checked,
                            });
                            filler_wip_chup.in = e.target.checked;
                            GetWipChup_(1, 10, 'search');
                            GetWipChup();

                          }}
                        />
                        <label>Status-In</label>
                      </div>

                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_wip_chup.out}
                          onChange={(e) => {
                            setFiller_wip_chup({
                              ...filler_wip_chup,
                              out: e.target.checked,
                            });
                            filler_wip_chup.out = e.target.checked;
                            GetWipChup_(1, 10, 'search');
                            GetWipChup();
                          }}
                        />
                        <label>Status-Out</label>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetWipChup_(1, 10, 'search');
                              GetWipChup();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              setFiller_wip_chup({
                                ...filler_wip_chup,
                                no: "",
                                name: "",
                                all_search: "",
                                date_start_cp: new Date(startdate),
                                date_end_cp: new Date(enddate),
                                in: true,
                                out: true,
                              });
                              filler_wip_chup.no = "";
                              filler_wip_chup.name = "";
                              filler_wip_chup.all_search = "";
                              filler_wip_chup.date_start_cp = new Date(startdate);
                              filler_wip_chup.date_end_cp = new Date(enddate);
                              GetWipChup_(1, 10, 'search');
                              GetWipChup();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          {/* <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_chup"
                            filename={
                              "HistoryWipChup" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div

                      style={{ whiteSpace: "nowrap" }}
                    /* style={{ height: "500px" }} */
                    >
                      {/* <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_wipChup}
                      /> */}
                      <TableLEK
                        headTable={wip_chup_header}
                        dataTable={wipChup_rowsData_}
                        countPage={countPage}
                        countData={countData}
                        entries={entries}
                        isSearch={search}
                        callFnc={GetWipChup_}


                      />
                    </div>
                    <div style={{ display: "none" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        entries={4096}
                        striped
                        id="table-to-xls_chup"
                        sortable={false}
                        bordered
                        paging={false}
                        hover
                        fixedHeader
                        data={data_wipChup}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    showActive === "pack"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-pack"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-pack-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History CTS &gt; Pack
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_pack.all_search}
                            onChange={(e) => {
                              setFiller_pack({
                                ...filler_pack,
                                all_search: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Search</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_pack.no}
                            onChange={(e) => {
                              setFiller_pack({
                                ...filler_pack,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_pack.name}
                            onChange={(e) => {
                              setFiller_pack({
                                ...filler_pack,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Name.</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>&nbsp;&nbsp; วันที่</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_pack.date_start_pk}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setFiller_pack({
                                ...filler_pack,
                                date_start_pk: date,
                              });
                            }}
                            selectsStart
                            startDate={filler_pack.date_start_pk}
                            endDate={filler_pack.date_end}
                          />
                        </div>
                      </div>
                      {/*      <div> */}
                      <label>ถึง</label>
                      {/*     </div> */}
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_pack.date_end_pk}
                            className="form-control"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setFiller_pack({
                                ...filler_pack,
                                date_end_pk: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_pack.date_start_pk}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_pack.date_end_pk}
                            minDate={filler_pack.date_start_pk}
                          />
                        </div>
                      </div>

                      <div className="mx-1"></div>
                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_pack.in}
                          onChange={(e) => {
                            setFiller_pack({
                              ...filler_pack,
                              in: e.target.checked,
                            });
                            filler_pack.in = e.target.checked;
                            GetLogInOutpack_(1, 10, 'search');
                            GetLogInOutpack();
                          }}
                        />
                        <label>Status-In</label>
                      </div>

                      <div className="p-1 m-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filler_pack.out}
                          onChange={(e) => {
                            setFiller_pack({
                              ...filler_pack,
                              out: e.target.checked,
                            });
                            filler_pack.out = e.target.checked;
                            GetLogInOutpack_(1, 10, 'search');
                            GetLogInOutpack();
                          }}
                        />
                        <label>Status-Out</label>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetLogInOutpack_(1, 10, 'search');
                              GetLogInOutpack();

                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              setFiller_pack({
                                ...filler_pack,
                                no: "",
                                name: "",
                                all_search: "",
                                date_start_pk: new Date(startdate),
                                date_end_pk: new Date(enddate),
                                in: true,
                                out: true,
                              });
                              filler_pack.no = "";
                              filler_pack.name = "";
                              filler_pack.all_search = "";
                              filler_pack.date_start_pk = new Date(startdate);
                              filler_pack.date_end_pk = new Date(enddate);
                              GetLogInOutpack_(1, 10, 'search');
                              GetLogInOutpack();
                            }}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          {/* <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_pack"
                            filename={
                              "HistoryPack" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div

                      style={{ whiteSpace: "nowrap", padding: '2%' }}
                    /* style={{ height: "500px" }} */
                    >
                      {/*   <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_pack}
                      /> */}
                      <TableLEK
                        headTable={pack_header}
                        dataTable={pack_rowsData_}
                        countPage={countPage}
                        countData={countData}
                        entries={entries}
                        isSearch={search}
                        callFnc={GetLogInOutpack_}


                      />
                    </div>
                    <div style={{ display: "none" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        entries={4096}
                        striped
                        id="table-to-xls_pack"
                        sortable={false}
                        bordered
                        paging={false}
                        hover
                        fixedHeader
                        data={data_pack}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* */}
            {/* {do_export_file.map((el) => {
                            return (
                                <>
                                    <tr>
                                        <td>{el.supplier_code}</td>

                                    </tr>

                                </>
                            )
                        })}
                        <table border='1'>
                            <tr>
                                <th>Company</th>
                                <th>Contact</th>
                                <th>Country</th>
                            </tr>
                            <tr>
                                <td>Alfreds Futterkiste</td>
                                <td>Maria Anders</td>
                                <td>Germany</td>
                            </tr>
                            <tr>
                                <td>Centro comercial Moctezuma</td>
                                <td>Francisco Chang</td>
                                <td>Mexico</td>
                            </tr>
                        </table> */}
          </div>

          {/* /.container-fluid */}
        </section>
        {/* Main content */}

        {/* /.content */}
      </div>

      {/*  <Footter/> */}
    </div>
  );
}
export default Warehouse_History_CTS;
