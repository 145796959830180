import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken } from "../../Utils/Common";
import moment from "moment";
function Warehouse_history(params) {
  const [cutting, setcutting] = useState([]);
  const [filler_cutting, setFiller_cutting] = useState({
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount:"",
    create_date:""
 });

 const [material, setmaterial] = useState([]);
 const [filler_material, setFiller_material] = useState({
   oem_id: getOem(),
   no: "",
   name: "",
   edit_type: "",
   amount:"",
   create_date:""
});

const [product_fg, setproduct_fg] = useState([]);
//------------no project
/* const [filler_product_fg, setFiller_product_fg] = useState({
  oem_id: getOem(),
  no: "",
  name: "",
  edit_type: "",
  amount:"",
  create_date:""
}); */


//-------------have project
const [filler_product_fg, setFiller_product_fg] = useState({
  oem_id: getOem(),
  no: "",
  name: "",
  edit_type: "",
  amount:"",
  create_date:"",
  project_name:"",

});

const [product_wip, setproduct_wip] = useState([]);
const [filler_product_wip, setFiller_product_wip] = useState({
  oem_id: getOem(),
  no: "",
  name: "",
  edit_type: "",
  amount:"",
  create_date:""
});

 const GetCutting = async () => {
 
  axios({
    method: "post",
    url: Configs.API_URL + "/history/cutting/filter",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filler_cutting
  
  })
    .then(function (response) {
      //console.log(response.data);
      setcutting(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const GetMaterial = async () => {
  axios({
    method: "post",
    url: Configs.API_URL + "/history/material/filter",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filler_material
  
  })
    .then(function (response) {
      //console.log(response.data);
      setmaterial(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

//--------------------มี project
const GetProduct_fg = async () => {
  axios({
    method: "post",
    url: Configs.API_URL + "/history/product_fg_project/filter",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filler_product_fg
  
  })
    .then(function (response) {
      //console.log(response.data);
      setproduct_fg(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

//-------------------------แบบไม่มี project
/* const GetProduct_fg = async () => {
  axios({
    method: "post",
    url: Configs.API_URL + "/history/product_fg/filter",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filler_product_fg
  
  })
    .then(function (response) {
      console.log(response.data);
      setproduct_fg(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}; */

const GetProduct_wip = async () => {
  axios({
    method: "post",
    url: Configs.API_URL + "/history/product_wip/filter",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filler_product_wip
  
  })
    .then(function (response) {
      //console.log(response.data);
      setproduct_wip(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

useEffect(() => {
  GetCutting();
  GetMaterial();
  GetProduct_fg();
  GetProduct_wip();
}, []);

const cuttingrowsData = [];
 
  for (var index = 0; index < cutting.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1; 
    rowItem["part_no"] = cutting[index].no;
    rowItem["part_name"] = cutting[index].name;
    if(cutting[index].edit_type == true){
      rowItem["activity_type"] = "in";
    }else if(cutting[index].edit_type == false){
      rowItem["activity_type"] = "out";
    }else{
      rowItem["activity_type"] = "";
    }
    rowItem["amount"] = cutting[index].amount;
    rowItem["date"] = moment(cutting[index].create_date).format('DD/MM/yyyy');

    cuttingrowsData.push(rowItem);
   
  }

  const materialrowsData = [];
 
  for (var index = 0; index < material.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1; 
    rowItem["part_no"] = material[index].no;
    rowItem["part_name"] = material[index].name;
    if(material[index].edit_type == true){
      rowItem["activity_type"] = "in";
    }else if(material[index].edit_type == false){
      rowItem["activity_type"] = "out";
    }else{
      rowItem["activity_type"] = "";
    }
    rowItem["amount"] = material[index].amount;
    rowItem["date"] = moment(material[index].create_date).format('DD/MM/yyyy');

    materialrowsData.push(rowItem);
   
  }

  const product_fg_rowsData = [];
 
  for (var index = 0; index < product_fg.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1; 
    rowItem["part_no"] = product_fg[index].no;
    rowItem["part_name"] = product_fg[index].name;
    if(product_fg[index].edit_type == true){
      rowItem["activity_type"] = "in";
    }else if(product_fg[index].edit_type == false){
      rowItem["activity_type"] = "out";
    }else{
      rowItem["activity_type"] = "";
    }
    rowItem["amount"] = product_fg[index].amount;
    rowItem["project_name"] = product_fg[index].project_name;
    rowItem["date"] = moment(product_fg[index].create_date).format('DD/MM/yyyy');

    product_fg_rowsData.push(rowItem);
   
  }

  const product_wip_rowsData = [];
 
  for (var index = 0; index < product_wip.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1; 
    rowItem["part_no"] = product_wip[index].no;
    rowItem["part_name"] = product_wip[index].name;
    if(product_wip[index].edit_type == true){
      rowItem["activity_type"] = "in";
    }else if(product_wip[index].edit_type == false){
      rowItem["activity_type"] = "out";
    }else{
      rowItem["activity_type"] = "";
    }
    rowItem["amount"] = product_wip[index].amount;
    rowItem["date"] = moment(product_wip[index].create_date).format('DD/MM/yyyy');

    product_wip_rowsData.push(rowItem);
   
  }

  const clearFilter = async () => {
   
    await setFiller_cutting({
      ...filler_cutting,
      no:"",
      name:"",
      create_date: "",
    });
    filler_cutting.no = "";
    filler_cutting.name = "";
    filler_cutting.create_date = "";
    GetCutting();

    await setFiller_material({
      ...filler_material,
      no:"",
      name:"",
      create_date: "",
    });
    filler_material.no = "";
    filler_material.name = "";
    filler_material.create_date = "";
    GetMaterial();

    await setFiller_product_fg({
      ...filler_product_fg,
      no:"",
      name:"",
      create_date: "",
      project_name:"",
    });
    filler_product_fg.no = "";
    filler_product_fg.name = "";
    filler_product_fg.create_date = "";
    filler_product_fg.project_name ="";
    GetProduct_fg();

    await setFiller_product_wip({
      ...filler_product_wip,
      no:"",
      name:"",
      create_date: "",
    });
    filler_product_wip.no = "";
    filler_product_wip.name = "";
    filler_product_wip.create_date = "";
    GetProduct_wip();
  };


  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail/add";
  }

 

  const data_cutting = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    
    ],
    rows: cuttingrowsData,
  };

  const data_material = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    
    ],
    rows: materialrowsData,
  };

  const data_product_fg = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Project",
        field: "project_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    
    ],
    rows: product_fg_rowsData,
  };

  const data_product_wip = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    
    ],
    rows: product_wip_rowsData,
  };

  return (
    <div className="wrapper">
      <Header />
      <Sidebar menu="snh" activemenu="history" submenu="none"/>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
        <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">       
              <a
              className= "nav-link active"
              id="custom-tabs-four-cutting-tab"
              data-toggle="pill"
              href="#custom-tabs-four-cutting"
              role="tab"
              aria-controls="custom-tabs-four-cutting"
              aria-selected="false"
            >
              
              Cutting
            </a>
                            
                </li>
                
                <li className="nav-item">
                  <a
                    className = "nav-link"
                    id="custom-tabs-four-material-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-material"
                    role="tab"
                    aria-controls="custom-tabs-four-material"
                    aria-selected="false"
                  >
                    Material
                  </a>

                </li>
                <li className="nav-item">
               
                  <a
                    className="nav-link"
                    id="custom-tabs-four-product_fg-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-product_fg"
                    role="tab"
                    aria-controls="custom-tabs-four-product_fg"
                    aria-selected="false"
                  >
                    Product_Fg
                  </a>
   
                </li>
                <li className="nav-item">
               
                  <a
                    className="nav-link"
                    id="custom-tabs-four-product_wip-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-product_wip"
                    role="tab"
                    aria-controls="custom-tabs-four-product_wip"
                    aria-selected="true"
                  >
                    Product_Wip
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
             
                   <div
          
                  className="tab-pane fade show active"
                  
                  id="custom-tabs-four-cutting"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-cutting-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>
              
                  <h3 class="title ng-binding">Warehouse History &gt; Cutting</h3>
                  <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_cutting.no}
                    onChange={(e) => {
                      setFiller_cutting({
                       ...filler_cutting,
                        no: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Part No.</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_cutting.name}
                    onChange={(e) => {
                      setFiller_cutting({
                        ...filler_cutting,
                        name: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Part Name.</label>{" "}
                </div>
              </div>
              {/*<div className="col-md-2">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_cutting.create_date}
                    onChange={(e) => {
                      setFiller_cutting({
                        ...filler_cutting,
                        create_date: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Date</label>{" "}
                </div>
                  </div>*/}
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      GetCutting();
                    }}
                  >
                       
                    Search
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
              </div>

              <div className="table-responsive" style={{ height: "500px" }}>
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data_cutting}
                />
              </div>
              </div>
                </div>

                <div
          className="tab-pane fade"
          
          id="custom-tabs-four-material"
          role="tabpanel"
          aria-labelledby="custom-tabs-four-material-tab"
        >
          <div className="row">
            <div className="col-4"></div>
          </div>
      
          <h3 class="title ng-binding">Warehouse History &gt; Material</h3>
          <div className="container-fluid">
    <div className="row mb-2">
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_material.no}
            onChange={(e) => {
              setFiller_material({
                ...filler_material,
               no: e.target.value,
              });
            }} 
          />
          <label htmlFor="">Part No.</label>{" "}
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_material.name}
            onChange={(e) => {
              setFiller_material({
                ...filler_material,
               name: e.target.value,
           });
            }} 
          />
          <label htmlFor="">Part Name.</label>{" "}
        </div>
      </div>
       {/*
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_material.create_date}
            onChange={(e) => {
              setFiller_material({
              ...filler_material,
                create_date: e.target.value,
              });
            }} 
          />
          <label htmlFor="">Date</label>{" "}
        </div>
      </div>
          */}
      <div className="col-2">
        <div className="form-group ">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              GetMaterial();
            }}
          >
               
            Search
          </button>
        </div>
      </div>
      <div className="col-2">
        <div className="form-group ">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={clearFilter}
          >
            Clear
          </button>
        </div>
      </div>
      </div>

      <div className="table-responsive" style={{ height: "500px" }}>
        <MDBDataTable
          className="table table-head-fixed"
          striped
          bordered
          hover
          fixedHeader
          data={data_material}
        />
      </div>
      </div>
        </div>

        
        <div
          className="tab-pane fade"
          
          id="custom-tabs-four-product_fg"
          role="tabpanel"
          aria-labelledby="custom-tabs-four-product_fg-tab"
        >
          <div className="row">
            <div className="col-4"></div>
          </div>
      
          <h3 class="title ng-binding">Warehouse History &gt; Product Fg</h3>
          <div className="container-fluid">
    <div className="row mb-2">
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_product_fg.no}
           onChange={(e) => {
              setFiller_product_fg({
                ...filler_product_fg,
                no: e.target.value,
             });
            }} 
          />
          <label htmlFor="">Part No.</label>{" "}
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_product_fg.name}
            onChange={(e) => {
              setFiller_product_fg({
                ...filler_product_fg,
                name: e.target.value,
             });
            }} 
          />
          <label htmlFor="">Part Name.</label>{" "}
        </div>
      </div>
      {/*
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_product_fg.create_date}
            onChange={(e) => {
              setFiller_product_fg({
                ...filler_product_fg,
                create_date: e.target.value,
              });
            }} 
          />
          <label htmlFor="">Date</label>{" "}
        </div>
      </div>
          */}
      <div className="col-2">
        <div className="form-group ">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              GetProduct_fg();
            }}
          >
               
            Search
          </button>
        </div>
      </div>
      <div className="col-2">
        <div className="form-group ">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={clearFilter}
          >
            Clear
          </button>
        </div>
      </div>
      </div>

      <div className="table-responsive" style={{ height: "500px" }}>
        <MDBDataTable
          className="table table-head-fixed"
          striped
          bordered
          hover
          fixedHeader
          data={data_product_fg}
        />
      </div>
      </div>
        </div>

        
        <div
          className="tab-pane fade"
          
          id="custom-tabs-four-product_wip"
          role="tabpanel"
          aria-labelledby="custom-tabs-four-product_wip-tab"
        >
          <div className="row">
            <div className="col-4"></div>
          </div>
      
          <h3 class="title ng-binding">Warehouse History &gt; Product Wip</h3>
          <div className="container-fluid">
    <div className="row mb-2">
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_product_wip.no}
            onChange={(e) => {
              setFiller_product_wip({
                ...filler_product_wip,
                no: e.target.value,
              });
            }} 
          />
          <label htmlFor="">Part No.</label>{" "}
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_product_wip.name}
            onChange={(e) => {
              setFiller_product_wip({
               ...filler_product_wip,
               name: e.target.value,
              });
            }} 
          />
          <label htmlFor="">Part Name.</label>{" "}
        </div>
      </div>
      {/*
      <div className="col-md-2">
        <div className="form-group ">
      
          <input
            type="text"
            className="form-control"
            required="false"    
            value={filler_product_wip.create_date}
            onChange={(e) => {
              setFiller_product_wip({
                ...filler_product_wip,
                create_date: e.target.value,
              });
            }} 
          />
          <label htmlFor="">Date</label>{" "}
        </div>
      </div>
          */}
      <div className="col-2">
        <div className="form-group ">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              GetProduct_wip();
            }}
          >
               
            Search
          </button>
        </div>
      </div>
      <div className="col-2">
        <div className="form-group ">
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={clearFilter}
          >
            Clear
          </button>
        </div>
      </div>
      </div>

      <div className="table-responsive" style={{ height: "500px" }}>
        <MDBDataTable
          className="table table-head-fixed"
          striped
          bordered
          hover
          fixedHeader
          data={data_product_wip}
        />
      </div>
      </div>
        </div>


                </div>
                </div>
            </div>
          
     
        
       
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        
        {/* /.content */}
      </div>

      <Footter/>
      </div>
  );
}
export default Warehouse_history;
