/**
* ชื่อไฟล์: viewMomnitor.js
* คําอธิบาย: ดูมอนิเตอร์แต่ละตัว
* Input: มอนิเตอร์ที่ต้องดู
* Output: ร่ายละเอียดของมอนิเตอร์ที่เลือก
* ชื่อผู้เขียน/แก้ไข: ณัฐวุฒิ สมดุลยกนก
* วันที่จัดทํา/แก้ไข: 14 กันยายน 2566
*/

import React, { useState, useEffect } from "react";
/* import Header from "../component/Header";
import Sidebar from "../component/Sidebar";
import Footter from "../component/Footter"; */
import "../../assets/css/IOT_Connect/Content.css";
import "../../assets/css/IOT_Connect/LandingPage.css";
import "../../assets/css/IOT_Connect/addMonitor.css";
// import Oem from "../component/OEM";
// import CustomerService from "../component/CustomerService";
import view from '../../assets/img/Dashboard/view.svg';
import { useLocation, useHistory } from 'react-router-dom';


import {
  getUser,
  getToken,
  setOemlist,
  getOem,
  getComConfig,
} from "../../Utils/Common";
import Configs from "../../config";
import axios from "axios";
/* import {
  Icon_factory,
  factory,
  userdefault_img,
  Icon_contact,
  Icon_Choose,
} from "../routes/imgRoute/imgUrl"; */
import Swal from "sweetalert2";
function ViewMonitor(params) {
  const routerLocation = useLocation();
  const rowData = routerLocation.state?.rowData || {};
  const [user, setUser] = useState(getUser());
  const [user_detail, setUser_detail] = useState({});
  const [company, setCompany] = useState({});
  const [oem, setOem] = useState([]);
  const [po_sign, setpo_sign] = useState(getComConfig());
  const [size, setSize] = useState({
    width: 1280,
    height: 720,
  });
  const {
    ims_monitor_id,
    ims_name,
    ims_detail,
    ims_location,
    ims_remark,
    frequency_api,
    ims_frequency_api,

  } = rowData;

  let hour = 0;
  let minute = 0;
  
  console.log("freq :" + ims_frequency_api);
  console.log("ID : " + ims_monitor_id);
  console.log("hour :" + hour);
  // console.log(rowData);

  var config_user = {
    method: "get",
    url: Configs.API_URL + "/company/findUserById?id=" + user.fup,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + user.com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_oem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + user.com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios(config_user)
      .then(function (response) {
        setUser_detail(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_com)
      .then(function (response) {
        const data = response.data;
        setCompany(data);
        setOem(data.oem);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    /*  axios(config_oem)
      .then(function (response) {
        console.log(oem)
        setOem(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
 */
    /* console.log(getOem()); */
  }, []);

  const handleClick = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        swalWithBootstrapButtons.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your imaginary file is safe :)',
          'error'
        )
      }
    })
  }

  function ViewMonitor() {
    const location = useLocation();
    const rowData = location.state?.rowData || {};
  }

  if(ims_frequency_api < 60) {
    hour = 0;
    minute = ims_frequency_api;
    console.log("frequecny < 60")
  }
  else if(ims_frequency_api >= 60) {
    hour = Math.floor(ims_frequency_api/60);
    minute = ims_frequency_api%60;
  }
  
  console.log("hour = " + hour)
  console.log("minute = " + minute)

  return (
    <div className="content-wrapper bg-[#FEFEFE]">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/Welcome">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/IoT_Master_Data/PowerMonitorConnection">Power Connection</a>
                </li>
                <li className="breadcrumb-item active">View Power Monitor</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div class="px-0 mx-auto max-w-7xl pb-5 sm:px-4 mx-bt">
            <div class="form-frame w-full px-8 pt-4 pb-2 mx-auto bg-white rounded-[10px] sm:rounded-lg sm:w-full md:w-8/12 lg:w-full xl:w-full sm:px-6">
              <div className="add-title-iot">
                <img src={view}/>
                <h1 class="title-add font-semibold text-center text-gray-900">View Power Monitor</h1>
              </div>
              
              <>
                <div className="row mx-3">
                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                maxlength="6"
                                value={ims_monitor_id}
                                // onChange={handleInputChangeID}
                                readOnly={true}
                                required
                            />
                            <label htmlFor="">
                            Monitor ID 
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mx-3">
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={ims_name}
                                    // onChange={handleInputChangeName}
                                    readOnly={true}
                                    required
                                />
                                <label htmlFor="">
                                Monitor Name
                                </label>
                            </div>
                        </div>
                </div>
                <div className="row mx-3">
                    <div className="col-12 col-md-8 col-xl-6">
                        <div className="form-group">
                            <textarea
                                style={{ fontSize: '1.3em' }}
                                className="form-control"
                                rows={2}
                                placeholder=""
                                value={ims_detail}
                                // onChange={handleInputChangeDetail}
                                readOnly={true}
                                required
                            />
                            <label htmlFor="">
                            Monitor Detail
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mx-3">
                    <div className="col-12 col-md-8 col-xl-6">
                        <div className="form-group">
                            <textarea
                                style={{ fontSize: '1.3em' }}
                                className="form-control"
                                rows={2}
                                placeholder=""
                                value={ims_location}
                                // onChange={handleInputChangeLocation}
                                readOnly={true}
                                required
                            />
                            <label htmlFor="">
                            Location
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mx-3">
                    <div className="col-12 col-md-8 col-xl-6">
                        <div className="form-group">
                            <textarea
                                style={{ fontSize: '1.3em' }}
                                className="form-control"
                                rows={2}
                                placeholder=""
                                value={ims_remark}
                                readOnly={true}
                                // onChange={handleInputChangeRemark}
                                required
                            />
                            <label htmlFor="">
                            Remark
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mx-4">
                    <label className="block" >
                        <div className="block mb-1  text-gray-700 font-normal">Frequency API <span>*</span></div>
                        <div className="time-selected flex items-center" style={{ display: "flex", flexDirection: "row"}}>
                            <input class="form-control freq" type="number" min="00" max="24" placeholder="0" required
                              value={hour} readOnly={true}
                            />
                            <div className="hour-freq ml-2 font-normal" style={{ display: "flex", alignItems: "flex-end"}}>hour</div>
                            <input class="form-control freq ml-2" type="number" min="00" max="60" placeholder="0" required
                              value={minute} readOnly={true} />
                            <div className="min-freq ml-2 font-normal" style={{ display: "flex", alignItems: "flex-end"}}>minute</div>
                        </div>
                    </label>
                </div>
            </>
              {/* <form class="mt-[25px] mb-2 space-y-4">
                <label class="block">
                  <div class="block mb-1 text-gray-700 font-normal">Monitor ID <span>*</span></div>
                  <div className="form-view">{ims_monitor_id}</div>
                </label>
                <label class="block">
                  <div class="block mb-1  text-gray-700 font-normal">Monitor Name<span>*</span></div>
                  <div className="form-view">{ims_name}</div>
                </label>
                <label class="block">
                  <div class="block mb-1  text-gray-700 font-normal">Monitor Detail</div>
                  <div className="form-view area">{ims_detail}</div>                </label>
                <label class="block">
                  <div class="block mb-1  text-gray-700 font-normal">Location <span>*</span></div>
                  <div className="form-view area">{ims_location}</div>                
                </label>
                <label class="block">
                  <div class="block mb-1  text-gray-700 font-normal">Remark</div>
                  <div className="form-view area ">{ims_remark}</div>
                </label>
                <label class="block">
                  <div class="block mb-1  text-gray-700 font-normal">Frequency API <span>*</span></div>
                    <div className="time-selected flex items-center">
                      <div className="form-view textarea">{hour}</div>                      
                      <div className="hour-freq ml-2 font-normal">hour</div>
                      <div className="form-view textarea ml-2">{minute}</div>
                      <div className="min-freq ml-2 font-normal">minute</div>
                    </div>
                </label>

                
              </form> */}
              {/* <div className="line"></div> */}
              <hr />
              <div className="bottom-phase-iot pr-4 pt-3">
              <input type="submit" className="form-button btn btn-danger cancel" value="Cancel" onClick={() => {
                window.location.href="/IoT_Master_Data/PowerMonitorConnection";
              }}/>
               {/*  <a  className="form-button cancel" >
                  Close
                </a> */}
              </div>
            </div>
            </div>
        </div>
      </section>
    </div>
  );
}
export default ViewMonitor;