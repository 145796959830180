import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Configs from "../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { useParams } from "react-router";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// import Modal_IO_AEV from "./Modal_IO_AEV";

function FG_IO_Location_AEV() {
  const [warehouseData, setWarehouseData] = useState({
    dropdown: [],
  });

  const [rackData, setRackData] = useState({
    allData: [],
    dropdown: [],
    dropdown_use: [],
  });

  const [positionData, setPositionData] = useState({
    allData: [],
    dropdown: [],
    dropdown_use: [],
  });

  const [productInWarehouse, setProductInWarehouse] = useState({
    product_all: [],
    product_warehouse: [],
    product_use: [],
    dropdown: [],
  });

  const [productLog, setProductLog] = useState({
    in_stock: [],
    in_lot: [],
  });

  const [unitData, setUnitData] = useState({
    dropdown: [],
  });

  const [tabData, setTabData] = useState({
    mode: "In",
    inData: [],
    outData: [],
    start: false,
    startMode: "In",
  });

  const [mainData, setMainData] = useState({
    warehouse_id: "",
    warehouse_name: "",
  });

  const [detailData, setDetailData] = useState({
    detail_id: "",
    product_id: "",
    product_no: "",
    product_name: "",
    current_amount: "",
    limit_amount: "",
    quantity: "",
    unit_id: "",
    unit_name: "",
    convert_unit: "",
    convert_quantity: "",
    rack_id: "",
    rack_name: "",
    position_id: "",
    position_name: "",
    out_list: [],
  });

  const [modalData, setModalData] = useState({
    is_open: false,
    mode: "Add",
  });

  const [refLightData, setRefLightData] = useState({
    temp_ref_id: "",
    count_temp: 0,
  });

  let checkInterval = null;

  const GetProduct = async () => {
    let temp = {
      no: null,
      name: null,
      product_model_id: null,
      oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response_data) => {
        setProductInWarehouse({
          ...productInWarehouse,
          product_all: response_data.data,
        });
        productInWarehouse.product_all = response_data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  async function GetUnitForDropDown(is_suggest) {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      product_id: detailData.product_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/unit/getUnitForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setUnitData({
          dropdown: response_data.data.data,
        });
        unitData.dropdown = response_data.data.data;

        if (is_suggest) {
          setDetailData({
            ...detailData,
            unit_id: response_data.data.data[0]?.value || "",
            unit_name: response_data.data.data[0]?.label || "",
            convert_unit: 1,
          });
          detailData.unit_id = response_data.data.data[0]?.value || "";
          detailData.unit_name = response_data.data.data[0]?.label || "";
          detailData.convert_unit = 1;
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetWarehouseForDropDown() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getWarehouseForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setWarehouseData({
          dropdown: response_data.data.data,
        });
        warehouseData.dropdown = response_data.data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetRackForDropDown(is_all) {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      allData: is_all || false,
      warehouse_id: mainData.warehouse_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getRackForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        const rack_id_list = [];

        if (is_all) {
          setRackData({
            ...rackData,
            allData: response_data.data.data,
          });
          rackData.allData = response_data.data.data;
        } else {
          const temp_list = JSON.parse(
            JSON.stringify(productInWarehouse.product_warehouse)
          );

          const product_list = temp_list.filter((item) => {
            return item.pp_product_id == detailData.product_id;
          });

          product_list.forEach((item) => {
            if (!rack_id_list.includes(item.rack_id)) {
              rack_id_list.push(item.rack_id);
            }
          });

          const rack_use = response_data.data.data.filter((item) => {
            return rack_id_list.includes(item.value);
          });

          setRackData({
            ...rackData,
            dropdown: response_data.data.data,
            dropdown_use: rack_use,
          });
          rackData.dropdown = response_data.data.data;
          rackData.dropdown_use = rack_use;
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetPositionForDropDown(is_all) {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      allData: is_all || false,
      rack_id: detailData.rack_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getPositionForDropDown`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        const position_id_list = [];

        if (is_all) {
          setPositionData({
            ...positionData,
            allData: response_data.data.data,
          });
          positionData.allData = response_data.data.data;
        } else {
          const temp_list = JSON.parse(
            JSON.stringify(productInWarehouse.product_warehouse)
          );

          const product_list = temp_list.filter((item) => {
            return item.pp_product_id == detailData.product_id;
          });

          product_list.forEach((item) => {
            if (!position_id_list.includes(item.rp_id)) {
              position_id_list.push(item.rp_id);
            }
          });

          const position_use = response_data.data.data.filter((item) => {
            return position_id_list.includes(item.value);
          });

          setPositionData({
            ...positionData,
            dropdown: response_data.data.data,
            dropdown_use: position_use,
          });
          positionData.dropdown = response_data.data.data;
          positionData.dropdown_use = position_use;
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetAllProductInWarehouse() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      warehouse_id: mainData.warehouse_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/warehouse/getAllProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        const product_id_list = [];

        response_data.data.data.forEach((item) => {
          if (!product_id_list.includes(item.pp_product_id)) {
            product_id_list.push(item.pp_product_id);
          }
        });

        const temp_list = JSON.parse(
          JSON.stringify(productInWarehouse.product_all)
        );

        const product_use = temp_list.filter((item) => {
          return product_id_list.includes(item.id);
        });

        const dropdown = product_use.map((item) => {
          return {
            label: item.no,
            value: item.id,
            hidden_value: item.name,
          };
        });

        setProductInWarehouse({
          ...productInWarehouse,
          product_warehouse: response_data.data.data,
          product_use: product_use,
          dropdown: dropdown,
        });
        productInWarehouse.product_warehouse = response_data.data.data;
        productInWarehouse.product_use = product_use;
        productInWarehouse.dropdown = dropdown;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetStockProductInWarehouse() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
      warehouse_id: mainData.warehouse_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getStockProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setProductLog({
          ...productLog,
          in_stock: response_data.data,
        });
        productLog.in_stock = response_data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function getLotProductInWarehouse() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
      warehouse_id: mainData.warehouse_id,
      product_id: detailData.product_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getLotProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setProductLog({
          ...productLog,
          in_lot: response_data.data.reverse(),
        });
        productLog.in_lot = response_data.data.reverse();
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function SaveLogProductInAIOI() {
    Swal.fire({
      title: "กำลังบันทึกข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
      warehouse_id: mainData.warehouse_id,
      dataIn: tabData.inData,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/saveLogProductInAIOI`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        Swal.fire({
          icon: "success",
          text: "เสร็จสิ้น",
          showConfirmButton: false,
          timer: 2000,
        }).then(async () => {
          if (refLightData.temp_ref_id) {
            await DeleteTemp();
            await CloseLight();
          }
          window.location.reload();
        });
      })
      .catch(async (error) => {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error").then(
          async () => {
            if (refLightData.temp_ref_id) {
              await DeleteTemp();
              await CloseLight();
            }
            window.location.reload();
          }
        );
      });
  }

  async function SaveLogProductOutAIOI() {
    Swal.fire({
      title: "กำลังบันทึกข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
      warehouse_id: mainData.warehouse_id,
      dataOut: tabData.outData,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/saveLogProductOutAIOI`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        Swal.fire({
          icon: "success",
          text: "เสร็จสิ้น",
          showConfirmButton: false,
          timer: 2000,
        }).then(async () => {
          if (refLightData.temp_ref_id) {
            await DeleteTemp();
            await CloseLight();
          }
          window.location.reload();
        });
      })
      .catch(async (error) => {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error").then(
          async () => {
            if (refLightData.temp_ref_id) {
              await DeleteTemp();
              await CloseLight();
            }
            window.location.reload();
          }
        );
      });
  }

  async function OpenInLight() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      warehouse_id: mainData.warehouse_id,
      dataIn: tabData.inData,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/logProduct/openInLight`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setTabData({
          ...tabData,
          start: true,
          startMode: "In",
          outData: [],
        });
        tabData.start = true;
        tabData.startMode = "In";
        tabData.outData = [];

        setRefLightData({
          temp_ref_id: response_data.data.data.temp_ref_id,
          count_temp: response_data.data.data.count_temp,
        });
        refLightData.temp_ref_id = response_data.data.data.temp_ref_id;
        refLightData.count_temp = response_data.data.data.count_temp;

        if (response_data.data.data.temp_ref_id && response_data.data.data.is_open) {
          checkInterval = setInterval(async () => {
            const check_data = {
              temp_ref_id: refLightData.temp_ref_id,
              temp_status: true,
            };

            await axios({
              method: "POST",
              url: `${Configs.API_URL_AIOI_Back}/api/temp_item/getTempData`,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: check_data,
            })
              .then(async (response_data) => {
                if (response_data.data.data.length == refLightData.count_temp) {
                  clearInterval(checkInterval);
                  await SaveLogProductInAIOI();
                }
              })
              .catch(async (error) => {
                console.log(error);
              });
          }, 1000);
        }
      })
      .catch(async (error) => {
        console.log(error);
        Swal.fire("Error", error.response.data.error.message, "error").then(
          async () => {
            // window.location.reload();
          }
        );
        await DeleteTemp();
        await CloseLight();
      });
  }

  async function OpenOutLight() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      warehouse_id: mainData.warehouse_id,
      dataOut: tabData.outData,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/logProduct/openOutLight`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setTabData({
          ...tabData,
          start: true,
          startMode: "Out",
          inData: [],
        });
        tabData.start = true;
        tabData.startMode = "Out";
        tabData.inData = [];

        setRefLightData({
          temp_ref_id: response_data.data.data.temp_ref_id,
          count_temp: response_data.data.data.count_temp,
        });
        refLightData.temp_ref_id = response_data.data.data.temp_ref_id;
        refLightData.count_temp = response_data.data.data.count_temp;

        if (response_data.data.data.temp_ref_id && response_data.data.data.is_open) {
          checkInterval = setInterval(async () => {
            const check_data = {
              temp_ref_id: refLightData.temp_ref_id,
              temp_status: true,
            };

            await axios({
              method: "POST",
              url: `${Configs.API_URL_AIOI_Back}/api/temp_item/getTempData`,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: check_data,
            })
              .then(async (response_data) => {
                if (response_data.data.data.length == refLightData.count_temp) {
                  clearInterval(checkInterval);
                  await SaveLogProductOutAIOI();
                }
              })
              .catch(async (error) => {
                console.log(error);
              });
          }, 1000);
        }
      })
      .catch(async (error) => {
        console.log(error);
        Swal.fire("Error", error.response.data.error.message, "error").then(
          async () => {
            // window.location.reload();
          }
        );
        await DeleteTemp();
        await CloseLight();
      });
  }

  async function CloseLight() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      warehouse_id: mainData.warehouse_id,
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/logProduct/closeLight`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {})
      .catch(async (error) => {
        console.log(error);
        // Swal.fire("Error", error.response.data.error.message, "error").then(
        //   () => {
        //     // window.location.reload();
        //   }
        // );
      });
  }

  async function DeleteTemp() {
    await axios({
      method: "POST",
      url: `${Configs.API_URL_AIOI_Back}/api/temp_item/deleteTemp`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: {
        temp_ref_id: refLightData.temp_ref_id,
      },
    })
      .then(() => {})
      .catch(async (error) => {
        console.log(error);
        // Swal.fire("Error", error.response.data.error.message, "error").then(
        //   () => {
        //     // window.location.reload();
        //   }
        // );
      });
  }

  async function StartAddModal() {
    setDetailData({
      ...detailData,
      detail_id: "",
      product_id: "",
      product_no: "",
      product_name: "",
      current_amount: "",
      limit_amount: "",
      quantity: "",
      unit_id: "",
      unit_name: "",
      convert_unit: "",
      convert_quantity: "",
      rack_id: "",
      rack_name: "",
      position_id: "",
      position_name: "",
      out_list: [],
    });
    detailData.detail_id = "";
    detailData.product_id = "";
    detailData.product_no = "";
    detailData.product_name = "";
    detailData.current_amount = "";
    detailData.limit_amount = "";
    detailData.quantity = "";
    detailData.unit_id = "";
    detailData.unit_name = "";
    detailData.convert_unit = "";
    detailData.convert_quantity = "";
    detailData.rack_id = "";
    detailData.rack_name = "";
    detailData.position_id = "";
    detailData.position_name = "";
    detailData.out_list = [];

    setUnitData({
      ...unitData,
      dropdown: [],
    });
    unitData.dropdown = [];

    setRackData({
      ...rackData,
      dropdown: [],
      dropdown_use: [],
    });
    rackData.dropdown = [];
    rackData.dropdown_use = [];

    setPositionData({
      ...positionData,
      dropdown: [],
      dropdown_use: [],
    });
    positionData.dropdown = [];
    positionData.dropdown_use = [];

    let tab_product_id_list = [];

    if (tabData.mode == "In") {
      tab_product_id_list = tabData.inData.map((item) => {
        return item.product_id;
      });
    } else {
      tab_product_id_list = tabData.outData.map((item) => {
        return item.product_id;
      });
    }

    const tab_product_list = productInWarehouse.product_use.filter((item) => {
      return !tab_product_id_list.includes(item.id);
    });

    const dropdown = tab_product_list.map((item) => {
      return {
        label: item.no,
        value: item.id,
        hidden_value: item.name,
      };
    });

    setProductInWarehouse({
      ...productInWarehouse,
      dropdown: dropdown,
    });
    productInWarehouse.dropdown = dropdown;
  }

  async function StartEditModal(index) {
    let temp_list = [];

    if (tabData.mode == "In") {
      temp_list = JSON.parse(JSON.stringify(tabData.inData));
    } else {
      temp_list = JSON.parse(JSON.stringify(tabData.outData));
    }

    setDetailData({
      ...detailData,
      detail_id: temp_list[index].detail_id,
      product_id: temp_list[index].product_id,
      product_no: temp_list[index].product_no,
      product_name: temp_list[index].product_name,
      current_amount: temp_list[index].current_amount,
      limit_amount: temp_list[index].limit_amount,
      quantity: temp_list[index].quantity,
      unit_id: temp_list[index].unit_id,
      unit_name: temp_list[index].unit_name,
      convert_unit: temp_list[index].convert_unit,
      convert_quantity: temp_list[index].convert_quantity,
      rack_id: temp_list[index].rack_id,
      rack_name: temp_list[index].rack_name,
      position_id: temp_list[index].position_id,
      position_name: temp_list[index].position_name,
      out_list: temp_list[index].out_list,
    });
    detailData.detail_id = temp_list[index].detail_id;
    detailData.product_id = temp_list[index].product_id;
    detailData.product_no = temp_list[index].product_no;
    detailData.product_name = temp_list[index].product_name;
    detailData.current_amount = temp_list[index].current_amount;
    detailData.limit_amount = temp_list[index].limit_amount;
    detailData.quantity = temp_list[index].quantity;
    detailData.unit_id = temp_list[index].unit_id;
    detailData.unit_name = temp_list[index].unit_name;
    detailData.convert_unit = temp_list[index].convert_unit;
    detailData.convert_quantity = temp_list[index].convert_quantity;
    detailData.rack_id = temp_list[index].rack_id;
    detailData.rack_name = temp_list[index].rack_name;
    detailData.position_id = temp_list[index].position_id;
    detailData.position_name = temp_list[index].position_name;
    detailData.out_list = temp_list[index].out_list;

    await GetUnitForDropDown(false);

    let tab_product_id_list = [];
    let tab_product_list = [];

    if (tabData.mode == "In") {
      await GetRackForDropDown(false);
      await GetPositionForDropDown(false);

      tab_product_id_list = tabData.inData.map((item) => {
        return item.product_id;
      });

      tab_product_list = productInWarehouse.product_use.filter((item) => {
        return (
          !tab_product_id_list.includes(item.id) ||
          item.id == tabData.inData[index].product_id
        );
      });
    } else {
      await getLotProductInWarehouse();

      tab_product_id_list = tabData.outData.map((item) => {
        return item.product_id;
      });

      tab_product_list = productInWarehouse.product_use.filter((item) => {
        return (
          !tab_product_id_list.includes(item.id) ||
          item.id == tabData.outData[index].product_id
        );
      });
    }

    const dropdown = tab_product_list.map((item) => {
      return {
        label: item.no,
        value: item.id,
        hidden_value: item.name,
      };
    });

    setProductInWarehouse({
      ...productInWarehouse,
      dropdown: dropdown,
    });
    productInWarehouse.dropdown = dropdown;
  }

  function SetInTableData(index) {
    const rowItem = {};

    rowItem["product_no"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.inData[index].product_no}
      </span>
    );
    rowItem["product_no_hide"] = tabData.inData[index].product_no;

    rowItem["product_name"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.inData[index].product_name}
      </span>
    );
    rowItem["product_name_hide"] = tabData.inData[index].product_name;

    rowItem["current_amount"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.inData[index].current_amount +
          " / " +
          tabData.inData[index].limit_amount}
      </span>
    );
    rowItem["current_amount_hide"] =
      tabData.inData[index].current_amount +
      " / " +
      tabData.inData[index].limit_amount;

    rowItem["quantity"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.inData[index].convert_quantity}
      </span>
    );
    rowItem["quantity_hide"] = tabData.inData[index].convert_quantity;

    rowItem["rack"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.inData[index].rack_name}
      </span>
    );
    rowItem["rack_hide"] = tabData.inData[index].rack_name;

    rowItem["position"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.inData[index].position_name}
      </span>
    );
    rowItem["position_hide"] = tabData.inData[index].position_name;

    rowItem["management"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap", margin: "0 auto" }}
      >
        <div className="col-6">
          <a
            key={`edit_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              await StartEditModal(index);

              setModalData({
                ...modalData,
                is_open: true,
                mode: "Edit",
              });
              modalData.is_open = true;
              modalData.mode = "Edit";
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div className="col-6">
          <a
            key={`delete_${index}`}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={async () => {
              const temp_list = JSON.parse(JSON.stringify(tabData.inData));

              const index_detail = temp_list.findIndex((item) => {
                return item.detail_id == tabData.inData[index].detail_id;
              });

              temp_list.splice(index_detail, 1);

              setTabData({
                ...tabData,
                inData: temp_list,
              });
              tabData.inData = temp_list;
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    return rowItem;
  }

  function SetOutTableData(index) {
    const rowItem = {};

    rowItem["product_no"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.outData[index].product_no}
      </span>
    );
    rowItem["product_no_hide"] = tabData.outData[index].product_no;

    rowItem["product_name"] = (
      <span style={{ whiteSpace: "nowrap" }}>
        {tabData.outData[index].product_name}
      </span>
    );
    rowItem["product_name_hide"] = tabData.outData[index].product_name;

    const mergeData = Object.values(
      tabData.outData[index].out_list.reduce((pre, cur) => {
        if (!pre[cur.out_rack_id && cur.out_position_id]) {
          pre[cur.out_rack_id && cur.out_position_id] = {
            ...cur,
          };
        } else {
          pre[cur.out_rack_id && cur.out_position_id].out_quantity =
            Number(pre[cur.out_rack_id && cur.out_position_id].out_quantity) +
            Number(cur.out_quantity);
        }
        return pre;
      }, {})
    );

    let current_amount = "";
    let quantity = "";
    let rack = "";
    let position = "";

    for (const item of mergeData) {
      current_amount += `${item.out_current_amount} / ${item.out_limit_amount} \n`;
      quantity += `${item.out_quantity} \n`;
      rack += `${item.out_rack_name} \n`;
      position += `${item.out_position_name} \n`;
    }

    rowItem["current_amount"] = (
      <span style={{ whiteSpace: "preserve nowrap" }}>{current_amount}</span>
    );
    rowItem["current_amount_hide"] = current_amount;

    rowItem["quantity"] = (
      <span style={{ whiteSpace: "preserve nowrap" }}>{quantity}</span>
    );
    rowItem["quantity_hide"] = quantity;

    rowItem["rack"] = (
      <span style={{ whiteSpace: "preserve nowrap" }}>{rack}</span>
    );
    rowItem["rack_hide"] = rack;

    rowItem["position"] = (
      <span style={{ whiteSpace: "preserve nowrap" }}>{position}</span>
    );
    rowItem["position_hide"] = position;

    rowItem["management"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap", margin: "0 auto" }}
      >
        <div className="col-6">
          <a
            key={`edit_${index}`}
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              await StartEditModal(index);

              setModalData({
                ...modalData,
                is_open: true,
                mode: "Edit",
              });
              modalData.is_open = true;
              modalData.mode = "Edit";
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div className="col-6">
          <a
            key={`delete_${index}`}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={async () => {
              const temp_list = JSON.parse(JSON.stringify(tabData.outData));

              const index_detail = temp_list.findIndex((item) => {
                return item.detail_id == tabData.outData[index].detail_id;
              });

              temp_list.splice(index_detail, 1);

              setTabData({
                ...tabData,
                outData: temp_list,
              });
              tabData.outData = temp_list;
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    return rowItem;
  }

  const InTableData = [];
  for (let index = 0; index < tabData.inData.length; index += 1) {
    InTableData.push(SetInTableData(index));
  }

  const OutTableData = [];
  for (let index = 0; index < tabData.outData.length; index += 1) {
    OutTableData.push(SetOutTableData(index));
  }

  const InOutTable = {
    columns: [
      {
        label: <div style={{ whiteSpace: "nowrap" }}>Product No</div>,
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ whiteSpace: "nowrap" }}>Product Name</div>,
        field: "product_name",
        sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ whiteSpace: "nowrap" }}>Current Amount</div>,
        field: "current_amount",
        sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ whiteSpace: "nowrap" }}>Quantity</div>,
        field: "quantity",
        sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ whiteSpace: "nowrap" }}>Rack</div>,
        field: "rack",
        sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ whiteSpace: "nowrap" }}>Position</div>,
        field: "position",
        sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ whiteSpace: "nowrap" }}>Management</div>,
        field: "management",
        sort: "asc",
        width: 50,
      },
    ],

    rows: tabData.mode == "In" ? InTableData : OutTableData,
  };

  if (tabData.start) {
    const index = InOutTable.columns.findIndex((e) => {
      return e.field === "management";
    });
    InOutTable.columns.splice(index, 1);
  }

  useEffect(async () => {
    await GetProduct();
    await GetWarehouseForDropDown();
    await GetRackForDropDown(true);
    await GetPositionForDropDown(true);
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-sm-4 col-xl-6">
                <h1>F/G In/Out With Location</h1>
              </div>
              <div className="col-12 col-sm-8 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">IoT Master Data</li>
                  <li className="breadcrumb-item active">
                    F/G In/Out With Location
                  </li>
                  <li className="breadcrumb-item active">In/OUt</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-danger"
                    disabled={tabData.start}
                    onClick={async () => {
                      Swal.fire({
                        title: "คุณมั่นใจว่าจะออกจากหน้านี้ใช่หรือไม่",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: `ใช่`,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: `ไม่`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.href =
                            "/IoT_Master_Data/FG_InOut_With_Location_aioi";
                        }
                      });
                    }}
                  >
                    Leave Page
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-3">
                <div class="form-group" style={{ "z-index": "98" }}>
                  <Select
                    isDisabled={tabData.start}
                    options={warehouseData.dropdown}
                    value={warehouseData.dropdown.filter((e) => {
                      return e.value == mainData.warehouse_id;
                    })}
                    onChange={async (e) => {
                      if (e !== null) {
                        setMainData({
                          ...mainData,
                          warehouse_id: e.value,
                          warehouse_name: e.label,
                        });
                        mainData.warehouse_id = e.value;
                        mainData.warehouse_name = e.label;

                        setTabData({
                          ...tabData,
                          inData: [],
                          outData: [],
                        });
                        tabData.inData = [];
                        tabData.outData = [];

                        await GetAllProductInWarehouse();
                        await GetStockProductInWarehouse();
                      }
                    }}
                  />
                  <label>
                    Warehouse <span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li
                  className="nav-item"
                  style={{
                    minWidth: "150px",
                    textAlign: "center",
                  }}
                >
                  <a
                    className="nav-link active"
                    id="FG-In-With-Location"
                    data-toggle="pill"
                    href="#FG-In-With-Location"
                    role="tab"
                    aria-controls="FG-In-With-Location"
                    aria-selected="true"
                    onClick={async () => {
                      setTabData({
                        ...tabData,
                        mode: "In",
                      });
                    }}
                  >
                    In
                  </a>
                </li>

                <li
                  className="nav-item"
                  style={{
                    minWidth: "150px",
                    textAlign: "center",
                  }}
                >
                  <a
                    className="nav-link"
                    id="FG-Out-With-Location"
                    data-toggle="pill"
                    href="#FG-Out-With-Location"
                    role="tab"
                    aria-controls="FG-Out-With-Location"
                    aria-selected="true"
                    onClick={async () => {
                      setTabData({
                        ...tabData,
                        mode: "Out",
                      });
                    }}
                  >
                    Out
                  </a>
                </li>
              </ul>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-12">
                  <div className="row">
                    <div className="col-6 col-md-4 col-xl-3">
                      <div className="form-group ">
                        <button
                          type="button"
                          className="btn btn-block btn-primary"
                          disabled={
                            mainData.warehouse_id == "" || tabData.start
                          }
                          onClick={async () => {
                            await StartAddModal();

                            setModalData({
                              ...modalData,
                              is_open: true,
                              mode: "Add",
                            });
                            modalData.is_open = true;
                            modalData.mode = "Add";
                          }}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>

                    {tabData.mode === "In" ? (
                      <>
                        <div
                          className="col-6 col-md-4 col-xl-3"
                          hidden={tabData.startMode == "In" && tabData.start}
                        >
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-primary"
                              disabled={
                                tabData.inData.length == 0 ||
                                (tabData.startMode == "Out" && tabData.start)
                              }
                              onClick={async () => {
                                await OpenInLight();
                              }}
                            >
                              Put Away
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="col-6 col-md-4 col-xl-3"
                          hidden={tabData.startMode == "Out" && tabData.start}
                        >
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-primary"
                              disabled={
                                tabData.outData.length == 0 ||
                                (tabData.startMode == "In" && tabData.start)
                              }
                              onClick={async () => {
                                await OpenOutLight();
                              }}
                            >
                              Pick Away
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {tabData.start && tabData.startMode == tabData.mode ? (
                      <>
                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-primary"
                              onClick={async () => {
                                Swal.fire({
                                  title: "คุณมั่นใจว่าเสร็จสิ้นแล้วใช่หรือไม่",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: `ใช่`,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  cancelButtonText: `ไม่`,
                                }).then(async (result) => {
                                  if (result.isConfirmed) {
                                    if (tabData.startMode == "In") {
                                      await SaveLogProductInAIOI();
                                    } else {
                                      await SaveLogProductOutAIOI();
                                    }
                                  }
                                });
                              }}
                            >
                              เสร็จสิ้น
                            </button>
                          </div>
                        </div>

                        <div className="col-6 col-md-4 col-xl-3">
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-danger"
                              onClick={async () => {
                                Swal.fire({
                                  title: "คุณมั่นใจว่าจะยกเลิกใช่หรือไม่",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: `ใช่`,
                                  confirmButtonColor: "#3085d6",
                                  cancelButtonColor: "#d33",
                                  cancelButtonText: `ไม่`,
                                }).then(async (result) => {
                                  if (result.isConfirmed) {
                                    if (refLightData.temp_ref_id) {
                                      await DeleteTemp();
                                      await CloseLight();
                                    }
                                    window.location.reload();
                                  }
                                });
                              }}
                            >
                              ยกเลิก
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-12">
                      <div className="table-responsive">
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed"
                          striped
                          bordered
                          hover
                          fixedHeader
                          disableRetreatAfterSorting={true}
                          data={InOutTable}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-5"></div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          size="sm"
          className="Modal_Center_LeftRight"
          isOpen={modalData.is_open}
          toggle={async () => {
            Swal.fire({
              title: "คุณมั่นใจว่าจะปิดหน้าต่างนี้ใช่หรือไม่",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: `ใช่`,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: `ไม่`,
            }).then((result) => {
              if (result.isConfirmed) {
                setModalData({
                  ...modalData,
                  is_open: false,
                });
                modalData.is_open = false;
              }
            });
          }}
        >
          <ModalHeader
            toggle={async () => {
              Swal.fire({
                title: "คุณมั่นใจว่าจะปิดหน้าต่างนี้ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
              }).then((result) => {
                if (result.isConfirmed) {
                  setModalData({
                    ...modalData,
                    is_open: false,
                  });
                  modalData.is_open = false;
                }
              });
            }}
          >
            <h4 className="modal-title" id="modalDelivery">
              <b>
                {modalData.mode} {tabData.mode} Item
              </b>
            </h4>
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    options={productInWarehouse.dropdown}
                    value={productInWarehouse.dropdown.filter((e) => {
                      return e.value == detailData.product_id;
                    })}
                    onChange={async (e) => {
                      if (e !== null) {
                        if (tabData.mode == "In") {
                          const temp_list = JSON.parse(
                            JSON.stringify(productInWarehouse.product_warehouse)
                          );

                          const temp_data = temp_list.filter((item) => {
                            return item.pp_product_id == e.value;
                          });

                          let product_data = {};
                          let current_amount = 0;

                          for (const item of temp_data) {
                            const check_data = productLog.in_stock.find(
                              (itemf) => {
                                return (
                                  item.rack_id == itemf.lp_rack_id &&
                                  item.rp_id == itemf.lp_rp_id
                                );
                              }
                            );

                            if (check_data) {
                              if (
                                Number(item.pp_max_capacity) >
                                Number(check_data.lp_amount || 0)
                              ) {
                                current_amount = Number(check_data.lp_amount);
                                product_data = item;
                                break;
                              }
                            } else {
                              product_data = item;
                              break;
                            }
                          }

                          setDetailData({
                            ...detailData,
                            product_id: e.value,
                            product_no: e.label,
                            product_name: e.hidden_value,
                            current_amount: current_amount,
                            limit_amount: product_data?.pp_max_capacity || "",
                            quantity: "",
                            convert_unit: "",
                            convert_quantity: "",
                            rack_id: product_data?.rack_id || "",
                            rack_name: product_data?.rack_name || "",
                            position_id: product_data?.rp_id || "",
                            position_name: product_data?.rp_position || "",
                          });
                          detailData.product_id = e.value;
                          detailData.product_no = e.label;
                          detailData.product_name = e.hidden_value;
                          detailData.current_amount = current_amount;
                          detailData.limit_amount =
                            product_data?.pp_max_capacity || "";
                          detailData.quantity = "";
                          detailData.convert_unit = "";
                          detailData.convert_quantity = "";
                          detailData.rack_id = product_data?.rack_id || "";
                          detailData.rack_name = product_data?.rack_name || "";
                          detailData.position_id = product_data?.rp_id || "";
                          detailData.position_name =
                            product_data?.rp_position || "";

                          await GetRackForDropDown(false);
                          await GetPositionForDropDown(false);
                        } else {
                          setDetailData({
                            ...detailData,
                            product_id: e.value,
                            product_no: e.label,
                            product_name: e.hidden_value,
                            quantity: "",
                            convert_unit: "",
                            convert_quantity: "",
                          });
                          detailData.product_id = e.value;
                          detailData.product_no = e.label;
                          detailData.product_name = e.hidden_value;
                          detailData.quantity = "";
                          detailData.convert_unit = "";
                          detailData.convert_quantity = "";

                          await getLotProductInWarehouse();
                        }

                        await GetUnitForDropDown(true);
                      }
                    }}
                  />
                  <label>
                    Product No.<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <NumberFormat
                    type="text"
                    className="form-control"
                    allowNegative={false}
                    allowLeadingZeros={false}
                    allowEmptyFormatting={false}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                    decimalScale={0}
                    required
                    value={detailData.quantity}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;

                      const convert_quantity =
                        Number(value || 0) *
                        Number(detailData.convert_unit || 1);

                      setDetailData({
                        ...detailData,
                        quantity: value,
                        convert_quantity: convert_quantity,
                      });
                      detailData.quantity = value;
                      detailData.convert_quantity = convert_quantity;
                    }}
                  />
                  <label>
                    Quantity<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div class="form-group">
                  <Select
                    options={unitData.dropdown}
                    value={unitData.dropdown.filter((e) => {
                      return e.value == detailData.unit_id;
                    })}
                    onChange={async (e) => {
                      if (e !== null) {
                        const convert_quantity =
                          Number(detailData.quantity || 0) *
                          Number(e.ratio || 1);

                        setDetailData({
                          ...detailData,
                          unit_id: e.value,
                          unit_name: e.label,
                          convert_unit: Number(e.ratio || 1),
                          convert_quantity: convert_quantity,
                        });
                        detailData.unit_id = e.value;
                        detailData.unit_name = e.label;
                        detailData.convert_unit = Number(e.ratio || 1);
                        detailData.convert_quantity = convert_quantity;
                      }
                    }}
                  />
                  <label>
                    Unit<span style={{ color: "red" }}> *</span>
                  </label>
                </div>
              </div>
            </div>

            {tabData.mode == "In" ? (
              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <Select
                      options={rackData.dropdown_use}
                      value={rackData.dropdown_use.filter((e) => {
                        return e.value == detailData.rack_id;
                      })}
                      onChange={async (e) => {
                        if (e !== null) {
                          setDetailData({
                            ...detailData,
                            rack_id: e.value,
                            rack_name: e.label,
                            position_id: "",
                            position_name: "",
                            current_amount: "",
                            limit_amount: "",
                          });
                          detailData.rack_id = e.value;
                          detailData.rack_name = e.label;
                          detailData.position_id = "";
                          detailData.position_name = "";
                          detailData.current_amount = "";
                          detailData.limit_amount = "";

                          await GetPositionForDropDown(false);
                        }
                      }}
                    />
                    <label>
                      Rack<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {tabData.mode == "In" ? (
              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <Select
                      options={positionData.dropdown_use}
                      value={positionData.dropdown_use.filter((e) => {
                        return e.value == detailData.position_id;
                      })}
                      onChange={async (e) => {
                        if (e !== null) {
                          const temp_list_product = JSON.parse(
                            JSON.stringify(productInWarehouse.product_warehouse)
                          );

                          const temp_list_log = JSON.parse(
                            JSON.stringify(productLog.in_stock)
                          );

                          const temp_data_product = temp_list_product.find(
                            (item) => {
                              return (
                                item.pp_product_id == detailData.product_id &&
                                item.rack_id == detailData.rack_id &&
                                item.rp_id == e.value
                              );
                            }
                          );

                          const temp_data_log = temp_list_log.find((item) => {
                            return (
                              item.product_id == detailData.product_id &&
                              item.lp_rack_id == detailData.rack_id &&
                              item.lp_rp_id == e.value
                            );
                          });

                          setDetailData({
                            ...detailData,
                            position_id: e.value,
                            position_name: e.label,
                            current_amount: Number(
                              temp_data_log?.lp_amount || 0
                            ),
                            limit_amount: temp_data_product?.pp_max_capacity,
                          });
                          detailData.position_id = e.value;
                          detailData.position_name = e.label;
                          detailData.current_amount = Number(
                            temp_data_log?.lp_amount || 0
                          );
                          detailData.limit_amount =
                            temp_data_product?.pp_max_capacity;
                        }
                      }}
                    />
                    <label>
                      Position<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              className="btn btn-success"
              onClick={async () => {
                const error_list = [];

                if (
                  detailData.product_id === null ||
                  detailData.product_id.trim() === ""
                ) {
                  let temp_err = {
                    message: "กรุณาเลือก Product",
                  };
                  error_list.push(temp_err);
                }

                if (
                  detailData.quantity === null ||
                  detailData.quantity.trim() === "" ||
                  detailData.quantity == 0
                ) {
                  let temp_err = {
                    message: "กรุณากรอก Quantity",
                  };
                  error_list.push(temp_err);
                }

                if (
                  detailData.unit_id === null ||
                  detailData.unit_id.trim() === ""
                ) {
                  let temp_err = {
                    message: "กรุณาเลือก Unit",
                  };
                  error_list.push(temp_err);
                }

                if (
                  tabData.mode == "In" &&
                  (detailData.rack_id === null ||
                    detailData.rack_id.trim() === "")
                ) {
                  let temp_err = {
                    message: "กรุณาเลือก Rack",
                  };
                  error_list.push(temp_err);
                }

                if (
                  tabData.mode == "In" &&
                  (detailData.position_id === null ||
                    detailData.position_id.trim() === "")
                ) {
                  let temp_err = {
                    message: "กรุณาเลือก Position",
                  };
                  error_list.push(temp_err);
                }

                if (
                  tabData.mode == "In" &&
                  Number(detailData.convert_quantity) +
                    Number(detailData.current_amount) >
                    Number(detailData.limit_amount)
                ) {
                  let temp_err = {
                    message: "จำนวนที่กรอกมีมากกว่า จำนวนที่เก็บได้",
                  };
                  error_list.push(temp_err);
                }

                if (
                  tabData.mode == "Out" &&
                  Number(detailData.convert_quantity) >
                    productLog.in_lot.reduce(
                      (accumulator, currentValue) =>
                        accumulator + Number(currentValue.lp_amount),
                      0
                    )
                ) {
                  let temp_err = {
                    message: "จำนวนที่กรอกมีมากกว่า จำนวนที่หยิบได้",
                  };
                  error_list.push(temp_err);
                }

                if (error_list.length > 0) {
                  var err_message = "";

                  for (var e = 0; e < error_list.length; e++) {
                    err_message += "<br/>" + error_list[e].message;
                  }

                  Swal.fire("Error", err_message, "error");
                } else {
                  let temp_list = [];

                  if (tabData.mode == "In") {
                    temp_list = JSON.parse(JSON.stringify(tabData.inData));
                  } else {
                    temp_list = JSON.parse(JSON.stringify(tabData.outData));
                  }

                  const temp_detail = JSON.parse(JSON.stringify(detailData));

                  if (tabData.mode == "Out") {
                    const temp_out_list = [];

                    let temp_amount = Number(temp_detail.convert_quantity);

                    for (const item of productLog.in_lot) {
                      const temp_out = {};

                      const temp_stock_product = productLog.in_stock.find(
                        (itemf) => {
                          return (
                            detailData.product_id == itemf.product_id &&
                            item.lp_rack_id == itemf.lp_rack_id &&
                            item.lp_rp_id == itemf.lp_rp_id
                          );
                        }
                      );

                      const temp_product_warehouse =
                        productInWarehouse.product_warehouse.find((itemf) => {
                          return (
                            detailData.product_id == itemf.pp_product_id &&
                            item.lp_rack_id == itemf.rack_id &&
                            item.lp_rp_id == itemf.rp_id
                          );
                        });

                      const rack = rackData.allData.find((itemf) => {
                        return itemf.value == item.lp_rack_id;
                      });

                      const position = positionData.allData.find((itemf) => {
                        return itemf.value == item.lp_rp_id;
                      });

                      if (temp_amount > item.lp_amount) {
                        temp_out["out_lot_no"] = item.fg_lot_no;
                        temp_out["out_current_amount"] =
                          temp_stock_product.lp_amount;
                        temp_out["out_limit_amount"] =
                          temp_product_warehouse?.pp_max_capacity;
                        temp_out["out_quantity"] = item.lp_amount;
                        temp_out["out_rack_id"] = item.lp_rack_id;
                        temp_out["out_rack_name"] = rack?.label;
                        temp_out["out_position_id"] = item.lp_rp_id;
                        temp_out["out_position_name"] = position?.label;

                        temp_out_list.push(temp_out);
                      } else {
                        temp_out["out_lot_no"] = item.fg_lot_no;
                        temp_out["out_current_amount"] =
                          temp_stock_product.lp_amount;
                        temp_out["out_limit_amount"] =
                          temp_product_warehouse?.pp_max_capacity;
                        temp_out["out_quantity"] = temp_amount;
                        temp_out["out_rack_id"] = item.lp_rack_id;
                        temp_out["out_rack_name"] = rack?.label;
                        temp_out["out_position_id"] = item.lp_rp_id;
                        temp_out["out_position_name"] = position?.label;

                        temp_out_list.push(temp_out);
                      }

                      temp_amount -= Number(item.lp_amount);

                      if (temp_amount <= 0) {
                        break;
                      }
                    }

                    temp_detail.out_list = temp_out_list;
                  }

                  if (modalData.mode == "Add") {
                    let detail_id = "";

                    if (temp_list.length == 0) {
                      detail_id = "detail_0";
                    } else {
                      detail_id = `detail_${
                        Number(temp_list.at(-1).detail_id.substring(7)) + 1
                      }`;
                    }

                    temp_detail.detail_id = detail_id;

                    temp_list.push(temp_detail);
                  } else {
                    const index_data = temp_list.findIndex((item) => {
                      return item.detail_id == temp_detail.detail_id;
                    });

                    temp_list[index_data] = temp_detail;
                  }

                  if (tabData.mode == "In") {
                    setTabData({
                      ...tabData,
                      inData: temp_list,
                    });
                    tabData.inData = temp_list;
                  } else {
                    setTabData({
                      ...tabData,
                      outData: temp_list,
                    });
                    tabData.outData = temp_list;
                  }

                  setModalData({
                    ...modalData,
                    is_open: false,
                  });
                  modalData.is_open = false;
                }
              }}
            >
              บันทึก
            </button>

            <button
              type="button"
              className="btn btn-danger"
              onClick={async () => {
                Swal.fire({
                  title: "คุณมั่นใจว่าจะปิดหน้าต่างนี้ใช่หรือไม่",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: `ใช่`,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: `ไม่`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    setModalData({
                      ...modalData,
                      is_open: false,
                    });
                    modalData.is_open = false;
                  }
                });
              }}
            >
              ยกเลืก
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default FG_IO_Location_AEV;
