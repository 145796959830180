import React, { Component, useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
} from "../../Utils/Common";
import Select from "react-select";
import { Tree, TreeNode } from "react-organizational-chart";

import CompanyCard from "./Manage_Component/Company_Card";

function Manag_Position() {
 

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  โครงสร้างองค์กร{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Manage Position</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid"></div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive"
              style={{ textAlign: "center" }}
            >
              
           
          
            </div>

            <div className="card-footer" />
          </div>
        </section>

      </div>
    </div>
  );
}

export default Manag_Position;
