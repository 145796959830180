import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";

function Warehouse_type_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [warehouse_type, setWarehouse_type] = useState({
    company_id: getUser().com,
    id: "",
    type: "",
    remark: "",
    is_use:"",
  });
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  useEffect(() => {
    GetCompany();
    //console.log("id:"+id);
    GetWarehouse_type_view();
  }, []);


  const GetWarehouse_type_view = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/warehouse_type/findById?id="+ id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          //console.log(response.data);
          setWarehouse_type({
            company_id: response.data.company_id,
            id: response.data.id,
            type: response.data.type,
            remark: response.data.remark,
            is_use:response.data.is_use,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  
  
  function saveOption(params) {
    const error_list = [];
    if(warehouse_type.type.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Type]."
      }
      error_list.push(temp_err);
    }
   
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
       if (mode.mode === "add") {
        const temp ={
            company_id: getUser().com,
            type: warehouse_type.type.trim(),
            remark: warehouse_type.remark,   
        }
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/warehouse_type/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Warehouse_Type";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }
       if (mode.mode === "edit") {
        const temp ={
            type_id:id,
            company_id: getUser().com,
            type: warehouse_type.type.trim(),
            remark: warehouse_type.remark,   
        }
        //console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/warehouse_type/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Warehouse_Type";
                });
              }
    
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }
    }
  
  }

  

  function cancelOption(params) {
    Swal.fire(
        "Do you want to cancel?",
        "Wait",
        "warning"
      ).then(()=>{
             window.location.href =
    "/Warehouse_Management/Factory_Master_Data/Warehouse_Type";
      }
      );
  }
  return (
    <div className="wrapper">

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Warehouse_type {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Warehouse_type</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-sm-1">
                  <button
                    type="button"
                    if
                      onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Warehouse_type {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">

                 <br/>
              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={warehouse_type.type}
                      disabled={disable}
                      onChange={(e) => {
                        setWarehouse_type({
                          ...warehouse_type,
                          type: e.target.value,
                        });
                      }}
                    
                    />
                    <label>
                      Type
                      {warehouse_type.type === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                </div>
                <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={warehouse_type.remark}
                      disabled={disable}
                      onChange={(e) => {
                        setWarehouse_type({
                          ...warehouse_type,
                          remark: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                      Remark
                    </label>
                  </div>
                </div>


               
              </div>
              
             
                
           
            </div>
            
          </div>
        </section>
      </div>
     {/*  <Footter/> */}
      </div>
  );
}
export default Warehouse_type_add;
