import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import moment from 'moment'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import NumberFormat from "react-number-format";
import Sidebar from "../../component/Sidebar";
import BTN from "../../component/btn_feature";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import QRCode2 from "qrcode";
import { Get_font_pdf } from "../../assets/font/pdf_font";
import jsPDF from "jspdf";
let date = new Date();
let dateformatYear = date.getFullYear();
let dateformatMonth = date.getMonth()+1;
function Product_Approve(params) {
  const [fillter, setfillter] = useState({
    oem_id: getOem(),
    year:  dateformatYear,
    month: dateformatMonth,
    suggestion: 0,
  });
  const [approve_list, setapprove_list] = useState([]);
  const [approve_list_update, setapprove_list_update] = useState([]);
  const [capacity, setcapacity] = useState(0);
  const [sumwip, setsumwip] = useState(0);
  const [sumnew, setsumnew] = useState(0);
  const [remain, setremain] = useState(0);
  const [year_now, setyear_now] = useState(moment(new Date()).format('YYYY'));
  const [month_now, setmonth_now] = useState(moment(new Date()).format('MM'));
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal, setModal] = useState(false);
  const toggle_modal = () => setModal(!modal);
/*   const year_now = moment().format('YYYY');
  const month_now = moment().format('YYYY'); */
  const [data_result, setdata_result] = useState([]);

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    axios(config_com)
      .then(function (response) {
        /* console.log(response.data.factory_capacity); */
        setcapacity(response.data.factory_capacity);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
 

  function load_suggrest() {
    if (fillter.year !== "" && fillter.month !== "") {
     
    
      const temp = {
        oem_id: fillter.oem_id,
        year: fillter.year,
        month: fillter.month,
        suggestion: Number(fillter.suggestion),
      };
      //console.log(temp);

      var config_filler = {
        method: "post",
        url: Configs.API_URL + "/productPlaning/FilterOrderForcast",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: temp,
      };
      let timerInterval;
      axios(config_filler)
        .then(function (response) {
       
          Swal.fire({
            title: "Loading",
            html: "Downloading Data <b></b>",
           timer: response.data.length*10,
      
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              timerInterval = setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft() / 10;
                  }
                }
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              //console.log("I was closed by the timer");
            }
          });

          //console.log(response.data);
          let sumwiptemp = 0;
          let sumnew_temp = 0;
          const temp_data = [];
          for (let index = 0; index < response.data.length; index++) {
            const element = {};
            element["id"] = response.data[index].id;
            element["no"] = response.data[index].no;
            element["name"] = response.data[index].name;
            element["wip"] = response.data[index].wip;
            element["fg"] = response.data[index].fg;
            element["delivery"] = response.data[index].delivery;
            element["amount_forcast"] = response.data[index].amount_forcast;
            element["in_hand"] = response.data[index].in_hand;
            element["pending"] = response.data[index].pending;
            element["n1"] = response.data[index].n1;
            element["n2"] = response.data[index].n2;
            element["n3"] = response.data[index].n3;
            element["n4"] = response.data[index].n4;
            element["suggestion"] =
              response.data[index].suggestion < 0
                ? 0
                : response.data[index].suggestion;
            element["nmo"] = response.data[index].suggestion;
            temp_data.push(element);

            sumwiptemp = sumwiptemp + response.data[index].in_hand;
            sumnew_temp = sumnew_temp + response.data[index].suggestion;
          }
          let sorted = temp_data.sort((a, b) => b.nmo - a.nmo);
          setapprove_list(sorted);
          setsumnew(Number(((sumnew_temp / capacity) * 100).toFixed(2)));
          setsumwip(Number(((sumwiptemp / capacity) * 100).toFixed(2)));
          setremain(
            Number(
              100 -
                (Number((sumwiptemp / capacity) * 100) +
                  Number((sumnew_temp / capacity) * 100))
            ).toFixed(2)
          );
        })
        .catch(function (error) {
          console.log(error);
        });
 
    } else {
      Swal.fire("Warning", "Please Select Year and Month", "warning");
    }
  }

  const Summary = () => {
    if (approve_list.length !== 0) {
      let sum_new = 0;
      for (let index = 0; index < approve_list.length; index++) {
        sum_new = sum_new + approve_list[index].nmo;
      }
      /*  console.log(Number(sum_new)); */

      setsumnew(Number(((sum_new / capacity) * 100).toFixed(2)));
      setremain(
        Number(
          100 - (Number(sumwip) + Number((sum_new / capacity) * 100))
        ).toFixed(2)
      );
      /* console.log(
        Number(
          100 - (Number(sumwip) + Number((sum_new / capacity) * 100))
        ).toFixed(2)
      ); */
      
    }
  };

  function approve_product() {
    /* console.log(approve_list.length); */
    if (
      fillter.year !== "" &&
      fillter.month !== "" &&
      approve_list.length !== 0
    ) {
      /*  console.log(approve_list); */
      const data_update = [
        /* {
        "no": "TC404-2443-1",
        "year": "2021",
        "month": "01",
        "order": 1050
      } */
      ];

      for (let index = 0; index < approve_list.length; index++) {
        const element = {};

        element["no"] = approve_list[index].no;
        element["year"] = fillter.year;
        element["month"] = fillter.month;
        element["order"] = approve_list[index].nmo;

        data_update.push(element);
      }
      //console.log(data_update);
      axios({
        method: "post",
        url: Configs.API_URL + "/productPlaning/updateRealOrder",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: data_update,
      })
        .then(function (response) {
          //console.log(response.data);
            
          let temp1 = response.data.filter(x => x.status = true )
          let temp2 = temp1.filter(x => x.order > 0 ) 
          setapprove_list_update(temp2);
          Swal.fire(
            "Upload Success",
            "Your file has been Upload.",
            "success"
          ).then(() => {
            toggle_modal();
          });
        })
        .catch(function (error) {

          console.log(error);
          Swal.fire(
            "Warning",
            "Year and Month not found!!!",
            "warning"
          );
       
        });
      /*    Swal.fire({
        icon: "info",
        title: "Waiting for API save to database",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }); */
    } else {
      Swal.fire(
        "Warning",
        "Please Select Year and Month or Suggression",
        "warning"
      );
    }
  }

  function approve_update(e, data) {
    approve_list[e.target.name].nmo = Number(e.target.value.replace(/,/g, ""));
     // console.log(approve_list[e.target.name].nmo); 
    Summary();
  }
  const rowsData = [];
  for (var index = 0; index < approve_list.length; index++) {
    const rowItem = {};
    rowItem["Item"] = index + 1;
    rowItem["Part_No"] = approve_list[index].no;
    rowItem["Part_Name"] = approve_list[index].name;
    rowItem["WIP"] = approve_list[index].wip;
    rowItem["FG"] = approve_list[index].fg;
    rowItem["In_Hand"] = approve_list[index].in_hand;

    rowItem["N1"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n1 !== null ? approve_list[index].n1 : 0}
      </div>
    );
    rowItem["N2"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n2 !== null ? approve_list[index].n2 : 0}
      </div>
    );
    rowItem["N3"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n3 !== null ? approve_list[index].n3 : 0}
      </div>
    );
    rowItem["N4"] = (
      <div style={{ color: "indianred" }}>
        {approve_list[index].n4 !== null ? approve_list[index].n4 : 0}
      </div>
    );
    rowItem["nms"] = (
      <div style={{ color: "violet" }}>{approve_list[index].suggestion}</div>
    );
    rowItem["nm_order"] = (
      <NumberFormat
        className="form-control"
        required
        name={index}
        thousandSeparator={true}
        value={approve_list[index].nmo < 0 ? 0 : approve_list[index].nmo}
        onChange={approve_update.bind(this)}
      />
    );

    rowsData.push(rowItem);
  }

  /*   const onButton_modal_Click = () => {
    modal_click.current.click();
  }; */

  const data = {
    columns: [
      {
        label: "Item",
        field: "Item",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_No",
        field: "Part_No",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_Name",
        field: "Part_Name",
        sort: "asc",
        width: 50,
      },
      {
        label: "WIP",
        field: "WIP",
        sort: "asc",
        width: 50,
      },
      {
        label: "FG",
        field: "FG",
        sort: "asc",
        width: 50,
      },
      {
        label: "In_Hand",
        field: "In_Hand",
        sort: "asc",
        width: 50,
      },
      ,
      /* {
        label: "N",
        field: "N",
        sort: "asc",
        width: 50,
      },
      {
        label: "Delivery",
        field: "Delivery",
        sort: "asc",
        width: 50,
      } ,
      {
        label: "Pending",
        field: "Pending",
        sort: "asc",
        width: 50,
      } */ {
        label: "N1",
        field: "N1",
        sort: "asc",
        width: 50,
      },
      {
        label: "N2",
        field: "N2",
        sort: "asc",
        width: 50,
      },
      {
        label: "N3",
        field: "N3",
        sort: "asc",
        width: 50,
      },
      {
        label: "N4",
        field: "N4",
        sort: "asc",
        width: 50,
      },
      {
        label: "Next_Month_Suggestion",
        field: "nms",
        sort: "asc",
        width: 50,
      },
      {
        label: "Next_Month Order",
        field: "nm_order",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "Mat / kg",
        field: "mat_kg",
        sort: "asc",
        width: 50,
      },
      {
        label: "cos / pc",
        field: "cos_pc",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mat. / Supp",
        field: "mat_pupp",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
       */
    ],
    rows: rowsData,
  };

  const [tagtono, settagtono] = useState([]);
  function add_tag(params) {
    let track_list = [];
    for (let i = 0; i < approve_list.length; i++) {
      if (approve_list[i].nmo > 0) {
        track_list.push(approve_list[i]);
      }
    }
    var tracking_list = [];
    for (let index = 0; index < track_list.length; index++) {
      const element = {};

      element["tracking_name"] = track_list[index].no;
      element["oem_id"] = getOem();

      tracking_list.push(element);
    }

    axios({
      method: "post",
      url: Configs.API_URL + "/trackingTag/add",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
      },
      data: tracking_list,
    })
      .then(function (response) {
        settagtono(response.data);
        //console.log("api");
        tracking(response.data);
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function tracking(tagtono2) {
    //console.log('here')
    //console.log(approve_list)
    let track_list = [];
    for (let i = 0; i < approve_list.length; i++) {
      if (approve_list[i].nmo > 0) {
        track_list.push(approve_list[i]);
      }
    }
    /*  var tracking_list = []
    for (let index = 0; index < track_list.length; index++) {
      const element = {};

      element["tracking_name"] = track_list[index].no;
      element["oem_id"] = getOem();
      
      
      tracking_list.push(element);
    } */

    //console.log(tagtono2);

    //console.log("here");

    const doc = new jsPDF("p", "mm", "a4");
    let img_qr = "";

    for (let index = 0; index < track_list.length; index = index + 8) {
      if (index < track_list.length) {
        QRCode2.toDataURL(track_list[index].no.toString(), function (err, url) {
          img_qr = url;
        });

        doc.addImage(img_qr, "JPEG", 58, 10, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 15, track_list[index].no.toString(), "center");
        doc.rect(0, 10, 105, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 20, "Tag No :  " + tagtono2[index].tag_no);
        doc.rect(0, 10, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 25, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 38, track_list[index].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 48, "Cutting");
        doc.rect(10, 45, 3, 3);
        doc.line(40, 48, 60, 48);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 55, "m/c");
        doc.rect(10, 52, 3, 3);
        doc.line(40, 55, 60, 55);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 62, "QC");
        doc.rect(10, 59, 3, 3);
        doc.line(40, 62, 60, 62);
      }
      //box 2
      if (index + 1 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 1].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 10, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 15, track_list[index + 1].no.toString(), "center");
        doc.rect(0, 10, 105, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 20, "Tag No : " + tagtono2[index + 1].tag_no);
        doc.rect(105, 10, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 25, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 38, track_list[index + 1].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 48, "Cutting");
        doc.rect(115, 45, 3, 3);
        doc.line(145, 48, 165, 48);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 55, "m/c");
        doc.rect(115, 52, 3, 3);
        doc.line(145, 55, 165, 55);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 62, "QC");
        doc.rect(115, 59, 3, 3);
        doc.line(145, 62, 165, 62);
      }
      //box 3
      if (index + 2 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 2].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 58, 70, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 75, track_list[index + 2].no.toString(), "center");
        doc.rect(0, 10, 105, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 80, "Tag No : " + tagtono2[index + 2].tag_no);
        doc.rect(0, 70, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 85, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 98, track_list[index + 2].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 108, "Cutting");
        doc.rect(10, 105, 3, 3);
        doc.line(40, 108, 60, 108);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 115, "m/c");
        doc.rect(10, 112, 3, 3);
        doc.line(40, 115, 60, 115);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 122, "QC");
        doc.rect(10, 119, 3, 3);
        doc.line(40, 122, 60, 122);
      }
      //box 4
      if (index + 3 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 3].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 70, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 75, track_list[index + 3].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 80, "Tag No : " + tagtono2[index + 3].tag_no);
        doc.rect(105, 70, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 85, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 98, track_list[index + 3].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 108, "Cutting");
        doc.rect(115, 105, 3, 3);
        doc.line(145, 108, 165, 108);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 115, "m/c");
        doc.rect(115, 112, 3, 3);
        doc.line(145, 115, 165, 115);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 122, "QC");
        doc.rect(115, 119, 3, 3);
        doc.line(145, 122, 165, 122);
      }

      //box 5
      if (index + 4 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 4].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 58, 130, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 135, track_list[index + 4].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 140, "Tag No : " + tagtono2[index + 4].tag_no);
        doc.rect(0, 130, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 145, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 158, track_list[index + 4].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 168, "Cutting");
        doc.rect(10, 165, 3, 3);
        doc.line(40, 168, 60, 168);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 175, "m/c");
        doc.rect(10, 172, 3, 3);
        doc.line(40, 175, 60, 175);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 182, "QC");
        doc.rect(10, 179, 3, 3);
        doc.line(40, 182, 60, 182);
      }
      //box 6
      if (index + 5 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 5].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 130, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 135, track_list[index + 5].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 140, "Tag No : " + tagtono2[index + 5].tag_no);
        doc.rect(105, 130, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 145, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 158, track_list[index + 5].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 168, "Cutting");
        doc.rect(115, 165, 3, 3);
        doc.line(145, 168, 165, 168);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 175, "m/c");
        doc.rect(115, 172, 3, 3);
        doc.line(145, 175, 165, 175);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 182, "QC");
        doc.rect(115, 179, 3, 3);
        doc.line(145, 182, 165, 182);
      }
      //box 7
      if (index + 6 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 6].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 58, 190, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(83, 195, track_list[index + 6].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(5, 200, "Tag No : " + tagtono2[index + 6].tag_no);
        doc.rect(0, 190, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(5, 205, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(12, 218, track_list[index + 6].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 228, "Cutting");
        doc.rect(10, 225, 3, 3);
        doc.line(40, 228, 60, 228);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 235, "m/c");
        doc.rect(10, 232, 3, 3);
        doc.line(40, 235, 60, 235);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(22, 242, "QC");
        doc.rect(10, 239, 3, 3);
        doc.line(40, 242, 60, 242);
      }
      //box 8

      if (index + 7 < track_list.length) {
        QRCode2.toDataURL(
          track_list[index + 7].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );

        doc.addImage(img_qr, "JPEG", 163, 190, 50, 60);

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(188, 195, track_list[index + 7].no.toString(), "center");

        doc.addFileToVFS("ROCK.TTF", Get_font_pdf());
        doc.addFont("ROCK.TTF", "Rocki", "normal");
        doc.setFont("Rocki");
        doc.setFontSize(12);
        doc.text(110, 200, "Tag No : " + tagtono2[index + 7].tag_no);
        doc.rect(105, 190, 105, 60);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(110, 205, "Amount");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(36);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(117, 218, track_list[index + 7].nmo.toString());

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 228, "Cutting");
        doc.rect(115, 225, 3, 3);
        doc.line(145, 228, 165, 228);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 235, "m/c");
        doc.rect(115, 232, 3, 3);
        doc.line(145, 235, 165, 235);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki");
        doc.setFont("Rocki");
        doc.text(127, 242, "QC");
        doc.rect(115, 239, 3, 3);
        doc.line(145, 242, 165, 242);
      }
      if (index + 8 < track_list.length) {
        doc.addPage("a4", "h");
      }
    }
    window.open(doc.output("bloburl"));
  }
  return (
    <div className="wrapper">
      <Header />
      <Sidebar menu="pp" activemenu="approve" submenu="none" />
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Approve Production Item</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Approve Production Item 
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.year}
                    onChange={(e) =>
                      setfillter({ ...fillter, year: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                  <label htmlFor="">Year</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.month}
                    onChange={(e) =>
                      setfillter({ ...fillter, month: e.target.value })
                    }
                  >
                    <option value="Select">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label htmlFor="">Month</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.suggestion}
                    onChange={(e) =>
                      setfillter({ ...fillter, suggestion: e.target.value })
                    }
                  >
                    <option value="0">Select Month</option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Month</option>
                    <option value="3">3 Month</option>
                    <option value="4">4 Month</option>
                  </select>
                  <label htmlFor="">Month For Suggession</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={load_suggrest}
                  >
                    Load Suggestion
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  {/* <button type="button" className="btn btn-block btn-primary"
                 
                  >
                    Export
                  </button> */}
              
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="table-to-xls_all"
                    filename={"Approve_Production_Item"+ " "+ Date()}
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={approve_product}
                  >
                    Save To Database
                  </button>
                </div>
              </div>
              <BTN onClick = {add_tag}  name = {"Report Tracking"} />
             {/*  <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary "
                    onClick={add_tag}
                  >
                    Report Tracking
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Approve Production Item</h3>
              <div className="card-tools">
                Status Capacity by %{" "}
                <span style={{ color: "green" }}>
                  Max:
                  <NumberFormat
                    displayType="text"
                    name={"capacity"}
                    thousandSeparator={true}
                    value={capacity}
                  />
                  {/*  {capacity}  */}piecse/month
                </span>
              </div>
              <br />
              <Progress multi>
                <Progress
                  bar
                  color="success"
                  value={sumwip}
                  id="Tooltip-sumwip"
                >
                  {"WIP:" + sumwip + "%"}
                </Progress>
                <Progress
                  bar
                  color="info"
                  value={sumnew}
                  id="Tooltip-New-Order"
                >
                  {"New Order:" + sumnew + "%"}
                </Progress>
                <Progress
                  bar
                  color={remain < 0 ? "danger" : "warning"}
                  value={remain}
                  id="Tooltip-Remain-Cap"
                >
                  {"Remain Cap:" + remain + "%"}
                </Progress>
              </Progress>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="Tooltip-sumwip"
                toggle={toggle}
              >
                WIP
              </Tooltip>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="Tooltip-New-Order"
                toggle={toggle}
              >
                New Order
              </Tooltip>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target="Tooltip-Remain-Cap"
                toggle={toggle}
              >
                Remain Cap
              </Tooltip>
              <div className="card-tools"> </div>
              <div className="card-tools">
                In Hand:{(sumwip * capacity) / 100}| New Order:
                {(sumnew * capacity) / 100}| Remain cap:
                {(remain / 100) * capacity}
              </div>
            </div>

            <div className="card-body ">
              <div
                className="table-responsive" /* style={{ height: "500px" }} */
              >
              
               

                  
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  id="table-to-xls"
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />

          <div style={{display:"none"}} >

              <MDBDataTable    
                  className="table table-head-fixed" 
                  striped
                  entries={4096}
                  id="table-to-xls_all"
                  bordered
                  hover
                  fixedHeader
                  data={data}
                
                />
                </div>
        

              </div>
            </div>
            <div className="card-footer">
              {/* <Button ref={modal_click} color="info" onClick={toggle_modal}>111111111</Button> */}
            </div>
          </div>
        </section>
      <Modal isOpen={modal} toggle={toggle_modal} /* className={className} */>
        <ModalHeader toggle={toggle_modal}>Approve Production Item</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-4">
              <label>Year: {fillter.year}</label>
            </div>
            <div className="col-4">
              <label>Month: {fillter.month}</label>
            </div>
            <div className="col-4">
              <label>Month Suggestion: {fillter.suggestion}</label>
            </div>
          </div>
          <div className="table-responsive" style={{ height: "500px" }}>
            <MDBTable striped hover bordered small searching={true}>
              <MDBTableHead>
                <tr>
                  <th>No.</th>
                  <th>Product No.</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {approve_list_update.map((el, index) => {
                  return (
                    <tr id={index}>
                      <td>{index + 1}</td>
                      <td>{el.no}</td>
                      <td>{el.order}</td>
                      <td>{el.status ? "True" : "False"}</td>
                    </tr>
                  );
                })}
              </MDBTableBody>
            </MDBTable>
          </div>
        </ModalBody>
        <ModalFooter>
          {/*  <Button color="primary" onClick={toggle_modal}>Do Something</Button>{' '} */}
          <Button color="secondary" onClick={toggle_modal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      </div>

      

      <Footter />
    </div>
  );
}
export default Product_Approve;
/* Approve Production Item */
