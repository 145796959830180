let config = {
  /*  API_URL:'http://150.95.80.78:9090',
    API_URL_IMG:'http://150.95.27.52:4001/static/',
  
  API_TTT: '20EC2A2BA3ED2470C11AC4E78461189769DEEAFBDC0D209709897902E6042D44',
  
  configpathfile: 'http://150.95.27.52:9090',
  DTC_HOST:"http://devweb.dtcgps.com:8087",
  DTC_TOKEN:"18FGEREW9PF7P2CVZ5HNSKZ31BB6R8VQ94U3AC2WTX4USHGMTK65LYQLADJN7JXD", */

  API_URL: "http://150.95.80.78:9090",
  API_URL_IMG: "http://150.95.80.78:9090/static/",
  API_TTT: "20EC2A2BA3ED2470C11AC4E78461189769DEEAFBDC0D209709897902E6042D44",
  configpathfile: "http://150.95.80.78:9090",
  DTC_HOST: "http://devweb.dtcgps.com:8087",
  DTC_TOKEN: "18FGEREW9PF7P2CVZ5HNSKZ31BB6R8VQ94U3AC2WTX4USHGMTK65LYQLADJN7JXD",
  API_URL_rawmatInOutCTS: "http://150.95.80.78:4000",
  API_URL_externalData: "http://150.95.80.78:4000",
  API_URL_reportPDF: "http://150.95.80.78:4000",
  API_URL_snManagement: "http://150.95.80.78:4000",
  API_URL_planningCTS: "http://150.95.80.78:4000",
  API_URL_hrMagenatement: "http://150.95.80.78:5007",
  API_URL_hrMagenatement_public: "http://150.95.80.78:5008",
  API_URL_incentive: "http://150.95.80.78:9998",
  API_URL_IMG_incentive: "http://150.95.80.78:9998/static/",

  API_URL_old_cuteplus: "http://150.95.80.78:8081",
  API_URL_IMG_old_cuteplus: "http://150.95.80.78:8081/static/",

  API_URL_cuteplus: "http://150.95.80.78:5005",
  API_URL_cuteplus_sales: "http://150.95.80.78:5002",
  API_URL_IMG_cuteplus: "http://150.95.80.78:5006",

  API_LINE: "https://fa41-49-49-218-104.ap.ngrok.io",

  API_Ticket: "http://150.95.80.78:5555",

  // API_URL_IoT_Connect: "http://150.95.25.8:6682" // Team 0
  API_URL_IoT_Connect: "http://150.95.80.78:6682", // TTT

  API_URL_AIOI_Back: "http://150.95.80.78:6681",
};

export default Object.freeze(Object.assign({}, config));
