import React, { useState, useEffect, useRef } from "react";

import "../../../../assets/css/ProductService.css";

import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

import { LeftButton1, RightButton1 } from "../Incentive_User_icon_img/All_Icon";

function Product_Service_Card_Slider({
  data,
  width,
  height,
  id,
  // allowButton,
  toggle,
  setModalData,
}) {
  // -------------------- Image Slide 1 --------------------
  // const [current, setCurrentIndex] = useState(0);
  // const length = data.length;

  // function nextSlide(params) {
  //   setCurrentIndex(current === length - 1 ? 0 : current + 1);
  // }

  // function previousSlide(params) {
  //   setCurrentIndex(current === 0 ? length - 1 : current - 1);
  // }

  // console.log(current);

  // if (!Array.isArray(data) || data.length <= 0) {
  //   return null;
  // }

  // return (
  //   <>
  //     <div className="containerStyles">
  //       <div className="sliderStyles">
  //         <button className="slider-left" onClick={previousSlide}>
  //           Left
  //         </button>
  //         <button className="slider-right" onClick={nextSlide}>
  //           Right
  //         </button>
  //         {data.map((item, index) => {
  //           return (
  //             <div
  //               className={index === current ? "slide active" : "slide"}
  //               key={index}
  //             >
  //               {index === current && (
  //                 <img className="slideStyles" src={item.item} />
  //               )}
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   </>
  // );
  // -------------------- Image Slide 1 --------------------

  // -------------------- Image Slide 2 --------------------
  const timerRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [elementSize, setElementSize] = useState({ width: 0 });

  const [startAt, setStartAt] = useState(0);
  const [currentAt, setCurrentAt] = useState(0);

  const [allowButton, setAllowButton] = useState(false);

  function nextSlide() {
    let isLastSlide = currentIndex === data.length - 1;
    let newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  function previousSlide() {
    let isFirstSlide = currentIndex === 0;
    let newIndex = isFirstSlide ? data.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  function toSlide(index) {
    setCurrentIndex(index);
  }

  useEffect(() => {
    if (data.length !== 0 && data.length !== 1) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        nextSlide();
      }, 1000 * 10);

      return () => clearTimeout(timerRef.current);
    }
  }, [nextSlide]);

  // const [windowSize, setWindowSize] = useState({
  //   width: undefined,
  //   height: undefined,
  // });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      // setWindowSize({
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      // });

      if (elementSize.width === 0) {
        setElementSize({
          width: width,
        });
        elementSize.width = width;
      } else {
        setElementSize({
          width: document
            .getElementById(`containerImageSlide2-${id}`)
            .getBoundingClientRect().width,
        });
        elementSize.width = document
          .getElementById(`containerImageSlide2-${id}`)
          .getBoundingClientRect().width;
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <>
      <div
        id={`containerImageSlide2-${id}`}
        className="containerImageSlide2"
        style={{
          maxWidth: `${width}px`,
          maxHeight: `${height}px`,
          aspectRatio: `${width} / ${height}`,
          backgroundColor: "white",
          borderRadius: "10px",
        }}
        onMouseEnter={() => {
          setAllowButton(true);
        }}
        onMouseLeave={() => {
          setAllowButton(false);
        }}
      >
        <div className="slider">
          {allowButton ? (
            <>
              <button className="slider-left" onClick={previousSlide}>
                <LeftButton1 width="20px" height="50px" fill="white" />
              </button>
              <button className="slider-right" onClick={nextSlide}>
                <RightButton1 width="20px" height="50px" fill="white" />
              </button>
            </>
          ) : (
            <></>
          )}
          <div className="containerSlide">
            <div
              className="slides"
              onTouchStart={(e) => {
                setStartAt(e.targetTouches[0].clientX);
              }}
              onTouchMove={(e) => {
                if (startAt !== 0) {
                  setCurrentAt(startAt - e.targetTouches[0].clientX);
                }
              }}
              onTouchEnd={(e) => {
                if (currentAt >= 100 && currentIndex < data.length - 1) {
                  setCurrentIndex(currentIndex + 1);
                } else if (currentAt <= -100 && currentIndex > 0) {
                  setCurrentIndex(currentIndex - 1);
                }

                setStartAt(0);
                setCurrentAt(0);
              }}
              onTouchCancel={(e) => {
                setStartAt(0);
                setCurrentAt(0);
              }}
              style={{
                // width: `${width * data.length}px`,
                // transform: `translateX(${-(
                //   currentIndex * elementSize.width
                // )}px)`,
                transform: `translateX(${-(
                  100 * currentIndex
                )}%) translateX(${-currentAt}px)`,
              }}
            >
              {data.length !== 0 ? (
                data.map((_, index) => {
                  return (
                    <a
                      onClick={() => {
                        toggle();
                        setModalData(data[index]);
                      }}
                      style={{
                        maxWidth: `${width}px`,
                        maxHeight: `${height}px`,
                        aspectRatio: `${width} / ${height}`,
                        cursor: "pointer",
                        // width: `${width}px`,
                        // backgroundImage: `url(${data[index].item})`,
                      }}
                    >
                      <img
                        // onClick={() => {
                        //   console.log(
                        //     `containerImageSlide2-${id}`,
                        //     index
                        //   );
                        // }}
                        alt={data[index].newsImageName || "..."}
                        src={data[index].newsImagePath || userdefault_img.imgs}
                        key={index}
                        className="slide"
                        style={{
                          maxWidth: `${width}px`,
                          maxHeight: `${height}px`,
                          aspectRatio: `${width} / ${height}`,
                          objectFit: "contain"
                          // width: `${width}px`,
                          // backgroundImage: `url(${data[index].item})`,
                        }}
                      />
                    </a>
                  );
                })
              ) : (
                <img
                  // onClick={() => {
                  //   console.log(
                  //     `containerImageSlide2-${id}`,
                  //     index
                  //   );
                  // }}
                  alt={"..."}
                  src={userdefault_img.imgs}
                  className="slide"
                  style={{
                    maxWidth: `${width}px`,
                    maxHeight: `${height}px`,
                    aspectRatio: `${width} / ${height}`,
                    // width: `${width}px`,
                    // backgroundImage: `url(${data[index].item})`,
                  }}
                />
              )}
            </div>
          </div>

          <div className="slider-to" style={{ margin: "-3% 0px" }}>
            {data.map((_, index) => {
              return (
                <div
                  key={index}
                  className={
                    currentIndex === index ? "dots-to1 active" : "dots-to1"
                  }
                  onClick={() => toSlide(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
  // -------------------- Image Slide 2 --------------------

  // -------------------- Multi Slide --------------------
  // function slideLeft(params) {
  //   var slider = document.getElementById("slider");
  //   slider.scrollLeft = slider.scrollLeft + 500;
  //   console.log(slider.scrollLeft);
  // }

  // function slideRight(params) {
  //   var slider = document.getElementById("slider");
  //   slider.scrollLeft = slider.scrollLeft - 500;
  //   console.log(slider.scrollLeft);
  // }

  // return (
  //   <>
  //     <div className="main-slider-contianer">
  //       <button className="slider-icon left" onClick={slideLeft}>Left</button>
  //       <div id="slider" className="slider">
  //         {data.map((item, index) => {
  //           return <div className="slider-card">{item.item}</div>;
  //         })}
  //       </div>
  //       <button className="slider-icon right" onClick={slideRight}>Right</button>
  //     </div>
  //   </>
  // );
  // -------------------- Multi Slide --------------------
}

export default Product_Service_Card_Slider;
