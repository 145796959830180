import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import { formats } from "numeral";

function Machine_FG(params) {
 
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  
  const [test, settest] = useState({
    test: "",
  });

  const [machine, setmachine] = useState([]);
  const [filter_machine, setfilter_machine] = useState({
    oem_id: getOem(),
    id: "",
    machine_name: "",
  });
  const [filter_product, setfilter_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });
  const [MDBT,setMDBT] =useState(true);
  const [product,setProduct] = useState([]);
  const [machine_product, setmachine_product] = useState([]);
  const [product_select,setProduct_select] = useState([]);
  const [machine_select,setmachine_select] = useState({
    machine:"",
    machine_id:"",
  });
  const GetProduct = async () => {
   
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product,
    })
      .then(async (response) => {
        console.log(response.data)
        //setProduct([]);
        for(let item of response.data){
          var check = product_select.filter((e)=>{
            return e.product_id === item.id
          });
          if(check.length > 0){
            item.isPush = true;
          }else{
            item.isPush = false;
          }
      
        }
        //console.log(response.data)

         setProduct(response.data);
         setMDBT(true);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetMachine = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/machine/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_machine,
    })
      .then(function (response) {
        //console.log(response.data);
        setmachine(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  useEffect(() => {
    GetMachine();
    GetProduct();
    GetMachineProduct();
    GetCompany();
  }, []);

  const clearFilter = async () => {
    setfilter_machine({
      ...filter_machine,
      machine_name:"",
    });
   
    filter_machine.machine_name = "";
    GetMachine();
   
  };


  const rowsData = [];
  
  for (var index = 0; index < machine.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["machine_name"] = machine[index].machine_name;
    
    var list_product = "";
    for(let item of machine_product){
      //console.log(item)
      if(item.machine_id === machine[index].id){
        list_product += item.no +" ,";
      }
    }

    rowItem["product"] = list_product;
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
          data-toggle="modal"
          data-target="#modal-product"
              //href={"/Warehouse_Management/Factory_Master_Data/Project/edit/"+ project[index].id}
             onClick={form.bind(this,machine[index].id,machine[index].machine_name)} 
             key={machine[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Machine Name",
        field: "machine_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product",
        field: "product",
        sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  };
  const Product_data = [];
  for(var index = 0; index < product.length;index++){
    const rowItem = {};

    rowItem["no"] = index +1 ;
    rowItem["id"] = product[index].id;
    rowItem["product_no"] = product[index].no;
    rowItem["product_name"] = product[index].name;
    rowItem["mgt"] = (
      <>
      {product[index].isPush === true ? (
          <>
          <div className="col-12">
                  <button
                    type="button"
                    id = {product[index].id}
                    onClick={removeProduct.bind(this)}
                    class="btn btn-block btn-danger "
                  >
                    Remove
                  </button>
                </div>
        </>
      ):(
        <>
        <div className="col-12">
        <button
          type="button"
          id = {product[index].id}
          onClick={pushProduct.bind(this)}
          class="btn btn-block btn-primary "
        >
          Push
        </button>
      </div>
      </>
      )}
     
      </>
    );
    Product_data.push(rowItem);
  }

  const TableProduct = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
       label: "Product Name",
       field: "product_name",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Product_data,
  };
  const clearFilter_product = async () => {
    setfilter_product({
     ...filter_product,
     no: "",
     name: "",
     product_model_id: "",
     oem_id: getOem()
   }); 
   filter_product.no = "";
   filter_product.name = "";
   filter_product.product_model_id = "";
  
   GetProduct();
  
 }; 

 function pushProduct(e){
  const product_select_temp = product_select;
    var data = product.filter((element)=>{ 
      return (element.id === e.target.id);
    })
    const product_p = product;
    setProduct([]);
    for(let i of product_p){
      if(i.id === e.target.id){
        i.isPush = true;
      }
    }
    setProduct(product_p);
    //console.log(product_select_temp);
    var checked = product_select_temp.filter((e2)=>{
      return e2.id === data[0].id;
    })
    //console.log(checked)
    if(checked.length > 0){
      console.log("ซ้ำ")
    }else{
      var temp = {
          product_id:data[0].id
      }
      product_select_temp.push(temp);
    }
     setProduct_select([]);
     setProduct_select(product_select_temp);
     settest({...test,test:""});
     test.test = "";
  }

  function removeProduct(e){
    //console.log(e.target.id);
    const product_p = product;
    setProduct([]);
    for(let i of product_p){
      if(i.id === e.target.id){
        i.isPush = false;
      }
    }
    setProduct(product_p);
    const product_select_temp = product_select;
    setProduct_select([]);
    let indexOf = product_select_temp.findIndex((element)=> element.product_id ===  e.target.id) 
    product_select_temp.splice(indexOf, 1);
    setProduct_select(product_select_temp);
    settest({...test,test:""});
     test.test = "";
    //console.log(data[0]);    
  }

  function form(machine_id,machine,e){
    //console.log(machine_id,machine,e)
    clearFilter_product();

    setmachine_select({...machine_select,machine:machine,machine_id:machine_id});
    GetProductByMachine(machine_id);
    //GetProduct();
   
  }

  function saveOption(){
    let temp = {
      machine_id:machine_select.machine_id,
      product_select:product_select,

    }
    console.log(temp)
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/addMachineProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        if (response.data) {
          Swal.fire({
            icon: "success",
            title: "Save",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            window.location.href = "/Warehouse_Management/Factory_Master_Data/Machine_And_FG";
          });
        }

        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
    setProduct_select([]);
    setmachine_select({...machine_select,machine:"",machine_id:""});
 
    machine_select.machine = "";
    machine_select.machine_id = "";

   
  }
  function GetMachineProduct(){
    axios({
      method: "get",
      url: Configs.API_URL + "/factoryParameter/GetMachineProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        console.log(response.data)
        setmachine_product(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  }
  function GetProductByMachine(id){
    axios({
      method: "get",
      url: Configs.API_URL + "/factoryParameter/getProductByMachine?id="+id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    
    })
      .then(function (response) {
         axios({
          method: "post",
          url: Configs.API_URL + "/product/filter",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: filter_product,
        })
          .then(async (response2) => {
            console.log(response.data)
            
            setProduct_select(response.data);
            //setProduct([]);
            for(let item of response2.data){
              var check = response.data.filter((e)=>{
                return e.product_id === item.id
              });
              if(check.length > 0){
                item.isPush = true;
              }else{
                item.isPush = false;
              }
          
            }
            console.log(response.data)
    
             setProduct(response2.data);
             setMDBT(true);
          })
          .catch(async (error) => {
            console.log(error);
          });
         


        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.message, "error");
      });
  }

  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Machine & FG</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Machine & FG</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filter_machine.machine_name.trim()}
                    onChange={(e) => {
                      setfilter_machine({
                        ...filter_machine,
                        machine_name: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Name</label>{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetMachine();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
             {/*  <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    //onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div> */}
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Machine & FG</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>


        <div className="modal fade" id="modal-product">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Machine {">>>"} {machine_select.machine}</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput">


                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">

                            <div className="col-12">
                              <div className="row">
                              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.no.trim()}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        no: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Product No</label>{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.name.trim()}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        name: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                      
                                    onClick={() =>{
                                      setMDBT(false);
                                      GetProduct();
                                      //setMDBT(true);
                                      }}
                                  >
                                    Search
                                  </button>
                                </div>
                                </div>

                                <div className="col-md-3">
                            <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                          
                                    onClick={()=>{
                                      setMDBT(false);
                                      clearFilter_product();
                                      //setMDBT(true);
                                      
                                    }
                                     }
                                  >
                                    Clear
                                  </button>
                                </div>
                                </div>
                               




                              </div>
                              <br />

                    

                              <div
                                className="table-responsive"
                                style={{ height: "500px" }}
                              >
                                <MDBDataTable
                                sortable = {false}
                                  className="table table-head-fixed"
                                  //paging={false}
                                  disableRetreatAfterSorting={MDBT}
                                  
                                  onPageChange={(page)=>{
                                    console.log(page)
                                  }}
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={TableProduct}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h3>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                        <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal"
                            onClick={saveOption}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
        {/* /.content */}
      </div>
   {/*   <Footter/> */}
     </div>
     
  );
}

export default Machine_FG;
