import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";

import axios from "axios";
import Configs from "../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import { MDBDataTable } from "mdbreact";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import Customer_Cute_Product from "./Component/Customer_Cute_Product";

function AEV_Customer_Cuteplus({ mode }) {
  const validThai = new RegExp(`[\u0E00-\u0E7F]`);
  const validEng = new RegExp(`[a-zA-Z]`);

  const { id } = useParams();

  const cusTab2Active = useRef(null);

  const [pageMode, setPageMode] = useState("");

  const [isDisabled, setIsDisabled] = useState(false);

  const [inputData, setInputData] = useState({
    cus_ct_id: "",
    customer_id: "",
    customer_code: "",
    customer_prefix: "",
    customer_name: "",
    distribution_channel: "",
    channel_type: "",
    customer_address: {
      house_number: "",
      building: "",
      group: "",
      alley: "",
      road: "",
      district: "",
      county: "",
      province: "",
      zip_code: "",
    },
    tax_number: "",
    office_address: {
      house_number: "",
      building: "",
      group: "",
      alley: "",
      road: "",
      district: "",
      county: "",
      province: "",
      zip_code: "",
    },
    tel: "",
    mail: "",
    payment_term: "",
    term: "",
    remark: "",
    branch_code: "",
    branch_name: "",
  });

  const [inputContactData, setInputContactData] = useState({
    data: {
      contact_id: "",
      contact_name: "",
      contact_mail: "",
      contact_tel: "",
      contact_category: "",
      category_name: "",
      contact_position: "",
      contact_gp: "",
      oldNew: "new",
      status: "add",
      check: true,
    },
  });

  const [inputSellerData, setInputSellerData] = useState({
    data: {
      seller_id: "",
      seller_name: "",
      seller_code: "",
      seller_tel: "",
      seller_mail: "",
      oldNew: "new",
      status: "add",
      check: true,
    },
  });

  const [inputDeliveryData, setInputDeliveryData] = useState({
    data: {
      delivery_id: "",
      place_name: "",
      house_number: "",
      building: "",
      group: "",
      alley: "",
      road: "",
      district: "",
      county: "",
      province: "",
      zip_code: "",
      contact_name: "",
      contact_tel: "",
      oldNew: "new",
      status: "add",
      check: true,
    },
  });

  const [inputContactList, setInputContactList] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [inputSellerList, setInputSellerList] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [inputDeliveryList, setInputDeliveryList] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [inputDataTab2, setInputDataTab2] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [errorListTab2, setErrorListTab2] = useState({
    data: [],
  });

  const [functionSaveTab2, setFunctionSaveTab2] = useState({
    data: null,
  });

  const [prefixData, setPrefixData] = useState({
    data: [],
  });

  const [customerTypeData, setCustomerTypeData] = useState({
    data: [],
  });

  const [distributionChannelData, setDistributionChannelData] = useState({
    data: [],
    distribution: [],
    channel: [],
  });

  const [paymentTermData, setPaymentTermData] = useState({
    data: [],
  });

  const [categoryData, setCategoryData] = useState({
    data: [],
  });

  const [userData, setUserData] = useState({
    data: [],
  });

  const [modalContact, setModalContact] = useState(false);
  const [modalSeller, setModalSeller] = useState(false);
  const [modalDelivery, setModalDelivery] = useState(false);

  const rowsData1 = [];
  for (let index = 0; index < inputContactList.data.length; index += 1) {
    const rowItem1 = {};

    rowItem1["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem1["contact_Name"] = inputContactList.data[index].contact_name || "-";

    rowItem1["contact_Tel"] = inputContactList.data[index].contact_tel || "-";

    rowItem1["category"] = inputContactList.data[index].category_name || "-";

    rowItem1["position"] = inputContactList.data[index].contact_position || "-";

    rowItem1["GP_Standard"] = inputContactList.data[index].contact_gp || "-";

    rowItem1["management"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        <div style={{ padding: "0px 10px 0px 20px" }}>
          <a
            // key={selectProductData.data[index].product_id}
            // id={selectProductData.data[index].product_id}
            className="btn btn-xs"
            onClick={() => {
              setInputContactData({
                ...inputContactData,
                data: {
                  ...inputContactData.data,
                  contact_id: inputContactList.data[index].contact_id,
                  contact_name: inputContactList.data[index].contact_name,
                  contact_mail: inputContactList.data[index].contact_mail,
                  contact_tel: inputContactList.data[index].contact_tel,
                  contact_category:
                    inputContactList.data[index].contact_category,
                  category_name: inputContactList.data[index].category_name,
                  contact_position:
                    inputContactList.data[index].contact_position,
                  contact_gp: inputContactList.data[index].contact_gp,
                  oldNew: inputContactList.data[index].oldNew,
                  status: "update",
                  check: true,
                },
              });

              setModalContact(true);
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div style={{ padding: "0px 10px 0px 20px" }}>
          <a
            // key={selectProductData.data[index].product_id}
            // id={selectProductData.data[index].product_id}
            className="btn btn-xs"
            onClick={async () => {
              inputContactList.data[index].check = false;
              inputContactList.data[index].status = "del";

              await saveContactData();
            }}
          >
            <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
          </a>
        </div>
      </div>
    );

    rowsData1.push(rowItem1);
  }

  const data1 = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อผู้ติดต่อ",
        field: "contact_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรติดต่อ",
        field: "contact_Tel",
        // sort: "asc",
        width: 50,
      },
      {
        label: "หมวดหมู่",
        field: "category",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ตำแหน่ง",
        field: "position",
        // sort: "asc",
        width: 50,
      },
      {
        label: "GP Standard (%)",
        field: "GP_Standard",
        // sort: "asc",
        width: 50,
      },
      {
        label: "การจัดการ",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData1,
  };

  const rowsData2 = [];
  for (let index = 0; index < inputSellerList.data.length; index += 1) {
    const rowItem2 = {};

    rowItem2["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem2["seller_Name"] = inputSellerList.data[index].seller_name || "-";

    rowItem2["seller_Tel"] = inputSellerList.data[index].seller_tel || "-";

    rowItem2["seller_email"] = inputSellerList.data[index].seller_mail || "-";

    rowItem2["management"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        <div style={{ padding: "0px 10px 0px 20px" }}>
          <a
            // key={selectProductData.data[index].product_id}
            // id={selectProductData.data[index].product_id}
            className="btn btn-xs"
            onClick={() => {
              setInputSellerData({
                ...inputSellerData,
                data: {
                  ...inputSellerData.data,
                  seller_id: inputSellerList.data[index].seller_id,
                  seller_name: inputSellerList.data[index].seller_name,
                  seller_code: inputSellerList.data[index].seller_code,
                  seller_tel: inputSellerList.data[index].seller_tel,
                  seller_mail: inputSellerList.data[index].seller_mail,
                  oldNew: inputSellerList.data[index].oldNew,
                  status: "update",
                  check: true,
                },
              });

              setModalSeller(true);
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div style={{ padding: "0px 10px 0px 20px" }}>
          <a
            // key={selectProductData.data[index].product_id}
            // id={selectProductData.data[index].product_id}
            className="btn btn-xs"
            onClick={async () => {
              inputSellerList.data[index].check = false;
              inputSellerList.data[index].status = "del";

              await saveSellerData();
            }}
          >
            <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
          </a>
        </div>
      </div>
    );

    rowsData2.push(rowItem2);
  }

  const data2 = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อผู้ดูแล",
        field: "seller_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทรติดต่อ",
        field: "seller_Tel",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Email",
        field: "seller_email",
        // sort: "asc",
        width: 50,
      },
      {
        label: "การจัดการ",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData2,
  };

  const rowsData3 = [];
  for (let index = 0; index < inputDeliveryList.data.length; index += 1) {
    const rowItem3 = {};

    rowItem3["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem3["address_Name"] = inputDeliveryList.data[index].place_name || "-";

    rowItem3["address2"] =
      inputDeliveryList.data[index].house_number ||
      inputDeliveryList.data[index].building ||
      inputDeliveryList.data[index].group ||
      inputDeliveryList.data[index].alley ||
      inputDeliveryList.data[index].road ||
      inputDeliveryList.data[index].district ||
      inputDeliveryList.data[index].county ||
      inputDeliveryList.data[index].zip_code
        ? `${inputDeliveryList.data[index].house_number || ''} ` +
          `${inputDeliveryList.data[index].building || ''} ` +
          `${inputDeliveryList.data[index].group || ''} ` +
          `${inputDeliveryList.data[index].alley || ''} ` +
          `${inputDeliveryList.data[index].road || ''} ` +
          `${inputDeliveryList.data[index].district || ''} ` +
          `${inputDeliveryList.data[index].county || ''} ` +
          `${inputDeliveryList.data[index].zip_code || ''} `
        : "-";

    rowItem3["contact2"] = inputDeliveryList.data[index].contact_name || "-";

    rowItem3["Tel2"] = inputDeliveryList.data[index].contact_tel || "-";

    rowItem3["management"] = (
      <div
        className="row"
        style={{
          flexWrap: "nowrap",
          justifyContent: "center",
        }}
      >
        <div style={{ padding: "0px 10px 0px 20px" }}>
          <a
            // key={selectProductData.data[index].product_id}
            // id={selectProductData.data[index].product_id}
            className="btn btn-xs"
            onClick={() => {
              setInputDeliveryData({
                ...inputDeliveryData,
                data: {
                  ...inputDeliveryData.data,
                  delivery_id: inputDeliveryList.data[index].delivery_id,
                  place_name: inputDeliveryList.data[index].place_name,
                  house_number: inputDeliveryList.data[index].house_number,
                  building: inputDeliveryList.data[index].building,
                  group: inputDeliveryList.data[index].group,
                  alley: inputDeliveryList.data[index].alley,
                  road: inputDeliveryList.data[index].road,
                  district: inputDeliveryList.data[index].district,
                  county: inputDeliveryList.data[index].county,
                  province: inputDeliveryList.data[index].province,
                  zip_code: inputDeliveryList.data[index].zip_code,
                  contact_name: inputDeliveryList.data[index].contact_name,
                  contact_tel: inputDeliveryList.data[index].contact_tel,
                  oldNew: inputDeliveryList.data[index].oldNew,
                  status: "update",
                  check: true,
                },
              });

              setModalDelivery(true);
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>

        <div style={{ padding: "0px 10px 0px 20px" }}>
          <a
            // key={selectProductData.data[index].product_id}
            // id={selectProductData.data[index].product_id}
            className="btn btn-xs"
            onClick={async () => {
              inputDeliveryList.data[index].check = false;
              inputDeliveryList.data[index].status = "del";

              await saveDeliveryData();
            }}
          >
            <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
          </a>
        </div>
      </div>
    );

    rowsData3.push(rowItem3);
  }

  const data3 = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อสถานที่",
        field: "address_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ที่อยู่",
        field: "address2",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ผู้ติดต่อ",
        field: "contact2",
        // sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์โทร",
        field: "Tel2",
        // sort: "asc",
        width: 50,
      },
      {
        label: "การจัดการ",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData3,
  };

  if (isDisabled) {
    const index1 = data1.columns.findIndex((e) => {
      return e.field === "management";
    });

    const index2 = data2.columns.findIndex((e) => {
      return e.field === "management";
    });

    const index3 = data3.columns.findIndex((e) => {
      return e.field === "management";
    });

    data1.columns.splice(index1, 1);
    data2.columns.splice(index2, 1);
    data3.columns.splice(index3, 1);
  }

  async function GetUserSales() {
    let tempData = {
      company_id: getUser().com,
      // oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/users/getUserSales",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setUserData({
          ...userData,
          data: response.data,
        });
        userData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetPrefix() {
    await axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/customer/getPrefix",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setPrefixData({
          ...prefixData,
          data: response.data,
        });
        prefixData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCustomerType() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        setCustomerTypeData({
          ...customerTypeData,
          data: response.data,
        });
        customerTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetDistributionChannel() {
    await axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/customer/getDistributionChannel",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const tempRes = response.data;

        const distributionData = tempRes.filter((item) => {
          return item.dc_level === "1";
        });

        setDistributionChannelData({
          ...distributionChannelData,
          data: tempRes,
          distribution: distributionData,
        });

        distributionChannelData.data = tempRes;
        distributionChannelData.distribution = distributionData;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetPaymentTerm() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getPaymentTerm",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setPaymentTermData({
          ...paymentTermData,
          data: response.data,
        });

        paymentTermData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function GetCategory() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCategoryData({
          ...categoryData,
          data: response.data,
        });

        categoryData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveContactData() {
    const findCheckTrue = inputContactList.data.filter((item) => {
      return item.check === true;
    });

    const addNew = inputContactList.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = inputContactList.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = inputContactList.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setInputContactList({
      ...inputContactList,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    inputContactList.data = findCheckTrue;
    inputContactList.newData = addNew;
    inputContactList.updateData = updateOld;
    inputContactList.delData = delOld;
  }

  async function saveSellerData() {
    const findCheckTrue = inputSellerList.data.filter((item) => {
      return item.check === true;
    });

    const addNew = inputSellerList.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = inputSellerList.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = inputSellerList.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setInputSellerList({
      ...inputSellerList,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    inputSellerList.data = findCheckTrue;
    inputSellerList.newData = addNew;
    inputSellerList.updateData = updateOld;
    inputSellerList.delData = delOld;
  }

  async function saveDeliveryData() {
    const findCheckTrue = inputDeliveryList.data.filter((item) => {
      return item.check === true;
    });

    const addNew = inputDeliveryList.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = inputDeliveryList.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = inputDeliveryList.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setInputDeliveryList({
      ...inputDeliveryList,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    inputDeliveryList.data = findCheckTrue;
    inputDeliveryList.newData = addNew;
    inputDeliveryList.updateData = updateOld;
    inputDeliveryList.delData = delOld;
  }

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const error_list = [];

        if (
          inputData.cus_ct_id === null ||
          inputData.cus_ct_id?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือกประเภทลูกค้า",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_code === null ||
          inputData.customer_code?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก รหัสลูกค้า",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_name === null ||
          inputData.customer_name?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก ชื่อลูกค้า",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.distribution_channel === null ||
          inputData.distribution_channel?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือก Distribution Channel",
          };
          error_list.push(temp_err);
        }

        if (
          (inputData.channel_type === null ||
            inputData.channel_type?.trim() === "") &&
          distributionChannelData.channel.length !== 0
        ) {
          let temp_err = {
            message: "กรุณาเลือก Channel Type",
          };
          error_list.push(temp_err);
        }

        /* if (
          inputData.customer_address.house_number === null ||
          inputData.customer_address.house_number?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก บ้านเลขที่ ของที่อยู่",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_address.building === null ||
          inputData.customer_address.building?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก อาคาร ของที่อยู่",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_address.group === null ||
          inputData.customer_address.group?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก หมู่ ของที่อยู่",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_address.alley === null ||
          inputData.customer_address.alley?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก ซอย ของที่อยู่",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_address.road === null ||
          inputData.customer_address.road?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก ถนน ของที่อยู่",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_address.district === null ||
          inputData.customer_address.district?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก แขวง/ตำบล ของที่อยู่",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_address.county === null ||
          inputData.customer_address.county?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก เขต/อำเภอ ของที่อยู่",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.customer_address.province === null ||
          inputData.customer_address.province?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก จังหวัด ของที่อยู่",
          };
          error_list.push(temp_err);
        } 

        if (
          inputData.customer_address.zip_code &&
          inputData.customer_address.zip_code?.trim().length !== 5
        ) {
          let temp_err = {
            message: "กรุณากรอก รหัสไปรษณีย์ ของที่อยู่ ให้ครบ 5 หลัก",
          };
          error_list.push(temp_err);
        }*/

        if (
          inputData.office_address.house_number === null ||
          inputData.office_address.house_number?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก บ้านเลขที่ ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        }

        /* if (
          inputData.office_address.building === null ||
          inputData.office_address.building?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก อาคาร ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.office_address.group === null ||
          inputData.office_address.group?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก หมู่ ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.office_address.alley === null ||
          inputData.office_address.alley?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก ซอย ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.office_address.road === null ||
          inputData.office_address.road?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก ถนน ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        } */

        if (
          inputData.office_address.district === null ||
          inputData.office_address.district?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก แขวง/ตำบล ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.office_address.county === null ||
          inputData.office_address.county?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก เขต/อำเภอ ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.office_address.province === null ||
          inputData.office_address.province?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก จังหวัด ของที่อยู่สำนักงาน",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.office_address.zip_code === null ||
          inputData.office_address.zip_code?.trim().length !== 5
        ) {
          let temp_err = {
            message: "กรุณากรอก รหัสไปรษณีย์ ของที่อยู่สำนักงาน ให้ครบ 5 หลัก",
          };
          error_list.push(temp_err);
        }
        if (
          inputData.tel === null ||
          inputData.tel?.trim().length <= 0
        ) {
          let temp_err = {
            message: "กรุณากรอก เบอร์โทรศัพท์",
          };
          error_list.push(temp_err);
        }
        // if (inputData.mail === null || inputData.mail?.trim() === "") {
        //   let temp_err = {
        //     message: "กรุณากรอก Email",
        //   };
        //   error_list.push(temp_err);
        // }

        if (
          inputData.tax_number === null ||
          inputData.tax_number?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณากรอก เลขกำกับภาษี หรือหมายเลขผู้เสียภาษี",
          };
          error_list.push(temp_err);
        }

        if (
          inputData.payment_term === null ||
          inputData.payment_term?.trim() === ""
        ) {
          let temp_err = {
            message: "กรุณาเลือก Payment Term",
          };
          error_list.push(temp_err);
        }

        if (inputData.term === null || inputData.term?.trim() === "") {
          let temp_err = {
            message: "กรุณากรอก Term",
          };
          error_list.push(temp_err);
        }

        await functionSaveTab2.data();
        error_list.push(...errorListTab2.data);

        if (error_list.length > 0) {
          let temp_err = {
            message: `- Tab Customer Add -`,
          };
          error_list.unshift(temp_err);

          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          if (mode === "add") {
            await setDefaultInputDeliveryData();
          }
          // Check Tab active
          const tab2 = document.getElementById("tab-2")
          if (tab2.className.includes("active")) {
            // console.log('tab2.className', tab2.className);
            sessionStorage.setItem("cusTab2Active", true);
          }
          // ********************
          let axiosData = {};

          const temp = {
            tab1: inputData,
            tab1_1: inputContactList,
            tab1_2: inputSellerList,
            tab1_3: inputDeliveryList,
            tab2: inputDataTab2,
            user_id: getUser().fup,
            user_name: `${getUser().name} ${getUser().surname}`,
            company_id: getUser().com,
            oem_id: getOem(),
          };

          if (mode === "add") {
            axiosData = {
              method: "post",
              url: Configs.API_URL_cuteplus + "/api/customer/addCustomer",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };
          }

          if (mode === "edit") {
            axiosData = {
              method: "patch",
              url: Configs.API_URL_cuteplus + "/api/customer/updateCustomer",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            };
          }

          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            await axios(axiosData)
              .then(function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus/edit/" + response.data;
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  async function GetCustomerData() {
    let tempData = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      customer_id: id,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerByID",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then((response) => {
        let distributionChannel = null;
        const channelType = distributionChannelData.data.find((item) => {
          return (
            item.dc_level === "2" &&
            item.dc_id === response.data.customer[0].cus_dc_id
          );
        });

        if (channelType) {
          distributionChannel = distributionChannelData.distribution.find(
            (item) => {
              return item.dc_id === channelType.dc_parent_id;
            }
          );

          const channelData = distributionChannelData.data.filter((item) => {
            return item.dc_parent_id === distributionChannel.dc_id;
          });

          setDistributionChannelData({
            ...distributionChannelData,
            channel: channelData,
          });

          distributionChannelData.channel = channelData;
        } else {
          distributionChannel = distributionChannelData.distribution.find(
            (item) => {
              return item.dc_id === response.data.customer[0].cus_dc_id;
            }
          );
        }

        setInputData({
          ...inputData,
          cus_ct_id: response.data.customer[0].cus_ct_id,
          customer_id: response.data.customer[0].cus_id,
          customer_code: response.data.customer[0].cus_no,
          customer_prefix: response.data.customer[0].cus_prefix_id,
          customer_name: response.data.customer[0].cus_name,
          distribution_channel: distributionChannel
            ? distributionChannel.dc_id
            : "",
          channel_type: channelType ? channelType.dc_id : "",
          customer_address: {
            house_number: response.data.customer[0].cus_house_number,
            building: response.data.customer[0].cus_building,
            group: response.data.customer[0].cus_swine,
            alley: response.data.customer[0].cus_soi,
            road: response.data.customer[0].cus_road,
            district: response.data.customer[0].cus_sub_district,
            county: response.data.customer[0].cus_district,
            province: response.data.customer[0].cus_province,
            zip_code: response.data.customer[0].cus_postcode,
          },
          tax_number: response.data.customer[0].cus_tax_no,
          office_address: {
            house_number: response.data.customer[0].cus_branch_house_number,
            building: response.data.customer[0].cus_branch_building,
            group: response.data.customer[0].cus_branch_swine,
            alley: response.data.customer[0].cus_branch_soi,
            road: response.data.customer[0].cus_branch_road,
            district: response.data.customer[0].cus_branch_sub_district,
            county: response.data.customer[0].cus_branch_district,
            province: response.data.customer[0].cus_branch_province,
            zip_code: response.data.customer[0].cus_branch_postcode,
          },
          tel: response.data.customer[0].cus_tel?.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "$1-$2-$3"
          ),
          mail: response.data.customer[0].cus_email,
          payment_term: response.data.customer[0].cus_pt_id,
          term: response.data.customer[0].cus_term,
          remark: response.data.customer[0].cus_remark,
          branch_code: response.data.customer[0].cus_branch_no,
          branch_name: response.data.customer[0].cus_branch,
        });

        const contactList = [];
        for (let item of response.data.contractPoint) {
          let category_name = categoryData.data.find((catItem) => {
            return catItem.cat_id === item.ccp_cat_id;
          });

          contactList.push({
            contact_id: item.ccp_id,
            contact_name: item.ccp_name,
            contact_mail: item.ccp_email,
            contact_tel: item.ccp_tel?.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "$1-$2-$3"
            ),
            contact_category: item.ccp_cat_id,
            category_name: category_name.cat_name,
            contact_position: item.ccp_position,
            contact_gp: item.ccp_gp_standard,
            oldNew: "old",
            status: "add",
            check: true,
          });
        }

        setInputContactList({
          ...inputContactList,
          data: contactList,
        });

        inputContactList.data = contactList;

        const sellerList = [];
        for (let item of response.data.sales) {
          sellerList.push({
            seller_id: item.cs_id,
            seller_name: item.cs_name,
            seller_code: item.cs_code,
            seller_tel: item.cs_tel?.replace(
              /(\d{3})(\d{3})(\d{4})/,
              "$1-$2-$3"
            ),
            seller_mail: item.cs_email,
            oldNew: "old",
            status: "add",
            check: true,
          });
        }

        setInputSellerList({
          ...inputSellerList,
          data: sellerList,
        });

        inputSellerList.data = sellerList;

        const deliveryList = [];
        for (let item of response.data.ship) {
          deliveryList.push({
            delivery_id: item.csh_id,
            place_name: item.csh_name,
            house_number: item.csh_house_number,
            building: item.csh_building,
            group: item.csh_swine,
            alley: item.csh_soi,
            road: item.csh_road,
            district: item.csh_sub_district,
            county: item.csh_district,
            province: item.csh_province,
            zip_code: item.csh_postcode,
            contact_name: item.csh_conteact_name,
            contact_tel:
              item.csh_tel?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") ||
              null,
            oldNew: "old",
            status: "add",
            check: true,
          });
        }

        setInputDeliveryList({
          ...inputDeliveryList,
          data: deliveryList,
        });

        inputDeliveryList.data = deliveryList;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href =
            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus";
        }
      });
    } else {
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus";
    }
  }

  async function setDefaultInputDeliveryData() {
    const dataList = inputDeliveryList.data;
    setInputDeliveryData({
      data: {
        delivery_id: dataList.length + 1,
        place_name: inputData.customer_name || null,
        house_number: inputData.office_address.house_number || null,
        building: inputData.office_address.building || null,
        group: inputData.office_address.group || null,
        alley: inputData.office_address.alley || null,
        road: inputData.office_address.road || null,
        district: inputData.office_address.district || null,
        county: inputData.office_address.county || null,
        province: inputData.office_address.province || null,
        zip_code: inputData.office_address.zip_code || null,
        contact_name: "",
        contact_tel: "",
        oldNew: "new",
        status: "add",
        check: true,
      },
    });
    inputDeliveryData.data.delivery_id = dataList.length + 1;
    inputDeliveryData.data.place_name = inputData.customer_name || null;
    inputDeliveryData.data.house_number =
      inputData.office_address.house_number || null;
    inputDeliveryData.data.building = inputData.office_address.building || null;
    inputDeliveryData.data.group = inputData.office_address.group || null;
    inputDeliveryData.data.alley = inputData.office_address.alley || null;
    inputDeliveryData.data.road = inputData.office_address.road || null;
    inputDeliveryData.data.district = inputData.office_address.district || null;
    inputDeliveryData.data.county = inputData.office_address.county || null;
    inputDeliveryData.data.province = inputData.office_address.province || null;
    inputDeliveryData.data.zip_code = inputData.office_address.zip_code || null;
    inputDeliveryData.data.contact_name = "";
    inputDeliveryData.data.contact_tel = "";
    inputDeliveryData.data.oldNew = "new";
    inputDeliveryData.data.status = "add";
    inputDeliveryData.data.check = true;

    dataList.push(inputDeliveryData.data);

    setInputDeliveryList({
      ...inputDeliveryList,
      data: dataList,
    });

    inputDeliveryList.data = dataList;

    await saveDeliveryData();
  }

  useEffect(async () => {
    if (sessionStorage.getItem("cusTab2Active")) {
      cusTab2Active.current.click();
    }
    await GetUserSales();
    await GetPrefix();
    await GetCustomerType();
    await GetDistributionChannel();
    await GetPaymentTerm();
    await GetCategory();

    if (mode === "add") {
      setPageMode("Add");
      setIsDisabled(false);
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
      await GetCustomerData();
    } else if (mode === "view") {
      setPageMode("Read");
      setIsDisabled(true);
      await GetCustomerData();
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-sm-4">
                <h1>Customer</h1>
              </div>
              <div className="col-sm-8">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Customer</li>
                  <li className="breadcrumb-item active">
                    Customer {pageMode}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={async () => {
                      await cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={async () => {
                      await saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={async () => {
                      await cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Customer Code : {inputData.customer_code}
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Customer Name : {inputData.customer_name}
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id={"tab-1"}
                          data-toggle="pill"
                          href={"#Customer_Add-tab"}
                          role="tab"
                          aria-controls={"Customer_Add-tab"}
                          aria-selected="true"
                          onClick={() => {
                            sessionStorage.removeItem("cusTab2Active");
                          }}
                        >
                          Customer Add
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id={"tab-2"}
                          data-toggle="pill"
                          href={"#Customer_Product-tab"}
                          role="tab"
                          aria-controls={"Customer_Product-tab"}
                          aria-selected="false"
                          ref={cusTab2Active}
                          onClick={() => {
                            sessionStorage.setItem("cusTab2Active", true);
                          }}
                        >
                          Customer Product
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-four-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id={"Customer_Add-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-1"}
                      >
                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Customer Detail
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                              <select
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.cus_ct_id}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    cus_ct_id: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  -- กรุณาเลือก --
                                </option>

                                {customerTypeData.data.map((data, index) => {
                                  return (
                                    <option key={index} value={data.ct_id}>
                                      {data.ct_name}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ประเภทลูกค้า
                                {inputData.cus_ct_id === "" ||
                                inputData.cus_ct_id === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                // decimalScale={0}
                                // allowNegative={false}
                                // allowLeadingZeros={true}
                                disabled={isDisabled}
                                value={inputData.customer_code}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_code: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                รหัสลูกค้า
                                {inputData.customer_code?.trim() === "" ||
                                inputData.customer_code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.branch_code}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    branch_code: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                รหัสสาขา
                                {/* {inputData.branch_code?.trim() === "" ||
                                  inputData.branch_code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                // decimalScale={0}
                                // allowNegative={false}
                                // allowLeadingZeros={true}
                                disabled={isDisabled}
                                value={inputData.branch_name}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    branch_name: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ชื่อสาขา
                                {/* {inputData.branch_name?.trim() === "" ||
                                  inputData.branch_name === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-4 col-xl-4">
                            <div class="form-group">
                              <select
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_prefix}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_prefix: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  -- กรุณาเลือก --
                                </option>

                                {prefixData.data.map((data, index) => {
                                  return (
                                    <option key={index} value={data.prefix_id}>
                                      {data.prefix_name}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                คำนำหน้า
                                {/* {inputData.customer_prefix === "" ||
                                inputData.customer_prefix === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-8 col-xl-8">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_name}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_name: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ชื่อลูกค้า
                                {inputData.customer_name?.trim() === "" ||
                                inputData.customer_name === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-6">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.distribution_channel}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    distribution_channel: e.target.value,
                                  });

                                  const channelData =
                                    distributionChannelData.data.filter(
                                      (item) => {
                                        return (
                                          item.dc_parent_id === e.target.value
                                        );
                                      }
                                    );

                                  setDistributionChannelData({
                                    ...distributionChannelData,
                                    channel: channelData,
                                  });

                                  distributionChannelData.channel = channelData;
                                }}
                              >
                                <option value="" disabled>
                                  -- กรุณาเลือก --
                                </option>

                                {distributionChannelData.distribution.map(
                                  (data, index) => {
                                    return (
                                      <option key={index} value={data.dc_id}>
                                        {data.dc_name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Distribution Channel
                                {inputData.distribution_channel === "" ||
                                inputData.distribution_channel === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={
                                  isDisabled ||
                                  distributionChannelData.channel.length === 0
                                }
                                value={inputData.channel_type}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    channel_type: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  -- กรุณาเลือก --
                                </option>

                                {distributionChannelData.channel.map(
                                  (data, index) => {
                                    return (
                                      <option key={index} value={data.dc_id}>
                                        {data.dc_name}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Channel Type
                                {distributionChannelData.channel.length !== 0 &&
                                (inputData.channel_type === "" ||
                                  inputData.channel_type === null) ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              ที่อยู่
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-2">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.house_number}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      house_number: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                บ้านเลขที่
                                {inputData.customer_address.house_number ===
                                  "" ||
                                  inputData.customer_address.house_number ===
                                  null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.building}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      building: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                อาคาร
                                {inputData.customer_address.building === "" ||
                                  inputData.customer_address.building === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.group}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      group: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                หมู่
                                {inputData.customer_address.group === "" ||
                                  inputData.customer_address.group === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.alley}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      alley: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ซอย
                                {inputData.customer_address.alley === "" ||
                                  inputData.customer_address.alley === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.road}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      road: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ถนน
                                {inputData.customer_address.road === "" ||
                                  inputData.customer_address.road === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.district}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      district: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                แขวง/ตำบล
                                {inputData.customer_address.district === "" ||
                                  inputData.customer_address.district === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-4">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.county}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      county: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เขต/อำเภอ
                                {inputData.customer_address.county === "" ||
                                  inputData.customer_address.county === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-5">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.customer_address.province}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      province: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                จังหวัด
                                {inputData.customer_address.province === "" ||
                                  inputData.customer_address.province === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                required
                                format={"#####"}
                                decimalScale={0}
                                allowNegative={false}
                                disabled={isDisabled}
                                value={inputData.customer_address.zip_code}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    customer_address: {
                                      ...inputData.customer_address,
                                      zip_code: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                รหัสไปรษณีย์
                                {inputData.customer_address.zip_code === "" ||
                                  inputData.customer_address.zip_code === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                required
                                decimalScale={0}
                                allowNegative={false}
                                disabled={isDisabled}
                                value={inputData.tax_number}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    tax_number: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เลขกำกับภาษี หรือหมายเลขผู้เสียภาษี
                                {inputData.tax_number === "" ||
                                  inputData.tax_number === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              ที่อยู่สำนักงานใหญ่ หรือสาขา
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-2">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.house_number}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      house_number: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                บ้านเลขที่
                                {inputData.office_address.house_number?.trim() ===
                                  "" ||
                                inputData.office_address.house_number ===
                                  null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.building}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      building: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                อาคาร
                                {/* {inputData.office_address.building?.trim() ===
                                  "" ||
                                inputData.office_address.building === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.group}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      group: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                หมู่
                                {/* {inputData.office_address.group?.trim() ===
                                  "" ||
                                inputData.office_address.group === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-2">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.alley}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      alley: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ซอย
                                {/* {inputData.office_address.alley?.trim() ===
                                  "" ||
                                inputData.office_address.alley === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.road}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      road: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ถนน
                                {/* {inputData.office_address.road?.trim() === "" ||
                                inputData.office_address.road === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.district}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      district: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                แขวง/ตำบล
                                {inputData.office_address.district?.trim() ===
                                  "" ||
                                inputData.office_address.district === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-4">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.county}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      county: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เขต/อำเภอ
                                {inputData.office_address.county?.trim() ===
                                  "" ||
                                inputData.office_address.county === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-5">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.office_address.province}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      province: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                จังหวัด
                                {inputData.office_address.province?.trim() ===
                                  "" ||
                                inputData.office_address.province === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-3">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                required
                                format={"#####"}
                                decimalScale={0}
                                allowNegative={false}
                                disabled={isDisabled}
                                value={inputData.office_address.zip_code}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    office_address: {
                                      ...inputData.office_address,
                                      zip_code: e.target.value,
                                    },
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                รหัสไปรษณีย์
                                {inputData.office_address.zip_code?.trim() ===
                                  "" ||
                                inputData.office_address.zip_code === null ||
                                inputData.office_address.zip_code?.trim()
                                  .length !== 5 ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                // format={"###-###-####"}
                                required
                                disabled={isDisabled}
                                value={inputData.tel}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    tel: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เบอร์โทรศัพท์
                                {inputData.tel === "" ||
                                inputData.tel === null ||
                                inputData.tel?.trim()
                                  .length <= 0 ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div class="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.mail}
                                onChange={(e) => {
                                  if (
                                    !validThai.test(
                                      e.target.value.toString().trim()
                                    )
                                  ) {
                                    setInputData({
                                      ...inputData,
                                      mail: e.target.value,
                                    });
                                  }
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                อีเมล
                                {/* {inputData.mail?.trim() === "" ||
                                inputData.mail === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )} */}
                              </label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                required
                                decimalScale={0}
                                allowNegative={false}
                                disabled={isDisabled}
                                value={inputData.tax_number}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    tax_number: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เลขกำกับภาษี หรือหมายเลขผู้เสียภาษี
                                {inputData.tax_number === "" ||
                                inputData.tax_number === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Payment Term
                            </label>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-6">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.payment_term}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    payment_term: e.target.value,
                                  });
                                }}
                              >
                                <option value="" disabled>
                                  -- กรุณาเลือก --
                                </option>

                                {paymentTermData.data.map((data) => {
                                  return (
                                    <option value={data.pt_id}>
                                      {data.pt_name}
                                    </option>
                                  );
                                })}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Payment Term
                                {inputData.payment_term === "" ||
                                inputData.payment_term === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div class="form-group">
                              <NumberFormat
                                type="text"
                                className="form-control"
                                // thousandSeparator={true}
                                // thousandsGroupStyle="thousand"
                                required
                                disabled={isDisabled}
                                allowNegative={false}
                                decimalScale={0}
                                value={inputData.term}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    term: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Term
                                {inputData.term === "" ||
                                inputData.term === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              ผู้ติดต่อ
                            </label>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-6 col-md-3 col-xl-2">
                            <button
                              type="button"
                              class="btn btn-block btn-primary"
                              disabled={isDisabled}
                              onClick={() => {
                                setModalContact(true);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div
                            className="col-md-12"
                            style={{ overflow: "overlay" }}
                          >
                            <div style={{ whiteSpace: "nowrap" }}>
                              <MDBDataTable
                                sortable={false}
                                striped
                                bordered
                                hover
                                data={data1}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              ผู้ดูแล
                            </label>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-6 col-md-3 col-xl-2">
                            <button
                              type="button"
                              class="btn btn-block btn-primary"
                              disabled={isDisabled}
                              onClick={() => {
                                setModalSeller(true);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div
                            className="col-md-12"
                            style={{ overflow: "overlay" }}
                          >
                            <div style={{ whiteSpace: "nowrap" }}>
                              <MDBDataTable
                                sortable={false}
                                striped
                                bordered
                                hover
                                data={data2}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              ที่อยู่จัดส่ง
                            </label>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-6 col-md-3 col-xl-2">
                            <button
                              type="button"
                              class="btn btn-block btn-primary"
                              disabled={isDisabled}
                              onClick={() => {
                                setModalDelivery(true);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div
                            className="col-md-12"
                            style={{ overflow: "overlay" }}
                          >
                            <div style={{ whiteSpace: "nowrap" }}>
                              <MDBDataTable
                                sortable={false}
                                striped
                                bordered
                                hover
                                data={data3}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div class="form-group">
                              <textarea
                                style={{ minHeight: "5rem" }}
                                type="text"
                                className="form-control"
                                required
                                disabled={isDisabled}
                                value={inputData.remark}
                                onChange={(e) => {
                                  setInputData({
                                    ...inputData,
                                    remark: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                หมายเหตุ
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id={"Customer_Product-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-2"}
                      >
                        <Customer_Cute_Product
                          id={id}
                          mode={mode}
                          isDisabled={isDisabled}
                          inputDataTab2={inputDataTab2}
                          setInputDataTab2={setInputDataTab2}
                          errorListTab2={errorListTab2}
                          setErrorListTab2={setErrorListTab2}
                          functionSaveTab2={functionSaveTab2}
                          setFunctionSaveTab2={setFunctionSaveTab2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        size="md"
        className="Modal_Center_LeftRight"
        isOpen={modalContact}
        toggle={() => {
          setInputContactData({
            data: {
              contact_id: "",
              contact_name: "",
              contact_mail: "",
              contact_tel: "",
              contact_category: "",
              category_name: "",
              contact_position: "",
              contact_gp: "",
              oldNew: "new",
              status: "add",
              check: true,
            },
          });

          setModalContact(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setInputContactData({
              data: {
                contact_id: "",
                contact_name: "",
                contact_mail: "",
                contact_tel: "",
                contact_category: "",
                category_name: "",
                contact_position: "",
                contact_gp: "",
                oldNew: "new",
                status: "add",
                check: true,
              },
            });

            setModalContact(false);
          }}
        >
          <h4 className="modal-title" id="modalContrat">
            <b>Add Contact Point</b>
          </h4>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputContactData.data.contact_name}
                  onChange={(e) => {
                    setInputContactData({
                      ...inputContactData,
                      data: {
                        ...inputContactData.data,
                        contact_name: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ชื่อผู้ติดต่อ
                  {inputContactData.data.contact_name?.trim() === "" ||
                  inputContactData.data.contact_name === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputContactData.data.contact_mail}
                  onChange={(e) => {
                    if (!validThai.test(e.target.value.toString().trim())) {
                      setInputContactData({
                        ...inputContactData,
                        data: {
                          ...inputContactData.data,
                          contact_mail: e.target.value,
                        },
                      });
                    }
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  อีเมล
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  // format={"###-###-####"}
                  disabled={isDisabled}
                  value={inputContactData.data.contact_tel}
                  onChange={(e) => {
                    setInputContactData({
                      ...inputContactData,
                      data: {
                        ...inputContactData.data,
                        contact_tel: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  เบอร์โทร
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div class="form-group">
                <select
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputContactData.data.contact_category}
                  onChange={(e) => {
                    setInputContactData({
                      ...inputContactData,
                      data: {
                        ...inputContactData.data,
                        contact_category: e.target.value,
                        category_name:
                          e.target.options[e.target.selectedIndex].text,
                      },
                    });
                  }}
                >
                  <option value="" disabled>
                    -- เลือก --
                  </option>
                  {categoryData.data.map((e) => {
                    return <option value={e.cat_id}>{e.cat_name}</option>;
                  })}
                </select>
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  หมวดหมู่
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputContactData.data.contact_position}
                  onChange={(e) => {
                    setInputContactData({
                      ...inputContactData,
                      data: {
                        ...inputContactData.data,
                        contact_position: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ตำแหน่ง
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div class="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={2}
                  allowLeadingZeros={true}
                  allowNegative={false}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 100;
                  }}
                  disabled={isDisabled}
                  value={inputContactData.data.contact_gp}
                  onChange={(e) => {
                    setInputContactData({
                      ...inputContactData,
                      data: {
                        ...inputContactData.data,
                        contact_gp: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  GP Standard (%)
                  {inputContactData.data.contact_gp === "" ||
                  inputContactData.data.contact_gp === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-between" }}>
          <button
            type="button"
            className="btn btn-danger"
            onClick={async () => {
              setInputContactData({
                data: {
                  contact_id: "",
                  contact_name: "",
                  contact_mail: "",
                  contact_tel: "",
                  contact_category: "",
                  category_name: "",
                  contact_position: "",
                  contact_gp: "",
                  oldNew: "new",
                  status: "add",
                  check: true,
                },
              });

              setModalContact(false);
            }}
          >
            ยกเลิก
          </button>

          <button
            type="button"
            className="btn btn-success"
            onClick={async () => {
              const error_list = [];

              if (
                inputContactData.data.contact_name === null ||
                inputContactData.data.contact_name?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก ชื่อผู้ติดต่อ",
                };
                error_list.push(temp_err);
              } else {
                inputContactData.data.contact_name =
                  inputContactData.data.contact_name?.trim();
                inputContactData.data.contact_mail =
                  inputContactData.data.contact_mail?.trim() || "";
                inputContactData.data.contact_position =
                  inputContactData.data.contact_position?.trim() || "";
              }

              if (
                inputContactData.data.contact_gp === null ||
                inputContactData.data.contact_gp?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก GP Standard",
                };
                error_list.push(temp_err);
              }

              if (error_list.length > 0) {
                var err_message = "";

                for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
                }

                Swal.fire("Error", err_message, "error");
              } else {
                if (
                  inputContactData.data.status === "add" &&
                  inputContactData.data.oldNew === "new"
                ) {
                  const dataList = inputContactList.data;

                  setInputContactData({
                    ...inputContactData,
                    data: {
                      ...inputContactData.data,
                      contact_id: dataList.length + 1,
                    },
                  });

                  inputContactData.data.contact_id = dataList.length + 1;

                  dataList.push(inputContactData.data);

                  setInputContactList({
                    ...inputContactList,
                    data: dataList,
                  });

                  inputContactList.data = dataList;
                } else if (inputContactData.data.status === "update") {
                  if (inputContactData.data.oldNew === "new") {
                    setInputContactData({
                      ...inputContactData,
                      data: {
                        ...inputContactData.data,
                        status: "add",
                      },
                    });

                    inputContactData.data.status = "add";
                  }

                  const dataList = inputContactList.data;

                  const indexData = dataList.findIndex((item) => {
                    return item.contact_id === inputContactData.data.contact_id;
                  });

                  dataList[indexData] = inputContactData.data;

                  setInputContactList({
                    ...inputContactList,
                    data: dataList,
                  });

                  inputContactList.data = dataList;
                }

                setInputContactData({
                  data: {
                    contact_id: "",
                    contact_name: "",
                    contact_mail: "",
                    contact_tel: "",
                    contact_category: "",
                    category_name: "",
                    contact_position: "",
                    contact_gp: "",
                    oldNew: "new",
                    status: "add",
                    check: true,
                  },
                });

                setModalContact(false);

                await saveContactData();
              }
            }}
          >
            บันทึก
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        size="md"
        className="Modal_Center_LeftRight"
        isOpen={modalSeller}
        toggle={() => {
          setInputSellerData({
            data: {
              seller_id: "",
              seller_name: "",
              seller_code: "",
              seller_tel: "",
              seller_mail: "",
              oldNew: "new",
              status: "add",
              check: true,
            },
          });

          setModalSeller(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setInputSellerData({
              data: {
                seller_id: "",
                seller_name: "",
                seller_code: "",
                seller_tel: "",
                seller_mail: "",
                oldNew: "new",
                status: "add",
                check: true,
              },
            });
            setModalSeller(false);
          }}
        >
          <h4 className="modal-title" id="modalCare">
            <b>Add Seller</b>
          </h4>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                {/* <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputSellerData.data.seller_name}
                  onChange={(e) => {
                    setInputSellerData({
                      ...inputSellerData,
                      data: {
                        ...inputSellerData.data,
                        seller_name: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Seller Name
                  {inputData.Supplier_Code === "" ||
                  inputData.Supplier_Code === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label> */}

                <select
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputSellerData.data.seller_name}
                  onChange={(e) => {
                    setInputSellerData({
                      ...inputSellerData,
                      data: {
                        ...inputSellerData.data,
                        seller_name: e.target.value,
                      },
                    });
                  }}
                >
                  <option value="" disabled>
                    -- กรุณาเลือก --
                  </option>

                  {userData.data.map((data, index) => {
                    return (
                      <option key={index} value={data.fullname}>
                        {data.fullname}
                      </option>
                    );
                  })}
                </select>
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ชื่อผู้ดูแล
                  {inputSellerData.data.seller_name === "" ||
                  inputSellerData.data.seller_name === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputSellerData.data.seller_code}
                  onChange={(e) => {
                    setInputSellerData({
                      ...inputSellerData,
                      data: {
                        ...inputSellerData.data,
                        seller_code: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  รหัสผู้ดูแล
                  {inputSellerData.data.seller_code?.trim() === "" ||
                  inputSellerData.data.seller_code === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  // format={"###-###-####"}
                  required
                  disabled={isDisabled}
                  value={inputSellerData.data.seller_tel}
                  onChange={(e) => {
                    setInputSellerData({
                      ...inputSellerData,
                      data: {
                        ...inputSellerData.data,
                        seller_tel: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  เบอร์โทร
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputSellerData.data.seller_mail}
                  onChange={(e) => {
                    if (!validThai.test(e.target.value.toString().trim())) {
                      setInputSellerData({
                        ...inputSellerData,
                        data: {
                          ...inputSellerData.data,
                          seller_mail: e.target.value,
                        },
                      });
                    }
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  อีเมล
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-between" }}>
          <button
            type="button"
            className="btn btn-danger"
            onClick={async () => {
              setInputSellerData({
                data: {
                  seller_id: "",
                  seller_name: "",
                  seller_code: "",
                  seller_tel: "",
                  seller_mail: "",
                  oldNew: "new",
                  status: "add",
                  check: true,
                },
              });

              setModalSeller(false);
            }}
          >
            ยกเลิก
          </button>

          <button
            type="button"
            className="btn btn-success"
            onClick={async () => {
              const error_list = [];
              if (
                (inputSellerData.data.seller_name === null ||
                  inputSellerData.data.seller_name?.trim() === "") &&
                (inputSellerData.data.seller_code === null ||
                  inputSellerData.data.seller_code?.trim() === "")
              ) {
                let temp_err = {
                  message: "กรุณาเลือก Seller Name และ กรอก Seller Code",
                };
                error_list.push(temp_err);
              } else if (
                inputSellerData.data.seller_name === null ||
                inputSellerData.data.seller_name?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณาเลือก Seller Name",
                };
                error_list.push(temp_err);
              } else if (
                inputSellerData.data.seller_code === null ||
                inputSellerData.data.seller_code?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก Seller Code",
                };
                error_list.push(temp_err);
              } else {
                inputSellerData.data.seller_name =
                  inputSellerData.data.seller_name?.trim() || null;
                inputSellerData.data.seller_code =
                  inputSellerData.data.seller_code?.trim() || null;
                inputSellerData.data.seller_tel =
                  inputSellerData.data.seller_tel?.trim() || null;
                inputSellerData.data.seller_mail =
                  inputSellerData.data.seller_mail?.trim() || null;
              }

              if (error_list.length > 0) {
                var err_message = "";

                for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
                }

                Swal.fire("Error", err_message, "error");
              } else {
                if (
                  inputSellerData.data.status === "add" &&
                  inputSellerData.data.oldNew === "new"
                ) {
                  const dataList = inputSellerList.data;

                  setInputSellerData({
                    ...inputSellerData,
                    data: {
                      ...inputSellerData.data,
                      seller_id: dataList.length + 1,
                    },
                  });

                  inputSellerData.data.seller_id = dataList.length + 1;

                  dataList.push(inputSellerData.data);

                  setInputSellerList({
                    ...inputSellerList,
                    data: dataList,
                  });

                  inputSellerList.data = dataList;
                } else if (inputSellerData.data.status === "update") {
                  if (inputSellerData.data.oldNew === "new") {
                    setInputSellerData({
                      ...inputSellerData,
                      data: {
                        ...inputSellerData.data,
                        status: "add",
                      },
                    });

                    inputSellerData.data.status = "add";
                  }

                  const dataList = inputSellerList.data;

                  const indexData = dataList.findIndex((item) => {
                    return item.seller_id === inputSellerData.data.seller_id;
                  });

                  dataList[indexData] = inputSellerData.data;

                  setInputSellerList({
                    ...inputSellerList,
                    data: dataList,
                  });

                  inputSellerList.data = dataList;
                }

                setInputSellerData({
                  data: {
                    seller_id: "",
                    seller_name: "",
                    seller_code: "",
                    seller_tel: "",
                    seller_mail: "",
                    oldNew: "new",
                    status: "add",
                    check: true,
                  },
                });

                setModalSeller(false);

                await saveSellerData(true);
              }
            }}
          >
            บันทึก
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        size="md"
        className="Modal_Center_LeftRight"
        isOpen={modalDelivery}
        toggle={() => {
          setInputDeliveryData({
            data: {
              delivery_id: "",
              place_name: "",
              house_number: "",
              building: "",
              group: "",
              alley: "",
              road: "",
              district: "",
              county: "",
              province: "",
              zip_code: "",
              contact_name: "",
              contact_tel: "",
              oldNew: "new",
              status: "add",
              check: true,
            },
          });

          setModalDelivery(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setInputDeliveryData({
              data: {
                delivery_id: "",
                place_name: "",
                house_number: "",
                building: "",
                group: "",
                alley: "",
                road: "",
                district: "",
                county: "",
                province: "",
                zip_code: "",
                contact_name: "",
                contact_tel: "",
                oldNew: "new",
                status: "add",
                check: true,
              },
            });

            setModalDelivery(false);
          }}
        >
          <h4 className="modal-title" id="modalDelivery">
            <b>เพิ่มที่อยู่จัดส่ง</b>
          </h4>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.place_name}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        place_name: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ชื่อสถานที่
                  {inputDeliveryData.data.place_name?.trim() === "" ||
                  inputDeliveryData.data.place_name === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <label
                style={{
                  fontSize: "24px",
                  lineHeight: "normal",
                  wordBreak: "break-all",
                }}
              >
                ที่อยู่
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.house_number}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        house_number: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  บ้านเลขที่
                  {inputDeliveryData.data.house_number?.trim() === "" ||
                  inputDeliveryData.data.house_number === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.building}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        building: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  อาคาร
                  {/* {inputDeliveryData.data.building?.trim() === "" ||
                  inputDeliveryData.data.building === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )} */}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-3">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.group}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        group: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  หมู่
                  {/* {inputDeliveryData.data.group?.trim() === "" ||
                  inputDeliveryData.data.group === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )} */}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.alley}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        alley: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ซอย
                  {/* {inputDeliveryData.data.alley?.trim() === "" ||
                  inputDeliveryData.data.alley === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )} */}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-5">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.road}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        road: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ถนน
                  {/* {inputDeliveryData.data.road?.trim() === "" ||
                  inputDeliveryData.data.road === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )} */}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-4">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.district}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        district: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  แขวง/ตำบล
                  {inputDeliveryData.data.district?.trim() === "" ||
                  inputDeliveryData.data.district === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.county}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        county: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  เขต/อำเภอ
                  {inputDeliveryData.data.county?.trim() === "" ||
                  inputDeliveryData.data.county === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-8">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.province}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        province: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  จังหวัด
                  {inputDeliveryData.data.province?.trim() === "" ||
                  inputDeliveryData.data.province === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div class="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  format={"#####"}
                  required
                  decimalScale={0}
                  allowNegative={false}
                  disabled={isDisabled}
                  value={inputDeliveryData.data.zip_code}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        zip_code: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  รหัสไปรษณีย์
                  {inputDeliveryData.data.zip_code?.trim() === "" ||
                  inputDeliveryData.data.zip_code === null ||
                  inputDeliveryData.data.zip_code?.trim().length !== 5 ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.contact_name}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        contact_name: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  ผู้ติดต่อ
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div class="form-group">
                <input
                  type="text"
                  className="form-control"
                  // format={"###-###-####"}
                  required
                  disabled={isDisabled}
                  value={inputDeliveryData.data.contact_tel}
                  onChange={(e) => {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        contact_tel: e.target.value,
                      },
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  เบอร์โทร
                  {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                </label>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-between" }}>
          <button
            type="button"
            className="btn btn-danger"
            onClick={async () => {
              setInputDeliveryData({
                data: {
                  delivery_id: "",
                  place_name: "",
                  house_number: "",
                  building: "",
                  group: "",
                  alley: "",
                  road: "",
                  district: "",
                  county: "",
                  province: "",
                  zip_code: "",
                  contact_name: "",
                  contact_tel: "",
                  oldNew: "new",
                  status: "add",
                  check: true,
                },
              });

              setModalDelivery(false);
            }}
          >
            ยกเลิก
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={async () => {
              const error_list = [];

              if (
                inputDeliveryData.data.place_name === null ||
                inputDeliveryData.data.place_name?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก ชื่อสถานที่",
                };
                error_list.push(temp_err);
              }

              if (
                inputDeliveryData.data.house_number === null ||
                inputDeliveryData.data.house_number?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก บ้านเลขที่",
                };
                error_list.push(temp_err);
              }

              /* if (
                inputDeliveryData.data.building === null ||
                inputDeliveryData.data.building?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก อาคาร",
                };
                error_list.push(temp_err);
              } */

              /* if (
                inputDeliveryData.data.group === null ||
                inputDeliveryData.data.group?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก หมู่",
                };
                error_list.push(temp_err);
              } */

              /* if (
                inputDeliveryData.data.alley === null ||
                inputDeliveryData.data.alley?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก ซอย",
                };
                error_list.push(temp_err);
              } */

              /* if (
                inputDeliveryData.data.road === null ||
                inputDeliveryData.data.road?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก ถนน",
                };
                error_list.push(temp_err);
              } */

              if (
                inputDeliveryData.data.district === null ||
                inputDeliveryData.data.district?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก แขวง/ตำบล",
                };
                error_list.push(temp_err);
              }

              if (
                inputDeliveryData.data.county === null ||
                inputDeliveryData.data.county?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก เขต/อำเภอ",
                };
                error_list.push(temp_err);
              }

              if (
                inputDeliveryData.data.province === null ||
                inputDeliveryData.data.province?.trim() === ""
              ) {
                let temp_err = {
                  message: "กรุณากรอก จังหวัด",
                };
                error_list.push(temp_err);
              }

              if (
                inputDeliveryData.data.zip_code === null ||
                inputDeliveryData.data.zip_code?.trim() === "" ||
                inputDeliveryData.data.zip_code?.trim().length !== 5
              ) {
                let temp_err = {
                  message: "กรุณากรอก รหัสไปรษณีย์ 5 หลัก",
                };
                error_list.push(temp_err);
              }

              /* if (
                inputDeliveryData.data.contact_tel !== null &&
                inputDeliveryData.data.contact_tel?.trim() !== ""
              ) {
                if (
                  inputDeliveryData.data.contact_tel?.trim().replace(/-/g, "")
                    .length !== 10
                ) {
                  let temp_err = {
                    message: "กรุณากรอก เบอร์โทร 10 หลัก",
                  };
                  error_list.push(temp_err);
                }
              } */

              if (error_list.length > 0) {
                var err_message = "";

                for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
                }

                Swal.fire("Error", err_message, "error");
              } else {
                inputDeliveryData.data.delivery_id =
                  inputDeliveryData.data.delivery_id?.trim() || null;
                inputDeliveryData.data.place_name =
                  inputDeliveryData.data.place_name?.trim() || null;
                inputDeliveryData.data.house_number =
                  inputDeliveryData.data.house_number?.trim() || null;
                inputDeliveryData.data.building =
                  inputDeliveryData.data.building?.trim() || null;
                inputDeliveryData.data.group =
                  inputDeliveryData.data.group?.trim() || null;
                inputDeliveryData.data.alley =
                  inputDeliveryData.data.alley?.trim() || null;
                inputDeliveryData.data.road =
                  inputDeliveryData.data.road?.trim() || null;
                inputDeliveryData.data.district =
                  inputDeliveryData.data.district?.trim() || null;
                inputDeliveryData.data.county =
                  inputDeliveryData.data.county?.trim() || null;
                inputDeliveryData.data.province =
                  inputDeliveryData.data.province?.trim() || null;
                inputDeliveryData.data.zip_code =
                  inputDeliveryData.data.zip_code?.trim() || null;
                inputDeliveryData.data.contact_name =
                  inputDeliveryData.data.contact_name?.trim() || null;
                inputDeliveryData.data.contact_tel =
                  inputDeliveryData.data.contact_tel
                    ?.trim() || null;
                if (
                  inputDeliveryData.data.status === "add" &&
                  inputDeliveryData.data.oldNew === "new"
                ) {
                  const dataList = inputDeliveryList.data;

                  setInputDeliveryData({
                    ...inputDeliveryData,
                    data: {
                      ...inputDeliveryData.data,
                      delivery_id: (dataList.length + 1).toString(),
                    },
                  });

                  inputDeliveryData.data.delivery_id = (
                    dataList.length + 1
                  ).toString();

                  dataList.push(inputDeliveryData.data);

                  setInputDeliveryList({
                    ...inputDeliveryList,
                    data: dataList,
                  });

                  inputDeliveryList.data = dataList;
                } else if (inputDeliveryData.data.status === "update") {
                  if (inputDeliveryData.data.oldNew === "new") {
                    setInputDeliveryData({
                      ...inputDeliveryData,
                      data: {
                        ...inputDeliveryData.data,
                        status: "add",
                      },
                    });

                    inputDeliveryData.data.status = "add";
                  }

                  const dataList = inputDeliveryList.data;

                  const indexData = dataList.findIndex((item) => {
                    return (
                      item.delivery_id === inputDeliveryData.data.delivery_id
                    );
                  });

                  dataList[indexData] = inputDeliveryData.data;

                  setInputDeliveryList({
                    ...inputDeliveryList,
                    data: dataList,
                  });

                  inputDeliveryList.data = dataList;
                }

                setInputDeliveryData({
                  data: {
                    delivery_id: "",
                    place_name: "",
                    house_number: "",
                    building: "",
                    group: "",
                    alley: "",
                    road: "",
                    district: "",
                    county: "",
                    province: "",
                    zip_code: "",
                    contact_name: "",
                    contact_tel: "",
                    oldNew: "new",
                    status: "add",
                    check: true,
                  },
                });

                setModalDelivery(false);

                await saveDeliveryData(true);
              }
            }}
          >
            บันทึก
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AEV_Customer_Cuteplus;
