import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import Tree from "./TEST/TreeNode";

import {
  getOem,
  getToken,
  getUser,
  getFeature,
  fixOEMForSST,
  getAcademy
} from "../../Utils/Common";

function TestLogic(params) {
  const [test,sesttest] = useState({
    test:"",
  })
  const [dynamicChild, setdynamicChild] = useState([]);
  
  useEffect(() => {
    var array1 = [];
    for(let i =0; i < 5; i++){
      let temp ={
        id:"id_"+(i+1),
        name:"testLV.1__"+(i+1),
        child:[],
      }
      /* for(let i2 =0; i2 < 5; i2++){
        let temp2 ={
          id:"id2_"+(i2+1),
          name:"testLV.2__"+(i2+1),
          child:[],
        }
        for(let i3 =0; i3 < 5; i3++){
          let temp3 ={
            id:"id3_"+(i3+1),
            name:"testLV.3__"+(i3+1),
            child:[],
          }
          temp2.child.push(temp3);
        }
        temp.child.push(temp2);
      } */
      array1.push(temp);
    }
    setdynamicChild(array1);







  }, []);


function addLv_1(){
  var tempData = dynamicChild;
  let temp = {
    id:"id_"+parseInt(dynamicChild.length+1),
    name:"testLV.1__"+parseInt(dynamicChild.length+1),
    child:[],
  }
  tempData.push(temp);
  setdynamicChild(tempData);
  sesttest({...test,test:""});
  test.test = "";
}

function addLv_2(index){
  var tempData = dynamicChild;
  console.log(tempData[index])
  
  let temp = {
    id:"id2_"+parseInt(tempData[index].child.length+1),
    name:"testLV.2__"+parseInt(tempData[index].child.length+1),
    child:[],
  }
  tempData[index].child.push(temp);
  setdynamicChild(tempData);
  sesttest({...test,test:""});
  test.test = "";
}


const treeData = [
 /*  {key:"0",
    label1:"",
    label2:"        โครงสร้างองค์กร        ",
    label3:"",
    icon:"fa fa-folder",
    title: "Doc",
    children :[ */
  {
    key:"1",
    label1:"บริษัท",
    label2:"บริษัท ทีทีที บราเธอร์ส จำกัด",
    label3:"TTT",
    icon:"fa fa-folder",
    title: "Doc",
    children :[
      { key:"2",
      label1:"สำนักงานสาขา",
      label2:"สาขาบางโพ",
      label3:"178",
      icon:"fa fa-folder",
      title: "Doc",
      children :[
        { key:"2.1",
        label1:"แผนก",
        label2:"TranformTranformTranformTranform",
        label3:"642",
        icon:"fa fa-folder",
        title: "Doc",},
        { key:"2.2",
        label1:"แผนก",
        label2:"TimelyTimelyTimely",
        label3:"643",
        icon:"fa fa-folder",
        title: "Doc",},
        { key:"2.3",
        label1:"แผนก",
        label2:"Trailblazer",
        label3:"644",
        icon:"fa fa-folder",
        title: "Doc",},
      ]
    },
      { key:"3",
      label1:"สำนักงานสาขา",
      label2:"สาขาลาดกระบัง",
      label3:"178",
      icon:"fa fa-folder",
      title: "Doc",    
    },
    { key:"3",
    label1:"สำนักงานสาขา",
    label2:"สำนักงานใหญ่",
    label3:"B000001",
    icon:"fa fa-folder",
    title: "Doc",
   
  },
    ]
  }
/* ]
} */
]


  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="customer" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Customer{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Customer</li>
                </ol>
              </div>
            </div>
          </div>

        
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">TEST LOGIC <button className="btn-primary" onClick={addLv_1}>+LV.1</button></h3>
              <div className="card-tools"></div>
            </div>
            
            
            <p className="mt-3 ml-5">
              <div className="row mt-3  ">

              <Tree data = {treeData} />
            
              </div>
         
          </p>
        
              
              <br/>






            <ul style={{"list-style-type":"circle"}}>
              {dynamicChild.map((lv1,index1) => {return (
            <>
            <li>{lv1.name} <button className="btn-primary" onClick={()=>{addLv_2(index1);}}>+LV.2</button></li> 
            {lv1.child.length > 0 ? (
              <>
               <ul style={{"list-style-type":"circle"}}>
              {lv1.child.map((lv2,index2)=>{return (
                <><li>{lv2.name}</li>
                {lv2.child.length > 0 ? (
              <>
               <ul style={{"list-style-type":"circle"}}>
              {lv2.child.map((lv3,index3)=>{return (
                <><li>{lv3.name}</li>
                
                 </>
              )})}
              </ul>
              </>

            ):(<></>)}
                 </>
              )})}
              </ul>
              </>

            ):(<></>)}
     

            </>
              )})}

</ul>
  {/* <li>Coffee</li> 
  <ul style={{"list-style-type":"circle"}}>
  <li>Coffee</li>
  <li>Tea</li>
  <li>Milk</li>
</ul>
  <li>Tea</li>
  <li>Milk</li>
</ul>

<ul style={{"list-style-type":"disc"}}>
  <li>Coffee</li>
  <li>Tea</li>
  <li>Milk</li>
</ul>

<ul style={{"list-style-type":"square"}}>
  <li>Coffee</li>
  <li>Tea</li>
  <li>Milk</li>
</ul> */}
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default TestLogic;
