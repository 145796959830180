import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";

import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";

function System_Configuraion_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [disabletype, setdisabletype] = useState(false);

  const [system_config, set_system_config] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    // persiod_name:"",
    // day_hour_start:"",
    // day_hour_start_value:"",
    // rate_time_absent:"",
    // rate_time_absent_value:"",
    begin_time: "",
    finish_time: "",
    break_time_minue: "",
    ratio: "",
    extra_budget: "",
    time_to_late: "",
    period_time_name: "",
    parent_period_time_id: "",
    sort: "",
    time_working_id: "",
    special_budget: "",
  });
  const [type_emp, set_type_emp] = useState([]);
  const [type_master_time, set_type_master_time] = useState([]);

  const GetemployeeTyer = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    console.log("get_com_oem", get_com_oem);
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        set_type_emp(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetMasterEmployeeType = async () => {
    let get_com_oem = {};
    console.log("get_com_oem", get_com_oem);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/getMastertimeWorkingType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        set_type_master_time(response.data.data);
        console.log(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

  const getview = async () => {
    if (mode.mode == "read" || mode.mode == "edit") {
      axios({
        method: "get",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/hrManagement/getConfigPeriodTimeByID?id=" +
          id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          if (
            response.data.data.begin_time === null ||
            response.data.data.finish_time === null
          ) {
            setdisabletype(true);
          } else {
            setdisabletype(false);
          }

          if (
            response.data.data.begin_time !== null &&
            response.data.data.finish_time !== null
          ) {
            let ss = response.data.data.begin_time.split(".");
            let fn = response.data.data.finish_time.split(".");

            if (ss[0].length < 2) {
              if (ss[0].length < 2 && ss[1].length < 2) {
                response.data.data.begin_time = "0" + ss[0] + ss[1] + "0";
              } else if (ss[0].length === 2 && ss[1].length < 2) {
                response.data.data.begin_time = ss[0] + ss[1] + "0";
              } else {
                response.data.data.begin_time = "0" + ss[0] + ss[1];
              }
            } else {
              response.data.data.begin_time = response.data.data.begin_time;
            }
            if (fn[0].length < 2) {
              if (fn[0].length < 2 && fn[1].length < 2) {
                response.data.data.finish_time = "0" + fn[0] + fn[1] + "0";
              } else if (fn[0].length === 2 && fn[1].length < 2) {
                response.data.data.finish_time = fn[0] + fn[1] + "0";
              } else {
                response.data.data.finish_time = "0" + fn[0] + fn[1];
              }
            } else {
              response.data.data.finish_time = response.data.data.finish_time;
            }
          }

          set_system_config({
            period_id: id,
            oem_id: response.data.data.oem_id,
            company_id: response.data.data.company_id,
            begin_time: response.data.data.begin_time,
            finish_time: response.data.data.finish_time,
            break_time_minue: response.data.data.break_time_minue,
            ratio: response.data.data.ratio,
            extra_budget: response.data.data.extra_budget,
            time_to_late: response.data.data.time_to_late,
            period_time_name: response.data.data.period_time_name,
            parent_period_time_id: response.data.data.parent_period_time_id,
            time_working_id: response.data.data.time_working_id,
            special_budget: response.data.data.special_budget,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  console.log(system_config);
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetCompany();
    GetemployeeTyer();
    GetMasterEmployeeType();
    getview();
    //console.log("id:"+id);
    // GetProject_view();
  }, []);

  function saveOption(params) {
    //console.log(Number(system_config.extra_budget.replace(/,/g,"")),);
    // console.log(Number(system_config.begin_time));
    const error_list = [];
    if (system_config.period_time_name.trim() == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [ชื่อช่วงงาน].",
      };
      error_list.push(temp_err);
    }
    if (system_config.time_working_id == "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [ประเภท].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.parent_period_time_id == "" && disabletype === false) ||
      (system_config.parent_period_time_id == null && disabletype === false)
    ) {
      let temp_err = {
        message: "Please enter information in the fields >>> [ประเภทหลัก].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.begin_time == "" && disabletype === false) ||
      (system_config.begin_time == null && disabletype === false)
    ) {
      let temp_err = {
        message:
          "Please enter information in the fields >>> [ชั่วโมงเริ่มต้น].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.time_to_late == "" && disabletype === false) ||
      (system_config.time_to_late == null && disabletype === false)
    ) {
      let temp_err = {
        message: "Please enter information in the fields >>> [สาย].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.finish_time == "" && disabletype === false) ||
      (system_config.finish_time == null && disabletype === false)
    ) {
      let temp_err = {
        message: "Please enter information in the fields >>> [ชั่วโมงสิ้นสุด].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.break_time_minue == "" && disabletype === false) ||
      (system_config.break_time_minue == null && disabletype === false)
    ) {
      let temp_err = {
        message: "Please enter information in the fields >>> [พัก (นาที)].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.ratio == "" && disabletype === false) ||
      (system_config.ratio == null && disabletype === false)
    ) {
      let temp_err = {
        message: "Please enter information in the fields >>> [อัตราส่วน].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.extra_budget == "" && disabletype === false) ||
      (system_config.extra_budget == null && disabletype === false)
    ) {
      let temp_err = {
        message: "Please enter information in the fields >>> [เงินบวกเพิ่ม].",
      };
      error_list.push(temp_err);
    }
    if (
      (system_config.special_budget == "" && disabletype === true) ||
      (system_config.special_budget == null && disabletype === true)
    ) {
      let temp_err = {
        message: "Please enter information in the fields >>> [ค่าเข้ากะ].",
      };
      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    } else {
      if (mode.mode === "add") {
        const add_system_config = {
          oem_id: system_config.oem_id,
          company_id: system_config.company_id,
          begin_time: system_config.begin_time || null,
          finish_time: system_config.finish_time || null,
          break_time_minue: system_config.break_time_minue || null,
          ratio: system_config.ratio || null,
          extra_budget: system_config.extra_budget || null,
          time_to_late: system_config.time_to_late || null,
          period_time_name: system_config.period_time_name,
          parent_period_time_id: system_config.parent_period_time_id || null,
          time_working_id: system_config.time_working_id || null,
          special_budget: system_config.special_budget || null,
        };

        if (
          system_config.begin_time === "" ||
          system_config.finish_time === ""
        ) {
          setdisabletype(true);
        } else {
          setdisabletype(false);
        }

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement +
              "/api/hrManagement/addConfigPeriodTime",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: add_system_config,
          })
            .then(function (response) {
              console.log(add_system_config);
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data";
                });
              }
            })
            .catch(function (error) {
              /* console.log(error); */
              Swal.fire({
                icon: "warning",
                title: "กรุณากรอกข้อมูลใหม่",
                text: "มี ชื่อช่วงงาน ซ้ำกันในระบบ",
              });
            });
        });
      }
      if (mode.mode === "edit") {
        setdisabletype(false);
        const edit_system_config = {
          period_id: id,
          oem_id: system_config.oem_id,
          company_id: system_config.company_id,
          begin_time: system_config.begin_time || 0,
          finish_time: system_config.finish_time || 0,
          break_time_minue: system_config.break_time_minue,
          ratio: system_config.ratio,
          extra_budget: system_config.extra_budget,
          time_to_late: system_config.time_to_late,
          period_time_name: system_config.period_time_name,
          parent_period_time_id: system_config.parent_period_time_id || null,
          time_working_id: system_config.time_working_id,
          special_budget: system_config.special_budget,
        };
        if (
          system_config.begin_time === null ||
          system_config.finish_time === null
        ) {
          setdisabletype(true);
        } else {
          setdisabletype(false);
        }

        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement +
              "/api/hrManagement/updateConfigPeriodTime?id=" +
              id,
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: edit_system_config,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data";
                });
              }

              //console.log(response.data);
            })
            .catch(function (error) {
              /* console.log(error); */
              Swal.fire({
                icon: "warning",
                title: "กรุณากรอกข้อมูลใหม่",
                text: "มี ชื่อช่วงงาน ซ้ำกันในระบบ",
              });
            });
        });
      }
    }
  }

  function chcek_box(params) {
    if (disabletype === false) {
      setdisabletype(true);
      system_config.begin_time = "";
      system_config.finish_time = "";
      system_config.break_time_minue = "";
      system_config.ratio = "";
      system_config.extra_budget = "";
      system_config.time_to_late = "";
      system_config.parent_period_time_id = "";
    } else {
      setdisabletype(false);
      system_config.special_budget = "";
    }
  }

  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Human_Resource/ตั้งค่า/Company_Time_Attendance_Data";
      }
    });
  }
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Company Time Attendance Data {pageMode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Company Time Attendance Data
                  </li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    id="btn_cancle1"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    id="btn_save"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    id="btn_cancle"
                    if
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header col">
              <h3 className="card-title col-3">
                Company Time Attendance Data {pageMode}
              </h3>
              {pageMode === "Add" ? (
                <div class="custom-control custom-switch col-2">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="is_fifo"
                    disabled={disable}
                    onChange={(event) => {
                      chcek_box(event);
                    }}
                  />
                  <label class="custom-control-label " for="is_fifo">
                    ประเภทหลัก
                  </label>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="text_name_work"
                      required
                      value={system_config.period_time_name}
                      disabled={disable}
                      onChange={(e) => {
                        set_system_config({
                          ...system_config,
                          period_time_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      ชื่อช่วงงาน
                      {system_config.period_time_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <select
                      className="form-control custom-select select2"
                      type="text"
                      required
                      id="drop_select_type"
                      disabled={disable}
                      value={system_config.time_working_id}
                      onChange={(e) => {
                        set_system_config({
                          ...system_config,
                          time_working_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Select time working Type
                      </option>
                      {type_master_time.map((el) => {
                        return <option value={el.id}>{el.work_name}</option>;
                      })}
                    </select>
                    <label>
                      ประเภท
                      {/* {employee.emp_name_th.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                {disabletype === true ? (
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        // meter_oil
                        placeholder="0"
                        type="text"
                        className="form-control"
                        id="text_money_plus"
                        //format={"##:##"}
                        allowNegative={false}
                        decimalScale={2}
                        //thousandSeparator={true}
                        required
                        value={system_config.special_budget}
                        disabled={disable || !disabletype}
                        onChange={(e) => {
                          set_system_config({
                            ...system_config,
                            special_budget: e.target.value,
                          });
                        }}
                      />
                      <label>
                        ค่าเข้ากะ
                        {system_config.special_budget === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {disabletype === false ? (
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        // meter_oil
                        placeholder="0"
                        type="text"
                        className="form-control"
                        id="text_money_plus"
                        //format={"##:##"}
                        allowNegative={false}
                        decimalScale={2}
                        //thousandSeparator={true}
                        required
                        value={system_config.extra_budget}
                        disabled={disable || disabletype}
                        onChange={(e) => {
                          set_system_config({
                            ...system_config,
                            extra_budget: e.target.value,
                          });
                        }}
                      />
                      <label>
                        เงินบวกเพิ่ม
                        {system_config.extra_budget === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {disabletype === false ? (
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        // meter_oil
                        type="text"
                        placeholder="00.00"
                        className="form-control"
                        allowNegative={false}
                        format={"##.##"}
                        id="drop_begin_time"
                        required
                        value={system_config.begin_time}
                        disabled={disable || disabletype}
                        // onChange={(e) => {
                        //   set_system_config({
                        //     ...system_config,
                        //     begin_time: e.target.value,
                        //   });
                        // }}
                        onChange={(e) => {
                          console.log(e.target.value);
                          const myArray = e.target.value.split(".");

                          console.log(myArray);
                          if (myArray[0] > 23 || myArray[1] > 59) {
                            set_system_config({
                              ...system_config,
                              begin_time: "23.59",
                            });
                          } else {
                            set_system_config({
                              ...system_config,
                              begin_time: e.target.value,
                            });
                          }
                        }}
                      />
                      <label>
                        ชั่วโมงเริ่มต้น
                        {system_config.begin_time === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {disabletype === false ? (
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        // meter_oil
                        type="text"
                        placeholder="00.00"
                        id="drop_finish_time"
                        className="form-control"
                        format={"##.##"}
                        allowNegative={false}
                        required
                        value={system_config.finish_time}
                        disabled={disable || disabletype}
                        // onChange={(e) => {
                        //   set_system_config({
                        //     ...system_config,
                        //     finish_time: e.target.value,
                        //   });
                        // }}
                        onChange={(e) => {
                          console.log(e.target.value);
                          const myArray = e.target.value.split(".");

                          console.log(myArray);
                          if (myArray[0] > 23 || myArray[1] > 59) {
                            set_system_config({
                              ...system_config,
                              finish_time: "23.59",
                            });
                          } else {
                            set_system_config({
                              ...system_config,
                              finish_time: e.target.value,
                            });
                          }
                        }}
                      />
                      <label>
                        ชั่วโมงสุดท้าย
                        {system_config.finish_time === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {disabletype === false ? (
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        // meter_oil
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        id="text_late"
                        decimalScale={0}
                        required
                        value={system_config.time_to_late}
                        disabled={disable || disabletype}
                        onChange={(e) => {
                          set_system_config({
                            ...system_config,
                            time_to_late: e.target.value,
                          });
                        }}
                      />
                      <label>
                        สาย
                        {system_config.time_to_late === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {disabletype === false ? (
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        // meter_oil
                        type="text"
                        className="form-control"
                        id="text_breack_time"
                        allowNegative={false}
                        decimalScale={0}
                        required
                        value={system_config.break_time_minue}
                        disabled={disable || disabletype}
                        onChange={(e) => {
                          set_system_config({
                            ...system_config,
                            break_time_minue: e.target.value,
                          });
                        }}
                      />
                      <label>
                        พัก(นาที)
                        {system_config.break_time_minue === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {disabletype === false ? (
                  <div className="col-12 col-md-6 col-xl-4">
                    <div class="form-group">
                      <NumberFormat
                        // meter_oil
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        id="text_ratio"
                        decimalScale={2}
                        required
                        value={system_config.ratio}
                        disabled={disable || disabletype}
                        onChange={(e) => {
                          set_system_config({
                            ...system_config,
                            ratio: e.target.value,
                          });
                        }}
                      />
                      <label>
                        อัตราส่วน
                        {system_config.ratio === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>{" "}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {disabletype === false ? (
                  <div className="col-6 col-md-6 col-xl-4">
                    <div class="form-group">
                      <select
                        className="form-control custom-select select2"
                        type="text"
                        required
                        id="drop_select_type"
                        disabled={disable}
                        value={system_config.parent_period_time_id}
                        onChange={(e) => {
                          set_system_config({
                            ...system_config,
                            parent_period_time_id: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Select Employee Type
                        </option>
                        {type_emp.map((el) => {
                          return (
                            <option value={el.period_time_id}>
                              {el.period_time}
                            </option>
                          );
                        })}
                      </select>
                      <label>
                        ประเภทหลัก
                        {system_config.parent_period_time_id === "" ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                {/*           { disabletype === false && pageMode === "Add" ? (
              <div className="col-6 col-md-6 col-xl-4">
                  <div class="form-group">
                
                  <select className="form-control custom-select select2"
                   type="text"
                   required
                   id = "drop_select_type"
                   disabled={disable}
                   value={system_config.parent_period_time_id}
                   onChange={(e) => {
                    set_system_config({
                      ...system_config,
                      parent_period_time_id: e.target.value,
                    });
                   }}
                  >
                     <option value=""  disabled selected>
                      Select Employee Type
                    </option>
                     {type_emp.map((el) => {
                      return (
                      <option value={el.period_time_id}>{el.period_time}
                      </option>
                      );
                    })} 
                   </select>
                    <label>
                    ประเภทหลัก
                    {system_config.parent_period_time_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                  ) : (
                    <>
                    </>
                    )} */}
              </div>
            </div>
          </div>
        </section>
      </div>
      {/*  <Footter/> */}
    </div>
  );
}

export default System_Configuraion_add;
