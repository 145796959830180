
var io;
var socketMap = new Map();
init();

function init(namespaces = [], option) {
	if (option) {
	}
	io = require('socket.io-client');
}

var setting = {};
setting.query = {
	token: '',
};

/* 
โค้ดนี้เป็นส่วนหนึ่งของฟังก์ชัน setup ที่กำหนดค่าการเชื่อมต่อ Socket.IO ไปยังเซิร์ฟเวอร์ โดยมีหลักการทำงานดังนี้:

	1. **การกำหนดค่าเริ่มต้น:**
		- กำหนดการตั้งค่าสำหรับการเชื่อมต่อ Socket.IO เช่น พารามิเตอร์ของคิวรี่, การเลือกใช้โปรโตคอลการสื่อสาร (transports) เช่น WebSocket และ polling, และตัวเลือกการเชื่อมต่ออื่น ๆ ที่เฉพาะเจาะจงตามความต้องการของการเชื่อมต่อนั้น ๆ

	2. **การเชื่อมต่อไปยังเซิร์ฟเวอร์:**
		- ใช้การตั้งค่าที่ระบุเพื่อเชื่อมต่อกับเซิร์ฟเวอร์ที่ระบุโดย `server_url` และ `namespace`

	3. **เหตุการณ์ของ Socket:**
		- ฟังก์ชันที่จัดการกับเหตุการณ์ต่าง ๆ ของ Socket เช่น 'connect', 'disconnect', 'error', 'reconnect', 'reconnect_attempt', 'reconnect_error', 'reconnect_failed', และ 'ping'
		- จัดการกับกระบวนการหรือบันทึกข้อความเมื่อเกิดเหตุการณ์เหล่านี้ เช่น บันทึกสถานะการเชื่อมต่อ, พยายามเชื่อมต่อใหม่, ข้อผิดพลาด และช่วงเวลาการ ping

	4. **การรับฟังเหตุการณ์ที่กำหนดเอง:**
		- กำหนดการรับฟังเหตุการณ์ที่กำหนดเอง เช่น 'clientBox' และ 'message' บน socket ซึ่งสามารถปรับแต่งตามความต้องการของแอปพลิเคชัน

	5. **การส่งค่า Socket กลับ:**
		- ส่งคืนออบเจกต์ของ socket ที่กำหนดค่าแล้ว ทำให้สามารถทำงานกับการเชื่อมต่อที่เปิดไว้นอกฟังก์ชัน setup ได้ต่อไป

	โดยรวมแล้วฟังก์ชันนี้ทำการเชื่อมต่อไปยังเซิร์ฟเวอร์ Socket.IO ด้วยการตั้งค่าที่ระบุและกำหนดพฤติกรรมในการจัดการกับเหตุการณ์ต่าง ๆ ที่อาจเกิดขึ้นระหว่างการทำงานของ socket ได้อย่างเหมาะสม
*/
function setup(server_url = 'http://localhost:3001', path = '/', namespace = '/', cb, option = null) {
	let lastPing = Date.now();
	setting.path = path;
	setting.transports = ['websocket', 'polling']; //use WebSocket first, if available

	if (option) {
		if (option.transports) setting.transports = option.transports; //["websocket", 'polling']
		if (option.query) setting.query = option.query; //{ 'token': 'xxxxx'}, //string only
		if (option.upgrade) setting.upgrade = option.upgrade; //false
		if (option.withCredentials) setting.withCredentials = option.withCredentials;
		if (option.extraHeaders) setting.extraHeaders = option.extraHeaders; //{"my-custom-header": "abcd"}
		if (option.secure) setting.secure = option.secure; //true
		if (option.reconnection) setting.reconnection = option.reconnection; //true
		if (option.rejectUnauthorized) setting.rejectUnauthorized = option.rejectUnauthorized; //false
		if (option.reconnectionDelay) setting.reconnectionDelay = option.reconnectionDelay; //1000
		if (option.reconnectionDelayMax) setting.reconnectionDelayMax = option.reconnectionDelayMax; //5000
		if (option.reconnectionAttempts) setting.reconnectionAttempts = option.reconnectionAttempts; //Infinity
	}

	console.log(setting);

	let socket = io(server_url + namespace, setting);

	socket.on('connect', function () {
		console.log('...Connecting as:', socket.id);
		socketMap.set(socket.id, socket);
		//console.log(socketMap);
		var topic = 'serverBox';
		var data = {};
		data.str = 'name';
		data.int = 1;
		cb(socket); //callback function
	});

	socket.on('disconnect', function (reason) {
		console.log('disconnect: ' + reason);
		//socket.removeAllListeners();
		socket.off('clientBox'); //close custom listener

		if (reason === 'io server disconnect') {
			// the disconnection was initiated by the server, you need to reconnect manually
			socket.connect();
		}
		// else the socket will automatically try to reconnect
	});

	socket.io.on('error', function (error) {
		//Fired upon a connection error.
		console.log('error: ' + error);
	});

	socket.io.on('reconnect', function (attempt) {
		//Fired upon a successful reconnection.
		console.log('reconnect: ' + attempt);
		//cb(socket); //callback function
	});

	socket.io.on('reconnect_attempt', function (attempt) {
		//Fired upon an attempt to reconnect.
		console.log('reconnect_attempt: ' + attempt);
	});

	socket.io.on('reconnect_error', function (error) {
		//Fired upon a reconnection attempt error.
		console.log('reconnect_error: ' + error);
	});

	socket.io.on('reconnect_failed', function () {
		//Fired when couldn't reconnect within reconnectionAttempts.
		console.log('reconnect_failed: ');
	});

	socket.io.on('ping', () => {
		//Fired when a ping packet is received from the server.
		let currentPing = Date.now();
		const pingInterval = currentPing - lastPing;
		lastPing = currentPing;

		let data = {};
		data.socketId = socket.id;
		data.pongTime = currentPing;

		socket.emit('pong', data);
		console.log('pingInterval(ms): ', pingInterval);
	});

	// default custom message(client side)
	socket.on('clientBox', function (data) {
		//console.log('['+socket.id+']: ' + data);
		//console.dir(data, {depth:null, colors:true});
	});

	// default message(client side)
	socket.on('message', function (data) {
		//console.log('['+socket.id+']: ' + data);
		console.dir(data, { depth: null, colors: true });
	});

	return socket;
}

function getSocketId(socket) {
	return socket.id;
}

//sending <data> to socket server on <topic>
function send(topic, data, socket) {
	if (topic) {
		socket.emit(topic, data);
	} else {
		socket.emit('serverBox', data);
	}
}

//secure join to room
function joinSecure(room, username, password, socket) {
	console.log('joinSecure');
	socket.emit('/join_secure', { room: room, username: username, password: password });
}

//joining to toom
function join(room, socket) {
	socket.emit('/join', room);
}

//leaving to toom
function leave(room, socket) {
	if (socket)
		socket.emit('/leave', room);
}

//sending to all clients in 'game' room(channel) except sender
function broadcastToRoom(room, data, socket) {
	//room = 'room1' or ['room1', 'room2']
	socket.emit('/broadcastToRoom', { room: room, data: data });
}

//sending to all clients in room(channel) include sender
function sendToRoom(room, data, socket) {
	socket.emit('/sendToRoom', { room: room, data: data });
}

//to all clients in room1 and/or room2 except those in room3
function sendToRoomExcept(room, except, data, socket) {
	socket.emit('/sendToRoomExcept', { room: room, except: except, data: data });
}

//to all clients in namespace "myNamespace"
function sendToNamespace(namespace, data, socket) {
	socket.emit('/sendToNamespace', { namespace: namespace, data: data });
}

//to all clients in room1 in namespace "myNamespace"
function sendToRoomInNamespace(namespace, room, data, socket) {
	socket.emit('/sendToRoomInNamespace', { namespace: namespace, room: room, data: data });
}

//to individual socketid (private message)
function sendToSocket(socketId, data, topic = 'clientBox', socket) {
	socket.emit('/sendToSocket', { socketId: socketId, data: data, topic: topic });
}

//to all clients on this node (when using multiple nodes)
function sendToLocal(data, socket) {
	socket.emit('/sendToLocal', { data: data });
}

//to all connected clients
function sendToAll(data, socket) {
	socket.emit('/sendToAll', { data: data });
}

//register username
function register(username = null, socket) {
	if (username) {
		socket.emit('/register', { customId: username });
	} else {
		socket.emit('/register', { customId: socket.id });
	}
}

function unRegister(username = null, socket) {
	if (username) {
		socket.emit('/unRegister', { customId: username });
	} else {
		socket.emit('/unRegister', { customId: socket.id });
	}
}

function getSocketById(socketId, username, socket, topic = 'clientBox') {
	socket.emit('getSocketId', { socketId: socketId, username: username, topic: topic });
}

var methods = {};
methods.setup = setup;
methods.register = register;
methods.send = send;
methods.join = join;
methods.leave = leave;
methods.broadcastToRoom = broadcastToRoom;
methods.sendToRoom = sendToRoom;
methods.sendToRoomExcept = sendToRoomExcept;
methods.sendToNamespace = sendToNamespace;
methods.sendToRoomInNamespace = sendToRoomInNamespace;
methods.sendToSocket = sendToSocket;
methods.sendToLocal = sendToLocal;
methods.sendToAll = sendToAll;
methods.joinSecure = joinSecure;

methods.getSocketId = getSocketId;

//exports.methods = methods;
module.exports = methods;
