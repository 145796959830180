import React from "react";
import Construccomponent from "../../component/Construction";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
function QCM(params) {
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="qc" activemenu="qcm" submenu="none" /> */}
      <Construccomponent name="Quanlity Control(Manual)" />
   {/*    <Footter /> */}
    </div>
  );
}
export default QCM;
