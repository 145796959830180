import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import { BrowserRouter, Link, Route } from "react-router-dom";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import GoogleMapReact from "google-map-react";


//import pin from "../../assets/img/map/pin.png";
function End_user_add(mode) {
  const [company, setCompany] = useState([]);
  const [pageMode, setPageMode] = useState("");
  const [disable, setdisable] = useState(null);
  const [filter_product, setfilter_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });
  const [test, settest] = useState({
    test: "",
  });
  const [MDBT,setMDBT] =useState(true);
  const [end_user, setend_user] = useState({
    id:"",
    name: "",
    full_name: "",
    contact: "",
    tel:"",
    address: "",
    latitude: "",
    longitude: "",
    is_use:"",
    email:"",
    line_id:"",
    oem_id: getOem(),
  });
  const [product,setProduct] = useState([]);
  const [product_select,setProduct_select] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    if (mode.mode === "read") {
      setdisable(true);
      setPageMode("Read");
    } else if (mode.mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else {
      setdisable(false);
      setPageMode("Edit");
    }
  }, []);

 

  
  useEffect(() => {
    GetProduct();
    if(mode.mode === "read" || mode.mode === "edit"){
      GetEnd_user_view();
      setdefault_center({...default_center,lat:end_user.latitude,lng:end_user.longitude});
    }
  /*   console.log("id:"+id); */
    /* GetEnd_user_view(); */
  }, []);


  const GetEnd_user_view = async () => {
     
    if ( mode.mode =="read" || mode.mode =="edit"  ) {
      axios({
        method: "get",
        url: Configs.API_URL + "/end_user/findById?id="+ id,
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
      })
        .then(function (response) {
          console.log(response.data.end_user_tel);
          setend_user({
            id:response.data.id,
            name:response.data.name || "",
            full_name:response.data.fullname || "",
            contact:response.data.contact || "",
            
            email:response.data.email || "",
            line_id:response.data.line_id || "",
            address:response.data.address || "",
            tel:response.data.end_user_tel || "",
            latitude:response.data.latitude || "",
            longitude:response.data.longitude || "",
            is_use:response.data.is_use,
          });
          for(let e = 0; e < response.data.relate_product.length;e++){
            response.data.relate_product[e].id = response.data.relate_product[e].product_id;
          }
      
          setProduct_select(response.data.relate_product);
          
          

           axios({
            method: "post",
            url: Configs.API_URL + "/product/filter",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: filter_product,
          })
            .then(async (response2) => {
     
              for(let item of response2.data){
                var check = response.data.relate_product.filter((e)=>{
                  return e.id === item.id
                });
                if(check.length > 0){
                  item.isPush = true;
                }else{
                  item.isPush = false;
                }
            
              }
              //console.log(response.data)
      
               setProduct(response2.data);
             
            })
            .catch(async (error) => {
              console.log(error);
            });

        })
        .catch(function (error) {
          console.log(error);
        });
    }
    
  }
  
  
  function saveOption(params) {
    const error_list = [];
    if(end_user.name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [No. End User]."
      }
      error_list.push(temp_err);
    }
    if(end_user.full_name.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Full Name]."
      }
      error_list.push(temp_err);
    }
/*     if(end_user.contact.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Contact]."
      }
      error_list.push(temp_err);
    } */
/*     if(end_user.address.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Address]."
      }
      error_list.push(temp_err);
    } */
/*     if(end_user.line_id.trim() == ""){
      let temp_err ={
        message:"Please enter information in the fields >>> [Line ID]."
      }
      error_list.push(temp_err);
    } */
    if(error_list.length > 0){
      var err_message = "";
      for(var e =0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{
       console.log("here")
      if (mode.mode === "add") {
        const temp = {
          name:end_user.name.trim(),
          full_name:end_user.full_name,
          contact:end_user.contact,
          tel:end_user.tel,
          email:end_user.email,
          line_id:end_user.line_id,
          address:end_user.address,
          latitude:end_user.latitude,
          longitude:end_user.longitude,
          oem_id:getOem(),
          relate_product:product_select,
        }
        Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/end_user/add",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/End_user";
                });
              }
  
              //console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
      }

      if (mode.mode === "edit") {

        const temp ={
          end_user_id:id,
          name:end_user.name,
          full_name:end_user.full_name,
          contact:end_user.contact,
          tel:end_user.tel,
          email:end_user.email,
          line_id:end_user.line_id,
          address:end_user.address,
          latitude:end_user.latitude,
          longitude:end_user.longitude,
          is_use:end_user.is_use,
          oem_id:getOem(),
          relate_product:product_select,
    
        }
        console.log(temp);
       
        Swal.fire( {
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url: Configs.API_URL + "/end_user/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/End_user";
                });
              }
    
              console.log(response.data);
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        });
       }

    }

  }


  function cancelOption(params) {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon:'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
        "/Warehouse_Management/Factory_Master_Data/End_user";
      }
    }
    );
  }
 

  const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)",
  };

  const [location,setlocation] = useState([]);
  function setMark(lat,lng){
    

   
    setlocation([]);
    const tempData2 = [];
      let temp =   
      {
        "id": 1,
      "title": "My Position",
      "website": "www.google.com",
      "image": " ",
        "address": [
          {
            "id": 1,
            "country": "",
            "city": "",
            "street": "",
            "postcode": "",
            "lat": lat,
            "lng": lng
          }
        ]
      }
      tempData2.push(temp);
      setlocation(tempData2);
      setend_user({...end_user,latitude: lat,longitude: lng});
      end_user.latitude = lat; 
      end_user.longitude = lng;
      settest({...test,test:""});
      test.test = "";
  }
//console.log(location)

const GetProduct = async () => {
   
  await axios({
    method: "post",
    url: Configs.API_URL + "/product/filter",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filter_product,
  })
    .then(async (response) => {
      //console.log(response.data)
      //setProduct([]);
      for(let item of response.data){
        var check = product_select.filter((e)=>{
          return e.id === item.id
        });
        if(check.length > 0){
          item.isPush = true;
        }else{
          item.isPush = false;
        }
    
      }
      //console.log(response.data)

       setProduct(response.data);
       setMDBT(true);
    })
    .catch(async (error) => {
      console.log(error);
    });
};

function pushProduct(e){
  const product_select_temp = product_select;
    var data = product.filter((element)=>{ 
      return (element.id === e.target.id);
    })
    const product_p = product;
    setProduct([]);
    for(let i of product_p){
      if(i.id === e.target.id){
        i.isPush = true;
      }
    }
    setProduct(product_p);
    //console.log(product_select_temp);
    var checked = product_select_temp.filter((e2)=>{
      return e2.id === data[0].id;
    })
    //console.log(checked)
    if(checked.length > 0){
      console.log("ซ้ำ")
    }else{
      product_select_temp.push(data[0]);
    }
     setProduct_select([]);
     setProduct_select(product_select_temp);
     settest({...test,test:""});
     test.test = "";
  }

  function removeProduct(e){
    //console.log(e.target.id);
    const product_p = product;
    setProduct([]);
    for(let i of product_p){
      if(i.id === e.target.id){
        i.isPush = false;
      }
    }
    setProduct(product_p);
    const product_select_temp = product_select;
    setProduct_select([]);
    let indexOf = product_select_temp.findIndex((element)=> element.id ===  e.target.id) 
    product_select_temp.splice(indexOf, 1);
    setProduct_select(product_select_temp);
    settest({...test,test:""});
     test.test = "";
    //console.log(data[0]);    
  }
  //console.log(product_select);
  const Product_data = [];
  for(var index = 0; index < product.length;index++){
    const rowItem = {};

    rowItem["no"] = index +1 ;
    rowItem["id"] = product[index].id;
    rowItem["product_no"] = product[index].no;
    rowItem["product_name"] = product[index].name;
    rowItem["mgt"] = (
      <>
      {product[index].isPush === true ? (
          <>
          <div className="col-12">
                  <button
                    type="button"
                    id = {product[index].id}
                    onClick={removeProduct.bind(this)}
                    class="btn btn-block btn-danger "
                  >
                    Remove
                  </button>
                </div>
        </>
      ):(
        <>
        <div className="col-12">
        <button
          type="button"
          id = {product[index].id}
          onClick={pushProduct.bind(this)}
          class="btn btn-block btn-primary "
        >
          Push
        </button>
      </div>
      </>
      )}
     
      </>
    );
    Product_data.push(rowItem);
  }

  const TableProduct = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
       label: "Product Name",
       field: "product_name",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Product_data,
  };


  const Product_data_select = [];

  for(var index = 0; index < product_select.length;index++){
    const rowItem = {};

    rowItem["no"] = index +1 ;
    rowItem["id"] = product_select[index].id;
    rowItem["product_no"] = product_select[index].no;
    rowItem["product_name"] = product_select[index].name;
    rowItem["mgt"] = (
      <>
      <div className="col-6">
                  <button
                    type="button"
                    id = {product_select[index].id}
                    onClick={removeProduct.bind(this)}
                    class="btn btn-block btn-danger "
                  >
                    Remove
                  </button>
                </div>
      </>
    );
    Product_data_select.push(rowItem);
  }
 
 
  

  const TableProduct_select = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
       label: "Product Name",
       field: "product_name",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Product_data_select,
  };

  const clearFilter = async () => {
    setfilter_product({
     ...filter_product,
     no: "",
     name: "",
     product_model_id: "",
     oem_id: getOem()
   }); 
   filter_product.no = "";
   filter_product.name = "";
   filter_product.product_model_id = "";
  
   GetProduct();
  
 }; 

 const [default_center,setdefault_center] = useState({
  lat:"",
  lng:"",
 })
 return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="end_user" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>End_user {pageMode}{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">End_user</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
           <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
             <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    if
                      onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">End_user {pageMode}</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
           
         
                 <br/>
              <div className="row">
              
                     

                  
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={end_user.name}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          name: e.target.value,
                        });
                      }}
                
                    />
                    <label>
                     No.End User 
                      {end_user.name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                   
                      value={end_user.full_name}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          full_name: e.target.value,
                        });
                      }}
                    />
                    <label>
                       Full Name
                      {end_user.full_name.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

               
              </div>
             

       

             
       
            
              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={end_user.contact}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          contact: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Contact
                   {/*  {end_user.contact.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  {/* <input
                      type="text"
                      className="form-control"
                      required
                      value={end_user.tel}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          tel: e.target.value.trim(),
                        });
                      }}
                    /> */}
                  <NumberFormat
                      type="text"
                      className="form-control"
                      required
                      thousandSeparator={false}
                      format={"###-###-#########"}
                      value={end_user.tel}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          tel: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Tel
                
                    </label>
                  </div>
                </div>
               
              </div>

              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={end_user.email}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          email: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Email
                    
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={end_user.line_id}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          line_id: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Line ID
                   {/*  {end_user.contact.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
              </div>

                      <div className = "row">
                      <div className="col-12 col-md-10 col-xl-8">
                  <div class="form-group">
                  <textarea
                      type="text"
                      className="form-control"
                      required
                      value={end_user.address}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          address: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Address
                    {/* {end_user.address.trim() === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                    </label>
                  </div>
                </div>
                      </div>



              <div className="row">
              <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={end_user.latitude}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          latitude: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Latitude
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-xl-4">
                  <div class="form-group">
                  <input
                      type="text"
                      className="form-control"
                      required
                      value={end_user.longitude}
                      disabled={disable}
                      onChange={(e) => {
                        setend_user({
                          ...end_user,
                          longitude: e.target.value,
                        });
                      }}
                    />
                    <label>
                    Longtitude
                    </label>
                  </div>
                </div>
             
                <div className="col-6 col-md-4 col-xl-1">
                <div class="form-group">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#modal-map"
                    //onClick={saveOption}
                    onClick={()=>{setMark(end_user.latitude,end_user.longitude);
                      setdefault_center({...default_center,lat:end_user.latitude || 13.847860,lng:end_user.longitude || 100.604274});
               
                    }}
                    class="btn btn-block btn-success "
                  >
                    Map Marker
                  </button>
                  </div>
                </div>
              </div>




              <div className="col-12">
                  <h3 className="mt-5 mt-5-head">
                    Relate Product
                  </h3>
                  <div className="tab-custom-content">
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="">Product Select</label>
                      <div className = "row">
                      <div className="col-8 col-md-4 col-xl-2">
                        <button
                          type="button"
                          class="btn btn-block btn-info "
                          disabled={mode.mode === "read" ? true : false}
                          data-toggle="modal"
                          data-target="#modal-product"
                        // onClick={testassy}
                        >
                          Select Product
                        </button>
                      </div>
                      {/* {product_select_temp.length > 0 && mode.mode != "read" ? (
                        <>
                        <div className="col-2">
                          <button
                            type="button"
                            class="btn btn-block btn-danger "
                            onClick={clear_restore}
                          >
                            Clear
                          </button>
                        </div>
                        </>
                      ):(
                     <></>)} */}
                     
                    
                      </div>


                    </div>

                  </div>
                  <br />

                







                  <div className="row">

                    <div className="col-12">

                  
                      <div className="table-responsive" style={{ height: "600px",whiteSpace: 'nowrap'  }} >
                        <MDBDataTable
                          className="table table-head-fixed"
                          sortable = {false}
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={TableProduct_select}
                        />
                      </div>
                    </div>


                  </div>
                  <br />
                  {/* ------------------------------------------------------------------------------------------------------------------ */}
                  <div className="modal fade" id="modal-product">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Product</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput">


                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">

                            <div className="col-12">
                              <div className="row">
                              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.no}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        no: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Product No</label>{" "}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group ">
                  
                <input
                     type="text"
                    className="form-control"
                    required="false"    
                    value={filter_product.name}
                    onChange={(e) => {
                      setfilter_product({
                        ...filter_product,
                        name: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>


              <div className="col-md-3">
                <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                                    disabled={mode.mode === "read" ? true : false}
                                    onClick={() =>{
                                      setMDBT(false);
                                      GetProduct();
                                      //setMDBT(true);
                                      }}
                                  >
                                    Search
                                  </button>
                                </div>
                                </div>

                                <div className="col-md-3">
                            <div className="form-group ">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-info "
                                    disabled={mode.mode === "read" ? true : false}
                                    onClick={()=>{
                                      setMDBT(false);
                                      clearFilter();
                                      setMDBT(true);
                                      
                                    }
                                     }
                                  >
                                    Clear
                                  </button>
                                </div>
                                </div>
                               




                              </div>
                              <br />

                    

                              <div
                                className="table-responsive"
                                style={{ height: "500px",whiteSpace: 'nowrap'  }}
                              >
                                <MDBDataTable
                                  className="table table-head-fixed"
                                  sortable = {false}
                                  disableRetreatAfterSorting={MDBT}
                                  
                                  onPageChange={(page)=>{
                                    console.log(page)
                                  }}
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={TableProduct}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <h3>
                              </h3>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
















             
              
    


            </div>
            
          </div>
        </section>
        <div className="modal fade" id="modal-map">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Map Marker {">>>"} End User</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput">
                              <div style={{ height: "80vh", width: "100%" }}>
                       
                      {/*        /// key: "AIzaSyA7yYhu2SMOYY11PLOGB4DgJD-BwNEDamY" */}
                      {default_center.lat !== "" && default_center.lat !== ""  ? (
 <GoogleMapReact
 bootstrapURLKeys={{
   key: "AIzaSyCIOsYyhFVUe39aftCUBTkk1IczCxGQxD8"
 }}
 disabled={true}
 defaultCenter={{ lat: parseFloat(default_center.lat), lng: parseFloat(default_center.lng) }}
     /*      defaultCenter={{lat:parseFloat(end_user.latitude || '13.847860'),lng:parseFloat(end_user.longitude || '100.604274')}} */
 defaultZoom={8}
 onClick={(e) => {console.log("lat: ",e.lat," lng :",e.lng);
 if(e.lat !== end_user.latitude && e.lng !== end_user.longitude){
   if(disable === false){
     setMark(e.lat,e.lng);
   }
  
 }

//setposition({...position,lat:e.lat,lng:e.lng});


}}

>
{location.map(item => {
     if (item.address.length !== 0) {
       return item.address.map(i => {
         return (
           <div  key={i.id} lat={i.lat} lng={i.lng}>
             <img   style={markerStyle} width={40} height={40}src={"https://img.icons8.com/dusk/452/region-code.png"} alt="pin" />
          </div>
         );
       });
     }
   })
  
}

</GoogleMapReact>
                      ): (
                   <></>
                      )}
       
      </div>

                              </div>
                            </div>
                          </div>
                          <br />
                         
                          
                        </div>
                        <div className="modal-footer justify-content-between">
                     
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>






      </div>
  {/*     <Footter/> */}
      </div>
  );
}
export default End_user_add;
