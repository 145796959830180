import { MDBDataTable } from "mdbreact";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import Configs from "../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

import Modal_IO_History from "./Modal_IO_History";

function FG_IO_Location_Table() {
  const [filter, setfilter] = useState({
    no: "",
    name: "",
    model: null,
  });

  const [MDBT, setMDBT] = useState({
    is_bool: true,
  });

  const [productData, setProductData] = useState({
    data: [],
  });

  const [modelCategory, setModelCategory] = useState({
    data: [],
  });

  const [unitProduct, setUnitProduct] = useState({
    data: [],
  });

  const [productStock, setProductStock] = useState({
    data: [],
  });

  const [modalStatus, setModalStatus] = useState({
    is_open: false,
  });

  const [dataModal, setDataModal] = useState({
    prod_id: "",
    prod_no: "",
    prod_name: "",
    amount: 0,
    unit: "",
  });

  function handleMDBD(isBool) {
    setMDBT({
      is_bool: isBool,
    });
    MDBT.is_bool = isBool;
  }

  async function clearFilter() {
    setfilter({
      no: "",
      name: "",
      model: null,
    });
    filter.no = "";
    filter.name = "";
    filter.model = null;

    await GetProduct();
  }

  async function GetProduct() {
    let temp = {
      oem_id: getOem(),
      no: filter.no?.toString().trim(),
      name: filter.name?.toString().trim(),
      product_model_id: filter.model,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        handleMDBD(true);

        setProductData({
          data: response.data || [],
        });
        productData.data = response.data || [];
      })
      .catch(async (error) => {
        handleMDBD(true);
        console.log(error);
      });
  }

  async function GetModelCateGory() {
    await axios({
      method: "get",
      url: Configs.API_URL + "/product/getProductModel?id=" + getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const options = [];

        response.data.forEach((el) => {
          var element = {};

          element["value"] = el.id;
          element["label"] = el.name;

          options.push(element);
        });

        setModelCategory({
          data: options,
        });
        modelCategory.data = options;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetDropdownUnit() {
    const data = {
      company_id: getUser().com,
      oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/product_unit/getAllUnitProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data,
    })
      .then(async (response) => {
        const data = response.data.data;

        setUnitProduct({
          data: data,
        });
        unitProduct.data = data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetStockProductInWarehouse() {
    const send_data = {
      user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      type_aioi: "aioi",
    };

    await axios({
      method: "POST",
      url: `${Configs.API_URL}/api/logProduct/getStockProductInWarehouse`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: send_data,
    })
      .then(async (response_data) => {
        setProductStock({
          data: response_data.data,
        });
        productStock.data = response_data.data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  function SetProductTable(index) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["prod_no"] = productData.data[index].no || "-";

    rowItem["prod_name"] = productData.data[index].name;

    rowItem["model"] =
      modelCategory.data.find(
        (el) => el.value == productData.data[index].product_model_id
      )?.label || "-";

    rowItem["amount"] =
      productStock.data.filter(
        (el) => el.product_id == productData.data[index].id
      )?.reduce((a, b) => a + Number(b.lp_amount || 0), 0) || 0;

    rowItem["unit"] =
      unitProduct.data.find(
        (el) => el.pu_product_id == productData.data[index].id
      )?.unit_name || "-";

    rowItem["history"] = (
      <div className="row" style={{ flexWrap: "nowrap", margin: "0 auto" }}>
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            id={`btn_edit_${index}`}
            className="btn btn-xs"
            type="button"
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={() => {
              setDataModal({
                prod_id: productData.data[index].id,
                prod_no: productData.data[index].no,
                prod_name: productData.data[index].name,
                amount:
                  productStock.data.filter(
                    (el) => el.product_id == productData.data[index].id
                  )?.reduce((a, b) => a + Number(b.lp_amount || 0), 0) || 0,
                unit:
                  unitProduct.data.find(
                    (el) => el.pu_product_id == productData.data[index].id
                  )?.unit_name || "",
              });

              dataModal.prod_id = productData.data[index].id;
              dataModal.prod_no = productData.data[index].no;
              dataModal.prod_name = productData.data[index].name;
              dataModal.amount =
                productStock.data.filter(
                  (el) => el.product_id == productData.data[index].id
                )?.reduce((a, b) => a + Number(b.lp_amount || 0), 0) || 0;
              dataModal.unit =
                unitProduct.data.find(
                  (el) => el.pu_product_id == productData.data[index].id
                )?.unit_name || "";

              setModalStatus({
                is_open: true,
              });
              modalStatus.is_open = true;
            }}
          >
            <i
              class="fas fa-eye"
              style={{
                fontSize: "14px",
              }}
            ></i>
          </a>
        </div>
      </div>
    );

    return rowItem;
  }

  const rowsData = [];
  for (let index = 0; index < productData.data.length; index += 1) {
    rowsData.push(SetProductTable(index));
  }

  const tableData = {
    columns: [
      {
        label: "No.",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No.",
        field: "prod_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "prod_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Model / Category",
        field: "model",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "History",
        field: "history",
        sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  useEffect(async () => {
    await GetProduct();
    await GetModelCateGory();
    await GetStockProductInWarehouse();
    await GetDropdownUnit();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-sm-4 col-xl-6">
                <h1>F/G In/Out With Location (AIOI)</h1>
              </div>
              <div className="col-12 col-sm-8 col-xl-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">IoT Master Data</li>
                  <li className="breadcrumb-item active">
                    F/G In/Out With Location (AIOI)
                  </li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    style={{ height: "42px" }}
                    value={filter.no}
                    onChange={(e) =>
                      setfilter({
                        ...filter,
                        no: e.target.value,
                      })
                    }
                  />
                  <label>Product No.</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    style={{ height: "42px" }}
                    value={filter.name}
                    onChange={(e) =>
                      setfilter({
                        ...filter,
                        name: e.target.value,
                      })
                    }
                  />
                  <label>Product Name</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group" style={{ "z-index": "98" }}>
                  <Select
                    options={modelCategory.data}
                    value={
                      modelCategory.data.find(
                        (el) => el.value == filter.model
                      ) || null
                    }
                    onChange={(e) => {
                      setfilter({
                        ...filter,
                        model: e.value,
                      });
                      filter.model = e.value;
                    }}
                  />
                  <label>Model/Category</label>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      handleMDBD(false);
                      await GetProduct();
                      await GetStockProductInWarehouse();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-6 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      handleMDBD(false);
                      clearFilter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    id="btn_add"
                    className="btn btn-block btn-primary"
                    type="button"
                    onClick={async () => {
                      window.location.href =
                        "/IoT_Master_Data/FG_InOut_With_Location_aioi/In_Out";
                    }}
                  >
                    In / Out Item
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    {/* <h4>Config AIOI</h4> */}
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div className="table-responsive">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={MDBT.is_bool}
                            data={tableData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal_IO_History
          dataModal={dataModal}
          modalStatus={modalStatus}
          setModalStatus={setModalStatus}
        />
      </div>
    </div>
  );
}

export default FG_IO_Location_Table;
