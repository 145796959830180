import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import axios from "axios";
import { getOem, getToken, getUser, getFeature, getComConfig ,getAcademy} from "../../Utils/Common";
import Configs from "../../config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Sidebar from "../../component/Sidebar";
import BTN from "../../component/btn_feature";
import Select from "react-select";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
/* import 'ag-grid-enterprise'; */
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import jsPDF from "jspdf";
import numeral from "numeral";
import { toDataURL } from "qrcode";
function MateForPO(params) {
  const [po_sign, setpo_sign] = useState(getComConfig());
  const [poConfig, setPoConfig] = useState({
    no: "",
    our_ref: "",
    your_ref: "",
  });
  const [order,setorder] = useState([]);
  const [order_option,setorder_option] = useState([]);
  const [supplier,setsuplier] = useState([]);
  const [materail_group_view,setmaterail_group_view] = useState({
    supplier_id:"",
  });
  const [material_group,setmaterial_group] = useState([]);
  const [material,setmaterial] = useState([]);
  const select_order = useRef();
  const [filter,setfilter] = useState({
    order_no:"",
    oem_id:getOem(),
  });
  const [test,settest] = useState({
    test:"",
  });
  const [company, setcompany] = useState();
  const [filter_order,setfilter_order] = useState({
    order_id:"",
    oem_id:getOem(),
  });
  const Getmaterial_order = async () => {
    
    console.log(filter_order);
    axios({
      method: "post",
      url: Configs.API_URL + "/materialPlaning/orderMaterial/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter,
    })
      .then(function (response) {
      
        setorder(response.data);
        const option = [];
        for(let item of response.data){
          const element = {};
          element['label'] = item.order_no;
          element['value'] = item.id;
          option.push(element);
        }
        setorder_option(option);
        console.log(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
    
  };
  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
   useEffect(() => {
    axios(config_com)
    .then(function (response) {
      /*  console.log(response.data); */
      setcompany(response.data);
    }).catch(function (error) {
      console.log(error);
    });
    Getmaterial_order();
  }, []);

    function clear_filter(){
      setfilter_order({...filter_order,order_id:""});
      filter_order.order_id = "";
      select_order.current.select.clearValue();
      setmaterial([]);
      setsuplier([]);
      setmaterial_group([]);
  setmaterail_group_view({...setmaterail_group_view,supplier_id:"",
  });
  setmaterail_group_view.supplier_id = "";


    }

    function get_listMAterialPO(){
      console.log(filter_order)
      
      axios({
        method: "post",
        url: Configs.API_URL + "/materialPlaning/approveRawMatPO/get_listMAterialPO",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: filter_order
      })
        .then(function (response) {
          console.log(response.data);
          setsuplier(response.data.list_supplier);
          setmaterial_group(response.data.list_mat_sup_group);
          setmaterial(response.data.list_material);
          setmaterail_group_view({...materail_group_view,supplier_id:response.data.list_supplier[0].supplier_id});
          test.test = "";
        })
        .catch(function (error) {
          Swal.fire("Error", error.response.data.message, "error");
          console.log(error);
        });
    }

    function SendmailPO(){

      axios({
        method: "post",
        url: Configs.API_URL + "/materialPlaning/approveRawMatPO/sentMailToPO",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: filter_order
      })
        .then(function (response) {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Send EMAIL success",
            showConfirmButton: false,
            timer: 1500,
          })
       
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
        });
    }
    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function editBuy_price(e){
      //console.log(e.target.value);
      var tempData = materail_data;
      materail_data = [];
      tempData[e.target.name].buy_price = e.target.value;
      tempData[e.target.name].total = e.target.value * tempData[e.target.name].nms;
      materail_data = tempData;
      settest({...test,test:""});
      test.test = "";
    }
    //console.log(materail_group_view)
    var materail_data = material.filter((e)=>{ return e.supplier_id === materail_group_view.supplier_id});
  const tempData = [];
    
    for(var index = 0; index < materail_data.length; index++){
      var element = {};
      element["no"] = index + 1;
      element["material_no"] = materail_data[index].material_no;
      element["material_name"] = materail_data[index].material_name;
      element["material_type"] = materail_data[index].material_type;
      element["material_spec"] = materail_data[index].material_spec;
      element["material_size"] = materail_data[index].material_size;
      element["material_unit"] = materail_data[index].material_unit;
      element["inhand"] = numberWithCommas(materail_data[index].inhand || 0);
      //element["buy_price"] =  numberWithCommas(materail_data[index].buy_price || 0);


      element["buy_price"] = (
      <NumberFormat
 
        type="text"
        className="form-control"
        required
        allowNegative={false}
        name={index}
        thousandSeparator={true}
        value={materail_data[index].buy_price || 0}
        onChange={editBuy_price.bind(this)}
      />
    
      );
      element["min_lot"] =  numberWithCommas(materail_data[index].min_lot || 0);
      element["reqOrder"] =  numberWithCommas(materail_data[index].amount || 0);
      element["nms"] =  numberWithCommas(materail_data[index].nms || 0);
      element["next_order"] =  numberWithCommas(materail_data[index].next_order || 0);
      element["supplier_name"] = materail_data[index].supplier_name;
      element["total"] =  numberWithCommas(materail_data[index].total || 0);
      tempData.push(element);
    }
    const data = {
      columns: [
        {
          label: "No",
          field: "no",
          sort: "asc",
          width: 50,
        },
        {
          label: "Material no",
          field: "material_no",
          sort: "asc",
          width: 50,
        },
        {
          label: "Material name",
          field: "material_name",
          sort: "asc",
          width: 50,
        },
        {
          label: "Material type",
          field: "material_type",
          sort: "asc",
          width: 50,
        },
        {
          label: "Material spec",
          field: "material_spec",
          sort: "asc",
          width: 50,
        },
        {
          label: "Material size",
          field: "material_size",
          sort: "asc",
          width: 50,
        },
        {
          label: "Material unit",
          field: "material_unit",
          sort: "asc",
          width: 50,
        },
        {
          label: "inhand",
          field: "inhand",
          sort: "asc",
          width: 50,
        },
        {
          label: "buy price",
          field: "buy_price",
          sort: "asc",
          width: 200,
        },
        {
          label: "min lot",
          field: "min_lot",
          sort: "asc",
          width: 50,
        },
        {
          label: "reqOrder",
          field: "reqOrder",
          sort: "asc",
          width: 50,
        },
        {
          label: "next month suggesstion",
          field: "nms",
          sort: "asc",
          width: 50,
        },
        {
          label: "Order",
          field: "next_order",
          sort: "asc",
          width: 50,
        },
        {
          label: "Supplier",
          field: "supplier_name",
          sort: "asc",
          width: 50,
        },
        {
          label: "Total",
          field: "total",
          sort: "asc",
          width: 50,
        },
       
        
     
    
      ],
      rows: tempData,
    };



    function PoReport() {

  
        /* var testjson = JSON.stringify(rowsDataForPO)
          console.log( JSON.parse(testjson));
        */
  
        var Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
        //console.log(supplier);
        //console.log(rowsDataForPO);
        const doc = new jsPDF("p", "mm", "a4");
  
        for (let index = 0; index < supplier.length; index++) {
          doc.addImage(Com_logo, 'JPEG', 20, 10, 25, 25)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(18)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          doc.addFileToVFS('THSarabunNew.ttf', Get_font_pdf_th2())
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew');
          //doc.addFileToVFS('ANGSA.ttf',Get_font_pdf_th())
          //doc.addFont('ANGSA.ttf', 'custom', 'normal');
          //doc.setFont('custom');
          doc.text(52, 18, 'SHIMIZU Manufacturing Co., Ltd.')
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(52, 24, '571 Prachapattana Road, Tabyao, Ladkrabang')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(52, 30, 'Bangkok 10520. Tel.02-7380623 Fax.02-1729639 เลขประจำตัวผู้เสียภาษี : 0105539003283')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(18)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /*  doc.addFont('ANGSA.ttf', 'custom', 'normal');
           doc.setFont('custom'); */
          doc.text(105, 40, 'PURCHASE ORDER', 'center')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
          doc.setFont('custom'); */
          doc.text(105, 47, 'ใบสั่งซื้อ', 'center')
  
  
          /*---------------------------------------------------------------------------*/
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(23, 55, 'Supplier')
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(55, 55, ':')
  
          /* doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(40, 55, 'บจก.') */
          //data
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
          doc.setFont('custom'); */
          doc.text(60, 55, `${supplier[index].supplier_name === null ? "-" : supplier[index].supplier_name}`)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(23, 60, 'ผู้ขาย')
  
          /* doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(40, 60, 'คุณ') */
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(55, 60, ':')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
          doc.setFont('custom'); */
          doc.text(60, 60, `${supplier[index].contact_name === null ? "-" : supplier[index].contact_name}`)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(40, 65, 'Tel')
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(55, 65, ':')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
            doc.setFont('custom'); */
          doc.text(60, 65, `${supplier[index].contact_tel === null ? "-" : supplier[index].contact_tel}`)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(40, 70, 'Fax')
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(55, 70, ':')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'custom', 'normal');
          doc.setFont('custom'); */
          doc.text(60, 70, `${supplier[index].contact_fax === null ? "-" : supplier[index].contact_fax}`)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(23, 75, 'Your Ref.   No.')
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(55, 75, ':')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(60, 75, poConfig.your_ref)
  
          //right
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(130, 55, 'PO No.')
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(150, 55, ':')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(155, 55, poConfig.no)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(130, 60, 'Date')
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(150, 60, ':')
  
  
  
          //var today = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
          Date.prototype.addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
          }
          var date = new Date();
          var today = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(155, 60, today)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(130, 75, 'Our Ref.')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(155, 75, poConfig.our_ref)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(150, 75, ':')
          doc.rect(20, 50, 170, 27)
  
          /*-------------------------------------------------------------------------------*/
          //header
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(24, 82, 'Item')
          doc.setFontSize(12)
          doc.text(24, 88, 'ลำดับ')
          doc.rect(20, 77, 15, 13)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(70, 82, 'Description', 'center')
          doc.setFontSize(12)
          doc.text(70, 88, 'รายการ', 'center')
          doc.rect(35, 77, 70, 13)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(118, 82, 'Delivery dd.', 'center')
          doc.setFontSize(12)
          doc.text(118, 88, 'กำหนดการส่งสินค้า', 'center')
          doc.rect(105, 77, 25, 13)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(138, 82, 'Qunitity', 'center')
          doc.setFontSize(12)
          doc.text(139, 88, 'จำนวนหน่วย', 'center')
          doc.rect(130, 77, 18, 13)
  
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(158, 82, 'Unit Price', 'center')
          doc.setFontSize(12)
          doc.text(158, 88, 'ราคาต่อหน่วย', 'center')
          doc.rect(148, 77, 20, 13)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
          doc.text(179, 82, 'Amount', 'center')
          doc.setFontSize(12)
          doc.text(179, 88, 'จำนวนเงิน', 'center')
          doc.rect(168, 77, 22, 13)
          /* console.log(rowsDataForPO.materail[0].no) */
          var row = 0
          var totalByPO = 0;
          var material_dataReport = material.filter((e)=>{ return e.supplier_id === supplier[index].supplier_id});
          for (let index2 = 0; index2 < material_dataReport.length; index2++) {
            for (let i = 0; row < 15; i = i + 8) {
              if (row < material_dataReport.length) {
               
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(12)
                doc.text(26, 95 + i, (row + 1).toString(), 'center') //item mat
  
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(12)
                doc.text(38, 95 + i, 'Material Spec : ' +
                material_dataReport[row].material_spec + ' , ' +
                  'Material Size : ' +
                  material_dataReport[row].material_size, 'left') //description mat
  
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(12)
  
                Date.prototype.addDays = function (days) {
                  var date = new Date(this.valueOf());
                  date.setDate(date.getDate() + days);
                  return date;
                }
                var date = new Date();
                var format_date = `${material_dataReport[row].lead_time_days === null ? "-" :
                  date.addDays(material_dataReport[row].lead_time_days).getDate() + '/' +
                  (date.addDays(material_dataReport[row].lead_time_days).getMonth() + 1) + '/' +
                  date.addDays(material_dataReport[row].lead_time_days).getFullYear()}`
  
                doc.text(118, 95 + i, format_date, 'center') //delivery dd
  
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(12)
                doc.text(147, 95 + i, numeral(material_dataReport[row].nms).format('0,0'), 'right') //qunitity mat
  
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(12)
                doc.text(167, 95 + i, `${material_dataReport[row].buy_price === null ? "-" : numeral(material_dataReport[row].buy_price).format('0,0')}`, 'right') //unit price
  
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(12)
                let amount_ = 0
                amount_ = material_dataReport[row].buy_price * material_dataReport[row].nms
                totalByPO += amount_;
                doc.text(189, 95 + i, numeral(amount_).format('0,0'), 'right')
              }
              doc.rect(20, 90 + i, 15, 8) //item
              doc.rect(35, 90 + i, 70, 8) // description
              doc.rect(105, 90 + i, 25, 8) // delivery dd.
              doc.rect(130, 90 + i, 18, 8) //qunitity
              doc.rect(148, 90 + i, 20, 8) //unit price
              doc.rect(168, 90 + i, 22, 8) //amount
              doc.setTextColor(0, 0, 0)
              doc.setFontSize(15)
              //doc.text(23, 92+i, materail[i].no,'center')
              row = row + 1
            }
          }
          /*------------------------------------------------------------------------*/
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(20, 216, 'PLEASE DELIVER THE GOODS ON/BEFORE')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(149, 216, 'Total')
          doc.rect(148, 210, 20, 8)
          //value total
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(189, 215, numeral(totalByPO).format('0,0'), 'right')
          doc.rect(168, 210, 22, 8)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(20, 224, 'ให้ส่งสินค้าภายในวันที่  :')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          let vat = 0
          vat = totalByPO * 0.07
          doc.text(149, 224, 'Vat 7 %')
          doc.text(189, 223, numeral(vat).format(0, 0.00), 'right')
          doc.rect(148, 218, 20, 8)
  
          //value
          doc.rect(168, 218, 22, 8)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
  
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(20, 232, 'PLEASE DELIVER THE GOODS TO: ')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(149, 232, 'Grand Total')
          let grand_total = totalByPO + vat
          doc.text(189, 231, numeral(grand_total).format(0,0.00), 'right')
          doc.rect(148, 226, 20, 8)
  
          //value grand
          doc.rect(168, 226, 22, 8)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(20, 240, 'ให้ส่งของที่  :   ')
  
          doc.setTextColor(255, 0, 0)
          doc.setFontSize(16)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(38, 240, po_sign.com_name)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(22, 248, 'กรุณาเซ็นต์ ยืนยันตรวจรับใบสั่งซื้อ')
          doc.rect(20, 244, 65, 25)
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(18)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(128, 248, 'APPROVED BY')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(15)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(128, 260, 'อนุมัติโดย…………………………………..')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(22, 260, 'ผู้รับใบสั่งซื้อ .................................................................')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(22, 264, 'และแฟ็กซ์กลับ 02-172-9639')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(20, 272, 'Remark the PO. NO. Must be show on the invoice and delivery note.')
  
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(145, 265, '( ' + po_sign.po_sign + ' )')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(20, 276, 'หมายเหตุ : จะต้องระบุเลขที่ใบสั่งซื้อในใบส่งสินค้าทุกครั้ง')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(20, 284, 'FORM 47-08')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(128, 272, '***กรุณาแนบใบ Cer ทุกครั้งที่ส่งวัตถุดิบ')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(128, 276, 'หรือส่งทาง อีเมล์ : shimiz_thai@hotmail.com')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(128, 280, '**กรุณาส่งของตาม PO.ระบุ วันที่/ส่งของ')
  
          doc.setTextColor(0, 0, 0)
          doc.setFontSize(12)
  
          doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal');
          doc.setFont('THSarabunNew')
          /* doc.addFont('ANGSA.ttf', 'th', 'normal');
          doc.setFont('th'); */
          doc.text(128, 284, 'ถ้าไม่มี PO.สั่งซื้องดรับของ ขอบคุณค่ะ')
  
  
          /* doc.text(38, 240, po_sign.com_name)
  
          doc.text(145, 265, po_sign.po_sign)  */
  
  
          if (index < supplier.length - 1) {
            doc.addPage("a4", "h");
          }
        }
  
        window.open(doc.output("bloburl"));
 
    }




    
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="mrp" activemenu="arm" submenu="none" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Approve Material Item for PO{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Approve Material Item for PO
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <Select
                    options={order_option}
                    ref={select_order}
                    defaultValue={filter_order.order_id}
                    onChange={(e) => {

                      if (e !== null) {
                        setfilter_order({
                          ...setfilter_order,
                          order_id: e.value,
                        });
                      }
                    }}
                  />
                
                  <label htmlFor="">Order</label>
                </div>
              </div>


         
              <div className="col-md-2">
                <div className="form-group ">

                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={get_listMAterialPO}
                  >
                    Load Material 
                  </button>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={clear_filter}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="table-to-xls"
                    filename={"MaterialPO" + " " + Date()}
                    sheet="tablexls"
                    buttonText="Download as XLS"
                  />
                </div>
              </div>
           
             {/*  <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    // onClick={search_mat}
                  >
                    send email to supplier
                  </button>
                </div>
              </div> */}

                <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                 /*    data-toggle="modal"
                    data-target="#modal-reportPO" */
                    onClick={PoReport}
                  >
                    Report PO
                  </button>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              {/* {feature.map((e) => {
                if (e.feature === "Report PO") {
                  return <BTN onClick={PrePoReport} name={e.feature} />;
                }
                if (e.feature === "Send Email PO") {
                  return <BTN onClick={PoEmail} name={e.feature} />;
                }
              })} */}
             
            </div>
          </div>

         
        </section>

        <section className="content">

<div className="card card-primary card-outline card-outline-tabs">
  <div className="card-header p-0 border-bottom-0">
    <ul
      className="nav nav-tabs"
      id="custom-tabs-four-tab"
      role="tablist"
    >
      {supplier.map((e,index)=>{
        var class_ac = "nav-link";
        if(index === 0){
          class_ac = "nav-link active";
        }
          return (
            <>
             <li className="nav-item">
        <a
          className={class_ac}
          id={"custom-tabs-four-"+e.supplier_name+"-tab"}
          data-toggle="pill"
          href={"#custom-tabs-four-"+e.supplier_name}
          role="tab"
          onClick={()=>{setmaterail_group_view({...materail_group_view,supplier_id:e.supplier_id})}}
          aria-controls={"custom-tabs-four-"+e.supplier_name}
          aria-selected="false"
        >
          {e.supplier_name}
        </a>
      </li>
            </>
          );
      })}
     
     
    </ul>
  </div>
 
  <div className="tab-content" id="custom-tabs-four-tabContent">

   


  {supplier.map((e,index)=>{
          var class_ac = "tab-pane fade";
          if(index === 0){
            class_ac = "tab-pane fade show active";
          }
    return (
      <>
   <div
        className={class_ac}
        id={"custom-tabs-four-"+e.supplier_name}
        role="tabpanel"
        aria-labelledby={"custom-tabs-four-"+e.supplier_name+"-tab"}
      >

  <div className="card-body">
  <h3 className="card-title">Material In {e.supplier_name}</h3>
  <div className="container-fluid">
    <div className="row mb-2">
    <div className="col-md-10"></div>
    <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                     onClick={SendmailPO}
                  >
                    send email to {e.supplier_name}
                  </button>
                </div>
              </div>
    </div>
  <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-head-fixed"
                  table
                  sortable = {false}
                  //disableRetreatAfterSorting={MDBT}
                  
                  //id="table-to-xls"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
</div>
</div>
  </div>
  
</div>
      </>
    );
  })}








    </div>
    
    </div>
  
    <table  class="table" id="table-to-xls" border = "1"style={{ display:"none"}} >
       <tr>
                 <td style={{ backgroundColor: "LightSkyBlue" ,textAlign: "center" }} colSpan = "13">Approve Material Item for PO Reprot</td>
               
               </tr>
              {supplier.map((e)=>{
             
        
                var key = `${e.supplier_name}`;
                   
               
                console.log(material_group[key]) 
                if(material_group[key] !== undefined){
                return (
                  <>
                  <tr>
                 <td colSpan = "13" style={{ backgroundColor: "salmon" ,textAlign: "center" }}>{e.supplier_name}</td>
               
               </tr>
               <tr>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>no</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>name</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>type</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>spec</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>size</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>unit</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>inhand</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>buy_price</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>min_lot</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>reqOrder</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>next_month_suggestion</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>order</td>
                 <td  style={{ backgroundColor: "LightSteelBlue" ,textAlign: "center" }}>Total</td>
               </tr>
               {material_group[key].map((e2)=>{
                 return (
                   <>
                    <tr>
                       <td>{e2.material_no}</td>
                       <td>{e2.material_name}</td>
                       <td>{e2.material_type}</td>
                       <td>{e2.material_spec}</td>
                       <td>{e2.material_size}</td>
                       <td>{e2.material_unit}</td>
                       <td>{e2.inhand || 0}</td>
                       <td>{e2.buy_price || 0}</td>
                       <td>{e2.min_lot || 0}</td>
                       
                       <td>{e2.amount || 0}</td>
                       <td>{e2.nms || 0}</td>
                       <td>{e2.next_order || 0}</td> 
                       <td>{e2.total || 0}</td>
                   </tr>
                   </>
                 )
                  
                       
                 })}
                  </>
                );
                }
              })}
               
             </table>
    </section>
    <div className="modal fade" id="modal-reportPO">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">Report {">>>"} PO</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput">
                              <div style={{ height: "80vh", width: "100%" }}>
       {/*  <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA7yYhu2SMOYY11PLOGB4DgJD-BwNEDamY"
          }}
          defaultCenter={{lat:13.847860,lng:100.604274}}
          defaultZoom={10}
          onClick={(e) => {
          setMark(e.lat,e.lng);
        //setposition({...position,lat:e.lat,lng:e.lng});
    
    
      }}
  
        >
         {location.map(item => {
              if (item.address.length !== 0) {
                return item.address.map(i => {
                  return (
                    <Link  key={i.id} lat={i.lat} lng={i.lng}>
                      <img style={markerStyle} width={40} height={40}src={"https://img.icons8.com/dusk/452/region-code.png"} alt="pin" />
                   </Link>
                  );
                });
              }
            })
           
         }
      
        </GoogleMapReact> */}
      </div>

                              </div>
                            </div>
                          </div>
                          <br />
                         
                          
                        </div>
                        <div className="modal-footer justify-content-between">
                     
                          <button
                            type="button"
                            className="btn btn-default"
                            data-dismiss="modal"
                          >
                            Close
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>

       
      </div>
      {/*     <Footter /> */}
    </div>
  );
}
export default MateForPO;
