import React, { useState } from 'react';
import './FormatSelect.css';

const FormatSelect = ({ formatDate }) => {
  const [selectedFormat, setSelectedFormat] = useState("day");

  const handleFormatDateSelect = (data) => {
    if (selectedFormat !== data) {
      formatDate(data);
      setSelectedFormat(data);
    }
  };

  return (
    <div className="flex custom-format-button">
      {/* Day Button */}
      <button
        onClick={() => handleFormatDateSelect("day")}
        className={`day-button ${selectedFormat === "day" ? "selected" : ""}`}
        disabled={selectedFormat === "day"}
      >
        Day
      </button>

      {/* Month Button */}
      <button
        onClick={() => handleFormatDateSelect("month")}
        className={`month-button ${
          selectedFormat === "month" ? "selected" : ""
        }`}
        disabled={selectedFormat === "month"}
      >
        Month
      </button>

      {/* Year Button */}
      <button
        onClick={() => handleFormatDateSelect("year")}
        className={`year-button ${selectedFormat === "year" ? "selected" : ""}`}
        disabled={selectedFormat === "year"}
      >
        Year
      </button>
    </div>
  );
};

export default FormatSelect;
