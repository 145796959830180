import { MDBDataTable } from "mdbreact";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../../config";
import React, { useState } from "react";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import { useRef, useEffect } from "react";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";

function Table_Certificate_license({ from }) {
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [Filter, setFilter] = useState({
    name: "",
    type: "",
  });

  const [Tab, setTab] = useState(true);

  const [Cer, setCer] = useState({});

  const [Certificate, setCertificate] = useState({
    data: [],
  });

  const [Lic, setLic] = useState({});

  const [License, setLicense] = useState({
    data: [],
  });

  const [modalData, setModalData] = useState({
    File_name: "",
    File_path: "",
    exp_date: "",
    ext_date: "",
  });

  const [File, setFile] = useState(null);

  const [cerType, setCerType] = useState({
    data: [],
  });

  const allowedTypes = [
    "image/png",
    "image/jpeg",
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  // async function DownloadFile() {
  //   // console.log(NameImage, URLImage);
  //   // await axios({
  //   //   method: "get",
  //   //   url: Configs.API_URL_IMG_cuteplus + URLImage,
  //   //   headers: {
  //   //     Authorization: "Bearer " + getToken(),
  //   //     "X-TTT": Configs.API_TTT,
  //   //     "Content-Type": "application/json",
  //   //   },
  //   //   responseType: "blob",
  //   // }).then(async (response) => {
  //   //   const type = response.headers["content-type"];
  //   //   const blob = new Blob([response.data]);
  //   //   const href = URL.createObjectURL(blob);
  //   //   const link = document.createElement("a");
  //   //   link.href = href;
  //   //   link.setAttribute("download", NameImage); //or any other extension
  //   //   document.body.appendChild(link);
  //   //   link.click();

  //   //   // clean up "a" element & remove ObjectURL
  //   //   document.body.removeChild(link);
  //   //   URL.revokeObjectURL(href);
  //   // });

  //   await axios({
  //     method: "get",
  //     url:
  //       Configs.API_URL_IMG_cuteplus +
  //       "/api/upload/getDataFileOne/a8d92053-b97b-48aa-800f-15d2094834eb",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     // data: tempData1,
  //   })
  //     .then(async (response) => {
  //       console.log(response.data);
  //       // temp["File_Path"] = response.data.data.path;
  //     })
  //     .catch(async (error) => {
  //       Swal.fire({
  //         icon: "error",
  //         title: "ไม่สามารถอัปโหลดเอกสารได้",
  //       });
  //     });
  // }

  function delCertificate(e) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/certificate/delCertificate",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: e,
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  function delLicense(e) {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/license/delLicense",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: e,
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  const extendCertificate = async () => {
    Swal.fire({
      title: "Saving",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    })
      .then(async () => {
        const dataImg = new FormData();
        dataImg.append("Profile", File);

        await axios({
          method: "post",
          url: Configs.API_URL_IMG_cuteplus + "/api/upload/uploadFile",
          headers: {
            Authorization: getToken(),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
          },
          data: dataImg,
        }).then(async (response) => {
          let temp = {
            File_Name: modalData.File_name,
            File_Path: response.data[0].path || modalData.File_path,
            Expire_Date: modalData.exp_date,
            Extend_Date: modalData.ext_date,
            cer_id: Cer.cer_id,
            cer_name: Cer.cer_name,
            cer_remark: Cer.cer_remark,
            cer_exp_date: Cer.cer_exp_date,
            cer_file_name: Cer.cer_file_name,
            cer_file_path: Cer.cer_file_path,
            cer_factory_name: Cer.cer_factory_name,
            cer_ctt_id: Cer.cer_ctt_id,
            cer_created_by: getUser().fup,
            cer_oem_id: getOem(),
            cer_company_id: getUser().com,
          };

          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus + "/api/certificate/extendCertificate",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: temp,
          })
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                // window.location.reload();
                GetCertificate();
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        });
      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถอัปโหลดเอกสารได้",
        });
      });
  };

  const extendLicense = async () => {
    Swal.fire({
      title: "Saving",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    })
      .then(async () => {
        const dataImg = new FormData();
        dataImg.append("Profile", File);

        await axios({
          method: "post",
          url: Configs.API_URL_IMG_cuteplus + "/api/upload/uploadFile",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "multipart/form-data",
          },
          data: dataImg,
        }).then(async (response) => {
          let temp = {
            File_Name: modalData.File_name,
            File_Path: response.data[0].path || modalData.File_path,
            Expire_Date: modalData.exp_date,
            Extend_Date: modalData.ext_date,
            license_id: Lic.license_id,
            license_name: Lic.license_name,
            license_remark: Lic.license_remark,
            license_exp_date: Lic.license_exp_date,
            license_file_name: Lic.license_file_name,
            license_file_path: Lic.license_file_path,
            license_created_by: getUser().fup,
            license_oem_id: getOem(),
            license_company_id: getUser().com,
          };

          await axios({
            method: "post",
            url: Configs.API_URL_cuteplus + "/api/license/extendLicense",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: temp,
          })
            .then(function (response) {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                // window.location.reload();
                GetLicense();
              });
            })
            .catch(function (error) {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        });
      })
      .catch(async (error) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถอัปโหลดเอกสารได้",
        });
      });
  };

  const GetCertificateType = async () => {
    // let temp = {
    //   company_id: getUser().com,
    //   oem_id: getOem(),
    // };
    axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/certificate/getCertificateType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      // data: temp,
    })
      .then(function (response) {
        setCerType({ ...cerType, data: response.data });
        cerType.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const GetCertificate = async () => {
    let temp = {
      name: Filter.name?.trim() || "",
      type: Filter.type?.trim() || "",
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/certificate/getCertificate",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
        setCertificate({ ...Certificate, data: response.data });
        Certificate.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const GetLicense = async () => {
    let temp = {
      name: Filter.name.trim() || "",
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/license/getLicense",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
        setLicense({ ...License, data: response.data });
        License.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const rowsData1 = [];
  for (let index = 0; index < Certificate.data.length; index += 1) {
    const rowItem1 = {};

    rowItem1["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem1["factory"] = Certificate.data[index].cer_name
      ? Certificate.data[index].cer_name
      : "-";

    // rowItem1["type"] = Certificate.data[index].cer_ctt_id
    //   ? Certificate.data[index].cer_ctt_id
    //   : "-";

    rowItem1["expire"] = Certificate.data[index].cer_exp_date
      ? moment(Certificate.data[index].cer_exp_date).format("DD/MM/yyyy")
      : "-";

    rowItem1["extendd"] = Certificate.data[index].cer_extend_date
      ? moment(Certificate.data[index].cer_extend_date).format("DD/MM/yyyy")
      : "-";

    rowItem1["extend"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-12 col-md-12 col-xs-12 ">
          <button
            type="button"
            className="btn btn-block btn-info"
            onClick={() => {
              setCer({
                ...Cer,
                cer_id: Certificate.data[index].cer_id,
                cer_name: Certificate.data[index].cer_name,
                cer_remark: Certificate.data[index].cer_remark,
                cer_exp_date: Certificate.data[index].cer_exp_date,
                cer_file_name: Certificate.data[index].cer_file_name,
                cer_file_path: Certificate.data[index].cer_file_path,
                cer_factory_name: Certificate.data[index].cer_factory_name,
                cer_ctt_id: Certificate.data[index].cer_ctt_id,
              });

              toggle();
            }}
          >
            Extend
          </button>
        </div>
      </div>
    );

    rowItem1["document"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-12 col-md-12 col-xs-12 ">
          <a
            href={
              Configs.API_URL_IMG_cuteplus +
              `/static/${Certificate.data[index].cer_file_name}/${Certificate.data[index].cer_file_path}`
            }
            download={
              Certificate.data[index].cer_file_name
                ? Certificate.data[index].cer_file_name
                : ""
            }
          >
            {/* <a
            // href={
            //   Configs.API_URL_IMG_cuteplus +
            //   `${Certificate.data[index].cer_file_name}/${Certificate.data[index].cer_file_path}`
            // }
          ></a> */}
            <button
              type="button"
              className="btn btn-block btn-primary"
              // onClick={() => {
              //   // console.log(Certificate.data);
              //   // DownloadFile();
              //   // Certificate.data[index].cer_file_name,
              //   // Certificate.data[index].cer_file_path
              // }}
            >
              Download
            </button>
          </a>
        </div>
      </div>
    );

    rowItem1["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Certificate.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/view/" +
                Certificate.data[index].cer_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Certificate.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/edit/" +
                Certificate.data[index].cer_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Certificate.data[index]}
            id={Certificate.data[index]}
            className=" btn btn-xs "
            onClick={delCertificate.bind(this, Certificate.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData1.push(rowItem1);
  }

  const CertificateTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Factory",
        field: "factory",
        // sort: "asc",
        width: 50,
      },
      // {
      //   label: "Certificate Type",
      //   field: "type",
      //   // sort: "asc",
      //   width: 50,
      // },
      {
        label: "Expire Date",
        field: "expire",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Extend Date",
        field: "extendd",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Extend Certificate",
        field: "extend",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Document",
        field: "document",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData1,
  };

  const rowsData2 = [];
  for (let index = 0; index < License.data.length; index += 1) {
    const rowItem2 = {};

    rowItem2["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem2["license"] = License.data[index].license_name
      ? License.data[index].license_name
      : "-";

    rowItem2["expire"] = License.data[index].license_exp_date
      ? moment(License.data[index].license_exp_date).format("DD/MM/yyyy")
      : "-";

    rowItem2["extendd"] = License.data[index].license_extend_date
      ? moment(License.data[index].license_extend_date).format("DD/MM/yyyy")
      : "-";

    rowItem2["extend"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-12 col-md-12 col-xs-12 ">
          <button
            type="button"
            className="btn btn-block btn-info"
            onClick={() => {
              setLic({
                ...Lic,
                license_id: License.data[index].license_id,
                license_name: License.data[index].license_name,
                license_remark: License.data[index].license_remark,
                license_exp_date: License.data[index].license_exp_date,
                license_file_name: License.data[index].license_file_name,
                license_file_path: License.data[index].license_file_path,
              });

              toggle();
            }}
          >
            Extend
          </button>
        </div>
      </div>
    );

    rowItem2["document"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-12 col-md-12 col-xs-12 ">
          {/* // href={
            //   Configs.API_URL_IMG_cuteplus +
            //   `${License.data[index].license_file_name}/${License.data[index].license_file_path}`
            // } */}
          <a
            href={
              Configs.API_URL_IMG_cuteplus +
              `/static/${License.data[index].license_file_name}/${License.data[index].license_file_path}`
            }
            download={
              License.data[index].license_file_name
                ? License.data[index].license_file_name
                : ""
            }
          >
            <button
              type="button"
              className="btn btn-block btn-primary"
              // onClick={() => {
              //   // DownloadFile(
              //   //   // Certificate.data[index].cer_file_name,
              //   //   // Certificate.data[index].cer_file_path
              //   // );
              // }}
            >
              Download
            </button>
          </a>
        </div>
      </div>
    );

    rowItem2["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3">
          <a
            key={License.data[index]}
            id="btn_delete"
            className=" btn btn-xs"
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/license/view/" +
                License.data[index].license_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3">
          <a
            key={License.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/license/edit/" +
                License.data[index].license_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3">
          <a
            key={License.data[index]}
            id={License.data[index]}
            className=" btn btn-xs "
            onClick={delLicense.bind(this, License.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData2.push(rowItem2);
  }

  const LicenseTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "License",
        field: "license",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Expire Date",
        field: "expire",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Extend Date",
        field: "extendd",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Extend License",
        field: "extend",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Document",
        field: "document",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData2,
  };

  async function clear() {
    setFilter({
      ...Filter,
      name: "",
      type: "",
    });
    Filter.name = "";
    Filter.type = "";

    await GetCertificate();
    await GetLicense();
  }

  useEffect(async () => {
    await GetCertificateType();
    await GetCertificate();
    await GetLicense();

    if (from === "license") {
      setTab(false);
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Certificate/License</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">
                    Certificate license
                  </li>
                </ol>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        name: e.target.value,
                      });
                      Filter.name = e.target.value;
                    }}
                  />
                  {Tab ? (
                    <label>Factory Name</label>
                  ) : (
                    <label>License Name</label>
                  )}
                </div>
              </div>

              {Tab ? (
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      value={Filter.type}
                      onChange={(e) => {
                        setFilter({
                          ...Filter,
                          type: e.target.value,
                        });
                        Filter.type = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select Type
                      </option>
                      {cerType.data.map((option, index) => (
                        <option key={index} value={option.ctt_id}>
                          {option.ctt_name}
                        </option>
                      ))}
                    </select>
                    {/* {Tab ? ( */}
                    <label>Certificate Type</label>
                    {/* ) : (
                      <label>License Type</label>
                    )} */}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      Tab ? await GetCertificate() : await GetLicense();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href = Tab
                        ? "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/add"
                        : "/Warehouse_Management/Factory_Master_Data_Cuteplus/Certificate_license/license/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className={
                            from === "license" ? "nav-link" : "nav-link active"
                          }
                          id={"tab-1"}
                          data-toggle="pill"
                          href={"#Certificate-tab"}
                          role="tab"
                          aria-controls={"Certificate-tab"}
                          aria-selected="true"
                          onClick={() => {
                            setTab(true);
                            // GetCertificate();
                          }}
                        >
                          Certificate
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className={
                            from === "license" ? "nav-link active" : "nav-link"
                          }
                          id={"tab-2"}
                          data-toggle="pill"
                          href={"#License-tab"}
                          role="tab"
                          aria-controls={"License-tab"}
                          aria-selected="false"
                          onClick={() => {
                            setTab(false);
                            // GetLicense();
                          }}
                        >
                          License
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-four-tabContent"
                    >
                      <div
                        className={
                          from === "license"
                            ? "tab-pane fade"
                            : "tab-pane fade show active"
                        }
                        // className="tab-pane fade show active"
                        id={"Certificate-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-1"}
                      >
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-12 col-md-12 col-xl-12">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={CertificateTable}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        // className="tab-pane fade"
                        className={
                          from === "license"
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                        }
                        id={"License-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-2"}
                      >
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-12 col-md-12 col-xl-12">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={LicenseTable}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        isOpen={modal}
        toggle={() => {
          setModalData({
            File_name: "",
            File_path: "",
            exp_date: "",
            ext_date: "",
          });

          setFile(null);

          toggle();
        }}
        size="md"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            setModalData({
              File_name: "",
              File_path: "",
              exp_date: "",
              ext_date: "",
            });

            setFile(null);

            toggle();
          }}
        >
          {Tab ? <h3>Extend Certificate</h3> : <h3>Extend License</h3>}
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-4">
              <label>
                File Upload{" "}
                {modalData.File_name === "" || modalData.File_name === null ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )}
              </label>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    // disabled={isDisabled}
                    id="exampleInputFile"
                    accept={allowedTypes}
                    onChange={(e) => {
                      if (e.target.files[0] !== undefined) {
                        if (allowedTypes.includes(e.target.files[0]?.type)) {
                          const file = e.target.files[0];

                          if (file.name) {
                            setFile(e.target.files[0]);

                            setModalData({
                              ...modalData,
                              File_name: e.target.files[0].name,
                              File_path: URL.createObjectURL(e.target.files[0]),
                            });
                          }
                        } else {
                          Swal.fire(
                            "อัปโหลดประเภทไฟล์ไม่ถูกต้อง",
                            "กรุณาอัปโหลดประเภทไฟล์ PNG, JPEG, CSV, WORD, EXCEL, PDF",
                            "error"
                          );

                          setFile(null);

                          setModalData({
                            ...modalData,
                            File_name: "",
                            File_path: "",
                          });

                          document.getElementById("exampleInputFile").value =
                            null;
                        }
                      } else {
                        setFile(null);

                        setModalData({
                          ...modalData,
                          File_name: "",
                          File_path: "",
                        });

                        document.getElementById("exampleInputFile").value =
                          null;
                      }
                    }}
                  />
                  <label
                    className="custom-file-label textVerticalOverflow-1"
                    htmlFor="exampleInputFile"
                  >
                    {modalData.File_name}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div class="form-group">
                <DatePicker
                  customInput={<CustomInput />}
                  wrapperClassName="w-100"
                  popperClassName="zI-2"
                  locale="th"
                  dateFormat={"dd-MM-yyyy"}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  disabledKeyboardNavigation
                  yearDropdownItemNumber={15}
                  selected={modalData.exp_date}
                  value={modalData.exp_date}
                  minDate={new Date()}
                  onChange={(e) => {
                    setModalData({
                      ...modalData,
                      exp_date: e,
                      ext_date:
                        modalData.ext_date < e ? modalData.ext_date : "",
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Expire Date
                  {modalData.exp_date === "" ||
                  modalData.exp_date === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>

            <div className="col-6">
              <div class="form-group">
                <DatePicker
                  customInput={<CustomInput />}
                  wrapperClassName="w-100"
                  popperClassName="zI-2"
                  locale="th"
                  dateFormat={"dd-MM-yyyy"}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  disabledKeyboardNavigation
                  yearDropdownItemNumber={15}
                  selected={modalData.ext_date}
                  value={modalData.ext_date}
                  maxDate={modalData.exp_date}
                  onChange={(e) => {
                    setModalData({
                      ...modalData,
                      ext_date: e,
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Extend Date
                  {modalData.ext_date === "" ||
                  modalData.ext_date === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            className="btn btn-danger"
            onClick={() => {
              setModalData({
                File_name: "",
                File_path: "",
                exp_date: "",
                ext_date: "",
              });

              setFile(null);

              toggle();
            }}
          >
            ยกเลิก
          </Button>
          {File !== null && modalData.exp_date !== "" && modalData.ext_date !== "" ? (
            <Button
              color="btn btn-info"
              onClick={async () => {
                if (Tab) {
                  await extendCertificate();
                } else {
                  await extendLicense();
                }

                setModalData({
                  File_name: "",
                  File_path: "",
                  exp_date: "",
                  ext_date: "",
                });

                setFile(null);

                toggle();
              }}
            >
              บันทึก
            </Button>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Table_Certificate_license;
