import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import TableLEK from "../../component/tableLEKTTT/table";

function Project(params) {
  const [test,settest] = useState({
    test:'',
  });
  const [headTable, setheadTable] = useState(
    [
      {
        label: "Project Name",
        field: "pro_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact Name",
        field: "contact_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact Tel",
        field: "contact_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact Email",
        field: "contact_email",
        sort: "asc",
        width: 50,
      },
      {
        label: "Detail",
        field: "detail",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
]
);
const [valueData, setvalueData] = useState([]);
const [countPage, setCountPage] = useState(1);
const [currentPage,setCurrentPage] = useState(1);
const [countData, setCountData] = useState(0);
const [entries, setEntries] = useState(10);
const [search, setSearch] = useState(0);
  const [project, setProject] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  const [filler_project, setFiller_project] = useState({
    project_name: "",
    oem_id: getOem(),
   company_id: getUser().com,
   all_search : ''
  });
  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetProject = async (page,size,search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if(search){
      setSearch(1);
  }else{
      setSearch(0);
  }
      let temp = {
      project_name: filler_project.project_name,
      oem_id: getOem(),
      company_id: getUser().com,
      page:page || 1,
      size:size || 10,
      all_search: (filler_project.all_search || "").trim()
      }
    //console.log(filler_project);
    axios({
      method: "post",
      url: Configs.API_URL + "/project/filter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log(response.data);
       /*  setProject(response.data); */
        
        setProject(response.data.data);
       setCountPage(response.data.countPage);
       setCountData(response.data.count)
       setEntries(size);
       settest({...test,test:''})
       test.test = '';
       Swal.close();
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetProject(1,10,'search');
    GetCompany();
  }, []);

  const clearFilter = async () => {
    await setFiller_project({
      ...filler_project,
      project_name:"",
      all_search:''
    });
   
    filler_project.project_name = "";
    filler_project.all_search = "";
    GetProject(1,10,'search');
   
  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Project/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/project/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Project";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];
 
  for (var index = 0; index < project.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["pro_name"] = project[index].project_name;
    rowItem["contact_name"] = project[index].contact_name;
    rowItem["contact_tel"] = (<><a href={"tel:"+project[index].contect_tel}>{project[index].contect_tel}</a></>);
    rowItem["contact_email"] = project[index].contact_email;
    rowItem["detail"] = project[index].detail;
 

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
             href={"/Warehouse_Management/Factory_Master_Data/Project/read/"+ project[index].id}
            key={project[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
              href={"/Warehouse_Management/Factory_Master_Data/Project/edit/"+ project[index].id}
             key={project[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
             key={project[index].id}
            className=" btn btn-xs "
             onClick={deldata.bind(this,project[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Project Name",
        field: "pro_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact Name",
        field: "contact_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact Tel",
        field: "contact_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Contact Email",
        field: "contact_email",
        sort: "asc",
        width: 50,
      },
      {
        label: "Detail",
        field: "detail",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      }
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Project{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Project</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_project.all_search}
                    onChange={(e) => {
                      setFiller_project({
                        ...filler_project,
                        all_search: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_project.project_name}
                    onChange={(e) => {
                      setFiller_project({
                        ...filler_project,
                        project_name: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Project Name</label>{" "}
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetProject(1,10,'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Project</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " style={{ whiteSpace: 'nowrap',padding:'2%' }}
            >
             
            <TableLEK       

                               
                                headTable = {headTable}
                                dataTable = {rowsData}
                                countPage = {countPage}
                                countData = {countData}
                                entries = {entries}
                                isSearch = {search}
                                callFnc ={GetProject}
                                
                                
                                />
            {/*            
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              /> */}
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
   {/*   <Footter/> */}
     </div>
     
  );
}

export default Project;
