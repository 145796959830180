import React, { Component, useEffect, useState } from "react";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "../Expenses_Approve/Expenses_Approve_Table.css";
import img_pay_slips from "../../../assets/img/accounting/Capa_1.png";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Button as ButtonAnt, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Select from "react-select";
import img_report_icon from "../../../assets/img/accounting/Frame.png";
import { userdefault_img } from "../../../routes/imgRoute/imgUrl";
import { GetStatusFilterAccounting } from "../Utils/Global_Function";

function Emp_Expenses_Admin_Table() {
  const [disableRetreatAfterSorting, setDisableRetreatAfterSorting] =
    useState(true);

  const [statusDropdown, setStatusDropdown] = useState({ data: [] });

  const [previewOpen, setPreviewOpen] = useState(false);

  const [previewImage, setPreviewImage] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [modalState, setModalState] = useState("");

  const [dataForSave, setDataForSave] = useState({
    id: "",
    remark_not_approve: "",
    type: ""
  });

  const [filterDataTable, setFilterDataTable] = useState({ data: null });

  const [fileList, setFileList] = useState([]);


  const [filterExpenses, setFilterExpenses] = useState({
    search: "",
    start_date: new Date(moment().startOf("month")),
    end_date: new Date(moment().endOf("month")),
    is_select_all: false,
    status_id: "",
    paid_by: "",
  });

  const [expensesData, setExpensesData] = useState({
    data: [],
    sum_amount: 0,
    start_d: new Date(moment().startOf("month")),
    end_d: new Date(moment().endOf("month")),
  });

  const [fileListApprove, setFileListApprove] = useState({
    data: [],
    onIndex: 0,
  });

  const [allEmployee, setAllEmployee] = useState({ data: [] });
 

  const ACCOUNTING_STATUS = {
    draft: "3d04de87-1ded-4968-b533-e8b2dda0c4d8", // แบบร่าง
    waiting: "a86edd8a-5b08-445c-8377-9def93ceb8c0", // รอดำเนินการ
    approve_not_upload: "99c1692b-8baa-46b3-983a-c5839a48cf84", // อนุมัติ	// อนุมัติแบบไม่ upload
    approve_uploaded: "d4329102-39f6-420a-820f-7c00f98cbcb3", // อนุมัตื	// อนุมัติแบบ upload
    not_approve: "8ab5629d-754c-4eda-8b8e-bd2920fa6167", // ไม่อนุมัติ
  };

  

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCloseModal = () => {
    setShowModal(false);
    setFileListApprove({
      ...fileListApprove,
      onIndex: 0
    });
    fileListApprove.onIndex = 0;
  };

  const handleOpenModal = (state) => {
    setModalState(state);
    setShowModal(true);        
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    // console.log("handleRemove",file.img_id);
    // setDelIMG([...delIMG, file.img_id]);
    setFileList(newFileList);
  };

  const handleUploadSuccess = (file) => {
    setFileList(file.fileList);
  };

  const GetEmployee = async () => {
    var get_filler_employee = {
      oem_id: getOem(),
      company_id: getUser().com,
      emp_no: "",
      emp_name_th: "",
      emp_type_id: "",
      status_User: true,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_employee,
    })
      .then(async function (response) {
        if (response.data && Array.isArray(response.data.data)) {
          const data = response.data.data.filter((el) => (
            el.emp_status_type_id !== "9437e6c7-68aa-4ee6-b7da-4b54b14c07ba" // ลาออก
            && el.is_active !== false 
          )).map((el) => ({ value: el.emp_id, label: el.emp_name_th }));
          data.unshift({ value: null, label: 'ทั้งหมด' });
          setAllEmployee({ ...allEmployee, data: data });
          allEmployee.data = data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  
  async function getTypesApproveOrgzById(emp_exp_id) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        ("/api/orgzExpenses/getTypesApproveOrgzById"),
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        emp_exp_id: emp_exp_id,
        user_id: getUser().fup,
        
      },
    })
      .then(function (response) {
        // console.log("response :>> ", response.data.data);
        
        if (response.data.data && Array.isArray(response.data.data)) {
          setFileListApprove({ ...fileListApprove, data: response.data.data });
          fileListApprove.data = response.data.data;
          
          
        } else {
          setFileListApprove({ ...fileListApprove, data: [] });
          fileListApprove.data = [];
        }
        // console.log(fileListApprove.data.map(item => item.emp_type))
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function getFilesApproveById(emp_exp_id) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        ("/api/orgzExpenses/getFilesApproveOrgzById"),
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        emp_exp_id: emp_exp_id,
        user_id: getUser().fup,
      },
    })
      .then(function (response) {
        // console.log("response :>> ", response.data.data);
        if (response.data.data && Array.isArray(response.data.data)) {
          setFileListApprove({ ...fileListApprove, data: response.data.data });
          fileListApprove.data = response.data.data;
        } else {
          setFileListApprove({ ...fileListApprove, data: [] });
          fileListApprove.data = [];
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function SendStatusExpensesByAdmin(state) {
    const data = {
      emp_exp_id: dataForSave.id,
      status_id: "",
      remark: dataForSave.remark_not_approve.trim(),
      user_id: getUser().fup,
      type : dataForSave.type
    };
    if (state === "not_aprrove") {
      data.status_id = ACCOUNTING_STATUS.not_approve;
      if (!data.remark) {
        Swal.fire("Error", `กรุณากรอก Remark`, "error");
        return;
      }
    } else if (state === "aprrove") {
      data.status_id = ACCOUNTING_STATUS.approve_not_upload;
      data.remark = null;
      data.is_approved = true;
    }
    handleCloseModal();
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/SendStatusExpensesByAdmin",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data) {
          Swal.fire({
            icon: "success",
            title: "ทำรายการสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            GetAllExpenses();
            setDataForSave({
              ...dataForSave,
              remark_not_approve: "",
            });
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function GetAllExpenses() {
    setDisableRetreatAfterSorting(false);
    let data = {
      company_id: getUser().com,
      oem_id: getOem(),
      search: filterExpenses.search,
      start_date: filterExpenses.start_date,
      end_date: filterExpenses.end_date,
      not_include_status: [ACCOUNTING_STATUS.draft],
      status_id: filterExpenses.status_id,
      paid_by: filterExpenses.paid_by,
      is_admin: true
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/orgzExpenses/filterExpensesOfOrgz",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        if (response.data.data && Array.isArray(response.data.data)) {
          const _sum_amount = response.data.data.reduce(
            (accumulator, currentValue) =>
              accumulator +
              Number(
                currentValue.emp_exp_status == ACCOUNTING_STATUS.not_approve
                  ? 0
                  : currentValue.emp_exp_amount || 0
              ),
            0
          );

          setExpensesData({
            ...expensesData,
            data: response.data.data,
            sum_amount: _sum_amount,
            start_d: data.start_date,
            end_d: data.end_date,
          });
          expensesData.data = response.data.data;
          expensesData.sum_amount = _sum_amount;
          expensesData.start_d = data.start_date;
          expensesData.end_d = data.end_date;
          setFilterExpenses({
            ...filterExpenses,
            is_select_all: false,
          });
          filterExpenses.is_select_all = false;
        }
        setDisableRetreatAfterSorting(true);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  function clearFilter() {
    setFilterExpenses({
      ...filterExpenses,
      start_date: new Date(moment().startOf("month")),
      end_date: new Date(moment().endOf("month")),
      is_select_all: false,
      search: "",
      status_id: "",
      paid_by: "",
    });
    filterExpenses.start_date = new Date(moment().startOf("month"));
    filterExpenses.end_date = new Date(moment().endOf("month"));
    filterExpenses.search = "";
    filterExpenses.status_id = "";
    filterExpenses.paid_by = "";
    filterExpenses.is_select_all = false;
    GetAllExpenses();
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group" style={{ width: "100%" }}>
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
        data-provide="datepicker"
        data-date-language="th-th"
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  function GetDivAlign(value, align) {
    return <div style={{ textAlign: align || "left" }}>{value}</div>;
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  async function fetchAndPreviewPDF(emp_exp_id, is_table_emp) {
    Swal.fire({
      title: "กำลังออกรายงาน",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const response = await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/orgzExpenses/viewReportBillPay",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        emp_exp_id: Array.isArray(emp_exp_id) ? emp_exp_id : [emp_exp_id],
        user_id: getUser().fup,
        url: Configs.API_URL_IMG,
        is_table_emp,
      },
    });
    // console.log("response.data.data :>> ", response.data.data);
    const data = await response.data.data;

    // // Decode the base64 PDF string
    // const byteCharacters = atob(data.base64);
    // const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
    // const byteArray = new Uint8Array(byteNumbers);

    // // Create a Blob from the byte array
    // const blob = new Blob([byteArray], { type: "application/pdf" });

    // // Create a URL for the Blob and open it in a new tab
    // const pdfUrl = URL.createObjectURL(blob);
    // window.open(pdfUrl, "_blank");

    // Create a data URL for the PDF
    const pdfDataUrl = `data:application/pdf;base64,${data.base64}`;
    // console.log('pdfDataUrl :>> ', pdfDataUrl);
    const link = document.createElement("a");
    link.href = pdfDataUrl;
    link.download = data.file_name || "downloaded_file.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up after click

    if (data.file_name) {
      await delFileReport(data.file_name);
    }
    Swal.close();
  }

  async function delFileReport(file_name) {
    await axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement + "/api/orgzExpenses/delReportBillPay",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: {
        file_name: file_name,
        folder: `${getUser().fup.replace(/-/g, "_")}`,
      },
    }).catch((error) => {
      console.log("error :>> ", error);
    });
  }


  function getEmpTypeDescription(emp_type) {
    switch (emp_type) {
      case "2":
        return "ใบเสร็จ";
      case "3":
        return "ใบเสร็จสำรองจ่าย";
      default:
        return "Unknown Type";
    }
  }

  async function ApproveHaveFiles() {
    const error_list = [];
    if (fileList.length == 0) {
      let temp_err = {
        message: "กรุณาอัปโหลดรูปภาพ",
      };
      error_list.push(temp_err);
    }
    if (dataForSave.remark_not_approve.trim() == "") {
      let temp_err = {
        message: "กรุณากรอก Remark",
      };
      error_list.push(temp_err);
    }
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
      return;
    }

    Swal.fire({
      title: "คุณมั่นใจที่จะแนบหลักฐานการชำระเงินหรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `บันทึก`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ยกเลิก`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        for (let item of fileList) {
          data.append("Profile", item.originFileObj);
        }
        data.append("typeFile", "exp_upload_file");

        await axios({
          method: "post",
          url: Configs.API_URL + "/upload/document",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(async function (response) {
            if (response.data.status) {
              const file_list = response.data.data?.map((el) => {
                return { name: el.orgin_name, path: el.path };
              });
              await axios({
                method: "post",
                url:
                  Configs.API_URL_hrMagenatement +
                  "/api/orgzExpenses/ApproveHaveFiles",
                headers: {
                  Authorization: getToken(),
                  "Content-Type": "application/json",
                  "X-TTT": Configs.API_TTT,
                },
                data: {
                  emp_exp_id: Array.isArray(dataForSave.id)
                    ? dataForSave.id
                    : [dataForSave.id],
                  file_list: file_list,
                  remark: dataForSave.remark_not_approve,
                  user_id: getUser().fup,
                  status_id: ACCOUNTING_STATUS.approve_uploaded,
                  emp_support_paid_id: dataForSave?.emp_support_paid_id || null,
                  is_admin: true
                },
              })
                .then(function (response) {})
                .catch(function (error) {
                  console.log(error);
                });

              Swal.fire({
                icon: "success",
                title: "ทำรายการสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                handleCloseModal();
                setFileList([]);
                setFilterExpenses({
                  ...filterExpenses,
                  is_select_all: false,
                });
                GetAllExpenses();
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }


  function GetBodyModal(modalState) {
    if (modalState === "not_approve") {
      return (
        <div style={{ padding: "15px" }}>
          <textarea
            type="text"
            className="form-control"
            required
            rows={4}
            id="award_point_name"
            value={dataForSave.remark_not_approve}
            onChange={(e) => {
              // check_rf_name(e);
              setDataForSave({
                ...dataForSave,
                remark_not_approve: e.target.value,
              });
              dataForSave.remark_not_approve = e.target.value;
            }}
          />
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={() => {
                SendStatusExpensesByAdmin("not_aprrove");
              }}
              className="btn btn-success"
              id="btn-save-SendStatusExpensesByAdmin"
              key="btn-save-SendStatusExpensesByAdmin"
            >
              Save
            </button>
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={() => {
                handleCloseModal();
              }}
              className="btn btn-danger"
              id="btn-cancle-handleCloseModal"
              key="btn-cancle-handleCloseModal"
            >
              Cancel
            </button>
          </div>
        </div>
      );
    } else if (modalState === "upload_slips") {
      return (
        <div /* style={{ padding: '15px'}} */>
          <div
            className="area-upload-files-expenses-user"
            style={{
              /* display: "flex",
              flexDirection: "column",
              alignItems: "center", */
              padding: "15px",
            }}
          >
            <Upload
              beforeUpload={() => false}
              listType="picture"
              fileList={fileList}
              onRemove={handleRemove}
              onPreview={handlePreview}
              onChange={handleUploadSuccess}
              accept=".jpg,.png"
              multiple
            >
              <ButtonAnt
                type="primary"
                style={{ backgroundColor: "#3579F626", color: "#3579F6" }}
              >
                Choose files
              </ButtonAnt>
            </Upload>
          </div>
          <div>
            <span
              style={{
                fontSize: "24px",
                fontWeight: "800",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <textarea
                type="text"
                className="form-control"
                required
                rows={4}
                id="award_point_name"
                value={dataForSave.remark_not_approve}
                onChange={(e) => {
                  // check_rf_name(e);
                  setDataForSave({
                    ...dataForSave,
                    remark_not_approve: e.target.value,
                  });
                  dataForSave.remark_not_approve = e.target.value;
                }}
              />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              paddingBottom: "15px",
            }}
          >
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={ApproveHaveFiles}
              className="btn btn-success"
              id="btn-save"
            >
              Save
            </button>
            <button
              type="button"
              style={{ minWidth: "130px" }}
              onClick={handleCloseModal}
              className="btn btn-danger"
              id="btn-cancle"
            >
              Cancel
            </button>
          </div>
        </div>
      );
    } else if (modalState === "view_slips") {
      return (
        <div /* style={{ padding: "15px" }} */>
          <div
            style={{
              padding: "15px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              hidden={fileListApprove.data.length <= 1}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index - 1 == -1) {
                  index = fileListApprove.data.length - 1;
                } else {
                  index -= 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
                fileListApprove.onIndex = index;
              }}
            >
              {"<"}
            </div>
            <div
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {fileListApprove.data?.[fileListApprove.onIndex]
                ?.emp_file_path ? (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid "
                  src={
                    Configs.API_URL_IMG +
                    fileListApprove.data?.[fileListApprove.onIndex]
                      .emp_file_path
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    objectFit: "contain",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                />
              ) : (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid rounded shadow border-0"
                  src={userdefault_img.imgs}
                  style={{
                    // width: "60px",
                    // height: "60px",
                    position: "relative",
                    objectFit: "cover",
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                />
              )}
            </div>
            <div
              hidden={fileListApprove.data.length <= 1}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index + 1 == fileListApprove.data.length) {
                  index = 0;
                } else {
                  index += 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
                fileListApprove.onIndex = index;
              }}
            >
              {">"}
            </div>
          </div>
          <div>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <p>{dataForSave.remark_not_approve || "-"}</p>
            </div>
          </div>
        </div>
      );
    } else if (modalState === "view_remark_not_approve") {
      return (
        <div /* style={{ padding: "15px" }} */>
          <div>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                padding: "0px 15px",
              }}
            >
              Remark
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
              <p>{dataForSave.remark_not_approve || "-"}</p>
            </div>
          </div>
        </div>
      );
    }
    if (modalState === "view_type") {
      return (
        <div>
          <div
            style={{
              padding: "15px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              hidden={fileListApprove.data.length === 0}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index - 1 === -1) {
                  index = fileListApprove.data.length - 1;
                } else {
                  index -= 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
              }}
            >
              {"<"}
            </div>
            <div
              style={{
                width: "100%",
                height: "400px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {fileListApprove.data?.[fileListApprove.onIndex]?.emp_file_path ? (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid"
                  src={
                    Configs.API_URL_IMG +
                    fileListApprove.data[fileListApprove.onIndex].emp_file_path
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              ) : (
                <img
                  id="img"
                  alt="..."
                  className="img-fluid rounded shadow border-0"
                  src={userdefault_img.imgs}
                  style={{
                    objectFit: "cover",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              )}
            </div>
            <div
              hidden={fileListApprove.data.length === 0}
              style={{ fontSize: "24px", fontWeight: "800", cursor: "pointer" }}
              onClick={() => {
                let index = fileListApprove.onIndex;
                if (index + 1 === fileListApprove.data.length) {
                  index = 0;
                } else {
                  index += 1;
                }
                setFileListApprove({
                  ...fileListApprove,
                  onIndex: index,
                });
              }}
            >
              {">"}
            </div>
          </div>
          <div>
            <span
              style={{
                fontSize: "20px",
                fontWeight: "600",
                padding: "0px 15px",
              }}
            >
              Type
            </span>
            <hr style={{ margin: "0px" }} />
            <div style={{ padding: "15px" }}>
            <p>{getEmpTypeDescription(fileListApprove.data[fileListApprove.onIndex]?.emp_type) || "-"}</p>
            </div>
          </div>
        </div>
      );
    }
    
  }


  function generateDataTable(expenses, index) {
    const element = {};

    element["select"] = (
      <div style={{ textAlign: "center", paddingTop: "0px" }}>
        <input
          style={{ width: "20px", height: "20px" }}
          type="checkbox"
          id="select_all"
          checked={expenses.is_select || false}
          onChange={(e) => {
            const dataTemp = [...expensesData.data];
            dataTemp[index].is_select = e.target.checked;
            setExpensesData({
              ...expensesData,
              data: dataTemp,
            });
            expensesData.data = dataTemp;

            const temp_is_select_all = dataTemp.every((el) => el.is_select);
            setFilterExpenses({
              ...filterExpenses,
              is_select_all: temp_is_select_all,
            });
            filterExpenses.is_select_all = temp_is_select_all;
          }}
        ></input>
      </div>
    );

    element["no"] = GetDivAlign(index + 1, "center");

    element["uep_code"] = GetDivAlign(expenses.uep_code || '-', 'center');
    element["cep_code"] = GetDivAlign(expenses.cep_code || '-', 'center');
    element["aep_code"] = GetDivAlign(expenses.aep_code || '-', 'center');

    element["no_search"] = index + 1;

    element["expenses_date"] = GetDivAlign(
      moment(expenses.emp_exp_start_date).format("DD-MM-YYYY"),
      "center"
    );
    element["expenses_date_search"] = moment(
      expenses.emp_exp_start_date
    ).format("DD-MM-YYYY");

    element["expenses_type_expenses"] = GetDivAlign(
      <div style={{ minWidth: "120px" }}>{expenses.acc_cc_name || "-"}</div>,
      "left"
    );
    element["expenses_type_expenses_search"] = expenses.acc_cc_name || "-";

    element["expenses_project"] = GetDivAlign(expenses.acc_project_name || "-", "left");

    element["expenses_header_expenses"] = GetDivAlign(
      expenses.acc_et_name || "-",
      "center"
    );
    element["expenses_header_expenses_search"] = expenses.acc_et_name || "-";

    element["expenses_amount"] = GetDivAlign(
      Number(expenses.emp_exp_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-",
      "right"
    );
    element["expenses_amount_search"] =
      Number(expenses.emp_exp_amount || 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) || "-";

    element["expenses_payer"] = GetDivAlign(
      <div style={{ minWidth: "120px" }}>
        {expenses.emp_name_th
          ? `${expenses.emp_name_th} (${expenses.nickname})`
          : "-"}
        {expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded ? (
          <p
            style={{
              color: "#1DC256",
              fontSize: "16px",
              padding: 0,
              margin: 0,
            }}
          >
            จ่ายแล้ว
          </p>
        ) : (
          <></>
        )}
      </div>,
      "left"
    );
    
    element["expenses_payer_supply"] = GetDivAlign(
      <div style={{ minWidth: "120px" }}>
        {expenses.emp_exp_paid_by_old && expenses.emp_exp_paid_by !== expenses.emp_exp_paid_by_old
          ? allEmployee.data.find(el => el.value == expenses.emp_exp_paid_by_old)?.label || '-'
          : "-"}
        {expenses.emp_exp_paid_by_old &&
          expenses.emp_exp_paid_by !== expenses.emp_exp_paid_by_old &&
          expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded ? (
          <p
            style={{
              color: "#1DC256",
              fontSize: "16px",
              padding: 0,
              margin: 0,
            }}
          >
            จ่ายแล้ว
          </p>
        ) : (
          <></>
        )}
      </div>,
      "left"
    );
    
    element["expenses_payer_search"] =
      expenses.emp_name_th ||
      "-" + expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
        ? "จ่ายแล้ว"
        : "";
    element["expenses_status"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {expenses.emp_exp_status === ACCOUNTING_STATUS.waiting ? (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <div
              className="expenses-admin-status_1 btn-expenses-admin-status4 cursor_expenses_admin_status"
              id="approve-admin"
              key="approve-admin"
              onClick={() => {
                // alert('test')
                Swal.fire({
                  title: "คุณต้องการที่จะอนุมัติหรือไม่",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: `บันทึก`,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: `ยกเลิก`,
                }).then((result) => {
                  if (filterDataTable.data) {
                    sessionStorage.setItem(
                      "state_filter_expenses",
                      filterDataTable.data
                    );
                  }
                  if (result.isConfirmed) {
                    setDataForSave({
                      ...dataForSave,
                      id: expenses.emp_exp_id,
                    });
                    dataForSave.id = expenses.emp_exp_id;
                    SendStatusExpensesByAdmin("aprrove");
                  }
                });
              }}
            >
              อนุมัติ
            </div>
            <div
              className="expenses-admin-status_4 btn-expenses-admin-status4 cursor_expenses_admin_status"
              id="not-approve-admin"
              key="not-approve-admin"
              onClick={() => {
                Swal.fire({
                  title: "คุณต้องการที่จะไม่อนุมัติหรือไม่",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: `บันทึก`,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: `ยกเลิก`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    setDataForSave({
                      ...dataForSave,
                      id: expenses.emp_exp_id,
                      remark_not_approve: ''
                    });
                    dataForSave.id = expenses.emp_exp_id;
                    dataForSave.remark_not_approve = '';
                    handleOpenModal("not_approve");
                  }
                });
              }}
            >
              ไม่อนุมัติ
            </div>
          </div>
        ) : (
          <div
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
            className={
              "btn-expenses-user-status" +
              (expenses.emp_exp_status === ACCOUNTING_STATUS.draft
                ? " expenses_user_status_0"
                : expenses.emp_exp_status ===
                  ACCOUNTING_STATUS.approve_not_upload
                ? " expenses_user_status_1"
                : expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
                ? " expenses_user_status_2 cursor_expenses_admin_status"
                : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
                ? " expenses_user_status_3 cursor_expenses_admin_status"
                : " expenses_user_status_4")
            }
            onClick={async () => {
              if (
                expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
              ) {
                setDataForSave({
                  ...dataForSave,
                  id: expenses.emp_exp_id,
                  remark_not_approve: expenses.emp_exp_remark,
                });
                dataForSave.id = expenses.emp_exp_id;
                dataForSave.remark_not_approve = expenses.emp_exp_remark;
                getFilesApproveById(expenses.emp_exp_id);
                handleOpenModal("view_slips");
              } else if (
                expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
              ) {
                setDataForSave({
                  ...dataForSave,
                  id: expenses.emp_exp_id,
                  remark_not_approve: expenses.emp_exp_remark,
                });
                dataForSave.id = expenses.emp_exp_id;
                dataForSave.remark_not_approve = expenses.emp_exp_remark;
                handleOpenModal("view_remark_not_approve");
              }
            }}
          >
            {expenses.emp_status_name}
            {expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded ? (
              <i
                style={{ fontSize: "16px" }}
                class="ri-money-dollar-circle-fill"
              ></i>
            ) : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve ? (
              <i style={{ fontSize: "16px" }} class="ri-message-fill"></i>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );
 //new comlumn Image
    element["image"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
         
         <button className="image_size btn-primary "
           onClick={ () => {
                dataForSave.id = expenses.emp_exp_id;
                
                dataForSave.remark_not_approve = expenses.emp_exp_remark;
                // getFilesApproveById();
                getTypesApproveOrgzById(expenses.emp_exp_id)
                handleOpenModal("view_type");      
                
            
          }}
          >Click</button>
        
    
  
      </div>
    );

    element["expenses_status"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {expenses.emp_exp_status === ACCOUNTING_STATUS.waiting ? (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <div
              className="expenses-admin-status_1 btn-expenses-admin-status4 cursor_expenses_admin_status"
              id="approve-admin"
              key="approve-admin"
              onClick={() => {
                // alert('test')
                Swal.fire({
                  title: "คุณต้องการที่จะอนุมัติหรือไม่",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: `บันทึก`,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: `ยกเลิก`,
                }).then((result) => {
                  if (filterDataTable.data) {
                    sessionStorage.setItem(
                      "state_filter_expenses",
                      filterDataTable.data
                    );
                  }
                  if (result.isConfirmed) {
                    setDataForSave({
                      ...dataForSave,
                      id: expenses.emp_exp_id,
                    });
                    dataForSave.id = expenses.emp_exp_id;
                    SendStatusExpensesByAdmin("aprrove");
                  }
                });
              }}
            >
              อนุมัติ
            </div>
            <div
              className="expenses-admin-status_4 btn-expenses-admin-status4 cursor_expenses_admin_status"
              id="not-approve-admin"
              key="not-approve-admin"
              onClick={() => {
                Swal.fire({
                  title: "คุณต้องการที่จะไม่อนุมัติหรือไม่",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: `บันทึก`,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: `ยกเลิก`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    setDataForSave({
                      ...dataForSave,
                      id: expenses.emp_exp_id,
                      remark_not_approve: ''
                    });
                    dataForSave.id = expenses.emp_exp_id;
                    dataForSave.remark_not_approve = '';
                    handleOpenModal("not_approve");
                  }
                });
              }}
            >
              ไม่อนุมัติ
            </div>
          </div>
        ) : (
          <div
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
            className={
              "btn-expenses-user-status" +
              (expenses.emp_exp_status === ACCOUNTING_STATUS.draft
                ? " expenses_user_status_0"
                : expenses.emp_exp_status ===
                  ACCOUNTING_STATUS.approve_not_upload
                ? " expenses_user_status_1"
                : expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
                ? " expenses_user_status_2 cursor_expenses_admin_status"
                : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
                ? " expenses_user_status_3 cursor_expenses_admin_status"
                : " expenses_user_status_4")
            }
            onClick={async () => {
              if (
                expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded
              ) {
                setDataForSave({
                  ...dataForSave,
                  id: expenses.emp_exp_id,
                  remark_not_approve: expenses.emp_exp_remark,
                });
                dataForSave.id = expenses.emp_exp_id;
                dataForSave.remark_not_approve = expenses.emp_exp_remark;
                getFilesApproveById(expenses.emp_exp_id);
                handleOpenModal("view_slips");
              } else if (
                expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve
              ) {
                setDataForSave({
                  ...dataForSave,
                  id: expenses.emp_exp_id,
                  remark_not_approve: expenses.emp_exp_remark,
                });
                dataForSave.id = expenses.emp_exp_id;
                dataForSave.remark_not_approve = expenses.emp_exp_remark;
                handleOpenModal("view_remark_not_approve");
              }
            }}
          >
            {expenses.emp_status_name}
            {expenses.emp_exp_status === ACCOUNTING_STATUS.approve_uploaded ? (
              <i
                style={{ fontSize: "16px" }}
                class="ri-money-dollar-circle-fill"
              ></i>
            ) : expenses.emp_exp_status === ACCOUNTING_STATUS.not_approve ? (
              <i style={{ fontSize: "16px" }} class="ri-message-fill"></i>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );

    element["mgt"] = (
      <div
        className=""
        style={{
          // flexWrap: "nowrap",
          display: "flex",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <div
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{
            textAlign: "center",
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
        >
          <a
            key={expenses.emp_exp_id + "del"}
            id="btn_delete"
            style={{ border: "none", cursor: "pointer" }}
            className=" btn btn-xs "
            onClick={() => {
              fetchAndPreviewPDF(expenses.emp_exp_id, false);
            }}
          >
            <img width={18} src={img_report_icon} />
          </a>
        </div>
        <div
          // className="col-xl-6 col-md-6 col-xs-6"
          style={{ cursor: "pointer", textAlign: "center", padding: 0 }}
        >
          <a
            href={
              "/accounting/expenses_approve/view/" +
              expenses.emp_exp_id
            }
            id="btn_read"
            key={expenses.rp_id + "view"}
            className="btn btn-xs "
            // filterExpenses
            onClick={() => {
              sessionStorage.setItem(
                "state_filter_expenses",
                JSON.stringify({
                  search: filterExpenses.search,
                  start_date: filterExpenses.start_date,
                  end_date: filterExpenses.end_date,
                })
              );
            }}
          >
            <i class="fas fa-eye"></i>
            {"   "}
          </a>
        </div>
        <div
          hidden={
            expenses.emp_exp_status !== ACCOUNTING_STATUS.approve_not_upload
          }
          // className="col-xl-3 col-md-3 col-xs-3"
          style={{
            textAlign: "center",
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
        >
          <a
            key={expenses.emp_exp_id + "del"}
            id="btn_delete"
            style={{ border: "none", cursor: "pointer" }}
            className=" btn btn-xs "
            onClick={() => {
              setDataForSave({
                ...dataForSave,
                id: expenses.emp_exp_id,
                remark_not_approve: "",
                emp_paid_id: expenses.emp_exp_paid_by_old,
                emp_support_paid_id: "",
                emp_support_paid_name: "",
              });
              dataForSave.id = expenses.emp_exp_id;
              dataForSave.remark_not_approve = "";
              dataForSave.emp_paid_id = expenses.emp_exp_paid_by_old;
              dataForSave.emp_support_paid_id = "";
              dataForSave.emp_support_paid_name = "";
              handleOpenModal("upload_slips");
            }}
          >
            <img width={16} src={img_pay_slips} />
          </a>
        </div>
      </div>
    );
    return element;
  }

  const dataExpenses = [];
  for (let index = 0; index < expensesData.data.length; index++) {
    const expenses = expensesData.data[index];
    dataExpenses.push(generateDataTable(expenses, index));
  }

  const data = {
    columns: [
      // {
      //   label: (
      //     <div style={{ textAlign: "center", paddingTop: "10px" }}>
      //       <input
      //         style={{ width: "20px", height: "20px" }}
      //         type="checkbox"
      //         id="select_all"
      //         checked={filterExpenses.is_select_all}
      //         onChange={(e) => {
      //           const dataTemp = [...expensesData.data];
      //           dataTemp.forEach((el) => {
      //             el.is_select = e.target.checked;
      //           });
      //           setExpensesData({
      //             ...expensesData,
      //             data: dataTemp,
      //           });
      //           expensesData.data = dataTemp;
      //           setFilterExpenses({
      //             ...filterExpenses,
      //             is_select_all: e.target.checked,
      //           });
      //           filterExpenses.is_select_all = e.target.checked;
      //         }}
      //       ></input>
      //     </div>
      //   ),
      //   field: "select",
      //   // sort: "asc",
      //   width: 50,
      // },
      {
        label: (
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <input
              style={{ width: "20px", height: "20px" }}
              type="checkbox"
              id="select_all"
              checked={filterExpenses.is_select_all}
              onChange={(e) => {
                const dataTemp = [...expensesData.data];
                dataTemp.forEach((el) => {
                  el.is_select = e.target.checked;
                });
                setExpensesData({
                  ...expensesData,
                  data: dataTemp,
                });
                expensesData.data = dataTemp;
                setFilterExpenses({
                  ...filterExpenses,
                  is_select_all: e.target.checked,
                });
                filterExpenses.is_select_all = e.target.checked;
              }}
            ></input>
          </div>
        ),
        field: "select",
        // sort: "asc",
        width: 50,
      },
      {
        label: GetDivAlign("No.", "center"),
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "UEP",
        field: "uep_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "CEP",
        field: "cep_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "AEP",
        field: "aep_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "วันที่",
        field: "expenses_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทรายจ่าย",
        field: "expenses_type_expenses",
        // sort: "asc",
        width: 100,
      },
      {
        label: "โครงการ",
        field: "expenses_project",
        // sort: "asc",
        width: 50,
      },
      {
        label: "หัวข้อค่าใช้จ่าย",
        field: "expenses_header_expenses",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ยอดเงิน",
        field: "expenses_amount",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ผู้จ่าย",
        field: "expenses_payer",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ผู้สำรองจ่าย",
        field: "expenses_payer_supply",
        // sort: "asc",
        width: 50,
      },
      {
        label: "รูปภาพหลักฐาน",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "expenses_status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        // sort: "asc",
        // width: 50,
      },
    ],
    rows: dataExpenses,
  };

  // async function getFilesApproveByRefId(emp_exp_id) {
  //   await axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_hrMagenatement +
  //       "/api/orgzExpenses/getFilesApproveOrgzByRefId",
  //     headers: {
  //       Authorization: getToken(),
  //       "Content-Type": "application/json",
  //       "X-TTT": Configs.API_TTT,
  //     },
  //     data: {
  //       emp_exp_id: emp_exp_id,
  //       user_id: getUser().fup,
  //     },
  //   })
  //     .then(function (response) {
  //       // console.log("response :>> ", response.data.data);
  //       if (response.data.data && Array.isArray(response.data.data)) {
  //         setFileListApprove({ ...fileListApprove, data: response.data.data });
  //         fileListApprove.data = response.data.data;
  //       } else {
  //         setFileListApprove({ ...fileListApprove, data: [] });
  //         fileListApprove.data = [];
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  async function GetStatusDropdown() {
    const data = await GetStatusFilterAccounting({
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      type: 1 // 1 is status expenses 2 is tax witholding
    });
    const temp_data = data.filter(el => el.value !== ACCOUNTING_STATUS.draft);
    setStatusDropdown({
      data: temp_data
    });
    statusDropdown.data = temp_data;
  }

  useEffect(() => {
    const data2 = sessionStorage.getItem("state_filter_expenses");
    if (data2) {
      const data_filter = JSON.parse(data2);
      setFilterExpenses({
        ...filterExpenses,
        search: data_filter.search,
        start_date: new Date(data_filter.start_date),
        end_date: new Date(data_filter.end_date),
      });
      filterExpenses.search = data_filter.search;
      filterExpenses.start_date = new Date(data_filter.start_date);
      filterExpenses.end_date = new Date(data_filter.end_date);
      sessionStorage.removeItem("state_filter_expenses");
    }
    GetStatusDropdown();
    GetEmployee();
    GetAllExpenses();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Expenses Approve{"  "}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Accounting</li>
                  <li className="breadcrumb-item active">Expenses Approve</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filterExpenses.search}
                    onChange={(e) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterExpenses.start_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        start_date: date,
                      });
                    }}
                    maxDate={new Date(filterExpenses.end_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>Start Date</label>
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                /* style={{ "z-index": "99" }} */
              >
                <div
                  class={`form-group hr-log-add-datepicker-custom`}
                  /* style={{ zIndex: "98" }} */
                >
                  <DatePicker
                    // disabled={true}
                    selected={
                      filterExpenses.end_date
                      // new Date(moment(new Date()).add(1, "day"))
                    }
                    required
                    locale="th"
                    id="text_start_work"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => {
                      setFilterExpenses({
                        ...filterExpenses,
                        end_date: date,
                      });
                    }}
                    minDate={new Date(filterExpenses.start_date)}
                    customInput={<CustomInput />}
                  ></DatePicker>
                  <label>End Date</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group" /* style={{ zIndex: '100' }} */>
                  <Select
                  // isMulti
                  // isDisabled={isDisabled}
                  options={statusDropdown.data}
                  // isOptionDisabled={(option) => option.disabled}
                  // formatOptionLabel={(label) => label.html}
                  filterOption={(option, searchText) => {
                    if (
                      option.data.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                      // || option.data.value
                      //   .toLowerCase()
                      //   .includes(searchText.toLowerCase())
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  value={
                    statusDropdown.data.find(el => el.value == (filterExpenses.status_id || null))
                  }
                  onChange={(e) => {
                    setFilterExpenses({
                      ...filterExpenses,
                      status_id: e.value
                    })
                  }}
                  />
                  <label>Status</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group" /* style={{ zIndex: '100' }} */>
                  <Select
                  // isMulti
                  // isDisabled={isDisabled}
                  options={allEmployee.data}
                  // isOptionDisabled={(option) => option.disabled}
                  // formatOptionLabel={(label) => label.html}
                  filterOption={(option, searchText) => {
                    if (
                      option.data.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                      // || option.data.value
                      //   .toLowerCase()
                      //   .includes(searchText.toLowerCase())
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  value={
                    allEmployee.data.find(el => el.value == (filterExpenses.paid_by || null))
                  }
                  onChange={(e) => {
                    setFilterExpenses({
                      ...filterExpenses,
                      paid_by: e.value
                    })
                  }}
                  />
                  <label>ผู้จ่าย</label>
                </div>
              </div>

            </div>
          </div>

          <div className="container-fluid">
            <div className="row" style={{ marginBottom: '1rem' }}>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary  "
                    onClick={() => {
                      GetAllExpenses();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    disabled={!expensesData.data.some((el) => el.is_select)}
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      // window.location.href =
                      //   "/accounting/Emp_Expenses_Admin/Add";
                      const _data = [...expensesData.data]
                        .filter((el) => el.is_select)
                        .map((el) => el.emp_exp_id);
                      fetchAndPreviewPDF(_data);
                    }}
                  >
                    ออกใบสำคัญจ่าย
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  className="form-group mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    disabled={
                      [...expensesData.data].filter((el) => el.is_select)
                        .length === 0 ||
                      ![...expensesData.data]
                        .filter((el) => el.is_select)
                        .every(
                          (el) =>
                            el.emp_exp_status ===
                            ACCOUNTING_STATUS.approve_not_upload
                        )
                    }
                    onClick={() => {
                      const _data = [...expensesData.data]
                        .filter(
                          (el) =>
                            el.is_select &&
                            el.emp_exp_status ===
                              ACCOUNTING_STATUS.approve_not_upload
                        )
                        .map((el) => el.emp_exp_id);
                      setDataForSave({
                        ...dataForSave,
                        id: _data,
                        remark_not_approve: "",
                      });
                      dataForSave.id = _data;
                      dataForSave.remark_not_approve = "";
                      handleOpenModal("upload_slips");
                    }}
                  >
                    แนบไฟล์ All
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                }}
              >
                สรุปยอดค่าใช้จ่ายวันที่
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                  color: "#3579F6",
                  padding: "0px 10px",
                }}
              >
                {`${moment(expensesData.start_d).format(
                  "DD MMM YY"
                )} - ${moment(expensesData.end_d).format("DD MMM YY")}`}
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                }}
              >
                ทั้งหมด :
              </span>
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "800",
                  padding: "0px 10px",
                  color: "#3579F6",
                }}
              >
                {Number(expensesData.sum_amount || 0).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            {/* <div className="card-header">
              <h3 className="card-title">รายการค่าใช้จ่าย</h3>
              <div className="card-tools"></div>
            </div> */}

            <div
              className="card-body table-responsive"
              style={{ whiteSpace: "nowrap" }}
            >
              <table className="card-body table-responsive" style={{ padding: 0 }}>
              <MDBDataTable
                disableRetreatAfterSorting={disableRetreatAfterSorting}
                sortable={false}
                // className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
              </table>
              {/* <MDBDataTable
                sortable={false}
                disableRetreatAfterSorting={disableRetreatAfterSorting}
                className="table table-head-fixed text-center"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              /> */}
            </div>
            <div className="card-footer"></div>
          </div>

          <Modal
            centered
            isOpen={showModal}
            toggle={handleCloseModal}
            // style={{ width: "50%" }}
          >
            <ModalHeader
              style={{
                /* border: "none" */ paddingTop: "10px",
                paddingBottom: "10px",
              }}
              toggle={
                modalState === "view_remark_not_approve" ||
                modalState === "view_slips"
                  ? handleCloseModal
                  : undefined
              }
            >
              <span style={{ fontSize: "24px", fontWeight: "800" }}>
              {modalState === "upload_slips"
                  ? "แนบหลักฐานการชำระเงิน"
                  : modalState === "view_remark_not_approve"
                  ? "รายละเอียดการไม่อนุมัติ"
                  : modalState === "view_slips"
                  ? "รายละเอียดการอนุมัติ"
                  : "Remark"}
              </span>
            </ModalHeader>
            <ModalBody style={{ padding: "0" }}>
              {GetBodyModal(modalState)}
            </ModalBody>
          </Modal>
          {previewImage && (
            <div className="expenses-image-popup" id="expenses-image-popup">
              <Image
                className="image"
                wrapperStyle={{ display: "none", zIndex: 1051 }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            </div>
          )}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Emp_Expenses_Admin_Table;
