import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Configs from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOem, getToken, getUser } from "../../../../Utils/Common";

function Wait_delivery_EV({ mode }) {
  const { id } = useParams();
  const [pageMode, setPageMode] = useState(null);
  const [Product_Table, setProduct_Table] = useState({
    data: [],
    remark: "",
  });
  const [Bill_Product, setBill_Product] = useState({
    data: [],
    bill_confirm: false,
    formtype_wait_delivery: null,
    ref_id: null,
    ref_cus_name: null,
  });
  const [routeDD, setRouteDD] = useState({
    data: [],
  });
  const [shippingByDD, setShippingByDD] = useState({
    data: [],
  });
  const [Product, setProduct] = useState({
    data: [],
  });
  const [SalesOrder, setSalesOrder] = useState({
    ref_no: "",
    route_id: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    so_cus_name: "",
    so_cus_house_number: "",
    so_cus_building: "",
    so_swine: "",
    so_soi: "",
    so_road: "",
    so_sub_district: "",
    so_district: "",
    so_province: "",
    so_postcode: "",
    so_contact_name: "",
    so_contact_email: "",
    so_contact_tel: "",
    so_ship_contract_name: "",
    so_csh_id: "",
    so_ship_tel: "",
    so_ship_house_number: "",
    so_ship_building: "",
    so_ship_swine: "",
    so_ship_soi: "",
    so_ship_road: "",
    so_ship_sub_district: "",
    so_ship_district: "",
    so_ship_province: "",
    so_ship_postcode: "",
    so_ds_id: "",
    so_cus_remark: "",
    check_packing: "",
    is_packing_confirm: false,
    is_success_All_pack: false,
  });
  const [disable, setDisable] = useState(false);
  const [test, setTest] = useState({
    test: "",
  });
  useEffect(async () => {
    setPageMode(mode === "view" ? "View" : "Edit");
    setDisable(mode === "view" ? true : false);
    await getRouteDropDown();
    await GetProduct();
    await GetShippingByDropDown();
    await getBillDropDown();

    if (id) {
      await getBillById();
    }
  }, []);

  async function GetShippingByDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getShippingByDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setShippingByDD({
          ...shippingByDD,
          data: response.data,
        });
        shippingByDD.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getRouteDropDown = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/GetRouteDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        // const options = []
        // console.log('response.data route DD.', response.data)
        setRouteDD({ ...routeDD, data: response.data });
        routeDD.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getBillById = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      bill_id: id,
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/getBillById",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        // console.log("response.data", response.data[0]);
        if (response.data) {
          const bill = response.data[0];
          setBill_Product({
            ...Bill_Product,
            data: bill.bill_products || [],
            bill_confirm: bill.bill_confirm,
            formtype_wait_delivery: bill.bill_so_id ? "SO" : "REQ",
            ref_id: bill.bill_so_id ? bill.bill_so_id : bill.bill_req_id,
            ref_cus_name: bill.bill_so_cus_name
              ? bill.bill_so_cus_name
              : bill.bill_req_cus_name,
          });

          Bill_Product.data = bill.bill_products || [];
          Bill_Product.formtype_wait_delivery = bill.bill_so_id ? "SO" : "REQ";
          Bill_Product.ref_id = bill.bill_so_id
            ? bill.bill_so_id
            : bill.bill_req_id;
          Bill_Product.ref_cus_name = bill.bill_so_cus_name
            ? bill.bill_so_cus_name
            : bill.bill_req_cus_name;
          // console.log("saaaaa", Bill_Product.data);
          setProduct_Table({
            ...Product_Table,
            remark: bill.bill_product_remark || "",
          });
          Product_Table.remark = bill.bill_product_remark || "";
          // console.log("bill.bill_confirm", bill.bill_confirm);
          // console.log("bill.bill_is_success", bill.bill_is_success);
          setSalesOrder({
            ...SalesOrder,
            so_ds_id: bill.bill_ds_id || "",
            route_id: bill.bill_route_id || "",
            end_date: bill.bill_delivery_date
              ? new Date(bill.bill_delivery_date) || ""
              : "",
            so_cus_remark: bill.bill_remark || "",
            is_packing_confirm: bill.bill_confirm || false,
            is_success_All_pack: bill.bill_is_success || false,
          });
          SalesOrder.is_packing_confirm = bill.bill_confirm || false;
          SalesOrder.is_success_All_pack = bill.bill_is_success || false;
          SalesOrder.so_ds_id = bill.bill_ds_id || "";
          SalesOrder.route_id = bill.bill_route_id || "";
          SalesOrder.end_date = bill.bill_delivery_date
            ? new Date(bill.bill_delivery_date) || ""
            : "";
          SalesOrder.end_time = bill.bill_delivery_time
            ? new Date(bill.bill_delivery_time) || ""
            : "";
          SalesOrder.so_cus_remark = bill.bill_remark || "";

          await getDetailProductBySO_or_REQId(
            bill.bill_so_id ? bill.bill_so_id : bill.bill_req_id,
            bill.bill_so_id ? "SO" : "REQ",
            bill.bill_products
          );
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const getDetailProductBySO_or_REQId = async (
    ref_id,
    typeGet,
    billProduct
  ) => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
      ref_id: ref_id,
      type: typeGet,
      products: billProduct,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/sale_order/getDetailProductBySOId",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        const promiseA = new Promise(async (resolve, reject) => {
          try {
            if (response.data) {
              if (response.data[0].products_list) {
                for (let item of response.data[0].products_list) {
                  const check = Product.data?.find(
                    (el) => el.product_id === item.ref_product_id
                  );
                  // console.log("check", check);
                  if (check) {
                    item.product_code = check.product_code;
                    item.pci_img_path = check.pci_img_path || null;
                    item.pci_img_name = check.pci_img_name || null;
                    if (response.data[0].ref_lang === "TH") {
                      item.product_name_ref = item.sop_product_name_th
                        ? item.sop_product_name_th
                        : check.product_name || null;
                    } else {
                      item.product_name_ref = item.sop_product_name_eng
                        ? item.sop_product_name_eng
                        : check.product_name_eng || null;
                    }

                    if (check.barcode_id) {
                      let indexCheck = check.barcode_id?.findIndex(
                        (el) => el === item.rp_pb_id
                      );
                      item.ref_cus_barcode =
                        indexCheck < 0
                          ? item.ref_cus_barcode
                          : check.barcode[indexCheck];
                    }
                  }
                  /* if (Bill_Product.data.length > 0) {
                                                          const check2 = Bill_Product.data?.find(el => (el.bpd_product_id === item.ref_product_id && el.bpd_is_packing === true));
                                                          if (check2) {
                                                              item.bpd_id = check2.bpd_id;
                                                              item.remark = check2.bpd_bill_remark;
                                                              item.check_packing = check2.bpd_is_packing === true ? "success" : check2.bpd_is_packing === false ? "fail" : "";
                                                          } else {
                                                              item.bpd_id = check2.bpd_id;
                                                              item.remark = check2.bpd_bill_remark;
                                                              item.check_packing = false;
                                                          }
                                                      } */
                }
              }
            }
            resolve();
          } catch (error) {
            console.log("error", error);
            reject(error);
          }
        });
        promiseA
          .then(() => {
            const data = response.data[0];
            setSalesOrder({
              ...SalesOrder,
              ref_no: data.ref_no,
              start_date: data.ref_date ? new Date(data.ref_date) : new Date(),
              so_cus_name: data.ref_cus_name,
              so_cus_house_number: data.ref_cus_house_number,
              so_cus_building: data.ref_cus_building,
              so_swine: data.ref_swine,
              so_soi: data.ref_soi,
              so_road: data.ref_road,
              so_sub_district: data.ref_sub_district,
              so_district: data.ref_district,
              so_province: data.ref_province,
              so_postcode: data.ref_postcode,
              so_contact_name: data.ref_contact_cus_name,
              so_contact_email: data.ref_contact_email,
              so_contact_tel: data.ref_contact_tel,
              so_ship_contract_name: data.ref_ship_contract_name,
              so_csh_id: data.ref_csh_name || "-",
              so_ship_tel: data.ref_ship_tel,
              so_ship_house_number: data.ref_ship_house_number,
              so_ship_building: data.ref_ship_building,
              so_ship_swine: data.ref_ship_swine,
              so_ship_soi: data.ref_ship_soi,
              so_ship_road: data.ref_ship_road,
              so_ship_sub_district: data.ref_ship_sub_district,
              so_ship_district: data.ref_ship_district,
              so_ship_province: data.ref_ship_province,
              so_ship_postcode: data.ref_ship_postcode,
            });
            // console.log("aaaaaaaaaaaaaaaaaaaaaaa", data.products_list);
            setProduct_Table({ ...Product_Table, data: data.products_list });
            Product_Table.data = data.products_list;
            // console.log("Product_Table.data", Product_Table.data);
            setTest({ ...test, test: "" });
            test.test = "";
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const GetProduct = async () => {
    let temp = {
      code: null,
      name: null,
      category: null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWherecat2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        if (response) {
          setProduct({ data: response.data });
          Product.data = response.data;
          /////////////////////////////////////
          let data = new FormData();
          data.append("company_id", getUser().com);
          data.append("oem_id", getOem());
          data.append("user_id", getUser().fup);
          data.append("products", JSON.stringify(response.data));

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "multipart/form-data",
            },
            data: data,
          })
            .then(async (response) => {
              setProduct({ data: response.data });
              Product.data = response.data;
              return response.data;
            })
            .catch(async (error) => {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
              });
            });

          /////////////////////////////////////
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [BillDD, setBillDD] = useState({
    data: [],
  });

  const getBillDropDown = async () => {
    let temp = {
      code: null,
      name: null,
      category: null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/getBillDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log(response);
        setBillDD({ ...BillDD, data: response.data });
        BillDD.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const rowsData = [];
  for (let index = 0; index < Product_Table.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = (
      <div style={{ textAlign: "center" }}>
        {Product_Table.data[index].product_code}
      </div>
    );

    rowItem["barcode"] = (
      <div style={{ textAlign: "center" }}>
        {Product_Table.data[index].ref_cus_barcode
          ? Product_Table.data[index].ref_cus_barcode
          : "-"}
      </div>
    );

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            Product_Table.data[index].pci_img_path !== "" &&
            Product_Table.data[index].pci_img_path !== null &&
            Product_Table.data[index].pci_img_path !== undefined
              ? Product_Table.data[index].pci_img_path?.indexOf("blob") >= 0
                ? Product_Table.data[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                  `/static/${Product_Table.data[index].pci_img_name}/${Product_Table.data[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["product_name"] = (
      <div style={{ textAlign: "center" }}>
        {Product_Table.data[index].product_name_ref
          ? Product_Table.data[index].product_name_ref
          : "-"}
      </div>
    );

    rowItem["product_quantity"] = (
      <div style={{ textAlign: "center" }}>
        {Product_Table.data[index].ref_quantity
          ? (Product_Table.data[index].ref_quantity + ' ' + Product_Table.data[index].ref_quantity_unit)
          : "-"}
      </div>
    );

    rowItem["exp_date"] = (
      <div style={{ textAlign: "center" }}>
        {Product_Table.data[index].sop_exp_date
          ? Product_Table.data[index].sop_exp_date
          : Product_Table.data[index].rp_exp_date
          ? Product_Table.data[index].rp_exp_date
          : "-"}
      </div>
    );

    rowItem["packing"] = (
      <div
        // disabled={
        //     disable || SalesOrder.is_packing_confirm === true ? true : false
        // }
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <a
          value="success"
          // disabled={
          //     disable || SalesOrder.is_packing_confirm === true ? true : false
          // }
          style={
            (disable || SalesOrder.is_packing_confirm === true ? true : false)
              ? { pointerEvents: "none" }
              : {}
          }
          onClick={(e) => {
            if (SalesOrder.is_packing_confirm === false) {
              const temp = Product_Table.data;
              temp[index].check_packing = "success";
              temp[index].tb_id = "";
              setProduct_Table({
                ...Product_Table,
                data: temp,
              });
              Product_Table.data = temp;
            }
          }}
        >
          <i
            disabled={
              disable || SalesOrder.is_packing_confirm === true ? true : false
            }
            className="fas fa-check-square"
            style={{
              fontSize: "2rem",
              color:
                Product_Table.data[index].check_packing === "success"
                  ? "#28a745"
                  : "gray",
            }}
          />
        </a>
        <a
          value="fail"
          // disabled={
          //     disable || SalesOrder.is_packing_confirm === true ? true : false
          // }
          style={
            (disable || SalesOrder.is_packing_confirm === true ? true : false)
              ? { pointerEvents: "none" }
              : {}
          }
          onClick={(e) => {
            if (SalesOrder.is_packing_confirm === false) {
              const temp = Product_Table.data;
              temp[index].check_packing = "fail";
              temp[index].tb_id = "";
              setProduct_Table({
                ...Product_Table,
                data: temp,
              });
              Product_Table.data = temp;
            }
          }}
        >
          <i
            disabled={
              disable || SalesOrder.is_packing_confirm === true ? true : false
            }
            className="fas fa-window-close"
            style={{
              fontSize: "2rem",
              color:
                Product_Table.data[index].check_packing === "fail"
                  ? "#dc3545"
                  : "gray",
            }}
          />
        </a>
      </div>
    );

    rowItem["topic"] = (
      <>
        {Product_Table.data[index].check_packing === "fail" ? (
          <select
            className="form-control"
            disabled={
              disable || SalesOrder.is_packing_confirm === true ? true : false
            }
            value={Product_Table.data[index].tb_id || ""}
            onChange={(e) => {
              const temp = Product_Table.data;
              temp[index].tb_id = e.target.value;

              setProduct({ ...Product, data: temp });
              Product.data = temp;
            }}
          >
            <option key="" value="">Select----</option>
            {BillDD.data?.map((option) => (
              <option key={option.tp_id} value={option.tp_id}>
                {option.tp_name}
              </option>
            ))}
          </select>
        ) : (
          <></>
        )}
      </>
    );
    rowItem["remark"] = (
      <>
        {Product_Table.data[index].tb_id && Product_Table.data[index].check_packing === "fail" ? (
          <input
            type="text"
            className="form-control"
            required="false"
            disabled={
              disable || SalesOrder.is_packing_confirm === true ? true : false
            }
            value={Product_Table.data[index].remark}
            onChange={(e) => {
              const temp = Product_Table.data;
              temp[index].remark = e.target.value;

              setProduct({ ...Product, data: temp });
              Product.data = temp;
            }}
          />
        ) : (
          <></>
        )}
      </>
    );

    rowsData.push(rowItem);
  }

  const Product_data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product code",
        field: "product_code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Product Quantity",
        field: "product_quantity",
        // sort: "asc",
        width: 50,
      },
      {
        label: "EXP. Date",
        field: "exp_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Packing",
        field: "packing",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Topic",
        field: "topic",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const checkErrorForSave = async (status_check) => {
    let error_list = [];

    if (status_check === true) {
      for (let item of Product_Table.data) {
        if (item.remark) {
          item.remark = item.remark ? item.remark?.trim() : null;
        }
        if (!item.check_packing) {
          let temp_err = {
            message: `Product Code: ${
              item.product_code || "-"
            } กรุณาเลือก Packing`,
          };
          error_list.push(temp_err);
        }
        if (item.check_packing === "fail" && !item.tb_id) {
          let temp_err = {
            message: `Product Code: ${
              item.product_code || "-"
            } กรุณาเลือก Topic`,
          };
          error_list.push(temp_err);
        }
        if (
          item.check_packing === "fail" &&
          item.tb_id === "26ec07ad-fbb6-416f-8dc1-6139350e4ea6" &&
          !item.remark
        ) {
          let temp_err = {
            message: `Product Code: ${
              item.product_code || "-"
            } กรุณากรอก Remark`,
          };
          error_list.push(temp_err);
        }
      }
    }
    if (Product_Table.remark) {
      let tempRemark = Product_Table.remark;
      tempRemark = tempRemark ? tempRemark?.trim() : null;
      setProduct_Table({
        ...Product_Table,
        remark: tempRemark,
      });
      Product_Table.remark = tempRemark;
    }

    if (
      SalesOrder.is_packing_confirm === true &&
      SalesOrder.is_success_All_pack === true
    ) {
      if (!SalesOrder.route_id) {
        let temp_err = {
          message: `กรุณาเลือก Route`,
        };
        error_list.push(temp_err);
      }

      if (!SalesOrder.so_ds_id) {
        let temp_err = {
          message: `กรุณาเลือก Shipping By`,
        };
        error_list.push(temp_err);
      }

      if (SalesOrder.so_cus_remark) {
        SalesOrder.so_cus_remark = SalesOrder.so_cus_remark
          ? SalesOrder.so_cus_remark?.trim()
          : null;
      }
    }

    return error_list;
  };

  const addStatusInLogOfProduct = async (productList, typeOf) => {
    let temp = {
      productList: productList,
      typeOf: typeOf,
      ref_no: SalesOrder.ref_no,
      ref_id: Bill_Product.ref_id,
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      user_name: getUser().name + " " + getUser().surname,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus + `/api/log_product/AddStatusInLogOfProduct`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {})
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function saveOption() {
    // console.log("Product_Table", Product_Table);
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave(false);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              data: Product_Table.data,
              tb_id: Product_Table.tb_id,
              remark:
                Product_Table.tb_id === "26ec07ad-fbb6-416f-8dc1-6139350e4ea6"
                  ? Product_Table.remark
                  : null,
              bill_id: id,
              details: SalesOrder,
            };
            await axios({
              method: "post",
              url:
                Configs.API_URL_cuteplus +
                `/api/bill${
                  SalesOrder.is_packing_confirm === true
                    ? "/updateWaitDelivery"
                    : "/saveDrafWaitDelivery"
                }`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                /* console.log('response saveDrafQuotation -', response.data);
                                                if (response.data) {
                                                    if (mode === "edit") {
                                                        await deleteImagesAndFilesOfProduct();
                                                    }
                                                } */
              })
              .then(async function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href =
                    "/Warehouse_Management/wait_delivery_cuteplus";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  async function saveConfirm() {
    /* console.log("SalesOrder", SalesOrder);
        console.log("Product_Table", Product_Table); */
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะ Confirm ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave(true);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const check = Product_Table.data?.find(
              (el) => el.check_packing === "fail"
            );
            if (!check) {
              await addStatusInLogOfProduct(
                Product_Table.data,
                Bill_Product.formtype_wait_delivery
              );
            }
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              data: Product_Table.data,
              tb_id: Product_Table.tb_id,
              remark:
                Product_Table.tb_id === "26ec07ad-fbb6-416f-8dc1-6139350e4ea6"
                  ? Product_Table.remark
                  : null,
              bill_id: id,
              details: SalesOrder,
            };
            await axios({
              method: "post",
              url: Configs.API_URL_cuteplus + `/api/bill/saveWaitDelivery`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                // console.log("response saveConfirm -", response.data);

                if (response.data) {
                  await updateStatusSO_or_REQ(response.data);
                }
              })
              .then(async function (response) {
                Swal.fire({
                  icon: "success",
                  title: "Confirm สำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.reload();
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  const updateStatusSO_or_REQ = async (id_status) => {
    const temp = {
      status_id: id_status,
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      user_name: getUser().name + " " + getUser().surname,
      ref_id: Bill_Product.ref_id,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        `/api/${
          Bill_Product.formtype_wait_delivery === "SO"
            ? "sale_order/updateStatusSaleOrder"
            : "requisition/updateStatusRequisition"
        }`,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {})
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/Warehouse_Management/wait_delivery_cuteplus";
        }
      });
    } else {
      window.location.href = "/Warehouse_Management/wait_delivery_cuteplus";
    }
  }

  const cancelWaitDelivery = async () => {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะยกเลิกรายการ บิลรอจัดส่ง นี้ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateStatusSO_or_REQ("19a886b6-0131-4542-a711-9332ed4fbf3c");
        await updateStatusBill(Product_Table.data);
        const temp = {
          productList: Product_Table.data,
          ref_no: SalesOrder.ref_no,
          ref_id: Bill_Product.ref_id,
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
          user_name: getUser().name + " " + getUser().surname,
        };
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus +
            `/api/log_product/CancelReserveLogOfProductSO`,
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(async function (response) {
            // console.log('response saveDrafQuotation -', response.data);

            Swal.fire({
              icon: "success",
              title: "ยกเลิกรายการ บิลรอจัดส่ง สำเร็จ",
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              window.location.href =
                "/Warehouse_Management/wait_delivery_cuteplus";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data,
              icon: "error",
            });
          });
      }
    });
  };

  const updateStatusBill = async (temp) => {
    let data = {
      data: temp,
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
      is_cancel: true,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/updateStatusBillSO",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then((response) => {
        // console.log("response.data :>> ", response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-12 col-md-4 col-xl-6">
                <h1>บิลรอจัดส่ง</h1>
              </div>
              <div className="col-12 col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    บิลรอจัดส่ง {pageMode}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                {/* <div className="col-6 col-md-3 col-xl-2">
                                    <button
                                        type="button"
                                        class="btn btn-block btn-success"
                                        onClick={async () => {
                                            await addStatusInLogOfProduct(
                                                Product_Table.data,
                                                Bill_Product.formtype_wait_delivery
                                            );
                                        }}
                                    >
                                        บันทึก log test
                                    </button>
                                </div> */}
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={async () => {
                      await saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                {Bill_Product.formtype_wait_delivery === "SO" &&
                SalesOrder.is_success_All_pack === true &&
                SalesOrder.is_packing_confirm === true ? (
                  <div className="col-6 col-md-3 col-xl-2">
                    <button
                      type="button"
                      class="btn btn-block btn-danger"
                      onClick={() => {
                        cancelWaitDelivery();
                      }}
                    >
                      ยกเลิกบิลรอจัดส่ง
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Reference No. : {SalesOrder.ref_no}
                </label>
              </div>
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Customer Name : {Bill_Product.ref_cus_name}
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {/* <div className="card">
                  <div className="card-header">
                    <h4 style={{ margin: "0px" }}>Supplier {pageMode}</h4>
                  </div> */}

                <div className="card card-primary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id={"tab-1"}
                          data-toggle="pill"
                          href={"#Supplier_Add-tab"}
                          role="tab"
                          aria-controls={"Supplier_Add-tab"}
                          aria-selected="true"
                        >
                          Product
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className={
                            SalesOrder.is_success_All_pack === true &&
                            SalesOrder.is_packing_confirm === true
                              ? "nav-link"
                              : "nav-link disabled"
                          }
                          id={"tab-2"}
                          data-toggle="pill"
                          href={"#Supplier_Product-tab"}
                          role="tab"
                          aria-controls={"Supplier_Product-tab"}
                          aria-selected="false"
                        >
                          บิลรอจัดส่ง
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-four-tabContent"
                    >
                      <div
                        className="tab-pane fade show active"
                        id={"Supplier_Add-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-1"}
                      >
                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Product
                            </label>
                            <hr style={{ marginTop: "0" }} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="table-responsive">
                            <div className="row">
                              <div className="col-12 col-md-12 col-xl-12">
                                <MDBDataTable
                                  sortable={false}
                                  className="table table-head-fixed"
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={Product_data}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="row mb-3"
                          style={{ flexDirection: "row-reverse" }}
                        >
                          <div className="col-6 col-md-3 col-xl-2">
                            <button
                              type="button"
                              disabled={
                                disable ||
                                SalesOrder.is_packing_confirm === true
                                  ? true
                                  : false
                              }
                              class="btn btn-block btn-primary"
                              onClick={async () => {
                                await saveConfirm();
                              }}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-12 col-xl-12">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                rows={2}
                                disabled={disable}
                                style={{
                                  height: "100px",
                                  fontSize: "25px",
                                }}
                                value={Product_Table.remark}
                                onChange={(e) => {
                                  setProduct_Table({
                                    ...Product_Table,
                                    remark: e.target.value,
                                  });
                                  Product_Table.remark = e.target.value;
                                }}
                                defaultValue=""
                                required
                              />
                              <label>Remark</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id={"Supplier_Product-tab"}
                        role="tabpanel"
                        aria-labelledby={"tab-2"}
                      >
                        <div className="row">
                          <div className="col-12 col-md-5 col-xl-3">
                            <div className="form-group ">
                              <input
                                type="text"
                                className="form-control"
                                required="false"
                                disabled={true}
                                value={SalesOrder.ref_no}
                              />
                              <label htmlFor="">Reference No.</label>
                            </div>
                          </div>
                          <div className="col-12 col-md-7 col-xl-2">
                            <div class="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={disable}
                                value={SalesOrder.route_id}
                                onChange={async (e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    route_id: e.target.value,
                                  });
                                  SalesOrder.route_id = e.target.value;
                                }}
                              >
                                <option key="" value="">
                                  Select -----
                                </option>
                                {routeDD.data?.map((option, index) => (
                                  <option
                                    key={option.route_id}
                                    value={option.route_id}
                                  >
                                    {option.route_name}
                                  </option>
                                ))}
                              </select>
                              <label>
                                Route
                                {SalesOrder.route_id === "" ||
                                SalesOrder.route_id === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-4 col-xl-3">
                            <div className="form-group">
                              <DatePicker
                                required
                                disabled={true}
                                locale="th"
                                wrapperClassName="w-100"
                                dateFormat={"dd-MM-yyyy"}
                                selected={SalesOrder.start_date}
                                onChange={async (date) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    start_date: date,
                                  });
                                }}
                                selectsStart
                                showYearDropdown
                                showMonthDropdown
                                startDate={new Date()}
                                minDate={new Date()}
                                customInput={<CustomInput isDis={true} />}
                              />
                              <label>Reference Date</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-xl-3">
                            <div className="form-group">
                              <DatePicker
                                required
                                disabled={disable}
                                locale="th"
                                wrapperClassName="w-100"
                                dateFormat={"dd-MM-yyyy"}
                                selected={SalesOrder.end_date || ""}
                                onChange={async (date) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    end_date: date,
                                  });
                                }}
                                selectsStart
                                showYearDropdown
                                showMonthDropdown
                                // startDate={new Date().setDate(new Date().getDate() + 30)}
                                minDate={SalesOrder.start_date}
                                customInput={<CustomInput isDis={disable} />}
                              />
                              <label>Delivery Date</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-xl-2">
                            <div className="form-group input-group">
                              <DatePicker
                                required
                                disabled={disable}
                                locale="th"
                                className="form-control"
                                wrapperClassName="form-control"
                                showTimeSelect
                                showTimeSelectOnly
                                selected={SalesOrder.end_time || ""}
                                onChange={async (date) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    end_time: date,
                                  });
                                }}
                                timeCaption="เวลา"
                                dateFormat="HH.mm น."
                                timeFormat="HH.mm น."
                                timeIntervals={1}
                                // minDate={SalesOrder.start_date}
                              />
                              <label>Delivery Time</label>{" "}
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="far fa-calendar-alt" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label
                              style={{
                                fontSize: "24px",
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Customer Detail
                            </label>
                            <hr style={{ marginTop: "0" }} />
                          </div>
                          <div className="col-12 col-sm-6 col-md-4 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={
                                  Bill_Product.ref_cus_name ||
                                  SalesOrder.so_cus_name
                                }
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_cus_house_number: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Customer Name
                                {SalesOrder.so_cus_name === "" ||
                                Bill_Product.ref_cus_name === "" ||
                                Bill_Product.ref_cus_name === null ||
                                SalesOrder.so_cus_name === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-12 col-xl-12">
                            <label>ที่อยู่</label>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_cus_house_number}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_cus_house_number: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                บ้านเลขที่
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_cus_building}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_cus_building: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                อาคาร
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-1 col-xl-1">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_swine}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_swine: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                หมู่
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_soi}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_soi: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ซอย
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_road}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_road: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ถนน
                              </label>
                            </div>
                          </div>
                          <div className="col-3 col-md-3 col-xl-3"></div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_sub_district}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_sub_district: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                แขวง/ตำบล
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_district}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_district: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เขต/อำเภอ
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_province}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_province: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                จังหวัด
                              </label>
                            </div>
                          </div>
                          <div className="col-3 col-md-3 col-xl-3"></div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_postcode}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_postcode: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                รหัสไปรษณีย์
                              </label>
                            </div>
                          </div>
                          <div className="col-8 col-md-8 col-xl-8"></div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_contact_name}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_contact_name: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Customer Contact Name
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-md-6 col-xl-6"></div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_contact_email}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_contact_email: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact Email
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_contact_tel}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_contact_tel: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Contact Tel.
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-xl-12">
                            <label>Ship to</label>
                          </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_contract_name}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_contract_name: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ผู้ติดต่อ
                              </label>
                            </div>
                          </div>
                          <div className="col-6 col-md-6 col-xl-6"></div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_csh_id}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_csh_id: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ชื่อที่อยู่
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-xl-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_tel}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_tel: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เบอร์โทรศัพท์
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 col-xl-12">
                            <label>ที่อยู่</label>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_house_number}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_house_number: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                บ้านเลขที่
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_building}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_building: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                อาคาร
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-1 col-xl-1">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_swine}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_swine: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                หมู่
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_soi}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_soi: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ซอย
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-2 col-xl-2">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_road}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_road: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                ถนน
                              </label>
                            </div>
                          </div>
                          <div className="col-3 col-md-3 col-xl-3"></div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_sub_district}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_sub_district: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                แขวง/ตำบล
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_district}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_district: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                เขต/อำเภอ
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_ship_province}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ship_province: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                จังหวัด
                              </label>
                            </div>
                          </div>
                          <div className="col-3 col-md-3 col-xl-3"></div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={true}
                                value={SalesOrder.so_postcode}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_postcode: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                รหัสไปรษณีย์
                              </label>
                            </div>
                          </div>
                          <div className="col-8 col-md-8 col-xl-8"></div>
                          <div className="col-12 col-md-3 col-xl-3">
                            <div className="form-group">
                              <select
                                type="text"
                                className="form-control"
                                required
                                disabled={disable}
                                value={SalesOrder.so_ds_id}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_ds_id: e.target.value,
                                  });
                                  SalesOrder.so_ds_id = e.target.value;
                                  // console.log(
                                  //   "shippingByDD.data[index].ds_id",
                                  //   shippingByDD.data[0].ds_id
                                  // );
                                  // getSonoRef(e.target.value);
                                }}
                              >
                                <option key="" value="">
                                  --- เลือก ---
                                </option>
                                {shippingByDD.data?.map((option, index) => (
                                  <option
                                    key={shippingByDD.data[index].ds_name}
                                    value={shippingByDD.data[index].ds_id}
                                  >
                                    {shippingByDD.data[index].ds_name}
                                  </option>
                                ))}
                              </select>
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Shipping by
                                {SalesOrder.so_ds_id === "" ||
                                SalesOrder.so_ds_id === null ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                required
                                disabled={disable}
                                value={SalesOrder.so_cus_remark}
                                onChange={(e) => {
                                  setSalesOrder({
                                    ...SalesOrder,
                                    so_cus_remark: e.target.value,
                                  });
                                }}
                              />
                              <label
                                style={{
                                  lineHeight: "normal",
                                  wordBreak: "break-all",
                                }}
                              >
                                Remark
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Wait_delivery_EV;
