import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
import Configs from "../../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../../Utils/Common";

import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

function Basic_Data({
  id,
  mode,
  isDisabled,
  inputData,
  setInputData,
  errorList,
  setErrorList,
  functionSave,
  setFunctionSave,
}) {
  const [tabData, setTabData] = useState({
    Product_Company: "",
    Resale: "",
    LTP: "",
    SPP: "",
    Product_Status: "",
    Product_Name_Eng: "",
    Product_Name_TH: "",
    Is_Product_Kitting: false,
    Ship_out_from_Country: "",
    Loading_Port: "",
    Thailand_Port: "",
    Brand: "",
    Category: "",
    Barcode: {
      newData: [],
      updateData: [],
      delData: [],
    },
  });

  const [barcodeInputList, setBarcodeInputList] = useState([]);

  const [barcodeInputData, setBarcodeInputData] = useState({
    id: "",
    piece: "",
    pack: "",
    carton: "",
    mode: "",
  });

  const [conutryData, setConutryData] = useState([]);

  const [brandData, setBrandData] = useState([]);

  let [categoryData, setCategoryData] = useState([]);
  const [categoryLv1Data, setCategoryLv1Data] = useState([]);
  const [categoryLv2Data, setCategoryLv2Data] = useState([]);
  const [categoryLv3Data, setCategoryLv3Data] = useState([]);
  const [categoryLv4Data, setCategoryLv4Data] = useState([]);

  const [productStatus, setProductStatus] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState({
    lv1: "",
    lv2: "",
    lv3: "",
    lv4: "",
    statusLv2: false,
    statusLv3: false,
    statusLv4: false,
  });

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const rowsData = [];
  for (let index = 0; index < barcodeInputList.length; index += 1) {
    const rowItem = {};

    rowItem["piece"] = barcodeInputList[index].piece || "-";

    rowItem["pack"] = barcodeInputList[index].pack || "-";

    rowItem["carton"] = barcodeInputList[index].carton || "-";

    rowItem["main"] = (
      <button
        type="button"
        id={barcodeInputList[index].id}
        disabled={isDisabled}
        className={
          barcodeInputList[index].main === true
            ? "btn btn-block btn-success"
            : "btn btn-block btn-primary"
        }
        style={{ minWidth: "100px" }}
        onClick={() => {
          if (barcodeInputList[index].main) {
            barcodeInputList[index].main = false;

            if (barcodeInputList[index].oldNew === "old") {
              barcodeInputList[index].status = "update";
            }
          } else {
            barcodeInputList.forEach((item) => {
              if (item.oldNew === "old") {
                item.status = "update";
              }
              
              item.main = false;
            });

            barcodeInputList[index].main = true;
          }

          const dataList = barcodeInputList.filter((item) => {
            return item.status !== "del";
          });

          const newList = barcodeInputList.filter((item) => {
            return item.status === "add" && item.oldNew === "new";
          });

          const updateList = barcodeInputList.filter((item) => {
            return item.status === "update" && item.oldNew === "old";
          });

          const delList = barcodeInputList.filter((item) => {
            return item.status === "del" && item.oldNew === "old";
          });

          setBarcodeInputList(dataList);

          setTabData({
            ...tabData,
            Barcode: {
              newData: newList,
              updateData: updateList,
              delData: delList,
            },
          });

          tabData.Barcode.newData = newList;
          tabData.Barcode.updateData = updateList;
          tabData.Barcode.delData = delList;

          console.log(tabData.Barcode);
        }}
      >
        ACTIVE
      </button>
    );

    rowItem["management"] = (
      <div
        className="row"
        style={{ justifyContent: "center", flexWrap: "nowrap" }}
      >
        <div className="col-auto">
          <a
            key={barcodeInputList[index].id}
            id={barcodeInputList[index].id}
            className="btn btn-sm"
            style={{ cursor: "pointer", margin: "0px 10px" }}
            onClick={() => {
              setBarcodeInputData({
                id: barcodeInputList[index].id,
                piece: barcodeInputList[index].piece,
                pack: barcodeInputList[index].pack,
                carton: barcodeInputList[index].carton,
                mode: "edit",
              });

              toggle();
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div className="col-auto">
          <a
            key={barcodeInputList[index].id}
            id={barcodeInputList[index].id}
            className="btn btn-sm"
            style={{ cursor: "pointer", margin: "0px 10px" }}
            onClick={() => {
              Swal.fire({
                title: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่?",
                text: "ข้อมูลที่ถูกลบจะไม่สามารถกู้คืนได้!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "ตกลง",
                confirmButtonColor: "#3085d6",
                cancelButtonText: "ยกเลิก",
                cancelButtonColor: "#d33",
              }).then((result) => {
                if (result.isConfirmed) {
                  barcodeInputList[index].status = "del";

                  const dataList = barcodeInputList.filter((item) => {
                    return item.status !== "del";
                  });

                  const newList = barcodeInputList.filter((item) => {
                    return item.status === "add" && item.oldNew === "new";
                  });

                  const updateList = barcodeInputList.filter((item) => {
                    return item.status === "update" && item.oldNew === "old";
                  });

                  const delList = barcodeInputList.filter((item) => {
                    return item.status === "del" && item.oldNew === "old";
                  });

                  setBarcodeInputList(dataList);

                  setTabData({
                    ...tabData,
                    Barcode: {
                      newData: newList,
                      updateData: updateList,
                      delData: delList,
                    },
                  });

                  tabData.Barcode.newData = newList;
                  tabData.Barcode.updateData = updateList;
                  tabData.Barcode.delData = delList;
                }
              });
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "Piece (pcs)",
        field: "piece",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Pack",
        field: "pack",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Carton (Ctn)",
        field: "carton",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Main",
        field: "main",
        // sort: "asc",
        width: 50,
      },
      {
        label: "การจัดการ",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  if (mode === "view") {
    const index = data.columns.findIndex((e) => e.field === "management");
    data.columns.splice(index, 1);
  }

  async function GetCountry() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCountry",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setConutryData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetBrand() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetBrand",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setBrandData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetCategory() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCategoryData(response.data);
        categoryData = response.data;

        setCategoryLv1Data(
          response.data.filter((item) => {
            return item.cat_level === "1";
          })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProductStatus() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetProductStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setProductStatus(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetBasicData() {
    let tempData = {
      Product_Id: id,
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      user_name: getUser().name + " " + getUser().surname,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetBasicData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        let CategoryCheck = true;

        response.data.CategoryList.sort((a, b) => {
          return a.cat_level > b.cat_level ? 1 : -1;
        });

        setSelectedCategory({
          lv1: response.data.CategoryList[0]
            ? response.data.CategoryList[0].cat_level === "1"
              ? response.data.CategoryList[0].cat_id
              : ""
            : "",
          lv2: response.data.CategoryList[1]
            ? response.data.CategoryList[1].cat_level === "2"
              ? response.data.CategoryList[1].cat_id
              : ""
            : "",
          lv3: response.data.CategoryList[2]
            ? response.data.CategoryList[2].cat_level === "3"
              ? response.data.CategoryList[2].cat_id
              : ""
            : "",
          lv4: response.data.CategoryList[3]
            ? response.data.CategoryList[3].cat_level === "4"
              ? response.data.CategoryList[3].cat_id
              : ""
            : "",
          statusLv2: response.data.CategoryList[1] ? true : false,
          statusLv3: response.data.CategoryList[2] ? true : false,
          statusLv4: response.data.CategoryList[3] ? true : false,
        });

        if (response.data.CategoryList[0]) {
          if (response.data.CategoryList[0].cat_level !== "1") {
            CategoryCheck = false;
          }
        }

        if (response.data.CategoryList[1]) {
          if (response.data.CategoryList[1].cat_level !== "2") {
            CategoryCheck = false;
          }
        }

        if (response.data.CategoryList[2]) {
          if (response.data.CategoryList[2].cat_level !== "3") {
            CategoryCheck = false;
          }
        }

        if (response.data.CategoryList[3]) {
          if (response.data.CategoryList[3].cat_level !== "4") {
            CategoryCheck = false;
          }
        }

        setCategoryLv2Data(
          response.data.CategoryList[1]
            ? categoryData.filter((item) => {
                return (
                  item.cat_parent_id === response.data.CategoryList[0].cat_id
                );
              })
            : []
        );

        setCategoryLv3Data(
          response.data.CategoryList[2]
            ? categoryData.filter((item) => {
                return (
                  item.cat_parent_id === response.data.CategoryList[1].cat_id
                );
              })
            : []
        );

        setCategoryLv4Data(
          response.data.CategoryList[3]
            ? categoryData.filter((item) => {
                return (
                  item.cat_parent_id === response.data.CategoryList[2].cat_id
                );
              })
            : []
        );

        const BarcodeData = [];

        for (let item of response.data.BarcodeList) {
          BarcodeData.push({
            id: item.pb_id,
            piece: item.pb_piece || "",
            pack: item.pb_pack || "",
            carton: item.pb_carton || "",
            main: item.pb_is_main || false,
            oldNew: "old",
            status: "",
          });
        }

        setBarcodeInputList(BarcodeData);

        setTabData({
          ...tabData,
          Product_Name_TH: response.data.BacisData.product_name || "",
          Is_Product_Kitting: response.data.BacisData.product_is_kitting,
          Product_Company: response.data.BacisData.product_code || "",
          Product_Name_Eng: response.data.BacisData.product_name_eng || "",
          Ship_out_from_Country:
            response.data.BacisData.product_country_id || "",
          Loading_Port: response.data.BacisData.product_loading_port || "",
          Thailand_Port: response.data.BacisData.product_thailand_port || "",
          Brand: response.data.BacisData.product_brand_id || "",
          Category: CategoryCheck
            ? response.data.BacisData.product_category_id
            : "",
          Product_Status: response.data.BacisData.product_pst_id || "",
          Resale: response.data.BacisData.product_resale || "",
          LTP: response.data.BacisData.product_ltp || "",
          SPP: response.data.BacisData.product_spp || "",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function CheckDataTab_1() {
    if (tabData.Product_Company) {
      tabData.Product_Company = tabData.Product_Company.trim();
    }

    if (tabData.Product_Name_Eng) {
      tabData.Product_Name_Eng = tabData.Product_Name_Eng.trim();
    }

    if (tabData.Product_Name_TH) {
      tabData.Product_Name_TH = tabData.Product_Name_TH.trim();
    }

    if (tabData.Loading_Port) {
      tabData.Loading_Port = tabData.Loading_Port.trim();
    }

    if (tabData.Thailand_Port) {
      tabData.Thailand_Port = tabData.Thailand_Port.trim();
    }

    if (tabData.Resale) {
      tabData.Resale =
        typeof tabData.Resale === "string"
          ? parseFloat(tabData.Resale?.replace(/,/g, "") || 0) || null
          : tabData.Resale;
    }

    if (tabData.LTP) {
      tabData.LTP =
        typeof tabData.LTP === "string"
          ? parseFloat(tabData.LTP?.replace(/,/g, "") || 0) || null
          : tabData.LTP;
    }

    if (tabData.SPP) {
      tabData.SPP =
        typeof tabData.SPP === "string"
          ? parseFloat(tabData.SPP?.replace(/,/g, "") || 0) || null
          : tabData.SPP;
    }

    const error_list = [];

    if (tabData.Product_Company === "" || tabData.Product_Company === null) {
      let temp_err = {
        message: "กรุณากรอก Product Code",
      };
      error_list.push(temp_err);
    }

    if (tabData.Resale === "" || tabData.Resale === null) {
      let temp_err = {
        message: "กรุณากรอก Retail Price",
      };
      error_list.push(temp_err);
    }

    if (tabData.Product_Name_Eng === "" || tabData.Product_Name_Eng === null) {
      let temp_err = {
        message: "กรุณากรอก Product Name (Eng)",
      };
      error_list.push(temp_err);
    } /* else {
      if (/^[a-zA-Z0-9.\s]+$/gi.test(tabData.Product_Name_Eng?.trim()) === false) {
        let temp_err = {
          message: "กรุณากรอก Product Name (Eng) เป็นภาษา อังกฤษเท่านั้น",
        };
        error_list.push(temp_err);
      }
    } */

    if (tabData.Product_Name_TH === "" || tabData.Product_Name_TH === null) {
      let temp_err = {
        message: "กรุณากรอก Product Name (TH)",
      };
      error_list.push(temp_err);
    } /* else {
      if (/^[ก-๙0-9.\s]+$/gi.test(tabData.Product_Name_TH?.trim()) === false) {
        let temp_err = {
          message: "กรุณากรอก Product Name (TH) เป็นภาษา ไทยเท่านั้น",
        };
        error_list.push(temp_err);
      }
    } */

    if (tabData.Is_Product_Kitting === false) {
      if (
        tabData.Ship_out_from_Country === "" ||
        tabData.Ship_out_from_Country === null
      ) {
        let temp_err = {
          message: "กรุณาเลือก Ship out from Country",
        };
        error_list.push(temp_err);
      }

      if (tabData.Thailand_Port === "" || tabData.Thailand_Port === null) {
        let temp_err = {
          message: "กรุณากรอก Thailand Port",
        };
        error_list.push(temp_err);
      }

      if (tabData.Brand === "" || tabData.Brand === null) {
        let temp_err = {
          message: "กรุณาเลือก Brand",
        };
        error_list.push(temp_err);
      }

      if (tabData.Category === "" || tabData.Category === null) {
        let temp_err = {
          message: "กรุณาเลือก Category",
        };
        error_list.push(temp_err);
      }
    }
    if (
      (tabData.Category !== "" && tabData.Category !== null) ||
      (selectedCategory.lv1 && tabData.Is_Product_Kitting === true)
    ) {
      const cat_find = categoryData.find(
        (el) => el.cat_id === tabData.Category
      );
      if (cat_find === undefined) {
        let temp_err = {
          message: "กรุณาเลือก Category",
        };
        error_list.push(temp_err);
      } else if (cat_find.cat_parent_id === null) {
        let temp_err = {
          message: "ต้องเลือก Category ที่มี Level มากกว่า 1 ขึ้นไป",
        };
        error_list.push(temp_err);
      }
    }

    if (barcodeInputList.length === 0) {
      let temp_err = {
        message: "กรุณาเกรอก EAN CODE (Barcode) อย่างน้อย 1",
      };
      error_list.push(temp_err);
    }

    setErrorList({
      ...errorList,
      data: error_list,
    });
    errorList.data = error_list;
  }

  useEffect(async () => {
    await GetCountry();
    await GetBrand();
    await GetCategory();

    if (mode === "edit" || mode === "view") {
      await GetProductStatus();
      await GetBasicData();
    }
  }, []);

  useEffect(async () => {
    setInputData({
      ...inputData,
      data: tabData,
    });

    setFunctionSave({
      ...functionSave,
      data: CheckDataTab_1,
    });
  }, [tabData]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.Product_Company}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Product_Company: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Product Code
              {tabData.Product_Company === "" ||
              tabData.Product_Company === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-2">
          <div class="form-group">
            <NumberFormat
              type="text"
              required
              className="form-control"
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              disabled={isDisabled}
              value={tabData.Resale}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Resale: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Retail Price
              {tabData.Resale === "" || tabData.Resale === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
        {/* </div>

      <div className="row"> */}
        <div className="col-12 col-md-6 col-xl-2">
          <div class="form-group">
            <NumberFormat
              type="text"
              required
              className="form-control"
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              disabled={isDisabled}
              value={tabData.LTP}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  LTP: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              LTP (ราคาส่งทั่วไป)
              {/* {tabData.LTP === "" ||
                tabData.LTP === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
            </label>
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-2">
          <div class="form-group">
            <NumberFormat
              type="text"
              required
              className="form-control"
              thousandSeparator={true}
              decimalScale={2}
              allowNegative={false}
              disabled={isDisabled}
              value={tabData.SPP}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  SPP: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              SPP (ราคาต่ำสุด)
              {/* {tabData.SPP === "" ||
                tabData.SPP === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.Product_Name_Eng}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Product_Name_Eng: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Product Name (Eng)
              {tabData.Product_Name_Eng === "" ||
              tabData.Product_Name_Eng === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div class="form-group" style={{ marginBottom: "0" }}>
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.Product_Name_TH}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Product_Name_TH: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Product Name (ภาษาไทย)
              {tabData.Product_Name_TH === "" ||
              tabData.Product_Name_TH === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div
          className="col-12 col-md-6 col-xl-6"
          style={{
            display: "flex",
            height: "auto",
            columnGap: "1rem",
            alignItems: "center",
            fontSize: "1.5rem",
            margin: "10px 5px",
          }}
        >
          <input
            // className="form-check-input"
            type="checkbox"
            style={{ width: 15, height: 15 }}
            id={"sbd_path_document"}
            name={"el.sbd_path_document"}
            value={tabData.Is_Product_Kitting}
            checked={tabData.Is_Product_Kitting}
            onClick={(e) => {
              setTabData({
                ...tabData,
                Is_Product_Kitting: e.target.checked,
              });
            }}
            disabled={isDisabled}
          />
          <p style={{ margin: 0, padding: 0 }}>Product Kitting</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div class="form-group">
            <select
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.Ship_out_from_Country}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Ship_out_from_Country: e.target.value,
                });
              }}
            >
              <option value="" disabled selected>
                --- กรุณาเลือก ---
              </option>
              {conutryData.map((e) => {
                return <option value={e.id}>{e.name}</option>;
              })}
            </select>
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Ship out from Country
              {!tabData.Is_Product_Kitting &&
              (tabData.Ship_out_from_Country === "" ||
                tabData.Ship_out_from_Country === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.Loading_Port}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Loading_Port: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Loading Port
              {/* {inputData.Supplier_Name === "" ||
              inputData.Supplier_Name === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div class="form-group">
            <input
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.Thailand_Port}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Thailand_Port: e.target.value,
                });
              }}
            />
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Thailand Port
              {!tabData.Is_Product_Kitting &&
              (tabData.Thailand_Port === "" ||
                tabData.Thailand_Port === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div class="form-group">
            <select
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={tabData.Brand}
              onChange={(e) => {
                setTabData({
                  ...tabData,
                  Brand: e.target.value,
                });
              }}
            >
              <option value="" disabled selected>
                --- กรุณาเลือก ---
              </option>
              {brandData.map((e) => {
                return <option value={e.brand_id}>{e.brand_name}</option>;
              })}
            </select>
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Brand
              {!tabData.Is_Product_Kitting &&
              (tabData.Brand === "" || tabData.Brand === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <label
            style={{
              fontSize: "24px",
              lineHeight: "normal",
              wordBreak: "break-all",
            }}
          >
            Category
          </label>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-3">
          <div class="form-group">
            <select
              type="text"
              className="form-control"
              required
              disabled={isDisabled}
              value={selectedCategory.lv1}
              onChange={(e) => {
                let lv2Data = categoryData.filter((item) => {
                  return item.cat_parent_id === e.target.value;
                });

                if (lv2Data.length > 0) {
                  setCategoryLv2Data(lv2Data);

                  setSelectedCategory({
                    ...selectedCategory,
                    lv1: e.target.value,
                    lv2: "",
                    lv3: "",
                    lv4: "",
                    statusLv2: true,
                    statusLv3: false,
                    statusLv4: false,
                  });

                  setTabData({
                    ...tabData,
                    Category: "",
                  });
                } else {
                  setCategoryLv2Data([]);

                  setSelectedCategory({
                    ...selectedCategory,
                    lv1: e.target.value,
                    lv2: "",
                    lv3: "",
                    lv4: "",
                    statusLv2: false,
                    statusLv3: false,
                    statusLv4: false,
                  });

                  setTabData({
                    ...tabData,
                    Category: e.target.value,
                  });
                }
              }}
            >
              <option value="" disabled selected>
                --- กรุณาเลือก ---
              </option>
              {categoryLv1Data.map((e) => {
                return <option value={e.cat_id}>{e.cat_name}</option>;
              })}
            </select>
            <label
              style={{
                lineHeight: "normal",
                wordBreak: "break-all",
              }}
            >
              Lv. 1
              {!tabData.Is_Product_Kitting &&
              (selectedCategory.lv1 === "" || selectedCategory.lv1 === null) ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )}
            </label>
          </div>
        </div>

        {selectedCategory.statusLv2 ? (
          <div className="col-12 col-md-3">
            <div class="form-group">
              <select
                type="text"
                className="form-control"
                required
                disabled={isDisabled}
                value={selectedCategory.lv2}
                onChange={(e) => {
                  let lv3Data = categoryData.filter((item) => {
                    return item.cat_parent_id === e.target.value;
                  });

                  if (lv3Data.length > 0) {
                    setCategoryLv3Data(lv3Data);

                    setSelectedCategory({
                      ...selectedCategory,
                      lv2: e.target.value,
                      lv3: "",
                      lv4: "",
                      statusLv3: true,
                      statusLv4: false,
                    });

                    setTabData({
                      ...tabData,
                      Category: e.target.value,
                    });
                  } else {
                    setCategoryLv3Data([]);

                    setSelectedCategory({
                      ...selectedCategory,
                      lv2: e.target.value,
                      lv3: "",
                      lv4: "",
                      statusLv3: false,
                      statusLv4: false,
                    });

                    setTabData({
                      ...tabData,
                      Category: e.target.value,
                    });
                  }
                }}
              >
                <option value="" disabled selected>
                  --- กรุณาเลือก ---
                </option>
                {categoryLv2Data.map((e) => {
                  return <option value={e.cat_id}>{e.cat_name}</option>;
                })}
              </select>
              <label
                style={{
                  lineHeight: "normal",
                  wordBreak: "break-all",
                }}
              >
                Lv. 2
                {
                  /* !tabData.Is_Product_Kitting &&  */ selectedCategory.lv2 ===
                    "" || selectedCategory.lv2 === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )
                }
              </label>
            </div>
          </div>
        ) : (
          <></>
        )}

        {selectedCategory.statusLv3 ? (
          <div className="col-12 col-md-3">
            <div class="form-group">
              <select
                type="text"
                className="form-control"
                required
                disabled={isDisabled}
                value={selectedCategory.lv3}
                onChange={(e) => {
                  let lv4Data = categoryData.filter((item) => {
                    return item.cat_parent_id === e.target.value;
                  });

                  if (lv4Data.length > 0) {
                    setCategoryLv4Data(lv4Data);

                    setSelectedCategory({
                      ...selectedCategory,
                      lv3: e.target.value,
                      lv4: "",
                      statusLv4: true,
                    });

                    setTabData({
                      ...tabData,
                      Category: e.target.value,
                    });
                  } else {
                    setCategoryLv4Data([]);

                    setSelectedCategory({
                      ...selectedCategory,
                      lv3: e.target.value,
                      lv4: "",
                      statusLv4: false,
                    });

                    setTabData({
                      ...tabData,
                      Category: e.target.value,
                    });
                  }
                }}
              >
                <option value="" selected>
                  --- กรุณาเลือก ---
                </option>
                {categoryLv3Data.map((e) => {
                  return <option value={e.cat_id}>{e.cat_name}</option>;
                })}
              </select>
              <label
                style={{
                  lineHeight: "normal",
                  wordBreak: "break-all",
                }}
              >
                Lv. 3
                {/* {(selectedCategory.lv3 === "" ||
                selectedCategory.lv3 === null) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>
            </div>
          </div>
        ) : (
          <></>
        )}

        {selectedCategory.statusLv4 ? (
          <div className="col-12 col-md-3">
            <div class="form-group">
              <select
                type="text"
                className="form-control"
                required
                disabled={isDisabled}
                value={selectedCategory.lv4}
                onChange={(e) => {
                  setSelectedCategory({
                    ...selectedCategory,
                    lv4: e.target.value,
                  });

                  setTabData({
                    ...tabData,
                    Category: e.target.value,
                  });
                }}
              >
                <option value="" selected>
                  --- กรุณาเลือก ---
                </option>
                {categoryLv4Data.map((e) => {
                  return <option value={e.cat_id}>{e.cat_name}</option>;
                })}
              </select>
              <label
                style={{
                  lineHeight: "normal",
                  wordBreak: "break-all",
                }}
              >
                Lv. 4
                {/* {(selectedCategory.lv4 === "" ||
                selectedCategory.lv4 === null) ? (
                  <span style={{ color: "red" }}> *</span>
                ) : (
                  ""
                )} */}
              </label>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {mode === "edit" ? (
        <>
          <div className="row">
            <div className="col-12 col-md-6 col-xl-4">
              <div class="form-group">
                <select
                  type="text"
                  className="form-control"
                  required
                  disabled={isDisabled}
                  value={tabData.Product_Status}
                  onChange={(e) => {
                    setTabData({
                      ...tabData,
                      Product_Status: e.target.value,
                    });
                  }}
                >
                  <option value="" disabled selected>
                    --- กรุณาเลือก ---
                  </option>
                  {productStatus.map((e) => {
                    return <option value={e.pst_id}>{e.pst_name}</option>;
                  })}
                </select>
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Status
                  {tabData.Product_Status === "" ||
                  tabData.Product_Status === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )}
                </label>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col-12">
          <label
            style={{
              fontSize: "24px",
              lineHeight: "normal",
              wordBreak: "break-all",
            }}
          >
            EAN CODE (Barcode)
            {barcodeInputList.length === 0 ? (
              <span style={{ color: "red" }}> *</span>
            ) : (
              ""
            )}
            {/* {tabData.Barcode === "" ||
              tabData.Barcode === null ? (
                <span style={{ color: "red" }}> *</span>
              ) : (
                ""
              )} */}
          </label>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-6 col-md-3 col-xl-2">
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDisabled}
            onClick={() => {
              setBarcodeInputData({
                id: "",
                piece: "",
                pack: "",
                carton: "",
                mode: "add",
              });

              toggle();
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className="table-responsive " style={{ whiteSpace: "nowrap" }}>
        <MDBDataTable
          sortable={false}
          className="table table-head-fixed"
          striped
          bordered
          hover
          fixedHeader
          data={data}
          disableRetreatAfterSorting={true}
        />
      </div>

      <Modal
        isOpen={modal}
        toggle={() => {
          toggle();
        }}
        size="md"
        className="Modal_Center_LeftRight"
      >
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          <label
            style={{
              fontSize: "40px",
              fontWeight: "700",
              lineHeight: "normal",
              color: "black",
              margin: "0px",
            }}
          >
            Add EAN CODE (Barcode)
          </label>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={0}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={isDisabled}
                  value={barcodeInputData.piece}
                  onChange={(e) => {
                    setBarcodeInputData({
                      ...barcodeInputData,
                      piece: e.target.value,
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Piece (pcs)
                  {/* {tabData.Thailand_Port === "" ||
                  tabData.Thailand_Port === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )} */}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={0}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={isDisabled}
                  value={barcodeInputData.pack}
                  onChange={(e) => {
                    setBarcodeInputData({
                      ...barcodeInputData,
                      pack: e.target.value,
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Pack
                  {/* {tabData.Thailand_Port === "" ||
                  tabData.Thailand_Port === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )} */}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={0}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={isDisabled}
                  value={barcodeInputData.carton}
                  onChange={(e) => {
                    setBarcodeInputData({
                      ...barcodeInputData,
                      carton: e.target.value,
                    });
                  }}
                />
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Carton (Ctn)
                  {/* {tabData.Thailand_Port === "" ||
                  tabData.Thailand_Port === null ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : (
                    ""
                  )} */}
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <div className="col-auto">
            <button
              type="button"
              class="btn btn-block btn-danger"
              onClick={() => {
                toggle();
              }}
            >
              ยกเลิก
            </button>
          </div>

          {(barcodeInputData.piece !== "" && barcodeInputData.piece !== null) ||
          (barcodeInputData.pack !== "" && barcodeInputData.pack !== null) ||
          (barcodeInputData.carton !== "" &&
            barcodeInputData.carton !== null) ? (
            <div className="col-auto">
              <button
                type="button"
                class="btn btn-block btn-success"
                onClick={() => {
                  if (barcodeInputData.mode === "add") {
                    barcodeInputList.push({
                      id: barcodeInputList.length,
                      piece: barcodeInputData.piece || "",
                      pack: barcodeInputData.pack || "",
                      carton: barcodeInputData.carton || "",
                      main: false,
                      oldNew: "new",
                      status: "add",
                    });
                  } else if (barcodeInputData.mode === "edit") {
                    const index = barcodeInputList.findIndex(
                      (item) => item.id === barcodeInputData.id
                    );

                    barcodeInputList[index].piece = barcodeInputData.piece;
                    barcodeInputList[index].pack = barcodeInputData.pack;
                    barcodeInputList[index].carton = barcodeInputData.carton;

                    if (barcodeInputList[index].oldNew === "old") {
                      barcodeInputList[index].status = "update";
                    }
                  }

                  const dataList = barcodeInputList.filter((item) => {
                    return item.status !== "del";
                  });

                  const newList = barcodeInputList.filter((item) => {
                    return item.status === "add" && item.oldNew === "new";
                  });

                  const updateList = barcodeInputList.filter((item) => {
                    return item.status === "update" && item.oldNew === "old";
                  });

                  const delList = barcodeInputList.filter((item) => {
                    return item.status === "del" && item.oldNew === "old";
                  });

                  setBarcodeInputList(dataList);

                  setTabData({
                    ...tabData,
                    Barcode: {
                      newData: newList,
                      updateData: updateList,
                      delData: delList,
                    },
                  });

                  tabData.Barcode.newData = newList;
                  tabData.Barcode.updateData = updateList;
                  tabData.Barcode.delData = delList;

                  toggle();
                }}
              >
                บันทึก
              </button>
            </div>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Basic_Data;
