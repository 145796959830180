import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import sortJsonArray from "sort-json-array";
import Swal from "sweetalert2";
import Footter from "../../../component/Footter";
import Header from "../../../component/Header";
import Sidebar from "../../../component/Sidebar";
import axios from "axios";
import { getOem, getToken, getUser, getFeature, getAcademy } from "../../../Utils/Common";
import Configs from "../../../config";
import NumberFormat from "react-number-format";
import QRCode from "qrcode.react";
import moment from "moment";
import { userdefault_img, logoTTT } from "../../../routes/imgRoute/imgUrl";
import INF from "../../../component/input_feature";
import LOGH from "../../../component/log_detailHistory_feature";
import { NUMBER_BINARY_OPERATORS } from "@babel/types";

import Select from "react-select";
function RawMatSTPInOut(params) {
  const [materail, setmaterail] = useState([]);
  const [mat_type_option, setmat_type_option] = useState([]);
  const [mat_spec_option, setmat_spec_option] = useState([]);
  const [mat_size_option, setmat_size_option] = useState([]);
  const [mat_supp_option, setmat_supp_option] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [type, setType] = useState([]);
  const [size, setSize] = useState([]);
  const [spect, setSpect] = useState([]);
  const [oem_select, setoem_select] = useState({
    oem_id:getOem(),
  });
  const [history, sethistory] = useState({
    id: "",
    material_id: "",
    edit_type: "",
    amount: "",
    create_date: "",
    mat_lot_no: "",
  });
  const [feature, setfeature] = useState([]);
  const [history_Select, sethistory_Select] = useState({
    isSelect_status: false,
  });
  const [historyMAT, setHistoryMAT] = useState([]);
  const [amount, setamount] = useState({
    id: "",
    edit_type: true,
    amount: 1,

    total: "",
    lot_no: "",
    lot_target:"",
    ref_lot_no:"",
    weight_by_tag:"",
    actual_weight:"",
  });

  const [fillter, setfillter] = useState({
    no: "",
    name: "",
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    material_supplier_id: "",
    material_unit_id: "",
    oem_id: getOem(), 
    company_id: getUser().com,
    companyGroup_id: "",
    with_oem:true,
  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });
  const [companyGroup, setCompanyGroup] = useState([]);

 

  useEffect(() => {
    
    ////////permission feature//////////
  
    ////////permission feature//////////



    
    Getdata();



  }, []);

  

  const Getdata = async () => {
let temp = {
  no: fillter.no.trim(),
  name: fillter.name.trim(),
  material_type_id: fillter.material_type_id,
  material_spec_id: fillter.material_spec_id,
  material_size_id: fillter.material_size_id,
  material_supplier_id: fillter.material_supplier_id,
  material_unit_id: fillter.material_unit_id,
  oem_id: getOem(), 
  company_id: getUser().com,
  companyGroup_id: fillter.companyGroup_id,
  with_oem:true,
}
 
    await axios({
      method: "post",
      url: Configs.API_URL + "/MatSTP_InOut/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        /*   await setmaterail(response.data); */

        let sorted = response.data.sort((a, b) => b.amount - a.amount);
        await setmaterail(sorted);

        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  const selectMat_type = useRef();
  const selectMat_spec = useRef();
  const selectMat_size = useRef();
  const selectMat_supp = useRef();
  const selectCompanyGroup = useRef(); 

  const clearfillter = async () => {
    await setfillter({
      ...fillter,
      product_model_id: "",
      name: "",
      no: "",
      material_type_id: "",
      material_spec_id: "",
      material_size_id: "",
      material_supplier_id: "",
      oem_id: getOem(),
      companyGroup_id: "",
    });
    fillter.product_model_id = "";
    fillter.name = "";
    fillter.no = "";
    fillter.material_type_id = "";
    fillter.material_spec_id = "";
    fillter.material_size_id = "";
    fillter.material_supplier_id = "";
    fillter.oem_id = getOem();
    fillter.companyGroup_id = "";
    
      feature.map((e) => {
        if (e.feature === "Mat Type") {
          selectMat_type.current.select.clearValue();
        }
        if (e.feature === "Mat Spec") {
          selectMat_spec.current.select.clearValue();
        }
        if (e.feature === "Mat Size") {
          selectMat_size.current.select.clearValue();
        }
        if (e.feature === "Mat Supplier") {
          selectMat_supp.current.select.clearValue();
        }
     /*    if (e.feature === "Group_Company") {
      
          selectCompanyGroup.current.select.clearValue();
        } */
        
      })
    
   
   
    
   
    Getdata();
  };

  // console.log(amount);
  const Getamount = async () => {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText:`No`,
    }).then((result) => {
      if(result.isConfirmed){
        const error_list = [];
        if( amount.edit_type === true &&
          (amount.weight_by_tag === null ||
          amount.weight_by_tag === undefined ||
          amount.weight_by_tag === "" ||
          Number(amount.weight_by_tag.replace(/,/g, "")) < 1)){
          let temp_err ={
            message:"กรุณากรอก >>> [น้ำหนักตามป้าย(tag)]."
          }
          error_list.push(temp_err);
        }
        if(  amount.edit_type === true &&
          (amount.actual_weight === null ||
          amount.actual_weight === undefined ||
          amount.actual_weight === "" ||
          Number(amount.actual_weight.replace(/,/g, "")) < 1)){
          let temp_err ={
            message:"กรุณากรอก >>> [น้ำหนักชั่งจริง]."
          }
          error_list.push(temp_err);
        }

        if(  amount.edit_type === false &&
          (amount.lot_target === null ||
          amount.lot_target === undefined ||
          amount.lot_target === "" )){
          let temp_err ={
            message:"กรุณาเลือก Lot ที่จะทำการ Out bound."
          }
          error_list.push(temp_err);
        }
        
        if(error_list.length > 0){
          var err_message = "";
          for(var e = 0; e < error_list.length;e++){
            err_message += "<br/>"+error_list[e].message;
          }
          Swal.fire("Error", err_message, "error");
        }else{
        
       
            //console.log(amount.lot_no);
            axios({
              method: "get",
              url: Configs.API_URL + "/MatSTP_InOut/genLotNo_material?oem_id="+oem_select.oem_id,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              /* data: tracking, */
            })
              .then(function (response) {
                setamount({ ...amount, lot_no: response.data });
                amount.lot_no = response.data;
                //console.log(response.data)
            
            const temp_amount = {
              id: amount.id,
              edit_type: amount.edit_type,
              amount: Number(amount.amount),
              lot_no: amount.lot_no,
              ref_lot_no: amount.ref_lot_no,
              weight_by_tag:  Number(amount.weight_by_tag.replace(/,/g, "")),
              actual_weight: Number(amount.actual_weight.replace(/,/g, "")),
              lot_target: amount.lot_target,

            };
    
           // console.log(temp_amount);
    
            axios({
              method: "post",
              url: Configs.API_URL + "/MatSTP_InOut/amountInOut",
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp_amount,
            })
              .then(async (response) => {
                if (response.data) {
                  Swal.fire({
                    title: "Saving",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    timer: 300,
                    onOpen: () => {
                      Swal.showLoading();
                    },
                  }).then((result) => {
                    Swal.fire({
                      icon: "success",
                      title: "Save",
                      showConfirmButton: false,
                      timer: 1500,
                    }).then((result) => {
                      genLotNo();
                      getHistoryMAT(amount.id);
                      setamount({...amount,
                        amount: 1,
                        weight_by_tag: "",
                        ref_lot_no:"",
                        actual_weight:"",
                        lot_target:"",
                      });
                      amount.amount = 1;
                      amount.actual_weight = "";
                      amount.ref_lot_no = "";
                      amount.weight_by_tag = "";
                      amount.lot_target = "";
                      //clear radio----------------------------------------
                      var radioButtonArray = document.getElementsByName('check_lot_matSTP');
                      //console.log(radioButtonArray)
                      for (var i=0; i<radioButtonArray.length; i++)
                      {
                       var radioButton = radioButtonArray[i];
                       radioButton.checked = false;
                      }
                      //clear radio------------------------------------------
                      axios({
                        method: "get",
                        url:
                          Configs.API_URL + "/MatSTP_InOut/gethistory?id=" + amount.id,
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                      })
                        .then(async (response) => {
                          sethistory(response.data);
                          Getdata();
                          //console.log(response.data);
                        })
                        .catch(async (error) => {
                          console.log(error);
                        });
                      /* let temp = {
                        no: message.no,
                        name: "",
                        material_type_id: "",
                        material_spec_id: "",
                        material_size_id: "",
                        material_supplier_id: "",
                        material_unit_id: "",
                        oem_id: getOem(),
                      }; */
          
                  
                      axios({
                        method: "post",
                        url: Configs.API_URL + "/MatSTP_InOut/filter",
                        headers: {
                          Authorization: getToken(),
                          "X-TTT": Configs.API_TTT,
                          "Content-Type": "application/json",
                        },
                        data: fillter,
                      })
                        .then(async (response) => {
                          /*   await setmaterail(response.data); */
    
                          //let sorted = response.data.sort((a, b) => b.amount - a.amount);
                          //await setmaterail(sorted);
                          let set_total_view = response.data.filter((element) => {
                            return element.id === amount.id;
                          });
                        /*   console.log(set_total_view);
                          console.log(
                            set_total_view[set_total_view.length - 1].amount
                          ); */
                          setamount({
                            ...amount,
                            total: set_total_view[set_total_view.length - 1].amount,
                            amount:1,
                          });
                          amount.total = set_total_view[set_total_view.length - 1].amount;
                          console.log("total",amount);
                        })
                        .catch(async (error) => {
                          console.log(error);
                        });
    
                      //window.location.href = "/Warehouse_Management/Raw_Mat_In_Out";
                    });
                  });
                }
    
                //console.log(response.data);
              })
              .catch(async (error) => {
                Swal.fire("Over Stock!", "Check your amount", "warning");
                // .then(
                //   (resault) => {
                //     window.location.href = "/Warehouse_Management/Raw_Mat_In_Out";
                //   }
                // );
                console.log(error);
              });
            })
            .catch(function (e) {
              console.log(e);
            });
          }
      
          }
    });
    
  };

  function fillterdata() {
    ///* console.log(type); */ console.log(rowsData);
    Getdata();
  }

  async function setoutbound(e) {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Raw Mat IN/OUT";
    });

    ////////permission feature//////////  {feature.map((e) =>{
    feature_data.map((e) => {
      if (e.feature === "Mat IN/OUT Lot No") {
        setamount({
          ...amount,
          lot_no:moment(new Date()).format("YYYYMMDD"),
        });
      }
    });
    setamount({
      ...amount,
      edit_type: e.target.value === "false" ? false : true,
      lot_no: "",
    });
    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/MatSTP_InOut/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    sethistory_Select({ ...history_Select, isSelect_status: true });
  }

  async function setinbound(e) {
    //console.log("in");
    setamount({
      ...amount,
      edit_type: e.target.value === "true" ? true : false,
      lot_no: "",
    });
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Raw Mat IN/OUT";
    });

    ////////permission feature//////////  {feature.map((e) =>{
    feature_data.map((e1) => {
      if (e1.feature === "Mat IN/OUT Lot No") {
        //console.log("in2")
        setamount({
          ...amount,
          edit_type: e.target.value === "true" ? true : false,
          lot_no: moment(new Date()).format("YYYYMMDD"),
        });
      }
    });

    sethistory({ ...history });

    await axios({
      method: "get",
      url: Configs.API_URL + "/MatSTP_InOut/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    sethistory_Select({ ...history_Select, isSelect_status: false });
  }

  async function form(no, name, id, amount_total, edit_type,oem) {
    
    oem_select.oem_id = oem;
    //console.log(oem)
    //console.log(oem_select.oem_id)
    getHistoryMAT(id);
    genLotNo();
    //console.log(edit_type);
    setmessage({ ...message, name: name, no: no });
    setamount({
      ...amount,
      amount: "",
      total: amount_total,
      id: id,
      lot_no: "",
      edit_type: true,
      lot_target: "",
      ref_lot_no: "",
      weight_by_tag: "",
      actual_weight: "",
  
    });
    amount.id = id;
    amount.amount = 1;
    amount.total = amount_total;
    amount.edit_type = true;
    amount.lot_target = "";
    amount.ref_lot_no = "";
    amount.weight_by_tag = "";
    amount.actual_weight = "";

    ////////permission feature//////////  {feature.map((e) =>{
  
        setamount({
          ...amount,
          amount: "",
          total: amount_total,
          id: id,
          lot_no: moment(new Date()).format("YYYYMMDD"),
        });
   

    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/MatSTP_InOut/gethistory?id=" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
    // Get_history({id});
  }
  
  async function reset_select() {
    sethistory({ ...history });
    await axios({
      method: "get",
      url: Configs.API_URL + "/MatSTP_InOut/gethistory?id=" + amount.id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        sethistory(response.data);
        //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });

    if (amount.edit_type == true) {
      setamount({
        ...amount,
        lot_no: "MAT" + moment(new Date()).format("YYYYMMDD"),
      });
      sethistory_Select({ ...history_Select, isSelect_status: false });
    } else {
      setamount({ ...amount, lot_no: "" });
      sethistory_Select({ ...history_Select, isSelect_status: false });
    }
    // e.target.checked = false;
    // Get_history({id});
  }

  const rowsData = [];
  for (var index = 0; index < materail.length; index++) {
    const rowItem = {};
    if (materail[index].amount < materail[index].minimum_lot) {
      var c = "red";
    } else if (materail[index].amount >= materail[index].minimum_lot) {
      var c = "black";
    } else {
      var c = "black";
    }
    rowItem["num"] = <div style={{ color: c }}>{index + 1}</div>;
    rowItem["mat_code"] = materail[index].no;

    /*  rowItem["unit_by"] = materail[index].material_unit_id; */

    for (let indexin = 0; indexin < spect.length; indexin++) {
      if (materail[index].material_spec_id === spect[indexin].id) {
        rowItem["spec"] = spect[indexin].name;
      } /* else {
        rowItem["spec"] = "none"
      } */
    }
    for (let indexin = 0; indexin < size.length; indexin++) {
      if (materail[index].material_size_id === size[indexin].id) {
        rowItem["mat_size"] = size[indexin].name;
      } /* else{
      rowItem["mat_size"] = "none"
    } */
    }
    for (let indexin = 0; indexin < type.length; indexin++) {
      if (materail[index].material_type_id === type[indexin].id) {
        rowItem["mat_type"] = type[indexin].name;
      } /* else{
    rowItem["mat_type"] = "none"
  } */
    }
    for (let indexin = 0; indexin < supplier.length; indexin++) {
      if (materail[index].material_supplier_id === supplier[indexin].id) {
        rowItem["mat_supp"] = supplier[indexin].name;
      } /* else {
        rowItem["mat_pupp"] = "none";
      } */
    }

    rowItem["Min_lot_sup"] = (
      <NumberFormat
        value={
          materail[index].minimum_order_supplier === null
            ? 0
            : materail[index].minimum_order_supplier
        }
        displayType={"text"}
        thousandSeparator={true}
      />
    );
    rowItem["Min_lot"] = (
      <div style={{ color: c }}>
        <NumberFormat
          value={
            materail[index].minimum_lot === null
              ? 0
              : materail[index].minimum_lot
          }
          displayType={"text"}
          thousandSeparator={true}
        />
      </div>
    );

    if (
      materail[index].material_unit_id ===
      "505a84a4-6c71-4fbf-9dbd-8c63644221f5"
    ) {
      rowItem["unit_id"] = "Piece";
    } else {
      rowItem["unit_id"] = "Weight";
    }
    rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            materail[index].imaget_path !== null
              ? Configs.API_URL_IMG + materail[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
    rowItem["qr"] = (
      <div style={{ textAlign: "center" }}>
        <QRCode
          value={materail[index].no}
          size={50}
          fgColor={"#000000"}
          bgColor={"#ffffff"}
          level={"M"}
          renderAs={"svg"}
          includeMargin={false}
          imageSettings={{
            src: logoTTT.imgs,
            height: 15,
            width: 15,
            x: null,
            y: null,
            excavate: true,
          }}
        />
      </div>
    );

    rowItem["name"] = materail[index].name;
    rowItem["amount"] = (
      /* materail[index].amount; */ <div style={{ color: c }}>
        <NumberFormat
          value={parseInt(materail[index].amount || 0)}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
      </div>
    );
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className="btn btn-xs "
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={form.bind(
              this,
              materail[index].no,
              materail[index].name,
              materail[index].id,
              materail[index].amount || 0,
              amount.edit_type,
              materail[index].oem_id,
            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "num",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material_Code",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
        label: "QR",
        field: "qr",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material_Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
    /*   {
        label: "Material_Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },
    
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "Size",
        field: "mat_size",
        sort: "asc",
        width: 50,
      }, */

      {
        label: "Min_Stock",
        field: "Min_lot",
        sort: "asc",
        width: 50,
      },
  
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
  
  
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
 

  function changes_lot_no_Target(e){

    setamount({...amount,lot_target:e.target.value});
    amount.lot_target = e.target.value;
    console.log(amount.lot_target)
  }
  const rowsData_history = [];

  for (var index = 0; index < history.length; index++) {
    const rowItem = {};
    rowItem["lot_no"] = history[index].mat_lot_no;

    rowItem["ref_lot_no"] = history[index].ref_lot_no;
    rowItem["weight_by_tag"] = history[index].weight_by_tag;
    rowItem["actual_weight"] = history[index].actual_weight;


    rowItem["items"] = parseInt(history[index].amount);
  
    rowItem["date"] = moment(history[index].create_date).format('DD/MM/yyyy  HH:mm น.');
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-6"></div>
        <div className="col-4">
          <input
            className="form-check-input"
            type="radio"
            id={index}
            name="check_lot_matSTP"

            value={history[index].mat_lot_no}
            onClick={changes_lot_no_Target.bind(this)
          
              /* changes_log_no.bind(
              this
            ) */}
          />
        </div>
      </div>
    );
    rowsData_history.push(rowItem);
  }

  const data_history = {
    columns: [
       {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No.",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Ref Lot No.",
        field: "ref_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนักตามป้าย",
        field: "weight_by_tag",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนักชั่งจริง",
        field: "actual_weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "items",
        sort: "asc",
        width: 50,
      },
      {
        label: "date",
        field: "date",
        sort: "asc",
        width: 50,
      },
 
    ],
    rows: rowsData_history,
  };


  if (amount.edit_type === true) {
    var index = data_history.columns.findIndex((e) => {
      return e.field === "mgt";
    })
    data_history.columns.splice(index, 1);
  }
 

  function getHistoryMAT(material_id) {
    axios({
      method: "get",
      url: Configs.API_URL + "/MatSTP_InOut/getHistoryMAT?id=" + material_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
        setHistoryMAT(response.data);
        console.log(response.data);
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const rowsData_historyMAT = [];

  for (var index = 0; index < historyMAT.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["mat_lot_no"] = historyMAT[index].mat_lot_no;
    rowItem["ref_lot_no"] = historyMAT[index].ref_lot_no;
    var status;
    if (historyMAT[index].edit_type === true) {
      status = "IN";
    } else {
      status = "OUT";
    }
    rowItem["status"] = status;
    rowItem["weight_by_tag"] = historyMAT[index].weight_by_tag;
    rowItem["actual_weight"] = historyMAT[index].actual_weight;
    rowItem["amount"] = parseInt(historyMAT[index].amount);
    rowItem["date"] = moment(historyMAT[index].create_date).format(
      "DD/MM/yyyy :: HH:mm น."
    );

    rowsData_historyMAT.push(rowItem);
  }

  const Data_HistoryMAT = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No.",
        field: "mat_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Ref Lot No.",
        field: "ref_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
     
    
      {
        label: "น้ำหนักตามป้าย",
        field: "weight_by_tag",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนักชั่งจริง",
        field: "actual_weight",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_historyMAT,
  };
  async function genLotNo() {
    axios({
      method: "get",
      url: Configs.API_URL + "/MatSTP_InOut/genLotNo_material?oem_id="+oem_select.oem_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      /* data: tracking, */
    })
      .then(function (response) {
      setamount({ ...amount, lot_no: response.data });
        amount.lot_no = response.data;
        console.log(response.data)
      })
      .catch(function (e) {
        console.log(e);
      });
  }

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="rawmatinout" submenu="none" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Raw Material STP IN/OUT{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                  Raw Material STP IN/OUT
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    value={fillter.no.trim()}
                    onChange={(e) => {
                      setfillter({ ...fillter, no: e.target.value.trim() });
                    }}
                    required
                  />
                  <label htmlFor="">Mat No.</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    value={fillter.name.trim()}
                    onChange={(e) => {
                      setfillter({ ...fillter, name: e.target.value.trim() });
                    }}
                    required
                  />
                  <label htmlFor="">Mat Name</label>
                </div>
              </div>
           

            
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2 ">
            <div className="col-4 col-md-3 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm "
                  onClick={fillterdata}
                >
                  Search
                </button>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm"
                  onClick={() => {
                    clearfillter();
                  }}
                >
                  Clear
                </button>
              </div>
              {/*
              <div className=" col-sm-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-sm "
                  onClick={showmessage}
                >
                  QR Code
                </button>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Raw Material STP IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div
                className="table-responsive" /*s tyle={{ height: "400px" }} */
              >
                <MDBDataTable
                  className="table table-head-fixed"
                  sortable = {false}
                 /*  disableRetreatAfterSorting={true} */
                  striped
                  bordered
                  hover
                  order={["amount", "asc"]}
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
        <div className="modal fade" id="modal-xl">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">
                  Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={{
                        backgroundColor: "#F0FFFF",
                        border: "1px solid #ddd",
                        padding: "10px",
                        width: "100",
                      }}
                      decimalScale={2}
  /*                     value={amount.total|| 0 } */
                      value={parseInt(amount.total|| 0 )}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  }
                  &nbsp;&nbsp;&nbsp; items
                </h1>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  //onClick={close}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-2"></div>
                  <div className="col-4">
                    <h3>Mat No : {message.no}</h3>
                    <input
                      type="radio"
                      id="inbound"
                      name="edit_type"
                      checked={amount.edit_type != true ? false : true}
                      value={true}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "true" ? true : false,
                        });
                      }}
                      /*   checked={amount.edit_type}
                      value="true"
                      onChange={(e) => {
                         setinbound(e);
                      }} */
                    />

                    <label htmlFor="inbound">
                      <h4> In bound</h4>
                    </label>
                  </div>
                  <div className="col-4">
                    <h3>Material Name : {message.name}</h3>

                    <input
                      type="radio"
                      id="outbound"
                      name="edit_type"
                      value={false}
                      checked={amount.edit_type != false ? false : true}
                      onChange={(e) => {
                        
                        setamount({
                          ...amount,
                          edit_type: e.target.value === "false" ? false : true,lot_target:""
                        });
                      }}
                    />
                    <label htmlFor="outbound">
                      <h4> Out bound</h4>
                    </label>
                  </div>
                </div>
              

          {/*       {feature.map((e) => {
                  if (e.feature === "Mat IN/OUT History") {
                    return ( */}
                      <LOGH
                        onClick={reset_select}
                        history={Data_HistoryMAT}
                        data={data_history}
                      />
               {/*      );
                  }
                })} */}
             
                <div className="row"></div>
                <div className="row">
            

                  {amount.edit_type === true ? (
                    <>
                          <div className="col-4">
                          <INF
                                value={amount.ref_lot_no}
                                //disabled={true}
                                label={"Ref Lot"}
                                onChange={(e) => {
                                  setamount({
                                    ...amount,
                                    ref_lot_no: e.target.value,
                                  });
                                }}
                              />
                          </div>
                      <div className="col-4">
                       
                              <INF
                                value={amount.lot_no}
                                disabled={true}
                                label={"Lot No"}
                                onChange={(e) => {
                                  setamount({
                                    ...amount,
                                    lot_no: e.target.value,
                                  });
                                }}
                              />
                     
                      </div>

                      <div className="col-4">
                       {/*  {feature.map((e) => {
                          if (e.feature === "MAT EXP") {
                            return ( */}
                             
                          {/*   );
                          }
                        })} */}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                    </>
                  )}
          
  {amount.edit_type === true ? (
                    <>
                             
                  <div className="col-4">
                  <div className="form-group ">
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        value={amount.weight_by_tag}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            weight_by_tag: e.target.value,
                          });
                        }}
                        required
                      />
                      <label htmlFor="">น้ำหนักตามป้าย(tag)</label>{" "}
                    </div>
                  </div>
                  </>
                  ) : (
                    <>  
                      <div className="col-4"></div>
                      <div className="col-4"></div>
                    </>
                  )}
                  
                  <div className="col-4">
                    <div className="form-group ">
                      <NumberFormat
                        disabled = {true}
                        thousandSeparator={true}
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        value={amount.amount}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            amount: e.target.value,
                          });
                        }}
                        required
                      />
                      <label htmlFor="">Amount</label>{" "}
                    </div>
                  </div>
                  {amount.edit_type === true ? (
                    <>
              
                  <div className="col-4">
                  <div className="form-group ">
                      <NumberFormat
                        thousandSeparator={true}
                        decimalScale={2}
                        type="text"
                        className="form-control"
                        allowNegative={false}
                        value={amount.actual_weight}
                        onChange={(e) => {
                          setamount({
                            ...amount,
                            actual_weight: e.target.value,
                          });
                        }}
                        required
                      />
                      <label htmlFor="">น้ำหนักชั่งจริง</label>{" "}
                    </div>
                  </div>
                      </>
                      ) : (
                        <>  
                          <div className="col-4"></div>
                        </>
                      )}
                </div>
            

              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={Getamount}
                  // data-dismiss="modal"
                  // aria-label="Close"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*    <Footter /> */}
    </div>
  );
}
export default RawMatSTPInOut;
