import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import DatePicker from "react-datepicker";
import { useParams } from "react-router";
import moment from "moment";
import Select from "react-select";

function Record_oil(params) {
  const [record_oil, setrecord_oil] = useState([]);
  const [company, setCompany] = useState([]);
  const [getgas_station, setgetgas_station] = useState([]);
  /* console.log(getUser().com); */
  const [filler_record_oil, setFiller_record_oil] = useState({
    record_oil: "",
    oil_location_name: "",
    plate_number: "",
    company_id: getUser().com,
    oem_id: getOem(),
    oem_target: getOem(),
    date_start: "",
    date_end: "",
  });

  const [filler_record, setFiller_record] = useState({
    start_date: "",
  });
  const [companyGroup, setCompanyGroup] = useState([]);
  const [all_record_oil, setall_record_oil] = useState([]);

  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });

  async function SetDate_start(date) {
    setFiller_record_oil({ ...filler_record_oil, date_start: date });
  }
  async function SetDate_end(date) {
    setFiller_record_oil({ ...filler_record_oil, date_end: date });
  }

  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectCompanyGroup = useRef();
  const { id } = useParams();
  useEffect(() => {
    GetCompany();
    getallRecord();
    getgas();
    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);

          if (el.id === getOem()) {
            setCurrentOEM({ label: el.name, value: el.id });

            /* console.log("Current_OEM",getOem(),element.id); */
          }
        });
        /*   console.log(options); */
        setCompanyGroup(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  console.log(id);

  const clearFilter = async () => {
    await setFiller_record_oil({
      ...filler_record_oil,
      record_oil: "",
      oil_location_name: "",
      plate_number: "",
      company_id: getUser().com,
      oem_id: getOem(),
      oem_target: getOem(),
      date_start: "",
      date_end: "",
    });
    filler_record_oil.record_oil = "";
    filler_record_oil.oil_location_name = "";
    filler_record_oil.plate_number = "";
    filler_record_oil.company_id = getUser().com;
    filler_record_oil.oem_id = getOem();
    filler_record_oil.oem_target = getOem();
    filler_record_oil.date_start = "";
    filler_record_oil.date_end = "";
    // selectCompanyGroup.current.select.clearValue();
    getallRecord();
  };

  function addpage(params) {
    window.location.href = "/Warehouse_Management/Record_oil/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/logistic/delrecord?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                // window.location.href = "/Warehouse_Management/Record_oil";
                getallRecord();
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  function getgas() {
    let locasionoill = { company_id: getUser().com };
    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/getgas_station",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: locasionoill,
    })
      .then(function (response) {
        console.log("เลือกที่เติม", response.data);
        setgetgas_station(response.data);
      })

      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error, "error");
      });
  }

  function getallRecord() {
    // let company = {company_id: getUser().com}
    let filter = {
      record_oil: filler_record_oil.record_oil,
      oil_location_name: filler_record_oil.oil_location_name,
      plate_number: filler_record_oil.plate_number.trim(),
      company_id: filler_record_oil.company_id,
      oem_id: filler_record_oil.oem_id,
      oem_target: filler_record_oil.oem_target,
      date_start: filler_record_oil.date_start,
      date_end: filler_record_oil.date_end,
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/all_record_oil",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter,
    })
      .then(function (response) {
        setall_record_oil(response.data);


      /*   console.log("ข้อมูลทั้งหมด", response.data); */
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("ErrorGetOil", error, "errorgetplate");
      });
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const rowsData = [];

  for (var index = 0; index < all_record_oil.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["plate_number"] = all_record_oil[index].plate_number;
    rowItem["record_date"] = moment(all_record_oil[index].record_date).format(
      "DD/MM/yyyy, HH:mm น."
    );
    rowItem["mileage_number"] = numberWithCommas(
      all_record_oil[index].mileage_number
    );
    rowItem["liter"] = numberWithCommas(all_record_oil[index].liter);
    rowItem["oil_location_name"] = all_record_oil[index].oil_location_name;

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Record_oil/read/" +
              all_record_oil[index].id
            }
            key={all_record_oil[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>

        {all_record_oil[index].oem_id === currentOEM.value ? (
          <>
            {/* <div className="col-4">
              <a
                href={
                  "/Warehouse_Management/Record_oil/edit/" +
                  all_record_oil[index].id
                }
                key={all_record_oil[index].id}
                className=" btn btn-xs "
              >
                {" "}
                <i class="fas fa-pencil-alt"></i>{" "}
              </a>
            </div> */}
            <div className="col-4">
              <a
                key={all_record_oil[index].id}
                className=" btn btn-xs "
                onClick={deldata.bind(this, all_record_oil[index].id)}
              >
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทะเบียนรถ",
        field: "plate_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่",
        field: "record_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขไมล์ (กม.)",
        field: "mileage_number",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวนลิตร (ลิตร)",
        field: "liter",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานที่เติม",
        field: "oil_location_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mgt",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>บันทึกการใช้น้ำมัน{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">บันทึกการใช้น้ำมัน</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    allowNegative={false}
                    className="form-control"
                    required="false"
                    value={filler_record_oil.plate_number}
                    onChange={(e) => {
                      setFiller_record_oil({
                        ...filler_record_oil,
                        plate_number: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ทะเบียนรถ</label>{" "}
                </div>
              </div>

              <div className="col-md-0.5">
                <label> วันที่ &nbsp;</label>
              </div>
              <div className="col-md-1.5" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <DatePicker
                    selected={filler_record_oil.date_start}
                    className="form-control"
                    //required="false"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => SetDate_start(date)}
                    selectsStart
                    /*     startDate={filler_record_oil.start_date}
                    endDate={filler_record_oil.end_date} */
                  />
                </div>
              </div>
              <div className="col-md-0.5">
                <label> &nbsp; ถึง &nbsp;</label>
              </div>
              <div className="col-md-1.5" style={{ "z-index": "99" }}>
                <div className="form-group ">
                  <DatePicker
                    selected={filler_record_oil.date_end}
                    className="form-control"
                    //required="false"
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => SetDate_end(date)}
                    selectsStart
                    /*     startDate={filler_record_oil.start_date}
                    endDate={filler_record_oil.end_date} */
                  />
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_record_oil.oil_location_name}
                    onChange={(e) =>
                      setFiller_record_oil({
                        ...filler_record_oil,
                        oil_location_name: e.target.value,
                      })
                    }
                  >
                    <option value="">Select</option>
                    {getgas_station.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.oil_location_name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">สถานที่</label>
                </div>
              </div>

              <div className="col-md-2" style={{ "z-index": "99" }}>
                <div className="form-group ">
                {getOem() === currentOEM.value && currentOEM.label ==="บจก.สตูลค้าเหล็ก"? (
                    <>
                    <Select
                    options={companyGroup}
                    value={companyGroup.filter((e) => {
                      return e.value === filler_record_oil.oem_target;
                    })}
                    // Value={filler_record_oil.oem_target}
                    ref={selectCompanyGroup}
                    onChange={(e) => {
                      if (e != null) {
                        setFiller_record_oil({
                          ...filler_record_oil,
                          oem_target: e.value,
                        });
                      }
                    }}
                  />
                  <label> รถของกลุ่มบริษัท </label>{" "}
                     
                    </>
                  ) : (
                    <></>
                  )}
                  
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={getallRecord}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">บันทึกการใช้น้ำมัน</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*  <Footter/> */}
    </div>
  );
}

export default Record_oil;
