import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../config";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";

import "../../../../../../src/assets/css/Quotation_CutePlus.css";

function Sales_Order({
  id,
  mode,
  sendid,
  cusid,
  sendinclvat,
  inputDataTab2,
  setInputDataTab2,
  SalesOrderParent,
  setSalesOrderParent,
  errorListTab2,
  setErrorListTab2,
  functionSaveTab2,
  setFunctionSaveTab2,
  sendDataToParent,
}) {
  const modalProductRef = useRef();

  const tempOem = {
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    user_name: getUser().name + " " + getUser().surname,
  };

  const [categoryData, setCategoryData] = useState({
    data: [],
  });

  const [productData, setProductData] = useState({
    data: [],
    filter: []
  });

  const [selectProductData, setSelectProductData] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [shelfLifeTypeData, setShelfLifeTypeData] = useState({
    data: [],
  });

  const [searchData, setSearchData] = useState({
    code: "",
    name: "",
    barcode: "",
    category: "",
    customer_barcode: "",
    customer_product_name: "",
  });

  const [Discount, setDiscount] = useState({
    discount: "",
    unit: "",
  });

  const [SalesOrder, setSalesOrder] = useState({
    so_incl_vat: "",
    so_final_discount: 0,
    so_shipping_cost: 0,
    so_total_befor_vat: 0,
    so_total_price: 0,
    so_vat: 0,
    so_total_m3: 0,
    so_lang: "",
    so_cus_id: null,
    so_final_discount_bath: "",
    so_total_of_product: 0
  });

  useEffect(() => {
    setSalesOrder({
      ...SalesOrder,
      so_cus_id: SalesOrderParent.so_cus_id || null,
      so_incl_vat: SalesOrderParent.so_incl_vat || false,
      so_lang: SalesOrderParent.so_lang || "ENG",
      so_shipping_cost: SalesOrderParent.so_shipping_cost || 0,
      so_final_discount: SalesOrderParent.so_final_discount_percent || 0,
    })
    SalesOrder.so_cus_id = SalesOrderParent.so_cus_id || null;
    SalesOrder.so_incl_vat = SalesOrderParent.so_incl_vat || false;
    SalesOrder.so_lang = SalesOrderParent.so_lang || "ENG";
    SalesOrder.so_shipping_cost = SalesOrderParent.so_shipping_cost || 0;
    SalesOrder.so_final_discount = SalesOrderParent.so_final_discount_percent || 0;
    setIsChecked(SalesOrderParent.so_lang === "TH" ? true : false);
    isChecked = SalesOrderParent.so_lang === "TH" ? true : false;
    // GetProduct();
  }, [SalesOrderParent.so_incl_vat, SalesOrderParent.so_lang, SalesOrderParent.so_cus_id]);

  useEffect(() => {
    calSummaryProductTable();
  }, [selectProductData.data]);

  useEffect(() => {
    if (SalesOrderParent.so_change_customer === true) {
      setSOProductData({
        data: []
      })
      SOProductData.data = []
    }
  }, [SalesOrderParent.so_change_customer])


  const getSonoRefsub = async (id) => {
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getSonoRefsub",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id },
    })
      .then(function (response) {
        // setSono({ data: response });
        // Sono.data = response;
        setSalesOrder(response.data[0]);
        // SalesOrder = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [resDiscount, setresDiscount] = useState([]);

  const [test, settest] = useState({});

  const [disable, setdisable] = useState(null);
  const [pageMode, setpageMode] = useState("");

  let [isChecked, setIsChecked] = useState(false);

  async function openModalProduct() {
    const tempSelectProduct = selectProductData.data;
    const tempProductTable = productData.data;

    const tempList = tempProductTable?.map((item1) => {
      for (let tl of item1.exp_date_list) {
        const check = tempSelectProduct.find(
          (item2) => item2.product.product_id === item1.product_id && tl.exp === item2.exp
        );
        if (check) {
          tl.check = true;
        } else {
          tl.check = false;
        }
      }

      return item1;
    });

    setProductData({ ...productData, data: tempList });
    productData.data = tempList;

    modalProductRef.current.click();
  }

  const [includeVAT, setIncludeVAT] = useState(false);

  const handleCheckboxChange = async (e) => {
    setIncludeVAT(e.target.checked);
    settest(...test, "test");
    await calSummaryProductTable();
  };

  async function GetSupplierRelationProduct() {
    // let tempData = {
    //   company_id: getUser().com,
    //   oem_id: getOem(),
    //   user_id: getUser().fup,
    // };

    await axios({
      method: "get",
      url:
        Configs.API_URL_cuteplus +
        "/api/supplier/getSupplierRelationProductBySupplierID/" +
        id,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      // data: tempData,
    })
      .then(function (response) {
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data,
        });

        inputDataTab2.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChange = (e) => {
    sendDataToParent(SalesOrder);
  };

  // const formatCurrency = (value) => {
  //   console.log(value);
  //   const format = new Intl.NumberFormat("th-TH", {
  //     // style: "currency",
  //     currency: "THB",
  //   }).format(value).replace(/,/g, '');
  //   console.log(format);
  //   return format;
  //   // return new Intl.NumberFormat("th-TH", {
  //   //   style: "currency",
  //   //   currency: "THB",
  //   // }).format(value);
  // };

  const validThai = new RegExp(`[\u0E00-\u0E7F]`);

  async function GetCategoryLv2() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategoryLv2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategoryData({ data: response.data });
        categoryData.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [rows, setRows] = useState([
    {
      discount: "",
      unit: "",
    },
  ]);

  const handleChangeDiscount = async (index, index2, field, value) => {
    let temp = selectProductData.data;
    // let discount = {
    //   discount: value,
    //   // unit: value,
    // }
    value = parseFloat(value?.toString().replace(/,/g, "") || 0)
    if (temp[index].product.discount[index2].unit === "percent") {
      if (value > 100) {
        value = 100;
      }
    }/*  else if (temp[index].product.discount[index2].unit === "baht") {
      
    } */
    temp[index].product.discount[index2].discount = value;

    setSelectProductData({
      ...selectProductData,
      data: temp,
    });
    selectProductData.data = temp;
    if (temp[index].product.oldNew === "old") {
      temp[index].product.status = "update";
      temp[index].product.statusUpdate = true;
      await saveProductData();
    }
    // console.log('temp[index]', temp[index]);
  };

  const handleChangeUnit = async (index, index2, field, value) => {
    let temp = selectProductData.data;
    // let discount = {
    //   // discount: value,
    //   unit: value,
    // }
    temp[index].product.discount[index2].unit = value;

    setSelectProductData({
      ...selectProductData,
      data: temp,
    });
    selectProductData.data = temp;
    if (temp[index].product.oldNew === "old") {
      temp[index].product.status = "update";
      temp[index].product.statusUpdate = true;
      await saveProductData();
    }
  };

  const handleAddRow = async (index) => {
    let temp = selectProductData.data; // Create a shallow copy of selectProductData.data
    /* if (!temp[index].product.discount) {
      // If discount array is not already initialized, initialize it as an empty array
      temp[index].product.discount = [];
    } */
    let discount = {
      discount: "",
      unit: "percent",
    };
    temp[index].product.discount.push(discount);

    setSelectProductData({
      ...selectProductData,
      data: temp,
    });
    selectProductData.data = temp;
    if (temp[index].product.oldNew === "old") {
      temp[index].product.status = "update";
      temp[index].product.statusUpdate = true;
      await saveProductData();
    }
  };

  const handleDeleteRow = async (index, index2) => {
    /* setSelectProductData((prevData) => {
      const updatedData = [...prevData.data];
      updatedData[index].product.discount.splice(index2, 1);
      return { ...prevData, data: updatedData };
    }); */

    let temp = selectProductData.data;
    temp[index].product.discount.splice(index2, 1);

    setSelectProductData({
      ...selectProductData,
      data: temp,
    });
    selectProductData.data = temp;
    if (temp[index].product.oldNew === "old") {
      temp[index].product.status = "update";
      temp[index].product.statusUpdate = true;
      await saveProductData();
    }
    calSummaryProductTable();
  };

  const handleSave = () => {
    // Add your save functionality here
  };

  async function GetShelfLifeType() {
    await axios({
      method: "get",
      url: Configs.API_URL_cuteplus + "/api/customer/getShelfLifeType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setShelfLifeTypeData({
          ...shelfLifeTypeData,
          data: response.data,
        });
        shelfLifeTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function formatDateToDDMMYYYY(dateString) {
    // console.log(dateString.date)
    const date = new Date(dateString.date);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function TableformatDateToDDMMYYYY(dateString) {
    // console.log(dateString)
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const calSummaryProductTable = async (check) => {
    let sumDiscount = 0;
    let sumTotal = 0;
    let beforeVat = 0;
    let total = 0;
    let shipCost = typeof SalesOrder.so_shipping_cost === "string" ? parseFloat(
      (SalesOrder.so_shipping_cost || "0").replace(/,/g, "")
    ) : SalesOrder.so_shipping_cost;
    let finalDiscount = typeof SalesOrder.so_final_discount === "string" ? parseFloat(SalesOrder.so_final_discount || 0) : SalesOrder.so_final_discount;
    let sumfinalDiscount = 0;
    let vat = 0;
    let m3Total = 0;
    // const checkUnit = false;
    // if ((item.discount[index].unit === || "" || null || undefined)) {
    //   checkUnit = false;
    // } else {
    //   checkUnit = true;
    // }
    // console.log(shipCost);
    for (let item of selectProductData.data) {
      // console.log(item)
      let m3 = 0;
      let sumTotal = 0;
      // console.log(item.unit)
      if (item.product.unit === "piece" || item.product.unit === undefined) {
        m3 =
          ((parseFloat(item.product.pd_dim_pch) || 1) *
            (parseFloat(item.product.pd_dim_pcl) || 1) *
            (parseFloat(item.product.pd_dim_pcw) || 1)) /
          1000000;
      } else if (item.product.unit === "pack") {
        m3 =
          ((parseFloat(item.product.pd_dim_packh) || 1) *
            (parseFloat(item.product.pd_dim_packl) || 1) *
            (parseFloat(item.product.pd_dim_packw) || 1)) /
          1000000;
      } else if (item.product.unit === "ctn") {
        m3 =
          ((parseFloat(item.product.pd_dim_ctnh) || 1) *
            (parseFloat(item.product.pd_dim_ctnl) || 1) *
            (parseFloat(item.product.pd_dim_ctnw) || 1)) /
          1000000;
      }

      let retail = 0;

      if (typeof item.product.product_price === "string") {
        retail = parseFloat(item.product.product_price.replace(/,/g, "")) || 0;
      } else if (typeof item.product.product_price === "number") {
        retail = item.product.product_price;
      }
      // console.log("ssssss",item.product.customer_bar_code)
      // console.log(selectProductData);
      // let quantity = parseFloat(item.product.product_quantity) || 1;
      let quantity = 1;
      if (item.product.unit?.toLowerCase() === "piece" || item.product.unit === undefined) {
        quantity = parseFloat(item.product.product_quantity) || 0;
        sumDiscount = retail * quantity;
      } else if (item.product.unit?.toLowerCase() === "pack") {
        quantity = (parseFloat(item.product.product_quantity) || 0) * (parseFloat(item.product.detailsAdvance.pc_pack) || 1);
        sumDiscount = retail * quantity;
      } else if (item.product.unit?.toLowerCase() === "ctn") {
        quantity = (parseFloat(item.product.product_quantity) || 0) * (parseFloat(item.product.detailsAdvance.pc_ctn) || 1);
        sumDiscount = retail * quantity;
      }
      sumTotal += (retail * quantity);
      let sumDiscountDup = sumDiscount;
      let discount_cals = 0;
      // sumDiscount = retail * quantity;
      let checkdiscount = 0;
      // console.log('item.product.discount', item.product.discount);
      for (let index = 0; index < item.product.discount?.length; index++) {

        let discount_old = 0;
        let discount_sort = 0;
        const discout_ = parseFloat(item.product.discount[index].discount?.toString()?.replace(/,/g, "") || 0) || 0;
        item.product.discount[index].discount = discout_;
        discount_old = discount_cals;

        if (item.product.discount[index].unit?.toLowerCase() === "percent") {
          discount_cals += (discout_ / 100) * sumDiscountDup;
          discount_sort = (discout_ / 100) * sumDiscountDup
        } else if (item.product.discount[index].unit?.toLowerCase() === "baht") {
          discount_cals += discout_;
          discount_sort = discout_;
        }
        // console.log('discount_cals', discount_cals)
        if (discount_cals > sumDiscount) {
          item.product.discount[index].discount = 0
          discount_cals = discount_old;
        }
        sumDiscountDup -= discount_sort;

      }
      sumDiscount -= discount_cals;
      beforeVat += sumDiscount;
      // console.log('sumDiscount', beforeVat);
      // vat = (beforeVat * 7) / 100;
      m3Total += m3 * (parseFloat(item.product.product_quantity) || 0);
      // console.log(SalesOrder.so_incl_vat);
    }
    let vatonly = (beforeVat * 7) / 100;
    total = beforeVat;
    sumfinalDiscount = beforeVat * ((finalDiscount || 0) / 100);
    beforeVat = beforeVat - sumfinalDiscount;
    vat = (beforeVat * 7) / 100;

    if (!SalesOrder.so_incl_vat || null || undefined || "") {
      sumTotal = beforeVat;
      total = total;
    } else {
      sumTotal = beforeVat + vat;
      total = total/*  + vatonly */;
    }
    sumTotal = sumTotal + (shipCost || 0);

    setSalesOrder({
      ...SalesOrder,
      so_final_discount: finalDiscount,
      so_shipping_cost: shipCost,
      total: sumTotal, //total
      so_total_price: sumTotal,
      so_total_befor_vat: beforeVat,
      so_vat: vat,
      so_total_m3: m3Total,
      so_total_of_product: total,
      so_final_discount_bath: ((finalDiscount / 100) * total)
    });
    // SalesOrder.so_shipping_cost
    SalesOrder.so_final_discount = finalDiscount;
    SalesOrder.so_shipping_cost = shipCost;
    SalesOrder.so_total_price = sumTotal;
    SalesOrder.so_total_befor_vat = beforeVat;
    SalesOrder.so_vat = vat;
    SalesOrder.so_total_m3 = m3Total;
    SalesOrder.so_total_of_product = total;
    SalesOrder.so_final_discount_bath = ((finalDiscount / 100) * total);
  };

  async function GetProduct(is_ref, is_clear, click_from) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      user_name: getUser().name + " " + getUser().surname,
      code: searchData.code.trim() || null,
      name: searchData.name.trim() || null,
      barcode: searchData.barcode.trim() || null,
      category: searchData.category.trim() || null,
      customer_barcode: searchData.customer_barcode.trim() || null,
      customer_product_name: searchData.customer_product_name.trim() || null,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductLogAndDetails2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {

        if (response.data.length == 0) {
          await resetProduct(false);
          return;
        } else if (productData.data?.length > 0) {
          setProductData({
            ...productData,
            filter: response.data
          })
          productData.filter = response.data
        }
        const tempRes = productData.data?.length > 0 ? productData.data : response.data;
        const tempResList = [];
        const selectProductDataList = [];

        let selectProductOldList = [];
        const checkreset = SalesOrderParent.so_change_customer || false;
        // console.log('checkreset', checkreset);
        if (checkreset && mode === "edit" || mode === "view") {
          selectProductOldList = structuredClone(selectProductData.data)
        }
        // console.log('tempRes ------------------', tempRes)
        for (let item1 of tempRes) {
          let tempImage = {
            company_id: getUser().com,
            oem_id: getOem(),
            // user_id: getUser().fup,
            ul_project: "Cute_Products_Plus",
            ul_type: "ภาพ",
            ul_ref_id: item1.product_id,
            // ul_menu_id: ,
            ul_is_profile: true,
            // ul_file_group: ,
          };

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getDataFileOne",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: tempImage,
          }).then(function (response) {
            item1.image = response.data;
          });

          item1.is_use = false
          if (productData.filter.length > 0) {
            const check = productData.filter.find(el => el.product_id === item1.product_id)
            if (check) {
              item1.is_use = true
            }
          } else {
            item1.is_use = true
          }
          if (is_clear) {
            let check = false
            for (let item of item1.exp_date_list) {
              selectProductData.data.forEach(el => {
                if (el.exp === item.exp && el.product.product_id === item1.product_id) {
                  item.check = true;
                  check = true;
                  // console.log('in is_clear');
                }
              })
            }
            if (check) {
              tempResList.push({ ...item1 });
              continue;

            }
          }

          item1.check = false;
          item1.retail_price = 0;
          item1.product_price = 0;
          item1.unit = "piece";
          item1.gp = "";
          item1.remaining_days = [];
          item1.type_receive = "-";
          item1.count_month = "-";
          item1.fg_amount_cal = [];
          // item1.discount = [];
          item1.product_quantity = 1;
          item1.customer_bar_code = "";
          item1.customer_code = "";
          if (!item1.oldNew) {
            item1.oldNew = "new";
          }
          /* if (item1.status)
            item1.status */
          /* if (item1.log_product && item1.log_amount) {
            item1.log_product = item1.log_product.map((el, index2) => {
              return {
                date: el,
                check: false,
                log_id: item1.log_id[index2],
                log_amount: item1.log_amount[index2],
              };
            });
          } */
          if (item1.barcode_id.length === 1) {
            item1.isBarSelect = false;
            item1.bar_code = item1.barcode_id[0];
          } else if (item1.barcode_id.length === 0) {
            item1.isBarSelect = false;
            item1.bar_code = "";
          } else {
            item1.isBarSelect = true;
            item1.bar_code = item1.barcode_id[0];
          }

          if (item1.retail_price === "" || item1.product_price) {
            item1.retail_price = item1.product_resale ? item1.product_resale : "";
            item1.product_price = item1.product_resale ? item1.product_resale : "";
          }

          // console.log("so", SalesOrder.so_cus_id)
          if (item1.detailsCustomer && SalesOrderParent.so_cus_id) {
            const check_has = item1.detailsCustomer?.find(
              (el) => el.crp_cus_id === SalesOrderParent.so_cus_id
            );
            if (check_has) {
              // console.log('check_has', check_has);
              item1.bar_code = check_has.crp_pb_id || item1.barcode_id[0]; //item1.barcode_id?.find(el => el === item1.detailsCustomer.crp_pb_id) || item1.barcode_id[0];
              item1.customer_bar_code = check_has.crp_cus_barcode || "";
              item1.customer_code = check_has.crp_cus_code || "";
              item1.gp = check_has.crp_gp1 || "";
              item1.retail_price = check_has.crp_sale_price || 0;
              item1.product_price = check_has.crp_sale_price || 0;
              if (check_has.crp_product_name_th)
                item1.product_name_eng = check_has.crp_product_name_th;
              if (check_has.crp_product_name_eng)
                item1.product_name = check_has.crp_product_name_eng;

              // item1.remaining_days = "" || "-";
              item1.type_receive = check_has.crp_slt_id ? shelfLifeTypeData.data?.find(tl => tl.slt_id === check_has.crp_slt_id).slt_name || "-" : "-";
              item1.count_month = check_has.crp_amount || "-";
              item1.exp_date_list.forEach(tl => {
                tl.product = ""
              });
              /* for (let item of item1.exp_date_list) {
                const { exp_date_list, ...other } = item1;
                // other.discount = [];
                item.product = { ...other };
              }
              tempResList.push({ ...item1 }); */
              /* if (click_from !== "search")
                item1.is_use = true; */
            } else {
              item1.is_use = false;
            }
          } else if (!item1.detailsCustomer && SalesOrderParent.so_cus_id) {
            item1.is_use = false;
          }/*  else {
            item1.is_use = true;
          } */

          if (item1.exp_date_list && item1.exp_date_list.length > 0) {
            for (let el of item1.exp_date_list) {
              item1.fg_amount_cal.push((
                el.amount_in -
                el.amount_out -
                el.amount_reserve
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0)
              if (item1.detailsCustomer && SalesOrderParent.so_cus_id && item1.count_month) {
                const range_days_cal = moment(new Date()).add(item1.count_month, "months");
                const diff_days = range_days_cal.diff(el.exp_cal, "days")
                item1.remaining_days.push(diff_days);
              }
            }
            item1.fg_amount_cal = item1.exp_date_list.map((el) => {
              return (
                (
                  el.amount_in -
                  el.amount_out -
                  el.amount_reserve
                )?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) || 0
              );
            });
          }
          // item1.discount = [];
          if (
            mode === "edit" ||
            mode === "view" ||
            ref_id_change.data === true ||
            is_ref === true ||
            /* SOProductData.data.length > 0 || */
            is_clear
          ) {
            /* const temp = [...SOProductData.data];
            console.log('temp', temp); */
            let index = 0;
            let logindex = 0;
            for (let item of item1.exp_date_list) {
              if (SOProductData.data.length > 0 && !is_clear) {
                console.log('in');
                const check = SOProductData.data.findIndex(
                  (item2) => item2.sop_exp_date === item.exp && item2.sop_product_id === item1.product_id /* && item.owner === getUser().fup */
                );
                if (check >= 0) {
                  // console.log('SOProductData.data[check]', SOProductData.data[check]);
                  item1.check = true;
                  item1.customer_bar_code = SOProductData.data[check].sop_cus_barcode;
                  item1.customer_code = SOProductData.data[check].sop_cus_code;
                  item1.oldNew = is_ref ? "new" : "old";
                  item1.bar_code = SOProductData.data[check].sop_pb_id;
                  item1.sop_id = SOProductData.data[check].sop_id;
                  item1.unit = SOProductData.data[check].sop_quantity_unit || "piece";
                  item1.retail_price = SOProductData.data[check].sop_price || 0;
                  item1.product_price = SOProductData.data[check].sop_price || 0;
                  item1.gp = SOProductData.data[check].sop_gp_percent;

                  if (SOProductData.data[check].sop_product_name_th) {
                    item1.product_name = SOProductData.data[check].sop_product_name_th;
                  }

                  if (SOProductData.data[check].sop_product_name_eng) {
                    item1.sop_product_name_eng = SOProductData.data[check].sop_product_name_eng;
                  }

                  item1.product_quantity =
                    SOProductData.data[check].sop_quantity || 1;

                  if (SOProductData.data[check].products_discount.length > 0) {
                    item1.discount = SOProductData.data[check].products_discount?.map(el => {
                      return {
                        discount: el.discount,
                        unit: el.unit
                      };
                    });
                  } else {
                    item1.discount = []
                  }
                  item1.exp = SOProductData.data[check].sop_exp_date;
                  item1.row = index;

                  const { exp_date_list, ...other } = item1;
                  item.product = { ...other };
                  if (is_ref) item.product.status = "add";

                  let check2 = false;
                  if (SOProductData.data[check].sop_exp_date === item.exp) {
                    check2 = true;
                  }

                  if (check2) {
                    item.check = true;
                    item.product.oldNew = is_ref ? "new" : "old"
                    selectProductDataList.push({ ...item });
                  } else {
                    item.product.oldNew = "new"
                  }
                  // console.log('item.product', item.product);
                  SOProductData.data?.splice(check, 1);
                }
              } else if (is_clear) {
                selectProductData.data.forEach(el => {
                  if (el.exp === item.exp && el.product.product_id === item1.product_id) {
                    item.check = true;
                    /* if (item.status === "add") {
                      item.oldNew = "new";
                    } */
                  }
                })
              }
            }
          }

          // console.log('SalesOrderParent.so_cus_id', SalesOrderParent.so_cus_id);

          for (let item of item1.exp_date_list) {
            // console.log('item1', item1);
            const { exp_date_list, ...other } = item1;

            if (checkreset && (mode === "edit" || mode === "view")) {
              for (let item2 of selectProductOldList) {
                if (item1.product_id === item2.product.product_id && item.exp === item2.exp && item2.product.sop_id) {
                  other.status = "del";
                  other.oldNew = "old";
                }
              }
            }

            if (selectProductData.delData.length > 0) {
              for (let item2 of selectProductData.delData) {
                if (item1.product_id === item2.product.product_id && item.exp === item2.exp && item2.product.sop_id) {
                  other.status = "del";
                  other.oldNew = "old";
                }
              }
            }

            if (!other.discount)
              other.discount = [];

            if (checkreset)
              item.check = false;
            // console.log('other', other)
            if (!item.product)
              item.product = { ...other };
          }

          tempResList.push({ ...item1 });
          /* if (item1.retail_price === "") {
            item1.retail_price = item1.product_price ? item1.product_price : "";
          }

          if (item1.exp_date_list && item1.exp_date_list.length > 0) {
            for (let item of item1.exp_date_list) {
              item1.discount = [];
              const { exp_date_list, ...other } = item1;
              item.product = { ...other };
            }
          } */
        }
        // console.log('selectProductDataList', selectProductDataList);
        if (!is_clear) {
          setSelectProductData({
            ...selectProductData,
            data: selectProductDataList,
          });
          selectProductData.data = selectProductDataList;

        }
        // if(tempResList){}
        // /getProductWherecat2
        // console.log('tempResList', tempResList);
        setProductData({ ...productData, data: tempResList });
        productData.data = tempResList;
        await saveProductData();

        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getDiscount",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: tempData,
        }).then(async function (response2) {
          setDiscount({ response2 });
          // for (let index = 0; index <= response.data.length; index++) {
          //   for (let index = 0; index <= response.data.length; index++) {
          //     console.log("dis:", response2.data[0].sop_product_id);
          //     console.log("pro:", response.data[index].product_id);
          //     if (
          //       response2.data[0].sop_product_id ===
          //       response.data[index].product_id
          //     ) {
          //       setDiscount({
          //         discount: response2.data.sod_discount,
          //         unit: response2.data.sod_unit_discount,
          //       });
          //     }
          //   }
          // }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [SOProductData, setSOProductData] = useState({
    data: [],
  });

  const getProductData = async (form_main_so, ref_id) => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/sale_order_product/getProductData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id: !form_main_so ? id : ref_id },
    })
      .then(async function (response) {
        setSOProductData({ ...SOProductData, data: response.data });
        SOProductData.data = response.data;
        settest({ ...test, test });
        // console.log('SOProductData.data', response.data);

        if (form_main_so && ref_id) {
          await GetProduct(form_main_so);
          await calSummaryProductTable();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [Log, setLog] = useState({
    data: "",
  });

  const getallLogProduct = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/log_product/getallLogProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        // console.log(response.data)
        setLog({ ...Log, data: response.data });
        Log.data = response.data;
        settest({ ...test, test });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function handleProductSelection(rowIndex, logIndex, action) {
    let tempData = productData.data;

    tempData[rowIndex].check = action === "add";
    tempData[rowIndex].status = action === "add" ? "add" : "del";

    setProductData({
      ...productData,
      data: tempData,
    });
  }

  let run_number = 0;
  const rowsData1 = [];
  for (let index = 0; index < productData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{run_number + 1}</div>;

    rowItem["code"] = productData.data[index].product_code
      ? productData.data[index].product_code
      : "-";

    rowItem["barcode"] = productData.data[index].barcode[0] ? productData.data[index].barcode[0] : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            productData.data[index].image
              ? productData.data[index].image.ul_path_file !== undefined &&
                productData.data[index].image.ul_path_file !== null &&
                productData.data[index].image.ul_path_file !== ""
                ? productData.data[index].image.ul_path_file?.indexOf("blob") >=
                  0
                  ? productData.data[index].image.ul_path_file
                  : Configs.API_URL_IMG_cuteplus +
                  `/static/${productData.data[index].image.ul_file_name}/${productData.data[index].image.ul_path_file}`
                : userdefault_img.imgs
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = productData.data[index].product_name_eng
      ? productData.data[index].product_name_eng
      : "-";

    rowItem["category"] = productData.data[index].cat_name
      ? productData.data[index].cat_name
      : "-";

    rowItem["type_receive"] = (
      <div
        style={{
          whiteSpace: "pre-line",
          lineHeight: "2.5rem",
          textAlign: "start",
        }}
      >
        {productData.data[index].type_receive}
      </div>
    );

    rowItem["count_month"] = (
      <div
        style={{
          whiteSpace: "pre-line",
          lineHeight: "2.5rem",
          textAlign: "end",
        }}
      >
        {productData.data[index].count_month}
      </div>
    );

    rowItem["expdate"] = (
      <div style={{ whiteSpace: "pre-line", lineHeight: "2.5rem" }}>
        {productData.data[index].exp_date_list &&
          productData.data[index].exp_date_list.length > 0 ? (
          productData.data[index].exp_date_display?.join("\n")
        ) : (
          <div style={{ lineHeight: "2.5rem", textAlign: "center" }}>- </div>
        )}
      </div>
    );

    rowItem["remaining_days"] = (
      <div
        style={{
          whiteSpace: "pre-line",
          lineHeight: "2.5rem",
          textAlign: "end",
        }}
      >
        {productData.data[index].exp_date_list &&
          productData.data[index].exp_date_list.length > 0 ? (
          productData.data[index].remaining_days?.join("\n")
        ) : (
          <div style={{ lineHeight: "2.5rem", textAlign: "center" }}>- </div>
        )}
        {/* {productData.data[index].remaining_days} */}
      </div>
    );

    rowItem["fg"] = (
      <div
        style={{
          whiteSpace: "pre-line",
          lineHeight: "2.5rem",
          textAlign: "end",
        }}
      >
        {productData.data[index].exp_date_list &&
          productData.data[index].exp_date_list.length > 0 ? (
          productData.data[index].fg_amount_cal?.join("\n")
        ) : (
          <div style={{ lineHeight: "2.5rem", textAlign: "center" }}>- </div>
        )}
      </div>
    );

    rowItem["management"] =
      productData.data[index].exp_date_list &&
        productData.data[index].exp_date_list.length === 0 ? (
        <div style={{ lineHeight: "2.5rem", textAlign: "center" }}>- </div>
      ) : (
        productData.data[index].exp_date_list?.map((el, index2) => {
          return productData.data[index].exp_date_list[index2].check ===
            false ? (
            <div>
              <button
                className="btn btn-block btn-primary py-1 mb-2"
                onClick={() => {
                  let tempData = productData.data;

                  tempData[index].exp_date_list[index2].check = true;
                  if (tempData[index].statusUpdate) {
                    // tempData[index].status = "update";
                    tempData[index].exp_date_list[index2].product.status =
                      "update";
                  } else {
                    // tempData[index].status = "add";
                    tempData[index].exp_date_list[index2].product.status =
                      "add";
                    tempData[index].exp_date_list[index2].product.oldNew =
                      "new";
                  }

                  setProductData({
                    ...productData,
                    data: tempData,
                  });
                }}
              >
                เลือก
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn btn-block btn-danger py-1 mb-2"
                onClick={() => {
                  let tempData = productData.data;

                  tempData[index].exp_date_list[index2].check = false;
                  tempData[index].exp_date_list[index2].product.status = "del";

                  setProductData({
                    ...productData,
                    data: tempData,
                  });
                }}
              >
                ยกเลิก
              </button>
            </div>
          );
        })
      );

    if (productData.data[index].is_use) {
      run_number += 1;
      rowsData1.push(rowItem);
    }
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Category",
        field: "category",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "ประเภทการรับสินค้า",
        field: "type_receive",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "จำนวน (เดือน)",
        field: "count_month",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "EXP.Date",
        field: "expdate",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Remaining days",
        field: "remaining_days",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "FG Amount",
        field: "fg",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };
  // console.log(selectProductData.data)
  const rowsData2 = [];
  for (let index = 0; index < selectProductData.data.length; index += 1) {
    // console.log(SOProductData.data);
    // console.log(selectProductData.data);
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = selectProductData.data[index].product.product_code
      ? selectProductData.data[index].product.product_code
      : "-";

    rowItem["bar_code"] = (
      <div style={{ display: "flex", justifyContent: "center", minWidth: "max-content" }}>
        {selectProductData.data[index].product.isBarSelect === false ? (
          <>
            <select
              type="text"
              className="form-control"
              disabled={true}
              value={selectProductData.data[index].product.bar_code}
            >
              <option value="-" disabled selected>
                -- เลือก --
              </option>

              <option
                value={selectProductData.data[index].product.barcode_id[0]}
              >
                {selectProductData.data[index].product.barcode[0]}
              </option>
            </select>
          </>
        ) : (
          <>
            <select
              type="text"
              className="form-control"
              disabled={disable}
              value={selectProductData.data[index].product.bar_code}
              onChange={async (e) => {
                const temp = selectProductData.data;

                temp[index].product.bar_code = e.target.value;
                /* temp[index].product.status = "update";
                temp[index].product.statusUpdate = true; */

                setSelectProductData({
                  ...selectProductData,
                  data: temp,
                });

                selectProductData.data = temp;

                if (temp[index].product.oldNew === "old") {
                  temp[index].product.status = "update";
                  temp[index].product.statusUpdate = true;
                  await saveProductData();
                }
              }}
            >
              <option value="" disabled>
                -- เลือก --
              </option>

              {selectProductData.data[index].product.barcode_id?.map(
                (item, indexIn) => {
                  return (
                    <option value={item}>
                      {selectProductData.data[index].product.barcode[indexIn]}
                    </option>
                  );
                }
              )}
            </select>
          </>
        )}
      </div>
    );

    rowItem["customer_code"] = (
      <NumberFormat
        type="text"
        className="form-control"
        // required
        decimalScale={0}
        allowNegative={false}
        allowLeadingZeros={true}
        disabled={disable}
        value={selectProductData.data[index].product.customer_code}
        onChange={async (item) => {
          const temp = selectProductData.data;

          temp[index].product.customer_code = item.target.value;
          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].product.oldNew === "old") {
            temp[index].product.status = "update";
            temp[index].product.statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    rowItem["customer_bar_code"] = (
      <NumberFormat
        type="text"
        className="form-control"
        // required
        decimalScale={0}
        allowNegative={false}
        allowLeadingZeros={true}
        disabled={disable}
        value={selectProductData.data[index].product.customer_bar_code}
        onChange={async (item) => {
          const temp = selectProductData.data;

          temp[index].product.customer_bar_code = item.target.value;
          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].product.oldNew === "old") {
            temp[index].product.status = "update";
            temp[index].product.statusUpdate = true;
            await saveProductData();
          }
        }}
      />
    );

    // rowItem["product_name"] = isChecked
    //   ? selectProductData.data[index].product.product_name
    //   : selectProductData.data[index].product.product_name_eng;
    rowItem["product_name"] = (
      <>
        {isChecked !== false ? (
          <input
            type="text"
            className="form-control"
            style={{ minWidth: "150px" }}
            // required
            // decimalScale={0}
            // allowNegative={false}
            // allowLeadingZeros={true}
            disabled={disable}
            value={selectProductData.data[index].product.product_name_eng}
            onChange={async (e) => {
              if (/* !validThai.test(e.target.value.toString().trim()) */true) {
                const temp = selectProductData.data;

                temp[index].product.product_name_eng = e.target.value;

                setSelectProductData({
                  ...selectProductData,
                  data: temp,
                });

                selectProductData.data = temp;

                if (temp[index].oldNew === "old") {
                  temp[index].status = "update";
                  temp[index].statusUpdate = true;
                  await saveProductData();
                }
              }
            }}
          />
        ) : (
          <input
            type="text"
            className="form-control"
            style={{ minWidth: "150px" }}
            // required
            // decimalScale={0}
            // allowNegative={false}
            // allowLeadingZeros={true}
            disabled={disable}
            value={selectProductData.data[index].product.product_name}
            onChange={async (e) => {
              const temp = selectProductData.data;

              temp[index].product.product_name = e.target.value;

              setSelectProductData({
                ...selectProductData,
                data: temp,
              });

              selectProductData.data = temp;

              if (temp[index].oldNew === "old") {
                temp[index].status = "update";
                temp[index].statusUpdate = true;
                await saveProductData();
              }
            }}
          />
        )}
      </>
    );

    rowItem["retail_price"] = (
      <NumberFormat
        type="text"
        className="form-control"
        // required
        decimalScale={0}
        thousandSeparator={true}
        allowNegative={false}
        allowLeadingZeros={true}
        disabled={disable}
        value={selectProductData.data[index].product.product_price}
        onChange={async (item) => {
          const temp = selectProductData.data;

          temp[index].product.product_price = item.target.value;
          setSelectProductData({
            ...selectProductData,
            data: temp,
          });

          selectProductData.data = temp;

          if (temp[index].product.oldNew === "old") {
            temp[index].product.status = "update";
            temp[index].product.statusUpdate = true;
            await saveProductData();
          }
          await calSummaryProductTable();
        }}
      />
    );

    rowItem["product_quantity"] = (
      <div style={{ display: "flex", gap: ".5rem", minWidth: "max-content" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          decimalScale={0}
          allowNegative={false}
          style={{ minWidth: '100px' }}
          allowLeadingZeros={true}
          disabled={disable}
          value={selectProductData.data[index].product.product_quantity}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].product.product_quantity = item.target.value;
            // temp[index].status = "update";
            // temp[index].statusUpdate = true;

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;
            if (temp[index].product.oldNew === "old") {
              temp[index].product.status = "update";
              temp[index].product.statusUpdate = true;
              await saveProductData();
            }
            calSummaryProductTable();
          }}
        />
        <select
          type="text"
          className="form-control"
          style={{ minWidth: "max-content" }}
          disabled={disable}
          value={selectProductData.data[index].product.unit}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].product.unit = e.target.value;

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });
            selectProductData.data = temp;

            if (temp[index].product.oldNew === "old") {
              temp[index].product.status = "update";
              temp[index].product.statusUpdate = true;
              await saveProductData();
            }
            calSummaryProductTable();
          }}
        >
          <option value="piece">{isChecked ? "ชิ้น" : "Piece"}</option>
          <option value="pack" hidden={selectProductData.data[index].product.detailsAdvance?.pc_pack ? false : true} >{isChecked ? "แพ็ค" : "Pack"}</option>
          <option value="ctn" hidden={selectProductData.data[index].product.detailsAdvance?.pc_ctn ? false : true}>{isChecked ? "ลัง" : "Ctn"}</option>
        </select>

        {/* {console.log(selectProductData.data[index].unit)} */}
      </div>
    );

    rowItem["discount"] = (
      <div>
        {selectProductData.data[index].product.discount?.map((row, index2) => (
          <div key={index2} style={{ display: "flex", gap: ".5rem" }}>
            <NumberFormat
              type="text"
              className="form-control mb-2"
              decimalScale={0}
              allowNegative={false}
              allowLeadingZeros={true}
              thousandSeparator={true}
              style={{ minWidth: '100px' }}
              disabled={disable}
              value={row?.discount || ""}
              onChange={async (e) => {
                /* let newValue = parseInt(e.target.value);
                newValue = Math.max(0, Math.min(100, newValue)) || 0;
                if (
                  newValue === "" ||
                  (Number(newValue) >= 0 && Number(newValue) <= 100)
                ) { */
                handleChangeDiscount(index, index2, "discount", e.target.value);
                calSummaryProductTable();
                // }
              }}
            />
            <select
              type="text"
              className="form-control"
              style={{ minWidth: "max-content" }}
              disabled={disable}
              value={row?.unit}
              onChange={(e) => {
                handleChangeUnit(index, index2, "discount", e.target.value);
                calSummaryProductTable();
              }}
            >
              {/* <option value="unit">{isChecked ? "หน่วย" : "Unit"}</option> */}
              <option value="percent">
                {isChecked ? "เปอร์เซ็นต์" : "Percent"}
              </option>
              <option value="baht">{isChecked ? "บาท" : "Baht"}</option>
            </select>
            {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80" // Adjust the width as needed
                height="38" // Adjust the height as needed
                viewBox="0 0 24 24"
                hidden={disable === true ? true : false}
                fill="red" // Set fill color to red for enabled, and gray for disabled
                style={{
                  cursor: "pointer",
                  pointerEvents: "auto", // Enable pointer events for enabled, and disable for disabled
                }}
                onClick={() => handleDeleteRow(index, index2)} // Conditionally add the click handler
              >
                <path d="M19.07 4.929c-.389-.39-1.024-.39-1.414 0L12 10.586 6.344 4.93c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414L10.586 12l-5.657 5.657c-.39.39-.39 1.024 0 1.414.195.195.45.293.707.293s.512-.098.707-.293L12 13.414l5.657 5.657c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.024 0-1.414L13.414 12l5.657-5.657c.39-.39.39-1.024 0-1.414z" />
              </svg>
            }
          </div>
        ))}
        <button
          type="button"
          className="btn btn-block btn-primary"
          disabled={disable}
          onClick={() => {
            handleAddRow(index);
          }}
        >
          Add
        </button>
      </div>
    );
    // console.log(selectProductData);
    rowItem["expdate"] = selectProductData.data[index].exp
      ? /* TableformatDateToDDMMYYYY( */
      selectProductData.data[index].log_product2 ||
      selectProductData.data[index].exp
      : // )
      "-";

    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          key={selectProductData.data[index].product.product_id}
          id={selectProductData.data[index].product.product_id}
          className=" btn btn-xs "
          onClick={async () => {
            const tempProductData = productData.data;
            const tempSelectProductData = selectProductData.data;

            const findIndex = tempProductData.findIndex(
              (item) =>
                item.product_id ===
                tempSelectProductData[index].product.product_id
            );

            if (findIndex >= 0) {
              const findIndex2 = tempProductData[
                findIndex
              ].exp_date_list.findIndex(
                (item) => item.exp === selectProductData.data[index].exp
              );

              if (findIndex2 >= 0) {
                tempProductData[findIndex].exp_date_list[
                  findIndex2
                ].check = false;
                tempProductData[findIndex].exp_date_list[
                  findIndex2
                ].product.status = "del";
              }
            }

            await saveProductData();
            await calSummaryProductTable();
          }}
        >
          <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
        </a>
      </div>
    );

    rowsData2.push(rowItem);
  }

  const selectProducTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Customer Code",
        field: "customer_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Customer Barcode",
        field: "customer_bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Retail Price",
        field: "retail_price",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Quantity",
        field: "product_quantity",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Discount",
        field: "discount",
        // sort: "asc",
        // width: 50,
      },
      // {
      //   label: "Amount",
      //   field: "amount",
      //   // sort: "asc",
      //   // width: 50,
      // },
      {
        label: "EXP.Date",
        field: "expdate",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (SalesOrderParent.so_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "customer_bar_code";
    });

    selectProducTable.columns.splice(index1, 1);

    const index2 = selectProducTable.columns.findIndex((e) => {
      return e.field === "customer_code";
    });

    selectProducTable.columns.splice(index2, 1);

    /* const index2 = selectProducTable.columns.findIndex((e) => {
      return e.field === "gp";
    });

    selectProducTable.columns.splice(index2, 1); */
  }

  if (disable) {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "management";
    });

    selectProducTable.columns.splice(index1, 1);
  }

  async function saveProductData() {
    let findCheckTrue = [];
    let addNew = [];
    let updateOld = [];
    let delOld = [];

    for (let item of productData.data) {
      const inFind = item.exp_date_list.filter((el) => el.check === true);
      if (inFind.length > 0) {
        findCheckTrue.push(...inFind);
      }

      const inFindNew = item.exp_date_list.filter(
        (el) => el.product.status === "add" && el.product.oldNew === "new"
      );
      if (inFindNew.length > 0) {
        addNew.push(...inFindNew);
      }

      const inFindUpdate = item.exp_date_list.filter(
        (el) => el.product.status === "update" && el.product.oldNew === "old"
      );
      if (inFindUpdate.length > 0) {
        // console.log('inFindUpdate', inFindUpdate);
        updateOld.push(...inFindUpdate);
      }

      const inFindDel = item.exp_date_list.filter(
        (el) => el.product.status === "del" && el.product.oldNew === "old"
      );
      if (inFindDel.length > 0) {
        delOld.push(...inFindDel);
      }
    }

    // console.log('findCheckTrue', findCheckTrue);

    setSelectProductData({
      ...selectProductData,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    selectProductData.data = findCheckTrue;
    selectProductData.newData = addNew;
    selectProductData.updateData = updateOld;
    selectProductData.delData = delOld;

    // console.log(
    //   selectProductData.data,
    //   selectProductData.newData,
    //   selectProductData.updateData,
    //   selectProductData.delData
    // );
  }

  async function DiscountUnit() {
    const matchingDiscounts = selectProductData.data;
    // console.log(selectProductData.data);
    for (let j = 0; j < selectProductData.data.length; j++) {
      let temp = [];
      for (let i = 0; i < Discount.response2.data.length; i++) {
        if (
          Discount.response2.data[i].sop_product_id ===
          selectProductData.data[j].product_id
        ) {
          // Collect matching discount objects in the array
          temp.push({
            // product_code: selectProductData.data[j].product_code,
            // barcode: selectProductData.data[j].barcode,
            // customer_bar_code: selectProductData.data[j].customer_bar_code,
            // product_name_eng: selectProductData.data[j].product_name_eng,
            // product_quantity: selectProductData.data[j].product_quantity,
            discount: Discount.response2.data[i].sod_discount,
            unit: Discount.response2.data[i].sod_unit_discount,
          });

          // console.log(Discount.response2.data[i].sod_discount);
          // console.log(Discount.response2.data[i].sod_unit_discount);
        }
      }
      matchingDiscounts[j].discount = temp;
    }

    setresDiscount([]);
    settest("test");
    setSelectProductData({ ...selectProductData, data: matchingDiscounts });
    settest("test");
  }

  async function clearSearch(is_clear, only_clear_input) {
    setSearchData({
      ...searchData,
      code: "",
      name: "",
      barcode: "",
      category: "",
      customer_barcode: "",
      customer_product_name: ""
    });

    searchData.code = "";
    searchData.name = "";
    searchData.barcode = "";
    searchData.category = "";
    searchData.customer_barcode = "";
    searchData.customer_product_name = "";

    if (!only_clear_input) {
      if (is_clear) {
        await resetProduct(true);
      } else {
        await GetProduct(null, true);
      }
    }
  }

  const resetProduct = async (is_use) => {
    const arrTemp = productData.data
    arrTemp.forEach(el => {
      el.is_use = is_use
    });
    setProductData({
      ...productData,
      data: arrTemp
    })
    productData.data = arrTemp;
  }

  async function checkErrorSelectProductData() {
    const error_list = [];
    const checkSameList = [];
    if (selectProductData.data.length > 0) {
      let number = 1;

      for (let item of selectProductData.data) {
        if (!item.product.bar_code || item.product.bar_code?.trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณาเลือก Barcode`,
          };

          error_list.push(temp_err);
        }
        /* if (
          !item.product.customer_bar_code ||
          item.product.customer_bar_code?.trim() === "" ||
          item.product.customer_bar_code === null ||
          item.product.customer_bar_code === undefined
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Customer Code`,
          };

          error_list.push(temp_err);
        } */
        if (
          !item.product.product_price
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Retail Price`,
          };

          error_list.push(temp_err);
        } else {
          item.product.product_price = item.product.product_price
            ? typeof item.product.product_price === "number"
              ? item.product.product_price
              : parseFloat(item.product.product_price?.toString().replace(/,/g, "") || 0)
            : null
        }
        if (
          !item.product
            .product_quantity /* || item.product_quantity?.trim() === "" */
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Product Quantity`,
          };

          error_list.push(temp_err);
        } else {
          item.product.product_quantity = item.product.product_quantity
            ? typeof item.product.product_quantity === "number"
              ? item.product.product_quantity
              : parseFloat(item.product.product_quantity?.toString().replace(/,/g, "") || 0)
            : null
        }

        const index_check = checkSameList.findIndex(el => el.product_code === item.product.product_code);
        if (index_check === -1) {
          const temp_list = selectProductData.data.filter(el => el.product.product_id === item.product.product_id);
          if (temp_list.length > 1) {
            checkSameList.push({
              product_code: item.product.product_code,
              arr_list: temp_list,
              arr_discount_list: temp_list.map(el => el.product.discount),
              is_checked: false
            });

          }
        } else if (checkSameList[index_check].is_checked === false) {
          // console.log('item.product', item.product);
          const dup_every = checkSameList[index_check].arr_list.every(
            el => (
              el.product.bar_code == item.product.bar_code
              && el.product.customer_bar_code == item.product.customer_bar_code
              && el.product.customer_code == item.product.customer_code
              && el.product[isChecked ? "product_name_eng" : "product_name"] == item.product[isChecked ? "product_name_eng" : "product_name"]
              && el.product.product_price == item.product.product_price
            )
          );

          let check_dup_discount = false;
          if (dup_every) {
            for (let item2 of checkSameList[index_check].arr_discount_list) {
              const checkFunc = compareDiscounts(item2.filter(el => el.discount), item.product.discount.filter(el => el.discount));
              if (checkFunc) {
                check_dup_discount = true;
                break;
              }
            }

          }

          if (!dup_every || check_dup_discount) {
            let temp_err = {
              message: `Product Code: ${item.product.product_code} มีข้อมูลไม่ตรงกัน`,
            };

            error_list.push(temp_err);
          }
          checkSameList[index_check].is_checked = true;
        }

        number += 1;
      }
    } else {
      let temp_err = {
        message: `กรุณาเลือก Product อย่างน้อย 1 รายการก่อนทำรายการถัดไป`,
      };

      error_list.push(temp_err);
    }

    setErrorListTab2({
      ...errorListTab2,
      data: error_list,
    });
    errorListTab2.data = error_list;
  }

  function compareDiscounts(arr1, arr2) {
    const discounts1 = arr1// .map(item => item.discount);
    const discounts2 = arr2// .map(item => item.discount);

    if (discounts1.length != discounts2.length) {
      return true
    }
    const sortedDiscounts1 = discounts1.sort((a, b) => (a?.unit.localeCompare(b?.unit) || a?.discount - b?.discount));
    const sortedDiscounts2 = discounts2.sort((a, b) => (a?.unit.localeCompare(b?.unit) || a?.discount - b?.discount));

    /* console.log('sortedDiscounts1', sortedDiscounts1 );
    console.log('sortedDiscounts2', sortedDiscounts2 ); */
    return (JSON.stringify(sortedDiscounts1) !== JSON.stringify(sortedDiscounts2));
  }

  async function GetQuotationRelationProduct(ref_id) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      quo_id: ref_id ? ref_id : id,
      // isHistory: isHistory
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotationProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        // console.log("response", response.data.data);
        /* [selectProductData, setSelectProductData] = useState({
          data: [],
          newData: [],
          updateData: [],
          delData: [],
        }); */

        /* for (let item of response.data.data) {
          item.oldNew = "old";
          item.check = true;
          item.product_id = item.qp_product_id
          item.bar_code = item.qp_pb_id
          item.customer_bar_code = item.qp_cus_barcode
          item.gp = item.qp_gp_percent
          item.retail_price = item.qp_retail_price
          item.product_quantity = item.qp_quantity
          item.pq_unit = item.qp_quantity_unit
        }

        setSelectProductData({
          ...selectProductData,
          data: response.data.data
        })
        selectProductData.data = response.data.data
        console.log('selectProductData.data', selectProductData.data); */
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data.data,
        });

        inputDataTab2.data = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(async () => {
    // if (mode === "edit" || mode === "view") {
    //   await GetSupplierRelationProduct();
    // }
    await GetShelfLifeType();
    await GetCategoryLv2();
    // await GetProduct();
    // console.log(id)
    // await getSonoRefsub(sendid);
    if (mode === "view") {
      setdisable(true);
      setpageMode("View");
      await getallLogProduct();
      await getProductData();
      // await GetProduct();
    } else if (mode === "edit") {
      setdisable(false);
      setpageMode("Edit");
      await getallLogProduct();
      await getProductData();
      // await GetProduct();
    } else {
      setdisable(false);
      setpageMode("Add");
    }
    // await GetProduct();
    // await calSummaryProductTable();
    // console.log(selectProductData.data);
    // SalesOrder.so_incl_vat = sendinclvat
    // console.log(SalesOrder)
    // console.log(SalesOrder.so_incl_vat)
    // console.log(SalesOrder.so_total_befor_vat)
  }, []);

  useEffect(async () => {
    if (sendid) {
      await getSonoRefsub(sendid);
    }
  }, [sendid]);

  useEffect(async () => {
    setSalesOrder({ ...SalesOrder, so_lang: isChecked ? "TH" : "ENG" });
    SalesOrder.so_lang = isChecked ? "TH" : "ENG";
  }, [isChecked]);

  /* useEffect(async () => {
    if (sendinclvat != undefined) {
      console.log('check');
      setSalesOrder({ ...SalesOrder, so_incl_vat: sendinclvat });
      SalesOrder.so_incl_vat = sendinclvat;
    }
    // SalesOrder.so_incl_vat = sendinclvat;
  }, [sendinclvat]); */

  useEffect(async () => {
    if (cusid != undefined) {
      setSalesOrder({ ...SalesOrder, so_cus_id: cusid });
      SalesOrder.so_cus_id = cusid;
    }
    settest("test");
    // console.log(cusid);
  }, [cusid]);

  useEffect(async () => {
    setInputDataTab2({
      ...inputDataTab2,
      data: selectProductData.data,
      newData: selectProductData.newData,
      updateData: selectProductData.updateData,
      delData: selectProductData.delData,
      SalesOrder: SalesOrder,
    });

    inputDataTab2.data = selectProductData.data;
    inputDataTab2.newData = selectProductData.newData;
    inputDataTab2.updateData = selectProductData.updateData;
    inputDataTab2.delData = selectProductData.delData;
    inputDataTab2.SalesOrder = SalesOrder;

    setFunctionSaveTab2({
      ...functionSaveTab2,
      data: checkErrorSelectProductData,
      getRef_SoProduct: getProductData,
      getProduct: GetProduct
    });

    functionSaveTab2.data = checkErrorSelectProductData;
    functionSaveTab2.getRef_SoProduct = getProductData;
    functionSaveTab2.getProduct = GetProduct;
  }, [selectProductData.data, SalesOrder, selectProductData]);

  const [ref_id_change, setRef_id_change] = useState({
    data: false,
  });
  /* 
  useEffect(async () => {
    if (id) {
      await GetQuotationRelationProduct(id);
      setRef_id_change({
        ...ref_id_change,
        data: true,
      });
      ref_id_change.data = true;
      await GetProduct();
    }
  }, [id]); */

  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-md-3 col-xl-2">
          <button
            hidden={true}
            data-toggle="modal"
            data-target="#modalProduct"
            disabled={disable}
            ref={modalProductRef}
          />
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={disable}
            onClick={async () => {
              // console.log(productData.data);
              for (let a = 0; a < selectProductData.data?.length; a++) {
                // let index = 0;
                for (
                  let i = 0;
                  i < selectProductData.data[a]?.product?.log_product?.length;
                  i++
                ) {
                  // console.log(selectProductData.data[i].product_id);
                  // console.log(i)
                  // console.log(
                  //   selectProductData.data[index].expdate,
                  //   "====",
                  //   selectProductData.data[index].log_product[i].date
                  // );
                  if (
                    selectProductData.data[a].exp ===
                    selectProductData.data[a].product.log_product[i].date
                  ) {
                    selectProductData.data[a].product.log_product[
                      i
                    ].check = true;
                    // index++;
                  }
                }
              }
              await openModalProduct();
              await clearSearch(false);
            }}
          >
            Select Product
          </button>
        </div>
        <div className="col-6 col-md-3 col-xl-2">
          <h4 style={{ color: "#007bff", whiteSpace: "nowrap" }}>
            <b>
              Total :{" "}
              {/* {SalesOrder.so_total_price?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0} */}
              {SalesOrder.total?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) || 0}{" "}
              Baht
            </b>
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-md-4 col-xl-3">
          <div class="custom-control custom-switch custom-switch-md">
            <input
              type="checkbox"
              class="custom-control-input"
              id="is_fifo"
              disabled={false}
              checked={SalesOrder.so_lang === "TH" ? true : false}
              onChange={(event) => {
                setSalesOrder({
                  ...SalesOrder,
                  so_lang: event.target.checked,
                });
                SalesOrder.so_lang = event.target.checked;
                settest("test");
                setIsChecked(event.target.checked);
              }}
            />
            <label
              class="custom-control-label "
              style={{ whiteSpace: "nowrap" }}
              for="is_fifo"
            >
              TH
            </label>
          </div>
        </div>
        <div
          className="col-6 col-md-4 col-xl-2 pl-3"
          style={{
            display: "flex",
            height: "auto",
            columnGap: "1rem",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
        >
          <label
            class="container-checkbox-product"
            style={{ whiteSpace: "nowrap" }}
          >
            Include VAT (7%)
            <input
              disabled={disable}
              className={``}
              type="checkbox"
              id={"sbd_path_document"}
              name={"el.sbd_path_document"}
              checked={SalesOrder.so_incl_vat}
              onChange={async (event) => {
                setSalesOrder({
                  ...SalesOrder,
                  so_incl_vat: event.target.checked,
                });
                SalesOrder.so_incl_vat = event.target.checked;
                settest("test");
                // console.log(SalesOrder);
                await calSummaryProductTable();
              }}
            />
            <span className="checkmark-checkbox-product"></span>
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12" style={{ overflow: "overlay" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <MDBDataTable
              sortable={false}
              striped
              bordered
              hover
              data={selectProducTable}
            />
          </div>
        </div>
      </div>

      <div className="row res-quotation-flex">
        <div className="col-6 col-md-6 col-xl-6 res-quotation-flex-box1">
          <div className="form-group">
            <textarea
              className="form-control"
              rows={2}
              disabled={disable}
              style={{
                height: "150px",
                fontSize: "25px",
              }}
              value={SalesOrder.so_remark}
              onChange={(e) =>
                setSalesOrder({
                  ...SalesOrder,
                  so_remark: e.target.value,
                })
              }
              defaultValue=""
              required
            />
            <label>Remark</label>
          </div>
        </div>
        <div className="col-6 col-md-6 col-xl-6 pl-6 d-flex align-items-end flex-column res-quotation-flex-box1">
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ส่วนลดท้ายบิล</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  allowLeadingZeros={true}
                  thousandSeparator
                  allowNegative={false}
                  decimalScale={2}
                  required
                  disabled={disable}
                  value={
                    SalesOrder.so_final_discount/* ?.toString().toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }) || 0 */
                  }
                  // min={0}
                  // max={100}
                  onChange={async (e) => {
                    let newValue = parseFloat(e.target.value);
                    const totalBeforeVat = parseFloat(SalesOrder.so_total_of_product?.toString().replace(/,/g, "") || 0);
                    // newValue = Math.max(0, Math.min(100, newValue)) || 0;
                    if (newValue > 100) {
                      newValue = 100;
                    }
                    setSalesOrder({
                      ...SalesOrder,
                      so_final_discount: newValue,
                      so_final_discount_bath: ((newValue / 100) * totalBeforeVat)
                    });
                    SalesOrder.so_final_discount = newValue;
                    SalesOrder.so_final_discount_bath = ((newValue / 100) * totalBeforeVat);
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div className="ml-3">
              <label>% {"   "}</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              {/* <label>ส่วนลดท้ายบิล *</label> */}
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  decimalScale={2}
                  // maxLength={5}
                  allowLeadingZeros={true}
                  thousandSeparator
                  allowNegative={false}
                  disabled={disable}
                  className="form-control"
                  required
                  value={SalesOrder.so_final_discount_bath}
                  onChange={async (e) => {
                    let value = parseFloat(e.target.value?.toString().replace(/,/g, "") || 0);
                    const totalBeforeVat = parseFloat(SalesOrder.so_total_of_product?.toString().replace(/,/g, "") || 0);
                    if (value > totalBeforeVat) value = totalBeforeVat;
                    setSalesOrder({
                      ...SalesOrder,
                      so_final_discount_bath: value,
                      so_final_discount: ((value / totalBeforeVat) * 100)
                    });
                    SalesOrder.so_final_discount_bath = value;
                    SalesOrder.so_final_discount = ((value / totalBeforeVat) * 100);
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div className="">
              <label>บาท {"   "}</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ค่าจัดส่ง</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={0}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  thousandSeparator={true}
                  disabled={disable}
                  value={
                    // formatCurrency(SalesOrder.so_shipping_cost.toString())
                    // SalesOrder.so_shipping_cost.toString()
                    SalesOrder.so_shipping_cost /* ?.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }) || 0 */
                  }
                  min={0}
                  onChange={async (e) => {
                    // console.log(SalesOrder.so_shipping_cost);
                    // let newValue = parseFloat(e.target.value);
                    // newValue = Math.max(0, isNaN(newValue) ? 0 : newValue);
                    setSalesOrder({
                      ...SalesOrder,
                      so_shipping_cost: e.target.value,
                    });
                    SalesOrder.so_shipping_cost = e.target.value;
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ราคาก่อน VAT</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  thousandSeparator={true}
                  disabled={true}
                  value={
                    SalesOrder.so_total_befor_vat?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) || 0
                  }
                  onChange={(e) => {
                    setSalesOrder({
                      ...SalesOrder,
                      so_total_befor_vat: e.target.value,
                    });
                    SalesOrder.so_total_befor_vat = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>VAT</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  required
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  thousandSeparator={true}
                  disabled={true}
                  value={
                    SalesOrder.so_incl_vat
                      ? SalesOrder.so_vat?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : 0?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                  }
                  onChange={(e) => {
                    setSalesOrder({
                      ...SalesOrder,
                      so_vat: e.target.value,
                    });
                    SalesOrder.so_vat = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>ราคารวม</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <input
                  type="text"
                  className="form-control"
                  required
                  disabled={true}
                  value={
                    SalesOrder.so_total_price?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) || 0
                  }
                  onChange={(e) => {
                    setSalesOrder({
                      ...SalesOrder,
                      so_total_price: e.target.value,
                    });
                    SalesOrder.so_total_price = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>บาท</label>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProduct"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProduct"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{ width: "100%" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="modalProduct">
                <b>Add Product</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.code}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          code: e.target.value,
                        });
                        searchData.code = e.target.value;
                      }}
                    />
                    <label>Product Code</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          barcode: e.target.value,
                        });
                        searchData.barcode = e.target.value;
                      }}
                    />
                    <label>Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          name: e.target.value,
                        });
                        searchData.name = e.target.value;
                      }}
                    />
                    <label>Product Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      value={searchData.category}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          category: e.target.value,
                        });
                        searchData.category = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select a category
                      </option>
                      {categoryData.data?.map((option, index) => (
                        <option
                          key={categoryData.data[index].cat_name}
                          value={categoryData.data[index].cat_id}
                        >
                          {categoryData.data[index].cat_name}
                        </option>
                      ))}
                    </select>
                    <label>Category</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_barcode: e.target.value,
                        });
                        searchData.customer_barcode = e.target.value;
                      }}
                    />
                    <label>Customer Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_product_name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_product_name: e.target.value,
                        });
                        searchData.customer_product_name = e.target.value;
                      }}
                    />
                    <label>Customer Product Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await GetProduct(null, true, "search");
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await clearSearch();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <div className="col-md-12" style={{ overflow: "overlay" }}>
                  <div
                    style={{
                      height: "450px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MDBDataTable
                      sortable={false}
                      striped
                      bordered
                      hover
                      data={ProductTable}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={async () => {
                  await calSummaryProductTable();
                }}
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onChange={handleChange}
                onClick={async () => {
                  await saveProductData();
                  await calSummaryProductTable();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sales_Order;
