import React, { useEffect, useState } from "react";
import Table_All_Project from "../../../component/component_ticket/table_all_project";
import {
  Avatar,
  Divider,
  Tooltip,
  Dropdown,
  Space,
  Checkbox,
  Switch,
} from "antd";
import {
  AntDesignOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button } from "reactstrap";
import img_header_ticket from "../../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import Select from "react-select";
import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../../Utils/Common";
import { useHistory } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";

function Permission_Ticket(params) {
  const history = useHistory();
  const [test, settest] = useState({
    test: "",
  });
  const [PermissionAccountList, setPermissionAccountList] = useState([]);
  const onChange_Checkbox = (e) => {
    // console.log(`checked = ${e.target.checked}`);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [SelectPosition, setSelectPosition] = useState([]);
  // const pageSize = 10;
  const [pageSize, setpageSize] = useState(10);

  const [Filter, setFilter] = useState({
    search_text: "",
    search_position: "",
  });
  const [total, Settotal] = useState("");

  // const handleEnterKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     setCurrentPage(1);
  //     filterPermission(1, pageSize, Filter.search_position);
  //   }
  // };

  const onChangePage = (page) => {
    setCurrentPage(page);
    filterPermission(page, pageSize);
  };

  const onShowSizeChange = (current, size) => {
    setpageSize(size);
    filterPermission(currentPage, size);
  };

  // const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    CheckRolePage().then(function (responseData) {
      if (responseData) {
        filterPermission(1, 10);
        SelectPositionFun();
      } else {
        // window.location = `/NonPermission`;
        history.push("/NonPermission", {
          module: "Setting Permission/SLA",
          menu: "Permission",
        });
      }
    });
  }, []);

  function onChange_Switch(checked, id) {
    // console.log(`switch to ${checked}`);
    let temp = {
      is_active: checked,
    };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/identity_user/changeIsActive/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        filterPermission(currentPage, pageSize);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function CheckRolePage() {
    const id = JSON.parse(sessionStorage.getItem("user"));
    return axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/project/getByidChckeRole/${id.fup}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        oem_id: getOem(),
        com_id: getUser().com
      }
    })
      .then(function (response) {
        // console.log("response: ", response.data[0].iu_ps_id);
        if (
          response.data[0]?.iu_ps_id === "ea57bf2f-232b-40d6-972d-b83e2e952519"
        ) {
          return true;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
        return false;
      });
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      fontSize: 16,
      // marginTop: "-18px",
      // marginLeft: "-9px"
    }),
  };

  function ChangeRole(id, role_name) {
    let temp = {
      role_name: role_name,
    };
    axios({
      method: "put",
      url: `${Configs.API_Ticket}/api/identity_user/changeRole/${id}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        // console.log("select_sla",response.data);

        filterPermission(currentPage, pageSize);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("แจ้งเตือน", error.response.data.errorMessage, "error");
      });
  }

  function SelectPositionFun() {
    axios({
      method: "get",
      url: `${Configs.API_Ticket}/api/permission_status/getAll`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        // console.log("select_sla",response.data);
        const dataW = [];
        //  console.log(response.data);
        response.data.forEach((item, index) => {
          const formatData = {
            value: item.ps_id,
            label: item.ps_name,
          };
          dataW.push(formatData);
        });
        setSelectPosition(dataW);
      })
      .catch(function (error) {
        console.log(error);
        /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
      });
  }

  async function filterPermission(current, size) {
    const sessionDataString = sessionStorage.getItem("user");
    const sessionData = JSON.parse(sessionDataString);
    const comValue = sessionData.com;

    Swal.fire({
      title: "ระบบ",
      text: `ระบบกำลังอัพเดตข้อมูล`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    setPermissionAccountList([]);
    // console.log(Filter.search_position);
    let temp = {
      search_text: Filter.search_text.trim() || "",
      current: current || 1,
      pageSize: size || 10,
      search_position: Filter.search_position || "",
      comValue: comValue,
      oem_id: getOem(),
      com_id: getUser().com
    };
    await axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/identity_user/getAll`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(response.data);
        Settotal(response.data.AllDataTotal);
        response.data._data.forEach((item) => {
          setPermissionAccountList((prevList) => [
            ...prevList,
            {
              id: item.iu_id,
              account_name: item.iu_firstname + " " + item.iu_lastname,
              permission_role: item.ps_name,
              iu_is_active: item.iu_is_active,
              iu_profile_path: item.iu_profile_path,
            },
          ]);
        });
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
        /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
      });
  }

  function syncUserPMRP() {
    let temp = {
      Authorization: getToken(),
    };
    axios({
      method: "post",
      url: `${Configs.API_Ticket}/api/identity_user/syncUserPMRP`,
      headers: {
        Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        filterPermission(currentPage, pageSize);
      })
      .catch(function (error) {
        console.log(error);
        /*      Swal.fire("แจ้งเตือน", error.response.data.error[0].errorMessage, "error"); */
      });
  }

  const cardData = [];

  PermissionAccountList.forEach((item) => {
    cardData.push({
      id: item.id,
      content: (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ display: "flex", width: "600px", alignItems: "center" }}
          >
            <div>
              <Avatar
                style={{
                  backgroundColor: "#f56a00",
                  borderWidth: "0px",
                }}
                src={
                  !item?.iu_profile_path
                    ? undefined
                    : Configs.API_URL_IMG + item?.iu_profile_path
                }
              >
                {item.account_name.substring(0, 1).toUpperCase()}
              </Avatar>
            </div>

            <div
              style={{
                marginLeft: "15px",
                width: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Tooltip title={`${item.account_name}`}>
                <span
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                  }}
                >{`${item.account_name}`}</span>
              </Tooltip>
            </div>
          </div>

          <div
            style={{
              marginLeft: "50px",
              display: "flex",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginTop: "20px" }}>
              {item.iu_is_active === true ? (
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#47BA6F",
                  }}
                >
                  Active
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#A1A1A1",
                  }}
                >
                  Inactive
                </span>
              )}
            </div>

            <div style={{ marginLeft: "20px" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span style={{ color: "gray", fontStyle: "italic" }}>
                  Status
                </span>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Switch
                  checked={item.iu_is_active}
                  onChange={(e) => {
                    onChange_Switch(e, item.id);
                  }}
                />
              </div>
            </div>

            <div style={{ width: "170px" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <span style={{ color: "gray", fontStyle: "italic" }}>
                  Permission
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "-2px",
                }}
              >
                <span style={{ fontSize: "16px", fontWeight: "700" }}>{`${
                  item.permission_role || "Not Permission"
                }`}</span>

                <div style={{ padding: "0px 0px 0px 5px" }}>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                ChangeRole(item.id, "Super Admin");
                              }}
                            >
                              Super Admin
                            </a>
                          ),
                          key: "0",
                        },
                        {
                          label: (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                ChangeRole(item.id, "Developer");
                              }}
                            >
                              Developer
                            </a>
                          ),
                          key: "1",
                        },
                        {
                          label: (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                ChangeRole(item.id, "Software Tester");
                              }}
                            >
                              Software Tester
                            </a>
                          ),
                          key: "2",
                        },
                      ],
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <DownOutlined style={{ marginTop: "6px" }} />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    });
  });

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-12 col-sm-6" style={{ display: "flex" }}>
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={28}
              />

              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginLeft: "10px",
                  lineHeight: "30px",
                }}
              >
                Permission
                {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
              </h1>
            </div>

            <div className="col-12 col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">
                  {/* <li className="breadcrumb-item">
                  <a href="#">Home</a> */}
                  Home
                </li>
                <li className="breadcrumb-item active">
                  Setting Permission/SLA
                </li>
                <li className="breadcrumb-item">Permission</li>
              </ol>
            </div>
          </div>

          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "end" }}
          >
            <div className="col-12 col-sm-12 col-md-12 col-lg-10">
              <div className="row" style={{ alignItems: "end" }}>
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-3"
                  style={{ marginTop: "10px" }}
                >
                  <span style={{ fontSize: "18px", margin: "0" }}>Search</span>
                  <input
                    type="search"
                    id="search"
                    className="form-control"
                    placeholder="search"
                    value={Filter.search_text}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        search_text: e.target.value,
                      });
                    }}
                    // onKeyPress={handleEnterKeyPress}
                  />
                </div>

                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-3"
                  style={{ marginTop: "10px" }}
                >
                  <span style={{ fontSize: "18px", margin: "0" }}>
                    Position
                  </span>
                  <Select
                    className="select_ProjectCode"
                    placeholder="All"
                    id="select_ProjectCode"
                    styles={customStyles}
                    options={SelectPosition}
                    value={
                      SelectPosition.find(
                        (option) => option.label === Filter.search_position
                      ) || ""
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFilter({
                          ...Filter,
                          search_position: selectedOption.label,
                        });

                        Filter.search_position = selectedOption.label;

                        // setCurrentPage(1);
                        // filterPermission(1, pageSize, selectedOption.label);
                      } else {
                        // เมื่อเลือกตัวเลือกว่าง (ล้างค่า)
                        setFilter({
                          ...Filter,
                          search_position: "",
                        });

                        Filter.search_position = "";

                        // setCurrentPage(1);
                        // filterPermission(1, pageSize);
                      }
                    }}
                    isClearable={true}
                  />
                </div>

                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-2"
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#007BFF",
                      borderWidth: "0px",
                    }}
                    onClick={() => {
                      setCurrentPage(1);
                      filterPermission(1, pageSize);
                    }}
                  >
                    Search
                  </Button>
                </div>

                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-2"
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#007BFF",
                      borderWidth: "0px",
                    }}
                    onClick={() => {
                      setCurrentPage(1);

                      setFilter({
                        ...Filter,
                        search_text: "",
                        search_position: "",
                      });

                      Filter.search_text = "";
                      Filter.search_position = "";

                      filterPermission(1, pageSize);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-2">
              <div className="row">
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-12"
                  style={{ marginTop: "10px" }}
                >
                  <Button
                    size="large"
                    type="primary"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#007BFF",
                      borderWidth: "0px",
                    }}
                    onClick={() => {
                      syncUserPMRP();
                    }}
                  >
                    <span>
                      <i class="ri-loop-left-line" /> Sync
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Table_All_Project
            currentPage={currentPage}
            pageSize={pageSize}
            cardData={cardData}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
            total={total}
          />
        </div>
      </section>
    </div>
  );
}
export default Permission_Ticket;
