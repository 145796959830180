import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  // Colors,
  Ticks,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";

function Bar_Chart(params) {
  ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels
    // Colors
  );

  // ChartJS.unregister(CrosshairPlugin);

  function numberWithCommas(x) {
    return x
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "-15px",
          paddingTop: params.isHideLegend ? "20px" : "0px",
        }}
      >
        <label
          style={{
            fontSize: "23px",
            lineHeight: "normal",
            margin: "0px",
            display: "block",
            textAlign: "center",
            margin: "0px 0px 15px 0px",
          }}
        >
          {params.Text}
        </label>
        <div
          className="customScroll-1"
          style={{
            height: params.isFullView
              ? "750px"
              : params.height
              ? params.height
              : "300px",
            width: params.width ? params.width : "100%",

            overflowX: "auto",
          }}
        >
          <Bar
            data={params.DataChart}
            height={"300px"}
            options={{
              onHover: (event, chartElement) => {
                if (params.func) {
                  if (chartElement.length == 1) {
                    event.native.target.style.cursor = "pointer";
                  }
                }
              },
              maintainAspectRatio: false,
              indexAxis: "x",

              scales: {
                x: {
                  stacked: params.isNotStacks ? false : true,
                  grid: {
                    display: false,
                    color: "#DAEBF2",
                  },
                },
                y: {
                  stacked: params.isNotStacks ? false : true,
                  beginAtZero: true,
                  grid: {
                    color: "#DAEBF2",
                    drawBorder: false,
                  },
                  border: {
                    display: false, // Ensure no border is drawn
                  },
                  // min: 0,
                  // max: props.data.max,
                  grace: "10%",
                  ticks: {
                    // stepSize: props.data.stepSize,
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: params.isHideLegend ? false : true,
                  position: "top",
                  align: params.legendPosition || "center",

                  labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    usePointStyle: true, // This enables circle icons for legend
                    pointStyle: "circle", // Set the legend shape to circle
                  },
                },
                datalabels: {
                  display: false,
                  formatter: function (value) {
                    let str_show = Number(value || 0)
                      ? Number(value || 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0;
                    return str_show; // numberWithCommas(str_show); //for number format
                  },
                  anchor: "end",
                  align: "top",
                  font: {
                    size: 12,
                    // family: "poppins",
                  },
                  listeners: {
                    enter: function (context) {
                      const chart = context?.chart;
                      if (chart) {
                        const tooltip = chart.tooltip;
                        tooltip.setActiveElements([
                          {
                            datasetIndex: context.datasetIndex,
                            index: context.dataIndex,
                          },
                        ]);
                        chart.update();
                      }
                    },
                    leave: function (context) {
                      const chart = context?.chart;
                      if (chart) {
                        const tooltip = chart.tooltip;
                        tooltip.setActiveElements([]);
                        chart.update();
                      }
                    },
                  },
                },

                responsive: true,
              },
            }}
            plugins={[
              {
                beforeInit(chart) {
                  const originalFit = chart.legend.fit;
                  chart.legend.fit = function fit() {
                    originalFit.bind(chart.legend)();
                    this.height += 20;
                  };
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}
export default Bar_Chart;
