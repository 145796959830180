import React, { Component, useEffect, useState } from "react";
import Footter from "../../../component/Footter";
import Header from "../../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { getOem, getToken, getUser, getAcademy } from "../../../Utils/Common";
import DatePicker from "react-datepicker";
import moment from "moment";
import QRCode2 from "qrcode";
import { Production_order_CTS_report } from "../../../component/Report";
import TableLEK from "../../../component/tableLEKTTT/table";

function Production_order_CTS(params) {
  const [Production, setProduction] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  const [filler_Production, setFiller_Production] = useState({
    oem_id: getOem(),
    Production_no: "",
    customer_name: "",
    Production_start_date: "",
    Production_end_date: "",
    document_true: true,
    document_false: true,
    search:""
  });
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);
  
  async function SetDate_start(date) {
    setFiller_Production({ ...filler_Production, Production_start_date: date });
  }
  async function SetDate_end(date) {
    setFiller_Production({ ...filler_Production, Production_end_date: date });
  }
  const Custom_Input_date = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );
  const [chcek_document_true, setchcek_document_true] = useState(true);
  const [chcek_document_false, setchcek_document_false] = useState(true);
  function chcek_boxis_true() {
    if (chcek_document_true === false) {
      setchcek_document_true(true);
      filler_Production.document_true = true;
      GetProduction();
    } else {
      setchcek_document_true(false);
      filler_Production.document_true = false;
      GetProduction();
    }
  }
  function chcek_boxis_false() {
    if (chcek_document_false === false) {
      setchcek_document_false(true);
      filler_Production.document_false = true;
      GetProduction();
    } else {
      setchcek_document_false(false);
      filler_Production.document_false = false;
      GetProduction();
    }
  }
  console.log(
    filler_Production.document_false,
    filler_Production.document_true
  );
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetProduction = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }

    let tmp = {
      oem_id: getOem(),
      Production_no: filler_Production.Production_no.trim(),
      customer_name: filler_Production.customer_name.trim(),
      Production_start_date: filler_Production.Production_start_date,
      Production_end_date: filler_Production.Production_end_date,
      document_true: filler_Production.document_true,
      document_false: filler_Production.document_false,
      page: page || 1,
      size: size || 10,
      search: filler_Production.search.trim()
    };
    //console.log("เซิฟ", tmp);
    axios({
      method: "post",
      url: Configs.API_URL + "/api/productionOrderCTS/filterProductionCTS_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp,
    })
      .then(function (response) {
        console.log(response.data);
/*         const calendarSort = response.data.sort(
          (a, b) => a.is_success - b.is_success
        );
        console.log(calendarSort); */
        setProduction(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        Swal.close();
       
      })
      .catch(function (error) {
        Swal.close();
        console.log(error);
      });
  };

  useEffect(() => {
    GetProduction(1, 10, 'search');
    GetCompany();
  }, []);

  const clearFilter = async () => {
    await setFiller_Production({
      ...filler_Production,
      Production_no: "",
      customer_name: "",
      Production_start_date: "",
      Production_end_date: "",
      search:"",
    });

    filler_Production.Production_no = "";
    filler_Production.customer_name = "";
    filler_Production.Production_start_date = "";
    filler_Production.Production_end_date = "";
    filler_Production.search = "";
    GetProduction(1, 10, 'search');
  };

  function addpage(params) {
    /*  Production_order_CTS_report() */
    window.location.href =
      "/Warehouse_Management/ใบสั่งผลิต/Production_order_CTS_add/add/";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url:
            Configs.API_URL +
            "/api/productionOrderCTS/delProductionCTS?id=" +
            id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href = "/Warehouse_Management/ใบสั่งผลิต";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }

  const linkreport = async (key_id) => {
    const temp = {
      produ_id: key_id,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL +
        "/api/productionOrderCTS/getProduction_in_reportByID?id=",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        console.log(response.data);

        var img_qr = "";
        QRCode2.toDataURL(
          response.data[0].no +
          "," +
          response.data[0].product_list[0].no +
          "," +
          response.data[0].amount,
          function (err, url) {
            img_qr = url;
          }
        );

        Production_order_CTS_report(response.data, img_qr);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const rowsData = [];
  for (let index = 0; index < Production.length; index++) {
    var numsum = Production[index].time_per_product;
    console.log(
      numsum,
      moment(Production[index].producer_date)
        .add(numsum, "day")
        .format("DD-MM-YYYY")
    );
    const rowItem = {};
   /*  rowItem["no"] = index + 1; */
    rowItem["Production_no"] = Production[index].no;
    rowItem["product_no"] = Production[index].product_no;
    rowItem["customer"] = Production[index].customer;
    rowItem["date"] = moment(Production[index].producer_date).format(
      "DD-MM-YYYY"
    );
    rowItem["min_date"] = moment(Production[index].finish_date_plan)
      .add(numsum, "day")
      .format("DD-MM-YYYY");
    rowItem["end_date"] =
      Production[index].finish_date_producer !== null
        ? moment(Production[index].finish_date_producer).format("DD-MM-YYYY")
        : "ไม่ระบุวัน";
    rowItem["user_document"] = Production[index].user_document;
    if (Production[index].is_success == true) {
      rowItem["is_success_document"] = (
        <div style={{ textAlign: 'center' }}>
          {
            <button
              type="button"
              className="btn btn-block btn-success"
            /*  disabled = {true} */
            >
              ดำเนินการเสร็จสิ้น
            </button>
          }
        </div>

      );
    } else {
      rowItem["is_success_document"] = (
        <div style={{textAlign:'center'}}>
          {
            <button
          type="button"
          className="btn btn-block btn-danger"
        /* disabled = {true} */
        >
          รอดำเนินการ
        </button>
          }
        </div>
        
      );
    }
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/ใบสั่งผลิต/Production_order_CTS_add/read/" +
              Production[index].id
            }
            key={Production[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/ใบสั่งผลิต/Production_order_CTS_add/edit/" +
              Production[index].id
            }
            key={Production[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={Production[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, Production[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowItem["report"] = (
      <div style={{ textAlign: 'center' }}>
        {
          <button
            type="button"
            onClick={linkreport.bind(this, Production[index].id)}
            class="btn btn-primary "
          >
            <i class="far fa-file-alt"></i> ใบสั่งผลิต
          </button>
        }
      </div>

    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
     /*  {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "เลขใบสั่งผลิต",
        field: "Production_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No.",
        field: "product_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่ผลิต",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "กำหนดเสร็จ",
        field: "min_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่ผลิตเสร็จ",
        field: "end_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "ผู้ออกเอกสาร",
        field: "user_document",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "is_success_document",
        sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  ใบสั่งผลิต {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">ใบสั่งผลิต</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                
                    value={filler_Production.search}
                    onChange={(e) => {
                      setFiller_Production({
                        ...filler_Production,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_Production.Production_no}
                    onChange={(e) => {
                      setFiller_Production({
                        ...filler_Production,
                        Production_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">เลขใบสั่งผลิต</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_Production.customer_name}
                    onChange={(e) => {
                      setFiller_Production({
                        ...filler_Production,
                        customer_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Customer</label>{" "}
                </div>
              </div>
              {/*  <label className="mt-3 mr-2">ค้นหาจากวันที่ผลิต</label> */}
              <div
                className="col-6 col-sm-4 col-md-2 col-xl-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filler_Production.Production_start_date}
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => SetDate_start(date)}
                    selectsStart
                    startDate={filler_Production.Production_start_date}
                    endDate={filler_Production.Production_end_date}
                    customInput={<Custom_Input_date />}
                  />
                  <label htmlFor="">วันที่เริ่มผลิตก่อน</label>{" "}
                </div>
              </div>

              <div
                className="col-6 col-sm-4 col-md-2 col-xl-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filler_Production.Production_end_date}
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => SetDate_end(date)}
                    //selectsEnd
                    // startDate={filler_Production.Production_start_date}
                    // endDate={filler_Production.Production_end_date}
                    // minDate={filler_Production.Production_start_date}
                    customInput={<Custom_Input_date />}
                  ></DatePicker>
                  <label htmlFor="">วันที่เริ่มผลิตหลัง</label>
                </div>
              </div>

              {/* <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_supplier.company_id}
                    onChange={(e) => {
                      setFiller_supplier({
                        ...filler_supplier,
                        company_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Company
                    </option>
                    {company.map((el, index) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Company</label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetProduction(1, 10, 'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-4 col-sm-4 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div class="col-5 col-sm-3   col-lg-2 mt-3 ml-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_true"
                  value= {filler_Production.document_true}
                  checked = {filler_Production.document_true}
                  onChange={(event) => {
                    chcek_boxis_true(event);
                  }}
                />
                <label  htmlFor="is_true"   for="is_true">
                  ดำเนินการเสร็จสิ้น
                </label>
              </div>
              <div class="col-6 col-lg-2 mt-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_false"
                  checked = {filler_Production.document_false}
                  onChange={(event) => {
                    chcek_boxis_false(event);
                  }}
                />
                <label  htmlFor="is_false"  for="is_false">
                  รอดำเนินการ
                </label>
              </div>
            </div>
          </div>

        </section>

        <section className="content">
          <div className="card">
            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <TableLEK


                headTable={data.columns}
                dataTable={rowsData}
                countPage={countPage}
                countData={countData}
                entries={entries}
                isSearch={search}
                callFnc={GetProduction}


              />
            {/*   <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              /> */}
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Production_order_CTS;
