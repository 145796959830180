import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";

function Pad_Connection(params) {
  function addpage(params) {
    window.location.href = "/Human_Resource/ตั้งค่า/Pad_Connection/add";
  }

  const [filler_pad_connection, set_filler_pad_connection] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    pad_name: "",
    username: "",
    location: "",
    ip_address: "",
    password: "",
    gate_id: "",
    remark: "",
  });
  const [type_emp, set_type_emp] = useState([]);

  const [Log_pad_connection, set_Log_pad_connection] = useState([]);

  /* console.log(getOem(),"compa",getUser().com,); */

  useEffect(() => {
    // GetemployeeTyer();
    GetLogPadConnection();
  }, []);

  const clearFilter = async () => {
    await set_filler_pad_connection({
      ...filler_pad_connection,
      oem_id: getOem(),
      company_id: getUser().com,
      pad_name: "",
    });

    filler_pad_connection.oem_id = getOem();
    filler_pad_connection.company_id = getUser().com;
    filler_pad_connection.pad_name = "";
    GetLogPadConnection();
  };
  //   const clearFilter = async () => {

  //     await set_filler_operational_data({
  //       ...filler_operational_data,
  //       emp_name: "",
  //       emp_type_name: "",
  //       emp_type_id: "",
  //       time_start: "",
  //       time_end: "",
  //     });
  //     filler_operational_data.emp_name = "";
  //     filler_operational_data.emp_type_name = "";
  //     filler_operational_data.emp_type_id = "";
  //     filler_operational_data.time_start = "";
  //     filler_operational_data.time_end = "";
  //     GetLogTimeAttendance();
  //   }
  /* 
  const GetemployeeTyer = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
    }
    console.log("get_com_oem",get_com_oem);
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmployeeType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data:get_com_oem,
    })
      .then(function (response) {
        console.log(response.data.data,"dddd");
        set_type_emp(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }; */

  function deldata(id) {
    Swal.fire({
      title: "คุณแน่ใจไหม?",
      text: "คุณจะไม่สามารถย้อนกลับได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่ ลบทิ้ง!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "DELETE",
          url: Configs.API_URL_hrMagenatement + "/config/" + id,
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data.status === "success") {
              Swal.fire("ลบแล้ว!", "ไฟล์ของคุณถูกลบแล้ว", "เสร็จสิ้น").then(
                () => {
                  GetLogPadConnection();
                  // window.location.href =
                  //   "/Warehouse_Management/Human_Resource/Employee";
                }
              );
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "บางอย่างผิดพลาด: " + error, "error");
          });
      }
    });
  }

  function isActive(id, satatus) {
    let list = {
      padconect_id: id,
      satatus_pad: satatus,
    };
    Swal.fire({
          title: "Saving",
          allowEscapeKey: false,
          allowOutsideClick: false,
          timer: 1000,
          onOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          axios({
            method: "post",
            url:
              Configs.API_URL_hrMagenatement + "/api/hrManagement/updatePadSatatus",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: list,
          })
            .then(function (response) {
              GetLogPadConnection();
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href = "/Human_Resource/ตั้งค่า/Pad_Connection";
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    
  }
  /*   async function CheckOnlinepad(kink_form_pad) {
        axios({
          method: 'get',
          url: 'https://c719-49-49-251-231.ngrok.io',
          headers: { }
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });

  } */
  const GetLogPadConnection = async () => {
    /*  console.log("เรียกใช้อีกครัง"); */
    axios({
      method: "get",
      url:
        Configs.API_URL_hrMagenatement +
        "/config?company_id=" +
        filler_pad_connection.company_id +
        "&&oem_id=" +
        filler_pad_connection.oem_id +
        "&&search=" +
        filler_pad_connection.pad_name,
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_pad_connection.pad_name,
    })
      .then(function (response) {
        const tempData = [];
        response.data.data.forEach((element) => {
          const el = element;
          /*  CheckOnlinepad(); */

          tempData.push(el);
        });
        response.data.data.sort((a, b) => a.pad_name.localeCompare(b.pad_name));
        // console.log("ฟีลเตอร์",response.data.data);
        set_Log_pad_connection(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const rowsData = [];
  for (var index = 0; index < Log_pad_connection.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["pad_name"] = Log_pad_connection[index].pad_name;
    rowItem["name"] = Log_pad_connection[index].username;
    rowItem["ip_address"] = Log_pad_connection[index].ip_address;
    rowItem["location"] = Log_pad_connection[index].location;
    rowItem["gate"] = Log_pad_connection[index].gate_id;
    rowItem["remark"] = Log_pad_connection[index].remark;

    if (Log_pad_connection[index].is_active === true) {
      /*   console.log("1",Log_pad_connection[index].id); */
      let id_pad = Log_pad_connection[index].id;
      let status_pad = Log_pad_connection[index].is_active;
      rowItem["active"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-block btn-success"
            onClick={() => {
              isActive(id_pad, status_pad);
            }}
            /* className="btn btn-block btn-danger" */
            /* onClick={isActive(Log_pad_connection[index].id)} */
            disabled={false}
          >
            Active
          </button>
        </div>
      );
    } else {
      let id_pad = Log_pad_connection[index].id;
      let status_pad = Log_pad_connection[index].is_active;
      /*    console.log("2",Log_pad_connection[index].id); */
      rowItem["active"] = (
        <div style={{ textAlign: "center" }}>
          <button
            type="button"
            /*   className="btn btn-block btn-success" */
            onClick={() => {
              isActive(id_pad, status_pad);
            }}
            className="btn btn-block btn-danger"
            /*  onClick={isActive(Log_pad_connection[index].id)} */
            disabled={false}
          >
            Active
          </button>
        </div>
      );
    }

    rowItem["mgt"] = (
      <div className="row">
        {/* <div className="col-4"> */}
        {/*  <a
           href={"/Human_Resource/ตั้งค่า/Pad_Connection/read/"+ Log_pad_connection[index].id}
          key={Log_pad_connection[index].id}
          className=" btn btn-xs "
        >
          <i class="fas fa-eye"></i>{" "}
        </a> */}
        {/* </div> */}
        <div className="col-4">
          <a
            href={
              "/Human_Resource/ตั้งค่า/Pad_Connection/edit/" +
              Log_pad_connection[index].id
            }
            key={Log_pad_connection[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={Log_pad_connection[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, Log_pad_connection[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Pad Name",
        field: "pad_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Username",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "IP Address",
        field: "ip_address",
        sort: "asc",
        width: 50,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 50,
      },
      {
        label: "Gate",
        field: "gate",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "Active",
        field: "active",
        sort: "asc",
        width: 50,
      },
      {
        label: "จัดการ",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Pad Connection{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Pad Connection</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-3">
                <div className="form-group ">
                  <input
                    type="text"
                    id="text_pad_name"
                    className="form-control"
                    required="false"
                    value={filler_pad_connection.pad_name}
                    onChange={(e) => {
                      set_filler_pad_connection({
                        ...filler_pad_connection,
                        pad_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      GetLogPadConnection();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-6 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-6 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_add"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              {/* <div className="card-tools"></div> */}
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                id="table-to-xls"
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Pad_Connection;
