import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";
import jsPDF from "jspdf";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import {
  userdefault_img,
  logo_smz,
  logoTTT,
} from "../../routes/imgRoute/imgUrl";

function Work_day_summary(params) {
  let [getEmployeeWorkTypeData, setGetEmployeeWorkTypeData] = useState([]);

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberWithOutCommas = (x) => {
    return x.toString().replace(/,/g, "");
  };

  const currentDate = new Date();
  let start_day = currentDate;
  let end_day = currentDate;

  if (currentDate.getDate() <= 15) {
    start_day = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    end_day = new Date(currentDate.getFullYear(), currentDate.getMonth(), 15);
  } else {
    start_day = new Date(currentDate.getFullYear(), currentDate.getMonth(), 16);
    end_day = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
  }

  const [filler_work_summary, set_filler_work_summary] = useState({
    oem_id: getOem(),
    company_id: getUser().com,
    // emp_nation_id: "",
    emp_name_th: "",
    date_start: new Date(start_day),
    date_end: new Date(end_day),
  });

  const [type_emp, set_type_emp] = useState([]);

  // console.log(
  //   "momentSt",
  //   moment(new Date()).startOf("month").format("YYYY-MM-DD")
  // );
  // console.log(
  //   "momentEd",
  //   moment(new Date()).endOf("month").format("YYYY-MM-DD")
  // );

  const [work_summary, set_work_summary] = useState([]);
  const [export_log_data, set_export_log_data] = useState([]);
  const [report_kb, set_report_kb] = useState([]);
  const [check_kb_view, set_check_kb] = useState({
    check: true,
  });

  useEffect(async () => {
    // GetemployeeType();
    await GetEmployeeWorkType();
    await GetWorkSummary();
    await getExportLog();
  }, []);

  const clearFilter = async () => {
    await set_filler_work_summary({
      ...filler_work_summary,
      // emp_nation_id: "",
      emp_name_th: "",
      date_start: new Date(start_day),
      date_end: new Date(end_day),
    });
    filler_work_summary.emp_name_th = "";
    filler_work_summary.date_start = new Date(start_day);
    filler_work_summary.date_end = new Date(end_day);
    GetWorkSummary();
  };

  // const GetemployeeType = async () => {
  //   let get_com_oem = {
  //     oem_id: getOem(),
  //     company_id: getUser().com,
  //   };
  //   console.log("get_com_oem", get_com_oem);
  //   axios({
  //     method: "post",
  //     url:
  //       Configs.API_URL_hrMagenatement +
  //       "/api/hrManagement/getMasterEmployeeType",
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: get_com_oem,
  //   })
  //     .then(function (response) {
  //       //console.log(response.data.data, "dddd");
  //       set_type_emp(response.data.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const GetEmployeeWorkType = async () => {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      getAll: true,
    };

    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getEmpWorkType",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(async function (response) {
        const arrList = [];

        for (let item of response.data.data) {
          let temp = {
            id: item.id,
            contract_type_name: item.contract_type_name,
            sort: item.sort,
          };

          arrList.push(temp);
        }

        // arrList.sort((a, b) => {
        //   if (a.sort === null) {
        //     return 1;
        //   }

        //   if (b.sort === null) {
        //     return -1;
        //   }

        //   if (a.sort === b.sort) {
        //     return 0;
        //   }

        //   return a.sort < b.sort ? -1 : 1;
        // });

        // console.log(arrList);

        setGetEmployeeWorkTypeData(arrList);
        getEmployeeWorkTypeData = arrList;

        // console.log('getEmployeeWorkTypeData', getEmployeeWorkTypeData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetWorkSummary = async () => {
    //console.log(filler_logistic);
    axios({
      method: "post",
      url:
        Configs.API_URL_hrMagenatement +
        "/api/hrManagement/filterSummaryWorkingReport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_work_summary,
    })
      .then(function (response) {
        // console.log("getWork", response.data.data);

        if (getUser().com === "5146dd92-19f6-4dab-ad49-735a114fcfdb") {
          let empAll = [];

          for (let item of getEmployeeWorkTypeData) {
            let empEqualType = response.data.data.filter((empItem) => {
              return empItem.emp_contract_type_id === item.id;
            });

            empEqualType.sort((a, b) => {
              if (a.emp_no === null) {
                return 1;
              }

              if (b.emp_no === null) {
                return -1;
              }

              if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
                return 0;
              }

              return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
            });

            empAll.push(...empEqualType);
          }

          set_work_summary(empAll);

          let check2 = empAll.filter((e1) => {
            return e1.account_number !== null && e1.account_name !== null;
          });

          set_report_kb(check2);
        } else {
          response.data.data.sort((a, b) => {
            if (a.employee_type === null) {
              return 1;
            }

            if (b.employee_type === null) {
              return -1;
            }

            if (a.employee_type === b.employee_type) {
              if (a.emp_no === null) {
                return 1;
              }

              if (b.emp_no === null) {
                return -1;
              }

              if (a.emp_no.toLowerCase() === b.emp_no.toLowerCase()) {
                return 0;
              }

              return a.emp_no.toLowerCase() < b.emp_no.toLowerCase() ? -1 : 1;
            }

            return a.employee_type > b.employee_type ? -1 : 1;
          });

          set_work_summary(response.data.data);

          let check2 = response.data.data.filter((e1) => {
            return e1.account_number !== null && e1.account_name !== null;
          });

          set_report_kb(check2);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const rowsData = [];
  for (var index = 0; index < work_summary.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["emp_name_th"] = work_summary[index].emp_name_th;
    rowItem["emp_no"] = work_summary[index].emp_no;
    rowItem["time_normal"] = work_summary[index].time_normal;
    rowItem["time_ot"] = work_summary[index].time_ot;
    rowItem["time_extra"] = work_summary[index].time_extra;
    rowItem["time_other"] = work_summary[index].time_other;
    rowItem["time_sunday_working"] = work_summary[index].time_sunday_working;
    // rowItem["extra_ot_hours"] = work_summary[index].time_extra;        รายได้พิเศษ
    if (work_summary[index].salary_month === null) {
      rowItem["salary_month"] = "0";
    } else {
      rowItem["salary_month"] = numberWithCommas(
        parseFloat(work_summary[index].salary_month).toFixed(2)
      );
    }
    if (work_summary[index].current_salary === null) {
      rowItem["current_salary"] = "0";
    } else {
      rowItem["current_salary"] = numberWithCommas(
        parseFloat(work_summary[index].current_salary).toFixed(2)
      );
    }
    if (work_summary[index].ot_salary === null) {
      rowItem["ot_salary"] = "0";
    } else {
      rowItem["ot_salary"] = numberWithCommas(
        parseFloat(work_summary[index].ot_salary).toFixed(2)
      );
    }
    if (work_summary[index].extra_salary === null) {
      rowItem["extra_salary"] = "0";
    } else {
      rowItem["extra_salary"] = numberWithCommas(
        parseFloat(work_summary[index].extra_salary).toFixed(2)
      );
    }
    if (work_summary[index].other_salary === null) {
      rowItem["other_salary"] = "0";
    } else {
      rowItem["other_salary"] = numberWithCommas(
        parseFloat(work_summary[index].other_salary).toFixed(2)
      );
    }
    if (work_summary[index].sunday_work_salary === null) {
      rowItem["sunday_work_salary"] = "0";
    } else {
      rowItem["sunday_work_salary"] = numberWithCommas(
        parseFloat(work_summary[index].sunday_work_salary).toFixed(2)
      );
    }

    if (work_summary[index].summary_salary === null) {
      rowItem["summary_salary"] = "0";
    } else {
      rowItem["summary_salary"] = numberWithCommas(
        parseFloat(work_summary[index].summary_salary).toFixed(2)
      );
    }
    if (work_summary[index].ppk_value === null) {
      rowItem["ppk_value"] = "0";
    } else {
      rowItem["ppk_value"] = numberWithCommas(
        parseFloat(work_summary[index].ppk_value).toFixed(2)
      );
    }
    if (work_summary[index].summary_salary_final === null) {
      rowItem["summary_salary_final"] = "0";
    } else {
      rowItem["summary_salary_final"] = numberWithCommas(
        parseFloat(work_summary[index].summary_salary_final).toFixed(2)
      );
    }

    // rowItem[""] = work_summary[index].;                       หักค่าปกันสังคม
    // rowItem[""] = Number.parseFloat(work_summary[index].).toFixed(2);    รายได้สุธิ
    // rowItem[""] = work_summary[index].;                          ปกส
    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ - นามสกุล ",
        field: "emp_name_th",
        sort: "asc",
        width: 50,
      },
      {
        label: "รหัสพนักงาน",
        field: "emp_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันทำงาน",
        field: "time_normal",
        sort: "asc",
        width: 50,
      },
      {
        label: "รวม OT/ชม.",
        field: "time_ot",
        sort: "asc",
        width: 50,
      },
      {
        label: "ค่าเที่ยว",
        field: "time_extra",
        sort: "asc",
        width: 50,
      },
      {
        label: "ค่าเข้ากะ",
        field: "time_other",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันอาทิตย์/วัน",
        field: "time_sunday_working",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายได้พิเศษ",
        field: "extra_ot_hours",
        sort: "asc",
        width: 50,
      },
      {
        label: "หักอื่นๆ",
        field: "other_hours",
        sort: "asc",
        width: 50,
      },
      {
        label: "อัตรา/เดือน",
        field: "salary_month",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันทำงาน",
        field: "current_salary",
        sort: "asc",
        width: 50,
      },
      {
        label: "รวม OT/ชม.",
        field: "ot_salary",
        sort: "asc",
        width: 50,
      },
      {
        label: "ค่าเที่ยว",
        field: "extra_salary",
        sort: "asc",
        width: 50,
      },
      {
        label: "ค่าเข้ากะ",
        field: "other_salary",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันอาทิตย์/วัน",
        field: "sunday_work_salary",
        sort: "asc",
        width: 50,
      },
      {
        label: "ยอดรวม",
        field: "summary_salary",
        sort: "asc",
        width: 50,
      },
      {
        label: "หักค่าภาษี",
        field: "",
        sort: "asc",
        width: 50,
      },
      {
        label: "หักค่า ปกส.",
        field: "ppk_value",
        sort: "asc",
        width: 50,
      },
      {
        label: "หักขาดงาน",
        field: "",
        sort: "asc",
        width: 50,
      },
      {
        label: "หักมาสาย",
        field: "",
        sort: "asc",
        width: 50,
      },
      {
        label: "หักอื่นๆ",
        field: "",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายได้สุทธิ",
        field: "summary_salary_final",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  /* var array = [1,2,3,4,5,6] */
  function receipt() {
    if (work_summary < 1) {
      Swal.fire({
        icon: "error",
        title: "ไม่มีข้อมูล",
      });
    } else {
      const doc = new jsPDF("l", "mm", "a4");

      const stock_month = [
        { th: "มกราคม", eng: "January" },
        { th: "กุมภาพันธ์", eng: "February" },
        { th: "มีนาคม", eng: "March" },
        { th: "เมษายน", eng: "April" },
        { th: "พฤษภาคม", eng: "May" },
        { th: "มิถุนายน", eng: "June" },
        { th: "กรกฎาคม", eng: "July" },
        { th: "สิงหาคม", eng: "August" },
        { th: "กันยายน", eng: "September" },
        { th: "ตุลาคม", eng: "October" },
        { th: "พฤศจิกายน", eng: "November" },
        { th: "ธันวาคม", eng: "December" },
      ];

      doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
      doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
      doc.addFont("Rocki", "bold");
      doc.setFont("THSarabunNew");
      doc.setFontSize(15);

      var img =
        getUser().com === "1a947e52-07ad-44fb-baca-aa24741512c3"
          ? logo_smz.imgs
          : logoTTT.imgs;

      /*  console.log(img,logoTTT.imgs) */

      moment.locale("en");
      var year_th = parseInt(moment(new Date()).format("yyyy")) + 543;
      var month_th = stock_month.filter((e) => {
        return e.eng === moment(new Date()).format("MMMM");
      });

      /*  for (let index = 0; index < materail.length; index++) {
            var material_lot_list_qc_passed_list = [];
            */

      /* console.log(num,"ssa") */

      if (work_summary < 1) {
      } else {
        if (getUser().com === "1a947e52-07ad-44fb-baca-aa24741512c3") {
          var counts = 0;
          for (let loop = 0; loop < work_summary.length; loop = loop + 4) {
            /*  console.log(numloop) */

            var row = 0;
            for (let i = 0; row < 4; i = i + 4) {
              if (!work_summary[loop + row]) {
              } else {
                var emp_name_th = "";
                var emp_no = "";
                var ot_salary = "";
                var extra_salary = "";
                var summary_salary = "";
                var sunday_work_salary = "";
                var time_extra = "";
                var time_normal = "";
                var time_ot = "";
                var time_sunday_working = "";
                var current_salary = "";
                var ppk_value = "";
                var summary_salary_final = "";

                emp_name_th = work_summary[loop + row].emp_name_th || "-";
                emp_no = work_summary[loop + row].emp_no || "-";
                ot_salary = work_summary[loop + row].ot_salary || 0;
                extra_salary = work_summary[loop + row].extra_salary || 0;
                summary_salary = work_summary[loop + row].summary_salary || 0;
                sunday_work_salary =
                  work_summary[loop + row].sunday_work_salary || 0;
                current_salary = work_summary[loop + row].current_salary || 0;
                time_extra = work_summary[loop + row].time_extra || "-";
                time_normal = work_summary[loop + row].time_normal || "-";
                time_ot = work_summary[loop + row].time_ot || "-";
                time_sunday_working =
                  work_summary[loop + row].time_sunday_working || "-";
                ppk_value = work_summary[loop + row].ppk_value || 0;
                summary_salary_final =
                  work_summary[loop + row].summary_salary_final || 0;

                var x = 0;
                var y = 0;
                if (row == 0) {
                  x = 0;
                  y = 0;
                } else if (row == 1) {
                  x = 148.5;
                  y = 0;
                } else if (row == 2) {
                  x = 0;
                  y = 105;
                } else if (row == 3) {
                  x = 148.5;
                  y = 105;
                }

                doc.setDrawColor(255, 255, 255);
                doc.setFillColor(255, 255, 255);
                doc.rect(115.5 + x, 77 + y, 10, 10, "FD");
                doc.setDrawColor(0, 0, 0);
                doc.addImage(img, "JPEG", 5 + x, 2.5 + y, 15, 15);
                doc.setLineDash([3, 4], 0);
                doc.rect(0 + x, 0 + y, 148.5, 105);
                doc.setLineDash([0, 0], 0);
                /* doc.rect(8+x,5+y,90,8);  */
                doc.setFontSize(18);
                doc.text(
                  25 + x,
                  10 + y,
                  "บริษัท ชิมิสึ แมนนิวแฟคเจอร์ริ่ง จำกัด"
                );
                doc.text(
                  25 + x,
                  10 + y,
                  "บริษัท ชิมิสึ แมนนิวแฟคเจอร์ริ่ง จำกัด"
                );
                /*  doc.rect(8+x,13+y,35,8);  */
                doc.setFontSize(15);
                doc.text(
                  3 + x,
                  22 + y,
                  "571 ถนนประชาพัฒนา แขวงทับยาว เขตลาดกระบัง กทม 10520 โทร.02-738-0623-4",
                  "left"
                );
                doc.text(3 + x, 30 + y, "เงินรายได้ งวด วันที่", "left");
                doc.text(14 + x, 39 + y, "ชื่อ ~ สกุล", "center");
                doc.text(14 + x, 48 + y, "วันทำงาน", "center");
                doc.text(23 + x, 57 + y, time_normal.toString(), "right");
                if (current_salary !== 0) {
                  doc.text(
                    23 + x,
                    66 + y,
                    current_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(23 + x, 66 + y, "-", "right");
                }

                doc.text(14 + x, 75 + y, "หัก ค่าภาษี", "center");
                doc.text(23 + x, 84 + y, "-", "right");
                doc.text(14 + x, 93 + y, "ลงชื่อผู้รับเงิน", "center");

                doc.text(
                  60 + x,
                  30 + y,
                  Number(moment(filler_work_summary.date_start).format("DD")) +
                    "-" +
                    Number(moment(filler_work_summary.date_end).format("DD")) +
                    " " +
                    month_th[0].th +
                    " " +
                    year_th,
                  "left"
                );
                doc.text(138 + x, 30 + y, "รหัส", "center");
                doc.text(75 + x, 39 + y, emp_name_th.toString(), "center");
                doc.text(138 + x, 39 + y, emp_no.toString(), "center");
                doc.text(138 + x, 48 + y, "รวมยอดรับ", "center");

                doc.text(42 + x, 48 + y, "รวม OT/ชม.", "center");
                doc.text(63 + x, 48 + y, " ค่าเที่ยว", "center");
                doc.text(85 + x, 48 + y, "ค่าเข้ากะ", "center");
                doc.text(110 + x, 48 + y, "OT วันอาทิตย์", "center");
                doc.text(50 + x, 57 + y, time_ot.toString(), "right");
                if (ot_salary !== 0) {
                  doc.text(
                    50 + x,
                    66 + y,
                    ot_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(50 + x, 66 + y, "-", "right");
                }

                doc.text(70 + x, 57 + y, time_extra.toString(), "right");
                if (extra_salary !== 0) {
                  doc.text(
                    70 + x,
                    66 + y,
                    extra_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(70 + x, 66 + y, "-", "right");
                }

                doc.text(90 + x, 57 + y, "-", "right");
                doc.text(90 + x, 66 + y, "-", "right");
                doc.text(
                  120 + x,
                  57 + y,
                  time_sunday_working.toString(),
                  "right"
                );
                doc.text(70 + x, 57 + y, time_extra.toString(), "right");
                if (sunday_work_salary !== 0) {
                  doc.text(
                    120 + x,
                    66 + y,
                    sunday_work_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(120 + x, 66 + y, "-", "right");
                }

                if (summary_salary !== 0) {
                  doc.text(
                    146 + x,
                    66 + y,
                    summary_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(146 + x, 66 + y, "-", "right");
                }

                doc.line(125 + x, 88 + y, 148 + x, 88 + y);

                doc.text(42 + x, 75 + y, "หัก ค่า ปกส", "center");
                doc.text(63 + x, 75 + y, "หัก ขาดงาน", "center");
                doc.text(85 + x, 75 + y, "หัก มาสาย", "center");
                doc.text(110 + x, 75 + y, "หัก อื่นๆ", "center");
                doc.text(138 + x, 75 + y, "รวมยอด หัก", "center");
                if (ppk_value !== 0) {
                  doc.text(
                    50 + x,
                    84 + y,
                    ppk_value.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(50 + x, 84 + y, "-", "right");
                }

                doc.text(70 + x, 84 + y, "-", "right");
                doc.text(90 + x, 84 + y, "-", "right");
                doc.text(118 + x, 84 + y, "-", "right");
                /*  if (ppk_value !== 0) { */
                doc.text(
                  146 + x,
                  84 + y,
                  ppk_value.toFixed(2).toString(),
                  "right"
                );
                /*  } else {
                    doc.text(146+x,84+y,"-","right");
                } */

                doc.line(125 + x, 96 + y, 148 + x, 96 + y);
                doc.line(125 + x, 97 + y, 148 + x, 97 + y);
                doc.text(88 + x, 93 + y, "ยอดรับเงินงวดนี้", "center");
                /*  if (summary_salary_final !== 0) { */
                doc.text(
                  146 + x,
                  93 + y,
                  summary_salary_final.toFixed(2).toString(),
                  "right"
                );
                /*  } else {
                     doc.text(146+x,93+y,"-","right");
                } */

                doc.setLineWidth(0.1);
                doc.line(25 + x, 96 + y, 76 + x, 96 + y);

                counts = work_summary.length - 4;
              }

              row = row + 1;

              /*  console.log(material_lot_list_qc_passed_list,"กฟห") */
            }

            if (loop < counts) {
              doc.addPage("l");
            }
          }
        } else {
          var counts = 0;
          for (let loop = 0; loop < work_summary.length; loop = loop + 4) {
            /*  console.log(numloop) */

            var row = 0;
            for (let i = 0; row < 4; i = i + 4) {
              if (!work_summary[loop + row]) {
              } else {
                var emp_name_th = "";
                var emp_no = "";
                var ot_salary = "";
                var extra_salary = "";
                var summary_salary = "";
                var sunday_work_salary = "";
                var time_extra = "";
                var time_normal = "";
                var time_ot = "";
                var time_sunday_working = "";
                var current_salary = "";
                var ppk_value = "";
                var summary_salary_final = "";

                emp_name_th = work_summary[loop + row].emp_name_th || "-";
                emp_no = work_summary[loop + row].emp_no || "-";
                ot_salary = work_summary[loop + row].ot_salary || 0;
                extra_salary = work_summary[loop + row].extra_salary || 0;
                summary_salary = work_summary[loop + row].summary_salary || 0;
                sunday_work_salary =
                  work_summary[loop + row].sunday_work_salary || 0;
                current_salary = work_summary[loop + row].current_salary || 0;
                time_extra = work_summary[loop + row].time_extra || "-";
                time_normal = work_summary[loop + row].time_normal || "-";
                time_ot = work_summary[loop + row].time_ot || "-";
                time_sunday_working =
                  work_summary[loop + row].time_sunday_working || "-";
                ppk_value = work_summary[loop + row].ppk_value || 0;
                summary_salary_final =
                  work_summary[loop + row].summary_salary_final || 0;

                var x = 0;
                var y = 0;
                if (row == 0) {
                  x = 0;
                  y = 0;
                } else if (row == 1) {
                  x = 148.5;
                  y = 0;
                } else if (row == 2) {
                  x = 0;
                  y = 105;
                } else if (row == 3) {
                  x = 148.5;
                  y = 105;
                }

                doc.setDrawColor(255, 255, 255);
                doc.setFillColor(255, 255, 255);
                doc.rect(115.5 + x, 77 + y, 10, 10, "FD");
                doc.setDrawColor(0, 0, 0);
                doc.addImage(img, "JPEG", 5 + x, 2.5 + y, 15, 15);
                doc.setLineDash([3, 4], 0);
                doc.rect(0 + x, 0 + y, 148.5, 105);
                doc.setLineDash([0, 0], 0);
                /* doc.rect(8+x,5+y,90,8);  */
                doc.setFontSize(18);
                doc.text(25 + x, 10 + y, "บริษัท ทีทีที บราเธอร์ส จำกัด");
                doc.text(25 + x, 10 + y, "บริษัท ทีทีที บราเธอร์ส จำกัด");
                /*  doc.rect(8+x,13+y,35,8);  */
                doc.setFontSize(15);
                doc.text(
                  3 + x,
                  22 + y,
                  "852/8 ถนนหลวงแพ่ง แขวงทับยาว เขตลาดกระบัง กทม 10520 โทร.02-738-0623-4",
                  "left"
                );
                doc.text(3 + x, 30 + y, "เงินรายได้ งวด วันที่", "left");
                doc.text(14 + x, 39 + y, "ชื่อ ~ สกุล", "center");
                doc.text(14 + x, 48 + y, "วันทำงาน", "center");
                doc.text(23 + x, 57 + y, time_normal.toString(), "right");
                if (current_salary !== 0) {
                  doc.text(
                    23 + x,
                    66 + y,
                    current_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(23 + x, 66 + y, "-", "right");
                }

                doc.text(14 + x, 75 + y, "หัก ค่าภาษี", "center");
                doc.text(23 + x, 84 + y, "-", "right");
                doc.text(14 + x, 93 + y, "ลงชื่อผู้รับเงิน", "center");

                doc.text(
                  60 + x,
                  30 + y,
                  Number(moment(filler_work_summary.date_start).format("DD")) +
                    "-" +
                    Number(moment(filler_work_summary.date_end).format("DD")) +
                    " " +
                    month_th[0].th +
                    " " +
                    year_th,
                  "left"
                );
                doc.text(138 + x, 30 + y, "รด/รหัส", "center");
                doc.text(75 + x, 39 + y, emp_name_th.toString(), "center");
                doc.text(138 + x, 39 + y, emp_no.toString(), "center");
                doc.text(138 + x, 48 + y, "รวมยอดรับ", "center");

                doc.text(42 + x, 48 + y, "รวม OT/ชม.", "center");
                doc.text(64 + x, 48 + y, "ค่าเบี้ยขยัน", "center");
                doc.text(84 + x, 48 + y, "ค่าเบี้ยเลี้ยง", "center");
                doc.text(110 + x, 48 + y, "OT วันอาทิตย์", "center");
                doc.text(50 + x, 57 + y, time_ot.toString(), "right");
                if (ot_salary !== 0) {
                  doc.text(
                    50 + x,
                    66 + y,
                    ot_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(50 + x, 66 + y, "-", "right");
                }

                doc.text(70 + x, 57 + y, time_extra.toString(), "right");
                if (extra_salary !== 0) {
                  doc.text(
                    70 + x,
                    66 + y,
                    extra_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(70 + x, 66 + y, "-", "right");
                }

                doc.text(90 + x, 57 + y, "-", "right");
                doc.text(90 + x, 66 + y, "-", "right");
                doc.text(
                  120 + x,
                  57 + y,
                  time_sunday_working.toString(),
                  "right"
                );
                doc.text(70 + x, 57 + y, time_extra.toString(), "right");
                if (sunday_work_salary !== 0) {
                  doc.text(
                    120 + x,
                    66 + y,
                    sunday_work_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(120 + x, 66 + y, "-", "right");
                }

                if (summary_salary !== 0) {
                  doc.text(
                    146 + x,
                    66 + y,
                    summary_salary.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(146 + x, 66 + y, "-", "right");
                }

                doc.line(125 + x, 88 + y, 148 + x, 88 + y);

                doc.text(42 + x, 75 + y, "หัก ค่า ปกส", "center");
                doc.text(63 + x, 75 + y, "หัก ขาดงาน", "center");
                doc.text(85 + x, 75 + y, "หัก มาสาย", "center");
                doc.text(110 + x, 75 + y, "หัก อื่นๆ", "center");
                doc.text(138 + x, 75 + y, "รวมยอด หัก", "center");
                if (ppk_value !== 0) {
                  doc.text(
                    50 + x,
                    84 + y,
                    ppk_value.toFixed(2).toString(),
                    "right"
                  );
                } else {
                  doc.text(50 + x, 84 + y, "-", "right");
                }

                doc.text(70 + x, 84 + y, "-", "right");
                doc.text(90 + x, 84 + y, "-", "right");
                doc.text(118 + x, 84 + y, "-", "right");
                /*  if (ppk_value !== 0) { */
                doc.text(
                  146 + x,
                  84 + y,
                  ppk_value.toFixed(2).toString(),
                  "right"
                );
                /*  } else {
                    doc.text(146+x,84+y,"-","right");
                } */

                doc.line(125 + x, 96 + y, 148 + x, 96 + y);
                doc.line(125 + x, 97 + y, 148 + x, 97 + y);
                doc.text(88 + x, 93 + y, "ยอดรับเงินงวดนี้", "center");
                /*  if (summary_salary_final !== 0) { */
                doc.text(
                  146 + x,
                  93 + y,
                  summary_salary_final.toFixed(2).toString(),
                  "right"
                );
                /*  } else {
                     doc.text(146+x,93+y,"-","right");
                } */

                doc.setLineWidth(0.1);
                doc.line(25 + x, 96 + y, 76 + x, 96 + y);

                counts = work_summary.length - 4;
              }

              row = row + 1;

              /*  console.log(material_lot_list_qc_passed_list,"กฟห") */
            }

            if (loop < counts) {
              doc.addPage("l");
            }
          }
        }
      }
      /*   } */
      window.open(doc.output("bloburl"));
    }
  }

  const getExportLog = async () => {
    //console.log(filler_logistic);
    axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/getExportLog",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_work_summary,
    })
      .then(function (response) {
        // console.log("ExportLog",response.data.data);
        set_export_log_data(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function disabledDate(date) {
    console.log("datess", date);
    return (
      date.format("YYYY-MM-DD") !==
      moment(date).startOf("month").format("YYYY-MM-DD")
    );
  }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  สรุปวันทำงาน{"  "}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">สรุปวันทำงาน</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div
                className="col-12 col-md-3 col-xl-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <DatePicker
                    customInput={<CustomInput />}
                    selected={filler_work_summary.date_start}
                    className="form-control"
                    wrapperClassName="w-100"
                    id="btn_start_date"
                    //disabledDate={disabledDate}
                    dateFormat={"dd-MM-yyyy"}
                    // onChange={async (date) => SetDate_start_fg(date)}
                    onChange={async (date) => {
                      set_filler_work_summary({
                        ...filler_work_summary,
                        date_start: date,
                      });
                    }}
                    selectsStart
                    startDate={filler_work_summary.date_start}
                    maxDate={filler_work_summary.date_end}

                    // endDate={filler_material.date_end}
                  />
                  <label> วันที่เริ่มต้น </label>
                </div>
              </div>
              {/* <p>วันที่สิ้นสุด</p>  */}
              <div
                className="col-12 col-md-3 col-xl-2"
                style={{ "z-index": "98" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filler_work_summary.date_end}
                    className="form-control"
                    wrapperClassName="w-100"
                    id="btn_end_date"
                    //required="false"
                    dateFormat={"dd-MM-yyyy"}
                    customInput={<CustomInput />}
                    //onChange={async (date) => SetDate_end_fg(date)}
                    onChange={async (date) => {
                      set_filler_work_summary({
                        ...filler_work_summary,
                        date_end: date,
                      });
                    }}
                    selectsEnd
                    startDate={filler_work_summary.date_start}
                    endDate={filler_work_summary.date_end}
                    minDate={filler_work_summary.date_start}
                    // endDate={filler_material.date_end}
                    // minDate = { filler_material.date_start }
                  />
                  <label> วันที่สิ้นสุด </label>
                </div>
              </div>
              <div
                className="col-sm-12 col-md-3 col-lg-3"
                style={{ "z-index": "97" }}
              >
                {/* <div className="form-group">
                  <select
                    className="form-control custom-select select2"
                    type="text"
                    required
                    id="drop_employee_type"
                    value={filler_work_summary.emp_nation_id}
                    onChange={(e) => {
                      set_filler_work_summary({
                        ...filler_work_summary,
                        emp_nation_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Nationality
                    </option>
                    {type_emp.map((el) => {
                      return (
                        <option value={el.emp_nation_id}>
                          {el.emp_nation}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">สัญชาติ</label>
                </div> */}
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    id="text_empname"
                    value={filler_work_summary.emp_name_th}
                    onChange={(e) => {
                      set_filler_work_summary({
                        ...filler_work_summary,
                        emp_name_th: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">ชื่อพนักงาน</label>{" "}
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      GetWorkSummary();
                      getExportLog();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>

              <div className="col-md-4 col-sm-2 col-lg-2">
                <div className="form-group ">
                  <ReactHTMLTableToExcel
                    id="btn_export_excel"
                    className="btn btn-block btn-primary"
                    table="table-to-xls1"
                    filename={
                      "สรุปวันทำงาน" + moment(new Date()).format("DD-MM-yy")
                    }
                    sheet="tablexls"
                    buttonText="Export to Excel"
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-lg-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_report_excel"
                    className="btn btn-block btn-primary"
                    data-toggle="modal"
                    data-target="#modal-formSaleDaily"
                    onClick={() => receipt()}
                  >
                    Report ใบเสร็จรับเงิน
                  </button>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="form-group ">
                  <ReactHTMLTableToExcel
                    id="btn_export_log"
                    className="btn btn-block btn-primary"
                    table="table-to-xls-export-log"
                    filename={
                      "Export Log" + moment(new Date()).format("DD-MM-yy")
                    }
                    sheet="tablexls"
                    buttonText="Export Log"
                  />
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-lg-2">
                <div className="form-group ">
                  {
                    <ReactHTMLTableToExcel
                      id="btn_report_krungthai"
                      className="btn btn-block btn-primary"
                      buttonText="Report กรุงไทย"
                      table="table-to-xls-report-kb"
                      filename={
                        "Report กรุงไทย" + moment(new Date()).format("DD-MM-yy")
                      }
                      sheet="tablexls"
                    />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              {/* &nbsp;&nbsp;          
              <p>วันที่เริ่ม</p>  */}
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              {/* <div className="card-tools"></div> */}
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                id="table-to-xls"
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
              <div style={{ display: "none" }}>
                <MDBDataTable
                  sortable={false}
                  id="table-to-xls1"
                  entries={4096}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              {/* <h3 className="card-title">Project</h3> */}
              {/* <div className="card-tools"></div> */}
            </div>
            <div
              className="card-body table-responsive "
              style={{ display: "none" }}
            >
              <table
                id="table-to-xls-export-log"
                className="table table-head-fixed"
                border="1"
              >
                <thead>
                  <tr>
                    <th> Date </th>
                    <th> Name - Surname </th>
                    <th> Preroid </th>
                    <th> Check In </th>
                    <th> Check Out </th>
                  </tr>
                </thead>
                <tbody>
                  {export_log_data.map((el) => {
                    return (
                      <>
                        <tr>
                          <td>{moment(el.date).format("DD/MM/YY")}</td>
                          <td>{el.name_surname}</td>
                          <td>{el.period_time_name}</td>
                          <td>
                            {el.check_in
                              ? moment(el.check_in).format("HH.mm")
                              : "-"}
                          </td>
                          <td>
                            {el.check_out
                              ? moment(el.check_out).format("HH.mm")
                              : "-"}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {check_kb_view.check === true ? (
              <div
                className="card-body table-responsive "
                style={{ display: "none" }}
              >
                <table
                  id="table-to-xls-report-kb"
                  className="table table-head-fixed"
                  border="1"
                >
                  <thead>
                    <tr>
                      <th>1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor: "#ffff99",
                          color: "#ff0000",
                        }}
                      >
                        {" "}
                        ACCOUNT NO.{" "}
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor: "#ffff99",
                          color: "#ff0000",
                        }}
                      >
                        {" "}
                        ACCOUNT NAME{" "}
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor: "#ffff99",
                          color: "#ff0000",
                        }}
                      >
                        {" "}
                        AMOUNT Baht{" "}
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor: "#ffff99",
                        }}
                      >
                        {" "}
                        User Ref.1(CIF ID/CARD No.){" "}
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor: "#ffff99",
                        }}
                      >
                        {" "}
                        User Ref.2{" "}
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor: "#ffff99",
                        }}
                      >
                        {" "}
                        DDA REF.1{" "}
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          textAlign: "center",
                          backgroundColor: "#ffff99",
                        }}
                      >
                        {" "}
                        DDA REF.2{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {report_kb.map((el) => {
                      // console.log(el);
                      if (
                        el.account_number !== null &&
                        el.account_number !== undefined
                      ) {
                        return (
                          <>
                            <tr>
                              {/* <td>{"`"+el.account_number.toString()}</td> */}
                              <td>
                                =CONCATENATE{"("}"","{el.account_number}"{")"}
                              </td>
                              <td>{el.account_name}</td>
                              <td>{el.summary_salary_final}</td>
                              <td>{el.check_in}</td>
                              <td>{el.check_out}</td>
                              <td>{el.check_out}</td>
                              <td>{el.check_out}</td>
                            </tr>
                          </>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="row"></div>
            )}

            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}

export default Work_day_summary;
