import React, { useState, useEffect } from "react";

import "../../assets/css/Content.css";
import "../../assets/css/LandingPage.css";
import liff from '@line/liff';
import Configs from "../../config";
import Swal from "sweetalert2";
import JWT_Decode from "jwt-decode";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  setUserSession,
  getToken,
  getUser,
  getRememberme,
  setRemembermetosess,
  removeUserSession,
  removeOem,
  removeRememberme,

} from "../../Utils/Common";
import axios from "axios";
import logo from '../../assets/img/userlogo/user1.jpg';
import { logoTTT, Login_BG2, Login_BG } from "../../routes/imgRoute/imgUrl";
import { useParams } from "react-router";



function Hr_line(params) {

  const { user_id } = useParams();
  const [datarememberme, setRemembermeData] = useState(getRememberme());
  const [rememberme, setRememberme] = useState(datarememberme.remember);
  const [idToken, setIdToken] = useState("");

  const logout = () => {
    liff.logout();
  }
  const initLine = async (id) => {
    liff.init({ liffId: "1657906434-xnPbMN82" }, () => {
      console.log("in hr line", id);
      if (liff.isLoggedIn()) {
        runApp();
      } else {
        liff.login();
      }
    }, (err) => console.error(err));
  };

  const checkInfoEmpLineApi = async (userId) => {
    let data = {
      userId,
    };
    Swal.fire({
      title: "Now loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then(() => {
      axios({
        method: "post",
        url:
          Configs.API_LINE +
          "/users/checkInfoEmpLineApi",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(function (response) {
          // alert(response.data);
          console.log('response', response);
          if (response.data === false) {
            Swal.fire({
              icon: "error",
              title: "ข้อมูล Id line ไม่ตรงกับพนักงาน",
              showConfirmButton: true,
            }).then((res) => {
              if (res.isConfirmed) {
                window.location.href = '/login';
              }
            })
            return;
          }
          responseAfterLogined(response.data[0], '/Welcome');
        })
    })
      .catch(function (error) {
        console.log(error);
      });
  };

  const responseAfterLogined = (response, path) => {
    const res = response;

    if (res.message === "login success") {
      var user = JWT_Decode(res.token);
      removeRememberme();
      localStorage.setItem("username", res.username);
      localStorage.setItem("remember", rememberme);
      setUserSession(res.token, 1, user);
      var balance_day = getUser().balance_time;

      Swal.fire({
        icon: "success",
        title: res.status,
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        window.location.href = (path);
        //console.log(getUser().inform)
        if (getUser().status_inform === "true") {
          Swal.fire({
            title: "ประกาศ",
            text: getUser().inform,
            imageUrl: logoTTT.imgs,
            imageWidth: 50,
            imageHeight: 50,
            imageAlt: "Custom image",
          }).then((result) => {
            if (balance_day > 0) {
              // console.log("ยังไม่หมดอายุการใช้งาน", balance_day)
              //console.log("มีอายุการใช้งาน" , "อีกจำนวน ",moment(exp2).format('HH')+ " ชม. " + moment(exp2).format('mm')+" นาที")

              //อายุการใช้งานต่ำกว่า 30 วัน
              if (balance_day <= 30 && balance_day > 29) {
                Swal.fire({
                  title: "แจ้งเตือน",
                  html:
                    "<pre>" +
                    "อายุการใช้งานเหลือน้อยกว่า 30 วัน \n คงเหลือ " +
                    getUser().balance_day +
                    "\nและจะหมดลงใน\n" +
                    getUser().exp_date +
                    "</pre>",
                  imageUrl: logoTTT.imgs,
                  imageWidth: 50,
                  imageHeight: 50,
                  imageAlt: "Custom image",
                });
                //console.log("อายุการใช้งานใกล้จะหมด 30", balance_day)
              }
              //อายุการใช้งานต่ำกว่า 7 วัน
              if (balance_day <= 7) {
                Swal.fire({
                  title: "แจ้งเตือน",
                  html:
                    "<pre>" +
                    "อายุการใช้งานเหลือน้อยกว่า 7 วัน \n คงเหลือ " +
                    getUser().balance_day +
                    "\nและจะหมดลงใน\n" +
                    getUser().exp_date +
                    "</pre>",
                  //text: 'อายุการใช้งานเหลือน้อยกว่า 7 วัน และจะหมดลงในวันที่ \n'+ getUser().exp,

                  imageUrl: logoTTT.imgs,
                  imageWidth: 50,
                  imageHeight: 50,
                  imageAlt: "Custom image",
                });
                //console.log("อายุการใช้งานใกล้จะหมด < 7", balance_day)
              }
              /*   if(balance_day <= 24){
                    console.log("อายุการใช้งานคงเหลือน่อยกว่า 1 วัน", balance_day)
                } */
            } else {
              Swal.fire({
                title: "แจ้งเตือน",
                text: "หมดอายุการใช้งาน",
                imageUrl: logoTTT.imgs,
                imageWidth: 50,
                imageHeight: 50,
                imageAlt: "Custom image",
              }).then((result) => {
                removeUserSession();
                removeOem();
                window.location.href = "/login";
              });
              //console.log("หมดอายุการใช้งาน", exp2)
            }
          });
        } else {
          if (balance_day > 0) {
            // console.log("ยังไม่หมดอายุการใช้งาน", balance_day)
            //console.log("มีอายุการใช้งาน" , "อีกจำนวน ",moment(exp2).format('HH')+ " ชม. " + moment(exp2).format('mm')+" นาที")

            //อายุการใช้งานต่ำกว่า 30 วัน
            if (balance_day <= 30 && balance_day > 29) {
              Swal.fire({
                title: "แจ้งเตือน",
                html:
                  "<pre>" +
                  "อายุการใช้งานเหลือน้อยกว่า 30 วัน \n คงเหลือ " +
                  getUser().balance_day +
                  "\nและจะหมดลงใน\n" +
                  getUser().exp_date +
                  "</pre>",
                imageUrl: logoTTT.imgs,
                imageWidth: 50,
                imageHeight: 50,
                imageAlt: "Custom image",
              });
              //console.log("อายุการใช้งานใกล้จะหมด 30", balance_day)
            }
            //อายุการใช้งานต่ำกว่า 7 วัน
            if (balance_day <= 7) {
              Swal.fire({
                title: "แจ้งเตือน",
                html:
                  "<pre>" +
                  "อายุการใช้งานเหลือน้อยกว่า 7 วัน \n คงเหลือ " +
                  getUser().balance_day +
                  "\nและจะหมดลงใน\n" +
                  getUser().exp_date +
                  "</pre>",
                imageUrl: logoTTT.imgs,
                imageWidth: 50,
                imageHeight: 50,
                imageAlt: "Custom image",
              });
              //console.log("อายุการใช้งานใกล้จะหมด < 7", balance_day)
            }
            /*   if(balance_day <= 24){
                  console.log("อายุการใช้งานคงเหลือน่อยกว่า 1 วัน", balance_day)
              } */
          } else {
            Swal.fire({
              title: "แจ้งเตือน",
              text: "หมดอายุการใช้งาน",
              imageUrl: logoTTT.imgs,
              imageWidth: 50,
              imageHeight: 50,
              imageAlt: "Custom image",
            }).then((result) => {
              removeUserSession();
              removeOem();
              window.location.href = "/login";
            });
            //console.log("หมดอายุการใช้งาน", exp2)
          }
        }
      });
    } else {
      // setError(response.data.message);
      Swal.fire({
        icon: "error",
        title: "Sign in fail",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        window.location.href = '/login';
      })
    }
  }

  const saveLineApi = (profile, idToken2) => {
    let data = {
      user: localStorage.getItem("id"),
      idToken: idToken2,
      userId: profile.userId,
      displayName: profile.displayName,
      pictureUrl: profile.pictureUrl,
    };
    Swal.fire({
      title: "Now loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 2000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then(() => {
      console.log('data', data);
      axios({
        method: "post",
        url:
          Configs.API_LINE +
          "/users/saveLineApi",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(function (response) {
          console.log('response', response);
          // window.location.href = '/Profile';
          responseAfterLogined(response.data[0], '/Profile');
        })
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const runApp = async () => {
    console.log('runApp', user_id);
    const idToken = liff.getIDToken();

    setIdToken(idToken);
    liff.getProfile().then(async profile => {
      console.log(profile);
      if (localStorage.getItem("id") && localStorage.getItem("id") !== "null") {
        console.log('save line emp Api');

        saveLineApi(profile, idToken);
      } else {

        console.log('in check Emp Api');
        checkInfoEmpLineApi(profile.userId);
      }

    }).catch(err => console.error(err));
  }
  const [userIdFup, setUserIdFup] = useState({
    id: ''
  })

  useEffect(() => {
    Swal.fire({
      title: "loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    if (user_id) {
      localStorage.setItem("id", user_id);
    }

    initLine();
    Swal.close();
  }, []);

  return (
    <div
      className="img-bg"
      style={{
        backgroundImage: "url(" + Login_BG.imgs + ")",
      }}
    >
      <section
        className="test section-padding section section-shaped section-sm"
        style={{ minHeight: "100%" }}
      >
        <Container className="fix-padding ">
          <Row className="justify-content-first border-0">
            <Col className="img-1 border-0" lg={{ size: "auto" }}>
              <img
                alt="..."
                className="img-fluid rounded shadow border-0"
                src={Login_BG2.imgs}
                style={{ width: "750px", height: "545px" }}
              />
            </Col>

            <Col className="">
              <Card className=" bg-secondary shadow border-0">
                <CardBody className=" body-text  px-lg-5 py-lg-5">
                  <Row className="justify-content-end">
                    <img
                      alt="..."
                      className="img-fluid rounded"
                      src={logoTTT.imgs}
                      style={{ width: "65px" }}
                    />
                  </Row>

                  <Row className="justify-content-center">
                    <div className=" font-weight-bold text-center">
                      <h3 style={{ fontWeight: "bold" }}>Login</h3>
                    </div>
                  </Row>

                  <Form
                    role="form"
                    onSubmit={(e) => {
                      /**
                       * Prevent submit from reloading the page
                       */
                      e.preventDefault();
                      e.stopPropagation();
                      // handleLogin();
                    }}
                  >
                    {/*  <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        {...username}
                        required
                      ></input>
                      <label>Username </label>
                    </div> */}
                    <label>
                      <span>Username</span>
                    </label>
                    <FormGroup className="mb-3 ">
                      <InputGroup className="input-group-alternative">
                        <Input type="text" id="username" />
                      </InputGroup>
                    </FormGroup>
                    {/*  <div className="form-group">
                      <input
                        type="password"
                        autoComplete="off"
                        className="form-control"
                        {...password}
                        required
                      ></input>
                      <label>Password </label>
                    </div> */}
                    <label>
                      <span>Password</span>
                    </label>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <Input
                          type="password"
                          autoComplete="off"

                          id="password"
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      {/*   <input
                        className="custom-control-input"
                        id="rememberme"
                        type="checkbox"
                        checked={rememberme}
                        onChange={Getchecked}
                      />
                       <input className="form-check-input " type="checkbox" />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckLogin"
                      >
                        <span>Remember me</span>
                      </label> */}
                      <input
                        className="form-check-input "
                        type="checkbox"
                        // onChange={Getchecked}
                        checked={rememberme}
                        id="chk_remember"
                      />
                      <label
                        className="form-check-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Remember me
                      </label>
                    </div>

                    <div className="text-center">
                      {/* {error && (
                        <>
                          <small style={{ color: "red" }}>{error}</small>
                          <br />
                        </>
                      )} */}
                      <Button
                        type="submit"
                        className="my-4"
                        color="primary"
                        // onClick={handleLogin}
                        id="btn_login"
                      >
                        Sign in
                      </Button>
                    </div>
                  </Form>
                  <span>
                    <small>V. 1.5</small>
                  </span>
                </CardBody>
                {/* <br></br><br></br><br></br><br></br><br></br> */}
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="https://tttbrother.com/%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A5%E0%B8%B0%E0%B9%80%E0%B8%AD%E0%B8%B5%E0%B8%A2%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%A3%E0%B8%B2-7820-1-%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%A3%E0%B8%B2.html"
                  >
                    <small>Request Demo Please Contact</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a
                    className="text-light"
                    href="https://tttbrother.com/%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%A5%E0%B8%B0%E0%B9%80%E0%B8%AD%E0%B8%B5%E0%B8%A2%E0%B8%94%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2-218253-production-material-requirement-planning-%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%AB%E0%B8%B2%E0%B8%A3%E0%B8%88%E0%B8%B1%E0%B8%94.html"
                  >
                    <small>PMRP Feature</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
export default Hr_line;
