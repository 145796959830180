import React, { Component, useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { useParams } from "react-router";
import axios from "axios";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Resizer from "react-image-file-resizer";

function Materail_add(props) {
  const [addRoedit, setaddRoedit] = useState(false);
  const [supplier, setSupplier] = useState([]);
  const [type, setType] = useState([]);
  const [size, setSize] = useState([]);
  const [spect, setSpect] = useState([]);
  const [radio, setRadio] = useState(null);
  const [editRoadd, setEditRoadd] = useState();
  const { id } = useParams();
  const [page_mode, setPage_mode] = useState([]);

  const [materailData, setmaterailData] = useState({
    no: "",
    name: "",
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    buy_price: "",
    material_supplier_id: "",
    material_unit_id: "",
    amount: 0,
    description: "",
    weight_piece: "",
    minimum_lot: "",
    minimum_order_supplier: "",
    supplier_lead_time: "",
    remark: "",
    image: "",
    image_name: "",
    imaget_path: "",
    oem_id: getOem(),
    is_active: true,
  });
  /*  const [materailDataEdit, setmaterailDataEdit] = useState(
    {
    id:id,
    no: null,
    name: null,
    material_type_id: null,
    material_spec_id: null,
    material_size_id: null,
    buy_price: null,
    material_supplier_id: null,
    material_unit_id: null,
    amount: null,
    description: null,
    remark: null,
    image: null,
    image_name: null,
    imaget_path: null,
    oem_id: null,
    is_active: null,
    }
  ); */

  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_type = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialType?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_spec = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSpec?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_size = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSize?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  useEffect(() => {
    axios(config_sub)
      .then(function (response) {
        setSupplier(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_type)
      .then(function (response) {
        setType(response.data);
        /*  console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_spec)
      .then(function (response) {
        setSpect(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_size)
      .then(function (response) {
        setSize(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    /* console.log(props.mode);  */
    if (props.mode === "read") {
      setPage_mode("View");
      setaddRoedit(true);
    } else if (props.mode === "edit") {
      setaddRoedit(false);
      setPage_mode("Edit");
    } else {
      setaddRoedit(false);
      setPage_mode("Add");
    }
  }, []);


  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      400,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
           
      },
     // "base64"
    );
  });
  function dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}
  async function setproductLogo(e) {
    if (e.target.files[0].size <= 26214400) {
      if (materailData.no !== "") {

        const file = e.target.files[0];
        
        const image = await resizeFile(file);
     
       
        var file_image = dataURLtoFile(image,file.name);
  
        /* console.log(product.no); */
        var data = new FormData();
        data.append("Profile", file_image);
        data.append("typeFile", materailData.no);

        var config_uploadProfile = {
          method: "post",
          url: Configs.API_URL + "/upload/profile",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config_uploadProfile)
          .then(function (response) {
            if (response.data.status) {
              /* console.log(response.data); */

              setmaterailData({
                ...materailData,
                image_name: response.data.data.orgin_name,
                imaget_path: response.data.data.path,
                image: response.data.data,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        Swal.fire(
          "check materailData_no",
          "materailData_no can't null",
          "warning"
        );
      }
    }else{
      Swal.fire(
        "Error",
        "Image size limit 25 mb.",
        "error"
      );
    }
  }

  var config_matt = {
    method: "get",
    url: Configs.API_URL + "/material/findById?id=" + id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (props.mode === "read" || props.mode === "edit") {
      //console.log(materailData);
      axios(config_matt)
        .then(function (response) {
          if (
            response.data.material_unit_id ===
            "505a84a4-6c71-4fbf-9dbd-8c63644221f5"
          ) {
            setRadio(true);
          } else {
            setRadio(false);
          }

          setmaterailData({
            no: response.data.no,
            name: response.data.name,
            material_type_id: response.data.material_type_id,
            material_spec_id: response.data.material_spec_id,
            material_size_id: response.data.material_size_id,
            buy_price:
              response.data.buy_price === null
                ? response.data.buy_price
                : response.data.buy_price.toString(),
            material_supplier_id: response.data.material_supplier_id,
            material_unit_id: response.data.material_unit_id,
            amount: 0,
            weight_piece: response.data.weight_piece,
            minimum_lot: response.data.minimum_lot,
            minimum_order_supplier: response.data.minimum_order_supplier,
            supplier_lead_time: response.data.supplier_lead_time,
            description: response.data.description,
            remark: response.data.remark,
            image: response.data.image,
            image_name: response.data.image_name,
            imaget_path: response.data.imaget_path,
            oem_id: response.data.oem_id,
            is_active: response.data.is_active,
          });

          /* if (condition) {
             setRadio();
          } */
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  function saveOption(params) {
    if (
      materailData.no !== "" &&
      materailData.name !== "" &&
      materailData.material_type_id !== "" &&
      materailData.material_spec_id !== "" &&
      materailData.material_size_id !== "" &&
      materailData.buy_price !== "" &&
      materailData.material_supplier_id !== "" &&
      materailData.material_unit_id !==
        "" /* ||
      materailData.amount !== "" */
    ) {
      if (props.mode === "add") {
        /*  const name_check ={ name:materailData.name} 
        axios({
          method: "post",
          url: Configs.API_URL + "/material/checkName",
          headers: {
            Authorization: getToken(),
            'X-TTT': Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: name_check,
        })
        .then(function (response) {
          
           console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        }); */

        const temp_data = {
          no: materailData.no,
          name: materailData.name,
          material_type_id: materailData.material_type_id,
          material_spec_id: materailData.material_spec_id,
          material_size_id: materailData.material_size_id,
          buy_price:
            materailData.buy_price === null
              ? 0
              : Number(materailData.buy_price.toString().replace(/,/g, "")),
          weight_piece:
            materailData.weight_piece === null
              ? 0
              : Number(materailData.weight_piece.toString().replace(/,/g, "")),
          minimum_lot:
            materailData.minimum_lot === null
              ? 0
              : Number(materailData.minimum_lot.toString().replace(/,/g, "")),
          minimum_order_supplier:
            materailData.minimum_order_supplier === null
              ? 0
              : Number(
                  materailData.minimum_order_supplier
                    .toString()
                    .replace(/,/g, "")
                ),
          supplier_lead_time:
            materailData.supplier_lead_time === null
              ? 0
              : Number(
                  materailData.supplier_lead_time.toString().replace(/,/g, "")
                ),
          material_supplier_id: materailData.material_supplier_id,
          material_unit_id: materailData.material_unit_id,
          amount: materailData.amount,
          weight_piece:
          materailData.weight_piece === null
            ? 0
            : Number(materailData.weight_piece.toString().replace(/,/g, "")),
          minimum_lot: materailData.minimum_lot,
          minimum_order_supplier: materailData.minimum_order_supplier,
          supplier_lead_time: materailData.supplier_lead_time,
          description: materailData.description,
          remark: materailData.remark,
          image: materailData.image,
          image_name: materailData.image_name,
          imaget_path: materailData.imaget_path,
          oem_id: materailData.oem_id,
          is_active: materailData.is_active,
        };

        //console.log(temp_data);

        axios({
          method: "post",
          url: Configs.API_URL + "/material/add",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_data,
        })
          .then(function (response) {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 2000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Raw_Materail";
              });
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 2000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire({
                icon: "error",
                title: "Can not save",
                text: "already material no",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          });
      } else {
        const editdata = {
          id: id,
          no: materailData.no,
          name: materailData.name,
          material_type_id: materailData.material_type_id,
          material_spec_id: materailData.material_spec_id,
          material_size_id: materailData.material_size_id,
          material_supplier_id: materailData.material_supplier_id,
          material_unit_id: materailData.material_unit_id,
          amount: materailData.amount,
          buy_price:
            materailData.buy_price === null
              ? 0
              : Number(materailData.buy_price.toString().replace(/,/g, "")),
          weight_piece:
            materailData.weight_piece === null
              ? 0
              : Number(materailData.weight_piece.toString().replace(/,/g, "")),
          minimum_lot:
            materailData.minimum_lot === null
              ? 0
              : Number(materailData.minimum_lot.toString().replace(/,/g, "")),
          minimum_order_supplier:
            materailData.minimum_order_supplier === null
              ? 0
              : Number(
                  materailData.minimum_order_supplier
                    .toString()
                    .replace(/,/g, "")
                ),
          supplier_lead_time:
            materailData.supplier_lead_time === null
              ? 0
              : Number(
                  materailData.supplier_lead_time.toString().replace(/,/g, "")
                ),
          description: materailData.description,
          remark: materailData.remark,
          image: materailData.image,
          image_name: materailData.image_name,
          imaget_path: materailData.imaget_path,
          oem_id: materailData.oem_id,
          is_active: materailData.is_active,
        };
        /*  const test = materailData.buy_price.replace(/,/g,'');;*/
        //console.log(editdata);
        
        axios({
          method: "post",
          url: Configs.API_URL + "/material/update",
          headers: {
            "X-TTT": Configs.API_TTT,
            Authorization: getToken(),
            "Content-Type": "application/json",
          },
          data: editdata,
        })
          .then(function (response) {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 2000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Raw_Materail";
              });
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 2000,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire({
                icon: "error",
                title: "Can no save",
                text: "Something went wrong!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
          });
      }
    }else{
      Swal.fire("Error", "Something went wrong! Data not colect", "error");
    }
  }

  function cancelOption(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail";
  }
  return (
    <div className="wrapper">
    <Header />
    <Sidebar menu="warehouse" activemenu="materail" submenu="factmaster" />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Raw Material</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Raw Material</li>
                  <li className="breadcrumb-item active">{page_mode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              {props.mode === "add" || props.mode === "edit" ? (
                <div className="col-sm-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
              ) : (
                ""
              )}

              <div className="col-sm-1">
                <button
                  type="button"
                  onClick={cancelOption}
                  class="btn btn-block btn-danger "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Material {page_mode}</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  {/* <div className="form-group">
                    <label htmlFor="">Materail No.</label>{" "}
                    <input type="text" className="form-control" />
                  </div> */}
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={materailData.no}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          no: e.target.value,
                        });
                      }}
                      disabled={addRoedit}
                    />
                    <label>
                      Material No.
                      {materailData.no === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={materailData.name}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          name: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Material Name
                      {materailData.name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <div class="form-group">
                    <select
                      className="custom-select"
                      value={materailData.material_type_id}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          material_type_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Select Type
                      </option>
                      {type.map((el) => {
                        return (
                          <option key={el.id} value={el.id}>
                            {el.name}{" "}
                          </option>
                        );
                      })}
                    </select>
                    <label>
                      Mat. Type
                      {materailData.material_type_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-2">
                  <div class="form-group">
                    <select
                      className="custom-select"
                      value={materailData.material_spec_id}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          material_spec_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Select Spec
                      </option>
                      {spect.map((el) => {
                        return (
                          <option key={el.id} value={el.id}>
                            {el.name}{" "}
                          </option>
                        );
                      })}
                    </select>
                    <label>
                      Mat. Spec
                      {materailData.material_spec_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-2">
                  <div class="form-group">
                    <select
                      className="custom-select"
                      value={materailData.material_size_id}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          material_size_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Select Size
                      </option>
                      {size.map((el) => {
                        return (
                          <option key={el.id} value={el.id}>
                            {el.name}{" "}
                          </option>
                        );
                      })}
                    </select>
                    <label>
                      Mat. Size
                      {materailData.material_size_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={true}
                      value={materailData.weight_piece}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          weight_piece: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Weight/Piece (Kg)
                      {materailData.weight_piece === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <div className="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={true}
                      value={materailData.buy_price}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          buy_price: e.target.value,
                        });
                      }}
                    />
                    <label>
                      Buy Price
                      {materailData.buy_price === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                {/* <div className="col-2">
                  <div class="form-group">
                    {materailData.amount === "" ? (
                      <span style={{ color: "red" }}>*</span>
                    ) : (
                      ""
                    )}
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      
                      required
                      value={materailData.amount}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          amount: e.target.value,
                        });
                      }}
                    />
                    <label>Amount</label>
                  </div>
                </div> */}

                <div className="col-2">
                  <div class="form-group">
                    <select
                      className="custom-select"
                      value={materailData.material_supplier_id}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          material_supplier_id: e.target.value,
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Select Suplier
                      </option>
                      {supplier.map((el) => {
                        return (
                          <option key={el.id} value={el.id}>
                            {el.name}{" "}
                          </option>
                        );
                      })}
                    </select>
                    <label>
                      Supplier
                      {materailData.material_supplier_id === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>

                <div className="col-2">
                  <div class="form-group">
                    <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={true}
                      value={materailData.supplier_lead_time}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          supplier_lead_time: e.target.value,
                        });
                      }}
                    />

                    <label>Supplier Lead Time{"(Day)"}</label>
                  </div>
                </div>

                <div className="col-4" style={{ textAlign: "center" }}>
                  <label htmlFor="exampleInputFile"> </label>
                  <img
                    id="img"
                    alt="..."
                    className="img-fluid rounded shadow border-0"
                    src={
                      materailData.imaget_path !== "" &&
                      materailData.imaget_path !== null &&
                      materailData.imaget_path !== undefined
                        ? Configs.API_URL_IMG + materailData.imaget_path
                        : userdefault_img.imgs
                    }
                    style={{
                      width: "300px",
                      height: "200px",
                      position: "absolute",
                    }}
                  />
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Recommend Size:400x300px{" "}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <label htmlFor="exampleInputFile">Part Image</label>
                  <span style = {{color:"red"}}> * ขนาดไม่เกิน 25 mb</span>
                  <div className="input-group">
                  
                    <div className="custom-file">
                      
                      <input
                        type="file"
                        className="custom-file-input"
                        id="exampleInputFile"
                        disabled={addRoedit}
                        accept="image/*"
                        onChange={setproductLogo}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="exampleInputFile"
                      >
                        {materailData.image_name !== ""
                          ? materailData.image_name
                          : "Select Image"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-1">
                  {materailData.material_unit_id === "" ? (
                    <span style={{ color: "red" }}>*</span>
                  ) : (
                    ""
                  )}
                  <label>Mat. Unit</label>
                </div>
                <div className="col-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radio1"
                      value="6fac9f35-9749-476d-a24a-4c33061e59b7"
                      disabled={addRoedit}
                      checked={
                        materailData.material_unit_id ===
                        "6fac9f35-9749-476d-a24a-4c33061e59b7"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          material_unit_id: e.target.value,
                        });
                      }}
                    />
                    <label className="form-check-label">By Weight(kg)</label>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="radio1"
                      value="505a84a4-6c71-4fbf-9dbd-8c63644221f5"
                      disabled={addRoedit}
                      checked={
                        materailData.material_unit_id ===
                        "505a84a4-6c71-4fbf-9dbd-8c63644221f5"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          material_unit_id: e.target.value,
                        });
                      }}
                    />
                    <label className="form-check-label">By Piece</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="form-group">
                  <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={true}
                      value={materailData.minimum_lot}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          minimum_lot: e.target.value,
                        });
                      }}
                    />

                    <label>Minimum Lot (StockBuffer)</label>
                    
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group">
                
                  <NumberFormat
                      className="form-control"
                      required
                      thousandSeparator={true}
                      value={materailData.minimum_order_supplier}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          minimum_order_supplier: e.target.value,
                        });
                      }}
                    />

                    <label>Minimum Order Supplier</label>


                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={materailData.description}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          description: e.target.value,
                        });
                      }}
                    />
                    <label>Material Description</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={materailData.remark}
                      disabled={addRoedit}
                      onChange={(e) => {
                        setmaterailData({
                          ...materailData,
                          remark: e.target.value,
                        });
                      }}
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      <Footter/>
      </div>
  );
}
export default Materail_add;
