import React from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import { useState } from "react";
import {
  getOem,
  getToken,
  getUser,
  getComConfig,
} from "../../../../Utils/Common";
import { MultiSelect } from "react-multi-select-component";
import { useEffect } from "react";

import NumberFormat from "react-number-format";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Tooling_report from '../../../../../src/component/Report/Tooling_po_report'
import Perchase_report from '../../../../../src/component/Report/Perchase_po_report'
import Quotation_report from './Component/Quotation_report';
import Select from "react-select";

function Table_Quotation_Admin() {
  const handleApproveClick = (index) => {
    let temp = QuotationAdmin.data;
    temp[index].statusApprove = true;
    setQuotationAdmin({ ...QuotationAdmin, data: temp });
    QuotationAdmin.data = temp;
    updateStatus(temp[index].hq_no, true);
  };
  const handleNotApproveClick = async (index) => {
    let temp = QuotationAdmin.data;
    temp[index].statusApprove = false;
    setQuotationAdmin({ ...QuotationAdmin, data: temp });
    QuotationAdmin.data = temp;
    updateStatus(temp[index].hq_no, false);
  };

  const [CustomerDD, setCustomerDD] = useState([]);
  const [statusDD, setStatusDD] = useState({
    data: [],
  });

  let [status_list_filter, setStatus_list_filter] = useState([]);

  const [Filter, setFilter] = useState({
    hq_No: "",
    customer_name: "",
    sale_name: "",
    status: [],
  });
  let [QuotationAdmin, setQuotationAdmin] = useState({
    data: [],
  });

  const [quotation_history, setQuotation_history] = useState({
    data: [],
    product: [],
    detail: [],
    pic: [],
  });

  const [modalData, setModalData] = useState({
    Quo_No: "",
    Quo_Date: "",
    Valid_Date: "",
    Status: "",
    Version: "",
    Cus_Name: "",
    Cus_contactName: "",
    Cus_Address: "",
    Contact_Tel: "",
    Contact_Email: "",
    Remark: "",
    Vat: "",
    typeText: "",
    product_Name: [],
    product_Unit: [],
    beforeTotal: [],
    subTotal: "",
    grandTotal: "",
    product: [],
    detail: [],
  });

  const [productModal, setProductModal] = useState({
    data: [],
    typeText: "",
    beforeTotal: [],
    subTotal: "",
    grandTotal: "",
    product: [],
    detail: [],
    finalDiscount: 0,
  });

  const [modalQuoNo, setModalQuoNo] = useState(false);
  const [MDBT, setMDBT] = useState(true);
  const [ModalNotApprove, setModalNotApprove] = useState(false);
  const [notApproveRemark, setNotApproveRemark] = useState({
    index: null,
    remark: null,
  });
  const [isReadNotApproveRemark, setisReadNotApproveRemark] = useState(false);
  useEffect(() => {
    FilterQuotAdmin();
    getCustomerDD();
    getStatusDD();
    getReport();
    GetProduct();
  }, []);

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  function getCustomerDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        const cus_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        cus_dd.unshift({
          label: "--Select--",
          value: ""
        })
        setCustomerDD(cus_dd);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function getStatusDD() {
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotationStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const options = [];
        for (let item of response.data) {
          let temp = {
            label: item.qs_name,
            value: item.qs_id,
          };
          if (temp.label !== "Cancel") {
            options.push(temp);
          }
        }
        setStatusDD({ ...statusDD, data: options });
        statusDD.data = options;
        // console.log('status:>> ', statusDD.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function updateStatus(hq_no, statusApprove) {
    let data = {
      hq_no: hq_no,
      newStatus_id:
        statusApprove === true
          ? statusDD.data[3].value
          : statusDD.data[1].value,
      approve_id: getUser().fup,
      approve_name: getUser().name + " " + getUser().surname,
      oem_id: getOem(),
      company_id: getUser().com,
      remark:
        notApproveRemark.remark !== "" && notApproveRemark.remark !== null
          ? notApproveRemark.remark?.trim()
          : null,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/quotation/updateStatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        FilterQuotAdmin();
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getQuotHistoryData(id) {
    let temp = {
      hq_id: id,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    // console.log('hq_id :>> ', temp.hq_id);
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotHistoryReport",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async (response) => {
        // console.log('response.data707 :>> ', response.data);
        setQuotation_history({
          ...quotation_history,
          data: response.data.result1,
          product: response.data.result2,
        });
        quotation_history.data = response.data.result1;
        quotation_history.product = response.data.result2;

        const getValidDate = new Date(quotation_history.data[0].hq_valid_unit);
        getValidDate.toISOString().slice(0, 10);
        let day = getValidDate.getDate();
        let month = getValidDate.getMonth() + 1;
        let year = getValidDate.getFullYear();
        let endDate = `${day.toString().padStart(2, "0")}/${month
          .toString()
          .padStart(2, "0")}/${year}`;
        quotation_history.data[0].hq_valid_unit = endDate;
        // console.log('endDate :>> ', quotation_history.data);

        let newDetails = [];
        for (let i = 0; i < quotation_history.product.length; i++) {
          let temp = {
            product_id: quotation_history.product[i].hqp_product_id,
            company_id: getUser().com,
            oem_id: getOem(),
          };
          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus +
              "/api/product/GetProductDetailsForQuotAdmin",
            headers: {
              Authorization: "Bearer " + getToken(),
              "Content-Type": "application/json",
              "X-TTT": Configs.API_TTT,
            },
            data: temp,
          })
            .then((response) => {
              if (response.data.length !== 0) {
                newDetails.push(response.data[0]);
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        }
        // console.log('product', newDetails);
        setQuotation_history({ ...quotation_history, detail: newDetails });
        quotation_history.detail = newDetails;
        // console.log('quot', Product.data)
        quotation_history.product?.map((el) => {
          const check = Product.data.find(
            (tl) => tl.product_id === el.hqp_product_id
          );
          // console.log('check', check)
          if (check) {
            el.path_image = check.pci_img_path;
            el.name_image = check.pci_img_name;
            quotation_history.pic.push(el);

            return el;
          }
        });
        let newReportList = [];
        quotation_history.product?.map((cl) => {
          const checklist = quotation_history.detail.find(
            (er) => er.product_id === cl.hqp_product_id
          );
          if (checklist) {
            newReportList.push(cl);
          }
        });
        // console.log('newReportList', newReportList)
        setQuotation_history({ ...quotation_history, product: newReportList });
        quotation_history.product = newReportList;
      })
      .then(async (response) => {
        let tempData = {
          company_id: getUser().com,
          oem_id: getOem(),
          // user_id: getUser().fup,
        };
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/customer/getPaymentTerm",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(async (response) => {
            setQuotation_history({
              ...quotation_history,
              term: response.data
                ? response.data.filter(
                    (el) => el.pt_id === quotation_history.data[0].hq_pt_id
                  )
                : [],
            });
            quotation_history.term = response.data
              ? response.data.filter(
                  (el) => el.pt_id === quotation_history.data[0].hq_pt_id
                )
              : [];
            if (quotation_history.product.length > 0) {
              Quotation_report(company, quotation_history, getComConfig());
            } else {
              Swal.fire({
                title: "ไม่สามารถออก Report ที่ไม่มี Product ได้",
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  const [Product, setProduct] = useState({
    data: [],
  });

  const GetProduct = async () => {
    let temp = {
      code: null,
      name: null,
      category: null,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductWherecat2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log('getProduct', response.data)
        if (response) {
          setProduct({ data: response.data });
          Product.data = response.data;
          /////////////////////////////////////
          let data = new FormData();
          data.append("company_id", getUser().com);
          data.append("oem_id", getOem());
          data.append("user_id", getUser().fup);
          data.append("products", JSON.stringify(response.data));

          await axios({
            method: "post",
            url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "multipart/form-data",
            },
            data: data,
          }).then(async (response) => {
            // console.log('first', response.data)
            setProduct({ data: response.data });
            Product.data = response.data;
            return response.data;
          });
          // .catch(async (error) => {
          //   Swal.fire({
          //     icon: "error",
          //     title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
          //   });
          // });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getProductDetails(index, id) {
    // console.log('id', id);
    let temp = {
      hq_id: id,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/quotation/getQuotHistoryReport",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('res', response.data)
        setProductModal({
          ...productModal,
          data: response.data.result1[0],
          typeText: response.data.result1[0].hq_lang,
          subTotal: response.data.result1[0].hq_total_befor_vat,
          grandTotal: response.data.result1[0].hq_total_price
            ? parseFloat(response.data.result1[0].hq_total_price || 0)?.toFixed(
                2
              )
            : 0,
          product: response.data.result2,
          finalDiscount: response.data.result1[0].hq_final_discount
            ? parseFloat(
                response.data.result1[0].hq_final_discount || 0
              )?.toFixed(2)
            : 0,
        });
        productModal.data = response.data.result1[0];
        productModal.typeText = response.data.result1[0].hq_lang;
        productModal.subTotal = response.data.result1[0].hq_total_befor_vat;
        productModal.grandTotal = response.data.result1[0].hq_total_price
          ? parseFloat(response.data.result1[0].hq_total_price || 0)?.toFixed(2)
          : 0;
        productModal.product = response.data.result2;
        productModal.finalDiscount = response.data.result1[0].hq_final_discount
          ? parseFloat(
              response.data.result1[0].hq_final_discount || 0
            )?.toFixed(2)
          : 0;
        // console.log('productModal', productModal)
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    let newDetails = [];
    // console.log('productLength', productModal.product.length)
    for (let i = 0; i < productModal.product.length; i++) {
      let Id_product = {
        product_id: productModal.product[i].hqp_product_id,
        oem_id: getOem(),
        company_id: getUser().com,
      };
      await axios({
        method: "post",
        url:
          Configs.API_URL_cuteplus +
          "/api/product/GetProductDetailsForQuotAdmin",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
          "X-TTT": Configs.API_TTT,
        },
        data: Id_product,
      })
        .then((response) => {
          if (response.data.length !== 0) {
            newDetails.push(response.data[0]);
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: error.response.data.error.message,
            icon: "error",
          });
        });
    }
    // console.log('newDetails', newDetails)
    // calculateTotal()
    setProductModal({ ...productModal, detail: newDetails });
    productModal.detail = newDetails;

    let newProductModalList = [];
    productModal.product?.map((cl) => {
      const checklist = productModal.detail.find(
        (er) => er.product_id === cl.hqp_product_id
      );
      if (checklist) {
        newProductModalList.push(cl);
      }
    });
    // console.log('newProductModalList', newProductModalList)
    setProductModal({ ...productModal, product: newProductModalList });
    productModal.product = newProductModalList;
    // console.log('productModal2', productModal)

    let nameTemp = [];
    let nameUnit = [];
    for (let i = 0; i < productModal.detail.length; i++) {
      if (productModal.typeText === "TH") {
        let ProductName =
          productModal.product[i].hqp_product_name_th !== null
            ? productModal.product[i].hqp_product_name_th
            : productModal.detail[i].product_name;
        nameTemp.push(ProductName);

        if (productModal.product[i].hqp_quantity_unit === "Piece") {
          nameUnit.push("ชิ้น");
        } else if (productModal.product[i].hqp_quantity_unit === "pack") {
          nameUnit.push("แพ็ค");
        } else {
          nameUnit.push("ลัง");
        }
      } else if (productModal.typeText === "ENG") {
        let ProductName =
          productModal.product[i].hqp_product_name_eng !== null
            ? productModal.product[i].hqp_product_name_eng
            : productModal.detail[i].product_name_eng;
        nameTemp.push(ProductName);

        if (productModal.product[i].hqp_quantity_unit === "Piece") {
          nameUnit.push("Piece");
        } else if (productModal.product[i].hqp_quantity_unit === "pack") {
          nameUnit.push("Pack");
        } else {
          nameUnit.push("Ctn.");
        }
      } else {
        nameTemp.push(" - ");
      }
    }
    setModalData({
      ...modalData,
      product_Name: nameTemp,
      product_Unit: nameUnit,
    });
    modalData.product_Name = nameTemp;
    modalData.product_Unit = nameUnit;

    let calList = [];
    let sumSubTotal = 0;
    for (let i = 0; i < productModal.product.length; i++) {
      let allQty =
        productModal.product[i].hqp_quantity_unit === "pack"
          ? parseFloat(productModal.detail[i].pd_sale_pc_pack) *
            parseFloat(productModal.product[i].hqp_quantity)
          : productModal.product[i].hqp_quantity_unit === "ctn"
          ? parseFloat(productModal.detail[i].pd_sale_pc_ctn) *
            parseFloat(productModal.product[i].hqp_quantity)
          : parseFloat(productModal.product[i].hqp_quantity);
      let origin_Price =
        parseFloat(productModal.product[i].hqp_retail_price) * allQty;
      let discount =
        productModal.product[i].hqp_gp_percent !== null &&
        productModal.product[i].hqp_gp_percent !== "NaN"
          ? parseFloat(productModal.product[i].hqp_gp_percent)
          : 0;
      let sumPrice = origin_Price - (origin_Price * discount) / 100;
      calList.push(sumPrice);
      sumSubTotal += sumPrice;
    }
    let subTotalOnly = sumSubTotal;
    // let grandTotalValue = productModal.data.hq_incl_vat === true ? sumSubTotal + (sumSubTotal * 0.07) : sumSubTotal
    // let subTotal_GP = sumSubTotal - (sumSubTotal * parseFloat(productModal.data.hq_final_discount)/100)
    // let grandTotalValue = subTotal_GP + (subTotal_GP*0.07) + parseFloat(productModal.data.hq_shipping_cost)
    // console.log('subTotal_GP', subTotal_GP)

    setProductModal({
      ...productModal,
      beforeTotal: calList,
      subTotal: subTotalOnly.toString(),
      // grandTotal: grandTotalValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    });
    productModal.beforeTotal = calList;
    productModal.subTotal = subTotalOnly.toString();
    // productModal.grandTotal = grandTotalValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    getModalData(index);
  }

  async function getModalData(index) {
    let data = QuotationAdmin.data;
    const start_Date = data[index].hq_date.split("T")[0].split("-");
    let startDate = `${start_Date[2]}/${start_Date[1]}/${start_Date[0]}`;

    const getEndDate = new Date(data[index].hq_valid_unit);
    getEndDate.toISOString().slice(0, 10);
    let day = getEndDate.getDate();
    let month = getEndDate.getMonth() + 1;
    let year = getEndDate.getFullYear();
    let endDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;

    let cus_address = "";
    let temp = [
      data[index].hq_cus_house_number,
      data[index].hq_cus_building,
      data[index].hq_swine,
      data[index].hq_soi,
      data[index].hq_road,
      data[index].hq_sub_district,
      data[index].hq_district,
      data[index].hq_province,
      data[index].hq_postcode,
    ];
    // let prefix = ["", "", "หมู่", "ซอย", "ถนน", "", "เขต", "", ""]
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] !== null) {
        cus_address += temp[i] + " ";
        // cus_address += prefix[i] + temp[i] + " ";
      }
    }
    const phoneNumber = data[index].hq_contract_tel.replace(/-/g, "");
    const formattedPhoneNumber =
      phoneNumber.substring(0, 3) +
      "-" +
      phoneNumber.substring(3, 6) +
      "-" +
      phoneNumber.substring(6);
    // console.log('hq_remark', productModal.data.hq_remark)
    setModalData({
      ...modalData,
      Quo_No: data[index].hq_no,
      Quo_Date: startDate,
      Valid_Date: endDate,
      Status: data[index].qs_name,
      Version: data[index].hq_version,
      Cus_Name: data[index].hq_cus_name,
      Cus_contactName: data[index].hq_contract_name || "-",
      Cus_Address: cus_address,
      Contact_Tel: formattedPhoneNumber || "-",
      Contact_Email: data[index].hq_contract_email || "-",
      Remark:
        productModal.data.hq_remark !== null
          ? productModal.data.hq_remark
          : "-",
      Vat: data[index].hq_incl_vat,
      typeText: productModal.typeText,
      beforeTotal: productModal.beforeTotal,
      subTotal: productModal.subTotal || "0",
      grandTotal: productModal.grandTotal || "0",
      product: productModal.product,
      detail: productModal.detail,
    });
    modalData.Quo_No = data[index].hq_no;
    modalData.Quo_Date = startDate;
    modalData.Valid_Date = endDate;
    modalData.Status = data[index].qs_name;
    modalData.Version = data[index].hq_version;
    modalData.Cus_Name = data[index].hq_cus_name;
    modalData.Cus_contactName = data[index].hq_contract_name || "-";
    modalData.Cus_Address = cus_address;
    modalData.Contact_Tel = formattedPhoneNumber || "-";
    modalData.Contact_Email = data[index].hq_contract_email || "-";
    modalData.Remark =
      productModal.data.hq_remark !== null ? productModal.data.hq_remark : "-";
    modalData.Vat = data[index].hq_incl_vat;
    modalData.typeText = productModal.typeText;
    modalData.beforeTotal = productModal.beforeTotal;
    modalData.subTotal = productModal.subTotal || "0";
    modalData.grandTotal = productModal.grandTotal || "0";
    modalData.product = productModal.product;
    modalData.detail = productModal.detail;
    // console.log('modalData', modalData)
  }

  function FilterQuotAdmin() {
    let data = {
      user_id: getUser().fup,
      hq_No: Filter.hq_No === "" ? null : Filter.hq_No.trim(),
      customer_name: Filter.customer_name === "" ? null : Filter.customer_name,
      sale_name: Filter.sale_name === "" ? null : Filter.sale_name.trim(),
      status: status_list_filter.length === 0 ? null : status_list_filter,
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/quotation/FilterQuotAdmin",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        setQuotationAdmin({
          ...QuotationAdmin,
          data: response.data
            ? response.data.filter(
                (el) => el.qs_name !== "Cancel" && el.hq_version !== null
              )
            : [],
        });
        QuotationAdmin = response.data.filter(
          (el) => el.qs_name !== "Cancel" && el.hq_version !== null
        );
        // console.log('Data :>> ', QuotationAdmin.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function clear() {
    setStatus_list_filter(() => []);
    status_list_filter = [];

    setFilter({
      ...Filter,
      hq_No: "",
      customer_name: "",
      sale_name: "",
      status: [],
    });
    Filter.hq_No = "";
    Filter.customer_name = "";
    Filter.sale_name = "";
    Filter.status = [];
    FilterQuotAdmin();
  }

  function getReport() {
    axios(config_com)
      .then(function (response) {
        let temp = [
          {
            perchaseReport: [
              {
                tooling_id: "ff60741d-2583-45fa-bddb-a2816ffde211",
                receive_amount: 0,
                amount: "800",
                is_receive: false,
                po_id: "d6c60ce9-1bce-48d1-ab7c-1766754555dc",
                tooling_name: "hhhhh",
                tooling_supplier_id: "92bd02d9-24a3-49c6-94c5-7884f841e9e3",
                price: "100",
                receive_date: null,
                po_no: "yyyyyyy",
                lead_time: "2",
                buy_price: 100,
                name: "SUP01",
                contact_name: null,
                contact_tel: null,
                contact_fax: null,
                is_use: true,
              },
            ],
            po_no: "yyyyyyy",
            po_id: "d6c60ce9-1bce-48d1-ab7c-1766754555dc",
            contact_name: null,
            contact_tel: null,
            contact_fax: null,
          },
        ];
        // Quotation_report(response.data, temp, getComConfig())
        // console.log('ReportTest :>> ', response.data);
        setCompany(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const [company, setCompany] = useState();
  let config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  const [toolingReport, setToolingReport] = useState([]);
  function generateReport(e, status, idx, event) {
    const data = {
      poList: e.poList,
      fup: getUser().fup,
    };
    axios({
      method: "post",
      url:
        Configs.API_URL_incentive + "/api/incentivePo/getPerchaseDataForReport",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then(function (response) {
        // console.log('response.data', response.data);
        // setMDBT(true);
        setToolingReport(response.data);
        // for (let item of response.data.tooling_receive)
        //     console.log('item.buy_price', item.buy_price);
        Perchase_report(company, response.data, getComConfig());
        if (status === true) {
          // SendEmailAndFilePDF(response.data, idx);
        } else {
          Perchase_report(company, response.data, getComConfig());
          // Perchase_po_address(response.data, company);
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const QuotationData = [];
  for (let index = 0; index < QuotationAdmin.data.length; index++) {
    let checkStatus = QuotationAdmin.data[index].qs_name;
    const rowItem = {};
    rowItem["No"] = index + 1;
    rowItem["hq_No"] = (
      <u
        style={{ color: "blue" }}
        key={QuotationAdmin.data[index]}
        id={QuotationAdmin.data[index].hq_id}
        type="button"
        class="btn"
        onClick={async () => {
          setModalQuoNo(true);
          await getProductDetails(index, QuotationAdmin.data[index].hq_id);
        }}
      >
        {QuotationAdmin.data[index].hq_no}
      </u>
    );
    rowItem["hq_No2"] = QuotationAdmin.data[index].hq_no;

    rowItem["Customer_Name"] = QuotationAdmin.data[index].hq_cus_name;
    rowItem["Price"] = parseFloat(QuotationAdmin.data[index].hq_total_price)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    rowItem["Sales_Name"] = QuotationAdmin.data[index].hq_created_name;
    rowItem["Approve_Name"] =
      QuotationAdmin.data[index].qs_name !== "Pending"
        ? QuotationAdmin.data[index].hq_approve_user_name
        : "";
    rowItem["Status"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-12">
            <button
              type="button"
              disabled={
                QuotationAdmin.data[index].qs_name === "Not Approve"
                  ? false
                  : true
              }
              style={{
                opacity: "1",
                width: "100%",
                border: "4px solid",
                borderRadius: "10px",
                paddingTop: ".2rem",
                paddingBottom: ".2rem",
              }}
              className={`btn btn-outline-${
                QuotationAdmin.data[index].qs_name === "Cancel"
                  ? "dark"
                  : QuotationAdmin.data[index].qs_name === "Success"
                  ? "success"
                  : QuotationAdmin.data[index].qs_name === "Approved"
                  ? "primary"
                  : QuotationAdmin.data[index].qs_name === "Not Approve"
                  ? "danger"
                  : "warning"
              }`}
              onClick={() => {
                setisReadNotApproveRemark(true);
                setNotApproveRemark({
                  ...notApproveRemark,
                  index: index,
                  remark: QuotationAdmin.data[index].hq_not_approve,
                });
                notApproveRemark.index = index;
                notApproveRemark.remark =
                  QuotationAdmin.data[index].hq_not_approve;
                setModalNotApprove(true);
              }}
            >
              {QuotationAdmin.data[index].qs_name}
            </button>
          </div>
        </div>
      </>
    );
    rowItem["Report"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-12">
            <button
              key={QuotationAdmin.data[index]}
              id={QuotationAdmin.data[index].hq_id}
              type="button"
              style={{ backgroundColor: "#007BFF" }}
              className="btn btn-block btn-info"
              onClick={() => {
                getQuotHistoryData(QuotationAdmin.data[index].hq_id);
                setQuotation_history({ ...quotation_history, pic: [] });
                quotation_history.pic = [];
              }}
            >
              Report
            </button>
          </div>
        </div>
      </>
    );
    rowItem["Management"] = (
      <>
        <div
          className="row"
          style={{
            flexWrap: "nowrap",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <div className="col-6">
            <button
              key={QuotationAdmin.data[index]}
              id={QuotationAdmin.data[index].hq_id}
              type="button"
              className="btn btn-block"
              style={{ backgroundColor: "#66CDAA" }}
              disabled={checkStatus === "Pending" ? false : true}
              onClick={() => {
                handleApproveClick(index);
              }}
            >
              Approve
            </button>
          </div>
          <div className="col-6">
            <button
              key={QuotationAdmin.data[index]}
              id={QuotationAdmin.data[index].hq_id}
              type="button"
              className="btn btn-block"
              disabled={checkStatus === "Pending" ? false : true}
              style={{ backgroundColor: "#FFFF00" }}
              onClick={() => {
                setNotApproveRemark({
                  ...notApproveRemark,
                  index: index,
                  remark: null,
                });
                notApproveRemark.index = index;
                notApproveRemark.remark = null;
                setModalNotApprove(true);
                setisReadNotApproveRemark(false);
              }}
            >
              Not Approve
            </button>
          </div>
        </div>
      </>
    );
    QuotationData.push(rowItem);
  }
  const QuotationTable = {
    columns: [
      {
        label: "No",
        field: "No",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Quotation No.",
        field: "hq_No",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "Customer_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Price",
        field: "Price",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Sales Name",
        field: "Sales_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Approve Name",
        field: "Approve_Name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "Status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "Report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "Management",
        // sort: "asc",
        width: 50,
      },
    ],
    rows: QuotationData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Quotation(Admin)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sale</li>
                  <li className="breadcrumb-item active">Quotation (Admin)</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.hq_No}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        hq_No: e.target.value,
                      });
                      Filter.hq_No = e.target.value;
                    }}
                  />
                  <label>Quotation No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <Select
                    required
                    styles={{
                      control: base => ({
                        ...base,
                        height: 35,
                        minHeight: 39,
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 999,
                      }),
                    }}
                    options={CustomerDD}
                    filterOption={customFilter}
                    value={
                      CustomerDD?.find(el => el.value === Filter.customer_name) || { label: "--Select--", value: "" }
                    }
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        customer_name: e.value
                      });
                      Filter.customer_name = e.value;
                    }}
                  >
                    {/* <option value="">--Select--</option>
                    {CustomerDD.map((data) => {
                      return (
                        <option value={data.cus_id}>{data.cus_name}</option>
                      );
                    })} */}
                  </Select>
                  <label>Customer</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.sale_name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        sale_name: e.target.value,
                      });
                      Filter.sale_name = e.target.value;
                    }}
                  />
                  <label>Sales Name</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    options={statusDD.data}
                    value={status_list_filter}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Status
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      FilterQuotAdmin();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Quotation(Admin)</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-12">
                        <div
                          className="table-responsive"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <MDBDataTable
                            sortable={false}
                            disableRetreatAfterSorting={MDBT}
                            striped
                            className="table table-head-fixed"
                            bordered
                            hover
                            data={QuotationTable}
                          />
                          <Modal
                            size="xl"
                            className="Modal_Center_LeftRight"
                            isOpen={modalQuoNo}
                            toggle={() => {
                              setModalData({
                                ...modalData,
                                Quo_No: "",
                                Quo_Date: "",
                                Valid_Date: "",
                                Status: "",
                                Version: "",
                                Cus_Name: "",
                                Cus_contactName: "",
                                Cus_Address: "",
                                Contact_Tel: "",
                                Contact_Email: "",
                                Remark: "",
                                Vat: "",
                                typeText: "",
                                product_Name: [],
                                product_Unit: [],
                                beforeTotal: [],
                                subTotal: "",
                                grandTotal: "",
                                product: [],
                                detail: [],
                              });

                              setModalQuoNo(false);
                            }}
                          >
                            <ModalHeader
                              toggle={() => {
                                setModalData({
                                  ...modalData,
                                  Quo_No: "",
                                  Quo_Date: "",
                                  Valid_Date: "",
                                  Status: "",
                                  Version: "",
                                  Cus_Name: "",
                                  Cus_contactName: "",
                                  Cus_Address: "",
                                  Contact_Tel: "",
                                  Contact_Email: "",
                                  Remark: "",
                                  Vat: "",
                                  typeText: "",
                                  product_Name: [],
                                  product_Unit: [],
                                  beforeTotal: [],
                                  subTotal: "",
                                  grandTotal: "",
                                  product: [],
                                  detail: [],
                                });
                                setModalQuoNo(false);
                              }}
                            >
                              <h4 className="modal-title" id="modalContrat">
                                <b style={{ paddingLeft: "1rem" }}>
                                  Quotation Detail
                                </b>
                              </h4>
                            </ModalHeader>

                            <ModalBody>
                              <div
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "2rem",
                                }}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Quotation No : {modalData.Quo_No}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Quotation Date : {modalData.Quo_Date}
                                    </h4>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Valid Date : {modalData.Valid_Date}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Status : {modalData.Status}
                                    </h4>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Version : {modalData.Version}
                                    </h4>
                                  </div>
                                </div>
                                <hr />

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Customer Name : {modalData.Cus_Name}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Customer Contact point name :{" "}
                                      {modalData.Cus_contactName}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Customer Address : {modalData.Cus_Address}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12 col-md-5 col-xl-3">
                                    <h4
                                      style={{
                                        flexWrap: "nowrap",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Contact Tel : {modalData.Contact_Tel}
                                    </h4>
                                  </div>
                                  <div className="col-12 col-md-7 col-xl-6">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Contact E-mail : {modalData.Contact_Email}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      Remark : {modalData.Remark}
                                    </h4>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <h4 style={{ flexWrap: "nowrap" }}>
                                      VAT :{" "}
                                      {modalData.Vat === true
                                        ? "include VAT"
                                        : "not include VAT"}
                                    </h4>
                                  </div>
                                </div>
                                <>
                                  {modalData.product.length !== 0 ? (
                                    <div className="row">
                                      <div className="col-12 col-md-12 col-xl-12">
                                        <div
                                          className="table-responsive"
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          <Table
                                            striped
                                            bordered
                                            hover
                                            size="lg"
                                          >
                                            <thead>
                                              <tr>
                                                <th>No.</th>
                                                <th>Product Code</th>
                                                <th>Product Name</th>
                                                <th>Amount</th>
                                                <th>Net Price</th>
                                                <th>GP (%)</th>
                                                <th>Net Profit</th>
                                                <th>Total</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {modalData.product.map(
                                                (rowData, index) => (
                                                  <tr key={index}>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {index + 1}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {modalData.detail[index]
                                                        .product_code !== null
                                                        ? modalData.detail[
                                                            index
                                                          ].product_code
                                                        : "-"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {modalData.product_Name[
                                                        index
                                                      ] !== null
                                                        ? modalData
                                                            .product_Name[index]
                                                        : " - "}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {rowData.hqp_quantity.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      ) +
                                                        " " +
                                                        modalData.product_Unit[
                                                          index
                                                        ]}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {rowData.hqp_retail_price.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {rowData.hqp_gp_percent !==
                                                        "0" &&
                                                      rowData.hqp_gp_percent !==
                                                        "NaN" &&
                                                      rowData.hqp_gp_percent !==
                                                        null
                                                        ? parseFloat(
                                                            rowData.hqp_gp_percent
                                                          )
                                                            ?.toFixed(2)
                                                            .replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ","
                                                            ) || 0
                                                        : "-"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {modalData.detail[index]
                                                        .product_spp !== null &&
                                                      modalData.detail[index]
                                                        .product_spp !==
                                                        "NaN" &&
                                                      modalData.beforeTotal[
                                                        index
                                                      ]
                                                        ? (
                                                            parseFloat(
                                                              modalData
                                                                .beforeTotal[
                                                                index
                                                              ]
                                                            ) -
                                                            parseFloat(
                                                              modalData.detail[
                                                                index
                                                              ].product_spp
                                                            )
                                                          )
                                                            ?.toFixed(2)
                                                            .replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ","
                                                            ) || 0
                                                        : "-"}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {parseFloat(
                                                        modalData.beforeTotal[
                                                          index
                                                        ]
                                                      )
                                                        .toFixed(2)
                                                        .replace(
                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                          ","
                                                        ) || 0}
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                              <tr
                                                style={{
                                                  background: "#ffffff",
                                                }}
                                              >
                                                <td
                                                  colSpan={6}
                                                  style={{
                                                    visibility: "hidden",
                                                    border: "none",
                                                  }}
                                                ></td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Total
                                                </td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {parseFloat(
                                                    modalData.subTotal
                                                  )
                                                    ?.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td>
                                              </tr>
                                              <tr
                                                style={{
                                                  background: "#ffffff",
                                                }}
                                              >
                                                <td
                                                  colSpan={6}
                                                  style={{
                                                    visibility: "hidden",
                                                    border: "none",
                                                  }}
                                                ></td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Discount Final (%){" "}
                                                </td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {parseFloat(
                                                    productModal.finalDiscount
                                                  )
                                                    ?.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td>
                                              </tr>
                                              <tr
                                                style={{
                                                  background: "#ffffff",
                                                }}
                                              >
                                                <td
                                                  colSpan={6}
                                                  style={{
                                                    visibility: "hidden",
                                                    border: "none",
                                                  }}
                                                ></td>
                                                <td
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Total + Vat
                                                </td>
                                                <td
                                                  style={{ textAlign: "right" }}
                                                >
                                                  {parseFloat(
                                                    productModal.grandTotal
                                                  )
                                                    ?.toLocaleString(
                                                      undefined,
                                                      {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      }
                                                    )
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              </div>
                            </ModalBody>

                            <ModalFooter>
                              <button
                                type="button"
                                style={{ width: "100px" }}
                                className="btn btn-success"
                                onClick={async () => {
                                  setModalQuoNo(false);
                                  setProductModal({
                                    data: [],
                                    typeText: "",
                                    beforeTotal: [],
                                    subTotal: "",
                                    grandTotal: "",
                                    product: [],
                                    detail: [],
                                  });
                                  productModal.data = [];
                                  productModal.typeText = "";
                                  productModal.beforeTotal = [];
                                  productModal.subTotal = "";
                                  productModal.grandTotal = "";
                                  productModal.product = [];
                                  productModal.detail = [];
                                }}
                              >
                                ตกลง
                              </button>
                            </ModalFooter>
                          </Modal>
                          <Modal
                            size="md"
                            className="Modal_Center_LeftRight"
                            isOpen={ModalNotApprove}
                          >
                            <ModalHeader
                              toggle={() => {
                                setNotApproveRemark({
                                  ...notApproveRemark,
                                  index: null,
                                  remark: null,
                                });
                                notApproveRemark.index = null;
                                notApproveRemark.remark = null;
                                setModalNotApprove(false);
                                setisReadNotApproveRemark(false);
                              }}
                            >
                              <h4 className="modal-title" id="modalContrat">
                                <b>Reason for Not Approve</b>
                                {notApproveRemark.remark === null ||
                                notApproveRemark.remark?.trim() === "" ? (
                                  <span style={{ color: "red" }}>*</span>
                                ) : (
                                  ""
                                )}
                              </h4>
                            </ModalHeader>
                            <ModalBody>
                              <div className="row">
                                <div className="col-12">
                                  <input
                                    className="form-control"
                                    value={notApproveRemark.remark}
                                    disabled={isReadNotApproveRemark}
                                    onChange={(e) => {
                                      setNotApproveRemark({
                                        ...notApproveRemark,
                                        remark: e.target.value,
                                      });
                                      notApproveRemark.remark = e.target.value;
                                    }}
                                  />
                                </div>
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              {isReadNotApproveRemark ? (
                                <>
                                  <button
                                    type="button"
                                    style={{ width: "100px" }}
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setModalNotApprove(false);
                                      setNotApproveRemark({
                                        ...notApproveRemark,
                                        index: null,
                                        remark: null,
                                      });
                                      notApproveRemark.index = null;
                                      notApproveRemark.remark = null;
                                    }}
                                  >
                                    ยกเลิก
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    type="button"
                                    style={{ width: "100px" }}
                                    className="btn btn-success"
                                    onClick={async () => {
                                      if (
                                        notApproveRemark.remark === null ||
                                        notApproveRemark.remark?.trim() === ""
                                      ) {
                                        Swal.fire({
                                          title: "Warning.",
                                          text: "กรุณากรอกข้อมูล Reason for Not Approve",
                                          icon: "warning",
                                        });
                                      } else {
                                        setModalNotApprove(false);
                                        await handleNotApproveClick(
                                          notApproveRemark.index
                                        );
                                        setNotApproveRemark({
                                          ...notApproveRemark,
                                          index: null,
                                          remark: null,
                                        });
                                        notApproveRemark.index = null;
                                        notApproveRemark.remark = null;
                                      }
                                    }}
                                  >
                                    ตกลง
                                  </button>
                                  <button
                                    type="button"
                                    style={{ width: "100px" }}
                                    className="btn btn-danger"
                                    onClick={() => {
                                      setModalNotApprove(false);
                                      setNotApproveRemark({
                                        ...notApproveRemark,
                                        index: null,
                                        remark: null,
                                      });
                                      notApproveRemark.index = null;
                                      notApproveRemark.remark = null;
                                    }}
                                  >
                                    ยกเลิก
                                  </button>
                                </>
                              )}
                            </ModalFooter>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Table_Quotation_Admin;
