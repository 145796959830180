import React, { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import sortJsonArray from "sort-json-array";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import { useParams, useHistory } from "react-router";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Footter from "../../component/Footter";
import material_excel from "../../assets/file/upload_Material.xlsx";
import Header from "../../component/Header";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser } from "../../Utils/Common";
import { Get_font_pdf_mat } from "../../assets/font/pdf_font";
import QRCode2 from "qrcode";
function Materail(mode) {
  const [materail, setmaterail] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [type, setType] = useState([]);
  const [size, setSize] = useState([]);
  const [spect, setSpect] = useState([]);
  const [oem_data, setOem_data] = useState();
  const [company, setcompany] = useState();

  const history = useHistory();
  const params = useParams();
  const [excel, setexcel] = useState({
    name: "",
    status: null,
  });
  const [material_exel, setmaterial_exel] = useState([]);
  const [fillter, setfillter] = useState({
    no: "",
    name: "",
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    material_supplier_id: "",
    oem_id: getOem(),
  });
  const [docMatType, setDocMatType] = useState(null);
  const [docMatSpec, setDocMatSpec] = useState(null);
  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_type = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialType?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_spec = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSpec?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  var config_size = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSize?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  useEffect(() => {
    axios(config_sub)
      .then(function (response) {
        setSupplier(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_type)
      .then(function (response) {
        setType(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_spec)
      .then(function (response) {
        setSpect(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_size)
      .then(function (response) {
        setSize(response.data);
        /* console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

    axios(config_getOem)
      .then(function (response) {
        response.data.map((el) => {
          if (el.id === getOem()) {
            /* console.log(el); */
            return setOem_data(el);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    axios(config_com)
      .then(function (response) {
        /*  console.log(response.data); */
        setcompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    Getdata();
  }, []);
  /*  const data_forfillter = JSON.stringify(fillter); */

  const Getdata = async () => {
    /* console.log(fillter); */
    await axios({
      method: "post",
      url: Configs.API_URL + "/material/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: fillter,
    })
      .then(async (response) => {
        let test = sortJsonArray(response.data, "no", "asc");

        await setmaterail(test);
        //console.log(test);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const clearfillter = async () => {
    await setfillter({
      no: "",
      name: "",
      material_type_id: "",
      material_spec_id: "",
      material_size_id: "",
      material_supplier_id: "",
      oem_id: getOem(),
    });
    fillter.no = "";
    fillter.name = "";
    fillter.material_type_id = "";
    fillter.material_spec_id = "";
    fillter.material_size_id = "";
    fillter.material_supplier_id = "";
    fillter.oem_id = getOem();

    Getdata();
  };

  function fillterdata() {
    /* console.log(type); */
    Getdata();
  }

  const addpage = () => {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail/add";
  };

  function set_excel() {
    setexcel({ ...excel, status: null, name: "" });
    excel.status = null;
    excel.name = "";
    setmaterial_exel([]);
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail";
  }
  const upload_mate_file = async (e) => {
    if (e.target.files[0]) {
      setexcel({ ...excel, name: e.target.files[0].name });
      /* console.log(e.target.files[0]); */
      var data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("oem_id", getOem());

      var config_uploadExecl = {
        method: "post",
        url: Configs.API_URL + "/material/importExcel",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config_uploadExecl)
        .then(function (response) {
          //console.log(response.data);

          let num = 0;
          for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].status === false) {
              num++;
              /*  console.log(response.data[index].status); */
            }
          }
          /* console.log(num); */
          if (num !== 0) {
            setmaterial_exel(response.data);
          } else {
            Swal.fire(
              "Upload Success",
              "Your file has been Upload.",
              "success"
            ).then(() => {
              /* window.location.href =
                "/Warehouse_Management/Factory_Master_Data/Raw_Materail"; */
                setmaterial_exel(response.data);
            });
            setexcel({ ...excel, status: null, name: "" });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const delpage = (id) => {
    //console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/material/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Raw_Materail";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  };

  function specOnchange(e) {
    /*  console.log(e); */
    setfillter({
      ...fillter,
      material_spec_id: e,
    });

    for (let indexin = 0; indexin < spect.length; indexin++) {
      if (e === spect[indexin].id) {
        setDocMatSpec(spect[indexin].name);
      }
    }
  }
  function typeOnchange(e) {
    setfillter({
      ...fillter,
      material_type_id: e,
    });

    for (let indexin = 0; indexin < type.length; indexin++) {
      if (e === type[indexin].id) {
        setDocMatType(type[indexin].name);
      }
    }
  }
  const rowsData = [];
  for (var index = 0; index < materail.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            materail[index].imaget_path !== null
              ? Configs.API_URL_IMG + materail[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
    rowItem["qr"] = (
      <div style={{ textAlign: "center" }}>
        <QRCode
          value={materail[index].no}
          size={60}
          fgColor={"#000000"}
          bgColor={"#ffffff"}
          level={"M"}
          renderAs={"canvas"}
          includeMargin={false}
          imageSettings={{
            src: logoTTT.imgs,
            height: 10,
            width: 10,
            x: null,
            y: null,
            excavate: true,
          }}
        />
      </div>
    );
    rowItem["mat_code"] = materail[index].no;

    /*  rowItem["unit_by"] = materail[index].material_unit_id; */

    for (let indexin = 0; indexin < spect.length; indexin++) {
      if (materail[index].material_spec_id === spect[indexin].id) {
        rowItem["spec"] = spect[indexin].name;
      } /* else {
        rowItem["spec"] = "none"
      } */
    }
    for (let indexin = 0; indexin < size.length; indexin++) {
      if (materail[index].material_size_id === size[indexin].id) {
        rowItem["mat_size"] = size[indexin].name;
      } /* else{
      rowItem["mat_size"] = "none"
    } */
    }
    for (let indexin = 0; indexin < type.length; indexin++) {
      if (materail[index].material_type_id === type[indexin].id) {
        rowItem["mat_type"] = type[indexin].name;
      } /* else{
    rowItem["mat_type"] = "none"
  } */
    }
    for (let indexin = 0; indexin < supplier.length; indexin++) {
      if (materail[index].material_supplier_id === supplier[indexin].id) {
        rowItem["mat_pupp"] = supplier[indexin].name;
      } /* else {
        rowItem["mat_pupp"] = "none";
      } */
    }
    rowItem["name"] = materail[index].name;
    rowItem["buy_price"] =
      materail[index].buy_price === null ? "0" : materail[index].buy_price;
    rowItem["Weigh_Piece"] =
      materail[index].weight_piece === null
        ? "0"
        : materail[index].weight_piece;
    rowItem["Sup_lead_time"] =
      materail[index].supplier_lead_time === null
        ? "0"
        : materail[index].supplier_lead_time;
    rowItem["Min_lot"] =
        materail[index].minimum_lot === null ? 0 : materail[index].minimum_lot;
    rowItem["Min_lot_sup"] =
      materail[index].minimum_order_supplier === null
        ? "0"
        : materail[index].minimum_order_supplier;
 
    rowItem["mat_unit"] = materail[index].material_unit;
    rowItem["description"] = materail[index].description;
    rowItem["remark"] = materail[index].remark;
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Raw_Materail/read/" +
              materail[index].id
            }
            key={materail[index].id}
            className=" btn btn-xs " /* onClick={readpage.bind(this,materail[index].id)} */
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Raw_Materail/edit/" +
              materail[index].id
            }
            key={materail[index].id}
            className=" btn btn-xs " /* onClick={editpage.bind(this,materail[index].id)} */
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={materail[index].id}
            className="btn btn-xs "
            onClick={delpage.bind(this, materail[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No_",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mat_code",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
        label: "QR",
        field: "qr",
        sort: "asc",
        width: 50,
      },

      {
        label: "Mat_Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mat_Size",
        field: "mat_size",
        sort: "asc",
        width: 50,
      },
      {
        label: "Buy_Price",
        field: "buy_price",
        sort: "asc",
        width: 50,
      },
      
      
      {
        label: "Mat_Unit",
        field: "mat_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "mat_pupp",
        sort: "asc",
        width: 50,
      },

      {
        label: "Weigh_Piece",
        field: "Weigh_Piece",
        sort: "asc",
        width: 50,
      },

      {
        label: "Sup_lead_time",
        field: "Sup_lead_time",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min_Lot",
        field: "Min_lot",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min_Lot_Sup",
        field: "Min_lot_sup",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mat_Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  function filterTypeSpec(data) {
    //console.log(data);
    var Com_logo = Configs.API_URL_IMG ?"":`${Configs.API_URL_IMG + company.logo_path}`;
    var OEM_logo = Configs.API_URL_IMG ?"":`${Configs.API_URL_IMG + oem_data.logo_path}`;

    const doc = new jsPDF("p", "mm", "a4");

    for (let index = 0; index < data.length; index = index + 8) {
      //console.log("start");
      doc.setDrawColor(0);
      doc.setFillColor("#272727");
      doc.rect(0, 0, 1080, 31, "F");

      doc.addImage(Com_logo, "JPEG", 1, 1, 29, 29);
      doc.addImage(OEM_logo, "JPEG", 180, 1, 29, 29);

      doc.setTextColor(255, 255, 255);
      doc.addFileToVFS(
        "ROCK.TTF", Get_font_pdf_mat()
      );

      doc.addFont("ROCK.TTF", "custom", "normal");

      doc.setFont("custom");
      //doc.setFont('bold')
      doc.setFontSize(15);
      const a = "Material Type";
      doc.text(45, 10, a);

      doc.setTextColor(255, 255, 255);
      //doc.setFont('bold')
      doc.setFontSize(35);
      doc.text(45, 25, docMatType);

      doc.setTextColor(255, 255, 255);
      doc.setFontSize(15);
      //doc.setFont('bold')
      doc.text(120, 10, "Material Spec");

      doc.setTextColor(255, 255, 255);
      doc.setFontSize(35);
      //.setFont('bold')
      doc.text(120, 25, docMatSpec);
      //box 1
      let img_qr = "";
      /* console.log(data[index+1].no.toString()); */
      let tepm = "";
      if (index < data.length) {
        QRCode2.toDataURL(data[index].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 1, 32, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        //doc.setFont('bold')
        doc.text(52, 37, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index].material_size_id === size[indexin].id) {
            doc.text(53, 60, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        //doc.setFont('bold')
        doc.text(7, 85, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(8, 90, data[index].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        //doc.setFont('bold')
        doc.text(52, 85, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index].name === null) {
          doc.text(53, 90, "None");
        } else {
          doc.text(53, 90, data[index].name);
        }
        doc.rect(0, 32, 105, 60);
      }

      // box 2
      if (index + 1 < data.length) {
        QRCode2.toDataURL(data[index + 1].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 103, 32, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(152, 37, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index + 1].material_size_id === size[indexin].id) {
            doc.text(153, 60, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(109, 85, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(110, 90, data[index + 1].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(152, 85, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index + 1].name === null) {
          doc.text(153, 90, "None");
        } else {
          doc.text(153, 90, data[index + 1].name);
        }
        doc.rect(105, 32, 105, 60);
      }
      //box 3
      if (index + 2 < data.length) {
        QRCode2.toDataURL(data[index + 2].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 1, 93, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(52, 98, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index + 2].material_size_id === size[indexin].id) {
            doc.text(52, 125, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(7, 148, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(8, 153, data[index + 2].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(52, 148, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index + 2].name === null) {
          doc.text(53, 153, "None");
        } else {
          doc.text(53, 153, data[index + 2].name);
        }
        doc.rect(0, 92, 105, 63);
      }

      //box 4
      if (index + 3 < data.length) {
        QRCode2.toDataURL(data[index + 3].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 103, 93, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(152, 98, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index + 3].material_size_id === size[indexin].id) {
            doc.text(153, 125, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(109, 148, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(110, 153, data[index + 3].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(152, 148, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index + 3].name === null) {
          doc.text(153, 153, "None");
        } else {
          doc.text(153, 153, data[index + 3].name);
        }
        doc.rect(105, 92, 105, 63);
      }

      //box 5
      if (index + 4 < data.length) {
        QRCode2.toDataURL(data[index + 4].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 1, 156, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(52, 161, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index + 4].material_size_id === size[indexin].id) {
            doc.text(53, 188, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(7, 211, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(8, 216, data[index + 4].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(52, 211, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index + 4].name === null) {
          doc.text(53, 216, "None");
        } else {
          doc.text(53, 216, data[index + 4].name);
        }
        doc.rect(0, 155, 105, 63);
      }

      //box 6

      if (index + 5 < data.length) {
        QRCode2.toDataURL(data[index + 5].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 103, 156, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(152, 161, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index + 5].material_size_id === size[indexin].id) {
            doc.text(153, 188, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(109, 211, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(110, 216, data[index + 5].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(152, 211, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index + 5].name === null) {
          doc.text(153, 216, "None");
        } else {
          doc.text(153, 216, data[index + 5].name);
        }
        doc.rect(105, 155, 105, 63);
      }

      //box 7
      if (index + 6 < data.length) {
        QRCode2.toDataURL(data[index + 6].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 1, 218, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(52, 223, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index + 6].material_size_id === size[indexin].id) {
            doc.text(53, 250, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(7, 273, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(8, 278, data[index + 6].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(52, 273, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index + 6].name === null) {
          doc.text(53, 278, "None");
        } else {
          doc.text(53, 278, data[index + 6].name);
        }
        doc.rect(0, 218, 105, 63);
      }

      //box 8

      if (index + 7 < data.length) {
        QRCode2.toDataURL(data[index + 7].no.toString(), function (err, url) {
          img_qr = url;
        });
        doc.addImage(img_qr, "JPEG", 103, 218, 50, 50);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(15);
        doc.text(152, 223, "Material Size");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(52);

        for (let indexin = 0; indexin < size.length; indexin++) {
          if (data[index + 7].material_size_id === size[indexin].id) {
            doc.text(153, 250, size[indexin].name);
          }
        }

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(109, 273, "Material No");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.text(110, 278, data[index + 7].no);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        doc.text(152, 273, "Material Name");
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        if (data[index + 7].name === null) {
          doc.text(153, 278, "None");
        } else {
          doc.text(153, 278, data[index + 7].name);
        }
        doc.rect(105, 218, 105, 63);
      }

      if (index + 8 < data.length) {
        //console.log("next");
        doc.addPage("a4", "h");
      }
    }
    window.open(doc.output("bloburl"));
    //doc.save("pdf");
  }

  function downloadReport() {
    const tempMatData = [];
    const tempFilter = {
      no: "",
      name: "",
      material_type_id: fillter.material_type_id,
      material_spec_id: fillter.material_spec_id,
      material_size_id: "",
      material_supplier_id: "",
      oem_id: getOem(),
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/material/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempFilter,
    })
      .then(async (response) => {
        filterTypeSpec(response.data);
        /*    response.data.forEach(materail => {
             tempMatData.push(materail )
           });
   */
            //console.log(tempMatData); 
      })
      .catch(async (error) => {
        console.log(error);
      });

    /*  filterTypeSpec(); */
    /* console.log(tempMatData); */
    /*  console.log(docMatSpec);
     */
  }

  function Document2(params) {}

   function PoReport () {
    var Com_logo = `${Configs.API_URL_IMG+company.logo_path}`
  
    const doc = new jsPDF("p", "mm", "a4");
    
    doc.addImage(Com_logo, 'JPEG', 20, 10, 30, 30)
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(12)
    doc.addFont('bold')
    //doc.addFont('Angsana New', 'Angsana New','bold');
    //doc.setFont('Angsana New');
    doc.text(52, 16, 'SHIMIZU Manufacturing Co., Ltd.')
    
    doc.setTextColor(0, 0, 0)
    doc.setFontSize(12)
    doc.addFont('ROCKI.TTF', 'custom', 'normal');

    doc.setFont('custom');
    doc.text(52, 20, '571 Prachapattana Road, Tabyao, Ladkrabang')

    doc.setTextColor(0, 0, 0)
    doc.addFont('D:/Project/PMRP/App/src/assets/font/ROCKI.TTF', "ROCKI", "normal");
    doc.setFont("ROCKI"); // set font
    doc.setFontSize(10);
    doc.text(52, 24, 'เลขประจำตัวผู้เสียภาษี 0105539003283')


    doc.addFont("test/reference/PTSans.ttf", "PTSans", "normal");
    doc.setFont("PTSans"); // set font
    doc.setFontSize(10);
    doc.text("А ну чики брики и в дамки!",100,100)
    
    window.open(doc.output('bloburl'));
  }

  function load(){
    var status = "0";
    Swal.fire({
      title: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 500,
      onOpen: () => {
         Swal.showLoading();
         status = "1";
      }, 
  
    }).then(() =>{
      if(status == "1"){
     
        reportQR();
       }else{
         alert("tttt");
       }
    });
  
    
  }

  function reportQR(){
    
  var Com_logo = `${Configs.API_URL_IMG+company.logo_path}`;
  var OEM_logo = `${Configs.API_URL_IMG+oem_data.logo_path}`;
   var prod_img = `${Configs.API_URL_IMG}`
 
  const doc = new jsPDF("p", "mm", "a4");
  let img_qr = "";
  for (let index = 0; index < materail.length; index = index + 8) {
 
   doc.setDrawColor(0)
   doc.setFillColor('#272727')
   doc.rect(0, 0, 1080, 31, 'F')
 
   doc.addImage(Com_logo, 'JPEG', 1, 1, 29, 29)
   doc.addImage(OEM_logo, 'JPEG', 180, 1, 29, 29) 
  /*  doc.addImage(imgData, 'JPEG',180, 1, 29, 29) */
  doc.setTextColor(255 , 255 , 255 )
  doc.addFileToVFS('ROCK.TTF', Get_font_pdf_mat());
 
   doc.addFont('ROCK.TTF', 'Rocki','normal');
 
   doc.setFont('Rocki');
  doc.setFontSize(40)
  //doc.addFileToVFS("ROCKI.ttf", arimoBase64);
  //doc.addFont('ROCK', 'ROCKI','normal')
  //doc.setFont('ROCKI')
  
  doc.text(42, 20, 'Materail LIST')
  //console.log(materail);
  //box 1
   if (index < materail.length) {
     QRCode2.toDataURL(materail[index].no.toString(), function (err, url) {
       img_qr = url
     })
     doc.addImage(img_qr, 'JPEG', 10, 32, 40, 40)
     
     doc.addImage( prod_img+materail[index].imaget_path , 'JPEG' ,48,35,30,30)
     doc.rect(48,37,30,30)
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(12)
     doc.addFont('Rocki', 'bold');
     doc.setFont('Rocki');
     doc.text(15, 73, 'MAT Code.')
 
     doc.setTextColor(255, 0, 0)
     doc.setFontSize(34)
     doc.addFont('Rocki', 'bold');
     doc.setFont('Rocki');
     doc.text(78, 82, materail[index].no,'right')
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(9.3)
     //doc.addFont('Rocki', 'normal');
     //doc.setFont('Rocki');
     doc.text(78, 88, 'MAT Name. : '+materail[index].name,'right')
     
     doc.setDrawColor(192,192,192)
     doc.rect(0,32,105,60)
   }
 
   //box 2
   
   if (index+1 < materail.length) {
     QRCode2.toDataURL(materail[index+1].no.toString(), function (err, url) {
       img_qr = url
     })
     doc.addImage(img_qr, 'JPEG', 115, 32, 40, 40)
     
     doc.addImage( prod_img+materail[index+1].imaget_path , 'JPEG' ,153,35,30,30)
     doc.rect(153,37,30,30)
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(12)
     //doc.addFont('App/public/dist/font/ROCKI.TTF.ttf', 'Rocki')
     //doc.addFont('Rocki', 'normal');
     //doc.setFont('Rocki');
     doc.text(120, 73, 'MAT Code.')
 
     //doc.addFont('App/public/dist/font/ROCKI.TTF.ttf', 'Rocki')
     doc.setTextColor(255, 0, 0)
     doc.setFontSize(34)
     doc.addFont('Rocki', 'bold');
     doc.setFont('Rocki');
     doc.text(185, 82, materail[index+1].no,'right') //x 120
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(9.3)
     /* doc.addFont('Rocki', 'normal');
     doc.setFont('Rocki'); */
     doc.text(185, 88, 'MAT Name. '+materail[index+1].name,'right') //120
     doc.setDrawColor(192,192,192)
     doc.rect(105,32,105,60)
   }
 
   //box 3
 
   if (index+2 < materail.length) {
     QRCode2.toDataURL(materail[index+2].no.toString(), function (err, url) {
       img_qr = url
     })
     doc.addImage(img_qr, 'JPEG', 10, 92, 40, 40)
     
     doc.addImage( prod_img+materail[index+2].imaget_path , 'JPEG' ,48,95,30,30)
     doc.rect(48,97,30,30)
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(12)
   /*   doc.addFont('Rocki', 'normal');
     doc.setFont('Rocki'); */
     doc.text(15, 133, 'MAT Code.')
 
     doc.setTextColor(255, 0, 0)
     doc.setFontSize(34)
     doc.addFont('Rocki', 'bold');
     doc.setFont('Rocki');
     doc.text(78, 142, materail[index+2].no,'right')
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(9.3)
    /*  doc.addFont('Rocki', 'normal');
     doc.setFont('Rocki'); */
     doc.text(78, 148, 'MAT Name. : '+materail[index+2].name,'right')
     
     doc.setDrawColor(211,211,211)
     doc.rect(0,92,105,60)
   }
   //box 4
   if (index+3 < materail.length) {
     QRCode2.toDataURL(materail[index+3].no.toString(), function (err, url) {
       img_qr = url
     })
     doc.addImage(img_qr, 'JPEG', 115, 92, 40, 40)
     
     doc.addImage( prod_img+materail[index+3].imaget_path , 'JPEG' ,153,95,30,30)
     doc.rect(153,97,30,30)
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(12)
     /* doc.addFont('Rocki', 'normal');
     doc.setFont('Rocki'); */
     doc.text(120, 133, 'MAT Code.')
 
     doc.setTextColor(255, 0, 0)
     doc.setFontSize(34)
     doc.addFont('Rocki', 'bold');
     doc.setFont('Rocki');
     doc.text(185, 142, materail[index+3].no,'right')
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(9.3)
    /*  doc.addFont('Rocki', 'normal');
     doc.setFont('Rocki'); */
     doc.text(185, 148, 'MAT Name. : '+materail[index+3].name,'right')
     
     doc.setDrawColor(211,211,211)
     doc.rect(105,92,105,60)
   }
   //box 5
 
   if (index+4 < materail.length) {
     QRCode2.toDataURL(materail[index+4].no.toString(), function (err, url) {
       img_qr = url
     })
     doc.addImage(img_qr, 'JPEG', 10, 152, 40, 40)
     
     doc.addImage( prod_img+materail[index+4].imaget_path , 'JPEG' ,48,155,30,30)
     doc.rect(48,157,30,30)
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(12)
     /* doc.addFont('Rocki', 'normal');
     doc.setFont('Rocki'); */
     doc.text(15, 193, 'MAT Code.')
 
     doc.setTextColor(255, 0, 0)
     doc.setFontSize(34)
     doc.addFont('Rocki', 'bold');
     doc.setFont('Rocki');
     doc.text(78, 202, materail[index+4].no,'right')
 
     doc.setTextColor(0, 0, 0)
     doc.setFontSize(9.3)
     /* doc.addFont('Rocki', 'normal');
     doc.setFont('Rocki'); */
     doc.text(78, 208, 'MAT Name. : '+materail[index+4].name,'right')
     
     doc.setDrawColor(211,211,211)
     doc.rect(0,152,105,60)
   }
 //box 6
 
 if (index+5 < materail.length) {
   QRCode2.toDataURL(materail[index+5].no.toString(), function (err, url) {
     img_qr = url
   })
   doc.addImage(img_qr, 'JPEG', 115, 152, 40, 40)
   
   doc.addImage( prod_img+materail[index+5].imaget_path , 'JPEG' ,153,155,30,30)
   doc.rect(153,157,30,30)
 
   doc.setTextColor(0, 0, 0)
   doc.setFontSize(12)
   doc.addFont('Rocki', 'bold');
   doc.setFont('Rocki');
   doc.text(120, 193, 'MAT Code.')
 
   doc.setTextColor(255, 0, 0)
   doc.setFontSize(34)
  /*  doc.addFont('Rocki', 'normal');
   doc.setFont('Rocki'); */
   doc.text(185, 202, materail[index+5].no,'right')
 
   doc.setTextColor(0, 0, 0)
   doc.setFontSize(9.3)
 /*   doc.addFont('Rocki', 'normal');
   doc.setFont('Rocki'); */
   doc.text(185, 208, 'MAT Name. : '+materail[index+5].name,'right')
   
   doc.setDrawColor(211,211,211)
   doc.rect(105,152,105,60)
 }
 //box 7
 if (index+6 < materail.length) {
   QRCode2.toDataURL(materail[index+6].no.toString(), function (err, url) {
     img_qr = url
   })
   doc.addImage(img_qr, 'JPEG', 10, 212, 40, 40)
   
   doc.addImage( prod_img+materail[index+6].imaget_path , 'JPEG' ,48,215,30,30)
   doc.rect(48,217,30,30)
 
   doc.setTextColor(0, 0, 0)
   doc.setFontSize(12)
   doc.addFont('Rocki', 'bold');
   doc.setFont('Rocki');
   doc.text(15, 253, 'MAT Code.')
 
   doc.setTextColor(255, 0, 0)
   doc.setFontSize(34)
 /*   doc.addFont('Rocki', 'normal');
   doc.setFont('Rocki'); */
   doc.text(78, 262, materail[index+6].no,'right')
 
   doc.setTextColor(0, 0, 0)
   doc.setFontSize(9.3)
  /*  doc.addFont('Rocki', 'normal');
   doc.setFont('Rocki'); */
   doc.text(78, 268, 'MAT Name. : '+materail[index+6].name,'right')
   
   doc.setDrawColor(211,211,211)
   doc.rect(0,212,105,60)
 }
 //box 8
 
 if (index+7 < materail.length) {
   QRCode2.toDataURL(materail[index+7].no.toString(), function (err, url) {
     img_qr = url
   })
   doc.addImage(img_qr, 'JPEG', 115, 212, 40, 40)
   
   doc.addImage( prod_img+materail[index+7].imaget_path , 'JPEG' ,153,215,30,30)
   doc.rect(153,217,30,30)
 
   doc.setTextColor(0, 0, 0)
   doc.setFontSize(12)
   doc.addFont('Rocki', 'bold');
   doc.setFont('Rocki');
   doc.text(120, 253, 'MAT Code.')
 
   doc.setTextColor(255, 0, 0)
   doc.setFontSize(34)
   /* doc.addFont('Rocki', 'normal');
   doc.setFont('Rocki'); */
   doc.text(185, 262, materail[index+7].no,'right')
 
   doc.setTextColor(0, 0, 0)
   doc.setFontSize(9.3)
  /*  doc.addFont('Rocki', 'normal');
   doc.setFont('Rocki'); */
   doc.text(185, 268, 'MAT Name. : '+materail[index+7].name,'right')
   
   doc.setDrawColor(192,192,192)
   doc.rect(105,212,105,60)
 }
 
  if (index + 8 < materail.length) {doc.addPage("a4", "h") }
 }
   
  window.open(doc.output('bloburl'));
 //doc.save("pdf");
  
  }
 
  return (
    <div className="wrapper">
    <Header />
    <Sidebar menu="warehouse" activemenu="materail" submenu="factmaster" />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Raw Material</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Raw Material</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={fillter.no}
                    onChange={(e) => {
                      setfillter({ ...fillter, no: e.target.value });
                    }}
                  />
                  <label htmlFor="">Mat No.</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={fillter.name}
                    onChange={(e) => {
                      setfillter({ ...fillter, name: e.target.value });
                    }}
                  />
                  <label htmlFor="">Mat Name</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  {" "}
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_type_id}
                    onChange={(e) => typeOnchange(e.target.value)}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select Type{" "}
                    </option>
                    {type.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Type</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  {" "}
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_spec_id}
                    onChange={(e) => specOnchange(e.target.value)}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select Spec{" "}
                    </option>
                    {spect.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Spec</label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group">
                  {" "}
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_size_id}
                    onChange={(e) => {
                      setfillter({
                        ...fillter,
                        material_size_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select Size{" "}
                    </option>
                    {size.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Size</label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group">
                  {" "}
                  <select
                    className="custom-select select2 form-control"
                    value={fillter.material_supplier_id}
                    onChange={(e) => {
                      setfillter({
                        ...fillter,
                        material_supplier_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select Supplier{" "}
                    </option>
                    {supplier.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">Mat Supplier</label>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2 ">
              <br />
              <div className=" col-sm-2  ">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm "
                  onClick={fillterdata}
                >
                  Search
                </button>
              </div>

              <div className=" col-sm-2  ">
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm "
                  onClick={() => {
                    clearfillter();
                  }}
                >
                  Clear
                </button>
              </div>

              <div className=" col-sm-2  ">
                <button
                  type="button"
                  className="btn btn-block btn-primary btn-sm "
                  onClick={addpage}
                >
                  Add
                </button>
              </div>

              <div className=" col-sm-2">
                {/* <button
                  type="button"
                  className="btn btn-block btn-primary btn-sm "
                >
                  Import Excel
                </button> */}

                <button
                  type="button"
                  className="btn btn-block btn-primary btn-sm"
                  data-toggle="modal"
                  data-target="#modal-default"
                >
                  Import Excel
                </button>

              
              </div>
          
              <div className=" col-sm-2  ">
                <a onClick = {load}>
                <button
                  type="button"
                  className="btn btn-block btn-info btn-sm"
                  //onClick={reportQR}
                  disabled={false}
                >
                  Report QR
                </button>
                </a>
              </div>
              <div className=" col-sm-2">
              <a  
                  href={material_excel}
                  download="upload_Material.xlsx"
                  style={{ fontSize: "" }}
                >
                  {" "}
                  <button className="btn btn-block btn-info btn-sm">
                  download template.xls
                  </button>
           
                </a>
                </div>
              {/* </div>
              <div className=" col-sm-2  ">
              <div className="form-group ">
                 <button
                  type="button"
                  className="btn btn-block btn-info"
                  onClick={PoReport}
                  disabled={false}
                >
                  PO QR
                </button>
              </div> */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Material</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " /*  style={{ height: "100%" }} */
            >
              <MDBDataTable
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {/* <QRCode
      
      value={"Ctrl+1"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+2"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+3"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+4"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+5"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+6"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />

<QRCode
      
      value={"Ctrl+7"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />
<QRCode
      
      value={"Ctrl+8"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    />
    <QRCode
      
      value={"Ctrl+9"}
      size={120}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"canvars"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 20,
              width: 20,
              x: null,
              y: null,
              excavate: true,
            }
      }
    /> */}
            </div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}<div className="modal fade" id="modal-default">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Uplaod Master Material</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={set_excel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 ">
                  <div className="none-nolmalinput">
                    <label htmlFor="">Meterail file:</label>
                    <small style={{ color: "red" }}>
                      ** Excel from template **
                    </small>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          /* disabled={} */
                          className="custom-file-input"
                          id="exampleInputFile"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={upload_mate_file}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                        >
                          {excel.name !== null &&
                          excel.name !== "" &&
                          excel.name !== undefined
                            ? excel.name
                            : "Select File"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <div
                    className="table-responsive" /* style={{ height: "800px" }} */
                  >
                    <MDBTable striped hover bordered small searching={true}>
                      <MDBTableHead>
                        <tr>
                          <th>No.</th>
                          <th>Model</th>
                          <th>Status</th>
                          <th>Error</th>
                          <th>Update</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {material_exel.map((el, index) => {
                          if(material_exel.filter((x) => !x.status_update).length > 0){
                            var status_up = false;
                          }else{
                            var status_up = true;
                          }
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{el.model}</td>
                              <td>
                                {el.status ? (
                                  <span style={{ color: "green" }}>True</span>
                                ) : (
                                  <span style={{ color: "red" }}>False</span>
                                )}
                              </td>
                              <td>{el.error}</td>
                              <td>{status_up ? <span style={{color:"green"}}>Success</span>:<span style={{color:"red"}}>False</span>}</td>
                            </tr>
                          );
                          


                        })}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <h3>
                    {excel.status !== null ? (
                      <span>
                        {excel.status === true
                          ? "Upload Success"
                          : "Upload Fail"}
                      </span>
                    ) : (
                      ""
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={set_excel}
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
      </div>
      
      <Footter />
    </div>

  );
}
export default Materail;
