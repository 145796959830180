import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";

function WinSpeedPO(params) {
  const [po, setPo] = useState([]);

  /* console.log(getUser().com); */
  const [filter_po, setFilter_po] = useState({
    po_no: "",
    company_id: getUser().com,
  });

  const GetPo = async () => {
    //console.log(filler_project);
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    axios({
      method: "post",
      url: Configs.API_URL + "/api/winSpeed/filterPO",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_po,
    })
      .then(function (response) {
        //console.log(response.data);
        setPo(response.data);
        Swal.close();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetPo();
  }, []);

  const clearFilter = async () => {
    setFilter_po({
      ...filter_po,
      po_no: "",
    });

    filter_po.po_no = "";
    GetPo();
  };
  const rowsData = [];

  for (var index = 0; index < po.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["COM_ID"] = po[index].COM_ID;
    rowItem["PO_ID"] = po[index].PO_ID;
    rowItem["PO_CODE"] = po[index].PO_CODE;
    rowItem["PO_DATE"] = po[index].PO_DATE;
    rowItem["RV_DATE"] = po[index].RV_DATE;
    rowItem["SUP_ID"] = po[index].SUP_ID;
    rowItem["SUP_NAME"] = po[index].SUP_NAME;
    rowItem["SUP_ADD"] = po[index].SUP_ADD;
    rowItem["SUP_ROAD"] = po[index].SUP_ROAD;
    rowItem["SUP_DISTRICT"] = po[index].SUP_DISTRICT;
    rowItem["SUP_PREFECTURE"] = po[index].SUP_PREFECTURE;
    rowItem["PAYMENT"] = po[index].PAYMENT;
    rowItem["VAT_PERCENT"] = po[index].VAT_PERCENT;
    rowItem["STS_NAME"] = po[index].STS_NAME;
    rowItem["COMPLETE"] = po[index].COMPLETE;
    rowItem["DEST_ADD"] = po[index].DEST_ADD;
    rowItem["DEST_NAME"] = po[index].DEST_NAME;
    rowItem["DEST_DISTRICT"] = po[index].DEST_DISTRICT;
    rowItem["DEST_PREFECTURE"] = po[index].DEST_PREFECTURE;
    rowItem["DEST_CITY"] = po[index].DEST_CITY;
    rowItem["DEST_ZIPCODE"] = po[index].DEST_ZIPCODE;
    rowItem["DEST_TEL"] = po[index].DEST_TEL;
    rowItem["DEST_FAX"] = po[index].DEST_FAX;
    rowItem["APPROVE"] = po[index].APPROVE;
    rowItem["POST"] = po[index].POST;
    rowItem["P"] = po[index].P;
    rowItem["ETD_DATE"] = po[index].ETD_DATE;
    rowItem["ETA_DATE"] = po[index].ETA_DATE;
    rowItem["IN_DATE"] = po[index].IN_DATE;
    rowItem["MAT_COMPLETE"] = po[index].MAT_COMPLETE;
    rowItem["INV_NO"] = po[index].INV_NO;
    rowItem["CREAE_USER"] = po[index].CREAE_USER;
    rowItem["CREATE_DATE"] = po[index].CREATE_DATE;

    /* rowItem["mgt"] = (
        <div className="row">
          <div className="col-4">
            <a
               href={"/Warehouse_Management/Factory_Master_Data/Loop/read/"+ loop[index].id}
              key={loop[index].id}
              className=" btn btn-xs "
            >
              <i class="fas fa-eye"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
                href={"/Warehouse_Management/Factory_Master_Data/Loop/edit/"+ loop[index].id}
               key={loop[index].id}
              className=" btn btn-xs "
            >
              {" "}
              <i class="fas fa-pencil-alt"></i>{" "}
            </a>
          </div>
          <div className="col-4">
            <a
               key={loop[index].id}
              className=" btn btn-xs "
               onClick={deldata.bind(this,loop[index].id)}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ); */
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "COM_ID",
        field: "COM_ID",
        sort: "asc",
        width: 50,
      },
      {
        label: "PO_ID",
        field: "PO_ID",
        sort: "asc",
        width: 50,
      },
      /*  {
        label: "PO_CODE",
        field: "PO_CODE",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "PO_DATE",
        field: "PO_DATE",
        sort: "asc",
        width: 50,
      },
      {
        label: "RV_DATE",
        field: "RV_DATE",
        sort: "asc",
        width: 50,
      },
      {
        label: "SUP_ID",
        field: "SUP_ID",
        sort: "asc",
        width: 50,
      },
      {
        label: "SUP_NAME",
        field: "SUP_NAME",
        sort: "asc",
        width: 50,
      },
      {
        label: "SUP_ADD",
        field: "SUP_ADD",
        sort: "asc",
        width: 50,
      },
      {
        label: "SUP_ROAD",
        field: "SUP_ROAD",
        sort: "asc",
        width: 50,
      },
      {
        label: "SUP_DISTRICT",
        field: "SUP_DISTRICT",
        sort: "asc",
        width: 50,
      },
      {
        label: "SUP_PREFECTURE",
        field: "SUP_PREFECTURE",
        sort: "asc",
        width: 50,
      },
      {
        label: "PAYMENT",
        field: "PAYMENT",
        sort: "asc",
        width: 50,
      },
      {
        label: "VAT_PERCENT",
        field: "VAT_PERCENT",
        sort: "asc",
        width: 50,
      },
      {
        label: "STS_NAME",
        field: "STS_NAME",
        sort: "asc",
        width: 50,
      },
      {
        label: "COMPLETE",
        field: "COMPLETE",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_ADD",
        field: "DEST_ADD",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_NAME",
        field: "DEST_NAME",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_DISTRICT",
        field: "DEST_DISTRICT",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_PREFECTURE",
        field: "DEST_PREFECTURE",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_CITY",
        field: "DEST_CITY",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_ZIPCODE",
        field: "DEST_ZIPCODE",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_TEL",
        field: "DEST_TEL",
        sort: "asc",
        width: 50,
      },
      {
        label: "DEST_FAX",
        field: "DEST_FAX",
        sort: "asc",
        width: 50,
      },
      {
        label: "APPROVE",
        field: "APPROVE",
        sort: "asc",
        width: 50,
      },
      {
        label: "POST",
        field: "POST",
        sort: "asc",
        width: 50,
      },
      {
        label: "P",
        field: "P",
        sort: "asc",
        width: 50,
      },
      {
        label: "ETD_DATE",
        field: "ETD_DATE",
        sort: "asc",
        width: 50,
      },
      {
        label: "ETA_DATE",
        field: "ETA_DATE",
        sort: "asc",
        width: 50,
      },
      {
        label: "IN_DATE",
        field: "IN_DATE",
        sort: "asc",
        width: 50,
      },
      {
        label: "MAT_COMPLETE",
        field: "MAT_COMPLETE",
        sort: "asc",
        width: 50,
      },
      {
        label: "INV_NO",
        field: "INV_NO",
        sort: "asc",
        width: 50,
      },
      {
        label: "CREATE_USER",
        field: "CREATE_USER",
        sort: "asc",
        width: 50,
      },
      {
        label: "CREATE_DATE",
        field: "CREATE_DATE",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>WINSpeed PO</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">WINSpeed PO</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filter_po.po_no}
                    onChange={(e) => {
                      setFilter_po({
                        ...filter_po,
                        po_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">PO No.</label>{" "}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetPo();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*  <div className="container-fluid">
            <div className="row">
              
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div> */}
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WINSpeed PO</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
      </div>
    </div>
  );
}
export default WinSpeedPO;
