import React from "react";

function ToolWarehouse({ actionActive, setActionActive, position, viewOnly, offsetSceneRef }) {

  function handleActionBtn(action) {
    // offsetSceneRef.current = true;
    setActionActive({
      btn: action,
    });
    actionActive.btn = action;
  };

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1,
        ...position,
        gap: "5px",
      }}
      hidden={viewOnly}
    >
      {actionActive.btn != "2D" ? (
        <div className="card p-2" style={{ gap: "10px", boxShadow: "5px 4px 15px 0.1px rgba(57, 67, 71, 0.473)" }}>
          <button
            id={"zoom-out-button" + (actionActive.btn === "2D" ? "-active" : "")}
            className="zoom-out-button"
            onClick={() => handleActionBtn("2D")}
          >
            <p style={{ margin: actionActive.btn === "2D" ? 0 : "", fontWeight: "bold", fontSize: "24px" }}>2D</p>
          </button>
          <button
            id={"zoom-out-button" + (actionActive.btn === "move" ? "-active" : "")}
            className="zoom-out-button"
            onClick={() => handleActionBtn("move")}
          >
            <i className="fas fa-arrows-alt"></i>
          </button>
          <button
            id={"zoom-out-button" + (actionActive.btn === "rotate" ? "-active" : "")}
            className="zoom-out-button"
            onClick={() => handleActionBtn("rotate")}
          >
            <i className="fas fa-sync-alt"></i>
          </button>
          <button
            id={"zoom-out-button" + (actionActive.btn === "scale" ? "-active" : "")}
            className="zoom-out-button"
            onClick={() => handleActionBtn("scale")}
          >
            <i className="fas fa-compress-alt"></i>
          </button>
          <button
            id={"zoom-out-button" + (actionActive.btn === "camera" ? "-active" : "")}
            className="zoom-out-button"
            onClick={() => handleActionBtn("camera")}
          >
            <i className="fas fa-mouse-pointer"></i>
          </button>
        </div>
      ) : (
        <div className="card p-2" style={{ gap: "10px", boxShadow: "5px 4px 15px 0.1px rgba(57, 67, 71, 0.473)" }}>
          <button
            id={"zoom-out-button" + (actionActive.btn === "camera" ? "-active" : "")}
            className="zoom-out-button"
            onClick={() => handleActionBtn("camera")}
          >
            <p style={{ margin: actionActive.btn === "camera" ? 0 : "", fontWeight: "bold", fontSize: "24px" }}>3D</p>
          </button>
        </div>
      )}
    </div>
  );
  
}

export default ToolWarehouse;
