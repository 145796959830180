import React, { useEffect, useState } from "react";
import axios from "axios";
import Configs from "../../../../../config";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { useParams } from "react-router";

function AEV_Logistic_Car({ mode }) {
  const { id } = useParams();

  const [pageMode, setPageMode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [outsourceDD, setOutsourceDD] = useState([]);
  const [deliveryDD, setDeliveryDD] = useState([]);
  const [transTypeDD, setTransTypeDD] = useState([]);
  const [vehicleTypeDD, setVehicleTypeDD] = useState([]);
  const [routeDD, setRouteDD] = useState([]);

  const [inputData, setInputData] = useState({
    Logistics_Name: "",
    OutSource_Company_Name: "",
    Contact_Name: "",
    Tel: "",
    Delivery_Source: "",
    Transportation_Type: "",
    Vehicle_Type: "",
    Width: "",
    Length: "",
    Height: "",
    Gross_weight: "",
    Route: "",
    Cost: "",
    Remark: "",
  });

  function saveOption() {
    const errorList = [];
    if (inputData.Logistics_Name.trim() === "") {
      errorList.push("กรุณากรอก Logistics Name");
    }
    if (inputData.OutSource_Company_Name === "") {
      errorList.push("กรุณาเลือก Outsource Company");
    }
    if (inputData.Contact_Name.trim() === "") {
      errorList.push("กรุณากรอก Contact Name");
    }
    if (inputData.Tel?.trim() === "") {
      errorList.push("กรุณากรอก Tel");
    }/*  else if (inputData.Tel.replace(/-/g, "").trim().length < 9) {
      errorList.push("กรุณากรอก Tel ให้ครบ 9 หรือ 10 หมายเลข");
    } */
    if (inputData.Gross_weight === "") {
      errorList.push("กรุณากรอก Gross weight");
    }
    if (inputData.Delivery_Source.trim() === "") {
      errorList.push("กรุณาเลือก Delivery Source");
    }
    if (inputData.Transportation_Type.trim() === "") {
      errorList.push("กรุณาเลือก Transportation Type");
    }
    if (inputData.Vehicle_Type.trim() === "") {
      errorList.push("กรุณาเลือก Vehicle Type");
    }
    if (inputData.Width.trim() === "") {
      errorList.push("กรุณากรอก Width");
    }
    if (inputData.Length.trim() === "") {
      errorList.push("กรุณากรอก Length");
    }
    if (inputData.Height.trim() === "") {
      errorList.push("กรุณากรอก Height");
    }
    if (errorList.length > 0) {
      let err_message = "";
      for (let index = 0; index < errorList.length; index++) {
        err_message += errorList[index] + "<br>";
      }
      Swal.fire({
        icon: "error",
        title: "Error",
        html: err_message,
      });
      return;
    }
    if (mode === "add") {
      addLogisticCar();
    } else if (mode === "edit") {
      editLogisticCar();
    }
  }

  function addLogisticCar() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        let savedData = {
          oem_id: getOem(),
          company_id: getUser().com,
          user_id: getUser().fup,
          user_name: `${getUser().name} ${getUser().surname}`,
          Logistics_Name:
            inputData.Logistics_Name !== ""
              ? inputData.Logistics_Name.trim()
              : null,
          OutSource_Company_Name:
            inputData.OutSource_Company_Name !== ""
              ? inputData.OutSource_Company_Name
              : null,
          Contact_Name:
            inputData.Contact_Name !== ""
              ? inputData.Contact_Name.trim()
              : null,
          Tel:
            inputData.Tel?.trim() !== ""
              ? inputData.Tel?.trim()
              : null,
          Gross_weight:
            inputData.Gross_weight !== ""
              ? inputData.Gross_weight.trim().replace(/,/g, "").trim()
              : null,
          Route: inputData.Route !== "" ? inputData.Route : null,
          Delivery_Source:
            inputData.Delivery_Source !== "" ? inputData.Delivery_Source : null,
          Transportation_Type:
            inputData.Transportation_Type !== ""
              ? inputData.Transportation_Type
              : null,
          Vehicle_Type:
            inputData.Vehicle_Type !== "" ? inputData.Vehicle_Type : null,
          Width:
            inputData.Width !== ""
              ? inputData.Width.trim().replace(/,/g, "").trim()
              : null,
          Length:
            inputData.Length !== ""
              ? inputData.Length.trim().replace(/,/g, "").trim()
              : null,
          Height:
            inputData.Height !== ""
              ? inputData.Height.trim().replace(/,/g, "").trim()
              : null,
          m3_auto_cal:
            typeof inputData.m3_auto_cal == "string"
              ? inputData.m3_auto_cal.trim().replace(/,/g, "").trim()
              : inputData.m3_auto_cal || 0,
          Cost:
            inputData.Cost !== ""
              ? inputData.Cost.trim().replace(/,/g, "").trim()
              : null,
          Remark: inputData.Remark !== "" ? inputData.Remark.trim() : null,
        };
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/logistic/saveLogistic",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: savedData,
        })
          .then((response) => {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus";
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  function editLogisticCar() {
    Swal.fire({
      title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "บันทึก",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          oem_id: getOem(),
          company_id: getUser().com,
          user_id: getUser().fup,
          user_name: `${getUser().name} ${getUser().surname}`,
          loc_id: id,
          Logistics_Name:
            inputData.Logistics_Name !== ""
              ? inputData.Logistics_Name.trim()
              : null,
          OutSource_Company_Name:
            inputData.OutSource_Company_Name !== ""
              ? inputData.OutSource_Company_Name
              : null,
          Contact_Name:
            inputData.Contact_Name !== ""
              ? inputData.Contact_Name.trim()
              : null,
          Tel:
            inputData.Tel?.trim() !== ""
              ? inputData.Tel?.trim()
              : null,
          Gross_weight:
            inputData.Gross_weight !== ""
              ? inputData.Gross_weight.trim().replace(/,/g, "").trim()
              : null,
          Route: inputData.Route !== "" ? inputData.Route : null,
          Delivery_Source:
            inputData.Delivery_Source !== "" ? inputData.Delivery_Source : null,
          Transportation_Type:
            inputData.Transportation_Type !== ""
              ? inputData.Transportation_Type
              : null,
          Vehicle_Type:
            inputData.Vehicle_Type !== "" ? inputData.Vehicle_Type : null,
          Width:
            inputData.Width !== ""
              ? inputData.Width.trim().replace(/,/g, "").trim()
              : null,
          Length:
            inputData.Length !== ""
              ? inputData.Length.trim().replace(/,/g, "").trim()
              : null,
          Height:
            inputData.Height !== ""
              ? inputData.Height.trim().replace(/,/g, "").trim()
              : null,
          m3_auto_cal:
            typeof inputData.m3_auto_cal == "string"
              ? inputData.m3_auto_cal.trim().replace(/,/g, "").trim()
              : inputData.m3_auto_cal || 0,
          Cost:
            inputData.Cost !== ""
              ? inputData.Cost.trim().replace(/,/g, "").trim()
              : null,
          Remark: inputData.Remark !== "" ? inputData.Remark.trim() : null,
        };
        axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/logistic/editLogistic",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus";
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  async function GetLogisticCarDetail() {
    let data = {
      loc_id: id,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getLogisticCarDetail",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          response.data = response.data[0];
          setInputData({
            ...inputData,
            Logistics_Name:
              response.data.lgt_name !== null ? response.data.lgt_name : "",
            OutSource_Company_Name:
              response.data.lgt_lcom_id !== null
                ? response.data.lgt_lcom_id
                : "",
            Contact_Name:
              response.data.lgt_contract_name !== null
                ? response.data.lgt_contract_name
                : "",
            Tel: response.data.lgt_tel !== null ? response.data.lgt_tel : "",
            Gross_weight:
              response.data.lgt_gross_weight !== null
                ? response.data.lgt_gross_weight
                : "",
            Route:
              response.data.lgt_route_id !== ""
                ? response.data.lgt_route_id
                : null,
            Delivery_Source:
              response.data.lgt_ds_id !== "" ? response.data.lgt_ds_id : null,
            Transportation_Type:
              response.data.lgt_tt_id !== "" ? response.data.lgt_tt_id : null,
            Vehicle_Type:
              response.data.lgt_vt_id !== "" ? response.data.lgt_vt_id : null,
            Width:
              response.data.lgt_size_width !== null
                ? response.data.lgt_size_width
                : "",
            Length:
              response.data.lgt_size_length !== null
                ? response.data.lgt_size_length
                : "",
            Height:
              response.data.lgt_size_height !== null
                ? response.data.lgt_size_height
                : "",
            m3_auto_cal:
              response.data.lgt_m3 !== null
                ? parseFloat(response.data.lgt_m3).toFixed(2)
                : "",
            Cost: response.data.lgt_cost !== null ? response.data.lgt_cost : "",
            Remark:
              response.data.lgt_remark !== null ? response.data.lgt_remark : "",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  function cancelOption() {
    Swal.fire({
      title: "คุณต้องการยกเลิกหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "ยกเลิก",
      confirmButtonText: "ตกลง",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Warehouse_Management/Factory_Master_Data_Cuteplus/LogisticCar_cuteplus";
      }
    });
  }

  async function getDropdownOutsourceComp() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/GetOutsourceComp",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setOutsourceDD(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getDropdownDeliverySource() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/GetDeliverySource",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setDeliveryDD(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getDropdownTransportation() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/GetTransportationType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setTransTypeDD(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getDropdownVehicleType() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/GetVehicleType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setVehicleTypeDD(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getDropdownRoute() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/GetRouteDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setRouteDD(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  useEffect(async () => {
    if (mode === "add") {
      setPageMode("Add");
      setIsDisabled(false);
    } else if (mode === "edit") {
      setPageMode("Edit");
      setIsDisabled(false);
      await GetLogisticCarDetail();
    } else if (mode === "view") {
      setPageMode("Read");
      setIsDisabled(true);
      await GetLogisticCarDetail();
    }

    await getDropdownOutsourceComp();
    await getDropdownDeliverySource();
    await getDropdownTransportation();
    await getDropdownVehicleType();
    await getDropdownRoute();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-sm-4">
                <h1>Logistics Car</h1>
              </div>
              <div className="col-sm-8">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Logistic Car</li>
                  <li className="breadcrumb-item active">
                    Logistic Car {pageMode}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-3">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-3">
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-success"
                    onClick={() => {
                      saveOption();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                  <button
                    type="button"
                    class="btn btn-block btn-danger"
                    onClick={() => {
                      cancelOption();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6">
                <label
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Logistics Name : {inputData.Logistics_Name}
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 style={{ margin: "0px" }}>Logistics Car</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <label
                          style={{
                            fontSize: "24px",
                            lineHeight: "normal",
                            wordBreak: "break-all",
                          }}
                        >
                          Logistic Car Detail
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.Logistics_Name}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Logistics_Name: e.target.value,
                              });
                              inputData.Logistics_Name = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Logistics Name
                            {inputData.Logistics_Name === "" ||
                            inputData.Logistics_Name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.OutSource_Company_Name}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                OutSource_Company_Name: e.target.value,
                              });
                              inputData.OutSource_Company_Name = e.target.value;
                            }}
                          >
                            <option value="" disabled>
                              -- Please Select --
                            </option>
                            {outsourceDD?.map((data, index) => {
                              return (
                                <option key={index} value={data.lcom_id}>
                                  {data.lcom_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Outsource Company
                            {inputData.OutSource_Company_Name === "" ||
                            inputData.OutSource_Company_Name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.Contact_Name}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Contact_Name: e.target.value,
                              });
                              inputData.Contact_Name = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Contact Name
                            {inputData.Contact_Name === "" ||
                            inputData.Contact_Name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            // format={"##-####-####"}
                            disabled={isDisabled}
                            value={inputData.Tel}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Tel: e.target.value,
                              });
                              inputData.Tel = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Tel
                            {inputData.Tel === "" ||
                            inputData.Tel === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            thousandSeparator={true}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={0}
                            required
                            disabled={isDisabled}
                            value={inputData.Gross_weight}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Gross_weight: e.target.value,
                              });
                              inputData.Gross_weight = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Gross weight
                            {inputData.Gross_weight === "" ||
                            inputData.Gross_weight === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.Route}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Route: e.target.value,
                              });
                              inputData.Route = e.target.value;
                            }}
                          >
                            <option value="">-- Please Select --</option>
                            {routeDD?.map((data, index) => {
                              return (
                                <option key={index} value={data.route_id}>
                                  {data.route_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Route
                            {/* {inputData.Route === "" ||
                            inputData.Route === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.Delivery_Source}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Delivery_Source: e.target.value,
                              });
                              inputData.Delivery_Source = e.target.value;
                            }}
                          >
                            <option value="" disabled>
                              -- Please Select --
                            </option>
                            {deliveryDD?.map((data, index) => {
                              return (
                                <option key={index} value={data.ds_id}>
                                  {data.ds_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Delivery Source
                            {inputData.Delivery_Source === "" ||
                            inputData.Delivery_Source === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.Transportation_Type}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Transportation_Type: e.target.value,
                              });
                              inputData.Transportation_Type = e.target.value;
                            }}
                          >
                            <option value="" disabled>
                              -- Please Select --
                            </option>
                            {transTypeDD?.map((data, index) => {
                              return (
                                <option key={index} value={data.tt_id}>
                                  {data.tt_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Transportation Type
                            {inputData.Transportation_Type === "" ||
                            inputData.Transportation_Type === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.Vehicle_Type}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Vehicle_Type: e.target.value,
                              });
                              inputData.Vehicle_Type = e.target.value;
                            }}
                          >
                            <option value="" disabled>
                              -- Please Select --
                            </option>
                            {vehicleTypeDD?.map((data, index) => {
                              return (
                                <option key={index} value={data.vt_id}>
                                  {data.vt_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Vehicle Type
                            {inputData.Vehicle_Type === "" ||
                            inputData.Vehicle_Type === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <h4>
                          <b>Size</b>
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-3 col-xl-3">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            thousandSeparator={true}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={0}
                            required
                            disabled={isDisabled}
                            value={inputData.Width}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Width: e.target.value,
                                m3_auto_cal:
                                  (parseFloat(
                                    e.target.value?.replace(/,/g, "") || 0
                                  ) *
                                    parseFloat(
                                      inputData.Length?.replace(/,/g, "") || 0
                                    ) *
                                    parseFloat(
                                      inputData.Height?.replace(/,/g, "") || 0
                                    )) /
                                  1000000.0,
                              });
                              inputData.Width = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Width
                            {inputData.Width === "" ||
                            inputData.Width === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            thousandSeparator={true}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={0}
                            required
                            disabled={isDisabled}
                            value={inputData.Length}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Length: e.target.value,
                                m3_auto_cal:
                                  (parseFloat(
                                    e.target.value?.replace(/,/g, "") || 0
                                  ) *
                                    parseFloat(
                                      inputData.Width?.replace(/,/g, "") || 0
                                    ) *
                                    parseFloat(
                                      inputData.Height?.replace(/,/g, "") || 0
                                    )) /
                                  1000000.0,
                              });
                              inputData.Length = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Length
                            {inputData.Length === "" ||
                            inputData.Length === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            thousandSeparator={true}
                            thousandsGroupStyle="thousand"
                            allowNegative={false}
                            decimalScale={0}
                            required
                            disabled={isDisabled}
                            value={inputData.Height}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Height: e.target.value,
                                m3_auto_cal:
                                  (parseFloat(
                                    e.target.value?.replace(/,/g, "") || 0
                                  ) *
                                    parseFloat(
                                      inputData.Width?.replace(/,/g, "") || 0
                                    ) *
                                    parseFloat(
                                      inputData.Length?.replace(/,/g, "") || 0
                                    )) /
                                  1000000.0,
                              });
                              inputData.Height = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Height
                            {inputData.Height === "" ||
                            inputData.Height === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group ">
                          <NumberFormat
                            type="text"
                            className="form-control"
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            allowNegative={false}
                            decimalScale={2}
                            id="text_product_name"
                            required="false"
                            disabled={true}
                            value={inputData.m3_auto_cal}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                m3_auto_cal: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">
                            M3 (Auto Calculate)
                            {/* {inputData.product_point === "" ?
                                                    <span style={{ color: "red" }}> *</span>
                                                    : ""
                                                } */}
                          </label>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3 col-xl-3">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            required
                            className="form-control"
                            thousandSeparator={true}
                            decimalScale={2}
                            allowNegative={false}
                            disabled={isDisabled}
                            value={inputData.Cost}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Cost: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Logistic Cost
                            {/* {inputData.Cost === "" ||
                            inputData.Cost === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )} */}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            className="form-control"
                            required
                            disabled={isDisabled}
                            value={inputData.Remark}
                            onChange={(e) => {
                              setInputData({
                                ...inputData,
                                Remark: e.target.value,
                              });
                              inputData.Remark = e.target.value;
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Remark
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AEV_Logistic_Car;
