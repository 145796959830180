import React, { Component, useEffect, useState, useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import { MDBDataTable } from "mdbreact";
import { useParams } from "react-router";
import QRCode from "qrcode.react";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import "../../assets/css/table.css";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getUser,
  getComConfig,
  getFeature,
  getAcademy,
  getOemlist_,
  getnamecompanee,
} from "../../Utils/Common";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment";
import sortJsonArray from "sort-json-array";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import INF from "../../component/input_feature";
import LOGH from "../../component/log_detailHistory_feature";
import INDF from "../../component/input_date_feature";
import Load_status from "./Load_status";
import Select from "react-select";
import { stock_product_report } from "../../component/Report";
function FGInOutCTS_mgt(params) {
  const [block_btn, setblock_btn] = useState(false);

  const [modeselect, setmodeselect] = useState(true);
  const [product_data, setProduct_data] = useState([]);
  const [Lot_No_option, setLot_No_option] = useState([]);
  const [loop_option, setloop_option] = useState([]);
  const [warehouse_option, setwarehouse_option] = useState([]);
  const [rack_option, setrack_option] = useState([]);
  const [product_on_rack_option, setproduct_on_rack_option] = useState([]);
  const [load_status_option, setload_status_option] = useState([]);
  const [project_option, setProject_option] = useState([]);
  const [color_option, setcolor_option] = useState([]);
  const [feature, setfeature] = useState([]);
  const [model, setModel] = useState([]);
  const [oem_id] = useState(getOem());
  const [company_id] = useState(getUser().com);
  const [historyFG, setHistoryFG] = useState([]);
  const [lotFGa, setLotFGa] = useState([]);
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    company_id: getUser().com,
    oem_id: getOem(),
  });

  const [filler_checkbox, setfiller_checkbox] = useState({
    inventory: false,
    virtual_product: false,
    under_min_lot: false,
  });

  const [tool_index, settool_index] = useState({
    index1: "100",
    index2: "99",
  });
  //console.log(filler_product);
  const [search_cus_end, setsearch_cus_end] = useState({
    result_cus: "",
    result_end: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [oemfix, setoemfix] = useState();
  //const [checked, setChecked] = useState();
  const [is_fifo, setis_fifo] = useState(true);
  const [color, setcolor] = useState([]);
  var isChecked2 = true;
  const [filter_loop, setFilter_loop] = useState({
    loop_no: "",
    company_id: getUser().com,
  });
  const [loop, setLoop] = useState([]);
  const [filter_warehouse, setFilter_warehouse] = useState({
    id: "",
    code_no: "",
    company_id: getUser().com,
    parent_id: "",
    type: "Warehouse",
  });
  const [lotinstocklist, setlotinstocklist] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  const [rack, setRack] = useState([]);
  const [product_on_rack, setProduct_on_rack] = useState([]);
  const [load_status, setLoad_status] = useState([]);
  const [warehouse_data, setwarehouse_data] = useState({
    loop_id: "",
    warehouse_id: "",
    rack_id: "",
    product_on_rack_id: "",
    load_status_id: "",
    remark: "",
    description: "",
  });
  const [log_detailFG, setlog_detailFG] = useState({
    id: "",
    product_id: "",
    user_id: "",
    edit_type: "",
    amount: "",
    create_date: "",
    project_id: "",
    fg_lot_no: "",
    exprire_date: "",
  });
  const [project, setProject] = useState([]);
  const [type_select, settype_select] = useState({
    status: "plant_item",
  });

  const [filler_project, setFiller_project] = useState({
    project_name: "",
    company_id: getUser().com,
    oem_id: getOem(),
  });

  const [summary_report, setSummary_report] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [customer_option, setcustomer_option] = useState([]);
  const [End_user_option, setEnd_user_option] = useState([]);

  const { product_id } = useParams();
/* console.log(product_id); */
  const [filler_summary_report, setFiller_summary_report] = useState({
    product_name: "",
    oem_id: getOem(),
  });

  const SaveInOut = async () => {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        var err_list = [];
        var loopsumamount = [];
        var is_chack = true;

        /* console.log(saveProcess,product_id) */
      if (modeselect === true && saveProcess.edit_type === false) {
      var allsum = parseFloat(saveProcess.amount.replace(/,/g, ""));
var resum = 0;
        for(let i of lotFGa){

          allsum -= parseFloat(i.amount)
/* console.log(allsum,resum) */
if (is_chack === true) {
  if(allsum < 0){
    is_chack = false
    if (resum === 0) {
      resum = parseFloat(saveProcess.amount.replace(/,/g, ""));
    }
    
    loopsumamount.push({
      product_id: product_id,
      edit_type: saveProcess.edit_type,
      amount: resum,
      project_id: saveProcess.project_id,
      // lot_no: saveProcess.lot_no,
      // production_order_cts_id: saveProcess.production_order_cts_id,
      // pack_amount: saveProcess.pack_amount,
      // exp_date: saveProcess.exp_date,
      remark: saveProcess.remark,
    })
  }else{
    loopsumamount.push({
      product_id: product_id,
      edit_type: saveProcess.edit_type,
      amount: Number(i.amount.replace(/,/g, "")),
      project_id: saveProcess.project_id,
      // lot_no: saveProcess.lot_no,
      // production_order_cts_id: saveProcess.production_order_cts_id,
      // pack_amount: saveProcess.pack_amount,
      // exp_date: saveProcess.exp_date,
      remark: saveProcess.remark,
    })
  }
}
         
resum = allsum
        }
       /*  var sumchack = allsum - parseFloat(material_process.lm_amount) */
        if (allsum > 0) {
         
          err_list.push("Over stock "+allsum.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+" item.");
        }
      }


        if (saveProcess.edit_type === true) {
          if (saveProcess.amount == "" || saveProcess.amount < 1) {
            err_list.push("กรุณากรอก จำนวนที่จะทำการ In bound!!!");
          }

          if (saveProcess.lot_no == "" || saveProcess.lot_no == null) {
            err_list.push("กรุณาเลือก Lot No ที่จะทำการ In bound!!!");
          }

          if (saveProcess.exp_date == "" || saveProcess.exp_date == null) {
            err_list.push("กรุณากรอก Exprire Date ที่จะทำการ In bound!!!");
          }
        }

        if (saveProcess.edit_type === false) {
          if (saveProcess.amount == "" || saveProcess.amount < 1) {
            err_list.push("กรุณากรอก จำนวนที่จะทำการ Out bound!!!");
          }
        }

        if (err_list.length > 0) {
          //มี err
          var err_message = "";
          for (var e = 0; e < err_list.length; e++) {
            err_message += "<br/>" + err_list[e];
          }
          Swal.fire("ระบบ", err_message, "error");
        } else {
          // for (let index = 0; index < lotFG.length; index++) {
          //   var packing_standart =
          //     lotFG[index].lot_no === saveProcess.lot_no
          //       ? 0
          //       : lotFG[index].packing_standart;

          //   if (
          //     saveProcess.amount / packing_standart === Infinity ||
          //     isNaN(saveProcess.amount / packing_standart)
          //   ) {
          //     saveProcess.pack_amount = 0;
          //   } else {
          //     saveProcess.pack_amount = Math.ceil(saveProcess.amount / packing_standart);
          //   }
          // }

          if (saveProcess.edit_type == true) {
            var tmp_save = {
              product_id: product_id,
              edit_type: saveProcess.edit_type,
              amount: Number(saveProcess.amount.replace(/,/g, "")),
              project_id: null,
              lot_no: saveProcess.lot_no,
              production_order_cts_id: saveProcess.production_order_cts_id,
              // pack_amount: saveProcess.pack_amount,
              exp_date: saveProcess.exp_date,
              remark: null,
            }
          }

          if (saveProcess.edit_type == false) {

                var tmp_save = {
                  product_id: product_id,
                  edit_type: saveProcess.edit_type,
                  amount: Number(saveProcess.amount.replace(/,/g, "")),
                  project_id: saveProcess.project_id,
                  lot_no: saveProcess.lot_no || null,
                  // production_order_cts_id: saveProcess.production_order_cts_id,
                  // pack_amount: saveProcess.pack_amount,
                  // exp_date: saveProcess.exp_date,
                  remark: saveProcess.remark,
                  amountselerL: saveProcess.amountseler,
                  num: saveProcess.num
                }
              
            
          }

         /*  console.log(tmp_save,'sass'); */
          axios({
            method: "post",
            url: Configs.API_URL + "/api/FGInOutCTS/save_In_Out_FG",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: tmp_save,
          })

            .then(function (response) {
              if (response.data) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                })
                  .then((result) => {
                    setSaveProcess({
                      product_id: null,
                      edit_type: saveProcess.edit_type,
                      amount: null,
                      project_id: null,
                      lot_no: null,
                      production_order_cts_id: null,
                      pack_amount: null,
                      exp_date: new Date(),
                      remark: null,
                    })
                    if (saveProcess.edit_type == true) {
                      selectProduct_model.current.select.clearValue();
                    }
                    if (saveProcess.edit_type == false) {
                      selectProject_model.current.select.clearValue();
                      textRemark.current.value = "";
                    }
                  });
              }

              GetLotNo();
              GetHistoryFG();
              GetLotFG();
              GetProduct();
            })

            .catch(function (error) {
              Swal.fire("Over Stock!", error.response.data.message, "warning");
              console.log(error);
            });
        }
      }
    });
  };

  function compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  const GetLotNo = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/FGInOutCTS/get_Lot_No?id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setModel(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;
          options.push(element);
        });
        options.sort(compare);

        setLot_No_option(options);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const GetProduct = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/FGInOutCTS/get_Product?id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        setMessage({
          no: response.data[0].no,
          name: response.data[0].name,
        });
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const [saveProcess, setSaveProcess] = useState({
    product_id: null,
    edit_type: true,
    amount: null,
    project_id: null,
    lot_no: null,
    production_order_cts_id: null,
    pack_amount: null,
    exp_date: new Date(),
    remark: null,
    amountseler: null,
    num: null
  });

  // id: "",
  // product_id: "",
  // edit_type: true, 
  // amount: "",
  // project_id: "",
  // tracking_no: "",
  // customer_id: "",
  // lot_no: "",
  // production_order_cts_id: "", 
  // pack_amount: "",
  // exp_date: "",
  // remark: "",
  // update_date: "",
  // update_by: ""

  const [message, setMessage] = useState({
    no: "",
    name: "",
  });

  const selectProduct_model = useRef();
  const selectLoop_model = useRef();
  const selectWarehouse_model = useRef();
  const selectRack_model = useRef();
  const selectProduct_on_rack_model = useRef();
  const selectLoad_status_model = useRef();
  const selectProject_model = useRef();
  const textRemark = useRef();

  const GetProject = async () => {
    let temp = {
      company_id: company_id,
      oem_id: oem_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/api/FGInOutCTS/filter_project",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        setProject(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.project_name;
          options.push(element);
        });
        await setProject_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  useEffect(() => {
    GetLotNo();
    GetHistoryFG();
    GetLotFG();
    GetProduct();
    GetProject();
  }, []);

  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }

  const GetLotFG = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/FGInOutCTS/get_Lot_FG?id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })

      .then(function (response) {
        /* console.log(response.data) */
        for (let index = 0; index < response.data.length; index++) {
          var packing_standart =
            response.data[index].packing_standart === null
              ? 0
              : response.data[index].packing_standart;
          var amount =
            response.data[index].amount === null
              ? 0
              : response.data[index].amount;
          if (
            amount / packing_standart === Infinity ||
            isNaN(amount / packing_standart)
          ) {
            response.data[index]["pack"] = amount;
          } else {
            response.data[index]["pack"] = Math.ceil(amount / packing_standart);
          }
          response.data[index].is_chack = false;
          response.data[index].num = index;
        }
        setLotFGa(response.data)
        formatTable(response.data);
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  const rowsData_lot_detail = [];

  async function formatTable(lotFG) {
    const row = [];
  for (var index = 0; index < lotFG.length; index++) {
    const rowItem = {};
    rowItem["Chack"] = (<div style={{textAlign:'center' }}><input  type="radio"
    style={{
        width: '40px',
        height: '20px'
    }}
    
    checked={lotFG[index].is_chack}
    id="is_active"
    name="is_active"
    className="form-check-input"
    onClick={chackseler.bind(this, lotFG[index].lot_no,lotFG,lotFG[index].num)}
    
    /></div>);
    rowItem["lot_no"] = lotFG[index].lot_no;

    rowItem["amount"] = (
      <div style={{ textAlign: 'right' }}>
        {
          parseFloat(lotFG[index].amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      </div>
    )
      ;

    rowItem["pack"] = (
      <div style={{ textAlign: 'right' }}>
        {
          parseFloat(lotFG[index].pack).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      </div>
    );

    rowItem["exprire_date"] = moment(
      lotFG[index].exp_date
    ).format("DD/MM/yyyy");

    row.push(rowItem);
  }
  setlotinstocklist(row);
  }
  var loopstock = [];
  if (modeselect === false && saveProcess.edit_type === false) {
    loopstock.push( {
      label: "",
      field: "Chack",
      sort: "asc",
      width: 150
    },)
  }
  loopstock.push(
    {
      label: "Lot No",
      field: "lot_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: 50,
    },
    {
      label: "Pack",
      field: "pack",
      sort: "asc",
      width: 50,
    },
    {
      label: "Exprire Date",
      field: "exprire_date",
      sort: "asc",
      width: 50,
    },
  )




  const Data_lot_detail = {
    columns: loopstock,
    rows: lotinstocklist,
  };

  function chackseler(chack_name,loop,num) {
    var tabel = loop;
    /* console.log(chack_name,tabel) */
    for (let i of tabel) {
      i.is_chack = false
      
  }
    var check = tabel.filter((e) => {
      return e.num === num;
    });
    if (check.length > 0) {
      /* console.log(tabel) */
      var objIndex = tabel.findIndex((e => e.num == num));
  
      tabel[objIndex].is_chack = true

      setSaveProcess({
        ...saveProcess,
        product_id: saveProcess.product_id,
        edit_type: false,
        amount: saveProcess.amount,
        project_id: saveProcess.project_id,
        lot_no: chack_name,
        production_order_cts_id: saveProcess.production_order_cts_id,
        pack_amount: saveProcess.pack_amount,
        exp_date: saveProcess.exp_date,
        remark: saveProcess.remark,
        amountseler:tabel[objIndex].amount || 0,
        num:num
      
      });
      saveProcess.product_id =  saveProcess.product_id;
      saveProcess.edit_type = false;
      saveProcess.amount =  saveProcess.amount;
      saveProcess.project_id =  saveProcess.project_id;
      saveProcess.lot_no =  chack_name;
      saveProcess.production_order_cts_id =  saveProcess.production_order_cts_id;
      saveProcess.pack_amount =  saveProcess.pack_amount;
      saveProcess.exp_date =  saveProcess.exp_date;
      saveProcess.remark =  saveProcess.remark;
      saveProcess.amountseler =  tabel[objIndex].amount || 0;
      saveProcess.num =  num;


    } 
   /*  setlotinstocklist(tabel) */
  /*  console.log('chack_name',tabel) */
    formatTable(tabel)

  }
  const GetHistoryFG = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/FGInOutCTS/get_History_FG?id=" + product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setHistoryFG(response.data);
        //console.log(response.data)
      })
      .catch(function (e) {
        console.log(e);
      });
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const rowsData_historyFG = [];

  for (var index = 0; index < historyFG.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["lot_no"] = historyFG[index].lot_no;

    var status;
    if (historyFG[index].edit_type === true) {
      status = "IN";
    } else {
      status = "OUT";
    }

    rowItem["status"] = status;

    rowItem["amount"] = (
      <div style={{ textAlign: 'right' }}>
        {
          parseFloat(historyFG[index].amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      </div>
    );

    rowItem["date"] = moment(historyFG[index].create_date).format("DD/MM/yyyy :: HH:mm น.");

    rowItem["project"] = historyFG[index].project_name;

    rowItem["exprire_date"] = moment(historyFG[index].exp_date).format("DD/MM/yyyy");

    rowItem["remark"] = historyFG[index].remark;

    rowsData_historyFG.push(rowItem);
  }

  const Data_HistoryFG = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Project",
        field: "project",
        sort: "asc",
        width: 50,
      },
      {
        label: "Exprire date",
        field: "exprire_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_historyFG,
  };

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#87CEFA" : "#FFF",
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  function cancelOption() {
    Swal.fire({
      title: 'Do you want to cancel and leave the changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Warehouse_Management/F_G_In_Out_CTS";
      }
    }
    );
  }

  let sumAmount = 0;
  for (let index = 0; index < lotFGa.length; index++) {
    sumAmount += Number(lotFGa[index].amount);
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  F/G In/Out Management{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    F/G In/Out Management
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h2>
                Edit Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                {
                  <NumberFormat
                    value={
                      sumAmount
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                }
                &nbsp;&nbsp;&nbsp; items
              </h2>
              <div className="card-tools" />
            </div>
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-0 col-md-2 col-xl-2" />

                <div className="col-12 col-md-5 col-xl-5">
                  <h3>Product No : {message.no}</h3>
                </div>

                <div className="col-12 col-md-5 col-xl-5">
                  <h3>Product Name : {message.name}</h3>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-0 col-md-2 col-xl-2" />

                <div className="col-0">
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={saveProcess.edit_type != true ? false : true}
                    value={true}
                    onChange={(e) => {
                      setSaveProcess({
                        ...saveProcess,
                        edit_type: e.target.value === "true" ? true : false,
                      });
                    }}
                  />
                </div>
                <div className="col-5 col-md-5 col-xl-5">
                  <label htmlFor="inbound">
                    <h4>In bound</h4>
                  </label>
                </div>

                <div className="col-0">
                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    value={false}
                    checked={saveProcess.edit_type != false ? false : true}
                    onChange={(e) => {
                      setSaveProcess({
                        ...saveProcess,
                        edit_type: e.target.value === "false" ? false : true,
                      });
                      setmodeselect(true)
                    }}
                  />
                </div>
                <div className="col-5 col-md-4 col-xl-4">
                  <label htmlFor="outbound">
                    <h4>Out bound</h4>
                  </label>
                </div>
              </div>

              {saveProcess.edit_type === false ?
                  (<>
                 <div className="row" style={{ whiteSpace: 'nowrap'}} >
                      <div className="col-4 col-md-5 col-xl-5" style = {{textAlign: "right"}}><label for="is_fifo">Manual out</label></div>
                      <div className="col-4 col-md-3 col-xl-3">
                    
                      <div class="custom-control custom-switch">
                     
                      <input type="checkbox" class="custom-control-input" id="is_fifo"
                      defaultChecked = {modeselect}
                      disabled={saveProcess.edit_type}
                     onClick={(event)=>{
                       /* console.log(modeselect); */
                       setSaveProcess({
                        ...saveProcess,
                        amount: '',
                      });
                      saveProcess.amount = '';
                       setmodeselect(!modeselect)
                     
                       
                     }}
                     />
<label class="custom-control-label" for="is_fifo">First In First Out</label>
</div>
                      
                      </div>
               
                      </div>
               
                   
                  </>
                  ) : (
                    <>
                    </>
                  )}




              <LOGH
                history={Data_HistoryFG}
                data={Data_lot_detail}
              />

              <div className="row">
                {saveProcess.edit_type === true ? (
                  <div
                    className="col-12 col-md-4 col-xl-4"
                    style={{ "z-index": "101", alignItems: "right" }}
                  >
                    <INDF
                      selected={saveProcess.exp_date}
                      minDate={new Date()}
                      txt="EXP Date"
                      onChange={(date) => {
                        setSaveProcess({
                          ...saveProcess,
                          exp_date: date
                        })
                      }}
                      customInput={<CustomInput />}
                    />
                  </div>
                ) : (
                  <div
                    className="col-12 col-md-4 col-xl-4"
                    style={{ "z-index": "99" }}
                  >
                    <div className="form-group ">
                      <Select
                        placeholder=""
                        styles={colourStyles}
                        options={project_option}
                        ref={selectProject_model}
                        onChange={(e) => {
                          if (e !== null) {
                            setSaveProcess({
                              ...saveProcess,
                              project_id: e.value
                            });
                          }
                        }}
                      />
                      <label htmlFor="">Project</label>
                    </div>
                  </div>
                )}

                {saveProcess.edit_type === true ? (
                  <div
                    className="col-12 col-md-4 col-xl-4"
                    style={{ "z-index": "99" }}
                  >
                    <div className="form-group ">
                      <Select
                        placeholder=""
                        styles={colourStyles}
                        options={Lot_No_option}
                        ref={selectProduct_model}
                        onChange={(e) => {
                          if (e !== null) {
                            setSaveProcess({
                              ...saveProcess,
                              lot_no: e.label,
                              production_order_cts_id: e.value
                            });
                          }
                        }}
                      />
                      <label htmlFor="">Lot No</label>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-12 col-md-4 col-xl-4">
                      <div className="form-group ">
                        <NumberFormat
                          thousandSeparator={true}
                          allowNegative={false}
                          id="amount_in"
                          type="text"
                          className="form-control"
                          decimalScale={0}
                          value={saveProcess.amount}
                          onChange={(e) => {
                            setSaveProcess({
                              ...saveProcess,
                              amount: e.target.value,
                            });
                          }}
                          autofocus
                          required
                        />
                        <label htmlFor="">Amount</label>{" "}
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-4 col-xl-4"
                    >
                      <div className="form-group ">
                        <input
                          type="text"
                          className="form-control"
                          required="false"
                          value={saveProcess.remark}
                          ref={textRemark}
                          onChange={(e) => {
                            setSaveProcess({
                              ...saveProcess,
                              remark: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="">Remark</label>{" "}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {saveProcess.edit_type === true ? (
                <div className="row">
                  <div className="col-12 col-md-4 col-xl-4" />
                  <div className="col-12 col-md-4 col-xl-4">
                    <div className="form-group ">
                      <NumberFormat
                        thousandSeparator={true}
                        allowNegative={false}
                        id="amount_in"
                        type="text"
                        className="form-control"
                        decimalScale={0}
                        value={saveProcess.amount}
                        onChange={(e) => {
                          setSaveProcess({
                            ...saveProcess,
                            amount: e.target.value,
                          });
                        }}
                        autofocus
                        required
                      />
                      <label htmlFor="">Amount</label>{" "}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <hr />

              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={SaveInOut}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"

                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default FGInOutCTS_mgt;
