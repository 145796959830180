import React, { useEffect, useState, useRef } from "react";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { max } from "date-fns";
import { validate } from "numeral";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";

function ImportDeliveryOrder() {
  const [deliveryData, setDeliveryData] = useState({
    data: [],
  });
  const [itemData, setItemData] = useState({
    data: [],
  });

  const [filter, setfilter] = useState({
    data: null,
  });
  const [removeDOAll, setRemoveDOAll] = useState({
    data: [],
  });

  const [moveItem, setMoveItem] = useState({
    data: [],
  });
  // console.log('moveItem', moveItem)

  const [filterTable, setFilterTable] = useState({
    is_check: true,
  });

  const [clearModal, setClearModal] = useState({});

  const [selectConfig, setSelectConfig] = useState();
  const [selectedOption, setSelectedOption] = useState({});

  const [warehouseList, setWarehouseList] = useState([]);
  const [selectWarehouse, setSelectWarehouse] = useState({
    data: null,
  });

  const [showQuantityMove, setShowQuantityMove] = useState(false);

  const [excel, setexcel] = useState({
    name: "",
    file: null,
    status: null,
  });

  const [material_exel, setmaterial_exel] = useState({
    data: [
      // {
      //   db_po : "",
      //   db_part_no : "",
      //   db_part_name : "",
      //   error : "",
      // }
    ],
  });

  // console.log("material_exel", material_exel);

  const [showMoveTable, setShowMoveTable] = useState({
    mode: "",
    dl_id: "",
    wh_id: "",
    dl_no: "",
  });

  const [importDelivery, setImportDelivery] = useState({
    data: [],
  });

  const [getTableItem, setGetTableItem] = useState({
    data: [],
  });

  const [getSelectTableItem, setGetSelectTableItem] = useState();

  const [modal, setModal] = useState(false);
  const toggle_modal = () => {
    if (!modal) {
      setModal(true);
    } else {
      window.location.reload();
    }
  };

  const clearFilter = async () => {
    setfilter({
      data: "",
    });
    filter.data = null;
    await getTableDelivery();
    setFilterTable({
      is_check: true,
    });
    filterTable.is_check = true;
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // console.log(selectedOption);
  };

  const clearSelectState = () => {
    setSelectedOption({});
  };

  async function getTableDelivery() {
    let temp = {
      dl_company_id: getUser().com,
      dl_oem_id: getOem(),
      filter: filter.data?.trim() || null,
    };

    // console.log(temp);
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/deliveryOrder/getTableDelivery",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data.data && response.data.data.length > 0) {
          setDeliveryData((prevData) => ({
            ...prevData,
            data: response.data.data,
          }));
          // console.log(response.data.data);
        } else {
          setDeliveryData({
            ...deliveryData,
            data: [],
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getDropdownWarehouse(moveDO, warehouseId) {
    let temp = {
      dl_company_id: getUser().com,
      dl_oem_id: getOem(),
    };
    // console.log(temp);
    await axios({
      method: "post",
      url:
        Configs.API_URL_AIOI_Back + "/api/deliveryOrder/getDropdownWarehouse",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        // console.log("response", response);
        if (moveDO) {
          let temp = response.data.data.filter((el) => {
            return el.wh_id !== warehouseId;
          });
          setWarehouseList(
            temp.map((el) => {
              return {
                value: el.wh_id,
                label: el.wh_name,
              };
            })
          );
        } else {
          setSelectConfig(
            response.data.data.map((el) => {
              return {
                value: el.wh_id,
                label: el.wh_name,
              };
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  const uploadFile = async (e) => {
    // console.log("excel.file",excel.status);
    // console.log(e.target.files[0]);
    if (selectedOption && selectedOption.value) {
      const firstOption = selectedOption;
      if (excel.file) {
        setexcel({ ...excel, name: excel.file.name });
        // console.log(e.target.files[0]);
        var data = new FormData();
        data.append("file", excel.file);
        data.append("wh_id", firstOption.value);
        data.append("wh_name", firstOption.label);
        data.append("dl_create_date", getUser().fup);
        data.append("dl_update_date", getUser().fup);
        data.append("dl_create_name", getUser().name + " " + getUser().surname);
        data.append("dl_update_name", getUser().name + " " + getUser().surname);
        data.append("dl_company_id", getUser().com);
        data.append("dl_oem_id", getOem());

        var config_uploadExecl = {
          method: "post",
          url: Configs.API_URL_AIOI_Back + "/api/deliveryOrder/uploadFile",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config_uploadExecl)
          .then(function (response) {
            // console.log("responses", response.data.data);
            // setmaterial_exel(response.data.data);
            if (response.data.data && response.data.data.length > 0) {
              setmaterial_exel((prevData) => ({
                ...prevData,
                data: response.data.data,
              }));
              // console.log(response.data.data);
            }
            setexcel({
              name: "",
              file: null,
              status: null,
            });

            Swal.fire(
              "Upload Complete",
              "Your file has been Upload.",
              "success"
            ).then(() => {
              toggle_modal();
              //   $("#modal-addFail_supplier_rerationship").on("hidden.bs.modal", function () {
              //     window.location.reload();
              // });

              // $("#modal-addFail_supplier_rerationship").modal("show");
            });
            // console.log('showMoal', showModal)
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } else {
      console.error("กรุณาเลือกข้อมูล");
      Swal.fire("แจ้งเตือน", "กรุณาเลือกคลังสินค้า", "warning");
      window.location.reload();
    }
  };

  const removeDeliveryOrderAll = async () => {
    // console.log("removeDOAll----", removeDOAll);
    Swal.fire({
      title: "คุณมั่นใจที่จะลบใช่หรือไม่",
      text: "คุณต้องการลบรายการ Delivery Order ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url: Configs.API_URL_AIOI_Back + "/api/deliveryOrder/removeAll",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data: removeDOAll.data,
        })
          .then(function (response) {
            if (response.data.data === true) {
              Swal.fire({
                icon: "success",
                title: "ลบข้อมูลสำเร็จ",
                allowEnterKey: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timer: 1500,
                showConfirmButton: false,
              }).then(() => {
                setRemoveDOAll({
                  ...removeDOAll,
                  data: [],
                });
                removeDOAll.data = [];
                getTableDelivery();
              });
            }
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error.response.data.error.message);
            if (
              error.response.data.error.message &&
              error.response.data.error.message
            ) {
              if (
                error.response.data.error.message.includes(
                  "ข้อมูล Delivery Order มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่"
                )
              ) {
                Swal.fire(
                  "Error",
                  "ข้อมูล Delivery Order มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่",
                  "error"
                ).then(() => {
                  window.location.reload();
                });
              } else {
                Swal.fire(
                  "Error",
                  "เกิดข้อผิดพลาด: " + error.response.data,
                  "error"
                );
              }
            } else {
              Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            }
          });
      }
    });
  };

  async function getTableDeliveryItem() {
    // console.log(">>>>>", showMoveTable.dl_id);
    await axios({
      method: "get",
      url:
        Configs.API_URL_AIOI_Back +
        "/api/deliveryOrder/getTableDeliveryItem/" +
        showMoveTable.dl_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        // console.log("response", response.data.data.rows);
        if (response.data.data && response.data.data.length > 0) {
          setGetTableItem(() => ({
            ...getTableItem,
            data: response.data.data,
            // getTableItem.data = response.data.data
          }));
          // console.log("tableItem",getTableItem);
        } else {
          setGetTableItem({
            ...getTableItem,
            data: [],
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getSelectItem() {
    // console.log(">>>>>", showMoveTable.dl_id);
    const dataTemp = {
      dl_company_id: getUser().com,
      dl_oem_id: getOem(),
      dl_wh_id: showMoveTable.wh_id,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_AIOI_Back +
        "/api/deliveryOrder/getSelectItem/" +
        showMoveTable.dl_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: dataTemp,
    })
      .then(async function (response) {
        // console.log("response", response.data.data.rows);
        setGetSelectTableItem(
          response.data.data.map((el) => {
            // console.log(el.dl_no);
            return {
              value: el.dl_id,
              label: el.dl_no,
            };
          })
        );
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function moveItemToDo() {
    // const tempAry = [selectedOption.value,selectedOption.label,...moveItem.data]
    const tempData = {
      dl_id: selectedOption.value,
      dl_no: selectedOption.label,
      moveItem: moveItem.data,
      dp_updated_by_id: getUser().fup,
      dp_updated_by: getUser().name + " " + getUser().surname,
      dp_company_id: getUser().com,
      dp_oem_id: getOem(),
      from_dl_id: showMoveTable.dl_id,
    };

    // console.log(">>>>>", tempData);
    if (Object.keys(selectedOption).length === 0) {
      // console.log("เข้า");
      Swal.fire("Error", "เกิดข้อผิดพลาด: กรุณาเลือก DO", "error");
      return;
    }
    if (moveItem.data.length === 0) {
      Swal.fire("Error", "เกิดข้อผิดพลาด: กรุณาเลือก Item", "error");
      return;
    }
    const checkQty = moveItem.data.filter((e) => {
      return e.dp_move === undefined || Number(e.dp_move) === 0;
    });
    if (checkQty.length > 0) {
      Swal.fire("Error", "เกิดข้อผิดพลาด: กรุณากรอก Quantity Move", "error");
      return;
    } else if (
      !moveItem.data.every((e) => parseInt(e.dp_move) === parseFloat(e.dp_move))
    ) {
      Swal.fire(
        "Error",
        "เกิดข้อผิดพลาด: กรุณากรอก Quantity Move เป็นจำนวนเต็ม",
        "error"
      );
      return;
    } else if (
      !moveItem.data.every((e) => !isNaN(e.dp_move) && e.dp_move >= 0)
    ) {
      Swal.fire(
        "Error",
        "เกิดข้อผิดพลาด: กรุณากรอก Quantity Move เป็นจำนวนเต็มบวก",
        "error"
      );
      return;
    } else if (
      moveItem.data.some((e) => parseInt(e.dp_move) > parseInt(e.dp_quantity))
    ) {
      Swal.fire(
        "Error",
        "เกิดข้อผิดพลาด: ไม่สามารถ move ได้ เนื่องจาก Quantity Move กรอกเกินจำนวนทั้งหมด",
        "error"
      );
      return;
    }

    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/deliveryOrder/moveItemToDo/",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        // console.log("response.data", response.data.data);
        if (!response.data.data.status) {
          Swal.fire("Error", response.data.data.message, "error");
        } else {
          Swal.fire("Success", "เสร็จสิ้น", "success").then(() => {
            window.location.reload();
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function moveDOtoWarehouse() {
    if (selectWarehouse.data === null) {
      Swal.fire("Error", "กรุณาเลือก Warehouse ที่จะย้ายไป", "error");
    } else {
      const tempData = {
        company_id: getUser().com,
        oem_id: getOem(),
        dl_id: showMoveTable.dl_id,
        wh_id: selectWarehouse.data.value,
        update_by: getUser().fup,
      };

      await axios({
        method: "post",
        url: Configs.API_URL_AIOI_Back + "/api/deliveryOrder/moveDOtoWarehouse",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempData,
      })
        .then(async function (response) {
          if (!response.data.data.status) {
            Swal.fire("Error", response.data.data.message, "error");
          } else {
            Swal.fire("Success", "เสร็จสิ้น", "success").then(() => {
              window.location.reload();
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
    }
  }

  const rowsData = [];
  for (let index = 0; index < deliveryData.data.length; index += 1) {
    const rowItem = {};

    rowItem["select"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap", margin: "0 auto" }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <div className="form-group">
            <input
              type="checkbox"
              hidden={deliveryData.data[index].ds_name === "Complete"}
              className="custom-checkbox"
              id={`checkbox${index}`}
              key={`checkbox${index}`}
              checked={removeDOAll.data.find((e) => {
                return e === deliveryData.data[index].dl_id;
              })}
              onClick={(e) => {
                // console.log("Checkbox checked status:", e.target.checked);
                if (e.target.checked) {
                  const temp = [...removeDOAll.data];
                  temp.push(deliveryData.data[index].dl_id);
                  setRemoveDOAll({
                    ...removeDOAll,
                    data: temp,
                  });
                  removeDOAll.data = temp;
                  // console.log("temp", temp);
                } else {
                  const updatedRemoveDOAll = removeDOAll.data.filter(
                    (item) => item !== deliveryData.data[index].dl_id
                  );
                  setRemoveDOAll({
                    ...removeDOAll,
                    data: updatedRemoveDOAll,
                  });
                }
                // getTableDelivery();
              }}
              style={{ width: "20px", height: "20px" }}
            ></input>
          </div>
        </div>
      </div>
    );

    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["no2"] = index + 1;

    rowItem["dl_no"] = (
      <div style={{ textAlign: "left" }}>{deliveryData.data[index].dl_no}</div>
    );
    rowItem["dl_no2"] = deliveryData.data[index].dl_no;
    // rowItem["unit_name"] = importDelivery.data[index].unit_name;
    rowItem["dl_delivery_date"] = (
      <div style={{ textAlign: "left" }}>
        {moment(deliveryData.data[index].dl_delivery_date).format("DD-MM-YYYY")}
      </div>
    );
    rowItem["dl_delivery_date2"] = moment(
      deliveryData.data[index].dl_delivery_date
    ).format("DD-MM-YYYY");

    rowItem["dl_location"] = (
      <div style={{ textAlign: "left" }}>
        {deliveryData.data[index].dl_location}
      </div>
    );
    rowItem["dl_location2"] = deliveryData.data[index].dl_location;

    rowItem["wh_name"] = (
      <div style={{ textAlign: "left" }}>
        {deliveryData.data[index].wh_name}
      </div>
    );
    rowItem["wh_name"] = deliveryData.data[index].wh_name;

    rowItem["ds_name"] = (
      <div style={{ textAlign: "left" }}>
        {deliveryData.data[index].ds_name === "Ready to pick" ? (
          <span className="badge badge-success">
            {deliveryData.data[index].ds_name || "-"}
          </span>
        ) : deliveryData.data[index].ds_name === "In progress" ? (
          <span className="badge badge-warning">
            {deliveryData.data[index].ds_name || "-"}
          </span>
        ) : deliveryData.data[index].ds_name === "Complete" ? (
          <span className="badge badge-primary">
            {deliveryData.data[index].ds_name || "-"}
          </span>
        ) : (
          <span className="badge badge-danger">
            {deliveryData.data[index].ds_name || "-"}
          </span>
        )}
      </div>
    );

    // rowItem["unit_remark2"] = importDelivery.data[index].unit_remark;

    rowItem["management"] =
      deliveryData.data[index].ds_name === "Ready to pick" ? (
        <div
          className="row "
          style={{ flexWrap: "nowrap", margin: "0 auto", textAlign: "left" }}
        >
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              // key={importDelivery.data[index].unit_id}
              id="btn_view"
              className=" btn btn-xs "
              onClick={() => {
                setShowMoveTable({
                  ...showMoveTable,
                  mode: "view",
                  dl_id: deliveryData.data[index].dl_id,
                  wh_id: deliveryData.data[index].dl_wh_id,
                  dl_no: deliveryData.data[index].dl_no,
                });
                showMoveTable.mode = "view";
                showMoveTable.dl_id = deliveryData.data[index].dl_id;
                showMoveTable.wh_id = deliveryData.data[index].dl_wh_id;
                showMoveTable.dl_no = deliveryData.data[index].dl_no;
                getTableDeliveryItem();
                getSelectItem();
              }}
            >
              <i class="fas fa-eye"></i>
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3  ">
            <a
              // key={importDelivery.data[index].unit_id}
              id="btn_edit"
              className=" btn btn-xs "
              type="button"
              value="edit"
              onClick={() => {
                getDropdownWarehouse(true, deliveryData.data[index].dl_wh_id);
                setShowQuantityMove(true);
                setShowMoveTable({
                  ...showMoveTable,
                  mode: "edit",
                  dl_id: deliveryData.data[index].dl_id,
                  wh_id: deliveryData.data[index].dl_wh_id,
                  dl_no: deliveryData.data[index].dl_no,
                });
                showMoveTable.mode = "edit";
                showMoveTable.dl_id = deliveryData.data[index].dl_id;
                showMoveTable.wh_id = deliveryData.data[index].dl_wh_id;
                showMoveTable.dl_no = deliveryData.data[index].dl_no;
                getTableDeliveryItem();
                getSelectItem();
                setMoveItem({
                  ...moveItem,
                  data: [],
                });
                moveItem.data = [];
                clearSelectState();
                // console.log("move", []);
              }}
            >
              <i
                class="fas fa-pencil-alt"
                style={{
                  fontSize: "14px",
                }}
              ></i>
            </a>
          </div>
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              // key={importDelivery.data[index].unit_id}
              id="btn_delete"
              className=" btn btn-xs "
              onClick={async () => {
                // console.log("checked", deliveryData.data[index].dl_id);
                let temp = [];
                temp.push(deliveryData.data[index].dl_id);
                setRemoveDOAll({
                  ...removeDOAll,
                  data: temp,
                });
                removeDOAll.data = temp;
                // console.log("removeDOAllremoveDOAllremoveDOAll", removeDOAll);
                await removeDeliveryOrderAll();
              }}
            >
              <i class="fas fa-trash-alt"></i>
            </a>
          </div>
        </div>
      ) : (
        <div
          className="row "
          style={{ flexWrap: "nowrap", margin: "0 auto", textAlign: "left" }}
        >
          <div className="col-xl-3 col-md-3 col-xs-3 ">
            <a
              // key={importDelivery.data[index].unit_id}
              id="btn_view"
              className=" btn btn-xs "
              onClick={() => {
                setShowMoveTable({
                  ...showMoveTable,
                  mode: "view",
                  dl_id: deliveryData.data[index].dl_id,
                  wh_id: deliveryData.data[index].dl_wh_id,
                  dl_no: deliveryData.data[index].dl_no,
                });
                showMoveTable.mode = "view";
                showMoveTable.dl_id = deliveryData.data[index].dl_id;
                showMoveTable.wh_id = deliveryData.data[index].dl_wh_id;
                showMoveTable.dl_no = deliveryData.data[index].dl_no;
                getTableDeliveryItem();
                getSelectItem();
              }}
            >
              <i class="fas fa-eye"></i>
            </a>
          </div>
        </div>
      );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"Select"}</div>,
        field: "select",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Delivery No."}</div>,
        field: "dl_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Delivery Date"}</div>,
        field: "dl_delivery_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Delivery Location"}</div>,
        field: "dl_location",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Warehouse"}</div>,
        field: "wh_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Status"}</div>,
        field: "ds_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Management"}</div>,
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const rowsData1 = [];
  for (let index = 0; index < getTableItem.data.length; index += 1) {
    const rowItem1 = {};

    rowItem1["select"] = (
      <div
        className="row justify-content-center"
        style={{ flexWrap: "nowrap", margin: "0 auto" }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <div className="form-group">
            <input
              type="checkbox"
              id={`checkbox2${index}`}
              key={`checkbox2${index}`}
              className="custom-checkbox"
              style={{ width: "20px", height: "20px" }}
              checked={
                moveItem.data.find((e) => {
                  return e.dp_id === getTableItem.data[index].dp_id;
                }) || false
              }
              disabled={showMoveTable.mode == "view"}
              onClick={(e) => {
                // console.log("Checkbox ItemTable:", e.target.checked);
                // console.log(
                //   "getTableItem.data[index].dp_id:",
                //   getTableItem.data[index]
                // );
                if (e.target.checked) {
                  const temp = [...moveItem.data];
                  temp.push(getTableItem.data[index]);
                  setMoveItem({
                    ...moveItem,
                    data: temp,
                  });
                  moveItem.data = temp;
                  // console.log("move", temp);
                } else {
                  const updatedMoveItem = moveItem.data.filter(
                    (item) => item.dp_id !== getTableItem.data[index].dp_id
                  );
                  setMoveItem({
                    ...moveItem,
                    data: updatedMoveItem,
                  });
                }
                // setData1({ ...data1, columns: newData1Columns });
              }}
            />
          </div>
        </div>
      </div>
    );

    rowItem1["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem1["no2"] = index + 1;

    rowItem1["dp_po_no"] = (
      <div style={{ textAlign: "left" }}>
        {getTableItem.data[index].dp_po_no}
      </div>
    );
    rowItem1["dp_po_no"] = getTableItem.data[index].dp_po_no;

    // rowItem["unit_name"] = importDelivery.data[index].unit_name;
    rowItem1["dp_part_no"] = (
      <div style={{ textAlign: "left" }}>
        {getTableItem.data[index].dp_part_no}
      </div>
    );
    rowItem1["dp_part_no"] = getTableItem.data[index].dp_part_no;

    rowItem1["dp_part_name"] = (
      <div style={{ textAlign: "left" }}>
        {getTableItem.data[index].dp_part_name}
      </div>
    );
    rowItem1["dp_part_name"] = getTableItem.data[index].dp_part_name;

    rowItem1["dp_quantity"] = (
      <div style={{ textAlign: "left" }}>
        {getTableItem.data[index].dp_quantity}
      </div>
    );
    rowItem1["dp_quantity"] = getTableItem.data[index].dp_quantity;

    rowItem1["quantity_move"] = (
      <div>
        <NumberFormat
          decimalScale={0}
          allowNegative={false}
          type="text"
          className=""
          value={getTableItem.data[index].dp_move || ""}
          onChange={(e) => {
            const qtyProduct = e.target.value;
            getTableItem.data[index].dp_move = qtyProduct;
          }}
          // style={{ width: "100%", height: "20px" }}
          disabled={showMoveTable.mode == "view"}
        ></NumberFormat>
      </div>
    );

    rowsData1.push(rowItem1);
  }

  const data1 = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"Select"}</div>,
        field: "select",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Po No."}</div>,
        field: "dp_po_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part No."}</div>,
        field: "dp_part_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part Name"}</div>,
        field: "dp_part_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Quantity"}</div>,
        field: "dp_quantity",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Quantity Move"}</div>,
        field: "quantity_move",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData1,
  };

  if (!showQuantityMove && showMoveTable.mode === "edit") {
    const indexToDisable = data1.columns.findIndex(
      (column) => column.field === "quantity_move"
    );
    if (indexToDisable !== -1) {
      // Set 'editable' property of the column to false
      data1.columns[indexToDisable].editable = false;
      // หรือหากคุณใช้คุณสมบัติ 'disabled' ในการควบคุมคอลัมน์
      // data1.columns[indexToDisable].disabled = true;
    }
  }

  const rowsData2 = [];
  for (let index = 0; index < material_exel.data.length; index += 1) {
    const rowItem2 = {};

    rowItem2["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem2["no2"] = index + 1;

    rowItem2["db_po"] = (
      <div style={{ textAlign: "left" }}>
        {material_exel.data[index].db_po || "-"}
      </div>
    );
    rowItem2["db_po"] = material_exel.data[index].db_po || "-";
    // console.log("db_po", material_exel.data[index].db_po);
    // rowItem["unit_name"] = importDelivery.data[index].unit_name;
    rowItem2["db_part_no"] = (
      <div style={{ textAlign: "left" }}>
        {material_exel.data[index].db_part_no || "-"}
      </div>
    );
    rowItem2["db_part_no"] = material_exel.data[index].db_part_no || "-";

    rowItem2["db_part_name"] = (
      <div style={{ textAlign: "left" }}>
        {material_exel.data[index].part_name || "-"}
      </div>
    );
    rowItem2["db_part_name"] = material_exel.data[index].part_name || "-";

    rowItem2["error"] =
      material_exel.data[index]?.error.length > 0 ? (
        <div
          style={{ textAlign: "left", whiteSpace: "pre-line", color: "red" }}
        >
          {material_exel.data[index]?.error.join("\n")}
        </div>
      ) : (
        <div style={{ textAlign: "left", color: "green" }}>
          เพิ่มข้อมูลสำเร็จ
        </div>
      );

    // rowItem["unit_remark2"] = importDelivery.data[index].unit_remark;

    rowsData2.push(rowItem2);
  }

  const data2 = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"PO Name"}</div>,
        field: "db_po",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part No."}</div>,
        field: "db_part_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part Name"}</div>,
        field: "db_part_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Remark"}</div>,
        field: "error",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData2,
  };

  // const [showModal, setShowModal] = useState(false);

  //   const closeModal = () => {
  //     setShowModal(false);
  //   };

  useEffect(async () => {
    await getTableDelivery();
  }, []);

  // useEffect(() => {
  //   console.log('showModal', showModal);
  // }, [showModal]);

  return (
    <div className="wrapper">
      {/* <Header />
          <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Import Delivery Order (AIOI)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  {/* <li className="breadcrumb-item active">Human Resource</li> */}
                  <li className="breadcrumb-item active">
                    Import Delivery Order (AIOI)
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    id="text_empid"
                    required="false"
                    value={filter.data}
                    onChange={(e) =>
                      setfilter({
                        ...filter,
                        data: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      setFilterTable({
                        is_check: false,
                      });
                      filterTable.is_check = false;
                      getTableDelivery();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      setFilterTable({
                        is_check: false,
                      });
                      filterTable.is_check = false;
                      clearFilter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    id="btn_add"
                    value="add"
                    className="btn btn-block btn-primary"
                    type="button"
                    data-toggle="modal"
                    data-target="#modal-upload_supplier_rerationship"
                    class="btn btn-block btn-primary "
                    onClick={() => {
                      setexcel({
                        name: "",
                        file: null,
                        status: null,
                      });
                      setSelectedOption([]);
                      getDropdownWarehouse();
                    }}
                    // hidden={disable}
                    // onClick={() => {
                    //   setUnitAddData({
                    //     ...unitAddData,
                    //     unit_name: "",
                    //     unit_remark: "",
                    //     mode: "add",
                    //   });
                    //   clearFilter();
                    // }}
                  >
                    Upload Delivery Order
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    id="btn_add"
                    value="add"
                    className="btn btn-block btn-primary"
                    type="button"
                    class="btn btn-block btn-primary "
                    onClick={removeDeliveryOrderAll}
                    // hidden={disable}
                    // onClick={() => {
                    //   setUnitAddData({
                    //     ...unitAddData,
                    //     unit_name: "",
                    //     unit_remark: "",
                    //     mode: "add",
                    //   });
                    //   clearFilter();
                    // }}
                  >
                    Delete Delivery Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="card">
            <div className="card-header">
              <h4>Import Delivery Order (AIOI)</h4>
            </div>
            <div className="card-body">
              <div
                className="table-responsive "
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  disableRetreatAfterSorting={filterTable.is_check}
                  fixedHeader
                  data={data}
                  //disableRetreatAfterSorting={MDBT}
                />
              </div>
            </div>
            <div className="card-footer" />
          </div>
        </section>

        {showMoveTable.mode === "edit" ? (
          <section>
            <div className="card">
              <div className="card-header">
                <h3>Delivery No : {showMoveTable.dl_no}</h3>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-6 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <Select
                          type="text"
                          id="text_empid"
                          required="false"
                          value={selectedOption}
                          options={getSelectTableItem}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={handleSelectChange}
                          // onClick={()=>{
                          //   setRemoveDOAll({
                          //     is_checked: false,})
                          //     removeDOAll.is_checked = false
                          // }}
                        />
                        <label htmlFor="">Select Do</label>{" "}
                      </div>
                    </div>

                    <div className="col-6 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-primary"
                          style={{
                            padding: "8px",
                          }}
                          onClick={moveItemToDo}
                          // onClick={()=>{
                          //   setFilterTable({
                          //     is_checked: false})
                          //     filterTable.is_check = false
                          //   getTableUnit()
                          // }}
                        >
                          Move Item To DO
                        </button>
                      </div>
                    </div>

                    <div className="col-6 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <Select
                          // isClearable
                          type="text"
                          id="text_empid"
                          required="false"
                          value={selectWarehouse.data}
                          options={warehouseList}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            setSelectWarehouse({
                              ...selectWarehouse,
                              data: e,
                            });
                          }}
                        />
                        <label htmlFor="">Select Warehouse</label>{" "}
                      </div>
                    </div>

                    <div className="col-6 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          id="btn_search"
                          className="btn btn-block btn-primary"
                          style={{
                            padding: "8px",
                          }}
                          onClick={() => {
                            moveDOtoWarehouse();
                          }}
                          // onClick={()=>{
                          //   setFilterTable({
                          //     is_checked: false})
                          //     filterTable.is_check = false
                          //   getTableUnit()
                          // }}
                        >
                          Move DO to Warehouse
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="table-responsive "
                  style={{ whiteSpace: "nowrap" }}
                >
                  <MDBDataTable
                    sortable={false}
                    disableRetreatAfterSorting={true}
                    className="table table-head-fixed"
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={data1}
                  />
                </div>
              </div>
              <div className="card-footer" />
            </div>
          </section>
        ) : showMoveTable.mode === "view" ? (
          <>
            <section>
              <div className="card">
                <div className="card-header">
                  <h3>Delivery No : {showMoveTable.dl_no}</h3>
                </div>
                <div className="card-body">
                  <div
                    className="table-responsive "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={data1}
                    />
                  </div>
                </div>
                <div className="card-footer" />
              </div>
            </section>
          </>
        ) : (
          <></>
        )}

        <div className="modal fade" id="modal-upload_supplier_rerationship">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-label">
                  Upload Group PO{" "}
                  {/* {unitAddData.mode === "add" ? "Add Unit" : "Edit Unit"} */}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="col-12 ">
                  <div className="none-nolmalinput">
                    <label htmlFor="">File:</label>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          /* disabled={} */
                          className="custom-file-input"
                          id="exampleInputFile"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={(e) => {
                            // console.log("first", e.target.files[0].name);
                            setexcel({
                              ...excel,
                              file: e.target.files[0],
                              name: e.target.files[0].name,
                            });
                            excel.file = e.target.files[0];
                            excel.name = e.target.files[0].name;
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                        >
                          {excel.name ? excel.name : "Select File"}
                        </label>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-xl-12 p-0">
                      <label
                        htmlFor=""
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        Warehouse
                      </label>
                      <div className="form-group ">
                        <Select
                          type="text"
                          id="text_empid"
                          required={false}
                          value={selectedOption}
                          options={selectConfig}
                          onChange={handleSelectChange}
                        />
                        <label htmlFor="">Search</label>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  // data-toggle="modal"
                  // data-target="#modal-addFail_supplier_rerationship"
                  //   onClick={() =>{
                  //     if(unitAddData.mode === "add"){
                  //       console.log("Add")
                  //       Add_Unit(unitAddData.unit_id);
                  //     }  else if(unitAddData.mode === "edit"){
                  //       console.log("Edit")
                  //       saveEditUnit(unitAddData.unit_id);

                  //     }
                  //   }}
                  data-dismiss="modal"
                  className="btn btn-primary"
                  onClick={(e) => {
                    uploadFile();
                  }}
                >
                  Save
                </button>

                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* { showModal && ( */}
        <Modal
          isOpen={modal}
          toggle={toggle_modal}
          className="modal-dialog modal-xl"
        >
          {/* <div className="modal fade " id="modal-addFail_supplier_rerationship">
          <div className="modal-dialog modal-xl">
            <div className="modal-content"> */}
          <ModalHeader toggle={toggle_modal}>
            Upload Group PO ( Fail Item ){" "}
          </ModalHeader>
          <ModalBody>
            <div className="col-12 ">
              <div className="card">
                <div className="card-body">
                  <div
                    className="table-responsive "
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed "
                      striped
                      bordered
                      hover
                      //   disableRetreatAfterSorting={filterTable.is_check}
                      fixedHeader
                      data={data2}
                      //disableRetreatAfterSorting={MDBT}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="justify-content-end">
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  toggle_modal();
                }}
                // style={{
                //   float: "right",
                // }}
              >
                Close
              </button>
            </div>
          </ModalFooter>
        </Modal>
        {/* )} */}
      </div>
    </div>
  );
}

export default ImportDeliveryOrder;
