import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";

function Mat_type(params) {
  const [mat_type, setmat_type] = useState([]);
  const [company, setCompany] = useState([]);
  /* console.log(getUser().com); */
  const [filler_mat_type, setFiller_mat_type] = useState({
    mat_type: "",
   company_id: getUser().com,
  });
  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetType = async () => {
    
    //console.log(filler_supplier);
    axios({
      method: "post",
      url: Configs.API_URL + "/material/filtermattype",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_mat_type
    })
      .then(function (response) {
        // console.log(response.data);
        setmat_type(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetType();
    GetCompany();
  }, []);

  const clearFilter = async () => {
    await setFiller_mat_type({
      ...filler_mat_type,
      mat_type:"",
    });
   
    filler_mat_type.mat_type = "";
    GetType();
   
  };

  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Mat_type/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL +"/material/mattypedel?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Mat_type";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];
 
  for (var index = 0; index < mat_type.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["name"] = mat_type[index].name;
    rowItem["remark"] = mat_type[index].remark;

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
             href={"/Warehouse_Management/Factory_Master_Data/Mat_type/read/"+ mat_type[index].id}
            key={mat_type[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
              href={"/Warehouse_Management/Factory_Master_Data/Mat_type/edit/"+ mat_type[index].id}
             key={mat_type[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
             key={mat_type[index].id}
            className=" btn btn-xs "
             onClick={deldata.bind(this,mat_type[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mat Type",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark", 
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Mat Type{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Mat Type</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_mat_type.mat_type.trim()}
                    onChange={(e) => {
                      setFiller_mat_type({
                        ...filler_mat_type,
                        mat_type: e.target.value.trim(),
                      });
                    }} 
                  />
                  <label htmlFor="">Mat Type</label>{" "}
                </div>
              </div>
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetType();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
              
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Mat Type</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive " style={{ whiteSpace: 'nowrap' }}
            >
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    {/*  <Footter/> */}
     </div>
     
  );
}

export default Mat_type;
