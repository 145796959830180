import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../Utils/Common";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Select from "react-select";

function Warehouse_history(params) {
  const [user, setUser] = useState(getUser());
  const [cutting, setcutting] = useState([]);
  const [filler_cutting, setFiller_cutting] = useState({
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
  });
  const [feature, setfeature] = useState([]);
  const [material, setmaterial] = useState([]);
  const [mat_lot_view_list, setmat_lot_view_list] = useState({
    deflux_lot: [],
    mat_lot: "",
  });
  const [tooling_brand_option, settooling_brand_option] = useState([]);
  const [filler_material, setFiller_material] = useState({
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
    date_start: "",
    date_end: "",
  });
  const [filterOption, setfilterOption] = useState({
    tooling_code: "",
    tooling_name: "",
    brand_id: "",
    date_start : "",
    date_end :"",
    oem_id: getOem(),
    company_id: getUser().com,
  });
  //deflux_STP
  const [deflux_list, setdeflux_list] = useState([]);
  const [filter_deflux, setFilter_deflux] = useState({
    company_id: getUser().com,
    oem_id: getOem(),
    diameter_no: "",
    diameter_name: "",
    edit_type: "",
    amount: "",
    create_date: "",
    date_start: "",
    date_end: "",
  });
  const [deflux_lot_view_list, setdeflux_lot_view_list] = useState({
    deflux_lot: "",
    mat_lot: [],
  });
  const [tooling, settooling] = useState([]);
  //deflux_STP

  let [showActive, setShowActive] = useState("");

  const [product_fg, setproduct_fg] = useState([]);
  //------------no project
  /* const [filler_product_fg, setFiller_product_fg] = useState({
  oem_id: getOem(),
  no: "",
  name: "",
  edit_type: "",
  amount:"",
  create_date:""
}); */

  //-------------have project
  const [filler_product_fg, setFiller_product_fg] = useState({
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
    project_name: "",
    date_start_fg: "",
    date_end_fg: "",
  });

  const [product_wip, setproduct_wip] = useState([]);
  const [filler_product_wip, setFiller_product_wip] = useState({
    oem_id: getOem(),
    no: "",
    name: "",
    edit_type: "",
    amount: "",
    create_date: "",
  });
  const GetTooling_brand = async () => {
    var config_brand = {
      method: "get",
      url:
        Configs.API_URL +
        `/tooling/getToolingBrand?id=${getUser().com}&id2=${getOem()}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    };

    axios(config_brand)
      .then(function (response) {
        /* setBrand(response.data); */
        const options = [{ value: "", label: "Select All" }];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.brand_id;
          element["label"] = el.brand_name;
          options.push(element);
        });
        settooling_brand_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetCutting = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/history/cutting/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_cutting,
    })
      .then(function (response) {
        //console.log(response.data);
        setcutting(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetDeflux = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/history/deflux/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_deflux,
    })
      .then(function (response) {
        //console.log(response.data);
        setdeflux_list(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function getLotInStock() {
    let data = {
      tooling_name: filterOption.tooling_name.trim(),
      tooling_code: filterOption.tooling_code.trim(),
      brand_id: filterOption.brand_id,
      date_start: filterOption.date_start !== "" ? moment(filterOption.date_start).format("YYYY-MM-DD 00:00:00.000+07") :"",
      date_end: filterOption.date_end !== "" ? moment(filterOption.date_end).format("YYYY-MM-DD 23:59:59.000+07") :"",
      company_id: getUser().com,
      oem_id: getOem(),
    };
    axios({
      method: "post",
      url: Configs.API_URL + `/tooling/filterHistoryTooling`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (response) {
        console.log("LotInStock", response.data);
        settooling(response.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.error.message, "error");
      });
  }
  const GetMaterial = async () => {
    var temp = {
      oem_id: filler_material.oem_id,
      no: filler_material.no.trim(),
      name: filler_material.name.trim(),
      edit_type: filler_material.edit_type,
      amount: filler_material.amount,
      create_date: filler_material.create_date,
      date_start: filler_material.date_start,
      date_end: filler_material.date_end,
    };
    //console.log("getOem",getOem());
    //console.log("currentOEM",currentOEM.value);
    let filter_o = "";
    if (
      getOem() === currentOEM.value &&
      currentOEM.label === "บจก.เอสทีสตีล(2001) ปัตตานี"
    ) {
      filter_o = "/history/materialSTP/filter";
      //console.log("เข้าเงทอนไข");
    } else {
      filter_o = "/history/material/filter";
      //console.log("ไม่เข้าเงือนไข");
    }
    //console.log("filter_o",filter_o);
    //{getOem() === currentOEM.value && currentOEM.label ==="บจก.สตูลค้าเหล็ก" ? (
    //console.log("oem",getOem(),currentOEM);
    axios({
      method: "post",
      url: Configs.API_URL + filter_o,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        console.log("filter", response.data);
        setmaterial(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //--------------------มี project
  const GetProduct_fg = async () => {
    var temp = {
      oem_id: filler_product_fg.oem_id,
      no: filler_product_fg.no.trim(),
      name: filler_product_fg.name.trim(),
      edit_type: filler_product_fg.edit_type,
      amount: filler_product_fg.amount,
      create_date: filler_product_fg.create_date,
      project_name: filler_product_fg.project_name,
      date_start_fg: filler_product_fg.date_start_fg,
      date_end_fg: filler_product_fg.date_end_fg,
    };
    console.log("getOem", getOem());
    console.log("currentOEM", currentOEM.value);
    let filter_o1 = "";
    if (
      getOem() === currentOEM.value &&
      currentOEM.label === "บจก.เอสทีสตีล(2001) ปัตตานี"
    ) {
      filter_o1 = "/history/productStp_fg_project/filter";
      //console.log("เข้าเงทอนไข");
    } else {
      filter_o1 = "/history/product_fg_project/filter";
      //console.log("ไม่เข้าเงือนไข");
    }
    //console.log("filter_o1",filter_o1);
    axios({
      method: "post",
      url: Configs.API_URL + filter_o1,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        //console.log("GetProduct_fg",response.data);
        setproduct_fg(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //-------------------------แบบไม่มี project
  /* const GetProduct_fg = async () => {
  axios({
    method: "post",
    url: Configs.API_URL + "/history/product_fg/filter",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filler_product_fg
  
  })
    .then(function (response) {
      console.log(response.data);
      setproduct_fg(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}; */

  const GetProduct_wip = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/history/product_wip/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product_wip,
    })
      .then(function (response) {
        //console.log("GetProduct_wip",response.data);
        setproduct_wip(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [company, setCompany] = useState([]);
  const [company2, setCompany2] = useState([]);
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + user.com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  //console.log("companys",companys);
  useEffect(() => {
    axios(config_com)
      .then(function (response) {
        const data = response.data;
        setCompany2(data);
        /*         data.oem.sort((a,b)=>a.sort - b.sort);
        setOem(data.oem); */
      })
      .catch(function (error) {
        console.log(error);
      });
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Warehouse History";
    });
    // console.log("feature_data", feature_data);
    //  console.log(feature_data[0].feature);
    const fineWarehouse = feature_data.filter((el)=>{
      return el.feature === "Material"
    })
    setShowActive(fineWarehouse.length===1 ? "Material":feature_data[0].feature);
    showActive = fineWarehouse.length===1 ? "Material":feature_data[0].feature;
    // console.log("showActive", "Material");
    // console.log("feature_data0", feature_data[0].feature);
    setfeature(feature_data);
    GetCutting();
    GetCompany();
    GetTooling_brand();
    getLotInStock();
    GetProduct_wip();
    axios(config_getOem)
      .then(function (response) {
        const options = [];
        var element2 = {};
        element2["value"] = "All_OEM";
        element2["label"] = "---ทั้งหมด---";
        options.push(element2);
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
          if (el.id === getOem()) {
            setCurrentOEM({ ...currentOEM, label: el.name, value: el.id });
            currentOEM.label = el.name;
            currentOEM.value = el.id;
          }
        });
        GetMaterial();
        GetDeflux();
        GetProduct_fg();
        setCompany(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  //console.log("nameOEM",currentOEM)
  const CustomInput = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>

        {/*  <label>test</label> */}
      </div>
    </div>
  );
  async function SetDate_start(date) {
    setFiller_material({ ...filler_material, date_start: date });
  }
  async function SetDate_end(date) {
    setFiller_material({ ...filler_material, date_end: date });
  }
  async function SetDate_start_fg(date) {
    setFiller_product_fg({ ...filler_product_fg, date_start_fg: date });
  }
  async function SetDate_end_fg(date) {
    setFiller_product_fg({ ...filler_product_fg, date_end_fg: date });
  }
  const cuttingrowsData = [];

  for (var index = 0; index < cutting.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["part_no"] = cutting[index].no;
    rowItem["part_name"] = cutting[index].name;
    if (cutting[index].edit_type == true) {
      rowItem["activity_type"] = "in";
    } else if (cutting[index].edit_type == false) {
      rowItem["activity_type"] = "out";
    } else {
      rowItem["activity_type"] = "";
    }
    rowItem["amount"] = cutting[index].amount;
    rowItem["date"] =
      moment(cutting[index].create_date).format("DD/MM/yyyy ::: HH:mm") + " น.";

    cuttingrowsData.push(rowItem);
  }

  function formMatLot(mat_detail, mat_lot, e) {
    //console.log(deflux_detail)
    setmat_lot_view_list({
      ...mat_lot_view_list,
      mat_lot: mat_lot,
      deflux_lot: mat_detail,
    });
    mat_lot_view_list.deflux_lot = mat_detail;
    mat_lot_view_list.mat_lot = mat_lot;
  }
  const materialrowsData = [];

  for (var index = 0; index < material.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["part_no"] = material[index].no;
    rowItem["part_name"] = material[index].name;
    rowItem["activity_type"] = material[index].activity_type;

    rowItem["amount"] = material[index].amount;
    rowItem["date"] =
      moment(material[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["username"] = material[index].username || "";

    /// stp
    rowItem["lot_no"] =
      company2.company_name === "บริษัท สตูลค้าเหล็ก จำกัด" ? (
        <a
          data-toggle="modal"
          data-target="#modal-mat_lot_no_detail"
          href=""
          onClick={formMatLot.bind(
            this,
            material[index].deflux_list,
            material[index].mat_lot_no
          )}
        >
          {material[index].mat_lot_no}
        </a>
      ) : (
        material[index].mat_lot_no
      );
    rowItem["weight_by_tag"] = material[index].weight_by_tag;
    rowItem["weight_actual"] = material[index].actual_weight;
    rowItem["weight_lose"] =
      100 -
      (
        (material[index].actual_weight / material[index].weight_by_tag) *
        100
      ).toFixed(2) +
      " %";

    materialrowsData.push(rowItem);
  }

  function formDefluxLot(deflux_detail, deflux_lot, e) {
    console.log(deflux_detail);
    setdeflux_lot_view_list({
      ...deflux_lot_view_list,
      mat_lot: deflux_detail,
      deflux_lot: deflux_lot,
    });
    deflux_lot_view_list.deflux_lot = deflux_lot;
    deflux_lot_view_list.mat_lot = deflux_detail;
  }

  const deflux_rowsData = [];
  for (var index = 0; index < deflux_list.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["diameter_no"] = deflux_list[index].diameter_no;
    rowItem["diameter_name"] = deflux_list[index].diameter;
    if (deflux_list[index].edit_type == true) {
      rowItem["activity_type"] = "in";
    } else if (deflux_list[index].edit_type == false) {
      rowItem["activity_type"] = "out";
    } else {
      rowItem["activity_type"] = "";
    }
    rowItem["deflux_lot_no"] = (
      <a
        data-toggle="modal"
        data-target="#modal-deflux_lot_no_detail"
        href=""
        onClick={formDefluxLot.bind(
          this,
          deflux_list[index].mat_lot_list,
          deflux_list[index].deflux_lot_no
        )}
      >
        {deflux_list[index].deflux_lot_no}
      </a>
    );

    rowItem["amount"] = deflux_list[index].amount;
    rowItem["date"] =
      moment(deflux_list[index].create_date).format("DD/MM/yyyy ::: HH:mm") +
      " น.";

    deflux_rowsData.push(rowItem);
  }

  const product_fg_rowsData = [];

  for (var index = 0; index < product_fg.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["part_no"] = product_fg[index].no;
    rowItem["part_name"] = product_fg[index].name;
    rowItem["activity_type"] = product_fg[index].activity_type;
    // if (product_fg[index].edit_type == true) {
    //   rowItem["activity_type"] = "in";
    // } else if (product_fg[index].edit_type == false) {
    //   rowItem["activity_type"] = "out";
    // } else {
    //   rowItem["activity_type"] = "";
    // }
    rowItem["amount"] = product_fg[index].amount;
    rowItem["project_name"] = product_fg[index].project;

    //rowItem["date"] =moment(product_fg[index].create_date).format('DD/MM/yyyy ::: HH:mm:ss a  ');
    rowItem["date"] =
      moment(product_fg[index].date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["stp_machine"] = product_fg[index].stp_machine || "";
    rowItem["username"] = product_fg[index].username || "";
    rowItem["Remark"] = product_fg[index].remark || "";
    product_fg_rowsData.push(rowItem);
  }

  const product_wip_rowsData = [];

  for (var index = 0; index < product_wip.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["part_no"] = product_wip[index].no;
    rowItem["part_name"] = product_wip[index].name;
    if (product_wip[index].edit_type == true) {
      rowItem["activity_type"] = "in";
    } else if (product_wip[index].edit_type == false) {
      rowItem["activity_type"] = "out";
    } else {
      rowItem["activity_type"] = "";
    }
    rowItem["amount"] = product_wip[index].amount;
    rowItem["date"] =
      moment(product_wip[index].create_date).format("DD/MM/yyyy ::: HH:mm") +
      " น.";
    rowItem["Remark"] = product_wip[index].remark || "";

    product_wip_rowsData.push(rowItem);
  }

  const clearFilter = async () => {
    await setFiller_cutting({
      ...filler_cutting,
      no: "",
      name: "",
      create_date: "",
      // date_start_fg: "",
      // date_end_fg: "",
    });
    filler_cutting.no = "";
    filler_cutting.name = "";
    filler_cutting.create_date = "";
    // filler_cutting.date_start = "";
    // filler_cutting.date_end = "";
    GetCutting();

    await setFiller_material({
      ...filler_material,
      no: "",
      name: "",
      create_date: "",
      date_start: "",
      date_end: "",
    });
    filler_material.no = "";
    filler_material.name = "";
    filler_material.create_date = "";
    filler_material.date_start = "";
    filler_material.date_end = "";
    GetMaterial();

    setFilter_deflux({
      ...filter_deflux,
      diameter_no: "",
      diameter_name: "",

      date_start: "",
      date_end: "",
    });
    filter_deflux.diameter_no = "";
    filter_deflux.diameter_name = "";
    filter_deflux.date_start = "";
    filter_deflux.date_end = "";

    GetDeflux();

    await setFiller_product_fg({
      ...filler_product_fg,
      no: "",
      name: "",
      create_date: "",
      project_name: "",
      date_start_fg: "",
      date_end_fg: "",
    });
    filler_product_fg.no = "";
    filler_product_fg.name = "";
    filler_product_fg.create_date = "";
    filler_product_fg.project_name = "";
    filler_product_fg.date_start_fg = "";
    filler_product_fg.date_end_fg = "";
    GetProduct_fg();

    await setFiller_product_wip({
      ...filler_product_wip,
      no: "",
      name: "",
      create_date: "",
    });
    filler_product_wip.no = "";
    filler_product_wip.name = "";
    filler_product_wip.create_date = "";
    GetProduct_wip();

    setfilterOption({...filterOption,
      tooling_code: "",
      tooling_name: "",
      brand_id: "",
      date_start : "",
      date_end :"",
      oem_id: getOem(),
      company_id: getUser().com,
    })
    filterOption.tooling_code = "";
    filterOption.tooling_name = "";
    filterOption.brand_id = "";
    filterOption.date_start = "";
    filterOption.date_end = "";
    getLotInStock();
  };

  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail/add";
  }

  const data_cutting = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product Name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: cuttingrowsData,
  };

  const data_material = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนักตามป้าย",
        field: "weight_by_tag",
        sort: "asc",
        width: 50,
      },
      {
        label: "น้ำหนักชั่งจริง",
        field: "weight_actual",
        sort: "asc",
        width: 50,
      },
      {
        label: "สูญเสีย",
        field: "weight_lose",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "username",
        field: "username",
        sort: "asc",
        width: 50,
      },
      // {
      //   label: "Remark",
      //   field: "Remark",
      //   sort: "asc",
      //   width: 50,
      // },
    ],
    rows: materialrowsData,
  };

  if (getOemlist_() !== "บจก.เอสทีสตีล(2001) ปัตตานี") {
    var check1 = data_material.columns.filter((e1) => {
      return e1.field === "weight_by_tag";
    });
    if (check1.length > 0) {
      var index1 = data_material.columns.findIndex(
        (e1) => e1.field === "weight_by_tag"
      );
      data_material.columns.splice(index1, 1);
    }
    var check2 = data_material.columns.filter((e2) => {
      return e2.field === "weight_actual";
    });
    if (check2.length > 0) {
      var index2 = data_material.columns.findIndex(
        (e2) => e2.field === "weight_actual"
      );
      data_material.columns.splice(index2, 1);
    }
    var check3 = data_material.columns.filter((e3) => {
      return e3.field === "weight_lose";
    });
    if (check3.length > 0) {
      var index3 = data_material.columns.findIndex(
        (e3) => e3.field === "weight_lose"
      );
      data_material.columns.splice(index3, 1);
    }
    /* var check4 = data_material.columns.filter((e4)=> {return e4.field === "weight_by_tag"});
    if(check4.length > 0){
      var index4 =  data_material.columns.findIndex((e4)=>  e4.field === "weight_by_tag");
      data_material.columns.splice(index4,1);
    } */
  }

  const data_deflux = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Diameter no",
        field: "diameter_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Diameter Name",
        field: "diameter_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Deflux Lot",
        field: "deflux_lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: deflux_rowsData,
  };

  const data_product_fg = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Project",
        field: "project_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "STP machine",
        field: "stp_machine",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "username",
        field: "username",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "Remark",
        sort: "asc",
        width: 50,
      },
    ],
    rows: product_fg_rowsData,
  };
  //console.log("product_fg",product_fg[0]);
  var company_cheak = product_fg.filter((e) => {
    return e.company !== "บริษัท สตูลค้าเหล็ก จำกัด";
  });
  if (company_cheak.length > 0) {
    console.log("company_cheak", company_cheak[0]);

    var index = data_product_fg.columns.findIndex((e) => {
      return e.field === "stp_machine";
    });
    data_product_fg.columns.splice(index, 1);
  }

  //console.log("company_cheak0",company_cheak);

  const data_product_wip = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Product name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Activity_type",
        field: "activity_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: product_wip_rowsData,
  };
  const Tooling_rowsData = [];
  for (var index = 0; index < tooling.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["tooling_code"] = tooling[index].tooling_code;
    rowItem["tooling_name"] = tooling[index].tooling_name;
    rowItem["brand"] = tooling[index].brand_name;
    rowItem["amount"] = tooling[index].amount;
    rowItem["in_out"] = tooling[index].edit_type ? "IN":"OUT" ;
    rowItem["lot_no"] = tooling[index].lot_no;
    rowItem["remark"] = tooling[index].remark;
    rowItem["po_no"] = tooling[index].po_no;
    rowItem["Date"] =  moment(tooling[index].created_date).format("DD/MM/yyyy ::: HH:mm") + " น.";
    rowItem["username"] = tooling[index].username;
    Tooling_rowsData.push(rowItem);
  }

  const Toolingdata = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tooling Code",
        field: "tooling_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Tooling Name",
        field: "tooling_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Brand",
        field: "brand",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lot No",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "In/Out",
        field: "in_out",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "Po No",
        field: "po_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "Date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Tooling_rowsData,
  };
  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="snh" activemenu="history" submenu="none"/> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Warehouse History{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse management
                  </li>
                  <li className="breadcrumb-item active">ข้อมูล Express</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content-header">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                {/* <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-material-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-material"
                    role="tab"
                    aria-controls="custom-tabs-four-material"
                    aria-selected="false"
                  >
                    Material
                  </a>
                </li> */}
                {feature.map((e) => {
                  if (e.feature === "Material") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={
                              showActive === "Material"
                                ? " nav-link active"
                                : "nav-link"
                            }
                            id="custom-tabs-four-material-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-material"
                            role="tab"
                            aria-controls="custom-tabs-four-material"
                            aria-selected="false"
                          >
                            Material
                          </a>
                        </li>
                      </>
                    );
                  }
                })}

                {feature.map((e) => {
                  if (e.feature === "deflux_STP") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={
                              showActive === "deflux_STP"
                                ? " nav-link active"
                                : "nav-link"
                            }
                            id="custom-tabs-four-deflux_STP-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-deflux_STP"
                            role="tab"
                            aria-controls="custom-tabs-four-deflux_STP"
                            aria-selected="false"
                          >
                            Deflux
                          </a>
                        </li>
                      </>
                    );
                  }
                })}

                {feature.map((e) => {
                  if (e.feature === "Cutting") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={
                              showActive === "Cutting"
                                ? " nav-link active"
                                : "nav-link"
                            }
                            id="custom-tabs-four-cutting-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-cutting"
                            role="tab"
                            aria-controls="custom-tabs-four-cutting"
                            aria-selected="false"
                          >
                            Cutting
                          </a>
                        </li>
                      </>
                    );
                  }
                })}

                {feature.map((e) => {
                  if (e.feature === "WIP") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={
                              showActive === "WIP"
                                ? " nav-link active"
                                : "nav-link"
                            }
                            id="custom-tabs-four-product_wip-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-product_wip"
                            role="tab"
                            aria-controls="custom-tabs-four-product_wip"
                            aria-selected="true"
                          >
                            Product_Wip
                          </a>
                        </li>
                      </>
                    );
                  }
                })}

                {feature.map((e) => {
                  if (e.feature === "Product FG") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={
                              showActive === "Product FG"
                                ? " nav-link active"
                                : "nav-link"
                            }
                            /*          className="nav-link" */
                            id="custom-tabs-four-product_fg-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-product_fg"
                            role="tab"
                            aria-controls="custom-tabs-four-product_fg"
                            aria-selected="false"
                          >
                            Product_Fg
                          </a>
                        </li>
                      </>
                    );
                  }
                })}
                {feature.map((e) => {
                  if (e.feature === "Tooling_History") {
                    return (
                      <>
                        <li className="nav-item">
                          <a
                            className={
                              showActive === "Tooling History"
                                ? " nav-link active"
                                : "nav-link"
                            }
                            /*          className="nav-link" */
                            id="custom-tabs-four-tooling_his-tab"
                            data-toggle="pill"
                            href="#custom-tabs-four-tooling_his"
                            role="tab"
                            aria-controls="custom-tabs-four-tooling_his"
                            aria-selected="false"
                          >
                            Tooling History
                          </a>
                        </li>
                      </>
                    );
                  }
                })}
                {/* 
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-four-product_fg-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-product_fg"
                    role="tab"
                    aria-controls="custom-tabs-four-product_fg"
                    aria-selected="false"
                  >
                    Product_Fg
                  </a>
                </li> */}
              </ul>
            </div>
            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className={
                    showActive === "Cutting"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-cutting"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-cutting-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History &gt; Cutting
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_cutting.no}
                            onChange={(e) => {
                              setFiller_cutting({
                                ...filler_cutting,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_cutting.name}
                            onChange={(e) => {
                              setFiller_cutting({
                                ...filler_cutting,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Name.</label>{" "}
                        </div>
                      </div>
                      {/*<div className="col-md-2">
                <div className="form-group ">
              
                  <input
                    type="text"
                    className="form-control"
                    required="false"    
                    value={filler_cutting.create_date}
                    onChange={(e) => {
                      setFiller_cutting({
                        ...filler_cutting,
                        create_date: e.target.value,
                      });
                    }} 
                  />
                  <label htmlFor="">Date</label>{" "}
                </div>
                  </div>*/}
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetCutting();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="table-responsive"
                      /* style={{ height: "500px" }} */
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_cutting}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "Material"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-material"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-material-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History &gt; Material
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_material.no}
                            onChange={(e) => {
                              setFiller_material({
                                ...filler_material,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Material No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_material.name}
                            onChange={(e) => {
                              setFiller_material({
                                ...filler_material,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Material Name.</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>&nbsp;&nbsp; วันที่</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_material.date_start}
                            className="form-control"
                            // //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            // onChange={async (date) => SetDate_start_fg(date)}
                            onChange={async (date) => {
                              setFiller_material({
                                ...filler_material,
                                date_start: date,
                              });
                            }}
                            selectsStart
                            startDate={filler_material.date_start}
                            endDate={filler_material.date_end}
                          />
                        </div>
                      </div>
                      <div>
                        <label>ถึง</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filler_material.date_end}
                            className="form-control"
                            //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            onChange={async (date) => {
                              setFiller_material({
                                ...filler_material,
                                date_end: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_material.date_start}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_material.date_end}
                            minDate={filler_material.date_start}
                          />
                        </div>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetMaterial();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          {/* <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_material"
                            filename={
                              "HistoryMat" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="table-responsive"
                      style={{ whiteSpace: "nowrap" }}
                      /* style={{ height: "500px" }} */
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_material}
                      />
                    </div>
                    <div style={{ display: "none" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        id="table-to-xls_material"
                        sortable={false}
                        bordered
                        paging={false}
                        hover
                        fixedHeader
                        data={data_material}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "deflux_STP"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-deflux_STP"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-deflux_STP-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History &gt; Deflux
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filter_deflux.diameter_no}
                            onChange={(e) => {
                              setFilter_deflux({
                                ...filter_deflux,
                                diameter_no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Diameter No</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filter_deflux.diameter_name}
                            onChange={(e) => {
                              setFilter_deflux({
                                ...filter_deflux,
                                diameter_name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Diameter Name</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>&nbsp;&nbsp; วันที่</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filter_deflux.date_start}
                            className="form-control"
                            // //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            // onChange={async (date) => SetDate_start_fg(date)}
                            onChange={async (date) => {
                              setFilter_deflux({
                                ...filter_deflux,
                                date_start: date,
                              });
                            }}
                            selectsStart
                            startDate={filter_deflux.date_start}
                            endDate={filter_deflux.date_end}
                          />
                        </div>
                      </div>
                      <div>
                        <label>ถึง</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filter_deflux.date_end}
                            className="form-control"
                            //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            onChange={async (date) => {
                              setFilter_deflux({
                                ...filter_deflux,
                                date_end: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_material.date_start}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_material.date_end}
                            minDate={filler_material.date_start}
                          />
                        </div>
                      </div>

                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetDeflux();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          {/* <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_deflux_STP"
                            filename={
                              "HistoryDeflux" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="table-responsive"
                      style={{ whiteSpace: "nowrap" }}
                      /* style={{ height: "500px" }} */
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_deflux}
                      />
                    </div>
                    <div style={{ display: "none" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        id="table-to-xls_deflux_STP"
                        sortable={false}
                        bordered
                        paging={false}
                        hover
                        fixedHeader
                        data={data_deflux}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "Product FG"
                      ? "tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-product_fg"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-product_fg-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History &gt; Product Fg
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_product_fg.no}
                            onChange={(e) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_product_fg.name}
                            onChange={(e) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Name.</label>{" "}
                        </div>
                      </div>
                      <div>
                        <label>วันที่ </label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group">
                          <DatePicker
                            selected={filler_product_fg.date_start_fg}
                            className="form-control"
                            // //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            // onChange={async (date) => SetDate_start_fg(date)}
                            onChange={async (date) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                date_start_fg: date,
                              });
                            }}
                            selectsStart
                            startDate={filler_product_fg.date_start_fg}
                            endDate={filler_product_fg.date_end_fg}
                          />
                        </div>
                      </div>
                      <div>
                        <label>ถึง</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group">
                          <DatePicker
                            selected={filler_product_fg.date_end_fg}
                            className="form-control"
                            //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            onChange={async (date) => {
                              setFiller_product_fg({
                                ...filler_product_fg,
                                date_end_fg: date,
                              });
                            }}
                            selectsEnd
                            startDate={filler_product_fg.date_start_fg}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filler_product_fg.date_end_fg}
                            minDate={filler_product_fg.date_start_fg}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetProduct_fg();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          {/*  <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_productFG"
                            filename={
                              "HistoryFG" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="table-responsive"
                      /*  style={{ height: "500px" }} */
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_product_fg}
                      />
                    </div>
                    <div
                      className="table-responsive"
                      style={{ display: "none" }}
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        id="table-to-xls_productFG"
                        paging={false}
                        hover
                        fixedHeader
                        data={data_product_fg}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "Wip"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-product_wip"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-product_wip-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History &gt; Product Wip
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_product_wip.no}
                            onChange={(e) => {
                              setFiller_product_wip({
                                ...filler_product_wip,
                                no: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product No.</label>{" "}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            value={filler_product_wip.name}
                            onChange={(e) => {
                              setFiller_product_wip({
                                ...filler_product_wip,
                                name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Product Name.</label>{" "}
                        </div>
                      </div>

                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => {
                              GetProduct_wip();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="table-responsive"
                      /*  style={{ height: "500px" }} */
                    >
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={data_product_wip}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    showActive === "Tooling_History"
                      ? " tab-pane fade active show"
                      : "tab-pane fade"
                  }
                  id="custom-tabs-four-tooling_his"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-tooling_his-tab"
                >
                  <div className="row">
                    <div className="col-4"></div>
                  </div>

                  <h3 class="title ng-binding">
                    Warehouse History &gt; Tooling History
                  </h3>
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={filterOption.tooling_code}
                            onChange={(e) => {
                              setfilterOption({
                                ...filterOption,
                                tooling_code: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Tooling Code</label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group ">
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={filterOption.tooling_name}
                            onChange={(e) => {
                              setfilterOption({
                                ...filterOption,
                                tooling_name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">Tooling Name</label>
                        </div>
                      </div>
                      <div className="col-md-3" style={{ "z-index": "98" }}>
                        <div className="form-group ">
                          <Select
                            options={tooling_brand_option}
                            /* ref={select_brand} */
                            value={tooling_brand_option.find(
                              (item) => item.value === filterOption.brand_id
                            )}
                            onChange={(e) => {
                              //console.log(e);
                              if (e !== null) {
                                setfilterOption({
                                  ...filterOption,
                                  brand_id: e.value || "",
                                });
                              }
                            }}
                          />
                          <label htmlFor="">Brand</label>
                        </div>
                      </div>
                      <div>
                        <label>&nbsp;&nbsp; วันที่</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filterOption.date_start}
                            className="form-control"
                            // //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            // onChange={async (date) => SetDate_start_fg(date)}
                            onChange={async (date) => {
                              setfilterOption({
                                ...filterOption,
                                date_start: date,
                              });
                            }}
                            selectsStart
                            startDate={filterOption.date_start}
                            endDate={filterOption.date_end}
                          />
                        </div>
                      </div>
                      <div>
                        <label>ถึง</label>
                      </div>
                      <div
                        className="col-md-2 col-sm-2 col-lg-2"
                        style={{ "z-index": "99" }}
                      >
                        <div className="form-group ">
                          <DatePicker
                            selected={filterOption.date_end}
                            className="form-control"
                            //required="false"
                            dateFormat={"dd-MM-yyyy"}
                            //onChange={async (date) => SetDate_end_fg(date)}
                            onChange={async (date) => {
                              setfilterOption({
                                ...filterOption,
                                date_end: date,
                              });
                            }}
                            selectsEnd
                            startDate={filterOption.date_start}
                            //startDate={filler_product_fg.date_end_fg}
                            endDate={filterOption.date_end}
                            minDate={filterOption.date_start}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                             onClick={() => {
                              getLotInStock();
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="form-group ">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                             onClick={clearFilter}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-2 col-lg-2">
                        <div className="form-group ">
                          {/* <button
                            type="button"
                            className="btn btn-block btn-primary"
                            //onClick={clearFilter}
                          >
                            Export To CSV
                          </button> */}
                          <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="btn btn-block btn-primary"
                            table="table-to-xls_tooling"
                            filename={
                              "ToolingHistory" +
                              "_" +
                              moment(new Date()).format("DDMMyyyy")
                            }
                            sheet="tablexls"
                            buttonText="Export To CSV"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive" style={{ whiteSpace: "nowrap" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        sortable={false}
                        bordered
                        hover
                        fixedHeader
                        data={Toolingdata}
                      />
                    </div>
                    <div className="table-responsive" style={{ whiteSpace: "nowrap" ,display:"none" }}>
                      <MDBDataTable
                        className="table table-head-fixed"
                        striped
                        id='table-to-xls_tooling'
                        sortable={false}
                        bordered
                        paging={false}
                        hover
                        fixedHeader
                        data={Toolingdata}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modal-deflux_lot_no_detail">
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">Deflux lot detail</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table
                            style={{
                              border: "1px solid #ddd",
                              bordercollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B0C4DE",
                                }}
                              >
                                Deflux Lot
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B0C4DE",
                                }}
                              >
                                Material Lot
                              </th>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {deflux_lot_view_list.deflux_lot}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {deflux_lot_view_list.mat_lot.map((e) => {
                                  return (
                                    <>
                                      <div>{e.label}</div>
                                    </>
                                  );
                                })}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer " align="right">
                    <div className="row">
                      {/*      <div className="col-12" align="right"> */}
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/*    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="modal-mat_lot_no_detail">
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-label">Material lot detail</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table
                            style={{
                              border: "1px solid #ddd",
                              bordercollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <tr>
                              <th
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B0C4DE",
                                }}
                              >
                                Material Lot
                              </th>
                              <th
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#B0C4DE",
                                }}
                              >
                                Deflux Lot
                              </th>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {mat_lot_view_list.mat_lot}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  border: "1px solid #ddd",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {mat_lot_view_list.deflux_lot.map((e) => {
                                  return (
                                    <>
                                      <div>{e}</div>
                                    </>
                                  );
                                })}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer " align="right">
                    <div className="row">
                      {/*      <div className="col-12" align="right"> */}
                      <button
                        type="button"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      {/*    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* /.container-fluid */}
        </section>
        {/* Main content */}

        {/* /.content */}
      </div>

      {/*  <Footter/> */}
    </div>
  );
}
export default Warehouse_history;
