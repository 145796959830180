import React, { useState, useEffect } from "react";

import "../../../assets/css/Dashboard.css";

function Card_Count(params) {
  return (
    <>
      <div
        className={`${params.ClassName} cardCountComponentNormal`}
        style={{
          ...params.Style
        }}
      >
        <label
          style={{
            fontSize: "24px",
            lineHeight: "normal",
            margin: "0px",
            display: "flex",
            alignItems: "flex-end",
            height: "100%",
          }}
        >
          {params.Text1}
        </label>
        <label
          style={{
            fontSize: "30px",
            lineHeight: "normal",
            margin: "0px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.Text2}
        </label>
      </div>
    </>
  );
}
export default Card_Count;
