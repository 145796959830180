import React, { Component, useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import "../../assets/css/Supp.css";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import moment from "moment";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import LOGH from "../../component/log_detailHistory_feature";
import INF from "../../component/input_feature";
import Select from "react-select";
import { tag_point_cts } from "../../component/Report";
function WipProdInOutCTS_mgt(mode) {
  const { product_id } = useParams();

  const [pageMode, setPageMode] = useState("");

  const [lot_in_stock_report, setlot_in_stock_report] = useState([]);

  const [product, setproduct] = useState({
    product_no: "",
    product_name: "",
    product_amount: "",
  });
  const [lot_no_status, setlot_no_status] = useState({
    status: "",
    key: 1,
  });

  const [product_process, setproduct_process] = useState({
    edit_type: true,
    lot_no: "", //เลขใบสั่งผลิต
    product_order_id: "", // id ใบสั่งผลิต
    loss: "", //จำนวนสูญหาย
  });

  const [add_po_no, setadd_po_no] = useState({
    coil_no: "",
    po_no: "",
  });

  const [isGen, setisGen] = useState(true);
  const [box_no_gen, setbox_no_gen] = useState();
  const [box_no_genDefault, setbox_no_genDefault] = useState();

  const [lot_in_stock_list, setlot_in_stock_list] = useState([]);
  const [lot_history_list, setlot_history_list] = useState([]);

  const [productionOrderList, setproductionOrderList] = useState([]);
  const [box_no_list, setbox_no_list] = useState([]);
  const [test, settest] = useState({
    test: "",
  });

  const select_lot_no = useRef();

  const getProductView = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipProdInOut/getWipProdMGT?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setproduct({
          ...product,
          product_no: response.data.no,
          product_name: response.data.name,
          /*   product_amount: response.data.amount, */
        });

        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotInStock = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipProdInOut/GetLotWipProdInStock?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        console.log(response.data);

        //var sort_data_ = response.data.sort((a,b)=> moment(b.create_date).format('YYYYMMDDHHMM') - moment(a.create_date).format('YYYYMMDDHHMM'));

        setlot_in_stock_list(response.data);
        setlot_in_stock_report(response.data);
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getLotHistory = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipProdInOut/GetLotWipProdHistory?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setlot_history_list(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getProductionOrder = async () => {
    axios({
      method: "get",
      url:
        Configs.API_URL +
        "/api/wipProdInOut/getProductionOrder?product_id=" +
        product_id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.no;
          options.push(element);
        });
        setproductionOrderList(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductView();
    getLotInStock();
    getLotHistory();
    getProductionOrder();
  }, []);

  function SelectedReportTag(index) {
    var tempList = lot_in_stock_report;
    console.log(index);

    if (tempList[index].selected1) {
      tempList[index].selected1 = false;
    } else {
      tempList[index].selected1 = true;
    }
    console.log(tempList);
    setlot_in_stock_report(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  const row_data_lot_in_stock = [];
  for (let index = 0; index < lot_in_stock_list.length; index++) {
    const rowItem = {};
    rowItem["box_no"] = lot_in_stock_list[index].box_no;
    rowItem["lot_no"] = lot_in_stock_list[index].lot_no;
    /*     rowItem["amount"] = lot_in_stock_list[index].material_amount; */
    rowItem["amount"] = (
      <div style={{ textAlign: 'right' }}>
        {
          <NumberFormat
            thousandSeparator={true}
            value={lot_in_stock_list[index].amount}
            displayType="text"
          />
        }
      </div>

    );

    rowItem["date"] = moment(lot_in_stock_list[index].create_date).format(
      "DD/MM/yyyy HH:mm น."
    );
    rowItem["ref_box_no"] = lot_in_stock_list[index].ref_box_no || "";

    rowItem["track_report"] = (
      /*  <>
        <button
          type="button"
          onClick={() => {
            SelectedReportTag(product_id, lot_in_stock_list[index].tracking_no);
          }}
          class="btn btn-block btn-warning "
        >
          Print
        </button>
      </> */

      <>
        {lot_in_stock_report[index] === undefined ? (
          <></>
        ) : lot_in_stock_report[index].selected1 ? (
          <button
            type="button"
            onClick={() => {
              SelectedReportTag(index);
            }}
            class="btn btn-block btn-info "
          >
            Selected Tag
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              SelectedReportTag(index);
            }}
            class="btn btn-block btn-primary "
          >
            Select Tag
          </button>
        )}
      </>
    );

    rowItem["mgt"] = (
      <>
        {lot_in_stock_list[index].selected ? (
          <button
            type="button"
            disabled={
              lot_in_stock_list[index].material_po_no !== null &&
                lot_in_stock_list[index].material_po_no != ""
                ? false
                : true
            }
            onClick={() => {
              SelectedBoxNo(index);
            }}
            class="btn btn-block btn-info "
          >
            Selected
          </button>
        ) : (
          <button
            type="button"
            disabled={
              lot_in_stock_list[index].material_po_no !== null &&
                lot_in_stock_list[index].material_po_no != ""
                ? false
                : true
            }
            onClick={() => {
              SelectedBoxNo(index);
            }}
            class="btn btn-block btn-primary "
          >
            Select
          </button>
        )}
      </>
    );

    row_data_lot_in_stock.push(rowItem);
  }

  const table_lot_in_stock_list = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },

      {
        label: "Lot No",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Box No",
        field: "box_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "อ้างอิง Box No. เก่า",
        field: "ref_box_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "จำนวน (กก.)",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "Print ป้ายชี้บ่ง",
        field: "track_report",
        sort: "asc",
        width: 50,
      },
      {
        label: "วันที่",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: row_data_lot_in_stock,
  };

  if (product_process.edit_type === true) {
    var index = table_lot_in_stock_list.columns.findIndex((e) => {
      return e.field === "mgt";
    });
    table_lot_in_stock_list.columns.splice(index, 1);
  }

  const row_data_lot_history = [];
  for (let index = 0; index < lot_history_list.length; index++) {
    const rowItem = {};
    rowItem["box_no"] = lot_history_list[index].box_no;
    rowItem["lot_no"] = lot_history_list[index].lot_no;
    rowItem["status"] =
      lot_history_list[index].edit_type === true ? "IN" : "OUT";

    rowItem["amount"] = (
      <div style={{textAlign:'right'}}> 
      {
         <NumberFormat
        thousandSeparator={true}
        value={lot_history_list[index].amount}
        displayType="text"
      />
      }
      </div>
     
    );

    rowItem["loss"] = lot_history_list[index].loss;

    rowItem["date"] = moment(lot_history_list[index].create_date).format(
      "DD/MM/yyyy HH:mm น."
    );
    row_data_lot_history.push(rowItem);
  }
  const table_lot_history_list = {
    columns: [
      {
        label: "Lot No",
        field: "lot_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Box No",
        field: "box_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 50,
      },

      {
        label: "จำนวน (กก.)",
        field: "amount",
        sort: "asc",
        width: 50,
      },

      {
        label: "สูญหาย (กก.)",
        field: "loss",
        sort: "asc",
        width: 50,
      },
      {
        label: "date",
        field: "date",
        sort: "asc",
        width: 50,
      },
    ],
    rows: row_data_lot_history,
  };

  function addPoNoInWipProd() {
    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        let temp = {
          product_id: product_id,
          po_no: add_po_no.po_no,
          coil_no: add_po_no.coil_no,
        };
        axios({
          method: "post",
          url: Configs.API_URL + "/api/wipProdInOut/addPoNoInWipProd",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp,
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                console.log("success");
                getLotInStock();
                //clear radio------------------------------------------
              });
            }

            console.log(response.data);
          })
          .catch(function (error) {
            Swal.fire("Message", error.response.data.message, "warning");
            console.log(error);
          });
      }
    });
  }

  function saveOption() {
    var box_no = lot_in_stock_list.filter((e) => {
      return e.selected;
    });

    Swal.fire({
      title: "Do you want to Save the changes?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        var err_list = [];

        if (
          product_process.lot_no == "" &&
          product_process.edit_type === true &&
          lot_no_status.status !== true
        ) {
          err_list.push("กรุณาเลือก Lot No. ให้ถูกต้อง");
        }

        if (box_no.length == 0 && product_process.edit_type === false) {
          err_list.push("กรุณาเลือก Box No. ที่ต้องการ Out bound.");
        }
        if (box_no_list.length == 0 && product_process.edit_type === true) {
          err_list.push("กรุณาเพิ่ม Box No. ที่ต้องการ In bound.");
        }
        var check_ref_box = box_no_list.filter((e) => {
          return e.status_ref_box === false;
        });
        if (check_ref_box.length > 0 && product_process.edit_type === true) {
          err_list.push("มีบางรายการ ที่ อ้างอิง Box No ไม่ถูกต้อง");
        }
        var checkWeight = box_no_list.filter((e) => {
          return (
            e.weight_by_tag == 0 ||
            e.weight_by_tag == ""
          );
        });

        if (checkWeight.length > 0 && product_process.edit_type === true) {
          err_list.push(
            "กรุณาเพิ่ม น้ำหนักตามป้าย ของ Box No. ที่ต้องการ In bound."
          );
        }

        if (err_list.length > 0) {
          //มี err
          var err_message = "";
          for (var e = 0; e < err_list.length; e++) {
            err_message += "<br/>" + err_list[e];
          }
          Swal.fire("ระบบ", err_message, "error");
        } else {
          let temp = {
            lot_no: product_process.lot_no,
            edit_type: product_process.edit_type,
            product_id: product_id,
            box_no_list: box_no_list,
            box_no_out: box_no,
            loss: product_process.loss,
          };
          axios({
            method: "post",
            url: Configs.API_URL + "/api/wipProdInOut/saveInOut",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(function (response) {
              if (response.data === true) {
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  /*       console.log("here"); */

                  getProductView();
                  getLotInStock();
                  getLotHistory();

                  getProductionOrder();
                  setbox_no_list([]);
                  setisGen(true);
                  setproduct_process({
                    ...product_process,
                    lot_no: "",
                    amount: "",
                    loss: "",
                  });
                  product_process.lot_no = "";
                  product_process.amount = "";
                  product_process.loss = "";
                  if (product_process.edit_type === true) {
                    select_lot_no.current.select.clearValue();
                  }

                  //clear radio------------------------------------------
                });
              }
            })
            .catch(function (error) {
              Swal.fire("Message", error.response.data.message, "warning");
              console.log(error);
            });
        }
      }
    });
  }

  function SelectedBoxNo(index) {
    var tempList = lot_in_stock_list;

    if (tempList[index].selected) {
      tempList[index].selected = false;
    } else {
      tempList[index].selected = true;
    }
    setlot_in_stock_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/Warehouse_Management/wip_prod_in_out";
      }
    });
  }

  if (product_process.lot_no.length == 9 && lot_no_status.key == 1) {
    console.log("call api check lot order CTS");

    let data = {
      lot_no: product_process.lot_no,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/wipProdInOut/checkLotNoOrderCts",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(function (response) {
        setlot_no_status({ ...lot_no_status, status: response.data, key: 0 });
        lot_no_status.status = response.data;

        lot_no_status.key = 0;
        /*         console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const linkreport = async (product_id, tracking) => {
    var get_filler_materail = {
      product_id: product_id,
      tracking: tracking,
      company_key: "CTS",
      oem_id: getOem(),
      company_id: getUser().com,
      product_no: product.product_no,
      product_name: product.product_name,
    };

    /*  console.log(filler_materail); */
    axios({
      method: "post",
      url: Configs.API_URL + "/api/wipProdInOut/getWiplogProdinreport",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_filler_materail,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.length < 1) {
          Swal.fire({
            icon: "error",
            title: "ไม่มีข้อมูล",
          });
        } else {
        tag_point_cts(response.data, getUser().sub);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const genBoxNo = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/wipProdInOut/genBoxNo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setbox_no_gen(response.data);
        setbox_no_genDefault(response.data);

        var tempList = box_no_list;
        setbox_no_list([]);
        let temp = {
          box_no: response.data,
          weight_by_tag: "",
          old_box_no: "",
        };
        tempList.push(temp);
        setbox_no_list(tempList);
        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  async function addBoxNoList() {
    var tempList = box_no_list;
    console.log("isGen", isGen);
    if (isGen) {
      await genBoxNo();
      setisGen(false);
    }
    if (!isGen) {
      var gen = box_no_gen;
      if (tempList.length > 0) {
        var intGen = box_no_gen.split("X");
        gen = parseInt(intGen[1]) + 1;
        gen = "BOX" + gen;
        /*       gen = parseInt(box_no_gen) +1; */
        /*  gen = box_no_gen; */
      }

      setbox_no_gen(gen);
      let temp = {
        box_no: gen,
        weight_by_tag: "",
        old_box_no: "",
      };
      tempList.push(temp);
      setbox_no_list(tempList);
      settest({ ...test, test: "" });
      test.test = "";
    }
  }

  async function ReRunBoxNo() {
    axios({
      method: "get",
      url: Configs.API_URL + "/api/wipProdInOut/genBoxNo",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        setbox_no_gen(response.data);
        setbox_no_genDefault(response.data);

        var tempList = box_no_list;
        setbox_no_list([]);
        var count = 0;

        for (let item of tempList) {
          /*         console.log("before",item.box_no) */
          var intGen = response.data.split("X");
          item.box_no = parseInt(intGen[1]) + count;
          item.box_no = "BOX" + item.box_no;
          /*  console.log("after",item.box_no) */
          count++;
        }
        setbox_no_list(tempList);
        console.log(tempList);
        settest({ ...test, test: "" });
        test.test = "";
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function changeWeightByTag(value, index) {
    var tempList = box_no_list;
    tempList[index].weight_by_tag = value;
    setbox_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function changeOldBoxByTag(value, index) {
    var tempList = box_no_list;
    tempList[index].old_box_no = value;
    setbox_no_list(tempList);
    settest({ ...test, test: "" });
    test.test = "";
  }

  async function checkRefBox(ref_box, index) {
    let tmp_check = {
      product_id: product_id,
      product_ref_box_no: ref_box,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/wipProdInOut/checkRefBox",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp_check,
    })
      .then(function (response) {
        if (response.data) {
          var tempList = box_no_list;
          tempList[index].status_ref_box = response.data.status;
          tempList[index].ref_box_message = response.data.message;
          setbox_no_list(tempList);
          settest({ ...test, test: "" });
          test.test = "";
        }
      })
      .catch(function (error) {
        Swal.fire("Error!", error.response.data.message, "warning");
        console.log(error);
      });
  }

  function delBoxNoList(index) {
    var tempList = box_no_list;
    tempList.splice(index, 1);
    setbox_no_list(tempList);
    var count = 0;
    for (let item of box_no_list) {
      var intGen = box_no_genDefault.split("X");
      item.box_no = parseInt(intGen[1]) + count;
      item.box_no = "BOX" + item.box_no;

      count++;
    }
    if (tempList.length === 0) {
      setisGen(true);
    }
    settest({ ...test, test: "" });
    test.test = "";
  }

  async function QcTagGenerate() {
    console.log(lot_in_stock_report);
    const temdata = lot_in_stock_report.filter((e) => {
      return e.selected1 === true;
    });

    const templist = [];

    temdata.forEach((e) => {
      templist.push(e.tracking_no);
    });

    await linkreport(product_id, templist);
  }
  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  WIP Prod In/Out Management {pageMode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    WIP Prod In/Out Management
                  </li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h2>Amount in warehouse {lot_in_stock_list.length} items</h2>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Product No : {product.product_no}</h1>
                </div>
                <div className="col-6 col-md-4 col-xl-4">
                  <h1>Product Name : {product.product_name}</h1>
                </div>
              </div>

              <div className="row">
                <div className="col-0 col-md-2 col-xl-2"></div>
                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={product_process.edit_type != true ? false : true}
                    value={true}
                    onClick={(e) => {
                      setproduct_process({
                        ...product_process,
                        edit_type: e.target.value === "true" ? true : false,
                      });
                      product_process.edit_type = true;
                      setproduct_process({
                        ...product_process,
                        coil_no_target: "",
                        invoice_no_target: "",
                        coil_no: "",
                        invoice_no: "",
                        supplier_id: "",
                        amount: "",
                        weight_tag: "",
                        weight_actual: "",
                        grade: "",
                        location: "",
                        unit: "",
                      });
                      product_process.coil_no_target = "";
                      product_process.invoice_no_target = "";

                      product_process.coil_no = "";
                      product_process.invoice_no = "";
                      product_process.supplier_id = "";
                      product_process.amount = "";
                      product_process.weight_tag = "";
                      product_process.weight_actual = "";
                      product_process.grade = "";
                      product_process.location = "";
                      product_process.unit = "";
                      product_process.po_no = "";
                      product_process.amount = "";
                      /*    select_coil_no.current.select.clearValue();
                      select_lot_no.current.select.clearValue(); */
                      //select_location_model.current.select.clearValue();
                    }}
                  />

                  <label htmlFor="inbound">
                    <h4>&nbsp;&nbsp;In bound</h4>
                  </label>
                </div>

                <div className="col-6 col-md-4 col-xl-4">
                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    value={true}
                    onClick={(e) => {
                      setproduct_process({
                        ...product_process,
                        edit_type: e.target.value === "true" ? false : true,

                        loss: "",
                        lot_no: "",

                        amount: "",
                      });

                      setbox_no_list([]);
                      setisGen(true);
                    }}
                  />

                  <label htmlFor="outbound">
                    <h4>&nbsp;&nbsp;Out bound</h4>
                  </label>
                </div>
              </div>
              <div className="row">
                {" "}
                <div className="col-4"></div>
              </div>
              <LOGH
                approve_cts={
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={() => QcTagGenerate()}
                    >
                      Report Tag
                    </button>
                  </div>
                }
                history={table_lot_history_list}
                data={table_lot_in_stock_list}
              />

              {product_process.edit_type === true ? (
                <>
                  <div className="row">
                    <div
                      className="col-12 col-md-4 col-xl-4"
                      style={{ zIndex: 99 }}
                    >
                      <div className="form-group">
                        <Select
                          options={productionOrderList}
                          ref={select_lot_no}
                          onChange={(e) => {
                            if (e !== null) {
                              setproduct_process({
                                ...product_process,
                                lot_no: e.label,
                              });
                            }
                          }}
                        />
                        <label>Lot No. </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={() => {
                          addBoxNoList();
                        }}
                        class="btn btn-block btn-primary "
                      >
                        เพิ่ม Box No.{" "}
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="col-4 col-md-3 col-xl-2">
                      <button
                        type="button"
                        onClick={() => {
                          ReRunBoxNo();
                        }}
                        class="btn btn-block btn-primary "
                      >
                        Refresh Box No.{" "}
                        <i className="fa fa-history" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <br />

                  <div
                    className="table-responsive"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <table style={{ width: "100%", whiteSpace: "nowrap" }}>
                      <tr>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>

                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "100px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                        <td
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            width: "30px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </td>
                      </tr>

                      {box_no_list.map((e, index) => {
                        var color_ref = "green";
                        if (e.status_ref_box === false) {
                          color_ref = "red";
                        }
                        return (
                          <>
                            <tr>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <INF
                                  value={e.box_no}
                                  label={"Box No."}
                                  /* onChange={(e) => {
                                setmaterial_process({
                                  ...material_process,
                                  coil_no: e.target.value,
                                });
                              }} */ disabled
                                />
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <NumberFormat
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    type="text"
                                    className="form-control"
                                    allowNegative={false}
                                    value={e.weight_by_tag}
                                    onChange={(e) => {
                                      changeWeightByTag(Number(e.target.value.replace(/,/g, "")), index);
                                    }}
                                  />
                                  <label htmlFor="">น้ำหนักตามป้าย (กก.)</label>{" "}
                                </div>
                              </td>

                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "100px",
                                }}
                              >
                                <div className="form-group ">
                                  <input
                                    maxLength="11"
                                    type="text"
                                    className="form-control"
                                    value={e.old_box_no}
                                    onChange={(e) => {
                                      changeOldBoxByTag(e.target.value, index);
                                      if (
                                        e.target.value.length > 0 &&
                                        e.target.value.length < 11
                                      ) {
                                        var tempList = box_no_list;
                                        tempList[index].status_ref_box = false;
                                        tempList[index].ref_box_message =
                                          "Incorrect";
                                        setbox_no_list(tempList);
                                        settest({ ...test, test: "" });
                                        test.test = "";
                                      }
                                      if (e.target.value.length === 0) {
                                        var tempList = box_no_list;
                                        tempList[index].status_ref_box = null;
                                        tempList[index].ref_box_message = "";
                                        setbox_no_list(tempList);
                                        settest({ ...test, test: "" });
                                        test.test = "";
                                      }
                                      if (e.target.value.length === 11) {
                                        checkRefBox(e.target.value, index);
                                      }
                                    }}
                                  />
                                  <label htmlFor="">
                                    อ้างอิง Box No. เก่า{" "}
                                    {e.ref_box_message ? (
                                      <font color={color_ref}>
                                        {" >>>>> "}
                                        {e.ref_box_message}
                                      </font>
                                    ) : (
                                      ""
                                    )}
                                  </label>{" "}
                                </div>
                              </td>
                              <td
                                style={{
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  width: "30px",
                                }}
                              >
                                {" "}
                                <div className="form-group ">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      delBoxNoList(index);
                                    }}
                                    class="btn btn-block btn-danger "
                                  >
                                    X
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                  <hr />
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                      <div className="form-group">
                        <NumberFormat
                          thousandSeparator={true}
                          decimalScale={2}
                          type="text"
                          className="form-control"
                          allowNegative={false}
                          value={product_process.loss}
                          onChange={(e) => {
                            setproduct_process({
                              ...product_process,
                              loss: Number(e.target.value.replace(/,/g, "")),
                            });
                          }}
                        />
                        <label htmlFor="">จำนวนที่สูญหาย (Loss)</label>{" "}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-2">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="modal fade" id="modal-add_po">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">เพิ่ม PO</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-sm-12">
                        <INF
                          value={add_po_no.po_no}
                          label={"PO No"}
                          onChange={(e) => {
                            setadd_po_no({
                              ...add_po_no,
                              po_no: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={() => {
                    addPoNoInWipProd();
                  }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <Footter/> */}
    </div>
  );
}
export default WipProdInOutCTS_mgt;
