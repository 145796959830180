import React from 'react'
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import { getOem, getToken, getUser } from "../../Utils/Common";
import Configs from "../../config";
import axios from "axios";
import Swal from "sweetalert2";

function ConfigUnai() {

  const [getUnitData, setGetUnitData] = useState({
    sc_name : "",
  })

  const [unaiTableData, setUnaiTableData] = useState({
    data : [],
  })

  const [filter, setfilter] = useState({
    data: null,
  });

  const [filterTable, setFilterTable] = useState({
    is_check: true,
  });

  const [checkMode,setCheckMode] = useState({
    mode: "",
    cun_id : "",
    cun_wh_remark : "",
  })

  const clearFilter = async () => {
    setfilter({
      data: "",
    });
    filter.data = null;
    await getTableUnai();
    setFilterTable({
      is_check: true,
    });
    filterTable.is_check = true;
  };

  async function GoToPage() {
    console.log("GoToPage",checkMode.mode)
    if (checkMode.mode === "Add") {
      window.location.href = "/IoT_Master_Data/Config_Unai_Add";
    } else if (checkMode.mode === "Edit") {
      window.localStorage.setItem('remark', "cun_wh_remark");
      window.location.href = `/IoT_Master_Data/Config_Unai_Edit/${checkMode.cun_id}`;
    } else if (checkMode.mode === "View") {
      window.location.href = `/IoT_Master_Data/Config_Unai_View/${checkMode.cun_id}`;
    }
  }


  async function getTableUnai() {
    let temp = {
      cun_company_id: getUser().com,
      cun_oem_id: getOem(),
      filter: filter.data?.trim() || null,
    };

    // console.log(temp);
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/configUnai/getTableUnai",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data.data && response.data.data.length > 0) {
          setUnaiTableData((prevData) => ({
            ...prevData,
            data: response.data.data,
          }));
          console.log(response.data.data);
        } else {
          setUnaiTableData({
            ...unaiTableData,
            data: [],
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function getUnit() {
    
    // console.log(temp);
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + "/api/configUnai/getUnit",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        if (response.data.data && response.data.data.length > 0) {
          setGetUnitData({
            ...getUnitData,
            sc_name : response.data.data[0].sc_name,
          });
          // console.log("ออกไหม",response.data.data[0].sc_name);
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  const removeUnai = async () => {
    console.log("removeunai", checkMode);
    Swal.fire({
      title: "คุณมั่นใจที่จะลบใช่หรือไม่",
      text: "คุณต้องการลบรายการ Config Unai ใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: "ไม่",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios({
          method: "post",
          url: Configs.API_URL_AIOI_Back + "/api/configUnai/removeUnai",
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
          data:{
            cun_id : checkMode.cun_id,
          }
        })
          .then(function (response) {
            if (response.data.data === true) {
              Swal.fire({
                icon: "success",
                title: "ลบข้อมูลสำเร็จ",
                allowEnterKey: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timer: 1500,
                showConfirmButton: false,
              }).then(() => {
                setCheckMode({
                  ...checkMode,
                  cun_id: "",
                  mode: "",
                });
                checkMode.cun_id = "";
                checkMode.mode = "";
                getTableUnai();
              });
            }
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error.response.data.error.message);
            if (
              error.response.data.error.message &&
              error.response.data.error.message
            ) {
              if (
                error.response.data.error.message.includes(
                  "ข้อมูล Config Unai มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่"
                )
              ) {
                Swal.fire(
                  "Error",
                  "ข้อมูล Config Unai มีการเปลี่ยนแปลง กรุณาก่อนทำรายการใหม่",
                  "error"
                ).then(() => {
                  window.location.reload();
                });
              } else {
                Swal.fire(
                  "Error",
                  "เกิดข้อผิดพลาด: " + error.response.data,
                  "error"
                );
              }
            } else {
              Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            }
          });
      }
    });
  };

  const rowsData = [];
  for (let index = 0; index < unaiTableData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["no2"] = index + 1;
    
    rowItem["wh_name"] = (
      <div style={{ textAlign: "left" }}>
        {unaiTableData.data[index].wh_name}
      </div>
    );
    rowItem["wh_name"] = unaiTableData.data[index].wh_name; 
    
    rowItem["remark"] = unaiTableData.data[index].cun_wh_remark;

    rowItem["fu_no"] = (
      <div style={{ textAlign: "left" }}>{unaiTableData.data[index].fu_list.map((e)=>{
        return <div>{e.fu_no}</div>
      })}</div>
    );
    rowItem["fu_no2"] = unaiTableData.data[index].fu_list.map((e)=>{
      return e.fu_no
    });

    rowItem["fu_name"] = (
      <div style={{ textAlign: "left" }}>
        {unaiTableData.data[index].fu_list.map((e)=>{
        return <div>{e.fu_name}</div>
      })}
      </div>
    );
    rowItem["fu_name2"] = unaiTableData.data[index].fu_list.map((e)=>{
      return e.fu_name
    });
    rowItem["fu_pos_x"] = (
      <div style={{ textAlign: "left" }}>
        {unaiTableData.data[index].fu_list.map((e)=>{
        return <div>{e.fu_pos_x}</div>
      })}
      </div>
    );
    rowItem["fu_pos_x2"] = unaiTableData.data[index].fu_list.map((e)=>{
      return <div>{e.fu_pos_x}</div>
    });
    rowItem["fu_pos_z"] = (
      <div style={{ textAlign: "left" }}>
        {unaiTableData.data[index].fu_list.map((e)=>{
        return <div>{e.fu_pos_z}</div>
      })}
      </div>
    );
    rowItem["fu_pos_z2"] = unaiTableData.data[index].fu_list.map((e)=>{
      return <div>{e.fu_pos_z}</div>
    });
    rowItem["management"] = (
      <div
        className="row "
        style={{ flexWrap: "nowrap", margin: "0 auto", textAlign: "left" }}
      >
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            // key={importDelivery.data[index].unit_id}
            id="btn_view"
            className=" btn btn-xs "
            onClick={() => {
              setCheckMode({
                ...checkMode,
                mode: "View",
                cun_id: unaiTableData.data[index].cun_id,
              });
              checkMode.mode = "View";
              checkMode.cun_id = unaiTableData.data[index].cun_id
              GoToPage()
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3  ">
          <a
            // key={importDelivery.data[index].unit_id}
            id="btn_edit"
            className=" btn btn-xs "
            type="button"
            onClick={() => {
              setCheckMode({
                ...checkMode,
                mode: "Edit",
                cun_id: unaiTableData.data[index].cun_id,
              });
              checkMode.mode = "Edit";
              checkMode.cun_id = unaiTableData.data[index].cun_id
              GoToPage()
            }}
          >
            <i
              class="fas fa-pencil-alt"
              style={{
                fontSize: "14px",
              }}
            ></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            // key={importDelivery.data[index].unit_id}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              setCheckMode({
                ...checkMode,
                cun_id: unaiTableData.data[index].cun_id,
              });
              checkMode.cun_id = unaiTableData.data[index].cun_id
              removeUnai()
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Warehouse"}</div>,
        field: "wh_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Remark"}</div>,
        field: "remark",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Forklift No."}</div>,
        field: "fu_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Forklift Name"}</div>,
        field: "fu_name",
        // sort: "asc",
        width: 50,
      },
      // {
      //   label: <div style={{ textAlign: "left" }}>{`Forklift Position X (${getUnitData.sc_name})`}</div>,
      //   field: "fu_pos_x",
      //   // sort: "asc",
      //   width: 50,
      // },
      // {
      //   label: <div style={{ textAlign: "left" }}>{`Forklift Position Y (${getUnitData.sc_name})`}</div>,
      //   field: "fu_pos_z",
      //   // sort: "asc",
      //   width: 50,
      // },
      // 
      {
        label: <div style={{ textAlign: "left" }}>{"Management"}</div>,
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  useEffect(async () => {
    await getTableUnai();
    await getUnit();
  }, []);

  // useEffect(() => {
  //   GoToPage();
  // }, [checkMode]);

  return (
    <div className="wrapper">
      {/* <Header />
          <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Config Unai
                  {"  "}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  {/* <li className="breadcrumb-item active">Human Resource</li> */}
                  <li className="breadcrumb-item active">Config Unai</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    id="text_empid"
                    required="false"
                    value={filter.data}
                    onChange={(e) =>
                      setfilter({
                        ...filter,
                        data: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info"
                    onClick={()=>{
                      setFilterTable({
                        is_checked: false})
                        filterTable.is_check = false
                        getTableUnai()
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      setFilterTable({
                        is_check : false})
                        filterTable.is_check = false
                      clearFilter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    id="btn_add"
                    value="add"
                    className="btn btn-block btn-primary"
                    type="button"
                    class="btn btn-block btn-primary "
                    // hidden={disable}
                    onClick={() => {
                      setCheckMode({
                        ...checkMode,
                        mode: "Add",
                      });
                      checkMode.mode = "Add";
                      console.log(checkMode);
                      GoToPage();
                    }}
                  >
                    Add Config Unai
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="card">
            <div className="card-header">
              <h4>Config Unai</h4>
            </div>
            <div className="card-body">
              <div
                className="table-responsive "
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  sortable={false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  // disableRetreatAfterSorting={filterTable.is_check}
                  fixedHeader
                  data={data}
                  //disableRetreatAfterSorting={MDBT}
                />
              </div>
            </div>
            <div className="card-footer" />
          </div>
        </section>

        <div className="modal fade" id="modal-add_supplier_rerationship">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-label">
                  {" "}
                  {/* {unitAddData.mode === "add" ? "Add Unit" : "Edit Unit"} */}
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <input
                        allowNegative={false}
                        type="text"
                        // disabled={disable}
                        className="form-control"
                        thousandSeparator={false}
                        // value={unitAddData.unit_name}
                        // onChange={(e) =>
                        //   setUnitAddData({
                        //     ...unitAddData,
                        //     unit_name: e.target.value,
                        //   })
                        // }
                        required
                      />

                      <label htmlFor="">Unit Name :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-10">
                    <div className="form-group">
                      <input
                        allowNegative={false}
                        type="text"
                        className="form-control"
                        // disabled={disable}
                        // value={unitAddData.unit_remark}
                        // onChange={(e) =>
                        //   setUnitAddData({
                        //     ...unitAddData,
                        //     unit_remark: e.target.value,
                        //   })
                        // }
                        required
                      />

                      <label htmlFor="">Remark :</label>
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>

                <div className="row">
                  <div className="col-1"></div>

                  <div className="col-1"></div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  // onClick={() =>{
                  //   if(unitAddData.mode === "add"){
                  //     console.log("Add")
                  //     Add_Unit(unitAddData.unit_id);
                  //   }  else if(unitAddData.mode === "edit"){
                  //     console.log("Edit")
                  //     saveEditUnit(unitAddData.unit_id);
                      
                  //   }
                  // }}
                  data-dismiss="modal"
                  className="btn btn-primary"
                >
                  Save
                </button>

                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfigUnai