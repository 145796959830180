import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";
import axios from "axios";
import Configs from "../../../../config";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function Booking_History({ mode }) {
  const [searchInput, setSearchInput] = useState({
    booker: "",
    booking_room: "",
    booking_date: "",
    is_cancel: false,
  });

  const [employeeList, setEmployeeList] = useState([]);

  const [roomList, setRoomList] = useState([]);

  const [bookingHistoryData, setBookingHistoryData] = useState([]);

  const rowsBookingHistory = [];

  function formatBookingHistory(index, data) {
    let rowItem = {};

    rowItem["no"] = index + 1;

    if (mode == "hr") {
      rowItem["booker"] = data.booker;
    }

    rowItem["booking_room"] = data.room_name;

    rowItem["booking_date"] = data.objective2
      ? `${moment(data.booking_date).format("DD/MM/YYYY")} - ${moment(
          data.booking_end_date
        ).format("DD/MM/YYYY")}`
      : moment(data.booking_date).format("DD/MM/YYYY");

    rowItem["booking_start_time"] = moment(data.booking_start_time).format(
      "HH.mm น."
    );

    rowItem["booking_end_time"] = moment(data.booking_end_time).format(
      "HH.mm น."
    );

    // if (mode == "hr") {
    rowItem["booking_status"] = (
      <span style={{ color: data.booking_status ? "#FF0024" : "#1DC256" }}>
        {data.booking_status ? "ยกเลิก" : "สำเร็จ"}
      </span>
    );
    // }

    rowItem["booking_update_date"] = moment(data.booking_update_date).format(
      "DD-MM-YYYY / HH.mm น."
    );

    if (mode == "hr") {
      rowItem["management"] = (
        <div
          className="row"
          style={{ flexWrap: "nowrap", justifyContent: "center" }}
        >
          <div className="col-12">
            <a
              href={
                data.room_id == "00000000-0000-0000-0000-000000000000"
                  ? "/Human_Resource/booking/booking_AEV_Hall_Hr/" +
                    data.booking_id
                  : data.room_id == "00000000-0000-0000-0000-000000000001"
                  ? "/Human_Resource/booking/booking_AEV_Hotel_Meet_Hr/" +
                    data.booking_id
                  : "/Human_Resource/booking/booking_AEV_Hotel_Hr/" +
                    data.booking_id
              }
              id="btn_read"
              key={data.booking_id}
              className="btn btn-xs "
            >
              <i class="fas fa-eye"></i>
              {"   "}
            </a>
          </div>
        </div>
      );
    } else if (mode == "normal") {
      rowItem["management"] = (
        <div className="row" style={{ flexWrap: "nowrap" }}>
          <div className="col-xl-3 col-md-3 col-xs-3">
            <a
              href={
                data.room_id == "00000000-0000-0000-0000-000000000000"
                  ? "/Human_Resource/booking/booking_AEV_Hall/" +
                    data.booking_id
                  : data.room_id == "00000000-0000-0000-0000-000000000001"
                  ? "/Human_Resource/booking/booking_AEV_Hotel_Meet/" +
                    data.booking_id
                  : "/Human_Resource/booking/booking_AEV_Hotel/" +
                    data.booking_id
              }
              id="btn_read"
              key={data.booking_id}
              className="btn btn-xs"
            >
              <i class="fas fa-eye"></i>
            </a>
          </div>

          {data.booking_status == false &&
          moment(new Date()).isBefore(data.booking_start_time) ? (
            <>
              <div className="col-xl-3 col-md-3 col-xs-3 ">
                <a
                  href={
                    data.room_id == "00000000-0000-0000-0000-000000000000"
                      ? "/Human_Resource/booking/booking_AEV_Hall_Edit/" +
                        data.booking_id
                      : data.room_id == "00000000-0000-0000-0000-000000000001"
                      ? "/Human_Resource/booking/booking_AEV_Hotel_Meet_Edit/" +
                        data.booking_id
                      : "/Human_Resource/booking/booking_AEV_Hotel_Edit/" +
                        data.booking_id
                  }
                  id="btn_edit"
                  key={data.booking_id}
                  className="btn btn-xs"
                >
                  <i class="fas fa-pencil-alt"></i>
                </a>
              </div>

              <div className="col-xl-6 col-md-6 col-xs-6 ">
                <button
                  type="button"
                  id="btn_search"
                  key={data.booking_id}
                  className="btn btn-block btn-danger"
                  style={{
                    minWidth: "max-content",
                    width: "100%",
                    marginRight: "30px",
                  }}
                  onClick={() => {
                    let type = "";

                    if (
                      data.room_id == "00000000-0000-0000-0000-000000000000"
                    ) {
                      type = "hall";
                    } else if (
                      data.room_id == "00000000-0000-0000-0000-000000000001"
                    ) {
                      type = "hotel_meet";
                    } else {
                      type = "hotel";
                    }

                    AbandonBookingData(data.booking_id, type);
                  }}
                >
                  ยกเลิกการจอง
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    }

    return rowItem;
  }

  for (let index = 0; index < bookingHistoryData.length; index += 1) {
    rowsBookingHistory.push(
      formatBookingHistory(index, bookingHistoryData[index])
    );
  }

  const tableBookingHistory = {
    columns: [
      {
        label: "No",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ผู้จอง",
        field: "booker",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ห้อง",
        field: "booking_room",
        // sort: "asc",
        width: 10,
      },
      {
        label: "วันที่จอง",
        field: "booking_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "เวลาเริ่มต้น",
        field: "booking_start_time",
        // sort: "asc",
        width: 50,
      },
      {
        label: "เวลาสิ้นสุด",
        field: "booking_end_time",
        // sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "booking_status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "ประทับเวลา",
        field: "booking_update_date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsBookingHistory,
  };

  if (mode == "normal") {
    const index1 = tableBookingHistory.columns.findIndex(
      (e) => e.field === "booker"
    );
    tableBookingHistory.columns.splice(index1, 1);

    // const index2 = tableBookingHistory.columns.findIndex(
    //   (e) => e.field === "booking_status"
    // );
    // tableBookingHistory.columns.splice(index2, 1);
  }

  async function FilterEmployee() {
    let get_com_oem = {
      oem_id: getOem(),
      company_id: getUser().com,
      resign_status: true,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/hrManagement/filterEmployee",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: get_com_oem,
    })
      .then(function (response) {
        const options = [{ value: "", label: "เลือกทั้งหมด" }];

        for (const item of response.data.data) {
          let element = {};

          element["value"] = item.emp_id;
          element["label"] = `${item.emp_no || ""} ${
            item.emp_nickname ? "( " + item.emp_nickname + " )" : ""
          } `;

          options.push(element);
        }

        setEmployeeList(options);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
      });
  }

  async function GetRoomHotel() {
    const tempData = {
      selectStartTime: "",
      selectEndTime: "",
    };

    await axios({
      method: "post",
      url: Configs.API_URL_hrMagenatement + "/api/booking/getHotelRoom",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        const options = [
          { value: "", label: "เลือกทั้งหมด" },
          { value: "00000000-0000-0000-0000-000000000000", label: "TTT Hall" },
          {
            value: "00000000-0000-0000-0000-000000000001",
            label: "TTT Hotel Meeting",
          },
        ];

        for (const item of response.data.data) {
          let element = {};

          element["value"] = item.bhr_id;
          element["label"] = item.bhr_name;

          options.push(element);
        }

        setRoomList(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function FilterBookingHistory() {
    let axiosData = {};

    const tempData = JSON.parse(JSON.stringify(searchInput));

    if (mode == "normal") {
      tempData["booker"] = getUser().fup;

      axiosData = {
        method: "post",
        url:
          Configs.API_URL_hrMagenatement + "/api/booking/filterBookingHistory",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempData,
      };
    } else if (mode == "hr") {
      axiosData = {
        method: "post",
        url:
          Configs.API_URL_hrMagenatement +
          "/api/booking/filterBookingHistoryHr",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempData,
      };
    }

    await axios(axiosData)
      .then(function (response) {
        setBookingHistoryData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "warning",
          // title: "กรุณากรอกข้อมูลใหม่",
          html: error.response.data.error.message,
        });
      });
  }

  async function AbandonBookingData(bookId, type) {
    Swal.fire({
      title: "คุณมั่นใจว่าจะยกเลิกการจองใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const tempData = {
          bookingID: bookId,
          type: type,
          company_id: getUser().com,
          oem_id: getOem(),
          user_id: getUser().fup,
        };

        await axios({
          method: "post",
          url:
            Configs.API_URL_hrMagenatement + "/api/booking/abandonBookingData",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempData,
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              title: "ยกเลิกสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              icon: "warning",
              // title: "กรุณากรอกข้อมูลใหม่",
              html: error.response.data.error.message,
            });
          });
      }
    });
  }

  async function clearFilter() {
    if (mode == "hr") {
      setSearchInput({
        ...searchInput,
        booker: "",
        booking_room: "",
        booking_date: "",
        is_cancel: false,
      });
      searchInput.booker = "";
      searchInput.booking_room = "";
      searchInput.booking_date = "";
      searchInput.is_cancel = false;
    } else if (mode == "normal") {
      setSearchInput({
        ...searchInput,
        booking_room: "",
        booking_date: "",
        is_cancel: false,
      });
      searchInput.booking_room = "";
      searchInput.booking_date = "";
      searchInput.is_cancel = false;
    }

    await FilterBookingHistory();
  }

  useEffect(() => {
    GetRoomHotel();
    FilterEmployee();
    FilterBookingHistory();
  }, []);

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  ประวัติการจอง {mode == "hr" ? "HR" : ""}
                  {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">Booking</li>
                  <li className="breadcrumb-item active">
                    Booking History {mode == "hr" ? "HR" : ""}
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              {mode == "hr" ? (
                <div className="col-12 col-md-4 col-xl-2">
                  <div className="form-group" style={{ zIndex: "99" }}>
                    <Select
                      options={employeeList}
                      filterOption={(option, searchText) => {
                        if (
                          option.data.label
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                          // || option.data.value
                          //   .toLowerCase()
                          //   .includes(searchText.toLowerCase())
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      value={employeeList.filter((e) => {
                        return e.value === searchInput.booker;
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          setSearchInput({
                            ...searchInput,
                            booker: e.value,
                          });
                        }
                      }}
                    />
                    <label htmlFor="">ผู้จอง</label>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group" style={{ zIndex: "98" }}>
                  <Select
                    options={roomList}
                    filterOption={(option, searchText) => {
                      if (
                        option.data.label
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                        // || option.data.value
                        //   .toLowerCase()
                        //   .includes(searchText.toLowerCase())
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    value={roomList.filter((e) => {
                      return e.value === searchInput.booking_room;
                    })}
                    onChange={(e) => {
                      if (e !== null) {
                        setSearchInput({
                          ...searchInput,
                          booking_room: e.value,
                        });
                      }
                    }}
                  />
                  <label htmlFor="">ห้อง</label>
                </div>
              </div>

              <div
                className="col-12 col-md-4 col-xl-2"
                style={{ "z-index": "97" }}
              >
                <div className="form-group input-group">
                  <DatePicker
                    id="inputDate"
                    className="form-control"
                    wrapperClassName="form-control"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    selected={searchInput.booking_date}
                    onChange={(date) => {
                      setSearchInput({
                        ...searchInput,
                        booking_date: date,
                      });
                    }}
                  />
                  <label>วันที่จอง</label>
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-primary"
                    onClick={FilterBookingHistory}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
              </div>

              {/* {mode == "hr" ? ( */}
              <div
                className="col-12 col-md-auto"
                style={{ marginLeft: "15px", alignSelf: "center" }}
              >
                <div className="form-check" style={{ height: "38px" }}>
                  <input
                    id="show_cancel"
                    className="form-check-input"
                    type="checkbox"
                    checked={searchInput.is_cancel}
                    onChange={async (event) => {
                      setSearchInput({
                        ...searchInput,
                        is_cancel: event.currentTarget.checked,
                      });
                      searchInput.is_cancel = event.currentTarget.checked;

                      FilterBookingHistory();
                    }}
                  />
                  <label className="form-check-label" htmlFor="chk_remember1">
                    แสดงยกเลิกการจอง
                  </label>
                </div>
              </div>
              {/* ) : (
                <></>
              )} */}
              <div hidden={mode != "hr"} className="col-6 col-md-3 col-xl-2">
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="btn btn-primary btn-block"
                  table="table-to-xls"
                  filename={"ข้อมูลการจองห้อง" + moment().format("DD-MM-YYYY")}
                  sheet="tablexlsx"
                  buttonText={"Export XLS"}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header"></div>

            <div className="card-body">
              <div className="row mb-2">
                <div
                  className="table-responsive "
                  style={{ whiteSpace: "nowrap" }}
                >
                  <MDBDataTable
                    sortable={false}
                    className="table table-head-fixed text-center"
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={tableBookingHistory}
                    disableRetreatAfterSorting={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <>
          <table id="table-to-xls" style={{ display: "none" }}>
            <tr>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                No
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                ผู้จอง
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                ห้อง
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                วันที่จองเริ่มต้น
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                วันที่จองสิ้นสุด
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                เวลาเริ่มต้น
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                เวลาสิ้นสุด
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                สถานะ
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                ประทับเวลา (วันที่)
              </th>
              <th style={{ border: "1px solid #000", textAlign: "center" }}>
                ประทับเวลา (เวลา)
              </th>
            </tr>
            {bookingHistoryData.map((_item, _index) => {
              return (
                <tr>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {_index + 1}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {_item.booker || ""}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {_item.room_name || ""}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {!_item.booking_date
                      ? ""
                      : moment(_item.booking_date).format("DD/MM/YYYY")}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {!_item.booking_end_date
                      ? moment(_item.booking_date).format("DD/MM/YYYY")
                      : moment(_item.booking_end_date).format("DD/MM/YYYY")}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {moment(_item.booking_start_time).format("HH.mm น.")}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {moment(_item.booking_end_time).format("HH.mm น.")}
                  </td>
                  <td
                    style={{
                      border: `1px solid #000`,
                      textAlign: "center",
                      color: `${_item.booking_status ? "#FF0024" : "#1DC256"}`,
                    }}
                  >
                    {_item.booking_status ? "ยกเลิก" : "สำเร็จ"}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {String.fromCharCode(8203) +
                      moment(_item.booking_update_date).format("DD-MM-YYYY")}
                  </td>
                  <td style={{ border: "1px solid #000", textAlign: "center" }}>
                    {moment(_item.booking_update_date).format("HH.mm น.")}
                  </td>
                </tr>
              );
            })}
          </table>
        </>
      </div>
    </div>
  );
}

export default Booking_History;
