import React, { Component, useEffect, useRef, useState } from "react";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import DatePicker from "react-datepicker";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import Configs from "../../config";
import NumberFormat from "react-number-format";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import 'react-datepicker/dist/react-datepicker.css';

function Tooling_po_receive() {

    const [MDBT, setMDBT] = useState(true);
    const closeModal = useRef();
    const closeClickModal = () => {
        closeModal.current.click();
    }
    const [toolingPOFilter, setToolingPOFilter] = useState({
        t_po_no: '',
        t_supplier_id: '',
        t_recieve_status: '',
        t_start_date: '',
        t_end_date: ''
    });
    const [test, setTest] = useState({
        test: ''
    });
    const [getpo_no, setPO_no] = useState("");
    const [supplier_option, setsupplier_option] = useState([]);
    const [tooling_po_receive, setTooling_po_receive] = useState([]);
    const [receiveStatus_option, setReceiveStatus_option] = useState([]);

    var config_sub = {
        method: "get",
        url: Configs.API_URL + "/tooling/getToolingSupplier?id=" + getUser().com,
        headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
        },
    };

    var config_receive = {
        method: "get",
        url: Configs.API_URL + "/tooling/getToolingReceiveStatus",
        headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
        },
    };

    useEffect(async () => {
        axios(config_sub)
            .then(function (response) {

                const options = [{ value: "", label: "Select All" }];
                response.data.forEach((el) => {
                    var element = {};
                    element["value"] = el.id;
                    element["label"] = el.name;
                    options.push(element);
                });
                setsupplier_option(options);
            })
            .catch(function (error) {
                console.log(error);
            });

        axios(config_receive)
            .then(function (response) {
                setMDBT(true);
                const options = [{ value: "", label: "Select All" }];
                response.data.forEach((el) => {
                    var element = {};
                    element["value"] = el.id;
                    element["label"] = el.name;
                    options.push(element);
                });
                setReceiveStatus_option(options);
            })
            .catch(function (error) {
                console.log(error);
            });
        FilterToolingPO();
    }, []);

    async function SetDate_start(date) {
        setToolingPOFilter({ ...toolingPOFilter, t_start_date: date });
    }
    async function SetDate_end(date) {
        setToolingPOFilter({ ...toolingPOFilter, t_end_date: date });
    }

    function setToolingModal(e) {
        setMDBT(true);
        const data = {
            toolingSupplier_id: e.tooling_supplier_id,
            tooling_poId: e.po_id,
        }
        axios({
            method: "post",
            url:
                Configs.API_URL +
                "/tooling/getToolingBySupplierId",
            headers: {
                Authorization: getToken(),
                "Content-Type": "application/json",
                "X-TTT": Configs.API_TTT,
            },
            data: data,
        })
            .then(function (response) {
                console.log('response.data', response.data);
                setToolingReceiveModal(response.data.tooling_receive);
                // toolingReceiveModal[index].value_add_amount = amount - receive;
                for (let item of response.data.tooling_receive) {                    
                    // console.log('parseInt(item.amount) - parseInt(item.receive_amount)', parseInt(item.amount || 0) - parseInt(item.receive_amount || 0));
                    item.value_add_amount = parseInt(item.amount || 0) - parseInt(item.receive_amount || 0);
                }
                setPO_no(response.data.po_no);
                setTest({ ...test, test: '' });
                /* let arrList = [];
                arrList = tooling_po.filter((po) => po.id !== e.id);
                setTooling_po(arrList);
                setTest({ ...test, test: '' }); */
                // window.location.href = "/Human_Resource/HRD/External_dev_train";

            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
    }

    function numberWithCommas(x) {
        return (
            <NumberFormat
                thousandSeparator={true}
                value={x || 0}
                displayType="text"
            />
        );
        /*  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); */
    }

    const Clearfilter = async () => {
        setToolingPOFilter({
            t_po_no: '',
            t_supplier_id: '',
            t_recieve_status: '',
            t_start_date: '',
            t_end_date: ''
        });
        toolingPOFilter.t_po_no = '';
        toolingPOFilter.t_supplier_id = '';
        toolingPOFilter.t_recieve_status = '';
        toolingPOFilter.t_start_date = '';
        toolingPOFilter.t_end_date = '';
        setTest({ ...test, test: '' });
        test.test = '';
        FilterToolingPO();
    }

    const FilterToolingPO = async () => {
        let start = null;//new Date(Date.UTC(new Date().getFullYear(), 0, 1));
        let end = null;//new Date(Date.UTC(new Date().getFullYear(), 11, 31));
        if (toolingPOFilter.t_start_date !== '' && toolingPOFilter.t_end_date !== '') {
            start = new Date(moment(toolingPOFilter.t_start_date).add(1, 'days')); //.add(1, 'days')
            start.setUTCHours(0, 0, 0, 0);
            end = new Date(moment(toolingPOFilter.t_end_date).add(1, 'days'));
            end.setUTCHours(23, 59, 59, 999);
        } else if (toolingPOFilter.t_start_date !== '') {
            start = new Date(moment(toolingPOFilter.t_start_date).add(1, 'days')); //.add(1, 'days')
            start.setUTCHours(0, 0, 0, 0);
            end = new Date(Date.UTC(new Date().getFullYear(), 11, 31));
            end.setUTCHours(23, 59, 59, 999);
        } else if (toolingPOFilter.t_end_date !== '') {
            end = new Date(moment(toolingPOFilter.t_end_date).add(1, 'days'));
            end.setUTCHours(23, 59, 59, 999);
            start = new Date(Date.UTC(new Date().getFullYear(), 0, 1));
            start.setUTCHours(0, 0, 0, 0);
        }
        let pushData = {
            user_id: getUser().fup,
            company_id: getUser().com,
            oem_id: getOem(),
            t_po_no: toolingPOFilter.t_po_no.trim() || null,
            t_supplier: toolingPOFilter.t_supplier_id || null,
            t_recieve_status: toolingPOFilter.t_recieve_status || null,
            t_start_date: toolingPOFilter.t_start_date || null,// start,
            t_end_date: toolingPOFilter.t_end_date || null, // end,
        };

        axios({
            method: "post",
            url: Configs.API_URL + "/tooling/getToolingPO",
            headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: pushData,
        })
            .then(async function (response) {
                console.log('response', response);
                const arrToolingPO = [];
                const arrSupplierTooling = [];
                for (let item of response.data) {
                    arrToolingPO.push(item.toolingPO);
                    arrSupplierTooling.push(item.supplier);
                }
                console.log('arrToolingPO', arrToolingPO);
                console.log('arrSupplierTooling', arrSupplierTooling);

                setTooling_po_receive(arrToolingPO);
                setMDBT(true);
            })
            .catch(function (error) {
                console.log(error);
                Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
            });
        // });
    };

    const addAmountOfToolingApi = async () => {
        for (let item of toolingReceiveModal) {
            let value_add_amount = (typeof item.value_add_amount !== "string" ? item.value_add_amount : parseInt(item.value_add_amount?.replace(/,/g,"") || 0));
            let receive_amount = (typeof item.receive_amount !== "string" ? item.receive_amount : parseInt(item.receive_amount?.replace(/,/g,"") || 0));
            let amount = (typeof item.amount !== "string" ? item.amount : parseInt(item.amount?.replace(/,/g,"") || 0));
            if ((value_add_amount + receive_amount) > amount) {
                Swal.fire("เกิดข้อผิดพลาด", "ช่องจำนวนที่รับ เกิน Amount กรุณากรอกข้อมูลใหม่", "error");
                return;
            }
        }

        Swal.fire({
            title: "คุณยืนยันที่จะเพิ่มจำนวนสินค้า ใช่หรือไม่",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: `ใช่`,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `ไม่`,
        })
            .then((res) => {
                if (res.isConfirmed) {
                    const temp = {
                        toolingReceive: toolingReceiveModal,
                        fup: getUser().fup,
                        oem_id: getOem(),
                        company_id: getUser().com,
                    }
                    axios({
                        method: "post",
                        url: Configs.API_URL + "/tooling/addAmountOfToolingApi",
                        headers: {
                            Authorization: getToken(),
                            "X-TTT": Configs.API_TTT,
                            "Content-Type": "application/json",
                        },
                        data: temp,
                    })
                        .then(async function (response) {
                            console.log('response', response);
                            if (response.data) {
                                Swal.fire({
                                    icon: "success",
                                    title: "Save",
                                    showConfirmButton: false,
                                    timer: 1500,
                                }).then((result) => {
                                    // console.log(response.data);
                                    closeClickModal();
                                    FilterToolingPO();
                                    setTest({ ...test, test: '' });
                                    // window.location.href = "/Warehouse_Management/Factory_Master_Data/Tooling";
                                });
                            }
                            // setTooling_po_receive(response.data);

                        })
                        .catch(function (error) {
                            console.log(error);
                            Swal.fire({
                                icon: "warning",
                                title: "เกิดข้อผิดพลาด",
                                text: '',
                                html: error.response.data.message
                            });
                            FilterToolingPO();
                        });
                }
            })
    }

    function editAmount_tooling(e) {
        //console.log(e.target.value);
        var tempData = toolingReceiveModal;
        // toolingInquiry = [];
        setToolingReceiveModal([]);
        tempData[e.target.name].value_add_amount = e.target.value;
        /* if (tempData[e.target.name].value_add_amount > toolingReceiveModal[e.target.name].receive_amount) {
            tempData[e.target.name].value_add_amount = toolingReceiveModal[e.target.name].receive_amount;

        } */
        // tempData[e.target.name].status_edit = 'update';
        // tempData[e.target.name].total = e.target.value * tempData[e.target.name].nms;
        // materail_data = tempData;
        setToolingReceiveModal(tempData);
        setTest({ ...test, test: "" });
        test.test = "";
    }

    const Custom_Input_date = ({ value, onClick }) => (
        <div className="input-group">
            <input
                type="text"
                className="form-control float-left"
                onClick={onClick}
                value={value}
            />{" "}
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="far fa-calendar-alt" />
                </span>
            </div>
        </div>
    );
    const [toolingReceiveModal, setToolingReceiveModal] = useState([]);
    const rowsDataTooling = [];
    for (let index = 0; index < toolingReceiveModal.length; index += 1) {
        const rowItem = {};
        const receive = parseInt(toolingReceiveModal[index].receive_amount);
        const amount = parseInt(toolingReceiveModal[index].amount);
        // toolingReceiveModal[index].value_add_amount = amount - receive;
        // rowItem["no"] = (<div style={{ textAlign: 'center' }}>{index + 1}</div>);

        rowItem["order"] = toolingReceiveModal[index].tooling_name;

        rowItem["amount_want"] = (
            <div style={{ textAlign: 'center' }} >
                {numberWithCommas(toolingReceiveModal[index].amount || 0)}
            </div>
        );

        rowItem["amount_received"] = (
            <>
                <div style={{ textAlign: 'center' }} >
                    {numberWithCommas(toolingReceiveModal[index].receive_amount)} /
                    {numberWithCommas(toolingReceiveModal[index].amount || 0)}
                </div>
            </>
        );

        rowItem["amount_receive"] = (
            <>
                {receive == amount ?
                    "" :
                    <NumberFormat
                        type="text"
                        className="form-control"
                        required
                        allowNegative={false}
                        decimalScale={0}
                        name={index}
                        thousandSeparator={true}
                        max={toolingReceiveModal[index].receive_amount}
                        value={toolingReceiveModal[index].value_add_amount || ''}
                        onChange={editAmount_tooling.bind(this)}
                    />
                }
            </>
        );

        rowsDataTooling.push(rowItem);
    }

    const dataTooling = {
        columns: [
            /* {
                label: "No.",
                field: "no",
                // sort: "asc",
                width: 50,
            }, */
            {
                label: "รายการ",
                field: "order",
                // sort: "asc",
                width: 50,
            },
            {
                label: "จำนวนที่ต้องการ",
                field: "amount_want",
                // sort: "asc",
                width: 50,
            },
            {
                label: "จำนวนที่เคยรับ",
                field: "amount_received",
                // sort: "asc",
                width: 50,
            },
            {
                label: "จำนวนที่รับ",
                field: "amount_receive",
                // sort: "asc",
                width: 50,
            },
        ],

        rows: rowsDataTooling,
    };

    const rowsData = [];
    for (let index = 0; index < tooling_po_receive.length; index += 1) {
        const rowItem = {};

        rowItem["no"] = (<div style={{ textAlign: 'center' }}>{index + 1}</div>);

        rowItem["tr_po_no"] = tooling_po_receive[index].po_no;

        rowItem["tr_supplier_name"] = supplier_option.map((item) => { if (item.value === tooling_po_receive[index].tooling_supplier_id) { return item.label } });

        rowItem["tr_receive_status"] = (
            <>
                {/* tooling_po_receive[index].is_receive === true  */}
                {tooling_po_receive[index].receive_status === '0e6bbbfb-12fd-4136-83ad-4131d2a5096d' ?
                    <div style={{ border: '1px solid #00CD00', borderRadius: '5px', color: '#00CD00', padding: '4px 0', textAlign: 'center', fontWeight: '800' }}>
                        {receiveStatus_option.map((item) => { if (item.value === tooling_po_receive[index].receive_status) { return item.label } })}
                    </div>
                    :
                    <div style={{ border: '1px solid #1E90FF', borderRadius: '5px', color: '#1E90FF', padding: '4px 0', textAlign: 'center', fontWeight: '800' }}>
                        {receiveStatus_option.map((item) => { if (item.value === tooling_po_receive[index].receive_status) { return item.label } })}
                    </div>
                }
            </>
        );

        rowItem["tr_receive_date"] = tooling_po_receive[index].receive_status === 'fc3bd39f-841a-4de6-ba7c-37582e299a26' ? "" : moment(tooling_po_receive[index].receive_date).format('DD/MM/YYYY');

        rowItem["tr_btn_receive"] = (
            <>
                {tooling_po_receive[index].receive_status !== 'f838dc87-5636-4e1c-acd2-01a69f76544d' ?
                    <div className="row">
                        <div className="col-12">
                            <button
                                type="button"
                                id={tooling_po_receive[index]}
                                /* disabled={disable}*/
                                data-toggle="modal"
                                data-target="#modal-receive-status"
                                onClick={setToolingModal.bind(
                                    this,
                                    tooling_po_receive[index]
                                )}
                                class={"btn btn-block btn-primary "}
                            >
                                รับสินค้า
                            </button>
                        </div>
                    </div>
                    :
                    ""}
            </>
        );

        rowsData.push(rowItem);
    }

    const data = {
        columns: [
            {
                label: "No.",
                field: "no",
                // sort: "asc",
                width: 50,
            },
            {
                label: "PO No.",
                field: "tr_po_no",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Supplier Name",
                field: "tr_supplier_name",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Receive Status",
                field: "tr_receive_status",
                // sort: "asc",
                width: 50,
            },
            {
                label: "Receive Date",
                field: "tr_receive_date",
                // sort: "asc",
                width: 50,
            },
            {
                label: "รับสินค้า",
                field: "tr_btn_receive",
                // sort: "asc",
                width: 50,
            },
        ],

        rows: rowsData,
    };

    return (
        <div className="wrapper">
            {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>
                                    Tooling Receive{"  "}
                                    <a target="_blank" href={getAcademy()}>
                                        <i class="fa fa-info-circle"></i>
                                    </a>
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Warehouse Management
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Tooling PO Receive
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row" style={{ alignItems: 'center' }}>
                            <div className="col-12 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <input
                                        type="text"
                                        className="form-control"
                                        required="false"
                                        value={toolingPOFilter.t_po_no}
                                        onChange={(e) => {
                                            setToolingPOFilter({
                                                ...toolingPOFilter,
                                                t_po_no: e.target.value,
                                            });
                                        }}
                                    />
                                    <label htmlFor="">PO No.</label>{" "}
                                </div>
                            </div>
                            <div
                                className="col-6 col-md-3 col-xl-2"
                                style={{ zIndex: "989" }}
                            >
                                <div className="form-group">
                                    <Select
                                        options={supplier_option}
                                        // ref={select_supplier}
                                        value={supplier_option.find(
                                            (item) => item.value === toolingPOFilter.t_supplier_id
                                        )}
                                        onChange={(e) => {
                                            //console.log(e);
                                            if (e !== null) {
                                                setToolingPOFilter({
                                                    ...toolingPOFilter,
                                                    t_supplier_id: e.value || "",
                                                });
                                            }
                                        }}
                                    />
                                    <label htmlFor="">Supplier</label>
                                </div>
                            </div>
                            <div
                                className="col-6 col-md-3 col-xl-2"
                                style={{ zIndex: "989" }}
                            >
                                <div className="form-group">
                                    <Select
                                        options={receiveStatus_option}
                                        // ref={select_supplier}
                                        value={receiveStatus_option.find(
                                            (item) => item.value === toolingPOFilter.t_recieve_status
                                        )}
                                        onChange={(e) => {
                                            //console.log(e);
                                            if (e !== null) {
                                                setToolingPOFilter({
                                                    ...toolingPOFilter,
                                                    t_recieve_status: e.value || "",
                                                });
                                            }
                                        }}
                                    />
                                    <label htmlFor="">Receive State</label>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-12 col-md-4 col-xl-3"
                                style={{ "z-index": "987", display: 'flex', flexDirection: 'row', columnGap: '5px', flexDirection: 'row', alignItems: 'center' }}
                            >
                                <label style={{ whiteSpace: 'nowrap', fontSize: 'x-large' }} htmlFor="">Start Date</label>
                                <div className="form-group ">
                                    <DatePicker
                                        selected={toolingPOFilter.t_start_date}
                                        dateFormat={"dd-MM-yyyy"}
                                        popperPlacement="bottom"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"] // don't allow it to flip to be above
                                            },
                                            preventOverflow: {
                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                            },
                                            hide: {
                                                enabled: false // turn off since needs preventOverflow to be enabled
                                            }
                                        }}
                                        onChange={async (date) => {
                                            setToolingPOFilter({
                                                ...toolingPOFilter,
                                                t_start_date: date,
                                            })
                                        }}
                                        // selectsStart
                                        startDate={toolingPOFilter.t_start_date}
                                        maxDate={toolingPOFilter.t_end_date}
                                        customInput={<Custom_Input_date />}
                                    />
                                </div>
                            </div>

                            <div
                                className="col-12 col-md-4 col-xl-3"
                                style={{ "z-index": "99", display: 'flex', flexDirection: 'row', columnGap: '5px', flexDirection: 'row', alignItems: 'center' }}
                            >
                                <label style={{ whiteSpace: 'nowrap', fontSize: 'x-large' }} htmlFor="">Date End</label>
                                <div className="form-group ">
                                    <DatePicker
                                        selected={toolingPOFilter.t_end_date}
                                        dateFormat={"dd-MM-yyyy"}
                                        popperPlacement="bottom"
                                        popperModifiers={{
                                            flip: {
                                                behavior: ["bottom"] // don't allow it to flip to be above
                                            },
                                            preventOverflow: {
                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                            },
                                            hide: {
                                                enabled: false // turn off since needs preventOverflow to be enabled
                                            }
                                        }}
                                        onChange={async (date) => {
                                            setToolingPOFilter({
                                                ...toolingPOFilter,
                                                t_end_date: date,
                                            })
                                        }}
                                        // selectsEnd
                                        startDate={toolingPOFilter.t_start_date}

                                        minDate={toolingPOFilter.t_start_date}
                                        customInput={<Custom_Input_date />}
                                    ></DatePicker>

                                </div>
                            </div>

                            {/* <div className="col-4 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                       onClick={() => QcTagGenerate()} 
                    >
                       Report Tag
                    </button>
                  </div>
                </div> */}
                        </div>
                        <div className='row' >
                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info  "
                                        onClick={() => {
                                            FilterToolingPO();
                                        }}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 col-xl-2">
                                <div className="form-group ">
                                    <button
                                        type="button"
                                        className="btn btn-block btn-info"
                                        onClick={() => Clearfilter()}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Tooling PO Receive</h3>
                            <div className="card-tools"></div>
                        </div>

                        <div
                            className="card-body table-responsive "
                            style={{ whiteSpace: "nowrap" }}
                        >
                            <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                striped
                                bordered
                                hover
                                fixedHeader
                                disableRetreatAfterSorting={MDBT}
                                data={data}
                            />
                        </div>
                        {/* /.card-body */}
                        <div className="card-footer"></div>
                        {/* /.card-footer*/}
                    </div>
                    <div className="modal fade" id="modal-receive-status">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-label">Tooling Order</h4>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        ref={closeModal}
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row mb-2">
                                            {/* {disable ? "" : */}
                                            <div className="col-6 col-md-4 col-xl-2">
                                                <div className="form-group ">
                                                    <button
                                                        type="button"
                                                        id="btn_search"
                                                        className="btn btn-block btn-success"
                                                        // disabled="true"
                                                        onClick={() => {
                                                            addAmountOfToolingApi();
                                                            //  window.location.href = "/Warehouse_Management/Tooling_Inquiry/add";
                                                        }}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                            {/* } */}
                                            {/* <div className="col-6 col-md-4 col-xl-2">
                                                <div className="form-group ">
                                                    <button
                                                        type="button"
                                                        // disabled="true"
                                                        id="btn_search"
                                                        className="btn btn-block btn-danger"
                                                        onClick={() => {
                                                            // backPeviousPage();
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-4 col-xl-3">
                                            <div className="form-group ">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="text_empid"
                                                    disabled={true}
                                                    required="false"
                                                    value={getpo_no}
                                                /* onChange={(e) => {
                                                    setInputToolingInquiry({
                                                        ...inputToolingInquiry,
                                                        tooling_no: e.target.value,
                                                    });
                                                }} */
                                                />
                                                <label htmlFor="">PO No.</label>{" "}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-12">


                                            <div className="table-responsive" style={{ whiteSpace: 'nowrap' }} /*  style={{ height: "600px" }} */ >
                                                <MDBDataTable
                                                    sortable={false}
                                                    className="table table-head-fixed"
                                                    striped
                                                    bordered
                                                    hover
                                                    fixedHeader
                                                    disableRetreatAfterSorting={MDBT}
                                                    data={dataTooling}
                                                />

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer justify-content-end ">
                                    <button
                                        // hidden="true"
                                        hidden="true"
                                        type="button"
                                        className="btn btn-default"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        style={{ width: '5em' }}
                                        className="btn btn-default"
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button>


                                </div>
                            </div>

                        </div>

                    </div>

                    {/* /.card */}
                </section>
                {/* /.content */}
            </div>
            {/*   <Footter/> */}
        </div>
    )
}

export default Tooling_po_receive