import React, { Component, useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from  'sort-json-array';
import Sidebar from "../../component/Sidebar";
import axios from "axios";
import QRCode from 'qrcode.react';
import Configs from "../../config";
import { getOem, getToken } from "../../Utils/Common";
import { userdefault_img,logoTTT } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
function WIPInOut(params) {
  const [product_data, setProduct_data] = useState([]);
  const [model, setModel] = useState([]);
  const [oem_id, setOem_id] = useState(getOem());
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id:getOem()
  });

  useEffect(() => {
    const script = document.createElement("script");

    script.src = `js/Product`;
    document.body.appendChild(script);
  }, []);

  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const Getamount = async () => {
    if (
      amount.amount !== null &&
      amount.amount !== undefined &&
      amount.amount !== ""
    ) {
      if (amount.edit_type !== null) {
        const temp_amount = {
          id: amount.id,
          edit_type: amount.edit_type,
          amount: Number(amount.amount.replace(/,/g, "")),
        };
        //console.log(temp_amount);
        axios({
          method: "post",
          url: Configs.API_URL + "/product/amountWIPInOut",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_amount,
        })
          .then(async (response) => {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 300,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
               /*  window.location.href = "/Warehouse_Management/WIP_IN_OUT"; */
               setmessage({
                num: "",
                name: "",
              });
              message.num="";
              message.name="";
              setamount({
                id: "",
                edit_type: null,
                amount: "",
              });
              amount.id = "";
              amount.edit_type = null;
              amount.amount = "";
              window.location.href =
              "/Warehouse_Management/WIP_IN_OUT";


              }).then(()=>{
                Gettable();
              })
            });
            /* console.log(response.data); */
          })
          .catch(async (error) => {

            Swal.fire("Over Stock!", "Check your amount", "warning")
        .then((resault)=> {
          window.location.href =
                "/Warehouse_Management/WIP_IN_OUT";
        });
            console.log(error);
          });
      } else {
        Swal.fire("check in bound , out bound", "bound can't null", "warning");
      }
    } else {
      Swal.fire("check amount", "amount can't null", "warning");
    }
  };

  useEffect(() => {
    Gettable();
    Getmodel();
  }, []);

  const Getmodel = async () => {
    axios(config_model)
      .then(async (response) => {
        // console.log(response.data);
        setModel(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product,
    })
      .then(async (response) => {
       let sorted = response.data.sort((a, b) => b.wip_amount-  a.wip_amount);
        await setProduct_data(sorted);
          /* console.log(test); */
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function form(no, name, id) {
    setmessage({ ...message, name: name, no: no });
    setamount({ ...amount, id: id });
  }

  const [amount, setamount] = useState({
    id: "",
    edit_type: null,
    amount: "",
  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });
  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product/add";
  }
  function handleChange(value) {
    Gettable();
    /* console.log(filler_product); */
  }

  const clearfillter = async () => {
    await setFiller_product({ product_model_id: "", name: "", no: "" });
    filler_product.product_model_id = "";
    filler_product.name = "";
    filler_product.no = "";
    Gettable();
  };
  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Raw_Materail/add";
  }

  // /////////////////////////////////////////
  const rowsData = [];
  for (var index = 0; index < product_data.length; index++) {
    const rowItem = {};

    for (let indexin = 0; indexin < model.length; indexin++) {
      if (
        model[indexin].id == product_data[index].product_model_id &&
        product_data[index].product_model_id !== undefined
      ) {
        rowItem["product_model_id"] = "" + model[indexin].name;
      }
    }

    if (product_data[index].wip_amount !== null) {
      rowItem["amount"] = (
        <NumberFormat
          value={product_data[index].wip_amount}
          displayType={"text"}
          thousandSeparator={true}
        />
      );
    } else {
      rowItem["amount"] = 0;
    }

    {
      /* <a className=" btn btn-xs " onClick={form.bind(this, product_data[index].no, product_data[index].name,product_data[index].id)}> */
    }
    rowItem["num"] = index + 1;
    rowItem["no"] = product_data[index].no;
    rowItem["name"] = product_data[index].name;
    rowItem["qty_unit"] = product_data[index].qty_unit;
    // rowItem["amount"] = product_data[index].wip_amount;
    // rowItem["weight"] = product_data[index].weight;
    rowItem["min_prod"] = <NumberFormat value={product_data[index].min_product ===null?0:product_data[index].min_product } displayType={'text'} thousandSeparator={true} />
    
    rowItem["image"] = /* product_data[index].image_path */(
      <img
        alt="..."
        className="img-fluid rounded "
        src={product_data[index].imaget_path !== null ? Configs.API_URL_IMG + product_data[index].imaget_path :userdefault_img.imgs}
        style={{ width: "50px", height: "50px" }}
      />
    );
    rowItem["qr"] = (
      <div style={{textAlign:"center"}}>
      <QRCode
      value={product_data[index].no}
      size={50}
      fgColor={"#000000"}
      bgColor={"#ffffff"}
      level={"M"}
      renderAs={"svg"}
      includeMargin={false}
      imageSettings={
           {
              src: logoTTT.imgs,
              height: 15,
              width: 15,
              x: null,
              y: null,
              excavate: true,
            }
      }
    /></div>
    );
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className=" btn btn-xs "
            data-toggle="modal"
            data-target="#modal-xl"
            onClick={form.bind(
              this,
              product_data[index].no,
              product_data[index].name,
              product_data[index].id
            )}
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  // console.log(rowsData);
  //////////////////////////

  const data = {
    columns: [
      {
        label: "No_",
        field: "num",
        sort: "asc",
        width: 50,
      },{
        label: "Part_No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 50,
      },
      {
       label: "QR",
       field: "qr",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "Part_Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part_Model",
        field: "product_model_id",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qty_Unit",
        field: "qty_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min_Product",
        field: "min_prod",
        sort: "asc",
        width: 50,
      },
      {
        label: "WIP_Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      ,
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }
  function showmessage() {
      Swal.fire({
        icon: "info",
        title: "Waiting for connect service API QR",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
  
    }
  return (
    <div className="wrapper">
    <Header />
    <Sidebar menu="warehouse" activemenu="wipinout" submenu="none" />
      <div className="content-wrapper">
       
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>WIP IN/OUT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">WIP IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.no}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Part No.</label>{" "}
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.name}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Part Name</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                  <label htmlFor="">Category/Model</label>
                </div>
                {/*   <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      Gettable();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearfillter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

              {/*
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary" onClick={showmessage}>
                    QR Code
                  </button>
                </div>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WIP IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
       <div className="modal fade" id="modal-xl">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Edit WIP Amount</h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-4">
                  <h3>Product No : {message.no}</h3>
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={amount.edit_type !=true ? false:true}
                    value={true}
                    onChange={(e) => {
                      setamount({
                        ...amount,
                        edit_type: e.target.value === "false" ? false : true,
                      });
                    }}
                  />

                  <label htmlFor="inbound">
                    <h4>  In bound</h4>
                  </label>
                </div>

                <div className="col-4">
                  <h3>Product Name : {message.name}</h3>

                  <div className="row"></div>

                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    checked={amount.edit_type !=false ? false:true}
                    value={false}
                    onChange={(e) => {
                      setamount({
                        ...amount,
                        edit_type: e.target.value === "false" ? false : true,
                      });
                    }}
                  />
                  <label htmlFor="outbound">
                    <h4>  Out bound</h4>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                  <div className="form-group ">
                    <NumberFormat
                    format="################"
                      thousandSeparator={true}
                      type="text"
                      className="form-control"
                      value={amount.amount}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          amount: e.target.value,
                        });
                      }}
                      required
                    />
                    <label htmlFor="">Amount In</label>{" "}
                  </div>
                </div>

                <div className="col-4"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={Getamount}
                data-dismiss="modal"
                aria-label="Close"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
     
                      <Footter/>
                      </div>
  );
}
export default WIPInOut;
