import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  LineElement,
  TimeScale,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { CrosshairPlugin } from "chartjs-plugin-crosshair";
import { Line } from "react-chartjs-2";
// import '../component/Chart.css';

const Chart = ({ type, displayFormat, date, data, unit }) => {
  const CustomCrosshairPlugin = function (plugin) {
    const originalAfterDraw = plugin.afterDraw;
    plugin.afterDraw = function (chart, easing) {
      if (chart && chart.crosshair) {
        originalAfterDraw.call(this, chart, easing);
      }
    };
    return plugin;
  };

  ChartJS.register(
    CustomCrosshairPlugin(CrosshairPlugin),
    LineElement,
    TimeScale,
    LinearScale,
    PointElement,
    Tooltip
  );

  const [startXAxis, setStartXAxis] = useState(null);
  const [endXAxis, setEndXAxis] = useState(null);
  const [timeUnit, setTimeUnit] = useState(null);

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  useEffect(() => {
    setChartData({
      datasets: data,
    });
  }, [data]);

  useEffect(() => {
    let start = new Date(date);
    let end = new Date(date);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    if (displayFormat === "day") {
      end.setDate(end.getDate());

      setTimeUnit("Minute");
    } else if (displayFormat === "month") {
      start.setDate(1);
      end.setMonth(end.getMonth() + 1, 1);
      end.setMilliseconds(end.getMilliseconds() - 1);

      setTimeUnit("Days");
    } else if (displayFormat === "year") {
      start.setMonth(0, 1);
      end.setFullYear(end.getFullYear(), 11, 31);
      end.setHours(23, 59, 59, 999);

      setTimeUnit("Month");
    }

    setStartXAxis(start.valueOf());
    setEndXAxis(end.valueOf());
  }, [displayFormat, date]);

  // * Settings *
  const scales = {
    x: {
      min: startXAxis,
      max: endXAxis,
      position: "bottom",
      type: "time",
      title: {
        display: true,
        text: `Time (${timeUnit})`,
      },
      time: {
        displayFormats: {
          month: "MMM",
          hour: "HH:mm",
          minute: "HH:mm",
          second: "HH:mm:ss",
        },
      },
      ticks: {
        maxTicksLimit: 12,
        autoSkip: true,
        autoSkipPadding: 5,
        maxRotation: 0,
      },
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        display: true,
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 12,
        beginAtZero: true,
        callback: function (value) {
          var ranges = [
            { divider: 1e6, suffix: "M" },
            { divider: 1e3, suffix: "k" },
          ];
          function formatNumber(n) {
            for (let i = 0; i < ranges.length; i++) {
              if (n >= ranges[i].divider) {
                return (
                  (n / ranges[i].divider).toFixed(1).toString() +
                  ranges[i].suffix
                );
              }
            }
            return n.toFixed(1);
          }
          return formatNumber(value);
        },
      },
      grid: {
        display: true,
        color: "#F6F6F6",
      },
    },
  };

  // * Configurations *
  const config = {
    type: type,
    mainTainAspectRatio: false,
    responsive: true,
    tension: 0.4,
    pointRadius: 1,
    pointBorderColor: "white",
    pointBorderWidth: 0,

    pointHoverRadius: 6,
    pointHoverBorderColor: "white",
    pointHoverBorderWidth: 3.5,

    scales: scales,
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,

    plugins: {
      legend: {
        display: false,
      },
      crosshair: {
        line: {
          color: "#D1D4DC", // crosshair line color
          width: 0.6, // crosshair line width
        },
        sync: {
          enabled: false, // enable trace line syncing with other charts
          group: 1, // chart group
          suppressTooltips: false, // suppress tooltips when showing a synced tracer
        },
        zoom: {
          enabled: false,
        },
        snap: {
          enabled: false,
        },
      },
      tooltip: {
        callbacks: {
          label: (item) => `${item.dataset.label} : ${item.parsed.y} ${unit}`,
        },
      },
    },
  };

  // * Return *
  return (
    <div className="chart-container" style={{ maxHeight: "350px" }}>
      <Line data={chartData} options={config} style={{ maxHeight: "350px" }} />
    </div>
  );
};

export default Chart;
