import React, { Component, useEffect, useState, useRef } from "react";
//import { MDBDataTable } from "mdbreact";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import Swal from "sweetalert2";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import { useParams } from "react-router";
import Sidebar from "../../component/Sidebar";
import {
  getOem,
  getToken,
  getUser,
  getFeature,
  getAcademy,
} from "../../Utils/Common";
import Configs from "../../config";
import FormData from "form-data";
import axios from "axios";
import NumberFormat from "react-number-format";
import { userdefault_img } from "../../routes/imgRoute/imgUrl";
import Resizer from "react-image-file-resizer";
import moment, { min } from "moment";
import product_bomlist from "../../assets/file/bomlist_Template.xlsx";
import product_bomlist_subassy from "../../assets/file/bomlist_sub_assy_Template.xlsx";
import Select from "react-select";
import TableLEK from "../../component/tableLEKTTT/table";

function Product_add(mode) {
  const [countPageChoup, setCountPageChoup] = useState(1);
  const [countDataChoup, setCountDataChoup] = useState(0);
  const [entriesChoup, setEntriesChoup] = useState(10);
  const [searchChoup, setSearchChoup] = useState(0);

  const [countPagecustomer, setCountPagecustomer] = useState(1);
  const [countDatacustomer, setCountDatacustomer] = useState(0);
  const [entriescustomer, setEntriescustomer] = useState(10);
  const [searchcustomer, setSearchcustomer] = useState(0);

  const [countPagemat, setCountPagemat] = useState(1);
  const [countDatamat, setCountDatamat] = useState(0);
  const [entriesmat, setEntriesmat] = useState(10);
  const [searchmat, setSearchmat] = useState(0);

  const [product, setproduct] = useState({
    no: "",
    name: "",
    product_model_id: "",
    qty_unit: "",
    min_product: "",
    min_lot: "",
    oem_id: getOem(),
    image: "",
    description: "",
    sell_price: "",
    cost: "",
    circle_time: "",
    sku_user: "",
    product_route_id: "",
    product_size_id: "",
    product_color_id: "",
    image_name: "",
    image_path: "",
    sku_system: "",
    material_id: "",

    size: "",
    unit: "",
    thickness: "",
    weight: "",
    cross_section_area: "",
    is_assy: "",
    is_virtual: false,

    stp_type: false,
    stp_width: "",
    stp_height: "",
    blade_head: "",

    surface: "",
    nw_number: "",
    packing_standart: "",

    pu_id: "",
    unit_id: "",
    unit_select: null,
    unit_width: "",
    unit_depth: "",
    unit_height: ""
  });

  const [unitConvertToList, setUnitConvertToList] = useState({
    data: []
  });

  const [unitDropdown, setUnitDropdown] = useState({
    data: []
  });
  const [isUseTabUnitConvert, setIsUseTabUnitConvert] = useState({
    data: false
  });
  const [feature, setfeature] = useState([]);
  const [type_select, settype_select] = useState({
    status: "",
  });
  const [facchoup_data, setfacchoup_data] = useState([]);
  const [facchoup_select, setfacchoup_select] = useState([]);
  const [matDefine_unit, setmatDefine_unit] = useState({
    mode: "",
    unit: "",
    index: "",
  });
  const [processById, setprocessById] = useState([]);
  const [color, setcolor] = useState([]);
  const [size, setsize] = useState([]);
  const [route, setroute] = useState([]);
  const [model, setmodel] = useState([]);
  const { id, target, assy } = useParams();
  //console.log(id, target, assy);
  const [page_mode, setPage_mode] = useState([]);
  const [materail, setmaterail] = useState([]);
  const [materailLazeload, setmaterailLazeload] = useState([]);
  const [Mat_supplier, setMat_Supplier] = useState([]);
  const [Mat_type, setMat_Type] = useState([]);
  const [Mat_size, setMat_Size] = useState([]);
  const [Mat_spect, setMat_Spect] = useState([]);
  //const [Mat_preview, setMat_preview] = useState([]);
  const [lines, setLines] = useState([]);
  const [process, setProcess] = useState([]);
  const [section, setSection] = useState([]);
  const [bomlist_excel, setbomlist_excel] = useState([]);
  const [excel, setexcel] = useState([]);
  const [cycle_time, setcycle_time] = useState([]);
  const [machine, setmachine] = useState([]);
  //const [machine_option, setmachine_option] = useState([]);
  const [machine_select, setmachine_select] = useState({
    id: "",
    cycle_time: "",
    remark: "",
    mode: "",
  });
  const [machine_select_data, setMachine_select_data] = useState([]);

  //const [machine_select_mode, setmachine_select_mode] = useState(false);

  const [process_select_data, setprocess_select_data] = useState([]);
  const [process_cycle_option, setprocess_cycle_option] = useState([]);

  const [process_cycle, setprocess_cycle] = useState([]);
  const [process_cycle_select, setprocess_cycle_select] = useState({
    id: "",
    process: "",
    cycle_time: "",
    mode: "",
    index: "",
  });
  const [filter_process, setFilter_process] = useState({
    oem_id: getOem(),
    section_id: "",
    section_name: "",
    process_name: "",
  });
  const [Mat_fillter, setMat_fillter] = useState({
    no: "",
    name: "",
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    material_supplier_id: "",
    oem_id: getOem(),
    with_oem: false,
    company_id: getUser().com,
  });
  
  //const [re_material, setre_material] = useState([]);
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [companyGroup, setCompanyGroup] = useState([]);
  const [materail_select_temp, setmaterail_select_temp] = useState([]);
  const [orgname, setname] = useState(
    []
    /*   {
    nw_number:"",
    gw_number:"",

  } */
  );
  const [product_select_temp, setproduct_select_temp] = useState({
    id: "",
    no: "",
    name: "",
    amount: "",
  });
  const [filter_product, setfilter_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
  });
  const [product_all, setproduct_all] = useState([]);
  const [customer_product_data_temp, setcustomer_product_data_temp] = useState({
    id: "",
    customer_item: "",
    customer_item_name: "",
    index: "",
    plan_item: product.no,
    remark: "",
    last_update: "",
    is_use: true,
  });
  const [customer_product_data, setcustomer_product_data] = useState([]);
  const [customer_product_mode, setcustomer_product_mode] = useState({
    mode: "",
  });
  const [customer_select, setCustomer_select] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [supplier_option, setSupplier_option] = useState([]);
  const [supplier_select_data, setSupplier_select_data] = useState([]);
  const [supplier_select, setsupplier_select] = useState({
    id: "",
    supplier: "",
    buy_price: "",
    minimum_order: "",
    lead_time: "",
    remark: "",
    mode: "",
    index: "",
  });
  const [supplier_active, setsupplier_active] = useState({
    id: "",
    supplier: "",
    buy_price: "",
    minimum_order: "",
    lead_time: "",
    remark: "",
    sort: "",
  });
  const [product_chack, setproduct_chack] = useState({
    checkthanasub: false,
  });
  var config_getOem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  const GetPrduct_all = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_product,
    })
      .then(async (response) => {
        await setproduct_all(response.data);
        if (mode.mode === "add") {
          GetFactoryChoup(1, 10, 'search', [])
          GetCustomer(1, 10, 'search', [])
          const temp_pro_assy = [];
          for (let i = 0; i < response.data.length; i++) {
            const element = {};
            element["id"] = response.data[i].id;
            element["no"] = response.data[i].no;
            element["name"] = response.data[i].name;
            if (product_select_temp.length > 0) {
              for (let t = 0; t < product_select_temp.length; t++) {
                if (
                  response.data[i].id === product_select_temp[t].product_sub
                ) {
                  element["id_pp"] = "";
                  element["isSelect"] = true;
                  element["amount"] = product_select_temp[t].amount;
                }
              }
            }
            temp_pro_assy.push(element);
          }

          setproduct_all(temp_pro_assy);
        } else {
          setproduct_all([]);
          axios(configProd_view)
            .then(function (response2) {
              console.log(response2.data);
              setfacchoup_select(response2.data.plating_product)
              setCustomer_select(response2.data.prod_customer);
              GetFactoryChoup(1, 10, 'search', response2.data.plating_product)
              GetCustomer(1, 10, 'search', response2.data.prod_customer)
              const temp_pro_assy = [];
              for (let i = 0; i < response.data.length; i++) {
                const element = {};
                element["id"] = response.data[i].id;
                element["no"] = response.data[i].no;
                element["name"] = response.data[i].name;
                if (product_select_temp.length > 0) {
                  for (let t = 0; t < product_select_temp.length; t++) {
                    if (
                      response.data[i].id === product_select_temp[t].product_sub
                    ) {
                      element["id_pp"] = "";
                      element["isSelect"] = true;
                      element["amount"] = product_select_temp[t].amount;
                    }
                  }
                } else {
                  for (
                    let k = 0;
                    k < response2.data.multiple_product_subAssy.length;
                    k++
                  ) {
                    if (
                      response.data[i].id ===
                      response2.data.multiple_product_subAssy[k].product_sub
                    ) {
                      element["id_pp"] =
                        response2.data.multiple_product_subAssy[k].id;
                      element["isSelect"] = true;
                      element["amount"] =
                        response2.data.multiple_product_subAssy[k].amount;
                    }
                  }
                }
                temp_pro_assy.push(element);
              }
              setproduct_all(temp_pro_assy);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
  var config_sub = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSupplier?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var config_type = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialType?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + getOem(),
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var config_color = {
    method: "get",
    url: Configs.API_URL + "/product/getProductColor?id=" + getOem(),
    headers: {
      "X-TTT": Configs.API_TTT,
      Authorization: getToken(),
      "Content-Type": "application/json",
    },
  };
  var config_route = {
    method: "get",
    url: Configs.API_URL + "/product/getProductRoute?id=" + getOem(),
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var config_size = {
    method: "get",
    url: Configs.API_URL + "/product/getProductSize?id=" + getOem(),
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var configMat_spec = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSpec?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    configProd_view,
  };
  var configMat_size = {
    method: "get",
    url: Configs.API_URL + "/material/getMaterialSize?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  var configProd_view = {
    method: "get",
    url: Configs.API_URL + "/product/findById?id=" + id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  function GetSection(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/productionLine/getSection?id=" + getOem(),
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setSection(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function GetLines(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/productionLine/getLines",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setLines(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function GetProcess(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/productionLine/getProcess",
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setProcess(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function GetCycleTime(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/productionLine/getCycleTime?id=" + id,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setcycle_time(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const GetMat_size = async () => {
    axios(configMat_size)
      .then(function (response) {
        setMat_Size(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetMat_spec = async () => {
    axios(configMat_spec)
      .then(function (response) {
        setMat_Spect(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  function GetProcessById(params) {
    axios({
      method: "get",
      url: Configs.API_URL + "/productionLine/getProcessByID?id=" + id,
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setprocessById(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const GetMat_data = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/material/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: Mat_fillter,
    })
      .then(async (response) => {
        if (mode.mode === "add") {
          const temp_mat = [];
          for (let i = 0; i < response.data.length; i++) {
            const element = {};
            element["id"] = response.data[i].id;
            element["no"] = response.data[i].no;
            element["name"] = response.data[i].name;
            element["material_type_id"] = response.data[i].material_type_id;
            element["material_spec_id"] = response.data[i].material_spec_id;
            element["material_size_id"] = response.data[i].material_size_id;
            element["buy_price"] = response.data[i].buy_price;
            element["material_unit"] = response.data[i].material_unit;
            element["material_supplier_id"] =
              response.data[i].material_supplier_id;
            element["description"] = response.data[i].description;
            element["remark"] = response.data[i].remark;
            if (materail_select_temp.length > 0) {
              for (var t = 0; t < materail_select_temp.length; t++) {
                if (
                  response.data[i].id === materail_select_temp[t].material_id
                ) {
                  element["id_mp"] = "";
                  element["isSelect"] = true;
                  element["wt_mtr"] = materail_select_temp[t].wt_mtr;
                  element["c_leng"] = materail_select_temp[t].c_leng;
                  element["cos_pc"] = materail_select_temp[t].cos_pc;
                  element["remark"] = materail_select_temp[t].remark || null;
                  element["amount_txt"] = materail_select_temp[t].amount_txt;
                  /*    element["nw_number"] = materail_select_temp[t].nw_number; */
                  element["gw_number"] = materail_select_temp[t].gw_number;
                  element["d_number"] = materail_select_temp[t].d_number;
                  element["t_number"] = materail_select_temp[t].t_number;
                  element["w_number"] = materail_select_temp[t].w_number;
                  element["l_number"] = materail_select_temp[t].l_number;
                  /*  element["country_number"] = materail_select_temp[t].country_number; */
                }
              }
            }
            temp_mat.push(element);
          }
          var temp = {
            id: "",
            name: "",
            material_type_id: "",
            material_spec_id: "",
            material_size_id: "",
          };
          setmaterail(temp);
          setmaterail(temp_mat);
        } else {
          axios(configProd_view)
            .then(function (response2) {
              setmaterail([]);
              const temp_mat = [];
              for (let i = 0; i < response.data.length; i++) {
                const element = {};
                element["id"] = response.data[i].id;
                element["no"] = response.data[i].no;
                element["name"] = response.data[i].name;
                element["material_type_id"] = response.data[i].material_type_id;
                element["material_spec_id"] = response.data[i].material_spec_id;
                element["material_size_id"] = response.data[i].material_size_id;
                element["buy_price"] = response.data[i].buy_price;
                element["material_unit"] = response.data[i].material_unit;
                element["material_supplier_id"] =
                  response.data[i].material_supplier_id;
                element["description"] = response.data[i].description;
                element["remark"] = response.data[i].remark;
                if (materail_select_temp.length > 0) {
                  for (var t = 0; t < materail_select_temp.length; t++) {
                    if (
                      response.data[i].id ===
                      materail_select_temp[t].material_id
                    ) {
                      element["id_mp"] = "";
                      element["isSelect"] = true;
                      element["wt_mtr"] = materail_select_temp[t].wt_mtr;
                      element["c_leng"] = materail_select_temp[t].c_leng;
                      element["cos_pc"] = materail_select_temp[t].cos_pc;
                      element["amount_txt"] =
                        materail_select_temp[t].amount_txt;
                      element["unit_txt"] = materail_select_temp[t].unit_txt;
                      /*  element["nw_number"] = materail_select_temp[t].nw_number; */
                      element["gw_number"] = materail_select_temp[t].gw_number;
                      element["d_number"] = materail_select_temp[t].d_number;
                      element["t_number"] = materail_select_temp[t].t_number;
                      element["w_number"] = materail_select_temp[t].w_number;
                      element["l_number"] = materail_select_temp[t].l_number;
                      /*     element["country_number"] = materail_select_temp[t].country_number; */
                    }
                  }
                } else {
                  for (let k = 0; k < response2.data.multiple_mat.length; k++) {
                    if (
                      response.data[i].id ===
                      response2.data.multiple_mat[k].material_id
                    ) {
                      element["id_mp"] = response2.data.multiple_mat[k].id;
                      element["isSelect"] = true;
                      element["wt_mtr"] = response2.data.multiple_mat[k].wt_mtr;
                      element["c_leng"] = response2.data.multiple_mat[k].c_leng;
                      element["cos_pc"] = response2.data.multiple_mat[k].cos_pc;
                      element["amount_txt"] =
                        response2.data.multiple_mat[k].amount_txt;
                      element["unit_txt"] =
                        response2.data.multiple_mat[k].unit_txt;
                      /* element["nw_number"] = response2.data.multiple_mat[k].nw_number; */
                      element["gw_number"] =
                        response2.data.multiple_mat[k].gw_number;
                      element["d_number"] =
                        response2.data.multiple_mat[k].d_number;
                      element["t_number"] =
                        response2.data.multiple_mat[k].t_number;
                      element["w_number"] =
                        response2.data.multiple_mat[k].w_number;
                      element["l_number"] =
                        response2.data.multiple_mat[k].l_number;
                      /* element["country_number"] = response2.data.multiple_mat[k].country_number; */
                    }
                  }
                }
                temp_mat.push(element);
              }
              var re_mat_temp = temp_mat.filter((e) => {
                return e.isSelect === true;
              });
              var temp_restore = {
                materail: re_mat_temp,
                materail_select: temp_mat,
              };
              //setre_material(temp_restore);
              setmaterail(temp_mat);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetMat_dataLazeload = async (page, size, search) => {

    if (search) {
      setSearchmat(1);
    } else {
      setSearchmat(0);
    }

    let temp = {

      oem_id: getOem(),
      company_id: getUser().com,
      no: Mat_fillter.no,
      name: Mat_fillter.name,
      material_type_id: Mat_fillter.material_type_id,
      material_spec_id: Mat_fillter.material_spec_id,
      material_size_id: Mat_fillter.material_size_id,
      material_supplier_id: Mat_fillter.material_supplier_id,

      with_oem: Mat_fillter.with_oem,
      page: page || 1,
      size: size || 10
    }

    await axios({
      method: "post",
      url: Configs.API_URL + "/material/filter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        console.log('response Mate',response.data);
        console.log('materail_select_temp',materail_select_temp);
        if (mode.mode === "add") {
          const temp_mat = [];
          for (let i = 0; i < response.data.data.length; i++) {
            const element = {};
            element["id"] = response.data.data[i].id;
            element["no"] = response.data.data[i].no;
            element["name"] = response.data.data[i].name;
            element["material_type_id"] = response.data.data[i].material_type_id;
            element["material_spec_id"] = response.data.data[i].material_spec_id;
            element["material_size_id"] = response.data.data[i].material_size_id;
            element["buy_price"] = response.data.data[i].buy_price;
            element["material_unit"] = response.data.data[i].material_unit;
            element["material_supplier_id"] =
              response.data.data[i].material_supplier_id;
            element["description"] = response.data.data[i].description;
            element["remark"] = response.data.data[i].remark;
            if (materail_select_temp.length > 0) {
              for (var t = 0; t < materail_select_temp.length; t++) {
                if (response.data.data[i].id === materail_select_temp[t].material_id) {
                  console.log('เท่ากัน',response.data.data[i].id,' :: ',materail_select_temp[t].material_id);
                 /*  element["id_mp"] = "";
                  element["isSelect"] = true;
                  element["wt_mtr"] = materail_select_temp[t].wt_mtr || null;
                  element["c_leng"] = materail_select_temp[t].c_leng || null;
                  element["cos_pc"] = materail_select_temp[t].cos_pc || null;
                  element["remark"] = materail_select_temp[t].remark || null;
                  element["amount_txt"] = materail_select_temp[t].amount_txt || null;
                  element["gw_number"] = materail_select_temp[t].gw_number || null;
                  element["d_number"] = materail_select_temp[t].d_number || null;
                  element["t_number"] = materail_select_temp[t].t_number || null;
                  element["w_number"] = materail_select_temp[t].w_number || null;
                  element["l_number"] = materail_select_temp[t].l_number || null; */
                }
              }
            }
            temp_mat.push(element);
          }
        /*   var temp = {
            id: "",
            name: "",
            material_type_id: "",
            material_spec_id: "",
            material_size_id: "",
          }; */
          /* setmaterail(temp); */
         /*  console.log('ตัวที่เลือก',temp_mat); */
          setmaterailLazeload(temp_mat);
          settest({ ...test, test: "" });
          test.test = "";
        } else {
          axios(configProd_view)
            .then(function (response2) {
              setmaterailLazeload([]);
              const temp_mat = [];
              for (let i = 0; i < response.data.data.length; i++) {
                const element = {};
                element["id"] = response.data.data[i].id;
                element["no"] = response.data.data[i].no;
                element["name"] = response.data.data[i].name;
                element["material_type_id"] = response.data.data[i].material_type_id;
                element["material_spec_id"] = response.data.data[i].material_spec_id;
                element["material_size_id"] = response.data.data[i].material_size_id;
                element["buy_price"] = response.data.data[i].buy_price;
                element["material_unit"] = response.data.data[i].material_unit;
                element["material_supplier_id"] =
                  response.data.data[i].material_supplier_id;
                element["description"] = response.data.data[i].description;
                element["remark"] = response.data.data[i].remark;
                if (materail_select_temp.length > 0) {
                  for (var t = 0; t < materail_select_temp.length; t++) {
                    if (
                      response.data.data[i].id === materail_select_temp[t].material_id
                    ) {
                      element["id_mp"] = "";
                      element["isSelect"] = true;
                      element["wt_mtr"] = materail_select_temp[t].wt_mtr;
                      element["c_leng"] = materail_select_temp[t].c_leng;
                      element["cos_pc"] = materail_select_temp[t].cos_pc;
                      element["amount_txt"] =
                        materail_select_temp[t].amount_txt;
                      element["unit_txt"] = materail_select_temp[t].unit_txt;
                      element["gw_number"] = materail_select_temp[t].gw_number;
                      element["d_number"] = materail_select_temp[t].d_number;
                      element["t_number"] = materail_select_temp[t].t_number;
                      element["w_number"] = materail_select_temp[t].w_number;
                      element["l_number"] = materail_select_temp[t].l_number;
                    }
                  }
                } else {
                  for (let k = 0; k < response2.data.multiple_mat.length; k++) {
                    if (
                      response.data[i].id ===
                      response2.data.multiple_mat[k].material_id
                    ) {
                      element["id_mp"] = response2.data.multiple_mat[k].id;
                      element["isSelect"] = true;
                      element["wt_mtr"] = response2.data.multiple_mat[k].wt_mtr;
                      element["c_leng"] = response2.data.multiple_mat[k].c_leng;
                      element["cos_pc"] = response2.data.multiple_mat[k].cos_pc;
                      element["amount_txt"] =
                        response2.data.multiple_mat[k].amount_txt;
                      element["unit_txt"] =
                        response2.data.multiple_mat[k].unit_txt;
                      element["gw_number"] =
                        response2.data.multiple_mat[k].gw_number;
                      element["d_number"] =
                        response2.data.multiple_mat[k].d_number;
                      element["t_number"] =
                        response2.data.multiple_mat[k].t_number;
                      element["w_number"] =
                        response2.data.multiple_mat[k].w_number;
                      element["l_number"] =
                        response2.data.multiple_mat[k].l_number;
                    }
                  }
                }
                temp_mat.push(element);
              }
          /*     var re_mat_temp = temp_mat.filter((e) => {
                return e.isSelect === true;
              }); */
             /*  console.log('ตัวที่เลือก',materail_select_temp); */
              setmaterailLazeload(temp_mat);
              settest({ ...test, test: "" });
              test.test = "";
            })
            .catch(function (error) {
              console.log(error);
            });
        }


        setCountPagemat(response.data.countPage);
        setCountDatamat(response.data.count)
        setEntriesmat(size);

        settest({ ...test, test: '' })
        test.test = '';


      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetMat_type = async () => {
    await axios(config_type)
      .then(function (response) {
        setMat_Type(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetMat_sub = async () => {
    await axios(config_sub)
      .then(function (response) {
        setMat_Supplier(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetSize = async () => {
    await axios(config_size)
      .then(function (response) {
        setsize(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetRoute = async () => {
    await axios(config_route)
      .then(function (response) {
        setroute(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getcolor = async () => {
    await axios(config_color)
      .then(function (response) {
        setcolor(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getmodel = async () => {
    await axios(config_model)
      .then(function (response) {
        setmodel(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetMachine = async () => {
    let temp = {
      oem_id: getOem(),
      id: "",
      machine_name: "",
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/machine/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setmachine([]);
        for (let item of response.data) {
          var check = machine_select_data.filter((e) => {
            return e.machine_id === item.id;
          });
          if (check.length > 0) {
            item.isPush = true;
          } else {
            item.isPush = false;
          }
        }
        setmachine(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetFactoryChoup = async (page, size, search, select_fac) => {

    //console.log(filler_project);
    if (search) {
      setSearchChoup(1);
    } else {
      setSearchChoup(0);
    }

    let temp = {
      fac_choup_no: '',
      fac_choup_name: '',
      oem_id: getOem(),
      company_id: getUser().com,
      page: page || 1,
      size: size || 10,
      all_search:''
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/api/factory_choup/filterFactoryChoup_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        //console.log(response.data);
        /*     var sort = response.data.sort((a,b)=> a.code.localeCompare(b.code)); */

        setCountPageChoup(response.data.countPage);
        setCountDataChoup(response.data.count)
        setEntriesChoup(size);

        const facAll = [];
      /*   console.log('Zrot', response.data.data); */
        for (let item of response.data.data) {
          var check = [];
          if (select_fac && select_fac.length > 0) {
            check = select_fac.filter((e) => {
              return e.fac_id === item.id;
            });
          } else {
          /*   console.log('checkcheck', check); */
            check = facchoup_select.filter((e) => {
              return e.fac_id === item.id;
            });
          }

       /*    console.log('checkcheck', check); */
          if (check.length > 0) {
            item.isPush = true;
          } else {
            item.isPush = false;
          }

          facAll.push(item);
        }
        /*    setFacChoup(response.data.data); */
     /*    console.log('facAllfacAllfacAllfacAll', facAll, facchoup_select); */
        setfacchoup_data(facAll);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    /*   set_product_chack */
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      product_chack.checkthanasub = true;
      /*  oem_fix = "thanasub"; */
    }
    /*  console.log(product_chack.checkthanasub); */
    if (mode.mode === "read") {
      setPage_mode("View");
    } else if (mode.mode === "edit") {
      setPage_mode("Edit");
    } else {
      setPage_mode("Add");
    }
  }, []);

  useEffect(() => {
    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Product";
    });
    if (feature_data.find(el => el.feature === "unit_convert")) {
      setIsUseTabUnitConvert({ data: true });
      isUseTabUnitConvert.data = true;
      GetDropdownUnit();
      GetSystemConfig();
    }
    setfeature(feature_data);
    Getmodel();
    GetRoute();
    Getcolor();
    GetSize();
    GetMat_type();
    GetMat_sub();
    GetMat_size();
    GetMat_spec();
    GetSection();
    GetLines();
    GetProcess();
    GetPrduct_all();
    GetCycleTime();
    GetMachine();
    Get_oem_stp();
    GetCustomer(1, 10, 'search', []);
    GetFactoryChoup(1, 10, 'search', []);
    GetMat_dataLazeload(1, 10, 'search');
    axios(config_sub)
      .then(function (response) {
        setSupplier(response.data);
        const options = [];
        response.data.forEach((el) => {
          var element = {};
          element["id_supplier"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setSupplier_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const Get_oem_stp = async () => {
    axios(config_getOem).then(function (response) {
      const options = [];
      var element2 = {};
      element2["value"] = "All_OEM";
      element2["label"] = "---ทั้งหมด---";
      options.push(element2);
      response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.name;
        options.push(element);
        if (el.id === getOem()) {
          setCurrentOEM({ label: el.name, value: el.id });
        }
      });

      setCompanyGroup(options);
    });
  };
  const GetProd_view = async () => {
    await axios(configProd_view)
      .then(function (response) {
      /*   console.log('TESTTTTT',response.data); */
        setmaterail_select_temp(response.data.multiple_mat);

        setproduct_select_temp(response.data.multiple_product_subAssy);
        if (response.data.is_assy === true) {
          settype_select({
            status: "sub_assy",
          });
        } else {
          settype_select({
            status: "normal",
          });
        }
        let i = 1;
        for (let e of response.data.process_cycle_time) {
          if (e.sort === null) {
            e.sort = i;
            i++;
          }
        }
        response.data.process_cycle_time.sort((a, b) => a.sort - b.sort);
        setprocess_select_data(response.data.process_cycle_time);
        var temp_cus_product = [];
        for (let i = 0; i < response.data.customer_product.length; i++) {
          let temp = {
            id: response.data.customer_product[i].id,
            customer_item: response.data.customer_product[i].customer_item,
            customer_item_name:
              response.data.customer_product[i].customer_item_name,
            plan_item: response.data.no,
            remark: response.data.customer_product[i].remark,
            last_update: response.data.customer_product[i].update_date,
            is_use: true,
            index: i,
          };
          temp_cus_product.push(temp);
        }
        setcustomer_product_data(temp_cus_product);
        setproduct({
          ...product,
          id: response.data.id,
          no: response.data.no || "",
          name: response.data.name || "",
          product_model_id: response.data.product_model_id,
          qty_unit:
            response.data.qty_unit === null
              ? 0
              : response.data.qty_unit.toString(),
          min_product: response.data.min_product,
          min_lot: response.data.min_lot,
          surface: response.data.surface,
          nw_number: response.data.nw_number,
          packing_standart: response.data.packing_standart,
          oem_id: response.data.oem_id,
          image: response.data.image,
          description: response.data.description,
          sell_price:
            response.data.sell_price === null
              ? 0
              : response.data.sell_price.toString(),
          cost: response.data.cost,
          circle_time: response.data.circle_time,
          sku_user: response.data.sku_user,
          product_route_id: response.data.product_route_id,
          product_size_id: response.data.product_size_id,
          product_color_id: response.data.product_color_id,
          image_name: response.data.image_name,
          image_path: response.data.imaget_path,
          sku_system: response.data.sku_system,
          product_material_id: response.data.product_material_id,
          is_assy: response.data.is_assy,
          is_virtual: response.data.is_virtual,
          size: response.data.size,
          unit: response.data.unit,
          thickness: response.data.thickness,
          weight: response.data.weight,
          cross_section_area: response.data.cross_section_area,
          stp_type: response.data.stp_type,
          stp_width: response.data.width,
          stp_height: response.data.height,
          blade_head: response.data.blade_head,
          surface: response.data.surface,
          nw_number: response.data.nw_number,
          packing_standart: response.data.packing_standart,
        });
        let sup_active = response.data.supplier_data.filter((e) => {
          return e.is_active === true;
        });
        if (sup_active.length > 0) {
          setsupplier_active({
            ...supplier_active,
            id: sup_active[0].supplier_id,
            supplier: sup_active[0].supplier,
            buy_price: sup_active[0].buy_price || 0,
            minimum_order: sup_active[0].minimum_order || 0,
            lead_time: sup_active[0].lead_time || 0,
            remark: sup_active[0].remark || "",
          });
        }
        response.data.supplier_data.sort((a, b) => b.sort - a.sort);
        response.data.supplier_data.sort((a, b) => b.is_active - a.is_active);
        setSupplier_select_data(response.data.supplier_data);
        setMachine_select_data(response.data.machine_data || []);

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetProcess_Cycle();

    if ((mode.mode === "read" || mode.mode === "edit") && isUseTabUnitConvert.data) {
      GetUnitConvertByProdId();
    }

    if (mode.mode === "read") {
      GetProd_view();
    } else {
      GetProd_view();
      GetMat_data();
      GetMat_dataLazeload(1, 10, 'search')
    }
  }, []);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(file, 400, 300, "png", 100, 0, (uri) => {
        resolve(uri);
      });
    });
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  function isFileImage(file) {
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
    return file && acceptedImageTypes.includes(file["type"]);
  }
  async function setproductLogo(e) {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const regex = /([\u0E00-\u0E7F]+)/gmu;
      const str = file.name;
      let m = regex.exec(str);
      if (m !== null || isFileImage(file) === false) {
        Swal.fire("Error", "File name or type are not correct!!!", "error");
      } else {
        const image = await resizeFile(file);

        var file_image = dataURLtoFile(image, file.name);
        var data = new FormData();
        data.append("Profile", file_image);
        data.append("typeFile", product.no);

        var config_uploadProfile = {
          method: "post",
          url: Configs.API_URL + "/upload/profile",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config_uploadProfile)
          .then(function (response) {
            if (response.data.status) {
              setproduct({
                ...product,
                image_name: response.data.data.orgin_name,
                image_path: response.data.data.path,
                image: response.data.data,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }
  function fillterdata() {
    GetMat_data();
    GetPrduct_all();
    GetMat_dataLazeload(1, 10, 'search')
  }
  const clear_fillter = async () => {
    await setMat_fillter({
      ...Mat_fillter,
      no: "",
      name: "",
      material_type_id: "",
      material_spec_id: "",
      material_size_id: "",
      material_supplier_id: "",
      oem_id: getOem(),
    });
    Mat_fillter.no = "";
    Mat_fillter.name = "";
    Mat_fillter.material_type_id = "";
    Mat_fillter.material_spec_id = "";
    Mat_fillter.material_size_id = "";
    Mat_fillter.material_supplier_id = "";
    Mat_fillter.oem_id = getOem();
    GetMat_data();
    GetMat_dataLazeload(1, 10, 'search')
    await setfilter_product({
      ...filter_product,
      no: "",
      name: "",
      product_model_id: "",
      oem_id: getOem(),
    });
    filter_product.no = "";
    filter_product.name = "";
    filter_product.product_model_id = "";
    filter_product.oem_id = getOem();
    GetPrduct_all();
  };
  function saveOption() {
    let save_status = false;
    const temp_multiple_material = [];
    const temp_multiple_product = [];
    if (type_select.status === "sub_assy") {
      if (bomlist_excel.length > 0) {
        if (bomlist_excel.tempMultiple_product_sub_assy.length > 0) {
          let another_product;
          another_product = bomlist_excel.tempMultiple_product_sub_assy.filter(
            (element) => {
              return element.part_no != product.no;
            }
          );
          for (var i = 0; i < another_product.length; i++) {
            temp_multiple_product.push(another_product[i]);
          }
          for (var k = 0; k < product_select_temp.length; k++) {
            temp_multiple_product.push(product_select_temp[k]);
          }
        }
      } else {
        for (var k = 0; k < product_select_temp.length; k++) {
          temp_multiple_product.push(product_select_temp[k]);
        }
      }
    } else {
      if (bomlist_excel.length > 0) {
        if (bomlist_excel.tempMultimat.length > 0) {
          let another_material;
          another_material = bomlist_excel.tempMultimat.filter((element) => {
            return element.part_no != product.no;
          });
          for (var i = 0; i < another_material.length; i++) {
            temp_multiple_material.push(another_material[i]);
          }
          for (var k = 0; k < materail_select_temp.length; k++) {
            temp_multiple_material.push(materail_select_temp[k]);
          }
        }
      } else {
        for (var k = 0; k < materail_select_temp.length; k++) {
          temp_multiple_material.push(materail_select_temp[k]);
        }
      }
    }

    let temp_save_edit;
    let temp_save_add;

    if (type_select.status === "sub_assy") {
      temp_save_edit = {
        id: id,
        no: product.no.trim(),
        name: product.name.trim(),
        product_model_id: product.product_model_id,
        qty_unit:
          product.qty_unit === null
            ? 0
            : Number(product.qty_unit.toString().replace(/,/g, "")),
        min_product:
          product.min_product === null
            ? 0
            : Number(product.min_product.toString().replace(/,/g, "")),
        min_lot:
          product.min_lot === null
            ? 0
            : Number(product.min_lot.toString().replace(/,/g, "")),
        surface: product.surface /* ? product.surface.trim() : "" */,
        nw_number:
          product.nw_number === null || product.nw_number === undefined
            ? 0
            : Number(product.nw_number.toString().replace(/,/g, "")),
        packing_standart:
          product.packing_standart === null ||
            product.packing_standart === undefined
            ? 0
            : Number(product.packing_standart.toString().replace(/,/g, "")),
        oem_id: product.oem_id,
        image: product.image,
        description: product.description,
        sell_price:
          product.sell_price === null
            ? 0
            : Number(product.sell_price.toString().replace(/,/g, "")),
        cost:
          product.cost === null
            ? 0
            : Number(product.cost.toString().replace(/,/g, "")),
        circle_time: product.circle_time,
        sku_user: product.sku_user,
        product_route_id: product.product_route_id,
        product_size_id: product.product_size_id,
        product_color_id: product.product_color_id,
        image_name: product.image_name,
        image_path: product.image_path,
        sku_system: product.sku_system,
        product_product_id: product.product_material_id,
        multiple_product_subAssy: temp_multiple_product,
        status_select: type_select.status,
        is_virtual: product.is_virtual,
        customer_product: customer_product_data,
        cycle_time: data_for_section,
        process_cycle_time: process_select_data,
        supplier_data: supplier_select_data,
        machine_data: machine_select_data,
        customer_data: customer_select,
        facchoup_data: facchoup_select,
        size:
          product.size === null
            ? 0
            : Number(product.size.toString().replace(/,/g, "")),
        unit: product.unit,
        thickness:
          product.thickness === null
            ? 0
            : Number(product.thickness.toString().replace(/,/g, "")),
        weight:
          product.weight === null
            ? 0
            : Number(product.weight.toString().replace(/,/g, "")),
        cross_section_area:
          product.cross_section_area === null
            ? 0
            : Number(product.cross_section_area.toString().replace(/,/g, "")),

        stp_type: product.stp_type,
        width:
          product.stp_width === null
            ? 0
            : Number(product.stp_width.toString().replace(/,/g, "")),
        height:
          product.stp_height === null
            ? 0
            : Number(product.stp_height.toString().replace(/,/g, "")),
        blade_head: product.blade_head,
      };

      temp_save_add = {
        check_product: product_chack.checkthanasub,
        no: product.no.trim(),
        name: product.name.trim(),
        product_model_id: product.product_model_id,
        qty_unit:
          product.qty_unit === null
            ? 0
            : Number(product.qty_unit.toString().replace(/,/g, "")),
        min_product:
          product.min_product === null
            ? 0
            : Number(product.min_product.toString().replace(/,/g, "")),
        min_lot:
          product.min_lot === null
            ? 0
            : Number(product.min_lot.toString().replace(/,/g, "")),
        surface: product.surface /* ? product.surface.trim() : "" */,
        nw_number:
          product.nw_number === null || product.nw_number === undefined
            ? 0
            : Number(product.nw_number.toString().replace(/,/g, "")),
        packing_standart:
          product.packing_standart === null ||
            product.packing_standart === undefined
            ? 0
            : Number(product.packing_standart.toString().replace(/,/g, "")),
        oem_id: product.oem_id,
        image: product.image,
        description: product.description,
        sell_price:
          product.sell_price === null
            ? 0
            : Number(product.sell_price.toString().replace(/,/g, "")),
        cost:
          product.cost === null
            ? 0
            : Number(product.cost.toString().replace(/,/g, "")),
        circle_time: product.circle_time,
        sku_user: product.sku_user,
        product_route_id: product.product_route_id,
        product_size_id: product.product_size_id,
        product_color_id: product.product_color_id,
        image_name: product.image_name,
        image_path: product.image_path,
        sku_system: product.sku_system,
        is_virtual: product.is_virtual,
        multiple_product_subAssy: temp_multiple_product,
        status_select: type_select.status,
        customer_product: customer_product_data,
        cycle_time: data_for_section,
        process_cycle_time: process_select_data,
        supplier_data: supplier_select_data,
        machine_data: machine_select_data,
        customer_data: customer_select,
        facchoup_data: facchoup_select,
        size:
          product.size === null
            ? 0
            : Number(product.size.toString().replace(/,/g, "")),
        unit: product.unit,
        thickness:
          product.thickness === null
            ? 0
            : Number(product.thickness.toString().replace(/,/g, "")),
        weight:
          product.weight === null
            ? 0
            : Number(product.weight.toString().replace(/,/g, "")),
        cross_section_area:
          product.cross_section_area === null
            ? 0
            : Number(product.cross_section_area.toString().replace(/,/g, "")),
        stp_type: product.stp_type,
        width:
          product.stp_width === null
            ? 0
            : Number(product.stp_width.toString().replace(/,/g, "")),
        height:
          product.stp_height === null
            ? 0
            : Number(product.stp_height.toString().replace(/,/g, "")),
        blade_head: product.blade_head,
      };
    } else {
      temp_save_edit = {
        id: id,
        no: product.no,
        name: product.name,
        product_model_id: product.product_model_id,
        qty_unit:
          product.qty_unit === null
            ? 0
            : Number(product.qty_unit.toString().replace(/,/g, "")),
        min_product:
          product.min_product === null
            ? 0
            : Number(product.min_product.toString().replace(/,/g, "")),
        min_lot:
          product.min_lot === null
            ? 0
            : Number(product.min_lot.toString().replace(/,/g, "")),
        surface: product.surface /* ? product.surface.trim() : "" */,
        nw_number:
          product.nw_number === null || product.nw_number === undefined
            ? 0
            : Number(product.nw_number.toString().replace(/,/g, "")),
        packing_standart:
          product.packing_standart === null ||
            product.packing_standart === undefined
            ? 0
            : Number(product.packing_standart.toString().replace(/,/g, "")),
        oem_id: product.oem_id,
        image: product.image,
        description: product.description,
        sell_price:
          product.sell_price === null
            ? 0
            : Number(product.sell_price.toString().replace(/,/g, "")),
        cost:
          product.cost === null
            ? 0
            : Number(product.cost.toString().replace(/,/g, "")),
        circle_time: product.circle_time,
        sku_user: product.sku_user,
        product_route_id: product.product_route_id,
        product_size_id: product.product_size_id,
        product_color_id: product.product_color_id,
        image_name: product.image_name,
        image_path: product.image_path,
        sku_system: product.sku_system,
        product_material_id: product.product_material_id,
        multiple_material: temp_multiple_material,
        status_select: "normal",
        is_virtual: product.is_virtual,
        wt_mtr: product.wt_mtr,
        c_leng: product.c_leng,
        cos_pc: product.cos_pc,
        customer_product: customer_product_data,
        cycle_time: data_for_section,
        process_cycle_time: process_select_data,
        supplier_data: supplier_select_data,
        machine_data: machine_select_data,
        customer_data: customer_select,
        facchoup_data: facchoup_select,
        size:
          product.size === null
            ? 0
            : Number(product.size.toString().replace(/,/g, "")),
        unit: product.unit,
        thickness:
          product.thickness === null
            ? 0
            : Number(product.thickness.toString().replace(/,/g, "")),
        weight:
          product.weight === null
            ? 0
            : Number(product.weight.toString().replace(/,/g, "")),
        cross_section_area:
          product.cross_section_area === null
            ? 0
            : Number(product.cross_section_area.toString().replace(/,/g, "")),
        stp_type: product.stp_type,
        width:
          product.stp_width === null
            ? 0
            : Number(product.stp_width.toString().replace(/,/g, "")),
        height:
          product.stp_height === null
            ? 0
            : Number(product.stp_height.toString().replace(/,/g, "")),
        blade_head: product.blade_head,
      };

      temp_save_add = {
        check_product: product_chack.checkthanasub,
        no: product.no,
        name: product.name,
        product_model_id: product.product_model_id,
        qty_unit:
          product.qty_unit === null
            ? 0
            : Number(product.qty_unit.toString().replace(/,/g, "")),
        min_product:
          product.min_product === null
            ? 0
            : Number(product.min_product.toString().replace(/,/g, "")),
        min_lot:
          product.min_lot === null
            ? 0
            : Number(product.min_lot.toString().replace(/,/g, "")),
        surface: product.surface /* ? product.surface.trim() : "" */,
        nw_number:
          product.nw_number === null || product.nw_number === undefined
            ? 0
            : Number(product.nw_number.toString().replace(/,/g, "")),
        packing_standart:
          product.packing_standart === null ||
            product.packing_standart === undefined
            ? 0
            : Number(product.packing_standart.toString().replace(/,/g, "")),
        oem_id: product.oem_id,
        image: product.image,
        description: product.description,
        sell_price:
          product.sell_price === null
            ? 0
            : Number(product.sell_price.toString().replace(/,/g, "")),
        cost:
          product.cost === null
            ? 0
            : Number(product.cost.toString().replace(/,/g, "")),
        circle_time: product.circle_time,
        sku_user: product.sku_user,
        product_route_id: product.product_route_id,
        product_size_id: product.product_size_id,
        product_color_id: product.product_color_id,
        image_name: product.image_name,
        image_path: product.image_path,
        sku_system: product.sku_system,
        multiple_material: temp_multiple_material,
        status_select: "normal",
        material_id: product.material_id,
        is_virtual: product.is_virtual,
        wt_mtr: product.wt_mtr,
        c_leng: product.c_leng,
        cos_pc: product.cos_pc,
        customer_product: customer_product_data,
        cycle_time: data_for_section,
        process_cycle_time: process_select_data,
        supplier_data: supplier_select_data,
        machine_data: machine_select_data,
        customer_data: customer_select,
        facchoup_data: facchoup_select,
        size:
          product.size === null
            ? 0
            : Number(product.size.toString().replace(/,/g, "")),
        unit: product.unit,
        thickness:
          product.thickness === null
            ? 0
            : Number(product.thickness.toString().replace(/,/g, "")),
        weight:
          product.weight === null
            ? 0
            : Number(product.weight.toString().replace(/,/g, "")),
        cross_section_area:
          product.cross_section_area === null
            ? 0
            : Number(product.cross_section_area.toString().replace(/,/g, "")),
        stp_type: product.stp_type,
        width:
          product.stp_width === null
            ? 0
            : Number(product.stp_width.toString().replace(/,/g, "")),
        height:
          product.stp_height === null
            ? 0
            : Number(product.stp_height.toString().replace(/,/g, "")),
        blade_head: product.blade_head,
      };
    }

    /* console.log("SAVE ADD", temp_save_add);
    console.log("edit", temp_save_edit); */
    const error_list = [];

    if (product.no.trim() === "" && product_chack.checkthanasub === false) {
      let temp_err = {
        message: "Please enter information in the fields >>> [Product No].",
      };
      error_list.push(temp_err);
    }
    if (product.name.trim() === "") {
      let temp_err = {
        message: "Please enter information in the fields >>> [Product Name].",
      };
      error_list.push(temp_err);
    }

    checkErrorTabUnitConvert(error_list);

    /*  if (product.surface.trim()) {
       let temp_err = {
         message: "Please enter information in the fields >>> [Product surface].",
       };
       error_list.push(temp_err);
     } */
    if (error_list.length > 0) {
      var err_message = "";
      for (var e = 0; e < error_list.length; e++) {
        err_message += "<br/>" + error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
      save_status = false;
    } else {
      save_status = true;
    }
    if (save_status && mode.mode === "add") {
     /*  console.log('โหมด Add',temp_save_add); */
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then(async (result) => {
        await axios({
          method: "post",
          url: Configs.API_URL + "/product/add",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_save_add,
        })
          .then(async function (response) {
            if (response.data) {
              await SaveTabUnitConvert(response.data);
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Product";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    } else if (save_status && mode.mode === "edit") {
      console.log(temp_save_edit);
      const data_temp_cycle = [];
      for (const section of data_for_section) {
        for (const cycle of section.process) {
          data_temp_cycle.push(cycle);
        }
      }

      // const data_temp_cycle2 = data_temp_cycle.filter((data) => {
      //   if (data.cycle_time > 0) {
      //     return data;
      //   }
      //   {
      //   }
      // });
      Swal.fire({
        title: "Do you want to save the changes?",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Save`,
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: Configs.API_URL + "/product/update",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp_save_edit,
          })
            .then(async function (response) {
              if (response.data) {
                await SaveTabUnitConvert(id);
                Swal.fire({
                  icon: "success",
                  title: "Save",
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  window.location.href =
                    "/Warehouse_Management/Factory_Master_Data/Product";
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Error", error.response.data.message, "error");
            });
        } else if (result.isDenied) {
          Swal.fire({
            icon: "warning",
            title: "Canceling",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            window.location.href =
              "/Warehouse_Management/Factory_Master_Data/Product";
          });
        }
      });
    }
  }
  function cancelOption(params) {
    Swal.fire({
      title: "Do you want to cancel and leave the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href =
          "/Warehouse_Management/Factory_Master_Data/Product";
      }
    });
  }

  const upload_bomlist_file = async (e) => {
    if (e.target.files[0]) {
      var data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("oem_id", getOem());
      data.append("part_no", product.no);

      var config_uploadExecl = {
        method: "post",
        url: Configs.API_URL + "/product/importExcel_Bomlist",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config_uploadExecl)
        .then(function (response) {
          let num = 0;
          for (let index = 0; index < response.data.tempRes.length; index++) {
            if (response.data.tempRes[index].status === false) {
              num++;
            }
          }
          if (num !== 0) {
            setbomlist_excel(response.data.tempRes);
            setexcel(response.data.tempRes);
          } else {
            setbomlist_excel(response.data.tempRes);
            setexcel(response.data.tempRes);

            Swal.fire(
              "Upload Success",
              "Your file has been Upload.",
              "success"
            ).then(() => {
              var temp_bomlist = [];

              for (var e = 0; e < response.data.tempMultimat.length; e++) {
                if (materail_select_temp.length > 0) {
                  let check = materail_select_temp.filter((element) => {
                    return (
                      element.material_id ===
                      response.data.tempMultimat[e].material_id
                    );
                  });
                  if (check.length > 0) {
                    if (
                      check[0].material_id ===
                      response.data.tempMultimat[e].material_id
                    ) {
                      var temp = {
                        c_leng: response.data.tempMultimat[e].c_leng,
                        cos_pc: response.data.tempMultimat[e].cos_pc,
                        id: response.data.tempMultimat[e].id,
                        material_id: response.data.tempMultimat[e].material_id,
                        material_size_id:
                          response.data.tempMultimat[e].material_size_id,
                        material_spec_id:
                          response.data.tempMultimat[e].material_spec_id,
                        material_type_id:
                          response.data.tempMultimat[e].material_type_id,
                        name: response.data.tempMultimat[e].name,
                        no: response.data.tempMultimat[e].no,
                        product_id: response.data.tempMultimat[e].product_id,
                        remark: response.data.tempMultimat[e].remark,
                        wt_mtr: response.data.tempMultimat[e].wt_mtr,
                        /* nw_number:  response.data.tempMultimat[e].nw_number, */
                        gw_number: response.data.tempMultimat[e].gw_number,
                        d_number: response.data.tempMultimat[e].d_number,
                        t_number: response.data.tempMultimat[e].t_number,
                        w_number: response.data.tempMultimat[e].w_number,
                        l_number: response.data.tempMultimat[e].l_number,
                        /* country_number:  response.data.tempMultimat[e].country_number, */
                      };
                      temp_bomlist.push(temp);
                    }
                  } else {
                    temp_bomlist.push(response.data.tempMultimat[e]);
                  }
                } else {
                  temp_bomlist.push(response.data.tempMultimat[e]);
                }
              }

              for (var e2 = 0; e2 < materail_select_temp.length; e2++) {
                let check2 = temp_bomlist.filter((element) => {
                  return (
                    element.material_id === materail_select_temp[e2].material_id
                  );
                });
                if (check2.length > 0) {
                } else {
                  temp_bomlist.push(materail_select_temp[e2]);
                }
              }
              setbomlist_excel(response.data);
              setmaterail_select_temp(temp_bomlist);

              var temp_mat1 = [];
              for (let i = 0; i < materail.length; i++) {
                const element = {};
                element["id"] = materail[i].id;
                element["no"] = materail[i].no;
                element["name"] = materail[i].name;
                element["material_type_id"] = materail[i].material_type_id;
                element["material_spec_id"] = materail[i].material_spec_id;
                element["material_size_id"] = materail[i].material_size_id;
                element["buy_price"] = materail[i].buy_price;
                element["material_unit"] = materail[i].material_unit;
                element["material_supplier_id"] =
                  materail[i].material_supplier_id;
                element["description"] = materail[i].description;
                element["remark"] = materail[i].remark;
                element["isSelect"] = materail[i].isSelect;

                for (let k = 0; k < temp_bomlist.length; k++) {
                  if (materail[i].id === temp_bomlist[k].material_id) {
                    element["id_mp"] = temp_bomlist[k].id;
                    element["isSelect"] = true;
                    element["wt_mtr"] = temp_bomlist[k].wt_mtr;
                    element["c_leng"] = temp_bomlist[k].c_leng;
                    element["cos_pc"] = temp_bomlist[k].cos_pc;
                    element["amount_txt"] = temp_bomlist[k].amount_txt;
                    element["unit_txt"] = temp_bomlist[k].unit_txt;
                    /* element["nw_number"] =  temp_bomlist[k].nw_number; */
                    element["gw_number"] = temp_bomlist[k].gw_number;
                    element["d_number"] = temp_bomlist[k].d_number;
                    element["t_number"] = temp_bomlist[k].t_number;
                    element["w_number"] = temp_bomlist[k].w_number;
                    element["l_number"] = temp_bomlist[k].l_number;
                    /* element["country_number"] =  temp_bomlist[k].country_number; */
                  }
                }

                temp_mat1.push(element);
              }
              setmaterail(...materail);
              setmaterail(temp_mat1);
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  const upload_bomlist_file_assy = async (e) => {
    if (e.target.files[0]) {
      var data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("oem_id", getOem());
      data.append("part_no", product.no);

      var config_uploadExecl_assy = {
        method: "post",
        url: Configs.API_URL + "/product/importExcel_Bomlist_Assy",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config_uploadExecl_assy)
        .then(function (response) {
          let num = 0;
          for (let index = 0; index < response.data.tempRes.length; index++) {
            if (response.data.tempRes[index].status === false) {
              num++;
            }
          }

          if (num !== 0) {
            setbomlist_excel(response.data.tempRes);
            setexcel(response.data.tempRes);
          } else {
            setbomlist_excel(response.data.tempRes);
            setexcel(response.data.tempRes);

            Swal.fire(
              "Upload Success",
              "Your file has been Upload.",
              "success"
            ).then(() => {
              var temp_bomlist_assy = [];
              for (
                var e = 0;
                e < response.data.tempMultiple_product_sub_assy.length;
                e++
              ) {
                if (product_select_temp.length > 0) {
                  let check = product_select_temp.filter((element) => {
                    return (
                      element.product_sub ===
                      response.data.tempMultiple_product_sub_assy[e].product_sub
                    );
                  });
                  if (check.length > 0) {
                    if (
                      check[0].product_sub ===
                      response.data.tempMultiple_product_sub_assy[e].product_sub
                    ) {
                      var temp = {
                        amount:
                          response.data.tempMultiple_product_sub_assy[e].amount,
                        id: response.data.tempMultiple_product_sub_assy[e].id,
                        name: response.data.tempMultiple_product_sub_assy[e]
                          .name,
                        no: response.data.tempMultiple_product_sub_assy[e].no,
                        product_id:
                          response.data.tempMultiple_product_sub_assy[e]
                            .product_id,
                        product_sub:
                          response.data.tempMultiple_product_sub_assy[e]
                            .product_sub,
                        remark:
                          response.data.tempMultiple_product_sub_assy[e].remark,
                        isSelect: true,
                      };
                      temp_bomlist_assy.push(temp);
                    }
                  } else {
                    temp_bomlist_assy.push(
                      response.data.tempMultiple_product_sub_assy[e]
                    );
                  }
                } else {
                  temp_bomlist_assy.push(
                    response.data.tempMultiple_product_sub_assy[e]
                  );
                }
              }
              if (product_select_temp.length > 0) {
                for (var e2 = 0; e2 < product_select_temp.length; e2++) {
                  let check2 = temp_bomlist_assy.filter((element) => {
                    return (
                      element.product_sub ===
                      product_select_temp[e2].product_sub
                    );
                  });
                  if (check2.length > 0) {
                  } else {
                    temp_bomlist_assy.push(product_select_temp[e2]);
                  }
                }
              }
              setbomlist_excel(response.data);
              setproduct_select_temp(temp_bomlist_assy);
              var temp_pro_assy1 = [];
              for (var i = 0; i < product_all.length; i++) {
                const element = {};
                element["id"] = product_all[i].id;
                element["no"] = product_all[i].no;
                element["name"] = product_all[i].name;
                element["isSelect"] = product_all[i].isSelect;
                for (var k = 0; k < temp_bomlist_assy.length; k++) {
                  if (product_all[i].id === temp_bomlist_assy[k].product_sub) {
                    element["id_pp"] = temp_bomlist_assy[k].product_sub;
                    element["isSelect"] = true;
                    element["amount"] = temp_bomlist_assy[k].amount;
                  }
                }
                temp_pro_assy1.push(element);
              }
              setproduct_all(...product_all);
              setproduct_all(temp_pro_assy1);
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  if (target == "step3") {
    var class_active_step1 = "tab-pane fade";
    var class_active_step2 = "tab-pane fade";
    var class_active_step3 = "tab-pane fade show active";
    var class_active_step4 = "tab-pane fade";
    var class_active_step5 = "tab-pane fade";
    var class_active_step6 = "tab-pane fade";
    var class_active_step7 = "tab-pane fade";
    var class_active_step8 = "tab-pane fade";
    var class_active_step9 = "tab-pane fade";
    var class_active_step10 = "tab-pane fade";
    var class_active_step11 = "tab-pane fade";
    var nav_active_step1 = "nav-link";
    var nav_active_step2 = "nav-link";
    var nav_active_step3 = "nav-link active";
    var nav_active_step4 = "nav-link";
    var nav_active_step5 = "nav-link";
    var nav_active_step6 = "nav-link";
    var nav_active_step7 = "nav-link";
    var nav_active_step8 = "nav-link";
    var nav_active_step9 = "nav-link";
    var nav_active_step10 = "nav-link";
    var nav_active_step11 = "nav-link";
  } else {
    var class_active_step1 = "tab-pane fade show active";
    var class_active_step2 = "tab-pane fade";
    var class_active_step3 = "tab-pane fade";
    var class_active_step4 = "tab-pane fade";
    var class_active_step5 = "tab-pane fade";
    var class_active_step6 = "tab-pane fade";
    var class_active_step7 = "tab-pane fade";
    var class_active_step8 = "tab-pane fade";
    var class_active_step9 = "tab-pane fade";
    var class_active_step10 = "tab-pane fade";
    var class_active_step11 = "tab-pane fade";
    var nav_active_step1 = "nav-link active";
    var nav_active_step2 = "nav-link";
    var nav_active_step3 = "nav-link ";
    var nav_active_step4 = "nav-link";
    var nav_active_step5 = "nav-link";
    var nav_active_step6 = "nav-link";
    var nav_active_step7 = "nav-link";
    var nav_active_step8 = "nav-link";
    var nav_active_step9 = "nav-link";
    var nav_active_step10 = "nav-link";
    var nav_active_step11 = "nav-link";
  }
  function set_bomlist_excel() {
    if (excel.filter((x) => !x.status).length > 0) {
      setexcel([]);
      window.location.href =
        "/Warehouse_Management/Factory_Master_Data/Product/edit/" +
        id +
        "/step3/" +
        status_assy;
    }
    if (type_select.status == "normal") {
      const temp_mat = [];
      for (let i = 0; i < materail.length; i++) {
        const element = {};
        element["id"] = materail[i].id;
        element["no"] = materail[i].no;
        element["name"] = materail[i].name;
        element["material_type_id"] = materail[i].material_type_id;
        element["material_spec_id"] = materail[i].material_spec_id;
        element["material_size_id"] = materail[i].material_size_id;
        element["buy_price"] = materail[i].buy_price;
        element["material_unit"] = materail[i].material_unit;
        element["material_supplier_id"] = materail[i].material_supplier_id;
        element["description"] = materail[i].description;
        element["remark"] = materail[i].remark;
        element["isSelect"] = false;
        for (let k = 0; k < materail_select_temp.length; k++) {
          if (materail[i].id === materail_select_temp[k].material_id) {
            element["id_mp"] = materail_select_temp[k].id;
            element["isSelect"] = true;
            element["wt_mtr"] = materail_select_temp[k].wt_mtr;
            element["c_leng"] = materail_select_temp[k].c_leng;
            element["cos_pc"] = materail_select_temp[k].cos_pc;
            element["amount_txt"] = materail_select_temp[k].amount_txt;
            element["unit_txt"] = materail_select_temp[k].unit_txt;
            /* element["nw_number"] =  materail_select_temp[k].nw_number; */
            element["gw_number"] = materail_select_temp[k].gw_number;
            element["d_number"] = materail_select_temp[k].d_number;
            element["t_number"] = materail_select_temp[k].t_number;
            element["w_number"] = materail_select_temp[k].w_number;
            element["l_number"] = materail_select_temp[k].l_number;
            /*  element["country_number"] =  materail_select_temp[k].country_number; */
          }
        }
        temp_mat.push(element);
      }
      setmaterail(temp_mat);
    } else if (type_select.status == "sub_assy") {
      const temp_product = [];
      for (let i = 0; i < product_all.length; i++) {
        const element = {};
        element["id"] = product_all[i].id;
        element["no"] = product_all[i].no;
        element["name"] = product_all[i].name;
        element["isSelect"] = false;

        for (let k = 0; k < product_select_temp.length; k++) {
          if (product_all[i].id === product_select_temp[k].product_sub) {
            element["id_pp"] = product_select_temp[k].id;
            element["isSelect"] = true;
            element["amount"] = product_select_temp[k].amount;
          }
        }

        temp_product.push(element);
      }
      setproduct_all(temp_product);
    }
  }

  const data_for_section = [];

  if (mode.mode !== "add") {
    if (section.length > 0) {
      for (let section_i = 0; section_i < section.length; section_i++) {
        const element = {};
        const element2 = [];
        element["section_id"] = section[section_i].id;
        element["section"] = section[section_i].section_name;
        for (let process_i = 0; process_i < process.length; process_i++) {
          const element4 = {};
          if (section[section_i].id === process[process_i].section_id) {
            element4["product_id"] = id;
            element4["process_id"] = process[process_i].id;
            element4["cycle_time"] =
              "" /* cycle_time[cycle_time_i].cycle_time */;
            element4["process_name"] = process[process_i].process_name;
            element2.push(element4);
          }
        }
        for (let element2_i = 0; element2_i < element2.length; element2_i++) {
          for (
            let cycle_time_i = 0;
            cycle_time_i < cycle_time.length;
            cycle_time_i++
          ) {
            if (
              element2[element2_i].process_id ===
              cycle_time[cycle_time_i].process_id
            ) {
              element2[element2_i].cycle_time =
                cycle_time[cycle_time_i].cycle_time;
            }
          }
        }
        if (element2.length !== 0) {
          for (let element2_i = 0; element2_i < element2.length; element2_i++) {
            for (
              let cycle_time_i = 0;
              cycle_time_i < cycle_time.length;
              cycle_time_i++
            ) {
              if (
                element2[element2_i].process_id ===
                cycle_time[cycle_time_i].process_id
              ) {
                element2[element2_i].cycle_time =
                  cycle_time[cycle_time_i].cycle_time;
              }
            }
          }
          element["process"] = element2;
          data_for_section.push(element);
        }
      }
    } else {
      let temp = {
        section: "ไม่มีข้อมูล",
        process: [],
      };
      data_for_section.push(temp);
    }
  } else {
    for (let section_i = 0; section_i < section.length; section_i++) {
      const element = {};
      const element2 = [];
      element["section_id"] = section[section_i].id;
      element["section"] = section[section_i].section_name;
      for (let process_i = 0; process_i < process.length; process_i++) {
        const element3 = {};

        if (section[section_i].id === process[process_i].section_id) {
          element3["id"] = process[process_i].id;
          element3["process_id"] = process[process_i].id;
          element3["cycle_time"] = "";
          element3["process_name"] = process[process_i].process_name;

          element2.push(element3);
        }
      }

      if (element2.length !== 0) {
        element["process"] = element2;
        data_for_section.push(element);
      }
    }
  }

  function GetProcessData(data) {
    const i = data.target.id.split("_")[0];
    const j = data.target.id.split("_")[1];
    if (isNaN(data.target.value)) {
      Swal.fire(
        "Error",
        "Cycle time has to be numeral" /* +isNaN(data.target.value) */,
        "error"
      );
    } else {
      data_for_section[i].process[j].cycle_time = data.target.value;
    }
  }

  const materail_select_item = async (el) => {
    const tempData = materail;
    const tempDataLazeload = materailLazeload;
    const tempSelect = materail_select_temp;
    var id_del = null;
    var index = tempData.findIndex((element) => element.id === el.target.name);
    for (var t = 0; t < tempData.length; t++) {
      if (tempData[t].id === el.target.name) {
        if (tempData[t].isSelect) {
          tempData[t].isSelect = false;
          tempData[t].id = tempData[t].id;
          id_del = tempData[t].id;
        } else {
          tempData[t].isSelect = true;
          tempData[t].id = tempData[t].id;
        }
      }
    }
    var index = tempDataLazeload.findIndex((element) => element.id === el.target.name);
    for (var t = 0; t < tempDataLazeload.length; t++) {
      if (tempDataLazeload[t].id === el.target.name) {
        if (tempDataLazeload[t].isSelect) {
          tempDataLazeload[t].isSelect = false;
          tempDataLazeload[t].id = tempDataLazeload[t].id;
          id_del = tempDataLazeload[t].id;
        } else {
          tempDataLazeload[t].isSelect = true;
          tempDataLazeload[t].id = tempDataLazeload[t].id;
        }
      }
    }
    await setmaterail(tempData);
  /*   console.log('tempDataLazeload',tempDataLazeload); */
    await setmaterailLazeload(tempDataLazeload);

    const materail_select_temp1 = materail.filter((element) => {
      return element.isSelect === true;
    });
    const materail_select_temp4 = tempDataLazeload.filter((element) => {
      return element.isSelect === true;
    });

    const tempIn_materail = [];

    for (var index = 0; index < materail_select_temp1.length; index++) {
      if (
        materail_select_temp1[index].wt_mtr != "" ||
        materail_select_temp1[index].c_leng != "" ||
        materail_select_temp1[index].cos_pc != ""
      ) {
        const temp_materail = {
          id: materail_select_temp1[index].id_mp || null,
          product_id: product.id || null,
          material_id: materail_select_temp1[index].id,
          no: materail_select_temp1[index].no,
          name: materail_select_temp1[index].name,
          material_type_id: materail_select_temp1[index].material_type_id,
          material_spec_id: materail_select_temp1[index].material_spec_id,
          material_size_id: materail_select_temp1[index].material_size_id,
          wt_mtr: materail_select_temp1[index].wt_mtr,
          c_leng: materail_select_temp1[index].c_leng,
          cos_pc: materail_select_temp1[index].cos_pc,
          amount_txt: materail_select_temp1[index].amount_txt,
          unit_txt: materail_select_temp1[index].unit_txt,
          /*  nw_number:   materail_select_temp1[index].nw_number, */
          gw_number: materail_select_temp1[index].gw_number,
          d_number: materail_select_temp1[index].d_number,
          t_number: materail_select_temp1[index].t_number,
          w_number: materail_select_temp1[index].w_number,
          l_number: materail_select_temp1[index].l_number,
          /* country_number:   materail_select_temp1[index].country_number, */
        };
        tempIn_materail.push(temp_materail);
      } else {
        const temp_materail = {
          id: materail_select_temp1[index].id_mp || null,
          product_id: product.id || null,
          material_id: materail_select_temp1[index].id,
          no: materail_select_temp1[index].no,
          name: materail_select_temp1[index].name,
          material_type_id: materail_select_temp1[index].material_type_id,
          material_spec_id: materail_select_temp1[index].material_spec_id,
          material_size_id: materail_select_temp1[index].material_size_id,
          wt_mtr: "",
          c_leng: "",
          cos_pc: "",
          amount_txt: materail_select_temp1[index].amount_txt || "",
          unit_txt: materail_select_temp1[index].unit_txt || "",
          /*  nw_number:   materail_select_temp1[index].nw_number || "", */
          gw_number: materail_select_temp1[index].gw_number || "",
          d_number: materail_select_temp1[index].d_number || "",
          t_number: materail_select_temp1[index].t_number || "",
          w_number: materail_select_temp1[index].w_number || "",
          l_number: materail_select_temp1[index].l_number || "",
          /*  country_number:   materail_select_temp1[index].country_number || "", */
        };

        tempIn_materail.push(temp_materail);
      }
    }
    var checked_del = tempSelect.filter((e) => {
      return e.material_id === id_del;
    });
    for (var t = 0; t < tempSelect.length; t++) {
      var add_test = tempIn_materail.filter((e) => {
        return e.material_id === tempSelect[t].material_id;
      });
      if (add_test.length > 0) {
      } else {
        const temp_materail = {
          id: tempSelect[t].id_mp || null,
          product_id: product.id || null,
          material_id: tempSelect[t].material_id,
          no: tempSelect[t].no,
          name: tempSelect[t].name,
          material_type_id: tempSelect[t].material_type_id,
          material_spec_id: tempSelect[t].material_spec_id,
          material_size_id: tempSelect[t].material_size_id,
          wt_mtr: "",
          c_leng: "",
          cos_pc: "",
        };
        tempIn_materail.push(temp_materail);
      }
    }
    var indexDel = tempIn_materail.findIndex(
      (element) => element.material_id === id_del
    );
    //console.log(indexDel)
    if (indexDel >= 0) {
      tempIn_materail.splice(indexDel, 1);
    }
  /*   console.log('ตัวที่เลือก๘๘',tempIn_materail) */
    await setmaterail_select_temp(tempIn_materail);
    settest({...test,test:''})
    test.test = '';
  };


  const materail_select_itemNolaze = async (el) => {
  /*   const tempData = materail; */
    const tempDataLazeload = materailLazeload;
    const tempSelect = materail_select_temp;
    var id_del = null;
  
    var index = tempDataLazeload.findIndex((element) => element.id === el.target.name);
    console.log('el.target',index);
    if(index > -1){
      tempDataLazeload[index].isSelect = tempDataLazeload[index].isSelect != undefined ? !tempDataLazeload[index].isSelect : true
    }
  /*   for (var t = 0; t < tempDataLazeload.length; t++) {
      if (tempDataLazeload[t].id === el.target.name) {
        if (tempDataLazeload[t].isSelect) {
          tempDataLazeload[t].isSelect = false;
          tempDataLazeload[t].id = tempDataLazeload[t].id;
          id_del = tempDataLazeload[t].id;
        } else {
          tempDataLazeload[t].isSelect = true;
          tempDataLazeload[t].id = tempDataLazeload[t].id;
        }
      }
    } */
/*     await setmaterail(tempData); */
  /*   console.log('tempDataLazeload',tempDataLazeload); */
    await setmaterailLazeload(tempDataLazeload);

    // const materail_select_temp1 = tempDataLazeload.filter((element) => {
    //   return element.isSelect === true;
    // });


    // const tempIn_materail = [];

    // for (var index = 0; index < materail_select_temp1.length; index++) {
    //   if (
    //     materail_select_temp1[index].wt_mtr != "" ||
    //     materail_select_temp1[index].c_leng != "" ||
    //     materail_select_temp1[index].cos_pc != ""
    //   ) {
    //     const temp_materail = {
    //       id: materail_select_temp1[index].id_mp || null,
    //       product_id: product.id || null,
    //       material_id: materail_select_temp1[index].id,
    //       no: materail_select_temp1[index].no,
    //       name: materail_select_temp1[index].name,
    //       material_type_id: materail_select_temp1[index].material_type_id,
    //       material_spec_id: materail_select_temp1[index].material_spec_id,
    //       material_size_id: materail_select_temp1[index].material_size_id,
    //       wt_mtr: materail_select_temp1[index].wt_mtr,
    //       c_leng: materail_select_temp1[index].c_leng,
    //       cos_pc: materail_select_temp1[index].cos_pc,
    //       amount_txt: materail_select_temp1[index].amount_txt,
    //       unit_txt: materail_select_temp1[index].unit_txt,
    //       /*  nw_number:   materail_select_temp1[index].nw_number, */
    //       gw_number: materail_select_temp1[index].gw_number,
    //       d_number: materail_select_temp1[index].d_number,
    //       t_number: materail_select_temp1[index].t_number,
    //       w_number: materail_select_temp1[index].w_number,
    //       l_number: materail_select_temp1[index].l_number,
    //       /* country_number:   materail_select_temp1[index].country_number, */
    //     };
    //     tempIn_materail.push(temp_materail);
    //   } else {
    //     const temp_materail = {
    //       id: materail_select_temp1[index].id_mp || null,
    //       product_id: product.id || null,
    //       material_id: materail_select_temp1[index].id,
    //       no: materail_select_temp1[index].no,
    //       name: materail_select_temp1[index].name,
    //       material_type_id: materail_select_temp1[index].material_type_id,
    //       material_spec_id: materail_select_temp1[index].material_spec_id,
    //       material_size_id: materail_select_temp1[index].material_size_id,
    //       wt_mtr: "",
    //       c_leng: "",
    //       cos_pc: "",
    //       amount_txt: materail_select_temp1[index].amount_txt || "",
    //       unit_txt: materail_select_temp1[index].unit_txt || "",
    //       /*  nw_number:   materail_select_temp1[index].nw_number || "", */
    //       gw_number: materail_select_temp1[index].gw_number || "",
    //       d_number: materail_select_temp1[index].d_number || "",
    //       t_number: materail_select_temp1[index].t_number || "",
    //       w_number: materail_select_temp1[index].w_number || "",
    //       l_number: materail_select_temp1[index].l_number || "",
    //       /*  country_number:   materail_select_temp1[index].country_number || "", */
    //     };

    //     tempIn_materail.push(temp_materail);
    //   }
    // }
    // var checked_del = tempSelect.filter((e) => {
    //   return e.material_id === id_del;
    // });
    // for (var t = 0; t < tempSelect.length; t++) {
    //   var add_test = tempIn_materail.filter((e) => {
    //     return e.material_id === tempSelect[t].material_id;
    //   });
    //   if (add_test.length > 0) {
    //   } else {
    //     const temp_materail = {
    //       id: tempSelect[t].id_mp || null,
    //       product_id: product.id || null,
    //       material_id: tempSelect[t].material_id,
    //       no: tempSelect[t].no,
    //       name: tempSelect[t].name,
    //       material_type_id: tempSelect[t].material_type_id,
    //       material_spec_id: tempSelect[t].material_spec_id,
    //       material_size_id: tempSelect[t].material_size_id,
    //       wt_mtr: "",
    //       c_leng: "",
    //       cos_pc: "",
    //     };
    //     tempIn_materail.push(temp_materail);
    //   }
    // }
    // var indexDel = tempIn_materail.findIndex(
    //   (element) => element.material_id === id_del
    // );
    // if (indexDel >= 0) {
    //   tempIn_materail.splice(indexDel, 1);
    // }
    
    // await setmaterail_select_temp(tempIn_materail);
    settest({...test,test:''})
    test.test = '';
  };



  async function restore_material() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product/edit/" +
      id +
      "/step3/false";
  }

  async function restore_product() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product/edit/" +
      id +
      "/step3/true";
  }

  function wt_mtr_check(e) {
    materail_select_temp[e.target.name].wt_mtr = e.target.value;
  }

  function c_leng_check(e) {
    materail_select_temp[e.target.name].c_leng = e.target.value;
  }

  function cos_pc_check(e) {
    materail_select_temp[e.target.name].cos_pc = e.target.value;
  }

  function amount_txt_check(e) {
    materail_select_temp[e.target.name].amount_txt = e.target.value;
  }
  function onChange_value(key, e, a) {
    //console.log(key, e, a)
    /* if (key === "nw") {
      materail_select_temp[e.target.name].nw_number = e.target.value;
    } */
    if (key === "gw") {
      materail_select_temp[e.target.name].gw_number = e.target.value;
    }
    if (key === "d") {
      materail_select_temp[e.target.name].d_number = e.target.value;
    }
    if (key === "t") {
      materail_select_temp[e.target.name].t_number = e.target.value;
    }
    if (key === "w") {
      materail_select_temp[e.target.name].w_number = e.target.value;
    }
    if (key === "l") {
      materail_select_temp[e.target.name].l_number = e.target.value;
    }
    /*     if (key === "country") {
      materail_select_temp[e.target.name].country = e.target.value;
    } */
  }

  function save_matDefind_unit() {
    materail_select_temp[matDefine_unit.index].unit_txt = matDefine_unit.unit;

    settest({ ...test, test: "" });
    test.test = "";
  }
  const [textChange, settextChange] = useState({
    value: "",
  });
  function ChangeUnitMatDefine(e) {
    settextChange({ ...textChange, value: e.target.value });
    textChange.value = e.target.value;
    materail_select_temp[e.target.name].unit_txt = textChange.value;
    settest({ ...test, test: "" });
    test.test = "";
  }
  const [textChange1, settextChange1] = useState({
    value: "",
  });
  function ChangeUnitMatDefine1(e) {
    settextChange1({ ...textChange1, value: e.target.value });
    textChange1.value = e.target.value;
    materail_select_temp[e.target.name].country = textChange1.value;
    settest({ ...test, test: "" });
    test.test = "";
  }

  //var arrynw_name = orgname.filter((e)=>{return e.ofindex })
  //console.log("array",orgname);
  const rowsData_value_select = [];
  for (var index = 0; index < materail_select_temp.length; index++) {
    const rowItem = {};
    /*  let nw_number = ""; */
    let d_number = "";
    let t_number = "";
    let w_number = "";
    let l_number = "";
    //let country = "";
    /* nw_number = materail_select_temp[index].nw_number; */
    d_number = materail_select_temp[index].d_number;
    t_number = materail_select_temp[index].t_number;
    w_number = materail_select_temp[index].w_number;
    l_number = materail_select_temp[index].l_number;
    //country = materail_select_temp[index].country;
    rowItem["no"] = index + 1;
    rowItem["mat_code"] = materail_select_temp[index].no;
    rowItem["name"] = materail_select_temp[index].name;

    /* rowItem["nw_number"] = (
        
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required
        //value={materail_select_temp[index].nw_number || ""}
        
       value={nw_number}
        id={materail_select_temp[index].material_id}
        name={index}
        //onChange={(e)=>{onChange_value(index,e.target.value,"key");}}
        onChange={onChange_value.bind(this, "nw")}
      />
    ); */
    rowItem["g/w"] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required
        value={materail_select_temp[index].gw_number || ""}
        id={materail_select_temp[index].material_id}
        name={index}
        //onChange={(e)=>{onChange_value(index,e.target.value,"key");}}
        onChange={onChange_value.bind(this, "gw")}
      />
    );
    rowItem["d."] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required
        value={d_number}
        id={materail_select_temp[index].material_id}
        name={index}
        //onChange={(e)=>{onChange_value(index,e.target.value,"key");}}
        onChange={onChange_value.bind(this, "d")}
      />
    );
    rowItem["t."] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required
        value={t_number}
        id={materail_select_temp[index].material_id}
        name={index}
        // onChange={(e)=>{onChange_value(index,e.target.value,"key");}}
        onChange={onChange_value.bind(this, "t")}
      />
    );
    rowItem["w."] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required
        value={w_number}
        id={materail_select_temp[index].material_id}
        name={index}
        // onChange={(e)=>{onChange_value(index,e.target.value,"key");}}
        onChange={onChange_value.bind(this, "w")}
      />
    );
    rowItem["l."] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required
        value={l_number}
        id={materail_select_temp[index].material_id}
        name={index}
        //onChange={onCheck_l.bind(this)}
        onChange={onChange_value.bind(this, "l")}
      />
    );
    /*  var country_text = materail_select_temp[index].country || "";
    rowItem["country"] = (
      <input
        type="text"
        className="form-control"
        decimalScale={0}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required = "false"
        value={country_text}
        //id={materail_select_temp[index].material_id}
        name={index}
        //onChange={onCheck_l.bind(this)}
        onChange={ChangeUnitMatDefine1.bind(this)}
      />
    ); */
    for (let indexin = 0; indexin < Mat_spect.length; indexin++) {
      if (
        materail_select_temp[index].material_spec_id === Mat_spect[indexin].id
      ) {
        rowItem["spec"] = Mat_spect[indexin].name;
      }
    }
    for (let indexin = 0; indexin < Mat_size.length; indexin++) {
      if (
        materail_select_temp[index].material_size_id === Mat_size[indexin].id
      ) {
        rowItem["mat_size"] = Mat_size[indexin].name;
      }
    }
    for (let indexin = 0; indexin < Mat_type.length; indexin++) {
      if (
        materail_select_temp[index].material_type_id === Mat_type[indexin].id
      ) {
        rowItem["mat_type"] = Mat_type[indexin].name;
      }
    }

    rowItem["wt_mtr"] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        required
        value={materail_select_temp[index].wt_mtr || ""}
        id={materail_select_temp[index].material_id}
        name={index}
        onChange={wt_mtr_check.bind(this)}
      />
    );
    rowItem["c_leng"] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        value={materail_select_temp[index].c_leng || ""}
        id={materail_select_temp[index].material_id}
        name={index}
        onChange={c_leng_check.bind(this)}
        required
      />
    );
    rowItem["cos/PC"] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        value={materail_select_temp[index].cos_pc || ""}
        id={materail_select_temp[index].material_id}
        name={index}
        onChange={cos_pc_check.bind(this)}
        required
      />
    );
    var amount_txt = materail_select_temp[index].amount_txt || "";
    var unit_txt = materail_select_temp[index].unit_txt || "";
    rowItem["amount"] = (
      <NumberFormat
        type="text"
        className="form-control"
        decimalScale={2}
        allowNegative={false}
        disabled={mode.mode === "read" ? true : false}
        value={amount_txt}
        id={materail_select_temp[index].material_id}
        name={index}
        onChange={amount_txt_check.bind(this)}
        required
      />
    );
    rowItem["unit"] = (
      <input
        disabled={mode.mode === "read" ? true : false}
        type="text"
        className="form-control"
        required="false"
        value={unit_txt}
        id={index}
        name={index}
        onChange={ChangeUnitMatDefine.bind(this)}
      />
    );

    rowsData_value_select.push(rowItem);
  }

  const data_value_select = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material No",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "N/W.(g.)",
        field: "nw_number",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "G/W.(g.)",
        field: "g/w",
        sort: "asc",
        width: 50,
      },
      /* {
        label: "country",
        field: "country",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "d.",
        field: "d.",
        sort: "asc",
        width: 50,
      },
      {
        label: "t.",
        field: "t.",
        sort: "asc",
        width: 50,
      },
      {
        label: "W.",
        field: "w.",
        sort: "asc",
        width: 50,
      },
      {
        label: "L.",
        field: "l.",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mat_Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "Mat_Size",
        field: "mat_size",
        sort: "asc",
        width: 50,
      },
      {
        label: "wt_mtr",
        field: "wt_mtr",
        sort: "asc",
        width: 50,
      },
      {
        label: "c_leng",
        field: "c_leng",
        sort: "asc",
        width: 50,
      },
      {
        label: "cos/PC",
        field: "cos/PC",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Unit type",
        field: "unit",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_value_select,
  };

  var MatdefineSHIMIZU_feature = feature.filter((e) => {
    return e.feature === "Matdefine_SHIMIZU";
  });
  var CtsAllUser_feature = feature.filter((e) => {
    return e.feature === "CtsAllUser";
  });

  var MatdefineSST_feature = feature.filter((e) => {
    return e.feature === "Matdefine_SST";
  });
  var MatSize_feature = feature.filter((e) => {
    return e.feature === "Mat_Size";
  });
  var MatSpec_feature = feature.filter((e) => {
    return e.feature === "Mat_Spec";
  });
  var MatType_feature = feature.filter((e) => {
    return e.feature === "Mat_Type";
  });
  var Customer_CTS = feature.filter((e) => {
    return e.feature === "RongShup";
  });
  var Product_Customer_CTS = feature.filter((e) => {
    return e.feature === "Product Customer CTS";
  });
  if (MatSize_feature.length === 0) {
    var index = data_value_select.columns.findIndex((e) => {
      return e.field === "mat_size";
    });
    data_value_select.columns.splice(index, 1);
  }

  if (MatSpec_feature.length === 0) {
    var index = data_value_select.columns.findIndex((e) => {
      return e.field === "spec";
    });
    data_value_select.columns.splice(index, 1);
  }

  if (MatType_feature.length === 0) {
    var index = data_value_select.columns.findIndex((e) => {
      return e.field === "mat_type";
    });
    data_value_select.columns.splice(index, 1);
  }

  if (MatdefineSST_feature.length === 0) {
    var index = data_value_select.columns.findIndex((e) => {
      return e.field === "amount";
    });
    data_value_select.columns.splice(index, 1);
    var index2 = data_value_select.columns.findIndex((e) => {
      return e.field === "unit";
    });
    data_value_select.columns.splice(index2, 1);
  }

  if (CtsAllUser_feature.length === 0) {
    /*  var index = data_value_select.columns.findIndex((e) => {
      return e.field === "nw_number";
    })
    data_value_select.columns.splice(index, 1); */
    var index1 = data_value_select.columns.findIndex((e) => {
      return e.field === "g/w";
    });
    data_value_select.columns.splice(index1, 1);
    var index2 = data_value_select.columns.findIndex((e) => {
      return e.field === "d.";
    });
    data_value_select.columns.splice(index2, 1);
    var index3 = data_value_select.columns.findIndex((e) => {
      return e.field === "t.";
    });
    data_value_select.columns.splice(index3, 1);
    var index4 = data_value_select.columns.findIndex((e) => {
      return e.field === "w.";
    });
    data_value_select.columns.splice(index4, 1);
    var index5 = data_value_select.columns.findIndex((e) => {
      return e.field === "l.";
    });
    data_value_select.columns.splice(index5, 1);

    /*  var index5 = data_value_select.columns.findIndex((e) => {
      return e.field === "country";
    })
    data_value_select.columns.splice(index5, 1); */
  }

  if (MatdefineSHIMIZU_feature.length === 0) {
    var index = data_value_select.columns.findIndex((e) => {
      return e.field === "wt_mtr";
    });
    data_value_select.columns.splice(index, 1);
    var index2 = data_value_select.columns.findIndex((e) => {
      return e.field === "c_leng";
    });
    data_value_select.columns.splice(index2, 1);
    var index3 = data_value_select.columns.findIndex((e) => {
      return e.field === "cos/PC";
    });
    data_value_select.columns.splice(index3, 1);
  }

  const rowsData = [];
  if (mode.mode == "add") {
    for (var index = 0; index < materail.length; index++) {
      const rowItem = {};
      rowItem["no"] = index + 1;
      rowItem["mat_code"] = materail[index].no;

      for (let indexin = 0; indexin < Mat_spect.length; indexin++) {
        if (materail[index].material_spec_id === Mat_spect[indexin].id) {
          rowItem["spec"] = Mat_spect[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_size.length; indexin++) {
        if (materail[index].material_size_id === Mat_size[indexin].id) {
          rowItem["mat_size"] = Mat_size[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_type.length; indexin++) {
        if (materail[index].material_type_id === Mat_type[indexin].id) {
          rowItem["mat_type"] = Mat_type[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_supplier.length; indexin++) {
        if (materail[index].material_supplier_id === Mat_supplier[indexin].id) {
          rowItem["mat_pupp"] = Mat_supplier[indexin].name;
        }
      }
      rowItem["name"] = materail[index].name;
      rowItem["buy_price"] = materail[index].buy_price;
      rowItem["amount"] = materail[index].amount;
      rowItem["mat_unit"] = materail[index].material_unit;
      rowItem["description"] = materail[index].description;
      rowItem["remark"] = materail[index].remark;

      var indexof = materail_select_temp.filter((element) => {
        return element.material_id === materail[index].id;
      });
      var select = false;
      if (indexof.length > 0) {
        select = true;
      }

      rowItem["mgt"] = (
        <div className="row">
          <div className="col-6"></div>
          <div className="col-4">
            <input
              className="form-check-input"
              type="checkbox"
              id={index}
              name={materail[index].id}
              value={materail[index].id}
              defaultChecked={select}
              onChange={materail_select_item.bind(this)}
            />
          </div>
        </div>
      );
      rowsData.push(rowItem);
    }
  } else {
    // console.log(materail)
    for (var index = 0; index < materail.length; index++) {
      const rowItem = {};
      rowItem["no"] = index + 1;
      rowItem["mat_code"] = materail[index].no;

      for (let indexin = 0; indexin < Mat_spect.length; indexin++) {
        if (materail[index].material_spec_id === Mat_spect[indexin].id) {
          rowItem["spec"] = Mat_spect[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_size.length; indexin++) {
        if (materail[index].material_size_id === Mat_size[indexin].id) {
          rowItem["mat_size"] = Mat_size[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_type.length; indexin++) {
        if (materail[index].material_type_id === Mat_type[indexin].id) {
          rowItem["mat_type"] = Mat_type[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_supplier.length; indexin++) {
        if (materail[index].material_supplier_id === Mat_supplier[indexin].id) {
          rowItem["mat_pupp"] = Mat_supplier[indexin].name;
        }
      }
      rowItem["name"] = materail[index].name;
      rowItem["buy_price"] = materail[index].buy_price;
      rowItem["amount"] = materail[index].amount;
      rowItem["mat_unit"] = materail[index].material_unit;
      rowItem["description"] = materail[index].description;
      rowItem["remark"] = materail[index].remark;

      rowItem["mgt"] = (
        <div className="row">
          <div className="col-6"></div>
          <div className="col-4">
            <input
              className="form-check-input"
              type="checkbox"
              id={materail[index].id}
              name={materail[index].id}
              value={materail[index].id}
              defaultChecked={materail[index].isSelect}
              onClick={materail_select_item.bind(this)}
            />
          </div>
        </div>
      );

      rowsData.push(rowItem);
    }
  }

  const data = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material No",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Size",
        field: "mat_size",
        sort: "asc",
        width: 50,
      },
      {
        label: "Buy Price",
        field: "buy_price",
        sort: "asc",
        width: 50,
      },

      {
        label: "Material Unit",
        field: "mat_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "mat_pupp",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  if (MatSize_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "mat_size";
    });
    data.columns.splice(index, 1);
  }

  if (MatSpec_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "spec";
    });
    data.columns.splice(index, 1);
  }

  if (MatType_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "mat_type";
    });
    data.columns.splice(index, 1);
  }
  /* ----------------------------------------------------- */
  const rowsDataMatLaze = [];
  if (mode.mode == "add") {
    for (var index = 0; index < materailLazeload.length; index++) {
      const rowItem = {};
      rowItem["no"] = index + 1;
      rowItem["mat_code"] = materailLazeload[index].no;

      for (let indexin = 0; indexin < Mat_spect.length; indexin++) {
        if (materailLazeload[index].material_spec_id === Mat_spect[indexin].id) {
          rowItem["spec"] = Mat_spect[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_size.length; indexin++) {
        if (materailLazeload[index].material_size_id === Mat_size[indexin].id) {
          rowItem["mat_size"] = Mat_size[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_type.length; indexin++) {
        if (materailLazeload[index].material_type_id === Mat_type[indexin].id) {
          rowItem["mat_type"] = Mat_type[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_supplier.length; indexin++) {
        if (materailLazeload[index].material_supplier_id === Mat_supplier[indexin].id) {
          rowItem["mat_pupp"] = Mat_supplier[indexin].name;
        }
      }
      rowItem["name"] = materailLazeload[index].name;
      rowItem["buy_price"] = materailLazeload[index].buy_price;
      rowItem["amount"] = materailLazeload[index].amount;
      rowItem["mat_unit"] = materailLazeload[index].material_unit;
      rowItem["description"] = materailLazeload[index].description;
      rowItem["remark"] = materailLazeload[index].remark;

      var indexof = materail_select_temp.filter((element) => {
        return element.material_id === materailLazeload[index].id ? true : false;
      });
      let checkIndex = materail_select_temp.filter((s) => {return s.material_id === materailLazeload[index].id})
      if(checkIndex.length > 0){
        console.log('> 0',index);
      }
      var select = false;
      if (indexof.length > 0) {
        select = true;
      }else{
        select = false;
      }
      console.log('ดูค่า',materailLazeload[index].isSelect);
      rowItem["mgt"] = (
        <div className="row">
          <div className="col-6"></div>
          <div className="col-4">
            <input
              className="form-check-input"
              type="checkbox"
              id={index}
              name={materailLazeload[index].id}
              value={materailLazeload[index].id}
           /*    defaultChecked={materailLazeload[index].isSelect ?? false} */
              checked = {select}
              onChange={materail_select_item.bind(this)}
            />
          </div>
        </div>
      );
      rowsDataMatLaze.push(rowItem);
    }
  } else {
    // console.log(materail)
    for (var index = 0; index < materailLazeload.length; index++) {
      const rowItem = {};
      /*     rowItem["no"] = index + 1; */
      rowItem["mat_code"] = materailLazeload[index].no;

      for (let indexin = 0; indexin < Mat_spect.length; indexin++) {
        if (materailLazeload[index].material_spec_id === Mat_spect[indexin].id) {
          rowItem["spec"] = Mat_spect[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_size.length; indexin++) {
        if (materailLazeload[index].material_size_id === Mat_size[indexin].id) {
          rowItem["mat_size"] = Mat_size[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_type.length; indexin++) {
        if (materailLazeload[index].material_type_id === Mat_type[indexin].id) {
          rowItem["mat_type"] = Mat_type[indexin].name;
        }
      }
      for (let indexin = 0; indexin < Mat_supplier.length; indexin++) {
        if (materailLazeload[index].material_supplier_id === Mat_supplier[indexin].id) {
          rowItem["mat_pupp"] = Mat_supplier[indexin].name;
        }
      }
      rowItem["name"] = materailLazeload[index].name;
      rowItem["buy_price"] = materailLazeload[index].buy_price;
      rowItem["amount"] = materailLazeload[index].amount;
      rowItem["mat_unit"] = materailLazeload[index].material_unit;
      rowItem["description"] = materailLazeload[index].description;
      rowItem["remark"] = materailLazeload[index].remark;

      rowItem["mgt"] = (
        <div className="row">
          <div className="col-6"></div>
          <div className="col-4">
            <input
              className="form-check-input"
              type="checkbox"
              id={materailLazeload[index].id}
              name={materailLazeload[index].id}
              value={materailLazeload[index].id}
              defaultChecked={materailLazeload[index].isSelect}
              onClick={materail_select_item.bind(this)}
            />
          </div>
        </div>
      );

      rowsDataMatLaze.push(rowItem);
    }
  }
/* console.log('rowsDataMatLaze',rowsDataMatLaze,mode.mode,); */
  const dataMatLaze = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      /*    {
           label: "No",
           field: "no",
           sort: "asc",
           width: 50,
         }, */
      {
        label: "Material No",
        field: "mat_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Name",
        field: "name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Type",
        field: "mat_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "Spec",
        field: "spec",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material Size",
        field: "mat_size",
        sort: "asc",
        width: 50,
      },
      {
        label: "Buy Price",
        field: "buy_price",
        sort: "asc",
        width: 50,
      },

      {
        label: "Material Unit",
        field: "mat_unit",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "mat_pupp",
        sort: "asc",
        width: 50,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsDataMatLaze,
  };

  if (MatSize_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "mat_size";
    });
    data.columns.splice(index, 1);
  }

  if (MatSpec_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "spec";
    });
    data.columns.splice(index, 1);
  }

  if (MatType_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "mat_type";
    });
    data.columns.splice(index, 1);
  }
  /* ------------------------------------------------------------------ */
  var display_sub_assy;
  var display_normal;
  var status_assy;

  if (type_select.status === "normal") {
    status_assy = false;
  }
  if (type_select.status === "sub_assy") {
    display_sub_assy = "block";
    display_normal = "none";
    status_assy = true;
  } else {
    display_sub_assy = "none";
    display_normal = "block";
    status_assy = false;
  }

  const product_select_item = async (el) => {
    const tempData = product_all;
    const tempSelect = product_select_temp;
    /*  console.log(el.target.name); */

    var id_del = null;
    var index = tempData.findIndex((element) => element.id === el.target.name);
    for (var t = 0; t < tempData.length; t++) {
      if (tempData[t].id === el.target.name) {
        if (tempData[t].isSelect) {
          tempData[t].isSelect = false;

          id_del = tempData[t].id;
        } else {
          tempData[t].isSelect = true;
        }
      }
    }

    await setproduct_all(tempData);

    let product_select_temp1 = product_all.filter((element) => {
      return element.isSelect === true;
    });
    console.log(product_select_temp1);
    var tempIn_product = [];

    for (var index = 0; index < product_select_temp1.length; index++) {
      if (product_select_temp1[index].min_product != "") {
        const temp_product = {
          id: product_select_temp1[index].id_pp || null,
          product_id: product.id || null,
          product_sub: product_select_temp1[index].id,
          no: product_select_temp1[index].no,
          name: product_select_temp1[index].name,

          amount: product_select_temp1[index].amount,
        };
        tempIn_product.push(temp_product);
      } else {
        const temp_product = {
          id: product_select_temp1[index].id_pp || null,
          product_id: product.id || null,
          product_sub: product_select_temp1[index].id,
          no: product_select_temp1[index].no,
          name: product_select_temp1[index].name,
          amount: "",
        };

        tempIn_product.push(temp_product);
      }
    }

    for (var t = 0; t < tempSelect.length; t++) {
      var add_test = tempIn_product.filter((e) => {
        return e.product_sub === tempSelect[t].product_sub;
      });
      if (add_test.length > 0) {
      } else {
        const temp_product = {
          product_id: product.id || null,
          product_sub: tempSelect[t].product_sub,
          no: tempSelect[t].no,
          name: tempSelect[t].name,

          amount: "",
        };
        tempIn_product.push(temp_product);
      }
    }
    var indexDel = tempIn_product.findIndex(
      (element) => element.product_sub === id_del
    );

    if (indexDel >= 0) {
      tempIn_product.splice(indexDel, 1);
    }

    await setproduct_select_temp(tempIn_product);
  };

  const rowsData_product = [];
  for (var index = 0; index < product_all.length; index++) {
    const rowpro_duct = {};
    rowpro_duct["no"] = index + 1;
    rowpro_duct["part_no"] = product_all[index].no;

    rowpro_duct["part_name"] = product_all[index].name;

    rowpro_duct["mgt"] = (
      <div className="row">
        <div className="col-6"></div>
        <div className="col-4">
          <input
            className="form-check-input"
            type="checkbox"
            id={index}
            name={product_all[index].id}
            value={product_all[index].id}
            defaultChecked={product_all[index].isSelect}
            onClick={product_select_item.bind(this)}
          />
        </div>
      </div>
    );
    rowsData_product.push(rowpro_duct);
  }

  const data_product = {
    columns: [
      {
        label: "++++",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part Name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_product,
  };

  function amount_check(e) {
    product_select_temp[e.target.name].amount = e.target.value;
  }

  function test_product() {
    for (var i = 0; i < product_select_temp.length; i++) { }
  }

  const rowsData_product_select = [];
  for (var index = 0; index < product_select_temp.length; index++) {
    const rowpro_duct = {};
    rowpro_duct["no"] = index + 1;
    rowpro_duct["part_no"] = product_select_temp[index].no;

    rowpro_duct["part_name"] = product_select_temp[index].name;
    rowpro_duct["amount"] = (
      <NumberFormat
        type="text"
        className="form-control"
        allowNegative={false}
        decimalScale={false}
        disabled={mode.mode === "read" ? true : false}
        required
        value={product_select_temp[index].amount || ""}
        id={product_select_temp[index].product_sub}
        name={index}
        onChange={amount_check.bind(this)}
      />
    );

    rowsData_product_select.push(rowpro_duct);
  }

  const data_product_select = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part No",
        field: "part_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Part Name",
        field: "part_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData_product_select,
  };

  async function Add_customer() {
    if (customer_product_mode.mode === "add") {
      let indexOf = customer_product_data.length;
      let temp = {
        id: customer_product_data_temp.id,
        customer_item: customer_product_data_temp.customer_item,
        customer_item_name: customer_product_data_temp.customer_item_name,
        index: indexOf,
        plan_item: product.no,
        remark: "",
        last_update: moment(new Date()),
        is_use: true,
      };
      customer_product_data.push(temp);
    } else {
      const tempdata_customer_list = [];

      for (let index = 0; index < customer_product_data.length; index++) {
        if (
          customer_product_data[index].index ===
          customer_product_data_temp.index &&
          customer_product_data[index].is_use === true
        ) {
          customer_product_data[index].customer_item =
            customer_product_data_temp.customer_item;
          customer_product_data[index].customer_item_name =
            customer_product_data_temp.customer_item_name;
        }

        tempdata_customer_list.push(customer_product_data[index]);
      }

      setcustomer_product_data(tempdata_customer_list);
    }

    setcustomer_product_data_temp({
      id: "",
      customer_item: "",
      customer_item_name: "",
      index: "",
      plan_item: product.no,
      remark: "",
      last_update: "",
      is_use: true,
    });
  }

  async function add_customer() {
    setcustomer_product_data_temp({
      ...customer_product_data_temp,
      id: "",
      customer_item: "",
      customer_item_name: "",
      index: "",
      plan_item: product.no,
      remark: "",
      last_update: "",
      is_use: true,
    });
    setcustomer_product_mode({ ...customer_product_mode, mode: "add" });
  }

  async function del_customer_list(data) {
    const tempdata_customer_list = [];
    if (data != "" && data != null && data != undefined) {
      for (let index = 0; index < customer_product_data.length; index++) {
        if (
          customer_product_data[index].customer_item === data &&
          customer_product_data[index].is_use === true
        ) {
          customer_product_data[index].is_use = false;
        }
        tempdata_customer_list.push(customer_product_data[index]);
      }
    }
    setcustomer_product_data(tempdata_customer_list);
  }

  async function form_customer(
    customer_item,
    customer_item_name,
    id,
    status,
    index
  ) {
    setcustomer_product_mode({
      mode: status,
    });

    setcustomer_product_data_temp({
      ...customer_product_data_temp,
      id: id,
      customer_item: customer_item,
      customer_item_name: customer_item_name,
      index: index,
    });
  }

  const GetProcess_Cycle = async () => {
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryParameter/process/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filter_process,
    })
      .then(function (response) {
        setprocess_cycle(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.process_name;
          options.push(element);
        });
        setprocess_cycle_option(options);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const select_process_cycle = useRef();

  function form_process_cycle(process, cycle_time, mode, index) {
    let id = process_cycle.filter((e) => {
      return e.process_name === process;
    });
    setprocess_cycle_select({
      ...process_cycle_select,
      id: id[0].id,
      process: process,
      cycle_time: cycle_time,
      mode: mode,
      index: index,
    });
  }
  var rowsProcess_cycle_time = [];

  for (var index = 0; index < process_select_data.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    let process_name = process_cycle.filter((e) => {
      return e.id === process_select_data[index].process_id;
    });
    name = "";
    if (process_name.length > 0) {
      var name = process_name[0].process_name;
    }

    rowItem["process"] = name;
    rowItem["cycle_time"] = process_select_data[index].cycle_time;
    var max = "";
    var min = "";

    if (index === 0) {
      min = "disabled";
    }
    if (index === process_select_data.length - 1) {
      max = "disabled";
    }

    if (mode.mode === "read") {
      var max = "disabled";
      var min = "disabled";
    }
    rowItem["mgt"] = (
      <>
        <div className="row">
          <div className="col-2">
            <a
              className={"btn btn-block mtg-btn btn-xs " + min}
              onClick={Sort_process_cycle_time.bind(this, "up", index)}
            >
              <i aria-hidden="true" className="fa fa-arrow-up mr-2 "></i>
            </a>
          </div>
          <div className="col-2">
            <a
              className={"btn btn-block mtg-btn btn-xs " + max}
              onClick={Sort_process_cycle_time.bind(this, "down", index)}
            >
              <i aria-hidden="true" className="fa fa-arrow-down mr-2 "></i>
            </a>
          </div>
          {mode.mode === "read" ? (
            <></>
          ) : (
            <>
              <div className="col-2">
                <a
                  data-toggle="modal"
                  data-target="#modal-add_process_cycle_time"
                  onClick={form_process_cycle.bind(
                    this,
                    name,
                    process_select_data[index].cycle_time,
                    "edit",
                    index
                  )}
                >
                  <i class="fas fa-pencil-alt"></i>
                </a>
              </div>
              <div className="col-2">
                <i
                  id={index}
                  onClick={Remove_process_cycle_time.bind(this)}
                  className="fa fa-trash  icon-sm"
                ></i>
              </div>
            </>
          )}
        </div>
      </>
    );

    rowsProcess_cycle_time.push(rowItem);
  }
  const dataProcess_cycle_time = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Process",
        field: "process",
        sort: "asc",
        width: 50,
      },
      {
        label: "Cycle Time",
        field: "cycle_time",
        sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsProcess_cycle_time,
  };

  function Sort_process_cycle_time(type, index, e) {
    var tempData = process_select_data;
    setprocess_select_data([]);
    if (type === "up") {
      tempData[index - 1].sort = tempData[index - 1].sort + 1;
      tempData[index].sort = tempData[index].sort - 1;
    } else if (type === "down") {
      tempData[index + 1].sort = tempData[index + 1].sort - 1;
      tempData[index].sort = tempData[index].sort + 1;
    }
    tempData.sort((a, b) => a.sort - b.sort);
    setprocess_select_data(tempData);
    setprocess_cycle_select({
      ...process_cycle_select,
      id: "",
      cycle_time: "",
    });
    process_cycle_select.id = "";
    process_cycle_select.cycle_time = "";
    select_process_cycle.current.select.clearValue();
  }

  function Add_process_cycle_time() {
    var tempData = process_select_data;
    setprocess_select_data([]);
    let temp = {
      process_id: process_cycle_select.id,
      cycle_time: process_cycle_select.cycle_time,
      sort: tempData.length + 1,
    };
    tempData.push(temp);
    tempData.sort((a, b) => a.sort - b.sort);
    setprocess_select_data(tempData);
    setprocess_cycle_select({
      ...process_cycle_select,
      id: "",
      cycle_time: "",
    });
    process_cycle_select.id = "";
    process_cycle_select.cycle_time = "";
    select_process_cycle.current.select.clearValue();
  }

  function Edit_process_cycle_time() {
    var tempData = process_select_data;
    setprocess_select_data([]);

    tempData[process_cycle_select.index].process_id = process_cycle_select.id;
    tempData[process_cycle_select.index].cycle_time =
      process_cycle_select.cycle_time;
    setprocess_select_data(tempData);
    setprocess_cycle_select({
      ...process_cycle_select,
      id: "",
      process: "",
      cycle_time: "",
      mode: "",
      index: "",
    });
    process_cycle_select.id = "";
    process_cycle_select.cycle_time = "";
    select_process_cycle.current.select.clearValue();
  }

  function Remove_process_cycle_time(e) {
    var tempData = process_select_data;
    setprocess_select_data([]);
    tempData.splice(e.target.id, 1);
    setprocess_select_data(tempData);
    setprocess_cycle_select({
      ...process_cycle_select,
      id: "",
      process: "",
      cycle_time: "",
      mode: "",
      index: "",
    });
    process_cycle_select.id = "";
    process_cycle_select.cycle_time = "";
    select_process_cycle.current.select.clearValue();
  }

  const select_supplier = useRef();

  var rowsSupplier = [];

  for (var index = 0; index < supplier_select_data.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    let sup_name = supplier.filter((e) => {
      return e.id === supplier_select_data[index].supplier_id;
    });

    var name = "";
    if (sup_name.length > 0) {
      name = sup_name[0].name;
    }

    rowItem["supplier"] = name;
    rowItem["buy_price"] = supplier_select_data[index].buy_price;
    rowItem["minimum_order"] = supplier_select_data[index].minimum_order;
    rowItem["lead_time"] = supplier_select_data[index].lead_time;
    rowItem["remark"] = supplier_select_data[index].remark;
    var max = "";
    var min = "";

    if (index === 0) {
      min = "disabled";
    }
    if (index === supplier_select_data.length - 1) {
      max = "disabled";
    }
    var is_active = false;
    if (supplier_select_data[index].is_active === true) {
      is_active = true;
    }
    if (mode.mode === "read") {
      var max = "disabled";
      var min = "disabled";
    }

    var date = moment(supplier_select_data[index].create_date).format(
      "DD/MM/yyyy :: HH:mm น."
    );

    rowItem["create_date"] = date;
    rowItem["mgt"] = (
      <>
        {is_active === true ? (
          <>
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  disabled={is_active}
                  onClick={setSupplier_active.bind(
                    this,
                    supplier_select_data[index].supplier_id,
                    name,
                    supplier_select_data[index].buy_price,
                    supplier_select_data[index].minimum_order,
                    supplier_select_data[index].lead_time,
                    supplier_select_data[index].remark,
                    index
                  )}
                  class="btn btn-block btn-success "
                >
                  ACTIVE
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  disabled={is_active}
                  onClick={setSupplier_active.bind(
                    this,
                    supplier_select_data[index].supplier_id,
                    name,
                    supplier_select_data[index].buy_price,
                    supplier_select_data[index].minimum_order,
                    supplier_select_data[index].lead_time,
                    supplier_select_data[index].remark,
                    index
                  )}
                  class="btn btn-block btn-primary "
                >
                  ACTIVE
                </button>
              </div>
            </div>
          </>
        )}
        <hr />

        {mode.mode === "read" ? (
          <></>
        ) : (
          <>
            {is_active === false ? (
              <>
                <div className="row">
                  <div className="col-6">
                    <a
                      data-toggle="modal"
                      data-target="#modal-add_supplier_rerationship"
                      onClick={form_supplier.bind(
                        this,
                        name,
                        supplier_select_data[index].buy_price,
                        supplier_select_data[index].minimum_order || 0,
                        supplier_select_data[index].lead_time || 0,
                        supplier_select_data[index].remark || "",
                        "edit",
                        index
                      )}
                    >
                      <i class="fas fa-pencil-alt"></i>
                    </a>
                  </div>
                  <div className="col-6">
                    <i
                      id={index}
                      onClick={Remove_Supplier.bind(this)}
                      className="fa fa-trash  icon-sm"
                    ></i>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );

    rowsSupplier.push(rowItem);
  }
  const dataSupplier = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Supplier",
        field: "supplier",
        sort: "asc",
        width: 50,
      },
      {
        label: "Buy Price",
        field: "buy_price",
        sort: "asc",
        width: 50,
      },
      {
        label: "Minimum Order",
        field: "minimum_order",
        sort: "asc",
        width: 50,
      },
      {
        label: "Lead Time (Days)",
        field: "lead_time",
        sort: "asc",
        width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        sort: "asc",
        width: 50,
      },
      {
        label: "Date",
        field: "create_date",
        sort: "asc",
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsSupplier,
  };
  function Add_Suppier() {
    var tempData = supplier_select_data;
    setSupplier_select_data([]);
    let temp = {
      supplier_id: supplier_select.id,
      buy_price: supplier_select.buy_price || 0,
      minimum_order: supplier_select.minimum_order || 0,
      lead_time: supplier_select.lead_time || 0,
      remark: supplier_select.remark || "",
      sort: tempData.length + 1,
      is_active: false,
      create_date: moment(new Date()).format(),
    };
    tempData.push(temp);
    tempData.sort((a, b) => b.sort - a.sort);
    tempData.sort((a, b) => b.is_active - a.is_active);
    setSupplier_select_data(tempData);
    setsupplier_select({
      ...supplier_select,
      id: "",
      buy_price: "",
      minimum_order: "",
      lead_time: "",
      remark: "",
    });
    supplier_select.id = "";
    supplier_select.buy_price = "";
    supplier_select.minimum_order = "";
    supplier_select.lead_time = "";
    supplier_select.remark = "";

    select_supplier.current.select.clearValue();
    /*  console.log(tempData); */
  }

  function Edit_Suppier() {
    var tempData = supplier_select_data;
    setSupplier_select_data([]);

    //console.log(tempData[supplier_select.index]);
    tempData[supplier_select.index].supplier_id = supplier_select.id;
    tempData[supplier_select.index].buy_price = supplier_select.buy_price;
    tempData[supplier_select.index].lead_time = supplier_select.lead_time;
    tempData[supplier_select.index].minimum_order =
      supplier_select.minimum_order;
    tempData[supplier_select.index].remark = supplier_select.remark;
    tempData[supplier_select.index].create_date = moment(new Date());
    setSupplier_select_data(tempData);
    setsupplier_select({
      ...supplier_select,
      id: "",
      supplier: "",
      buy_price: "",
      minimum_order: "",
      lead_time: "",
      remark: "",
      mode: "",
      index: "",
    });
    supplier_select.id = "";
    supplier_select.buy_price = "";
    supplier_select.minimum_order = "";
    supplier_select.lead_time = "";
    supplier_select.remark = "";
    select_supplier.current.select.clearValue();
  }

  function form_supplier(
    supplier_name,
    buy_price,
    minimum_order,
    lead_time,
    remark,
    mode,
    index
  ) {
    let id = supplier.filter((e) => {
      return e.name === supplier_name;
    });
    setsupplier_select({
      ...supplier_select,
      id: id[0].id,
      supplier: supplier_name,
      buy_price: buy_price,
      minimum_order: minimum_order,
      lead_time: lead_time,
      remark: remark,
      mode: mode,
      index: index,
    });
  }

  function Remove_Supplier(e) {
    var tempData = supplier_select_data;
    setSupplier_select_data([]);
    tempData.splice(e.target.id, 1);
    setSupplier_select_data(tempData);
    setsupplier_select({
      ...supplier_select,
      id: "",
      supplier: "",
      buy_price: "",
      minimum_order: "",
      lead_time: "",
      remark: "",
      mode: "",
      index: "",
    });
    supplier_select.id = "";
    supplier_select.buy_price = "";
    supplier_select.minimum_order = "";
    supplier_select.lead_time = "";
    supplier_select.remark = "";
    select_supplier.current.select.clearValue();
  }

  function setSupplier_active(
    supplier_id,
    supplier_name,
    buy_price,
    minimum_order,
    lead_time,
    remark,
    index
  ) {
    var tempData = supplier_select_data;
    setSupplier_select_data([]);
    for (let i of tempData) {
      i.is_active = false;
    }
    tempData[index].is_active = true;
    tempData.sort((a, b) => b.sort - a.sort);
    tempData.sort((a, b) => b.is_active - a.is_active);
    setsupplier_active({
      ...supplier_active,
      id: supplier_id,
      supplier: supplier_name,
      buy_price: buy_price,
      minimum_order: minimum_order,
      lead_time: lead_time,
      remark: remark,
    });

    setSupplier_select_data(tempData);
  }

  var rowsMachine = [];

  for (var index = 0; index < machine_select_data.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    let machine_name = machine.filter((e) => {
      return e.id === machine_select_data[index].machine_id;
    });

    var name = "";
    if (machine_name.length > 0) {
      name = machine_name[0].machine_name;
    }
    rowItem["machine"] = name;

    rowItem["mgt"] = (
      <>
        <div className="col-12">
          <button
            type="button"
            disabled={mode.mode === "read" ? true : false}
            id={machine_select_data[index].id}
            onClick={Remove_Machine.bind(this)}
            class="btn btn-block btn-danger "
          >
            Remove
          </button>
        </div>
      </>
    );

    rowsMachine.push(rowItem);
  }

  const dataMachine = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Machine",
        field: "machine",
        sort: "asc",
        width: 50,
      },

      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsMachine,
  };
  const [test, settest] = useState({
    test: "",
  });
  function Add_Machine(e) {
    const machine_select_temp = machine_select_data;

    var data = machine.filter((element) => {
      return element.id === e.target.id;
    });
    const machine_p = machine;
    setmachine([]);
    for (let i of machine_p) {
      if (i.id === e.target.id) {
        i.isPush = true;
      }
    }
    setmachine(machine_p);

    var checked = machine_select_temp.filter((e2) => {
      return e2.machine_id === data[0].id;
    });

    if (checked.length > 0) {
    } else {
      data[0].machine_id = data[0].id;
      machine_select_temp.push(data[0]);
    }
    setMachine_select_data([]);
    setMachine_select_data(machine_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function Remove_Machine(e) {
    const machine_p = machine;
    setmachine([]);
    for (let i of machine_p) {
      if (i.id === e.target.id) {
        i.isPush = false;
      }
    }
    setmachine(machine_p);
    const machine_select_temp = machine_select_data;
    setMachine_select_data([]);
    let indexOf = machine_select_temp.findIndex(
      (element) => element.id === e.target.id
    );
    machine_select_temp.splice(indexOf, 1);
    setMachine_select_data(machine_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
  }

  const rows_Machine = [];
  for (var index = 0; index < machine.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["machine"] = machine[index].machine_name;
    rowItem["mgt"] = (
      <>
        {machine[index].isPush === true ? (
          <>
            <div className="col-12">
              <button
                type="button"
                id={machine[index].id}
                onClick={Remove_Machine.bind(this)}
                class="btn btn-block btn-danger "
              >
                Remove
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="col-12">
              <button
                type="button"
                id={machine[index].id}
                onClick={Add_Machine.bind(this)}
                class="btn btn-block btn-primary "
              >
                Push
              </button>
            </div>
          </>
        )}
      </>
    );
    rows_Machine.push(rowItem);
  }
  const data_Machine = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Machine",
        field: "machine",
        sort: "asc",
        width: 50,
      },

      {
        label: "MGT",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rows_Machine,
  };

  const [selectedOption, setSelectedOption] = useState();

  const [filler_customer_cts, setFiller_customer_cts] = useState({
    name: "",
    relate_product: "",
    company_id: getUser().com,
    companyGroup_id: getOem(),
  });

  const [customer, setCustomer] = useState([]);

  const GetCustomer = async (page, size, search, select_cus) => {
    if (search) {
      setSearchcustomer(1);
    } else {
      setSearchcustomer(0);
    }

    var filters = {
      name: filler_customer_cts.name,
      relate_product: filler_customer_cts.relate_product,
      company_id: getUser().com,
      companyGroup_id: getOem(),
      page: page || 1,
      size: size || 10
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/customer/filter_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filters,
    })
      .then(function (response) {
        const CustomerAll = [];
        setCustomer([]);

        setCountPagecustomer(response.data.countPage);
        setCountDatacustomer(response.data.count)
        setEntriescustomer(size);

        for (let item of response.data.data) {
          var check = [];


          check = select_cus?.filter((e) => {
            return e.cus_id === item.id;
          });


          if (check && check?.length > 0) {
            item.isPush = true;
          } else {
            item.isPush = false;
          }

          CustomerAll.push(item);
        }
        console.log(CustomerAll);
        setCustomer(CustomerAll);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  async function removeCus(e) {
    const customer_p = customer;
    setCustomer([]);
    for (let i of customer_p) {
      if (i.id === e.target.id) {
        i.isPush = false;
      }
    }
    setCustomer(customer_p);

    const cus_select_temp = customer_select;
    setCustomer_select([]);
    let indexOf = cus_select_temp.findIndex(
      (element) => element.cus_id === e.target.id
    );
    cus_select_temp.splice(indexOf, 1);
    setCustomer_select(cus_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
  }
  async function pushCus(e) {

    const cus_select_temp = customer_select;
    var data = customer.filter((element) => {
      return element.id === e.target.id;
    });

    const customer_p = customer;
    setCustomer([]);
    for (let i of customer_p) {
      if (i.id === e.target.id) {
        i.isPush = true;
      }
    }
    setCustomer(customer_p);
    // console.log(emp_select_temp);
    var checked = cus_select_temp.filter((e2) => {
      return e2.id === data[0].id;
    });
    // console.log(checked)
    if (checked.length > 0) {
      /* console.log("ซ้ำ"); */
    } else {
      data[0].cus_id = e.target.id;
      cus_select_temp.push(data[0]);

    }
    setCustomer_select([]);
    setCustomer_select(cus_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
    /*  console.log(customer_select); */
  }

  const Customer = [];

  for (var index = 0; index < customer.length; index++) {
    const rowItem = {};

    /*    rowItem["no"] = index + 1; */
    rowItem["customer_no"] = customer[index].name;
    rowItem["cuntomer_name"] = customer[index].full_name;
    rowItem["line"] = customer[index].line_id;

    rowItem["mgt"] = (
      <>
        <div className="row">
          {customer[index].isPush === true ? (
            <>
              <div className="col-12">
                <button
                  type="button"
                  disabled={mode.mode === "read" ? true : false}
                  id={customer[index].id}
                  onClick={removeCus.bind(this)}
                  class="btn btn-block btn-danger "
                >
                  Remove
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <button
                  type="button"
                  name="เพิ่ม"
                  disabled={mode.mode === "read" ? true : false}
                  id={customer[index].id}
                  onClick={pushCus.bind(this)}
                  class="btn btn-block btn-primary "
                >
                  Push
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );
    Customer.push(rowItem);
  }

  const TableCustomer = {
    columns: [
      /*  {
         label: "No",
         field: "no",
         sort: "asc",
         width: 50,
       }, */
      {
        label: "Customer No",
        field: "customer_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer Name",
        field: "cuntomer_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line",
        field: "line",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Customer,
  };

  const Customer_cts_select = [];
  /* console.log(customer_select); */
  for (var index = 0; index < customer_select.length; index++) {
    const rowItem = {};

    rowItem["no"] = index + 1;
    rowItem["customer_no"] = customer_select[index].name;
    rowItem["cuntomer_name"] = customer_select[index].full_name;
    rowItem["line"] = (
      <a
        target="_blank"
        href={" https://line.me/ti/p/~" + customer_select[index].line_id}
      >
        {customer_select[index].line_id}
      </a>
    );


    rowItem["mgt"] = (
      <>
        <div className="row">
          <div className="col-0 col-md-2 col-xl-3" />
          <div className="col-12 col-md-8 col-xl-6">
            <button
              type="button"
              id={customer_select[index].cus_id}
              onClick={removeCus.bind(this)}
              class="btn btn-block btn-danger "
            >
              Remove
            </button>
          </div></div>
      </>
    );
    Customer_cts_select.push(rowItem);
  }

  const TableCustomer_Select = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer No",
        field: "customer_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer Name",
        field: "cuntomer_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Line",
        field: "line",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: Customer_cts_select,
  };


  async function removeFac(e) {
    const facchoup_p = facchoup_data;
    setfacchoup_data([]);
    for (let i of facchoup_p) {
      if (i.id === e.target.id) {
        i.isPush = false;
      }
    }
    setfacchoup_data(facchoup_p);

    const fac_select_temp = facchoup_select;
    setfacchoup_select([]);
    let indexOf = fac_select_temp.findIndex(
      (element) => element.fac_id === e.target.id
    );
    fac_select_temp.splice(indexOf, 1);
    setfacchoup_select(fac_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
  }
  async function pushFac(e) {
    console.log(facchoup_data);
    const fac_select_temp = facchoup_select;
    var data = facchoup_data.filter((element) => {
      return element.id === e.target.id;
    });
    const facchoup_p = facchoup_data;
    setfacchoup_data([]);
    for (let i of facchoup_p) {
      if (i.id === e.target.id) {
        i.isPush = true;
      }
    }
    console.log(facchoup_p);
    setfacchoup_data(facchoup_p);

    /*  console.log(facchoup_select); */
    if (data.length > 0) {
      data[0].fac_id = e.target.id
      fac_select_temp.push(data[0]);
    }


    /*   var checked = fac_select_temp.filter((e2) => {
        return e2.id === data[0].id;
      });
  
      if (checked.length > 0) {
        console.log("ซ้ำ");
      } else {
        fac_select_temp.push(data[0]);
      } */
    setfacchoup_select([]);
    setfacchoup_select(fac_select_temp);
    settest({ ...test, test: "" });
    test.test = "";
    /* console.log(facchoup_select); */
  }







  const FacChoupData = [];
  for (var index = 0; index < facchoup_data.length; index++) {
    const rowItem = {};
    /*  rowItem["no"] = index + 1; */
    rowItem["fac_choup_no"] = facchoup_data[index].code;
    rowItem["fac_choup_name"] = facchoup_data[index].name;
    rowItem["fac_choup_tel"] = (<><a href={"tel:" + facchoup_data[index].telnumber}>{facchoup_data[index].telnumber}</a></>);;
    rowItem["mgt"] = (
      <>
        <div className="row">
          {facchoup_data[index].isPush === true ? (
            <>
              <div className="col-12">
                <button
                  type="button"
                  disabled={mode.mode === "read" ? true : false}
                  id={facchoup_data[index].id}
                  onClick={removeFac.bind(this)}
                  class="btn btn-block btn-danger "
                >
                  Remove
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="col-12">
                <button
                  type="button"
                  name="เพิ่ม"
                  disabled={mode.mode === "read" ? true : false}
                  id={facchoup_data[index].id}
                  onClick={pushFac.bind(this)}
                  class="btn btn-block btn-primary "
                >
                  Push
                </button>
              </div>
            </>
          )}
        </div>
      </>
    );

    FacChoupData.push(rowItem);
  }
  const FacChoupD = {
    columns: [
      /*  {
         label: "No",
         field: "no",
         sort: "asc",
         width: 50,
       }, */
      {
        label: "ชื่อย่อโรงงานชุบ",
        field: "fac_choup_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "โรงงานชุบ",
        field: "fac_choup_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ติดต่อ",
        field: "fac_choup_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },


    ],
    rows: FacChoupData,
  };

  const FacChoupselect = [];
  for (var index = 0; index < facchoup_select.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["fac_choup_no"] = facchoup_select[index].code;
    rowItem["fac_choup_name"] = facchoup_select[index].name;
    rowItem["fac_choup_tel"] = (<><a href={"tel:" + facchoup_select[index].telnumber}>{facchoup_select[index].telnumber}</a></>);;
    rowItem["mgt"] = (
      <>
        <div className="row">
          <div className="col-0 col-md-2 col-xl-3" />
          <div className="col-12 col-md-8 col-xl-6">
            <button
              type="button"
              id={facchoup_select[index].fac_id}
              onClick={removeFac.bind(this)}
              class="btn btn-block btn-danger "
            >
              Remove
            </button>
          </div></div>
      </>
    );

    FacChoupselect.push(rowItem);
  }

  const FacChoupS = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อย่อโรงงานชุบ",
        field: "fac_choup_no",
        sort: "asc",
        width: 50,
      },
      {
        label: "โรงงานชุบ",
        field: "fac_choup_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ติดต่อ",
        field: "fac_choup_tel",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },


    ],
    rows: FacChoupselect,
  };

  async function SaveTabUnitConvert(product_id) {
    if (!isUseTabUnitConvert.data) {
      return;
    }

    const data = {
      product_id,
      unit_id: product.unit_id,
      unit_width: product.unit_width,
      unit_depth: product.unit_depth,
      unit_height: product.unit_height,
      unit_to_list: unitConvertToList.data,
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      pu_id: mode.mode === "edit" ? product.pu_id : undefined
    }
    await axios({
      method: "post",
      url: Configs.API_URL_AIOI_Back + `/api/product_unit/${mode.mode === "edit" ? 'editUnitConvert' : 'saveUnitConvert'}`,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data
    })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetUnitConvertByProdId() {
    await axios({
      method: "get",
      url: Configs.API_URL_AIOI_Back + "/api/product_unit/GetUnitConvertByProdId/" + id,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const data = response.data.data;
        setproduct({
          ...product,
          pu_id: data.pu_id || "",
          unit_id: data.unit_id || "",
          unit_width: data.unit_width || "",
          unit_depth: data.unit_depth || "",
          unit_height: data.unit_height || ""
        });
        product.pu_id = data.pu_id || "";
        product.unit_id = data.unit_id || "";
        product.unit_width = data.unit_width || "";
        product.unit_depth = data.unit_depth || "";
        product.unit_height = data.unit_height || "";

        setUnitConvertToList({
          data: data.unit_to_list || []
        });
        unitConvertToList.data = data.unit_to_list || [];

      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  async function GetDropdownUnit() {
    await axios({
      method: "get",
      url: Configs.API_URL_AIOI_Back + "/api/unit/getUnitDropdownForProduct",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const data = response.data.data;
        setUnitDropdown({
          data: data
        });
        unitDropdown.data = data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }
  const filterOptions = (candidate, input) => {
    if (input) {
      return candidate.label?.toString().toLowerCase().includes(input?.toString().toLowerCase());
    }
    return true;
  };

  const [systemConfig, setSystemConfig] = useState({
    data: {}
  });
  async function GetSystemConfig() {
    await axios({
      method: "get",
      url: Configs.API_URL_AIOI_Back + "/api/product_unit/GetSystemConfigDisplay",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const data = response.data.data;
        setSystemConfig({
          data: data
        });
        systemConfig.data = data;
      })
      .catch(async (error) => {
        console.log(error);
      });
  }

  function handleOnChangeUnitToList(value, key, index) {
    const data = unitConvertToList.data;
    if (!data[index][key] && value === '.') return;
    data[index][key] = value;
    setUnitConvertToList({
      data
    });
    unitConvertToList.data = data;
  }

  function AddRowUnitToList() {
    if (!product.unit_id) {
      Swal.fire('เพิ่ม Unit to ไม่สำเร็จ', 'กรุณาเลือก Unit', 'error');
      return
    }
    const data = unitConvertToList.data;
    data.push({
      unit_to_id: "",
      unit_to_select: null,
      ratio: "",
      unit_width: "",
      unit_depth: "",
      unit_height: ""
    })
    setUnitConvertToList({
      data
    });
    unitConvertToList.data = data;
  }

  function checkErrorTabUnitConvert(error_list) {
    if (isUseTabUnitConvert.data) {
      if (!product.unit_id || !product.unit_width || !product.unit_depth || !product.unit_height) {
        let temp_err = {
          message: "--- Tab Unit Convert ---",
        };
        error_list.push(temp_err);
      }
      if (!product.unit_id) {
        let temp_err = {
          message: "Please select information in the fields >>> [Unit].",
        };
        error_list.push(temp_err);
      }
      if (!product.unit_width) {
        let temp_err = {
          message: "Please enter information in the fields >>> [Width].",
        };
        error_list.push(temp_err);
      }
      if (!product.unit_depth) {
        let temp_err = {
          message: "Please enter information in the fields >>> [Depth].",
        };
        error_list.push(temp_err);
      }
      if (!product.unit_height) {
        let temp_err = {
          message: "Please enter information in the fields >>> [Height].",
        };
        error_list.push(temp_err);
      }

      let index_run = 1;
      for (let item of unitConvertToList?.data || []) {

        if (!item.unit_to_id) {
          let temp_err = {
            message: `Please select information in the fields >>> [To Unit]. in row >>> [${index_run}]`,
          };
          error_list.push(temp_err);
        }
        if (!item.ratio) {
          let temp_err = {
            message: `Please enter information in the fields >>> [Ratio]. in row >>> [${index_run}]`,
          };
          error_list.push(temp_err);
        }
        // if (!item.unit_width) {
        //   let temp_err = {
        //     message: `Please enter information in the fields >>> [Width]. in row >>> [${index_run}]`,
        //   };
        //   error_list.push(temp_err);
        // }
        // if (!item.unit_depth) {
        //   let temp_err = {
        //     message: `Please enter information in the fields >>> [Depth]. in row >>> [${index_run}]`,
        //   };
        //   error_list.push(temp_err);
        // }
        // if (!item.unit_height) {
        //   let temp_err = {
        //     message: `Please enter information in the fields >>> [Height]. in row >>> [${index_run}]`,
        //   };
        //   error_list.push(temp_err);
        // }
        index_run++;
      }
    }
    return error_list;
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Product {page_mode}
                  {"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Product</li>
                  <li className="breadcrumb-item active">{page_mode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            {mode.mode === "read" ? (
              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-4 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveOption}
                    class="btn btn-block btn-success "
                  >
                    Save
                  </button>
                </div>
                <div className="col-4 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={cancelOption}
                    class="btn btn-block btn-danger "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 col-md-6 col-xl-3">
                <h1>Product No: {product.no}</h1>
              </div>
              <div className="col-12 col-md-6 col-xl-3">
                <h1>Product Name: {product.name}</h1>
              </div>
            </div>
          </div>

          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="card card-primary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className={nav_active_step1}
                          id="custom-tabs-four-home-tab"
                          data-toggle="pill"
                          href="#custom-tabs-four-home"
                          role="tab"
                          aria-controls="custom-tabs-four-home"
                          aria-selected="true"
                        >
                          Basic Information
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={nav_active_step2}
                          id="custom-tabs-four-profile-tab"
                          data-toggle="pill"
                          href="#custom-tabs-four-profile"
                          role="tab"
                          aria-controls="custom-tabs-four-profile"
                          aria-selected="false"
                        >
                          Advance Information
                        </a>
                      </li>
                      {feature.map((e) => {
                        if (e.feature === "Product Material Define") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step3}
                                id="custom-tabs-four-messages-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-messages"
                                role="tab"
                                aria-controls="custom-tabs-four-messages"
                                aria-selected="false"
                              >
                                Material Define
                              </a>
                            </li>
                          );
                        }
                        if (e.feature === "Customer Product") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step4}
                                id="custom-tabs-four-customer-tab"
                                data-toggle="pill"
                                href="#custom-tabs-four-customer"
                                role="tab"
                                aria-controls="custom-tabs-four-customer"
                                aria-selected="false"
                              >
                                Customer Product
                              </a>
                            </li>
                          );
                        }
                        if (e.feature === "Cycle Time") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step5}
                                id="custom-tabs-five-tapsfore-tab"
                                data-toggle="pill"
                                href="#custom-tabs-five-tapsfore"
                                role="tab"
                                aria-controls="custom-tabs-five-tapsfore"
                                aria-selected="false"
                              >
                                Cycle Time
                              </a>
                            </li>
                          );
                        }

                        if (e.feature === "Cycle Time V.2") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step6}
                                id="custom-tabs-cycle_time2-tapsfore-tab"
                                data-toggle="pill"
                                href="#custom-tabs-cycle_time2-tapsfore"
                                role="tab"
                                aria-controls="custom-tabs-cycle_time2-tapsfore"
                                aria-selected="false"
                              >
                                Cycle Time
                              </a>
                            </li>
                          );
                        }

                        if (e.feature === "Product & Supplier") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step7}
                                id="custom-tabs-product_supplier-tapsfore-tab"
                                data-toggle="pill"
                                href="#custom-tabs-product_supplier-tapsfore"
                                role="tab"
                                aria-controls="custom-tabs-product_supplier-tapsfore"
                                aria-selected="false"
                              >
                                Supplier
                              </a>
                            </li>
                          );
                        }

                        if (e.feature === "Machine_Product_Tab") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step8}
                                id="custom-tabs-product_machine-tapsfore-tab"
                                data-toggle="pill"
                                href="#custom-tabs-product_machine-tapsfore"
                                role="tab"
                                aria-controls="custom-tabs-product_machine-tapsfore"
                                aria-selected="false"
                              >
                                Machine Product
                              </a>
                            </li>
                          );
                        }

                        if (e.feature === "RongShup") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step9}
                                id="custom-tabs-rongshup-tab"
                                data-toggle="pill"
                                href="#custom-tabs-rongshup"
                                role="tab"
                                aria-controls="custom-tabs-rongshup"
                                aria-selected="false"
                              >
                                {e.feature_name}
                              </a>
                            </li>
                          );
                        }
                        if (e.feature === "Product Customer CTS") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step10}
                                id="custom-tabs-product-customer-cts-tap"
                                data-toggle="pill"
                                href="#custom-tabs-product-customer-cts"
                                role="tab"
                                aria-controls="custom-tabs-product-customer-cts"
                                aria-selected="false"
                              >
                                {e.feature_name}
                              </a>
                            </li>
                          );
                        }

                        if (e.feature === "unit_convert") {
                          return (
                            <li className="nav-item">
                              <a
                                className={nav_active_step11}
                                id="custom-tabs-unit-convert-cts-tap"
                                data-toggle="pill"
                                href="#custom-tabs-unit-convert-cts"
                                role="tab"
                                aria-controls="custom-tabs-unit-convert-cts"
                                aria-selected="false"
                              >
                                {"Unit Convert" || e.feature_name}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                  <div className="card-body">
                    <div
                      className="tab-content"
                      id="custom-tabs-four-tabContent"
                    >
                      <div
                        className={class_active_step1}
                        id="custom-tabs-four-home"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-four-home-tab"
                      >
                        {feature.map((e) => {
                          if (e.feature === "Select Normal/Sub Assy (radio)") {
                            return (
                              <div className="row">
                                <div className="col-2">
                                  <div className="form-group">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="normal"
                                      name="type_select"
                                      value="normal"
                                      defaultChecked={
                                        assy === "true" ? false : true
                                      }
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onChange={(e) =>
                                        settype_select({
                                          ...type_select,
                                          status: e.target.value,
                                        })
                                      }
                                    />
                                    <label htmlFor="normal">
                                      Normal.{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="col-2">
                                  <div className="form-group">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="sub_assy"
                                      name="type_select"
                                      value="sub_assy"
                                      defaultChecked={
                                        assy === "true" ? true : false
                                      }
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onChange={(e) =>
                                        settype_select({
                                          ...type_select,
                                          status: e.target.value,
                                        })
                                      }
                                    />
                                    <label htmlFor="sub_assy">
                                      Sub Assy.{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}

                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-4">
                            {product_chack.checkthanasub === true ? (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={product.no}
                                  disabled={
                                    mode.mode === "read"
                                      ? true
                                      : false || product_chack.checkthanasub
                                        ? true
                                        : false
                                  }
                                  onChange={(e) =>
                                    setproduct({
                                      ...product,
                                      no: e.target.value,
                                    })
                                  }
                                  required
                                />
                                <label htmlFor="">
                                  Product No.
                                  {/*   {product.no.trim() === "" ? (
                                   <span style={{ color: "red" }}> *</span>
                                 ) : (
                                   ""
                                 )} */}
                                </label>
                              </div>
                            ) : (
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={product.no}
                                  disabled={mode.mode === "read" ? true : false}
                                  onChange={(e) =>
                                    setproduct({
                                      ...product,
                                      no: e.target.value,
                                    })
                                  }
                                  required
                                />
                                <label htmlFor="">
                                  Product No.
                                  {product.no.trim() === "" ? (
                                    <span style={{ color: "red" }}> *</span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                disabled={mode.mode === "read" ? true : false}
                                value={product.name}
                                onChange={(e) =>
                                  setproduct({
                                    ...product,
                                    name: e.target.value,
                                  })
                                }
                                required
                              />
                              <label htmlFor="">
                                Product Name{" "}
                                {product.name.trim() === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              <select
                                className="custom-select select2 form-control"
                                value={product.product_model_id}
                                disabled={mode.mode === "read" ? true : false}
                                onChange={(e) =>
                                  setproduct({
                                    ...product,
                                    product_model_id: e.target.value,
                                  })
                                }
                              >
                                <option value="" selected disabled>
                                  Select Model
                                </option>
                                {model.map((e) => {
                                  return (
                                    <option key={e.id} value={e.id}>
                                      {" "}
                                      {e.name}{" "}
                                    </option>
                                  );
                                })}
                              </select>
                              <label htmlFor="">Model/Category</label>
                            </div>
                          </div>
                        </div>

                        {getOem() === currentOEM.value &&
                          currentOEM.label === "บจก.เอสทีสตีล(2001) ปัตตานี" ? (
                          <>
                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="form-group">
                                  <select
                                    className="custom-select select2 form-control"
                                    value={product.stp_type}
                                    disabled={
                                      mode.mode === "read" ? true : false
                                    }
                                    onChange={(e) =>
                                      setproduct({
                                        ...product,
                                        stp_type: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="false">แผ่น</option>
                                    <option value="true">ม้วน</option>
                                  </select>
                                  <label htmlFor="">ประเภท</label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="form-group">
                                  <NumberFormat
                                    className="form-control"
                                    required
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    disabled={
                                      mode.mode === "read" ? true : false
                                    }
                                    value={product.stp_width}
                                    onChange={(e) =>
                                      setproduct({
                                        ...product,
                                        stp_width: e.target.value,
                                      })
                                    }
                                  />
                                  <label htmlFor="">Size(ความกว้าง)</label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="form-group">
                                  <NumberFormat
                                    className="form-control"
                                    required
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    disabled={
                                      mode.mode === "read" ? true : false
                                    }
                                    value={product.stp_height}
                                    onChange={(e) =>
                                      setproduct({
                                        ...product,
                                        stp_height: e.target.value,
                                      })
                                    }
                                  />
                                  <label htmlFor="">Size(ความยาว)</label>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12 col-md-6 col-xl-4">
                                <div className="form-group">
                                  <select
                                    className="custom-select select2 form-control"
                                    value={product.blade_head}
                                    disabled={
                                      mode.mode === "read" ? true : false
                                    }
                                    onChange={(e) =>
                                      setproduct({
                                        ...product,
                                        blade_head: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="0">ไม่ใช่หัวรีด</option>
                                    <option value="1">หัวรีดใหม่</option>
                                    <option value="2">หัวรีดเก่า</option>
                                  </select>
                                  <label htmlFor="">ประเภทหัวรีด</label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {feature.map((e) => {
                          if (e.feature === "Qty_unit") {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <NumberFormat
                                        className="form-control"
                                        required
                                        thousandSeparator={true}
                                        value={product.qty_unit}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            qty_unit: e.target.value,
                                          })
                                        }
                                      />

                                      <label htmlFor="">Qty / Unit </label>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}

                        <br />
                      </div>
                      <div
                        className={class_active_step2}
                        id="custom-tabs-four-profile"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-four-profile-tab"
                      >
                        <div className="row">
                          <div
                            className="col-12 col-md-8 col-xl-6"
                            style={{ textAlign: "center" }}
                          >
                            <img
                              id="img"
                              alt="..."
                              className="img-fluid rounded shadow border-0"
                              src={
                                product.image_path !== "" &&
                                  product.image_path !== null &&
                                  product.image_path !== undefined
                                  ? Configs.API_URL_IMG + product.image_path
                                  : userdefault_img.imgs
                              }
                              style={{ width: "400px", height: "300px" }}
                            />
                            {/* <span style={{ color: "red", fontSize: "12px" }}>
                              Recommend Size:400x300px{" "}
                            </span> */}
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <label htmlFor="exampleInputFile">
                              Product Image{" "}
                              <span style={{ color: "red" }}>
                                {" "}
                                size(400 x 300) px
                              </span>
                            </label>
                            <div className="input-group">
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="exampleInputFile"
                                  disabled={mode.mode === "read" ? true : false}
                                  accept="image/*"
                                  onChange={setproductLogo}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="exampleInputFile"
                                >
                                  {product.image_name !== ""
                                    ? product.image_name
                                    : "Select Image"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-10 col-xl-8">
                            <div className="form-group">
                              {" "}
                              <textarea
                                className="form-control"
                                rows={3}
                                placeholder="Description"
                                disabled={mode.mode === "read" ? true : false}
                                value={product.description}
                                onChange={(e) =>
                                  setproduct({
                                    ...product,
                                    description: e.target.value,
                                  })
                                }
                                defaultValue={""}
                                required
                              />
                              <label>Product Description</label>
                            </div>
                          </div>

                          {feature.map((e) => {
                            if (e.feature === "Vertaul Product") {
                              return (
                                <>
                                  <div className="col-12 col-md-6 col-xl-2">
                                    <div className="form-group">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="sub_assy"
                                        name="is_virtual"
                                        value="virtual"
                                        checked={product.is_virtual}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            is_virtual: e.target.checked,
                                          })
                                        }
                                      />
                                      {/*  {console.log(  product.is_virtual)} */}
                                      <label htmlFor="">สินค้าโป๊ว</label>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              <NumberFormat
                                className="form-control"
                                required
                                allowNegative={false}
                                thousandSeparator={true}
                                disabled={mode.mode === "read" ? true : false}
                                value={product.sell_price}
                                onChange={(e) =>
                                  setproduct({
                                    ...product,
                                    sell_price: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="">Sell Price(THB)</label>
                            </div>
                          </div>

                          {feature.map((e) => {
                            if (e.feature === "Cost") {
                              return (
                                <>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <NumberFormat
                                        className="form-control"
                                        required
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        value={product.cost}
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            cost: e.target.value,
                                          })
                                        }
                                      />
                                      <label htmlFor="">Cost(THB)</label>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })}

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              {" "}
                              <NumberFormat
                                className="form-control"
                                required
                                allowNegative={false}
                                thousandSeparator={true}
                                value={product.min_product}
                                disabled={mode.mode === "read" ? true : false}
                                onChange={(e) =>
                                  setproduct({
                                    ...product,
                                    min_product: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="">Min stock</label>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              {" "}
                              <NumberFormat
                                className="form-control"
                                required
                                allowNegative={false}
                                thousandSeparator={true}
                                value={product.min_lot}
                                disabled={mode.mode === "read" ? true : false}
                                onChange={(e) =>
                                  setproduct({
                                    ...product,
                                    min_lot: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="">Min Product</label>
                            </div>
                          </div>
                          {feature.map((e) => {
                            if (e.feature === "Surface") {
                              return (
                                <>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      {" "}
                                      <input
                                        className="form-control"
                                        required
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        value={product.surface}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            surface: e.target.value,
                                          })
                                        }
                                      />
                                      <label htmlFor="">
                                        Surface Treatment
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      {" "}
                                      <NumberFormat
                                        className="form-control"
                                        required
                                        allowNegative={false}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        value={product.nw_number}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            nw_number: e.target.value,
                                          })
                                        }
                                      />
                                      <label htmlFor="">N/W (g.)</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      {" "}
                                      <NumberFormat
                                        className="form-control"
                                        required
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        value={product.packing_standart}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            packing_standart: e.target.value,
                                          })
                                        }
                                      />
                                      <label htmlFor="">
                                        Packing Standard (ชิ้น)
                                      </label>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>

                        {feature.map((e) => {
                          if (e.feature === "SKU") {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={product.sku_user}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            sku_user: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                      <label htmlFor="">SKU</label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        value={product.sku_system}
                                        required
                                      />
                                      <label htmlFor="">SKU(System)</label>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }
                        })}

                        <div className="row">
                          {feature.map((e) => {
                            if (e.feature === "size_sst") {
                              return (
                                <div className="col-12 col-md-6 col-xl-4">
                                  <div className="form-group">
                                    {" "}
                                    <NumberFormat
                                      className="form-control"
                                      required
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      value={product.size}
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onChange={(e) =>
                                        setproduct({
                                          ...product,
                                          size: e.target.value,
                                        })
                                      }
                                    />
                                    <label htmlFor="">Size(ความกว้าง)</label>
                                  </div>
                                </div>
                              );
                            }

                            if (e.feature === "unit_sst") {
                              return (
                                <div className="col-12 col-md-6 col-xl-4">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      value={product.unit}
                                      onChange={(e) =>
                                        setproduct({
                                          ...product,
                                          unit: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                    <label htmlFor="">Unit type</label>
                                  </div>
                                </div>
                              );
                            }

                            if (e.feature === "thickness_sst") {
                              return (
                                <div className="col-12 col-md-6 col-xl-4">
                                  <div className="form-group">
                                    {" "}
                                    <NumberFormat
                                      className="form-control"
                                      required
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      value={product.thickness}
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onChange={(e) =>
                                        setproduct({
                                          ...product,
                                          thickness: e.target.value,
                                        })
                                      }
                                    />
                                    <label htmlFor="">ความหนา</label>
                                  </div>
                                </div>
                              );
                            }

                            if (e.feature === "weight_sst") {
                              return (
                                <div className="col-12 col-md-6 col-xl-4">
                                  <div className="form-group">
                                    {" "}
                                    <NumberFormat
                                      className="form-control"
                                      required
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      value={product.weight}
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onChange={(e) =>
                                        setproduct({
                                          ...product,
                                          weight: e.target.value,
                                        })
                                      }
                                    />
                                    <label htmlFor="">น้ำหนักต่อหน่วย</label>
                                  </div>
                                </div>
                              );
                            }

                            if (e.feature === "cross_section_area_sst") {
                              return (
                                <div className="col-12 col-md-6 col-xl-4">
                                  <div className="form-group">
                                    {" "}
                                    <NumberFormat
                                      className="form-control"
                                      required
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      value={product.cross_section_area}
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      onChange={(e) =>
                                        setproduct({
                                          ...product,
                                          cross_section_area: e.target.value,
                                        })
                                      }
                                    />
                                    <label htmlFor="">
                                      พื้นที่หน้าตัด(ตร.ซม.)
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <div className="row">
                          {feature.map((e) => {
                            if (e.feature === "Route") {
                              return (
                                <>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      {" "}
                                      <select
                                        className="custom-select"
                                        value={product.product_route_id}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            product_route_id: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="" selected disabled>
                                          select Route
                                        </option>
                                        {route.map((el) => {
                                          return (
                                            <option key={el.id} value={el.id}>
                                              {el.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <label htmlFor="">Route</label>
                                    </div>
                                  </div>
                                </>
                              );
                            }

                            if (e.feature === "Size") {
                              return (
                                <>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      {" "}
                                      <select
                                        className="custom-select"
                                        value={product.product_size_id}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            product_size_id: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="" selected disabled>
                                          select Size
                                        </option>
                                        {size.map((el) => {
                                          return (
                                            <option key={el.id} value={el.id}>
                                              {el.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <label htmlFor="">Size</label>
                                    </div>
                                  </div>
                                </>
                              );
                            }

                            if (e.feature === "Color") {
                              return (
                                <>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <select
                                        className="custom-select"
                                        value={product.product_color_id}
                                        disabled={
                                          mode.mode === "read" ? true : false
                                        }
                                        onChange={(e) =>
                                          setproduct({
                                            ...product,
                                            product_color_id: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="" selected disabled>
                                          select Color
                                        </option>
                                        {color.map((el) => {
                                          return (
                                            <option key={el.id} value={el.id}>
                                              {el.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <label htmlFor="">Color</label>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                        <br />
                      </div>

                      <div
                        className={class_active_step3}
                        id="custom-tabs-four-messages"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-four-messages-tab"
                      >
                        <div style={{ display: display_normal }}>
                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="">Material Select</label>
                              <div className="row">
                                <div className="col-8 col-md-4 col-xl-2">
                                  <div className="form-group ">
                                    <button
                                      type="button"
                                      class="btn btn-block btn-info "
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      data-toggle="modal"
                                      data-target="#modal-material"
                                      onClick={()=>{GetMat_dataLazeload(1,10,'search')}}
                                    >
                                      Select Material
                                    </button>
                                  </div>
                                </div>
                                <div className="col-8 col-md-4 col-xl-2">
                                  <div className="form-group ">
                                    <button
                                      type="button"
                                      class="btn btn-block btn-info "
                                      disabled={
                                        mode.mode === "read" ||
                                          mode.mode === "add"
                                          ? true
                                          : false
                                      }
                                      onClick={restore_material}
                                    >
                                      Restore
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="table-responsive"
                                style={{
                                  height: "600px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <MDBDataTable
                                  sortable={false}
                                  className="table table-head-fixed"
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={data_value_select}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div style={{ display: display_sub_assy }}>
                          <div className="row">
                            <div className="col-12">
                              <label htmlFor="">Product Select</label>
                              <div className="row">
                                <div className="col-8 col-md-4 col-xl-2">
                                  <div className="form-group ">
                                    <button
                                      type="button"
                                      class="btn btn-block btn-info "
                                      disabled={
                                        mode.mode === "read" ? true : false
                                      }
                                      data-toggle="modal"
                                      data-target="#modal-product"
                                    >
                                      Select Product
                                    </button>
                                  </div>{" "}
                                </div>

                                <div className="col-8 col-md-4 col-xl-2">
                                  <div className="form-group ">
                                    <button
                                      type="button"
                                      class="btn btn-block btn-info "
                                      disabled={
                                        mode.mode === "read" ||
                                          mode.mode === "add"
                                          ? true
                                          : false
                                      }
                                      onClick={restore_product}
                                    >
                                      Restore
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div
                                className="table-responsive"
                                style={{
                                  height: "600px",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <MDBDataTable
                                  sortable={false}
                                  className="table table-head-fixed"
                                  striped
                                  bordered
                                  hover
                                  fixedHeader
                                  data={data_product_select}
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      </div>

                      {/* //----------------------------------------------------------------------------------- */}

                      <div
                        custom-tabs-four-customer
                        className={class_active_step4}
                        id="custom-tabs-four-customer"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-four-customer-tab"
                      >
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-primary"
                              data-toggle="modal"
                              data-target="#modal-add_customer_product"
                              onClick={add_customer}
                              disabled={mode.mode === "read" ? true : false}
                            >
                              Add Customer Product&nbsp;
                              <span>
                                <i className="fa fa-plus-circle"></i>
                              </span>
                            </button>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <MDBTable
                                sortable={false}
                                striped
                                hover
                                bordered
                                small
                                searching={true}
                              >
                                <MDBTableHead>
                                  <tr>
                                    <th>No</th>
                                    <th>Customer item</th>
                                    <th>Customer item name</th>
                                    <th>Plant item</th>
                                    <th>Remark</th>
                                    <th>Last update Record</th>
                                    {mode.mode === "read" ? (
                                      <></>
                                    ) : (
                                      <th>MGT</th>
                                    )}
                                  </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                  {customer_product_data.map((el, index) => {
                                    if (el.is_use) {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{el.customer_item}</td>
                                          <td>{el.customer_item_name}</td>
                                          <td>{el.plan_item}</td>
                                          <td>{el.remark}</td>
                                          <td>
                                            {moment(el.last_update).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </td>

                                          {mode.mode === "read" ? (
                                            <></>
                                          ) : (
                                            <>
                                              <td>
                                                <div className="row">
                                                  <div className="col-4">
                                                    {" "}
                                                    <a
                                                      data-toggle="modal"
                                                      data-target="#modal-add_customer_product"
                                                      onClick={form_customer.bind(
                                                        this,
                                                        el.customer_item,
                                                        el.customer_item_name,
                                                        el.id,
                                                        "edit",
                                                        index
                                                      )}
                                                    >
                                                      <i class="fas fa-pencil-alt"></i>
                                                    </a>{" "}
                                                  </div>
                                                  <div className="col-4">
                                                    <i
                                                      onClick={del_customer_list.bind(
                                                        this,
                                                        el.customer_item
                                                      )}
                                                      className="fa fa-trash  icon-sm"
                                                    ></i>
                                                  </div>
                                                </div>
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      );
                                    }
                                  })}
                                </MDBTableBody>
                              </MDBTable>
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>

                      <div
                        className={class_active_step5}
                        id="custom-tabs-five-tapsfore"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-five-tapsfore-tab"
                      >
                        {data_for_section.map((el, i) => {
                          return (
                            <>
                              <h3 className="mt-5-head">{el.section}</h3>
                              <div className="tab-custom-content"></div>
                              <br />

                              <div className="row">
                                {el.process.map((el2, j) => {
                                  return (
                                    <div className="col-4">
                                      <div className="form-group">
                                        <NumberFormat
                                          type="text"
                                          allowNegative={false}
                                          key={el2.id}
                                          id={i + "_" + j}
                                          className="form-control"
                                          name={el2.id}
                                          value={el2.cycle_time}
                                          disabled={
                                            mode.mode === "read" ? true : false
                                          }
                                          onChange={GetProcessData.bind(this)}
                                          required
                                        />

                                        <label htmlFor="">
                                          {el2.process_name}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })}

                        <br />
                      </div>

                      <div
                        className={class_active_step6}
                        id="custom-tabs-cycle_time2-tapsfore"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-cycle_time2-tapsfore-tab"
                      >
                        <div className="col-1">
                          <div className="form-group ">
                            <button
                              disabled={mode.mode === "read" ? true : false}
                              type="button"
                              className="btn btn-block btn-primary"
                              data-toggle="modal"
                              data-target="#modal-add_process_cycle_time"
                              onClick={() => {
                                setprocess_cycle_select({
                                  ...process_cycle_select,
                                  id: "",
                                  process: "",
                                  cycle_time: "",
                                  mode: "add",
                                  index: "",
                                });
                              }}
                            >
                              Add&nbsp;
                              <span>
                                <i className="fa fa-plus-circle"></i>
                              </span>
                            </button>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                disableRetreatAfterSorting={true}
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={dataProcess_cycle_time}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>

                      <div
                        className={class_active_step7}
                        id="custom-tabs-product_supplier-tapsfore"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-product_supplier-tapsfore-tab"
                      >
                        <div className="row">
                          <div className="col-8 col-md-4 col-xl-2">
                            <div class="form-group">
                              <button
                                type="button"
                                disabled={mode.mode === "read" ? true : false}
                                data-toggle="modal"
                                data-target="#modal-add_supplier_rerationship"
                                class="btn btn-block btn-primary "
                                onClick={() => {
                                  setsupplier_select({
                                    ...supplier_select,
                                    id: "",
                                    supplier: "",
                                    buy_price: "",
                                    minimum_order: "",
                                    lead_time: "",
                                    remark: "",
                                    mode: "add",
                                    index: "",
                                  });
                                }}
                              >
                                Add Relationship Supplier&nbsp;
                                <span>
                                  <i className="fa fa-plus-circle"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div
                              className="table-responsive"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                disableRetreatAfterSorting={true}
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={dataSupplier}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>

                      <div
                        className={class_active_step8}
                        id="custom-tabs-product_machine-tapsfore"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-product_machine-tapsfore-tab"
                      >
                        <div className="row">
                          <div className="col-2">
                            <div class="form-group">
                              <button
                                type="button"
                                disabled={mode.mode === "read" ? true : false}
                                data-toggle="modal"
                                data-target="#modal-add_machine_rerationship"
                                class="btn btn-block btn-primary "
                                onClick={() => {
                                  GetMachine();
                                }}
                              >
                                Add Relationship Machine&nbsp;
                                <span>
                                  <i className="fa fa-plus-circle"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                disableRetreatAfterSorting={true}
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={dataMachine}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>

                      <div
                        className={class_active_step9}
                        id="custom-tabs-rongshup"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-rongshup-tab"
                      >
                        <div className="row">
                          <div className="col-2">
                            <div class="form-group">
                              <button
                                type="button"
                                disabled={mode.mode === "read" ? true : false}
                                data-toggle="modal"
                                data-target="#modal-add_factory_choup"
                                class="btn btn-block btn-primary "
                              /* onClick={() => {
                                GetMachine();
                              }} */
                              >
                                เพิ่ม โรงชุป&nbsp;
                                <span>
                                  <i className="fa fa-plus-circle"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                disableRetreatAfterSorting={true}
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={FacChoupS}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>

                      <div
                        className={class_active_step10}
                        id="custom-tabs-product-customer-cts"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-product-customer-cts-tap"
                      >
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-3">
                            <div class="form-group">
                              <button
                                type="button"
                                disabled={mode.mode === "read" ? true : false}
                                data-toggle="modal"
                                data-target="#modal-add_product_customer_cts"
                                class="btn btn-block btn-primary "
                              /*  onClick={() => {
                                GetMachine();
                              }} */
                              >
                                Add Product Customer Relate&nbsp;
                                <span>
                                  <i className="fa fa-plus-circle"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                disableRetreatAfterSorting={true}
                                striped
                                bordered
                                hover
                                fixedHeader
                                data={TableCustomer_Select}
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                      </div>

                      <div
                        className={class_active_step11}
                        id="custom-tabs-unit-convert-cts"
                        role="tabpanel"
                        aria-labelledby="custom-tabs-unit-convert-cts-tap"
                      >
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              <Select
                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                disabled={mode.mode === "read" ? true : false}
                                options={unitDropdown.data?.filter(fi => fi.unit_is_use === true)}
                                value={unitDropdown.data?.find(fid => fid.value === product.unit_id)}
                                filterOption={filterOptions}
                                onChange={async (e) => {
                                  if (product.unit_id == e.value) {
                                    return;
                                  }
                                  setproduct({
                                    ...product,
                                    unit_id: e.value,
                                    unit_select: e
                                  })

                                }}
                              />
                              {/* <select
                                className="custom-select select2 form-control"
                                value={product.unit_id}
                                disabled={mode.mode === "read" ? true : false}
                                onChange={(e) =>
                                  setproduct({
                                    ...product,
                                    unit_id: e.target.value,
                                  })
                                }
                              >
                                <option value="" selected disabled>
                                  Select Unit
                                </option>
                                {unitDropdown.data?.map((e) => {
                                  return (
                                    <option key={e.id} value={e.id}>
                                      {" "}
                                      {e.name}{" "}
                                    </option>
                                  );
                                })}
                              </select> */}
                              <label htmlFor="">Unit
                                {product.unit_id?.trim() === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              <NumberFormat
                                className="form-control"
                                required
                                allowNegative={false}
                                decimalScale={2}
                                thousandSeparator={true}
                                disabled={mode.mode === "read" ? true : false}
                                value={product.unit_width}
                                onChange={(e) => {
                                  if (!product.unit_width && e.target.value === '.') return;
                                  setproduct({
                                    ...product,
                                    unit_width: e.target.value,
                                  })
                                }}
                              />
                              <label htmlFor="">
                                Width ({systemConfig.data?.sc_name || '-'})
                                {product.unit_width?.trim() === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              <NumberFormat
                                className="form-control"
                                required
                                allowNegative={false}
                                decimalScale={2}
                                thousandSeparator={true}
                                disabled={mode.mode === "read" ? true : false}
                                value={product.unit_depth}
                                onChange={(e) => {
                                  if (!product.unit_depth && e.target.value === '.') return;
                                  setproduct({
                                    ...product,
                                    unit_depth: e.target.value,
                                  })
                                }}
                              />
                              <label htmlFor="">
                                Depth ({systemConfig.data?.sc_name || '-'})
                                {product.unit_depth?.trim() === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                              <NumberFormat
                                className="form-control"
                                required
                                allowNegative={false}
                                decimalScale={2}
                                thousandSeparator={true}
                                disabled={mode.mode === "read" ? true : false}
                                value={product.unit_height}
                                onChange={(e) => {
                                  if (!product.unit_height && e.target.value === '.') return;
                                  setproduct({
                                    ...product,
                                    unit_height: e.target.value,
                                  })
                                }}
                              />
                              <label htmlFor="">
                                Height ({systemConfig.data?.sc_name || '-'})
                                {product.unit_height?.trim() === "" ? (
                                  <span style={{ color: "red" }}> *</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-xl-2">
                            <button
                              type="button"
                              hidden={mode.mode === "read"}
                              onClick={() => {
                                AddRowUnitToList();
                              }}
                              data-dismiss="modal"
                              className="btn btn-primary w-100"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                        <hr />
                        {
                          unitConvertToList.data?.map((el, index) => {
                            return (
                              <div key={index}>
                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <Select
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        options={unitDropdown.data?.filter(fil => (product.unit_id !== fil.value && fil.unit_is_use === true && !unitConvertToList.data.find(tl => tl.unit_to_id === fil.value)) || el.unit_to_id === fil.value)}
                                        disabled={mode.mode === "read" ? true : false}
                                        value={unitDropdown.data?.find(fid => fid.value === el.unit_to_id)}
                                        filterOption={filterOptions}
                                        onChange={async (e) => {
                                          if (el.unit_to_id == e.value) {
                                            return;
                                          }
                                          handleOnChangeUnitToList(e, 'unit_to_select', index);
                                          handleOnChangeUnitToList(e.value, 'unit_to_id', index);

                                        }}
                                      />
                                      {/* <select
                                        className="custom-select select2 form-control"
                                        value={el.unit_to_id}
                                        disabled={mode.mode === "read" ? true : false}
                                        onChange={(e) => {
                                          handleOnChangeUnitToList(e.target.value, 'unit_to_id', index);
                                        }}
                                      >
                                        <option value="" selected disabled>
                                          Select Unit
                                        </option>
                                        {unitDropdown.data
                                          ?.filter(fil => (product.unit_id !== fil.id && !unitConvertToList.data.find(tl => tl.unit_to_id === fil.id)) || el.unit_to_id === fil.id)
                                          .map((e) => {
                                            return (
                                              <option key={e.id} value={e.id}>
                                                {" "}
                                                {e.name}{" "}
                                              </option>
                                            );
                                        })}
                                      </select> */}
                                      <label htmlFor="">To Unit
                                        {el.unit_to_id?.toString().trim() === "" ? (
                                          <span style={{ color: "red" }}> *</span>
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="d-flex align-items-center">
                                      <span style={{ whiteSpace: 'nowrap', fontSize: '1.5rem' }}>1 : {"   "}</span>
                                      <div className="form-group w-100 ml-2">
                                        <NumberFormat
                                          className="form-control"
                                          required
                                          allowNegative={false}
                                          decimalScale={2}
                                          thousandSeparator={true}
                                          disabled={mode.mode === "read" ? true : false}
                                          value={el.ratio}
                                          onChange={(e) =>
                                            handleOnChangeUnitToList(e.target.value, 'ratio', index)
                                          }
                                        />
                                        <label htmlFor="">Ratio
                                          {el.ratio?.toString().trim() === "" ? (
                                            <span style={{ color: "red" }}> *</span>
                                          ) : (
                                            ""
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row">
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <NumberFormat
                                        className="form-control"
                                        required
                                        allowNegative={false}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        disabled={mode.mode === "read" ? true : false}
                                        value={el.unit_width}
                                        onChange={(e) =>
                                          handleOnChangeUnitToList(e.target.value, 'unit_width', index)
                                        }
                                      />
                                      <label htmlFor="">
                                        Width ({systemConfig.data?.sc_name || '-'})
                                        {el.unit_width?.toString().trim() === "" ? (
                                          <span style={{ color: "red" }}> *</span>
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <NumberFormat
                                        className="form-control"
                                        required
                                        allowNegative={false}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        disabled={mode.mode === "read" ? true : false}
                                        value={el.unit_depth}
                                        onChange={(e) =>
                                          handleOnChangeUnitToList(e.target.value, 'unit_depth', index)
                                        }
                                      />
                                      <label htmlFor="">
                                        Depth ({systemConfig.data?.sc_name || '-'})
                                        {el.unit_depth?.toString().trim() === "" ? (
                                          <span style={{ color: "red" }}> *</span>
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-xl-4">
                                    <div className="form-group">
                                      <NumberFormat
                                        className="form-control"
                                        required
                                        allowNegative={false}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        disabled={mode.mode === "read" ? true : false}
                                        value={el.unit_height}
                                        onChange={(e) =>
                                          handleOnChangeUnitToList(e.target.value, 'unit_height', index)
                                        }
                                      />
                                      <label htmlFor="">
                                        Height ({systemConfig.data?.sc_name || '-'})
                                        {el.unit_height?.toString().trim() === "" ? (
                                          <span style={{ color: "red" }}> *</span>
                                        ) : (
                                          ""
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="row">
                                  <div className="col-12 col-md-6 col-xl-2">
                                    <button
                                      type="button"
                                      hidden={mode.mode === "read"}
                                      onClick={() => {
                                        const data = unitConvertToList.data;
                                        data.splice(index, 1);
                                        setUnitConvertToList({
                                          data
                                        });
                                        unitConvertToList.data = data;
                                      }}
                                      data-dismiss="modal"
                                      className="btn btn-danger w-100"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}

        <div className="modal fade" id="modal-size-add">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Size Add</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      {" "}
                      <input type="text" className="form-control" required />
                      <label htmlFor="">New Size</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
        {/* /.modal */}
        <div className="modal fade" id="modal-size-edit">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Size Edit</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <input type="text" className="form-control" required />
                      <label htmlFor="">Edit Size</label>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
        {/* /.modal */}
        <div className="modal fade" id="modal-color-add">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Color Add</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      {" "}
                      <input type="text" className="form-control" required />
                      <label htmlFor="">New Color</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
        {/* /.modal */}
        <div className="modal fade" id="modal-color-edit">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Color Edit</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-4">
                    <label htmlFor="">Edit Color</label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Confirm
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
        {/* /.modal */}
      </div>
      {/*   <Footter /> */}

      <div className="modal fade" id="modal-material">
        <div className="modal-dialog modal-xl" >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Material</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 ">
                  <div className="none-nolmalinput"></div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-8 col-md-4 col-xl-2">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={Mat_fillter.no.trim()}
                          disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setMat_fillter({
                              ...Mat_fillter,
                              no: e.target.value.trim(),
                            })
                          }
                          required
                        />
                        <label htmlFor="">Material No</label>
                      </div>
                    </div>
                    <div className="col-8 col-md-4 col-xl-2">
                      <div className="form-group">
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          value={Mat_fillter.name.trim()}
                          disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setMat_fillter({
                              ...Mat_fillter,
                              name: e.target.value.trim(),
                            })
                          }
                          required
                        />
                        <label htmlFor="">Material Name</label>
                      </div>
                    </div>
                    {feature.map((e) => {
                      if (e.feature === "Mat Type") {
                        return (
                          <>
                            <div className="col-8 col-md-4 col-xl-2">
                              <div className="form-group">
                                {" "}
                                <select
                                  className="custom-select"
                                  value={Mat_fillter.material_type_id}
                                  disabled={mode.mode === "read" ? true : false}
                                  onChange={(e) =>
                                    setMat_fillter({
                                      ...Mat_fillter,
                                      material_type_id: e.target.value,
                                    })
                                  }
                                >
                                  <option value="" disabled selected>
                                    Select Type
                                  </option>
                                  {Mat_type.map((el) => {
                                    return (
                                      <option key={el.id} value={el.id}>
                                        {el.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label htmlFor="">Mat Type</label>
                              </div>
                            </div>
                          </>
                        );
                      }

                      if (e.feature === "Mat Spec") {
                        return (
                          <>
                            <div className="col-8 col-md-4 col-xl-2">
                              <div className="form-group">
                                {" "}
                                <select
                                  className="custom-select"
                                  value={Mat_fillter.material_spec_id}
                                  disabled={mode.mode === "read" ? true : false}
                                  onChange={(e) =>
                                    setMat_fillter({
                                      ...Mat_fillter,
                                      material_spec_id: e.target.value,
                                    })
                                  }
                                >
                                  <option value="" disabled selected>
                                    Select Spec
                                  </option>
                                  {Mat_spect.map((el) => {
                                    return (
                                      <option key={el.id} value={el.id}>
                                        {el.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label htmlFor="">Mat Spec</label>
                              </div>
                            </div>
                          </>
                        );
                      }
                    })}
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 col-xl-2">
                      <br />
                      <button
                        type="button"
                        class="btn btn-block btn-info "
                        disabled={mode.mode === "read" ? true : false}
                        onClick={fillterdata}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-4 col-md-3 col-xl-2">
                      <br />
                      <button
                        type="button"
                        class="btn btn-block btn-info "
                        disabled={mode.mode === "read" ? true : false}
                        onClick={() => {
                          clear_fillter();
                        }}
                      >
                        Clear
                      </button>
                    </div>

                    {feature.map((e) => {
                      if (e.feature === "Import Bomlist (nomal)") {
                        return (
                          <div className="col-8 col-md-4 col-xl-2">
                            <br />
                            <button
                              type="button"
                              class="btn btn-block btn-info "
                              disabled={mode.mode === "read" ? true : false}
                              data-toggle="modal"
                              data-target="#modal-default"
                            >
                              Import Bomlist
                            </button>
                          </div>
                        );
                      }
                      if (e.feature === "download template.xls (normal)") {
                        return (
                          <div className="col-8 col-md-4 col-xl-2">
                            <br />
                            <a
                              href={product_bomlist}
                              download="upload_Bomlist.xlsx"
                              style={{ fontSize: "" }}
                            >
                              {" "}
                              <button className="btn btn-block btn-info">
                                download template.xls
                              </button>
                            </a>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <br />
                  <div
                    className="table-responsive"
                    style={{ height: "300px", whiteSpace: "nowrap" }}
                  >
                    <TableLEK


                      headTable={dataMatLaze.columns}
                      dataTable={rowsDataMatLaze}
                      countPage={countPagemat}
                      countData={countDatamat}
                      entries={entriesmat}
                      isSearch={searchmat}
                      callFnc={GetMat_dataLazeload}


                    />
                    
                    {/*  <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      entries={4096}
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={data}
                    /> */}

                    {/* ตรงนี้ Material */}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-product">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Product</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 ">
                  <div className="none-nolmalinput"></div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-8 col-md-4 col-xl-2">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          value={filter_product.no.trim()}
                          disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setfilter_product({
                              ...filter_product,
                              no: e.target.value.trim(),
                            })
                          }
                          required
                        />
                        <label htmlFor="">Product No</label>
                      </div>
                    </div>
                    <div className="col-8 col-md-4 col-xl-2">
                      <div className="form-group">
                        {" "}
                        <input
                          type="text"
                          className="form-control"
                          value={filter_product.name}
                          disabled={mode.mode === "read" ? true : false}
                          onChange={(e) =>
                            setfilter_product({
                              ...filter_product,
                              name: e.target.value,
                            })
                          }
                          required
                        />
                        <label htmlFor="">Product Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8 col-md-4 col-xl-2">
                      <br />
                      <button
                        type="button"
                        class="btn btn-block btn-info "
                        disabled={mode.mode === "read" ? true : false}
                        onClick={fillterdata}
                      >
                        Search
                      </button>
                    </div>
                    <div className="col-8 col-md-4 col-xl-2">
                      <br />
                      <button
                        type="button"
                        class="btn btn-block btn-info "
                        disabled={mode.mode === "read" ? true : false}
                        onClick={() => {
                          clear_fillter();
                        }}
                      >
                        Clear
                      </button>
                    </div>
                    {feature.map((e) => {
                      if (e.feature === "Import Bomlist (sub-assy)") {
                        return (
                          <div className="col-8 col-md-4 col-xl-2">
                            <br />
                            <button
                              type="button"
                              class="btn btn-block btn-info "
                              disabled={mode.mode === "read" ? true : false}
                              data-toggle="modal"
                              data-target="#modal-subassy"
                            >
                              Import Bomlist
                            </button>
                          </div>
                        );
                      }
                      if (e.feature === "download template.xsl (sub-assy)") {
                        return (
                          <div className="col-8 col-md-4 col-xl-2">
                            <br />
                            <a
                              href={product_bomlist_subassy}
                              download="upload_Bomlist_sub_assy.xlsx"
                              style={{ fontSize: "" }}
                            >
                              {" "}
                              <button className="btn btn-block btn-info">
                                download template.xls
                              </button>
                            </a>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <br />
                  <div
                    className="table-responsive"
                    style={{ height: "300px", whiteSpace: "nowrap" }}
                  >
                    <MDBDataTable
                      sortable={false}
                      className="table table-head-fixed"
                      entries={4096}
                      striped
                      bordered
                      hover
                      fixedHeader
                      data={data_product}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-default">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Upload Bomlist(Normal)</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={set_bomlist_excel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 ">
                  <div className="none-nolmalinput">
                    <label htmlFor="">Bomlist file:</label>
                    <small style={{ color: "red" }}>
                      ** Excel from template **
                    </small>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={upload_bomlist_file}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <MDBTable striped hover bordered small searching={true}>
                      <MDBTableHead>
                        <tr>
                          <th>No</th>
                          <th>Product No</th>
                          <th>Material No</th>
                          <th>wt_mtr</th>
                          <th>c_leng</th>
                          <th>cos_pc</th>
                          <th>Status</th>
                          <th>Error_message</th>
                          <th>Add/Update</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {excel.map((el, index) => {
                          if (excel.filter((x) => !x.status).length > 0) {
                            var status_up = false;
                          } else {
                            var status_up = true;
                          }
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{el.part_no}</td>
                              <td>{el.mat_code}</td>
                              <td>{el.wt_mtr}</td>
                              <td>{el.c_leng}</td>
                              <td>{el.cos_pc}</td>
                              <td>
                                {el.status ? (
                                  <span style={{ color: "green" }}>True</span>
                                ) : (
                                  <span style={{ color: "red" }}>False</span>
                                )}
                              </td>
                              <td>{el.error}</td>
                              <td>
                                {status_up ? (
                                  <span style={{ color: "green" }}>
                                    Success
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>False</span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <h3>
                    {excel.filter((x) => !x.status).length > 0
                      ? "Upload Fail"
                      : "Upload Success"}
                  </h3>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={set_bomlist_excel}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-subassy">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Upload Bomlist(Sub-Assy)</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 ">
                  <div className="none-nolmalinput">
                    <label htmlFor="">Bomlist file:</label>
                    <small style={{ color: "red" }}>
                      ** Excel from template **
                    </small>
                    <div className="input-group">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="exampleInputFile"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={upload_bomlist_file_assy}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="exampleInputFile"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <MDBTable striped hover bordered small searching={true}>
                      <MDBTableHead>
                        <tr>
                          <th>No.</th>
                          <th>Product No</th>
                          <th>Product No(Sub-Assy)</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Error_message</th>
                          <th>Add/Update</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {excel.map((el, index) => {
                          if (excel.filter((x) => !x.status).length > 0) {
                            var status_up = false;
                          } else {
                            var status_up = true;
                          }
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{el.part_no}</td>
                              <td>{el.product_sub_no}</td>
                              <td>{el.amount}</td>

                              <td>
                                {el.status ? (
                                  <span style={{ color: "green" }}>True</span>
                                ) : (
                                  <span style={{ color: "red" }}>False</span>
                                )}
                              </td>
                              <td>{el.error}</td>
                              <td>
                                {status_up ? (
                                  <span style={{ color: "green" }}>
                                    Success
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>False</span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={set_bomlist_excel}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-add_customer_product">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Add Customer Product</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      disabled={
                        customer_product_mode.mode === "read" ? true : false
                      }
                      value={customer_product_data_temp.customer_item.trim()}
                      onChange={(e) =>
                        setcustomer_product_data_temp({
                          ...customer_product_data_temp,
                          customer_item: e.target.value.trim(),
                          plan_item: product.no.trim(),
                        })
                      }
                      required
                    />
                    <label htmlFor="">Customer item:</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      disabled={
                        customer_product_mode.mode === "read" ? true : false
                      }
                      value={customer_product_data_temp.customer_item_name.trim()}
                      onChange={(e) =>
                        setcustomer_product_data_temp({
                          ...customer_product_data_temp,
                          customer_item_name: e.target.value.trim(),
                        })
                      }
                      required
                    />
                    <label htmlFor="">Customer item name:</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row">
                <div className="col-1"></div>

                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              {customer_product_mode.mode === "edit" ? (
                <>
                  <button
                    disabled={
                      customer_product_data_temp.customer_item === "" ||
                        customer_product_data_temp.customer_item_name === ""
                        ? true
                        : false
                    }
                    type="button"
                    onClick={Add_customer}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save Change
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={
                      customer_product_data_temp.customer_item === "" ||
                        customer_product_data_temp.customer_item_name === ""
                        ? true
                        : false
                    }
                    type="button"
                    onClick={Add_customer}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="modal-add_process_cycle_time">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Add Cycle Time</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <Select
                      placeholder=""
                      options={process_cycle_option}
                      ref={select_process_cycle}
                      value={process_cycle_option.filter((e) => {
                        return e.label === process_cycle_select.process;
                      })}
                      isSearchable
                      isSelected={process_cycle_option.filter((e) => {
                        return e.label === process_cycle_select.process;
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          setprocess_cycle_select({
                            ...process_cycle_select,
                            id: e.value || "",
                            process: e.label || "",
                          });
                        }
                      }}
                    />
                    <label htmlFor="">Process :</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      type="text"
                      className="form-control"
                      id={index}
                      value={process_cycle_select.cycle_time}
                      onChange={(e) =>
                        setprocess_cycle_select({
                          ...process_cycle_select,
                          cycle_time: e.target.value.trim(),
                        })
                      }
                      required
                    />
                    <label htmlFor="">Cycle time :</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>

                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              {process_cycle_select.mode === "edit" ? (
                <>
                  <button
                    disabled={
                      process_cycle_select.id === "" ||
                        process_cycle_select.cycle_time === ""
                        ? true
                        : false
                    }
                    type="button"
                    onClick={Edit_process_cycle_time}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save Change
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={
                      process_cycle_select.id === "" ||
                        process_cycle_select.cycle_time === ""
                        ? true
                        : false
                    }
                    type="button"
                    onClick={Add_process_cycle_time}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </>
              )}
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="modal-unit">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Unit</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={matDefine_unit.unit}
                      onChange={(e) =>
                        setmatDefine_unit({
                          ...matDefine_unit,
                          unit: e.target.value,
                        })
                      }
                    ></input>
                    <label htmlFor="">Unit</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button
                type="button"
                onClick={save_matDefind_unit}
                data-dismiss="modal"
                className="btn btn-primary"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="modal-add_supplier_rerationship">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Add Supplier Relationship</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <Select
                      options={supplier_option}
                      ref={select_supplier}
                      value={supplier_option.filter((e) => {
                        return e.label === supplier_select.supplier;
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          setsupplier_select({
                            ...supplier_select,
                            id: e.id_supplier || "",
                            supplier: e.label || "",
                          });
                        }
                      }}
                    />
                    <label htmlFor="">Supplier :</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      type="text"
                      className="form-control"
                      value={supplier_select.buy_price}
                      onChange={(e) =>
                        setsupplier_select({
                          ...supplier_select,
                          buy_price: e.target.value.trim(),
                        })
                      }
                      required
                    />
                    <label htmlFor="">Buy Price :</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      type="text"
                      className="form-control"
                      value={supplier_select.minimum_order}
                      onChange={(e) =>
                        setsupplier_select({
                          ...supplier_select,
                          minimum_order: e.target.value.trim(),
                        })
                      }
                      required
                    />
                    <label htmlFor="">Minimum order:</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <NumberFormat
                      allowNegative={false}
                      type="text"
                      className="form-control"
                      value={supplier_select.lead_time}
                      onChange={(e) =>
                        setsupplier_select({
                          ...supplier_select,
                          lead_time: e.target.value.trim(),
                        })
                      }
                      required
                    />
                    <label htmlFor="">Lead Time (Days):</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      value={supplier_select.remark}
                      onChange={(e) =>
                        setsupplier_select({
                          ...supplier_select,
                          remark: e.target.value.trim(),
                        })
                      }
                      required
                    />
                    <label htmlFor="">Remark:</label>
                  </div>
                </div>
                <div className="col-1"></div>
              </div>

              <div className="row">
                <div className="col-1"></div>

                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              {supplier_select.mode === "edit" ? (
                <>
                  <button
                    disabled={
                      supplier_select.id === "" ||
                        supplier_select.buy_price === ""
                        ? true
                        : false
                    }
                    type="button"
                    onClick={Edit_Suppier}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save Change
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={
                      supplier_select.id === "" ||
                        supplier_select.buy_price === ""
                        ? true
                        : false
                    }
                    type="button"
                    onClick={Add_Suppier}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </>
              )}

              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-add_machine_rerationship">
        <div className="modal-dialog modal-l">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Add Machine Relationship</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body table-responsive" style={{ whiteSpace: "nowrap", height: '500px' }}>
                <MDBDataTable
                  sortable={false}
                  className="table  "
                  disableRetreatAfterSorting={true}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data_Machine}
                />
              </div>

              <div className="row">
                <div className="col-1"></div>

                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              {machine_select.mode === "edit" ? (
                <>
                  <button
                    disabled={machine_select.id === "" ? true : false}
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save Change
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={machine_select.id === "" ? true : false}
                    type="button"
                    onClick={Add_Machine}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </>
              )}

              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-add_factory_choup">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">โรงชุป</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body table-responsive" style={{ whiteSpace: "nowrap", height: '500px' }}>
                {/*   <MDBDataTable
                  sortable={false}
                  className="table  "
                  disableRetreatAfterSorting={true}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={FacChoupD}
                /> */}
                <TableLEK
                  headTable={FacChoupD.columns}
                  dataTable={FacChoupD.rows}
                  countPage={countPageChoup}
                  countData={countDataChoup}
                  entries={entriesChoup}
                  isSearch={searchChoup}
                  callFnc={GetFactoryChoup}
                />
              </div>

              <div className="row">
                <div className="col-1"></div>

                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              {/* {machine_select.mode === "edit" ? (
                <>
                  <button
                    disabled={machine_select.id === "" ? true : false}
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save Change
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={machine_select.id === "" ? true : false}
                    type="button"
                    onClick={Add_Machine}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </>
              )} */}

              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-add_product_customer_cts">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-label">Add Product Customer</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body table-responsive" style={{ whiteSpace: "nowrap", height: '500px' }}>
                {/*  <MDBDataTable
                  sortable={false}
                  className="table  "
                  disableRetreatAfterSorting={true}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={TableCustomer}
                /> */}
                <TableLEK
                  headTable={TableCustomer.columns}
                  dataTable={TableCustomer.rows}
                  countPage={countPagecustomer}
                  countData={countDatacustomer}
                  entries={entriescustomer}
                  isSearch={searchcustomer}
                  callFnc={GetCustomer}
                />

              </div>

              <div className="row">
                <div className="col-1"></div>

                <div className="col-1"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              {/* {machine_select.mode === "edit" ? (
                <>
                  <button
                    disabled={machine_select.id === "" ? true : false}
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Save Change
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={machine_select.id === "" ? true : false}
                    type="button"
                    onClick={Add_Machine}
                    data-dismiss="modal"
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </>
              )} */}

              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Product_add;
