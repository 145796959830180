import React, { useEffect, useState, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../config";
import {
  getOem,
  getToken,
  getAcademy,
} from "../../Utils/Common";
import NumberFormat from "react-number-format";
import Select from "react-select";
import TableLEK from "../../component/tableLEKTTT/table";

function FGInOutCTS() {
  const [test,settest] = useState({
    test:'',
  });
  const [headTable, setheadTable] = useState(
    [
      {
        label: "Product No",
        field: "no",
        /*        sort: "asc", */
        width: 50,
      },
      {
        label: "Product" + " " + "Name",
        field: "name",
        /*      sort: "asc", */
        width: 100,
      },
      {
        label: "Model Category",
        field: "product_model_id",
        /*      sort: "asc", */
        width: 50,
      },
      {
        label: "Lot",
        field: "lotno",
        /*      sort: "asc", */
        width: 50,
      },
      {
        label: "Min Stock",
        field: "min_prod",
        /*         sort: "asc", */
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        /*    sort: "asc", */
        width: 50,
      },
      {
        label: "Pack",
        field: "pack",
        /*      sort: "asc", */
        width: 50,
      },
      {
        label: "MGT",
        field: "mgt",
        /*      sort: "asc", */
        width: 50,
      }
]
);
const [valueData, setvalueData] = useState([]);
const [countPage, setCountPage] = useState(1);
const [currentPage,setCurrentPage] = useState(1);
const [countData, setCountData] = useState(0);
const [entries, setEntries] = useState(10);
const [search, setSearch] = useState(0);
  const [product_data, setProduct_data] = useState([]);
  const [product_model_option, setproduct_model_option] = useState([]);
  const [model, setModel] = useState([]);
  const [lotno, setLotno] = useState([]);
  const [oem_id] = useState(getOem());
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
    search:''
  });

  const [filler_checkbox, setfiller_checkbox] = useState({
    inventory: false,
    virtual_product: false,
    under_min_lot: false,
  });

  var config_model = {
    method: "get",
    url: Configs.API_URL + "/api/FGInOutCTS/get_Product_Model?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  function compare(a, b) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  const Getlot = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/api/FGInOutCTS/filter_Lot_FG",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      setLotno(response.data);
    })
    .catch(async (error) => {
      console.log(error);
    });
  };

  const Getmodel = async () => {
    axios(config_model)
    .then(async (response) => {
      setModel(response.data);
      const options = [];

      response.data.forEach((el) => {
        var element = {};
        element["value"] = el.id;
        element["label"] = el.name;
        options.push(element);
      });
      options.sort(compare);
      setproduct_model_option(options);
    })
    .catch(async (error) => {
      console.log(error);
    });
  };

  const Gettable = async (page,size,search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if(search){
      setSearch(1);
  }else{
      setSearch(0);
  }
    let temp = {
      id: null,
      no: filler_product.no.trim(),
      name: filler_product.name.trim(),
      product_model_id: filler_product.product_model_id,
      oem_id: oem_id,
      under_min_lot: filler_checkbox.under_min_lot,
      page:page || 1,
      size:size || 10,
      search:filler_product.search.trim()
    };

    await axios({
      method: "post",
      url: Configs.API_URL + "/api/FGInOutCTS/filter_product_",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
    .then(async (response) => {
 /*      response.data.sort((a, b) => b.fg_amount - a.fg_amount); */
 let data_ = response.data.data;
      for (let index = 0; index < data_.length; index++) {
        var packing_standart =
          data_[index].packing_standart === null
            ? 0
            : data_[index].packing_standart;
        var fg_amount =
          data_[index].fg_amount === null
            ? 0
            : data_[index].fg_amount;
        if (
          fg_amount / packing_standart === Infinity ||
          isNaN(fg_amount / packing_standart)
        ) {
          data_[index]["pack"] = fg_amount;
        } else {
          data_[index]["pack"] = Math.ceil(fg_amount / packing_standart);
        }
      }

      //let sorted = data_.sort((a, b) => a.no.localeCompare(b.no));
/*       console.log(sorted) */

       setProduct_data(data_);
       setCountPage(response.data.countPage);
       setCountData(response.data.count)
       setEntries(size);
       settest({...test,test:''})
       test.test = '';
       Swal.close();
    })
    .catch(async (error) => {
      console.log(error);
    });
  };
  
  const selectProduct_model = useRef();

  const clearfillter = async () => {
    await setFiller_product({
      ...filler_product,
      product_model_id: "",
      name: "",
      no: "",
      search:''
    });
    filler_product.product_model_id = "";
    filler_product.name = "";
    filler_product.no = "";
    filler_product.search = "";
    selectProduct_model.current.select.clearValue();

    Gettable(1,10,'search');
  };

  useEffect(() => {
    Gettable(1,10,'search');
    Getmodel();
    Getlot();
  }, []);

  const rowsData = [];
  
  for (var index = 0; index < product_data.length; index++) {
    var FG_amount = product_data[index].fg_amount;

    const rowItem = {};

    if (product_data[index].fg_amount < product_data[index].min_product) {
      var c = "red";
    } else if (product_data[index].fg_amount >= product_data[index].min_product) {
      var c = "black";
    } else {
      var c = "black";
    }

    for (let indexin = 0; indexin < model.length; indexin++) {
      if (
        model[indexin].id == product_data[index].product_model_id &&
        product_data[index].product_model_id !== undefined
      ) {
        rowItem["product_model_id"] = (
          <div style={{ color: c }}>{"" + model[indexin].name}</div>
        );
      }
    }

    var lotShow = [];
    if (product_data[index].lot_no.length > 0) {
      for (let indexlot = 0; indexlot < product_data[index].lot_no.length; indexlot++) {
        lotShow.push(<div style={{ color: c }}>{"" + product_data[index].lot_no[indexlot].lot_no}</div>)
      }

      rowItem["lotno"] = (
        lotShow
      );
    }

    if (FG_amount !== null) {
      rowItem["amount"] = (
      
          <div style={{ color: c,textAlign:'right' }}>
            {
            <NumberFormat
              value={FG_amount}
              displayType={"text"}
              thousandSeparator={true}
            />
    }
          </div>
    
      );
    } else {
      rowItem["amount"] = (
         <> 
        <div style={{ color: c,textAlign:'right' }}>
          {
            '0'
          }
        </div>
      
        </>
      );
    }
    
    rowItem["num"] = <div>{index + 1}</div>;

    rowItem["no"] = product_data[index].no;

    rowItem["name"] = product_data[index].name;

    rowItem["min_prod"] = (
      <div style={{ color: c,textAlign:'right'}}>
        <NumberFormat
          value={
            product_data[index].min_product === null
              ? 0
              : product_data[index].min_product
          }
          displayType={"text"}
          thousandSeparator={true}
        />
      </div>
    );

    rowItem["pack"] = (
      <div style={{ color: c,textAlign:'right' }}>
        <NumberFormat
          value={product_data[index].pack}
          displayType={"text"}
          thousandSeparator={true}
        />
      </div>
    );

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            className="btn btn-xs "
            href={
              "/Warehouse_Management/F_G_In_Out_CTS/mgt/" + product_data[index].id
            }
            key={product_data[index].id}
          >
            <i class="fas fa-pencil-alt" />
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  var go = [];

  go.push(
    {
      label: "No",
      field: "num",
      /*     sort: "asc", */
      width: 50,
    },
    {
      label: "Product No",
      field: "no",
      /*        sort: "asc", */
      width: 50,
    },
    {
      label: "Product" + " " + "Name",
      field: "name",
      /*      sort: "asc", */
      width: 100,
    },
    {
      label: "Model Category",
      field: "product_model_id",
      /*      sort: "asc", */
      width: 50,
    },
    {
      label: "Lot",
      field: "lotno",
      /*      sort: "asc", */
      width: 50,
    },
    {
      label: "Min Stock",
      field: "min_prod",
      /*         sort: "asc", */
      width: 50,
    },
    {
      label: "Amount",
      field: "amount",
      /*    sort: "asc", */
      width: 50,
    },
    {
      label: "Pack",
      field: "pack",
      /*      sort: "asc", */
      width: 50,
    },
    {
      label: "MGT",
      field: "mgt",
      /*      sort: "asc", */
      width: 50,
    }
  );

  const data = {
    columns: go,
    rows: rowsData,
  };
  
  function search_data() {
    Gettable(1,10,'search');
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { isDisabled, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#87CEFA" : "#FFF",
        color: "#000",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  F/G In/Out{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">F/G In/Out</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-0">
            <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.search}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        search: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.no}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        no: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product No.</label>{" "}
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.name.trim()}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        name: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>

              <div
                className="col-12 col-md-6 col-xl-3"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <Select
                    placeholder=""
                    styles={colourStyles}
                    options={product_model_option}
                    defaultValue={filler_product.product_model_id}
                    ref={selectProduct_model}
                    onChange={(e) => {
                      if (e !== null) {
                        setFiller_product({
                          ...filler_product,
                          product_model_id: e.value,
                        });
                        filler_product.product_model_id = e.value;
                      }

                      search_data();
                    }}
                  />
                  <label htmlFor="">Model Category</label>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="ml-4 mb-2 col-4 col-md-3 col-xl-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="ch_under_min_lot"
                  onChange={(event) => {
                    setfiller_checkbox({
                      ...filler_checkbox,
                      under_min_lot: event.currentTarget.checked,
                    });
                    filler_checkbox.under_min_lot = event.currentTarget.checked;
                    Gettable(1,10,'search');
                  }}
                  checked={filler_checkbox.under_min_lot}
                />
                <label htmlFor="ch_under_min_lot">Under min stock</label>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      search_data();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info "
                    onClick={() => {
                      clearfillter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Product</h3>
              <div className="card-tools" />
            </div>

            <div className="card-body ">
            <div style= {{padding:'2%'}}>
            <TableLEK       

                               
                                headTable = {headTable}
                                dataTable = {rowsData}
                                countPage = {countPage}
                                countData = {countData}
                                entries = {entries}
                                isSearch = {search}
                                callFnc ={Gettable}
                                
                                
                                />
                                </div>
           {/*    <div
                className="table-responsive"
                style={{ whiteSpace: "nowrap" }}
              >
                <MDBDataTable
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                  width="1000px"
                />
              </div> */}
            </div>

            <div className="card-footer" />
          </div>
        </section>
      </div>
    </div>
  );
}

export default FGInOutCTS;
