import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Configs from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import {
  Progress,
  Tooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

function Logistic_Planning_Table() {
  const [inputSearch, setInputSearch] = useState({
    so_no: "",
    route_id: "",
  });

  const [showWeek, setShowWeek] = useState(1);

  const [showYear, setShowYear] = useState(1);

  const [searchDate, setSearchDate] = useState({
    startTimestamp: null,
    endTimestamp: null,
  });

  const [searchWeek, setSearchWeek] = useState({
    week: 1,
  });

  const [searchYear, setSearchYear] = useState({
    year: 1,
  });

  const [showDate, setShowDate] = useState({
    startDate: null,
    startMonth: null,
    endDate: null,
    endMonth: null,
    year: null,
    startTimestamp: null,
    endTimestamp: null,
  });

  const [routeDD, setRouteDD] = useState({
    data: [],
  });

  const [resData, setResData] = useState({
    data: [],
  });

  const [dataModal, setDataModal] = useState({
    dayWeek: "",
    data: [],
  });

  const [ocModal, setOcModal] = useState(false);

  const dayOfWeek = [
    "วันจันทร์",
    "วันอังคาร",
    "วันพุธ",
    "วันพฤหัส",
    "วันศุกร์",
    "วันเสาร์",
    "วันอาทิตย์",
  ];

  async function getRouteDropDown() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/GetRouteDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        setRouteDD({ ...routeDD, data: response.data });
        routeDD.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function FilterLogisticPlanning() {
    const temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
      so_no: inputSearch.so_no,
      route_id: inputSearch.route_id,
      startTimestamp: searchDate.startTimestamp,
      endTimestamp: searchDate.endTimestamp,
      // week: searchWeek.week,
      // year: searchYear.year,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/filterLogisticPlanning",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response1) {
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus_sales +
            "/api/sale_order/getLogisticPlanningSO",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: response1.data,
        })
          .then(async function (response2) {
            setResData({
              data: response2.data,
            });

            resData.data = response2.data;
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveBillSort() {
    const saveData = [];

    dataModal.data.forEach((item) => {
      saveData.push({
        id: item.bill_id,
        sort: item.bill_sort,
      });
    });

    let tempData = {
      // company_id: getUser().com,
      // oem_id: getOem(),
      user_id: getUser().fup,
      user_name: `${getUser().name} ${getUser().surname}`,
      data: saveData,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/saveBillSort",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async function (response) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const rowsData = [];
  for (let index = 0; index < dayOfWeek.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["day_of_week"] = dayOfWeek[index];

    const splitData =
      resData.data[
        moment(showDate.startTimestamp).add(index, "day").format("YYYY-MM-DD")
      ];

    if (splitData) {
      const data = [];

      data.push(...splitData);

      const timeData = []
      let deli_time_text = "";
      for (let item of data) {
        if (item.bill_delivery_time) {
          timeData.push(<div>{moment(item.bill_delivery_time).format("HH.mm น.")}</div>)
          deli_time_text += `${moment(item.bill_delivery_time).format("HH.mm น.")}\n`
        } else {
          timeData.push(<div>-</div>)
        }
      }

      rowItem["deli_time"] = timeData;

      rowItem["deli_time_search"] = deli_time_text;

      // rowItem["deli_time"] = data.map((item) => {
      //   return <div>{item.bill_delivery_time ? moment(item.bill_delivery_time).format("HH.mm น.") : ""}</div>;
      // });

      rowItem["so_no"] = data.map((item) => {
        return <div>{item.bill_so_no}</div>;
      });

      let so_no_text = "";

      for (let item of data) {
        so_no_text += `${item.bill_so_no}\n`;
      }

      rowItem["so_no_search"] = so_no_text;

      rowItem["m3"] = data
        .reduce((firstItem, secondItem) => {
          return firstItem + Number(secondItem.so_Data[0]?.so_total_m3 || 0);
        }, 0)
        .toFixed(2);

      rowItem["route"] = data.map((item) => {
        return (
          <div style={{ color: `${item.route_color_code || "#000000"}` }}>
            {item.route_name}
          </div>
        );
      });

      let route_text = "";

      for (let item of data) {
        route_text += `${item.route_name}\n`;
      }

      rowItem["route_search"] = route_text;

      rowItem["ship_to"] = data.map((item) => {
        return (
          <div style={{ color: `${item.route_color_code || "#000000"}` }}>
            {item.so_Data[0]?.so_csh_name}
          </div>
        );
      });

      let ship_to_text = "";

      for (let item of data) {
        ship_to_text += `${item.so_Data[0]?.so_csh_name}\n`;
      }

      rowItem["ship_to_search"] = ship_to_text;

      rowItem["management"] = (
        <div
          className="row"
          style={{ flexWrap: "nowrap", textAlign: "center" }}
        >
          <div className="col-12">
            <a
              key={index}
              id="btn_delete"
              className="btn btn-xs"
              onClick={() => {
                setDataModal({
                  dayWeek: dayOfWeek[index],
                  data: data,
                });
                dataModal.data = data;

                setOcModal(true);
              }}
            >
              <i class="fas fa-pencil-alt"></i>
            </a>
          </div>
        </div>
      );
    }

    rowsData.push(rowItem);
  }

  const planningTable = {
    columns: [
      {
        label: "No",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "วันที่จัดส่ง",
        field: "day_of_week",
        // sort: "asc",
        width: 50,
      },
      {
        label: "เวลา",
        field: "deli_time",
        // sort: "asc",
        width: 50,
      },
      {
        label: "SO No.",
        field: "so_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "M3",
        field: "m3",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Route",
        field: "route",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Ship to",
        field: "ship_to",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  const rowsData1 = [];
  for (let indexIn = 0; indexIn < dataModal.data.length; indexIn += 1) {
    // dataModal.data[indexIn].bill_sort = dataModal.data[indexIn].bill_sort || indexIn + 1;
    dataModal.data[indexIn].bill_sort = indexIn + 1;

    const rowItem1 = {};

    rowItem1["so_no"] = dataModal.data[indexIn].bill_so_no;

    rowItem1["route"] = (
      <div
        style={{
          color: `${dataModal.data[indexIn].route_color_code || "#000000"}`,
        }}
      >
        {dataModal.data[indexIn].route_name}
      </div>
    );

    rowItem1["route_search"] = dataModal.data[indexIn].route_name;

    rowItem1["ship_to"] = (
      <div
        style={{
          color: `${dataModal.data[indexIn].route_color_code || "#000000"}`,
        }}
      >
        {dataModal.data[indexIn].so_Data[0]?.so_csh_name}
      </div>
    );

    rowItem1["ship_to_search"] =
      dataModal.data[indexIn].so_Data[0]?.so_csh_name;

    rowItem1["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-6">
          <a
            key={indexIn + "_up"}
            id="btn_delete"
            className="btn btn-xs"
            onClick={() => {
              if (dataModal.data[indexIn].bill_sort > 1) {
                dataModal.data[indexIn].bill_sort -= 1;

                dataModal.data.forEach((item) => {
                  if (item.bill_id !== dataModal.data[indexIn].bill_id) {
                    item.bill_sort += 1;
                  }
                });
              }

              dataModal.data.sort((a, b) => a.bill_sort - b.bill_sort);

              setDataModal({
                ...dataModal,
                data: dataModal.data,
              });
            }}
          >
            <i class="fas fa-arrow-up"></i>
          </a>
        </div>

        <div className="col-6">
          <a
            key={indexIn + "_down"}
            id="btn_delete"
            className="btn btn-xs"
            onClick={() => {
              if (dataModal.data[indexIn].bill_sort < dataModal.data.length) {
                dataModal.data[indexIn].bill_sort += 1;

                dataModal.data.forEach((item) => {
                  if (item.bill_id !== dataModal.data[indexIn].bill_id) {
                    item.bill_sort -= 1;
                  }
                });
              }

              dataModal.data.sort((a, b) => a.bill_sort - b.bill_sort);

              setDataModal({
                ...dataModal,
                data: dataModal.data,
              });
            }}
          >
            <i class="fas fa-arrow-down"></i>
          </a>
        </div>
      </div>
    );

    rowsData1.push(rowItem1);
  }

  const modalTable = {
    columns: [
      {
        label: "SO No.",
        field: "so_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Route",
        field: "route",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Ship to",
        field: "ship_to",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData1,
  };

  async function clearSearch() {
    setInputSearch({
      so_no: "",
      route_id: "",
    });

    inputSearch.so_no = "";
    inputSearch.route_id = "";

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    setShowWeek(weekNumber);
    setShowYear(currentDate.getFullYear());

    setSearchWeek({ ...searchWeek, week: weekNumber });
    setSearchYear({ ...searchYear, year: currentDate.getFullYear() });
    searchWeek.week = weekNumber;
    searchYear.year = currentDate.getFullYear();

    const firstDay = new Date(
      currentDate.setDate(
        currentDate.getDate() -
          currentDate.getDay() +
          (currentDate.getDay() === 0 ? -6 : 1)
      )
    );

    const lastDay = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7)
    );

    setShowDate({
      ...showDate,
      startDate: firstDay.getDate(),
      startMonth: firstDay.toLocaleString("default", { month: "short" }),
      endDate: lastDay.getDate(),
      endMonth: lastDay.toLocaleString("default", { month: "short" }),
      year: lastDay.getFullYear(),
      startTimestamp: firstDay.setHours(0, 0, 0, 0),
      endTimestamp: lastDay.setHours(23, 59, 59, 999),
    });

    setSearchDate({
      ...searchDate,
      startTimestamp: firstDay.setHours(0, 0, 0, 0),
      endTimestamp: lastDay.setHours(23, 59, 59, 999),
    });

    searchDate.startTimestamp = firstDay.setHours(0, 0, 0, 0);
    searchDate.endTimestamp = lastDay.setHours(23, 59, 59, 999);

    await FilterLogisticPlanning();
  }

  useEffect(async () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    setShowWeek(weekNumber);
    setShowYear(currentDate.getFullYear());

    setSearchWeek({ ...searchWeek, week: weekNumber });
    setSearchYear({ ...searchYear, year: currentDate.getFullYear() });
    searchWeek.week = weekNumber;
    searchYear.year = currentDate.getFullYear();
    // console.log(Math.ceil(Math.floor((new Date() - new Date(new Date().getFullYear(), 0, 1)) / (24 * 60 * 60 * 1000)) / 7));

    // let searchDay = 1;
    // if (week && year) {
    //   searchDay = new Date(
    //     Number(searchYear.year),
    //     0,
    //     Number(searchWeek.week) * 7
    //   );
    // } else {
    //   searchDay = currentDate;
    // }

    const searchDay = currentDate;

    const firstDay = new Date(
      searchDay.setDate(
        searchDay.getDate() -
          searchDay.getDay() +
          (searchDay.getDay() === 0 ? -6 : 1)
      )
    );

    const lastDay = new Date(
      searchDay.setDate(searchDay.getDate() - searchDay.getDay() + 7)
    );

    setShowDate({
      ...showDate,
      startDate: firstDay.getDate(),
      startMonth: firstDay.toLocaleString("default", { month: "short" }),
      endDate: lastDay.getDate(),
      endMonth: lastDay.toLocaleString("default", { month: "short" }),
      year: lastDay.getFullYear(),
      startTimestamp: firstDay.setHours(0, 0, 0, 0),
      endTimestamp: lastDay.setHours(23, 59, 59, 999),
    });

    setSearchDate({
      ...searchDate,
      startTimestamp: firstDay.setHours(0, 0, 0, 0),
      endTimestamp: lastDay.setHours(23, 59, 59, 999),
    });

    searchDate.startTimestamp = firstDay.setHours(0, 0, 0, 0);
    searchDate.endTimestamp = lastDay.setHours(23, 59, 59, 999);

    await getRouteDropDown();
    await FilterLogisticPlanning();
    // await GetEmployeeWorkType();
    // await FilterEmployee();
    // await FilterTask();
    // await FilterEvaluate();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Logistic Planning</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Logistic Planning</li>
                </ol>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={inputSearch.so_no}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        so_no: e.target.value,
                      });

                      inputSearch.so_no = e.target.value;
                    }}
                  />
                  <label>SO No.</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <select
                    type="text"
                    className="form-control"
                    required
                    value={inputSearch.route_id}
                    onChange={(e) => {
                      setInputSearch({
                        ...inputSearch,
                        route_id: e.target.value,
                      });

                      inputSearch.route_id = e.target.value;
                    }}
                  >
                    <option key="" value="">
                      Select a Route
                    </option>

                    {routeDD.data.map((option, index) => (
                      <option key={option.route_id} value={option.route_id}>
                        {option.route_name}
                      </option>
                    ))}
                  </select>
                  <label>Route</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-3">
                <div className="row">
                  <div className="col-2 col-xl-3">
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          Number(showWeek) === 1
                            ? setShowWeek(52)
                            : setShowWeek(Number(showWeek) - 1);

                          const weekDay = new Date(
                            Number(showYear),
                            0,
                            (Number(showWeek) === 1
                              ? Number(52)
                              : Number(showWeek) - 1) * 7
                          );

                          const firstDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() -
                                weekDay.getDay() +
                                (weekDay.getDay() === 0 ? -6 : 1)
                            )
                          );

                          const lastDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() - weekDay.getDay() + 7
                            )
                          );

                          setShowDate({
                            ...showDate,
                            startDate: firstDay.getDate(),
                            startMonth: firstDay.toLocaleString("default", {
                              month: "short",
                            }),
                            endDate: lastDay.getDate(),
                            endMonth: lastDay.toLocaleString("default", {
                              month: "short",
                            }),
                            year: lastDay.getFullYear(),
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week:
                              Number(showWeek) === 1
                                ? 52
                                : Number(showWeek) - 1,
                          });

                          setSearchYear({
                            ...searchYear,
                            year: showYear,
                          });

                          searchWeek.week =
                            Number(showWeek) === 1 ? 52 : Number(showWeek) - 1;
                          searchYear.year = showYear;

                          setSearchDate({
                            ...searchDate,
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          searchDate.startTimestamp = firstDay.setHours(
                            0,
                            0,
                            0
                          );
                          searchDate.endTimestamp = lastDay.setHours(
                            23,
                            59,
                            59
                          );

                          await FilterLogisticPlanning();
                          // await FilterTask();
                          // await FilterEvaluate();
                        }}
                      >
                        <span
                          className="fa fa-chevron-left"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="col-8 col-xl-6">
                    <div className="row">
                      <div className="col">
                        <div
                          style={{
                            position: "relative",
                            padding: "10px 0px",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "1.4rem",
                              margin: "4px 0px 0px 0px",
                            }}
                          >
                            Week
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            style={{
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              if (
                                Number(e.target.value) < 1 &&
                                e.target.value !== ""
                              ) {
                                setShowWeek(1);
                              } else if (
                                Number(e.target.value) > 52 &&
                                e.target.value !== ""
                              ) {
                                setShowWeek(52);
                              } else {
                                setShowWeek(e.target.value);
                              }
                              const weekDay = new Date(
                                Number(showYear),
                                0,
                                (Number(e.target.value) < 1 &&
                                e.target.value !== ""
                                  ? Number(1)
                                  : Number(e.target.value) > 52 &&
                                    e.target.value !== ""
                                  ? Number(52)
                                  : Number(e.target.value)) * 7
                              );

                              const firstDay = new Date(
                                weekDay.setDate(
                                  weekDay.getDate() -
                                    weekDay.getDay() +
                                    (weekDay.getDay() === 0 ? -6 : 1)
                                )
                              );

                              const lastDay = new Date(
                                weekDay.setDate(
                                  weekDay.getDate() - weekDay.getDay() + 7
                                )
                              );

                              setShowDate({
                                ...showDate,
                                startDate: firstDay.getDate(),
                                startMonth: firstDay.toLocaleString("default", {
                                  month: "short",
                                }),
                                endDate: lastDay.getDate(),
                                endMonth: lastDay.toLocaleString("default", {
                                  month: "short",
                                }),
                                year:
                                  firstDay.getMonth() > lastDay.getMonth()
                                    ? lastDay.getFullYear() + 1
                                    : lastDay.getFullYear(),
                                startTimestamp: firstDay.setHours(0, 0, 0, 0),
                                endTimestamp: lastDay.setHours(23, 59, 59, 999),
                              });
                            }}
                            value={showWeek}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-2 col-xl-3"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          Number(showWeek) === 52
                            ? setShowWeek(1)
                            : setShowWeek(Number(showWeek) + 1);

                          const weekDay = new Date(
                            Number(showYear),
                            0,
                            (Number(showWeek) === 52
                              ? Number(1)
                              : Number(showWeek) + 1) * 7
                          );

                          const firstDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() -
                                weekDay.getDay() +
                                (weekDay.getDay() === 0 ? -6 : 1)
                            )
                          );

                          const lastDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() - weekDay.getDay() + 7
                            )
                          );

                          setShowDate({
                            ...showDate,
                            startDate: firstDay.getDate(),
                            startMonth: firstDay.toLocaleString("default", {
                              month: "short",
                            }),
                            endDate: lastDay.getDate(),
                            endMonth: lastDay.toLocaleString("default", {
                              month: "short",
                            }),
                            year: lastDay.getFullYear(),
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week:
                              Number(showWeek) === 52
                                ? 1
                                : Number(showWeek) + 1,
                          });

                          setSearchYear({
                            ...searchYear,
                            year: showYear,
                          });

                          searchWeek.week =
                            Number(showWeek) === 52 ? 1 : Number(showWeek) + 1;
                          searchYear.year = showYear;

                          setSearchDate({
                            ...searchDate,
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          searchDate.startTimestamp = firstDay.setHours(
                            0,
                            0,
                            0
                          );
                          searchDate.endTimestamp = lastDay.setHours(
                            23,
                            59,
                            59
                          );

                          await FilterLogisticPlanning();
                          // await FilterTask();
                          // await FilterEvaluate();
                        }}
                      >
                        <span
                          className="fa fa-chevron-right"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-3">
                <div className="row">
                  <div className="col-2">
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          setShowYear(Number(showYear) - 1);
                          const weekDay = new Date(
                            Number(showYear) - 1,
                            0,
                            Number(showWeek) * 7
                          );

                          const firstDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() -
                                weekDay.getDay() +
                                (weekDay.getDay() === 0 ? -6 : 1)
                            )
                          );

                          const lastDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() - weekDay.getDay() + 7
                            )
                          );

                          setShowDate({
                            ...showDate,
                            startDate: firstDay.getDate(),
                            startMonth: firstDay.toLocaleString("default", {
                              month: "short",
                            }),
                            endDate: lastDay.getDate(),
                            endMonth: lastDay.toLocaleString("default", {
                              month: "short",
                            }),
                            year: lastDay.getFullYear(),
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week: showWeek,
                          });
                          setSearchYear({
                            ...searchYear,
                            year: Number(showYear) - 1,
                          });

                          searchWeek.week = showWeek;
                          searchYear.year = Number(showYear) - 1;

                          setSearchDate({
                            ...searchDate,
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          searchDate.startTimestamp = firstDay.setHours(
                            0,
                            0,
                            0
                          );
                          searchDate.endTimestamp = lastDay.setHours(
                            23,
                            59,
                            59
                          );

                          await FilterLogisticPlanning();
                          // await FilterTask();
                          // await FilterEvaluate();
                        }}
                      >
                        <span
                          className="fa fa-chevron-left"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <div className="col">
                        <div
                          style={{
                            position: "relative",
                            padding: "10px 0px",
                            marginBottom: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "1.4rem",
                              margin: "4px 0px 0px 0px",
                            }}
                          >
                            Year
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            style={{
                              textAlign: "center",
                            }}
                            onChange={(e) => {
                              setShowYear(e.target.value);
                              const weekDay = new Date(
                                Number(e.target.value),
                                0,
                                Number(showWeek) * 7
                              );

                              const firstDay = new Date(
                                weekDay.setDate(
                                  weekDay.getDate() -
                                    weekDay.getDay() +
                                    (weekDay.getDay() === 0 ? -6 : 1)
                                )
                              );

                              const lastDay = new Date(
                                weekDay.setDate(
                                  weekDay.getDate() - weekDay.getDay() + 7
                                )
                              );

                              setShowDate({
                                ...showDate,
                                startDate: firstDay.getDate(),
                                startMonth: firstDay.toLocaleString("default", {
                                  month: "short",
                                }),
                                endDate: lastDay.getDate(),
                                endMonth: lastDay.toLocaleString("default", {
                                  month: "short",
                                }),
                                year: lastDay.getFullYear(),
                                startTimestamp: firstDay.setHours(0, 0, 0, 0),
                                endTimestamp: lastDay.setHours(23, 59, 59, 999),
                              });
                            }}
                            value={showYear}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-2"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div className="form-group">
                      <button
                        type="button"
                        id="btn_clear"
                        className="btn btn-block btn-primary"
                        style={{ width: "auto" }}
                        onClick={async () => {
                          setShowYear(Number(showYear) + 1);
                          const weekDay = new Date(
                            Number(showYear) + 1,
                            0,
                            Number(showWeek) * 7
                          );

                          const firstDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() -
                                weekDay.getDay() +
                                (weekDay.getDay() === 0 ? -6 : 1)
                            )
                          );

                          const lastDay = new Date(
                            weekDay.setDate(
                              weekDay.getDate() - weekDay.getDay() + 7
                            )
                          );

                          setShowDate({
                            ...showDate,
                            startDate: firstDay.getDate(),
                            startMonth: firstDay.toLocaleString("default", {
                              month: "short",
                            }),
                            endDate: lastDay.getDate(),
                            endMonth: lastDay.toLocaleString("default", {
                              month: "short",
                            }),
                            year: lastDay.getFullYear(),
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          setSearchWeek({
                            ...searchWeek,
                            week: showWeek,
                          });
                          setSearchYear({
                            ...searchYear,
                            year: Number(showYear) + 1,
                          });

                          searchWeek.week = showWeek;
                          searchYear.year = Number(showYear) + 1;

                          setSearchDate({
                            ...searchDate,
                            startTimestamp: firstDay.setHours(0, 0, 0, 0),
                            endTimestamp: lastDay.setHours(23, 59, 59, 999),
                          });

                          searchDate.startTimestamp = firstDay.setHours(
                            0,
                            0,
                            0
                          );
                          searchDate.endTimestamp = lastDay.setHours(
                            23,
                            59,
                            59
                          );

                          await FilterLogisticPlanning();
                          // await FilterTask();
                          // await FilterEvaluate();
                        }}
                      >
                        <span
                          className="fa fa-chevron-right"
                          style={{ verticalAlign: "middle" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div
                  style={{
                    position: "relative",
                    padding: "10px 0px",
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  <label
                    style={{
                      fontSize: "1.4rem",
                      margin: "4px 0px 0px 0px",
                    }}
                  >
                    {`${showDate.startDate} ${showDate.startMonth} - ${showDate.endDate} ${showDate.endMonth} ${showDate.year}`}
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      setSearchWeek({
                        ...searchWeek,
                        week: showWeek,
                      });
                      setSearchYear({
                        ...searchYear,
                        year: showYear,
                      });
                      searchWeek.week = showWeek;
                      searchYear.year = showYear;

                      setSearchDate({
                        ...searchDate,
                        startTimestamp: showDate.startTimestamp,
                        endTimestamp: showDate.endTimestamp,
                      });

                      searchDate.startTimestamp = showDate.startTimestamp;
                      searchDate.endTimestamp = showDate.endTimestamp;

                      await FilterLogisticPlanning();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await clearSearch();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Logistic Planning</h4>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12" style={{ overflow: "overlay" }}>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <MDBDataTable
                          sortable={false}
                          className="table table-head-fixed"
                          striped
                          bordered
                          hover
                          fixedHeader
                          data={planningTable}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          isOpen={ocModal}
          toggle={() => {
            setOcModal(false);
          }}
          size="xl"
          className="Modal_Center_LeftRight"
        >
          <ModalHeader
            toggle={() => {
              setOcModal(false);
            }}
          >
            <h3>Logistic Planning : {dataModal.dayWeek}</h3>
          </ModalHeader>

          <ModalBody>
            <div className="table-responsive " style={{ whiteSpace: "nowrap" }}>
              <MDBDataTable
                sortable={false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={modalTable}
                disableRetreatAfterSorting={true}
                // paging ={false}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              className="btn btn-danger"
              onClick={() => {
                setOcModal(false);
              }}
            >
              ยกเลิก
            </Button>

            <Button
              color="btn btn-info"
              onClick={async () => {
                saveBillSort();

                setOcModal(false);
              }}
            >
              บันทึก
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
}

export default Logistic_Planning_Table;
