import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import DatePicker from "react-datepicker";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import moment from "moment";
import { getOem, getToken, getUser, getAcademy } from "../../Utils/Common";
import QRCode2 from "qrcode";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import Select from "react-select";
import TableLEK from "../../component/tableLEKTTT/table";

function MatInOutCTS(params) {
  const [company, setcompany] = useState([]);
  const [materail, setmaterail] = useState([]);
  const [materailfack, setmaterailfack] = useState([]);
  const [getsup, setgetsup] = useState([]);
  /* console.log(getUser().com); */
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);

  const [test, settest] = useState({
    test: 0,
  });

  const [filler_materail, setfiller_materail] = useState({
    material_no: "",
    materail_name: "",
    materail_lot_start_date: "",
    materail_lot_end_date: "",
    is_waitQc: false,
    is_minStock: false,
    is_waitCer: false,
    company_key: "CTS",
    oem_id: getOem(),
    company_id: getUser().com,
    search: ""
  });

  async function SetDate_start(date) {
    setfiller_materail({ ...filler_materail, materail_lot_start_date: date });
  }
  async function SetDate_end(date) {
    setfiller_materail({ ...filler_materail, materail_lot_end_date: date });
  }

  const Custom_Input_date = ({ value, onClick }) => (
    <div className="input-group">
      <input
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  const getSupplier = async () => {
    let tmp = {
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL + "/api/rawMatInOut/getSupplier",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tmp,
    })
      .then(function (response) {
        setgetsup(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        /*  console.log(response.data); */
        setcompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const Getmaterail = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    console.log(page, size);
    if ((filler_materail.is_waitQc === false && filler_materail.is_minStock === false && filler_materail.is_waitCer === false) === true ? true : test.test === 0) {
      if (search) {
        setSearch(1);
      } else {
        setSearch(0);
      }
      var get_filler_materail = {
        material_no: filler_materail.material_no.trim(),
        materail_name: filler_materail.materail_name.trim(),
        materail_lot_start_date: filler_materail.materail_lot_start_date,
        materail_lot_end_date:
          filler_materail.materail_lot_end_date ||
          filler_materail.materail_lot_start_date,
        is_waitQc: filler_materail.is_waitQc,
        is_minStock: filler_materail.is_minStock,
        is_waitCer: filler_materail.is_waitCer,
        company_key: "CTS",
        oem_id: getOem(),
        company_id: getUser().com,
        page: page || 1,
        size: size || 10,
        search: filler_materail.search.trim()
      };
  
      /*  console.log(filler_materail); */
      axios({
        method: "post",
        url: Configs.API_URL + "/api/rawMatInOut/filter_",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: get_filler_materail,
      })
        .then(function (response) {
          /*           response.data.sort((a,b)=> a.plate_number.localeCompare(b.plate_number)); */
          response.data.data.sort((a, b) =>
            a.material_no.localeCompare(b.material_no)
          );
          setCountPage(response.data.countPage);
          setCountData(response.data.count);
          setmaterailfack(response.data.data)
        
          let datapang = []
          if (filler_materail.is_waitQc === false && filler_materail.is_minStock === false && filler_materail.is_waitCer === false) {
            datapang = response.data.data
           
          }else{
            datapang = response.data.data.filter((item,i) => {return i >= ((page-1) * size) && i < (size*page)})
          }
          setmaterail(datapang);
         
          setEntries(size);
      console.log(countPage);

          settest({ ...test, test: test.test+1 })
          test.test = test.test+1;
          Swal.close();
      
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
    }else{
      if (search) {
        setSearch(1);
      } else {
        setSearch(0);
      }
   let datapang = materailfack.filter((item,i) => {return i >= ((page-1) * size) && i < (size*page)})
   setmaterail(datapang);
   setEntries(size);
  setCountPage(Math.ceil(countData / size));
      Swal.close();
    }
    
  };

  useEffect(() => {
    GetCompany();
    Getmaterail(1, 10, 'search');
    getSupplier();
  }, []);

  const Clearfilter = async () => {
    await setfiller_materail({
      ...filler_materail,
      material_no: "",
      materail_name: "",
      materail_lot_start_date: "",
      materail_lot_end_date: "",
      is_waitQc: false,
      is_minStock: false,
      is_waitCer: false,
      search:''
    });

    filler_materail.material_no = "";
    filler_materail.materail_name = "";
    filler_materail.materail_lot_start_date = "";
    filler_materail.materail_lot_end_date = "";
    filler_materail.is_waitQc = false;
    filler_materail.is_minStock = false;
    filler_materail.is_waitCer = false;
    filler_materail.search = '';
    Getmaterail(1, 10, 'search');
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const rowsData = [];

  for (var index = 0; index < materail.length; index++) {
    const rowItem = {};
   /*  rowItem["no"] = index + 1; */
    rowItem["material_code"] = materail[index].material_no;
    rowItem["material_image"] = (
      <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            materail[index].material_image !== null
              ? Configs.API_URL_IMG + materail[index].material_image
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
    rowItem["material_qr"] = (
      <div style={{ textAlign: "center" }}>
        <QRCode
          value={materail[index].material_no}
          size={60}
          fgColor={"#000000"}
          bgColor={"#ffffff"}
          level={"M"}
          renderAs={"canvas"}
          includeMargin={false}
          imageSettings={{
            src: logoTTT.imgs,
            height: 10,
            width: 10,
            x: null,
            y: null,
            excavate: true,
          }}
        />
      </div>
    );
    var material_lot_qc_passed = materail[index].material_lot_list_qc_passed;

    var material_lot_wait_qc = materail[index].material_lot_list_wait_qc;

    var min_stock = materail[index].material_min_stock || 0;
    var material_name = "";
    if (materail[index].material_size !== null) {
      material_name += materail[index].material_size + " ";
    }
    if (materail[index].material_spec !== null) {
      material_name += materail[index].material_spec;
    }
    var color_alert = "#";
    var sum_tag_weight_pass = 0;
    var sum_tag_weight_wait = 0;
    var wtqc = 0;
    var wtqcp = 0;

    for (let wqc of material_lot_qc_passed) {
      wtqc += wqc.material_tag_weight || 0;
    }
    for (let qcp of material_lot_wait_qc) {
      wtqcp += qcp.material_tag_weight || 0;
    }
    if (wtqc + wtqcp < min_stock) {
      color_alert = "red";
    }
    rowItem["material_lot_list_qc_passed"] = material_lot_qc_passed.map(
      (el, i) => {
        sum_tag_weight_pass += el.material_tag_weight || 0;
        if (i + 1 === material_lot_qc_passed.length) {
          return (
            <div style={{ whiteSpace: "nowrap", color: color_alert,textAlign:'right'}}>
              <>
                {numberWithCommas(sum_tag_weight_pass)}
                {" กก."}
              </>
            </div>
          );
        }
      }
    );

    rowItem["material_lot_list_wait_qc"] = material_lot_wait_qc.map((el, i) => {
      sum_tag_weight_wait += el.material_tag_weight || 0;
      if (i + 1 === material_lot_wait_qc.length) {
        return (
          <div style={{ whiteSpace: "nowrap", color: color_alert ,textAlign:'right'}}>
            <>
              {numberWithCommas(sum_tag_weight_wait)}
              {" กก."}
            </>
          </div>
        );
      }
    });

    rowItem["weight_by_tag"] =
    <div style={{  textAlign: 'right' }}>
        <a style={{ color: color_alert }}>
          {numberWithCommas(sum_tag_weight_pass + sum_tag_weight_wait) + " กก."}
        </a>
      </div>
    rowItem["material_name"] = (
      <>
        <a /* style={{ color: color_alert }} */>{materail[index].material_name}</a>
      </>
    );


    rowItem["material_min_stock"] = (

      <div style={{  textAlign: 'right' }}>
        <a style={{ color: color_alert }}>
          {materail[index].material_min_stock}
        </a>
      </div>

    );

    rowItem["material_amount"] = (
      <>
        <a style={{ color: color_alert }}>{materail[index].material_amount}</a>
      </>
    );
    var stock_coil_no = material_lot_qc_passed.concat(material_lot_wait_qc);
    rowItem["material_lot_list_wait_cer"] = stock_coil_no.map((el, i) => {
      if (el.material_is_certificate !== true) {
        return (
          <div style={{ whiteSpace: "nowrap", /* color: color_alert */ }}>
            <>{el.material_coil_no}</>
          </div>
        );
      }
    });

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Raw_Mat_In_Out_CTS/mgt/" +
              materail[index].material_id
            }
            key={materail[index].material_id}
            className=" btn btn-xs " /* onClick={editpage.bind(this,materail[index].id)} */
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  const [headTable, setheadTable] = useState(
    [
      /* {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      }, */
      {
        label: "Material No.",
        field: "material_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "material_image",
        sort: "asc",
        width: 50,
      },
      /*     {
          label: "QR",
          field: "material_qr",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Material Name",
        field: "material_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min Stock",
        field: "material_min_stock",
        sort: "asc",
        width: 50,
      },
      /*      {
            label: "Amount",
            field: "material_amount",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "น้ำหนักตามป้าย (กก.)",
        field: "weight_by_tag",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qc Pass",
        field: "material_lot_list_qc_passed",
        sort: "asc",
        width: 50,
      },
      {
        label: "Wait Qc",
        field: "material_lot_list_wait_qc",
        sort: "asc",
        width: 50,
      },
      {
        label: "Wait Cer",
        field: "material_lot_list_wait_cer",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ]
  );
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "Material No.",
        field: "material_code",
        sort: "asc",
        width: 50,
      },
      {
        label: "Image",
        field: "material_image",
        sort: "asc",
        width: 50,
      },
      /*     {
          label: "QR",
          field: "material_qr",
          sort: "asc",
          width: 50,
        }, */
      {
        label: "Material Name",
        field: "material_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "Min Stock",
        field: "material_min_stock",
        sort: "asc",
        width: 50,
      },
      /*      {
            label: "Amount",
            field: "material_amount",
            sort: "asc",
            width: 50,
          }, */
      {
        label: "น้ำหนักตามป้าย (กก.)",
        field: "weight_by_tag",
        sort: "asc",
        width: 50,
      },
      {
        label: "Qc Pass",
        field: "material_lot_list_qc_passed",
        sort: "asc",
        width: 50,
      },
      {
        label: "Wait Qc",
        field: "material_lot_list_wait_qc",
        sort: "asc",
        width: 50,
      },
      {
        label: "Wait Cer",
        field: "material_lot_list_wait_cer",
        sort: "asc",
        width: 50,
      },

      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  // function QcTagGenerate() {
  //   var materail_list = [];
  //   materail_list = materail;
  //   if (materail_list < 1) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "ไม่มีข้อมูล",
  //     });
  //   } else {
  //     /*  console.log(moment(new Date()).format('MMM')) */
  //     const doc = new jsPDF("l", "mm", "a4");

  //     const stock_month = [
  //       { th: "มกราคม", eng: "January" },
  //       { th: "กุมภาพันธ์", eng: "February" },
  //       { th: "มีนาคม", eng: "March" },
  //       { th: "เมษายน", eng: "April" },
  //       { th: "พฤษภาคม", eng: "May" },
  //       { th: "มิถุนายน", eng: "June" },
  //       { th: "กรกฎาคม", eng: "July" },
  //       { th: "สิงหาคม", eng: "August" },
  //       { th: "กันยายน", eng: "September" },
  //       { th: "ตุลาคม", eng: "October" },
  //       { th: "พฤศจิกายน", eng: "November" },
  //       { th: "ธันวาคม", eng: "December" },
  //     ];

  //     doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
  //     doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
  //     doc.addFont("Rocki", "bold");
  //     doc.setFont("THSarabunNew");
  //     doc.setFontSize(15);

  //     /*   doc.rect(5,5,286,200); */

  //     doc.rect(0, 0, 297, 210);

  //     //บนซ้าย

  //     //บนขวา
  //     /*  doc.rect(148.5,0,148.5,105); 
  //   //ล่างซ้าย
  //   doc.rect(0,105,148.5,105); 
  //   //ล่างขวา
  //   doc.rect(148.5,105,148.5,105); 
  //    */
  //     /* doc.rect(0,0,145,100);    
  //   doc.rect(145,0,1,100); */
  //     var Qc_passed = [];
  //     var material_lot_list_qc_passed_list = [];
  //     for (let index = 0; index < materail.length; index++) {
  //       Qc_passed = materail[index].material_lot_list_qc_passed;

  //       for (let item of Qc_passed) {
  //         var supplier = getsup.filter((e) => {
  //           return e.id === item.material_supplier;
  //         });
  //         for (let i of supplier) {
  //           var getsupplier = i.full_name;
  //         }
  //         let as = {
  //           tracking_no: item.tracking_no,
  //           material_actual_weight: item.material_actual_weight,
  //           material_amount: item.material_amount,
  //           material_coil_no:
  //             item.material_ref_coil_no === null
  //               ? item.material_coil_no
  //               : item.material_ref_coil_no,
  //           material_grade: item.material_grade,
  //           material_invoice_no: item.material_invoice_no,
  //           material_location: item.material_location,
  //           material_qc_approve_person: item.material_qc_approve_person,
  //           material_qc_date: item.material_qc_date,
  //           material_qc_status: item.material_qc_status,
  //           material_supplier: getsupplier,
  //           material_supplier_coil: item.material_supplier_coil,
  //           material_tag_weight: item.material_tag_weight,
  //           material_weight_actual: item.material_weight_actual,
  //           material_no: materail[index].material_no || "-",
  //           material_dimention: materail[index].material_dimention || "-",
  //           material_name: materail[index].material_name || "-",
  //         };

  //         material_lot_list_qc_passed_list.push(as);
  //       }
  //     }
  //     console.log(material_lot_list_qc_passed_list);

  //     /*  console.log(img,logoTTT.imgs) */
  //     var year_th = parseInt(moment(new Date()).format("YY")) + 43;

  //     var counts = 0;
  //     var numloop = 0;

  //     for (
  //       let loop = numloop;
  //       loop < material_lot_list_qc_passed_list.length;
  //       loop = loop + 4
  //     ) {
  //       var row = 0;
  //       for (let i = 0; row < 4; i = i + 4) {
  //         if (!material_lot_list_qc_passed_list[loop + row]) {
  //         } else {
  //           var material_dimention = "";
  //           var material_supplier = "";
  //           var material_no = "";
  //           var material_grade = "";
  //           var material_coin_no = "";
  //           var material_invoice_no = "";
  //           var material_qc_approve_person = "";
  //           var material_tag_weight = "";
  //           var material_actual_weight = "";
  //           var material_supplier_coil = "";

  //           var img_qr = "";
  //           /* console.log(material_lot_list_qc_passed_list[loop+row].tracking_no) */
  //           var track =
  //             material_lot_list_qc_passed_list[loop + row].tracking_no;
  //           QRCode2.toDataURL(track, function (err, url) {
  //             img_qr = url;
  //           });

  //           if (
  //             material_lot_list_qc_passed_list[loop + row].material_dimention
  //               .length > 0
  //           ) {
  //             var dimention =
  //               material_lot_list_qc_passed_list[loop + row]
  //                 .material_dimention[0].dimention || "-";
  //           }

  //           material_no =
  //             material_lot_list_qc_passed_list[loop + row].material_no || "-";
  //           material_dimention = dimention;
  //           material_supplier =
  //             material_lot_list_qc_passed_list[loop + row].material_supplier ||
  //             "-";
  //           material_grade =
  //             material_lot_list_qc_passed_list[loop + row].material_grade ||
  //             "-";
  //           material_coin_no =
  //             material_lot_list_qc_passed_list[loop + row].material_coil_no;
  //           material_invoice_no =
  //             material_lot_list_qc_passed_list[loop + row].material_invoice_no;
  //           material_qc_approve_person =
  //             material_lot_list_qc_passed_list[loop + row]
  //               .material_qc_approve_person || "-";
  //           material_tag_weight =
  //             material_lot_list_qc_passed_list[loop + row]
  //               .material_tag_weight || "";
  //           material_actual_weight =
  //             material_lot_list_qc_passed_list[loop + row]
  //               .material_actual_weight || "";
  //           material_supplier_coil =
  //             material_lot_list_qc_passed_list[loop + row]
  //               .material_supplier_coil;
  //           console.log(material_supplier, material_grade);
  //           var month_th = stock_month.filter((e) => {
  //             return (
  //               e.eng ===
  //               moment(
  //                 material_lot_list_qc_passed_list[loop + row].material_qc_date
  //               ).format("MMMM")
  //             );
  //           });
  //           console.log(month_th, "sss");
  //           var x = 0;
  //           var y = 0;
  //           if (row == 0) {
  //             x = 0;
  //             y = 0;
  //           } else if (row == 1) {
  //             x = 148.5;
  //             y = 0;
  //           } else if (row == 2) {
  //             x = 0;
  //             y = 105;
  //           } else if (row == 3) {
  //             x = 148.5;
  //             y = 105;
  //           }

  //           doc.addImage(img_qr, "JPEG", 95 + x, 58 + y, 50, 50);
  //           doc.setDrawColor(255, 255, 255);
  //           doc.setFillColor(255, 255, 255);
  //           /*  doc.rect(115.5+x,77+y,10,10,"FD");  */
  //           doc.setDrawColor(0, 0, 0);
  //           /*  doc.addImage(img, 'JPEG',117+x,79+y, 7,7); */
  //           doc.setLineDash([3, 4], 0);
  //           doc.rect(0 + x, 0 + y, 148.5, 105);
  //           doc.setLineDash([0, 0], 0);
  //           doc.rect(8 + x, 5 + y, 90, 8);
  //           doc.text(47 + x, 10 + y, "MATERIAL");
  //           doc.rect(8 + x, 13 + y, 35, 8);
  //           doc.text(25 + x, 18 + y, "VENDOR", "center");
  //           doc.rect(43 + x, 13 + y, 55, 8);
  //           doc.text(70 + x, 18 + y, material_supplier.toString(), "center");
  //           doc.rect(8 + x, 21 + y, 35, 8);
  //           doc.text(25 + x, 26 + y, "GRADE", "center");
  //           doc.rect(43 + x, 21 + y, 55, 8);
  //           doc.text(70 + x, 26 + y, material_grade.toString(), "center");

  //           doc.rect(98 + x, 5 + y, 43, 24);
  //           doc.setFontSize(30);
  //           doc.text(120 + x, 20 + y, "SoC FREE", "center");
  //           doc.text(120 + x, 20 + y, "SoC FREE", "center");
  //           doc.text(120 + x, 20 + y, "SoC FREE", "center");

  //           doc.setFontSize(15);
  //           doc.rect(8 + x, 29 + y, 35, 8);
  //           doc.text(25 + x, 34 + y, "DIMENSION", "center");
  //           doc.rect(43 + x, 29 + y, 98, 8);
  //           doc.text(45 + x, 34 + y, material_dimention.toString(), "left");

  //           doc.rect(98 + x, 29 + y, 43, 8);
  //           doc.text(
  //             119.5 + x,
  //             34 + y,
  //             material_supplier_coil.toString(),
  //             "center"
  //           );

  //           doc.rect(8 + x, 37 + y, 35, 8);

  //           doc.text(25 + x, 42 + y, "WEIGHT", "center");
  //           doc.setFontSize(10);

  //           doc.text(45.5 + x, 39.5 + y, "Tag", "center");
  //           /*  doc.text(74+x,39.5+y,"Actual","center"); */
  //           doc.setFontSize(15);
  //           doc.rect(43 + x, 37 + y, 55, 8);
  //           /*  doc.rect(43+x,37+y,27,8); */
  //           doc.text(
  //             70 + x,
  //             43 + y,
  //             material_tag_weight
  //               .toString()
  //               .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  //             "center"
  //           );
  //           /*  doc.text(84+x,43+y,material_actual_weight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),"center"); */

  //           doc.rect(98 + x, 37 + y, 43, 8);

  //           if (
  //             material_lot_list_qc_passed_list[loop + row].material_qc_date !==
  //             null
  //           ) {
  //             doc.text(120 + x, 42 + y, "เดือน " + month_th[0].th, "center");
  //           }

  //           doc.rect(8 + x, 45 + y, 35, 8);
  //           doc.text(25 + x, 50 + y, "COIL NO.", "center");
  //           doc.rect(43 + x, 45 + y, 55, 8);
  //           doc.text(45 + x, 50 + y, material_coin_no.toString(), "left");
  //           doc.rect(8 + x, 53 + y, 35, 8);
  //           doc.text(25 + x, 58 + y, "INVOICE NO.", "center");
  //           doc.rect(43 + x, 53 + y, 55, 8);
  //           doc.text(45 + x, 58 + y, material_invoice_no.toString(), "left");

  //           doc.rect(98 + x, 45 + y, 43, 16);
  //           doc.setFontSize(30);
  //           doc.text(120 + x, 56 + y, "OK", "center");
  //           doc.text(120 + x, 56 + y, "OK", "center");
  //           doc.text(120 + x, 56 + y, "OK", "center");

  //           doc.setFontSize(15);

  //           doc.text(
  //             34 + x,
  //             72 + y,
  //             material_qc_approve_person.toString(),
  //             "center"
  //           );
  //           doc.text(
  //             34 + x,
  //             73 + y,
  //             "..........................................",
  //             "center"
  //           );
  //           doc.text(34 + x, 79 + y, "QC.", "center");
  //           if (
  //             material_lot_list_qc_passed_list[loop + row].material_qc_date ===
  //             null
  //           ) {
  //             doc.text(80 + x, 72 + y, "ไม่ระบุ", "center");
  //           } else {
  //             doc.text(
  //               80 + x,
  //               72 + y,
  //               moment(
  //                 material_lot_list_qc_passed_list[loop + row].material_qc_date
  //               )
  //                 .format("DD/MM/YY")
  //                 .toString(),
  //               "center"
  //             );
  //           }
  //           doc.text(
  //             80 + x,
  //             73 + y,
  //             "..........................................",
  //             "center"
  //           );
  //           doc.text(80 + x, 79 + y, "DATE", "center");

  //           doc.text(
  //             35 + x,
  //             100 + y,
  //             "F-QC2-01 Rev No.:1 Date:27/12/53",
  //             "center"
  //           );
  //         }

  //         row = row + 1;
  //         counts = material_lot_list_qc_passed_list.length - 4;

  //         /*  console.log(material_lot_list_qc_passed_list,"กฟห") */
  //       }

  //       if (loop < counts) {
  //         doc.addPage("l");
  //       }
  //     }
  //     window.open(doc.output("bloburl"));
  //   }
  // }

  return (
    <div className="wrapper">
      {/* <Header />
      <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Raw Material IN/OUT{"  "}
                  <a target="_blank" href={getAcademy()}>
                    <i class="fa fa-info-circle"></i>
                  </a>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Raw Material IN/OUT
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-3">
            <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_materail.search}
                    onChange={(e) => {
                      setfiller_materail({
                        ...filler_materail,
                        search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_materail.material_no}
                    onChange={(e) => {
                      setfiller_materail({
                        ...filler_materail,
                        material_no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Mat No.</label>{" "}
                </div>
              </div>
              <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_materail.materail_name}
                    onChange={(e) => {
                      setfiller_materail({
                        ...filler_materail,
                        materail_name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Mat Name</label>{" "}
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      Getmaterail(1, 10, 'search');
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => Clearfilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-3">
              <div
                className="col-12 col-md-12 col-xl-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filler_materail.materail_lot_start_date}
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => SetDate_start(date)}
                    selectsStart
                    startDate={filler_materail.materail_lot_start_date}
                    endDate={filler_materail.materail_lot_end_date}
                    customInput={<Custom_Input_date />}
                  />
                  <label htmlFor="">QC Date Start</label>
                </div>
              </div>

              <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <div
                className="col-12 col-md-12 col-xl-2"
                style={{ "z-index": "99" }}
              >
                <div className="form-group ">
                  <DatePicker
                    selected={filler_materail.materail_lot_end_date}
                    dateFormat={"dd-MM-yyyy"}
                    onChange={async (date) => SetDate_end(date)}
                    selectsEnd
                    startDate={filler_materail.materail_lot_start_date}
                    endDate={filler_materail.materail_lot_end_date}
                    minDate={filler_materail.materail_lot_start_date}
                    customInput={<Custom_Input_date />}
                  ></DatePicker>
                  <label htmlFor="">QC Date End</label>
                </div>
              </div>
              <div className="col-1"></div>
              <div className="col-3 col-md-2 col-xl-1  mt-3">
                {/*                <div className="form-group"> */}
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="is_waitCer"
                  name="is_waitCer"
                  value="is_waitCer"
                  checked={filler_materail.is_waitCer}
                  onChange={(event) => {
                    //console.log(event.currentTarget.checked);
                    setfiller_materail({
                      ...filler_materail,
                      is_waitCer: event.currentTarget.checked,
                    });
                    filler_materail.is_waitCer = event.currentTarget.checked;
                    settest({ ...test, test:0 })
                    test.test =0;
                    Getmaterail(1, 10, 'search');
                  }}
                />
                {/*  {console.log(  product.is_virtual)} */}
                <label htmlFor="is_waitCer">Wait Cer.</label>
                {/*            </div> */}
              </div>
              <div className="col-3 col-md-2 col-xl-1  mt-3">
                {/*                <div className="form-group"> */}
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="is_waitQc"
                  name="is_waitQc"
                  value="is_waitQc"
                  checked={filler_materail.is_waitQc}
                  onChange={(event) => {
                    //console.log(event.currentTarget.checked);
                    setfiller_materail({
                      ...filler_materail,
                      is_waitQc: event.currentTarget.checked,
                    });
                    filler_materail.is_waitQc = event.currentTarget.checked;
                    settest({ ...test, test:0 })
                    test.test =0;
                    Getmaterail(1, 10, 'search');
                  }}
                />
                {/*  {console.log(  product.is_virtual)} */}
                <label htmlFor="is_waitQc">Wait Qc</label>
                {/*            </div> */}
              </div>

              <div className="col-3 col-md-2 col-xl-1 mt-3">
                {/*  <div className="form-group"> */}
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="is_minStock"
                  name="is_minStock"
                  value="is_minStock"
                  checked={filler_materail.is_minStock}
                  onChange={(event) => {
                    //console.log(event.currentTarget.checked);
                    setfiller_materail({
                      ...filler_materail,
                      is_minStock: event.currentTarget.checked,
                    });
                    filler_materail.is_minStock = event.currentTarget.checked;
                    settest({ ...test, test:0 })
                    test.test =0;
                    Getmaterail(1, 10, 'search');
                  }}
                />
                {/*  {console.log(  product.is_virtual)} */}
                <label htmlFor="is_minStock">Min Stock</label>
                {/*  </div> */}
              </div>

              {/* <div className="col-4 col-md-3 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                       onClick={() => QcTagGenerate()} 
                    >
                       Report Tag
                    </button>
                  </div>
                </div> */}
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Raw Mat In/Out</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive "
              style={{ whiteSpace: "nowrap" }}
            >
               <TableLEK


headTable={headTable}
dataTable={rowsData}
countPage={countPage}
countData={countData}
entries={entries}
isSearch={search}
callFnc={Getmaterail}


/>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
      {/*   <Footter/> */}
    </div>
  );
}
export default MatInOutCTS;
