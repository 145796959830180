import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../config";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
// import Sales_Order from "./Component/Sales_Order";
import DatePicker from "react-datepicker";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";
import CN_Products_Component from "./Component/CN_Products_Component";

function Product_CN_EV({ mode }) {
  const { id } = useParams();
  const closeModalCustomer = useRef();
  const [disable, setdisable] = useState(null);
  const [pageMode, setpageMode] = useState("");
  const [Invoice, setInvoice] = useState({});

  const [on_change_cusName, setOn_change_cusName] = useState({
    data: "",
  });

  const [inputDataComponent, setInputDataComponent] = useState({
    data: [],
  });

  const [errorListComponent, setErrorListComponent] = useState({
    data: [],
  });

  const [functionSaveComponent, setFunctionSaveComponent] = useState({
    data: null,
    getProduct: null
  });

  const [modalInputCustomer, setModalInputCustomer] = useState({
    ref_id: "",
  });

  const [invoiceDropDown, setInvoiceDropDown] = useState({
    data: [],
  });

  const [shipDropDown, setShipDropDown] = useState({
    data: [],
    dropdownOld: [],
    dropdown: [],
  });

  const [shippingByDropDown, setShippingByDropDown] = useState({
    data: [],
  });

  const [modalCustomer, setModalCustomer] = useState(false);

  const [customerDD, setCustomerDD] = useState({
    data: [],
  });
  const [customerNameDD, setCustomerNameDD] = useState({
    data: [],
  });

  const [customerTypeData, setCustomerTypeData] = useState({
    data: [],
  });

  const clickCloseModalCustomer = () => {
    closeModalCustomer.current.click();
  };

  useEffect(async () => {
    setpageMode("Edit");
    await GetCustomerType();
    if (mode === "view") {
      setdisable(true);
      setpageMode("View");
    }

    await GetInvoiceRef();
    await getCustomerDropDown();

    if (id) {
      await GetProductCNById();
    }
  }, []);

  async function GetCustomerType() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com
    }
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerType",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(async (response) => {
        setCustomerTypeData({
          ...customerTypeData,
          data: response.data,
        });
        customerTypeData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const clearDataOnChange_CtId = async () => {
    setInvoice({
      ...Invoice,
      pc_ref_no: "",
      so_cus_name_id: "",
      so_cus_name: "",
      so_cus_house_number: "",
      so_cus_building: "",
      so_swine: "",
      so_soi: "",
      so_road: "",
      so_sub_district: "",
      so_district: "",
      so_province: "",
      so_postcode: "",
      so_contact_name: "",
      so_contact_email: "",
      so_contact_tel: "",
      so_ship_contract_name: "",
      so_csh_id: "",
      so_csh_name: "",
      so_ship_tel: "",
      so_ship_house_number: "",
      so_ship_building: "",
      so_ship_swine: "",
      so_ship_soi: "",
      so_ship_road: "",
      so_ship_sub_district: "",
      so_ship_district: "",
      so_ship_province: "",
      so_ship_postcode: "",
    });
    Invoice.pc_ref_no = "";
    Invoice.so_cus_name = "";
    Invoice.so_cus_house_number = "";
    Invoice.so_cus_building = "";
    Invoice.so_swine = "";
    Invoice.so_soi = "";
    Invoice.so_road = "";
    Invoice.so_sub_district = "";
    Invoice.so_district = "";
    Invoice.so_province = "";
    Invoice.so_postcode = "";
    Invoice.so_contact_name = "";
    Invoice.so_cus_name_id = "";
    Invoice.so_contact_email = "";
    Invoice.so_contact_tel = "";
    Invoice.so_ship_contract_name = "";
    Invoice.so_csh_id = "";
    Invoice.so_csh_name = "";
    Invoice.so_ship_tel = "";
    Invoice.so_ship_house_number = "";
    Invoice.so_ship_building = "";
    Invoice.so_ship_swine = "";
    Invoice.so_ship_soi = "";
    Invoice.so_ship_road = "";
    Invoice.so_ship_sub_district = "";
    Invoice.so_ship_district = "";
    Invoice.so_ship_province = "";
    Invoice.so_ship_postcode = "";
  }

  const GetProductCNById = async () => {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      pc_id: id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product_cn/GetProductCNById",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        if (response.data && response.data.length > 0) {
          const res = response.data[0];
          setInvoice({
            ...Invoice,
            pc_id: res.pc_id,
            sr_no: res.pc_no,
            pc_ref_no: res.pc_ref_no,
            sr_date: res.pc_date ? new Date(res.pc_date) : "",
            inv_id: res.pc_ref_id,
            pc_ref_id: res.pc_ref_id,
            inv_no:
              invoiceDropDown?.data.length > 0
                ? invoiceDropDown?.data?.find(
                  (el) => el.inv_id === res.pc_ref_id
                )?.inv_no || ""
                : "",
            so_delivery_date: res.pc_ref_date ? new Date(res.pc_ref_date) : "",

            so_id: res.inv_so_id,
            so_no: res.inv_so_no,

            so_cus_house_number: res.pc_cus_house_number || "",
            so_cus_building: res.pc_cus_building || "",
            so_swine: res.pc_swine || "",
            so_soi: res.pc_soi || "",
            so_road: res.pc_road || "",
            so_sub_district: res.pc_sub_district || "",
            so_district: res.pc_district || "",
            so_province: res.pc_province || "",
            so_postcode: res.pc_postcode || "",
            ///////
            so_cus_name: res.pc_cus_name || "",
            so_cus_name_id: res.pc_cus_id || null,
            so_contact_name: res.pc_contact_name || "",
            so_contact_email: res.pc_contact_email || "",
            so_contact_tel: res.pc_contact_tel || "",
            ///////
            so_ship_contract_name: res.inv_ship_contract_name || "",
            so_csh_id: res.pc_ccp_id,
            so_csh_name:
              res.inv_csh_id && shipDropDown.data.length > 0
                ? shipDropDown.data?.find(
                  (el) => el.csh_cus_id === res.inv_csh_id
                )?.csh_name || ""
                : "",
            so_ship_tel: res.inv_ship_tel || "",
            ///////
            so_ship_house_number: res.inv_ship_house_number || "",
            so_ship_building: res.inv_ship_building || "",
            so_ship_swine: res.inv_ship_swine || "",
            so_ship_soi: res.inv_ship_soi || "",
            so_ship_road: res.inv_ship_road || "",
            so_ship_sub_district: res.inv_ship_sub_district || "",
            so_ship_district: res.inv_ship_district || "",
            so_ship_province: res.inv_ship_province || "",
            so_ship_postcode: res.inv_ship_postcode || "",
            inv_cus_remark: res.pc_cus_remark || "",
            inv_lang: res.pc_lang === "TH" ? true : false,
            inv_incl_vat: res.pc_incl_vat || false,
            inv_final_discount:
              res.pc_final_discount_percent && parseFloat(res.pc_final_discount_percent) > 100
                ? 100
                : res.pc_final_discount_percent || "",
            inv_final_discount_bath:
              res.pc_final_discount && parseFloat(res.pc_final_discount) > 100
                ? 100
                : res.pc_final_discount || "",
            inv_shipping_cost: res.pc_shipping_cost || "",
            inv_total_befor_vat: res.pc_total_befor_vat || "",
            inv_vat: res.pc_vat || "",
            inv_total_price: res.pc_total_price || "",
            inv_remark: res.pc_remark || "",
            so_ds_id: res.inv_ds_id || null,
            inv_deposit: res.inv_deposit || "",
            so_ct_id: res.pc_ct_id || "fd5acfc9-6d49-45fc-b7bf-aba6e2536b93"

          });

          if (res.pc_cus_name) {
            setOn_change_cusName({ data: res.pc_cus_name });
            on_change_cusName.data = res.pc_cus_name;
          }
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function GetInvoiceRef() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/GetInvoiceRef",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setInvoiceDropDown({ data: response.data });
        invoiceDropDown.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const getCustomerDropDown = async () => {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerAndNameDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log('response.data :>> ', response.data);
        setCustomerDD({ ...customerDD, data: response.data });
        customerDD.data = response.data;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const GetInvoiceById = async () => {
    let temp = {
      user_id: getUser().fup,
      oem_id: getOem(),
      company_id: getUser().com,
      inv_id: Invoice.pc_ref_id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/invoice/GetInvoiceById",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        console.log("response", response.data[0].inv_csh_id);
        // console.log('shipDropDown.data?.find(el => el.csh_cus_id === res.inv_csh_id)', shipDropDown.data?.find(el => el.csh_cus_id === ));
        if (response.data && response.data.length > 0) {
          const res = response.data[0];
          setInvoice({
            ...Invoice,
            inv_id: res.inv_id,
            inv_no: res.inv_no,
            so_id: res.inv_so_id,
            so_no: res.inv_so_no,
            so_delivery_date: res.inv_date ? new Date(res.inv_date) : "",
            so_cus_house_number: res.inv_cus_house_number || "",
            so_cus_building: res.inv_cus_building || "",
            so_swine: res.inv_swine || "",
            so_soi: res.inv_soi || "",
            so_road: res.inv_road || "",
            so_sub_district: res.inv_sub_district || "",
            so_district: res.inv_district || "",
            so_province: res.inv_province || "",
            so_postcode: res.inv_postcode || "",
            ///////
            so_cus_name: res.inv_cus_name || "",
            so_cus_name_id: res.inv_cus_id,
            so_contact_name: res.inv_contact_name || "",
            so_contact_email: res.inv_contact_email || "",
            so_contact_tel: res.inv_contact_tel || "",
            ///////
            so_ship_contract_name: res.inv_ship_contract_name || "",
            so_csh_id: res.inv_csh_id,
            so_csh_name:
              res.inv_csh_id && shipDropDown.data.length > 0
                ? shipDropDown.data?.find(
                  (el) => el.csh_cus_id === res.inv_csh_id
                )?.csh_name || ""
                : "",
            so_ship_tel: res.inv_ship_tel || "",
            ///////
            so_ship_house_number: res.inv_ship_house_number || "",
            so_ship_building: res.inv_ship_building || "",
            so_ship_swine: res.inv_ship_swine || "",
            so_ship_soi: res.inv_ship_soi || "",
            so_ship_road: res.inv_ship_road || "",
            so_ship_sub_district: res.inv_ship_sub_district || "",
            so_ship_district: res.inv_ship_district || "",
            so_ship_province: res.inv_ship_province || "",
            so_ship_postcode: res.inv_ship_postcode || "",
            inv_cus_remark: res.inv_cus_remark || "",
            inv_lang: res.inv_lang === "TH" ? true : false,
            inv_incl_vat: res.inv_incl_vat,
            inv_final_discount:
              res.inv_final_discount_percent && parseFloat(res.inv_final_discount_percent) > 100
                ? 100
                : res.inv_final_discount_percent || "",
            inv_final_discount_bath:
              res.inv_final_discount && parseFloat(res.inv_final_discount) > 100
                ? 100
                : res.inv_final_discount || "",
            inv_shipping_cost: res.inv_shipping_cost || "",
            inv_total_befor_vat: res.inv_total_befor_vat || "",
            inv_vat: res.inv_vat || "",
            inv_total_price: res.inv_total_price || "",
            inv_remark: res.inv_remark || "",
            so_ds_id: res.inv_ds_id || null,
            inv_deposit: res.inv_deposit || "",
            so_ct_id: res.inv_ct_id || "fd5acfc9-6d49-45fc-b7bf-aba6e2536b93",
            is_change_id_ref: false,
          });

          if (res.inv_cus_name) {
            setOn_change_cusName({ data: res.inv_cus_name });
            on_change_cusName.data = res.inv_cus_name;
          }
          setModalInputCustomer({
            ...modalInputCustomer,
            contact: res.inv_ccp_id,
            ship: res.inv_csh_id,
          });
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const clearInvoiceRef = async () => {
    setInvoice({
      ...Invoice,
      pc_ref_no: "",
      inv_id: "",
      inv_no: "",
      so_id: "",
      so_no: "",
      so_delivery_date: "",
      so_cus_house_number: "",
      so_cus_building: "",
      so_swine: "",
      so_soi: "",
      so_road: "",
      so_sub_district: "",
      so_district: "",
      so_province: "",
      so_postcode: "",
      ///////
      so_cus_name: "",
      so_cus_name_id: "",
      so_contact_name: "",
      so_contact_email: "",
      so_contact_tel: "",
      ///////
      so_ship_contract_name: "",
      so_csh_id: "",
      so_csh_name: "",
      so_ship_tel: "",
      ///////
      so_ship_house_number: "",
      so_ship_building: "",
      so_ship_swine: "",
      so_ship_soi: "",
      so_ship_road: "",
      so_ship_sub_district: "",
      so_ship_district: "",
      so_ship_province: "",
      so_ship_postcode: "",
      inv_cus_remark: "",
      inv_lang: "",
      inv_incl_vat: "",
      inv_final_discount: "",
      inv_shipping_cost: "",
      inv_total_befor_vat: "",
      inv_vat: "",
      inv_total_price: "",
      inv_remark: "",
      so_ds_id: "",
      inv_deposit: "",
    });

    setInputDataComponent({ data: [] });
    inputDataComponent.data = [];
    await functionSaveComponent.getProduct();
  };

  const clearCustomer = async () => {
    setInvoice({
      ...Invoice,
      so_cus_name: "",
      so_cus_house_number: "",
      so_cus_building: "",
      so_swine: "",
      so_soi: "",
      so_road: "",
      so_sub_district: "",
      so_district: "",
      so_province: "",
      so_postcode: "",
      inv_cus_remark: "",
      so_contact_name: "",
      so_contact_email: "",
      so_contact_tel: "",
    });
    Invoice.so_cus_name = "";
    Invoice.so_cus_house_number = "";
    Invoice.so_cus_building = "";
    Invoice.so_swine = "";
    Invoice.so_soi = "";
    Invoice.so_road = "";
    Invoice.so_sub_district = "";
    Invoice.so_district = "";
    Invoice.so_province = "";
    Invoice.so_postcode = "";
    Invoice.inv_cus_remark = "";
    Invoice.so_contact_name = "";
    Invoice.so_contact_email = "";
    Invoice.so_contact_tel = "";

    await clearInvoiceRef();
  };

  const addCustomerDetails = async () => {
    const cus_id = Invoice.so_cus_name_id;
    const cus_name_id = Invoice.so_csh_id;
    const tempAddress = customerDD.data?.find((el) => el.cus_id === cus_id);
    const tempCustomer =
      tempAddress.customerList.length > 0
        ? tempAddress.customerList.find((el) => el.ccp_id === cus_name_id)
        : [];
    let checkCusCommon = false;
    if (Invoice.so_ct_id === "7095dc2b-efeb-433e-af3f-449e9e90bdf2") {
      checkCusCommon = true;
    }
    // console.log('tempAddress, tempCustomer', tempAddress, tempCustomer);
    setInvoice({
      ...Invoice,
      so_cus_name: tempAddress.cus_name || "",
      so_cus_house_number: tempAddress[checkCusCommon ? "cus_house_number" : "cus_branch_house_number"] || "",
      so_cus_building: tempAddress[checkCusCommon ? "cus_building" : "cus_branch_building"] || "",
      so_swine: tempAddress[checkCusCommon ? "cus_swine" : "cus_branch_swine"] || "",
      so_soi: tempAddress[checkCusCommon ? "cus_soi" : "cus_branch_soi"] || "",
      so_road: tempAddress[checkCusCommon ? "cus_road" : "cus_branch_road"] || "",
      so_sub_district: tempAddress[checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"] || "",
      so_district: tempAddress[checkCusCommon ? "cus_district" : "cus_branch_district"] || "",
      so_province: tempAddress[checkCusCommon ? "cus_province" : "cus_branch_province"] || "",
      so_postcode: tempAddress[checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] || "",
      inv_cus_remark: tempAddress.cus_remark || "",
      so_contact_name: tempCustomer.ccp_name || "",
      so_contact_email: tempCustomer.ccp_email || "",
      so_contact_tel: tempCustomer.ccp_tel || "",
    });
    Invoice.so_cus_name = tempAddress.cus_name || "";
    Invoice.so_cus_house_number = tempAddress[checkCusCommon ? "cus_house_number" : "cus_branch_house_number"] || "";
    Invoice.so_cus_building = tempAddress[checkCusCommon ? "cus_building" : "cus_branch_building"] || "";
    Invoice.so_swine = tempAddress[checkCusCommon ? "cus_swine" : "cus_branch_swine"] || "";
    Invoice.so_soi = tempAddress[checkCusCommon ? "cus_soi" : "cus_branch_soi"] || "";
    Invoice.so_road = tempAddress[checkCusCommon ? "cus_road" : "cus_branch_road"] || "";
    Invoice.so_sub_district = tempAddress[checkCusCommon ? "cus_sub_district" : "cus_branch_sub_district"] || "";
    Invoice.so_district = tempAddress[checkCusCommon ? "cus_district" : "cus_branch_district"] || "";
    Invoice.so_province = tempAddress[checkCusCommon ? "cus_province" : "cus_branch_province"] || "";
    Invoice.so_postcode = tempAddress[checkCusCommon ? "cus_postcode" : "cus_branch_postcode"] || "";
    Invoice.inv_cus_remark = tempAddress.cus_remark || "";
    Invoice.so_contact_name = tempCustomer.ccp_name || "";
    Invoice.so_contact_email = tempCustomer.ccp_email || "";
    Invoice.so_contact_tel = tempCustomer.ccp_tel || "";

    if (tempAddress.cus_name) {
      setOn_change_cusName({ data: tempAddress.cus_name });
      on_change_cusName.data = tempAddress.cus_name;
    }
  };

  const checkErrorForSave = async () => {
    let error_list = [];
    // console.log('quotation_data', quotation_data);

    if (Invoice.so_cus_name) {
      Invoice.so_cus_name = Invoice.so_cus_name?.trim();
    }
    if (Invoice.pc_ref_no) {
      Invoice.pc_ref_no = Invoice.pc_ref_no?.trim();
    }
    if (Invoice.so_contact_name) {
      Invoice.so_contact_name = Invoice.so_contact_name?.trim();
    }
    if (Invoice.so_contact_email) {
      Invoice.so_contact_email = Invoice.so_contact_email?.trim();
    }
    if (Invoice.so_contact_tel) {
      Invoice.so_contact_tel = Invoice.so_contact_tel?.trim();
    }

    if (Invoice.so_cus_house_number) {
      Invoice.so_cus_house_number = Invoice.so_cus_house_number?.trim();
    }
    if (Invoice.so_cus_building) {
      Invoice.so_cus_building = Invoice.so_cus_building?.trim();
    }
    if (Invoice.so_swine) {
      Invoice.so_swine = Invoice.so_swine?.trim();
    }
    if (Invoice.so_soi) {
      Invoice.so_soi = Invoice.so_soi?.trim();
    }
    if (Invoice.so_road) {
      Invoice.so_road = Invoice.so_road?.trim();
    }
    if (Invoice.so_sub_district) {
      Invoice.so_sub_district = Invoice.so_sub_district?.trim();
    }
    if (Invoice.so_district) {
      Invoice.so_district = Invoice.so_district?.trim();
    }
    if (Invoice.so_province) {
      Invoice.so_province = Invoice.so_province?.trim();
    }
    if (Invoice.inv_remark) {
      Invoice.inv_remark = Invoice.inv_remark?.trim();
    }
    if (Invoice.inv_cus_remark) {
      Invoice.inv_cus_remark = Invoice.inv_cus_remark?.trim();
    }

    if (Invoice.inv_final_discount) {
      Invoice.inv_final_discount =
        typeof Invoice.inv_final_discount === "string"
          ? parseFloat(Invoice.inv_final_discount?.replace(/,/g, ""))
          : Invoice.inv_final_discount;
    }
    if (Invoice.inv_shipping_cost) {
      Invoice.inv_shipping_cost =
        typeof Invoice.inv_shipping_cost === "string"
          ? parseFloat(Invoice.inv_shipping_cost?.replace(/,/g, ""))
          : Invoice.inv_shipping_cost;
    }
    if (Invoice.inv_total_befor_vat) {
      Invoice.inv_total_befor_vat =
        typeof Invoice.inv_total_befor_vat === "string"
          ? parseFloat(Invoice.inv_total_befor_vat?.replace(/,/g, ""))
          : Invoice.inv_total_befor_vat;
    }
    if (Invoice.inv_vat) {
      Invoice.inv_vat =
        typeof Invoice.inv_vat === "string"
          ? parseFloat(Invoice.inv_vat?.replace(/,/g, ""))
          : Invoice.inv_vat;
    }
    if (Invoice.inv_total_price) {
      Invoice.inv_total_price =
        typeof Invoice.inv_total_price === "string"
          ? parseFloat(Invoice.inv_total_price?.replace(/,/g, ""))
          : Invoice.inv_total_price;
    }

    if (on_change_cusName.data) {
      if (on_change_cusName.data?.trim() !== Invoice.so_cus_name) {
        Invoice.so_cus_name_id = "";
      } else {
        const check = customerDD?.data?.find(
          (el) => el.cus_name === Invoice.so_cus_name
        );

        if (check) {
          Invoice.so_cus_name_id = check.cus_id;
        }
      }
    } else {
      Invoice.so_cus_name_id = null;
    }

    if (!Invoice.so_cus_name) {
      let temp_err = {
        message: `กรุณากรอก Customer Name`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_contact_name) {
      let temp_err = {
        message: `กรุณากรอก Customer Contact Name`,
      };
      error_list.push(temp_err);
    }

    if (!Invoice.so_contact_tel) {
      let temp_err = {
        message: `กรุณากรอก Customer Contact Tel`,
      };
      error_list.push(temp_err);
      /* if ((Invoice.so_contact_tel?.replace(/-/g, "").trim()).length !== 10) {
        let temp_err = {
          message: `Contact Tel ต้องมี 10 หลัก`,
        };
        error_list.push(temp_err);
      } */
    }

    if (Invoice.so_postcode && Invoice.so_postcode?.length !== 5) {
      let temp_err = {
        message: `กรุณากรอกรหัสไปรษณีย์ให้ครบ 5 หลัก`,
      };
      error_list.push(temp_err);
    }

    return error_list;
  };

  async function saveOption() {
    Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let error_list = [];

        error_list = await checkErrorForSave();

        await functionSaveComponent.data();
        error_list.push(...errorListComponent.data);
        // console.log('inputDataTab2', inputDataTab2);

        if (error_list.length > 0) {
          var err_message = "";

          for (var e = 0; e < error_list.length; e++) {
            err_message += "<br/>" + error_list[e].message;
          }

          Swal.fire("Error", err_message, "error");
        } else {
          Swal.fire({
            title: "Saving",
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 1000,
            onOpen: () => {
              Swal.showLoading();
            },
          }).then(async () => {
            const temp = {
              company_id: getUser().com,
              oem_id: getOem(),
              user_id: getUser().fup,
              user_name: getUser().name + " " + getUser().surname,
              pc_id: id,
              data: Invoice,
              products: inputDataComponent,
            };
            await axios({
              method: "post",
              url: Configs.API_URL_cuteplus + `/api/product_cn/updateProductCN`,
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp,
            })
              .then(async function (response) {
                // console.log('response saveDrafQuotation -', response.data);
              })
              .then(async function (response) {
                Swal.fire({
                  icon: "success",
                  title: "บันทึกข้อมูลสำเร็จ",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000,
                }).then(() => {
                  window.location.href = "/Warehouse_Management/Product_CN";
                });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          });
        }
      }
    });
  }

  function cancelOption() {
    if (mode === "add" || mode === "edit") {
      Swal.fire({
        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `ใช่`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `ไม่`,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/Warehouse_Management/Product_CN";
        }
      });
    } else {
      window.location.href = "/Warehouse_Management/Product_CN";
    }
  }

  const CustomInput = ({ value, onClick, isDis }) => (
    <div className="input-group">
      <input
        disabled={isDis}
        type="text"
        className="form-control float-left"
        onClick={onClick}
        value={value}
      />{" "}
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </div>
  );

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1 style={{ whiteSpace: "nowrap" }}>Product CN {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">Product CN</li>
                  <li className="breadcrumb-item active">{pageMode}</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              {mode === "edit" || mode === "add" ? (
                <>
                  <div className="col-12 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-success"
                      onClick={async () => {
                        // await getSonoRef();
                        // console.log(Invoice);
                        // deleteImagesAndFilesOfProduct()

                        await saveOption();
                      }}
                    >
                      บันทึก
                    </button>
                  </div>
                  <div className="col-12 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={async () => {
                        cancelOption();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-md-4 col-xl-2">
                    <button
                      type="button"
                      className="btn btn-block btn-danger"
                      onClick={async () => {
                        cancelOption();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  {/* <div> */}
                  <div className="card-header">
                    <div
                      className="row"
                      style={{ justifyContent: "start", alignItems: "start" }}
                    >
                      <div
                        className="col-12"
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <h3
                          className="card-title"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Product CN
                        </h3>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-header">
                    <h3 className="card-title">Sales Order (Sales)</h3>
                  </div> */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={true}
                            value={
                              mode === "add" ? "Auto Generate" : Invoice.sr_no
                            }
                          // onChange={(e) => {
                          //   console.log(Invoice)
                          //   // setInvoice({
                          //   //   ...Invoice,
                          //   //   so_no: e.target.value,
                          //   // });
                          // }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            SR No.
                            {/* {Invoice.so_no === "" ||
                              Invoice.so_no === null ? (
                                <span style={{ color: "red" }}> *</span>
                              ) : (
                                ""
                              )} */}
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="form-group">
                          <DatePicker
                            required
                            disabled={true}
                            selected={Invoice.sr_date}
                            locale="th"
                            wrapperClassName="w-100"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setInvoice({
                                ...Invoice,
                                sr_date: date,
                              });
                            }}
                            selectsStart
                            showYearDropdown
                            showMonthDropdown
                            // startDate={new Date().setDate(new Date().getDate() + 30)}
                            minDate={new Date()}
                            customInput={<CustomInput isDis={true} />}
                          />
                          <label>SR Date</label>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-3 col-xl-3">
                        <div
                          class="form-group"
                          style={{ display: "flex ", alignItems: "baseline" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.inv_no}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                inv_no: e.target.value,
                              });
                            }}
                          />
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            Ref. Document
                          </label>

                          <a
                            style={{ cursor: "pointer", marginLeft: "10px" }}
                            // hidden={disable}
                            onClick={() => {
                              if (!disable) {
                                setModalInputCustomer({
                                  ...modalInputCustomer,
                                  ref_id: "",
                                });
                                setModalCustomer(true);
                              }
                            }}
                          >
                            <i class="fas fa-search"></i>
                          </a>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 col-xl-3" style={{ zIndex: "999" }}>
                        <div className="form-group" style={{ zIndex: "999" }}>
                          <DatePicker
                            style={{ zIndex: "999" }}
                            required
                            disabled={disable}
                            selected={Invoice.so_delivery_date}
                            locale="th"
                            wrapperClassName="w-100"
                            dateFormat={"dd-MM-yyyy"}
                            onChange={async (date) => {
                              setInvoice({
                                ...Invoice,
                                so_delivery_date: date,
                              });
                            }}
                            popperPlacement="bottom"
                            popperModifiers={{
                              flip: {
                                behavior: ["bottom"], // don't allow it to flip to be above
                              },
                              preventOverflow: {
                                enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                              },
                              hide: {
                                enabled: false, // turn off since needs preventOverflow to be enabled
                              },
                            }}
                            selectsStart
                            showYearDropdown
                            showMonthDropdown
                            // startDate={new Date().setDate(new Date().getDate() + 30)}
                            minDate={new Date()}
                            customInput={<CustomInput isDis={disable} />}
                          />
                          <label>Ref. Document Date</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">Customer Detail</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-3">
                        <div class="form-group">
                          <select
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_ct_id}
                            onChange={async (e) => {
                              const value = e.target.value;
                              const ct_name = customerTypeData.data?.find(el => el.ct_id === value).ct_name
                              setInvoice({
                                ...Invoice,
                                so_ct_id: value,
                                so_ct_name: ct_name,
                                so_change_customer: true
                              });
                              Invoice.so_ct_id = value;
                              Invoice.so_ct_name = ct_name;
                              Invoice.so_change_customer = true;

                              await functionSaveComponent.getProduct();
                              await clearDataOnChange_CtId();

                              setInvoice({
                                ...Invoice,
                                so_change_customer: false,
                                inv_total_price: 0,
                                inv_total_befor_vat: 0,
                                inv_vat: 0,
                                quo_product_total: 0
                              });
                              Invoice.so_change_customer = false;
                              Invoice.inv_total_price = 0;
                              Invoice.inv_total_befor_vat = 0;
                              Invoice.inv_vat = 0;
                              Invoice.quo_product_total = 0;
                            }}
                          >
                            <option value="" disabled>
                              -- กรุณาเลือก --
                            </option>

                            {customerTypeData.data.map((data, index) => {
                              return (
                                <option key={data.ct_id} value={data.ct_id}>
                                  {data.ct_name}
                                </option>
                              );
                            })}
                          </select>
                          <label
                            style={{
                              lineHeight: "normal",
                              wordBreak: "break-all",
                            }}
                          >
                            ประเภทลูกค้า
                            {Invoice.so_ct_id === "" ||
                              Invoice.so_ct_id === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-5 col-xl-4">
                        <div
                          className="form-group"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: ".5rem",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            required="false"
                            disabled={disable}
                            value={Invoice.so_cus_name}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_cus_name: e.target.value,
                              });
                            }}
                          />
                          <label htmlFor="">
                            Customer Name
                            {Invoice.so_cus_name === "" ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <a
                            data-toggle="modal"
                            data-target="#modalQuotationCustomer"
                            style={{ cursor: "pointer" }}
                            hidden={disable}
                            onClick={() => {
                              setInvoice({
                                ...Invoice,
                                so_cus_name_id_sup: "",
                                so_ccp_id_sup: "",
                              });
                              Invoice.so_cus_name_id_sup = "";
                              Invoice.so_ccp_id_sup = "";
                            }}
                          >
                            <i class="fas fa-search"></i>
                          </a>
                        </div>
                      </div>
                      <div className="col-1 col-md-1 col-xl-1"></div>
                      <div className="col-12 col-md-5 col-xl-5">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.pc_ref_no}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                pc_ref_no: e.target.value,
                              });
                              Invoice.pc_ref_no = e.target.value;
                            }}
                          />
                          <label>Reference No.</label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-4 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_contact_name}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_contact_name: e.target.value,
                              });
                              Invoice.so_contact_name = e.target.value;
                            }}
                          />
                          <label>
                            Customer Contact Name
                            {Invoice.so_contact_name === "" ||
                              Invoice.so_contact_name === null ? (
                              <span style={{ color: "red" }}> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-5">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_contact_email}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_contact_email: e.target.value,
                              });
                              Invoice.so_contact_email = e.target.value;
                            }}
                          />
                          <label>Contact Email</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-5">
                        <div class="form-group">
                          <unput
                            type="text"
                            className="form-control"
                            // format={"###-###-####"}
                            required
                            disabled={disable}
                            value={Invoice.so_contact_tel}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_contact_tel: e.target.value,
                              });
                              Invoice.so_contact_tel = e.target.value;
                            }}
                          />
                          <label>Contact Tel.</label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <h4>
                          <b> ที่อยู่</b>
                          {/* {true ? (
                                                        <span style={{ color: "red" }}> *</span>
                                                    ) : (
                                                        ""
                                                    )} */}
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-2 col-xl-1">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_cus_house_number}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_cus_house_number: e.target.value,
                              });
                              Invoice.so_cus_house_number = e.target.value;
                            }}
                          />
                          <label>บ้านเลขที่</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 col-xl-2">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_cus_building}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_cus_building: e.target.value,
                              });
                              Invoice.so_cus_building = e.target.value;
                            }}
                          />
                          <label>อาคาร</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-2 col-xl-1">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_swine}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_swine: e.target.value,
                              });
                              Invoice.so_swine = e.target.value;
                            }}
                          />
                          <label>หมู่</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-xl-3">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_soi}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_soi: e.target.value,
                              });
                              Invoice.so_soi = e.target.value;
                            }}
                          />
                          <label>ซอย</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-xl-3">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_road}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_road: e.target.value,
                              });
                              Invoice.so_road = e.target.value;
                            }}
                          />
                          <label>ถนน</label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-4 col-xl-3">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_sub_district}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_sub_district: e.target.value,
                              });
                              Invoice.so_sub_district = e.target.value;
                            }}
                          />
                          <label>แขวง / ตำบล</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-xl-3">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_district}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_district: e.target.value,
                              });
                              Invoice.so_district = e.target.value;
                            }}
                          />
                          <label>เขต / อำเภอ</label>
                        </div>
                      </div>

                      <div className="col-12 col-md-5 col-xl-4">
                        <div class="form-group">
                          <input
                            type="text"
                            className="form-control"
                            required
                            disabled={disable}
                            value={Invoice.so_province}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_province: e.target.value,
                              });
                              Invoice.so_province = e.target.value;
                            }}
                          />
                          <label>จังหวัด</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-2">
                        <div class="form-group">
                          <NumberFormat
                            type="text"
                            decimalScale={0}
                            maxLength={5}
                            allowNegative={false}
                            disabled={disable}
                            className="form-control"
                            required
                            value={Invoice.so_postcode}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                so_postcode: e.target.value,
                              });
                              Invoice.so_postcode = e.target.value;
                            }}
                          />
                          <label>รหัสไปรษณีย์</label>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-12 col-xl-10">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            rows={2}
                            disabled={disable}
                            style={{
                              height: "100px",
                              fontSize: "25px",
                            }}
                            value={Invoice.inv_cus_remark}
                            onChange={(e) => {
                              setInvoice({
                                ...Invoice,
                                inv_cus_remark: e.target.value,
                              });
                              Invoice.inv_cus_remark = e.target.value;
                            }}
                            defaultValue=""
                            required
                          />
                          <label>Remark</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">Product</h3>
                  </div>
                  <div className="card-body">
                    <CN_Products_Component
                      id={id}
                      mode={mode}
                      isDisabled={disable}
                      Invoice_data={Invoice}
                      setInvoice_data={setInvoice}
                      inputDataTab2={inputDataComponent}
                      setInputDataTab2={setInputDataComponent}
                      errorListTab2={errorListComponent}
                      setErrorListTab2={setErrorListComponent}
                      functionSaveTab2={functionSaveComponent}
                      setFunctionSaveTab2={setFunctionSaveComponent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            size="sm"
            className="Modal_Center_LeftRight"
            isOpen={modalCustomer}
            toggle={() => {
              setModalInputCustomer({
                ...modalInputCustomer,
                ref_id: "",
              });

              modalInputCustomer.ref_id = "";

              setModalCustomer(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setModalInputCustomer({
                  ...modalInputCustomer,
                  ref_id: "",
                });

                modalInputCustomer.ref_id = "";

                setModalCustomer(false);
              }}
            >
              <h4 className="modal-title" id="modalCustomer">
                <b>Ref. Document</b>
              </h4>
            </ModalHeader>

            <ModalBody>
              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      disabled={disable}
                      value={modalInputCustomer.ref_id}
                      onChange={(e) => {
                        setModalInputCustomer({
                          ...modalInputCustomer,
                          ref_id: e.target.value,
                        });

                        modalInputCustomer.ref_id = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        --Select Ref.--
                      </option>

                      {invoiceDropDown?.data?.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.inv_id}
                            id={data.inv_id}
                          >
                            {data.inv_no}
                          </option>
                        );
                      })}
                    </select>
                    <label
                      style={{
                        lineHeight: "normal",
                        wordBreak: "break-all",
                      }}
                    >
                      Ref. Document
                    </label>
                  </div>
                </div>
              </div>
            </ModalBody>

            <ModalFooter style={{ justifyContent: "space-between" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={async () => {
                  setModalCustomer(false);
                }}
              >
                ยกเลิก
              </button>

              <button
                type="button"
                className="btn btn-success"
                onClick={async () => {
                  setInvoice({
                    ...Invoice,
                    pc_ref_id: modalInputCustomer.ref_id,
                    is_change_id_ref: true,
                  });
                  Invoice.is_change_id_ref = true;
                  Invoice.pc_ref_id = modalInputCustomer.ref_id;

                  if (Invoice.pc_ref_id) {
                    await GetInvoiceById();
                  } else {
                    await clearInvoiceRef();
                  }
                  setModalCustomer(false);
                }}
              >
                บันทึก
              </button>
            </ModalFooter>
          </Modal>

          <div
            className="modal fade"
            id="modalQuotationCustomer"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalfoodRedisterLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-md"
              role="document"
            >
              <div className="modal-content">
                {/* Header */}
                <div className="modal-header">
                  <h4 className="modal-title" id="modalfoodRedisterLabel">
                    <b>Customer</b>
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {/* Body */}
                <div className="modal-body">
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <h5>
                        <b>Customer</b>
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group">
                        <select
                          type="text"
                          className="form-control"
                          required
                          value={Invoice.so_cus_name_id_sup}
                          onChange={(e) => {
                            const customerName = e.target.value
                              ? customerDD?.data.find(
                                (el) => el.cus_id === e.target.value
                              ).customerList || ""
                              : [];
                            setInvoice({
                              ...Invoice,
                              so_cus_name_id_sup: e.target.value,
                            });
                            Invoice.so_cus_name_id_sup = e.target.value;

                            console.log("customerName", customerName);
                            setCustomerNameDD({
                              ...customerNameDD,
                              data: customerName,
                            });
                            customerNameDD.data = customerName;
                          }} // customerDD.data
                        >
                          <option key="" value="">
                            Select a Customer
                          </option>
                          {customerDD?.data?.map((item, index) => {
                            return (
                              <option key={index} value={item.cus_id}>
                                {item.cus_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <h5>
                        <b>Customer Name</b>
                        <span style={{ color: "red" }}> *</span>
                      </h5>
                    </div>
                    <div className="col-md-12">
                      <div class="form-group">
                        <select
                          type="text"
                          className="form-control"
                          required
                          disabled={
                            mode !== "view"
                              ? !Invoice.so_cus_name_id_sup
                                ? true
                                : false
                              : true
                          }
                          value={Invoice.so_ccp_id_sup}
                          onChange={(e) => {
                            setInvoice({
                              ...Invoice,
                              so_ccp_id_sup: e.target.value,
                            });
                            Invoice.so_ccp_id_sup = e.target.value;
                          }}
                        >
                          <option key="" value="">
                            Select a Customer Name
                          </option>
                          {customerNameDD?.data?.map((item, index) => {
                            return (
                              <option key={index} value={item.ccp_id}>
                                {item.ccp_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Footer */}
                <div
                  className="modal-footer"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    ref={closeModalCustomer}
                  >
                    ยกเลิก
                  </button>
                  {true ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          setInvoice({
                            ...Invoice,
                            so_cus_name_id: Invoice.so_cus_name_id_sup,
                            so_csh_id: Invoice.so_ccp_id_sup,
                            is_change_id_ref: true,
                          });
                          Invoice.so_cus_name_id = Invoice.so_cus_name_id_sup;
                          Invoice.so_csh_id = Invoice.so_ccp_id_sup;
                          Invoice.is_change_id_ref = true;
                          console.log("save cus");
                          if (!Invoice.so_cus_name_id) {
                            clearCustomer();
                          } else {
                            if (!Invoice.so_csh_id) {
                              Swal.fire(
                                "Error",
                                "กรุณาเลือก Customer name ก่อนทำรายการถัดไป",
                                "error"
                              );
                              return;
                            }
                            addCustomerDetails();
                          }
                          clickCloseModalCustomer();
                        }}
                      >
                        บันทึก
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Product_CN_EV;
