import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import {
  getComConfig,
  getOem,
  getToken,
  getUser,
} from "../../../../Utils/Common";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import NumberFormat from "react-number-format";

function OrderInquiry() {
  const [test, settest] = useState({
    test: ""
  })

  const [FilterInquiry, setFilterInquiry] = useState({
    Inquiry_no: "",
  })
  const [InquiryData, setInquiryData] = useState([])

  useEffect(() => {
    InquiryFiterFunc();
  }, [])

  function InquiryFiterFunc() {
    let data = {
      Inquiry_no: FilterInquiry.Inquiry_no,
      company_id: getUser().com,
      oem_id: getOem(),
    }
    axios({
      method: "post",
      url: Configs.API_URL_incentive + "/api/orderInquiry/filterInquiry",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        console.log(res.data);
        setInquiryData(res.data);
      }
      )
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.response.data.error[0].errorDis,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
      );
  }

  function clearFilter() {
    setFilterInquiry({
      Inquiry_no: "",
    })
    FilterInquiry.Inquiry_no = "";
    InquiryFiterFunc();
  }

  function SendEmail(id) {
    Swal.fire({
      title: "Send Email",
      text: "คุณต้องการส่งอีเมล์ใช่หรือไม่?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        //Swal.showLoading();
        Swal.fire({
          title: "กำลังส่งอีเมล์",
          text: "กรุณารอสักครู่...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let data = {
          inquiry_id: id,
          com_name: getComConfig().com_name === "" ? "TTT Brother Co., Ltd." : getComConfig().com_name,
        }
        axios({
          method: "post",
          url: Configs.API_URL_incentive + "/api/orderInquiry/SendEmail",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then((response) => {
            console.log(response.data);
            if (response.data === "OK") {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Send Email Success",
              });
              InquiryFiterFunc();
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error!",
              html: error.response.data.error[0].errorDis,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    })
  }

  const InquiryTableData = [];
  for (let index = 0; index < InquiryData.length; index++) {
    const rowItem = {};
    rowItem["No"] = index + 1;
    rowItem["Inquiry_No"] = InquiryData[index].oi_no;
    rowItem["Order_Request_Amount"] = (
      <div style={{ textAlign: "right" }}>
        <NumberFormat
          value={InquiryData[index].oi_amount}
          displayType={"text"}
          thousandSeparator={true}
          thousandsGroupStyle="thousand"
        />
      </div>
    );
    rowItem["Send_Email"] = (
      <div style={{ textAlign: "center" }}>
        {InquiryData[index].oi_amount !== null && InquiryData[index].oi_amount != 0
          ? (
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={() => {
                SendEmail(InquiryData[index].oi_id);
              }}
            >
              Send Email
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-block btn"
              style={{ backgroundColor: "#808080" }}
              disabled={true}
              onClick={() => {
                SendEmail(InquiryData[index].oi_id);
              }}
            >
              Send Email
            </button>
          )}
      </div>
    );
    rowItem["Send_Email_Date"] = (InquiryData[index].oi_sent_email_date !== null ? moment(InquiryData[index].oi_sent_email_date).format("DD/MM/YYYY : HH:mm น.") : "");
    rowItem["Management"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Human_Resource/intensive_TTT/order_inquiry/order_inquiry_view/" + InquiryData[index].oi_id
            }
            // key={supplier[index].sup_id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Human_Resource/intensive_TTT/order_inquiry/order_inquiry_edit/" + InquiryData[index].oi_id
            }
            // key={supplier[index].sup_id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        {/* <div className="col-4">
          <a
            key={supplier[index].sup_id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, supplier[index].sup_id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div> */}
      </div>
    );
    InquiryTableData.push(rowItem);
  }
  const InquiryTable = {
    columns: [
      {
        label: "No",
        field: "No",
        sort: "asc",
        width: 150,
      },
      {
        label: "Inquiry No.",
        field: "Inquiry_No",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Request Amount",
        field: "Order_Request_Amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Send Email",
        field: "Send_Email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Send Email Date",
        field: "Send_Email_Date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Management",
        field: "Management",
        sort: "asc",
        width: 150,
      },
    ],
    rows: InquiryTableData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <h1>Order Inquiry</h1>
              </div>
              {/* <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>

                  <li className="breadcrumb-item active">Human Resource</li>
                  <li className="breadcrumb-item active">
                    Intensive Point (HR-Admin)
                  </li>
                  <li className="breadcrumb-item active">Summary Point</li>
                </ol>
              </div> */}
            </div>
            <div className="row mt-3">
              <div className="col-12 col-md-4 col-xl-2">
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control'
                    id='Inquiry_No'
                    name='Inquiry_No'
                    required="false"
                    value={FilterInquiry.Inquiry_no}
                    onChange={(e) => {
                      setFilterInquiry({
                        ...FilterInquiry,
                        Inquiry_no: e.target.value,
                      });
                      FilterInquiry.Inquiry_no = e.target.value;
                    }}
                  />
                  <label htmlFor=''>Inquiry No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className='form-group'>
                  <button
                    type='button'
                    className='btn btn-block btn-info'
                    onClick={() => {
                      InquiryFiterFunc();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className='form-group'>
                  <button
                    type='button'
                    className='btn btn-block btn-info'
                    onClick={() => {
                      clearFilter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div className='form-group'>
                  <button
                    type='button'
                    className='btn btn-block btn-primary'
                    onClick={() => {
                      console.log("Add");
                      window.location.href = "/Human_Resource/intensive_TTT/Order_Inquiry/order_inquiry_add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Order Inquiry</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive " style={{ whiteSpace: 'nowrap' }}>
                          <MDBDataTable
                            // searching={false}
                            sortable={true}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={InquiryTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default OrderInquiry