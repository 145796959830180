import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Configs from "../../../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function Logistic_Planning_Table() {
  const [inputData, setInputData] = useState({
    selectCarId: "",
    selectCarName: "",
    selectInv: "",
  });

  const [carData, setCarData] = useState({
    data: [],
  });

  const [ocModal, setOcModal] = useState(false);

  const [inputModal, setInputModal] = useState({
    carId: "",
    carName: "",
  });

  const [closeData, setCloseData] = useState({
    checkCar: false,
    checkScan: true,
    checkSave: true,
  });

  async function getCarAll() {
    const tempData = {
      // user_id: getUser().fup,
      // user_name: `${getUser().name} ${getUser().surname}`,
      company_id: getUser().com,
      oem_id: getOem(),
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/logistic/getCarAll",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(async (response) => {
        setCarData({
          data: response.data,
        });

        carData.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function saveData() {
    const tempData = {
      user_id: getUser().fup,
      user_name: `${getUser().name} ${getUser().surname}`,
      company_id: getUser().com,
      oem_id: getOem(),
      inputData: inputData,
    };

    Swal.fire({
      title: "Saving",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then(async () => {
      await axios({
        method: "post",
        url: Configs.API_URL_cuteplus_sales + "/api/invoice/getInvoiceByNo",
        headers: {
          Authorization: "Bearer " + getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempData,
      })
        .then(async (response) => {
          const temp = response.data;

          await axios({
            method: "post",
            url:
              Configs.API_URL_cuteplus +
              "/api/logistic/saveUpdateLogisticHandheld",
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
            .then(async (response) => {
              await axios({
                method: "post",
                url:
                  Configs.API_URL_cuteplus_sales +
                  "/api/invoice/updateInvDataLoInOutHandheld",
                headers: {
                  Authorization: "Bearer " + getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "application/json",
                },
                data: temp,
              })
                .then(async (response) => {
                  Swal.fire({
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    window.location.href =
                      "/Warehouse_Management/Handheld_Cuteplus";
                  });
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Error",
                    text: error.response.data.error.message,
                    icon: "error",
                  });
                });
            })
            .catch((error) => {
              Swal.fire({
                title: "Error",
                text: error.response.data.error.message,
                icon: "error",
              });
            });
        })
        .catch(function (error) {
          Swal.fire({
            title: "Error",
            text: error.response.data.error.message,
            icon: "error",
          });
        });
    });
  }

  useEffect(async () => {
    await getCarAll();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Handheld Logistic Out</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Handheld Logistic Out
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="container-fluid">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Handheld Logistic Out</h4>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12">
                          <h4>STEP 1</h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div
                            class="form-group"
                            style={{ display: "flex ", alignItems: "baseline" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              required
                              disabled={closeData.checkCar}
                              value={inputData.selectCarName}
                              onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                            <label
                              style={{
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Select Car
                            </label>

                            <a
                              style={{ cursor: "pointer", marginLeft: "10px" }}
                              onClick={() => {
                                setOcModal(true);
                              }}
                            >
                              <i class="fas fa-search"></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <h4>STEP 2</h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div class="form-group">
                            <input
                              type="text"
                              id="scanData"
                              className="form-control"
                              required
                              disabled={closeData.checkScan}
                              value={inputData.selectInv}
                              onChange={(e) => {
                                const replaceData = e.target.value.replaceAll(
                                  ", ",
                                  ""
                                );

                                setInputData({
                                  ...inputData,
                                  selectInv: replaceData.replace(
                                    /(.{9})/g,
                                    "$1, "
                                  ),
                                });

                                inputData.selectInv = replaceData.replace(
                                  /(.{9})/g,
                                  "$1, "
                                );
                              }}
                            />
                            <label
                              style={{
                                lineHeight: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              Scan Invoice
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ justifyContent: "center" }}>
                        <div
                          className="col-4"
                          style={{
                            minWidth: "130px",
                            width: "100%",
                            marginBottom: "20px",
                          }}
                        >
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            style={{ fontSize: "17px" }}
                            disabled={
                              inputData.selectCarId !== "" &&
                              inputData.selectInv !== ""
                                ? false
                                : true
                            }
                            onClick={() => {
                              setCloseData({
                                ...closeData,
                                checkCar: true,
                                checkScan: true,
                                checkSave: false,
                              });
                            }}
                          >
                            Scan Finish
                          </button>
                        </div>
                      </div>

                      <div className="row" style={{ justifyContent: "center" }}>
                        <div
                          className="col-4"
                          style={{
                            minWidth: "130px",
                            width: "100%",
                          }}
                        >
                          <button
                            type="button"
                            disabled={closeData.checkSave}
                            className="btn btn-block btn-info"
                            style={{ fontSize: "17px" }}
                            onClick={async () => {
                              await saveData();
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        size="sm"
        className="Modal_Center_LeftRight"
        isOpen={ocModal}
        toggle={() => {
          setInputModal({
            carId: "",
            carName: "",
          });

          setOcModal(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setInputModal({
              carId: "",
              carName: "",
            });

            setOcModal(false);
          }}
        >
          <h4 className="modal-title" id="modalCustomer">
            <b>Logistic Car</b>
          </h4>
        </ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <select
                  type="text"
                  className="form-control"
                  required
                  // disabled={isDisabled}
                  value={inputModal.carId}
                  onChange={(e) => {
                    setInputModal({
                      carId: e.target.value,
                      carName: e.target.selectedOptions[0].label,
                    });
                  }}
                >
                  <option key="" value="">
                    -- Select Car --
                  </option>

                  {carData.data.map((data, index) => {
                    return (
                      <option key={index} value={data.lgt_id}>
                        {data.lgt_name}
                      </option>
                    );
                  })}
                </select>
                <label
                  style={{
                    lineHeight: "normal",
                    wordBreak: "break-all",
                  }}
                >
                  Logistic Car
                </label>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter style={{ justifyContent: "space-between" }}>
          <button
            type="button"
            className="btn btn-danger"
            onClick={async () => {
              setInputModal({
                carId: "",
                carName: "",
              });

              setOcModal(false);
            }}
          >
            ยกเลิก
          </button>

          <button
            type="button"
            className="btn btn-success"
            onClick={async () => {
              setInputData({
                ...inputData,
                selectCarId: inputModal.carId,
                selectCarName: inputModal.carName,
              });

              inputData.selectCarId = inputModal.carId;
              inputData.selectCarName = inputModal.carName;

              setInputModal({
                carId: "",
                carName: "",
              });

              setInputData({
                ...inputData,
                selectInv: "",
              });

              setOcModal(false);

              if (inputData.selectCarId !== "") {
                setCloseData({
                  checkCar: true,
                  checkScan: false,
                  checkSave: true,
                });

                const interval = setInterval(() => {
                  document.getElementById("scanData").focus();
                  clearInterval(interval);
                }, 50);
              } else {
                setCloseData({
                  ...closeData,
                  checkCar: false,
                  checkScan: true,
                  checkSave: true,
                });
              }
            }}
          >
            บันทึก
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Logistic_Planning_Table;
