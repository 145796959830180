import React, { Component, useEffect, useState ,Children} from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import NumberFormat from "react-number-format";
//import BigCalendar from 'react-big-calendar';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import moment from "moment";


import 'react-big-calendar/lib/css/react-big-calendar.css';
function Factory_work_hoursWithOem(params) {
  
  const [calendarData, setcalendarData] = useState([]);
 // console.log(calendarData);
  const [work_hours, setWork_hours] = useState({
    company_id: getUser().com,
    oem_id:getOem(),
    day_normal:"" ,
    day_ot:"" ,
    night_normal:"" ,
    night_ot: "",
  });
  const [work_hours_fac, setWork_hours_fac] = useState({
    day_normal:"" ,
    day_ot:"" ,
    night_normal:"" ,
    night_ot: "",
  });
  const [filler_work_hours, setFiller_work_hours] = useState({
    oem_id:getOem(),
    company_id: getUser().com,
 
   });
  const [company, setCompany] = useState([]);
  const [filter_carlendar, setfilter_carlendar] = useState({
    year:moment(new Date()).format('yyyy'),
    month:moment(new Date()).format('MMM'),
  });
  const [type_select, settype_select] = useState({
    status:"set_work_hour",
  });
  const [select_date,setselect_date] = useState({
    date:moment(new Date()),
  })
 // console.log(select_date.date)
  const locales = {
    'en-US': enUS,
  }
  
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  })
  
  /*  const config_company = {
    method: "get",
    url: Configs.API_URL + "/company/all",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  }; */

  const GetCompany = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/all",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
      /*   console.log(response.data); */
        setCompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const GetWork_hours = async () => {
    
    axios({
      method: "post",
      url: Configs.API_URL + "/factoryWorkHours/filterWithOem",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_work_hours
    })
      .then(function (response) {
        //console.log(response.data);
        setWork_hours(response.data);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const GetCalendarByCompany = async () => {
    
    axios({
      method: "get",
      url: Configs.API_URL + "/factoryWorkHours/GetCalendarByCompanyWithOem?id="+ getOem(),
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      //data: filler_work_hours
    })
      .then(function (response) {
        console.log(response.data);
        setcalendarData(response.data)
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };


 

  useEffect(() => {
    GetWork_hours();
    GetCompany();
    GetCalendarByCompany();
   
  }, []);

  function saveOption(params) {
    const error_list = [];
    if((parseInt(work_hours.day_normal)+parseInt(work_hours.night_normal)+parseInt(work_hours.day_ot)+parseInt(work_hours.night_ot)) > 24){
     
      let temp_err = {
        message:"Time limit [time is not more than 24 hour]."
      }
      error_list.push(temp_err);
    }
    if(work_hours.day_normal == ""){
      let temp_err = {
        message:"Please enter information in the fields >>> [Day Shift(hour)]."
      }
      error_list.push(temp_err);
    }
    if(work_hours.night_normal == ""){
      let temp_err = {
        message:"Please enter information in the fields >>> [Night Shift(hour)]."
      }
      error_list.push(temp_err);
    }
    if(work_hours.day_ot == ""){
      let temp_err = {
        message:"Please enter information in the fields >>> [Day Shift[OT](hour)]."
      }
      error_list.push(temp_err);
    }
    if(work_hours.night_ot == ""){
      let temp_err = {
        message:"Please enter information in the fields >>> [Night Shift[OT](hour)]."
      }
      error_list.push(temp_err);
    }
    if(error_list.length > 0){
      var err_message = "";
      for(var e = 0; e < error_list.length;e++){
        err_message += "<br/>"+error_list[e].message;
      }
      Swal.fire("Error", err_message, "error");
    }else{

      var tempRes=[];
    const temp_day ={}
    temp_day.id = work_hours.id_dayship;
    temp_day.workday = "day_ship";
    temp_day.normal = work_hours.day_normal;
    temp_day.ot = work_hours.day_ot;
    temp_day.company_id = getUser().com;
    temp_day.oem_id = getOem();
    tempRes.push(temp_day);

    const temp_night ={}
    temp_night.id = work_hours.id_nightship;
    temp_night.workday = "night_ship";
    temp_night.normal = work_hours.night_normal;
    temp_night.ot = work_hours.night_ot;
    temp_night.company_id = getUser().com;
    temp_night.oem_id = getOem();
    tempRes.push(temp_night);

    
    //9console.log(tempRes);
   
 
      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 1000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {
        axios({
          method: "post",
          url: Configs.API_URL + "/factoryWorkHours/saveWithOem",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: tempRes,
        })
          .then(function (response) {
            if (response.data) {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                GetWork_hours();
                
               /*  window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Factory_Work_Hours"; */
              });
            }

            console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", error.response.data.message, "error");
          });
      });
    }
    
    
  
  }

  const [default_month,setdefault_month] = useState({
    month:moment(new Date()),
  });

  function SerchCaledar(){
    if(filter_carlendar.month !== "" && filter_carlendar.year !== ""){
      var formatYearMonth = filter_carlendar.month+" "+moment(default_month.month).format('DD')+" "+filter_carlendar.year+" 00:00:00 GMT+0700 (Indochina Time)";
      setdefault_month({...default_month,month:moment(formatYearMonth)});
      setselect_date({ ...select_date, date: moment(formatYearMonth) });
      //console.log(formatYearMonth)
    }
  }
  
  function NextMonthView(){
    var formatYearMonth = moment(default_month.month).add(1, 'M').format('MM')+" "+moment(default_month.month).format('DD')+" "+moment(default_month.month).format('yyyy')+" 00:00:00 GMT+0700 (Indochina Time)";
    if(parseInt(moment(default_month.month).format('MM')) >11){
       formatYearMonth = moment(default_month.month).add(1, 'M').format('MM')+" "+moment(default_month.month).format('DD')+" "+moment(default_month.month).add(1, 'years').format('yyyy')+" 00:00:00 GMT+0700 (Indochina Time)";
    }
   // console.log(formatYearMonth)
    setdefault_month({...default_month,month:moment(formatYearMonth)});
    setselect_date({ ...select_date, date: moment(formatYearMonth) });
  }

  function BackMonthView(){
    var formatYearMonth = moment(default_month.month).add(-1, 'M').format('MM')+" "+moment(default_month.month).format('DD')+" "+moment(default_month.month).format('yyyy')+" 00:00:00 GMT+0700 (Indochina Time)";
    if(parseInt(moment(default_month.month).format('MM')) < 2){
       formatYearMonth = moment(default_month.month).add(-1, 'M').format('MM')+" "+moment(default_month.month).format('DD')+" "+moment(default_month.month).add(-1, 'years').format('yyyy')+" 00:00:00 GMT+0700 (Indochina Time)";
    }
    //console.log(formatYearMonth)
    setdefault_month({...default_month,month:moment(formatYearMonth)});
    setselect_date({ ...select_date, date: moment(formatYearMonth) });
  }

  function ClearSerchCaledar(){
  
    setfilter_carlendar({...filter_carlendar,year:moment(new Date()).format('yyyy'),month:moment(new Date()).format('MMM')});
    filter_carlendar.year = "";
    filter_carlendar.month = "";
    setselect_date({ ...select_date, date: moment(new Date()) });
    setdefault_month({...default_month,month:moment(new Date())});
  }
  var working_hours_fac = (parseInt(work_hours_fac.day_normal) || 0) + (parseInt(work_hours_fac.day_ot) || 0) + (parseInt(work_hours_fac.night_normal) || 0) + (parseInt(work_hours_fac.night_ot) || 0);
    if(working_hours_fac > 24){
        setWork_hours_fac({
            ...work_hours_fac,
            day_normal: 8,
            day_ot:4,
            night_normal:8,
            night_ot:4,
        });   
    }

    var working_hours = (parseInt(work_hours.day_normal) || 0) + (parseInt(work_hours.day_ot) || 0) + (parseInt(work_hours.night_normal) || 0) + (parseInt(work_hours.night_ot) || 0);
    if(working_hours > 24){
        setWork_hours({
            ...work_hours,
            day_normal: 8,
            day_ot:4,
            night_normal:8,
            night_ot:4,
        });   
    }
  function SetHourToCalendar(){
   
    if(calendarData.length > 0){
      var data_calendar = calendarData;
    }else{
      var data_calendar = [];
    }
   var title;
   var color;
   var day_normal;
   var day_ot;
   var night_normal;
   var night_ot;
    if(type_select.status === "set_work_hour"){
      title = "Day : "+(work_hours_fac.day_normal || 0)+" + [OT]"+(work_hours_fac.day_ot || 0)+" / "+"Night : "+(work_hours_fac.night_normal || 0)+" + [OT]"+(work_hours_fac.night_ot || 0);
      color = "#FFFF00";
      day_normal = work_hours_fac.day_normal || 0;
      day_ot = work_hours_fac.day_ot || 0;
      night_normal = work_hours_fac.night_normal || 0;
      night_ot = work_hours_fac.night_ot || 0;
    }else if (type_select.status === "default"){
      title = "Default "+"Day : "+(work_hours.day_normal || 0)+" + [OT]"+(work_hours.day_ot || 0)+" / "+"Night : "+(work_hours.night_normal || 0)+" + [OT]"+(work_hours.night_ot || 0);;
      color = "#87CEEB";
      day_normal = work_hours.day_normal || 0;
      day_ot = work_hours.day_ot || 0;
      night_normal = work_hours.night_normal || 0;
      night_ot = work_hours.night_ot || 0;
    }else{
      title = "Day off";
      color = "#B0C4DE";
      day_normal = 0;
      day_ot = 0;
      night_normal = 0;
      night_ot = 0;
    }
   
    var temp = {
      id: moment(select_date.date).format('yyyyMMDD'),
      title: title,
      allDay: true,
      start: select_date.date,
      end: select_date.date,
      color:color,
      day_normal:day_normal,
      day_ot:day_ot,
      night_normal:night_normal,
      night_ot:night_ot,
      year:parseInt(moment(select_date.date).format('yyyy')),
      month:parseInt(moment(select_date.date).format('MM')),
    }
    let  check = data_calendar.findIndex((element)=>element.id === moment(select_date.date).format('yyyyMMDD'));
    if(check >= 0){
      data_calendar.splice(check,1);
    }
    if(select_date.date !== ""){
      data_calendar.push(temp);
    }
    
    setcalendarData(data_calendar);
    setselect_date({
      ...select_date,
      date: "",
    });
    select_date.date = "";
  }

const [test,settest] = useState({
  test:"",
})


  function SetHourToThisMonth(){
    console.log(calendarData)
    if(calendarData.length > 0){
      var data_calendar = calendarData;
    }else{
      var data_calendar = [];
    }
   var title;
   var color;
   var day_normal;
   var day_ot;
   var night_normal;
   var night_ot;
    if(type_select.status === "set_work_hour"){
      title = "Day : "+(work_hours_fac.day_normal || 0)+" + [OT]"+(work_hours_fac.day_ot || 0)+" / "+"Night : "+(work_hours_fac.night_normal || 0)+" + [OT]"+(work_hours_fac.night_ot || 0);
      color = "#FFFF00";
      day_normal = work_hours_fac.day_normal || 0;
      day_ot = work_hours_fac.day_ot || 0;
      night_normal = work_hours_fac.night_normal || 0;
      night_ot = work_hours_fac.night_ot || 0;
    }else if (type_select.status === "default"){
      title = "Default "+"Day : "+(work_hours.day_normal || 0)+" + [OT]"+(work_hours.day_ot || 0)+" / "+"Night : "+(work_hours.night_normal || 0)+" + [OT]"+(work_hours.night_ot || 0);;
      color = "#87CEEB";
      day_normal = work_hours.day_normal || 0;
      day_ot = work_hours.day_ot || 0;
      night_normal = work_hours.night_normal || 0;
      night_ot = work_hours.night_ot || 0;
    }else{
      title = "Day off";
      color = "#B0C4DE";
      day_normal = 0;
      day_ot = 0;
      night_normal = 0;
      night_ot = 0;
    }
   var date_in_month = moment(select_date.date, "YYYY-MM").daysInMonth();
   
   for(let d_num =1;d_num <= date_in_month; d_num++){
     var date_format1 = moment(select_date.date).format("YYYY/MM/") + d_num;
     var date_format2 = moment(date_format1).format();
     console.log(d_num,moment(date_format2).format("dd"))
     var temp = {
      id: moment(date_format2).format('yyyyMMDD'),
      title: title,
      allDay: true,
      start: date_format2,
      end: date_format2,
      color:color,
      day_normal:day_normal,
      day_ot:day_ot,
      night_normal:night_normal,
      night_ot:night_ot,
      year:parseInt(moment(date_format2).format('yyyy')),
      month:parseInt(moment(date_format2).format('MM')),
    }
      if(moment(date_format2).format("dd") === "Su"){
        console.log("หยุด")
        var temp = {
          id: moment(date_format2).format('yyyyMMDD'),
          title: "Day off",
          allDay: true,
          start: date_format2,
          end: date_format2,
          color:"#B0C4DE",
          day_normal:0,
          day_ot:0,
          night_normal:0,
          night_ot:0,
          year:parseInt(moment(date_format2).format('yyyy')),
          month:parseInt(moment(date_format2).format('MM')),
        }
      }
      let  check = data_calendar.findIndex((element)=>element.id === moment(date_format2).format('yyyyMMDD'));
      if(check >= 0){
        data_calendar.splice(check,1);
      }
     /*  if(select_date.date !== ""){ */
        data_calendar.push(temp);
     // }
   }
    //console.log(moment(select_date.date, "YYYY-MM").daysInMonth());
   
   
 
    
    setcalendarData(data_calendar);
    settest({...test,test:""});
    test.test = "";
   /*  setselect_date({
      ...select_date,
      date: "",
    });
    select_date.date = "";  */
  }













   function saveCalendar(){
     var temp = {
      company_id:getUser().com,
      oem_id:getOem(),
      calendar:calendarData
     }
     Swal.fire({
      title: "Saving",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 1000,
      onOpen: () => {
        Swal.showLoading();
      },
    }).then((result) => {
      axios({
        method: "post",
        url: Configs.API_URL + "/factoryWorkHours/save_calendarWithOem",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: temp,
      })
        .then(function (response) {
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "Save",
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              GetCalendarByCompany();
             if(params.mode === "popup"){
                params.fnc();
             }
              /* window.location.href =
                "/Warehouse_Management/Factory_Master_Data/Factory_Work_Hours"; */
            });
          }

          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", error.response.data.message, "error");
        });
    });

   }
  var class_name = "content-wrapper";
 if(params.mode === "popup"){
  class_name = "";
 }
 function CustomToolbar() {

   var test = moment(default_month.month).format('MMMM  yyyy').toString();
  return (
    <>
    
     <div className="rbc-toolbar">

   
        <span class="rbc-btn-group">
   
                  <button
                    type="button"
                    onClick={ClearSerchCaledar}
                  >
                    Today
                  </button>

                  <button
                    type="button"
                    
                    onClick={BackMonthView}
                  >
                    Back
                  </button>

                  <button
                    type="button"
                    
                    onClick={NextMonthView}
                  >
                    Next
                  </button>

                </span>

                <span className="rbc-toolbar-label">{test}</span>
               
        </div>

  
       
        </>
  )
}

/* const CURRENT_DATE = moment(select_date.date);

const ColoredDateCellWrapper = ({children, value}) =>
console.log(value)
	React.cloneElement(Children.only(children), {
		style: {
			...children.style,
			backgroundColor: value === CURRENT_DATE ? 'red' : 'lightblue',
		},
	});

 */

  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="facworkhours" submenu="factmaster" />  */}
      
      <div className={class_name}>
        {/* Content Header (Page header) */}
        <section className="content-header">
        
          <div className="container-fluid">
            
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Factory Work Hours{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Factory Work Hours</li>
                </ol>
              </div>
            </div>
          </div>

        </section>

        <section className="content">

          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="custom-tabs-four-section-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-section"
                    role="tab"
                    aria-controls="custom-tabs-four-section"
                    aria-selected="false"
                  >
                    Work Hour Default
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="custom-tabs-four-process-tab"
                    data-toggle="pill"
                    href="#custom-tabs-four-process"
                    role="tab"
                    aria-controls="custom-tabs-four-process"
                    aria-selected="false"
                  >
                    Factory Calendar
                  </a>
                </li>
               
              </ul>
            </div>
            <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="custom-tabs-four-section"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-section-tab"
                >
            <div className="card-header">
            
              <h3 className="card-title">Factory Work Hours</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">

            <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <NumberFormat 
                    allowNegative = {false}
                    decimalScale={0}
                    type="text"
                    className="form-control"
                    required="false"    
                    value={work_hours.day_normal}
                    onChange={(e) => {
                      setWork_hours({
                        ...work_hours,
                        day_normal: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Day_Shift(hour)
                  {work_hours.day_normal === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}</label>{" "}
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  
                <NumberFormat 
                   allowNegative = {false}
                    type="text"
                    decimalScale={0}
                    className="form-control"
                    required="false"    
                    value={work_hours.night_normal}
                    onChange={(e) => {
                      setWork_hours({
                        ...work_hours,
                        night_normal: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Night_shift(hour)
                  {work_hours.night_normal === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}</label>{" "}
                </div>
              </div>
             
       
            </div>

            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  
                <NumberFormat 
                   allowNegative = {false}
                    type="text"
                    className="form-control"
                    decimalScale={0}
                    required="false"    
                    value={work_hours.day_ot}
                    onChange={(e) => {
                      setWork_hours({
                        ...work_hours,
                        day_ot: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Day_Shift[OT](hour)
                  {work_hours.day_ot === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}</label>{" "}
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group ">
                  
                <NumberFormat 
                    allowNegative = {false}
                    type="text"
                    className="form-control"
                    decimalScale={0}
                    required="false"    
                    value={work_hours.night_ot}
                    onChange={(e) => {
                      setWork_hours({
                        ...work_hours,
                        night_ot: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Night_Shift[OT](hour)
                  {work_hours.night_ot === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}</label>{" "}
                </div>
              </div>
             
       
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-success"
                    onClick={saveOption}
                  >
                    Save
                  </button>
                </div>
              </div>


              
            </div>
            </div>

                        
            </div>
            
          </div>
          <div
                  className="tab-pane fade show"
                  id="custom-tabs-four-process"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-four-process-tab"
                >
            <div className="card-header">
            
              <h3 className="card-title">Factory Calendar</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body">
              <div className="row"> 
                <div className="col-12 col-md-12 col-xl-2" style = {{"vertical-align":"bottom"}}><h4>กำหนดตารางวัน หน่วย ชั่วโมง</h4></div>
                <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  
                <NumberFormat 
                    allowNegative = {false}
                    decimalScale={0}
                    type="text"
                    className="form-control"
                    required="false"    
                    value={work_hours_fac.day_normal}
                    onChange={(e) => {
                      setWork_hours_fac({
                        ...work_hours_fac,
                        day_normal: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Day_Shift
                  </label>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  
                <NumberFormat 
                   allowNegative = {false}
                    type="text"
                    className="form-control"
                    decimalScale={0}
                    required="false"    
                    value={work_hours_fac.day_ot}
                    onChange={(e) => {
                      setWork_hours_fac({
                        ...work_hours_fac,
                        day_ot: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Day_Shift[OT]
                  </label>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  
                <NumberFormat 
                   allowNegative = {false}
                    type="text"
                    decimalScale={0}
                    className="form-control"
                    required="false"    
                    value={work_hours_fac.night_normal}
                    onChange={(e) => {
                      setWork_hours_fac({
                        ...work_hours_fac,
                        night_normal: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Night_shift
                  </label>
                  </div>
                </div>
                <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  
                <NumberFormat 
                    allowNegative = {false}
                    type="text"
                    className="form-control"
                    decimalScale={0}
                    required="false"    
                    value={work_hours_fac.night_ot}
                    onChange={(e) => {
                      setWork_hours_fac({
                        ...work_hours_fac,
                        night_ot: e.target.value,
                      });
                    }}  
                  />
                  <label htmlFor="">Night_Shift[OT]
                  </label>{" "}
                  </div>
                </div>
    
                <div className="col-12 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={SetHourToCalendar}
                  >
                    Set Work Hour
                  </button>
                </div>

                </div>
              
              </div>
              <div className="row">
              <div className="col-1 col-md-2 col-xl-3"></div>
              <div className="col-4 col-md-3 col-xl-1">
                       
                            <input
                              className="form-check-input"
                              type="radio"
                              id="set_work_hour"
                              name ="type_select"
                              value="set_work_hour"
                              defaultChecked={type_select.status === "set_work_hour" ? true : false}
                             
                              onChange={(e) =>
                                settype_select({ ...type_select, status: e.target.value })
                              }
                              /> 
                              <label htmlFor="set_work_hour">
                              Set Work Hour 
                              </label>
                       
                          </div>
                          <div className="col-3 col-md-3 col-xl-1">
                        
                            <input
                              className="form-check-input"
                              type="radio"
                              id="default"
                              name ="type_select"
                              value="default"  
                              defaultChecked={type_select.status === "default" ? true : false}
                              onChange={(e) =>
                                settype_select({ ...type_select, status: e.target.value })
                              }
                              />
                              <label htmlFor="default">
                              Default
                              </label>
                        
                          </div>

                          <div className="col-3 col-md-3 col-xl-1">
                        
                            <input
                              className="form-check-input"
                              type="radio"
                              id="day_off"
                              name ="type_select"
                              value="day_off"  
                              defaultChecked={type_select.status === "day_off" ? true : false}
                              onChange={(e) =>
                                settype_select({ ...type_select, status: e.target.value })
                              }
                              />
                              <label htmlFor="day_off">
                              Day off
                              </label>
                        
                          </div>
                          <div className="col-12 col-md-6 col-xl-3">
                            <h3 style = {{backgroundColor:"#EAF6FF",textAlign:"center"}}>วันที่เลือก : {select_date.date === "" ?("ยังไม่ได้เลือก") : (moment(select_date.date).format('DD-MM-yyyy'))}</h3>
                            </div>
                            <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={SetHourToThisMonth}
                  >
                    Set this month
                  </button>
                  </div>
                          </div>
                        </div>
                        <div className="row">
        
                        <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                <select
                    className="custom-select select2 form-control"
                    value={filter_carlendar.year}
                    onChange={(e) =>
                      setfilter_carlendar({ ...filter_carlendar, year: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                    
                  </select>
                  <label htmlFor="">Year
                  </label>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  
                <select
                    className="custom-select select2 form-control"
                    value={filter_carlendar.month}
                    onChange={(e) =>
                      setfilter_carlendar({ ...filter_carlendar, month: e.target.value })
                    }
                  >
                    <option value="">Select</option>
                    <option value="Jan">1</option>
                    <option value="Feb">2</option>
                    <option value="Mar">3</option>
                    <option value="Apr">4</option>
                    <option value="May">5</option>
                    <option value="Jun">6</option>
                    <option value="Jul">7</option>
                    <option value="Aug">8</option>
                    <option value="Sep">9</option>
                    <option value="Oct">10</option>
                    <option value="Nov">11</option>
                    <option value="Dec">12</option>
                    
                    
                  </select>
                  <label htmlFor="">Month
                  </label>
                  </div>
                </div>
                
    
                <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={SerchCaledar}
                  >
                    Search
                  </button>
                </div>

                </div>
                <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={ClearSerchCaledar}
                  >
                    Clear
                  </button>
                </div>

                </div>
              
              </div>
        
        
          
            <Calendar
      localizer={localizer}
      events={calendarData}
      selectable={true}
 
    /*  components={{
       toolbar:CustomToolbar,
      
     }} */
      startAccessor="start"
      endAccessor="end"
      views={["month"]}
      style={{ height: 500 }}
      onSelectSlot={date => {
        setselect_date({ ...select_date, date: date.start })}}
      //onSelectSlot={event => alert(event.title)}
      onSelectEvent={event => Swal.fire("Event",
       event.title+"<br>Day_Shift: "+event.day_normal
       +" / Day_Shift[OT]: "+event.day_ot
       +"<br>Night_Shift: "+event.night_normal
       +" / Night_Shift[OT]: "+event.night_ot,)}
       date={default_month.month}
     
      eventPropGetter={(event, start, end, isSelected) =>
        ({
          event,
          start,
          end,
          isSelected,
         // style: { backgroundColor: "green" }
          style: { backgroundColor: event.color,color: 'black'}
        })
      
        }
      onNavigate={date => {
        setselect_date({ ...select_date, date: date })
      
  }}
 
  components={{
   /*  month: {
      dateHeader: ({ date, label }) => {
        let highlightDate =
        calendarData.find(event =>
            moment(select_date)
          ) != undefined;
        return (
          <h1 style={highlightDate ? { color: "red" } : null}>{label}</h1>
        );
      }
    }, */
  	//dateCellWrapper: ColoredDateCellWrapper,
    toolbar:CustomToolbar,
  }}
  resizable
    />

        <br/>

            <div className="row">
 
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-success"
                    onClick={saveCalendar}
                  >
                    Save
                  </button>
                </div>
              </div>


              
            </div>
        
                        
            </div>
            
          </div>


              </div>
              
              </div>
  
              </section>

        
        {/* /.content */}
      </div>
   {/*   <Footter/> */}
     </div>
     
  );
}

export default Factory_work_hoursWithOem;
