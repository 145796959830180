import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../../config";
import React, { useState } from "react";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import { useEffect } from "react";

function Table_Customer_CutePlus() {
  const [Filter, setFilter] = useState({
    code: "",
    name: "",
  });

  const [Customer, setCustomer] = useState({
    data: [],
  });

  useEffect(async () => {
    sessionStorage.removeItem("cusTab2Active");
    await GetCustomer();
  }, []);

  const GetCustomer = async () => {
    let temp = {
      company_id: getUser().com,
      oem_id: getOem(),
      code: Filter.code.trim() || "",
      name: Filter.name.trim() || "",
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomer",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(function (response) {
        // console.log(Filter.name);
        setCustomer({ data: response.data });
        Customer.data = response.data;
        console.log(response.data);
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function clear() {
    setFilter({
      ...Filter,
      code: "",
      name: "",
    });
    Filter.code = "";
    Filter.name = "";

    await GetCustomer();
  }

  function delDataToolingPO(e) {
    console.log("id", e.cus_id);
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus + "/api/customer/delCustomer/" + e.cus_id,
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            Swal.fire({
              icon: "success",
              text: "เสร็จสิ้น",
              showConfirmButton: false,
              timer: 1000,
            }).then(() => {
              let arrList = [];
              window.location.reload();
              /* arrList = perchase_po.filter((po) => po.perchaseTable.po_id !== e.perchaseTable.po_id);
                        setPerchase_po(arrList);
                        setTest({ ...test, test: '' }); */
              // window.location.href = "/Human_Resource/HRD/External_dev_train";
            });
          })
          .catch(function (error) {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      }
    });
  }

  const rowsData = [];
  for (let index = 0; index < Customer.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["code"] = Customer.data[index].cus_no
      ? Customer.data[index].cus_no
      : "-";

    rowItem["name"] = Customer.data[index].cus_name
      ? Customer.data[index].cus_name
      : "-";

    rowItem["distribution"] = Customer.data[index].dc_name
      ? Customer.data[index].dc_name
      : "-";

    // rowItem["seller"] = Customer.data[index].cus_seller_name
    //   ? Customer.data[index].cus_seller_name
    //   : "-";

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={Customer.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus/view/" +
                Customer.data[index].cus_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <a
            key={Customer.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus/edit/" +
                Customer.data[index].cus_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        <div
          className="col-xl-3 col-md-3 col-xs-3 " /* style={{ visibility: perchase_po[index].statusAll.deleteBtn ? "hidden" : "visible" }} */
        >
          <a
            key={Customer.data[index]}
            id={Customer.data[index]}
            className=" btn btn-xs "
            onClick={delDataToolingPO.bind(this, Customer.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const CustomerTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer Code",
        field: "code",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer Name",
        field: "name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Distribution Chanel",
        field: "distribution",
        // sort: "asc",
        width: 50,
      },
      // {
      //   label: "Seller",
      //   field: "seller",
      //   // sort: "asc",
      //   width: 50,
      // },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Customer</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Customer</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.code}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        code: e.target.value,
                      });
                      Filter.code = e.target.value;
                    }}
                  />
                  <label>Customer Code</label>
                </div>
              </div>

              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        name: e.target.value,
                      });
                      Filter.name = e.target.value;
                    }}
                  />
                  <label>Customer Name</label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await GetCustomer();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <button
                  type="button"
                  className="btn btn-block btn-primary"
                  onClick={() => {
                    window.location.href =
                      "/Warehouse_Management/Factory_Master_Data_Cuteplus/Customer_cutePlus/add";
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Customer</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            striped
                            className="table table-head-fixed"
                            bordered
                            hover
                            data={CustomerTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Table_Customer_CutePlus;
