import React, { useEffect, useState, useRef } from "react";
import { Card, Pagination } from "antd";

function Table_All_Project({
  cardData,
  onPageChange,
  currentPage,
  pageSize,
  onChange,
  onShowSizeChange,
  total,
}) {
  // คำนวณจำนวนข้อมูลทั้งหมด
  const totalItems = total?.length || 0;

  // คำนวณหน้าทั้งหมดโดยใช้ pageSize และจำนวนข้อมูลทั้งหมด
  const totalPages = Math.ceil(totalItems / pageSize);

  return (
    <>
      <div style={{ overflow: "overlay" }}>
        {cardData.map((card) => (
          <Card
            key={card.id}
            style={{
              boxShadow: "1px 1px 3px 1px #d1cece",
              minWidth: "max-content",
              height: "max-content",
              margin: "15px 5px",
            }}
          >
            {card.content}
          </Card>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: "20px",
          marginRight: "10px",
        }}
      >
        <span style={{ fontSize: "20px" }}>Total {totalItems} items</span>

        <Pagination
          locale={{ page: '' }}
          showQuickJumper
          showSizeChanger
          // defaultCurrent={currentPage}
          current={currentPage}
          pageSizeOptions={[10, 20, 50, 100, 250]}
          total={totalItems}
          onChange={onChange}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </>
  );
}

export default Table_All_Project;
