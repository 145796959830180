import axios from "axios";
import React from "react";
import { useState , useEffect } from "react";
import Select from "react-select";
import Configs from "../../config";
import Swal from "sweetalert2";
import { getOem, getToken, getUser } from "../../Utils/Common";

function SystemConfig() {


    const [selectConfig,setSelectConfig] = useState()

    const [selectedOption, setSelectedOption] = useState({
      
    });

    const [checkConfig, setCheckConfig] = useState();

    const [systemConfigID, setSystemConfigID] = useState({
      id:null,
    })


    const handleSelectChange = (selectedOption) => {
      setSelectedOption(selectedOption);
      console.log(selectedOption);
    };

    async function getSelectConfig() {
        await axios({
          method: "get",
          url: Configs.API_URL_AIOI_Back + "/api/systemConfig/getSelectConfig",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          
        })
          .then(async function (response) {
            // console.log("response", response);
            setSelectConfig(response.data.data.map(el => {
                return {
                    value : el.um_id,
                    label : el.um_name,
                }
            }))
            // setSelectConfig({
            //   ...selectConfig,
            //   um_id : response.data.um_id,
            //   um_name : response.data.um_id,
            //   um_is_use : response.data.um_id,
            // });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }

      async function getCheckConfig() {
        await axios({
          method: "get",
          url: Configs.API_URL_AIOI_Back + "/api/systemConfig/getCheckConfig",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          
        })
          .then(async function (response) {
            if(response.data.data.length > 0){
              
              const data = response.data.data.map(el => {
                return {
                    value : el.sc_value,
                    label : el.sc_name,
                    sc_id : el.sc_id,
                }
              
            })// console.log("response", response);
            if (data.length > 0){
              console.log('data[0]', data[0])
              setSelectedOption(data[0])
              setSystemConfigID({id: data[0].sc_id})
            }
        
          }
            // setSelectConfig({
            //   ...selectConfig,
            //   um_id : response.data.um_id,
            //   um_name : response.data.um_id,
            //   um_is_use : response.data.um_id,
            // });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
          });
      }

      async function saveSelectConfig() {
        console.log(selectedOption); 
      
        if (!selectedOption.value) {
          
          await Swal.fire({
            icon: "warning",
            title: "โปรดเลือกข้อมูล",
            text: "โปรดเลือกข้อมูลที่ต้องการบันทึก",
            confirmButtonText: "ตกลง",
          });
          return;
        }
        
        let temp = {
          sc_id : selectedOption.value || null,  
          sc_name : selectedOption.label || null,
          id : systemConfigID?.id || null,
        }
        console.log(systemConfigID);
        
        await axios({
          method: "post",
          url: Configs.API_URL_AIOI_Back + `/api/systemConfig/${temp.id?"updateSelectConfig":"saveSelectConfig"}`, 
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp
        })
        .then(async function (response) {
          
          console.log("Response from backend:", response.data);
          if (response.data) {
            Swal.fire({
              icon: "success",
              title: "บันทึกสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            }).then(async (result) => {
              await getCheckConfig();
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire("Error", "เกิดข้อผิดพลาด: " + error, "error");
        });
      }
      

      useEffect(async () => {
        await getSelectConfig();
      }, []);

      useEffect(async () => {
        await getCheckConfig();
      },[]);

  return (
    <div className="wrapper">
      {/* <Header />
          <Sidebar menu="warehouse" activemenu="projmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  System Configuration
                  {"  "}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  {/* <li className="breadcrumb-item active">Human Resource</li> */}
                  <li className="breadcrumb-item active">
                    System Configuration
                  </li>
                </ol>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_search"
                    className="btn btn-block btn-info"
                    onClick={()=> {
                      Swal.fire({
                        title: 'ยืนยันการบันทึก',
                        text: 'คุณแน่ใจหรือไม่ที่ต้องการบันทึกข้อมูล?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'ยืนยัน',
                        cancelButtonText: 'ยกเลิก',
                        cancelButtonColor: "#d33"
                      }).then((result) => {
                        if(result.isConfirmed){
                          saveSelectConfig();
                        }
                        
                      })
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    id="btn_clear"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      window.location.reload();}}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content-body">
          <div className="card">
            <div className="card-body">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-12 col-md-4 col-xl-3">
                    <div className="form-group ">
                      <Select
                        type="text"
                        // className="form-control"
                        id="text_empid"
                        required="false"
                        value = {selectedOption}
                        options={selectConfig}
                        onChange={handleSelectChange}
                        
                      />
                      <label htmlFor="">Unit of Measurement</label>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default SystemConfig;
