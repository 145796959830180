import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Configs from "../../../../config";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import {
    getOem,
    getToken,
    getUser,
    getAcademy,
} from "../../../../Utils/Common";
import { useParams } from "react-router-dom";

function IncentiveUserAwardpoint_View() {
    const { id } = useParams();
    const [awardData, setawardData] = useState({
        ap_id: "",
        ap_point_id: "",
        ap_point_name: "",
        ap_point: "",
        ap_image_path: "",
        ap_image_name: "",
        ap_point_detail: "",
    })

    const back = () => {
        Swal.fire({
            title: "คุณต้องการย้อนกลับหรือไม่ ?",
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.isConfirmed) {
                window.location.href = "/Human_Resource/incentive_user/award_point";
            }
        })
    }

    const getUserAwardByID = async () => {
        const get_award = {
            ap_id: id,
            company_id: getUser().com,
            oem_id: getOem(),
        };
        axios({
            method: "post",
            url: Configs.API_URL_incentive + "/api/awardPoint/getUserAwardByID",
            headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
            },
            data: get_award,

        }).then((res) => {
            console.log(res.data);
            setawardData({
                ...awardData,
                ap_id: res.data[0].ap_id,
                ap_point_id: res.data[0].ap_point_id,
                ap_point_name: res.data[0].ap_point_name,
                ap_point: res.data[0].ap_point,
                ap_image_path: res.data[0].ap_image_path,
                ap_image_name: res.data[0].ap_image_name,
                ap_point_detail: res.data[0].ap_point_detail,
            });
            awardData.ap_id = res.data[0].ap_id;
            awardData.ap_point_id = res.data[0].ap_point_id;
            awardData.ap_point_name = res.data[0].ap_point_name;
            awardData.ap_point = res.data[0].ap_point;
            awardData.ap_image_path = res.data[0].ap_image_path;
            awardData.ap_image_name = res.data[0].ap_image_name;
            awardData.ap_point_detail = res.data[0].ap_point_detail;
        });
    }
    useEffect(() => {
        getUserAwardByID();
    }, []);

    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>คะแนนความสามารถ</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>

                                    <li className="breadcrumb-item active">Human Resource</li>
                                    <li className="breadcrumb-item active">News</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className="content-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-6 col-md-4 col-xl-3">
                                <button
                                    type="button"
                                    className="btn btn-danger btn-block"
                                    onClick={() => {
                                        back();
                                    }}
                                >
                                    ย้อนกลับ
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">คะแนนความสามารถ</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-6 col-md-3 col-xl-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id='award_no'
                                                className="form-control"
                                                value={awardData.ap_point_id}
                                                disabled={true}
                                            ></input>
                                            <label htmlFor='award_no'>รหัสคะแนน</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-3 col-xl-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id='award_name'
                                                className="form-control"
                                                value={awardData.ap_point_name}
                                                disabled={true}
                                            ></input>
                                            <label htmlFor='award_name'>ชื่อคะแนนความสามารถ</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-3 col-xl-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id='award_point'
                                                className="form-control"
                                                value={awardData.ap_point}
                                                disabled={true}
                                            ></input>
                                            <label htmlFor='award_point'>จำนวนคะแนน</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-6 col-md-3 col-xl-3">
                                        {/* <div className="form-group"> */}
                                        <label htmlFor='award_img'>รูปภาพ</label>
                                        <img
                                            id="award_img"
                                            className="img-fluid rounded shadow border-0"
                                            src={awardData.ap_image_path !== "" &&
                                                awardData.ap_image_path !== null &&
                                                awardData.ap_image_path !== undefined
                                                ? Configs.API_URL_IMG_incentive + awardData.ap_image_path
                                                : userdefault_img.imgs}
                                            alt=""
                                            style={{
                                                width: "400px",
                                                height: "300px",
                                                position: "relative",
                                            }}
                                        ></img>

                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-3 col-xl-3">
                                        <div className="form-group">
                                            <textarea
                                                type="text"
                                                id='award_point_detail'
                                                className="form-control"
                                                value={awardData.ap_point_detail}
                                                disabled={true}
                                            ></textarea>
                                            <label htmlFor='award_point_detail'>รายละเอียด</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default IncentiveUserAwardpoint_View