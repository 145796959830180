import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import axios from "axios";
import Configs from "../../../../../../config";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../../Utils/Common";

import { MDBDataTable } from "mdbreact";
import moment from "moment";

function History_Certificate_license({ fromPage, historyData }) {
  // async function DownloadFile(index) {
  //   await axios({
  //     method: "get",
  //     url: Configs.API_URL_IMG_cuteplus + historyData[index].File_Path,
  //     headers: {
  //       Authorization: "Bearer " + getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     responseType: "blob",
  //   }).then(async (response) => {
  //     const type = response.headers["content-type"];
  //     const blob = new Blob([response.data]);
  //     const href = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = href;
  //     link.setAttribute("download", historyData[index].File_Name); //or any other extension
  //     document.body.appendChild(link);
  //     link.click();

  //     // clean up "a" element & remove ObjectURL
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(href);
  //   });
  // }

  const rowsData = [];
  for (let index = 0; index < historyData.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = index + 1;

    rowItem["name"] = historyData[index].Certificate_License;

    rowItem["type"] = historyData[index].Type;
    
    rowItem["create_Date"] = moment(historyData[index].Create_Date).format(
      "DD/MM/YYYY"
    );

    rowItem["expire_Date"] = moment(historyData[index].Expire_Date).format(
      "DD/MM/YYYY"
    );

    rowItem["document"] = (
      <a
        href={
          Configs.API_URL_IMG_cuteplus +
          `/static/${historyData[index].File_Name}/${historyData[index].File_Path}`
        }
      >
        <button
          type="button"
          className="btn btn-block btn-primary"
          style={{ minWidth: "100px" }}
          // onClick={async () => {
          //   await DownloadFile(index);
          // }}
        >
          Download
        </button>
      </a>
    );

    rowsData.push(rowItem);
  }

  const data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: fromPage === "Certificate" ? "Factory" : "License",
        field: "name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Certificate Type",
        field: "type",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Create Date",
        field: "create_Date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Expire Date",
        field: "expire_Date",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Document",
        field: "document",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  if (fromPage !== "Certificate") {
    const index = data.columns.findIndex((e) => e.field === "type");
    data.columns.splice(index, 1);
  }

  return (
    <>
      <div className="table-responsive " style={{ whiteSpace: "nowrap" }}>
        <MDBDataTable
          sortable={false}
          className="table table-head-fixed"
          striped
          bordered
          hover
          fixedHeader
          data={data}
          disableRetreatAfterSorting={true}
        />
      </div>
    </>
  );
}

export default History_Certificate_license;