import React, { useState, useEffect } from "react";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import { MultiSelect } from "react-multi-select-component";
import NumberFormat from "react-number-format";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Sales_Order from "./Component/Sales_Order";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../config";
import moment from "moment";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../Utils/Common";
import Sale_Order_Report from "./Component/Sale_Order_Report.js";
import Select from "react-select";

function Table_Sales_Order() {
  const [Filter, setFilter] = useState({
    no: "",
    customer: "",
    status: [],
    so_created_name: getUser().name + " " + getUser().surname,
    so_oem_id: getOem(),
    so_company_id: getUser().com,
  });

  const [status_list_filter, setStatus_list_filter] = useState([]);

  const [modal, setModal] = useState(false);

  const tempOem = {
    company_id: getUser().com,
    oem_id: getOem(),
    user_id: getUser().fup,
    user_name: getUser().name + " " + getUser().surname,
    created_by: getUser().fup,
  };

  function clear() {
    setStatus_list_filter([]);
    setFilter({
      ...Filter,
      so_no: "",
      customer: "",
      status: [],
    });
    Filter.so_no = "";
    Filter.customer = "";
    Filter.status = [];
    getSO();
  }

  const [SalesOrder, setSalesOrder] = useState({
    data: [],
  });

  const getSO = async () => {
    let temp = {
      so_no: Filter.so_no?.trim() || "",
      customer: Filter.customer?.trim() || "",
      status: status_list_filter,
      so_created_name: getUser().fup,
      so_oem_id: getOem(),
      so_company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getTable",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then(async function (response) {
        setSalesOrder({ data: response.data });
        SalesOrder.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const updateStatusBill = async (temp) => {
    let data = {
      data: temp,
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/updateStatusBillSO",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: data,
    })
      .then((response) => {
        // console.log("response.data :>> ", response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  // function delKitting(e) {
  //   // console.log("id", e.product_id);
  //   Swal.fire({
  //     title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
  //     text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "ตกลง",
  //     cancelButtonText: `ไม่`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let temp = {
  //         id: e.product_id.trim() || ""
  //         // category: Filter.category.trim() || "",
  //       };
  //       axios({
  //         method: "post",
  //         url:
  //           Configs.API_URL_cuteplus +
  //           "/api/product_kitting/delproduct_kitting",
  //           // e.product_id,
  //         headers: {
  //           Authorization: "Bearer " + getToken(),
  //           "Content-Type": "application/json",
  //           "X-TTT": Configs.API_TTT,
  //         },
  //         data: temp
  //       })
  //         .then(function (response) {
  //           Swal.fire({
  //             icon: "success",
  //             text: "เสร็จสิ้น",
  //             showConfirmButton: false,
  //             timer: 1000,
  //           }).then(() => {
  //             let arrList = [];
  //             window.location.reload();
  //             /* arrList = perchase_po.filter((po) => po.perchaseTable.po_id !== e.perchaseTable.po_id);
  //                       setPerchase_po(arrList);
  //                       setTest({ ...test, test: '' }); */
  //             // window.location.href = "/Human_Resource/HRD/External_dev_train";
  //           });
  //         })
  //         .catch(function (error) {
  //           console.log(error.response.data);
  //           Swal.fire("Error", error.response.data.error[0].errorDis, "error");
  //         });
  //     }
  //   });
  // }

  const [test, settest] = useState({
    test: "",
  });

  useEffect(async () => {
    // await getBarcode();
    await getStatusDD();
    await getCustomerDD();
    // await GetRequisition();
  }, []);

  const [statusDD, setStatusDD] = useState({
    data: [],
  });

  const [CustomerDD, setCustomerDD] = useState([]);

  async function getCustomerDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        // console.log("response.data :>> ", response.data);
        const cus_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        cus_dd.unshift({
          label: "--Select--",
          value: ""
        })
        setCustomerDD(cus_dd);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getStatusDD() {
    axios({
      method: "get",
      url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getSOstatus",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const options = [];
        for (let item of response.data) {
          let temp = {
            label: item.ss_name,
            value: item.ss_id,
          };
          options.push(temp);
        }
        setStatusDD({ ...statusDD, data: options });
        statusDD.data = options;
        // console.log("status:>> ", statusDD.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const [Barcode, setBarcode] = useState({
    data: "",
  });

  const getBarcode = async (id) => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/getBarcode",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id },
    })
      .then(function (response) {
        // console.log(response.data)
        // console.log(StatusBill_Table.data)
        for (let BC of response.data) {
          for (let SOP of SOProductData.data) {
            if (SOProductData.data.sop_pb_id === Barcode.data.pb_id) {
            }
          }
        }
        setBarcode({ ...Barcode, data: response.data });
        Barcode.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [SOProductData, setSOProductData] = useState({
    data: "",
  });

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  const getProductData = async (id) => {
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/sale_order_product/getProductData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id, tempOem },
    })
      .then(function (response) {
        if (response.data.length > 0) {
          for (let item of response.data) {
            item.show_sop_quantity = item.sop_quantity;
          }
          setSOProductData({
            ...SOProductData,
            data: response.data,
          });
          SOProductData.data = response.data;
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [SOBill, setSOBill] = useState({
    data: "",
  });

  const getBillfromSO = async (id) => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/getBillfromSO",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id },
    })
      .then(async function (response) {
        await getProductData(id);
        await getBarcode();
        for (let SOD of response.data) {
          for (let SO of SOProductData.data) {
            if (SO.so_id === SOD.bill_so_id) {
              // console.log(SO);
              // setSOProductData({
              //   ...SOProductData,
              //   so_quantity: SO.so_quantity,
              // });
            }
          }
        }
        setSOBill({ ...SOBill, data: response.data });
        SOBill.data = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const [SOData, setSOData] = useState({
    data: "",
  });

  const getSonoData = async (id) => {
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus_sales + "/api/sale_order/getSonoData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: { id, tempOem },
    })
      .then(async function (response) {
        // setSono({ data: response });
        // Sono.data = response;
        const { so_date, so_delivery_date, ...other } = response.data[0];
        setSOData(other);
        const soDate = new Date(response.data[0].so_date);
        const soDeliveryDate = new Date(response.data[0].so_delivery_date);
        setSOData((prevSOData) => ({
          ...prevSOData,
          ...other,
          so_date: soDate,
          so_delivery_date: soDeliveryDate,
        }));
        settest({ ...test, test });
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const handleRowDelete = (index) => {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      text: "ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ตกลง",
      cancelButtonText: `ไม่`,
    }).then((result) => {
      if (result.isConfirmed) {
        const tempProductData = [...SOProductData.data];
        tempProductData.splice(index, 1);
        setSOProductData({ ...SOProductData, data: tempProductData });
      }
    });
  };

  const [ValueformCom, setValueformCom] = useState({});

  const checkErrorForSave = async () => {
    let error_list = [];
    for (let index = 0; index < SOProductData.data.length; index += 1) {
      if (SOProductData.data[index].sop_quantity) {
        SOProductData.data[index].sop_quantity =
          SOProductData.data[index].sop_quantity?.trim();
      }
      if (
        SOProductData.data[index].sop_quantity === "" ||
        SOProductData.data[index].sop_quantity === null ||
        SOProductData.data[index].sop_quantity === undefined
      ) {
        let temp_err = {
          message: `กรุณากรอกจำนวนในช่อง Edit`,
        };
        error_list.push(temp_err);
      } else if (Number(SOProductData.data[index].sop_quantity) === 0) {
        let temp_err = {
          message: `กรุณากรอกจำนวนที่ไม่ใช่ 0 ในช่อง Edit`,
        };
        error_list.push(temp_err);
      }
    }

    return error_list;
  };

  async function saveOption() {
    const result = await Swal.fire({
      title: "คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `ไม่`,
    });

    if (result.isConfirmed) {
      let error_list = [];

      error_list = await checkErrorForSave();

      if (error_list.length > 0) {
        var err_message = "";

        for (var e = 0; e < error_list.length; e++) {
          err_message += "<br/>" + error_list[e].message;
        }

        Swal.fire("Error", err_message, "error");
      } else {
        // SOProductData.data[index].sop_quantity;
        const temp = [];
        const temp1 = [];
        for (let item of SOProductData.data) {
          const tempItem = {
            sop_id: item.sop_id,
            sop_quantity: item.sop_quantity || null,
            sop_quantity_unit: item.sop_quantity_unit || null,
            sop_product_id: item.sop_product_id || null,
            sop_pb_id: item.sop_pb_id || null,
            sop_cus_barcode: item.sop_cus_barcode || null,
            sop_quantity: item.sop_quantity || null,
            sop_quantity_unit: item.sop_quantity_unit || null,
            sop_price: item.sop_price || null,
            sop_total: item.sop_total || null,
            sop_is_use: true,
            // sop_created_date: new Date(),
            sop_updated_date: new Date(),
            // sop_created_by: getUser().fup,
            sop_updated_by: getUser().fup,
            // sop_created_name: null,
            sop_updated_name: getUser().name + " " + getUser().surname,
            sop_product_name_th: item.sop_product_name_th || null,
            sop_product_name_eng: item.sop_product_name_eng || null,
            sop_lp_id: item.sop_lp_id || null,
            sop_lot_no: item.sop_lot_no || null,
            sop_exp_date: item.sop_exp_date || null,
            sop_so_id: SOData.so_id,
            // discount: item.discount?.map((el, index) => {
            //   return {
            //     sod_discount: el.discount || null,
            //     sod_unit_discount: el.unit || null,
            //     sod_sort: index + 1,
            //     sod_is_use: true,
            //     sod_created_date: new Date(),
            //     sod_updated_date: new Date(),
            //     sod_created_by: getUser().fup,
            //     sod_updated_by: getUser().fup,
            //     sod_created_name: getUser().name + " " + getUser().surname,
            //     sod_updated_name: getUser().name + " " + getUser().surname,
            //   };
          };
          temp.push(tempItem);
        }

        const tempItem1 = {
          bill_so_id: SOData.so_id,
          // sop_created_date: new Date(),
          bill_updated_date: new Date(),
          // sop_created_by: getUser().fup,
          bill_updated_by: getUser().name + " " + getUser().surname,
          // sop_created_name: null,
          bill_updated_name: getUser().name + " " + getUser().surname,
          bill_so_no: SOData.so_no,
          bill_so_cus_name: SOData.so_cus_name,
          bill_so_cus_id: SOData.so_cus_id,
          bill_req_cus_id: SOData.so_cus_id,
          bill_product_remark: SOData.so_remark,
          bill_remark: SOData.so_cus_remark,
        };
        temp1.push(tempItem1);

        try {
          let axiosData = {};
          let axiosData1 = {};

          axiosData = {
            method: "post",
            url:
              Configs.API_URL_cuteplus_sales +
              "/api/sale_order_product/realeditProduct",
            headers: {
              Authorization: "Bearer " + getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          };
          await axios(axiosData)
            .then(async (response) => {
              await updateStatusBill(temp);
            })
            /* .then(() => {
            // await axios(axiosData).then(async (response) => {
            axiosData1 = {
              method: "post",
              url: Configs.API_URL_cuteplus + "/api/bill/addBillfromSO",
              headers: {
                Authorization: "Bearer " + getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
              data: temp1,
            };
          });

        await axios(axiosData1) */ .then(async (response) => {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                setModal(false);
                getSO();
              });
            });
        } catch (error) {
          console.log(error);

          Swal.fire({
            icon: "error",
            title: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
      }
    }
  }

  const [DataForReport, setDataForReport] = useState({
    data: [],
  });
  let [company, setCompany] = useState([]);

  async function getSaleOrderReport(so_id) {
    await axios({
      method: "get",
      url: Configs.API_URL + "/company/findById?id=" + getUser().com,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setCompany(response.data);
        company = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    //GET DATA FOR REPORT
    const temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      so_id: so_id,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/sale_order/admin/GetReportProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus +
            "/api/product/getProductDetailsForSaleOrder",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: response.data,
        })
          .then(async (response2) => {
            // console.log("response2", response2.data);
            setDataForReport({
              ...DataForReport,
              data: response2.data,
            });
            DataForReport.data = response2.data;
            if (DataForReport.data.SaleProduct.length > 0) {
              let data = new FormData();
              data.append("company_id", getUser().com);
              data.append("oem_id", getOem());
              data.append("user_id", getUser().fup);
              data.append(
                "products",
                JSON.stringify(
                  DataForReport.data.SaleProduct !== undefined
                    ? DataForReport.data.SaleProduct
                    : []
                )
              );

              await axios({
                method: "post",
                url:
                  Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "multipart/form-data",
                },
                data: data,
              })
                .then(async (response3) => {
                  console.log("response3", response3.data);
                  setDataForReport({
                    ...DataForReport,
                    data: {
                      ...DataForReport.data,
                      SaleProduct: response3.data,
                    },
                  });
                  DataForReport.data.SaleProduct = response3.data;
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Error",
                    text: error.response.data.error.message,
                    icon: "error",
                  });
                });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
    if (DataForReport.data.SaleProduct.length > 0) {
      Sale_Order_Report(company, DataForReport.data, false);
    } else {
      Swal.fire({
        title: "ไม่สามารถออก Report ได้",
        text: "ไม่พบข้อมูลสินค้า",
        icon: "error",
      });
    }
  }

  const rowsData1 = [];
  for (let index = 0; index < SOProductData.data.length; index += 1) {
    const rowItem = {};

    // rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    if (SOBill.data[0]?.remark[index] || SOBill.data[0]?.product_list[index]?.bpd_tb_id) {
      for (let BC of Barcode.data) {
        //   for (let REQ of SOProductData.data) {
        if (SOProductData.data[index].sop_pb_id === BC.pb_id) {
          rowItem["barcode"] = BC.pb_piece ? BC.pb_piece : "-";
        }
      }
      // rowItem["barcode"] = SOProductData.data[index].sop_cus_barcode
      //   ? SOProductData.data[index].sop_cus_barcode
      //   : "-";

      rowItem["name"] = SOProductData.data[index].sop_product_name_eng
        ? SOProductData.data[index].sop_product_name_eng
        : "-";

      rowItem["quantity"] = SOProductData.data[index].show_sop_quantity
        ? SOProductData.data[index].show_sop_quantity
        : "-";

      rowItem["topic"] = SOBill.data[0]?.product_list[index]?.tp_name || "";

      rowItem["remark"] = SOBill.data[0]?.remark[index] || ""

      rowItem["edit"] = (
        <div style={{ display: "flex", gap: ".5rem" }}>
          <NumberFormat
            type="text"
            className="form-control"
            // required
            decimalScale={0}
            allowNegative={false}
            // allowLeadingZeros={true}
            disabled={false}
            value={SOProductData.data[index].sop_quantity}
            onChange={(item) => {
              const temp = SOProductData.data;

              temp[index].sop_quantity = item.target.value;
              // temp[index].status = "update";
              // temp[index].statusUpdate = true;

              setSOProductData({
                ...SOProductData,
                data: temp,
              });

              SOProductData.data = temp;
              // saveProductData();
              // calSummaryProductTable();
            }}
          />
          <select
            type="text"
            className="form-control"
            disabled={false}
            value={SOProductData.data[index].sop_quantity_unit}
            onChange={(e) => {
              const temp = SOProductData.data;

              temp[index].sop_quantity_unit = e.target.value;
              // temp[index].status = "update";
              // temp[index].statusUpdate = true;

              setSOProductData({
                ...SOProductData,
                data: temp,
              });

              SOProductData.data = temp;
              // saveProductData();
              // calSummaryProductTable();
            }}
          >
            <option value="piece">Piece</option>
            <option value="pack">Pack</option>
            <option value="ctn">Ctn</option>
          </select>
        </div>
      );

      rowItem["management"] = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a className="btn btn-xs" onClick={() => handleRowDelete(index)}>
            <i className="fas fa-trash-alt" style={{ color: "red" }} />
          </a>
        </div>
      );

      rowsData1.push(rowItem);
    }
  }

  const ProductTable = {
    columns: [
      {
        label: "Barcode",
        field: "barcode",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Quantity",
        field: "quantity",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Topic",
        field: "topic",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Remark",
        field: "remark",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Edit",
        field: "edit",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };

  const rowsData = [];
  for (let index = 0; index < SalesOrder.data.length; index += 1) {
    const date = moment(SalesOrder.data[index].so_date);
    const formattedDate = date.format("DD/MM/YYYY");
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;
    rowItem["sono"] = SalesOrder.data[index].so_no
      ? SalesOrder.data[index].so_no
      : "-";
    rowItem["sodate"] = SalesOrder.data[index].so_date ? formattedDate : "-";
    rowItem["customer"] = SalesOrder.data[index].so_cus_name
      ? SalesOrder.data[index].so_cus_name
      : "-";
    rowItem["pono"] = SalesOrder.data[index].so_ref_po
      ? SalesOrder.data[index].so_ref_po
      : "-";
    rowItem["qtno"] = SalesOrder.data[index].quo_no
      ? SalesOrder.data[index].quo_no
      : "-";
    rowItem["salesname"] = SalesOrder.data[index].so_created_name
      ? SalesOrder.data[index].so_created_name
      : "-";
    rowItem["status"] = SalesOrder.data[index].so_ss_id ? (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <button
          type="button"
          disabled={
            SalesOrder.data[index].so_ss_id ===
              "5b9217d8-5124-404e-a502-fbb9f0ebeff9"
              ? false
              : true
          }
          style={{
            opacity: "1",
            width: "100%",
            border: "4px solid",
            borderRadius: "10px",
            paddingTop: ".2rem",
            paddingBottom: ".2rem",
          }}
          className={`${SalesOrder.data[index].so_ss_id ===
              "5b9217d8-5124-404e-a502-fbb9f0ebeff9"
              ? "btn btn-outline-danger"
              : SalesOrder.data[index].so_ss_id ===
                "e243b1f7-380c-4f9b-9ce0-b2cc5c95ccaf"
                ? "btn btn-outline-success"
                : SalesOrder.data[index].so_ss_id ===
                  "e468ce5d-8638-4c5a-8d82-1bf3c452804b"
                  ? "btn btn-outline-warning"
                  : SalesOrder.data[index].so_ss_id ===
                    "b8f0615a-c177-41f9-b166-9e9fe2f67fa2"
                    ? "btn btn-outline-primary"
                    : SalesOrder.data[index].so_ss_id ===
                      "19a886b6-0131-4542-a711-9332ed4fbf3c"
                      ? "btn btn-outline-danger"
                      : ""
            }`}
          onClick={async () => {
            await getSonoData(SalesOrder.data[index].so_id);
            await getBillfromSO(SalesOrder.data[index].so_id);
            // console.log(SOData);
            setModal(true);
          }}
        >
          {SalesOrder.data[index].ss_name}
        </button>
      </div>
    ) : (
      "-"
    );
    rowItem["report"] = (
      <button
        type="button"
        className="btn btn-block btn-primary"
        onClick={async () => {
          Swal.fire({
            title: "คุณต้องการออก Report ใช่หรือไม่?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: `ใช่`,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: `ไม่`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              await getSaleOrderReport(SalesOrder.data[index].so_id);
            }
          });
        }}
      >
        Report
      </button>
    );
    // : "-";
    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={SalesOrder.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/Sales_Order_SO_Cuteplus/view/" +
                SalesOrder.data[index].so_id;
            }}
          >
            <i class="fas fa-eye"></i>
          </a>
        </div>
        <div className="col-xl-3 col-md-3 col-xs-3 ">
          <a
            key={SalesOrder.data[index]}
            id="btn_delete"
            className=" btn btn-xs "
            hidden={
              SalesOrder.data[index].so_ss_id ===
                "b8f0615a-c177-41f9-b166-9e9fe2f67fa2" ||
                SalesOrder.data[index].so_ss_id ===
                "e243b1f7-380c-4f9b-9ce0-b2cc5c95ccaf" ||
                SalesOrder.data[index].so_ss_id ===
                "19a886b6-0131-4542-a711-9332ed4fbf3c"
                ? true
                : false
            }
            onClick={() => {
              window.location.href =
                "/Sale_cuteplut/Sales_Order_SO_Cuteplus/edit/" +
                SalesOrder.data[index].so_id;
            }}
          >
            <i class="fas fa-pencil-alt"></i>
          </a>
        </div>
        {/* <div
          className="col-xl-3 col-md-3 col-xs-3 "
        >
          <a
            key={SalesOrder.data[index]}
            id={SalesOrder.data[index]}
            className=" btn btn-xs "
            onClick={delKitting.bind(this, SalesOrder.data[index])}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div> */}
      </div>
    );

    rowsData.push(rowItem);
  }

  const SalesOrderTable = {
    columns: [
      {
        label: "No",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "SO No.",
        field: "sono",
        // sort: "asc",
        width: 50,
      },
      {
        label: "SO Date",
        field: "sodate",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        // sort: "asc",
        width: 50,
      },
      {
        label: "QT No.",
        field: "qtno",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Sales Name",
        field: "salesname",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  useEffect(async () => {
    getSO();
    // GetCategory();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>Sales Order</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sale</li>
                  {/* <li className="breadcrumb-item active">
                    Factory Master Data
                  </li> */}
                  <li className="breadcrumb-item active">Sales Order</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.so_no}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        so_no: e.target.value,
                      });
                      Filter.so_no = e.target.value;
                    }}
                  />
                  <label>Sales Order No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <Select
                    styles={{
                      control: base => ({
                        ...base,
                        height: 35,
                        minHeight: 39,
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 999,
                      }),
                    }}
                    required
                    value={
                      CustomerDD?.find(el => el.value === Filter.customer) || { label: "--Select--", value: "" }
                    }
                    options={CustomerDD}
                    filterOption={customFilter}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        customer: e.value,
                      });
                      Filter.customer = e.value;
                    }}
                  >
                    {/* <option value="">--Select--</option>
                    {CustomerDD.map((data) => {
                      return (
                        <option value={data.cus_id}>{data.cus_name}</option>
                      );
                    })} */}
                  </Select>
                  <label>Customer</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    value={status_list_filter}
                    options={statusDD.data}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Status
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      getSO();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Sale_cuteplut/Sales_Order_SO_Cuteplus/add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Sales Order (Sales)</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            data={SalesOrderTable}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            size="lg"
            className="Modal_Center_LeftRight"
            isOpen={modal}
            toggle={() => {
              setModal(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setModal(false);
              }}
            >
              <h4 className="modal-title" id="modal">
                <b>จัดของไม่สำเร็จ</b>
              </h4>
            </ModalHeader>

            <ModalBody>
              <div className="row">
                <div className="col-12 px-3">
                  {/* <div class="form-group"> */}
                  {/* <select
                      type="text"
                      className="form-control"
                      required
                      disabled={false}
                      value={SalesOrder.so_cus_name}
                    >
                      <option key="" value="">
                        --Select Customer--
                      </option>
                    </select> */}
                  <h3
                    style={{
                      lineHeight: "normal",
                      wordBreak: "break-all",
                    }}
                  >
                    {SOData.so_no}
                    {/* {inputData.Supplier_Code === "" ||
                      inputData.Supplier_Code === null ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )} */}
                  </h3>
                  <MDBDataTable
                    sortable={false}
                    striped
                    bordered
                    hover
                    data={ProductTable}
                  />
                  {/* </div> */}
                </div>
              </div>
            </ModalBody>

            <ModalFooter style={{ justifyContent: "space-between" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={async () => {
                  setModal(false);
                }}
              >
                ยกเลิก
              </button>

              <button
                type="button"
                className="btn btn-success"
                onClick={async () => {
                  saveOption();
                }}
              >
                บันทึก
              </button>
            </ModalFooter>
          </Modal>
        </section>
      </div>
    </div>
  );
}

export default Table_Sales_Order;
