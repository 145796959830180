import React from "react";
import { useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../../config";

function Category_AEV({ mode }) {
  const { id } = useParams();

  const location = useLocation();

  const dataReceived = location.state;

  const [pageMode, setPageMode] = useState("");

  const [disable, setdisable] = useState(null);

  const [dataSelect, setDataSelect] = useState({
    data1: [],
    data2: [],
    data2Select: [],
    data3: [],
    data3Select: [],
    data4: [],
  });

  const [infoForSave, setInfoForSave] = useState({
    category1: "",
    category2: "",
    category3: "",
    category4: "",
    category_name: "",
    remark: "",
  });

  const checkSwalError = () => {
    const error_list = [];

    if (dataReceived.level === "lv2") {
      if (infoForSave.category1 === "") {
        let temp_err = {
          message: "กรุณาเลือก Category Lv.1",
        };
        error_list.push(temp_err);
      }
    } else if (dataReceived.level === "lv3") {
      if (infoForSave.category1 === "") {
        let temp_err = {
          message: "กรุณาเลือก Category Lv.1",
        };
        error_list.push(temp_err);
      }
      if (infoForSave.category2 === "") {
        let temp_err = {
          message: "กรุณาเลือก Category Lv.2",
        };
        error_list.push(temp_err);
      }
    } else if (dataReceived.level === "lv4") {
      if (infoForSave.category1 === "") {
        let temp_err = {
          message: "กรุณาเลือก Category Lv.1",
        };
        error_list.push(temp_err);
      }
      if (infoForSave.category2 === "") {
        let temp_err = {
          message: "กรุณาเลือก Category Lv.2",
        };
        error_list.push(temp_err);
      }
      if (infoForSave.category3 === "") {
        let temp_err = {
          message: "กรุณาเลือก Category Lv.3",
        };
        error_list.push(temp_err);
      }
    }

    if (infoForSave.category_name?.trim() === "") {
      let temp_err = {
        message: "กรุณากรอก Category Name",
      };
      error_list.push(temp_err);
    }

    return error_list;
  };

  const saveCategory = () => {
    Swal.fire({
      title:
        mode === "add"
          ? "คุณต้องการที่จะบันทึกใช่หรือไม่"
          : "คุณต้องการที่จะแก้ไขใช่หรือไม่",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const tempErr = checkSwalError();

        if (tempErr.length > 0) {
          var err_message = "";

          for (var e = 0; e < tempErr.length; e++) {
            err_message += "<br/>" + tempErr[e].message;
          }

          Swal.fire("บันทึกข้อมูลไม่สำเร็จ", err_message, "error");
        } else {
          if (mode === "add") {
            const temp = {
              oem_id: getOem(),
              company_id: getUser().com,
              fup: getUser().fup,
              category: infoForSave,
              level: dataReceived.level,
            };

            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            })
              .then(function (response) {
                axios({
                  method: "post",
                  url: Configs.API_URL_cuteplus + "/api/category/addCategory",
                  headers: {
                    Authorization: "Bearer " + getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                  },
                  data: temp,
                })
                  .then(function (response) {
                    if (response.data) {
                      Swal.fire({
                        icon: "success",
                        title: "Save",
                        showConfirmButton: false,
                        timer: 1500,
                      }).then((result) => {
                        if (dataReceived.level === "lv2") {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv2";
                        } else if (dataReceived.level === "lv3") {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv3";
                        } else if (dataReceived.level === "lv4") {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv4";
                        } else {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category";
                        }
                      });
                    }
                  })
                  .catch(function (error) {
                    Swal.fire({
                      title: "Error",
                      text: error.response.data.error.message,
                      icon: "error",
                    });
                  });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          } else if (mode === "edit") {
            const temp = {
              oem_id: getOem(),
              company_id: getUser().com,
              fup: getUser().fup,
              category: infoForSave,
              level: dataReceived.level,
              category_id: id,
            };
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1000,
              onOpen: () => {
                Swal.showLoading();
              },
            })
              .then(function (response) {
                axios({
                  method: "patch",
                  url:
                    Configs.API_URL_cuteplus + "/api/category/updateCategory",
                  headers: {
                    Authorization: "Bearer " + getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                  },
                  data: temp,
                })
                  .then(function (response) {
                    if (response.data) {
                      Swal.fire({
                        icon: "success",
                        title: "Save",
                        showConfirmButton: false,
                        timer: 1500,
                      }).then((result) => {
                        if (dataReceived.level === "lv2") {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv2";
                        } else if (dataReceived.level === "lv3") {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv3";
                        } else if (dataReceived.level === "lv4") {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv4";
                        } else {
                          window.location.href =
                            "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category";
                        }
                      });
                    }
                  })
                  .catch(function (error) {
                    Swal.fire({
                      title: "Error",
                      text: error.response.data.error.message,
                      icon: "error",
                    });
                  });
              })
              .catch(function (error) {
                Swal.fire({
                  title: "Error",
                  text: error.response.data.error.message,
                  icon: "error",
                });
              });
          }
        }
      }
    });
  };

  async function getDropDownLevel(lvl) {
    let pushData = {
      // user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      level: lvl,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/category/getCategoryDropDown",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        if (dataReceived.level === "lv2") {
          setDataSelect({ ...dataSelect, data1: response.data.category1 });
          dataSelect.data1 = response.data.category1;
        } else if (dataReceived.level === "lv3") {
          let lv1Id = infoForSave.category1 ? infoForSave.category1 : null;
          let lv2DD = response.data.category2?.filter((el) => el.cat_parent_id === lv1Id);
          setDataSelect({
            ...dataSelect,
            data1: response.data.category1,
            data2: response.data.category2,
            data2Select: lv2DD,
          });
          dataSelect.data1 = response.data.category1;
          dataSelect.data2 = response.data.category2;
          dataSelect.data2Select = lv2DD;
        } else if (dataReceived.level === "lv4") {
          let lv1Id = infoForSave.category1 ? infoForSave.category1 : null;
          let lv2Id = infoForSave.category2 ? infoForSave.category2 : null;
          let lv2DD = response.data.category2?.filter((el) => el.cat_parent_id === lv1Id);
          let lv3DD = response.data.category3?.filter((el) => el.cat_parent_id === lv2Id);
          setDataSelect({
            ...dataSelect,
            data1: response.data.category1,
            data2: response.data.category2,
            data2Select: lv2DD,
            data3: response.data.category3,
            data3Select: lv3DD,
          });
          dataSelect.data1 = response.data.category1;
          dataSelect.data2 = response.data.category2;
          dataSelect.data2Select = lv2DD;
          dataSelect.data3 = response.data.category3;
          dataSelect.data3Select = lv3DD;
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getCategoryByID() {
    let pushData = {
      // user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      level: dataReceived.level,
      id: id,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/category/getCategoryByID",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        setInfoForSave({
          category1: response.data[0].cat_id1,
          category2: response.data[0].cat_id2 ? response.data[0].cat_id2 : "",
          category3: response.data[0].cat_id3 ? response.data[0].cat_id3 : "",
          category4: response.data[0].cat_id4 ? response.data[0].cat_id4 : "",
          category_name: response.data[0].cat_name,
          remark: response.data[0].cat_remark,
        });

        infoForSave.category1 = response.data[0].cat_id1;
        infoForSave.category2 = response.data[0].cat_id2
          ? response.data[0].cat_id2
          : "";
        infoForSave.category3 = response.data[0].cat_id3
          ? response.data[0].cat_id3
          : "";
        infoForSave.category4 = response.data[0].cat_id4
          ? response.data[0].cat_id4
          : "";
        infoForSave.category_name = response.data[0].category_name;
        infoForSave.remark = response.data[0].cat_remark;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  useEffect(async () => {
    if (mode === "add") {
      setdisable(false);
      setPageMode("Add");
    } else if (mode === "view") {
      setdisable(true);
      setPageMode("Read");
      await getCategoryByID();
    } else {
      setdisable(false);
      setPageMode("Edit");
      await getCategoryByID();
    }
    if (dataReceived.level !== "lv1") {
      await getDropDownLevel(dataReceived.level);
    }
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Product Category {pageMode}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product Category</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            {mode === "view" ? (
              <div className="row mb-2">
                <div className="col-6 col-md-4 col-xl-2">
                  <button
                    type="button"
                    onClick={() => {
                      Swal.fire({
                        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: `Yes`,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: `No`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          if (dataReceived.level === "lv2") {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv2";
                          } else if (dataReceived.level === "lv3") {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv3";
                          } else if (dataReceived.level === "lv4") {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv4";
                          } else {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category";
                          }
                        }
                      });
                    }}
                    className="btn btn-block btn-danger "
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="row mb-2">
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={saveCategory}
                    className="btn btn-block btn-success"
                    id="btn-save"
                  >
                    Save
                  </button>
                </div>
                <div className="col-6 col-md-3 col-xl-1">
                  <button
                    type="button"
                    onClick={() => {
                      Swal.fire({
                        title: "คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: `Yes`,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: `No`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          if (dataReceived.level === "lv2") {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv2";
                          } else if (dataReceived.level === "lv3") {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv3";
                          } else if (dataReceived.level === "lv4") {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category/lv4";
                          } else {
                            window.location.href =
                              "/Warehouse_Management/Factory_Master_Data_Cuteplus/Product_Category";
                          }
                        }
                      });
                    }}
                    className="btn btn-block btn-danger"
                    id="btn-cancle"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}

            <div className="row mb-2 mt-2">
              <div className="col-6 col-md-6 col-xl-6">
                <h4>
                  Category Level :{" "}
                  {dataReceived.level
                    ? dataReceived.level.substring(2, dataReceived.level.length)
                    : ""}
                </h4>
              </div>

              <div className="col-6 col-md-6 col-xl-6">
                <h4>
                  Category Name :{" "}
                  {infoForSave.category_name ? infoForSave.category_name : "-"}
                </h4>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Category {pageMode}</h3>
            </div>

            <div className="card-body">
              <div className="row mb-2">
                <div className="col-6">
                  <h2>
                    <b>Category Detail</b>
                  </h2>
                </div>
              </div>

              {dataReceived.level === "lv2" ? (
                <>
                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <select
                          className="form-control"
                          id="select_dropdown"
                          name="select_dropdown"
                          disabled={ disable ? true : false }
                          value={infoForSave.category1}
                          onChange={(e) => {
                            setInfoForSave({
                              ...infoForSave,
                              category1: e.target.value,
                            });
                            infoForSave.category1 = e.target.value;
                          }}
                        >
                          <option value="" disabled>
                            --- เลือก Category ---
                          </option>
                          {dataSelect.data1.map((item, index) => {
                            return (
                              <option key={index} value={item.cat_id}>
                                {item.cat_name1}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          Category Lv.1
                          {infoForSave.category1 === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {dataReceived.level === "lv3" ? (
                <>
                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <select
                          className="form-control"
                          id="select_dropdown"
                          name="select_dropdown"
                          disabled={ disable ? true : false }
                          value={infoForSave.category1}
                          onChange={(e) => {
                            setInfoForSave({
                              ...infoForSave,
                              category1: e.target.value,
                            });

                            infoForSave.category1 = e.target.value;
                            const temp = dataSelect.data2;

                            let arr = [];
                            arr = temp.filter(
                              (el) => el.cat_parent_id === e.target.value
                            );

                            setDataSelect({ ...dataSelect, data2Select: arr });
                            dataSelect.data2Select = arr;

                            setInfoForSave({
                              ...infoForSave,
                              category2: "",
                              category3: "",
                            });
                            infoForSave.category2 = "";
                            infoForSave.category3 = "";
                          }}
                        >
                          <option value="" disabled>
                            --- เลือก Category ---
                          </option>
                          {dataSelect.data1.map((item, index) => {
                            return (
                              <option key={index} value={item.cat_id}>
                                {item.cat_name1}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          Category Lv.1
                          {infoForSave.category1 === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <select
                          className="form-control"
                          id="select_dropdown"
                          name="select_dropdown"
                          value={infoForSave.category2}
                          disabled={
                            disable
                            ? true 
                            : infoForSave.category1 === ""
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setInfoForSave({
                              ...infoForSave,
                              category2: e.target.value,
                            });
                            infoForSave.category2 = e.target.value;
                          }}
                        >
                          <option value="" disabled>
                            --- เลือก Category ---
                          </option>
                          {dataSelect.data2Select.map((item, index) => {
                            return (
                              <option key={index} value={item.cat_id}>
                                {item.cat_name2}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          Category Lv.2
                          {infoForSave.category2 === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {dataReceived.level === "lv4" ? (
                <>
                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <select
                          className="form-control"
                          id="select_dropdown"
                          name="select_dropdown"
                          disabled={ disable ? true : false }
                          value={infoForSave.category1}
                          onChange={(e) => {
                            setInfoForSave({
                              ...infoForSave,
                              category1: e.target.value,
                            });

                            infoForSave.category1 = e.target.value;
                            const temp = dataSelect.data2;

                            let arr = [];
                            arr = temp.filter(
                              (el) => el.cat_parent_id === e.target.value
                            );

                            setDataSelect({ ...dataSelect, data2Select: arr });
                            dataSelect.data2Select = arr;

                            setInfoForSave({
                              ...infoForSave,
                              category2: "",
                              category3: "",
                            });
                            infoForSave.category2 = "";
                            infoForSave.category3 = "";
                          }}
                        >
                          <option value="" disabled>
                            --- เลือก Category ---
                          </option>
                          {dataSelect.data1.map((item, index) => {
                            return (
                              <option key={index} value={item.cat_id}>
                                {item.cat_name1}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          Category Lv.1
                          {infoForSave.category1 === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <select
                          className="form-control"
                          id="select_dropdown"
                          name="select_dropdown"
                          disabled={disable
                            ? true 
                            : infoForSave.category1 === ""
                              ? true
                              : false
                          }
                          value={infoForSave.category2}
                          onChange={(e) => {
                            setInfoForSave({
                              ...infoForSave,
                              category2: e.target.value,
                            });

                            infoForSave.category2 = e.target.value;
                            const temp = dataSelect.data3;

                            let arr = [];
                            arr = temp.filter(
                              (el) => el.cat_parent_id === e.target.value
                            );

                            setDataSelect({ ...dataSelect, data3Select: arr });
                            dataSelect.data3Select = arr;

                            setInfoForSave({ ...infoForSave, category3: "" });
                            infoForSave.category3 = "";
                          }}
                        >
                          <option value="" disabled>
                            --- เลือก Category ---
                          </option>
                          {dataSelect.data2Select.map((item, index) => {
                            return (
                              <option key={index} value={item.cat_id}>
                                {item.cat_name2}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          Category Lv.2
                          {infoForSave.category2 === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <select
                          className="form-control"
                          id="select_dropdown"
                          name="select_dropdown"
                          disabled={disable
                            ? true 
                            : infoForSave.category1 === "" || infoForSave.category2 === ""
                              ? true
                              : false
                          }
                          value={infoForSave.category3}
                          onChange={(e) => {
                            setInfoForSave({
                              ...infoForSave,
                              category3: e.target.value,
                            });
                            infoForSave.category3 = e.target.value;
                          }}
                        >
                          <option value="" disabled>
                            --- เลือก Category ---
                          </option>
                          {dataSelect.data3Select.map((item, index) => {
                            return (
                              <option key={index} value={item.cat_id}>
                                {item.cat_name3}
                              </option>
                            );
                          })}
                        </select>
                        <label>
                          Category Lv.3
                          {infoForSave.category3 === "" ? (
                            <span style={{ color: "red" }}> *</span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="row">
                <div className="col-12 col-md-5 col-xl-3">
                  <div className="form-group ">
                    <input
                      type="text"
                      className="form-control"
                      required="false"
                      disabled={disable}
                      value={infoForSave.category_name}
                      onChange={(e) => {
                        setInfoForSave({
                          ...infoForSave,
                          category_name: e.target.value,
                        });
                      }}
                    />
                    <label htmlFor="">
                      Category Name
                      {infoForSave.category_name === "" ? (
                        <span style={{ color: "red" }}> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-7 col-xl-6">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={2}
                      disabled={disable}
                      style={{
                        height: "100px",
                        fontSize: "25px",
                      }}
                      value={infoForSave.remark}
                      onChange={(e) =>
                        setInfoForSave({
                          ...infoForSave,
                          remark: e.target.value,
                        })
                      }
                      defaultValue=""
                      required
                    />
                    <label>Remark</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Category_AEV;
