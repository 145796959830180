import React, { useState, useEffect } from "react";

import "../../../../assets/css/ProductService.css";

import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";

function Product_Service_Card({ data }) {
  return (
    <>
      <div
        className="card psecard_size"
        style={{
          // width: "225px",
          // height: "320px",
          border: "solid 1px #003583",
          borderRadius: "15px",
          margin: "0px",
          color: "black",
          backgroundColor: "#F9F9F9",
        }}
      >
        <div
          className="card-body"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px",
            cursor: "pointer",
            position: "relative",
          }}
        >
          {data.discount ? (
            <div
              className="psecard_dis_size"
              style={{
                position: "absolute",
                top: "6px",
                right: "6px",
                // width: "40px",
                // height: "26px",
                backgroundColor: "#ffd938",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <label
                className="psecard_dis_text"
                style={{
                  // fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  margin: "0px",
                  wordBreak: "break-all",
                  cursor: "pointer",
                  color: "#ff0000",
                }}
              >
                {data.discount}%
              </label>
            </div>
          ) : (
            <></>
          )}

          <img
            alt={data.productImageName || "..."}
            style={{
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "60%",
              maxHeight: "60%",
              borderBottom: "solid 1px #003583",
              borderRadius: "15px 15px 0px 0px",
              // objectFit: "contain",
              backgroundColor: "white",
            }}
            src={data.productImagePath || userdefault_img.imgs}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              margin: "5px 10px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label
                className="textVerticalOverflow-1 psecard_text_1"
                style={{
                  // fontSize: "24px",
                  lineHeight: "normal",
                  margin: "0px",
                  cursor: "pointer",
                  wordBreak: "break-all",
                }}
              >
                {data.productName}
              </label>

              <label
                className="textVerticalOverflow-2 psecard_text_2"
                style={{
                  // fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  color: "#8a8a8a",
                  margin: "0px",
                  wordBreak: "break-all",
                  cursor: "pointer",
                }}
              >
                {data.productDetail}
              </label>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {data.discount ? (
                <label
                  className="psecard_text_4"
                  style={{
                    // fontSize: "13px",
                    fontWeight: "700",
                    lineHeight: "normal",
                    color: "#8a8a8a",
                    margin: "0px",
                    wordBreak: "break-all",
                    cursor: "pointer",
                    // textDecoration: "line-through",
                    textDecoration: "underline",
                    textUnderlineOffset: "-28%",
                    textDecorationSkipInk: "none",
                  }}
                >
                  {data.productValue} คะแนน
                </label>
              ) : (
                <></>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <label
                  className="psecard_text_3"
                  style={{
                    // fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "normal",
                    color: "#0696fe",
                    margin: "0px",
                    display: "block",
                    cursor: "pointer",
                  }}
                >
                  {data.discount ? data.discountValue : data.productValue} คะแนน
                </label>

                <label
                  className="psecard_text_4"
                  style={{
                    // fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    color: "#8a8a8a",
                    margin: "0px",
                    wordBreak: "break-all",
                    cursor: "pointer",
                  }}
                >
                  ขายแล้ว {data.productSold || 0} ชิ้น
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product_Service_Card;
