import React, { useState, useEffect, useRef } from "react";

import "../../../../assets/css/ProductService.css";

import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import { numberProduct } from "../../../../routes/imgRoute/imgUrl";
import { productSuggest } from "../../../../routes/imgRoute/imgUrl";

import { LeftButton1, RightButton1 } from "../Incentive_User_icon_img/All_Icon";

function Product_Service_Card_Slider({
  data,
  width,
  height,
  id,
  // allowButton,
  textType,
}) {
  // -------------------- Image Slide 1 --------------------
  // const [current, setCurrentIndex] = useState(0);
  // const length = data.length;

  // function nextSlide(params) {
  //   setCurrentIndex(current === length - 1 ? 0 : current + 1);
  // }

  // function previousSlide(params) {
  //   setCurrentIndex(current === 0 ? length - 1 : current - 1);
  // }

  // console.log(current);

  // if (!Array.isArray(data) || data.length <= 0) {
  //   return null;
  // }

  // return (
  //   <>
  //     <div className="containerStyles">
  //       <div className="sliderStyles">
  //         <button className="slider-left" onClick={previousSlide}>
  //           Left
  //         </button>
  //         <button className="slider-right" onClick={nextSlide}>
  //           Right
  //         </button>
  //         {data.map((item, index) => {
  //           return (
  //             <div
  //               className={index === current ? "slide active" : "slide"}
  //               key={index}
  //             >
  //               {index === current && (
  //                 <img className="slideStyles" src={item.item} />
  //               )}
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   </>
  // );
  // -------------------- Image Slide 1 --------------------

  // -------------------- Image Slide 2 --------------------
  const timerRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [elementSize, setElementSize] = useState({ width: 0, height: 0 });

  const [startAt, setStartAt] = useState(0);
  const [currentAt, setCurrentAt] = useState(0);

  const [allowButton, setAllowButton] = useState(false);

  function nextSlide() {
    let isLastSlide = currentIndex === data.length - 1;
    let newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  function previousSlide() {
    let isFirstSlide = currentIndex === 0;
    let newIndex = isFirstSlide ? data.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  function toSlide(index) {
    setCurrentIndex(index);
  }

  useEffect(() => {
    if (data.length !== 0 && data.length !== 1) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        nextSlide();
      }, 1000 * 10);

      return () => clearTimeout(timerRef.current);
    }
  }, [nextSlide]);

  // const [windowSize, setWindowSize] = useState({
  //   width: undefined,
  //   height: undefined,
  // });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      // setWindowSize({
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      // });

      if (elementSize.width === 0) {
        setElementSize({
          width: width,
          height: height,
        });

        elementSize.width = width;
        elementSize.height = height;
      } else {
        setElementSize({
          width: document
            .getElementById(`containerImageSlide2-${id}`)
            .getBoundingClientRect().width,
          height: document
            .getElementById(`containerImageSlide2-${id}`)
            .getBoundingClientRect().height,
        });

        elementSize.width = document
          .getElementById(`containerImageSlide2-${id}`)
          .getBoundingClientRect().width;
        elementSize.height = document
          .getElementById(`containerImageSlide2-${id}`)
          .getBoundingClientRect().height;
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return (
    <>
      <div
        id={`containerImageSlide2-${id}`}
        className="containerImageSlide2"
        style={{
          maxWidth: `${width}px`,
          maxHeight: `${height}px`,
          aspectRatio: `${width} / ${height}`,
          backgroundColor: "white",
          borderRadius: "10px",
        }}
        onMouseEnter={() => {
          setAllowButton(true);
        }}
        onMouseLeave={() => {
          setAllowButton(false);
        }}
      >
        <div className="slider">
          {allowButton ? (
            <>
              <button className="slider-left" onClick={previousSlide}>
                <LeftButton1 width="15px" height="25px" fill="white" />
              </button>
              <button className="slider-right" onClick={nextSlide}>
                <RightButton1 width="15px" height="25px" fill="white" />
              </button>
            </>
          ) : (
            <></>
          )}

          <div className="containerSlide">
            <div
              className="slides"
              onTouchStart={(e) => {
                setStartAt(e.targetTouches[0].clientX);
              }}
              onTouchMove={(e) => {
                if (startAt !== 0) {
                  setCurrentAt(startAt - e.targetTouches[0].clientX);
                }
              }}
              onTouchEnd={(e) => {
                if (currentAt >= 50 && currentIndex < data.length - 1) {
                  setCurrentIndex(currentIndex + 1);
                } else if (currentAt <= -50 && currentIndex > 0) {
                  setCurrentIndex(currentIndex - 1);
                }

                setStartAt(0);
                setCurrentAt(0);
              }}
              onTouchCancel={(e) => {
                setStartAt(0);
                setCurrentAt(0);
              }}
              style={{
                // width: `${width * data.length}px`,
                // transform: `translateX(${-(
                //   currentIndex * elementSize.width
                // )}px)`,
                transform: `translateX(${-(
                  100 * currentIndex
                )}%) translateX(${-currentAt}px)`,
              }}
            >
              {data.length !== 0 ? (
                data.map((_, index) => {
                  return (
                    <a
                      href={`/Human_Resource/incentive_user/product_service/product_view_details/${data[index].productId}`}
                      style={{
                        maxWidth: `${width}px`,
                        maxHeight: `${height}px`,
                        aspectRatio: `${width} / ${height}`,
                        cursor: "pointer",
                        // width: `${width}px`,
                        // backgroundImage: `url(${data[index].item})`,
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          width: "100%",
                          height: "50%",
                          backgroundImage:
                            "linear-gradient(0.61deg, rgba(0, 0, 0, 0.5) -17.32%, rgba(0, 0, 0, 0.23) 50.2%, rgba(106, 106, 106, 0) 85.68%)",
                        }}
                      />

                      <div
                        style={{
                          width: "calc(100% - 50px)",
                          height: "100%",
                          position: "absolute",
                          minWidth: "93px",
                          maxHeight: "24px",
                          borderRadius: "10px 0px 10px 0px",
                          backgroundColor: "white",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#292E4F",
                        }}
                      >
                        <div
                          style={{
                            width: "calc(100% - 50px)",
                            height: "100%",
                            position: "absolute",
                            top: "3px",
                            left: "3px",
                            minWidth: "93px",
                            maxHeight: "24px",
                            borderRadius: "10px 0px 10px 0px",
                            backgroundColor: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 0)",
                            border: "1px solid white",
                          }}
                        />

                        <label
                          style={{
                            color: "white",
                            fontSize: "calc(100% - 10px)",
                            margin: "5px 0px 0px 0px ",
                          }}
                        >
                          {textType}
                        </label>
                      </div>

                      {/* <img
                        // onClick={() => {
                        //   console.log(
                        //     `containerImageSlide2-${id}`,
                        //     index
                        //   );
                        // }}
                        alt={"..."}
                        src={
                          textType === 1
                            ? productSuggest.img_1
                            : productSuggest.img_2
                        }
                        key={index}
                        className="slide"
                        style={{
                          position: "absolute",
                          // bottom: "-3px",
                          // right: "-11px",
                          // bottom: "-2%",
                          // right: "-6%",
                          // bottom: `calc(100% - ${elementSize.width}px)`,
                          // right: `calc(100% - ${elementSize.height}px)`,
                          width: `calc(100% - 50px)`,
                          height: `calc(100% - 60px)`,
                          aspectRatio: `369 / 288`,
                        }}
                      /> */}

                      <div
                        style={{
                          position: "absolute",
                          left: "10px",
                          bottom: "20px",
                          // width: "2.5px",
                          // height: "18px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "2.5px",
                            height: "18px",
                            borderRadius: "100px",
                            backgroundColor: "white",
                          }}
                        />

                        <label
                          className="textVerticalOverflow-2"
                          style={{
                            width: "50px",
                            color: "white",
                            fontSize: "calc(100% - 10px)",
                            margin: "0px",
                            lineHeight: "normal",
                            marginTop: "1px",
                            marginLeft: "3px",
                          }}
                        >
                          {data[index].productName}
                        </label>
                      </div>

                      <img
                        // onClick={() => {
                        //   console.log(
                        //     `containerImageSlide2-${id}`,
                        //     index
                        //   );
                        // }}
                        alt={"..."}
                        src={
                          index === 0
                            ? numberProduct.img_1
                            : index === 1
                            ? numberProduct.img_2
                            : index === 2
                            ? numberProduct.img_3
                            : index === 3
                            ? numberProduct.img_4
                            : numberProduct.img_5
                        }
                        key={index}
                        className="slide"
                        style={{
                          position: "absolute",
                          // bottom: "-3px",
                          // right: "-11px",
                          bottom: "-2%",
                          right: "-6%",
                          // bottom: `calc(100% - ${elementSize.width}px)`,
                          // right: `calc(100% - ${elementSize.height}px)`,
                          width: `calc(100% - 50px)`,
                          height: `calc(100% - 60px)`,
                          aspectRatio: `369 / 288`,
                        }}
                      />

                      <img
                        // onClick={() => {
                        //   console.log(
                        //     `containerImageSlide2-${id}`,
                        //     index
                        //   );
                        // }}
                        alt={data[index].productImageName || "..."}
                        src={
                          data[index].productImagePath || userdefault_img.imgs
                        }
                        key={index}
                        className="slide"
                        style={{
                          maxWidth: `${width}px`,
                          maxHeight: `${height}px`,
                          aspectRatio: `${width} / ${height}`,
                          // width: `${width}px`,
                          // backgroundImage: `url(${data[index].item})`,
                        }}
                      />
                    </a>
                  );
                })
              ) : (
                <img
                  // onClick={() => {
                  //   console.log(
                  //     `containerImageSlide2-${id}`,
                  //     index
                  //   );
                  // }}
                  alt={"..."}
                  src={userdefault_img.imgs}
                  className="slide"
                  style={{
                    maxWidth: `${width}px`,
                    maxHeight: `${height}px`,
                    aspectRatio: `${width} / ${height}`,
                    // width: `${width}px`,
                    // backgroundImage: `url(${data[index].item})`,
                  }}
                />
              )}
            </div>
          </div>

          <div className="slider-to">
            {data.map((_, index) => {
              return (
                <div
                  key={index}
                  className={
                    currentIndex === index ? "dots-to active" : "dots-to"
                  }
                  onClick={() => toSlide(index)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
  // -------------------- Image Slide 2 --------------------

  // -------------------- Multi Slide --------------------
  // function slideLeft(params) {
  //   var slider = document.getElementById("slider");
  //   slider.scrollLeft = slider.scrollLeft + 500;
  //   console.log(slider.scrollLeft);
  // }

  // function slideRight(params) {
  //   var slider = document.getElementById("slider");
  //   slider.scrollLeft = slider.scrollLeft - 500;
  //   console.log(slider.scrollLeft);
  // }

  // return (
  //   <>
  //     <div className="main-slider-contianer">
  //       <button className="slider-icon left" onClick={slideLeft}>Left</button>
  //       <div id="slider" className="slider">
  //         {data.map((item, index) => {
  //           return <div className="slider-card">{item.item}</div>;
  //         })}
  //       </div>
  //       <button className="slider-icon right" onClick={slideRight}>Right</button>
  //     </div>
  //   </>
  // );
  // -------------------- Multi Slide --------------------
}

export default Product_Service_Card_Slider;
