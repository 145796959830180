import React, { Component, useEffect, useState ,useRef} from "react";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from  'sort-json-array';
import Sidebar from "../../component/Sidebar";
import DatePicker from "react-datepicker";
import axios from "axios";
import QRCode from 'qrcode.react';
import Configs from "../../config";
import { getOem, getToken } from "../../Utils/Common";
import { userdefault_img,logoTTT } from "../../routes/imgRoute/imgUrl";
import NumberFormat from "react-number-format";
import Select from "react-select";
function WIPSSTInOut(params) {
  const [filler_order_product, setFiller_order_product] = useState({
    order_no: "",
    start_date: "",
    end_date: "",
    oem_id: getOem(),
  });
  const rowsData = [];
  const CustomInput = ({ value, onClick }) => ( 
      
    <
    div className = "input-group" >
    < input type = "text"
    className = "form-control float-left"
    onClick = { onClick }
    value = { value }
    /> <div className = "input-group-prepend" >
    <span className = "input-group-text" >
    <i className = "far fa-calendar-alt" />
    </span>
  
    { /*  <label>test</label> */ } 
    </div> 
    </div>
  
  
  )

  const [product_data, setProduct_data] = useState([]);

  const [end_option, setend_option] = useState([]);
  const select_end = useRef();
  const [model, setModel] = useState([]);
  const [oem_id, setOem_id] = useState(getOem());
  const [end_user, setEnd_user] = useState([]);
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id:getOem()
  });
  const [filler_end_user, setFiller_end_user] = useState({
    name: "",
    full_name: "",
  });

  useEffect(() => {
    const script = document.createElement("script");

    script.src = `js/Product`;
    document.body.appendChild(script);
  }, []);

  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };

  const Get_End_user = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/end_user/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product,
    })
      .then(async (response) => {
     
        await setEnd_user(response.data);
        
        console.log(response.data);
        const options = [];

        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.fullname;
          options.push(element);
        });
        setend_option(options);
    
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Getamount = async () => {
    if (
      amount.amount !== null &&
      amount.amount !== undefined &&
      amount.amount !== ""
    ) {
      if (amount.edit_type !== null) {
        const temp_amount = {
          id: amount.id,
          edit_type: amount.edit_type,
          amount: Number(amount.amount.replace(/,/g, "")),
          end_user_id: amount.end_user_id,
        };
        //console.log(temp_amount);
        axios({
          method: "post",
          url: Configs.API_URL + "/product/amountEnd_userInOut",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: temp_amount,
        })
          .then(async (response) => {
            Swal.fire({
              title: "Saving",
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 300,
              onOpen: () => {
                Swal.showLoading();
              },
            }).then((result) => {
              Swal.fire({
                icon: "success",
                title: "Save",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
               /*  window.location.href = "/Warehouse_Management/WIP_IN_OUT"; */
              //  setmessage({
              //   num: "",
              //   name: "",
              // });
              // message.num="";
              // message.name="";
              setamount({
                id: "",
                edit_type: null,
                amount: "",
                end_user_id:"",
              });
              amount.id = "";
              amount.edit_type = null;
              amount.amount = "";
              amount.end_user_id = "";
              window.location.href =
              "/Warehouse_Management/End_user_IN_OUT";


              }).then(()=>{
                Gettable();
                axios({
                  method: "post",
                  url: Configs.API_URL + "/product/filter",
                  headers: {
                    Authorization: getToken(),
                    "X-TTT": Configs.API_TTT,
                    "Content-Type": "application/json",
                  },
                  data: filler_product,
                })
                  .then(function (response) {
                    console.log(response.data);
                    let set_total_view = response.data.filter((element)=>{
                      return element.id === amount.id;
                    })
                   // console.log(set_total_view)
                   // console.log(set_total_view[set_total_view.length - 1].logistic_amount)
                    setamount({
                      ...amount,
                      total: set_total_view[set_total_view.length - 1].end_user_amount || 0,
                      amount:"",
                    });
                    
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
              })
            });
            /* console.log(response.data); */
          })
          .catch(async (error) => {

            Swal.fire("Over Stock!", "Check your amount", "warning")
        .then((resault)=> {
          window.location.href =
                "/Warehouse_Management/End_user_IN_OUT";
        });
            console.log(error);
          });
      } else {
        Swal.fire("check in bound , out bound", "bound can't null", "warning");
      }
    } else {
      Swal.fire("check amount", "amount can't null", "warning");
    }
  };

  useEffect(() => {
    Gettable();
    Getmodel();
    Get_End_user();
  }, []);

  const Getmodel = async () => {
    axios(config_model)
      .then(async (response) => {
        // console.log(response.data);
        setModel(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable = async () => {
    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: filler_product,
    })
      .then(async (response) => {
       let sorted = response.data.sort((a, b) => b.end_user_amount-  a.end_user_amount);
        await setProduct_data(sorted);
          //console.log(response.data);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function form(no, name, id,amount,edit_type) {
    setmessage({ ...message, name: name, no: no });
    setamount({
      ...amount,
      amount: "",
      total: amount,
      id: id,
      lot_no: "",
      edit_type:edit_type,
    });
  }

  const [amount, setamount] = useState({
    id: "",
    edit_type: null,
    end_user_id:"",
    amount: "",
  });

  const [message, setmessage] = useState({
    num: "",
    name: "",
  });
  function addpage() {
    window.location.href =
      "/Warehouse_Management/WIP_SSTInOut/add";
  }
  function handleChange(value) {
    Gettable();
    /* console.log(filler_product); */
  }

  const clearfillter = async () => {
    await setFiller_product({...filler_product, product_model_id: "", name: "", no: "" });
    filler_product.product_model_id = "";
    filler_product.name = "";
    filler_product.no = "";
    Gettable();
  };


  for (let i = 1; i < 10; i++) {
    const rowItem = {}
    rowItem["num"] = i;
    rowItem["no"] = "1235467890";
    rowItem["productlist"] = "dfag5w5e46qqq66";

    rowItem["date"] = "22/02/2565 : 10.30 น.";
    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            //  href={"/Warehouse_Management/Factory_Master_Data/Supplier/read/"+ supplier[index].id}
            // key={supplier[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            //   href={"/Warehouse_Management/Factory_Master_Data/Supplier/edit/"+ supplier[index].id}
            //  key={supplier[index].id}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            //  key={supplier[index].id}
            className=" btn btn-xs "
          //  onClick={deldata.bind(this,supplier[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowItem["datec"] = "23/02/2565 : 10.30 น.";



    rowsData.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No_",
        field: "num",
        sort: "asc",
        width: 50,
      },{
        label: "รหัสใบสั่งซื้อ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "รายการผลิต",
        field: "productlist",
        sort: "asc",
        width: 50,
      },
      {
       label: "วันที่สั่งซื้อ",
       field: "date",
       sort: "asc",
       width: 50,
     },
      
      {
        label: "วันที่สั่งผลิต",
        field: "datec",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };
  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }
  function showmessage() {
      Swal.fire({
        icon: "info",
        title: "Waiting for connect service API QR",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
  
    }

    async function SetDate_start(date){
      setFiller_order_product({ ...filler_order_product, start_date: date})
  
     }
      async function SetDate_end(date){
        setFiller_order_product({ ...filler_order_product, end_date: date})
  
     }
    
  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="end_userinout" submenu="none" /> */}
      <div className="content-wrapper">
       
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>WIP_SST IN/OUT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">WIP SST IN/OUT</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                <Select
                          // options={product_model_option}
                          // defaultValue={filler_product.product_model_id}
                          // ref = {selectProduct_model}
                          // onChange={(e) => {
                          //  // console.log(e);
                          //  if(e !== null){
                          //   setFiller_product({
                          //     ...filler_product,
                          //     product_model_id: e.value,
                          //   });
                          //  }
                          
                          // }}
                        />
                         <label htmlFor="">รหัสใบสั่งซื้อ</label>
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
                   
            <div className="col-md-0.5">
        <label > Date &nbsp;</label>
        </div>
              <div className="col-md-2">
                <div className="form-group ">
                <DatePicker selected = {filler_order_product.start_date}
        dateFormat = {"dd-MM-yyyy"}
        onChange = {
          async(date) =>
          SetDate_start(date)   
          }
        selectsStart startDate = {filler_order_product.start_date}
        endDate = {filler_order_product.end_date}
        customInput = {<CustomInput/>}
        
        /> 
                </div>
              </div>
              <div className="col-md-0.5">
        <label>&nbsp;&nbsp;  Between  &nbsp;&nbsp;</label> 
        </div> 
              <div className="col-md-2">
                <div className="form-group ">
                <DatePicker 
        selected = {filler_order_product.end_date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>
          SetDate_end(date)   
          }
        selectsEnd startDate = { filler_order_product.start_date }
        endDate = { filler_order_product.end_date }
        minDate = { filler_order_product.start_date }
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
                </div>
              </div>
              <div className="col-md-2">
            {/*     <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select>
                  <label htmlFor="">Category/Model</label>
                </div> */}
                {/*   <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
                <div className="form-group ">
                <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
         
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                 
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                 
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      Gettable();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      clearfillter();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>

              {/*
              <div className="col-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary" onClick={showmessage}>
                    QR Code
                  </button>
                </div>
              </div>
              */}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">WIP_SST IN/OUT</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                sortable = {false}
                  className="table table-head-fixed"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
       <div className="modal fade" id="modal-xl">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Edit WIP_SST Amount : Items in warehouse &nbsp;&nbsp;&nbsp;
                  {
                    <NumberFormat
                      style={
                        { backgroundColor: "#F0FFFF", border: "1px solid #ddd", padding: "10px", width: "100" }}
                      value={amount.total}
                      displayType={"text"}
                      thousandSeparator={true}
                    />}

                  &nbsp;&nbsp;&nbsp; items </h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-2"></div>
                <div className="col-4">
                  <h3>Product No : {message.no}</h3>
                  <input
                    type="radio"
                    id="inbound"
                    name="edit_type"
                    checked={amount.edit_type !=true ? false:true}
                    value={true}
                    onChange={(e) => {
                      setamount({
                        ...amount,
                        edit_type: e.target.value === "false" ? false : true,
                      });
                    }}
                  />

                  <label htmlFor="inbound">
                    <h4>  In bound</h4>
                  </label>
                </div>

                <div className="col-4">
                  <h3>Product Name : {message.name}</h3>

                  <div className="row"></div>

                  <input
                    type="radio"
                    id="outbound"
                    name="edit_type"
                    checked={amount.edit_type !=false ? false:true}
                    value={false}
                    onChange={(e) => {
                      setamount({
                        ...amount,
                        edit_type: e.target.value === "false" ? false : true,
                      });
                    }}
                  />
                  <label htmlFor="outbound">
                    <h4>  Out bound</h4>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4">
                    <div className="form-group ">
 
                    <Select
                          options={end_option}
                          ref = {select_end}
                          defaultValue={amount.end_user_id}
                          onChange={(e) => {
                            //console.log(e);
                            if(e !== null){
                              setamount({
                              ...amount,
                              end_user_id: e.value || "",
                            });
                            }
                           
                          }}
                        />                 
 


                               {/* <select
                     className="custom-select select2 form-control"
                     value={amount.end_user_id}
                     
                     onChange={(e) => {
                       setamount({
                         ...amount,
                         end_user_id: e.target.value,
                       });
                     }}
                   >
                     <option value="" disabled selected>
                       Select End_user
                     </option>
                     {end_user.map((el) => {
                       return <option value={el.id}>{el.name}</option>;
                     })}
                   </select> */}
                   <label htmlFor="">WIP SST</label>
                 </div>
                 </div>
                 <div className="col-4"></div>
                 <div className="col-4"></div>
                <div className="col-4">
                  <div className="form-group ">
                    <NumberFormat
                    format="################"
                      thousandSeparator={true}
                      type="text"
                      className="form-control"
                      value={amount.amount}
                      onChange={(e) => {
                        setamount({
                          ...amount,
                          amount: e.target.value,
                        });
                      }}
                      required
                    />
                    <label htmlFor="">Amount In</label>{" "}
                  </div>
                </div>

                <div className="col-4"></div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={Getamount}
                data-dismiss="modal"
                aria-label="Close"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
     
                    {/*   <Footter/> */}
                      </div>
  );
}
export default WIPSSTInOut;
