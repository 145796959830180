import React, { useEffect, useState } from "react";
import img_header_ticket from "../../assets/img/IMG_Ticket_Management/img_header_ticket.png";
import img_lock from "../../assets/img/IMG_Ticket_Management/lock.png";
import { useLocation } from "react-router-dom";
/* import axios from "axios";
import Configs from "../../../config";
import Swal from "sweetalert2"; */

function NonPermission(params) {
  const location = useLocation();

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid" style={{ marginTop: "10px" }}>
          <div className="row">
            <div className="col-12 col-sm-6" style={{ display: "flex" }}>
              <img
                alt="..."
                className="img_header_ticket"
                src={img_header_ticket}
                width={30}
                height={28}
              />

              <h1
                style={{
                  fontWeight: 700,
                  fontSize: "55px",
                  marginLeft: "10px",
                  lineHeight: "30px",
                }}
              >
                {location.state?.menu || "No Permission Ticket"}
                {/* <a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a> */}
              </h1>
            </div>

            <div className="col-12 col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">
                  {/* <li className="breadcrumb-item">
                  <a href="#">Home</a> */}
                  Home
                </li>

                <li className="breadcrumb-item active">
                  {location.state?.module || "No Permission Ticket"}
                </li>

                <li className="breadcrumb-item">
                  {location.state?.menu || "No Permission Ticket"}
                </li>
              </ol>
            </div>
          </div>

          <div style={{ position: "relative", minHeight: "80vh" }}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    maxWidth: "165px",
                    maxHeight: "165px",
                    aspectRatio: "1/1",
                  }}
                >
                  <img
                    alt="..."
                    src={img_lock}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>

              <div
                style={{
                  fontSize: "44px",
                  fontWeight: "700",
                  color: "#677E97",
                  textAlign: "center",
                  // whiteSpace: "nowrap",
                }}
              >
                Access denied.
              </div>

              <div
                style={{
                  fontSize: "44px",
                  fontWeight: "700",
                  color: "#677E97",
                  textAlign: "center",
                  // whiteSpace: "nowrap",
                }}
              >
                Please contact the administrator.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default NonPermission;
