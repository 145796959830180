import React, { Component, useEffect, useState,useRef } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import { Get_font_pdf_th, Get_font_pdf_th2 } from "../../assets/font/pdf_font";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature, getComConfig,getAcademy } from "../../Utils/Common";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import {
  daily_delivery_report,
  daily_sale_report,
  daily_travel_report,
  month_travel_report,
  Repair_Cost_Report,
  monthly_fuel_consumption_summary,
  Wire_mace_STP,
  self_pickup_report
} from "../../component/Report";
/* import { Chart } from 'react-charts'; */
import Chart from "react-google-charts";
import html2canvas from "html2canvas";
function Report_pdf(params) {
  const [company, setcompany] = useState();
  const [companyGroup, setCompanyGroup] = useState([]);
  const selectCompanyGroup = useRef();
  const [all_OEM, setall_OEM] = useState([]);
  const [Oem,setOem] = useState([]);
  const [logistic_status, setLogistic_status] = useState([]);

  ///dev
 /*  const addressOEM =[
    {oem_id:"b717f172-5013-4aa3-a040-65f4f116870b",oem_name:"บริษัท สตูลค้าเหล็ก จำกัด",address:"59 ม.5 ต.ย่านซื่อ อ.ควนโดน จ.สตูล 91160 โทร. 074-795572, 086-4883872",r:188,g:240,b:136},
    {oem_id:"08721f6f-ee1d-4900-8d67-2c7ce03b601e",oem_name:"บริษัท เอสทีสตีล(2001) จำกัด",address:"1205/2 หมู่ที่ 4 ถ.เพชรเกษม ต.ควนลัง อ.หาดใหญ่ จ.สงขลา 90110 โทร. 074-292421, 099-3647799",r:136,g:209,b:240},
    {oem_id:"c8de2754-d8f9-4e18-97db-4201efca6e2f",oem_name:"บริษัท เอสทีสตีล(2001) ปัตตานี จำกัด",address:"60/12 ม.4 ต.บางเขา อ.หนองจิก จ.ปัตตานี 94170 โทร. 073-330657, 062-0865888",r:199,g:215,b:138},
    {oem_id:"d937f81b-9743-4c33-886f-e1c575f4cf21",oem_name:"บริษัท เอสที คอนกรีตโปรดักส์ จำกัด",address:"59 ม.5 ถ.ยนตรการกำธร ต.ย่านซื่อ อ.ควนโดน จ.สตูล 91160 โทร. 074-795400, 095-0369128",r:249,g:194,b:194},
    {oem_id:"360e9f89-6b60-4edd-9f4d-a6e487c97cf3",oem_name:"บริษัท เอสทีซีวิล(2001) จำกัด",address:"12 ม.5 ถ.ยนตรการกำธร ต.ย่านซื่อ อ.ควนโดน จ.สตูล 91160 โทร. 062-2451473, 094-5875999",r:246,g:217,b:122}
  ];  */
///dev


  ///production
  const addressOEM =[
    {oem_id:"95a64745-2908-4194-b27a-254bdb7426ed",oem_name:"บริษัท สตูลค้าเหล็ก จำกัด",address:"59 ม.5 ต.ย่านซื่อ อ.ควนโดน จ.สตูล 91160 โทร. 074-795572, 086-4883872" ,r:188,g:240,b:136},
    {oem_id:"94c849f1-1084-4e38-85e7-e8bc4d8a8d22",oem_name:"บริษัท เอสทีสตีล(2001) จำกัด",address:"1205/2 หมู่ที่ 4 ถ.เพชรเกษม ต.ควนลัง อ.หาดใหญ่ จ.สงขลา 90110 โทร. 074-292421, 099-3647799",r:136,g:209,b:240},
    {oem_id:"b59e702b-6b00-4c15-8de7-292c3f528ebe",oem_name:"บริษัท เอสทีสตีล(2001) ปัตตานี จำกัด",address:"60/12 ม.4 ต.บางเขา อ.หนองจิก จ.ปัตตานี 94170 โทร. 073-330657, 062-0865888",r:199,g:215,b:138},
    {oem_id:"56d89897-b654-43c9-99a5-8ece3dd676b1",oem_name:"บริษัท เอสที คอนกรีตโปรดักส์ จำกัด",address:"59 ม.5 ถ.ยนตรการกำธร ต.ย่านซื่อ อ.ควนโดน จ.สตูล 91160 โทร. 074-795400, 095-0369128",r:249,g:194,b:194},
    {oem_id:"e2b57288-576b-4e69-b083-83ab484af480",oem_name:"บริษัท เอสทีซีวิล(2001) จำกัด",address:"12 ม.5 ถ.ยนตรการกำธร ต.ย่านซื่อ อ.ควนโดน จ.สตูล 91160 โทร. 062-2451473, 094-5875999",r:246,g:217,b:122}
  ];
  ///production

  const [report_data, setreport_data] = useState([]);
   
  const [filler_report_record, setfiller_report_record] = useState({
    start_date: new Date(),
    oem_id: getOem(),
    oem_name:""
   
  });
  async function SetDate_start(date) {
    setfiller_report_record({ ...filler_report_record, start_date: date 
    });
    filler_report_record.start_date = date;
  }
 
  async function setStartDate(date) {
    set_report_repair_cost({
      ...report_repair_cost, 
      start_month: date,
    });

  }
  
  const [year, setYear] = useState([
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
    { label: "2026", value: 2026 },
    { label: "2026", value: 2026 },
    { label: "2027", value: 2027 },
    { label: "2028", value: 2028 },
    { label: "2029", value: 2029 },
    { label: "2030", value: 2030 },
  ]);

  const [mounth, setMounth] = useState([
    { label: "มกราคม", value: 1 },
    { label: "กุมภาพันธ์", value: 2 },
    { label: "มีนาคม", value: 3 },
    { label: "เมษายน", value: 4 },
    { label: "พฤษภาคม", value: 5 },
    { label: "มิถุนายน", value: 6 },
    { label: "กรกฎาคม", value: 7 },
    { label: "สิงหาคม", value: 8 },
    { label: "กันยายน", value: 9 },
    { label: "ตุลาคม", value: 10 },
    { label: "พฤศจิกายน", value: 11 },
    { label: "ธันวาคม", value: 12 },
  ]);

  const [stcData, setStcData] = useState([]);

  const [GetMatSTC, setMatSTC] = useState([]);
  const [month_gen_table, setMounth_gen_table] = useState(
    moment("2020-04", "YYYY-MM").daysInMonth()
  );
  const [currentViewYearMonth, setCurrentViewYearMonth] = useState({
    month: moment("2020-04", "YYYY-MM").startOf("month").format("MMMM"),
    year: moment("2020-04", "YYYY").year(),
  });
/*   const [highlightToday, sethighlightToday] = useState({
    date_toda: moment(new Date(), "YYYY-MM-DD").date(),
  }); */


  const [oem_list_data, setoem_list_data] = useState({
    oem_name:"",
    oem_address:"",

  });
  const [data_HR, setdata_HR] = useState([]);
  const CustomInput = ({ value, onClick }) => ( 
      
    <
    div className = "input-group" >
    < input type = "text"
    className = "form-control float-left"
    onClick = { onClick }
    value = { value }
    /> <div className = "input-group-prepend" >
    <span className = "input-group-text" >
    <i className = "far fa-calendar-alt" />
    </span>
  
    { /*  <label>test</label> */ } 
    </div> 
    </div>
  );

  const [filter_logistic, setFilter_logistic] = useState({
      oem_target: getOem(),
    company_id: getUser().com,
    oem_id:getOem(),
    date:new Date(),
    fuelday: 0,
});
const [logistic, setLogistic] = useState([]);


 var config_com = {
    method: "get",
    url: Configs.API_URL + "/company/findById?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };
  var config_alloem = {
    method: "get",
    url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
    headers: {
      Authorization: getToken(),
      "Content-Type": "application/json",
      "X-TTT": Configs.API_TTT,
    },
  };

  ///////////////////////////////รายงานเที่ยววิ่งประจำเดือน//////////////////////////////////////
const [month_travel_chart ,setmonth_travel_chart] = useState([]);
const [option_month_chart ,setoption_month_chart] = useState({
  chart: {
    title: 'เที่ยววิ่งประจำเดือน',
    subtitle: '',
  },
  bars: 'vertical',
  /* bar: {groupWidth: "10%"}, */
  backgroundColor: '#E4E4E4',
  is3D: true,
  vAxis: {
    minValue: 0,
    format: '',
    title: "จำนวนเที่ยว", 

  },

  colors: ['(0,76,153)']
});

const GetLogistic_month = async () => {
    
  //console.log(filler_logistic);
  axios({
    method: "post",
    url: Configs.API_URL + "/report_pdf/GetMonth_travel_reportData",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filter_logistic,
  })
    .then(function (response) {
      // console.log(response.data);
      var tempData_chart = [["Element","จำนวนเที่ยว" ,{role:"style"},{role:'annotation'}]];
     /*  var target = addressOEM.filter((e)=>{return e.oem_id === filter_logistic.oem_target});
      var color = '('+target[0].r+','+target[0].g+','+target[0].b+')'; */
      if(response.data.length < 4){
        setoption_month_chart({...option_month_chart,bar: {groupWidth: "10%"}});
        option_month_chart.bar = {groupWidth: "10%"}; 
      }else{
        setoption_month_chart({...option_month_chart,bar: {groupWidth: "75%"}});
        option_month_chart.bar = {groupWidth: "75%"}; 
      }
      for(let item of response.data) {
        tempData_chart.push([item.plate_number,item.bill_logistic.length,'(0,76,153)',item.bill_logistic.length]);
        //tempData_chart.push([item.plate_number,item.bill_logistic.length,'stroke-color: #703593;stroke-width: 4; fill-color: #C5A5CF',item.bill_logistic.length]);
      }
  
      setmonth_travel_chart(tempData_chart);
      settesthide({...testhide,test:"block"});
      testhide.test = "block";
      var chart_pf2 = "";
      html2canvas(document.getElementById("print_to_pdf")).then(canvas => {
         chart_pf2 = canvas.toDataURL();
         settesthide({...testhide,test:"none"});
         testhide.test = "none";
         //console.log(chart_pf2);
       month_travel_report(company,addressOEM,response.data,filter_logistic.date,filter_logistic.oem_target,Oem,chart_pf2); 
       }); 
    })
    .catch(function (error) {
      console.log(error);
    });
};
///////////////////////////////รายงานเที่ยววิ่งประจำเดือน//////////////////////////////////////

///////////////////////////////รายงานเที่ยววิ่งประจำวัน//////////////////////////////////////
const GetLogistic = async () => {
    
  //console.log(filler_logistic);
  axios({
    method: "post",
    url: Configs.API_URL + "/logistic/filterLogisticInOutReport",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filter_logistic,
  })
    .then(function (response) {
      // console.log(response.data);
      setLogistic(response.data);
       daily_travel_report(company,addressOEM,response.data,filter_logistic.date,filter_logistic.oem_target,Oem);
    })
    .catch(function (error) {
      console.log(error);
    });
};
///////////////////////////////รายงานเที่ยววิ่งประจำวัน//////////////////////////////////////


///////////////////////////////รายงานค้าส่งประจำวัน//////////////////////////////////////
const GetSaleDaily= async () => {
  
  const tempfilter_report = {
    
    company_id:getUser().com,
    oem_id:filler_report_record.oem_id,
   dateFrom:filler_report_record.start_date,
   
  
  }
  //console.log(filler_logistic);
  axios({
    method: "post",
    url: Configs.API_URL + "/report_pdf/GetDaily_outstanding_bill",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: tempfilter_report,
  })
  

  
    .then(function (response) {
      /*  console.log(response.data); */
       if (response.data.temp1 < 1) {
        Swal.fire({
          icon: "error",
          title: "ไม่มีข้อมูล",
        });
      } else {
      axios.get('https://script.google.com/macros/s/AKfycbz833PMLB169WLSmWTBRg4CVX_5yMnhAnrvfg6LMtu7xHAvZpinXW34qMqcj8xp2ExQ9Q/exec').then(function(response2){

      
        var tempData = [];
        for(var index = 0; index < response2.data.length; index++){
          let temp = {


                Company:response2.data[index].oem_id,
                sum:response2.data[index].all_employee,
                Department:response2.data[index].department,
                come:response2.data[index].day_shift,
                afternoon_phase:response2.data[index].night_shift,
                work_leave:response2.data[index].personal_leave,
                summer_leave:response2.data[index].leave,
                sick_leave:response2.data[index].sick_leave,
                dont_come:response2.data[index].absence,
                stop_leave:response2.data[index].holiday,
                day_off:response2.data[index].special_holiday,
                resign:response2.data[index].Resign,
  
          }
          tempData.push(temp);
          //console.log(response.data[index]["สังกัด"])
        }
      
        //setdata_HR(tempData);
        daily_sale_report(company,addressOEM,response.data.temp1,response.data.tempall,response.data.temp5,response.data.tempdriver,filter_logistic.date,filter_logistic.oem_target,Oem,response.data.temp3,tempData,response.data.allvalus);
     
    // console.log(tempData)
      }).catch(function (error) {
        console.log(error);
      });
     // daily_sale_report(company,addressOEM,response.data,filter_logistic.date,filter_logistic.oem_target,Oem);
    }
    })
    
    .catch(function (error) {
      console.log(error);
      
    });
    
};
///////////////////////////////รายงานค้าส่งประจำวัน//////////////////////////////////////

useEffect(() => {
  axios(config_alloem)
  .then(function (response) {
 
    const options = [];

/*     var element2 = {};
    element2["value"] = "";
    element2["label"] = "---ทั้งหมด---";
    options.push(element2); */
    response.data.forEach((el) => {
      var element = {};
      element["value"] = el.id;
      element["label"] = el.name;
      options.push(element);
    });
   
    //console.log(options);
    setCompanyGroup(options);
  })
  .catch(function (error) {
    console.log(error);
  });


 // Getmodel_category();
}, []);






const [month_fuel_chart ,setmonth_fuel_chart] = useState([]);
const [option_month_fuel_chart ,setoption_month_fuel_chart] = useState({
  chart: {
    title: 'การใช้น้ำมัน',
    subtitle: '',
  },
  bars: 'vertical',
  /* bar: {groupWidth: "10%"}, */
  backgroundColor: '#E4E4E4',
  is3D: true,
  vAxis: {
    minValue: 0,
    format: '',
    title: "การใช้น้ำมัน", 

  },
  
 
  
});



const Getfuelmonth = async () => {

  if (filter_logistic.fuelday == 0) {
       Swal.fire({
         icon: "error",
         title: "กรุณากรอกราคาน้ำมัน ",
       });
     } else {
 
   axios({
     method: "post",
     url: Configs.API_URL + "/report_pdf/Getmonthly_fuel_consumption_summary",
     headers: {
       Authorization: getToken(),
       "X-TTT": Configs.API_TTT,
       "Content-Type": "application/json",
     },
     
     data: filter_logistic
     
    
   })
   
   .then(function (response) {
   //  console.log(response)
    setLogistic(response.data.temp1,response.data.temp2,response.data.temp3,response.data.temp4);
   
   
   if (response.data.fuel_chat < 1) {
     Swal.fire({
       icon: "error",
       title: "ไม่มีข้อมูล",
     });
   } else {
    
     // console.log(response.data);
     var tempData_chart = [["Element","การใช้น้ำมัน" ,{role:"style"},{role:'annotation'}]];
 
      if(response.data.fuel_chat.length < 4){
       setoption_month_fuel_chart({...option_month_fuel_chart,bar: {groupWidth: "10%"}});
       option_month_fuel_chart.bar = {groupWidth: "10%"} ; 
      }else{
       setoption_month_fuel_chart({...option_month_fuel_chart,bar: {groupWidth: "75%"}});
       option_month_fuel_chart.bar = {groupWidth: "75%"} ; 
      }
      for(let item of response.data.fuel_chat) {
       //console.log("sss",response.data.fuel_chat)
       tempData_chart.push([item.plate_number1,parseFloat(item.liter1),'(0,76,153)',parseFloat(item.liter1)]);
        
        //tempData_chart.push([item.plate_number,item.bill_logistic.length,'stroke-color: #703593;stroke-width: 4; fill-color: #C5A5CF',item.bill_logistic.length]);
      }
     
      setmonth_fuel_chart(tempData_chart);
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
  
 
      settesthidefuel({...testhidefuel,test:"block"});
      testhidefuel.test = "block";
      var chart_pf5 = "";
      html2canvas(document.getElementById("print_to_monthfuelpdf")).then(canvas => {
       chart_pf5 = canvas.toDataURL();
       settesthidefuel({...testhidefuel,test:"none"});
       testhidefuel.test = "none";
       //console.log(chart_pf2);
      monthly_fuel_consumption_summary(company,addressOEM,response.data.tempad,response.data.tempac,response.data.temp3,response.data.temp4,filter_logistic.date,filter_logistic.oem_target,Oem,filter_logistic.fuelday,chart_pf5)
 /*         daily_delivery_report(company,addressOEM,response.data,Oem) */
 }); 
 setFilter_logistic({
   ...filter_logistic,
   fuelday: ""
 })
 }
  })
 
  .catch(function (error) {
    console.log(error);
  });
 
 }
   };




   const GetWire_mace_STP = async () => {
 
    axios({
      method: "post",
      url: Configs.API_URL + "/report_pdf/GetWire_mace_STP",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      
      data: filter_logistic
      
     
    })

    
    
    .then(function (response) {
    console.log(response.data)
     setLogistic(response.data); 
     

     if (response.data.data_all < 1) {
      Swal.fire({
        icon: "error",
        title: "ไม่มีข้อมูล",
      });
    } else {
  

     axios.get('https://script.google.com/macros/s/AKfycbz833PMLB169WLSmWTBRg4CVX_5yMnhAnrvfg6LMtu7xHAvZpinXW34qMqcj8xp2ExQ9Q/exec').then(function(response2){

   /*    console.log(response2) */
      var tempData = [];
      for(var index = 0; index < response2.data.length; index++){
        let temp = {


              Company:response2.data[index].oem_id,
              sum:response2.data[index].all_employee,
              Department:response2.data[index].department,
              come:response2.data[index].day_shift,
              afternoon_phase:response2.data[index].night_shift,
              work_leave:response2.data[index].personal_leave,
              summer_leave:response2.data[index].leave,
              sick_leave:response2.data[index].sick_leave,
              dont_come:response2.data[index].absence,
              stop_leave:response2.data[index].holiday,
              day_off:response2.data[index].special_holiday,
              resign:response2.data[index].Resign,

        }
        tempData.push(temp);
        //console.log(response.data[index]["สังกัด"])
      }
     
     Wire_mace_STP(company,addressOEM,filter_logistic.oem_target,Oem,filter_logistic.date,response.data.data_all,response.data.fg_am,response.data.STP_am,response2.data,response.data.cus_pro,response.data.end_pro,response.data.df_stp)
     
  })
  
  .catch(function (error) {
    console.log(error);
  });
}})

.catch(function (error) {
  console.log(error);
});

    }

  
   
  
  
 
  
  
   








   







// =========== ค่าซ่อม ============================
 const [report_repair_cost, set_report_repair_cost] = useState({
  start_month: new Date(),
  oem_id: getOem(),
});
const [repair_cost, setrepair_cost] = useState([]);
const [repair_cost_trimat, setrepair_cost_trimat] = useState([]);

async function setStartDate(date) {
  set_report_repair_cost({
    ...report_repair_cost, 
    start_month: date,
  });

}

const [month_Cost_Repair_chart , setmonth_Cost_Repair_chart] = useState([]);
const [option_Cost_Repair_chart, setoption_Cost_Repair_chart] = useState({
  chart: {
    title: 'ค่าซ่อม',
    subtitle: '',
  },
  barsa: 'vertical',
  /* bar: {groupWidth: "10%"}, */
  backgroundColor: '#E4E4E4',
  is3D: true,
  vAxis: {
    minValue: 0,
    format: '',
    title: "ราคา", 

  },

  colors: ['(0,76,153)']
});
const [option_Cost_Repair_chart_trimat, setoption_Cost_Repair_chart_trimat] = useState({
  chart: {
    title: 'ค่าซ่อมเเต่ละเดือน',
    subtitle: '',
  },
  barsa: 'vertical',
  /* bar: {groupWidth: "10%"}, */
  backgroundColor: '#E4E4E4',
  is3D: true,
  vAxis: {
    minValue: 0,
    format: '',
    title: "ค่าซ่อม", 

  },

  colors: ['(0,76,153)']
});

const saveRepair_Cost_Report = async() =>{
/*   console.log("เทสเดือน Repair_Cost_Report : ",report_repair_cost.start_month);
  console.log("Oem Repair_Cost_Report : ",report_repair_cost.oem_id); */
  if(report_repair_cost.start_month == ""){
    Swal.fire({
      icon: 'error',
      title: 'กรุณาเลือกเดือนที่จะออกรายงาน',
    })
  }
    const tempfilter_Repair_cost = {
    company_id: getUser().com,
    oem_id: report_repair_cost.oem_id,
    dateFrom: report_repair_cost.start_month,
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/report_pdf/fileter_Rpair_Cost_Report",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempfilter_Repair_cost,
    })
    .then(function(response){
     // console.log("ข้อมูล",response.data);
      var tempRepair_chart = [["Element","ค่าซ่อม" ,{role:"style"},{role:'annotation'}]];
      if(response.data.length < 4 ){
        setoption_Cost_Repair_chart({...option_month_chart,barsa: {groupWidth: "10%"}});
        option_Cost_Repair_chart.barsa = {groupWidth: "10%"}; 
      }else{
        setoption_Cost_Repair_chart({...option_month_chart,barsa: {groupWidth: "10%"}});
        option_Cost_Repair_chart.barsa = {groupWidth: "75%"}; 
      }
      for(let Repair of response.data.cheak){
        tempRepair_chart.push([Repair.plate_number1,Repair.sumplate1,'(0,76,153)',Repair.sumplate1])
      }
       setrepair_cost(tempRepair_chart);
    var tempChart_month = [["Element","ค่าซ่อมเเต่ละเดือน" ,{role:"style"},{role:'annotation'}]];
    setoption_Cost_Repair_chart_trimat({...option_month_chart,barsa: {groupWidth: "10%"}});
    option_Cost_Repair_chart_trimat.barsa = {groupWidth: "75%"}; 
    for(let trimat of response.data.costall1){
      tempChart_month.push([trimat.mon_name,parseFloat(trimat.price),'(0,76,153)',parseFloat(trimat.price)])
    }
    setrepair_cost_trimat(tempChart_month);
 //console.log("tempChart_month",tempChart_month);
      var chary_pdf_repair_cost = ""; //print_Cost_Repairto_pdf2
      var chary_pdf_repair_cost2 = ""; //print_Cost_Repairto_pdf2
      settesthidefuel({...testhidefuel,test:"block"});
      testhidefuel.test = "block";
      html2canvas(document.getElementById("print_Cost_Repairto_pdf")).then(canvas => {
      chary_pdf_repair_cost = canvas.toDataURL();
      html2canvas(document.getElementById("print_Cost_Repairto_pdf2")).then(canvas2 => {
        chary_pdf_repair_cost2 = canvas2.toDataURL();
        settesthidefuel({...testhidefuel,test:"none"});
        testhidefuel.test = "none";
  
        Repair_Cost_Report(company,addressOEM,response.data,report_repair_cost.start_month, report_repair_cost.oem_id, Oem,chary_pdf_repair_cost,chary_pdf_repair_cost2);
        //company, addressOEM, response.data, report_repair_cost.date, report_repair_cost.oem_target, Oem);
      });


      //Repair_Cost_Report(company,addressOEM,response.data,report_repair_cost.start_month, report_repair_cost.oem_id, Oem,chary_pdf_repair_cost);
      //company, addressOEM, response.data, report_repair_cost.date, report_repair_cost.oem_target, Oem);
    });

  })
    .catch(function (error) {
      console.log(error);
    });

    // Repair_Cost_Report(company,addressOEM,response.data,report_repair_cost.start_month, report_repair_cost.oem_id, Oem);
};



const [daily_delivery_Excel, setdaily_delivery_Excel] = useState({
  company:company,
addressOEM:addressOEM,
data:[],
date:filler_report_record.start_date,
oem:filler_report_record.oem_id
});

const saveReportDD_Excel = async (date) => {
 

  const tempfilter_report = {
    
    company_id:getUser().com,
    oem_id:filler_report_record.oem_id,
   dateFrom:filler_report_record.start_date,
   
  
  }
    
 
   axios({
     method: "post",
     url: Configs.API_URL + "/report_pdf/FilterReportDD",
     headers: {
       Authorization: getToken(),
       "X-TTT": Configs.API_TTT,
       "Content-Type": "application/json",
     },
     
     data: tempfilter_report
     
   })
   
   .then(function (response) {
 
    response.data.sort((a,b)=> a.delivery_sort - b.delivery_sort);
    var sortData = response.data.sort((a,b)=> (a.plate_number).localeCompare(b.plate_number))
      
      setdaily_delivery_Excel({...daily_delivery_Excel,
      company:company,
      addressOEM:addressOEM,
      data:sortData,
      date:filler_report_record.start_date,
      oem:filler_report_record.oem_id
      });
      daily_delivery_Excel.company = company;
      daily_delivery_Excel.addressOEM=addressOEM;
      daily_delivery_Excel.data=sortData;
      daily_delivery_Excel.date=filler_report_record.start_date;
      daily_delivery_Excel.oem=filler_report_record.oem_id;

        //daily_delivery_report(company,addressOEM,response.data,filler_report_record.start_date,filler_report_record.oem_id,Oem)

   /*  } */
  })
  .catch(function (error) {
    console.log(error);
  });
 
   };




const saveReportDD = async (date) => {
 
  if(filler_report_record.start_date == "" ){
    Swal.fire({
      icon: 'error',
      title: 'กรุณาเลือกวันที่รายงาน',
     
    })
  }
  

  const tempfilter_report = {
    
    company_id:getUser().com,
    oem_id:filler_report_record.oem_id,
   dateFrom:filler_report_record.start_date,
   
  
  }
 
  
 
   axios({
     method: "post",
     url: Configs.API_URL + "/report_pdf/FilterReportDD",
     headers: {
       Authorization: getToken(),
       "X-TTT": Configs.API_TTT,
       "Content-Type": "application/json",
     },
     
     data: tempfilter_report
     
    
   })
   
   .then(function (response) {
    if (response.data < 1) {
      Swal.fire({
        icon: "error",
        title: "ไม่มีข้อมูล",
      });
    } else {
      /*   var sortData = response.data.sort((a,b)=> (a.bill_no).localeCompare(b.bill_no)) */
        daily_delivery_report(company,addressOEM, response.data,filler_report_record.start_date,filler_report_record.oem_id,Oem)

    }
  })
  .catch(function (error) {
    console.log(error);
  });
 
   };

 

   const GetSelfPickup= async () => {
   
    const tempfilter_report = {
      
      company_id:getUser().com,
      oem_id:filler_report_record.oem_id,
     dateFrom:filler_report_record.start_date,
     
    
    }
    //console.log(filler_logistic);
    axios({
      method: "post",
      url: Configs.API_URL + "/report_pdf/Self_Pickup_report",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempfilter_report,
    })
    
  
    
      .then(function (response) {
        
        /*  console.log(response.data); */
         if (response.data < 1) {
          Swal.fire({
            
            icon: "error",
            title: "ไม่มีข้อมูล",
          });
        } else {
          self_pickup_report(company,addressOEM, response.data,filler_report_record.start_date,filler_report_record.oem_id,Oem)

      }
      })
      
      .catch(function (error) {
        console.log(error);
        
      });
      
  };

   

  
  function HR_data(){
    axios.get('https://script.google.com/macros/s/AKfycbz833PMLB169WLSmWTBRg4CVX_5yMnhAnrvfg6LMtu7xHAvZpinXW34qMqcj8xp2ExQ9Q/exec').then(function(response){

      //console.log(response.data)
      var tempData = [];
      for(var index = 0; index < response.data.length; index++){
        let temp = {
              Company:response.data[index].oem_id,
              sum:response.data[index].all_employee,
              Department:response.data[index].department,
              come:response.data[index].day_shift,
              afternoon_phase:response.data[index].night_shift,
              work_leave:response.data[index].personal_leave,
              summer_leave:response.data[index].leave,
              sick_leave:response.data[index].sick_leave,
              dont_come:response.data[index].absence,
              stop_leave:response.data[index].holiday,
              day_off:response.data[index].special_holiday,
              resign:response.data[index].Resign,

        }
        tempData.push(temp);
        //console.log(response.data[index]["สังกัด"])
        
      }
      setdata_HR(tempData);
   //console.log(tempData)
    }).catch(function (error) {
      console.log(error);
    });
  }
  useEffect(() => {
    axios(config_com)
    .then(function (response) {

      setcompany(response.data);
      setall_OEM(response.data);
      setOem(response.data.oem);
    }).catch(function (error) {
      console.log(error);
    });
    axios(config_alloem)
    .then(function (response) {
      //console.log(response.data);
  
    /*   var this_oem = response.data.filter((e)=>{
        return e.id
      }) */
    }).catch(function (error) {
      console.log(error);
    });
  
    HR_data();




   // GetLogistic();
  }, []);

  const [is_ready_toprint, setIs_ready_toprint] = useState({ display: "none" });
  
  function saveReportStockSSM() {
    if (
      MatStc_select.year != null &&
      MatStc_select.month != null &&
      MatStc_select.year != "" &&
      MatStc_select.month != ""&& 
      MatStc_select.year != undefined &&
      MatStc_select.month != undefined
    ) {
      setIs_ready_toprint({ display: "block" });
      is_ready_toprint.display = "block";
      //console.log("block", MatStc_select);
      GetAllMaterialValue();
    } else {
      //console.log("none", MatStc_select);
      setIs_ready_toprint({ display: "none" });
      is_ready_toprint.display = "none";
      Swal.fire({
        icon: "error",
        title: "กรุณาเลือกปี และ เดือน ของรายงาน",
      });
    }
    /*  setMatSTC_select({
      ...MatStc_select,
      year: "",
      month: "",
    });
    MatStc_select.year = "";
    MatStc_select.month = ""; */
  }
  
    const HR_DataReport = [];
  for(var index = 0; index < data_HR.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["Company"] = data_HR[index].Company;
    rowItem["Department"] = data_HR[index].Department;
    rowItem["come"] = data_HR[index].come;
    rowItem["afternoon_phase"] = data_HR[index].afternoon_phase;
    rowItem["work_leave"] = data_HR[index].work_leave;
    rowItem["summer_leave"] = data_HR[index].summer_leave;
    rowItem["sick_leave"] = data_HR[index].sick_leave;
    rowItem["dont_come"] = data_HR[index].dont_come;
    rowItem["stop_leave"] = data_HR[index].stop_leave;
    rowItem["day_off"] = data_HR[index].day_off;
    rowItem["resign"] = data_HR[index].resign;
    HR_DataReport.push(rowItem);
  }
  const data = {
    columns: [
      {
        label: "No",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "สังกัด",
        field: "Company",
        sort: "asc",
        width: 50,
      },
      {
        label: "ฝ่าย",
        field: "Department",
        sort: "asc",
        width: 50,
      },
      {
        label: "มา",
        field: "come",
        sort: "asc",
        width: 50,
      },
      {
        label: "กบ",
        field: "aternoon_phase",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลก",
        field: "work_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลพ",
        field: "summer_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลป",
        field: "sick_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "ข",
        field: "dont_come",
        sort: "asc",
        width: 50,
      },
      {
        label: "x",
        field: "stop_leave",
        sort: "asc",
        width: 50,
      },
      {
        label: "นข",
        field: "day_off",
        sort: "asc",
        width: 50,
      },
      {
        label: "ลอ",
        field: "resign",
        sort: "asc",
        width: 50,
      },
    ],
    rows: HR_DataReport,
  };

  
  const [MatStc_select, setMatSTC_select] = useState({
    company_id: getUser().com,
    year: "",
    month: "",
    oem_id: getOem(),
  });
  
  
  const GetAllMaterialValue = async () => {
    const temp_filter = {
      company_id: getUser().com,
      year: MatStc_select.year,
      month: MatStc_select.month,
      oem_id: getOem(),
    };
    //console.log("getAllนอก",temp_filter);
if ( MatStc_select.year !="" && MatStc_select.month!="" ) {
  //console.log("getAllใน",temp_filter);
  await axios({
    method: "post",
    url: Configs.API_URL + "/materialSTC/getAllMaterialValueByYearMonth",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: temp_filter,
  })
    .then(async (response) => {
      let _temp_data = [];
     
      for (
        let index_data = 0;
        index_data < response.data.length;
        index_data++
      ) {
        let element = {};
        element["id"] = response.data[index_data].id;
        element["mortar_silo_1"] = response.data[index_data].mortar_silo_1;
        element["mortar_silo_2"] = response.data[index_data].mortar_silo_2;
        element["fly_ash_silo"] = response.data[index_data].fly_ash_silo;
        element["mortar_ssm_1"] = response.data[index_data].mortar_ssm_1;
        element["mortar_ssm_2"] = response.data[index_data].mortar_ssm_2;
        element["flyAsh_ssm"] = response.data[index_data].flyAsh_ssm;
        element["surface_retarder_ssm"] =
          response.data[index_data].surface_retarder_ssm;
        element["sand_ssm"] = response.data[index_data].sand_ssm;
        element["rock_ssm"] = response.data[index_data].rock_ssm;
        element["mortar_express"] = response.data[index_data].mortar_express;
        element["flyAss_express"] = response.data[index_data].flyAss_express;
        element["surface_retarder_express"] =
          response.data[index_data].surface_retarder_express;
        element["sand_express"] = response.data[index_data].sand_express;
        element["rock_express"] = response.data[index_data].rock_express;
        element["day"] = response.data[index_data].day;
        element["item_material_date_id"] =
          response.data[index_data].item_material_date_id;
        element["mortar_elephant_in"] =
          response.data[index_data].mortar_elephant_in || "-";
        element["mortar_eagle_in"] =
          response.data[index_data].mortar_eagle_in || "-";
        element["fly_Ash_in"] = response.data[index_data].fly_Ash_in || "-";
        element["surface_retarder_in"] =
          response.data[index_data].surface_retarder_in || "-";
        element["number_in"] = response.data[index_data].number_in || "-";
        element["mortart_in"] = response.data[index_data].mortart_in || "-";
        element["fly_ash_kg"] = response.data[index_data].fly_ash_kg || "-";
        element["rock_3_8_in"] = response.data[index_data].rock_3_8_in || "-";
        element["rock_3_4_in"] = response.data[index_data].rock_3_4_in || "-";
        element["sand_in"] = response.data[index_data].sand_in || "-";
        element["surface_retarder_in_2"] = response.data[index_data].surface_retarder_in_2 || "-";
        element["update_by"] = response.data[index_data].update_by || "-";
        element["remark"] = response.data[index_data].remark || "-";

        _temp_data.push(element);
      }
     /*  console.log("TempFromBase", _temp_data); */

      setStcData(_temp_data);
    })
    .catch(async (error) => {
      console.log(error);
    });


}
};

  let tableData = [];

  for (let index = 0; index < month_gen_table; index++) {
    const element = {};

    let filter_seq_data = stcData.filter((e) => e.day === index + 1);
      /*  console.log("dataInMount",filter_seq_data); */
    if (filter_seq_data.length > 0) {
      /*       console.log(); */

      element["id"] = filter_seq_data[0].id;
      element["mortar_silo_1"] = filter_seq_data[0].mortar_silo_1;
      element["mortar_silo_2"] = filter_seq_data[0].mortar_silo_2;
      element["fly_ash_silo"] = filter_seq_data[0].fly_ash_silo;
      element["mortar_ssm_1"] = filter_seq_data[0].mortar_ssm_1;
      element["mortar_ssm_2"] = filter_seq_data[0].mortar_ssm_2;
      element["flyAsh_ssm"] = filter_seq_data[0].flyAsh_ssm;
      element["surface_retarder_ssm"] = filter_seq_data[0].surface_retarder_ssm;
      element["sand_ssm"] = filter_seq_data[0].sand_ssm;
      element["rock_ssm"] = filter_seq_data[0].rock_ssm;
      element["mortar_express"] = filter_seq_data[0].mortar_express;
      element["flyAss_express"] = filter_seq_data[0].flyAss_express;
      element["surface_retarder_express"] =
        filter_seq_data[0].surface_retarder_express;
      element["sand_express"] = filter_seq_data[0].sand_express;
      element["rock_express"] = filter_seq_data[0].rock_express;
      element["day"] = filter_seq_data[0].day;
      element["item_material_date_id"] =
        filter_seq_data[0].item_material_date_id;
      element["mortar_elephant_in"] = filter_seq_data[0].mortar_elephant_in;
      element["mortar_eagle_in"] = filter_seq_data[0].mortar_eagle_in;
      element["fly_Ash_in"] = filter_seq_data[0].fly_Ash_in;
      element["surface_retarder_in"] = filter_seq_data[0].surface_retarder_in;
      element["number_in"] = filter_seq_data[0].number_in;
      element["mortart_in"] = filter_seq_data[0].mortart_in;
      element["fly_ash_kg"] = filter_seq_data[0].fly_ash_kg;
      element["rock_3_8_in"] = filter_seq_data[0].rock_3_8_in;
      element["rock_3_8_in"] = filter_seq_data[0].rock_3_8_in;
      element["rock_3_4_in"] = filter_seq_data[0].rock_3_4_in;
      element["sand_in"] = filter_seq_data[0].sand_in;
      element["surface_retarder_in_2"] =
        filter_seq_data[0].surface_retarder_in_2;
      element["update_by"] = filter_seq_data[0].update_by;
      element["remark"] = filter_seq_data[0].remark;

      /* element = filter_seq_data[0]; */
    } else {
      element["id"] = "";
      element["mortar_silo_1"] = "";
      element["mortar_silo_2"] = "";
      element["fly_ash_silo"] = "";
      element["mortar_ssm_1"] = "";
      element["mortar_ssm_2"] = "";
      element["flyAsh_ssm"] = "";
      element["surface_retarder_ssm"] = "";
      element["sand_ssm"] = "";
      element["rock_ssm"] = "";
      element["mortar_express"] = "";
      element["flyAss_express"] = "";
      element["surface_retarder_express"] = "";
      element["sand_express"] = "";
      element["rock_express"] = "";
      element["day"] = index + 1;
      element["item_material_date_id"] = "";
      element["mortar_elephant_in"] = "";
      element["mortar_eagle_in"] = "";
      element["fly_Ash_in"] = "";
      element["surface_retarder_in"] = "";
      element["number_in"] = "";
      element["mortart_in"] = "";
      element["fly_ash_kg"] = "";
      element["rock_3_8_in"] = "";
      element["rock_3_4_in"] = "";
      element["sand_in"] = "";
      element["surface_retarder_in_2"] = "";
      element["update_by"] = "";
      element["remark"] = "";
    }
    tableData.push(element);
  }

  const tableHeader = {
    headerColums: [
      {
        label: "วัน",
        field: "day",
        colspan: "",
        rowspan: "2",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "STC (Silo)",
        field: "stc_cilo",
        colspan: "3",
        rowspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "SSM",
        field: "ssm",
        colspan: "6",
        rowspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Express",
        field: "express",
        colspan: "5",
        rowspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Mgt",
        field: "mgt",
        colspan: "",
        rowspan: "2",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Daily Update",
        field: "daily_update",
        colspan: "13",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
        },
      },
    ],
    columns: [
      {
        label: "ปูนผง 1 (ตัน)",
        field: "mortar_silo_1",
        colspan: "",

        style: {
          textAliment: "center",
          width: "9 rem",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง 2 (ตัน)",
        field: "mortar_silo_2",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash",
        field: "fly_ash_silo",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง 1 (kg)",
        field: "mortar_ssm_1",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง 2 (kg)",
        field: "mortar_ssm_2",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash (kg)",
        field: "flyAsh_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง (Ml)",
        field: "surface_retarder_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ทรายหยาบ (kg)",
        field: "sand_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หิน 3/4 (kg)",
        field: "rock_ssm",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง",
        field: "mortar_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash (kg)",
        field: "flyAss_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง (Ml)",
        field: "surface_retarder_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ทรายหยาบ (kg)",
        field: "sand_express",
        colspan: "",
        style: {
          textAliment: "center",
          whiteSpace: "nowrap",
          width: "",
        },
      },
      {
        label: "หิน 3/4 (kg)",
        field: "rock_express",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนช้าง (ตัน)",
        field: "mortar_elephant_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนอินทรีย์ (ตัน)",
        field: "mortar_eagle_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash",
        field: "fly_Ash_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง",
        field: "surface_retarder_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },

      {
        label: "เลขที่ (TK)",
        field: "number_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ปูนผง",
        field: "mortart_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "Fly Ash (kg)",
        field: "fly_ash_kg",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หิน 3/8 (kg)",
        field: "rock_3_8_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หิน 3/8 (kg)",
        field: "rock_3_4_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ทราบหยาบ",
        field: "sand_in",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "น้ำยาหน่วง",
        field: "surface_retarder_in_2",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "ผู้บันทึก",
        field: "update_by",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
      {
        label: "หมายเหตุ",
        field: "remark",
        colspan: "",
        style: {
          textAliment: "center",
          width: "",
          whiteSpace: "nowrap",
        },
      },
    ],
    rows: tableData,
  };




  const data_chart = [
    ["Element", "Density", { role: "style" },{ role: 'annotation'}],
    ["Copper", 8.94, "#b87333",8.94], // RGB value

    ["Gold", 19.3, "gold",19.3],
    ["Platinum", 21.45, "color: #e5e4e2",21.45], // CSS-style declaration

  ];


  const chart_ref = useRef();


  const [testhide,settesthide] = useState({
    test:"none"
  })

  const [testhidefuel,settesthidefuel] = useState({
    test:"none"
  })

 

  function testpdf(){
    var chart_pf = "";
    html2canvas(document.getElementById("print_to_pdf")).then(canvas => {
       chart_pf = canvas.toDataURL();
       var pdf = new jsPDF('landscape');
    
       pdf.addImage(chart_pf, 'JPEG', 20, 10, 25, 25)
       /* var imgData = Chart.toDataURL('image/jpeg');
       pdf.addImage(imgData, 'JPEG', 0, 0, 100, 100); */
       window.open(pdf.output('bloburl'));
      //console.log(chart_pf)
     /*  var pdfExportSetting = {
        content: [
          {
            image: data,
            width: 500
          }
        ]
      }; */
   /*    pdfMake.createPdf(pdfExportSetting).download("test_file.pdf"); */
    });
 /*    var chart = new Chart(document.querySelector("#chart"), options); */

        /* console.log(Chart.dataURI()); */



  }




  return (
    <div className="wrapper">
    {/* <Header />
    <Sidebar menu="warehouse" activemenu="suppmenu" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Report PDF{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Report PDF</li>
                </ol>
              </div>
            </div>
          </div>

        
            <div class="row">
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">รายงานสรุป</h3>
                    <p class="card-text"></p>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                          <button
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-add_reportdd_data_rerationship"
                              onClick={() => {
                                var oem_name = companyGroup.filter((c)=>{return c.value === filler_report_record.oem_id});

                                setfiller_report_record({ ...filler_report_record,oem_name:oem_name[0].label});
                                filler_report_record.oem_name = oem_name[0].label;
                                setreport_data({
                                  ...report_data,
                                  id: "",
                                  start_date: "",
                                  oem: "",
                                  
                                });
                                saveReportDD_Excel();
                               } }
                              className="btn btn-block btn-outline-primary"
                            > 
                              รายงานการตรวจสอบสินค้าและขนส่งประจำวัน
                            </button>
                          </div>
                        </div>





{/* 
                        <div id="chart">
</div> */}
  
                       
                           

   

                        <div className="col-4">
                          <div className="form-group ">
                            <button
                            //disabled = {true}
                              type="button"
                              className="btn btn-block btn-outline-primary"
                              data-toggle="modal"
                              data-target="#modal-formTravelmonth"
                             // onClick={testpdf}formTravelmonth
                            >
                              รายงานเที่ยววิ่งประจำเดือน
                            </button>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group " /* style = {{display:"none"}} */ >
                        <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-block btn-outline-primary"
                          table="DataHR-xls"
                          filename={"HR" + " " + moment(new Date()).format('DD-MM-yyyy')}
                          sheet="tablexls"
                     
                          buttonText="Data HR TO XLS"
                        />
  </div>
                        </div>

                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                            <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                              data-toggle="modal"
                              data-target="#modal-stock-ssm-express-daily"
                           >
                              รายงาน Stock Raw Mat คงเหลือประจำวัน จาก SSM , Expess
                            </button>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group ">
                            <button
                              // disabled = {true}
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-RepairCost"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงานค่าซ่อม
                            </button>
                          </div>
                        </div>


                        <div className="col-4">
                          <div className="form-group ">
                            <button
                              // disabled = {true}
                              type="button"
                              data-toggle="modal"
                              data-target="#modal-Wire_mace_STP"
                              className="btn btn-block btn-outline-primary"
                            >
                              Wire Mesh STP
                            </button>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                            <button
               /*              disabled = {true} */
                              type="button"
                              className="btn btn-block btn-outline-primary"
                              data-toggle="modal"
                              data-target="#modal-add-reportdd-data-Monthlyfuel"
                      
                            >
                              รายงานการใช้งานน้ำมัน
                            </button>
                          </div>
                        </div>
                      {/*   <div className="col-4">
                          <div className="form-group ">
                            <button
                      
                              type="button"
                              className="btn btn-block btn-outline-primary"
                              data-toggle="modal"
                              data-target="#modal-formTraveldaily"
                              //onClick={() =>{daily_travel_report(company,addressOEM,logistic);}}
                            >
                              รายงานเที่ยววิ่งประจำวัน
                            </button>
                          </div>
                        </div> */}
                        <div className="col-4">
                          <div className="form-group ">
                            <button
                              type="button"
                              
                              className="btn btn-block btn-outline-primary"
                              data-toggle="modal"
                              data-target="#modal-formSaleDaily"
                      /*         onClick={()=>{daily_sale_report(company,addressOEM,data);}} */
                            >
                              รายงานบิลค้างส่งประจำวัน
                            </button>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="form-group ">
                            <button
                              type="button"
                              
                              className="btn btn-block btn-outline-primary"
                              data-toggle="modal"
                              data-target="#modal-formself-pickup"
                      /*         onClick={()=>{daily_sale_report(company,addressOEM,data);}} */
                            >
                              รายงานสินค้ารับเอง
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
               </div>
              </div>
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">รายงานสถานะ</h3>
                    <p class="card-text"></p>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                           {/*  <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                          {/*   <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                      {/*       <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>



            <div class="row">
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">รายงานกราฟ</h3>
                    <p class="card-text"></p>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                         {/*    <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                         {/*    <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                       {/*      <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
               </div>
              </div>
              <div class="col-sm-6">
                <div class="card">
                  <div class="card-body">
                    <h3 class="card-title">รายงานการเข้าพื้นที่</h3>
                    <p class="card-text"></p>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                          {/*   <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                          {/*   <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group ">
                         {/*    <button
                              type="button"
                              className="btn btn-block btn-outline-primary"
                            >
                              รายงาน TEST3
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            
            <div
              className="card-body table-responsive "   style={{ display: "none" }} 
            >
              <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                id = "DataHR-xls"
                paging={false}
                striped
                bordered
                hover
                fixedHeader
                data={data}
              />
            </div>
            <div
                  className="card-body table-responsive table-bordered "
                  style={{
                    textAlign: "center",
                    maxHeight: "820px" ,"display":"none",
                  }}
                >
                  <MDBTable bordered className="scrollbar" id="table-to-xls">
                    <MDBTableHead>
                      <tr>
                        {/*  <th key={}
                          rowSpan={}>


                          </th> */}
                        {tableHeader.headerColums.map((el) => {
                          if (el.field != "mgt") {
                            return (
                              <th
                                key={el.field}
                                colspan={el.colspan}
                                rowSpan={el.rowspan}
                                style={{
                                  textAlign: el.style.textAliment,
                                  whiteSpace: el.style.whiteSpace,
                                  marginLeft: el.style.marginLeft,
                                  marginRight: el.style.marginRight,
                                }}
                              >
                                {el.label}
                              </th>
                            );
                          }
                        })}
                      </tr>
                      <tr>
                        {tableHeader.columns.map((el) => {
                          return (
                            <th
                              key={el.field}
                              colspan={el.colspan}
                              style={{
                                textAlign: el.style.textAliment,
                                whiteSpace: el.style.whiteSpace,
                              }}
                            >
                              {el.label}
                            </th>
                          );
                        })}
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                      {tableHeader.rows.map((el, i) => {
                        return (
                          <tr
                            key={i}
                           /*  style={{
                              backgroundColor:
                                highlightToday.date_toda === el.day
                                  ? "#6dabd1"
                                  : "",
                            }} */
                          >
                            <td>{el.day}</td>
                            <td>
                              <NumberFormat
                                value={el.mortar_silo_1}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                           
                            <td>
                              <NumberFormat
                                value={el.mortar_silo_2}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.fly_ash_silo}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.mortar_ssm_1}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.mortar_ssm_2}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.flyAsh_ssm}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.surface_retarder_ssm}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.sand_ssm}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.rock_ssm}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.mortar_express}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.flyAss_express}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.surface_retarder_express}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.sand_express}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.rock_express}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>

                            <td>
                              <NumberFormat
                                value={el.mortar_elephant_in}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.mortar_eagle_in}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.fly_Ash_in}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.surface_retarder_in}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.number_in}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.mortart_in}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.fly_ash_kg}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.rock_3_8_in}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.rock_3_4_in}
                                displayType="text"
                                thousandSeparator={true}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={el.sand_in}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>
                              <NumberFormat
                                value={el.surface_retarder_in_2}
                                displayType="text"
                                thousandSeparator={true}
                              />{" "}
                            </td>
                            <td>{el.update_by} </td>
                             {/* <td>
                          <input
                            value={el.remark}
                            displayType="text"
                            thousandSeparator={true}
                          />{" "}
                        </td> */}
                            <td>{el.remark} </td>
                          </tr>
                        );
                      })}
                    </MDBTableBody>
                  </MDBTable>
                </div>

        </section>




  {/*  ///////////////////////////////      รายงานเที่ยววิ่งประจำเดือน  ////////////////////////// */}
  <div className="modal fade" id="modal-formTravelmonth">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">รายงานเที่ยววิ่งประจำเดือน</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput"></div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                           
                                

                                    <div className="col-md-10" style={{ "z-index": "98" }}>
                <div className="form-group ">
                <Select
                            options={companyGroup}
                   
                            value={companyGroup.filter((e) => {
                              return e.value === filter_logistic.oem_target;
                            })}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setFilter_logistic({
                                  ...filter_logistic,
                                  oem_target: e.value,
                                });
                      
                              }
                            }}
                          />
              
                  <label htmlFor="">กลุ่มบริษัท</label>
                </div>
              </div>
                         
              <div className="col-md-10">
                <div className="form-group ">
                <DatePicker
      
      selected = {filter_logistic.date}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      customInput = { <CustomInput/> }
      showFullMonthYearPicker
      showFourColumnMonthYearPicker
      onChange = {
        async(date) =>{
          setFilter_logistic({
            ...filter_logistic,
            date: date,
          });
        }
       
        }
    />
             {/*    <DatePicker 
                 disabled = {true}
        selected = {filter_logistic.date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>{
            setFilter_logistic({
              ...filter_logistic,
              date: date,
            });
          }
         
          }
        selectsEnd startDate = { filter_logistic.date }

        customInput = { <CustomInput/> }
      
         >
        </DatePicker>  */}
                </div>
              </div>

                               
                              </div>
                              <br />

                           
                            </div>
                          </div>
                         
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal"
                           // onClick={testpdf}
                            onClick={GetLogistic_month}
                          >
                            ยืนยัน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
           {/*  ///////////////////////////////      รายงานเที่ยววิ่งประจำเดือน  ////////////////////////// */}



          {/*  ///////////////////////////////      รายงานเที่ยววิ่งประจำวัน  ////////////////////////// */}
        <div className="modal fade" id="modal-formTraveldaily">
                    <div className="modal-dialog modal-md">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">รายงานเที่ยววิ่งประจำวัน</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput"></div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                           
                                

                                    <div className="col-md-10" style={{ "z-index": "98" }}>
                <div className="form-group ">
                <Select
                            options={companyGroup}
                   
                            value={companyGroup.filter((e) => {
                              return e.value === filter_logistic.oem_target;
                            })}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setFilter_logistic({
                                  ...filter_logistic,
                                  oem_target: e.value,
                                });
                      
                              }
                            }}
                          />
              
                  <label htmlFor="">กลุ่มบริษัท</label>
                </div>
              </div>
                         
              <div className="col-md-10">
                <div className="form-group ">
                <DatePicker 
           /*       disabled = {true} */
        selected = {filter_logistic.date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>{
            setFilter_logistic({
              ...filter_logistic,
              date: date,
            });
          }
         
          }
        selectsEnd startDate = { filter_logistic.date }
 /*        endDate = { filter_bill.date_end }
        minDate = { filter_bill.date_start } */
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
                </div>
              </div>

                               
                              </div>
                              <br />

                           
                            </div>
                          </div>
                         
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal"
                            onClick={GetLogistic}
                          >
                            ยืนยัน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
           {/*  ///////////////////////////////      รายงานเที่ยววิ่งประจำวัน  ////////////////////////// */}


            {/*  ///////////////////////////////      รายงานบิลค้าส่งประจำวัน  ////////////////////////// */}
        <div className="modal fade" id="modal-formSaleDaily">
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">รายงานบิลค้างส่งประจำวัน</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput"></div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                           
                                

                                    <div className="col-md-10" style={{ "z-index": "98" }}>
                <div className="form-group ">
             {/*    <Select
                            options={companyGroup}
                   
                            value={companyGroup.filter((e) => {
                              return e.value === filter_logistic.oem_target;
                            })}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setFilter_logistic({
                                  ...filter_logistic,
                                  oem_target: e.value,
                                });
                      
                              }
                            }}
                          /> */}
                          <select
                    className="custom-select select2 form-control"
                    value={filler_report_record.oem_id}
                    onChange={(e) =>{
                      var oem_name = companyGroup.filter((c)=>{return c.value === e.target.value});

                      setfiller_report_record({ ...filler_report_record, oem_id: e.target.value ,oem_name:oem_name[0].label});
                      filler_report_record.oem_id=e.target.value;
                      filler_report_record.oem_name=oem_name[0].label;
                      saveReportDD_Excel();
                    }
              
                    }
                  >
                   {companyGroup.map((e)=>{return (<option value={e.value}>{e.label}</option>)})}
    {/*              <option value="b717f172-5013-4aa3-a040-65f4f116870b">บริษัท สตูลค้าเหล็ก จำกัด</option>
                    <option value="08721f6f-ee1d-4900-8d67-2c7ce03b601e">บริษัท เอสทีสตีล(2001) จำกัด</option>
                    <option value="c8de2754-d8f9-4e18-97db-4201efca6e2f">บริษัท เอสทีสตีล(2001) ปัตตานี จำกัด</option>
                    <option value="d937f81b-9743-4c33-886f-e1c575f4cf21">บริษัท เอสที คอนกรีตโปรดักส์ จำกัด</option>
                    <option value="360e9f89-6b60-4edd-9f4d-a6e487c97cf3">บริษัท เอสทีซีวิล(2001) จำกัด</option> */}
                  </select>
              
                  <label htmlFor="">กลุ่มบริษัท</label>
                </div>
              </div>
                         
              <div className="col-md-10">
                <div className="form-group ">
               {/*  <DatePicker 
        selected = {filter_logistic.date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>{
            setFilter_logistic({
              ...filter_logistic,
              date: date,
            });
          }
         
          }
        selectsEnd startDate = { filter_logistic.date }
 
 
        customInput = { <CustomInput/> }
      
         > */}
         <DatePicker
                             // disabled={true}
                             value={filler_report_record.start_date}
                              selected={filler_report_record.start_date}
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => {SetDate_start(date);saveReportDD_Excel();}}
                              selectsStart
                              startDate={filler_report_record.start_date}
                              customInput={<CustomInput />}
                            ></DatePicker>
        
                </div>
              </div>

                               
                              </div>
                              <br />

                           
                            </div>
                          </div>
                         
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal"
                            onClick={GetSaleDaily}
                          >
                            ยืนยัน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
           {/*  ///////////////////////////////      รายงานบิลค้าส่งประจำวัน  ////////////////////////// */}




 {/*  ///////////////////////////////      รายงานบิลค้าส่งประจำวัน  ////////////////////////// */}
 <div className="modal fade" id="modal-formself-pickup">
                    <div className="modal-dialog modal-sm">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">รายงานบิลรับสินค้าเอง</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            //onClick={set_bomlist_excel}
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="none-nolmalinput"></div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                           
                                

                                    <div className="col-md-10" style={{ "z-index": "98" }}>
                <div className="form-group ">
             {/*    <Select
                            options={companyGroup}
                   
                            value={companyGroup.filter((e) => {
                              return e.value === filter_logistic.oem_target;
                            })}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                                setFilter_logistic({
                                  ...filter_logistic,
                                  oem_target: e.value,
                                });
                      
                              }
                            }}
                          /> */}
                          <select
                    className="custom-select select2 form-control"
                    value={filler_report_record.oem_id}
                    onChange={(e) =>{
                      var oem_name = companyGroup.filter((c)=>{return c.value === e.target.value});

                      setfiller_report_record({ ...filler_report_record, oem_id: e.target.value ,oem_name:oem_name[0].label});
                      filler_report_record.oem_id=e.target.value;
                      filler_report_record.oem_name=oem_name[0].label;
                      saveReportDD_Excel();
                    }
              
                    }
                  >
                   {companyGroup.map((e)=>{return (<option value={e.value}>{e.label}</option>)})}
    {/*              <option value="b717f172-5013-4aa3-a040-65f4f116870b">บริษัท สตูลค้าเหล็ก จำกัด</option>
                    <option value="08721f6f-ee1d-4900-8d67-2c7ce03b601e">บริษัท เอสทีสตีล(2001) จำกัด</option>
                    <option value="c8de2754-d8f9-4e18-97db-4201efca6e2f">บริษัท เอสทีสตีล(2001) ปัตตานี จำกัด</option>
                    <option value="d937f81b-9743-4c33-886f-e1c575f4cf21">บริษัท เอสที คอนกรีตโปรดักส์ จำกัด</option>
                    <option value="360e9f89-6b60-4edd-9f4d-a6e487c97cf3">บริษัท เอสทีซีวิล(2001) จำกัด</option> */}
                  </select>
              
                  <label htmlFor="">กลุ่มบริษัท</label>
                </div>
              </div>
                         
              <div className="col-md-10">
                <div className="form-group ">
               {/*  <DatePicker 
        selected = {filter_logistic.date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>{
            setFilter_logistic({
              ...filter_logistic,
              date: date,
            });
          }
         
          }
        selectsEnd startDate = { filter_logistic.date }
 
 
        customInput = { <CustomInput/> }
      
         > */}
         <DatePicker
                             // disabled={true}
                             value={filler_report_record.start_date}
                              selected={filler_report_record.start_date}
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => {SetDate_start(date);saveReportDD_Excel();}}
                              selectsStart
                              startDate={filler_report_record.start_date}
                              customInput={<CustomInput />}
                            ></DatePicker>
        
                </div>
              </div>

                               
                              </div>
                              <br />

                           
                            </div>
                          </div>
                         
                        </div>
                        <div className="modal-footer justify-content-between">
                          <button
                            type="button"
                            className="btn btn-success"
                            data-dismiss="modal"
                            onClick={GetSelfPickup}
                          >
                            ยืนยัน
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
           {/*  ///////////////////////////////      รายงานบิลค้าส่งประจำวัน  ////////////////////////// */}








           {/*  ///////////////////////////////      รายงานการตรวจสอบสินค้าและส่งสินค้าประจำวัน ////////////////////////// */}
                  <div className="modal fade" id="modal-add_reportdd_data_rerationship">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h4 className="modal-label"> รายงานการตรวจสอบสินค้าและส่งสินค้าประจำวัน</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-sm-3 col-md-3 col-lg-3"></div>
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="form-group">
                              
                              
                        <div className="col-sm-12 col-md-12 col-lg-12" style={{ "z-index": "99" }}>
                          
                          <div className="form-group ">
                            <DatePicker
                             // disabled={true}
                             value={filler_report_record.start_date}
                              selected={filler_report_record.start_date}
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => {SetDate_start(date);saveReportDD_Excel();}}
                              selectsStart
                              startDate={filler_report_record.start_date}
                              customInput={<CustomInput />}
                            ></DatePicker>
                          </div>
                          <label htmlFor="">วันที่</label>
                        </div>
                            
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="form-group">
                            <select
                    className="custom-select select2 form-control"
                    value={filler_report_record.oem_id}
                    onChange={(e) =>{
                      var oem_name = companyGroup.filter((c)=>{return c.value === e.target.value});

                      setfiller_report_record({ ...filler_report_record, oem_id: e.target.value ,oem_name:oem_name[0].label});
                      filler_report_record.oem_id=e.target.value;
                      filler_report_record.oem_name=oem_name[0].label;
                      saveReportDD_Excel();
                    }
              
                    }
                  >
                   {companyGroup.map((e)=>{return (<option value={e.value}>{e.label}</option>)})}
    {/*              <option value="b717f172-5013-4aa3-a040-65f4f116870b">บริษัท สตูลค้าเหล็ก จำกัด</option>
                    <option value="08721f6f-ee1d-4900-8d67-2c7ce03b601e">บริษัท เอสทีสตีล(2001) จำกัด</option>
                    <option value="c8de2754-d8f9-4e18-97db-4201efca6e2f">บริษัท เอสทีสตีล(2001) ปัตตานี จำกัด</option>
                    <option value="d937f81b-9743-4c33-886f-e1c575f4cf21">บริษัท เอสที คอนกรีตโปรดักส์ จำกัด</option>
                    <option value="360e9f89-6b60-4edd-9f4d-a6e487c97cf3">บริษัท เอสทีซีวิล(2001) จำกัด</option> */}
                  </select>
                            
                              <label htmlFor="">บริษัท</label>
                            </div>
                          </div>

                          </div>
            
                          <div className="col-sm-1 col-md-1 col-lg-1"></div>
                        </div>

                        <div className="row">
                          <div className="col-1"></div>

                          <div className="col-1"></div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-between">
                        <div className = "row">
                          <div className = "col-4">

                          <button
                              
                              type="button"
                                onClick={() => {saveReportDD();}}  
                              data-dismiss="modal"
                              className="btn btn-danger"
                            >
                              PDF
                            </button>
                            </div>
                            <div className = "col-1">    </div>
                            <div className = "col-4"           onClick={saveReportDD_Excel}>
                            <ReactHTMLTableToExcel
                          //id="test-table-xls-button"
             
                          className="btn btn-success"
                          table="dd_to_xls"
                          filename={"รายงานการตรวจสอบสินค้าและขนส่งประจำวัน" + "_" + moment(new Date()).format('DD-MM-yyyy')}
                          //sheet="tablexls"
                          data-dismiss="modal"
                          buttonText="Excel"
                        />
                         {/*    <button
                              
                              type="button"
                                onClick={() => {saveReportDD();}}  
                              data-dismiss="modal"
                              className="btn btn-success"
                            >
                              EXCEL
                            </button> */}

                            </div>
                   
                         
                        </div>
                        
                                
                        <button
                          type="button"
                          className="btn btn-default"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                       
                      </div>
                    </div>
                  </div>
                </div>
           {/*  ///////////////////////////////      รายงานการตรวจสอบสินค้าและส่งสินค้าประจำวัน ////////////////////////// */}
           <div style ={{display:testhide.test,width: "100%"}}>
           <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/>
           </div>
           <div className="row">
           <div className="col-12">
 <span id="print_to_pdf" style ={{display:testhide.test,width: "100%"}}> 
<Chart
        options={option_month_chart}
                ref={chart_ref}
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={month_travel_chart}
          legend_toggle = {false}
        />
     </span>
                          </div>
                          </div>

                          <div style ={{display:testhidefuel.test,width: "100%"}}> 
                          <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/>
                          </div>
                          <div className="row">
           <div className="col-12">
           <span id="print_to_monthfuelpdf" style ={{display:testhidefuel.test,width: "100%"}}> 
<Chart
        options={option_month_fuel_chart}
              
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={month_fuel_chart}
          legend_toggle = {false}
        />
    </span>
                          </div>
                          </div>


                          <div /* style ={{display:testhidefuel.test,width: "100%"}} */> 
                          <div className="row">
           <div className="col-12">
           <table id = "dd_to_xls" style = {{ border: "1px solid #ddd", bordercollapse: "collapse", width: "100%",display:"none"}}>
            
           <tr>
            <td colSpan={14}  style= {{textAlign: "center",backgroundColor:"#FFFF00"}}>รายงานการตรวจสอบสินค้าและส่งสินค้าประจำวันของ {filler_report_record.oem_name} ประจำวันที่ {moment(filler_report_record.start_date).format('DD/MM/yyyy')}</td>
           </tr>
         
                  <tr>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      ลำดับเที่ยว
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      เลขที่บิล
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      รายการ
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      มูลค่า
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      น้ำหนัก(ตัน)
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      ลูกค้า
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      หน้างาน
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      ทะเบียน
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      ผู้ส่ง
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      ผู้ตรวจ
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      เวลาออก
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      เวลาเข้า
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      สถานะ
                    </td>
                    <td style = {{ border: "1px solid #ddd",textAlign: "center",backgroundColor:"#FFFF00"}}>
                      หมายเหตุ
                    </td>
                  </tr>
            {daily_delivery_Excel.data.map((e,index)=>{
              var modelCate ="";
              let stack = 1;
             
              if(e.product_and_category && e.product_and_category.length > 0){
                for(let i of e.product_and_category){
                  if(stack === e.product_and_category.length){
                    modelCate += i.label;
                  }else{
                    modelCate += i.label+",";
                  }
            /*       modelCate += i.label;
                  modelCate += i.label; */
                  stack++;
                }
              }
              var nickname = "";
              if(e.driver_persons.length > 0){
                for(let i =0; i < e.driver_persons.length;i++){
                  if(i+1 === e.driver_persons.length ){
                    nickname += e.driver_persons[i].nickname || e.driver_persons[i].remark ;
                  }else{
                    nickname += (e.driver_persons[i].nickname || e.driver_persons[i].remark )+ ",";
                  }
                 
                }
              }
              var row = 1;
              var row2 = 1;
              var test = daily_delivery_Excel.data.filter((t)=>{ return t.plate_number == e.plate_number})
              var test2 = daily_delivery_Excel.data.filter((t)=>{ return t.plate_number == e.plate_number && t.delivery_sort == e.delivery_sort})
              row = test.length === 0 ? 1 :test.length;
              row2 = test2.length === 0 ? 1 :test2.length;
              return (<>
                <tr>


                {index === 0 ? (
                    <>
                     <td rowspan = {row2} style = {{textAlign: "center", border: "1px solid #ddd"}}>{e.delivery_sort}</td>
                    </>
                  ) : (
                    <>
                    </>
                  )}

                {
                    daily_delivery_Excel.data[index -1] ? (
                      <>
                          { daily_delivery_Excel.data[index -1].delivery_sort !== e.delivery_sort && daily_delivery_Excel.data[index -1].plate_number == e.plate_number
                  ? (
                  <>
                       <td rowspan = {row2} style = {{textAlign: "center", border: "1px solid #ddd"}}>{e.delivery_sort}</td>
                  </>
                  ):(
                    <>
                    
                    </>
                  )
                  }

{ daily_delivery_Excel.data[index -1].plate_number !== e.plate_number 
                  ? (
                  <>
                       <td rowspan = {row2} style = {{textAlign: "center", border: "1px solid #ddd"}}>{e.delivery_sort}</td>
                  </>
                  ):(
                    <>
                    
                    </>
                  )
                  }
                      </>
                    ):(
                   <>
                      </>
                    )
                  }
                  
     {/*              <td style = {{textAlign: "center", border: "1px solid #ddd"}}>{e.delivery_sort}</td> */}
                  <td style = {{ border: "1px solid #ddd"}}>{e.bill_no}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{modelCate}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.bill_values}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.weight}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.customer_name}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.bill_destination}</td>
                  {index === 0 ? (
                    <>
                     <td rowspan = {row} style = {{ textAlign: "center",border: "1px solid #ddd"}}>{e.plate_number}</td>
                    </>
                  ) : (
                    <>
                    </>
                  )}
                  {
                    daily_delivery_Excel.data[index -1] ? (
                      <>
                          { daily_delivery_Excel.data[index -1].plate_number !== e.plate_number
                  ? (
                  <>
                       <td rowspan = {row} style = {{textAlign: "center", border: "1px solid #ddd"}}>{e.plate_number}</td>
                  </>
                  ):(
                    <></>
                  )
                  }
                      </>
                    ):(
                   <>
                      </>
                    )
                  }
              
                {/*   <td rowspan = {row} style = {{ border: "1px solid #ddd"}}>{e.plate_number}</td> */}
                  <td style = {{ border: "1px solid #ddd"}}>{nickname}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.approve_person}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.start_time !== null ? moment(e.start_time).format('DD/MM/yyyy HH:mm น.') :""}</td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.finish_time !== null ? moment(e.finish_time).format('DD/MM/yyyy HH:mm น.') :""}</td>
                  <td style = {{ border: "1px solid #ddd"}}></td>
                  <td style = {{ border: "1px solid #ddd"}}>{e.remark}</td>
                </tr>
         
                </>
              );
            })}
                   </table>
                          </div>
                          </div>
                          </div>
          {/*---------------------------------------  รายงาน Stock Raw Mat คงเหลือประจำวัน จาก SSM ,Expess   ---------------------------------*/}

   <div className="modal fade" id="modal-stock-ssm-express-daily">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-label">
                  {" "}
                  รายงาน Stock Raw Mat คงเหลือประจำวัน จาก SSM ,Expess
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setMatSTC_select({
                      ...MatStc_select,
                      year: "",
                      month: "",
                    });
                    MatStc_select.year = "";
                    MatStc_select.month = "";
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row" style={{ textAlign: "center" }}>
                  <div className="col-sm-8 col-md-8 col-lg-8">
                    <div className="form-group ">
                      <select
                        className="custom-select select2 form-control"
                        value={MatStc_select.year}
                        onChange={(e) => {
                          setMatSTC_select({
                            ...MatStc_select,
                            year: e.target.value,
                          });
                          MatStc_select.year =  e.currentTarget.value;
                          GetAllMaterialValue();
                        }}
                      >
                        <option value="">Select</option>
                        {year.map((el) => {
                          return <option value={el.value}>{el.label}</option>;
                        })}
                      </select>

                      <label htmlFor="">ปี</label>
                    </div>
                  </div>
                  <div className="col-sm-8 col-md-8 col-lg-8">
                    <div className="form-group ">
                      <select
                        className="custom-select select2 form-control"
                        value={MatStc_select.month}
                        onChange={(e) => {
                          setMatSTC_select({
                            ...MatStc_select,
                            month: e.target.value,
                          });
                          MatStc_select.month =  e.target.value;
                          GetAllMaterialValue();
                        }}
                      >
                        <option value="">Select</option>
                        {mounth.map((el) => {
                          return <option value={el.value}>{el.label}</option>;
                        })}
                      </select>
                      <label htmlFor="">เดือน</label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-1"></div>

                  <div className="col-1"></div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                {MatStc_select.year != "" && MatStc_select.month != "" ? (
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn button btn-primary"
                    data-dismiss="modal"
                    table="table-to-xls"
                    filename={
                      "รายงาน Stock ประจำวัน " +
                      moment(new Date()).format("DD-MM-yyyy")
                    }
                    sheet="tablexls"
                    buttonText="รายงาน Stock"
                  />
                ) : (
                  ""
                )}


                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={() => {
                    setMatSTC_select({
                      ...MatStc_select,
                      year: "",
                      month: "",
                    });
                    MatStc_select.year = "";
                    MatStc_select.month = "";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>



          {/* ============================รายงานค่าซ่อม================================== */}
          <div className="modal fade" id="modal-RepairCost">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">รายงานค่าซ่อมประจำเดือน</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-10" style={{ "z-index": "98" }}>
                        <div className="form-group ">

                          {/* อันนูน */}
                          <select
                          className="custom-select select2 form-control"
                          value={report_repair_cost.oem_id}
                          onChange={(e) =>
                            set_report_repair_cost({ 
                              ...report_repair_cost, oem_id: e.target.value })
                          }
                        >
                          {companyGroup.map((e) => { return (<option value={e.value}>{e.label}</option>) })}
                          {/*              <option value="b717f172-5013-4aa3-a040-65f4f116870b">บริษัท สตูลค้าเหล็ก จำกัด</option>
                    <option value="08721f6f-ee1d-4900-8d67-2c7ce03b601e">บริษัท เอสทีสตีล(2001) จำกัด</option>
                    <option value="c8de2754-d8f9-4e18-97db-4201efca6e2f">บริษัท เอสทีสตีล(2001) ปัตตานี จำกัด</option>
                    <option value="d937f81b-9743-4c33-886f-e1c575f4cf21">บริษัท เอสที คอนกรีตโปรดักส์ จำกัด</option>
                    <option value="360e9f89-6b60-4edd-9f4d-a6e487c97cf3">บริษัท เอสทีซีวิล(2001) จำกัด</option> */}
                        </select>
                          <label htmlFor="">กลุ่มบริษัท</label>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="form-group ">
                      
                          <DatePicker
                          value={report_repair_cost.start_month}
                            selected={report_repair_cost.start_month}
                            dateFormat="MM/yyyy"
                            onChange={async (date) => setStartDate(date)}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            customInput = { <CustomInput/> }
                          />
                       
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>

              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={() => {saveRepair_Cost_Report(); }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </div>

        <div style ={{display:testhidefuel.test,width: "100%"}}> 
                          <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/> <br/><br/><br/>
                          </div>
                          <div className="row">
           <div className="col-12">
 
 <span id="print_Cost_Repairto_pdf" style ={{display:testhidefuel.test,width: "100%"}}> 
 <Chart
        options={option_Cost_Repair_chart}
          // ref={chart_ret_cost}
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={repair_cost}
          legend_toggle = {false}
        />
     </span>
 <span id="print_Cost_Repairto_pdf2"style ={{display:testhidefuel.test,width: "100%"}}> 
 <Chart
        options={option_Cost_Repair_chart_trimat}
          // ref={chart_ret_cost}
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={repair_cost_trimat}
          legend_toggle = {false}
        />
     </span>
                          </div>
                          </div>

{/* ================================== รายงานค่าซ่อม ================================ */}
      

{/* ================================== รายงานค่าซ่อม ================================ */}

 
      


<div className="modal fade" id="modal-add-reportdd-data-Monthlyfuel">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">รายงานสรุปอัตราการใช้น้ำมันประจำเดือน</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-10" style={{ "z-index": "98" }}>
                        <div className="form-group ">

                          {/* อันนูน */}
                          <Select
                            options={companyGroup}
                   
                            value={companyGroup.filter((e) => {
                              
                              return e.value === filter_logistic.oem_target;
                            })}
                            ref={selectCompanyGroup}
                            onChange={(e) => {
                              if (e != null) {
                               
                                setFilter_logistic({
                                  ...filter_logistic,
                                  oem_target: e.value,
                                });
                              }
                              
                            }}
                          />

                          <label htmlFor="">กลุ่มบริษัท</label>

                        </div>
                      </div>


                    
                          <div className="col-12"></div>
                          <div className="col-sm-12">
                            <div className="form-group">
                            <NumberFormat
                                type="text"
                                className="form-control"
                                value={filter_logistic.fuelday}
                                decimalScale={2}
                                onChange={(e) =>
                                  setFilter_logistic({
                                    ...filter_logistic,
                                    fuelday: e.target.value.trim(),
                                  })
                                }
                              />
                              <label htmlFor="">ราคาน้ำมัน</label>
                            </div>
                          </div>
               
                      <div className="col-md-12">
                        <div className="form-group ">
                      
                          <DatePicker
                          value={filter_logistic.date}
                            selected={filter_logistic.date}
                            dateFormat={"MM-yyyy"}
                            onChange = {
                              async(date) =>{
                                setFilter_logistic({
                                  ...filter_logistic,
                                  date: date,
                                });
                              }
                             
                              }
                            selectsStart
                            startDate={filter_logistic.date}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            customInput={<CustomInput />}
                          />
                       {/*  <DatePicker
                              disabled={true}
                             value={filler_report_record.start_date}
                              selected={filler_report_record.start_date}
                              dateFormat={"dd-MM-yyyy"}
                              onChange={async (date) => SetDate_start(date)}
                              selectsStart
                              startDate={filler_report_record.start_date}
                              customInput={<CustomInput />}
                            ></DatePicker> */}
                        </div>
                      </div>
                    </div>
                    
                    <br />
                  </div>
                </div>

              </div>
             
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={() => {Getfuelmonth(); }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </div>


        <div className="modal fade" id="modal-Wire_mace_STP">
          <div className="modal-dialog modal-sm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Wire Mesh STP</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput"></div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-10" style={{ "z-index": "98" }}>
                        <div className="form-group ">

                          {/* อันนูน */}
                          <Select
                            options={companyGroup.label === "บจก.เอสทีสตีล(2001) ปัตตานี"}
                            disabled={true}
                            value={companyGroup.filter((e) => {
                              return e.value === filter_logistic.oem_target;
                            })}
                            ref={selectCompanyGroup}
                            
                                      
                            onChange={(e) => {
                              
                              if (e != null) {
                                
                                setFilter_logistic({
                                  ...filter_logistic,
                                  oem_target: e.value,
                                });
                              }
                            }}
                          />
            <label htmlFor="">กลุ่มบริษัท</label>

                        </div>
                      </div>


               
                      <div className="col-md-12">
                        <div className="form-group ">
                      
                        <DatePicker 
        selected = {filter_logistic.date}
        dateFormat = { "dd-MM-yyyy" }
        onChange = {
          async(date) =>{
            setFilter_logistic({
              ...filter_logistic,
              date: date,
            });
          }
         
          }
        selectsEnd startDate = { filter_logistic.date }
 /*        endDate = { filter_bill.date_end }
        minDate = { filter_bill.date_start } */
        customInput = { <CustomInput/> }
      
         >
        </DatePicker> 
                        </div>
                      </div>
                    </div>
                    
                    <br />
                  </div>
                </div>

              </div>
             
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={() => {GetWire_mace_STP(); }}
                >
                  ยืนยัน
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* /.content */}
      </div>
    {/*  <Footter/> */}
     </div>
     
     
  );
}

export default Report_pdf;
