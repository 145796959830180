import React, { useEffect, useState } from "react";
import { getOem, getToken, getUser } from "../../../../../Utils/Common";
import Table_Category_Component from "./component/Table_Category_Component";
import axios from "axios";
import Swal from "sweetalert2";
import Configs from "../../../../../config";

function Table_Product_Category({ modePage }) {
  const [categoryData, setCategoryData] = useState({
    data1: [],
    data2: [],
    data3: [],
    data4: [],
  });

  const [Tab, setTab] = useState({
    tab: "",
  })

  async function getCategoryTable(data, mode) {
    let pushData = {
      // user_id: getUser().fup,
      company_id: getUser().com,
      oem_id: getOem(),
      category_search: data,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/category/getTableCategory",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: pushData,
    })
      .then(async function (response) {
        if (mode === "lv1") {
          setCategoryData({
            ...categoryData,
            data1: response.data.category1,
          });

          categoryData.data1 = response.data.category1;
        } else if (mode === "lv2") {
          setCategoryData({
            ...categoryData,
            data2: response.data.category2,
          });

          categoryData.data2 = response.data.category2;
        } else if (mode === "lv3") {
          setCategoryData({
            ...categoryData,
            data3: response.data.category3,
          });

          categoryData.data3 = response.data.category3;
        } else if (mode === "lv4") {
          setCategoryData({
            ...categoryData,
            data4: response.data.category4,
          });

          categoryData.data4 = response.data.category4;
        } else {
          setCategoryData({
            ...categoryData,
            data1: response.data.category1,
            data2: response.data.category2,
            data3: response.data.category3,
            data4: response.data.category4,
          });

          categoryData.data1 = response.data.category1;
          categoryData.data2 = response.data.category2;
          categoryData.data3 = response.data.category3;
          categoryData.data4 = response.data.category4;
        }
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  useEffect(async () => {
    await getCategoryTable();
  }, []);

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Product Category</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Warehouse Management
                  </li>
                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product Category</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card card-primary card-outline card-outline-tabs">
            <div className="card-header p-0 border-bottom-0">
              <ul
                className="nav nav-tabs"
                id="custom-tabs-four-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={
                      modePage !== "lv2" &&
                      modePage !== "lv3" &&
                      modePage !== "lv4"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    id="elid1"
                    data-toggle="pill"
                    href="#elcolumns1-teb"
                    role="tab"
                    aria-controls="elcolumns1-teb"
                    aria-selected="true"
                    onClick={() => {
                      setTab({
                        tab: "lv1"
                      })
                    }}
                  >
                    Category Lv.1
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      modePage === "lv2" ? "nav-link active" : "nav-link"
                    }
                    id="elid2"
                    data-toggle="pill"
                    href="#elcolumns2-teb"
                    role="tab"
                    aria-controls="elcolumns2-teb"
                    aria-selected="false"
                    onClick={() => {
                      setTab({
                        tab: "lv2"
                      })
                    }}
                  >
                    Category Lv.2
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      modePage === "lv3" ? "nav-link active" : "nav-link"
                    }
                    id="elid3"
                    data-toggle="pill"
                    href="#elcolumns3-teb"
                    role="tab"
                    aria-controls="elcolumns3-teb"
                    aria-selected="false"
                    onClick={() => {
                      setTab({
                        tab: "lv3"
                      })
                    }}
                  >
                    Category Lv.3
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      modePage === "lv4" ? "nav-link active" : "nav-link"
                    }
                    id={"elid4"}
                    data-toggle="pill"
                    href="#elcolumns4-teb"
                    role="tab"
                    aria-controls="elcolumns4-teb"
                    aria-selected="false"
                    onClick={() => {
                      setTab({
                        tab: "lv4"
                      })
                    }}
                  >
                    Category Lv.4
                  </a>
                </li>
              </ul>
            </div>

            <div className="card-body">
              <div className="tab-content" id="custom-tabs-four-tabContent">
                <div
                  className={
                    modePage !== "lv2" &&
                    modePage !== "lv3" &&
                    modePage !== "lv4"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="elcolumns1-teb"
                  role="tabpanel"
                  aria-labelledby="elid1"
                >
                  <Table_Category_Component
                    mode="lv1"
                    dataTable={categoryData.data1}
                    getCategoryTable={getCategoryTable}
                    reset={Tab.tab}
                  />
                </div>
                <div
                  className={
                    modePage === "lv2"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="elcolumns2-teb"
                  role="tabpanel"
                  aria-labelledby="elid2"
                >
                  <Table_Category_Component
                    mode="lv2"
                    dataTable={categoryData.data2}
                    getCategoryTable={getCategoryTable}
                    reset={Tab.tab}
                  />
                </div>
                <div
                  className={
                    modePage === "lv3"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="elcolumns3-teb"
                  role="tabpanel"
                  aria-labelledby="elid3"
                >
                  <Table_Category_Component
                    mode="lv3"
                    dataTable={categoryData.data3}
                    getCategoryTable={getCategoryTable}
                    reset={Tab.tab}
                  />
                </div>
                <div
                  className={
                    modePage === "lv4"
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                  id="elcolumns4-teb"
                  role="tabpanel"
                  aria-labelledby="elid4"
                >
                  <Table_Category_Component
                    mode="lv4"
                    dataTable={categoryData.data4}
                    getCategoryTable={getCategoryTable}
                    reset={Tab.tab}
                  />
                </div>
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Table_Product_Category;
