import React from "react";
import NumberFormat from "react-number-format";

function AxisInput({ isEdit, rackData, setrackData, axis, handleInputChange }) {
  return (
    <>
      <div className="p-1 pr-3 row align-items-center mt-1">
        <div className="col-12">
          <div class="form-group mb-0">
            <NumberFormat
              className={`pl-1 form-control popup-input-wh3d${
                !isEdit.rack_edit ? "-disabled" : ""
              }`}
              disabled={!isEdit.rack_edit}
              decimalScale={0}
              value={rackData[`axis_${axis}_input`]}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  setrackData,
                  rackData,
                  `axis_${axis}_input`,
                  true
                )
              }
            />
            <label>
              Axis-{axis}
              <span
                hidden={rackData[`axis_${axis}_input`]}
                style={{ color: "red" }}
              >
                *
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="p-1 pr-3 row align-items-center">
        <div className="col-12">
          <div class="form-group mb-0">
            <select
              className={`pl-1 popup-input-wh3d${
                !isEdit.rack_edit ? "-disabled" : ""
              }`}
              disabled={!isEdit.rack_edit}
              value={rackData[`axis_${axis}_pos_input`]}
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  setrackData,
                  rackData,
                  `axis_${axis}_pos_input`,
                  true
                )
              }
            >
              <option value={"1"}>Alphabet</option>
              <option value={"2"}>Number</option>
            </select>
            <label>
              Position Format
              <span
                hidden={rackData[`axis_${axis}_pos_input`]}
                style={{ color: "red" }}
              >
                *
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="p-1 pr-3 row align-items-center">
        <div className="col-12">
          <div class="form-group mb-0">
            <input
              placeholder="Seperate Char"
              className={`pl-1 popup-input-wh3d${
                !isEdit.rack_edit ? "-disabled" : ""
              }`}
              disabled={!isEdit.rack_edit}
              value={rackData[`axis_${axis}_chr_input`]}
              onChange={(e) => {
                if (
                  /^[-/!@#$%^&*(),.?":{}|<>]+$/.test(e.target.value?.trim()) ||
                  e.target.value?.trim() === ""
                ) {
                  handleInputChange(
                    e.target.value,
                    setrackData,
                    rackData,
                    `axis_${axis}_chr_input`,
                    true
                  );
                }
              }}
            />
            <label>
              Seperate Char
              {/* <span
                hidden={rackData[`axis_${axis}_chr_input`]}
                style={{ color: "red" }}
              >
                *
              </span> */}
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default AxisInput;
