import React, { useEffect, useState, useRef } from "react";
import { getOem, getToken, getUser } from "../../../Utils/Common";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../config";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import Select from "react-select";
import NumberFormat from "react-number-format";
import Report_PP_UNAI from "./Component/Report_PP_UNAI";

function PPUNAI_Table() {
  // state
  const userId = getUser().fup;

  const [filter, setFilter] = useState({
    filter: "",
  });
  const [readyToPick, setReadyToPick] = useState({
    readyToPick: true,
  });
  const [firstData, setFirstData] = useState([]);
  const [secondData, setSecondData] = useState([]);

  const [isSecondTable, setIsSecondTable] = useState({
    isSecondTable: false,
    id: "",
    warehouse_name: "",
    is_complete: false,
  });

  const [refLightData, setRefLightData] = useState({
    id: "",
    data_length: 0,
  });

  let checkInterval = null;

  const FirstRowsData = [];
  for (let index = 0; index < firstData.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["delivery_no"] = (
      <div style={{ textAlign: "left" }}>{firstData[index].dl_no || "-"}</div>
    );
    rowItem["delivery_date"] = (
      <div style={{ textAlign: "left" }}>
        {moment(firstData[index].dl_delivery_date).format("DD-MM-YYYY") || "-"}
      </div>
    );
    rowItem["delivery_location"] = (
      <div style={{ textAlign: "left" }}>
        {firstData[index].dl_location || "-"}
      </div>
    );
    rowItem["status"] = (
      <div style={{ textAlign: "left" }}>
        {firstData[index].ds_name === "Ready to pick" ? (
          <span className="badge badge-success">
            {firstData[index].ds_name || "-"}
          </span>
        ) : firstData[index].ds_name === "In progress" ? (
          <span className="badge badge-warning">
            {firstData[index].ds_name || "-"}
          </span>
        ) : firstData[index].ds_name === "Complete" ? (
          <span className="badge badge-primary">
            {firstData[index].ds_name || "-"}
          </span>
        ) : (
          <span className="badge badge-danger">
            {firstData[index].ds_name || "-"}
          </span>
        )}
      </div>
    );
    rowItem["warehouse_name"] = (
      <div style={{ textAlign: "left" }}>{firstData[index].wh_name || "-"}</div>
    );
    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "left" }}>
        <div className="col-xl-4 col-md-4 col-xs-4">
          <a
            id="btn_edit"
            className=" btn btn-xs "
            onClick={async () => {
              setIsSecondTable({
                isSecondTable: true,
                id: firstData[index].id,
                warehouse_name: firstData[index].wh_name,
                is_complete:
                  firstData[index].ds_name === "Complete" ? true : false,
              });
              isSecondTable.isSecondTable = true;
              isSecondTable.id = firstData[index].id;
              isSecondTable.warehouse_name = firstData[index].wh_name;
              isSecondTable.is_complete =
                firstData[index].ds_name === "Complete" ? true : false;

              await getPrepareProductDetail(
                firstData[index].dl_id,
                firstData[index].wh_id
              );
            }}
          >
            <i
              class="fas fa-eye"
              style={{
                fontSize: "14px",
              }}
            ></i>
          </a>
        </div>
        <div className="col-xl-4 col-md-4 col-xs-4">
          <button
            style={{ whiteSpace: "nowrap" }}
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              getReportData(firstData[index].dl_id, firstData[index].wh_id);
            }}
          >
            Report
          </button>
        </div>
        <div className="col-xl-4 col-md-4 col-xs-4">
          {firstData[index].dl_ds_id ===
          "4dd77c98-93d3-4a83-8168-9ca37bfdb3a2" ? (
            <button
              style={{ whiteSpace: "nowrap" }}
              type="button"
              className="btn btn-block btn-primary"
              onClick={() => {
                PrepareProduct(firstData[index].dl_id, firstData[index].wh_id);
                // FinishPrepareProduct(
                //   firstData[index].dl_id,
                //   firstData[index].wh_id
                // );
              }}
            >
              จัดของ
            </button>
          ) : (
            // ) : firstData[index].dl_ds_id ===
            //   "7549be1c-1878-42bb-b6a0-f1864d71a220" ? (
            //   <div className="row">
            //     <div className="col-xl-6 col-md-6 col-xs-6">
            //       <button
            //         style={{ whiteSpace: "nowrap" }}
            //         type="button"
            //         className="btn btn-block btn-success"
            //         onClick={() => {
            //           FinishPrepareProduct(
            //             firstData[index].dl_id,
            //             firstData[index].wh_id
            //           );
            //         }}
            //       >
            //         เสร็จสิ้น
            //       </button>
            //     </div>
            //     <div className="col-xl-6 col-md-6 col-xs-6">
            //       <button
            //         style={{ whiteSpace: "nowrap" }}
            //         type="button"
            //         className="btn btn-block btn-danger"
            //         onClick={() => {
            //           cancelPrepareProduct(
            //             firstData[index].dl_id,
            //             firstData[index].wh_id
            //           );
            //         }}
            //       >
            //         ยกเลิก
            //       </button>
            //     </div>
            //   </div>
            <></>
          )}
        </div>
      </div>
    );
    FirstRowsData.push(rowItem);
  }

  const FirstTableColumns = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Delivery No."}</div>,
        field: "delivery_no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Delivery Date"}</div>,
        field: "delivery_date",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Delivery Location"}</div>,
        field: "delivery_location",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Status"}</div>,
        field: "status",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Warehouse Name"}</div>,
        field: "warehouse_name",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Management"}</div>,
        field: "management",
        width: 50,
      },
    ],
    rows: FirstRowsData,
  };

  const SecondRowsData = [];
  for (let index = 0; index < secondData.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["po_name"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_po_no || "-"}
      </div>
    );
    rowItem["part_no"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_part_no || "-"}
      </div>
    );
    rowItem["part_name"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_part_name || "-"}
      </div>
    );
    rowItem["pick_amount"] = (
      <div style={{ textAlign: "left" }}>
        <div
          style={{
            color:
              secondData[index].dp_quantity > secondData[index].can_out_amount
                ? "red"
                : "green",
          }}
        >
          <NumberFormat
            value={secondData[index].dp_quantity}
            displayType={"text"}
            thousandSeparator={true}
            thousandsGroupStyle="thousand"
          />{" "}
          /{" "}
          <NumberFormat
            value={secondData[index].can_out_amount}
            displayType={"text"}
            thousandSeparator={true}
            thousandsGroupStyle="thousand"
          />
        </div>
      </div>
    );
    const mergeData = Object.values(
      secondData[index].log_product.reduce((pre, cur) => {
        if (!pre[cur.lp_rack_id && cur.lp_rp_id]) {
          pre[cur.lp_rack_id && cur.lp_rp_id] = {
            ...cur,
          };
        } else {
          pre[cur.lp_rack_id && cur.lp_rp_id].out_quantity =
            Number(pre[cur.lp_rack_id && cur.lp_rp_id].out_quantity) +
            Number(cur.out_quantity);
        }
        return pre;
      }, {})
    );
    // let current_amount = "";
    let quantity = "";
    let rack = "";
    let position = "";

    for (const item of mergeData) {
      // current_amount += `${item.out_current_amount} / ${item.out_limit_amount} \n`;
      quantity += `${item.out_quantity} \n`;
      rack += `${item.rack_name} \n`;
      position += `${item.rp_position} \n`;
    }
    rowItem["rack"] = (
      // <div style={{ textAlign: "left" }}>
      //   {secondData[index].log_product.length > 0
      //     ? secondData[index].log_product.map((item, index) => {
      //         return <div key={index}>{item.rack_name}</div>;
      //       })
      //     : "-"}
      // </div>
      <span style={{ whiteSpace: "preserve nowrap" }}>{rack || "-"}</span>
    );
    rowItem["position"] = (
      // <div style={{ textAlign: "left" }}>
      //   {secondData[index].log_product.length > 0
      //     ? secondData[index].log_product.map((item, index) => {
      //         return <div key={index}>{item.rp_position}</div>;
      //       })
      //     : "-"}
      // </div>
      <span style={{ whiteSpace: "preserve nowrap" }}>{position || "-"}</span>
    );
    rowItem["quantity"] = (
      // <div style={{ textAlign: "left" }}>
      //   {secondData[index].log_product.length > 0
      //     ? secondData[index].log_product.map((item, index) => {
      //         return (
      //           <div key={index}>
      //             <NumberFormat
      //               value={item.out_quantity}
      //               displayType={"text"}
      //               thousandSeparator={true}
      //             />
      //           </div>
      //         );
      //       })
      //     : "-"}
      // </div>
      <span style={{ whiteSpace: "preserve nowrap" }}>{quantity || "-"}</span>
    );
    SecondRowsData.push(rowItem);
  }

  const SecondTableColumns = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Po Name"}</div>,
        field: "po_name",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part No."}</div>,
        field: "part_no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part Name"}</div>,
        field: "part_name",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Pick Amount"}</div>,
        field: "pick_amount",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Rack"}</div>,
        field: "rack",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Position"}</div>,
        field: "position",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Quantity"}</div>,
        field: "quantity",
        width: 50,
      },
    ],
    rows: SecondRowsData,
  };

  const SecondCompleteRowsData = [];
  for (let index = 0; index < secondData.length; index += 1) {
    const rowItem = {};
    rowItem["no"] = <div style={{ textAlign: "left" }}>{index + 1}</div>;
    rowItem["po_name"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_po_no || "-"}
      </div>
    );
    rowItem["part_no"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_part_no || "-"}
      </div>
    );
    rowItem["part_name"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_part_name || "-"}
      </div>
    );
    rowItem["pick_amount"] = (
      <div style={{ textAlign: "left" }}>
        <div
          style={{
            color: "green",
          }}
        >
          <NumberFormat
            value={secondData[index].dp_quantity}
            displayType={"text"}
            thousandSeparator={true}
            thousandsGroupStyle="thousand"
          />{" "}
        </div>
      </div>
    );
    rowItem["rack"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_rack_name || "-"}
      </div>
    );
    rowItem["position"] = (
      <div style={{ textAlign: "left" }}>
        {secondData[index].dp_rp_position || "-"}
      </div>
    );
    rowItem["quantity"] = (
      <div style={{ textAlign: "left" }}>
        <NumberFormat
          value={secondData[index].dp_quantity}
          displayType={"text"}
          thousandSeparator={true}
          thousandsGroupStyle="thousand"
        />
      </div>
    );
    SecondCompleteRowsData.push(rowItem);
  }

  const SecondTableCompleteColumns = {
    columns: [
      {
        label: <div style={{ textAlign: "left" }}>{"No."}</div>,
        field: "no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Po Name"}</div>,
        field: "po_name",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part No."}</div>,
        field: "part_no",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Part Name"}</div>,
        field: "part_name",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Pick Amount"}</div>,
        field: "pick_amount",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Rack"}</div>,
        field: "rack",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Position"}</div>,
        field: "position",
        width: 50,
      },
      {
        label: <div style={{ textAlign: "left" }}>{"Quantity"}</div>,
        field: "quantity",
        width: 50,
      },
    ],
    rows: SecondCompleteRowsData,
  };

  async function getPrepareProduct() {
    let tempSearch = {
      filter: filter.filter?.trim() || null,
      ready_to_pick: readyToPick.readyToPick,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios
      .request({
        method: "POST",
        url:
          Configs.API_URL_AIOI_Back +
          "/api/prepareProductUnai/getPrepareProduct_UNAI",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempSearch,
      })
      .then((response) => {
        setFirstData(response.data.data);
        setIsSecondTable({
          isSecondTable: false,
          id: "",
          warehouse_name: "",
          is_complete: false,
        });
        setSecondData([]);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "getPrepare Error",
        });
      });
  }

  async function clearFilter() {
    setFilter({
      filter: "",
    });
    filter.filter = "";
    setReadyToPick({
      readyToPick: true,
    });
    readyToPick.readyToPick = true;
    await getPrepareProduct();
  }

  async function getPrepareProductDetail(dl_id, wh_id) {
    let tempSearch = {
      dl_id: dl_id,
      wh_id: wh_id,
      oem_id: getOem(),
      company_id: getUser().com,
    };
    await axios
      .request({
        method: "POST",
        url:
          Configs.API_URL_AIOI_Back +
          "/api/prepareProductUnai/getPrepareProductUnaiDetail",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempSearch,
      })
      .then((response) => {
        setSecondData(response.data.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "getPrepareProductDetail Error",
        });
      });
  }

  // async function SaveFinalIn(dl_id, wh_id, ref_id) {
  //   Swal.fire({
  //     title: "กำลังบันทึกข้อมูล",
  //     text: "Loading",
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     timer: 2000,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });

  //   const send_data = {
  //     fup: getUser().fup,
  //     company_id: getUser().com,
  //     oem_id: getOem(),
  //     ref_id: ref_id,
  //     wh_id: wh_id,
  //     dl_id: dl_id,
  //   };

  //   await axios({
  //     method: "POST",
  //     url: `${Configs.API_URL_AIOI_Back}/api/prepareProduct/finishOutProduct`,
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: send_data,
  //   })
  //     .then(async (response_data) => {
  //       Swal.fire({
  //         icon: "success",
  //         text: "เสร็จสิ้น",
  //         showConfirmButton: false,
  //         timer: 2000,
  //       }).then(async () => {
  //         if (refLightData.id) {
  //           await DeleteTemp();
  //           await CloseLight(wh_id);
  //         }
  //         window.location.reload();
  //       });
  //     })
  //     .catch(async (error) => {
  //       console.log(error);
  //       Swal.fire("Error", error.response.data.error.message, "error");
  //       if (refLightData.id) {
  //         await DeleteTemp();
  //         await CloseLight(wh_id);
  //       }
  //       window.location.reload();
  //     });
  // }

  async function PrepareProduct(dl_id, wh_id) {
    Swal.fire({
      icon: "warning",
      title: "คุณต้องการที่จะจัดของใช่หรือไม่",
      showCancelButton: true,
      confirmButtonText: `ใช่`,
      cancelButtonText: `ไม่`,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let temp = {
          oem_id: getOem(),
          company_id: getUser().com,
          fup: getUser().fup,
          dl_id: dl_id || null,
          wh_id: wh_id || null,
        };
        await axios
          .request({
            method: "POST",
            url:
              Configs.API_URL_AIOI_Back +
              "/api/prepareProductUnai/prepareOutProduct_Unai",
            headers: {
              Authorization: getToken(),
              "X-TTT": Configs.API_TTT,
              "Content-Type": "application/json",
            },
            data: temp,
          })
          .then(async (response) => {
            if (response.data.data.statusPP) {
              Swal.fire({
                icon: "success",
                title: "ตรวจสอบเสร็จสิ้น",
                text: "คุณต้องการนำของออกใช่หรือไม่",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                cancelButtonText: `ไม่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await FinishPrepareProduct(dl_id, wh_id);
                }
              });
              // await getPrepareProduct();

              // setRefLightData({
              //   id: response.data.data.data.ref_id,
              //   data_length: response.data.data.data.data_length,
              // });
              // refLightData.id = response.data.data.ref_id;
              // refLightData.data_length = response.data.data.data.data_length;

              // if (response.data.data.data.ref_id) {
              //   checkInterval = setInterval(async () => {
              //     const check_data = {
              //       temp_ref_id: refLightData.id,
              //       temp_status: true,
              //     };

              //     await axios({
              //       method: "POST",
              //       url: `${Configs.API_URL_AIOI_Back}/api/temp_item/getTempData`,
              //       headers: {
              //         Authorization: getToken(),
              //         "X-TTT": Configs.API_TTT,
              //         "Content-Type": "application/json",
              //       },
              //       data: check_data,
              //     })
              //       .then(async (response_data) => {
              //         if (
              //           response_data.data.data.length ==
              //           refLightData.data_length
              //         ) {
              //           clearInterval(checkInterval);
              //           await SaveFinalIn(dl_id, wh_id, refLightData.id);
              //         }
              //       })
              //       .catch(async (error) => {
              //         console.log(error);
              //       });
              //   }, 2000);
              // }
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: `${response.data.data.message}`,
              });
              // await DeleteTemp();
              // await CloseLight();
            }
          })
          .catch(async (error) => {
            Swal.fire({
              icon: "error",
              title: "Prepare Product Error",
              // text: `${error.response.data.message}`,
            });
            // await DeleteTemp();
            // await CloseLight();
          });
      }
    });
  }

  // async function DeleteTemp() {
  //   await axios({
  //     method: "POST",
  //     url: `${Configs.API_URL_AIOI_Back}/api/temp_item/deleteTemp`,
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: {
  //       temp_ref_id: refLightData.id,
  //     },
  //   })
  //     .then(() => {})
  //     .catch(async (error) => {
  //       console.log(error);
  //     });
  // }

  // async function cancelPrepareProduct(dl_id, wh_id) {
  //   Swal.fire({
  //     icon: "warning",
  //     title: "คุณต้องการที่จะยกเลิกการจัดของใช่หรือไม่",
  //     showCancelButton: true,
  //     confirmButtonText: `ใช่`,
  //     cancelButtonText: `ไม่`,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       let temp = {
  //         oem_id: getOem(),
  //         company_id: getUser().com,
  //         fup: getUser().fup,
  //         dl_id: dl_id || null,
  //         wh_id: wh_id || null,
  //       };
  //       await axios
  //         .request({
  //           method: "POST",
  //           url:
  //             Configs.API_URL_AIOI_Back +
  //             "/api/prepareProduct/cancelPrepareProduct",
  //           headers: {
  //             Authorization: getToken(),
  //             "X-TTT": Configs.API_TTT,
  //             "Content-Type": "application/json",
  //           },
  //           data: temp,
  //         })
  //         .then((response) => {
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success",
  //             text: "ยกเลิกการจัดของสำเร็จ",
  //             timer: 2000,
  //             showConfirmButton: false,
  //             allowEnterKey: false,
  //             allowEscapeKey: false,
  //             allowOutsideClick: false,
  //           }).then(async () => {
  //             if (refLightData.id) {
  //               await DeleteTemp();
  //               await CloseLight(wh_id);
  //             }
  //             window.location.reload();
  //           });
  //         })
  //         .catch(async (error) => {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Cancel Prepare Product Error",
  //             // text: `${error.response.data.message}`,
  //           });
  //           if (refLightData.id) {
  //             await DeleteTemp();
  //             await CloseLight(wh_id);
  //           }
  //           window.location.reload();
  //         });
  //     }
  //   });
  // }

  async function FinishPrepareProduct(dl_id, wh_id) {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      fup: getUser().fup,
      dl_id: dl_id || null,
      wh_id: wh_id || null,
    };
    await axios
      .request({
        method: "POST",
        url:
          Configs.API_URL_AIOI_Back +
          "/api/prepareProductUnai/finishOutProduct_Unai",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: temp,
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "จัดของเสร็จสิ้นแล้ว",
          timer: 2000,
          showConfirmButton: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then(async () => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Finish Prepare Product Error",
          // text: `${error.response.data.message}`,
        });
        window.location.reload();
      });
  }

  // async function CloseLight(wh_id) {
  //   const send_data = {
  //     user_id: getUser().fup,
  //     company_id: getUser().com,
  //     oem_id: getOem(),
  //     warehouse_id: wh_id,
  //   };

  //   await axios({
  //     method: "POST",
  //     url: `${Configs.API_URL_AIOI_Back}/api/logProduct/closeLight`,
  //     headers: {
  //       Authorization: getToken(),
  //       "X-TTT": Configs.API_TTT,
  //       "Content-Type": "application/json",
  //     },
  //     data: send_data,
  //   })
  //     .then(async (response_data) => {
  //       // console.log(response_data);
  //     })
  //     .catch(async (error) => {
  //       console.log(error);
  //     });
  // }

  async function getReportData(dl_id, wh_id) {
    let tempSearch = {
      oem_id: getOem(),
      company_id: getUser().com,
      dl_id: dl_id,
      wh_id: wh_id,
    };
    await axios
      .request({
        method: "POST",
        url:
          Configs.API_URL_AIOI_Back +
          "/api/prepareProductUnai/getReportDataUnai",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: tempSearch,
      })
      .then(async (response) => {
        let data = response.data.data.data;
        if (response.data.data.statusPP) {
          // getcompanyLogo
          await axios
            .request({
              method: "get",
              url: Configs.API_URL + "/company/findById?id=" + getUser().com,
              headers: {
                Authorization: getToken(),
                "X-TTT": Configs.API_TTT,
                "Content-Type": "application/json",
              },
            })
            .then((response2) => {
              // console.log(response2.data)
              let companyData = {
                logo: response2.data.logo_path || "",
                name: response2.data.company_name || "",
              };
              Report_PP_UNAI(data, companyData);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "getcompanyLogo Error",
              });
            });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${response.data.data.message}`,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "getReportData Error",
        });
      });
  }

  useEffect(() => {
    getPrepareProduct();
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-4 col-md-4 col-xl-6">
                  <h1>Prepare Product (UNAI)</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <a href="/Welcome">Home</a>
                    </li>
                    <li className="breadcrumb-item active">IoT Master Data</li>
                    <li className="breadcrumb-item active">
                      Prepare Product (UNAI)
                    </li>
                  </ol>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={filter.filter}
                      onChange={(e) => {
                        setFilter({
                          filter: e.target.value,
                        });
                        filter.filter = e.target.value;
                      }}
                    />
                    <label>Search</label>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await getPrepareProduct();
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await clearFilter();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-xl-2">
                  <div
                    className="custom-control custom-checkbox"
                    style={{ marginTop: "1rem" }}
                  >
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id={`customCheckbox`}
                      checked={readyToPick.readyToPick}
                      onChange={async (e) => {
                        setReadyToPick({
                          readyToPick: e.target.checked,
                        });
                        readyToPick.readyToPick = e.target.checked;
                        await getPrepareProduct();
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`customCheckbox`}
                    >
                      Ready to Pick
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-12 col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-responsive">
                        <div className="row">
                          <div className="col-12 col-md-12 col-xl-12">
                            <MDBDataTable
                              sortable={false}
                              className="table table-head-fixed"
                              // disableRetreatAfterSorting={disAftSort}
                              disableRetreatAfterSorting={true}
                              striped
                              bordered
                              hover
                              data={FirstTableColumns}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 2nd Table */}
              {isSecondTable.isSecondTable && !isSecondTable.is_complete ? (
                <div className="row">
                  <div className="col-12 col-md-12 col-xl-12">
                    <div className="card">
                      {/* <div className="card-header">
                        <h4>{isSecondTable.warehouse_name || "-"}</h4>
                      </div> */}
                      <div className="card-body">
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-12 col-md-12 col-xl-12">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                disableRetreatAfterSorting={true}
                                striped
                                bordered
                                hover
                                data={SecondTableColumns}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : isSecondTable.isSecondTable && isSecondTable.is_complete ? (
                <div className="row">
                  <div className="col-12 col-md-12 col-xl-12">
                    <div className="card">
                      {/* <div className="card-header">
                        <h4>{isSecondTable.warehouse_name || "-"}</h4>
                      </div> */}
                      <div className="card-body">
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-12 col-md-12 col-xl-12">
                              <MDBDataTable
                                sortable={false}
                                className="table table-head-fixed"
                                disableRetreatAfterSorting={true}
                                striped
                                bordered
                                hover
                                data={SecondTableCompleteColumns}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default PPUNAI_Table;
