import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import Configs from "../../../../../config";
import axios from "axios";
import Swal from "sweetalert2";
import {
  getOem,
  getToken,
  getFeature,
  getUser,
  getAcademy,
  getOemlist_,
} from "../../../../../Utils/Common";

import { userdefault_img } from "../../../../../routes/imgRoute/imgUrl";
import { MDBDataTable } from "mdbreact";
import NumberFormat from "react-number-format";

import "../../../../../../src/assets/css/Quotation_CutePlus.css";
import { getDropdown } from "../../../../../Utils/Employee_history";

function PO_Products_Component({
  id,
  mode,
  isHistory,
  ref_id_po,
  isDisabled,
  inputDataTab2,
  setInputDataTab2,
  purchaseOrder_data,
  setPurchaseOrder_data,
  errorListTab2,
  setErrorListTab2,
  functionSaveTab2,
  setFunctionSaveTab2,
}) {

  const validThai = new RegExp(`[\u0E00-\u0E7F]`);

  const modalProductRef = useRef();

  const [categoryData, setCategoryData] = useState({
    data: []
  });

  const [MDBT, setMDBT] = useState(true);

  const [productData, setProductData] = useState({
    data: [],
    filter: []
  });

  const [selectProductData, setSelectProductData] = useState({
    data: [],
    newData: [],
    updateData: [],
    delData: [],
  });

  const [searchData, setSearchData] = useState({
    code: "",
    name: "",
    barcode: "",
    category: "",
    customer_barcode: "",
    customer_product_name: ""
  });

  const [currency, setCurrency] = useState({
    data: []
  });

  const [symbol, setSymbol] = useState({
    data: "฿",
    data_name: "THB"
  });

  const [ref_id_po_change, setRef_id_po_change] = useState({
    data: false,
  });

  useEffect(() => {
    if (purchaseOrder_data.po_currency_id) {
      let find_symbol = currency.data?.find(el => el.currency_id === purchaseOrder_data.po_currency_id)
      if (find_symbol) {
        setSymbol({
          data: find_symbol.currency_symbol || "฿",
          data_name: find_symbol.currency_name || "THB"
        });
        symbol.data = find_symbol.currency_symbol || "฿"
        symbol.data_name = find_symbol.currency_name || "THB"
      } else {
        setSymbol({
          data: "฿",
          data_name: "THB"
        });
        symbol.data = "฿"
        symbol.data_name = "THB"
      }
    }
  }, [purchaseOrder_data.po_currency_id, selectProductData.data])

  useEffect(() => {
    calSummaryProductTable();
  }, [selectProductData.data])

  useEffect(async () => {
    if (ref_id_po.data) {
      await GetQuotationRelationProduct(ref_id_po.data);
    }
    setRef_id_po_change({
      ...ref_id_po_change,
      data: true,
    });
    ref_id_po_change.data = true;
    await GetProduct();
    // calSummaryProductTable();

  }, [ref_id_po.data])

  useEffect(async () => {
    if (purchaseOrder_data.po_confirm_sup === true) {
      await GetProduct();
      setPurchaseOrder_data({ ...purchaseOrder_data, po_confirm_sup: false });
      purchaseOrder_data.po_confirm_sup = false;
    }
  }, [purchaseOrder_data.po_sup_id, purchaseOrder_data.po_confirm_sup]);

  useEffect(async () => {

    const currencyDD = await getDropdown("currency");
    setCurrency({ data: currencyDD });
    currency.data = currencyDD;

    if (mode === "edit" || mode === "view") {
      await GetQuotationRelationProduct();
    } else {
      setPurchaseOrder_data({ ...purchaseOrder_data, po_currency_id: "33570a04-ad41-4d93-bf8b-278cf9f29083" });
      purchaseOrder_data.po_currency_id = "33570a04-ad41-4d93-bf8b-278cf9f29083";
    }
    await GetCategoryLv2();
    await GetProduct();
  }, []);

  useEffect(async () => {
    setInputDataTab2({
      ...inputDataTab2,
      data: selectProductData.data,
      newData: selectProductData.newData,
      updateData: selectProductData.updateData,
      delData: selectProductData.delData,
    });

    inputDataTab2.data = selectProductData.data;
    inputDataTab2.newData = selectProductData.newData;
    inputDataTab2.updateData = selectProductData.updateData;
    inputDataTab2.delData = selectProductData.delData;

    setFunctionSaveTab2({
      ...functionSaveTab2,
      data: checkErrorSelectProductData,
    });

    functionSaveTab2.data = checkErrorSelectProductData;
  }, [selectProductData.data, purchaseOrder_data.po_total_price]);

  async function openModalProduct() {
    const tempSelectProduct = selectProductData.data;
    const tempProductTable = productData.data;

    const tempList = tempProductTable.map((item1) => {
      const check = tempSelectProduct.find(
        (item2) => item2.product_id === item1.product_id
      );

      if (check) {
        item1.check = true;
        return item1;
      } else {
        item1.check = false;
        return item1;
      }
    });

    setProductData({ ...productData, data: tempList });
    productData.data = tempList;
    setMDBT(true)
    modalProductRef.current.click();
  }

  async function GetQuotationRelationProduct(ref_id) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      user_id: getUser().fup,
      po_id: ref_id ? ref_id : id,
      isHistory: isHistory
    };

    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/purchase_order/getPO_Product",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        console.log('response', response.data);
        setInputDataTab2({
          ...inputDataTab2,
          data: response.data.data,
        });

        inputDataTab2.data = response.data.data;
        setMDBT(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function GetCategoryLv2() {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/GetCategoryLv2",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: tempData,
    })
      .then(function (response) {
        setCategoryData({ data: response.data });
        categoryData.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function GetProduct(cus_id) {
    let tempData = {
      company_id: getUser().com,
      oem_id: getOem(),
      // user_id: getUser().fup,
      code: searchData.code.trim() || null,
      name: searchData.name.trim() || null,
      barcode: searchData.barcode.trim() || null,
      category: searchData.category.trim() || null,
      customer_barcode: searchData.customer_barcode.trim() || null,
      customer_product_name: searchData.customer_product_name.trim() || null,
      from_PO: true
    };

    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/product/getProductAndDetails",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: tempData,
    })
      .then(async function (response) {

        let data = new FormData();
        data.append("company_id", getUser().com);
        data.append("oem_id", getOem());
        data.append("user_id", getUser().fup);
        data.append("products", JSON.stringify(response.data));

        await axios({
          method: "post",
          url: Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
          headers: {
            Authorization: getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "multipart/form-data",
          },
          data: data,
        })
          .then(async (response) => {
            if (response.data) {
              if (response.data.length == 0) {
                await resetProduct(false);
                return;
              } else if (productData.data?.length > 0) {
                setProductData({
                  ...productData,
                  filter: response.data
                })
                productData.filter = response.data
              }
              await SetProduct_Details(productData.data?.length > 0 ? productData.data : response.data, cus_id)
            }
          })
          .catch(async (error) => {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาดในการดึงรูปภาพ",
            });
          });
        // const tempRes = response.data;
        setMDBT(true)
        // calSummaryProductTable();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const SetProduct_Details = async (tempRes, cus_id) => {
    const tempResList = [];
    const selectProductDataList = [];

    for (let item1 of tempRes) {

      if (productData.filter.length > 0) {

        const check = productData.filter.find(el => el.product_id === item1.product_id)
        if (check) {
          item1.is_use = true
        } else {
          item1.is_use = false
        }
      } else {
        item1.is_use = true
      }

      if (cus_id) {
        const check = selectProductData.data.find(el => el.product_id === item1.product_id);
        if (check) {
          item1.check = true;
          tempResList.push(item1);
          continue;
        }
      }

      item1.check = false;
      item1.discount = [];
      item1.retail_price = "";
      item1.pq_unit = "piece"
      item1.gp = "";
      item1.product_quantity = "";
      item1.customer_code = "";
      item1.supplier_code = "";
      item1.oldNew = "new";
      if (item1.barcode_id.length === 1) {
        item1.isBarSelect = false;
        item1.bar_code = item1.barcode_id[0] || "";
      } else if (item1.barcode_id.length === 0) {
        item1.isBarSelect = false;
        item1.bar_code = "";
      } else {
        item1.isBarSelect = true;
        item1.bar_code = item1.barcode_id[0] || "";
      }

      if (item1.detailsAdvance) {
        item1.retail_price = typeof item1.detailsAdvance.pc_import_price === "string" ?
          parseFloat(item1.detailsAdvance.pc_import_price).toFixed(2) : item1.detailsAdvance.pc_import_price?.toFixed(2);
      }

      if (item1.detailsSupplier && purchaseOrder_data.po_sup_id) {
        const check_has = item1.detailsSupplier?.find(el => el.srp_sub_id === purchaseOrder_data.po_sup_id)
        if (check_has) {
          item1.bar_code = check_has.srp_pb_id || item1.barcode_id[0];
          item1.supplier_code = check_has.srp_barcode || ""
        }
      } else if (!purchaseOrder_data.po_sup_id) {
        item1.bar_code = item1.barcode_id[0] || "";
      }

      if (item1.detailsSupPrd && purchaseOrder_data.po_sup_id) {

        if (item1.detailsSupPrd.ps_supplier_id === purchaseOrder_data.po_sup_id) {
          item1.retail_price = (parseFloat(item1.detailsSupPrd.ps_buy_price || 0) * parseFloat(item1.detailsSupPrd.ps_exchange_rate || 1))
        }
      }

      if (mode === "edit" || mode === "view" || ref_id_po_change.data === true) {
        console.log("in");
        for (let item2 of inputDataTab2.data) {
          if (item1.product_id === (item2.pop_product_id ? item2.pop_product_id : item2.product_id)) {
            item1.pop_id = item2.pop_id;
            item1.check = true;
            item1.supplier_code = item2.pop_sup_barcode || "";
            item1.oldNew = "old";
            item1.bar_code = item2.pop_pb_id;
            item1.pq_unit = item2.pop_quantity_unit?.toLowerCase();
            item1.retail_price = item2.pop_prices;
            item1.product_quantity = item2.pop_quantity;
            if (item2.pod_discounts && item2.pod_discounts.length > 0) {
              item1.discount = item2.pod_discounts?.map((el, index) => {
                return {
                  discount: el,
                  unit: item2.pod_unit_discounts[index]
                }
              });
            }
            /* if (item2.qp_product_name_th)
              item1.product_name = item2.qp_product_name_th || "";
            if (item2.qp_product_name_eng)
              item1.product_name_eng = item2.qp_product_name_eng || ""; */
            item1 = { ...item1, ...item2 }
            console.log('item1', item1);
            selectProductDataList.push(item1);
          }
        }
      }

      tempResList.push(item1);

    }
    console.log('selectProductDataList', selectProductDataList);

    if (!cus_id) {
      if (ref_id_po_change.data === false) {
        setSelectProductData({
          ...selectProductData,
          data: selectProductDataList,
        });
        selectProductData.data = selectProductDataList;
      } else /* if (mode !== "add")  */{

        setSelectProductData({
          ...selectProductData,
          data: selectProductDataList
        });
        selectProductData.data = selectProductDataList;
      }
    }

    setRef_id_po_change({
      ...ref_id_po_change,
      data: false
    });
    ref_id_po_change.data = false;

    setProductData({ ...productData, data: tempResList });
    productData.data = tempResList;
  }

  const handleChangeDiscount = async (index, index2, field, value) => {
    let temp = selectProductData.data;
    if (field === "discount") {
      if (temp[index].discount[index2].unit === "percent") {
        if (parseFloat(value?.toString()?.replace(/,/g, "")) > 100) {
          temp[index].discount[index2].discount = 100;
        } else {
          temp[index].discount[index2].discount = value;
        }
      } else {
        temp[index].discount[index2].discount = value;
      }
    } else if (field === "unit") {
      temp[index].discount[index2].unit = value;
      if (temp[index].discount[index2].unit === "percent" && parseFloat(temp[index].discount[index2].discount?.toString()?.replace(/,/g, "")) > 100) {
        temp[index].discount[index2].discount = 100;
      }
    }

    setSelectProductData({
      ...selectProductData,
      data: temp,
    });
    selectProductData.data = temp;
  };

  const rowsData1 = [];
  let run_number = 0;
  for (let index = 0; index < productData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{run_number + 1}</div>;

    rowItem["code"] = productData.data[index].product_code
      ? productData.data[index].product_code
      : "-";

    rowItem["barcode"] = productData.data[index].barcode[0] ? productData.data[index].barcode[0] : "-";

    rowItem["image"] = (
      <div style={{ display: "flex", justifyContent: "center", width: "70px" }}>
        <img
          id="img"
          alt="display image"
          className="img-fluid rounded shadow border-0"
          src={
            productData.data[index].pci_img_path !== "" &&
              productData.data[index].pci_img_path !== null &&
              productData.data[index].pci_img_path !== undefined
              ? productData.data[index].pci_img_path?.indexOf("blob") >= 0
                ? productData.data[index].pci_img_path
                : Configs.API_URL_IMG_cuteplus +
                `/static/${productData.data[index].pci_img_name}/${productData.data[index].pci_img_path}`
              : userdefault_img.imgs
          }
          onError={(e) => {
            e.target.src = userdefault_img.imgs;
          }}
          style={{
            width: "70px",
            height: "70px",
            position: "relative",
          }}
        />
      </div>
    );

    rowItem["name"] = productData.data[index].product_name_eng
      ? productData.data[index].product_name_eng
      : "-";

    rowItem["category"] = productData.data[index].cat_name
      ? productData.data[index].cat_name
      : "-";

    rowItem["management"] =
      productData.data[index].check === false ? (
        <div>
          <button
            className="btn btn-block btn-primary"
            onClick={() => {
              let tempData = productData.data;

              tempData[index].check = true;
              if (tempData[index].statusUpdate) {
                tempData[index].status = "update";
              } else {
                tempData[index].status = "add";
              }

              setProductData({
                ...productData,
                data: tempData,
              });
            }}
          >
            เลือก
          </button>
        </div>
      ) : (
        <div>
          <button
            className="btn btn-block btn-danger"
            onClick={() => {
              let tempData = productData.data;

              tempData[index].check = false;
              tempData[index].status = "del";

              setProductData({
                ...productData,
                data: tempData,
              });
            }}
          >
            ยกเลิก
          </button>
        </div>
      );
    if (productData.data[index].is_use) {
      run_number += 1;
      rowsData1.push(rowItem);
    }
  }

  const ProductTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "barcode",
      },
      {
        label: "Image",
        field: "image",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Category",
        field: "category",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],

    rows: rowsData1,
  };

  const calSummaryProductTable = async () => {
    let sumTotal = 0;
    let beforeVat = 0;
    let vat = 0;
    let valueDelivery = parseFloat(purchaseOrder_data.quo_shipping_cost) || 0;
    let discountfinal = parseFloat(purchaseOrder_data.po_final_discount) || 0;
    for (let item of selectProductData.data) {
      let retail_price = parseFloat(item.retail_price?.toString()?.replace(/,/g, "") || 0) || 0;
      let p_quantity = parseInt(item.product_quantity?.toString()?.replace(/,/g, "") || 0) || 0;
      let gp = parseFloat(item.gp);
      const x_unit = item.pq_unit === "piece" ? 1 : item.pq_unit === "pack" ? parseInt(item.detailsAdvance.pc_pack || 1) : item.pq_unit === "ctn" ? parseInt(item.detailsAdvance.pc_ctn || 1) : 1;

      sumTotal += (retail_price * (p_quantity * x_unit));

      let sumItem = (retail_price * (p_quantity * x_unit));
      // const sumItemDiscount = (retail_price * (p_quantity * x_unit));
      let discount_cals = 0;
      if (item.discount && item.discount?.length > 0) {
        let sumDiscoutItem = sumItem
        for (let item2 of item.discount) {
          if (!item2.discount) continue;
          let discount_old = 0;

          const discout_ = parseFloat(item2.discount?.toString()?.replace(/,/g, "") || 0) || 0;
          discount_old = discount_cals;

          if (item2.unit === "bath") {
            discount_cals += discout_;
            sumDiscoutItem -= discout_
          } else if (item2.unit === "percent") {
            const _calPercent = (((discout_ / 100) * sumDiscoutItem));
            discount_cals += _calPercent
            sumDiscoutItem -= _calPercent;
          }
          // console.log('discount_cals', discount_cals)
          if (discount_cals > sumItem) {
            item2.discount = 0;
            discount_cals = discount_old
          }
        }
      }
      if ((retail_price * (p_quantity * x_unit)) > discount_cals) {
        sumTotal -= discount_cals;
      } else {
        sumTotal -= (retail_price * (p_quantity * x_unit));
      }
      item.sumTotal = sumTotal;
    }
    let product_total = purchaseOrder_data.po_incl_vat === false ? sumTotal : ((7 / 100) * sumTotal) + sumTotal;

    if (purchaseOrder_data.po_incl_vat === true) {
      vat = (7 / 100) * sumTotal;
      beforeVat = sumTotal;
      sumTotal = beforeVat + vat;
      // console.log('sumTotal', sumTotal);
    } else {
      beforeVat = sumTotal;
      // sumTotal += valueDelivery;
      vat = 0;
    }
    if (discountfinal) {
      sumTotal -= discountfinal;
    }
    setPurchaseOrder_data({
      ...purchaseOrder_data,
      po_total_price: sumTotal,
      po_total_befor_vat: beforeVat,
      po_vat: vat,
      quo_product_total: product_total
    });
    purchaseOrder_data.po_total_price = sumTotal;
    purchaseOrder_data.po_total_befor_vat = beforeVat;
    purchaseOrder_data.po_vat = vat;
    purchaseOrder_data.quo_product_total = product_total;

  }

  const rowsData2 = [];
  for (let index = 0; index < selectProductData.data.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["product_code"] = selectProductData.data[index].product_code
      ? selectProductData.data[index].product_code
      : "-";
    rowItem["bar_code"] = (
      <div style={{ display: "flex", justifyContent: "center", minWidth: "max-content" }}>
        {selectProductData.data[index].isBarSelect === false ? (
          <>
            <select
              type="text"
              className="form-control"
              disabled={true}
              value={selectProductData.data[index].bar_code || ""}
            >
              <option value="" disabled selected>
                -- เลือก --
              </option>

              <option value={selectProductData.data[index].barcode_id[0]}>
                {selectProductData.data[index].barcode[0]}
              </option>
            </select>
          </>
        ) : (
          <>
            <select
              type="text"
              className="form-control"
              disabled={isDisabled}
              value={selectProductData.data[index].bar_code || ""}
              onChange={async (e) => {
                const temp = selectProductData.data;
                temp[index].bar_code = e.target.value;
                setSelectProductData({
                  ...selectProductData,
                  data: temp,
                });

                selectProductData.data = temp;

                if (temp[index].oldNew === "old") {
                  temp[index].status = "update";
                  temp[index].statusUpdate = true;

                  await saveProductData();
                }

              }}
            >
              <option value="" disabled>
                -- เลือก --
              </option>

              {selectProductData.data[index].barcode_id.map((item, indexIn) => {
                return (
                  <option value={item}>
                    {selectProductData.data[index].barcode[indexIn]}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </div>
    );

    rowItem["customer_code"] = (
      <div style={{ minWidth: "max-content" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          decimalScale={0}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].supplier_code}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].supplier_code = item.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }

          }}
        />
      </div>
    );

    rowItem["product_name"] = purchaseOrder_data.po_lang === false ? (selectProductData.data[index].product_name_eng
      ? selectProductData.data[index].product_name_eng
      : "-") : (selectProductData.data[index].product_name
        ? selectProductData.data[index].product_name : "-");

    /// product_quantity
    rowItem["retail_price"] = (
      <div style={{ display: "flex", gap: ".5rem", minWidth: "max-content" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          decimalScale={0}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].product_quantity}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].product_quantity = item.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        />
        <select
          type="text"
          className="form-control"
          disabled={isDisabled}
          value={selectProductData.data[index].pq_unit}
          onChange={async (e) => {
            const temp = selectProductData.data;

            temp[index].pq_unit = e.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        >
          <option value="piece">
            {purchaseOrder_data.po_lang === false ? "Piece" : "ชิ้น"}
          </option>
          <option value="pack" hidden={selectProductData.data[index].detailsAdvance?.pc_pack ? false : true} >
            {purchaseOrder_data.po_lang === false ? "Pack" : "แพ็ค"}
          </option>
          <option value="ctn" hidden={selectProductData.data[index].detailsAdvance?.pc_ctn ? false : true}>
            {purchaseOrder_data.po_lang === false ? "Ctn" : "ลัง"}
          </option>
        </select>
      </div>
    );
    ///// product discount 
    rowItem["product_quantity"] = (
      <div style={{ minWidth: "max-content" }}>
        {selectProductData.data[index].discount?.map((item, index2) => (item.unit !== null && item.discount !== null) ? (
          <div key={index2} style={{ display: "flex", gap: ".5rem", minWidth: "max-content" }}>
            <NumberFormat
              type="text"
              className="form-control mb-2"
              decimalScale={0}
              allowNegative={false}
              allowLeadingZeros={true}
              disabled={isDisabled}
              value={item?.discount || ""}
              onChange={async (e) => {
                await handleChangeDiscount(index, index2, "discount", e.target.value);

                if (selectProductData.data[index].oldNew === "old") {
                  selectProductData.data[index].status = "update";
                  selectProductData.data[index].statusUpdate = true;
                  await saveProductData();
                }

                await calSummaryProductTable();
              }}
            />
            <select
              type="text"
              className="form-control"
              disabled={isDisabled}
              value={item?.unit}
              onChange={async (e) => {
                await handleChangeDiscount(index, index2, "unit", e.target.value);

                if (selectProductData.data[index].oldNew === "old") {
                  selectProductData.data[index].status = "update";
                  selectProductData.data[index].statusUpdate = true;
                  await saveProductData();
                }


                await calSummaryProductTable();
              }}
            >
              <option value="" disabled>Unit</option>
              <option value="bath">{symbol.data_name}</option>
              <option value="percent">Percent</option>
            </select>
            {
              <svg
                hidden={isDisabled}

                xmlns="http://www.w3.org/2000/svg"
                width="80" // Adjust the width as needed
                height="38" // Adjust the height as needed
                viewBox="0 0 24 24"
                fill={"red"} // Set fill color to red for enabled, and gray for disabled
                style={{
                  cursor: "pointer",
                  pointerEvents: "auto", // Enable pointer events for enabled, and disable for disabled
                }}
                onClick={async () => {
                  let temp = selectProductData.data
                  temp[index].discount?.splice(index2, 1);

                  setSelectProductData({
                    ...selectProductData,
                    data: temp,
                  });

                  selectProductData.data = temp;

                  if (temp[index].oldNew === "old") {
                    temp[index].status = "update";
                    temp[index].statusUpdate = true;
                    await saveProductData();
                  }
                  await calSummaryProductTable();
                }
                  /* index2 !== 0
                    ? () => handleDeleteRow(index, index2)
                    : undefined */
                } // Conditionally add the click handler
              >
                <path d="M19.07 4.929c-.389-.39-1.024-.39-1.414 0L12 10.586 6.344 4.93c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414L10.586 12l-5.657 5.657c-.39.39-.39 1.024 0 1.414.195.195.45.293.707.293s.512-.098.707-.293L12 13.414l5.657 5.657c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.024 0-1.414L13.414 12l5.657-5.657c.39-.39.39-1.024 0-1.414z" />
              </svg>
            }
          </div>
        )
          :
          ""
        )}
        <button
          type="button"
          style={{ minWidth: "150px" }}
          className="btn btn-block btn-primary"
          disabled={isDisabled}
          onClick={async () => {
            let temp = selectProductData.data;
            let discount = {
              discount: "",
              unit: "bath",
            };
            temp[index]?.discount?.push(discount);

            setSelectProductData({
              ...selectProductData,
              data: temp,
            });
            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        >
          Add
        </button>
      </div>
    );
    ///// product sum prices 
    rowItem["gp"] = (
      <div style={{ minWidth: "max-content" }}>
        <NumberFormat
          type="text"
          className="form-control"
          // required
          thousandSeparator={true}
          decimalScale={2}
          allowNegative={false}
          allowLeadingZeros={true}
          disabled={isDisabled}
          value={selectProductData.data[index].retail_price}
          onChange={async (item) => {
            const temp = selectProductData.data;

            temp[index].retail_price = item.target.value;
            setSelectProductData({
              ...selectProductData,
              data: temp,
            });

            selectProductData.data = temp;

            if (temp[index].oldNew === "old") {
              temp[index].status = "update";
              temp[index].statusUpdate = true;
              await saveProductData();
            }
            await calSummaryProductTable();
          }}
        />
      </div>
    );

    rowItem["management"] = (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a
          key={selectProductData.data[index].product_id}
          id={selectProductData.data[index].product_id}
          className=" btn btn-xs "
          onClick={async () => {
            const tempProductData = productData.data;
            const tempSelectProductData = selectProductData.data;

            const findIndex = tempProductData.findIndex(
              (item) =>
                item.product_id === tempSelectProductData[index].product_id
            );

            if (findIndex >= 0) {
              tempProductData[findIndex].check = false;
              tempProductData[findIndex].status = "del";
            }

            await saveProductData();
          }}
        >
          <i class="fas fa-trash-alt" style={{ color: "red" }}></i>
        </a>
      </div>
    );

    rowsData2.push(rowItem);
  }

  const selectProducTable = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Code",
        field: "product_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Barcode",
        field: "bar_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Supplier Code",
        field: "customer_code",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Name",
        field: "product_name",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Product Quantity",
        field: "retail_price",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Discount",
        field: "product_quantity",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Prices",
        field: "gp",
        // sort: "asc",
        // width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        // width: 50,
      },
    ],
    rows: rowsData2,
  };

  if (isDisabled) {
    const index1 = selectProducTable.columns.findIndex((e) => {
      return e.field === "management";
    });

    selectProducTable.columns.splice(index1, 1);
  }

  async function saveProductData() {
    const findCheckTrue = productData.data.filter((item) => {
      if (item.check === true) {
        return item;
      }
    });
    await filterCurrencyProduct(findCheckTrue);

    const addNew = productData.data.filter((item) => {
      return item.status === "add" && item.oldNew === "new";
    });

    const updateOld = productData.data.filter((item) => {
      return item.status === "update" && item.oldNew === "old";
    });

    const delOld = productData.data.filter((item) => {
      return item.status === "del" && item.oldNew === "old";
    });

    setSelectProductData({
      ...selectProductData,
      data: findCheckTrue,
      newData: addNew,
      updateData: updateOld,
      delData: delOld,
    });

    selectProductData.data = findCheckTrue;
    selectProductData.newData = addNew;
    selectProductData.updateData = updateOld;
    selectProductData.delData = delOld;

    console.log(
      selectProductData.data,
      selectProductData.newData,
      selectProductData.updateData,
      selectProductData.delData
    );
  }

  const filterCurrencyProduct = async (findCheckTrue) => {
    if (findCheckTrue && findCheckTrue.length > 0) {
      const curren = currency.data
      let lemp_curren = [];
      findCheckTrue.forEach((el) => {
        if (el.pd_currency_id) {
          const check = curren?.find(tl => tl.currency_id === el.pd_currency_id)
          if (check) {
            lemp_curren.push(check);
          }
        }
      })

      if (lemp_curren && lemp_curren.length > 0) {
        setCurrency({ data: lemp_curren });
        currency.data = lemp_curren;
      } else {
        let temp_th_curren = {
          "currency_id": "33570a04-ad41-4d93-bf8b-278cf9f29083",
          "currency_name": "THB",
          "currency_is_use": true,
          "currency_is_active": true,
          "currency_oem_id": "ae5d9f3f-321c-48e9-9e78-3c4d644c55a6",
          "currency_company_id": "363e0c07-2c35-4cc2-8fa2-d411914a592c",
          "currency_created_date": "2023-06-13T01:53:21.394Z",
          "currency_updated_date": "2023-06-13T01:53:21.394Z",
          "currency_symbol": "฿"
        }
        lemp_curren.push(temp_th_curren);
        setCurrency({ data: lemp_curren });
        currency.data = lemp_curren;
      }
    }
  }

  async function clearSearch(is_clear) {
    setSearchData({
      ...searchData,
      code: "",
      name: "",
      barcode: "",
      category: "",
      customer_barcode: "",
      customer_product_name: "",
    });

    searchData.code = "";
    searchData.name = "";
    searchData.barcode = "";
    searchData.category = "";
    searchData.customer_barcode = "";
    searchData.customer_product_name = "";

    if (is_clear) {
      await resetProduct(true)
    } else {
      await GetProduct("clear");
    }
  }

  const resetProduct = async (is_use) => {
    const arrTemp = productData.data
    arrTemp.forEach(el => {
      el.is_use = is_use
    });
    setProductData({
      ...productData,
      data: arrTemp
    })
    productData.data = arrTemp;
  }

  async function checkErrorSelectProductData() {
    const error_list = [];
    if (selectProductData.data.length > 0) {
      let number = 1;

      for (let item of selectProductData.data) {
        if (!item.bar_code || item.bar_code?.trim() === "") {
          let temp_err = {
            message: `แถวที่ ${number} กรุณาเลือก Barcode`,
          };

          error_list.push(temp_err);
        }

        if (
          !item.supplier_code ||
          item.supplier_code?.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Supplier Code`,
          };

          error_list.push(temp_err);
        } else {
          item.supplier_code = item.supplier_code?.trim();
        }

        /* if (
          !item.product_name ||
          item.product_name?.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Product Name ภาษาไทย`,
          };

          error_list.push(temp_err);
        } else {
          item.product_name = item.product_name?.trim();
        }

        if (
          !item.product_name_eng ||
          item.product_name_eng?.trim() === ""
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Product Name ภาษาอังกฤษ`,
          };

          error_list.push(temp_err);
        } else {
          item.product_name_eng = item.product_name_eng?.trim();
        } */

        if (item.discount && item.discount.length) {
          for (let item2 of item.discount) {
            if (!item2.discount && item.unit) {
              let temp_err = {
                message: `แถวที่ ${number} กรุณากรอกข้อมูล Discount`,
              };

              error_list.push(temp_err);
            } else {
              item2.discount = typeof item2.discount === "string" ? parseFloat(item2.discount?.replace(/,/g, "")) : item2.discount;
            }
          }
        }

        if (
          !item.retail_price
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Prices`,
          };

          error_list.push(temp_err);
        } else if (item.retail_price === 0) {
          let temp_err = {
            message: `แถวที่ ${number} Prices ต้องไม่เท่ากับ 0`,
          };

          error_list.push(temp_err);
        } else {
          item.retail_price = typeof item.retail_price === "string" ? parseFloat(item.retail_price?.replace(/,/g, "")) : item.retail_price;
        }

        if (
          !item.product_quantity
        ) {
          let temp_err = {
            message: `แถวที่ ${number} กรุณากรอกข้อมูล Product Quantity`,
          };

          error_list.push(temp_err);
        } else if (item.product_quantity === 0) {
          let temp_err = {
            message: `แถวที่ ${number} Product Quantity ต้องไม่เท่ากับ 0`,
          };

          error_list.push(temp_err);
        } else {
          item.product_quantity = typeof item.product_quantity === "string" ? parseFloat(item.product_quantity?.replace(/,/g, "")) : item.product_quantity;
        }

        number += 1;
      }

    } else {
      let temp_err = {
        message: `กรุณาเลือก Product อย่างน้อย 1 รายการก่อนทำรายการถัดไป`,
      };

      error_list.push(temp_err);
    }
    if (purchaseOrder_data.po_total_befor_vat) {
      purchaseOrder_data.po_total_befor_vat = typeof purchaseOrder_data.po_total_befor_vat === "string" ? parseFloat(purchaseOrder_data.po_total_befor_vat?.replace(/,/g, "")) : purchaseOrder_data.po_total_befor_vat || 0;
    }

    if (purchaseOrder_data.po_final_discount) {
      purchaseOrder_data.po_final_discount = typeof purchaseOrder_data.po_final_discount === "string" ? parseFloat(purchaseOrder_data.po_final_discount?.replace(/,/g, "") || 0) : purchaseOrder_data.po_final_discount || 0;
    }

    if (purchaseOrder_data.po_total_befor_vat < purchaseOrder_data.po_final_discount) {
      let temp_err = {
        message: `Discount ต้องไม่มากกว่า SubTotal`,
      };

      error_list.push(temp_err);
    }

    if (error_list.length > 0) {
      /* let temp_err = {
        message: `- Tab Supplier Product -`,
      };
      error_list.unshift(temp_err); */
    }

    setErrorListTab2({
      ...errorListTab2,
      data: error_list,
    });
    errorListTab2.data = error_list;
  }


  return (
    <>
      <div className="row mb-3">
        <div className="col-6 col-md-3 col-xl-2 pb-3">
          <button
            hidden={true}
            data-toggle="modal"
            data-target="#modalProduct"
            ref={modalProductRef}
          />
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDisabled}
            onClick={async () => {
              await openModalProduct();
              await clearSearch(true);
            }}
          >
            Select Product
          </button>
        </div>
        <div className="col-12 col-md-3 col-xl-2">
          <h4 style={{ color: "#007bff", whiteSpace: "nowrap" }}><b>Total : {" "}
            {purchaseOrder_data.po_total_price?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) || 0} Baht</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6 col-md-4 col-xl-3">
          <div class="custom-control custom-switch custom-switch-md">
            <input type="checkbox" class="custom-control-input" id="customSwitch8"
              disabled={isDisabled}
              // value={purchaseOrder_data.po_lang}
              checked={purchaseOrder_data.po_lang}
              onChange={(event) => {
                setPurchaseOrder_data({
                  ...purchaseOrder_data,
                  po_lang: event.target.checked,
                });
                purchaseOrder_data.po_lang = event.target.checked;
                // console.log('event.target', event.target.checked);
              }}
            />
            <label class="custom-control-label" for="customSwitch8">TH</label>
          </div>
        </div>

        <div className="col-12 col-md-4 col-xl-3"
          style={{
            display: "flex",
            height: "auto",
            columnGap: "1rem",
            alignItems: "center",
            fontSize: "1.5rem",
          }}
        >
          <label class="container-checkbox-product" style={{ whiteSpace: "nowrap" }}>Include VAT (7%)
            <input
              disabled={isDisabled}
              className={``}
              type="checkbox"
              id={"sbd_path_document"}
              name={"el.sbd_path_document"}
              /* value={product_data.art_work}
              */
              checked={purchaseOrder_data.po_incl_vat}
              onClick={(e) => {
                setPurchaseOrder_data({
                  ...purchaseOrder_data,
                  po_incl_vat: e.target.checked,
                });
                purchaseOrder_data.po_incl_vat = e.target.checked;
                calSummaryProductTable();
              }}
            />
            <span className="checkmark-checkbox-product"></span>
          </label>
        </div>

        {/* <div className="col-6 col-md-4 col-xl-2 pl-3">
          <div class="form-group">
            <select
              type="text"
              className="form-control"
              disabled={isDisabled}
              required
              value={purchaseOrder_data.po_currency_id}
              onChange={(e) => {
                setPurchaseOrder_data({
                  ...purchaseOrder_data,
                  po_currency_id: e.target.value,
                });
                purchaseOrder_data.po_currency_id = e.target.value;


              }}
            >
              <option key="" value="" disabled>
                Select a currency
              </option>
              {currency.data?.map((option, index) => (
                <option
                  key={option.currency_id}
                  value={option.currency_id}
                >
                  {option.currency_name}
                </option>
              ))}
            </select>
            <label>Currency</label>
          </div>
        </div> */}

      </div>
      <div className="row mb-3">
        <div className="col-md-12" style={{ overflow: "overlay" }}>
          <div
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <MDBDataTable
              sortable={false}
              disableRetreatAfterSorting={MDBT}
              striped
              bordered
              hover
              data={selectProducTable}
            />
          </div>
        </div>
      </div>

      <div className="row res-quotation-flex">
        <div className="col-6 col-md-6 col-xl-6 res-quotation-flex-box1">
          <div className="form-group">
            <textarea
              className="form-control"
              rows={2}
              disabled={isDisabled}
              style={{
                height: "150px",
                fontSize: "25px",
              }}
              value={purchaseOrder_data.po_remark}
              onChange={(e) =>
                setPurchaseOrder_data({
                  ...purchaseOrder_data,
                  po_remark: e.target.value,
                })
              }
              defaultValue=""
              required
            />
            <label>Remark</label>
          </div>
        </div>
        <div className="col-6 col-md-6 col-xl-6 pl-6 d-flex align-items-end flex-column res-quotation-flex-box1">
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>Sub Total</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  // required
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={true}
                  value={purchaseOrder_data.po_total_befor_vat}
                  onChange={async (e) => {
                    let value = parseFloat(e.target.value?.replace(/,/g, ""));
                    setPurchaseOrder_data({
                      ...purchaseOrder_data,
                      po_total_befor_vat: value,
                    });
                    purchaseOrder_data.po_total_befor_vat = value;
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>{symbol.data}</label>
            </div>
          </div>

          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>Discount</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  decimalScale={2}
                  // maxLength={5}
                  allowLeadingZeros={true}
                  allowNegative={false}
                  disabled={isDisabled}
                  className="form-control"
                  required
                  value={purchaseOrder_data.po_final_discount}
                  onChange={async (e) => {
                    let value = parseFloat(e.target.value);
                    // if (value > 100) value = 100;
                    setPurchaseOrder_data({
                      ...purchaseOrder_data,
                      po_final_discount: value,
                    });
                    purchaseOrder_data.po_final_discount = value;
                    await calSummaryProductTable();
                  }}
                />
                <label></label>
              </div>
            </div>
            <div className="">
              <label>{symbol.data}</label>
            </div>
          </div>

          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>VAT</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  // required
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={true}
                  value={purchaseOrder_data.po_vat}
                  onChange={(e) => {
                    setPurchaseOrder_data({
                      ...purchaseOrder_data,
                      po_vat: e.target.value,
                    });
                    purchaseOrder_data.po_vat = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>{symbol.data}</label>
            </div>
          </div>
          <div className="d-flex align-items-center" style={{ gap: ".5rem" }}>
            <div style={{ minWidth: "8rem" }}>
              <label>Grand Total</label>
            </div>
            <div>
              <div class="form-group" style={{ paddingBottom: "0" }}>
                <NumberFormat
                  type="text"
                  className="form-control"
                  // required
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={true}
                  disabled={true}
                  value={purchaseOrder_data.po_total_price}
                  onChange={(e) => {
                    setPurchaseOrder_data({
                      ...purchaseOrder_data,
                      po_total_price: e.target.value,
                    });
                    purchaseOrder_data.po_total_price = e.target.value;
                  }}
                />
                <label></label>
              </div>
            </div>
            <div>
              <label>{symbol.data}</label>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modalProduct"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalProduct"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          style={{ width: "70%" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="modalProduct">
                <b>Add Product</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.code}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          code: e.target.value,
                        });
                        searchData.code = e.target.value;
                      }}
                    />
                    <label>Product Code</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          barcode: e.target.value,
                        });
                        searchData.barcode = e.target.value;
                      }}
                    />
                    <label>Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          name: e.target.value,
                        });
                        searchData.name = e.target.value;
                      }}
                    />
                    <label>Product Name</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <select
                      type="text"
                      className="form-control"
                      required
                      value={searchData.category}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          category: e.target.value,
                        });
                        searchData.category = e.target.value;
                      }}
                    >
                      <option key="" value="">
                        Select a category
                      </option>
                      {categoryData.data.map((option, index) => (
                        <option
                          key={categoryData.data[index].cat_name}
                          value={categoryData.data[index].cat_id}
                        >
                          {categoryData.data[index].cat_name}
                        </option>
                      ))}
                    </select>
                    <label>Category</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_barcode}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_barcode: e.target.value,
                        });
                        searchData.customer_barcode = e.target.value;
                      }}
                    />
                    <label>Customer Barcode</label>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-xl-2">
                  <div class="form-group">
                    <input
                      type="text"
                      className="form-control"
                      required
                      value={searchData.customer_product_name}
                      onChange={(e) => {
                        setSearchData({
                          ...searchData,
                          customer_product_name: e.target.value,
                        });
                        searchData.customer_product_name = e.target.value;
                      }}
                    />
                    <label>Customer Product Name</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await GetProduct("search");
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div className="col-6 col-md-4 col-xl-2">
                  <div className="form-group ">
                    <button
                      type="button"
                      className="btn btn-block btn-info"
                      onClick={async () => {
                        await clearSearch();
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </div>

                <div className="col-md-12" style={{ overflow: "overlay" }}>
                  <div
                    style={{
                      height: "450px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MDBDataTable
                      sortable={false}
                      striped
                      bordered
                      hover
                      disableRetreatAfterSorting={MDBT}
                      data={ProductTable}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                ยกเลิก
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={async () => {
                  await saveProductData();
                }}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PO_Products_Component;
