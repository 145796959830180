import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../../../config";
import { MDBDataTable } from "mdbreact";
import { userdefault_img } from "../../../../routes/imgRoute/imgUrl";
import { MultiSelect } from "react-multi-select-component";
import { getOem, getToken, getUser } from "../../../../Utils/Common";
import "../../../../../src/assets/css/Quotation_CutePlus.css";
import Pick_Product_Admin_Report from "./Component/Pick_Product_Admin_Report";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Select from "react-select";

function Pick_Product_Admin_Table() {
  const [Requisition_Table, setRequisition_Table] = useState([]);
  const [saleDD, setSaleDD] = useState([]);
  const [statusDD, setStatusDD] = useState({
    data: [],
  });
  const [CustomerDD, setCustomerDD] = useState([]);
  const [Filter, setFilter] = useState({
    req_no: "",
    req_cus_id: "",
    req_rs_id: [],
    req_created_name: "",
    req_oem_id: getOem(),
    req_company_id: getUser().com,
  });

  let [status_list_filter, setStatus_list_filter] = useState([]);
  let [company, setCompany] = useState([]);
  let [DataForReport, setDataForReport] = useState({
    data: [],
  });

  const [NameModal, setNameModal] = useState(false);

  let [modalData, setModalData] = useState({
    req_no: "",
    req_status: "",
    req_version: "",
    req_cus_name: "",
    req_cus_contact_name: "",
    req_cus_address: "",
    req_contact_tel: "",
    req_contact_email: "",
    req_remark: "",
    product: [],
  });

  useEffect(async () => {
    await getStatusDD();
    await getCustomerDD();
    await GetRequisition();
  }, []);

  const customFilter = (option, searchText) => {
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };

  async function getModalData(hr_no) {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      hr_no: hr_no,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales + "/api/requisition/admin/GetModalData",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        // console.log("response", response.data);
        await axios({
          method: "post",
          url: Configs.API_URL_cuteplus + "/api/product/admin/GetModalData",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: response.data,
        })
          .then(async (response2) => {
            // console.log("response2", response2.data);
            let cus_address =
              (response2.data.Req.req_cus_house_number
                ? response2.data.Req.req_cus_house_number
                : "") +
              " " +
              (response2.data.Req.req_cus_building
                ? response2.data.Req.req_cus_building
                : "") +
              " " +
              (response2.data.Req.req_swine
                ? response2.data.Req.req_swine
                : "") +
              " " +
              (response2.data.Req.req_soi ? response2.data.Req.req_soi : "") +
              " " +
              (response2.data.Req.req_road ? response2.data.Req.req_road : "") +
              " " +
              (response2.data.Req.req_sub_district
                ? response2.data.Req.req_sub_district
                : "") +
              " " +
              (response2.data.Req.req_district
                ? response2.data.Req.req_district
                : "") +
              " " +
              (response2.data.Req.req_province
                ? response2.data.Req.req_province
                : "") +
              " " +
              (response2.data.Req.req_postcode
                ? response2.data.Req.req_postcode
                : "");
            // console.log("cus_address", cus_address);
            setModalData({
              ...modalData,
              req_no: response2.data.Req.req_no
                ? response2.data.Req.req_no
                : "",
              req_status: response2.data.Req.rs_name
                ? response2.data.Req.rs_name
                : "",
              req_version: response2.data.Req.req_version
                ? response2.data.Req.req_version
                : "",
              req_cus_name: response2.data.Req.req_cus_name
                ? response2.data.Req.req_cus_name
                : "",
              req_cus_contact_name: response2.data.Req.req_contact_name
                ? response2.data.Req.req_contact_name
                : "",
              req_cus_address: cus_address,
              req_contact_tel: response2.data.Req.req_contact_tel
                ? response2.data.Req.req_contact_tel
                : "",
              req_contact_email: response2.data.Req.req_contact_email
                ? response2.data.Req.req_contact_email
                : "",
              req_remark: response2.data.Req.req_remark
                ? response2.data.Req.req_remark
                : "",
              product: response2.data.Req_product,
            });
            modalData.req_no = response2.data.Req.req_no
              ? response2.data.Req.req_no
              : "";
            modalData.req_status = response2.data.Req.rs_name
              ? response2.data.Req.rs_name
              : "";
            modalData.req_version = response2.data.Req.req_version
              ? response2.data.Req.req_version
              : "";
            modalData.req_cus_name = response2.data.Req.req_cus_name;
            modalData.req_cus_contact_name = response2.data.Req.req_contact_name
              ? response2.data.Req.req_contact_name
              : "";
            modalData.req_cus_address = cus_address;
            modalData.req_contact_tel = response2.data.Req.req_contact_tel
              ? response2.data.Req.req_contact_tel
              : "";
            modalData.req_contact_email = response2.data.Req.req_contact_email
              ? response2.data.Req.req_contact_email
              : "";
            modalData.req_remark = response2.data.Req.req_remark
              ? response2.data.Req.req_remark
              : "";
            modalData.product = response2.data.Req_product;
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getCustomerDD() {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
    };
    axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/customer/getCustomerDD",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
      data: temp,
    })
      .then((response) => {
        const cus_dd = response.data?.map(el => ({ label: el.cus_name, value: el.cus_id }));
        cus_dd.unshift({
          label: "--Select--",
          value: ""
        })
        setCustomerDD(cus_dd);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  async function getStatusDD() {
    axios({
      method: "get",
      url:
        Configs.API_URL_cuteplus_sales + "/api/requistion_status/getAllremark1",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const options = [];
        for (let item of response.data) {
          let temp = {
            label: item.rs_name,
            value: item.rs_id,
          };
          options.push(temp);
        }
        setStatusDD({ ...statusDD, data: options });
        statusDD.data = options;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }

  const GetRequisition = async () => {
    const temFilter = {
      req_no: Filter.req_no.trim() === "" ? null : Filter.req_no.trim(),
      req_cus_id: Filter.req_cus_id === "" ? null : Filter.req_cus_id,
      req_rs_id:
        status_list_filter.length === 0 ||
        status_list_filter.length === statusDD.data.length
          ? []
          : status_list_filter,
      req_created_name:
        Filter.req_created_name.trim() === ""
          ? null
          : Filter.req_created_name.trim(),
      req_oem_id: getOem(),
      req_company_id: getUser().com,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/history_requisition/admin/filter",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temFilter,
    })
      .then((response) => {
        setRequisition_Table(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function sendApprove(data, approved) {
    let temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      hr_id: data.hr_id,
      hr_no: data.hr_no,
      status: approved
        ? statusDD.data.find((x) => x.label === "Approve").value
        : statusDD.data.find((x) => x.label === "Not Approve").value,
      approveById: getUser().fup,
      approveByName: getUser().name + " " + getUser().surname,
      is_bool: approved
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/history_requisition/admin/UpdateApprove",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        if (response.data.length > 0 && approved) {
          await saveBill_Delivery(response.data[0]);
          // await saveLogFgOutAutoLot(response.data[0]);
        }
      })
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Update Status Success",
          icon: "success",
        });
        GetRequisition();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  }
  const [dataForSave, setDataForSave] = useState({});
  const saveLogFgOutAutoLot = async (data) => {
    let temp_data_sand_save = {
      lp_lot_no: "",
      lp_id: "",
      lp_product_id: dataForSave.lp_product_id,
      lp_edit_amount: false,
      lp_amount: dataForSave.lp_amount,
      lp_ref_document: dataForSave.lp_ref_document,
      lp_remark: null,
      lp_created_date: new Date(),
      lp_created_by: getUser().fup,
      lp_created_name: getUser().name + " " + getUser().surname,
      lp_company_id: getUser().com,
      lp_oem_id: getOem(),
      product: data,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus + "/api/log_product/saveMutiLogFgOutAutoLot",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp_data_sand_save,
    })
      .then(async (response) => {})
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  const saveBill_Delivery = async (data) => {
    let temp = {
      bill_data: data,
      oem_id: getOem(),
      company_id: getUser().com,
      user_id: getUser().fup,
      user_name: getUser().name + " " + getUser().surname,
    };
    await axios({
      method: "post",
      url: Configs.API_URL_cuteplus + "/api/bill/saveBill_Delivery",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {})
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });
  };

  async function getReport(dataHisReq) {
    //GET COMPANY NAME
    await axios({
      method: "get",
      url: Configs.API_URL + "/company/findById?id=" + getUser().com,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        setCompany(response.data);
        company = response.data;
      })
      .catch(function (error) {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    //GET DATA FOR REPORT
    const temp = {
      oem_id: getOem(),
      company_id: getUser().com,
      hr_id: dataHisReq.hr_id,
    };
    await axios({
      method: "post",
      url:
        Configs.API_URL_cuteplus_sales +
        "/api/history_requisition/admin/GetReportProduct",
      headers: {
        Authorization: "Bearer " + getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        await axios({
          method: "post",
          url:
            Configs.API_URL_cuteplus +
            "/api/product/getProductDetailsForReqAdmin",
          headers: {
            Authorization: "Bearer " + getToken(),
            "X-TTT": Configs.API_TTT,
            "Content-Type": "application/json",
          },
          data: response.data,
        })
          .then(async (response2) => {
            setDataForReport({
              ...DataForReport,
              data: response2.data,
            });
            DataForReport.data = response2.data;
            if (DataForReport.data.HisReqProduct.length > 0) {
              let data = new FormData();
              data.append("company_id", getUser().com);
              data.append("oem_id", getOem());
              data.append("user_id", getUser().fup);
              data.append(
                "products",
                JSON.stringify(
                  DataForReport.data.HisReqProduct !== undefined
                    ? DataForReport.data.HisReqProduct
                    : []
                )
              );

              await axios({
                method: "post",
                url:
                  Configs.API_URL_IMG_cuteplus + "/api/upload/getImageProduct",
                headers: {
                  Authorization: getToken(),
                  "X-TTT": Configs.API_TTT,
                  "Content-Type": "multipart/form-data",
                },
                data: data,
              })
                .then(async (response) => {
                  setDataForReport({
                    ...DataForReport,
                    data: {
                      ...DataForReport.data,
                      HisReqProduct: response.data,
                    },
                  });
                  DataForReport.data.HisReqProduct = response.data;
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Error",
                    text: error.response.data.error.message,
                    icon: "error",
                  });
                });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.response.data.error.message,
              icon: "error",
            });
          });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response.data.error.message,
          icon: "error",
        });
      });

    Pick_Product_Admin_Report(company, DataForReport.data, false);
  }

  const rowsData = [];
  for (let index = 0; index < Requisition_Table.length; index += 1) {
    const rowItem = {};

    rowItem["no"] = <div style={{ textAlign: "center" }}>{index + 1}</div>;

    rowItem["Requisition_no"] = Requisition_Table[index].hr_no ? (
      <u
        style={{ whiteSpace: "pre-line", color: "blue" }}
        onClick={async () => {
          setNameModal(true);
          await getModalData(Requisition_Table[index].hr_no);
        }}
      >
        {typeof Requisition_Table[index].hr_no === "string"
          ? Requisition_Table[index].hr_no
          : "-"}
      </u>
    ) : (
      "-"
    );

    rowItem["customer"] = Requisition_Table[index].hr_cus_name;

    rowItem["sales_name"] = Requisition_Table[index].hr_created_name;

    rowItem["approved_name"] = Requisition_Table[index].hr_approve_user_name ? (
      <span style={{ whiteSpace: "pre-line" }}>
        {typeof Requisition_Table[index].hr_approve_user_name === "string"
          ? Requisition_Table[index].hr_approve_user_name
          : "-"}
      </span>
    ) : (
      "-"
    );
    rowItem["status"] = (
      <div>
        <button
          type="button"
          style={{
            opacity: "1",
            width: "100%",
            border: "4px solid",
            borderRadius: "10px",
            paddingTop: ".2rem",
            paddingBottom: ".2rem",
          }}
          className={`btn btn-outline-${
            Requisition_Table[index].rs_name === "Approve"
              ? "primary"
              : Requisition_Table[index].rs_name === "Not Approve"
              ? "danger"
              : "warning"
          } disabled`}
        >
          {Requisition_Table[index].rs_name}
        </button>
      </div>
    );

    rowItem["report"] = (
      <>
        <div>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              Swal.fire({
                title: "คุณต้องการจะออก Report นี้ใช่หรือไม่",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `ใช่`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: `ไม่`,
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await getReport(Requisition_Table[index]);
                }
              });
            }}
          >
            Report
          </button>
        </div>
      </>
    );

    rowItem["management"] = (
      <div className="row" style={{ flexWrap: "nowrap", textAlign: "center" }}>
        <div className="col-xl-6 col-md-6 col-xs-6 ">
          <button
            type="button"
            disabled={
              Requisition_Table[index].rs_name === "Pending" ? false : true
            }
            style={{
              borderBlockColor: "#97DAC3",
              color: "#000000",
              borderColor: "#97DAC3",
              backgroundColor: "#97DAC3",
            }}
            class="btn btn-block"
            onClick={sendApprove.bind(this, Requisition_Table[index], true)}
          >
            Approve
          </button>
        </div>
        <div
          className="col-xl-6 col-md-6 col-xs-6 " /* style={{ visibility: perchase_po[index].statusAll.editBtn ? "hidden" : "visible" }} */
        >
          <button
            type="button"
            disabled={
              Requisition_Table[index].rs_name === "Pending" ? false : true
            }
            style={{
              borderBlockColor: "#FFFF00",
              color: "#000000",
              borderColor: "#FFFF00",
              backgroundColor: "#FFFF00",
            }}
            class="btn btn-block"
            onClick={sendApprove.bind(this, Requisition_Table[index], false)}
          >
            Not Approve
          </button>
        </div>
      </div>
    );

    rowsData.push(rowItem);
  }

  const Requisition_data = {
    columns: [
      {
        label: "No.",
        field: "no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Requisition No.",
        field: "Requisition_no",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Customer",
        field: "customer",
        // sort: "asc",
        width: 50,
      },
      /* {
        label: "Price",
        field: "price",
        // sort: "asc",
        width: 50,
      }, */
      {
        label: "Sales Name",
        field: "sales_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Approved Name",
        field: "approved_name",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Status",
        field: "status",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Report",
        field: "report",
        // sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "management",
        // sort: "asc",
        width: 50,
      },
    ],

    rows: rowsData,
  };

  async function clear() {
    setStatus_list_filter([]);
    status_list_filter = [];
    setFilter({
      ...Filter,
      req_no: "",
      req_cus_id: "",
      req_rs_id: [],
      req_created_name: "",
    });
    Filter.req_no = "";
    Filter.req_cus_id = "";
    Filter.req_rs_id = [];
    Filter.req_created_name = "";
    await GetRequisition();
  }

  return (
    <div className="wrapper">
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-4 col-md-4 col-xl-6">
                <h1>เบิกสินค้า (Admin)</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/Welcome">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Sale</li>
                  <li className="breadcrumb-item active">เบิกสินค้า (Admin)</li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.req_no}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        req_no: e.target.value,
                      });
                      Filter.req_no = e.target.value;
                    }}
                  />
                  <label>Requisition No.</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <Select
                    styles={{
                      control: base => ({
                        ...base,
                        height: 35,
                        minHeight: 39,
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 999,
                      }),
                    }}
                    options={CustomerDD}
                    filterOption={customFilter}
                    required
                    value={
                      CustomerDD?.find(el => el.value === Filter.req_cus_id) || { label: "--Select--", value: "" }
                    }
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        req_cus_id: e.value,
                      });
                      Filter.req_cus_id = e.value;
                    }}
                  >
                    {/* <option value="">--Select--</option>
                    {CustomerDD.map((data) => {
                      return (
                        <option value={data.cus_id}>{data.cus_name}</option>
                      );
                    })} */}
                  </Select>
                  <label>Customer</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                <div class="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={Filter.req_created_name}
                    onChange={(e) => {
                      setFilter({
                        ...Filter,
                        req_created_name: e.target.value,
                      });
                      Filter.req_created_name = e.target.value;
                    }}
                  />
                  <label>Sale Name</label>
                </div>
              </div>
              <div className="col-12 col-md-4 col-xl-2">
                {/* <label></label> */}
                <div class="" style={{ position: "relative" }}>
                  <MultiSelect
                    className="mt-2"
                    hasSelectAll={false}
                    value={status_list_filter}
                    options={statusDD.data}
                    onChange={setStatus_list_filter}
                    labelledBy="Select"
                  />
                  <label
                    style={{
                      position: "absolute",
                      top: "-22px",
                      color: "#004fa4",
                    }}
                  >
                    Status
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => {
                      GetRequisition();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={async () => {
                      await clear();
                    }}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-6 col-md-4 col-xl-2">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={() => {
                      window.location.href =
                        "/Sale_cuteplut/เบิกสินค้า_sale/Add";
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        <section className="content-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4>เบิกสินค้า (Admin)</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="row">
                        <div className="col-12 col-md-12 col-xl-12">
                          <MDBDataTable
                            sortable={false}
                            className="table table-head-fixed"
                            striped
                            bordered
                            hover
                            fixedHeader
                            disableRetreatAfterSorting={true}
                            data={Requisition_data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        size="xl"
        className="Modal_Center_LeftRight"
        isOpen={NameModal}
        toggle={() => {
          setNameModal(false);
          setModalData({
            ...modalData,
            req_no: "",
            req_status: "",
            req_version: "",
            req_cus_name: "",
            req_cus_contact_name: "",
            req_cus_address: "",
            req_contact_tel: "",
            req_contact_email: "",
            req_remark: "",
            product: [],
          });
          modalData.req_no = "";
          modalData.req_status = "";
          modalData.req_version = "";
          modalData.req_cus_name = "";
          modalData.req_cus_contact_name = "";
          modalData.req_cus_address = "";
          modalData.req_contact_tel = "";
          modalData.req_contact_email = "";
          modalData.req_remark = "";
          modalData.product = [];
        }}
      >
        <ModalHeader>
          <h4 className="modal-title" id="modalContrat">
            <b style={{ paddingLeft: "1rem" }}>รายละเอียดใบเบิกสินค้า</b>
          </h4>
        </ModalHeader>

        <ModalBody>
          <div style={{ paddingLeft: "2rem", paddingRight: "2rem" }}>
            <div className="row">
              <div className="col-12">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Requisition No : {modalData.req_no}
                  {/* Requisition No : {"TEST"} */}
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-xl-6">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Status : {modalData.req_status}
                  {/* Status : {"TEST"} */}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-xl-6">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Version : {modalData.req_version}
                  {/* Version : {"TEST"} */}
                </h4>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Customer Name : {modalData.req_cus_name}
                  {/* Customer Name : {"TEST"} */}
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Customer Contact point name : {modalData.req_cus_contact_name}
                  {/* Customer Contact point name : {"TEST"} */}
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Customer Address : {modalData.req_cus_address}
                  {/* Customer Address : {"TEST"} */}
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-5 col-xl-3">
                <h4 style={{ flexWrap: "nowrap", whiteSpace: "nowrap" }}>
                  Contact Tel : {modalData.req_contact_tel}
                  {/* Contact Tel : {"TEST"} */}
                </h4>
              </div>
              <div className="col-12 col-md-7 col-xl-6">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Contact E-mail : {modalData.req_contact_email}
                  {/* Contact E-mail : {"TEST"} */}
                </h4>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h4 style={{ flexWrap: "nowrap" }}>
                  Remark : {modalData.Remark}
                  {/* Remark : {"TEST"} */}
                </h4>
              </div>
            </div>

            <>
              {/* {modalData.product.length !== 0 ? ( */}
              <div className="row">
                <div className="col-12 col-md-12 col-xl-12">
                  <div
                    className="table-responsive"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <Table striped bordered hover size="lg">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Product Code</th>
                          <th>Product Name</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <td>{"1"}</td>
                          <td>{"2"}</td>
                          <td>{"3"}</td>
                          <td>{"4"}</td> */}
                        {modalData.product.map((rowData, index) => (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td style={{ textAlign: "center" }}>
                              {modalData.product[index].product_code !== null
                                ? modalData.product[index].product_code
                                : "-"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {modalData.product[index].rp_product_name !== null
                                ? modalData.product[index].rp_product_name
                                : "-"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {modalData.product[index].rp_quantity !== null
                                ? modalData.product[index].rp_quantity
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
              {/* ) : ""} */}
            </>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              setNameModal(false);
            }}
            style={{ width: "100px" }}
          >
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Pick_Product_Admin_Table;
