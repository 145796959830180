import React, { Component, useEffect, useState } from "react";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import Swal from "sweetalert2";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, fixOEMForSST,getAcademy } from "../../Utils/Common";
import { DistinctDataArray } from "../../Utils/Common";
import { Switch } from "react-router";
import moment from "moment";
function Logistic(params) {
  const [logistic, setLogistic] = useState([]);
  const [logistic_type, setlogistic_type] = useState([]);
  const [is_dtc, setis_dtc] = useState({ data: true });
  const [is_nodtc, setis_nodtc] = useState({ data: false });
  const [dtc_logistic, setDtc_logistic] = useState([]);
  const [dtc_logistic_status, setDtc_logistic_status] = useState([]);
  const [companyGroup, setCompanyGroup] = useState([]);
  const [currentOEM, setCurrentOEM] = useState({ label: "", value: "" });
  const [allOEM, setallOEM] = useState([]);
  /* console.log(getUser().com); */
  const [filler_logistic, setFiller_logistic] = useState({
    plate_number: "",
    company_id: getUser().com,
    oem_id: getOem(),
    setLogisticGroupTypeDTC: "DTC",
  });

  const GetCompanyGroup = async () => {
    let temp = { company_id: getUser().com };

    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/allOemByCompany",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        setCompanyGroup(response.data);

        response.data.forEach((element) => {
          if (element.id === getOem()) {
            setCurrentOEM({ label: element.name, value: element.id });
          }
         
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetAllOEM = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/company/findOEMByCompanyId?id="+ getUser().com,
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setallOEM(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const GetLogistic_type = async () => {
    axios({
      method: "get",
      url: Configs.API_URL + "/logistic/logistic_type",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        //console.log(response.data);
        setlogistic_type(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const GetDTCData_car = async (e) => {
    var allDtcCar = [];
    var _allDtcCar = [];
    var allDtcGps = [];
    var allDtcGroup = [];
    let status_error = {
      getVehicleMaster: false,
      getGroupMaster: false,
      getRealtimeData: false,
    };
    var data_body = {
      api_token_key: Configs.DTC_TOKEN,
    };

    axios({
      method: "post",
      url: Configs.DTC_HOST + "/getVehicleMaster",
      headers: {
        "Content-Type": "application/json",
      },
      data: data_body,
    })
      .then(function (response) {
        //console.log(response.data);
        let _temp = [];

        response.data.data.forEach((element) => {
          let _element = {};
          _element["type"] = element.vehicle_name.split("/")[1] || "ไม่ระบุ";
          _temp.push(_element);
          allDtcCar.push(element);
          _allDtcCar.push(element.gps_id);
        });

        let __temp = DistinctDataArray(_temp, "type");

        UpdateLogisticType(__temp);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "สถานะข้อผิดพลาด : ระบบ DTC รองรับการอัปเดตข้อมูลได้ทุก 1 นาที จะไม่สามารถอัปเดตข้อมูลได้อย่างต่อเนื่อง",
          "ช้อความจากระบบ DTC",
          "error"
        );
      });

    axios({
      method: "post",
      url: Configs.DTC_HOST + "/getGroupMaster",
      headers: {
        "Content-Type": "application/json",
      },
      data: data_body,
    })
      .then(function (response) {
        response.data.data.forEach((element) => {
          allDtcGroup.push(element);
        });
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "สถานะข้อผิดพลาด : ระบบ DTC รองรับการอัปเดตข้อมูลได้ทุก 1 นาที จะไม่สามารถอัปเดตข้อมูลได้อย่างต่อเนื่อง",
          "ช้อความจากระบบ DTC",
          "error"
        );
      });

    var _data_body = {
      api_token_key: Configs.DTC_TOKEN,
      gps_list: _allDtcCar,
    };

    axios({
      method: "post",
      url: Configs.DTC_HOST + "/getRealtimeData",
      headers: {
        "Content-Type": "application/json",
      },
      data: _data_body,
    })
      .then(function (response) {
        let elementData = [];
        setDtc_logistic_status(response.data.data);
      /*   console.log("data Status", response.data.data); */
        allDtcCar.forEach((_element) => {
          response.data.data.forEach((element) => {
            let _elementData = {};
            if (_element.gps_id === element.gps_id) {
              _elementData["gps_id"] = _element.gps_id;
              _elementData["vehicle_name"] = _element.vehicle_name;
              _elementData["license_plate"] = _element.license_plate;
              _elementData["sim_no"] = _element.sim_no;
              _elementData["status_name_th"] = element.status_name_th;
              _elementData["mileage"] = element.mileage;
              _elementData["company_id"] = getUser().com;
              _elementData["group_id"] = "None";
              /*    _elementData["group_name"] = "None"; */
              for (let index = 0; index < allDtcGroup.length; index++) {
                allDtcGroup[index].device_list.forEach((_element_) => {
                  if (_element.gps_id === _element_) {

                 
                       if (allDtcGroup[index].group_name === "ST Civil") {
                      var oem_name =   allOEM.filter((el)=>{
                          return el.name ==="บจก.เอสทีซีวิล(2001)";
                        });

                        /* console.log("oem_name",oem_name);
 */
                      _elementData["group_name"] = oem_name[0].name;
                      _elementData["group_id"] = oem_name[0].id;
                    } else if (allDtcGroup[index].group_name === "STP") {
                      var oem_name2 =   allOEM.filter((el)=>{
                        return el.name ==="บจก.เอสทีสตีล(2001) ปัตตานี";
                      });
                      _elementData["group_name"] =oem_name2[0].name;
                      _elementData["group_id"] = oem_name2[0].id;
                    } else if (allDtcGroup[index].group_name === "STS") {
                      var oem_name3 =   allOEM.filter((el)=>{
                        return el.name ==="บจก.เอสทีสตีล(2001)";
                      });
                      _elementData["group_name"] = oem_name3[0].name;
                      _elementData["group_id"] = oem_name3[0].id;
                    } else if (allDtcGroup[index].group_name === "SST") {
                      var oem_name4 =   allOEM.filter((el)=>{
                        return el.name ==="บจก.สตูลค้าเหล็ก";
                      });
                      _elementData["group_name"] = oem_name4[0].name;
                      _elementData["group_id"] = oem_name4[0].id;
                    } else if (allDtcGroup[index].group_name === "STC") {
                      var oem_name5 =   allOEM.filter((el)=>{
                        return el.name ==="บจก.เอสที คอนกรีตโปรดักส์";
                      });
                      _elementData["group_name"] = oem_name5[0].name;
                      _elementData["group_id"] = oem_name5[0].id;
                    } else {
                      _elementData["group_name"] = "None";
                      _elementData["group_id"] = "None";
                    }
                  }
                });
              }

              elementData.push(_elementData);
            }
          });
        });
         // console.log(elementData);
        UpdateLogisticCar(elementData);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire(
          "สถานะข้อผิดพลาด : ระบบ DTC รองรับการอัปเดตข้อมูลได้ทุก 1 นาที จะไม่สามารถอัปเดตข้อมูลได้อย่างต่อเนื่อง",
          "ช้อความจากระบบ DTC",
          "error"
        );
      });

    /*  axios({
        method: 'post',
        url: Configs.DTC_HOST+'/getRealtimeData',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : _data_body
      })
      .then(function (response) {
     
        response.data.data.forEach(element => {
          allDtcGps.push(element);
        });
      
      })
      .catch(function (error) {
        console.log(error);
      });

        */

    /* axios({
        method: 'post',
        url: Configs.DTC_HOST+'/getGroupMaster',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data_body
      })
      .then(function (response) {
    
      axios({
        method: "post",
        url: Configs.API_URL + "/logistic/upDateFromDTC_Type",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data:  response.data.data
      })
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });


      })
      .catch(function (error) {
        console.log(error);
      }); */
    /* 
      var updateLogistic = {
        allDtcCar:allDtcCar,
        allDtcGps:allDtcGps,
        allDtcType:allDtcType
      }*/
    /*   console.log("update",allDtcType);  */
    /* axios({
        method: "post",
        url: Configs.API_URL + "/logistic/upDateFromDTC_Type",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data:allDtcType
      })
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        }); */
  };

  function UpdateLogisticCar(params) {
  //  console.log("params",params);
 

    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/UpdateFromDTC_Car",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then(function (response) {
        let count_time = response.data.length * 50;
        let timerInterval;
        Swal.fire({
          title: "Auto close alert!",
          html: "I will close in <b></b> milliseconds.",
          timer: count_time,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
         
          if (result.dismiss === Swal.DismissReason.timer) {
          //  console.log("I was closed by the timer");
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function UpdateLogisticType(params) {
    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/upDateFromDTC_Type",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then(function (response) {
        /*      console.log(response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  
  useEffect(() => {
    GetLogistic("all");
    GetCompanyGroup();
    GetAllOEM();
    /*  GetLogistic_type(); */
    /*   GetDTCData_car(); */
  }, []);
  const GetLogistic = async (e) => {
    let logist = "DTC";

    if (is_dtc.data === true && is_nodtc.data === false) {
      logist = "DTC";
    } else if (is_dtc.data === false && is_nodtc.data === true) {
      logist = "รถภายใน";
    } else {
      logist = "all";
    }
/*     console.log("filter", logist); */

    let temp__ = {
      plate_number: filler_logistic.plate_number,
      company_id: filler_logistic.company_id,
      oem_id: filler_logistic.oem_id,
      setLogisticGroupTypeDTC: logist,
    };

    axios({
      method: "post",
      url: Configs.API_URL + "/logistic/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp__,
    })
      .then(function (response) {
      /*   console.log("logistic", response.data); */
        setLogistic(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });


  };


  const clearFilter = async () => {
    await setFiller_logistic({
      plate_number: "",
      company_id: getUser().com,
      oem_id: getOem(),
      setLogisticGroupTypeDTC: "DTC",
    });

    setis_dtc({ data: true });
    setis_nodtc({ data: false });

    is_dtc.data = true;
    is_nodtc.data = false;

    filler_logistic.plate_number = "";
    filler_logistic.oem_id = getOem();
    filler_logistic.company_id = getUser().com;
    filler_logistic.setLogisticGroupTypeDTC = "DTC";
    setLogistic([]);
  
    GetLogistic("all");
    GetCompanyGroup();
  
  };
  function UpdateFromDTC(params) {
    GetLogistic_type();
    GetDTCData_car();
    GetCompanyGroup();

   
    Swal.fire({
      icon: "success",
      title: "Update Complete!",
      showConfirmButton: false,
      timer: 1500,
    }).then((result) => { GetLogistic("all")});
  }
  function addpage(params) {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Logistic/add";
  }

  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/logistic/logistic_car_del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Logistic";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const rowsData = [];

  for (var index = 0; index < logistic.length; index++) {
    const rowItem = {};
    rowItem["no"] = index + 1;
    rowItem["logistic_name"] = logistic[index].car_name;
    rowItem["logistic_type"] =
     /*  logistic[index].car_name.split("/")[1] || "ไม่ระบุ"; */
     logistic[index].car_type_name || "ไม่ระบุ";
    /*     rowItem["logistic_group"] = logistic[index].car_type_name; */
    rowItem["car_plate"] = logistic[index].plate_number;
    if (dtc_logistic_status.length > 0) {
      for (
        let index_status = 0;
        index_status < dtc_logistic_status.length;
        index_status++
      ) {
        if (
          logistic[index].box_number ===
          dtc_logistic_status[index_status].gps_id
        ) {
          rowItem["status"] = dtc_logistic_status[index_status].status_name_th;
        }
      }
    } else {
      rowItem["status"] =
        logistic[index].logistic_group_name === "DTC"
          ? "กรุณาอัปเดต จาก DTC"
          : "รถภายใน";
    }

    rowItem["mile_rage"] = logistic[index].mileage || "ไม่ระบุ";
    rowItem["group"] = logistic[index].logistic_group_name;
    rowItem["sim"] = logistic[index].sim_number;
    rowItem["kpi"] = logistic[index].kpi || "ไม่ระบุ";
    rowItem["last_update"] =
      logistic[index].update_date === null
        ? "ไม่อัปเดต"
        : moment(logistic[index].update_date).format("DD-MM-yyyy HH:mm");

    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4 col-md-4 col-xl-4" style={{"padding":"5px"}}>
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Logistic/read/" +
              logistic[index].id
            }
            key={logistic[index].id}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>

        {logistic[index].oem_id === currentOEM.value ? (
          <>
            <div className="col-4 col-md-4 col-xl-4" style={{"padding":"5px"}}>
              <a
                href={
                  "/Warehouse_Management/Factory_Master_Data/Logistic/edit/" +
                  logistic[index].id
                }
                key={logistic[index].id}
                className=" btn btn-xs "
              >
                {" "}
                <i class="fas fa-pencil-alt" ></i>
              </a>
            </div>
            {
           <div className="col-4 col-md-4 col-xl-4" style={{"padding":"5px"}}>
             <a
               key={logistic[index].id}
               className=" btn btn-xs "
               onClick={deldata.bind(this, logistic[index].id)}
             >
               <i class="fas fa-trash-alt"></i>
             </a>
           </div>
            }
           
          </>
        ) : (
          <></>
        )}
      </div>
    );
    rowsData.push(rowItem);
  }

  const data_import = {
    columns: [
      {
        label: "ลำดับ",
        field: "no",
        sort: "asc",
        width: 50,
      },
      {
        label: "ชื่อ",
        field: "logistic_name",
        sort: "asc",
        width: 50,
      },
      {
        label: "ประเภทรถ",
        field: "logistic_type",
        sort: "asc",
        width: 50,
      },
      {
        label: "ทะเบียน",
        field: "car_plate",
        sort: "asc",
        width: 50,
      },
      {
        label: "สถานะ",
        field: "status",
        sort: "asc",
        width: 50,
      },
      {
        label: "เลขไมล์ปัจจุบัน",
        field: "mile_rage",
        sort: "asc",
        width: 50,
      },
      {
        label: "กลุ่มรถ",
        field: "group",
        sort: "asc",
        width: 50,
      },
      {
        label: "เบอร์ซิม",
        field: "sim",
        sort: "asc",
        width: 50,
      },
      {
        label: "KPI",
        field: "kpi",
        sort: "asc",
        width: 50,
      },
      {
        label: "Management",
        field: "mgt",
        sort: "asc",
        width: 50,
      },
      {
        label: "อัปเดตล่าสุด",
        field: "last_update",
        sort: "asc",
        width: 50,
      },
    ],
    rows: rowsData,
  };

  /* 
  function setLOis_nodtc(e) {
    setis_nodtc({...is_nodtc,data:e});
    is_nodtc.data = e;
    if (e===true) {
      GetLogistic("DTC");
    }else if(is_dtc.data===true){
      GetLogistic("รถภายใน");
    }else{
      GetLogistic("all");
    }
  }

  function setLOis_dtc(e) {
    setis_dtc({...is_dtc,data:e});
    is_dtc.data = e;
    if (e===true) {
       GetLogistic("รถภายใน");
    }

    else if(is_nodtc.data===true){
      GetLogistic("DTC");
    }else{
      GetLogistic("all");
    }
   
  } */
  /*   function  click(params) {
    GetLogistic('all');
  }
 */

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="logistic" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Logistic{"  "}<a target="_blank"  href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Logistic</li>
                </ol>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2  col-lg-2  col-xs-2 ">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_logistic.plate_number.trim()}
                    onChange={(e) => {
                      setFiller_logistic({
                        ...filler_logistic,
                        plate_number: e.target.value.trim(),
                      });
                    }}
                  />
                  <label htmlFor="">เลขทะเบียน</label>{" "}
                </div>
              </div>

              <div className="col-md-2  col-lg-2  col-xs-2 ">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filler_logistic.oem_id}
                    onChange={(e) => {
                      setFiller_logistic({
                        ...filler_logistic,
                        oem_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">ทั้งหมด</option>

                    {companyGroup.map((el) => {
                      return (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="">กลุ่มบริษัท</label>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      GetLogistic();
                     
                    }}
                  >
                    search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearFilter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-1  col-lg-1  col-xs-1">
                <input
                  type="checkbox"
                  id="fromDTC"
                  checked={is_dtc.data}
                  onChange={(e) => {
                    setis_dtc({ ...is_dtc, data: e.currentTarget.checked });
                    is_dtc.data = e.currentTarget.checked;
                    GetLogistic();
                  }}
                />
                <label for="fromDTC">&nbsp;DTC</label>
                {/* </div> */}
              </div>

              <div className="col-md-2  col-lg-2  col-xs-2 ">
                <input
                  type="checkbox"
                  id="NotDTC"
                  checked={is_nodtc.data}
                  onChange={(e) => {
                    setis_nodtc({ ...is_nodtc, data: e.currentTarget.checked });
                    is_nodtc.data = e.currentTarget.checked;
                    GetLogistic();
                  }}
                />
                <label for="NotDTC">&nbsp;รถภายใน</label>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
            <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="col-6 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={()=>{UpdateFromDTC();}}
                  >
                    Update from DTC
                  </button>
                </div>
              </div>
{/* 
              <div className="col-md-2  col-lg-2  col-xs-2 ">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    data-toggle="modal"
                    data-target="#modal-import"
                  >
                    Import
                  </button>
                </div>
              </div> */}

             {/*  <div className="col-md-2  col-lg-2  col-xs-2 ">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    
                  >
                    Download template
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Logistic</h3>
              <div className="card-tools"></div>
            </div>

            <div
              className="card-body table-responsive  "
              style={{ whiteSpace: "nowrap" }}
            >
              <MDBDataTable
                className="table table-head-fixed"
                sortable = {false}
                striped
                bordered
                hover
                fixedHeader
                // data={data}
                data={data_import}
              />
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>

        <div className="modal fade" id="modal-import">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Import {">>>"} Logistic</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  //onClick={set_bomlist_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group ">
                      <select
                        className="custom-select select2 form-control"
                        // value={fillter.month}
                        // onChange={(e)=>{setfillter({...fillter,month:e.target.value})}}
                      >
                        <option value="">Select Month</option>
                        <option value="รถ">รถ</option>
                        <option value="คนขับ">คนขับ</option>
                      </select>
                      <label htmlFor="">ประเภท</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 col-lg-4 col-sm-4 ">
                    <div className="none-nolmalinput">
                      <label htmlFor="">External file:</label>
                      <small style={{ color: "red" }}>
                        ** Excel from template **
                      </small>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            /* disabled={} */
                            className="custom-file-input"
                            id="exampleInputFile"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            //onChange={upload_file}
                            multiple
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            {/*   {excel.name !== null &&
                          excel.name !== "" &&
                          excel.name !== undefined
                            ? excel.name
                            : "Select File"} */}
                          </label>
                        </div>
                      </div>

                      <div className="col 4 "></div>
                    </div>
                  </div>
                </div>

                <div
                  className="card-body table-responsive " /*  style={{ height: "100%" }} */
                >
                  <MDBDataTable
                    className="table table-head-fixed"
                    sortable = {false}
                    striped
                    bordered
                    hover
                    fixedHeader
                    data={data_import}
                  />
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* /.content */}
      </div>
      {/*      <Footter/> */}
    </div>
  );
}

export default Logistic;
