import React, { Component, useEffect, useState,useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import axios from "axios";
import { getOem, getToken, getUser,getAcademy } from "../../Utils/Common";
import Configs from "../../config";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Sidebar from "../../component/Sidebar";
import Select from "react-select";
let date = new Date();
let dateformatYear = date.getFullYear();
let dateformatMonth = date.getMonth()+1;
function Mat_Approve(params) {
  const [material, setmaterial] = useState([]);
  const [osl,setosl] = useState([]);
  const [fillter, setfillter] = useState({
    no: "",
    name: "",
    year: dateformatYear,
    month: dateformatMonth,
    material_type_id: "",
    material_spec_id: "",
    material_size_id: "",
    material_supplier_id: "",
    oem_id: getOem(),
  });
  const [filter, setfilter] = useState({
    osl_id:"",
    oem_id: getOem(),
  });
  const [test, settest] = useState({
    test: "",
  });
  const [MDBT,setMDBT] =useState(true);
  
  const [GrandTotal,setGrandTotal] =useState({
    total:0,
  });
  const GetOSL = async () => {
    let temp = {
      osl_no:"",
      oem_id:getOem(),
    }
    axios({
      method: "post",
      url: Configs.API_URL + "/orderSummary/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp
    })
      .then(function (response) {
        console.log(response.data);
        setosl(response.data);
     
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  

  useEffect(() => {

    GetOSL();
  }, []);

  
 
 function approve_material() {
  
  if (rowsData.length > 0) {

  
     for(let item of material){
       if(!item.next_order){
         item.next_order = item.nms;
       }
     }
     var data = material.filter((e)=>{ return e.next_order > 0});
    var temp = {
      material: data,
      oem_id: getOem(),
    }
    console.log(material);
    axios({
      method: 'post',
      url:Configs.API_URL+ '/materialPlaning/updateMaterialForcast',
      headers: { 
        'Authorization': getToken(),
        'Content-Type': 'application/json', 
        'X-TTT': Configs.API_TTT
      },
      data : temp,
    })
    .then(function (response) {
      console.log(response.data);

      Swal.fire({
        title: "Saving",
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 2000,
        onOpen: () => {
          Swal.showLoading();
        },
      }).then((result) => {

        Swal.fire({
          icon: "success",
          title: "Save",
          showConfirmButton: false,
          timer: 1500,
        })

      });
    })
    .catch(function (error) {
      console.log(error);
    });

     
  } else {
    Swal.fire("Warning", "Please Select OSL and Load Suggression", "warning");
  }
}
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const rowsData = [];
for (var index = 0; index < material.length; index++) {
  const rowItem = {};
  rowItem["no"] = index + 1;
  rowItem["material_id"] = material[index].material_id;
  rowItem["mat_code"] =material[index].material_no;
  rowItem["mat_name"] =material[index].material_name;
  rowItem["in_hand"] = numberWithCommas(material[index].inhand || 0);
  rowItem["buy_price"] = numberWithCommas(material[index].buy_price || 0);
  rowItem["min_stock"] = numberWithCommas(material[index].min_lot || 0);
  rowItem["amount"] = numberWithCommas(material[index].amount || 0);
  var sumstock = ((material[index].inhand || 0)-(material[index].min_lot || 0));
  if(sumstock < 0){
    sumstock = 0;
  }
  var nms = (material[index].amount || 0) - sumstock;
  var addtostock = 0;
  if((material[index].inhand || 0) < (material[index].min_lot || 0)){
    addtostock = (material[index].min_lot || 0) - (material[index].inhand || 0);
    
  }
  if(nms < 0){
    nms =0;
  }
  //console.log("nms",nms)
  nms = parseInt(nms)+parseInt(addtostock);
/*   console.log("addtostock",addtostock)
  console.log("nms2",nms) */
  rowItem["nms"] = numberWithCommas(nms);
 
  material[index].total = ((material[index].next_order ? material[index].next_order : nms)*material[index].buy_price);
  
  rowItem["next_order"]=(
  <NumberFormat
    className="form-control"
    required
    id ={index} 
    thousandSeparator={true}
    value={material[index].next_order ? material[index].next_order : nms}
    allowNegative ={false}
    onChange={approve_update.bind(this)}
    //disabled={addRoedit}
    /* onChange={(e) => {
      setmaterailData({
        ...materailData,
        weight_piece: e.target.value,
      });
    }} */
  />
 
);
  rowItem["total"] = numberWithCommas((material[index].next_order ? material[index].next_order : nms)*material[index].buy_price);
  GrandTotal.total=GrandTotal.total+((material[index].next_order ? material[index].next_order : nms)*material[index].buy_price);
  material[index].nms = nms;

  rowsData.push(rowItem);
}

var grand_total =0;
 const data = {
   columns: [
     {
       label: "NO.",
       field: "no",
       sort: "asc",
       width: 50,
     },
     {
       label: "Mat_Code",
       field: "mat_code",
       sort: "asc",
       width: 50,
     },
     {
      label: "Mat_Name",
      field: "mat_name",
      sort: "asc",
      width: 50,
    },
     {
       label: "In Hand",
       field: "in_hand",
       sort: "asc",
       width: 50,
     },
     {
       label: "Buy Price",
       field: "buy_price",
       sort: "asc",
       width: 50,
     },
     {
      label: "Min Stock",
      field: "min_stock",
      sort: "asc",
      width: 50,
    },
     {
      label: "ReqOrder",
      field: "amount",
      sort: "asc",
      width: 50,
    },
     {
       label: "Next_Month_Suggestion",
       field: "nms",
       sort: "asc",
       width: 50,
     },
     {
       label: "Next_Order",
       field: "next_order",
       sort: "asc",
       width: 50,
     },
     {
       label: "Total_Price",
       field: "total",
       sort: "asc",
       width: 50,
     },
   ],
   rows: rowsData,
 };


 function approve_update (e) {
 

  var temp_mat = material;
  setmaterial([]);
//console.log("berfore ", temp_mat[e.target.id]);
temp_mat[e.target.id].next_order = Number(e.target.value.replace(/,/g, ""));
//console.log("after ", temp_mat[e.target.id]);
var total = temp_mat[e.target.id].buy_price * Number(e.target.value.replace(/,/g, ""));
//console.log("total ", total);
temp_mat[e.target.id].total = total;
setmaterial(temp_mat);
setGrandTotal({...GrandTotal,total:0});
GrandTotal.total = 0; 
settest({...test,test:""});
test.test = "";

 }
 //console.log(material)
function load(){
  Swal.fire({
    title: "Saving",
    allowEscapeKey: false,
    allowOutsideClick: false,
    timer: material.length*40,
    onOpen: () => {
      Swal.showLoading();
    },
  })
}
function Load_sug(){

  axios({
    method: "post",
    url: Configs.API_URL + "/materialPlaning/approveMaterial/getMaterialByOSL",
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
    data: filter
  })
    .then(function (response) {
      console.log(response.data);
      setmaterial(response.data)
      //setosl(response.data);
   
    })
    .catch(function (error) {
      console.log(error);
    });
}
 

  return (

    <div className="wrapper">
   
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Approve Material Item{"  "}<a target="_blank" href = {getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Approve Material Item
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <select
                    className="custom-select select2 form-control"
                    value={filter.osl_id}
                    onChange={(e)=>{setfilter({...filter,osl_id:e.target.value})}}
                  >
                    <option value="">Select OSL</option>
                    {osl.map((e)=>{
                      return <option value={e.id}>{e.oslno}</option>;
                    })}
                  </select>
                  <label htmlFor="">Select OSL</label>
                </div>
              </div>
            
              <div className="col-md-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary"
                  onClick={Load_sug}
                  >
                    Load Suggestion
                  </button>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <a  onClick={load}>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-block btn-primary"
                    table="table-to-xls_all"
                    filename={"test"+ " " + Date()}
                    sheet="tablexls"
                    buttonText="Download as XLS"
                   
                  />
                  </a>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <button type="button" className="btn btn-block btn-primary "
                  onClick={approve_material}>
                    Save To Database
                  </button>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  {/* <button
                    type="button"
                    className="btn btn-block btn-info  "
                  
                  >
                    Search
                  </button> */}
                </div>
              </div>
              </div>
              </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
            <div className="row">
            <div className="col-md-9">
              <h3 className="card-title">
               
             Approve Material Item 
            
              </h3>
              </div>
              <div className="col-md-3">
              <h3 className="card-title">
               Total Price : {numberWithCommas(GrandTotal.total)}
               </h3>
              </div>
              </div>
              <div className="card-tools"></div>
            </div>

            <div className="card-body ">
              <div className="table-responsive" /* style={{ height: "500px" }} */>
                <MDBDataTable
                  className="table table-head-fixed"
                  table
                  sortable = {false}
                  disableRetreatAfterSorting={MDBT}
                  id="table-to-xls"
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
              <div style = {{display:"none"}}>
                <MDBDataTable
                  className="table table-head-fixed"
                  table
                  sortable = {false}
                  id="table-to-xls_all"
                  entries={4096}
                  striped
                  bordered
                  hover
                  fixedHeader
                  data={data}
                />
                </div>

              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </section>
      </div>
      </div>
  );
}
export default Mat_Approve;
