import React, { Component, useEffect, useState, useRef } from "react";
import komatsu_logo from "../../assets/img/Landing_Page/komatsu_logo.png";
import Footter from "../../component/Footter";
import Header from "../../component/Header";
import sortJsonArray from "sort-json-array";
import Sidebar from "../../component/Sidebar";
import Select from "react-select";
import jsPDF from "jspdf";
import { MDBDataTable, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import QRCode from "qrcode.react";
import "../../assets/css/Content.css";
import "../../assets/css/Input.css";
import "../../assets/css/table.css";
import { userdefault_img, logoTTT } from "../../routes/imgRoute/imgUrl";
import Swal from "sweetalert2";
import product_excel from "../../assets/file/upload_Products.xlsx";
import axios from "axios";
import Configs from "../../config";
import { getOem, getToken, getUser, getFeature, getAcademy, getComConfig } from "../../Utils/Common";
import QRCode2 from "qrcode";
import BTN from "../../component/btn_feature";
import { Get_font_pdf } from "../../assets/font/pdf_font";
import TableLEK from "../../component/tableLEKTTT/table";
import {

  product_qr_report
} from "../../component/Report";
function Product(params) {
  const [product_option, setproduct_option] = useState([]);
  const select_product = useRef();
  const [product_data, setProduct_data] = useState([]);
  const [product_data_lazeload, setProduct_data_lazeload] = useState([]);
  const [model, setModel] = useState([]);
  const [oem_id, setOem_id] = useState(getOem());
  const [oemfix, setoemfix] = useState();
  const [excel, setexcel] = useState({
    name: "",
    status: null,
  });
  const [product_exel, setproduct_exel] = useState([]);
  const [feature, setfeature] = useState([]);
  const [filler_product, setFiller_product] = useState({
    no: "",
    name: "",
    product_model_id: "",
    oem_id: getOem(),
    all_search:''

  });
  const [countPage, setCountPage] = useState(1);
  const [countData, setCountData] = useState(0);
  const [entries, setEntries] = useState(10);
  const [search, setSearch] = useState(0);
  const [oem_data, setOem_data] = useState();
  const [company, setcompany] = useState();

  const [test, settest] = useState({
    test: '',
  });
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `js/Product`;
    document.body.appendChild(script);

  }, []);

  const [type_select, settype_select] = useState({
    status: "plant_item",
  });
  const [filler_checkbox, setfiller_checkbox] = useState({
    inventory: false,
    virtual_product: false,
    under_min_lot: false,

  });


  var config_model = {
    method: "get",
    url: Configs.API_URL + "/product/getProductModel?id=" + oem_id,
    headers: {
      Authorization: getToken(),
      "X-TTT": Configs.API_TTT,
      "Content-Type": "application/json",
    },
  };
  const [oem_fix_, setoem_fix] = useState({
    oem: "",
  });
  useEffect(() => {
    if (getOem() === "480479b6-dcd3-4a4f-89a7-95c5e8f275bc") {
      setoemfix("thanasub");
      oem_fix_.oem = "thanasub";
    }
    Gettable(1, 10, 'search');
    filterProductLaze(1, 10, 'search');
    Getmodel();

    var feature_data = JSON.parse(getFeature()).filter((element) => {
      return element.menu === "Product";
    });
    setfeature(feature_data);
    //console.log("feature",JSON.parse(getFeature()));

    axios({
      method: "get",
      url: Configs.API_URL + "/company/findOEMByCompanyId?id=" + getUser().com,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        response.data.map((el) => {
          if (el.id === getOem()) {
            /* console.log(el); */
            return setOem_data(el);
          }
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    axios({
      method: "get",
      url: Configs.API_URL + "/company/findById?id=" + getUser().com,
      headers: {
        Authorization: getToken(),
        "Content-Type": "application/json",
        "X-TTT": Configs.API_TTT,
      },
    })
      .then(function (response) {
        /*  console.log(response.data); */
        setcompany(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const Getmodel = async () => {
    axios(config_model)
      .then(async (response) => {
        // console.log(response.data);
        setModel(response.data);
        const options = [];
        response.data.forEach((el) => {
          var element = {};
          element["value"] = el.id;
          element["label"] = el.name;
          options.push(element);
        });
        setproduct_option(options);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const Gettable = async (page, size, search) => {
    /*     Swal.fire({
          title: "กำลังดึงข้อมูล",
          text: "Loading",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading();
          },
        });
        if (search) {
          setSearch(1);
        } else {
          setSearch(0);
        } */

    let temp = {
      no: (filler_product.no || '').trim(),
      name: (filler_product.name || '').trim(),
      product_model_id: filler_product.product_model_id,
      oem_id: getOem(),
      virtual_product: filler_checkbox.virtual_product,
      oem_fix: oem_fix_.oem,
      page: page || 1,
      size: size || 10,
      all_search:(filler_product.all_search || '').trim()
    }

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        console.log(response.data)
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            QRCode2.toDataURL(
              response.data[i].no,
              function (err, url) {
                response.data[i].img_qr = url;
              }
            );

          }
        }





        let test = sortJsonArray(response.data, "no", "asc");

        await setProduct_data(test);
      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  const filterProductLaze = async (page, size, search) => {
    Swal.fire({
      title: "กำลังดึงข้อมูล",
      text: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    if (search) {
      setSearch(1);
    } else {
      setSearch(0);
    }

    let temp = {
      no: (filler_product.no || '').trim(),
      name: (filler_product.name || '').trim(),
      product_model_id: filler_product.product_model_id,
      oem_id: getOem(),
      virtual_product: filler_checkbox.virtual_product,
      oem_fix: oem_fix_.oem,
      page: page || 1,
      size: size || 10,
      all_search:(filler_product.all_search || '').trim()
    }

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filterproductLazeload",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        console.log(response.data)
        /*    if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              QRCode2.toDataURL(
                response.data[i].no,
                function (err, url) {
                  response.data[i].img_qr = url;
                }
              );
    
            }
           } */





        /*   let test = sortJsonArray(response.data, "no", "asc");
  
          await setProduct_data(test); */
        setProduct_data_lazeload(response.data.data);
        setCountPage(response.data.countPage);
        setCountData(response.data.count)
        setEntries(size);
        Swal.close();

      })
      .catch(async (error) => {
        console.log(error);
      });
  };


  const Gettable_all = async (page, size, search) => {
    /*    Swal.fire({
         title: "กำลังดึงข้อมูล",
         text: "Loading",
         allowEscapeKey: false,
         allowOutsideClick: false,
         onOpen: () => {
           Swal.showLoading();
         },
       });
       if (search) {
         setSearch(1);
       } else {
         setSearch(0);
       } */

    let temp = {
      no: (filler_product.no || '').trim(),
      name: (filler_product.name || '').trim(),
      product_model_id: filler_product.product_model_id,
      oem_id: getOem(),
      virtual_product: filler_checkbox.virtual_product,
      oem_fix: oemfix,
      /*  page: page || 1,
       size: size || 10 */
    }

    await axios({
      method: "post",
      url: Configs.API_URL + "/product/filter_allItem",
      headers: {
        Authorization: getToken(),
        "X-TTT": Configs.API_TTT,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async (response) => {
        //console.log(response.data)
        let test = sortJsonArray(response.data, "no", "asc");

        await setProduct_data(test);

        /*       setProduct_data(response.data.data);
              setCountPage(response.data.countPage);
              setCountData(response.data.count)
              setEntries(size);
              settest({ ...test, test: '' })
              test.test = '';
              Swal.close(); */

      })
      .catch(async (error) => {
        console.log(error);
      });
  };

  function addpage() {
    window.location.href =
      "/Warehouse_Management/Factory_Master_Data/Product/add/*";
  }
  const upload_prod_file = async (e) => {
    if (e.target.files[0]) {
      //console.log(e.target.files[0]);
      /*  setexcel({ ...excel, name: e.target.files[0].name }); */

      var data = new FormData();
      data.append("file", e.target.files[0]);
      data.append("oem_id", getOem());

      var config_uploadExecl = {
        method: "post",
        url: Configs.API_URL + "/product/importExcel",
        headers: {
          Authorization: getToken(),
          "X-TTT": Configs.API_TTT,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config_uploadExecl)
        .then(function (response) {
          //console.log(response.data);

          let num = 0;
          for (let index = 0; index < response.data.length; index++) {
            if (response.data[index].status === false) {
              num++;
            }
          }

          if (num !== 0) {
            setproduct_exel(response.data);
          } else {
            Swal.fire(
              "Upload Success",
              "Your file has been Upload.",
              "success"
            ).then(() => {
              /*  window.location.href =
              "/Warehouse_Management/Factory_Master_Data/Product"; */
              setproduct_exel(response.data);
            });

            setexcel({ ...excel, status: null, name: "" });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  function deldata(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "get",
          url: Configs.API_URL + "/product/del?id=" + id,
          headers: {
            Authorization: getToken(),
            "Content-Type": "application/json",
            "X-TTT": Configs.API_TTT,
          },
        })
          .then(function (response) {
            if (response.data === true) {
              Swal.fire(
                "Deleted!",
                "Your file has been deleted.",
                "success"
              ).then(() => {
                window.location.href =
                  "/Warehouse_Management/Factory_Master_Data/Product";
              });
            }
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Error", "Something went wrong: " + error, "error");
          });
      }
    });
  }
  const clearfillter = async () => {
    await setFiller_product({
      ...filler_product,
      product_model_id: "",
      name: "",
      no: "",
      status: "plant_item",
      all_search:''
    });
    filler_product.product_model_id = "";
    filler_product.name = "";
    filler_product.no = "";
    filler_product.all_search = "";
    filler_product.status = "plant_item";
    select_product.current.select.clearValue();
    search_data();
    // window.location.reload();
  };
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const rowsData = [];
  for (var index = 0; index < product_data_lazeload.length; index++) {
    var id_toProduct;
    if (type_select.status === "all_item") {
      if (product_data_lazeload[index].product_plants_item === null) {
        id_toProduct = product_data_lazeload[index].id;
      } else {
        id_toProduct = product_data_lazeload[index].product_plants_item;
      }
    } else {
      id_toProduct = product_data_lazeload[index].id;
    }

    const rowItem = {};
  /*   rowItem["no"] = index + 1; */
    rowItem["image"] = (
      /* product_data_lazeload[index].image_path */ <div style={{ textAlign: "center" }}>
        <img
          alt="..."
          className="img-fluid rounded "
          src={
            product_data_lazeload[index].imaget_path !== null
              ? Configs.API_URL_IMG + product_data_lazeload[index].imaget_path
              : userdefault_img.imgs
          }
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
    rowItem["qr"] = (
      <div style={{ textAlign: "center" }}>
        <QRCode
          value={product_data_lazeload[index].no}
          size={60}
          fgColor={"#000000"}
          bgColor={"#ffffff"}
          level={"M"}
          renderAs={"png"}
          includeMargin={false}
          imageSettings={{
            src: logoTTT.imgs,
            height: 10,
            width: 10,
            x: null,
            y: null,
            excavate: true,
          }}
        />
      </div>
    );
    rowItem["product_no"] = product_data_lazeload[index].no;
    rowItem["product_name"] = product_data_lazeload[index].name;
    /*rowItem["product_model"] = product_data_lazeload[index].product_model_id (model.map((el)=>{product_data_lazeload[index].product_model_id ===el.id ?el.name:"noe match"})); */
    /* rowItem["product_model"] ="" +product_data_lazeload[index].product_model_id; */
    for (let indexin = 0; indexin < model.length; indexin++) {
      if (model[indexin].id === product_data_lazeload[index].product_model_id) {
        rowItem["product_model"] = "" + model[indexin].name;
      } else if (
        product_data_lazeload[index].product_model_id === undefined ||
        product_data_lazeload[index].product_model_id === null ||
        product_data_lazeload[index].product_model_id === ""
      ) {
        rowItem["product_model"] = "none";
      }
    }
    rowItem["qty_unit"] = product_data_lazeload[index].qty_unit;
    rowItem["buy_price"] = numberWithCommas(product_data_lazeload[index].buy_price || 0);
    rowItem["sell_price"] = numberWithCommas(product_data_lazeload[index].sell_price || 0);
    rowItem["description"] = product_data_lazeload[index].description;


    rowItem["mgt"] = (
      <div className="row">
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Product/read/" +
              id_toProduct +
              "/*/" +
              product_data_lazeload[index].is_assy
            }
            key={id_toProduct}
            className=" btn btn-xs "
          >
            <i class="fas fa-eye"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            href={
              "/Warehouse_Management/Factory_Master_Data/Product/edit/" +
              id_toProduct +
              "/*/" +
              product_data_lazeload[index].is_assy
            }
            key={id_toProduct}
            className=" btn btn-xs "
          >
            {" "}
            <i class="fas fa-pencil-alt"></i>{" "}
          </a>
        </div>
        <div className="col-4">
          <a
            key={product_data_lazeload[index].id}
            className=" btn btn-xs "
            onClick={deldata.bind(this, product_data_lazeload[index].id)}
          >
            <i class="fas fa-trash-alt"></i>
          </a>
        </div>
      </div>
    );
    rowsData.push(rowItem);
  }
  var go = []

  go.push(
   /*  {
    label: "No",
    field: "no",
    sort: "asc",
    width: 50,
  }, */
    {
      label: "Image",
      field: "image",
      sort: "asc",
      width: 50,
    },

    {
      label: "QR",
      field: "qr",
      sort: "asc",
      width: 50,
    },

    {
      label: "Product No",
      field: "product_no",
      sort: "asc",
      width: 50,
    },
    {
      label: "Product Name",
      field: "product_name",
      sort: "asc",
      width: 50,
    },
    {
      label: "Model/category",
      field: "product_model",
      sort: "asc",
      width: 50,
    },
    {
      label: "Qty_Unit",
      field: "qty_unit",
      sort: "asc",
      width: 50,
    },
    {
      label: "Buy price",
      field: "buy_price",
      sort: "asc",
      width: 50,
    },
    {
      label: "Sell price",
      field: "sell_price",
      sort: "asc",
      width: 50,
    },
  )
  {
    feature.map((e) => {
      if (e.feature === "description") {
        go.push(
          {
            label: "Description",
            field: "description",
            /*      sort: "asc", */
            width: 50,
          },
        )
      }
    })
  }

  go.push({
    label: "Management",
    field: "mgt",
    sort: "asc",
    width: 50,
  },)
  const data = {
    columns: go,
    rows: rowsData,
  };
  var Image_feature = feature.filter((e) => {
    return e.feature === "image";
  });
  var QR_feature = feature.filter((e) => {
    return e.feature === "QR";
  });
  var QTY_feature = feature.filter((e) => {
    return e.feature === "Qty_unit";
  });

  var BuyPrice_feature = feature.filter((e) => {
    return e.feature === "Buy_price";
  });

  var SellPrice_feature = feature.filter((e) => {
    return e.feature === "Sell_price";
  });
  if (Image_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "image";
    });
    data.columns.splice(index, 1);
  }
  if (QR_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "qr";
    });
    data.columns.splice(index, 1);
  }
  if (QTY_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "qty_unit";
    });
    data.columns.splice(index, 1);
  }

  if (BuyPrice_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "buy_price";
    });
    data.columns.splice(index, 1);
  }

  if (SellPrice_feature.length === 0) {
    var index = data.columns.findIndex((e) => {
      return e.field === "sell_price";
    });
    data.columns.splice(index, 1);
  }

  const options = [];
  for (let index = 0; index < 5; index++) {
    const element = [];
    element["value"] = index;
    element["label"] = index;
    options.push(element);
  }
  function set_excel() {
    setexcel({ ...excel, status: null, name: "" });
    excel.status = null;
    excel.name = "";
    setproduct_exel([]);
    window.location.href = "/Warehouse_Management/Factory_Master_Data/Product";
  }

  function load() {
    var status = "0";
    Swal.fire({
      title: "Loading",
      allowEscapeKey: false,
      allowOutsideClick: false,
      timer: 500,
      onOpen: () => {
        Swal.showLoading();
        status = "1";
      },
    }).then(() => {
      if (status == "1") {
        downloadReport2();
      } else {
        alert("tttt");
      }
    });
  }

  function downloadReport() {
    var Com_logo = `${Configs.API_URL_IMG + company.logo_path}`;
    var OEM_logo = `${Configs.API_URL_IMG + oem_data.logo_path}`;
    var prod_img = `${Configs.API_URL_IMG}`;
    var com_chackname = getComConfig().com_name
    const doc = new jsPDF("p", "mm", "a4");
    let img_qr = "";
    for (let index = 0; index < product_data.length; index = index + 8) {
      doc.setDrawColor(0);
      doc.setFillColor("#272727");
      doc.rect(0, 0, 1080, 31, "F");

      doc.addImage(Com_logo, "JPEG", 1, 1, 29, 29);
      doc.addImage(OEM_logo, "JPEG", 180, 1, 29, 29);
      /*  doc.addImage(imgData, 'JPEG',180, 1, 29, 29) */
      doc.setTextColor(255, 255, 255);
      doc.addFileToVFS("ROCK.TTF", Get_font_pdf());

      doc.addFont("ROCK.TTF", "Rocki", "normal");

      doc.setFont("Rocki");

      //doc.addFileToVFS("ROCKI.ttf", arimoBase64);
      //doc.addFont('ROCK', 'ROCKI','normal')
      //doc.setFont('ROCKI')
      if (com_chackname === "Central Spring co., Ltd.") {
        doc.setFontSize(30);
        doc.text(105, 20, "CENTRAL SPRING PART LIST", "center");
      } else {
        doc.setFontSize(40);
        doc.text(105, 20, "SHIMIZU PART LIST", "center");
      }
      //console.log(product_data);
      //box 1
      if (index < product_data.length) {
        QRCode2.toDataURL(
          product_data[index].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 10, 32, 40, 40);

        doc.addImage(
          prod_img + product_data[index].imaget_path,
          "JPEG",
          48,
          37,
          30,
          30
        );
        doc.rect(48, 37, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(15, 73, "Part No.");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(78, 82, product_data[index].no, "right");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        //doc.addFont('Rocki', 'normal');
        //doc.setFont('Rocki');
        doc.text(78, 88, "Part Name. : " + product_data[index].name, "right");

        doc.setDrawColor(192, 192, 192);
        doc.rect(0, 32, 105, 60);
      }

      //box 2

      if (index + 1 < product_data.length) {
        QRCode2.toDataURL(
          product_data[index + 1].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 115, 32, 40, 40);

        doc.addImage(
          prod_img + product_data[index + 1].imaget_path,
          "JPEG",
          153,
          37,
          30,
          30
        );
        doc.rect(153, 37, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        //doc.addFont('App/public/dist/font/ROCKI.TTF.ttf', 'Rocki')
        //doc.addFont('Rocki', 'normal');
        //doc.setFont('Rocki');
        doc.text(120, 73, "Part No.");

        //doc.addFont('App/public/dist/font/ROCKI.TTF.ttf', 'Rocki')
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(185, 82, product_data[index + 1].no, "right"); //x 120

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        /* doc.addFont('Rocki', 'normal');
    doc.setFont('Rocki'); */
        doc.text(
          185,
          88,
          "Part Name. " + product_data[index + 1].name,
          "right"
        ); //120
        doc.setDrawColor(192, 192, 192);
        doc.rect(105, 32, 105, 60);
      }

      //box 3

      if (index + 2 < product_data.length) {
        QRCode2.toDataURL(
          product_data[index + 2].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 10, 92, 40, 40);

        doc.addImage(
          prod_img + product_data[index + 2].imaget_path,
          "JPEG",
          48,
          97,
          30,
          30
        );
        doc.rect(48, 97, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        /*   doc.addFont('Rocki', 'normal');
    doc.setFont('Rocki'); */
        doc.text(15, 133, "Part No.");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(78, 142, product_data[index + 2].no, "right");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        /*  doc.addFont('Rocki', 'normal');
    doc.setFont('Rocki'); */
        doc.text(
          78,
          148,
          "Part Name. : " + product_data[index + 2].name,
          "right"
        );

        doc.setDrawColor(211, 211, 211);
        doc.rect(0, 92, 105, 60);
      }
      //box 4
      if (index + 3 < product_data.length) {
        QRCode2.toDataURL(
          product_data[index + 3].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 115, 92, 40, 40);

        doc.addImage(
          prod_img + product_data[index + 3].imaget_path,
          "JPEG",
          153,
          97,
          30,
          30
        );
        doc.rect(153, 97, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        /* doc.addFont('Rocki', 'normal');
    doc.setFont('Rocki'); */
        doc.text(120, 133, "Part No.");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(185, 142, product_data[index + 3].no, "right");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        /*  doc.addFont('Rocki', 'normal');
    doc.setFont('Rocki'); */
        doc.text(
          185,
          148,
          "Part Name. : " + product_data[index + 3].name,
          "right"
        );

        doc.setDrawColor(211, 211, 211);
        doc.rect(105, 92, 105, 60);
      }
      //box 5

      if (index + 4 < product_data.length) {
        QRCode2.toDataURL(
          product_data[index + 4].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 10, 152, 40, 40);

        doc.addImage(
          prod_img + product_data[index + 4].imaget_path,
          "JPEG",
          48,
          157,
          30,
          30
        );
        doc.rect(48, 157, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        /* doc.addFont('Rocki', 'normal');
    doc.setFont('Rocki'); */
        doc.text(15, 193, "Part No.");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(78, 202, product_data[index + 4].no, "right");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        /* doc.addFont('Rocki', 'normal');
    doc.setFont('Rocki'); */
        doc.text(
          78,
          208,
          "Part Name. : " + product_data[index + 4].name,
          "right"
        );

        doc.setDrawColor(211, 211, 211);
        doc.rect(0, 152, 105, 60);
      }
      //box 6

      if (index + 5 < product_data.length) {
        QRCode2.toDataURL(
          product_data[index + 5].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 115, 152, 40, 40);

        doc.addImage(
          prod_img + product_data[index + 5].imaget_path,
          "JPEG",
          153,
          157,
          30,
          30
        );
        doc.rect(153, 157, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(120, 193, "Part No.");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        /*  doc.addFont('Rocki', 'normal');
  doc.setFont('Rocki'); */
        doc.text(185, 202, product_data[index + 5].no, "right");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        /*   doc.addFont('Rocki', 'normal');
  doc.setFont('Rocki'); */
        doc.text(
          185,
          208,
          "Part Name. : " + product_data[index + 5].name,
          "right"
        );

        doc.setDrawColor(211, 211, 211);
        doc.rect(105, 152, 105, 60);
      }
      //box 7
      if (index + 6 < product_data.length) {
        QRCode2.toDataURL(
          product_data[index + 6].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 10, 212, 40, 40);

        doc.addImage(
          prod_img + product_data[index + 6].imaget_path,
          "JPEG",
          48,
          217,
          30,
          30
        );
        doc.rect(48, 217, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(15, 253, "Part No.");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        /*   doc.addFont('Rocki', 'normal');
  doc.setFont('Rocki'); */
        doc.text(78, 262, product_data[index + 6].no, "right");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        /*  doc.addFont('Rocki', 'normal');
  doc.setFont('Rocki'); */
        doc.text(
          78,
          268,
          "Part Name. : " + product_data[index + 6].name,
          "right"
        );

        doc.setDrawColor(211, 211, 211);
        doc.rect(0, 212, 105, 60);
      }
      //box 8

      if (index + 7 < product_data.length) {
        QRCode2.toDataURL(
          product_data[index + 7].no.toString(),
          function (err, url) {
            img_qr = url;
          }
        );
        doc.addImage(img_qr, "JPEG", 115, 212, 40, 40);

        doc.addImage(
          prod_img + product_data[index + 7].imaget_path,
          "JPEG",
          153,
          217,
          30,
          30
        );
        doc.rect(153, 217, 30, 30);

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
        doc.addFont("Rocki", "bold");
        doc.setFont("Rocki");
        doc.text(120, 253, "Part No.");

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(34);
        /* doc.addFont('Rocki', 'normal');
  doc.setFont('Rocki'); */
        doc.text(185, 262, product_data[index + 7].no, "right");

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(9.3);
        /*  doc.addFont('Rocki', 'normal');
  doc.setFont('Rocki'); */
        doc.text(
          185,
          268,
          "Part Name. : " + product_data[index + 7].name,
          "right"
        );

        doc.setDrawColor(192, 192, 192);
        doc.rect(105, 212, 105, 60);
      }

      if (index + 8 < product_data.length) {
        doc.addPage("a4", "h");
      }
    }

    window.open(doc.output("bloburl"));
    //doc.save("pdf");
  }

  function downloadReport2() {

    product_qr_report(product_data, company, oem_data)
  }
  //console.log("status",type_select.status);

  async function set_status_plant_item(e) {
    settype_select({
      ...type_select,
      status: e.target.value,
    });
    //console.log(type_select.status)
    Gettable(1, 10, 'search');
    filterProductLaze(1, 10, 'search');
  }
  /*   
   async function set_status_vitual_item(e) {
     console.log("หา",e.target.checked);
    settype_virtual({
      ...type_virtual,
      status: e.target.checked,
    });
    Gettable();
   } 
   */


  async function set_status_all_item(e) {
    settype_select({
      ...type_select,
      status: e.target.value,
    });
    //console.log(type_select.status)
    Gettable_all();
  }

  function search_data() {
    if (type_select.status === "all_item") {
      //console.log("all_item")
      Gettable_all();
    } else {
      //console.log("plant_item")
      Gettable(1, 10, 'search');
      filterProductLaze(1, 10, 'search');

    }



  }
  /* 
  function search_data_virtual() {
   
    if (type_virtual.status === "all_item" ) {
      //console.log("all_item")
      Gettable_all_virtual();
    } else {
      //console.log("plant_item")
      Gettable_virtual();
    }

  } */

  return (
    <div className="wrapper">
      {/* <Header />
    <Sidebar menu="warehouse" activemenu="product" submenu="factmaster" /> */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Product{"  "}<a target="_blank" href={getAcademy()}><i class='fa fa-info-circle' ></i></a></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active">
                    Factory Master Data
                  </li>
                  <li className="breadcrumb-item active">Product</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.all_search}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        all_search: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Search</label>{" "}
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.no}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        no: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product No.</label>{" "}
                  {/* <a onClick={handleChange}>vbnjmkfgbhnjmk,fvgbhnmj</a> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group ">
                  <input
                    type="text"
                    className="form-control"
                    required="false"
                    value={filler_product.name}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        name: e.target.value,
                      });
                    }}
                  />
                  <label htmlFor="">Product Name</label>{" "}
                </div>
              </div>
              <div className="col-md-2" style={{ "z-index": "98" }}>
                <div className="form-group ">
                  <Select
                    options={product_option}
                    ref={select_product}
                    defaultValue={filler_product.product_model_id}
                    onChange={(e) => {
                      //console.log(e);
                      if (e !== null) {
                        setFiller_product({
                          ...filler_product,
                          product_model_id: e.value || "",
                        });
                      }
                    }}
                  />

                  {/* <select
                    className="custom-select select2 form-control"
                    value={filler_product.product_model_id}
                    onChange={(e) => {
                      setFiller_product({
                        ...filler_product,
                        product_model_id: e.target.value,
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Select Model
                    </option>
                    {model.map((el) => {
                      return <option value={el.id}>{el.name}</option>;
                    })}
                  </select> */}
                  <label htmlFor="">Model/Category</label>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info  "
                    onClick={() => {
                      search_data();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>

              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-info"
                    onClick={() => clearfillter()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {/* {JSON.parse(getFeature()).map((e) => {

                if(e.feature === "Tap1"){
                  return( 
                    <BTN onClick={""} name = {e.feature} />
                  );
                }
                if(e.feature === "Tap2"){
                  return( 
                    <BTN onClick={""} name = {e.feature} />
                  );
                }
                if(e.feature === "Tap3"){
                  return( 
                    <BTN onClick={""} name = {e.feature} />
                  );
                }
                if(e.feature === "Tap4"){
                  return( 
                    <BTN onClick={""} name = {e.feature} />
                  );
                }
              })

              } */}
            </div>
          </div>
          <div className="container-fluid">
            {feature.map((e) => {
              if (e.feature === "Plant Item view") {
                return (
                  <div className="row">
                    <div className="col-1"></div>
                    <div className="col-5 col-md-3 col-xl-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="plant_item"
                        name="type_select"
                        value="plant_item"
                        checked={
                          type_select.status === "plant_item" ? true : false
                        }
                        onClick={set_status_plant_item.bind(this)}
                      />
                      <label htmlFor="plant_item">
                        Plant items view.{" "}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                    </div>

                    <div className="col-5 col-md-3 col-xl-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="all_item"
                        name="type_select"
                        value="all_item"
                        checked={
                          type_select.status === "all_item" ? true : false
                        }
                        onClick={set_status_all_item.bind(this)}
                      />
                      <label htmlFor="all_item">
                        All items view.{" "}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </label>
                    </div>
                  </div>
                );
              }
            })}

            {feature.map((e) => {
              if (e.feature === "Vertaul Product") {
                return (
                  <>
                    <div className="col-1"></div>
                    <div className="col-5 col-md-3 col-xl-2">
                      <div className="form-group">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is_virtual"
                          name="is_virtual"
                          value="virtual"
                          checked={filler_checkbox.virtual_product}
                          onChange={(event) => {
                            //console.log(event.currentTarget.checked);
                            setfiller_checkbox({ ...filler_checkbox, virtual_product: event.currentTarget.checked });
                            filler_checkbox.virtual_product = event.currentTarget.checked;
                            Gettable(1, 10, 'search');
                            filterProductLaze(1, 10, 'search');
                            /*  isChecked2 = setIsChecked(event.currentTarget.checked);
                             search_check(event.currentTarget.checked) */
                          }}
                        />
                        {/*  {console.log(  product.is_virtual)} */}
                        <label htmlFor="">สินค้าโป๊ว</label>
                      </div>
                    </div>
                  </>
                );
              }
            })}
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-4 col-md-3 col-xl-2">
                <div className="form-group ">
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={addpage}
                  >
                    Add
                  </button>
                </div>
              </div>

              {feature.map((e) => {
                if (e.feature === "Import Excel") {
                  return (
                    <div className="col-12 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <button
                          type="button"
                          className="btn btn-block btn-primary"
                          data-toggle="modal"
                          data-target="#modal-default"
                        >
                          Import Excel
                        </button>
                      </div>
                    </div>
                  );
                }
                if (e.feature === "Report QR") {
                  return (
                    <div className="col-12 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <a onClick={load}>
                          <button
                            type="button"
                            className="btn btn-block btn-info"
                            //onClick={downloadReport}
                            disabled={false}
                          >
                            Report QR
                          </button>
                        </a>
                      </div>
                    </div>
                  );
                }
                if (e.feature === "download template.xls") {
                  return (
                    <div className="col-12 col-md-6 col-xl-2">
                      <div className="form-group ">
                        <a
                          href={product_excel}
                          download="upload_Products.xlsx"
                          style={{ fontSize: "" }}
                        >
                          {" "}
                          <button className="btn btn-block btn-info">
                            download template.xls
                          </button>
                        </a>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Product</h3>
              <div className="card-tools"></div>
            </div>

            <div className="card-body table-responsive " style={{ whiteSpace: 'nowrap' }}>
              <TableLEK


                headTable={data.columns}
                dataTable={rowsData}
                countPage={countPage}
                countData={countData}
                entries={entries}
                isSearch={search}
                callFnc={filterProductLaze}


              />
              {/*    <MDBDataTable
              sortable = {false}
                className="table table-head-fixed"
                striped
                bordered
                hover
                fixedHeader
                data={data}
              /> */}

              {/* <MDBTable>
      <MDBTableHead>
        <tr>
          <th  style={{textAlign:"center"}}>  
          <img
        alt="..."
        className="img-fluid rounded "
        src={komatsu_logo}
        style={{ width: "50px", height: "50px" }}
      />

          </th>
          <th style={{fontSize:"64px",textAlign:"center"}} colSpan="2">Shimizu Part List </th>
          <th style={{textAlign:"center"}}> <img
        alt="..."
        className="img-fluid rounded "
        src={komatsu_logo}
        style={{ width: "50px", height: "50px" }}
      /></th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Larry</td>
          <td>the Bird</td>
          <td>@twitter</td>
        </tr>
      </MDBTableBody>
    </MDBTable> */}
            </div>
            {/* /.card-body */}
            <div className="card-footer"></div>
            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
        <div className="modal fade" id="modal-default">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Uplaod Master Product</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={set_excel}
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 ">
                    <div className="none-nolmalinput">
                      <label htmlFor="">Product file:</label>
                      <small style={{ color: "red" }}>
                        ** Excel from template **
                      </small>
                      <div className="input-group">
                        <div className="custom-file">
                          <input
                            type="file"
                            /* disabled={} */
                            className="custom-file-input"
                            id="exampleInputFile"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={upload_prod_file}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="exampleInputFile"
                          >
                            {excel.name !== null &&
                              excel.name !== "" &&
                              excel.name !== undefined
                              ? excel.name
                              : "Select File"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div
                      className="table-responsive" /* style={{ height: "500px" }} */
                    >
                      <MDBTable striped hover bordered small searching={true}>
                        <MDBTableHead>
                          <tr>
                            <th>No.</th>
                            <th>Model</th>
                            <th>Status</th>
                            <th>Error</th>
                            <th>Update</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {product_exel.map((el, index) => {
                            if (
                              product_exel.filter((x) => !x.status_update)
                                .length > 0
                            ) {
                              var status_up = false;
                            } else {
                              var status_up = true;
                            }
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{el.model}</td>

                                <td>
                                  {el.status ? (
                                    <span style={{ color: "green" }}>True</span>
                                  ) : (
                                    <span style={{ color: "red" }}>False</span>
                                  )}
                                </td>
                                <td>{el.error}</td>
                                <td>
                                  {status_up ? (
                                    <span style={{ color: "green" }}>
                                      Success
                                    </span>
                                  ) : (
                                    <span style={{ color: "red" }}>False</span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </MDBTableBody>
                      </MDBTable>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h3>
                      {excel.status !== null ? (
                        <span>
                          {excel.status === true
                            ? "Upload Success"
                            : "Upload Fail"}
                        </span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={set_excel}
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*     <Footter/> */}
    </div>
  );
}
export default Product;
